<dgx-delete-multi-confirmation-modal
  [itemIds]="itemIds"
  [items]="items"
  [deleteNoticeGeneric]="i18n.OrgManage_Opportunities_Delete_Notice_Generic"
  [deleteNoticeSpecific]="deleteNoticeSpecific"
  [deleteNoticeUndeletable]="deleteNoticeUndeletable"
  [deleteConfirmation]="deleteConfirmation"
  [modalHeaderTitle]="modalHeaderTitle"
  [undeletableItems]="undeletableItems"
></dgx-delete-multi-confirmation-modal>
