import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const enum Expanded {
  title = 'title',
  summary = 'summary',
}

@Injectable({ providedIn: 'root' })
export class SearchResultCardService {
  public expanded$: BehaviorSubject<{
    id: string | number;
    expandedSections: any;
  }> = new BehaviorSubject<{
    id: string | number;
    expandedSections: Expanded[];
  }>({
    id: undefined,
    expandedSections: [],
  });

  public updateExpanded(id: string | number, expandedSections: Expanded) {
    this.expanded$.next({
      id,
      expandedSections:
        this.expanded$.getValue().id === id
          ? [...this.expanded$.getValue().expandedSections, expandedSections]
          : [expandedSections],
    });
  }
}
