<dgx-search-field-wrapper
  *ngIf="useSearchField; else userSearchField"
  [styles]="isDisabled ? 'disabled' : ''"
  class="full-width"
>
  <ng-container *ngTemplateOutlet="userSearchField"></ng-container>
</dgx-search-field-wrapper>
<!-- selected users -->
<div class="rel guts-m-t-1" *ngIf="!hideResults && recipients?.length">
  <p *ngIf="userListTitle" class="par par--small font-semibold guts-m-t-2">
    {{ userListTitle }}
  </p>
  <div
    class="l_flexbar"
    *ngFor="let user of recipients; trackBy: trackBy"
    data-dgat="user-search-56d"
  >
    <div class="l_flex-grow min-width-0">
      <dgx-user-search-item
        [item]="asUserSearchItem(user)"
      ></dgx-user-search-item>
    </div>
    <button
      df-button
      dfButtonType="clear"
      class="guts-p-full-1 dg-modal__close-icon"
      *ngIf="
        !isReadOnly &&
        (allowRemoval ||
          preventUserRemovalKey !== asUserSearchItem(user)?.userProfileKey)
      "
      [ngbTooltip]="'A11y_RemoveUser' | translate: { userType: user.name }"
      (click)="onRemove(user)"
      [attr.aria-label]="'A11y_RemoveUser' | translate: { userType: user.name }"
      data-dgat="user-search-420"
    >
      <df-icon icon="cross"></df-icon>
    </button>
  </div>
</div>

<!-- template for search field -->
<ng-template #userSearchField>
  <div *ngIf="!isReadOnly" class="full-width l_flex l_flex-middle">
    <input
      #userSearch
      [attr.id]="labelKey ? labelKey : 'user-search'"
      type="text"
      [disabled]="isDisabled"
      [dgxAutofocus]="autofocus"
      placeholder="{{ placeholder | translate }}"
      [attr.aria-label]="ariaLabel || null"
      [ngModel]="model"
      [ngbTypeahead]="onSearch"
      [resultTemplate]="userSearchItem"
      [resultFormatter]="resultFormatter"
      [editable]="false"
      [focusFirst]="false"
      [required]="isRequired && !recipients.length"
      (selectItem)="onSelect($event)"
      (focus)="onStateChange($event)"
      (input)="onStateChange($event)"
      data-dgat="user-search-354"
      (keydown)="scrollToElement($event)"
    />
    <df-spinner
      *ngIf="isLoading || isLoadingInternal"
      spinnerContext="textInput"
    ></df-spinner>
  </div>
</ng-template>

<!-- template for search results -->
<ng-template #userSearchItem let-user="result" let-term="term">
  <dgx-user-search-item [item]="user"></dgx-user-search-item>
</ng-template>
