import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';

import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { TagsFieldComponent } from '@app/form-fields/wrappers/tags-field/tags-field.component';
import { AssessmentFormModel } from '../assessment-form.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';
import { LDFlagsService } from '@dg/shared-services';
@Injectable({ providedIn: 'root' })
export class PathwayAssessmentRendererService implements FormRenderer {
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private translateService: TranslateService,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService,
    private userInputsService: UserInputsService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<AssessmentFormModel>
  ): DfFormFieldConfig[] {
    const vm: AssessmentFormModel = context.state();
    const minZeroError = this.translateService.instant('Core_InvalidMinValue', {
      min: 0,
    });

    return [
      this.formBuilder
        .title()
        .labeled(context.translationKeys.AssessmentFormCtrl_AssessmentName)
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentName
        )
        .withDgatId('assessmentForm-487')
        .autofocused()
        .readonlyWhen(() => context.inputContext.isEditing && vm.isCredSpark)
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translateService.instant('Core_FieldRequiredFormat', {
              fieldName: this.translateService.instant(
                context.translationKeys.AssessmentFormCtrl_AssessmentName
              ),
            }),
          },
        })
        .build(),
      this.formBuilder
        .optionalTextInput(
          'providerName',
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withDgatId('assessmentForm-e70')
        .readonlyWhen(() => context.inputContext.isEditing && vm.isCredSpark)
        .hiddenWhen(() => {
          return !context.state().isAuthoring;
        })
        .build(),
      this.formBuilder
        .requiredTextInput(
          'url',
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .ofType('url')
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .withDgatId('assessmentForm-501')
        .hiddenWhen(() => {
          const providerNameIsCredSpark = vm.getIsCredSpark({
            userProviderName: vm.providerName,
          });

          return (
            (vm.isCredSpark || providerNameIsCredSpark) &&
            context.inputContext.isEditing
          );
        })
        .validatedByIndexed({
          urlValidation: {
            expression: (url: AbstractControl) => {
              const isCredSpark = vm.getIsCredSpark({
                userProviderName: context.state().providerName,
              });
              return vm.validateAssessmentUrl(url.value, isCredSpark);
            },
            message: context.translationKeys.AssessmentFormCtrl_UrlRequired,
          },
        })
        .build(),
      this.formBuilder
        .optionalTextarea(
          'description',
          context.translationKeys.AssessmentFormCtrl_Description
        )
        .withDgatId('assessmentForm-91f')
        .readonlyWhen(() => context.inputContext.isEditing && vm.isCredSpark)
        .build(),
      // Questions
      this.formBuilder
        .optionalTextInput(
          'questions',
          context.translationKeys.dgOrgInternalContentForm_Questions
        )
        .ofType('number')
        .withDgatId('assessmentForm-1df')
        .onKeydown((event, model, formControl) => {
          const validateNumericValue =
            this.userInputsService.validateNumericValueOnKeyDown(event);
          if (!validateNumericValue) {
            event.preventDefault();
          }
        })
        .updatedOn('change')
        .validatedBy((control: AbstractControl) =>
          parseInt(control.value) < 0 ? { minZeroError } : null
        )
        .withErrorMessages({ minZeroError })
        .hiddenWhen(() => vm.isCredSpark)
        .build(),
      // Questions & CredSpark Button Inline
      this.formBuilder
        .fieldGroup(
          '',
          [
            this.formBuilder
              .optionalTextInput(
                'questions',
                context.translationKeys.dgOrgInternalContentForm_Questions
              )
              .ofType('number')
              .styledBy('df-form__col-quart')
              .withDgatId('assessmentForm-1df')
              .onKeydown((event, model, formControl) => {
                const validateNumericValue =
                  this.userInputsService.validateNumericValueOnKeyDown(event);
                if (!validateNumericValue) {
                  event.preventDefault();
                }
              })
              .updatedOn('change')
              .validatedBy((control: AbstractControl) =>
                parseInt(control.value) < 0 ? { minZeroError } : null
              )
              .withErrorMessages({ minZeroError })
              .hiddenWhen(() => {
                return !context.inputContext.isEditing;
              })
              .readonlyWhen(() => {
                return context.inputContext.isEditing && vm.isCredSpark;
              })
              .build(),
            this.formBuilder
              .customField(
                null,
                null,
                context.templates.assessmentManageCredSpark,
                {
                  forwardToCredSpark: vm.forwardToCredSpark,
                }
              )
              .styledBy('df-form__col-three-quart')
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(() => {
          return !context.inputContext.isEditing || !vm.isCredSpark;
        })
        .build(),
      // Duration
      this.commonFieldBuilder.buildDurationFields(
        this.ldFlagsService.inputs.addEditContentDuration,
        vm.inputType
      ),
      // Add to Catalog
      this.inputFieldBuilder.buildPathwaysAddToCatalogField(
        context.inputContext.renderMode,
        vm.canManageContent,
        vm.authUser.defaultOrgInfo?.name,
        'AssessmentFormCtrl_AddToCatalogFormat',
        context.templates.addToCatalogDupsHelp,
        vm.onAddToCatalogChange,
        context.state().addToCatalog
      ),
      this.formBuilder
        .foreignField(
          'tags',
          'Core_Skills',
          TagsFieldComponent.REGISTERED_FIELD_TYPE,
          { topTags: vm.authUser.viewerInterests }
        )
        .build(),
    ];
  }
}
