<div class="l_flexbar m-l_flexbar-col gap-full-1 full-width">
  <div class="l_flexbar m-l_flexbar gap-full-half data-bulk-action--container">
    <button
      df-button
      dfButtonType="clear"
      (click)="cancelBulkActions()"
      data-dgat="data-bulk-actions-c28"
    >
      <df-icon icon="cross"></df-icon>
    </button>
    <div>
      {{
        'Core_BulkEditSelectedCount' | translate: { count: numItemsForAction }
      }}
    </div>
  </div>
  <div
    class="
      l_flexbar
      m-l_flexbar
      gap-full-1
      l_flex--align-right
      m_l-flex-wrap
      m-l_flex-justify-center
    "
  >
    <ng-content></ng-content>
  </div>
</div>
