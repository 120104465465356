<div class="ib rel no-wrap" [ngClass]="wrapperClasses">
  <button
    [dgxPopoverTrigger]="columnPopover"
    df-button
    dfButtonType="passive"
    [attr.aria-label]="popupTitle"
    [disabled]="disabled"
    [class.is_disabled]="disabled"
    [ngClass]="buttonClasses"
    data-dgat="column-configurator-905"
  >
    <df-icon icon="columns"></df-icon>
    {{ i18n.dgProfileOverview_Columns }}
  </button>
  <dgx-popover
    #columnPopover
    [(isOpen)]="isPopoverOpen"
    (isOpenChange)="popoverToggled($event)"
    [popoverArrowHidden]="true"
    placement="bottom-left"
    (focusout)="popoverToggled($event)"
  >
    <div class="guts-p-l-2 guts-p-r-3 guts-p-v-2 no-wrap">
      <div
        class="font-semibold guts-p-b-half no-wrap"
        [textContent]="popupTitle"
        aria-hidden="true"
      ></div>
      <div role="listbox">
        <!-- role="option" elements must be immediate children of the role="listbox" element -->
        <button
          *ngFor="let column of columnConfigurations"
          role="option"
          [attr.aria-selected]="column.visible"
          (click)="columnChanged(column)"
          class="l_flexbar par--small font-medium guts-p-t-1"
          [ngClass]="{
            'color-ebony-a45': !column.visible && isConfiguratorDisabled()
          }"
          data-dgat="column-configurator-e70"
        >
          <input
            type="checkbox"
            tabindex="-1"
            readonly="readonly"
            class="block guts-m-r-1"
            [checked]="column.visible"
            [disabled]="!column.visible && isConfiguratorDisabled()"
            data-dgat="column-configurator-781"
          />
          {{ column.label }}
        </button>
      </div>
    </div>
  </dgx-popover>
</div>
