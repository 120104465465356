import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

@Component({
  selector: 'dgx-skill-change-published',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillChangePublishedComponent extends NotificationItemComponentBase {

  protected buildTemplate() {
    return this.translateHtml('Team_Skills_SkillChangePublished_NotificationContent', {
      skillChangePublishedHtml: this.skillChangePublishedTemplate(),
    });
  }
}
