<div
  class="grid__col-lg-8 guts-p-t-0"
  id="section-{{ section.number }}"
  tabindex="-1"
>
  <div *ngIf="!isFauxSection">
    <input
      #sectionTitle="ngModel"
      type="text"
      class="h2"
      required
      [ngModel]="section.title"
      (blur)="onBlur($event, 'title', section)"
      [placeholder]="i18n?.Pathways_SectionTitlePlaceholder"
      [attr.aria-label]="
        'Pathways_SectionTitleLabel'
          | translate: { sectionNumber: section.number }
      "
      [maxlength]="titleMaxLength"
      aria-describedby="pathauthA11yBlurInstructions"
      data-dgat="pathway-author-section-0cb"
      (keyup)="onFieldChange($event, 'title', section)"
    />
    <dgx-validate-field
      *ngIf="isTitleFieldInvalid(sectionTitle)"
      [message]="i18n.Core_TitleRequired"
    ></dgx-validate-field>

    <textarea
      #sectionDescription="ngModel"
      class="pathway-author-section__textarea guts-m-t-half"
      [ngModel]="section.description"
      (blur)="onBlur($event, 'description', section)"
      [placeholder]="i18n?.Pathways_SectionDescriptionPlaceholder"
      [attr.aria-label]="
        'Pathways_SectionDescriptionLabel'
          | translate: { sectionNumber: section.number }
      "
      autosize
      [minRows]="descriptionRowsLength.min"
      [maxRows]="descriptionRowsLength.max"
      [maxlength]="descriptionMaxLength"
      [dgxSoftLimitValidation]="softCharacterLimit"
      aria-describedby="pathauthA11yBlurInstructions"
      data-dgat="pathway-author-section-155"
    ></textarea>
    <div
      *ngIf="
        sectionDescription?.invalid &&
        (sectionDescription.dirty || sectionDescription.touched)
      "
    >
      <div aria-live="assertive">
        <div
          class="form__invalid-msg ib rel"
          *ngIf="sectionDescription.errors.softLimit"
          [class.color-ebony-a61-hue]="
            !isDescriptionFieldInvalid(sectionDescription)
          "
          [@dfSlideFadeInLeft]
        >
          <df-icon size="small" icon="arrow-down-right"></df-icon>
          <span
            class="guts-p-l-half"
            [innerHtml]="i18n?.Pathways_SectionDescLimit"
          ></span>
        </div>
      </div>
    </div>
    <dgx-pathway-section-meta-data
      [hideDuration]="hideDuration"
      [section]="section"
      [hasFauxSubsection]="hasFauxSubsection"
    ></dgx-pathway-section-meta-data>
  </div>
  <dgx-pathway-author-subsections
    [subsections]="section.lessons"
    [hasFauxParent]="isFauxSection"
    [hasSingleParent]="isOnlyChild"
    [isFauxSubsection]="hasFauxSubsection"
    [parentIndex]="index"
  ></dgx-pathway-author-subsections>
</div>
