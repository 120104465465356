import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';
import { OrgBaseGuard } from './org-base.guard';
import { OrgInfo } from '@app/account/account-api.model';
import { SkillsPlatformGuard } from '@app/skills-platform-lxp-ui';
import { FtpFileUploadType } from '@app/ftp/ftp-api.model';

@Injectable({
  providedIn: 'root',
})
export class BulkUploadGuard extends OrgBaseGuard {
  constructor(
    authService: AuthService,
    router: Router,
    @Inject(WindowToken) windowRef: Window,
    private skillsPlatformGuard: SkillsPlatformGuard
  ) {
    super(authService, windowRef, router);
    this.routeDirectory = '/fileupload';
  }

  public canUpload(additionalPermissions: boolean = true) {
    return this.hasPermission && additionalPermissions;
  }

  public get skillsPlus(): boolean {
    return this.skillsPlatformGuard.hasPermission;
  }

  public override get hasPermission() {
    const { settings, permissions } =
      this.orgInfo || ({ settings: {}, permissions: {} } as OrgInfo);

    return (
      !settings.disableBulkUpload &&
      !settings.isClientProvider &&
      !settings.skillAnalyticsClient &&
      !settings.skillInventoryClient &&
      this.authUser.canBulkUpload &&
      (permissions.uploadCompletions ||
        permissions.uploadContent ||
        permissions.uploadGroups ||
        permissions.uploadOpportunities ||
        permissions.uploadRequiredLearning ||
        permissions.uploadUserUpdates ||
        permissions.uploadClientUser ||
        permissions.uploadRoles ||
        permissions.uploadSkills)
    );
  }

  public override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isBulkTaxonomySkills = state.url.includes(
      `/${FtpFileUploadType.Skills}`
    );

    if (!this.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/forbidden'));
    }
    //Don't allow bulk upload of skills if the user has Skills plus enabled
    return isBulkTaxonomySkills && this.skillsPlus
      ? this.router.parseUrl(this.resolveOrgUrl('/forbidden'))
      : true;
  }
}
