import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';

import { TagsApi } from '@app/tags/tag-api.model';
import { camelCaseKeys } from '@app/shared/utils/property';

/** Parameters passed to drive the {@link TagsFieldComponent} properties and its child {@link TagsEditComponent}
 * inputs. For more details see {@link TagsEditComponent} input properties. */
export interface TagsFieldParams {
  isCompleting?: boolean;
  customTooltip?: string;
  targetLevel?: number;
  topTags?: TagsApi.Tag[];
  hideTopTags?: boolean;
  hideTooltip?: boolean;
  hint?: string;
  required?: boolean;
  showHintAlone?: boolean;
  placeholder?: string;
  allowExistingOnly?: boolean;
}

/** A Formly-compatible wrapper for the dgx-select component. To create
 * one of these, pass {@link REGISTERED_FIELD_TYPE} to {@link DfFormFieldBuilder.foreignField}.
 */
@Component({
  selector: 'dgx-tags-field',
  templateUrl: 'tags-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public static REGISTERED_FIELD_TYPE = 'dgx-tags';

  public i18n = this.translate.instant(['TagsCtrl_SkillsPlaceholder']);
  public label: string;

  constructor(private translate: TranslateService) {
    super();
  }

  public ngOnInit() {
    if (typeof this.to.dfLabel === 'string') {
      // Note that we could support string-emitting observable and callback flavors of dfLabel here too
      // if needed, but not dynamic templates
      this.label = this.to.dfLabel;
    } else if (this.to.dfLabel) {
      console.warn('TagsFieldComponent supports only static string labels');
    }
  }

  public get params(): TagsFieldParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions?.params ?? {};
  }

  public handleTagsChange(pcTags: unknown) {
    const tags: TagsApi.Tag[] = camelCaseKeys(pcTags);
    this.formControl.setValue(tags);
    this.formControl.markAsDirty();
  }

  public handleFocusOut() {
    this.formControl.markAsTouched();
  }
}
