import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OrgAnnouncements {
  title: string;
  description: string;
  linkName: string;
  linkUrl: string;
  imageUrl: string;
  enabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrgAnnouncementsService {
  constructor(public http: NgxHttpClient) {}

  public getOrgAnnouncements(
    organizationId: number
  ): Observable<OrgAnnouncements> {
    // get the org setting that stores the org announcement data

    const announcement$ = this.http
      .get<any>('/organizations/GetSettings', {
        params: {
          organizationId,
          visibility: 'OrgHome',
        },
      })
      .pipe(
        map((results) => {
          let orgAnnouncementsEnabled = false;
          let orgAnnouncementLinks = {};
          results?.settings?.forEach((setting) => {
            switch (setting.id) {
              case 'OrgAnnouncementLinks':
                orgAnnouncementLinks = JSON.parse(setting.value || '{}');
                break;
              case 'OrgAnnouncements':
                orgAnnouncementsEnabled = setting.enabled;
                break;
            }
          });
          return {
            ...orgAnnouncementLinks,
            enabled: orgAnnouncementsEnabled,
          } as OrgAnnouncements;
        })
      );
    return announcement$;
  }

  public saveOrgAnnouncements(
    orgId: number,
    orgAnnouncement: OrgAnnouncements
  ) {
    const formData: any = new FormData();
    Object.keys(orgAnnouncement).forEach((key) => {
      if (key !== 'enabled') {
        const val = orgAnnouncement[key] === null ? '' : orgAnnouncement[key];
        formData.append(key, val);
      }
    });
    return this.http.post('/organizations/UpdateOrgAnnouncement', formData, {
      params: {
        organizationId: `${orgId}`,
        enabled: `${orgAnnouncement.enabled ?? false}`,
      },
    });
  }
}
