import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { PopoverComponent } from '@app/shared/components/popover/popover.component';
import { isKey, Key } from '@app/shared/key';
import { A11yService } from '@app/shared/services/a11y.service';
import { DfPopoverService, DfClosePopoverResult } from '@lib/fresco';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

/** A generic search auto-suggest component consisting of a search input and popover, whose contents are provided externally. */
@Component({
  selector: 'dgx-search-suggest',
  templateUrl: './search-suggest.component.html',
  styleUrls: ['./search-suggest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSuggestComponent implements OnInit, OnDestroy {
  public static readonly debounceDuration = 300;
  public static _idCounter = 0;
  public isTable: boolean = false;
  public instanceId = SearchSuggestComponent._idCounter++;
  public styles = 'h3';
  public i18n = this.translateService.instant(['Core_Search']);

  @Input() public ariaDescription: string;
  @Input() public isPopoverOpen: boolean;
  @Input() public searchTerm: string;
  @Input() public overrideClasses: string;
  @Input() public shouldRenderInGlobalSearchMode: boolean = false;
  @Output() public inputEnter = new EventEmitter<string>();
  @Output() public inputChange = new EventEmitter<string>();
  @Output() public isPopoverOpenChange = new EventEmitter<boolean>();

  @ViewChild('searchInput') public searchInputRef: ElementRef;
  @ViewChild('popoverComponent') private popoverComponent: PopoverComponent;

  private rawInputChange$ = new Subject<string>();
  private unlisteners: (() => void)[] = [];

  constructor(
    private a11yService: A11yService,
    private renderer: Renderer2,
    private popoverService: DfPopoverService,
    private searchTrackerService: SearchTrackerService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WindowToken) private windowRef: Window,
    private windowLayoutService: WindowLayoutService
  ) {}

  public ngOnInit() {
    this.styles = this.overrideClasses ?? this.styles;
    this.rawInputChange$
      .pipe(
        debounceTime(SearchSuggestComponent.debounceDuration),
        filter((i: string) => i.length !== 1), // search term must be empty (for default search) or have at least two characters
        distinctUntilChanged() // don't retrigger unless term has changed
      )
      .subscribe((v) => this.inputChange.emit(v)); // wrap the event handler instead of chaining; chaining would return an Observable
  }

  public ngOnDestroy() {
    if (this.isPopoverOpen) {
      this.isPopoverOpen = false;
      this.popoverService.close();
    }
    // complete this subject
    this.rawInputChange$.complete();
    this.removeListeners();
  }

  public submit(form: NgForm) {
    this.inputEnter.emit(form.value.term);
  }

  public onInputChange(event: Event) {
    this.rawInputChange$.next((event.target as HTMLInputElement).value);
  }

  public onIsPopoverOpenChange(isOpen: boolean) {
    this.isPopoverOpen = isOpen;
    this.isPopoverOpenChange.emit(isOpen); // forward menu event to parent component
    if (isOpen) {
      this.unlisteners.push(
        this.renderer.listen(
          this.searchInputRef.nativeElement,
          'keydown',
          this.onInputKeydown.bind(this)
        )
      );
    } else {
      this.removeListeners();
    }
  }

  public onInputFocus(event: FocusEvent) {
    this.searchTrackerService.searchInitiated();

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
  }

  public onSuggestionSelection(result: DfClosePopoverResult) {
    if (result?.itemViewModel) {
      this.searchTerm = result.itemViewModel.title;
      if (result.itemViewModel.linkUrl && !this.windowLayoutService.isMsTeams) {
        // Navigate to the item URL if present
        this.windowRef.location.assign(result.itemViewModel.linkUrl);
      } else {
        this.inputEnter.emit(this.searchTerm);
      }
    }
  }

  private onInputKeydown(event: KeyboardEvent) {
    if (isKey(event, Key.Tab)) {
      this.popoverService.close({ preventRefocus: true });
    }
    if (isKey(event, Key.Down)) {
      // since this is tracked on an INPUT element, the body class doesn't change automatically. Let's force it here
      event.preventDefault();
      this.document.querySelector('body').classList.remove('mouse-focus');
      this.document.querySelector('body').classList.add('keyboard-focus');
      this.a11yService.focusNextFocusable(
        this.popoverComponent.popover.nativeElement
      );
    }
  }

  private removeListeners() {
    for (const unlisten of this.unlisteners) {
      unlisten();
    }
    this.unlisteners = [];
  }
}
