import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GroupInfoCore } from '@app/groups/group-api';
import {
  AllRecommendees,
  AnyRecommendee,
  RecommendingItem,
} from '@app/recommendations/recommendations.model';
import { RecommendationsParams } from '@app/recommendations/recommendations.api';
import {
  isGroup,
  isUser,
} from '@app/recommendations/recommendations.type-guards';
import {
  RecommendationsService,
  RecommendationType,
} from '@app/recommendations/services/recommendations.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { RecommendationReference } from '@app/shared/models/core-api.model';
import { RecommendActions } from '@app/shared/models/recommendations.model';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { ContentDurationService } from '@app/shared/services/content/content-duration.service';
import { AnyLearningResource } from '@app/inputs/models/learning-resource.view-model';
import { Option } from '@app/shared/components/select/select.component';

/** A form component for creating recommendations and assigned learning. Since this includes its own modal
 * footer it is intended for embedding into a comprehensive sharing modal. */
@Component({
  selector: 'dgx-recommendation-form',
  templateUrl: 'recommendation-form.component.html',
  styleUrls: ['recommendation-form.component.scss'],
  // TODO: add expand/contract animations for dynamic fields when fresco supports them
})
export class RecommendationFormComponent
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit, OnChanges
{
  public static readonly defaultRecommendDays = 7;

  private static readonly maxCommentLength = 2000;
  private static readonly commentRemainingWarnLength = 20;

  @Input() public item: RecommendingItem;
  @Input() public selected: AnyRecommendee[];
  @Input() public usersOnly: boolean = false;

  @Output() public cancelModal: EventEmitter<void> = new EventEmitter();
  @Output()
  public closeModal: EventEmitter<RecommendationsParams> = new EventEmitter();

  @ViewChild('recommendeeSearch')
  private recommendeeSearchRef: TemplateRef<any>;
  @ViewChild('preview') private previewRef: TemplateRef<any>;
  @ViewChild('actionSelector') private actionSelectorRef: TemplateRef<any>;
  @ViewChild('dateDue') private dateDueRef: TemplateRef<any>;

  public model: {
    recommendees: AnyRecommendee[];
    comment?: string;
    shareableUrl?: string;
    actionOption?: RecommendActions;
    isAssigned?: boolean;
    dateDue?: Date;
  };
  public minutesToDisplay: string;
  public hoursToDisplay: string;
  public showLegacyDurationDisplay: boolean;
  public isLoading = true;
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public canAssign: boolean;
  public canAssignToSelectedUsers: boolean;
  public recommendingUsersCount: number;
  public actionOptions: RecommendActions[];
  public itemDisplayType: string;
  public i18n = this.translate.instant([
    'Core_OpenDatePicker',
    'recommendationForm_PickADate',
    'recommendationForm_OnlyOrgMembers',
  ]);

  constructor(
    private contentDurationService: ContentDurationService,
    private translate: TranslateService,
    private builder: DfFormFieldBuilder,
    private authService: AuthService,
    private recommendationsService: RecommendationsService,
    private cdr: ChangeDetectorRef,
    private displayTypePipe: DisplayTypePipe
  ) {
    super();
  }

  public get submitButtonText() {
    let resourceId: string;
    if (this.model.isAssigned) {
      resourceId =
        this.recommendingUsersCount === 1
          ? 'recommendationForm_submitButtonSingularAssign'
          : 'recommendationForm_submitButtonPluralAssign';
    } else {
      resourceId =
        this.recommendingUsersCount === 1
          ? 'recommendationForm_submitButtonSingular'
          : 'recommendationForm_submitButtonPlural';
    }
    return this.translate.instant(resourceId, {
      recommendeeCount: this.recommendingUsersCount,
    });
  }

  public get shouldShowDueDate() {
    return this.canAssign && this.model.isAssigned;
  }

  private static setCustomFieldValue(control: FormControl, value: any) {
    control.setValue(value, { emitModelToViewChange: false });
    control.markAsDirty();
    control.markAsTouched();
  }

  public ngOnInit() {
    this.initModel();
    this.setUserCount();
    const isMoreDetailsView = false;
    const isTileCardView = false;
    const translatedResourceType =
      this.item.resourceType === 'Episode'
        ? this.translate.instant('Core_Podcast')
        : this.translate.instant(`Core_${this.item.resourceType}`);
    this.showLegacyDurationDisplay =
      this.contentDurationService.setShowLegacyDurationDisplay(
        isMoreDetailsView,
        isTileCardView,
        translatedResourceType
      );
    const shouldDurationTimeBeCalculated =
      this.contentDurationService.shouldDurationTimeBeCalculated(
        translatedResourceType,
        isMoreDetailsView,
        isTileCardView
      );
    if (shouldDurationTimeBeCalculated) {
      const { hoursToDisplay, minutesToDisplay } =
        this.contentDurationService.calculateDurationTime(
          translatedResourceType,
          Number(this.item.durationMinutes),
          Number(this.item.durationHours),
          isTileCardView
        );
      this.hoursToDisplay = hoursToDisplay;
      this.minutesToDisplay = minutesToDisplay;
    } else {
      this.hoursToDisplay = null;
      this.minutesToDisplay = null;
    }
  }

  public ngOnChanges({ item }: SimpleChanges) {
    if (item) {
      const user = this.authService.authUser;
      const newItem = item.currentValue;

      if (user.defaultOrgInfo.permissions.assignLearning) {
        if (newItem.resourceType === 'Tag') {
          this.canAssign = user.canAssignRatings;
        } else if (newItem.resourceType === 'Opportunity') {
          this.canAssign = false;
        } else {
          this.canAssign = user.canAssignLearning;
        }
      } else {
        this.canAssign = false;
      }

      // TODO: Fix this when we have a unified way to display all item types
      this.itemDisplayType = this.displayTypePipe.transform(
        newItem.resourceType || newItem.referenceType
      );
      this.cdr.markForCheck();
    }
  }

  public ngAfterViewInit() {
    this.fields = this.buildFormFields();
    this.resetAssignLearning();
    this.cdr.detectChanges();
  }

  // linter fix, the RecommendingType is kind of insane, that has a todo to be refactored
  public asAnyLearningResource(item: RecommendingItem): AnyLearningResource {
    return item as AnyLearningResource;
  }

  public onRecommendeesChange(
    formControl: FormControl,
    recommendees: AnyRecommendee[]
  ) {
    RecommendationFormComponent.setCustomFieldValue(formControl, recommendees);
    this.resetAssignLearning();
    this.setUserCount();
  }

  public onActionChange(formControl: FormControl, action: Option) {
    RecommendationFormComponent.setCustomFieldValue(formControl, action);
  }

  public onDateChange(formControl: FormControl, date: Date) {
    RecommendationFormComponent.setCustomFieldValue(formControl, date);
  }

  public onDismiss() {
    this.cancelModal.emit();
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.closeModal.emit(this.createResult());
    }
  }

  public initModel() {
    this.model = {
      recommendees: this.selected?.length ? this.selected : [],
      comment: '',
      shareableUrl: this.recommendationsService.getShareUrl(this.item),
      // due date is optional, so don't force a value here
      dateDue: null,
      // If only 1 action is available, select it
      actionOption:
        this.item.actions?.length === 1 ? this.item.actions[0] : undefined,
      isAssigned: false,
    };
  }

  public createResult() {
    const result: RecommendationsParams = {
      userKeys: this.model.recommendees
        .filter((r) => isUser(r))
        .map((u: UserSearchItem) => u.userProfileKey),
      groupIds: this.model.recommendees
        .filter((r) => isGroup(r))
        .map((g: GroupInfoCore) => g.groupId),
      comment: this.model.comment,
      recommendationType: this.model.isAssigned
        ? RecommendationType.RequiredLearning
        : RecommendationType.Recommendation,
      dateDue: this.model.isAssigned ? this.model.dateDue : undefined,
      action: this.model.actionOption?.targetType,
      resource: this.item as RecommendationReference,
    };
    return result;
  }

  private resetAssignLearning() {
    const hasNonOrgUser =
      this.model.recommendees.filter(
        (r: AllRecommendees) => !r.organizationId && !r.groupId
      ).length > 0;
    this.canAssignToSelectedUsers = !hasNonOrgUser;
    this.cdr.markForCheck();
    if (hasNonOrgUser) {
      this.form.get('isAssigned').setValue(false);
    }
  }

  private buildFormFields() {
    return [
      this.builder
        .customField('recommendees', '', this.recommendeeSearchRef, {})
        .unwrapped() // unwrapped and unlabeled
        .validatedByIndexed({
          expression: (control) => control.value?.length > 0,
        }),
      this.builder
        .charCountedTextarea(
          'comment',
          'recommendationForm_WhyRecommending',
          RecommendationFormComponent.maxCommentLength
        )
        .withPlaceholder('recommendationForm_WhyRecommendingPlaceholder')
        .withHelp((context) => {
          const remaining =
            RecommendationFormComponent.maxCommentLength -
            context.formControl.value.length;
          return remaining > 0 &&
            remaining <= RecommendationFormComponent.commentRemainingWarnLength
            ? this.translate.instant('recommendationForm_CharacterCount', {
                characterCount: remaining,
              })
            : '';
        })

        .withDgatId('recommendationForm-7e4')
        .hiddenWhen(() => this.authService.authUser?.isRestrictedProfile),
      this.builder.customField('shareableUrl', '', this.previewRef).unwrapped(), // unwrapped and unlabeled
      this.builder
        .customField(
          'actionOption',
          this.item.actionsLabel?.label,
          this.actionSelectorRef
        )
        .asRequired()
        .hiddenWhen(() => !this.item.actions?.length),
      this.builder
        .toggleSwitch('isAssigned', 'recommendationForm_dateToggle')
        .withHelp('recommendationForm_dateToggleInfo')
        .withDgatId('recommendationForm-983')
        .hiddenWhen(() => !this.canAssign)
        .readonlyWhen(() => !this.canAssignToSelectedUsers),
      this.builder
        .customField('dateDue', 'recommendationForm_dateLabel', this.dateDueRef)
        .hiddenWhen(() => !this.shouldShowDueDate),
    ].map((b) => b.build()); // build 'em all at once
  }

  private setUserCount() {
    // for "Share with x people"
    this.recommendingUsersCount = this.model.recommendees.reduce(
      (sum, invitee) => sum + (isGroup(invitee) ? invitee.userCount : 1),
      0
    );
  }
}
