import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';

// misc
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { handleExperienceFocusOnSubmit } from '../../services/experience.utils';
import {
  ExperienceModel,
  ExperienceTypes,
  GlobalAddExperienceField,
} from '@app/user-content/user-input-v2/inputs/experience/experience.model';
import { ExperienceGlobalAddInputFacade } from '../../services/global-add/experience-global-add.facade';
import { EXPERIENCE_GLOBAL_ADD_DEFAULTS } from '../../services/experience-facade-base.service';

import {
  maxValidator,
  minValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import {
  ExperienceMapperService,
  ExperienceNotificationService,
  ExperienceService,
  ExperienceTrackerService,
} from '@app/user-content/user-input-v2/inputs/experience/services';
import {
  onFormControlUpdate,
  markFormAsTouched,
} from '@app/shared/utils/angular-form-field-helpers';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';
import { resetIfWhitespaceOnly } from '@app/user-content/user-input-v2/utils/form-field-helper';

@Component({
  selector: 'dgx-experience-global-add',
  templateUrl: './experience-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExperienceGlobalAddInputFacade,
    ExperienceMapperService,
    ExperienceNotificationService,
    ExperienceService,
    ExperienceTrackerService,
  ],
})
export class ExperienceGlobalAddComponent implements OnInit {
  @Input() public context: InputContext;
  @Input() public initialModel: any;

  public experienceGlobalAddForm: FormGroup;

  public i18n = this.translate.instant([
    'PositionFormCtrl_SaveExperience',
    'Core_Type',
    'Core_JobRoleLiteral',
    'Opportunities_TypeProject',
    'Opportunities_TypeMentorship',
    'Opportunities_TypeMenteeship',
    'Opportunities_TypeShadowing',
    'Opportunities_TypeStretchAssignment',
    'Opportunities_TypeOther',
    'Core_ExperienceType',
    'PositionFormCtrl_PositionTitle',
    'PositionFormCtrl_CompanyOrg',
    'PositionFormCtrl_CurrentPosition',
    'PositionFormCtrl_Seniority',
    'PositionFormCtrl_HoursPerWeek',
    'Core_AddExperience',
    'Core_Mentee',
    'Core_Mentor',
    'Core_Description',
    'Core_Skills',
    'Core_Title',
    'MediaFormCtrl_TitleRequired',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsMaxError',
    'Core_Name',
    'PositionFormCtrl_SelectType',
    'PositionFormCtrl_ExperienceTitle',
    'PositionFormCtrl_DescribeExperience',
    'PositionFormCtrl_MenteeName',
    'PositionFormCtrl_MentorName',
    'PositionFormCtrl_EditExperience',
  ]);
  public heading: string;
  public vm$: Observable<ExperienceModel>;

  public markSubFormsAsTouched = false;

  public get experienceTypes() {
    return ExperienceTypes;
  }

  constructor(
    public facade: ExperienceGlobalAddInputFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private globalAddTrackingService: GlobalAddTrackingService
  ) {
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    if (this.initialModel?.inputSubType) {
      this.facade.initializeViewModelWithExperienceType(
        this.context,
        this.initialModel.inputSubType
      );
    } else {
      await this.facade.initializeViewModel(this.context);
    }

    this.heading = this.context.isEditing
      ? this.i18n.PositionFormCtrl_EditExperience
      : this.i18n.Core_AddExperience;

    if (this.context.isEditing) {
      await this.facade.initializeEdit();
      this.initializeExpandedEditForm();
      return;
    }

    // Set up the form
    this.initializeForm();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/
  public onFormControlUpdate(field: GlobalAddExperienceField, value: any) {
    onFormControlUpdate(this.experienceGlobalAddForm, field, value);
  }

  /**
   * On form Submission check if the form is invalid
   */
  public async onSubmit(): Promise<void> {
    markFormAsTouched(this.experienceGlobalAddForm);
    this.markSubFormsAsTouched = true;
    this.cdr.detectChanges();

    if (this.experienceGlobalAddForm.invalid) {
      handleExperienceFocusOnSubmit(this.experienceGlobalAddForm.controls);
      return;
    }

    await this.facade.onSubmit(this.experienceGlobalAddForm);

    this.activeModal.close(this.facade.snapshot);
  }

  public getHoursPerWeekValidationMessage(): string {
    const field = this.experienceGlobalAddForm.get('hoursPerWeek');
    const minError = field.getError('minError');
    const maxError = field.getError('maxError');
    const requiredError = field.getError('required');
    return requiredError
      ? this.translate.instant('Core_FieldRequiredFormat', {
          fieldName: this.i18n.PositionFormCtrl_HoursPerWeek,
        })
      : (minError ?? maxError);
  }

  public loadInferredSkills() {
    this.facade.loadInferredSkills(
      this.experienceGlobalAddForm.get('title').value || '',
      this.experienceGlobalAddForm.get('description').value || ''
    );
  }

  public onDismiss(event: Event) {
    this.activeModal.dismiss();
    this.globalAddTrackingService.trackGlobalAddCancelled('Position');
  }

  private initializeExpandedEditForm(): void {
    this.experienceGlobalAddForm = this.formBuilder.group({
      isCurrent: this.facade.snapshot.isCurrent ?? false,
      experienceType: this.facade.snapshot.experienceType ?? '',
      title: this.facade.snapshot.title ?? '',
      orgName: this.facade.snapshot.orgName ?? '',
      hoursPerWeek: [
        this.facade.snapshot.hoursPerWeek ??
          EXPERIENCE_GLOBAL_ADD_DEFAULTS.hoursPerWeek,
        [
          Validators.required,
          minValidator(1, this.translate),
          maxValidator(168, this.translate),
        ],
      ],
      level: this.facade.snapshot.level ?? EXPERIENCE_GLOBAL_ADD_DEFAULTS.level,
      description: this.facade.snapshot.description ?? '',
      skills: [this.facade.snapshot.skills],
      menteeName: this.facade.snapshot.menteeName ?? '',
      mentorName: this.facade.snapshot.mentorName ?? '',
      dateRange: this.facade.snapshot.dateRangeForm ?? null,
    });
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm() {
    this.experienceGlobalAddForm = this.formBuilder.group({
      isCurrent: [],
      experienceType: [this.initialModel?.inputSubType ?? ''],
      title: ['', Validators.required],
      orgName: ['', Validators.required],
      hoursPerWeek: [
        EXPERIENCE_GLOBAL_ADD_DEFAULTS.hoursPerWeek,
        [
          Validators.required,
          minValidator(1, this.translate),
          maxValidator(168, this.translate),
        ],
      ],
      level: [EXPERIENCE_GLOBAL_ADD_DEFAULTS.level],
      description: [],
      skills: [],
      menteeName: [],
      mentorName: [],
      dateRange: [],
    });
  }

  transformInput(formControlName: string) {
    resetIfWhitespaceOnly(this.experienceGlobalAddForm, formControlName);
  }
}
