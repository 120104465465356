import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DgError } from '../models/dg-error';

/**
 * Replaces `catchError` in the pipeline.
 * The error will be *swallowed*, an immediately completed Observable of the correct
 * type will be returned, and the page will redirect.
 * @see {DgError}
 *
 * @param url
 */
export function catchAndRedirectError<T>(
  url: string
): (error: unknown) => Observable<T> {
  return catchError(() => {
    window.location.href = url;
    return of(false) as unknown as Observable<T>;
  });
}

/**
 * Replaces `catchError` in the pipeline.
 * The thrown DgError will then be caught by our GlobalErrorHandlerService.
 * @see {DgError}
 *
 * @param conditionalString - Either a string or a function that returns a string. **Either way, already-translated.**
 */
export function catchAndSurfaceError<T>(
  conditionalString: string | ((error: any) => string)
): (error: unknown) => Observable<T> {
  return catchError((error) => {
    // Get our friendly error message.
    if (typeof conditionalString === 'function') {
      conditionalString = conditionalString(error);
    }
    // Return empty for non-errors, otherwise throw our error with our new message.
    return !error
      ? EMPTY
      : throwError(() => new DgError(conditionalString as string, error));
  });
}
