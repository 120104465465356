import { Pipe, PipeTransform } from '@angular/core';
import { ResourceType } from '@app/shared/models/core-api.model';
import { resourceTypeIcon } from '../utils/common-utils/resource-type-icon';

/** Transforms a {@link ResourceType} into a fresco icon name for supported types (see code for supported types) */
@Pipe({
  name: 'resourceTypeIcon',
})
export class ResourceTypeIconPipe implements PipeTransform {
  transform(value: ResourceType): any {
    return resourceTypeIcon(value);
  }
}
