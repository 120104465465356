<dgx-modal
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">{{
    i18n.RequestRating_Header
  }}</ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div id="a11y-keywatch" class="dropdown-menu__min-height">
      <div class="grid">
        <div class="grid__col-12 guts-p-t-half" *ngIf="!rater">
          <label
            for="user-search"
            [innerText]="i18n.dgTagRating_SelectManager"
            class="guts-m-b-half"
          ></label>
          <dgx-user-search
            [recipients]="rater ? [rater] : []"
            [search]="loadPotentialRaters"
            placeholder="Core_SearchForPerson"
            [dgxAutofocus]
            (selectRecipient)="onSelect($event)"
            (removeRecipient)="onRemove()"
          ></dgx-user-search>
        </div>
        <div class="grid__col-12 guts-p-t-half" *ngIf="rater">
          <h2 class="h4 guts-p-b-half border-bottom">
            {{ i18n.TargetResourcesForm_Selected }}
          </h2>
          <dgx-removable-user-item
            [user]="rater"
            (remove)="onRemove()"
          ></dgx-removable-user-item>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <div class="l_flexbar">
      <button
        df-button
        dfButtonType="primary"
        class="l_flex--align-right"
        (click)="submit()"
        [disabled]="!rater"
        [textContent]="i18n.RequestRating_SendRequest"
        data-dgat="tag-rating-request-modal-57e"
      ></button>
    </div>
  </ng-container>
</dgx-modal>
