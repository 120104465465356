import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupsEditComponent } from './groups-edit.component';
import { DgxGroupSearchModule } from '../group-search/groups-search.module';
import { DgxSimpleFieldWrapperModule } from '../form/simple-field-wrapper/simple-field-wrapper.module';

@NgModule({
  imports: [CommonModule, DgxGroupSearchModule, DgxSimpleFieldWrapperModule],
  providers: [],
  declarations: [GroupsEditComponent],
  exports: [GroupsEditComponent],
})
export class DgxGroupsEditModule {}
