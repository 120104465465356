
<div class="guts-p-t-2">
  <div
    dgxSticky
    [stickyCustomClass]="'box-shadow bg-color-ebony-a03-hue'"
    [notStickyCustomClass]="'grid__col-12 guts-p-v-0'"
  >
    <div
      class="l_flexbar bg-color-ebony-a03 rounded--large m-l_flexbar-block guts-p-full-1"
      *ngIf="!hasNoFilter"
    >
      <div class="l_flexbar m-l_flexbar-block">
        <div class="guts-p-r-1 m-no-guts" data-dgat="orgReporting-645">
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxReportingRangePicker');
              inputs: getChartInputs('dgxReportingRangePicker')
            "
          ></ng-container>
        </div>
        <div class="m-guts-m-t-half" *ngIf="context === 'channel'">
          <div
            class="form__label ib"
            [innerHTML]="i18n.Core_Organizations"
          ></div>
          <dgx-combobox
            class="guts-p-l-half ib m-full-width"
            [ariaLabel]="i18n.OrgReportingCtrl_FilterByOrg"
            [isExactMatchesEnabled]="false"
            [selectedOption]="selectedOrg?.name"
            [options]="tenants"
            [placeholder]="i18n.OrgReportingCtrl_FilterByOrg"
            (selection)="onOrgSelection($event)"
            [hasNoResultsText]="i18n.OrgReportingCtrl_NoMatchingOrgs"
            labelKey="name"
            trackBy="organizationId"
            dgatInput="org-reporting-2ec"
          >
          </dgx-combobox>
        </div>
        <div
          class="m-guts-m-t-half"
          *ngIf="initComplete && groupOptions && context !== 'channel'"
        >
          <dgx-combobox
            class="m-full-width"
            [ariaLabel]="i18n.OrgReportingCtrl_FilterByGroup"
            [isExactMatchesEnabled]="true"
            [isMultiSelect]="true"
            [isFixedWidth]="true"
            [options]="groupOptions"
            [placeholder]="i18n.OrgReportingCtrl_FilterByGroup"
            (selections)="onGroupSelections($event)"
            [hasNoResultsText]="i18n.OrgReportingCtrl_NoMatchingGroups"
            labelKey="name"
            trackBy="groupId"
            (click)="trackInsightFilter('Group', $event)"
            data-dgat="orgReporting-c05"
            dgatInput="org-reporting-15d"
          >
            <dgx-user-count-warning-message
              [warningMessage]="warningMessage"
              message
            ></dgx-user-count-warning-message>
          </dgx-combobox>
        </div>
      </div>
      <div
        class="l_flex--align-right l_flex--align-right--no-mobile guts-p-l-2 m-block m-guts-p-l-0 m-guts-p-t-half"
        *ngIf="permissions.canDownloadReports && context !== 'channel'"
      >
        <button
          df-button
          dfButtonType="passive"
          [routerLink]="['/orgs', orgId, 'downloadableReports']"
          [queryParams]="typedReportingParams"
          class="bg-color-white m-full-width"
          data-dgat="Organizations-Index-ae5"
        >
          <df-icon
            icon="arrow-down-tray"
            size="small"
            class="guts-m-r-half"
          ></df-icon>

          {{ i18n.OrgReportingCtrl_DownloadableReports }}
        </button>
      </div>
    </div>
  </div>
  <section class="guts-p-t-1" *ngIf="!showMore">
    <dgx-kpi-list
      class="guts-p-t-3"
      [kpiClass]="'grid__col-sm-3'"
      [kpis]="kpis"
      [kpisLoading]="kpisLoading"
      [kpisError]="kpisError"
      [kpiPlaceholder]="[0, 0, 0, 0]"
    ></dgx-kpi-list>
  </section>
  <div [ngClass]="{ 'guts-p-b-3': showMore }">
    <div *ngIf="!showMore && permissions.enableActiveUserInsights">
      <section class="grid__col-12">
        <div
          class="grid__cell box-shadow rounded--large guts-p-full-2 m-guts-p-full-1"
        >
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxActiveUserChart');
              inputs: getChartInputs('dgxActiveUserChart')
            "
          ></ng-container>
        </div>
      </section>
    </div>
    <div *ngIf="!showMore">
      <section class="grid__col-12">
        <div
          class="grid__cell box-shadow rounded--large guts-p-full-2 m-guts-p-full-1"
        >
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxLearningChart');
              inputs: getChartInputs('dgxLearningChart')
            "
          ></ng-container>
        </div>
      </section>
    </div>
    <div
      *ngIf="
        (!showMore || showMore === 'skillRatings') &&
        context === 'channel' &&
        orgId
      "
    >
      <section class="grid__col-12">
        <div
          class="grid__cell"
          [ngClass]="{
            'box-shadow rounded--large guts-p-full-2 m-guts-p-full-1': !showMore
          }"
        >
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxSkillRatingsChart');
              inputs: getChartInputs('dgxSkillRatingsChart')
            "
          ></ng-container>
        </div>
      </section>
    </div>
    <div [ngClass]="{ grid: !showMore }">
      <div class="grid__col-12 grid__col-sm-6">
        <section
          *ngIf="!showMore || showMore === 'popularProviders'"
          [ngClass]="{
            'l_flex-grow-vertical box-shadow rounded--large guts-p-full-2 m-guts-p-full-1':
              !showMore
          }"
        >
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxTopCompletionsChart');
              inputs: getChartInputs('dgxTopCompletionsChart')
            "
          ></ng-container>
        </section>
      </div>
      <div class="grid__col-12 grid__col-sm-6">
        <section
          *ngIf="!showMore || showMore === 'popularData'"
          [ngClass]="{
            'l_flex-grow-vertical box-shadow rounded--large guts-p-full-2 m-guts-p-full-1':
              !showMore
          }"
        >
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxPopularDataChart');
              inputs: getChartInputs('dgxPopularDataChart')
            "
          ></ng-container>
        </section>
      </div>
    </div>
    <div *ngIf="hasTopGroups">
      <section class="grid__col-12">
        <div
          class="grid__cell"
          [ngClass]="{
            'box-shadow rounded--large guts-p-full-2 m-guts-p-full-1': !showMore
          }"
        >
          <dgx-top-groups
            [orgId]="orgId"
            [startDate]="startDate.value"
            [endDate]="endDate.value"
            [context]="context"
            [location]="'Org Insights'"
          >
          </dgx-top-groups>
        </div>
      </section>
    </div>
    <div *ngIf="hasRecommendationsStatus">
      <section class="grid__col-sm-12 guts-m-b-2">
        <div
          class="grid__cell"
          [ngClass]="{
            'box-shadow rounded--large guts-p-full-2 m-guts-p-full-1': !showMore
          }"
        >
          <dgx-recommendations-status
            [orgId]="orgId"
            [groupId]="groupIdList"
            [location]="'Org Insights'"
          >
          </dgx-recommendations-status>
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="!showMore" class="footer-glasses"></div>
</div>
