import { Options } from '@angular-slider/ngx-slider';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  AfterViewInit,
  NgZone,
  OnInit,
} from '@angular/core';
import { LocaleService } from '@app/shared/services/locale.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-rating-slider',
  templateUrl: './rating-slider.component.html',
  styleUrls: ['./rating-slider.component.scss'],
})
export class RatingSliderComponent implements OnInit, AfterViewInit {
  @Input() public value: number;
  @Input() public options: Options;
  @Input() public autoFocus: boolean;

  @Output() public onRatingChange: EventEmitter<number> = new EventEmitter();

  public triggerFocus: EventEmitter<void> = new EventEmitter();
  public manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  public i18n = this.translate.instant(['Core_SelfRating', 'A11y_SliderDescription', 'A11y_SliderNavigation']);

  constructor(private ngZone: NgZone, private localeService: LocaleService, private translate: TranslateService) { }

  public ngOnInit(): void {
    // honor RTL language settings
    this.options.rightToLeft = this.localeService.isRTL;
    //set a11y
    this.options.ariaLabel = this.i18n.Core_SelfRating + this.i18n.A11y_SliderNavigation;
  }

  public ngAfterViewInit(): void {
    if (this.autoFocus) {
      // prevent setTimeout from triggering another round of CD
      this.ngZone.runOutsideAngular(() =>
        setTimeout(() => this.triggerFocus.emit())
      );
    }

    // fixes styling in IE
    this.manualRefresh.emit();
  }
}
