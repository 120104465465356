import { InjectionToken } from '@angular/core';

import type { Hls } from '../vendor/hls';

export type HlsLazy = Promise<typeof Hls>;
export const HLS_LAZY = new InjectionToken<HlsLazy>('HLS_LAZY');

let resolved: HlsLazy = null;
export function hlsLazySingleton(): HlsLazy {
  if (!resolved) {
    resolved = import('../vendor/hls').then((m) => m.Hls);
  }
  return resolved;
}
