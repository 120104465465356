<li
  *ngIf="warningMessage"
  class="listbox--option cursor-default guts-p-v-1 guts-p-h-1 pointer-events-none"
>
  <label
    class="l_flex full-width guts-p-v-1 guts-p-h-1 warning-message-container"
  >
    <df-icon icon="info" class="l_flex l_flex-center"></df-icon>
    <div class="l_flex guts-m-l-1" [innerHTML]="warningMessage"></div>
  </label>
</li>
