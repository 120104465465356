import { Observable, Subject, merge } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { CHUploadService } from '@degreed/content-hosting-data-access';
import { ResourceType } from '@app/shared/models/core-api.model';
import { FileUploadSetting } from '@app/uploader/uploader-api.model';
import {
  AzureUploadFileResult,
  CompleteAzureUploadResult,
  HostType,
} from '@app/content-hosting/data-access/models/upload-file.model';
import {
  UploadEventType,
  Uploader,
  UploadEvent,
  UploadProgressEvent,
} from '../../uploader';
import { FileChunkUploaderService } from '@app/uploader/file-chunk-uploader/file-chunk-uploader.service';

export class ContentHostingUpload implements Uploader<never> {
  public resourceType: ResourceType;
  public resourceId: number;
  constructor(
    private contentHostingUploadService: CHUploadService,
    private chunkUploader: FileChunkUploaderService,
    resourceType: ResourceType,
    resourceId?: number
  ) {
    this.resourceType = resourceType;
    this.resourceId = resourceId;
  }

  public getUploadSettings(): Observable<FileUploadSetting> {
    return this.contentHostingUploadService.getFileUploadLimits(
      this.resourceType
    );
  }

  public upload(file: File, options?: any): Observable<UploadEvent> {
    if (options.inputType === 'Article') {
      return this.uploadFileToAzure(file);
    }
  }

  private uploadFileToAzure(file: File): Observable<UploadEvent> {
    const progress$ = new Subject<UploadProgressEvent>();
    // Transform the chunk uploader interface, which has a progress callback and async result into a single
    // async event stream
    return merge(
      progress$,
      this.chunkUploader
        .uploadFile(file, 'Article', (progress) => {
          progress$.next({
            type: UploadEventType.Progress,
            progress: progress / 100, // normalize the chunk uploader's percentage progress to a fractional value
          });
        })
        .pipe(
          map((result: CompleteAzureUploadResult) => {
            const fileType = file.type.split('/').pop();
            const fileData: AzureUploadFileResult = {
              name: file.name,
              id: result.fileId,
              type: fileType,
              size: file.size,
              url: result.fileUrl,
              hostType: HostType.DegreedMedia,
            };

            const returnObject = {
              response: {
                result,
                azureUploadFileResult: fileData,
              },
              type: UploadEventType.Complete,
            } as UploadEvent;
            return returnObject;
          }),
          finalize(() => progress$.complete()) // we have to explicitly complete or the outer stream will stay open indefinitely
        )
    );
  }
}
