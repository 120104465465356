<dgx-upload-section
  [imageUrl]="imageUrl | async"
  [isHostImageUrl]="params.isHostImageUrl"
  [uploadAdapter]="params.uploadAdapter"
  (uploadSuccessEvent)="onImageUploadSuccess($event)"
  (deleteEvent)="onDelete()"
  [useCropper]="params.useCropper"
  [aspectRatio]="16 / 9"
  [secondaryAspectRatio]="16 / 5"
  [useBrowserReplace]="true"
  [setFocusManually]="params.setFocusManually"
  [showReplaceButton]="params.showReplaceButton"
>
</dgx-upload-section>
