import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface DfPopover {
  isOpen: boolean;
  isOpenChange: EventEmitter<boolean>;
}

export interface DfClosePopoverResult {
  itemViewModel?: any;
  preventRefocus?: boolean;
}

/** A simple service for tracking and managing popovers
 * to enforce singleton visibility behavior and allow
 * subscribers to get menu selections or other results from popovers
 */
@Injectable()
export class DfPopoverService {
  public _activePopover: DfPopover;
  public _close$ = new Subject<any>();

  /** Fires when the active popover is closed, providing an optional popover selection result */
  public get close$() {
    return this._close$ as Observable<any>;
  }

  public get activePopover() {
    return this._activePopover;
  }

  public set openPopover(popover: DfPopover) {
    if (this._activePopover && popover !== this._activePopover) {
      this.close();
    }
    this._activePopover = popover;
  }

  public close(result?: DfClosePopoverResult) {
    if (this._activePopover) {
      this._close$.next(result);
      this._activePopover = undefined;
    }
  }
}
