import { Injectable } from '@angular/core';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';
import { TagsApi } from '@app/tags/tag-api.model';
import { InferredSkillsModel } from '@app/user-content/user-input-v2/inputs/experience/experience.model';

@Injectable()
export class OutcomesService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_EditContentDuplicateError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public getInferredSkills(
    title: string,
    description?: string
  ): Promise<InferredSkillsModel> {
    const request$ = this.get<InferredSkillsModel>(
      '/tag/InferredSkillsByMetadata',
      {
        title,
        description: description || title,
        contentType: 'achievements',
      }
    ).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_GeneralError, e);
      })
    );
    return lastValueFrom(request$);
  }

  /**
   * Convert the inferred string[] into a partial TagsApi.Tag object.
   * BE is only passing name, this is needed for the tag-rendered. This is later mapped back to name as the BE needs
   * at least name to construct a full Tag object once received.
   * @param skills
   * @returns
   */
  public getMappedInferredSkills(skills: string[]): TagsApi.Tag[] {
    if (!skills?.length) return [];
    return skills.map(
      (skillName) => ({ title: skillName, name: skillName }) as TagsApi.Tag
    );
  }
}
