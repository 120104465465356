import { Injectable } from '@angular/core';
import { AdvancedFieldsetBuilderService } from './advanced-fieldset-builder.service';
import {
  FieldBuilderFactory,
  FieldType,
  FieldWrapperBuilder,
} from './field-builder-factory';
import { InputSessionFieldBuilderService } from './input-session-field-builder.service';
import { SkillsFieldBuilderService } from './skills-field-builder.service';
import { UserImageFieldBuilderService } from './user-image-field-builder.service';

@Injectable({ providedIn: 'root' })
export class ContentCatalogFormBuilderService extends FieldBuilderFactory {
  private builders = new Map<FieldType, FieldWrapperBuilder>([
    [FieldType.INPUT_SESSION_FIELD, this.bldInputFieldset],
    [FieldType.ADVANCED_FIELDSET, this.bldAdvanceFieldset],
    [FieldType.SKILLS_FIELD, this.bldSkillsField],
    [FieldType.USER_IMAGE_FIELD, this.userImageFieldset],
  ]);
  constructor(
    private bldAdvanceFieldset: AdvancedFieldsetBuilderService,
    private bldInputFieldset: InputSessionFieldBuilderService,
    private bldSkillsField: SkillsFieldBuilderService,
    private userImageFieldset: UserImageFieldBuilderService
  ) {
    super();
  }

  public getBuilder(type: FieldType): FieldWrapperBuilder {
    return this.builders.get(type);
  }
}
