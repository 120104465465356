import { HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { WebEnvironmentService } from '@dg/shared-services';
import { catchError, map, Observable, of, Subject } from 'rxjs';
import {
  Coach,
  CoachConversation,
  CoachConversationResponse,
  CoachInference,
  CoachRecommendationResponse,
  Feedback,
  Summary,
} from '../models/coach.model';
import { COACH_API } from '../shared/constants';

@Injectable()
export class CoachAPIService {
  private dataService = inject(NgxHttpClient);
  private skillSubject = new Subject<any>();
  private summaySubject = new Subject<any>();
  private webEnvironmentService = inject(WebEnvironmentService);
  public get fallBackAvatar(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/branding/degreed-logo-glasses.svg',
      true
    );
  }
  public skill$ = this.skillSubject.asObservable();
  public summary$ = this.summaySubject.asObservable();

  public createNewConversation(payload?: any): Observable<CoachConversation> {
    return this.dataService.post(COACH_API.CREATE_CONVERSATION, payload);
  }

  public deleteConversation(conversationId: string): Observable<void> {
    return this.dataService.delete(
      `${COACH_API.DELETE_CONVERSATION}/${conversationId}`
    );
  }

  public exportSummaryAsPDF(
    conversationId: number
  ): Observable<HttpResponse<Blob>> {
    return this.dataService.get(
      `${COACH_API.SUMMARIES}/${conversationId}/pdf`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  public getCoachById(coachId: string): Observable<Coach> {
    return this.dataService.get(`${COACH_API.GET_COACH}/${coachId}`);
  }

  public getCoachList(params?: any): Observable<Coach[]> {
    return this.dataService.get(COACH_API.COACH_LIST, { params });
  }

  public getConversationById(conversationId: number): Observable<any> {
    return this.dataService.get(
      `${COACH_API.GET_CONVERSATION}/${conversationId}`
    );
  }

  public getConversations(params: any): Observable<CoachConversation[]> {
    return this.dataService.get(`${COACH_API.GET_CONVERSATION}`, { params });
  }

  public getMasterCoach(): Observable<Coach> {
    return this.dataService
      .get(COACH_API.COACH_LIST, { params: { coachType: 'Master' } })
      .pipe(
        map((coaches: Coach[]) => coaches?.[0]),
        catchError((err: any) => of(err))
      );
  }

  public getFeedbackOptions(): Observable<any> {
    return this.dataService.get(COACH_API.GET_FEEDBACK_OPTIONS);
  }

  public getRecommendations(
    coachId?: number,
    conversationId?: number
  ): Observable<CoachRecommendationResponse> {
    return this.dataService.get(
      `${COACH_API.RECOMMENDATION}/${conversationId}`,
      {
        params: { coachId },
      }
    );
  }

  public getSkillReport(conversationId: number): Observable<CoachInference> {
    return this.dataService.get(COACH_API.SKILL_REPORT, {
      params: { conversationId },
    });
  }

  public getTopNConversation(
    coachId: string,
    topN: number = 4
  ): Observable<CoachConversationResponse> {
    return this.dataService.get(COACH_API.GET_CONVERSATION, {
      params: { topN, coachId },
    });
  }

  public sendFeedback(payload: any): Observable<Feedback> {
    return this.dataService.post(COACH_API.FEEDBACK, payload);
  }

  public setSummary(summary: Summary[]): void {
    this.summaySubject.next(summary);
  }

  public updateConversation(payload: any): Observable<CoachConversation> {
    return this.dataService.put(`${COACH_API.UPDATE_CONVERSATION}`, payload);
  }

  public updatePartialConversation(
    payload: any
  ): Observable<CoachConversation> {
    return this.dataService.put(`${COACH_API.UPDATE_PARTIAL}`, payload);
  }
}
