import { EventBus } from '@app/shared/services/event-bus';
import { pdfInit, PDFViewerEvent, pdfZoom } from './';
import { Injectable, ElementRef } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { loadAssets } from '../utils';
import { JS, WORKER, CSS } from './pdf-asset-urls';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

interface PDFRenderParams {
  fileUrl: string;
  containerEl: ElementRef;
}

export interface PDFZoomControls {
  zoomIn: () => void;
  zoomOut: () => void;
  currentScale$: Observable<number>;
}

const DEFAULT_SCALE_DELTA = 0.1;
export const MAX_SCALE = 10;
export const MIN_SCALE = 0.1;

/**
 * PDF Viewer Service
 */
@Injectable()
export class PDFViewerService {
  pdfjsLib: any;
  pdfjsViewer: any;
  pdfEventBus: any;
  pdfDocViewer: any;
  pdfLinkService: any;

  constructor(
    private envConfigService: ConfigurationService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private eventBus: EventBus
  ) {}

  /**
   * Renders pdf file to an element
   *
   * @param PDFRenderParams params
   */
  public renderPDF(params: PDFRenderParams) {
    // Load assets and then createPDF document
    return loadAssets({ JS, CSS, env: this.envConfigService })
      .then(() => this.createPDF(params))
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Creates the PDF using the PDFjs library
   *
   * @param PDFRenderParams params
   */
  private async createPDF(params: PDFRenderParams) {
    this.initViewer(params.containerEl);
    // Load document
    const loadDocument = this.pdfjsLib.getDocument({
      url: params.fileUrl,
    });

    const pdfDoc = await loadDocument.promise;
    // Document loaded, render with the viewer
    this.pdfDocViewer.setDocument(pdfDoc);
    this.pdfLinkService.setDocument(pdfDoc, params.fileUrl);
  }

  /**
   * Inits pdf viewer objects to prepare for loading the document
   *
   * @param ElementRef containerEL - Container elelement pdf is loaded into
   */
  private initViewer(containerEL: ElementRef) {
    this.pdfjsLib = window['pdfjsLib'];
    this.pdfjsViewer = window['pdfjsViewer'];
    this.pdfEventBus = new this.pdfjsViewer.EventBus();

    // Performs linking functions inside PDF, such as opening specified page, and external links
    this.pdfLinkService = new this.pdfjsViewer.PDFLinkService({
      eventBus: this.pdfEventBus,
    });

    this.pdfDocViewer = new this.pdfjsViewer.PDFViewer({
      container: containerEL.nativeElement,
      eventBus: this.pdfEventBus,
      linkService: this.pdfLinkService,
    });

    this.pdfLinkService.setViewer(this.pdfDocViewer);

    this.pdfEventBus.on('pagesinit', () => {
      this.eventBus.announce(pdfInit());
      this.pdfDocViewer.currentScaleValue = 'auto';
      this.updateScale(parseFloat(this.pdfDocViewer.currentScale.toFixed(3)));
    });

    // Set the worker js src
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = this.envConfigService.getCdnUrl(
      WORKER
    );
  }

  /**
   * Zooms in
   */
  public zoomIn() {
    let newScale = this.pdfDocViewer.currentScale + DEFAULT_SCALE_DELTA;
    newScale = Math.min(MAX_SCALE, parseFloat(newScale.toFixed(3)));
    this.updateScale(newScale);
  }

  /**
   * Zooms out
   */
  public zoomOut() {
    let newScale = this.pdfDocViewer.currentScale - DEFAULT_SCALE_DELTA;
    newScale = Math.max(MIN_SCALE, parseFloat(newScale.toFixed(3)));
    this.updateScale(newScale);
  }

  /**
   * Gets controls for PDF Viewer
   */
  public get controls(): PDFZoomControls {
    return {
      zoomIn: this.zoomIn.bind(this),
      zoomOut: this.zoomOut.bind(this),
      currentScale$: this.eventBus.observableFor<number>(
        PDFViewerEvent.PDF_ZOOM
      ),
    };
  }

  private updateScale(scale: number) {
    if (scale !== this.pdfDocViewer.currentScaleValue) {
      this.eventBus.announce(pdfZoom(scale));
      this.pdfDocViewer.currentScaleValue = scale;
    }
  }

  /**
   * Handles user error notification and logging
   * @param error
   */
  private handleError(error) {
    this.notifier.showError(this.translate.instant('Core_GeneralLoadingError'));
    throw new DgError(error);
  }
}
