import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DfUtilitiesModule } from '../../utilities';
import { DfCheckboxModule } from './components/checkbox';
import { DfInputDecoratorModule } from './components/input-decorator';
import { DfTextInputModule } from './components/text-input';
import { DfTextareaModule } from './components/textarea';
import { DfAutofocusModule } from './directives/autofocus';
import { DfControlValueModule } from './directives/control-value';
import { DfInputDebounceModule } from './directives/input-debounce';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DfUtilitiesModule,
    // Components
    DfCheckboxModule,
    DfInputDecoratorModule,
    DfTextareaModule,
    DfTextInputModule,
    // Directives
    DfControlValueModule,
    DfInputDebounceModule,
    DfAutofocusModule,
  ],
  exports: [
    // Components
    DfCheckboxModule,
    DfInputDecoratorModule,
    DfTextareaModule,
    DfTextInputModule,
    // Directives
    DfControlValueModule,
    DfInputDebounceModule,
    DfAutofocusModule,
  ],
  declarations: [],
  providers: [],
})
export class DfInputsModule {}
