import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfSpinnerComponent } from './spinner.component';

@NgModule({
  declarations: [DfSpinnerComponent],
  imports: [CommonModule],
  exports: [DfSpinnerComponent],
})
export class DfSpinnerModule {}
