import { EmitEvent } from '@app/shared/services/event-bus';

/**
 * PDF Viewer Event Service
 *
 * Manages announced changes for the PDF Viewer.
 * It works along with the EventBus library which announced and allows subscribers to listen to events.
 *
 */

export enum PDFViewerEvent {
  PDF_INIT = 'pdfinit',
  PDF_RENDERED = 'pdfrendered',
  PDF_PAGECHANGING = 'pdfpagechanging',
  PDF_ZOOM = 'pdfzoom',
  PDF_ERROR = 'pdferror',
}

export class PDFEvent<K> implements EmitEvent<K> {
  constructor(public type: string, public data?: K) {}
}

export const pdfInit = () => new PDFEvent(PDFViewerEvent.PDF_INIT);
export const pdfZoom = (scale: number) =>
  new PDFEvent(PDFViewerEvent.PDF_ZOOM, scale);

export const pdfRendered = () => new PDFEvent(PDFViewerEvent.PDF_RENDERED);
export const pdfPageChanging = () =>
  new PDFEvent(PDFViewerEvent.PDF_PAGECHANGING);
export const pdfError = (error: any) =>
  new PDFEvent(PDFViewerEvent.PDF_ERROR, error);
