<div class="grid settings-family">
  <div class="grid__col-12 grid__col-sm-3">
    <div
      class="par par--small"
      [innerHTML]="i18n.User_FamilyAccountInstructions"
    ></div>
  </div>
  <div class="grid__col-12 grid__col-sm-6">
    <div class="family-view__container" *ngIf="!isLoading">
      <dgx-settings-family-empty
        *ngIf="!hasUserFamilyAccounts && !isAdding"
        [@fadeIn]
      ></dgx-settings-family-empty>
      <dgx-settings-family-accounts
        *ngIf="hasUserFamilyAccounts || isAdding"
        [accounts]="userFamilyAccounts"
        [isAdding]="isAdding"
        [@fadeIn]
      ></dgx-settings-family-accounts>
      <div class="family-view__controls" *ngIf="tenantOrgs?.length">
        <dgx-menu [menuConfig]="menuConfig" placement="bottom">
          <button
            df-button
            dfButtonType="clear"
            class="color-blue par par--small font-semibold"
            data-dgat="settings-family-5d7"
          >
            <df-icon
              icon="plus-circle"
              size="large"
              class="color-blue"
            ></df-icon>
            <span class="ib-v-middle guts-m-l-half">{{
              i18n.User_FamilyAccountAdd
            }}</span>
          </button>
        </dgx-menu>
      </div>
    </div>
    <div *ngIf="isLoading" class="family-view__container grid__cell">
      <dgx-skeleton
        class="block guts-m-t-2 guts-p-full-1-half"
        type="table"
        [columns]="3"
        [rows]="4"
        layout="thick"
      ></dgx-skeleton>
    </div>
  </div>
</div>
