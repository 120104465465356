/**
 * Return sorted dates, given as a string.  Invalid dates will either be sorted first, or last, depedning on sort direction.
 * This function should not be widely used, sorting dates really should be a function left to the database.
 *
 * @param a first date
 * @param b second date
 * @param isDescending order to compare dates
 * @returns
 */
export function sortDate(a: string, b: string, isDescending: boolean) {
  return !isDescending
    ? (new Date(a).getTime() || -Infinity) -
        (new Date(b).getTime() || -Infinity)
    : (new Date(b).getTime() || -Infinity) -
        (new Date(a).getTime() || -Infinity);
}
