import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LayoutAspect } from '@degreed/apollo-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'dgx-org-branding-preview',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="tw-hidden tw-space-y-4 lg:tw-block">
      <label class="tw-text-sm tw-font-semibold">Preview</label>
      <div class="tw-overflow-hidden tw-rounded-lg tw-shadow-xl">
        <div
          class="tw-flex tw-h-10 tw-items-center tw-gap-4 tw-bg-neutral-900 tw-px-4"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <span class="tw-size-[10px] tw-rounded-full tw-bg-red-800"></span>
            <span
              class="tw-size-[10px] tw-rounded-full tw-bg-yellow-600"
            ></span>
            <span class="tw-size-[10px] tw-rounded-full tw-bg-green-800"></span>
          </div>
          <div class="tw-grow"></div>
          <div
            class="tw-h-5 tw-flex-[8] tw-rounded-[20px] tw-bg-neutral-700"
          ></div>
        </div>

        @if (layout$ | async; as layout) {
          <div class="tw-relative tw-h-[544px]">
            <da-layout-v3 [configuration]="layout" [previewOnly]="true">
              <!-- Placeholder content area -->
              <div
                class="tw-absolute tw-inset-0 tw-m-4 tw-rounded-xl tw-border tw-border-dashed tw-border-neutral-400 tw-opacity-75 md:tw-mx-10 md:tw-my-8"
              >
                <svg
                  class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-stroke-neutral-900/10"
                  fill="none"
                >
                  <defs>
                    <pattern
                      id="pattern-1526ac66-f54a-4681-8fb8-0859d412f251"
                      x="0"
                      y="0"
                      width="10"
                      height="10"
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
                    </pattern>
                  </defs>
                  <rect
                    stroke="none"
                    fill="url(#pattern-1526ac66-f54a-4681-8fb8-0859d412f251)"
                    width="100%"
                    height="100%"
                  ></rect>
                </svg>
              </div>
            </da-layout-v3>
          </div>
        }
      </div>
    </div>
  `,
})
export class OrgBrandingPreviewComponent {
  @Input() layout$: Observable<LayoutAspect>;
}
