import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ArchiveCandidatesParams } from '@app/opportunities/opportunities-api.model';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

function CustomMaxLengthValidator(
  control: AbstractControl,
  maxLength: number
): boolean {
  return (control.value || '').trim().length <= maxLength;
}

@Component({
  selector: 'dgx-archive-candidates-modal',
  templateUrl: './archive-candidates-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveCandidatesModalComponent implements AfterViewInit, OnInit {
  @Input() public opportunityId: number;
  @Input() public selectedUserCount: number;
  public i18n = this.translate.instant([
    'Opportunities_ArchiveCandidatesModal_Header_Singular',
    'Opportunities_ArchiveCandidatesModal_Labels_Notify_Bulk',
    'Opportunities_ArchiveCandidatesModal_Labels_Notify_Singular',
    'Opportunities_ArchiveCandidatesModal_Labels_RejectionMessage',
    'Opportunities_ArchiveCandidatesModal_Labels_RejectionMessage_Placeholder',
    'Opportunities_ArchiveCandidatesModal_SubmitButtonText',
  ]);
  public fields: DfFormFieldConfig[] = [];
  public form: FormGroup = new FormGroup({});
  public model: ArchiveCandidatesParams = {
    notifyUnSelectedCandidates: false,
    messageFromSender: '',
  };
  public modalHeaderText =
    this.i18n.Opportunities_ArchiveCandidatesModal_Header_Singular;
  public notifyUnSelectedText =
    this.i18n.Opportunities_ArchiveCandidatesModal_Labels_Notify_Singular;
  public submitButtonText =
    this.i18n.Opportunities_ArchiveCandidatesModal_SubmitButtonText;
  private static readonly maxMessageLength = 250;
  private static readonly messageRemainingWarnLength = 50;

  constructor(
    private activeModal: NgbActiveModal,
    private builder: DfFormFieldBuilder,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  /**
   * Set plural translation strings.
   */
  public ngOnInit(): void {
    // bulk strings
    if (this.selectedUserCount > 1) {
      this.modalHeaderText = this.translate.instant(
        'Opportunities_ArchiveCandidatesModal_Header_Bulk',
        {
          number: this.selectedUserCount,
        }
      );
      this.notifyUnSelectedText =
        this.i18n.Opportunities_ArchiveCandidatesModal_Labels_Notify_Bulk;
    }
  }

  /**
   * Set up the form fields and trigger change detection.
   */
  public ngAfterViewInit() {
    this.fields = this.buildFormFields();
    this.cdr.detectChanges();
  }

  /**
   * Modal was canceled without any submission.
   */
  public onDismiss() {
    this.activeModal.dismiss();
  }

  /**
   * Modal was submitted.
   */
  public onSubmit() {
    // Sanity-checking. Our form should disable the submit button
    // as soon as the form is invalid.
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.activeModal.close(this.createResult());
    }
  }

  private buildFormFields() {
    return [
      this.builder.checkbox(
        'notifyUnSelectedCandidates',
        this.notifyUnSelectedText
      ),
      this.builder
        .optionalTextarea(
          'messageFromSender',
          'Opportunities_ArchiveCandidatesModal_Labels_RejectionMessage',
          ArchiveCandidatesModalComponent.maxMessageLength +
            ArchiveCandidatesModalComponent.messageRemainingWarnLength
        )
        // Updating on blur so that our validation will update as the user types.
        .updatedOn('change')
        // Recreation of validation on the mentees modal, where object notation was used.
        // Refactoring should see the two share this version of the function instead,
        // if not this max-length validator moved into Fresco.
        .validatedByIndexed({
          max: {
            expression: (control) =>
              CustomMaxLengthValidator(
                control,
                ArchiveCandidatesModalComponent.maxMessageLength
              ),
            message: (_, field) =>
              this.translate.instant(
                'OrgManage_Opportunities_Valid_MaxLength',
                {
                  number:
                    (field.formControl.value?.length || 0) -
                    ArchiveCandidatesModalComponent.maxMessageLength,
                }
              ),
          },
        })
        .withPlaceholder(
          'Opportunities_ArchiveCandidatesModal_Labels_RejectionMessage_Placeholder'
        )
        .hiddenWhen((_) => !this.model.notifyUnSelectedCandidates),
    ].map((b) => b.build()); // build 'em all at once
  }

  private createResult(): ArchiveCandidatesParams {
    return {
      notifyUnSelectedCandidates: this.model.notifyUnSelectedCandidates,
      messageFromSender: this.model.messageFromSender,
    };
  }
}
