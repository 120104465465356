import { TranslateService } from '@ngx-translate/core';
import { Filter } from '@app/shared/components/filter/filter.component';
import { SimpleItemViewModel } from '@app/shared/models/core-view.model';

export function toSelectedPlanTypes(filters: Filter[]) {
  let selectedPlanTypes = [];
  if (filters[0]?.filters[0]?.subitems) {
    selectedPlanTypes = filters[0]?.filters[0]?.subitems
      .filter((x) => x.isSelected)
      .map((type) => type.id);
  }

  return selectedPlanTypes;
}

/**
 * Makes filter options for plans
 *
 * @param translate
 * @param planTypes
 * @returns
 */
export function makeFilter(
  translate: TranslateService,
  planTypes: SimpleItemViewModel[]
): Filter[] {
  const i18n = translate.instant(['OrgPlans_Types']);
  return [
    {
      id: 'planTypes',
      altTitle: i18n.OrgPlans_Types,
      title: i18n.OrgPlans_Types,
      canSearch: false,
      ignoreCount: true,
      isDisabled: false,
      showEmptyOptions: true,
      sortSelectedFilters: true,
      filters: [
        {
          title: i18n.OrgPlans_Types,
          id: 'planTypes',
          subitems: planTypes,
        },
      ],
    },
  ];
}
