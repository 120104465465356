import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  AddressSuggestService,
  LocalityViewModel,
} from './address-suggest.service';

/**
 * An autosuggest input custom component which provides physical address suggestions from Azure's Geolocation service. This component will accept the same properties as a normal input (eg. `placeholder`, `(change)`, etc) and will be updated with ng classes like a normal input (eg. `ng-invalid`, `ng-touched`, etc.)
 * @param ariaDescribedby - `id` of an element used to describe the `input` field (helper text)
 * @param dgatInput - string used as the `data-dgat` attribute of the `input` field
 * @param select - Output event which passes the selected locality object
 *
 * (Warning: `ngDefaultControl` directive is REQUIRED when implementing for `ngModel` to work as expected)
 *
 * @example
 * <dgx-address-suggest
 *   id="locationAddress"
 *   ngDefaultControl
 *   [(ngModel)]="session.locationAddress"
 *   (change)="onChange()"
 *   [placeholder]="'InputSessionForm_SearchAddress' | translate"
 *   [requred]="true"
 *   (select)="onAddressSelect($event)"
 *   ariaDescribedby="locationAddressHelp"
 *   dgatInput="abc-123"
 * ></dgx-address-suggest>
 */
@Component({
  selector: 'dgx-address-suggest',
  templateUrl: './address-suggest.component.html',
  styleUrls: ['./address-suggest.component.scss'],
})
export class AddressSuggestComponent implements OnInit, AfterViewInit {
  // custom controls
  @Input() public dgatInput: string;
  @Input() public ariaDescribedby: string;
  @Output() public select: EventEmitter<LocalityViewModel> = new EventEmitter();
  @Output() public clear: EventEmitter<any> = new EventEmitter();

  // mimic <input ngmodel> controls (note `disabled` is handled by setDisabledState, no need to add it here)
  @Input('id') public hostId;
  @Input() public placeholder: string;
  @Input() public required: boolean;
  @Input() public initialValue?: string;
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  // Function registered to propagate a changes to the parent component
  public propagateChange: (e: Event) => void;
  public propagateTouch: (e: Event) => void;
  public disabled: boolean = false;

  public readonly minChars = 5; // the api won't respond with fewer than 5 characters anyway
  public isSearching: boolean = false;
  public hasBeenTouched: boolean = false;

  @ViewChild('field') public field: ElementRef;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private addressSuggestService: AddressSuggestService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  // set up ngControl.valueAccessor
  public writeValue(val: string) {
    if (!this.field) {
      return;
    }
    this.field.nativeElement.value = val;
  }
  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public ngOnInit(): void {
    this.ngControl?.control.updateValueAndValidity({ onlySelf: true });
    // since we are passing the id attribute in to the input and you can only have 1 id per html page.
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
  }

  public ngAfterViewInit(): void {
    // set initial value if provided
    if (this.initialValue) {
      this.field.nativeElement.value = this.initialValue;
    }
  }

  public suggestAddress = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        // No term? Emit clear event.
        if (!term) {
          this.clear.emit();
          return of([]);
        }
        // Too few characters? Empty array return.
        if (term?.length < this.minChars) {
          return of([]);
        }
        // Otherwise, get our address!
        return this.addressSuggestService.getAddressLocalities(term).pipe(
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );

  public formatResults(result: LocalityViewModel) {
    return result.locationAddress;
  }

  public onBlur(e: Event) {
    // fire touched event, only the first time, so that
    // our parent field can correctly assess this field as .touched.
    if (!this.hasBeenTouched) {
      this.propagateTouch(e);
      this.hasBeenTouched = true;
    }
    // emit blur event
    this.blur.emit(e);
  }

  public onSelectItem($event: NgbTypeaheadSelectItemEvent<LocalityViewModel>) {
    // gotta inform the form control model that it has changed
    this.ngControl?.viewToModelUpdate($event.item.locationAddress);

    this.select.emit($event.item);
  }
}
