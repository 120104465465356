import {
  ChangeDetectionStrategy,
  Component, Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'dgx-update-self-rating',
  templateUrl: './update-self-rating.component.html',
  styleUrls: ['./update-self-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateSelfRatingComponent {
  @Input() public bodyClasses: string;
  @Input() public bodyStyle: any;
  @Input() public isHeaderBorderless = true;
  @Input() public isHeaderCentered = true;
  @Input() public sourceTarget: EventTarget;
  @Input() public tag: any;
  @Input() public userData: any;
  @Input() public updateHistoryData?: any;

  public filteredHistoryData: any;

  public i18n = this.translate.instant(['Core_SelfRating']);

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.bodyClasses ??= 'guts-p-b-2 guts-p-h-2';
    // filter out cleared ratings PD-95068
    this.filteredHistoryData = this.updateHistoryData.filter(hd => !hd.title.includes('{{Level}}'));
  }
  public onSubmit(userData): void {
    this.activeModal.close(userData);
  }
}
