import { Injectable } from '@angular/core';
import { GroupInfoCore } from '@app/groups/group-api';
import {
  ActionFeedback,
  CourseEntry,
  InputCreationFeedback,
  InputCreationResult,
  UserInputCreationFeedback,
} from '@app/inputs/inputs-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { Institution } from '@app/user-content/user-input/course-form/course-form.model';
import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CourseApiInputEdit, CourseMappingToApi } from '../course.model';
import { NotifierService } from '@dg/shared-services';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { Merge } from '@app/shared/utils';
import {
  addOwnerDetailsToInput,
  addTempOwnerToInput,
} from '@app/user-content/user-input-v2/utils/content-owner-helper';

@Injectable({
  providedIn: 'root',
})
export class CourseService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_GetCourseError',
    'InputsSvc_EditContentDuplicateError',
    'CoursesSvc_ProviderError',
    'TargetsSvc_RetrieveError',
    'UserInputProvidersSvc_ProfileProvidersError',
    'UserCourseSvc_CourseError',
  ]);

  constructor(
    private translate: TranslateService,
    private userGroupListService: UserGroupListService,
    private notifier: NotifierService,
    private commentsApiService: CommentsApiService,
    public http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  // TODO: Remove Input if unneeded after migrations are completed
  public addCourse(
    params: CourseMappingToApi
  ): Promise<ActionFeedback<InputCreationResult, void>> {
    const request$ = this.post<ActionFeedback<InputCreationResult, void>>(
      '/inputs/addcourse',
      {
        ...params,
      },
      this.i18n.InputsSvc_AddItemError
    );
    return lastValueFrom(request$);
  }

  public async addGlobalCourse(
    params: CourseMappingToApi
  ): Promise<Merge<UserInputCreationFeedback, InputCreationFeedback>> {
    let promise;
    if (params.userInputId) {
      promise = this.updateUserCourse(params);
    } else if (params.inputId) {
      promise = this.addUserExistingCourse(params);
    } else {
      promise = this.addUserCourse(params);
    }
    return promise;
  }

  public async updateUserCourse(
    params: CourseMappingToApi
  ): Promise<Merge<UserInputCreationFeedback, InputCreationFeedback>> {
    const request$ = this.http
      .put<
        Merge<UserInputCreationFeedback, InputCreationFeedback>
      >('/userinputs/updateusercourse', params)
      .pipe(
        catchError((error) => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      );
    return lastValueFrom(request$ as any);
  }

  public async addUserExistingCourse(
    params: CourseMappingToApi
  ): Promise<Merge<UserInputCreationFeedback, InputCreationFeedback>> {
    const request$ = this.http
      .post<
        Merge<UserInputCreationFeedback, InputCreationFeedback>
      >('/userinputs/adduserexistingcourse', params)
      .pipe(
        catchError((error) => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      );
    return lastValueFrom(request$ as any);
  }

  public async addUserCourse(
    params: CourseMappingToApi
  ): Promise<Merge<UserInputCreationFeedback, InputCreationFeedback>> {
    const request$ = this.http
      .post<
        Merge<UserInputCreationFeedback, InputCreationFeedback>
      >('/userinputs/addusercourse', params)
      .pipe(
        catchError((error) => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      );

    return lastValueFrom(request$ as any);
  }

  /**
   * After globally adding a course, we need to add the comment to the course (if a comment was added)
   * @param {resourceId: number, resourceType: string, title: string, comment: string}
   * @returns
   */
  public async addGlobalCourseComment({
    resourceId,
    resourceType,
    title,
    comment,
  }): Promise<any> {
    return lastValueFrom(
      this.commentsApiService.addComment(
        { resourceId, resourceType, title },
        comment
      )
    );
  }

  // TODO: Create Type here for return on API on in Edit Course PD-93294
  public updateCourse(input): Promise<void> {
    const request$ = this.put<void>(
      '/inputs/updatecourse',
      input,
      this.i18n.InputsSvc_GeneralError
    ).pipe(
      catchError((e) => {
        throw new DgError(
          e.data.isDuplicate
            ? this.i18n.InputsSvc_EditContentDuplicateError
            : this.i18n.InputsSvc_GeneralError,
          e
        );
      })
    );
    return lastValueFrom(request$);
  }

  /**
   * For our Typeahead searches. Also used by getCourse to return the full CourseApiEdit
   * object.
   *
   * @param inputId
   * @param isCms
   * @returns
   */
  public getRawCourse(inputId: number, isCms = false): Observable<CourseEntry> {
    // TODO: This should really return CourseEntryApi.
    return this.get(
      '/inputs/getcourse',
      {
        inputId,
        isCms,
        useResourceImages: true,
      },
      this.i18n.InputsSvc_GetCourseError
    );
  }

  public getCourse(
    inputId: number,
    isCms = false
  ): Promise<CourseApiInputEdit> {
    const request$ = this.getRawCourse(inputId, isCms).pipe(
      // TODO: Jaime may be able to send this back on the getcourse call
      switchMap((input: any) => this.addOwnerToInput(input)),
      catchError((e) => {
        throw new DgError(this.i18n.TargetsSvc_RetrieveError);
      })
    );

    return lastValueFrom(request$);
  }

  public getCourses(
    term: string,
    isFormal: boolean,
    institutionId?: number
  ): Observable<AutocompleteItem[]> {
    const url = '/degreed/' + (isFormal ? 'courses' : 'informalcourses');
    return this.get(url, {
      term,
      instId: !isFormal ? institutionId : undefined,
      count: 500,
    });
  }

  public getInstitution(institutionId: number) {
    const request$ = this.get<Institution>(
      '/providers/getinstitutioninfo',
      { institutionId },
      this.i18n.UserInputProvidersSvc_ProfileProvidersError
    );

    return lastValueFrom(request$);
  }

  public getInstitutions(
    term: string,
    isAccredited: boolean,
    country: string
  ): Observable<AutocompleteItem[]> {
    return this.get<AutocompleteItem[]>(
      '/degreed/institutions',
      { term, isAccredited, country: isAccredited ? country : '' },
      this.i18n.CoursesSvc_ProviderError
    );
  }

  public getCountries(term: string): Observable<string[]> {
    return this.get('degreed/getcountries', { term });
  }

  /**
   * Gets or creates the appropriate owner for a given input and adds it to the input.
   *
   * @param input - Should *really* be CourseEntryApi, which is also what getRawCourse should return.
   */
  private addOwnerToInput(input: any): Observable<CourseApiInputEdit> {
    // Where we have a 'real' owner, get supporting details (userpic, name)
    if (!!input.primaryContactResourceId) {
      return addOwnerDetailsToInput<CourseApiInputEdit>(
        input,
        this.userGroupListService
      );
    }
    // Where we have a simple string owner, set the name but leave other fields null
    if (!!input.owner) {
      return addTempOwnerToInput<CourseApiInputEdit>(input);
    }
    // Where we have no owner, return input unchanged
    return of(input);
  }
}
