import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DfIconSize } from './icon-sizes.model';
import { DfIconService } from './icon.service';

@Component({
  selector: 'df-icon',
  styleUrls: ['./icon.component.scss'],
  template: ``,
})
export class DfIconComponent implements OnChanges {
  @Input() public icon: string;
  @Input() public size?: DfIconSize;
  @Input() public a11yText?: string;
  public ariaHidden = false;

  private svgIcon: SVGElement;

  constructor(
    private element: ElementRef,
    private IconService: DfIconService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.icon = changes.icon?.currentValue || this.icon;
    this.size = changes.size?.currentValue || this.size;
    this.a11yText = changes.a11yText?.currentValue || this.a11yText;

    if (this.icon) {
      this.cleanupExisting();

      this.svgIcon = this.createIcon({
        size: this.size,
        icon: this.icon,
        a11yText: this.a11yText,
      });
      this.setSvgContentAttr(this.svgIcon);
      this.element.nativeElement.appendChild(this.svgIcon);
    }
  }

  public cleanupExisting(): void {
    // Otherwise we end up with duplicates on the page if anything changes
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
  }

  public createIcon({
    size,
    icon,
    a11yText,
  }: {
    size?: DfIconSize;
    icon: string;
    a11yText?: string;
  }): SVGElement {
    const svgIcon = this.IconService.createIcon(icon, size);
    this.IconService.setSvgStyles({
      svg: svgIcon,
      a11yText,
      size,
    });
    return svgIcon;
  }

  private getContentAttr(): string {
    // this is a brittle, unideal solution that relies on the naming pattern on
    // the parent el to remain the same across Angular versions and may break
    // but it gets the job done, otherwise styles don't apply to the inserted
    // svg / container elements
    // TODO: if there's ever another option we should update this
    const attrs = this.element.nativeElement.attributes;
    for (let i = 0, l = attrs.length; i < l; i++) {
      if (attrs[i].name.startsWith('_nghost-')) {
        return `_ngcontent-${attrs[i].name.substring(8)}`;
      }
    }
  }

  private setSvgContentAttr(svgIcon) {
    svgIcon.setAttribute(this.getContentAttr(), '');
  }
}
