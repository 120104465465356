import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const maxAge = 30000;
@Injectable({ providedIn: 'root' })
export class RequestCache {
  public cache = new Map();

  public get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - maxAge; // ??
    const expired = isExpired ? 'expired ' : ''; // ??  where used?
    return cached.response;
  }

  public put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach((expiredEntry) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
}

// eslint-disable-next-line max-classes-per-file
export class CachedHttpRequest<T> extends HttpRequest<T> {}

/** An HTTP interceptor that conditionally provides caching, similar to angularjs's $http methods with
 *  <pre><code>options: {cache: true } </code></pre>
 */
// eslint-disable-next-line max-classes-per-file
@Injectable({ providedIn: 'root' })
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse =
      req instanceof CachedHttpRequest && this.cache.get(req);
    return cachedResponse
      ? of(cachedResponse)
      : this.sendRequest(req, next, this.cache);
  }

  public sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
