<div class="box-shadow rounded--large guts-p-full-2 m-guts-p-full-1">
  <div class="l_flexbar guts-m-b-1">
    <h1 class="h3 l_flex-grow">{{ chartName }}</h1>
    <div class="legend l_flexbar guts-p-t-quart">
      <div
        *ngFor="let type of activeLegend"
        class="guts-m-r-1-half ib"
        data-dgat="activity-insights-e0c"
      >
        <div
          class="legend__symbol--block"
          [ngStyle]="{ 'background-color': type.Color }"
        ></div>
        <div class="legend__label par par--small par--light guts-m-l-quart">
          {{ type.Label }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="l_flexbar m-l_flexbar-block guts-m-t-half m-guts-m-t-0 guts-p-full-1 bg-color-ebony-a03"
  >
    <div class="guts-p-r-1 m-guts-p-b-half">
      <dgx-select
        class="m-full-width"
        labelKey="Name"
        trackBy="Value"
        [ariaLabel]="i18n.PathwayActivity_SelectionAriaLabel"
        [options]="formData.dataType.options"
        [placeholder]="i18n.dgVBarChart_DisplayTypePlaceholder"
        [selectedItem]="formData.dataType.selected"
        (selection)="updateDataType($event)"
        dgatInput="activity-insights-56e"
      >
      </dgx-select>
    </div>
    <div class="guts-p-r-1 m-guts-p-b-half">
      <dgx-reporting-range-picker
        [appearance]="'dropdown'"
        [selection]="updateDateRange"
        [value]="formData.dateRange"
        [location]="'Opportunity Insights'"
      ></dgx-reporting-range-picker>
    </div>
  </div>
  <div class="guts-p-t-2-half vbargraph">
    <dgx-skeleton *ngIf="isLoading" type="linechart"></dgx-skeleton>
    <div
      [hidden]="!isLoading && !hasData"
      id="highcharts"
      class="highcharts"
    ></div>
    <div
      *ngIf="!isLoading && !hasData"
      class="grid grid--bleed guts-p-t-2-half"
    >
      <div class="grid__col-sm-12">
        <div class="center-text guts-p-b-1">
          <img
            [alt]="'ReportingContentSvc_NotEnoughActivity' | translate"
            [src]="noDataImage | blobifyUrl: true"
            class="image-layout--empty--large"
          />
        </div>
        <div
          *ngIf="formData.dataType.selected.Value !== 'enroll' || !groupId"
          class="center-text"
        >
          <p class="par font-semibold guts-p-b-0 guts-m-b-quart">
            {{ 'ReportingContentSvc_NotEnoughActivity' | translate }}
          </p>
          <p class="par--small par--light">
            {{ 'ReportingContentSvc_ReportsNotAvailable' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
