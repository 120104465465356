import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInsightsContentDetails } from '@app/insights/insights-api.model';
import { RouterComponents } from '@app/orgs/constants';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import {
  DateRange,
  DateRangeService,
} from '@app/shared/services/date-range.service';
import { ReportingContentService } from '@app/shared/services/reporting-content/reporting-content.service';
import { ReportingService } from '@app/shared/services/reporting/reporting.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { orderBy } from '@app/shared/utils/common-utils';
import { camelCaseKeys } from '@app/shared/utils/property';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { InsightsFeatureService } from '../services/insights-feature.service';

@Component({
  selector: 'dgx-org-insights-learning-summary-details',
  templateUrl: './org-insights-learning-summary-details.component.html',
  styleUrls: ['./org-insights-learning-summary-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgInsightsLearningSummaryDetailsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public readonly CLASS_NAME =
    RouterComponents.ORG_INSIGHTS_LEARNING_SUMMARY_DETAILS;

  public startDateRange: number;
  public endDateRange: number;
  public startDate;
  public endDate;
  public dateValidationMsg;
  public dateRange = { startDate: null, endDate: null };
  public dateRangeOptions;
  public hasResults;
  public learningSummaryResults;
  public resultsText;
  public topResultsText;

  public orgId: number;
  public isChannel: boolean;
  public dataTypeOptions = this.reportingContentService.getDataTypeOptions(
    false,
    false,
    true
  );
  // removing last item from list (points), for now
  public allInputDisplayTypes =
    this.reportingContentService.reportingInputTypes.slice(
      0,
      this.reportingContentService.reportingInputTypes.length - 1
    );
  public allInputTypeOptions = this.reportingContentService.allInputTypes;
  public groupId;
  public groupIdList: string;
  public originLoc;
  public inputType;
  public summaryType;
  public selectedInputType: { type: string; name: string } = {
    type: null,
    name: null,
  };
  public selectedDataType: { type: string; name: string } = {
    type: null,
    name: null,
  };
  public learningSummaryData = [];
  public groupOptions = [];
  public isLoadingGroups = true;
  public selectedGroup = { name: null };
  public selectedGroups = [];
  public permissions;
  public breadcrumbs: Breadcrumbs;
  public warningMessage: string;
  public i18n = this.translate.instant([
    'Core_CalendarView',
    'Core_DownloadDetails',
    'OrgReportingCtrl_DateMaxValidation',
    'OrgReportingCtrl_Insights',
    'OrgReportingCtrl_AllGroups',
    'OrgReportingCtrl_NoMatchingGroups',
    'OrgReportingCtrl_FilterByGroup',
    'OrgReportingCtrl_DownloadableReports',
    'dgVBarChart_InputTypePlaceholder',
    'dgVBarChart_DisplayTypePlaceholder',
    'reportingContentService_InternalContent',
    'OrgReportingCtrl_LearningSummary',
  ]);

  private maxResultsShown = 50;
  private readonly DATE_FORMAT = 'd MMM yyyy';

  // TODO: Remove AngularJS dependencies
  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private context: ContextService,
    private datePipe: DatePipe,
    private dateRangeService: DateRangeService,
    private insightsFeatureService: InsightsFeatureService,
    private pageTitle: Title,
    private reportingContentService: ReportingContentService,
    private reportingService: ReportingService,
    private route: ActivatedRoute,
    private router: Router,
    private tracker: TrackerService,
    private translate: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
  }

  /**
   * TODO: Remove once the ngx-org-routing LD flag is removed
   */
  private get queryParams() {
    return this.route?.snapshot?.queryParams
      ? this.route.snapshot.queryParams
      : undefined;
  }

  public ngOnInit(): void {
    const { defaultOrgInfo } = this.authService.authUser;

    this.orgId = defaultOrgInfo.organizationId;
    this.permissions = defaultOrgInfo.permissions;
    this.isChannel = this.context.urlHasContext(location.href, 'channel');
    this.pageTitle.setTitle(
      `${this.i18n.OrgReportingCtrl_LearningSummary} - ${this.i18n.OrgReportingCtrl_Insights} | Degreed`
    );
    // add groupId to make divider in dropdown
    this.allInputDisplayTypes = camelCaseKeys([
      ...this.allInputDisplayTypes.map((obj) => ({
        ...obj,
        groupingId: 0,
      })),
      ...this.allInputTypeOptions.map((obj) => ({
        ...obj,
        groupingId: 1,
      })),
    ]);

    this.getInitialUrlParams();
    this.setBreadcrumbTree();

    this.dateRangeOptions = {
      maxDate: new Date(), // Don't exceed today
      showWeeks: false,
    };

    // subscribe to url changes
    this.route.queryParams
      .pipe(debounceTime(100), this.takeUntilDestroyed())
      .subscribe((params) => {
        this.updateFromURLParams(params);

        this.loadReportData();
      });

    this.tracker.trackEventData({
      action: 'Org Learning Summary Details Viewed',
      properties: {
        Location: 'Learning Summary Detail Insights',
        OrganizationId: this.orgId,
      },
    });
  }

  /**
   * Gets the initial params for the route, pulled from the ngx route resolver
   */
  public getInitialUrlParams(): void {
    const params =
      this.route.snapshot?.data?.learningSummaryData &&
      this.route.snapshot.data.learningSummaryData;

    this.updateFromURLParams(params);
  }

  /**
   * Updates the component state from the given params
   */
  public updateFromURLParams(params: any): void {
    this.inputType = params.type;
    this.summaryType = params.summary;
    this.startDateRange = params.startdate;
    this.endDateRange = params.enddate || this.startDate;
    this.groupId = params.groupid;
    this.groupIdList = params.groupIdList;
    this.originLoc = parseInt(params.originLoc);
  }

  public getReport(requestObj: {
    orgId;
    startDate;
    endDate;
    inputTypes;
    groupId;
    groupIdList;
    term;
    location;
  }): Observable<UserInsightsContentDetails[]> {
    switch (this.summaryType) {
      case 'views': // Viewed
        return this.reportingService.getInsightsViewed(requestObj);
      case 'items': // Added/Completed (this case should fall through to the default)
      default:
        return this.reportingService.getInsightsCompletion(requestObj);
    }
  }

  public setSelectedPeriod = (dateRange: DateRange) => {
    this.dateRange = dateRange;
    this.startDate = {
      name: this.datePipe.transform(dateRange.startDate, this.DATE_FORMAT),
      value: new Date(dateRange.startDate).toUTCString(),
    };
    this.endDate = {
      name: this.datePipe.transform(dateRange.endDate, this.DATE_FORMAT),
      value: new Date(dateRange.endDate).toUTCString(),
    };
    this.goToState({
      startdate: new Date(this.startDate.value),
      enddate: new Date(this.endDate.value),
    });
  };

  public setBreadcrumbTree() {
    if (this.originLoc > 0) {
      this.breadcrumbs = [
        {
          label: this.translate.instant('Core_Groups'),
          link: `/groups/${this.originLoc}/groupInsights`,
        },
        {
          label: this.translate.instant('OrgReportingCtrl_LearningSummary'),
        },
      ];
    } else {
      this.breadcrumbs = [
        {
          label: this.translate.instant('Core_Insights'),
          link: `/orgs/${this.orgId}`,
        },
        {
          label: this.translate.instant('OrgReportingCtrl_LearningSummary'),
        },
      ];
    }
  }

  public getInputType(type) {
    return (
      this.allInputDisplayTypes.filter(
        (inputType) => inputType.type.toLowerCase() === type.toLowerCase()
      )[0] || null
    );
  }

  public getSummaryType(type) {
    const isPersonal = false;
    const isManager = false;
    const shouldFilterPoints = true;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    const dataTypeOptiomns = this.reportingContentService.getDataTypeOptions(
      isPersonal,
      isManager,
      shouldFilterPoints
    );

    return (
      dataTypeOptiomns.filter(
        (dataTypeOption) =>
          dataTypeOption.type.toLowerCase() === type.toLowerCase()
      )[0] || null
    );
  }

  public updateSelectedData(event) {
    this.goToState({
      summary: event?.type?.toLowerCase(),
    });
  }

  public updateSelectedInput(event) {
    this.goToState({
      type: event?.type?.toLowerCase(),
    });
  }

  public updateGroupId(event) {
    this.goToState({
      groupid: event.selectedItem,
    });
  }

  public updateGroupIds(options: any[]) {
    this.groupIdList =
      options?.length > 0
        ? options
            .map((o) => {
              return o.groupId;
            })
            .join(',')
        : '';
    this.goToState({
      groupIdList: this.groupIdList,
    });
  }

  public goToState(params): void {
    const queryParams = Object.assign({ ...this.queryParams }, params);
    queryParams.startdate = this.datePipe.transform(
      queryParams.startdate,
      'M/d/yyyy'
    );
    queryParams.enddate = this.datePipe.transform(
      queryParams.enddate,
      'M/d/yyyy'
    );

    const matches = this.windowRef.location.pathname;
    const url = this.router
      .createUrlTree([matches[0]], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      })
      .toString();

    this.router.navigateByUrl(
      `${this.windowRef.location.pathname}${url.replace('/', '')}`,
      { replaceUrl: true }
    );
  }

  public setGroups() {
    this.reportingService
      .getGroups(this.orgId)
      .pipe(take(1))
      .subscribe((data) => {
        const groups = this.insightsFeatureService.applyGroupFilter(data);
        this.warningMessage = this.insightsFeatureService.warningMessage;
        this.groupOptions = [...groups.sort((a, b) => orderBy(a, b, 'name'))];
        if (this.groupIdList) {
          const list = this.groupIdList.split(',').map((item) => Number(item));
          this.selectedGroups = this.groupOptions.filter((o) =>
            list.includes(o.groupId)
          );
        }
        this.isLoadingGroups = false;
      });
  }
  /**
   * Normalizes request parameters, then requests the initial report data
   */
  private loadReportData() {
    this.selectedDataType = this.summaryType
      ? this.getSummaryType(this.summaryType)
      : camelCaseKeys(this.dataTypeOptions[0]);

    this.selectedInputType = this.inputType
      ? this.getInputType(this.inputType)
      : this.allInputTypeOptions[0];
    this.setGroups();

    this.dateRange = {
      startDate: this.startDateRange,
      endDate: this.endDateRange,
    };
    this.startDate = {
      name: this.datePipe.transform(this.dateRange.startDate, this.DATE_FORMAT),
      value: this.dateRange.startDate,
    };
    this.endDate = {
      name: this.datePipe.transform(this.dateRange.startDate, this.DATE_FORMAT),
      value: this.dateRange.endDate,
    };
    const requestObj = {
      orgId: this.orgId,
      startDate: this.dateRangeService.getDateFormat(this.startDate.value),
      endDate: this.dateRangeService.getDateFormat(this.endDate.value),
      inputTypes: this.selectedInputType.type,
      groupId: this.groupId,
      groupIdList: this.groupIdList,
      term: null,
      location: 'Org Insights',
    };
    this.getReport(requestObj)
      .pipe(take(1))
      .subscribe((data) => {
        this.learningSummaryResults = data;
        if (this.learningSummaryResults.length > 0) {
          this.resultsText = this.topResultsText = this.translate.instant(
            this.learningSummaryResults.length >= this.maxResultsShown
              ? 'OrgReportingCtrl_TopResultsFormat'
              : 'OrgReportingCtrl_ResultsFormat',
            {
              count: Math.min(
                this.learningSummaryResults.length,
                this.maxResultsShown
              ),
            }
          );
          this.hasResults = true;
        } else {
          this.hasResults = false;
        }
        this.cdr.markForCheck();
      });
  }
}
