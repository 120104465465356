import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  GroupIdentifier,
  GroupPrivacy,
  GroupPrivacyLevel,
} from '@app/groups/group-api';
import { VisibilityItem, VisibilityOption } from './visibility.model';
import { Visibility } from './visibility.enum';
import { Option } from '../select/select.component';

/**
 * Container for the visibility component used in most modals
 */
@Component({
  selector: 'dgx-visibility',
  templateUrl: './visibility.component.html',
  styleUrls: ['./visibility.component.scss'],
  animations: [DF_COLLAPSE_EXPAND],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisibilityComponent implements OnInit {
  public optionItems: VisibilityItem[] = [];
  public selectedOption: VisibilityOption;

  /** The options for each of the sections in the visibility component. */
  @Input() public options: VisibilityOption[] = [];
  /** The selected visibility option. */
  @Input() public selected: Visibility;

  @Input() public groups?: GroupIdentifier[] = [];
  @Input() public groupPrivacyLevel?: GroupPrivacyLevel =
    GroupPrivacyLevel.Closed;

  @Input() public withLabel?: boolean = true;

  @Input() public labelText?: string = 'TargetForm_Visibility';

  @Input() public required: boolean = false;

  @Input() public excludedPrivacyLevels?: GroupPrivacy[] = [];

  /** Event emitter for when a selection is made */
  @Output()
  public onSelection: EventEmitter<VisibilityItem> = new EventEmitter();

  /** Event emitter for when groups are selected */
  @Output()
  public onGroupSelection: EventEmitter<GroupIdentifier> = new EventEmitter();
  /** Event emitter for when groups input is blurred */
  @Output() public onGroupInputBlur: EventEmitter<any> = new EventEmitter();

  /** Event emitter for when groups are removed */
  @Output()
  public onGroupRemove: EventEmitter<GroupIdentifier> = new EventEmitter();

  public handleSelect(option: Option): void {
    const selectedItem = option.name;

    this.selectedOption = this.options.find(
      (option) => option.item.name === selectedItem
    );

    if (this.selectedOption) {
      this.onSelection.emit(this.selectedOption.item);
    }
  }

  public handleGroupSelection($event: GroupIdentifier) {
    this.onGroupSelection.emit($event);
  }

  public handleGroupBlur() {
    this.onGroupInputBlur.emit();
  }

  public handleGroupRemove($event: GroupIdentifier) {
    this.onGroupRemove.emit($event);
  }

  public ngOnInit() {
    this.optionItems = this.options.map((option) => option.item);
    this.selectedOption = this.options.find(
      (option) => option.item.visibility === this.selected
    );
  }
}
