import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IdGeneratorService {
  private static _nextId = 0;

  /** Generates a unique numeric ID based on a static counter */
  generateNumberId(): number {
    return ++IdGeneratorService._nextId;
  }

  /** Generates a unique, optionally prefixed numeric ID as a string based on a static counter */
  generateStringId(prefix = ''): string {
    return prefix + this.generateNumberId();
  }
}
