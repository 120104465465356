import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

// Types
import { AuthUser } from '@app/account/account-api.model';

// Utils/Libs
import { camelCaseKeys } from '@app/shared/utils/property';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Services
import { AuthService } from '@app/shared/services/auth.service';
import { FollowingService } from '@app/shared/services/following.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';

// Directives
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { catchError } from 'rxjs/operators';
import { DgError } from '@app/shared/models/dg-error';

@Component({
  selector: 'dgx-user-list-form',
  templateUrl: './user-list-form.component.html',
})
export class UserListFormComponent
  extends SubscriberBaseDirective
  implements OnDestroy, OnInit
{
  @Input() public title: string;
  /** Common case: the `users` list is immediately available */
  @Input() public users: any[] = [];
  /** Less-common case: if the `users` list is not defined ahead of time, but you have an observable to retrieve the users while the modal UI is present and loading */
  @Input() public users$?: Observable<any>;
  @Input() public disableLink?: boolean = false;
  @Input() public showFollowBtns?: boolean = true;
  @Input() public showAnonLearners?: boolean = false;
  @Input() public userAttribute?: string;
  @Input() public modalInstance?;

  public authUser: AuthUser = this.authService.authUser;
  public isIframe: boolean = false; // e.g. Microsoft Teams App and extension/bookmarklet
  public i18n = this.translate.instant([
    'Core_Follow',
    'Core_FollowingButtonLabel',
    'Core_Following',
    'Core_ListItemsError',
    'Core_AnonymousLearnersNotShown',
    'Core_NoMembers',
    'Core_ScrollLimited',
  ]);
  // property names used in userAttribute that need the date pipe by default
  public dateAttributes = ['completionDate'];

  private loadingFollowers = false;
  private loadingUsers = false;

  public get loading() {
    return this.loadingFollowers || this.loadingUsers;
  }

  public get isOrgArea() {
    return window.location.href.indexOf('/orgs/') !== -1;
  }

  constructor(
    private followingService: FollowingService,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private translate: TranslateService,
    private windowLayoutService: WindowLayoutService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isIframe = this.windowLayoutService.isIframe;
    this.users$ ? this.getUsersBySubscription() : this.transformPassedUsers();
    this.handleFollowees();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // Follower & Followee:

  public followUser(
    userId: string,
    userName: string,
    isEngaged: boolean
  ): void {
    this.followingService.followUser(userId, userName, isEngaged).subscribe();
  }

  public unfollowUser(
    userId: string,
    userName: string,
    isEngaged: boolean
  ): void {
    this.followingService.unfollowUser(userId, userName, isEngaged).subscribe();
  }

  public isViewersFollowee(userId: string): boolean {
    return this.followingService.isViewersFollowee(userId);
  }

  public isViewer(userId: string): boolean {
    return !!(
      this.authUser && this.authUser.viewerProfile.userProfileId === userId
    );
  }

  public handleFollowees() {
    if (this.authUser && this.showFollowBtns) {
      this.loadingFollowers = true;
      this.followingService
        .getViewersFollowees(true)
        .subscribe(() => (this.loadingFollowers = false));
    }
  }

  // Users: Get & Process:

  public getUsersBySubscription() {
    this.loadingUsers = true;
    this.users$
      .pipe(
        this.takeUntilDestroyed(),
        catchError((error: Error) => {
          this.loadingUsers = false;
          return throwError(new DgError(this.i18n.Core_ListItemsError, error));
        })
      )
      .subscribe((results) => this.setUsers(results));
  }

  private setUsers(results: Partial<{ completions: any[] }>) {
    this.users = results.completions;
    this.transformUrls();
    this.loadingUsers = false;
  }

  // Utilities:

  public onClose() {
    this.activeModal.dismiss();
  }

  public transformPassedUsers() {
    this.users = camelCaseKeys(this.users);
    this.transformUrls();
  }

  private transformUrls() {
    this.users.forEach(
      (user) => (user.vanityUrl = `/profile/${user.vanityUrl}/overview`)
    );
  }
}
