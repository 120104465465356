<dgx-select
  class="full-width"
  [id]="hostId"
  [placeholder]="placeholder"
  [options]="timeZoneOptions"
  [selectedItem]="selectedTimeZone"
  [isDisabled]="disabled"
  (selection)="onSelectItem($event.title)"
  (blur)="onTouch(); blur.emit($event)"
  dgatInput="dgatInput"
></dgx-select>
