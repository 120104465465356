import { TemplateRef } from '@angular/core';
import { SortByValues } from '../services/content-catalog.service';
import {
  DataColumn,
  DataColumnDate,
} from '@app/shared/components/data-table/data-table';
import { A11yService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { FacetValue } from '@app/shared/models/core-api.model';

export function makeTableColumns(
  isBrokenLinksTable: boolean,
  currentSortId: SortByValues,
  templates: {
    title: TemplateRef<any>;
    completions: TemplateRef<any>;
    shares: TemplateRef<any>;
    takeaways: TemplateRef<any>;
    ratings: TemplateRef<any>;
    views: TemplateRef<any>;
    edit: TemplateRef<any>;
    usedIn?: TemplateRef<any>;
    hosted?: TemplateRef<any>;
    owner: TemplateRef<any>;
  }
): DataColumn[] {
  let columnsToDisplay;
  const titleCol = {
    label: 'Core_Title',
    headClasses: 'l_w30',
    bodyClasses: 'l_w30',
    template: templates.title,
  } as DataColumn;
  const typeCol = {
    label: 'Core_Type',
    prop: 'inputType',
    headClasses: 'l_w10',
  } as DataColumn;
  const contentOwnerCol = {
    label: 'OrgInternalContentCtrl_ContentOwner',
    headClasses: 'l_w15',
    template: templates.owner,
  } as DataColumn;
  const dateUpdatedCol = {
    prop: 'dateModified',
    label: 'OrgInternalContentCtrl_InputMetricDateUpdated',
    dateFormat: 'M/dd/yy',
    sortName: SortByValues.DateUpdated,
    canSort: true,
    type: 'date',
    headClasses: 'l_w15',
    hide: currentSortId !== SortByValues.DateUpdated,
  } as DataColumnDate;
  const dateAddedCol = {
    prop: 'dateCreated',
    label: 'OrgInternalContentCtrl_InputMetricDateAdded',
    dateFormat: 'M/dd/yy',
    canSort: true,
    sortName: SortByValues.DateCreated,
    type: 'date',
    headClasses: 'l_w15',
    hide: currentSortId !== SortByValues.DateCreated,
  } as DataColumnDate;
  const completionsCol = {
    template: templates.completions,
    label: 'OrgInternalContentCtrl_InputMetricCompletions',
    canSort: true,
    headClasses: 'l_w15',
    sortName: SortByValues.Completions,
    hide: currentSortId !== SortByValues.Completions,
  } as DataColumn;
  const recommendationsCol = {
    template: templates.shares,
    label: 'OrgInternalContentCtrl_InputMetricRecommendations',
    canSort: true,
    headClasses: 'l_w15',
    sortName: SortByValues.Recommendations,
    hide: currentSortId !== SortByValues.Recommendations,
  } as DataColumn;
  const takeawaysCol = {
    template: templates.takeaways,
    label: 'OrgInternalContentCtrl_InputMetricTakeaways',
    canSort: true,
    headClasses: 'l_w15',
    sortName: SortByValues.Takeaways,
    hide: currentSortId !== SortByValues.Takeaways,
  } as DataColumn;
  const ratingsCol = {
    template: templates.ratings,
    label: 'OrgInternalContentCtrl_InputMetricRating',
    canSort: true,
    headClasses: 'l_w15',
    sortName: SortByValues.NetRating,
    hide: currentSortId !== SortByValues.NetRating,
  } as DataColumn;
  const viewsCol = {
    template: templates.views,
    label: 'OrgInternalContentCtrl_InputMetricViews',
    canSort: true,
    headClasses: 'l_w15',
    sortName: SortByValues.Views,
    hide: currentSortId !== SortByValues.Views,
  } as DataColumn;
  const editCol = {
    label: '',
    bodyClasses: 'l_w5 center-text',
    template: templates.edit,
  };
  const hostedCol = {
    template: templates.hosted,
  } as DataColumn;
  const createdByCol = {
    label: 'OrgPlans_CreatedBy',
    prop: 'createdBy',
    headClasses: 'l_w15',
  } as DataColumn;
  const usedInCol = {
    label: 'Core_UsedIn',
    headClasses: 'l_w10',
    template: templates.usedIn,
  } as DataColumn;

  if (isBrokenLinksTable) {
    columnsToDisplay = [
      titleCol,
      typeCol,
      contentOwnerCol,
      createdByCol,
      usedInCol,
      dateUpdatedCol,
      dateAddedCol,
      completionsCol,
      recommendationsCol,
      takeawaysCol,
      ratingsCol,
      viewsCol,
      editCol,
    ];
    // Changing certain column widths for broken links table because of the extra column
    const sortableColumns = columnsToDisplay.filter((column) => column.canSort);
    sortableColumns.forEach((column) => (column.headClasses = 'l_w10'));
    columnsToDisplay[0].headClasses = 'l_w20';
    columnsToDisplay[0].bodyClasses = 'l_w20';
  } else {
    columnsToDisplay = [
      titleCol,
      hostedCol,
      typeCol,
      contentOwnerCol,
      dateUpdatedCol,
      dateAddedCol,
      completionsCol,
      recommendationsCol,
      takeawaysCol,
      ratingsCol,
      viewsCol,
      editCol,
    ];
  }
  return columnsToDisplay;
}

export function announceResultsCount(
  facets: any[],
  a11yService: A11yService,
  translateService: TranslateService
): void {
  // only use the type facet since it's the most inclusive
  const typeFacet = getTypeFacet(facets);
  let count = 0;

  if (typeFacet) {
    typeFacet.values?.forEach((type) => {
      count += type.Count;
    });
  }

  a11yService.announcePolite(
    translateService.instant('Core_ResultsFoundFormat', {
      count: count,
    })
  );
}

function getTypeFacet(facets: any[]): any {
  let typeFacetIndex = -1,
    typeFacet = null;
  for (let i = 0; i < facets.length; i++) {
    if (facets[i].id === 'Type') {
      typeFacetIndex = i;
    }
  }
  if (typeFacetIndex >= 0) {
    typeFacet = facets[typeFacetIndex];
  }
  return typeFacet;
}

// Sorts the facet values by name
export function sortFacet(
  facetValues: FacetValue[],
  userLocale: string
): FacetValue[] {
  return [...facetValues].sort((a, b) =>
    a.name.localeCompare(b.name, userLocale, { sensitivity: 'accent' })
  );
}
