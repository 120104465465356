import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DgError } from './../../shared/models/dg-error';
import { NgxHttpClient } from './../../shared/ngx-http-client';
import { NotifierService } from './../../shared/services/notifier.service';
import { TrackerService } from './../../shared/services/tracker.service';
import { OrgFaq } from './orgs.model';

@Injectable({
  providedIn: 'root',
})
export class OrgFaqService {
  constructor(
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public getFaqs(
    orgId: number,
    count: number,
    start: number,
    searchTerm: string
  ): Observable<OrgFaq[]> {
    return this.http
      .get<OrgFaq[]>('/organizations/faqs', {
        params: {
          id: orgId,
          numRows: count,
          startingRow: start,
          searchTerm: searchTerm,
        },
      })
      .pipe(
        catchError(
          (error: Error) =>
            error &&
            throwError(
              new DgError(
                this.translate.instant('OrgManage_FAQ_Error_GetFAQs'),
                error
              )
            )
        )
      );
  }

  public addFaq(faq: OrgFaq): Observable<OrgFaq> {
    return this.http.put<OrgFaq>('/organizations/addfaq', faq).pipe(
      tap(() => {
        this.notifier.showSuccess(
          this.translate.instant('OrgManage_FAQ_FaqCreated')
        );
        this.tracker.trackEventData({
          action: 'FAQ Created',
          category: faq.organizationId.toString(),
        });
      }),
      catchError(
        (error: Error) =>
          error &&
          throwError(
            new DgError(
              this.translate.instant('OrgManage_FAQ_Error_CreateFAQ'),
              error
            )
          )
      )
    );
  }

  public updateFaq(faq: OrgFaq): Observable<OrgFaq> {
    return this.http.put<OrgFaq>('/organizations/updatefaq', faq).pipe(
      tap(() => {
        this.notifier.showSuccess(
          this.translate.instant('OrgManage_FAQ_FaqUpdated')
        );
        this.tracker.trackEventData({
          action: 'FAQ Updated',
          category: faq.organizationId.toString(),
        });
      }),
      catchError(
        (error: Error) =>
          error &&
          throwError(
            new DgError(
              this.translate.instant('OrgManage_FAQ_Error_UpdateFAQ'),
              error
            )
          )
      )
    );
  }

  public deleteFaq(faq: OrgFaq) {
    return this.http.put('/organizations/deleteFaq', faq).pipe(
      tap(() => {
        this.notifier.showSuccess(
          this.translate.instant('OrgManage_FAQ_FaqDeleted')
        );
        this.tracker.trackEventData({
          action: 'FAQ Deleted',
          category: faq.organizationId.toString(),
        });
      }),
      catchError(
        (error: Error) =>
          error &&
          throwError(
            new DgError(
              this.translate.instant('OrgManage_FAQ_Error_DeleteFAQ'),
              error
            )
          )
      )
    );
  }
}
