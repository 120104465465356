import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ActionsConfig,
  EmbeddedLearningTag,
  GetPathwayGalleryResponse,
} from '@app/embedded-learning/embedded-learning-api.model';
import { EmbeddedLearningService } from '@app/embedded-learning/services/embedded-learning.service';
import { InputDetails } from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { appendQueryParams } from '@app/shared/utils/property';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-related-learning',
  templateUrl: './related-learning.component.html',
  styleUrls: ['./related-learning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedLearningComponent implements OnInit {
  @Input() inputType: string;
  @Input() config?: ActionsConfig = {};
  @Input() tags: EmbeddedLearningTag[];
  @Input() input: InputDetails;
  @Input() orgId: number;

  public i18n = this.translate.instant(['EmbeddedLearning_ComingSoon']);

  public headerTitle: string;
  public inputs: InputDetails[] | LearningResourceViewModel[];

  /**
   * The problem is we're iterating over an array of two types and angular doesn't like that,
   * we should really be mapping these types to a common type, or using a common type to cast the
   * types to, etc...
   */
  public get resources(): any[] {
    return this.inputs as any;
  }

  constructor(
    private translate: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private inputsService: InputsService,
    private embeddedLearningService: EmbeddedLearningService,
    private inputToLearningResourcePipe: InputToLearningResourcePipe,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.headerTitle = this.translate.instant(
      'EmbeddedLearning_Related' +
        this.titleCasePipe.transform(this.inputType) +
        's'
    );

    if (this.inputType !== 'pathway') {
      this.getRelatedInputs();
    } else {
      this.getRelatedPathways();
    }
  }

  public getUrlForResource(resource: LearningResourceViewModel): string {
    if (resource.resourceType.toLowerCase() === 'pathway') {
      return resource.internalUrl;
    }

    return resource.externalUrl;
  }

  private getRelatedInputs() {
    const searchFacets = [];
    searchFacets.push(
      {
        Id: 'Type',
        Values: [this.inputType],
      },
      {
        Id: 'Internal',
        Values: [this.orgId],
      }
    );
    const exclusionList = [
      {
        inputType: this.input.model.inputType.toLowerCase(),
        inputId: this.input.model.inputId,
      },
    ];
    const query = this.inputsService.find({
      terms: '',
      tags: this.tags.map((tag) => tag.label),
      facets: searchFacets,
      skip: 0,
      count: 3,
      includeProviders: false,
      defaults: 'Library',
      includeCompleted: false,
      sortBy: null,
      isAscending: null,
      exclusionList,
    });
    query.subscribe((data) => {
      this.inputsService.mapInputStatistics(data.inputs, data.statistics);

      const learningResourceViews: LearningResourceViewModel[] =
        data.inputs.map((input) =>
          this.inputToLearningResourcePipe.transform(input)
        );

      this.inputs = learningResourceViews;

      // data.inputs.forEach((input: any) => {
      //   let learningResourceView =
      //     this.inputToLearningResourcePipe.transform(input);
      //   (input as any).internalUrl = learningResourceView.externalUrl;
      //   // if this is a non embedded piece of content, it should open in a new tab
      //   input.openInNewTab =
      //     !learningResourceView.isHarvardSparkEmbeddedContent;
      // });

      // this.inputs = data.inputs;

      console.log(this.inputs);
      this.cdr.detectChanges();
    });
  }

  private getRelatedPathways() {
    const query = this.embeddedLearningService.getPathways(
      false,
      this.orgId,
      '',
      [],
      this.tags.map((tag) => tag.label),
      3,
      null
    );

    query.subscribe((response: GetPathwayGalleryResponse) => {
      const paths = response.paths;
      const learningResourceViews: LearningResourceViewModel[] = [];
      paths.forEach((path) => {
        let learningResourceView =
          this.inputToLearningResourcePipe.transform(path);
        learningResourceViews.push(learningResourceView);
      });

      this.inputs = learningResourceViews;
      this.cdr.detectChanges();
    });
  }
}
