import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  DfIconRegistry,
  DfIconThumbsDown16,
  DfIconThumbsUp16,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

/** Thumbs up and down rating button */
@Component({
  selector: 'dgx-thumbs',
  templateUrl: './thumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbsComponent implements OnInit, OnChanges {
  @Input() public currentRating: number;

  @Input() public size: string; // Set size="large" to make thumb icons bigger

  @Input() public inputTitle: string;

  @Output() public rate: EventEmitter<{
    rating: number;
  }> = new EventEmitter();

  public liked: boolean = false;
  public disliked: boolean = false;
  public i18n: { [key: string]: string };
  public dislikeTooltipText: string;
  public likeTooltipText: string;

  constructor(
    private translate: TranslateService,
    private iconRegistry: DfIconRegistry
  ) {
    this.iconRegistry.registerIcons([DfIconThumbsDown16, DfIconThumbsUp16]);
    this.i18n = this.translate.instant([
      'dgThumbs_Like',
      'dgThumbs_Dislike',
      'dgThumbs_RemoveDislike',
      'dgThumbs_RemoveLike',
    ]);
  }

  public ngOnInit(): void {
    this.dislikeTooltipText = this.disliked
      ? this.i18n.dgThumbs_RemoveDislike
      : this.i18n.dgThumbs_Dislike;
    this.likeTooltipText = this.liked
      ? this.i18n.dgThumbs_RemoveLike
      : this.i18n.dgThumbs_Like;
  }

  public ngOnChanges(changes): void {
    if (!isNaN(parseInt(changes.currentRating?.currentValue))) {
      if (this.currentRating === 0) {
        this.liked = false;
        this.disliked = false;
      } else if (this.currentRating === 1) {
        this.liked = true;
        this.disliked = false;
      } else {
        this.liked = false;
        this.disliked = true;
      }
    }
  }

  public toggleItem($event, type) {
    $event.preventDefault();
    $event.stopPropagation();
    let rating = 0;
    if (this.liked) {
      if (type === 'like') {
        // previously liked, so undo
        this.liked = false;
        rating = 0;
      } else {
        // previously liked, so toggle to dislike
        this.liked = false;
        this.disliked = true;
        rating = 2;
      }
    } else if (this.disliked) {
      if (type === 'like') {
        // previously disliked, so toggle to like
        this.disliked = false;
        this.liked = true;
        rating = 1;
      } else {
        // previously disliked, so undo
        this.disliked = false;
        rating = 0;
      }
    } else {
      if (type === 'like') {
        this.disliked = false;
        this.liked = true;
        rating = 1;
      } else {
        this.disliked = true;
        this.liked = false;
        rating = 2;
      }
    }
    this.rate.emit({ rating: rating });
  }
}
