import { Injectable } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { InputsService } from '@app/inputs/services/inputs.service';
import { InputIdentifier } from '@app/inputs/inputs-api.model';

import {
  ACADEMY_STATUS,
  COLLABORATOR,
  getAcademyFilters,
  getTotalCount,
} from '@app/profile/components/profile-collection/profile-collection-reactive-store/utils';
import {
  ProfileCollectionModel,
  CollectionItemType,
  ProfileCollectionFacets,
  CollectionItem,
  ProfileCollectionItem,
  InputFacet,
} from '@app/profile/profile-api.model';
import { SearchFacet } from '@app/search/search-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TrackerService } from '@app/shared/services/tracker.service';
import {
  SortKey,
  CollectionFilterV2,
} from '@app/profile/components/profile-collection/profile-collection-reactive-store';
import { PathwaySummaryModel } from '@app/pathways/pathway-api.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileCollectionService {
  public i18n = this.translateService.instant([
    'Core_AllTypesDisplayName',
    'Core_EventsDisplayName',
    'Core_CoursesDisplayName',
    'Core_BooksDisplayName',
    'Core_ArticlesDisplayName',
    'Core_VideosDisplayName',
    'Core_AssessmentsDisplayName',
    'Core_ExperienceDisplayName',
    'Core_PodcastsDisplayName',
    'Core_TasksDisplayName',
    'Core_PostsDisplayName',
    'Core_DegreesDisplayName',
    'Core_SkillsDisplayName',
    'Core_AccomplishmentsDisplayName',
    'Core_BadgesDisplayName',
    'Core_AwardsDisplayName',
    'Core_CertificatesDisplayName',
    'UserProfileSvc_ProblemAccessingCollection',
    'UserProfileSvc_ProblemUpdatingCollectionItem',
    'UserProfileSvc_ProblemDeletingCollectionItem',
    'ProfilePathways_Following',
    'ProfilePathways_Authoring',
    'ProfilePathways_Completed',
    'Core_AcademyContributor',
    'Core_Completed',
    'Core_InProgress',
  ]);

  public currentFilter = this.resetCurrentFilter();
  public typeFacetId = 'Type';

  public constructor(
    private translateService: TranslateService,
    private inputsService: InputsService,
    private http: NgxHttpClient,
    private trackerService: TrackerService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe
  ) {}

  public resetCurrentFilter(): { queryOffset: number; itemsPerPage: number } {
    return {
      queryOffset: 0,
      itemsPerPage: 20, // 4 per row vs 3 per row
    };
  }

  public getItemTypes(): CollectionItemType[] {
    return [
      {
        count: 0,
        displayName: this.i18n.Core_AllTypesDisplayName,
        name: null,
        type: null,
      },
      {
        count: 0,
        displayName: this.i18n.Core_EventsDisplayName,
        name: 'Event',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_CoursesDisplayName,
        name: 'Course',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_BooksDisplayName,
        name: 'Book',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_ArticlesDisplayName,
        name: 'Article',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_VideosDisplayName,
        name: 'Video',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_AssessmentsDisplayName,
        name: 'Assessment',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_ExperienceDisplayName,
        name: 'Position',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_PodcastsDisplayName,
        name: 'Episode',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_TasksDisplayName,
        name: 'Task',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_PostsDisplayName,
        name: 'Post',
        type: 'Content',
      },
      {
        count: 0,
        displayName: this.i18n.Core_DegreesDisplayName,
        name: 'Degree',
        type: 'Achievement',
      },
      {
        count: 0,
        displayName: this.i18n.Core_SkillsDisplayName,
        name: 'Skill',
        type: 'Achievement',
      },
      {
        count: 0,
        displayName: this.i18n.Core_AccomplishmentsDisplayName,
        name: 'Accomplishment',
        type: 'Achievement',
      },
      {
        count: 0,
        displayName: this.i18n.Core_BadgesDisplayName,
        name: 'Badge',
        type: 'Achievement',
      },
      {
        count: 0,
        displayName: this.i18n.Core_AwardsDisplayName,
        name: 'Award',
        type: 'Achievement',
      },
      {
        count: 0,
        displayName: this.i18n.Core_CertificatesDisplayName,
        name: 'Certificate',
        type: 'Achievement',
      },
    ];
  }

  public getExperienceTypes(): any[] {
    return [
      {
        count: 0,
        id: 'jobRole',
        name: 'jobrole',
      },
      {
        count: 0,
        id: 'project',
        name: 'project',
      },
      {
        count: 0,
        id: 'mentorship',
        name: 'mentorship',
      },
      {
        count: 0,
        id: 'menteeship',
        name: 'menteeship',
      },
      {
        count: 0,
        id: 'shadowing',
        name: 'shadowing',
      },
      {
        count: 0,
        id: 'stretchAssignment',
        name: 'stretchassignment',
      },
      {
        count: 0,
        id: 'other',
        name: 'other',
      },
    ];
  }

  public getSearchFacets(filter): InputFacet[] {
    const searchFacets = [];

    if (!!filter.tags?.length) {
      searchFacets.push({
        id: 'Category',
        values: filter.tags,
      });
    }

    if (!!filter.providers?.length) {
      searchFacets.push({
        id: 'Provider',
        values: filter.providers,
      });
    }

    if (!!filter.experienceType?.length) {
      searchFacets.push({
        id: 'ExperienceType',
        values: filter.experienceType,
      });
    }

    if (!!filter.type?.length) {
      const allTypes = filter.type;
      searchFacets.push({
        id: 'Type',
        values: allTypes.map((t) => `user${t}`),
      });
    }
    return searchFacets;
  }

  public getPathwaysAsCollectionItems(
    ownerId: number,
    filter: CollectionFilterV2,
    sorting: SortKey
  ): Observable<CollectionItem<PathwaySummaryModel>> {
    return this.getPathwaysOrPlansAsCollectionItems(
      ownerId,
      'pathway',
      '/Pathways/SearchPathwaysforUserProfile',
      filter,
      sorting
    );
  }

  /**
   * Gets academies that a user is contributing to has started or completed.
   * This api allows result search, filtering by status and contributor only flag, and sorting by date and title.
   * @param profileKey
   * @param filter
   * @param sorting
   * @returns academies collection items
   */
  public getAcademiesCollectionItems(
    profileKey: number,
    filter: CollectionFilterV2,
    sorting: SortKey
  ): Observable<CollectionItem<any>> {
    const type = 'Academy';

    return this.http
      .get('/userinputs/getuseracademies', {
        params: {
          requestedUserKey: profileKey,
          sortKey: sorting.key,
          sortOrder: sorting.direction,
          term: filter.term,
          facet: getAcademyFilters(filter),
        },
      })
      .pipe(
        map((data: any) => {
          const items = data.academies;
          const processedItems = items.reduce((allItems, i) => {
            allItems.push({
              itemId: i.academyId,
              userItemId: i.academyId,
              content: { ...i, resourceType: type, inputType: type },
            });
            return allItems;
          }, []);

          const collaboratorFacetValues = [
            {
              id: COLLABORATOR.Id,
              name: this.i18n.Core_AcademyContributor,
              value: false,
            },
          ];
          const academyStatusFacetValues = [
            {
              id: 'InProgress',
              name: this.i18n.Core_InProgress,
            },
            {
              id: 'Completed',
              name: this.i18n.Core_Completed,
            },
          ];

          const processedFacets = {
            itemTypes: [],
            facets: [
              {
                id: ACADEMY_STATUS.Id,
                name: ACADEMY_STATUS.Id,
                values: academyStatusFacetValues,
                label: 'Core_Status',
              },
              {
                id: COLLABORATOR.Id,
                name: COLLABORATOR.Id,
                values: collaboratorFacetValues,
                label: 'Core_AcademyContributor',
              },
            ],
          };

          return {
            items: processedItems,
            processedFacets,
            hasMoreItems: false,
          };
        })
      );
  }

  public getPlansAsCollectionItems(
    ownerId: number,
    filter: CollectionFilterV2,
    sorting: SortKey
  ): Observable<CollectionItem<any>> {
    // TODO: Is there something like "PlanSummaryModel" ?
    return this.getPathwaysOrPlansAsCollectionItems(
      ownerId,
      'target',
      '/Targets/SearchTargetsForUserProfile',
      filter,
      sorting
    );
  }

  private getPathwaysOrPlansAsCollectionItems(
    ownerId: number,
    contentType: string,
    url: string,
    filter: CollectionFilterV2,
    sorting: SortKey
  ): Observable<CollectionItem<any>> {
    // TODO: Pathway or Plan ? Type on this could be one or the other?
    const queryParams = {
      requestedUserKey: ownerId.toString(),
      useResourceImages: true,
      term: filter.term,
      facet: !!filter[`${contentType}Status`].length
        ? JSON.stringify([
            {
              id: `${this.titleCasePipe.transform(contentType)}Status`,
              values: filter[`${contentType}Status`],
            },
          ])
        : '',
      sortKey: sorting.key,
      sortDirection: sorting.direction,
    };
    return this.http
      .get(url, {
        params: queryParams,
      })
      .pipe(
        map((itemsAndFacets: any) => {
          const items = itemsAndFacets[`${contentType}s`];
          const { facets } = itemsAndFacets;

          const processedItems = items.reduce((allItems, i) => {
            allItems.push({
              inputType: contentType,
              contentCategory: contentType,
              contentType: contentType,
              itemId: i.resourceId,
              userItemId: i.resourceId,
              content: i,
            });
            return allItems;
          }, []);
          const facetValues = [
            { id: 'Following', name: this.i18n.ProfilePathways_Following },
            { id: 'Authoring', name: this.i18n.ProfilePathways_Authoring },
          ];
          if (contentType === 'pathway') {
            facetValues.push({
              id: 'Completed',
              name: this.i18n.ProfilePathways_Completed,
            });
          }

          const processedFacets = {
            itemTypes: [],
            facets: [
              {
                id: facets[0].id,
                name: facets[0].id,
                values: facetValues,
                label: 'Core_Status',
              },
            ],
          };
          // SearchTargetsforUserProfile EP doesn't currently support pagination
          return {
            processedFacets,
            items: processedItems,
            hasMoreItems: false,
          };
        })
      );
  }

  public getCollectionItems(
    facetFilters: CollectionFilterV2,
    ownerId: number,
    append?: boolean
  ): Observable<CollectionItem<any>> {
    const searchFacets = this.getSearchFacets(facetFilters);

    if (!append) {
      this.currentFilter.queryOffset = 0;
    }

    return this.getCollectionModel(
      ownerId,
      searchFacets,
      this.currentFilter.queryOffset,
      this.currentFilter.itemsPerPage,
      '',
      facetFilters.term,
      true,
      facetFilters.startDate,
      facetFilters.endDate
    ).pipe(
      tap((collectionModel: ProfileCollectionModel<any>) => {
        this.currentFilter.queryOffset += this.currentFilter.itemsPerPage;
        return collectionModel;
      }),
      switchMap((collectionModel: ProfileCollectionModel<any>) => {
        const results = {
          items: collectionModel.items,
          hasMoreItems: collectionModel.hasMoreItems,
          processedFacets: this.processResultFacets(collectionModel.facets),
        };
        const inputs = collectionModel.items
          .filter((item) => {
            return item.contentCategory === 'Input';
          })
          .map((item) => {
            return item.content;
          });
        this.inputsService.mapInputComments(
          inputs as unknown as InputIdentifier[], // check this
          collectionModel.comments
        );
        this.inputsService.mapInputStatistics(
          inputs,
          collectionModel.statistics
        );
        return of(results);
      })
    );
  }

  /**
   * Get the ProfileCollectionModel with 'count' number of ProfileCollectionItems for the supplied 'facets',
   * sorted by most recently added.
   *
   * @param userKey - The key for the user
   * @param facets - The array of CollectionFacet specifying which facets to return
   * @param start - the start index
   * @param count - How many experiences to return - -1 will return all items
   * @param tagName (Optional) - Get items that have been tagged with this tag name
   * @param terms (Optional) - Get items that match these search terms
   * @param doCache - cache the response
   * @param startDate - the start date for the collection items
   * @param endDate - the endDate for the collection items
   */
  public getCollectionModel(
    userKey: number,
    facets: InputFacet[],
    start: number,
    count: number,
    tagName: string = '',
    terms: string = '',
    doCache: boolean = false,
    startDate: string = null,
    endDate: string = null
  ): Observable<ProfileCollectionModel<any>> {
    if (tagName) {
      facets.push({
        id: 'Category',
        values: [tagName],
      });
    }
    return this.http
      .get('/userprofile/getcollectionitems', {
        params: {
          userKey,
          terms,
          facets: JSON.stringify(facets),
          count: count === -1 ? '' : count,
          skip: start,
          useResourceImages: true,
          startDate: startDate
            ? this.datePipe.transform(startDate, 'y/M/d')
            : null,
          endDate: endDate ? this.datePipe.transform(endDate, 'y/M/d') : null,
        },
        cache: doCache,
      })
      .pipe(
        catchAndSurfaceError(
          this.i18n.UserProfileSvc_ProblemAccessingCollection
        )
      );
  }

  public updateCollectionItems(items, tags: TagsApi.Tag[]): Observable<any> {
    return this.http
      .post('/userprofile/updatecollectionitems', {
        items,
        tags,
      })
      .pipe(
        catchAndSurfaceError(
          this.i18n.UserProfileSvc_ProblemUpdatingCollectionItem
        )
      );
  }

  public deleteCollectionItems(
    items: ProfileCollectionItem<any>[]
  ): Observable<void> {
    return this.http
      .post('/userprofile/deletecollectionitems', {
        items,
      })
      .pipe(
        tap(() => {
          this.trackerService.trackBatchEvents(
            items.map((item) => ({
              eventName: 'User Item Deleted',
              category: item.contentType,
              properties: {},
            }))
          );
        }),
        catchAndSurfaceError(
          this.i18n.UserProfileSvc_ProblemDeletingCollectionItem
        )
      );
  }

  public processResultFacets(facets: SearchFacet[]): ProfileCollectionFacets {
    const processedFacets = {
      itemTypes: [],
      facets: [],
    };
    // until we work out a better API interface for the facets, we need to make sure that we have a way of handling
    // exceptions to the rule of 'only show filters for facets that have values'
    const alwaysShowFacets = ['ExperienceType'];
    const itemTypes = this.getItemTypes();
    const typeFacetIndex = facets.findIndex(
      (facet) => facet.id === this.typeFacetId
    );
    // remove the type facet because it's handled separately
    const typeFacet = facets.splice(typeFacetIndex, 1)[0];
    const allType = this.getItemType(null, itemTypes);

    for (const value of typeFacet.values) {
      // Set the counts on the fresh itemTypes from the EP
      this.getItemType(value.name, itemTypes).count = value.count;
    }

    // Get the total count for the 'All Types' itemType
    const realTypes = itemTypes.filter((itemType) => itemType.name !== null);
    allType.count = getTotalCount(realTypes);

    // Sort so the itemTypes show in order of highest count to lowest in the filter menu
    processedFacets.itemTypes = itemTypes.sort((a, b) => b.count - a.count);

    // only include facets that have values to avoid empty filter menus
    const displayFacets = facets.filter(
      (facet) => !!facet?.values?.length || alwaysShowFacets.includes(facet.id)
    );
    processedFacets.facets = displayFacets.reduce((facets, f) => {
      facets.push({ ...f });
      return facets;
    }, []);

    const experienceTypeFacet = processedFacets.facets.find((f) => {
      return f.name === 'ExperienceType';
    });

    if (experienceTypeFacet) {
      experienceTypeFacet.values = this.getExperienceTypes();
      experienceTypeFacet.values = this.translateExperienceTypes(
        experienceTypeFacet.values
      );
    }
    return processedFacets;
  }

  private getItemType(name: string, itemTypes): CollectionItemType {
    if (!name) {
      return itemTypes[0];
    }

    return itemTypes.find((itemType) => itemType.name === name);
  }

  private translateExperienceTypes(
    experienceTypes: SearchFacet[]
  ): SearchFacet[] {
    /**
     * This can return the following i18n strings:
     *  - 'Opportunities_TypeProject',
     *  - 'Opportunities_TypeMentorship',
     *  - 'Opportunities_TypeMenteeship',
     *  - 'Opportunities_TypeShadowing',
     *  - 'Opportunities_TypeStretchAssignment',
     *  - 'Opportunities_TypeOther',
     */

    // rather than fix the casing for these types in the BE, we'll just map them here
    const typeMap = {
      jobrole: 'Core_JobRoleLiteral',
      stretchassignment: 'Opportunities_TypeStretchAssignment',
    };
    return experienceTypes.map((type) => {
      return {
        ...type,
        name: this.translateService.instant(
          typeMap[type.name] ||
            `Opportunities_Type${this.titleCasePipe.transform(type.name)}`
        ),
      };
    });
  }
}
