import { Component, Input } from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';

/**
 * Content Hosting Video Viewer Component
 */
@Component({
  selector: 'dgx-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent {
  @Input() public input: LearningResourceViewModel;

  constructor() {}
}
