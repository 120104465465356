<aside *ngIf="inputs?.length > 0" class="guts-m-b-1">
  <div class="skillnav">
    <h4 class="h4 guts-m-b-1">{{ headerTitle }}</h4>
    <div class="grid">
      <div
        *ngFor="let resource of resources"
        class="guts-m-b-2 grid__col-md-12 grid__col-sm-6 grid__col--bleed"
        data-dgat="relatedLearning-97e"
      >
        <div class="l_flex m-l_flex">
          <div>
            <a
              [href]="getUrlForResource(resource)"
              [attr.target]="
                !resource.isHarvardSparkEmbeddedContent ? '_blank' : undefined
              "
              data-dgat="relatedLearning-330"
            >
              <dgx-image-layout
                format="related"
                [imageSrc]="resource.imageUrl"
                [resourceId]="resource.resourceId"
                [resourceType]="resource.resourceType"
                [videoUrl]="resource.inputType === 'Video' ? resource.url : ''"
                [providerImage]="
                  resource.providerImageInfo
                    ? resource.providerImageInfo.svg
                    : undefined
                "
              >
              </dgx-image-layout>
            </a>
          </div>
          <div class="guts-m-l-1 l_flex m-l_flex l_flex-column">
            <a
              [href]="getUrlForResource(resource)"
              [attr.target]="
                !resource.isHarvardSparkEmbeddedContent ? '_blank' : undefined
              "
              class="par par--small font-semibold l_flex-grow"
              data-dgat="relatedLearning-66e"
              >{{ resource.title }}</a
            >
            <p class="par par--small par--light guts-m-b-half">
              {{ resource.model.durationDisplay }}
            </p>

            <!-- linter issues - social exclusions don't exist on related-learning component
              [exclusions]="socialExclusions"
            -->
            <dgx-social-counts
              [item]="resource"
              [config]="config"
              [context]="resource"
              class="social-counts guts-p-r-half guts-p-b-half"
            ></dgx-social-counts>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>
