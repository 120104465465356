<dgx-modal
  [canCancel]="false"
  [canCloseHeader]="true"
  [closeOnSubmit]="true"
  [useDefaultForm]="false"
  [isSubmitDisabled]="false"
  [submitButtonType]="'primary'"
  [isHeaderBorderless]="false"
  [useDefaultSubmitButton]="false"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <!-- creating our own close here as we want a different action on x than "continue"(cancel) -->
    {{ tag.title }} {{ i18n.dgTagRating_Checkpoints }}
  </ng-container>
  <!-- Modal Body -->
  <ng-container class="modal-body guts-p-v-1 guts-p-h">
    <ng-container *ngIf="checkpoints?.length > 0">
      <div
        *ngFor="let checkpoint of checkpoints; last as isLast; first as isFirst"
        data-dgat="tag-checkpoint-ratings-modal-d68"
        class="par par--small checkpoint__container"
        [ngClass]="{
          checkpoint__border: !isLast,
          checkpoint__first: isFirst,
          checkpoint__last: isLast
        }"
      >
        <div class="l_flex m-l_flex l_flex-grow">
          <div
            class="l_flex m-l_flex l_flex-grow l_flex-center-children __title"
          >
            {{ checkpoint.name || checkpoint.checkpointName }}
          </div>
          <div
            class="l_flex m-l_flex l_flex-center-children guts-p-full-0 guts-m-l-auto"
          >
            <span
              *ngIf="checkpoint.level"
              class="badge badge-pill badge-neutral"
            >
              {{ checkpoint.level }}</span
            >
            <span
              *ngIf="checkpoint.inProgress"
              class="badge badge-pill badge-attention"
            >
              {{ i18n.dgTagRating_InProgress }}</span
            >
            <span *ngIf="mayStartCheckpoint(checkpoint)">
              <button
                (click)="openCheckpointLink($event, checkpoint.checkpointUrl)"
                class="par par--small font-semibold color-blue"
                [textContent]="i18n.dgTagRating_TakeCheckpoint"
                data-dgat="tag-checkpoint-ratings-modal-a75"
              ></button>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Modal Body -->
  </ng-container>

  <ng-container class="modal-footer">
    <button
      dfButtonType="primary"
      (click)="done()"
      df-button
      type="submit"
      data-dgat="tag-checkpoint-ratings-modal-238"
    >
      {{ 'Core_Done' | translate }}
    </button>
  </ng-container>
</dgx-modal>
