/** Custom error type for global error handling */
export class DgError extends Error {
  constructor(
    message: string,
    public innerError?: Error,
    // Before adding more error types, consider that the provider of the error might not be the right object to determine how to present it
    public type: ErrorType = ErrorType.Toast
  ) {
    super(message);
    this.name = 'DgError';
  }
}

export enum ErrorType {
  Toast,
}

/**
 * Helper to identify DgErrors
 *
 * NOTE: `instanceof` check alone doesn't work when targeting es5, should work when we're targeting es2015+
 */
export function isDgError(e?: any): e is DgError {
  return e && (e instanceof DgError || e.name === 'DgError');
}
