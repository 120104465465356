import { camelCaseKeys } from '@app/shared/utils/property';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { WindowToken } from '@app/shared/window.token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '@app/notifications/notification-api.model';
import { EvaluationService } from '@app/tags/services/evaluation.service';

@Component({
  selector: 'dgx-tag-rating-endorsement-completed-modal',
  templateUrl: './tag-rating-endorsement-completed-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingEndorsementCompletedModalComponent implements OnInit {
  @Input() public set notification(notification: Notification) {
    this._notification = camelCaseKeys(notification);
  }
  public get notification() {
    return this._notification;
  }
  private _notification: Notification;
  public i18n: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    private evaluationService: EvaluationService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public ngOnInit(): void {
    this.i18n = this.translateService.instant(
      [
        'dgUserEvaluationEndorsement_goToApplication',
        'dgUserEvaluationEndorsement_requestedChanges',
        'dgUserEvaluationEndorsement_endosed',
      ],
      { tagName: this.notification.parameters?.tagName }
    );
  }

  public goToApplication() {
    this.activeModal.close();
    const tagName = this.notification.parameters?.tagName;
    const tagId = this.notification.parameters?.tagId;

    if (this.isEndorsed) {
      this.evaluationService.reviewEvaluationResults(tagName, tagId);
    } else {
      this.evaluationService.continueEvaluation(tagName, tagId);
    }
  }

  public get statusMessage(): string {
    return this.isEndorsed
      ? this.i18n.dgUserEvaluationEndorsement_endosed
      : this.i18n.dgUserEvaluationEndorsement_requestedChanges;
  }

  public get statusIcon(): string {
    return this.isEndorsed ? 'checkmark-circle' : 'exclamation-circle';
  }

  public get statusIconClass(): string {
    return this.isEndorsed
      ? 'eval-endorse__accept-review'
      : 'eval-endorse__reject-review';
  }

  private get isEndorsed(): boolean {
    return +this.notification.parameters?.endorsementStatus === 2;
  }
}
