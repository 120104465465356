import { Injectable, TemplateRef } from '@angular/core';
import { Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { DfDynamicContentProvider } from '../content-provider/providers';

import {
  DfCheckboxFieldConfig,
  DfCustomFieldConfig,
  DfFieldTemplateContext,
  DfForeignFieldConfig,
  DfFormFieldConfig,
  DfTextareaFieldConfig,
  DfTextFieldConfig,
  DfTextInputFieldConfig,
  DfToggleSwitchFieldConfig,
} from '../field-types';
import { countWords } from '../validation/helpers';
import { dfWordCountValidator } from '../validation/validators';
import { DfConfiguredFormFieldBuilder } from './configured-form-field.builder';
import { DfFieldGroupBuilder } from './field-group.builder';
import { DfRequirableFormFieldBuilder } from './requirable-form-field.builder';
import { DfTextInputFieldBuilder } from './text-input-field.builder';
import { DfTextareaFieldBuilder } from './textarea-field.builder';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * A field configuration builder service for generating various default configurations that can
 * be further customized via the builder pattern.
 * @description Each default configuration factory method returns a strongly typed configuration
 * builder for a specific field type.
 */
@Injectable({ providedIn: 'root' })
export class DfFormFieldBuilder {
  private static readonly defaultPasswordRegex =
    /^(?=.*\d)(?=.*[a-z,A-Z]).{8,}$/;

  constructor(private translate: TranslateService) {}

  /** Initializes the builder with a field configuration for a general text input field, defaulted as required.
   */
  public requiredTextInput(key: string, label: DfDynamicContentProvider) {
    return this.optionalTextInput(key, label).asRequired();
  }

  /** Initializes the builder with a field configuration for a general text input field, defaulted as optional.
   */
  public optionalTextInput(key: string, label: DfDynamicContentProvider) {
    const defaults: DfTextFieldConfig = {
      key,
      name: 'test___',
      type: 'input',
      className: 'form__col-1',
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: label,
        type: 'text',
        required: false,
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a text input Email field, defaulted as required.
   * @description For login or account creation validation applications, you may need to add an async validator
   * that checks for an existing account.
   */
  public email() {
    const defaults: DfTextFieldConfig = {
      key: 'email',
      name: 'email',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'email',
        dfLabel: 'Core_Email',
        placeholder: 'Core_EmailPlaceholder',
        autocomplete: 'email',
        required: true,
      },
      validators: {
        validation: [Validators.email],
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a current password input field, defaulted as required.
   */
  public currentPassword() {
    const defaults: DfTextFieldConfig = {
      key: 'currentPassword',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'password',
        dfLabel: 'Core_CurrentPassword',
        autocomplete: 'current-password',
        placeholder: 'Core_CurrentPasswordPlaceholder',
        required: true,
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a new password input field, defaulted as required.
   * @param overrides Optional overrides to apply to the default configuration.
   * @description @description If {@linkcode templateOptions.required} is true, a {@linkcode Validators.required} will
   * automatically be added. Credential fields tend to be more complex and vary between use cases. This
   * should be seen as a good vanilla base configuration to start with. You may need to add
   * more custom validators to check if the password has already been used recently, etc.
   */
  public newPassword() {
    const defaults: DfTextFieldConfig = {
      key: 'newPassword',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'password',
        dfLabel: 'Core_NewPassword',
        autocomplete: 'new-password',
        placeholder: 'Core_NewPasswordPlaceholder',
        required: true,
      },
      validators: {
        // By default passwords must be at least 8 characters long and include 1 number & 1 letter
        validation: [
          Validators.pattern(DfFormFieldBuilder.defaultPasswordRegex),
        ],
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a confirm password input field, defaulted as required.
   * @param overrides Optional overrides to apply to the default configuration.
   * @description If {@linkcode templateOptions.required} is true, a {@linkcode Validators.required}
   * will automatically be added. Credential fields tend to be more complex and vary between use
   * cases. This should be seen as a good vanilla base configuration to start with. You may need to
   * add more custom validators to check if the password has already been used recently, etc.
   * Normally, this would be used as part of a pair of password input fields, for which
   * you should consider using {@link passwordResetGroup} instead, which provides the combined
   * password matching validation.
   */
  public confirmPassword() {
    const defaults: DfTextInputFieldConfig = {
      key: 'confirmPassword',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'password',
        dfLabel: 'Core_ConfirmPassword',
        placeholder: 'Core_ConfirmPasswordPlaceholder',
        required: true,
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Creates a field group config containing both 'new password' and 'confirm password' fields,
   * which are both required.
   * @param newPasswordBuildFn Builder callback to call to create the new password field configuration
   * configuration. This receives a builder initialized by a call to the {@link newPassword} builder method.
   * @param confirmPasswordBuildFn Builder callback to call to create the password confirmation field
   * configuration. This receives a builder initialized by a call to the {@link confirmPassword} builder method.
   * @description IMPORTANT: The default form control keys are 'newPassword' and 'confirmPassword',
   * respectively. These are referenced in the global 'fieldMatch' validator used to verify the passwords
   * match. If you rename either of these keys via the 'withKey' method, that mechanism will break. You can
   * provide this functionality externally by registering your own global validator if needed, but it's
   * simpler to comply to the existing form names if possible.
   */
  public passwordResetGroup(
    newPasswordBuildFn: (
      builder: DfTextInputFieldBuilder
    ) => DfTextFieldConfig = (builder) =>
      builder.build() as DfTextInputFieldConfig,
    confirmPasswordBuildFn: (
      builder: DfTextInputFieldBuilder
    ) => DfTextFieldConfig = (builder) =>
      builder.build() as DfTextInputFieldConfig
  ) {
    // Create standard password confirmation field builder and pass to caller for further configuration
    const confirmPasswordConfig = confirmPasswordBuildFn(
      this.confirmPassword()
    );

    return new DfFieldGroupBuilder(this.translate, {
      validators: {
        validation: [
          {
            name: 'fieldMatch',
            options: {
              // errorPath isn't documented, but points the shared validator's output to the confirmation field
              errorPath: confirmPasswordConfig.key.toString(),
            },
          },
        ],
      },
      fieldGroup: [
        newPasswordBuildFn(
          // Create standard new password field builder and pass to caller for further configuration
          this.newPassword().withHelp('Core_PasswordPatternInfo')
        ),
        confirmPasswordConfig,
      ],
    });
  }

  /** Initializes the builder with a field configuration for a text input First Name field, defaulted as required
   * and styled with the `form__col-1` class for a two-column layout to accommodate an adjacent Last Name field.
   */
  public firstName() {
    const defaults: DfTextFieldConfig = {
      key: 'first',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'text',
        dfLabel: 'Core_FirstName',
        autocomplete: 'given-name',
        required: true,
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for  text input Last Name field, defaulted as required
   * and styled with the `form__col-1` class for a two-column layout to accommodate an adjacent First Name field.
   */
  public lastName() {
    const defaults: DfTextFieldConfig = {
      key: 'last',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'text',
        dfLabel: 'Core_LastName',
        autocomplete: 'family-name',
        required: true,
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a text input Title field, defaulted as required.
   */
  public title() {
    const defaults: DfTextFieldConfig = {
      key: 'title',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'text',
        dfLabel: 'Core_Title',
        required: true,
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a text input Subtitle field, defaulted as optional.
   * automatically be added.
   */
  public subtitle() {
    return this.title()
      .withKey('subtitle')
      .labeled('Core_Subtitle')
      .asOptional();
  }

  /** Initializes the builder with a field configuration for a text input Description field, defaulted as optional.
   * @param overrides Optional overrides to apply to the default configuration.
   * @description If {@linkcode templateOptions.required} is true, a {@linkcode Validators.required} will
   * automatically be added.
   */
  public descriptionShort() {
    const defaults: DfTextFieldConfig = {
      key: 'description',
      type: 'input',
      templateOptions: {
        defaultLayout: 'vertical',
        type: 'text',
        dfLabel: 'Core_Description',
      },
    };
    return new DfTextInputFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a textarea Description field, defaulted as an optional
   * field and limited to 30000 characters.
   * @param overrides Optional overrides to apply to the default configuration.
   * @description If {@linkcode templateOptions.required} is true, a {@linkcode Validators.required} validator will
   * automatically be added.
   */
  public descriptionLong() {
    const defaults: DfTextareaFieldConfig = {
      key: 'description',
      type: 'textarea',
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: 'Core_Description',
        maxLength: 30000,
        required: false,
      },
      validators: {},
    };
    return new DfTextareaFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a word-limited general textarea field displaying the words remaining
   * as a user types, defaulted as optional and limited to 500 words.
   */
  public wordCountedTextarea(
    key: string,
    label: DfDynamicContentProvider,
    maxWords: number = 500
  ) {
    const defaults: DfTextareaFieldConfig = {
      key,
      type: 'textarea',
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: label,
        required: false,
        maxLength: 30000,
        help: (context: DfFieldTemplateContext) => {
          // Calculates and displays words remaining below the textarea. The actual validation
          // of the length is handled separately by the validator
          const text = (context.formControl.value ?? '').toString();
          const wordCount = countWords(text);
          const wordsLeft = maxWords - wordCount;
          if (wordsLeft >= 0) {
            return this.translate.instant('Core_WordsRemainingFormat', {
              count: wordsLeft,
            });
          }
        },
      },
      validators: {
        validation: [dfWordCountValidator(maxWords)],
      },
    };
    return new DfTextareaFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a character-limited general textarea field displaying
   * the characters remaining as a user types, defaulted as optional and limited to 10,000 characters.
   */
  public charCountedTextarea(
    key: string,
    label: DfDynamicContentProvider,
    maxChars: number = 10000
  ) {
    const defaults: DfTextareaFieldConfig = {
      key,
      type: 'textarea',
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: label,
        maxLength: maxChars,
        rows: 4,
        help: (context: DfFieldTemplateContext) => {
          // Calculates and displays characters remaining below the textarea.
          // Note that unlike the word counted textarea, this uses the built in
          // maxLength attribute to limit, so we don't need a validator
          const text = (context.formControl.value ?? '').toString();
          const charCount = text.length;
          const charsLeft = maxChars - charCount;
          if (charsLeft >= 0) {
            return this.translate.instant('Core_CharsRemainingFormat', {
              count: charsLeft,
            });
          }
        },
      },
    };
    return new DfTextareaFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder with a field configuration for a general required textarea field displaying
   * the characters remaining as a user types, defaulted as optional and limited to 10,000 characters.
   */
  public requiredTextarea(
    key: string,
    label: DfDynamicContentProvider,
    maxLength = 10000
  ) {
    const defaults: DfTextareaFieldConfig = {
      key,
      type: 'textarea',
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: label,
        maxLength,
        required: true,
      },
    };
    return new DfTextareaFieldBuilder(this.translate, defaults);
  }

  public optionalTextarea(
    key: string,
    label: DfDynamicContentProvider,
    maxLength = 10000
  ) {
    return this.requiredTextarea(key, label, maxLength).asOptional();
  }

  /** Initializes the builder for creating a field group.
   * @description A Formly field group is a collection of related fields. They may be related
   * functionally, such as a set of password reset fields that validate together, or presentationally
   * by providing an optional class name
   * @param fields The set of fields contained in the group
   * @param containerCssClass An optional CSS class for the group container element
   */
  public fieldGroup(
    key: string,
    fields: DfFormFieldConfig[],
    containerCssClass?: string
  ) {
    const defaults: DfFormFieldConfig = {
      key,
      fieldGroupClassName: containerCssClass,
      fieldGroup: fields,
      templateOptions: {},
    };
    return new DfFieldGroupBuilder(this.translate, defaults);
  }

  public toggleSwitch(key: string, label: DfDynamicContentProvider) {
    const defaults: DfToggleSwitchFieldConfig = {
      key,
      type: 'toggle-switch',
      templateOptions: {
        defaultLayout: 'horizontal-normal',
        dfLabel: label,
      },
    };
    return new DfConfiguredFormFieldBuilder(this.translate, defaults);
  }

  public checkbox(key: string, label: DfDynamicContentProvider) {
    const defaults: DfCheckboxFieldConfig = {
      key,
      type: 'checkbox',
      templateOptions: {
        defaultLayout: 'horizontal-reverse',
        dfLabel: label,
      },
    };
    return new DfConfiguredFormFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder for creating a field whose primary interaction is provided
   * by a custom angular template.
   * {@link DfFormlyModule.forRoot}.
   * @param fields The set of fields contained in the group
   * @param groupCssClass An optional CSS class for the group container element
   */
  public customField(
    key: string,
    label: DfDynamicContentProvider,
    fieldTemplate: TemplateRef<DfFieldTemplateContext>,
    params: any = {}
  ) {
    const defaults: DfCustomFieldConfig = {
      key,
      type: 'custom',
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: label,
        fieldTemplate,
        params,
      },
    };
    return new DfRequirableFormFieldBuilder(this.translate, defaults);
  }

  /** Initializes the builder for creating a field whose primary interaction is provided
   * by a component defined externally to fresco. The specified component must have already
   * been explicitly registered by passing a configuration to {@link DfFormlyModule.forRoot}.
   *
   * @param params Optional configuration properties to be provided to the component instance.
   */
  public foreignField<T = any>(
    key: string,
    label: DfDynamicContentProvider,
    type: string,
    params?: T
  ) {
    const defaults: DfForeignFieldConfig<T> = {
      key,
      type,
      templateOptions: {
        defaultLayout: 'vertical',
        dfLabel: label,
        params,
      },
    };
    return new DfRequirableFormFieldBuilder(this.translate, defaults);
  }
}
