import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LayoutAspect } from '@degreed/apollo-angular';
import { Observable } from 'rxjs';

import {
  fileFromInput,
  NavigationViewModel,
  valueFromInput,
} from '../data-service';

@Component({
  selector: 'dgx-org-branding-navigation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'tw-col-span-full tw-flex tw-flex-col tw-gap-y-8 lg:tw-col-start-2 lg:-tw-col-end-2',
  },
  template: `
    <header>
      <h3 class="tw-text-2xl tw-font-bold">Navigation</h3>
      <p class="tw-text-sm tw-text-neutral-600">
        Customize the appearance of the side navigation and header for your
        users. All changes can be previewed in the example below.
      </p>
    </header>

    <div
      class="tw-flex tw-flex-col tw-gap-x-4 tw-gap-y-10 md:tw-flex-row md:tw-justify-between"
    >
      <!-- Logo Mark Loader -->
      <dgx-org-branding-uploader
        name="Logo Mark Upload"
        [required]="true"
        hint="Appears in the collapsed navigation"
        sizeHint="Recommended dimensions: 320x320 pixels"
        [state]="navigation.mark"
        (change)="navigation.updateMark($event)"
      ></dgx-org-branding-uploader>

      <!-- Logo Loader -->
      <dgx-org-branding-uploader
        name="Logo Upload"
        hint="Appears in the expanded navigation"
        sizeHint="Recommended dimensions: 1520x320 pixels"
        [state]="navigation.logo"
        (change)="navigation.updateLogo($event)"
      ></dgx-org-branding-uploader>
    </div>

    <div>
      <label class="tw-text-sm tw-font-semibold">Colors</label>
      <p class="tw-mb-4 tw-text-sm tw-text-neutral-600">
        Some helper text here
      </p>

      <div
        class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row sm:tw-items-center sm:tw-gap-6 md:tw-gap-12"
      >
        <div
          class="tw-flex tw-items-center tw-gap-3 sm:tw-border-r sm:tw-border-neutral-300 sm:tw-pr-6 md:tw-pr-12"
        >
          <label for="background-color" class="tw-text-xs tw-font-extrabold"
            >Background</label
          >
          <input
            id="background-color"
            name="background-color"
            type="color"
            class="tw-h-6 tw-w-10 tw-cursor-pointer tw-border-0 tw-bg-transparent tw-p-0"
            [value]="navigation.colors.background"
            (input)="
              navigation.updateColor(valueFromInput($event), 'background')
            "
          />
        </div>

        <div
          class="tw-flex tw-items-center tw-gap-3 sm:tw-border-r sm:tw-border-neutral-300 sm:tw-pr-6 md:tw-pr-12"
        >
          <label for="highlight-color" class="tw-text-xs tw-font-extrabold">
            Highlight
          </label>
          <input
            id="highlight-color"
            name="highlight-color"
            type="color"
            class="tw-h-6 tw-w-10 tw-cursor-pointer tw-border-0 tw-bg-transparent tw-p-0"
            [value]="navigation.colors.highlight"
            (input)="
              navigation.updateColor(valueFromInput($event), 'highlight')
            "
          />
        </div>

        <div
          class="tw-flex tw-items-center tw-gap-3 sm:tw-border-r sm:tw-border-neutral-300 sm:tw-pr-6 md:tw-pr-12"
        >
          <label for="text-color" class="tw-text-xs tw-font-extrabold"
            >Text</label
          >
          <input
            id="text-color"
            name="text-color"
            type="color"
            class="tw-h-6 tw-w-10 tw-cursor-pointer tw-border-0 tw-bg-transparent tw-p-0"
            [value]="navigation.colors.text"
            (input)="navigation.updateColor(valueFromInput($event), 'text')"
          />
        </div>

        <div class="tw-flex tw-items-center tw-gap-3">
          <label for="separator-color" class="tw-text-xs tw-font-extrabold"
            >Separator</label
          >
          <input
            id="separator-color"
            name="separator-color"
            type="color"
            class="tw-h-6 tw-w-10 tw-cursor-pointer tw-border-0 tw-bg-transparent tw-p-0"
            [value]="navigation.colors.separator"
            (input)="
              navigation.updateColor(valueFromInput($event), 'separator')
            "
          />
        </div>
      </div>
    </div>

    <dgx-org-branding-preview [layout$]="layout$"></dgx-org-branding-preview>
  `,
})
export class OrgBrandingNavigationComponent {
  fileFromInput = fileFromInput;
  valueFromInput = valueFromInput;

  @Input() navigation: NavigationViewModel;
  @Input() layout$: Observable<LayoutAspect>;
}
