import { LDFlagsService } from '../ld-flags.service';

export class SkillsLDFlags {
  /***
   *  Team: Skills
   *  Created Date: 12/22/2022 [PD-81334]
   *  Planned Cleanup: January Major Release 2024
   *  Visibility:
   *    -
   *
   *  Description TBD
   */
  public get skillStandardsManagerView(): boolean {
    return this.ldFlagsService.getFlag('skill-standards-manager-view', false);
  }

  /***
   *  Team: Skills
   *  Created Date: 12/22/2022 [PD-82566]
   *  Planned Cleanup: January Major Release 2024
   *  Visibility:
   *    -
   *
   *  Description TBD
   */
  public get skillStandardsLearnerView(): boolean {
    return this.ldFlagsService.getFlag('skill-standards-learner-view', false);
  }

  /***
   *  Team: Skills
   *  Created Date: 08/20/2021 [PD-94787]
   *  Planned Cleanup: May Major Release 2024
   *  Visibility:
   *    -
   *
   *  Description TBD
   */
  public get skillRegistryAdminSuggestions(): boolean {
    return this.ldFlagsService.getFlag(
      'skills-registry-admin-suggestions',
      false
    );
  }

  /***
   *  Team: Skills
   *  Created Date: 01/27/2021 [PD-94792]
   *  Planned Cleanup: May Major Release 2024
   *  Visibility:
   *    -
   *
   *  Description TBD
   */
  public get peerRatingsPhaseOne(): boolean {
    return this.ldFlagsService.getFlag('peer-ratings-phase-1', false);
  }

  /***
   *  Team: Skills
   *  Created Date: 06/04/2023 [PD-100115]
   *  Planned Cleanup: May Major Release 2024
   *  Visibility:
   *    -
   *
   *  This affects the grouping of skills in the skills i/o table
   */
  public get workdaySkillsIntegrationMain(): boolean {
    return this.ldFlagsService.getFlag(
      'workday-skills-integration-main',
      false
    );
  }

  /***
   *  Team: Skills
   *  Created Date: 05/10/2024 [PD-102080]
   *  Planned Cleanup: July Major Release 2024
   *  Visibility:
   *    -
   *
   *  Yes, this is named onboarding-experience-updates but was re-purposed to be the
   *  general Skills July Major Release flag.
   */
  public get july2024MajorRelease(): boolean {
    return this.ldFlagsService.getFlag('onboarding-experience-updates', false);
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
