import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { Tag } from '@app/shared/ajs/pascal-cased-types.model';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTypes } from '@app/tags/services/tag-rating-tracker.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes, TagRatingMeta } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-card',
  templateUrl: './tag-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCardComponent {
  public i18n = this.translateService.instant([
    'dgTagRating_MeasureYourSkill',
    'dgTagRating_EvalVisibility',
    'dgTagRating_AddSelfRatingDetail',
    'dgTagRating_VisibleToYou',
    'dgTagRating_CertVisibility',
    'dgTagRating_GetCertificate',
    'dgTagRating_InvalidRequest',
    'dgActivityDetails_RemoveConfirm',
    'dgActivityDetails_RemoveActivity',
  ]);

  @Input() public tag: TagsApi.Tag;
  @Input() public initialAction: string;

  private _actionsContext: any;

  constructor(
    private translateService: TranslateService,
    private displayType: DisplayTypePipe,
    private tagActionOptionsService: TagActionOptionsService,
    private tagRatingService: TagRatingService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {}

  // TODO: remove camelCasekeys/private member after we kill AJS
  @Input() public set actionsContext(value: any) {
    this._actionsContext = camelCaseKeys(value);
  }
  public get actionsContext() {
    return this._actionsContext;
  }

  public get ctaLabel(): string {
    return this.translateService.instant(
      this.isCompleted
        ? 'Core_Completed'
        : this.isPending
        ? 'Core_Continue'
        : 'dgTagRating_AddRating'
    );
  }

  public get authUser(): AuthUser {
    return this.authService?.authUser;
  }

  public get isPending(): boolean {
    return !!this.rating && !this.rating.dateCompleted;
  }

  public get isCompleted(): boolean {
    return !!this.rating?.dateCompleted;
  }

  // only relates to recommendations passed in via the `TagCardResourceComponent`
  public get recommendationCompleted(): boolean {
    return this.actionsContext ? this.actionsContext.alreadyCompleted : false;
  }

  public get type(): string {
    const displayType = this.tag.resourceType + this.initialAction;
    return this.displayType.transform(displayType);
  }

  public get description(): string {
    switch (this.initialAction) {
      case InternalTagRatingTypes.credential:
        return this.i18n.dgTagRating_GetCertificate;
      case InternalTagRatingTypes.evaluation:
        return this.i18n.dgTagRating_MeasureYourSkill;
      case InternalTagRatingTypes.self:
        return this.i18n.dgTagRating_AddSelfRatingDetail;
      default:
        return this.initialAction;
    }
  }

  public get metaOptions(): TagRatingMeta {
    const duration = {
      [InternalTagRatingTypes.evaluation]: 20,
      [InternalTagRatingTypes.self]: 1,
    }[this.initialAction];
    return {
      duration,
      visibility: this.i18n.dgTagRating_EvalVisibility,
    };
  }

  public get menuConfig(): MenuViewModel[] {
    return [
      this.tagActionOptionsService.getMyRatingsMenuOption(
        this.tag as TagsApi.Tag,
        this.actionsContext,
        () => {
          this.tagRatingService
            .getTagRatings(
              this.authUser?.viewerProfile?.userProfileKey,
              this.tag.tagId
            )
            .subscribe((ratings) => {
              this.tag.ratings = ratings;
              this.cdr.markForCheck();
            });
        }
      ),
      this.tagActionOptionsService.getRemoveFromFeedMenuOption(
        this.actionsContext
      ),
    ];
  }

  private get rating() {
    return this.tag.ratings.find(
      (rating) => rating.type === this.initialAction
    );
  }

  public handleClick(event: Event): void {
    switch (this.initialAction) {
      case InternalTagRatingTypes.credential:
        // Skill Certifications are no longer allowed, however we may need to handle previously recommended Skill Certs
        return this.notifierService.showError(
          this.translateService.instant('dgTagRating_InvalidRequest')
        );
      case InternalTagRatingTypes.evaluation:
        this.tagActionOptionsService.startSkillReviewAction(
          this.tag,
          event.target as HTMLElement
        );
        break;
      case InternalTagRatingTypes.self:
        // ensure the rating is the self rating
        this.tag.rating = this.rating;
        this.tagRatingService
          .openSelfRatingModal(event, this.tag)
          .subscribe((rating: Partial<TagsApi.UserTagRatingDetails>) => {
            const _rating = {
              ...rating,
              type: InternalTagRatingTypes.self,
              dateCompleted: new Date().toDateString(),
            } as TagsApi.UserTagRatingDetails;
            // make sure the rating modal will reflect the new value if launched again
            this.tag.rating = _rating;
            // make sure the button state is updated
            this.tag.ratings.push(_rating);
            this.cdr.markForCheck();
          });
        break;
    }
  }
}
