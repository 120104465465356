/**
 * Returns the current document's origin.
 *
 * This is equivalent to window.origin, but that's not supported in IE11 when
 * running in compatibility mode, like when serving scripts from localhost.
 */
export function getCurrentOrigin() {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '')
  );
}
