import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { isValidFileExtension, isValidFileSize } from '../uploader';
import { UploaderService } from '../uploader.service';
import { DfIconSize } from '@lib/fresco';

export type TemplateOption = 'full' | 'compact';

export interface FileUploadFailResult {
  error: string;
}

/*
 * We have a large variety of responses on success across uploads, hence
 *   the optional fields.
 * If you choose to make something required, be sure to check that it is
 *   in use in ALL instances of the uploader (it won't be, trust me)
 */
export interface FileUploadSuccessResult {
  message?: string;
  success?: boolean;
  url?: string;
  fileId?: number;
  firstItemValue?: string;
  rowsFound?: number;
  sheetName?: string;
  uploadFileName?: string;
}

@Component({
  selector: 'dgx-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @Input() public errorLabel: string = this.translate.instant(
    'Uploader_UnknownError'
  ); // visible label for generic file errors; please pre-translate
  @Input() public fileTypes: string; // optional list of file type defined as in an HTML 5 'accept' attribute
  @Input() public inProgressLabel: string; // visible label for in progress phase; please pre-translate
  @Input() public limitNotificationLabel: string; // visible information about file restrictions, otherwise fallback is used
  @Input() public postObject: object = {}; // additional information to pass to post if necessary
  @Input() public showCancel; // Show cancel button; will be forced to true if parent is listening for a cancel event
  @Input() public sizeLimit: number; // optional, in MB
  @Input() public targetLabel: string; // visible label for uploader; please pre-translate
  @Input() public templateOption: TemplateOption;
  @Input() public uploadApiEndpoint: string; // Custom upload api input
  @Input() public useErrorFallback: boolean = true; // Toast an error by default, or only emit error event if off.
  /*
    TODO: Have design created/implemented for multiple uploads prior to use in application.
    INFO: The ability to upload multiple was added for demo purposes and should not be used
    except for the demo, unless a design is implemented.
  */
  @Input() public multiple: boolean = false; // Set true if the underlying input should accept multiple files

  @Output() public cancelEvent = new EventEmitter<string>(); // Optional method to be called after default cancel behaviors
  @Output() public errorEvent = new EventEmitter<string>(); // Custom error handler; will be sent error info from post
  @Output() public successEvent = new EventEmitter<FileUploadSuccessResult>(); // Optional method to be called after upload success, if not using a custom handler

  public activeFileName: string;
  public files: FileList;
  public fileTypesExtensions: any[];
  public iconSize: DfIconSize = 'medium';
  public inErrorState: boolean;
  public isFileReady: boolean;
  public sizingNotice: string;
  public uploaderFocused: boolean;
  public uploadInProgress: boolean;
  public uploadSubscription: Subscription;

  constructor(
    private uploader: UploaderService,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    // Check for custom handlers; set defaults
    this.templateOption = this.templateOption ? this.templateOption : 'full';
    this.showCancel =
      this.cancelEvent.observers.length > 0 ? true : this.showCancel; // force true if parent is listening for a cancel event
    if (this.templateOption === 'compact') {
      this.iconSize = 'small';
    }
    this.fileTypesExtensions = this.buildExtensions();
    if (!this.limitNotificationLabel && (this.fileTypes || this.sizeLimit)) {
      this.sizingNotice =
        this.fileTypes && this.sizeLimit
          ? 'Uploader_DefaultLimitNotice_TypeAndSize'
          : this.fileTypes
            ? 'Uploader_DefaultLimitNotice_Type'
            : 'Uploader_DefaultLimitNotice_Size';
    }
  }

  /*
   * onFocus
   * apply focus classes so keyboard navigators can tell what's going on
   */
  public onFocus(): void {
    this.uploaderFocused = true;
  }

  /*
   * onBlur
   * reverse focus
   */
  public onBlur(): void {
    this.uploaderFocused = false;
  }

  /*
   * onFileSelected
   * Prep the file, then pass it along to the api
   */
  public onFileSelected(event) {
    const files = event.target ? event.target.files : event;
    for (let i = 0; i < files.length; i++) {
      if (!this.fileMeetsRestrictions(files.item(i))) {
        return;
      }
    }

    this.files = files;
    this.uploadInProgress = true;
    this.activeFileName =
      files.length > 1
        ? files.item(0).name + ' +' + (files.length - 1)
        : files.item(0).name;
    const filesFormData = this.uploader.prepRawFiles(files);
    this.uploadSubscription = this.uploader
      .uploadFile(filesFormData, this.uploadApiEndpoint, this.postObject)
      .subscribe(
        (response: FileUploadFailResult | FileUploadSuccessResult) => {
          if ('error' in response) {
            this.postErrorHandler(response.error);
          } else {
            this.isFileReady = true;
            this.successEvent.emit(response); // Alert parent to successful upload
          }
        },
        (error) => {
          this.postErrorHandler(error);
        }
      );
  }

  /*
   * onChangeDragState
   * Because the uploader itself isn't focusable, show visuals when the label is focused
   *   for keyboard users
   */
  public onChangeDragState($event) {
    switch ($event) {
      case 'dragover':
        this.uploaderFocused = true;
        break;
      default:
        this.uploaderFocused = false;
    }
  }

  /*
   * postErrorHandler
   * digests and toasts an error
   * fallback for situations where host doesn't provide their own handler; will show translated
   *   message from server, errorLabel if provided, or a generic error message
   */
  public postErrorHandler(error) {
    this.errorEvent.emit(error);
    if (this.useErrorFallback) {
      const errormsg = error.message
        ? this.translate.instant(error.message)
        : this.errorLabel;
      this.inErrorState = true;
      this.notifier.showError(errormsg);
    }
  }

  /*
   * cancelFileUpload
   * Unsubscribe from post and reset variables; make parent aware of cancel if listening
   */
  public cancelFileUpload(event) {
    this.uploadSubscription.unsubscribe();
    this.reset();
    this.notifier.showSuccess(this.translate.instant('Uploader_Canceled'));
    this.cancelEvent.emit(event); // alert parent to cancellation
  }

  /*
   * reset
   * Put uploader back in upload state
   */
  public reset() {
    this.files = null;
    this.uploadInProgress = false;
    this.activeFileName = '';
  }

  /*
   * buildExtensions
   * filters fileTypes to extensions only, since HTML 5 accept can take other
   *  type indicators as well for use as a second check in browsers that don't
   *  handle accept
   */
  public buildExtensions() {
    if (!this.fileTypes) {
      return [];
    }
    const fileTypeArray = this.fileTypes.split(',');
    return fileTypeArray
      .map((item) => item.trim())
      .filter((item) => item.substring(0, 1) === '.');
  }

  /*
   * fileMeetsRestrictions
   * check against any extant restrictions and toast errors
   */
  public fileMeetsRestrictions(file): boolean {
    if (this.sizeLimit && !isValidFileSize(file, this.sizeLimit)) {
      this.postErrorHandler({
        message: this.translate.instant('Uploader_SizeLimitMet', {
          maxSize: this.sizeLimit,
        }),
      });
      return false;
    }
    if (
      this.fileTypes &&
      !isValidFileExtension(file, this.fileTypesExtensions)
    ) {
      this.postErrorHandler({
        message: this.translate.instant('Uploader_FileTypeError', {
          filePattern: this.fileTypes,
        }),
      });
      return false;
    }
    return true;
  }

  public get fileUploaderDescribedBy() {
    if (!this.uploadInProgress && this.limitNotificationLabel) {
      return 'limit-notification-desc';
    } else if (!this.uploadInProgress && this.sizingNotice) {
      return 'sizing-notice-desc';
    }

    return null;
  }
}
