/**
 * Adapted from https://github.com/github/markdown-toolbar-element
 *
 * (Warning: `ngDefaultControl` directive is REQUIRED when implementing for `ngModel` to work as expected)
 *
 * Example usage for reactive form:
 * <dgx-markdown-editor
 *   [minHeight]="300px"
 *   [textareaLabel]="type something"
 *   [textareaId]="myId"
 *   [placeholder]="type something"
 *   formControlName="content"
 * ></dgx-markdown-editor>
 *
 * Example usage for ngModel form:
 * <dgx-markdown-editor
 *   [(ngModel)]="content"
 *   [minHeight]="300px"
 *   [textareaLabel]="type something"
 *   [textareaId]="myId"
 *   [placeholder]="type something"
 *   name="content"
 *   ngDefaultControl <!-- ngDefaultControl needed for ngModel -->
 * ></dgx-markdown-editor>
 *
 * Be sure to enable image support on the markdownToHtml pipe. For example:
 * <p [innerHTML]=" description | markdownToHtml: { allowImages: true, proxyImages: true }"></p>
 *
 * @param minHeight (optional) sets the min height of the textarea (excludes toolbar height)
 * @param textareaId (optional) used in conjunction with <label for="">, textareaId sets the ID of the textarea element of the editor
 * @param textareaLabel (optional) used in place of <label for=""> and acts like aria-label for accessibility
 * @param placeholder (optional) sets the placeholder attribute of the textarea
 * @param showValidationErrors (optional) if false, turns off validation error messages
 * @param enableImageUpload (optional) if false, turns off drag/drop support and image upload button in the toolbar
 * @param onImageUploadStart (optional) used to call a function when image uploading starts
 * @param onImageUploadEnd (optional) used to call a function when image uploading stops
 * @param ngDisabled (optional) set to true to disable the markdown textarea and hide the toolbar
 */

import { AuthService } from '@app/shared/services/auth.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Self,
  Optional,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { UploaderService } from '@app/uploader/uploader.service';
import { TranslateService } from '@ngx-translate/core';
import { StyleArgs, ToolbarTool } from './markdown-editor.model';
import { MarkdownEditorService } from './markdown-editor.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { mergeMap } from 'rxjs/operators';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { NgControl } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import {
  FileUploadSetting,
  UploadPictureResponse,
} from '@app/uploader/uploader-api.model';
import { FinraTextareaLimitComponent } from '@app/shared/components/finra-textarea-limit/finra-textarea-limit.component';
import {
  SelectionRange,
  TextareaService,
} from '@app/markdown/services/textarea.service';
import { A11yToolbarService } from '@app/shared/services/a11y-helpers/toolbar.service';
import { Subscription } from 'rxjs';
import { isValidFileSize, isValidFileExtension } from '@app/uploader/uploader';

@Component({
  selector: 'dgx-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
})
export class MarkdownEditorComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor
{
  @Input() public textareaId?: string;
  @Input() public textareaLabel?: string;
  @Input() public minHeight = '270px';
  @Input() public placeholder = '';
  @Input() public shouldHaveFocus = false;
  @Input() public showValidationErrors = true;
  @Input() public enableImageUpload = true;
  @Input() public ngDisabled?: boolean;
  @Input() public maxLength = 30000;
  @Input() public showCharacterCount = false;
  @Output() public onImageUploadStart?: EventEmitter<void> = new EventEmitter();
  @Output() public onImageUploadEnd?: EventEmitter<void> = new EventEmitter();
  @Output() public onBlur?: EventEmitter<void> = new EventEmitter();

  // local
  public field: string;
  public imageType = 'addImage';
  public toolbarConfig = this.markdownService.getToolbarConfig();
  public fileTypesAllowed: string;
  public allowedFileTypes: string[];
  public maxImageSizeMB: number;
  public fileUploadStatusMessage: string;
  public helperText: string;
  public canUploadImages: boolean;
  public canManageContent: boolean;
  public uploaderFocused = false;
  public fieldRef: ElementRef<HTMLTextAreaElement> = {
    nativeElement: undefined,
  };
  // We need to get the Textarea NativeElement from the FinraComponent
  @ViewChild(FinraTextareaLimitComponent)
  public finraComponent: FinraTextareaLimitComponent;

  // Function registered to propagate a changes to the parent component
  public propagateChange: (e: Event) => void;

  public i18n = this.translate.instant([
    'Markdown_toolbarA11y_bold',
    'Markdown_toolbarA11y_italic',
    'Markdown_toolbarA11y_addImage',
    'Markdown_toolbarA11y_link',
    'Markdown_toolbarA11y_ul',
    'Markdown_toolbarA11y_ol',
    'Markdown_toolbarA11y_quote',
    'Markdown_EditorHelperFormat',
    'Core_FieldRequired',
    'Markdown_ContentLength',
  ]);

  private authUser = this.authService.authUser;
  private styleDefaults = this.markdownService.getStyleDefaults();
  private fileUploadStatuses = this.uploaderService.getUploadStatuses();
  private allTools: ToolbarTool[] = [].concat(...this.toolbarConfig); // because can't use this.toolbarConfig.flat();;
  private selectedText = '';
  private originalSelectedText = '';
  private tools: HTMLElement[] = [];
  private keydownEventSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private markdownService: MarkdownEditorService,
    private uploaderService: UploaderService,
    private authService: AuthService,
    private textareaService: TextareaService,
    private notifierService: NotifierService,
    private webEnvironmentServive: WebEnvironmentService,
    @Optional() @Self() public ngControl: NgControl,
    private element: ElementRef,
    private a11yToolbarService: A11yToolbarService
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }

    this.tools =
      this.element.nativeElement.getElementsByClassName('a11y-toolitem');
    // adding additional keyboard support to meet ADA requirements for toolbars
    this.a11yToolbarService.setupToolbarKeyboardHandlers(
      this.element.nativeElement,
      this.tools
    );
  }

  public get showContentErrors() {
    return (
      this.ngControl.control.invalid &&
      this.ngControl.control.errors &&
      this.showValidationErrors
    );
  }

  public get contentErrorMsg() {
    return this.ngControl.control.errors?.required
      ? this.i18n.Core_FieldRequired
      : this.i18n.Markdown_ContentLength;
  }

  public ngOnDestroy() {
    this.a11yToolbarService.teardownToolbarKeyboardHandlers(
      this.element.nativeElement
    );
  }

  /*
   * ControlValueAccessor interface methods that need to be implemented
   */
  public writeValue(obj: string): void {
    this.field = obj;
  }

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {}

  public ngAfterViewInit() {
    this.fieldRef.nativeElement = this.finraComponent.nativeElement;
  }

  public ngOnInit(): void {
    this.ngControl?.control.updateValueAndValidity({ onlySelf: true });
    this.canManageContent =
      this.authUser.defaultOrgInfo.permissions.manageContent;
    this.canUploadImages =
      this.authService.userCanUploadFiles && this.enableImageUpload;

    this.uploaderService
      .getUploadLimit('image')
      .subscribe((response: FileUploadSetting) => {
        this.fileTypesAllowed = response.allowedFileTypes
          .join(',')
          .toLowerCase(); // no spaces
        this.allowedFileTypes = response.allowedFileTypes;
        this.maxImageSizeMB = response.maxSizeMB;
        this.helperText =
          this.canUploadImages &&
          this.translate.instant('Markdown_EditorHelperFormat', {
            supportedFiletypes: response.allowedFileTypes
              .join(', ')
              .toLowerCase(),
            filesizeLimit: response.maxSizeMB + 'MB',
          });
        return response;
      });
  }

  public applyStyle = (styleType: string) => {
    let tool: ToolbarTool;
    for (const t of this.allTools) {
      if (t.type === styleType) {
        tool = t;
      }
    }
    const styles = tool ? tool.style : {};

    const style = { ...this.styleDefaults, ...styles };
    this.fieldRef.nativeElement.focus();
    this.styleSelectedText(this.fieldRef.nativeElement, style);

    // Update ngModel reference required for IE11 where field value wasn't progogating
    this.field = this.fieldRef.nativeElement.value;
  };

  /*
   * onChangeDragState
   * Because the uploader itself isn't focusable, show visuals when the label is focused
   * for keyboard users
   */
  public onChangeDragState($event) {
    switch ($event) {
      case 'dragover':
        this.uploaderFocused = true;
        break;
      default:
        this.uploaderFocused = false;
    }
  }

  public onFileInputChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    this.onFileSelected(fileList);
  }

  public onFileSelected(fileList: FileList) {
    if (!fileList || !fileList[0] || !this.isValidImage(fileList[0])) {
      return;
    }
    if (!!this.onImageUploadStart) {
      this.onImageUploadStart.emit();
    }

    this.insertLoadingText(this.fieldRef.nativeElement, fileList[0].name);
    this.upload(fileList[0]);
  }

  public onTextareaBlur() {
    this.onBlur.emit();
  }

  public upload(file: File) {
    const formData: FormData = this.uploaderService.prepRawImageFile(file);
    this.uploaderService
      .getImageDimension(file)
      .pipe(
        mergeMap(({ width, height }) => {
          return this.uploaderService.uploadFile<UploadPictureResponse>(
            formData,
            '/inputs/uploadpicture',
            {},
            {
              pointY: 0,
              pointX: 0,
              width: width,
              height: height,
            }
          );
        })
      )
      .subscribe(
        (response) => {
          if (response?.error || !response?.success) {
            this.handleImageError(response);
          } else {
            this.onImageUploadComplete(this.fieldRef.nativeElement, response);
            return response;
          }
        },
        (error) => {
          this.handleImageError(error);
        }
      );
  }

  private styleSelectedText = (
    textarea: HTMLTextAreaElement,
    styleArgs: StyleArgs
  ) => {
    if (!this.selectedText) {
      // if an image was uploaded, selectedText is already defined
      this.selectedText = this.textareaService.getSelectedText(textarea);
    }

    let result: SelectionRange;
    if (styleArgs.orderedList) {
      result = this.markdownService.orderedList(textarea, styleArgs);
    } else if (
      styleArgs.multiline &&
      this.markdownService.isMultipleLines(this.selectedText)
    ) {
      result = this.markdownService.multilineStyle(textarea, styleArgs);
    } else {
      result = this.markdownService.blockStyle(textarea, styleArgs);
    }
    this.textareaService.insertText(textarea, result);

    // reset to default for next selection
    this.selectedText = '';

    return result;
  };

  private isValidImage(file: File) {
    const isValidSize = isValidFileSize(file, this.maxImageSizeMB);
    const isValidExtension = isValidFileExtension(file, this.allowedFileTypes);
    if (!isValidSize) {
      this.notifierService.showError(
        this.translate.instant('dgImageUpload_FilesizeError', {
          maxSize: this.maxImageSizeMB + 'MB',
        })
      );
    }

    if (!isValidExtension) {
      this.notifierService.showError(
        this.translate.instant('dgFileUploadButton_FiletypeError', {
          filePattern: this.fileTypesAllowed,
        })
      );
    }

    return isValidExtension && isValidSize;
  }

  private handleImageError(error) {
    const errorMessage = this.translate.instant(
      'dgFileUploadButton_UploadErrorMessage'
    );
    this.notifierService.showError(errorMessage);
    if (error && error.message) {
      console.error(error.message);
    }
    this.notifierService.showError(errorMessage);
  }

  private onImageUploadComplete = (
    textarea: HTMLTextAreaElement,
    data: UploadPictureResponse
  ) => {
    let cursorPosition: number;
    let imageTool: ToolbarTool;
    for (const t of this.allTools) {
      if (t.type === this.imageType) {
        imageTool = t;
      }
    }

    // remove upload indicator text and restore cursor to that spot
    cursorPosition = textarea.selectionStart;
    this.fieldRef.nativeElement.value =
      this.fieldRef.nativeElement.value.replace(
        this.fileUploadStatusMessage,
        ''
      );
    textarea.setSelectionRange(cursorPosition, cursorPosition);
    const imageUrl = this.webEnvironmentServive.getBlobUrl(data.imageUrl);

    imageTool.style.prefix = `![${this.originalSelectedText}`;
    imageTool.style.suffix = `](${imageUrl})`;
    imageTool.style.surroundWithNewlines = false; // already made new lines with upload indicator
    this.applyStyle(this.imageType);

    // cursor is now at end of prefix, let's select the alt text for immediate editing and for screen readers
    cursorPosition = textarea.selectionStart;
    textarea.setSelectionRange(
      cursorPosition - this.originalSelectedText.length,
      cursorPosition
    );

    this.originalSelectedText = ''; // reset for next time
    if (!!this.onImageUploadEnd) {
      this.onImageUploadEnd.emit();
    }
  };

  private insertLoadingText(textarea: HTMLTextAreaElement, filename: string) {
    let textIsSelected = true;
    const filenameText = this.filterTextFromFileName(filename);
    this.selectedText = this.textareaService.getSelectedText(textarea);
    if (this.selectedText === '') {
      textIsSelected = false;
      this.selectedText = filenameText;
    }
    this.originalSelectedText = this.selectedText;
    const styles: StyleArgs = {
      prefix: `[${this.fileUploadStatuses.uploading.label} ${
        !textIsSelected ? filenameText : ''
      }`,
      suffix: `...]`,
      trimFirst: true,
      surroundWithNewlines: true,
      replaceNext: this.originalSelectedText,
      skipExpand: true,
    };
    this.fileUploadStatusMessage = `${styles.prefix}${
      textIsSelected ? this.originalSelectedText : ''
    }${styles.suffix}`;
    const style = { ...this.styleDefaults, ...styles };
    this.styleSelectedText(textarea, style);
    this.textareaService.selectText(textarea, this.fileUploadStatusMessage);
  }

  private filterTextFromFileName(filename: string) {
    const filenameParts = filename.split('.');
    filenameParts.pop();
    let text = filenameParts.join('');
    text = text.replace(/[-_.]/g, ' ');
    return text;
  }
}
