import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  NormalizedRecommendation,
  RecommendationForUser,
} from '@app/recommendations/recommendations.api';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { UserRecommendationHeaderItem } from '@app/shared/components/user-recommendation-header/user-recommendation-header.component';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsApi } from '@app/tags/tag-api.model';

@Component({
  selector: 'dgx-tag-card-resource',
  templateUrl: './tag-card-resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCardResourceComponent implements OnInit {
  @Input() public recommendation: RecommendationForUser;
  @Input() public tag: TagsApi.Tag;

  constructor(
    private recommendationsService: RecommendationsService,
    private trackerService: TrackerService
  ) {}

  public get normalizedRecommendation(): NormalizedRecommendation {
    if (!this.recommendation) {
      return;
    }
    return this.recommendationsService.normalizeRecommendation(
      this.recommendation
    );
  }

  public get initialAction(): string {
    return this.normalizedRecommendation?.action || '';
  }

  public get recommendationForHeader(): UserRecommendationHeaderItem {
    const { reference, groupId, groupName, recommendationType } =
      this.normalizedRecommendation;
    return {
      ...this.normalizedRecommendation,
      contentType: reference?.resourceType,
      // only default to AssignedLearning if there is no recommendationType
      recommendationType:
        recommendationType || UserRecommendationType.AssignedLearning,
      group: {
        groupId,
        groupName,
      },
    } as UserRecommendationHeaderItem;
  }

  public ngOnInit(): void {
    this.trackerService.setLocation('Skill');
  }
}
