import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  GroupInfo,
  GroupPrivacyLevel,
  Permission,
} from '@app/groups/group-api';
import { GroupInsightsComponent } from '@app/insights/group-insights/group-insights.component';
import { TabNavigationService } from '@app/navigation/components/tab-navigation/tab-navigation.service';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import { WindowToken } from '@app/shared/window.token';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import { AuthService } from '@app/shared/services/auth.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import { ContextService } from '@app/shared/services/context.service';
import { GroupService } from '@app/shared/services/group.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupFeedComponent } from '../group-feed/group-feed.component';
import { GroupMembersViewComponent } from '../group-members-view/group-members-view.component';
import { GroupSettingsComponent } from '../group-settings/group-settings.component';
import { GroupOrganizationsComponent } from '../group-organizations/group-organizations.component';
import { TrackingEventArgs } from '@app/shared/services/tracking.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { UserProfile } from '@app/user/user-api.model';
import {
  getPrivacyLevelNumber,
  getPrivacyLevelText,
  transformPermissions,
} from '@app/groups/group-utils';
import { filter, pipe, switchMap, tap } from 'rxjs';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

export enum GroupsTabComponents {
  GroupFeedComponent,
  GroupMembersViewComponent,
  GroupInsightsComponent,
  GroupSettingsComponent,
  GroupOrganizationsComponent,
}

@Component({
  selector: 'dgx-group',
  templateUrl: './group.component.html',
})
export class GroupComponent extends SubscriberBaseDirective implements OnInit {
  public group: GroupInfo;
  public orgPermissions: Partial<Permission> = {};
  public groupPermissions: Partial<Permission> = {};
  public breadcrumbs: Breadcrumbs;
  public tabsList: TabNavigationItem[] = [];
  public requestingMembership = false;
  public useNewGroupSettingsLDFlag: any;
  public i18n = this.translateService.instant([
    'GroupsController_Feed',
    'GroupsController_Members',
    'GroupsController_Insights',
    'GroupsController_Settings',
    'DashboardNav_YourGroups',
    'OrgGroupsCtrl_GroupsTitle',
    'dgGroupTile_RequestMembership',
    'dgGroupOverview_GroupMembershipPending',
    'Core_Home',
    'Core_Join',
    'Core_GeneralErrorMessage',
    'Core_Organizations',
  ]);

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private groupService: GroupService,
    private contextService: ContextService,
    private navigationService: NavigationService,
    private breadcrumbsService: BreadcrumbsService,
    private tabNavigationService: TabNavigationService,
    private windowLayoutService: WindowLayoutService,
    private trackerService: TrackerService,
    private notifierService: NotifierService,
    @Inject(WindowToken) private windowRef: Window,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public get groupInfo() {
    return this.groupService.groupInfo;
  }

  public get groupCounts() {
    return {
      userCount: this.group.userCount,
      anonymousUserCount: this.group.anonymousUserCount,
    };
  }

  public get isChannelContext() {
    return this.contextService.urlHasContext(
      this.windowRef.location.href,
      'channel'
    );
  }

  public get groupTypeText(): string {
    return this.translateService.instant('GroupSettingsCtrl_GroupNameFormat', {
      groupName: getPrivacyLevelText(this.group.privacyLevel),
    });
  }

  public get accessPageButtonText(): string {
    const isOpen = this.group.privacyLevel === GroupPrivacyLevel.Open;
    const nonOpenVariant = this.groupInfo.isPendingMember;

    return isOpen
      ? this.i18n.Core_Join
      : nonOpenVariant
      ? this.i18n.dgGroupOverview_GroupMembershipPending
      : this.i18n.dgGroupTile_RequestMembership;
  }

  public get groupCountText(): string {
    return this.translateService.instant('dgGroupTile_MemberCount', {
      count: this.group.userCount,
    });
  }

  // Coerce type for subcomponents that are expecting specific interfaces
  public get topActiveUsers() {
    return this.group.topActiveUsers as unknown as UserProfile[];
  }

  public get isMobile() {
    return this.windowLayoutService.isMobile;
  }

  public get disableJoinGroupButton() {
    return this.requestingMembership || this.groupInfo.isPendingMember
      ? true
      : undefined;
  }

  public get showAccessPage() {
    if (this.groupInfo.canManageGroups) {
      return false;
    }

    if (
      !this.groupInfo.isMember &&
      (this.group.privacyLevel === GroupPrivacyLevel.Closed ||
        this.group.privacyLevel === GroupPrivacyLevel.Open)
    ) {
      return true;
    }

    return false;
  }

  private get showGroupFeedTab() {
    return (
      (this.groupInfo.isMember || this.groupInfo.canManageGroups) &&
      !this.groupInfo.isAdministrativeGroup
    );
  }

  private get showMembersTab() {
    return this.groupPermissions.canViewMembers;
  }

  private get showInsightsTab() {
    return (
      this.groupPermissions.canViewGroupReports ||
      this.groupInfo.canManageGroups
    );
  }

  private get showSettingsTab() {
    if (
      this.isRestrictedProfile &&
      this.group.privacyLevel !== getPrivacyLevelNumber('Private')
    ) {
      return false;
    }

    return (
      this.groupPermissions.canEditSettings ||
      this.groupPermissions.canEditPermissions ||
      this.groupPermissions.canDeleteGroup
    );
  }

  private get showOrganizationsTab() {
    return this.isChannelContext && this.groupInfo.canManageGroups;
  }

  private get isRestrictedProfile() {
    return this.authService.authUser.isRestrictedViewerProfile;
  }

  public ngOnInit(): void {
    this.trackerService.setLocation('Group');

    // Reload the group info if it's updated
    // - joining/requesting membership
    // - settings/permissions changes
    this.groupService.groupModified
      .pipe(this.takeUntilDestroyed(), this.updateGroup())
      .subscribe();

    this.initialize(this.groupInfo);

    // Track if we've shown the access page
    if (this.showAccessPage) {
      const data: TrackingEventArgs = {
        action: 'Group Closed Group Access Viewed',
        properties: {
          group: this.group,
        },
      };
      this.trackerService.trackEventData(data);
    }
  }

  /** public methods */

  /**
   * Call to join or request membership in the group
   */
  public submitForMembership() {
    const canJoin = this.group.privacyLevel === GroupPrivacyLevel.Open;
    canJoin ? this.joinGroup() : this.requestMembership();
  }

  public onActivate(
    component:
      | GroupFeedComponent
      | GroupMembersViewComponent
      | GroupInsightsComponent
      | GroupSettingsComponent
      | GroupOrganizationsComponent
  ) {
    switch (component.CLASS_NAME) {
      case GroupsTabComponents.GroupFeedComponent:
        component.canRecommendItems = this.groupPermissions.canRecommendItems;
        component.canRemoveFeedItems = this.groupPermissions.canRemoveFeedItems;
        component.canManageGroups = this.groupInfo.canManageGroups;
        component.canViewMembers = this.groupPermissions.canViewMembers;
        component.group = this.group;
        component.isMobile = this.isMobile;
        break;
      case GroupsTabComponents.GroupMembersViewComponent:
        component.groupId = this.group.groupId;
        component.expectedCount = this.group.userCount;
        component.anonymousCount = this.group.anonymousUserCount;
        component.referId = this.groupInfo.referId;
        component.inviteUrl = this.groupInfo.inviteUrl;
        component.canInviteMembers = this.groupPermissions.canInviteMembers;
        component.isAdministrativeGroup = this.groupInfo.isAdministrativeGroup;
        break;
      case GroupsTabComponents.GroupInsightsComponent:
        component.orgId = this.group.organizationId;
        component.groupId = this.group.groupId;
        component.groupName = this.group.name;
        component.canViewDownloadReports =
          this.groupPermissions.canViewGroupReports;
        break;
      case GroupsTabComponents.GroupSettingsComponent:
        component.group = this.group;
        component.availablePermissions =
          this.groupInfo.availableGroupPermissions;
        component.groupId = this.group.groupId;
        component.canCreateOpenGroups = this.orgPermissions.canCreateOpenGroups;
        component.canCreateClosedGroups =
          this.orgPermissions.canCreateClosedGroups;
        component.canCreatePrivateGroups =
          this.orgPermissions.canCreatePrivateGroups;
        component.canCreateAdministrativeGroups =
          this.orgPermissions.canCreateAdministrativeGroups;
        component.isRegulated = this.isRestrictedProfile;
        component.canEditPermissions = this.groupPermissions.canEditPermissions;
        component.canDeleteGroup = this.groupPermissions.canDeleteGroup;
        component.isAdministrativeGroup = this.groupInfo.isAdministrativeGroup;
        break;
      case GroupsTabComponents.GroupOrganizationsComponent:
        component.group = this.group;
        break;
    }
  }

  /** private methods */

  private initialize(groupInfo: GroupInfo) {
    this.groupService.groupInfo = groupInfo;
    this.group = this.getGroup(groupInfo);
    this.orgPermissions = transformPermissions(groupInfo.userOrgPermissions);
    this.groupPermissions = transformPermissions(
      groupInfo.userGroupPermissions
    );
    this.breadcrumbs = this.getBreadcrumbs();
    this.tabsList = this.getTabs();
    this.cdr.markForCheck();
  }

  // Pipeable operator to update the group info only if the group id matches
  private updateGroup() {
    return pipe(
      filter((groupId: number) => groupId === this.group.groupId),
      switchMap((groupId: number) => this.groupService.getSummary(groupId)),
      tap((groupInfo: GroupInfo) => this.initialize(groupInfo))
    );
  }

  private getGroup(groupInfo: GroupInfo) {
    const group = groupInfo.group;

    return {
      ...group,
      privacyLevel: getPrivacyLevelNumber(group.privacyLevel),
    };
  }

  /**
   * Call group service to join
   */
  private joinGroup() {
    this.requestingMembership = true;

    const groupInfo = {
      groupId: this.group.groupId,
      name: this.group.name,
      privacyLevel: this.group.privacyLevel,
      userCount: this.group.userCount,
      interestNames: this.group.interests.join(','),
    };

    return this.groupService.join(groupInfo).subscribe({
      next: () => {
        this.groupService.notifyGroupModified(groupInfo.groupId);
      },
      error: () => {
        this.notifierService.showError(this.i18n.Core_GeneralErrorMessage);
        this.requestingMembership = false;
      },
    });
  }

  /**
   * Call group service to request membership
   */
  private requestMembership() {
    this.requestingMembership = true;

    const data: TrackingEventArgs = {
      action: 'Group Closed Group Access Requested',
      properties: {
        group: this.group,
      },
    };

    this.trackerService.trackEventData(data);

    const groupInfo = {
      groupId: this.group.groupId,
      name: this.group.name,
      privacyLevel: this.group.privacyLevel,
      userCount: this.group.userCount,
      interestNames: this.group.interests.join(','),
    };

    return this.groupService.requestMembership(groupInfo).subscribe({
      next: () => {
        this.groupService.notifyGroupModified(groupInfo.groupId);
      },
      error: () => {
        this.notifierService.showError(this.i18n.Core_GeneralErrorMessage);
        this.requestingMembership = false;
      },
    });
  }

  private getBreadcrumbs() {
    const label = {
      label: this.group.name,
    };

    const defaultCrumbs = [
      {
        label: this.i18n.Core_Home,
        link: '/me/dashboard/',
      },
      {
        label: this.i18n.DashboardNav_YourGroups,
        link: `/${this.navigationService.userGroupsUrl}`,
      },
      label,
    ];

    const channelCrumbs = [
      {
        label: this.i18n.OrgGroupsCtrl_GroupsTitle,
        link: '/channel/groups',
      },
      label,
    ];

    const crumbs = this.isChannelContext ? channelCrumbs : defaultCrumbs;

    return this.breadcrumbsService.decodeBreadcrumbs(crumbs);
  }

  private getTabs() {
    return this.tabNavigationService.formatTabs([
      {
        dgat: 'Groups-Index-096',
        label: this.i18n.GroupsController_Feed,
        routerLink: './feed',
        isAuthorized: this.showGroupFeedTab,
        sortOrder: 1,
      },
      {
        dgat: 'Groups-Index-b97',
        label: this.i18n.GroupsController_Members,
        routerLink: './members',
        isAuthorized: this.showMembersTab,
        sortOrder: 2,
      },
      {
        dgat: 'Groups-Index-286',
        label: this.i18n.GroupsController_Insights,
        routerLink: './groupInsights',
        isAuthorized: this.showInsightsTab,
        sortOrder: 3,
      },
      {
        dgat: 'Groups-Index-26b',
        label: this.i18n.GroupsController_Settings,
        routerLink: './settings',
        isAuthorized: this.showSettingsTab,
        sortOrder: 4,
      },
      {
        dgat: 'Groups-Index-322',
        label: this.i18n.Core_Organizations,
        routerLink: './organizations',
        isAuthorized: this.showOrganizationsTab,
        sortOrder: 5,
      },
    ]);
  }
}
