import {
  OnInit,
  Component,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { TabRouterLessNavigationItem } from '@app/navigation/navigation.model';
import {
  AddModalResults,
  PathwayDetailsModel,
} from '@app/pathways/rsm/pathway-api.model';
import { ResourceVersionService } from '@app/resource-version/services/resource-version.service';
import { A11yService } from '@app/shared/services/a11y.service';
import {
  PathwayFacade,
  PathwayViewModel,
  PathwayAuthoring,
} from '@dg/pathways-rsm';
import { ResourceCategory } from '@app/inputs/inputs.enums';

interface AddContentConfig {
  pathway: PathwayDetailsModel;
  afterNode: string;
  beforeNode?: string;
  savedManual?: LearningResourceViewModel[];
  savedSearch?: LearningResourceViewModel[];
  defaultSearch?: any;
}

interface AddContentModel extends AddContentConfig {
  onAddItems: (result: AddModalResults, callback: (data) => void) => void;
}

@Component({
  selector: 'dgx-pathway-add-content',
  templateUrl: './pathway-add-content.component.html',
})
export class PathwayAddContentComponent implements OnInit {
  @Input() public addContentModel: AddContentModel;

  @ViewChild('catalog', { static: true })
  public catalogTemplate: TemplateRef<any>;
  @ViewChild('manual', { static: true })
  public manualTemplate: TemplateRef<any>;
  @ViewChild('bin', { static: true }) public binTemplate: TemplateRef<any>;
  @ViewChild('binHeader', { static: true })
  public binHeaderTemplate: TemplateRef<any>;

  public pathId: number;
  public pathwayOrgId: number;
  public isSubmitting = false;
  public selectedTab: string = 'catalog';
  public searchItems: LearningResourceViewModel[] = [];
  public manualItems: LearningResourceViewModel[] = [];
  public i18n: { [key: string]: string } = this.translate.instant([
    'Core_Search',
    'Pathways_AddByType',
    'Pathways_Bin',
  ]);
  public tabList: TabRouterLessNavigationItem[];
  public vm$: Observable<PathwayViewModel>;
  public resourceCategory = ResourceCategory.AllInputsWithoutAcademies;

  constructor(
    private translate: TranslateService,
    private a11YService: A11yService,
    private activeModal: NgbActiveModal,
    private resourceVersionService: ResourceVersionService,
    private authoring: PathwayAuthoring,
    private facade: PathwayFacade
  ) {}

  public ngOnInit() {
    this.vm$ = this.facade.vm$;
    this.pathId = this.addContentModel.pathway.id;
    this.pathwayOrgId = this.addContentModel.pathway.organizationId;
    this.searchItems = this.addContentModel.savedSearch;
    this.manualItems = this.addContentModel.savedManual;
    const headerElement = document.getElementById('dialogTitle');
    if (headerElement) {
      headerElement.focus();
    }
    this.tabList = [
      {
        name: this.i18n.Core_Search,
        nameId: 'catalog',
        template: this.catalogTemplate,
      },
      {
        name: this.i18n.Pathways_AddByType,
        nameId: 'manual',
        template: this.manualTemplate,
      },
      {
        name: this.i18n.Pathways_Bin,
        nameId: 'bin',
        headerTemplate: this.binHeaderTemplate,
        template: this.binTemplate,
      },
    ];
  }

  public switchTab(newTab) {
    this.selectedTab = newTab;
  }

  public itemsAdded(result: AddModalResults) {
    this.resourceVersionService
      .isCurrentVersion({
        id: this.pathId,
        resourceVersion: this.facade.snapshot.version,
        resourceType: this.addContentModel.pathway.resourceType,
        showRefreshModal: true,
      })
      .subscribe((isCurrentVersion) => {
        if (isCurrentVersion) {
          this.isSubmitting = true;
          this.activeModal.close(result);
          return;
        }
      });
  }

  public addToBin = async (result: AddModalResults) => {
    const success = await this.authoring.addToBin(
      this.pathId,
      result.savedItems
    );

    if (success) {
      if (result.whichModal === 'search') {
        this.searchItems = [];
      } else {
        this.manualItems = [];
      }
      this.a11YService.announcePolite(
        this.translate.instant('Pathways_MovedToHoldForLater')
      );
    }
  };

  public updateSearchItems = (result) => {
    this.searchItems = result.savedItems;
  };

  public updateManualItems = (result) => {
    this.manualItems = result.savedItems;
  };

  public async dismiss(_?: any) {
    const success = await this.authoring.updateBinSelection(null, false, true);

    this.activeModal.dismiss({
      selectedManual: this.manualItems,
      selectedSearch: this.searchItems,
    });
  }
}
