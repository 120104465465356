import { BehaviorSubject, combineLatest, filter, map, Subject } from 'rxjs';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { InputsFacadeBaseV2 } from '@app/user-content/user-input-v2/services/inputs-facade-base';
import { TranslateService } from '@ngx-translate/core';

// misc
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { FormGroup } from '@angular/forms';
import { SubmissionStatus } from '@app/inputs/inputs.model';

import {
  ExperienceFormDataModel,
  ExperienceModel,
  InferredSkillsModel,
  ExperienceTypes,
} from '@app/user-content/user-input-v2/inputs/experience/experience.model';
import { ExperienceService, ExperienceMapperService } from './';
import { PositionLevel } from '@app/inputs/inputs-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';
export const EXPERIENCE_GLOBAL_ADD_DEFAULTS: {
  hoursPerWeek: number;
  level: PositionLevel;
} = {
  hoursPerWeek: 40,
  level: 'Intermediate',
};

/**
 * Base Experience service to define shared functionality, with the ability to overwrite if it is different.
 */
export abstract class ExperienceFacadeBase extends InputsFacadeBaseV2<ExperienceModel> {
  public viewModel$ = new BehaviorSubject<ExperienceModel>(undefined);
  public inferredSkills$ = new BehaviorSubject<TagsApi.Tag[]>([]);

  public inferredSkillsTrigger$ = new Subject<{
    title: string;
    description: string;
  }>();

  public i18n = this.translate.instant([
    'Core_JobRoleLiteral',
    'Opportunities_TypeProject',
    'Opportunities_TypeMentorship',
    'Opportunities_TypeMenteeship',
    'Opportunities_TypeShadowing',
    'Opportunities_TypeStretchAssignment',
    'Opportunities_TypeOther',
    'PositionFormCtrl_JuniorPosition',
    'PositionFormCtrl_IntermediatePosition',
    'PositionFormCtrl_SeniorPosition',
  ]);

  /**
   * Provides notifications to the attached form that either the form view model, the form UI configuration
   * or both have changed
   */
  protected constructor(
    public inputsService: InputsService,
    public translate: TranslateService,
    public experienceMapperService: ExperienceMapperService,
    public experienceService: ExperienceService,
    public globalAddTrackingService: GlobalAddTrackingService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService
  ) {
    super(inputsService, inputImageUploadAdapterService);

    this.inferredSkillsTrigger$
      .pipe(
        // don't call if both title and description are not set
        filter(({ title, description }) => !!title || !!description),
        // I considered adding a debounceTime here, but as this is called when the title and description fields are blurred
        // we do want to update the inferred skills on each change. distinctUntilChanged is used to prevent duplicate API calls
        // if the title and description are the same as the last call.
        distinctUntilChanged((prev, curr) => {
          return (
            prev.title === curr.title && prev.description === curr.description
          );
        })
      )
      .subscribe(({ title, description }) => {
        this.getInferredSkills(title, description);
      });
  }

  /** Gets the latest view model */
  protected get viewModel(): ExperienceModel {
    return this.viewModel$.value as ExperienceModel;
  }

  protected set viewModel(viewModel: ExperienceModel) {
    this.viewModel$.next(viewModel);
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    const formData = form.value;
    // Step 1 update the View model with the form data
    this.updateViewWithFormData(formData);
    // step 2 get API Params mapper
    const apiParameters = this.experienceMapperService.toApiParameters(
      this.viewModel as ExperienceModel
    );
    this.submissionStatus$.next(SubmissionStatus.Submitting);
    try {
      if (this.viewModel.inputContext.isEditing) {
        await this.experienceService.updateUserPosition(apiParameters);
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      } else {
        const { result } =
          await this.experienceService.addUserPosition(apiParameters);
        this.viewModel = {
          ...this.viewModel,
          inputId: result?.inputId ?? this.viewModel.inputId,
        };
        this.submissionStatus$.next(SubmissionStatus.Succeeded);
      }
      this.globalAddTrackingService.trackGlobalAddSaved(
        'Position',
        this.viewModel.skills,
        this.viewModel.mediumConfidenceInferredSkills,
        this.viewModel.highConfidenceInferredSkills
      );
      return;
    } catch (e) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw e;
    }
  }

  /********************************************************
   * View Model
   ********************************************************/

  public async initializeEdit(): Promise<void> {
    // Update viewModel
    const experience: any = await this.experienceService.getExperienceDetails(
      this.viewModel.inputContext.inputId
    );

    this.loadInferredSkills(experience.title, experience.description);
    // Map response to view model
    const updatedView = this.experienceMapperService.toViewModel(experience);
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
    };
    return;
  }

  protected initializeViewModel(inputContext: InputContext): void {
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
    ]).pipe(map(([s]) => s === SubmissionStatus.Submitting));

    // initialize context
    super.initializeContextViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      title: '',
      dateRangeForm: {
        startDate: null,
        endDate: null,
      },
      inferredSkills$: this.inferredSkills$, // the list of inferred skills from the API
      hoursPerWeek: EXPERIENCE_GLOBAL_ADD_DEFAULTS.hoursPerWeek,
      isCurrent: false,
      level: EXPERIENCE_GLOBAL_ADD_DEFAULTS.level,
      experienceType: '',
      orgName: '',
      inputType: 'Position',
      skills: [], // the selected skills for the experience
      description: '',
      shouldSpinSubmitButton$: shouldSpinSubmitButton$,
      loadInferredSkills: this.loadInferredSkills.bind(this),
      experienceTypeOptions: [
        {
          id: null,
          inputSubType: '',
        },
        {
          id: ExperienceTypes.JobRole,
          inputSubType: this.i18n.Core_JobRoleLiteral,
        },
        {
          id: ExperienceTypes.Project,
          inputSubType: this.i18n.Opportunities_TypeProject,
        },
        {
          id: ExperienceTypes.Mentorship,
          inputSubType: this.i18n.Opportunities_TypeMentorship,
        },
        {
          id: ExperienceTypes.Menteeship,
          inputSubType: this.i18n.Opportunities_TypeMenteeship,
        },
        {
          id: ExperienceTypes.Shadowing,
          inputSubType: this.i18n.Opportunities_TypeShadowing,
        },
        {
          id: ExperienceTypes.StretchAssignment,
          inputSubType: this.i18n.Opportunities_TypeStretchAssignment,
        },
        {
          id: ExperienceTypes.Other,
          inputSubType: this.i18n.Opportunities_TypeOther,
        },
      ],
      levelOptions: [
        {
          id: 'Junior',
          level: this.i18n.PositionFormCtrl_JuniorPosition,
        },
        {
          id: 'Intermediate',
          level: this.i18n.PositionFormCtrl_IntermediatePosition,
        },
        {
          id: 'Senior',
          level: this.i18n.PositionFormCtrl_SeniorPosition,
        },
      ],
    };
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: ExperienceFormDataModel) {
    this.viewModel = {
      ...this.viewModel,
      ...formData,
    };
  }

  public loadInferredSkills(title: string, description: string): void {
    this.inferredSkillsTrigger$.next({ title, description });
  }

  protected async getInferredSkills(
    title: string,
    description: string
  ): Promise<void> {
    try {
      const response: InferredSkillsModel =
        await this.experienceService.getInferredSkills(
          title || '',
          description || ''
        );
      this.viewModel = {
        ...this.viewModel,
        mediumConfidenceInferredSkills:
          this.experienceMapperService.getMappedInferredSkills(
            response.mediumConfidence
          ),
        highConfidenceInferredSkills:
          this.experienceMapperService.getMappedInferredSkills(
            response.highConfidence
          ),
      };
      this.inferredSkills$.next(
        this.experienceMapperService.getMappedInferredSkills(
          response.highConfidence
        )
      );
    } catch (e) {
      console.error('Error in loadInferredSkills', e);
    }
  }
}
