import { Injectable } from '@angular/core';
import { NgxHttpClient } from '../ngx-http-client';
import { lastValueFrom, Observable, Subject, tap } from 'rxjs';
import { DgError } from '../models/dg-error';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class PathwayAutoEnrollService {
  public get pathwayAutoEnrolledBeforeInputCompletion(): Observable<unknown> {
    return this._pathwayAutoEnrolledBeforeInputCompletion.asObservable();
  }
  private _pathwayAutoEnrolledBeforeInputCompletion = new Subject();

  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  public setHasPathwayAutoEnrolledBeforeCompletion(
    hasAutoEnrollmentOccurred: boolean
  ) {
    this._pathwayAutoEnrolledBeforeInputCompletion.next(
      hasAutoEnrollmentOccurred
    );
  }

  public async autoEnroll(pathwayId: number) {
    try {
      const request$ = this.http
        .post<void>('/pathways/enroll', {
          pathId: pathwayId,
          explicitEnrollment: false,
          trackableLinkTag: '',
        })
        .pipe(
          tap(() => this._pathwayAutoEnrolledBeforeInputCompletion.next(true))
        );
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_FollowError'), e);
    }
  }
}
