import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WindowToken } from '@app/shared/window.token';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
import { NotifierService } from '@app/shared/services/notifier.service';
import { OrgMembersService } from '@app/orgs/services/org-members.service';
import { SettingsRole } from '@app/orgs/services/orgs.model';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ContextService } from '@app/shared/services/context.service';
import { OrgMemberSummary, OrgMemberDetail } from '../../services/orgs.model';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { map, tap, finalize } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TrackerService } from '@app/shared/services/tracker.service';

export interface EditMemberModalResult {
  event: EditMemberModalEvent;
  memberRole?: SettingsRole;
}

export enum EditMemberModalEvent {
  DELETE,
  UPDATE,
}

@Component({
  selector: 'dgx-edit-member-modal',
  templateUrl: './edit-member-modal.component.html',
})
export class EditMemberModalComponent implements OnInit {
  @Input() public member: OrgMemberSummary;
  @Input() public orgId: number;

  @ViewChild('cancelRemovalButton')
  public cancelRemovalButton: ElementRef<HTMLButtonElement>;

  @ViewChild('softRemovalButton')
  public softRemovalButton: ElementRef<HTMLButtonElement>;

  public availableRoles: SettingsRole[];
  public assignedRole: SettingsRole;
  public canEditPermissions: boolean;
  public canRemoveMembers: boolean;
  public canEmailResetPassword: boolean;
  public canSetDefaultPassword: boolean;
  public confirmRemoval = false;
  public isLoading = false;
  public isSsoOnly = false;
  public isSubmitting = false;
  public memberDetail: OrgMemberDetail;
  public removing = false;

  private authUser: AuthUser;
  private userOrg: OrgInfo;
  private workingInTenant: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private contextService: ContextService,
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private notifierService: NotifierService,
    private orgSettingsService: OrgSettingsService,
    private orgMembersService: OrgMembersService,
    private translate: TranslateService,
    private trackerService: TrackerService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get headerTitle() {
    return this.canEditPermissions
      ? this.translate.instant('OrgMemberFormCtrl_Title')
      : this.translate.instant('OrgMemberFormCtrl_ViewUserSettings');
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.workingInTenant = this.contextService.workingInTenant();
    // if we're in channel, the permissions for modifying a tenant are set on the channel org, not based on the tenant.
    // also, tenant orgs are not populated in the orgInfo array
    this.userOrg = this.workingInTenant
      ? this.authUser.defaultOrgInfo
      : this.authUser.orgInfo.find((x) => x.organizationId === this.orgId);
    this.canRemoveMembers = this.userOrg.permissions.removeMembers;
    this.canEditPermissions = this.workingInTenant
      ? this.userOrg.permissions.editTenantPermissions
      : this.userOrg.permissions.editPermissions;

    this.isLoading = true;
    combineLatest([
      this.getAvailableRoles(),
      this.orgMembersService.getMemberDetail(
        this.orgId,
        this.member.userProfileId
      ),
      this.orgMembersService.allowEmailPasswordReset(this.orgId),
      this.orgMembersService.getIsSsoOnly(this.orgId),
      this.orgMembersService.allowSetPasswordToDefault(this.orgId),
    ]).subscribe(
      ([
        roles,
        memberDetail,
        canEmailResetPassword,
        isSsoOnly,
        canSetDefaultPassword,
      ]) => {
        this.availableRoles = roles;
        this.canEmailResetPassword = canEmailResetPassword;
        this.isSsoOnly = isSsoOnly;
        this.canSetDefaultPassword = canSetDefaultPassword;
        const defaultRole = roles.find((x) => x.roleId === 2); // HACK: until we support a real "default role" flag
        this.assignedRole =
          memberDetail.roles?.length > 0
            ? this.availableRoles.find(
                (x) => x.roleId === memberDetail.roles[0].roleId
              )
            : defaultRole;
        this.memberDetail = memberDetail;
        this.isLoading = false;
      }
    );
  }

  public trackByRoleId(index: number, role: SettingsRole) {
    return role.roleId;
  }

  public onRoleChange(role: SettingsRole) {
    this.assignedRole = role;
  }

  public emailResetPassword() {
    if (this.canEditPermissions) {
      this.orgMembersService
        .emailResetPassword(this.orgId, this.memberDetail.userProfileId)
        .pipe(
          tap(() => {
            this.notifierService.showSuccess(
              this.translate.instant(
                'OrgMemberFormCtrl_EmailResetPasswordSuccessMessageFormat',
                {
                  name: this.encodeToEntitiesPipe.transform(
                    this.memberDetail.name
                  ),
                }
              )
            );
          })
        )
        .subscribe();
    }
  }

  public softRemove() {
    this.confirmRemoval = true;
    setTimeout(() => {
      this.cancelRemovalButton.nativeElement.focus();
    });
  }

  public cancelRemove() {
    this.confirmRemoval = false;
    setTimeout(() => {
      this.softRemovalButton.nativeElement.focus();
    });
  }

  public remove() {
    this.removing = true;
    const removeCurrentUser =
      this.authUser.viewerProfile.userProfileId === this.member.userProfileId;
    this.orgMembersService
      .removeMember(this.orgId, this.member.userProfileId)
      .subscribe(() => {
        if (removeCurrentUser) {
          // reload necessary at this point to re-establish context of user
          this.windowRef.location.assign('/');
        } else {
          const result: EditMemberModalResult = {
            event: EditMemberModalEvent.DELETE,
          };

          this.activeModal.close(result);

          this.notifierService.showSuccess(
            this.translate.instant(
              'OrgMemberFormCtrl_RemovedMemberSuccessMessage',
              {
                name: this.encodeToEntitiesPipe.transform(
                  this.memberDetail.name
                ),
              }
            )
          );
        }
      });
  }

  public save() {
    if (this.canEditPermissions) {
      this.isSubmitting = true;
      this.orgMembersService
        .setMemberRole(
          this.orgId,
          this.member.userProfileId,
          this.assignedRole.roleId
        )
        .pipe(
          tap(() => {
            this.notifierService.showSuccess(
              this.translate.instant('Core_ChangesSaved')
            );
          }),
          finalize(() => (this.isSubmitting = false))
        )
        .subscribe(() => {
          this.activeModal.close({
            event: EditMemberModalEvent.UPDATE,
            memberRole: this.assignedRole,
          });

          this.authService.refreshCacheVersionToken();
        });
    }
  }

  public resetPasswordToDefault() {
    this.orgMembersService
      .resetPasswordToDefault(this.orgId, this.member.userProfileId)
      .pipe(
        tap(() => {
          this.trackerService.trackEventData({
            action: 'Org Reset Password to Default Button Clicked',
          });

          this.notifierService.showSuccess(
            this.translate.instant(
              'OrgMemberFormCtrl_ResetPasswordToDefaultSuccess'
            )
          );
        })
      )
      .subscribe();
  }

  private getAvailableRoles() {
    return this.orgSettingsService
      .getRoles(this.orgId, this.workingInTenant)
      .pipe(
        map((roles) => {
          roles.map((role) => {
            const availablePermissionsTranslated = [];
            role.labelKey = 'Role' + role.name.replace(/\s/g, '');
            role.name = this.translate.instant('Core_' + role.labelKey);

            role.permissions.forEach((permission) => {
              availablePermissionsTranslated.push(
                this.translate.instant(
                  'dgOrgSecurity_' + permission.name.replace(/\s/g, '')
                )
              );
            });
            role.permissionNames = availablePermissionsTranslated.join(', ');
          });
          return roles;
        })
      );
  }
}
