<dgx-modal
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  (dismiss)="closeModal()"
>
  <ng-container class="modal-header">
    {{ i18n.TagsSvc_ReplaceFocusInterestModalTitle }}
  </ng-container>

  <ng-container class="modal-body">
    <p
      class="guts-m-h-1-half guts-m-t-2 par par--small par--light replace-focus-modal-desc"
      [innerHtml]="i18n.TagsSvc_ReplaceFocusInterestModalDescription"
    ></p>
    <div *ngIf="isLoading" class="spinner"></div>
    <div
      *ngIf="!isLoading"
      class="l_flex l_flex-column guts-m-h-1-half guts-m-t-half guts-m-b-1-half"
    >
      <form novalidate>
        <div class="guts-p-b-1-half">
          <label
            *ngFor="let tag of tags"
            class="l_flex m-l_flex l_flex-middle tile tag-tile--horizontal tile--clickable guts-m-v-half guts-p-v-1-half"
            data-dgat="replaceFocusInterestModal-f90"
          >
            <input
              type="radio"
              [(ngModel)]="selectedTag"
              [value]="tag"
              name="selected-focus-skill"
              class="guts-m-l-half guts-m-r-1"
              data-dgat="replaceFocusInterestModal-1e1"
            />
            <dgx-tag-rating-donut
              class="ib guts-m-r-1"
              size="sm"
              [tag]="tag"
            ></dgx-tag-rating-donut>
            <div>
              <div
                [textContent]="tag.title"
                class="par par--small font-semibold break"
              ></div>
              <div
                [textContent]="getRatingLabel(tag)"
                class="par par--small par--light"
              ></div>
            </div>
          </label>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      type="submit"
      df-button
      dfButtonType="primary"
      [ngClass]="{ is_disabled: !selectedTag }"
      [disabled]="!selectedTag"
      [textContent]="i18n.TagsSvc_Replace"
      (click)="submit()"
      data-dgat="replaceFocusInterestModal-a8c"
    ></button>
  </ng-container>
</dgx-modal>
