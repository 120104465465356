import { Injectable, TemplateRef } from '@angular/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { EpisodeFormModel } from '../episode-forms.model';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { ContentHostingUploadAdapterService } from '@app/uploader/upload-section/adapters/content-hosting-upload-adapter.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';

@Injectable({ providedIn: 'root' })
export class EpisodeContentCatalogInitialRendererService
  implements FormRenderer
{
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('MediaFormCtrl_UrlRequired'),
    },
  };

  private urlValidatorAllowEmpty = {
    urlValidation: {
      ...this.urlValidator.urlValidation,
      expression: (control: AbstractControl, _) => {
        const valid =
          HTTP_URL_PATTERN.test(control.value) || control.value?.trim() === '';
        return valid;
      },
    },
  };

  constructor(
    private formBuilder: DfFormFieldBuilder,
    private contentHostingUploadAdapterService: ContentHostingUploadAdapterService,
    private translate: TranslateService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return this.renderInitialForm(context);
  }

  private renderInitialForm(context: RendererContext): DfFormFieldConfig[] {
    const vm = context.state();
    const contentHosting = this.buildContentHostingField(
      context.state,
      vm.shouldShowContentUploader,
      vm.isEditing,
      vm.hostedContentDetails,
      vm.inputId,
      context.templates.contentUploader
    );

    // Initial article loader input
    return [this.buildUrlField(), contentHosting];
  }

  private buildUrlField(): DfFormFieldConfig {
    return this.formBuilder
      .optionalTextInput('mediaUrl', 'dgOrgInternalContentForm_AudioURL')
      .ofType('url')
      .validatedByIndexed(this.urlValidatorAllowEmpty) // allow empty to be valid initially to not throw errors
      .withDgatId('episodeForm-a78')
      .autofocused()
      .build();
  }

  private buildContentHostingField(
    state: () => EpisodeFormModel,
    supportsContentHosting: boolean,
    isEditing: boolean,
    hostedContentDetails: HostedContentMetadata,
    inputId: number,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.formBuilder
      .customField('hostedContentDetails', '', contentUploaderTemplate, {
        useExistingFileRestrictions: true,
        file:
          isEditing && hostedContentDetails
            ? {
                name: hostedContentDetails.fileName,
                size: hostedContentDetails.fileSize,
              }
            : undefined,
        uploadAdapter: this.contentHostingUploadAdapterService.getAdapter(
          'Episode',
          inputId
        ),
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
        },
        i18n: this.translate.instant(['Core_Or']),
        hasDivider: true,
      })
      .unwrapped()
      .hiddenWhen(() => {
        const vm = state();
        return (
          !vm.shouldShowContentUploader ||
          isEditing ||
          (hostedContentDetails && hostedContentDetails.hostType !== 'Box')
        );
      })
      .build();
  }
}
