import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { InlineInputGetter } from '@app/inputs/inputs.model';
import { PostInput } from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { PathwaySettingsService } from '@app/pathways/services/pathway-settings.service';
import { PathwaySummaryModel } from '@app/pathways/pathway-api.model';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { FileUploadSetting } from '@app/uploader/uploader-api.model';
import { UploaderService } from '@app/uploader/uploader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { truncate, words } from 'lodash-es';
import { from, of } from 'rxjs';
import { InputValidationService } from '../services/input-validation.service';
import { NotificationType } from '@lib/fresco';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

@Component({
  selector: 'dgx-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.scss'],
})
export class PostFormComponent implements OnInit {
  @Input() public pathwayId: number;
  @Input() public inputId: number;
  @Input() public sourceTarget;
  @Input() public organizationId: number;
  @Input() public load: InlineInputGetter;

  public fileTypesAllowed: string[];
  public titleValidationMessage: string = '';
  public editMessage: string = '';
  public initialBody: string = '';
  public initialTitle: string = '';
  public contentValidationMessage: string = '';
  public disableForm: boolean = false;
  public input: PostInput;
  public clonedEditMessage: string;
  public isSubmitPending = false;
  public titleMaxLength = 255;
  public contentMaxLength = 15000;
  public contentTouched = false;
  public durationMinutesValidationError: string = null;
  public durationHoursValidationError: string = null;
  public readonly NotificationType = NotificationType;

  public i18n = this.translate.instant([
    'Core_Hours',
    'Core_Minutes',
    'Core_Preview',
    'PostFormCtrl_CreatePost',
    'PostFormCtrl_Title',
    'PostFormCtrl_TitleRequired',
    'PostFormCtrl_Content',
    'PostFormCtrl_EditPost',
    'PostFormCtrl_EditPostSave',
    'PostFormCtrl_CreatePostSave',
    'PostFormCtrl_EditingDescription',
    'PostFormCtrl_TitleLength',
    'PostFormCtrl_TitleRequired',
    'PostFormCtrl_ClonedEditMessage',
    'PostFormCtrl_AreYouSure',
    'deleteModal_Delete',
    'Core_FieldRequired',
    'Markdown_ContentLength',
    'Core_YesSure',
    'PostFormCtrl_AnotherPathway',
    'Core_Loading',
  ]);

  public postForm = new FormGroup(
    {
      title: new FormControl(undefined, [
        isEmptyValidator,
        Validators.required,
        Validators.maxLength(this.titleMaxLength - 1),
      ]),
      content: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(this.contentMaxLength - 1),
      ]),
      durationHours: new FormControl(undefined),
      durationMinutes: new FormControl(undefined),
    },
    {
      validators: this.postFormValidator,
    }
  );

  public isLoading = true;

  constructor(
    private inputsService: InputsService,
    private pathwaySettingsService: PathwaySettingsService,
    private translate: TranslateService,
    private detailModalService: DetailsModalService,
    private modalService: ModalService,
    private markdownService: MarkdownService,
    private trackerService: TrackerService,
    private activeModal: NgbActiveModal,
    private focusStackService: FocusStackService,
    private inputToLearningPipe: InputToLearningResourcePipe,
    private uploadService: UploaderService,
    private inputValidationService: InputValidationService
  ) {}

  public get title() {
    return this.postForm.get('title');
  }

  public get content() {
    return this.postForm.get('content');
  }

  public get durationHours() {
    return this.postForm.get('durationHours');
  }

  public get durationMinutes() {
    return this.postForm.get('durationMinutes');
  }

  public get showTitleErrors() {
    return this.title.errors && (this.title.dirty || this.title.touched);
  }

  public get durationHoursError() {
    return this.durationHours.errors;
  }

  public get durationMinutesError() {
    return this.durationMinutes.errors;
  }

  // TODO: Remove after ngx migration of postForm
  // Use contentTouched variable in place of dirty/touched b/c the markdowneditor is not using reactive forms
  public get showContentErrors() {
    return this.content.errors && this.contentTouched;
  }

  public get titleErrorMsg() {
    return this.title.errors?.required
      ? this.i18n.PostFormCtrl_TitleRequired
      : this.i18n.PostFormCtrl_TitleLength;
  }

  // TODO: Remove after ngx migration of postForm
  public get contentErrorMsg() {
    return this.content.errors?.required
      ? this.i18n.Core_FieldRequired
      : this.i18n.Markdown_ContentLength;
  }

  public get isSubmitDisabled() {
    return !this.postForm.valid || this.disableForm;
  }

  public get editing() {
    return !!this.inputId;
  }

  public get header() {
    return this.editing
      ? this.i18n.PostFormCtrl_EditPost
      : this.i18n.PostFormCtrl_CreatePost;
  }

  public ngOnInit(): void {
    // Feature flags only allow orgs that have opted in to edit
    this.disableForm = !this.inputsService.showCustomPostType();

    if (!this.disableForm && this.editing) {
      this.load(this.inputId, 'Post').subscribe((response: any) => {
        this.initEditForm(response);
        this.isLoading = false;
      });
    } else {
      // Initialize values for new posts
      this.input = {
        ...this.input,
        inputId: -1,
        inputType: 'Post',
        title: '',
        description: '',
        details: {
          body: '',
          originalPathwayId: this.pathwayId,
        },
        imageUrl: '',
        organizationId: this.organizationId,
      };
      this.isLoading = false;
    }

    this.uploadService
      .getUploadLimit('image')
      .subscribe((response: FileUploadSetting) => {
        this.fileTypesAllowed = response.allowedFileTypes;
      });
  }

  public disable() {
    this.disableForm = true;
  }

  public enable() {
    this.disableForm = false;
  }

  public isClonedPost(): boolean {
    return (
      this.editing &&
      this.input.details &&
      this.input.details.originalPathwayId &&
      this.pathwayId &&
      this.input.details.originalPathwayId !== this.pathwayId
    );
  }

  public preview() {
    // overwrite description in case user tried submitting unsuccessfuly
    // otherwise the stripped version gets displayed in the preview
    const previewInput = {
      ...this.input,
      title: this.title.value,
      description: '',
      resourceType: 'Post',
      resourceId: this.inputId,
      details: { ...this.input.details, body: this.content.value },
    };

    // TODO: ideally we create a Post component instead of rehashing the input details for posts
    const learningResource = this.inputToLearningPipe.transform(previewInput);
    this.detailModalService.openDetails(learningResource, {
      preview: true,
    });
  }

  // TODO: Remove after ngx migration of postForm
  public onContentChange(content: string) {
    this.contentTouched = true;
    this.content.setValue(content);
  }

  public confirmationModal() {
    // Show confirmation modal on-dismiss if content was updated
    if (!this.title.dirty && !this.content.dirty) {
      this.activeModal.dismiss();
      return;
    }

    const inputs = {
      canCancel: true,
      bodyClasses: 'h3 center-text',
      bodyText: this.i18n.PostFormCtrl_AreYouSure,
      submitButtonText: this.i18n.Core_YesSure,
    };
    this.focusStackService.push(this.sourceTarget);
    return this.modalService
      .show<any>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: false,
      })
      .subscribe({
        next: () => {
          this.activeModal.dismiss();
        },
      });
  }

  public onSubmit() {
    this.input.title = this.title.value; // Get form value
    this.input.details.body = this.content.value; // Get form value
    const scraperMaxChars = 2000; // match this with scraper's max
    this.isSubmitPending = true;

    // Save stripped down and truncated description.
    // This mimics what we do when we scrape an article and save a Description/Summary
    // Description/Summary should be displayed in the Input card until the Input modal is opened
    const strippedBody = this.markdownService.markdownToPlaintext(
      this.input.details.body
    );

    // Save first Markdown image as the ImageUrl
    const firstImage = this.markdownService.getFirstImage(
      this.input.details.body
    );
    this.input.imageUrl = firstImage ? firstImage : '';

    this.input.description = truncate(strippedBody, {
      length: scraperMaxChars,
    });

    this.input.orgContentMetadata = {
      ...this.input.orgContentMetadata,
      hideFromCatalog: true,
    };

    // NOTE: Normally word count is provided when we scrape articles,
    // but since we aren't scraping Posts, we need to provide this info up front
    this.input.durationUnitType = 'Words';
    this.input.durationUnits = words(this.input.details.body).length;

    this.input.durationHours = this.durationHours.value;
    this.input.durationMinutes = this.durationMinutes.value;

    from(of(this.input)).subscribe({
      next: () => {
        this.activeModal.close(this.input);

        const postLocation = this.editing
          ? 'Edit Pathway Post Modal'
          : 'Create Pathway Post Modal';

        this.trackerService.trackEventData({
          action: 'Create Pathway Post Saved',
          properties: {
            Location: postLocation,
          },
        });
      },
      error: () => {
        this.isSubmitPending = false;
      },
    });
  }

  // Creates an alert message that Post can not be edited with link back to parent Post.
  public getClonedEditMessage() {
    this.pathwaySettingsService
      .getPathSummary(this.input.details.originalPathwayId)
      .subscribe((response: PathwaySummaryModel) => {
        let pathwayTitle: string;
        if (response.title && response.internalUrl) {
          pathwayTitle = `<a href="${response.internalUrl}">${response.title}</a>`;
        } else {
          pathwayTitle = this.i18n.PostFormCtrl_AnotherPathway;
        }
        this.clonedEditMessage = this.translate.instant(
          'PostFormCtrl_ClonedEditMessage',
          {
            pathwayName: pathwayTitle,
          }
        );
      });
  }

  public get postFormValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const durationHours = form.controls['durationHours']?.value;
      const durationMinutes = form.controls['durationMinutes']?.value;
      const durationHoursAndMinutes: {
        durationHoursValidationError: string;
        durationMinutesValidationError: string;
      } = this.inputValidationService.getDurationValidationErrors(
        durationHours,
        durationMinutes
      );

      this.durationHoursValidationError =
        durationHoursAndMinutes.durationHoursValidationError;
      this.durationMinutesValidationError =
        durationHoursAndMinutes.durationMinutesValidationError;

      const durationHoursError = this.durationHoursValidationError
        ? {
            durationHoursValidationError: this.durationHoursValidationError,
          }
        : null;
      const durationMinutesError = this.durationMinutesValidationError
        ? {
            durationMinutesValidationError: this.durationMinutesValidationError,
          }
        : null;

      form.controls['durationHours'].setErrors(durationHoursError);
      form.controls['durationMinutes'].setErrors(durationMinutesError);

      return null;
    };
  }

  private initEditForm(_input) {
    this.input = _input;
    this.initialBody =
      _input.details && _input.details.body ? _input.details.body : '';
    this.initialTitle = _input.title ? _input.title : '';

    this.title.setValue(this.initialTitle);
    this.content.setValue(this.initialBody);
    this.durationHours.setValue(_input.durationHours);
    this.durationMinutes.setValue(_input.durationMinutes);

    // Check if pathway is cloned as Post of cloned pathways can not be edited.
    if (this.isClonedPost()) {
      this.disableForm = true;
      this.getClonedEditMessage();
    } else {
      // Default edit message
      this.editMessage = this.i18n.PostFormCtrl_EditingDescription;
    }
  }
}
