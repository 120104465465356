import { SimpleItemViewModel } from '@app/shared/models/core-view.model';
import { SearchAutocompleteState } from './search-autocomplete.model';
import { Key, isKey } from '@app/shared/key';

export function initState(): SearchAutocompleteState {
  return {
    suggestions: [],
    defaultSuggestions: [],
    searchTerm: '',
    initiationLocation: undefined,
    shouldShowSuggestions: false,
    termSuggestionsOnly: false,
    shouldShowInitiation: false,
    shouldShowAutocomplete: false,

    ///// POC Natural Language
    query: null,
    queryResponse: '',
    references: null,
    runningNLQuery: false,
  };
}

export function shouldShowInitiation(state: Partial<SearchAutocompleteState>) {
  const { searchTerm, termSuggestionsOnly } = state;

  const shouldShowInitiation =
    !termSuggestionsOnly && !termIsMinLength(searchTerm);

  return {
    ...state,
    shouldShowInitiation,
  };
}

export function shouldShowAutocomplete(
  state: Partial<SearchAutocompleteState>
) {
  const { searchTerm, shouldShowSuggestions } = state;

  const shouldShowAutocomplete =
    shouldShowSuggestions && termIsMinLength(searchTerm);

  return {
    ...state,
    shouldShowAutocomplete,
  };
}

export function shouldShowSuggestions(state: Partial<SearchAutocompleteState>) {
  const { searchTerm, suggestions, defaultSuggestions } = state;

  const suggestionCount = (
    termIsMinLength(searchTerm) ? suggestions : defaultSuggestions
  ).reduce((total, i) => total + i.subitems?.length, 0);

  const shouldShowSuggestions = suggestionCount > 0;

  return {
    ...state,
    shouldShowSuggestions,
  };
}

export function keydownHandler(
  event: KeyboardEvent,
  ...callbacks: (() => any)[]
): void {
  if (isKey(event, Key.Enter, Key.Space)) {
    // prevent spacebar from scrolling screen
    event.preventDefault();

    callbacks.forEach((callback) => callback());
  }
}

export function getItemTrackingKey(
  index: number,
  { trackingKey }: SimpleItemViewModel
): any {
  return trackingKey;
}

export function termIsMinLength(term: string) {
  return term?.length > 1;
}
