import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  EmbeddedLearningTag,
  GetPathwayGalleryResponse,
} from '../embedded-learning-api.model';

// export interface PathwayGalleryModel {
//   enrollments: UserPathway[];
//   paths: UserPathwaySummaryModel[];
//   facets: any[];
//   featured: UserPathwaySummaryModel[];
//   hasMoreItems: boolean;
// }

@Injectable({
  providedIn: 'root',
})
export class EmbeddedLearningService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  public getPathways(
    enrolledOnly: boolean,
    organizationId,
    searchTerm: string,
    facets: [],
    tagLabels: string[],
    count: number,
    skip: number
  ) {
    return this.http
      .get<GetPathwayGalleryResponse>('/pathways/getpathwaygallery', {
        params: {
          enrolledOnly: enrolledOnly,
          facets: JSON.stringify(facets),
          organizationId: organizationId,
          searchTerm: searchTerm,
          tags: tagLabels.join(),
          take: count,
          skip: skip,
        },
      })
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          return throwError(
            new DgError(
              this.translate.instant('AccountSvc_CreateAccountError') +
                ' ' +
                errorResponse.message,
              errorResponse
            )
          );
        })
      );
  }
}
