<article
  *ngIf="!isLoading; else loading"
  class="card"
  [ngClass]="{ 'card--clickable': isClickable }"
  [attr.aria-label]="cardAriaLabel"
>
  <div class="card__content">
    <!-- Card Body -->
    <ng-content select=".card-content"></ng-content>
  </div>

  <!-- Card Footer -->
  <ng-content select=".card-footer"></ng-content>
</article>

<ng-template #loading>
  <dgx-skeleton type="card"></dgx-skeleton>
</ng-template>
