import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SlugifyPipe } from '@app/shared/pipes/slugify.pipe';
import { TagsApi } from '@app/tags/tag-api.model';

@Component({
  selector: 'dgx-tag-rating-external-display',
  templateUrl: './tag-rating-external-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingExternalDisplayComponent implements OnInit {
  @Input() public tag: TagsApi.Tag;
  @Input() public ratingInfo: Partial<TagsApi.UserTagRating>;

  public imgSrc: string;

  constructor(private slugify: SlugifyPipe) {}

  public ngOnInit(): void {
    this.setImageSrc();
  }

  private setImageSrc(): void {
    const provider = this.slugify.transform(this.ratingInfo.providerName);
    this.imgSrc = `/content/img/app/tag-ratings/${provider}.svg`;
  }
}
