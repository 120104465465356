import { Injectable, Injector } from '@angular/core';

import {
  UserEpisode,
  UserEpisodeParameters,
  UserExistingEpisodeParameters,
  UpdateUserEpisodeParameters,
} from '@app/inputs/inputs-api.model';
import { UserInputTypeServiceBase } from '@app/inputs/services/user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Podcast Episodes */
@Injectable({
  providedIn: 'root',
})
export class UserEpisodeService extends UserInputTypeServiceBase<
  'Episode',
  UserEpisode,
  UserEpisodeParameters,
  UserExistingEpisodeParameters,
  UpdateUserEpisodeParameters
> {
  constructor(injector: Injector) {
    super(injector, '', 'Episode');
  }
}
