import { TranslateService } from '@ngx-translate/core';
import { Component, Input } from '@angular/core';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';

@Component({
  selector: 'dgx-org-managed-notification',
  templateUrl: './org-manage-notification.component.html',
  styleUrls: ['./org-managed-notification.component.scss'],
})
export class OrgManagedNotificationComponent {
  @Input() public isCombinedSearch = false;
  @Input() public resourceType: 'Pathway' | 'Target' | 'SkillPlan';
  @Input() public linkTextKey = this.translateService.instant(
    'Core_VisibilityDocLinkLabel'
  );
  @Input() public link: string = '';

  public orgManagedLink: string;
  private linkConfig = {
    Pathway: {
      link: '/articles/4408921448978',
    },
    Target: {
      link: '/articles/4408921475602',
    },
  };

  constructor(
    private displayTypePipe: DisplayTypePipe,
    private translateService: TranslateService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public get message() {
    const translationKey = this.isCombinedSearch
      ? 'Core_OrgManagedNotificationPlural'
      : 'Core_OrgManagedNotification';
    return this.translateService.instant(translationKey, {
      resourceType: this.displayTypePipe.transform(this.resourceType),
    });
  }

  public ngOnInit() {
    this.orgManagedLink = this.webEnvironmentService.getZendeskUrl(
      this.link ? this.link : this.linkConfig[this.resourceType].link
    );
  }
}
