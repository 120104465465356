import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GroupItem } from '@app/groups/group-api';
import {
  Candidate,
  CandidatesSearchParams,
  Opportunity,
  OpportunityApplicationStage,
  OpportunityDetails,
  PagedCandidatesGetResponse,
} from '@app/opportunities/opportunities-api.model';
import {
  Filter,
  FilterIcon,
} from '@app/shared/components/filter/filter.component';
import { Facet } from '@app/shared/models/core-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { FilterService } from '@app/shared/services/filter.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { numberFromString, remove } from '@app/shared/utils/common-utils';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { isEqual as _isEqual, sortBy as _sortBy } from 'lodash-es';
import { BehaviorSubject, concat, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  finalize,
  map,
  retryWhen,
  take,
  tap,
} from 'rxjs/operators';
import { OpportunityApplicationStageEnum } from '../opportunities.enums';
import { hasExternalProvider } from '../utils';
import { OpportunityServiceBase } from './opportunity-service-base';

export enum StatusHighlights {
  NoResponse = 'neutral',
  Interested = 'success',
  InviteSent = 'emphasize',
  NotInterested = 'danger',
}

/**
 * This service is intended to be used for methods that are exclusively utilized by
 * the Opportunity view pages -- the header and tabs, the modals, etc. Anything that
 * is also used by opportunity cards or the "Manage Opportunities" page should be in
 * the opportunity-service-base instead.
 *
 * @type {OpportunityServiceBase}
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunityViewService extends OpportunityServiceBase {
  private readonly discoverWaitBetweenAttempts = 2000;
  private readonly discoverAttemptsToMakeBeforeFailing = 15;
  private showArchivedOnly = false;
  private updatedOpportunitySubject = new BehaviorSubject<Opportunity>(
    {} as Opportunity
  );
  private opportunityDetails: OpportunityDetails;
  private _deletedOpportunityIds = new BehaviorSubject<number[]>([]);
  private _viewedOpportunityId: number;
  private _viewedOpportunityIsDeleting = false;
  private _viewedOpportunityIsLoading = false;

  constructor(
    private filterService: FilterService,
    private windowLayoutService: WindowLayoutService,
    authService: AuthService,
    http: NgxHttpClient,
    notifierService: NotifierService,
    pageTitle: Title,
    translateService: TranslateService,
    @Inject(WindowToken) windowRef: Window
  ) {
    super(
      authService,
      http,
      notifierService,
      pageTitle,
      translateService,
      windowRef
    );
  }

  /**
   * This subject is emitted when an opportunity is deleted externally, and
   * is consumed by another view elsewhere. It is used to sync changes
   * between tabs, etc.
   *
   * TODO: Replace this system with true NgRX state management.
   */
  public get deletedOpportunityIds$(): Observable<number[]> {
    return this._deletedOpportunityIds.asObservable();
  }

  /**
   * This subject is emitted when an opportunity is edited via a modal,
   * and consumed by the landing page view. It is used to sync changes to
   * description between the modal there and the Overview tab.
   *
   * This is necessary to avoid making additional API calls.
   *
   * TODO: Replace this system with true NgRX state management.
   */
  public get updatedOpportunity$(): Observable<Opportunity> {
    return this.updatedOpportunitySubject.asObservable();
  }

  public set viewedOpportunityIsLoading(isLoading: boolean) {
    this._viewedOpportunityIsLoading = isLoading;
  }
  public get viewedOpportunityIsLoading(): boolean {
    return this._viewedOpportunityIsLoading;
  }

  public set viewedOpportunityId(id: number) {
    this._viewedOpportunityId = id;
  }
  public get viewedOpportunityId(): number {
    return this._viewedOpportunityId;
  }

  public set viewedOpportunityIsDeleting(isDeleting: boolean) {
    this._viewedOpportunityIsDeleting = isDeleting;
  }
  public get viewedOpportunityIsDeleting(): boolean {
    return this._viewedOpportunityIsDeleting;
  }

  /**
   * Used to determine route access. Also incidentally (but crucially) sets
   * the local OpportunityDetails object.
   *
   * @param opportunityId - Required. Opportunity ID.
   */
  public canAccessOpportunity(
    opportunityId: number | string
  ): Observable<boolean> {
    return this.getOpportunityDetails(opportunityId).pipe(
      // Is the opportunity defined? Or did we get a 404?
      map((details) => {
        return !!details?.opportunity;
      })
    );
  }

  /**
   * Used to determine route access. Also incidentally (but crucially) sets
   * the local OpportunityDetails object.
   *
   * @param opportunityId - Required. Opportunity ID.
   */
  public canAccessOpportunityInterested(
    opportunityId: number | string
  ): Observable<boolean> {
    return this.getOpportunityDetails(opportunityId).pipe(
      // Does this opportunity have an external provider? No Interested tab!
      map((details) => !hasExternalProvider(details?.opportunity))
    );
  }

  /**
   * Used to determine route access. Also incidentally (but crucially) sets
   * the local OpportunityDetails object.
   *
   * @param opportunityId - Required. Opportunity ID.
   */
  public canAccessOpportunityManagement(
    opportunityId: number | string
  ): Observable<boolean> {
    return this.getOpportunityDetails(opportunityId).pipe(
      // Can the user edit this particular opportunity, for whatever reason?
      map((details) => details.userCanEditOpportunity)
    );
  }

  /**
   * Get Candidates from the back end. All params optional.
   *
   * @param filters - The filters to send back as facets.
   * @param opportunityId - The opportunity being searched.
   * @param orderBy - Column by which to sort the items.
   * @param pageNum - The page number to return items for.
   * @param sortDescending - Whether to sort items acb or zyx.
   * @param takeNum - Number of items to return.
   * @param term - Search term.
   */
  public getCandidates(
    {
      filters = [],
      opportunityId = 1,
      orderBy = 'skillMatch',
      pageNum = 1,
      sortDescending = true,
      term = '',
      takeNum = 10,
    }: CandidatesSearchParams = {},
    icons: FilterIcon[] = []
  ): Observable<PagedCandidatesGetResponse> {
    let facets = this.filterService.filtersToFacets(filters);

    const stageSelected = facets.some((f) => f.id === 'Stage');
    const archiveSelected = facets.some((f) => f.id === 'Archived');

    // either 'Stage' or 'Archived' is present among our facets
    if (stageSelected || archiveSelected) {
      // only 'Stage' is present
      if (!archiveSelected) {
        // set our flag to false for later!
        this.showArchivedOnly = false;
      }
      // only 'Archived' is present
      else if (!stageSelected) {
        this.showArchivedOnly = true;
        facets = this.removeStageFacets(facets, true);
      }
      // both are present
      else {
        // the user must be TRYING to set showArchivedOnly to FALSE
        if (this.showArchivedOnly) {
          this.showArchivedOnly = false;
          facets = this.removeArchivedFacet(facets);
        }
        // the user must be TRYING to set showArchivedOnly to TRUE
        else {
          this.showArchivedOnly = true;
          facets = this.removeStageFacets(facets);
        }
      }
    }
    // neither is among our facets
    else {
      // sanity check
      this.showArchivedOnly = false;
    }

    return this.http
      .get<PagedCandidatesGetResponse>(`${this.baseUrl}/getcandidates`, {
        params: {
          facets: JSON.stringify(facets),
          opportunityId,
          orderBy,
          pageNum,
          sortDescending,
          take: takeNum,
          term,
        },
      })
      .pipe(
        map((data: PagedCandidatesGetResponse) =>
          this.formatCandidatesData(data, icons)
        ),
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant(
                'Opportunities_Candidates_Error_Get'
              ),
              error
            )
          )
        )
      );
  }

  /**
   * Get Discover from the back end. All params optional.
   *
   * @param filters - The filters to send back as facets.
   * @param opportunityId - The opportunity being searched.
   * @param orderBy - Column by which to sort the items.
   * @param pageNum - The page number to return items for.
   * @param sortDescending - Whether to sort items acb or zyx.
   * @param takeNum - Number of items to return.
   * @param term - Search term.
   */
  public getDiscoverCandidates({
    filters = [],
    opportunityId = 1,
    orderBy = 'skillMatch',
    pageNum = 1,
    sortDescending = true,
    term = '',
    takeNum = 10,
  }: CandidatesSearchParams = {}): Observable<PagedCandidatesGetResponse> {
    return this.http
      .get<PagedCandidatesGetResponse>(`${this.baseUrl}/discovercandidates`, {
        observe: 'response',
        params: {
          facets: JSON.stringify(
            filters.length ? this.filterService.filtersToFacets(filters) : []
          ),
          opportunityId,
          orderBy,
          pageNum,
          sortDescending,
          take: takeNum,
          term,
        },
      })
      .pipe(
        map((response: HttpResponse<PagedCandidatesGetResponse>) => {
          if (response.status === 202) {
            throw response;
          }
          return this.formatDiscoverData(response.body);
        }),
        retryWhen((response) =>
          response.pipe(
            concatMap((response) => {
              // We can specify the retry only if we are getting back a 202.
              if (response.status === 202) {
                return of(response);
              }
              // in other cases we throw an error down the pipe
              return throwError(
                // Due to the way the retryWhen / take combination works, we are
                // just returning a normal error here and displaying it manually in the
                // discover component.
                this.translateService.instant(
                  'Opportunities_Discover_Error_Get'
                )
              );
            }),
            delay(this.discoverWaitBetweenAttempts),
            // we can keep calling forever but usually we want to avoid this.
            // So, we set the number of attempts including the initial one.
            take(this.discoverAttemptsToMakeBeforeFailing),
            (observable) =>
              concat(
                observable,
                // this error will occur after we reach the max number of attempts to try
                throwError(
                  // Due to the way the retryWhen / take combination works, we are
                  // just returning a normal error here and displaying it manually in the
                  // discover component.
                  this.translateService.instant(
                    'Opportunities_Discover_Error_Get'
                  )
                )
              )
          )
        )
      );
  }

  /**
   * Get an opportunity *and* the current user's ability to edit that opportunity
   * from the backend by ID. **Should only be used by OpportunityDetails.Resolve.**
   * *For other opportunity overview needs, use OpportunityServiceBase's
   * getOpportunityOverview.*
   *
   * @param opportunityId - Required. Opportunity ID.
   * @param skipCache - If skip cache.
   */
  public getOpportunityDetails(
    opportunityId: string | number
  ): Observable<OpportunityDetails> {
    // set loading state
    this.viewedOpportunityIsLoading = true;
    // transform opportunityId
    opportunityId = numberFromString(opportunityId);
    // check for "cached" opportunityDetails
    if (this.opportunityDetails?.opportunity?.opportunityId === opportunityId) {
      return of(this.opportunityDetails).pipe(
        finalize(() => (this.viewedOpportunityIsLoading = false))
      );
    }
    // otherwise, get fresh data
    return this.getOpportunityDetailsBasis(opportunityId).pipe(
      // update our local copy of opportunityDetails
      tap((details) => this.setOpportunityDetails(details)),
      // update loading state
      finalize(() => (this.viewedOpportunityIsLoading = false)),
      // handle errors
      catchError((error) => {
        // 404s we want to handle differently
        if (error?.status === 404) {
          return of({
            hasExternalProvider: false,
            opportunity: undefined,
            totalSkills: 0,
            userCanEditOpportunity: false,
          });
        }
        // careful this will return an error in the the form of an
        // observable that emits only an error, not throw an actual error
        return throwError(
          new DgError(
            this.translateService.instant('Opportunities_View_Error_Get'),
            error
          )
        );
      })
    );
  }

  /**
   * Update opportunity for the service globally
   * @param opportunity
   */
  public rehydrateOpportunity(opportunity: Opportunity) {
    // Ensure opportunityDetails is *always* defined; when this
    // comes from the browse card view, or other places where we
    // are updating an individual opportunity from a big list of them,
    // it may not be!
    this.opportunityDetails ??= {} as OpportunityDetails;
    // Update details (shouldn't be necessary, but doesn't hurt)
    this.setOpportunityDetails({
      userCanEditOpportunity:
        // These IDs should *always* match, as each service in
        // each tab is an individual instance of that service.
        // But sanity-checking here anyway.
        this.opportunityDetails.opportunity?.opportunityId ===
        opportunity.opportunityId
          ? !!this.opportunityDetails.userCanEditOpportunity
          : false,
      hasExternalProvider: hasExternalProvider(opportunity),
      opportunity,
      totalSkills: opportunity.tags.length,
    });
    // Update subject
    this.updatedOpportunitySubject.next(opportunity);
  }

  /**
   * Action upon clicking on the "Remove" option for a opportunity flex-row resource
   * (Plan/Pathway) meatball menu.
   *
   * @param opportunityId - Current Opportunity ID.
   * @param item - Item to remove.
   * @param existingResources - Current resources in this row.
   */
  public removeResource({
    opportunityId,
    resourceId,
    resourceType,
    existingResources,
  }: {
    opportunityId: number;
    resourceId: any;
    resourceType: string;
    existingResources: any[];
  }): Observable<void> {
    // remove mutates the original array, but *returns*
    // a new array containing the removed items
    remove(
      existingResources,
      (resource) =>
        resource.resourceId === resourceId && resourceType === resourceType
    );
    // so we pass the *original*, but mutated, array to updateResources
    return this.updateResources(opportunityId, existingResources).pipe(
      catchError(() => of([]))
    );
  }

  /**
   * Compare new to existing skills on the basis of .name.
   * Fully case-insensitive comparison.
   *
   * @param newSkills - Array of new skills.
   * @param existingSkills - Array of existing skills.
   */
  public skillsHaveChanged(newSkills: any[], existingSkills: any[]) {
    // don't bother with more complicated checks if obviously different
    if (existingSkills.length !== newSkills.length) {
      return true;
    }
    // simplify arrays
    existingSkills = existingSkills.map((skill) => ({
      name: skill.name.toLowerCase(),
    }));
    newSkills = newSkills.map((tag) => ({
      name: tag.name.toLowerCase(),
    }));
    return !_isEqual(
      _sortBy(newSkills, 'name'),
      _sortBy(existingSkills, 'name')
    );
  }

  public updateDeletedOpportunityIds(opportunityIds: number[]) {
    this._viewedOpportunityIsDeleting = true;
    this._deletedOpportunityIds.next(opportunityIds);
  }

  public buildCustomGroupsFilter(opportunity: Opportunity): Filter {
    const groupsFacet: Facet = {
      id: 'GroupIds',
      name: 'GroupIds',
      values: opportunity.groupIds?.map((group: GroupItem) => ({
        id: `${group.id}`,
        name: group.name,
        filter: false,
        count: -1, // Count is disregarded as this filter does not show count (ignoreCount)
      })),
    };

    return this.filterService.facetToFilter(groupsFacet, {
      title: this.translateService.instant('Core_Group'),
      ignoreCount: true,
    });
  }

  private formatCandidatesData(
    { facets, items, total, unfilteredTotal }: PagedCandidatesGetResponse,
    icons: FilterIcon[] = []
  ) {
    // reorder facets, then turn them into filters
    // because dataFilters will spit facets out back
    // in the same order we get them from the server
    const {
      archivedFacetIndex,
      configs,
      facets: updatedFacets,
    } = this.prepCandidatesFacetsForFilters(facets, icons);

    return {
      filters: this.filterService.facetsToFilters(updatedFacets, configs),
      items: this.manipulateCandidates(
        items,
        updatedFacets[archivedFacetIndex]
      ),
      total,
      unfilteredTotal,
    };
  }

  private formatDiscoverData({
    facets,
    items,
    total,
    unfilteredTotal,
  }: PagedCandidatesGetResponse): PagedCandidatesGetResponse {
    return {
      // TODO: Get this working. We're not getting the right facet from the BE.
      // filters: this.filter.facetsToFilters(facets),
      items: items.map((item) => ({
        ...item,
        vanityUrl: `/${item.vanityUrl}`,
      })),
      total,
      unfilteredTotal,
    };
  }

  // This whole thing is such a gross mess. Filter Redux, where this kind of manipulation
  // will be wholly unnecessary, can't come soon enough.
  // PD-50689
  private manipulateCandidates(
    items: Candidate[],
    archived: Facet
  ): Candidate[] {
    let candidates = items.map((item) => ({
      ...item,
      vanityUrl: `/${item.vanityUrl}`,
    }));

    // We want to exclude Archived status candidates if the "Show archived only" checkbox not checked.
    // We are safe to do values[0] because the Archived facet is created in FE,
    // and we are sure it will only contain one element in the values array
    if (!archived.values[0].filter) {
      candidates = candidates.filter((i) => i.stage !== 'Archived');
    }

    return candidates;
  }

  private manipulateCandidatesFacets(facets: Facet[]): Facet[] {
    // Avoid mutating the array that was passed in
    const updatedFacets = [...facets];

    const stageFacetIndex = updatedFacets.findIndex((f) => f.id === 'Stage');

    if (stageFacetIndex >= 0) {
      const archivedFaceValue = updatedFacets[stageFacetIndex].values.find(
        (v) => v.id === OpportunityApplicationStageEnum.Archived
      );
      const archivedFacet = {
        id: 'Archived',
        name: 'Archived',
        values: [
          {
            ...archivedFaceValue,
            // set `filter` match `this.showArchivedOnly`
            filter: this.showArchivedOnly,
          },
        ],
      };

      updatedFacets[stageFacetIndex].values = updatedFacets[
        stageFacetIndex
      ].values
        // We need to filter out the Archived and None status in the Stage facet
        .filter(
          (v) =>
            v.id !== OpportunityApplicationStageEnum.Archived &&
            v.id !== OpportunityApplicationStageEnum.None
        )
        .map((value) => {
          return {
            ...value,
            // Our Stage facet is not user-generated, so
            // its titles *should* be translated.
            name: this.translateService.instant(
              `Opportunities_Candidates_Stage_${value.id}`
            ),
            // set `filter` to false if `this.showArchivedOnly` is true
            filter: this.showArchivedOnly ? false : value.filter,
          };
        });

      updatedFacets.push(archivedFacet);
    }

    const locationFacetIndex = updatedFacets.findIndex(
      (f) => f.id === 'Location'
    );

    // Move Stage facet to before Location facet without
    // affecting any *other* facets that might be added later
    if (stageFacetIndex >= 0 && locationFacetIndex >= 0) {
      return this.moveByIndex(
        updatedFacets,
        locationFacetIndex,
        stageFacetIndex
      );
    }
    return updatedFacets;
  }

  private moveByIndex(arr: any[], oldIndex: number, newIndex: number) {
    // Splice mutates, so we make a copy here to avoid that.
    const arrCopy = [...arr];
    while (oldIndex < 0) {
      oldIndex += arrCopy.length;
    }
    while (newIndex < 0) {
      newIndex += arrCopy.length;
    }
    if (newIndex >= arrCopy.length) {
      let k = newIndex - arrCopy.length;
      while (k-- + 1) {
        arrCopy.push(undefined);
      }
    }
    arrCopy.splice(newIndex, 0, arrCopy.splice(oldIndex, 1)[0]);
    return arrCopy;
  }

  private prepCandidatesFacetsForFilters(
    facets: any[],
    icons: FilterIcon[] = []
  ): { facets: any[]; configs: any[]; archivedFacetIndex: number } {
    const configs = [];

    // reorder facets, then turn them into filters
    // because dataFilters will spit facets out back
    // in the same order we get them from the server
    const updatedFacets = this.manipulateCandidatesFacets(facets);

    const archivedFacetIndex = updatedFacets.findIndex(
      (facet) => facet.id === 'Archived'
    );

    const stageFacetIndex = updatedFacets.findIndex(
      (facet) => facet.id === 'Stage'
    );
    const locationFacetIndex = updatedFacets.findIndex(
      (facet) => facet.id === 'Location'
    );

    const archivedValues = updatedFacets[archivedFacetIndex]?.values;
    const archivedIsDisabled =
      archivedValues && archivedValues.length > 0
        ? // not currently being filtered AND there are NO archived users
          !archivedValues[0].filter && archivedValues[0].count < 1
        : true;
    // We want to make Archived as a checkbox
    configs[archivedFacetIndex] = {
      title: this.translateService.instant(
        'Opportunities_Candidates_ShowArchived'
      ),
      checkboxFilterId: 'Archived',
      isCheckboxFilter: true,
      showEmptyOptions: true,
      isDisabled: archivedIsDisabled,
    };

    configs[stageFacetIndex] = {
      icons,
      showEmptyOptions: true,
    };

    configs[locationFacetIndex] = {
      showEmptyOptions: true,
    };

    return {
      archivedFacetIndex,
      configs,
      facets: updatedFacets,
    };
  }

  private removeArchivedFacet(facets: any[]) {
    // First, remove the 'Archived' facet, if it's present
    const updatedFacets = facets.filter((facet) => facet.id !== 'Archived');
    // Next, filter the 'Archived' value out of the 'Stage' facet, if *it's*
    // present.
    return updatedFacets.map((facet) => {
      // for the 'Stage' facet, filter out the archived value
      if (facet.id === 'Stage') {
        facet.values.filter(
          (value: OpportunityApplicationStage) =>
            value !== OpportunityApplicationStageEnum.Archived
        );
      }
      return facet;
    });
  }

  private removeStageFacets(facets: any[], addStagedFacet = false): Facet[] {
    // First, remove the 'Archived' facet, if it's present
    // (Because this isn't how we want to send the Archived value
    // to the backend.)
    const updatedFacets = facets.filter((facet) => facet.id !== 'Archived');
    // Next, add the archived facet to the 'Stage' facet
    // If the 'Stage' facet is missing, just add it fresh
    if (addStagedFacet) {
      return [
        ...updatedFacets,
        {
          id: 'Stage',
          name: 'Stage',
          values: [OpportunityApplicationStageEnum.Archived],
        },
      ];
    }
    // Otherwise, find the 'Stage' facet and replace existing values with 'Archived'
    return updatedFacets.map((facet) => {
      if (facet.id === 'Stage') {
        facet.values = [OpportunityApplicationStageEnum.Archived];
      }
      return facet;
    });
  }

  /**
   * Set the local OpportunityDetails object.
   *
   * @param details - Opportunity details
   */
  private setOpportunityDetails(details: OpportunityDetails) {
    this.opportunityDetails = details;
  }
}
