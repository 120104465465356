import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { map, tap } from 'rxjs';

// modules
import { MarkdownModule } from '@app/markdown/markdown.module';

// services
import { OrgService } from '@app/orgs/services/org.service';
import { AuthService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import {
  COMMENT_MAX_LENGTH,
  MentionService,
} from '../../services/mention.service';

// components/directives
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { ValidateFieldComponent } from '@app/shared/components/validate-field/validate-field.component';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';

// misc
import { MentionItem, MentionUserMatch } from '@app/comments/comments.model';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import { MentionDirective } from '../../directives/mention.directive';
import { MentionToHtmlPipe } from '../../pipes/mention-to-html.pipe';

// TODO: AutosizeDirective would be MUCH better as a member of hostDirectives on MentionDirective,
// but can't be placed there because it isn't standalone.
// Ideally: replace AutosizeDirective with something standalone.

@Component({
  selector: 'dgx-mention-input',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MarkdownModule,
    AutofocusDirective,
    AutosizeModule,
    MentionDirective,
    MentionToHtmlPipe,
    ValidateFieldComponent,
  ],
  templateUrl: './mention-input.component.html',
  styleUrl: './mention-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionInputComponent
  extends SubscriberBaseDirective
  implements OnChanges, OnInit
{
  @Input() public ariaLabel?: string;
  @Input() public autoFocus = true;
  @Input() public dgatInput?: string;
  @Input() public form: FormGroup;
  @Input() public isEditing = false;
  @Input() public isMentioning = false;
  @Input() public placeholder?: string;

  /** Only used by groups. */
  @Input() public groupId?: number;
  /** Only used by groups. */
  @Input() public groupPrivacy?: GroupPrivacyLevel;

  @Output() public focused = new EventEmitter<void>();

  @ViewChild('inputRef')
  public inputRef: ElementRef<HTMLElement>;

  public maxLength = COMMENT_MAX_LENGTH;
  public orgId: number;
  public searchResults: MentionUserMatch[] = [];

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private orgService: OrgService,
    private mentionService: MentionService,
    private translateService: TranslateService
  ) {
    super();
  }

  public get mentions(): MentionItem[] {
    return this.form?.value?.mentions || [];
  }

  public get value(): string {
    return this.form?.value?.comment;
  }

  public get errorMessage(): string {
    if (!this.form?.get('comment').hasError('maxlength')) {
      return '';
    }

    return this.translateService.instant(
      'OrgManage_Opportunities_Valid_MaxLength',
      { number: this.value.length - this.maxLength }
    );
  }

  public ngOnInit(): void {
    this.orgId = this.authService.authUser?.orgInfo[0].organizationId;
    this.groupId =
      this.groupPrivacy === GroupPrivacyLevel.Open ? undefined : this.groupId;
  }

  // TODO: View model. This isn't terrible (only runs when isEditing actually changes),
  // but still feels hacky.
  public ngOnChanges({ isEditing }: SimpleChanges): void {
    if (!isEditing || isEditing?.firstChange || !this.value) {
      return;
    }

    if (this.isEditing) {
      this.form.patchValue({
        comment: this.mentionService.formatCommentAndMentionsForEdit(
          this.value,
          this.mentions
        ),
      });
    }
  }

  public onSearch(searchTerm: string): void {
    this.isMentioning = true;
    this.orgService
      .getMentionUserMatches(
        this.orgId,
        searchTerm.trim(),
        5,
        0, // the original code was false, but the property skip is defined as integer by the API
        this.groupId
      )
      .pipe(
        map(({ items }) => items),
        tap((results: MentionUserMatch[]) => {
          this.searchResults = results?.length ? results : [];
          this.isMentioning = false;
          this.cdr.detectChanges();
        }),
        this.takeUntilDestroyed()
      )
      .subscribe();
  }

  /**
   * All this does is add mentions. Mentions will be removed elsewhere.
   *
   * @param mention
   */
  public onAddMention(mention: MentionUserMatch): void {
    this.form.patchValue({ mentions: [...this.mentions, mention] });
  }

  /**
   * Called externally to focus our native textarea and trigger its resize event,
   * where needed.
   */
  public reset() {
    this.inputRef.nativeElement.focus();
    // Trigger input event, which will in turn resize the textarea if needed.
    this.inputRef.nativeElement.dispatchEvent(new Event('input'));
  }
}
