<!-- Inputs -->
<div
  *ngIf="item.statistics && showStatistics"
  class="tile-launcher__statistics"
>
  <dgx-input-statistics
    [input]="item"
    layout="learner-home"
  ></dgx-input-statistics>
</div>

<dgx-content-card
  *ngIf="
    isInput() || isPathway() || isTarget() || isDirectory() || isProvider()
  "
  [cardFormat]="cardFormat"
  [appendMenuToBody]="appendMenuToBody"
  [content]="item | camelCase | inputToLearningResource"
  [recommendationInfo]="recommendationInfo"
  [hideFooter]="hideFooter"
  [hideImage]="hideImage || isProvider()"
  [isPreview]="isPreview"
  [modifyOptionsFn]="modifyOptionsFn"
  [sectionType]="section"
  [isSelected]="isSelected"
  [isClickable]="isClickable"
  [expandCard]="expandCard"
  [cardIndex]="tileIndex"
  [lazyChunkIndex]="lazyChunkIndex"
  (clickedTitleEllipses)="clickedTitleEllipses.emit($event)"
>
</dgx-content-card>

<!-- Tags  -->
<dgx-tag-tile
  *ngIf="isTag() && !initialTagAction; else useTagRecommendation"
  [isOwner]="true"
  [tag]="tag"
  [actionsContext]="actionContext"
  class="suggested-section__tile"
></dgx-tag-tile>

<ng-template #useTagRecommendation>
  <dgx-tag-recommendation-tile
    *ngIf="isTag()"
    [tag]="tag"
    [initialAction]="initialTagAction"
    [actionsContext]="actionContext"
    [assignmentDueDate]="assignmentDueDate"
    [menuConfig]="menuConfig"
    [recommendationInfo]="recommendationInfo"
    (dismiss)="dismissTagRecommendation.emit()"
  ></dgx-tag-recommendation-tile>
</ng-template>

<!-- Opportunities -->
<dgx-opportunity-card
  *ngIf="isOpportunity()"
  [descriptionLength]="opportunityDescriptionLength"
  [opportunity]="opportunity"
  (dismiss)="dismiss.emit($event)"
  class="full-height"
></dgx-opportunity-card>

<!-- Users -->
<dgx-user-card
  *ngIf="isUser()"
  [user]="item | camelCase"
  [modifyOptionsFn]="modifyOptionsFn"
  class="block tile__wrapper"
></dgx-user-card>
