import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { A11yService } from '../../services/a11y.service';

/**
 * Presents plain or html text, truncated with an ellipsis (aka line clamping)
 * WARNING: if you see text being cut off it's probably sovlable by switching to margins from padding on the element or children
 * @param dgxLineClampText pass plain text in to this selector
 * @param dgxLineClampHtml pass html strings in to this selector instead
 * @param clampLinesMax number of lines to display before clamping
 * @emits clamped is true when number of lines is greater than clampLinesMax property causing the clamping to happen.
 */
@Directive({
  selector: '[dgxLineClampText],[dgxLineClampHtml]',
})
export class LineClampDirective implements OnChanges {
  private static UPDATE_TIMEOUT = 250; // ms

  @Input() public clampLinesMax = 2;
  @Input('dgxLineClampText') public textcontent: string;
  @Input('dgxLineClampHtml') public htmlcontent: string;

  @Output() public clamped = new EventEmitter<boolean>();

  private updateTimer: any;
  private wrapperClass = 'line-clamp-outer-div';

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    private a11yService: A11yService
  ) {}

  public ngOnChanges(): void {
    if (this.clampLinesMax !== undefined) {
      this.clamp();
    } else {
      this.appendContent();
      this.cleanup();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(() => {
      this.clamp();
    }, LineClampDirective.UPDATE_TIMEOUT);
  }

  private clamp(): void {
    const el = this.elementRef.nativeElement;
    this.appendContent();
    const elementStyles = getComputedStyle(el);
    const fullHeight = elementStyles.height;
    const lineHeight = parseInt(elementStyles.lineHeight);
    const bodyElements = document.getElementsByName('body');
    const bodyFontSize = bodyElements?.length;
    this.renderer.addClass(el, 'clamp');
    this.renderer.setStyle(el, '-webkit-line-clamp', this.clampLinesMax);

    const clampedHeight = elementStyles.height;
    const isClamped = clampedHeight !== fullHeight;
    if (!!this.htmlcontent && isClamped) {
      this.preventIllegalFocus();
    }
    this.clamped.emit(isClamped);
  }

  private appendContent(): void {
    const el = this.elementRef.nativeElement;
    // remove the clamp class and reset the line-clamp value so that the element height can be calculated properly
    // after resizing. Without this, the clamped height and full height are equal and 'isClamped' is emitted as false
    // even when the content has been clamped
    this.renderer.removeClass(el, 'clamp');
    this.renderer.setStyle(el, '-webkit-line-clamp', 'none');

    if (!!this.htmlcontent) {
      const isContentWrapped = !!el.querySelector(`.${this.wrapperClass}`);
      if (!isContentWrapped) {
        const wrapper = document.createElement('span');
        wrapper.classList.add(this.wrapperClass);
        el.appendChild(wrapper);
      }
      this.renderer.setProperty(
        el.querySelector(`.${this.wrapperClass}`),
        'innerHTML',
        this.domSanitizer.sanitize(SecurityContext.HTML, this.htmlcontent)
      );
    } else {
      this.renderer.setProperty(el, 'innerText', this.textcontent);
    }
  }

  private preventIllegalFocus(): void {
    const el = this.elementRef.nativeElement;
    // items hidden by overflow are still able to receive focus, but we want to prevent that.
    const innards = el.getElementsByTagName('*');
    for (let i = 0; i < innards.length; i++) {
      const child: HTMLElement = innards.item(i) as HTMLElement;
      const childIsTabbable = this.a11yService.isTabbable(child);
      if (childIsTabbable && this.isHidden(el, child)) {
        this.renderer.setAttribute(child, 'tabindex', '-1');
      }
    }
  }

  private isHidden(el: HTMLElement, child: HTMLElement) {
    return child.offsetTop > el.offsetHeight;
  }

  private cleanup(): void {
    const el = this.elementRef.nativeElement;
    this.renderer.removeClass(el, 'clamp');
    this.renderer.removeStyle(el, '-webkit-line-clamp');
  }
}
