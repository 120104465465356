import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';

interface TileLauncherItem {
  [key: string]: any;
}

export enum CardFormat {
  Standard = 'small',
  Large = 'large',
}
/**
 * This will pick which tile component to show based on the resourceType type.
 */
@Component({
  selector: 'dgx-tile-launcher',
  templateUrl: './tile-launcher.component.html',
  styleUrls: ['./tile-launcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileLauncherComponent {
  @Input() cardFormat: CardFormat = CardFormat.Standard;
  /** Should be false in all cases *except* when a child of the DgxCarousel component. */
  @Input() public appendMenuToBody = false;
  @Input() public item: TileLauncherItem;
  @Input() public recommendationInfo: RecommendationInfo;
  /** The object that will be passed to the actionOptions context  */
  @Input() public actionContext: any;
  /** If the item is a Tag, pass in the action the tag should start on */
  @Input() public initialTagAction: string;
  /** Determines if the description should be shown on the tile */
  @Input() public hideDescription?: boolean;
  /** Determines if the statistics will be rendered  */
  @Input() public showStatistics?: boolean;
  @Input() public assignmentDueDate?: string;
  /** Override menu config in the content card or the tag recommendation tile. */
  @Input() public menuConfig?: MenuViewModel[];
  @Input() public isIframe?: boolean = false;
  @Input() public hideFooter?: boolean;
  @Input() public hideImage?: boolean;
  @Input() public useBorderedButtons?: boolean = true;
  @Input() public hideCommentField?: boolean = true;
  @Input() public isPreview?: boolean = false;
  @Input() public isSelected?: boolean = false;
  /** see {@link ContentCardComponent} for usage */
  @Input()
  public modifyOptionsFn?: ModifyOptionsFnType<LearningResourceViewModel>;
  @Input() public lazyChunkIndex?: number;
  @Input() public tileIndex?: number;
  @Input() public expandCard?: boolean;
  /** see {@link OpportunityCardComponent} `DescriptionLength` input for usage */
  @Input() public opportunityDescriptionLength?: any;
  @Input() public isClickable?: boolean = true;
  @Input() public section?: string;
  @Output() public dismissTagRecommendation = new EventEmitter<HTMLElement>();
  /** Dismiss an Opportunity */
  @Output() public dismiss = new EventEmitter<HTMLElement>();
  @Output()
  public clickedTitleEllipses: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  constructor() {}

  // coerce types for sub components
  public get tag() {
    return this.item as TagsApi.TagDetails;
  }

  public get opportunity() {
    return this.item as Opportunity;
  }

  private get resourceType() {
    return (
      this.item?.resourceType ||
      this.item?.ResourceType ||
      this.item?.model?.resourceType
    );
  }

  /**
   * Returns true if the item is a target or pathway.
   * @param item
   */
  public isResourceCollection() {
    return this.resourceType === 'Target' || this.resourceType === 'Pathway';
  }

  /**
   * Returns true if the item is a tag.
   * @param item
   */
  public isTag() {
    return this.resourceType === 'Tag';
  }

  /**
   * Returns true if the item is an input.
   * @param item
   */
  public isInput() {
    return (
      this.resourceType !== 'Target' &&
      this.resourceType !== 'Pathway' &&
      this.resourceType !== 'Tag' &&
      this.resourceType !== 'Opportunity' &&
      this.resourceType !== 'User'
    );
  }

  public isOpportunity() {
    return this.resourceType === 'Opportunity';
  }

  /**
   * Returns true if the item is a pathway.
   * @param item
   */
  public isPathway() {
    return this.resourceType === 'Pathway';
  }

  /**
   * Returns true if the item is a provider.
   * TODO: This is only here for CarouselContent -> type = any.
   * It can be deleted once carousels are no longer in use.
   */
  public isProvider() {
    return this.resourceType === 'Provider';
  }

  /**
   * Returns true if the item is a target or a role.
   * @param item
   */
  public isTarget() {
    return this.resourceType === 'Target';
  }

  /**
   * Returns true if the item is a Directory
   * @param item
   */
  public isDirectory() {
    // Need to check both camel case and pascal case until this component is no longer used in an ajs component.
    return (
      this.resourceType === 'Target' &&
      (this.item?.targetType === 'Directory' ||
        this.item?.TargetType === 'Directory')
    );
  }

  /**
   * Returns true if the item is a user.
   * @param item
   */
  public isUser() {
    return this.resourceType === 'User';
  }
}
