import {
  Component,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  Output,
  Inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WindowToken } from '@app/shared/window.token';
import { TrackerService } from '@app/shared/services/tracker.service';
import { OrgService } from '@app/orgs/services/org.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadSuccessResult } from '@app/uploader/file-uploader/file-uploader.component';

@Component({
  selector: 'dgx-org-bulk-upload-modal',
  templateUrl: './org-bulk-upload-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgBulkUploadModalComponent {
  @Input() public orgId: number;
  @Output() public pageSelection: EventEmitter<string> = new EventEmitter();

  public i18n = this.translateService.instant([
    'Core_Back',
    'bulkUpdateModal_UploadAFile',
    'bulkUpdateModal_SelectAFile',
    'bulkUpdateModal_CreateRoles',
    'bulkUpdateModal_CancelProcessing',
    'bulkUpdateModal_ExcelImported',
    'bulkUpdateModal_RowsFound',
    'bulkUpdateModal_FirstRow',
    'bulkUpdateModal_SuccessfulUpload',
    'bulkUpdateModal_FileName',
    'bulkUpdateModal_HeadingRolePlans',
    'bulkUpdateModal_DownloadTemplate',
    'dgFileUploadButton_UploadErrorMessage',
  ]);
  public isFileUploaded: boolean = false;
  public uploadTo: string = '/organizations/bulkupload';
  public postObject = {
    uploadTemplateType: 'BulkTargets',
  };
  public fileId;
  public sheetName;
  public fileName;
  public rowsFound;
  public firstItemValue;

  constructor(
    private translateService: TranslateService,
    private trackerService: TrackerService,
    private orgService: OrgService,
    private activeModal: NgbActiveModal,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public downloadTemplate(): void {
    this.windowRef.open(`/api/organizations/template?type=BulkTargets`);
    this.trackerService.trackEventData({ action: 'Template Downloaded' });
  }

  public preprocessingComplete({
    fileId,
    firstItemValue,
    rowsFound,
    sheetName,
    uploadFileName,
  }: FileUploadSuccessResult): void {
    this.fileId = fileId;
    this.sheetName = sheetName;
    this.fileName = uploadFileName;
    this.rowsFound = rowsFound;
    this.firstItemValue = firstItemValue;
    this.isFileUploaded = true;
    this.trackerService.trackEventData({
      action: 'Plan Bulk Upload Initiated',
      properties: {
        RowCount: this.rowsFound,
        OrganizationId: this.orgId,
      },
    });
  }

  public cancelProcessing(): void {
    this.orgService.cancelBulkUpload(this.fileId);
    this.trackerService.trackEventData({ action: 'Cancel Process Clicked' });
    this.activeModal.close();
  }

  public createRoles(): void {
    this.orgService.processBulkUpload(this.fileId).subscribe(() => {
      this.pageSelection.emit('bulkUploadSuccess');
      this.trackerService.trackEventData({
        action: 'Plan Bulk Upload Submitted',
        properties: {
          RowCount: this.rowsFound,
          OrganizationId: this.orgId,
        },
      });
    });
  }

  public backToSelection(): void {
    this.pageSelection.emit('select');
  }
}
