/* eslint-disable  @typescript-eslint/member-ordering */

/**
 * TODO: The HTML/TranslateHTML members here have been recreated as pipes (html.pipe and html-translated.pipe).
 * Replace these versions with the pipes wherever possible.
 */
import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  SecurityContext,
  ɵBypassType,
  ɵgetSanitizationBypassType,
  ɵunwrapSafeValue,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, timer } from 'rxjs';

import { ResourceIdBuilder } from '@app/notifications/resource-id-builder';
import { NotificationItem } from './notification-item.model';
import { Notification } from '../../../notification-api.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { NotificationItemService } from '../notification-item.service';

const timedRefreshInterval = 1000; // TODO SHOULD BE EVERY MINUTE

/** Base class for all notification item components */
@Directive()
export abstract class NotificationItemComponentBase
  extends SubscriberBaseDirective
  implements NotificationItem, OnInit
{
  @Input() public notification: Notification;
  @Input() public maxTitleLength: number;

  // when displaying time-relative variables via usePeriodicRefresh, refresh once per minute
  private static timer: Observable<number> = timer(
    timedRefreshInterval,
    timedRefreshInterval
  );
  private content: string | SafeHtml;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    protected readonly itemService: NotificationItemService
  ) {
    super();
  }

  protected get usePeriodicRefresh() {
    return false;
  }

  public get safeContent() {
    if (this.isSafeHtml(this.content)) {
      return this.content;
    } else if (this.content !== undefined && this.content !== null) {
      // Because SafeHtml is defined as an empty interface and according to Typescript EVERYTHING implements the empty
      // interface, it thinks that this branch can never happen.
      return this.escapeHtmlEntities((this.content as any).toString());
    }
  }

  /** Handy shortcut for getting parameters */
  public get params() {
    return this.notification.parameters;
  }

  public ngOnInit() {
    this.updateContent();

    if (this.usePeriodicRefresh) {
      this.initRefreshTimer();
    }
  }

  /** Re-renders the content template with the current values and kicks off a change detection
   * cycle on the dynamically loaded content component. Because content component variables
   * don't use angular binding, this can be used to force an update. Typically, the variables
   * come from the notification which is static, but this could be useful for updating any
   * elapsed or current time values.
   */
  public updateContent() {
    try {
      this.content = this.buildTemplate(
        new ResourceIdBuilder(this.notification)
      );
      this.cdr.markForCheck();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * A tagged template literal helper to safely compose HTML fragments and escape user provided values
   *
   * NOTE: if this is used to create an interpolation parameter for a translation, the helper
   * {@see #translateHtml} should be used instead, to correctly escape args.
   */
  public html(templates: TemplateStringsArray, ...variables: any[]) {
    let result = '';
    for (let i = 0; i < templates.length; i++) {
      result += templates[i]; // Templates are provided by the developer and implicitly trusted
      const va = variables[i];
      if (va !== undefined && va !== null) {
        if (this.isSafeHtml(va)) {
          // If this is already a safe fragment, just add it to the result
          result += ɵunwrapSafeValue(va);
        } else {
          // Otherwise, turn it into a string and escape it
          result += this.escapeHtmlEntities(va.toString());
        }
      }
    }

    // Because the template fragments are trusted and all of the variables were either already trusted
    // or were escaped, the result of the concatenation can be trusted too.
    return this.itemService.sanitizer.bypassSecurityTrustHtml(result);
  }

  /** Escape the provided string so it can safely be inserted as HTML
   *
   * The escaped value is only valid as either tag content or attribute!
   * https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#rule-1-html-encode-before-inserting-untrusted-data-into-html-element-content
   */
  private escapeHtmlEntities(content: string): string {
    return content.replace(/[&<>"']/g, function (match) {
      switch (match) {
        case `&`:
          return '&amp';
        case `<`:
          return '&lt';
        case `>`:
          return '&gt';
        case `"`:
          return '&quot';
        case `'`:
          return '&#x27;';
      }
    });
  }

  /** Wrapper around @see TranslateService.instant() that escapes any arguments if necessary
   *
   * You only need to use this if either the translation string itself or any of the arguments
   * contain HTML.
   */
  public translateHtml(key: string, args: Record<string, any>): SafeHtml {
    const escaped: Record<string, any> = {};
    for (const name in args) {
      const value = args[name];
      escaped[name] = this.isSafeHtml(value)
        ? ɵunwrapSafeValue(value)
        : this.escapeHtmlEntities((value ?? '').toString());
    }
    const result = this.itemService.translate.instant(key, escaped);

    // All of the arguments were escaped so we can trust the combined translation
    return this.itemService.sanitizer.bypassSecurityTrustHtml(result);
  }

  private isSafeHtml(value: any): value is SafeHtml {
    return ɵgetSanitizationBypassType(value) == ɵBypassType.Html;
  }

  // Commonly used mini-templates
  // These can be used in buildTemplate() implementations for providing static values

  protected get trimmedTitleTemplate() {
    return this.html`<span>${this.itemService.ellipsisPipe.transform(
      this.params.title,
      this.maxTitleLength
    )}</span>`;
  }

  protected get dueDateTemplate() {
    let dueDate = this.params.dueDate;
    if (dueDate?.endsWith('T00:00:00Z')) {
      // remove the time component from the due date if it exists so that the date chosen is the date shown. With the
      // time component and no timezone, the due date is transformed using the user's timezone which can make it show
      // as a day early if they are in a timezone behind UTC.
      dueDate = dueDate.split('T00:00:00Z')[0];
    }
    return this
      .html`<span class="no-wrap">${this.itemService.datePipe.transform(
      dueDate
    )}</span>`;
  }

  protected get inputTypeTemplate() {
    return this.itemService.translate.instant(`Core_${this.params.inputType}`);
  }

  protected get userFullNameTemplate() {
    return this
      .html`<span class="font-semibold">${this.userFullNameArgument}</span>`;
  }

  protected get groupNameTemplate() {
    return this
      .html`<span class="font-semibold">${this.groupNameArgument}</span>`;
  }

  protected get tagNameTemplate() {
    return this
      .html`<span class="font-semibold">${this.tagNameArgument}</span>`;
  }

  protected get skillNameTemplate() {
    return this
      .html`<span class="font-semibold">${this.skillNameArgument}</span>`;
  }

  protected viewSkillsTemplate(plural?: boolean) {
    const str = plural ? 'Core_ViewSkills' : 'dgTagRating_ViewSkill';
    return this
      .html`<p><span class="ont-semibold color-blue">${this.itemService.translate.instant(
      str
    )}</span></p>`;
  }

  protected tagsRemovedMessageTemplate() {
    let html: string = '';
    for (var i = 0; i < this.skillNamesArgument.length; i++) {
      // sanitize the user defined strings
      let safeValue: string = this.itemService.sanitizer.sanitize(
        SecurityContext.HTML,
        this.skillNamesArgument[i]
      );
      let skillHtml = `<span class="font-semibold">${safeValue}</span>`;
      let messageValue = this.itemService.translate.instant(
        'workdaySkillRemoved_Message',
        { skillName: skillHtml }
      );
      let messageHtml = `<p>${messageValue}</p>`;
      html += messageHtml;
    }
    return this.itemService.sanitizer.bypassSecurityTrustHtml(html);
  }

  protected viewOrgRatingScaleChangeTemplate() {
    return this
      .html`<p><span class="ont-semibold color-blue">${this.itemService.translate.instant(
      'dgTagRating_ViewNewRatingScale'
    )}</span></p>`;
  }

  protected skillChangePublishedTemplate() {
    return this
      .html`<p><span class="font-semibold color-blue">${this.itemService.translate.instant(
      'Core_ViewSkills'
    )}&nbsp;&gt;</span></p>`;
  }

  /** Implemented in derived classes to build the content template string to be rendered. It can
   * return a string, in which case it will be correctly escaped when inserted into the DOM, or
   * a SafeHtml object returned by the helpers or the bypassSecurityTrustHtml function which will
   * be used as is.
   */
  protected abstract buildTemplate(
    resourceIdBuilder: ResourceIdBuilder
  ): string | SafeHtml;

  // Commonly used processed/formatted template argument accessors

  protected get userFullNameArgument() {
    return this.params.person?.userFullName;
  }

  protected get groupNameArgument() {
    return this.params.groupName || this.params.title;
  }

  protected get titleArgument() {
    return this.params.title;
  }

  protected get tagNameArgument() {
    return this.params.tagName;
  }

  protected get skillNameArgument() {
    return this.params.skillName;
  }

  protected get skillNamesArgument() {
    return this.params.skillNames;
  }

  protected get personCountArgument() {
    return this.params.personCount > 1 ? this.params.personCount : undefined;
  }

  protected get resourceTypeArgument() {
    return this.params.resourceType
      ? this.itemService.translate
          .instant('Core_' + this.params.resourceType)
          .toLowerCase()
      : undefined;
  }

  private initRefreshTimer() {
    NotificationItemComponentBase.timer
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.updateContent();
      });
  }
}

export interface TemplateArguments {
  userFullName?: string;
  groupName?: string;
  resourceType?: string;
  goal?: string;
  reportName?: string;
  title?: string;
  targetAuthor?: string;
  pathwayAuthor?: string;
  pathwayName?: string;
  tagName?: string;
  elapsedTime?: string;
}
