<div class="guts-p-t-4 guts-p-h-5 guts-p-b-3">
  <dgx-confetti-dropper
    [maxParticles]="60"
    [dropDuration]="2"
    [colorIncrement]="5"
  ></dgx-confetti-dropper>

  <button
    type="button"
    class="
      dg-modal__close-icon
      js-modal-close-btn
      dg-modal__close-icon--abs-right dg-modal__close-icon--top
      guts-p-full-2
    "
    (click)="dismiss()"
    [attr.aria-label]="i18n.Core_Close"
    dgxAutoFocus
    data-dgat="engagedCongratulationsModal-141"
  >
    <df-icon icon="cross"></df-icon>
  </button>

  <div class="l_flex l_flex-justify-center m-l_flex">
    <dgx-profile-pic
      [profile]="profileForProfilePic"
      [hideTooltip]="true"
      size="large"
    ></dgx-profile-pic>
  </div>

  <h1
    class="h2 crap guts-m-t-1-half center-text"
    [textContent]="i18n.ActiveLearner_Congratulations"
  ></h1>

  <p
    #description
    class="guts-m-t-2 justify-text center-last"
    [innerHTML]="i18n.ActiveLearner_CongratulationsText"
    (click)="close()"
  ></p>

  <div class="center-text guts-m-t-2">
    <button
      df-button
      dfButtonType="primary"
      [textContent]="i18n.Core_Close"
      (click)="dismiss()"
      data-dgat="engagedCongratulationsModal-4f3"
    ></button>
  </div>
</div>
