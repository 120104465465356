<dgx-viewer-header [controls]="controls"></dgx-viewer-header>
<div #pdfContainer class="pdf-container">
  <div
    id="viewer"
    class="pdfViewer"
    tabindex="0"
    [attr.aria-label]="i18nFileType"
  ></div>
</div>
<df-spinner *ngIf="isLoading" class="pdf-container__loading"></df-spinner>
