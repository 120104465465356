import { Injectable } from '@angular/core';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  FileLog,
  FtpFileDetail,
  FtpFileUploadType,
  FtpSummary,
} from '../ftp-api.model';

@Injectable({
  providedIn: 'root',
})
export class FtpService {
  constructor(
    private http: NgxHttpClient,
    private modalService: ModalService,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public getFtpDetails(
    fileId: number,
    orgId: number
  ): Observable<FtpFileDetail> {
    return this.http
      .get<FtpFileDetail>('/Organizations/GetFtpFileDetail', {
        params: { id: orgId, fileId },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('OrgFtp_ErrorFileDetails'),
              error
            )
          )
        )
      );
  }

  public getFtpFileLog(params: {
    orgId: number;
    take: number;
    pageNum: number;
    searchTerm?: string;
  }): Observable<FileLog> {
    return this.http.get<FileLog>('/Organizations/GetFtpFileLog', {
      params,
    });
  }

  public getStatusTranslation(key: string, status: string) {
    const translation = this.getTranslationIfExists(key);

    if (!translation) {
      this.tracker.trackEventData({
        action: 'FTP Unknown Error Status',
        properties: {
          status,
        },
      });
      return this.translate.instant('OrgFtp_ErrorUnknownFormat', {
        error: status,
      });
    } else {
      return translation;
    }
  }

  public getTranslationIfExists(key: string) {
    const translation = this.translate.instant(key);
    return translation === key ? null : translation;
  }

  /**
   * getFileNameFormat
   * takes an upload type and ordId, returns the preferred format for the file
   *  name (via get subscription)
   */
  public getFileNameFormat(
    orgId: number,
    uploadType: string
  ): Observable<string> {
    const defaultTemplate = `${uploadType}_File_`;
    return this.http
      .get<any>('/organizations/getftptemplateprefix', {
        params: { orgId: orgId, messageType: uploadType, sourceType: 'File' },
      })
      .pipe(
        switchMap((response: string) => {
          return of(response ? response : defaultTemplate);
        }),
        catchError(() => {
          return of(defaultTemplate);
        })
      );
  }

  public getAllUploadTypes(orgId: number = null): Observable<any> {
    return this.http.get<any>('/organizations/GetAllUploadTemplateTypes', {
      params: { orgId: orgId },
    });
  }

  public postTriggerFileProcess(params: {
    fileId: number;
    uploadType: string;
  }) {
    return this.http
      .post('/Organizations/ProcessUploadedFile', params)
      .pipe(
        catchError(
          (error) =>
            error &&
            throwError(
              new DgError(
                this.translate.instant('Uploader_UnknownError'),
                error
              )
            )
        )
      );
  }

  public getFileLogDetailUrl(orgId: number, fileId: number): string {
    return `/orgs/${orgId}/fileupload/ftp/details/${fileId}`;
  }

  public getFileUploadUrl(orgId: number, fileType: FtpFileUploadType): string {
    return `/orgs/${orgId}/fileupload/uploadFile/${fileType}`;
  }

  public discardFile(fileId: number): Observable<any> {
    return this.http
      .delete('/Organizations/DiscardFile', {
        params: { fileId },
      })
      .pipe(
        catchError(
          (error) =>
            error &&
            throwError(
              new DgError(
                this.translate.instant('OrgFtp_ErrorDiscardingFile'),
                error
              )
            )
        )
      );
  }

  public showConfirmDiscardFileModal() {
    const inputs: SimpleModalInputBindings = {
      canCancel: true,
      bodyText: this.translate.instant('OrgFtp_DiscardConfirmationMessage'),
    };
    return this.modalService.show(SimpleModalComponent, {
      inputs,
    });
  }

  public getIsPendingFile(summary: FtpSummary) {
    return summary.fileStatus === 'Pending';
  }

  public showConfirmClientUserFileModal() {
    const inputs: SimpleModalInputBindings = {
      canCancel: true,
      bodyText: this.translate.instant('OrgFtp_ClientUserFileWarning'),
      submitButtonText: this.translate.instant('OrgFtp_SubmitFile'),
      headerText: this.translate.instant('OrgFtp_Note'),
    };
    return this.modalService.show(SimpleModalComponent, { inputs });
  }
}
