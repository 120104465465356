import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TagsApi } from '@app/tags/tag-api.model';

@Component({
  selector: 'dgx-tag-suggestions',
  templateUrl: './tag-suggestions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagSuggestionsComponent {
  @Input() public isLoading: boolean = false;
  @Input() public isSuggestedTagsByOrg: boolean = false;
  @Input() public suggestedTags: TagsApi.Tag[] = [];
  @Input() public tags?: TagsApi.Tag[] = [];
  @Output() public onAddSuggestedTag: EventEmitter<TagsApi.Tag> =
    new EventEmitter();

  constructor(private authService: AuthService) {}

  public addSuggestedTag(tag: TagsApi.Tag): void {
    this.onAddSuggestedTag.emit(tag);
  }

  public trackByTitle(index: number, item: TagsApi.Tag): string {
    return item.title;
  }

  public isTagSelected(tag: TagsApi.Tag): boolean {
    const isFound = (item: TagsApi.Tag) =>
      item.title.trim().toLowerCase() === tag.title.trim().toLowerCase() ||
      item.name.trim().toLowerCase() === tag.name.trim().toLowerCase();
    return this.tags.some(isFound);
  }


  public getSkillStandardUrl() {
    const vanityUrl = this.authService.authUser.viewerProfile.vanityUrl;
    return `/${vanityUrl}/dashboard/skillstandards`;
  }

}
