<div class="field-group">
  <ng-container *ngIf="showUpdatedDatePicker; else oldDatePicker">
    <span class="field-group__input">
      <df-input-decorator prependageType="default">
        <df-icon df-prepend icon="calendar" class="guts-m-l-quart"></df-icon>
        <input
          type="text"
          ngbDatepicker
          readonly
          #ngbDatepicker="ngbDatepicker"
          [attr.id]="labelKey ? labelKey : 'dgxDatePicker'"
          (click)="ngbDatepicker.toggle()"
          (keydown)="onKeyDown($event)"
          [minDate]="minDate | ngbDate"
          [maxDate]="maxDate | ngbDate"
          [footerTemplate]="datepickerFooter"
          [firstDayOfWeek]="7"
          [placeholder]="placeholder || ''"
          [disabled]="disabled"
          [ngModel]="date | ngbDate"
          (dateSelect)="selectDate($event)"
          (blur)="handleBlur()"
          [attr.data-dgat]="customDgat || 'date-picker-618'"
          [required]="required || undefined"
        />
      </df-input-decorator>
    </span>
  </ng-container>
  <ng-template #oldDatePicker>
    <span class="field-group__input">
      <input
        type="text"
        ngbDatepicker
        readonly
        #ngbDatepicker="ngbDatepicker"
        (click)="ngbDatepicker.toggle()"
        (keydown)="onKeyDown($event)"
        [minDate]="minDate | ngbDate"
        [maxDate]="maxDate | ngbDate"
        [footerTemplate]="datepickerFooter"
        [firstDayOfWeek]="7"
        [placeholder]="placeholder || ''"
        [disabled]="disabled"
        [ngModel]="date | ngbDate"
        (dateSelect)="selectDate($event)"
        (blur)="handleBlur()"
        [attr.data-dgat]="customDgat || 'date-picker-618'"
        [required]="required || undefined"
      />
    </span>
    <span class="field-group__btn">
      <button
        df-button
        dfButtonType="ghost"
        (click)="ngbDatepicker.toggle()"
        [attr.aria-label]="i18n.Core_OpenDatePicker"
        data-dgat="date-picker-f26"
      >
        <df-icon icon="calendar" size="medium"></df-icon>
      </button>
    </span>
  </ng-template>
</div>

<ng-template #datepickerFooter>
  <div class="grid grid--align-center grid--justify-space-between">
    <button
      df-button
      dfButtonType="passive"
      (click)="clearDate()"
      [textContent]="i18n.Core_Clear"
      data-dgat="date-picker-ddf"
    ></button>
  </div>
</ng-template>
