import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { GroupIdentifier } from '@app/groups/group-api';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-groups-edit',
  templateUrl: './groups-edit.component.html',
  styleUrls: ['./groups-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsEditComponent implements OnInit {
  @Input() public label: string;
  @Input() public tipText: string;
  @Input() public isRequired: boolean;
  @Input() public groups: GroupIdentifier[];

  @Output() public groupsChange = new EventEmitter<GroupIdentifier[]>();

  constructor(private translate: TranslateService) {}

  public ngOnInit() {
    if (!this.label) {
      this.label = this.translate.instant('dgGroupsEdit_AddGroups');
    }
    if (!this.tipText) {
      this.tipText = this.translate.instant('dgGroupsEdit_AddGroupsTooltip');
    }
  }

  public handleGroupSelection(group: GroupIdentifier): void {
    if (!this.groups) {
      this.groups = [];
    }
    if (group && !this.groups.some((g) => g.groupId === group.groupId)) {
      this.groups = [...this.groups, group];
      this.groupsChange.emit(this.groups);
    }
  }

  public handleGroupRemove(group: GroupIdentifier): void {
    const groups =
      this.groups?.filter((g) => g.groupId !== group.groupId) ?? [];
    if (groups.length < this.groups.length) {
      this.groups = groups;
      this.groupsChange.emit(this.groups);
    }
  }
}
