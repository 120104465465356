import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { OrgMembersService } from '@app/orgs/services/org-members.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingButtonBaseComponent } from '@app/tags/components/tag-rating-button/tag-rating-button-base.component';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { FocusStackService } from '@app/shared/services/focus-stack.service';

@Component({
  selector: 'dgx-manager-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerRatingButtonComponent extends TagRatingButtonBaseComponent {
  public readonly type = InternalTagRatingTypes.manager;
  public loading = false;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    private tagRatingService: TagRatingService,
    private orgMembersService: OrgMembersService,
    public focusStackService: FocusStackService
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get noRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_RequestRating');
  }

  public get inProgressRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_CancelRequest');
  }

  public get inProgressRatingLevelLabel(): string {
    return this.translateService.instant('dgTagRating_RatingPendingFormat', {
      manager: this.incompleteRatings?.[0]?.rater?.name,
    });
  }

  public get showPrivacyDropdown(): boolean {
    return (
      !this.authService.authUser?.defaultOrgInfo?.settings
        ?.lockSkillManagerRatingVisibility &&
      this.ownerIsViewing &&
      this.hasCompletedRatings
    );
  }

  public get menuConfig(): MenuViewModel[] {
    const option =
      this.tagActionOptionsService.getCancelManagerRatingRequestMenuOption(
        this.tag,
        this.inProgressRating
      );
    // if the only option is hidden, null out the menuConfig so that the menu gets destroyed and then
    // recreated when there is a visible option. This prevents the disconnect between the button and the menu popover
    // see PD-78150
    if (option.isHidden()) {
      return null;
    }
    return [option];
  }

  public get inProgressRating(): TagsApi.UserTagRatingDetails {
    return this.incompleteRatings?.[0];
  }

  public get endorsedByLabel(): string {
    return this.hasSingleEndorser
      ? this.translateService.instant('dgTagRating_RatedByFormat', {
          manager: this.endorser?.name,
        })
      : undefined;
  }

  public get ratingDescriptionLabel(): string {
    return this.translateService.instant(
      'dgTagRating_RequestManagerRatingInstruction'
    );
  }

  public get hasSingleEndorser(): boolean {
    return !!this.endorser && this.hasCompletedRatings;
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    this.focusStackService.push(event.target as HTMLElement);
    if (this.loading) {
      return;
    }
    if (this.ownerIsViewing) {
      if (!this.hasRatings) {
        this.openRatingRequest(event);
      } else if (this.hasIncompleteRatings) {
        this.cancelRatingRequest(event);
      } else if (this.hasCompletedRatings) {
        this.openRatingByOtherUser(event);
      }
    }
  }

  private openRatingRequest(event: Event): void {
    // preselect users manager if available
    this.loading = true;
    this.orgMembersService
      .getManager(this.authService.authUser?.defaultOrgId)
      .subscribe(
        (manager) => {
          this.tagRatingService
            .openManagerRatingRequestModal(event, this.tag, manager)
            .subscribe();
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }

  private cancelRatingRequest(event: Event): void {
    this.loading = true;
    this.tagRatingService
      .openCancelManagerRatingRequestModal(event, this.tag)
      .subscribe()
      .add(() => (this.loading = false));
  }

  private openRatingByOtherUser(event: Event): void {
    const allowRatingRequest =
      this.authService.authUser?.canRequestManagerRating;

    this.orgMembersService
      .getManager(this.authService.authUser?.defaultOrgId)
      .subscribe((manager) => {
        // use manager if available, otherwise use previous rater
        const rater = manager || this.completedRatings[0].rater;
        this.tagRatingService.openCompletedAssociateRatingModal(
          event,
          this.completedRatings?.[0],
          allowRatingRequest,
          () =>
            this.tagRatingService
              .openManagerRatingRequestModal(event, this.tag, rater)
              .subscribe()
        );
      });
  }
}
