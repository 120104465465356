import { ElementRef, Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthUser } from '@app/account/account-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { MenuViewModel } from '../../menu/menu.component';
import { DgError } from '@app/shared/models/dg-error';
import { HttpErrorResponse } from '@angular/common/http';

import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { AuthService } from '@app/shared/services/auth.service';
import {
  FocusStackService,
  ModalService,
  NotifierService,
  TrackerService,
} from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { CatalogSource } from '@app/shared/utils/tracker-helper';

@Injectable({
  providedIn: 'root',
})
export class EndorseContentService {
  private static endorseableContentTypes = [
    'Article',
    'Assessment',
    'Video',
    'Book',
    'Course',
    'Event',
    'Episode',
  ];

  public onUpdate$ = new Subject<LearningResourceViewModel>();
  private i18n = this.translateService.instant([
    'Core_Endorse',
    'Core_Remove',
    'Core_Endorsed',
    'Core_RemoveEndorsement',
    'Core_EndorsementRemoved',
    'Core_RemoveEndorsementConfirmation',
    'Core_EndorseConfirmation',
    'Core_EndorseInformation',
    'Core_EndorseContentGenericError',
    'Core_EndorseContentOrgTypeError',
    'Core_EndorseContentPermissionError',
  ]);

  constructor(
    private http: NgxHttpClient,
    private authService: AuthService,
    private focusStackService: FocusStackService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private notifierService: NotifierService,
    private trackerService: TrackerService
  ) {}

  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public endorseContentMenuOption(
    resource: LearningResourceViewModel
  ): MenuViewModel {
    return {
      title: this.i18n.Core_Endorse,
      preventRefocus: true,
      isHidden: () =>
        this.hideEndorseContentMenuOptions(resource) || resource.isEndorsed,
      defaultAction: (_, popoverTrigger) =>
        this.confirmContentEndorsement(resource, popoverTrigger),
    };
  }

  public removeEndorsementContentMenuOption(
    resource: LearningResourceViewModel
  ): MenuViewModel {
    return {
      title: this.i18n.Core_RemoveEndorsement,
      preventRefocus: true,
      isHidden: () =>
        this.hideEndorseContentMenuOptions(resource) || !resource.isEndorsed,
      defaultAction: (_, popoverTrigger) =>
        this.confirmContentEndorsement(resource, popoverTrigger),
    };
  }

  private trackEndorsementUpdated(resource: LearningResourceViewModel) {
    this.trackerService.trackEventData({
      action: 'Content Endorsement Updated',
      properties: {
        isEndorsed: resource.isEndorsed,
        catalogSource: resource.internalUrl
          ? CatalogSource.Internal
          : CatalogSource.External,
        contentId: resource.resourceId,
        contentName: resource.title,
        contentType: resource.resourceType,
        hasImage: !!resource.imageUrl,
        organizationId: resource.organizationId,
        hostedType: resource.model.hostedType,
        providerId: resource.providerSummary?.id,
        providerName: resource.providerSummary?.name,
      },
    });
  }

  private hideEndorseContentMenuOptions(
    resource: LearningResourceViewModel
  ): boolean {
    return (
      !this.canEndorseOrgContent(resource) ||
      !this.isEndorseableType(resource.resourceType) ||
      this.isChannelResource(resource.organizationId) ||
      resource.model.obsolete ||
      !!resource.model.marketplaceInputId
    );
  }

  /**
   * Verify whether the user can endorse content for the organization that owns the resource.
   */
  private canEndorseOrgContent(resource: LearningResourceViewModel) {
    return !!this.authUser.orgInfo.find(
      ({ organizationId }) => organizationId === resource.organizationId
    )?.permissions?.endorseContent;
  }

  private isEndorseableType(resourceType: string) {
    return EndorseContentService.endorseableContentTypes.includes(resourceType);
  }

  private isChannelResource(orgId: number) {
    return (
      this.authUser.orgInfo.find(
        ({ organizationId }) => organizationId === orgId
      )?.type === 'Channel'
    );
  }

  private confirmContentEndorsement(
    resource: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ): void {
    const isEndorsed = resource.isEndorsed;
    this.focusStackService.push(popoverTrigger.nativeElement);

    const modalOptions = {
      bodyText: this.i18n.Core_EndorseInformation,
      secondaryBodyText: isEndorsed
        ? this.i18n.Core_RemoveEndorsementConfirmation
        : this.i18n.Core_EndorseConfirmation,
      secondaryBodyClasses: isEndorsed ? 'italic' : '',
      submitButtonText: isEndorsed
        ? this.i18n.Core_Remove
        : this.i18n.Core_Endorse,
      canCancel: true,
      headerText: isEndorsed
        ? this.i18n.Core_RemoveEndorsement
        : this.i18n.Core_Endorse,
    };

    this.modalService
      .show(SimpleModalComponent, {
        inputs: modalOptions,
      })
      .pipe(
        switchMap(() =>
          this.updateEndorsements(!resource.isEndorsed, [resource])
        )
      )
      .subscribe(() => {
        resource.isEndorsed = !resource.isEndorsed;
        this.trackEndorsementUpdated(resource);
        this.onUpdate$.next(resource);
        this.notifierService.showSuccess(
          isEndorsed
            ? this.i18n.Core_EndorsementRemoved
            : this.i18n.Core_Endorsed
        );
      });
  }

  private updateEndorsements(
    isEndorsed: boolean,
    resources: LearningResourceViewModel[]
  ) {
    return this.http
      .put<void>('/inputs/updateEndorsements', {
        isEndorsed,
        inputs: resources.map((resource) => ({
          inputId: resource.resourceId,
          inputType: resource.resourceType,
        })),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage: string = this.i18n.Core_EndorseContentGenericError;
          if (error.status === 19409) {
            errorMessage = this.i18n.Core_EndorseContentOrgTypeError;
          } else if (error.status === 17403) {
            errorMessage = this.i18n.Core_EndorseContentPermissionError;
          }

          this.notifierService.showError(errorMessage);
          return throwError(new DgError(errorMessage, error));
        })
      );
  }
}
