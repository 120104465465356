import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-input-statistics',
  templateUrl: './input-statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputStatisticsComponent implements OnChanges, AfterViewInit {
  @Input() public input;
  @Input() public layout?: 'learner-home' | undefined;
  public inputStatisticsString: string;

  constructor(
    private inputsService: InputsService,
    private trackerService: TrackerService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) {}

  public ngOnChanges() {
    if (this.input) {
      this.inputStatisticsString = this.getTranslationString();
    }
  }

  public ngAfterViewInit() {
    // we have to manually add a click listener to the buttons
    // because Angular does not compile property bindings in innerHtml (click in this case)
    this.elementRef.nativeElement
      .querySelector('.viewUsersButton')
      ?.addEventListener('click', (event) => this.viewUsers(event));
  }

  public viewUsers(event) {
    if (this.input instanceof LearningResourceViewModel) {
      const data = {
        inputType: this.input.resourceType,
        inputId: this.input.resourceId,
      } as any;
      this.inputsService.showTopUsers(data, event);
      this.trackerService.trackEventData({
        action: 'Content Completions Viewed',
        properties: this.input.getTrackingCopy(),
      });
    } else {
      this.inputsService.showTopUsers(this.input, event);
      this.trackerService.trackEventData({
        action: 'Content Completions Viewed',
        properties: this.input,
      });
    }
  }

  public getTranslationString(): string {
    // extra safeguard on the topUser. The template won't be rendered if there is no topUser, but this could still
    // be called from ngOnChanges resulting in an error
    if (!this.input.statistics.topUser) {
      return '';
    }
    let key;

    const userName = (dgat) =>
      `<a href='/${this.escapeAndSanitizeHtmlEntities(
        this.input.statistics.topUser.vanityUrl
      )}' class='link font-medium' data-dgat='${dgat}'>${this.escapeAndSanitizeHtmlEntities(
        this.input.statistics.topUser.name
      )}</a>`;
    const params = {} as Record<string, any>;

    // There can be cases where the userCount is 0, but there is a topUser, in these cases, assume that the topUser
    // is the only user, effectively userCount = 1. This should be safe as the component is only displayed if there is
    // a topUser present - see  PD-77333
    if (
      this.input.statistics.userCount === 1 ||
      (!this.input.statistics.userCount && this.input.statistics.topUser)
    ) {
      switch (this.input.resourceType) {
        case 'Article':
        case 'Book':
          key = 'dgInputStatistics_TopCompleterRead';
          break;
        case 'Video':
          key = 'dgInputStatistics_TopCompleterWatched';
          break;
        case 'Pathway':
        case 'Target':
          key = 'dgInputStatistics_TopFollower';
          break;
        default:
          key = 'dgInputStatistics_TopCompleter';
      }
      params.userName = userName('inputStatistics-b79');
    } else if (this.input.statistics.userCount === 2) {
      switch (this.input.resourceType) {
        case 'Article':
        case 'Book':
          key = 'dgInputStatistics_TopCompleterAndOneRead';
          break;
        case 'Video':
          key = 'dgInputStatistics_TopCompleterAndOneWatched';
          break;
        case 'Pathway':
        case 'Target':
          key = 'dgInputStatistics_TopFollowerAndOne';
          break;
        default:
          key = 'dgInputStatistics_TopCompleterAndOne';
      }
      params.userName = userName('inputStatistics-754');
      params.countStartSpan =
        this.input.resourceType === 'Pathway' ||
        this.input.resourceType === 'Target'
          ? ''
          : `<button type='button' class='viewUsersButton link font-medium' data-dgat='inputStatistics-754'>`;
      params.countEndSpan =
        this.input.resourceType === 'Pathway' ||
        this.input.resourceType === 'Target'
          ? ''
          : `</button>`;
    } else if (this.input.statistics.userCount > 2) {
      switch (this.input.resourceType) {
        case 'Article':
        case 'Book':
          key = 'dgInputStatistics_TopCompleterCountRead';
          break;
        case 'Video':
          key = 'dgInputStatistics_TopCompleterCountWatched';
          break;
        case 'Pathway':
        case 'Target':
          key = 'dgInputStatistics_TopFollowerCount';
          break;
        default:
          key = 'dgInputStatistics_TopCompleterCount';
      }
      params.userName = userName('inputStatistics-556');
      params.countStartSpan =
        this.input.resourceType === 'Pathway' ||
        this.input.resourceType === 'Target'
          ? ''
          : `<button type='button' class='viewUsersButton link font-medium' data-dgat='inputStatistics-73e'>`;
      params.userCount = this.input.statistics.userCount - 1;
      params.countEndSpan =
        this.input.resourceType === 'Pathway' ||
        this.input.resourceType === 'Target'
          ? ''
          : `</button>`;
    }

    // FE fix for PD-77333 to error-handle check if the userCount comes back 0 or undefined
    return key ? this.translateService.instant(key, params) : '';
  }

  private escapeAndSanitizeHtmlEntities(content: string): string {
    const escaped = content.replace(/[&<>"']/g, function (match) {
      switch (match) {
        case `&`:
          return '&amp';
        case `<`:
          return '&lt';
        case `>`:
          return '&gt';
        case `"`:
          return '&quot';
        case `'`:
          return '&#x27;';
      }
    });

    return this.sanitizer.sanitize(SecurityContext.HTML, escaped);
  }
}
