import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AnyInputOrUserInput,
  AnyInputOrUserInputParameters,
  SubmissionStatus,
} from '@app/inputs/inputs.model';
import {
  InputDetails,
  LearningInputModel,
  UserInputCreationFeedback,
  UserInputIdentifier,
} from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { AuthService } from '@app/shared/services/auth.service';
import { Loadable, loadWith } from '@dg/shared-rxjs';
import { DfFormFieldConfig } from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AnyUserInputParametersViewModel } from '../user-input.model';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';
import { pascalCaseKeys } from '@app/shared/utils/property';

type AnyContentOrUserContent = AnyInputOrUserInput | UserOutcomeDetail;
type AnyInputParameters =
  | AnyUserInputParametersViewModel
  | AnyInputOrUserInputParameters;

/** Base class that provides common logic and interface for add/edit User Input modals.
 * @description The modal can be pre-populated by providing or partially providing the {@link initialViewModel} input.
 * The form creates it's own model from the combination of that model and other inputs. Upon submission, the
 * modified form data is merged with the original inputs to create a composite model. The actual submission
 * logic is the responsibility of the caller and may be provided either after the modal closes or inline via
 * the {@link submit} input, after which a results animation is displayed in the modal.
 * @template TUserContent, TFormModel, TUserInputParams
 * @type {TUserContent} Type of the primary Input object being created or edited
 * @type {TFormModel} Type of the model ({@link initialViewModel}) attached directly to the reactive form
 * @type {TUserInputParams} Type of the parameters to create as the final output. On form submission,
 * this will include the merged results of any ({@link initialViewModel}) and the user-entered form values.
 */
@Directive()
export abstract class UserContentModalBaseDirective<
    TUserContent extends AnyContentOrUserContent,
    TFormModel,
    TUserInputParams extends
      | AnyInputParameters
      | AnyContentOrUserContent = TUserContent // by default, generate the same type as provided as input to the modal
  >
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit, Loadable
{
  /** A helper method that sets the value of a custom field, marks it as dirty and, optionally, as touched. */
  public static setCustomFieldValue(
    formControl: FormControl,
    value: any,
    touch = true
  ) {
    formControl.setValue(value);
    formControl.markAsDirty();
    if (touch) {
      formControl.markAsTouched();
    }
  }

  public model: Partial<TFormModel> = {};
  // Optional title for the modal which overrides the default 'Add *' or 'Edit *' strings. Can be a translation key.
  @Input() public modalTitle?: string;
  /** The initial data to use to populate the form.
   * @remarks This is Partial so you can provide any and all data that's available. Upon submission, the form values
   * will be merged with these defaults to form the final results. Note that the naming of this property
   * is mostly for ajs backward compatibility. The passed object will generally be a DTO model. We do output
   * view models in some cases that contain additional form inputs or other metadata used by downstream
   * services.
   */
  @Input() public initialViewModel: Partial<any>;
  /** set to true if the user is completing the input, i.e. creating a User Input */
  @Input() public isCompleting: boolean;
  /** set to true if the input is being edited vs. created */
  @Input() public isEditing: boolean;
  /** the id of the user outcome if it exists */
  @Input() public userOutcomeId: number;

  /** Allows external loading of the input as the modal is displayed */
  @Input() public load: (
    identifier: Partial<UserInputIdentifier>
  ) => Observable<any>;
  /**
   * Allows further external processing of the successfully submitted input before the modal is closed,
   * since global add modals stay open subsequently to present feedback
   */
  @Input() public submit: (
    result: TUserInputParams
  ) => Observable<UserInputCreationFeedback>;
  @Input() public canManageContent: boolean;
  @Input() public isPathwayBinAdd: boolean;
  /** Overrides the org ID from the user's default org */
  @Input() public organizationId?: number;
  @Input() public pathwayId: number;
  public isDurationDisabled: boolean;
  public isLoading = true;
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public isNewbUser: boolean;
  public isAuthoring: boolean;
  public submissionStatus: SubmissionStatus = SubmissionStatus.None;
  public creationFeedback: any;
  public isPrivateProfile = this.authService.authUser.isPrivateProfile;
  public readonly userInterests = pascalCaseKeys(
    this.authService.authUser.viewerInterests
  );
  protected duplicate: {
    inputs: InputDetails[];
    count: number;
  } = { inputs: [], count: 0 };
  private _authUser: AuthUser;
  private _orgGroupNames: string[] = [];
  private _orgGroupIds: string[] = [];
  private contentItem: Partial<any> = {};

  constructor(
    protected windowRef: Window,
    protected cdr: ChangeDetectorRef,
    protected activeModal: NgbActiveModal,
    protected authService: AuthService,
    protected tipService: TipService,
    protected inputsService: InputsService,
    private defaultAddingTitle: string = '',
    private defaultEditingTitle: string = ''
  ) {
    super();

    authService.authUser$
      .pipe(this.takeUntilDestroyed())
      .subscribe((authUser) => {
        this._authUser = authUser;
        this._orgGroupNames = authUser.defaultOrgInfo?.groups?.map(
          (g) => g.name
        );
        this._orgGroupIds = authUser.defaultOrgInfo?.groups?.map((g) => g.id);
      });
  }

  public get authUser(): AuthUser {
    return this._authUser;
  }

  public get organization(): OrgInfo {
    return this._authUser.defaultOrgInfo;
  }

  public get orgGroupNames(): string[] {
    return this._orgGroupNames;
  }

  public get orgGroupIds(): string[] {
    return this._orgGroupIds;
  }

  public get shouldShowResults() {
    return this.submissionStatus >= SubmissionStatus.Submitting;
  }

  public get isAdding() {
    return !this.isEditing;
  }

  /** Gets an observable that blocks submission of a validated form until completed. The default implementation
   * completes immediately. Derived classes can override to impose submission prerequisites, such as loading
   * additional initialization data, then asynchronously continue completion once conditions are met.
   */
  protected get beforeSubmit(): Observable<void> {
    return of(void 0);
  }

  public ngOnInit() {
    this.tipService.onboardHistory$
      .pipe(this.takeUntilDestroyed())
      .subscribe((v) => {
        this.isNewbUser = v.indexOf('firstinput') === -1; // is new user if there is no initial input recorded for the profile yet
      });
    this.modalTitle =
      this.modalTitle ||
      (this.isEditing ? this.defaultEditingTitle : this.defaultAddingTitle);

    this.isAuthoring =
      !(this.initialViewModel as any)?.inputId || !this.isCompleting;

    this.contentItem = cloneDeep({
      // might need to add the inputId & userInputId here when we add the edit capability for those forms
      userOutcomeId: this.userOutcomeId,
      ...this.initialViewModel,
    });
  }

  public ngAfterViewInit() {
    const { inputId, userInputId, userOutcomeId, ...remaining } = this
      .contentItem as any;

    if (
      this.isEditing &&
      isEmpty(remaining) &&
      (inputId || userInputId || userOutcomeId)
    ) {
      // If remaining properties besides the IDs weren't provided we need to fetch the rest of the input from the BE
      loadWith(
        this.load({
          inputId,
          userInputId,
          userOutcomeId,
        } as any),
        this
      ).subscribe((input) => {
        this.initialViewModel = input as unknown as Partial<TUserContent>;
        this.contentItem = input as unknown as Partial<TUserContent>;

        this.initializeForm();
      });
    } else {
      this.initializeForm();
    }
  }

  /** Override to allow additional form control wiring to happen after the form and its controls are created.  */
  public initControlDependencies() {}

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.submissionStatus = SubmissionStatus.Pending;
      // Queue a submission, blocking until observable completes
      this.beforeSubmit
        .pipe(
          // unsubscribe if user cancels modal while submission is pending.
          // TODO: update this to cancel any in-flight request via switchMap
          this.takeUntilDestroyed()
        )
        .subscribe(() => {
          const result = this.createResult(this.model, this.contentItem);

          if (this.submit) {
            this.doExternalSubmit(result);
          } else {
            this.activeModal.close(result);
          }
        });
    }
  }

  /** Call to request the modal to close, optionally with navigation to a profile collection containing the new user input. */
  public onNavigateToCollection(collectionUrl?: string) {
    // close the modal before navigating
    const result = this.createResult(this.model, this.contentItem);
    this.activeModal.close(result); // input should be assumed to be submitted successfully if we're here
    const isProfilePage =
      this.windowRef.location.pathname.indexOf('index/1') > -1;
    if (isProfilePage) {
      // we're already on the profile page so no need to formally navigate
      if (this.isNewbUser) {
        // and it's a new user.
        // collection tab doesn't exist yet, so refresh the page.
        // TODO: make tabs dynamic so page refresh isn't necessary
        this.windowRef.location.reload();
      }
    } else if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }

  /** Derived classes must implement to build the form fields via a DfFormBuilder */
  protected abstract buildFormFields(): DfFormFieldConfig[];

  /** Derived classes must implement to initialize the form {@link model} property from the
   * input defaults.
   * @param source A deep copy of the {@link initialViewModel} provided or, in edit mode,
   * may contain the results of a {@link load} call when {@link initialViewModel} contains only
   * ID properties.
   */
  protected abstract initFormModel(source: Partial<TUserContent>);

  /** Derived classes must implement to create the final output result to be passed to
   * the caller via the {@link submit} method, if provided, as well on modal closure.
   * @param model The form model
   * @param defaults The initial defaults provided to the modal
   */
  protected abstract createResult(
    model: Partial<TFormModel>,
    defaults: Partial<TUserContent>
  ): TUserInputParams;

  protected notifyInputModifiedListeners(contentType) {
    // Notify any listeners that an input was modified or created
    this.inputsService.notifyInputModified(contentType);
  }

  // Check duplicates for Add to Pathway Content
  protected checkDuplicates(url: string) {
    return this.inputsService
      .getCmsInputsByUrl(this.organizationId, url) // organizationId Input passed in for pathways
      .subscribe((response: LearningInputModel) => {
        if (response.inputs) {
          this.duplicate.inputs = response.inputs;
          this.duplicate.count = response.inputs.length;
        }
      });
  }

  // Called for duplicates found in Pathways to support adding content to hold for later bin
  protected viewDuplicatesEvent() {
    this.inputsService.viewDuplicates(
      this.duplicate.inputs,
      undefined,
      this.pathwayId
    );
  }

  private initializeForm() {
    this.initFormModel(this.contentItem);
    this.fields = this.buildFormFields();
    this.isLoading = false;
    this.cdr.detectChanges(); // force form control creation
    this.initControlDependencies();
  }

  private doExternalSubmit(result: TUserInputParams) {
    // Listen for the submission delegate to provide its results (or an error) back to us
    this.submissionStatus = SubmissionStatus.Submitting;
    // Delegate the submitting details to our client but still receive the results/errors
    // and display them subsequently instead of closing immediately
    this.submit(result)
      .pipe(finalize(() => this.cdr.detectChanges()))
      .subscribe(
        (feedback) => {
          this.creationFeedback = feedback;
          this.submissionStatus = SubmissionStatus.Succeeded;
        },
        (_) => {
          this.submissionStatus = SubmissionStatus.Failed;
        }
      );
  }
}
