<div>
  <h1 class="h3 guts-m-b-1" [innerHTML]="chartName"></h1>
  <div
    *ngIf="!loading && recommendations.length === 0"
    translate="dgRecommendationsStatus_Empty"
  ></div>
  <div *ngIf="recommendations.length > 0" class="data-table--sortable-wrapper">
    <table class="data-table--sortable data-table">
      <thead>
      <tr>
        <th
          class="l_w25"
          scope="col"
          translate="dgRecommendationsStatus_HeaderTitle"
        ></th>
        <th
          class="no-wrap no-mobile"
          scope="col"
          translate="dgRecommendationsStatus_ByHeader"
        ></th>
        <th class="no-wrap" scope="col">
          %
          <span
            class="no-mobile"
            translate="dgRecommendationsStatus_HeaderPercentComplete"
          ></span>
        </th>
        <th
          scope="col"
          translate="dgRecommendationsStatus_HeaderDueDate"
        ></th>
        <th
          scope="col"
          class="no-mobile"
          translate="dgRecommendationsStatus_HeaderCreatedDate"
        ></th>
        <th class="no-mobile" scope="col">
          <span class="a11y-hide-text" translate="Core_Actions"></span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        class="guts-p-v-half"
        data-dgat="recommendationsStatus-ff5"
        *ngFor="
            let recommendation of recommendations;
            trackBy: trackRecommendationById
          "
        ng-class-odd="'odd'"
      >
        <td class="l_w25">
          <div
            class="color-ebony-a61 guts-m-b-half font-medium"
            [innerHTML]="recommendation.displayType"
          ></div>
          <div
            *ngIf="
                !recommendation.reference.internalUrl &&
                !recommendation.reference.url
              "
            [innerHTML]="recommendation.reference.title"
          ></div>
          <a
            target="_blank"
            data-dgat="recommendationsStatus-144"
            *ngIf="recommendation.reference.url"
            href="{{ recommendation.reference.url }}"
            [innerHTML]="recommendation.reference.title"
          >
          </a>
          <a
            target="_blank"
            data-dgat="recommendationsStatus-9e9"
            *ngIf="
                recommendation.reference.internalUrl &&
                !recommendation.reference.url
              "
            href="{{ recommendation.reference.internalUrl }}"
            [innerHTML]="recommendation.reference.title"
          >
          </a>
        </td>
        <td class="no-mobile" [innerHTML]="recommendation.creator?.name"></td>
        <td>
          <div class="mobile-only">
              <span
                [innerHTML]="recommendation.percentComplete | number: '1.0-0'"
              ></span
              >%
          </div>
          <div class="no-mobile">
            <ngb-progressbar
              attr.aria-label="{{
                  recommendation.percentComplete | number: '1.1-1'
                }}%"
              ngbTooltip="{{
                  recommendation.percentComplete | number: '1.1-1'
                }}%"
              class="show"
              [value]="recommendation.percentComplete"
            >
            </ngb-progressbar>
          </div>
        </td>
        <td
          class="l_w15"
          [innerHTML]="recommendation.dateDue | date: 'mediumDate'"
        ></td>
        <td
          class="l_w15 no-mobile"
          [innerHTML]="recommendation.dateCreated | date: 'mediumDate'"
        ></td>
        <td class="no-mobile right-text no-wrap">
          <button
            type="button"
            class="color-ebony-a61 guts-m-r-1"
            attr.aria-label="{{ 'Core_Edit' | translate }}"
            *ngIf="recommendation.canEdit"
            (focus)="detailTooltipEnabled = true"
            (click)="
                t.close();
                detailTooltipEnabled = false;
                editRec($event, recommendation);
                trackEdit(recommendation.reference.title)
              "
            ngbTooltip="{{ 'Core_Edit' | translate }}"
            #t="ngbTooltip"
            [disableTooltip]="!detailTooltipEnabled"
            data-dgat="recommendationsStatus-f1c"
          >
            <df-icon icon="pencil" size="medium"></df-icon>
          </button>
          <button
            type="button"
            class="color-ebony-a61 guts-m-r"
            attr.aria-label="{{ 'Core_Details' | translate }}"
            (focus)="detailTooltipEnabled = true"
            (click)="
                t.close();
                detailTooltipEnabled = false;
                getDetailsModal($event, recommendation);
                detailTooltipEnabled = false
              "
            ngbTooltip="{{ 'Core_Details' | translate }}"
            #t="ngbTooltip"
            [disableTooltip]="!detailTooltipEnabled"
            data-dgat="recommendationsStatus-6b4"
          >
            <df-icon icon="dots" size="medium"></df-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="spinner" *ngIf="loading"></div>
  </div>
  <div class="center-text guts-m-t-1" *ngIf="showMore">
    <button
      df-button
      dfButtonType="passive"
      data-dgat="recommendationsStatus-7ff"
      (click)="loadMore()"
      translate="Core_ShowMore"
    ></button>
  </div>
</div>
