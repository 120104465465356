import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserInputModalService } from '@app/inputs/services/user-input-modal.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { WindowToken } from '@app/shared/window.token';
import { AddTagsModalComponent } from '@app/tags/components/add-tags-modal/add-tags-modal.component';
import { AddSuggestedTagsModalComponent } from '@app/tags/components/add-suggested-tags-modal/add-suggested-tags-modal.component';
import { AddTagsConvertCanonicalModalComponent } from '@app/tags/components/add-tags-convert-canonical-modal/add-tags-convert-canonical-modal.component';
import { PeerRatingOverviewModalComponent } from '@app/tags/components/peer-rating-overview-modal/peer-rating-overview-modal.component';
import { TagPeerRatingRequestModalComponent } from '@app/tags/components/tag-peer-rating-request-modal/tag-peer-rating-request-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { TagsApi } from '../tag-api.model';
import { InternalTagRatingTypes } from '../tags';
import { TagRatingTrackerService } from './tag-rating-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class TagModalService {
  private readonly i18n = this.translate.instant(['Core_ActionError']);

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private tagRatingTrackerService: TagRatingTrackerService,
    private translate: TranslateService,
    private userInputModalService: UserInputModalService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  /**
   * Opens modal to add one or more tags to a users profile
   */
  public openAddTagsModal(event: Event): Observable<void> {
    // Exception for client providers. See user story 19026 for details
    if (this.authService.authUser?.defaultOrgInfo?.settings.isClientProvider) {
      this.router.navigateByUrl('/getstarted/skills');
      return EMPTY;
    }
    const checkForCanonicalTags =
      this.authService.authUser?.defaultOrgInfo?.settings
        .enableWorkdaySkillsIntegration;
    return this.modalService
      .show(AddTagsModalComponent, {
        inputs: { checkForCanonicalTags },
      })
      .pipe(
        mergeMap((results: TagsApi.CanonicalTag[]) => {
          // if canonical skills exist, open new modal
          if (checkForCanonicalTags && results) {
            return this.openAddTagsConvertCanonicalModal(event, results);
          } else {
            return of();
          }
        })
      );
  }

  public openAddSuggestedTagsModal(event: Event): Observable<void> {
    const checkForCanonicalTags =
      this.authService.authUser?.defaultOrgInfo?.settings
        .enableWorkdaySkillsIntegration;
    const canViewSkillSuggestions =
      this.authService?.authUser?.defaultOrgInfo?.settings
        ?.allowSkillSuggestions;
    return this.modalService
      .show(AddSuggestedTagsModalComponent, {
        inputs: { checkForCanonicalTags, canViewSkillSuggestions },
      })
      .pipe(
        mergeMap((results: TagsApi.CanonicalTag[]) => {
          // if canonical skills exist, open new modal
          if (checkForCanonicalTags && results) {
            return this.openAddTagsConvertCanonicalModal(event, results);
          } else {
            return of();
          }
        })
      );
  }

  public openAddTagsConvertCanonicalModal(
    event: Event,
    canonicalTags: TagsApi.CanonicalTag[]
  ): Observable<void> {
    return this.modalService.show(AddTagsConvertCanonicalModalComponent, {
      inputs: { canonicalTags },
    });
  }

  /**
   * Opens modal to add one or more tags to a piece of content.
   *
   * @param target - Source target.
   * @param item - Content item.
   */
  public openAddTagsToContentItemModal(
    target: HTMLElement,
    item: any
  ): Observable<unknown> {
    return this.userInputModalService
      .addTags({
        ...item,
        sourceTarget: target,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translate.instant(
              'ProfileCtrl_EditCollectionItemNotifierFormat',
              {
                itemTitle: item.title,
              }
            )
          );
        })
      );
  }

  /**
   * Show overview of pending/completed peer ratings for a user
   */
  public openPeerRatingOverviewModal(
    tag: TagsApi.TagDetails,
    pendingRatings?: Partial<TagsApi.UserTagRatingDetails>[],
    completedRatings?: Partial<TagsApi.UserTagRatingDetails>[],
    isOwner?: boolean
  ): Observable<void> {
    return this.modalService.show(PeerRatingOverviewModalComponent, {
      inputs: {
        tag,
        pendingRatings,
        completedRatings,
        requestRating: (event) => this.openPeerRatingRequestModal(event, tag),
        isOwner,
      },
    });
  }

  /**
   * Request a tag rating from a users peer
   */
  public openPeerRatingRequestModal(
    event: Event,
    tag: TagsApi.TagDetails
  ): Observable<void> {
    this.tagRatingTrackerService.trackRatingUpdateInitiated(
      event?.target as HTMLElement,
      tag,
      InternalTagRatingTypes.peer,
      this.authService.authUser?.viewerProfile?.userProfileKey // logged in user is the owner here
    );
    return this.modalService.show(TagPeerRatingRequestModalComponent, {
      inputs: {
        tag,
      },
    });
  }
}
