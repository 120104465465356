export enum Visibility {
  /** Private to collaborators */
  private = 0,
  /** Visible to organization */
  public = 1,
  /** Visible to group */
  groups = 2,
  /** Visible on collaborator's profile */
  profile = 3,

  /** Variations used in some locations; TODO: correct at the API to use lowercase version and eventually sunset */
  Org = 1,
  Group = 2,
}
