import {
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import {
  blockIdGenerator,
  formatCommitRequestBody,
} from './file-chunk-uploader.utils';
import { getFormData } from '@app/shared/utils/content-upload';

export interface FileUploadChunkResult {
  fileUrl: string;
  fileId: string;
}

export interface RequestUploadResponse {
  uploadId: string;
  uploadUrl: string;
  canChunkUpload: boolean;
}

// TODO: These events could be refactored to use the newer common upload events instead
export interface CompleteUploadResponse {
  fileId?: string;
  fileUrl: string;
}

export interface FileChunkEvent {
  type: FileChunkEventType;
  data: any;
}

export enum FileChunkEventType {
  Progress = 'Progress',
  Response = 'Response',
}

@Injectable({ providedIn: 'root' })
export class FileChunkUploaderService {
  public static readonly MaxBlockSize = 1024 * 1024 * 10; // 10 MB

  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient
  ) {}

  /**
   * Handles chunked uploads of files via Azure Storage Services
   * Non-media (non-video and non-audio) types do not get chunk uploaded below
   * @link https://docs.microsoft.com/en-us/rest/api/storageservices/operations-on-block-blobs
   */
  public uploadFile(
    file: File,
    purpose: string,
    progressHandler?: (progress: number) => void
  ): Observable<FileUploadChunkResult> {
    let uploadId, uploadUrl;
    return this.requestUpload(file.name, purpose).pipe(
      switchMap((response) => {
        // capture these values for use later in the pipeline
        uploadId = response.uploadId;
        uploadUrl = response.uploadUrl;

        if (!response.canChunkUpload) {
          const options = {
            observe: 'events' as 'events',
            reportProgress: true,
          };
          const isAzureUpload = true;
          const formData = getFormData(file, isAzureUpload);
          return this.http.post(uploadUrl, formData, options);
        }
        return this.uploadBlocks(file, uploadUrl);
      }),
      // Filter out progress events, and emit to the caller
      tap((event) => {
        if (
          (progressHandler && event.type === FileChunkEventType.Progress) ||
          event.type === HttpEventType.UploadProgress
        ) {
          const data =
            event.type === FileChunkEventType.Progress
              ? (event as FileChunkEvent).data
              : (event as HttpProgressEvent).loaded;
          progressHandler(data);
        }
      }),
      // Return only the final response from the upload
      first((event) => {
        return (
          event.type === FileChunkEventType.Response ||
          event.type === HttpEventType.Response
        );
      }),
      // Complete the upload after our last block is uploaded
      switchMap(() => {
        return this.completeUpload(uploadId, purpose, file.name, file.size);
      }),
      catchAndSurfaceError(
        this.translate.instant('dgAuthorVideo_UploadErrorMessage')
      )
    );
  }

  /**
   * Request the unique upload endpoint for this file
   */
  private requestUpload(
    fileName: string,
    purpose: string
  ): Observable<RequestUploadResponse> {
    return this.http.get<RequestUploadResponse>('/files/requestUpload', {
      params: { fileName, purpose },
    });
  }

  /**
   * Given an in-progress upload, signal completion and return the fileUrl
   */
  private completeUpload(
    uploadId: string,
    purpose: string,
    fileName: string,
    fileSize: number
  ) {
    return this.http.get<CompleteUploadResponse>('/files/completeUpload', {
      params: { uploadId, purpose, fileName, fileSize },
    });
  }

  /**
   * Upload the file in chunks
   */
  private uploadBlocks(
    file: File,
    uploadUrl: string
  ): Observable<FileChunkEvent> {
    const nextBlockId = blockIdGenerator();
    let maxBlockSize = FileChunkUploaderService.MaxBlockSize;
    if (file.size < maxBlockSize) {
      maxBlockSize = file.size;
    }

    const blockIds = [];
    let bytesUploaded = 0;
    let currentFilePointer = 0;
    let totalBytesRemaining = file.size;

    return new Observable<FileChunkEvent>((subscriber) => {
      const fileReader = new FileReader();
      let activeUploadSub;

      fileReader.onloadend = (e) => {
        if (fileReader.readyState === FileReader.DONE) {
          const block = fileReader.result;
          let blockLength: number;
          if (block instanceof ArrayBuffer) {
            blockLength = block.byteLength;
          } else {
            blockLength = block.length;
          }

          activeUploadSub = this.putBlock(block, uploadUrl, blockIds)
            .pipe(
              tap((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                  const totalPercentCompleted =
                    ((bytesUploaded + event.loaded) / file.size) * 100;

                  // emit this event to our subscriber
                  subscriber.next({
                    type: FileChunkEventType.Progress,
                    data: totalPercentCompleted,
                  });
                }
              }),
              filter((event) => event.type === HttpEventType.Response)
            )
            .subscribe({
              next: () => {
                bytesUploaded += blockLength;
                const totalPercentCompleted = (bytesUploaded / file.size) * 100;

                // emit this event to our subscriber
                subscriber.next({
                  type: FileChunkEventType.Progress,
                  data: totalPercentCompleted,
                });

                setTimeout(() => {
                  // timeout gives enough time for browser garbage collector to
                  // clear out previous chunk from memory before proceeding to the next.
                  // This helps prevent out of memory errors. (See bug #16223)
                  loadNextBlock();
                }, 100);
              },
              // expose the error here to our subscriber
              error: (error) => subscriber.error(error),
            });
        }
      };

      const loadNextBlock = () => {
        if (totalBytesRemaining > 0) {
          const blockId = nextBlockId();
          blockIds.push(blockId);

          const fileContent = file.slice(
            currentFilePointer,
            currentFilePointer + maxBlockSize
          );
          fileReader.readAsArrayBuffer(fileContent);

          currentFilePointer += maxBlockSize;
          totalBytesRemaining -= maxBlockSize;
          if (totalBytesRemaining < maxBlockSize) {
            maxBlockSize = totalBytesRemaining;
          }
        } else {
          this.commitBlockList(file, uploadUrl, blockIds).subscribe({
            next: (response: any) =>
              subscriber.next({
                type: FileChunkEventType.Response,
                data: response,
              }),
            complete: () => subscriber.complete(),
          });
        }
      };

      subscriber.next({ type: FileChunkEventType.Progress, data: 0 });
      loadNextBlock();

      // support teardown in the observable
      return () => {
        if (activeUploadSub) {
          activeUploadSub.unsubscribe();
        }
      };
    });
  }

  /**
   * @link https://docs.microsoft.com/en-us/rest/api/storageservices/put-block#sample-request
   */
  private putBlock(
    block: string | ArrayBuffer,
    uploadUrl: string,
    blockIds: string[]
  ): Observable<HttpEvent<unknown>> {
    const url =
      uploadUrl +
      '&api-version=2017-07-29&comp=block&blockid=' +
      blockIds[blockIds.length - 1];

    return this.http.put(url, block, {
      observe: 'events',
      reportProgress: true,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
      },
    });
  }

  /**
   * @link https://docs.microsoft.com/en-us/rest/api/storageservices/put-block-list#sample-request
   */
  private commitBlockList(
    file: File,
    uploadUrl: string,
    blockIds: string[]
  ): Observable<unknown> {
    const url = uploadUrl + '&api-version=2017-07-29&comp=blocklist';
    const requestBody = formatCommitRequestBody(blockIds);

    return this.http.put(url, requestBody, {
      headers: { 'x-ms-blob-content-type': file.type },
    });
  }
}
