import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isKey, Key } from '@app/shared/key';
import { AuthService } from '@app/shared/services/auth.service';
import { TagFlagsService } from '@app/tags/services/tag-flags.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'dgx-peer-rating-overview-modal',
  templateUrl: './peer-rating-overview-modal.component.html',
  styleUrls: ['./peer-rating-overview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeerRatingOverviewModalComponent {
  @Input() public tag: TagsApi.TagDetails;
  @Input() public pendingRatings?: Partial<TagsApi.UserTagRatingDetails>[];
  @Input() public completedRatings?: Partial<TagsApi.UserTagRatingDetails>[];
  @Input() public requestRating: (event: Event) => Observable<void>;
  @Input() public isOwner: boolean = false;

  public i18n = this.translateService.instant([
    'dgTagRating_PeerRatings',
    'dgTagRating_RequestPeerRating',
    'dgTagRating_DeactivatedUser',
    'Core_Cancel',
    'Core_Done',
    'Core_Pending',
  ]);

  constructor(
    private translateService: TranslateService,
    private tagRatingService: TagRatingService,
    private activeModal: NgbActiveModal,
    private tagFlagsService: TagFlagsService,
    private authService: AuthService
  ) {}

  public get ctaText(): string {
    return this.canRequestPeerRating
      ? this.i18n.dgTagRating_RequestPeerRating
      : this.i18n.Core_Done;
  }

  public get cancelPendingRatingLabel(): string {
    return `${this.i18n.Core_Cancel} ${this.i18n.Core_Pending} ${this.i18n.dgTagRating_PeerRatings}`;
  }

  public get canRequestPeerRating(): boolean {
    return (
      this.isOwner &&
      this.authService?.authUser?.canRequestPeerRating &&
      this.tagFlagsService.showPeerRatings
    );
  }

  public ratingLevelString(level: string): string {
    return this.translateService.instant('dgTagRating_Level', {
      Level: level,
    });
  }

  public requestedAgeString(age: string): string {
    return this.translateService.instant('dgTagRating_RatingRequestedAge', {
      RequestedAge: age,
    });
  }

  public keydownHandler(
    event: KeyboardEvent,
    rating: Partial<TagsApi.UserTagRatingDetails>
  ): void {
    if (isKey(event, Key.Enter, Key.Space)) {
      // prevent spacebar from scrolling page
      event.preventDefault();
      this.openCompletedRatingModal(event, rating);
    }
  }

  public cancelPendingRating(
    event: Event,
    rating: Partial<TagsApi.UserTagRatingDetails>
  ): Subscription {
    return this.tagRatingService
      .openCancelRatingRequestModal(
        event,
        InternalTagRatingTypes.peer,
        this.tag,
        rating
      )
      .subscribe(() => {
        this.activeModal.dismiss();
      });
  }

  public openCompletedRatingModal(
    event: Event,
    rating: Partial<TagsApi.UserTagRatingDetails>
  ): Subscription | void {
    if (!this.isOwner || !rating.rater) {
      return;
    }

    return this.tagRatingService.openCompletedAssociateRatingModal(
      event,
      rating,
      this.canRequestPeerRating,
      this.requestRating
    );
  }

  public handleClick(event: Event): void {
    if (!this.canRequestPeerRating) {
      return this.activeModal.dismiss();
    }
    this.requestRating(event).subscribe(() => {
      this.activeModal.dismiss();
    });
  }

  public getRaterName(
    raterInfo: Partial<TagsApi.UserTagRatingDetails>
  ): string {
    return raterInfo.rater?.name ?? this.i18n.dgTagRating_DeactivatedUser;
  }
}
