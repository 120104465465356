<div class="l_flexbar m-l_flexbar-col">
  <dgx-data-search
    [placeholder]="searchPlaceholderText"
    [searchTerm]="searchTerm"
    (doSearch)="updateQuery($event)"
    type="short"
    class="m-full-width l_flex-grow guts-m-r-1 m-guts-m-b-half m-guts-m-r-0"
  ></dgx-data-search>
  <ng-container
    *dgxFeatureToggle="
      ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
      hidden: true
    "
  >
    <div class="m-guts-p-t-1 l_flexbar gap-full-1 m-l_flexbar-col">
      <button
        *ngIf="canInviteMembers"
        (click)="inviteMembers($event)"
        df-button
        dfButtonType="primary"
        data-dgat="orgMembers-6ec"
      >
        <div class="l_flexbar gap-full-half">
          <df-icon icon="plus"></df-icon>
          <div>{{ inviteButtonText }}</div>
        </div>
      </button>
      <dgx-ftp-upload-link
        *ngIf="canUpdateUsers"
        buttonText="{{ 'OrgManage_UpdateUsers' | translate }}"
        [fileUploadType]="userUpdateType"
        icon="arrow-circular"
        [orgId]="orgId"
      ></dgx-ftp-upload-link>
      <dgx-ftp-upload-link
        *ngIf="canUploadUsers"
        buttonText="{{ 'OrgManage_UploadUsers' | translate }}"
        [fileUploadType]="userUploadType"
        icon="arrow-up-tray"
        [orgId]="orgId"
      ></dgx-ftp-upload-link>
    </div>
  </ng-container>
</div>
