import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from '../utils';

/**
 * A pipe that sorts an array of objects based on one or more keys and orders.
 * It utilizes a standalone sorting utility function to perform the actual sorting logic.
 * This pipe can be used in Angular templates to dynamically sort arrays based on user-defined criteria.
 *
 * @example
 * ```html
 * <!-- In an Angular template, assuming 'people' is an array of objects with 'name' and 'age' properties -->
 * <div *ngFor="let person of people | orderBy: ['name', 'age']: ['asc', 'desc']">
 *   Name: {{ person.name }}, Age: {{ person.age }}
 * </div>
 * ```
 * This will sort the `people` array first by `name` in ascending order and then by `age` in descending order.
 *
 * @param array The array to be sorted.
 * @param keys An array of keys (property names) to sort by. If it's a single key, it can be passed as a string.
 * @param orders Optional. An array of sort orders corresponding to the keys, either 'asc' for ascending or 'desc' for descending.
 * If it's a single order, it can be passed as a string. If unspecified, defaults to ascending order. If there are fewer orders than keys,
 * the last specified order is applied to the remaining keys, or 'asc' if only one key is provided.
 * @returns A new array sorted based on the specified keys and orders. If the input array is empty, null, or no keys are provided,
 * the original array is returned unchanged.
 */
@Pipe({
  name: 'orderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform(
    array: Array<any>,
    keys: string | string[],
    orders?: string | string[]
  ): Array<any> {
    // If the array is empty or null, or keys are not provided, return the array as is
    if (!array || array.length === 0 || !keys || keys.length === 0) {
      return array;
    }

    // Sort the array using the provided utility function
    return array.sort((a, b) => orderBy(a, b, keys, orders));
  }
}
