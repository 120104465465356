<div *ngIf="liveEvent">
  <dl
    class="l_flexbar m-l_flex font-medium par par--light_a par--small no-wrap"
    [class.resource-meta--compact]="config?.compact"
  >
    <!-- these can be assignments or collection items, collection items won't have assignment info-->
    <dt class="a11y-hide">{{ i18n.A11y_OptionalStatus }}</dt>
    <dd
      *ngIf="isOptional"
      class="resource-meta__item"
      [title]="i18n.Core_Optional"
    >
      <span class="no-wrap badge badge-pill badge-pill--compact badge-neutral">
        {{ i18n.Core_Optional }}
      </span>
    </dd>
    <dt class="a11y-hide">{{ i18n.A11y_VerificationStatus }}</dt>
    <dd
      *ngIf="assignmentInfo?.reference?.isVerified || isVerified"
      data-dgprop-item-clicked="Verified"
      class="guts-m-r-half resource-meta__item"
      [title]="i18n.dgContentTile_Verified"
    >
      <df-icon
        icon="checkmark-circle"
        size="small"
        class="color-success-dark guts-m-r-quart verified-icon"
      ></df-icon>
      <span class="color-success-dark">{{ i18n.dgContentTile_Verified }}</span>
    </dd>

    <!-- required/assigned badge -->
    <dt class="a11y-hide">{{ i18n.A11y_AssignmentStatus }}</dt>
    <dd
      class="resource-meta__item rel skip-delimiter"
      *ngIf="viewModel.showAssignementBadge"
    >
      <!--
        Linter Error:
        [resourceType]="resourceType"
        Property 'resourceType' does not exist on type 'LiveEventMetadataComponent'.
      -->

      <dgx-assignment-badge
        [assignmentInfo]="assignmentInfo"
      ></dgx-assignment-badge>
    </dd>
    <!-- registered badge -->
    <dt class="a11y-hide">{{ i18n.A11y_RegistrationStatus }}</dt>
    <dd *ngIf="viewModel.showRegisteredBadge" class="resource-meta__item">
      <span class="badge badge-pill badge-pill--compact badge-emphasize">{{
        i18n.Core_Registered
      }}</span>
    </dd>

    <!-- live badge -->
    <dt class="a11y-hide">{{ i18n.Core_SessionType }}</dt>
    <dd
      *ngIf="viewModel.showLiveBadge"
      class="resource-meta__item color-destructive"
    >
      <df-icon icon="broadcast" size="small" class="guts-m-r-quart"></df-icon>
      <span class="up-me font-semibold">{{ i18n.Core_Live }}</span>
    </dd>

    <dt class="a11y-hide">
      {{ i18n.dgOrgInternalContent_ContentType }}
    </dt>
    <dd
      *ngIf="viewModel.showInputType"
      class="resource-meta__item skip-delimiter"
    >
      {{ translatedInputType }}
    </dd>

    <!-- date of single session -->
    <dt class="a11y-hide">{{ i18n.A11y_SessionStartDate }}</dt>
    <dd *ngIf="viewModel.showDate" class="resource-meta__item">
      {{ liveEvent.startDateFormatted }}
    </dd>

    <!-- date and city of single session -->
    <dt class="a11y-hide">{{ i18n.Core_SessionLocation }}</dt>
    <dd *ngIf="viewModel.showLocation" class="resource-meta__item">
      {{ location }}
    </dd>

    <!-- multiple sessions -->
    <dt class="a11y-hide">{{ i18n.Core_Multisession }}</dt>
    <dd *ngIf="viewModel.showMultiSession" class="resource-meta__item">
      {{ i18n.Core_Multisession }}
    </dd>
  </dl>
</div>
