import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { fadeInAndOut } from '@app/shared/animations/animations';
import { isKey, Key } from '@app/shared/key';
import { A11yService } from '@app/shared/services/a11y.service';
import { ScrollService } from '@app/shared/services/scroll.service';
import { DfIconChevronDown12, DfIconRegistry } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

export type SelectTemplateOption = 'dropdown' | 'filter';

/**
 * This component is based on the `dgx-select` component, but implements the ControlValueAccessor
 * and Validator interface to work with reactive forms.
 * It was quickly put together to use in reporting.
 * As the with original component, it should be refactored to be more generic, clean and reusable.
 * There are some small differences in the implementation, but the API is mostly the same.
 */

/**
 * NOTE: Module-scoped variable, to support assigning a unique id to each instance of a `dgxSelect` that does not
 * explicity have an id set on the component. This id value is used in the aria attributes of the component
 */
let nextId = 1;

export interface SelectOption {
  id?: any; // default unless `trackBy` is set
  title?: string; // default unless `labelKey` is set
  groupingId?: string; // common id for grouping items together separated by a line
  groupingLabel?: string; // label associated with the above grouping (if any)
  classname?: string;
}

// accepts any type of object, as long as appropriate labelKey and trackBy associations have been made
// TODO: this should accept a generic as the type (everything will need to be refactored at the same time... )
export interface Option extends SelectOption {
  [key: string]: any; // must at least include the key associated with labelKey
}

/**
 * @name Select-Reactive Component
 *
 * @desc Simple collapsible/expandable listbox. Similar to {@see ComboboxComponent}, but without the functionality to
 * filter down the list of options that works with reactive forms
 *
 * @param id an unique identifier for this instance of the select (defaults to a random, unique number)
 * @param ariaLabelledby id for an existing label in the page that labels this instance of the select
 * @param ariaLabel label for this select if no label element exists
 * @param loadingText message to display when options are loading
 * @param options input options in a form of array of objects whose keys align with labelKey and trackBy.
 * @param trackBy key for the trackBy function, this speeds up the search for long lists (defaults to 'id' prop)
 * @param labelKey key for the property to iterate on in the object in the array of options (defaults to 'title' prop)
 * @param labelValue a custom function to overwrite what displays in the dropdown instead of option[labelKey]. ** You still need a labelKey for this to work **
 * @param placeholder translated string to display when no option has been selected
 * @param dgatInput dgat prefix to pass into inner elements. Falls back to 'select'
 * @param isRequired flag to require the select component -- does not currently do validation, just manages aria attribute
 * @param disabledOptions a subset of options disabled in the dropdown
 *
 * @example
 * <dgx-select-reactive
 *  id="inputType"
 *  [ariaLabel]="aria label text"
 *  [ariaLabelledby]="a-field-label-id"
 *  [loadingText]="loadingText"
 *  [options]="optionList"
 *  [trackBy]="trackByProp"
 *  [labelKey]="labelPropName"
 *  [placeholder]="placeholderText"
 *  [dgatInput]="inputTypeSelect"
 *  [hasError]="form.invalid"
 *  [isDisabled]="false"
 *  [isRequired]="false"
 *  [templateOption]="dropdown"
 * </dgx-select-reactive>
 */

@Component({
  selector: 'dgx-select-reactive',
  templateUrl: './select-reactive.component.html',
  styleUrls: ['./select-reactive.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectReactiveComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectReactiveComponent),
      multi: true,
    },
  ],
  animations: [fadeInAndOut],
})
export class SelectReactiveComponent
  implements AfterViewInit, OnInit, OnChanges, ControlValueAccessor, Validator
{
  @Input('id') public hostId: string | number = nextId++;
  @Input() public ariaLabelledby?: string;
  @Input() public ariaLabel?: string;
  @Input() public loadingText?: string;
  @Input() public options: Option[] = [];
  @Input() public trackBy: string = 'id';
  @Input() public labelKey: string = 'title';
  @Input() public labelValue?: (option: Option) => string;
  @Input() public placeholder: string = '';
  @Input() public dgatInput: string = 'select-reactive';
  @Input() public hasError: boolean = false;
  @Input() public isRequired: boolean = false;
  @Input() public templateOption: SelectTemplateOption = 'dropdown';
  @Input() public disabledOptions: any[] = [];

  @Output() public blur: EventEmitter<any> = new EventEmitter();

  // Trigger to open the dropdown of select options
  @ViewChild('trigger') public trigger: ElementRef;
  // The list of select options
  @ViewChildren('listOptions') public listOptions: QueryList<ElementRef>;
  // The listbox scroll viewport
  @ViewChild('scrollContainer') public scrollContainer: ElementRef;

  // enable or disable visibility of dropdown list
  public isDropdownDisplayed: boolean = false;
  // Is the user actively hovering over the listbox?
  public isHovering: boolean = false;
  // true when user selects an item in the dropdown
  public isItemSelected: boolean = false;
  // current highlighted option index
  public highlightedIndex: number = -1;
  // displays loadingText until options have been passed in
  public optionsLoaded: boolean = false;
  // current selected option index, used to reset selected option after selection
  public selectedIndex: number = -1;
  // display label based on selectedOption
  public selectedLabel: string = '';

  public readonly i18n = this.translate.instant(['Core_LoadingResults']);
  // The fixed size for items in the virtual viewport
  public readonly listboxItemSize = 32;

  // The id for the label element that describes this select
  private labelId?: string;
  // if a labelValue input is preset set to true
  public useLabelValue: boolean = false;
  // current disabled state of the select component
  public isDisabled: boolean = false;

  private listeners: (() => void)[] = [];

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  // Internal model
  private selectedItem: any;

  constructor(
    private element: ElementRef,
    private a11yService: A11yService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private iconRegistry: DfIconRegistry,
    private scrollService: ScrollService,
    @Inject(DOCUMENT) private document,
    private cdr: ChangeDetectorRef
  ) {
    this.iconRegistry.registerIcons([DfIconChevronDown12]);
  }

  public ngOnInit() {
    if (typeof this.labelKey !== 'string' || this.labelKey === undefined) {
      // Throw an error if labelKey isn't provided
      throw new Error('labelKey input binding is not a string or is undefined');
    }

    this.optionsLoaded = !!this.options?.length;
    this.useLabelValue = typeof this.labelValue === 'function';
  }

  public ngAfterViewInit() {
    this.setupAriaLabel();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.optionsLoaded && changes.options?.currentValue) {
      this.optionsLoaded = !!this.options?.length;
      if (!!this.selectedItem) {
        this.setSelection();
      }
    }

    // Extract selectedLabel for selected option
    // (Simple null check here to allow for the value to be 0.)
    // (!= null also matches != undefined)
    if (changes.value?.currentValue != null) {
      this.setSelection();
    }
  }

  public ngOnDestroy() {
    this.removeListeners();
  }

  // ControlValueAccessor interface method
  public writeValue(value: any) {
    if (value === null || value === undefined || value === -1) {
      this.clearSelection();
    } else {
      const option = this.findSelectedOptionByTrackBy(value);
      this.selectOption(option);
    }
  }

  // ControlValueAccessor interface method
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // ControlValueAccessor interface method
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // ControlValueAccessor interface method
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Validator interface method
  public validate(control: AbstractControl): ValidationErrors | null {
    return this.selectedItem === null ? { required: true } : null;
  }

  // Reset the component to the initial state
  public clearSelection(): void {
    // Reset internal states
    this.selectedItem = null;
    this.selectedLabel = '';
    this.selectedIndex = -1;
    this.highlightedIndex = -1;
    this.isItemSelected = false;

    // Notify Angular forms about the change
    this.onChange(this.selectedItem);

    // Close the dropdown if open
    this.isDropdownDisplayed = false;
  }

  public getDgat(...keys) {
    return this.dgatInput + '-' + keys.join('-');
  }

  public getTriggerId() {
    return `select-trigger-${this.hostId}`;
  }

  public optionDisabled(option: Option): boolean {
    if (!option) {
      return false;
    }
    return this.disabledOptions.find(
      (o) => o[this.labelKey] === option[this.labelKey]
    );
  }

  /**
   * Construct the `aria-labelledby` value for the select dropdown trigger from:
   * - The label that describes this select component
   * - The current value of the select
   * - Any additional 'labelled by' value passed in as an input
   */
  public getTriggerAriaLabelledby(): string | null {
    if (this.ariaLabel) {
      return null;
    }

    const labelId = this.labelId;
    let value = (labelId ? labelId + ' ' : '') + this.getTriggerId();

    if (this.ariaLabelledby) {
      value += ' ' + this.ariaLabelledby;
    }

    return value;
  }

  public getListboxId() {
    return `select-listbox-${this.hostId}`;
  }

  /**
   * Construct the `aria-labelledby` value for the select item list:
   * - The label that describes this select component
   * - Any additional 'labelled by' value passed in as an input
   */
  public getListboxAriaLabelledby(): string | null {
    if (this.ariaLabel) {
      return null;
    }

    const labelId = this.labelId;
    let value = labelId ? labelId + ' ' : '';

    if (this.ariaLabelledby) {
      value += ' ' + this.ariaLabelledby;
    }

    return value;
  }

  public getOptionId(index: number) {
    return `select-option-${this.hostId}-${index}`;
  }

  /**
   * Select and emit the option from the given selected index
   */
  public selectOption(option: Option): void {
    if (!option) {
      return;
    }

    this.isItemSelected = true;
    this.selectedIndex = this.options.indexOf(option[this.trackBy]);
    this.selectedLabel = option[this.labelKey];
    this.selectedItem = option;

    // notify change
    this.onChange(this.selectedItem[this.trackBy]);

    this.toggleDropdown(false);
  }

  /**
   * Set the selected index, highlight the option in the virtual scroll, and set the aria attribute for option
   */
  public highlightOption(index: number) {
    this.highlightedIndex = index;
    const elementId = this.getOptionId(index);

    this.setActiveDescendant(elementId);
    this.scrollElementIntoView(elementId);
  }

  /**
   * Checks to see if a group divider is necessary
   * @param option pass in the option from loop on template
   * @param i pass in the index from loop on template
   */
  public isGrouping(option: Option, i: number) {
    let isGrouping = false;
    if (
      !!option.groupingId &&
      option.groupingId !== this.options[i - 1]?.groupingId
    ) {
      // add divider above new group
      isGrouping = true;
    } else if (!option.groupingId && !!this.options[i - 1]?.groupingId) {
      // non-group option following a group also needs a divider
      isGrouping = true;
    }
    return isGrouping;
  }

  /**
   * For angular to keep track of the long list of results
   */
  public trackByTrackBy = (index: number, option: Option): string => {
    return option[this.trackBy] ?? option;
  };

  /**
   * Handle blur event from the trigger
   *
   * NOTE: isHovering check is needed to allow scrolling in the list on IE11
   */
  public handleBlur(): void {
    this.onTouched(); // Notify Angular forms that the control was touched

    if (this.isDropdownDisplayed && !this.isHovering) {
      this.toggleDropdown(false);
    }
    this.blur.emit();
  }

  /**
   * Handle keyboard interactions for the listbox while focus is on the trigger button
   *
   * https://w3c.github.io/aria-practices/#listbox_kbd_interaction
   */
  public handleKeydown(event: KeyboardEvent): void {
    const optionsLength = this.options.length;

    if (this.isDropdownDisplayed) {
      // handle events when the dropdown is open
      if (isKey(event, Key.Enter)) {
        event.preventDefault();
        event.stopPropagation();

        this.selectOption(this.options[this.highlightedIndex]);
      } else if (isKey(event, Key.Escape)) {
        event.stopPropagation();

        this.toggleDropdown(false);
      } else if (isKey(event, Key.Down)) {
        event.preventDefault();
        event.stopPropagation();

        this.highlightOption((this.highlightedIndex + 1) % optionsLength);
      } else if (isKey(event, Key.Up)) {
        event.preventDefault();
        event.stopPropagation();

        let nextIndex = this.highlightedIndex - 1;
        if (nextIndex < 0) {
          nextIndex = optionsLength - 1;
        }

        this.highlightOption(nextIndex);
      } else if (isKey(event, Key.Home)) {
        this.highlightOption(0);
      } else if (isKey(event, Key.End)) {
        this.highlightOption(optionsLength - 1);
      }
    } else {
      // open the dropdown with the following key events
      if (isKey(event, Key.Enter, Key.Down, Key.Up, Key.Space)) {
        event.preventDefault();
        event.stopPropagation();

        this.toggleDropdown(true);
      }
    }
  }

  /**
   * Prevent blur event on parent firing faster than click event when the dropdown is open
   */
  public handleMousedown(event: Event): void {
    if (this.isDropdownDisplayed) {
      event.preventDefault();
    }
  }

  /**
   * Toggle the dropdown list when input is focused or moves out of focus
   */
  public toggleDropdown = (shouldOpen?: boolean, event?: Event): void => {
    // ignore unnecessary events
    if (this.isDisabled || shouldOpen === this.isDropdownDisplayed) {
      event?.stopPropagation();
      return;
    }

    if (this.optionDisabled(this.options[this.selectedIndex])) {
      this.selectedIndex = -1;
    }

    this.isDropdownDisplayed = shouldOpen ?? !this.isDropdownDisplayed;
    if (this.isDropdownDisplayed) {
      // Ensure the trigger button is focused after open, to capture key events and ensure the blur will fire when
      // focus leaves
      this.trigger.nativeElement.focus();

      // Select the first in list or the selected index
      const firstSelectable = this.options.findIndex(
        (option) => !this.optionDisabled(option)
      );
      this.highlightOption(Math.max(this.selectedIndex, firstSelectable));

      this.a11yService.announceOptionCount(this.options.length);
    } else {
      this.highlightedIndex = -1;

      // see note on method for Safari
      this.setActiveDescendant(undefined);

      if (!this.isItemSelected) {
        this.selectedLabel = '';
        this.selectedIndex = -1;
      }
    }
    this.cdr.detectChanges();
  };

  private setSelection() {
    const selectedIndex = this.options?.findIndex(
      (o) =>
        this.selectedItem === o ||
        this.selectedItem === (o[this.trackBy] ?? o) ||
        this.selectedItem?.[this.trackBy] === (o[this.trackBy] ?? o)
    );

    this.selectedIndex = selectedIndex;
    const doesIndexHaveValue =
      this.selectedIndex !== null && this.selectedIndex !== undefined;
    if (doesIndexHaveValue && this.selectedIndex > -1) {
      this.isItemSelected = true;
      this.selectedLabel = this.options[selectedIndex]
        ? this.options[selectedIndex][this.labelKey]
        : '';
    }
  }

  /**
   * Sets up the the best aria label from the component properties. If no explicit label is given,
   * this finds a parent `<label>` element, and sets the `aria-labelledby` automatically
   *
   * Based on the old implementation in `dgSelect` and the implementation in Angular Material's
   * `matSelect`
   *
   * @link https://github.com/angular/components/blob/master/src/material/select/select.ts#L523-L536
   */
  private setupAriaLabel() {
    // Don't check when there is an explicit label for the component
    if (this.ariaLabel) {
      return;
    }

    // NOTE: must defer the setup to avoid `ExpressionChangedAfterItHasBeenCheckedError`
    setTimeout(() => {
      const parentElement: HTMLElement =
        this.element.nativeElement.parentElement;

      let labelEl: HTMLElement = this.document.querySelector(
        `label[for="${this.hostId}"]`
      );

      if (!labelEl && parentElement.tagName === 'LABEL') {
        labelEl = parentElement;
      }

      // If no suitable label element is found, log an error for the developer
      if (!labelEl) {
        console.warn(
          '[A11y] dgxSelect component must have an associated <label> (wrapped or for/id related), or an aria-label attribute.'
        );

        // Set the placeholder as a temporary label
        if (this.placeholder) {
          this.ariaLabel = this.placeholder;
        }

        return;
      }

      const labelId = labelEl.id || 'select-label-' + this.hostId;
      if (!labelEl.id) {
        this.renderer.setAttribute(labelEl, 'id', labelId);
      }
      this.labelId = labelId;
      this.listeners.push(
        this.renderer.listen(labelEl, 'click', (event: Event) => {
          this.toggleDropdown(true, event);
        })
      );
      this.cdr.detectChanges();
    });
  }

  /**
   * Announce the 'focused' element in the list of options
   *
   * NOTE: On Safari with VoiceOver, if active descendant is not unset when focus leaves, the listbox popup is not
   * recognized when the trigger is re-focused
   *
   * https://www.w3.org/TR/wai-aria-practices/#kbd_focus_activedescendant
   */
  private setActiveDescendant(elementId?: string) {
    if (elementId) {
      this.renderer.setAttribute(
        this.trigger.nativeElement,
        'aria-activedescendant',
        elementId
      );
    } else {
      this.renderer.removeAttribute(
        this.trigger.nativeElement,
        'aria-activedescendant'
      );
    }
  }

  /**
   * Scroll element for highlighted index into view, if it is outside the viewport
   */
  private scrollElementIntoView(elementId: string): void {
    const element = this.document.getElementById(elementId);
    const viewport = this.scrollContainer?.nativeElement;

    if (element && viewport) {
      if (!this.scrollService.inViewport(element, 0, viewport)) {
        this.scrollService.scrollToElementByReference(element);
      }
    }
  }

  private removeListeners() {
    for (const unlisten of this.listeners) {
      unlisten();
    }
    this.listeners = [];
  }

  private findSelectedOptionByTrackBy(value: any): Option {
    return this.options.find((option) => option[this.trackBy] === value);
  }

  private findSelectedIndexByTrackBy(value: any): number {
    return this.options.findIndex((option) => option[this.trackBy] === value);
  }

  private findIndexByLabel(label: string): number {
    return this.options.findIndex((option) => option[this.labelKey] === label);
  }
}
