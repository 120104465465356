import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SplitButtonMenuItemComponent } from './split-button-menu-item/split-button-menu-item.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * Split Button Component
 * This component is used to display a primary action button next to another button that acts
 * as a drop down list for other actions.
 *
 * Uses ng-bootstrap Dropdown `https://ng-bootstrap.github.io/#/components/dropdown/examples`
 *
 * @param buttonClass {string} (Optional) Default value is `btn-passive`.
 * @param displayText {string} Primary button's display text.
 * @param dropdownPosition {string} (Optional) If set to `bottom-right`, dropdown menu will be right-aligned instead of left.
 * @param icon {string} Icon the be added in to the primary button to the left of the Title.
 * @param primaryAction Event emitter to pass the action to be taken back to the parent component.
 *
 * EXAMPLE USAGE:
 * @example
 * ```
 * <dgx-split-button
 *   [displayText]="displayText"
 *   [buttonClass]="btn-primary"
 *   [dropdownPosition]="bottom-right"
 *   [icon]="plus"
 *   (primaryAction)="showModal($event)"
 * >
 *  <dgx-split-button-menu-item
 *   [displayText]="Delete"
 *   [icon]="delete"
 *   (buttonAction)="showModalDelete($event)">
 *  </dgx-split-button-menu-item>
 *
 *  <dgx-split-button-menu-item
 *   [displayText]="Add"
 *   [icon]="plus"
 *   (buttonAction)="showModalAdd($event)">
 * </dgx-split-button-menu-item>
 *
 * </dgx-split-button>
 * ```
 */

@Component({
  selector: 'dgx-split-button',
  templateUrl: './split-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitButtonComponent {
  @ContentChildren(SplitButtonMenuItemComponent)
  public menuItem: SplitButtonMenuItemComponent[];
  @ViewChild('splitButtonTrigger') public splitButtonTriggerRef: ElementRef;
  @ViewChild('ngbDropdownRef') public ngbDropdownRef: NgbDropdown;

  @Input() public buttonClass = 'btn-passive';
  @Input() public displayText = '';
  @Input() public dropdownPosition = 'bottom-right';
  @Input() public icon = '';
  @Input() public isDisabled = false;
  @Output() public primaryAction: EventEmitter<any> = new EventEmitter();

  public readonly i18n = this.translate.instant([
    'A11y_ButtonGroupWithNestedDropdown',
  ]);

  constructor(private translate: TranslateService) {}

  public get hasPrimaryAction(): boolean {
    return this.primaryAction.observers.length > 0;
  }

  public get ariaLabel(): string {
    return `${this.displayText} ${this.i18n.A11y_ButtonGroupWithNestedDropdown}`;
  }

  /**
   * Emit click event to parent
   */
  public buttonAction($event: Event) {
    // if we don't have a primary action then we'll make the button toggle the menu
    if (!this.hasPrimaryAction) {
      this.ngbDropdownRef.toggle();
      return;
    }

    this.primaryAction.emit($event);
  }
}
