<div
  class="df-add-on df-prepend df-text-input-border"
  [ngClass]="prependageType | dfPrefix: 'df-input-decorator__add-on--'"
  #prependage
>
  <ng-content select="[df-prepend]"></ng-content>
</div>
<div
  class="df-input-wrapper"
  [ngClass]="{ 'df-text-input-border': !readonly }"
  [class.df-prepended]="prependage.children.length > 0"
  [class.df-appended]="appendage.children.length > 0"
>
  <ng-content select="input, [df-input-container]"></ng-content>
</div>
<div
  class="df-add-on df-append df-text-input-border"
  [ngClass]="appendageType | dfPrefix: 'df-input-decorator__add-on--'"
  #appendage
>
  <ng-content select="[df-append]"></ng-content>
</div>
