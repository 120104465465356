/**
 * This file is adapted from https://github.com/Mawi137/ngx-image-cropper with our own
 * modifications to support a secondary aspect ratio box while cropping
 */
import { SimpleChanges } from '@angular/core';

export class DgxCropperSettings {
  // from the component  options
  public maintainAspectRatio: boolean = true;
  public aspectRatio: number = 1;
  public secondaryAspectRatio?: number = null;
  public cropArea: number = 0.9;
  public hideResizeSquares: boolean = false;
  public cropperMinWidth: number = 0;
  public cropperMinHeight: number = 0;
  public cropperMaxWidth: number = 0;
  public cropperMaxHeight: number = 0;
  public cropperStaticWidth: number = 0;
  public cropperStaticHeight: number = 0;

  // Internal
  public cropperScaledMinWidth: number = 20;
  public cropperScaledMinHeight: number = 20;
  public cropperScaledMaxWidth: number = 20;
  public cropperScaledMaxHeight: number = 20;

  /** Setup the cropper settings for static height and width */
  public setStaticCropper() {
    this.cropperMinWidth = this.cropperStaticWidth;
    this.cropperMinHeight = this.cropperStaticHeight;
    this.cropperMaxHeight = this.cropperStaticHeight;
    this.cropperMaxWidth = this.cropperStaticWidth;
    this.maintainAspectRatio = false;
    this.hideResizeSquares = true;
  }

  public setOptionsFromChanges(changes: SimpleChanges): void {
    Object.keys(changes)
      .filter((k) => k in this)
      .forEach((k) => (this[k] = changes[k].currentValue));
    this.validateOptions();
  }

  private validateOptions(): void {
    if (this.maintainAspectRatio && !this.aspectRatio) {
      throw new Error(
        '`aspectRatio` should > 0 when `maintainAspectRatio` is enabled'
      );
    }
  }
}
