import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Character limit input component
 * This is a component wrapper that adds a character countdown to the end of
 * a text input or textarea. NOTE - this will not prevent the user from typing over the character
 * limit - the maxlength attribute should be used for that.
 *
 * @param {number} limit character limit
 *
 * @param {string} text the string value of the input
 *
 * EXAMPLE USAGE:
 * @example
 * ```html
 * <dgx-character-limit-input [limit]="titleMaxLength" [text]="form.value.title">
 *   <input
 *     id="title"
 *     type="text"
 *     [attr.maxlength]="titleMaxLength"
 *   />
 * </dgx-character-limit-input>
 * ```
 */
@Component({
  selector: 'dgx-character-limit-input',
  standalone: true,
  templateUrl: './character-limit-input.component.html',
  styleUrls: ['./character-limit-input.component.scss'],
  imports: [NgIf, NgClass, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterLimitInputComponent implements OnInit {
  @Input() public limit: number;
  @Input() public text: string = '';
  @Input() public isOnSettingsPageV2?: boolean = false;
  @Input() public errorState?: boolean = false;

  @ViewChild('content', { static: true }) public content;
  public hasFocus: boolean = false;
  public isTextarea: boolean = false;
  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit() {
    const innerElement =
      this.content.nativeElement.querySelector('input, textarea');
    if (innerElement) {
      this.isTextarea = innerElement.nodeName.toLowerCase() === 'textarea';
    }
    this.cdr.markForCheck();
  }

  @HostListener('focusin', ['$event'])
  public onFocus() {
    this.hasFocus = true;
  }

  @HostListener('focusout')
  public onBlur() {
    this.hasFocus = false;
  }
}
