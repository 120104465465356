import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable, EMPTY } from 'rxjs';

import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { InputSubmissionResult } from '@app/user-content/services/inputs-facade-base';
import { MediaModalFacadeBase } from '../media-modal/media-modal-facade-base';
import { InputContext, MediaEntryApiEntity } from '../user-input.model';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { TranslateService } from '@ngx-translate/core';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { RootArticleFormRenderer } from './renderers/root-article-form.renderer';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { MediaFormModel } from '../media-modal/media-form.model';
import { CompleteAzureUploadResult } from '@degreed/content-hosting-data-access';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { CHUploadService } from '@degreed/content-hosting-data-access';
import { HttpEvent } from '@angular/common/http';

/** A stateful facade service that provides Article add/edit modal functionality to the generic Input
 **/
@Injectable({ providedIn: 'any' }) // provide in any so we can use with modal factory's child injector
export class ArticleModalFacade extends MediaModalFacadeBase<
  MediaFormModel<HostedContentMetadata>
> {
  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: MediaEntryApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    builder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    commentsApiService: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    tipService: TipService,
    @Inject(DOCUMENT) document: Document,
    private renderer: RootArticleFormRenderer,
    contentHostingUploadService: CHUploadService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      builder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      commentsApiService,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      document,
      tipService,
      contentHostingUploadService
    );
  }

  /** Override */
  public onSubmit(): Observable<InputSubmissionResult> {
    if (
      this.isEditing ||
      this.viewModel.mediaUrl ||
      this.viewModel.uploadedContentDetails
    ) {
      // must have either a media URL entered or hosted content uploaded before submitting (i.e. advancing to full form) is valid
      return super.onSubmit();
    }
    return EMPTY;
  }

  protected buildUIConfiguration(): DfFormFieldConfig<DfTemplateOptions>[] {
    // Check if content hosting is supported for org and renderMode
    this.checkForContentHostingSupport();

    return this.renderer.render({
      state: () => this.viewModel,
      inputContext: this.inputContext,
      templates: this.templates,
    });
  }

  /** Override */
  protected onContentUploadSuccess(
    formControl: FormControl,
    response: HttpEvent<CompleteAzureUploadResult>
  ) {
    const details = this.getHostedContentDetails(response);
    if (!details) {
      throw new DgError(
        this.translate.instant('dgFileUploadButton_UploadErrorMessage')
      );
    }
    super.onContentUploadSuccess(formControl, response);
    this.setCustomFieldValue(formControl, details);
    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      submitButtonText: this.submitButtonText,
    };
    this.updateUIConfiguration(); // force a transition to the full form instead of waiting for Next button

    this.tracker.trackEventData({
      action: 'File Uploaded',
      properties: {
        ActionLocation: 'Manage Org File Upload',
        contentType: 'Article',
        contentId: this.viewModel.inputId,
        fileType: details.fileType,
        filesize: details.fileSize,
      },
    });
  }

  private getHostedContentDetails(
    uploadResponse: HttpEvent<CompleteAzureUploadResult>
  ): HostedContentMetadata {
    const response = uploadResponse as any;

    if (!response.azureUploadFileResult) {
      return undefined;
    }

    const { name, id, size } = response.azureUploadFileResult;
    const fileType = response.azureUploadFileResult.type;
    const fileName = name;
    const hostType = response.azureUploadFileResult.hostType;

    const details: HostedContentMetadata = {
      fileId: id,
      fileName,
      fileSize: size,
      fileType,
      hostType,
    };

    return details;
  }
}
