import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-recommendation-view-modal',
  templateUrl: './tag-recommendation-view-modal.component.html',
})
export class TagRecommendationViewModalComponent implements OnInit {
  @Input() public tags;
  @Input() public recommender;
  @Input() public trackingLocation?: string;
  public authUserUrl = this.authService.authUser.viewerProfile.profileUrl;
  public i18n = this.translate.instant([
    'Core_Close',
    'Core_Follow',
    'Core_UnFollow',
    'dgTagRating_AddToProfile',
    'dgTagRating_RemoveFromProfile',
    'recommendationForm_HasRecommendedSkills',
  ]);
  public selectedTags = new Set<string>();

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private tagsService: TagsService
  ) {}
  public get numSelected(): number {
    return this.selectedTags.size;
  }

  public ngOnInit() {
    this.updateSelectedTags();
  }

  public toggleAdd(tag) {
    let action;
    if (tag.reference.isFollowing) {
      action = 'Recommended Skill Removed From Profile';
      tag.reference.isFollowing = false;
      this.tagsService.removeUserTag(tag.reference).subscribe();
    } else {
      action = 'Recommended Skill Added To Profile';
      tag.reference.isFollowing = true;
      this.tagsService.addUserTag(tag.reference).subscribe();
    }
    this.updateSelectedTags();

    this.tracker.trackEventData({
      action: action,
      properties: {
        TagId: tag.reference.tagId,
        TagName: tag.reference.name,
        Location: 'View & Add Recommended Skills Modal',
      },
    });
  }
  /**
   * Close the modal, not saving.
   */
  public handleClose(): void {
    this.activeModal.dismiss();
  }

  public goToProfile() {
    let url: string;
    url = `/profile/${this.authUserUrl}/skills`;
    window.location.href = url;
    this.handleClose();
  }

  private updateSelectedTags(): void {
    if (!this.tags?.length) {
      return;
    }
    this.selectedTags.clear();

    for (const tag of this.tags) {
      if (tag.reference.isFollowing) {
        this.selectedTags.add(tag.reference.tagId);
      }
    }
  }
}
