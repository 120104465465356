import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupItem, GroupPrivacy } from '@app/groups/group-api';
import { ModalService } from '@app/shared/services/modal.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { camelCaseKeys } from '@app/shared/utils/property';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { AuthService } from '@app/shared/services/auth.service';
import { AuthUser } from '@app/account/account-api.model';
import { getPrivacyOptions } from '@app/pathways/rsm/utils';
import {
  PathwayFacade,
  PathwayDetailsModel,
  PathwayPermissionsModel,
  PathwayPrivacyOption,
} from '@dg/pathways-rsm';

export interface PathwayVisibilityInfo {
  pathwayTitle: string;
  pathwayId: number;
  pathwayOrgId: number;
  userBelongsToOrg: boolean;
  privacyLevel: Visibility;
  inviteUrl: string;
  groups: GroupItem[];
  authorProfileKeys: number[];
  canAuthorPathways: boolean;
  canManagePathways: boolean;
  canShareAuthor: boolean;
  canViewEnrolleesTab: boolean;
  canViewInsightsTab: boolean;
  canViewPathwayReports: boolean;
  userKey: number;
}

@Component({
  selector: 'dgx-pathway-visibility',
  templateUrl: './pathway-visibility.component.html',
  animations: [DF_COLLAPSE_EXPAND],
})
export class PathwayVisibilityComponent implements OnInit {
  private authUser: AuthUser;
  public pathwayVisibilityInfo: PathwayVisibilityInfo;
  public groups = [];
  public pathwayPrivacyLevel: Visibility;
  public privacyOptions: PathwayPrivacyOption[] = [];
  public limitOrgPublish: boolean = false;
  public isSubmitting: boolean = false;
  public groupTagsErrorMsg: string = '';

  public i18n = this.translate.instant([
    'PathwayPrivacyModal_SaveVisibility',
    'PathwayPrivacyModal_FoundWhere',
    'PathwayPrivacyModal_TargetGroups',
    'PathwayPrivacyModal_TargetGroupsToolTip',
    'PathwayPrivacyModal_ChangeVisibility',
    'orgInternalContentVisibilitySelector_TargetGroupsLabel',
  ]);

  constructor(
    private translate: TranslateService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private facade: PathwayFacade
  ) {}

  /**
   * Hide admin groups
   */
  public get excludedGroupPrivacyLevels(): GroupPrivacy[] {
    return ['Administrative'];
  }

  public get groupNames() {
    let groupNames: string[] = [];
    if (this.pathwayVisibilityInfo.groups) {
      groupNames = this.pathwayVisibilityInfo.groups.map((group) => {
        return group.name;
      });
    }
    return groupNames;
  }

  public get groupIds() {
    let groupIds: number[] = [];
    if (this.pathwayVisibilityInfo.groups) {
      groupIds = this.pathwayVisibilityInfo.groups.map((group) => {
        return group.id;
      });
    }
    return groupIds;
  }

  private get userIsAuthor() {
    const authorKeys = this.pathwayVisibilityInfo.authorProfileKeys;
    if (!authorKeys || authorKeys.length === 0) {
      return false;
    }
    let isAuthor = false;
    // check if user is a listed author or just has manage permissions
    const userKey = this.pathwayVisibilityInfo.userKey;
    for (const authorKey of authorKeys) {
      if (authorKey === userKey) {
        isAuthor = true;
        break;
      }
    }
    return isAuthor;
  }

  public ngOnInit(): void {
    const pathway: PathwayDetailsModel = this.facade.snapshot.pathway;
    const permissions: PathwayPermissionsModel =
      this.facade.snapshot.permissions;
    this.authUser = this.authService.authUser;
    this.pathwayVisibilityInfo = {
      pathwayTitle: pathway.title,
      pathwayId: pathway.id,
      pathwayOrgId: pathway.organizationId,
      privacyLevel: pathway.privacyLevel,
      inviteUrl: pathway.inviteUrl,
      groups: pathway.groupIds,
      authorProfileKeys: pathway.authorProfileKeys,
      userBelongsToOrg:
        this.authUser.orgInfo && this.authUser.orgInfo.length > 0,
      canAuthorPathways:
        this.authUser.defaultOrgInfo?.permissions.authorPathways,
      canManagePathways:
        this.authUser.defaultOrgInfo?.permissions.managePathways,
      canShareAuthor: pathway.shareAuthorPermission,
      canViewEnrolleesTab: permissions.canViewEnrolleesTab,
      canViewInsightsTab: permissions.canViewInsightsTab,
      canViewPathwayReports: permissions.canViewPathwayReports,
      userKey: this.authUser.viewerProfile.userProfileKey,
    };
    this.groups = this.pathwayVisibilityInfo.groups.map((group) => {
      return { ...group, groupId: group.id };
    });
    this.i18n.PathwayPrivacyModal_ChangeVisibility = this.translate.instant(
      'PathwayPrivacyModal_ChangeVisibility',
      { title: this.pathwayVisibilityInfo.pathwayTitle }
    );
    this.pathwayPrivacyLevel = this.pathwayVisibilityInfo.privacyLevel;
    this.pathwayVisibilityInfo.groups =
      camelCaseKeys(this.pathwayVisibilityInfo.groups) || [];
    this.privacyOptions = this.initPrivacyOptions();
  }

  public handleGroupsAdd = ({ model }) => {
    const groupId = model.GroupId ?? model.groupId;
    const groupName = model.Name ?? model.name;
    this.pathwayVisibilityInfo.groups.push({
      id: groupId,
      name: groupName,
      isRestricted: this.pathwayPrivacyLevel === Visibility.groups,
    });
    this.groupTagsErrorMsg = '';
  };

  public handleGroupsRemove = ({ group }) => {
    const groupName = group.Name ?? group.name;
    this.pathwayVisibilityInfo.groups =
      this.pathwayVisibilityInfo.groups.filter(
        (visGroup) => groupName !== visGroup.name
      );
    this.groupTagsErrorMsg = '';
  };

  public checkAndSave = ($event?: any) => {
    let isValid =
      this.pathwayPrivacyLevel !== undefined &&
      this.pathwayPrivacyLevel !== null;
    const isOrgVisibilityLevel =
      this.pathwayPrivacyLevel === Visibility.public ||
      this.pathwayPrivacyLevel === Visibility.groups;

    if (isOrgVisibilityLevel) {
      isValid = isValid && this.validateGroups();
    }

    if (isValid) {
      if (!isOrgVisibilityLevel && !this.userIsAuthor) {
        this.confirm();
      } else {
        this.saveAndClose(
          isOrgVisibilityLevel ? this.pathwayVisibilityInfo.groups : []
        );
      }
    }
  };

  private initPrivacyOptions() {
    const onlyAuthor: boolean =
      this.pathwayVisibilityInfo.authorProfileKeys &&
      this.pathwayVisibilityInfo.authorProfileKeys.length === 1;
    const pathwayIsGroupOrgVisible: boolean =
      this.pathwayPrivacyLevel === Visibility.public ||
      this.pathwayPrivacyLevel === Visibility.groups;
    const showOrgPublishOptions: boolean =
      this.pathwayVisibilityInfo.canAuthorPathways ||
      this.pathwayVisibilityInfo.canManagePathways ||
      this.pathwayVisibilityInfo.canShareAuthor ||
      pathwayIsGroupOrgVisible;

    this.limitOrgPublish =
      pathwayIsGroupOrgVisible &&
      !(
        this.pathwayVisibilityInfo.canAuthorPathways ||
        this.pathwayVisibilityInfo.canManagePathways ||
        this.pathwayVisibilityInfo.canShareAuthor
      );

    return getPrivacyOptions(
      this.pathwayVisibilityInfo.userBelongsToOrg,
      this.translate,
      this.pathwayPrivacyLevel,
      onlyAuthor,
      showOrgPublishOptions,
      this.limitOrgPublish
    );
  }

  private validateGroups = () => {
    const groups = this.pathwayVisibilityInfo.groups;
    const isValid =
      (groups && groups.length > 0) ||
      this.pathwayPrivacyLevel !== Visibility.groups;
    this.groupTagsErrorMsg = isValid
      ? ''
      : this.translate.instant('PathwayPrivacyModal_SelectAGroupError');
    return isValid;
  };

  private confirm = () => {
    const inputs = {
      headerText: this.translate.instant('Core_AreYouSure'),
      bodyText: this.translate.instant(
        'PathwayPrivacyModal_ConfirmationModalBody'
      ),
      canCancel: true,
      submitButtonType: 'destructive',
      submitButtonText: this.translate.instant('Core_YesSure'),
    };
    return this.modalService
      .show(SimpleModalComponent, {
        inputs,
        errorOnDismiss: true,
      })
      .subscribe({
        next: () => {
          this.saveAndClose([]);
        },
        error: () => {
          this.isSubmitting = false;
        },
      });
  };

  private saveAndClose(groups: GroupItem[]) {
    const result = { privacyLevel: this.pathwayPrivacyLevel, groups };
    this.activeModal.close(result);
  }
}
