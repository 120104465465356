import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { getCaretCoordinates } from '@app/comments/mentions/utils';
import { MentionUserMatch } from '@app/comments/comments.model';
import { TranslateModule } from '@ngx-translate/core';
import { MentionListItemComponent } from '../mention-list-item/mention-list-item.component';

export const MENTION_BASE_CLASS = 'mention-list__item';

@Component({
  selector: 'dgx-mention-list',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgTemplateOutlet,
    MentionListItemComponent,
    TranslateModule,
  ],
  templateUrl: './mention-list.component.html',
  styleUrl: './mention-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionListComponent {
  @Output() public mentionClicked: EventEmitter<number> = new EventEmitter();

  @ViewChild('container', { static: true })
  public container!: ElementRef<HTMLDivElement>;

  public activeIndex = 0;
  public isHidden = true;
  public items: MentionUserMatch[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  public activateNextItem(): void {
    const nextActiveIndex = this.activeIndex + 1;

    if (!this.items[nextActiveIndex]) {
      return;
    }

    this.highlightOption(nextActiveIndex);
  }

  public activatePreviousItem(): void {
    const nextActiveIndex = this.activeIndex - 1;

    if (!this.items[nextActiveIndex]) {
      return;
    }

    this.highlightOption(nextActiveIndex);
  }

  public getItemId(index: number) {
    return `${MENTION_BASE_CLASS}-${index}`;
  }

  /**
   * Call to clear the list of results and hide the list.
   */
  public clearList(): void {
    this.updateList([], true, -1);
    this.setActiveDescendant(undefined);
  }

  /**
   * Call to initialize the list, including positioning the element.
   *
   * @param items
   * @param parentElement
   */
  public initializeList(
    items: MentionUserMatch[],
    parentElement: HTMLTextAreaElement
  ): void {
    this.updateList(items, false, 0);
    this.position(parentElement);
  }

  /**
   * Call to update the list, value of hidden, and activeIndex, if
   * passed in.
   *
   * @param items
   * @param hidden
   * @param activeIndex - optional.
   */
  public updateList(
    items: MentionUserMatch[],
    hidden = false,
    activeIndex = 0
  ): void {
    this.activeIndex = activeIndex;
    this.items = items;
    this.isHidden = hidden;
    this.cdr.markForCheck();
  }

  /**
   * Emit clicks/selections on our list.
   *
   * @param index
   */
  public onMentionClick(index: number): void {
    if (index === -1) {
      return;
    }
    this.activeIndex = index;
    this.mentionClicked.emit(index);
    this.clearList();
  }

  public trackBy(_: number, item: MentionUserMatch): number {
    return item.userProfileKey;
  }

  private highlightOption(index: number): void {
    this.activeIndex = index;
    const elementId = this.getItemId(index);

    this.setActiveDescendant(elementId);
    this.cdr.markForCheck();
  }

  /**
   * Position the drop-down beside the cursor.
   *
   * @param parentElement
   */
  private position(parentElement: HTMLTextAreaElement): void {
    const nativeElement = this.elementRef.nativeElement;

    const coords = getCaretCoordinates(
      parentElement,
      parentElement.selectionStart
    );
    coords.top = parentElement.offsetTop + coords.top - parentElement.scrollTop;
    coords.left =
      parentElement.offsetLeft + coords.left - parentElement.scrollLeft;

    const offsetTop = parseFloat(
      window.getComputedStyle(parentElement).lineHeight
    );

    const top = coords.top + offsetTop;

    this.renderer.setStyle(nativeElement, 'position', 'absolute');
    this.renderer.setStyle(nativeElement, 'left', `${coords.left}px`);
    this.renderer.setStyle(nativeElement, 'top', `${top}px`);
    this.cdr.markForCheck();
  }

  /**
   * Announce the 'focused' element in the list of options
   *
   * NOTE: On Safari with VoiceOver, if active descendant is not unset when focus leaves, the listbox popup is not
   * recognized when the trigger is re-focused
   *
   * https://www.w3.org/TR/wai-aria-practices/#kbd_focus_activedescendant
   */
  private setActiveDescendant(elementId?: string) {
    if (elementId) {
      this.renderer.setAttribute(
        this.container.nativeElement,
        'aria-activedescendant',
        elementId
      );
    } else {
      this.renderer.removeAttribute(
        this.container.nativeElement,
        'aria-activedescendant'
      );
    }
  }
}
