import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@dg/shared-services';
import { Observable, map } from 'rxjs';
import {
  PagedResponse,
  BusinessRule,
  LoadOptions,
  EmailTemplate,
  AutomationFindModel,
  EngageEvent,
  BusinessRuleType,
  Attribute,
  Action,
  ActiveType,
} from '../model';
import { catchAndSurfaceError } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class AutomationsApiService extends ApiServiceBase {
  private i18n = this.translate.instant(['Core_Error']);

  constructor(
    http: NgxHttpClient,

    private translate: TranslateService
  ) {
    super(http, translate.instant('OrgSvc_OrgError'));
  }

  public loadAutomations(
    options: LoadOptions,
    businessRuleTypes: BusinessRuleType[]
  ): Observable<PagedResponse<BusinessRule[]>> {
    const params = {
      businessRuleType: businessRuleTypes.join(','),
      page: options.page,
      pageSize: options.pageSize,
      sort: options.sort,
      direction: this.getSortDirection(options.isDescending),
      term: options.term,
      getAll: options.getAll,
    };

    options?.facets?.forEach((facet) => {
      params[facet.id] = facet.values;
    });

    return this.http
      .get<PagedResponse<BusinessRule[]>>(
        `/organizations/${options.orgId}/automationsv2/rules`,
        {
          params,
        }
      )
      .pipe(
        map((response) => {
          response.payload.map(this.prepareRuleForUI);
          return response;
        }),
        catchAndSurfaceError(this.i18n.Core_Error)
      );
  }

  public loadAutomation(
    orgId: number,
    id: string
  ): Observable<PagedResponse<BusinessRule>> {
    return this.http
      .get<
        PagedResponse<BusinessRule>
      >(`/organizations/${orgId}/automationsv2/rules/${id}`)
      .pipe(
        map((response) => {
          this.prepareRuleForUI(response.payload);
          return response;
        }),
        catchAndSurfaceError(this.i18n.Core_Error)
      );
  }

  public updateAutomation(
    orgId: number,
    id: string,
    businessRule: Partial<BusinessRule>,
    options?: LoadOptions
  ): Observable<PagedResponse<BusinessRule>> {
    this.prepareRuleForSave(businessRule);

    return this.http
      .put<PagedResponse<BusinessRule>>(
        `/organizations/${orgId}/automationsv2/rules/${id}`,
        {
          ...businessRule,
          page: options?.page,
          pageSize: options?.pageSize,
          sort: options?.sort,
          direction: this.getSortDirection(options?.isDescending),
        }
      )
      .pipe(
        map((response) => {
          this.prepareRuleForUI(response.payload);
          return response;
        }),
        catchAndSurfaceError(this.i18n.Core_Error)
      );
  }

  public createAutomation(
    orgId: number,
    businessRule: Partial<BusinessRule>,
    options?: LoadOptions
  ): Observable<PagedResponse<BusinessRule>> {
    this.prepareRuleForSave(businessRule);

    return this.http
      .post<PagedResponse<BusinessRule>>(
        `/organizations/${orgId}/automationsv2/rules`,
        {
          ...businessRule,
          page: options?.page,
          pageSize: options?.pageSize,
          sort: options?.sort,
          direction: this.getSortDirection(options?.isDescending),
        }
      )
      .pipe(
        map((response) => {
          this.prepareRuleForUI(response.payload);
          return response;
        }),
        catchAndSurfaceError(this.i18n.Core_Error)
      );
  }

  public deleteAutomation(orgId: number, id: string): Observable<boolean> {
    return this.http.delete(
      `/organizations/${orgId}/automationsv2/rules/${id}`
    );
  }

  public deleteAutomations(
    orgId: number,
    ids: { id: string }[]
  ): Observable<boolean> {
    return this.http.post(
      `/organizations/${orgId}/automationsv2/rules/bulk`,
      ids
    );
  }
  public bulkEnableAutomations(
    orgId: number,
    ids: string[],
    enable: boolean
  ): Observable<PagedResponse<BusinessRule>> {
    return this.http.post(
      `/organizations/${orgId}/automationsv2/rules/activeTypes/bulk`,
      {
        activeType: enable ? ActiveType.Enabled : ActiveType.Disabled,
        ids,
      }
    );
  }

  public getEmailTemplate(
    orgId: number,
    id: string
  ): Observable<PagedResponse<EmailTemplate>> {
    return this.http.get(
      `/organizations/${orgId}/automationsv2/emailtemplates/${id}`
    );
  }

  public getEvents(orgId: number): Observable<{ payload: EngageEvent[] }> {
    return this.http.get(`/organizations/${orgId}/automationsv2/events`);
  }

  public getResourcesByResourceType(
    orgId: number,
    resourceType: string,
    inputType: string,
    term: string,
    skip?: number,
    take?: number,
    orderBy?: string,
    getAll?: boolean
  ) {
    return this.http.get<AutomationFindModel>(
      `/organizations/${orgId}/automationsv2/resources/${resourceType}`,
      {
        params: {
          term,
          skip,
          take,
          orderBy,
          getAll,
          inputType,
        },
      }
    );
  }

  public getActionOptions(
    orgId: number,
    businessRuleType?: BusinessRuleType
  ): Observable<{
    pagination: any;
    payload: Action[];
  }> {
    return this.http
      .get<{ pagination: any; payload: Action[] }>(
        `/organizations/${orgId}/automationsv2/actions`,
        {
          params: { businessRuleType },
        }
      )
      .pipe(catchAndSurfaceError(this.i18n.Core_Error));
  }

  public setActiveType(
    orgId: number,
    status: ActiveType,
    id: string
  ): Observable<PagedResponse<BusinessRule>> {
    return this.http
      .put<PagedResponse<BusinessRule>>(
        `/organizations/${orgId}/automationsv2/rules/${id}/activeType`,
        {
          activeType: status,
        }
      )
      .pipe(
        map((response) => {
          this.prepareRuleForUI(response.payload);
          return response;
        }),
        catchAndSurfaceError(this.i18n.Core_Error)
      );
  }

  public getAttributes(
    orgId: number,
    businessRuleType: 'BusinessRule' | 'Segment' = 'BusinessRule'
  ): Observable<{ payload: Attribute[] }> {
    return this.http
      .get(`/organizations/${orgId}/automationsv2/attributes`, {
        params: { businessRuleType },
      })
      .pipe(catchAndSurfaceError(this.i18n.Core_Error));
  }

  public loadAutomationHistory(orgId: number, automationId: number) {
    return this.http.get<any>(
      `/organizations/${orgId}/automationsv2/rules/${automationId}/logs`
    );
  }

  public getHistorySyncDate(orgId: number): Observable<{ payload: string }> {
    return this.http.get(
      `/organizations/${orgId}/automationsv2/logs/sync-date`
    );
  }

  public validateName(ruleName, orgId, ruleType): Observable<boolean> {
    return this.http.get<boolean>(
      `/organizations/${orgId}/automationsv2/checkRuleNameExists`,
      {
        params: { ruleName, ruleType },
      }
    );
  }

  public getPredicateTypes(orgId: number) {
    return this.http.get<{ payload: string[] }>(
      `/organizations/${orgId}/automationsv2/condition-types`
    );
  }

  private getSortDirection(isDescending: boolean) {
    return isDescending ? 'desc' : 'asc';
  }

  private prepareRuleForUI(rule: BusinessRule): BusinessRule {
    const removeUTCFromRule = (r) => {
      const specifiedDate = r.trigger?.schedule?.specifiedDate?.toString();
      if (specifiedDate?.endsWith('Z')) {
        r.trigger.schedule.specifiedDate = new Date( // remove timezone offset as it doesn't apply to the specified date
          specifiedDate.split('Z')[0]
        );
      }
    };

    removeUTCFromRule(rule);
    rule.predicates?.forEach((p) => {
      if (p.comparisonValue) {
        p.comparisonValue = JSON.parse(p.comparisonValue as unknown as string);
      }
      if (p.userAcademyStatus) {
        p.userAcademyStatus = JSON.parse(
          p.userAcademyStatus as unknown as string
        );
      }
    });
    return rule;
  }

  private prepareRuleForSave(rule: Partial<BusinessRule>) {
    const formatSpecifiedDate = (date: Date) => {
      const day = date.getDate();
      const month =
        date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    if (rule.trigger?.schedule?.specifiedDate) {
      rule.trigger.schedule.specifiedDate = formatSpecifiedDate(
        rule.trigger.schedule.specifiedDate
      ) as any;
    }

    rule.predicates?.forEach((p) => {
      if (p.comparisonValue) {
        p.comparisonValue = JSON.stringify(p.comparisonValue) as any;
      }

      if (p.userAcademyStatus) {
        p.userAcademyStatus = JSON.stringify(p.userAcademyStatus) as any;
      }
    });
  }
}
