import { Component, Input } from '@angular/core';
import { SimpleItemViewModel } from '@app/shared/models/core-view.model';

/** A general purpose list item component with a customizable right icon and optional left button, title, and optional subtitle */
@Component({
  selector: 'dgx-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() public endorsedSrc: string;
  @Input() public item: SimpleItemViewModel;
  @Input() public alignTop: boolean;

  public onClick(ev: Event) {
    if (this.item.defaultAction) {
      this.item.defaultAction(ev);
    }
  }
}
