import { Injectable } from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { QueueService } from '@app/shared/services/queue.service';
import { TagModalService } from '@app/tags/services/tag-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InputDetailsService {
  constructor(
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private queueService: QueueService,
    private recommendationsModalService: RecommendationsModalService,
    private tagModalService: TagModalService
  ) {}

  public showShareModel(
    resource: LearningResourceViewModel,
    returnFocusEl: HTMLElement
  ) {
    return (
      this.recommendationsModalService
        // linter error -  Argument of type 'ProviderLearningResource' is not assignable to parameter of type 'RecommendingItem'.
        .showShareModal(resource.model as any, returnFocusEl)
        .subscribe()
    );
  }

  public removeFromMyQueue(
    resource: LearningResourceViewModel,
    element?: HTMLElement
  ): Observable<void> {
    return this.queueService.deleteItem(
      resource.queueItemId,
      resource.resourceType,
      resource.resourceId,
      null,
      null,
      element
    );
  }

  public addToMyQueue(
    resource: LearningResourceViewModel,
    element?: HTMLElement
  ): Observable<number> {
    return this.queueService
      .post(
        resource.resourceType,
        resource.resourceId,
        null,
        resource,
        'More Details',
        element
      )
      .pipe(
        map((response) => {
          this.notifierService.showSuccess(
            this.translateService.instant('Core_AddedToQueueNotifierFormat', {
              itemTitle: resource.title,
            })
          );
          return response.data;
        })
      );
  }

  public tagResource(resource: LearningResourceViewModel, event: MouseEvent) {
    return this.tagModalService.openAddTagsToContentItemModal(
      event.target as HTMLElement,
      {
        ...resource,
        inputType: resource.resourceType,
        inputId: resource.resourceId,
        userInputId: resource.completionInfo.userInputId,
      }
    );
  }
}
