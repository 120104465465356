<div>
  <!--
    NOTE: The "Read More" button cannot be displayed inline because it would interfere with ellpsis and height calculations in dgxLineClampHtml
  -->
  <div
    *ngIf="collapse && !!content"
    [dgxLineClampHtml]="content"
    [clampLinesMax]="currentVisibleLines"
    (clamped)="isContentTrimmed($event)"
    class="break"
  ></div>
  <div *ngIf="!collapse" class="break" [innerHTML]="content"></div>
  <button
    *ngIf="showReadMore"
    [class.block]="isExpanded"
    (click)="toggleExpanded()"
    class="link"
    type="button"
    data-dgat="text-expander-318"
    aria-hidden="true"
  >
    {{ (isExpanded ? 'Core_ShowLess' : 'Core_ReadMore') | translate }}
  </button>
</div>
