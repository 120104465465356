import { Component } from '@angular/core';

@Component({
  selector: 'dgx-settings-family-empty',
  templateUrl: './settings-family-empty.component.html',
  styleUrls: ['./settings-family-empty.component.scss'],
})
export class SettingsFamilyEmptyComponent {
  public readonly imgSrc1x = '/content/img/illustrations/hurdle@1x.png';
  public readonly imgSrc2x = '/content/img/illustrations/hurdle@2x.png';
}
