import { Injectable } from '@angular/core';
import { CatalogSearchQueryOpts } from '@app/content-catalog/components/catalog-search-query-opts';
import { ContentCatalogService } from '@app/content-catalog/services/content-catalog.service';
import { InputDetails, InputIdentifier } from '@app/inputs/inputs-api.model';
import { OrgPathwaysService } from '@app/orgs/services/org-pathways.service';
import { PathwayManageModel } from '@app/pathways/pathway-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { TargetsService } from '@app/shared/services/targets.service';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { OrgTargetVM } from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface BundleResourceReference {
  referenceId: number;
  referenceType: string;
}

export interface BundleResourceSearchConfig {
  orgId?: number;
  skip?: number;
  count?: number;
  sortColumnIndex?: string; // Maps to 'orderBy' in `TargetsService.getOrgTargets()`
  isDescending?: boolean;
  targetTypes?: string;
}

// default options for search query
const defaultSearchQueryOpts: CatalogSearchQueryOpts = {
  terms: '',
  tags: [],
  facets: [
    {
      id: 'Internal',
      values: [1],
    },
  ],
  skip: 0,
  count: 20,
  includeProviders: false,
  defaults: 'CMS',
  includeCompleted: true,
  sortBy: 'DateUpdated',
  isAscending: true,
  exclusionList: [],
};

@Injectable({
  providedIn: 'root',
})
export class BundleResourceService {
  public readonly search = {
    Content: this.searchContent.bind(this),
    Target: this.searchTargets.bind(this),
    Pathway: this.searchPathways.bind(this),
  };

  constructor(
    private contentCatalogService: ContentCatalogService,
    private displayTypePipe: DisplayTypePipe,
    private orgPathwaysService: OrgPathwaysService,
    private targetsService: TargetsService,
    private translate: TranslateService
  ) {}

  /**
   * Merge an existing set of Bundle Resources, and pending set of selected resources to build a normalized "Exclusion List"
   */
  public getExclusionList({
    resourceList,
    selectedSearchResults,
  }: {
    resourceList: (
      | BundleResourceReference
      | Pick<PathwayManageModel, 'resourceId' | 'resourceType'>
      | Pick<OrgTargetVM, 'targetId' | 'resourceType'>
    )[];
    selectedSearchResults: BundleResourceReference[];
  }): InputIdentifier[] {
    const pathwayType = 'Pathway';

    const exclusionList = resourceList.map((resource: any) => ({
      inputType: resource.referenceType || resource.resourceType || pathwayType,
      inputId:
        resource.referenceId ||
        resource.targetId ||
        resource.resourceId ||
        resource.id,
    }));

    selectedSearchResults.forEach((result: any) => {
      // Don't duplicate items in the exclusion list
      if (exclusionList.some((e) => e.inputId === result.referenceId)) {
        return;
      }

      exclusionList.push({
        inputType: result.referenceType || result.resourceType || pathwayType,
        inputId:
          result.referenceId ||
          result.targetId ||
          result.resourceId ||
          result.id,
      });
    });

    return exclusionList as InputIdentifier[];
  }

  /**
   * Search for Content
   */
  public searchContent({
    value,
    exclusionList,
    orgId,
  }: {
    value: string;
    exclusionList: InputIdentifier[];
    orgId: number;
  }): Observable<
    (InputDetails & { referenceType: string; _DisplayType: string })[]
  > {
    const searchOpts: CatalogSearchQueryOpts = {
      ...defaultSearchQueryOpts,
      terms: value,
      facets: [
        {
          id: 'Internal',
          values: [orgId],
        },
      ],
      exclusionList,
    };

    return this.contentCatalogService.searchCatalog(searchOpts).pipe(
      map((response) => {
        const searchResults = response.inputs;
        const formattedSearchResults = searchResults.map((result) => {
          const formatted = {
            ...result,
            referenceType: result.resourceType,
          };

          return this.withCardActionOptionsDisplayType(
            formatted,
            result.resourceType
          );
        });
        return formattedSearchResults;
      }),
      // TODO: more specific error
      catchAndSurfaceError(this.translate.instant('Core_Error'))
    );
  }

  /**
   * Searches for "Targets" based on the value provided in `config.targetTypes` (defaults to: `Browse`, `Directory`, `Role`, and `Target`)
   */
  public searchTargets({
    value,
    exclusionList,
    orgId,
    config,
  }: {
    value: string;
    exclusionList: InputIdentifier[];
    orgId: number;
    config: BundleResourceSearchConfig;
  }): Observable<
    (OrgTargetVM & {
      title: string;
      referenceId: number;
      referenceType: string;
      _DisplayType: string;
    })[]
  > {
    return this.targetsService
      .getOrgTargets(
        value,
        config.skip,
        config.count,
        config.sortColumnIndex,
        config.isDescending,
        orgId,
        config.targetTypes
      )
      .pipe(
        map((response) => {
          const searchResults = response.results;

          // NOTE: TargetsService.getOrgTargets does not have param to specify items to 'exclude',
          // so manually filtering the response here
          const exclusionListIds = exclusionList.map((e) => e.inputId);
          const filteredResults = searchResults.filter((result) => {
            return !exclusionListIds.includes(result.targetId);
          });
          const formattedSearchResults = filteredResults.map((result) => {
            const formatted = {
              ...result,
              title: result.name,
              referenceId: result.targetId,
              referenceType: result.targetType,
            };

            return this.withCardActionOptionsDisplayType(
              formatted,
              result.targetType
            );
          });

          return formattedSearchResults;
        }),
        // TODO: more specific error
        catchAndSurfaceError(this.translate.instant('Core_Error'))
      );
  }

  public searchPathways({
    value,
    exclusionList,
    orgId,
  }: {
    value: string;
    exclusionList: InputIdentifier[];
    orgId: number;
  }): Observable<
    (PathwayManageModel & {
      referenceId: number;
      referenceType: string;
      _DisplayType: string;
    })[]
  > {
    return this.orgPathwaysService
      .getContainerPathways(value, 0, 10, undefined, false, orgId)
      .pipe(
        map((response) => {
          const searchResults = response.pathways;

          // NOTE: OrgPathwaysService.getContainerPathways does not have param to specify items to 'exclude',
          // so manually filtering the response here
          const exclusionListIds = exclusionList.map((e) => e.inputId);
          const filteredSearchResults = searchResults.filter((path) => {
            // filter search results so that this pathway
            // does not match anything in the exclusion list
            return !exclusionListIds.includes(path.id);
          });

          const formattedSearchResults = filteredSearchResults.map((path) => {
            const formatted = {
              ...path,
              referenceId: path.resourceId,
              referenceType: path.resourceType,
            };

            return this.withCardActionOptionsDisplayType(
              formatted,
              path.resourceType
            );
          });
          return formattedSearchResults;
        }),
        // TODO: more specific error
        catchAndSurfaceError(this.translate.instant('Core_Error'))
      );
  }

  /**
   * NOTE: the special `_DisplayType` key is needed to pass along to `dgCardActionOptions`
   */
  private withCardActionOptionsDisplayType<T>(
    obj: T,
    type: InputType | string
  ): T & { _DisplayType: string } {
    return {
      ...obj,
      _DisplayType: this.displayTypePipe.transform(type, false),
    };
  }
}
