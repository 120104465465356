import { getDeepCopy } from '../property';
import { orderBy } from './order-by';
import { search } from './search';

/**
 * Return an array of items, filtered by a given searchKey/Term pair, and then
 * sorted by our own orderBy method. **Does not mutate the original array.**
 *
 * @param items - The items to sort. Of type T.
 * @param searchKey - The property in the array to search.
 * @param searchTerm - The term to search the property for.
 * @param keys - The sort key, or array of keys.
 * @param orders - Ascending or descending. Defaults to ascending.
 *
 * @example
 * ```
 * users = searchAndOrderBy<User>(users, 'user', 'fred', ['user', 'age'], 'desc')
 * // [{ user: 'fred', age: 48 }, { user: 'fred', age: 40 }]
 * ```
 */
export function searchAndOrderBy<T>(
  items: T[],
  searchKey: string,
  searchTerm: number | string = '',
  keys: string | string[],
  orders?: string | string[]
): T[] {
  const localItems = getDeepCopy(items);
  // if the search term is undefined, or an empty string, then obviously we return true
  if (searchTerm === '' || searchTerm == undefined) {
    return localItems.sort((a: T, b: T) => orderBy(a, b, keys, orders));
  }
  // otherwise, filter *and* sort
  return search<T>(localItems, searchKey, searchTerm).sort((a: T, b: T) =>
    orderBy(a, b, keys, orders)
  );
}
