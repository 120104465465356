import {
  AzureUserAuthoredResult,
  CHUploadService,
} from '@degreed/content-hosting-data-access';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable, EMPTY, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { InputSubmissionResult } from '@app/user-content/services/inputs-facade-base';
import { MediaModalFacadeBase } from '../media-modal/media-modal-facade-base';
import {
  InputContext,
  MediaEntryApiEntity,
  RenderMode,
} from '../user-input.model';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { shortsUrlToFull } from '@app/user-content/utils';
import { TranslateService } from '@ngx-translate/core';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { VideoFormRenderManager } from './renderers/video-form-render.manager';
import { UploaderService } from '@app/uploader/uploader.service';
import { VideoFormModel } from './video-form.model.model';
import { VideoService } from '@app/inputs/services/video.service';
import { FileUploadChunkResult } from '@app/uploader/file-chunk-uploader/file-chunk-uploader.service';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { TipService } from '@app/onboarding/services/tip.service';
import { VideoUploadSettings } from '@app/uploader/uploader-api.model';

/** A stateful facade service that provides Article add/edit modal functionality to the generic Input
 * modal container
 **/
@Injectable({ providedIn: 'any' }) // provide in any so we can use with modal factory's child injector
export class VideoModalFacade extends MediaModalFacadeBase<VideoFormModel> {
  uploadingFile: File;

  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: MediaEntryApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    builder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    commentsApiService: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    @Inject(DOCUMENT) document: Document,
    tipService: TipService,
    contentHostingUploadService: CHUploadService,
    private renderManager: VideoFormRenderManager,
    private uploader: UploaderService,
    private videoService: VideoService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      builder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      commentsApiService,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      document,
      tipService,
      contentHostingUploadService
    );
  }

  /** Override */
  public onSubmit(): Observable<InputSubmissionResult> {
    // must have either a media URL entered or hosted content uploaded before submitting (i.e. advancing to full form) is valid
    if (this.isEditing || this.viewModel.mediaUrl) {
      // If mediaUrl is set, check for YouTube 'shorts' URLS and update.
      if (this.viewModel.mediaUrl) {
        this.viewModel.mediaUrl = shortsUrlToFull(this.viewModel.mediaUrl);
      }
      return super.onSubmit();
    }
    return EMPTY;
  }

  /** Override */
  protected get extendedDefaultViewModel(): Partial<VideoFormModel> {
    const authUser = this.authService.authUser;

    const shouldShowRecordVideoButton =
      authUser.defaultOrgInfo &&
      !authUser.defaultOrgInfo.settings?.disableUserGeneratedContent &&
      authUser.canRecordVideo &&
      this.inputContext.renderMode === RenderMode.ContentCatalog;

    const shouldShowContentUploader =
      authUser.canUploadVideo && !this.isEditing; // initially hide the uploader when editing, until the Reupload button is clicked
    const shouldDisableContentUploader =
      !authUser.canUploadVideo && this.isEditing;

    const videoConstraints$ = this.uploader.getUploadSettings().pipe(
      map((s) => ({
        settings: s,
        dgOrgInternalContentForm_VideoTimeLimit: this.translate.instant(
          'dgOrgInternalContentForm_VideoTimeLimit',
          {
            timeLimit: s.maxVideoLengthMinutes,
          }
        ),
      }))
    );

    const vm: Partial<VideoFormModel> = {
      ...super.extendedDefaultViewModel,
      // Show body submit button initially
      shouldShowSubmitButton$: new BehaviorSubject(
        this.inputContext.renderMode === RenderMode.UserProfile ||
          this.inputContext.renderMode == RenderMode.Pathways
      ),
      shouldShowRecordVideoButton,
      shouldShowContentUploader,
      shouldDisableContentUploader,
      videoConstraints$,
      isPlayableVideo$: this.videoService.isPlayableVideo.bind(
        this.videoService
      ),
    };
    return vm;
  }

  protected getEditViewModel(
    source: Partial<MediaEntryApiEntity> = {}
  ): Observable<VideoFormModel> {
    // Extend the editable view model with the shouldShowReplaceContentButtons state which depends on the media URL
    return super.getEditViewModel(source).pipe(
      map((vm) => {
        const authUser = this.authService.authUser;
        return {
          ...vm,
          shouldShowReplaceContentButtons: !this.isAuthoredContent(vm.mediaUrl)
            ? false
            : this.isUploadedContent(vm.mediaUrl),
          shouldShowContentUploader: this.shouldShowContentUploader(
            vm.mediaUrl
          ),
          shouldDisableContentUploader:
            this.shouldShowContentUploader(vm.mediaUrl) &&
            !authUser.canUploadVideo,
        };
      })
    );
  }

  protected buildUIConfiguration(): DfFormFieldConfig<DfTemplateOptions>[] {
    // when refactoring update the VM from initial screen to expanded.
    // Right now it only updates VM on media uploaded/recorded.
    // This is to update the VM when the user only enters a URL
    //  NOTE this is only when showAzureContentHosting is true
    const hasMedia =
      this.isEditing ||
      this.viewModel.isMediaParsed ||
      this.viewModel.uploadedContentDetails;
    if (hasMedia) {
      const authUser = this.authService.authUser;
      this.viewModel = {
        ...this.viewModel,
        shouldShowReplaceContentButtons: !this.isAuthoredContent(
          this.viewModel.mediaUrl
        )
          ? false
          : this.isUploadedContent(this.viewModel.mediaUrl),
        shouldShowContentUploader: this.shouldShowContentUploader(
          this.viewModel.mediaUrl
        ),
        shouldDisableContentUploader:
          this.shouldShowContentUploader(this.viewModel.mediaUrl) &&
          !authUser.canUploadVideo &&
          this.isEditing,
      };
    }

    this.uploader
      .getUploadSettings()
      .subscribe((response: VideoUploadSettings) => {
        this.viewModel = {
          ...this.viewModel,
          fileRestrictions: response,
        };
      });

    return this.renderManager.render({
      state: () => this.viewModel,
      inputContext: this.inputContext,
      templates: this.templates,
    });
  }

  protected onContentFileChange(file: File) {
    super.onContentFileChange(file);
    this.uploadingFile = file;
  }

  protected onContentUploadFailure() {
    super.onContentUploadFailure();
    this.uploadingFile = undefined;
  }

  /**
   * Handle updating the UI form after uploading a recorded video
   * @param formControl - mediaUrl form control
   * @param recordedFileResult - result returned from uploading a recorded video
   */
  public recordedVideoUploaded(
    formControl: FormControl,
    recordedFileResult: AzureUserAuthoredResult
  ): void {
    const isRecordedVideo = true;
    this.uploadingFile = recordedFileResult.file;
    this.onContentUploadSuccess(
      formControl,
      recordedFileResult.result,
      isRecordedVideo
    );
  }

  /** Override */
  protected onContentUploadSuccess(
    formControl: FormControl,
    result: FileUploadChunkResult,
    isRecordedVideo?: boolean
  ) {
    super.onContentUploadSuccess(formControl, result);
    const hasExistingUploadedContent = this.isUploadedContent(
      this.viewModel.mediaUrl
    );
    this.setCustomFieldValue(formControl, result.fileUrl); // set mediaUrl form value to URL
    const authUser = this.authService.authUser;
    this.viewModel = {
      ...this.viewModel,
      shouldShowReplaceContentButtons: !this.isAuthoredContent(result.fileUrl)
        ? false
        : this.isUploadedContent(result.fileUrl),
      shouldShowContentUploader: this.shouldShowContentUploader(result.fileUrl), // hide content uploader on full page (if editing, re-upload button can be clicked to re-show)
      shouldDisableContentUploader:
        this.shouldShowContentUploader(this.viewModel.mediaUrl) &&
        !authUser.canUploadVideo &&
        this.isEditing,
      isSubmitButtonDisabled: !result.fileUrl,
      submitButtonText: this.submitButtonText,
      // Also set uploadedContentDetails so render manager can load expanded form
      uploadedContentDetails: this.getHostedContentDetails(
        this.uploadingFile,
        result
      ),
    };

    this.uploadingFile = undefined;
    if (!hasExistingUploadedContent) {
      this.updateUIConfiguration(); // if not already on full form, force a transition to the full form instead of waiting for Next button
    }

    this.tracker.trackEventData({
      action: 'File Uploaded',
      properties: {
        ActionLocation: 'Manage Org File Upload',
        contentType: isRecordedVideo ? 'Recorded Video' : 'Video',
        contentId: this.viewModel.inputId,
      },
    });
  }

  private getHostedContentDetails(
    uploadingFile: File,
    fileDetails: FileUploadChunkResult
  ): HostedContentMetadata {
    const originalFileName = uploadingFile.name;

    const fileExtension = originalFileName.substring(
      originalFileName.lastIndexOf('.') + 1,
      originalFileName.length
    );

    const details: HostedContentMetadata = {
      fileId: fileDetails.fileId,
      fileName: originalFileName,
      fileSize: uploadingFile.size,
      fileType: fileExtension,
      hostType: 'DegreedMedia',
      url: fileDetails.fileUrl,
    };
    return details;
  }

  // Only show the content uploader for uploaded files not recorded video files
  // TODO: remove the LD flag once Azure hosting is ready for the general audience
  private shouldShowContentUploader(mediaUrl: string) {
    const authUser = this.authService.authUser;
    return (
      this.isUploadedContent(mediaUrl) &&
      !this.isAuthoredContent(mediaUrl) &&
      (authUser.canUploadVideo || (!authUser.canUploadVideo && this.isEditing))
    );
  }

  private isUploadedContent(mediaUrl: string) {
    return mediaUrl?.indexOf('degreed://') > -1;
  }

  private isAuthoredContent(mediaUrl: string) {
    return mediaUrl?.indexOf('userauthoredvideo') > -1;
  }
}
