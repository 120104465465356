import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

@Component({
  selector: 'dgx-pathway-content-completion-reminder',
  template: `
    <div [innerHTML]="safeContent"></div>
    <div class="guts-p-t-half color-blue">{{ linkText }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayContentCompletionReminderComponent extends NotificationItemComponentBase {
  public linkText = this.itemService.translate.instant(
    'Notification_ReviewItems'
  );

  protected buildTemplate() {
    return this.translateHtml(
      'Notification_PathwayContentCompletionReminder',
      {
        pathwayname: this
          .html`<span class="font-semibold">${this.params.pathwayTitle}</span>`,
      }
      // "You viewed items from the pathway {{pathwayname}} but didn’t mark them complete."
    );
  }
}
