import { Injectable, Inject } from '@angular/core';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { InputType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { DefaultSelectOptionsType } from '@app/user-content/user-input/course-form/course-form.model';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { DgError } from '@app/shared/models/dg-error';
import { InputsService } from '@app/inputs/services/inputs.service';
import { LanguageService } from '@app/translation/services/language.service';
import { AuthService } from '@app/shared/services/auth.service';
import { InputDetails, InputIdentifier } from '@app/inputs/inputs-api.model';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
@Injectable({ providedIn: 'root' })
export class OrgInternalContentService extends ApiServiceBase {
  public readonly inputTypes: DefaultSelectOptionsType[];
  private readonly i18n = this.translate.instant([
    'OrgInternalContentSvc_InternalContentError',
    'OrgInternalContentSvc_ProviderName',
    'OrgInternalContentSvc_Summary',
    'OrgInternalContentSvc_InputType',
    'OrgInternalContentSvc_Tags',
    'OrgInternalContentSvc_Title',
    'Core_Hours',
    'Core_Article',
    'Core_Podcast',
    'Core_Video',
    'Core_Course',
    'Core_Event',
    'Core_Assessment',
    'OrgInternalContentSvc_ItemWTitleDeletedSuccessMessage',
    'OrgInternalContentSvc_ItemsDeletedSuccessMessage',
    'OrgInternalContentSvc_ItemWTitleDeletionError',
    'OrgInternalContentSvc_ItemsUpdateError',
  ]);
  private inputTypeFormats: Record<string, string[]>;

  constructor(
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private authService: AuthService,
    private languageService: LanguageService,
    private inputsService: InputsService,
    private translate: TranslateService,
    http: NgxHttpClient,
    private notifier: NotifierService,
    private trackerService: TrackerService
  ) {
    super(http, '');
    this.inputTypes = [
      { title: this.i18n.Core_Article, type: 'Article' },
      { title: this.i18n.Core_Podcast, type: 'Episode' },
      { title: this.i18n.Core_Video, type: 'Video' },
      { title: this.i18n.Core_Course, type: 'Course' },
      { title: this.i18n.Core_Event, type: 'Event' },
      { title: this.i18n.Core_Assessment, type: 'Assessment' },
    ];
  }

  /** Gets a list of valid Input types  */
  public getInputTypeFormats(inputType: InputType): Observable<string[]> {
    // Lazy init inputTypeFormats, then use it to look up the formats for the specified input type
    return (
      this.inputTypeFormats
        ? of(this.inputTypeFormats)
        : this.get<Record<string, string[]>>(
            '/organizations/contentformats'
          ).pipe(tap((f) => (this.inputTypeFormats = f)))
    ).pipe(map((f) => f[inputType.toLowerCase()] ?? []));
  }

  public reportDuplicateResult(
    organizationId: number,
    duplicates: InputDetails[]
  ): Observable<any> {
    const url = '/organizations/reportduplicateresult';
    return this.http.post(url, {
      orgId: organizationId,
      duplicates: duplicates,
    });
  }

  public setInputsObsolete(
    organizationId: number,
    inputs: InputDetails[],
    shouldDeleteAllReferences: boolean
  ): Observable<any> {
    let title = '';
    if (inputs.length === 1) {
      title = inputs[0].title;
    }
    return this.http
      .post('/organizations/setInputsObsolete', {
        organizationId: organizationId,
        inputs: inputs,
        cascadeDelete: shouldDeleteAllReferences,
      })
      .pipe(
        tap((response: { inputsObsoletedCount: number }) => {
          if (title && response.inputsObsoletedCount !== 0) {
            this.notifier.showSuccess(
              title +
                this.i18n.OrgInternalContentSvc_ItemWTitleDeletedSuccessMessage
            );
            return;
          } else if (title && response.inputsObsoletedCount === 0) {
            this.notifier.showError(
              this.translate.instant(
                'OrgInternalContentSvc_ItemWTitleDeletionError',
                {
                  title: this.encodeToEntitiesPipe.transform(title),
                }
              )
            );
            return;
          }
          this.notifier.showSuccess(
            response.inputsObsoletedCount +
              this.i18n.OrgInternalContentSvc_ItemsDeletedSuccessMessage
          );
        }),
        catchError((error) => {
          if (title) {
            return throwError(
              new DgError(
                this.translate.instant(
                  'OrgInternalContentSvc_ItemWTitleDeletionError',
                  {
                    title: this.encodeToEntitiesPipe.transform(title),
                  }
                ),
                error
              )
            );
          } else {
            return throwError(
              new DgError(
                this.translate.instant(
                  'OrgInternalContentSvc_ItemsDeletionError',
                  {
                    inputsCount: inputs.length,
                  }
                ),
                error
              )
            );
          }
        })
      );
  }

  public updateInputs(
    organizationId: number,
    inputs: InputDetails[]
  ): Observable<any> {
    return this.http
      .post('/organizations/updateInputs', {
        organizationId: organizationId,
        inputs: inputs,
      })
      .pipe(
        tap(() => {
          this.notifier.showSuccess(
            this.translate.instant(
              'OrgInternalContentSvc_ItemsUpdatedSuccessMessage',
              { inputsCount: inputs.length }
            )
          );
          this.trackerService.trackEventData({
            action: 'CMS Item Updated',
            category: inputs.length === 1 ? inputs[0].inputType : 'Bulk',
          });
        }),
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant('OrgInternalContentSvc_ItemsUpdateError', {
                inputsCount: inputs.length,
              }),
              error
            )
          );
        })
      );
  }

  public findOrgInputMatch(user: any, input: any) {
    let hasMatch = false;
    // Compare all user's orgs for a match with the input's orgId
    for (let i = 0; i < user.orgInfo.length; i++) {
      const org = user.orgInfo[i];
      if (org.organizationId === input.organizationId) {
        hasMatch = true;
        break;
      }
    }
    return hasMatch;
  }

  public setInputAsReference(input: any) {
    if (input && input.reference) {
      input = { ...{}, ...input, ...input.reference };
    }
    return input;
  }

  public canEditOrgManagedContent(input: any) {
    const authUser = this.authService.authUser;
    const canManageContent =
      authUser?.defaultOrgInfo?.permissions?.manageContent;
    const orgInfo = authUser?.orgInfo;
    const hasMatchingOrg = orgInfo
      ? this.findOrgInputMatch(authUser, input)
      : false;
    // check content status and user permission
    input = this.setInputAsReference(input);

    // if the input is a LearningResourceViewModel then isCmsContent will exist on the model
    const isCmsContent = input?.isCmsContent ?? input?.model?.isCmsContent;

    return (
      isCmsContent &&
      canManageContent &&
      hasMatchingOrg &&
      (!input.providerId || input.providerId === -1)
    );
  }
}
