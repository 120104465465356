<div
  class="popover__container popover__container--{{ placement }}"
  #popover
  *ngIf="isPopoverPresent"
  [@openClose]="animationState"
  (@openClose.done)="onAnimationEvent($event)"
  [ngStyle]="{
    left: placementAdjustLeftRem + 'rem',
    top: placementAdjustTopRem + 'rem'
  }"
>
  <div class="popover__content">
    <ng-content></ng-content>
  </div>
</div>
