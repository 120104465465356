<dgx-modal
  bodyClasses="guts-p-t-0 "
  [isHeaderBorderless]="false"
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
  [isFooterless]="true"
  trapFocus
>
  <ng-container class="modal-header">
    {{ formTitle }}
  </ng-container>
  <ng-container class="modal-body">
    <form
      role="form"
      [formGroup]="form"
      class="form-wrap guts-p-full-1"
      (ngSubmit)="onSubmit()"
    >
      <div [class.input-success-kickstart]="shouldShowResults">
        <div class="input-success-wrap">
          <df-form-root
            [form]="form"
            [model]="model"
            [fields]="fields"
            [options]="formOptions"
          >
          </df-form-root>
          <dgx-course-formal-form
            [model]="courseFormModel"
            (onModelHasChanged)="onModelHasChanged($event)"
            (onSelectedCourseHasChange)="onSelectedCourseHasChange($event)"
          ></dgx-course-formal-form>
          <dgx-course-informal-form
            [model]="courseFormModel"
            (onModelHasChanged)="onModelHasChanged($event)"
            (onSelectedCourseHasChange)="onSelectedCourseHasChange($event)"
          ></dgx-course-informal-form>
        </div>

        <!--Submit button and submission results-->
        <dgx-global-add-results
          [buttonText]="saveCourseLabel"
          [isEditing]="isEditing"
          [isCompleting]="isCompleting"
          [isNewbUser]="isNewbUser"
          [submissionStatus]="submissionStatus"
          [creationFeedback]="creationFeedback"
          [classifier]="'learning'"
          (dismissWithNavigation)="onNavigateToCollection($event)"
          data-dgat="assessment-form-05f"
        ></dgx-global-add-results>
      </div>
    </form>
  </ng-container>
</dgx-modal>
<ng-template #institutionSelectionTemplate>
  <ng-container *ngIf="model.completing && !isPathwayBinAdd">
    <div *ngFor="let source of model.sourceList" data-dgat="courseForm-f3d">
      <label for="courseType">
        <input
          type="radio"
          id="courseType"
          name="courseType"
          [value]="source"
          [checked]="model.selectedOrg === source"
          (change)="onCourseTypeChange(source)"
          data-dgat="courseForm-63b"
        />
        {{ source.title }}
      </label>
    </div>
  </ng-container>
  <ng-container *ngIf="!model.completing || isPathwayBinAdd">
    <label for="courseType"></label>
    <dgx-select
      id="courseType"
      class="full-width"
      [ariaLabel]="model.course.label"
      [selectedItem]="model.selectedOrg"
      (selection)="onCourseTypeChange($event)"
      [options]="model.sourceList"
      dgatInput="course-modal-6c1"
    >
    </dgx-select>
  </ng-container>
</ng-template>
