import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DfButtonActionModule } from './components/button-action';
import { DfButtonBasicModule } from './components/button-basic';
import { DfButtonSplitModule } from './components/button-split';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DfButtonActionModule,
    DfButtonBasicModule,
    DfButtonSplitModule,
  ],
  exports: [DfButtonActionModule, DfButtonBasicModule, DfButtonSplitModule],
  declarations: [],
  providers: [],
})
export class DfButtonsModule {}
