import { Injectable } from '@angular/core';

import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { ActivationStart, Router } from '@angular/router';

import { Subscription } from 'rxjs';

export interface DialogData extends Record<string, unknown> {
  className?: string;
  autoCloseOnRouteChange?: boolean;
  disableClose?: boolean;
  hasBackdrop?: boolean;
}

@Injectable()
export class DialogService {
  isDialogOpen = false;

  private dialogRef?: DialogRef<unknown, ComponentType<unknown>>;
  private routeWatcher: Subscription;

  constructor(
    private dialog: Dialog,
    private router: Router
  ) {}

  /**
   * Open dialog with component as content... close existing dialog if open
   * @param component Component to render in the dialog
   * @param data Parameters to pass to the dialog
   */
  show<T extends DialogData>(component: any, data?: T): DialogRef {
    if (this.isDialogOpen) {
      this.close();

      if (data.autoCloseOnRouteChange) {
        // When the dialog content activates a new route, automatically close the dialog
        this.routeWatcher = this.router.events.subscribe((event) => {
          if (event instanceof ActivationStart) {
            this.close();
          }
        });
      }

      // If show is called while the SAME component is open, then simply close the dialog
      if (this.dialogRef.componentRef === component) return;
    }

    this.dialogRef = this.dialog.open(component, {
      hasBackdrop: data?.hasBackdrop ?? true,
      backdropClass:
        (data?.hasBackdrop ?? true) ? 'apollo-overlay-dark-backdrop' : '',
      // TODO: I don't think this does anything to add the className.
      // there's a panelClass option to add to the panel classes, which I can do but
      // the tailwind doesn't seem to be necessary here
      data: { className: 'tw-max-w-xl', ...data },
      disableClose: !!data?.disableClose,
      ariaModal: data?.hasBackdrop ?? true,
      // TODO: right now our other focus management tools are interacting with this option
      // so if you set to 'first-heading' or any other option, the focus can actually get
      // stolen out of the modal instead.  So for now it's false, once everything is using
      // CDK for popover/modal/other overlays and any other focus tools are removed we should
      // be able to turn this on to 'first-heading' to achieve similar behavior to what we're doing.
      // autoFocus: 'first-heading',
    });
    this.isDialogOpen = true;

    this.dialogRef.closed.subscribe(() => {
      this.dialogRef = undefined;
      this.isDialogOpen = false;
    });

    return this.dialogRef;
  }

  close(): void {
    this.routeWatcher?.unsubscribe();

    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
      this.isDialogOpen = false;
    }
  }
}
