import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { AuthService } from '@app/shared/services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  public postMessage = new Subject();
  public integrations: any = {};
  public integration: any = {};

  private socialIntegrationDetails = {
    twitter: {
      providerCode: 'Twitter',
      value: 'Twitter',
      viewFriendType: this.translateService.instant(
        'IntegrationsSvc_ViewFollowers'
      ),
      friendType: this.translateService.instant('IntegrationsSvc_Followers'),
      icon: '2',
      className: 'social-share-list__icon--twitter',
    },
    facebook: {
      providerCode: 'Facebook',
      displayName: 'Facebook',
      viewFriendType: this.translateService.instant(
        'IntegrationsSvc_ViewFriends'
      ),
      friendType: this.translateService.instant('IntegrationsSvc_Friends'),
      icon: 'j',
      className: 'social-share-list__icon--facebook',
    },
  };

  constructor(
    private authService: AuthService,
    private trackerService: TrackerService,
    private webEnvironmentService: WebEnvironmentService,
    private http: NgxHttpClient,
    private translateService: TranslateService
  ) {}

  public connectIntegration(e, content, context) {
    this.integration = content;
    this.trackerService.trackEventData({
      action: 'Integrations Connected',
      category: 'Connect',
      properties: {
        ProviderName: content.providerCode,
        Context: context,
        Label: content.providerCode,
      },
    });

    const childWindow = this.getChildWindow(content.providerCode);

    const checkOAuthChildWindow = () => {
      if (childWindow != null && childWindow.closed) {
        if (this.getIsOldIE()) {
          this.postMessage.next('rejected');
        } else {
          this.postMessage.next(content);
        }

        clearInterval(timer);

        this.postMessage.complete();
      }
    };
    const timer = setInterval(checkOAuthChildWindow, 500);
    return this.resolveOAuth();
  }

  public socialMessageEventHandler = (e) => {
    // Check if origin is proper to prevent XSS
    const validOrigin = this.webEnvironmentService.validateOrigin(e.origin);
    if (!validOrigin) {
      return;
    }

    return this.postMessage;
  };

  public getProviderConfig(integration, userProviderIntegrations) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < userProviderIntegrations.length; i++) {
      const userProvider = userProviderIntegrations[i];
      if (integration.providerId === userProvider.providerId) {
        if (
          integration.providerConfiguration &&
          integration.providerConfiguration.oAuth
        ) {
          userProvider.oAuth = true;
        }
        userProvider.isSocial = integration.isSocial;
        integration.integrationLogin = userProvider.userLogin
          ? userProvider.userLogin
          : integration.integrationLogin;
        return userProvider;
      }
    }
  }

  public disconnectIntegration(content, context) {
    const isEnabled = false;
    const integrationLogin = '';
    if (
      content.providerConfiguration &&
      content.providerConfiguration.hasNetworking
    ) {
      this.disconnectOAuth(content.providerCode);
    }
    this.trackerService.trackEventData({
      action: 'Integrations Disconnected',
      category: 'Disconnect',
      label: content.providerCode,
      properties: {
        ProviderId: content.providerCode,
        Context: context,
      },
    });
    return this.addEditIntegration(
      integrationLogin,
      content.providerId,
      isEnabled
    );
  }

  public getIntegrations() {
    return this.http.get('/userprofile/getuserintegrations').pipe(
      map((response: any) => {
        this.setSocialIcons(response.socialIntegrationProviders);
        this.integrations = response;
        return response;
      })
    );
  }

  public getSocialIntegrationTypes() {
    return this.socialIntegrationDetails;
  }

  public getProviderIntegrations() {
    return {
      availableProviderIntegrations: this.integrations
        .contentIntegrationProviders,
      userProviderIntegrations: this.integrations.userIntegrations,
    };
  }

  public getSocialIntegrations() {
    return {
      availableSocialIntegrations: this.integrations.socialIntegrationProviders,
      userSocialIntegrations: this.integrations.userSocialNetworks,
    };
  }

  public addEditIntegration(integrationLogin, providerId, isEnabled) {
    return this.http.post('/user/addedituserintegration', {
      IntegrationLogin: integrationLogin,
      ProviderId: providerId,
      ConnectionEnabled: isEnabled,
    });
  }

  public syncIntegration(content) {
    const userId = this.authService.authUser.viewerProfile.userProfileKey;

    return this.http.get('/userintegration/syncexternalcontentforuser', {
      params: {
        userId,
        provider: content.providerCode,
      },
    });
  }

  public getIsOldIE() {
    // IE <= 11 (any better solution, go ahead and update).
    // We assume success here (no rejected resolve param). If there are issues with OAuth, the OAuth method callbacks should be responsible for disabling.
    if (typeof Reflect !== 'object') {
      return true;
    } else {
      return false;
    }
  }

  private disconnectOAuth(providerCode) {
    this.http.post('/secure/Disconnect' + providerCode, undefined);
  }

  private resolveOAuth() {
    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    const oAuthSuccessEvent = window[eventMethod];

    // Listen to message from success page (if social OAuth success)
    window.removeEventListener(
      messageEvent,
      this.socialMessageEventHandler,
      false
    );
    oAuthSuccessEvent(messageEvent, this.socialMessageEventHandler);

    return this.postMessage;
  }

  private getChildWindow(providerCode: string) {
    const dualScreenLeft =
      window.screenX !== undefined ? window.screenX : window.screenLeft;
    const dualScreenTop =
      window.screenY !== undefined ? window.screenY : window.screenTop;
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
    const newWindowHeight = 600;
    const newWindowWidth = 1000;
    const left = width / 2 + dualScreenLeft - newWindowWidth / 2;
    const top = height / 2 + dualScreenTop - newWindowHeight / 2;
    const child = window.open(
      '/Secure/' + providerCode + 'Authorization',
      'auth',
      'width=' +
        newWindowWidth +
        ', height=' +
        newWindowHeight +
        ', top=' +
        top +
        ', left=' +
        left
    );

    return child;
  }

  // this part is not used because social accounts are twitter and facebook
  private setSocialIcons(socialProviders) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < socialProviders.length; i++) {
      const socialProvider = this.socialIntegrationDetails[
        socialProviders[i].providerCode
      ];
      if (socialProvider) {
        socialProviders[i].icon = socialProvider.icon;
        socialProviders[i].className = socialProvider.className;
      } else {
        if (
          socialProviders[i].providerConfiguration &&
          socialProviders[i].providerConfiguration.hasNetworking
        ) {
          console.warn(
            'Missing %s social definition',
            socialProviders[i].providerConfiguration.displayName
          );
        }
      }
    }
  }

  // This method won't be used. migrate in case we need it in future
  private getSocialFriends(content) {
    return this.http.get(
      '/api/userprofile/Find' + content.providerCode + 'Friends',
      { params: {} }
    );
  }
}
