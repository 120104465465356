export enum LearnerInsightType {
  Completions = 1,
  Points = 2,
}

export enum ProviderCatalogType {
  Internal = 0,
  External = 1,
  All = 2,
}
