import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { TagResourceViewComponent } from './components/tag-resource-view/tag-resource-view.component';

// Guards

const tagsRoutes: Routes = [
  {
    path: 'tag',
    component: TagResourceViewComponent,
  },
];
@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(tagsRoutes)],
  exports: [RouterModule],
})
export class TagsRoutingModule {}
