import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  SortableListDirective,
  SORTABLE_LIST_TOKEN,
} from './sortable-list.directive';
import { DraggableToken } from '../draggable.token';
import { Draggable } from 'gsap/dist/Draggable';

export interface Sortable {
  item: any;
  itemIndex: number;
  element: HTMLElement;
  draggableObj: any;
  lastX: number;
  col: number;
  inBounds: boolean;
  index: number;
  isDragging: boolean;
  lastIndex: number;
  positioned: boolean;
  row: number;
  x: number;
  y: number;
  disabled: boolean;
  manageSortButtonFocus?: (button: HTMLButtonElement) => void;
}

/**
 * Element that can be moved inside a SortableList container.
 *
 * Add a template variable `sortPreviousRef` to a child button to add a click handler to that element that moves the item up in the list
 * Add a template variable `sortNextRef` to a child button to add a click handler to that element that moves the item down in the list
 */
@Directive({
  selector: '[dgxSortable]',
})
export class SortableDirective
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() public sortableItem: any;
  @Input() public sortableIndex?: number;
  /** Prevents a component from being dragged */
  @Input() public disableDrag?: boolean = false;

  @ContentChild('sortPreviousRef')
  public sortPreviousRef: ElementRef<HTMLButtonElement>;
  @ContentChild('sortNextRef')
  public sortNextRef: ElementRef<HTMLButtonElement>;
  public sortable: Sortable = {
    item: undefined,
    itemIndex: null,
    element: this.elementRef.nativeElement,
    draggableObj: null,
    lastX: 0,
    col: 0,
    inBounds: true,
    index: null,
    isDragging: false,
    lastIndex: null,
    positioned: false,
    row: 0,
    x: 0,
    y: 0,
    disabled: false,
  };

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Inject(SORTABLE_LIST_TOKEN)
    private sortableListRef: SortableListDirective,
    @Inject(DraggableToken) private draggable: Draggable
  ) {}
  /** When sorting is enabled, disable all pointer events on children except if they have interactable buttons */
  @HostBinding('class.children-pointer-events-none') get disableChildren() {
    return !(this.sortPreviousRef || this.sortNextRef);
  }

  public ngOnInit() {
    if (!this.sortableListRef) {
      throw Error(
        'SortableDirective requires SortableListDirective as a parent'
      );
    }

    if (!this.sortableListRef.sortingDisabled) {
      this.setupSortable();
    }
  }

  public ngAfterViewInit(): void {
    this.setupSortable();

    if (this.disableDrag) {
      this.sortable.draggableObj[0].disable();
      return;
    }

    if (this.sortPreviousRef) {
      this.sortPreviousRef.nativeElement.addEventListener('click', () => {
        this.sortableListRef.onSortButton(
          'prev',
          this.sortable,
          this.sortPreviousRef.nativeElement
        );
      });
    }

    if (this.sortNextRef) {
      this.sortNextRef.nativeElement.addEventListener('click', () => {
        this.sortableListRef.onSortButton(
          'next',
          this.sortable,
          this.sortNextRef.nativeElement
        );
      });
    }

    if (this.sortNextRef || this.sortPreviousRef) {
      this.sortable.manageSortButtonFocus = (buttonClicked) => {
        this.manageSortButtonFocus(buttonClicked);
      };
    }
  }

  public ngOnChanges({ disableDrag, sortableItem }: SimpleChanges): void {
    if (!disableDrag?.currentValue) {
      this.setupSortable();
    }

    if (sortableItem?.currentValue) {
      this.setupSortable();
    }
  }

  public ngOnDestroy(): void {
    this.sortableListRef.destroyItem(this.sortable);
    if (!this.sortableListRef.sortingDisabled) {
      this.sortableListRef.layoutInvalidated();
    }
  }

  private setupSortable() {
    this.sortable.element = this.elementRef.nativeElement;
    this.sortable.index = this.sortableIndex;
    this.sortable.itemIndex = this.sortableIndex;
    this.sortable.disabled = this.disableDrag;
    this.sortable.item = this.sortableItem;

    this.sortable.draggableObj = this.createDraggableObj();
    this.sortableListRef.registerItem(this.sortable);
  }

  private manageSortButtonFocus(sortButtonClicked: HTMLButtonElement) {
    if (!sortButtonClicked.disabled) {
      sortButtonClicked.focus();
      sortButtonClicked = null;
    } else if (sortButtonClicked.disabled) {
      if (sortButtonClicked === this.sortPreviousRef.nativeElement) {
        this.sortNextRef.nativeElement.focus();
      } else if (sortButtonClicked === this.sortNextRef.nativeElement) {
        this.sortPreviousRef.nativeElement.focus();
      }
    }
  }

  private createDraggableObj() {
    let setTimeoutId: any;
    const sortableRef = this;
    const draggableObj = this.draggable.create(this.elementRef.nativeElement, {
      /*
        IMPORTANT: keep function()'s below
        DO NOT change to () => {}'s
        Preserve `this` (drag utility) provided by Greensock
      */
      onPress: function (e: Event) {
        const dragUtil = this;
        sortableRef.sortable.element.style.zIndex = `${
          sortableRef.sortableListRef.zIndex + 1000
        }`;
        // delay click for clickables and for touch screens
        setTimeoutId = setTimeout(() => {
          e.preventDefault();
          sortableRef.sortableListRef.onPress(dragUtil, sortableRef.sortable);
        }, 200);
      },
      onDrag: function (e: Event) {
        const dragUtil = this;
        // delay drag to wait for the delay above ^^^
        setTimeoutId = setTimeout(() => {
          e.preventDefault();
          sortableRef.sortableListRef.onDrag(dragUtil, sortableRef.sortable);
        }, 200);
      },
      onRelease: function (e: Event) {
        const dragUtil = this;
        clearTimeout(setTimeoutId);
        sortableRef.sortableListRef.onRelease(dragUtil, sortableRef.sortable);
      },
      dragClickables: true,
      clickableTest: (target: HTMLElement) => {
        if (target.tagName === 'DF-ICON') {
          target = target.parentElement;
        }
        if (target.tagName === 'A' || target.tagName === 'BUTTON') {
          return true;
        }
      },
      bounds: this.sortableListRef.container,
      autoScroll: 1,
      zIndexBoost: false,
    });

    return draggableObj;
  }
}
