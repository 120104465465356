import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'dgx-add-to-catalog',
  templateUrl: './add-to-catalog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class AddToCatalogComponent implements OnInit {
  @Input() public checkboxLabel: string;

  public addToCatalog: FormControl;

  constructor(
    public formBuilder: FormBuilder,
    public parentForm: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    this.addToCatalog = this.formBuilder.control('');
    this.parentForm.form.addControl('addToCatalog', this.addToCatalog);
  }
}
