import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { Target, TargetProperty } from '@app/target/target-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '@app/shared/components/select/select.component';

@Component({
  selector: 'dgx-meta-data-modal',
  templateUrl: './meta-data-modal.component.html',
})
export class MetaDataModal implements OnInit {
  public submitting: boolean = false;
  public metadata: TargetProperty[] = [];

  public i18n = this.translate.instant([
    'addEditMetaDataModal_EditProperties',
    'addEditMetaDataModal_AddProperty',
    'addEditMetaDataModal_RemoveProperty',
    'addEditMetaDataModal_Property',
    'addEditMetaDataModal_Value',
    'OrgPlans_Purpose',
    'Core_Save',
    'dgFlexRow_FieldRequired',
    'Core_FieldRequired',
    'Core_Loading',
  ]);

  @Input() public target: Target;
  @ViewChild('metadataForm') public metadataForm: NgForm;

  public purposes: Option[];
  public selectedPurpose: Option;
  public showPurpose: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private sharedTargetService: SharedTargetService,
    private translate: TranslateService,
    private ldFlagsService: LDFlagsService
  ) {}

  public ngOnInit(): void {
    this.purposes = [
      {
        name: this.sharedTargetService.getTypeDisplayName(
          'Target',
          false,
          true
        ),
        targetType: 'Target',
      },
      {
        name: this.sharedTargetService.getTypeDisplayName('Role', false, true),
        targetType: 'Role',
      },
    ];
    // create a local copy of the metadata, so the source target is not accidentally updated.
    this.metadata = [...this.target.metadata];

    this.selectedPurpose = this.purposes.find(
      (purpose: Option) => purpose.targetType === this.target.targetType
    );

    this.showPurpose = false;
  }

  public updateSelectedPurpose(purpose: Option) {
    this.selectedPurpose = purpose;
    this.metadataForm.form.markAsDirty();
  }

  public addMetaData() {
    this.metadata.push({ property: undefined, value: undefined });
  }

  public removeMetaData(property: TargetProperty) {
    const index = this.metadata.indexOf(property);
    if (index > -1) {
      this.metadata.splice(index, 1);
      this.metadataForm.form.markAsDirty();
    }
  }

  public trackByIndex(index) {
    return index;
  }

  public save() {
    if (this.metadataForm.form.invalid) {
      return;
    }

    this.submitting = true;

    this.target.targetType = this.selectedPurpose
      ? this.selectedPurpose.targetType
      : this.target.targetType;
    this.target.metadata = this.metadata;

    this.activeModal.close(this.target);
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
