import { ResourceType } from '@app/shared/models/core-api.model';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { GroupIdentifier } from '@app/groups/group-api';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { InputDetails } from '@app/inputs/inputs-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

type DuplicateInputDetails = Omit<InputDetails, 'tags'> & {
  _ContentId: number; // not sure where this is coming from
  _ContentType: ResourceType; // not sure where this is coming from
  tags: string;
};

@Component({
  selector: 'dgx-content-catalog-duplicates',
  templateUrl: './content-catalog-duplicates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./content-catalog-duplicates.component.scss'],
})
export class ContentCatalogDuplicatesComponent implements OnInit {
  @Input() duplicates: DuplicateInputDetails[];
  @Input() addToBin?: boolean = false;

  public i18n = this.translate.instant([
    'OrgInternalContentModalCtrl_DuplicatesDescriptionLabel',
    'OrgInternalContentModalCtrl_DuplicatesGroupsLabel',
    'OrgInternalContentModalCtrl_DuplicatesTopicsLabel',
    'Pathways_HoldForLater',
    'Core_Close',
    'Core_Open',
    'A11y_ExpandPanelButton',
    'A11y_CollapsePanelButton',
  ]);
  public header: string;

  constructor(
    private activeModal: NgbActiveModal,
    private searchUrlService: SearchUrlService,
    private translate: TranslateService
  ) {}
  public ngOnInit(): void {
    this.header = this.translate.instant(
      'OrgInternalContentModalCtrl_DuplicatesHeader',
      { count: this.duplicates.length }
    );
  }
  getAriaText(expanded: boolean, title: string) {
    return this.translate.instant(
      expanded ? 'A11y_CollapsePanelButton' : 'A11y_ExpandPanelButton',
      {
        name: title,
      }
    );
  }
  public dismiss(): void {
    this.activeModal.dismiss();
  }

  public groupsText(groupIds: GroupIdentifier[]): string {
    return this.translate.instant(
      'OrgInternalContentModalCtrl_DuplicatesGroupsCount',
      { count: groupIds.length }
    );
  }

  public topicsText(tags: string): string {
    return this.translate.instant(
      'OrgInternalContentModalCtrl_DuplicatesTopicsCount',
      { count: tags.split(',').length }
    );
  }

  public itemAdded(duplicate: InputDetails): void {
    this.activeModal.close(duplicate);
  }

  public getSearchURL(topic: string): string {
    return this.searchUrlService.getGlobalSearchURL(topic);
  }
}
