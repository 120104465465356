import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { WindowLayoutService } from '../services/window-layout/window-layout.service';

@Directive({ selector: '[dgxSticky]' })
export class StickyDirective implements AfterViewInit, OnDestroy {
  @Input() public notStickyMobile?: boolean;
  @Input() public stickyDisabled: boolean;
  @Input() public stickyFullWidth: boolean = true;
  @Input() public stickyCustomClass: string;
  @Input() public notStickyCustomClass: string;
  @Output() public stickyChange = new EventEmitter<boolean>();
  public stickyOnState: string = 'is_fixed';
  private readonly id = Math.random().toString(36).substring(2);

  private headerTop: number;
  private elementProperties;
  private windowResizeTimer;

  constructor(
    private windowLayoutService: WindowLayoutService,
    private element: ElementRef<HTMLElement>
  ) {}

  public ngAfterViewInit(): void {
    const appHeaderEl = document.querySelector<HTMLElement>('.js-app-header');
    this.elementProperties = {
      W: `${this.getWidth(this.element)}`,
      H: this.element.nativeElement.offsetHeight, // I need to make sure this is the same as this.$element.height(),
      Top: this.element.nativeElement.style.top,
    };

    this.headerTop = appHeaderEl?.offsetHeight;

    // preserve dimensions of element
    this.element.nativeElement.style.width = `${this.elementProperties.W}`;

    // append dummy placeholder
    const dummy = document.createElement('div');
    dummy.id = `dummy-${this.id}`;
    dummy.style.height = `${this.elementProperties.H}px`;
    dummy.style.width = this.stickyFullWidth
      ? '100%'
      : `${this.elementProperties.W}px`;
    dummy.classList.add('hidden');

    this.element.nativeElement.insertAdjacentElement('afterend', dummy);

    this.stickIt(this.headerTop, this.elementProperties);
  }

  public ngOnDestroy() {
    const dummyElement =
      this.element.nativeElement.querySelectorAll<HTMLElement>(
        `#dummy-${this.id}`
      );
    dummyElement[0]?.remove();
  }

  @HostListener('window:resize')
  public onWindowResize() {
    clearTimeout(this.windowResizeTimer);
    this.windowResizeTimer = setTimeout(() => {
      const dummyElement = document.querySelector<HTMLElement>(
        `#dummy-${this.id}`
      );
      const windowTop = window.pageYOffset;
      let elementTop = this.getElementTopOffset(this.element.nativeElement);
      if (!dummyElement.classList.contains('hidden')) {
        elementTop = this.getElementTopOffset(dummyElement);
      }

      if (windowTop >= elementTop - this.headerTop) {
        this.elementProperties = {
          W: dummyElement.parentElement.offsetWidth,
          H: dummyElement.parentElement.offsetHeight,
          Top: dummyElement.parentElement.style.top,
        };
      } else {
        this.elementProperties = {
          W: `${this.getWidth(this.element)}`,
          H: this.element.nativeElement.offsetHeight,
          Top: this.element.nativeElement.style.top,
        };
      }

      this.stickIt(this.headerTop, this.elementProperties);
    });
  }

  @HostListener('window:scroll')
  public onScroll() {
    this.stickIt(this.headerTop, this.elementProperties);
  }

  private getWidth(el: ElementRef<HTMLElement>) {
    return this.stickyFullWidth ? '100%' : `${el.nativeElement.offsetWidth}px`;
  }

  private stickIt(
    headerTop: number,
    elProperties: { W: string; H: number; Top: string }
  ) {
    if (
      (this.notStickyMobile && this.windowLayoutService.isMobile) ||
      this.stickyDisabled
    ) {
      return;
    }
    const isLxpRefresh = document.querySelector('dgx-lxp-nav');
    const leftPosition = isLxpRefresh ? '6.6666666667rem' : '0';
    const windowTop = window.pageYOffset;
    let elementTop = this.getElementTopOffset(this.element.nativeElement);
    const dummyElement = document.querySelector<HTMLElement>(
      `#dummy-${this.id}`
    );

    if (!dummyElement.classList.contains('hidden')) {
      elementTop = this.getElementTopOffset(dummyElement);
    }

    if (windowTop >= elementTop - headerTop) {
      if (this.notStickyCustomClass) {
        this.getClasses(false, this.notStickyCustomClass).map((cssClass) =>
          this.element.nativeElement.classList.remove(cssClass)
        );
      }
      this.getClasses(true, this.stickyCustomClass).map((cssClass) =>
        this.element.nativeElement.classList.add(cssClass)
      );
      this.element.nativeElement.style.top = `${headerTop}px`;
      this.element.nativeElement.style.left = this.stickyFullWidth
        ? leftPosition
        : null;
      this.element.nativeElement.style.right = '0';
      dummyElement.classList.remove('hidden');
      dummyElement.style.height = getComputedStyle(
        this.element.nativeElement,
        null
      ).height.replace('px', '');
      this.stickyChange.emit(true);
    } else {
      if (this.notStickyCustomClass) {
        this.getClasses(false, this.notStickyCustomClass).map((cssClass) =>
          this.element.nativeElement.classList.add(cssClass)
        );
      }

      this.getClasses(true, this.stickyCustomClass).map((cssClass) =>
        this.element.nativeElement.classList.remove(cssClass)
      );
      this.element.nativeElement.style.top = elProperties.Top;
      this.element.nativeElement.style.width = '';
      this.element.nativeElement.style.left = null;
      this.element.nativeElement.style.right = null;
      dummyElement.classList.add('hidden');
      this.stickyChange.emit(false);
    }
  }

  private getClasses(sticky: boolean, givenClasses: string): string[] {
    let splitClasses = [];
    if (givenClasses && givenClasses.length > 1) {
      splitClasses = givenClasses.split(' ');
    }
    let classes = [];
    if (sticky) {
      classes = [this.stickyOnState];
    }
    classes = classes.concat(splitClasses);
    return classes;
  }

  private getElementTopOffset(element: HTMLElement): number {
    // https://stackoverflow.com/a/28857255/9714896 equivalent to jQuerys .offset().top
    const rect = element.getBoundingClientRect();
    return rect.top + window.pageYOffset - document.documentElement.clientTop;
  }
}
