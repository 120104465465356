<div
  class="provider collapse-white-space l_flexbar"
  data-dgprop-item-clicked="Provider"
  [ngClass]="{
    'l_flex-justify-center': alignment == 'center',
    'l_flex-justify-left': alignment == 'left',
    'l_flex-justify-right': alignment == 'right',
    'guts-m-b-half': padding == 'below',
    'meta-list--bullets': display == 'list-item'
  }"
>
  <div
    class="provider__meta right-text color-ebony-a61 l_flexbar m-guts-m-b-half"
    [ngClass]="{
      'no-content': isFirstChild
    }"
  >
    <a
      *ngIf="provider.providerId !== null"
      class="provider__meta-label link color-ebony-a61"
      href="{{ getProviderUrl(provider) }}"
      (click)="navigateToProvider($event, provider.providerName)"
      data-dgat="provider-dcc"
      target="{{ isIframe ? '_blank' : '_self' }}"
      >{{ provider.providerName | ellipsis: 30 }}</a
    >
    <span
      *ngIf="provider.providerId === null"
      class="provider__meta-label"
      class="no-wrap"
      >{{ provider.providerName | ellipsis: 30 }}</span
    >
    <img class="provider__meta-favicon" [src]="favicon | blobifyUrl" alt="" />
  </div>
  <span *ngIf="provider.cost" class="provider__cost color-ebony-a61 ib">{{
    provider.cost
  }}</span>
</div>
