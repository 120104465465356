import { InjectionToken, Type } from '@angular/core';
import { InputContext } from './user-input.model';

/**
 * Avoid circular dependency warnings caused by components importing services
 * that import other services that ultimately import those components (e.g.,
 * Component A has a ViewChild typed with Component B and Component B imports
 * a service that imports a service that imports Component A as the component
 * a modal displays).
 */

export const INPUT_CONTEXT: InjectionToken<Type<InputContext>> =
  new InjectionToken<Type<InputContext>>('INPUT_CONTEXT');

export const INPUT_ENTITY_MODEL: InjectionToken<Type<any>> = new InjectionToken<
  Type<any>
>('INPUT_ENTITY_MODEL');
