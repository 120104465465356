import { Component, Input, OnInit } from '@angular/core';
import {
  GroupIdentifier,
  GroupPrivacy,
  GroupPrivacyLevel,
} from '@app/groups/group-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-group-visibility',
  templateUrl: './group-visibility.component.html',
})
export class GroupVisibilityComponent implements OnInit {
  @Input() public adminOnly = false;
  @Input() public completing = false;
  @Input() public customGroupTooltip: string;
  @Input() public customTitle: string;
  // TODO: Refactor these to use a single group object so the interface is contractual
  @Input() public groupIds: number[] = [];
  @Input() public groupNames: string[] = [];
  @Input() public groups: any[] = [];
  @Input() public groupPlaceholderText: string;
  @Input() public hideLabel = false;
  @Input() public hideTooltip = false;
  @Input() public hint: string;
  @Input() public includeAll = false;
  @Input() public includeUsersGroups = false;
  @Input() public readOnly = false;
  @Input() public required = false;
  @Input() public excludedPrivacyLevels?: GroupPrivacy[] = [];
  // TODO: Refactor these to be @Output events instead
  @Input() public selectCallback: (input: any) => void;
  @Input() public deselectCallback: (input: any) => void;

  public i18n = this.translate.instant([
    'dgGroupsEdit_AddGroups',
    'dgGroupsEdit_AddGroupsLabel',
    'dgGroupsEdit_AddGroupsToBoostTooltip',
  ]);
  public groupDefaultTitle = this.i18n.dgGroupsEdit_AddGroups;
  public groupDefaultTooltip = this.i18n.dgGroupsEdit_AddGroupsToBoostTooltip;
  public groupDefaultLabel = this.i18n.dgGroupsEdit_AddGroupsLabel;
  public newGroup = '';
  public groupPrivacyLevel = GroupPrivacyLevel.Closed;

  constructor(private translate: TranslateService) {}

  public get groupTooltip() {
    return this.customGroupTooltip ?? this.groupDefaultTooltip;
  }

  public ngOnInit() {
    if (this.adminOnly) {
      this.groupPrivacyLevel = GroupPrivacyLevel.Administrative;
    }

    this.hideLabel =
      this.hideLabel || (this.readOnly && this.groupNames.length === 0);
    if (this.groupIds.length && !this.groups.length) {
      this.groupIds.forEach((groupId, gindex) => {
        const group = {
          name: '',
          groupId: undefined,
        };
        group.name = this.groupNames[gindex];
        group.groupId = groupId;
        this.groups.push(group);
      });
    }
  }

  public addGroup = (group: GroupIdentifier) => {
    const hasText: boolean = group.name.length > 0;
    const isNewToList: boolean = !this.groups.some(
      (item) => item.name === group.name
    );
    const validGroup: boolean = hasText && isNewToList;

    if (validGroup) {
      this.groupNames.push(group.name);
      this.groupIds.push(group.groupId);
      this.groups.push(group);
      if (this.selectCallback) {
        this.selectCallback({
          model: {
            // TODO: Use camel-case model when fully migrated
            Name: group.name,
            GroupId: group.groupId,
          },
          groupNames: this.groupNames,
          groupIds: this.groupIds,
        });
      }
    }
    // Reset regardless of validity
    this.resetGroupField();
  };

  public deselectGroup = (group: GroupIdentifier) => {
    if (this.readOnly) {
      return;
    }
    this.groups.forEach((item, gIndex: number) => {
      if (item.name === group.name) {
        this.removeGroupAtIndex(gIndex);
      }
    });
    if (this.deselectCallback) {
      this.deselectCallback({
        // TODO: Use camel-case model when fully migrated
        group: {
          Name: group.name,
          GroupId: group.groupId,
        },
      });
    }
  };

  public removeGroupAtIndex(index: number) {
    this.groups.splice(index, 1);
    this.groupNames.splice(index, 1);
    this.groupIds.splice(index, 1);
  }

  public resetGroupField() {
    this.newGroup = '';
  }
}
