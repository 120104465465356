import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CertificateUploadAdapter } from '@app/user-content/user-outcome/certificate-modal/certificate-upload.adapter';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CertificateField, CertificateModel } from '../../certificate.model';
import { CertificateGlobalAddFacade } from '../../services/global-add/certificate-global-add.facade';
import { CertificateMapperService } from '../../services/certificate-mapper.service';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { CertificateTrackerService } from '../../services/certificate-tracker.service';
import { CertificateService } from '../../services/certificate.service';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { WindowToken } from '@app/shared/window.token';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Component({
  selector: 'dgx-certificate',
  templateUrl: './certificate-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CertificateGlobalAddFacade,
    CertificateMapperService,
    OutcomeNotificationService,
    CertificateTrackerService,
    CertificateService,
    OutcomesService,
  ],
})
export class CertificateComponent implements OnInit {
  @Input() public isEditing: boolean;
  @Input() public userOutcomeId: number;

  public vm$: Observable<CertificateModel>;
  public certificateForm: FormGroup;
  public heading: string;
  public submitButtonText: string;

  public i18n = this.translate.instant([
    'Cred_Certificate_CertificateTitle',
    'Core_AddExperience',
    'MediaFormCtrl_TitleRequired',
    'Core_Title',
    'dgProfileCollectionItem_CertIssuedBy',
    'CertificateFormCtrl_IssuerNameRequired',
    'dgUserOutcomeEditForm_DoesCertExpire',
    'dgOrgInternalContent_SkillsTooltipText',
    'Core_Skills',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsMaxError',
    'CertificateFormCtrl_SaveCertificate',
    'CertificateFormCtrl_IssuerURL',
    'CertificateFormCtrl_IssuerName',
    'CertificateFormCtrl_CertificateNumber',
    'CertificateFormCtrl_AddCertificate',
    'CertificateFormCtrl_IssueDate',
    'CertificateFormCtrl_ExpiryDate',
    'dgContentHosting_DragAndDrop',
    'dgUserOutcomeEditForm_EditCertificate',
    'CertificateFormCtrl_AddCertificate',
    'Core_Save',
  ]);
  public markSubFormsAsTouched = false;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    public uploadAdapter: CertificateUploadAdapter,
    private facade: CertificateGlobalAddFacade,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private globalAddTrackingService: GlobalAddTrackingService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.facade.initializeViewModel();
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    this.heading = this.isEditing
      ? this.i18n.dgUserOutcomeEditForm_EditCertificate
      : this.i18n.CertificateFormCtrl_AddCertificate;

    this.submitButtonText = this.isEditing
      ? this.i18n.Core_Save
      : this.i18n.CertificateFormCtrl_SaveCertificate;

    if (this.isEditing) {
      await this.facade.initializeEdit(this.userOutcomeId);
      this.initializeExpandedEditForm();
      return;
    }
    this.initializeForm();
  }

  public onDismiss(event: Event) {
    this.activeModal.dismiss();
    this.globalAddTrackingService.trackGlobalAddCancelled('Certificate');
  }

  private initializeExpandedEditForm(): void {
    this.certificateForm = this.formBuilder.group({
      title: this.facade.snapshot.title ?? '',
      issuer: this.facade.snapshot.issuer ?? '',
      issuerUrl: this.facade.snapshot.issuerUrl ?? '',
      certNumber: this.facade.snapshot.certNumber ?? '',
      noExpiration: this.facade.snapshot.noExpiration ?? false,
      dateRange: this.facade.snapshot.dateRangeForm ?? null,
      skills: [this.facade.snapshot.skills],
      imageUrl: this.facade.snapshot.imageUrl ?? '',
    });
  }

  private initializeForm() {
    this.certificateForm = this.formBuilder.group({
      title: ['', Validators.required],
      issuer: ['', Validators.required],
      issuerUrl: [],
      certNumber: [],
      noExpiration: [],
      skills: [],
      imageUrl: [],
      dateRange: [],
    });
  }

  public onFormControlUpdate(field: CertificateField, value: any) {
    this.facade.onFormControlUpdate(this.certificateForm, field, value);
  }

  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.certificateForm);
    this.markSubFormsAsTouched = true;
    this.cdr.detectChanges();

    if (this.certificateForm.invalid) {
      return;
    }

    try {
      await this.facade.onSubmit(this.certificateForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }
  }

  public loadInferredSkills() {
    this.facade.loadInferredSkills(
      this.certificateForm.get('title').value || '',
      this.certificateForm.get('issuer').value || ''
    );
  }

  public onNavigateToCollection(collectionUrl: string) {
    this.activeModal.close(this.facade.snapshot);
    if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }
}
