import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { RecommendationStatusIdTypes } from '@app/recommendations/services/recommendations.service';
import { NotificationType } from '@lib/fresco';

// endpoint '/recommendations/getuserstatusbyrecommendation' is returning the statusId as numerals instead of strings -- would need to update
export enum RecommendationStatusIdNumerals {
  Pending = 0,
  Queued = 1,
  Dismissed = 2,
  Complete = 3,
  Private = 4,
}
@Component({
  selector: 'dgx-shared-with-user-list',
  templateUrl: './shared-with-user-list.component.html',
  styleUrls: ['./shared-with-user-list.component.scss'],
})
export class SharedWithUserListComponent implements OnInit {
  @Input() public assignedUsersInfo;
  @Input() public isSkillCoach: boolean;
  @Output() public loadUsers: EventEmitter<{
    skip: number;
    count: number;
  }> = new EventEmitter();

  public readonly NotificationType = NotificationType;

  public showPrivateOrAnonymous = false;

  // Locals (Public)
  public i18n = this.translate.instant([
    'recommendationDetail_StatusComplete',
    'recommendationDetail_StatusDismissed',
    'recommendationDetail_StatusPending',
    'recommendationDetail_StatusQueued',
    'Core_Private',
    'Team_Group_Shared_And_Private_Message',
  ]);
  public moreUsersAvailable;
  public loadingUsers: boolean = true;
  // Due to dg-casing, the enum returns as a key - string instead of key - int
  public statusTypes = RecommendationStatusIdTypes;
  public statusTypeNums = RecommendationStatusIdNumerals;
  public users;

  // Locals (Private)
  private count: number = 10;
  private skip: number = 0;

  constructor(
    private recommendationsService: RecommendationsService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.loadRecommendationUsers();
  }

  public ngOnChanges({ assignedUsersInfo }: SimpleChanges) {
    if (assignedUsersInfo.currentValue) {
      this.assignedUsersInfo = assignedUsersInfo.currentValue;
      this.users = this.users
        ? [...this.users, ...this.assignedUsersInfo.users]
        : this.assignedUsersInfo.users;
      this.moreUsersAvailable = this.assignedUsersInfo.hasMoreItems;
      this.showPrivateOrAnonymous =
        this.recommendationsService.hasPrivateOrAnonymous(
          this.assignedUsersInfo
        );
    }
    this.loadingUsers = !this.users ? true : false;
  }

  public loadMoreRecommendationUsers(): void {
    if (!this.loadingUsers && this.moreUsersAvailable) {
      this.loadRecommendationUsers();
    }
  }

  public scrollHandler = (event: Event): void => {
    const { scrollHeight, scrollTop, clientHeight } =
      event.target as HTMLElement;
    // Win/Chrome (only) returns float values for scrollHeight/scrollTop
    if (Math.floor(scrollHeight) - Math.ceil(scrollTop) <= clientHeight) {
      this.loadMoreRecommendationUsers();
    }
  };

  private loadRecommendationUsers(): void {
    this.loadUsers.emit({ skip: this.skip, count: this.count });
    this.skip += this.count;
  }
}
