import { Component, Input, OnInit } from '@angular/core';

import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { SkillsStandardsApi } from '@app/skill-standards/skill-standards-api.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-skill-standards-card',
  templateUrl: './skill-standards-card.component.html',
  styleUrls: ['./skill-standards-card.component.scss'],
})
export class SkillStandardsCardComponent implements OnInit {
  // Bindings
  @Input() public skillStandard: SkillsStandardsApi.SkillStandardsCard;

  public authUser: AuthUser;
  public i18n = this.translateService.instant(['Core_Collaborator']);

  constructor(
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  public get showSkillCount(): boolean {
    return this.skillStandard.isAssigned;
  }

  public get showCollaboratorIcon(): boolean {
    return this.skillStandard.isAuthor;
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
  }

  public getViewDetailsLink(id) {
    return [
      `/profile/${this.authUser.viewerProfile.profileUrl}/skillstandards/`,
      id,
    ];
  }

  public getEditDetailsLink(id) {
    return ['/skillstandards/', id];
  }
}
