<div class="combobox select--{{ templateOption }}">
  <div
    #trigger
    role="combobox"
    class="combobox__btn btn btn-dropdown"
    [class.is_disabled]="isDisabled"
    [class.placeholder]="!selectedLabel"
    [class.border--error]="hasError"
    [class.ng-invalid]="(isRequired && !isItemSelected) || hasError"
    [ngClass]="{
      'btn-passive color-ebony-a61 hover-color-ebony font-semibold guts-p-l-1 guts-p-r-2-half':
        templateOption === 'filter'
    }"
    [attr.id]="getTriggerId()"
    [attr.aria-required]="isRequired"
    [attr.aria-disabled]="isDisabled"
    [attr.aria-expanded]="isDropdownDisplayed"
    [attr.aria-errormessage]="ariaErrormessage"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="getTriggerAriaLabelledby()"
    aria-haspopup="listbox"
    [attr.aria-owns]="isDropdownDisplayed ? getListboxId() : null"
    [attr.data-dgat]="getDgat('trigger')"
    [tabindex]="isDisabled ? -1 : 0"
    (click)="toggleDropdown()"
    (blur)="handleBlur()"
    (keydown)="handleKeydown($event)"
    (mousedown)="handleMousedown($event)"
  >
    <span class="l_flex m-l_flex">
      <span
        class="combobox__value l_flex-grow"
        [dgxLineClampText]="selectedLabel ? selectedLabel : placeholder"
        [clampLinesMax]="1"
      >
      </span>

      <span
        class="combobox__arrow"
        [class.combobox__arrow--no-border]="templateOption === 'filter'"
      >
        <df-icon
          [attr.data-dgat]="getDgat('icon')"
          icon="chevron-down"
          [size]="iconSize"
        ></df-icon>
      </span>
    </span>
  </div>

  <!-- Dropdown results -->
  <div
    #scrollContainer
    role="listbox"
    class="combobox__menu dropdown-menu dropdown-menu-left guts-p-v-half rounded--large white-space"
    [attr.id]="getListboxId()"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="getListboxAriaLabelledby()"
    [class.dropdown-menu--open]="isDropdownDisplayed"
    [class.dropdown-menu-right]="templateOption === 'dropdown'"
    [class.dropdown-menu-left]="templateOption === 'filter'"
    [ngSwitch]="optionsLoaded"
    (mousedown)="handleMousedown($event)"
    (mouseover)="isHovering = true"
    (mouseout)="isHovering = false"
    [@fadeInAndOut]
  >
    <!-- loading results -->
    <ng-container *ngSwitchCase="false">
      <!--
        Linter error
        [class.listbox--option-selected]="i === selectedIndex"
        Property 'i' does not exist on type 'SelectComponent'.
      -->

      <div
        #listOptions
        role="option"
        tabindex="-1"
        class="combobox__option listbox--option"
      >
        {{ loadingText ? loadingText : i18n.Core_LoadingResults }}
      </div>
    </ng-container>

    <!-- filtered results -->
    <ng-container *ngSwitchDefault>
      <ng-container
        *ngFor="
          let option of options;
          let i = index;
          let first = first;
          trackBy: trackByTrackBy
        "
        data-dgat="select-18d"
      >
        <!-- group divider/label -->
        <ng-container *ngIf="isGrouping(option, i)">
          <div *ngIf="!first" class="combobox__divider"></div>
          <div
            *ngIf="!!option.groupingLabel"
            [innerHtml]="option.groupingLabel"
            class="combobox__group-label"
          ></div>
        </ng-container>

        <!-- select options -->
        <div
          #listOptions
          role="option"
          class="combobox__option listbox--option {{ option.classname || '' }}"
          [class.disabled]="optionDisabled(option)"
          [class.listbox--option-focus]="i === highlightedIndex"
          [class.listbox--option-selected]="i === selectedIndex"
          [attr.id]="getOptionId(i)"
          [attr.aria-selected]="i === selectedIndex"
          [attr.data-dgat]="getDgat('listOption', i)"
          tabindex="-1"
          (click)="selectOption(i)"
          (mousedown)="handleMousedown($event)"
        >
          <ng-container [ngSwitch]="useLabelValue">
            <div
              *ngSwitchCase="true"
              class="combobox__option-label"
              [innerHtml]="labelValue(option)"
            ></div>
            <div *ngSwitchDefault class="combobox__option-label">
              {{ option[labelKey] }}
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
