import { HttpUrlEncodingCodec } from '@angular/common/http';
import { safeEncodeURIComponent } from './utils';

/**
 * @description
 * By default, Angular's HTTPClient (and by extension our NgxHttpClient) encodes query parameters.
 * Since some characters are reserved (https://www.rfc-editor.org/rfc/rfc3986#section-2.2) they are
 * not encoded (e.g. '+' treated as empty space). This causes a problem when those specific characters
 * need to be passed to the back end, i.e. global search. Note that we can't pre-encode (encodeURIComponent) the
 * parameters before passing them to the Angular HTTPClient as the '%' character will be double encoded.
 *
 * @see NgxHttpClient for implementation
 */
export class NgxHttpParamUriEncoder extends HttpUrlEncodingCodec {
  public encodeKey(key: string): string {
    return safeEncodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return safeEncodeURIComponent(value);
  }
}
