<dgx-modal
  bodyClasses="guts-p-t-0 "
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
  [isFooterless]="true"
>
  <ng-container class="modal-header">
    {{ isEditing ? i18n.TaskFormCtrl_Edit : i18n.TaskFormCtrl_Add }}
  </ng-container>
  <ng-container class="modal-body">
    <form
      class="form-wrap guts-p-full-2"
      (ngSubmit)="onSubmit()"
      [ngClass]="{ 'input-success-kickstart': isAdding }"
    >
      <div *ngIf="isLoading">
        <div class="spinner"></div>
      </div>

      <div *ngIf="!isLoading">
        <div class="input-success-wrap" id="input-success-wrap">
          <div class="overlay"></div>
          <div class="guts-m-b-1">
            <label for="taskTitle" class="form-label">
              <span>{{ 'TaskFormCtrl_Title' | translate }}</span
              ><df-label-marker></df-label-marker>
            </label>
            <input
              #taskTitle="ngModel"
              id="taskTitle"
              name="taskTitle"
              type="text"
              class="task-title"
              [(ngModel)]="input.title"
              [value]="input.title"
              dgxAutofocus
              required
              data-dgat="taskForm-cab"
            />
            <dgx-validate-field
              [message]="
                !(taskTitle.value || '').toString().trim() && taskTitle.touched
                  ? i18n.TaskFormCtrl_TitleError
                  : ''
              "
            ></dgx-validate-field>
          </div>
          <div class="guts-m-b-1 m-guts-m-t-1">
            <label for="articleSummary">{{
              i18n.TaskFormCtrl_Description
            }}</label>
            <textarea
              name="articleSummary"
              id="articleSummary"
              [(ngModel)]="input.summary"
              [value]="input.summary"
              data-dgat="taskForm-bae"
            ></textarea>
          </div>
          <div
            *dgxFeatureToggle="
              'content_duration_course_event_other';
              hidden: true
            "
            class="guts-m-b-1"
          >
            <label for="duration">{{ i18n.Core_DurationMinutes }}</label>
            <input
              id="duration"
              name="duration"
              type="text"
              [(ngModel)]="input.durationUnits"
              [value]="input.durationUnits"
              data-dgat="taskForm-8b5"
            />
          </div>
          <div
            *dgxFeatureToggle="'content_duration_course_event_other'"
            class="guts-m-b-1 taskDuration"
          >
            <div class="taskDuration--flexItem guts-p-r-1">
              <label for="durationHours">{{ i18n.Core_Hours }}</label>
              <input
                #durationHours="ngModel"
                id="durationHours"
                name="durationHours"
                type="number"
                [(ngModel)]="input.durationHours"
                (ngModelChange)="
                  onChangeOfDuration(
                    durationHours.control.value,
                    durationMinutes.control.value
                  )
                "
                [value]="input.durationHours"
                data-dgat="task-modal-fb8"
              />
              <dgx-validate-field
                *ngIf="durationHoursValidationError"
                [message]="durationHoursValidationError"
              ></dgx-validate-field>
            </div>
            <div class="taskDuration--flexItem guts-p-l-1">
              <label for="durationMinutes">{{ i18n.Core_Minutes }}</label>
              <input
                #durationMinutes="ngModel"
                id="durationMinutes"
                name="durationMinutes"
                type="number"
                [(ngModel)]="input.durationMinutes"
                (ngModelChange)="
                  onChangeOfDuration(
                    durationHours.control.value,
                    durationMinutes.control.value
                  )
                "
                [value]="input.durationMinutes"
                data-dgat="task-modal-7d6"
              />
              <dgx-validate-field
                *ngIf="durationMinutesValidationError"
                [message]="durationMinutesValidationError"
              ></dgx-validate-field>
            </div>
          </div>
          <div *ngIf="allowSetImage">
            <div class="guts-m-b-1">
              <dgx-upload-section
                [imageUrl]="input.imageUrl"
                [uploadAdapter]="uploadAdapter"
                (imageParsedEvent)="onImageParsed($event)"
                (uploadSuccessEvent)="onImageUploadSuccess($event)"
                (deleteEvent)="onDeleteImage()"
                [useCropper]="true"
                [aspectRatio]="16 / 9"
                [secondaryAspectRatio]="16 / 5"
              >
              </dgx-upload-section>
            </div>
          </div>
          <div class="guts-m-b-1">
            <dgx-tags-edit
              [tags]="input.tags"
              (tagsChange)="handleTagsChange($event)"
              [completing]="true"
            ></dgx-tags-edit>
          </div>
        </div>

        <div class="guts-m-t-1">
          <div class="input-success-button-wrap">
            <div class="input-success-dot"></div>
            <button
              type="submit"
              df-button
              dfButtonType="primary"
              class="input-success-button zeta full-width"
              data-dgat="taskForm-11a"
              [ngClass]="{
                is_disabled: !taskTitle.value
              }"
              [disabled]="!taskTitle.value"
            >
              <span>{{ i18n.TaskFormCtrl_Save }}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</dgx-modal>
