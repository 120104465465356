import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

/**
 * This helper is mostly used for the {@link MarkdownEditorComponent} to provide
 * additional keyboard functionality to meet the ADA standards of toolbars:
 * http://www.w3.org/TR/wai-aria-practices/#toolbar
 *
 * But it can be used for any similar situation that requires an a11y-friendly toolbar.
 * Disables using tab/shift-tab on the toolbar in favor of using arrow keys.
 */
@Injectable({
  providedIn: 'root',
})
export class A11yToolbarService {
  public get keydown(): Observable<KeyboardEvent> {
    return this.keydownSubject.asObservable();
  }
  private keydownSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  private keydownSubscription: Subscription;

  constructor() {}

  /**
   * Use this function to setup listeners; client component should handle
   * call {@link this.teardownToolbarKeyboardHandlers} to remove the listener and subscription
   * on ngOnDestroy of the component to ensure proper cleanup.
   *
   * @param container HTMLElement to listen to events on that contains the toolbar elements
   * @param tools List of the toolbar HTMLElement references
   */
  public setupToolbarKeyboardHandlers(
    container: HTMLElement,
    tools: HTMLElement[]
  ): Subscription {
    container.addEventListener('keydown', this.eventListenerHandler);
    return (this.keydownSubscription = this.keydown.subscribe((event) => {
      this.handleToolbarKeydownEvent(event, tools);
    }));
  }

  /**
   * Use this function to clean up the listener and subscription that are handling
   * the keyboard events setup in {@link this.setupToolbarKeyboardHandlers}
   *
   * @param container
   */
  public teardownToolbarKeyboardHandlers(container: HTMLElement) {
    container.removeEventListener('keydown', this.eventListenerHandler);
    this.keydownSubscription.unsubscribe();
  }

  /**
   * Handler for the keydown events, checks whether a key was pressed that
   * will cause a focus change and changes the focus if it should.  Prevents
   * default behavior for keys it handles.
   *
   * @param event
   * @param tools
   */
  public handleToolbarKeydownEvent(
    event: KeyboardEvent,
    tools: HTMLElement[]
  ): void {
    if (!this.shouldHandleKeypress(event)) {
      return;
    }

    const targetEl = event.target;
    const currentIndex = Array.from(tools).findIndex((tool) => {
      return tool === targetEl;
    });
    const nextIndex = this.getNextIndex(
      event.key.toLowerCase(),
      currentIndex,
      tools.length
    );
    event.preventDefault();
    this.setNewToolFocus(currentIndex, nextIndex, tools);
  }

  /**
   * Figures out which is the next element that should be focused
   * based on the current position and the key that was pressed.
   * The arrow keys will loop back to the beginning if the focus was
   * on the end element and the right arrow is pressed, or to the end
   * if the first element was selected and the left arrow is pressed.
   *
   * @param key Lowercase string representing the key pressed
   * @param currentIndex Index of the currently selected element
   * @param tools List of tool elements
   */
  public getNextIndex(
    key: string,
    currentIndex: number,
    toolbarCount: number
  ): number {
    let nextIndex: number;

    switch (key) {
      case 'arrowleft':
        // loop to the end if we are at the beginning otherwise go back one
        nextIndex = currentIndex === 0 ? toolbarCount - 1 : currentIndex - 1;
        break;
      case 'arrowright':
        // loop around back to the beginning if we are at the end of the tools,
        // otherwise go forward one
        nextIndex = currentIndex === toolbarCount - 1 ? 0 : currentIndex + 1;
        break;
      case 'home':
        nextIndex = 0;
        break;
      case 'end':
        nextIndex = toolbarCount - 1;
        break;
      default:
        break;
    }

    return nextIndex;
  }

  /**
   * Simple function that takes a list of HTMLElements representing the
   * tools in the toolbar and the index of the currently focused tool and
   * the index of the tool that should become focused and changes the focus
   * and tabindex to match.
   *
   * @param currentIndex Index of the currently focused tool element
   * @param nextIndex Index of the tool element that should have focus next
   * @param tools The list of tool elements
   */
  public setNewToolFocus(
    currentIndex: number,
    nextIndex: number,
    tools: HTMLElement[]
  ): void {
    tools[currentIndex].setAttribute('tabindex', '-1');
    tools[nextIndex].setAttribute('tabindex', '0');
    tools[nextIndex].focus();
  }

  /**
   * Checks that the keyboard event was not triggered within an input or textarea
   * and matches one of the valid key events we want to handle for the toolbar
   * and returns a boolean.
   *
   * @param event
   */
  public shouldHandleKeypress(event: KeyboardEvent): boolean {
    const targetEl = event.target as HTMLElement;
    const tagName = targetEl.tagName.toLowerCase();
    const key = event.key.toLowerCase();

    // if we are typing in an input or textarea inside the markdown editor,
    // ignore the keypress handler entirely
    if (tagName === 'input' || tagName === 'textarea') {
      return false;
    }

    // otherwise check if it's a key we want to handle
    return (
      key === 'arrowleft' ||
      key === 'arrowright' ||
      key === 'home' ||
      key === 'end'
    );
  }

  /**
   * Created to have a reference to the event listener handler for the
   * keydown events in order to clean it up on destroy.
   *
   * @param event
   */
  private eventListenerHandler = (event) => {
    this.keydownSubject.next(event);
  };
}
