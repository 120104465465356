import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeedbackService } from './feedback.service';

export interface FeedbackCategory {
  name: string;
  routeDestination?: string;
  // Main categories will have subcategories, but subcategories will not have additional subcategories
  subcategories?: FeedbackCategory[];
  descriptionRequired?: boolean;
  userWarningMessage?: string;
}

export interface SendFeedbackParams {
  // The values for category & subcategory represent the numerical option chosen (1-4) in the category/subcategory list
  // Numerical values are being used for Amplitude tracking purposes
  category: string;
  contextId?: number;
  contextType?: string;
  element?: any;
  feedbackType?: number;
  imageUrl?: string;
  itemType: string;
  itemId: number;
  location?: string;
  message: string;
  okayToNotify: boolean;
  routeDestination?: string;
  subcategory: string;
  sectionType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackModalService {
  constructor(
    private feedbackService: FeedbackService,
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private webEnvironmentService: WebEnvironmentService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public getFeedbackCategories(
    contextType: string,
    contextId: number,
    itemType: string,
    itemId: number
  ): Observable<FeedbackCategory[]> {
    return this.http.get<FeedbackCategory[]>('/user/getfeedbackrouting', {
      params: { contextType, contextId, itemType, itemId },
    });
  }

  public sendFeedback(
    feedbackParams: SendFeedbackParams,
    numOfCategorySelected: number,
    numOfSubcategorySelected: number
  ) {
    if (!feedbackParams.contextType) {
      /**
       * This check against analyticsAppLocation has been here since the beginning of the
       *   feedback service, but it's not clear why it was used, and additional values
       *   have been added as possible locations since then, resulting in a 400 error
       *   when submitting.
       *  This fix adds a check to ensure that the contextType is set to an existing value in
       *    FeedbackContextType enum or falls back to null.
       */
      feedbackParams.contextType = this.feedbackService.feedbackContextType[
        this.webEnvironmentService.analyticsAppLocation
      ]
        ? this.feedbackService.feedbackContextType[
            this.webEnvironmentService.analyticsAppLocation
          ]
        : feedbackParams.contextType;
    }

    feedbackParams.location = this.document.location.href;

    return this.http.post('/user/sendfeedback', feedbackParams).pipe(
      tap(() => {
        this.tracker.trackEventData({
          action: 'Feedback Sent',
          properties: {
            feedbackType: numOfCategorySelected,
            feedbackDetails: numOfSubcategorySelected,
            itemId: feedbackParams.itemId,
            itemType: feedbackParams.itemType, // displays as ContentType in Amplitude
            okToNotify: feedbackParams.okayToNotify,
            sectionType: feedbackParams.sectionType,
          },
        });
      }),
      tap(() => {
        this.notifier.showSuccess(
          this.translate.instant('FeedbackSvc_ThankyouMessage')
        );
      })
    );
  }
}
