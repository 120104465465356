import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PathwayStep } from '@app/pathways/rsm/pathway-api.model';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';

/**
 * Simple presentational component for an authoring step.
 */
@Component({
  selector: 'dgx-pathway-author-step',
  templateUrl: './pathway-author-step.component.html',
})
export class PathwayAuthorStepComponent {
  @Input() public isOnlyChild = false;
  @Input() public menuConfig: MenuViewModel[];
  @Input() public pathwayId: number;
  @Input() public pathwayVersion: string;
  @Input() public step: PathwayStep;
  @Output() public reorderSteps = new EventEmitter<any>();

  /**
   * Emit reorder-steps click for the parent.
   */
  public onReorderStepsClick(): void {
    this.reorderSteps.emit();
  }
}
