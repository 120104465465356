<div
  #container
  [class.mention-list--visible]="!isHidden && items?.length"
  role="listbox"
  class="mention-list"
  aria-describedby="mention-list__help"
>
  <ng-container
    *ngFor="
      let result of items;
      let i = index;
      let last = last;
      trackBy: trackBy
    "
  >
    <!-- avoid calling getItemId() multiple times -->
    <ng-container
      *ngTemplateOutlet="
        listItem;
        context: { $implicit: result, i: i, last: last, id: getItemId(i) }
      "
    ></ng-container>
  </ng-container>
</div>

<!-- Alert the user to the number of search results as they type! -->
<div id="mention-list__help" class="a11y-hide">
  <span *ngIf="items.length" role="alert">{{
    'Core_ResultsFoundFormat' | translate: { count: items.length }
  }}</span>
</div>

<ng-template #listItem let-result let-id="id" let-i="i" let-last="last">
  <button
    role="option"
    type="button"
    tabindex="-1"
    [id]="id"
    [attr.data-dgat]="id"
    [attr.aria-describedby]="id + '__highlighted'"
    [attr.aria-selected]="i === activeIndex"
    (click)="onMentionClick(i)"
    class="mention-list__item full-width left-text listbox-option"
    [class.border-bottom]="!last"
    [class.mention-list__item--selected]="i === activeIndex"
  >
    <dgx-mention-list-item [mention]="result"></dgx-mention-list-item>
  </button>
  <!-- Read the result.name as the user highlights each option! -->
  <div
    [id]="getItemId(i) + '__highlighted'"
    class="a11y-hide mention-list__item__alert"
  >
    <span *ngIf="i === activeIndex" role="alert">{{ result.name }}</span>
  </div>
</ng-template>
