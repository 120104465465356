import { Inject, Injectable } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { EngagedLearnerModalComponent } from '@app/shared/components/engaged-learner-modal/engaged-learner-modal.component';
import { EngagedCongratulationsModalComponent } from '@app/shared/components/engaged-congratulations-modal/engaged-congratulations-modal.component';
import { AuthService } from '@app/shared/services/auth.service';
import { AuthUser } from '@app/account/account-api.model';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';

export interface EngagedLearner {
  // Some user models only have the key OR the id so we accept both here.
  id: string | number;
  isEngaged: boolean;
  name: string;
  picture: string;
}

@Injectable({
  providedIn: 'root',
})
export class EngagedLearnerService {
  // In a RTL language we need to nudge the engaged learner circle to the other
  // side since transform is text direction agnostic
  public invertHorizOffset = document.dir === 'rtl';

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get isEnabled(): boolean {
    // don't assume we have an authUser, this is accessible on public pages where the authUser will be undefined
    return !this.authUser?.defaultOrgInfo?.settings?.disableEngagedLearner;
  }

  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  /**
   * Check to see if the user's active learner status has changed
   * since last page load and show a congratulations modal if so.
   */
  public checkForEngagedLearnerStatusChange() {
    if (!this.authUser) {
      return;
    }

    // Check if the feature is enabled via the feature flags
    if (!this.isEnabled) {
      return;
    }

    const { userProfileId, picture, isEngaged, name } =
      this.authUser.viewerProfile;
    const sessionKey = `degreed.EngagedSvc.engaged_prev:${userProfileId}`;
    const prevValue = this.windowRef.sessionStorage.getItem(sessionKey);

    if (prevValue && JSON.parse(prevValue) === false && isEngaged) {
      this.windowRef.sessionStorage.removeItem(sessionKey);

      const profile: EngagedLearner = {
        id: userProfileId,
        picture,
        isEngaged,
        name,
      };

      this.showEngagedCongratulations(profile).subscribe(() => {
        this.showEngagedLearnerDetails(profile);
      });
    } else if (isEngaged === false) {
      this.windowRef.sessionStorage.setItem(sessionKey, 'false');
    }
  }

  /**
   * Show a modal with user details and information about active status
   */
  public showEngagedLearnerDetails(profile: EngagedLearner): void {
    const inputs = { profile };
    this.modalService
      .show(EngagedLearnerModalComponent, { inputs })
      .subscribe();
  }

  /**
   * Show a modal congratulating the user for becoming an Active learner
   */
  private showEngagedCongratulations(
    profile: EngagedLearner
  ): Observable<undefined> {
    const inputs = { profile };
    return this.modalService.show(EngagedCongratulationsModalComponent, {
      inputs,
    });
  }
}
