import { Injectable } from '@angular/core';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import { sortBy } from '@app/shared/utils/common-utils';

@Injectable({
  providedIn: 'root',
})
export class TabNavigationService {
  /**
   * Return a correctly-sorted and filtered list of tabs for use with TabNavigationService.
   *
   * @param tabs - The tabs to filter and potentially sort.
   */
  public formatTabs(tabs: TabNavigationItem[]): TabNavigationItem[] {
    // Filter out tabs where isAuthorized is *false*. Undefined is okay.
    tabs = this.removeUnauthorizedTabs(tabs);
    // If `sortOrder` is present on *all* tabs, sort accordingly.
    // (The way `sort` works with missing properties is just not very intuitive.)
    // == type checking is intentional here, per our FE code standards for checking undefined/null
    if (tabs.every((tab) => tab.sortOrder != undefined)) {
      tabs = tabs.sort((a, b) => sortBy(a, b, 'sortOrder'));
    }
    // Return final tabs.
    return tabs;
  }

  private removeUnauthorizedTabs(
    tabs: TabNavigationItem[]
  ): TabNavigationItem[] {
    return tabs.filter((tab) => {
      return !tab.hasOwnProperty('isAuthorized') || !!tab.isAuthorized;
    });
  }
}
