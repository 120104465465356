<div>
  <label
    [for]="labelFor"
    [ngClass]="{ 'full-width': showFullWidthLabel }"
    class="form-label"
  >
    <!-- Pairing the label with the info icon for orientation purposes -->
    <span>
      {{ label }}
      <df-icon
        *ngIf="tipText"
        [icon]="icon"
        size="small"
        class="par--light guts-m-b-quart"
        [a11yText]="'Core_MoreInfo' | translate"
        [attr.aria-label]="'Core_MoreInfo' | translate"
        [ngbTooltip]="tipText"
        tabindex="0"
        role="text"
      ></df-icon>
    </span>
    <df-label-marker *ngIf="isRequired"></df-label-marker>
  </label>
  <ng-content></ng-content>
</div>
