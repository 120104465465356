import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { RatingLevelNames } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-tile-target-rating',
  templateUrl: './tag-tile-target-rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagTileTargetRatingComponent {
  @Input() public targetAuthoring: boolean;
  @Input() public targetLevel: number;
  @Input() public showAddTarget: boolean;
  @Input() public tag: TagsApi.Tag;

  public i18n = this.translate.instant([
    'dgTagRating_NoTargetLevelAssigned',
    'Opportunities_Skills_EditTargetLevel',
  ]);

  public ratingLevelNames: RatingLevelNames;

  constructor(
    private tagsService: TagsService,
    private translate: TranslateService
  ) {}

  public get levelName() {
    return this.tagsService.getNameForRatingLevel(this.targetLevel);
  }
}
