<li
  class="guts-p-full-1"
  [ngClass]="{ 'bg-color-ebony-a03': !notification.readFlag }"
  data-dgat="notificationFeed-ff0"
>
  <section
    (click)="onNotificationClick($event)"
    title="{{ notificationCreatedDate | date: 'short' }}"
    data-dgat="notificationFeed-212"
  >
    <div class="l_flexbar">
      <div class="l_flex-top guts-p-r-1">
        <df-icon [icon]="lookupIcon(notification)" class="v-baseline"></df-icon>
      </div>
      <div class="l_flex-grow">
        <div class="guts-p-b-half">
          <p class="par par--small guts-p-b-0">
            <ng-container [ngSwitch]="itemType">
              <ng-container
                *ngSwitchCase="notificationItemType.NewSkillProviderAdded"
                [ngTemplateOutlet]="spanTemplate"
              ></ng-container>
              <ng-container
                *ngSwitchCase="notificationItemType.AutomationsPush"
                [ngTemplateOutlet]="spanTemplate"
              ></ng-container>
              <button
                *ngSwitchDefault
                class="link left-text par par--small color-ebony"
                (click)="onNotificationClick($event)"
                data-dgat="notificationFeed-51d"
                [dgLoadChild]="{
                  componentType: getItemComponentType(
                    notification.notificationType
                  ),
                  bindings: { notification: notification }
                }"
              ></button>
            </ng-container>
            <!-- Template for non-clickable notifications -->
            <ng-template #spanTemplate>
              <span
                class="left-text par par--small color-ebony"
                [dgLoadChild]="{
                  componentType: getItemComponentType(
                    notification.notificationType
                  ),
                  bindings: { notification: notification }
                }"
              >
              </span>
            </ng-template>
          </p>
        </div>
        <div
          class="guts-p-b-half"
          *ngIf="
            params.parentCommentText ||
            params.comment ||
            params.post ||
            params.tag?.otherRating.comment
          "
        >
          <dgx-notification-comment
            [comment]="
              params.parentCommentText ||
              params.comment ||
              params.post ||
              params.tag.otherRating.comment
            "
            [maxLines]="maxCommentLines"
          >
          </dgx-notification-comment>
        </div>
        <div
          class="guts-p-b-half"
          *ngIf="itemType === notificationItemType.NewTargetAuthor"
        >
          <a
            [target]="navigationTarget"
            [href]="params.sourceUrl"
            data-dgat="notificationFeed-a60"
            (click)="stopProfileEventPropagation($event, params.sourceUrl)"
          >
            <h1
              class="h3"
              [dgxLineClampText]="params.targetTitle"
              [clampLinesMax]="maxTitleLines"
            ></h1>
          </a>
        </div>
        <div
          class="guts-p-b-half"
          *ngIf="itemType === notificationItemType.NewPathwayAuthor"
        >
          <a
            [target]="navigationTarget"
            [href]="params.sourceUrl"
            data-dgat="notificationFeed-a61"
            (click)="stopProfileEventPropagation($event, params.sourceUrl)"
          >
            <h1
              class="h3"
              [dgxLineClampText]="params.pathwayName"
              [clampLinesMax]="maxTitleLines"
            ></h1>
          </a>
        </div>
        <div
          class="guts-p-b-half"
          *ngIf="itemType === notificationItemType.Opportunity"
        >
          <a
            [target]="navigationTarget"
            [href]="params.sourceUrl"
            data-dgat="notificationFeed-a60"
            (click)="stopProfileEventPropagation($event, params.sourceUrl)"
          >
            <h1
              class="h3"
              [dgxLineClampText]="params.opportunityTitle"
              [clampLinesMax]="maxTitleLines"
            ></h1>
          </a>
        </div>
        <div
          class="guts-p-b-half"
          *ngIf="itemType === notificationItemType.Recommendation"
        >
          <a
            [target]="navigationTarget"
            [href]="params.sourceUrl || params.inputUrl"
            data-dgat="notificationFeed-124"
            (click)="
              stopProfileEventPropagation(
                $event,
                params.sourceUrl || params.inputUrl
              )
            "
          >
            <h1
              class="h3"
              [dgxLineClampText]="params.title || params.resourceTitle"
              [clampLinesMax]="maxTitleLines"
            ></h1>
          </a>
        </div>
        <div
          class="guts-p-b-half"
          *ngIf="itemType === notificationItemType.ResourceAssociationRemoved"
        >
          <a
            [target]="navigationTarget"
            [href]="params.sourceUrl"
            data-dgat="notificationFeed-124"
            (click)="stopProfileEventPropagation($event, params.sourceUrl)"
          >
            <h1
              class="h3"
              [dgxLineClampText]="params.targetTitle"
              [clampLinesMax]="maxTitleLines"
            ></h1>
          </a>
        </div>
        <div
          *ngIf="params.requestStatus === -1"
          class="par par--small guts-p-b-half"
        >
          <button
            type="button"
            class="link font-semibold"
            (click)="onRespondToGroupInviteClick($event, true)"
            translate="requestGroupMembership_Accept"
            data-dgat="notificationFeed-05e"
          ></button>
          |
          <button
            type="button"
            class="link font-semibold"
            (click)="onRespondToGroupInviteClick($event, false)"
            translate="requestGroupMembership_Decline"
            data-dgat="notificationFeed-4e5"
          ></button>
        </div>
        <div *ngIf="params.person">
          <div class="ib guts-m-r-half" *ngIf="isSingular">
            <a
              [target]="navigationTarget"
              [href]="params.person.userProfileUrl"
              data-dgat="notificationFeed-c88"
              (click)="
                stopProfileEventPropagation(
                  $event,
                  params.person.userProfileUrl
                )
              "
            >
              <div>
                <dgx-profile-pic [profile]="params.person" size="tiny">
                </dgx-profile-pic>
              </div>
            </a>
          </div>
          <div class="ib guts-m-r-half" *ngIf="isPlural">
            <div *ngFor="let person of people" data-dgat="notificationFeed-c70">
              <a
                [target]="navigationTarget"
                [href]="person.userProfileUrl"
                data-dgat="notificationFeed-b20"
              >
                <div>
                  <dgx-profile-pic [profile]="person" size="tiny">
                  </dgx-profile-pic>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</li>
