import { Component, Input, OnInit } from '@angular/core';
import {
  BundleResourceSearchConfig,
  BundleResourceService,
} from '@app/channel/services/bundle-resource.service';
import { ChannelBundleApiService } from '@app/channel/services/channel-bundle-api.service';
import { ChannelBundle } from '@app/channel/services/channel-bundle.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { lazySearch } from '@dg/shared-rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export type BundleResourceType = 'Pathway' | 'Content' | 'Target';

export interface AddBundleResourceModalInputs {
  type: BundleResourceType;
  bundle: ChannelBundle;
  config?: BundleResourceSearchConfig;
  resourceList: any[];
}

@Component({
  selector: 'dgx-add-bundle-resource-modal',
  templateUrl: './add-bundle-resource-modal.component.html',
  styleUrls: ['./add-bundle-resource-modal.component.scss'],
})
export class AddBundleResourceModalComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public type: BundleResourceType;
  @Input() public bundle: ChannelBundle;
  @Input() public config: BundleResourceSearchConfig;
  @Input() public resourceList: any[];
  @Input() public addResourceText: string;

  public readonly i18n = this.translate.instant([
    'Core_AddContent',
    'Core_AddPathway',
    'Core_AddPage',
    'Channel_AddContent',
    'Channel_AddContentFromCatalog',
    'Channel_AddPathwayFromCatalog',
    'Channel_AddPageFromCatalog',
    'Channel_SearchForContent',
    'Channel_SearchForPage',
    'Channel_SearchForPathway',
    'Channel_NoContentMatchMessage',
    'Channel_NoPageMatchMessage',
    'Channel_NoPathwayMatchMessage',
    'Channel_InvalidContentSelection',
    'Channel_InvalidPageSelection',
    'Channel_InvalidPathwaySelection',
    'Channel_CouldNotSave',
    'TargetResourcesForm_Selected',
    'Core_Remove',
    'Core_Loading',
  ]);

  // dynamic i18n resources, based on the resource type
  public addFromCatalogText: string;
  public searchForText: string;
  public noMatchText: string;
  public invalidSelectionText: string;

  // form state
  public isSearching: boolean = false;
  public isSaving: boolean = false;
  public isValid: boolean = false;
  public noMatchingResults = false;
  public searchResourceInput = '';
  public selectedSearchResults: any[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private channelBundleApiService: ChannelBundleApiService,
    private bundleResourceService: BundleResourceService,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initializeLabelsForType(this.type);
  }

  public searchItemFormatter(item: any): string {
    return item?.title || '';
  }

  public getResources: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly any[]> => {
    return term.pipe(
      lazySearch(
        (t) => this.getResourcesSearch(t),
        () => true // bypassing the default filter so we can handle clearing the popover if the text is deleted
      )
    );
  };

  public removeResource(item: any): void {
    this.selectedSearchResults = this.selectedSearchResults.filter(
      (selectedItem) => selectedItem.referenceId !== item.referenceId
    );

    this.validate();
  }

  public selectResource(item: any): void {
    this.selectedSearchResults = this.selectedSearchResults.concat(item);
    this.searchResourceInput = '';

    this.validate();
  }

  /**
   * Handle the modal 'submit' action, adds the resource to the given bundle and closes the modal
   */
  public onSubmit(): void {
    if (!this.isValid) {
      return;
    }

    this.isSaving = true;

    this.channelBundleApiService
      .addResourcesToBundle({
        organizationId: this.bundle.organizationId,
        containerId: this.bundle.containerId,
        resources: this.selectedSearchResults,
      })
      .pipe(
        tap(() => {
          // Returns the selected search results to the caller
          this.activeModal.close(this.selectedSearchResults);
          this.notifier.showSuccess(
            this.translate.instant('Channel_SuccessfulAddResourcesToBundle', {
              itemCount: this.selectedSearchResults.length,
              bundleCount: 1,
            })
          );
        }),
        catchAndSurfaceError(this.i18n.Channel_CouldNotSave),
        finalize(() => {
          this.isSaving = false;
        }),
        this.takeUntilDestroyed()
      )
      .subscribe();
  }

  /**
   * Backend fetch for resources of a type that match the given search term
   */
  private getResourcesSearch(term: string): Observable<any[]> {
    // Handle the search term reset
    if (term === '') {
      this.noMatchingResults = false;
      return of([]);
    }

    this.isSearching = true;

    const exclusionList = this.bundleResourceService.getExclusionList({
      selectedSearchResults: this.selectedSearchResults,
      resourceList: this.resourceList,
    });

    // get the search handler for the given type
    const searchByType = this.bundleResourceService.search[this.type];
    return searchByType({
      value: term,
      exclusionList,
      orgId: this.bundle.organizationId,
      config: this.config,
    }).pipe(
      tap((results: any[]) => {
        this.noMatchingResults = results.length === 0;
      }),
      finalize(() => (this.isSearching = false))
    );
  }

  /**
   * Set up the labels for the modal, based on the resource type given
   */
  private initializeLabelsForType(type: BundleResourceType): void {
    if (type === 'Target') {
      this.addResourceText = this.translate.instant(
        'Channel_AddPlanOrDirectory'
      );
      this.addFromCatalogText = this.translate.instant(
        'Channel_AddPlanOrDirectory'
      );
      this.searchForText = this.translate.instant(
        'Channel_SearchForPlanOrDirectory'
      );
      this.noMatchText = this.i18n[`Channel_NoContentMatchMessage`];
      this.invalidSelectionText = this.i18n[`Channel_InvalidContentSelection`];
    } else {
      const addResourceText = this.addResourceText
        ? this.i18n[this.addResourceText]
        : this.i18n[`Core_Add${type}`];
      this.addResourceText = addResourceText;
      this.addFromCatalogText = this.i18n[`Channel_Add${type}FromCatalog`];
      this.searchForText = this.i18n[`Channel_SearchFor${type}`];
      this.noMatchText = this.i18n[`Channel_No${type}MatchMessage`];
      this.invalidSelectionText = this.i18n[`Channel_Invalid${type}Selection`];
    }
  }

  /** Validate the form */
  private validate() {
    this.isValid = this.selectedSearchResults.length > 0;
  }
}
