<dgx-modal
  [canCancel]="true"
  [item]="{
    opportunity: opportunity,
    notifyUnSelectedCandidates: notifyUnSelectedCandidates,
    messageToUnSelectedCandidates: rejectionMessage || ''
  }"
  [submitButtonType]="submitButtonType"
  [submitButtonText]="submitButtonText"
>
  <ng-container class="modal-header">{{ headerText }}</ng-container>
  <ng-container class="modal-body">
    <df-local-notification
      [type]="NotificationType.info"
      size="small"
      [text]="bodyText"
    ></df-local-notification>

    <div class="guts-m-t-2 l_flexbar">
      <input
        [checked]="notifyUnSelectedCandidates"
        (change)="toggleUnSelectedCandidatesNotification()"
        type="checkbox"
        id="sendUnSelectedCandidatesNotification"
        name="sendUnSelectedCandidatesNotification"
        class="guts-m-r-1"
        data-dgat="modal-c92"
      />
      <label
        for="sendUnSelectedCandidatesNotification"
        class="font-regular color-ebony"
      >
        {{ bodySendNotificationText }}
      </label>
    </div>
    <ng-container *ngIf="showRejectMessageEmail && notifyUnSelectedCandidates">
      <div class="l_flexbar l_flex-justify guts-m-t-2">
        <label for="rejectionMessage">
          {{ i18n.Opportunities_Candidates_Email_RejectionMessage }}</label
        >
        <span class="par par--small color-ebony-a61">{{
          i18n.Core_Optional
        }}</span>
      </div>
      <textarea
        id="rejectionMessage"
        [(ngModel)]="rejectionMessage"
        class="guts-p-t-quart"
        maxlength="250"
        data-dgat="award-opportunity-modal-2d9"
      ></textarea>
    </ng-container>
  </ng-container>
</dgx-modal>
