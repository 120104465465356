<ul
  class="
    pathway-section-meta-data
    meta-list meta-list--alt meta-list--bullets
    guts-p-t-half
  "
>
  <li *ngIf="subsectionTotalText">
    {{ subsectionTotalText }}
  </li>
  <li *ngIf="itemTotalText">
    {{ itemTotalText }}
  </li>
  <ng-container *ngIf="!hideDuration">
    <ng-container *dgxFeatureToggle="'add-edit-content-duration'; hidden: true">
      <li *ngIf="section.durationDisplay">
        {{ section.durationDisplay }}
      </li>
    </ng-container>
    <ng-container *dgxFeatureToggle="'add-edit-content-duration'">
      <li *ngIf="durationHours">
        {{ durationHours }}
      </li>
    </ng-container>
    <ng-container *dgxFeatureToggle="'add-edit-content-duration'">
      <li *ngIf="durationMinutes">
        {{ durationMinutes }}
      </li></ng-container
    >
  </ng-container>
  <li *ngIf="optionalText">
    {{ optionalText }}
  </li>
</ul>
