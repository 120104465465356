import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  GlobalAddInputAction,
  GlobalAddOutcomeAction,
} from '@app/global-add/global-add.model';
import { GlobalAddService } from '@app/global-add/services/global-add.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import { PathwayAddEditFormModalService } from '@app/pathways/services/pathway-add-edit-form-modal-service';
import { UserOutcomeModalService } from '@app/outcomes/services/user-outcome-modal.service';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { InputModalDispatcherService } from '@app/user-content/user-input/services/input-modal-dispatcher.service';
import { InputType } from '@app/shared/models/core-api.model';
import { LDFlagsService, TrackerService } from '@dg/shared-services';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';
import { OutcomeType } from '@app/outcomes/outcomes-api.model';

@Component({
  selector: 'dgx-global-add-menu',
  templateUrl: 'global-add-menu.component.html',
  styleUrls: ['./global-add-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAddMenuComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() placement: 'auto' | 'right' = 'auto';
  @Input() placementOffsetLeftPixels: number = 0;

  public outcomeActions: Partial<GlobalAddOutcomeAction>[];
  public inputActions: Partial<GlobalAddInputAction>[];
  public i18n = this.translateService.instant([
    'dgUtilityBar_AddToProfileTitle',
    'dgGlobalAdd_Content',
    'dgGlobalAdd_Description',
    'dgGlobalAdd_Achievement',
    'dgGlobalAdd_AchievementDescription',
  ]);
  public authUser: AuthUser;
  public showIntegrationsSection: boolean;
  public extensionsText: string;
  public integrationsText: string;

  @ViewChild('globalAddMenuButton') public menuButtonRef: TemplateRef<any>;

  private isChannelContext: boolean;

  constructor(
    private authService: AuthService,
    private contextService: ContextService,
    private globalAddService: GlobalAddService,
    private translateService: TranslateService,
    private pathwayAddEditModalService: PathwayAddEditFormModalService,
    private genericInputModalService: InputModalDispatcherService,
    private userOutcomeModalService: UserOutcomeModalService,
    private userOutcomeService: UserOutcomeService,
    private trackerService: TrackerService,
    private ldFlagsService: LDFlagsService,
    private globalAddTrackingService: GlobalAddTrackingService
  ) {
    super();
  }

  public ngOnInit() {
    this.isChannelContext = this.contextService.urlHasContext(
      location.href,
      'channel'
    );
    this.authService.authUser$
      .pipe(this.takeUntilDestroyed())
      .subscribe((authUser) => {
        this.authUser = authUser;
        this.showIntegrationsSection =
          !this.authUser?.orgInfo[0]?.settings
            ?.hidePersonalIntegrationsInUserProfileSettings;
      });

    this.inputActions = this.globalAddService.globalAddInputs
      .filter((input) => input.type !== 'Task')
      .map((input) => {
        return {
          ...input,
          ...{
            handlerFn: this.handleInput,
            dgat: 'globalAdd-69e',
          },
        };
      });
    this.inputActions.push({
      type: 'CreatePathway',
      handlerFn: this.createPathway,
      dgat: 'globalAdd-843',
    });
    this.outcomeActions = this.globalAddService.globalAddOutcomes.map(
      (outcome) => {
        return {
          ...outcome,
          ...{
            handlerFn: this.handleOutcome,
            dgat: 'globalAdd-21a',
          },
        };
      }
    );

    const settingsUrl = '/me/settings#';

    const hideExtension =
      this.authUser?.defaultOrgInfo?.settings?.restrictExtension;
    this.extensionsText = hideExtension
      ? ''
      : this.translateService.instant('dgGlobalAdd_ExtensionsFormat', {
          startAnchor: `<a href="${settingsUrl}/extensions" class="link" data-dgat="globalAdd-78c">`,
          endAnchor: '</a>',
        });

    this.integrationsText = this.translateService.instant(
      'dgGlobalAdd_IntegrationsFormat',
      {
        startAnchor: `<a href="${settingsUrl}/integrations" class="link" data-dgat="globalAdd-ddd">`,
        endAnchor: '</a>',
      }
    );
  }

  // arrow function for context
  public handleInput = (event: Event, input: GlobalAddInputAction) => {
    this.genericInputModalService
      .complete({
        inputType: input.type as InputType,
        // can't use the event.target because the popover with the buttons disappears
        sourceTarget: this.menuButtonRef.elementRef.nativeElement,
        trackingArea: 'Global Add',
      })
      .pipe(this.takeUntilDestroyed());
    this.globalAddTrackingService.trackGlobalAddItemClick(
      input.type as InputType
    );
  };

  // arrow function for context
  public handleOutcome = (event: Event, outcome: GlobalAddOutcomeAction) => {
    this.userOutcomeModalService
      .add({
        outcomeType: outcome.type,
        // can't use the event.target because the popover with the buttons disappears
        sourceTarget: this.menuButtonRef.elementRef.nativeElement,
        trackingAction: 'Achievement Added',
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        if (!this.ldFlagsService.showUpdatedAchievementModals) {
          this.userOutcomeService.notifyOutcomeModified(outcome.type);
        }
      });
    this.globalAddTrackingService.trackGlobalAddItemClick(
      outcome.type as OutcomeType
    );
  };

  // arrow function for maintaining context coming from ajs
  public createPathway = () => {
    const options = {
      pathId: 0,
      forCloning: false,
      itemToAdd: null,
      isChannel: this.isChannelContext,
      disableEndorsedOption: true,
      trackingArea: 'Global Add',
    };

    this.pathwayAddEditModalService.openModal(options).subscribe();
  };

  public trackGlobalAddClicked() {
    this.trackerService.trackEventData({
      action: 'Global Add Clicked',
      properties: {},
    });
  }
}
