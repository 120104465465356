import { Injectable } from '@angular/core';
import {
  BadgeFormDataModel,
  BadgeMappingToAPI,
  BadgeModel,
  BadgeInfo,
} from '@app/user-content/user-outcome-v2/outcomes/badge/badge.model';
import { DatePipe } from '@angular/common';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';

@Injectable()
export class BadgeMapperService {
  constructor(public datePipe: DatePipe) {}

  public toApiParameters(source: BadgeModel): BadgeMappingToAPI {
    const { skills } = source;

    return {
      contentTypeId: 'Badge',
      contentType: 'Badge',
      userOutcomeId: source.userOutcomeId,
      tags: skills || [],
      startDate: this.datePipe.transform(
        source.dateRangeForm.startDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        source.dateRangeForm.endDate,
        'yyyy-MM-dd'
      ),
      url: source.issuerUrl,
      imageUrl: source.badgeImageUrl,
      title: '',
      details: JSON.stringify(source.details),
    };
  }

  public toViewModel(source: UserOutcomeDetail<BadgeInfo>): BadgeFormDataModel {
    return {
      skills: source.tags,
      dateRangeForm: {
        startDate: new Date(source.startDate),
        endDate: new Date(source.endDate),
      },
      issuerUrl: source.url,
      issuer: source?.details?.issuerinfo?.name,
      badgeImageUrl: source.imageUrl,
      badgeUrl: source.url,
      details: source.details || ({} as BadgeInfo),
      userOutcomeId: source?.userOutcomeId,
    };
  }
}
