<!--global notification menu button-->
<button
  class="btn btn-utility rounded--large notification-menu__trigger"
  df-button
  dfButtonType="passive"
  attr.aria-label="{{ newNotificationCountLabel }}"
  title="{{ newNotificationCountLabel }}"
  data-dgat="utilityBar-364"
  [dfPopoverTrigger]="menuPopover"
  [attr.tabindex]="refreshIcon ? '-1' : undefined"
>
  <span
    *ngIf="state.newNotificationCount && hasNotificationsFormat === 'count'"
    class="zeta count-bubble"
    >{{ newNotificationCount }}
  </span>
  <span
    *ngIf="state.newNotificationCount && hasNotificationsFormat === 'dot'"
    class="notification-dot"
  ></span>
  <df-icon *ngIf="!refreshIcon" [size]="iconSize" icon="bell"></df-icon>
  <svg
    *ngIf="refreshIcon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    height="2rem"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
    />
  </svg>
</button>

<!-- disablePopover is a bit of a hack since we don't have a way to disable popovers yet -->
<df-popover
  #menuPopover
  (isOpenChange)="onIsOpenChange($event)"
  [style.visibility]="disablePopover ? 'hidden' : 'visible'"
  [placement]="placement"
>
  <div class="notification-menu" role="menu" *ngIf="!disablePopover">
    <div class="guts-m-h-1 guts-p-t-1-half guts-p-b-half border-bottom">
      <h1
        class="h4"
        tabindex="-1"
        translate="dgNotificationMenu_Notifications"
      ></h1>
    </div>
    <div class="scrollable guts-p-b-half">
      <div *ngIf="isLoading" class="guts-m-v-2">
        <df-spinner></df-spinner>
      </div>
      <div *ngIf="hasRequiredLearning && !isLoading">
        <section
          class="guts-p-full-1 guts-p-b-half"
          data-dgat="notificationMenu-ecc"
        >
          <div class="l_flexbar border-bottom guts-p-b-1">
            <div class="l_flex-top guts-p-r-1"></div>
            <df-icon
              [icon]="notificationService.lookupIcon('requiredLearning')"
            ></df-icon>
            <div class="l_flex-grow">
              <div class="guts-p-b-half">
                <p class="par par--small guts-p-b-0">
                  <a
                    [href]="assignedLearningLink"
                    data-dgat="notificationMenu-00a"
                  >
                    {{ state.requiredLearningPhrase }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div *ngIf="showNotifications">
        <dgx-notification-list
          [maxTitleLines]="1"
          [maxCommentLines]="maxCommentLines"
          [groupByDate]="false"
          [shouldLoadNotifications]="false"
          (isLoadingChange)="isLoading = $event"
        >
        </dgx-notification-list>
      </div>
      <div *ngIf="state.notifications?.length === 0 && !isLoading">
        <p
          class="guts-p-full-1 par par--small"
          translate="dgNotificationMenu_NothingNew"
        ></p>
      </div>
    </div>
    <div class="center-text border-top" *ngIf="showNotifications">
      <a
        href="/notifications"
        class="guts-p-full-1 block link par par--small"
        translate="Core_ViewAll"
        data-dgat="notificationMenu-b4d"
      ></a>
    </div>
  </div>
</df-popover>
