<div class="l_flexbar">
  <input
    #field
    type="text"
    [id]="hostId"
    [name]="hostId"
    [placeholder]="placeholder"
    [required]="required"
    [attr.data-dgat]="dgatInput"
    [attr.aria-describedby]="ariaDescribedby"
    autocomplete="off"
    [ngbTypeahead]="suggestAddress"
    [resultFormatter]="formatResults"
    [inputFormatter]="formatResults"
    [disabled]="disabled"
    (selectItem)="onSelectItem($event)"
    (input)="propagateChange($event)"
    (blur)="onBlur($event)"
  />
  <df-spinner *ngIf="isSearching" spinnerContext="textInput"></df-spinner>
</div>
