import { ElementRef, EventEmitter, Inject, Injectable } from '@angular/core';
import { FeedbackService } from '@app/feedback/services/feedback.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { Opportunity } from '../opportunities-api.model';
import { OpportunityFlagsService } from './opportunity-flags.service';
import { OpportunityModalsService } from './opportunity-modals.service';
import { OpportunityPermissionsService } from './opportunity-permissions.service';
import { OpportunityViewService } from './opportunity-view.service';

import {
  hasExternalProvider,
  isOpportunityQueued,
  isUserInterested,
  isUserSelected,
} from '@app/opportunities/utils';
import { OpportunityTrackingService } from './opportunity-tracking.service';
import { WindowToken } from '@app/shared/window.token';
import { finalize } from 'rxjs/operators';
import { getDeepCopy } from '@app/shared/utils/property';
import { OpportunityApiService } from './opportunity-api.service';
import {
  OpportunityMenuConfig,
  OpportunityMenuOption,
  OpportunityMenuTypeEnum,
} from '../opportunity-menu.model';

@Injectable({
  providedIn: 'root',
})
export class OpportunityMenuService {
  public homeFeedMenu: OpportunityMenuConfig = {
    menuConfigName: OpportunityMenuTypeEnum.HomeFeedMenu,
    menuConfig: [
      OpportunityMenuOption.Apply,
      OpportunityMenuOption.ShowInterest,
      OpportunityMenuOption.RemoveInterest,
      OpportunityMenuOption.SaveForLater,
      OpportunityMenuOption.Unsave,
      OpportunityMenuOption.Share,
      OpportunityMenuOption.Dismiss,
      OpportunityMenuOption.ReportAProblem,
    ],
  };

  public overviewHeaderMenu: OpportunityMenuConfig = {
    menuConfigName: OpportunityMenuTypeEnum.OverviewHeaderMenu,
    menuConfig: [
      OpportunityMenuOption.SaveForLater,
      OpportunityMenuOption.Unsave,
      OpportunityMenuOption.ReportAProblem,
      OpportunityMenuOption.Separator,
      OpportunityMenuOption.EditDetails,
      OpportunityMenuOption.Clone,
      OpportunityMenuOption.Close,
      OpportunityMenuOption.Delete,
    ],
  };

  public cardMenu: OpportunityMenuConfig = {
    menuConfigName: OpportunityMenuTypeEnum.CardMenu,
    menuConfig: [
      OpportunityMenuOption.Apply,
      OpportunityMenuOption.ShowInterest,
      OpportunityMenuOption.RemoveInterest,
      OpportunityMenuOption.SaveForLater,
      OpportunityMenuOption.Unsave,
      OpportunityMenuOption.Share,
      OpportunityMenuOption.ReportAProblem,
    ],
  };

  // Used for browse list/card view
  public browseListMenu: OpportunityMenuConfig = {
    menuConfigName: OpportunityMenuTypeEnum.BrowseListMenu,
    menuConfig: [
      OpportunityMenuOption.Apply,
      OpportunityMenuOption.ShowInterest,
      OpportunityMenuOption.RemoveInterest,
      OpportunityMenuOption.SaveForLater,
      OpportunityMenuOption.Unsave,
      OpportunityMenuOption.EditDetails,
      OpportunityMenuOption.Clone,
      OpportunityMenuOption.Share,
      OpportunityMenuOption.Close,
      OpportunityMenuOption.Delete,
      OpportunityMenuOption.ReportAProblem,
    ],
  };
  private useSmarterMatching = this.opportunityFlagsService.useSmarterMatching;
  private i18n = this.translateService.instant([
    'Opportunities_Apply',
    'Opportunities_ShowInterest',
    'Opportunities_RemoveInterest',
    'Core_Clone',
    'Core_Close',
    'Core_Delete',
    'Core_Dismiss',
    'Core_EditDetails',
    'Core_MoreOptions',
    'Core_Recommend',
    'Core_ReportAProblem',
    'Core_SaveForLater',
    'Core_Share',
    'Core_Unsave',
  ]);

  constructor(
    private feedbackService: FeedbackService,
    private focusStackService: FocusStackService,
    private opportunityApiService: OpportunityApiService,
    private opportunityFlagsService: OpportunityFlagsService,
    private opportunityModalsService: OpportunityModalsService,
    private opportunityPermissionsService: OpportunityPermissionsService,
    private opportunityTrackingService: OpportunityTrackingService,
    private opportunityViewService: OpportunityViewService,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public getMenuConfig(
    menuType: OpportunityMenuTypeEnum,
    opportunity: Opportunity,
    dismiss?: EventEmitter<any>,
    customViewUpdateMethod?: () => void,
    isBrowsePreviewView = false
  ): MenuViewModel[] {
    const menuSetup: MenuViewModel[] = this.getMenuSetup(
      opportunity,
      dismiss,
      customViewUpdateMethod,
      isBrowsePreviewView
    );

    const menuOption = this.getMenuType(menuType);

    const menu: MenuViewModel[] = [];
    let separateNextOption: boolean = false;
    // Get the menu options *only* passed by menuType
    menuOption.menuConfig.forEach((option: OpportunityMenuOption) => {
      // find the menuSetup options id through the OpportunityMenuOption
      menuSetup.find((options: MenuViewModel) => {
        // if the option is found..
        if (option === options.id) {
          // and if the option is not hidden and
          // if this option should have a separator
          if (
            separateNextOption &&
            (options.isHidden === undefined || !options.isHidden())
          ) {
            // ..set the property handling the separator to true
            options.isSeparated = true;
            separateNextOption = false;
          }

          // ..then add it to the menu
          menu.push(options);
        }
      });

      // the menu separator is added at the top of a menu item,
      // do this check to prepare the next menu option with a separator
      if (option === OpportunityMenuOption.Separator) {
        separateNextOption = true;
      }
    });

    // Return the menu configuration for the menu type
    return menu;
  }

  public getMenuSetup(
    opportunity: Opportunity,
    dismiss?: EventEmitter<any>,
    customViewUpdateMethod?: () => void,
    isBrowsePreviewView = false
  ) {
    return [
      // Apply
      {
        id: OpportunityMenuOption.Apply,
        title: this.i18n.Opportunities_Apply,
        isHidden: () =>
          !this.opportunityPermissionsService.canApply(opportunity),
        defaultAction: () => this.openExternalLink(opportunity),
        preventRefocus: !hasExternalProvider(opportunity),
      },
      // Show Interest
      {
        id: OpportunityMenuOption.ShowInterest,
        title: this.i18n.Opportunities_ShowInterest,
        isHidden: () =>
          !this.opportunityPermissionsService.canShowInterest(opportunity),
        defaultAction: (_, trigger: ElementRef) =>
          this.updateInterest(opportunity, trigger, customViewUpdateMethod),
        preventRefocus: !hasExternalProvider(opportunity),
      },
      // Remove Interest
      {
        id: OpportunityMenuOption.RemoveInterest,
        title: this.i18n.Opportunities_RemoveInterest,
        isHidden: () =>
          hasExternalProvider(opportunity) ||
          !isUserInterested(opportunity) ||
          isUserSelected(opportunity),
        defaultAction: (_, trigger: ElementRef) =>
          this.updateInterest(opportunity, trigger, customViewUpdateMethod),
      },
      // Save for Later
      {
        id: OpportunityMenuOption.SaveForLater,
        title: this.i18n.Core_SaveForLater,
        isHidden: () =>
          !this.opportunityPermissionsService.showQueueOption(opportunity),
        defaultAction: (_, trigger: ElementRef) =>
          this.saveForLater(opportunity, trigger.nativeElement),
      },
      // Unsave
      {
        id: OpportunityMenuOption.Unsave,
        title: this.i18n.Core_Unsave,
        isHidden: () => !isOpportunityQueued(opportunity),
        defaultAction: (_, trigger: ElementRef) =>
          this.unSaveForLater(opportunity, trigger.nativeElement),
      },
      // Edit Details
      {
        id: OpportunityMenuOption.EditDetails,
        title: this.i18n.Core_EditDetails,
        defaultAction: (_, trigger: ElementRef) =>
          this.showEditModal(opportunity, trigger, customViewUpdateMethod),
        isHidden: () =>
          !this.opportunityPermissionsService.canEdit(opportunity),
        preventRefocus: true,
      },
      // Clone
      {
        id: OpportunityMenuOption.Clone,
        title: this.i18n.Core_Clone,
        defaultAction: (_, trigger: ElementRef) =>
          this.showCloneModal(opportunity, trigger),
        isHidden: () =>
          !this.opportunityPermissionsService.canClone(opportunity),
        preventRefocus: true,
      },
      // Share
      {
        id: OpportunityMenuOption.Share,
        title: this.i18n.Core_Share,
        defaultAction: (_, popoverTrigger: ElementRef) =>
          this.showShareModal(opportunity, popoverTrigger.nativeElement),
        isHidden: () =>
          !this.opportunityPermissionsService.canShare(opportunity),
        preventRefocus: true,
      },
      // Close
      {
        id: OpportunityMenuOption.Close,
        title: this.i18n.Core_Close,
        defaultAction: (_, trigger: ElementRef) =>
          this.showCloseModal(opportunity, trigger, customViewUpdateMethod),
        isHidden: () =>
          !this.opportunityPermissionsService.canClose(opportunity),
        preventRefocus: true,
      },
      // Delete
      {
        id: OpportunityMenuOption.Delete,
        title: this.i18n.Core_Delete,
        defaultAction: (_, trigger: ElementRef) =>
          this.showDeleteModal(
            opportunity,
            trigger,
            customViewUpdateMethod,
            isBrowsePreviewView
          ),
        isHidden: () =>
          !this.opportunityPermissionsService.canDelete(opportunity),
        preventRefocus: true,
      },
      // Dismiss
      {
        id: OpportunityMenuOption.Dismiss,
        title: this.i18n.Core_Dismiss,
        // elementRef.nativeElement may be null, if used by server-side Angular
        // but it's only here for optional tracking anyway, and we wouldn't want
        // the information in a server-side situation
        defaultAction: (event: Event, trigger: ElementRef) =>
          this.handleDismiss(event, trigger, dismiss),
        // `.observers.length` will be -1 where the dismiss property
        // has been left off, which will hide this option.
        isHidden: () => !dismiss.observers?.length,
      },
      // Report a Problem
      {
        id: OpportunityMenuOption.ReportAProblem,
        title: this.i18n.Core_ReportAProblem,
        defaultAction: (_, trigger: ElementRef) =>
          this.showReportModal(opportunity, trigger),
        preventRefocus: true,
      },
    ];
  }

  /**
   * Open a new window for external opportunity application.
   */
  public openExternalLink(opportunity: Opportunity): void {
    if (
      !(
        opportunity.url?.includes('http://') ||
        opportunity.url?.includes('https://')
      )
    ) {
      opportunity = {
        ...opportunity,
        url: `https://${this.windowRef.location.host}/opportunities/${opportunity.url}`,
      };
    }

    this.windowRef.open(opportunity.url, '_blank');
    this.opportunityModalsService.apply(opportunity).subscribe();

    this.opportunityTrackingService.trackOpportunity(
      opportunity,
      'Opportunity Interested'
    );
  }

  /**
   * Toggles the 'interested' status on internal opportunities
   */
  public updateInterest(
    opportunity: Opportunity,
    trigger?: ElementRef,
    customViewUpdateMethod?: () => void
  ) {
    // capture updated interest
    const isNowInterested = !isUserInterested(opportunity);
    const trackedAction = isNowInterested
      ? 'Opportunity Interested'
      : 'Opportunity Uninterested';

    this.opportunityModalsService
      .updateInterest({
        opportunity: opportunity,
        wasInterested: !isNowInterested,
      })
      .pipe(
        tap(() => {
          this.opportunityTrackingService.trackOpportunity(
            opportunity,
            trackedAction,
            {
              // update this value manually because it will not be updated
              // on the actual item for external opportunities
              isInterested: isNowInterested,
            }
          );
        }),
        finalize(() => {
          if (trigger) {
            this.focusStackService.push(trigger.nativeElement);
            this.focusStackService.pop();
          }
        })
      )
      .subscribe(({ applicationStatus }: Opportunity) => {
        // update opportunity status
        opportunity.applicationStatus = applicationStatus;

        // Update the view.
        if (customViewUpdateMethod) {
          customViewUpdateMethod();
        } else {
          this.opportunityViewService.rehydrateOpportunity(opportunity);
        }

        // handle focus -- only needed in case of showing the
        // user the prompt to update their skills.
        if (trigger) {
          this.focusStackService.push(trigger.nativeElement);
          this.focusStackService.pop();
        }
      });
  }

  /**
   * Add an opportunity to the user's queue.
   */
  public saveForLater(opportunity: Opportunity, element: HTMLElement): void {
    this.opportunityApiService
      .addToQueue({
        element,
        opportunity,
      })
      .subscribe((userQueueId) => {
        opportunity.isQueued = true;
        opportunity.userQueueId = userQueueId;
      });
  }

  /**
   * Remove an opportunity from the user's queue.
   */
  public unSaveForLater(opportunity: Opportunity, element: HTMLElement): void {
    this.opportunityApiService
      .removeFromQueue({
        element,
        opportunity: opportunity,
      })
      .subscribe(() => {
        opportunity.isQueued = false;
        opportunity.userQueueId = null;
      });
  }

  /**
   * Open the edit modal for a given opportunity.
   */
  public showEditModal(
    opportunity: Opportunity,
    trigger: ElementRef,
    customViewUpdateMethod?: () => void
  ) {
    this.setFocusStack(trigger);

    this.opportunityModalsService
      .showAddEditModal({
        fetchExtraData: true,
        isEditing: true,
        opportunity,
      })
      .pipe(
        tap((opportunity) => {
          this.opportunityTrackingService.trackOpportunity(
            opportunity,
            'Opportunity Edited'
          );
          // Update the view.
          if (customViewUpdateMethod) {
            return customViewUpdateMethod();
          }
          this.opportunityViewService.rehydrateOpportunity(opportunity);
        })
      )
      .subscribe();
  }

  /**
   * Open the clone modal for a given opportunity.
   */
  public showCloneModal(opportunity: Opportunity, trigger: ElementRef): void {
    const originalOpportunity = getDeepCopy(opportunity);
    this.setFocusStack(trigger);

    this.opportunityModalsService
      .showCloneModal({
        fetchExtraData: true,
        opportunity: originalOpportunity,
      })
      .pipe(
        tap(() => {
          this.opportunityTrackingService.trackOpportunity(
            originalOpportunity,
            'Opportunity Cloned'
          );
        })
      )
      .subscribe(({ opportunityId }) => {
        // success toast handled by service, so we just track here
        // navigate to new opportunity
        this.opportunityViewService.navigate(opportunityId);
      });
  }

  /**
   * Open the share modal for a given opportunity.
   */
  public showShareModal(opportunity: Opportunity, element?: HTMLElement): void {
    this.opportunityModalsService
      .showShareModal(opportunity, element, this.useSmarterMatching)
      .subscribe();
  }

  /**
   * Open the close modal for a given opportunity.
   */
  public showCloseModal(
    opportunity: Opportunity,
    trigger: ElementRef,
    customViewUpdateMethod?: () => void
  ): void {
    this.setFocusStack(trigger);

    this.opportunityModalsService
      .showCloseModal(opportunity)
      .pipe(
        tap((opportunity) => {
          this.opportunityTrackingService.trackOpportunity(
            opportunity,
            'Opportunity Closed'
          );
          // Update the view.
          if (customViewUpdateMethod) {
            return customViewUpdateMethod();
          }
          this.opportunityViewService.rehydrateOpportunity(opportunity);
        })
      )
      .subscribe();
  }

  /**
   * Open the delete modal for a given opportunity.
   */
  public showDeleteModal(
    opportunity: Opportunity,
    trigger: ElementRef,
    customViewUpdateMethod?: () => void,
    isBrowsePreviewView = false
  ): void {
    this.setFocusStack(trigger);

    this.opportunityModalsService
      .showDeleteOpportunitiesModal([opportunity])
      .pipe(
        tap(() => {
          this.opportunityTrackingService.trackOpportunity(
            opportunity,
            'Org Opportunity Removed'
          );
        })
      )
      .subscribe((deletedOpportunityIds) => {
        // Custom updates.
        if (customViewUpdateMethod) {
          return customViewUpdateMethod();
        }
        // Otherwise, we're done with our stream and there's nothing else
        // to do here except...
        if (!isBrowsePreviewView) {
          // Attempt to navigate back in the browser
          return (this.windowRef.location.href =
            '/career/opportunities/browse');
        }
        // Updates for the browse view, which we should be in if we're still here
        this.opportunityViewService.updateDeletedOpportunityIds([
          deletedOpportunityIds[0],
        ]);
      });
  }

  /**
   * Dismisses an opportunity, typically on the home feed.
   * Wrap `dismiss` action and prevent the click event from bubbling up
   * (causing multiple incorrect tracked clicks).
   */
  public handleDismiss(
    event: Event,
    trigger: ElementRef,
    dismiss: EventEmitter<any>
  ): void {
    event.stopPropagation();
    dismiss.emit(trigger);
  }

  /**
   * Report a problem with a given opportunity.
   */
  public showReportModal(opportunity: Opportunity, trigger: ElementRef): void {
    this.feedbackService
      .showReportProblemModal(
        {
          itemType: this.feedbackService.feedbackItemType.Opportunity,
          itemId: opportunity.opportunityId,
        },
        trigger.nativeElement
      )
      .pipe(
        tap(() => {
          this.opportunityTrackingService.trackOpportunity(
            opportunity,
            'Opportunity Reported'
          );
        })
      )
      .subscribe();
  }

  private setFocusStack(trigger: ElementRef): void {
    this.focusStackService.push(trigger.nativeElement);
    this.focusStackService.pop();
  }

  private getMenuType(menuType: OpportunityMenuTypeEnum) {
    switch (menuType) {
      case OpportunityMenuTypeEnum.CardMenu:
        return this.cardMenu;
      case OpportunityMenuTypeEnum.BrowseListMenu:
        return this.browseListMenu;
      case OpportunityMenuTypeEnum.HomeFeedMenu:
        return this.homeFeedMenu;
      case OpportunityMenuTypeEnum.OverviewHeaderMenu:
        return this.overviewHeaderMenu;
    }
  }
}
