import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';

import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { NotificationItemService } from './notification-item.service';

/** Represents a New Target Resource notification */
@Component({
  selector: 'dgx-new-target-resource',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTargetResourceComponent extends NotificationItemComponentBase {
  constructor(
    cdr: ChangeDetectorRef,
    itemService: NotificationItemService,
    private displayTypePipe: DisplayTypePipe
  ) {
    super(cdr, itemService);
  }

  protected buildTemplate() {
    return this.translateHtml('newTargetResource_Description', {
      targetAuthor: this
        .html`<span class="font-semibold">${this.params.targetAuthor}</span>`,
      targetType: this.displayTypePipe
        .transform(this.params.targetType)
        .toLowerCase(),
      targetTitle: this.params.targetTitle,
    });
  }
}
