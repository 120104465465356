import { AuthUser } from '@app/account/account-api.model';
import {
  MarketplaceSection,
  Opportunity,
  Provider,
  Skill,
} from '@app/opportunities/opportunities-api.model';
import { OpportunityGetResponse } from '@app/opportunities/services/browse-opportunities.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { getMatchingSkillsCount } from './methods';

/**
 * Format opportunities for the marketplace endpoint.
 *
 * @param items - The opportunities returned.
 * @param totalCount - The total number of opportunities
 */
export function formatMarketplaceOpportunities(
  { items, totalCount }: OpportunityGetResponse,
  section: MarketplaceSection<Opportunity>
) {
  return {
    ...section,
    items: items.map((item) => ({
      ...item,
      authors: formatAuthors(item.authors),
      source: formatSource(item.provider),
      tags: formatTags(item.tags),
    })),
    totalCount,
    isLoading: false,
  };
}

/**
 * Format opportunities for the marketplace endpoint.
 *
 * @param items - The opportunities returned.
 * @param totalCount - The total number of opportunities
 * @param filters - The existing filters from the FE.
 * @param authUser - The currently logged-in user.
 */
export function formatOrgOpportunities(
  { items, totalCount }: OpportunityGetResponse,
  filters: any[],
  authUser: AuthUser
) {
  return {
    items: items.map((item) => ({
      ...item,
      authors: formatAuthors(item.authors),
      interestedApplicants: formatApplicants(item.interestedApplicants),
      // TODO: Remove `getMatchingSkillsCount` once matchingSkills is returned consistently.
      matchingSkills: getMatchingSkillsCount(authUser, item),
      source: formatSource(item.provider),
      tags: formatTags(item.tags),
    })),
    totalCount,
    // reorder facets, then turn them into filters
    // because dataFilters will spit facets out back
    // in the same order we get them from the server
    filters,
  };
}

function formatApplicants(
  interestedApplicants: UserSearchItem[]
): UserSearchItem[] {
  return interestedApplicants
    ? interestedApplicants.sort((a, b) => a.name.localeCompare(b.name))
    : [];
}

function formatAuthors(authors: UserSearchItem[]): UserSearchItem[] {
  return authors
    ?.map((author) => ({
      ...author,
      vanityUrl: `/${author.vanityUrl}`,
    }))
    .sort((a, b) => a.name?.localeCompare(b.name));
}

function formatSource(provider: Provider): string {
  return provider?.id ? provider.name : 'Degreed';
}

function formatTags(tags: Skill[]) {
  return tags?.sort((a, b) => a.name?.localeCompare(b.name));
}
