import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isObservable } from 'rxjs';

function isFunction(v): v is Function {
  return typeof v === 'function';
}

/** Accepts a value that is either an object/primitive, an Observable that streams objects/primitives
 *  or a function that returns an object/primitive and evaluates accordingly.
 */
@Pipe({
  name: 'dfProvideString',
  pure: false, // must be impure for Observable and function provider variants
})
export class DfStringProviderPipe extends AsyncPipe implements PipeTransform {
  transform(provider: any, ...args: any[]): any {
    if (isFunction(provider)) {
      return provider(...args); // call the function
    }
    if (isObservable(provider)) {
      return super.transform(provider); // borrow the parent async pipe's magic for the Observable case
    }
    return provider; // identity transform for the string case
  }
}
