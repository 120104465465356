import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AddKey,
  SortKey,
  TypeFilterConfig,
} from '@app/profile/components/profile-collection/profile-collection-reactive-store';
import {
  CollectionItemType,
  ProfileCollectionItem,
} from '@app/profile/profile-api.model';
import { Observable } from 'rxjs';
import {
  AnyInputApiEntity,
  ItemTypes,
} from '@app/user-content/user-input/user-input.model';
import { InputType, OutcomeType } from '@app/shared/models/core-api.model';
import { finalize, take } from 'rxjs/operators';
import { InputModalDispatcherService } from '@app/user-content/user-input/services/input-modal-dispatcher.service';
import { UserOutcomeModalService } from '@app/outcomes/services/user-outcome-modal.service';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { LDFlagsService, PopoverService } from '@dg/shared-services';
import { TitleCasePipe } from '@angular/common';
import { sortBy as _sortBy } from 'lodash-es';
import { checkIsRestrictedType } from './profile-collection.utils';

export const ITEM_TYPE_MAP = {
  learning: [
    'article',
    'book',
    'video',
    'course',
    'episode',
    'event',
    'assessment',
    'task',
    'post',
  ],
  achievements: ['degree', 'certificate', 'badge', 'accomplishment', 'award'],
  experiences: ['position'],
};

@Injectable()
export class ProfileCollectionUtilsService {
  public i18n = this.translateService.instant([
    'Core_Newest',
    'Core_Oldest',
    'Core_Atoz',
    'Core_Ztoa',
    'Core_Learnings',
    'Core_Achievements',
    'Core_ExperiencesDisplayName',
    'Core_Plans',
    'Core_Pathways',
    'Core_Clear',
    'Core_ClearNumberFormat',
    'dgProfileOverview_LearningTitle',
    'Core_Position',
    'Core_Academies',
  ]);

  constructor(
    private translateService: TranslateService,
    private genericInputModalService: InputModalDispatcherService,
    private userOutcomeModalService: UserOutcomeModalService,
    private userOutcomeService: UserOutcomeService,
    private popover: PopoverService,
    private titleCasePipe: TitleCasePipe,
    private ldFlagsService: LDFlagsService
  ) {}

  public get sortKeys(): SortKey[] {
    return [
      {
        label: this.i18n.Core_Newest,
        key: 'date',
        direction: 'desc',
      },
      {
        label: this.i18n.Core_Oldest,
        key: 'date',
        direction: 'asc',
      },
      {
        label: this.i18n.Core_Atoz,
        key: 'title',
        direction: 'asc',
      },
      {
        label: this.i18n.Core_Ztoa,
        key: 'title',
        direction: 'desc',
      },
    ];
  }

  public getBulkEditState(
    selectedItems?: ProfileCollectionItem<any>[],
    enabled: boolean = true
  ): any {
    const items = selectedItems;
    const count = items.length;

    return {
      bulkUpdateVM: {
        enabled,
        selectedItems: items,
        selectedItemsText: this.translateService.instant(
          'Core_ItemsSelectedFormat',
          {
            count,
          }
        ),
        clearSelectionText:
          count === 1
            ? this.translateService.instant('Core_ClearSelectionSingle')
            : this.translateService.instant('Core_ClearSelectionPluralFormat', {
                count,
              }),
        deleteModalHeaderText:
          count === 1
            ? this.translateService.instant('ProfileCtrl_BulkDeleteSingle')
            : this.translateService.instant('ProfileCtrl_BulkDeleteFormat', {
                selectedLength: count,
              }),
        deleteModalBodyText:
          count === 1
            ? this.translateService.instant(
                'ProfileCtrl_DeleteCollectionGenericItemBodySingle'
              )
            : this.translateService.instant(
                'ProfileCtrl_DeleteCollectionGenericItemBody'
              ),
      },
    };
  }

  public showAddModal(addKey: AddKey, buttonRef: HTMLElement): void {
    const showModal: Observable<AnyInputApiEntity | void> =
      addKey.itemType === ItemTypes.Input
        ? this.genericInputModalService.complete({
            inputType: addKey.key as InputType,
            sourceTarget: buttonRef,
          })
        : this.userOutcomeModalService.add({
            outcomeType: addKey.key as OutcomeType,
            sourceTarget: buttonRef,
          });

    showModal
      .pipe(
        finalize(() => {
          this.popover.close();
        })
      )
      .subscribe(() => {
        if (
          addKey.itemType === ItemTypes.Outcome &&
          !this.ldFlagsService.showUpdatedAchievementModals
        ) {
          this.userOutcomeService.notifyOutcomeModified(addKey.key);
        }
      });
  }

  public getEmptyStateTitle(classifier: string, isOwner: boolean): string {
    const key = isOwner
      ? 'UserController_AddToCollectionSelf'
      : 'UserController_AddToCollectionOther';
    return this.translateService.instant(key, {
      classifier: this.getClassifierDisplayName(classifier),
    });
  }

  public getClassifierDisplayName(classifier: string): string {
    return {
      learning: this.i18n.Core_Learnings,
      achievements: this.i18n.Core_Achievements,
      experiences: this.i18n.Core_ExperiencesDisplayName,
      plans: this.i18n.Core_Plans,
      pathways: this.i18n.Core_Pathways,
      academies: this.i18n.Core_Academies,
    }[classifier];
  }

  public getAddKeys(currentItemClassifier: string): AddKey[] {
    if (checkIsRestrictedType(currentItemClassifier)) {
      return [];
    }
    const excluded = ['task', 'post'];

    const itemTypes = ITEM_TYPE_MAP[currentItemClassifier].filter(
      (t: string) => !excluded.includes(t)
    );
    if (!itemTypes) {
      // Add button is not shown for plans and pathways
      return [];
    }
    const itemTypeNameMap = {
      learning: 'input',
      experiences: 'input',
      achievements: 'outcome',
    };
    return itemTypes.map((i: string) => {
      const key = this.titleCasePipe.transform(i);
      return {
        key,
        label: this.translateService.instant(`Core_${key}`),
        itemType: itemTypeNameMap[currentItemClassifier],
      };
    });
  }

  public getClearFilterButtonText(numValues: number): string {
    return numValues === 0
      ? this.translateService.instant('Core_Clear')
      : this.translateService.instant('Core_ClearNumberFormat', {
          numberSelected: numValues,
        });
  }

  public getTypeFilterConfig(
    inputTypes: CollectionItemType[],
    experienceTypes: CollectionItemType[],
    outcomeTypes: CollectionItemType[],
    currentItemClassifier: string
  ): TypeFilterConfig[] {
    const allFilterConfig = [
      {
        name: 'learning',
        title: this.i18n.dgProfileOverview_LearningTitle,
        itemType: 'input',
        items: _sortBy(inputTypes, ['name']),
      },
      {
        name: 'experiences',
        title: this.i18n.Core_Position,
        itemType: 'experience',
        items: _sortBy(experienceTypes, ['name']),
      },
      {
        name: 'achievements',
        title: this.i18n.Core_Achievements,
        itemType: 'outcome',
        items: _sortBy(outcomeTypes, ['name']),
      },
    ];
    const filterConfig = allFilterConfig.find(
      (config) => config.name === currentItemClassifier
    );

    return filterConfig ? [filterConfig] : [];
  }
}
