import { search } from '@app/shared/utils/common-utils';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgForm } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import {
  BehaviorSubject,
  Observable,
  debounceTime,
  distinctUntilChanged,
  of,
  tap,
} from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DialogData } from '@degreed/apollo-angular';

import { AuthUser } from '@app/account/account-api.model';
import { OrgSelectorExternalWarningService } from '@app/orgs/services/org-selector-external-warning.service';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { SearchSuggestionSource } from '@app/search/search-api.model';
import {
  SearchTrackerService,
  SearchTypeaheadInitLocations,
} from '@app/search/services';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { Key, isKey } from '@app/shared/key';
import { A11yService } from '@app/shared/services/a11y.service';
import { AuthService } from '@app/shared/services/auth.service';
import { LDFlagsService } from '@dg/shared-services';

import { SearchAutocompleteFacade } from '@app/search/components/search-initiation-autocomplete/search-autocomplete.facade';
import { SearchAutocompleteViewModel } from '@app/search/components/search-initiation-autocomplete/search-autocomplete.model';
import { keydownHandler } from '@app/search/components/search-initiation-autocomplete/search-initiation-autocomplete.helpers';

/**
 * Angular CDK Dialog data customized for SearchComponent
 * NOTE: Formerly these were @Input() properties in the SearchComponent
 */
export type SearchGlobalData = DialogData & {
  showDefaults?: boolean;
  suggestionSource?: SearchSuggestionSource;
  termSuggestionsOnly?: boolean;
  shouldRenderInGlobalSearchMode?: boolean;
  initiationLocation?: SearchTypeaheadInitLocations;
  searchTerm?: string;
  onInputEnter?: (term: string) => void;
};

/**
 * This is a isolated version of the GlobalSearchComponent that is used in the app header.
 * This version only has the services and content and excludes all the other wrapper components
 * in the v1 GlobalSearchComponent
 */
@Component({
  selector: 'dgx-search-global',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('PanelTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(95%)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'scale(100%)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(100%)' }),
        animate(
          '200ms ease-in-out',
          style({ opacity: 0, transform: 'scale(95%)' })
        ),
      ]),
    ]),
  ],
})
export class SearchComponent
  extends SubscriberBaseDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  public static readonly debounceDuration = 300;
  public static _idCounter = 0;

  public showDefaults: boolean = true;
  public suggestionSource: SearchSuggestionSource; // when undefined causes all available sources to be searched
  public termSuggestionsOnly: boolean = false;
  public shouldRenderInGlobalSearchMode: boolean = true;
  public initiationLocation: SearchTypeaheadInitLocations;
  public inputEnter: (value: string) => void = () => {};

  public set searchTerm(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  @ViewChild('searchResults') public searchResultsRef: ElementRef;
  @ViewChild('searchInitiation') public searchInitiationRef: ElementRef;
  @ViewChild('searchInput') public searchInputRef: ElementRef;

  public i18n = this.translateService.instant([
    'Core_Search',
    'LearningSearch_GoToSearch',
    'LearningSearch_GoToMarketplace',
    'OrgSettings_MarketplaceWarning_Default',
  ]);
  public vm$: Observable<SearchAutocompleteViewModel>;
  public searchTerm$ = new BehaviorSubject<string>(undefined);
  public keydownHandler = keydownHandler;

  private authUser: AuthUser;

  public instanceId = SearchComponent._idCounter++;
  public isPopoverOpen: boolean = false; // TODO: Move to state?
  private popoverEscaped: boolean;
  private unlisteners: (() => void)[] = [];

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: SearchGlobalData,
    private a11yService: A11yService,
    private searchAutocompleteFacade: SearchAutocompleteFacade,
    private translateService: TranslateService,
    private searchTrackerService: SearchTrackerService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private ldFlagsService: LDFlagsService,
    private orgSettingsService: OrgSettingsService,
    private authService: AuthService,
    private orgSelectorExternalWarningService: OrgSelectorExternalWarningService,
    private facade: SearchAutocompleteFacade,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.initializeInputs();
  }

  /**
   * Since this component is instantiated by the Angular CDK Dialog,
   * we need to support modal updates to the @Input()/public properties
   */
  private initializeInputs() {
    if (this.data) {
      this.showDefaults = this.data.showDefaults ?? this.showDefaults;
      this.suggestionSource =
        this.data.suggestionSource ?? this.suggestionSource;
      this.termSuggestionsOnly =
        this.data.termSuggestionsOnly ?? this.termSuggestionsOnly;
      this.shouldRenderInGlobalSearchMode =
        this.data.shouldRenderInGlobalSearchMode ??
        this.shouldRenderInGlobalSearchMode;
      this.initiationLocation =
        this.data.initiationLocation ?? this.initiationLocation;
      this.searchTerm = this.data.searchTerm ?? this.searchTerm;

      this.inputEnter = this.data.onInputEnter ?? this.inputEnter;
    }
  }

  /* eslint-enable @typescript-eslint/member-ordering */

  // Use a different identifier for app header global search
  public get dgat(): string {
    return this.shouldRenderInGlobalSearchMode
      ? 'search-initiation-autocomplete-995'
      : 'search-initiation-autocomplete-654';
  }

  //// Natural Language POC
  public get naturalLanguageSearchEnabled(): boolean {
    return this.ldFlagsService.search.naturalLanguageCatalogSearch;
  }

  public get showGotoMarketplace(): boolean {
    // make sure this is false for un-authed users
    return (
      (!this.authService?.authUser?.disableDegreedMarketplace &&
        this.ldFlagsService.showMarketplace) ||
      this.ldFlagsService.isPaidCMUser
    );
  }

  public ngOnDestroy(): void {
    this.onIsPopoverOpenChange(false);
  }

  public ngOnInit() {
    this.dialogRef.addPanelClass('apollo-dialog-panel');

    const initialState = {
      initiationLocation: this.initiationLocation,
      termSuggestionsOnly: this.termSuggestionsOnly,
    };

    this.vm$ = this.searchAutocompleteFacade.initializeVM(initialState);

    this.searchTerm$
      .pipe(
        this.takeUntilDestroyed(),
        debounceTime(SearchComponent.debounceDuration),
        distinctUntilChanged(), // don't retrigger unless term has changed
        tap((searchTerm) => {
          if (this.isPopoverOpen) {
            // User is likely manually entering a search term so
            // trigger request for suggestions
            this.searchAutocompleteFacade.updateSearchTerm(searchTerm);
          } else {
            // Search term was set programatically so we update the state
            // directly to avoid triggering request for suggestions
            this.searchAutocompleteFacade.updateState({ searchTerm });
          }
        })
      )
      .subscribe();
    this.authUser = this.authService.authUser;

    // Dialog is ALREADY open... so let's manually simulate popup activity
    this.onInitiationFocus();
  }

  public ngAfterViewInit(): void {
    this.onIsPopoverOpenChange(true);
  }

  public onInitiationFocus(): void {
    if (this.isPopoverOpen) {
      this.isPopoverOpen = false;
      return;
    }
    if (this.popoverEscaped) {
      this.popoverEscaped = false;
      return;
    }
    this.isPopoverOpen = true;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.searchInputRef.nativeElement.focus();
      }, 0);
    });
    this.searchTrackerService.searchInitiated({
      typeaheadInitiationLocation: this.initiationLocation,
    });
  }

  public onIsPopoverOpenChange(isOpen: boolean): void {
    if (!this.isPopoverOpen && isOpen) {
      this.searchInputRef.nativeElement.focus();
    }
    this.isPopoverOpen = isOpen;
    if (isOpen) {
      this.unlisteners.push(
        this.renderer.listen(
          this.searchInputRef.nativeElement,
          'keydown',
          this.onInputKeydown.bind(this, this.searchResultsRef)
        )
      );
    } else {
      this.removeListeners();
    }
  }

  /**
   * Keep for a11y purposes
   * Prevents popover from reappearing after it was explicitly escaped
   */
  public handleKeyup(event: KeyboardEvent): void {
    if (isKey(event, Key.Escape)) {
      this.popoverEscaped = true;
    }
  }

  public submit(form: NgForm): void {
    this.inputEnter(form.value.searchTerm as string);
    this.closeDropdown();
  }

  public onSearch(event: Event): void {
    this.searchTerm$.next((event.target as HTMLInputElement).value);
  }

  public closeDropdown = () => {
    if (this.shouldRenderInGlobalSearchMode) {
      this.searchTerm$.next(undefined);
    }
    this.dialogRef.close();
  };

  ///// Natural Language POC
  public getNaturalLanguageSearchResults(event) {
    event.stopPropagation();
    event.preventDefault();
    this.searchAutocompleteFacade.doNaturalLanguageSearch(
      this.searchTerm$.value
    );
  }

  private onInputKeydown(event: KeyboardEvent): void {
    if (isKey(event, Key.Down)) {
      // since this is tracked on an INPUT element, the body class doesn't change automatically. Let's force it here
      event.preventDefault();
      this.document.querySelector('body').classList.remove('mouse-focus');
      this.document.querySelector('body').classList.add('keyboard-focus');
      this.a11yService.focusNextFocusable(this.searchResultsRef.nativeElement);
    }
  }

  private removeListeners(): void {
    for (const unlisten of this.unlisteners) {
      unlisten();
    }
    this.unlisteners = [];
  }

  goToMarketplace() {
    return this.orgSettingsService
      .getSetting(
        this.authUser?.defaultOrgInfo?.organizationId,
        'MarketplaceWarning'
      )
      .pipe(
        take(1),
        switchMap(
          (setting) =>
            setting.enabled
              ? this.orgSelectorExternalWarningService.showMarketplaceWarning(
                  setting.value,
                  'Degreed'
                )
              : of({}) // warning is disabled so just continue navigation
        )
      )
      .subscribe(() => {
        this.facade.emptyMarketplaceSearch();
      });
  }
}
