import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tag'
  | 'danger'
  | 'destructive'
  | 'passive'
  | 'ghost'
  | 'clear';
const BUTTON_HOST_ATTRIBUTES = [
  'df-button',
  'df-button-small',
  'df-button-extra-small',
  'df-button-square',
];

/**
 *
 * @example
 * ```
 * <button df-button dfButtonType="primary"></button>
 * ```
 */
@Component({
  /* eslint-disable-next-line */
  selector: 'button[df-button], a[df-button]',
  // role=button is required to make some screenreaders read out related content correctly
  // such as tooltips associated with the button on VoiceOver
  host: { role: 'button' },
  template: '<span class="df-button-wrapper"><ng-content></ng-content></span>',
  styleUrls: ['./button-basic.component.scss'],
  // eslint-disable-next-line
  encapsulation: ViewEncapsulation.None, // this allows buttons to be restyled
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfButtonBasicComponent implements OnChanges {
  @HostBinding('class.df-button--disabled') get isDisabled() {
    return this._hasHostAttributes('disabled');
  }

  @HostBinding('class.df-button--inactive') get isInactive() {
    return this._hasHostAttributes('inactive');
  }

  @Input() public dfButtonType: ButtonType;
  protected _elementClass: string[] = [];

  constructor(public elementRef: ElementRef) {
    // For each of the variant selectors that is present in the button's host
    // attributes, add the correct corresponding class.
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (this._getHostElement() as HTMLElement).classList.add(attr);
      }
    }

    if (!this._hasHostAttributes('type')) {
      (this._getHostElement() as HTMLElement).setAttribute('type', 'button');
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dfButtonType) {
      this._removeButtonStyle(changes.dfButtonType.previousValue);
      this._setButtonStyle(changes.dfButtonType.currentValue);
    }
  }

  private _getHostElement() {
    return this.elementRef.nativeElement;
  }

  private _hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) =>
      this._getHostElement().hasAttribute(attribute)
    );
  }

  private _removeButtonStyle(style) {
    const className = `df-button-${style}`;
    if (this.elementRef.nativeElement.classList.contains(className)) {
      this.elementRef.nativeElement.classList.remove(className);
    }
  }

  private _setButtonStyle(style) {
    this.elementRef.nativeElement.classList.add(`df-button-${style}`);
  }
}
