import { LDFlagsService } from '../ld-flags.service';

export class ChartsLDFlags {
  /***
   *  Team: Platform Services
   *  Created Date: 9/22/2023 https://github.com/degreed/Degreed/pull/37497
   *  Planned Cleanup: 1/6/2024 [PD-91436]
   *  Visibility:
   *    - Org 1
   *
   *  User skill inference based on job role.
   */
  public get gaiInferredSkillRating(): boolean {
    return this.ldFlagsService.getFlag('gai-inferred-skill-ratings', false);
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
