import { Injectable } from '@angular/core';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ContextService } from '@app/shared/services/context.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PathwayLesson } from '../pathway-api.model';
import {
  PathwayDetailsModel,
  PathwayNodeType,
  PathwaySection,
  PathwayStep,
} from '../rsm/pathway-api.model';
import { PathwayLearnerService } from './pathway-learner.service';
import { PathwayService } from './pathway.service';

@Injectable({
  providedIn: 'root',
})
export class PathwayAuthoringService {
  public readonly i18n = this.translate.instant([
    'Pathways_ReorderLessonsTitleFormat',
    'Pathways_ConfirmDeleteLesson',
    'Pathways_ConfirmRemoveSection',
    'deleteModal_Delete',
  ]);

  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private pathwayLearnerService: PathwayLearnerService,
    private modalService: ModalService,
    private contextService: ContextService,
    private pathwayService: PathwayService
  ) {}

  /**
   * Delete section or lesson node after confirming delete via modal and update full pathway
   * @param pathway - Current pathway.
   * @param node - Node of the section or lesson to delete
   * @param type - Type of node: section or lesson
   */
  public confirmDeleteNode(
    pathway: PathwayDetailsModel,
    node: string,
    type: PathwayNodeType
  ): Observable<PathwayDetailsModel> {
    const inputs = {
      canCancel: true,
      bodyClasses: 'h3 center-text',
      bodyText:
        type === 'section'
          ? this.i18n?.Pathways_ConfirmRemoveSection
          : this.i18n?.Pathways_ConfirmDeleteLesson,
      submitButtonText: this.i18n?.deleteModal_Delete,
      item: { pathwayId: pathway.id },
    };
    return this.modalService
      .show<any>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: true,
      })
      .pipe(
        switchMap(() => {
          return this.deletePathwayNode(pathway.id, node, type);
        }),
        switchMap(() => {
          return this.pathwayLearnerService.getPathway(pathway.id);
        }),
        map((data) => {
          return { ...pathway, ...data };
        })
      );
  }

  public deletePathwayNode(
    pathId: number,
    node: string,
    type: PathwayNodeType
  ): Observable<void> {
    return this.http
      .delete<void>('/pathways/deletepathwaynode', {
        params: { pathId: pathId, Node: node },
      })
      .pipe(
        tap(() => {
          this.pathwayService.updateVersion(pathId);
        }),
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.translate.instant(`Pathways_Delete${type}Error`),
              error
            )
          );
        })
      );
  }

  /**
   * Updates pathway's section after a move or reorder
   *
   * @param pathway - Current pathway
   * @param source - Section source that update was made from
   * @param destination - (Optional)Destination section that update was made to
   */
  public afterRequestUpdateSections(
    pathway: PathwayDetailsModel,
    source: PathwaySection,
    destination: PathwaySection
  ): Observable<PathwayDetailsModel> {
    pathway.levels[source.number - 1] = source;

    if (destination) {
      // destination not always be provided
      pathway.levels[destination.number - 1] = destination;
    }
    return of(pathway);
  }

  /**
   * Check if valid section to prevent default Untitled Section in the nav and empty section title and description
   *
   * @param sections - Pathway sections
   */
  public isValidSections(sections: PathwaySection[]): boolean {
    if (sections.length > 1 || (sections.length === 1 && !!sections[0].title)) {
      return true;
    }
    return false;
  }

  /**
   * Valid lessons check to prevent empty subsection title & descriptions
   *
   * @param lessons - The section lessons
   */
  public isValidLessons(lessons: PathwayLesson[]): boolean {
    if (
      lessons.length > 1 ||
      (lessons.length === 1 && (lessons[0].title || lessons[0].description))
    ) {
      return true;
    }
    return false;
  }

  /**
   * Get pathway authoring url
   *
   * @param pathwayUrl - Internal url for a given pathway
   * @param context - Additional context ie. channel
   */
  public getAuthoringUrl(pathwayUrl: string, context?: string): string {
    let authoringUrl = `${pathwayUrl}?editMode=true`;

    if (context) {
      authoringUrl = this.contextService.addContextToUrl(authoringUrl, context);
    }

    return authoringUrl;
  }

  /**
   * Update section, lesson, or step properties wholesale
   * @param node full node object
   */
  public updatePathwayNode(node: PathwayLesson | PathwaySection | PathwayStep) {
    return this.http.post<void>('/pathways/updatepathwaynode', node).pipe(
      tap(() => {
        this.pathwayService.updateVersion(node.pathId);
      }),
      catchError((e) => {
        return throwError(
          new DgError(this.translate.instant('Pathways_UpdateError'), e)
        );
      })
    );
  }

  /**
   * Override Input details with Step details from the editDetails modal
   * @param step full step object
   */
  public updateStepDetails(step: PathwayStep) {
    return this.updatePathwayNode(step);
  }
}
