/* 🤖 this file was generated by svg-to-ts */

import { DfIcon } from './build/fresco-icons.model';

export type DfIconNameSubset<T extends Readonly<DfIcon[]>> = T[number]['name'];

export * from './build/DfIcon-mentor-24.icon';
export * from './build/DfIcon-plus-circle-24.icon';
export * from './build/DfIcon-twitter-square-24.icon';
export * from './build/DfIcon-accomplishment-16.icon';
export * from './build/DfIcon-accomplishment-circle-16.icon';
export * from './build/DfIcon-archive-16.icon';
export * from './build/DfIcon-arrow-backward-16.icon';
export * from './build/DfIcon-arrow-backward-circle-16.icon';
export * from './build/DfIcon-arrow-circular-16.icon';
export * from './build/DfIcon-arrow-circular-left-16.icon';
export * from './build/DfIcon-arrow-down-16.icon';
export * from './build/DfIcon-arrow-down-right-16.icon';
export * from './build/DfIcon-arrow-down-tray-16.icon';
export * from './build/DfIcon-arrow-forward-16.icon';
export * from './build/DfIcon-arrow-forward-circle-16.icon';
export * from './build/DfIcon-arrow-forward-square-16.icon';
export * from './build/DfIcon-arrow-left-16.icon';
export * from './build/DfIcon-arrow-ne-16.icon';
export * from './build/DfIcon-arrow-open-right-16.icon';
export * from './build/DfIcon-arrow-right-16.icon';
export * from './build/DfIcon-arrow-up-16.icon';
export * from './build/DfIcon-arrow-up-down-16.icon';
export * from './build/DfIcon-arrow-up-right-out-16.icon';
export * from './build/DfIcon-arrow-up-tray-16.icon';
export * from './build/DfIcon-article-16.icon';
export * from './build/DfIcon-assessment-16.icon';
export * from './build/DfIcon-author-note-16.icon';
export * from './build/DfIcon-award-16.icon';
export * from './build/DfIcon-badge-16.icon';
export * from './build/DfIcon-bargraph-16.icon';
export * from './build/DfIcon-bargraph-square-16.icon';
export * from './build/DfIcon-bell-16.icon';
export * from './build/DfIcon-bold-16.icon';
export * from './build/DfIcon-book-16.icon';
export * from './build/DfIcon-bookmark-16.icon';
export * from './build/DfIcon-broadcast-16.icon';
export * from './build/DfIcon-bulleted-list-16.icon';
export * from './build/DfIcon-calendar-16.icon';
export * from './build/DfIcon-camera-plus-16.icon';
export * from './build/DfIcon-caret-down-16.icon';
export * from './build/DfIcon-caret-up-16.icon';
export * from './build/DfIcon-certificate-16.icon';
export * from './build/DfIcon-chain-16.icon';
export * from './build/DfIcon-checkmark-16.icon';
export * from './build/DfIcon-checkmark-badge-16.icon';
export * from './build/DfIcon-checkmark-circle-16.icon';
export * from './build/DfIcon-checkmark-outline-16.icon';
export * from './build/DfIcon-checkmark-square-16.icon';
export * from './build/DfIcon-chevron-double-left-16.icon';
export * from './build/DfIcon-chevron-double-right-16.icon';
export * from './build/DfIcon-chevron-down-16.icon';
export * from './build/DfIcon-chevron-left-16.icon';
export * from './build/DfIcon-chevron-right-16.icon';
export * from './build/DfIcon-chevron-up-16.icon';
export * from './build/DfIcon-clock-16.icon';
export * from './build/DfIcon-clock-arrow-16.icon';
export * from './build/DfIcon-clock-filled-16.icon';
export * from './build/DfIcon-clock-square-16.icon';
export * from './build/DfIcon-coins-16.icon';
export * from './build/DfIcon-columns-16.icon';
export * from './build/DfIcon-course-16.icon';
export * from './build/DfIcon-cross-16.icon';
export * from './build/DfIcon-cross-circle-16.icon';
export * from './build/DfIcon-cross-circle-outline-16.icon';
export * from './build/DfIcon-dart-16.icon';
export * from './build/DfIcon-dart-square-16.icon';
export * from './build/DfIcon-degree-16.icon';
export * from './build/DfIcon-dots-16.icon';
export * from './build/DfIcon-dots-double-vertical-16.icon';
export * from './build/DfIcon-envelope-16.icon';
export * from './build/DfIcon-envelope-circle-16.icon';
export * from './build/DfIcon-episode-16.icon';
export * from './build/DfIcon-event-16.icon';
export * from './build/DfIcon-exclamation-circle-16.icon';
export * from './build/DfIcon-exclamation-circle-outline-16.icon';
export * from './build/DfIcon-exclamation-square-16.icon';
export * from './build/DfIcon-exclamation-triangle-outline-16.icon';
export * from './build/DfIcon-experience-16.icon';
export * from './build/DfIcon-eye-closed-16.icon';
export * from './build/DfIcon-eye-open-16.icon';
export * from './build/DfIcon-eye-open-square-16.icon';
export * from './build/DfIcon-facebook-square-16.icon';
export * from './build/DfIcon-flag-16.icon';
export * from './build/DfIcon-gear-16.icon';
export * from './build/DfIcon-gear-square-16.icon';
export * from './build/DfIcon-google-square-16.icon';
export * from './build/DfIcon-grid-16.icon';
export * from './build/DfIcon-heart-16.icon';
export * from './build/DfIcon-heart-square-16.icon';
export * from './build/DfIcon-home-16.icon';
export * from './build/DfIcon-image-broken-16.icon';
export * from './build/DfIcon-image-plus-16.icon';
export * from './build/DfIcon-info-16.icon';
export * from './build/DfIcon-info-square-16.icon';
export * from './build/DfIcon-italic-16.icon';
export * from './build/DfIcon-linkedin-square-16.icon';
export * from './build/DfIcon-lock-closed-16.icon';
export * from './build/DfIcon-lock-open-16.icon';
export * from './build/DfIcon-magnifying-glass-16.icon';
export * from './build/DfIcon-mentor-16.icon';
export * from './build/DfIcon-minus-16.icon';
export * from './build/DfIcon-minus-circle-16.icon';
export * from './build/DfIcon-minus-square-16.icon';
export * from './build/DfIcon-numbered-list-16.icon';
export * from './build/DfIcon-office-16.icon';
export * from './build/DfIcon-opportunity-16.icon';
export * from './build/DfIcon-page-16.icon';
export * from './build/DfIcon-paperclip-16.icon';
export * from './build/DfIcon-pencil-16.icon';
export * from './build/DfIcon-pencil-square-16.icon';
export * from './build/DfIcon-person-16.icon';
export * from './build/DfIcon-person-double-16.icon';
export * from './build/DfIcon-person-double-square-16.icon';
export * from './build/DfIcon-person-plus-16.icon';
export * from './build/DfIcon-person-square-16.icon';
export * from './build/DfIcon-person-triple-16.icon';
export * from './build/DfIcon-person-triple-square-16.icon';
export * from './build/DfIcon-phone-16.icon';
export * from './build/DfIcon-pin-16.icon';
export * from './build/DfIcon-play-16.icon';
export * from './build/DfIcon-plus-16.icon';
export * from './build/DfIcon-plus-circle-16.icon';
export * from './build/DfIcon-plus-outline-16.icon';
export * from './build/DfIcon-plus-square-16.icon';
export * from './build/DfIcon-qanda-16.icon';
export * from './build/DfIcon-question-mark-16.icon';
export * from './build/DfIcon-question-mark-circle-16.icon';
export * from './build/DfIcon-quote-16.icon';
export * from './build/DfIcon-record-16.icon';
export * from './build/DfIcon-speech-bubble-16.icon';
export * from './build/DfIcon-speech-bubble-circle-16.icon';
export * from './build/DfIcon-speech-bubble-square-16.icon';
export * from './build/DfIcon-square-double-16.icon';
export * from './build/DfIcon-star-16.icon';
export * from './build/DfIcon-stop-16.icon';
export * from './build/DfIcon-stripes-down-16.icon';
export * from './build/DfIcon-tag-16.icon';
export * from './build/DfIcon-tag-circle-16.icon';
export * from './build/DfIcon-tag-square-16.icon';
export * from './build/DfIcon-task-16.icon';
export * from './build/DfIcon-thumbs-down-16.icon';
export * from './build/DfIcon-thumbs-down-circle-16.icon';
export * from './build/DfIcon-thumbs-up-16.icon';
export * from './build/DfIcon-thumbs-up-circle-16.icon';
export * from './build/DfIcon-thumbs-up-square-16.icon';
export * from './build/DfIcon-tools-square-16.icon';
export * from './build/DfIcon-trash-16.icon';
export * from './build/DfIcon-triangle-down-16.icon';
export * from './build/DfIcon-triangle-up-16.icon';
export * from './build/DfIcon-video-16.icon';
export * from './build/DfIcon-video-camera-16.icon';
export * from './build/DfIcon-accomplishment-12.icon';
export * from './build/DfIcon-accomplishment-circle-12.icon';
export * from './build/DfIcon-arrow-circular-left-12.icon';
export * from './build/DfIcon-arrow-down-right-12.icon';
export * from './build/DfIcon-arrow-forward-12.icon';
export * from './build/DfIcon-arrow-up-right-out-12.icon';
export * from './build/DfIcon-bookmark-12.icon';
export * from './build/DfIcon-caret-down-12.icon';
export * from './build/DfIcon-caret-up-12.icon';
export * from './build/DfIcon-checkmark-12.icon';
export * from './build/DfIcon-checkmark-circle-12.icon';
export * from './build/DfIcon-chevron-double-left-12.icon';
export * from './build/DfIcon-chevron-double-right-12.icon';
export * from './build/DfIcon-chevron-down-12.icon';
export * from './build/DfIcon-chevron-left-12.icon';
export * from './build/DfIcon-chevron-right-12.icon';
export * from './build/DfIcon-chevron-up-12.icon';
export * from './build/DfIcon-clock-filled-12.icon';
export * from './build/DfIcon-cross-12.icon';
export * from './build/DfIcon-cross-circle-12.icon';
export * from './build/DfIcon-exclamation-circle-12.icon';
export * from './build/DfIcon-eye-closed-12.icon';
export * from './build/DfIcon-eye-open-12.icon';
export * from './build/DfIcon-eye-open-square-12.icon';
export * from './build/DfIcon-info-12.icon';
export * from './build/DfIcon-lock-closed-12.icon';
export * from './build/DfIcon-lock-open-12.icon';
export * from './build/DfIcon-mentor-12.icon';
export * from './build/DfIcon-minus-12.icon';
export * from './build/DfIcon-opportunity-12.icon';
export * from './build/DfIcon-page-12.icon';
export * from './build/DfIcon-pin-12.icon';
export * from './build/DfIcon-plus-12.icon';
export * from './build/DfIcon-tag-12.icon';
export * from './build/DfIcon-thumbs-down-12.icon';
export * from './build/DfIcon-thumbs-up-12.icon';
export * from './build/complete-icon-set';
export * from './build/fresco-icons.model';
