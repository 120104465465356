import { Injectable } from '@angular/core';
export interface DateRange {
  startDate: number;
  endDate: number;
  daysBetween: number;
}
@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  constructor() {}

  /**
   * Formats a date into a mm/dd/yyyy string that our backend understands
   * NOTE: ensure the endpoint you are formatting this for is expecting the date WITHOUT the time data
   * @param time A Date object, a number representing ms since unix epoch or a date string to format
   */
  public getDateFormat(time: Date | number | string): string {
    const date = time instanceof Date ? time : new Date(time);
    return (
      date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    );
  }

  /**
   * Get the date range based on a given interval and start date
   * @param interval string - 'day' | 'week' | 'month'
   * @param start A Date object, a number representing ms since unix epoch or a date string to format
   */
  public getDateInterval(interval: string, start): DateRange {
    const day = 'day',
      week = 'week',
      month = 'month',
      weekRange = 6;
    let startDate = start,
      endDate = null,
      dateFormat = null;

    const startDateObject = new Date(startDate);
    startDate = new Date(
      startDateObject.getUTCMonth() +
        1 +
        '/' +
        startDateObject.getUTCDate() +
        '/' +
        startDateObject.getUTCFullYear()
    );

    if (interval === day) {
      endDate = startDate;
    }

    if (interval === week) {
      dateFormat = new Date(startDate);
      endDate = new Date(dateFormat.setDate(startDate.getDate() + weekRange));
    }

    if (interval === month) {
      const yyyy = startDate.getFullYear(),
        mm = startDate.getMonth();
      endDate = new Date(yyyy, mm + 1, 0); // get last day of month
    }
    const daysBetween = this.getDaysBetween(startDate, endDate);

    return {
      startDate: startDate,
      endDate: endDate,
      daysBetween: daysBetween,
    };
  }

  public getNumberOfDays(startDate: Date, endDate: Date): number {
    // original date format "Wed Nov 06 2019 00:00:00"
    // we must convert to milliseconds
    // because the time is 00:00:00 or midnight the last day is not counted so we must add it
    const millisecondsInADay = 86400000,
      endDay = 1,
      diffInTime = endDate.getTime() - startDate.getTime(),
      diffInDays =
        Math.round(Math.abs(diffInTime / millisecondsInADay)) + endDay;
    return diffInDays;
  }

  public getPastDaysExcludeTodayUTC(numDays: number): DateRange {
    const yesterdayUTC = this.getDaysAgoUTC(1);

    const startDate = Math.round(+this.getDaysAgoUTC(numDays));
    const endDate = Math.round(+yesterdayUTC);
    const daysBetween = this.getDaysBetween(startDate, endDate);
    return {
      startDate: startDate,
      endDate: endDate,
      daysBetween: daysBetween,
    };
  }

  public getPastTwoYearsExcludeTodayUTC(): number {
    const pastTwoYearsUTC = new Date(
      new Date().setUTCDate(new Date().getUTCDate() - 730)
    ).setHours(0, 0, 0, 0);

    return Math.round(+pastTwoYearsUTC);
  }

  public getDaysAgoUTC(daysAgo: number): Date {
    const currentDate = new Date();
    const targetDate = new Date(currentDate);
    targetDate.setUTCDate(currentDate.getUTCDate() - daysAgo);

    // Adjust the time to the beginning of the day
    targetDate.setHours(0, 0, 0, 0)

    return targetDate;
  }

  public parseDateFormat(date: string): Date | undefined {
    if (typeof date === 'string') {
      const mmddyyyMatches = date.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
      const yyyymmddMatches = date.match(/(\d{4})-(\d{2})-(\d{2})/);
      if (mmddyyyMatches) {
        const [_, month, day, year] = mmddyyyMatches;
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      } else if (yyyymmddMatches) {
        const [_, year, month, day] = yyyymmddMatches;
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      }
    }
  }

  /**
   * Return a new Date object that only contains the day/month/year information
   * @param time
   */
  public truncateDate(time: Date) {
    return new Date(time.getFullYear(), time.getMonth() - 1, time.getDate());
  }

  private getDaysBetween(startDate: number, endDate: number) {
    return Math.ceil(Math.abs(startDate - endDate) / (1000 * 3600 * 24)) + 1;
  }
}
