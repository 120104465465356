import { Inject, Injectable } from '@angular/core';
import { ResourceSuggestionWithDetails } from '@app/learner-home/learner-home-api.model';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { ReportProblemModalComponent } from '../report-problem-modal/report-problem-modal.component';
import { of } from 'rxjs';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { WindowToken } from '@app/shared/window.token';
import { AuthService, LDFlagsService } from '@dg/shared-services';

export interface FeedbackOptions {
  feedbackType?: number;
  itemId?: string | number;
  itemType: number;
  contextType?: number;
  contextId?: string | number;
  sectionType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  public readonly feedbackContextType = {
    // this should align with the FeedbackContextType enum
    Unknown: 0,
    Pathway: 1,
    Profile: 2,
    Recommendation: 3,
    AssignedLearning: 4,
    RequiredLearning: 5,
    Group: 6,
    GroupActivity: 7,
    Target: 8,
  };

  public readonly feedbackItemType = {
    // this should align with the FeedbackItemType enum
    Unknown: 0,
    Comment: 1,
    Group: 2,
    GroupPost: 3,
    Profile: 4,
    CertificationApplication: 5,
    Article: 6,
    Assessment: 7,
    Book: 8,
    Course: 9,
    Episode: 10,
    Event: 11,
    Task: 12,
    Video: 13,
    Accomplishment: 14,
    Award: 15,
    Certificate: 16,
    Badge: 17,
    Degree: 18,
    Position: 19,
    Skill: 20,
    Pathway: 21,
    Target: 22,
    InvalidCertificate: 23,
    Post: 24,
    Opportunity: 25,
    Team: 26,
    Provider: 27,
  };

  public readonly feedbackType = {
    // this should align with the SendFeedbackType enum
    GeneralReportProblem: 0,
    Inappropriate: 1,
    NativeGeneralReportProblem: 2,
    NativeInappropriate: 3,
    BrokenLink: 4,
    AccessDenied: 5,
  };

  constructor(
    private modalService: ModalService,
    private focusStackService: FocusStackService,
    private webEnvironmentService: WebEnvironmentService,
    private authService: AuthService,
    private ldFlagsService: LDFlagsService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public showReportProblemModal(
    options: FeedbackOptions,
    activeElement?: HTMLElement
  ) {
    if (this.authService.authUser?.reportAProblemRedirectURL) {
      this.windowRef.open(
        this.authService.authUser.reportAProblemRedirectURL,
        '_blank'
      );
      return of(null);
    }

    if (
      options.contextType === this.feedbackContextType.RequiredLearning &&
      !this.sendAllRapTicketsToClient()
    ) {
      const article = this.webEnvironmentService.getZendeskUrl(
        '/articles/7686711744668'
      );
      this.windowRef.open(article, '_blank');
      return of(null);
    }

    this.focusStackService.push(activeElement);
    return this.modalService.show(ReportProblemModalComponent, {
      inputs: { ...options, feedbackType: null },
    });
  }

  public getFeedbackItemTypeFromResource(resourceType: any): number {
    const feedbackItemType = this.feedbackItemType[resourceType];
    if (feedbackItemType) {
      return feedbackItemType;
    } else {
      return this.feedbackItemType.Unknown;
    }
  }

  /** Try to get the feedback (Report a problem) context from the action context */
  public getFeedbackContext(
    _resource: any,
    context: any
  ): { contextType: number; contextId?: number } | undefined {
    if (this.isRecommendationSuggestion(context)) {
      // TODO: Currently we can only get the recommendation type and not the id from the suggestion
      const contextType = this.contextTypeFromRecommendation(
        context.suggestionDetails.recommendationType
      );
      if (contextType !== undefined) {
        return { contextType };
      }
    }
  }

  private contextTypeFromRecommendation(type: UserRecommendationType): number {
    switch (type) {
      case UserRecommendationType.AssignedLearning:
        return this.feedbackContextType.AssignedLearning;
      case UserRecommendationType.Recommendation:
        return this.feedbackContextType.Recommendation;
      case UserRecommendationType.RequiredLearning:
        return this.feedbackContextType.RequiredLearning;
    }
  }

  private isRecommendationSuggestion(
    obj: any
  ): obj is ResourceSuggestionWithDetails {
    return (
      obj?.contentSource === 'Recommendations' &&
      typeof obj.suggestionDetails === 'object'
    );
  }

  public sendAllRapTicketsToClient(): boolean {
    return (
      this.authService.authUser.defaultOrgInfo?.settings
        ?.sendAllRapTicketsToClient &&
      this.ldFlagsService.enableAllRapTicketsForOrg
    );
  }
}
