import { Component, Input, OnChanges } from '@angular/core';
import { NotificationType } from '@lib/fresco';

@Component({
  selector: 'dgx-kpi-list',
  templateUrl: './kpi-list.component.html',
  styleUrls: ['./kpi-list.component.scss'],
})
export class KpiListComponent implements OnChanges {
  @Input() public kpis;
  @Input() public kpisLoading;
  @Input() public kpisError;
  @Input() public kpiClass;
  @Input() public kpiPlaceholder;

  public readonly NotificationType = NotificationType;

  public ngOnChanges() {
    if (this.kpis) {
      this.kpis = this.kpis.sort((a, b) => a.order - b.order);
    }
  }
}
