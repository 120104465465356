import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingLinksTableComponent } from './tracking-links-table.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [TrackingLinksTableComponent],
  imports: [CommonModule, SharedModule],
  exports: [TrackingLinksTableComponent],
})
export class TrackingLinksTableModule {}
