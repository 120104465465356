import { TagsApi } from '@app/tags/tag-api.model';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { ColorService } from '@app/shared/services/color.service';
import { getTagLevel } from '@app/shared/utils/tag-helpers';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { DonutSize } from './tag-rating-donut.model';

@Component({
  selector: 'dgx-tag-rating-donut',
  templateUrl: './tag-rating-donut.component.html',
  styleUrls: ['./tag-rating-donut.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingDonutComponent implements OnChanges, AfterViewInit {
  @ViewChild('targetIndicator', { static: false })
  public targetIndicator: ElementRef<HTMLElement>;

  @Input() public anchorHigh: number;
  @Input() public background: boolean;
  @Input() public showDefaultGlasses = false;
  @Input() public showDefaultPlus = false;
  @Input() public size: DonutSize;
  @Input() public targetLevel: number;
  @Input() public highlight: boolean;
  @Input() public isOwner: boolean;
  @Input() public showLock: boolean;
  @Input() public tag: TagsApi.Tag;
  @Input() public level: string;
  /** If we are editing on a target, still fallback when rating i not set */
  @Input() public isTargetAuthoring: boolean;
  /* If this is the tag tile, show the target Level on the legacy donuts */
  @Input() public isTagTile: boolean;

  private _pending: boolean;

  private _certified: boolean;

  private _evaluated: boolean;

  private _evaluating: boolean;

  constructor(
    private colorService: ColorService,
    private translateService: TranslateService,
    private authService: AuthService,
    private renderer: Renderer2
  ) {}
  @Input() public set showPending(pending: boolean) {
    this._pending = pending;
  }
  public get showPending(): boolean {
    // We can't show the lock and pending icon at the same time.
    if (this.showLock === undefined) {
      return this._pending;
    }
    return false;
  }

  @Input() public set certified(certified: boolean) {
    this._certified = certified;
  }
  public get certified(): boolean {
    return (
      this._certified ||
      (!!this.convertedLevel &&
        this.tag?.rating?.type === InternalTagRatingTypes.credential)
    );
  }
  @Input() public set evaluated(evaluated: boolean) {
    this._evaluated = evaluated;
  }
  public get evaluated(): boolean {
    return (
      this._evaluated ||
      (!!this.convertedLevel &&
        this.tag?.rating?.type === InternalTagRatingTypes.evaluation)
    );
  }
  @Input() public set evaluating(evaluating: boolean) {
    this._evaluating = evaluating;
  }
  public get evaluating(): boolean {
    return (
      this._evaluating ||
      (!this.convertedLevel &&
        this.tag?.rating?.type === InternalTagRatingTypes.evaluation)
    );
  }

  public get maxLevel(): number {
    return (
      this.anchorHigh || this.authService.authUser?.orgRatingScale?.anchorHigh
    );
  }

  public get enableSkillsPlatform(): boolean {
    return (
      this.authService.authUser?.defaultOrgInfo?.settings
        ?.enableSkillsPlatform || false
    );
  }

  public get pendingIconSize() {
    switch (this.size) {
      case 'lg':
        return 'medium';
      case 'sm':
        return 'small';
      default:
        '';
    }
  }

  public get brandColor(): string {
    return this.colorService.getColor('blue');
  }

  public get inactiveColor(): string {
    return this.colorService.getColor('ebony-a18');
  }

  public get activeColor(): string {
    return this.certified || this.highlight
      ? this.brandColor
      : this.colorService.getColor('ebony-a61');
  }

  public get targetIndicatorColor(): string {
    return this.colorService.getColor('sky');
  }

  public get targetIndicatorPathDescription(): string {
    switch (this.size) {
      case 'lg':
        return 'M 8 3 L 8 10';
      case 'md':
        return 'M 8 5 L 8 10';
      case 'sm':
        return 'M 8 4 L 8 8';
      default:
        '';
    }
  }

  public get targetIndicatorStrokeWidth(): number {
    switch (this.size) {
      case 'lg':
        return 6;
      case 'md':
        return 5;
      case 'sm':
        return 4;
      default:
        0;
    }
  }

  public get showPlus(): boolean {
    if (!this.isOwner) {
      return false;
    }
    if (this.tag) {
      return !this.tag.rating || !this.tag.rating?.isInternal;
    }
    return this.showDefaultPlus && !this.convertedLevel;
  }

  public get showSeal(): boolean {
    return this.certified;
  }

  public get showGlasses(): boolean {
    return (
      (!this.convertedLevel &&
        (!this.isOwner || this.showDefaultGlasses || this.showPending)) ||
      (!this.targetLevel && this.isTargetAuthoring)
    );
  }

  public get convertedLevel(): number {
    const levelAsInt = parseInt(this.level, 10);

    // if it's a number, then return the number
    if (!isNaN(levelAsInt)) {
      return levelAsInt;
    }

    // otherwise this will handle any other case, defaults to 0 if we don't find a match
    return getTagLevel(this.tag);
  }

  public get i18n(): { [key: string]: string } {
    return this.translateService.instant(
      ['Cred_Certificate_SealAlt', 'Target_TargetLevelFormat'],
      { level: this.targetLevel }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { tag, level, targetLevel } = changes;

    // - Use the `tag` binding for simple use cases
    // - Use `level`, `certified`, `evaluated` etc. when more granular control is required
    // - Using `tag`/`level` together can corrupt the component state, so...
    if (tag && level) {
      throw new Error(
        'The [tag] and [level] bindings on `dgxTagRatingDonut` are mutually exclusive'
      );
    }

    // Update target level
    if (targetLevel) {
      this.setTargetIndicatorAngle(
        this.targetIndicator,
        targetLevel.currentValue,
        this.maxLevel
      );
    }
  }

  public ngAfterViewInit(): void {
    this.setTargetIndicatorAngle(
      this.targetIndicator,
      this.targetLevel,
      this.maxLevel
    );
  }

  private setTargetIndicatorAngle(
    element: ElementRef,
    targetLevel: number,
    maxLevel: number
  ): void {
    if (element) {
      if (targetLevel > 0) {
        const transform = this.getRotateTransform(targetLevel, maxLevel);
        this.renderer.setStyle(element.nativeElement, 'transform', transform);
        this.renderer.setStyle(element.nativeElement, 'visibility', 'visible');
      } else {
        this.renderer.setStyle(element.nativeElement, 'visibility', 'hidden');
      }
    }
  }

  private getRotateTransform(targetLevel: number, maxLevel: number): string {
    const degrees = (targetLevel * 360) / maxLevel;
    return `rotate(${degrees}deg)`;
  }
}
