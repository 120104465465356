import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { SimpleItemViewModel } from '@app/shared/models/core-view.model';
import {
  DfAccessibilityService,
  DfIconSize,
  DfPlacementArray,
  DfPopoverComponent,
  DfPopoverService,
  DfPopoverTriggerDirective,
} from '@lib/fresco';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

export interface MenuViewModel extends SimpleItemViewModel {
  /** A DfIcon icon to display. */
  icon?: string;
  /** Turns the option title red. */
  isDestructive?: boolean;
  /** Turns the option title blue. */
  isPrimary?: boolean;
  /** Adds top border to option title. */
  isSeparated?: boolean;
  /** Add additional context information for assistive tech; eg. to explain which row in a table is being edited from a table menu */
  ariaLabel?: string;
  /** Called when option is clicked. */
  defaultAction: (event: Event, popoverTrigger?: ElementRef) => any;
  /** Dynamically determine whether option should be hidden. Use this to show/hide mutually exclusive items, *rather* than using ternary logic on the title or defaultAction. */
  isHidden?: () => boolean;
}

/**
 * Modify the menu config array.
 *
 * @param item - The current item in a `getMenuConfig`-type method. Type correctly with <>.
 * @param defaultConfig - The array of MenuViewModel items to be combined with your custom options.
 *
 * @example
 * // Use at the end of a method to update the normal menu config with
 * // additional, context-dependant options.
 * ```javascript
 * return modifyOptionsFn
 *  ? modifyOptionsFn<LearningResourceViewModel>(item, defaultConfig)
 *  : defaultConfig;
 * ```
 * // meanwhile, modifyOptionsFn itself might look like:
 * ```
 * public getMenuOptionsFn(suggestion: ResourceSuggestionWithDetails): ModifyOptionsFnType<ResourceSuggestionWithDetails> {
 *   return (item, defaultMenuConfig) => [
 *     ...defaultMenuConfig,
 *     {
 *       title: this.i18n.Core_Dismiss,
 *       isHidden: () => item.completionInfo?.isCompleted,
 *       defaultAction: (_, popoverTrigger) => {
 *         this.dismissItem(
 *           { isModal: false },
 *           suggestion,
 *           popoverTrigger.nativeElement
 *         );
 *       },
 *     },
 *   ];
 * }
 * ```
 */
export type ModifyOptionsFnType<T> = (
  item: T,
  defaultConfig: MenuViewModel[]
) => MenuViewModel[];

/**
 * Generic popover menu component with the ability to define options as JSON. Trigger button is customizable by adding it to the transcluded content
 * NOTE: In the past (with ActionOptions) the config lived in the context (feed, browse, etc) but to reduce redundancy, and so that it's easier to see all of the use cases, etc. the new idea is to put the config in the component (tile, card, etc.) and pass the context to the component and show/hide menu items based on context. (For this reason we are not relying on passing back `item` or other contextual data to the action.)
 *
 * If you are trying to figure out what handles key events on the menu, look at {@link ItemContainerRoleDirective} instead.
 *
 * @param menuConfig {MenuViewModel[]} (required) array of MenuViewModel objects used to populate the menu items. Example: [{title: 'Option one', defaultAction: ()=>{ doSomething() }}]
 * @param appendToBody {boolean=false} (optional) attaches the popover to the *body* rather than the parent. Useful for when the menu appears inside an overflow-hidden container.
 * @param isMobileOptedOut {boolean=true} (optional) toggles the default mobile styling of popovers off.  Set to false to allow the popover to expand full width in mobile views.
 * @param isPopoverOpen {boolean=false} (optional) allows the popover to be opened from outside this component. Gets passed in to the popoverComponent.
 * @param placement {DfPlacementArray='bottom-left'} (optional) accepts valid Boostrap placement values. Gets passed in to the popoverComponent.
 * @param placementAdjustLeftRem {number=0} (optional) accepts positive or negative numbers to adjust the horizontal placement for visual balance.
 * @param placementAdjustTopRem {number=0} (optional) accepts positive or negative numbers to adjust the vertical placement for visual balance.
 * @param autoCloseOnItemSelect {boolean=false} (optional) automatically closes the menu on item click.
 */

@Component({
  selector: 'dgx-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements AfterViewInit, OnDestroy {
  @Input() public appendToBody = false;
  @Input() public isMobileOptedOut = true;
  @Input() public isPopoverOpen: boolean = false;
  @Input() public menuConfig: readonly MenuViewModel[];
  @Input() public placement: DfPlacementArray = 'bottom-left';
  @Input() public placementAdjustLeftRem: number = 0;
  @Input() public placementAdjustTopRem: number = 0;
  @Input() public allowMenuGrowth: boolean = false;
  @Input() public autoCloseOnItemSelect = false;
  @Input() public iconSize: DfIconSize = 'medium';

  @Output() public isOpenChange = new EventEmitter<boolean>();

  @ViewChild(DfPopoverComponent) public menuPopoverRef: DfPopoverComponent;

  public popoverTriggerInstance: DfPopoverTriggerDirective;

  constructor(
    private accessibilityService: DfAccessibilityService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private popoverService: DfPopoverService
  ) {}

  public get menuHasIcons() {
    return this.menuConfig?.some((itemVm) => !!itemVm.icon);
  }

  /**
   * Determine whether or not to hide the entire menu.
   */
  public get hasMenuOptions(): boolean {
    // hide if menuConfig is undefined or empty
    if (!this.menuConfig?.length) {
      return false;
    }
    // also hide if menuConfig is defined and not empty but *all* options are hidden
    return this.menuConfig.some((itemVm) => this.isVisible(itemVm));
  }

  public ngAfterViewInit(): void {
    if (!this.hasMenuOptions) {
      // this whole component gets hidden if all options are hidden
      return;
    }
    // attach popoverTriggerDirective to button
    const menuButton: HTMLElement = this.elementRef.nativeElement.querySelector(
      'button,[role=button]'
    );
    const menuButtonRef = new ElementRef(menuButton);
    this.popoverTriggerInstance = this.attachPopoverDirective(menuButtonRef);
  }

  public ngOnDestroy(): void {
    if (
      this.popoverTriggerInstance &&
      typeof this.popoverTriggerInstance.ngOnDestroy === 'function'
    ) {
      this.popoverTriggerInstance.ngOnDestroy();
    }
  }

  public missingLargeIcon(itemVm: MenuViewModel): boolean {
    return this.menuHasIcons && !itemVm.icon && this.iconSize === 'large';
  }

  public missingMediumIcon(itemVm: MenuViewModel): boolean {
    return this.menuHasIcons && !itemVm.icon && this.iconSize === 'medium';
  }

  public missingSmallIcon(itemVm: MenuViewModel): boolean {
    return this.menuHasIcons && !itemVm.icon && this.iconSize === 'small';
  }

  /**
   * Determine whether to show separation line. Always return false if this
   * is the first child in the list.
   *
   * @param menuConfig
   * @returns
   */
  public isSeparated(isFirst: boolean, isSeparated: boolean): boolean {
    return !isFirst && isSeparated;
  }

  /**
   * Determine whether or not to hide an individual item.
   *
   * @param itemVm - The current menu item.
   */
  public isVisible(itemVm: MenuViewModel): boolean {
    return itemVm.isHidden === undefined || !itemVm.isHidden();
  }

  /**
   * Get the correct data-dgat value.
   *
   * @param itemVm - The current menu item.
   */
  public getDgat(itemVm: MenuViewModel): string {
    return itemVm.id ? 'menuitem-' + itemVm.id : 'menuitem';
  }

  /**
   * Get the correct tracking key. In a situation where the trackingKey
   * is undefined, it is more performant to return undefined/null
   * than to fall back on using the index.
   *
   * @param itemVm - The current menu item.
   */
  public getItemTrackingKey(_, itemVm: MenuViewModel) {
    return itemVm.trackingKey;
  }

  public onMenuItemClick($event: Event, itemVm: MenuViewModel): void {
    $event.stopPropagation();
    if (this.autoCloseOnItemSelect && this.menuPopoverRef.isOpen) {
      this.menuPopoverRef.toggle();
    }
    itemVm.defaultAction($event, this.menuPopoverRef.popoverTrigger);
  }

  /**
   * Fired when the popover emits the isOpenChange event.
   * @param isPopoverOpen - current state of popover; true if open, false otherwise
   */
  public popoverToggled(isPopoverOpen: boolean) {
    const wasPopoverClosedViaClickOfInput =
      document.activeElement.tagName === 'INPUT' ||
      document.activeElement.tagName === 'TEXTAREA';

    this.isOpenChange.emit(isPopoverOpen);
    if (!isPopoverOpen && !wasPopoverClosedViaClickOfInput) {
      this.accessibilityService.focusNextFocusable(
        this.elementRef.nativeElement
      );
    }
  }

  /**
   * Instantiates the menu button as a Popover Trigger by applying the PopoverTriggerDirective to it
   * @param menuButton the menu button to instantiate
   */
  private attachPopoverDirective(menuButton: ElementRef) {
    if (!menuButton) {
      console.error(`invalid menuButton ref`);
      return;
    }
    let popoverTriggerInstance: DfPopoverTriggerDirective;
    try {
      popoverTriggerInstance = new DfPopoverTriggerDirective(
        menuButton,
        this.renderer,
        this.accessibilityService,
        this.popoverService
      );
    } catch (error) {
      console.error(`Could not attach PopoverTriggerDirective to the button`);
    }
    popoverTriggerInstance.dfPopoverTrigger = this.menuPopoverRef;

    popoverTriggerInstance.ngAfterViewInit();
    return popoverTriggerInstance;
  }
}
