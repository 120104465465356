import { AuthService } from './../../services/auth.service';
import { ReportingService } from './../../../insights/services/reporting.service';
import { ReportingContentService } from './../../services/reporting-content/reporting-content.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportingAPI } from '@app/reporting/models/api.model';
import { Option } from '../select/select.component';

interface PopularProvidersItem extends ReportingAPI.PopularProviders {
  y: number;
}

export interface CatalogTypeOption {
  Type: string;
  Name: string;
}

@Component({
  selector: 'dgx-popular-providers',
  templateUrl: './popular-providers.component.html',
  styleUrls: ['./popular-providers.component.scss'],
})
export class PopularProvidersComponent implements OnInit {
  @Input() public orgId: number;
  @Input() public groupId: number;
  @Input() public startDate: string;
  @Input() public endDate: string;

  public i18n = this.translate.instant([
    'dgPopularProviders_ProviderPlaceholder',
    'OrgReportingCtrl_InternalCatalog',
    'OrgReportingCtrl_ExternalCatalog',
    'OrgReportingCtrl_FullCatalog',
    'ReportingContentSvc_Name',
    'OrgReportingCtrl_ItemsAdded',
  ]);

  public chartName = '';
  public catalogType: CatalogTypeOption;
  public catalogTypes: CatalogTypeOption[] = [
    {
      Type: 'Internal',
      Name: this.i18n.OrgReportingCtrl_InternalCatalog,
    },
    {
      Type: 'External',
      Name: this.i18n.OrgReportingCtrl_ExternalCatalog,
    },
    {
      Type: 'All',
      Name: this.i18n.OrgReportingCtrl_FullCatalog,
    },
  ];

  public popularProviders: any = {
    Providers: [],
    Headers: [],
  };

  constructor(
    private translate: TranslateService,
    private reportingContentService: ReportingContentService,
    private reportingService: ReportingService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    const authUser = this.authService.authUser;
    this.chartName = this.reportingContentService.getChartName(
      'popularProviders'
    );
    if (
      authUser &&
      authUser?.defaultOrgInfo?.settings.hideExternalCatalog === true
    ) {
      this.catalogTypes.splice(1, 2); // remove last 2 options
    }
    this.catalogType = this.catalogTypes[0]; // set default
    this.getPopularProviders(
      this.orgId,
      this.startDate,
      this.endDate,
      this.catalogType,
      this.groupId
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.catalogType &&
      (changes.startDate || changes.endDate || changes.groupId || changes.orgId)
    ) {
      this.getPopularProviders(
        this.orgId,
        this.startDate,
        this.endDate,
        this.catalogType,
        this.groupId
      );
    }
  }

  public setSelectedCatalogType($event: Option) {
    if ($event) {
      this.catalogType = $event as CatalogTypeOption;
      this.getPopularProviders(
        this.orgId,
        this.startDate,
        this.endDate,
        this.catalogType,
        this.groupId
      );
    }
  }

  public getPopularProviders(orgId, startDate, endDate, catalogType, groupId) {
    const groupIdAdjust = groupId === 0 ? null : groupId,
      count = 10;
    this.reportingService
      .getPopularProviders(
        orgId,
        startDate,
        endDate,
        catalogType.Type,
        count,
        groupIdAdjust
      )
      .subscribe((data: PopularProvidersItem[]) => {
        let total = 0;
        data.forEach((item: PopularProvidersItem) => {
          total += item.ItemCount;
          item.y = item.ItemCount;
        });
        this.popularProviders = {
          Headers: [
            this.i18n.ReportingContentSvc_Name,
            this.i18n.OrgReportingCtrl_ItemsAdded,
          ],
          Total: total,
          Providers: data,
        };
      });
  }
}
