<dgx-modal-header
  *ngIf="!hideHeader"
  [canCancel]="true"
  (dismiss)="dismiss()"
  >{{
    isEditing
      ? this.i18n.TrackableLinkCtrl_EditModalTitle
      : this.i18n.TrackableLinkCtrl_ModalTitle
  }}</dgx-modal-header
>
<div class="guts-m-t-2 guts-p-h-2" *ngIf="!isEditing && description">
  <p class="par" [innerHtml]="description | safe: 'html'"></p>
</div>

<div class="guts-m-t-3 guts-p-h-2">
  <label class="font-semibold color-ebony zeta">{{
    'dgTrackableLink_Trackable' | translate
  }}</label>
  <dgx-copy-link
    [url]="url"
    [inputLabel]="'dgTrackableLink_Trackable' | translate"
    [buttonLabel]="
      isEditing
        ? i18n.TrackableLinkCtrl_CopyLinkAgain
        : i18n.TrackableLinkCtrl_CopyLink
    "
  ></dgx-copy-link>
</div>

<form>
  <div class="dg-modal__body">
    <div class="guts-p-h-1 guts-p-v-1">
      <label for="linkTitle" class="l_flexbar">
        <div class="font-semibold color-ebony zeta">
          {{ i18n.TrackableLinkCtrl_FormTitle }}
        </div>
        <div
          class="right-text color-ebony-a45 par par--small l_flex-grow l_flex-bottom"
        >
          {{ i18n.Core_Optional }}
        </div>
      </label>
      <input
        id="linkTitle"
        name="linkTitle"
        type="text"
        [(ngModel)]="localLink.name"
        [placeholder]="i18n.TrackableLinkCtrl_FormTitlePlaceholder"
        maxLength="1950"
        data-dgat="trackingLinkForm-a03"
      />
    </div>
  </div>
</form>
<dgx-modal-footer
  [canCancel]="true"
  (dismiss)="dismiss()"
  [useDefaultSubmitButton]="false"
>
  <button
    df-button
    dfButtonType="primary"
    data-dgat="newTrackingLink-e43"
    class="guts-p-h-2"
    (click)="submit(localLink)"
    [class.is_disabled]="isSubmitting"
    [attr.aria-disabled]="isSubmitting"
    [attr.aria-label]="
      isSubmitting ? i18n.Core_Loading : i18n.TrackableLinkCtrl_StartTracking
    "
  >
    <df-spinner-button [isSpinning]="isSubmitting">
      {{ i18n.TrackableLinkCtrl_StartTracking }}
    </df-spinner-button>
  </button>
</dgx-modal-footer>
