<div class="grid grid--justify-center grid--bleed">
  <div class="grid__col-sm-8">
    <div class="guts-p-t-3 guts-p-b-2 guts-m-b-half guts-p-h-1">
      <h1 *ngIf="!showNewLayout" class="h1" translate="dgNotificationMenu_Notifications"></h1>
    </div>
    <div class="guts-p-full-1" *ngIf="!hasNotifications && !isLoading">
      <p class="guts-p-v-1" translate="dgNotificationMenu_NothingNew"></p>
    </div>
    <div *ngIf="hasRequiredLearning && !isLoading">
      <section class="guts-p-full-1" title="" data-dgat="userNotifications-844">
        <div class="l_flexbar border-bottom guts-p-b-1 guts-m-b-3">
          <div class="l_flex-top guts-p-r-1">
            <df-icon
              [icon]="notificationService.lookupIcon('requiredlearning')"
              class="v-baseline"
            ></df-icon>
          </div>
          <div class="l_flex-grow">
            <div class="guts-p-b-half">
              <p class="par par--small guts-p-b-0">
                <a
                  [href]="assignedLearningLink"
                  data-dgat="Notifications-Index-80f"
                >
                  {{ requiredLearningPhrase }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- TODO: replace spinner with skeleton loaders -->
    <df-spinner *ngIf="isLoading"></df-spinner>
    <div
      infiniteScroll
      [infiniteScrollDisabled]="!hasMoreLoadableItems"
      [immediateCheck]="true"
      (scrolled)="list.loadNotifications(true)"
    >
      <dgx-notification-list
        #list
        [maxTitleLines]="maxTitleLines"
        [maxCommentLines]="maxCommentLines"
        [shouldLoadNotifications]="true"
        (isLoadingChange)="isLoading = $event"
      >
      </dgx-notification-list>
    </div>
  </div>
</div>
