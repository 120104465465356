<button
  type="button"
  [attr.role]="role"
  [attr.tabindex]="tabindex"
  [attr.aria-label]="label"
  [ngClass]="{
    'btn-sort--down': isDescending === false,
    'btn-sort--up': isDescending === true,
    'cursor-pointer': !isDisabled,
    'btn-sort--disabled': isDisabled
  }"
  (click)="sort()"
  data-dgat="thSort-263"
  class="ib btn-sort font-semibold"
>
  {{ buttonLabel }}
</button>
