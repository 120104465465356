import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { DfIconModule } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'dgx-expanding-title-section',
  templateUrl: './expanding-title-section.component.html',
  styleUrls: ['./expanding-title-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkAccordionModule, DfIconModule, CommonModule],
})
export class ExpandingTitleSectionComponent {
  @Input() public title: string;
  @Input() public rightText: string;
  constructor(public translate: TranslateService) {}
  getAriaText(expanded: boolean, title: string) {
    return this.translate.instant(
      expanded ? 'A11y_CollapsePanelButton' : 'A11y_ExpandPanelButton',
      {
        name: title,
      }
    );
  }
}
