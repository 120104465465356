import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import {
  ApiInputImageProps,
  InputContext,
} from '@app/user-content/user-input-v2/input.model';
import { BehaviorSubject, filter, lastValueFrom, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { InputDetails, InputIdentifier } from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { getMappedInferredSkills } from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { CourseTypeId } from '../inputs/course/course.model';
import { ResourceImage } from '@app/shared/services/resource-image/resource-image.model';
import { onFormControlUpdate } from '@app/shared/utils/angular-form-field-helpers';
import { UploadPictureAndCropResponse } from '@app/uploader/uploader-api.model';

export enum MediaMetadataStatus {
  None,
  Parsing,
  QuickParsed,
  FullyParsed,
}
// TODO: This will get refactored out and deleted as we separate this file into multiple services,
export interface InputIdForms {
  inputContext: InputContext;
  inputId?: number;
  duplicates?: InputDetails[];
  isSubmitButtonDisabled?: boolean;
}
/**
 * Base Input service to define common functionality, with the ability to overwrite if it is different.
 */
@Injectable()
export abstract class InputsFacadeBaseV2<
  T extends InputIdForms & ApiInputImageProps,
> {
  /**
   * Provides notifications to the attached form that either the form view model, the the form UI configuration
   * or both have changed
   */
  public viewModel$ = new BehaviorSubject<T>(undefined);

  public inferredSkillsTrigger$ = new Subject<{
    title: string;
    description?: string;
  }>();

  /**
   * TruncationLength for Summary/Description
   */
  public readonly truncationLength = 500; // number of characters

  /**
   * Provides notifications indicating the media status of the form data
   */
  protected mediaMetadataStatus$ = new BehaviorSubject(
    MediaMetadataStatus.None
  );

  /**
   * Provides notifications indicating the submission status of the form data
   */
  protected submissionStatus$ = new BehaviorSubject<SubmissionStatus>(
    SubmissionStatus.None
  );

  constructor(
    protected inputsService: InputsService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService
  ) {
    // if we have other states we want to send out
    // Show the Next/Submit button as spinning whenever we're parsing, waiting to submit on dependent data (such as a file upload or media parse), or actually submitting
    this.inferredSkillsTrigger$
      .pipe(
        // don't call if both title and description are not set
        filter(({ title, description }) => !!title || !!description),
        // I considered adding a debounceTime here, but as this is called when the title is blurred
        // we do want to update the inferred skills on each change. distinctUntilChanged is used to prevent duplicate API calls
        // if the title are the same as the last call.
        distinctUntilChanged(
          (prev, curr) =>
            prev.title === curr.title && prev.description === curr.description
        )
      )
      .subscribe(({ title, description }) => {
        this.getInferredSkills(title, description);
      });
  }

  /** Gets the latest view model */
  protected get viewModel(): T {
    return this.viewModel$.value as T;
  }

  protected set viewModel(viewModel: T) {
    this.viewModel$.next(viewModel as T);
  }

  /********************************************************
   * DUPLICATES
   ********************************************************/
  // TODO: factor this out to a duplicates serives
  /**
   * Reset view models duplicate values
   */
  public resetDuplicates() {
    this.viewModel = { ...this.viewModel, duplicateCount: 0, duplicates: [] };
  }

  /** Shows duplicate inputs modal already in the catalog */
  public viewDuplicates() {
    this.inputsService.viewDuplicates(
      this.viewModel.duplicates,
      undefined,
      this.viewModel.inputContext.pathwayId // pathway ID will be undefined in catalog context
    );
  }

  /**
   * Get the duplicates of the URL in the catalog manager
   * (this will be used in catalog and in plans/pathway when the checkbox is checked)
   * @param url to check
   */
  public async fetchUrlDuplicates(
    url: string,
    defaultOrgId?: number
  ): Promise<void> {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputContext.inputType as InputType,
    };

    const request$ = this.inputsService.getCmsInputsByUrl(
      this.viewModel.inputContext.organizationId ?? defaultOrgId,
      url,
      inputIdentifier
    );
    const response = await lastValueFrom(request$);
    if (response.inputs) {
      this.viewModel = {
        ...this.viewModel,
        duplicates: response.inputs,
        duplicateCount: response.inputs.length,
      };
    }
  }

  /******************************************************************
   * Image Uploading
   ******************************************************************/
  // TODO: factor these out to a Image uploading service  /**
  /**
   * On image upload success event, update the imageUrl and anything else we've got.
   *
   * @param form
   * @param event
   */
  public onImageUpload(form: FormGroup, event: UploadPictureAndCropResponse) {
    const { pictureUrl, legacyPictureUrl, resourceImageId } = event;
    // Update the form control
    onFormControlUpdate(form, 'image', pictureUrl);
    // THEN update the viewModel
    this.viewModel = {
      ...this.viewModel,
      imageUrl: pictureUrl,
    };
    // AND the resourceImageId, where present. Will exist when the image
    // is new, but not when an existing image's crop/alt text is updated.
    if (resourceImageId) {
      // AND the legacyPictureUrl, where present *and* when NOT editing an existing image.
      if (
        legacyPictureUrl &&
        resourceImageId !== this.viewModel.resourceImageId
      ) {
        this.viewModel = {
          ...this.viewModel,
          legacyPictureUrl,
        };
      }
      // Update resourceImageId now that we've checked it.
      this.viewModel = {
        ...this.viewModel,
        resourceImageId,
      };
    }
  }

  /**
   * On image parsed event, update the resourceImageId/legacyPictureUrl. Fires
   * whenever an image's imageUrl field is parsed (for example, when the modal
   * first opens with an existing image).
   *
   * @param resourceImage
   * @param legacyPictureUrl
   */
  public onImageParse({ resourceImageId, legacyPictureUrl }: ResourceImage) {
    // WHEN neither resourceImageId nor legacyPictureUrl are present
    if (!resourceImageId && !legacyPictureUrl) {
      // Don't do anything, we've already got other events for this.
      return;
    }
    // Add the resourceImageId, where present. Will exist for an existing image,
    // WON'T be present when crop/alt text is updated.
    if (resourceImageId) {
      // AND the legacyPictureUrl, where present *and* when editing an existing image.
      if (
        legacyPictureUrl &&
        resourceImageId === this.viewModel.resourceImageId
      ) {
        this.viewModel = {
          ...this.viewModel,
          legacyPictureUrl,
        };
      }
      // Update resourceImageId now that we've checked it.
      this.viewModel = {
        ...this.viewModel,
        resourceImageId,
      };
    }
  }

  /**
   * On Image deletion remove the imageURL from the view model.
   *
   * @param form
   */
  public onImageDelete(form: FormGroup) {
    // Update the form control
    onFormControlUpdate(form, 'image', '');
    // Update the VM.
    this.viewModel = {
      ...this.viewModel,
      imageUrl: '',
      legacyPictureUrl: '',
      resourceImageId: undefined,
    };
  }

  /******************************************************************
   * Content Owner
   ******************************************************************/
  // TODO: Factor this out to a content owner service
  /**
   * On add or change of content owner field, update the view model.
   */
  public onContentOwnerChange(contentOwner: AnyRecommendee): void {
    this.viewModel = {
      ...this.viewModel,
      owner: contentOwner,
    };
  }

  /******************************************************************
   * Submission
   ******************************************************************/

  public abstract onSubmit(form: FormGroup): Promise<void>;

  public abstract onNext(...args: [string | CourseTypeId]): Promise<void>;

  protected abstract performSuccessSideEffects();

  /******************************************************************
   * View Model
   ******************************************************************/
  // TODO: factor this out to a  View model management service

  // Initialize the view model with the passed in context, default properties, and computed properties
  protected initializeContextViewModel(inputContext: InputContext) {
    // On initialization of context make sure to start with a new reset model
    this.viewModel = undefined;

    // Create imageUploadAdapter properties.
    const imageUploadAdapterProps: (InputType | number | string)[] = [
      inputContext.inputType,
      inputContext.inputId,
    ];
    // WHERE there is a pathwayId and we AREN'T editing catalog content, add it
    // as our parent resource ID/type.
    // TODO: Verify that plans don't need special handling? It really seems like
    // internally-added plan content should also need targetId and 'Target' to be
    // passed to the upload adapter, but there's no use of RenderMode.Targets
    // anywhere in the app, even though it exists.
    if (
      !!inputContext.pathwayId &&
      (!inputContext.isCmsContent || !inputContext.isEditingInternalContent)
    ) {
      imageUploadAdapterProps.push('Pathway', inputContext.pathwayId);
    }

    // initialize context
    this.viewModel = {
      ...this.viewModel,
      inputContext: inputContext,
      inputType: inputContext.inputType,
      isInitialForm: inputContext.isEditing ? false : true,
      organizationId: inputContext.organizationId,
      loadInferredSkills: this.loadInferredSkills.bind(this),
      imageUpload: {
        onUpload: this.onImageUpload.bind(this),
        onParse: this.onImageParse.bind(this),
        onDelete: this.onImageDelete.bind(this),
      },
      imageUploadAdapter: this.inputImageUploadAdapterService.getAdapter(
        ...imageUploadAdapterProps
      ),
    };
  }

  /******************************************************************
   * Content Hosting
   ******************************************************************/
  // TODO: Factor this out to a content hosting service
  // NOTE: We are adding the content uploader to plans|Pathways & catalog
  // for the uploader when in progress we will disable the button
  // But after succeeded we will re-enable the submit button, but we will nit
  // automatically send the user to the next screen giving the user control to look over the upload first.
  // NOTE: article format is only catalog
  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {
    this.viewModel.isSubmitButtonDisabled = false;
  }

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
  }

  protected onContentFileChange(file: File) {
    this.viewModel.isSubmitButtonDisabled = true;
  }

  public loadInferredSkills(title: string, description: string): void {
    this.inferredSkillsTrigger$.next({ title, description });
  }

  protected async getInferredSkills(
    title: string,
    description?: string
  ): Promise<void> {
    try {
      const response = await this.inputsService.getInferredSkills(
        title,
        description
      );

      this.viewModel = {
        ...this.viewModel,
        highConfidenceInferredSkills: getMappedInferredSkills(
          response.highConfidence
        ),
        mediumConfidenceInferredSkills: getMappedInferredSkills(
          response.mediumConfidence
        ),
      };
    } catch (e) {
      console.error('Error in loadInferredSkills', e);
    }
  }
}
