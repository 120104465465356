import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import type { AuthorContentComponent } from '@app/author/author.model';
import { AuthorContentService } from '@app/author/services/author-content.service';
import { AuthorVideoService } from '@app/author/services/author-video.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import type { InputType } from '@app/shared/models/core-api.model';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { msToTimeString } from '@app/shared/utils/time-utils';
import { WindowToken } from '@app/shared/window.token';
import { FileChunkUploaderService } from '@app/uploader/file-chunk-uploader/file-chunk-uploader.service';
import { DfButtonBasicComponent } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, fromEvent, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ContentDurationService } from '@app/shared/services/content/content-duration.service';

export type FileUploadStatus = 'unknown' | 'success' | 'error';

/**
 * The AuthorVideoComponent handles the recording and uploading of user authored videos. Videos can be recorded by:
 * - selecting the 'Record Video' link in global add -> video.
 * - (deprecated) selecting the 'Record Video' link in the "More" menu in the user's feed
 * - Adding video content from an Org or Channel Catalog
 *
 * When not recording the controller reverts to showing the media player.
 *
 * The controller relies on the MediaRecorder api being available in the user's browser if not, video can't be recorded and an
 * error notification will be shown.
 *
 * When recording has finished and the video has been successfully uploaded, the user will be redirected based on any
 * "redirect" query string values:
 *   * redirect=feed - the user will be redirected to their feed
 *   * redirect=gat - the user will be redirected to then channel content
 *   * redirect= - the user will be redirected to the org content (orgId must be set)
 */
@Component({
  selector: 'dgx-author-video',
  templateUrl: './author-video.component.html',
  styles: [
    `
      .progress-min-width {
        min-width: 60px;
      }
    `,
  ],
})
export class AuthorVideoComponent
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit, AuthorContentComponent
{
  public static readonly VIDEO_WIDTH = 648;
  public static readonly VIDEO_HEIGHT = 364;

  // properties for AuthorContent
  public readonly inputType: InputType = 'Video';

  @Input() public redirectUrl: URL;
  @Output() public uploadResult = new EventEmitter<any>();

  @ViewChild('userMediaVideo', { static: true })
  public userMediaVideoRef: ElementRef<HTMLVideoElement>;
  @ViewChild('recordedVideo', { static: true })
  public recordedVideoRef: ElementRef<HTMLVideoElement>;
  @ViewChild('startRecording', { static: true })
  public startRecordingRef: DfButtonBasicComponent;

  public readonly i18n = this.translate.instant([
    'dgAuthorVideo_StartRecording',
    'dgAuthorVideo_StopRecording',
    'dgAuthorVideo_RedoRecording',
    'dgAuthorVideo_Uploading',
    'dgAuthorVideo_ProcessingVideo',
    'dgAuthorVideo_Redirecting',
    'dgAuthorVideo_TryAgain',
    'Core_Next',
  ]);

  public initialTimeStamp = 0;
  public videoTimer = '00:00';
  public videoTimerMinutes = 0;
  public uploadProgress = 0;
  public isRecording = false;
  public disableRecord = true;
  public showPlayer = false;
  public processingUpload = false;
  public videoTimerMinutesDisplay = '';
  public uploadLabel: string;
  public fileUploadStatus: FileUploadStatus;
  public timeLimit: number;
  public totalTime: number;
  public eventTime: number = 0;
  private stream: MediaStream;
  private timeWarning: number;
  private maxTimeWarningMinutes: number;
  private mediaRecorder: any;
  private videoBlob: any;
  private hasBeenWarned = false;
  private hasBeenStopped = false;
  private recordedBlobs = [];
  private destroy$ = new Subject();

  constructor(
    private contentDurationService: ContentDurationService,
    private authorContentService: AuthorContentService,
    private authorVideoService: AuthorVideoService,
    private cdr: ChangeDetectorRef,
    private fileChunkUploaderService: FileChunkUploaderService,
    private modalService: ModalService,
    private notifier: NotifierService,
    private translate: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
  }

  public ngOnInit(): void {
    this.authorContentService
      .getConstraints('Video')
      .pipe(takeUntil(this.destroy$))
      .subscribe((constraints) => {
        this.timeLimit = constraints.maxVideoLengthMinutes * 60 * 1000; // minutes to milliseconds
        this.maxTimeWarningMinutes =
          constraints.maxVideoLengthWarningMinutes * 60 * 1000;
        this.timeWarning =
          this.timeLimit - constraints.maxVideoLengthWarningMinutes * 60 * 1000;
      });
  }

  public ngAfterViewInit(): void {
    this.initializeMediaRecorder();
    this.initializeMediaPlayer();
  }

  public toggleRecording(): void {
    if (!this.isRecording) {
      this.resetTimer();
      this.startRecording();
      this.isRecording = true;
      this.showPlayer = false;
    } else {
      this.stopRecording();
      this.isRecording = false;
      this.showPlayer = true;
    }
  }

  public resetTimer(): void {
    this.initialTimeStamp = 0;
    this.videoTimer = '00:00';
    this.videoTimerMinutes = 0;
    this.videoTimerMinutesDisplay = '';
  }

  public startRecording(): void {
    this.recordedBlobs = [];

    this.mediaRecorder = this.authorVideoService.getMediaRecorder(this.stream);
    if (this.mediaRecorder) {
      this.mediaRecorder.addEventListener(
        'stop',
        this.handleMediaRecorderStop.bind(this)
      );
      this.mediaRecorder.addEventListener(
        'dataavailable',
        this.handleMediaRecorderDataAvailable.bind(this)
      );
      this.mediaRecorder.start(10); // collect 10ms of data
    }
  }

  public stopRecording(): void {
    this.mediaRecorder.stop();
  }

  /**
   * Show a modal to the user to confirm the recording
   */
  public redoRecording(): void {
    const inputs = {
      headerText: this.translate.instant('Core_AreYouSure'),
      bodyText: this.translate.instant('dgAuthorVideo_ConfirmRedoRecording'),
      submitButtonText: this.translate.instant('Core_Confirm'),
      canCancel: true,
    };

    this.modalService.show(SimpleModalComponent, { inputs }).subscribe(() => {
      this.recordedVideoRef.nativeElement.pause();
      this.isRecording = false;
      this.showPlayer = false;
      this.resetTimer();

      this.cdr.markForCheck();

      this.windowRef.setTimeout(() => {
        this.startRecordingRef?.elementRef?.nativeElement?.focus();
      });
    });
  }

  /**
   * Uploaded the recorded video to the server
   */
  public uploadVideo(): void {
    const videoBlob = new Blob(this.recordedBlobs, {
      type: this.mediaRecorder.mimeType,
    });
    const fileOfBlob = new File([videoBlob], 'blob.webm');
    const totalSeconds = Math.ceil(this.totalTime / 1000);

    this.uploadLabel = this.i18n.dgAuthorVideo_Uploading;

    this.fileUploadStatus = undefined;
    this.uploadProgress = 0;
    this.processingUpload = true;

    const onProgress = (progress: number) => {
      this.uploadProgress = Math.round(progress);
      this.cdr.detectChanges();
    };

    this.fileChunkUploaderService
      .uploadFile(fileOfBlob, 'UserAuthoredVideo', onProgress)
      .pipe(
        tap((result) => {
          if (this.redirectUrl) {
            this.uploadLabel = this.i18n.dgAuthorVideo_Redirecting;
            const searchParams = new URLSearchParams();
            searchParams.set('authored', 'Video');
            searchParams.set('url', result.fileUrl);
            searchParams.set('seconds', totalSeconds.toString());

            // append the search params to the hash fragment (for ajs routes), or to the url proper
            const redirectTo = new URL(this.redirectUrl.toString());
            if (redirectTo.hash) {
              redirectTo.hash += '?' + searchParams.toString();
            } else {
              // copy the params to the route
              searchParams.forEach((value, key) => {
                redirectTo.searchParams.set(key, value);
              });
            }

            // redirect the user to add the newly created media
            this.windowRef.location.href = redirectTo.toString();
          }
          this.fileUploadStatus = 'success';
        }),
        catchError((error) => {
          this.uploadLabel = this.translate.instant(
            'dgAuthorVideo_UploadError'
          );
          this.fileUploadStatus = 'error';
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        if (result) {
          this.uploadResult.emit({
            result,
            file: fileOfBlob,
          });
        }
      });
  }

  /**
   * Called once after the view initialized to set up the video container for the user media
   */
  private initializeMediaRecorder() {
    const userMediaVideoEl = this.userMediaVideoRef.nativeElement;

    this.authorVideoService
      .initializeMediaStream({
        audio: true,
        video: {
          width: AuthorVideoComponent.VIDEO_WIDTH,
          height: AuthorVideoComponent.VIDEO_HEIGHT,
        },
      })
      .subscribe((stream) => {
        this.disableRecord = false;
        this.stream = stream;
        userMediaVideoEl.srcObject = stream;
        // ensure that this stays muted
        userMediaVideoEl.muted = true;
      });
  }

  /**
   * Called once after the view initialized to set up the video container for the recorded video
   */
  private initializeMediaPlayer() {
    const recordedVideoEl = this.recordedVideoRef.nativeElement;
    recordedVideoEl.loop = false;
    recordedVideoEl.controls = true;

    const recordedVideoErrorEvents = fromEvent(recordedVideoEl, 'error').pipe(
      tap(() => {
        this.notifier.showError(
          this.translate.instant('dgAuthorVideo_playerError')
        );
      })
    );

    const recordedVideoLoadedmetadataEvents = fromEvent(
      recordedVideoEl,
      'loadedmetadata'
    ).pipe(
      tap(() => {
        this.contentDurationService.triggerVideoDurationUpdate(recordedVideoEl);
      })
    );

    // ensure these event listeners are unsubscribed when the component is destroyed
    forkJoin([recordedVideoErrorEvents, recordedVideoLoadedmetadataEvents])
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * When the media recorder is stopped, gather the emitted blobs from the stream and preview the recording
   * for the user
   */
  private handleMediaRecorderStop() {
    this.videoBlob = new Blob(this.recordedBlobs, {
      type: this.mediaRecorder.mimeType,
    });
    const recordedVideoEl = this.recordedVideoRef.nativeElement;
    const objectURL = URL.createObjectURL(this.videoBlob);
    if (recordedVideoEl.src) {
      // ensure the object url is cleaned up afterwards
      URL.revokeObjectURL(recordedVideoEl.src);
    }
    recordedVideoEl.src = objectURL;
  }

  /**
   * Handle the blobs emitted from the UserMedia stream
   */
  private handleMediaRecorderDataAvailable(event: BlobEventInit) {
    if (this.initialTimeStamp === 0) {
      this.initialTimeStamp = event.timecode;
    }
    // only set the event time if the video is still recording
    if (this.isRecording) {
      this.eventTime = event.timecode;
    }
    this.totalTime = this.eventTime - this.initialTimeStamp;
    const videoTimer = msToTimeString(this.totalTime);
    const videoTimerMinutes = Math.floor(this.totalTime / 1000 / 60);

    if (videoTimer !== this.videoTimer) {
      this.videoTimer = videoTimer;
      if (videoTimerMinutes !== this.videoTimerMinutes) {
        // this is just for a11y to announce each minute to screen readers
        this.videoTimerMinutes = videoTimerMinutes;
        this.videoTimerMinutesDisplay = this.translate.instant(
          'dgAuthorVideo_TimeInMinutes',
          { timeInMinutes: this.videoTimerMinutes }
        );
      }
    }

    if (this.totalTime >= this.timeWarning && !this.hasBeenWarned) {
      const warningMessage = this.translate.instant(
        'dgAuthorVideo_TimeWarningFormat',
        {
          timeRemaining: Math.floor(this.maxTimeWarningMinutes / 1000 / 60),
        }
      );
      this.notifier.showWarning(warningMessage);
      this.hasBeenWarned = true;
    }

    if (this.totalTime >= this.timeLimit && !this.hasBeenStopped) {
      const errorMessage = this.translate.instant(
        'dgAuthorVideo_TimeLimitReached'
      );
      this.notifier.showError(errorMessage);
      this.toggleRecording();
      this.hasBeenStopped = true;
    }

    if (event.data && event.data.size > 0) {
      this.recordedBlobs.push(event.data);
    }

    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    // Turns off media devices
    this.stream?.getTracks().forEach(function (track) {
      track.stop();
    });
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
