import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DfIconSize } from '../../../../icon';

export const BUTTON_ACTION_STYLES = {
  medium: {
    buttonClass: 'button-action--medium',
    iconSize: 'medium' as ActionButtonSize,
  },
  small: {
    buttonClass: 'button-action--small',
    iconSize: 'small' as ActionButtonSize,
  },
  'extra-small': {
    buttonClass: 'button-action--extra-small',
    iconSize: 'small' as ActionButtonSize,
  },
};
/** An Action Button with customizable icon, design (pill, square), and content. */
@Component({
  selector: 'df-button-action',
  templateUrl: './button-action.component.html',
  styleUrls: ['./button-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfButtonActionComponent implements OnChanges, OnInit {
  @Input() public design: Design = 'pill';
  @Input() public size: ActionButtonSize = 'extra-small';
  @Input() public icon: string;
  @Input() public isActive = false;
  @Input() public autoToggleState = true;
  @Input() public dataDgat: string;
  @Input() public isDisabled = false;
  @Input() public ariaLabel: string;

  // When autoToggleState = true, this fires at the
  // same time as the native 'click' event. It's mainly provided for
  // bidirectional binding syntax convenience in that case:
  // [(isActive)]="myVar"
  @Output() public isActiveChange = new EventEmitter<boolean>();

  @ViewChild('label', { static: true }) public label: ElementRef;

  public style: {
    buttonClass: string;
    iconSize: ActionButtonSize;
  } = BUTTON_ACTION_STYLES['extra-small'];

  constructor(public elementRef: ElementRef) {}

  public ngOnInit() {
    const hasTextLabel = !!this.label.nativeElement.textContent.trim();

    if (!hasTextLabel && !this.ariaLabel) {
      console.warn(
        'WARNING: DfButtonActionComponent is not a11y compliant. Please provide an aria-label or text content.'
      );
    }
  }

  public ngOnChanges({ design, size }: SimpleChanges): void {
    if (size?.currentValue) {
      this.style = this.getStyle(size.currentValue);
    }

    if (design?.currentValue) {
      this.addClass(`button-action--${design.currentValue}`);
    }
  }

  public onClick() {
    if (this.autoToggleState) {
      this.isActive = !this.isActive;
      this.isActiveChange.emit(this.isActive);
    }
  }

  private addClass(className: string): void {
    this.elementRef.nativeElement
      .querySelector('button')
      .classList.add(className);
  }

  private getStyle(size: ActionButtonSize): any {
    if (!BUTTON_ACTION_STYLES.hasOwnProperty(size)) {
      // eslint-disable-next-line
      console.info(
        `ActionButtonSize ${size} is not valid. Defaulting to size 'extra-small'.`
      );
      size = 'extra-small';
    }

    return BUTTON_ACTION_STYLES[size];
  }
}

type Design = 'pill' | 'square';
type ActionButtonSize = Exclude<DfIconSize, 'large'>;
