import { DecimalPipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { ColorService } from '@app/shared/services/color.service';
import { HighchartsService } from '@app/shared/services/highcharts.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { UserService } from '@app/user/services/user.service';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SkillSectionsChartService {
  public i18n = this.translateService.instant([
    'Core_Title',
    'SkillSectionsChart_SkillRatingsBreakdown',
    'Core_Subtitle',
    'SkillRatingsChart_SelfRatings',
    'SkillRatingsChart_ManagerRatings',
    'SkillRatingsChart_Evaluations',
    'SkillSectionsChart_BelowTarget',
    'SkillSectionsChart_SingularRating',
    'SkillSectionsChart_PluralRatings',
    'SkillSectionsChart_PercentOfRatings',
    'TagsSvc_CredentialableTagsError',
  ]);

  private authUser: AuthUser;
  private orgId: number;
  private skillMax: number;

  constructor(
    private authService: AuthService,
    private colorService: ColorService,
    private decimalPipe: DecimalPipe,
    private highchartsService: HighchartsService,
    private http: NgxHttpClient,
    private trackerService: TrackerService,
    private translateService: TranslateService,
    private userService: UserService,
    private ldFlagService: LDFlagsService
  ) {
    this.authUser = this.authService.authUser;
    this.orgId = this.authUser?.orgInfo[0].organizationId;
    this.skillMax = this.authUser?.orgRatingScale?.anchorHigh;
  }

  public generateChart(data, context): void {
    const response = data.items ?? data,
      responseCopy = data;
    response.forEach((skill, index) => {
      const chartOptions = {
        accessibility: {
          enabled: true,
          keyboardNavigation: {
            enabled: true,
          },
          screenReaderSection: {
            beforeChartFormat:
              this.i18n.Core_Title +
              this.i18n.SkillSectionsChart_SkillRatingsBreakdown +
              this.i18n.Core_Subtitle +
              skill.tag.title,
          },
          point: {
            descriptionFormatter: (point) =>
              context.skillSectionsChartService.getA11yPointDescription(
                point,
                skill.tag.title, // title
                skill.ratingCount, // num of ratings
                skill.target, // target
                skill.ratingNotAvailable, // rating not available
                context.isOrgView // Location
              ),
          },
        },
        chart: {
          width: 385,
          height: 114,
          marginTop: 0,
          marginBottom: 25,
          renderTo: 'graphContainer' + skill.GUID,
        },
        title: {
          text: '',
          margin: 0,
        },
        yAxis: {
          min: 0,
          endOnTick: skill.ratingCount === 0 ? true : false,
          maxPadding: 0,
          title: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
          gridLineColor: 'transparent',
        },
        xAxis: {
          tickLength: 0,
          lineWidth: 0,
          categories: Array.from({ length: this.skillMax }, (x, i) => i + 1), // [1, 2, to this.skillMax]
          labels: {
            formatter: function () {
              if (skill.target === this.value && !skill.ratingNotAvailable) {
                return (
                  '<span class="font-semibold color-ebony">' +
                  this.value +
                  '</span>'
                );
              } else {
                return (
                  '<span class="font-regular color-ebony-a61">' +
                  this.value +
                  '</span>'
                );
              }
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            groupPadding: 0,
            minPointLength: 5,
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            cursor: !context.canViewIndividualRatings ? 'default' : 'pointer',
            point: {
              events: this.getPointEvents(
                responseCopy,
                skill,
                context.ratingType,
                context.canViewIndividualRatings,
                context.groupIdList,
                context.teamId,
                context.managerProfileKey,
                context.includeFullManagerHierarchy,
                context.facets
              ),
            },
          },
        },
        tooltip: {
          useHTML: true,
          outside: true,
          pointFormat: '<span>{point.y}</span>',
          formatter: function () {
            return context.skillSectionsChartService.getToolTip(
              this.x, // rating
              skill.tag.title, // title
              this.y, // users with rating
              skill.ratingCount, // num of ratings
              skill.target, // target
              skill.ratingNotAvailable, // rating not available
              context.isOrgView // Location
            );
          },
        },
        series: [
          {
            type: 'column',
            name: 'Rating',
            borderRadius: '5',
            data: this.buildColumnData(skill, this.skillMax),
          },
          {
            type: 'line',
            name: 'Target',
            data: skill.ratingNotAvailable
              ? null
              : this.getTargetData(skill.target),
            marker: {
              lineWidth: 1,
              lineColor: this.colorService.getColor('ebony'),
              fillColor: this.colorService.getColor('ebony'),
            },
            enableMouseTracking: false,
          },
        ],
      };
      this.highchartsService.createChart(undefined, chartOptions).subscribe();
    });
  }

  public getRatingTypeOptions() {
    const ratingTypeOptions = [
      [
        {
          Name: this.i18n.SkillRatingsChart_SelfRatings,
          Type: 'Self',
        },
      ],
    ];
    if (this.authUser.canRequestManagerRating) {
      ratingTypeOptions[0].push({
        Name: this.i18n.SkillRatingsChart_ManagerRatings,
        Type: 'Manager',
      });
    }
    if (this.authUser.canEvaluateSkills) {
      ratingTypeOptions[0].push({
        Name: this.i18n.SkillRatingsChart_Evaluations,
        Type: 'Evaluation',
      });
    }

    if (this.ldFlagService.charts.gaiInferredSkillRating) {
      ratingTypeOptions[0].push({
        Name: 'Inferred Ratings',
        Type: 'Inferred',
      });
    }

    return ratingTypeOptions;
  }

  public getA11yPointDescription(
    point: any,
    title: string,
    totalUsers: number,
    target: number,
    ratingNotAvailable: boolean
  ): string {
    const pointDescription = this.buildPointDescription(
      point.category, // rating
      point.y, // usersWithRating
      totalUsers,
      target,
      ratingNotAvailable
    );

    const description = `${pointDescription[0]}, ${title}, ${point.y} ${pointDescription[2]}, ${pointDescription[1]} ${pointDescription[3]}`;
    return description;
  }

  public getToolTip(
    rating: number,
    title: string,
    usersWithRating: number,
    totalUsers: number,
    target: number,
    ratingNotAvailable: boolean,
    isOrgView: boolean
  ): string {
    const pointDescription = this.buildPointDescription(
      rating,
      usersWithRating,
      totalUsers,
      target,
      ratingNotAvailable
    );

    const toolTip =
      '<div>' +
      '<p class="font-semibold guts-p-b-0">' +
      pointDescription[0] +
      '</p>' +
      '<p class="par par--small par--light guts-p-b-quart">' +
      title +
      '</p>' +
      '<p class="font-medium guts-p-b-0">' +
      usersWithRating +
      ' ' +
      pointDescription[2] +
      '</p>' +
      '<p class="font-medium guts-p-b-0">' +
      pointDescription[1] +
      pointDescription[3] +
      '</p>' +
      '</div>';
    this.trackerService.trackEventData({
      action: 'Skill Rating Breakdown Hovered',
      properties: {
        Name: title,
        RatingScale: this.skillMax,
        Level: rating,
        RatingCount: usersWithRating,
        RatingCountPerc: pointDescription[2],
        Location: isOrgView ? 'Org Skill Insights' : 'Plan Insights',
      },
    });
    return toolTip;
  }

  private buildPointDescription(
    rating: number,
    usersWithRating: number,
    totalUsers: number,
    target: number,
    ratingNotAvailable: boolean
  ) {
    let atRating: string;
    if (target === 0 || target === undefined || ratingNotAvailable) {
      atRating = this.translateService.instant('SkillSectionsChart_Level', {
        ratingNumber: rating,
      });
    } else if (rating < target) {
      atRating = this.translateService.instant(
        'SkillSectionsChart_BelowTarget',
        {
          ratingNumber: rating,
        }
      );
    } else if (rating === target) {
      atRating = this.translateService.instant('SkillSectionsChart_AtTarget', {
        ratingNumber: rating,
      });
    } else {
      atRating = this.translateService.instant(
        'SkillSectionsChart_AboveTarget',
        {
          ratingNumber: rating,
        }
      );
    }

    const ratingPercentage =
      totalUsers === 0
        ? 0
        : this.decimalPipe.transform(
            (usersWithRating / totalUsers) * 100,
            '1.0-0'
          );
    const singularPluralRating =
      usersWithRating === 1
        ? this.i18n.SkillSectionsChart_SingularRating
        : this.i18n.SkillSectionsChart_PluralRatings;

    const percentageOfRatings = this.i18n.SkillSectionsChart_PercentOfRatings;

    return [
      atRating,
      ratingPercentage,
      singularPluralRating,
      percentageOfRatings,
    ];
  }

  private buildColumnData(skill, count: number) {
    const columnData = [];
    for (let i = 0; i < count; i++) {
      columnData.push({
        y: skill.tagRatings[i],
        color: this.getColor(
          skill.target,
          i + 1,
          skill.tagRatings[i],
          skill.ratingNotAvailable
        ),
      });
    }
    return columnData;
  }

  private getColor(
    target: number,
    currentRating: number,
    peopleWhoHaveRated: number,
    ratingNotAvailable: boolean
  ) {
    if (ratingNotAvailable) {
      return this.colorService.getColor('ebony-a18');
    } else if (!target && peopleWhoHaveRated === 0) {
      return this.colorService.getColor('purple');
    } else if (!target && peopleWhoHaveRated > 0) {
      return this.colorService.getColor('purple');
    } else if (currentRating < target && peopleWhoHaveRated === 0) {
      return this.colorService.getColor('error-light');
    } else if (currentRating >= target && peopleWhoHaveRated === 0) {
      return this.colorService.getColor('success-light');
    } else if (currentRating < target && peopleWhoHaveRated > 0) {
      return this.colorService.getColor('error');
    } else {
      return this.colorService.getColor('success');
    }
  }

  private getPointEvents(
    responseCopy,
    skill,
    ratingType,
    canViewIndividualRatings,
    groupIdList,
    teamId?,
    managerProfileKey?,
    includeFullManagerHierarchy?,
    facets?
  ) {
    return {
      click: (event) => {
        const point = event.point || event.target;
        if (!canViewIndividualRatings || point.y === 0) {
          return {};
        }
        let endpointUrl, params;
        if (teamId) {
          endpointUrl = '/managers/getteamtagratingusers';
          params = {
            orgId: this.orgId,
            managerProfileKey:
              managerProfileKey ?? this.authUser.viewerProfile.userProfileKey,
            tagId: skill.tagId,
            ratingLevel: point.index + 1,
            ratingType,
            includePrivateProfiles: true,
            includeFullManagerHierarchy,
            facets: JSON.stringify(facets),
          };
        } else {
          endpointUrl = '/reporting/gettagratingusers';
          params = {
            orgId: this.orgId,
            groupIdList: groupIdList,
            ratingType: ratingType,
            tagId: skill.tagId,
            ratingLevel: point.index + 1,
            expectedCount: point.y,
          };
        }
        const endpoint = {
          url: endpointUrl,
          params: params,
        };
        const showFollowBtns = false,
          disableLink = teamId ? true : false;
        let users = [],
          showAnonLearners; // undefined for team scenario;
        this.http
          .get(endpoint.url, {
            params: endpoint.params,
          })
          .toPromise()
          .then(
            (data: any) => {
              // Adapt for differences in endpoint response structure
              users = data.item1 || data;
              // Always undefined for team response
              showAnonLearners = data.item2;
              this.userService.showUsersList(
                skill.name,
                users,
                event,
                disableLink,
                showFollowBtns,
                showAnonLearners
              );
              return data;
            },
            (error) => {
              throw new DgError(
                this.i18n.TagsSvc_CredentialableTagsError,
                error
              );
            }
          );
        this.trackerService.trackEventData({
          action: 'Skill Insights Individual Drilldown Viewed',
          properties: {
            Name: skill.name,
            RatingScale: this.skillMax,
            Level: point.index + 1,
            FilteredGroupID: groupIdList,
            FilteredTeamID: teamId,
            FilteredRatingType: ratingType,
            Location: !teamId ? 'Org Skill Insights' : 'Team Skill Insights',
          },
        });
      },
    };
  }

  private getTargetData(target: number) {
    if (target > 0) {
      const targetData = Array.apply(null, Array(target - 1)).map(() => null);
      targetData.push(0);
      return targetData;
    } else {
      return [];
    }
  }
}
