import { Injectable } from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserData } from '../models/user-data.model';
import { AuthService } from '@dg/shared-services';

@Injectable({
  providedIn: 'root',
})
export class EbbAuthUserService extends AuthService {
  private userDataSubject = new BehaviorSubject<UserData>(null);
  private _isLoggedIn: boolean;

  public readonly userData: Observable<UserData | null> = this.userDataSubject.asObservable();

  /** Gets the data for an authenticated user, if any */
  public get authUser() {
    return camelCaseKeys(this.userDataSubject.value)?.authedUser;
  }

  private addDefaultOrgInfoIfExist(userData) {
    const { AuthedUser } = userData;
    if (AuthedUser && Object.keys(AuthedUser).length && (AuthedUser.DefaultOrgId !== undefined && AuthedUser.DefaultOrgId !== null)) {
      AuthedUser.DefaultOrgInfo = { OrganizationId: AuthedUser.DefaultOrgId };
    }
    return userData;
  }

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  public getUserData(): Promise<UserData> {
    return this.userData.pipe(take(1)).toPromise();
  }

  public setUserData(userData: UserData, mergeWithExisting = false) {
    this.isLoggedIn = !!userData;
    const ud = this.process(this.userDataSubject.getValue());
    let nextValue = mergeWithExisting
      ? Object.assign({}, ud, userData)
      : userData;
    nextValue = this.addDefaultOrgInfoIfExist(nextValue);
    this.userDataSubject.next(nextValue);
  }

  private process(userData: UserData) {
    if (userData && userData.MemberInOrg) {
      userData.InOrganization = userData.MemberInOrg;
    }
    return userData;
  }
}
