import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MarkdownToHtmlPipe } from '@app/markdown/pipes/markdown-to-html.pipe';
import { MarkdownToHtmlOptions } from '@app/markdown/services/markdown.service';
import { MentionItem } from '../../comments.model';
import { MentionService } from '../services/mention.service';

interface MentionToHtmlOptions extends MarkdownToHtmlOptions {
  mentions: MentionItem[];
}

@Pipe({
  name: 'mentionToHtml',
  standalone: true,
  pure: true,
})
// allows this to be imported into standalone pipes.
@Injectable({
  providedIn: 'root',
})
export class MentionToHtmlPipe implements PipeTransform {
  public constructor(
    private markdownToHtmlPipe: MarkdownToHtmlPipe,
    private mentionService: MentionService
  ) {}

  public transform(
    input: string,
    { mentions, ...options }: MentionToHtmlOptions
  ): string {
    if (!input) {
      return '';
    }
    // Default options are sanitizing and stripping HTML. Enforce that, though.
    options = {
      ...options,
      sanitize: true,
      stripSourceHtml: true,
    };
    const safeHtml = this.markdownToHtmlPipe.transform(input, options);
    return this.mentionService.formatCommentAndMentionsForDisplay(
      safeHtml,
      mentions
    );
  }
}
