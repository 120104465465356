import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';

import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';

import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { TagsFieldComponent } from '@app/form-fields/wrappers/tags-field/tags-field.component';
import { AssessmentFormModel } from '../assessment-form.model';

@Injectable({ providedIn: 'root' })
export class UserProfileEditAssessmentRendererService implements FormRenderer {
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private translateService: TranslateService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<AssessmentFormModel>
  ): DfFormFieldConfig[] {
    const vm: AssessmentFormModel = context.state();

    return [
      this.formBuilder
        .title()
        .labeled(context.translationKeys.AssessmentFormCtrl_AssessmentName)
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentName
        )
        .withDgatId('assessmentForm-487')
        .autofocused()
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translateService.instant('Core_FieldRequiredFormat', {
              fieldName: this.translateService.instant(
                context.translationKeys.AssessmentFormCtrl_AssessmentName
              ),
            }),
          },
        })
        .asOptional()
        .readonlyWhen(() => context.inputContext.isEditing)
        .build(),
      this.formBuilder
        .optionalTextInput(
          'providerName',
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withDgatId('assessmentForm-e70')
        .asOptional()
        .readonlyWhen(() => context.inputContext.isEditing)
        .hiddenWhen(() => !vm.providerName)
        .build(),
      this.formBuilder
        .requiredTextInput(
          'url',
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .asOptional()
        .ofType('url')
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .withDgatId('assessmentForm-501')
        .validatedByIndexed({
          urlValidation: {
            expression: (url: AbstractControl) => {
              const isCredSpark = vm.getIsCredSpark({
                userProviderName: context.state().providerName,
              });
              return vm.validateAssessmentUrl(url.value, isCredSpark);
            },
            message: context.translationKeys.AssessmentFormCtrl_UrlRequired,
          },
        })
        .readonlyWhen(() => context.inputContext.isEditing)
        .build(),
      this.formBuilder
        .foreignField<MonthPickerFieldParams>(
          'dateCompleted',
          context.translationKeys.AssessmentFormCtrl_DateCompleted,
          MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
          {
            isMaxDateToday: true,
            disabled: true,
            ariaLabel: context.translationKeys.Core_SelectDate,
          }
        )
        .asOptional()
        .hiddenWhen(() => !vm.dateCompleted)
        .build(),
      this.formBuilder
        .foreignField(
          'tags',
          'Core_Skills',
          TagsFieldComponent.REGISTERED_FIELD_TYPE,
          { topTags: vm.authUser.viewerInterests }
        )
        .build(),
    ];
  }
}
