import { Inject, Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { WindowToken } from '@app/shared/window.token';

@Injectable({
  providedIn: 'root',
})
export class WindowLayoutService {
  /* eslint-disable @typescript-eslint/member-ordering */
  private learnerHubPhone = 640;
  private phoneBreakpoint = 480;
  private mobileBreakpoint = 768;
  private tabletBreakpoint = 1024;

  public windowResize$: Observable<Event>;

  /**
   * Breakpoints as values for code usages, same as above.
   * See {@link styles/scss/base/_mixins.scss}
   */
  public dgBreakpointSize = {
    MamaBear: 769,
    PapaBear: 1272,
    BrotherBear: this.tabletBreakpoint,
    BabyBear: this.mobileBreakpoint,
    TeenieBear: this.phoneBreakpoint,
  };

  /**
   * Suggested breakpoints definitions
   * See {@link styles/scss/base/_mixins.scss}
   */
  public dgBreakpoints = {
    MamaBear: `(min-width: ${this.dgBreakpointSize.MamaBear}px)`,
    PapaBear: `(max-width: ${this.dgBreakpointSize.PapaBear}px)`,
    BrotherBear: `(max-width: ${this.dgBreakpointSize.BrotherBear}px)`,
    BabyBear: `(max-width: ${this.dgBreakpointSize.BabyBear}px)`,
    TeenieBear: `(max-width: ${this.dgBreakpointSize.TeenieBear}px)`,
  };

  constructor(
    private mediaObserver: BreakpointObserver,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.windowResize$ = fromEvent(window, 'resize');
  }

  /**
   * Detect when the app is served from within an iframe
   */
  public get isIframe(): boolean {
    let isInIframe: boolean;
    try {
      isInIframe = this.windowRef.self !== this.windowRef.top;
    } catch (e) {
      // ignore/suppress same-origin policy errors
      // https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
    }

    // Support overriding the iframe detection
    const params = this.getLocationSearchParams();

    return isInIframe || this.isMsTeams || params.is_ext === 'true';
  }

  public get isMsTeams(): boolean {
    return (
      this.getLocationSearchParams().is_msteams === 'true' ||
      this.windowRef.location.href.indexOf('degreed-ms-teams/app') > -1
    );
  }

  public get isCypress(): boolean {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return this.windowRef.frames['Cypress'] !== undefined ? true : false;
  }

  public get isLearnerHubPhone(): boolean {
    return this.windowRef.innerWidth < this.learnerHubPhone;
  }

  public get isPhone(): boolean {
    return this.windowRef.innerWidth <= this.phoneBreakpoint;
  }

  public get isMobile(): boolean {
    return this.windowRef.innerWidth <= this.mobileBreakpoint;
  }

  public get isTablet(): boolean {
    return this.windowRef.innerWidth <= this.tabletBreakpoint;
  }

  public registerMediaListener(
    query: string | string[],
    notifyOnlyWhenTrue: boolean = true
  ): Observable<BreakpointState> {
    if (notifyOnlyWhenTrue == false) {
      return this.mediaObserver.observe(query);
    }
    return this.mediaObserver
      .observe(query)
      .pipe(filter((data) => data.matches));
  }

  /**
   * Get the parsed search params from the current url
   *
   * If a hash fragment is present, parses the fragment as a url and extends the params with the hash search params
   *
   * https://docs.angularjs.org/api/ng/service/$location#search
   */
  private getLocationSearchParams(): Record<string, string> {
    const params = {};
    const { hash, origin, search, pathname } = this.windowRef.location;

    if (search) {
      // parse the current search params from the query string
      const location = new URL(pathname + search, origin);
      location.searchParams.forEach((value, key) => {
        params[key] = value;
      });
    }

    if (hash) {
      // Remove the hash prefix `#`, and parse as a url
      const locationHash = new URL(hash.slice(1), origin);
      locationHash.searchParams.forEach((value, key) => {
        params[key] = value;
      });
    }

    return params;
  }
}
