import { inject, Injectable } from '@angular/core';
import { LocalizationService } from '@app/shared/localization.service';
import { EbbAuthService } from '@app/browser-extension/services/ebb-auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ExtensionLocalizationService extends LocalizationService {
  private ebbAuthService: EbbAuthService = inject(EbbAuthService);

  public get userExtensionLocale(): Observable<string> {
    const supportedIds = [
      ...this.localeIds.map((l) => l.toLowerCase()),
      'en-JM',
      'fr-CA',
    ];
    return this.ebbAuthService.authCheck()
      .pipe(
        map((authUser) => {
          if (!authUser) {
            return this.defaultLocale;
          }

          return (authUser as any)?.authedUser?.viewerProfile?.localeId;
        }),
        map((userLocale) => {
          const isSupportedLocale =
            supportedIds.includes(userLocale) ||
            supportedIds.includes(userLocale?.split('-')[0]);
          if (userLocale && !isSupportedLocale) {
            return this.defaultLocale;
          }
          return userLocale || this.defaultLocale;
        }),
        catchError(() => of(this.defaultLocale)),
      );
  }
}