import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ExternalProvidersAPI } from './externalProviders.model';
import { DgError } from '@app/shared/models/dg-error';
import { catchError } from 'rxjs/operators';

type ProviderImageList = Record<string, Record<string, string>>;

@Injectable({
  providedIn: 'root',
})
export class ExternalProvidersService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  // these 3 functions used for podcasts
  public findPodcasts(
    term: string
  ): Observable<ExternalProvidersAPI.FindExternalResponse[]> {
    return this.http
      .get<ExternalProvidersAPI.FindExternalResponse[]>(
        '/inputs/findexternal',
        {
          params: { type: 'episode', term: term },
        }
      )
      .pipe(
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant(
                'ExternalProvidersSvc_ErrorMatchingResults'
              ),
              error
            )
          );
        })
      );
  }

  public getEpisodesList(
    providerId: string
  ): Observable<ExternalProvidersAPI.GetEpisodesResponse[]> {
    return this.http
      .get<ExternalProvidersAPI.GetEpisodesResponse[]>('/inputs/listexternal', {
        params: { type: 'episode', key: providerId, count: 300 },
      })
      .pipe(
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant(
                'ExternalProvidersSvc_ErrorRetrivingListFormat',
                { type: 'episode' }
              ),
              error
            )
          );
        })
      );
  }

  public getEpisodeDetails(
    itemId: string
  ): Observable<ExternalProvidersAPI.GetEpisodeDetailsResponse> {
    return this.http
      .get<ExternalProvidersAPI.GetEpisodeDetailsResponse>(
        '/inputs/getexternal',
        {
          params: { type: 'episode', key: itemId },
        }
      )
      .pipe(
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant(
                'ExternalProvidersSvc_ErrorRetrievingDetailsFormat',
                { type: 'episode' }
              ),
              error
            )
          );
        })
      );
  }
}
