<section class="box-shadow rounded--large guts-p-full-2">
  <div class="data-table-container">
    <div class="guts-m-t-half m-guts-m-t-0">
      <h3 class="h3 table-title guts-p-b-half">
        {{ 'PathwayEnrollmentSource_Title' | translate }}
      </h3>
      <div>
        <dgx-skeleton
          *ngIf="isLoading"
          type="table"
          [rows]="7"
          [columns]="1"
        ></dgx-skeleton>
        <div class="guts-p-t-1" *ngIf="!isLoading">
          <dgx-data-table
            componentWrapperClass="data-table--medium grid__col-12 guts-p-full-0"
            tableWrapperClass="data-table"
            [columns]="columns"
            [items]="sources"
          ></dgx-data-table>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #label let-source>
  <span *ngIf="(!source.url && !source.routerLink) || !pathwayPrivacyLevel">{{
    source.label
  }}</span>
  <a
    *ngIf="source.url && pathwayPrivacyLevel"
    href="{{ source.url }}"
    data-dgat="pathwayEnrollmentSource-716"
    class="link color-ebony uhover"
    >{{ source.label }}</a
  >
  <a
    *ngIf="source.routerLink && pathwayPrivacyLevel"
    routerLink="{{ source.routerLink }}"
    data-dgat="pathwayEnrollmentSource-716"
    class="link color-ebony uhover"
    >{{ source.label }}</a
  >
  <df-icon
    *ngIf="source.tooltip"
    icon="question-mark-circle"
    class="guts-m-l-quart color-ebony-a45"
    [ngbTooltip]="source.tooltip"
    tabindex="0"
  ></df-icon>
</ng-template>

<ng-template #count let-count>
  {{ count === undefined ? '-' : count }}
</ng-template>
