import { Component } from '@angular/core';
import { PathwayAuthorBaseDirective } from '@app/pathways/directives/pathway-author-base.directive';
import { AutoSaveService } from '@app/shared/services/auto-save/auto-save.service';
import { DF_SLIDE_FADE_IN_LEFT } from '@lib/fresco';
import {
  PathwayAuthoring,
  PathwayFacade,
  getSectionsSafe,
  hasFauxOrMockSection,
  hasFauxOrMockSubsection,
} from '@dg/pathways-rsm';
import { EventBus } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-author-sections',
  templateUrl: './pathway-author-sections.component.html',
  styleUrls: ['../pathway-sections/pathway-sections.component.scss'],
  animations: [DF_SLIDE_FADE_IN_LEFT],
})
export class PathwayAuthorSectionsComponent extends PathwayAuthorBaseDirective {
  // Not actually used, but having this prop present is way simpler than re-adding everything
  // that we *do* need from the base directive (trust me, I tried!)
  public readonly i18n: Record<string, string>;

  constructor(
    autoSaveService: AutoSaveService,
    authoring: PathwayAuthoring,
    eventBus: EventBus,
    facade: PathwayFacade,
    translate: TranslateService
  ) {
    super(autoSaveService, authoring, eventBus, facade, translate);
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public getSections = getSectionsSafe;
  public hasFauxSection = hasFauxOrMockSection;
  public hasFauxSubsection = hasFauxOrMockSubsection;
}
