import { Injectable, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { VideoFormModel } from '../video-form.model.model';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { RenderMode } from '../../user-input.model';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

/** Creates an array of form field configurations for adding/editing Videos for pathways and user profile content */
@Injectable({ providedIn: 'root' })
export class DefaultFullVideoFormRenderer implements FormRenderer {
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('MediaFormCtrl_UrlRequired'),
    },
  };

  public expandAdvanced = new BehaviorSubject(false);
  constructor(
    private translate: TranslateService,
    private builder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext<VideoFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();

    if (
      !vm.isMediaParsed &&
      !context.inputContext.isEditing &&
      !vm.uploadedContentDetails
    ) {
      throw new Error('Wrong state for renderer');
    }

    return [
      // Media URL
      this.builder
        .requiredTextInput('mediaUrl', 'MediaFormCtrl_VideoUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidator)
        .withDgatId('videoForm-2b6')
        .autofocused()
        .withHelp(context.templates.urlCustomHelp)
        .readonlyWhen(() => true)
        .build(),

      // Title
      this.buildTitleField(vm.canEditTitle, context.templates.readonlyField),
      // Provider
      this.buildProviderField(
        vm.canEditProvider,
        context.templates.readonlyField
      ),
      // Media length
      this.buildLengthField(
        vm.canEditMediaLength,
        context.inputContext.renderMode === RenderMode.Pathways
      ),
      // Duration
      this.inputFieldBuilder.buildDurationFields(
        this.ldFlagsService.durationForCoursesEventsOther &&
          context.inputContext.renderMode === RenderMode.Pathways,
        vm.inputType
      ),
      // Description
      this.buildDescriptionField(),

      // Add to catalog checkbox
      this.inputFieldBuilder.buildPathwaysAddToCatalogField(
        context.inputContext.renderMode,
        vm.canManageContent,
        vm.organizationName,
        'MediaFormCtrl_AddVideoToCatalogFormat',
        context.templates.addToCatalogDupsHelp,
        vm.onAddToCatalogChange
      ),
      // User authored ("I made this video")
      this.buildUserAuthoredField(context.inputContext.isCompleting),
      // Comment
      this.buildCommentField(
        context.inputContext.isCompleting,
        context.inputContext.isEditing,
        vm.canComment
      ),
      // Skills
      this.inputFieldBuilder.buildSkillsField(vm.topTags, false), // we have a lot of fields to show in catalog mode so hide top tags for compactness
      // Consumer Groups
      this.inputFieldBuilder.buildMediaConsumerGroupsField(
        context.inputContext.isChannel,
        context.inputContext.isCompleting,
        vm.organizationId,
        context.templates.groups
      ),
    ];
  }

  // Field builder helpers _______________________________

  private buildTitleField(
    canEdit: boolean,
    readonlyTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    return (
      canEdit
        ? // editable
          this.builder
            .title() // defaults to required
            .withDgatId('videoForm-f56')
            .validatedBy(isEmptyValidator)
        : // readonly
          this.builder.customField('title', 'Core_Title', readonlyTemplate)
    ).build();
  }

  private buildLengthField(canEdit: boolean, isPathway: boolean) {
    return this.builder
      .optionalTextInput('mediaLength', 'MediaFormCtrl_VideoLength')
      .ofType('number')
      .withMin(0)
      .readonlyWhen(() => !canEdit)
      .withDgatId('videoForm-430')
      .hiddenWhen(
        () => this.ldFlagsService.durationForCoursesEventsOther && isPathway
      )
      .build();
  }

  private buildDescriptionField() {
    return this.builder.descriptionLong().withDgatId('articleForm-59d').build();
  }

  private buildUserAuthoredField(isCompleting: boolean) {
    return this.builder
      .checkbox('isUserAuthored', 'MediaFormCtrl_ICreatedVideo')
      .withDgatId('articleForm-951')
      .hiddenWhen(() => !isCompleting)
      .build();
  }

  private buildCommentField(
    isCompleting: boolean,
    isEditing: boolean,
    userCanComment: boolean
  ) {
    return this.builder
      .optionalTextarea('comment', 'MediaFormCtrl_WhatDidYouLearnVideoLong')
      .withRows(1) // TODO: Fix in fresco. Shouldn't need to explicitly set this to one but default is zero which throws during testing
      .withPlaceholder('MediaFormCtrl_WhatDidYouLearnShort')
      .withDgatId('articleForm-aaf')
      .hiddenWhen(
        () =>
          // comment is independently associated with the user completion, and not available when editing
          !(isCompleting && userCanComment) || isEditing
      )
      .build();
  }

  private buildProviderField(
    canEdit: boolean,
    readonlyTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    return (
      canEdit
        ? this.builder
            .requiredTextInput('providerName', 'Core_Provider')
            .withDgatId('videoForm-8e2')
        : this.builder.customField(
            'providerName',
            'Core_Provider',
            readonlyTemplate
          )
    ).build();
  }
}
