import { Injectable } from '@angular/core';
import { ReorderItem } from '@app/reorder-modal/reorder-modal.model';
import { ModalOptions, ModalService } from '@app/shared/services/modal.service';
import { Observable } from 'rxjs';
import { ReorderModalComponent } from './reorder-modal.component';
import { ReorderType } from './reorder-modal.constants';

@Injectable({
  providedIn: 'root',
})
export class ReorderModalService {
  constructor(private modalService: ModalService) {}

  public openModal(
    headerText: string,
    reorderData: ReorderItem[],
    reorderType: ReorderType,
    // TODO: this callback is definitely unnecessary and should be removed when
    // we do the reactive stores work on plans and pathways.
    onSave?: (reorderedItems: ReorderItem[], closeModalFn?) => void
  ): Observable<ReorderItem[]> {
    const isModalForAdminSettings =
      reorderType === ReorderType.OrgInsightCards ||
      reorderType === ReorderType.OrgHomeSections;
    const modalOptions: ModalOptions = {
      windowClass: isModalForAdminSettings ? 'modal-medium' : 'xlg-modal',
      inputs: {
        headerText,
        reorderData,
        reorderType,
        onSave,
      },
    };
    return this.modalService.show(ReorderModalComponent, modalOptions);
  }
}
