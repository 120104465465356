import { buildAcademyUrl } from '@app/academies/utils/academy.utils';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionButtonSize } from '../action-button/action-button.component';

/**
 * View Academy Component
 * This component is used to display view academy button
 */
@Component({
  selector: 'dgx-view-academy-button',
  templateUrl: './view-academy.component.html',
  styleUrls: ['./view-academy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAcademyComponent {
  @Input() public url: string;
  @Input() public size: ActionButtonSize = 'xs';
  @Input() public isSearchResultCard: boolean = false;

  public buildAcademyUrl = buildAcademyUrl;
  public openAcademy() {
    window.open(buildAcademyUrl(this.url), '_self');
  }
}
