import { Component, Input } from '@angular/core';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { UserSearchItem } from '@app/user/user-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-add-org-collaborators-modal',
  templateUrl: './add-collaborators-modal.component.html',
  styleUrls: ['./add-collaborators-modal.component.scss'],
})
export class AddOrgCollaboratorsModalComponent {
  @Input() public headerText? = '';
  @Input() public submitButtonText? = this.translate.instant('Core_Add');

  public collaborators: UserSearchItem[] = [];
  public isSubmitPending = false;

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  public onSubmit(): void {
    this.activeModal.close({
      collaborators: this.collaborators,
      userKeys: this.collaborators.map((c) => c.userProfileKey),
    });
  }

  public onCollaboratorSelect($event: AnyRecommendee): void {
    const collaborator = $event as UserSearchItem;
    const exists = this.collaborators.some(
      (c) => c.userProfileKey === collaborator.userProfileKey
    );

    if (!exists) {
      this.collaborators.push(collaborator);
    }
  }

  public onCollaboratorRemove($event: AnyRecommendee): void {
    this.collaborators = this.collaborators.filter(
      ({ userProfileKey }) =>
        ($event as UserSearchItem).userProfileKey !== userProfileKey
    );
  }
}
