import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Book } from '@app/inputs/inputs-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { DecodeHtmlPipe } from '@app/shared/pipes/decode-html.pipe';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

/**
 * Google API Response
 * https://developers.google.com/books/docs/v1/using
 */
export interface GoogleAPIs_Books_VolumeResponse {
  items?: GoogleAPIs_Books_Volume[];
  error?: GoogleAPIs_Books_Error;
}

/**
 * Error response from the Volume endpoint
 * Example: make call without required `q` param (https://www.googleapis.com/books/v1/volumes?callback=cb)
 */
export interface GoogleAPIs_Books_Error {
  code: number;
  message: string;
  errors: { message: string; domain: string; reason: string }[];
  status?: string;
}
export interface GoogleAPIs_Books_Volume {
  id: string;
  volumeInfo: GoogleAPIs_Books_VolumeInfo;
}
export interface GoogleAPIs_Books_VolumeInfo {
  title: string;
  subtitle: string;
  authors: string[];
  publisher: string;
  publishedDate: string;
  description: string;
  industryIdentifiers?: GoogleAPIs_Identifier[];
  readingModes: {
    text: boolean;
    image: boolean;
  };
  pageCount: number;
  printType: string;
  categories: string[];
  averageRating: number;
  ratingsCount: number;
  maturityRating: string;
  allowAnonLogging: boolean;
  contentVersion: string;
  panelizationSummary: {
    containsEpubBubbles: boolean;
    containsImageBubbles: boolean;
  };
  imageLinks: {
    thumbnail: string;
    smallThumbnail?: string;
  };
  language: string;
  previewLink: string;
  infoLink: string;
  canonicalVolumeLink: string;
}
export interface GoogleAPIs_Identifier {
  type: string;
  identifier: string;
}

/**
 * Service wrapping the Google Books API
 *
 * https://developers.google.com/books/docs/overview
 */
@Injectable({ providedIn: 'root' })
export class GoogleBooksService {
  public static readonly GoogleBooksEndpoint =
    'https://www.googleapis.com/books/v1/volumes';
  public static readonly GoogleBooksDefaultThumbnail =
    'https://books.google.com/googlebooks/images/no_cover_thumb.gif';
  public static readonly GoogleBooksIsbn13 = 'ISBN_13';
  public static readonly GoogleBooksIsbn10 = 'ISBN_10';
  public static readonly GoogleBooksProvider = 'GoogleBooks';
  public static readonly GoogleBooksDurationUnitType = 'Pages';

  public readonly i18n = this.translate.instant([
    'BooksSvc_ErrorMatchingBooks',
  ]);

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private decodeHtmlPipe: DecodeHtmlPipe
  ) {}

  /**
   * Given a search term, find matching books in the Google Books dataset
   */
  public find(term: string): Observable<Partial<Book>[]> {
    const params: HttpParams = new HttpParams()
      .append('q', term)
      .append('fields', 'items(id,volumeInfo)')
      .append('maxResults', '20')
      .append('startIndex', '0');

    const url = `${
      GoogleBooksService.GoogleBooksEndpoint
    }?${params.toString()}`;

    return this.http
      .jsonp<GoogleAPIs_Books_VolumeResponse>(url, 'callback')
      .pipe(
        switchMap((response) => {
          const { items, error } = response;

          // Throw an error from the response (if present)
          if (error?.errors.length) {
            return throwError(error.errors[0]);
          }

          return of(items.map((v) => this.mapVolumeToBook(v)));
        }),
        catchError((error) =>
          throwError(new DgError(this.i18n.BooksSvc_ErrorMatchingBooks, error))
        )
      );
  }

  /**
   * Given a Volume result from the Google API response, map to the Degreed `Book` type
   */
  private mapVolumeToBook(volume: GoogleAPIs_Books_Volume): Partial<Book> {
    const { id, volumeInfo } = volume;
    const book = {
      title: this.transformText(volumeInfo.title),
      subtitle: this.transformText(volumeInfo.subtitle),
      author: volumeInfo.authors ? volumeInfo.authors.join(', ') : '',
      description: this.transformText(volumeInfo.description),
      durationUnits: volumeInfo.pageCount,
      durationUnitType: GoogleBooksService.GoogleBooksDurationUnitType,
      externalId: id,
      externalData: JSON.stringify(volume),
      imageUrl: this.getThumbnailLink(volumeInfo),
      isbn13: this.getBestIsbn(volumeInfo),
      tags: [],
      providerCode: GoogleBooksService.GoogleBooksProvider,
    } as Partial<Book>;

    return book;
  }

  private transformText(raw: string): string {
    const decoded = this.decodeHtmlPipe.transform(raw);
    const plainText = this.htmlToPlaintextPipe.transform(decoded, false, true);
    return plainText;
  }

  private getBestIsbn(volumeInfo: GoogleAPIs_Books_VolumeInfo): string {
    if (!volumeInfo?.industryIdentifiers) {
      return '';
    }

    let bestIsbn = '';
    for (const id of volumeInfo?.industryIdentifiers) {
      if (id.type === GoogleBooksService.GoogleBooksIsbn13) {
        bestIsbn = id.identifier;
        break;
      } else if (id.type === GoogleBooksService.GoogleBooksIsbn10) {
        bestIsbn = id.identifier;
        // continue in case ISBN_13 exists
      }
    }
    return bestIsbn;
  }

  private getThumbnailLink(volumeInfo: GoogleAPIs_Books_VolumeInfo) {
    let link = GoogleBooksService.GoogleBooksDefaultThumbnail;

    // Google APIs is served over `https`, but results are returned with `http` protocol
    if (volumeInfo.imageLinks?.thumbnail) {
      link = volumeInfo.imageLinks.thumbnail.replace('http:', 'https:');
    }

    return link;
  }
}
