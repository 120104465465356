import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputPermissionsService } from '@app/inputs/services/input-permissions.service';
import { UserCardSkillsModalComponent } from '@app/mentoring/components/modals/user-card-skills-modal/user-card-skills-modal.component';
import { OpportunityPermissionsService } from '@app/opportunities/services/opportunity-permissions.service';
import { SignalCount } from '@app/profile/profile-api.model';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { FollowingService } from '@app/shared/services/following.service';
import { ModalService } from '@app/shared/services/modal.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { isFunction } from '@app/shared/utils/type-guard-helpers';
import { TargetService } from '@app/target/services/target.service';
import {
  ProximitySearchResults,
  UserProfileSummary,
} from '@app/user/user-api.model';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dgx-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardComponent implements OnDestroy, OnInit {
  @Input()
  public modifyOptionsFn: ModifyOptionsFnType<LearningResourceViewModel>;
  @Input() public user: UserProfileSummary & ProximitySearchResults;

  public i18n = this.translateService.instant([
    'Core_Follow',
    'Core_UnFollow',
    'Core_Following',
    'Core_AddToJobRole',
    'Core_SkillCount',
    'Core_SkillsCount',
    'Core_ActiveLearner',
    'Core_Mentor',
  ]);
  public authUser: AuthUser;
  public userIsLoggedIn: boolean;
  public viewerFolloweeIds: string[] = [];
  public isMyTile: boolean;
  public showMentorOptions: boolean;
  public menuConfig: MenuViewModel[];
  public mentor: Partial<UserProfileSummary>;
  public tagsCount: string;
  public showCardFooter: boolean;

  @ViewChild('link') public link: ElementRef<HTMLElement>;

  private events: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private targetService: TargetService,
    private translateService: TranslateService,
    private followingService: FollowingService,
    private opportunityPermissionsService: OpportunityPermissionsService,
    private modalService: ModalService,
    private inputPermissionsService: InputPermissionsService,
    private ldFlagsService: LDFlagsService
  ) {}

  public get isViewerFollowing() {
    if (this.user.isFollowing != undefined) {
      return this.user.isFollowing;
    }
    return this.followingService.isViewersFollowee(this.user.userProfileId);
  }

  public get showFollowButton() {
    return !this.isViewerFollowing && !this.isMyTile;
  }

  public get showUnfollowButton() {
    return this.isViewerFollowing && !this.isMyTile;
  }

  public get userAndLocation() {
    let arr: Array<string> = [];
    if (this.user.jobRole) {
      arr = [this.user.jobRole];
    }
    if (this.user.location) {
      arr = [...arr, this.user.location];
    }
    return arr.join(' · ');
  }

  public get signalCount() {
    return (
      this.user.tagSignals?.reduce((acc, curr) => acc + curr.count, 0) || 0
    );
  }

  public ngOnInit() {
    // TODO: Remove this once we are no longer receiving pascal-cased users from
    // CarouselSection, GridSection, RecommendUsers.
    // A sincere effort was made here to do camel-casing in the above components
    // instead, but it's really hairy with the loading conditions, and was causing
    // infinite digests if done in the template at the point of entry (| camelCase)
    // so for the moment, this is necessary.
    this.user = camelCaseKeys(this.user);

    this.authUser = this.authService.authUser;
    this.userIsLoggedIn = !!this.authUser;
    this.isMyTile =
      this.authUser?.viewerProfile.userProfileId === this.user.userProfileId;
    this.showCardFooter = this.userIsLoggedIn;

    this.tagsCount = this.tagCountTranslation(this.user.tags);

    this.followingService
      .getViewersFollowees(true)
      .subscribe((ids: string[]) => {
        this.viewerFolloweeIds = ids;
      });

    this.events.push(
      this.followingService.followingChange.subscribe(() => {
        // this is required to ensure that the following button displays the correct state on the home page
        // when mentorships aren't enabled and the user cards are shown for the first time via the infinite scroll
        this.viewerFolloweeIds = this.followingService.viewersFolloweeIds;
        this.cdr.detectChanges();
      })
    );

    this.menuConfig = this.configUserCardMenu();
  }

  public ngOnDestroy() {
    this.events.forEach((e) => e.unsubscribe());
  }

  public imageClick(e: MouseEvent) {
    e.stopPropagation();
    this.link.nativeElement.click();
  }

  public tagCountTranslation(tags: string): string {
    const hasMultipleTags = tags?.includes(',');
    // 0 is also plural, so we also handle no tags at all
    // with the standard plural split. (This is a sanity-check.)
    if (hasMultipleTags || !tags) {
      return this.translateService.instant('Core_SkillsCount', {
        count: hasMultipleTags ? tags.split(',').length : 0,
      });
    }
    return this.i18n.Core_SkillCount;
  }

  public getUserLabel(userName: string) {
    return this.translateService.instant('Core_Follow_UserName', {
      userName,
    });
  }

  public configUserCardMenu(): MenuViewModel[] {
    const userAsResource = {
      ...this.user,
      resourceType: 'User',
      resourceId: this.user.userProfileKey,
    } as unknown as LearningResourceViewModel;
    // both the follow AND unfollow options should be hidden if:
    // - the user is not logged in
    // - the user cannot view mentorship options
    // - this is the current logged-in user's tile
    const hideFollowOptions =
      !this.userIsLoggedIn || this.isMyTile || !this.canViewMentorshipOptions();

    const menu: MenuViewModel[] = [
      {
        title: this.i18n.Core_Follow,
        defaultAction: () => this.followUser(this.user),
        isHidden: () => hideFollowOptions || this.isViewerFollowing,
      },
      {
        title: this.i18n.Core_UnFollow,
        defaultAction: () => this.unfollowUser(this.user),
        isHidden: () => hideFollowOptions || !this.isViewerFollowing,
      },
      {
        title: this.i18n.Core_AddToJobRole,
        defaultAction: (_, trigger: ElementRef<HTMLElement>) =>
          this.targetService
            .showAddToTargetModal(
              'JobRole',
              userAsResource,
              trigger.nativeElement
            )
            .subscribe(),
        isHidden: () => !this.inputPermissionsService.canAddToTarget('User'),
      },
    ];

    return isFunction(this.modifyOptionsFn)
      ? this.modifyOptionsFn(userAsResource, menu)
      : menu;
  }

  public followUser(user: UserProfileSummary & ProximitySearchResults) {
    this.followingService
      .followUser(user.userProfileId, user.name, user.isEngaged)
      .subscribe(() => {
        user.isFollowing = true;
        this.cdr.markForCheck();
      });
  }

  public unfollowUser(user: UserProfileSummary & ProximitySearchResults) {
    this.followingService
      .unfollowUser(user.userProfileId, user.name, user.isEngaged)
      .subscribe(() => {
        user.isFollowing = false;
        this.cdr.markForCheck();
      });
  }

  public showUserCardSkillsModal() {
    return this.modalService.show(UserCardSkillsModalComponent, {
      inputs: {
        peer: this.user,
      },
      centered: true,
    });
  }

  public showMentorIcon(): boolean {
    return (
      !!this.user?.isMentoring && !!this.authUser?.enableMentorshipOpportunities
    );
  }

  private canViewMentorshipOptions = () => {
    return (
      this.opportunityPermissionsService.canViewMentorshipOptions() &&
      !!this.user?.isMentoring
    );
  };
}
