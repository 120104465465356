import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { RenderMode } from '../../user-input.model';
import { RendererContext, FormRenderer } from '../../form-renderer.model';
import { AbstractControl } from '@angular/forms';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

@Injectable({ providedIn: 'root' })
export class FormalCourseRenderer implements FormRenderer {
  private i18n = this.translate.instant([
    'CourseFormCtrl_SelectProvider',
    'CourseFormCtrl_FirstYearLevel',
    'CourseFormCtrl_SecondYearLevel',
    'CourseFormCtrl_ThirdYearLevel',
    'CourseFormCtrl_FourthYearLevel',
    'CourseFormCtrl_GraduateLevel',
    'Core_SelectDate',
  ]);
  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return [
      this.builder
        .customField(
          'country',
          'CourseFormCtrl_Country',
          context.templates.countrySearch
        )
        .autofocused()
        .hiddenWhen(() => context.inputContext.isEditing)
        .build(),
      this.getInstitutionNameField(context),
      this.getCourseNameField(context),
      this.builder
        .fieldGroup(
          'input',
          [
            this.builder
              .optionalTextInput('courseNumber', 'CourseFormCtrl_CourseNumber')
              .styledBy('df-form__col-half')
              .build(),
            this.builder
              .optionalTextInput('creditHours', 'CourseFormCtrl_CreditHours')
              .styledBy('df-form__col-half')
              .readonlyWhen(() => {
                return (
                  context.state().institution &&
                  context.state().courseName?.length > 0 &&
                  context.state().creditHours
                );
              })
              .build(),
          ],
          'df-form__row'
        )
        .build(),
      this.builder
        .checkbox('authored', 'CourseFormCtrl_ITaughtCourse')
        .hiddenWhen(
          () => context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .customField(
                'courseLevel',
                'CourseFormCtrl_CourseLevel',
                context.templates.courseLevel,
                {
                  allCourseLevels: [
                    { id: 1, level: this.i18n.CourseFormCtrl_FirstYearLevel },
                    {
                      id: 2,
                      level: this.i18n.CourseFormCtrl_SecondYearLevel,
                    },
                    { id: 3, level: this.i18n.CourseFormCtrl_ThirdYearLevel },
                    {
                      id: 4,
                      level: this.i18n.CourseFormCtrl_FourthYearLevel,
                    },
                    { id: 5, level: this.i18n.CourseFormCtrl_GraduateLevel },
                  ],
                }
              )
              .styledBy('df-form__col-half')
              .build(),
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                'CourseFormCtrl_DateCompleted',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectDate,
                }
              )
              .styledBy('df-form__col-half')
              .hiddenWhen(() => context.state().authored)
              .build(),
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                'CourseFormCtrl_DateTaught',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectDate,
                }
              )
              .styledBy('df-form__col-half')
              .hiddenWhen(() => !context.state().authored)
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(
          () => context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .customField(
                'courseGrade',
                'CourseFormCtrl_CourseGrade',
                context.templates.courseGrade,
                {
                  allGrades: [
                    { id: 11, Name: 'A' },
                    { id: 10, Name: 'A-' },
                    { id: 9, Name: 'B+' },
                    { id: 8, Name: 'B' },
                    { id: 7, Name: 'B-' },
                    { id: 6, Name: 'C+' },
                    { id: 5, Name: 'C' },
                    { id: 4, Name: 'C-' },
                    { id: 3, Name: 'D+' },
                    { id: 2, Name: 'D' },
                    { id: 1, Name: 'D-' },
                  ],
                }
              )
              .styledBy('df-form__col-half')
              .build(),
            this.builder
              .optionalTextInput(
                'verificationUrl',
                'CourseFormCtrl_VerificationUrl'
              )
              .ofType('url')
              // .validatedByIndexed(this.domainService.urlValidation)
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(
          () =>
            context.state().authored === true ||
            context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),
      this.builder
        .optionalTextarea('comment', 'CourseFormCtrl_WhatDidYouLearnLong', 100)
        .withPlaceholder('CourseFormCtrl_WhatDidYouLearnShort')
        .hiddenWhen(
          () =>
            context.inputContext.renderMode === RenderMode.Pathways ||
            (!context.state().canComment &&
              !context.state().completing &&
              context.state().formUIState.isEditing)
        )
        .hiddenWhen(() => context.inputContext.isEditing)
        .build(),
      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
    ];
  }

  private getInstitutionNameField(context: RendererContext): DfFormFieldConfig {
    if (context.inputContext.isEditing) {
      return this.builder
        .requiredTextInput('institutionName', 'CourseFormCtrl_SchoolName')
        .readonlyWhen(() => true)
        .build();
    } else {
      return this.builder
        .customField(
          'institutionName',
          'CourseFormCtrl_SchoolName',
          context.templates.institutionSearch
        )
        .validatedByIndexed({
          institutionValidation: {
            expression: (control: AbstractControl) => {
              return context.validators.get('INSTITUTION_VALIDATION')(
                context.state()
              );
            },
            message: this.i18n.CourseFormCtrl_SelectProvider,
          },
        })
        .hiddenWhen(() => context.inputContext.isEditing)
        .onBlur((event, model, formControl) => {
          formControl.updateValueAndValidity();
        })
        .build();
    }
  }

  private getCourseNameField(context: RendererContext): DfFormFieldConfig {
    if (context.inputContext.isEditing) {
      return this.builder
        .requiredTextInput('courseName', 'CourseFormCtrl_CourseTitle')
        .readonlyWhen(() => true)
        .build();
    } else {
      return this.builder
        .customField(
          'courseName',
          'CourseFormCtrl_CourseTitle',
          context.templates.courseTitleSearch
        )
        .asRequired()
        .validatedBy(isEmptyValidator)
        .build();
    }
  }
}
