<ul class="ib" *ngIf="!hideValue">
  <!-- case where we don't want pop over and only show the count of items -->
  <ng-container
    *ngIf="!usePopover && numberOnly && list.length > 0; else showContent"
  >
    <span>{{ list.length }}</span>
  </ng-container>

  <ng-template #showContent>
    <!-- show items in the column (The last one not included), if usePopover, we regard all the items in the column as the last item  -->
    <ng-container *ngIf="(usePopover && list.length <= limit) || !usePopover">
      <ng-template *ngTemplateOutlet="liItem"></ng-template>
    </ng-container>

    <!-- last item in the column - either popoverFinalItem or finalItem; -->
    <li *ngIf="list.length > limit" [ngClass]="listItemClasses">
      <ng-container
        *ngTemplateOutlet="
          usePopover ? popoverFinalItem : finalItem;
          context: {
            remainder: list.length - limit
          }
        "
      >
      </ng-container>
    </li>
  </ng-template>
</ul>

<ng-template #liItem>
  <li
    *ngFor="let item of list | slice: 0:limit; last as isLast"
    [ngClass]="listItemClasses"
    data-dgat="data-column-list-5dd"
  >
    <ng-container
      *ngTemplateOutlet="
        propHref ? linkedContent : itemContent;
        context: {
          $implicit: item,
          href: propHref ? item[propHref] : '',
          prop: prop,
          linkClasses: listItemLinkClasses
        }
      "
    >
    </ng-container>
    <span *ngIf="(list.length > limit || (list.length && !isLast)) && !isLast"
      >,&#32;</span
    >
  </li>
</ng-template>

<!-- linked item -->
<ng-template
  #linkedContent
  let-item
  let-href="href"
  let-linkClasses="linkClasses"
  let-prop="prop"
>
  <a
    class="uhover color-ebony"
    [href]="href"
    [ngClass]="linkClasses"
    target="_blank"
    data-dgat="data-column-list-c4e"
  >
    <span>{{ prop ? item[prop] : item }}</span>
  </a>
</ng-template>

<!-- plain text item -->
<ng-template #itemContent let-item let-prop="prop">
  <span [ngClass]="popoverListItemTextClasses">{{
    prop ? item[prop] : item
  }}</span>
</ng-template>

<!-- final item (with popover) -->
<ng-template #popoverFinalItem let-remainder="remainder">
  <button
    [dgxPopoverTrigger]="menuPopover"
    [class]="popoverButtonClasses"
    data-dgat="data-column-list-471"
  >
    <span *ngIf="numberOnly; else showContent">{{ list.length }}</span>
    <ng-template #showContent>
      <ul class="left-text">
        <ng-content
          *ngTemplateOutlet="
            finalItem;
            context: {
              remainder: remainder
            }
          "
        ></ng-content>
      </ul>
    </ng-template>
  </button>
  <dgx-popover #menuPopover placement="bottom">
    <ul [class]="popoverListClasses" [ngStyle]="popoverListStyles">
      <li
        *ngFor="let item of list"
        [ngClass]="popoverListItemClasses"
        data-dgat="data-column-list-346"
      >
        <ng-container
          *ngTemplateOutlet="
            propHref ? linkedContent : itemContent;
            context: {
              $implicit: item,
              href: propHref ? item[propHref] : '',
              linkClasses: popoverListItemLinkClasses,
              prop: prop
            }
          "
        >
        </ng-container>
      </li>
    </ul>
  </dgx-popover>
</ng-template>

<!-- final item -->
<ng-template #finalItem let-remainder="remainder">
  <ng-container *ngIf="usePopover">
    <li
      *ngFor="let item of list | slice: 0:limit; last as isLast"
      [ngClass]="listItemClasses"
      data-dgat="data-column-list-b13"
    >
      <span>{{ prop ? item[prop] : item }}</span>
      <span *ngIf="!isLast">,&#32;</span>
    </li>
  </ng-container>
  <span>,&#32;+{{ remainder }}</span>
</ng-template>
