import { Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
// services
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError, zip } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { ModalService } from '@app/shared/services/modal.service';
import { FirstPathwayComponent } from '../components/first-pathway/first-pathway.component';

@Injectable({
  providedIn: 'root',
})
export class TipService {
  // this BehaviorSubject doesn't actually have an initial value?
  private onboardHistorySubject = new BehaviorSubject<string[]>(['loading']);

  constructor(
    private modalService: ModalService,
    private http: NgxHttpClient,
    private translateService: TranslateService,
    private authService: AuthService,
    private notifierService: NotifierService,
    private trackerService: TrackerService
  ) {
    // initialize to the tips specific to the user
    this.updateOnboardHistoryFromServer(this.authService.authUser);
  }

  public set onboardHistory(value) {
    this.onboardHistorySubject.next(value);
  }

  public get onboardHistory(): string[] {
    return this.onboardHistorySubject.value;
  }

  public get onboardHistory$(): Observable<string[]> {
    return this.onboardHistorySubject.asObservable();
  }

  get pathwaysUrl(): string {
    const vanityUrl = this.authService.authUser.viewerProfile.vanityUrl;
    return `/${vanityUrl}/dashboard/pathways`;
  }

  /**
   * Gets all flags for tips completed by the user account logged in.
   *
   */
  public updateOnboardHistoryFromServer(authUser): void {
    if (authUser) {
      this.http
        .get<string[]>('/user/GetUserTips')
        .pipe(first())
        .subscribe((data) => {
          this.onboardHistory = data;
        });
    }
  }

  /**
   * Sets a new record of completion of a tip type to be associated with the user account.
   *
   * @param {string} tip The tip type that was completed and should be added to the user's history.
   */
  public setOnboardHistory(tip: string): void {
    this.http
      .post<string[]>('/user/AddUserTips', [tip])
      .pipe(
        first(),
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant('TipSvc_OnboardError', error)
            )
          )
        )
      )
      .subscribe(() => {
        this.onboardHistory = [...this.onboardHistory, ...[tip]];
      });
  }

  /**
   * Checks if this is the first time a pathway has been created and opens the tip modal, if so.
   * TODO: update to ModalService when the 'first pathway' modal is updated to a component
   *
   * @param target Trigger of the modal to return focus.
   */
  public checkFirstEnrollment(target?): void {
    if (this.onboardHistory.indexOf('firstpathway') === -1) {
      const inputs = {
        pathwaysUrl: this.pathwaysUrl,
      };
      this.setOnboardHistory('firstpathway');
      this.modalService
        .show<any>(FirstPathwayComponent, {
          inputs,
        })
        .subscribe();
    }
  }

  /**
   * Resets the user's status of onboarding progress back to that of a new user.
   * Used in account settings.
   *
   */
  public resetOnboardHistory(): Observable<[void, void]> {
    this.onboardHistory = [];
    const updateIntroStatus$ = this.updateIntroStatus(false);
    const deleteUserTips$ = this.deleteUserTips();
    return zip(updateIntroStatus$, deleteUserTips$).pipe(
      tap(() => {
        // show notification for success if this was a reset
        this.notifierService.showSuccess(
          this.translateService.instant('TipSvc_OnboardResetSuccess')
        );
      })
    );
  }

  /**
   * Deletes the recorded user tips from the server
   *
   */
  public deleteUserTips(): Observable<void> {
    return this.http.post<void>('/user/DeleteUserTips', {}).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Onboarding Tips Reset',
          category: 'Onboarding',
        });
      }),
      catchError((error) =>
        throwError(
          new DgError(
            this.translateService.instant('TipSvc_OnboardError', error)
          )
        )
      )
    );
  }

  /**
   * Sets the user value of introCompleted; used to progress or to reset the user state.
   *
   * @param introCompleted
   */
  public updateIntroStatus(introCompleted: boolean): Observable<void> {
    return this.http
      .get<void>('/user/updateuserintrostatus', {
        params: { introCompleted, introNumber: 0 },
      })
      .pipe(
        tap(() => {
          // need to make sure when we get the user object next it'll have the
          // correctly updated introCompleted status.
          this.authService.refreshCacheVersionToken();
        }),
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant('TipSvc_OnboardError', error)
            )
          )
        )
      );
  }

  /**
   *
   * @param jobRole
   */
  public updateJobRole(jobRole: string): Observable<void> {
    return this.http
      .put<void>('/user/UpdateJobRole', { JobRoleName: jobRole })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant(
                'UserSettingsSvc_JobRoleSaveFailed',
                error
              )
            )
          )
        )
      );
  }
}
