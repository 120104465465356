import { Injectable } from '@angular/core';
import { FormRenderManager } from '@app/shared/patterns/render-manager/form-render-manager';
import { DfFormFieldConfig, DfTemplateOptions } from '@lib/fresco';
import { RenderMode } from '../../user-input.model';

import { RendererContext } from '../../form-renderer.model';
import { ContentCatalogEventRenderer } from '../renderers/content-catalog-event.renderer';
import { UserProfileEventRenderer } from '../renderers/user-profile-event.renderer';
import { PathwayEventRenderer } from '../renderers/pathway-event-renderer';

@Injectable({ providedIn: 'root' })
export class EventFormRenderManager implements FormRenderManager {
  constructor(
    private globalAddRenderer: UserProfileEventRenderer,
    private pathwaysRenderer: PathwayEventRenderer,
    private contentCatalogRenderer: ContentCatalogEventRenderer
  ) {}

  /**
   * This method is responsible for running the correct
   * strategy method to render the UI.
   * implementing the mediator pattern
   */
  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let specificFormFields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    switch (context.inputContext.renderMode) {
      case RenderMode.UserProfile: {
        specificFormFields = this.globalAddRenderer.render(context);
        break;
      }
      case RenderMode.ContentCatalog: {
        specificFormFields = this.contentCatalogRenderer.render(context);
        break;
      }
      case RenderMode.Pathways: {
        specificFormFields = this.pathwaysRenderer.render(context);

        break;
      }
    }
    return specificFormFields;
  }
}
