import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a New Pathway Author notification */
@Component({
  selector: 'dgx-new-pathway-author',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPathwayAuthorComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('newPathwayAuthor_DescriptionFormat', {
      pathwayAuthor: this.userFullNameTemplate,
      pathwayName: this.params.pathwayName,
    });
  }
}
