import { Injectable } from '@angular/core';
import { TrackerService } from '@app/shared/services/tracker.service';

@Injectable({ providedIn: 'root' })
export class LearnerHomeTrackerService {
  constructor(private trackerService: TrackerService) {}

  public setTrackerLocationLearnerHome(): void {
    this.trackerService.setLocation('Learner Home');
  }

  public leanerHomeViewed(element: HTMLElement): void {
    this.trackerService.trackEventData({
      action: 'Learner Home Viewed',
      element,
    });
  }

  public itemDismissed(element: HTMLElement, p: ItemDismissed): void {
    this.trackerService.trackEventData({
      action: 'Learner Home Item Dismissed',
      element,
      properties: {
        itemType: p.itemType,
        sectionIndex: p.sectionIndex,
        sectionItemCount: p.sectionItemCount,
        sectionType: p.sectionType,
        userSuggestionId: p.userSuggestionId,
        isEndorsed: p.isEndorsed,
      },
    });
  }
  public viewMoreClicked(element: HTMLElement): void {
    this.trackerService.trackEventData({
      action: 'Learner Home Section View More Clicked',
      element,
    });
  }

  public sectionImpressed(element: HTMLElement): void {
    this.trackerService.trackEventData({
      action: 'Learner Home Section Impressed',
      element,
    });
  }

  public trackContentImpressed(properties: any): void {
    this.trackerService.trackEventData({
      action: 'Content Impressed',
      properties,
    });
  }

  public externalOrgLinkClicked(itemClicked: string, context: string): void {
    this.trackerService.trackEventData({
      action: 'External Org Link Clicked',
      properties: { itemClicked, context },
    });
  }

  public navigationClicked(p: NavigationClicked): void {
    this.trackerService.trackEventData({
      action: 'Learner Home Navigation Clicked',
      properties: {
        itemClicked: p.itemClicked,
        context: p.context,
        isFocusSkill: p.isFocusSkill,
        skillId: p.skillId,
        skillName: p.skillName,
        totalAssignments: p.totalAssignments,
        overdueAssignments: p.overdueAssignments,
      },
    });
  }

  public skillPopoverViewed(skillId, skillName): void {
    this.trackerService.trackEventData({
      action: 'Skill Other Signals Previewed',
      properties: {
        skillId,
        skillName,
      },
    });
  }

  public sectionExpandedCollapsed(
    element: HTMLElement,
    isExpanded: boolean
  ): void {
    this.trackerService.trackEventData({
      action: `Learner Home Section ${isExpanded ? 'Expanded' : 'Collapsed'}`,
      element,
    });
  }
}

export interface OpportunityDismissed {
  opportunityId: number;
  title: string;
  isInterested: boolean;
  opportunityType: string;
  opportunityLocation: string;
}

export interface NavigationClicked {
  itemClicked: string;
  context: string;
  isFocusSkill?: boolean;
  skillId?: number;
  skillName?: string;
  totalAssignments?: number;
  overdueAssignments?: number;
}

interface ItemDismissed {
  itemType: string;
  userSuggestionId: number;
  isEndorsed: boolean;
  sectionIndex: number;
  sectionItemCount: number;
  sectionType: string;
}

export type SectionTrackingProperties = Pick<
  ItemDismissed,
  'sectionIndex' | 'sectionItemCount' | 'sectionType'
>;
