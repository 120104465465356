import { CommonModule } from '@angular/common';
import { DfToggleSwitchComponent } from './toggle-switch.component';
import { NgModule } from '@angular/core';
import { DfControlValueModule } from '../inputs/directives/control-value/control-value.module';

@NgModule({
  imports: [CommonModule, DfControlValueModule],
  declarations: [DfToggleSwitchComponent],
  exports: [DfToggleSwitchComponent],
})
export class DfToggleSwitchModule {}
