<dgx-modal
  [canCancel]="true"
  [cancelButtonText]="i18n.Core_Close"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
  [isHeaderBorderless]="true"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    {{ i18n.dgTagRating_Levels }}
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div class="guts-p-b-1 guts-p-h-1">
      <div class="guts-p-h-1">
        <!--Slider-->
        <dgx-rating-slider
          [value]="level"
          [options]="sliderConfig"
          [autoFocus]="true"
          (onRatingChange)="setLevel($event)"
        ></dgx-rating-slider>

        <!--Beginner/Expert labels-->
        <div
          class="l_flexbar l_flex-justify par par--light par--small up-me"
          dir="ltr"
        >
          <div class="guts-p-h-half" [textContent]="lowestLevelName"></div>
          <div class="guts-p-h-half" [textContent]="highestLevelName"></div>
        </div>
      </div>

      <div class="rel border rounded--large guts-p-full-1-half guts-m-t-1">
        <!--Level-->
        <dgx-tag-rating-donut
          size="sm"
          [level]="level.toString()"
          class="ib horizontal-center guts-m-b-1-half"
        ></dgx-tag-rating-donut>

        <!--Title-->
        <h3 class="h3 guts-p-b-1-half" [textContent]="levelTitle | async"></h3>

        <!--Description-->
        <div class="skill-definitions par par--small">
          <dl>
            <ng-container
              *ngFor="let key of skillAttributeKeys"
              data-dgat="tag-level-description-modal-74e"
            >
              <!--Skill attribute-->
              <dt [textContent]="i18n[key]"></dt>
              <!--Attribute description-->
              <dd [innerHTML]="getAttrDescription(key) | async"></dd>
            </ng-container>
          </dl>
        </div>
      </div>
    </div>
  </ng-container>
</dgx-modal>
