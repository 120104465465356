import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';

export const parseImageDataForBE = (
  source: any,
  imageUrlService: ImageUrlService
): {
  imageUrl?: string;
  resourceImageId?: string | number;
  legacyPictureUrl?: string;
} => {
  // WHERE imageUrl is undefined, it may have been deleted.
  if (!source?.imageUrl) {
    return {
      imageUrl: '',
      legacyPictureUrl: '',
      resourceImageId: undefined,
    };
  }
  // OTHERWISE, if we haven't updated our imageUrl, it will likely be a stringified
  // object. Parse for safety
  const { legacyPictureUrl, imageUrl, resourceImageId } =
    imageUrlService.getImageData(source.imageUrl);

  return {
    // Prefer source properties where those exist, as they'll be fresh from a new crop.
    imageUrl: source.legacyPictureUrl
      ? source.legacyPictureUrl
      : // But also, if this IS just a string, only resourceImage.image will have any value.
        // So fall back to that if legacyPictureUrl is undefined.
        legacyPictureUrl
        ? legacyPictureUrl
        : imageUrl,
    resourceImageId: source.resourceImageId
      ? source.resourceImageId
      : resourceImageId,
    legacyPictureUrl: source.legacyPictureUrl
      ? source.legacyPictureUrl
      : legacyPictureUrl,
  };
};
