import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { CourseFormModel } from '../course-form.model';
import { CourseApiEntity } from '../repository/course.entity.model';

@Injectable({ providedIn: 'root' })
export class PathwayCourseMapper
  implements Mapper<Partial<CourseFormModel>, CourseApiEntity>
{
  constructor(private ldFlagsService: LDFlagsService) {}

  public toApiParameters(source: CourseFormModel): CourseApiEntity {
    if ((!source.groups || source.groups.length === 0) && source.groupIds) {
      source.groups = source.groupIds;
    }

    const courseEntity: CourseApiEntity = {
      ...source.input,
      inputId: source.inputId,
      inputType: 'Course',
      durationHours:
        !this.ldFlagsService.durationForCoursesEventsOther &&
        source.unitType === 'Hours'
          ? source.units
          : source.durationHours,
      durationMinutes: source.durationMinutes,
      courseUrl: source.courseUrl,
      creditHours: source.creditHours,
      authored: source.authoring,
      dateCompleted: source.dateCompleted,
      institutionId: source.institution?.institutionId,
      institutionName: source.institutionName,
      name: source.course?.id ? source.course.label : source.courseName,
      tags: source.tags,
      verificationUrl: source.extent.verificationUrl,
      gradeId: source.extent.courseGrade,
      levelId: source.extent.courseLevel,
      organizationId: source.organizationId,
      orgContentMetadata: {
        groupIds: source.groupIds,
        hideFromCatalog: !source.addToCatalog,
      },
      owner: source.owner,
      language: source.language,
      country: source.country,
      isAccredited: source.isAccredited,
      comment: source.comment,
      description: source.description,
      units: source.units,
      unitType: source.unitType,
    };

    return courseEntity;
  }
  public toViewModel(source: CourseApiEntity): Partial<CourseFormModel> {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];

    const target: Partial<CourseFormModel> = {
      externalId: source.externalId,
      durationHours:
        !this.ldFlagsService.durationForCoursesEventsOther &&
        source.unitType === 'Hours'
          ? source.units
          : source.durationHours,
      durationMinutes: source.durationMinutes,
      inputId: source.inputId,
      courseUrl: source.courseUrl,
      courseName: source.name,
      fileManaged: source.fileManaged,
      description: source.description,
      courseTypeId: source.isAccredited ? 0 : 1, // Informal by default ( radio button )
      creditHours: source.creditHours,
      institution: {
        defaultUnitType: undefined,
        institutionId: source.institutionId,
        name: source.institutionName,
      },
      groups,
      groupIds: groups,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      isAccredited: source.isAccredited ? source.isAccredited : false, // Informal by default ( radio button )
      country: source.country ? source.country : '',
      authoring: source.authored ? source.authored : false,
      extent: {
        dateCompleted: source.dateCompleted,
        verificationUrl: source.verificationUrl,
        courseLevel: source.levelId,
        courseGrade: source.gradeId,
      },
      input: {
        isAccredited: source.isAccredited ? source.isAccredited : false, // Informal by default ( radio button )
        accessible: source.accessible,
        orgContentMetadata: { ...source.orgContentMetadata },
        institutionId: source.institutionId,
        name: source.name,
        userInputId: source.userInputId,
        inputType: source.inputType,
        inputId: source.inputId,
        title: source.title,
        courseUrl: source.courseUrl,
        institutionName: source.institutionName,
        externalId: source.externalId,
        description: source.description,
        groupIds: source.groupIds,
        dateCompleted: source.dateCompleted,
        gradeId: source.gradeId,
        tags: source.tags,
        verificationUrl: source.verificationUrl,
        authored: source.authored,
        levelId: source.levelId,
        organizationId: source.organizationId,
        courseNumber: source.courseNumber,
        creditHours: source.creditHours,
        imageUrl: source.imageUrl,
      },
      addToCatalog: source.orgContentMetadata
        ? !source.orgContentMetadata?.hideFromCatalog
        : false,
      tags: source.tags,
      units: source.units,
      unitType: source.unitType,
    };
    return target;
  }
}
