import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Opportunity Closed notification */
@Component({
  selector: 'dgx-opportunity-closed',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityClosedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('Notification_TitleFormat_OpportunityClosed', {
      opportunityAuthor: this.userFullNameTemplate,
    });
  }
}
