import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackerService } from '@app/shared/services/tracker.service';
import { tap } from 'rxjs/operators';
import { TrackingLink } from '@app/insights/tracking-links-table/tracking-link.model';
import { Component, OnInit, Input } from '@angular/core';
import { generateGuid } from '@app/shared/utils/uuid';
import { TranslateService } from '@ngx-translate/core';
import { TrackingLinkService } from '@app/insights/services/tracking-link.service';

interface TrackingLinkFormResolve {
  description?: string;
  link: TrackingLink;
  url: string;
  resourceId: number;
  resourceType: string;
  resourceCtrl: string;

  canManage?: boolean;
  isEditing?: boolean;
  goToUrlAfterCreate?: string;
}

interface TrackingLinkFormData {
  id: number;
  name: string;
  tag: string;
}

export interface TrackingLinkResponse {
  isEditing: boolean;
  // partially filled tracking link if new
  link: TrackingLink | TrackingLinkFormData;
}

@Component({
  selector: 'dgx-tracking-link-form',
  templateUrl: './tracking-link-form.component.html',
})
export class TrackingLinkFormComponent implements OnInit {
  @Input() public hideHeader: boolean = false;
  @Input() public resolve: TrackingLinkFormResolve;

  public description: string;
  public isEditing: boolean;
  public isSubmitting: boolean;
  public localLink: TrackingLinkFormData;
  public url: string;

  public i18n = this.translateService.instant([
    'TrackableLinkCtrl_CopyLink',
    'TrackableLinkCtrl_CopiedLink',
    'TrackableLinkCtrl_CopyLinkAgain',
    'TrackableLinkCtrl_ModalTitle',
    'TrackableLinkCtrl_EditModalTitle',
    'TrackableLinkCtrl_FormTitle',
    'TrackableLinkCtrl_FormTitlePlaceholder',
    'TrackableLinkCtrl_StartTracking',
    'Core_Optional',
    'Core_Loading',
  ]);

  constructor(
    private router: Router,
    private trackerService: TrackerService,
    private translateService: TranslateService,
    private trackingLinkService: TrackingLinkService,
    private activeModal: NgbActiveModal
  ) {}

  public ngOnInit(): void {
    const { link, url, isEditing, description } = this.resolve;

    this.description = description;
    this.isEditing = isEditing;

    this.localLink = isEditing
      ? { id: link.id, name: link.name, tag: link.tag }
      : { id: -1, name: '', tag: generateGuid() };

    this.url = `${url}&tltag=${this.localLink.tag}`;
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public submit(link: TrackingLinkFormData) {
    this.isSubmitting = true;
    const { resourceId, goToUrlAfterCreate } = this.resolve;
    const returnData: TrackingLinkResponse = {
      link,
      isEditing: this.isEditing,
    };

    if (!this.isEditing) {
      return this.createLink(link, resourceId).subscribe((data) => {
        returnData.link = data;
        this.isSubmitting = false;
        this.activeModal.close(returnData);

        if (goToUrlAfterCreate) {
          this.router.navigateByUrl(goToUrlAfterCreate);
        }
      });
    }

    return this.updateLink(link, resourceId).subscribe(() => {
      this.isSubmitting = false;
      this.activeModal.close(returnData);
    });
  }

  private createLink = (link: TrackingLinkFormData, resourceId: number) => {
    const { resourceCtrl, resourceType } = this.resolve;
    const { name, tag } = link;

    return this.trackingLinkService
      .createTrackingLink(resourceId, name, tag, resourceType, resourceCtrl)
      .pipe(
        tap((response) => {
          this.trackerService.trackEventData({
            action: `${resourceType} Trackable Link Created`,
            properties: {
              referenceId: resourceId,
            },
          });
        })
      );
  };

  private updateLink = (link: TrackingLinkFormData, resourceId: number) => {
    const { resourceCtrl, resourceType } = this.resolve;
    const { id, name } = link;

    return this.trackingLinkService
      .updateTrackingLink(id, resourceId, name, resourceType, resourceCtrl)
      .pipe(
        tap((response) => {
          this.trackerService.trackEventData({
            action: `${resourceType} Trackable Link Edited`,
            properties: {
              referenceId: resourceId,
            },
          });
        })
      );
  };
}
