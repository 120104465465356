import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { OrgMembersService } from '@app/orgs/services/org-members.service';
import { AuthService } from '@app/shared/services/auth.service';
import { OrgInfo } from '@app/account/account-api.model';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-invite-member-modal',
  templateUrl: './invite-member-modal.component.html',
})
export class InviteMemberModalComponent implements OnInit {
  @Input() public org: OrgInfo;

  public emailSuffixList: string[];
  public inviteList: string[];
  public inviteMessage: string;
  public inviteUrl: string;
  public isSubmitting: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private orgMembersService: OrgMembersService,
    private translateService: TranslateService
  ) {}

  /**
   * Returns true when there are more than 0 email addresses to invite
   */
  public get canSubmit(): boolean {
    return this.inviteList?.length > 0;
  }

  /**
   * Returns translated modal title
   * Ex: `Invite Members to Degreed`
   */
  public get headerTitle(): string {
    return this.translateService.instant(
      'dgOrgMembers_InviteMembersTitleFormat',
      {
        org: this.org.name,
      }
    );
  }

  public get orSeparator(): string {
    return ` ${this.translateService.instant('Core_Or')} `;
  }

  /**
   * Returns translated description for the personal message field
   */
  public get personalMessageDescription(): string {
    const orgName = this.org.name,
      rgx = /[ (),\[\]:\/\\;<>@]/g;
    const orgNameForEmail = orgName && orgName.replace(rgx, '').toLowerCase();
    return this.translateService.instant(
      'dgOrgMembers_PersonalMessageDescriptionFormat',
      {
        orgName: orgNameForEmail ? `${orgNameForEmail}-noreply` : 'support',
      }
    );
  }

  public ngOnInit(): void {
    this.getSuffixList();
    this.setOrgInviteUrl();
  }

  public getSuffixList() {
    this.orgMembersService
      .getRequiredEmailSuffix(this.org.organizationId)
      .subscribe((suffixList) => {
        this.emailSuffixList = suffixList?.split(',');
      });
  }

  /**
   * Use org member service to send invite emails to users.
   */
  public inviteNewMembers() {
    if (this.canSubmit) {
      this.isSubmitting = true;
      this.orgMembersService
        .sendInvite({
          orgId: this.org.organizationId,
          emailAddresses: this.inviteList,
          customMessage: this.inviteMessage,
        })
        .pipe(finalize(() => (this.isSubmitting = false)))
        .subscribe(() => {
          this.activeModal.close();
        });
    }
  }

  /**
   * Handles updates to the invite list
   *
   * @param list - list of email addresses emitted from the invite-member-field
   */
  public updateInviteList(list: string[]) {
    this.inviteList = list;
  }

  /**
   * Generates organization invite link
   * Ex: https://localhost:44300/orgs/invite/sxAM9ureg7o__BnStb4Fqw~~?orgsso=degreed"
   */
  public setOrgInviteUrl(): void {
    this.orgMembersService
      .getOrgInviteUrl(this.org.organizationId)
      .subscribe((orgInviteUrl) => {
        this.inviteUrl = orgInviteUrl;
      });
  }
}
