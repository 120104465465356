import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { AuthService } from '@app/shared/services';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';
import { ArticleFacadeBase } from '../article-facade-base.service';
import { ArticleMapperService } from '../article-mapper.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';

// misc
import { MediaParseType } from '@app/shared/models/core.enums';
import { MediaMetadataStatus } from '@app/user-content/user-input/media-modal/media-modal-facade-base';
import { ArticleModel } from '@app/user-content/user-input-v2/inputs/article/article.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { ArticleService } from '../article.service';
import { FormGroup } from '@angular/forms';
import { ArticleNotificationService } from '../article-notification.service';
import { ArticleTrackerService } from '../article-tracker.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { combineLatest, map } from 'rxjs';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable()
export class ArticleGlobalAddInputFacade extends ArticleFacadeBase {
  constructor(
    public translate: TranslateService,
    public inputsService: InputsService,
    public authService: AuthService,
    public mapperService: ArticleMapperService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public articleService: ArticleService,
    public globalAddTrackingService: GlobalAddTrackingService,
    private articleNotificationService: ArticleNotificationService,
    private articleTrackerService: ArticleTrackerService,
    private commentsApiService: CommentsApiService,
    private tipService: TipService
  ) {
    super(
      inputsService,
      authService,
      translate,
      mapperService,
      orgInternalContentService,
      inputImageUploadAdapterService,
      articleService,
      globalAddTrackingService
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): ArticleModel {
    return readFirst(this.viewModel$);
  }

  public get orgName(): string {
    return this.authService.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    ).name;
  }

  // *******************************************************
  // Overwrites
  // *******************************************************

  /**
   * Overwrite the on Next form the base facade for global add
   * When the next button has been selected
   * @param url
   * @returns
   */
  public async onNext(url: string): Promise<void> {
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

      // Update the view model with the url and set loading
      this.viewModel = { ...this.viewModel, entryUrl: url };
      try {
        const result = await this.inputsService.getMediaMetadataAsPromise(
          url,
          this.viewModel.inputContext.inputType,
          MediaParseType.None
        );

        // Update the viewModel with the results
        const updatedView = this.mapperService.toViewModel(
          result,
          this.viewModel
        );

        this.viewModel = {
          ...this.viewModel,
          ...updatedView,
          isInitialForm: false,
          organizationId: this.orgId,
          owner: undefined,
        };

        this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
        return;
      } catch (e) {
        this.mediaMetadataStatus$.next(MediaMetadataStatus.None);
        throw e;
      }
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      await super.onSubmit(form, true);
      this.performSuccessSideEffects();
    } catch {
      this.performFailureSideEffects();
    }
    return;
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    const shouldShowResults$ = combineLatest([this.submissionStatus$]).pipe(
      map(([s]) => s >= SubmissionStatus.Submitting)
    );

    const isNewbUser$ = this.tipService.onboardHistory$.pipe(
      map((v) => {
        return v.indexOf('firstinput') === -1;
      })
    );

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      isNewbUser$,
      shouldShowResults$,
    };
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.articleNotificationService.notifyArticleInputCreated(
        this.viewModel.title
      );

      if (this.viewModel.comment) {
        const resource = {
          resourceId: this.viewModel.inputId,
          resourceType: 'Article',
          title: this.viewModel.title,
        };
        this.commentsApiService
          .addComment(resource, this.viewModel.comment)
          .subscribe();
      }

      const apiParameters = this.mapperService.toApiParameters(this.viewModel);

      this.articleTrackerService.trackContentCompleted(apiParameters);

      // using this over the articleNotificationService as we already have multiple pages linked to the events from
      // the inputsService and userOutcomesService
      this.inputsService.notifyInputModified('Article');
    }
  }

  /** Performs any side effects required following failed creation of an Input */
  protected performFailureSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.articleNotificationService.notifyArticleInputCreateFailed();
    }
  }
}
