import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-add-content-button',
  templateUrl: './pathway-add-content-button.component.html',
  styleUrls: ['./pathway-add-content-button.component.scss'],
})
export class PathwayAddContentButtonComponent {
  @Input() public size: 'small' | 'large';
  @Output() public addContentClicked = new EventEmitter<any>();

  public readonly i18n = this.translate.instant(['Pathways_AddContent']);

  constructor(private translate: TranslateService) {}

  public addContent($event) {
    this.addContentClicked.emit($event.target);
  }
}
