import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommentsModule } from '@app/comments/comments.module';
import { SharedModule } from '@app/shared/shared.module';

import { CheckDuplicateInputsComponent } from './components/check-duplicate-inputs.component.ts/check-duplicate-inputs.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [CheckDuplicateInputsComponent],
  declarations: [CheckDuplicateInputsComponent],
})
export class InputsSharedModule {}
