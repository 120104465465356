import { Injectable } from '@angular/core';
import { SearchSuggestionSource } from '@app/search/search-api.model';
import { SearchSuggestionPresenterService } from '@app/search/services';
import { ParentItemViewModel } from '@app/shared/models/core-view.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchAutocompleteDataService {
  constructor(private suggestionService: SearchSuggestionPresenterService) {}

  public searchAutocompleteSuggestions(
    searchTerm: string,
    source?: SearchSuggestionSource
  ): Observable<ParentItemViewModel[]> {
    return this.suggestionService.getGroupedSearchSuggestions(
      searchTerm,
      source
    );
  }

  public searchDefaultSuggestions(): Observable<ParentItemViewModel[]> {
    return this.suggestionService.getDefaultSuggestions();
  }

  //// Natural Language POC
  public searchNaturalLanguage(query: string): Observable<string> {
    return this.suggestionService.streamNaturalLanguageSearch(query);
  }
}
