<li class="v-middle-children" ngbDropdownItem>
  <button
    (click)="actionClicked($event)"
    class="listbox--option"
    data-dgat="split-button-menu-item-c8b"
    type="button"
  >
    <df-icon *ngIf="icon" icon="{{ icon }}"></df-icon>
    <span>{{ displayText }}</span>
  </button>
</li>
