<dgx-modal
  bodyClasses="guts-p-full-0"
  [canCancel]="true"
  [useDefaultForm]="false"
  [isFooterless]="true"
  [isHeaderBorderless]="true"
  (dismiss)="dismiss($event)"
>
  <ng-container class="modal-header">
    {{ 'Pathways_AddContent' | translate }}
  </ng-container>
  <ng-container class="modal-body">
    <dgx-tab-navigation-routerless
      [tabList]="tabList"
      wrapperClasses="guts-p-h-2"
    >
      <ng-template #catalog>
        <form>
          <dgx-add-by-search
            [defaultTerm]="addContentModel.defaultSearch"
            [isSubmitting]="isSubmitting"
            [savedSearch]="searchItems"
            [savedManual]="manualItems"
            [resourceCategory]="resourceCategory"
            (addItems)="itemsAdded($event)"
            (updateItemList)="updateSearchItems($event)"
            (addToBin)="addToBin($event)"
            (dismiss)="dismiss($event)"
            resourceType="Pathway"
            [resourceOrgId]="pathwayOrgId"
          ></dgx-add-by-search></form
      ></ng-template>
      <ng-template #manual>
        <form>
          <dgx-pathway-add-by-type
            [pathId]="pathId"
            [pathwayOrgId]="addContentModel.pathway?.organizationId"
            [isSubmitting]="isSubmitting"
            [savedSearch]="searchItems"
            [savedManual]="manualItems"
            (addItems)="itemsAdded($event)"
            (updateItemList)="updateManualItems($event)"
            (addToBin)="addToBin($event)"
            (dismiss)="dismiss($event)"
          ></dgx-pathway-add-by-type>
        </form>
      </ng-template>
      <ng-template #bin>
        <form>
          <!--
            Linter error
            Property 'pathwayVersion' does not exist on type
            [pathwayVersion]="pathwayVersion"
          -->
          <dgx-hold-for-later
            [isSubmitting]="isSubmitting"
            [savedSearch]="searchItems"
            [savedManual]="manualItems"
            (addItems)="itemsAdded($event)"
            (dismiss)="dismiss($event)"
          ></dgx-hold-for-later>
        </form>
      </ng-template>
    </dgx-tab-navigation-routerless>
  </ng-container>
</dgx-modal>

<ng-template #binHeader>
  <span
    *ngIf="vm$ | async as vm"
    class="badge badge-neutral badge-pill badge-pill--compact"
  >
    {{ vm.itemBin.length }}
  </span>
</ng-template>
