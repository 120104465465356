import { Component, OnInit } from '@angular/core';
import { pascalCaseKeys } from '@app/shared/utils/property';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';

// services
import { ReportingService } from '@app/insights/services/reporting.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TagsService } from '@app/tags/services/tags.service';

// types
import {
  KPI,
  TagComparisonByUser,
  TagRatingInsightKpi,
} from '@app/insights/insights-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { map, switchMap, take } from 'rxjs/operators';
import { InternalTagRatingTypes, TagRatingResponse } from '@app/tags/tags';

/**
 * Personal Skill Career Insights Component
 * Used to display the following:
 *
 * - KPIs insights in profile
 * - Top 10 skills for my job role table
 *
 * i18n strings in this component:
 * KPI_JobRoleSkillCount
 * KPI_AddedSkillsByOthersAverage
 * KPI_RatedSkillsByOthersAverage
 * KPI_JobRoleSkillCountToolTip
 * KPI_AddedSkillsByOthersAverageToolTip
 * KPI_RatedSkillsByOthersAverageToolTip
 * KPI_NoJobRoleToolTip
 * KPI_NotApplicableToolTip
 *
 *
 */
@Component({
  selector: 'dgx-personal-skills-career-insights',
  templateUrl: './personal-skills-career-insights.component.html',
})
export class PersonalSkillsCareerInsightsComponent implements OnInit {
  // Local
  public hasJobRole: boolean;
  public kpisLoading: boolean;
  public tableLoading: boolean;
  public orgId: number;
  public userProfileKey: number;
  public kpis: KPI[] = [];
  public skills: TagComparisonByUser[];

  constructor(
    private auth: AuthService,
    private reportingService: ReportingService,
    private translate: TranslateService,
    private tagsService: TagsService
  ) {}

  public ngOnInit(): void {
    this.kpisLoading = true;
    this.tableLoading = true;
    this.orgId = this.auth.authUser.defaultOrgId;
    this.userProfileKey = this.auth.authUser.viewerProfile.userProfileKey;
    const authUser$ = this.auth.fetchAuthenticatedUser(true);
    const kpis$ = this.getKpis(this.userProfileKey, this.orgId);
    const skills$ = this.reportingService.GetInsightTagComparisonByUser(
      this.userProfileKey,
      this.orgId
    );
    forkJoin([authUser$, kpis$, skills$])
      .pipe(take(1))
      .subscribe((result) => {
        this.hasJobRole = !!this.auth.authUser.viewerProfile.jobRole;
        this.kpis = this.formatKpis(result[1]);
        this.skills = result[2];
        this.kpisLoading = false;
        this.tableLoading = false;
      });
  }

  public getKpis(userProfileKey, orgId): Observable<TagRatingInsightKpi[]> {
    return this.reportingService.GetInsightKpisByUser(userProfileKey, orgId);
  }

  public formatKpis(kpis: TagRatingInsightKpi[]): KPI[] {
    return (
      kpis.length &&
      kpis.map((kpi: TagRatingInsightKpi, index: number) => {
        return {
          location: 'Personal Skill Career Insights',
          description: this.translate.instant(`KPI_${kpi.name}`),
          metric: this.formatKpiMetrics(kpi),
          name: kpi.name,
          order: index,
          tooltip: this.getKpiToolTip(kpi),
        };
      })
    );
  }

  public rateSkill(obj: { tag: TagComparisonByUser; level: string }): void {
    this.kpisLoading = true;
    const { tag, level } = obj;
    const updates: Observable<TagsApi.Tag | TagRatingResponse>[] = [];
    if (!tag.isFollowing) {
      const tagToAdd: TagsApi.Tag = {
        ...tag,
        tagId: tag.tagId,
        name: tag.tagName,
        internalUrl: null,
        title: null,
        resourceId: null,
        resourceType: null,
        rating: null,
        ratings: null,
      };
      updates.push(this.tagsService.addUserTag(tagToAdd));
    }
    // TODO: level is coming as string from dgx-personal-skills-table
    // however we need it to be a int for the api
    // we should look into this inconsistency and make it all the same.
    const parsedLevel = parseInt(level, 10);
    if (parsedLevel > 0) {
      const ratings = (tag.selfRating > 0
        ? [{ type: InternalTagRatingTypes.self, level: tag.selfRating }]
        : []) as unknown as TagsApi.UserTagRating[];
      updates.push(
        this.tagsService.rateTag(
          parsedLevel,
          InternalTagRatingTypes.self,
          {
            ...tag,
            name: tag.tagName,
            ratings,
          } as unknown as TagsApi.TagDetails,
          'Profile Insights'
        )
      );
    }
    forkJoin(updates)
      .pipe(
        take(1),
        switchMap(() => {
          this.updateSkillSelfRating(tag, level);
          return this.getKpis(this.userProfileKey, this.orgId);
        }),
        map((result) => {
          this.kpis = this.formatKpis(result);
          this.kpisLoading = false;
        })
      )
      .subscribe();
  }

  public formatKpiMetrics(kpi: TagRatingInsightKpi): number | string {
    // return 'n/a' if there is not enough data to compare applicant to
    if (kpi.isNotApplicable) {
      return 'n/a';
    }

    // return a string representation of the skills count
    if (kpi.name === 'JobRoleSkillCount') {
      return `${kpi.metricPrimary}/${kpi.metricSecondary}`;
    }

    // return metricPrimary for AddedSkillsByOthersAverage and RatedSkillsByOthersAverage KPIs
    return kpi.metricPrimary;
  }

  public getKpiToolTip(kpi: TagRatingInsightKpi): string {
    // User has no job role
    if (!this.hasJobRole) {
      return this.translate.instant(`KPI_NoJobRoleToolTip`);
    }
    // User has job role but there are <2 other users in role
    if (kpi.isNotApplicable) {
      return this.translate.instant(`KPI_NotApplicableToolTip`);
    }
    // User has job role, there are 2+ others in role
    return this.translate.instant(`KPI_${kpi.name}ToolTip`);
  }

  private updateSkillSelfRating(tag, rating): void {
    const { tagId } = tag;
    const i = this.skills.findIndex((s) => s.tagId === tagId);
    this.skills[i].isFollowing = true;
    this.skills[i].selfRating = rating;
    this.skills = [...this.skills];
  }
}
