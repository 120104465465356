<div class="l_flexbar par par--small">
  <div class="l_flex-grow">
    <div *ngIf="displayStartDate" class="guts-m-v-half font-medium">
      <time>{{ displayStartDate }}</time
      ><span *ngIf="displayEndDate">
        &ndash; <time>{{ displayEndDate }}</time></span
      >
    </div>
    <div
      *ngIf="
        displayStartTime &&
        (displayStartDate === displayEndDate || !displayEndDate)
      "
      class="guts-m-v-half font-medium"
    >
      <time>{{ displayStartTime }}</time
      ><span *ngIf="displayEndTime"> &ndash; </span
      ><time>{{ displayEndTime }}</time>
    </div>
    <div *ngIf="location" class="par--light">{{ location }}</div>
  </div>
  <div *ngIf="shouldShowRegistrationLink" class="guts-p-r-1">
    <a
      href="{{ session.registrationUrl }}"
      (click)="trackRegisterLink()"
      class="link font-semibold ib-v-middle"
      data-dgat="live-event-session-425"
      rel="noopener noreferrer"
      target="_blank"
      aria-describedby="a11yNewWindowDescription"
      >{{ 'Core_Register' | translate
      }}<df-icon
        size="small"
        icon="arrow-up-right-out"
        class="guts-p-l-half"
      ></df-icon
    ></a>
  </div>
  <div *ngIf="shouldShowJoinButton">
    <dgx-action-button
      (click)="openLiveSession()"
      [autoToggleState]="false"
      [isActive]="true"
      icon="video-camera"
      dgatInput="content-card-a4a"
    >
      <div>{{ 'Core_Join' | translate }}</div>
    </dgx-action-button>
  </div>
</div>
