<ul class="social-pics social-pics--{{ size }}" *ngIf="users.length">
  <li
    class="social-pic__item social-pic__item--{{ size }}"
    *ngFor="let user of users; let index = index; trackBy: trackByFn"
    data-dgat="socialPics-b0a"
  >
    <dgx-profile-pic
      *ngIf="!maxUsers || index < maxUsers"
      [useFallbackClass]="false"
      [profile]="user"
      class="social-pic__image social-pic__image--{{ size }}"
      [size]="size"
      [hideMentorIcon]="true"
    ></dgx-profile-pic>
  </li>
  <li
    class="social-pic__item social-pic__item-count social-pic__item--{{ size }}"
    *ngIf="!hideLeftOverUsers && leftOverUsers"
  >
    <span
      class="social-pic__count social-pic__count--{{ size }} par par--small"
    >
      +{{ leftOverUsers }}
    </span>
  </li>
</ul>
