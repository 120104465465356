import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppComponent } from '@app/app.component';

@Component({
  selector: 'dgx-root',
  template: `
    <dgx-bulk-icon-registry>
      <ng-content></ng-content>
    </dgx-bulk-icon-registry>
    <div class="grid center-item">
      <header class="app-header js-app-header"></header>
      <div class="grid__col-12 grid__col--bleed">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsTeamsBootstrapComponent extends AppComponent {}
