import { Injectable, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { InputContext } from '../../user-input.model';
import { ContentHostingUploadAdapterService } from '@app/uploader/upload-section/adapters/content-hosting-upload-adapter.service';
import { TranslateService } from '@ngx-translate/core';
import {
  SelectFieldComponent,
  SelectFieldParams,
} from '@app/form-fields/wrappers/select-field/select-field.component';
import { FieldType } from '@app/user-content/services/field-builder-factory';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { MediaFormModel } from '../../media-modal/media-form.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { ArticleCommonFieldBuilder } from '../article-common-field.builder';
import { InputsService } from '@app/inputs/services/inputs.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { GroupIdentifier } from '@app/groups/group-api';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { hasBrokenUrlValidator } from '@app/shared/validators/has-broken-url.validator';

/** Creates an array of form field configurations for adding/editing Articles within the content catalog */
@Injectable({ providedIn: 'any' })
export class ContentCatalogArticleFormRenderer implements FormRenderer {
  private get ldFlagNewDuractionFields(): boolean {
    return this.ldFlagsService.newDurationForArticlesAndAssessments;
  }

  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_REQUIRED_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('MediaFormCtrl_UrlRequired'),
    },
  };

  private urlValidatorAllowEmpty = {
    urlValidation: {
      ...this.urlValidator.urlValidation,
      expression: (control: AbstractControl, _) => {
        const valid =
          HTTP_REQUIRED_URL_PATTERN.test(control.value) ||
          control.value?.trim() === '';
        return valid;
      },
    },
  };

  public expandAdvanced = new BehaviorSubject(false);

  constructor(
    private translate: TranslateService,
    private builder: DfFormFieldBuilder,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private contentHostingUploadAdapterService: ContentHostingUploadAdapterService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private articleFieldBuilder: ArticleCommonFieldBuilder,
    private inputsService: InputsService,
    private webEnvironmentService: WebEnvironmentService,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext<MediaFormModel<HostedContentMetadata>>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const state = context.state();
    return state.isMediaParsed || // parsed media URL
      state.uploadedContentDetails || // or uploaded hosted content
      context.inputContext.isEditing
      ? this.renderExpandedForm(context)
      : this.renderInitialForm(context);
  }

  protected renderInitialForm(
    context: RendererContext<MediaFormModel<HostedContentMetadata>>
  ): DfFormFieldConfig[] {
    const vm = context.state();

    const contentHosting = this.buildContentHostingField(
      context.state,
      context.inputContext.isEditing,
      context.templates.contentUploader
    );

    // Initial article loader input
    return [
      // Media URL
      this.builder
        .optionalTextInput('mediaUrl', 'MediaFormCtrl_ArticleUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidatorAllowEmpty) // allow empty to be valid initially to not throw errors
        .withDgatId('articleForm-97a')
        .autofocused()
        .build(),
      contentHosting,
    ];
  }

  /** Expanded field set, shown when article has been parsed */
  protected renderExpandedForm(
    context: RendererContext<MediaFormModel<HostedContentMetadata>>
  ): DfFormFieldConfig[] {
    const vm = context.state();
    const contentHosting = this.buildContentHostingField(
      context.state,
      context.inputContext.isEditing,
      context.templates.contentUploader
    );
    // Expanded field set, shown when article has been loaded
    return [
      // Created By
      this.commonFieldBuilder.buildCreatedByField(context.templates.creator),
      // Media URL
      this.createUrlField(context),
      // Content hosting
      contentHosting,
      // Title
      this.articleFieldBuilder.buildTitleField(
        vm.canEditTitle,
        context.templates.readonlyField
      ),
      // Format
      this.buildFormatField(vm.inputTypeFormats$),
      // Duration
      this.commonFieldBuilder.buildDurationFields(
        this.ldFlagNewDuractionFields,
        vm.inputType
      ),
      // Description
      this.buildDescriptionField(),
      // Image uploader
      this.buildImageUploaderField(context.inputContext, vm.inputId),
      // Image size selector
      // TODO: include image size selector when it's supported correctly by the back end round trips. See https://degreedjira.atlassian.net/browse/PD-68988
      //this.buildImageSizeField(context.templates.imageSize),
      // Skills
      this.commonFieldBuilder.buildSkillsField(vm.topTags, true), // we have a lot of fields to show in catalog mode so hide top tags for compactness
      // Advanced fields
      this.buildAdvancedFieldset(
        vm.canRestrictContent,
        vm.isVisibleToOrg,
        vm.organizationId,
        vm.expandAdvanced$,
        vm.groups,
        context.templates.advancedExpander,
        context
      ),
    ];
  }

  protected buildContentHostingField(
    state: () => MediaFormModel<HostedContentMetadata>,
    isEditing: boolean,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    const vm = state();
    const hostedContentDetails = vm.uploadedContentDetails;
    return this.builder
      .customField('uploadedContentDetails', '', contentUploaderTemplate, {
        useExistingFileRestrictions: true,
        file: hostedContentDetails
          ? {
              name: hostedContentDetails.fileName,
              size: hostedContentDetails.fileSize,
            }
          : undefined,
        uploadAdapter: this.contentHostingUploadAdapterService.getAdapter(
          'Article',
          vm.inputId
        ),
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: this.buildCHLabel(),
        i18n: this.translate.instant(['Core_Or']),
        hasDivider: !isEditing && !vm.mediaUrl && !hostedContentDetails, // only on initial page, when not editing existing uploaded content
      })
      .unwrapped()
      .hiddenWhen(() => {
        const vm = state();
        const acceptableHostTypes = ['DegreedMedia'];
        return (
          !vm.shouldShowContentUploader ||
          vm.isMediaParsed ||
          (isEditing && !hostedContentDetails) ||
          (hostedContentDetails &&
            !acceptableHostTypes.includes(hostedContentDetails.hostType))
        );
      })
      .build();
  }

  /**
   * Builds description label for Content Hosting file uploader
   *
   * Show default description generated by upload-section component for
   * Azure Content and hide description for Box b/c of its long file list
   *
   * TODO: Can remove once Box has been removed
   *
   */
  private buildCHLabel() {
    const header = this.translate.instant('dgContentHosting_DragAndDrop');
    return { header };
  }

  protected buildFormatField(inputTypeFormats$: Observable<string[]>) {
    return this.builder
      .fieldGroup(
        '',
        [
          this.builder
            .foreignField<SelectFieldParams>(
              'format',
              'dgOrgInternalContentForm_ArticleFormat',
              SelectFieldComponent.REGISTERED_FIELD_TYPE,
              {
                options$: inputTypeFormats$.pipe(
                  // map formats to SelectOption
                  map((formats) => formats.map((f) => ({ title: f })))
                ),
                optionLabelKey: 'title',
                optionTrackByKey: 'title',
                placeholder: this.translate.instant(
                  'dgOrgInternalContentForm_ArticalFormatPlaceholder'
                ),
              }
            )
            .styledBy('df-form__col-half')
            .build(),
        ],
        'df-form__row'
      )
      .build();
  }

  protected buildDescriptionField() {
    return this.builder.descriptionLong().withDgatId('articleForm-59d').build();
  }

  protected buildImageUploaderField(
    inputContext: InputContext,
    inputId: number
  ) {
    return this.contentCatalogFormBuilderService
      .getBuilder(FieldType.USER_IMAGE_FIELD)
      .build({
        useCropper: true,
        inputType: inputContext.inputType,
        resourceId: inputId,
      });
  }

  protected buildImageSizeField(
    imageSizeTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    let sizes = [];
    this.inputsService.getImageSizes().subscribe((s) => (sizes = s));
    return this.builder
      .customField(
        'imageSizeId',
        'dgOrgInternalContentForm_ImageDisplayOption',
        imageSizeTemplate,
        {
          // template parameters
          imageSizeOptions$: this.inputsService.getImageSizes().pipe(
            // map to size option view model
            map((sizes) => {
              return sizes.map((s) => ({
                label: this.translate.instant(
                  'dgOrgInternalContentForm_ImageDisplayOption' + s.imageSizeId
                ),
                id: s.imageSizeId,
              }));
            })
          ),
        }
      )
      .hiddenWhen(() => sizes.length <= 1)
      .build();
  }

  protected buildCommentField(
    isCompleting: boolean,
    isEditing: boolean,
    userCanComment: boolean
  ) {
    return this.builder
      .optionalTextarea('comment', 'MediaFormCtrl_WhatDidYouLearnLong')
      .withPlaceholder('MediaFormCtrl_WhatDidYouLearnShort')
      .withDgatId('articleForm-aaf')
      .hiddenWhen(
        () =>
          // comment is independently associated with the user completion, and not available when editing
          !(isCompleting && userCanComment) || isEditing
      )
      .build();
  }

  protected buildAdvancedFieldset(
    canRestrictContent: boolean,
    isInitiallyVisibleToOrg: boolean,
    orgId: number,
    expand$: Observable<boolean>,
    groups: GroupIdentifier[],
    expanderTemplate: TemplateRef<DfFieldTemplateContext>,
    context: RendererContext<MediaFormModel<HostedContentMetadata>>
  ) {
    return this.contentCatalogFormBuilderService
      .getBuilder(FieldType.ADVANCED_FIELDSET)
      .build({
        canRestrictContent,
        orgId,
        isInitiallyVisibleToOrg,
        groups,
        expand$,
        expanderTemplate,
        fieldOptions: {
          externalId: {
            isDisabled: context.state().fileManaged,
          },
        },
      });
  }

  private createUrlField(
    context: RendererContext<MediaFormModel<HostedContentMetadata>>
  ): DfFormFieldConfig {
    if (!this.ldFlagsService.showBrokenLinksManagement) {
      return this.builder
        .requiredTextInput('mediaUrl', 'MediaFormCtrl_ArticleUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidator)
        .withDgatId('articleForm-391')
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .hiddenWhen(() => !!context.state().uploadedContentDetails) // hide if we using hosted content instead of parsed URL
        .build();
    } else {
      const originalUrl = context.state().mediaUrl;
      return this.builder
        .customField('mediaUrl', null, context.templates.urlBrokenValidation, {
          label: this.translate.instant('MediaFormCtrl_ArticleUrl'),
          invalidUrlMessage: this.translate.instant(
            'MediaFormCtrl_UrlRequired'
          ),
          brokenUrlMessage: this.translate.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
          originalUrl,
          isRequired: true,
        })
        .validatedByIndexed(this.urlValidator)
        .validatedBy((control: AbstractControl) =>
          hasBrokenUrlValidator(
            control,
            this.translate.instant('dgOrgInternalContent_BrokenLinkError'),
            context.state().hasBrokenUrl,
            control.value !== originalUrl
          )
        )
        .withDgatId('articleForm-391')
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .hiddenWhen(() => !!context.state().uploadedContentDetails) // hide if we using hosted content instead of parsed URL
        .build();
    }
  }
}
