import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { RecommendationGroup } from '@app/recommendations/recommendations.api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dgx-share-with-group-modal',
  templateUrl: './share-with-group-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareWithGroupModalComponent implements OnInit {
  @Input() title: string;
  @Input() groups: RecommendationGroup[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
