import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrgInfo } from '@app/account/account-api.model';
import { AuthUser } from '@app/account/account-api.model';
import { OrgViewBaseComponent } from '@app/orgs/components/org-view-base/org-view-base.component';
import { RouterComponents } from '@app/orgs/constants';
import {
  ManagedGroup,
  OrgGroupsService,
} from '@app/orgs/services/org-groups.service';
import {
  DataColumn,
  DataColumnDate,
  DataColumnList,
} from '@app/shared/components/data-table/data-table.d';
import { Filter } from '@app/shared/components/filter/filter.component';
import { AuthService } from '@app/shared/services/auth.service';
import { GroupService } from '@app/shared/services/group.service';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dgx-org-groups',
  templateUrl: './org-groups.component.html',
})
export class OrgGroupsComponent extends OrgViewBaseComponent implements OnInit {
  @ViewChild('groupName', { static: true })
  public groupNameTemplate: TemplateRef<HTMLElement>;
  @ViewChild('actionLink', { static: true })
  public actionLinkTemplate: TemplateRef<HTMLElement>;

  @ViewChild('visibilityTemplate', { static: true })
  public visibilityTemplate: TemplateRef<HTMLElement>;

  @ViewChild('memberCountTemplate', { static: true })
  public memberCountTemplate: TemplateRef<HTMLElement>;

  public authUser: AuthUser;
  public bulkEdit: boolean = false;
  public canManageGroups: boolean;
  public canSelectRows: boolean;
  public columns: (DataColumn | DataColumnDate | DataColumnList)[] = [];
  public groups: ManagedGroup[];
  public hasMoreItems: boolean;
  public isLoading: boolean = false;
  public orgInfo: OrgInfo;
  public propTrackingKey = 'groupId';
  public searchTerm: string;
  public orderBy = 'name';
  public isDescending = false;
  public filters: Filter[] = [];
  public pageNum: number = 1;
  public numResultsPerPage: number = 10;
  public totalNumResults: number;

  public readonly CLASS_NAME = RouterComponents.ORG_GROUPS;

  public i18n = this.translateService.instant([
    'OrgGroupsCtrl_RequestPending',
    'OrgGroupsCtrl_Leave',
    'Core_Join',
    'OrgGroupsCtrl_Dynamic',
    'OrgGroupsCtrl_Static',
    'OrgGroupsCtrl_ThreeOrLess',
    'BusinessRules_DateCreated_Column',
  ]);

  public dynamicGroupsEnabled = this.ldFlagsService.orgManagement.dynamicGroups;

  private _skip: number = 0;

  constructor(
    private authService: AuthService,
    private groupService: GroupService,
    private orgGroupsService: OrgGroupsService,
    private translateService: TranslateService,
    private ldFlagsService: LDFlagsService
  ) {
    super();
  }

  public get selectedGroups() {
    return this.groups?.filter((group) => group.isSelected);
  }

  public get showBulkActions(): boolean {
    return this.selectedGroups?.length > 0;
  }

  public ngOnInit(): void {
    if (!this.dynamicGroupsEnabled) {
      // we don't want a default sort unless the dynamic group flag is enabled.
      // once the flag is enabled, a default sort is the expected behavior
      this.orderBy = undefined;
      this.isDescending = undefined;
    }
    this.authUser = this.authService.authUser;
    this.orgInfo = this.authUser.orgInfo.find(
      (x) => x.organizationId === this.orgId
    );
    this.canManageGroups = this.orgInfo.permissions.manageGroups;
    this.columns = this.getColumns();
    this.doSearch();
    this.orgGroupsService.trackGroupAction({
      action: 'Org Groups Viewed',
    });
  }

  public doSearch(term?: string) {
    this.searchTerm = term;
    this.reset();
    this.getGroups();
    if (term) {
      this.orgGroupsService.trackGroupAction({
        action: 'Org Groups Searched',
      });
    }
  }

  public doFilter($event: { filters: Filter[]; id: string }) {
    this.reset();
    this.filters = $event.filters;
    this.getGroups();
  }

  public leaveGroup(group: ManagedGroup) {
    this.orgGroupsService
      .showLeaveGroupModal(group)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => this.doSearch(this.searchTerm));
  }

  public joinGroup(group: ManagedGroup) {
    this.groupService
      .join(
        {
          groupId: group.groupId,
          name: group.name,
          privacyLevel: group.privacyLevel,
          userCount: group.userCount,
          interestNames: null,
        },
        null
      )
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => this.doSearch(this.searchTerm));
  }

  public requestMembership(group: ManagedGroup) {
    return this.groupService
      .requestMembership({
        groupId: group.groupId,
        name: group.name,
        privacyLevel: group.privacyLevel,
        userCount: group.userCount,
      })
      .subscribe(() => this.doSearch(this.searchTerm));
  }

  public selectNone() {
    this.groups = this.groups.map((group) => ({
      ...group,
      isSelected: false,
    }));
  }

  public getGroups() {
    // prevent multiple requests
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    return this.orgGroupsService
      .groupsToManage(
        this.orgId,
        this.skip,
        this.take,
        this.searchTerm,
        this.canManageGroups,
        this.orderBy,
        this.isDescending,
        this.filters
      )
      .pipe(
        this.takeUntilDestroyed(),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response) => {
        this.groups =
          this.skip === 0 || this.dynamicGroupsEnabled
            ? response.groups
            : this.groups.concat(response.groups);
        this.hasMoreItems = response.hasMoreGroups;
        this._skip += this.take;
        this.filters = response.filters;
        this.totalNumResults = response.totalCount;
      });
  }

  public getAriaLabel(i18nKey: string, name: string) {
    return this.translateService.instant(i18nKey, { name });
  }

  public getMemberCountDisplay(group: ManagedGroup) {
    return group.membershipType === 'Dynamic' &&
      group.userCount <= 3 &&
      group.userCount !== 0
      ? this.i18n.OrgGroupsCtrl_ThreeOrLess
      : group.userCount;
  }

  public changeSort(sort: string) {
    if (this.isLoading) {
      return;
    }
    this.reset();

    if (this.orderBy === sort) {
      this.isDescending = !this.isDescending;
    } else {
      this.orderBy = sort;
      this.isDescending = false;
    }

    this.getGroups();
  }

  public pageChange(newlySelectedPage: number) {
    this.pageNum = newlySelectedPage;
    this.getGroups();
  }
  public perPageChange(itemsPerPage: number) {
    this.numResultsPerPage = itemsPerPage;
    this.getGroups();
  }

  private getColumns(): (DataColumn | DataColumnDate | DataColumnList)[] {
    const dynamicGroupsColumnsDefinition = [
      {
        label: 'OrgGroupsCtrl_GroupName',
        headClasses: 'l_w50',
        canSort: true,
        sortName: 'name',
        template: this.groupNameTemplate,
      } as DataColumn,
      {
        label: 'GroupSettingsCtrl_PrivacySettings',
        headClasses: 'l_w20',
        template: this.visibilityTemplate,
      },
      {
        label: 'OrgGroupsCtrl_GroupMembers',
        template: this.memberCountTemplate,
        canSort: true,
        sortName: 'members',
        headClasses: 'l_w10 right-text guts-p-l-half guts-p-r-3',
        bodyClasses: 'right-text guts-p-r-3-half',
      },
      {
        label: 'BusinessRules_DateCreated_Column',
        canSort: true,
        sortName: 'createdDate',
        type: 'date',
        dateFormat: 'MM/dd/yy',
        headClasses: 'l_w15',
        prop: 'createdDate',
      } as DataColumnDate,
      {
        label: 'Core_Actions',
        headClasses: 'l_w5 a11y-hide-text',
        template: this.actionLinkTemplate,
      },
    ];

    return this.dynamicGroupsEnabled
      ? dynamicGroupsColumnsDefinition
      : [
          {
            label: 'OrgGroupsCtrl_GroupName',
            headClasses: 'l_w70',
            template: this.groupNameTemplate,
          } as DataColumn,
          {
            label: 'OrgGroupsCtrl_GroupMembers',
            prop: 'userCount',
            headClasses: 'l_w10 right-text guts-p-r-5',
            bodyClasses: 'right-text guts-p-r-5',
          },
          {
            label: 'OrgGroupsCtrl_GroupVisibility',
            headClasses: 'l_w15',
            prop: 'privacyDisplayText',
          },
          {
            label: 'Core_Actions',
            headClasses: 'a11y-hide-text',
            template: this.actionLinkTemplate,
          },
        ];
  }

  private reset() {
    this.groups = [];
    this._skip = 0;
    this.pageNum = 1;
  }

  // TODO: Refactor when LD flag is retired
  public get take(): number {
    return this.dynamicGroupsEnabled ? this.numResultsPerPage : 20;
  }

  public get skip(): number {
    return this.dynamicGroupsEnabled
      ? (this.pageNum - 1) * this.numResultsPerPage
      : this._skip;
  }
}
