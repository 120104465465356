import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { PathwayLiteModel } from '@app/pathways/pathway.model';
import { PathwayLiteItemModel } from '@app/pathways/rsm/pathway-api.model';

@Component({
  selector: 'dgx-pathway-viewed-item-list',
  templateUrl: './pathway-viewed-item-list.component.html',
})
export class PathwayViewedItemListComponent implements OnInit {
  @Input() public pathwayViewedItems: PathwayLiteModel;

  public didYouCompleteTitle: string;
  public viewedItems: PathwayLiteItemModel[];

  public i18n = this.translate.instant([
    'Core_Done',
    'Pathways_ConfirmCompletions',
  ]);

  constructor(
    private translate: TranslateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    const viewedPathwaySteps = this.pathwayViewedItems.steps;
    this.viewedItems = viewedPathwaySteps.map((input) =>
      this.normalizeProps(input)
    );
    const pathwayName = `<a href="${this.pathwayViewedItems.url}" class="link" data-dgat="pathwayViewedItems-44d">${this.pathwayViewedItems.name}</a>`;

    this.didYouCompleteTitle = this.translate.instant(
      'Pathways_CompletionNotificationModalTitle',
      {
        pathwayName: pathwayName,
      }
    );
  }

  close() {
    this.activeModal.close();
  }

  private normalizeProps(input: PathwayLiteItemModel) {
    const normalizedInput = {
      ...input,
      resourceId: input.inputId,
      resourceType: input.inputType,
    };
    return normalizedInput;
  }
}
