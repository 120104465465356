import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Similar to the character limit on charCountedTextarea,
 * but allows for a different validator limit in the message
 * than on the actual field.
 *
 * Enables the creation of a textarea with a soft limit of
 * 2000 characters and a hard limit of 2400 characters, thus
 * **alerting** users when they exceed the limit so that they
 * can adust their entry.
 *
 * @param control
 * @param maxLength
 * @param translatedMessage
 */
export function isCharLimitedValidator(
  { value }: AbstractControl,
  maxLength: number,
  translatedMessage: string
): ValidationErrors {
  const length = (value ?? '').toString().length;
  return length > maxLength
    ? { maxLength: { message: translatedMessage } }
    : null;
}
