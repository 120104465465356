import { Inject, Injectable, NgZone } from '@angular/core';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import {
  BehaviorSubject,
  forkJoin,
  from,
  lastValueFrom,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { Router } from '@angular/router';

import manifestData from '@app/ms-teams/build/manifest/manifest-config.json';

import { AuthService } from '@app/shared/services/auth.service';
import { MSTeamsMsal2Token } from '@app/ms-teams/msteams-msal2.token';
import { MSTeamsMgtToken } from '@app/ms-teams/msteams-mgt.token';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { WindowToken } from '@app/shared/window.token';

import { Profile } from '@app/profile/profile.model';
import { MSTeamsService } from '@app/ms-teams/services/ms-teams/ms-teams.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { WebEnvironmentService } from '@dg/shared-services';
import { authentication } from '@microsoft/teams-js';

export const MS_TEAMS_ROUTE_PREFIX = '/degreed-ms-teams/';
export const MS_TEAMS_ROUTE_BASE = `${MS_TEAMS_ROUTE_PREFIX}app/`;
const MS_TEAMS_API_IS_VALID_TOKEN = `${MS_TEAMS_ROUTE_PREFIX}is-valid-token`;
const MS_TEAMS_API_SERVICE_HOST_DETAILS = `${MS_TEAMS_ROUTE_PREFIX}service-host-lookup`;
const MS_TEAMS_API_SET_ANTIFORGERY_COOKIE = `${MS_TEAMS_ROUTE_PREFIX}new-antiforgery-cookies`;
const MS_TEAMS_API_IS_REGISTERED_FOR_NOTIFICATION = `${MS_TEAMS_ROUTE_PREFIX}is-user-associated`;
const MS_TEAMS_API_LOGOUT = `${MS_TEAMS_ROUTE_PREFIX}sign-out`;
const MS_TEAMS_API_SIGN_IN = `${MS_TEAMS_ROUTE_PREFIX}signin`;
const MS_TEAMS_REGISTER_AUTHED_USER = `${MS_TEAMS_ROUTE_PREFIX}register-user`;
const APP_VERSION_KEY = 'DEGREED_MS_TEAMS_APP_VERSION';
const REMOTE_ORG_HOST_DETAILS = 'remoteOrgHostDetails';
const MS_TEAMS_AF_TOKEN_REFRESH = 'msTeamsAFCookieRefresh';

export enum AssociationStatus {
  Failed = 'Failed',
  Associated = 'Associated',
  LocaleUpdated = 'LocaleUpdated',
}

@Injectable({ providedIn: 'root' })
export class MSTeamsAuthService {
  constructor(
    private enviroService: WebEnvironmentService,
    private http: NgxHttpClient,
    private router: Router,
    private zone: NgZone,
    @Inject(AuthService) private authService: AuthService,
    @Inject(WindowToken) private windowRef: Window,
    @Inject(MSTeamsMsal2Token) private msTeamsMsal2,
    @Inject(MSTeamsMgtToken) private msTeamsMgt,
    private msTeamsService: MSTeamsService
  ) {}

  public appVersion: number = +(
    this.windowRef.localStorage.getItem(APP_VERSION_KEY) || 1
  );
  public idToken: string;
  private consentSuccess: boolean;
  private scopes: string[] = [
    // these scopes are pre consented in Azure
    'User.Read',
    'openId',
    'offline_access',
    'email',
    'profile',
  ];

  public $appId = new BehaviorSubject('');
  public $tokenAud = new BehaviorSubject('');
  public $token = new BehaviorSubject('');
  public $serviceHost = new BehaviorSubject('');
  public $ownerProfile = new BehaviorSubject<Profile>(null);
  public $registeredUser = new BehaviorSubject<boolean>(false);
  public remoteHostDetailsValid = false;

  public isValidRemoteHostDetailsSchema(): boolean {
    if (this.remoteHostDetailsValid) {
      return true;
    }

    const remoteOrgHostDetails: Record<string, string> = JSON.parse(localStorage.getItem(REMOTE_ORG_HOST_DETAILS) || '{}');
    // Makes sure is required properties are set for app to function properly
    return this.remoteHostDetailsValid = ['dataCenter', 'host'].every((key) => !!remoteOrgHostDetails[key]);
  }

  public msal2TeamsAuthInit() {
    const providerStateChanged = () => {
      if (
        this.msTeamsMgt.Providers.globalProvider.state ===
        this.msTeamsMgt.ProviderState.SignedIn
      ) {
        this.msTeamsMgt.Providers.globalProvider.setState(
          this.msTeamsMgt.ProviderState.SignedIn
        );
        this.msTeamsService
          .getLocale()
          .pipe(
            switchMap((locale) => {
              return from(
                this.msTeamsMgt.Providers.globalProvider.getAccessToken({
                  scopes: this.scopes,
                })
              ).pipe(
                tap((token: any) => {
                  this.$token.next(token);
                }),
                switchMap(() => {
                  if (this.authService.isLoggedIn) {
                    return this.getAuthUserAndProceedToRoute();
                  } else {
                    return this.msTeamsLogin(locale);
                  }
                })
              );
            })
          )
          .subscribe();
      }
    };

    this.msTeamsMgt.Providers.onProviderUpdated(providerStateChanged);
    this.setupMgtGlobalProvider();
  }

  public getRemoteHostDetails() {
    from(this.msTeamsService.initialize())
      .pipe(
        switchMap(() => from(this.msTeamsService.getAuthToken()))
      )
      .subscribe(this.remoteHostDetailsLookup);
  }

  private remoteHostDetailsLookup = (idToken) => {
    this.http
      .post(MS_TEAMS_API_SERVICE_HOST_DETAILS, {
        token: idToken,
        version: this.appVersion,
      })
      .pipe(
        switchMap((serviceHostResponse: any) => {
          if (!!serviceHostResponse?.host) {
            localStorage.setItem(
              REMOTE_ORG_HOST_DETAILS,
              JSON.stringify(serviceHostResponse)
            );
            return this.http
              .get(`${MS_TEAMS_API_SET_ANTIFORGERY_COOKIE}?cb=${Date.now()}`)
              .pipe(
                switchMap(() => {
                  return this.enviroService.initialize(true);
                })
              );
          } else {
            return of(null);
          }
        })
      )
      .subscribe(() => {
        const remoteOrgHostDetails = localStorage.getItem(
          REMOTE_ORG_HOST_DETAILS
        );
        if (!!remoteOrgHostDetails && JSON.parse(remoteOrgHostDetails).host) {
          this.msTeamsStartLogin(false);
        } else {
          const queryParams = '';
          this.proceedToRoute(queryParams, 'unauthorized');
        }
      });
  };

  public msTeamsStartLogin(consentSuccess: boolean) {
    this.consentSuccess = consentSuccess;
    if (this.authService.isLoggedIn) {
      // cancel MS Teams loading indicator to prevent timeout error on MS Teams
      this.msTeamsService.initializeAndNotifySuccess();
      this.proceedToRoute();
      return;
    }

    from(this.msTeamsService.initializeAndNotifySuccess())
      .pipe(
        switchMap(() => this.msTeamsService.getAuthToken())
      )
      .subscribe(this.checkIfUserHasAccount)
  }

  private getAppIdByTokenAud(tokenAud) {
    const { environment } = manifestData;

    // Find the environment object with the matching appId
    const selectedEnvironment = Object.values(environment).find(
      (env) => env.webAppId === tokenAud
    );

    return selectedEnvironment?.appId || tokenAud;
  }

  // Start with msTeamsStartLogin do not call this directly
  private msTeamsLogin(locale: string) {
    const signInData = {
      token: this.idToken,
      locale: locale,
      version: this.getAppVersion(),
    };

    return this.http
      .post(MS_TEAMS_API_SIGN_IN, signInData, {
        headers: {
          Authorization: `Bearer ${this.$token.value}`,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        switchMap(() => {
          return this.getAuthUserAndProceedToRoute();
        })
      );
  }

  public checkIfUserHasAccount = (idToken) => {
    this.idToken = idToken;
    this.http
      .post(
        MS_TEAMS_API_IS_VALID_TOKEN,
        { token: idToken, version: this.appVersion },
        { observe: 'response' }
      )
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          // if 400 error and no cookie refresh has been attempted,
          // remove remoteOrgHostDetails and reload the page to refresh anti-forgery token and try again
          if (
            error.status === 400 &&
            !localStorage.getItem(MS_TEAMS_AF_TOKEN_REFRESH)
          ) {
            localStorage.setItem(MS_TEAMS_AF_TOKEN_REFRESH, 'true');
            localStorage.removeItem(REMOTE_ORG_HOST_DETAILS);
            // reload app
            this.msTeamsService.getCurrentTab().subscribe((tab) => {
              this.windowRef.open(
                `/degreed-ms-teams/app/${tab}?v=${
                  this.appVersion
                }`,
                '_self'
              );
            });
          }
          return throwError(() => error);
        })
      )
      .subscribe((response: HttpResponse<Boolean>) => {
        localStorage.removeItem(MS_TEAMS_AF_TOKEN_REFRESH);
        if (response.body) {
          this.$appId.next(
            this.getAppIdByTokenAud(this.parseTokenAud(idToken))
          );
          if (this.consentSuccess) {
            // Continue to next step in login process if we received success for user consent but are still in the login flow
            this.msal2TeamsAuthInit();
          } else {
            this.proceedToRoute('?login=in_progress&is_msteams=true');
          }
        } else {
          const queryParams = '';
          this.proceedToRoute(queryParams, 'unauthorized');
        }
      });
  };

  private getAuthUserAndProceedToRoute(): Observable<HttpResponse<Profile>> {
    return this.authService.fetchAuthenticatedUser().pipe(
      tap((user) => {
        this.setOwnerProfile(user.body);
        this.proceedToRoute('?login=success&is_msteams=true');
      })
    );
  }

  public proceedToRoute(
    queryParams: string = '',
    teamsTabRouteOverride: string = ''
  ) {
    const queryParamSymbol = queryParams.indexOf('?') > -1 ? '&' : '?';
    queryParams = `${queryParams}${queryParamSymbol}v=${this.appVersion}`;
    this.msTeamsService.getCurrentTab()
      .subscribe((currentTab) => {
        const currentMSTeamsTab = teamsTabRouteOverride || currentTab;
        const destination = `${MS_TEAMS_ROUTE_BASE}${currentMSTeamsTab}${queryParams}`;
        this.zone.run(() => {
          this.router.navigateByUrl(destination);
        });
      });
  }

  public setTokens() {
    this.msTeamsService.getAuthToken().subscribe(this.handleSetTokenCallback);
  }

  public setAppId() {
    this.msTeamsService.getAuthToken().subscribe(this.setAppIdCallback);
  }

  private setAppIdCallback = (idToken) => {
    this.$appId.next(this.getAppIdByTokenAud(this.parseTokenAud(idToken)));
  };

  public handleSetTokenCallback = (idToken) => {
    this.idToken = idToken;
    this.$tokenAud.next(this.parseTokenAud(idToken));
    this.setupMgtGlobalProvider();
  };

  public setAppVersion(search: string) {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('v')) {
      this.windowRef.localStorage.setItem(
        APP_VERSION_KEY,
        searchParams.get('v')
      );
    }

    this.appVersion = +(
      searchParams.get('v') ||
      this.windowRef.localStorage.getItem(APP_VERSION_KEY) ||
      1
    );
  }

  public getAppVersion() {
    return this.windowRef.localStorage.getItem(APP_VERSION_KEY) || 1;
  }

  public logout() {
    this.$token.next('logging_out');
    this.http
      .post(MS_TEAMS_API_LOGOUT, {})
      .pipe(take(1))
      .subscribe(() => {
        this.authService.clearAuth();
        this.windowRef.location.reload();
      });
  }

  public async checkAndSilentlyRegisterTheUser() {
    // User is not logged in then we do not need to do anything we return the user
    // Let the popup auth take place.
    if (!this.authService.isLoggedIn) {
      return;
    }

    // User was already logged into Degreed app and isRegistered was set in the localstorage by default
    // No need to register the user in this case.
    const registeredUserFlag =
      this.windowRef.localStorage.getItem('isUserRegisteredToTeams') === 'true';
    if (this.authService.isLoggedIn && registeredUserFlag) {
      return;
    }

    // Check if the teams token is already associated in our app
    // If yes then do nothing and return
    if (await this.isUserAssociatedForNotifications()) {
      this.windowRef.localStorage.setItem(
        'isUserRegisteredToTeams',
        JSON.stringify(true)
      );
      return;
    }

    // Handle the association of the user with Degreed account.
    await lastValueFrom(
      this.associateTeamsUser(
        await lastValueFrom(this.msTeamsService.getAuthToken()),
        await lastValueFrom(this.msTeamsService.getLocale())
      )
    );
  }

  public isUserAssociatedForNotifications(): Promise<boolean> {
    return lastValueFrom(
      this.msTeamsService
        .getAuthToken()
        .pipe(
          switchMap((token) =>
            this.http.post<boolean>(
              MS_TEAMS_API_IS_REGISTERED_FOR_NOTIFICATION,
              { token }
            )
          )
        )
    );
  }

  public getAuthUserAndRegisterUser(): Observable<AssociationStatus> {
    return this.msTeamsService.getAuthToken().pipe(
      switchMap((token) =>
        this.msTeamsService
          .getLocale()
          .pipe(switchMap((locale) => this.associateTeamsUser(token, locale)))
      ),
      tap((status) => {
        if (status !== AssociationStatus.Failed) {
          authentication.notifySuccess();
        } else {
          authentication.notifyFailure();
        }
      })
    );
  }

  public associateTeamsUser(
    token = '',
    locale = ''
  ): Observable<AssociationStatus> {
    return this.http
      .post(
        MS_TEAMS_REGISTER_AUTHED_USER,
        { token, locale },
        { observe: 'response' }
      )
      .pipe(
        tap((response: HttpResponse<any>) => {
          if (response.status !== 201) {
            console.warn('User was not properly associated in the backend.');
          }
          this.$registeredUser.next(true);
        }),
        map((response: HttpResponse<any>) => {
          return response.status !== 201
            ? AssociationStatus.LocaleUpdated
            : AssociationStatus.Associated;
        }),
        catchError((error) => {
          return error.status === 401
            ? of(AssociationStatus.Failed)
            : throwError(error);
        })
      );
  }

  private setupMgtGlobalProvider() {
    const domain = this.windowRef.location.hostname;
    const taskSrc = `https://${domain}/api${MS_TEAMS_ROUTE_PREFIX}getGraphToken/${this.getAppVersion()}`;
    const authInteraction = `https://${domain}${MS_TEAMS_ROUTE_BASE}auth`;

    this.msTeamsMgt.Providers.globalProvider =
      new this.msTeamsMsal2.TeamsMsal2Provider({
        clientId: this.$tokenAud.getValue(),
        authPopupUrl: authInteraction,
        ssoUrl: taskSrc,
        httpMethod: this.msTeamsMsal2.HttpMethod.POST,
        scopes: this.scopes,
      });

    this.msTeamsMgt.Providers.globalProvider
      .getAccessToken({ scopes: this.scopes })
      .then((token) => {
        this.$token.next(token);
      });
  }

  public getUserDetails(): Observable<any> {
    return this.msTeamsService.getAuthToken().pipe(
      switchMap((token) => {
        const claims = this.getTokenClaims(token);
        const uId$ = of(claims?.oid);
        const tId$ = of(claims?.tid);

        return forkJoin({ uId: uId$, tId: tId$ });
      }),
      map(({ uId, tId }) => ({
        userId: uId,
        tenantId: tId,
      }))
    );
  }

  private getTokenClaims(idToken) {
    return JSON.parse(
      atob(idToken.split('.')[1]) // base64 decode and get idToken claims
    );
  }

  private parseTokenAud(idToken) {
    const tokenAud = this.getTokenClaims(idToken).aud; // intended audience
    this.$tokenAud.next(tokenAud);
    return tokenAud;
  }

  private setOwnerProfile(user) {
    this.$ownerProfile.next({
      id: user?.viewerProfile?.userProfileKey,
      bio: user?.viewerProfile?.bio,
      isEngaged: user?.isEngaged,
      jobRole: user?.viewerProfile?.jobRole,
      location: user?.viewerProfile?.location,
      name: user?.viewerProfile?.name,
      pic: user?.viewerProfile?.picture,
      vanityUrl: user?.viewerProfile?.vanityUrl,
    } as Profile);
  }

}
