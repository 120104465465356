import { SimpleItemViewModel } from '@app/shared/models/core-view.model';
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TargetType } from '@app/shared/models/core-api.model';
import { Target } from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';
import { TrackerService } from '@app/shared/services/tracker.service';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { SharedTargetService } from '@app/target/services/shared-target.service';

@Injectable({
  providedIn: 'root',
})
export class OrgPlansService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private tracker: TrackerService,
    private sharedTargetService: SharedTargetService
  ) {}

  // Previously getLabeledTargetTypes
  /**
   * getLabeledPlanTypes
   * @returns array of types and translated meta data
   */
  public getLabeledPlanTypes(): SimpleItemViewModel[] {
    return ['Browse', 'Directory', 'Role', 'Target'].map((t) => ({
      id: t,
      title: this.sharedTargetService.getTypeDisplayName(
        t as TargetType,
        false,
        true
      ),
      model: {
        id: t,
        title: this.sharedTargetService.getTypeDisplayName(
          t as TargetType,
          false,
          true
        ),
        filter: false,
      },
    }));
  }

  /**
   * bulkDeletePlans
   * @param ItemIds
   * @returns response from http post
   * Deletes multiple pans and notifies on failure
   */
  public bulkDeletePlans(ItemIds: Target['targetId'][]): Observable<any> {
    return this.http
      .post('/targets/bulkDelete', {
        ItemIds,
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_DeleteError', {
                targetType: this.sharedTargetService
                  .getTypeDisplayName('Directory', true)
                  .toLowerCase(),
              }),
              error
            )
          )
        )
      );
  }

  // Previously bulkCollaboratorAdd
  /**
   * bulkAddCollaborators
   * @param Object containing array of itemIds and user keys to add
   * @returns
   */
  public bulkAddCollaborators({ itemIds, toAddUserKeys }): Observable<any> {
    return this.http
      .put('/targets/bulkCollaboratorAdd', {
        itemIds,
        toAddUserKeys,
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_UpdateAuthorsError', {
                targetType: this.sharedTargetService
                  .getTypeDisplayName('Directory', true)
                  .toLowerCase(),
              }),
              error
            )
          )
        )
      );
  }

  /**
   * bulkVisibilityChange
   * @param itemIds
   * @param visibility
   * @param groupIdentifiers Groups to give visibility to, if any
   * @returns
   */
  public bulkVisibilityChange(
    itemIds: number[],
    visibility: Visibility,
    groupIdentifiers
  ): Observable<any> {
    return this.http
      .put('/targets/bulkVisibilityChange', {
        itemIds,
        visibility,
        groupIdentifiers,
      })
      .pipe(
        tap(() => {
          itemIds.map((id) => {
            this.tracker.trackEventData({
              action: 'Page Updated',
              category: visibility.toString(),
            });
          });
        }),
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_DetailsError'),
              error
            )
          )
        )
      );
  }
}
