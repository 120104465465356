import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@app/shared/services/auth.service';
import {
  InputCreationFeedback,
  UserInputCreationFeedback,
} from '@app/inputs/inputs-api.model';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { forkJoin, Subject, timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { Merge } from '@app/shared/utils/type';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Displays the animated results of a Global Add UserInput add/edit attempt, including stats, points
 * and rewards where applicable.
 */
@Component({
  selector: 'dgx-global-add-results',
  templateUrl: './global-add-results.component.html',
  styleUrls: ['./global-add-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAddResultsComponent implements OnChanges {
  private static readonly cssAnimationDuration = 1200;

  private successAnimating = new Subject<void>();
  private submissionComplete = new Subject<void>();

  @Input() public creationFeedback: Merge<
    UserInputCreationFeedback,
    InputCreationFeedback
  >;

  @Input() public buttonText: string;
  @Input() public isCompleting = false;
  @Input() public isEditing = false;
  @Input() public isNewbUser = false;
  @Input() public submissionStatus = SubmissionStatus.Failed;
  @Input() public classifier: any;
  @Input() public showCancelButton = false;
  @Input() public cancelButtonText? = this.translate.instant('Core_Cancel');
  @Input() public addSpacing? = true;
  /** Fires when the user or timer triggers dismissal. If the user is navigating
   * the target url is provided as the event arg and the client component must perform the actual navigation */
  @Output() public dismissWithNavigation = new EventEmitter<string>();
  public collectionUrl: string;
  public shouldShowReward: boolean;
  public errorMessage: string;
  public todaysCountsOrdered: any;
  public isSuccessAnimationComplete = false;
  public shouldShowResults = false;
  public checkImage = this.webEnvironmentService.getBlobUrl(
    `/content/img/checkmark.svg`,
    true
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private authService: AuthService,
    private webEnvironmentService: WebEnvironmentService,
    private activeModal: NgbActiveModal
  ) {
    this.collectionUrl = `/profile/${this.authService.authUser.viewerProfile.vanityUrl}/collection`;
    this.initSequence();
  }

  public get isAdding() {
    return !this.isEditing;
  }

  public get isSubmitting() {
    return (
      this.submissionStatus === SubmissionStatus.Pending ||
      this.submissionStatus === SubmissionStatus.Submitting
    );
  }

  private get shouldAnimate() {
    return this.isAdding && this.isCompleting && !this.isNewbUser;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.submissionStatus) {
      if (
        !this.isSuccessAnimationComplete &&
        this.isSubmitting &&
        this.shouldAnimate
      ) {
        this.successAnimating.next(); // kick off animation
        this.successAnimating.complete();
      } else if (this.submissionStatus === SubmissionStatus.Succeeded) {
        // If successfully submitted, check creationFeedback has been set too
        if (this.isAdding && this.isCompleting) {
          // Only need to fire submissionComplete when adding/completing to finish displaying points/stats/rewards
          this.submissionComplete.next();
          this.submissionComplete.complete();
        } else if (!this.isNewbUser) {
          // dismiss immediately upon sucessful submission if not completing a new input as a new user
          this.dismissWithNavigation.emit();
        }
      } else if (this.submissionStatus === SubmissionStatus.Failed) {
        this.submissionComplete.error(
          this.translate.instant('dgGlobalAddSuccess_ErrorWhenAdding')
        );
      }
    }
    this.collectionUrl = this.classifier
      ? `/profile/${this.authService.authUser.viewerProfile.vanityUrl}/collection?classifier=${this.classifier}`
      : `/profile/${this.authService.authUser.viewerProfile.vanityUrl}/collection`;
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

  public onCollectionLinkClick(event: Event) {
    event.preventDefault();
    // Don't allow the link to navigate directly. Instead, pass it to the containing (modal) component to
    // handle any cleanup first
    this.dismissWithNavigation.emit((event.target as HTMLAnchorElement).href);
  }

  private initSequence() {
    const showResults = forkJoin([
      this.submissionComplete,
      this.successAnimating,
    ]);

    this.successAnimating
      .pipe(delay(GlobalAddResultsComponent.cssAnimationDuration))
      .subscribe(() => {
        this.isSuccessAnimationComplete = true;
        this.cdr.markForCheck();
      });

    showResults.subscribe(
      () => {
        this.shouldShowResults = true;
        // set auto-close timeout depending on number of discrete things for user to read first
        if (this.isCompleting && this.isAdding) {
          let timeout = 3000; // default timeout for simplest case
          if (this.creationFeedback) {
            // Once we have successfully submitted AND the submit animation is done AND we received a creation feedback
            // (only available for inputs), we can show results and start the auto-close timeout
            this.todaysCountsOrdered =
              this.creationFeedback.statistics.todaysCounts.sort((a, b) =>
                a.inputType.localeCompare(b.inputType)
              );
            if (this.creationFeedback.statistics) {
              timeout += 3000;
            }
            if (this.creationFeedback.reward) {
              timer(timeout).subscribe(() => {
                this.shouldShowReward = true;
                this.shouldShowResults = false;
                this.cdr.markForCheck();
              });
              timeout += 4000;
            }
          }
          // dismiss with a navigation target url after the timeout
          timer(timeout).subscribe(() => this.dismissWithNavigation.emit());
        } else {
          // dismiss immediately following success animation if not completing a new input
          this.dismissWithNavigation.emit();
        }
      },
      (error) => {
        if (this.isCompleting && this.isAdding) {
          // error already taps into notifier (toaster)
          // so, this only appears if adding, not changing.
          this.errorMessage = error;
        }
      }
    );
  }
}
