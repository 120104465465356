import { InternalTagRatingTypes } from '@app/tags/tags';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { getTagCompletedRatings } from '@app/shared/utils/tag-helpers';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'dgx-tag-rating-signal',
  templateUrl: './tag-rating-signal.component.html',
  styleUrls: ['./tag-rating-signal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingSignalComponent implements OnChanges {
  @Input() public tag: TagsApi.Tag;
  @Input() public showVisibility: boolean;
  @Input() public displayHorizontal = false;
  @Input() public popoverViewedDelay = 1000;

  // Allow for tracking of popover views
  @Output() public popoverViewed = new EventEmitter();

  @ViewChild('popoverTrigger') public popoverTriggerRef: ElementRef;

  public showRatings: boolean = false;
  public completedRatings: TagsApi.UserTagRating[];
  public moreRatingsCount: number;
  public hasInternalRating: boolean;
  public hasMoreRatings: boolean;
  public showPrivacyIcon: boolean;
  public i18n: { [key: string]: string };
  private popoverViewedTimeout;

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public ngOnChanges({ tag }: SimpleChanges): void {
    if (tag?.currentValue) {
      // TODO: remove camelCaseKeys after api calls start using ngxHttpClient
      this.tag = camelCaseKeys(tag.currentValue);
      this.setState(this.tag);
    }
  }

  public ngOnInit(): void {
    this.i18n = this.translate.instant([
      'dgTagRating_PrivateToYou',
      'Core_Private',
      'Core_LimitedVisibility',
    ]);
  }

  public getPrivacyLabel() {
    return !this.auth.authUser.participateInTeamProgram
      ? this.i18n.Core_Private
      : this.i18n.Core_LimitedVisibility;
  }

  public togglePopover(showRatings: boolean): void {
    this.showRatings = showRatings && this.hasMoreRatings;
    if (this.showRatings) {
      // Delay notification of `popoverViewed` so random mousenter events aren't tracked
      this.popoverViewedTimeout = this.windowRef.setTimeout(
        () => this.popoverViewed.emit(),
        this.popoverViewedDelay
      );
    } else {
      this.windowRef.clearTimeout(this.popoverViewedTimeout);
    }
  }

  private setState(tag: TagsApi.Tag): void {
    this.completedRatings = getTagCompletedRatings(tag);
    this.moreRatingsCount = this.getTagMoreRatingsCount(
      tag,
      this.completedRatings
    );
    this.hasInternalRating = tag.rating.isInternal;
    this.hasMoreRatings = this.moreRatingsCount > 0;
    this.showPrivacyIcon =
      tag.rating.dateCompleted &&
      tag.rating.privacyId === 0 &&
      this.showVisibility &&
      tag.rating.isInternal;
  }

  private getTagMoreRatingsCount(
    tag: TagsApi.Tag,
    completedRatings: TagsApi.UserTagRating[]
  ): number {
    if (!completedRatings?.length) {
      return 0;
    }

    let totalCompletedRatings = completedRatings.length;
    // get total checkpoint ratings
    const totalCheckpointRatings = completedRatings.filter(
      (rating) => rating.type === InternalTagRatingTypes.checkpoint
    ).length;
    // If there is more than 1, remove all from count and only add 1 as they will be displayed all together
    totalCompletedRatings =
      totalCheckpointRatings > 0
        ? totalCompletedRatings - totalCheckpointRatings + 1
        : totalCompletedRatings;
    const totalWithoutPeer = completedRatings.filter(
      (rating) => rating.type !== InternalTagRatingTypes.peer
    ).length;
    const count =
      totalCompletedRatings > totalWithoutPeer
        ? totalWithoutPeer + 1
        : totalCompletedRatings;

    // Exclude most rigorous internal rating from `more ratings` count (if completed)
    return tag.rating.isInternal && tag.rating.dateCompleted
      ? count - 1
      : count;
  }
}
