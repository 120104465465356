import { Component, Input, OnInit } from '@angular/core';
import { KPI } from '@app/insights/insights-api.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { shortenBigNumber } from '@app/shared/utils/common-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-kpi-item',
  templateUrl: './kpi-item.component.html',
  styleUrls: ['./kpi-item.component.scss'],
})
export class KpiItemComponent implements OnInit {
  @Input() public kpi;
  @Input() public kpiIndex;
  public showKPITrend;
  public i18n = this.translate.instant([
    'KPI_Increase',
    'KPI_Decrease',
    'KPI_NoChange',
  ]);
  constructor(
    private translate: TranslateService,
    private tracker: TrackerService
  ) {}

  public ngOnInit(): void {}

  public ngOnChanges(): void {
    // TODO: add kpi trend logic
    this.showKPITrend = this.kpi.trend !== undefined && !isNaN(this.kpi.trend);
    if (this.showKPITrend) {
      this.kpi.trendDisplay = shortenBigNumber(
        Math.abs(this.kpi.trend || 0),
        1
      );
      if (this.kpi.numOfDays < 365) {
        this.kpi.trendOverTime = this.translate.instant(
          'KPI_OverTheLastXDays',
          {
            number: this.kpi.numOfDays,
          }
        );
      } else if (this.kpi.numOfDays === 365) {
        this.kpi.trendOverTime = this.translate.instant('KPI_OverTheLastYear');
      } else {
        this.kpi.trendOverTime = this.translate.instant(
          'KPI_OverTheLastXYears',
          {
            number: (this.kpi.numOfDays / 365).toFixed(1),
          }
        );
      }
    }
  }

  public trackHover(kpi: KPI) {
    this.tracker.trackEventData({
      action: 'Insights KPI Hovered',
      properties: { Name: kpi.name, Location: kpi.location },
    });
  }
}
