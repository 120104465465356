import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MonoTypeOperatorFunction, lastValueFrom } from 'rxjs';

// types
import { GroupItem } from '@app/groups/group-api';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import {
  AddModalResults,
  Pathway,
  PathwayBinItem,
  PathwayDetailsModel,
  PathwayPermissionsModel,
  PathwaySection,
  PathwayStep,
  PathwaySubsection,
  PathwayWithUserPermissions,
} from '@app/pathways/rsm/pathway-api.model';
import {
  ActionType,
  AddContentNodes,
  GetPathwayWithPermissionsParams,
  LogPathwayViewParams,
  PathwayLevel,
  PathwayMoveNodeInput,
  PathwayNode,
  PathwayUrlQueryParams,
} from '@app/pathways/rsm/pathway.model';
import { ReorderItem } from '@app/reorder-modal/reorder-modal.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';

// misc
import { DgError } from '@app/shared/models/dg-error';

// services
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { DocumentTitleService } from '@app/shared/services/document-title/document-title.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { pascalCaseKeys } from '@app/shared/utils/property';
import { TranslateService } from '@ngx-translate/core';

// utils
import {
  convertServerPrivacy,
  createReorderedNodeMap,
  preProcessPathway,
  preProcessSection,
  preProcessSubsection,
  reorderPathwaySections,
  toSectionIndex,
  updatePathwayAfterNodeMove,
  updatePathwaySection,
} from './utils';

export type LoginFullPathwayResponse = PathwayWithUserPermissions & {
  exclusionList: InputIdentifier[];
};

/**
 * The Pathway Facade has special functionality that calls has a non-trivial server
 * call to load a full Pathway data model and pre-process. It also uses the
 * TrackerService, DocumentTitle, etc.
 *
 * This class hides the details of those async activities and simplifies
 * the Facade itself.
 */
@Injectable({ providedIn: 'root' })
export class PathwayDataAPI {
  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient,
    private tracker: TrackerService,
    private docTitle: DocumentTitleService,
    private router: Router
  ) {}

  // *******************************************************
  // Public Data service Access methods for PathwayFacade
  // *******************************************************

  // *******************************************************
  // Pathway API
  // *******************************************************

  /**
   * Adds to bin (Hold For Later)
   * @param pathId
   * @param contentItems
   * @returns Promise<void>
   */
  public async addToBin(
    pathId: number,
    contentItems: LearningResourceViewModel[]
  ): Promise<PathwayBinItem[]> {
    try {
      const itemsMappedForApi = contentItems.map((input) => ({
        InputId: input.resourceId,
        InputType: input.resourceType,
        UseResourceImages: true,
      }));
      const request$ = this.http.post<PathwayBinItem[]>(
        '/pathways/addpathwaybinitems',
        {
          pathId,
          inputs: itemsMappedForApi,
        }
      );
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(
        this.translate.instant('Pathways_HoldForLaterError'),
        e
      );
    }
  }

  /**
   * Checks if url includes orgsso and tltag, if true enroll into pathway
   * and use tltag to "click" and track users event with enrollByTlTag()
   */
  async autoEnrollForSSO(
    pathway: Pathway,
    urlParams?: PathwayUrlQueryParams
  ): Promise<boolean> {
    // if user already is enrolled, we don't need to reroll again.
    if (pathway.isEnrolled) {
      return false;
    }
    if (urlParams) {
      const { orgsso, tltag } = urlParams;
      if (!!(orgsso && tltag)) {
        const request$ = this.http.post<boolean>('/pathways/enrollbytltag', {
          pathId: pathway.id,
          tlTag: tltag,
        });
        const success = await lastValueFrom(request$);
        return success;
      }
    }
    return false;
  }

  /**
   * Deletes a pathway.
   *
   * @param pathId
   */
  public async deletePathway(pathId: number) {
    try {
      const request$ = this.http.delete<void>('/pathways/deletepathway', {
        params: { pathId },
      });
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_DeleteError'), e);
    }
  }

  /**
   * Enrolls the user in the Pathway
   * @param pathway the full pathway object
   * @param isAutoEnroll determines if the user is implicitly enrolling
   * @param trackableLinkTag if the user follows the pathway by clicking a trackable link, pass the tracking info to the server
   */
  public async enroll(
    pathway: PathwayDetailsModel,
    isAutoEnroll: boolean = false,
    trackableLinkTag: string = ''
  ): Promise<void> {
    try {
      const request$ = this.http.post<void>('/pathways/enroll', {
        pathId: pathway.id || pathway.resourceId,
        explicitEnrollment: !isAutoEnroll,
        trackableLinkTag,
      });
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_FollowError'), e);
    }
  }

  /**
   * Get the bin items for the authored pathway
   * @param pathId
   * @returns PathwayBinItem[] this is different from the LearningResourceViewModel that the search and add by type are.
   */
  public async getPathAuthoringBin(pathId: number): Promise<PathwayBinItem[]> {
    try {
      const request$ = this.http.get<PathwayBinItem[]>(
        '/pathways/getpathwayauthoringbin',
        {
          params: { pathId: pathId },
        }
      );
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(e);
    }
  }
  /**
   * Get the pathway permissions.
   *
   * @param pathId
   */
  public async getPathwayPermissions(
    pathId: number
  ): Promise<PathwayPermissionsModel> {
    try {
      const request$ = this.http.get<PathwayPermissionsModel>(
        '/pathways/getPathwayPermissions',
        {
          params: { pathId },
        }
      );
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Core_GeneralErrorMessage'), e);
    }
  }

  public async getVersion(id: number) {
    const request$ = this.http.get<string>('/pathways/getPathwayVersion', {
      params: { pathId: id },
    });
    return await lastValueFrom(request$);
  }

  /**
   * Load full pathway with permissions
   * @param params
   * @returns
   */
  public async loadFullPathway(
    params: GetPathwayWithPermissionsParams,
    trackStatus: MonoTypeOperatorFunction<any>
  ): Promise<LoginFullPathwayResponse> {
    try {
      const onError = this.reportMissingStep.bind(this);
      params = { ...params, useResourceImages: true };

      const request$ = this.http
        .get<PathwayWithUserPermissions>(
          '/pathways/getpathwaywithuserpermissions',
          {
            params,
          }
        )
        .pipe(trackStatus);

      const { pathway, userPermissions } = await lastValueFrom(request$);

      return { ...preProcessPathway(pathway, onError), userPermissions };
    } catch (e) {
      // We do NOT want to surface these errors to the user by toasting them,
      // nor can we throw errors either before or after navigating to our error
      // pages (before would prevent the redirect, after would never fire), so
      // we simply log the error and continue.
      console.error(e);

      // NOTE: If a user does not have access this api throws a 403. In all other
      // scenarios, we should display a 404 page instead.
      this.router.navigateByUrl(
        `/error-handler/${e.status === 403 ? e.status : 404}`
      );
    }
  }

  /**
   * remove to bin (Hold For Later)
   * @param pathId
   * @param contentItems
   * @returns Promise<void>
   */
  public async removeFromBin(
    pathId: number,
    item: PathwayBinItem
  ): Promise<void> {
    try {
      const request$ = this.http.post('/pathways/removeinputfrompathway', {
        PathId: pathId,
        LevelNumber: '',
        LessonNumber: '',
        StepNumber: '',
        Item: item,
      });
      await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Core_GeneralErrorMessage'), e);
    }
  }

  public async setPathwayVisibility(
    pathId: number,
    visibility: Visibility,
    groups: GroupItem[]
  ): Promise<PathwayPermissionsModel> {
    try {
      const request$ = this.http.post<PathwayPermissionsModel>(
        '/pathways/setpathwayvisibility',
        {
          pathId,
          visibility,
          groupIds: pascalCaseKeys(groups),
        }
      );
      return await lastValueFrom(request$);
    } catch (e) {
      const messageKey =
        e.status === 409
          ? 'Pathways_SetPathwayNameError'
          : 'Pathways_UpdateVisibilityError';
      throw new DgError(this.translate.instant(messageKey), e);
    }
  }

  /**
   * Pathway viewed tracking for reports
   */
  public async trackReportsPathwayViewed(
    params: LogPathwayViewParams
  ): Promise<void> {
    return await lastValueFrom(
      this.http.get<void>('/pathways/LogPathwayView', { params })
    );
  }

  /**
   * Unenrolls the user to the pathway
   * @param pathway the full pathway object
   */
  public async unenroll(pathway: PathwayDetailsModel): Promise<void> {
    try {
      const request$ = this.http.post<void>('/pathways/unenroll', {
        pathId: pathway.id || pathway.resourceId,
      });
      return await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_UnfollowError'), e);
    }
  }

  public async updateDocumentTitle(pathway: Pathway) {
    if (!!pathway) {
      // Set the document title
      this.docTitle.prependTitle(pathway.title);
    }
    return true;
  }

  // *******************************************************
  // Section API
  // *******************************************************

  /**
   * Adds section to a pathway
   * Note: 1 empty subsection is automatically added by the BE
   *
   * @param pathway - Pathway to add subsection to.
   */
  public async addSection(pathway: Pathway): Promise<PathwaySection> {
    try {
      const request$ = this.http.post<PathwaySection>(
        '/pathways/addpathwaysection',
        { pathId: pathway.id }
      );
      const section = await lastValueFrom(request$);
      return preProcessSection(pathway, section);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_AddSectionError'), e);
    }
  }

  /**
   * Adds content to a section while also creating it. Only used for the first section on a pathway.
   * Notes:
   *  - Alias "Lesson"
   *  - This call populates the subsection as it adds it.
   *
   * @param pathId - Pathway ID.
   * @param contentToSave - The return value of the Add Content modal. This may be content items or an array of node strings, if the content is from the bin.
   * @param nodes - The nodes to insert our content between.
   */
  public async addSectionAndPopulate({
    contentToSave,
    nodes,
    pathway,
  }: {
    contentToSave: AddModalResults;
    nodes: AddContentNodes;
    pathway: Pathway;
  }): Promise<PathwayDetailsModel> {
    try {
      const { isBin, savedItems, selectedNodes } = contentToSave;
      const { beforeNode, afterNode } = nodes;

      // Base object.
      const apiInput: any = {
        node: {
          pathId: pathway.id,
        },
        content: {
          afterNode,
          beforeNode,
          pathId: pathway.id,
        },
        useResourceImages: true,
      };

      // Check if we're adding content from the bin. If yes, we use one URI,
      // and pass the BE an array of node strings that it can use to match
      // our existing content items to this new node. If no, a different URI,
      // and an array of resourceId/Type values to create new content items.
      let uri = '';
      if (isBin) {
        apiInput.content.nodes = selectedNodes;
        uri = '/pathways/AddPathwaySectionAndPopulateFromBin';
      }
      // Otherwise, we have to create content items on the BE.
      else {
        uri = '/pathways/AddPathwaySectionAndPopulate';
        apiInput.content.inputs = savedItems.map((input) => {
          const { resourceId, resourceType } = input as any;
          const localOverride = input.overrideScrapedData
            ? {
                updatedTitle: input.title ?? undefined,
                updatedDescription: input.summary ?? undefined,
              }
            : {};
          return {
            inputId: resourceId,
            inputType: resourceType,
            ...localOverride,
          };
        });
      }

      const request$ = this.http.post<PathwayDetailsModel>(uri, apiInput);

      const updatedPathway = await lastValueFrom(request$);

      // Ensure our new section is pre-processed for this call, too.
      updatedPathway.levels[0] = preProcessSection(
        pathway,
        updatedPathway.levels[0]
      );

      // NOTE: The updated pathway coming back from the server returns the privacy level as a string,
      // convert server privacy level to enum
      updatedPathway.privacyLevel = convertServerPrivacy(
        updatedPathway.privacyLevel as any
      );

      return updatedPathway;
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_AddItemError'), e);
    }
  }

  /**
   * Reorders pathway sections and returns an updated pathway
   * @param pathway
   * @param reorderedItems
   * @returns Promise<PathwayDetailsModel>
   */
  public async reorderSections(
    pathway: PathwayDetailsModel,
    reorderedItems: ReorderItem[]
  ): Promise<PathwayDetailsModel> {
    try {
      const nodeChanges = createReorderedNodeMap(
        reorderedItems,
        PathwayLevel.SECTION
      );
      const request$ = this.http.put<void>('/pathways/arrangepathwaynodes', {
        PathwayId: pathway.id,
        NodeChanges: nodeChanges,
      });

      await lastValueFrom(request$);
      return reorderPathwaySections(pathway, reorderedItems);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_MoveSectionError'), e);
    }
  }

  /**
   * Reorders nodes in a section (subsections and steps)
   * @param pathway
   * @param reorderedItems
   * @param type
   * @returns Promise<PathwayDetailsModel>
   */
  public async reorderSectionNodes(
    pathway: PathwayDetailsModel,
    reorderedItems: ReorderItem[],
    type: ActionType
  ): Promise<PathwayDetailsModel> {
    try {
      const nodeChanges = createReorderedNodeMap(reorderedItems, type);
      const request$ = this.http.put<PathwaySection[]>(
        '/pathways/reorderpathwaynodes',
        {
          PathwayId: pathway.id,
          NodeChanges: nodeChanges,
        }
      );
      const section = await lastValueFrom(request$);
      return updatePathwaySection(pathway, section[0]);
    } catch (e) {
      throw new DgError(
        this.translate.instant(
          `Pathways_Move${this.capitalizeType(type)}Error`
        ),
        e
      );
    }
  }

  // *******************************************************
  // Subsection API
  // *******************************************************

  /**
   * Adds content to pathway
   * @param pathway - Pathway.
   * @param contentToSave - The return value of the Add Content modal. This may be content items or an array of node strings, if the content is from the bin.
   * @param nodes - The nodes to insert our content between.
   */
  public async addContent({
    pathway,
    contentToSave,
    nodes,
  }: {
    pathway: PathwayDetailsModel;
    contentToSave: AddModalResults;
    nodes: AddContentNodes;
  }): Promise<PathwayDetailsModel> {
    try {
      const { isBin, savedItems, selectedNodes } = contentToSave;
      const { beforeNode, afterNode } = nodes;

      // Base object.
      const apiInput: any = {
        afterNode,
        beforeNode,
        pathId: pathway.id,
        useResourceImages: true,
      };

      // Check if we're adding content from the bin. If yes, we use one URI,
      // and pass the BE an array of node strings that it can use to match
      // our existing content items to this new node. If no, a different URI,
      // and an array of resourceId/Type values to create new content items.
      let uri = '';
      if (isBin) {
        apiInput.nodes = selectedNodes;
        uri = '/pathways/addpathwayinputsfrombin';
      }
      // Otherwise, we have to create content items on the BE.
      else {
        uri = '/pathways/addpathwayinputs';
        apiInput.inputs = savedItems.map((input) => {
          const { resourceId, resourceType } = input as any;
          const localOverride = input.overrideScrapedData
            ? {
                updatedTitle: input.title ?? undefined,
                updatedDescription: input.summary ?? undefined,
              }
            : {};
          return {
            inputId: resourceId,
            inputType: resourceType,
            ...localOverride,
          };
        });
      }

      const request$ = this.http.post<PathwayDetailsModel>(uri, apiInput);

      const updatedPathway: PathwayDetailsModel = await lastValueFrom(request$);
      // NOTE: The updated pathway coming back from the server returns the privacy level as a string,
      // convert server privacy level to enum
      updatedPathway.privacyLevel = convertServerPrivacy(
        updatedPathway.privacyLevel as any
      );

      return updatedPathway;
    } catch (e) {
      const messageKey =
        e.status === 409
          ? 'Pathways_SetPathwayNameError'
          : 'Pathways_AddItemError';
      throw new DgError(this.translate.instant(messageKey), e);
    }
  }

  /**
   * Adds subsection to a pathway
   * Notes:
   *  - Alias "Lesson"
   *  - 1 empty step is automatically added by the BE
   *
   * @param pathway - Pathway to add subsection to.
   * @param sectionNode - Node of the parent section.
   */
  public async addSubsection(
    pathway: Pathway,
    sectionNode: string
  ): Promise<PathwaySubsection> {
    try {
      const request$ = this.http.post<PathwaySubsection>(
        '/pathways/addpathwaylesson',
        { pathId: pathway.id, parentNode: sectionNode }
      );
      const subsection = await lastValueFrom(request$);
      return preProcessSubsection(pathway, subsection);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_AddLessonError'), e);
    }
  }

  /**
   * Adds content to a subsection while also creating it. Only used for the first subsection on a section.
   * Notes:
   *  - Alias "Lesson"
   *  - This call populates the subsection as it adds it.
   *
   * @param contentToSave - The return value of the Add Content modal. This may be content items or an array of node strings, if the content is from the bin.
   * @param nodes - The nodes to insert our content between.
   * @param parentNode - Node of the parent section
   * @param pathway - Pathway.
   */
  public async addSubsectionAndPopulate({
    contentToSave,
    nodes,
    parentNode,
    pathway,
  }: {
    contentToSave: AddModalResults;
    nodes: AddContentNodes;
    parentNode: string;
    pathway: Pathway;
  }): Promise<PathwayDetailsModel> {
    try {
      const { isBin, savedItems, selectedNodes } = contentToSave;
      const { beforeNode, afterNode } = nodes;

      // Base object.
      const apiInput: any = {
        node: {
          parentNode,
          pathId: pathway.id,
        },
        content: {
          afterNode,
          beforeNode,
          pathId: pathway.id,
        },
        useResourceImages: true,
      };

      // Check if we're adding content from the bin. If yes, we use one URI,
      // and pass the BE an array of node strings that it can use to match
      // our existing content items to this new node. If no, a different URI,
      // and an array of resourceId/Type values to create new content items.
      let uri = '';
      if (isBin) {
        apiInput.content.nodes = selectedNodes;
        uri = '/pathways/AddPathwayLessonAndPopulateFromBin';
      }
      // Otherwise, we have to create content items on the BE.
      else {
        uri = '/pathways/AddPathwayLessonAndPopulate';
        apiInput.content.inputs = savedItems.map((input) => {
          const { resourceId, resourceType } = input as any;
          const localOverride = input.overrideScrapedData
            ? {
                updatedTitle: input.title ?? undefined,
                updatedDescription: input.summary ?? undefined,
              }
            : {};
          return {
            inputId: resourceId,
            inputType: resourceType,
            ...localOverride,
          };
        });
      }

      const request$ = this.http.post<PathwayDetailsModel>(uri, apiInput);

      const updatedPathway = await lastValueFrom(request$);

      // Ensure our new subsection is pre-processed for this call, too.
      const updatedSectionIndex = toSectionIndex(pathway.levels, parentNode);
      updatedPathway.levels[updatedSectionIndex].lessons[0] =
        preProcessSubsection(
          pathway,
          updatedPathway.levels[updatedSectionIndex].lessons[0]
        );

      // NOTE: The updated pathway coming back from the server returns the privacy level as a string,
      // convert server privacy level to enum
      updatedPathway.privacyLevel = convertServerPrivacy(
        updatedPathway.privacyLevel as any
      );

      return updatedPathway;
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_AddItemError'), e);
    }
  }

  // *******************************************************
  // Step API
  // *******************************************************

  public async togglePathwayNodeRequired(
    pathId: number,
    node: string,
    isRequired: boolean
  ) {
    try {
      const request$ = this.http.post<void>(
        '/pathways/updatepathwaynoderequired',
        {
          pathId,
          node,
          isRequired,
        }
      );

      await lastValueFrom(request$);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_SaveError'), e);
    }
  }

  // *******************************************************
  // Common (section/subsection/step) API
  // *******************************************************

  /**
   * Moves pathway node
   * @param pathway
   * @param movedNodes
   * @param type
   * @returns Promise<PathwayDetailsModel>
   */
  public async movePathwayNode(
    pathway: PathwayDetailsModel,
    movedNodes: PathwayMoveNodeInput,
    type: ActionType
  ): Promise<PathwayDetailsModel> {
    try {
      const request$ = this.http.put<{
        destSection: PathwaySection[];
        srcSection: PathwaySection[];
      }>('/pathways/movepathwaynodetonewposition', {
        PathwayId: movedNodes.pathwayId,
        Node: movedNodes.node,
        AfterNode: movedNodes.moveAfterNode,
        BeforeNode: movedNodes.beforeNode,
      });
      const newSections = await lastValueFrom(request$);
      return updatePathwayAfterNodeMove(
        pathway,
        newSections.srcSection[0],
        newSections.destSection[0]
      );
    } catch (e) {
      throw new DgError(
        this.translate.instant(
          `Pathways_Move${this.capitalizeType(type)}Error`
        ),
        e
      );
    }
  }

  /**
   * Remove a single pathway node.
   *
   * @param pathId
   * @param node
   * @param type
   */
  public async removePathwayNode(
    pathId: number,
    node: string,
    type: ActionType
  ): Promise<PathwayDetailsModel> {
    try {
      const params = { pathId, Node: node };
      const request$ = this.http.delete<void>('/pathways/deletepathwaynode', {
        params,
      });
      await lastValueFrom(request$);

      return this.loadPathway(pathId);
    } catch (e) {
      throw new DgError(
        this.translate.instant(
          `Pathways_Delete${this.capitalizeType(type)}Error`
        ),
        e
      );
    }
  }

  /**
   * Updates pathway node used for updating title, description etc on a pathway
   * @param node
   * @returns void
   */
  public async updatePathwayNode(
    node: PathwayNode
  ): Promise<PathwayDetailsModel> {
    try {
      const request$ = this.http.post<PathwayDetailsModel>(
        '/pathways/updatepathwaynode',
        node
      );
      await lastValueFrom(request$);

      return this.loadPathway(node.pathId);
    } catch (e) {
      throw new DgError(this.translate.instant('Pathways_UpdateError'), e);
    }
  }

  // *******************************************************
  // Private utility functions
  // *******************************************************

  private capitalizeType(type: string): string {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  /**
   * Load pathway *without* getting the user permissions again. Used when nodes
   * are deleted and when they are added, to get updated ResourceImages.
   *
   * @param pathId
   * @returns Promise<PathwayDetailsModel>
   */
  private async loadPathway(pathId: number): Promise<PathwayDetailsModel> {
    try {
      const params = { pathId, useResourceImages: true };
      const request$ = this.http.get<PathwayDetailsModel>(
        '/pathways/getpathway',
        {
          params,
        }
      );
      return await lastValueFrom(request$);
    } catch (e) {
      const message = this.translate.instant(
        'Pathways_PathwayDetailAccessError'
      );
      throw new DgError(message, e);
    }
  }

  private reportMissingStep(step: PathwayStep) {
    console.error('Missing Reference object on Step.', step);

    this.tracker.trackEventData({
      action: 'Missing Reference',
      category: 'Pathway Step',
      label: '',
      properties: step,
    });
  }
}
