// This is the upgraded component of the ajs version: cardSkillMatchPresentation.ts
import { Component, Input, OnInit } from '@angular/core';

// Types
import { SkillMatchCategory } from '@app/opportunities/opportunities-api.model';

// Services
import { TranslateService } from '@ngx-translate/core';

// TODO: Update this once our smarter-matching is off the ground.

@Component({
  selector: 'dgx-opportunity-match-badge',
  templateUrl: './opportunity-match-badge.component.html',
})
export class OpportunityMatchBadgeComponent implements OnInit {
  // Bindings
  @Input() public includeFullLabel? = false;
  @Input() public isReversed? = false;
  @Input() public matchedSkills: number;
  @Input() public totalSkills: number;
  @Input() public badgeSize: string;

  // Local
  public labelResource: string;

  constructor(private translate: TranslateService) {}

  // Getter methods

  /**
   * Get match label, based on current total/matched skills.
   */
  public get matchLabel(): string {
    return this.translate.instant(this.labelResource, {
      total: this.totalSkills,
      matched: this.matchedSkills,
    });
  }

  /**
   * Get match type, based on current total/matched skills.
   */
  public get matchType(): SkillMatchCategory {
    return this.getMatchType(this.matchedSkills / this.totalSkills);
  }

  // Angular methods
  public ngOnInit(): void {
    this.labelResource = this.includeFullLabel
      ? 'Opportunities_SkillMatchFullLabel'
      : 'Opportunities_SkillMatchLabel';
  }

  // Private methods

  /**
   * Return matchType object, with true/false values for each
   * match type, optionally reversed.
   *
   * @param percentMatch - A given percentage.
   */
  private getMatchType(percentMatch: number) {
    if (isNaN(percentMatch)) {
      return;
    }

    // get our match percentage values
    const matches = [
      percentMatch < 0.4,
      percentMatch >= 0.4 && percentMatch <= 0.6,
      percentMatch > 0.6,
    ];
    // flip what is considered good and what is considered bad
    if (this.isReversed) {
      matches.reverse();
    }
    // return finished object
    return {
      partial: matches[0], // light gray (less than 0.4)
      good: matches[1], // light yellow (0.4-0.6)
      excellent: matches[2], // light green (greater than 0.6)
    };
  }
}
