import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { GlobalSearchComponent } from '@app/layout/v1/page-header/global-search/global-search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchAutocompleteReactiveStoreModule } from './components/search-initiation-autocomplete/search-autocomplete-reactive-store.module';
import { SearchAutocompleteComponent } from './components/search-initiation-autocomplete/search-autocomplete/search-autocomplete.component';
import { SearchInitiationAutocompleteComponent } from './components/search-initiation-autocomplete/search-initiation-autocomplete.component';
import { SearchInitiationComponent } from './components/search-initiation-autocomplete/search-initiation/search-initiation.component';
import { SearchResultCardComponent } from './components/search-result-card/search-result-card.component';
import { SearchSuggestComponent } from './components/search-suggest/search-suggest.component';
import { SimpleSearchSuggestComponent } from './components/simple-search-suggest/simple-search-suggest.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    SearchAutocompleteReactiveStoreModule,
  ],
  declarations: [
    SearchSuggestComponent,
    SimpleSearchSuggestComponent,
    GlobalSearchComponent,
    SearchInitiationAutocompleteComponent,
    SearchInitiationComponent,
    SearchAutocompleteComponent,
    SearchResultCardComponent,
  ],
  exports: [
    SearchSuggestComponent,
    SimpleSearchSuggestComponent,
    GlobalSearchComponent,
    SearchInitiationAutocompleteComponent,
    SearchInitiationComponent,
    SearchAutocompleteComponent,
    SearchResultCardComponent,
  ],
})
export class SearchSharedModule {}
