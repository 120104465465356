import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, pipe, UnaryFunction } from 'rxjs';

import {
  DocumentTitleService,
  TrackerService,
  AuthService,
} from '@app/shared/services';

import { PathwayViewModel } from './pathway.model';
import { PathwayFacade } from './pathway.facade';
import { tap } from 'rxjs/operators';

/**
 * The Bookmark class is the synchronization 'bridge' between
 * the Facade/Store and the URL.
 *
 *  - If the store state changes, some its state may be represented on the URL
 *  - When a store is initialized, some of the URL may be used to auto-load and
 *    configure the Pathway state/store
 */
@Injectable({ providedIn: 'root' })
export class PathwayBookmark {
  /**
   * Create a 'rxjs' operator to tap PathwayViewModel emissions and update the
   * url query params.
   */
  public updateBookmarkUrl: UnaryFunction<
    Observable<PathwayViewModel>,
    Observable<PathwayViewModel>
  >;

  constructor(
    private facade: PathwayFacade,
    private tracker: TrackerService,
    private documentTitleService: DocumentTitleService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    // Build operator that taps a SearchViewModel stream
    this.updateBookmarkUrl = pipe(tap(this.syncStoreToUrl.bind(this)));
  }

  /**
   * Initial URL -> Pathway State
   *
   * This is a 'bookmark' feature that will use the current url query params and
   * update the PathwayState to match.
   *
   * Note: This only runs once when a Pathway 'view component' first inits
   * and we fall back to the PathwayState initial values if there are
   * no overrides in the URL
   */
  public syncUrlToStore(): void {
    // TODO: Finalize implementation
  }

  /**
   * Pathway State updates -> URL
   * Update the url with the given state changes
   */
  private syncStoreToUrl(vm: PathwayViewModel): PathwayViewModel {
    if (!vm.pathway) {
      return vm;
    }

    // Update document title.
    this.documentTitleService.prependTitle(vm.pathway.title);

    // Return vm.
    return vm;
  }
}
