/**
 * Get form data for uploading file to Box
 */
export function getFormData(
  file: File,
  isAzureUpload: boolean,
  folderId?: string
): FormData {
  let fileAttributes;

  if (!isAzureUpload) {
    fileAttributes = JSON.stringify({
      name: getUniqueFileName(file.name),
      parent: {
        id: folderId,
      },
    });
  } else {
    fileAttributes = JSON.stringify({
      name: getUniqueFileName(file.name),
    });
  }

  const formData = new FormData();
  formData.append('attributes', fileAttributes);
  formData.append('file', file);
  return formData;
}

/**
 * Unique file name by appending the current UTC date to the file name
 */
function getUniqueFileName(fileName: string) {
  const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
  const fileExtension = fileName.substring(
    fileName.lastIndexOf('.'),
    fileName.length
  );

  const date = new Date();

  return `${fileNameWithoutExt}-${buildFormattedUTC(date)}${fileExtension}`;
}

function buildFormattedUTC(date: Date): string {
  return `${date.getUTCFullYear()}${
    date.getUTCMonth() + 1
  }${date.getUTCDate()}${date.getUTCHours()}${date.getUTCMinutes()}${date.getUTCSeconds()}${date.getUTCMilliseconds()}`;
}
