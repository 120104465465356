import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IndividualConfig,
  Toast,
  ToastPackage,
  ToastrService,
} from 'ngx-toastr';

export interface DfToastConfig extends IndividualConfig {
  actionLabel: string;
}

@Component({
  selector: 'dgx-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent extends Toast {
  public i18n = this.translateService.instant(['Core_Close']);
  public get hasAction(): boolean {
    return !!(this.options as DfToastConfig).actionLabel;
  }

  public get actionLabel(): string {
    return (this.options as DfToastConfig).actionLabel;
  }

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private translateService: TranslateService
  ) {
    super(toastrService, toastPackage);
  }
  action() {
    this.toastPackage.triggerAction();
    this.toastrService.clear();
    return false;
  }
}
