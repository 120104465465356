import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfSpinnerModule } from '../spinner/spinner.module';
import { DfSpinnerButtonComponent } from './spinner-button.component';

@NgModule({
  declarations: [DfSpinnerButtonComponent],
  imports: [CommonModule, DfSpinnerModule],
  exports: [DfSpinnerButtonComponent],
})
export class DfSpinnerButtonModule {}
