import { DfFormFieldConfig } from '@lib/fresco';

export interface FieldWrapperBuilder {
  build(context?: {}): DfFormFieldConfig;
}

export enum FieldType {
  'INPUT_SESSION_FIELD',
  'ADVANCED_FIELDSET',
  'SKILLS_FIELD',
  'USER_IMAGE_FIELD',
}

export abstract class FieldBuilderFactory {
  public abstract getBuilder(type: FieldType): FieldWrapperBuilder;
}
