import { CommonModule } from '@angular/common';
import { DfCheckboxComponent } from './checkbox.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule],
  declarations: [DfCheckboxComponent],
  exports: [DfCheckboxComponent]
})
export class DfCheckboxModule {}
