import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from '@app/shared/services/notifier.service';
import { SanitizationService } from '@app/shared/services/sanitization.service';
import { WindowToken } from '@app/shared/window.token';
import { EbbConfigService } from './ebb-config.service';

declare const browser;
export type PageOptions = 'search' | 'home' | 'start' | 'auth';

@Injectable({
  providedIn: 'root',
})
export class EbbUtilityService {
  constructor(
    private config: EbbConfigService,
    private notifier: NotifierService,
    private router: Router,
    private sanitization: SanitizationService,
    @Inject(WindowToken)
    private windowRef: Window
  ) {}

  public routePageTo(page: PageOptions) {
    if (page === 'search') {
      this.router.navigate(['/degreed-button/app/search']);
    } else if (page === 'auth') {
      this.router.navigate(['/degreed-button/app/auth']);
    } else if (page === 'start') {
      this.router.navigate(['/degreed-button/app/start']);
    }
  }

  public isRTLLanguage(userLocale: string): boolean {
    return this.config.USER_LANGUAGE.RTL_CODES.includes(userLocale);
  }


  public getParamByName(name: string) {
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(
      this.windowRef.location.search
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  public getDomain(url: string) {
    // Take location and parse the domain
    let domain = '';
    if (url) {
      const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match !== null &&
        match.length > 2 &&
        typeof match[2] === 'string' &&
        match[2].length > 0
      ) {
        domain = match[2];
      } else {
        domain = url;
      }
    }
    return domain;
  }

  public getProfileUrlFromVanity(profileUrl: string): string {
    const url = `profile${profileUrl}/overview`;
    return url;
  }

  public goToPathInProfile(profileName: string, path: string): string {
    const url = `profile${[profileName]}/${path}`;
    return url;
  }

  public baseUrlOnCdn(url: string) {
    url = url.replace('~/', '/');
    return this.config.CDN_URL + url;
  }

  public baseUrlOnBlob(url: string) {
    // if not already a based URL (starting with http)...
    if (url && url.indexOf('http') !== 0) {
      url = url.replace('~/', '/');
      url = this.config.BLOB_URL + url;
    }
    return url;
  }

  public baseRelativeUrlOnBase(url: string) {
    return this.config.ENDPOINT_BASE + '/' + url;
  }

  public openLink(url: string) {
    this.windowRef.open(url);
  }

  public buildUtmParams(utmContent: string) {
    const utmSource = this.getDomain(this.windowRef.document.referrer);
    const utmMedium = this.config.APP_PLATFORM;
    const utmCampaign = this.getParamByName('utm_campaign');
    return `utm_source=${utmSource}&utm_medium=${utmMedium}${
      utmCampaign && utmCampaign !== '' ? '&utm_campaign=' + utmCampaign : ''
    }&utm_content=${utmContent}`;
  }

  public handleError(message: string, error) {
    this.notifier.showError(message);
    console.error(error);
  }

  public showSuccess(message: string) {
    this.notifier.showSuccess(message);
  }

  public testImageForFallback(imageUrl: string) {
    const deferred = new Promise<void>((resolve, reject) => {
      if (imageUrl) {
        const img = document.createElement('img');
        img.onload = () => {
          resolve();
        };
        img.onerror = () => {
          reject();
        };
        img.src = imageUrl;
      } else {
        reject();
      }
    });
    return deferred;
  }

  public popupCenter(
    url: string,
    title: string,
    width: number,
    height: number
  ) {
    // Some calculations based on common oauth window positionings
    const screenX: number =
      typeof this.windowRef.screenX !== 'undefined'
        ? this.windowRef.screenX
        : this.windowRef.screenLeft;
    const screenY: number =
      typeof this.windowRef.screenY !== 'undefined'
        ? this.windowRef.screenY
        : this.windowRef.screenTop;
    const outerWidth: number =
      typeof this.windowRef.outerWidth !== 'undefined'
        ? this.windowRef.outerWidth
        : document.documentElement.clientWidth;
    const outerHeight: number =
      typeof this.windowRef.outerHeight !== 'undefined'
        ? this.windowRef.outerHeight
        : document.documentElement.clientHeight - 22; // 22 = toolbar magic number
    const horizontal: number =
      screenX < 0 ? this.windowRef.screen.width + screenX : screenX;
    // Passing float to window.open or browser.windows.create
    // throws an exception in Edge, so we'll round
    const left = Math.round(horizontal + (outerWidth - width));
    const top = Math.round(screenY + (outerHeight - height) / 2.5) + 14; // 14 = magic offset
    width = Math.round(width);
    height = Math.round(height);
    if (
      typeof browser !== 'undefined' &&
      browser.windows &&
      browser.windows.create
    ) {
      browser.windows.create({
        url: url,
        type: 'popup',
        width: width,
        height: height,
        top: top,
        left: left,
        focused: true,
      });
    } else {
      const newWindow = this.windowRef.open(
        url,
        title,
        `status=0,titlebar=0,width=${width},
         height=${height},top=${top},left=${left}`
      );
      if (this.windowRef.focus) {
        try {
          // try to put focus on the new window
          newWindow && newWindow.focus();
        } catch (e) {}
      }
    }
  }
  public sanitizeUserInput(text: string, decode = true) {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      '/': '&#x2F;',
      '`': '&grave;',
    };
    const reg = /[&<>"'/`]/gi;
    if (decode) {
      text = decodeURIComponent(text.trim());
    }
    return text.replace(reg, (match) => map[match]);
  }

  public cleanHtml(text: string): string {
    return this.sanitization.cleanHtml(text);
  }

  public setAnalytics() {
    // Typescript-compatible GA injection code
    const now = new Date() as any;
    const gaScript = document.createElement('script');
    const scripts = document.getElementsByTagName('script')[0];
    const ga = 'ga';
    const gaObj = 'GoogleAnalyticsObject';
    this.windowRef[gaObj] = ga;
    this.windowRef[ga] = (...args) => {
      (this.windowRef[ga].q = this.windowRef[ga].q || []).push(args);
    };
    this.windowRef[ga].l = 1 * now;
    gaScript.async = true;
    gaScript.src = 'https://www.google-analytics.com/analytics.js';
    scripts.parentNode.insertBefore(gaScript, scripts);
  }
}
