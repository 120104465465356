import { Injectable } from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { ResourceType } from '@app/shared/models/core-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { isChannelOrg, isResourceCollection } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class InputPermissionsService {
  constructor(private authService: AuthService) {}

  /**
   * Whether content can be added to the bundle with the current org.
   *
   * @param org - The org to check.
   */
  public canAddToBundle(org: OrgInfo) {
    return isChannelOrg(org);
  }

  /**
   * Whether the current resource type can be added to a pathway.
   *
   * @param resourceType - The resource type as a string.
   */
  public canAddToPathway(resourceType: ResourceType) {
    switch (resourceType) {
      case 'Article':
      case 'Video':
      case 'Book':
      case 'Course':
      case 'Episode':
      case 'Event':
      case 'Assessment': // intentional case fallthroughs
        return true;
      default:
        return false;
    }
  }

  /**
   * Whether the current resource can be queued.
   *
   * @param resourceType - The resource type as a string.
   */
  public canAddToQueue(resourceType: ResourceType) {
    switch (resourceType) {
      case 'Task':
      case 'Target':
      case 'Position':
      case undefined:
        return false;
      default:
        return true;
    }
  }

  /**
   * Whether the current resource can be added to a target.
   *
   * @param resourceType - The resource type as a string.
   * @param authUser - The current logged-in user.
   */
  public canAddToTarget(resourceType: ResourceType, authUser?: AuthUser) {
    authUser ??= this.authService.authUser;
    const allowed =
      authUser &&
      (authUser.canManageTargets ||
        authUser.canAuthorTargets ||
        authUser.isTargetAuthor) &&
      !authUser.defaultOrgInfo?.settings?.isClientProvider;
    switch (resourceType) {
      case 'Tag':
      case 'Pathway':
      case 'Article':
      case 'Video':
      case 'Book':
      case 'Course':
      case 'Episode':
      case 'Event':
      case 'Academy':
      case 'Assessment': // intentional case fallthroughs
        return allowed;
      case 'User':
      case 'Group':
        return !!authUser.orgInfo && allowed;
      default:
        return false;
    }
  }

  /**
   * Whether the current resource type can be marked complete.
   *
   * @param resourceType - The resource type as a string.
   */
  public canComplete(resourceType: ResourceType) {
    return !this.isResourceCollection(resourceType);
  }

  /**
   * Whether the current resource can be rated.
   *
   * @param resourceType - The resource type as a string.
   */
  public canRate(resourceType: ResourceType) {
    switch (resourceType) {
      case 'Task':
        return true;
      case 'Target':
      case 'Pathway':
      case 'Position':
      case undefined:
        return false;
      default:
        return true;
    }
  }

  /**
   * Whether the current resource type can be recommended.
   *
   * @param resourceType - The resource type as a string.
   * @param authUser - The current logged-in user.
   */
  public canRecommend(resourceType: ResourceType, authUser?: AuthUser) {
    authUser ??= this.authService.authUser;
    return (
      authUser &&
      authUser.canRecommendItems &&
      !!resourceType &&
      resourceType !== 'Task' &&
      resourceType !== 'Position'
    );
  }

  /**
   * Whether the current resource type is a resourcce collection.
   *
   * @param resourceType - The resource type as a string.
   */
  public isResourceCollection(resourceType: ResourceType) {
    return isResourceCollection(resourceType);
  }
}
