import { Injectable } from '@angular/core';
import { OrgSettingsService } from '@app/orgs/services';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { map } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * Used for commonly used URLs related to the authenticated user
 */

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public authUser = this.authService.authUser;
  private isSkillInventoryClient = false;
  private isSkillAnalyticsClient = false;
  private isSkillsClient = false;

  private readonly lxpHome = '/me';
  public paths = {
    home: this.lxpHome,
    vanityUrl: '',
    profileBase: '',
  };
  public profileOverviewEnabled = this.ldFlagsService.showProfileOverviewTab;

  constructor(
    private authService: AuthService,
    private orgSettingsService: OrgSettingsService,
    private ldFlagsService: LDFlagsService
  ) {
    this.setupPaths();
  }

  public get userUrlBase(): string {
    const authUser = this.authService.authUser;
    const userUrlBase = `/profile/${authUser?.viewerProfile?.vanityUrl}`;
    return userUrlBase;
  }

  /**
   * Gets the correct profile URL for the authenticated user
   */
  public get userProfileUrl(): string {
    return `${this.userUrlBase}/overview`;
  }

  /**
   * Gets the correct profile URL for the authenticated user
   */
  public get userGroupsUrl(): string {
    return `${this.authService.authUser?.viewerProfile?.vanityUrl}/dashboard/groups`;
  }

  public getUrl(vanityUrl: string, route?: string): string {
    let url = `/profile/${vanityUrl}/`;

    if (route) {
      url += route;
    }

    return url;
  }

  public getUserProfileUrl(userVanityUrl): string {
    if (this.isSkillsClient || !this.profileOverviewEnabled) {
      return this.getUrl(userVanityUrl, 'skills');
    } else if (this.profileOverviewEnabled) {
      return this.getUrl(userVanityUrl, 'overview');
    }
  }

  public setupPaths() {
    if (this.authUser) {
      this.isSkillInventoryClient = this.authUser.isSkillInventoryClient;
      this.isSkillAnalyticsClient = this.authUser.isSkillAnalyticsClient;
      this.isSkillsClient =
        this.isSkillInventoryClient || this.isSkillAnalyticsClient;
      this.paths.vanityUrl = this.authUser.userUrl;
      this.paths.profileBase = '';
      this.paths.home = this.isSkillsClient
        ? this.getUrl(this.authUser.userUrl, 'skills')
        : this.lxpHome;
      this.profileOverviewEnabled = !this.authUser.hideOverviewTab;
    }
  }

  public getLearnInSSOUrl(orgId: number) {
    return this.orgSettingsService
      .getSetting(orgId, 'LearnInSsoUrl')
      .pipe(map((settings) => settings.value));
  }
}
