import { Injectable } from '@angular/core';
import { UploadAdapter } from '@app/uploader/upload-section/upload-adapter';
import { UploadEvent } from '@app/uploader/uploader';
import {
  FileUploadSetting,
  UploadFileResponse,
  UserFileType,
} from '@app/uploader/uploader-api.model';
import { UploaderService } from '@app/uploader/uploader.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any', // allow the owning module to inject this locally in preference of any higher-level adapters
})
export class CertificateUploadAdapter extends UploadAdapter<UploadFileResponse> {
  constructor(uploaderService: UploaderService) {
    super(uploaderService);
  }

  public getUploadSettings(): Observable<FileUploadSetting> {
    return this.uploaderService.getUploadLimit('evidence');
  }

  public upload(
    imageFile: File,
    _
  ): Observable<UploadEvent<UploadFileResponse>> {
    const formData = this.uploaderService.prepRawImageFile(imageFile);
    const endpoint = '/files/uploaduserfile';
    const purpose: UserFileType = 'BehaviorEvidence';
    return this.uploaderService.uploadFile<UploadFileResponse>(
      formData,
      endpoint,
      {
        purpose,
        convertPdfToImage: true,
      },
      undefined,
      true
    );
  }
}
