import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { ResourceIdBuilder } from '../../resource-id-builder';

/** Represents a Reply to Post notification */
@Component({
  selector: 'dgx-reply-to-post',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplyToPostComponent extends NotificationItemComponentBase {
  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    // compose a valid resource ID permutation based on the notification parameters
    const contentResource = resourceIdBuilder
      .withSegment('replyToPost_')
      .withOptionalGroup()
      .withSingleOrPlural()
      .asFormat();

    return this.translateHtml(
      contentResource,
      {
        nameHtml: this.userFullNameTemplate,
        groupNameHtml: this.groupNameTemplate,
        number: this.params.personCount,
      }
      // "{{nameHtml}} [and {{number}} others] replied to your post [in {{groupNameHtml}}]"
    );
  }
}
