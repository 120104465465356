import { NgModule } from '@angular/core';
import { MenuComponent } from './menu.component';
import { CommonModule } from '@angular/common';
import { DfIconModule, DfPopoverModule } from '@lib/fresco';
import { DgxItemRoleModule } from '@app/shared/directives/item-role/item-role.module';

@NgModule({
  imports: [CommonModule, DfIconModule, DfPopoverModule, DgxItemRoleModule],
  providers: [],
  declarations: [MenuComponent],
  exports: [MenuComponent],
})
export class DgxMenuModule {}
