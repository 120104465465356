import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component mainly provides a single point to update
 * the styling for these kind of markers on inputs.
 * Defaults to 'Required' but is overrideable to 'Optional'
 * or any other text if we make a global update and need to transition.
 *
 * Can also potentially be restyled to use the icon method where
 * an icon is displayed as a marker on fields and described in aria.
 * e.g. https://www.accessibility-developer-guide.com/examples/forms/required/#better-approach-using-an-icon
 */
@Component({
  selector: 'df-label-marker',
  templateUrl: './label-marker.component.html',
  styleUrls: ['./label-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfLabelMarkerComponent {
  @Input() public labelMarkerText = this.translate.instant('Core_Required');

  constructor(public translate: TranslateService) {}
}
