import { Injectable } from '@angular/core';
import {
  CourseParameters,
  Input,
  InputCreationFeedback,
} from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { Observable } from 'rxjs';
import { PathwayInputService } from './pathway-input.model';

/**
 * Provides a helper service for pathway course modal form inputs.
 * TODO: Add additional functions here to load and update modal + add base class for getters and setters when ready if applicable
 * */
@Injectable({
  providedIn: 'root',
})
export class PathwayCourseService
  implements PathwayInputService<CourseParameters>
{
  constructor(private inputsService: InputsService) {}

  /**
   * @param userInputParams parameters for the specific new input type to add and associate with this user
   */
  public addNewInput(
    userInputParams: Partial<CourseParameters>
  ): Observable<InputCreationFeedback> {
    return this.inputsService.addCourse(userInputParams);
  }
}
