/**
 * Removes all the elements from the passed-in array, **mutating it**, and returns an
 * array of removed elements.
 *
 * Replacement for lodash remove() - https://youmightnotneed.com/lodash
 *
 * @param array The array to update
 * @param removalFunction The function to determine if an element should be removed
 * @returns Removed elements
 */
export function remove<T>(
  array: T[],
  removalFunction: (obj: T) => boolean
): T[] {
  // in order to not mutate the original array until the very end
  // we want to cache the indexes to remove while preparing the result to return
  const toRemove = [];
  const result = array.filter(
    (item, i) => removalFunction(item) && toRemove.push(i)
  );

  // just before returning, we can then remove the items, making sure we start
  // from the higher indexes: otherwise they would shift at each removal
  toRemove.reverse().forEach((i) => array.splice(i, 1));
  return result;
}
