import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgsInternalContentVisibilityComponent } from './orgs-internal-content-visibility.component';
import { GroupsSharedModule } from '@app/groups/groups-shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
  declarations: [OrgsInternalContentVisibilityComponent],
  imports: [CommonModule, SharedModule, GroupsSharedModule, CdkAccordionModule],
  exports: [OrgsInternalContentVisibilityComponent],
})
export class OrgsInternalContentVisibilityModule {}
