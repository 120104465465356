import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';

export interface ProfilePathInfo {
  home: string;
  vanityUrl: string;
  profileBase: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfilePathsService {
  private static readonly defaultPaths: ProfilePathInfo = {
    home: '/',
    vanityUrl: '',
    profileBase: '',
  };

  public constructor(private authService: AuthService) {}

  public get paths(): ProfilePathInfo {
    const authUser = this.authService.authUser;
    if (authUser) {
      return {
        vanityUrl: authUser.viewerProfile.profileUrl,
        home: '/' + authUser.viewerProfile.profileUrl + '/dashboard/feed',
        profileBase: '/index/1',
      };
    }
    return ProfilePathsService.defaultPaths;
  }

  // const jumpToTop = () => {
  //   document.body.scrollTop = document.documentElement.scrollTop = 0;
  // };
}
