<div class="grid__col-12 grid__col--bleed">
  <ng-container *ngIf="!isLoading; else loading">
    <div class="grid__col-12 guts-p-h-1-half guts-p-t-1 guts-p-b-half">
      <h1 class="h3" [innerHTML]="title"></h1>
      <p class="zeta par--light" [innerHTML]="subtitle"></p>
    </div>
    <div class="grid__col-12 guts-p-h-0 guts-p-v-0">
      <dgx-data-table
        [columns]="columns"
        [hasNoResults]="hasNoResults"
        [items]="skills"
        [isDescending]="isDescending"
        [isLoading]="isLoading"
        [noItemsImage]="noItemsImage"
        [emptyImageAlt]="i18n.Core_Empty_Image_Alt"
      ></dgx-data-table>
    </div>
  </ng-container>
</div>

<!-- Loading template -->
<ng-template #loading>
  <dgx-skeleton type="table" [columns]="1" [rows]="2"></dgx-skeleton>
  <dgx-skeleton type="table" [columns]="3" [rows]="10"></dgx-skeleton>
</ng-template>

<!--Average rating column template -->
<ng-template #averageRating let-item>
  <ng-container
    *ngIf="item.selfRating !== 0 && item.isFollowing; else hasNoAverageRating"
  >
    {{ item.averageRating || 0 | number: '1.1-1' }}
  </ng-container>
  <ng-template #hasNoAverageRating>
    <ng-container *ngIf="item.isFollowing; else addSkillToSeeAvg">
      {{ i18n.PersonalSkillView_Top10SkillsAvgRating }}
    </ng-container>
  </ng-template>
  <ng-template #addSkillToSeeAvg>
    {{ i18n.PersonalSkillView_Top10SkillsAddSkill }}
  </ng-template>
</ng-template>

<!--Self rating column template -->
<ng-template #selfRating let-item>
  <ng-container
    *ngIf="
      (item.selfRating || item.selfRating === 0) && item.isFollowing;
      else addSkill
    "
  >
    <dgx-tag-rating-donut
      class="ib horizontal-center rating-icon cursor-pointer"
      size="sm"
      [highlight]="true"
      [isOwner]="true"
      [level]="item.selfRating"
      [showDefaultPlus]="true"
      (click)="handleAddTargetLevel($event, item)"
    ></dgx-tag-rating-donut>
  </ng-container>
  <ng-template #addSkill>
    <dgx-action-button-link
      class="ib horizontal-center rating-icon cursor-pointer"
      [displayText]="i18n.PersonalSkillView_AddSkill"
      (primaryAction)="handleAddSkill($event, item)"
      icon="plus"
    >
    </dgx-action-button-link>
  </ng-template>
</ng-template>
