import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ChartsService } from '@app/charts/charts.service';
import { LearningChartService } from '@app/charts/components/learning-chart/learning-chart.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import {
  DateRange,
  DateRangeService,
} from '@app/shared/services/date-range.service';
import { ReportingContentService } from '@app/shared/services/reporting-content/reporting-content.service';
import { ReportingService } from '@app/shared/services/reporting/reporting.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  Router,
  Event,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { GroupsTabComponents } from '@app/groups/components/group/group.component';
@Component({
  selector: 'dgx-group-insights',
  templateUrl: './group-insights.component.html',
  styleUrls: ['./group-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInsightsComponent implements AfterViewInit, OnInit {
  @Input() public orgId: number;
  @Input() public groupId: number;
  @Input() public groupName: string;
  @Input() public canViewDownloadReports: boolean;

  // dgxLearningChart component Output binding
  @Output() public getChartData: EventEmitter<{
    params: any;
  }> = new EventEmitter<{ params: any }>();

  public readonly CLASS_NAME = GroupsTabComponents.GroupInsightsComponent;
  public kpisLoading: boolean;
  public kpis: any[] = [];
  public canViewDownloadableReports: boolean;
  public canViewReporting: boolean;
  public canViewLearningSummaryDetails: boolean;
  public dateRange: DateRange;
  public totalDisplayData: any = {};
  public selectedPeriod: any = {};
  public disableRatingsInsights: boolean;
  public dateDisplayFormat: any = 'd MMM yyyy';
  public authUser: any;
  public startRange: DateRange;
  public startDate: any = {};
  public endDate: any = {};
  public dataTypes: any;
  public reportingUrl: string;
  public isChannel: boolean;
  public showMore = false;
  public resolver: any;

  private readonly DATE_FORMAT = 'd MMM yyyy';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private contextService: ContextService,
    private dateRangeService: DateRangeService,
    private reportingService: ReportingService,
    private authService: AuthService,
    private tracker: TrackerService,
    private reportingContentService: ReportingContentService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private chartsService: ChartsService,
    private learningChartService: LearningChartService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.getChartData.subscribe((event) => {
      this.getLearningSummary(event);
    });
    this.authService.authUser$.subscribe((user) => {
      this.authUser = user;
      this.reportingUrl = `/reporting?src=group&gId=${this.groupId}`;
      this.dateRange = this.startRange =
        this.dateRangeService.getPastDaysExcludeTodayUTC(30);
      this.dataTypes = this.reportingContentService.getDataTypes();
      this.startDate = {
        name: this.dateRangeService.getDateFormat(this.startRange.startDate),
        value: new Date(this.startRange.startDate),
      };

      this.endDate = {
        name: this.dateRangeService.getDateFormat(this.startRange.endDate),
        value: new Date(this.startRange.endDate),
      };

      this.disableRatingsInsights =
        this.authUser && this.authUser.disableRatingsInsights;
      this.updateDateRange(this.dateRange);

      this.tracker.trackEventData({
        action: 'Group Insights Viewed',
        properties: {
          GroupId: this.groupId,
          GroupName: this.groupName,
          Location: 'Group Insights',
        },
      });

      this.isChannel = this.contextService.urlHasContext(
        location.href,
        'channel'
      );

      // Currently this button is being hidden both in GAT and for Global Groups regardless
      // of the download reports permission
      // If PD-35689 is resolved to allow functional reports for GAT groups, this can go back
      // to being a check only on the authUser.canDownloadReports permission
      this.canViewDownloadableReports =
        !this.isChannel && // Global Group in GAT
        this.orgId === this.authUser.orgInfo[0].organizationId && // Global Group shared
        this.authUser.defaultOrgInfo.permissions.downloadReports; // permission check

      // PD-82328 display view learning summary details only for user with viewReports permission
      this.canViewLearningSummaryDetails =
        this.authUser.defaultOrgInfo.permissions.viewReports;

      this.router.events
        .pipe(
          filter(
            (e: Event): e is NavigationStart => e instanceof NavigationStart
          )
        )
        .subscribe((e: NavigationStart) => {
          let regex = /[?&]([^=#]+)=([^&#]*)/g,
            url = e.url,
            params = {
              more: false,
            },
            match;
          while ((match = regex.exec(url))) {
            params[match[1]] = match[2];
          }
          this.showMore = params?.more;
        });
    });
  }

  public ngAfterViewInit(): void {
    this.loadChartsModule();
  }

  public updateDateRange = (range) => {
    this.dateRange = range;
    // This remaining bit is still necessary since all of the chid components depend on these bindings
    const startDate = new Date(this.dateRange.startDate),
      endDate = new Date(this.dateRange.endDate);
    this.selectedPeriod = {
      Value: {
        start: startDate,
        end: endDate,
      },
    };

    this.startDate = {
      name: this.datePipe.transform(startDate, this.DATE_FORMAT),
      value: startDate,
    };
    this.endDate = {
      name: this.datePipe.transform(endDate, this.DATE_FORMAT),
      value: endDate,
    };
    this.cdr.markForCheck();
  };

  public getKpis() {
    const startDate = this.startDate.value,
      endDate = this.endDate.value,
      groupId = this.groupId > 0 ? this.groupId : '';

    this.kpisLoading = true;
    this.reportingService
      .getInsightKPIs(this.orgId, startDate, endDate, groupId, null)
      .pipe(take(1))
      .subscribe((data) => {
        const kpis = [];
        this.kpisLoading = false;
        for (let i = 0; i < data.length; i++) {
          const kpi = data[i],
            kpiItem = {
              name: kpi.name,
              location: 'Group Insights',
              metric: kpi.metric,
              trend: kpi.trend || 0,
              description: this.translate.instant(
                'OrgReportingCtrl_Kpi' + kpi.name + 'Name'
              ),
              tooltip: this.translate.instant(
                'OrgReportingCtrl_Kpi' + kpi.name + 'Tooltip'
              ),
              order: i,
              uid: i, // for track by
              numOfDays: this.reportingService.getNumberOfDays(
                startDate,
                endDate
              ),
            };
          switch (kpi.name) {
            case 'ItemsAdded':
              kpiItem.order = 2;
              break;
            case 'Recommendations':
              kpiItem.order = 3;
              break;
          }
          kpis.push(kpiItem);
        }
        this.kpis = kpis;
        this.cdr.markForCheck();
      });
  }

  public getLearningSummary({ params }: any) {
    const startDate = this.startDate.value;
    const endDate = this.endDate.value;
    if (this.reportingContentService.isInputType(params.inputType)) {
      params.inputType = [params.inputType];
    }
    params.tag = this.reportingContentService.setTagWhenDefault(params.tag);
    let observer: Observable<any>;
    if (params.dataType === this.dataTypes.views) {
      observer = this.reportingService.getTotalContentViewed(
        this.orgId,
        startDate,
        endDate,
        params.inputType,
        params.tag,
        this.groupId,
        null
      );
    } else {
      observer = this.reportingService.getInsights(
        this.orgId,
        startDate,
        endDate,
        params.inputType,
        params.tag,
        this.groupId
      );
    }
    observer.pipe(take(1)).subscribe(
      (data) => {
        data = this.learningChartService.transformDataToPascal(data);
        const chartData = this.learningChartService.extractChartData(
          data,
          params.dataType
        );
        this.totalDisplayData = chartData;
      },
      (err) => {
        this.totalDisplayData = null;
      },
      () => {
        this.cdr.markForCheck();
      }
    );
    this.getKpis();
  }

  /**
   * Lazy load Charts module
   */
  private loadChartsModule(): void {
    ChartsService.loadModule().then((m) => {
      this.resolver = m.ComponentResolver;

      this.cdr.markForCheck();
    });
  }
  public getComponent(name: string): any {
    if (this.resolver) {
      return this.resolver.getComponent(name);
    }
    return null;
  }
  public getChartInputs(name: string) {
    let config = {};
    if (!this.resolver) {
      return config;
    }
    switch (name) {
      case 'dgxReportingRangePicker':
        config = {
          appearance: 'dropdown',
          selection: this.updateDateRange,
          value: this.dateRange,
          location: 'Group Insights',
        };
        break;
      case 'dgxLearningChart':
        config = {
          startDate: this.startDate,
          endDate: this.endDate,
          chart: this.totalDisplayData,
          selectedPeriod: this.selectedPeriod,
          setSelectedPeriod: false,
          orgId: this.orgId,
          hasDrillDown: !this.isChannel && this.canViewLearningSummaryDetails,
          groupId: this.groupId,
          location: 'Group Insights',
          getChartData: this.getChartData,
        };
        break;
      case 'dgxSkillRatingsChart':
        config = {
          orgId: this.orgId,
          context: 'group',
          moreId: 'skillRatings',
          startDate: this.startDate.value,
          endDate: this.endDate.value,
          groupId: this.groupId,
          location: 'Group Insights',
        };
        break;
      case 'dgxPopularDataChart':
        config = {
          orgId: this.orgId,
          startDate: this.startDate.value,
          endDate: this.endDate.value,
          groupId: this.groupId,
          location: 'Group Insights',
        };
        break;
    }
    return config;
  }
}
