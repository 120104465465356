import { TranslationHelper } from '@app/shared/services/translation-helper.service';
import { Notification, NotificationParameters } from './notification-api.model';

/** Helper class that uses the Builder pattern to simplify dynamic creation of resource ID strings from segments */
export class ResourceIdBuilder {
  private params: NotificationParameters;
  private resourceId = '';

  constructor(notification: Notification) {
    this.params = notification.parameters;
  }

  public withSegment(segment: string) {
    this.resourceId += segment;
    return this;
  }

  public withYouOrGroup() {
    this.resourceId +=
      this.params.groupId || this.params.groupName ? 'Group' : 'You';
    return this;
  }

  public withOptionalGroup() {
    this.resourceId += this.params.groupName ? 'Group' : '';
    return this;
  }

  public withSingleOrPlural() {
    this.resourceId += this.params.personCount > 1 ? 'Plural' : 'Single';
    return this;
  }

  public withOptionalCompletion() {
    this.resourceId += this.params.alreadyCompleted ? 'AlreadyCompleted' : '';
    return this;
  }

  public withOptionalAlready() {
    this.resourceId += this.params.alreadyCompleted ? 'Already' : '';
    return this;
  }

  public withOptionalDueDate() {
    this.resourceId += this.params.dueDate ? 'Date' : 'NoDate';
    return this;
  }

  public withStatus() {
    this.resourceId += this.params.isSuccess ? 'Success' : 'Failure';
    return this;
  }

  public withFollow() {
    this.resourceId += this.params.alreadyCompleted
      ? 'AlreadyFollow'
      : 'Follow';
    return this;
  }

  public asFormat() {
    return this.resourceId + 'Format';
  }

  public withContentActionType() {
    this.resourceId += TranslationHelper.getContentActionType(
      this.params.resourceType
    );
    return this;
  }
}
