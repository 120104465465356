<div role="alert" aria-live="assertive">
  <div
    *ngIf="this.controlIsDirty && this.errors"
    class="form__invalid-msg ib rel"
    [@fadeInAndOut]
  >
    <df-icon
      size="small"
      icon="arrow-down-right"
      class="guts-p-r-quart"
    ></df-icon>

    <span
      *ngFor="let error of errorTranslations; last as lastItem"
      data-dgat="validation-pattern-message"
      >{{ error }}<br *ngIf="!lastItem"
    /></span>
  </div>
</div>
