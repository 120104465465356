/**
 * Checks if value is a plain object, that is, an object created by the Object constructor or 
 * one with a [[Prototype]] of null.
 * 
 * @param value The value to check.
 * @returns Returns true if value is a plain object, else false.
 */

export function isPlainObject(value: any): boolean {
  if (typeof value !== 'object' || value === null) return false

  if (Object.prototype.toString.call(value) !== '[object Object]') return false

  const proto = Object.getPrototypeOf(value);
  if (proto === null) return true

  const Ctor = Object.prototype.hasOwnProperty.call(proto, 'constructor') && proto.constructor;
  return (
      typeof Ctor === 'function' &&
      Ctor instanceof Ctor && Function.prototype.call(Ctor) === Function.prototype.call(value)
  );
}
