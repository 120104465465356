/**
 * Encode the given string without double encoding it
 */
export function safeEncodeURIComponent(input?: string) {
  if (!input) {
    return '';
  }

  let value: string;

  try {
    // Swallow errors on decoding non-ascii characters
    value = decodeURIComponent(input);
  } catch (e) {
    value = input;
  }

  return encodeURIComponent(value);
}
