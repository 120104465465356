import { OrgInfo } from '@app/account/account-api.model';
import { ResourceType } from '@app/shared/models/core-api.model';

/**
 * Whether the current org is a channel org.
 *
 * @param org - The org to check.
 */
export function isChannelOrg(org: OrgInfo) {
  return org.type === 'Channel';
}

/**
 * Whether the resourceType is a pathway or a target.
 *
 * @param resourceType - The type as a string.
 */
export function isResourceCollection(resourceType: ResourceType) {
  return resourceType === 'Pathway' || resourceType === 'Target';
}
