import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OpportunityFlagsService } from '@app/opportunities/services/opportunity-flags.service';
import { OpportunityPermissionsService } from '@app/opportunities/services/opportunity-permissions.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { GroupService } from '@app/shared/services/group.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AvailableGroupPermissions, GroupInfo } from '@app/groups/group-api';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { GroupsTabComponents } from '../group/group.component';
import { PredicateType } from '@app/automations/model';
import { AuthService, LDFlagsService } from '@dg/shared-services';

interface PrivacyOption {
  name: string;
  level: number;
  enabled: boolean;
  newTip: string;
  tip: string;
}

// Must match GroupPrivacyLevel.cs
enum GroupPrivacyLevels {
  Open = 0,
  Closed = 1,
  Private = 2,
  Administrative = 3,
}

@Component({
  selector: 'dgx-group-settings',
  templateUrl: './group-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSettingsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public group: GroupInfo;
  @Input() public availablePermissions: AvailableGroupPermissions[];
  @Input() public groupId: number;
  @Input() public canCreateOpenGroups: boolean;
  @Input() public canCreateClosedGroups: boolean;
  @Input() public canCreatePrivateGroups: boolean;
  @Input() public canCreateAdministrativeGroups: boolean;
  @Input() public canEditPermissions: boolean;
  @Input() public canDeleteGroup: boolean;
  @Input() public isAdministrativeGroup: boolean;
  @Input() public newGroupOrg: any;
  @Input() public isRegulated: boolean;

  @Output() public saveCallback: EventEmitter<any> = new EventEmitter<any>();

  public readonly CLASS_NAME = GroupsTabComponents.GroupSettingsComponent;
  public descriptionMessage: string;
  public editing: boolean;
  public loadedPrivacyLevel: number;
  public nameChanged = false;
  public nameMessage: string;
  public originalGroupName = '';
  public privacyOptions: PrivacyOption[] = [];
  public radioButtonTip: string;
  public saving = false;
  public showPrivacyOptions: boolean;

  // form
  public groupForm: FormGroup = this.formBuilder.group({
    anonymousUserCount: [''],
    description: ['', [Validators.required, Validators.maxLength(1950)]],
    groupId: [''],
    groupType: [''],
    interestNames: [''],
    interests: [[]],
    isRestricted: [''],
    name: [
      '',
      [Validators.required, Validators.maxLength(255), Validators.minLength(2)],
    ],
    organizationCode: [''],
    organizationId: [''],
    organizationName: [''],
    organizationName_Secure: [''],
    privacyLevel: [0],
    userCount: [''],
    enableOpportunities: [''],
    membershipType: ['Static'],
    predicates: this.formBuilder.array([]),
    segmentId: [''],
  });

  public i18n = this.translateService.instant([
    'Core_AdvancedSettings',
    'Core_Save',
    'Core_Loading',
    'Core_FieldRequired',
    'GroupSettingsCtrl_AdministrativeGroup',
    'GroupSettingsCtrl_AdministrativeNewTip',
    'GroupSettingsCtrl_AdministrativeTip',
    'GroupSettingsCtrl_ClosedGroup',
    'GroupSettingsCtrl_ClosedNewTip',
    'GroupSettingsCtrl_ClosedTip',
    'GroupSettingsCtrl_CustomTooltip',
    'GroupSettingsCtrl_Description',
    'GroupSettingsCtrl_EnableOpportunities',
    'GroupSettingsCtrl_GroupName',
    'GroupSettingsCtrl_OpenGroup',
    'GroupSettingsCtrl_OpenNewTip',
    'GroupSettingsCtrl_OpenTip',
    'GroupSettingsCtrl_PrivateGroup',
    'GroupSettingsCtrl_PrivateNewTip',
    'GroupSettingsCtrl_PrivacySettings',
    'GroupSettingsCtrl_PrivateTip',
    'GroupSettingsCtrl_SpecifyName',
    'GroupSettingsCtrl_DynamicMembership',
    'GroupSettingsCtrl_DynamicMembership_Info',
    'GroupSettingsCtrl_DynamicMembership_Instructions',
    'dgGroupDelete_DeleteGroupTitle',
  ]);

  // Hide `Permissions` and `Delete` during group creation (modal)
  public get isEditing() {
    return this.group?.groupId;
  }

  public get canCreateDynamicGroups() {
    return (
      this.ldFlagsService.orgManagement.dynamicGroups &&
      this.authService.authUser?.defaultOrgInfo?.permissions?.manageSegments &&
      this.canCreateAdministrativeGroups
    );
  }

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private groupService: GroupService,
    private notifierService: NotifierService,
    private OpportunityFlagsService: OpportunityFlagsService,
    private opportunityPermissionsService: OpportunityPermissionsService,
    private trackerService: TrackerService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private ldFlagsService: LDFlagsService,
    private authService: AuthService
  ) {
    super();
  }

  public ngOnInit() {
    // update privacy tip on privacyLevel change
    this.groupForm
      .get('privacyLevel')
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((val) => {
        this.setRadioButtonTip(val);
      });

    // verify group name is unique
    this.groupForm
      .get('name')
      .valueChanges.pipe(
        this.takeUntilDestroyed(),
        debounceTime(500),
        // filter out empty strings
        filter((name) => !!name),
        switchMap((name) => this.groupService.checkName(name, this.groupId))
      )
      .subscribe(({ alreadyExists }) => {
        this.validateName(alreadyExists);
        this.changeDetectorRef.markForCheck();
      });

    this.privacyOptions = [
      {
        name: this.i18n.GroupSettingsCtrl_OpenGroup,
        level: 0,
        enabled: this.canCreateOpenGroups,
        newTip: this.i18n.GroupSettingsCtrl_OpenNewTip,
        tip: this.i18n.GroupSettingsCtrl_OpenTip,
      },
      {
        name: this.i18n.GroupSettingsCtrl_ClosedGroup,
        level: 1,
        enabled: this.canCreateClosedGroups,
        newTip: this.i18n.GroupSettingsCtrl_ClosedNewTip,
        tip: this.i18n.GroupSettingsCtrl_ClosedTip,
      },
      {
        name: this.i18n.GroupSettingsCtrl_PrivateGroup,
        level: 2,
        enabled: this.canCreatePrivateGroups,
        newTip: this.i18n.GroupSettingsCtrl_PrivateNewTip,
        tip: this.i18n.GroupSettingsCtrl_PrivateTip,
      },
      {
        name: this.i18n.GroupSettingsCtrl_AdministrativeGroup,
        level: 3,
        enabled: this.canCreateAdministrativeGroups,
        newTip: this.i18n.GroupSettingsCtrl_AdministrativeNewTip,
        tip: this.i18n.GroupSettingsCtrl_AdministrativeTip,
      },
    ];

    if (this.newGroupOrg) {
      this.groupForm.patchValue({
        privacyLevel: this.getInitialPrivacyLevel(),
        organizationId: this.newGroupOrg,
      });
      this.editing = false;
      if (this.isRegulated) {
        this.groupForm.patchValue({ privacyLevel: 2 });
        this.showPrivacyOptions = false;
      }
      // show privacy options when there are two or more options available
      this.showPrivacyOptions =
        Number(this.canCreateOpenGroups) +
          Number(this.canCreateClosedGroups) +
          Number(this.canCreatePrivateGroups) +
          Number(this.canCreateAdministrativeGroups) >
        1;
    } else {
      // hide administrative option when editing since you can't change to/from it. showPrivacyOptions is set after group loads
      this.privacyOptions[3].enabled = false;
      this.editing = true;
      this.loadGroup();
    }

    this.trackerService.trackEventData({
      action: 'Group Settings Viewed',
      properties: this.group,
    });
  }

  public getEditTags() {
    return this.groupForm.get('interests').value;
  }

  public getDisplayAdvancedSettings() {
    return (
      this.opportunityPermissionsService.canViewOpportunityGroupSettings() &&
      this.OpportunityFlagsService.useEnableOpportunitySettingForGroup
    );
  }

  public save() {
    if (!this.validate()) {
      return;
    }

    this.saving = true;

    const { enableOpportunities } = this.groupForm.value;
    const groupFormData = {
      ...this.groupForm.value,
      groupConfigs: {
        enabledOpportunitiesForGroup: enableOpportunities,
      },
    };

    const save$ = this.editing
      ? this.updateGroup(groupFormData)
      : this.addGroup(groupFormData);

    save$.subscribe((successMessage: string) => {
      this.notifierService.showSuccess(
        this.translateService.instant(successMessage)
      );

      this.saving = false;
      this.groupService.notifyGroupModified(groupFormData.groupId);
      this.changeDetectorRef.markForCheck();
    });
  }

  public getInitialPrivacyLevel() {
    for (const option of this.privacyOptions) {
      if (option.enabled) {
        return option.level;
      }
    }
    return null;
  }

  public loadGroup() {
    this.groupService.getGroup(this.groupId).subscribe((data) => {
      const privacyLevel = this.isRegulated
        ? 2
        : // convert string value to number value via enum
          (GroupPrivacyLevels[data.privacyLevel] as unknown as number);

      this.predicates.clear();
      data.predicates?.forEach((p) => {
        this.predicates.push(
          this.formBuilder.group({
            fieldName: ['', Validators.required],
            comparisonOperator: ['', Validators.required],
            comparisonValue: ['', Validators.required],
            predicateType: ['', Validators.required],
          })
        );
      });

      // map data to form
      this.groupForm.patchValue({
        anonymousUserCount: data.anonymousUserCount,
        description: data.description,
        groupId: data.groupId,
        groupType: data.groupType,
        interestNames: data.interestNames,
        interests: data.interests,
        isRestricted: data.isRestricted,
        name: data.name,
        organizationCode: data.organizationCode,
        organizationId: data.organizationId,
        organizationName: data.organizationName,
        organizationName_Secure: data.organizationName_Secure,
        privacyLevel,
        userCount: data.userCount,
        enableOpportunities: data.groupConfigs?.enabledOpportunitiesForGroup,
        membershipType: data.membershipType,
        predicates: data.predicates,
        segmentId: data.segmentId,
      });

      // set radio button tip based on privacyLevel
      this.setRadioButtonTip(privacyLevel);

      this.originalGroupName = data.name;

      this.showPrivacyOptions = this.mayShowPrivacyOptions(privacyLevel);

      this.loadedPrivacyLevel = privacyLevel;

      // Skill Coach autogenerates manager groups without descriptions
      // Trigger the validation on edit to show the error message so it's
      // clear to users why the form isn't valid
      if (this.editing) {
        this.triggerDescriptionValidation();
      }

      this.changeDetectorRef.markForCheck();
    });
  }

  public validate(): boolean {
    return this.validateName() && this.validateDescription();
  }

  public setRadioButtonTip(privacyLevelValue: number) {
    const option: PrivacyOption = this.privacyOptions?.find(
      ({ level }) => level === privacyLevelValue
    );

    if (option) {
      this.radioButtonTip = `${option.name}: ${
        this.newGroupOrg ? option.newTip : option.tip
      }`;
    }
  }

  public handleTagsChange($event: unknown) {
    this.groupForm.patchValue({ interests: $event });
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  public setDynamicGroupOption(event: boolean) {
    this.predicates.clear();
    if (event) {
      this.predicates.push(
        this.formBuilder.group({
          fieldName: ['', Validators.required],
          comparisonOperator: ['', Validators.required],
          comparisonValue: ['', Validators.required],
          predicateType: [PredicateType.Attribute, Validators.required],
        })
      );
    }

    this.groupForm.get('membershipType').setValue(event ? 'Dynamic' : 'Static');
  }

  public get predicates() {
    return this.groupForm.get('predicates') as FormArray;
  }

  private addGroup(groupFormData: Partial<GroupInfo>) {
    return this.groupService.add(groupFormData).pipe(
      map((data) => {
        this.saveCallback?.emit({ groupId: data.groupId });

        this.resetGroup();

        return 'GroupSettingsCtrl_GroupAddedSuccess';
      })
    );
  }

  private updateGroup(groupFormData: Partial<GroupInfo>) {
    return this.groupService.update(groupFormData).pipe(
      map(() => {
        this.saveCallback?.emit();

        const privacyLevelChanged =
          this.loadedPrivacyLevel !== groupFormData.privacyLevel;

        if (privacyLevelChanged) {
          this.loadGroup();
        }

        return privacyLevelChanged
          ? 'GroupSettingsCtrl_GroupUpdatePrivacySuccess'
          : 'GroupSettingsCtrl_GroupUpdateSuccess';
      })
    );
  }

  private mayShowPrivacyOptions(privacyLevel: number) {
    if (this.isRegulated || privacyLevel === 3) {
      return false;
    }

    // show privacy options when there are two or more options available
    // not including administrative and if privacy is not administrative
    const showPrivacyOptions =
      Number(!!this.canCreateOpenGroups) +
        Number(!!this.canCreateClosedGroups) +
        Number(!!this.canCreatePrivateGroups) >
      1;

    return showPrivacyOptions;
  }

  private validateName(org = null) {
    if (typeof org === 'string') {
      this.nameMessage = this.translateService.instant(
        'GroupSettingsCtrl_NameExists',
        { org }
      );
    } else {
      this.nameMessage = this.groupForm.get('name').value
        ? ''
        : this.i18n.GroupSettingsCtrl_SpecifyName;
    }
    return this.nameMessage.length === 0;
  }

  private validateDescription() {
    this.descriptionMessage = this.groupForm.get('description').value
      ? ''
      : this.translateService.instant('GroupSettingsCtrl_SpecifyDescription');
    return this.descriptionMessage.length === 0;
  }

  private triggerDescriptionValidation() {
    const description = this.groupForm.get('description');

    description.markAsTouched();
    description.updateValueAndValidity();
  }

  private resetGroup() {
    this.groupForm.patchValue({
      description: null,
      interests: null,
      name: null,
      organizationId: this.newGroupOrg,
      privacyLevel: this.getInitialPrivacyLevel(),
      segmentId: null,
    });
  }
}
