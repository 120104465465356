import { Injectable } from '@angular/core';
import { InputType } from '@app/shared/models/core-api.model';
import { Trackable } from '@app/shared/models/trackable';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  Input,
  InputDetails,
  InputIdentifier,
  UserInputCreationResult,
  UserInputIdentifier,
} from '../inputs-api.model';
import { InputActions } from '../inputs.enums';

/**
 * Provides web API and helper methods for getting and manipulating Input (aka Content) objects.
 */
@Injectable({ providedIn: 'root' })
export class UserInputsService extends ApiServiceBase {
  public get inputsChange(): Observable<unknown> {
    return this._inputsChange.asObservable();
  }
  /**
   * Hot observable for keeping track of submissons on the parent form.
   * This and related methods/properties can be deleted once the form has *either* been
   * moved entirely into Formly or entirely into ReactiveForms, but while this hybrid
   * continues, we need something like this to aid in change detection.
   */
  public get childFormsMarkedAsTouched$(): Observable<void> {
    return this._markChildFormsAsTouched.asObservable();
  }
  private _inputsChange = new Subject();
  /**
   * Hot observable for keeping track of sumissions on the parent form #2. See note for
   * @see {childFormsMarkedAsTouched$}
   */
  private _markChildFormsAsTouched = new Subject<void>();

  private readonly i18n = this.translate.instant([
    'UserInputsSvc_AddToProfileError',
    'UserInputsSvc_DeleteFromProfileError',
    'UserInputsSvc_UpdateProfileError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient,
    private tracker: TrackerService
  ) {
    super(http, translate.instant('UserInputsSvc_AccessProfileError'));
  }

  public getUserInput(inputType: InputType, userInputId: number) {
    return this.get('/userinputs/getuserinput', {
      inputType: inputType,
      userInputId: userInputId,
    });
  }

  /** @deprecated Don't use in Angular code. Provided for backward compatibility only. */
  public add(
    inputType: InputType,
    inputId: number,
    input: Input | InputDetails,
    trackingArea: any,
    element?: HTMLElement
  ) {
    return this.addUserInput(
      { inputType, inputId },
      input,
      trackingArea,
      null,
      element
    );
  }

  /** Adds a "completion" entry for the current user.
   * @param identifier Input identifier
   * @param input Input for tracking purposes only
   * @param trackingArea Contextual tracking area
   */
  public addUserInput(
    identifier: InputIdentifier,
    trackableInput: Trackable | any,
    trackingArea: any,
    isPathwayOptionalStep?: boolean,
    element?: HTMLElement,
    tags?: any[]
  ) {
    return this.post<UserInputCreationResult>(
      '/userinputs/post',
      { ...identifier, tags },
      this.i18n.UserInputsSvc_AddToProfileError
    ).pipe(
      tap((r) => {
        this.notify(
          { ...identifier, userInputId: r.userInputId },
          InputActions.completed,
          isPathwayOptionalStep
        );
        this.tracker.trackEventData({
          action: 'Content Completed',
          category: identifier.inputType,
          element,
          properties: {
            ...(trackableInput instanceof Trackable
              ? {
                  ...trackableInput.getTrackingCopy(),
                  providerId:
                    trackableInput.getTrackingCopy().providerSummary?.id ??
                    'None',
                  providerName:
                    trackableInput.getTrackingCopy().providerSummary?.name ??
                    'None',
                  skillTagCount: tags?.length ?? 'None',
                  tagsCount: tags?.length ?? 'None',
                }
              : {
                  ...trackableInput,
                  providerId: trackableInput.providerSummary?.id ?? 'None',
                  providerName: trackableInput.providerSummary?.name ?? 'None',
                  skillTagCount: tags?.length ?? 'None',
                  tagsCount: tags?.length ?? 'None',
                  hostedType: trackableInput.hostedType ?? 'None',
                  location: trackingArea ?? 'None',
                }),
          },
        });
      })
    );
  }

  /** @deprecated Don't use in angular code. Provided for backward compatibility only. */
  public _deleteUserInput(
    inputType: InputType,
    inputId: number,
    userInputId: number,
    element?: HTMLElement
  ) {
    return this.deleteUserInput(
      {
        inputType,
        inputId,
        userInputId,
        userProfileKey: undefined,
      },
      null,
      element
    ).toPromise();
  }

  public deleteUserInput(
    identifier: UserInputIdentifier,
    isPathwayOptionalStep?: boolean,
    element?: HTMLElement
  ) {
    return this.delete(
      '/userinputs/deleteuserinput',
      identifier,
      this.i18n.UserInputsSvc_DeleteFromProfileError
    ).pipe(
      tap(() => {
        this.notify(
          identifier,
          InputActions.uncompleted,
          isPathwayOptionalStep
        );
        this.tracker.trackEventData({
          action: 'User Item Deleted',
          category: identifier.inputType,
          element,
        });
      })
    );
  }

  public deleteMany(userInputs: UserInputIdentifier[]) {
    return this.post(
      '/userinputs/deleteuserinputs',
      {
        userInputs,
      },
      this.i18n.UserInputsSvc_DeleteFromProfileError
    ).pipe(tap(() => this.notify()));
  }

  public updateMany(userInputs: UserInputIdentifier, tags: TagsApi.Tag[]) {
    return this.post(
      '/userinputs/updateuserinputs',
      {
        userInputs,
        tags,
      },
      this.i18n.UserInputsSvc_UpdateProfileError
    ).pipe(tap(() => this.notify()));
  }

  /**
   * Hot observable for keeping track of sumissions on the parent form #3. See note for
   * @see {childFormsMarkedAsTouched$}
   */
  public markChildFormsAsTouched() {
    this._markChildFormsAsTouched.next();
  }

  private notify(
    identifier?: UserInputIdentifier,
    action?: InputActions,
    isPathwayOptionalStep?: boolean
  ) {
    this._inputsChange.next({ ...identifier, action, isPathwayOptionalStep }); // Notify angular listeners
  }

  public validateNumericValueOnKeyDown(event: any) {
    const charCode = event?.which ? event.which : event.keyCode;
    const eventKey = event?.key ? event.key : event.key;
    if (
      eventKey === '-' ||
      eventKey === '+' ||
      eventKey === 'e' ||
      eventKey === 'E'
    ) {
      // This condition we exclude some symbols on input type number field
      return false;
    } else if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105) // In this condition we exclude Keys on the number pad are codes 96 to 105 &  The 0 to 9 keys are codes 48 to 57.
    ) {
      return false;
    } else {
      return true; // This condition allow to type remaining keys
    }
  }
}
