import { OnInit, ElementRef, Input, Renderer2, Directive } from '@angular/core';

/**
 * This fixes an issue where using target="_blank" on outgoing links poses a security risk.
 * https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c#.luxeei64q
 *
 * NOTE: This will NOT secure interpolated link elements in translation strings. You must add the properties manually...
 * `this.translate.instant('i18nkey', {
 *    link: '<a href="..." target="_blank" rel="noopener noreferrer">foo</a>'
 * })`
 */

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[target=_blank]',
})
export class SecureBlankTargetDirective implements OnInit {
  @Input('rel') public rel: string;

  private readonly secureProperties = ['noopener', 'noreferrer'];

  constructor(
    private elementRef: ElementRef<HTMLAnchorElement>,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    const currentProperties = this.rel?.split(' ') || [];
    // use `Set` to prevent duplicates
    const newProperties = [
      ...new Set([...currentProperties, ...this.secureProperties]),
    ].join(' ');
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'rel',
      newProperties
    );
  }
}
