import { Pipe, PipeTransform } from '@angular/core';
import { jsDateToNgbDate } from '../utils/time-utils';

/** Converts a Date, date string or number to a Date then to an ng-bootstrap
 * NgbDate.
 */
@Pipe({
  name: 'ngbDate',
  pure: true,
})
export class NgbDatePipe implements PipeTransform {
  transform(value: string | number | Date): any {
    if (value !== undefined && value !== null) {
      const date = new Date(value);
      return jsDateToNgbDate(date);
    }
    return undefined;
  }
}
