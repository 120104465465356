import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Request Group Membership notification */
@Component({
  selector: 'dgx-request-group-membership',
  template: `
    <span [innerHTML]="safeContent"></span>
    <span>
      <span *ngIf="notification.parameters.requestStatus === 0">
        - {{ 'requestGroupMembership_Denied' | translate }}</span
      >
      <span *ngIf="notification.parameters.requestStatus === 1">
        - {{ 'requestGroupMembership_Approved' | translate }}</span
      >
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestGroupMembershipComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('requestGroupMembership_Single', {
      nameHtml: this.userFullNameTemplate,
      groupNameHtml: this.groupNameTemplate,
    });
  }
}
