<div class="page-header">
  <ng-container *ngIf="canStick; else headerWithoutStick">
    <div
      (stickyChange)="onStickyChange($event)"
      class="page-header__main"
      dgxSticky
      stickyCustomClass="page-header__main--sticky box-shadow bg-color-ebony-a03-hue guts-p-full-1"
    >
      <ng-container *ngTemplateOutlet="headerWithoutStick"></ng-container>
    </div>
  </ng-container>
  <ng-template #headerWithoutStick>
    <div class="l_flexbar" [class.m-l_flexbar-block]="!isHeaderStuck">
      <!-- Page title -->
      <div class="page-header__title l_flex-grow">
        <div class="l_flex l_flex-wrap grid--align-center">
          <div class="guts-m-r-2">
            <h1
              *ngIf="pageTitle || useCustomPageHeaderTemplate"
              class="page-header__title__text h1 break"
            >
              <ng-container [ngSwitch]="pageTitleType">
                <ng-container *ngSwitchCase="'template'">
                  <ng-content select=".custom-page-title"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="'usePageHeaderLink'">
                  <a
                    [ngbTooltip]="pageHeaderTitleTooltip"
                    [innerHTML]="pageTitle | stripMarkup"
                    data-dgat="page-header-a78"
                    (click)="pageHeaderCallbackHandler()"
                  ></a>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <span [innerHTML]="pageTitle | stripMarkup"></span>
                </ng-container>
              </ng-container>
            </h1>
          </div>
          <div *ngIf="statusPillText">
            <span [ngClass]="statusPillClass" class="badge badge-pill">
              {{ statusPillText }}
            </span>
          </div>
        </div>
        <div
          *ngIf="isNewHeaderDesign"
          [class.guts-p-t-1]="!isHeaderStuck"
          class="page-header__meta"
        >
          <ul class="break bullets par par--light par--small">
            <li *ngIf="department" [innerHTML]="department | stripMarkup"></li>
            <ng-container *ngIf="closeDate || isClosed; else onlyPublishedDate">
              <li>
                {{ getFormattedDate('Core_OpenedOnDate', publishDate) }}
              </li>
              <li>
                <span class="color-error">
                  {{ getClosedDate(closeDate) }}
                </span>
              </li>
            </ng-container>
            <ng-template #onlyPublishedDate>
              <li [ngbTooltip]="getFormattedDate('Core_OnDate', publishDate)">
                {{
                  getPrettyFormattedDate(
                    'Core_PublishedXTimeAgoFormat',
                    publishDate
                  )
                }}
              </li>
            </ng-template>
            <!-- TODO: This is used *only* by the OpportunityViewHeader component. Perhaps PageHeader
              should be extended so that its contents can be more easily customized? -->
            <li *ngIf="userStage" class="no-bullet">
              <span class="badge badge-pill badge-success">
                {{ userStage }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- Page actions -->
      <div
        class="
          page-header__actions
          l_flex-top
          l_flex--align-right
          m-guts-p-t-1
          m-l_flex-col
        "
      >
        <ng-content></ng-content>
      </div>
    </div>
  </ng-template>
  <!-- Page description -->
  <div *ngIf="pageDescription" class="guts-p-t-1-half par markdown">
    <dgx-text-expander
      [content]="pageDescription | markdownToHtml: { allowImages: true }"
      [isHtml]="true"
      [maxCollapsedLines]="2"
      [useReadMore]="true"
      [collapse]="pageType !== 'Browse'"
    ></dgx-text-expander>
  </div>

  <!-- Collaborators and Privacy Level, if showing -->
  <section
    class="guts-p-t-3 l_flexbar m-l_flexbar-col"
    *ngIf="(showCollaborators || privacyLevelHeader) && !isNewHeaderDesign"
  >
    <div
      *ngIf="showCollaborators"
      class="
        par par--small
        guts-m-r-quart guts-p-r-2-half
        m-guts-p-r-0
        l_flexbar
      "
    >
      <div class="guts-p-r-half font-semibold">{{ collaboratorsText }}:</div>
      <dgx-users-pictures
        [isEditing]="allowCollaboratorsEditing && isEditing"
        [users]="collaborators"
        (onEdit)="handleEditCollaborators($event)"
      >
      </dgx-users-pictures>
    </div>
    <div *ngIf="privacyLevelHeader">
      <!-- Privacy Level display -->
      <p
        *ngIf="!allowPrivacyLevelEditing || !isEditing"
        class="par par--small m-guts-p-t-1"
      >
        {{ privacyLevelHeader }}
      </p>
      <button
        *ngIf="allowPrivacyLevelEditing && isEditing"
        class="par par--small uhover no-wrap m-guts-p-t-1"
        (click)="onEditPrivacyLevel($event)"
        data-dgat="pageHeader-886"
      >
        <span>{{ privacyLevelHeader }}</span
        ><df-icon
          class="guts-p-l-half"
          icon="chevron-down"
          size="small"
        ></df-icon>
      </button>
    </div>
  </section>
</div>
