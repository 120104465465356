import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  NEVER,
  Observable,
  race,
  EMPTY,
  of,
} from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { DfFormFieldBuilder } from '@lib/fresco';

import {
  InputIdentifier,
  LearningInputModel,
  MediaInput,
} from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { isKey, Key } from '@app/shared/key';
import { AuthService } from '@app/shared/services/auth.service';
import {
  InputsFacadeBase,
  InputSubmissionResult,
} from '@app/user-content/services/inputs-facade-base';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import {
  InputContext,
  MediaEntryApiEntity,
  RenderMode,
} from '../user-input.model';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { MediaFormModel } from './media-form.model';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { InputType, MediaInputType } from '@app/shared/models/core-api.model';
import { TipService } from '@app/onboarding/services/tip.service';
import { CHUploadService } from '@degreed/content-hosting-data-access';
import { MediaParseType } from '@app/shared/models/core.enums';

export enum MediaMetadataStatus {
  None,
  Parsing,
  QuickParsed,
  FullyParsed,
}

/** Base class for {@link MediaEntry}-related user content management modal services, including articles and videos */
@Injectable()
export abstract class MediaModalFacadeBase<
  TFormModel extends MediaFormModel = MediaFormModel
> extends InputsFacadeBase<TFormModel, MediaEntryApiEntity> {
  public readonly options = {};
  public collapsedFields = [];
  public expandedFields = [];
  public readonly userInterests = this.authService.authUser.viewerInterests;
  public mediaMetadata: MediaInput;
  public shouldShowRecordVideo: boolean;
  public shouldDisabledRecordButton: boolean;
  public isDurationDisabled: boolean;

  protected mediaMetadataStatus$ = new BehaviorSubject(
    MediaMetadataStatus.None
  );

  protected parse$: Observable<any> = NEVER;

  private readonly requiredError = this.translate.instant(
    'Core_FieldRequiredFormat',
    { fieldName: this.translate.instant('MediaFormCtrl_ArticleUrl') }
  );

  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: MediaEntryApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    builder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    commentsApiService: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    @Inject(DOCUMENT) private document: Document,
    tipService: TipService,
    private contentHostingUploadService: CHUploadService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      builder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      commentsApiService,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }

  /** Override */
  protected get beforeSubmit(): Observable<void> {
    /*  WHEN editing
          can submit IF
            content upload succeeded OR not started
        WHEN adding
          can submit IF
            url parsed OR content upload succeeded
    */

    // Don't get confused here; the submission status being checked is that of the file upload, which is being used to gate submission of the overall form
    if (this.inputContext.isEditing) {
      return this.contentUploadStatus$.pipe(
        switchMap((s) => {
          return s === SubmissionStatus.None || s === SubmissionStatus.Succeeded
            ? of(void 0)
            : EMPTY;
        })
      );
    } else {
      const contentUploaded$ = this.contentUploadStatus$.pipe(
        switchMap((s) =>
          s === SubmissionStatus.Succeeded ? of(void 0) : EMPTY
        )
      );
      // When adding, allow submission to proceed either when we complete the first parse of the initial URL or a media upload completed
      return race([this.parse$, contentUploaded$]);
    }
  }

  /** Override */
  protected get extendedDefaultViewModel(): Partial<TFormModel> {
    // Show the Next/Submit button as spinning whenever we're parsing, waiting to submit on dependent data (such as a file upload or media parse), or actually submitting
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
      this.mediaMetadataStatus$,
    ]).pipe(
      map(
        ([s, m]) =>
          s === SubmissionStatus.Pending ||
          s === SubmissionStatus.Submitting ||
          m === MediaMetadataStatus.Parsing
      )
    );

    const vm: Partial<MediaFormModel> = {
      formTitle: this.translate.instant(
        this.inputContext.isEditing
          ? this.inputContext.renderMode === RenderMode.Pathways
            ? 'Core_EditItem' // pathways internal content edit shows a special title
            : `MediaFormCtrl_Edit${this.inputContext.inputType}` /* MediaFormCtrl_EditArticle, MediaFormCtrl_EditVideo */
          : `MediaFormCtrl_Add${this.inputContext.inputType}` /* MediaFormCtrl_AddArticle, MediaFormCtrl_AddVideo */
      ),
      // This submit button displayed as a Next button to create a wizard-like form is only shown in the content catalog context
      submitButtonText: this.submitButtonText,
      // This was being *intentionally* disabled, then re-enabled later after the article had been parsed.
      // *However*, when entering a URL, you had to hit ENTER/RETURN on the keyboard in order to *simulate*
      // clicking the Submit button (labeled "Next"), as simply blurring the field did not load the article.
      // It would probably be better to add an onBlur action to this specific form field, but these multiple
      // inherited facades are kind of chaotic, and it's difficult to find where the correct place to update
      // the field definition would be, and then it would have to share a handler with onKeyDown, and...
      // In the meantime: setting this back to *false*, which leaves the user with a lit-up "Next" submit button
      // that they can click to load the article. This makes more sense as a user flow, imho.
      isSubmitButtonDisabled: false,
      onAddToCatalogChange: this.onAddToCatalogChange.bind(this),
      // allow the submit button once we've parsed any metadata
      shouldShowSubmitButton$: this.mediaMetadataStatus$.pipe(
        map((s) => s >= MediaMetadataStatus.QuickParsed)
      ),
      shouldSpinSubmitButton$,
      parseMediaUrl: this.parseMediaUrl.bind(this),
      isMediaParsed: false,
      canEditTitle: this.isAuthoring,
      canEditProvider: false,
      canEditMediaLength: false,
      canEditSummary: false,
      inputType: this.inputContext.inputType as MediaInputType,
      organizationName: this.authService.authUser.defaultOrgInfo?.name,
      mediaMetadataStatus$: this.mediaMetadataStatus$,
      inputTypeFormats$: this.getInputTypeFormats(),
      imageSizeId: 1,
      mediaUrl: '',
      title: '',
      description: '',
      providerName: '',
    };
    return vm as Partial<TFormModel>;
  }

  protected get submitButtonText() {
    return this.isEditing ||
      this.mediaMetadataStatus$.value >= MediaMetadataStatus.QuickParsed ||
      this.contentUploadStatus$.value === SubmissionStatus.Succeeded
      ? this.translate.instant(
          `MediaFormCtrl_Save${this.inputContext.inputType}` /* MediaFormCtrl_SaveArticle, MediaFormCtrl_SaveVideo */
        )
      : this.translate.instant('Core_Next');
  }

  /** Override */
  public onKeyDown(event: KeyboardEvent) {
    if (
      !this.inputContext.isEditing &&
      !this.mediaMetadata &&
      isKey(event, Key.Enter)
    ) {
      // Simulate the default button enter key 'submit' behavior to parse the article
      // HACK: force the url field's form control to update so we don't get a `required` validation error
      // A11y Note: This blur should be ok since we're in a modal so not much else to focus, and as soon
      // as the the parsing is complete we'll autofocus the replaced URL input again
      (this.document.activeElement as HTMLElement)?.blur();
      this.parseMediaUrl();
    }
  }

  /** Override */
  public onSubmit(): Observable<InputSubmissionResult> {
    // editing we need to check for duplicates before submitting
    if (
      this.isEditing &&
      (this.inputContext.renderMode === RenderMode.ContentCatalog ||
        this.inputContext.renderMode === RenderMode.Pathways)
    ) {
      return this.checkAndUpdateUrlDuplicates().pipe(
        mergeMap(() => {
          return super.onSubmit();
        })
      );
    }
    // first screen, we're going to parse URL if we're using the URL to get information
    else if (
      !this.isEditing &&
      !this.mediaMetadata &&
      !this.viewModel.uploadedContentDetails && // If uploading media content && we have a media URL, we don't need to parse the url--the content IS already uploaded there
      this.viewModel.mediaUrl
    ) {
      this.parseMediaUrl();
      return EMPTY; // Close stream and don't actually submit if parsing (i.e. "Next" button pressed)
      // for hosted content the form will be expanded as if Next clicked once the upload is complete
    }
    // final screen we're submitting
    else {
      // onsubmit recheck for duplications for content catalog (when not uploading) incase the url has changed,
      if (
        this.inputContext.renderMode === RenderMode.ContentCatalog &&
        !this.viewModel.uploadedContentDetails
      ) {
        return this.checkAndUpdateUrlDuplicates().pipe(
          mergeMap(() => {
            return super.onSubmit();
          })
        );
      }
      return super.onSubmit();
    }
  }

  protected parseMediaUrl() {
    if (!this.viewModel?.mediaUrl) {
      return;
    }

    if (this.inputContext.renderMode === RenderMode.ContentCatalog) {
      this.fetchUrlDuplicates();
    }

    const url = this.inputsService.cleanUrl(this.viewModel.mediaUrl);

    this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);
    const parseRequest = this.inputsService
      .getMediaMetadata(url, this.inputContext.inputType, MediaParseType.None)
      .pipe(shareReplay());
    this.parse$ = parseRequest;

    parseRequest.subscribe((result: any) => {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
      this.scrapedData = result;
      this.updateStateFromParsedMetadata(result, url);
    });
  }

  protected updateStateFromParsedMetadata(
    mediaMetadata: MediaInput,
    url: string
  ) {
    this.mediaMetadata = mediaMetadata;

    const model: MediaEntryApiEntity = {
      ...this.initialModel,
      ...this.mediaMetadata,
      authored: this.viewModel.isUserAuthored,
      organizationId: this.orgId,
      tags: this.viewModel.tags, // ignore metadata tags
      useQuickCheck: this.mediaMetadata.accessible
        ? this.inputsService.canQuickParse(this.mediaMetadata.entryUrl)
        : false,
      durationHours: this.mediaMetadata.durationHours,
      durationMinutes: this.mediaMetadata.durationMinutes,
      entryUrl: url,
    };

    this.viewModel = {
      ...this.viewModel,
      isMediaParsed: true,
      isSubmitButtonDisabled: false,
      // change Next button (displayed for content catalog only) to Submit button
      submitButtonText: this.submitButtonText,
      canEditProvider: this.isAuthoring && !this.mediaMetadata.sourceName,
      canEditSummary:
        this.isAuthoring &&
        (!this.mediaMetadata.summary || !this.mediaMetadata.accessible),
      canEditMediaLength:
        this.isAuthoring &&
        (!this.mediaMetadata.mediaLength || !this.mediaMetadata.accessible),
      ...this.getMapper().toViewModel({
        ...model,
        orgContentMetadata: { hideFromCatalog: !this.viewModel.addToCatalog },
      }),
    };

    this.updateUIConfiguration();
  }

  /** Derived classes should call this to check for duplicates when addToCatalog vm property
   * changes to true.
   */
  protected onAddToCatalogChange(shouldAdd: boolean) {
    if (shouldAdd && this.viewModel.mediaUrl !== null) {
      this.fetchUrlDuplicates();
    } else {
      this.duplicates = [];
      this.viewModel = {
        ...this.viewModel,
        duplicateCount: 0,
      };
    }
  }

  /**
   * Check for content hosting support and update view model with FileUploadSettings if true.
   * Note: Adding to media-modal-facade as support will be added for video soon.
   *
   * @returns void
   */
  protected checkForContentHostingSupport() {
    this.contentHostingUploadService
      .canUploadHostedFile({
        uploadType: 'Article',
        renderMode: this.inputContext.renderMode,
      })
      .subscribe((response) => {
        const authUser = this.authService.authUser;
        const [supportsContentHosting, fileRestrictions] = response;
        this.viewModel = {
          ...this.viewModel,
          // determines to load upload-section component in modal container
          shouldShowContentUploader:
            supportsContentHosting ||
            (!authUser.canUploadContentFiles &&
              this.inputContext.isEditing &&
              this.inputContext.renderMode === RenderMode.ContentCatalog),
          // the upload-section component should be displayed but disabled
          shouldDisableContentUploader:
            !supportsContentHosting &&
            this.inputContext.renderMode === RenderMode.ContentCatalog &&
            this.inputContext.isEditing,
          fileRestrictions: fileRestrictions,
        };
      });
  }

  private fetchUrlDuplicates() {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.fetchDuplicates(
      true,
      this.viewModel.organizationId,
      this.viewModel.mediaUrl,
      inputIdentifier
    ).subscribe();
  }

  private checkAndUpdateUrlDuplicates() {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.viewModel.mediaUrl = this.inputsService.cleanUrl(
      this.viewModel.mediaUrl
    );

    return this.inputsService
      .getCmsInputsByUrl(
        this.viewModel.organizationId,
        this.viewModel.mediaUrl,
        inputIdentifier
      )
      .pipe(
        tap((response: LearningInputModel) => {
          if (response.inputs) {
            this.duplicates = response.inputs;
            this.viewModel = {
              ...this.viewModel,
              duplicateCount: response.inputs.length,
            };
          }
        })
      );
  }
}
