import { ReplyCommentModel } from '@app/inputs/inputs-api.model';
import {
  InputDetails as PascalCaseInputDetails,
  Tag as PascalCaseTag,
  Target as PascalCaseTarget,
} from '@app/shared/ajs/pascal-cased-types.model';

// When a typeguard returns true, it's telling the TS compiler
// that this variable is a specfic type. This is useful when a function
// is working with a variable number of types and they need to be narrowed down.
// See more here: https://rangle.io/blog/how-to-use-typescript-type-guards/

export function isFunction<T extends Function>(v: any): v is T {
  return typeof v === 'function';
}

export function isBoolean(v: any): v is boolean {
  return typeof v === 'boolean';
}

/**
 * Determines if a the given value is a date.
 */
export function isDate(v: any): v is Date {
  return Object.prototype.toString.call(v) === '[object Date]';
}

/**
 * Test if item is a PascalCase (Legacy) target type
 *
 * @example
 * // without a typeguard
 * function(item: Target | InputDetails | Tag) {
 *   // ERROR - Property 'TargetId' does not exist on type 'Target | InputDetails | Tag'
 *   const id = item.TargetId;
 * }
 *
 * // with a typeguard
 * function(item: Target | InputDetails | Tag) {
 *   if (isPascalCaseTarget(item)) {
 *     // no error because we are telling the TS compiler item is a Target
 *      const id = item.TargetId;
 *   }
 * }
 */
export const isPascalCaseTarget = (item: any): item is PascalCaseTarget => {
  return 'TargetId' in item || 'PathwayId' in item;
};

/**
 * Test if item is a PascalCase (Legacy) Input type
 *
 * @example
 * // without a typeguard
 * function(item: Target | InputDetails | Tag) {
 *   // ERROR - Property 'InputId' does not exist on type 'Target | InputDetails | Tag'
 *   const id = item.InputId;
 * }
 *
 * // with a typeguard
 * function(item: Target | InputDetails | Tag) {
 *   if (isPascalCaseInput(item)) {
 *      // no error because we are telling the TS compiler item is an Input
 *      const id = item.InputId;
 *   }
 * }
 */
export const isPascalCaseInput = (
  item: any
): item is PascalCaseInputDetails => {
  return 'InputId' in item;
};

/**
 * Test if item is a PascalCase (Legacy) Tag type
 *
 * @example
 * // without a typeguard
 * function(item: Target | InputDetails | Tag) {
 *   // ERROR - Property 'InputId' does not exist on type 'Target | InputDetails | Tag'
 *   const id = item.TagId;
 * }
 *
 * // with a typeguard
 * function(item: Target | InputDetails | Tag) {
 *   if (isPascalCaseTag(item)) {
 *      // no error because we are telling the TS compiler item is a Tag
 *      const id = item.TagId;
 *   }
 * }
 */
export const isPascalCaseTag = (item: any): item is PascalCaseTag => {
  return 'TagId' in item;
};

export const isReplyCommentModel = (item: any): item is ReplyCommentModel => {
  return 'parentId' in item;
};
