import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '../window.token';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get isRTL(): boolean {
    return this.computedStyle.direction === 'rtl';
  }

  private get computedStyle(): CSSStyleDeclaration {
    return this.windowRef.getComputedStyle(this.document.body);
  }
}
