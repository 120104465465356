import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { DocumentTitleService } from '@app/shared/services/document-title/document-title.service';
import { EngagedLearnerService } from './shared/services/engaged-learner.service';
import { TrackerService } from './shared/services/tracker.service';
import { UserTourService } from './shared/services/user-tour.service';
import { TagRatingService } from './tags/services/tag-rating.service';
import { A11yService } from './shared/services/a11y.service';

declare global {
  interface Window {
    Osano: any;
  }
}

@Component({
  selector: 'dgx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @HostListener('document:mousedown')
  handleMouseClick() {
    this.a11yService.addMouseFocus();
  }

  @HostListener('document:keyup.tab')
  @HostListener('document:keyup.shift.tab')
  handleKeyUp() {
    this.a11yService.removeMouseFocus();
  }

  constructor(
    private documentTitleService: DocumentTitleService,
    private tagRatingService: TagRatingService,
    private engagedLearnerService: EngagedLearnerService,
    private userTourService: UserTourService,
    private trackerService: TrackerService,
    private a11yService: A11yService
  ) {}

  public ngOnInit(): void {
    // Set the document title tag value for a given Angular route
    // after the navigation ends is successful
    this.documentTitleService.register();

    // Some Tag rating email notification urls include properties to trigger modals
    this.tagRatingService.checkForRatingEmails();

    // Congratulate user for achieving engaged learner status
    this.engagedLearnerService.checkForEngagedLearnerStatusChange();

    // Start chameleon tour for registered orgs
    this.userTourService.loadTourIfEnabled();
  }

  ngAfterViewInit() {
    // Degreed Amplitude Tracking
    this.trackerService.trackIdentify().subscribe();
  }
}
