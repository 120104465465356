import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FacetFilter,
  Filter,
} from '@app/shared/components/filter/filter.component';
import { TranslateService } from '@ngx-translate/core';
import { pull as _pull } from 'lodash-es';

type FilterTrackByFn = (index: number, filter: Filter) => unknown;

/**
 * Use this component to filter data-tables. Uses `filter.component`
 * under the hood.
 *
 * @example
 * ```
 * // Data filter definition
 * // `l_flex-grow` class allows the container to take up all
 * // available width in an l_flex design
 * <dgx-data-filters
 *   [filters]="filters"
 *   [isLoading]="isLoading"
 *   (filtersChange)="applyFilters($event)" or use $event.filters for just filters
 *   class="l_flex-grow"
 * ></dgx-data-filters>
 * ```
 */
@Component({
  selector: 'dgx-data-filters',
  templateUrl: './data-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFiltersComponent implements OnChanges {
  // Bindings
  // - input
  /** Enables the popover close to act as an "Apply" instead of a "Cancel" */
  @Input() public applyOnClose?: boolean = false;
  /** Disable or enable clear filters button to clear out all filters at once.* */
  @Input() public disableClearFiltersButton?: boolean = false;
  /** Hide the checkbox and only show the text when implementing the checkbox filer */
  @Input() public hideCheckboxIcon = false;
  /** Class to indicate a filter is passive. *Defaults to `btn-passive`.* */
  @Input() public disabledButtonClass? = 'btn-passive';
  /** An array of filters for display. More than one filter one will add a "Clear Filters" button. */
  @Input() public filters: Filter[] = [];
  /**
   * Classes to be added to the wrapping block element of each filter. Use `m-full-width` in
   * conjunction with `wrapperClasses` to force each filter to be full-width on mobile.
   */
  @Input() public filterClasses? = 'm-full-width';
  /** Pass in from parent component if filter content might sometimes be loading. */
  @Input() public isLoading? = false;
  /** Pass in from parent component if filter content should be disabled. */
  @Input() public isDisabled? = false;
  /** How many filters must there be before the (option) 'Clear Filters' button shows? */
  @Input() public minimumFiltersForClearButton = 2;
  /** Use a custom trackBy for the for-loop (optional) */
  @Input() public trackByFn?: FilterTrackByFn = this.defaultTrackByFn;
  /**
   * Classes to be added to the wrapping block element of *all* filters. Use `m-l_flexbar-col`
   * in conjunction with `filterClasses` to force each filter to be full-width on mobile.
   */
  @Input() public wrapperClasses? = 'm-flexbar-col';
  /** The number of character of text to show before truncating with an ellipsis. */
  @Input() ellipsis? = 0;
  // - output
  /** Pass the current filters, id of the filter that changed to parent component when they are saved. */
  @Output() public filtersChange = new EventEmitter<{
    filters: Filter[];
    id: string;
  }>();
  /** Pass the filter change event to parent component. This event tracks when filter being clicked or updated */
  @Output() public trackableEvent = new EventEmitter<any>();
  /** This event only track filter has been updated */
  @Output() public trackableFilterUpdateEvent = new EventEmitter<Filter>();

  // - public
  public i18n = this.translate.instant([
    'Core_ClearFilter',
    'Core_ClearFilters',
  ]);
  public clearFilterLabel: string;
  public showClearFiltersButton = false;

  public constructor(private translate: TranslateService) {}

  public ngOnChanges({ filters }: SimpleChanges): void {
    // update clear filters button
    if (
      !filters ||
      this.disableClearFiltersButton ||
      this.filters.length < this.minimumFiltersForClearButton
    ) {
      return;
    }
    this.setShowClearFiltersButton();
  }

  /**
   * On any filter change, pass the current filters array back to
   * the parent component.
   */
  public applyFilter({ id, filters }: { id: string; filters: FacetFilter[] }) {
    // update the *correct* filter
    this.filters = this.filters.map((filter) =>
      filter.id !== id
        ? filter
        : {
            ...filter,
            filters,
          }
    );
    // emit change with all filters
    this.filtersChange.emit({ filters: this.filters, id: id });
  }

  /**
   * Update the value of showClearFiltersButton, should be called
   * whenever the value of filters updates.
   */
  public setShowClearFiltersButton(): void {
    const numSelectedFilters =
      // _.pull removes items from an array that match its predicate
      _pull(
        // _.some returns true if any of its predicates return true
        // (and stops looping through as soon as it gets a true return)
        this.filters.map((filter) =>
          filter.filters.some((subfilter) =>
            subfilter.subitems.some((item) => item.isSelected)
          )
        ),
        // so this creates an array like [true, false], which we then
        // pull the false values from
        false
        // and we check its length as greater than minimumFiltersForClearButton
        // to display the clear button
      ).length;
    this.clearFilterLabel =
      numSelectedFilters > 1
        ? this.i18n.Core_ClearFilters
        : this.i18n.Core_ClearFilter;
    // return true if at least one option from *more than the number of minimumFiltersForClearButton* filter(s) is(are) selected
    this.showClearFiltersButton =
      !this.disableClearFiltersButton &&
      numSelectedFilters >= this.minimumFiltersForClearButton;
  }

  /**
   * Emit events for tracking in the parent.
   */
  public trackEvent(event: Event) {
    this.trackableEvent.emit(event);
  }

  public trackFilterUpdateEvent(filter: Filter) {
    this.trackableFilterUpdateEvent.emit(filter);
  }

  /**
   * Programmatically clear *all* filters at once, then pass the
   * (now cleared) filters array back to the parent component. *Used
   * in situations where there is more than one filter displayed.*
   */
  public clearFilters(): void {
    // clear all filters
    this.filters = this.filters.map((filter: Filter) => ({
      ...filter,
      filters: filter.filters.map((subfilter) => ({
        ...subfilter,
        subitems: subfilter.subitems.map((option) => ({
          ...option,
          isSelected: false,
        })),
      })),
    }));
    // emit change
    this.filtersChange.emit({ filters: this.filters, id: undefined });
  }

  public defaultTrackByFn(_: number, filter: Filter) {
    return filter.id;
  }
}
