import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Coach } from '@app/degreed-coach/models/coach.model';
import { CoachAPIService } from '@app/degreed-coach/services/coach-api.service';
import { ROUTER_CONSTANTS } from '@app/degreed-coach/shared/constants';
import { CoachInput } from '@app/inputs/inputs-api.model';
import { InlineInputGetter } from '@app/inputs/inputs.model';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { SharedModule } from '@app/shared/shared.module';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { lazySearch } from '@dg/shared-rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';

@Component({
  selector: 'dgx-coach-form',
  templateUrl: './coach-form.component.html',
  standalone: true,
  imports: [CommonModule, SharedModule],
  providers: [CoachAPIService, TranslatePipe],
})
export class CoachFormComponent implements OnInit {
  @Input() public inputId: number;
  @Input() public load: InlineInputGetter;
  @Input() public organizationId: number;
  @Input() public pathwayId: number;
  @Input() public sourceTarget;

  public coachForm = new FormGroup({
    coachName: new FormControl(undefined, [
      isEmptyValidator,
      Validators.required,
      Validators.maxLength(254),
    ]),
  });
  public disableForm: boolean = false;
  public i18n = this.translate.instant([
    'Coach_AddInputLabelText',
    'Coach_AddInputPlaceholder',
    'Coach_AddModalTitle',
    'Coach_AddRequiredName',
    'Coach_FormCtrlAreYouSure',
    'Core_Coach',
    'Core_FieldRequired',
    'Core_Loading',
    'Core_Save',
    'Core_YesSure',
    'Coach_AddCoverAltFormat',
  ]);
  public input: CoachInput;
  public isLoading = true;
  public isSubmitPending = false;
  public onSelectCoach = this.coachSelect.bind(this);
  public search = this.searchCoach.bind(this);
  public selectedCoach: Coach;

  constructor(
    private translate: TranslateService,
    private modalService: ModalService,
    private trackerService: TrackerService,
    private activeModal: NgbActiveModal,
    private focusStackService: FocusStackService,
    private coachService: CoachAPIService
  ) {}

  public get coachName() {
    return this.coachForm.get('coachName');
  }

  public get showTitleErrors() {
    return (
      this.coachName.errors && (this.coachName.dirty || this.coachName.touched)
    );
  }

  public get titleErrorMsg() {
    return this.i18n.Coach_AddRequiredName;
  }

  public get isSubmitDisabled() {
    return !this.coachName.valid || this.disableForm;
  }

  public ngOnInit(): void {
    this.input = {
      ...this.input,
      inputId: -1,
      inputType: 'Coach',
      title: '',
      description: '',
      details: {
        body: '',
        originalPathwayId: this.pathwayId,
      },
      imageUrl: '',
      organizationId: this.organizationId,
    };
  }

  public confirmationModal() {
    // Show confirmation modal on-dismiss if content was updated
    if (!this.coachName.dirty) {
      this.activeModal.dismiss();
      return;
    }

    const inputs = {
      canCancel: true,
      bodyClasses: 'h3 center-text',
      bodyText: this.i18n.Coach_FormCtrlAreYouSure,
      submitButtonText: this.i18n.Core_YesSure,
    };
    this.focusStackService.push(this.sourceTarget);
    return this.modalService
      .show(SimpleModalComponent, {
        inputs,
        errorOnDismiss: false,
      })
      .subscribe({
        next: () => {
          this.activeModal.dismiss();
        },
      });
  }

  public onSubmit() {
    const { coachName, shortDescription, avatar, coachId } = this.selectedCoach;
    Object.assign(this.input, {
      inputType: this.i18n.Core_Coach,
      title: coachName,
      description: shortDescription,
      imageUrl: avatar,
      url: `${ROUTER_CONSTANTS.HOME}/${coachId}`,
    });

    this.isSubmitPending = true;

    from(of(this.input)).subscribe({
      next: () => {
        this.activeModal.close(this.input);
        const postLocation = 'Create Coach Post Modal';
        this.trackerService.trackEventData({
          action: 'Create Coach Post Saved',
          properties: {
            Location: postLocation,
          },
        });
      },
      error: () => {
        this.isSubmitPending = false;
      },
    });
  }

  public searchCoach(term: Observable<string>): Observable<Coach[]> {
    return term.pipe(
      lazySearch((t) => this.coachService.getCoachList({ searchTitle: t }))
    );
  }

  public formatCoachResult(item: Coach) {
    return item.coachName;
  }

  public coachSelect(item: Coach) {
    this.selectedCoach = item;
  }
}
