<form class="form-wrap" (ngSubmit)="upload()">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="!isFileUploaded"
    [submitButtonText]="
      !isFileUploaded && onBackClick ? i18n.Core_Back : confirmButtonText
    "
    [useDefaultForm]="false"
    bodyClasses="guts-p-1"
    (dismiss)="
      !isFileUploaded && onBackClick
        ? onBackClick()
        : !isFileUploaded && !onBackClick
        ? cancel()
        : cancelProcessing()
    "
  >
    <ng-container class="modal-header" *ngIf="heading">
      {{ heading }}
    </ng-container>
    <ng-container class="modal-body">
      <div class="guts-p-full-1 form-wrap">
        <div *ngIf="description" class="guts-p-b-1 par--small">
          {{ description }}
        </div>
        <div class="guts-p-b-1">
          <button
            (click)="downloadTemplate()"
            class="font-medium link"
            data-dgat="orgSkillsBulkUpload-95c"
          >
            <df-icon
              icon="arrow-down-tray"
              size="medium"
              class="guts-m-r-half"
            ></df-icon>
            <span class="zeta">{{
              i18n.bulkUpdateModal_DownloadTemplate
            }}</span>
          </button>
        </div>
        <div>
          <dgx-file-uploader
            #fileUploader
            [showCancel]="true"
            [targetLabel]="i18n.bulkUpdateModal_UploadAFile"
            (successEvent)="preprocessingComplete($event)"
            [uploadApiEndpoint]="uploadTo"
            [fileTypes]="'.xls,.xlsx'"
            [sizeLimit]="2"
            [templateOption]="'compact'"
            [postObject]="postObject"
          ></dgx-file-uploader>
        </div>
        <div *ngIf="isFileUploaded" class="guts-p-t-2">
          <table class="data-table data-table--short">
            <tbody>
              <tr data-dgat="orgSkillsBulkUpload-row">
                <th scope="row">{{ i18n.bulkUpdateModal_FileName }}</th>
                <td class="right-text upload-overview__text">
                  {{ fileName }}
                </td>
              </tr>
              <tr data-dgat="orgSkillsBulkUpload-row">
                <th scope="row">{{ i18n.bulkUpdateModal_ExcelImported }}</th>
                <td class="right-text upload-overview__text">
                  {{ sheetName }}
                </td>
              </tr>
              <tr data-dgat="orgSkillsBulkUpload-row">
                <th scope="row">{{ i18n.bulkUpdateModal_RowsFound }}</th>
                <td class="right-text upload-overview__text">
                  {{ rowsFound }}
                </td>
              </tr>
              <tr data-dgat="orgSkillsBulkUpload-row">
                <th scope="row">{{ i18n.bulkUpdateModal_FirstRow }}</th>
                <td class="right-text upload-overview__text">
                  {{ firstItemValue }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="guts-p-t-2 font-medium zeta"
            data-dgat="orgSkillsBulkUpload-SuccessfulUpload"
          >
            {{ i18n.bulkUpdateModal_SuccessfulUpload }}
          </div>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>
