import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { CONSOLE } from '@app/shared/services/console.token';
import { HttpErrorService } from '@app/shared/services/http-error.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { DgError, ErrorType } from '../models/dg-error';

/**
 * An error handler that can be used anywhere with catchError, like so
 * (this will *automatically* toast the error, because by default DgError is
 * type=Toast):
 *
 * ```
 * .pipe(
 *   catchError((error) =>
 *     throwError(new DgError(this.translate('SomeErrorMessage'), error)))
 * );
 * ```
 *
 * If your observable has a callback for the error,
 * the throwError operator will be returned to the observer and will not be picked up
 * by the GlobalErrorHandlerService.
 */
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private httpErrorService: HttpErrorService,
    private injector: Injector,
    @Inject(CONSOLE) private consoleToken: any
  ) {}

  private get notifierService(): NotifierService {
    return this.injector.get(NotifierService);
  }

  /**
   * Handles errors differently depending on type.
   * **Currently defaults to toasting for DgErrors.**
   * Other errors will be logged to the console.
   */
  public handleError(error: any | DgError) {
    const errorUpdated = error?.rejection ? error.rejection : error;
    if (errorUpdated?.type === ErrorType.Toast ) {
      const status = errorUpdated?.innerError?.status as any;
      if (status) {
        this.httpErrorService.handle(errorUpdated);
      } else {
        this.handleToastErrors(errorUpdated);
      }
    }
    this.consoleToken.error(error); // always log console error
  }

  private handleToastErrors(error: DgError) {
    this.notifierService.showError(error.message || error.innerError?.message);
  }
}
