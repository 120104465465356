import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  EndorsementsViewModel,
  fileFromInput,
  UpdateImageFn,
} from '../data-service';
import { CARD_IMAGE, PLACEHOLDER_CARD } from './endorsements.images';

@Component({
  selector: 'dgx-org-branding-endorsements',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'tw-col-span-full tw-flex tw-flex-col tw-gap-y-8 lg:tw-col-start-2 lg:-tw-col-end-2',
  },
  template: `
    <header>
      <h3 class="tw-text-2xl tw-font-bold">Endorsements</h3>
      <p class="tw-text-sm tw-text-neutral-600">
        Add a custom visual to items that you'd like to highlight. All changes
        can be previewed in the examples below.
      </p>
    </header>

    <div
      class="tw-flex tw-flex-col tw-gap-x-4 tw-gap-y-10 md:tw-flex-row md:tw-justify-between"
    >
      <dgx-org-branding-uploader
        name="File Upload"
        [state]="endorsements.endorsement"
        (change)="endorsements.updateImage($event)"
      ></dgx-org-branding-uploader>

      <div>
        <div class="tw-mb-4">
          <label class="tw-text-sm tw-font-semibold">Preview - Card View</label>
        </div>

        <div
          class="tw-mb-2 tw-flex tw-h-[289px] tw-w-[252px] tw-flex-col tw-rounded-lg tw-border tw-border-neutral-300 tw-bg-white tw-p-3 tw-text-neutral-600"
        >
          <div class="tw-relative">
            <img [src]="cardImage" alt="" class="tw-w-full" />
            <img
              [src]="endorsements.endorsement.url || placeholderImage"
              alt=""
              class="tw-absolute tw-bottom-1.5 tw-left-1.5 tw-h-6"
            />
          </div>
          <div class="tw-px-3 tw-py-6">
            <span
              class="tw-neutral-600 tw-mb-[3px] tw-text-xs tw-leading-[18px]"
              >Article · 24 min · Degreed Meta</span
            >
            <p class="tw-text-sm tw-font-[500] tw-leading-[18px] tw-text-black">
              The Degreed Learning Index
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-gap-1.5">
            <button
              type="button"
              class="tw-inline-flex tw-h-6 tw-items-center tw-whitespace-nowrap tw-rounded-xl tw-border tw-border-neutral-200 tw-bg-white tw-px-[13px] tw-text-xs tw-font-semibold tw-leading-[18px] tw-text-neutral-600"
            >
              Mark Complete
            </button>
            <button
              type="button"
              class="tw-inline-flex tw-h-6 tw-items-center tw-whitespace-nowrap tw-rounded-xl tw-border tw-border-neutral-200 tw-bg-white tw-px-[13px] tw-text-xs tw-font-semibold tw-leading-[18px] tw-text-neutral-600"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon-info-12" clip-path="url(#clip0_3816_10437)">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM6 5C5.44772 5 5 5.44772 5 6V9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9V6C7 5.44772 6.55228 5 6 5ZM6 4C6.55228 4 7 3.55228 7 3C7 2.44772 6.55228 2 6 2C5.44772 2 5 2.44772 5 3C5 3.55228 5.44772 4 6 4Z"
                    fill="#0F1F2C"
                    fill-opacity="0.61"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3816_10437">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <span class="tw-grow"></span>
            <button
              type="button"
              class="tw-h-6 tw-whitespace-nowrap tw-px-1.5 tw-text-xs tw-font-semibold tw-leading-[18px] tw-text-neutral-600"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 7.5C5.17157 7.5 4.5 6.82843 4.5 6C4.5 5.17157 5.17157 4.5 6 4.5C6.82843 4.5 7.5 5.17157 7.5 6C7.5 6.82843 6.82843 7.5 6 7.5ZM10.5 7.5C9.67157 7.5 9 6.82843 9 6C9 5.17157 9.67157 4.5 10.5 4.5C11.3284 4.5 12 5.17157 12 6C12 6.82843 11.3284 7.5 10.5 7.5ZM1.5 7.5C0.671573 7.5 0 6.82843 0 6C0 5.17157 0.671573 4.5 1.5 4.5C2.32843 4.5 3 5.17157 3 6C3 6.82843 2.32843 7.5 1.5 7.5Z"
                  fill="#0F1F2C"
                  fill-opacity="0.61"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="tw-mb-4">
        <label class="tw-text-sm tw-font-semibold">Preview - List View</label>
      </div>

      <div
        class="tw-flex tw-gap-3 tw-overflow-hidden tw-rounded-md tw-border tw-border-neutral-200 tw-bg-white tw-p-[18px] sm:tw-gap-6"
      >
        <div class="tw-flex tw-shrink-0 tw-items-center">
          <img [src]="cardImage" alt="" class="tw-w-[103px]" />
        </div>
        <div class="tw-space-y-3.5 tw-overflow-hidden">
          <div
            class="tw-flex tw-items-center tw-gap-[18px] tw-text-xs tw-leading-[18px] tw-text-neutral-600"
          >
            <img
              [src]="endorsements.endorsement.url || placeholderImage"
              alt=""
              class="tw-h-6"
            />
            <span>Type</span>
            <span>Duration</span>
            <span>Source</span>
            <span>Other</span>
          </div>
          <h3 class="tw-truncate tw-font-semibold tw-text-blue-800">
            Title goes here and just in case it's long it can stretch out pretty
            far and not be cut off until you...
          </h3>
          <p
            class="tw-line-clamp-2 tw-text-xs tw-leading-[18px] tw-text-neutral-600"
          >
            A description for the content goes here. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco...
          </p>
          <div class="tw-flex tw-items-center tw-gap-1.5">
            <button
              type="button"
              class="tw-inline-flex tw-h-6 tw-items-center tw-whitespace-nowrap tw-rounded-xl tw-border tw-border-neutral-200 tw-bg-white tw-px-[13px] tw-text-xs tw-font-semibold tw-leading-[18px] tw-text-neutral-600"
            >
              Mark Complete
            </button>
            <button
              type="button"
              class="tw-inline-flex tw-h-6 tw-items-center tw-whitespace-nowrap tw-rounded-xl tw-border tw-border-neutral-200 tw-bg-white tw-px-[13px] tw-text-xs tw-font-semibold tw-leading-[18px] tw-text-neutral-600"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon-info-12" clip-path="url(#clip0_3816_10437)">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM6 5C5.44772 5 5 5.44772 5 6V9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9V6C7 5.44772 6.55228 5 6 5ZM6 4C6.55228 4 7 3.55228 7 3C7 2.44772 6.55228 2 6 2C5.44772 2 5 2.44772 5 3C5 3.55228 5.44772 4 6 4Z"
                    fill="#0F1F2C"
                    fill-opacity="0.61"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3816_10437">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <span class="tw-grow"></span>
            <button
              type="button"
              class="tw-h-6 tw-whitespace-nowrap tw-px-1.5 tw-text-xs tw-font-semibold tw-leading-[18px] tw-text-neutral-600"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 7.5C5.17157 7.5 4.5 6.82843 4.5 6C4.5 5.17157 5.17157 4.5 6 4.5C6.82843 4.5 7.5 5.17157 7.5 6C7.5 6.82843 6.82843 7.5 6 7.5ZM10.5 7.5C9.67157 7.5 9 6.82843 9 6C9 5.17157 9.67157 4.5 10.5 4.5C11.3284 4.5 12 5.17157 12 6C12 6.82843 11.3284 7.5 10.5 7.5ZM1.5 7.5C0.671573 7.5 0 6.82843 0 6C0 5.17157 0.671573 4.5 1.5 4.5C2.32843 4.5 3 5.17157 3 6C3 6.82843 2.32843 7.5 1.5 7.5Z"
                  fill="#0F1F2C"
                  fill-opacity="0.61"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class OrgBrandingEndorsementsComponent {
  fileFromInput = fileFromInput;
  placeholderImage = PLACEHOLDER_CARD;
  cardImage = CARD_IMAGE;

  @Input() endorsements: EndorsementsViewModel;
}
