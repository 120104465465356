import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

// Services
import { ReportingService } from '@app/insights/services/reporting.service';
import {
  RecommendationsService,
  RecommendationType,
} from '@app/recommendations/services/recommendations.service';
import { TeamService } from '@app/team/services/team.service';
import { TrackerService } from '@app/shared/services/tracker.service';

// Components
import { HeadingMetaOption } from '@app/shared/components/modal/modal-header/modal-header.component';

// Misc
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { tap } from 'rxjs/operators';
import { RecommendationUserInsights } from '@app/recommendations/recommendations.api';
import { RecommendationUsers } from '@app/insights/insights-api.model';

@Component({
  selector: 'dgx-recommendations-detail-modal',
  templateUrl: './recommendations-detail-modal.component.html',
})
export class RecommendationsDetailModalComponent implements OnInit {
  @Input() public isSkillCoach: boolean;
  @Input() public recommendation;
  @Input() public dateCreated: Date;
  // Optional Inputs
  @Input() public organizationId?: number;
  @Input() public managerProfileKey?: number;
  @Input() public includeFullManagerHierarchy?: boolean = false;
  @Input() public isUserActivity?: boolean = false;

  public assignedUsersInfo: RecommendationUserInsights | RecommendationUsers;
  public headingMetaConfig: HeadingMetaOption[];
  public title: string;

  public titleEllipsisLimit = 70;
  public i18n = this.translate.instant([
    'Core_AnonymousLearners',
    'Core_AnonymousLearner',
  ]);

  public showAnonymous = false;

  constructor(
    private activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private displayTypePipe: DisplayTypePipe,
    private recommendationsService: RecommendationsService,
    private reportingSvc: ReportingService,
    private teamService: TeamService,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    const referenceInfo = this.recommendation.referenceInfo
      ? this.recommendation.referenceInfo
      : this.recommendation.reference;
    const referenceType = referenceInfo.skillRatingTypeLabel
      ? this.displayTypePipe.transform(
          this.recommendation.referenceType ?? referenceInfo.resourceType
        )
      : '';
    this.title = this.recommendation.title ?? referenceInfo.title;
    this.headingMetaConfig = referenceType
      ? [
          {
            class: '',
            label: referenceType,
          },
        ]
      : [];
    if (this.recommendation.dateDue) {
      // With the datePipe, we don't have locale support for dates
      const formattedDate = this.datePipe.transform(
        this.recommendation.dateDue,
        'mediumDate'
      );
      const dueDate = this.translate.instant(
        'recommendationDetail_DueDateFormat',
        {
          dueDate: formattedDate,
        }
      );
      this.headingMetaConfig.push({
        class: 'due-date',
        label: dueDate,
      } as HeadingMetaOption);
    }
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  // TODO: Seems like this can be refactored in a way that is more clear than the if/else-if/else flow
  public loadUsers(event: { skip: number; count: number }): any {
    if (this.isSkillCoach) {
      const referenceInfo = this.recommendation.referenceInfo;
      const dateCreated =
        this.recommendation.dateCreated ?? referenceInfo.dateCreated;
      const assignedByUpk = this.recommendation.userProfileKey;
      const dueDate = this.recommendation.dateDue ?? referenceInfo.dateDue;
      const recommendationAction = this.recommendation.action;

      this.teamService
        .getRecommendationUsers(
          this.organizationId,
          referenceInfo.resourceId,
          referenceInfo.resourceType,
          dueDate,
          dateCreated,
          recommendationAction,
          event.count,
          event.skip,
          assignedByUpk,
          this.managerProfileKey,
          this.includeFullManagerHierarchy
        )
        .pipe(
          tap((response: RecommendationUserInsights | RecommendationUsers) =>
            this.loadUsersSharedResponse(response)
          )
        )
        .subscribe();
    } else if (this.isUserActivity) {
      this.recommendationsService
        .getUserStatusByRecommendation({
          recommendationId: this.recommendation.recommendationId,
          recommendationType: this.recommendation.recommendationType,
          skip: event.skip,
          take: event.count,
        })
        .pipe(
          tap((response: RecommendationUserInsights | RecommendationUsers) =>
            this.loadUsersSharedResponse(response)
          )
        )
        .subscribe();
    } else {
      this.reportingSvc
        .GetGroupRecommendationUsers(
          this.organizationId,
          this.recommendation.recommendationId,
          this.recommendation.groupId,
          event.count,
          event.skip
        )
        .pipe(
          tap((response: RecommendationUserInsights | RecommendationUsers) =>
            this.loadUsersSharedResponse(response)
          )
        )
        .subscribe();
    }
  }

  public loadUsersSharedResponse(
    response: RecommendationUserInsights | RecommendationUsers
  ) {
    this.assignedUsersInfo = response;
    this.showAnonymous = this.recommendationsService.hasAnonymous(response);
  }
}
