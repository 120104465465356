import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

// services
import { TranslateService } from '@ngx-translate/core';

// misc
import {
  durationHoursValidator,
  durationMinuteValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import { Key, isKey } from '@app/shared/key';

@Component({
  selector: 'dgx-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DurationComponent implements OnInit {
  @Input() public formModel: { durationHours: number; durationMinutes: number };
  @Input() public isReadOnly: boolean;

  public durationForm: FormGroup;
  public i18n = this.translate.instant(['Core_Hours', 'Core_Minutes']);
  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public parentForm: FormGroupDirective,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.initializeForm();
    if (this.formModel) {
      this.durationForm.patchValue({
        durationHours: this.formModel.durationHours,
        durationMinutes: this.formModel.durationMinutes,
      });
    }
  }

  /**
   * Marks touch status for validation
   */
  public onBlur(field: string) {
    // also mark the field a touched
    const formField = this.durationForm.get(field);
    formField.markAsDirty();
    formField.markAsTouched();
  }

  /**
   * Exclude e in the number input
   * @param event
   */
  public excludeCharacters(event: KeyboardEvent) {
    if (isKey(event, Key.e)) {
      event.preventDefault();
    }
    this.durationForm.markAsDirty();
  }

  private initializeForm() {
    const durationFormGroup = this.formBuilder.group({
      durationHours: ['', [durationHoursValidator(this.translate)]],
      durationMinutes: ['', [durationMinuteValidator(this.translate)]],
    });
    this.parentForm.form.addControl('durationForm', durationFormGroup);
    this.durationForm = durationFormGroup;

    this.cdr.markForCheck();
  }
}
