import { XAxisInterval } from './insights-api.model';

/**
 * A method specifically for handling certain date labels (charts, insights). Uses JS's
 * built-in locale methods in conjunction with our user locale string to return months
 * and dates formatted accordingly.
 *
 * Can handle any of three formats:
 * - MMM (month name)
 * - MM/dd/yyyy (zero-padded and days)
 * - M/d/yyyy (non-zero-padded)
 *
 * @param labels - Array of date strings to be formatted. The XAxisLabels on a chart.
 * @param interval - Type of date strings. The XAxisInterval.
 * @param userLocale - The current locale. Grab from LocalizationService.
 * @param dateOptions - Object of options for dates.
 * @param monthOptions - Object of options for month names.
 */
export function formatInsightsDateLabels(
  labels: string[],
  interval: XAxisInterval = 'Week',
  userLocale = 'en-US',
  dateOptions: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    day: 'numeric',
  },
  monthOptions: Intl.DateTimeFormatOptions = { month: 'short' }
): string[] {
  // Future-proof: Just in case. If these aren't strings, bail out and return the original data.
  if (!labels?.length || typeof labels[0] !== 'string') {
    return labels;
  }
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
  let dateTimeFormat = new Intl.DateTimeFormat(userLocale, dateOptions);
  let formattedDates: string[];
  switch (interval) {
    case 'Month':
      dateTimeFormat = new Intl.DateTimeFormat(userLocale, monthOptions);
      formattedDates = labels.map((monthName) =>
        dateTimeFormat.format(getDateFromMonthName(monthName))
      );
      break;
    case 'Day':
      formattedDates = labels.map((dateString) =>
        dateTimeFormat.format(getDateFromString(dateString))
      );
      break;
    default:
      // TODO: When we next update our JavaScript "target", replace this with:
      // return labels.map((dateString) => {
      //   const [dateStart, dateEnd] = dateString.split('-');
      //   // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatRange
      //   return dateTimeFormat.formatRange(getDateFromString(dateStart), getDateFromString(dateEnd));
      // })
      formattedDates = labels.map((dateString) => {
        const [dateStart, dateEnd] = dateString.split('-');
        return (
          dateTimeFormat.format(getDateFromString(dateStart)) +
          ' - ' +
          dateTimeFormat.format(getDateFromString(dateEnd))
        );
      });
  }
  // Return formatted dates
  return formattedDates;
}

/**
 * Split a date string on `/`, then format it into a Date object.
 * MUST be in either: MM/dd(/yyyy) or M/d(/yyyy) format. If missing
 * the year, substitutes in the current year.
 *
 * @param dateStr
 */
function getDateFromString(dateStr: string): Date {
  // sanity check
  if (!dateStr) {
    return new Date();
  }
  // expected format: mm/dd/yyyy
  const dateParts = dateStr.split('/');
  const month = Number(dateParts[0]) - 1; // for whatever reason, months are zero-indexed in Date objects.
  const day = Number(dateParts[1]);
  const year = dateParts[2] ? Number(dateParts[2]) : new Date().getFullYear();
  return new Date(year, month, day);
}

/**
 * Get a date object with an arbitrary day and year from a month name.
 * Useful for turning a string like 'Oct' into a localized string like
 * 'Okt' or '10月'.
 *
 * @param monthName
 */
function getDateFromMonthName(monthName: string): Date {
  // sanity check
  if (!monthName) {
    return new Date();
  }
  // expected format: MMM (short month) (using the en-US abbreviation for the month)
  // By adding an arbitrary year and day onto this, we create the kind of string
  // JavaScript expects. We only care about the month anyway!
  return new Date(Date.parse(monthName + ' 1, 2023'));
}
