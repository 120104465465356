import { Injectable, Inject } from '@angular/core';
import { InputsFacadeBase } from '@app/user-content/services/inputs-facade-base';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { AuthService } from '@app/shared/services/auth.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputContext, RenderMode } from '../user-input.model';
import { SelectBookRendererService } from './renderers/select-book-renderer.service';
import { BookApiEntity } from './repository/book.entity.model';
import { BookFormModel } from './book-forms.model';
import { Observable, of } from 'rxjs';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { Book } from '@app/inputs/inputs-api.model';
import { GoogleBooksService } from '@app/shared/services/google-books.service';
import { lazySearch } from '@dg/shared-rxjs';
import { BookInfoRendererService } from './renderers/book-info-renderer.service';
import { RendererContext } from '../form-renderer.model';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { TipService } from '@app/onboarding/services/tip.service';

@Injectable({ providedIn: 'any' })
export class BookFacade extends InputsFacadeBase<BookFormModel, BookApiEntity> {
  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: BookApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    builder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    protected translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    commentsApiService: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    tipService: TipService,
    private selectBookRenderer: SelectBookRendererService,
    private bookInfoRenderer: BookInfoRendererService,
    private booksService: GoogleBooksService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      builder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      commentsApiService,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }

  protected get extendedDefaultViewModel(): Partial<BookFormModel> {
    const modalTitle = this.translate.instant(
      this.inputContext.isEditing
        ? 'BookFormCtrl_EditBook'
        : 'BookFormCtrl_AddBook'
    );

    return {
      book: undefined,
      formTitle: modalTitle,
      submitButtonText: this.translate.instant('BookFormCtrl_SaveBook'),
      inputType: 'Book',
      shouldShowSubmitButton$: of(this.isEditing),
      completionInfo: {
        length: undefined,
        dateRead: undefined,
      },
      authUser: this.authService.authUser,
      // Fix to prevent orgIds from being added to Pathway Books
      // TODO: Will add a better solution after we determine what content in pathways need/do not need org Id [PD-74326]
      organizationId: this.orgId,

      // template configuration
      formFields: {
        titleSuggest: {
          onSelectBook: (result: Book) => {
            this.viewModel.book = result;
            this.viewModel.shouldShowSubmitButton$ = of(true);
            this.viewModel.completionInfo.length = result.durationUnits;

            this.updateUIConfiguration();
          },
          formatBookResult: this.formatBookResult,
          searchTitle: this.searchTitle,
        },
      },
    };
  }

  protected buildUIConfiguration(): DfFormFieldConfig<DfTemplateOptions>[] {
    const context: RendererContext = {
      inputContext: {
        ...this.inputContext,
        inputType: 'Book',
        isCompleting: this.isCompleting,
      },
      state: () => this.viewModel,
      templates: this.templates,
    };

    switch (this.inputContext.renderMode) {
      case RenderMode.UserProfile:
      case RenderMode.Pathways:
        if (!this.viewModel.book) {
          return this.selectBookRenderer.render(context);
        } else {
          return this.bookInfoRenderer.render(context);
        }
      default:
        throw new Error(
          `Book Facade - No render mode defined for ${this.inputContext.renderMode}`
        );
    }
  }

  // template helpers
  private searchTitle: TypeaheadSearchFunction<string, Partial<Book>> = (
    term: Observable<string>
  ): Observable<readonly Partial<Book>[]> => {
    return term.pipe(lazySearch((t) => this.booksService.find(t)));
  };

  private formatBookResult(book: Book) {
    return book.title;
  }
}
