<header
  *ngIf="!isInitializing && showHeader"
  class="l_header product-header js-app-header"
  [class.product-header--dark]="brandColor && hasLightText"
  [class.product-header--light]="brandColor && !hasLightText"
  [style.background-color]="brandColor"
  data-dgat="app-header-a12"
>
  <div class="l_flexbar header-wrap align-center">
    <div
      class="product-header__logos guts-p-h-1"
      *ngIf="!shouldHideHomeLogo"
      #logos
    >
      <!-- Logo/Home -->
      <div class="inline" [class.isMobile]="isMobile">
        <button
          [attr.role]="isMobile ? 'button' : 'link'"
          class="ib product-header__logo--link js-appLogo-clicktarget"
          (click)="onLogoClick($event)"
          [attr.aria-haspopup]="isMobile"
          [attr.aria-expanded]="isMobile ? navOpen : undefined"
          data-dgat="product-header-5f2"
        >
          <img
            [src]="productImageSrc.orig | blobifyUrl: true"
            [srcset]="productImageSrcValue"
            alt="Degreed"
            class="{{
              isMobile
                ? 'js-appLogo-clicktarget product-header__logo-img product-header__logo m-guts-p-r-1'
                : 'js-appLogo-clicktarget product-header__logo-img product-header__logo'
            }}"
            role="img"
            *ngIf="!isDegreedLearnerHome"
            (load)="handleImageLoad()"
          />
          <span
            *ngIf="showBrandLogo && !isMobile && !isDegreedLearnerHome"
            class="vertical-divider guts-m-h-2"
          ></span>

          <img
            *ngIf="showBrandLogo && !isMobile"
            [src]="brandLogoSrcset.retina"
            attr.srcset="{{ brandLogoSrcset.orig }} 1x,
                {{ brandLogoSrcset.retina }} 2x"
            [alt]="brandName"
            (load)="handleImageLoad()"
            class="js-appLogo-clicktarget product-header__logo-img product-header__client-logo-img"
          />
          <df-icon
            icon="chevron-down"
            size="medium"
            *ngIf="isMobile"
            class="product-header__logo-df-icon"
          ></df-icon>
        </button>
      </div>
    </div>
    <!-- Centered org image -->
    <div
      class="l_flex-one l_flex--inline l_flex-justify-center"
      *ngIf="!shouldHideCenterOrgLogo"
    >
      <img
        *ngIf="showBrandLogo"
        [src]="brandLogoSrcset.retina"
        attr.srcset="{{ brandLogoSrcset.orig }} 1x,
          {{ brandLogoSrcset.retina }} 2x"
        [alt]="brandName"
        class="product-header__logo-img product-header__client-logo-img"
      />
      <img
        [src]="productImageSrc.orig | blobifyUrl: true"
        [srcset]="productImageSrcValue"
        alt="Degreed"
        class="product-header__logo-img product-header__logo"
        role="img"
        *ngIf="!showBrandLogo"
      />
    </div>
    <div
      class="guts-m-r-3"
      *ngIf="
        !shouldHideCenterOrgLogo && ldFlagsService.skills.july2024MajorRelease
      "
    >
      <ng-container *ngIf="data$ | async as data">
        <label for="language" class="form-label"></label>
        <dgx-select
          id="language"
          class="full-width"
          trackBy="lcid"
          labelKey="name"
          [selectedItem]="data.personalSettings.localeId || DEFAULT_LANGUAGE"
          [options]="data.allLanguages"
          (selection)="onLanguageSelection($event)"
          dgatInput="product-header-195"
        >
        </dgx-select>
      </ng-container>
    </div>
    <!-- Other Sections, Per Permissions -->
    <div
      class="product-header__nav guts-p-l-0"
      [ngClass]="{
        'product-header__nav--dropdown': isMobile,
        'product-header__nav--dark': hasLightText && !isMobile,
      }"
      *ngIf="(!isMobile || navOpen) && navLinks?.length > 0"
      [@dfCollapseExpand]
      [@.disabled]="!isMobile"
      [@dfCollapseExpand]
    >
      <nav [attr.aria-label]="'A11y_MainPagesNavType' | translate">
        <ul class="inline-list">
          <ng-container
            *ngFor="let link of navLinks"
            data-dgat="product-header-e52"
          >
            <li
              *ngIf="link.isVisible() && !useNgxRouting"
              class="tabnav__item guts-p-r-half"
              [class.is_active]="isActiveLink(link)"
            >
              <a
                #navigationLinks
                class="tabnav__link"
                [attr.data-dgat]="link.dgat"
                (click)="onClickNavItem($event, link.href, link.title)"
                [href]="link.href"
                [attr.aria-current]="isActiveLink(link)"
              >
                {{ link.title }}
              </a>
            </li>

            <li
              *ngIf="link.isVisible() && useNgxRouting"
              class="tabnav__item guts-p-r-half"
              routerLinkActive="is_active"
            >
              <a
                #navigationLinks
                [routerLink]="link.routerLink"
                routerLinkActive="is_active"
                class="tabnav__link"
                [attr.data-dgat]="link.dgat"
                tabindex="0"
              >
                {{ link.title }}
              </a>
            </li>
          </ng-container>
        </ul>
      </nav>
    </div>

    <div class="l_flex-grow" *ngIf="!shouldHideUtilityBar"></div>

    <dgx-utility-bar
      #utilityBar
      *ngIf="!shouldHideUtilityBar"
      [shouldHideGlobalSearch]="shouldHideGlobalSearch"
      [shouldHideGlobalAdd]="shouldHideGlobalAdd"
      [shouldHideNotifications]="shouldHideNotifications"
      [hasLightText]="hasLightText"
      [orgId]="orgId"
    ></dgx-utility-bar>
    <div class="utility-bar-item" *ngIf="!hideProductSwitcher">
      <dgx-product-switcher
        [hasLightText]="hasLightText"
      ></dgx-product-switcher>
    </div>
  </div>
</header>
