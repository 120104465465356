import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuViewModel } from './../menu/menu.component';
import { TrackerService } from '@app/shared/services/tracker.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FlexEdService } from '@app/flex-ed/services/flex-ed.service';
import { FlexedService } from '@app/shared/services/flexed.service';
import { OrgHelpMenuService } from '@app/orgs/services/org-help-menu.service';
import { OrganizationSupportInfo } from '@app/orgs/services/orgs.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { DfPlacement, DfPlacementArray } from '@lib/fresco';
import { LDFlagsService } from '@dg/shared-services';

export interface ProfileMenuViewModel extends MenuViewModel {
  href?: string;
  isNewWindow?: boolean;
}

/**
 * Component used to display the user's profile picture as a dropdown and display links/information in the product header
 */
@Component({
  selector: 'dgx-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public authUser: AuthUser;
  public canViewManageOrgLink: boolean;
  public hasChannel: boolean;
  public isConsumerUser = false;
  public isMenuOpen: boolean = false;
  public isSkillAnalyticsClient: boolean;
  public isSkillInventoryClient: boolean;
  public orgInfo: OrgInfo[];
  public supportInfo: OrganizationSupportInfo;
  public supportInfoSubject = this.helpMenu.orgSupportInfo$;
  public useContentLanguage =
    this.ldFlagsService.showAdditionalContentLanguages;

  public i18n = this.translate.instant([
    'dgUserOverview_Profile',
    'dgUserOverview_Settings',
    'Core_LanguageSettings',
    'dgUserOverview_ConnectFlexedCard',
    'FeedbackPartial_KnowledgeCenter',
    'FeedbackPartial_PrivacyPolicy',
    'OrgSettings_ShowCookiePolicyLink_Name',
    'FeedbackPartial_OSSLicense',
    'OrgSettings_OrgSupportInfoFaq_Name',
    'dgUserOverview_Logout',
  ]);

  @Input() public placement: DfPlacement | DfPlacementArray = 'auto';
  @Input() public isAppendToBody: boolean = false;
  @Input() public hasLightText: boolean;
  @Input() public orgId?: number;

  @ViewChild('menuTrigger') public menuTrigger: ElementRef;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private trackerService: TrackerService,
    private flexEdService: FlexEdService,
    private flexedService: FlexedService,
    private translate: TranslateService,
    private focusStackService: FocusStackService,
    private helpMenu: OrgHelpMenuService,
    private ldFlagsService: LDFlagsService
  ) {
    super();
  }

  /**
   * Get the options to display in the profile dropdown
   */
  public get menuConfig() {
    const menuConfig: ProfileMenuViewModel[] = [
      {
        // profile
        defaultAction: () => {
          this.trackItemClicked('my-profile');
        },
        title: this.i18n.dgUserOverview_Profile,
        id: 'profile',
        href: this.navigationService.userProfileUrl,
      },
      {
        // profile settings
        defaultAction: () => {
          this.trackItemClicked('profile-settings');
        },
        title: this.i18n.dgUserOverview_Settings,
        id: 'profile-settings',
        href: `/profile/${this.authUser?.viewerProfile.vanityUrl}/settings/profile`,
      },
      {
        // Language Settings
        isHidden: () => !this.useContentLanguage,
        defaultAction: () => {
          this.trackItemClicked('language-settings');
        },
        title: this.i18n.Core_LanguageSettings,
        id: 'language-settings',
        href: `/${this.authUser?.viewerProfile.vanityUrl}/settings/account`,
      },
      {
        // connect flex ed
        isHidden: () => !(!this.authUser?.isPexUser && this.authUser.isPexOrg),
        defaultAction: (event, menuTrigger) => {
          this.trackItemClicked('flexEd');
          this.focusStackService.push(menuTrigger.nativeElement);
          this.openFlexEdModal();
        },
        title: this.i18n.dgUserOverview_ConnectFlexedCard,
        preventRefocus: true,
        id: 'flex-ed',
      },
    ];

    if (this.supportInfo?.helpLink) {
      // knowledge center
      menuConfig.push({
        defaultAction: () => {
          this.trackItemClicked('knowledge-center');
        },
        title: this.i18n.FeedbackPartial_KnowledgeCenter,
        isSeparated: true,
        id: 'kcHelpLink',
        href: this.supportInfo.helpLink,
        isNewWindow: true,
      });
    }

    // privacy policy
    menuConfig.push({
      defaultAction: () => {
        this.trackItemClicked('privacy-policy');
      },
      title: this.i18n.FeedbackPartial_PrivacyPolicy,
      isSeparated: false,
      id: 'kcPrivacyPolicy',
      href: '/about/privacy',
      isNewWindow: true,
    });

    // Cookie Policy
    if (this.supportInfo?.showCookieLink) {
      menuConfig.push({
        defaultAction: () => {
          this.trackItemClicked('cookie-policy');
        },
        title: this.i18n.OrgSettings_ShowCookiePolicyLink_Name,
        id: 'cookiePolicy',

        // This will be used with new cookie notice page - enabled with PD-108068
        // href: `/${this.authUser?.viewerProfile.vanityUrl}/about/cookie-notice`,
        // isNewWindow: false,

        href: '/api/about/cookienotice',
        isNewWindow: true,
      });
    }

    // FAQ
    if (this.supportInfo?.faq) {
      menuConfig.push({
        defaultAction: () => {
          this.trackItemClicked('faq');
        },
        title: this.i18n.OrgSettings_OrgSupportInfoFaq_Name,
        isSeparated: true,
        id: 'kcFAQ',
        href: this.supportInfo?.faq,
        isNewWindow: true,
      });
    }

    // custom Link
    if (this.supportInfo?.customLink) {
      menuConfig.push({
        defaultAction: () => {
          this.trackItemClicked('custom-help-link');
        },
        title: this.supportInfo?.customText,
        isSeparated: !this.supportInfo?.faq,
        id: 'kcCustomLink',
        href: this.supportInfo?.customLink,
        isNewWindow: true,
      });
    }

    // support phone number
    if (this.supportInfo?.phone) {
      menuConfig.push({
        defaultAction: () => {
          this.trackItemClicked('support-phone');
        },
        title: this.isConsumerUser ? '800.311.7061' : this.supportInfo.phone,
        isSeparated: true,
        id: 'kcPhone',
        icon: 'phone',
        href: 'tel:' + this.supportInfo.phone,
      });
    }

    // support email; if set AND has not had a degreed address manually entered
    if (
      this.supportInfo?.email &&
      !this.supportInfo?.email.includes('@degreed.com')
    ) {
      menuConfig.push({
        defaultAction: () => {
          this.trackItemClicked('support-email');
        },
        title: this.supportInfo?.email,
        isSeparated: false,
        id: 'kcEmail',
        icon: 'envelope',
        href: 'mailto:' + this.supportInfo?.email,
      });
    }

    // logout
    menuConfig.push({
      defaultAction: () => {
        this.trackItemClicked('logout');
        this.authService.clearAuth();
      },
      title: this.i18n.dgUserOverview_Logout,
      isSeparated: true,
      id: 'logout',
      href: '/account/logoff',
    });

    return menuConfig;
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.orgInfo = this.authUser?.orgInfo;
    this.canViewManageOrgLink = this.authUser?.canManageOrganization;
    this.isSkillInventoryClient = this.authUser?.isSkillInventoryClient;
    this.isSkillAnalyticsClient = this.authUser?.isSkillAnalyticsClient;
    this.hasChannel = this.authUser?.hasChannel;

    this.getFlexCardDetails();

    this.orgId = this.orgId ?? this.authUser.defaultOrgId; // use the binding, otherwise try to use default org id

    if (!this.orgId) {
      // logged in user does not belong to an organization, populate support info with defaults
      this.isConsumerUser = true;
      this.supportInfo = {
        phone: '18003117061',
      };
    } else {
      this.supportInfoSubject
        .pipe(this.takeUntilDestroyed())
        .subscribe((supportInfo) => {
          this.supportInfo = supportInfo;
        });

      this.helpMenu.getSupportInfo(this.orgId);
    }
  }

  /**
   * Click handler for tracking when the avatar menu is opened
   *
   * @param isOpen - whether the avatar menu is open or closed
   */
  public avatarMenuViewed(isOpen: boolean) {
    if (isOpen) {
      this.trackerService.trackEventData({
        action: 'Avatar Menu Viewed',
        category: 'User',
        label: '',
        properties: {
          organizationCount: this.orgInfo?.length,
        },
      });
    }
  }

  public toggleOpen() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public handleItemClick(event, item, menuTrigger = null) {
    this.isMenuOpen = false;
    item.defaultAction(event, menuTrigger);
  }

  public trackById(item) {
    return item.id;
  }

  public isVisible(item: MenuViewModel) {
    return item.isHidden === undefined || !item.isHidden();
  }

  /**
   *
   * @param item - the item that is being tracked
   * @param id - the id of the item being tracked.  Currently this is only used to pass in the organization id
   */
  private trackItemClicked(item: string, id?): boolean {
    const props: {
      itemClicked: string;
      organizationIdClicked?: string;
      skillIdClicked?: string;
    } = { itemClicked: item };
    if (item === 'manage-org') {
      props.organizationIdClicked = id;
    }
    this.trackerService.trackEventData({
      action: 'Avatar Menu Item Clicked',
      category: '',
      label: '',
      properties: props,
    });
    return true;
  }

  /**
   * display the flex ed modal to link a card
   */
  private openFlexEdModal() {
    this.flexEdService
      .showUpdateFlexCardModal({
        orgId: this.authUser.defaultOrgId,
      })
      .subscribe();
  }

  /**
   * determine if the current user has already configured their flex ed card
   */
  private getFlexCardDetails() {
    this.authUser.isPexOrg = this.authUser?.defaultOrgInfo?.hasPex;
    if (this.authUser.isPexOrg) {
      this.flexedService
        .getCardDetails(this.authUser.viewerProfile.userProfileKey, true)
        .subscribe(
          (response) => {
            this.authUser.isPexUser = !!response?.card;
          },
          (error) => {
            this.authUser.isPexOrg = error?.isPexOrg;
            this.authUser.isPexUser = error?.isPexUser;
          }
        );
    }
  }
}
