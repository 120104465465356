<div class="l_flexbar">
  <button
    class="btn btn-primary split-button__primary-action"
    [ngClass]="{ 'split-button--disabled': isDisabled }"
    type="button"
    #splitButtonTrigger
    (click)="buttonAction($event)"
    data-dgat="split-button-8f9"
    [disabled]="isDisabled"
  >
    <df-icon *ngIf="icon" icon="{{ icon }}" class="guts-m-r-half"></df-icon>
    <span class="ib-v-middle" [ngClass]="{ 'guts-m-l-quart': icon }">{{
      displayText
    }}</span>
  </button>
  <div
    #ngbDropdownRef="ngbDropdown"
    [attr.aria-label]="ariaLabel"
    class="dropdown"
    ngbDropdown
    placement="bottom-right"
    role="group"
  >
    <button
      class="btn btn-primary split-button__secondary-action"
      [ngClass]="{ 'split-button--disabled': isDisabled }"
      type="button"
      data-dgat="split-button-1ad"
      ngbDropdownToggle
      [disabled]="isDisabled"
    >
      <df-icon icon="chevron-down"></df-icon>
    </button>
    <ul
      class="dropdown-menu dropdown-menu__arrow rounded guts-m-t-1"
      [ngClass]="{
        'dropdown-menu-right arrow--hard-right':
          dropdownPosition === 'bottom-right'
      }"
      ngbDropdownMenu
      role="menu"
    >
      <ng-content></ng-content>
    </ul>
  </div>
</div>
