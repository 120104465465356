import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { OrgSettingBase } from '@app/orgs/OrgSettingBase';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';

@Component({
  selector: 'dgx-setting-tile-toggle-text',
  templateUrl: './setting-tile-toggle-text.component.html',
})
export class SettingTileToggleTextComponent extends OrgSettingBase {
  public settingLabel: string;
  public settingPlaceholder: string;

  constructor(formBuilder: FormBuilder, orgSettings: OrgSettingsService) {
    super(formBuilder, orgSettings);
  }

  public handleSave() {
    this.setting.enabled = this.settingEnabled;
    this.setting.value = this.settingForm.get(this.setting.id).value;
    this.settingSaved.emit(this.setting);
    this.settingForm.markAsPristine();
    this.setFocusToForm();
  }

  protected resetFormToDefault() {
    const defaultValue = {} as any;
    defaultValue[this.setting.id] = this.setting.value;
    this.settingForm.reset(defaultValue);
  }

  protected createFormGroupFromSetting() {
    const group = {} as any;
    const validators = [] as any[];

    this.settingLabel = this.orgSettings.getFieldLabelTranslated(
      this.setting.id
    );

    this.settingPlaceholder = this.orgSettings.getFieldPlaceholderTranslated(
      this.setting
    );

    if (this.validationSettings) {
      this.validationSettings.forEach((validation) => {
        if (
          validation.ValidationField === this.setting.id ||
          !validation.ValidationField
        ) {
          validators.push(super.getValidator(validation));
        }
      });
    }

    group[this.setting.id] = new FormControl(this.setting.value, validators);
    return group;
  }
}
