import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserInputCreationFeedback } from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { Repository } from '@app/shared/patterns/repository/repository';

/**
 * A repository providing CRUD operations for a single user Input type from the user inputs API.
 */
export class UserInputsRepository<TEntity>
  extends ApiServiceBase
  implements Repository<TEntity, UserInputCreationFeedback, unknown>
{
  private static readonly baseUrl = '/userinputs';
  private apiInputType: string;

  constructor(httpClient: NgxHttpClient, inputType: InputType) {
    super(httpClient, '');
    this.apiInputType =
      inputType === 'Article' || inputType === 'Video'
        ? 'mediaentry'
        : inputType.toLowerCase();
  }

  public fetchOne(id: number): Observable<TEntity> {
    const apiIdType =
      this.apiInputType === 'mediaentry'
        ? 'usermediaid'
        : `user${this.apiInputType}id`; // media entry API doesn't follow ID param naming convention
    const params = { [apiIdType]: id };
    return this.get(
      `${UserInputsRepository.baseUrl}/getuser${this.apiInputType}`,
      params
    );
  }

  public add(item: TEntity): Observable<UserInputCreationFeedback> {
    return this.post(
      `${UserInputsRepository.baseUrl}/adduser${this.apiInputType}`,
      item
    );
  }

  public addExisting(item: TEntity): Observable<UserInputCreationFeedback> {
    return this.post(
      `${UserInputsRepository.baseUrl}/adduserexisting${this.apiInputType}`,
      item
    );
  }

  // tbd on type until we have an implementation for this
  public remove(id: unknown): Observable<void> {
    throw new Error('Method not implemented.');
  }

  public update(item: TEntity): Observable<unknown> {
    return this.put<void>(
      `${UserInputsRepository.baseUrl}/updateuser${this.apiInputType}`,
      item
    );
  }
}
