import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import {
  PathwayBookmark,
  PathwayFacade,
  PathwayViewModel,
  hasFauxSection,
  shouldShowNav,
} from '@dg/pathways-rsm';

@Component({
  selector: 'dgx-pathway-view',
  templateUrl: './pathway-view.component.html',
  styleUrls: ['./pathway-view.component.scss'],
})
export class PathwayViewComponent implements OnInit {
  public vm$: Observable<PathwayViewModel>;

  constructor(
    private facade: PathwayFacade,
    private bookmark: PathwayBookmark
  ) {
    // Whenever the store state changes, update the URL
    this.vm$ = this.facade.vm$.pipe(this.bookmark.updateBookmarkUrl);
  }

  public ngOnInit(): void {
    // Whenever this view is first shown, update store
    // state from the URL
    this.bookmark.syncUrlToStore();
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public shouldShowNav = shouldShowNav;
  // NOT hasFauxOrMockSection. This is used by PathwayAuthoringNav to determine
  // whether or not to create sections and subsections, and when a section/subsection
  // is MOCK, we *do* want to create a new one.
  public hasFauxSection = hasFauxSection;
}
