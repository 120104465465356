<ng-template #dPicker>
  <ngb-datepicker></ngb-datepicker>
</ng-template>

<!-- template for skillsView-->
<ng-template
  #skillsViewTemplate
  let-formControl="formControl"
  let-params="params"
  dfSkill
>
  <dgx-tags-edit
    #tagsEditor
    class="form-wrap guts-m-t-1-quart"
    [tags]="formControl.value"
    (tagsChange)="onTagsChanged($event, formControl)"
  ></dgx-tags-edit>
</ng-template>

<!-- template for provider search -->
<ng-template #providerSearchTemplate let-formControl="formControl">
  <input
    dgxAutofocus
    type="text"
    class="df-text-input df-text-input-border"
    autocomplete="off"
    [value]="formControl.value"
    [ngbTypeahead]="onProviderSearch"
    [inputFormatter]="labelFormatter"
    [resultTemplate]="providerResultTemplate"
    (selectItem)="onProviderSelect($event, formControl)"
    (blur)="setCourseProvider($event, formControl)"
    data-dgat="course-formal-form-a54"
  />
</ng-template>

<ng-template #providerResultTemplate let-item="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="item.label"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
  <dgx-validate-field
    *ngIf="model.institutionMessage"
    [message]="model.institutionMessage"
  ></dgx-validate-field>
</ng-template>

<!-- template for course title -->
<ng-template #courseTitleSearchTemplate let-formControl="formControl">
  <input
    type="text"
    class="course-title"
    autocomplete="off"
    name="courseName"
    [value]="formControl.value"
    [ngbTypeahead]="onCourseTitleSearch"
    [inputFormatter]="labelFormatter"
    [resultTemplate]="courseTitleResultTemplate"
    (selectItem)="onSelectCourseFromList($event, formControl)"
    (blur)="setCourseTitle($event, formControl)"
    data-dgat="course-formal-form-294"
  />
</ng-template>
<ng-template #courseTitleResultTemplate let-item="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="item.value"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
</ng-template>

<dgx-add-to-pathway
  *ngIf="model.formUIState.shouldIncludeAddToPathWay"
  (submit)="onEbbSubmit($event)"
  [activeModal]="modalInstance"
  [addToPathwayOptions]="addToPathwayOptions"
  [ebbAddToPathwayConfig]="ebbConfig"
></dgx-add-to-pathway>
