/**
 * Used in the inputFormatter field on ngbTypeahead. Pass in a *defined object* and
 * a field name. If you need to handle cases like empty strings, do that in a local
 * implementation.
 *
 * @param item - Typed, defined object to pull a field out of.
 * @param fieldName - The field to pull.
 *
 * @example
 * ```
 * // item might sometimes be undefined
 * public inputFormatter(item: SpecialType) {
 *   return ngbTypeaheadInputFormatter<SpecialType>(item || {}, 'title');
 * }
 *
 * // item might not always have a title field
 * public inputFormatter(item: SpecialType) {
 *   return item.title ? ngbTypeaheadInputFormatter<SpecialType>(item, 'title') : '';
 * }
 * ```
 */
export const ngbTypeaheadInputFormatter = <T>(result: T, fieldName: string) =>
  result[fieldName];

export const ngbTypeaheadInputFormatterMultiField = <T>(
  result: T,
  fieldNames: string[]
) => {
  if (fieldNames.length === 1) {
    return result[fieldNames[0]];
  }
  // Otherwise, grab first result that has a truthy match
  const matchingFieldName = fieldNames.find((fn) => !!result[fn]);
  // Safety: or an empty string, if no match.
  return matchingFieldName ? result[matchingFieldName] : '';
};
