import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, finalize, map } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';

// misc
import { DegreeLevel } from '@app/outcomes/outcomes.model';
import { TypeaheadSearchTerm } from '@app/shared/shared-api.model';
import { WindowToken } from '@app/shared/window.token';
import {
  DegreeModel,
  GlobalAddDegreeField,
} from '@app/user-content/user-outcome-v2/outcomes/degree/degree.model';
import { DegreeGlobalAddFacade } from '@app/user-content/user-outcome-v2/outcomes/degree/services/global-add/degree-global-add.facade';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { lazySearch } from '@dg/shared-rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DegreeMapperService,
  DegreeService,
  DegreeTrackerService,
} from '../../services';
import { handleDegreeFocusOnSubmit } from '../../services/degree.utils';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Component({
  selector: 'dgx-degree-global-add',
  templateUrl: './degree-global-add.component.html',
  styleUrls: ['./degree-global-add.component.scss'],
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DegreeGlobalAddFacade,
    DegreeMapperService,
    OutcomeNotificationService,
    DegreeService,
    DegreeTrackerService,
    OutcomesService,
  ],
})
export class DegreeGlobalAddComponent implements OnInit {
  @Input() public isEditing: boolean;
  @Input() public userOutcomeId: number;

  public isLoadingCountries = false;
  public isLoadingColleges = false;

  public degreeGlobalAddForm: FormGroup;

  public i18n = this.translate.instant([
    'Core_Degree',
    'Core_Save',
    'Core_SelectDate',
    'Core_Skills',
    'Core_Title',
    'dgContentHosting_DragAndDrop',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgUserOutcomeEditForm_AddDegree',
    'dgUserOutcomeEditForm_CollegeUniversity',
    'dgUserOutcomeEditForm_Country',
    'dgUserOutcomeEditForm_DateGraduated',
    'dgUserOutcomeEditForm_DegreeLevelExample',
    'dgUserOutcomeEditForm_DegreeTitleExample',
    'dgUserOutcomeEditForm_EditDegree',
    'dgUserOutcomeEditForm_GPA',
    'dgUserOutcomeEditForm_GPAExample',
    'dgUserOutcomeEditForm_OutsideUS',
    'dgUserOutcomeEditForm_SaveDegree',
    'dgUserOutcomeEditForm_UniversityCountry',
    'dgUserOutcomeEditForm_UniversityName',
    'DegreeFormCtrl_GradePointAvg',
  ]);
  public heading: string;
  public submitButtonText: string;
  public levels: DegreeLevel[];
  public vm$: Observable<DegreeModel>;

  public markSubFormsAsTouched = false;

  constructor(
    private facade: DegreeGlobalAddFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private globalAddTrackingService: GlobalAddTrackingService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.facade.initializeViewModel();
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    this.heading = this.isEditing
      ? this.i18n.dgUserOutcomeEditForm_EditDegree
      : this.i18n.dgUserOutcomeEditForm_AddDegree;

    this.submitButtonText = this.isEditing
      ? this.i18n.Core_Save
      : this.i18n.dgUserOutcomeEditForm_SaveDegree;

    if (this.isEditing) {
      await this.facade.initializeEdit(this.userOutcomeId);
      this.initializeExpandedEditForm();
      return;
    }

    // Set up the form
    this.initializeForm();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/
  public onFormControlUpdate(field: GlobalAddDegreeField, value: any) {
    this.facade.onFormControlUpdate(this.degreeGlobalAddForm, field, value);
  }

  /**
   * On form Submission check if the form is invalid
   */
  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.degreeGlobalAddForm);
    this.markSubFormsAsTouched = true;
    this.cdr.detectChanges();

    if (this.degreeGlobalAddForm.invalid) {
      handleDegreeFocusOnSubmit(this.degreeGlobalAddForm.controls);
      return;
    }

    try {
      await this.facade.onSubmit(this.degreeGlobalAddForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }
  }

  public loadColleges = (termProvider: TypeaheadSearchTerm<string>) => {
    return termProvider.pipe(
      lazySearch((term) => {
        this.isLoadingColleges = true;
        this.cdr.detectChanges();
        return this.facade
          .collegeSearch(this.degreeGlobalAddForm.get('country').value, term)
          .pipe(
            map((colleges) => colleges.map((college) => college.label)),
            finalize(() => {
              this.isLoadingColleges = false;
              this.cdr.detectChanges();
            })
          );
      })
    );
  };

  public loadCountries = (termProvider: TypeaheadSearchTerm<string>) => {
    return termProvider.pipe(
      lazySearch((term) => {
        this.isLoadingCountries = true;
        this.cdr.detectChanges();
        return this.facade.countrySearch(term).pipe(
          finalize(() => {
            this.isLoadingCountries = false;
            this.cdr.detectChanges();
          })
        );
      })
    );
  };

  public loadInferredSkills() {
    this.facade.loadInferredSkills(
      this.degreeGlobalAddForm.get('title').value || ''
    );
  }

  /** Call to request the modal to close, optionally with navigation to a profile collection containing the new user input. */
  public onNavigateToCollection(collectionUrl?: string) {
    // close the modal with the entity as result before navigating
    this.activeModal.close(this.facade.snapshot); // input should be assumed to be submitted successfully if we're here
    if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }

  public onDismiss(event: Event) {
    this.activeModal.dismiss();
    this.globalAddTrackingService.trackGlobalAddCancelled('Degree');
  }

  private initializeExpandedEditForm(): void {
    this.degreeGlobalAddForm = this.formBuilder.group({
      country: [this.facade.snapshot.country, Validators.required],
      college: [this.facade.snapshot.college, Validators.required],
      endDate: this.facade.snapshot.endDate,
      degree: this.facade.snapshot.degree,
      gpa: [
        this.facade.snapshot.gpa,
        [
          Validators.min(0),
          Validators.max(10),
          Validators.pattern(/^(?!.*(\+|-).*\1)[0-9]+(\.[0-9]{1,2})?$/),
        ],
      ],
      imageUrl: this.facade.snapshot.imageUrl,
      isInternational: this.facade.snapshot.isInternational,
      skills: [this.facade.snapshot.skills],
      title: [this.facade.snapshot.title, Validators.required],
    });
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm() {
    this.degreeGlobalAddForm = this.formBuilder.group({
      country: ['', Validators.required],
      college: ['', Validators.required],
      endDate: null,
      degree: null,
      gpa: [
        null,
        [
          Validators.min(0),
          Validators.max(10),
          Validators.pattern(/^(?!.*(\+|-).*\1)[0-9]+(\.[0-9]{1,2})?$/),
        ],
      ],
      imageUrl: '',
      isInternational: false,
      skills: [],
      title: ['', Validators.required],
    });
  }
}
