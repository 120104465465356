import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'encodeToEntities' })
export class EncodeToEntitiesPipe implements PipeTransform {
  constructor() {}

  public transform(inputVal: string) {
    return inputVal.replace(/[\u00A0-\u9999<>\&]/gim, (i) => {
      return '&#' + i.charCodeAt(0) + ';';
    });
  }
}
