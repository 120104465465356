import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export interface DfControlEvent<T> extends Omit<Event, 'target'> {
  target: T;
}

/**
 *
 * Implementation of {@link ControlValueAccessor} for simple form input control components.
 * This provides the essentials for interacting with an {@link AbstractControl} and is intented to
 * serve as a directive or a base class for other directives and components.
 */
@Directive({
  selector: 'dfControlValue',
})
export class DfControlValueDirective<T> implements ControlValueAccessor {
  public get value(): T {
    return this._value;
  }

  /**
   * TODO: Reestablish disabled setter/getter in base class as soon as we move to Target ES6.
   * Due to an issue in TypeScript https://github.com/Microsoft/TypeScript/issues/338.
   * We could not use the original class design. I am forcing a call to coerceBooleanProperty as a remainder to reestablish the original code in the base class once we move to ES6.
   */
  @Input() public disabled = false;

  @Input('aria-describedby') public ariaDescribedby: string;
  @Input('aria-label') public ariaLabel: string;
  @Input('aria-labelledby') public ariaLabelledby: string | null;

  @Output() public controlValueChange: EventEmitter<T> = new EventEmitter<T>();

  private _value: T;

  // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
  public onChange = (value: T) => {};
  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  public onTouched = () => {};

  /** Called from the associated {@link FormControl} to push a value from the model out to the UI */
  public writeValue(obj: T): void {
    this._value = obj;
    this.controlValueChange.emit(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  // this is to get around the linting issues of not being able to do this cast directly from the template
  public handleOnChangesOfCheckedEvent($event) {
    const isChecked = ($event.target as HTMLInputElement).checked;
    return this.handleOnChangeEvent(isChecked as unknown as T);
  }

  /** Call this from your derived class to update the control value from the UI */
  public handleOnChangeEvent(obj: T): void {
    if (this.disabled || obj === this._value) {
      return;
    }
    this._value = obj;
    this.onChange(obj);
    this.controlValueChange.emit(obj);
  }
}
