import { NgModule } from '@angular/core';
import { DfAutofocusDirective } from './autofocus.directive';

@NgModule({
  imports: [],
  exports: [DfAutofocusDirective],
  declarations: [DfAutofocusDirective],
  providers: [],
})
export class DfAutofocusModule {}
