import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DfDgatFieldTypeDirective } from '../dgat-field-type/dgat-field-type.directive';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * A simple formly wrapper for a checkbox component
 */
@Component({
  selector: 'df-form-checkbox',
  template: `<input
    type="checkbox"
    [id]="id"
    [required]="to.required"
    [formControl]="control"
    [formlyAttributes]="field"
    [attr.aria-controls]="to.ariaControlleeId"
    [attr.data-dgat]="to.dgatId"
    [dfAutofocus]="to.shouldAutofocus"
  />`,
  // explicitly provide type so [formControl] selector matches it
  styles: [
    `
      :host {
        display: block;
        padding-top: 0.4rem;
      }
    `, // pad to align with field label text
  ],
})
export class DfFormCheckboxComponent extends DfDgatFieldTypeDirective {
  public get control() {
    return this.formControl as FormControl;
  }
}
