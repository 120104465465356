import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { switchMap } from 'rxjs/operators';
import {
  TrackingLink,
  TrackingLinkResponse,
} from '../tracking-links-table/tracking-link.model';
import { AuthUser } from '@app/account/account-api.model';
import { TargetsService } from '@app/shared/services/targets.service';
import { ModalService } from '@app/shared/services/modal.service';
import { AuthService } from '@app/shared/services/auth.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TrackingLinkService } from '../services/tracking-link.service';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash-es';
import { Target } from '@app/target/target-api.model';
import { ResourceType } from '@app/shared/models/core-api.model';
import { TrackingLinkFormComponent } from '@app/recommendations/components/tracking-link-form/tracking-link-form.component';

@Component({
  selector: 'dgx-skill-and-role-plan-tracking-links',
  templateUrl: './skill-and-role-plan-tracking-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillAndRolePlanTrackingLinksComponent implements OnInit {
  @Input() public target: Target;

  public i18n = this.translateService.instant([
    'TrackableLinkCtrl_AutoEnroll',
    'TrackableLinkCtrl_CopyLink',
    'TrackableLinkCtrl_CopyLinkAgain',
    'TrackableLinkCtrl_DeleteLinkModalHeader',
    'Core_Delete',
  ]);

  public ascend = {};
  public baseTrackingUrl = '';
  public defaultSort = 'created';
  public currentSortBy = this.defaultSort;
  public canManageTargets: boolean;
  public canViewTrackableLinks: boolean;
  public isOrgContent: boolean;
  public links: TrackingLink[] = [];

  private authUser: AuthUser;
  private resourceId: number;
  private resourceType: ResourceType;
  private resourceCtrl: 'pathways' | 'targets';

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private targetsService: TargetsService,
    private trackingLinkService: TrackingLinkService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    this.resourceId = this.target.targetId;

    this.canManageTargets = this.authUser?.canManageTargets;
    this.canViewTrackableLinks = this.target.privacyId > 0;
    this.isOrgContent =
      this.authUser?.defaultOrgId === this.target.organizationId;

    // target.targetType can be 'Target' for Skill and 'Role for Role
    // but they should both be 'Target'.
    // could consider using `target.resourceType` which appears to be
    // 'Target' for Skill/Role plan and 'Pathway' for Pathways.
    this.resourceType = 'Target';
    this.resourceCtrl = this.trackingLinkService.resourceCtrlFor(
      this.resourceType
    );

    this.baseTrackingUrl = this.targetsService.getShareUrl(
      this.target,
      this.authUser?.defaultOrgInfo?.organizationCode
    );

    this.trackingLinkService
      .getTrackingLinks(this.resourceId, this.resourceCtrl)
      .subscribe((links) => {
        this.links = links;
        this.cdr.detectChanges();
      });
  }

  public deleteLink(trackingLink: { item: TrackingLink }) {
    const inputs: SimpleModalInputBindings = {
      submitButtonText: this.i18n.Core_Delete,
      bodyText: this.i18n.TrackableLinkCtrl_DeleteLinkModalHeader,
      canCancel: true,
    };
    this.modalService
      .show(SimpleModalComponent, { inputs })
      .pipe(
        switchMap(() => {
          return this.trackingLinkService.deleteTrackingLink(
            trackingLink.item.id,
            this.resourceId,
            this.resourceCtrl
          );
        })
      )
      .subscribe(() => {
        this.links = this.links.filter((l) => l.id !== trackingLink.item.id);
        this.cdr.detectChanges();
      });
  }

  public newLink() {
    this.addOrEdit(null).subscribe((response: TrackingLinkResponse) => {
      this.links.push(response.link as TrackingLink);
      this.orderLinks();
      this.cdr.detectChanges();
    });
  }

  public editLink(link: TrackingLink) {
    this.addOrEdit(link).subscribe((response: TrackingLinkResponse) => {
      const { name, id } = response.link;
      this.links = this.links.map((l) => {
        if (l.id === id) {
          return {
            ...l,
            name,
          };
        }

        return l;
      });
      this.cdr.detectChanges();
    });
  }

  public sortLinks(sortBy: string) {
    if (this.currentSortBy === sortBy) {
      this.ascend[sortBy] = !this.ascend[sortBy];
    } else {
      this.ascend[sortBy] = true;
    }
    this.currentSortBy = sortBy;
    this.orderLinks();
  }

  private orderLinks() {
    const direction = this.ascend[this.currentSortBy] ? 'asc' : 'desc';
    this.links = orderBy(this.links, this.currentSortBy, direction);
  }

  private addOrEdit(link?: TrackingLink) {
    const isEditing = !!link;

    return this.modalService.show(TrackingLinkFormComponent, {
      inputs: {
        resolve: {
          link,
          url: this.baseTrackingUrl,
          isEditing,
          resourceId: this.resourceId,
          resourceType: this.resourceType,
          resourceCtrl: this.resourceCtrl,
          canManage: this.canManageTargets,
        },
      },
    });
  }
}
