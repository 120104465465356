import { Injector } from '@angular/core';
import { InputType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TrackerService } from '@app/shared/services/tracker.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  AllUserInputParameters,
  AnyUpdateUserInputParameters,
  AnyUserExistingInputParameters,
  AnyUserInput,
  AnyUserNewInputParameters,
  InputManager,
} from '../inputs.model';
import { UserInputCreationFeedback } from '../inputs-api.model';
import { InputsService } from './inputs.service';

/** Base class for building specialized user input type web API services.
 * @description Inherit from this class and call its constructor to configure it
 * for your {@link InputType} -specific management.
 */
export abstract class UserInputTypeServiceBase<
    TInputType extends InputType | 'MediaEntry',
    TUserInput extends AnyUserInput,
    TAddNew extends AnyUserNewInputParameters,
    TAddExisting extends AnyUserExistingInputParameters,
    TUpdate extends AnyUpdateUserInputParameters
  >
  extends ApiServiceBase
  implements
    InputManager<
      TUserInput,
      TAddNew,
      TAddExisting,
      TUpdate,
      UserInputCreationFeedback
    >
{
  private static readonly defaultAddTrackingAction = 'Content Completed';
  private inputTypeLowerCase = this.inputType.toLowerCase();
  private tracker: TrackerService;
  private inputsService: InputsService;

  // TODO: add a tracking area update mechanism so we don't have to pass it in everywhere

  /**
   * Constructs the service
   * @param injector The injector to use to supply {@link HttpClient}, {@link TrackerService} and {@link InputsService}
   * @param defaultErrorMessage Default error message to throw as {@link DgError}
   * @param inputType {@link InputType} to use for building endpoint URLs
   * @param defaultTrackingCategory Default Category property value to use for tracking. If an input argument contains
   * an {@link AnyUserInputParameters.inputType} property, that property will be used instead
   */
  constructor(
    injector: Injector,
    defaultErrorMessage: string,
    private inputType: TInputType, // Have to provide the actual type string at runtime even though we know the compiler knows the type,
    private defaultTrackingCategory: string = inputType
  ) {
    super(injector.get(NgxHttpClient), defaultErrorMessage);
    this.tracker = injector.get(TrackerService);
    this.inputsService = injector.get(InputsService);
  }

  /**
   * @param userInputParams parameters for the specific new input type to add and associate with this user
   */
  public addNewInput(
    userInputParams: TAddNew,
    trackingArea: string,
    trackingAction: string = UserInputTypeServiceBase.defaultAddTrackingAction
  ): Observable<UserInputCreationFeedback> {
    return this.add(
      `/userinputs/adduser${this.inputTypeLowerCase}`,
      userInputParams,
      trackingArea,
      trackingAction
    );
  }

  /**
   * @param existingUserInputParams parameters for the specific existing input type to associate with this user
   */
  public addExistingInput(
    userExistingInputParams: TAddExisting,
    trackingArea: string
  ): Observable<UserInputCreationFeedback> {
    return this.add(
      `/userinputs/adduserexisting${this.inputTypeLowerCase}`,
      userExistingInputParams,
      trackingArea
    );
  }

  /**
   * @param updateInputParams parameters for the specific user input type to update
   */
  public updateInput(updateInputParams: TUpdate): Observable<void> {
    return this.put<void>(
      `/userinputs/updateuser${this.inputTypeLowerCase}`,
      updateInputParams
    ).pipe(
      tap((_) => {
        this.track(updateInputParams, 'User Item Updated');
        this.inputsService.notifyInputModified(this.inputType);
      })
    );
  }

  /**
   * @param id the id of the user item to request
   */
  public getInput(id: number): Observable<TUserInput> {
    const params = {};
    const paramName = `user${this.inputType}Id`;
    params[paramName] = id;
    return this.get(`/userinputs/getuser${this.inputTypeLowerCase}`, params);
  }

  private add(
    url: string,
    input: TAddNew | TAddExisting,
    trackingArea: string,
    trackingAction = UserInputTypeServiceBase.defaultAddTrackingAction
  ): Observable<UserInputCreationFeedback> {
    return this.post<UserInputCreationFeedback>(url, input).pipe(
      tap((_) => {
        this.track(input, trackingAction, trackingArea);
        this.inputsService.notifyInputModified(this.inputType);
      })
    );
  }

  private track(
    inputParams: TAddNew | TAddExisting | TUpdate,
    action?: string,
    area?: string
  ) {
    this.tracker.trackEventData({
      action,
      category:
        (inputParams as AllUserInputParameters).inputType ||
        this.defaultTrackingCategory,
      label: null,
      properties: { ...inputParams, skillTagCount: inputParams.tags?.length },
    });
  }
}
