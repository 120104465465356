import {
  AllUserMediaParameters,
  AnyUserAssessmentParameters,
  AnyUserBookParameters,
  AnyUserCourseParameters,
  AnyUserEpisodeParameters,
  AnyUserEventParameters,
  AnyUserInputParameters,
  AnyUserMediaParameters,
  AnyUserPositionParameters,
} from '@app/inputs/inputs.model';
import {
  InputCreationFeedback,
  MediaEntry,
  UpdateUserAssessmentParameters,
  UpdateUserBookParameters,
  UpdateUserCourseParameters,
  UpdateUserEpisodeParameters,
  UpdateUserEventParameters,
  UpdateUserPositionParameters,
  UserAssessmentParameters,
  UserBookParameters,
  UserCourseParameters,
  UserEpisodeParameters,
  UserEventParameters,
  UserExistingAssessmentParameters,
  UserExistingBookParameters,
  UserExistingEpisodeParameters,
  UserExistingEventParameters,
  UserExistingPositionParameters,
  UserInputCreationFeedback,
  UserMediaEntry,
  UserPositionParameters,
} from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { Merge, UnionToIntersection } from '@app/shared/utils/type';
import { AssessmentApiEntity } from './assessment-forms/repository/assessment.entity.model';
import { BookApiEntity } from './book-forms/repository/book.entity.model';
import { CourseApiEntity } from './course-form/repository/course.entity.model';
import { EpisodeApiEntity } from './episode-forms/repository/episode.entity.model';
import { EventApiEntity } from './event-modal/repository/user-event.entity.model';
import {
  ExperienceApiEntity,
  OpportunityAsExperienceApiEntity,
} from './experience-modal/model/experience-form.model';

/** The render modes for Input forms, which allows forms to be presented differently depending on context */
export const enum RenderMode {
  /** Includes both Global Add and Profile Collection use cases */
  UserProfile,
  /** Pathway content */
  Pathways,
  /** Catalog content */
  ContentCatalog,
  /** Skill and Role Plans */
  Targets,
}

/** Represents the application context within which a content add or edit is taking place */
export interface InputContext {
  isEditing?: boolean;
  isChannel?: boolean;
  /** @deprecated The {@link renderMode} property should be used instead */
  isCompleting?: boolean;
  isPathwayBinAdd?: boolean;
  inputType: InputType;
  trackingArea?: string;
  renderMode: RenderMode;
  inputId?: number;
  /** Only valid for renderMode = RenderMode.Pathways */
  pathwayId?: number;
  organizationId?: number;
  isCmsContent?: boolean;
  isEditingInternalContent?: boolean;
}

/** Extends a specific input type to include a user comment */
export type UserInputParametersViewModel<T> = T & {
  comment?: string; // When adding/editing media entries, this additional field is updated independently from the rest of the entry props
  title?: string;
};

export type AnyCreationFeedback = Merge<
  UserInputCreationFeedback,
  InputCreationFeedback
>;

// Helper types for abstraction when dealing with input parameter type variants generically

// User media
export type AnyUserMediaParametersViewModel =
  UserInputParametersViewModel<AnyUserMediaParameters>;
export type AllUserMediaParametersViewModel =
  UserInputParametersViewModel<AnyUserMediaParameters>;

// User assessment
export type AnyUserAssessmentParametersViewModel =
  UserInputParametersViewModel<AnyUserAssessmentParameters>;
export type AllUserAssessmentParametersViewModel = UserInputParametersViewModel<
  Merge<
    Merge<UserAssessmentParameters, UserExistingAssessmentParameters>,
    UpdateUserAssessmentParameters
  >
>;

// User book
export type AnyUserBookParametersViewModel =
  UserInputParametersViewModel<AnyUserBookParameters>;
export type AllUserBookParametersViewModel = UserInputParametersViewModel<
  Merge<
    Merge<UserBookParameters, UserExistingBookParameters>,
    UpdateUserBookParameters
  >
>;

// User episode
export type AnyUserEpisodeParametersViewModel =
  UserInputParametersViewModel<AnyUserEpisodeParameters>;
export type AllUserEpisodeParametersViewModel = UserInputParametersViewModel<
  Merge<
    Merge<UserEpisodeParameters, UserExistingEpisodeParameters>,
    UpdateUserEpisodeParameters
  >
>;

// User event
export type AnyUserEventParametersViewModel =
  UserInputParametersViewModel<AnyUserEventParameters>;
export type AllUserEventParametersViewModel = UserInputParametersViewModel<
  Merge<
    Merge<UserEventParameters, UserExistingEventParameters>,
    UpdateUserEventParameters
  >
>;

// User position (experience)
export type AnyUserPositionParametersViewModel =
  UserInputParametersViewModel<AnyUserPositionParameters>;
export type AllUserPositionParametersViewModel = UserInputParametersViewModel<
  Merge<
    Merge<UserPositionParameters, UserExistingPositionParameters>,
    UpdateUserPositionParameters
  >
>;

// User course
export type AnyUserCourseParametersViewModel =
  UserInputParametersViewModel<AnyUserCourseParameters>;
export type AllUserCourseParametersViewModel = UserInputParametersViewModel<
  Merge<UserCourseParameters, UpdateUserCourseParameters>
>;

/** Represents a view model for any NEW user input creation parameter type */
export type AnyUserNewInputParametersViewModel =
  | AnyUserMediaParametersViewModel
  | AnyUserPositionParametersViewModel
  | AnyUserAssessmentParametersViewModel
  | AnyUserBookParametersViewModel
  | AnyUserEventParametersViewModel
  | AnyUserEpisodeParametersViewModel
  | AnyUserCourseParametersViewModel;

/** Represents a view model for ANY user input parameter (new, existing, update) type */
export type AnyUserInputParametersViewModel =
  UserInputParametersViewModel<AnyUserInputParameters>;

export type MediaEntryApiEntity = Merge<
  Merge<UserMediaEntry, MediaEntry>,
  AllUserMediaParameters
>;

// TODO: Add others
export type AnyInputApiEntity =
  | EventApiEntity
  | MediaEntryApiEntity
  | CourseApiEntity
  | BookApiEntity
  | EpisodeApiEntity
  | AssessmentApiEntity
  | ExperienceApiEntity
  | OpportunityAsExperienceApiEntity;
// TODO: Add others

export type AllInputApiEntities = UnionToIntersection<AnyInputApiEntity>;

export enum ItemTypes {
  Outcome = 'outcome',
  Input = 'input',
  Experience = 'experience',
}
