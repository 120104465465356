import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  BrokenLinksService,
  BrokenLinksInputResponse,
} from '@app/content-catalog/services/broken-links.service';
import { CatalogSearchQueryOpts } from '@app/content-catalog/components/catalog-search-query-opts';
import { AuthService } from '@dg/shared-services';

@Injectable({ providedIn: 'root' })
export class BrokenLinksResolver  {
  constructor(
    private brokenLinksService: BrokenLinksService,
    private authService: AuthService
  ) {}

  public resolve(): Observable<BrokenLinksInputResponse> {
    const params: CatalogSearchQueryOpts = {
      terms: '',
      tags: [],
      facets: [
        {
          id: 'Internal',
          values: [this.authService.authUser?.defaultOrgId],
        },
      ],
      skip: 0,
      count: 12, // try to get 12 to match the broken links table size
      includeProviders: false,
      defaults: 'CMS',
      includeCompleted: true,
      sortBy: 'DateUpdated',
      isAscending: false,
      exclusionList: [],
    };
    return this.brokenLinksService.getBrokenLinksInput(params);
  }
}
