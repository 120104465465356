import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a skill target comment notification */
@Component({
  selector: 'dgx-user-tag-set-goal',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTagSetGoalComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml(
      'Notification_UserTagSetGoal_NotificationTitleFormat',
      {
        nameHtml: this.userFullNameTemplate,
        tag: this.params.tagName,
        level: this.params.level,
      }
    );
  }
}
