import { Injectable } from '@angular/core';

import { DfFormFieldConfig } from '@lib/fresco';

import { RendererContext } from '../../form-renderer.model';
import { RenderMode } from '../../user-input.model';
import { DefaultArticleFormRenderer } from './default-article-form.renderer';
import { ContentCatalogArticleFormRenderer } from './content-catalog-article-form.renderer';
import { MediaFormModel } from '../../media-modal/media-form.model';
import { FormRenderManager } from '@app/shared/patterns/render-manager/form-render-manager';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';

/** Top-level form field configuration renderer for article form */
@Injectable({ providedIn: 'any' })
export class RootArticleFormRenderer implements FormRenderManager {
  constructor(
    private defaultRenderer: DefaultArticleFormRenderer,
    private contentCatalogRenderer: ContentCatalogArticleFormRenderer
  ) {}

  public render(
    context: RendererContext<MediaFormModel<HostedContentMetadata>>
  ): DfFormFieldConfig[] {
    return (
      context.inputContext.renderMode === RenderMode.ContentCatalog ||
      (context.inputContext.renderMode === RenderMode.Pathways &&
        context.state().isEditing) // When editing the item from pathways, we're actually editing the content catalog internal item
        ? this.contentCatalogRenderer
        : this.defaultRenderer
    ).render(context);
  }
}
