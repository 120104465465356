import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { getAverageRatingLevel } from '@app/shared/utils/tag-helpers';

@Component({
  selector: 'dgx-tag-rating-display',
  templateUrl: './tag-rating-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingDisplayComponent implements OnInit {
  @Input() public tag: TagsApi.Tag;
  @Input() public ratingInfo: Partial<TagsApi.UserTagRating>;
  @Input() public endorserUserName: string;

  public isEvaluation: boolean;
  public isCertified: boolean;
  public i18n = this.translate.instant([
    'dgTagRating_SelfAssessment',
    'dgTagRating_ManagerRating',
    'dgTagRating_Evaluation',
    'dgTagRating_SkillCertification',
    'dgTagRating_EndorsedByFormat',
  ]);

  constructor(
    private translate: TranslateService,
    private tagRatingType: TagRatingTypePipe
  ) {}

  public ngOnInit(): void {
    this.isEvaluation =
      this.ratingInfo.type === InternalTagRatingTypes.evaluation;
    this.isCertified =
      this.ratingInfo.type === InternalTagRatingTypes.credential;
    this.setEndorserUserName();
  }

  public get level(): string {
    return this.ratingInfo.type === InternalTagRatingTypes.peer
      ? getAverageRatingLevel(
          this.tag.ratings.filter(
            (rating) =>
              rating.type === InternalTagRatingTypes.peer &&
              !!rating.dateCompleted
          )
        )
      : this.ratingInfo.level;
  }

  public get ratingType(): string {
    return this.tagRatingType.transform(
      this.ratingInfo as TagsApi.UserTagRating
    );
  }

  private setEndorserUserName(): void {
    if (this.endorserUserName) {
      this.i18n.dgTagRating_EndorsedByFormat = this.translate.instant(
        'dgTagRating_EndorsedByFormat',
        { name: this.endorserUserName }
      );
    }
  }
}
