<dgx-card [cardAriaLabel]="group.name">
  <ng-container class="card-content">
    <div class="guts-p-h-1 guts-p-t-1">
      <h3 class="h3 guts-m-b-half" data-dgprop-item-clicked="Title">
        <a
          [routerLink]="'/groups/' + this.group?.groupId"
          (click)="$event.stopPropagation()"
          [attr.title]="group.name"
          [attr.aria-label]="group.name"
          class="tile__title--clickable"
          data-dgat="group-tile-580"
          >{{ group.name | ellipsis: titleEllipsisLimit }}</a
        >
      </h3>
      <p class="par par--small tile__description" *ngIf="group.description">
        {{ group.description | ellipsis: descriptionEllipsisLimit }}
      </p>
    </div>

    <div
      class="
        l_flex-grow
        l_flex
        l_flex-middle
        l_flex
        l_flex-middle
        l_flex-justify-center
      "
    >
      <dgx-social-pics
        *ngIf="group.members?.length > 0"
        [users]="group.members"
        [totalUsers]="group.memberCount"
        [attr.aria-label]="memberCountLabel(group.memberCount)"
        size="smallish"
        [maxUsers]="4"
        tabindex="-1"
      ></dgx-social-pics>
    </div>
  </ng-container>

  <ng-container class="card-footer">
    <footer class="l_flexbar l_flex-justify">
      <ng-container [ngSwitch]="buttonToShow">
        <button
          *ngSwitchCase="'join'"
          df-button
          dfButtonType="passive"
          df-button-small
          (click)="join($event)"
          class="group-tile__footer-button"
          data-dgat="groupTile-d67"
          [attr.aria-label]="ariaLabel"
          [attr.aria-pressed]="ariaPressed"
        >
          <div class="l_flexbar gap-full-half">
            <df-icon icon="plus"></df-icon>
            <div>{{ i18n.Core_Join }}</div>
          </div>
        </button>

        <button
          *ngSwitchCase="'requestMembership'"
          df-button
          dfButtonType="passive"
          df-button-small
          class="group-tile__footer-button"
          (click)="requestMembership($event)"
          data-dgat="groupTile-9ea"
        >
          <div class="l_flexbar gap-full-half">
            <df-icon icon="plus"></df-icon>
            <div>{{ i18n.dgGroupTile_RequestMembership }}</div>
          </div>
        </button>

        <button
          *ngSwitchCase="'joinRequestPending'"
          df-button
          dfButtonType="ghost"
          df-button-small
          class="group-tile__footer-button color-blue"
          data-dgat="groupTile-228"
        >
          <div class="l_flexbar gap-full-half">
            <df-icon icon="checkmark"></df-icon>
            <div>{{ i18n.dgGroupTile_JoinRequestPending }}</div>
          </div>
        </button>

        <button
          *ngSwitchCase="'joined'"
          df-button
          dfButtonType="primary"
          df-button-small
          (click)="leave($event)"
          class="group-tile__footer-button"
          data-dgat="groupTile-191"
          [attr.aria-label]="ariaLabel"
          [attr.aria-pressed]="ariaPressed"
        >
          <div class="l_flexbar gap-full-half">
            <df-icon icon="checkmark"></df-icon>
            <div>{{ i18n.Core_Joined }}</div>
          </div>
        </button>
      </ng-container>

      <dgx-menu
        *ngIf="menuConfig"
        #optionsMenuTrigger
        [appendToBody]="true"
        [menuConfig]="menuConfig"
        placement="bottom-right"
      >
        <button
          df-button
          df-button-square
          dfButtonType="clear"
          [attr.aria-label]="i18n.Core_MoreOptions"
          type="button"
          class="group-tile__footer-menu"
          data-dgat="actionOptions-3cf"
        >
          <df-icon icon="dots" class="color-ebony-a61"></df-icon>
        </button>
      </dgx-menu>
    </footer>
  </ng-container>
</dgx-card>
