import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VideoSource, VideoType } from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { HLS_LAZY, HlsLazy } from '@app/shared/services/hls-lazy.token';
import { NotifierService } from '@app/shared/services/notifier.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { ContentDurationService } from '@app/shared/services/content/content-duration.service';
import { MediaInputType } from '@app/shared/models/core-api.model';
import { WindowLayoutService } from '@dg/shared-services';
import { ContentHostingSource } from '@app/content-hosting';

@Component({
  selector: 'dgx-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnInit {
  @Input() public resourceId: number;
  @Input() public resourceUrl: string;
  @Input() public resourceTitle: string;
  @Input() public videoType: VideoType;
  @Input() public resourceType: MediaInputType = 'Video';

  @ViewChildren('sourceElement') public sourceElements: QueryList<ElementRef>;
  @ViewChild('videoPlayer') public videoPlayer: ElementRef<HTMLMediaElement>;

  public playerType: 'native' | 'iframe' = 'native';
  public safeVideoUrl: SafeResourceUrl;
  public stillBeingProcessed = false;
  public videoReady = false;
  public videoSources: VideoSource[] = [];

  private sourceErrorsCount = 0;
  private expiryDate: number;

  constructor(
    @Inject(WindowToken) private windowRef: Window,
    @Inject(HLS_LAZY) private hlsLazy: HlsLazy,
    private contentDurationService: ContentDurationService,
    private inputsService: InputsService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private windowLayoutService: WindowLayoutService
  ) {}

  public ngOnInit(): void {
    if (
      this.videoType === 'youtube' ||
      this.videoType === 'vimeo' ||
      this.videoType === 'vztube'
    ) {
      this.playerType = 'iframe';
    }
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.resourceUrl
    );
  }

  public ngAfterViewInit(): void {
    if (this.playerType === 'native') {
      this.isLoading();
      if (this.videoType === 'hls') {
        this.hlsInit();
      } else {
        this.setSources();
      }
    } else {
      this.videoReady = true;
    }
  }

  /**
   * Modify URLs from some domains to allow embedded video to play.
   *
   * @param url
   */
  public parseEmbeddedVideoUrl(url: string): string {
    // https://degreedjira.atlassian.net/browse/PD-70547
    // https://degreedjira.atlassian.net/browse/PD-89710
    // We don't want to actually modify these URLs where we store them, only alter them
    // *locally* for embedding.
    const modifyUrlsFrom = ['sharepoint.com'];
    for (const site of modifyUrlsFrom) {
      if (url.indexOf(site) > -1) {
        if (site === 'sharepoint.com') {
          // Condition to embed sharepoint video url in video content
          return url.replace(
            /[&?]web=1&?/g,
            (x) => ({ '&web=1': '', '&web=1&': '&', '?web=1&': '?' }[x])
          );
        }
      }
    }
    return url;
  }

  private isLoading() {
    this.videoReady = false;
    // iOS and desktop Safari browsers do not fire `canPlayThrough` for our
    // native players *until the user has interacted with the video in some
    // fashion*. See: https://github.com/video-dev/hls.js/issues/1686
    // `loadedMetaData`, the suggested alternate, fires first, so we will
    // only listen for it.
    // https://degreedjira.atlassian.net/browse/PD-86027
    this.renderer.listen(
      this.videoPlayer?.nativeElement,
      'loadedmetadata',
      () => {
        if (this.isAuthoredContent()) {
          this.contentDurationService.triggerVideoDurationUpdate(
            this.videoPlayer.nativeElement
          );
        }
        this.videoReady = true;
        this.cdr.detectChanges();
      }
    );
  }

  private setSources() {
    if (this.videoType === 'degreed') {
      // 'degreed' videos have been uploaded or recorded by a user in our system

      this.inputsService
        .getHostedInputUrls({
          inputId: this.resourceId,
          inputType: this.resourceType,
        })
        .subscribe((response) => {
          if (response) {
            this.videoSources = response as ContentHostingSource[];

            this.expiryDate = Date.parse(
              this.getUrlParameter('se', this.videoSources[0].url)
            );
            this.cdr.detectChanges();
          }
        });
    } else if (this.videoType === 'mp4') {
      // direct link to externally hosted mp4

      this.videoSources = [
        {
          url: this.resourceUrl,
          type: 'video/mp4',
        },
      ];
    }
    this.sourceElements.changes.subscribe(() => {
      this.addErrorListener(this.sourceElements);
    });
  }

  private isAuthoredContent() {
    return this.resourceUrl?.indexOf('userauthoredvideo') > -1;
  }

  private getUrlParameter(name: string, url: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  private addErrorListener(sourceElements: QueryList<ElementRef>) {
    this.sourceErrorsCount = 0;
    for (const sourceElement of sourceElements) {
      this.renderer.listen(sourceElement.nativeElement, 'error', () => {
        if (this.videoType === 'degreed') {
          this.sourceErrorsCount++;
          if (this.expiryDate <= Date.now()) {
            // video has expired, try again
            this.isLoading();
            this.setSources();
          } else if (this.sourceErrorsCount >= this.videoSources.length) {
            // making the assumption that if no video type is supported, it means the server isn't finished with encoding process yet since the server will spit out mp4 which is broadly supported.
            this.stillBeingProcessed = true;
            this.cdr.detectChanges();
          }
        } else {
          // making the assumption that video is redirecting (behind a login wall), send user to redirected URL to login
          this.videoReady = true; // stop the spinner
          this.windowRef.open(this.resourceUrl, '_blank');
        }
      });
    }
  }

  private hlsInit() {
    // Lazy loading the hls library on demand.
    // see https://github.com/video-dev/hls.js/blob/HEAD/docs/API.md
    this.hlsLazy
      .then((Hls) => {
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.attachMedia(this.videoPlayer?.nativeElement);
          // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            // load the manifest
            hls.loadSource(this.resourceUrl);
          });
          // Error handling
          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.error(
                    'fatal network error encountered, try to recover'
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.error(
                    'fatal media error encountered, try to recover'
                  );
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  this.videoReady = true; // stop the spinner
                  hls.destroy();
                  break;
              }
            } else {
              this.videoReady = true; // stop the spinner
            }
          });
        } else if (
          this.videoPlayer?.nativeElement.canPlayType(
            'application/vnd.apple.mpegurl'
          )
        ) {
          this.videoPlayer.nativeElement.src = this.resourceUrl;
          this.videoReady = true; // stop the spinner
        } else {
          this.notifier.showError(
            this.translate.instant('dgVideoPlayer_BrowserError')
          );
        }
      })
      .catch(() => {
        this.notifier.showError(
          this.translate.instant('dgVideoPlayer_BrowserError')
        );
      });
  }
}
