import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';

export type AssetConfiguration = {
  JS: string[];
  CSS: string[];
  env: ConfigurationService;
};

/**
 * Create script element to load external script
 *
 * @param string url - JS Script url
 * @param boolean isAsync - Load script async
 * @return HTMLElement
 */
export function createScript(url: string, isAsync = false) {
  const script = document.createElement('script');
  script.src = url;

  // Force scripts to execute in order, see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script
  script.async = isAsync;
  return script;
}

/**
 * Loads external scripts
 *
 * @param string[] url - Array of JS urls
 * @param ConfigurationService env - Service for rendering CDN Url
 * @return Array
 */
export function loadScript(
  urls: string[],
  env: ConfigurationService
): Promise<any>[] {
  const { head } = document;
  const promises = [];

  urls.forEach((url) => {
    url = env.getCdnUrl(url);

    if (!head.querySelector(`script[src="${url}"]`)) {
      const script = createScript(url);
      head.appendChild(script);
      promises.push(
        new Promise((resolve, reject) => {
          script.addEventListener('load', () => resolve(url));
          script.addEventListener('error', () => reject(url));
        })
      );
    }
  });

  return promises;
}

/**
 * Create <link> element to load external stylesheet
 *
 * @param string url - Asset urls
 * @return HTMLElement
 */
export function createStylesheet(url): HTMLElement {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  return link;
}

/**
 * Loads external stylsheets by appending a <link> element
 *
 * @returns string[] - Array of CDN urls that were loaded
 */
export function loadStylesheets(
  urls: string[],
  env: ConfigurationService
): string[] {
  const { head } = document;
  const cdnUrls: string[] = [];

  urls.forEach((url) => {
    url = env.getCdnUrl(url);
    const selector = `link[rel="stylesheet"][href="${url}"]`;
    const hasSheet = head.querySelector(selector);

    if (!hasSheet) {
      cdnUrls.push(url);
      head.appendChild(createStylesheet(url));
    }
  });

  return cdnUrls;
}

/**
 * Checks if assets were loaded successfully
 */
export function checkAssetsLoaded(results): string[] {
  const errors = results
    .filter((result) => result.status === 'rejected')
    .map((result) => result.reason);
  const values = results
    .filter((result) => result.status !== 'rejected')
    .map((result) => result.value);

  if (errors.length) {
    const message = `Issue loading resources: ${errors
      .map((error) => error)
      .join(', ')}`;
    throw Error(message);
  }
  return values;
}

/**
 * Loads external assets (CSS and JS)
 *
 * @param Array CSS - CSS urls
 * @param Array JS - JS urls
 * @param ConfigurationService env - Service for rendering CDN Url
 *
 * @return Promise
 */
export function loadAssets({
  JS,
  CSS,
  env,
}: AssetConfiguration): Promise<string[]> {
  const cssUrlsLoaded = loadStylesheets(CSS, env);
  const reportAllUrls = (jsUrls: string[]) => [...jsUrls, ...cssUrlsLoaded];

  return (Promise as any) // TODO: Use until we add tsconfig lib es2020 support
    .allSettled(loadScript(JS, env))
    .then(checkAssetsLoaded)
    .then(reportAllUrls);
}
