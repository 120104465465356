import { Injectable } from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'highcharts';

export interface LegendItem {
  color: string;
  display: string;
}

@Injectable({ providedIn: 'root' })
export class LearningChartService {
  public selectedDataType: any;
  public selectedInputType: any;
  public selectedPeriod: any;
  public selectedTag: string;

  public readonly CONTENT_TYPE = {
    article: 'article',
    video: 'video',
    book: 'book',
    course: 'course',
    event: 'event',
    episode: 'episode',
    assessment: 'assessment',
    task: 'task',
    post: 'post',
    position: 'position',
  };
  public readonly CHART_COLORS = {
    [this.CONTENT_TYPE.article]: this.colorService.getColor('ocean'),
    [this.CONTENT_TYPE.video]: this.colorService.getColor('ocean-dark'),
    [this.CONTENT_TYPE.book]: this.colorService.getColor('sky-medium'),
    [this.CONTENT_TYPE.course]: this.colorService.getColor('sky-dark'),
    [this.CONTENT_TYPE.event]: this.colorService.getColor('pink-medium'),
    [this.CONTENT_TYPE.position]: this.colorService.getColor('yellow-dark'),
    [this.CONTENT_TYPE.episode]: this.colorService.getColor('pink-dark'),
    [this.CONTENT_TYPE.assessment]: this.colorService.getColor('fuchsia'),
    [this.CONTENT_TYPE.task]: this.colorService.getColor('orange'),
    [this.CONTENT_TYPE.post]: this.colorService.getColor('orange-dark'),
  };

  private legendMap: Dictionary<LegendItem>;

  private readonly COLUMN_PREFIX = 'Data';
  private readonly ITEM_COLUMN_ALIAS = 'Count';

  constructor(
    private colorService: ColorService,
    private translateService: TranslateService,
    private ldFlagService: LDFlagsService
  ) {
    this.legendMap = this.createLegendMap();
  }

  public extractChartData(totalChartData: any, type: string): any {
    // take in all of the data and return a new object with the X axis data and only
    // data for the given 'type' as <type>Data, i.e. ArticleData, BookData, VideoData, etc...

    // to keep true to how the chart expects no data
    if (!totalChartData) {
      return null;
    }

    // todo: should probably change where 'type' is being sent into this service to match
    // result set that is returned, like 'Points' and 'Views'.
    // type = 'Items' is actually 'Count'
    const filterType = type === 'Items' ? this.ITEM_COLUMN_ALIAS : type;

    // always these two props?
    const { XAxisInterval, XAxisLabels, dates } = totalChartData;
    const typeDataArray = Object.keys(totalChartData)
      // pull out all of the keys that are of a given 'type', i.e. 'Count' or 'Points', etc.
      .filter((k) => k.endsWith(filterType))
      // map a key/value pair with the new key ending in 'Data' and
      // the data from the 'type' key
      // i.e. ArticleCount/ArticlePoints => ArticleData
      // i.e. BookCount/BookPoints => BookData
      .map((k) => {
        const newKey = k.replace(filterType, this.COLUMN_PREFIX);

        return {
          [newKey]: totalChartData[k],
        };
      });

    // create a new object with the axis and 'type' specific chart data.
    const chartData = {
      // dataSetCount is to identify how many datasets are filtered for the chart
      dataSetCount: typeDataArray.length,
      XAxisInterval,
      XAxisLabels,
      dates,
    };

    // merge the filtered data into the chart data
    for (const filtered of typeDataArray) {
      // eslint-disable-next-line guard-for-in
      for (const name in filtered) {
        chartData[name] = filtered[name];
      }
    }

    return chartData;
  }

  public getLegend(chartData: any): LegendItem[] {
    if (!chartData) {
      return [];
    }

    // Since we know that the chartData contains generic chart data in the form
    // of props that end with 'COLUMN_PREFIX', we can filter those props out
    // then use them to pull the filter the legendMap.
    const items = Object.keys(chartData)
      // filter the props in the chartData that ends with 'Data'

      .filter((k) => k.endsWith(this.COLUMN_PREFIX))
      // pull out the legend item that match the chart data column
      .map((k) => this.legendMap[k])
      // order alpha
      .sort((legendItemA, legendItemB) =>
        legendItemA.display > legendItemB.display ? 1 : -1
      );
    return items;
  }

  public transformDataToPascal(data: any) {
    // The chart data payload casing needs to abide by the following in order to bind correctly.
    // BEWARE: You cannot use the following: "data = pascalCaseKeys(data)".
    // The pascalCaseKeys method changed each key's value data type from a number to an object data type.
    const toPascalCase = (str: string) =>
      str.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1));
    Object.keys(data).forEach((key) => {
      if (key !== 'dates') {
        const newKey = toPascalCase(key);
        data[newKey] = data[key];
        delete data[key];
      }
    });
    return data;
  }

  private createLegendMap(): Dictionary<LegendItem> {
    const map: Dictionary<LegendItem> = {
      ArticleData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.article],
        display: this.translateService.instant('Core_ArticlesDisplayName'),
      },
      VideoData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.video],
        display: this.translateService.instant('Core_VideosDisplayName'),
      },
      BookData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.book],
        display: this.translateService.instant('Core_BooksDisplayName'),
      },
      CourseData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.course],
        display: this.translateService.instant('Core_CoursesDisplayName'),
      },
      EventData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.event],
        display: this.translateService.instant('Core_EventsDisplayName'),
      },
      EpisodeData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.episode],
        display: this.translateService.instant('Core_PodcastsDisplayName'),
      },
      AssessmentData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.assessment],
        display: this.translateService.instant('Core_AssessmentsDisplayName'),
      },
      TaskData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.task],
        display: this.translateService.instant('Core_TasksDisplayName'),
      },
      PostData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.post],
        display: this.translateService.instant('Core_PostsDisplayName'),
      },
      PositionData: {
        color: this.CHART_COLORS[this.CONTENT_TYPE.position],
        display: this.translateService.instant('Core_ExperiencesDisplayName'),
      },
    };
    return map;
  }
}
