import { PDFZoomControls } from './../../../services/pdf-viewer.service';
import { PDFViewerEvent, PDFViewerService } from '@degreed/content-hosting';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventBus, Unsubscribe } from '@app/shared/services/event-bus';
import { DgError } from '@app/shared/models/dg-error';

/**
 * Content Hosting PDF Viewer Component
 */
@Component({
  selector: 'dgx-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PDFViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public fileUrl: string;
  isLoading = true;
  i18nFileType: string;
  @ViewChild('pdfContainer', { static: false }) pdfContainerEl: ElementRef;
  private detachEventBus: Unsubscribe;
  controls: PDFZoomControls = this.viewerService.controls;

  constructor(
    private viewerService: PDFViewerService,
    private translate: TranslateService,
    private eventBus: EventBus,
    private cdr: ChangeDetectorRef
  ) {
    this.detachEventBus = this.eventBus.on(PDFViewerEvent.PDF_INIT, () =>
      this.showLoading(false)
    );
  }

  ngOnInit(): void {
    this.i18nFileType = this.translate.instant('A11y_virtualDocument', {
      // there will be other file types eventually but right now PDF is the only one
      documentType: this.translate.instant('Core_PDF'),
    });
  }

  ngAfterViewInit() {
    this.viewerService
      .renderPDF({
        fileUrl: this.fileUrl,
        containerEl: this.pdfContainerEl,
      })
      .catch((error) => {
        this.showLoading(false);
        throw new DgError(error);
      });
  }

  ngOnDestroy(): void {
    this.detachEventBus();
  }

  private showLoading(isLoading = true) {
    this.isLoading = isLoading;
    this.cdr.markForCheck();
  }
}
