<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!isMobile; else search">
    <ng-container *ngTemplateOutlet="searchPopover"></ng-container>
  </ng-container>

  <!-- Displays a 'dummy' search field that when clicked will open the popover with the real search field -->
  <ng-template #searchPopover>
    <dgx-search-field-wrapper
      class="full-width"
      [type]="!shouldRenderInGlobalSearchMode ? 'large' : undefined"
      [shouldRenderInGlobalSearchMode]="shouldRenderInGlobalSearchMode"
    >
      <span
        #searchInitiation
        (focusin)="onInitiationFocus()"
        [attr.aria-label]="i18n.Core_Search"
        tabindex="0"
        type="search"
        attr.aria-describedby="search-input-desc-{{ instanceId }}"
        attr.aria-owns="search-initiation-{{ instanceId }}"
        aria-haspopup="menu"
        [attr.aria-expanded]="isPopoverOpen"
        attr.aria-controls="search-initiation-{{ instanceId }}"
        class="search-initiator clamp break full-width h3 global-search__input l_flexbar"
        [dgxLineClampText]="vm.searchTerm || i18n.Core_Search"
        [clampLinesMax]="1"
        [ngClass]="{ 'search-initiator-placeholder': !vm.searchTerm }"
        [attr.data-dgat]="dgat"
      >
      </span>
    </dgx-search-field-wrapper>

    <df-popover
      #popoverComponent
      id="search-initiation-{{ instanceId }}"
      class="search-initiation-autocomplete"
      [class]="
        'search-initiation-autocomplete search-initiation-autocomplete__popover search-initiation-autocomplete__popover-' +
        size
      "
      (focusout)="onFocusOut($event)"
      [(isOpen)]="isPopoverOpen"
      (isOpenChange)="onIsPopoverOpenChange($event)"
      [popoverTrigger]="searchInitiationRef"
      placement="bottom"
      [placementAdjustTopRem]="-4"
      [placementAdjustLeftRem]="-1.6"
      [isFocusFirstItem]="false"
    >
      <ng-container *ngTemplateOutlet="search"></ng-container>
    </df-popover>
  </ng-template>

  <!-- Displays the global search field & results -->
  <ng-template #search>
    <div
      (keyup)="handleKeyup($event)"
      class="guts-p-v-1 guts-p-h-1"
      [attr.aria-label]="i18n.Core_Search"
    >
      <form #form="ngForm" (ngSubmit)="submit(form)">
        <dgx-search-field-wrapper
          class="full-width"
          styles="d-full-width"
          type="search-popover"
        >
          <input
            #searchInput
            id="search-input-{{ instanceId }}"
            type="search"
            name="searchTerm"
            class="search-initiation-autocomplete__input"
            #term="ngModel"
            [ngModel]="searchTerm$ | async"
            [placeholder]="i18n.Core_Search"
            autocomplete="off"
            [attr.aria-label]="i18n.Core_Search"
            attr.aria-describedby="search-input-desc-{{ instanceId }}"
            spellcheck="true"
            (input)="onSearch($event)"
            data-dgat="search-initiation-autocomplete-beb"
          />
        </dgx-search-field-wrapper>

        <!-- AI Search POC -->
        <div class="guts-m-t-1 guts-m-b-2" *ngIf="naturalLanguageSearchEnabled">
          <div class="degreed-assistant">
            <!-- no search term -->
            <ng-container *ngIf="!vm.searchTerm">
              <div class="guts-p-l-half">
                Ask Degreed Assistant a question in search
              </div>
              <div
                class="guts-p-l-half guts-m-t-half example par--light_a par--small"
              >
                <span class="fw-bold">Example:</span> &ldquo;What are the best
                practices for creating engaging social media content?&rdquo;
              </div>
              <div
                class="guts-p-l-half guts-m-t-half example par--light_a par--small"
              >
                <span class="fw-bold">Example:</span> &ldquo;Provide tips for
                improving presentation and speaking skills.&rdquo;
              </div>
            </ng-container>
            <!-- search term, no AI result -->
            <ng-container
              *ngIf="
                vm.searchTerm?.length > 1 &&
                !vm.runningNLQuery &&
                !vm.queryResponse
              "
            >
              <div class="grid grid--bleed">
                <div class="grid__col-sm-10">
                  <div class="guts-p-l-half">Degreed Assistant</div>
                  <div class="guts-p-l-half par--light_a par--small">
                    Generate intelligent answers to your search
                  </div>
                </div>
                <div class="grid__col-sm-2 grid--justify-center">
                  <dgx-action-button
                    (click)="getNaturalLanguageSearchResults($event)"
                    [autoToggleState]="false"
                    btnType="button"
                    dgatInput="search-initiation-autocomplete-5f3"
                    >Ask</dgx-action-button
                  >
                </div>
              </div>
            </ng-container>

            <!-- search term, querying AI result-->
            <ng-container
              *ngIf="vm.searchTerm?.length > 1 && vm.runningNLQuery"
            >
              <div class="center-text">
                <span>Generating response...</span>
                <div class="guts-m-b-2 guts-m-t-2">
                  <df-spinner [isSpinning]="vm.runningNLQuery"> </df-spinner>
                </div>
              </div>
            </ng-container>

            <!-- search term, querying AI result-->
            <ng-container
              *ngIf="
                vm.searchTerm?.length > 1 &&
                !vm.runningNLQuery &&
                vm.queryResponse
              "
            >
              <h3
                class="h4 guts-p-l-half par--light_a guts-m-b-1 border-bottom"
              >
                Answered by Degreed Assistant
              </h3>
              <div
                class="guts-p-l-half guts-p-r-half guts-m-b-1 query-response"
                [innerHTML]="vm.queryResponse | safe: 'html'"
              ></div>
              <div class="guts-p-full-1" *ngIf="vm.references?.length > 0">
                <h3 class="h4 par--light_a guts-m-b-1 border-bottom">
                  Sources
                </h3>
                <div class="query-sources">
                  <ul class="guts-p-1">
                    <li
                      *ngFor="let reference of vm.references"
                      data-dgat="search-initiation-autocomplete-85e"
                      class="guts-m-b-half"
                    >
                      <a
                        class="link"
                        [href]="reference.Url"
                        data-dgat="search-initiation-autocomplete-c65"
                        >{{ reference.Title }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </form>

      <span #searchResults>
        <ng-container>
          <!-- Initiation/Autocomplete Results -->
          <dgx-search-initiation
            *ngIf="vm.shouldShowInitiation"
            [suggestions]="vm.defaultSuggestions"
            [showDefaults]="showDefaults"
            [shouldShowSuggestions]="vm.shouldShowSuggestions"
            (skillSelect)="vm.initiationSkillSelect($event); closeDropdown()"
            (filterSelect)="vm.initiationFilterSelect($event); closeDropdown()"
          ></dgx-search-initiation>
          <dgx-search-autocomplete
            *ngIf="vm.shouldShowAutocomplete"
            [suggestions]="vm.suggestions"
            [termSuggestionsOnly]="termSuggestionsOnly"
            (autosuggestSelect)="vm.autosuggestSelect($event); closeDropdown()"
            (typeSelect)="vm.typeSelect($event); closeDropdown()"
            (suggestionSelect)="vm.suggestionSelect($event); closeDropdown()"
          ></dgx-search-autocomplete>
        </ng-container>
      </span>

      <div
        *ngIf="!termSuggestionsOnly"
        class="l_flex l_flex-middle l_flex-justify"
      >
        <!-- Search Link -->
        <div
          tabindex="0"
          role="link"
          (click)="vm.emptySearch(); closeDropdown()"
          (keydown)="
            keydownHandler($event, vm.emptySearch.bind(this), closeDropdown)
          "
          class="search-initiation-autocomplete-search guts-p-r-1"
        >
          <a
            class="search-initiation-autocomplete-search__link"
            data-dgat="search-initiation-autocomplete-ff3"
          >
            <df-icon
              size="medium"
              icon="magnifying-glass"
              class="search-initiation-autocomplete-search__icon"
              role="presentation"
            ></df-icon>
            <span>{{ i18n.LearningSearch_GoToSearch }}</span>
          </a>
        </div>
        <div
          *ngIf="showGotoMarketplace"
          tabindex="0"
          role="link"
          (click)="goToMarketplace(); closeDropdown()"
          (keydown)="
            keydownHandler(
              $event,
              vm.emptyMarketplaceSearch.bind(this),
              closeDropdown
            )
          "
          class="search-initiation-autocomplete-search guts-p-r-1"
        >
          <a
            class="search-initiation-autocomplete-search__link"
            data-dgat="search-initiation-autocomplete-275"
          >
            <df-icon
              size="medium"
              icon="magnifying-glass"
              class="search-initiation-autocomplete-search__icon"
              role="presentation"
            ></df-icon>
            <span>{{ i18n.LearningSearch_GoToMarketplace }}</span>
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
