<div class="l_flexbar guts-p-full-1" data-dgat="typeaheadPopup2-287">
  <div class="guts-m-r-1">
    <df-icon
      class="thumb-smaller color-ebony-a18"
      *ngIf="!item.picture"
      [icon]="item.resourceType === 'Group' ? 'person-triple' : 'person-double'"
      size="large"
    ></df-icon>
    <!-- NOTE: since this is a search and the profile image is only used as decorative it does not need to
    use the image aria label on the profile picture -->
    <dgx-profile-pic
      *ngIf="item.picture"
      size="small"
      [profile]="item"
      [hideTooltip]="true"
    ></dgx-profile-pic>
  </div>
  <div class="l_flex-grow l_flex__ellipsis-wrapper left-text">
    <h3 class="no-wrap par par--small ellipsis font-semibold" *ngIf="item.name">
      {{ item.name }}
    </h3>
    <span
      class="no-wrap ellipsis par--light"
      *ngIf="item.organizationEmail || item.email"
    >
      {{ item.organizationEmail || item.email }}
    </span>
  </div>
</div>
