/**
 * Returns true if an object is null or has no properties defined.
 * @param value
 * @returns
 */
export function isEmpty(value: any): boolean {
  if (value == null) {
    return true;
  }

  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      return false;
    }
  }

  return true;
}
