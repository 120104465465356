<div
  class="loading-spinner loading-spinner--{{ spinnerContext }}"
  [class.loading-spinner--hidden]="!isSpinning"
  role="progressbar"
>
  <div class="loading-spinner__bar"></div>
  <div class="loading-spinner__bar"></div>
  <div class="loading-spinner__bar"></div>
  <div class="loading-spinner__bar"></div>
</div>
