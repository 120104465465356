import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';
import { UserProfile, UserProfileSummary } from '@app/user/user-api.model';

@Component({
  selector: 'dgx-social-pics',
  templateUrl: './social-pics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPicsComponent {
  @Input() public size: string; // Size of the profile pic this component is decorating
  @Input() public maxUsers?: number; // The max users to show
  @Input() public totalUsers?: number; // The total users - used to show leftover users
  /** Whether to show leftover user count (+x) */
  @Input() public hideLeftOverUsers?: boolean = false;
  private _users: UserProfile[];
  @Input() public set users(users: UserProfile[]) {
    this._users = users.map((user) => camelCaseKeys(user));
  }
  public get users(): UserProfile[] {
    return !!this.maxUsers ? this._users.slice(0, this.maxUsers) : this._users;
  }

  public get leftOverUsers(): number {
    if (!!this.totalUsers) {
      const leftOver = this.totalUsers - this.users.length;

      if (isNaN(leftOver) || leftOver < 1) {
        return;
      }

      return leftOver;
    }
  }

  public trackByFn(_: number, user: UserProfile): number {
    return user.userProfileKey;
  }
}
