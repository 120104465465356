import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrgNewGroupSettingsInfo } from '@app/shared/services/group.service';

@Component({
  selector: 'dgx-org-new-group-settings',
  templateUrl: './org-new-group-settings.component.html',
})
export class OrgNewGroupSettingsComponent {
  @Input() public orgNewGroupSettingsInfo: OrgNewGroupSettingsInfo;
  @Output()
  public saveGroupId: EventEmitter<number> = new EventEmitter<number>();

  constructor(private activeModal: NgbActiveModal) {}

  public close(): void {
    this.activeModal.dismiss();
  }

  public save(data: any) {
    this.activeModal.close(data.groupId);
  }
}
