import { animate, style, transition, trigger } from '@angular/animations';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AuthUser } from '@app/account/account-api.model';
import {
  GlobalAddInputAction,
  GlobalAddOutcomeAction,
} from '@app/global-add/global-add.model';
import { GlobalAddService } from '@app/global-add/services/global-add.service';
import { UserOutcomeModalService } from '@app/outcomes/services/user-outcome-modal.service';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { PathwayAddEditFormModalService } from '@app/pathways/services/pathway-add-edit-form-modal-service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { InputType } from '@app/shared/models/core-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import { InputModalDispatcherService } from '@app/user-content/user-input/services/input-modal-dispatcher.service';
import { LDFlagsService, TrackerService } from '@dg/shared-services';

import { DialogData } from '@degreed/apollo-angular';

/**
 * This is a isolated version of the GlobalAddMenuComponent that is used in the app header.
 * This version only has the services and content and excludes all the other wrapper components
 * in the v1 GlobalAddMenuComponent
 */
@Component({
  selector: 'dgx-add-content',
  templateUrl: 'add-content.component.html',
  styleUrls: ['./add-content.component.scss'],
  animations: [
    trigger('PanelTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(95%)' }),
        animate(
          '300ms ease-out',
          style({ opacity: 1, transform: 'scale(100%)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(100%)' }),
        animate(
          '200ms ease-in-out',
          style({ opacity: 0, transform: 'scale(95%)' })
        ),
      ]),
    ]),
  ],
})
export class AddContentComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public outcomeActions: Partial<GlobalAddOutcomeAction>[];
  public inputActions: Partial<GlobalAddInputAction>[];
  public i18n = this.translateService.instant([
    'dgUtilityBar_AddToProfileTitle',
    'dgGlobalAdd_Content',
    'dgGlobalAdd_Description',
    'dgGlobalAdd_Achievement',
    'dgGlobalAdd_AchievementDescription',
  ]);
  public authUser: AuthUser;
  public showIntegrationsSection: boolean;
  public extensionsText: string;
  public integrationsText: string;

  private isChannelContext: boolean;

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private contextService: ContextService,
    private globalAddService: GlobalAddService,
    private translateService: TranslateService,
    private pathwayAddEditModalService: PathwayAddEditFormModalService,
    private genericInputModalService: InputModalDispatcherService,
    private userOutcomeModalService: UserOutcomeModalService,
    private userOutcomeService: UserOutcomeService,
    private trackerService: TrackerService,
    private ldFlagsService: LDFlagsService
  ) {
    super();
  }

  public ngOnInit() {
    this.dialogRef.addPanelClass('apollo-dialog-panel');

    this.isChannelContext = this.contextService.urlHasContext(
      location.href,
      'channel'
    );
    this.authService.authUser$
      .pipe(this.takeUntilDestroyed())
      .subscribe((authUser) => {
        this.authUser = authUser;
        this.showIntegrationsSection =
          !this.authUser?.orgInfo[0]?.settings
            ?.hidePersonalIntegrationsInUserProfileSettings;
      });

    this.inputActions = this.globalAddService.globalAddInputs
      .filter((input) => input.type !== 'Task')
      .map((input) => {
        return {
          ...input,
          ...{
            handlerFn: this.handleInput,
            dgat: 'globalAdd-69e',
          },
        };
      });
    this.inputActions.push({
      type: 'CreatePathway',
      handlerFn: this.createPathway,
      dgat: 'globalAdd-843',
    });
    this.outcomeActions = this.globalAddService.globalAddOutcomes.map(
      (outcome) => {
        return {
          ...outcome,
          ...{
            handlerFn: this.handleOutcome,
            dgat: 'globalAdd-21a',
          },
        };
      }
    );

    const settingsUrl = '/me/settings#';

    const hideExtension =
      this.authUser?.defaultOrgInfo?.settings?.restrictExtension;
    this.extensionsText = hideExtension
      ? ''
      : this.translateService.instant('dgGlobalAdd_ExtensionsFormat', {
          startAnchor: `<a href="${settingsUrl}/extensions" class="link" data-dgat="globalAdd-78c">`,
          endAnchor: '</a>',
        });

    this.integrationsText = this.translateService.instant(
      'dgGlobalAdd_IntegrationsFormat',
      {
        startAnchor: `<a href="${settingsUrl}/integrations" class="link" data-dgat="globalAdd-ddd">`,
        endAnchor: '</a>',
      }
    );
  }

  // arrow function for context
  public handleInput = (event: Event, input: GlobalAddInputAction) => {
    this.dialogRef.close();
    this.genericInputModalService
      .complete({
        inputType: input.type as InputType,
        sourceTarget: undefined, // this.menuButtonRef.elementRef.nativeElement,
        trackingArea: 'Global Add',
      })
      .pipe(this.takeUntilDestroyed());
  };

  // arrow function for context
  public handleOutcome = (event: Event, outcome: GlobalAddOutcomeAction) => {
    this.dialogRef.close();
    this.userOutcomeModalService
      .add({
        outcomeType: outcome.type,
        sourceTarget: undefined, // this.menuButtonRef.elementRef.nativeElement,
        trackingAction: 'Achievement Added',
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        if (!this.ldFlagsService.showUpdatedAchievementModals) {
          this.userOutcomeService.notifyOutcomeModified(outcome.type);
        }
      });
  };

  // arrow function for maintaining context coming from ajs
  public createPathway = () => {
    const options = {
      pathId: 0,
      forCloning: false,
      itemToAdd: null,
      isChannel: this.isChannelContext,
      disableEndorsedOption: true,
      trackingArea: 'Global Add',
    };

    this.dialogRef.close();
    this.pathwayAddEditModalService.openModal(options).subscribe();
  };

  public trackGlobalAddClicked() {
    this.trackerService.trackEventData({
      action: 'Global Add Clicked',
      properties: {},
    });
  }
}
