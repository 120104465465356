import { Inject, Injectable } from '@angular/core';
import type { Chart, HTMLDOMElement } from 'highcharts';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HighchartsLazy, HIGHCHARTS_LAZY } from './highcharts-lazy.token';

@Injectable({ providedIn: 'root' })
export class HighchartsService {
  public colors = {
    lightCoral: '#FDDCD6',
    coral: '#F54E31',
    darkCoral: '#C2341B',
    // orange
    lightOrange: '#FAE3CC',
    orange: '#E87400',
    darkOrange: '#B54E00',
    // forest green
    lightForestGreen: '#D8EECD',
    forestGreen: '#3DA807',
    darkForestGreen: '#297504',
    // ocean blue
    lightOceanBlue: '#CCEDED',
    oceanBlue: '#00A6A6',
    darkOceanBlue: '#007373',
    // sky blue
    lightSkyBlue: '#CCECF7',
    skyBlue: '#009FD9',
    darkSkyBlue: '#007099',
    // purple
    lightPurple: '#E5D9F9',
    purple: '#7F43E0',
    darkPurple: '#562AAD',
    // fuschia
    lightFuschia: '#F1D7F2',
    fuschia: '#B844BD',
    darkFuschia: '#85128A',
    // pink
    lightPink: '#FBD8E8',
    pink: '#EB3D7D',
    darkPink: '#B81C62',
  };

  private highcharts = from(this.highchartsLazy);

  constructor(
    @Inject(HIGHCHARTS_LAZY) private highchartsLazy: HighchartsLazy
  ) {}

  /**
   * Ensure the lazy-loaded highcharts service is ready, then create the chart
   */
  public createChart(
    element: string | HTMLDOMElement,
    options: any
  ): Observable<Chart> {
    return this.highcharts.pipe(
      switchMap(
        (highcharts) =>
          new Observable<Chart>((subscriber) => {
            // TODO: ensure the element still exists?
            // const elementExists = document.body.contains(element);
            if (element) {
              highcharts.chart(element, options, (chart) => {
                subscriber.next(chart);
                subscriber.complete();
              });
            } else {
              highcharts.chart(options, (chart) => {
                subscriber.next(chart);
                subscriber.complete();
              });
            }
          })
      )
    );
  }

  public getChartColors() {
    return this.colors;
  }
}
