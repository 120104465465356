import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { InputType } from '@app/shared/models/core-api.model';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { TrackerService } from '@app/shared/services/tracker.service';
import { catchAndRedirectError } from '@app/shared/utils';
import {
  CHFileLoaderService,
  HostType,
} from '@degreed/content-hosting-data-access';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/**
 *  Content Hosting Preview Component
 *  @description The base component for Content Hosting that loads the file content and its meta data.
 */
@Component({
  selector: 'dgx-content-hosting-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CHPreviewComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public input$: Observable<LearningResourceViewModel>;
  public inputId: number;
  public inputType: InputType;
  /** Show or hide the preview controls based on the mobile query param */
  public showPreviewControls = true;

  private hostType: HostType;

  constructor(
    private fileLoaderService: CHFileLoaderService,
    private inputsService: InputsService,
    private inputToLearningResourcePipe: InputToLearningResourcePipe,
    private trackerService: TrackerService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    // This can use the angular way once the input cshtml migration is completed
    //  const { d, inputType }: Params = this.activeRoute.snapshot.queryParams;

    const paramMap = new URLSearchParams(window.location.search);

    this.inputId = parseInt(paramMap.get('d'));
    // for some reason the toString() in the C# method where this is being provided is lowercase, FE expects uppercase
    // TODO: figure out if we can get this properly cased so we don't have to do this step.
    const inputTypeLowerCase = paramMap.get('inputtype') as InputType;
    this.inputType = (inputTypeLowerCase?.charAt(0)?.toUpperCase() +
      inputTypeLowerCase?.slice(1)) as InputType;

    if (!this.inputId || !this.inputType) {
      window.location.href = '/error-handler/404';
      return;
    }

    this.input$ = this.inputsService
      .getInputAndStatistics(
        {
          inputId: this.inputId,
          inputType: this.inputType,
        },
        true
      )
      .pipe(
        tap((data: any) => {
          this.hostType = data.input.hostedType;
        }),
        map((data: any) =>
          this.inputToLearningResourcePipe.transform(data.input)
        ),
        catchAndRedirectError<LearningResourceViewModel>('/error-handler/404')
      );

    this.trackerService.setLocation('Content Hosting Viewer');

    this.route.queryParams
    .pipe(this.takeUntilDestroyed())
    .subscribe((params) => {
      const mobile = params['mobile'];
      // Hide the controls if mobile=1
      this.showPreviewControls = mobile !== '1';
    });
  }

  public get canLoadAzureHostedFile() {
    return this.fileLoaderService.canLoadFile(this.hostType);
  }
}
