import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { ModalService } from '@app/shared/services/modal.service';
import { Inject, Injectable } from '@angular/core';
import { PathwayDetailsModel } from '../pathway-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowToken } from '@app/shared/window.token';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { DgError } from '@app/shared/models/dg-error';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TipService } from '@app/onboarding/services/tip.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PathwayEnrollmentService {
  constructor(
    private http: NgxHttpClient,
    private trackerService: TrackerService,
    private translate: TranslateService,
    private tipService: TipService,
    private modalService: ModalService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  /**
   * Enrolls the user in the Pathway
   * @param pathway the full pathway object
   * @param isAutoEnroll determines if the user is implicitly enrolling
   * @param trackableLinkTag if the user follows the pathway by clicking a trackable link, pass the tracking info to the server
   * @param redirect set to true to redirect to the pathway after enrolling
   * @param showPathwayModal set to true to show to the pathway after enrolling
   */
  public enroll = (
    pathway: PathwayDetailsModel,
    isAutoEnroll: boolean = false,
    trackableLinkTag: string = '',
    redirect: boolean = false,
    showPathwayModal: boolean = true
  ) => {
    return this.http
      .post<void>('/pathways/enroll', {
        pathId: pathway.id || pathway.resourceId,
        explicitEnrollment: !isAutoEnroll,
        trackableLinkTag,
      })
      .pipe(
        tap(() => {
          pathway.isEnrolled = true;
          this.trackerService.trackEventData({
            action: 'Pathway Enrolled',
            category: pathway.privacyLevel?.toString() || '0',
            label: '',
            properties: {
              PathwayName: pathway.title,
              PathwayId: pathway.id || pathway.resourceId,
              IsEndorsed: pathway.isEndorsed,
            },
          });

          if (redirect) {
            this.pathwayRedirect(pathway.publicUrl);
          }

          // Don't show enrollment message on impicit auto-enrollments such as marking an item completed
          if (!isAutoEnroll) {
            if (showPathwayModal) {
              this.tipService.checkFirstEnrollment();
            }
          }
        }),
        catchError((e: HttpErrorResponse) => {
          pathway.isEnrolled = false;
          return throwError(
            new DgError(this.translate.instant('Pathways_FollowError'), e)
          );
        })
      );
  };

  public unenrollConfirmation = (pathway: PathwayDetailsModel) => {
    const modalI18n = this.translate.instant([
      'Pathways_WithdrawFromPathway',
      'Pathways_WithdrawFromPathwayBody',
      'Core_YesSure',
    ]);
    const inputs = {
      canCancel: true,
      closeOnSubmit: false,
      headerText: modalI18n.Pathways_WithdrawFromPathway,
      bodyText: modalI18n.Pathways_WithdrawFromPathwayBody,
      submitButtonText: modalI18n.Core_YesSure,
    };
    return this.modalService
      .show<void>(SimpleModalComponent, { inputs, errorOnDismiss: true })
      .pipe(
        mergeMap(() => {
          return this.unenroll(pathway).pipe(
            catchError((e) => {
              pathway.isEnrolled = true;
              return throwError(e);
            })
          );
        })
      );
  };

  /**
   * Auto enrolls the user to a Pathway behind the scenes via code after an action such as completing first content item
   * @param pathway - existing pathway
   */
  public autoEnroll(pathway: PathwayDetailsModel) {
    const isAutoEnroll = true;
    return this.enroll(pathway, isAutoEnroll);
  }

  private unenroll(pathway: PathwayDetailsModel) {
    return this.http
      .post<void>('/pathways/unenroll', {
        pathId: pathway.id || pathway.resourceId,
      })
      .pipe(
        tap(() => {
          this.trackerService.trackEventData({
            action: 'Pathway Withdrawn',
            category: pathway.privacyLevel?.toString() || '0',
            properties: {
              PathwayId: pathway.id || pathway.resourceId,
              IsEndorsed: pathway.isEndorsed,
            },
          });
        }),
        catchError((e) => {
          pathway.isEnrolled = true;
          return throwError(
            new DgError(this.translate.instant('Pathways_UnfollowError'), e)
          );
        })
      );
  }

  private pathwayRedirect(pathPublicUrl: string) {
    if (pathPublicUrl) {
      this.windowRef.location.href = pathPublicUrl;
    }
  }
}
