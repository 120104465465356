import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfAccessibilityService } from './accessibility.service';
import { DfAccessibilityReaderService } from './accessibility-reader.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [DfAccessibilityService, DfAccessibilityReaderService],
  exports: [],
})
export class DfAccessibilityModule {}
