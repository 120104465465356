<form (ngSubmit)="checkAndSave($event)">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="i18n.PathwayPrivacyModal_SaveVisibility"
    [useDefaultForm]="false"
    [isSubmitPending]="isSubmitting"
    [isSubmitDisabled]="isSubmitting"
  >
    <ng-container class="modal-header">
      {{ i18n.PathwayPrivacyModal_ChangeVisibility }}
    </ng-container>
    <ng-container class="modal-body">
      <fieldset class="guts-p-full-1">
        <legend
          class="form__label"
          *ngIf="pathwayVisibilityInfo.userBelongsToOrg"
        >
          {{ i18n.PathwayPrivacyModal_FoundWhere }}
        </legend>
        <div
          *ngFor="let option of privacyOptions"
          data-dgat="pathwayPrivacy-e39"
          class="selection-box"
        >
          <label class="l_flex m-l_flex selection-box__label"
            ><div class="l_flex-grow v-middle-children">
              <input
                type="radio"
                required
                name="visibility"
                [value]="option.id"
                [(ngModel)]="pathwayPrivacyLevel"
                data-dgat="pathwayPrivacy-d33"
                [disabled]="isSubmitting"
              />
              <span class="selection-box__text par--small guts-m-l-half">{{
                option.name
              }}</span>
            </div>
            <div *ngIf="option.id !== 0">
              <df-icon
                *ngIf="pathwayPrivacyLevel !== option.id"
                icon="chevron-down"
                size="small"
              ></df-icon>
              <df-icon
                *ngIf="pathwayPrivacyLevel === option.id"
                icon="chevron-up"
                size="small"
              ></df-icon></div
          ></label>

          <!-- My Profile/Public Share Link -->
          <div
            *ngIf="option.id === 3 && pathwayPrivacyLevel === 3"
            [@dfCollapseExpand]
          >
            <div class="selection-box__extras guts-p-h-2 m-guts-m-t-1">
              <dgx-copy-link
                [url]="pathwayVisibilityInfo.inviteUrl"
                trackingLabel="Pathway Shared"
                trackingCategory="{{ pathwayVisibilityInfo.pathwayId }}"
              ></dgx-copy-link>
            </div>
          </div>

          <!-- Specific Groups -->
          <div
            *ngIf="option.id === 2 && pathwayPrivacyLevel === 2"
            [@dfCollapseExpand]
          >
            <div
              class="selection-box__extras guts-p-h-2 m-guts-m-t-1"
              [class.guts-p-b-0]="!!groupTagsErrorMsg"
            >
              <dgx-group-visibility
                [readOnly]="limitOrgPublish"
                [includeAll]="true"
                [groups]="groups"
                [groupNames]="groupNames"
                [groupIds]="groupIds"
                [selectCallback]="handleGroupsAdd"
                [deselectCallback]="handleGroupsRemove"
                [hideLabel]="true"
                [completing]="false"
              ></dgx-group-visibility>
            </div>
            <dgx-validate-field
              class="block"
              [ngClass]="{ 'guts-p-h-2 guts-p-b-2': groupTagsErrorMsg }"
              [message]="groupTagsErrorMsg"
            ></dgx-validate-field>
          </div>

          <!-- Organization Groups -->
          <div
            *ngIf="option.id === 1 && pathwayPrivacyLevel === 1"
            [@dfCollapseExpand]
          >
            <div class="selection-box__extras guts-p-h-2 m-guts-m-t-1">
              <dgx-group-visibility
                [readOnly]="limitOrgPublish"
                [includeAll]="true"
                [groups]="groups"
                [groupNames]="groupNames"
                [groupIds]="groupIds"
                [selectCallback]="handleGroupsAdd"
                [deselectCallback]="handleGroupsRemove"
                [hideLabel]="false"
                [completing]="false"
                [customTitle]="
                  i18n.orgInternalContentVisibilitySelector_TargetGroupsLabel
                "
                [excludedPrivacyLevels]="excludedGroupPrivacyLevels"
              ></dgx-group-visibility>
            </div>
          </div>
        </div>
      </fieldset>
    </ng-container>
  </dgx-modal>
</form>
