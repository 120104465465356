import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgForm } from '@angular/forms';
import { SearchSuggestionSource } from '@app/search/search-api.model';
import { isKey, Key } from '@app/shared/key';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
  tap,
  of,
} from 'rxjs';
import { SearchAutocompleteFacade } from './search-autocomplete.facade';
import { SearchAutocompleteViewModel } from './search-autocomplete.model';
import { A11yService } from '@app/shared/services/a11y.service';
import { DfPopoverService, DfPopoverComponent } from '@lib/fresco';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import {
  SearchTrackerService,
  SearchTypeaheadInitLocations,
} from '@app/search/services';
import { keydownHandler } from './search-initiation-autocomplete.helpers';
import { LDFlagsService } from '@dg/shared-services';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { switchMap, take } from 'rxjs/operators';
import { OrgSelectorExternalWarningService } from '@app/orgs/services/org-selector-external-warning.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dgx-search-initiation-autocomplete',
  templateUrl: './search-initiation-autocomplete.component.html',
  styleUrls: ['./search-initiation-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInitiationAutocompleteComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public static readonly debounceDuration = 300;
  public static _idCounter = 0;

  /* eslint-disable @typescript-eslint/member-ordering */
  @Input() public isMobile: boolean;
  @Input() public size: 'small' | 'large' = 'small';
  @Input() public showDefaults: boolean = false;
  @Input() public suggestionSource: SearchSuggestionSource; // when undefined causes all available sources to be searched
  @Input() public termSuggestionsOnly: boolean = false;
  @Input() public shouldRenderInGlobalSearchMode: boolean = false;
  @Input() public initiationLocation: SearchTypeaheadInitLocations;

  @Input()
  public set searchTerm(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  @Output() public inputEnter = new EventEmitter<string>();

  @ViewChild('searchResults') public searchResultsRef: ElementRef;
  @ViewChild('searchInitiation') public searchInitiationRef: ElementRef;
  @ViewChild('searchInput') public searchInputRef: ElementRef;
  @ViewChild('popoverComponent')
  public popoverComponentRef: DfPopoverComponent;

  public i18n = this.translateService.instant([
    'Core_Search',
    'LearningSearch_GoToSearch',
    'LearningSearch_GoToMarketplace',
    'OrgSettings_MarketplaceWarning_Default',
  ]);
  public isPopoverOpen: boolean = false; // TODO: Move to state?
  public instanceId = SearchInitiationAutocompleteComponent._idCounter++;
  public vm$: Observable<SearchAutocompleteViewModel>;
  public searchTerm$ = new BehaviorSubject<string>(undefined);
  public keydownHandler = keydownHandler;

  private popoverEscaped: boolean;
  private unlisteners: (() => void)[] = [];
  private authUser: AuthUser;

  constructor(
    private a11yService: A11yService,
    private searchAutocompleteFacade: SearchAutocompleteFacade,
    private translateService: TranslateService,
    private popoverService: DfPopoverService,
    private searchTrackerService: SearchTrackerService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private ldFlagsService: LDFlagsService,
    private orgSettingsService: OrgSettingsService,
    private authService: AuthService,
    private orgSelectorExternalWarningService: OrgSelectorExternalWarningService,
    private router: Router,
    private facade: SearchAutocompleteFacade,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  /* eslint-enable @typescript-eslint/member-ordering */

  // Use a different identifier for app header global search
  public get dgat(): string {
    return this.shouldRenderInGlobalSearchMode
      ? 'search-initiation-autocomplete-995'
      : 'search-initiation-autocomplete-654';
  }

  //// Natural Language POC
  public get naturalLanguageSearchEnabled(): boolean {
    return this.ldFlagsService.search.naturalLanguageCatalogSearch;
  }

  public get showGotoMarketplace(): boolean {
    // make sure this is false for un-authed users
    return (
      (!this.authService?.authUser?.disableDegreedMarketplace &&
        this.ldFlagsService.showMarketplace) ||
      this.ldFlagsService.isPaidCMUser
    );
  }

  public ngOnInit() {
    const initialState = {
      initiationLocation: this.initiationLocation,
      termSuggestionsOnly: this.termSuggestionsOnly,
    };

    this.vm$ = this.searchAutocompleteFacade.initializeVM(initialState);

    this.searchTerm$
      .pipe(
        this.takeUntilDestroyed(),
        debounceTime(SearchInitiationAutocompleteComponent.debounceDuration),
        distinctUntilChanged(), // don't retrigger unless term has changed
        tap((searchTerm) => {
          if (this.isPopoverOpen) {
            // User is likely manually entering a search term so
            // trigger request for suggestions
            this.searchAutocompleteFacade.updateSearchTerm(searchTerm);
          } else {
            // Search term was set programatically so we update the state
            // directly to avoid triggering request for suggestions
            this.searchAutocompleteFacade.updateState({ searchTerm });
          }
        })
      )
      .subscribe();
    this.authUser = this.authService.authUser;
  }

  public onFocusOut(event: FocusEvent) {
    // close popover if an element outside of the popover is focused
    if (
      this.popoverComponentRef.popover?.nativeElement.contains(
        event.relatedTarget as Node
      )
    ) {
      return;
    }

    // Natural Language POC - Don't close when clicking the ask button (prob better way to handle this)
    if ((event.target as HTMLElement).closest('.action-button')) {
      return;
    }

    this.closeDropdown();
  }

  public onInitiationFocus(): void {
    if (this.isPopoverOpen) {
      this.isPopoverOpen = false;
      return;
    }
    if (this.popoverEscaped) {
      this.popoverEscaped = false;
      return;
    }
    this.isPopoverOpen = true;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.searchInputRef.nativeElement.focus();
      }, 0);
    });
    this.searchTrackerService.searchInitiated({
      typeaheadInitiationLocation: this.initiationLocation,
    });
  }

  public onIsPopoverOpenChange(isOpen: boolean): void {
    if (!this.isPopoverOpen && isOpen) {
      this.searchInputRef.nativeElement.focus();
    }
    this.isPopoverOpen = isOpen;
    if (isOpen) {
      this.unlisteners.push(
        this.renderer.listen(
          this.searchInputRef.nativeElement,
          'keydown',
          this.onInputKeydown.bind(this, this.searchResultsRef)
        )
      );
    } else {
      this.removeListeners();
    }
  }

  /**
   * Keep for a11y purposes
   * Prevents popover from reappearing after it was explicitly escaped
   */
  public handleKeyup(event: KeyboardEvent): void {
    if (isKey(event, Key.Escape)) {
      this.popoverEscaped = true;
    }
  }

  public submit(form: NgForm): void {
    this.inputEnter.emit(form.value.searchTerm);
    this.closeDropdown();
  }

  public onSearch(event: Event): void {
    this.searchTerm$.next((event.target as HTMLInputElement).value);
  }

  public closeDropdown = () => {
    if (this.shouldRenderInGlobalSearchMode) {
      this.searchTerm$.next(undefined);
    }

    this.popoverService.close({ preventRefocus: true });
  };

  ///// Natural Language POC
  public getNaturalLanguageSearchResults(event) {
    event.stopPropagation();
    event.preventDefault();
    this.searchAutocompleteFacade.doNaturalLanguageSearch(
      this.searchTerm$.value
    );
  }

  private onInputKeydown(event: KeyboardEvent): void {
    if (isKey(event, Key.Down)) {
      // since this is tracked on an INPUT element, the body class doesn't change automatically. Let's force it here
      event.preventDefault();
      this.document.querySelector('body').classList.remove('mouse-focus');
      this.document.querySelector('body').classList.add('keyboard-focus');
      this.a11yService.focusNextFocusable(this.searchResultsRef.nativeElement);
    }
  }

  private removeListeners(): void {
    for (const unlisten of this.unlisteners) {
      unlisten();
    }
    this.unlisteners = [];
  }

  goToMarketplace() {
    return this.orgSettingsService
      .getSetting(
        this.authUser?.defaultOrgInfo?.organizationId,
        'MarketplaceWarning'
      )
      .pipe(
        take(1),
        switchMap(
          (setting) =>
            setting.enabled
              ? this.orgSelectorExternalWarningService.showMarketplaceWarning(
                  setting.value,
                  'Degreed'
                )
              : of({}) // warning is disabled so just continue navigation
        )
      )
      .subscribe(() => {
        this.facade.emptyMarketplaceSearch();
      });
  }
}
