import { VideoViewerComponent } from './components/content-viewers/video-viewer/video-viewer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommentsModule } from '@app/comments/comments.module';
import { SharedModule } from '@app/shared/shared.module';
import { SharedProvidersModule } from '@app/shared/shared-providers.module';
import { ContentHostingDataAccessModule } from '@degreed/content-hosting-data-access';

import { CHPreviewComponent } from './components';
import { ContentSwitcherComponent } from './components';
import { PDFViewerComponent } from './components/content-viewers/pdf-viewer/pdf-viewer.component';
import { PDFViewerService } from './services';
import { ViewerHeaderComponnet } from './components/content-viewers/viewer-header';
import { ZoomControlsComponent } from './components/content-viewers/controls/zoom';

@NgModule({
  declarations: [
    CHPreviewComponent,
    ContentSwitcherComponent,
    PDFViewerComponent,
    VideoViewerComponent,
    ViewerHeaderComponnet,
    ZoomControlsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommentsModule,
    SharedProvidersModule,
    ContentHostingDataAccessModule,
  ],
  exports: [CHPreviewComponent],
  providers: [PDFViewerService],
})
export class ContentHostingModule {}
