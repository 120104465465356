import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { OutcomesFormsModel } from '@app/user-content/user-outcome-v2/outcome.model';
import { BehaviorSubject, combineLatest, filter, Subject } from 'rxjs';
import { TagsApi } from '@app/tags/tag-api.model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { InferredSkillsModel } from '@app/user-content/user-input-v2/inputs/experience/experience.model';

// TODO: continue to refine and define what should be included in all input types
/**
 * Base Input service to define common functionality, with the ability to overwrite if it is different.
 */
@Injectable()
export abstract class OutcomesFacadeBaseV2<
  TViewModel extends OutcomesFormsModel = OutcomesFormsModel,
> {
  /**
   * Provides notifications to the attached form that either the form view model, the the form UI configuration
   * or both have changed
   */
  public viewModel$ = new BehaviorSubject<TViewModel>(undefined);

  /**
   * Provides notifications indicating the submission status of the form data
   */
  protected submissionStatus$ = new BehaviorSubject<SubmissionStatus>(
    SubmissionStatus.None
  );

  public inferredSkills$ = new BehaviorSubject<TagsApi.Tag[]>([]);

  public inferredSkillsTrigger$ = new Subject<{
    title: string;
    description?: string;
  }>();

  protected constructor(
    public outcomesService: OutcomesService,
    public tipService: TipService
  ) {
    this.inferredSkillsTrigger$
      .pipe(
        // don't call if both title and description are not set
        filter(({ title, description }) => !!title || !!description),
        // I considered adding a debounceTime here, but as this is called when the title is blurred
        // we do want to update the inferred skills on each change. distinctUntilChanged is used to prevent duplicate API calls
        // if the title are the same as the last call.
        distinctUntilChanged(
          (prev, curr) =>
            prev.title === curr.title && prev.description === curr.description
        )
      )
      .subscribe(({ title, description }) => {
        this.getInferredSkills(title, description);
      });
  }

  /** Gets the latest view model */
  protected get viewModel(): TViewModel {
    return this.viewModel$.value;
  }

  protected set viewModel(viewModel: TViewModel) {
    this.viewModel$.next(viewModel);
  }

  public initializeViewModel(): void {
    this.resetModel();

    const shouldShowResults$ = combineLatest([this.submissionStatus$]).pipe(
      map(([s]) => s >= SubmissionStatus.Submitting)
    );

    const isNewbUser$ = this.tipService.onboardHistory$.pipe(
      map((v) => {
        return v.indexOf('firstinput') === -1;
      })
    );

    // initialize context
    this.viewModel = {
      ...this.viewModel,
      submissionStatus$: this.submissionStatus$,
      shouldShowResults$,
      isNewbUser$,
    };
  }

  /********************************************************
   * Form Updates
   ********************************************************/

  /**
   * When a form control is updated, patch the form group value and mark field as dirty and touched.
   * @param formGroup
   * @param field
   * @param value
   */
  public onFormControlUpdate(
    formGroup: FormGroup,
    fieldName: string,
    value: any
  ): void {
    formGroup.patchValue({
      [fieldName]: value,
    });
    const field = formGroup.get(fieldName);
    field.markAsDirty();
    field.markAsTouched();
  }

  /**
   * Recursively mark all controls as touched
   * @param formGroup
   */
  public markFormAsTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormAsTouched(control);
      } else {
        control.markAsTouched();
        control.markAsDirty();
      }
    });
  }

  /******************************************************************
   * Image Uploading
   ******************************************************************/

  /**
   * On Image upload success update the Image URl on the view model.
   * @param event
   */
  public onImageUploadSuccess(pictureUrl: string) {
    this.viewModel = {
      ...this.viewModel,
      imageUrl: pictureUrl,
    };
  }

  /**
   * On Image deletion remove the imageURL from the view model.
   */
  public onDeleteImage() {
    this.viewModel = {
      ...this.viewModel,
      imageUrl: '',
    };
  }

  private async getInferredSkills(
    title: string,
    description?: string
  ): Promise<void> {
    try {
      const response: InferredSkillsModel =
        await this.outcomesService.getInferredSkills(title, description);
      this.viewModel = {
        ...this.viewModel,
        mediumConfidenceInferredSkills: response.mediumConfidence?.length
          ? response.mediumConfidence.map(
              (skillName) =>
                ({ title: skillName, name: skillName }) as TagsApi.Tag
            )
          : [],
        highConfidenceInferredSkills: response.highConfidence?.length
          ? response.highConfidence.map(
              (skillName) =>
                ({ title: skillName, name: skillName }) as TagsApi.Tag
            )
          : [],
      };
      this.inferredSkills$.next(
        this.outcomesService.getMappedInferredSkills(response.highConfidence)
      );
    } catch (e) {
      console.error('Error in loadInferredSkills', e);
    }
  }

  private resetModel(): void {
    this.viewModel = undefined;
  }

  /******************************************************************
   * Form Submission
   ******************************************************************/

  public abstract onSubmit(form: FormGroup): Promise<void>;

  public abstract onNext(url: string): Promise<void>;

  protected abstract performSuccessSideEffects();

  protected abstract performFailureSideEffects();

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {}

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
  }

  protected onContentFileChange(file: File) {
    this.viewModel.isSubmitButtonDisabled = true;
  }
}
