<form (ngSubmit)="moveStep()">
  <dgx-modal
    [canCancel]="true"
    [isLoading]="isLoading"
    [isSubmitDisabled]="isSubmitDisabled"
    [submitButtonText]="'Core_SaveChanges' | translate"
    [useDefaultForm]="false"
    (dismiss)="onDismiss()"
    bodyClasses="guts-p-h-2"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ i18n.Pathways_MoveItemTo }}
    </ng-container>
    <!-- Modal Footer -->
    <ng-container class="modal-body">
      <div
        *ngFor="let section of sections"
        class="border-bottom guts-p-t-1 guts-p-b-2"
        data-dgat="moveItemModal-ae1"
      >
        <h4 class="guts-p-v-1 guts-p-h-half font-semibold par--small">
          {{ section.title }}
        </h4>
        <label
          *ngFor="let subsection of section.lessons"
          [ngClass]="{ 'sort hover-bg-color-ebony-a03': !subsection.disabled }"
          class="l_flex l_flex-justify-left guts-p-v-half guts-p-h-half rounded"
          data-dgat="moveItemModal-1b4"
        >
          <div class="l_flex-grow">
            <input
              type="radio"
              class="a11y-hide hide-focus"
              [disabled]="subsection.disabled"
              (focus)="select(subsection.id, subsection.afterNode)"
              (click)="select(subsection.id, subsection.afterNode)"
              name="moveTo"
              data-dgat="moveItemModal-53a"
            />
            <span class="par--small">{{ subsection.title }}</span>
          </div>
          <df-icon
            [ngClass]="{
              'color-blue': moveToValue === subsection.id,
              'color-ebony-a45': subsection.disabled
            }"
            *ngIf="subsection.disabled || moveToValue === subsection.id"
            icon="checkmark"
          ></df-icon>
        </label>
      </div>
      <label
        class="l_flex l_flex-justify-left guts-p-v-half guts-p-h-half guts-m-v-2 rounded sort hover-bg-color-ebony-a03"
      >
        <input
          type="radio"
          name="moveTo"
          class="a11y-hide hide-focus"
          (focus)="select('bin')"
          (click)="select('bin')"
          data-dgat="moveItemModal-c1b"
        />
        <div class="l_flex l_flex-grow">
          <span class="par--small">{{ i18n.Pathways_Bin }}</span>
          <span
            *ngIf="!!binCount || binCount === 0"
            class="par--small par--light bg-color-ebony-a08 rounded guts-p-h-half guts-m-l-half font-semibold"
            >{{ binCount }}</span
          >
        </div>
        <df-icon
          class="color-blue"
          *ngIf="moveToValue === 'bin'"
          icon="checkmark"
        ></df-icon>
      </label>
    </ng-container>
  </dgx-modal>
</form>
