import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { OpportunityType } from '@app/orgs/org-opportunity-types';

export const isTypeMissing = (
  opportunity: Opportunity,
  types: OpportunityType[]
): boolean => {
  // Either no opportunity is defined, or the opportunity has no type set.
  // In either case, there's no type to be missing. ;)
  if (!opportunity?.type) {
    return false;
  }
  // Empty array of types? Nothing to check! But our type isn't undefined,
  // so it's missing by definition.
  if (!types.length) {
    return true;
  }
  // internal types should be checked against the `name` property,
  // custom types will always be missing
  return opportunity.internalType
    ? !types.some((currType) => currType.name === opportunity.type)
    : true;
};
