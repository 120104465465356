import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function hasBrokenUrlValidator(
  translate: TranslateService,
  hasBrokenUrl: boolean,
  originalUrl: string,
  newUrl: string
) {
  const hasUserEdited = originalUrl !== newUrl;
  return newUrl && hasBrokenUrl && !hasUserEdited
    ? {
        urlBrokenValidation: translate.instant(
          'dgOrgInternalContent_BrokenLinkError'
        ),
      }
    : null;
}

export function validateEntryUrlError(
  translate: TranslateService,
  isInitialForm: boolean,
  courseUrlField: AbstractControl,
  atLeastOneRequired: boolean,
  hasBrokenUrl: boolean,
  originalUrl: string
): boolean {
  if (isInitialForm) {
    return (
      courseUrlField?.dirty &&
      (atLeastOneRequired || courseUrlField.hasError('pattern'))
    );
  }
  const hasBrokenUrlValidatorcos = hasBrokenUrlValidator(
    translate,
    hasBrokenUrl,
    originalUrl,
    courseUrlField.value
  );

  if (!!hasBrokenUrlValidatorcos) {
    return true;
  }
  return courseUrlField.invalid;
}
