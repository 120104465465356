import { Injectable } from '@angular/core';
import { getDeepCopy } from '@app/shared/utils';

import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';

import { SearchFilterFilter } from '@app/search/search-api.model';
import { CareerPathCustomNames } from '@dg/shared-services';
import { SearchFilter } from '@app/search/components/search-view/search-reactive-store';
import { TranslateService } from '@ngx-translate/core';

export enum SearchFilterId {
  Type = 'Type',
  Provider = 'Provider',
  Duration = 'Duration',
  Language = 'Language',
  Groups = 'Groups',
  PublishDate = 'PublishDate',
  Endorsed = 'Endorsed',
  Location = 'Location',
  JobRole = 'JobRole',
  Mentors = 'Mentors',
  ActiveLearners = 'ActiveLearners',
  CEU = 'CEU',
  PriceRange = 'PriceRange',
}

const sortOrder = [
  SearchFilterId.Type,
  SearchFilterId.Endorsed,
  SearchFilterId.Provider,
  SearchFilterId.Duration,
  SearchFilterId.Language,
  SearchFilterId.Groups,
  SearchFilterId.PublishDate,
  SearchFilterId.Location,
  SearchFilterId.JobRole,
  SearchFilterId.Mentors,
  SearchFilterId.ActiveLearners,
  SearchFilterId.CEU,
  SearchFilterId.PriceRange,
  undefined, // any other filter types
];

@Injectable({ providedIn: 'root' })
export class SearchFiltersService {
  constructor(
    private displayTypePipe: DisplayTypePipe,
    private translateService: TranslateService
  ) {}

  public transformSearchFilters(
    filters: SearchFilter[],
    careerPathingNames?: CareerPathCustomNames
  ): SearchFilterFilter[] {
    if (!filters || filters.length === 0) {
      return [];
    }

    const groupedFilters = this.buildGroupedFilters(filters);
    const standardFilters = filters.filter(
      (f) => !f?.model?.config?.isRangeFilter
    );
    filters = [...standardFilters, ...groupedFilters];

    return getDeepCopy(filters)
      .sort(
        (a, b) =>
          sortOrder.indexOf(SearchFilterId[a.title]) -
          sortOrder.indexOf(SearchFilterId[b.title])
      )
      .map(this.buildSearchFilter.bind(this))
      .map((filter) => this.setFilterOptionNames(filter, careerPathingNames));
  }

  private buildSearchFilter(filter: SearchFilter): SearchFilterFilter {
    const isCheckboxFilter = [
      SearchFilterId.Endorsed,
      SearchFilterId.Mentors,
      SearchFilterId.ActiveLearners,
      SearchFilterId.CEU,
    ].includes(filter.title as SearchFilterId);

    const isRangeFilter = [SearchFilterId.PriceRange].includes(
      filter.title as SearchFilterId
    );

    const baseFilter = this.buildDefaultSearchFilter(filter);

    if (isCheckboxFilter) {
      return {
        ...baseFilter,
        config: {
          isCheckboxFilter: true,
          checkboxFilterId: filter.subitems[0]?.model.id,
        },
      };
    } else if (isRangeFilter) {
      return {
        ...baseFilter,
        config: filter.model.config,
      };
    } else {
      return baseFilter;
    }
  }

  private setFilterOptionNames(
    filter: SearchFilterFilter,
    careerPathingNames?: CareerPathCustomNames
  ): SearchFilterFilter {
    filter.subitems?.forEach((item) => {
      if (
        careerPathingNames &&
        filter.id === SearchFilterId.Type &&
        item.model.name === 'Target'
      ) {
        item.title = careerPathingNames.groupedSearchFacedName;
      } else {
        if (filter.id === 'Duration') {
          item.title = this.translateService.instant(
            this.buildI18n(item.model.id)
          );
        } else {
          item.title = filter?.config?.isRangeFilter
            ? (item.model.id as string)
            : this.displayTypePipe.transform(
                item.model?.name || item.model?.id
              );
        }
      }
    });
    return filter;
  }

  private buildDefaultSearchFilter(
    filter: SearchFilterFilter
  ): SearchFilterFilter {
    const id = filter.model?.id || (filter.id as string);
    return {
      id,
      title: this.buildI18n(id),
      subitems: [...filter.subitems],
    };
  }

  private buildI18n(id: string): string {
    switch (id) {
      case 'Endorsed':
        return 'Core_EndorsedOnly';
      case 'JobRole':
        return 'Core_JobRoleLiteral';
      default:
        return `Core_${id}`;
    }
  }

  private buildGroupedFilters(filters: SearchFilter[]) {
    const groupedFilters = filters.filter(
      (filter) => !!filter.model?.config?.filterGroup
    );
    const groups = groupedFilters.map(
      (filter) => filter.model.config.filterGroup
    );

    return [...new Set(groups)].map((id) => {
      const groupFilter = {
        id,
        title: id,
        subitems: [],
        model: {
          id,
          name: id,
          config: { isRangeFilter: true, filterGroup: id },
        },
      };
      return groupedFilters
        .filter((filter) => filter.model.config.filterGroup === id)
        .reduce((group, filter) => {
          (group as any).subitems.push({
            ...filter.subitems[0],
            model: { ...filter.model, name: filter.subitems[0].model.name },
          });
          return group;
        }, groupFilter);
    }) as unknown as SearchFilter[];
  }
}
