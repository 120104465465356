import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeValue } from '@angular/platform-browser';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-provider-logo',
  templateUrl: './tag-provider-logo.component.html',
  styleUrls: ['./tag-provider-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagProviderLogoComponent {
  @Input() public set providerName(name: string) {
    this._providerName = name;
  }
  /* path to icon svg */
  @Input() public src: string;
  /* height/width of logo in px */
  @Input() public size: 'xs' | 'md';
  /* custom class for the external image, using component must have ViewEncapsulation.None */
  @Input() public imageClass: string;

  // Local
  private _providerName: string;
  public fallbackChar: string;

  constructor(
    private webEnvironmentService: WebEnvironmentService,
    private translate: TranslateService
  ) {}

  public get blobSrc(): SafeValue {
    return this.webEnvironmentService.getBlobUrl(this.src, true);
  }

  public get providerName(): string {
    return this.translate.instant('dgTagRating_ProviderLogo', {
      ProviderName: this._providerName,
    });
  }

  public useFallback(): void {
    this.fallbackChar = this._providerName.charAt(0).toUpperCase();
  }
}
