import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

/** A simple form field container control that provides a label and optional tooltip via a help icon
 * @deprecated: Use this only as a stop gap for fields containing the help icon toolip, which is not
 * provided in Fresco via the forms builder.
 */
@Component({
  selector: 'dgx-simple-field-wrapper',
  templateUrl: 'simple-field-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFieldWrapperComponent implements OnInit {
  @Input() public label: string;
  @Input() public tipText: string;
  @Input() public isRequired: boolean;
  @Input() public labelFor?: string;
  @Input() public icon?: string;
  @Input() public showFullWidthLabel?: boolean = false;

  ngOnInit(): void {
    this.icon = this.icon ?? 'question-mark-circle';
  }
}
