import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '@app/shared/services/modal.service';
import { TagPublicRatingModalComponent } from './tag-public-rating-modal.component';
import { TrackerService } from '@app/shared/services/tracker.service';
import { OwnerTag } from '@app/tags/tags';

@Injectable({
  providedIn: 'root',
})
export class TagPublicRatingModalService {
  constructor(
    private modalService: ModalService,
    private trackerService: TrackerService
  ) {}

  /**
   * Show another users completed public ratings for a given tag
   */
  public openUserPublicRatingModal(
    tag: OwnerTag,
    headerText: string,
    event: Event,
    ownerKey: Number,
    isManager?: boolean
  ): Observable<undefined> {
    this.trackerService.trackEventData({
      action: 'Skill Rating Viewed',
      element: event?.target as HTMLElement,
      properties: {
        SkillId: tag.tagId,
        SkillName: tag.name,
        IsFocusSkillForOwner: tag.isFocusSkillForOwner,
        IsOnProfileForOwner: tag.isOnProfileForOwner,
        SkillOwnerUserId: ownerKey,
      },
    });

    return this.modalService.show(TagPublicRatingModalComponent, {
      inputs: {
        tag,
        headerText,
        isManager,
      },
    });
  }
}
