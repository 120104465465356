import { Injectable } from '@angular/core';
import {
  BaseInsightsContent,
  GetPathwayTopContent,
  GetTagRatingProgression,
  GetTargetTopContent,
  RecommendationUsers,
  TagComparisonByUser,
  TagIdentifier,
  TagRatingInsightKpi,
  TargetIdentifier,
  UserSkillProgressKpi,
} from '@app/insights/insights-api.model';
import { ReportingAPI } from '@app/reporting/models/api.model';
import { LocalizationService } from '@app/shared/localization.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { DateRangeService } from '@app/shared/services/date-range.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { map, Observable } from 'rxjs';
import { formatInsightsDateLabels } from '../utils';

interface InsightPoint {
  dateItem: string;
  itemCount: number;
  itemType: string;
  xAxisInterval: string;
  xAxisLabel: string;
  xAxisDescription: string;
}

interface TopTagData {
  totalOpportunities: number;
  tagList: any[];
}

interface TopJobRolesData {
  recordCount: number;
  totalCount: number;
  types: {
    JobRoleId: number;
    JobRole: string;
    RecordCount: number;
  }[];
}

interface ViewInterestData extends BaseInsightsContent {
  interestByMatchedCount: [];
  interestByUnmatchedCount: [];
  viewedByMatchedCount: [];
  viewedByUnmatchedCount: [];
}

interface SelectedCandidatesData extends BaseInsightsContent {
  selectedMatchedCandidateCount: [];
  selectedUnmatchedCandidateCount: [];
}

interface UserGroupInfo {
  description: string;
  groupId: number;
  isAdmin?: boolean;
  isRestricted: boolean;
  joinDate?: string;
  lastVisitDate?: string;
  name: string;
  organizationId: number;
  organizationName_Secure: string;
  pendingJoinRequest?: boolean;
  privacyLevel: number;
  roleId?: number;
  userCount?: number;
  userGroupId?: number;
  userProfileKey?: number;
}

export interface GetShareActivityParams {
  RecommendationType: 'RequiredLearning' | 'Recommendation' | null;
  since: Date | null;
  skip: number | null;
  take: number | null;
  includeCompleted: boolean;
}

export enum ScoreType {
  itemsCompleted = 0,
  points = 1,
}
@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(
    private http: NgxHttpClient,
    private dateRangeService: DateRangeService,
    private localizationService: LocalizationService,
    private trackerService: TrackerService
  ) {}
  public GetGroupRecommendationUsers(
    orgId,
    recommendationId,
    groupId,
    count,
    skip
  ): Observable<RecommendationUsers> {
    return this.http.get<RecommendationUsers>(
      '/reporting/getgrouprecommendationusers',
      {
        params: {
          organizationId: orgId,
          recommendationId: recommendationId,
          groupId: groupId,
          count: count,
          skip: skip,
        },
      }
    );
  }
  public GetInsightKpisByUser(
    userProfileKey,
    orgId
  ): Observable<TagRatingInsightKpi[]> {
    return this.http.get<TagRatingInsightKpi[]>(
      '/reporting/GetInsightKpisByUser',
      {
        params: {
          userProfileKey,
          orgId,
        },
      }
    );
  }
  /**
   *
   * Get Insights tag comparision by user
   * Used to populate skills rating for top 10 skills for my job role table
   *
   * @param {number} userProfileKey
   * @param {number} orgId
   *
   */
  public GetInsightTagComparisonByUser(
    userProfileKey,
    orgId
  ): Observable<TagComparisonByUser[]> {
    return this.http.get<TagComparisonByUser[]>(
      '/reporting/GetInsightTagComparisonByUser',
      {
        params: {
          userProfileKey,
          orgId,
        },
      }
    );
  }
  public GetPathwayInsightKPIs(pathwayId, groupId, filterOrgId) {
    return this.http.get<any[]>('/reporting/GetPathwayInsightKPIs', {
      params: {
        pathwayId,
        groupId,
        filterOrgId,
      },
    });
  }
  public GetTargetsInsightKPIs(orgId, startDate, endDate, targetId, groupId?) {
    return this.http.get<any[]>('/reporting/GetTargetsInsightKPIs', {
      params: {
        orgId,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        targetId,
        groupId,
      },
    });
  }

  public GetViewInterestInsights(
    orgId,
    startDate,
    endDate,
    groupId,
    opportunityTypeId,
    locationId,
    tagsList,
    statusId
  ) {
    return this.http
      .get<ViewInterestData>('/reporting/GetViewInterestInsights', {
        cache: true,
        params: {
          orgId,
          startDate,
          endDate,
          groupId,
          opportunityTypeId,
          locationId,
          tagsList,
          statusId,
        },
      })
      .pipe(
        map((response) => ({
          ...response,
          xAxisLabels: formatInsightsDateLabels(
            response.xAxisLabels,
            response.xAxisInterval,
            this.localizationService.userLocale
          ),
        }))
      );
  }
  public GetSelectedCandidateInsights(
    orgId,
    startDate,
    endDate,
    groupId,
    opportunityTypeId,
    locationId,
    tagsList,
    statusId
  ) {
    return this.http
      .get<SelectedCandidatesData>('/reporting/GetSelectedCandidateInsights', {
        cache: true,
        params: {
          orgId,
          startDate,
          endDate,
          groupId,
          opportunityTypeId,
          locationId,
          tagsList,
          statusId,
        },
      })
      .pipe(
        map((response) => ({
          ...response,
          xAxisLabels: formatInsightsDateLabels(
            response.xAxisLabels,
            response.xAxisInterval,
            this.localizationService.userLocale
          ),
        }))
      );
  }
  public GetOpportunitiesInsightTopTags(
    orgId,
    opportunityTypeId,
    locationId,
    tagsList
  ) {
    return this.http.get<TopTagData>(
      '/reporting/GetOpportunitiesInsightTopTags',
      {
        cache: true,
        params: {
          orgId,
          opportunityTypeId,
          locationId,
          tagsList,
        },
      }
    );
  }
  public GetOpportunitiesInsightByType(
    orgId,
    opportunityTypeId,
    locationId,
    tagsList
  ) {
    return this.http.get<any[]>('/reporting/GetOpportunitiesInsightByType', {
      cache: true,
      params: {
        orgId,
        opportunityTypeId,
        locationId,
        tagsList,
      },
    });
  }
  public GetOpportunitiesInsightByJobRole(
    orgId,
    groupId,
    opportunityTypeId,
    locationId,
    tagsList
  ) {
    return this.http.get<TopJobRolesData>(
      '/reporting/GetOpportunitiesInsightByJobRole',
      {
        cache: true,
        params: {
          orgId,
          groupId,
          opportunityTypeId,
          locationId,
          tagsList,
        },
      }
    );
  }
  public GetOpportunityInsightsActivity(
    startDate,
    endDate,
    opportunityId,
    orgId
  ) {
    return this.http.get<InsightPoint[]>(
      '/reporting/GetOpportunityInsightsActivity',
      {
        cache: true,
        params: {
          opportunityId,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          orgId,
        },
      }
    );
  }

  public GetPathwayInsightsActivity(
    startDate: Date | number,
    endDate: Date | number,
    pathwayId,
    groupId?: number,
    filterOrgId?: number
  ) {
    return this.http.get<ReportingAPI.InsightPoint[]>(
      '/reporting/GetPathwayInsightsActivity',
      {
        cache: true,
        params: {
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          pathwayId,
          groupId,
          filterOrgId,
        },
      }
    );
  }

  public GetOpportunityKPIs(opportunityId, orgId) {
    return this.http.get<any[]>('/reporting/GetOpportunityInsightKPIs', {
      cache: true,
      params: {
        opportunityId,
        orgId,
      },
    });
  }
  public GetOrgOpportunityKPIs(orgId, opportunityTypeId, locationId, tagsList) {
    return this.http.get<any[]>('/reporting/GetOpportunitiesInsightKPIs', {
      cache: true,
      params: {
        orgId,
        opportunityTypeId,
        locationId,
        tagsList,
      },
    });
  }

  public GetShareActivity(params: Partial<GetShareActivityParams>) {
    const defaultParams = {
      includeCompleted: true,
    };
    return this.http.get<any>('/reporting/getsentrecommendations', {
      params: {
        ...defaultParams,
        ...params,
      },
    });
  }

  public getNumberOfDays(startDate, endDate) {
    if (startDate.value) {
      startDate = startDate.value;
      endDate = endDate.value;
    }
    // original date format "Wed Nov 06 2019 00:00:00"
    // we must convert to milliseconds
    // because the time is 00:00:00 or midnight the last day is not counted so we must add it
    const millisecondsInADay = 86400000,
      endDay = 1,
      diffInTime = endDate.getTime() - startDate.getTime(),
      diffInDays =
        Math.round(Math.abs(diffInTime / millisecondsInADay)) + endDay;
    return diffInDays;
  }
  /**
   * Get user skill progression kpis
   * @param startDate
   * @param endDate
   * @param tagList
   */
  public GetUserSkillProgressKPIs(
    startDate: string | number | Date,
    endDate: string | number | Date,
    tagList?: number[]
  ): Observable<UserSkillProgressKpi[]> {
    return this.http.get<any[]>('/reporting/GetUserSkillProgressKPIs', {
      params: {
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        tagList: tagList ? tagList.join() : null,
      },
    });
  }

  /**
   * Get plan names of plans with related skills to the auth user.
   * This is used in conjunction with GetUserSkillProgressSkill
   */
  public GetUserSkillProgressSkillCategory(): Observable<TargetIdentifier[]> {
    return this.http.get<TargetIdentifier[]>(
      '/reporting/GetUserSkillProgressSkillCategory'
    );
  }
  /**
   * Get all skills related to the selection from GetUserSkillProgressSkillCategory
   * @param targetId number
   */
  public GetUserSkillProgressSkill(
    targetId: number
  ): Observable<TagIdentifier[]> {
    return this.http.get<TagIdentifier[]>(
      '/reporting/GetUserSkillProgressSkill',
      {
        params: { targetId },
      }
    );
  }

  /**
   * Get skill ratings over time
   * @param orgId
   * @param groupId
   * @param ratingType
   * @param tagsList
   * @param startDate
   * @param endDate
   * @param userProgression
   */
  public GetSkillProgressionInsights(
    orgId: number,
    groupId: number,
    ratingType: string,
    tagsList: number[],
    startDate: string | number | Date,
    endDate: string | number | Date,
    userProgression?: boolean
  ): Observable<GetTagRatingProgression> {
    return this.http.get<GetTagRatingProgression>(
      '/reporting/GetTagRatingProgression',
      {
        params: {
          orgId,
          groupId: groupId === 0 ? null : groupId,
          ratingType,
          tagsList,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          userProgression,
        },
      }
    );
  }

  public GetPathwayTopContent(
    orgId: number,
    pathwayId: number,
    skip: number = 0,
    take: number = 10,
    sortColumn: string,
    sortAscending: boolean,
    groupId?: number,
    filterOrgId?: number
  ): Observable<GetPathwayTopContent> {
    return this.http.get<GetPathwayTopContent>(
      '/reporting/GetPathwayTopContent',
      {
        params: {
          orgId,
          skip,
          take,
          pathwayId,
          sortColumn,
          sortAscending,
          groupId,
          filterOrgId,
        },
      }
    );
  }

  public GetTargetTopContent(
    orgId: number,
    targetId: number,
    skip: number = 0,
    take: number = 10,
    sortColumn: string,
    sortAscending: boolean,
    sectionNode?: string,
    groupId?: number
  ): Observable<GetTargetTopContent> {
    return this.http.get<GetTargetTopContent>(
      '/reporting/GetTargetTopContent',
      {
        cache: true,
        params: {
          orgId,
          skip,
          take,
          targetId,
          sectionNode,
          sortColumn,
          sortAscending,
          groupId,
        },
      }
    );
  }

  public getPopularProviders(
    orgId: string,
    startDate: string,
    endDate: string,
    catalog: string,
    count: number,
    groupId: string
  ): Observable<ReportingAPI.PopularProviders[]> {
    return this.http.get<ReportingAPI.PopularProviders[]>(
      '/reporting/getpopularproviders',
      {
        params: {
          orgId,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          contentLevel: catalog,
          count,
          groupId,
        },
      }
    );
  }

  public getTargetInsightsActivity(
    orgId: number,
    startDate: Date | number,
    endDate: Date | number,
    targetId,
    groupId?
  ) {
    return this.http.get('/reporting/GetTargetInsightsActivity', {
      cache: true,
      params: {
        orgId,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        targetId,
        groupId,
      },
    });
  }

  public getTopActiveGroups(
    orgId,
    startDate,
    endDate,
    count,
    scoreType = null,
    groupId = null
  ) {
    return this.http.get('/reporting/gettopactivegroups', {
      params: {
        orgId,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        count,
        scoreType,
        groupId,
      },
    });
  }

  public generateCmsReport(input) {
    this.trackerService.trackEventData({
      action: 'CMS Report Generated',
      category: input.InputId,
    });
    return (
      '/api/reporting/generatecmsitemreport?inputType=' +
      input.inputType +
      '&inputId=' +
      input.inputId
    );
  }

  public generateCmsCatalogReport() {
    return '/api/reporting/generatecmscatalogreport';
  }

  public getReportTypeId(reportTitle: string): Observable<number> {
    return this.http.get<number>('/reportinginapp/GetReportTypeId', {
      params: {
        reportTitle,
      },
    });
  }

  public getReportUrlById(orgId: number, reportId: number): string {
    return `orgs/${orgId}/reporting/create/preview?reportTypeId=${reportId}`;
  }
}
