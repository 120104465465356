<dgx-search-suggest
  [ariaDescription]="'dgGlobalSearch_A11ySearchDescription' | translate"
  (focusin)="onInputFocus()"
  (inputChange)="loadSuggestions($event)"
  (inputEnter)="onInputEnter($event)"
  [searchTerm]="searchTerm"
  [(isPopoverOpen)]="shouldShowSuggestions"
  [overrideClasses]="overrideClasses"
  [shouldRenderInGlobalSearchMode]="shouldRenderInGlobalSearchMode"
>
  <!--Search input auto suggest list contents. role=listbox and role=option are both a11y attributes AND custom directives that enable keyboard navigation-->
  <div
    class="scrollable search-menu"
    tabindex="-1"
    role="listbox"
    (keyup)="handleKeyup($event)"
    [attr.aria-label]="'Core_Search' | translate"
  >
    <ng-container
      *ngFor="let typeVm of suggestionsByType; trackBy: getItemTrackingKey"
      data-dgat="simple-search-suggest-2c6"
    >
      <ul
        *ngIf="typeVm.subitems.length > 0"
        class="guts-p-v-half"
        role="group"
        attr.aria-labelledby="suggestions-label-{{ typeVm.trackingKey }}"
      >
        <li
          id="suggestions-label-{{ typeVm.trackingKey }}"
          role="presentation"
          class="h4 border-bottom guts-m-h-1 guts-p-b-half"
          [textContent]="typeVm.title | translate"
        ></li>
        <li
          *ngFor="
            let suggestionVm of typeVm.subitems;
            trackBy: getItemTrackingKey
          "
          data-dgat="simple-search-suggest-3r4"
          role="none"
          attr.aria-describedby="suggestions-label-{{ typeVm.trackingKey }}"
          tabindex="-1"
        >
          <button
            role="option"
            class="listbox--option"
            (click)="trackSuggestionClick(suggestionVm)"
            tabindex="-1"
            data-dgat="search-view-06d"
            [textContent]="suggestionVm.title"
          ></button>
        </li>
      </ul>
    </ng-container>
  </div>
</dgx-search-suggest>
