<!-- Card -->
<article
  #recommendation
  class="input-card js-content-container"
  *ngIf="initialAction && !recommendationCompleted; else tagTile"
>
  <div
    class="meta-list meta-list--light font-semibold guts-p-h-2 guts-p-t-2 guts-p-b-1"
    [textContent]="type"
  ></div>

  <section class="guts-m-h-2 guts-m-b-1-half">
    <!-- Title -->
    <header>
      <h2 class="input-card__title break">
        <span [textContent]="tag.title"></span>
      </h2>
    </header>

    <!-- Description -->
    <div
      class="l_flex-top guts-m-t-half color-ebony delta hyphenate break"
      [textContent]="description"
    ></div>

    <!-- Meta -->
    <div class="guts-m-v-half">
      <dgx-rating-button-meta [options]="metaOptions"></dgx-rating-button-meta>
    </div>
  </section>

  <!-- Actions -->
  <footer class="guts-m-h-2 guts-p-b-2">
    <div class="l_flexbar">
      <div class="l_flex l_flex-grow">
        <dgx-action-button
          design="pill"
          size="md"
          [isActive]="isCompleted || isPending"
          [autoToggleState]="false"
          (click)="handleClick($event)"
          dgatInput="tag-card-d18"
          >{{ ctaLabel }}
        </dgx-action-button>
      </div>
      <dgx-menu
        [appendToBody]="true"
        [menuConfig]="menuConfig"
        placement="bottom-right"
        [placementAdjustLeftRem]="1"
      >
        <button type="button" data-dgat="tag-card-6ca">
          <df-icon icon="dots" size="medium" class="color-ebony-a61"></df-icon>
        </button>
      </dgx-menu>
    </div>
  </footer>
</article>

<!-- If no recommendation -->
<ng-template #tagTile>
  <!-- the tag card component is only used in areas where the owner is ever viewing it if that changes then this will need to be passed into the component -->
  <dgx-tag-tile class="block" [tag]="tag" [isOwner]="true"></dgx-tag-tile>
</ng-template>
