import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

@Component({
  selector: 'dgx-org-view-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgViewBaseComponent extends SubscriberBaseDirective {
  @Input() public orgId: number;
  constructor() {
    super();
  }
}
