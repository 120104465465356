import { SimpleFieldWrapperComponent } from './simple-field-wrapper.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfIconModule, DfLabelMarkerModule } from '@lib/fresco';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    DfIconModule,
    DfLabelMarkerModule,
    NgbTooltipModule,
    TranslateModule,
  ],
  providers: [],
  declarations: [SimpleFieldWrapperComponent],
  exports: [SimpleFieldWrapperComponent],
})
export class DgxSimpleFieldWrapperModule {}
