// This file is shared with the bookmarklet/extensions
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import {
  PathwayAddEditFormOptions,
  PathwayDetailsModel,
  PathwayLesson,
  PathwaySummaryModel,
} from '@app/pathways/pathway-api.model';
import { PathwayAuthoringService } from '@app/pathways/services/pathway-authoring.service';
import { PathwayLearnerService } from '@app/pathways/services/pathway-learner.service';
import { ComboboxOption } from '@app/shared/components/combobox/combobox.model';
import { InputType } from '@app/shared/models/core-api.model';
import { ContextService } from '@app/shared/services/context.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PathwayAddContentService } from '@app/pathways/services/pathway-add-content.service';
import { PathwayAddEditFormModalService } from '@app/pathways/services/pathway-add-edit-form-modal-service';
import { AddToPathwayService } from '@app/pathways/services/add-to-pathway.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { DropdownRowItem } from '@degreed/apollo-angular';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { useApolloLayout } from '@degreed/apollo-angular';

export interface AddToPathwayOptions {
  inputId?: number;
  inputType: InputType;
  organizationId?: number;
  isEndorsed?: boolean;
  inputName?: string;
}

export interface ebbAddToPathwayConfig {
  baseUrl: string;
  input?: any; // only use if inputId isn't available
  displayAddToCatalogOption: boolean;
}

interface Option extends ComboboxOption {
  name: string;
  node?: string;
}

/**
 * ******** Original Acceptance Criteria ********
 *
 * - Supports predictive text selection (combobox) when user types a pathway title into the Pathway selection input
 * - Allows user to immediately add item to "Hold for Later" instead of selecting a section
 * - Allows user to make their selection via a stepped approach so that each selection makes visible the next selection (this supports pathways that may have only 1 section or no lessons)
 * - Allows user to select from UP TO 5 "Recently Used" Pathways depending on how many pathways are available
 * - List of "Recently Used" pathways should be in order of recency, Most Recent at the top
 * - Allows user to select and scroll through a list of "Your Pathways"
 * - List of "Your Pathways" should be in alphabetical order A-Z.
 * - Upon completion, present user with choice to "Edit the Pathway" or "Done" to return user to their original context.
 * - If user has ZERO pathways available to them as an author, then present them with a special "Create your first pathway!" modal and a CTA button which will open the "Create a Pathway Modal"
 *
 *
 *
 * ********    Additional Acceptance Criteria    ********
 * ******** Unique to the Degreed Button Context ********
 *
 * - Allow user to select "Add to Catalog" option IF they have the "Manage Catalog" permission and confirm and edit content details before Saving.
 * - Choosing to Create or Edit a pathway opens a new browser tab to the web app.
 */
@Component({
  selector: 'dgx-add-to-pathway',
  templateUrl: './add-to-pathway.component.html',
})
export class AddToPathwayComponent implements OnInit, OnChanges {
  @Input() public addToPathwayOptions: AddToPathwayOptions;

  /*** these are for Degreed Button support ***/
  @Input() public ebbAddToPathwayConfig?: ebbAddToPathwayConfig;
  @Input() public activeModal?: NgbActiveModal;
  @Output() public submit? = new EventEmitter<boolean>();
  /*****/

  public i18n = this.translate.instant([
    'Core_Next',
    'Core_Done',
    'Core_MaliciousURL',
    'Core_NoResultsFound',
    'Ext_ErrorMessage',
    'OnboardingFooter_NextStep',
    'addToPathwayModal_AddToCatalog',
    'addToPathwayModal_AddToPathway',
    'addToPathwayModal_CreateYourPathway',
    'addToPathwayModal_CreatePathwayExplanation',
    'addToPathwayModal_CreatePathway',
    'addToPathwayModal_Pathway',
    'addToPathwayModal_SelectPathway',
    'addToPathwayModal_RecentlyUsed',
    'addToPathwayModal_YourPathways',
    'addToPathwayModal_Section',
    'addToPathwayModal_SelectSection',
    'addToPathwayModal_HoldForLater',
    'addToPathwayModal_Lesson',
    'addToPathwayModal_SelectLesson',
    'addToPathwayModal_AddedSuccessfully',
    'addToPathwayModal_VisitPathwayEditPage',
    'addToPathwayModal_EditPathway',
    'dgManagePathways_CreatePathway',
    'Core_Loading',
    'addToPathwayModal_SelectPathway',
    'Core_Cancel',
    'addToPathwayModal_SelectPathwaySection',
    'addToPathwayModal_SelectPathwaySubSection',
    'addToPathwayModal_GoToPathway',
    'addToPathwayModal_NoPathwayDescription',
  ]);

  // Which panel in the modal template to show at any given time
  public displayArea:
    | 'selectionForm'
    | 'noPathways'
    | 'successMessage'
    | 'ebbCreatePathway' = 'selectionForm';

  public pathwayAuthoringUrl: string;

  public pathwayOptions: Option[] = [];
  public sectionOptions: Option[] = [];
  public subsectionOptions: Option[] = [];
  public autoSelectedSection: Option;

  public isEbb: boolean = false;
  public isIframe: boolean = false;
  public loadingPathways: boolean = true;
  public loadingSections: boolean = false;
  public isValid: boolean = false;
  public isSubmitting = new BehaviorSubject<boolean>(false);
  public isCreatingInputIdForEbb: boolean = false;

  public displayAddToCatalogOption: boolean = false;
  public addToCatalog: boolean = false;
  public selectedPathway: PathwayDetailsModel;
  public successLabel: string;
  public orgManagedLabel: string;
  public link: string = '';
  public orgManagedLink: string;
  private linkConfig = {
    Pathway: {
      link: '/articles/4408921448978',
    },
    Target: {
      link: '/articles/4408921475602',
    },
  };

  private orgManagedNotificationFlag =
    this.ldFlagsService.showPathwayVisibilityEnhancements;
  private selectedPathwayOption: Option;
  private selectedSubsectionNode: string;
  private holdingForLater: boolean = false;
  private holdForLaterId: number = -1;
  private selectedPathwayId: number;

  constructor(
    private translate: TranslateService,
    private ngbActiveModal: NgbActiveModal,
    private ldFlagsService: LDFlagsService,
    private pathwayAddContentService: PathwayAddContentService,
    private pathwayAddEditFormModalService: PathwayAddEditFormModalService,
    private addToPathwayService: AddToPathwayService,
    private pathwayLearnerService: PathwayLearnerService,
    private pathwayAuthoringService: PathwayAuthoringService,
    private contextService: ContextService,
    private windowLayoutService: WindowLayoutService,
    private cdr: ChangeDetectorRef,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public get showOrgManagedNotification(): boolean {
    return (
      this.orgManagedNotificationFlag &&
      !!this.selectedPathway && // Pathway must first be selected
      !this.selectedPathway.organizationId &&
      !!this.addToPathwayOptions?.organizationId
    );
  }

  public get showLearnerHubModal() {
    return (
      useApolloLayout() &&
      this.ldFlagsService.useLearnerHub &&
      this.ldFlagsService.useNewLearnerAssignments
    );
  }

  public get pathwayRowItems(): DropdownRowItem[] {
    return this.pathwayOptions as DropdownRowItem[];
  }

  public get sectionRowItems(): DropdownRowItem[] {
    return this.sectionOptions as DropdownRowItem[];
  }

  public get subSectionRowItems(): DropdownRowItem[] {
    return this.subsectionOptions as DropdownRowItem[];
  }

  public ngOnInit() {
    if (
      !this.addToPathwayOptions?.inputId &&
      !this.ebbAddToPathwayConfig?.input
    ) {
      throwError(new Error('must provide either an input Id or an input'));
    }

    this.isEbb = !!this.ebbAddToPathwayConfig;
    this.isIframe = this.windowLayoutService.isIframe;

    if (this.isEbb) {
      /* EBB Stuff */
      this.displayAddToCatalogOption =
        this.ebbAddToPathwayConfig.displayAddToCatalogOption;
    }

    if (!this.activeModal) {
      this.activeModal = this.ngbActiveModal;
    }

    this.orgManagedLink = this.webEnvironmentService.getZendeskUrl(
      this.link ? this.link : this.linkConfig['Pathway'].link
    );

    this.orgManagedLabel = this.translate.instant(
      'addToPathwayModal_OrgManagedNotice',
      {
        anchorOpen: `<a href="${this.orgManagedLink}" rel="noopener noreferrer" target="_blank" class="tw-font-bold tw-text-blue-800">`,
        anchorClose: '</a>',
      }
    );

    this.loadPathways();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ebbAddToPathwayConfig) {
      if (this.isSubmitting.value && !this.isCreatingInputIdForEbb) {
        this.isSubmitting.next(false);
        this.addToPathway();
      } else if (this.isCreatingInputIdForEbb) {
        this.isSubmitting.next(false);
        this.openCreatePathway();
      }
    }
  }

  public onPathwaySelected(selectedPathwayOption: ComboboxOption) {
    this.clearSections();
    if (selectedPathwayOption.id === -1) {
      // adding new pathway
      this.openCreatePathway();
      this.selectedPathway = undefined;
      this.selectedPathwayOption = undefined;
      this.selectedPathwayId = undefined;
      return; // exit early, no new selection
    }
    this.loadingSections = true;
    this.selectedPathwayId = selectedPathwayOption.id as number;
    return this.pathwayLearnerService
      .getPathway(this.selectedPathwayId)
      .subscribe((pathwayDetails) => {
        this.selectedPathway = pathwayDetails;
        this.selectedPathwayOption = {
          name: pathwayDetails.title,
          id: pathwayDetails.id,
        };
        // include "Hold for Later" option at top
        this.sectionOptions = [
          {
            name: this.i18n.addToPathwayModal_HoldForLater,
            id: this.holdForLaterId,
            groupingId: 'hold',
          } as any,
        ];
        const sectionsAsOptions = pathwayDetails.levels.map((section) => {
          const sectionOption = {
            name: this.translate.instant(
              'addToPathwayModal_SectionNumberFormat',
              {
                number: section.number,
              }
            ),
            id: section.id,
          };
          if (section.title && section.title.trim() !== '') {
            sectionOption.name = `${sectionOption.name}: ${section.title}`;
          }
          return sectionOption;
        });
        this.sectionOptions = [...this.sectionOptions, ...sectionsAsOptions];
        if (pathwayDetails.levels.length === 1) {
          const selectedSection = this.sectionOptions[1]; // skip "Hold for Later"
          this.autoSelectedSection = selectedSection;
          this.onSectionSelected(selectedSection);
        }
        this.loadingSections = false;
        this.cdr.markForCheck();
      });
  }

  public onSectionSelected(selectedSection: ComboboxOption) {
    this.clearSubsections();
    if (selectedSection.id === this.holdForLaterId) {
      this.onHoldForLater();
    } else {
      const section = this.selectedPathway.levels.filter((section) => {
        return section.id === selectedSection.id;
      })[0];
      const subsectionOptions: Option[] = section.lessons.map(
        (subsection: PathwayLesson) => {
          const subsectionOption = {
            name: this.translate.instant(
              'addToPathwayModal_LessonNumberFormat',
              {
                number: subsection.number,
              }
            ),
            id: subsection.id,
            node: subsection.node,
          };
          if (subsection.title && subsection.title.trim() !== '') {
            subsectionOption.name = `${subsectionOption.name}: ${subsection.title}`;
          }
          return subsectionOption;
        }
      );
      if (section.lessons.length === 1) {
        this.onSubsectionSelected(subsectionOptions[0]);
      } else {
        this.subsectionOptions = subsectionOptions;
      }
    }
  }

  public onSubsectionSelected(subsectionOption: ComboboxOption) {
    // option in this context is an interface extending ComboboxOption that adds node
    this.selectedSubsectionNode = (subsectionOption as Option).node;
    this.isValid = true;
  }

  public openCreatePathway() {
    let addModalOptions: PathwayAddEditFormOptions;
    let isButton = false;

    const itemToAdd = {
      inputType: this.addToPathwayOptions.inputType,
      inputId: this.addToPathwayOptions.inputId,
    };

    if (this.isEbb) {
      // inputId is required to be able to add it to a new pathway
      if (!this.addToPathwayOptions?.inputId && !this.isCreatingInputIdForEbb) {
        this.isCreatingInputIdForEbb = true;
        this.isSubmitting.next(true);
        this.loadingPathways = true;
        this.submit.emit(false);
        return;
      } else if (this.isCreatingInputIdForEbb) {
        this.loadingPathways = false;
        this.displayArea = 'ebbCreatePathway';
        this.isCreatingInputIdForEbb = false;
        return;
      }
      // open's the modal in user's profile a new window
      addModalOptions = {
        itemToAdd,
        baseUrl: this.ebbAddToPathwayConfig.baseUrl,
      };
      isButton = true;
    } else {
      addModalOptions = {
        itemToAdd,
        forCloning: false,
        preventRedirect: false,
        disableEndorsedOption: true,
      };
    }
    this.closeModal();

    this.pathwayAddEditFormModalService
      .openModal(addModalOptions, isButton)
      .subscribe();
  }

  public closeModal() {
    this.activeModal.close();
  }

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public onFormSubmit() {
    if (this.isEbb && !this.isSubmitting.value) {
      this.isSubmitting.next(true);
      this.submit.emit(this.addToCatalog);
    } else {
      this.addToPathway();
    }
  }

  public toggleAddToCatalog() {
    this.addToCatalog = !this.addToCatalog;
  }

  private loadPathways() {
    this.addToPathwayService
      .getAuthoredPathways()
      .subscribe((authoredPathways) => {
        const recentPathways = this.getRecentPathways(authoredPathways);
        this.pathwayOptions = this.getPathwayOptions(
          authoredPathways,
          recentPathways
        );

        if (this.pathwayOptions.length === 0) {
          this.displayArea = 'noPathways';
        } else {
          this.pathwayOptions.unshift({
            name: this.i18n.addToPathwayModal_CreatePathway,
            id: -1,
            groupingId: 'add-pathway',
            classname: 'combobox__option--add',
          } as any);
        }

        this.loadingPathways = false;
        this.cdr.markForCheck();
      });
  }

  private onHoldForLater() {
    this.holdingForLater = true;
    this.isValid = true;
  }

  private getRecentPathways(authoredPathways: PathwaySummaryModel[]) {
    /**
     * 0-5 pathways: don't show any recent.
     * 6-9 pathways: show 3 recent.
     * 10+ pathways: show 5 recent.
     */
    if (authoredPathways.length < 6) {
      return [];
    }
    const recentPathwaysLimit = authoredPathways.length < 10 ? 3 : 5;
    let recentPathways = authoredPathways.sort((a, b) => {
      return new Date(a.dateModified) > new Date(b.dateModified) ? -1 : 1;
    });
    recentPathways = recentPathways.slice(0, recentPathwaysLimit);
    return recentPathways;
  }

  private getPathwayOptions(
    authoredPathways: PathwaySummaryModel[],
    recentPathways: PathwaySummaryModel[]
  ): Option[] {
    // remove duplicates
    authoredPathways = authoredPathways.filter((val) => {
      return recentPathways.indexOf(val) === -1;
    });

    // alphabetical order A-Z
    authoredPathways.sort((a, b) => {
      const nameA = a.title.toUpperCase(); // ignore upper and lowercase
      const nameB = b.title.toUpperCase(); // ignore upper and lowercase
      return nameA.localeCompare(nameB);
    });

    let pathwayOptions: Option[] = recentPathways.map((path) => {
      return {
        name: path.title,
        id: path.id,
        groupingId: 'recent',
        groupingLabel: this.i18n.addToPathwayModal_RecentlyUsed,
      };
    });

    pathwayOptions = [
      ...pathwayOptions,
      ...authoredPathways.map((path) => {
        return {
          name: path.title,
          id: path.id,
          groupingId: 'all',
          groupingLabel: this.i18n.addToPathwayModal_YourPathways,
        };
      }),
    ];
    return pathwayOptions;
  }

  private clearSections() {
    this.sectionOptions = [];
    this.autoSelectedSection = undefined;
    this.clearSubsections();
  }

  private clearSubsections() {
    this.subsectionOptions = [];
    this.holdingForLater = false;
    this.isValid = false;
  }

  private addToPathway() {
    this.isSubmitting.next(true);
    if (this.holdingForLater) {
      return this.pathwayAddContentService
        .addInputToBin(
          this.selectedPathwayId,
          this.addToPathwayOptions.inputType,
          this.addToPathwayOptions.inputId
        )
        .pipe(
          finalize(() => {
            this.isSubmitting.next(false);
          })
        )
        .subscribe(() => {
          this.onSuccess();
        });
    } else {
      return this.pathwayAddContentService
        .addContentToLesson(
          [
            {
              resourceId: this.addToPathwayOptions.inputId,
              resourceType: this.addToPathwayOptions.inputType,
              organizationId: this.addToPathwayOptions.organizationId,
              isEndorsed: this.addToPathwayOptions.isEndorsed,
            } as LearningResourceViewModel,
          ],
          this.selectedSubsectionNode,
          undefined,
          this.selectedPathwayId,
          false
        )
        .pipe(
          finalize(() => {
            this.isSubmitting.next(false);
          })
        )
        .subscribe(() => {
          this.onSuccess();
        });
    }
  }

  private onSuccess() {
    const currentlyInChannel = this.contextService.urlInChannel();
    const pathwayUrlContext = currentlyInChannel ? 'channel' : undefined;
    if (this.selectedPathwayOption) {
      this.pathwayAuthoringUrl = this.pathwayAuthoringService.getAuthoringUrl(
        this.selectedPathway.publicUrl,
        pathwayUrlContext
      );
    }
    this.successLabel = this.translate.instant(
      'addToPathwayModal_SuccessLabel',
      {
        startBold: '<span class="tw-font-semibold">',
        endBold: '</span>',
        assignmentName: this.addToPathwayOptions.inputName,
        pathwayName: this.selectedPathway.title,
      }
    );
    this.displayArea = 'successMessage';
  }
}
