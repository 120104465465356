import { Injectable } from '@angular/core';
import { MetaData } from '@app/inputs/models/learning-resource.view-model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ContentDurationService {
  private i18n = this.translate.instant([
    'Core_Article',
    'Core_Assessment',
    'Core_Course',
    'Core_Event',
    'Core_Podcast',
    'Core_Post',
    'Core_Task',
    'Core_Video',
    'Core_Pathway',
  ]);

  constructor(private translate: TranslateService) {}

  public triggerVideoDurationUpdate(nativeElement): void {
    // workaround for non-seekable video in Chrome
    // see https://bugs.chromium.org/p/chromium/issues/detail?id=642012

    if (nativeElement.duration === Infinity) {
      // set currentTime to bigger than the actual duration which
      // moves the seeker to the end and also forces duration to be set
      nativeElement.currentTime = Number.MAX_SAFE_INTEGER;
      nativeElement.ontimeupdate = () => {
        // now move it back to zero for playback
        nativeElement.currentTime = 0;
        nativeElement.ontimeupdate = () => {
          // now kill this loop
          nativeElement.ontimeupdate = () => {
            return;
          };
        };
      };
    }
  }

  public setShowLegacyDurationDisplay(
    isMoreDetailsView: boolean,
    isTileCardView: boolean,
    resourceType: string
  ): boolean {
    const newDurationResourceTypes = this.getNewDurationResourceTypes();
    let showLegacyDurationDisplay;
    if (
      (isMoreDetailsView || isTileCardView) &&
      resourceType === this.i18n.Core_Assessment
    ) {
      // We only want to show the number of questions for assessments in the above scenarios
      showLegacyDurationDisplay = true;
    } else if (!newDurationResourceTypes.includes(resourceType)) {
      // Show legacy durationDisplay property for books & any other types of content not within the array
      showLegacyDurationDisplay = true;
    } else {
      showLegacyDurationDisplay = false;
    }
    return showLegacyDurationDisplay;
  }

  public shouldDurationTimeBeCalculated(
    resourceType: string,
    isMoreDetailsView: boolean,
    isTileCardView: boolean
  ): boolean {
    return (
      resourceType === this.i18n.Core_Course ||
      resourceType === this.i18n.Core_Video ||
      resourceType === this.i18n.Core_Task ||
      resourceType === this.i18n.Core_Podcast ||
      resourceType === this.i18n.Core_Event ||
      resourceType === this.i18n.Core_Post ||
      resourceType === this.i18n.Core_Article ||
      resourceType === this.i18n.Core_Pathway ||
      (!isMoreDetailsView &&
        !isTileCardView &&
        resourceType === this.i18n.Core_Assessment)
    );
  }

  public calculateDurationTime(
    resourceType: string,
    durationMinutes: number,
    durationHours: number,
    isTileCardView: boolean
  ): { hoursToDisplay: string | null; minutesToDisplay: string | null } {
    const contentMetaData: MetaData = {
      resourceLabel: resourceType,
      durationMinutes,
      durationHours,
    };
    const isVideoOrPodcast =
      resourceType === this.i18n.Core_Video ||
      resourceType === this.i18n.Core_Podcast;
    let hoursToDisplay;
    let minutesToDisplay;

    if (
      (!isTileCardView && !isVideoOrPodcast) ||
      (isTileCardView && resourceType === this.i18n.Core_Event) ||
      (isTileCardView && resourceType === this.i18n.Core_Course)
    ) {
      hoursToDisplay = this.getHoursToDisplay(contentMetaData, isTileCardView);
    }
    if (
      resourceType !== this.i18n.Core_Event &&
      !(isTileCardView && resourceType === this.i18n.Core_Course)
    ) {
      minutesToDisplay = this.getMinutesToDisplay(
        contentMetaData,
        isVideoOrPodcast,
        isTileCardView
      );
    }
    return { hoursToDisplay, minutesToDisplay };
  }

  private getNewDurationResourceTypes(): Array<string> {
    return [
      this.i18n.Core_Assessment,
      this.i18n.Core_Article,
      this.i18n.Core_Course,
      this.i18n.Core_Video,
      this.i18n.Core_Task,
      this.i18n.Core_Post,
      this.i18n.Core_Podcast,
      this.i18n.Core_Event,
      this.i18n.Core_Pathway,
    ];
  }

  private getHoursToDisplay(
    metaData: MetaData,
    isTileCardView: boolean
  ): string | null {
    if (
      metaData.resourceLabel === this.i18n.Core_Event ||
      (isTileCardView && metaData.resourceLabel === this.i18n.Core_Course)
    ) {
      return this.getHoursToDisplayForEventsAndCourseTiles(metaData);
    } else if (metaData.durationHours === 1) {
      return `${this.translate.instant('Core_1Hour')}`;
    } else if (metaData.durationHours > 0) {
      return `${this.translate.instant('Core_HoursFormat', {
        hours: metaData.durationHours,
      })}`;
    }
    return null;
  }

  private getHoursToDisplayForEventsAndCourseTiles(
    metaData: MetaData
  ): string | null {
    let totalNumberOfHours;
    const durationHours = metaData.durationHours;
    const durationMinutes = metaData.durationMinutes;

    if (durationHours && durationMinutes) {
      totalNumberOfHours = Number(
        (durationHours + durationMinutes / 60).toFixed(2)
      );
    } else if (durationHours) {
      totalNumberOfHours = durationHours;
    } else if (durationMinutes) {
      totalNumberOfHours = Number((durationMinutes / 60).toFixed(2));
    } else {
      totalNumberOfHours = null;
    }

    if (totalNumberOfHours === 1) {
      return `${this.translate.instant('Core_1Hour')}`;
    } else if (totalNumberOfHours > 0) {
      return `${this.translate.instant('Core_HoursFormat', {
        hours: totalNumberOfHours,
      })}`;
    }
    return null;
  }

  private getMinutesToDisplay(
    metaData: MetaData,
    isVideoOrPodcast: boolean,
    isTileCardView: boolean
  ): string | null {
    let totalNumberOfMinutes;

    if (isTileCardView || isVideoOrPodcast) {
      if (metaData.durationHours && metaData.durationMinutes) {
        totalNumberOfMinutes =
          metaData.durationHours * 60 + metaData.durationMinutes;
      } else if (metaData.durationHours) {
        totalNumberOfMinutes = metaData.durationHours * 60;
      } else if (metaData.durationMinutes) {
        totalNumberOfMinutes = metaData.durationMinutes;
      }
    } else {
      totalNumberOfMinutes = metaData.durationMinutes;
    }

    return !totalNumberOfMinutes
      ? null
      : totalNumberOfMinutes === 1
      ? `${this.translate.instant('Core_1Minute')}`
      : `${this.translate.instant('Core_MinutesFormat', {
          minutes: totalNumberOfMinutes,
        })}`;
  }
}
