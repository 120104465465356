import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ngbDateToJsMonth } from '@app/shared/utils/time-utils';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Custom date formatter for use in a month picker
 * NOTE: the js `Date` month is 0-based, NgbDateStruct month is 1-based
 */
@Injectable()
export class MonthDateParserFormatterService implements NgbDateParserFormatter {
  constructor(private datePipe: DatePipe) {}

  /** Parse the given date as a ngb date struct with day forced to 1 */
  public parse(value?: string): NgbDateStruct {
    if (!value) return;
    const date = new Date(value);

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: 1,
    };
  }

  /** Format the struct as a date, with day forced to 1 */
  public format(ngbDate: NgbDateStruct): string {
    if (!ngbDate) return '';

    const date = ngbDateToJsMonth(ngbDate);
    return this.datePipe.transform(date, 'MMM y');
  }
}
