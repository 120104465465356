import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PathwayLearnerService } from '@app/pathways/services/pathway-learner.service';
import { IntegrationsService } from '@app/profile-settings/components/integrations/integrations.service';
import { SkillScaleLevelChangeModalTypes } from '@app/profile/components/skill-scale-level-change-modal/services/skill-scale-level-change.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsRecommendationService } from '@app/tags/services/tags-recommendation.service';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PathwayViewedItemListComponent } from '../pathways/components/pathway/pathway-viewed-item-list/pathway-viewed-item-list.component';
import {
  CollectionDetails,
  Notification,
  NotificationIcon,
  NotificationPerson,
  NotificationType,
  RequiredLearningCounts,
} from './notification-api.model';
import { camelCaseKeys } from '@app/shared/utils';

export type NotificationCollection = CollectionDetails<Notification>;

export interface NotificationsState {
  notifications: Notification[]; // current set of loaded notifications
  loadInfo?: {
    start: number; // if the state change was due to a load, the index of the most recently loaded notification page
    length: number; // if the state change was due to a load, length of the most recently loaded notification page
  };
  hasMoreLoadableItems: boolean;
  requiredLearningItemCount: number; // number of required learning item notifications
  requiredLearningNewNotificationCount: number; // number of unread required learning notifications
  newNotificationCount: number; // total number of unread notifications
  requiredLearningPhrase?: string; // phrase describing required learning count
}

const EMPTY_NOTIFICATIONS_STATE = {
  notifications: [],
  hasMoreLoadableItems: true, // initially assume we can get at least more than our zero items
  requiredLearningItemCount: 0,
  requiredLearningNewNotificationCount: 0,
  newNotificationCount: 0,
};

/** A stateful service for requesting and managing notifications. */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public _notificationsState = new BehaviorSubject<NotificationsState>(
    EMPTY_NOTIFICATIONS_STATE
  );
  // ** This updates whenever new notifications or counts are retrieved via the `get` APIs. Makes a
  // a convenient way to globally listen for any changes so the listener always has the latest
  // regardless of who kicked a request off. */
  public readonly notificationsState = this._notificationsState.asObservable();

  // TODO: Replace temporarily injected ngjs services with native angular service when angular modals are implemented
  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient,
    private integrationsService: IntegrationsService,
    private tagsRecommendationService: TagsRecommendationService,
    private authService: AuthService,
    private tagRatingService: TagRatingService,
    private pathwayLearnerService: PathwayLearnerService,
    private modalService: ModalService,
    private windowLayoutService: WindowLayoutService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private get currentState() {
    return this._notificationsState.value;
  }

  // TODO: back end should send a different NotificationType for each
  // evaluation endorsement status, then we can call lookupIcon() directly
  public getIcon(notification): NotificationIcon {
    let type: string;
    if (
      notification.notificationType === 'UserEvaluationEndorsementCompleted'
    ) {
      type =
        +notification.parameters.endorsementStatus === 2
          ? 'userevaluationendorsementcompleted'
          : 'userevaluationendorsementchanges';
    } else if (notification.notificationType === 'BulkRecommendation') {
      switch (notification.parameters.resourceType) {
        case 'Tag':
          type = 'tagbulkrecommendation';
      }
    } else {
      type = notification.notificationType;
    }
    return this.lookupIcon(type);
  }

  /** Looks up an icon mapped to a character code */
  public lookupIcon(notifyType: string = ''): NotificationIcon {
    switch (notifyType.toLowerCase()) {
      case 'orgratingscalechange':
        return 'info-square';
      case 'completedgoal':
      case 'unsubscribe':
      case 'completedrecommendation':
      case 'userevaluationendorsementcompleted':
        return 'checkmark-square'; // check mark
      case 'userevaluationendorsementchanges':
        return 'exclamation-square'; // exclamation point
      case 'singlerecommendation':
      case 'assignedlearning':
        return 'arrow-forward-square'; // reply
      case 'groupinvite':
      case 'groupinviteaccepted':
      case 'requestgroupmembership':
      case 'userevaluationendorsementrequested':
        return 'person-triple-square'; // people
      case 'grouppostadded':
      case 'grouppostmention':
      case 'mentionincomment':
      case 'replytocomment':
      case 'replytopost':
        return 'speech-bubble-square'; // speech bubble
      case 'likecomment':
      case 'likegroupactivity':
        return 'heart-square'; // heart
      case 'newcontentinfollowedpathway':
      case 'newtargetresource':
      case 'newtargetauthor':
      case 'newpathwayauthor':
      case 'opportunitynewauthor':
      case 'singleassessmentfoundaddskill':
      case 'multipleassessmentsfoundaddskills':
        return 'plus-square'; // plus
      case 'newuserfollower':
        return 'person-square'; // person
      case 'requiredlearning':
        return 'clock-square'; // clock
      case 'facebookconnectsuccess':
        return 'facebook-square'; // Facebook icon
      case 'usertagratingrequested':
      case 'usertagratingcompleted':
      case 'mentorshipfeedbackrequest':
      case 'mentorshipfeedbackreceived':
        return 'person-double-square';
      case 'skillmatched':
      case 'newskillsprovideradded':
      case 'singleassessmentfoundreviewskill':
      case 'multipleassessmentsfoundreviewskills':
        return 'tools-square'; // tools icon
      case 'usertagsetgoal':
        return 'dart-square'; // target icon
      case 'customreportoutcome':
      case 'downloadreportoutcome':
        return 'bargraph-square'; // graph icon
      case 'resourceassociationremoved':
        return 'minus-square'; // dash/hyphen icon
      case 'pathwaycontentcompletionreminder':
      case 'brokenlinksscancompleted':
        return 'eye-open-square'; // eye
      case 'opportunityinterested':
      case 'opportunityclosed':
        return 'opportunity'; // briefcase
      case 'workdayskillsintegrationtoggled':
      case 'workdayskilladded':
      case 'workdayskillremoved':
      case 'tagbulkrecommendation':
      case 'skillsaddedtoprofilefromjobrole':
      case 'skillchangepublished':  
        return 'tag-square'; // tag
      default:
        return 'checkmark-square'; // check mark
    }
  }

  public loadShallowCounts() {
    return this.http
      .get<RequiredLearningCounts>('/userprofile/gethighlevelcounts')
      .pipe(
        tap<RequiredLearningCounts>((data) => {
          let stateChanges = {};
          stateChanges = { newNotificationCount: data.notificationCount };
          this.updateState({
            ...stateChanges,
          });
        })
      );
  }

  public skipCompleteViewedItem(inputType, inputId) {
    return this.http.post('/user/skipcompletevieweditem', {
      inputType,
      inputId,
    });
  }

  /** Gets notifications of a particular type, without adding them to the current state */
  public getNotificationsOfType(
    skip,
    count,
    notificationTypeMode
  ): Observable<NotificationCollection> {
    return this.http
      .get<NotificationCollection>('/user/getallusernotifications', {
        params: { skip, count, notificationTypeMode },
      })
      .pipe(
        tap((collection) => {
          collection.items.forEach((n) => this.normalizeNotification(n));
        })
      );
  }

  /** Gets notifications and adds them to the current state.
   * @param skip: offset to load items at. If skip === 0, the current items will be replaced, otherwise
   * they will be appended, and skip must be equal to the number of existing items.
   */
  public loadNotifications(
    skip: number,
    count: number
  ): Observable<NotificationCollection> {
    // TODO: This standard paging API is't a great fit for a feed. New items can appear at any time,
    // making the top-relative 'skip' param unstable. Better to allow a notification id to be passed
    // as an absolute reference identifying the item preceding the requested page (see )
    if (skip !== 0 && skip !== this.currentState.notifications.length) {
      throw new Error('Notifications must be loaded progressively');
    }
    return this.http
      .get<NotificationCollection>('/user/getallusernotifications', {
        params: { skip, count },
      })
      .pipe(
        tap((collection) => {
          collection.items.forEach((n) => this.normalizeNotification(n));
          // immutably create new state from old
          const existingItems = this.currentState.notifications;
          let newItems: Notification[];
          if (skip > 0) {
            // Its possible that a component may load new items that we already have. In this case replace them
            // and make the new ones the new end of the list
            if (skip < existingItems.length) {
              newItems = [...existingItems.slice(0, skip), ...collection.items];
            } else {
              newItems = [...existingItems, ...collection.items];
            }
          } else {
            newItems = [...collection.items];
          }

          const stateChanges = {
            notifications: newItems,
            loadInfo: {
              start: skip,
              length: collection.items.length,
            },
            hasMoreLoadableItems: collection.hasMoreItems,
          };
          this.updateState(stateChanges);
        })
      );
  }

  public getById(notificationId: string): Observable<Notification> {
    return this.http
      .get('/user/getnotificationforuserbyid', {
        params: { notificationId },
      })
      .pipe(
        map(
          (
            notification: Omit<Notification, 'parameters'> & {
              parameters: string;
            }
          ) => ({
            ...notification,
            parameters: camelCaseKeys(JSON.parse(notification.parameters)),
          })
        )
      );
  }

  public markNotificationsRead(): Observable<any> {
    return this.http.get('/user/marknotificationsread').pipe(
      tap(() => {
        const stateChanges = {
          // update existing state with counts, leaving only new required learning items
          newNotificationCount:
            this.currentState.requiredLearningItemCount - // include required learning items
            this.currentState.requiredLearningNewNotificationCount, // but subtract any new ones already accounted for in the notificationCount,
        };
        this.updateState(stateChanges);
      })
    );
  }

  public dismissNotification(id): Observable<any> {
    return this.http.post('/user/dismissnotification', {
      notificationId: id,
    });
  }

  public snoozeNotification(notificationType, daysToSnooze): Observable<any> {
    return this.http.post('/user/snoozenotification', {
      notificationType,
      daysToHide: daysToSnooze,
    });
  }

  public markNotificationRead(id): Observable<any> {
    return this.http.post('/user/marknotificationread', {
      notificationId: id,
    });
  }

  public markBannerNotificationsRead(
    notificationId,
    notificationType,
    daysToHide
  ) {
    return this.http.post('/user/markbannernotificationsread', {
      NotificationId: notificationId,
      NotificationType: notificationType,
      DaysToHide: daysToHide,
    });
  }

  public goToNotification(notification: Notification) {
    let loc = null;
    // We need to come back and get everything running on navigateByUrl but some don't work
    let navigateByUrl = false;
    const {
      tagId,
      tagName: name,
      tagRatingType,
      comment,
      alreadyCompleted,
      sourceUrl,
      inputUrl,
      pathId,
      person,
    } = notification.parameters;

    switch (notification.notificationType) {
      case 'UserTagRatingRequested':
        if (alreadyCompleted) {
          // Rater already completed the rating. Do nothing.
          return;
        }
        switch (tagRatingType) {
          case InternalTagRatingTypes.manager:
            this.tagRatingService.processAssociateRatingRequested(
              this.getUserData(person),
              {
                name,
                tagId,
              }
            );
            break;
          case InternalTagRatingTypes.peer:
            this.tagRatingService.processPeerRatingRequested(
              this.getUserData(person),
              tagId,
              name,
              comment
            );
            break;
          case InternalTagRatingTypes.self:
            this.tagRatingService.processSelfRatingRequested(
              this.getUserData(person),
              tagId,
              name,
              comment
            );
            break;
        }
        break;
      case 'UserTagRatingCompleted': {
        this.tagRatingService.processAssociateRatingCompleted(
          tagRatingType,
          this.getUserData(person),
          tagId
        );
        break;
      }
      case 'UserTagSetGoal': {
        this.tagRatingService.processAssociateRatingCompleted(
          InternalTagRatingTypes.target,
          this.getUserData(person),
          tagId
        );
        break;
      }
      case 'UserEvaluationEndorsementCompleted':
        this.tagRatingService.openEndorsementCompletedModal(notification);
        break;
      case 'CompletedRecommendation':
      case 'CompletedAssignedLearning':
        loc = person.userProfileUrl;
        break;
      case 'PathwayContentCompletionReminder':
        this.pathwayLearnerService
          .getViewedItems(pathId)
          .subscribe((pathwayViewedItems: any) => {
            this.modalService.show(PathwayViewedItemListComponent, {
              inputs: {
                pathwayViewedItems,
              },
            });
          });
        break;
      case 'BulkRecommendation':
        this.tagsRecommendationService.processBulkRecommendation(notification);
        loc = sourceUrl.replace('#', ''); // This is needed for backwards compatibility for notifications that were created when AngularJS routing was enabled
        break;
      case 'CustomReportOutcome':
        loc = sourceUrl.replace('#', ''); // This is needed for backwards compatibility for notifications that were created when AngularJS routing was enabled
        break;
      case 'WorkdaySkillsIntegrationToggled':
      case 'WorkdaySkillAdded':
      case 'WorkdaySkillRemoved':
      case 'SkillsAddedToProfileFromJobRole':
        loc = this.getSkillsLink();
        break;
      case 'OrgRatingScaleChange':
        loc = this.getOrgRatingChangeModalLink();
        navigateByUrl = true;
        break;
      case 'SkillChangePublished':
        loc = this.getSkillChangePublishedLink();
        navigateByUrl = true;
        break;  
      default:
        loc = sourceUrl || inputUrl;
    }
    if (loc) {
      if (this.windowLayoutService.isIframe) {
        const url = loc.includes(this.document.location.origin)
          ? loc
          : `${this.document.location.origin}${loc}`;
        window.open(url, '_blank');
        return true;
      }
      if (navigateByUrl) {
        this.router.navigateByUrl(loc);
        return true;
      } else {
        this.document.location.href = loc;
      }
    }
  }

  public getAssignedLearningLink() {
    const profileUrl = this.authService.authUser?.viewerProfile.profileUrl;
    return `/${profileUrl}/dashboard/assignments`;
  }

  public getSkillsLink() {
    const profileUrl = this.authService.authUser?.viewerProfile.profileUrl;
    return `/${profileUrl}/skills`;
  }

  public getOrgRatingChangeModalLink() {
    // Redirects to the user profile and opens a modal showing the rating changes
    const profileUrl = this.authService.authUser?.viewerProfile.profileUrl;
    return `/profile/${profileUrl}/skills?showModal=${SkillScaleLevelChangeModalTypes.ORG_RATING_SCALE_CHANGE}`;
  }

  public getSkillChangePublishedLink() {
    const profileUrl = this.authService.authUser?.viewerProfile.profileUrl;
    return `/profile/${profileUrl}/skills`;
  }

  private getUserData(person: NotificationPerson) {
    return {
      name: person.userFullName,
      userProfileKey: person.userProfileKey,
      picture: person.userPictureUrl,
      userProfileUrl: person.userProfileUrl,
    };
  }

  private normalizeNotification(notification: Notification): Notification {
    // Transform notification types where needed
    if (
      notification.notificationType === 'LikeGroupActivity' &&
      notification.parameters.action === 'Post'
    ) {
      notification.notificationType = 'LikeComment';
    }
    if (
      this.isDeprecated(notification.notificationType) ||
      (notification.notificationType === 'LikeComment' &&
        notification.parameters.personCount < 1)
    ) {
      notification.notificationType = 'Deprecated';
    }

    switch (notification.notificationType) {
      case 'Unsubscribe':
        if (
          notification.parameters &&
          notification.parameters.notificationTypeId
        ) {
          const type = this.translate.instant(
            `UserSettingsSvc_NotificationTypeDisplayText${notification.parameters.notificationTypeId}`
          );
          notification.notificationText = this.translate.instant(
            'NotificationManager_UnsubscribeFormat',
            { type: type }
          );
        }
        break;
      case 'UserEvaluationEndorsementRequested':
        if (
          notification.parameters &&
          notification.parameters.userEvaluationUid
        ) {
          notification.parameters.sourceUrl = `/skillreview/endorse?uid=${notification.parameters.userEvaluationUid}`;
        }
        break;
      case 'SocialConnectSuccess':
        const socialDetails =
          this.integrationsService.getSocialIntegrationTypes();
        if (
          notification.parameters &&
          notification.parameters.providerCode &&
          notification.parameters.providerCode ===
            socialDetails[notification.parameters.providerCode].providerCode
        ) {
          notification.notificationType = (notification.parameters
            .providerCode + 'ConnectSuccess') as NotificationType;
          notification.parameters.viewFriendType =
            socialDetails[notification.parameters.providerCode].viewFriendType;
        }
        break;
      case 'SocialConnectionJoined':
        const socialConnectionDetails =
          this.integrationsService.getSocialIntegrationTypes();
        if (
          notification.parameters &&
          notification.parameters.providerCode &&
          notification.parameters.providerCode ===
            socialConnectionDetails[notification.parameters.providerCode]
              .providerCode
        ) {
          notification.notificationType = (notification.parameters
            .providerCode + 'ConnectionJoined') as NotificationType;
          notification.parameters.viewFriendType =
            socialConnectionDetails[
              notification.parameters.providerCode
            ].viewFriendType;
        }
        break;
      case 'WorkdaySingleProfileSkillAdded':
      case 'WorkdayMultipleProfileSkillsAdded':
        notification.notificationType = 'WorkdaySkillAdded';
        break;
      case 'WorkdaySingleProfileSkillRemoved':
      case 'WorkdayMultipleProfileSkillsRemoved':
        notification.notificationType = 'WorkdaySkillRemoved';
        break;
    }
    return notification;
  }

  private updateState(stateUpdates: Partial<NotificationsState>) {
    const newState = {
      ...this.currentState,
      loadInfo: undefined, // zero out load info each time unless explicitly provided by a load
      ...stateUpdates,
    };
    // dumb equality check. Should be fine for simple static dto's
    if (!(JSON.stringify(this.currentState) === JSON.stringify(newState))) {
      this._notificationsState.next(newState);
    }
  }

  private isDeprecated(type) {
    switch (type.toLowerCase()) {
      case 'achievedgoal':
      case 'assignedgoal':
      case 'goalreminder':
      case 'groupactivitycomment':
      case 'inputcomment':
      case 'likegroupactivity':
      case 'newuserinput':
      case 'providercomment':
        return true;
      default:
        return false;
    }
  }
}

export type NotificationAction =
  // should align with SinaAction enum
  | 'Close'
  | 'EnableDailyEmail'
  | 'MarkReadAndClose'
  | 'CallbackAndClose'
  | 'CompleteViewed_Dismiss'
  | 'CompleteViewed_Skip'
  | 'CompleteViewed_Complete';
