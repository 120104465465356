<div
  ngbDropdown
  #ngbDropdown="ngbDropdown"
  placement="bottom-left"
  [autoClose]="'outside'"
  [open]="isOpen"
  (openChange)="toggled($event)"
  data-dgat="reportingRangePicker-987"
>
  <button
    ngbDropdownToggle
    class="{{ classes.button }}"
    [ngClass]="{ is_disabled: disabled }"
    [disabled]="disabled"
    type="button"
    data-dgat="reportingRangePicker-28c"
  >
    <span class="{{ classes.content }}">
      <df-icon
        *ngIf="appearance === 'dropdown'"
        class="guts-m-r-half"
        icon="calendar"
        size="small"
      ></df-icon>
      <span class="sr-only">{{ 'Core_CalendarSelectRange' | translate }}</span>
      <span>{{ labels.startDate }} - {{ labels.endDate }}</span>
      <df-icon
        size="small"
        class="btn-dropdown__icon par--small guts-m-h-quart"
        [attr.aria-label]="'Core_CalendarView' | translate"
        aria-haspopup="true"
        aria-expanded="false"
        icon="chevron-down"
      ></df-icon>
    </span>
  </button>
  <div
    ngbDropdownMenu
    [ngSwitch]="datepickerState"
    [attr.a11y-is-open]="isOpen"
    role="menu"
    class="dropdown-menu guts-p-t-half guts-p-b-half rounded--large"
  >
    <div class="l_flexbar guts-p-t-1 guts-p-h-1 guts-m-b-half">
      <df-icon
        icon="info"
        size="small"
        class="guts-m-r-half color-ebony-a61"
      ></df-icon>
      <span class="par par--light par--small">{{
        'Core_UTCDatesNote' | translate
      }}</span>
    </div>

    <ul
      *ngSwitchCase="'quickSelect'"
      aria-labelledby="date-range-button"
      style="width: 300px"
    >
      <li
        *ngFor="let days of maxDaysBoundaries"
        data-dgat="reportingRangePicker-a39"
        ngbDropdownItem
      >
        <button
          (click)="setSpecificDates(days)"
          type="button"
          class="combobox__option listbox--option"
          role="menuitem"
          [attr.data-dgat]="'datepicker-' + days + 'days'"
        >
          <div class="font-semibold color-ebony">{{ getLabel(days) }}</div>
          <div class="font-medium">{{ getSpecificDates(days) }}</div>
        </button>
      </li>
      <li ngbDropdownItem>
        <button
          (click)="datepickerState = 'calendar'"
          type="button"
          class="combobox__option listbox--option color-ebony"
          role="menuitem"
          data-dgat="reportingRangePicker-099"
        >
          <df-icon
            class="guts-m-r-half ng-scope"
            icon="calendar"
            size="small"
          ></df-icon>
          <span class="ib-v-middle font-semibold">{{
            'Core_CalendarSpecificDates' | translate
          }}</span>
        </button>
      </li>
    </ul>
    <div *ngSwitchCase="'calendar'">
      <ng-container *ngTemplateOutlet="customDate"></ng-container>
    </div>
  </div>
</div>

<!-- Custom Date Template -->
<ng-template #customDate>
  <div class="guts-p-full-1">
    <div class="guts-p-b-1">
      <p class="par par--small color-ebony font-medium guts-p-b-half">
        {{ 'Core_CalendarSelectRange' | translate }}
      </p>
      <input
        #dpCustomStartDate
        ngbDatepicker
        name="dpCustomStartDate"
        [value]="customDateParserFormatter.format(customStartDate)"
        (input)="
          customStartDate = validateInput(
            customStartDate,
            dpCustomStartDate.value
          )
        "
        class="range-input guts-m-r-half"
        type="text"
        readonly
        data-dgat="reporting-range-picker-bdd"
      />
      <input
        #dpCustomEndDate
        ngbDatepicker
        name="dpCustomEndDate"
        [value]="customDateParserFormatter.format(customEndDate)"
        (input)="
          customEndDate = validateInput(customEndDate, dpCustomEndDate.value)
        "
        class="range-input"
        type="text"
        readonly
        data-dgat="reporting-range-picker-1b0"
      />
    </div>
    <ngb-datepicker
      class="ngb-datepicker--borderless"
      [dayTemplate]="dayTemplate"
      [footerTemplate]="footerTemplate"
      [startDate]="customStartDate"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (dateSelect)="dateSelect($event)"
      [displayMonths]="1"
      dgxDatepickerDataDgat
    >
    </ngb-datepicker>
  </div>
</ng-template>

<!-- Day Template -->
<ng-template
  #dayTemplate
  let-date
  let-focused="focused"
  let-disabled="disabled"
>
  <span
    class="custom-day"
    [class.custom-day--focused]="focused"
    [class.custom-day--disabled]="disabled"
    [class.custom-day--range]="isWithinRange(date)"
    [class.custom-day--range-end]="isRangeEnd(date)"
    [class.custom-day--range-st]="isStRange(date)"
    [class.custom-day--range-ed]="isEdRange(date)"
  >
    {{ date.day }}
  </span>
</ng-template>

<!-- Footer Template -->
<ng-template #footerTemplate>
  <div
    *ngIf="validationMessage"
    [@fadeIn]
    class="form__invalid-msg ib rel"
    class="form__invalid-msg ib rel"
    aria-live="polite"
  >
    <df-icon
      icon="arrow-down-right"
      size="small"
      class="guts-p-r-quart"
    ></df-icon
    >{{ validationMessage }}
  </div>
  <div class="l_flexbar l_flex-justify guts-p-t-1">
    <button
      df-button
      dfButtonType="passive"
      (click)="toggleCustomDate($event)"
      data-dgat="reporting-range-picker-9b1"
    >
      {{ 'Core_Cancel' | translate }}
    </button>
    <button
      df-button
      dfButtonType="primary"
      [disabled]="!isValid"
      (click)="apply()"
      data-dgat="date-range-filter-7dd"
    >
      {{ 'Core_Apply' | translate }}
    </button>
  </div>
</ng-template>
