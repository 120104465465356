import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  UrlTree,
} from '@angular/router';
import * as api from '@app/pathways/rsm/pathway-api.model';
import { PathwayPermissionsModel } from '@app/pathways/rsm/pathway-api.model';
import * as helpers from '@app/shared/utils/route-guard-helpers';
import { AuthService } from '@dg/shared-services';
import { GetPathwayWithPermissionsParams } from '../pathway.model';

/**
 * Check if a user is even logged in, then more granular permissions.
 * @see {PathwayPageGuard} @see {PathwayPageLegacyGuard}
 *
 * @param authService - AuthService from parent service.
 * @param router - Router from parent service.
 * @param activeSnapshot - ActiveSnapshot from parent service.
 * @param hasPermission - Result of parent service hasViewPermissions method.
 */
export const canActivate = async (
  authService: AuthService,
  router: Router,
  activeSnapshot: ActivatedRouteSnapshot,
  permissions: Promise<boolean | UrlTree>
): Promise<boolean | UrlTree> => {
  // Immediately stop work if the user is not logged in.
  if (!helpers.ensureLoggedIn(authService, router, activeSnapshot)) {
    return false;
  }
  // Turn our promise into a boolean.
  const hasPermission = await permissions;
  // If truthy, continue; otherwise, error page redirect!
  return hasPermission ? hasPermission : errorHandler(router);
};

/**
 * Check if the user has permission to view the route
 *
 * @param childRoute - Child route segment(s).
 * @param permissions - Auth user permissions.
 * @param isEditMode - Whether the user is in editing mode.
 */
export const checkPermissions = (
  childRoute: string,
  permissions: PathwayPermissionsModel | api.PathwayPermissionsModel,
  isEditing = false
): boolean | UrlTree => {
  // We already pre-checked that the user is logged in
  let hasPermissions: boolean = true;
  // Any 'insights' routes need to check the canViewInsightsTab permission.
  if (childRoute.includes('insights')) {
    if (childRoute.includes('reporting')) {
      hasPermissions = permissions.canViewPathwayReports;
    }
    else 
    {
      hasPermissions = permissions.canViewInsightsTab;
    }
  }
  // Meanwhile any 'following' routes need to check the pathway following tab permission.
  else if (childRoute.includes('enrollees')) {
    hasPermissions = permissions.canViewPathwayEnrollees;
  }
  // Additionally check for canEditPathway if in editing mode.
  if (hasPermissions && isEditing) {
    hasPermissions = permissions?.canEditPathway;
  }
  // Return final permissions.
  return hasPermissions;
};

/**
 * Grab the obfuscatedId from the active snapshot.
 *
 * @param activeSnapshot - ActiveSnapshot from parent service.
 */
export const getPathwayObfuscatedId = (
  activeSnapshot: ActivatedRouteSnapshot
): string => {
  // if we're on a regular pathway route, we are *always* on a child to the :obfuscatedId
  // so we get the id from the parent:
  const parentParam = activeSnapshot.parent.params?.obfuscatedId;
  // if we aren't, and routing directly to the insights paths (that are *not* real child routes),
  // then we get the obfuscatedId from the paramMap.
  const paramMap = activeSnapshot.paramMap.get('obfuscatedId');
  // Return this segment.
  return paramMap ?? parentParam;
};

/**
 * The /api/getpathwaywithuserpermissions only needs an ID to get a pathway, but the
 * old routes also need a bit of massaging in terms of path segment order.
 *
 * @param queryParams - Params that might have been passed in and might contain an id prop.
 * @param currentPath - Full path for current page, including parent segments and query params.
 * @param isEditing - Whether or not we're in edit mode.
 */
export const getLoadPathwayParams = (
  queryParams: Params,
  currentPath: string,
  isEditing: boolean
): {
  pathId: number;
  childRoute: string;
  isEditing: boolean;
} => {
  // Set param
  let pathId: number;
  // Default to 'pathway', overwrite if currentPath contains certain parameters
  let childRoute = 'pathway';

  // Grab ID parameter, if it exists to be grabbed.
  if (queryParams?.id) {
    // Coerce to a number.
    pathId = +queryParams.id;
  }

  // Check if our current path contains more than one segment (e.g. `/paths` versus `/paths/report`)
  if (currentPath.indexOf('/', 1) > 0) {
    // If we don't already have an ID, check the last segment of our path to see if it's an ID.
    if (!pathId) {
      // .pop() and .shift() modify the original array, but that's fine; we don't care about these temporary arrays.
      const potentialId = currentPath
        // '/paths/insights/123?orgsso=Degreed&tltag=blah-blah-blah' -> ['', 'paths', 'insights', '123?orgsso=Degreed&tltag=blah-blah-blah']
        .split('/')
        // -> '123?orgsso=Degreed&tltag=blah-blah-blah'
        .pop()
        // -> ['123', '?orgsso=Degreed&tltag=blah-blah-blah']
        .split('?')
        // -> '123'
        .shift();
      // If our last segment is a number, set pathId accordingly.
      if (potentialId.match(/^[0-9]+$/)) {
        // Coerce to a number.
        pathId = +potentialId;
      }
    }
    // Continue...
    if (currentPath.includes('report')) {
      // matches paths/reports but also paths/reporting
      childRoute = 'insights/reporting';
    } else if (currentPath.includes('insights')) {
      childRoute = 'insights';
    } else if (currentPath.includes('enrollees')) {
      childRoute = 'enrollees';
    } else if (currentPath.includes('authoring')) {
      isEditing = true;
      // Default value for childRoute is fine here.
    }
    // DELIBERATELY DO NOT HANDLE ANY OTHER PATH SEGMENTS.
    // There is a final pattern, where a kebab-cased 'path' property is passed, e.g. https://localhost:44300/paths?path=title-of-pathway&id=11111
    // (which also raises the possibility of https://localhost:44300/paths/title-of-pathway/11111)
    // and we want to *discard* this 'path' segment, grabbing only the ID.
  }

  return { pathId, childRoute, isEditing };
};

/**
 * Lowercase all query params received
 * Safety measure to make sure all query params match have the same casing
 */
export const normalizeQueryParams = (params: Params): Params => {
  const normalizedParams: Params = {};
  let normalizedParam: string;
  let isEditing = false;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      // Lowercase all params except 'newWindow', which we don't care about and shouldn't modify.
      normalizedParam = key !== 'newWindow' ? key.toLowerCase() : key;
      // Update value of isEditing and avoid adding editmode to our params.
      if (normalizedParam === 'editmode') {
        isEditing = params[key] === 'true';
      } else {
        normalizedParams[normalizedParam] = params[key];
      }
    }
  }
  // Effectively add editmode back *as a boolean*.
  normalizedParams.isEditing = isEditing;
  // Return completed params.
  return normalizedParams;
};

/**
 * Sends the user to a 404 page when needed.
 *
 * @param router - Router from parent service.
 */
const errorHandler = (router: Router): Promise<boolean> =>
  router.navigateByUrl('/error-handler/404');
