import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DgError } from '../models/dg-error';

/** For checking that the exact error emitted is about focus. */
export const MODAL_FOCUS_ERROR = 'modal dismissed. focus management needed!';

/**
 * For modals where focus is being manually handled elsewhere,
 * e.g. in the component.
 *
 * This will pass dismiss and focus errors on unaltered, so that
 * the component can catch and handle them. True errors, on the
 * other hand, are masked by the passed-in, already-translated
 * error message, and re-thrown, so that the global error service
 * can handle them appropriately.
 *
 * See @type {catchAndSurfaceError}
 *
 * @param error - Either an error, a number, or the focus string.
 * @param translatedErrorMessage - The message for actual errors.
 */
export function catchAndSurfaceModalFocusError<T>(
  translatedErrorMessage: string
): (error: unknown) => Observable<T> {
  return catchError((error) => {
    // wrap true errors in our DgError, for global error service handling
    if (isTrueModalError(error)) {
      return throwError(() => new DgError(translatedErrorMessage, error));
    }
    // throw non-falsy "errors" so that they can be handled by the component
    // for focus manipulation
    return !!error
      ? throwError(() => error)
      : // but otherwise, just return EMPTY to complete the stream without erroring (sanity-check)
        EMPTY;
  });
}

/**
 * For modals where focus is being handled in the service, via a
 * finalize() call.
 *
 * This will swallow 'errors' from modal dismissal. True errors
 * will be masked by the passed-in, already-translated error message
 * and re-thrown, so that the global error service can handle them
 * appropriately.
 *
 * See @type {catchAndSurfaceModalFocusError}
 *
 * @param error - Either an error, a number, or the focus string.
 * @param translatedErrorMessage - The message for actual errors.
 */
export function catchAndSurfaceNonModalError<T>(
  translatedErrorMessage: string
): (error: unknown) => Observable<T> {
  return catchError((error) => {
    // wrap true errors in our DgError, for global error service handling
    if (isTrueModalError(error)) {
      return throwError(() => new DgError(translatedErrorMessage, error));
    }
    // any other "error" can be swallowed, just return EMPTY to complete the stream (sanity-check)
    return EMPTY;
  });
}

/**
 * Whether the 'error' is from dismissing the modal, which would be a number
 * (representing the type of dismissal, e.g. backdrop click (0) or ESC key press (1)).
 *
 * @param error - The error to check.
 */
export function isModalDismissError(error: Error | unknown): boolean {
  return !!(error && !isNaN(error as number));
}

/**
 * Whether the 'error' is just for focus handling. Check against this when
 * using errorOnDismiss.
 *
 * @param error - The error to check.
 */
export function isModalFocusError(error: Error | unknown): boolean {
  return error === MODAL_FOCUS_ERROR;
}

/**
 * Whether the 'error' is neither of the previous types. If true, the error
 * needs to be handled.
 *
 * @param error - The error to check.
 */
export function isTrueModalError(error: Error | unknown): boolean {
  return !!(error && !isModalDismissError(error) && !isModalFocusError(error));
}
