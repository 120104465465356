import { Injectable } from '@angular/core';
import {
  Breadcrumb,
  Breadcrumbs,
} from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import { DecodeHtmlPipe } from '@app/shared/pipes/decode-html.pipe';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
  constructor(private decodeHtml: DecodeHtmlPipe) {}

  public decodeBreadcrumbs(breadcrumbs: Breadcrumbs): Breadcrumbs {
    return breadcrumbs.map(this.getDecodedBreadcrumb);
  }

  private getDecodedBreadcrumb = (breadcrumb: Breadcrumb): Breadcrumb => {
    return {
      ...breadcrumb,
      label: this.decodeHtml.transform(breadcrumb.label),
      link: this.decodeHtml.transform(breadcrumb.link),
    };
  };
}
