import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { OrgTargetVM } from '@app/target/target-api.model';

/**
 * This component is used to generate a plan link, typically displayed within a DataTable column,
 * that directs the user to the correct location based on the context they're currently in
 */

@Component({
  selector: 'dgx-plan-link',
  templateUrl: './plan-link.component.html',
})
export class PlanLinkComponent {
  @Input() public plan: OrgTargetVM;
  @Input() public contextPath: string = '';
  @Input() public autoPopulate: boolean;

  public i18n = this.translate.instant(['OrgPlans_OnFeaturedTooltip']);

  constructor(
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public get editPath() {
    const route = this.plan.targetType === 'Directory' ? 'explore' : 'plan';

    return `/${route}/${this.plan.targetId}${
      this.contextPath === '' ? '?' : `${this.contextPath}&`
    }editMode=true&autoSuggest=${this.autoPopulate}`;
  }

  public trackPlanViewed() {
    this.tracker.trackEventData({
      action: 'Org Plan Viewed',
    });
  }
}
