import { Injectable } from '@angular/core';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import {
  AddToPathwayComponent,
  AddToPathwayOptions,
} from '../components/add-to-pathway/add-to-pathway.component';

@Injectable({
  providedIn: 'root',
})
export class AddToPathwayModalService {
  constructor(
    private modalService: ModalService,
    private focusStackService: FocusStackService
  ) {}

  /**
   * Used for openening the modal containing the Add To Pathway component
   * @param inputId Id number of Input being added to pathway
   * @param inputType Which kind of Input being added to pathway required by several api's used in the component
   */
  public showAddToPathwayForm = (
    inputId: number,
    inputType: string,
    organizationId?: number,
    isEndorsed?: boolean,
    returnFocusEl?: HTMLElement,
    inputName?: string
  ) => {
    const modalOptions = {
      inputs: {
        addToPathwayOptions: {
          inputId,
          inputType,
          organizationId,
          isEndorsed,
          inputName,
        } as AddToPathwayOptions,
      },
    };
    if (returnFocusEl) {
      this.focusStackService.push(returnFocusEl);
    }
    return this.modalService.show(AddToPathwayComponent, modalOptions);
  };
}
