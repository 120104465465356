<section class="box-shadow rounded--large guts-p-full-2">
  <dgx-tracking-links-table
    [disabled]="!canViewTrackableLinks"
    [inviteUrl]="baseTrackingUrl"
    [links]="links"
    [canManage]="canManageTargets"
    [isOrgContent]="isOrgContent"
    [currentSortBy]="currentSortBy"
    [ascend]="ascend"
    (onDelete)="deleteLink($event)"
    (onEdit)="editLink($event)"
    (onNew)="newLink()"
    (onSort)="sortLinks($event)"
  >
    <header>
      <h3 class="h3 table-title">{{ i18n.TrackableLinkCtrl_AutoEnroll }}</h3>
    </header>
  </dgx-tracking-links-table>
</section>
