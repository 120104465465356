import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, map } from 'rxjs';

// misc
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { notNullishOrEmptyString } from '@app/utils';
import { readFirst } from '@dg/shared-rxjs';
import {
  CourseFormDataModel,
  CourseModel,
  CourseTypeId,
} from '../../course.model';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { CourseTrackerService } from '@app/user-content/user-input-v2/inputs/course/services/course-tracker.service';
import { AuthService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { CourseBaseFacade } from '../course-base.facade.service';
import { CourseNotificationService } from '../course-notification.service';
import { CourseService } from '../course.service';
import { CourseMapper } from '../course-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class CourseGlobalAddFacade extends CourseBaseFacade {
  private i18n = this.translateService.instant([
    'CourseFormCtrl_FirstYearLevel',
    'CourseFormCtrl_SecondYearLevel',
    'CourseFormCtrl_ThirdYearLevel',
    'CourseFormCtrl_FourthYearLevel',
    'CourseFormCtrl_GraduateLevel',
  ]);

  constructor(
    protected inputsService: InputsService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService,
    protected courseService: CourseService,
    protected mapperService: CourseMapper,
    protected courseNotificationService: CourseNotificationService,
    private translateService: TranslateService,
    private courseTrackerService: CourseTrackerService,
    private tipService: TipService,
    authService: AuthService,
    datehandler: NgbDateParserFormatter
  ) {
    super(
      inputsService,
      inputImageUploadAdapterService,
      courseService,
      mapperService,
      courseNotificationService,
      authService,
      datehandler
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): CourseModel {
    return readFirst(this.viewModel$);
  }

  /**
   * Overwrite the on Next form the base facade for global add
   * When the next button has been selected
   * @param courseTypeId
   */
  public async onNext(courseTypeId: CourseTypeId): Promise<void> {
    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
      courseTypeId,
      isFormal: courseTypeId === CourseTypeId.Accredited,
      isAccredited: courseTypeId === CourseTypeId.Accredited,
    };
  }

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      await super.onSubmit(form, true);
      const apiParameters = this.mapperService.toApiParameters(this.viewModel);
      this.courseTrackerService.trackContentCompleted({
        ...apiParameters,
        institutionName: this.viewModel.institutionName,
      });
      await this.performSuccessSideEffects();
    } catch (error) {
      throw error;
    }
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public initializeViewModel(inputContext: InputContext): void {
    super.initializeViewModel(inputContext);

    const shouldShowResults$ = combineLatest([this.submissionStatus$]).pipe(
      map(([s]) => s >= SubmissionStatus.Submitting)
    );
    const isNewbUser$ = this.tipService.onboardHistory$.pipe(
      map((v) => {
        return v.indexOf('firstinput') === -1;
      })
    );

    // initialize new/computed Properties
    // TODO: on add update model
    this.viewModel = {
      ...this.viewModel,
      allCourseLevels: [
        { title: this.i18n.CourseFormCtrl_FirstYearLevel },
        { title: this.i18n.CourseFormCtrl_SecondYearLevel },
        { title: this.i18n.CourseFormCtrl_ThirdYearLevel },
        { title: this.i18n.CourseFormCtrl_FourthYearLevel },
        { title: this.i18n.CourseFormCtrl_GraduateLevel },
      ],
      allGrades: [
        { title: 'A' },
        { title: 'A-' },
        { title: 'B+' },
        { title: 'B' },
        { title: 'B-' },
        { title: 'C+' },
        { title: 'C' },
        { title: 'C-' },
        { title: 'D+' },
        { title: 'D' },
        { title: 'D-' },
      ],
      readonly: {
        courseUrl: false,
        description: false,
        courseNumber: false,
        creditHours: false,
      },
      addToCatalog: false, // always false
      isSubmitButtonDisabled: true, // true until a selection is made in the initial add modal
      isNewbUser$,
      shouldShowResults$,
    };
  }

  public onCountrySelection(country: string): void {
    this.viewModel = {
      ...this.viewModel,
      country,
    };
  }

  /**
   * Handler for removing focus from course name input. Updates the vm
   * where necessary.
   *
   * @param course - Either a string or an AutocompleteItem. Id matches our courseNumber.
   * @param form - Parent form.
   */
  public onCourseNameSet(course: string | AutocompleteItem, form: FormGroup) {
    // Informal courses should be handled by the base method, used by pathways/plans.
    if (!this.viewModel.isFormal) {
      return super.onCourseNameSet(course, form);
    }
    // WHEN the new value is not meaningfully different, bail.
    if (
      (!course && !this.viewModel.input?.courseId) ||
      (!!this.viewModel.input?.courseId &&
        (course as AutocompleteItem)?.id === this.viewModel.input.courseId)
    ) {
      return;
    }

    // OTHERWISE, update the vm.
    // AND WHEN the old name was NOT nullish, reset the course.
    if (notNullishOrEmptyString(this.viewModel.name)) {
      this.courseReset(form);
    }
    // EITHER WAY, set our new name. By this point, we can assume that it's a text
    // input, because if it were a real course, institutionId would've been set and
    // would have matched.
    this.viewModel.name = course as string;
  }

  public onCourseGradeChange(courseGrade): void {
    this.viewModel = {
      ...this.viewModel,
      extent: {
        ...this.viewModel.extent,
        courseGrade,
      },
    };
  }

  public onCourseLevelChange(courseLevel): void {
    this.viewModel = {
      ...this.viewModel,
      extent: {
        ...this.viewModel.extent,
        courseLevel,
      },
    };
  }

  /** Performs any side effects required following successful creation of an Input */
  protected async performSuccessSideEffects() {
    if (
      this.viewModel.submissionResult?.result &&
      this.viewModel.comment?.length
    ) {
      const params = {
        resourceId: this.viewModel.inputId,
        resourceType: 'Course',
        title: this.viewModel.name,
        comment: this.viewModel.comment,
      };
      await this.courseService.addGlobalCourseComment(params);
    }

    const apiParameters = this.mapperService.toApiParameters(
      this.viewModel as CourseModel
    );
    this.courseTrackerService.trackContentCompleted({
      ...apiParameters,
      institutionName: this.viewModel.institutionName,
    });
    // using this over the courseNotificationService as we already have multiple pages linked to the events from
    // the inputsService and userOutcomesService
    this.inputsService.notifyInputModified('Course');
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: CourseFormDataModel) {
    this.viewModel = {
      ...this.viewModel,
      courseNumber: formData.courseNumber,
      courseUrl: formData.courseUrl,
      comment: formData.comment,
      description: formData.description,
      durationMinutes: formData.durationForm?.durationMinutes,
      durationHours: formData.durationForm?.durationHours,
      imageUrl: formData.image,
      tags: formData.tags?.length
        ? formData.tags
        : formData.skills?.length
          ? formData.skills
          : [],
      extent: {
        ...this.viewModel.extent,
        dateCompleted: formData.dateCompleted,
        verificationUrl: formData.verificationUrl,
      },
    };
  }
}
