import { Injectable } from '@angular/core';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import {
  ActionFeedback,
  InputCreationResult,
  UserPosition,
} from '@app/inputs/inputs-api.model';
import { DgError } from '@app/shared/models/dg-error';
import {
  ExperienceMappingToAPI,
  InferredSkillsModel,
} from '../experience.model';

@Injectable()
export class ExperienceService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_EditContentDuplicateError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public addUserPosition(
    params: ExperienceMappingToAPI
  ): Promise<ActionFeedback<InputCreationResult, void>> {
    const request$ = this.post<ActionFeedback<InputCreationResult, void>>(
      '/userinputs/adduserposition',
      {
        ...params,
      }
    ).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public updateUserPosition(
    params: ExperienceMappingToAPI
  ): Promise<ActionFeedback<InputCreationResult, void>> {
    const request$ = this.put<ActionFeedback<InputCreationResult, void>>(
      '/userinputs/updateuserposition',
      {
        ...params,
      }
    ).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public getInferredSkills(
    title: string,
    description: string
  ): Promise<InferredSkillsModel> {
    const request$ = this.get<InferredSkillsModel>(
      '/tag/InferredSkillsByMetadata',
      {
        title,
        description,
        contentType: 'experience',
      }
    ).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_GeneralError, e);
      })
    );
    return lastValueFrom(request$);
  }

  /**
   * Get the full details for an experience.
   *
   * @param userPositionId - The id for the experience to retrieve
   */
  public getExperienceDetails(userPositionId: number): Promise<UserPosition> {
    const request$ = this.get<UserPosition>('/userinputs/getuserposition', {
      userPositionId,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_GeneralError, e);
      })
    );
    return lastValueFrom(request$);
  }
}
