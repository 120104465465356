import { A11yService } from '@app/shared/services/a11y.service';
import { Component, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { timer } from 'rxjs';

@Component({
  selector: 'dgx-copy-link',
  templateUrl: './copy-link.component.html',
})
export class CopyLinkComponent implements OnInit {
  @Input() public url: string;
  @Input() public trackingLabel: string;
  @Input() public trackingCategory: string;
  @Input() public inputLabel: string;
  @Input() public buttonLabel: string;

  public tooltipText: string;
  public openTooltipDelay = 200;

  constructor(
    private translate: TranslateService,
    private clipboardService: ClipboardService,
    private cdr: ChangeDetectorRef,
    private trackerService: TrackerService,
    private a11yService: A11yService
  ) {}

  public get canCopyToClipboard() {
    return this.clipboardService.canCopyToClipboard;
  }

  public ngOnInit(): void {
    if (!this.buttonLabel) {
      this.buttonLabel = this.translate.instant('dgCopyLink_CopyLink');
    }
    if (!this.inputLabel) {
      this.inputLabel = this.translate.instant('dgCopyLink_InviteURL');
    }
  }

  public copy(tooltip: NgbTooltip, textToCopy: string) {
    this.clipboardService.copyToClipboard(textToCopy).subscribe({
      next: (clipboardMessage: string) => {
        this.tooltipText = clipboardMessage;
        this.cdr.detectChanges();
        if (tooltip.isOpen) {
          tooltip.close();
        }
        timer(this.openTooltipDelay).subscribe(() => tooltip.open());
        this.a11yService.announcePolite(clipboardMessage);
        if (this.trackingLabel) {
          this.trackerService.trackEventData({
            action: this.trackingLabel || null,
            category: this.trackingCategory || null,
            label: 'Link Copied',
          });
        }
      },
    });
  }
}
