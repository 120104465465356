<div>
  <button
    df-button
    dfButtonType="clear"
    size="small"
    data-dgat="zoom-controls-98a"
    (click)="onZoomIn.emit()"
    [disabled]="currentScale >= maxScale"
  >
    <df-icon icon="plus" [a11yText]="'Core_ZoomIn' | translate"></df-icon>
  </button>
  <span aria-live="polite" class="guts-p-full-1-half"
    >{{ getScale(currentScale) }}%</span
  >
  <button
    df-button
    dfButtonType="clear"
    size="small"
    data-dgat="zoom-controls-765"
    (click)="onZoomOut.emit()"
    [disabled]="currentScale <= minScale"
  >
    <df-icon icon="minus" [a11yText]="'Core_ZoomOut' | translate"></df-icon>
  </button>
</div>
