import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';

// misc
import { PrettyTimeElapsedPipe } from '@app/shared/pipes/pretty-time-elapsed.pipe';

@Component({
  selector: 'dgx-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements OnInit {
  // Bindings - Input
  @Input() public allowCollaboratorsEditing? = false;
  @Input() public allowPrivacyLevelEditing? = false;
  /** Whether the top portion of the header should stick when the user scrolls down. */
  @Input() public canStick = false;
  @Input() public closeDate?: string;
  @Input() public collaborators: UserSearchItem[];
  /** Defaults to *translated* `Core_Collaborators`. */
  @Input() public collaboratorsText: string;
  /** Defaults to *translated* `TargetAuthorsForm_Title`. */
  @Input() public collaboratorTooltip: string;
  @Input() public department?: string;
  @Input() public hideCollaboratorsHeader? = false;
  /**
   * Used to *override* content that would otherwise be considered opened,
   * due to lack of a closed date.
   */
  @Input() public isClosed? = false;
  @Input() public isEditing? = false;
  @Input() public isNewHeaderDesign?: boolean = false;
  @Input() public pageTitle: string;
  @Input() public statusPillText: string;
  @Input() public statusPillClass: string;
  @Input() public publishDate?: string;
  @Input() public pageDescription: string;
  @Input() public privacyLevelHeader: string;
  @Input() public pageType: string;
  @Input() public userStage? = '';
  @Input() public usePageHeaderLink? = false;
  @Input() public pageHeaderTitleTooltip? = '';
  /** Pass in a custom template to replace the pageTitle   */
  @Input() public useCustomPageHeaderTemplate = false;
  // Bindings - Output
  // TODO: determine type
  @Output() public editCollaborators?: EventEmitter<any> = new EventEmitter();
  @Output() public editPrivacyLevel?: EventEmitter<any> = new EventEmitter();
  @Output() public pageHeaderCallback?: EventEmitter<any> = new EventEmitter();

  // Local
  public i18n = this.translateService.instant(['Core_Closed']);
  public isHeaderStuck = false;
  public showCollaborators = false;
  public pageTitleType: string = '';

  constructor(
    private datePipe: DatePipe,
    private prettyTimeElapsedPipe: PrettyTimeElapsedPipe,
    private translateService: TranslateService
  ) {}

  // Angular events
  public ngOnInit(): void {
    // these properties may be passed in as undefined rather than left off
    this.hideCollaboratorsHeader = this.hideCollaboratorsHeader ?? false;
    this.collaboratorsText =
      this.collaboratorsText ??
      this.translateService.instant('Core_Collaborators');
    this.collaboratorTooltip =
      this.collaboratorTooltip ??
      this.translateService.instant('TargetAuthorsForm_Title');
    this.allowPrivacyLevelEditing = this.editPrivacyLevel.observers.length > 0;
    this.getPageTitleType();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEditing) {
      this.showCollaborators =
        !this.hideCollaboratorsHeader &&
        (this.collaborators?.length > 0 || this.isEditing);
      this.allowCollaboratorsEditing =
        this.showCollaborators && this.editCollaborators.observers.length > 0;
    }
  }

  // Public events
  /**
   * Handles page handler callback
   */
  public pageHeaderCallbackHandler() {
    this.pageHeaderCallback.emit();
  }

  public getPageTitleType() {
    if (this.useCustomPageHeaderTemplate) {
      this.pageTitleType = 'template';
    } else if (this.usePageHeaderLink) {
      this.pageTitleType = 'usePageHeaderLink';
    } else {
      this.pageTitleType = 'default';
    }
  }

  /**
   * Displays formatted closed date if one exists, but falls back
   * to just displaying 'Closed' otherwise. Display this fallback
   * by passing `[isClosed]="true"` to the component.
   *
   * @param closeDate - Date in UTC format.
   */
  public getClosedDate(closeDate: string) {
    // No closed date, but content is still closed!
    if (!closeDate) {
      return this.i18n.Core_Closed;
    }
    // Otherwise, pass date to `getFormattedDate`.
    return this.getFormattedDate('Core_ClosedOnDate', closeDate);
  }

  /**
   * Formatted with a given i18n string.
   *
   * @param i18n - The translation string to use.
   * @param date - Date in UTC format.
   */
  public getFormattedDate(i18n: string, date: string): string {
    return this.translateService.instant(i18n, {
      date: this.datePipe.transform(date, 'shortDate'),
    });
  }

  /**
   * Prettified date, formatted with a given i18n string.
   *
   * @param i18n - The translation string to use.
   * @param date - Date in UTC format.
   */
  public getPrettyFormattedDate(i18n: string, date: string): string {
    return this.translateService.instant(i18n, {
      elapsedTime: this.prettyTimeElapsedPipe.transform(date, 'minutes'),
    });
  }

  // TODO: once UsersPicturesComponent has been updated to *not*
  // use `on`-prefixed @Outputs, rename this method.
  public handleEditCollaborators($event): void {
    this.editCollaborators.emit($event);
  }

  public onEditPrivacyLevel($event): void {
    this.editPrivacyLevel.emit($event);
  }

  /**
   * Fires when the header sticks or unsticks.
   *
   * @param isHeaderStuck - The value of 'isStuck' from dgxSticky.
   */
  public onStickyChange(isHeaderStuck: boolean): void {
    this.isHeaderStuck = isHeaderStuck;
  }
}
