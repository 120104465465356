import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { TranslateService } from '@ngx-translate/core';
import {
  getItemTrackingKey,
  keydownHandler,
} from '../search-initiation-autocomplete.helpers';

@Component({
  selector: 'dgx-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAutocompleteComponent {
  @Input() public suggestions: ParentItemViewModel<SimpleItemViewModel<any>>[];
  @Input() public termSuggestionsOnly: boolean = false;

  @Output() public autosuggestSelect = new EventEmitter<SimpleItemViewModel>();
  @Output() public typeSelect = new EventEmitter<SimpleItemViewModel>();
  @Output() public suggestionSelect = new EventEmitter<SimpleItemViewModel>();

  public i18n = this.translateService.instant(['Core_Skills']);
  public keydownHandler = keydownHandler;
  public getItemTrackingKey = getItemTrackingKey;

  constructor(private translateService: TranslateService) {}

  public limit(isAutosuggest: boolean): number {
    return isAutosuggest ? 3 : 4;
  }

  public describer(suggestionVm: SimpleItemViewModel): string {
    return suggestionVm.typeTitleTranslation
      ? ` • ${this.translateService.instant(suggestionVm.typeTitleTranslation)}`
      : '';
  }

  public onSuggestionClick(
    suggestionVm: SimpleItemViewModel,
    sectionTitle: string
  ): void {
    sectionTitle === 'Autosuggest'
      ? this.autosuggestSelect.emit(suggestionVm)
      : this.suggestionSelect.emit(suggestionVm);
  }

  public onTypeClick(typeVm: SimpleItemViewModel): void {
    this.typeSelect.emit(typeVm);
  }

  public shouldShowType(vm: ParentItemViewModel): boolean {
    return (
      vm.subitems.length > 0 &&
      (!this.termSuggestionsOnly || vm.title === 'Autosuggest')
    );
  }
}
