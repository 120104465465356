import { InternalTagRatingTypes } from '@app/tags/tags';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { TagsService } from '@app/tags/services/tags.service';
import { UserService } from '@app/user/services/user.service';
import { UserProfile, UserSearchItem } from '@app/user/user-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';

@Component({
  selector: 'dgx-tag-manager-rating-request-modal',
  templateUrl: './tag-manager-rating-request-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagManagerRatingRequestModalComponent {
  @Input() public tagId: number;
  @Input() public rater: UserProfile;

  public potentialRaters = [];
  public submitting = false;

  public i18n = this.translate.instant([
    'RequestRating_Header',
    'RequestRating_Label',
    'RequestRating_SendRequest',
    'Core_SearchForPerson',
    'dgTagRating_SelectManager',
    'TargetResourcesForm_Selected',
  ]);

  constructor(
    private translate: TranslateService,
    private tagsService: TagsService,
    private userService: UserService,
    private activeModal: NgbActiveModal
  ) {}

  public dismiss(): void {
    this.activeModal.dismiss();
  }

  /**
   * TODO: Check @type {UserSearchComponent} and @type {UserService}. Now that
   * the options we're passing to findNetworkMembers are available on the component,
   * can we just use the default search?
   * Key difference: the UserService maps its @type {UserProfileSummaries} to
   * simpler @type {UserSearchItems}. Also note that excludeSelf is false by default
   * on UserSearchComponent, but true by default on findNetWorkMembers.
   */
  public loadPotentialRaters: TypeaheadSearchFunction<string, UserSearchItem> =
    (term: Observable<string>): Observable<readonly UserSearchItem[]> => {
      return term.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((term) => term.length >= 2),
        switchMap((term: string) => {
          return this.userService.findNetworkMembers(term, true, 20);
        }),
        catchError(() => {
          // for now, swallow and return no results
          return of([] as UserSearchItem[]);
        })
      );
    };

  public onSelect = (item: AnyRecommendee) => {
    this.rater = item as UserProfile;
  };

  public onRemove = () => {
    this.rater = null;
  };

  public submit = () => {
    if (!this.rater) {
      return;
    }

    if (this.submitting) {
      return;
    }

    this.submitting = true;

    return this.requestRating();
  };

  public requestRating = () => {
    return this.tagsService
      .requestTagRating(
        this.tagId,
        InternalTagRatingTypes.manager,
        this.rater.userProfileKey
      )
      .subscribe(
        (response) => {
          this.activeModal.close({ ...this.rater, ...response });
          // refresh the list view
          this.tagsService.notifyUserTagsModified();
        },
        () => {
          this.submitting = false;
        }
      );
  };
}
