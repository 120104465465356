import { Pipe, PipeTransform } from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';

/**
 * Transform all of object's keys to camel case. Useful for interop with AngularJS
 */
@Pipe({ name: 'camelCase' })
export class CamelCasePipe implements PipeTransform {
  public transform(obj: any) {
    if (!obj) {
      return;
    }
    return camelCaseKeys(obj);
  }
}
