import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a single integration skill assessment has been added to a skill on the user's profile */
@Component({
  selector: 'dgx-single-assessment-found-review-skill',
  template: ` <span [innerHTML]="safeContent"></span> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleAssessmentFoundReviewSkillComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.itemService.translate.instant(
      'Notification_SingleAssessmentFoundReviewSkill',
      {
        skillName: this.params.skillRating.tagName,
        providerName: this.params.providerName,
        ratingLevel: this.params.skillRating.ratingLevel,
      }
    );
  }
}
