<div class="center-text guts-p-t-4">
  <img [src]="headerImageUrl" [ngClass]="headerImageClasses" />
  <h3
    *ngIf="headerText"
    class="font-semibold guts-p-t-2 guts-p-b-{{ descriptionText ? '1' : '2' }}"
    [ngClass]="headerTextClasses"
    [innerHTML]="headerText"
  ></h3>
  <p
    *ngIf="descriptionText"
    class="color-ebony-a61 guts-p-b-2 par par--small"
    [ngClass]="descriptionTextClasses"
    [class.guts-p-t-2]="!headerText"
    [innerHTML]="descriptionText"
  ></p>
  <ng-container *ngIf="primaryNavigationText">
    <div class="l_flexbar l_flex-justify-center gap-full-half">
      <div *ngIf="secondaryNavigationText">
        <a
          *ngIf="secondaryNavigationUrl !== '#'; else secondaryNavigateButton"
          df-button
          [dfButtonType]="secondaryNavigationType"
          [href]="secondaryNavigationUrl"
          [attr.data-dgat]="secondaryNavigationDgat"
          [attr.target]="secondaryNavigationIsExternal ? '_blank' : '_self'"
          (click)="onSecondaryClick($event)"
          class="guts-m-r-half"
        >
          <div class="l_flexbar gap-full-half">
            <div>{{ secondaryNavigationText }}</div>
            <df-icon
              *ngIf="secondaryNavigationIsExternal"
              [icon]="externalIcon"
              size="small"
            ></df-icon>
          </div>
        </a>
      </div>
      <div>
        <a
          *ngIf="primaryNavigationUrl !== '#'; else primaryNavigateButton"
          df-button
          [dfButtonType]="primaryNavigationType"
          [href]="primaryNavigationUrl"
          [attr.data-dgat]="primaryNavigationDgat"
          [attr.target]="primaryNavigationIsExternal ? '_blank' : '_self'"
          (click)="onPrimaryClick($event)"
        >
          <div class="l_flexbar">
            <div class="l_flexbar gap-full-half">
              <div>{{ primaryNavigationText }}</div>
              <df-icon
                *ngIf="primaryNavigationIsExternal"
                [icon]="externalIcon"
                size="small"
              ></df-icon>
            </div>
          </div>
        </a>
      </div>
    </div>
    <ng-template #secondaryNavigateButton>
      <button
        type="button"
        df-button
        [dfButtonType]="secondaryNavigationType"
        [attr.data-dgat]="secondaryNavigationDgat"
        (click)="onSecondaryClick($event)"
        [class.is_disabled]="isNavigationPending"
        [attr.aria-disabled]="isNavigationPending"
        [attr.aria-label]="
          isNavigationPending
            ? ('Core_Loading' | translate)
            : secondaryNavigationText
        "
      >
        <df-spinner-button [isSpinning]="isNavigationPending">
          <div class="l_flexbar gap-full-half">
            <div>{{ secondaryNavigationText }}</div>
            <df-icon
              *ngIf="secondaryNavigationIsExternal"
              [icon]="externalIcon"
              size="small"
            ></df-icon>
          </div>
        </df-spinner-button>
      </button>
    </ng-template>
    <ng-template #primaryNavigateButton>
      <button
        type="button"
        df-button
        [dfButtonType]="primaryNavigationType"
        [attr.data-dgat]="primaryNavigationDgat"
        (click)="onPrimaryClick($event)"
        [class.is_disabled]="isNavigationPending"
        [attr.aria-disabled]="isNavigationPending"
        [attr.aria-label]="
          isNavigationPending
            ? ('Core_Loading' | translate)
            : primaryNavigationText
        "
      >
        <df-spinner-button [isSpinning]="isNavigationPending">
          <div class="l_flexbar gap-full-half">
            <div>{{ primaryNavigationText }}</div>
            <df-icon
              *ngIf="primaryNavigationIsExternal"
              [icon]="externalIcon"
              size="small"
            ></df-icon>
          </div>
        </df-spinner-button>
      </button>
    </ng-template>
  </ng-container>
</div>
