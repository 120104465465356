import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-recommendation-tile',
  templateUrl: './tag-recommendation-tile.component.html',
  styleUrls: ['./tag-recommendation-tile.component.scss'],
  host: { class: 'tile__wrapper' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRecommendationTileComponent {
  @Input() public tag: TagsApi.TagDetails;
  @Input() public initialAction: string;
  @Input() public recommendationInfo: RecommendationInfo;
  @Input() public actionsContext?: any;
  @Input() public assignmentDueDate?: string;
  @Input() public menuConfig?: MenuViewModel[];

  @Output() public dismiss: EventEmitter<void> = new EventEmitter();

  public i18n = this.translateService.instant([
    'dgTagRating_MeasureYourSkill',
    'dgTagRating_AddSelfRatingDetail',
    'dgTagRating_GetCertificate',
    'dgTagRating_MyRatings',
    'dgTagRating_AddEvaluation',
    'dgTagRating_ContinueEvaluation',
    'dgTagRating_Evaluation',
    'dgTagRating_StartEvaluation',
    'dgTagRating_SelfAssessment',
    'dgTagRating_AddSelfRating',
    'dgTagRating_StartCredential',
    'dgTagRating_InvalidRequest',
    'Core_Remove',
    'Core_Completed',
    'Core_Continue',
    'Core_Pending',
  ]);

  public degreedImageSrc = `/content/img/app/tag-ratings/small/degreed.svg`;

  constructor(
    private translateService: TranslateService,
    private tagRatingService: TagRatingService,
    private tagActionOptionsService: TagActionOptionsService,
    private notifierService: NotifierService,
    private cdr: ChangeDetectorRef
  ) {}

  public get assignmentInfo(): RecommendationInfo {
    return {
      dateDue: this.assignmentDueDate,
    } as RecommendationInfo;
  }

  public get buttonLabel(): string {
    if (this.isCompleted) {
      switch (this.initialAction) {
        case InternalTagRatingTypes.credential:
          return this.i18n.Core_Completed;
        case InternalTagRatingTypes.evaluation:
          return this.i18n.dgTagRating_Evaluation;
        case InternalTagRatingTypes.self:
          return this.i18n.dgTagRating_SelfAssessment;
      }
    }

    switch (this.initialAction) {
      case InternalTagRatingTypes.credential:
        return this.isInProgress
          ? this.i18n.Core_Pending
          : this.i18n.dgTagRating_StartCredential;
      case InternalTagRatingTypes.evaluation:
        return this.isInProgress
          ? this.i18n.dgTagRating_ContinueEvaluation
          : this.i18n.dgTagRating_AddEvaluation;
      case InternalTagRatingTypes.self:
        return this.i18n.dgTagRating_AddSelfRatingDetail;

      default:
        return '';
    }
  }

  public get tooltip(): string {
    return this.buttonLabel.length > 19 ? this.buttonLabel : undefined;
  }

  public get recommendationDescription(): string {
    switch (this.initialAction) {
      case InternalTagRatingTypes.credential:
        return this.i18n.dgTagRating_GetCertificate;
      case InternalTagRatingTypes.evaluation:
        return this.i18n.dgTagRating_MeasureYourSkill;
      case InternalTagRatingTypes.self:
        return this.i18n.dgTagRating_AddSelfRatingDetail;
      default:
        return this.initialAction;
    }
  }

  public get recommendationDisplayType(): string {
    return `${this.tag.resourceType}${
      this.initialAction ? this.initialAction : ''
    }`;
  }

  public get showDismiss(): boolean {
    return (
      this.recommendationInfo?.recommendationType !==
        UserRecommendationType.RequiredLearning && !this.isCompleted
    );
  }

  public get isCompleted(): boolean {
    return !!this.tag.ratings.find(
      (rating) => rating.type === this.initialAction && rating.dateCompleted
    );
  }

  public get isInProgress(): boolean {
    return !!this.tag.ratings.find(
      (rating) =>
        rating.type === this.initialAction &&
        // skill coach managers self rating recommendations should not be "in-progress"
        this.initialAction !== InternalTagRatingTypes.self &&
        !rating.dateCompleted
    );
  }

  // Main action button will be truncated after 19 characters so show tooltip
  public get showTooltip(): boolean {
    return this.buttonLabel.length > 19;
  }

  public get getLevel(): string {
    const rating = this.tag.ratings?.find(
      (rating) => rating.type === this.initialAction
    );
    return rating?.level;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tag) {
      this.menuConfig ??= [
        {
          title: this.i18n.dgTagRating_MyRatings,
          isHidden: () => false,
          defaultAction: (event) => {
            this.tagRatingService.openSkillModal(event, this.tag, true);
          },
        },
        {
          title: this.i18n.Core_Remove,
          defaultAction: () => {
            this.dismiss.emit();
          },
        },
      ];
    }
  }

  public doAction(event: MouseEvent) {
    switch (this.initialAction) {
      case InternalTagRatingTypes.credential:
        // Skill Certifications are no longer allowed, however we may need to handle previously recommended Skill Certs
        return this.notifierService.showError(
          this.translateService.instant('dgTagRating_InvalidRequest')
        );
      case InternalTagRatingTypes.evaluation:
        this.tagActionOptionsService.startSkillReviewAction(
          this.tag,
          event.target as HTMLElement
        );
        break;
      case InternalTagRatingTypes.self:
        this.tagRatingService
          .openSelfRatingModal(event, {
            ...this.tag,
            rating: ({
              level: this.getLevel,
            } as unknown) as TagsApi.UserTagRating,
          })
          .subscribe((rating: Partial<TagsApi.UserTagRatingDetails>) => {
            const _rating = {
              ...rating,
              type: InternalTagRatingTypes.self,
              dateCompleted: new Date().toDateString(),
            } as TagsApi.UserTagRatingDetails;
            // make sure the rating modal will reflect the new value if launched again
            this.tag.rating = _rating;
            // make sure the button state is updated
            this.tag.ratings.push(_rating);
            this.cdr.markForCheck();
          });
        break;
    }
  }
}
