/**
 * Return an array of items, filtered by a given searchKey/Term pair. **Does not
 * mutate the original array.**
 *
 * @param items - The items to sort. Of type T.
 * @param searchKey - The property in the array to search.
 * @param searchTerm - The term to search the property for.
 *
 * @example
 * ```
 * users = searchAndOrderBy<User>(users, 'user', 'fred', ['user', 'age'], 'desc')
 * // [{ user: 'fred', age: 48 }, { user: 'fred', age: 40 }]
 * ```
 */
export function search<T>(
  items: T[],
  searchKey: string,
  searchTerm: number | string = ''
): T[] {
  return items.filter((item: T) =>
    searchItemForKey<T>(item, searchKey, searchTerm)
  );
}

/**
 * Test whether a given item's given property matches the value of a given search term.
 * Safe for use with either string or number search terms.
 *
 * @param item - The current item to check.
 * @param searchKey - The property of the item to check.
 * @param searchTerm - The string or number to search the property for.
 */
function searchItemForKey<T>(
  item: T,
  searchKey: string,
  searchTerm: string | number
): boolean {
  // determine whether the current item even has the search key property
  const searchKeyExists = item?.hasOwnProperty(searchKey);
  // for strings
  if (typeof searchTerm === 'string') {
    return searchKeyExists
      ? item[searchKey].toLowerCase().includes(searchTerm.toLowerCase())
      : false;
  }
  // for numbers
  if (!isNaN(searchTerm)) {
    return searchKeyExists
      ? item[searchKey].toString().includes(searchTerm.toString())
      : false;
  }
  // sanity check!
  return false;
}
