<article class="search-result-card--result">
  <div class="guts-p-h-1 guts-p-v-1 rel">
    <!-- Meta data -->
    <div
      *ngIf="!isPreview"
      class="l_flex l_flex-middle guts-p-b-1 search-result-card__meta-data"
    >
      <span
        *ngIf="endorsedImageUrl"
        [ngbTooltip]="i18n.Core_EndorsedTooltip"
        class="search-result-card__endorsed l_flex guts-m-r-1-quart"
      >
        <img [src]="endorsedImageUrl" [alt]="i18n.Core_Endorsed" />
      </span>
      <dgx-learning-resource-metadata
        *ngIf="!resource?.liveSessions"
        [metaData]="resource.metaData"
        [searchTerm]="searchTerm"
        [isOptional]="resource.pathwayStepDetails?.isOptional"
        [config]="{
          percentComplete: resource.percentComplete,
          compact: false,
          isAcademy:
            !!resource?.model?.marketplaceInputId || resource.isAcademy,
          isv2Academy: resource.isv2Academy,
        }"
        [isVerified]="resource.isVerified"
        [disableProviderLink]="true"
        [isCompleted]="resource.completionInfo?.isCompleted"
      ></dgx-learning-resource-metadata>
      <dgx-live-event-metadata
        *ngIf="resource?.liveSessions"
        [liveSessions]="resource?.liveSessions"
        [isRegistered]="resource?.isRegistered"
        [isOptional]="resource.pathwayStepDetails?.isOptional"
        class="guts-m-b-quart"
      ></dgx-live-event-metadata>
    </div>
    <div class="l_flexbar l_flex-middle">
      <!-- Image -->
      <div class="search-result-card__img m-guts-m-b-2 guts-m-r-1">
        <dgx-image-layout
          class="cursor-pointer"
          format="resource-card"
          (click)="onImageClick($event)"
          (brokenImage)="onBrokenImage($event)"
          [imageSrc]="resource.imageUrl"
          [resourceType]="resource.resourceType"
          [videoUrl]="resource.videoInfo?.contentUrl"
          [resourceId]="resource.resourceId"
          [providerImage]="resource.providerSummary?.image"
          [providerLogo]="resource.providerSummary?.logo"
          [hideFallback]="true"
        ></dgx-image-layout>
      </div>
      <div class="l_flex-grow m-no-guts">
        <!-- Clickable Title -->
        <h3
          *ngIf="resource.title && !isPreview"
          class="h3 guts-p-b-half l_flex"
        >
          <a
            #title
            *ngIf="url"
            class="search-result-card__title"
            [ngClass]="{
              'search-result-card__title--expanded': (expanded$ | async)?.title,
            }"
            [href]="titleHref"
            (click)="onTitleClick($event)"
            [attr.aria-describedby]="
              shouldOpenNewWindow ? 'a11yNewWindowDescription' : null
            "
            [attr.role]="shouldOpenContentInModal ? 'button' : null"
            [attr.title]="resource.title"
            [attr.target]="shouldOpenNewWindow ? '_blank' : null"
            [attr.rel]="shouldOpenNewWindow ? 'noopener nofollow' : null"
            data-dgat="search-result-card-ac2"
          >
            {{ resource.title | decodeHtml }}
          </a>
          <div class="search-result-card__cost-container">
            <span *ngIf="isMarketplaceProgram" class="search-result-card__cost">
              {{ resource.metaData.cost }}
            </span>
          </div>
          <span
            #title
            *ngIf="!url"
            class="search-result-card__title search-result-card__title--not-clickable"
            [ngClass]="{
              'search-result-card__title--expanded': (expanded$ | async)?.title,
            }"
            >{{ resource.title }}</span
          >
          <button
            *ngIf="showTitleEllipses$ | async"
            [attr.title]="i18n.A11y_ExpandForTitle"
            [attr.aria-label]="i18n.A11y_ExpandForTitle"
            (click)="expandCard(titleExpanded)"
            data-dgat="search-result-card-8b1"
          >
            ...
          </button>
        </h3>

        <!-- Preview Title-->
        <h3 *ngIf="resource.title && isPreview" class="h3 guts-p-b-half l_flex">
          <span
            class="search-result-card__title search-result-card__title--expanded"
          >
            {{ resource.title }}
          </span>
        </h3>

        <!-- Summary -->
        <div class="l_flex">
          <p
            #summaryRef
            class="font-medium par par--light_a par--small search-result-card__summary"
            [ngClass]="{
              'search-result-card__summary--expanded': (expanded$ | async)
                ?.summary,
            }"
            [attr.title]="summary"
            [innerHTML]="summary"
          ></p>
          <button
            *ngIf="showSummaryEllipses$ | async"
            [attr.title]="i18n.A11y_ExpandForSummary"
            [attr.aria-label]="i18n.A11y_ExpandForSummary"
            class="l_flex-bottom search-result-card__expand-button"
            (click)="expandCard(summaryExpanded)"
            data-dgat="search-result-card-6e1"
          >
            ...
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <footer class="guts-p-h-1 guts-p-b-1" *ngIf="!isPreview">
    <!--
      A11y Note: Using fieldset and legend here is a way to group these buttons
      (fieldset is semantic for role="group"). Screen readers read the <legend>
      text with every field (button in this case) inside the <fieldset>.
      Most screen readers read the <legend> first. VoiceOver reads the label
      of the form field first, then the <legend>, which sound backwards,
      but that's out of the developer's control, and regular VoiceOver
      users will be used to hearing things that way.
    -->
    <fieldset>
      <legend class="a11y-hide">{{ resource.title | decodeHtml }}</legend>
      <div class="l_flexbar search-result-card__footer">
        <div class="l_flex-grow l_flexbar l_flex-wrap guts-m-r-1">
          <!--View Academy button -->
          <dgx-view-academy-button
            *ngIf="resource.isAcademy"
            [url]="resource.url"
            [isSearchResultCard]="true"
            class="guts-m-r-half"
          ></dgx-view-academy-button>

          <!-- Academy v2 buttons Complete and Joined -->
          <button
            *ngIf="resource.isv2Academy && isCompleted"
            df-button
            df-button-square
            dfButtonType="passive"
            [ngbTooltip]="i18n.Core_Completed"
            disabled
            [attr.aria-disabled]="true"
            class="search-result-card__button guts-m-r-half"
            data-dgat="search-result-card-d81"
          >
            <df-icon icon="checkmark" size="small"></df-icon>
          </button>
          <button
            *ngIf="showJoinedButton"
            df-button
            df-button-small
            dfButtonType="primary"
            disabled
            [attr.aria-disabled]="true"
            class="search-result-card__button guts-m-r-half"
            data-dgat="search-result-card-d82"
          >
            <div class="l_flexbar">
              <df-icon
                icon="checkmark"
                class="guts-m-r-half"
                size="small"
              ></df-icon>
              <div>{{ i18n.Core_Joined }}</div>
            </div>
          </button>

          <!-- Live Event button -->
          <button
            *ngIf="resource.isLive"
            df-button
            df-button-small
            dfButtonType="primary"
            class="search-result-card__button guts-m-r-half"
            (click)="openLiveSession()"
            data-dgat="search-result-card-d26"
          >
            <div class="l_flexbar">
              <df-icon
                icon="video-camera"
                class="guts-m-r-half"
                size="small"
              ></df-icon>
              <div>{{ i18n.Core_Join }}</div>
            </div>
          </button>

          <!--View Details button-->
          <button
            *ngIf="showMoreDetailsButton"
            df-button
            df-button-small
            dfButtonType="passive"
            class="search-result-card__button guts-m-r-half"
            (click)="openDetails()"
            data-dgat="search-result-card-5db"
          >
            <div>{{ i18n.Core_ViewDetails }}</div>
          </button>

          <!-- Pathway Follow button-->
          <button
            *ngIf="showPathwayFollowButton"
            df-button
            df-button-small
            [dfButtonType]="isEnrolled ? 'primary' : 'passive'"
            class="search-result-card__button guts-m-r-half"
            (click)="onEnrollClick($event)"
            data-dgat="search-result-card-a58"
          >
            <div class="l_flexbar">
              <df-icon
                [icon]="isEnrolled ? 'checkmark' : 'plus'"
                class="guts-m-r-half"
                size="small"
              ></df-icon>
              <div>
                {{ isEnrolled ? i18n.Core_Following : i18n.Core_Follow }}
              </div>
            </div>
          </button>

          <!-- Target (skill, role, directory, browse) Follow button-->
          <button
            *ngIf="showTargetFollowButton"
            df-button
            df-button-small
            [dfButtonType]="isFollowing ? 'primary' : 'passive'"
            class="search-result-card__button guts-m-r-half"
            (click)="onFollowClick()"
            data-dgat="search-result-card-9c5"
          >
            <div class="l_flexbar">
              <df-icon
                [icon]="isFollowing ? 'checkmark' : 'plus'"
                class="guts-m-r-half"
                size="small"
              ></df-icon>
              <div>
                {{ isFollowing ? i18n.Core_Following : i18n.Core_Follow }}
              </div>
            </div>
          </button>

          <!--Save For Later button-->
          <button
            *ngIf="showSaveForLaterButton"
            df-button
            df-button-square
            [dfButtonType]="resource.isQueued ? 'primary' : 'passive'"
            [ngbTooltip]="
              resource.isQueued ? i18n.Core_Unsave : i18n.Core_SaveForLater
            "
            [attr.aria-label]="
              resource.isQueued ? i18n.Core_Unsave : i18n.Core_SaveForLater
            "
            class="search-result-card__button guts-m-r-half"
            (click)="onSaveForLaterClick()"
            data-dgat="search-result-card-c39"
          >
            <df-icon
              icon="bookmark"
              size="small"
              [a11yText]="i18n.Core_SaveForLater"
            ></df-icon>
          </button>

          <!--Completion button-->
          <dgx-input-completion
            *ngIf="showCompleteButton"
            class="guts-m-r-half"
            [tooltip]="i18n.Ext_MenuMarkComplete"
            [isSearchResultCard]="true"
            [isCompleted]="isCompleted"
            [input]="resource"
            [class.guts-m-r-0]="showMoreDetailsButton"
          ></dgx-input-completion>
        </div>

        <!-- Action menu -->
        <div>
          <dgx-menu
            [menuConfig]="menuConfig"
            placement="bottom-right"
            [placementAdjustLeftRem]="1"
          >
            <button
              df-button
              df-button-square
              dfButtonType="clear"
              class="search-result-card__button--menu"
              [ngbTooltip]="i18n.Core_Actions"
              [attr.aria-label]="i18n.Core_Actions"
              data-dgat="search-result-card-224"
            >
              <df-icon
                icon="dots"
                class="color-ebony-a61 search-result-card__actions-button"
              ></df-icon>
            </button>
          </dgx-menu>
        </div>
      </div>
    </fieldset>
  </footer>
</article>
