import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-pathway-viewed-item',
  templateUrl: './pathway-viewed-item.component.html',
  styleUrls: ['./pathway-viewed-item.component.scss'],
})
export class PathwayViewedItemComponent {
  @Input() public item: any; // Does not match PathwayLiteItemModel
}
