import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { isKey, Key } from '../../utilities/keyboard/key-helper';
import { DfAccessibilityService } from '../../utilities/accessibility/accessibility.service';
import { DfPopoverComponent } from '../components/popover.component';
import { DfPopoverService } from '../services/popover.service';

@Directive({
  selector:
    // Must be an accessible, clickable element
    'button[dfPopoverTrigger], [role="button"][dfPopoverTrigger]',
})
export class DfPopoverTriggerDirective implements AfterViewInit, OnDestroy {
  @Input() public dfPopoverTrigger: DfPopoverComponent;
  public popoverComponent: DfPopoverComponent;
  private listeners: (() => void)[] = [];
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private accessibilityService: DfAccessibilityService,
    private popoverService: DfPopoverService
  ) {}

  public ngAfterViewInit() {
    this.popoverComponent = this.dfPopoverTrigger;
    this.popoverComponent.popoverTrigger = this.elementRef;
    this.initializeListeners();
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'aria-haspopup',
      'true'
    );
    this.setExpanded();
  }

  public ngOnDestroy(): void {
    for (const listener of this.listeners) {
      listener();
    }
    this.listeners = [];
  }

  private initializeListeners() {
    this.popoverService.close$.subscribe(() => {
      // overriding to ensure if the user clicks outside the
      // popover to close it, aria-expanded is still set to
      // false
      this.popoverComponent.isOpen = false;
      this.setExpanded();
    });
    // The @HostListener mechanism is more convenient,
    // but isn't used in this directive so that it can be dynamically instantiated. (See MenuComponent)
    this.listeners.push(
      this.renderer.listen(this.elementRef.nativeElement, 'click', () => {
        this.popoverComponent.toggle();
        this.setExpanded();
      })
    );
    this.listeners.push(
      this.renderer.listen(
        this.elementRef.nativeElement,
        'keyup',
        (e: KeyboardEvent) => {
          if (this.popoverComponent.popover && isKey(e, Key.Down)) {
            e.preventDefault();
            this.accessibilityService.focusNextFocusable(
              this.popoverComponent.popover.nativeElement
            );
          }
        }
      )
    );
  }

  private setExpanded() {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'aria-expanded',
      this.popoverComponent.isOpen ? 'true' : 'false'
    );
  }
}
