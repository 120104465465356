<div class="guts-p-t-1">
  <div class="tile tag-tile--horizontal">
    <div class="l_flex m-l_flex">
      <div class="tag-tile--horizontal__rating">
        <dgx-tag-provider-logo
          [src]="imgSrc"
          [providerName]="ratingInfo.providerName"
          size="md"
        ></dgx-tag-provider-logo>
      </div>
      <div class="l_flex-grow left-text">
        <h4
          class="par par--small font-semibold"
          [textContent]="ratingInfo.providerName"
        ></h4>
        <p
          class="par par--small font-medium guts-p-t-half"
          [textContent]="ratingInfo.level"
        ></p>
        <div class="par par--small par--light guts-p-t-half">
          {{ ratingInfo.dateCompleted | date: 'longDate' }}
        </div>
      </div>
    </div>
  </div>
</div>
