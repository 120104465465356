import { UploaderService } from '@app/uploader/uploader.service';
import { Injectable } from '@angular/core';

import type { InputType } from '@app/shared/models/core-api.model';
import { Observable, throwError } from 'rxjs';
export interface AuthorContentConstraints {
  maxVideoLengthMinutes: number;
  maxVideoLengthWarningMinutes: number;
}

/**
 * Facade for components related to AuthorContent
 */
@Injectable()
export class AuthorContentService {
  constructor(private uploaderService: UploaderService) {}

  public getConstraints(
    inputType: InputType
  ): Observable<AuthorContentConstraints> {
    if (inputType === 'Video') {
      return this.uploaderService.getUploadSettings();
    }

    return throwError(new Error('Not Implemented'));
  }
}
