import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ContextService } from '@app/shared/services/context.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalOptions, ModalService } from '@app/shared/services/modal.service';
import { ModalContainerComponent } from '@app/user-content/modal-container/modal-container.component';
import { finalize } from 'rxjs/operators';
import { InputShowFormParams } from '../../../inputs/inputs.model';
import { OpportunityAsExperienceApiEntity } from '../experience-modal/model/experience-form.model';
import {
  AnyInputApiEntity,
  InputContext,
  RenderMode,
} from '../user-input.model';
import { InputModalFactoryService } from './input-modal-factory.service';
import { InputType } from '@app/shared/models/core-api.model';
import { ArticleCatalogComponent } from '../../user-input-v2/inputs/article/components/catalog/article-catalog.component';
import { LDFlagsService } from '@dg/shared-services';
import { ArticlePathwaysPlansComponent } from '@app/user-content/user-input-v2/inputs/article/components/pathways-and-plans/article-pathways-plans.component';
import { VideoCatalogComponent } from '@app/user-content/user-input-v2/inputs/video/components/catalog/video-catalog.component';
import { VideoPathwaysPlansComponent } from '@app/user-content/user-input-v2/inputs/video/components/pathways-and-plans/video-pathways-plans.component';
import { PathwayStep } from '@dg/pathways-rsm';
import { VideoGlobalAddComponent } from '@app/user-content/user-input-v2/inputs/video/components/global-add/video-global-add.component';
import { ArticleGlobalAddComponent } from '@app/user-content/user-input-v2/inputs/article/components/global-add/article-global-add.component';
import { CourseCatalogComponent } from '@app/user-content/user-input-v2/inputs/course/components/catalog/course-catalog.component';
import { ExperienceGlobalAddComponent } from '@app/user-content/user-input-v2/inputs/experience/components/global-add/experience-global-add.component';
import { CourseGlobalAddComponent } from '@app/user-content/user-input-v2/inputs/course/components/global-add/course-global-add.component';
import { CoursePathwaysPlansComponent } from '@app/user-content/user-input-v2/inputs/course/components/pathways-and-plans/course-pathways-plans.component';

type ShowModalParams = Partial<InputShowFormParams<AnyInputApiEntity>>;

export interface InlineSubmissionArgs<T> {
  resultModel: T;
  resultProvider: Subject<T>;
}

@Injectable({
  providedIn: 'root',
})
export class InputModalDispatcherService {
  constructor(
    private modalService: ModalService,
    private focusStackService: FocusStackService,
    private inputModalFactoryService: InputModalFactoryService,
    private contextService: ContextService,
    private ld: LDFlagsService
  ) {}

  /**
   * adding to profile (completing but not editing) AFTER submit (for success animation)
   * @param {ShowModalParams} options
   */
  public create<TEntity extends AnyInputApiEntity>({
    inputType,
    organizationId,
    canManageContent,
    pathwayId,
    pathwayTitle,
    pathwayInternalUrl,
    sourceTarget,
  }: ShowModalParams): Observable<TEntity> {
    return this.showModal({
      inputType,
      organizationId,
      canManageContent,
      pathwayId,
      pathwayTitle,
      pathwayInternalUrl,
      sourceTarget,
    });
  }

  /**
   * completing = is being added to, or edited from, user profile (not a pathway)
   * @param {ShowModalParams} options
   * @returns {Observable<unknown>}
   */
  public complete<TEntity extends AnyInputApiEntity>({
    inputType,
    inputId,
    sourceTarget,
    trackingArea,
  }: ShowModalParams): Observable<TEntity> {
    return this.showModal({
      inputType,
      inputId,
      sourceTarget,
      isCompleting: true,
      trackingArea,
    });
  }

  /**
   * editing = modifying the item's meta info (tags, display title, etc.)
   * @param {ShowModalParams} options
   * @param {PathwayStep} pathwayStep (optional - required when editing a pathway step locally only/not in the context of the catalog)
   * @returns {Observable<TEntity>}
   */
  public edit<TEntity extends AnyInputApiEntity>(
    {
      inputType,
      inputId,
      userInputId,
      pathwayId,
      pathwayTitle,
      pathwayInternalUrl,
      initialModel,
      sourceTarget,
      targetId,
      organizationId,
      isCmsContent,
      isEditingInternalContent,
    }: ShowModalParams,
    pathwayStep?: PathwayStep
  ): Observable<TEntity> {
    return this.showModal(
      {
        inputType,
        inputId,
        userInputId,
        isCompleting: !!userInputId,
        isEditing: true,
        pathwayId,
        pathwayTitle,
        pathwayInternalUrl,
        initialModel,
        targetId,
        sourceTarget,
        organizationId,
        isCmsContent,
        isEditingInternalContent,
      },
      pathwayStep
    );
  }

  /**
   * addAuthored = adding to profile with the Authored checkbox checked from thought Leadership
   * @param {ShowModalParams} options
   * @returns {Observable<TEntity>}
   */
  public addAuthored<TEntity extends AnyInputApiEntity>({
    inputType,
    inputId,
    canManageContent,
    sourceTarget,
    modalOptions,
  }: ShowModalParams): Observable<TEntity> {
    return this.showModal({
      inputType,
      inputId,
      isCompleting: true,
      isAuthoredChecked: true,
      canManageContent,
      sourceTarget,
      modalOptions,
    });
  }

  /**
   * addOpportunity = adding an opportunity to profile as a position
   * @param {ShowModalParams} options
   * @returns {Observable<TEntity>}
   */
  public addOpportunity<TEntity extends OpportunityAsExperienceApiEntity>({
    initialModel,
    sourceTarget,
    trackingAction,
  }: ShowModalParams): Observable<TEntity> {
    return this.showModal({
      inputType: 'Position',
      initialModel,
      isCompleting: true,
      sourceTarget,
      trackingAction,
    });
  }

  /**
   *
   * @param {ShowModalParams} options
   * @param {PathwayStep} pathwayStep (optional - required when editing a pathway step locally only/not in the context of the catalog)
   * @returns {Observable<unknown>}
   */
  public showModal<TEntity extends AnyInputApiEntity>(
    options: ShowModalParams,
    pathwayStep?: PathwayStep
  ): Observable<TEntity> {
    const {
      inputType,
      isEditing,
      isCompleting,
      organizationId,
      trackingArea,
      inputId,
      pathwayId,
      targetId,
      isCmsContent,
      isEditingInternalContent,
      initialModel,
    } = options;
    const { sourceTarget, ...providedInputs } = options;
    const renderMode = isCompleting
      ? RenderMode.UserProfile
      : // if this is a pathway *or* target, we want to use the pathway render option
        pathwayId || targetId
        ? RenderMode.Pathways
        : RenderMode.ContentCatalog;

    const context: InputContext = {
      inputType,
      isEditing,
      isCompleting,
      isChannel: this.contextService.isChannel(),
      pathwayId,
      renderMode: renderMode,
      organizationId,
      trackingArea,
      inputId,
      isCmsContent,
      isEditingInternalContent,
    };

    // NEW MODALS. Global Edit is being phased out, so we did not make new modals for
    // that. All other scenarios continue to use old modals for now, including the
    // extension, which does not appear to use this service but *does* use the old modals.
    const newArticleVideoModals =
      this.ld.inferredSkillsContent &&
      (inputType === 'Article' || inputType === 'Video');
    const newExperienceModals =
      this.ld.showUpdatedAchievementModals && inputType === 'Position';
    const newCourseModals =
      this.ld.inferredSkillsCourseEvent && inputType === 'Course';
    const notGlobalEdit = renderMode !== RenderMode.UserProfile || !isEditing;

    if (
      notGlobalEdit &&
      (newArticleVideoModals || newCourseModals || newExperienceModals)
    ) {
      let modalOptions: ModalOptions = {
        windowClass: 'lg-modal',
        inputs: { context },
      };
      const component: any = this.modalMapper(inputType, renderMode);
      if (renderMode === RenderMode.Pathways) {
        modalOptions.inputs = {
          ...modalOptions.inputs,
          pathwayStep,
        };
      } else {
        modalOptions.inputs = {
          ...modalOptions.inputs,
          initialModel,
        };
      }
      if (sourceTarget) {
        this.focusStackService.push(sourceTarget);
      }
      return this.modalService.show<TEntity>(component, modalOptions).pipe(
        finalize(() => {
          if (sourceTarget) {
            this.focusStackService.pop();
          }
        })
      );
    }

    // OLD MODALS. Create the domain service for the current context
    const facade = this.inputModalFactoryService.getInputModalService(
      context,
      options.initialModel
    );

    const modalInputs = {
      ...providedInputs,
      facade,
      submit: () => facade.onSubmit(), // TODO: Do we need external submit now or can domain handle it?
    };

    if (sourceTarget) {
      this.focusStackService.push(sourceTarget);
    }

    const modalOptions = { windowClass: 'lg-modal', inputs: modalInputs };
    return this.modalService
      .show<TEntity>(ModalContainerComponent, modalOptions)

      .pipe(
        finalize(() => {
          if (sourceTarget) {
            this.focusStackService.pop();
          }
        })
      );
  }

  private modalMapper(inputType: InputType, rendererMode: RenderMode) {
    switch (inputType) {
      case 'Article': {
        switch (rendererMode) {
          case RenderMode.UserProfile:
            return ArticleGlobalAddComponent;
          case RenderMode.Pathways:
            return ArticlePathwaysPlansComponent;
          default:
            return ArticleCatalogComponent;
        }
      }
      case 'Video': {
        switch (rendererMode) {
          case RenderMode.UserProfile:
            return VideoGlobalAddComponent;
          case RenderMode.Pathways:
            return VideoPathwaysPlansComponent;
          default:
            return VideoCatalogComponent;
        }
      }
      case 'Position': {
        return ExperienceGlobalAddComponent;
      }
      case 'Course': {
        switch (rendererMode) {
          case RenderMode.UserProfile:
            return CourseGlobalAddComponent;
          case RenderMode.Pathways:
            return CoursePathwaysPlansComponent;
          default:
            return CourseCatalogComponent;
        }
      }
    }
  }
}
