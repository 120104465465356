import { Injectable, Injector } from '@angular/core';
import {
  UpdateUserCourseParameters,
  UserCourse,
  UserCourseParameters,
  UserExistingCourseParameters,
} from '@app/inputs/inputs-api.model';
import { UserInputTypeServiceBase } from '@app/inputs/services/user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Books */
@Injectable({
  providedIn: 'root',
})
export class UserCourseModalService extends UserInputTypeServiceBase<
  'Course',
  UserCourse,
  UserCourseParameters,
  UserExistingCourseParameters,
  UpdateUserCourseParameters
> {
  constructor(injector: Injector) {
    super(injector, '', 'Course');
  }
}
