import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeMultiSelectComponent } from './components/form-components/attribute-multi-select/attribute-multi-select.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [AttributeMultiSelectComponent],
  exports: [AttributeMultiSelectComponent],
  imports: [CommonModule, SharedModule],
})
export class AutomationsSharedModule {}
