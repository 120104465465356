import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Workday Skill Added notification */
@Component({
  selector: 'dgx-skills-added-to-profile-from-job-role',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillsAddedToProfileFromJobRoleComponent extends NotificationItemComponentBase {
  protected get usePeriodicRefresh() {
    return true;
  }

  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    const count = Number(this.notification.parameters.addedSkillCount);
    if (count > 1) {
      return this.translateHtml(
        'Notification_SkillsAddedToProfileFromJobRole_Plural',
        {
          count: count,
          role: this.notification.parameters.jobRole,
          viewSkillsHtml: this.viewSkillsTemplate(true),
        }
      );
    } else {
      return this.translateHtml(
        'Notification_SkillsAddedToProfileFromJobRole_Single',
        {
          count: count,
          role: this.notification.parameters.jobRole,
          viewSkillsHtml: this.viewSkillsTemplate(true),
        }
      );
    }
  }
}
