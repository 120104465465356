import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { WindowToken } from '@app/shared/window.token';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { CredentialApi } from '../credential-api';

/**
 * Service for managing Skill Certificates (AKA Credentials)
 *
 * NOTICE: This service is NOT intended for user authorization credentials
 */

@Injectable({ providedIn: 'root' })
export class CredentialService extends ApiServiceBase {
  constructor(
    public http: NgxHttpClient,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super(http, translateService.instant('InputsSvc_GeneralError'));
  }

  /**
   * Get the Skill Certification for the given `uid`
   *
   * @param uid
   */
  public getCredentialByUid(uid: string): Observable<CredentialApi.Credential> {
    return this.get(
      '/credentials/GetUserCredentialDetailsByUid',
      { uid },
      this.translateService.instant('CredentialSvc_GetUserCredError')
    );
  }

  /**
   * Get the endorsers for a given Skill Certification
   *
   * @param userCredentialId
   */
  public getUserCredentialEndorsersById(
    userCredentialId: number
  ): Observable<CredentialApi.Endorsement[]> {
    return this.get(
      '/credentials/GetUserCredentialEndorsersById',
      { userCredentialId },
      this.translateService.instant('CredentialSvc_GetEndorsersError')
    );
  }

  /**
   * Get the social media links for a given Skill Certification
   *
   * @param uid
   */
  public getUserCredentialSocialLinks(
    uid: string
  ): Observable<CredentialApi.SocialLinks> {
    return this.get(
      '/credentials/GetUserCredentialSocialLinks',
      { uid },
      this.translateService.instant('CredentialSvc_GetSocialLinksError')
    );
  }

  /**
   * Navigate to a completed Skill Certification
   *
   * @param userCredentialUid
   */
  public viewCredentialCertificate(userCredentialUid: string): void {
    this.windowRef.open(`/credential/${userCredentialUid}`, '_self');
  }

  /**
   * Deep links to the Skill Certificate page may be visited by anonymous users.
   * This allows the owner of the certificate to toggle that visibility. Visibility
   * of the Skill Certificate rating may be toggled separately on the users profile.
   *
   * @param guid
   * @param privacyId
   */
  public updateUserCredentialPrivacy(
    guid: string,
    privacyId: number
  ): Observable<void> {
    return this.put(
      '/credentials/UpdateUserCredentialPrivacy',
      {
        guid,
        privacyId,
      },
      this.translateService.instant('CredentialSvc_ErrorSavingChanges')
    );
  }

  /**
   * Report a fradulent Skill Certification
   *
   * @param uid
   * @param source
   */
  public reportInvalidUid(uid: string, source: string): Observable<void> {
    return this.post(
      '/credentials/ReportInvalidUid',
      {
        uid,
        source,
      },
      this.translateService.instant('Cred_CredVerifySvc_SubmitError')
    );
  }

  /**
   * Check for a completed Skill Certification for the current authenticated user
   *
   * @param tagId
   */
  public getCredentialByTagId(
    tagId: number
  ): Observable<CredentialApi.CredentialStatus> {
    return this.get(
      '/credentials/GetCredentialByTagId',
      { tagId },
      this.translateService.instant('CredentialSvc_GetCredsError')
    );
  }
}
