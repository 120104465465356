<span
  [ngClass]="styles"
  class="l_flexbar"
  [class.has-error]="hasError"
  role="search"
>
  <df-icon
    [size]="iconSize"
    [icon]="icon"
    class="search-icon"
    role="presentation"
  ></df-icon>
  <ng-content></ng-content>
</span>
