import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ColorService } from '@app/shared/services/color.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';

/** Generates default user profile pictures as colorful swirly avatars.  */
@Injectable({ providedIn: 'root' })
export class ProfilePicFallbackService {
  private static getFallbackImpl(authUser?: AuthUser) {
    if (authUser?.orgInfo[0]?.settings?.useOriginalProfileAvatar) {
      return ProfilePicFallbackService.prototype.createInitialsFallbackUrl;
    } else {
      return ProfilePicFallbackService.prototype.getColorfulAvatarUrl;
    }
  }

  // Here we decide what implementation of the fallbackUrl to use depending on
  // the setting on authUser.
  public authUser = this.authService.authUser;
  public readonly getFallbackUrl = ProfilePicFallbackService.getFallbackImpl(
    this.authUser
  );
  private profileCanvas?: HTMLCanvasElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private colorService: ColorService,
    private enviroService: WebEnvironmentService
  ) {}
  private get canvas() {
    if (this.profileCanvas) {
      return this.profileCanvas;
    }

    // Create a canvas element that's shared between all calls to the initials fn
    const canvas = this.document.createElement('canvas');
    canvas.style.display = 'none';
    this.document.body.appendChild(canvas);

    return (this.profileCanvas = canvas);
  }

  public getColorfulAvatarUrl(userName: string): string {
    const num = this.stringHash(userName);
    return this.enviroService.getBlobUrl(
      `/content/img/default/profile/${num % 36}.svg`,
      true
    );
  }

  public createInitialsFallbackUrl(
    userName = '',
    size: number,
    fontFamily: string
  ): string {
    const dimension = size * 2; // for retina
    const textSize = Math.floor(0.42 * dimension);
    const initials = this.getInitials(userName).toUpperCase();
    const { canvas } = this;

    // generate image
    canvas.width = dimension;
    canvas.height = dimension;
    const context = canvas.getContext('2d');

    // first draw the circle
    context.fillStyle = '#d4d7d9'; // See PD-89991 for bug around 'ebony-a18-hue'  this.colorService.getColor('ebony-a18-hue');
    context.fillRect(0, 0, canvas.width, canvas.height);

    // then draw the text with ebony on top
    context.fillStyle = this.colorService.getColor('ebony');
    context.font = textSize + 'px ' + fontFamily;
    context.textAlign = 'center';
    context.fillText(
      initials,
      canvas.width / 2,
      canvas.height / 2 + textSize / 3
    );

    return canvas.toDataURL();
  }

  private getInitials(name = '') {
    const nameParts = name.trim().split(' ');
    let initials = nameParts[0].substr(0, 1);
    if (nameParts.length > 1) {
      initials = initials + nameParts[nameParts.length - 1].substr(0, 1);
    }
    return initials;
  }

  private stringHash(str: string): number {
    if (!str) {
      str = Math.random().toString('Degreed'.length).substr(2, 6);
    }
    // https://stackoverflow.com/a/7616484
    let hash = 0;
    for (const ch of str) {
      /* eslint-disable no-bitwise */
      hash = (hash << 5) - hash + ch.charCodeAt(0);
      hash |= 0;
    }
    return Math.abs(hash);
  }
}
