import { throttle } from 'lodash-es';

// ****************************************************************
// File Upload Utils
// ****************************************************************

export type ProgressNotification = (
  percentDone: number,
  message: string,
  stop?: () => void
) => void;
export type UploadResponse = [string, string]; // filename, dataUrl

/**
 * For the selected file, load into memory (use progress simulator if needed)
 * Use `progressFn` to provide status notifications externally
 */
export const loadFile = async (
  file: File,
  progressFn?: ProgressNotification,
  maxSize = 1 * 1024 * 1024
) => {
  progressFn ||= () => {};
  const exceedsMaxSize = file?.size > maxSize;
  if (!file || exceedsMaxSize) {
    return Promise.reject(
      !file ? 'Error: No file selected' : 'Error: File size exceeds 1Mb limit.'
    );
  }

  return new Promise<UploadResponse>((resolve, reject) => {
    const dataUrl = (e) => (e.target as any)?.result.toString();
    let simulator = new ProgressSimulator(file.size, file.name, progressFn);

    // ****************************************************************
    // Callback handlers for FileReader
    // ****************************************************************

    const onError = () => reject(`Error: unable to load ${file.name}`);
    const onLoadProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        // NOTE: This is a workaround for Safari, which doesn't support the 'progress' event
        if (e.loaded !== e.total) {
          const percent = Math.round((e.loaded / e.total) * 100);
          progressFn(Math.min(100, percent), `Uploading ${file.name}`);
          simulator = undefined; // disable simulation
        }
      }

      if (simulator) {
        simulator.onFinish = () => resolve([file.name, dataUrl(e)]);
        simulator.run();
      } else {
        reader.onload = (e) => {
          resolve([file.name, dataUrl(e)]);
        };
      }
    };

    // Read the file as a data URL
    const reader = new FileReader();

    reader.onerror = onError;
    reader.onprogress = onLoadProgress;

    reader.readAsDataURL(file); // Use the data URL to display the image
  });
};

// ****************************************************************
// Progress Simulator
// ****************************************************************

/**
 * When readAsDataURL() is invoked the progress event may not trigger properly with increments
 * So we will simulate the progress event by creating a queue of progress percentages
 */
class ProgressSimulator {
  public onFinish = () => {};
  private notifyQueue = [];
  private simulationId: number;

  constructor(
    private totalSize: number,
    private fileName: string,
    private progressFn: ProgressNotification,
    private options = { numSteps: 8, interval: 150 }
  ) {
    this.buildQueue();
  }

  run(): ProgressSimulator {
    if (this.isRunning) return this;

    const { interval } = this.options;
    const queue = [...this.notifyQueue];
    const stop = this.stop.bind(this);

    this.simulationId = window.setInterval(() => {
      const percentage = queue.shift();
      if (percentage && this.isRunning) {
        this.progressFn(percentage, `Uploading file '${this.fileName}'`, stop);
      } else {
        const announceFinish = !!this.simulationId;
        this.stop();

        announceFinish && this.onFinish();
      }
    }, interval);

    return this;
  }

  stop(): void {
    clearInterval(this.simulationId);
    this.simulationId = undefined;
  }

  private get isRunning(): boolean {
    return !!this.simulationId;
  }

  /**
   * Build percentage steps for the progress simulation
   */
  private buildQueue() {
    const chunkSize = Math.ceil(this.totalSize / this.options.numSteps);

    let progress = 0;
    while (progress < this.totalSize) {
      progress += chunkSize;
      const percentage = Math.min(
        100,
        Math.round((progress / this.totalSize) * 100)
      );
      this.notifyQueue.push(percentage);
    }
  }
}
