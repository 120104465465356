import { Injectable } from '@angular/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { EpisodeFormModel } from '../episode-forms.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { RenderMode } from '../../user-input.model';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
@Injectable({ providedIn: 'root' })
export class EpisodeInfoRenderer implements FormRenderer {
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private translate: TranslateService,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<EpisodeFormModel>
  ): DfFormFieldConfig[] {
    const state = context.state();
    return [
      this.formBuilder
        .customField(
          'nameOrUrl',
          'Core_PodcastNameUrl',
          context.templates.episodeInitialization,
          {
            vm: state.formFields.episodeInitialization,
            nameOrUrl: context.state().nameOrUrl,
            isLoadingEpisodes: context.state().isLoadingEpisodes,
            hasNoPodcastMatch: context.state().hasNoPodcastMatch,
            i18n: context.translationKeys,
          }
        )
        .autofocused()
        .readonlyWhen(() => context.inputContext.isEditing)
        .build(),
      this.formBuilder
        .fieldGroup('initializedForm', [
          this.formBuilder
            .customField(
              'episodeSelected',
              undefined,
              context.templates.episodeSelection,
              {
                isLoadingEpisode: context.state().isLoadingEpisode,
                selectedEpisode: context.state().selectedEpisode,
                episodeList: context.state().episodeList,
                vm: state,
                fieldData: state.formFields.episodeSelected,
              }
            )
            .validatedByIndexed({
              required: {
                expression: () => {
                  // making this required, or else the form will error
                  // trying to submit without an episode in that view
                  return !!context.state().selectedEpisode;
                },
                message: () => {
                  return this.translate.instant('Core_FieldRequiredFormat', {
                    fieldName: context.translationKeys.EpisodeFormCtrl_Episode,
                  });
                },
              },
            })
            .hiddenWhen(() => !context.state().selectedPodcast)
            .build(),
          this.formBuilder
            .foreignField<MonthPickerFieldParams>(
              'dateCompleted',
              context.translationKeys.EpisodeFormCtrl_DateCompleted,
              MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
              {
                isMaxDateToday: true,
              }
            )
            .hiddenWhen(
              () =>
                context.state().podcastType === 'url' ||
                context.inputContext.renderMode === RenderMode.Pathways
            )
            .asOptional()
            .build(),
          this.formBuilder
            .checkbox(
              'audioAuthored',
              context.translationKeys.Core_ICreatedAudio
            )
            .withDgatId('episodeForm-e7f')
            .hiddenWhen(
              () => context.inputContext.renderMode === RenderMode.Pathways
            )
            .build(),
          this.formBuilder
            .title() // defaults to required
            .withPlaceholder(context.translationKeys.Core_AudioTitlePlaceholder)
            .withDgatId('episodeForm-cca')
            .hiddenWhen(() => context.state().podcastType === 'selected')
            .validatedBy(isEmptyValidator)
            .build(),
          this.formBuilder
            .fieldGroup('addToCatalogSection', [
              this.inputFieldBuilder.buildPathwaysAddToCatalogField(
                context.inputContext.renderMode,
                state.canManageContent,
                state.authUser.defaultOrgInfo?.name,
                'MediaFormCtrl_AddToCatalogFormat',
                context.templates.addToCatalogDupsHelp,
                state.onAddToCatalogChange
              ),
            ])
            .hiddenWhen(() => {
              return (
                context.inputContext.isEditing ||
                context.state().podcastType === 'selected'
              );
            })
            .build(),

          this.formBuilder
            .optionalTextInput(
              'duration',
              context.translationKeys.Core_AudioLength
            )
            .ofType('number')
            .withDgatId('episodeForm-512')
            .withMin(0)
            .asRequired()
            .withStep(0.1)
            .readonlyWhen(() => context.state().isDurationDisabled)
            .hiddenWhen(
              () =>
                this.ldFlagsService.durationForCoursesEventsOther &&
                context.inputContext.renderMode === RenderMode.Pathways
            )
            .build(),
          this.formBuilder
            .optionalTextarea(
              'comment',
              context.translationKeys.EpisodeFormCtrl_WhatDidYouLearnLong
            )
            .withPlaceholder(
              context.translationKeys.EpisodeFormCtrl_WhatDidYouLearnShort
            )
            .withDgatId('episodeForm-5e9')
            .hiddenWhen(
              () => context.inputContext.renderMode === RenderMode.Pathways
            )
            .build(),
          // Duration
          this.inputFieldBuilder.buildDurationFields(
            this.ldFlagsService.durationForCoursesEventsOther &&
              context.inputContext.renderMode === RenderMode.Pathways,
            state.inputType
          ),
          this.formBuilder
            .optionalTextarea(
              'description',
              context.translationKeys.Core_Description
            )
            .withDgatId('episodeForm-328')
            .hiddenWhen(
              () => context.inputContext.renderMode === RenderMode.UserProfile
            )
            .build(),
          this.inputFieldBuilder.buildSkillsField(
            state.authUser.viewerInterests,
            false
          ),
        ])
        .hiddenWhen(() => !context.state().isFormInitialized)
        .build(),
    ];
  }
}
