import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DurationConverterService } from '@app/shared/services/duration-converter.service';
import { Mapper } from '../../mapper.model';
import { EpisodeFormModel } from '../episode-forms.model';
import { EpisodeApiEntity } from '../repository/episode.entity.model';

@Injectable({ providedIn: 'root' })
export class UserProfileEpisodeMapper implements Mapper<any, EpisodeApiEntity> {
  constructor(
    private datePipe: DatePipe,
    private durationConverterService: DurationConverterService
  ) {}

  public toApiParameters(source: EpisodeFormModel): EpisodeApiEntity {
    let episodeEntity: EpisodeApiEntity = {
      userEpisodeId: source.userInputId,
      inputType: source.inputType,
      authored: source.initializedForm.audioAuthored,
      comment: source.initializedForm.comment,
      duration: this.durationConverterService.fromMinutesToSeconds(
        source.initializedForm.duration
      ),
      tags: source.initializedForm.tags,
      orgContentMetadata: {
        hideFromCatalog: true,
      },
    };

    if (source.podcastType === 'url') {
      episodeEntity.title = source.initializedForm.title;
      episodeEntity.url = source.nameOrUrl;
    } else if (source.podcastType === 'selected') {
      episodeEntity.title = source.selectedEpisode.title;
      episodeEntity.url = source.selectedEpisode.url;
      episodeEntity.summary = source.selectedEpisode.summary;
      episodeEntity.author = source.selectedEpisode.author;
      episodeEntity.imageUrl = source.selectedEpisode.imageUrl;
      episodeEntity.feedUrl = source.selectedEpisode.feedUrl;
      episodeEntity.feedName = source.selectedEpisode.feedName;
      episodeEntity.publishDate = source.selectedEpisode.publishDate;
      episodeEntity.externalId = source.selectedEpisode.id;
      episodeEntity.language = source.selectedEpisode.language;
      episodeEntity.contentUrl = source.selectedEpisode.contentUrl;
    }
    // implies edit - need to set title for notification
    else {
      episodeEntity.title = source.initializedForm.title;
    }

    if (source.initializedForm.dateCompleted) {
      episodeEntity.dateCompleted = this.datePipe.transform(
        source.initializedForm.dateCompleted,
        'yyyy-MM-dd'
      );
    }

    return episodeEntity;
  }

  public toViewModel(source: EpisodeApiEntity): Partial<EpisodeFormModel> {
    const target: Partial<EpisodeFormModel> = {
      inputType: source.inputType,
      userInputId: source.userEpisodeId,
      initializedForm: {
        duration: source.duration,
        title: source.title,
        audioAuthored: source.authored,
        description: source.summary,
        dateCompleted: source.dateCompleted,
        tags: source.tags,
      },
    };
    return target;
  }
}
