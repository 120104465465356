import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'dgx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  /** Option to display a loading state. */
  @Input() public isLoading: boolean = false;
  /**
   * Option to if the whole card is clickable.
   * Note: Avoid this since we usually have clickable elements inside the cards
   */
  @Input() public isClickable: boolean = false;
  /** Option to give the whole card an aria label */
  @Input() public cardAriaLabel: string = '';
}
