<div class="wizard">
  <div
    [ngClass]="{
      'arch-wizard-navigation-bar': true,
      'arrow-indicator': indicator == 'arrow'
    }"
  >
    <ul class="steps-indicator">
      <li
        *ngFor="
          let section of stepLabelItems;
          first as first;
          last as last;
          index as index
        "
        class="h-step-item"
      >
        <div
          class="h-step-body"
          [ngClass]="{
            clickable: isClickable(index)
          }"
          (click)="onHeaderClick(index)"
        >
          <ng-container *ngTemplateOutlet="section" />
          <div
            class="step-indicator"
            *ngIf="hasStepSymbol(index); else defaultIndicator"
          >
            <ng-container *ngTemplateOutlet="stepSymbolItems[index]" />
          </div>
        </div>
      </li>
    </ul>
  </div>
  <ng-content></ng-content>
</div>
<ng-template #defaultIndicator>
  <ng-container *ngIf="indicator == 'line'; else arrowIndicator">
    <div class="step-indicator-line color-ebony-a61"></div>
  </ng-container>
  <ng-template #arrowIndicator>
    <div class="step-indicator">
      <df-icon
        class="color-ebony-a61"
        icon="chevron-right"
        size="medium"
      ></df-icon>
    </div>
  </ng-template>
</ng-template>
