<div class="field-group cursor-pointer" (click)="userSearch.focus()">
  <span class="field-group__input field-group__input--tag">
    <span class="guts-m-r-quart par par--small font-medium color-ebony">
      {{ i18n.Core_To }}:
    </span>

    <!--Selected peers-->
    <button
      type="button"
      *ngFor="let item of selectedPeers; trackBy: trackByFn"
      class="btn btn-sm bg-color-ebony-a08 color-ebony guts-m-l-quart guts-m-v-half guts-m-r-half user-tag"
      (click)="removePeer(item)"
      data-dgat="newSearchUsers-7d9"
    >
      <dgx-profile-pic
        *ngIf="hasPicture(item)"
        class="user-tag__picture"
        [profile]="item"
        size="tiny"
      >
      </dgx-profile-pic>
      <span>
        {{ item.name }}
        <df-icon
          icon="cross"
          size="small"
          class="guts-m-l-quart color-ebony-a45"
        ></df-icon>
      </span>
    </button>

    <!--Search-->
    <dgx-user-search
      #userSearch
      class="ib"
      [useSearchField]="false"
      [placeholder]="i18n.recommendationForm_SearchByName"
      [dgxAutofocus]
      [search]="searchPeers"
      [hideResults]="true"
      (selectRecipient)="addPeer($event)"
    ></dgx-user-search>
  </span>
</div>

<!--No matching search results-->
<div class="guts-m-b-1-half" [hidden]="!noResults">
  <span class="guts-p-v-1 ib">{{ i18n.recommendationForm_NoResults }}</span>
</div>
