<div
  class="tags-list l_flexbar"
  [ngClass]="{
    'tags-list--one-line': fitOneLine,
    'l_flex-justify-center': centered
  }"
>
  <div class="l_flex-top guts-p-r-half" *ngIf="!hideIcon">
    <df-icon icon="tag" class="par par--small par--light"></df-icon>
  </div>
  <div
    #tagsListContainer
    [class.invisible]="isHiding"
    class="tags-list__container"
  >
    <ul
      #tagsListElement
      class="tags-list__list"
      [class.white-space]="centered && !fitOneLine"
    >
      <li
        *ngFor="let tag of tagLinks"
        class="tags-list__item"
        data-dgat="tagsList-7f5"
      >
        <a
          [attr.href]="getTagUrl(tag.url)"
          [innerHTML]="tag.label"
          (click)="openTag($event, tag)"
          class="par par--small par--light"
          data-dgat="tagsList-a22"
        ></a>
      </li>
      <li *ngIf="fitOneLine && showMoreLink" class="tags-list__item">
        <button
          (click)="showMore()"
          type="button"
          class="par par--small par--light"
          data-dgat="tagsList-0c1"
        >
          {{
            'dgTagsList_PlusCountMore' | translate: { count: hiddenTags.length }
          }}
        </button>
      </li>
    </ul>
  </div>
</div>
