import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';

import {
  FlexRow,
  FlexRowSummary,
  FlexRowType,
} from '@app/flex-framework/flex-api.model';
import { LayoutConfig } from '@app/flex-framework/flex-section-api.model';
import { FlexService } from '@app/flex-framework/services/flex-framework.service';
import { TargetType } from '@app/profile/profile-api.model';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { HtmlTranslatedPipe } from '@app/shared/pipes/html-translated.pipe';
import { HtmlPipe } from '@app/shared/pipes/html.pipe';
import { ModalService } from '@app/shared/services/modal.service';
import { TargetsService } from '@app/shared/services/targets.service';
import {
  GetTargetResourcesResponse,
  Resource,
} from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from '@app/shared/services/notifier.service';

@Injectable({
  providedIn: 'root',
})
export class FlexRowService {
  public i18n = this.translateService.instant([
    'dgFlexRow_DeleteConfirmSubmit',
    'dgFlexRow_DeleteSuccess',
  ]);
  // row updated
  private rowUpdated = new Subject<void>();

  constructor(
    private flexService: FlexService,
    private htmlPipe: HtmlPipe,
    private htmlTranslatedPipe: HtmlTranslatedPipe,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private targetsService: TargetsService,
    private translateService: TranslateService
  ) {}

  public get onRowUpdated$() {
    return this.rowUpdated.asObservable();
  }

  public createFlexRowSummary(
    layoutConfig: LayoutConfig,
    flexRowType: FlexRowType,
    title: string,
    rowNode: number = 0,
    targetId: number = null
  ): FlexRowSummary {
    const flexRowSummary: FlexRowSummary = {
      layoutConfig: layoutConfig,
      name: title,
      parentNode: `/${rowNode}/`,
      resourceType: flexRowType,
      targetId: targetId,
    };

    return flexRowSummary;
  }

  public createStandaloneFlexRow(
    type: FlexRowType,
    title: string,
    rowResources: any[],
    legacyDisplayInfo = false,
    alwaysCanViewAll = false
  ): FlexRow {
    const rowDisplayType = this.flexService.getRowDisplayInfo(
      type,
      legacyDisplayInfo
    );
    const rowSummary = this.createFlexRowSummary(
      rowDisplayType.layoutConfig,
      type,
      title
    );
    let flexRow = this.flexService.createFlexRow(rowSummary, legacyDisplayInfo);
    flexRow = this.updateRowLayout(flexRow, false, alwaysCanViewAll);
    flexRow.targetResources = rowResources;

    return flexRow;
  }

  /**
   * Opens a custom sub-modal to confirm the user intends to delete
   * this row.
   */
  public removeRow(
    section: FlexRowSummary,
    sectionDefaultTitle: string,
    activeModal: NgbActiveModal
  ) {
    const inputs: SimpleModalInputBindings = {
      canCancel: true,
      headerText: this.i18n.dgFlexRow_DeleteConfirmSubmit,
      bodyClasses: 'par--small',
      bodyText: this.htmlTranslatedPipe.transform(
        'dgFlexRow_DeleteSectionBody',
        {
          sectionNameHtml: this.htmlPipe
            .transform`<span class="font-semibold">${
            section.name || sectionDefaultTitle
          }</span>`,
        }
      ),
      submitButtonText: this.i18n.dgFlexRow_DeleteConfirmSubmit,
      submitButtonType: 'destructive',
    };

    return this.modalService
      .show<void>(SimpleModalComponent, {
        inputs,
      })
      .pipe(
        switchMap(() =>
          this.targetsService.removeTargetRow(
            section.targetId,
            section.parentNode
          )
        )
      )
      .subscribe(() => {
        activeModal.close();

        this.notifierService.showSuccess(this.i18n.dgFlexRow_DeleteSuccess);
      });
  }

  public removeResourceFromRow(row: FlexRow, id: number) {
    const reference = row.targetResources.find(
      (resource: Resource) => resource.referenceId === id
    );

    return this.targetsService
      .deleteTargetResourceByNode(row.section.targetId, reference.node)
      .subscribe(() => {
        row.updateRowFn(reference.referenceId);
      });
  }

  /**
   * Called from outside the service to alert the parent component to updates.
   * @see {FlexViewHeaderComponent} subscription.
   */
  public rowUpdate() {
    this.rowUpdated.next();
  }

  // See flex-framework.service for notes on fetching all resources.
  public getRowResources(
    row: FlexRow,
    force: boolean = false,
    isEditing: boolean
  ): Observable<any[]> {
    const loadedCount = row.targetResources?.length ?? 0;
    // For rows displaying all their content at once, i.e. where layoutConfig is 'All',
    // the section.resourceCount property will be *undefined*. In that instance, use our
    // targetResources.length to determine the number of resources, as it will be the
    // total count.
    const totalCount =
      row.section.layoutConfig.displayType !== 'All' &&
      row.section.resourceCount !== undefined
        ? row.section.resourceCount
        : loadedCount;
    row = { ...this.updateRowLayout(row, isEditing) };

    const shouldFetch = force
      ? true
      : loadedCount < totalCount && loadedCount < row.resourceLimit;

    if (!shouldFetch) {
      return of([]);
    }

    row.loading = true;

    const skip = force ? 0 : loadedCount;

    return this.targetsService
      .getTargetResources(
        row.section.targetId,
        [row.section.parentNode],
        skip,
        totalCount,
        true // TODO: do we really need to include statistics?
      )
      .pipe(
        finalize(() => {
          row.loading = false;
        }),
        map((response: GetTargetResourcesResponse) => response.resources)
      );
  }

  /**
   * Call to update a given target row, *only* the title and description fields.
   *
   * @param targetId - Section.TargetId
   * @param name - Section.Name
   * @param node - Section.ParentNode
   * @param description - Section.Description
   * @returns Observable<void>
   */
  public updateSection(
    { description, name, parentNode, targetId }: Partial<FlexRowSummary>,
    targetType: TargetType
  ): Observable<void> {
    return this.targetsService.updateTargetSectionDetails(
      targetId,
      name,
      targetType,
      parentNode,
      description
    );
  }

  /**
   * Goes over the row resource count and make sure all the
   * related counts (stub and skeleton) are in sync and up to date.
   * @param row The flex row to check
   * @param isEditing
   * @param alwaysCanViewAll
   * @returns passes back the flex row with updated counts.
   */
  public updateRowLayout(
    row: FlexRow,
    isEditing = false,
    alwaysCanViewAll = false
  ): FlexRow {
    let itemsPerRowCount: number;
    let stubCount = 0;
    const editModeTile = isEditing ? 1 : 0;
    const totalCount = row.targetResources?.length || 0;
    const isOpportunity = row.flexRowType === 'Opportunity';

    // Opportunity rows are only showing 3 cards
    itemsPerRowCount = isOpportunity ? 3 : 4;
    // Calculate the stub count:
    // Ie. number of phantom cards to fill out the row with when there are only 1-3 cards in a row
    // (Opportunities don't need a stub currently, since this row is automatic)
    let sumStubCount: number;
    if (!isOpportunity) {
      sumStubCount =
        itemsPerRowCount - editModeTile - (totalCount % itemsPerRowCount);
    }

    switch (row.section.layoutConfig.displayType) {
      case 'None':
        row.canViewAll = false;
        break;
      // cover Double into the same switch as Single to fallback for rows with old settings
      case 'Single':
      case 'Double':
        row.canViewAll = totalCount > itemsPerRowCount - editModeTile;
        stubCount = sumStubCount;
        break;
      case 'All':
        // current view = all, canViewAll = false (hides button)
        row.isViewAll = true;
        row.canViewAll = alwaysCanViewAll;
        stubCount = sumStubCount;
        break;
    }

    row.stubCount = this.convertCountToArray(stubCount);
    row.skeletonCount = this.convertCountToArray(totalCount);
    row.resourceLimit = this.getRowResourceDisplayLimit(row, isEditing);

    return row;
  }

  public convertCountToArray(count: number): number[] {
    const array: number[] = [];
    for (let i = 0; i < count; i++) {
      array.push(i);
    }
    return array;
  }

  public updateRowResourceCount(row: FlexRow, resourcesCount: number): FlexRow {
    // DisplayType 'All' resourceLimit should actually not be set,
    // we currently allow adding as many resources the user wants.
    if (row.section.layoutConfig.displayType === 'All') {
      row.section.resourceCount = undefined;
    }
    // When transitioning between the expanded and compact, our previous resourceCount
    // will have been undefined...
    else if (!row.section.resourceCount) {
      row.section.resourceCount =
        (row.targetResources?.length ?? 0) + resourcesCount;
    } else {
      row.section.resourceCount += resourcesCount;
    }

    return row;

    // TODO: Old comment:
    // Reload the newly added resources from the server since we need the full data for the items. In addition,
    // the nodes of existing resources might have changed so we reload all items.
    // return this.getRowResources();
  }

  private getRowResourceDisplayLimit(row: FlexRow, isEditing: boolean): number {
    // default resource count should be at least 1
    const totalCount = row.targetResources?.length;
    const isOpportunity = row.flexRowType === 'Opportunity';
    if (row.flexRowType === 'FreeformText' || row.flexRowType === 'LinkText') {
      //  text and link row is considered to have one resource
      return 1;
    } else if (isEditing) {
      if (!row.isViewAll) {
        // if we are editing, and we are not viewing all
        // the resources to show is 3 (+1 from add tile = 4)
        return 3;
      }
      // otherwise we return the count of the current cards to show all
      return totalCount;
    } else if (!row.isViewAll) {
      // if we are not editing but not showing all resources, a row should be total of 4
      return isOpportunity ? 3 : 4;
    }
    // otherwise we return the count of the current cards to show all
    return totalCount;
  }
}
