import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { AssessmentFormModel } from '../assessment-form.model';
import { AssessmentApiEntity } from '../repository/assessment.entity.model';

@Injectable({ providedIn: 'root' })
export class UserProfileAssessmentMapper
  implements Mapper<any, AssessmentApiEntity>
{
  constructor(private datePipe: DatePipe) {}

  public toApiParameters(source: AssessmentFormModel): AssessmentApiEntity {
    const assessmentEntity: AssessmentApiEntity = {
      inputType: 'Assessment',
      name: source.title,
      userProviderName: source.providerName,
      url: source.url,
      description: source.description,
      questions: source.questions,
      questionsCorrect: source.questionsCorrect,
      percentile: parseInt(source.percentile, 10),
      dateCompleted: this.datePipe.transform(
        source.dateCompleted,
        'yyyy-MM-dd'
      ),
      tags: source.tags,

      // edit
      dateCreated: source.dateCreated,
      inputId: source.inputId,
      isCredSpark: source.isCredSpark,
      isImported: source.isImported,
      isVerified: source.isVerified,
      masteryPoints: source.masteryPoints,
      userInputId: source.userInputId,
      userProfileId: source.authUser.viewerProfile.userProfileId,
      userProfileKey: source.authUser.viewerProfile.userProfileKey,
    };

    return assessmentEntity;
  }
  public toViewModel(
    source: AssessmentApiEntity
  ): Partial<AssessmentFormModel> {
    const target: Partial<AssessmentFormModel> = {
      ...source,
      title: source.name,
      providerName: source.userProviderName,
      percentile: source.percentile?.toString(),
    } as any;
    return target;
  }
}
