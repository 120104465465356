import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MAX_SCALE, MIN_SCALE } from '@app/content-hosting/services';

/**
 * Content Hosting Zoom Controls
 * Handle zoom controls for Content Hoting documents such as PDFs
 */
@Component({
  selector: 'dgx-zoom-controls',
  templateUrl: './zoom-controls.component.html',
  styleUrls: ['./zoom-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomControlsComponent {
  @Input() currentScale: number;
  @Input() maxScale = MAX_SCALE;
  @Input() minScale = MIN_SCALE;
  @Output()
  onZoomIn = new EventEmitter<void>();
  @Output()
  onZoomOut = new EventEmitter<void>();

  public getScale(scale: number): number {
    return scale > 0 ? Math.round(scale * 100) : 100;
  }
}
