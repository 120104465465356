import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  filter as _filter,
  find as _find,
  identity as _identity,
  pickBy as _pickBy,
} from 'lodash-es';

// Types
import {
  FacetFilter,
  Filter,
  FilteredFacetValue,
} from '../components/filter/filter.component';
import { BuildFilterParams, Facet, FacetValue } from '../models/core-api.model';
import { SimpleItemViewModel } from '../models/core-view.model';
import { remove } from '../utils';

// Interfaces

/**
 * Used to transform server facets to filters and back again, for the
 * generic filter.component.
 *
 * TODO: Investigate possibility of moving this functionality to a class
 * similar to LearningResourceViewModel.
 */
@Injectable({
  providedIn: 'root',
})
export class FilterService {
  // Local
  private readonly notSpecified = 'Core_NotSpecified';

  constructor(private translate: TranslateService) {}

  /**
   * Transform a single server facet to a single filter. Best utilized
   * when facets may come back from the server in an unexpected configuration
   * or order, such as on search pages where the facets present vary depending
   * on search term.
   *
   * @param facet A facet direct from the server.
   * @param config An optional configuration object.
   */
  public facetToFilter(facet: Facet, config: any = {}): Filter {
    // if we aren't showing filters with no results, first filter out everything with a count > 0
    const facetValues = config.showEmptyOptions
      ? facet.values
      : _filter(facet.values, 'count');
    return this.buildFilter(
      {
        title: facet.name,
        id: facet.id,
        subitems: facetValues.map((value: FacetValue) =>
          this.buildFilterValue(value, config)
        ),
      } as FacetFilter,
      config
    );
  }

  /**
   * Transform server facets to filters. Best utilized when the facets
   * coming back from the server will always be the same number in the
   * same order.
   *
   * @param facets An array of server facets.
   * @param config An array of optional configuration objects.
   */
  public facetsToFilters(facets: Facet[], config: any[] = []): Filter[] {
    return facets.map((facet: Facet, index: number) =>
      this.facetToFilter(facet, config[index])
    );
  }

  /**
   * Transform filters into a very simple array for the server. Can be
   * used in the last step right before sending facets to the server,
   * and will automatically pull filter collections apart into their
   * individual filters.
   *
   * @param filters An array of front-end filters.
   */
  public filtersToFacets(filters: Filter[]): any[] {
    // create a copy here so that we don't accidentally
    // mutate the filters that are being used to display
    // data in place.
    const updatedFilters = [...filters];
    // separate out multi-tiered filters, if any
    const separatedFilters = [];
    // loop through isFilterCollection filters,
    // simultaneously removing them from filters
    // (remove mutates the array it's used on)
    for (const filter of remove(
      updatedFilters,
      (filter) => filter.isFilterCollection
    )) {
      separatedFilters.push(
        // create a new array from the filters
        // attached to each collection
        ...filter.filters.map((subfilter) => ({
          id: subfilter.model.id,
          // complete with a 'new' filters array, containing
          // only the subitems attached to this filter
          filters: [{ subitems: subfilter.subitems }],
        }))
      );
    }
    return _filter(
      // here, re-combine the two arrays, loop through them, and
      // create a *new* array of facets that our server will
      // understand.
      // (The server needs ID/name and a simple array of string values,
      // representing the "name" (ID) of all selected filters.)
      [...updatedFilters, ...separatedFilters].map((filter: Filter) => {
        return {
          id: filter.id,
          name: filter.id,
          values:
            // shorthand for filter.filters[0].subitem.isSelected == TRUE
            _filter(filter.filters[0].subitems, 'isSelected').map(
              (value: SimpleItemViewModel<FacetValue>) => value.model.id
            ),
        };
      }),
      // including *only* those filters with a values array of more than 1
      'values.length'
    );
  }

  private buildFilter(
    filter: FacetFilter,
    // allow *all* these values to fall through
    // to their default values
    {
      id = '',
      title = '',
      canSearch = false,
      checkboxFilterId = '',
      doAutomaticUpdate = false,
      isCheckboxFilter = false,
      isDisabled = false,
      isFilterCollection = false,
      organizationName = '',
      showEmptyOptions = false,
      isSelectionFilter = false,
      ignoreCount = false,
    }: BuildFilterParams = {}
  ): Filter {
    // if it is a checkbox filter, ensure checkboxFilterId is set
    // otherwise, leave it as an empty string
    if (isCheckboxFilter) {
      checkboxFilterId = checkboxFilterId
        ? checkboxFilterId
        : filter.subitems[0].model.id;
    }
    // return an object with only truthy values
    // false, empty strings, and null values will be stripped
    return _pickBy<Filter>(
      {
        // required fields
        id: id ? id : filter.title,
        title: title ? title : this.translate.instant(`Core_${filter.title}`),
        filters: [filter],
        // optional fields
        canSearch,
        checkboxFilterId,
        doAutomaticUpdate,
        isCheckboxFilter,
        isDisabled,
        isFilterCollection,
        organizationName,
        showEmptyOptions,
        isSelectionFilter,
        ignoreCount,
      },
      _identity
    ) as Filter;
  }

  private buildFilterValue(value: FilteredFacetValue, config: any) {
    // Optionally add icon, if included in a possibly-undefined config block
    const icon = _find(config?.icons, ['id', value.id]);
    if (icon) {
      value.icon = icon;
    }
    // _.find(collection, [predicate=_.identity], [fromIndex=0])
    // If empty facets are part of the result, catch them
    if (this.isNotSpecified(value.id)) {
      // Translate the *name*, since it is what is used for display
      // (Normal value names will *not* be translated, since they will
      // be unique to the data set and likely already in the right language.)
      value.name = this.translate.instant(this.notSpecified);
    }
    // Return only truthy values
    return {
      title: value.name,
      model: value,
      isSelected: value.filter,
    };
  }

  private isNotSpecified(id: string): boolean {
    return !id || id === 'None';
  }
}
