export const getUpdatedDuration = (
  durationMinutes: any,
  durationHours: any
): number | string => {
  const hours = !durationHours ? 0 : parseInt(durationHours.toString());
  const minutes = !durationMinutes ? 0 : parseInt(durationMinutes.toString());

  if (hours && minutes) {
    durationHours = hours;
    durationMinutes = minutes;
    return durationHours * 60 + durationMinutes;
  } else if (hours) {
    durationHours = hours;
    return durationHours * 60;
  } else if (minutes) {
    durationMinutes = minutes;
    return durationMinutes;
  }
  return 'None';
};
