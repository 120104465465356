<!-- TODO: Should consider replacing dgx-tags-search with simple-search-suggest component -->
<dgx-tags-search
  class="guts-m-b-half"
  [focusOnInit]="true"
  [hideTopTags]="true"
  [placeholderText]="i18n.OrgSkills_SearchPlaceholder"
  [showHintAlone]="false"
  [useSearchStyle]="true"
  (addTag)="addTag($event)"
></dgx-tags-search>

<div *ngIf="tags.length" class="guts-p-t-3">
  <h2 class="h4 guts-p-b-half border-bottom">
    {{ i18n.OrgSkills_Selected }}
  </h2>
  <ul>
    <li *ngFor="let tag of tags" data-dgat="tag-search-list-f41">
      <div class="l_flexbar border-bottom guts-p-v-1">
        <div class="icon-spot bg-color-ebony-a08 center-item guts-m-h-1">
          <df-icon icon="tag" class="color-ebony-a61"></df-icon>
        </div>
        <div class="l_flex-grow">
          <h3 class="par par--small font-bold">
            {{ tag.title }}
          </h3>
        </div>
        <div>
          <button
            type="button"
            class="dg-modal__close-icon"
            (click)="removeTag(tag)"
            [attr.aria-label]="i18n.Core_Remove"
            [ngbTooltip]="i18n.Core_Remove"
            data-dgat="tag-search-list-6dd"
          >
            <df-icon icon="cross"></df-icon>
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
