import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { DfIconModule } from '@lib/fresco';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * A small component to keep Reactive Forms Labels consistent.
 *
 * Example usage:
 * ```
 * <dgx-reactive-forms-label for="string" [isRequired]="boolean">
 *  {{ i18n.dgOrgInternalContentForm_ArticleFormat }}
 * </dgx-reactive-forms-label>
 * ```
 */
@Component({
  selector: 'dgx-reactive-forms-label',
  standalone: true,
  templateUrl: './reactive-forms-label.component.html',
  imports: [NgbTooltip, DfIconModule, NgIf, NgClass, TranslateModule],
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveFormsLabelComponent {
  /** (Required) Id of the matching input element. <label for="input"> */
  @Input() public for?: string;
  /** (Optional) if the accompanying input is required, then also show the "Required" label */
  @Input() public isRequired?: boolean;
  /** (Optional) override label text for screen readers. */
  @Input() public ariaLabel?: string;
  /** (Optional) if label has an accompanying icon */
  @Input() public icon?: string;
  /** (Optional) Translated string for the icon tool tip. Should be passed if setting an icon. */
  @Input() public iconTooltip?: string;
  /** (Optional) Translated a11y helper text of the tool tip icon. Should be passed if setting an icon. */
  @Input() public iconA11yText?: string;
}
