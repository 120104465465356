import { Inject, Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { LanguageService } from '@app/translation/services/language.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { TrackerService } from '@app/shared/services/tracker.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { DgError } from '@app/shared/models/dg-error';
import { AuthService } from '@app/shared/services/auth.service';
import {
  StaticSettings,
  StaticSettingsResponse,
} from '@app/profile-settings/profile-settings-api.model';
import { WindowToken } from '@app/shared/window.token';
import { LocalizationService } from '@app/shared/localization.service';

@Injectable({
  providedIn: 'root',
})
export class UserStaticSettingsService {
  private readonly getPersonalSettingsURL = '/user/GetPersonalSettings';

  constructor(
    private http: NgxHttpClient,
    private languageService: LanguageService,
    private tracker: TrackerService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private authService: AuthService,
    private localizationService: LocalizationService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public getPersonalSettings(): Observable<StaticSettings> {
    return this.http
      .get<StaticSettingsResponse>(this.getPersonalSettingsURL, {
        cache: true,
      })
      .pipe(
        mergeMap((profile) => {
          // The combine order matters
          return combineLatest([
            of(profile),
            of(this.localizationService.userLocale),
            this.languageService.getContentAndSystemLanguageDetail(
              profile.contentLanguage,
              this.localizationService.userLocale
            ),
            this.languageService.getContentLanguagesDetails(
              profile.additionalContentLanguages
            ),
          ]);
        }),
        map((personalSettings) => {
          const languageDetail = {
            originalLocaleId: personalSettings[1],
            contentLanguage: personalSettings[2].contentLanguage,
            localeId: personalSettings[2].systemLanguage,
            additionalContentLanguages: personalSettings[3],
          };
          return {
            ...personalSettings[0],
            ...languageDetail,
            jobRole: personalSettings[0].jobRole || '',
            privacyId: this.convertPrivacyId(personalSettings[0].privacyId),
          };
        })
      );
  }

  public getPreviousLoginDate(): Observable<any> {
    return this.http.get<any>('/user/GetPreviousLogin');
  }
  public invalidateCache() {
    localStorage.removeItem(this.getPersonalSettingsURL);
    this.authService.clearAuth();
    this.windowRef.location.reload();
  }

  public updateParticipateInTeamProgramSetting(settingValue: boolean) {
    return this.http
      .post('/user/UpdateParticipateInTeamProgramSetting', {
        settingValue,
      })
      .pipe(
        tap(() => {
          this.tracker.trackEventData({
            action: 'Profile Participate In Team Program Setting Updated',
            properties: {
              OptInStatus: settingValue,
            },
          });
        }),
        catchError((error: any) => {
          const errorMessageToTranslate =
            this.getUpdateSettingsErrorTranslation(error);
          return throwError(
            new DgError(this.translate.instant(errorMessageToTranslate), error)
          );
        })
      );
  }

  public updateOptInAISkillInferenceSetting(settingValue: boolean) {
    return this.http
      .post(
        `/user/UpdateOptInAISkillInferenceSetting?optIn=${settingValue}`,
        null
      )
      .pipe(
        tap(() => {
          this.tracker.trackEventData({
            action: 'Participate In AI Skill Inference Setting Updated',
            properties: {
              OptInStatus: settingValue,
            },
          });
        }),
        catchError((error: any) => {
          const errorMessageToTranslate =
            this.getUpdateSettingsErrorTranslation(error);
          return throwError(
            new DgError(this.translate.instant(errorMessageToTranslate), error)
          );
        })
      );
  }

  public updatePersonalSettings(userProfile, invalidateCache = true) {
    const localeId =
      userProfile.localeId?.lcid || userProfile.originalLocaleId || null;
    return this.http
      .put('/user/UpdatePersonalSettings', {
        ...userProfile,
        contentLanguage: userProfile?.contentLanguage?.lcid || null,
        additionalContentLanguages:
          userProfile?.additionalContentLanguages?.map((lang) => lang.lcid),
        localeId,
      })
      .pipe(
        tap(() => {
          this.tracker.trackEventData({
            action: 'Profile Settings Updated',
            properties: {
              UserId: userProfile.UserProfileKey,
            },
          });
          this.notifier.showSuccess(
            this.translate.instant('UserStaticSettingsSvc_Success')
          );
          if (invalidateCache) {
            this.invalidateCache();
          }
        }),
        catchError((error: any) => {
          const errorMessageToTranslate =
            this.getUpdateSettingsErrorTranslation(error);
          const errorMessage = this.translate.instant(errorMessageToTranslate);
          this.notifier.showError(errorMessage);
          return throwError(new DgError(errorMessage, error));
        })
      );
  }

  private getUpdateSettingsErrorTranslation(errorKey: any) {
    switch (errorKey.error) {
      case 'AssociatedPersonalEmail':
        return 'UserStaticSettingsSvc_AssociatedPersonalEmail';
      case 'InvalidProfileUrl':
        return 'UserStaticSettingsSvc_InvalidProfileName';
      case 'DuplicateOrgEmail':
        return 'UserStaticSettingsSvc_DuplicateOrgEmail';
      case 'InvalidPersonalEmail':
        return 'UserStaticSettingsSvc_InvalidPersonalEmail';
      case 'DuplicateProfileUrl':
        return 'UserStaticSettingsSvc_DuplicateProfileName';
      default:
        return 'UserStaticSettingsSvc_Failure';
    }
  }

  /*
   * when the privacyId is a number, return the number.
   * Otherwise, convert the privacyId from string to number -
   */
  private convertPrivacyId(privacyType: string | number) {
    if (typeof privacyType === 'number') return privacyType;

    switch (privacyType) {
      case 'Public':
        return 0;
      case 'OrgVisible':
        return 1;
      case 'Private':
        return 2;
    }
  }
}
