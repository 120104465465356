import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { TranslateService } from '@ngx-translate/core';
import {
  getItemTrackingKey,
  keydownHandler,
} from '../search-initiation-autocomplete.helpers';
import { LDFlagsService } from '@dg/shared-services';

@Component({
  selector: 'dgx-search-initiation',
  templateUrl: './search-initiation.component.html',
  styleUrls: ['./search-initiation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInitiationComponent implements OnInit {
  @Input() public suggestions: ParentItemViewModel<SimpleItemViewModel<any>>[];
  @Input() public shouldShowSuggestions: boolean;
  @Input() public showDefaults: boolean;

  @Output() public skillSelect = new EventEmitter<SimpleItemViewModel>();
  @Output() public filterSelect = new EventEmitter<string>();

  public keydownHandler = keydownHandler;
  public getItemTrackingKey = getItemTrackingKey;
  public i18n = this.translateService.instant([
    'LearningSearch_LookingFor',
    'Core_Skills',
    'Core_Pathways',
    'Core_Plans',
    'Core_Courses',
    'Core_Articles',
    'Core_Podcasts',
    'Core_Books',
    'Core_Events',
    'Core_Videos',
    'Core_AssessmentsDisplayName',
    'Core_Academies',
  ]);
  public filterTypes = [
    { title: this.i18n.Core_Pathways, value: 'pathway' },
    { title: this.i18n.Core_Plans, value: 'target' },
    { title: this.i18n.Core_Courses, value: 'course' },
    { title: this.i18n.Core_Articles, value: 'article' },
    { title: this.i18n.Core_Videos, value: 'video' },
    { title: this.i18n.Core_Podcasts, value: 'episode' },
    { title: this.i18n.Core_Books, value: 'book' },
    { title: this.i18n.Core_Events, value: 'event' },
    { title: this.i18n.Core_AssessmentsDisplayName, value: 'assessment' },
  ];

  constructor(
    private translateService: TranslateService,
    private ldFlagsService: LDFlagsService
  ) {}

  public ngOnInit() {
    if (this.ldFlagsService.showv2Academies) {
      this.filterTypes.splice(2, 0, {
        title: this.i18n.Core_Academies,
        value: 'academy',
      });
    }
  }

  public onSuggestionClick(suggestionVm: SimpleItemViewModel): void {
    this.skillSelect.emit(suggestionVm);
  }

  public onFilterClick(filter: string): void {
    this.filterSelect.emit(filter);
  }
}
