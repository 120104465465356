import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Notification } from '../../notification-api.model';
import { NotificationItem } from './core/notification-item.model';

/** Represents a deprecated notification */
@Component({
  selector: 'dgx-deprecated',
  template: '<div>[{{"Core_Removed" | translate}}]</div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeprecatedComponent implements NotificationItem {
  @Input() public notification: Notification; // placeholder to make binding happy in context of other notifications
}
