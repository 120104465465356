import { WindowToken } from '@app/shared/window.token';
import { Inject, Injectable } from '@angular/core';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';

declare const ENV_CONFIG; // Environmental config from .cshtml

@Injectable({
  providedIn: 'root',
})
export class EbbConfigService {
  // App Info
  public VERSION_NUMBER = '3.0.0';
  public APP_PLATFORM = ENV_CONFIG.APP_PLATFORM;
  public IS_EXT = ENV_CONFIG.IS_EXT;
  public IS_BM = ENV_CONFIG.IS_BM;
  public IS_BTN = ENV_CONFIG.IS_BTN;
  public IS_IE = ENV_CONFIG.IE_SNIFFED;
  public SESSION_ID = ENV_CONFIG.SESSION_ID;

  // Defaults
  public NOTIFICATION_COUNT = 10;
  public RECOMMENDATION_PEOPLE_COUNT = 5;
  public RECOMMENDATION_GROUP_COUNT = 5;
  public DEFAULT_TITLE_ELLIPSIS = 50;
  public MAX_COMMENT_CHAR_COUNT = 1500;

  // Defaults - Search
  public DEFAULT_SEARCH_COUNT = 4;
  public DEFAULT_SEARCH_SKIP = 0;

  // i18n
  public USER_LANGUAGE; // initialized in constructor

  // Storage & OAuth
  public HAS_CHROME_STORAGE = this.isChromeStorageAvailable();
  public TOKEN_KEY = 'degreed_bearer_access';
  public HOST_KEY = 'degreed_host';
  public STORED_TOKEN_NAME = 'AccessToken';

  // Urls
  public ANALYTICS_ENDPOINT = ENV_CONFIG.ANALYTICS_ENDPOINT;
  public CDN_URL = ENV_CONFIG.CDN_URL;
  public BLOB_URL = ENV_CONFIG.BLOB_URL;
  public WEBAPP_BASE_URL = ENV_CONFIG.WEBAPP_BASE_URL;
  public ENDPOINT_BASE = ''; // for ext, this should be same as this.WEBAPP_BASE_URL

  // Images
  public DEFAULT_CARD_IMAGE_WIDTH = 350;
  public DEFAULT_CARD_IMAGE_HEIGHT = 175;
  public FALLBACK_PROFILE_IMAGE_PATH = 'content/img/default-profile.png';

  // Display
  public LOGIN_WINDOW_WIDTH = 400;
  public LOGIN_WINDOW_HEIGHT = 625;

  // Event names
  public EVENT_SENDER = 'degreed';
  public EVENT_LOGGED_IN = 'loggedIn';
  public EVENT_TOKEN_READY = 'dgDash';
  public EVENT_HOST_CHANGE = 'dgHost';
  public EVENT_CHECK_AUTH = 'checkAuth';
  public EVENT_OPEN_LOGIN = 'openLogin';
  public EVENT_GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
  public EVENT_SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
  public EVENT_CLEAR_ACCESS_TOKEN = 'CLEAR_ACCESS_TOKEN';
  public EVENT_CLEAR_SERVICE_HOST = 'CLEAR_SERVICE_HOST';
  public EVENT_CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

  constructor(
    @Inject(WindowToken)
    private windowRef: Window,
    private configurationService: ConfigurationService
  ) {
    this.USER_LANGUAGE = this.getLanguageInfo();
  }

  public get OAUTH_CLIENT_ID(): string {
    return this.configurationService.ebbClientId;;
  }
  public get EXTENSION_ID(): string {
    return this.configurationService.ebbExtensionId;
  }

  private isChromeStorageAvailable() {
    // If there is a global chrome object with storage.sync
    return (
      typeof chrome !== 'undefined' && !!chrome.storage && !!chrome.storage.sync
    );
  }

  private getLanguageInfo() {
    const rtlCodes = ['ar', 'arb', 'az', 'dv', 'fa', 'he', 'ku', 'ur'];
    const lang = this.getFirstBrowserLanguage();
    const isoFull = lang.toLowerCase();
    const isoSimple = lang.toLowerCase().split('-')[0];
    const isRtl = rtlCodes.indexOf(isoSimple) > -1;

    return {
      RTL_CODES: rtlCodes,
      LANGUAGE: lang,
      ISO_FULL: isoFull,
      ISO: isoSimple,
      IS_RTL: isRtl,
      FALLBACK: 'en',
    };
  }

  private getFirstBrowserLanguage() {
    const defaultIso = 'en-us';
    const nav = this.windowRef.navigator;
    const browserLanguagePropertyKeys = [
      'language',
      'browserLanguage',
      'systemLanguage',
      'userLanguage',
    ];
    let language: string = defaultIso;

    // For browsers supporting navigator.languages
    if (Array.isArray(nav.languages)) {
      for (let i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language;
        }
      }
    }

    // For browsers supporting other browser language properties
    for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return language;
  }
}
