import { Component, Input } from '@angular/core';
import { fadeIn } from '@app/shared/animations/animations';
import { UserFamilyAccount } from '../settings-family.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-settings-family-accounts',
  templateUrl: './settings-family-accounts.component.html',
  styleUrls: ['./settings-family-accounts.component.scss'],
  animations: [fadeIn],
})
export class SettingsFamilyAccountsComponent {
  @Input() public accounts: UserFamilyAccount[];
  @Input() public isAdding = false;
  public unknownPassword = this.translateService.instant('Core_AccountClaimed');
  public currentRevealedField: string;

  constructor(private translateService: TranslateService) {}

  public showPassword(fieldName: string): void {
    if (this.currentRevealedField === fieldName) {
      this.currentRevealedField = null;
    } else {
      this.currentRevealedField = fieldName;
    }
  }

  public trackByUserProfileKey(_, account: UserFamilyAccount) {
    return account.userProfileKey;
  }
}
