// Angular
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

// services
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { OptionalNavigationComponent } from '../optional-navigation/optional-navigation.component';

/**
 * Useful as a generic 404 page. Compare with `forbidden`. See `OpportunityNotFound`
 * and `opportunity.ts` (old app) for an example of usage.
 *
 * @example
 * <dgx-not-found
 *  [descriptionText]="'Core_NotFound_DescriptionText' | translate"
 *  [headerText]="'Core_NotFound_HeaderText' | translate"
 *  [navigationText]="'Pathways_BackToBrowse' | translate"
 *  [navigationUrl]="'/'"
 * ></dgx-not-found>
 */
@Component({
  selector: 'dgx-not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [CommonModule, OptionalNavigationComponent],
})
export class NotFoundComponent implements OnInit {
  /** (Optional) Description text. *Must already be translated.* */
  @Input() public descriptionText?: string;
  /** (Optional) Header text. *Must already be translated.* Generic option: `Core_NotFound_DescriptionText`. */
  @Input() public headerText?: string;
  /** Header image for our 404 page. *Defaults to our empty-search-results image.* Generic option: `Core_NotFound_HeaderText`. */
  @Input() public headerImageUrl?: string;
  /** Classes for our header. *Default class sets height.* */
  @Input() public headerImageClasses = 'image-layout--empty';
  /** (Optional) Navigation button text. If absent, button will be hidden. *Must already be translated.*  Generic option: `Pathways_BackToBrowse`. */
  @Input() public navigationText: string;
  /** The URL to navigate to on button click. */
  @Input() public navigationUrl = '/me';

  constructor(private webEnvironmentService: WebEnvironmentService) {}

  public ngOnInit(): void {
    // preserve our defaults in the face of them being left off
    this.headerImageClasses ||= 'image-layout--empty';
    this.navigationUrl ||= '/';
    // if the header image url has not been provided, we use our preferred default
    this.headerImageUrl ||= this.webEnvironmentService.getBlobUrl(
      '/content/img/emptystate/empty-search-results.svg',
      true
    );
  }
}
