import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';
import { constants, TagTypes } from '../models/constants';
import { LDFlagsService } from '@dg/shared-services';

@Injectable()
export class AnalyticsGuard  {
  public readonly isAuthorized: boolean;

  constructor(
    private authService: AuthService,
    private ldFlagsService: LDFlagsService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    const flag = this.ldFlagsService.insights.insightsSkillAnalytics;
    this.isAuthorized = flag ? this.hasFeature : false;
  }

  private get hasFeature(): boolean {
    const authUser = this.authService.authUser;
    const value = authUser?.defaultOrgInfo?.settings
      ?.enableAdvancedSkillAnalytics
      ? authUser.defaultOrgInfo.settings.enableAdvancedSkillAnalytics
      : false;
    return value;
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuthorized();
  }

  public canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkAuthorized();
  }

  /**
   * Return the parent url for Analytics module
   * @param tag Optional param which identifies the source
   * @returns
   */
  public getParentUrl(tag?: TagTypes): string {
    let url = constants.PARENT_URL;
    url += tag ? `?tag=${tag}` : '';
    return url;
  }

  private checkAuthorized(): boolean {
    if (!this.isAuthorized) {
      this.windowRef.location.href = '/Errors/HTTP403Error';
    }
    return this.isAuthorized;
  }
}
