import { Injectable } from '@angular/core';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { ModalService } from '@app/shared/services/modal.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  ContentLoadParams,
  InlineVersionGetter,
  ResourceType,
  ResourceVersionConfig,
} from '../resource-version.model';
import { PathwayVersionService } from './pathway-version.service';
import { TranslateService } from '@ngx-translate/core';
import { LDFlagsService } from '@dg/shared-services';

/**
 *  Service that manages if a resource is using the correct version to prevent users from editing old content.
 */

@Injectable({
  providedIn: 'root',
})
export class ResourceVersionService {
  private i18n = this.translate.instant([
    'Pathways_OutOfDate',
    'Pathways_RefreshForLatestChange',
    'Core_RefreshPage',
  ]);
  public outOfDateText = this.i18n.Pathways_OutOfDate;
  public refreshForLatestText = this.i18n.Pathways_RefreshForLatestChange;
  public getVersion: InlineVersionGetter;

  // Config for resources that will be using the VersionChecker
  private readonly resourceConfig: Record<ResourceType, ResourceVersionConfig> =
    {
      Pathway: {
        service: this.pathwayVersionService,
        i18n: {
          bodyText: this.outOfDateText,
          secondaryText: this.refreshForLatestText,
        },
      },
    };

  constructor(
    private pathwayVersionService: PathwayVersionService,
    private modalService: ModalService,
    private ldFlagsService: LDFlagsService,
    private translate: TranslateService
  ) {}

  /**
   * Used to check if the user is on the most current version with an option to show a refresh modal
   * allowing the user to page refresh the page to get current version
   *
   * @param ContentLoadParams
   * @returns Observable<boolean>
   */
  public isCurrentVersion(options: ContentLoadParams): Observable<boolean> {
    if (!this.lDConcurrentEditing()) {
      return of(true);
    }

    const resourceConfig = this.getResourceConfig(options.resourceType);
    this.getVersion = resourceConfig.service.getVersion.bind(
      resourceConfig.service
    );

    return this.getVersion(options.id).pipe(
      map((version: string) => {
        return version === options.resourceVersion;
      }),
      tap((isCurrentVersion) => {
        if (options.showRefreshModal && !isCurrentVersion)
          this.getVersionRefreshModal(options.resourceType, options.id);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  /**
   * Used to show the version refresh modal
   * @param ResourceType
   * @returns void
   */
  public getVersionRefreshModal(resourceType: ResourceType, id: number) {
    const resource = this.getResourceConfig(resourceType);
    const inputs = {
      canCancel: true,
      bodyClasses: 'h3 guts-p-b-0 center-text',
      bodyText: resource.i18n.bodyText,
      secondaryBodyClasses: 'guts-p-h-4 center-text',
      secondaryBodyText: resource.i18n.secondaryText,
      submitButtonText: this.i18n.Core_RefreshPage,
      isHeaderBorderless: true,
      useDefaultStyles: false,
    };

    this.modalService
      .show<any>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: false,
      })
      .subscribe({
        next: () => {
          resource.service.reloadInformation();
        },
      });
  }

  /**
   * Gets ld flag for Pathway concurrent editing
   * Note: concurrent editing is only allowed with NGX Pathway router
   * @returns boolean
   */
  public lDConcurrentEditing(): boolean {
    return this.ldFlagsService.pathway.concurrentEditingPathways;
  }

  /**
   * Gets the assigned service config based on the resource
   * @param ResourceType
   * @returns ResourceVersionConfig
   */
  private getResourceConfig(type: ResourceType): ResourceVersionConfig {
    return this.resourceConfig[type];
  }
}
