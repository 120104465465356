import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
// Services
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CourseGlobalAddFacade } from '../../services/global-add/course-global-add.facade.service';

// misc
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { CourseModel, CourseTypeId, courseTypes } from '../../course.model';
import {
  onFormControlUpdate,
  markFormAsTouched,
  showError,
  onFormControlReset,
} from '@app/shared/utils/angular-form-field-helpers';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { HTTP_REQUIRED_URL_PATTERN, lazySearch } from '@app/shared/utils';
import { maxFifteenSkillsValidator } from '@app/user-content/user-input-v2/utils/validators';
import {
  NgbActiveModal,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import {
  handleFocusOnSubmit,
  inputFormatter,
} from '@app/user-content/user-input-v2/utils/form-field-helper';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { CourseFormDomainService } from '@app/user-content/user-input/course-form/course-form-domain.service';
import { WindowToken } from '@app/shared/window.token';

@Component({
  selector: 'dgx-course-global-add',
  templateUrl: './course-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for additional styles
  styleUrls: ['../course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseGlobalAddComponent
  extends SubscriberBaseDirective
  implements OnInit, OnDestroy
{
  @Input() public context: InputContext;
  @ViewChild('form') public formRef: ElementRef<HTMLElement>;

  public i18n = this.translate.instant([
    'Core_Comment',
    'Core_Description',
    'Core_GeneralErrorMessage',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Or',
    'Core_Required',
    'Core_Skills',
    'Core_Title',
    'CourseFormCtrl_AddCourse',
    'CourseFormCtrl_Accredited',
    'CourseFormCtrl_CommentPlaceholder',
    'CourseFormCtrl_Country',
    'CourseFormCtrl_CountryPlaceholder',
    'CourseFormCtrl_CourseGrade',
    'CourseFormCtrl_CourseGradePlaceholder',
    'CourseFormCtrl_CourseLevel',
    'CourseFormCtrl_CourseLevelPlaceholder',
    'CourseFormCtrl_CourseNumber',
    'CourseFormCtrl_CourseNumberPlaceholder',
    'CourseFormCtrl_CourseUrl',
    'CourseFormCtrl_CourseUrlAria',
    'CourseFormCtrl_CreditHours',
    'CourseFormCtrl_CreditHoursPlaceholder',
    'CourseFormCtrl_DateCompleted',
    'CourseFormCtrl_DateCompletedPlaceholder',
    'CourseFormCtrl_EditCourse',
    'CourseFormCtrl_HowAdded',
    'CourseFormCtrl_Provider',
    'CourseFormCtrl_ProviderName',
    'CourseFormCtrl_Provider_Placeholder',
    'CourseFormCtrl_SaveCourse',
    'CourseFormCtrl_SchoolName',
    'CourseFormCtrl_SchoolPlaceholder',
    'CourseFormCtrl_SelectCourse',
    'CourseFormCtrl_SelectProvider',
    'CourseFormCtrl_TitlePlaceholder',
    'CourseFormCtrl_UrlOrSelectionRequired',
    'CourseFormCtrl_VerificationUrl',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgOrgInternalContentForm_CommentTooltip',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContentForm_CourseDescriptionPlaceholder',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContent_SkillsTooltipText',
    'MediaFormCtrl_SelectionRequired',
    'MediaFormCtrl_Skills_Placeholder',
    'MediaFormCtrl_TitleRequired',
    'MediaFormCtrl_Title_Placeholder',
    'MediaFormCtrl_ValidContentOwnerRequired',
    'MediaFormCtrl_UrlRequired',
  ]);
  public vm$: Observable<CourseModel>;
  public courseGlobalAddForm: FormGroup;
  public heading: string;
  public sources = courseTypes;
  public isAddingAccreditedCourse: boolean = false;

  // ***************************
  // GETTERS -------------------
  // ***************************

  public get courseTypeId(): CourseTypeId {
    return this.courseGlobalAddForm.get('courseTypeId')?.value;
  }

  public get courseTypeIdHasError(): boolean {
    return (
      this.courseGlobalAddForm.get('courseTypeId')?.dirty &&
      this.courseGlobalAddForm.get('courseTypeId')?.invalid
    );
  }

  /**
   * Change which error message to show based on which error is present.
   * The template checks to make sure there IS an error, so we don't need
   * to do that again here.
   */
  public get ownerErrorMessage(): string {
    return this.courseGlobalAddForm
      .get('owner')
      .hasError('validContentOwnerNeeded')
      ? this.i18n.MediaFormCtrl_ValidContentOwnerRequired
      : '';
  }

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private facade: CourseGlobalAddFacade,
    private formBuilder: FormBuilder,
    private domainService: CourseFormDomainService,
    private activeModal: NgbActiveModal,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public ngOnInit(): Promise<void> {
    this.facade.initializeViewModel(this.context);

    this.heading = this.facade.snapshot.inputContext.isEditing
      ? this.i18n.CourseFormCtrl_EditCourse
      : this.i18n.CourseFormCtrl_AddCourse;

    if (this.facade.snapshot.inputContext.isEditing) {
      // TODO: We're actually phasing out global editing (aka, editing from the user profile)
      // So we should remove the "edit" options from those meatball menus. In the meantime, editMode
      // causes us not to open these new modals.
      return;
    }
    // Setup the view model
    this.initializeForm();
  }

  public ngOnDestroy(): void {
    this.facade.resetSubmissionStatus();
  }

  // ***************************
  // PUBLIC -------------------
  // Form submission actions
  // ***************************

  public async onNext(form: FormGroupDirective) {
    markFormAsTouched(this.courseGlobalAddForm);

    if (this.courseGlobalAddForm.invalid) {
      return;
    }

    await this.facade.onNext(
      this.courseGlobalAddForm.get('courseTypeId').value
    );

    // reset the submitted state on the form
    form.resetForm(this.courseGlobalAddForm.value);

    this.isAddingAccreditedCourse
      ? this.initializeAccreditedForm()
      : this.initializeInformalForm();
  }
  public async onSubmit() {
    markFormAsTouched(this.courseGlobalAddForm);
    if (this.courseGlobalAddForm.invalid) {
      handleFocusOnSubmit(this.formRef.nativeElement);
      return;
    }
    await this.facade.onSubmit(this.courseGlobalAddForm);
    // this.facade.resetSubmissionStatus();
  }

  // ***************************
  // PUBLIC -------------------
  // Event actions from the UI
  // ***************************

  public onFormControlReset(fields: string | string[]): void {
    onFormControlReset(this.courseGlobalAddForm, fields);
  }

  public onFormControlUpdate(
    fields: string | string[],
    values: any | any[]
  ): void {
    onFormControlUpdate(this.courseGlobalAddForm, fields, values);
  }

  public onContentOwnerChange(value?: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  public onCourseGradeChange(value) {
    this.facade.onCourseGradeChange(value);
  }

  public onCourseLevelChange(value) {
    this.facade.onCourseLevelChange(value);
  }
  /**
   * Wrapper for vm.onCourseTypeChange, first sanity-checking to be sure
   * we don't already have this courseTypeId set.
   *
   * @param courseTypeId
   */
  public onCourseTypeChange(courseTypeId: CourseTypeId) {
    // Don't do anything if courseTypeId already set to this ID.
    if (this.courseTypeId === courseTypeId) {
      return;
    }
    // Otherwise, update form.
    this.isAddingAccreditedCourse = courseTypeId === CourseTypeId.Accredited;
    this.onFormControlUpdate('courseTypeId', courseTypeId);
    this.facade.onCourseTypeChange(courseTypeId);
  }

  /**
   * When a search term is entered into the provider input.
   *
   * @param term
   */
  public onProviderSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> => {
    return this.facade.onProviderSearch(term);
  };

  /**
   * When a result is selected from the provider Tyepahead list.
   *
   * @param term
   */
  public async onProviderSelect(
    event: NgbTypeaheadSelectItemEvent<AutocompleteItem>
  ) {
    await this.facade.onProviderSelect(event, this.courseGlobalAddForm);
  }

  /**
   * When the provider input loses focus. We want to set providers here,
   * too, to allow for custom text input.
   *
   * @param term
   */
  public onProviderSet(): void {
    this.facade.onProviderSet(
      this.courseGlobalAddForm.get('institutionName')?.value,
      this.courseGlobalAddForm
    );
  }

  /**
   * When a search term is entered into the course title input.
   *
   * @param term
   */
  public onCourseNameSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> =>
    this.facade.onCourseNameSearch(term);

  /**
   * When a result is selected from the course title Tyepahead list.
   *
   * @param term
   */
  public async onCourseNameSelect(
    event: NgbTypeaheadSelectItemEvent<AutocompleteItem>
  ) {
    await this.facade.onCourseNameSelect(event, this.courseGlobalAddForm);
  }

  /**
   * When the course title input loses focus. We want to set titles here,
   * too, to allow for custom text input.
   *
   * @param target
   */
  public onCourseNameSet(): void {
    this.facade.onCourseNameSet(
      this.courseGlobalAddForm.get('name')?.value,
      this.courseGlobalAddForm
    );
  }

  public onCountrySearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly any[]> => {
    return term.pipe(
      lazySearch((searchTerm: string) =>
        this.domainService.loadCountries(searchTerm)
      )
    );
  };

  public onCountrySelection(event: NgbTypeaheadSelectItemEvent) {
    this.onFormControlUpdate('country', event.item);
    this.facade.onCountrySelection(event.item);
  }

  public onNavigateToCollection(collectionUrl: string) {
    this.activeModal.close(this.facade.snapshot);
    if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }

  // ***************************
  // PUBLIC -------------------
  // Misc
  // ***************************

  /**
   * Formats ngbTypeahead results when selected, so that the form
   * displays the actual *name* of a provider instead of [Object, object].
   * Shell for our default inputFormatter, which has 'label' as its prop,
   * shared by the results we get back for both of our search inputs.
   *
   * @param result
   */
  public inputFormatter(result: AutocompleteItem): string {
    return inputFormatter(result);
  }

  public showError(fieldName: string): boolean {
    return showError(this.courseGlobalAddForm, fieldName);
  }

  // ***************************
  // PRIVATE -------------------
  // Form initializations
  // ***************************

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.courseGlobalAddForm = this.formBuilder.group({
      courseTypeId: ['', [Validators.required]],
    });
  }

  /**
   * Initialize the accredited/formal course form
   */
  private initializeAccreditedForm(): void {
    this.courseGlobalAddForm = this.formBuilder.group({
      country: [],
      institutionName: [], // aka schoolName
      name: ['', [Validators.required, isEmptyValidator]], // aka title
      courseNumber: [],
      creditHours: [],
      courseLevel: [],
      dateCompleted: [],
      courseGrade: [],
      verificationUrl: ['', Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      comment: [],
      owner: [], // aka contentOwner
      tags: ['', maxFifteenSkillsValidator], // aka skills
    });
  }

  /**
   * Initialize the informal course form
   */
  private initializeInformalForm(): void {
    this.courseGlobalAddForm = this.formBuilder.group({
      institutionName: [''], // aka providerName
      name: ['', [Validators.required, isEmptyValidator]], // aka title
      courseUrl: ['', Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      description: [],
      dateCompleted: [],
      verificationUrl: ['', Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      // durationHours & durationMinutes are added to the form via a child component
      owner: [], // aka contentOwner
      image: [],
      tags: ['', maxFifteenSkillsValidator], // aka skills
    });
  }
}
