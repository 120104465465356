import { Injectable } from '@angular/core';
import {
  NormalizedRecommendation,
  NormalizedRecommendationEntity,
  RecommendationForUser,
  RecommendationStatus,
  UserProfileRecommendationsModel,
  UserProfileRecommendationsModelByType,
} from '@app/recommendations/recommendations.api';
import {
  RecommendationsService,
  RecommendationType,
} from '@app/recommendations/services/recommendations.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import {
  UserProfileAssignments,
  UserProfileAssignmentsByType,
} from './profile-assignments-reactive-store';

import { InputsService } from '@app/inputs/services/inputs.service';
import { ProfileActionOptionsConfigService } from '@app/profile/services/profile-action-options-config.service';
import { ProfileFacetsService } from '@app/profile/services/profile-facets.service';
import { TranslateService } from '@ngx-translate/core';
import { share, switchMap } from 'rxjs/operators';
import { ProfileFilter } from '../profile-filter-bar/profile-filter-bar.component';
import { normalizeDateTime } from '@app/reporting/in-app/utils/date-time';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import {
  AssignmentsHttpResponse,
  AssignmentsPaginationResponse,
  AssignmentsResponse,
  SearchAssignmentOptions,
} from '@app/profile/components/profile-assignments/profile-assignments-reactive-store/profile-assignments.model';
import { PAGINATION_LIMIT } from '@app/profile/components/profile-assignments/profile-assignments-reactive-store/utils/profile-assignments.utils';
import { getDaysDifference } from '@app/profile/components/profile-assignments/profile-assignments-reactive-store/utils';

export enum AssignmentStatusEnum {
  OverDue,
  DueSoon,
  DueLater,
}

@Injectable({
  providedIn: 'root',
})
export class ProfileAssignmentService {
  public readonly requiredType = RecommendationType.RequiredLearning;
  public readonly trackingArea = 'Assigned Learning';

  /** Call next on this to refresh profile assignments data */
  public refresh$ = new BehaviorSubject<UserProfileAssignments>(null);

  /** Subscribe to this observable to get the latest assignments
   * and an updated filter object
   */
  public assignments$ = this.refresh$.pipe(
    switchMap(() => this.loadAssignments$)
  );

  /** Subscribe to this observable to get the latest assignments
   * and an updated filter object
   */
  public assignmentsByType$ = this.refresh$.pipe(
    switchMap(() => this.loadAssignmentsByType$)
  );

  public i18n = this.translate.instant(['Core_Type']);

  private loadAssignmentsByType$: Observable<{
    data: UserProfileAssignmentsByType;
    filter: ProfileFilter;
  }> = this.recommendationsService
    .getUserProfileAssignedLearning(RecommendationType.RequiredLearning)
    .pipe(
      take(1),
      map((data) => {
        const { completed, other, required } = this.normalizeDataByType(data);
        // default filter
        const filter = {
          searchTerm: '',
          isActive: false,
          facets: [
            {
              id: 'Type',
              name: this.i18n.Core_Type,
              values: this.profileFacets.contentTypeFacetValues,
            },
          ],
        };

        const allData = [...completed, ...required, ...other];

        filter.facets.forEach((facet) => {
          this.profileFacets.setFacetCounts(facet, allData);
        });

        return {
          data: {
            other,
            required,
            completed,
          },
          filter,
        };
      }),
      share()
    );

  private loadAssignments$: Observable<{
    data: UserProfileAssignments;
    filter: ProfileFilter;
  }> = this.recommendationsService
    .getUserProfileRecommendations(RecommendationType.RequiredLearning)
    .pipe(
      take(1),
      map((data) => {
        const { complete, incomplete, dueSoon, overDue } = this.normalizeData(
          data
        ) as UserProfileRecommendationsModel<NormalizedRecommendation>;
        // default filter
        const filter = {
          searchTerm: '',
          isActive: false,
          facets: [
            {
              id: 'Type',
              name: this.i18n.Core_Type,
              values: this.profileFacets.contentTypeFacetValues,
            },
          ],
        };

        const allData = [...complete, ...incomplete, ...dueSoon, ...overDue];

        filter.facets.forEach((facet) => {
          this.profileFacets.setFacetCounts(facet, allData);
        });

        return {
          data: {
            overDueAndDueSoon: [...overDue, ...dueSoon],
            incomplete: incomplete,
            completed: complete,
          },
          filter,
        };
      }),
      share()
    );

  constructor(
    private profileActionOptionsConfigService: ProfileActionOptionsConfigService,
    private recommendationsService: RecommendationsService,
    private translate: TranslateService,
    private inputsService: InputsService,
    private profileFacets: ProfileFacetsService,
    private http: NgxHttpClient
  ) {
    /** Call next on this to refresh profile assignments data */
    this.refresh$ = new BehaviorSubject<UserProfileAssignments>(null);

    /** Subscribe to this observable to get the latest assignments
     * and an updated filter object
     */
    this.assignments$ = this.refresh$.pipe(
      switchMap(() => this.loadAssignments$)
    );

    /** Subscribe to this observable to get the latest assignments
     * and an updated filter object
     */
    this.assignmentsByType$ = this.refresh$.pipe(
      switchMap(() => this.loadAssignmentsByType$)
    );
  }

  public loadAssignmentsByStatus(
    options: SearchAssignmentOptions
  ): Observable<AssignmentsResponse> {
    const { page, sortBy, sortDir, completed, term } = options;
    return this.http
      .get<AssignmentsHttpResponse>(
        '/recommendations/getUserProfileAssignedLearningByStatus',
        {
          params: {
            // FE is one based paging, BE is 0 based
            page: page - 1,
            count: PAGINATION_LIMIT,
            sortKey: sortBy,
            sortDir,
            completed,
            searchTerm: term,
          },
        }
      )
      .pipe(
        map((response: AssignmentsHttpResponse) => {
          let facets = [];
          let assignments = this.normalizeRecommendationsToEntities(
            response.assignments
          );
          const pagination = this.normalizePagination(response.pagination);
          assignments = this.setIsOverdue(assignments);
          return { pagination, assignments, facets };
        })
      );
  }

  public loadAssignments(
    options: SearchAssignmentOptions
  ): Observable<AssignmentsResponse> {
    const { page, sortBy, sortDir, completed, term, facets } = options;
    return this.http
      .get<AssignmentsHttpResponse>('/recommendations/getUserAssignments', {
        params: {
          // FE is one based paging, BE is 0 based
          page: page - 1,
          count: PAGINATION_LIMIT,
          sortKey: sortBy,
          sortDir,
          completed,
          searchTerm: term,
          facets: JSON.stringify(facets),
        },
      })
      .pipe(
        map((response: AssignmentsHttpResponse) => {
          let facets = response.facets;
          let assignments = this.normalizeRecommendationsToEntities(
            response.assignments
          );
          const pagination = this.normalizePagination(response.pagination);
          assignments = this.setIsOverdue(assignments);
          return { pagination, assignments, facets };
        })
      );
  }

  setIsOverdue(assignments) {
    return assignments.map((assignment) => {
      if (assignment.rawDateDue) {
        const dueDate = assignment.rawDateDue.replace(/-/g, '/');
        if (
          getDaysDifference(new Date(dueDate), new Date()) === 1 &&
          new Date() < new Date(dueDate)
        ) {
          assignment.isOverdue = false;
          assignment.isDueSoon = true;
        }
      }
      return assignment;
    });
  }

  public normalizePagination(
    pagination: AssignmentsPaginationResponse
  ): AssignmentsPaginationResponse {
    // NOTE: This shouldn't be required once PD-87626 is available which returns the pagination using the same keys as the
    // paginated store.
    const { currentPage, perPage, lastPage, total } = pagination;
    return {
      // FE is one based paging, BE is 0 based
      currentPage: currentPage + 1,
      perPage: PAGINATION_LIMIT,
      total,
      lastPage,
    };
  }

  public normalizeRecommendationsToEntities(
    recommendations
  ): NormalizedRecommendationEntity[] {
    return this.recommendationsService
      .normalizeRecommendations(recommendations)
      .map((r: NormalizedRecommendation): NormalizedRecommendationEntity => {
        return {
          ...r,
          id: r.recommendationId.toString(),
        };
      });
  }

  public normalizeDataByType(
    data: UserProfileRecommendationsModel<RecommendationForUser>
  ): UserProfileRecommendationsModelByType<NormalizedRecommendation> {
    const assignmentCatergories = ['required', 'completed', 'other'];
    const normalizedData = {
      required: [],
      completed: [],
      other: [],
      statistics: data.statistics,
    };

    assignmentCatergories.forEach((key) => {
      if (data[key] === undefined) {
        console.warn(`The key ${key} does not exist on ${data}`);
        return;
      }

      // get recommendations that are inputs and setup statistics
      const inputs = data[key]
        .filter((recommendation) => !!recommendation.reference.inputType)
        .map((recommendation) => recommendation.reference);

      this.inputsService.mapInputStatistics(
        inputs,
        data.statistics,
        this.mapInputStatisticsComparer
      );

      // normalize data
      normalizedData[key] =
        this.recommendationsService.normalizeRecommendations(data[key]);
    });

    return normalizedData;
  }

  public normalizeData(
    data: UserProfileRecommendationsModel<RecommendationForUser>
  ): UserProfileRecommendationsModel<NormalizedRecommendation> {
    const assignmentCatergories = [
      'complete',
      'incomplete',
      'dueSoon',
      'overDue',
    ];
    const normalizedData = {
      complete: [],
      incomplete: [],
      dueSoon: [],
      overDue: [],
      statistics: data.statistics,
    };

    assignmentCatergories.forEach((key) => {
      if (data[key] === undefined) {
        console.warn(`The key ${key} does not exist on ${data}`);
        return;
      }

      // get recommendations that are inputs and setup statistics
      const inputs = data[key]
        .filter((recommendation) => !!recommendation.reference.inputType)
        .map((recommendation) => recommendation.reference);

      this.inputsService.mapInputStatistics(
        inputs,
        data.statistics,
        this.mapInputStatisticsComparer
      );

      // normalize data
      normalizedData[key] =
        this.recommendationsService.normalizeRecommendations(data[key]);
    });

    return normalizedData;
  }

  public configureModifyOptionsFn(
    recommendation?: NormalizedRecommendation,
    updateFn?: () => void
  ) {
    return this.profileActionOptionsConfigService.getModifyOptionsFn({
      recommendation,
      processChange: (rec, status) => this.processChange(rec, status, updateFn),
    });
  }

  public processChange(
    recommendation: NormalizedRecommendation,
    status: RecommendationStatus,
    updateFn?: () => void
  ) {
    // Only refresh the data if the status is in this list and will cause the view to change.
    const refreshStatuses = ['Dismissed', 'Complete'];
    return this.recommendationsService
      .updateRecommendationStatus({
        recommendation,
        status,
        item: recommendation.reference,
      })
      .pipe(
        take(1),
        filter(() => refreshStatuses.includes(status))
      )
      .subscribe(() => {
        this.refresh$.next(null);
        if (updateFn) {
          updateFn();
        }
      });
  }

  /**
   * Get assignment status based on dateDue string.
   * @param dateDueStr date string that can be parsed using Date() api
   */
  public getAssignmentStatus(dateDueStr?: string): AssignmentStatusEnum {
    // when there is no dateDue, use DueLate
    if (!dateDueStr) {
      return;
    }

    // make sure we are interrupting the server due date in UTC time
    // remove time from the dateDueStr - it is present when rendered from the inputs razor page
    const dateDue = normalizeDateTime(dateDueStr.split('T')[0], true);

    const currentDate = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

    // compare due date and ignore time
    currentDate.setHours(0, 0, 0, 0);
    thirtyDaysFromNow.setHours(0, 0, 0, 0);

    if (currentDate <= dateDue && thirtyDaysFromNow >= dateDue) {
      return AssignmentStatusEnum.DueSoon;
    } else if (currentDate > dateDue) {
      return AssignmentStatusEnum.OverDue;
    } else {
      return AssignmentStatusEnum.DueLater;
    }
  }

  private updateInput() {
    this.refresh$.next(null);
  }

  private mapInputStatisticsComparer(input, stat) {
    const type = input.inputType || input.referenceType || input.contentType;
    const id = input.inputId || input.referenceId || input.contentId;

    return type === stat.inputType && id === stat.inputId;
  }
}
