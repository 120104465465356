import { Injectable } from '@angular/core';
import { VisibilityOption } from '@app/shared/components/visibility/visibility.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { ModalService } from '@app/shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EditVisibilityModalComponent } from './edit-visibility-modal.component';

export interface VisibilityData {
  privacyId: number;
  opportunityIds?: number[];
  groups?: [
    {
      groupId: number;
      name: string;
      isRestricted: boolean;
    }
  ];
}

@Injectable({
  providedIn: 'root',
})
export class EditVisibilityModalService {
  constructor(
    private modalService: ModalService,
    private translateService: TranslateService
  ) {}

  private get visibilityOptions(): VisibilityOption[] {
    return [
      {
        canExpand: false,
        item: {
          name: this.translateService.instant(
            'dgPathwayPrivacy_CollaboratorProfilePrivate'
          ),
          visibility: Visibility.private,
          level: Visibility.private,
        },
      },
      {
        canExpand: false,
        item: {
          name: this.translateService.instant(
            'dgPathwayPrivacy_CollaboratorProfileVisible'
          ),
          visibility: Visibility.profile,
          level: Visibility.profile,
        },
      },
      {
        canExpand: true,
        item: {
          name: this.translateService.instant(
            'dgPathwayPrivacy_SpecificGroupsVisible'
          ),
          visibility: Visibility.groups,
          level: Visibility.groups,
        },
      },
      {
        canExpand: false,
        item: {
          name: this.translateService.instant(
            'dgPathwayPrivacy_OrgVisibleFormat'
          ),
          visibility: Visibility.public,
          level: Visibility.public,
        },
      },
    ];
  }

  public openModal(options: {
    headerText: string;
    visibility?: VisibilityOption[];
  }): Observable<VisibilityData> {
    return this.modalService.show(EditVisibilityModalComponent, {
      inputs: {
        ...options,
        visibility: options.visibility || this.visibilityOptions,
      },
    });
  }
}
