<div
  #popoverTrigger
  (mouseenter)="togglePopover(true)"
  (mouseleave)="togglePopover(false)"
  (focus)="togglePopover(true)"
  (blur)="togglePopover(false)"
  tabindex="0"
  (keydown)="handleKeydown($event)"
  class="badge badge-pill badge-neutral"
  data-dgat="skill-signal-count-cbf"
  [attr.role]="isButton ? 'button' : null"
  [attr.aria-label]="skillSignalsAriaLabel"
  [ngStyle]="{ cursor: isButton ? 'pointer' : 'default' }"
>
  {{ signalLabel }}
</div>
<dgx-popover
  #signalPopover
  [isOpen]="showPopover"
  (isOpenChange)="onIsPopoverOpenChange(signalPopover)"
  [popoverTrigger]="popoverTriggerRef"
  [focusFirstItem]="false"
  placement="bottom"
  [appendToBody]="true"
>
  <div role="listbox" class="guts-p-full-1-half">
    <div
      role="option"
      class="l_flex m-l_flex l_flex-center-children width-100 guts-p-b-1"
    >
      <span class="par font-medium par--small">{{ i18n.Core_Ratings }}</span>
      <div class="badge badge-pill badge-neutral guts-m-l-auto">
        {{ ratingsCount }}
      </div>
    </div>
    <div
      role="option"
      class="l_flex m-l_flex l_flex-center-children width-100 guts-p-b-1"
    >
      <span class="par font-medium par--small">{{
        i18n.Core_ExperiencesDisplayName
      }}</span>
      <div class="badge badge-pill badge-neutral guts-m-l-auto">
        {{ experiencesCount }}
      </div>
    </div>
    <div
      role="option"
      class="l_flex m-l_flex l_flex-center-children width-100 guts-p-b-1"
    >
      <span class="par font-medium par--small">{{
        i18n.Core_Achievements
      }}</span>
      <div class="badge badge-pill badge-neutral guts-m-l-auto">
        {{ achievementsCount }}
      </div>
    </div>
    <div role="option" class="l_flex m-l_flex l_flex-center-children width-100">
      <span class="par font-medium par--small">{{ i18n.Core_Learnings }}</span>
      <div class="badge badge-pill badge-neutral guts-m-l-auto">
        {{ learningsCount }}
      </div>
    </div>
  </div>
</dgx-popover>
