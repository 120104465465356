import { Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface FamilyTenantOrg {
  tenantOrganizationId: number;
  tenantOrgCode: string;
  tenantOrgName: string;
}

export interface UserFamilyAccount {
  code: string;
  defaultFamilyAccountPassword: string;
  hasPendingRole: boolean;
  organizationId: number;
  organizationName: string;
  userProfileKey: number;
  userName: string;
  lastLogin?: string;
  timeZoneOffset?: number;
}

export interface GetUserFamilyAccountsApi {
  tenantOrgs: FamilyTenantOrg[];
  userFamilyAccounts: UserFamilyAccount[];
}

export interface CreateUserFamilyAccountResult {
  newUserProfileKey: number;
  newUserId: number;
  isNewUser: boolean;
  newUserName: string;
  newUserPassword: string;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsFamilyService {
  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient,
    private notifier: NotifierService
  ) {}

  public getFamilyAccount() {
    return this.http
      .get<GetUserFamilyAccountsApi>('/userprofile/getUserFamilyAccounts')
      .pipe(
        tap((response) => {
          // there aren't any tenant orgs, how did the user get here??
          if (response.tenantOrgs?.length === 0) {
            throwError(
              this.notifier.showError(
                this.translate.instant('User_FamilyAccountGetError')
              )
            );
          }
        }),
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('User_FamilyAccountGetError'),
              error
            )
          )
        )
      );
  }

  public createFamilyAccount(tenantOrgId: number) {
    return this.http
      .post<CreateUserFamilyAccountResult>(
        '/userprofile/createUserFamilyAccount',
        { id: tenantOrgId }
      )
      .pipe(
        tap(() => {
          this.notifier.showSuccess(
            this.translate.instant('User_FamilyAccountCreateSuccess')
          );
        }),
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('User_FamilyAccountCreateError'),
              error
            )
          )
        )
      );
  }
}
