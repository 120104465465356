<dgx-modal
  bodyClasses="guts-p-v-0 guts-p-h-0"
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
  [isHeaderBorderless]="false"
  [isHeaderCentered]="true"
  (dismiss)="dismiss()"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">{{ title | translate }}</ng-container>

  <!-- Modal Body -->
  <div class="modal-body guts-p-v-0 guts-p-h-0">
    <form>
      <div class="grid__col-12 bg-color-ebony-a03">
        <div class="l_flexbar guts-m-t-half guts-m-b-1-half">
          <div class="l_flex-grow">
            <h4 class="h4">{{ currentRatingHeader }}</h4>
          </div>
          <div class="l_flex--align-right" *ngIf="lastRatedDate">
            <span>
              <span class="par par--small par--light"
                >{{ 'dgTagRating_LastRated' | translate }}:</span
              >
              <span class="par par--small font-semibold guts-m-l-half">{{
                lastRatedDate | date: 'mediumDate'
              }}</span>
            </span>
          </div>
        </div>
        <div [ngSwitch]="isEmptyRating()">
          <div *ngSwitchCase="false">
            <div class="left-text par par--small font-semibold guts-m-b-quart">
              {{ currentLevelValue }} - {{ currentLevelName }}
            </div>
            <div class="left-text par par--small">
              {{ currentLevelDescription }}
            </div>
          </div>
          <div *ngSwitchDefault>
            <div class="left-text par par--small font-semibold guts-m-b-quart">
              {{ unratedRatingHeader }}
            </div>
            <div class="left-text par par--small">
              {{ unratedRatingDescription }}
            </div>
          </div>
        </div>
      </div>

      <div class="grid__col-12 guts-p-t-2">
        <div class="l_flexbar guts-m-b-1-half">
          <div class="l_flex-grow" [ngSwitch]="ratingChanged">
            <div *ngSwitchCase="false" class="h4">{{ newRatingHeader }}</div>
            <div *ngSwitchDefault class="h4">{{ updatedRatingHeader }}</div>
          </div>
          <div class="l_flex--align-right">
            <dgx-tag-rating-modal-change-rating
              [currentLevel]="currentRating"
              [defaultText]="dropDownDefaultText"
              (ratingChanged)="updateRating($event)"
            >
            </dgx-tag-rating-modal-change-rating>
          </div>
        </div>
        <div class="l_flexbar guts-m-b-1">
          <div class="l_flex guts-m-r-1">
            <dgx-tag-rating-donut
              size="sm"
              [level]="newLevelValue.toString()"
              class="ib"
            ></dgx-tag-rating-donut>
          </div>
          <div class="l_flex--align-left">
            <h2 class="h2">{{ newLevelName }}</h2>
          </div>
        </div>
        <div class="l_flexbar">
          <div class="par par--small par--light">
            {{ newLevelDescription }}
          </div>
        </div>
        <div *ngIf="targetLevelValue" class="l_flexbar guts-m-t-1-half">
          <span class="badge badge-pill badge-neutral">
            <!-- TODO: get this icon working -->
            <df-icon icon="tag" size="small" class="guts-m-r-quart"></df-icon>
            {{ 'Core_SkillTarget' | translate }}: {{ targetLevelValue
            }}<span *ngIf="targetLevelName"> - {{ targetLevelName }} </span>
          </span>
        </div>
      </div>
      <!-- Description -->
      <div *ngIf="showComments" class="grid__col-12">
        <div class="l_flexbar">
          <label
            for="ratingComment"
            class="par par--small font-medium"
            translate="Core_Description"
          ></label>
          <span class="l_flex--align-right par par--light_a par--small">{{
            'Core_Optional' | translate
          }}</span>
        </div>
        <dgx-character-limit-input
          [limit]="maxMessageLength"
          [text]="comment"
          *ngIf="maxMessageLength; else textareaTemplate"
        >
          <textarea
            name="ratingComment"
            id="ratingComment"
            cols="30"
            rows="10"
            [maxlength]="maxMessageLength"
            [placeholder]="descriptionPlaceholderText"
            [(ngModel)]="comment"
          ></textarea>
        </dgx-character-limit-input>

        <ng-template #textareaTemplate>
          <textarea
            name="ratingComment"
            id="ratingComment"
            cols="30"
            rows="10"
            maxlength="4000"
            [placeholder]="descriptionPlaceholderText"
            [(ngModel)]="comment"
          ></textarea>
        </ng-template>
      </div>
      <div class="grid__col-12 guts-p-b-half"></div>
    </form>
  </div>

  <!-- Modal Footer -->
  <ng-container class="modal-footer" [ngSwitch]="buttonConfig">
    <ng-container *ngSwitchCase="'add'">
      <div class="l_flex--align-right">
        <button
          df-button
          dfButtonType="passive"
          (click)="onSubmit({ level: null })"
        >
          {{ 'dgTagRating_ModalButtonAddWithoutRating' | translate }}
          <!-- Add without rating -->
        </button>
      </div>
      <div>
        <button
          df-button
          dfButtonType="primary"
          class="guts-m-l-1"
          (click)="
            onSubmit({ level: updatedRating.toString(), comment: comment })
          "
        >
          {{ 'dgTagRating_ModalButtonAddWithRating' | translate }}
          <!-- Add with rating -->
        </button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'confirm'">
      <div class="l_flex--align-right">
        <button
          df-button
          dfButtonType="primary"
          class="guts-m-l-1"
          [disabled]="!ratingChanged"
          (click)="
            onSubmit({ level: updatedRating.toString(), comment: comment })
          "
        >
          {{ confirmButtonText }}
        </button>
        <button
          *ngIf="allowClearRating"
          df-button
          dfButtonType="passive"
          (click)="onSubmit({ level: null, comment: comment })"
        >
          {{ clearButtonText }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'multi'">
      <div class="l_flex--align-right">
        <button
          df-button
          *ngIf="!ratingChanged"
          dfButtonType="primary"
          class="guts-m-l-1"
          (click)="onMultiSubmit({ level: null }, false)"
        >
          {{ 'Core_Next' | translate }}
          <!-- Just show 'Next" if rating hasn't changed -->
        </button>
        <button
          df-button
          *ngIf="ratingChanged"
          dfButtonType="primary"
          class="guts-m-l-1"
          (click)="onMultiSubmit({ level: updatedRating.toString() }, false)"
        >
          {{ 'dgTagRating_SubmitNext' | translate }}
          <!-- "Submit & Next" if rating has changed -->
        </button>
        <button
          df-button
          dfButtonType="passive"
          (click)="
            onMultiSubmit(
              { level: ratingChanged ? updatedRating.toString() : null },
              true
            )
          "
        >
          {{ 'dgTagRating_DoneRating' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</dgx-modal>
