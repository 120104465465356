import {
  CollectionFacet,
  CollectionItemType,
  ProfileCollectionItem,
} from '@app/profile/profile-api.model';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import {
  AnyLearningResource,
  LearningResourceViewModel,
} from '@app/inputs/models/learning-resource.view-model';
import {
  BulkUpdateVM,
  CollectionFilterV2,
} from '@app/profile/components/profile-collection/profile-collection-reactive-store';

import { ItemTypes } from '@app/user-content/user-input/user-input.model';

export const getItemIndex = (
  item: ProfileCollectionItem<any>,
  allItems: ProfileCollectionItem<any>[]
): number => {
  return getFirstItemIndex(item, allItems, (item1, item2) => {
    return (
      item1.userItemId === item2.userItemId &&
      item1.contentType === item2.contentType
    );
  });
};

export const getFirstItemIndex = (
  item: ProfileCollectionItem<any>,
  collection: ProfileCollectionItem<any>[],
  comparer: (
    item1: ProfileCollectionItem<any>,
    item2: ProfileCollectionItem<any>
  ) => boolean
): number => {
  let idx = -1;
  let i = 0;
  for (; i < collection.length; i++) {
    if (comparer(item, collection[i])) {
      idx = i;
      break;
    }
  }
  return idx;
};

export const getTotalCount = (values: CollectionItemType[]): number => {
  let total = 0;
  values.forEach((value) => {
    total += value.count;
  });
  return total;
};

const TILE_TYPES = {
  Event: 'input',
  Course: 'input',
  Book: 'input',
  Article: 'input',
  Video: 'input',
  Assessment: 'input',
  Position: 'experience',
  Episode: 'input',
  Task: 'input',
  Degree: 'outcome',
  Skill: 'outcome',
  Accomplishment: 'outcome',
  Badge: 'outcome',
  Award: 'outcome',
  Certificate: 'outcome',
};

export const isOutcomeTile = (type: string): boolean => {
  return TILE_TYPES[type] === ItemTypes.Outcome;
};

export const isExperienceTile = (type: string): boolean => {
  return TILE_TYPES[type] === ItemTypes.Experience;
};

export const isPlanOrPathwayTile = (type: string): boolean => {
  return ['pathway', 'target'].includes(type);
};

export const isInputTile = (type: string): boolean => {
  return (
    !isExperienceTile(type) &&
    !isOutcomeTile(type) &&
    !isPlanOrPathwayTile(type)
  );
};

export const isItemSelected = (
  collectionItem: ProfileCollectionItem<any>,
  selectedItems: ProfileCollectionItem<any>[]
): boolean => {
  if (!selectedItems.length) {
    return false;
  }
  return getItemIndex(collectionItem, selectedItems) > -1;
};

export const getItemTypes = (
  itemTypes: CollectionItemType[]
): {
  inputTypes: CollectionItemType[];
  outcomeTypes: CollectionItemType[];
  experienceTypes: CollectionItemType[];
} => {
  const inputTypes = itemTypes.filter((t) => {
    return t.type === 'Content' && t.name !== 'Position';
  });
  const outcomeTypes = itemTypes.filter((t) => {
    return t.type === 'Achievement' && t.name !== 'Skill';
  });
  const experienceTypes = itemTypes.filter((t) => {
    return t.name === 'Position';
  });
  return { inputTypes, outcomeTypes, experienceTypes };
};

// map from the ids supplied by the BE to more appropriate ids
const FACET_ID_MAP = {
  Category: 'tags',
  Provider: 'providers',
  PathwayStatus: 'pathwayStatus',
  TargetStatus: 'targetStatus',
  ExperienceType: 'experienceType',
  CollaboratorOnly: 'collaboratorOnly',
  AcademyStatus: 'academyStatus',
};

export const getDisplayFacets = (allFacets: CollectionFacet[]) => {
  return _cloneDeep(
    allFacets.reduce((allFacets, facet) => {
      facet.id = FACET_ID_MAP[facet.id] || facet.id;
      allFacets.push(facet);
      return allFacets;
    }, [])
  );
};

export const getItemForModel = (
  model: LearningResourceViewModel,
  items: ProfileCollectionItem<any>[]
): ProfileCollectionItem<any> => {
  const contentModel = model.model as AnyLearningResource;
  return items.find((item) => item.itemId === contentModel.inputId);
};

export const getBulkUpdateEmptyState = (): BulkUpdateVM => {
  return {
    enabled: false,
    selectedItems: [],
    selectedItemsText: '',
    clearSelectionText: '',
    deleteModalHeaderText: '',
    deleteModalBodyText: '',
  };
};

export const getFilterEmptyState = (
  currentItemClassifier: string
): CollectionFilterV2 => {
  return {
    type: [],
    tags: [],
    term: null,
    providers: [],
    startDate: null,
    endDate: null,
    currentItemClassifier,
    pathwayStatus: [],
    targetStatus: [],
    experienceType: [],
    collaboratorOnly: [],
    academyStatus: [],
  };
};

/**
 * Checks if the specified type is a restricted type ie pathway, plans or academies
 * @param type The type to check.
 * @returns True if the type is a restricted type, false otherwise.
 */
export const checkIsRestrictedType = (type: string): boolean => {
  return ['pathways', 'plans', 'academies'].includes(type);
};

/**
 * Helper to get the stringified filter by filterId
 * @param filter
 * @param filterId
 * @param id
 * @returns string of filtered items
 */
export const stringifyFilter = (
  filter: CollectionFilterV2,
  filterId: string,
  id: string
): string => {
  if (filter[filterId] && filter[filterId].length) {
    return JSON.stringify({
      id: id,
      values: filter[filterId],
    });
  }
  return '';
};

export const COLLABORATOR = {
  Id: 'CollaboratorOnly',
  FilterId: 'collaboratorOnly',
};

export const ACADEMY_STATUS = {
  Id: 'AcademyStatus',
  FilterId: 'academyStatus',
};

/**
 * Helper to get the status and collaborator filters for an academy for api request
 * @param filter
 * @returns string of filtered items for academy
 */
export const getAcademyFilters = (filter: CollectionFilterV2): string => {
  // Get filter results for academy and collaborator
  const collaboratorFilterResult = stringifyFilter(
    filter,
    COLLABORATOR.FilterId,
    COLLABORATOR.Id
  );
  const academyFilterResult = stringifyFilter(
    filter,
    ACADEMY_STATUS.FilterId,
    ACADEMY_STATUS.Id
  );

  //Combine results
  const stringify = [collaboratorFilterResult, academyFilterResult]
    .filter((result) => result !== '')
    .join(',');

  return `[${stringify}]`;
};
