import { Injectable } from '@angular/core';
import {
  CropperPosition,
  ImageCroppedEvent,
} from '../../cropper/cropper.model';
import { CropperCoordinates } from '../image-cropper-modal.model';

@Injectable({
  providedIn: 'root',
})
export class ImageCropperModalService {
  /**
   * Get cropper coordinates from the image cropped event.
   * Use this to generate data for the API.
   * @param imageCroppedEvent event emitted from the cropper
   */
  public getCropperCoordinates(
    imageCroppedEvent: ImageCroppedEvent
  ): CropperCoordinates {
    const { cropper, secondaryCropper } = imageCroppedEvent;
    if (!cropper) {
      return;
    }

    let output: CropperCoordinates;
    output = {
      pointX: cropper.imagePosition.x1,
      pointY: cropper.imagePosition.y1,
      width: cropper.imagePosition.x2 - cropper.imagePosition.x1,
      height: cropper.imagePosition.y2 - cropper.imagePosition.y1,
    };
    if (secondaryCropper) {
      output.secondaryPointY = secondaryCropper.imagePosition.y1;
      output.secondaryHeight =
        secondaryCropper.imagePosition.y2 - secondaryCropper.imagePosition.y1;
    }

    return output;
  }

  /**
   * Get cropper position from cropper coordinates.
   * Use this to generate data for the cropper.
   * @param cropperCoordinates cropper coordinates that have been saved
   * @param displayedImageWidth width of the image on screen
   * @param sourceImageWidth width of the original image
   */
  public getCropperPosition(
    cropperCoordinates: CropperCoordinates,
    displayedImageWidth: number,
    sourceImageWidth: number
  ): CropperPosition | null {
    if (!sourceImageWidth || !displayedImageWidth) {
      return;
    }
    const ratio = sourceImageWidth / displayedImageWidth;

    if (cropperCoordinates?.height && cropperCoordinates?.width) {
      // calculate the cropper position using the original image size and
      // the image size on screen.
      const x1 = cropperCoordinates.pointX / ratio;
      const y1 = cropperCoordinates.pointY / ratio;
      return {
        x1,
        y1,
        x2: x1 + cropperCoordinates.width / ratio,
        y2: y1 + cropperCoordinates.height / ratio,
      };
    }
  }
}
