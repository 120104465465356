import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InputSession } from '@app/inputs/inputs-api.model';
import { WindowToken } from '@app/shared/window.token';
import { LiveEventsService } from '../live-events.service';

/**
 * @note
 * Q: Why aren't we using the angular `DatePipe` instead of all of these custom date/time functions?
 * A: Because the browser's locale handling in the Date api is much cleaner and doesn't require extra angular code imported/exported to support. Browser FTW.
 */

@Component({
  selector: 'dgx-live-event-session',
  templateUrl: './live-event-session.component.html',
})
export class LiveEventSessionComponent implements OnInit {
  @Input() public session: InputSession;
  @Output() public trackClick = new EventEmitter<{
    trackAction: string;
    session: InputSession;
  }>();

  public displayStartDate: string;
  public displayEndDate: string;
  public displayStartTime: string;
  public displayEndTime: string;
  public location: string;

  public shouldShowJoinButton: boolean;
  public shouldShowRegistrationLink: boolean;

  private readonly dateFormat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  private readonly startTimeFormat: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  private readonly endTimeFormat: Intl.DateTimeFormatOptions = {
    ...this.startTimeFormat,
    ...{ timeZoneName: 'short' },
  };

  constructor(
    private liveEventsService: LiveEventsService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  private get endDate() {
    if (!this.session.endDateTime) return '';
    const endDate = new Date(this.session.endDateTime).toLocaleDateString(
      this.userLocale,
      this.dateFormat
    );
    return endDate !== this.startDate ? endDate : '';
  }

  private get startTime() {
    if (this.startDate === this.endDate) return '';
    if (!this.session.startDateTime) return '';

    // if there is no end time, show the timezone on the start time.
    const startTimeFormat = !this.endTime
      ? this.endTimeFormat
      : this.startTimeFormat;

    return new Date(this.session.startDateTime).toLocaleTimeString(
      this.userLocale,
      startTimeFormat
    );
  }

  private get endTime() {
    if (!this.session.endDateTime) return '';
    return new Date(this.session.endDateTime).toLocaleTimeString(
      this.userLocale,
      this.endTimeFormat
    );
  }

  private get startDate() {
    if (!this.session.startDateTime) return '';
    return new Date(this.session.startDateTime).toLocaleDateString(
      this.userLocale,
      this.dateFormat
    );
  }

  private get userLocale() {
    const userSettingsLang = this.windowRef?.document.documentElement.lang;
    const browserSettingsLang = this.windowRef?.navigator?.language;
    return userSettingsLang || browserSettingsLang || [];
  }

  private get sessionHasExpired() {
    return this.liveEventsService.isSessionExpired(this.session);
  }

  public ngOnInit() {
    this.displayStartDate = this.startDate;
    this.displayEndDate = this.endDate;
    this.displayStartTime = this.startTime;
    this.displayEndTime = this.endTime;
    this.location = this.liveEventsService.getLocation(this.session, 'long');
    this.shouldShowJoinButton =
      !!this.session.isLive && !!this.session.locationUrl;
    this.shouldShowRegistrationLink =
      !!this.session.registrationUrl &&
      !this.shouldShowJoinButton &&
      !this.sessionHasExpired;
  }

  public openLiveSession() {
    this.trackJoinButton();
    this.windowRef.open(this.session.locationUrl, '_blank');
  }

  public trackRegisterLink() {
    this.trackClick.emit({
      trackAction: 'LiveEventRegistrationClicked',
      session: this.session,
    });
  }

  private trackJoinButton() {
    this.trackClick.emit({
      trackAction: 'JoinSession',
      session: this.session,
    });
  }
}
