import { NgModule } from '@angular/core';

import { PrefixPipe } from './prefix.pipe';
import { DfStringProviderPipe } from './string-provider.pipe';

@NgModule({
  imports: [],
  exports: [DfStringProviderPipe, PrefixPipe],
  declarations: [DfStringProviderPipe, PrefixPipe],
  providers: [],
})
export class DfPipesModule {}
