import { Component, Input } from '@angular/core';

export type SpinnerContext = 'container' | 'button' | 'textInput' | 'video';

@Component({
  selector: 'df-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class DfSpinnerComponent {
  @Input() public spinnerContext: SpinnerContext = 'container';
  @Input() public isSpinning = true;
}
