import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Skill was matched to another skill notification */
@Component({
  selector: 'dgx-skill-matched',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillMatchedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.itemService.translate.instant('Notification_SkillMatched', {
      skillName: this.params.skillName,
      matchedToName: this.params.matchedTo,
    });
  }
}
