import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import {
  FlexRow,
  FlexRowSummary,
  FlexRowType,
  FreeformTextRow,
  LinkTextRow,
} from '@app/flex-framework/flex-api.model';
import { SectionDisplayType } from '@app/flex-framework/flex-section-api.model';
import { ResourceType, TargetType } from '@app/shared/models/core-api.model';
import { ReferenceIdentifier } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { AddEditTargetModal } from '@app/target/components/modals/add-edit-target-modal/add-edit-target-modal.component';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import {
  GetOrgTargetsResponse,
  GetTargetResourceSuggestionsResponse,
  GetTargetResourcesBySectionResponse,
  GetTargetResourcesResponse,
  GetTargetResponse,
  ResourceReference,
  Target,
  TargetSettingsFormMode,
  TargetSuggestionType,
  TitleSuggestion,
} from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/**
 * Intended to house methods for *multiple* targets.
 * See: {@link SharedTargetService}, {@link TargetService}.
 *
 * TODO: Clean this up and straighten out the differences
 * between the services; there are currently methods here
 * that are duplicated in TargetService.
 */
@Injectable({
  providedIn: 'root',
})
export class TargetsService {
  private browseTargetResponse: { targetId: number };
  private stepName: string = '';
  private _viewAllRow?: FlexRow;

  private i18nError = this.translate.instant([
    'Pathways_SearchError', // Sorry! There was a problem retrieving results.
    'TargetsSvc_AddSectionError', // Sorry! There was a problem adding a section.
    'TargetsSvc_DetailsError', // Sorry! We can't load the content. Reload the page to try again.
    'TargetsSvc_FollowError', // Sorry! There was a problem following the plan.
    'TargetsSvc_ReorderResourceError', // Sorry! There was a problem moving the item.
    'TargetsSvc_RetrieveError', // Sorry! There was a problem retrieving the content.
    'TargetsSvc_SetTargetNameError', // Sorry! There's already a plan with the same name. Please choose a different name for this plan.
    'TargetsSvc_SuggestionError', // Sorry! There was a problem retrieving suggestions.
    'TargetsSvc_TargetLevelError', // Sorry! There was a problem setting a target level.
    'TargetsSvc_UnfollowError', // Sorry! There was a problem unfollowing the plan.
    'TargetsSvc_UpdateAuthorsError', // Sorry! There was a problem updating the collaborators.
  ]);

  constructor(
    private authService: AuthService,
    private http: NgxHttpClient,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private sharedTargetService: SharedTargetService,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  // TODO: Remove after solving viewAll link
  public set viewAllRow(row: FlexRow) {
    this._viewAllRow = row;
  }
  public get viewAllRow() {
    return this._viewAllRow;
  }

  /**
   *
   * @param targetId Id of the target
   * @param referenceId id of tag referenced
   * @param parameter level of the rating
   */
  public setTagTargetRating(
    targetId: number,
    referenceId: number,
    parameter: number
  ): Observable<any> {
    return this.http
      .put('/targets/SetTargetResourceParameter', {
        targetId,
        referenceType: 'Tag',
        referenceId,
        parameter,
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_TargetLevelError));
  }

  /**
   * Get the featured tiles for a certain target.
   *
   * @param targetType: TargetSuggestionType Target type to get suggestions for
   */
  public getFeaturedTitles(
    targetType: TargetSuggestionType
  ): Observable<TitleSuggestion[]> {
    return this.http
      .get<TitleSuggestion[]>('/targets/getfeaturedtitles', {
        params: { targetType },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_SuggestionError));
  }

  /**
   * Returns the main Browse/Featured Plan Target
   *
   * @returns
   * This fetches all the relevant Browse/Featured Plan data.
   * Including Name, Description, Image, Sections etc.
   *
   * @param organizationId - Organization ID
   *
   */
  public getBrowseTarget(
    organizationId: number
  ): Observable<{ targetId: number }> {
    if (this.browseTargetResponse) {
      return of(this.browseTargetResponse);
    }

    return this.http
      .get<{ targetId: number }>('/targets/getbrowsetarget', {
        params: {
          organizationId: organizationId,
          useResourceImages: true,
        },
      })
      .pipe(
        tap((response) => (this.browseTargetResponse = response)),
        map((response) => response || of({})),
        catchAndSurfaceError(this.i18nError.TargetsSvc_RetrieveError)
      );
  }

  public clearBrowseTargetCache() {
    this.browseTargetResponse = null;
  }

  // TODO: Rename "getSuggestedPlanTitles"
  public getSuggestedTitles(
    targetType: TargetSuggestionType,
    term: string,
    count: number,
    context?,
    excludeSuggestions?
  ): Observable<TitleSuggestion[]> {
    if (term.length === 0) {
      return of([]);
    }
    return this.http
      .get<TitleSuggestion[]>('/targets/getsuggestedtitles', {
        params: {
          targetType,
          term,
          count,
          context,
          excludeSuggestions: excludeSuggestions
            ? excludeSuggestions.join()
            : null,
        },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_SuggestionError));
  }

  public getTargetResourcesBySection(
    targetId: number,
    sectionNodes: string[],
    skip: number,
    take: number,
    includeStatistics = false
  ): Observable<GetTargetResourcesBySectionResponse> {
    return this.http
      .get<GetTargetResourcesBySectionResponse>(
        '/targets/gettargetresourcesbysection',
        {
          params: {
            targetId,
            sectionNodes: sectionNodes.join(),
            skip,
            take,
            includeStatistics,
            useResourceImages: true,
          },
        }
      )
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_DetailsError));
  }

  /**
   * Adds a flex row to a target.
   *
   * @param targetId - The unique ID of the target.
   * @param title - Defaults to the type of row, ie. Pathway, Plans and Directories, Link Text.
   * @param leftNode - Inserts the row at the top of the Target.
   * @param rightNode - Inserts the row at the bottom of the Target.
   * @param referenceType - The row reference type.
   * @param displayType - The row's display type.
   * @param optionalResource - Optional. Contains the resource if the row is Link Text or Freeform row.
   */
  public addTargetRow(
    targetId: number,
    name: string,
    leftNode: string,
    rightNode: string,
    referenceType: FlexRowType,
    displayType: SectionDisplayType,
    optionalResource?: LinkTextRow | FreeformTextRow,
    resourceImageId?: number
  ): Observable<FlexRowSummary> {
    const params = {
      targetId,
      name,
      leftNode,
      rightNode,
      referenceType,
      layoutConfig: {
        displayType: displayType,
      },
      optionalResource,
      resourceImageId,
    };
    return this.http
      .post<FlexRowSummary>('/targets/addTargetSection', params)
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_AddSectionError));
  }

  public getTargetResources(
    targetId: number,
    sectionNodes,
    skip: number,
    take: number,
    includeStatistics = false
  ): Observable<GetTargetResourcesResponse> {
    return this.http
      .get<GetTargetResourcesResponse>('/targets/gettargetresources', {
        params: {
          targetId,
          sectionNodes: sectionNodes.join(),
          skip,
          take,
          includeStatistics,
        },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_DetailsError));
  }

  public deleteTargetResourceByNode(targetId: number, node: string) {
    return this.http.delete('/targets/deletetargetresourcebynode', {
      params: {
        targetId,
        node,
      },
    });
  }

  public getTargetResourceSuggestions(
    targetName: string,
    organizationId: number,
    targetId: number,
    targetType?: TargetType
  ) {
    return this.http
      .get<GetTargetResourceSuggestionsResponse>(
        '/targets/gettargetresourcesuggestions',
        {
          params: {
            title: targetName,
            orgId: organizationId,
            targetType,
            targetId,
          },
        }
      )
      .pipe(
        catchAndSurfaceError(this.i18nError.TargetsSvc_SuggestionError),
        map((response: any) => {
          const trackEventsData = [];
          const targetTypeForEvent =
            this.sharedTargetService.getTargetTypeForEvents(targetType);

          // TODO: investigate this tracking
          response.forEach((result) => {
            const properties = {
              ...result,
              targetId: targetId,
              title: targetName,
            };
            // properties.category = 'Plans';
            // properties.TargetId = targetId;
            // properties.TargetName = targetName;
            const trackEvent = {
              eventName: targetTypeForEvent + ' Item Suggested',
              properties: properties,
            };
            trackEventsData.push(trackEvent);
          });

          // TODO: move then or add tracker here?
          this.tracker.trackBatchEvents(trackEventsData);
          return response;
        })
      );
  }

  public updateTargetSection(
    section: FlexRowSummary,
    targetType: TargetType,
    optionalResource?: LinkTextRow | FreeformTextRow,
    resourceImageId?: number
  ): Observable<FlexRowSummary> {
    return this.http
      .put('/targets/updateTargetSection', {
        targetId: section.targetId,
        name: section.name,
        node: section.parentNode,
        layoutConfig: { displayType: section.layoutConfig.displayType },
        optionalResource: optionalResource,
        description: section.description || '',
        resourceImageId,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            this.translate.instant('TargetsSvc_UpdateSuccess', {
              targetType:
                this.sharedTargetService.getTypeDisplayName(targetType),
            })
          );
        }),
        catchAndSurfaceError<FlexRowSummary>(
          // Sorry! There was a problem updating the {{targetType}}.
          this.translate.instant('TargetsSvc_UpdateError', {
            targetType: this.sharedTargetService.getTypeDisplayName(targetType),
          })
        ),
        map(() => section)
      );
  }

  /**
   * Get the target sections, passing the sectionNode only fetches
   * that specific section.
   *
   * @param targetId
   * @param targetResourceId
   */
  public getTargetRowSections(
    targetId: number,
    targetResourceId?: number
  ): Observable<FlexRowSummary[]> {
    return this.http
      .get<FlexRowSummary[]>('/targets/getTargetSections', {
        params: {
          targetId,
          targetResourceId,
          useResourceImages: true,
        },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_RetrieveError));
  }

  /**
   * Call to update a given target row, *only* the title and description fields.
   *
   * @param targetId - Section.TargetId
   * @param name - Section.Name
   * @param node - Section.ParentNode
   * @param description - Section.Description
   * @returns Observable<void>
   */
  public updateTargetSectionDetails(
    targetId: number,
    name: string,
    targetType: TargetType,
    node: string,
    description?: string
  ): Observable<void> {
    return this.http
      .put('/targets/updateTargetSectionDetails', {
        targetId,
        name,
        node,
        description,
      })
      .pipe(
        tap(() => {
          this.notifierService.showSuccess(
            // The {{targetType}} was updated successfully!
            this.translate.instant('TargetsSvc_UpdateSuccess', {
              targetType:
                this.sharedTargetService.getTypeDisplayName(targetType),
            })
          );
        }),
        catchAndSurfaceError<void>(
          // Sorry! There was a problem updating the {{targetType}}.
          this.translate.instant('TargetsSvc_UpdateError', {
            targetType: this.sharedTargetService.getTypeDisplayName(targetType),
          })
        )
      );
  }

  public addTargetResources(
    target: Target,
    sectionNode: string,
    resources: ReferenceIdentifier[]
  ): Observable<any> {
    return this.http
      .post('/targets/addtargetresources', {
        targetId: target.targetId,
        sectionNode,
        resources,
      })
      .pipe(
        tap(() => {
          const eventTargetType =
            this.sharedTargetService.getTargetTypeForEvents(target.targetType);
          const trackEvents = [];
          for (const resource of resources) {
            const properties: any = Object.assign({}, resource);
            properties.category = 'Plans';
            properties.TargetId = target.targetId;
            properties.Name = target.name;
            const eventName =
              resource.referenceType + ' Added To ' + eventTargetType;
            const trackEvent = {
              eventName: eventName,
              properties: properties,
            };
            trackEvents.push(trackEvent);
          }
          this.tracker.trackBatchEvents(trackEvents);
        }),
        catchAndSurfaceError(
          // Sorry! There was a problem adding to the {{targetType}}.
          this.translate.instant('TargetsSvc_AddResourceError', {
            targetType: this.sharedTargetService.getTypeDisplayName(
              target.targetType
            ),
          })
        )
      );
  }

  public getTargetSections(targetId: number): Observable<FlexRowSummary[]> {
    return this.http
      .get<FlexRowSummary[]>('/targets/getTargetSections', {
        params: {
          targetId,
        },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_RetrieveError));
  }

  public getPrivacyOptions(
    authUser: AuthUser,
    isNew: boolean,
    groupIds: number[],
    targetObj: Target
  ) {
    const isCurrentCollaborator =
      targetObj.authorKeys &&
      targetObj.authorKeys.indexOf(authUser?.viewerProfile?.userProfileKey) >
        -1;
    const canFullyAuthorThisTarget =
      authUser.canAuthorTargets && (isCurrentCollaborator || isNew);
    const canManageTargets = authUser.canManageTargets;
    const allowToManageThisTarget =
      canFullyAuthorThisTarget || canManageTargets;
    const allowProfileOrGroupVisibility =
      targetObj.targetType !== 'Browse' &&
      targetObj.targetType !== 'Featured' &&
      targetObj.targetType !== 'Directory';

    const permissions = {
      canManageTargets: canManageTargets,
      limitOrgPublish: !allowToManageThisTarget,
    };

    // see ResourcePrivacyLevel Enum
    const optionsList = [
      {
        name: this.translate.instant('Core_PrivacyLevel0'),
        level: 0,
        id: 0, // Author (private)
        enabled:
          targetObj.targetType !== 'Browse' &&
          targetObj.targetType !== 'Featured',
        newTip: this.translate.instant('GroupSettingsCtrl_OpenNewTip'),
        tip: this.translate.instant('GroupSettingsCtrl_OpenTip'),
        canExpand: false,
      },
    ];

    optionsList.push({
      name: this.translate.instant('Core_PrivacyLevel3'),
      level: 1,
      id: 3, // ProfileVisible
      enabled: allowProfileOrGroupVisibility,
      newTip: this.translate.instant('GroupSettingsCtrl_ClosedNewTip'),
      tip: this.translate.instant('GroupSettingsCtrl_ClosedTip'),
      canExpand: false,
    });
    optionsList.push({
      name: this.translate.instant(
        'Core_PrivacyLevel2' + (groupIds.length === 1 ? 'A' : 'B'),
        { groupCount: '' }
      ),
      level: 2,
      id: 2, // Group
      enabled:
        allowProfileOrGroupVisibility &&
        (allowToManageThisTarget ||
          (isCurrentCollaborator && targetObj.privacyId === 2)),
      newTip: this.translate.instant('GroupSettingsCtrl_PrivateNewTip'),
      tip: this.translate.instant('GroupSettingsCtrl_PrivateTip'),
      canExpand: true,
    });
    optionsList.push({
      name: this.translate.instant('Core_PrivacyLevel1'),
      level: 3,
      id: 1, // Public
      enabled:
        allowToManageThisTarget ||
        (isCurrentCollaborator && targetObj.privacyId === 1),
      newTip: this.translate.instant('GroupSettingsCtrl_AdministrativeNewTip'),
      tip: this.translate.instant('GroupSettingsCtrl_AdministrativeTip'),
      canExpand:
        targetObj.targetType !== 'Browse' &&
        targetObj.targetType !== 'Featured',
    });

    return {
      optionList: optionsList,
      permissions: permissions,
    };
  }

  public getOrgTargets(
    term: string,
    skip: number,
    count: number,
    orderBy: string,
    sortDescending: boolean,
    organizationId: number,
    targetTypes?: string
  ): Observable<GetOrgTargetsResponse> {
    return this.http
      .get<any>('/targets/getOrgTargets', {
        params: {
          term,
          skip,
          count,
          orderBy,
          sortDescending,
          organizationId,
          targetTypes,
        },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_RetrieveError));
  }

  // TODO: Rename to more suitable "getUserTargets"?
  public findTargets(userKey?: number) {
    return this.http
      .get<any>('/targets/GetTargetsForUserProfileAsync', {
        params: {
          requestedUserKey: userKey,
          useResourceImages: true,
        },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_RetrieveError));
  }

  /**
   * What is the difference here between showTargetModal (with mode passed in)
   * and showCreateTargetModal / showEditTargetModal?
   */
  public showTargetCreateModal({
    targetDefaults,
    context,
    element,
    isChild,
    initialResource,
    showAutoPopulateOption,
    autoPopulateDefault,
    sourceEventTarget,
    navigateTo,
  }: {
    targetDefaults?: Partial<Target>;
    context?: string;
    element?: HTMLElement;
    isChild?: boolean;
    initialResource?: any;
    showAutoPopulateOption?: boolean;
    autoPopulateDefault?: boolean;
    sourceEventTarget?: any;
    navigateTo?: boolean;
  }) {
    const mode: TargetSettingsFormMode = 'new';
    const resolve = {
      mode: mode,
      targetSource: targetDefaults,
      autoPopulate: autoPopulateDefault,
      context: context || 'default',
      isChild: isChild,
      showAutoPopulateOption: showAutoPopulateOption,
      navigateTo: navigateTo,
    };
    const modalOptions = {
      inputs: {
        targetSource: targetDefaults,
        resolve,
      },
    };
    return this.modalService.show(AddEditTargetModal, modalOptions);
  }

  public reorderTargetSections(params: {
    targetId: number;
    nodes: Record<string, string>;
  }): Observable<any> {
    return this.http
      .put('/targets/reordertargetsections', params)
      .pipe(
        catchAndSurfaceError(this.i18nError.TargetsSvc_ReorderResourceError)
      );
  }

  public removeTargetRow(targetId: number, node: string) {
    return this.http.delete('/targets/deleteTargetSection', {
      params: {
        targetId: targetId,
        node: node,
      },
    });
  }

  /** Composes a localized string key variant based on a Target Resource -related key.
   * For Targets (Plans), the translation will conditionally include a Directory term when career pathing is enabled.
   * Example: "TargetSvc_Create" is converted to "TargetSvc_CreateTargetOrDirectoryPlural" when primaryResourceType = "Target",
   * directories are enabled for this user/org, orSecondary === true and plural === true.
   */
  public composeTargetResourceStringKey({
    baseKey,
    primaryResourceType,
    orSecondary,
    plural,
  }: {
    baseKey: string;
    primaryResourceType: ResourceType;
    orSecondary?: boolean;
    plural?: boolean;
  }): string {
    const authUser = this.authService.authUser;
    const allowDirectoriesInTargets = authUser
      ? authUser.hasCareerPathing && authUser.canManageTargets
      : false;

    let key = baseKey + primaryResourceType;
    if (primaryResourceType === 'Target' && allowDirectoriesInTargets) {
      key += (orSecondary ? 'Or' : 'And') + 'Directory';
    }
    key += plural ? 'Plural' : '';
    return key;
  }

  public getTarget(targetId: number): Observable<GetTargetResponse> {
    return this.http
      .get<GetTargetResponse>('/targets/gettarget', {
        params: { targetId, useResourceImages: true },
      })
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_DetailsError));
  }

  public addTarget(target: Target): Observable<number> {
    // Do not send the orgId to the /addtargets endpoint, the backend
    // will handle this logic
    const { organizationId, ...targetRest } = target;

    return this.http.post('/targets/addtarget', targetRest).pipe(
      tap((response: any) => {
        const targetType = target.targetType || target.type;
        this.notifierService.showSuccess(
          this.translate.instant('TargetsSvc_AddSuccess', {
            targetType: this.sharedTargetService.getTypeDisplayName(targetType),
          })
        );
        const targetProperties: any = { ...target };
        if (response.data) {
          targetProperties.targetId = response.data;
        }
        this.tracker.trackEventData({
          action:
            this.sharedTargetService.getTargetTypeForEvents(targetType) +
            ' Created',
          category: null,
          label: null,
          properties: targetProperties,
        });
      }),
      catchAndSurfaceError<number>((error) =>
        error.status === 409
          ? this.i18nError.TargetsSvc_SetTargetNameError
          : // Sorry! There was a problem adding the {{targetType}}.
            this.translate.instant('TargetsSvc_AddError', {
              targetType: this.sharedTargetService.getTypeDisplayName(
                target.targetType
              ),
            })
      )
    );
  }

  public cloneTarget(target: Target): Observable<number> {
    return this.http.post('/targets/clonetarget', target).pipe(
      tap((response: any) => {
        this.notifierService.showSuccess(
          this.translate.instant('TargetsSvc_CloneSuccess', {
            targetType: this.sharedTargetService.getTypeDisplayName(
              target.targetType
            ),
          })
        );
        const targetProperties = { ...target };
        if (response.data || (!!response && !response.data)) {
          targetProperties.targetId = response.data ? response.data : response;
        }
        this.tracker.trackEventData({
          action:
            this.sharedTargetService.getTargetTypeForEvents(target.targetType) +
            ' Cloned',
          category: null,
          label: null,
          properties: targetProperties,
        });
        return response;
      }),
      catchAndSurfaceError(
        // Sorry! There was a problem cloning the {{targetType}}.
        this.translate.instant('TargetsSvc_CloneError', {
          targetType: this.sharedTargetService.getTypeDisplayName(
            target.targetType
          ),
        })
      )
    );
  }

  public updateTarget(target: Target): Observable<any> {
    return this.http.put('/targets/updatetarget', target).pipe(
      tap(() => {
        this.tracker.trackEventData({
          action:
            this.sharedTargetService.getTargetTypeForEvents(target.targetType) +
            ' Updated',
          properties: target,
        });
        this.notifierService.showSuccess(
          this.translate.instant('TargetsSvc_UpdateSuccess', {
            targetType: this.sharedTargetService.getTypeDisplayName(
              target.targetType
            ),
          })
        );
      }),
      catchAndSurfaceError((error) =>
        error.status === 409
          ? this.i18nError.TargetsSvc_SetTargetNameError
          : // Sorry! There was a problem updating the {{targetType}}.
            this.translate.instant('TargetsSvc_UpdateError', {
              targetType: this.sharedTargetService.getTypeDisplayName(
                target.targetType
              ),
            })
      )
    );
  }

  public updateTargetAuthors(target: Target, userKeys): Observable<any> {
    const params = { TargetId: target.targetId, UserKeys: userKeys };
    return this.http.post('/targets/updatetargetauthors', params).pipe(
      map((response) => {
        this.tracker.trackEventData({
          action:
            this.sharedTargetService.getTargetTypeForEvents(target.targetType) +
            ' Collaborators Edited',
          properties: target,
        });
        return response;
      }),
      catchAndSurfaceError(this.i18nError.TargetsSvc_UpdateAuthorsError)
    );
  }

  public getAuthoredTargets(): Observable<unknown> {
    return this.http
      .get<Target[]>('/targets/getauthoredtargets')
      .pipe(catchAndSurfaceError(this.i18nError.TargetsSvc_RetrieveError));
  }

  /** Specialized search for finding resources to add to the *Mixed Resources* plan section.
   *
   * The search is modified in certain cases on the backend,
   * for instance *prependAuthoredTargets* is added when type-filtering *only* targets and the plan simplification.
   * See PD-82666 for details.
   *
   * This is not intended to be reused!
   */
  public searchMixedResources(params: {
    targetId: number;
    term?: string;
    orgId?: number;
    facets?: any[];
    language?: string;
    exclusionList?: {
      resourceId: number;
      resourceType: ResourceType;
    }[];
  }): Observable<{
    results: any[];
    facets: any[];
    total: number;
  }> {
    // Handle our arrays, keeping them an optional parameter for this method
    const { facets = [], exclusionList = [] } = params;
    if (params.orgId) {
      const internalFacet = {
        id: 'Internal',
        name: 'Internal',
        values: [params.orgId],
      };
      facets.push(internalFacet);
    }
    return this.http
      .get<{
        catalogResults: any[];
        facets: any[];
        mixedResults: any[];
        total: number;
      }>('/targets/SearchMixedResources', {
        params: {
          ...params,
          facets: JSON.stringify(facets),
          exclusionList: JSON.stringify(exclusionList),
        },
      })
      .pipe(
        map(({ catalogResults, facets, mixedResults, total }) => ({
          // massage and combine the arrays from the back-end
          results: [
            ...catalogResults.map((result) => ({
              ...result.reference,
            })),
            ...mixedResults.map((result) => ({
              ...result,
              resourceId: result.referenceId,
              resourceType: result.referenceType,
              imageUrl: result.image,
            })),
          ],
          facets,
          total,
        })),
        catchAndSurfaceError(this.i18nError.Pathways_SearchError)
      );
  }

  public searchTargetResources(searchTargetResourcesParms: {
    TargetId: number;
    ResourceSection: string;
    OrganizationId: number;
    Context: string;
    Term: string;
    ExclusionList: ResourceReference[];
    Skip: number;
    Take: number;
  }): Observable<any[]> {
    return this.http
      .post<any[]>('/targets/searchtargetresources', searchTargetResourcesParms)
      .pipe(catchAndSurfaceError(this.i18nError.Pathways_SearchError));
  }

  /**
   * NOTE: This function was pulled from the RecommendationSvc after talking
   * with @tommy about how the url for tracking links are generated in the
   * backend (InviteUrl).
   *
   * This is pretty much the same concept for generating a target url for tracking.
   *
   * TODO: consider making a more generic service that will format these tracking urls
   *       for pathways, role and skill plans.
   *
   * @param target The target item.
   * @param orgCode The Organization Code.
   * @returns A url for sharing.
   */
  public getShareUrl(target: Target, orgCode?: string): string {
    // targets have InternalUrls and not InviteUrls
    let baseUrl = `https://${window.location.host}${target.internalUrl}`;

    if (orgCode && baseUrl.indexOf('orgsso=') === -1) {
      // NOTE: In a perfect world without IE11 we'd use new URL(..)
      // here to parse baseUrl and correctly add a new query param.
      const sep = baseUrl.indexOf('?') === -1 ? '?' : '&';
      baseUrl += `${sep}orgsso=${encodeURIComponent(orgCode)}`;
    }

    return baseUrl;
  }
}
