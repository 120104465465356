import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Observable } from 'rxjs';

export interface LocalityViewModel {
  locationAddress: string;
  city: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root',
})
export class AddressSuggestService {
  constructor(private http: NgxHttpClient) {}

  public getAddressLocalities(
    addressQuery: string
  ): Observable<LocalityViewModel[]> {
    return this.http.get('/search/getaddresslocalities', {
      params: { addressQuery },
    });
  }
}
