import { NgModule } from '@angular/core';
import { OrgAnnouncementsComponent } from './org-announcements.component';
import { CommonModule } from '@angular/common';
import { DfIconModule } from '@lib/fresco';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OrgAnnouncementsComponent],
  exports: [OrgAnnouncementsComponent],
  imports: [CommonModule, DfIconModule, TranslateModule],
})
export class OrgAnnouncementsModule {}
