import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { coerceBooleanProperty } from '@angular/cdk/coercion';

/** Automatically focuses an element immediately following its insertion into the DOM */
@Directive({
  selector: '[dfAutofocus]',
})
export class DfAutofocusDirective implements AfterViewInit {
  private _autofocus: boolean;

  constructor(private el: ElementRef) {
    if (!el.nativeElement.focus) {
      throw new Error(
        `Element '${el.nativeElement.tagName}' does not accept focus.`
      );
    }
  }

  public get autofocus() {
    return this._autofocus;
  }

  /** Can be used to set a true/false value on the directive or, if valueless, will be
   * coerced to 'true';
   */
  @Input('dfAutofocus')
  public set autofocus(value: any) {
    if (value !== this._autofocus) {
      this._autofocus = coerceBooleanProperty(value); // allow valueless attribute presence to mean 'true'
    }
  }

  public ngAfterViewInit(): void {
    if (this._autofocus) {
      // TODO: Once migration to angular popovers is complete, update this to inject
      // the popover service or transition monitor service (if ported) and listen for
      // the actual popover states instead of crossing our fingers with a timer.
      // need to delay setting focus until popovers are fully closed
      setTimeout(() => {
        const input = this.el.nativeElement as HTMLInputElement;
        input.focus();
        // optionally select the content of the input
        if (input.select) {
          input.select();
        }
      }, 500);
    }
  }
}
