import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { useApolloLayout } from '@degreed/apollo-angular';
import { Loadable } from '@dg/shared-rxjs';

import { NotificationIcon } from '@app/notifications/notification-api.model';
import {
  NotificationService,
  NotificationsState,
} from '@app/notifications/notification.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GlobalNavigationService } from '@app/shared/services/global-navigation.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { TrackerService } from '@dg/shared-services';
@Component({
  selector: 'dgx-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationViewComponent
  extends SubscriberBaseDirective
  implements OnInit, Loadable
{
  public readonly maxTitleLines = 2;
  public readonly maxCommentLines = 2;

  public hasNotifications = true; // assume we initially have some notifications so they get fetched
  public hasRequiredLearning = false;
  public hasMoreLoadableItems = true;
  public requiredLearningPhrase: string;
  public _isLoading = false;

  public get showNewLayout(): boolean {
    return useApolloLayout();
  }

  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(val) {
    this._isLoading = val;
    this.cdr.detectChanges();
  }

  public assignedLearningLink =
    this.notificationService.getAssignedLearningLink();

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    public notificationService: NotificationService,
    private globalNavigationService: GlobalNavigationService,
    private cdr: ChangeDetectorRef,
    private ldFlagsService: LDFlagsService,
    private trackerService: TrackerService
  ) {
    super();
  }

  public ngOnInit() {
    this.trackerService.setLocation('Notifications');
    this.trackerService.trackEventData({ action: 'Notifications List Viewed' });

    this.globalNavigationService.register({
      title: this.translateService.instant('User_Notifications'),
    });

    // This isn't strictly necessary yet, but listening to state changes let's us be reactive to a push scenario
    this.notificationService.notificationsState
      .pipe(this.takeUntilDestroyed())
      .subscribe((state) => this.updateNotificationProperties(state));

    this.notificationService.loadShallowCounts().subscribe(); // load here, react above
    this.titleService.setTitle(
      `${this.translateService.instant(
        'dgNotificationMenu_Notifications'
      )} | Degreed `
    );
  }

  public ngOnDestroy(): void {
    this.globalNavigationService.reset();
  }

  public lookupIcon(type: string): NotificationIcon {
    return this.notificationService.lookupIcon(type);
  }

  private updateNotificationProperties(state: NotificationsState) {
    this.requiredLearningPhrase = state.requiredLearningPhrase;
    this.hasRequiredLearning = state.requiredLearningItemCount > 0;
    this.hasNotifications = state.notifications.length > 0; // This will be updated when the child list loads the actual notifications
    this.hasMoreLoadableItems = state.hasMoreLoadableItems;
  }
}
