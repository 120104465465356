import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Observable } from 'rxjs';
import { UserHasManagers } from '@app/team/team.model';

@Injectable({
  providedIn: 'root',
})
export class ManagerOfManagerService {
  constructor(private http: NgxHttpClient) {}

  public getHasManager(orgId: number): Observable<UserHasManagers> {
    return this.http
      .get<UserHasManagers>('/managers/DoesUserHaveManagers', {
        params: {
          orgId,
        },
      })
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
