import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { ProfilePicFallbackService } from './profile-pic-fallback.service';
import { EngagedLearnerService } from '@app/shared/services/engaged-learner.service';
import { OpportunityPermissionsService } from '@app/opportunities/services/opportunity-permissions.service';
import { isKey, Key } from '@app/shared/key';
import { DfIconSize } from '@lib/fresco';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { Router } from '@angular/router';
import { ModalService } from '@dg/shared-services';

// This file is shared with the bookmarklet/extensions

export const AVATAR_FONT_FAMILY = new InjectionToken<string>(
  'AvatarFontFamily'
);

export enum ProfilePicSize {
  xlarge = 150,
  large = 84,
  medium = 74,
  smallish = 48,
  small = 36,
  smallnav = 34 /* special case for main nav */,
  smaller = 30,
  tinyish = 28 /* special case for social pics */,
  tiny = 24,
}

/**
 * The ProfilePicComponent is the easiest way to add profile pictures to the app. The
 * component also adds a fallback to display the user's initials if no picture is available.
 * Add classname `has_border` to the directive element to add a 2px white border.
 *
 * Options:
 * @param fallbackImage - Url to custom fallback image to use instead of the default service.
 * @param profile - Profile object. Should contain `name` / `userFullName`; `picture` / `userPictureUrl` and `isEngaged` are optional.
 * @param size - Size of profile pic to display. Values: `large` / `medium` / `small` / `tiny`. *Defaults to `small`.*
 * @param useAlt - Whether to use the profile name as alt text, or leave it null. *Defaults to false.*
 * @param hideMentorIcon = Whether the mentor icon should be be hidden, default is false.  (right now social pics and main nav prifle should not display)
 */
@Component({
  selector: 'dgx-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePicComponent implements OnChanges, OnInit {
  // Bindings
  // - input
  @Input() public fallbackImage? = '';
  @Input() public useFallbackClass?: boolean = true; // used for dgx-social-pics
  /**
   * Create a union of all potential user types defined on the FE to be
   * flexible when it comes to usage.
   *
   * Could be either: Profile, UserProfileSummary, UserSearchItem, UserProfile, NotificationPerson, ResourceResult, EngagedLearner;
   *
   * Because of this, use null-safety when referencing `profile` herein since
   * we need to guard against issues we've seen (i.e. infinite errors in CD loops).
   *
   * TODO: This could be handled better and take specific inputs for name, image, vanityUrl etc.
   * much like how the DataColumnPersonComponent does.
   */
  @Input() public profile: {
    name?: string;
    picture?: string;
    email?: string;
    userFullName?: string;
    userPictureUrl?: string;
    pic?: string;
    image?: string; // profiles returned from GET searchtargetresources use image
    isEngaged?: boolean;
    isMentoring?: boolean;
    id?: string | number; // required for EngagedLearner
    UserProfileId?: string;
    UserProfileKey?: string;
    userKey?: string;
    userId?: string;
    vanityUrl?: string;
    firstName?: string;
    lastName?: string;
    title?: string;
  };
  @Input() public shouldRouteToProfile?: boolean = false;
  @Input() public shouldRouteViaParent?: boolean = false;
  @Input() public hideTooltip?: boolean = false;
  @Input() public useAlt?: boolean = false;
  @Input() public size? = 'small';
  @Input() public hideMentorIcon?: boolean = false;
  @Input() public mentorIconSize?: string;
  @Input() public showEngagement?: boolean = true;

  @ViewChild('tooltip')
  public engagedLearnerTooltip: any;

  @ViewChild('profilePic')
  public profilePicEl: ElementRef;

  // Local
  public fallbackLoaded = false;
  public image: string;
  public isEngaged = false;
  public isLoading = true;
  public profileName: string;
  public sizeScalar: number = ProfilePicSize.small;
  public tooltipOpenFromHover: boolean = false;
  public canViewMentorship: boolean = false;
  public baseMentorImageSizeClass = 'profile-pic__mentor-pic--';
  public useLearnerHubFallback = false;

  constructor(
    public engagedLearnerService: EngagedLearnerService,
    private enviroService: WebEnvironmentService,
    private fallbackService: ProfilePicFallbackService,
    private changeDetectorRef: ChangeDetectorRef,
    private opportunityPermissionsService: OpportunityPermissionsService,
    private ldFLagsService: LDFlagsService,
    private router: Router,
    private modalService: ModalService,
    @Inject(AVATAR_FONT_FAMILY) private avatarFontFamily
  ) {}

  // Getters
  public get shouldPreventEngagedLearnerModal(): boolean {
    return (
      !this.isEngaged ||
      this.hideTooltip ||
      !this.engagedLearnerService.isEnabled
    );
  }

  public get tabIndexOfProfilePic(): number {
    return !this.shouldPreventEngagedLearnerModal || this.shouldRouteToProfile
      ? 0
      : -1;
  }

  public get altText() {
    return this.useAlt ? this.profileName : '';
  }

  public get ariaLabelForProfilePic() {
    return this.shouldPreventEngagedLearnerModal && this.shouldRouteToProfile
      ? 'A11y_GoToProfile'
      : undefined;
  }

  /**
   * Get the mentor image size, based off of the size of the profile icon
   */
  public get mentorImageSize(): DfIconSize {
    const fallbackSize = this.mentorIconSize || this.size;

    switch (fallbackSize) {
      case 'small':
      case 'smallnav':
      case 'smaller':
      case 'tinyish':
      case 'tiny':
      case 'smallish':
      case 'medium':
        return 'medium';
      case 'large':
      default:
        return 'large';
    }
  }

  public get isLearnerHub(): boolean {
    return this.ldFLagsService.useLearnerHub;
  }

  private get useFallback() {
    return this.image?.indexOf('default-profile.png') > -1;
  }

  // Methods
  // - angular
  public ngOnInit(): void {
    this.isLoading = true;
    this.sizeScalar = ProfilePicSize[this.size];
    this.profileName = this.profile?.name || this.profile?.userFullName;
    this.image =
      this.profile?.picture ||
      this.profile?.userPictureUrl ||
      this.profile?.pic ||
      this.profile?.image; // profiles returned from GET searchtargetresources use image
    this.isEngaged = !this.showEngagement
      ? false
      : this.profile?.isEngaged || false;
    this.canViewMentorship =
      this.opportunityPermissionsService.canViewMentorshipOptions() &&
      !!this.profile?.isMentoring &&
      !this.hideMentorIcon;
    if (this.useFallback) {
      this.onFallback();
      return;
    }
    // Otherwise, finally show image
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();

    // Immediately fallback on null images
    if (!this.image) {
      this.onFallback();
      return;
    }

    // Convert a relative image path to the full CDN url
    if (this.image?.startsWith('~')) {
      this.image = this.enviroService.getBlobUrl(this.image);
      // Double-check our blob image
      if (this.useFallback) {
        this.onFallback();
        return;
      }
      // Otherwise, show image
      this.isLoading = false;
      return;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.profile) {
      // for changes when adding a new pic in settings
      const profile = changes.profile.currentValue;
      this.image =
        profile?.picture ||
        profile?.userPictureUrl ||
        profile?.pic ||
        profile?.image;
      this.profileName = profile?.name || profile?.userFullName;
      // Convert a relative image path to the full CDN url
      if (this.image?.startsWith('~')) {
        this.image = this.enviroService.getBlobUrl(this.image);
      }
    }
    if (
      this.isLearnerHub &&
      (!this.image || this.image?.includes('default') || this.fallbackLoaded)
    ) {
      this.useLearnerHubFallback = true;
    }
  }

  // - public

  /**
   * Use or set `fallbackImage` value, either on missing user profile pics or errors.
   */
  public onFallback() {
    if (this.fallbackLoaded) {
      return;
    }

    // Only call fallbackService if no fallbackImage provided
    if (!this.fallbackImage) {
      this.fallbackImage = this.fallbackService.getFallbackUrl(
        this.profileName,
        this.sizeScalar as ProfilePicSize,
        this.avatarFontFamily // TODO: consider importing font as variable generated directly from SCSS like we do colors
      );
    }

    // If we're using the fallback image, add a special fallback class so
    // we can tweak the CSS some.
    this.fallbackLoaded = true;
    // Set `isLoading` to false, showing the image.
    this.isLoading = false;
    this.changeDetectorRef.markForCheck();
  }

  public openTooltip() {
    this.tooltipOpenFromHover = true;
    this.engagedLearnerTooltip.open();
  }

  public closeTooltip() {
    this.tooltipOpenFromHover = false;
    this.engagedLearnerTooltip.close();
  }

  public checkHover(event) {
    const cx = event.clientX;
    const cy = event.clientY;

    const profilePicBBox =
      this.profilePicEl.nativeElement.getBoundingClientRect();

    const isOutside =
      cx < profilePicBBox.left ||
      cx > profilePicBBox.left + profilePicBBox.width ||
      cy < profilePicBBox.top - 20 ||
      cy > profilePicBBox.top + profilePicBBox.height;

    if (isOutside && !this.tooltipOpenFromHover) {
      this.closeTooltip();
    }
  }

  public keydownHandler(event: KeyboardEvent): void {
    if (isKey(event, Key.Enter)) {
      event.preventDefault();
      this.onClickOfProfilePic(event);
    }
  }

  /**
   * Either route to the user profile OR Show a modal with user details and information about active status
   */
  public onClickOfProfilePic(event: Event) {
    if (!this.shouldPreventEngagedLearnerModal) {
      this.showEngagedLearnerDetails(event);
    } else if (this.shouldRouteToProfile && !this.shouldRouteViaParent) {
      this.modalService.dismissAll();
      this.router.navigate(['/profile', this.profile.vanityUrl]);
      return;
    }
    return;
  }

  /**
   * Show a modal with user details and information about active status
   */
  public showEngagedLearnerDetails(event: Event) {
    event.stopPropagation();
    const learner = {
      id:
        this.profile.id ||
        this.profile.UserProfileId ||
        this.profile.UserProfileKey ||
        this.profile.userKey ||
        this.profile.userId,
      isEngaged: this.isEngaged,
      name: this.profileName,
      picture: this.image,
    };
    this.engagedLearnerService.showEngagedLearnerDetails(learner);
  }
}
