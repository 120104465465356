import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TagsApi } from '../tag-api.model';

/**
 * Given an array of user tag ratings, return an array of raters/endorsers
 *
 * @param ratings TagsApi.UserTagRatingDetails[]
 * @returns UserProfileSummary[]
 */

@Pipe({
  name: 'tagRatingEndorsers',
})
export class TagRatingEndorsersPipe implements PipeTransform {
  public transform(
    ratings: TagsApi.UserTagRatingDetails[]
  ): UserProfileSummary[] {
    return !!ratings?.length
      ? ratings
          .filter((rating) => !!rating.rater || !!rating.endorser)
          .map((rating) => rating.rater || rating.endorser)
      : [];
  }
}
