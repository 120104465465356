import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';

import { BrandingGuard } from './branding.guard';
import { CommunicationsGuard } from './communications.guard';
import { CredentialsGuard } from './credentials.guard';
import { EditSettingsGuard } from './edit-settings.guard';
import { FaqGuard } from './faq.guard';
import { IntegrationProvidersGuard } from './integration-providers.guard';
import { MessagingGuard } from './messaging.guard';
import { OrgBaseGuard } from './org-base.guard';
import { PermissionsGuard } from './permissions.guard';

@Injectable({
  providedIn: 'root',
})
export class OrgSettingsRedirectGuard extends OrgBaseGuard {
  constructor(
    @Inject(WindowToken) windowRef: Window,
    authService: AuthService,
    private brandingGuard: BrandingGuard,
    private communicationsGuard: CommunicationsGuard,
    private credentialsGuard: CredentialsGuard,
    private editSettingsGuard: EditSettingsGuard,
    private faqGuard: FaqGuard,
    private integrationProvidersGuard: IntegrationProvidersGuard,
    private messagingGuard: MessagingGuard,
    private permissionsGuard: PermissionsGuard,
    protected router: Router
  ) {
    super(authService, windowRef, router);
    this.routeDirectory = '/settings';
  }

  override get hasPermission() {
    return (
      this.brandingGuard.hasPermission ||
      this.editSettingsGuard.hasPermission ||
      this.communicationsGuard.hasPermission ||
      this.faqGuard.hasPermission ||
      this.messagingGuard.hasPermission ||
      this.permissionsGuard.hasPermission ||
      this.integrationProvidersGuard.hasPermission ||
      this.credentialsGuard.hasPermission
    );
  }
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.brandingGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/branding'));
    }
    if (this.editSettingsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/helpMenu'));
    }
    if (this.communicationsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/communications'));
    }
    if (this.faqGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/faq'));
    }
    if (this.messagingGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/messaging'));
    }
    if (this.permissionsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/permissions'));
    }
    if (this.integrationProvidersGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/integrationProviders'));
    }
    if (this.credentialsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/manageCredentials'));
    }

    return this.router.parseUrl('/');
  }
}
