import { Injectable, Injector } from '@angular/core';

import {
  UserEvent,
  UserEventParameters,
  UserExistingEventParameters,
  UpdateUserEventParameters,
} from '@app/inputs/inputs-api.model';
import { UserInputTypeServiceBase } from '@app/inputs/services/user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Events */
@Injectable({
  providedIn: 'root',
})
export class UserEventService extends UserInputTypeServiceBase<
  'Event',
  UserEvent,
  UserEventParameters,
  UserExistingEventParameters,
  UpdateUserEventParameters
> {
  constructor(injector: Injector) {
    super(injector, '', 'Event');
  }
}
