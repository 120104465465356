import { Component, Input } from '@angular/core';
import { InputInfo } from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import {
  PathwayDetailsModel,
  PathwayStep,
} from '@app/pathways/rsm/pathway-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';
import { InputImageUpload } from '@app/uploader/upload-section/adapters/input-image-upload.adapter';
import { UploaderService } from '@app/uploader/uploader.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceImage } from '@app/shared/services/resource-image/resource-image.model';
import { ResourceVersionService } from '@app/resource-version/services/resource-version.service';
import { PathwayFacade } from '@dg/pathways-rsm';
import { NotificationType } from '@lib/fresco';

@Component({
  selector: 'dgx-pathway-step-details-modal',
  templateUrl: './pathway-step-details-modal.component.html',
  styleUrls: ['./pathway-step-details-modal.component.scss'],
})
export class PathwayStepDetailsModalComponent {
  @Input() public pathway: PathwayDetailsModel;
  @Input() public step: PathwayStep;

  public isSaving: boolean = false;
  public originalTitle: string;
  public image: string;
  public imageDisplayUrl: string;
  public resourceImageId: string;
  public inputId: number;
  public originalDescription: string;
  public descriptionReverted = false;
  public originalImage: string;
  public removeImage: boolean;
  public allowSetImage: boolean;
  public uploadAdapter: InputImageUpload;
  public legacyPictureUrl: string;
  public titleMaxLength = 255;
  public readonly i18n = this.translate.instant([
    'Core_Description',
    'Core_EditDetails',
    'Core_RemoveImage',
    'Core_SaveChanges',
    'Core_Title',
    'Pathways_EditingDescription',
    'Pathways_Edited',
    'Pathways_RestoreToOriginal',
    'TaskFormCtrl_AddImage',
    'Core_TitleLength',
    'Core_TitleRequired',
  ]);
  public readonly NotificationType = NotificationType;

  public pathwayStepDetailsModalForm = new FormGroup({
    titleInput: new FormControl(undefined, [
      Validators.required,
      Validators.maxLength(this.titleMaxLength - 1),
    ]),
    descriptionInput: new FormControl(undefined, []),
  });

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private webEnvironmentService: WebEnvironmentService,
    private inputService: InputsService,
    private authService: AuthService,
    private uploadService: UploaderService,
    private resourceVersionService: ResourceVersionService,
    private facade: PathwayFacade
  ) {}

  public get titleInput() {
    return this.pathwayStepDetailsModalForm.get('titleInput');
  }

  public get descriptionInput() {
    return this.pathwayStepDetailsModalForm.get('descriptionInput');
  }

  public get showTitleErrors() {
    return (
      this.titleInput.errors &&
      (this.titleInput.dirty || this.titleInput.touched)
    );
  }

  public get titleErrorMsg() {
    return this.titleInput.errors?.required
      ? this.i18n.Core_TitleRequired
      : this.i18n.Core_TitleLength;
  }

  public get isSubmitDisabled(): boolean {
    return this.isSaving || !this.pathwayStepDetailsModalForm.valid;
  }

  // WHEN title field is

  /**
   * originalTitle = the title of the underlying content item in the catalog.
   */
  public get titleIsEdited() {
    return this.titleInput.value !== this.originalTitle;
  }

  /**
   * originalDescription = the description of the underlying content item in the catalog.
   * Since originalDescription can be undefined, and we don't want to show this button to "restore"
   * a description to empty (users can do that by themselves just fine), we also check for that.
   */
  public get descriptionIsEdited() {
    return (
      this.descriptionInput.value !== this.originalDescription &&
      !!this.originalDescription
    );
  }

  /**
   * TODO: this can be removed when the`dg-pathway-image-edit` is removed
   */
  public get imageIsEdited() {
    let thisImage = '';
    if (this.image) {
      thisImage = this.image.split('?')[0]; // ignore query strings
    }
    // check if image is different
    if (thisImage !== this.originalImage) {
      if (
        // original image was nothing and user just removed image
        (!thisImage && !this.originalImage) ||
        // almost matching except comparing local vs cdn image
        (thisImage &&
          this.originalImage &&
          this.webEnvironmentService.getBlobUrl(this.originalImage))
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  public ngOnInit(): void {
    this.initializeFormFields();
  }

  public initializeFormFields() {
    const thisInputType: InputType =
      this.step.reference.inputType || this.step.reference.resourceType;
    const thisInputId: number =
      this.step.reference.inputId || this.step.reference.resourceId;
    this.uploadAdapter = new InputImageUpload(
      this.uploadService,
      thisInputType,
      thisInputId,
      'Pathway',
      this.pathway.id
    );
    this.inputService
      .getInputInfo(
        { inputType: thisInputType, inputId: thisInputId },
        this.pathway.id
      )
      .subscribe((item: InputInfo) => {
        this.originalTitle = item.title || '';
        this.originalDescription = item.summary || '';
        this.originalImage = item.imageUrl;

        // Check if step values are undefined / null and default empty strings values to step value
        this.titleInput.setValue(this.step.title ?? item.title);
        this.descriptionInput.setValue(this.step.description ?? item.summary);
        this.image = this.step.imageUrl ?? item.imageUrl;

        if (
          typeof this.step.imageUrl === 'undefined' ||
          this.step.imageUrl === null
        ) {
          this.imageDisplayUrl =
            item.imageDisplayUrl ||
            this.webEnvironmentService.getBlobUrl(this.image);
        } else {
          this.imageDisplayUrl =
            this.step.imageDisplayUrl ||
            this.webEnvironmentService.getBlobUrl(this.image);
        }
        this.allowSetImage = this.authService.userCanUploadFiles;
        this.inputId = thisInputId;

        if (!this.image) {
          this.removeImage = true;
        }
      });
  }

  public onImageParsed({ legacyPictureUrl }: ResourceImage) {
    if (legacyPictureUrl) {
      this.legacyPictureUrl = legacyPictureUrl;
    }
  }

  public onImageUploadSuccess({
    resourceImageId,
    pictureUrl,
    legacyPictureUrl,
  }) {
    if (pictureUrl) {
      this.image = pictureUrl;
      this.legacyPictureUrl = legacyPictureUrl;
      this.resourceImageId = resourceImageId;
    }
  }

  public onDeleteImage() {
    this.image = '';
    this.step.imageUrl = '';
    this.legacyPictureUrl = '';
    this.resourceImageId = '';
  }

  public onDismiss(): void {
    this.activeModal.dismiss();
  }

  public revertTitle(): void {
    this.titleInput.setValue(this.originalTitle);
  }

  public revertDescription(): void {
    this.descriptionInput.setValue(this.originalDescription);
    // It's actually fine to only set this to true ONCE, as once the description
    // has been reverted one time, the description will always either be NEW (and
    // thus need saving) or RESTORED (and thus need saving)
    this.descriptionReverted = true;
  }

  public updateDetails(): void {
    this.resourceVersionService
      .isCurrentVersion({
        id: this.facade.snapshot.pathway.id,
        resourceVersion: this.facade.snapshot.version,
        resourceType: 'Pathway',
        showRefreshModal: true,
      })
      .subscribe((isCurrentVersion) => {
        if (!isCurrentVersion) {
          // Just close the details modal
          this.activeModal.close(null);
          return;
        }

        let step = { ...this.step };
        // Title can never be empty and should never be set to undefined. Since reversions
        // update the value of titleInput.value, we can just assume it's correct as long
        // as it's defined, and fall back to the original title when it's not (sanity check).
        step.title = this.titleInput.value ?? this.originalTitle;
        step.description =
          // Can't use descriptionIsEdited here, because *it* will be false to hide
          // the revert button when the description was originally empty, but we definitely
          // want to let those changes be saved!
          this.descriptionInput.value !== this.originalDescription ||
          this.descriptionReverted
            ? this.descriptionInput.value
            : undefined;
        if (this.legacyPictureUrl) {
          step.imageUrl = this.legacyPictureUrl;
        } else {
          step.imageUrl =
            this.image !== this.originalImage ? this.image : undefined;
        }
        step.resourceImageId = this.resourceImageId
          ? this.resourceImageId
          : undefined;

        const updatedNode = {
          step: {
            title: step.title,
            description: step.description,
            imageUrl: step.imageUrl,
            resourceImageId: step.resourceImageId,
          },
        };
        this.activeModal.close(updatedNode.step);
      });
  }
}
