import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { SearchFilterComponent } from '@app/search/components/search-filter/search-filter.component';
import { SearchFilter, SearchFilterFilter } from '@app/search/search-api.model';
import { SearchFlagsService } from '@app/search/services/search-flags.service';
import {
  SearchSubmitMethod,
  SearchTrackerService,
} from '@app/search/services/search-tracker.service';
import { DfIconCross16, DfIconRegistry } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

export enum SearchFilterId {
  Type = 'Type',
  Provider = 'Provider',
  Duration = 'Duration',
  Language = 'Language',
  Endorsed = 'Endorsed',
  Mentors = 'Mentors',
  ActiveLearners = 'ActiveLearners',
  MoreFilters = 'MoreFilters',
  MinCost = 'MinCost',
  MaxCost = 'MaxCost',
  // TODO: remove these with search-global-filters-date-location-sessionType flag removal
  Sessions = 'Sessions',
  SessionType = 'SessionType',
  SessionLocation = 'SessionLocation',
  SessionDateRange = 'SessionDateRange',
}

interface BuildSearchFilters {
  typeSearchFilter?: SearchFilter;
  providerSearchFilter?: SearchFilter;
  durationSearchFilter?: SearchFilter;
  endorsedContentSearchFilter?: SearchFilter;
  mentorsSearchFilter?: SearchFilter;
  activeLearnersSearchFilter?: SearchFilter;
  languageSearchFilter?: SearchFilter;
  sessionSearchFilters?: {
    id: SearchFilterId.Sessions;
    title: `Core_Sessions`;
    isFilterCollection: true;
    filters: SearchFilterFilter[];
  };
  defaultSearchFilters?: Map<string, SearchFilter>;
  nonDefaultFilters?: {
    id: SearchFilterId.MoreFilters;
    title: `LearningSearch_MoreFilters`;
    isFilterCollection: true;
    filters: SearchFilterFilter[];
  };
}

@Component({
  selector: 'dgx-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
})
export class SearchFiltersComponent {
  @Input() public isLoading: boolean;
  @Input() public isClientProvider: boolean;
  @Input() public organizationName: string;
  @Input() public defaultFilterIds?: string[];
  @Input() public filterIdsToIgnore?: string[] = [];
  @Input() public numberOfSkeletonColumns = 5;
  // whether the parent component uses the global search api `api/search/findlearningresources`
  @Input() public usesGlobalSearchApi: boolean = false;
  // When true, the 'reset filters' button will display and can be used to clear the search term as well as the filters.
  @Input() public hasSearchTerm: boolean = false;
  @Input() public showCount: boolean = true;
  @Output() public filtersChange = new EventEmitter<SearchFilterFilter[]>();
  @Output() public clearSearchTerm = new EventEmitter<void>();

  public searchFilters = new Map<string, SearchFilter>();
  public i18n = this.translateService.instant(['Core_ResetFilters']);

  @ViewChildren(SearchFilterComponent)
  private filterComponents: QueryList<SearchFilterComponent>;

  constructor(
    private iconRegistry: DfIconRegistry,
    private translateService: TranslateService,
    private searchTrackerService: SearchTrackerService,
    private searchFlagsService: SearchFlagsService
  ) {
    this.iconRegistry.registerIcons([DfIconCross16]);
  }

  @Input() public set filters(filters: SearchFilterFilter[]) {
    if (!filters || filters.length === 0) {
      return;
    }

    this.buildFilterModel(filters);
  }

  public get filters(): SearchFilterFilter[] {
    let filters = [];
    [...this.searchFilters.values()].forEach(
      (searchFilter) => (filters = [...filters, ...searchFilter?.filters])
    );
    return filters;
  }

  public get hasActiveFilter(): boolean {
    return this.filterComponents?.some((filter) => filter.isPopoverOpen);
  }

  public get selectedFilterTypes(): string[] {
    if (!this.filters) {
      return [];
    }

    return this.filters.reduce((filters, filter) => {
      const hasSelected = filter.subitems?.some(({ isSelected }) => isSelected);
      return hasSelected ? [...filters, filter.title] : filters;
    }, []);
  }

  public get showResetFilters(): boolean {
    return Boolean(this.selectedFilterTypes.length) || this.hasSearchTerm;
  }

  // Preserve original property order for keyvalue pipe
  public originalOrder(
    _: KeyValue<string, SearchFilter>,
    __: KeyValue<string, SearchFilter>
  ): number {
    return 0;
  }

  public applyFilters(searchFilter: SearchFilter): void {
    // Clear all except the Type filter when the Type filter changes
    if (searchFilter.id === SearchFilterId.Type) {
      this.clearSelectedFilterItems(true);
    }

    this.updateSearchFilters(searchFilter);
    this.filtersChange.emit(this.filters);
  }

  public clearFilters(): void {
    this.clearSelectedFilterItems();

    this.filtersChange.emit(this.filters);
    if (this.hasSearchTerm) {
      this.clearSearchTerm.emit();
    }
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.clearFilters,
    });
  }

  private buildDefaultSearchFilter(filter: SearchFilterFilter): SearchFilter {
    let translationTitle = filter.title;

    // Core_JobRole translates to Plan, so use Core_JobRoleLiteral instead which translates to Job Role
    if (translationTitle === 'JobRole') {
      translationTitle = 'JobRoleLiteral';
    } else {
      translationTitle = filter?.model?.id
        ? filter.model.id.charAt(0).toUpperCase() + filter.model.id.slice(1)
        : '';
    }

    return {
      id: filter?.model?.id,
      title: filter.config?.title || `Core_${translationTitle}`,
      filters: [filter],
      isSegmentedFilter: !!filter?.config?.isSegmentedFilter,
      isCheckboxFilter: !!filter?.config?.isCheckboxFilter,
      checkboxFilterId:
        (!!filter?.config?.isCheckboxFilter && filter.subitems[0]?.model.id) ||
        undefined,
      canSearch: filter?.config?.canSearch ?? false,
      segments: filter?.config?.segments ?? [],
    };
  }

  private updateSearchFilters(searchFilter: SearchFilter): void {
    if (this.searchFilters.has(searchFilter.id)) {
      this.searchFilters.set(searchFilter.id, searchFilter);
    }
  }

  private setSearchFilters(searchFilters: BuildSearchFilters): void {
    const {
      typeSearchFilter,
      providerSearchFilter,
      durationSearchFilter,
      languageSearchFilter,
      endorsedContentSearchFilter,
      mentorsSearchFilter,
      activeLearnersSearchFilter,
      sessionSearchFilters,
      defaultSearchFilters,
      nonDefaultFilters,
    } = searchFilters;

    // Add to the map in this order to ensure they are properly displayed in order
    if (typeSearchFilter) {
      this.searchFilters.set(SearchFilterId.Type, typeSearchFilter);
    }
    if (providerSearchFilter) {
      this.searchFilters.set(SearchFilterId.Provider, providerSearchFilter);
    }
    if (durationSearchFilter) {
      this.searchFilters.set(SearchFilterId.Duration, durationSearchFilter);
    }
    if (languageSearchFilter) {
      this.searchFilters.set(SearchFilterId.Language, languageSearchFilter);
    }
    if (endorsedContentSearchFilter) {
      this.searchFilters.set(
        SearchFilterId.Endorsed,
        endorsedContentSearchFilter
      );
    }

    // Add to the map in the order specified in the input to ensure they are properly displayed in order
    this.defaultFilterIds?.forEach((id) => {
      this.searchFilters.set(id, defaultSearchFilters.get(id));
    });

    if (sessionSearchFilters.filters.length > 0) {
      this.searchFilters.set(SearchFilterId.Sessions, sessionSearchFilters);
    }

    if (nonDefaultFilters.filters.length > 0) {
      this.searchFilters.set(SearchFilterId.MoreFilters, nonDefaultFilters);
    }

    if (mentorsSearchFilter) {
      this.searchFilters.set(SearchFilterId.Mentors, mentorsSearchFilter);
    }

    if (activeLearnersSearchFilter) {
      this.searchFilters.set(
        SearchFilterId.ActiveLearners,
        activeLearnersSearchFilter
      );
    }
  }

  private buildFilterModel(filters: SearchFilterFilter[]): void {
    const searchFilters: BuildSearchFilters = {};

    this.searchFilters.clear();

    searchFilters.defaultSearchFilters = new Map<string, SearchFilter>();
    searchFilters.nonDefaultFilters = {
      id: SearchFilterId.MoreFilters,
      title: `LearningSearch_MoreFilters`,
      isFilterCollection: true,
      filters: [],
    };
    searchFilters.sessionSearchFilters = {
      id: SearchFilterId.Sessions,
      title: `Core_Sessions`,
      isFilterCollection: true,
      filters: [],
    };

    filters
      .filter((filter) => !this.filterIdsToIgnore?.includes(filter.model.id))
      .forEach((filter) => {
        const filterId = filter.model.id;

        switch (filterId) {
          case SearchFilterId.SessionType:
          case SearchFilterId.SessionLocation:
          case SearchFilterId.SessionDateRange:
            searchFilters.sessionSearchFilters.filters.push(filter);
            break;
          case SearchFilterId.Type:
            if (!searchFilters.typeSearchFilter) {
              searchFilters.typeSearchFilter =
                this.buildDefaultSearchFilter(filter);
            }
            break;
          case SearchFilterId.Provider:
            if (!this.isClientProvider) {
              searchFilters.providerSearchFilter =
                this.buildDefaultSearchFilter(filter);
            }
            break;
          case SearchFilterId.Duration:
            searchFilters.durationSearchFilter =
              this.buildDefaultSearchFilter(filter);
            break;
          case SearchFilterId.Endorsed:
            if (this.organizationName) {
              searchFilters.endorsedContentSearchFilter = {
                id: SearchFilterId.Endorsed,
                title: 'Core_EndorsedByOrganization',
                isCheckboxFilter: true,
                checkboxFilterId: filter.subitems[0]?.model.id,
                organizationName: this.organizationName,
                filters: [filter],
              };
            }
            break;
          case SearchFilterId.Mentors:
            searchFilters.mentorsSearchFilter = {
              id: SearchFilterId.Mentors,
              title: 'Core_Mentors',
              isCheckboxFilter: true,
              checkboxFilterId: filter.subitems[0]?.model.id,
              filters: [filter],
            };
            break;
          case SearchFilterId.ActiveLearners:
            searchFilters.activeLearnersSearchFilter = {
              id: SearchFilterId.ActiveLearners,
              title: 'Core_ActiveLearners',
              isCheckboxFilter: true,
              checkboxFilterId: filter.subitems[0]?.model.id,
              filters: [filter],
            };
            break;
          default:
            if (this.defaultFilterIds?.includes(filterId)) {
              searchFilters.defaultSearchFilters.set(
                filterId,
                this.buildDefaultSearchFilter(filter)
              );
            } else {
              // Group all filters that are not default filters as more filters
              // Do not include Language filter if it only has one subitem
              if (
                filterId === SearchFilterId.Language &&
                filter.subitems.length === 1
              ) {
                return;
              }

              searchFilters.nonDefaultFilters.filters.push(filter);
            }
        }
      });

    this.setSearchFilters(searchFilters);
  }

  private clearSelectedFilterItems(excludeTypeFilter: boolean = false): void {
    this.filterComponents.forEach((searchFilterComponent) => {
      // Check if Type filter should be excluded from being cleared
      if (
        excludeTypeFilter &&
        searchFilterComponent.searchFilter.id === SearchFilterId.Type
      ) {
        return;
      }

      searchFilterComponent.clearSelectedFilterItems();
      this.updateSearchFilters(searchFilterComponent.searchFilter);
    });
  }
}
