<div data-dgprop-item-clicked="Social Counts" *ngIf="tags$ | async as tags">
  <div *ngIf="socialCounts || tags.length">
    <!-- Completions -->
    <button
      #socialCountButton
      *ngIf="socialCounts?.userCount"
      type="button"
      class="guts-m-r-half {{ buttonClasses }}"
      (click)="showTopUsers($event, 'completions')"
      [attr.aria-label]="
        getAriaLabelForSocialCount(
          i18n.InputsSvc_UsersTitle,
          socialCounts.userCount
        )
      "
      [ngbTooltip]="i18n.InputsSvc_UsersTitle"
      #tooltip="ngbTooltip"
      container="body"
      placement="auto top"
      [openDelay]="tooltipOpenDelay"
      data-dgat="socialCounts-269"
      aria-haspopup="dialog"
    >
      <ng-container
        *ngTemplateOutlet="
          buttonlabel;
          context: {
            i18n: 'dgInputStatistics_CompletedCount',
            icon: 'checkmark-circle',
            count: socialCounts.userCount
          }
        "
      ></ng-container>
    </button>

    <!-- Shares / recommendations -->
    <button
      #socialCountButton
      *ngIf="socialCounts?.recommendationCount"
      type="button"
      class="guts-m-r-half {{ buttonClasses }}"
      (click)="showTopUsers($event, 'recommendations')"
      [attr.aria-label]="
        getAriaLabelForSocialCount(
          i18n.RecommendationsSvc_UsersTitle,
          socialCounts.recommendationCount
        )
      "
      [ngbTooltip]="i18n.RecommendationsSvc_UsersTitle"
      #tooltip="ngbTooltip"
      [openDelay]="tooltipOpenDelay"
      container="body"
      placement="auto top"
      data-dgat="socialCounts-e14"
      aria-haspopup="dialog"
    >
      <ng-container
        *ngTemplateOutlet="
          buttonlabel;
          context: {
            i18n: 'dgInputStatistics_SharesCount',
            icon: 'arrow-forward-circle',
            count: socialCounts.recommendationCount
          }
        "
      ></ng-container>
    </button>

    <!-- Comments -->
    <button
      #socialCountButton
      *ngIf="socialCounts?.commentCount && canComment"
      type="button"
      class="guts-m-r-half {{ buttonClasses }}"
      [ngClass]="{ 'social-counts__no-action': !hasCommentsAction }"
      (click)="openComments()"
      [attr.aria-label]="
        getAriaLabelForSocialCount(
          i18n.dgComment_ViewConversation,
          socialCounts.commentCount
        )
      "
      [ngbTooltip]="i18n.dgComment_ViewConversation"
      #tooltip="ngbTooltip"
      [openDelay]="tooltipOpenDelay"
      container="body"
      placement="auto top"
      data-dgat="socialCounts-9e0"
    >
      <ng-container
        *ngTemplateOutlet="
          buttonlabel;
          context: {
            i18n: 'dgInputStatistics_CommentsCount',
            icon: 'speech-bubble-circle',
            count: socialCounts.commentCount
          }
        "
      ></ng-container>
    </button>

    <!-- Likes -->
    <button
      #socialCountButton
      *ngIf="socialCounts?.likeCount"
      type="button"
      class="guts-m-r-half {{ buttonClasses }}"
      [ngClass]="{ 'cursor-default': !socialCounts.likeCount }"
      (click)="showTopUsers($event, 'likes')"
      [attr.aria-label]="
        getAriaLabelForSocialCount(
          i18n.dgInputStatistics_PeopleWhoLiked,
          socialCounts.likeCount
        )
      "
      [ngbTooltip]="i18n.dgInputStatistics_PeopleWhoLiked"
      #tooltip="ngbTooltip"
      [openDelay]="tooltipOpenDelay"
      container="body"
      placement="auto top"
      data-dgat="socialCounts-ee6"
      aria-haspopup="dialog"
    >
      <ng-container
        *ngTemplateOutlet="
          buttonlabel;
          context: {
            i18n: 'dgInputStatistics_LikesCount',
            icon: 'thumbs-up-circle',
            count: socialCounts.likeCount
          }
        "
      ></ng-container>
    </button>

    <!-- Tags -->
    <span
      *ngIf="!config.hideSkills"
      ngbDropdown
      placement="bottom-right"
      class="social-counts--tags"
    >
      <button
        ngbDropdownToggle
        #socialCountButton
        type="button"
        id="tagDropdown{{ item.id }}"
        (click)="trackTagsView($event)"
        class="guts-m-r-half {{ buttonClasses }}"
        [ngbTooltip]="i18n.Core_Topics"
        #tooltip="ngbTooltip"
        [openDelay]="tooltipOpenDelay"
        container="body"
        placement="auto top"
        data-dgat="socialCounts-e14"
        [attr.aria-label]="
          getAriaLabelForSocialCount(i18n.Core_Topics, tags.length ?? 0)
        "
        aria-haspopup="menu"
        aria-controls="skill-dropdown-menu"
      >
        <ng-container
          *ngTemplateOutlet="
            buttonlabel;
            context: {
              i18n: 'dgInputStatistics_SkillsCount',
              icon: 'tag-circle',
              count: tags?.length ?? 0
            }
          "
        ></ng-container>
      </button>
      <ul
        id="skill-dropdown-menu"
        class="
          dropdown-menu dropdown-menu-right
          dropdown-menu__arrow
          rounded
          break
        "
        ngbDropdownMenu
        attr.aria-labelledby="tagDropdown{{ item.id }}"
      >
        <li *ngFor="let tag of tags" data-dgat="socialCounts-5d8">
          <a
            class="link"
            (click)="trackLinkClick($event, tag.internalUrl)"
            [href]="socialCountsUrl(tag.title)"
            data-dgat="socialCounts-66f"
            target="{{ isIframe ? '_blank' : '_self' }}"
            ngbDropdownItem
            >{{ tag.title }}</a
          >
        </li>
      </ul>
    </span>
  </div>
</div>

<!-- Button Label Template -->
<ng-template let-icon="icon" let-i18n="i18n" let-count="count" #buttonlabel>
  <!-- Badge -->
  <ng-container *ngIf="useBadgeLayout">
    {{ i18n | translate: { count: count } }}
  </ng-container>

  <!-- Icon-->
  <ng-container *ngIf="!useBadgeLayout">
    <df-icon
      [icon]="icon"
      class="social-counts__icon guts-m-r-quart"
      aria-hidden="true"
    ></df-icon>
    {{ count }}
  </ng-container>
</ng-template>
