import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { merge, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { WindowToken } from '@app/shared/window.token';
import { AuthUser } from '@app/account/account-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { Target } from '@app/target/target-api.model';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { FollowTargetModal } from '@app/target/components/modals/follow-target-modal/follow-target-modal.component';
import { PathwayDetailsModel } from '@app/pathways/pathway-api.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { PathwayEnrollmentService } from '@app/pathways/services/pathway-enrollment.service';
import { CareerPathNamingService } from '@app/shared/services/career-path-naming.service';
import { ModalService } from '@app/shared/services/modal.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { LiveEventsService } from '@app/shared/components/content/live-events/live-events.service';
import { ResourceCardMenuService } from '@app/shared/components/content/learning-resource-card/resource-card-menu.service';
import { EndorseContentService } from '@app/shared/components/content/services/endorse-content.service';
import {
  Expanded,
  SearchResultCardService,
} from './search-result-card.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { LDFlagsService } from '@dg/shared-services';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { SearchResultTrackingConfig } from '@app/search';
import { showJoinedButton } from '@app/academies/utils/academy.utils';

@Component({
  selector: 'dgx-search-result-card',
  templateUrl: './search-result-card.component.html',
  styleUrls: ['./search-result-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultCardComponent
  implements OnInit, OnChanges, AfterViewChecked, OnDestroy
{
  @Input() public resource: LearningResourceViewModel;
  @Input() public searchTerm: string;
  /** Set to true to hide all clickable elements - currently only used in group comment component */
  @Input() public isPreview: boolean = false;
  /** Default menu config is specific to global search results - override that here */
  @Input() public customMenuConfig?: MenuViewModel[];
  @Input()
  public searchResultTrackingConfig: SearchResultTrackingConfig;

  @Output()
  public inputSelected = new EventEmitter<LearningResourceViewModel>();

  @ViewChild('title') public titleRef: ElementRef<HTMLElement>;
  @ViewChild('summaryRef') public summaryRef: ElementRef<HTMLElement>;

  public isImageBroken: boolean = false;
  public i18n = this.translateService.instant([
    'A11y_ExpandForTitle',
    'A11y_ExpandForSummary',
    'Core_YesSure',
    'Core_Follow',
    'Core_Following',
    'Core_MoreDetails',
    'dgPathwayTile_UnfollowConfirm',
    'dgPathwayTile_WithdrawExplanation',
    'dgTargetTile_UnfollowConfirm',
    'dgTargetTile_UnfollowExplanation',
    'Core_ViewDetails',
    'Core_Viewed',
    'Core_EndorsedTooltip',
    'Core_SaveForLater',
    'Core_Unsave',
    'Core_Join',
    'Core_Actions',
    'Core_Endorsed',
    'Ext_MenuMarkComplete',
    'Core_Join',
    'Core_Joined',
    'Core_Completed',
  ]);

  public showEllipsesButton$: BehaviorSubject<{
    title: boolean;
    summary: boolean;
  }> = new BehaviorSubject<{ title: boolean; summary: boolean }>(undefined);
  public expanded$ = this.searchResultCardService.expanded$.pipe(
    map(({ id, expandedSections }) => ({
      title: id === this.resource?.id && expandedSections.includes('title'),
      summary: id === this.resource?.id && expandedSections.includes('summary'),
    }))
  );

  public showTitleEllipses$ = combineLatest([
    this.showEllipsesButton$,
    this.expanded$,
  ]).pipe(
    map(
      ([showEllipsesButton, expanded]) =>
        showEllipsesButton?.title && !expanded?.title
    )
  );

  public showSummaryEllipses$ = combineLatest([
    this.showEllipsesButton$,
    this.expanded$,
  ]).pipe(
    map(
      ([showEllipsesButton, expanded]) =>
        showEllipsesButton?.summary && !expanded?.summary
    )
  );

  private destroy$ = new Subject();

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private careerPathNamingService: CareerPathNamingService,
    private detailsModalService: DetailsModalService,
    private liveEventsService: LiveEventsService,
    private modalService: ModalService,
    private orgEndorsedService: OrgEndorsedService,
    private pathwayEnrollmentService: PathwayEnrollmentService,
    private sharedTargetService: SharedTargetService,
    private resourceCardMenuService: ResourceCardMenuService,
    private translateService: TranslateService,
    private trackerService: TrackerService,
    private endorseContentService: EndorseContentService,
    private searchResultCardService: SearchResultCardService,
    private ldFlagService: LDFlagsService,
    private markdownService: MarkdownService,
    private searchTrackerService: SearchTrackerService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get showPathwayFollowButton(): boolean {
    return this.resource.canFollow && this.resource.isPathway;
  }

  public get showTargetFollowButton(): boolean {
    return this.resource.canFollow && this.resource.isTarget;
  }

  public get showSaveForLaterButton(): boolean {
    return (
      !this.resource.isTarget &&
      !this.resource.completionInfo?.isCompleted &&
      !this.resource.model?.marketplaceInputId
    );
  }

  public get showCompleteButton(): boolean {
    return (
      this.resource.isInput &&
      !this.resource.isLive &&
      !this.resource.isAcademy &&
      !this.resource.isv2Academy
    );
  }

  public get showJoinedButton(): boolean {
    return showJoinedButton(this.resource);
  }

  public get showMoreDetailsButton(): boolean {
    return (
      !this.resource.isPathway &&
      !this.resource.isTarget &&
      !this.resource.isv2Academy
    );
  }

  public get titleExpanded(): Expanded {
    return Expanded.title;
  }

  public get summaryExpanded(): Expanded {
    return Expanded.summary;
  }

  public get shouldOpenContentInModal(): boolean {
    return this.resource.canPlayVideo || !!this.resource.liveSessions;
  }

  public get shouldOpenNewWindow(): boolean {
    return !(
      this.resource.isPathway ||
      this.resource.isTarget ||
      this.shouldOpenContentInModal ||
      this.isMarketplaceProgram
    );
  }

  public get isCompleted(): boolean {
    return this.resource.completionInfo?.isCompleted;
  }

  public get isEnrolled(): boolean {
    return this.resource.isEnrolled;
  }

  public get isFollowing(): boolean {
    return this.resource.isFollowing;
  }

  public get endorsedImageUrl(): string {
    return this.resource.isEndorsed
      ? this.orgEndorsedService.getEndorsedSrc(this.resource.organizationId)
      : undefined;
  }

  public get menuConfig(): MenuViewModel[] {
    return (
      this.customMenuConfig ||
      this.resourceCardMenuService.getResourceMenu(
        this.resource,
        false,
        'Search',
        true
      )
    );
  }

  public get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public get url(): string {
    if (this.resource.isLive) {
      const liveSession = this.resource.liveSessions.find(
        (session) => session.isLive === true
      );
      return liveSession?.locationUrl;
    }

    return this.resource?.model?.marketplaceInputId
      ? this.resource.url
      : this.resource.externalUrl || this.resource.url;
  }

  public get hidePlanImage(): boolean {
    return this.authUser?.defaultOrgInfo?.settings?.disablePlanImage;
  }

  public get missingImageIcon(): string {
    return this.resource.resourceType.toLowerCase();
  }

  public get summary(): string {
    const { publishDate, plainTextSummary } = this.resource;
    const summary =
      publishDate && plainTextSummary
        ? `${publishDate} - ${plainTextSummary}`
        : plainTextSummary;
    // markdownToPlaintext will sanitize the string so it's safe for innerHTML in the template
    return this.markdownService.markdownToPlaintext(summary);
  }

  public get isMarketplaceProgram(): boolean {
    return !!this.resource.model.marketplaceInputId;
  }

  public get titleHref(): string {
    if (this.shouldOpenContentInModal || this.isMarketplaceProgram) {
      return this.url;
    }

    return this.addNewQueryParam(this.url, 'newWindow=true');
  }

  public ngOnInit(): void {
    merge(
      this.resourceCardMenuService.onUpdate$,
      this.endorseContentService.onUpdate$
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((resourceUpdated: LearningResourceViewModel) => {
        if (
          resourceUpdated.resourceId === this.resource.resourceId &&
          resourceUpdated.resourceType === this.resource.resourceType
        )
          this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngAfterViewChecked(): void {
    this.showEllipsesButton$.next({
      title:
        this.titleRef?.nativeElement.clientHeight <
        this.titleRef?.nativeElement.scrollHeight,
      summary:
        this.summaryRef?.nativeElement.clientHeight <
        this.summaryRef?.nativeElement.scrollHeight,
    });
    this.cdr.detectChanges();
  }

  public ngOnChanges(): void {
    if (this.resource.isTarget) {
      this.updateTargetI18nStringValues();
    }
  }

  public expandCard(expandedSection: Expanded): void {
    this.searchResultCardService.updateExpanded(
      this.resource.id,
      expandedSection
    );
  }

  public onTitleClick(e: Event): void {
    // Open modal for playable videos, Posts, and Tasks.
    if (this.shouldOpenContentInModal) {
      e.preventDefault();
      this.detailsModalService.openDetails(this.resource);
    } else if (this.isMarketplaceProgram) {
      e.preventDefault();
      e.stopPropagation();
      this.openDetails();
    }

    this.inputSelected.next(this.resource);
  }

  public onImageClick(e: MouseEvent): void {
    e.stopPropagation();
    this.titleRef.nativeElement.click();
  }

  public onEnrollClick(_: Event): void {
    this.resource.isEnrolled ? this.unenroll() : this.enroll();
  }

  public onFollowClick(): void {
    this.isFollowing ? this.unfollowTarget() : this.followTarget();
  }

  public openLiveSession(): void {
    const currentLiveSession = this.liveEventsService.getLiveSession(
      this.resource.liveSessions
    );
    this.windowRef.open(
      currentLiveSession.locationUrl,
      '_blank',
      'noopener,noreferrer'
    );

    this.trackerService.trackEventData({
      action: 'JoinSession',
      properties: this.liveEventsService.getTrackingProperties(
        this.resource,
        currentLiveSession
      ),
    });
  }

  public onBrokenImage(isBroken: boolean): void {
    this.isImageBroken = isBroken;
    this.cdr.detectChanges();
  }

  public openDetails(): void {
    const isAcademiesUser = this.ldFlagService.isPaidCMUser;
    if (isAcademiesUser && this.resource?.model?.marketplaceInputId) {
      this.searchTrackerService
        .contentOpened(this.resource, this.searchResultTrackingConfig)
        .pipe(take(1))
        .subscribe({
          complete: () => {
            // wait for the tracking call to complete before leaving the Degreed domain
            const currentPath = window.location.href;
            // Replaces the empty srcpath with the current href.
            const resourceUrl = this.resource.url.replace(
              /(\?|&)srcpath=.*?(&|$)/,
              `$1srcpath=${encodeURIComponent(currentPath)}$2`
            );
            this.windowRef.open(resourceUrl, '_self');
          },
        });
    } else {
      this.detailsModalService
        .openDetails(this.resource, {
          isMarketplaceProgram: this.isMarketplaceProgram,
          searchResultTrackingConfig: this.searchResultTrackingConfig,
        })
        .subscribe(({ resource }) => {
          if (resource) {
            // update the content item from any changes that occurred in the more details modal
            this.resource = resource;
            this.cdr.detectChanges();
          }
        });
    }
  }

  public addNewQueryParam(href: string, queryParam: string): string {
    return href.indexOf('?') > -1
      ? `${href}&${queryParam}`
      : `${href}?${queryParam}`;
  }

  public onSaveForLaterClick(): void {
    if (!this.resource.isQueued) {
      this.saveForLater();
    } else {
      this.unsaveForLater();
    }
  }

  private saveForLater(): void {
    const { resourceId, resourceType, title, isEndorsed } = this.resource;

    this.resourceCardMenuService
      .saveForLater({
        resourceType,
        resourceId,
        title,
        isEndorsed,
      })
      .subscribe((result) => {
        this.resource.queueItemId = result;
        this.resource.isQueued = true;
        this.cdr.markForCheck();
      });
  }

  private unsaveForLater(): void {
    const { resourceId, resourceType, queueItemId } = this.resource;

    this.resourceCardMenuService
      .unsave({
        resourceId,
        resourceType,
        queueItemId,
      })
      .subscribe(() => {
        this.resource.queueItemId = null;
        this.resource.isQueued = false;
        this.cdr.markForCheck();
      });
  }

  private updateTargetI18nStringValues(): void {
    this.i18n.dgTargetTile_UnfollowConfirm = this.translateService.instant(
      'dgTargetTile_UnfollowConfirm',
      {
        targetType: this.sharedTargetService.getTypeDisplayName(
          this.resource.targetType
        ),
      }
    );
    this.i18n.dgTargetTile_UnfollowExplanation = this.translateService.instant(
      'dgTargetTile_UnfollowExplanation',
      {
        targetType: this.sharedTargetService.getTypeDisplayName(
          this.resource.targetType
        ),
      }
    );

    if (this.authUser && this.authUser.hasCareerPathing) {
      const customNames = this.careerPathNamingService.careerPathNames;

      if (this.resource.targetIsDirectory) {
        this.resource.metaData.resourceLabel =
          customNames.customDirectoryName || this.resource.targetType;
      }
      if (this.resource.targetIsRole) {
        this.resource.metaData.resourceLabel =
          customNames.customPlanName || this.resource.targetType;
      }
    }
  }

  private enroll(): void {
    const pathway: PathwayDetailsModel = {
      ...this.resource,
      id: this.resource.resourceId,
    } as any;
    this.resource.isEnrolled = true;

    this.pathwayEnrollmentService.enroll(pathway).subscribe({
      error: () => {
        this.resource.isEnrolled = false;
      },
    });
  }

  private unenroll(): void {
    const pathway: PathwayDetailsModel = {
      ...this.resource,
      id: this.resource.resourceId,
    } as any;
    this.resource.isEnrolled = false;

    this.pathwayEnrollmentService.unenrollConfirmation(pathway).subscribe({
      error: () => {
        this.resource.isEnrolled = true;
      },
    });
  }

  private followTarget(): void {
    this.modalService
      .show<boolean>(FollowTargetModal, {
        inputs: {
          target: this.resource,
        },
      })
      .subscribe((followPlan) => {
        if (followPlan) {
          this.resource.isFollowing = true;
          this.cdr.markForCheck();
        }
      });
  }

  private unfollowTarget(): void {
    this.modalService
      .show<void>(SimpleModalComponent, {
        inputs: {
          bodyText: this.i18n.dgTargetTile_UnfollowExplanation,
          canCancel: true,
          headerText: this.i18n.dgTargetTile_UnfollowConfirm,
          submitButtonText: this.i18n.Core_YesSure,
        },
      })
      .pipe(
        tap(() =>
          this.sharedTargetService.unfollowTarget(
            this.resource as unknown as Target
          )
        )
      )
      .subscribe(() => {
        this.resource.isFollowing = false;
        this.cdr.markForCheck();
      });
  }
}
