import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import {
  FlexRowSummary,
  FlexRowType,
} from '@app/flex-framework/flex-api.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { TargetType } from '@app/shared/models/core-api.model';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { TargetsService } from '@app/shared/services/targets.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import {
  GetTargetResourcesBySectionResponse,
  Resource,
} from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Target } from '@app/target/target-api.model';

type ItemWithSelected<T> = Partial<T> & { selected?: boolean };
@Component({
  selector: 'dgx-follow-target-modal',
  templateUrl: './follow-target-modal.component.html',
  styleUrls: ['./follow-target-modal.component.scss'],
})
export class FollowTargetModal
  extends SubscriberBaseDirective
  implements OnInit
{
  public i18n = this.translate.instant([
    'Core_Expand',
    'Core_Collapse',
    'Core_Loading',
    'followTargetForm_Instructions',
    'followTargetForm_NoResources',
    'followTargetForm_selectTitleFormat',
  ]);
  public headerTitle: string = '';
  public instructions: string = '';
  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public followableSections: FlexRowType[] = [
    'User',
    'Pathway',
    'Tag',
    'Group',
  ];
  public sectionsToFollow: ItemWithSelected<Partial<FlexRowSummary>>[] = [];
  public sectionsToCheck: ItemWithSelected<FlexRowSummary>[] = [];
  public resources: ItemWithSelected<Resource>[] = [];
  public targetType: TargetType;
  public targetId: number;

  @Input() public target: Target;

  constructor(
    private activeModal: NgbActiveModal,
    private displayTypePipe: DisplayTypePipe,
    private translate: TranslateService,
    private sharedTargetService: SharedTargetService,
    private targetsService: TargetsService
  ) {
    super();
  }
  getAriaText(expanded: boolean, title: string) {
    return this.translate.instant(
      expanded ? 'A11y_CollapsePanelButton' : 'A11y_ExpandPanelButton',
      {
        name: title,
      }
    );
  }

  public ngOnInit(): void {
    this.targetId = this.target.targetId || this.target.resourceId;

    // calculate strings that won't change
    this.targetType = this.displayTypePipe.transform<TargetType>(
      this.target.targetType
    );
    this.headerTitle = this.translate.instant('followTargetForm_Title', {
      targetType: this.targetType,
    });
    this.instructions = this.translate.instant(
      'followTargetForm_Instructions',
      {
        targetType: this.targetType,
        targetTitle: this.target.title,
      }
    );

    this.targetsService
      .getTarget(this.targetId)
      .pipe(
        this.takeUntilDestroyed(),
        map(({ sections }) =>
          // Filter sections returned by getTarget down to only those sections which
          // might potentially contain followable content.
          sections.filter(
            (section) =>
              (section.resourceType === 'Any' ||
                this.followableSections.indexOf(section.resourceType) > -1) &&
              // sections with undefined resourceCount properties may be sections set to 'expanded' view
              (section.layoutConfig?.displayType === 'All' ||
                section.resourceCount > 0)
          )
        ),
        switchMap((sections) => {
          // Definitely no content -- go directly to finalize. (This never hits subscribe.)
          if (!sections.length) {
            return EMPTY;
          }
          // Potentially content -- we might have an 'Any' section that has no
          // *followable* content, though.
          return this.targetsService.getTargetResourcesBySection(
            this.targetId,
            sections.map((section) => section.parentNode),
            0,
            100
          );
        }),
        // Strip the resources property out of the return from GetTargetResourcesBySection
        map(({ resources }: GetTargetResourcesBySectionResponse) => resources),
        // After everything else is done, set loading to false.
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((resources) => {
        this.loadResources(resources);
      });
  }

  public getSectionName(key: string, sectionName: string): string {
    if (sectionName) {
      // Non localized
      return sectionName;
    } else {
      return this.translate.instant(`Core_${key}sDisplayName`);
    }
  }

  public getSectionHeaderName(
    section: ItemWithSelected<Partial<FlexRowSummary>>,
    ariaLabel: boolean = false
  ): string {
    if (section.resources.length > 1) {
      if (ariaLabel) {
        const title = this.translate.instant(
          `followTargetForm_${section.resourceType}Header`,
          { count: section.resources.length }
        );

        return this.translate.instant('followTargetForm_selectTitleFormat', {
          title: title,
        });
      }

      return this.translate.instant(
        `followTargetForm_${section.resourceType}Header`,
        { count: section.resources.length, name: section.name }
      );
    }

    return this.translate.instant(
      `followTargetForm_${section.resourceType}HeaderSingular`
    );
  }

  public submit() {
    // sanity check
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;

    const tltag = this.sharedTargetService.getTagFromUrl(
      window.location.search
    );
    const selectedResources = this.resources.filter(
      (resource) => resource.selected
    );

    return this.sharedTargetService
      .followTargetAndResources(this.target, selectedResources, tltag)
      .pipe(finalize(() => this.activeModal.close(true)))
      .subscribe();
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public sectionSelected(section) {
    section.resources.forEach((resource) => {
      resource.selected = section.selected;
    });
  }

  public resourceSelected(section, resource) {
    if (!resource.selected) {
      section.selected = false;
    }
  }

  public trackById(index: number) {
    return index;
  }

  private loadResources(
    resources: { node: string; resource: Resource }[]
  ): void {
    // split sections by type -- first an array of potential
    // sectioned resources, mapped off followable sections.
    const resourceSections = this.followableSections.map((resourceType) => ({
      resourceType,
      resources: [],
      selected: true,
    }));
    const referenceIds: number[] = [];

    // loop through resources and create sectionedResources from them
    resources.forEach(({ resource }) => {
      const referenceType = resource.referenceType as FlexRowType;
      // skip nonapplicable types (plans, providers, etc.), and eliminate duplicates
      if (
        this.followableSections.indexOf(referenceType) < 0 ||
        referenceIds.indexOf(resource.referenceId) > -1
      ) {
        return;
      }
      resourceSections.forEach((resourceSection) => {
        // skip through until we've found the right sectionedResource section
        if (referenceType !== resourceSection.resourceType) {
          return;
        }
        const selectedResource = { ...resource, selected: true };
        // for display
        resourceSection.resources.push(selectedResource);
        // for sending to the backend
        this.resources.push(selectedResource);
        // for further comparisons
        referenceIds.push(resource.referenceId);
      });
    });

    // our sectionedResources array is now complete.
    resourceSections.forEach(({ resources, resourceType }) => {
      if (!resources.length) {
        return;
      }
      // create a faux section with all the vital fields
      this.sectionsToFollow.push({
        resourceCount: resources.length,
        resourceType,
        resources,
        selected: true,
        targetId: this.target.targetId,
      });
    });
  }
}
