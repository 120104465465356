<div>
  <div class="video-player border collapse-white-space guts-m-b-1">
    <video
      #userMediaVideo
      id="gum"
      [hidden]="!!showPlayer"
      autoplay
      muted
      class="
        video-player__component video-player__component--recorder
        full-width
      "
    ></video>
    <video
      #recordedVideo
      id="recorded"
      [hidden]="!showPlayer"
      controls
      class="video-player__component full-width"
    ></video>
  </div>

  <div class="video-player__footer l_flexbar">
    <div class="l_flex-grow">
      <div class="l_flexbar">
        <df-icon
          *ngIf="isRecording"
          icon="record"
          class="guts-m-r-quart color-destructive"
          role="presentation"
        ></df-icon>
        <span class="">{{ videoTimer }}</span>
        <div role="alert" aria-live="assertive" class="a11y-hide-text">
          {{ videoTimerMinutesDisplay }}
        </div>
      </div>
    </div>

    <div *ngIf="!processingUpload">
      <ng-container *ngIf="isRecording">
        <button
          df-button
          dfButtonType="passive"
          data-bak-class="btn btn-passive"
          [ngClass]="{ is_disabled: disableRecord }"
          (click)="toggleRecording()"
          [disabled]="disableRecord"
          data-dgat="authorVideo-450"
        >
          <div class="l_flexbar">
            <df-icon
              icon="stop"
              class="guts-m-r-quart"
              role="presentation"
            ></df-icon>
            <span class="">{{ i18n.dgAuthorVideo_StopRecording }}</span>
          </div>
        </button>
      </ng-container>

      <ng-container *ngIf="!isRecording">
        <button
          #startRecording
          *ngIf="!showPlayer"
          df-button
          dfButtonType="passive"
          [ngClass]="{ is_disabled: disableRecord }"
          (click)="toggleRecording()"
          [disabled]="disableRecord"
          data-dgat="authorVideo-450"
          [dgxAutofocus]
        >
          <div class="l_flexbar">
            <df-icon
              icon="record"
              class="guts-m-r-half color-destructive"
              role="presentation"
            ></df-icon>
            <span class="">{{ i18n.dgAuthorVideo_StartRecording }}</span>
          </div>
        </button>

        <button
          *ngIf="showPlayer"
          df-button
          dfButtonType="passive"
          class="guts-m-r-1"
          [ngClass]="{ is_disabled: disableRecord }"
          data-bak-class="btn btn-passive"
          (click)="redoRecording()"
          [disabled]="disableRecord"
          data-dgat="authorVideo-450"
        >
          <div class="l_flexbar">
            <df-icon
              icon="arrow-circular"
              class="guts-m-r-half"
              role="presentation"
            ></df-icon>
            <span class="">{{ i18n.dgAuthorVideo_RedoRecording }}</span>
          </div>
        </button>

        <button
          *ngIf="showPlayer"
          df-button
          dfButtonType="primary"
          (click)="uploadVideo()"
          data-dgat="authorVideo-797"
        >
          <div class="l_flexbar">
            <span class="guts-m-r-half">{{ i18n.Core_Next }}</span>
            <df-icon
              icon="chevron-right"
              class=""
              role="presentation"
            ></df-icon>
          </div>
        </button>
      </ng-container>
    </div>

    <div *ngIf="processingUpload" class="par par--small par--light">
      <div class="ib-v-middle guts-m-r-1">
        <df-icon
          *ngIf="fileUploadStatus === 'error'"
          icon="exclamation-circle-outline"
          size="large"
          class="color-error"
          role="presentation"
        ></df-icon>

        <dgx-progress-circle
          *ngIf="fileUploadStatus !== 'error'"
          [percent]="uploadProgress"
          [config]="{ radius: 12, stroke: 3, checkmark: 'medium' }"
          class=""
        ></dgx-progress-circle>
      </div>

      <span class="guts-m-r-half">{{ uploadLabel }}</span>
      <span
        *ngIf="uploadProgress > 0 && uploadProgress < 100"
        class="ib progress-min-width"
        >- {{ uploadProgress }}%</span
      >

      <button
        *ngIf="fileUploadStatus === 'error'"
        df-button
        dfButtonType="primary"
        class="guts-m-l-1"
        (click)="uploadVideo()"
        data-dgat="author-video-2ba"
      >
        {{ i18n.dgAuthorVideo_TryAgain }}
      </button>
    </div>
  </div>
</div>
