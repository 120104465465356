import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FieldWrapperBuilder } from './field-builder-factory';

@Injectable({ providedIn: 'root' })
export class SkillsFieldBuilderService implements FieldWrapperBuilder {
  constructor(private builder: DfFormFieldBuilder) {}

  public build(): DfFormFieldConfig<DfTemplateOptions> {
    throw new Error('Method not implemented.');
  }
}
