import { Injectable } from '@angular/core';
import { emitOnce } from '@ngneat/elf';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom, of } from 'rxjs';

import { PathwaySubsectionMoveModalComponent } from '@app/pathways/components/pathway';
import { PathwayAddContentComponent } from '@app/pathways/components/pathway/pathway-add-content/pathway-add-content.component';
import {
  AddModalResults,
  Pathway,
  PathwayBinItem,
  PathwayDetailsModel,
  PathwayModalSettingsChecks,
  PathwayPermissionsModel,
  PathwaySection,
  PathwayStep,
  PathwaySubsection,
  StepModalProperties,
} from '@app/pathways/rsm/pathway-api.model';
import { PathwayDataAPI } from '@app/pathways/rsm/pathway.api';
import { PathwayStore } from '@app/pathways/rsm/pathway.store';
import {
  announceCompletion,
  calculateNewPathwayDuration,
  convertServerPrivacy,
  createReorderedItems,
  getAddContentDoneMsg,
  getAddContentNodes,
  getAddContentTracking,
  getAddToBinTracking,
  getPrivacyParamsToUpdate,
  isFauxlike,
  toIndexes,
  toSectionIndex,
  updateEditStepDisplay,
  updateStepDetails,
  updateStepInternal,
} from '@app/pathways/rsm/utils';
import {
  AllPathwayInputParameters,
  PathwayInputModalService,
} from '@app/pathways/services/inputs/pathway-input-modal.service';
import { PathwayStepDetailsModalComponent } from '../components/pathway/pathway-step-details-modal/pathway-step-details-modal.component';
import {
  ActionType,
  AddContentModalType,
  AddContentNodes,
  AuthoringAction,
  PathwayLevel,
  PathwayMoveNodeInput,
  PathwayNode,
  addContent,
  addContentToBin,
  createNode,
  createNodeAndPopulate,
  deleteAuthorNote,
  deleteNode,
  editAuthors,
  editCustomDetailsStep,
  editDetailsStep,
  editInternalDetailsStep,
  moveNode,
  removeContentFromBin,
  reorderNode,
  settings,
  toggleHasFaux,
  toggleOptionalStep,
  toggleShouldDisplayNote,
  updateItemInBin,
  updateNodeField,
  updateVisibility,
} from './pathway.model';

import { GroupItem } from '@app/groups/group-api';
import {
  UpdateInputParameters,
  UpdateTaskParameters,
} from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { ReorderModalService } from '@app/reorder-modal/reorder-modal.service';
import { ReorderType } from '@app/reorder-modal/reorder-modal.constants';
import { ReorderItem } from '@app/reorder-modal/reorder-modal.model';
import { ResourceVersionService } from '@app/resource-version/services/resource-version.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { JsonObject } from '@app/shared/models/core-api.model';
import {
  ModalService,
  NotifierService,
  TrackerService,
} from '@app/shared/services';
import { Merge } from '@app/shared/utils/type';
import { InputModalDispatcherService } from '@app/user-content/user-input/services/input-modal-dispatcher.service';
import { AllInputApiEntities } from '@app/user-content/user-input/user-input.model';
import { PathwayStepMoveModalComponent } from '../components/pathway/pathway-step-move-modal/pathway-step-move-modal.component';
import { PathwayAddEditFormModalComponent } from '../components/settings/pathway-add-edit-form-modal/pathway-add-edit-form-modal.component';
import {
  PathwayVisibilityComponent,
  PathwayVisibilityInfo,
} from '../components/settings/pathway-visibility/pathway-visibility.component';
import { PathwaySummaryConfig } from '../pathway-api.model';
import { PathwayAuthorNoteService } from '../services/reactive-pathway-services/pathway-author-note.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { LDFlagsService } from '@dg/shared-services';
import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';
import { Router } from '@angular/router';
import { parseImageDataForBE } from '@app/user-content/user-input-v2/utils/image-data-utils';
const EMPTY_ERROR = 'EmptyError';

interface DeleteNodeInputs {
  id: number;
  node: string;
  type: ActionType;
  pathway?: Pathway;
}
const { PATHWAY, SECTION, SUBSECTION, STEP } = PathwayLevel;

/**
 * This should NEVER be used by the PathwayFacade.
 *
 * NOTE: the PathwayAuthoring is using a shared 'store' that
 *       the PathwayFacade owns.
 */
@Injectable({ providedIn: 'root' })
export class PathwayAuthoring {
  private selectedSearch: LearningResourceViewModel[] = [];
  private selectedManual: LearningResourceViewModel[] = [];
  constructor(
    private store: PathwayStore,
    private api: PathwayDataAPI,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private resourceVersioner: ResourceVersionService,
    private translate: TranslateService,
    private reorderModalService: ReorderModalService,
    private modalService: ModalService,
    private genericInputModalService: InputModalDispatcherService,
    private pathwayInputModalService: PathwayInputModalService,
    private pathwayAuthoringNoteService: PathwayAuthorNoteService,
    private router: Router,
    private webEnvironmentService: WebEnvironmentService,
    private lDFlagsService: LDFlagsService,
    private imageUrlService: ImageUrlService
  ) {}
  /*****************************************************************
   * API for calculating what commands to use.
   ****************************************************************/
  /**********************************************************
   * Common Public API
   **********************************************************/

  /**
   * When the Authoring UI is making changes to fields, we want
   * to share those changes immediate (via the reactive store) but
   * NOT save to the server until all changes are saved.
   *
   * eg. this an immediate in-memory update only!
   */
  public async updateField(
    field: string,
    value: string,
    target: PathwayNode,
    updateStoreOnly: boolean
  ) {
    return this.editWith(
      updateNodeField(
        target,
        value,
        field,
        updateStoreOnly // update store value
      )
    );
  }

  /**
   * Create a Section | Subsection.
   *
   * eg. this an immediate in-memory update only!
   * @param type (Section | Subsection)
   * @param id pathway id
   * @param node section node when creating subsection
   */
  public async createNode(type: ActionType, id: number, node?: string) {
    return this.editWith(createNode(type, id, node));
  }

  /**
   * Re-order the (section | subsection | step) with in the pathway
   * Opens a modal reorder editor
   * On success it updates the store and API data service
   *
   * @param type type that is being reordered
   * @param levelNumber subsection level number ( required when re-ordering subsection)
   * @param subsection required when re-ordering step
   *
   */
  public async showReorderEditor(
    type: ActionType,
    node?: string,
    subsection?: PathwaySubsection
  ) {
    return this.editWith(reorderNode(type, node, subsection));
  }

  /**
   * Delete a Section | Subsection | Step within a pathway
   * @param type type that is being deleted
   * @param id pathway id
   * @param sectionNode section Node
   *
   * will also need to update the messages(done|track|error) in the model for subsection & step
   */
  public async deleteNode(type: ActionType, id: number, sectionNode: string) {
    return this.editWith(deleteNode(type, id, sectionNode));
  }

  /**
   * *********** PATHWAY API ***************
   */

  /**********************************************
   * Pathway API
   **********************************************/

  /**
   * Show the pathway settings modal (in edit&non-edit mode)
   *
   * @param checks Pathway setting modal checks to keep the store out of the modal
   *               so the modal can be reusable in other locations outside of pathways.
   * @returns
   */
  public async showSettings(
    checks: PathwayModalSettingsChecks
  ): Promise<boolean> {
    return this.editWith(settings(checks));
  }

  /**
   * Edit Pathway authors show the pathway settings modal
   *
   * @param checks Pathway setting modal checks to keep the store out of the modal
   *               so the modal can be reusable in other locations outside of pathways.
   */
  public async editAuthors(
    checks: PathwayModalSettingsChecks
  ): Promise<boolean> {
    return this.editWith(editAuthors(checks));
  }

  /**
   * Update the visibility/privacy of the pathway (edit mode)
   */
  public async updatePathwayVisibility(privacyLevel: Visibility) {
    const { pathway } = this.store.snapshot;
    return this.editWith(
      updateVisibility(privacyLevel, pathway.id, pathway.title)
    );
  }

  /**
   * Toggle the virtual isNotFaux property.
   *
   * @param pathId
   * @param node
   * @param isNotFaux
   */
  public async toggleHasFauxSection(
    pathId: number,
    node: string,
    isNotFaux: boolean
  ) {
    return this.editWith(
      toggleHasFaux(PathwayLevel.PATHWAY, pathId, node, isNotFaux)
    );
  }

  /**********************************************
   * Section API
   **********************************************/

  /**
   * Toggle the virtual isNotFaux property.
   *
   * @param pathId
   * @param node
   * @param isNotFaux
   */
  public async toggleHasFauxSubsection(
    pathId: number,
    node: string,
    hasNoFaux: boolean
  ) {
    return this.editWith(
      toggleHasFaux(PathwayLevel.SECTION, pathId, node, hasNoFaux)
    );
  }

  /**********************************************
   * Subsection API
   **********************************************/

  /**
   * Display the add-content-to-subsection modal
   * On success the command will save to store / api data service
   *
   * @param afterNode - Node to insert content after.
   * @param node - Subsection node.
   * @param parentIndex - Index of parent section. Used when creating (sub)sections.
   * @param pathway - Pathway to modify. Used when creating (sub)sections.
   * @param subsections - Subsection array.
addContent   */
  public async showAddContentEditor({
    afterNode,
    node,
    parentIndex,
    pathway,
    subsections,
  }: {
    subsections: PathwaySubsection[];
    node: string;
    parentIndex: number;
    afterNode: string;
    pathway: PathwayDetailsModel;
  }) {
    // Adding content to a node that already exists. Simple, happy path.
    if (node) {
      return this.editWith(
        addContent(getAddContentNodes(subsections, node, afterNode))
      );
    }

    // Oops! We have to *create* one or more nodes first.
    let type = PathwayLevel.SECTION;
    let parentNode: string;
    if (pathway.levels?.length) {
      type = PathwayLevel.SUBSECTION;
      parentNode = pathway.levels[parentIndex].node;
    }

    return this.editWith(
      createNodeAndPopulate({
        id: pathway.id,
        type,
        parentNode,
        nodes: getAddContentNodes(subsections, node, afterNode),
      })
    );
  }

  /**
   * Move the subsection to a different section or move a step to a different subsection. this is different than re-ordering subsections within
   * the section it is in
   * NOTE: This re-parents a subsection; to modify ordering in current parent use `showReorderEditor()`
   * @param actionType
   * @param node
   */
  public async showMoveEditor(actionType: ActionType, node: PathwayNode) {
    return this.editWith(moveNode(actionType, node));
  }

  /***********************************************
   * Step API
   ***********************************************/

  /**
   * Display the Editor for the internal details of the steps content
   * Editing the internal details will also update the item in the content catalog
   *
   * On success the command will save to store / api data service
   *
   * @param stepProperties
   * @param step
   */
  public async showEditInternalDetails(
    stepProperties: StepModalProperties,
    step: PathwayStep
  ) {
    return this.editWith(editInternalDetailsStep(stepProperties, step));
  }

  /**
   * Toggle step optional
   *
   * On success the command will toggle the step as Optional/Required
   *
   * @param id pathway id
   * @param step
   */
  public async toggleOptionalStep(id: number, step: PathwayStep) {
    return this.editWith(toggleOptionalStep(id, step));
  }

  /**
   * Toggle the display of the author note
   *
   * Shows or hides the author note on the step
   * @param step PathwayStep
   * @param shouldDisplayNote boolean
   */
  public async toggleDisplayOfAuthorNote(
    step: PathwayStep,
    shouldDisplayNote: boolean
  ) {
    return this.editWith(toggleShouldDisplayNote(step, shouldDisplayNote));
  }

  /**
   * Deletes the author note on the step
   *
   * @param step PathwayStep
   */
  public async deleteAuthorNote(step: PathwayStep) {
    return this.editWith(deleteAuthorNote(step));
  }

  /**
   * Display the Editor for the edit details of the steps content
   * Editing th details will only update the item in the pathway
   *
   * On success the command will save to store / api data service
   * @param step
   */
  public async showEditDetails(
    stepProperties: StepModalProperties,
    step: PathwayStep
  ) {
    return this.editWith(editDetailsStep(stepProperties, step));
  }

  /*
   * Display the Editor for the custom details of the steps content
   * This is for editing for post and task
   *
   * On success the command will save to store / api data service

   * @param stepProperties
   * @param step
   */
  public async showEditCustomDetails(
    stepProperties: StepModalProperties,
    step: PathwayStep
  ) {
    return this.editWith(editCustomDetailsStep(stepProperties, step));
  }
  /**
   * Add to Bin or in the UI Hold content item for later.
   * When in the adding content modal, you can select to hold the
   * content item for later.
   *
   * This updates the store and API for adding that content to the bin
   * @param id
   * @param savedItems
   */
  public async addToBin(id: number, savedItems: LearningResourceViewModel[]) {
    return this.editWith(addContentToBin(id, savedItems));
  }

  /**
   * Remove an item from the Bin (Hold for later in UI).
   *
   * This updates the store and API for removing content from the Bin.
   * @param item
   * @returns
   */
  public async removeFromBin(item) {
    return this.editWith(removeContentFromBin(item));
  }

  /**
   * Updates whether a bin(Hold for later in UI) item is selected.
   * @param item item to change
   * @param isSelected value to update to.
   * @param isAllSelected
   * @returns
   */
  public async updateBinSelection(
    item: PathwayBinItem,
    isSelected: boolean,
    isAllSelected: boolean = false
  ) {
    return this.editWith(updateItemInBin(isSelected, isAllSelected, item));
  }
  // ****************************************************************************************************
  // Edit the Section/Subsection/Step and then update the PathwayStore (which emits a new PathwayViewModel)
  // ****************************************************************************************************

  /**
   * Processes section, subsection and step actions
   */
  private async editWith(command: AuthoringAction): Promise<boolean> {
    try {
      if (!(await this.canEdit(command))) return false;

      switch (command.type) {
        case PATHWAY:
          await this.editPathway(command);
          break;
        case SECTION:
          await this.editSection(command);
          break;
        case SUBSECTION:
          await this.editSubsection(command);
          break;
        case STEP:
          await this.editStep(command);
          break;
      }

      announceCompletion(command, this.tracker, this.notifier, this.translate);
      return true;
    } catch (e) {
      if (e?.name === EMPTY_ERROR) return false;
      const status = e.innerError?.status ? e.innerError?.status : e.status;
      // If the error is 404 (possibly the pathway has been deleted)
      if (status === 404) {
        this.router.navigateByUrl(`/error-handler/404`);
        return false;
      }
      // propagate to global error handler
      throw e;
    }
  }

  private async editPathway(command: AuthoringAction) {
    const { permissions, pathway } = this.store.snapshot;
    const originalPermissions = permissions;

    switch (command.action) {
      case 'updatePathwayVisibility': {
        const modalResponse: {
          privacyLevel: Visibility;
          groups: GroupItem[];
        } = await this.openVisibilityModal();
        const updatedPermissions: PathwayPermissionsModel =
          await this.api.setPathwayVisibility(
            pathway.id,
            modalResponse.privacyLevel,
            modalResponse.groups
          );
        const version = await this.loadVersion(pathway.id);

        if (pathway.privacyLevel !== updatedPermissions.privacyLevel) {
          const { canViewPathwayEnrollees, canViewInsightsTab, orgIdToUpdate } =
            getPrivacyParamsToUpdate(originalPermissions, updatedPermissions);

          emitOnce(() => {
            this.store.updatePathwayPrivacy(
              modalResponse.privacyLevel,
              modalResponse.groups,
              canViewPathwayEnrollees,
              canViewInsightsTab,
              orgIdToUpdate
            );
            this.store.updateVersion(version);
          });
        }
        break;
      }
      case 'toggleHasFaux': {
        return this.store.updateHasFaux({
          hasFaux: command.payload.hasFaux,
          type: PathwayLevel.SECTION, // for our store, this is a section update
        });
      }
      case 'editAuthors':
      // falls through to settings
      case 'settings': {
        const { checks } = command.payload;
        const summaryConfig = await this.openSettingsModal(checks);
        // NOTE: IF the user removes themselves the get version throws a 403
        const version = await this.loadVersion(pathway.id);
        const updatedPathway = this.updateStoreSettings(summaryConfig);
        if (this.lDFlagsService.pathwayNotificationOwnership) {
          const updatedPermissions = await this.api.getPathwayPermissions(
            pathway.id
          );
          const { canViewPathwayEnrollees, canViewInsightsTab, orgIdToUpdate } =
            getPrivacyParamsToUpdate(originalPermissions, updatedPermissions);
          emitOnce(() => {
            this.store.updatePathway(updatedPathway);
            if (this.lDFlagsService.pathwayNotificationOwnership)
              this.store.updatePathwayPrivacy(
                updatedPathway.privacyLevel,
                updatedPathway.groupIds,
                canViewPathwayEnrollees,
                canViewInsightsTab,
                orgIdToUpdate
              );

            this.store.updateVersion(version);
          });
          return;
        }
        emitOnce(() => {
          this.store.updatePathway(updatedPathway);
          this.store.updateVersion(version);
        });
        break;
      }
    }
  }

  /**
   * Use API service to edit/create/delete section
   * Then update the store so a new PathwayViewModel emits
   */
  private async editSection(command: AuthoringAction) {
    const { pathway } = this.store.snapshot;
    const { type } = command;

    switch (command.action) {
      case 'create': {
        const section = await this.api.addSection(pathway);
        const version = await this.loadVersion(pathway.id);

        emitOnce(() => {
          this.store.updateSection(section);
          // When creating a new empty section, the first subsection should
          // *always* be faux.
          this.store.updateHasFaux({
            hasFaux: true,
            node: section.lessons[0].node,
            type: PathwayLevel.SUBSECTION,
          });
          this.store.updateVersion(version);
        });
        break;
      }
      case 'createAndPopulate': {
        const { nodes } = command.payload;
        // Get the result of our content modal.
        const contentToSave = await this.openAddContentModal(nodes);
        // Update messages
        command.doneMessage = getAddContentDoneMsg(
          contentToSave.savedItems.length
        );
        command.trackingEvent = getAddContentTracking(
          contentToSave.whichModal as AddContentModalType
        );
        // add subsection and populate
        return this.saveContent({
          contentToSave,
          nodes,
          type,
          newParent: true,
        });
      }
      case 'reorder': {
        const { levels: sections } = pathway; // levels are represented as sections in the ui

        const reorderedItems = await this.openReorderModal(
          sections,
          this.translate.instant('Pathways_ReorderSections'),
          type
        );
        return this.saveReorderedNodes(reorderedItems, type);
      }
      case 'delete': {
        const { id, node } = command.payload;
        await this.openDeleteModal(type, pathway, node);
        return this.deletePathwayNode({
          id,
          node,
          type,
          pathway,
        });
      }
      case 'toggleHasFaux': {
        const { hasFaux, node } = command.payload;
        return this.store.updateHasFaux({
          node,
          hasFaux,
          type: PathwayLevel.SUBSECTION, // for our store, this is a subsection update
        });
      }
      case 'updateField': {
        const index = toSectionIndex(
          pathway.levels,
          command.payload.sectionNode.node
        );
        const { field, value } = command.payload;

        this.store.updateSectionField(index, field, value);

        // get the updated section version from the store to save
        const updatedPathway = this.store.snapshot.pathway;
        const section = updatedPathway.levels[index];
        if (!command.updateStoreOnly) this.saveSection(section);
        break;
      }
    }
  }

  /**
   * Use API service to create/reorder/delete SUBSECTION
   * Then update the store so a new PathwayViewModel emits
   * @param command
   * @returns
   */
  private async editSubsection(command: AuthoringAction): Promise<void> {
    const { pathway } = this.store.snapshot;
    const { node } = command.payload;
    const { type } = command;

    switch (command.action) {
      case 'create': {
        const subsection = await this.api.addSubsection(pathway, node);
        const version = await this.loadVersion(pathway.id);
        emitOnce(() => {
          this.store.updateSubsection(subsection);
          this.store.updateVersion(version);
        });
        break;
      }
      case 'createAndPopulate': {
        const { nodes, parentNode } = command.payload;
        // Get the result of our content modal.
        const contentToSave = await this.openAddContentModal(nodes);
        // Update messages
        command.doneMessage = getAddContentDoneMsg(
          contentToSave.savedItems.length
        );
        command.trackingEvent = getAddContentTracking(
          contentToSave.whichModal as AddContentModalType
        );
        // add subsection and populate
        return this.saveContent({
          contentToSave,
          nodes,
          type,
          parentNode,
          newParent: true,
        });
      }
      case 'reorder': {
        const { levels: sections } = pathway;
        const { node } = command.payload;
        const sectionIndex = toSectionIndex(pathway.levels, node);
        const subsections = sections[sectionIndex].lessons;
        const reorderedItems = await this.openReorderModal(
          subsections,
          this.getReorderSubsectionHeader(sectionIndex + 1),
          type
        );
        return this.saveReorderedNodes(reorderedItems, type);
      }
      case 'delete':
        const { id } = command.payload;
        await this.openDeleteModal(type, pathway, node);
        return this.deletePathwayNode({
          id,
          node,
          type,
          pathway,
        });
      case 'move': {
        const { subsection } = command.payload;
        const movedNodes = await this.openMoveModal(type, subsection);
        return this.saveMovedNodes(movedNodes, type);
      }
      case 'addContent': {
        const { nodes } = command.payload;
        const contentToSave = await this.openAddContentModal(nodes);
        command.doneMessage = getAddContentDoneMsg(
          contentToSave.savedItems.length
        );
        command.trackingEvent = getAddContentTracking(
          contentToSave.whichModal as AddContentModalType
        );
        return this.saveContent({ contentToSave, nodes, type });
      }
      case 'updateField': {
        const { subsection, field, value } = command.payload;

        this.store.updateSubsectionField(subsection, field, value);
        // note the subsection node does not have the updated value in it
        const { pathway } = this.store.snapshot;
        const [sectionIndex, subsectionIndex] = toIndexes(
          pathway,
          subsection.node
        );
        const subsectionUpdated =
          pathway.levels[sectionIndex].lessons[subsectionIndex];

        if (!command.updateStoreOnly)
          return this.saveSubsection(subsectionUpdated);
        break;
      }
    }
  }

  /**
   * Use API service to create/reorder/delete STEP
   * Then update the store so a new PathwayViewModel emits
   * @param command
   * @returns
   */
  private async editStep(command: AuthoringAction): Promise<void> {
    const { pathway } = this.store.snapshot;
    const { type } = command;

    switch (command.action) {
      case 'create':
        console.error(
          'This should never be hit. (See: editSubsection.addContent.)'
        );
        return;
      case 'reorder': {
        const { subsection } = command.payload;
        const steps = subsection.steps;

        const reorderedItems = await this.openReorderModal(
          steps,
          this.getReorderStepHeader(subsection.number),
          type
        );
        return this.saveReorderedNodes(reorderedItems, type);
      }
      case 'move': {
        const { step } = command.payload;
        const movedNodes = await this.openMoveModal(type, step);
        return this.saveMovedNodes(movedNodes, type);
      }
      case 'delete':
        const { id, node } = command.payload;
        await this.openDeleteModal(type, pathway, node);
        return this.deletePathwayNode({
          id,
          node,
          type,
        });
      case 'addContentToBin': {
        const { pathId, contentItems } = command.payload;
        // This already returns the object items we need for the bin(Hold for later in UI) without having to map from the content Items added
        const results: PathwayBinItem[] = await this.api.addToBin(
          pathId,
          contentItems
        );
        const version = await this.loadVersion(pathId);
        const { itemBin } = this.store.snapshot;
        // Updated the adding items in the bin
        const items = [...itemBin, ...results];
        emitOnce(() => {
          this.store.updateItemBin(items);
          this.store.updateVersion(version);
        });
        command.batchTrackingEvent = getAddToBinTracking(pathId, contentItems);
        return;
      }
      case 'removeContentFromBin': {
        const { item } = command.payload;
        await this.api.removeFromBin(pathway.id, item);
        const version = await this.loadVersion(pathway.id);
        const { itemBin } = this.store.snapshot;
        const index = itemBin.indexOf(item);
        emitOnce(() => {
          this.store.updateItemBin(itemBin, true, index);
          this.store.updateVersion(version);
        });
        break;
      }
      case 'updateItemInBin': {
        const { item, isSelected, isAllSelected } = command.payload;
        const { itemBin } = this.store.snapshot;
        emitOnce(() => {
          if (isAllSelected) {
            itemBin.forEach((itemFor) => {
              const index = itemBin.indexOf(itemFor);
              this.store.updateItemInBin(isSelected, 'isSelected', index);
            });
          } else {
            const index = itemBin.indexOf(item);
            this.store.updateItemInBin(isSelected, 'isSelected', index);
          }
        });
        break;
      }
      case 'editInternalDetails': {
        const { stepProperties, step } = command.payload;
        const { id } = pathway;

        // NOTE: Editing internal details will never affect the integrity of the pathway itself.
        // It only affects the individual content item (catalog)
        const newInput: AllInputApiEntities =
          await this.openInternalDetailsModal(stepProperties);

        const internalDetails = updateStepInternal(
          newInput,
          step,
          this.imageUrlService
        );
        return this.saveStepDetails(internalDetails, id);
      }
      case 'toggleOptional':
        const { step } = command.payload;
        return this.saveToggleOptionalStep(step);
      case 'toggleShouldDisplayNote': {
        const { step, shouldDisplayNote } = command.payload;
        return this.store.updateShouldDisplayNote(step, shouldDisplayNote);
      }
      case 'deleteAuthorNote': {
        const { step } = command.payload;
        const { id } = pathway;

        const updatedStep = {
          ...step,
          note: undefined,
          shouldDisplayNote: false,
        };
        await this.pathwayAuthoringNoteService.deleteAuthorNote(id, step.node);
        this.store.updateStep(updatedStep);
        return;
      }
      case 'editDetails': {
        const { stepProperties, step } = command.payload;
        const { id } = pathway;

        const newStep: Partial<PathwayStep> = await this.openStepDetailsModal(
          pathway,
          step,
          stepProperties
        );
        // NOTE: right now until we update the details modal
        // when out of date return null and close modal
        if (!!newStep) {
          command.doneParams = { title: newStep.title };
          const newArticleVideoModals =
            this.lDFlagsService.inferredSkillsContent &&
            (stepProperties.inputType === 'Article' ||
              stepProperties.inputType === 'Video');
          const newCourseModals =
            this.lDFlagsService.inferredSkillsCourseEvent &&
            stepProperties.inputType === 'Course';

          if (newArticleVideoModals || newCourseModals) {
            const updatedStep = updateStepDetails(
              newStep,
              step,
              this.imageUrlService
            );
            return this.saveStepDetails(updatedStep, id);
          } else {
            const updatedStep = updateEditStepDisplay(step, newStep);
            return this.saveStep(updatedStep);
          }
        }
        command.doneMessage = null;
        return;
      }
      case 'editCustomDetails': {
        const { stepProperties, step } = command.payload;
        const { id } = pathway;

        // NOTE: Editing custom details which is (Task/Post) will never affect the integrity of the pathway itself.
        // It only affects the individual Task/Post.
        const newInput: Merge<UpdateTaskParameters, UpdateInputParameters> =
          await this.openCustomDetailsModal(stepProperties);

        const stepDetails = updateEditStepDisplay(step, newInput);
        command.doneParams = { title: stepDetails.title };

        return this.saveStepDetails(stepDetails, id);
      }
      // This is used for adding/updating an author note on a step
      case 'updateField': {
        const { step, field, value } = command.payload;
        this.store.updateStepField(step, field, value);
        if (!command.updateStoreOnly) this.saveStepNote(step);
        return;
      }
    }
  }

  // ****************************************************************************************************
  // Internal validation utils to ensure we have the latest version of the Pathway object in memory
  // ****************************************************************************************************

  /**
   * We are able to edit the pathway only if we have the current version in-memory
   * NOTE: if NOT current, the version service will show a modal
   */
  private async canEdit(command: AuthoringAction): Promise<boolean> {
    let isVersionCurrent = true;
    if (!command.updateStoreOnly) {
      try {
        isVersionCurrent = await this.validateVersions();
      } catch (e) {
        throw e;
      }
    }
    return isVersionCurrent;
  }
  /**
   * remove multiple items from the bin(Hold for later in UI)
   * When adding selected content from the bin we need to remove all selected
   * items after adding content to the pathway.
   */
  private async removeMultipleFromBin(itemsToRemove) {
    const { itemBin } = this.store.snapshot;
    const removedMap = new Map<any, boolean>();

    const filteredArray = itemBin.filter((item) => {
      if (itemsToRemove.includes(item) && !removedMap.get(item)) {
        removedMap.set(item, true);
        return false; // Exclude the first occurrence of this item
      }
      return true; // Include all other items
    });
    return this.store.updateItemBin(filteredArray);
  }

  /**
   * The client and database both maintain a version of the Pathway.
   * This answers the question "do we have the most recent version" of the pathway.
   *
   * If the server has a newer version, then ResourceVersioner will show a modal requesting
   * a client 'refresh'. The user can cancel the modal and save data first BEFORE refreshing.
   *
   * Note: we do not have server-push features that automatically update the client version
   * when a pathway may be saved by another client to the database.
   * @TODO - do not refresh the entire app, just reload the new pathway information!
   *
   */
  private validateVersions(): Promise<boolean> {
    const {
      pathway: { id, resourceType },
      version: resourceVersion,
    } = this.store.snapshot;
    const options = { id, resourceType, resourceVersion };
    try {
      const request$ = this.resourceVersioner.isCurrentVersion({
        ...options,
        showRefreshModal: true,
      });

      return lastValueFrom(request$);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Load the latest 'version' information of the pathway.
   *
   * Used to update the in-memory store. Note, this does not necessarily mean
   * that the in-memory pathway will have the SAME data as the database pathway.
   */
  private async loadVersion(pathwayId): Promise<string> {
    return this.api.getVersion(pathwayId);
  }

  // ****************************************************************************************************
  // Internal Utils to handle logic of Recording Pathway Items
  // ****************************************************************************************************

  /**
   * Saves reordered Pathway Nodes(sections, subsections, steps) via api and updates the store
   * @param reorderedItems
   * @param type
   * @returns Promise<void>
   */
  private async saveReorderedNodes(
    reorderedItems: ReorderItem[],
    type: ActionType
  ): Promise<void> {
    const { pathway } = this.store.snapshot;
    const reorderedPathway = await (type === SECTION
      ? this.api.reorderSections(pathway, reorderedItems)
      : this.api.reorderSectionNodes(pathway, reorderedItems, type));

    const version = await this.loadVersion(pathway.id);
    emitOnce(() => {
      this.store.updatePathway(reorderedPathway);
      this.store.updateVersion(version);
    });
  }

  /**
   * Opens reorder modal to allow reordering of pathway items
   * @param itemsToReorder
   * @param title
   * @param onSave
   * @param type
   * @returns Promise<ReorderItem[]>
   */
  private async openReorderModal(
    itemsToReorder: PathwayNode[],
    title: string,
    type: ActionType
  ) {
    const nodeMap: ReorderItem[] = createReorderedItems(
      itemsToReorder,
      type,
      this.translate
    );
    return lastValueFrom(
      this.reorderModalService.openModal(
        title,
        nodeMap,
        ReorderType.PathwayNodes,
        undefined
      )
    );
  }

  // ****************************************************************************************************
  // Internal Utils to handle logic of Deleting Pathway Items
  // ****************************************************************************************************

  /**
   * Opens delete modal returning true on user confirmation and throws an empty error on cancel
   * @param type
   * @returns Promise<boolean>
   */
  private async openDeleteModal(
    type: ActionType,
    pathway: Pathway,
    node: string
  ) {
    let confirmationMsg = '';
    let submitButtonAriaLabel = '';
    let sectionIndex: number;
    let subsectionIndex: number;
    let stepIndex: number;

    switch (type) {
      case SECTION:
        const section = pathway.levels[toSectionIndex(pathway.levels, node)];
        confirmationMsg = this.translate.instant(
          'Pathways_ConfirmRemoveSection'
        );
        submitButtonAriaLabel = this.translate.instant('A11y_RemoveSection', {
          sectionNumber: section.number,
          sectionTitle: section.title,
        });
        break;
      case SUBSECTION:
        [sectionIndex, subsectionIndex] = toIndexes(pathway, node);
        const subsection =
          pathway.levels[sectionIndex].lessons[subsectionIndex];
        confirmationMsg = this.translate.instant(
          'Pathways_ConfirmDeleteSubsection'
        );
        submitButtonAriaLabel = this.translate.instant(
          'A11y_RemoveSubsection',
          {
            sectionNumber: subsection.levelNumber,
            subsectionNumber: subsection.number,
            subsectionTitle: subsection.title,
          }
        );
        break;
      // step
      default:
        [sectionIndex, subsectionIndex, stepIndex] = toIndexes(pathway, node);
        const step =
          pathway.levels[sectionIndex].lessons[subsectionIndex].steps[
            stepIndex
          ];
        confirmationMsg = this.translate.instant('Pathways_ConfirmRemoveItem');
        submitButtonAriaLabel = this.translate.instant('A11y_RemoveContent', {
          contentTitle: step.title,
          contentType: this.translate.instant(`Core_${step.referenceType}`),
        });
    }
    const inputs = {
      bodyClasses: 'h3 center-text',
      bodyText: confirmationMsg,
      canCancel: true,
      isHeaderBorderless: true,
      // Item is *intended* to be content from the modal, but by setting it to true
      // here we can force this modal to return true when it's closed (as opposed to
      // undefined when canceled).
      item: true,
      submitButtonText: this.translate.instant('deleteModal_Delete'),
      submitButtonType: 'destructive',
      submitButtonAriaLabel,
    };

    const isDeleteConfirmed$ = this.modalService.show<boolean>(
      SimpleModalComponent,
      { inputs }
    );
    return lastValueFrom(isDeleteConfirmed$);
  }

  /**
   * Deletes pathway node (section, subsection, step) via api then updates the store
   *
   * @param id
   * @param node
   * @param type
   */
  private async deletePathwayNode({
    id,
    node,
    type,
    pathway,
  }: DeleteNodeInputs): Promise<void> {
    const updatedPathway = await this.api.removePathwayNode(id, node, type);
    updatedPathway.privacyLevel = convertServerPrivacy(
      updatedPathway.privacyLevel as any
    );
    const version = await this.loadVersion(id);
    let sectionIndex: number;
    let subsectionIndex: number;
    let updateFaux = false;

    // Handle *steps* that were deleted in this process. The API call adds content to the bin on the BE,
    // but the bin won't be updated without a hard refresh unless we also update the bin here.
    let binItems: PathwayBinItem[];
    switch (type) {
      case PathwayLevel.SECTION:
        // If at least one of our subsections (lessons) had steps, define binItems as an empty array.
        sectionIndex = toSectionIndex(pathway.levels, node);
        if (
          pathway.levels?.[sectionIndex].lessons?.some(
            (lesson) => lesson.steps?.length
          )
        ) {
          binItems = [];
        }
        // If we're deleting a section that had a faux subsection, ensure we clean up after ourselves.
        if (isFauxlike(pathway.levels?.[sectionIndex].lessons)) {
          updateFaux = true;
        }
        break;
      case PathwayLevel.SUBSECTION:
        // If our subsection (lesson) had steps, same thing.
        [sectionIndex, subsectionIndex] = toIndexes(pathway, node);
        if (
          pathway.levels?.[sectionIndex].lessons?.[subsectionIndex].steps
            ?.length
        ) {
          binItems = [];
        }
        // If we're deleting a formerly faux subsection, ensure we clean up after ourselves.
        if (
          subsectionIndex === 0 &&
          isFauxlike(pathway.levels?.[sectionIndex].lessons)
        ) {
          updateFaux = true;
        }
        break;
      // No need to check anything for Step, as we are *always* deleting steps.
      case PathwayLevel.STEP:
        binItems = [];
    }
    // If binItems is *defined*...
    if (binItems !== undefined) {
      binItems = await this.api.getPathAuthoringBin(id);
    }

    // Emit only once for our store updates.
    emitOnce(() => {
      // If binItems *and* populated, update the bin
      if (binItems?.length) {
        this.store.updateItemBin(binItems);
      }
      // If updateFaux is truthy, clean up after ourselves by removing the deleted node from our array.
      if (updateFaux) {
        this.store.updateHasFaux({
          hasFaux: false,
          node: pathway.levels?.[sectionIndex].lessons[0].node,
          type: PathwayLevel.SUBSECTION,
        });
      }
      // Update pathway and version in all scenarios
      this.store.updatePathway(updatedPathway);
      this.store.updateVersion(version);
    });
  }

  // Subsection helper function
  private getReorderSubsectionHeader(sectionNumber: number) {
    return this.translate.instant('Pathways_ReorderSubsectionsTitleFormat', {
      sectionNumber, // non-zero index for the section, NOT its sectionNumber property
    });
  }

  // step helper function
  private getReorderStepHeader(subsectionNumber: number) {
    return this.translate.instant(
      'Pathways_ReorderSubsectionItemsTitleFormat',
      {
        lessonNumber: subsectionNumber,
      }
    );
  }
  // ****************************************************************************************************
  // Internal Utils to handle logic of Moving Pathway Items
  // ****************************************************************************************************

  private openMoveModal(
    actionType: ActionType,
    node: PathwayNode
  ): Promise<PathwayMoveNodeInput> {
    const { pathway } = this.store.snapshot;
    let component;
    let modalOptions;
    if (actionType === PathwayLevel.SUBSECTION) {
      component = PathwaySubsectionMoveModalComponent;
      modalOptions = {
        backdropClickClose: true,
        inputs: { pathway, subsection: node },
      };
    } else if (actionType === PathwayLevel.STEP) {
      component = PathwayStepMoveModalComponent;
      modalOptions = {
        backdropClickClose: true,
        inputs: {
          step: node,
          pathway: pathway,
        },
      };
    }
    const request$ = this.modalService.show<PathwayMoveNodeInput>(
      component,
      modalOptions
    );
    return lastValueFrom(request$);
  }

  /**
   * Saves sections via api when items (subsection, step) are moved then updates the pathway store
   * @param movedNodes
   * @param type
   * @returns Promise<void>
   */
  private async saveMovedNodes(
    movedNodes: PathwayMoveNodeInput,
    type: ActionType
  ): Promise<void> {
    const { pathway } = this.store.snapshot;
    if (movedNodes.moveToBin) {
      this.deletePathwayNode({
        id: pathway.id,
        node: movedNodes.node,
        type,
        pathway,
      });
      return;
    }
    const updatedPathway = await this.api.movePathwayNode(
      pathway,
      movedNodes,
      type
    );
    const version = await this.loadVersion(pathway.id);
    emitOnce(() => {
      this.store.updatePathway(updatedPathway);
      this.store.updateVersion(version);
    });
  }

  // ****************************************************************************************************
  // Internal Utils to handle logic of adding content to a Pathway
  // ****************************************************************************************************

  /**
   * Opens add content modal
   * @param nodes
   * @returns Promise<AddModalResults>
   */
  private async openAddContentModal(
    nodes: AddContentNodes
  ): Promise<AddModalResults> {
    const { pathway } = this.store.snapshot;
    const addContentModel = {
      afterNode: nodes.afterNode,
      beforeNode: nodes.beforeNode,
      pathway,
      savedSearch: this.selectedSearch,
      savedManual: this.selectedManual,
    };

    try {
      const request$ = this.modalService.show<AddModalResults>(
        PathwayAddContentComponent,
        {
          inputs: { addContentModel },
          errorOnDismiss: true,
          windowClass: 'xlg-modal',
        }
      );
      const contentToAdd = await lastValueFrom(request$);
      return contentToAdd;
    } catch (dismissedResults) {
      // modal was closed or cancelled save selected content items from search & add by type
      this.selectedSearch = dismissedResults.selectedSearch;
      this.selectedManual = dismissedResults.selectedManual;
      throw { name: EMPTY_ERROR };
    }
  }

  /**
   * Save content from the add content modal via api
   * @param contentToAdd
   * @param nodes
   */
  private async saveContent({
    contentToSave,
    nodes,
    type,
    parentNode,
    newParent,
  }: {
    contentToSave: AddModalResults;
    nodes: AddContentNodes;
    type: ActionType;
    parentNode?: string;
    newParent?: boolean;
  }) {
    const { pathway } = this.store.snapshot;
    const apiMethod =
      // If we aren't creating a new parent, we can just add as normal.
      !newParent
        ? 'addContent'
        : // Otherwise, either creating a section (and subsection)...
          type === PathwayLevel.SECTION
          ? 'addSectionAndPopulate'
          : // ...or just a subsection.
            'addSubsectionAndPopulate';
    const updatedPathway = await this.api[apiMethod]({
      pathway,
      parentNode,
      contentToSave,
      nodes,
    });

    // clear selected content items that were saved
    if (contentToSave.whichModal === 'search') {
      this.selectedSearch = [];
    } else if (
      contentToSave.whichModal === 'manual' ||
      contentToSave.whichModal === 'bin'
    ) {
      this.selectedManual = [];
    }
    // grab our new version
    const version = await this.loadVersion(pathway.id);

    emitOnce(() => {
      if (contentToSave.whichModal === 'bin') {
        this.removeMultipleFromBin(contentToSave.savedItems);
      }
      // We added a new section or subsection AND added content.
      // Because of how everything is structured currently, that
      // means it MUST be a faux section/subsection (first in its
      // node).
      if (newParent) {
        // Set `hasFaux` to true only for section updates.
        if (type === PathwayLevel.SECTION) {
          this.store.updateHasFaux({
            hasFaux: true,
            type,
          });
        }
        // But for *both* section and subsection updates, add the first
        // child of our new section to the faux subsections array.
        this.store.updateHasFaux({
          node: updatedPathway.levels[
            parentNode
              ? // if parentNode is defined, get its index
                toSectionIndex(updatedPathway.levels, parentNode)
              : // if it's not, this *must* be a brand new section,
                // and therefore the correct index is 0
                0
          ].lessons[0].node,
          hasFaux: true,
          type: PathwayLevel.SUBSECTION, // Now we're updating the subsection, regardless of our original type
        });
      }
      // meanwhile, update the pathway and version as normal
      this.store.updatePathway(updatedPathway);
      this.store.updateVersion(version);
    });
  }

  // ****************************************************************************************************
  // Internal Utils to handle logic of Editing Pathway Items
  // ****************************************************************************************************
  /**
   * Opens the pathway settings modal.
   * On save the modal takes care of the save to api,
   * the results returned are to be updated into the store.
   *
   * @returns saved settings modal results PathwaySummaryConfig
   */
  private async openSettingsModal(
    checks: PathwayModalSettingsChecks
  ): Promise<PathwaySummaryConfig> {
    const options = {
      pathId: this.store.snapshot.pathway.id,
      forCloning: false,
      itemToAdd: null,
      preventRedirect: true,
      isChannel: checks.isChannel, // NOTE: computed properties are not on the state snapshot pass in the checks from the component $VM
      disableEndorsedOption: false,
      disableLearnerSkillsRegistry: checks.disableLearnerSkillsRegistry,
      showPathwayBadgeTrigger: checks.showPathwayBadgeTrigger,
      isConsumerUser: checks.isConsumerUser,
    };
    const modalOptions = {
      backdropClickClose: true,
      inputs: { options, pathway: this.store.snapshot.pathway },
    };

    const request$ = this.modalService.show<PathwaySummaryConfig>(
      PathwayAddEditFormModalComponent,
      modalOptions
    );
    return lastValueFrom(request$);
  }
  /**
   * Update the store with the new pathway settings
   *
   * @param newPathway results from the saved settings modal
   */
  private updateStoreSettings(
    newPathway: PathwaySummaryConfig
  ): PathwayDetailsModel {
    let updatedPathway: PathwayDetailsModel = {
      ...this.store.snapshot.pathway,
      description: newPathway.description,
      title: newPathway.title,
      headerImageDisabled: newPathway.headerImageDisabled,
      authors: newPathway.authors as any,
      durationDisplayDisabled: newPathway.durationDisplayDisabled,
      isEndorsed: newPathway.isEndorsed,
    };
    if (this.lDFlagsService.pathwayNotificationOwnership) {
      updatedPathway = {
        ...updatedPathway,
        privacyLevel: newPathway.visibility,
        groupIds: newPathway.visibilityGroupIds,
      };
    }
    if (
      !(this.store.snapshot.pathway.headerImageDisabled || !newPathway.imageUrl)
    ) {
      updatedPathway = {
        ...updatedPathway,
        imageUrl: this.webEnvironmentService.getBlobUrl(newPathway.imageUrl),
      };
    }

    // On delete imageUrl is undefined
    if (newPathway.imageUrl === undefined) {
      updatedPathway = {
        ...updatedPathway,
        imageUrl: '',
      };
    }
    return updatedPathway;
  }

  /**
   * Opens the internal details model ( which is the same as the content catalog form)
   * @param stepProperties step properties for the input form
   */
  private openInternalDetailsModal(
    stepProperties: StepModalProperties
  ): Promise<AllInputApiEntities> {
    const request$ =
      this.genericInputModalService.edit<AllInputApiEntities>(stepProperties);
    return lastValueFrom(request$);
  }

  /**
   * Opens the edit details model. Note this is not the same as the content catalog.
   *
   * @param pathway
   * @param step
   */
  private async openStepDetailsModal(
    pathway: PathwayDetailsModel,
    step: PathwayStep,
    stepProperties: StepModalProperties
  ): Promise<Partial<PathwayStep>> {
    let request$;

    const newArticleVideoModals =
      this.lDFlagsService.inferredSkillsContent &&
      (stepProperties.inputType === 'Article' ||
        stepProperties.inputType === 'Video');
    const newCourseModals =
      this.lDFlagsService.inferredSkillsCourseEvent &&
      stepProperties.inputType === 'Course';

    if (newArticleVideoModals || newCourseModals) {
      request$ = this.genericInputModalService.edit<AllInputApiEntities>(
        stepProperties,
        step
      );
      return request$ ? lastValueFrom(request$) : lastValueFrom(of());
    }
    const modalOptions = {
      backdropClickClose: true,
      inputs: { pathway, step },
    };
    request$ = this.modalService.show<Partial<PathwayStep>>(
      PathwayStepDetailsModalComponent,
      modalOptions
    );

    return lastValueFrom(request$);
  }

  /**
   * Opens the custom details model which is for Posts and Tasks specific only to pathways
   *
   * @param stepProperties step properties for the input form
   */
  private async openCustomDetailsModal(
    stepProperties: StepModalProperties
  ): Promise<AllPathwayInputParameters> {
    const updatedItem = await lastValueFrom(
      this.pathwayInputModalService.edit(stepProperties)
    );
    // Submit the updated item
    await lastValueFrom(this.pathwayInputModalService.submitFn(updatedItem));

    return updatedItem;
  }

  /**
   * Update the step's content detail, this also updates the section and pathway duration.
   *
   */
  private async saveStepDetails(updatedStep: PathwayStep, id: number) {
    const updatedPathway = await this.api.updatePathwayNode(updatedStep);
    const version = await this.loadVersion(id);

    emitOnce(() => {
      this.store.updatePathway(updatedPathway);
      this.store.updateVersion(version);
    });
  }

  /**
   * Update a step to be Optional/Required
   * Will calculate the duration calculations for section and pathway, as well as
   * update the step display information to be saved as a step.
   *
   * @param pathway
   * @param step Original step
   * @param updatedStep New updated info
   * @returns
   */
  private async saveToggleOptionalStep(step: PathwayStep) {
    const { pathway } = this.store.snapshot;
    // Grab the *current* value of isOptional, which is about to be the
    // *future* value of isRequired.
    const isRequired = step.reference.pathwayStepDetails?.isOptional;

    const updatedStep: PathwayStep = {
      ...step,
      isRequired,
      reference: {
        ...step.reference,
        pathwayStepDetails: {
          node: step.node,
          pathwayId: pathway.id,
          isOptional: !isRequired,
        },
      },
    };

    await this.api.togglePathwayNodeRequired(pathway.id, step.node, isRequired);

    const { sectionDuration, pathwayDuration } = calculateNewPathwayDuration(
      updatedStep,
      pathway,
      {} as AllInputApiEntities,
      true
    );

    const version = await this.loadVersion(pathway.id);

    emitOnce(() => {
      this.store.updatePathwayDuration(
        pathwayDuration.durationHours,
        pathwayDuration.durationMinutes
      );
      this.store.updatePathwayOptionalStepCount(isRequired ? -1 : 1);
      this.store.updateSectionDuration(
        sectionDuration.node,
        sectionDuration.durationHours,
        sectionDuration.durationMinutes
      );
      this.store.updateSectionOptionalStepCount(step.node, isRequired ? -1 : 1);
      this.store.updateStep(updatedStep);
      this.store.updateVersion(version);
    });
  }

  // ****************************************************************************************************
  // Internal Utils to handle logic of updating section, subsection and step nodes
  // ****************************************************************************************************

  /**
   * Saves section title via api and update the store and version
   * @param sectionNode
   */
  private async saveSection(sectionNode: PathwaySection) {
    const { pathway } = this.store.snapshot;

    await this.api.updatePathwayNode(sectionNode);
    const version = await this.loadVersion(pathway.id);
    emitOnce(() => {
      this.store.updateVersion(version);
    });
  }

  private async saveSubsection(subsectionNode: PathwaySubsection) {
    const { pathway } = this.store.snapshot;

    await this.api.updatePathwayNode(subsectionNode);
    const version = await this.loadVersion(pathway.id);
    emitOnce(() => {
      this.store.updateVersion(version);
    });
  }

  /**
   * Saves step
   */
  private async saveStep(stepNode: PathwayStep) {
    const {
      pathway: { id },
    } = this.store.snapshot;

    // Note: be sure to update the URL to be string value not a possible object with legacyPictureUrl
    const imageUrlData = stepNode?.imageUrl as any;
    const { imageUrl, legacyPictureUrl, resourceImageId } =
      this.imageUrlService.getImageData(imageUrlData);
    stepNode = {
      ...stepNode,
      imageUrl: legacyPictureUrl ? legacyPictureUrl : imageUrl,
      resourceImageId: stepNode.resourceImageId
        ? stepNode.resourceImageId
        : resourceImageId,
    };

    const updatedPathway = await this.api.updatePathwayNode(stepNode);
    const version = await this.loadVersion(id);

    emitOnce(() => {
      this.store.updatePathway(updatedPathway);
      this.store.updateVersion(version);
    });
  }

  /**
   * Saves the step's author note
   */
  private async saveStepNote(step: PathwayStep) {
    const { pathway } = this.store.snapshot;

    // The api call `/pathways/addupdatepathwaynodenote` should be used
    // for adding/updating a note on a step rather than `/pathways/updatepathwaynode`
    await this.pathwayAuthoringNoteService.addUpdateAuthorNote(
      step.pathId,
      step.node,
      step.note.note
    );
    const version = await this.loadVersion(pathway.id);

    this.store.updateVersion(version);
  }

  /**
   * Opens the visibility/privacy modal for the overall pathway and returns the response
   */
  private async openVisibilityModal() {
    const request$: Observable<PathwayVisibilityInfo> = this.modalService.show(
      PathwayVisibilityComponent
    );
    return lastValueFrom(request$);
  }
}
