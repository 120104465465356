import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { appendQueryParams } from '@app/shared/utils';

export function buildAcademyUrl(url: string): string {
  const path = window.location.pathname + window.location.search;
  return appendQueryParams(url, { srcpath: encodeURIComponent(path) });
}

// Helper function to determine if the joined button should be shown for an Academy
export const showJoinedButton = ({
  isv2Academy,
  isInProgress,
  completionInfo,
  isEnrolled,
}: LearningResourceViewModel): boolean => {
  return (
    isv2Academy && (isInProgress || completionInfo?.isCompleted || isEnrolled)
  );
};
