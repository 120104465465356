import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OutcomeType } from '@app/outcomes/outcomes-api.model';
import { ProfileService } from '@app/profile/services/profile.service';
import { InputType } from '@app/shared/models/core-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  getDSSkillsAdded,
  getDSSkillsRemoved,
  getDSSkillsSuggested,
} from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { TrackerService } from '@dg/shared-services';

@Injectable({
  providedIn: 'root',
})
export class GlobalAddTrackingService {
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private trackerService: TrackerService
  ) {}

  private getTriggerLocation() {
    const isProfile = this.router.url.includes('profile');
    const location = isProfile
      ? this.profileService.profileLocation.replace(/-v2$/, '')
      : 'Global Add';
    return location;
  }

  public trackGlobalAddItemClick(item: InputType | OutcomeType) {
    this.trackerService.trackEventData({
      action: 'Global Add Clicked',
      properties: {
        ItemType: item,
        Location: this.getTriggerLocation(),
      },
    });
  }

  public trackGlobalAddSaved(
    item: InputType | OutcomeType,
    tags?: TagsApi.Tag[],
    mediumConfidence?: TagsApi.Tag[],
    highConfidence?: TagsApi.Tag[]
  ) {
    const skillsAdded = tags?.length ? tags.map((tag) => tag.name) : [];
    const tagCount = skillsAdded?.length;
    const skillsInferred = highConfidence?.length
      ? highConfidence.map((tag) => tag.name)
      : [];
    const skillsInferredCount = skillsInferred?.length;
    const skillsRemoved = getDSSkillsRemoved(tags, highConfidence);
    const skillsRemovedCount = skillsRemoved?.length;
    const skillsSuggested = getDSSkillsSuggested(mediumConfidence);
    const skillsSuggestedCount = skillsSuggested?.length;
    const skillSuggestionClicked = getDSSkillsAdded(tags, mediumConfidence);
    const skillSuggestionClickedCount = skillSuggestionClicked?.length;

    this.trackerService.trackEventData({
      action: 'Global Add Saved',
      properties: {
        ItemType: item,
        Location: this.getTriggerLocation(),
        skillsAdded,
        tagCount,
        skillsInferred,
        skillsInferredCount,
        skillsRemoved,
        skillsRemovedCount,
        skillsSuggested,
        skillsSuggestedCount,
        skillSuggestionClicked,
        skillSuggestionClickedCount,
      },
    });
  }

  public trackGlobalAddCancelled(item: InputType | OutcomeType) {
    this.trackerService.trackEventData({
      action: 'Global Add Cancelled',
      properties: {
        ItemType: item,
        Location: this.getTriggerLocation(),
      },
    });
  }
}
