import { Injectable } from '@angular/core';
import { ObsoleteOrgInputsParameters } from '@app/orgs/services/orgs.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Repository } from '@app/shared/patterns/repository/repository';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { Institution } from '@app/user-content/user-input/course-form/course-form.model';
import { Observable } from 'rxjs';

type InstitutionRepository = Repository<
  any,
  Institution,
  ObsoleteOrgInputsParameters<any>
>;

@Injectable({ providedIn: 'root' })
export class ProvidersRepository
  extends ApiServiceBase
  implements InstitutionRepository
{
  constructor(httpClient: NgxHttpClient) {
    super(httpClient, '');
  }
  fetchOne(id: number, useResourceImages?: boolean): Observable<Institution> {
    return this.http.get(`${this.baseUrl()}/getinstitutioninfo`, {
      params: { institutionId: id },
    });
  }
  add(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  addExisting(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  update(item: any): Observable<unknown> {
    throw new Error('Method not implemented.');
  }
  remove(id: any): Observable<void> {
    throw new Error('Method not implemented.');
  }

  private baseUrl(): string {
    return '/providers';
  }
}
