import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthUser } from '@app/account/account-api.model';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { PopoverComponent } from '@app/shared/components/popover/popover.component';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { isKey, Key } from '@app/shared/key';
import { A11yService } from '@app/shared/services/a11y.service';
import { AuthService } from '@app/shared/services/auth.service';
import { PopoverService } from '@app/shared/services/popover.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { TagsSearchItem } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, map, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { TitleSuggestion } from '@app/target/target-api.model';

@Component({
  selector: 'dgx-tags-search',
  templateUrl: './tags-search.component.html',
  styleUrls: ['./tags-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsSearchComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public useNewTagsDesign?: boolean = false;
  @Input() public isPopoverOpen: boolean;
  @Input() public tags: TagsApi.Tag[] = [];
  @Input() public topTags: TagsApi.Tag[];
  @Input() public placeholderText: string;
  @Input() public hideTopTags: boolean;
  @Input() public hint: string;
  @Input() public allowExistingOnly = false;
  @Input() public showHintAlone: boolean;
  @Input() public useSearchStyle = false;
  @Input() public ngDisabled: boolean;
  @Input() public focusOnInit? = false;
  @Input() public searchInputLabel?: string;
  @Input() public suggestSkills?: boolean;
  @Input() public useSuggestedSkillsView?: boolean = false;
  @Input() public hideLabel: boolean = false;
  @Input() public loadingJobRoleAISkillSuggestions: boolean = false;
  @Input() public useSkillProficiencyLevels: boolean = false;
  @Output() public inputEnter = new EventEmitter<string>();
  @Output() public addTag: EventEmitter<TagsApi.Tag> = new EventEmitter();
  @Output() public removeTag: EventEmitter<TagsApi.Tag> = new EventEmitter();
  @Output() public isPopoverOpenChange = new EventEmitter<boolean>();

  @ViewChild('tagsInput') public tagsInputRef: ElementRef;

  @ViewChild('popoverComponent') private popoverComponent: PopoverComponent;
  @ViewChild('popoverComponentCustomTag')
  private popoverComponentCustomTag: PopoverComponent;

  public static _idCounter = 0;
  public instanceId = TagsSearchComponent._idCounter++;
  public searchTerm: string;
  public shouldShowCustomTag: boolean;
  public customItem: Partial<TagsApi.Tag>;
  public shouldShowSuggestions = false;
  public i18n = this.translate.instant([
    'dgGlobalSearch_A11ySearchDescription',
    'TargetResourcesForm_SearchPlaceHolder',
    'TargetResourcesForm_NoResults',
    'Core_CategoryName',
    'Core_Endorsed',
    'ContentTabs_RelatedSkills',
    'Core_Skills',
    'TagsSearch_A11yTopTagsHint',
    'OrgSkills_GeneratingSkills',
  ]);
  public suggestions = [];
  public isTagsInputOverflowing = false;

  private authUser: AuthUser;
  private suggest = new Subject<string>();
  private listeners: (() => void)[] = [];
  private disableLearnerSkillsRegistry: boolean = true;

  constructor(
    private a11yService: A11yService,
    private authService: AuthService,
    private renderer: Renderer2,
    private tagsService: TagsService,
    private translate: TranslateService,
    private popoverService: PopoverService,
    private changeDetectorRef: ChangeDetectorRef,
    private orgEndorsedService: OrgEndorsedService,
    private router: Router
  ) {
    super();
  }
  // TODO: Consider replacing this with simple-search-suggest.component
  // with some customization

  public get isSkillRegistryEnabled(): boolean {
    // TODO: This is super hacky but needed to hotfix "Skills Registry Suggestions" title to Profile > Skills (PD-76290)
    // This will be reworked with the next phase of work
    const isUrl =
      this.router.url.includes('profile') && this.router.url.includes('skill');
    const isLearner = !this.disableLearnerSkillsRegistry;
    return isUrl && isLearner;
  }

  public ngOnInit(): void {
    // TODO: suggestSkills can get passed in as an input or enabled by detecting the url in isSkillRegistryEnabled()
    // there is currently no clear pathway here from some components that need it. We will refactor this later
    this.authUser = this.authService.authUser;
    this.disableLearnerSkillsRegistry =
      this.authUser?.disableLearnerSkillsRegistry !== undefined
        ? this.authUser.disableLearnerSkillsRegistry
        : true;
    if (!this.suggestSkills) {
      this.suggestSkills = this.isSkillRegistryEnabled;
    }

    this.allowExistingOnly ??= false;
    this.i18n.TargetResourcesForm_SearchPlaceHolder = this.translate.instant(
      'TargetResourcesForm_SearchPlaceHolder',
      { targetResourceType: this.i18n.Core_Skills }
    );

    this.searchTerm = null;
    this.suggest
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(
          // switchMap cancels (ie, unsubscribes from) any pending request before issuing a new one. Coupled with keypress debouncing, this creates a robustly efficient auto-complete.
          (searchTerm) => {
            // If SPL only get 15 results, otherwise get the default results
            const countOfSkills = this.useSkillProficiencyLevels ? 15 : null;

            if (searchTerm.trim().length === 0) {
              this.shouldShowCustomTag = false;
            }
            if (searchTerm.length > 1) {
              const innerSuggest = this.tagsService.suggestTag(
                searchTerm,
                countOfSkills,
                this.useSkillProficiencyLevels
              ) as Observable<TagsSearchItem[]>;

              return innerSuggest.pipe(takeUntil(this.inputEnter)); // Stop taking suggestion responses when a term is entered
            }
            this.shouldShowSuggestions = false;
            return of([]);
          }
        )
      )
      .subscribe((s: TitleSuggestion[]) => {
        // the type for tags added to this.tags from the suggestion list is TitleSuggestion, tags passed as inputs are
        // TagsApi.Tag, the long term fix would be to have a specific end point for tag searches that can return tag objects
        const getId = (tag: TagsApi.Tag | TitleSuggestion) =>
          (tag as TagsApi.Tag)?.tagId || (tag as TitleSuggestion)?.id;
        const addedIds = [...this.tags, ...this.authUser?.viewerInterests].map(
          (t) => getId(t)
        );
        this.suggestions = s
          .filter((suggestion) =>
            this.useSuggestedSkillsView // only filter out existing skills in the suggested skill view
              ? !addedIds.includes(suggestion.id)
              : true
          )
          .map((suggestion) => ({
            ...suggestion,
            endorsedSrc: this.setEndorsedImg(suggestion.isEndorsed),
          }));
        this.shouldShowCustomTag =
          !this.allowExistingOnly &&
          s.length === 0 &&
          this.searchTerm?.trim().length > 0;
        if (this.shouldShowCustomTag) {
          this.customItem = {
            name: this.searchTerm,
            title: this.searchTerm,
          };
        }
        this.shouldShowSuggestions = s.length > 0;
        this.changeDetectorRef.detectChanges();
      });

    if (this.focusOnInit) {
      // Give the DOM time to catch up
      setTimeout(() => {
        this.focusTagSearch();
      });
    }
  }

  public ngAfterViewInit(): void {
    this.checkTagsInputOverflow();
  }

  public focusTagSearch(): void {
    this.tagsInputRef?.nativeElement.focus();
  }

  public onIsPopoverOpenChange(isOpen: boolean) {
    this.isPopoverOpen = isOpen;
    this.isPopoverOpenChange.emit(isOpen); // forward menu event to parent component
    if (isOpen) {
      const that = this;
      this.listeners.push(
        this.renderer.listen(this.tagsInputRef.nativeElement, 'keydown', (ev) =>
          this.handlePopoverKeydown(ev)
        )
      );
    } else {
      this.removeListeners();
    }
  }

  public handlePopoverKeydown(event: KeyboardEvent) {
    if (isKey(event, Key.Tab)) {
      this.popoverService.close({ preventRefocus: true });
    }
    if (isKey(event, Key.Escape)) {
      // just close the skill modal not the modal, hitting esc again should still close the modal [PD-84621]
      event.stopPropagation();
    }
    if (isKey(event, Key.Down)) {
      // since this is tracked on an INPUT element, the body class doesn't change automatically. Let's force it here
      event.preventDefault();
      document.querySelector('body').classList.remove('mouse-focus');
      document.querySelector('body').classList.add('keyboard-focus');
      const nextItem =
        this.popoverComponent.popover?.nativeElement ??
        this.popoverComponentCustomTag.popover.nativeElement;
      this.a11yService.focusNextFocusable(nextItem);
    }
  }

  public onInputChange(event: Event) {
    const value = (<HTMLInputElement>event.target).value;
    this.suggest.next(value);
  }

  public remove(tagToRemove: TagsApi.Tag): void {
    if (this.tags.length === 0) {
      return;
    }
    this.tags = this.tags.filter((tag) => tag.name !== tagToRemove.name);
    this.removeTag.emit(tagToRemove);
  }

  // Using $event.key instead of $event.which or $event.keyCode due to
  // deprecation
  public inputKeyPress($event: KeyboardEvent): void {
    if (
      !this.allowExistingOnly &&
      ($event.key === ',' || $event.key === 'Enter')
    ) {
      // Search item in translations and add the result of that to our tags array
      const found = this.suggestions.find(
        (f) =>
          f.name.trim().toLowerCase() === this.searchTerm.trim().toLowerCase()
      );
      if (found) {
        $event.preventDefault();
        this.selected(found);
        this.searchTerm = null;
        return;
      }

      $event.preventDefault();
      this.selected({
        name: this.searchTerm,
        title: this.searchTerm,
      } as TagsApi.Tag);
      this.searchTerm = null;
    }
  }

  public selected(tag: Partial<TagsApi.Tag>, event?: Event): void {
    // prevent the modal from closing
    event?.preventDefault();
    if (tag) {
      this.add(tag as TagsApi.Tag);
      this.shouldShowSuggestions = false;
      this.shouldShowCustomTag = false;
      this.focusTagSearch();
    }
  }

  public trackByTitle(index: number, item: TagsApi.Tag): string {
    return item.title;
  }

  public checkTagsInputOverflow() {
    const inputEl = this.tagsInputRef.nativeElement;
    inputEl.value = this.loadingJobRoleAISkillSuggestions
      ? this.i18n.OrgSkills_GeneratingSkills
      : this.placeholderText || this.i18n.Core_CategoryName;
    this.isTagsInputOverflowing = inputEl.scrollWidth > inputEl.clientWidth;
    inputEl.value = '';
  }

  private add(tag: TagsApi.Tag): void {
    if (tag?.title?.trim().length > 0) {
      this.addTag.emit(tag);
      this.searchTerm = null;
    }
  }

  private setEndorsedImg(isEndorsed: boolean): string {
    if (!isEndorsed) {
      return;
    }
    return this.orgEndorsedService.getEndorsedSrc(
      this.authUser.defaultOrgInfo.organizationId
    );
  }

  private removeListeners() {
    for (const listener of this.listeners) {
      listener();
    }
    this.listeners = [];
  }
}
