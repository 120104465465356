import { LDFlagsService } from '../ld-flags.service';

export class ReportingLDFlags {
  /***
   *  Team: Reporting
   *  Planned Cleanup: https://degreedjira.atlassian.net/browse/PD-102875
   *  Visibility:
   *    - Org 1
   *    - Degreed Orgs
   *
   *  Refactor the reporting date component to be reactive.
   */
  public get getReportingReactiveDateComponent(): boolean {
    return this.ldFlagsService.getFlag(
      'reporting-reactive-date-component',
      false
    );
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
