import { sortBy } from '@app/shared/utils/common-utils';
import { OrgTargetVM } from '@app/target/target-api.model';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { LoadOptions, OrgPlansState } from '../org-plans.model';

/**
 * Appends additional properties to plans to support FE usage
 *
 * @param plan
 * @param sharedTargetService
 */
export function preparePlan(
  plan: OrgTargetVM,
  sharedTargetService: SharedTargetService
) {
  // No orderBy pipe in ngx, so sort our authors here
  // Also add hide select for bulk options on Browse plans.
  plan.authored = plan.authored.sort((a, b) => sortBy(a, b, 'name'));
  plan.hideSelect = plan.targetType === 'Browse';
  plan.displayTargetType = sharedTargetService.getTypeDisplayName(
    plan.targetType,
    false,
    true
  );
  plan.id = plan.targetId.toString(); // for use with Elf Reactive Store
}

/**
 * Handler for updating plan facade state with optional loading overrides
 */
export function handleStateUpdates(
  hasMoreItems: boolean,
  options?: Partial<LoadOptions>
) {
  return (state: OrgPlansState) => {
    state.paging = {
      skip: options?.paging.skip ?? state.paging.skip,
      take: options?.paging.take ?? state.paging.take,
      hasMoreItems,
    };
    state.ordering = {
      orderBy: options?.ordering?.orderBy ?? state.ordering.orderBy,
      isDescending:
        options?.ordering?.isDescending ?? state.ordering?.isDescending,
    };
    state.term = options?.term ?? state.term;
    state.filterType = options?.filterType ?? state.filterType;
  };
}
