import { InjectionToken } from '@angular/core';

import type { Locales } from '../vendor/locales';

export type LocalesLazy = Promise<typeof Locales>;
export const LOCALES_LAZY = new InjectionToken<LocalesLazy>('LOCALES_LAZY');

let resolved: LocalesLazy = null;
export function localesLazySingleton(): LocalesLazy {
  if (!resolved) {
    resolved = import('../vendor/locales').then((m) => m.Locales);
  }

  return resolved;
}
