import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { isUrlValid } from '@app/shared/utils/common-utils';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { RendererContext, FormRenderer } from '../../form-renderer.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { RenderMode } from '../../user-input.model';
@Injectable({ providedIn: 'root' })
export class PathwayEventRenderer implements FormRenderer {
  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}
  render(
    context: RendererContext<any>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const urlValidation = {
      urlValidation: {
        expression: (control: AbstractControl) => {
          return control.value?.length > 0 ? isUrlValid(control.value) : true;
        },
        message: this.translate.instant('EventFormCtrl_ValidUrl'),
      },
    };
    return [
      this.builder
        .title()
        .asRequired()
        .withPlaceholder('EventFormCtrl_EventTitle')
        .autofocused()
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translate.instant('Core_FieldRequiredFormat', {
              fieldName: this.translate.instant('Core_Title'),
            }),
          },
        })
        .build(),
      this.builder
        .optionalTextInput('eventUrl', 'EventFormCtrl_EventSite')
        .ofType('url')
        .withPlaceholder('EventFormCtrl_EventUrl')
        .validatedByIndexed(urlValidation)
        .build(),
      this.commonFieldBuilder.buildDurationFields(
        this.ldFlagsService.durationForCoursesEventsOther &&
          context.inputContext.renderMode === RenderMode.Pathways,
        context.inputContext.inputType
      ),
      this.builder
        .optionalTextarea('summary', 'EventFormCtrl_Description')
        .withDgatId('eventForm-abd')
        .build(),
      // Add to Catalog
      this.commonFieldBuilder.buildPathwaysAddToCatalogField(
        context.inputContext.renderMode,
        context.state().canManageContent,
        context.state().organizationName,
        'EventFormCtrl_AddToCatalogFormat',
        context.templates.addToCatalogDupsHelp,
        context.state().onAddToCatalogChange,
        context.state().addToCatalog
      ),
      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
    ];
  }
}
