import { Injectable } from '@angular/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
@Injectable({ providedIn: 'root' })
export class SelectBookRendererService implements FormRenderer {
  public readonly i18n = this.translate.instant([
    'BookFormCtrl_BookLength',
    'BookFormCtrl_BookTitle',
    'BookFormCtrl_AddCategory',
    'BookFormCtrl_SelectBook',
    'BookFormCtrl_SaveBook',
    'Core_AddGroups',
    'Core_AddGroupsInfo',
    'Core_OpenDatePicker',
  ]);

  constructor(
    private formBuilder: DfFormFieldBuilder,
    // private authService: AuthService,
    private translate: TranslateService // private domainService: BookFormDomainService, // private authService: AuthService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(context: RendererContext): DfFormFieldConfig[] {
    const state = context.state();

    return [
      this.formBuilder
        .customField(
          'title',
          'Core_Title',
          context.templates.bookTitleSuggest,
          {
            placeholder: this.i18n.BookFormCtrl_BookTitle,
            vm: state.formFields.titleSuggest,
            required: true,
          }
        )
        .asRequired()
        .autofocused()
        .build(),
    ];
  }
}
