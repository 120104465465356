import { Injectable } from '@angular/core';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import {
  NormalizedRecommendation,
  RecommendationStatus,
} from '@app/recommendations/recommendations.api';
import { RecommendationType } from '@app/recommendations/services/recommendations.service';
import {
  InputDetails as PascalCaseInputDetails,
  Tag as PascalCaseTag,
  Target as PascalCaseTarget,
} from '@app/shared/ajs/pascal-cased-types.model';
import { ModifyOptionsFnType } from '@app/shared/components/menu/menu.component';
import { ActionOptionHelpersService } from '@app/shared/services/action-option-helpers.service';
import { TranslateService } from '@ngx-translate/core';

// This represents the resource types that can be passed to the tileActionOptions config onClick and isVisible callback functions for profile assignments
export type TileActionOptionsResource =
  | PascalCaseTag
  | PascalCaseInputDetails
  | PascalCaseTarget;

/**
 * A service to create action options config for profile tiles.
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileActionOptionsConfigService {
  public readonly requiredType = RecommendationType.RequiredLearning;
  public i18n = this.translateService.instant(['Core_Remove']);
  constructor(
    private actionOptionHelpersService: ActionOptionHelpersService,
    private translateService: TranslateService
  ) {}

  public getModifyOptionsFn({
    recommendation,
    processChange,
  }: {
    recommendation: NormalizedRecommendation;
    processChange: (
      recommendation: NormalizedRecommendation,
      status: RecommendationStatus
    ) => any;
  }): ModifyOptionsFnType<LearningResourceViewModel> {
    return (content, defaultMenuConfig) => [
      ...defaultMenuConfig,
      {
        title: this.i18n.Core_Remove,
        isHidden: () => {
          return (
            this.isRequired(recommendation) ||
            content.completionInfo?.isCompleted
          );
        },
        defaultAction: (event) => {
          this.actionOptionHelpersService.deleteRecommendation(
            recommendation,
            event,
            () => processChange(recommendation, 'Dismissed')
          );
        },
      },
    ];
  }

  private isRequired(item: NormalizedRecommendation) {
    return (
      item.recommendationType === this.requiredType &&
      (item.creator === undefined || item.creator === null)
    );
  }
}
