import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';

// services
import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { Observable } from 'rxjs';
import { TagsApi } from '@app/tags/tag-api.model';

@Injectable({ providedIn: 'root' })
export class InputRatingsService {
  private i18n = this.translate.instant(['InputRatingsSvc_SaveRatingError']);

  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private inputsService: InputsService
  ) {}

  /**
   * Use to make a lazy call to update the rating, but
   * immediately return the updated like/dislike counts.
   * (Equivalent to the old ActionOptions / CardActionOptions.rate
   * methods.)
   *
   * @param rating
   * @param itemId
   * @param itemType
   * @param item
   */
  public rateItem(
    resource: any,
    newRating: number,
    tags?: string
  ): { likeCount: number; dislikeCount: number } {
    const oldRating = resource.requestingUserRating || 0;
    let likeCount = resource.statistics?.likeCount || 0;
    let dislikeCount = resource.statistics?.dislikeCount || 0;

    this.saveRating(
      newRating,
      resource.resourceId,
      resource.resourceType,
      resource.getTrackingCopy(),
      tags || resource.tags
    ).subscribe();

    if (oldRating === 0) {
      if (newRating === 1) {
        likeCount++;
      } else if (newRating === 2) {
        dislikeCount++;
      }
    } else if (oldRating === 1) {
      if (newRating === 0) {
        likeCount = likeCount ? likeCount - 1 : 0;
      } else if (newRating === 2) {
        likeCount = likeCount ? likeCount - 1 : 0;
        dislikeCount++;
      }
    } else {
      if (newRating === 1) {
        likeCount++;
        dislikeCount = dislikeCount ? dislikeCount - 1 : 0;
      } else if (newRating === 0) {
        dislikeCount = dislikeCount ? dislikeCount - 1 : 0;
      }
    }

    return { likeCount, dislikeCount };
  }

  /**
   * Use to directly call the API method and return
   * an Observable.
   *
   * @param rating - The new rating.
   * @param itemId - The item ID.
   * @param itemType - The item type.
   * @param item - Tracking information about the item.
   * @param tags -The tags for the Item
   */
  public saveRating(
    rating: number,
    itemId: number,
    itemType: string,
    item: any,
    tags?: TagsApi.Tag[]
  ): Observable<unknown> {
    return this.http
      .post('/inputs/addrating', {
        Type: itemType,
        ItemId: itemId,
        Rating: rating,
        Tags: tags,
      })
      .pipe(
        tap(() => {
          this.inputsService.notifyInputModified(itemType);
          this.tracker.trackEventData({
            action:
              rating === 1
                ? 'Content Liked'
                : rating === 2
                ? 'Content Disliked'
                : 'Content Rating Removed',
            category: itemType,
            label: rating.toString(),
            properties: item,
          });
        }),
        catchError(({ status }, o) => {
          if (status !== 0) {
            this.notifier.showError(this.i18n.InputRatingsSvc_SaveRatingError);
          }
          return null;
        })
      );
  }
}
