import { Options } from '@angular-slider/ngx-slider';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DecodeHtmlPipe } from '@app/shared/pipes/decode-html.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dgx-tag-level-description-modal',
  templateUrl: './tag-level-description-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagLevelDescriptionModalComponent implements OnInit {
  /* eslint-disable @typescript-eslint/member-ordering */
  @Input() public sliderConfig: Options;
  @Input() public set level(level: number) {
    this.setLevel(level);
  }
  public get level(): number {
    return this.level$.getValue();
  }

  private level$ = new BehaviorSubject<number>(1);
  public i18n: { [key: string]: string } = {};
  public skillAttributeKeys: string[];

  public levelTitle: Observable<string> = this.level$.pipe(
    map((level) => {
      const name = this.getLevelName(level);
      return this.translateService.instant('Cred_levelTitle', {
        level,
        name,
      });
    })
  );

  constructor(
    private translateService: TranslateService,
    private tagsService: TagsService,
    private decodeHtml: DecodeHtmlPipe,
    private authService: AuthService
  ) {}

  public get lowestLevelName(): string {
    return this.getLevelName(this.min);
  }

  public get highestLevelName(): string {
    return this.getLevelName(this.max);
  }

  private get min(): number {
    return this.authService.authUser?.orgRatingScale?.anchorLow;
  }

  private get max(): number {
    return this.authService.authUser?.orgRatingScale?.anchorHigh;
  }

  public ngOnInit(): void {
    this.skillAttributeKeys = [
      'dgTagRating_Knowledge',
      'dgTagRating_TasksTools',
      'dgTagRating_Autonomy',
      'dgTagRating_Industry',
      'dgTagRating_LevelUp',
    ];
    this.i18n = this.translateService.instant([
      ...this.skillAttributeKeys,
      'Core_Close',
      'dgTagRating_Beginner',
      'dgTagRating_Expert',
      'dgTagRating_Levels',
    ]);
  }

  public setLevel(level: number): void {
    this.level$.next(level);
  }

  public getAttrDescription(topic: string): Observable<string> {
    /**
     * Something to match against in case someone tries searching for the strings generated here
     *
     * dgTagRating_Knowledge_Desc_Scale
     * dgTagRating_TasksTools_Desc_Scale
     * dgTagRating_Autonomy_Desc_Scale
     * dgTagRating_Industry_Desc_Scale
     * dgTagRating_LevelUp_Desc_Scale
     */
    return this.level$.pipe(
      map((level) => {
        return this.decodeHtml.transform(
          this.translateService.instant(
            `${topic}_Desc_Scale${this.max}_${level}`
          )
        );
      })
    );
  }

  private getLevelName(level: number): string {
    return (
      this.tagsService.getNameForRatingLevel(level) ||
      this.translateService.instant(`Cred_levelName${level}`)
    );
  }
}
