<button
  [dfPopoverTrigger]="productSwitcherPopover"
  data-dgat="product-switcher-7a2"
  class="product-switcher__trigger rounded--large js-product-switcher__trigger"
  [ngClass]="{
    'product-switcher__trigger--light': hasLightText
  }"
  [ngbTooltip]="'Switch Apps'"
  [attr.aria-expanded]="ariaExpanded"
  placement="left"
>
  <df-icon
    *ngIf="!refreshIcon"
    size="medium"
    icon="grid"
    [a11yText]="'A11y_ToggleProductMenu' | translate"
  ></df-icon>

  <svg
    *ngIf="refreshIcon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="product-switcher__refresh-icon"
  >
    <title>Toggle Product Menu</title>
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
    />
  </svg>
</button>
<df-popover
  #productSwitcherPopover
  [isAppendToBody]="false"
  [isFocusFirstItem]="false"
  placement="bottom-right"
  class="product-switcher__popover"
>
  <div class="guts-p-v-1 product-switcher__popover-content">
    <span class="h3 guts-p-b-1-half guts-p-h-2">{{
      'OrgManage_SwitchTo' | translate
    }}</span>
    <div role="menu">
      <a
        role="menuitem"
        class="product-switcher__product l_flexbar guts-p-v-1 guts-p-h-1 listbox--option"
        data-dgat="product-switcher-398"
        [href]="lxpPath"
      >
        <img
          class="product-switcher__icon guts-m-r-1"
          [src]="homeImg | blobifyUrl: true"
          alt=""
        />
        <span>{{ 'Core_Home' | translate }}</span>
      </a>
      <a
        attr.aria-label="{{
          'dgUtilityBar_ManageOrg'
            | translate: { orgName: 'OrgManage_ExtendedEnterprise' | translate }
        }}"
        role="menuitem"
        class="product-switcher__product l_flexbar guts-p-v-1 guts-p-h-1 listbox--option"
        data-dgat="product-switcher-398"
        *ngIf="hasExtendedEnterprise"
        href="/channel/"
      >
        <img
          class="product-switcher__icon guts-m-r-1"
          [src]="
            '/content/img/product-switcher/extended-enterprise@2x.png'
              | blobifyUrl: true
          "
          alt=""
        />
        <span>
          <span>{{ 'OrgManage_Manage' | translate }}</span>
          <br />
          <span class="font-bold product-switcher__wide-content">{{
            'OrgManage_ExtendedEnterprise' | translate
          }}</span>
        </span>
      </a>

      <ng-container
        *ngFor="let org of orgInfo"
        data-dgat="product-switcher-fc9"
      >
        <div>
          <a
            role="menuItem"
            attr.aria-label="{{
              'dgUtilityBar_ManageOrg' | translate: { orgName: org.name }
            }}"
            class="product-switcher__product l_flexbar guts-p-v-1 guts-p-h-1 listbox--option"
            *ngIf="canViewManageOrgLink"
            data-dgat="product-switcher-4e2"
            href="/orgs/{{ org.organizationId }}"
          >
            <img
              class="product-switcher__icon guts-m-r-1"
              [src]="
                '/content/img/product-switcher/learner-experience@2x.png'
                  | blobifyUrl: true
              "
              alt=""
            />
            <span>
              <span>{{ 'OrgManage_Manage' | translate }}</span>
              <br />
              <span class="font-bold">{{ org.name }}</span>
            </span>
          </a>
        </div>
      </ng-container>
      <a
        *ngIf="hasAnalytics"
        role="menuitem"
        attr.aria-label="{{
          'OrgManage_Analytics_MenuItem_Label' | translate
        }} {{ 'OrgManage_Analytics_MenuItem_Text' | translate }}"
        class="product-switcher__product l_flexbar guts-p-v-1 guts-p-h-1 listbox--option"
        data-dgat=""
        [attr.href]="analyticsUrl"
      >
        <img
          class="product-switcher__icon guts-m-r-1"
          [src]="
            '/content/img/product-switcher/skill-analytics@2x.png'
              | blobifyUrl: true
          "
          alt=""
        />
        <span>
          <span>{{ 'OrgManage_Analytics_MenuItem_Label' | translate }}</span>
          <br />
          <span class="font-bold">{{
            'OrgManage_Analytics_MenuItem_Text' | translate
          }}</span>
        </span>
      </a>

      <a
        *ngIf="hasSkillsPlatform"
        role="menuitem"
        attr.aria-label="{{ 'OrgManage_Manage' | translate }} Degreed Skills"
        class="product-switcher__product l_flexbar guts-p-v-1 guts-p-h-1 listbox--option"
        href="/skillsplatform"
        data-dgat="product-switcher-71a"
      >
        <img
          class="product-switcher__icon guts-m-r-1"
          [src]="
            '/content/img/product-switcher/skills-platform@2x.png'
              | blobifyUrl: true
          "
          alt=""
        />
        <span>
          <span class="font-bold">Degreed Skills</span>
        </span>
      </a>

      <a
        *ngIf="learnInSSOUrl$ | async as learnInSSOUrl"
        role="menuitem"
        attr.aria-label="{{ 'Core_AcademiesMarketplace' | translate }}"
        class="product-switcher__product l_flexbar guts-p-v-1 guts-p-h-1 listbox--option"
        [href]="learnInSSOUrl"
        data-dgat="product-switcher-4ee"
      >
        <img
          class="product-switcher__icon guts-m-r-1"
          [src]="
            '/content/img/product-switcher/academies@2x.png' | blobifyUrl: true
          "
          alt=""
        />
        <span class="academies">{{
          'Core_AcademiesMarketplace' | translate
        }}</span>
      </a>
    </div>
  </div>
</df-popover>
