import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { FlexEdDetails } from '@app/shared/services/flexed.service';
import { ModalService } from '@app/shared/services/modal.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FlexEdModalComponent } from './../components/flex-ed-modal/flex-ed-modal.component';

export interface FlexEdTransaction {
  accountId: number;
  amount: number;
  dateImported: string;
  description: string;
  lastModifiedDate: string;
  organizationId: number;
  pexTransactionId: number;
  settlementTime: string;
  transactionId: number;
  transactionTime: string;
}

@Injectable({
  providedIn: 'root',
})
export class FlexEdService {
  constructor(
    private http: NgxHttpClient,
    private modalService: ModalService,
    private authService: AuthService
  ) {}

  public getTransactionHistory(
    userKey: number
  ): Observable<FlexEdTransaction[]> {
    return this.http.get<FlexEdTransaction[]>(
      '/organizations/pexcardtransactions',
      {
        params: {
          id: userKey,
        },
      }
    );
  }

  public showUpdateFlexCardModal(options: {
    orgId: number;
    cardDetails?: FlexEdDetails;
  }) {
    const updateCardDetails = (cardInfo: {
      cardNumber: number;
      name: string;
    }) => {
      return this.validateCardDetails({
        organizationId: options.orgId,
        name: cardInfo.name,
        cardNumber: cardInfo.cardNumber,
      });
    };

    const inputs = {
      ...options,
      updateCardDetails,
    };

    return this.modalService.show(FlexEdModalComponent, {
      inputs,
    });
  }

  public validateCardDetails(data: {
    organizationId: number;
    name: string;
    cardNumber: number;
  }) {
    return this.http
      .get('/organizations/associatepexcard', {
        params: data,
      })
      .pipe(
        tap(() => {
          this.authService.authUser.isPexUser = true;
        })
      );
  }
}
