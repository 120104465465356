import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';
import { catchError, tap } from 'rxjs/operators';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { PathwayService } from '@app/pathways/services/pathway.service';

@Injectable({
  providedIn: 'root',
})
export class PathwayHoldService {
  public i18n = this.translate.instant([
    'Pathways_AddItemPermissionError',
    'Pathways_HoldForLaterError',
    'Pathways_PathwayItemError',
    'Pathways_RemoveItemError',
    'Pathways_AddItemError',
  ]);
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private pathwayService: PathwayService
  ) {}

  public getPathAuthoringBin = (pathId: number) => {
    return this.http
      .get<LearningResourceViewModel[]>('/pathways/getpathwayauthoringbin', {
        params: { pathId: pathId },
      })
      .pipe(
        catchError((e) => {
          return throwError(new DgError(e));
        })
      );
  };

  public removeItemFromBin = (pathId: number, item) => {
    return this.http
      .post<void>('/pathways/removeinputfrompathway', {
        PathId: pathId,
        LevelNumber: '',
        LessonNumber: '',
        StepNumber: '',
        Item: item,
      })
      .pipe(
        tap(() => {
          this.pathwayService.updateVersion(pathId);
        }),
        catchError((e) => {
          return throwError(new DgError(e));
        })
      );
  };

  /**
   * Returns number of bin (Hold for Later) items in the pathway
   * @param pathId ID of the bin's Pathway
   */
  public getBinCount = (pathId: number) => {
    return this.http
      .get<number>('/pathways/getpathwaybincount', {
        params: { pathId: pathId },
      })
      .pipe(
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.translate.instant(`Pathways_PathwayItemError`),
              error
            )
          );
        })
      );
  };
}
