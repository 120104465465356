<!--Since this is a custom field, this is a similar setup to what .describedAs does to replace placeholders-->
<p id="markdownEditor-desc" class="form-label-description">
  {{ placeholder }}
</p>
<div class="markdown-editor">
  <div role="toolbar" class="markdown-editor__toolbar" *ngIf="!ngDisabled">
    <div
      class="markdown-editor__toolbar-group"
      *ngFor="let toolGroup of toolbarConfig"
      data-dgat="markdownEditor-e90"
    >
      <span *ngFor="let button of toolGroup" data-dgat="markdownEditor-d35">
        <button
          *ngIf="button.type !== imageType"
          type="button"
          class="markdown-editor__button a11y-toolitem"
          [attr.aria-label]="i18n['Markdown_toolbarA11y_' + button.type]"
          [ngbTooltip]="i18n['Markdown_toolbarA11y_' + button.type]"
          (click)="applyStyle(button.type)"
          data-dgat="markdownEditor-3c7"
        >
          <df-icon icon="{{ button.icon }}"></df-icon>
        </button>
        <button
          *ngIf="canUploadImages && button.type === imageType"
          type="button"
          class="markdown-editor__button a11y-toolitem"
          [attr.aria-label]="i18n['Markdown_toolbarA11y_' + button.type]"
          [ngbTooltip]="i18n['Markdown_toolbarA11y_' + button.type]"
          data-dgat="markdownEditor-3c7"
          (click)="inputRef.click()"
        >
          <df-icon icon="{{ button.icon }}"></df-icon>
          <input
            type="file"
            #inputRef
            id="inputRef"
            class="hidden-without-display aria-hidden"
            (change)="onFileInputChange($event)"
            data-dgat="markdown-editor-2bb"
            tabindex="-1"
          />
        </button>
      </span>
    </div>
  </div>
  <div
    [ngClass]="{
      is_disabled: ngDisabled,
      'markdown-editor__dropzone--over': canUploadImages && uploaderFocused
    }"
    dgxUploader
    (fileDropped)="onFileSelected($event)"
    (dragState)="onChangeDragState($event)"
    class="markdown-editor__dropzone"
  >
    <dgx-character-limit-input
      [limit]="maxLength"
      [text]="field"
      *ngIf="showCharacterCount; else markdownTextArea"
    >
      <ng-container
        [ngTemplateOutlet]="markdownTextArea"
        ngProjectAs="textarea"
      ></ng-container>
    </dgx-character-limit-input>
  </div>
  <div *ngIf="ngControl?.dirty && ngControl?.invalid">
    <dgx-validate-field
      *ngIf="showContentErrors"
      [message]="contentErrorMsg"
    ></dgx-validate-field>
  </div>
  <div
    id="{{ textareaId }}-help"
    *ngIf="helperText"
    class="par par--small par--light guts-p-t-quart"
  >
    {{ helperText }}
  </div>
</div>

<ng-template #markdownTextArea>
  <textarea
    class="markdown-editor__text full-width js-mde-textarea"
    [class.markdown-editor--characterCount]="showCharacterCount"
    name="dgMarkdownEditor"
    id="{{ textareaId }}"
    [(ngModel)]="field"
    [style.min-height]="minHeight"
    [dgxAutofocus]="shouldHaveFocus"
    [disabled]="ngDisabled"
    [attr.aria-describedby]="
      'markdownEditor-desc ' + (helperText ? textareaId + '-help' : undefined)
    "
    [attr.aria-label]="textareaLabel"
    (ngModelChange)="propagateChange($event)"
    (blur)="onTextareaBlur()"
    [maxlength]="maxLength"
    data-dgat="markdownEditor-a5a"
  ></textarea>
</ng-template>
