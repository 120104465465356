<figure
  tabindex="0"
  class="box-shadow rounded--large center-text guts-p-v-2 guts-p-h-quart grid__col-auto"
>
  <span
    class="h1 ib-v-middle a11y-hide"
    id="{{ 'headerDescription-' + kpiIndex }}"
    >{{ kpi.metric }} {{ kpi.description }}</span
  >
  <h3
    class="h1 ib-v-middle"
    [attr.aria-labelledby]="'headerDescription-' + kpiIndex"
  >
    <a
      *ngIf="kpi.url; else noLink"
      [attr.href]="kpi.url"
      [attr.aria-labelledby]="'headerDescription-' + kpiIndex"
      target="_self"
      data-dgat="kpi-item-33d"
    >
      {{ kpi.metric }}
    </a>
    <ng-template #noLink>
      {{ kpi.metric }}
    </ng-template>
  </h3>
  <div *ngIf="kpi.description" class="guts-p-t-quart v-bottom">
    <p class="par par--light">
      <span aria-hidden="true">{{ kpi.description }}</span>
      <span
        class="guts-p-h-quart"
        *ngIf="kpi.tooltip"
        tabindex="0"
        [attr.aria-label]="kpi.tooltip"
        [ngbTooltip]="kpi.tooltip"
        placement="bottom"
        (mouseenter)="trackHover(kpi)"
      >
        <df-icon
          icon="question-mark-circle"
          class="color-ebony-a45 text-top"
          role="text"
        ></df-icon>
      </span>
    </p>
  </div>
  <div class="guts-p-t-1" *ngIf="showKPITrend" id="showtrendtest">
    <span class="guts-p-h-half">
      <df-icon
        *ngIf="kpi.trend > 0"
        icon="triangle-up"
        size="medium"
        class="color-increase"
        [attr.aria-label]="i18n.KPI_Increase"
      ></df-icon>
      <df-icon
        *ngIf="kpi.trend < 0"
        icon="triangle-down"
        size="medium"
        class="color-decrease"
        [attr.aria-label]="i18n.KPI_Decrease"
      ></df-icon>
      <df-icon
        *ngIf="kpi.trend * 1 === 0"
        icon="triangle-up"
        size="medium"
        class="color-ebony-a61 rotate-icon-90"
        [attr.aria-label]="i18n.KPI_NoChange"
      ></df-icon>
      <span class="font-semibold zeta" tabindex="0"
        >{{ kpi.trendDisplay }}%&nbsp;{{ kpi.trendOverTime }}</span
      >
    </span>
  </div>
</figure>
