import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { StripMarkupPipe } from '@app/shared/pipes/strip-markup.pipe';
import {
  PathwayDetailsModel,
  PathwaySection,
  PathwaySubsection,
  sectionTitleToAriaLabel,
} from '@dg/pathways-rsm';
import { TranslateService } from '@ngx-translate/core';

export interface ActivationEvent {
  section: number;
  subsection?: number;
  source?: any; // originating event
}

/**
 * Used by the PathwayAuthor/SectionNav components.
 */
@Directive()
export abstract class PathwayNavBaseDirective {
  @Input() public pathway: PathwayDetailsModel;
  @Input() public activeSection: number;
  @Input() public activeSubsection: number;
  @Output() public sectionClicked = new EventEmitter<ActivationEvent>();
  @Output() public subsectionClicked = new EventEmitter<ActivationEvent>();

  public readonly baseI18n = [
    'Core_Completion_Progress',
    'Pathways_UntitledSection',
    'Pathways_UntitledLesson',
  ];
  public abstract readonly i18n: Record<string, string>;

  constructor(
    private stripMarkupPipe: StripMarkupPipe,
    protected translate: TranslateService
  ) {}

  // ***************************
  // PUBLIC -------------------
  // Abstract Methods
  // ***************************

  /**
   * Whether a section has a faux subsection.
   *
   * @param section
   * @param fauxSubsections
   */
  public abstract hasFauxSubsection(
    section: PathwaySection,
    fauxSubsections: string[]
  ): boolean;

  /**
   * Check for valid nav subsections.
   *
   * @param subsections - The subsections to check.
   */
  public abstract shouldShowNavSubsections(
    subsections: PathwaySubsection[]
  ): boolean;

  // ***************************
  // Methods
  // ***************************

  /**
   * Return any passed-in number as a string.
   *
   * @param item - The number to stringify.
   */
  public asString(item: number): string {
    return item.toString();
  }

  /**
   * Display section title or (translated) Untitled Section.
   * Strips HTML for display.
   *
   * @param sectionTitle - The section title to check.
   */
  public getSectionTitle(sectionTitle: string): string {
    return sectionTitle
      ? this.stripMarkupPipe.transform(sectionTitle)
      : this.i18n.Pathways_UntitledSection;
  }

  /**
   * Display section title or (translated) Untitled Section as ARIA title.
   * Strips HTML for display.
   *
   * @param sectionTitle - The section title to check.
   */
  public getSectionTitleForAriaLabel(
    sectionNumber: number,
    sectionTitle: string,
    completionProgress = '0'
  ) {
    return sectionTitleToAriaLabel(
      sectionNumber,
      sectionTitle
        ? this.stripMarkupPipe.transform(sectionTitle)
        : this.i18n.Pathways_UntitledSection,
      `${this.i18n.Core_Completion_Progress} ${completionProgress}%`
    );
  }

  /**
   * Display subsection title or (translated) Untitled Subsection.
   * Strips HTML for display.
   *
   * @param sectionTitle - The section title to check.
   */
  public getSubsectionTitle(subsectionTitle: string): string {
    return subsectionTitle
      ? this.stripMarkupPipe.transform(subsectionTitle)
      : this.i18n.Pathways_UntitledLesson;
  }

  /**
   * Whether the current section is the active section.
   *
   * @param currentSection - Current section number.
   */
  public isActiveSection(currentSection: number): boolean {
    return this.activeSection === currentSection;
  }

  /**
   * Whether the current subsection is the active subsection.
   *
   * @param currentSection - Current subsection number.
   */
  public isActiveSubsection(currentSubsection: number): boolean {
    return this.activeSubsection === currentSubsection;
  }

  /**
   * Emits section-clicked event.
   *
   * @param event - Click event.
   * @param section - Pathway section.
   */
  public sectionClick(event: Event, section: PathwaySection) {
    event?.preventDefault();
    this.sectionClicked.emit({ section: section.number });
  }

  /**
   * Whether the subsections of a given section should be displayed.
   *
   * @param section - The section in question.
   */
  public shouldShowSubsections(
    section: PathwaySection,
    fauxSubsections?: string[]
  ): boolean {
    return (
      this.isActiveSection(section.number) &&
      this.shouldShowNavSubsections(section.lessons) &&
      !this.hasFauxSubsection(section, fauxSubsections || [])
    );
  }

  /**
   * Emits subsection-clicked event.
   *
   * @param event - Click event.
   * @param section - Pathway subsection.
   */
  public subsectionClick(
    event: Event,
    section: PathwaySection,
    subsection: PathwaySubsection
  ) {
    event?.preventDefault();
    this.subsectionClicked.emit({
      section: section.number,
      subsection: subsection.number,
    });
  }

  /**
   * Track by (sub)section ID.
   *
   * @param index - The ngFor index.
   * @param item - The (sub)section.
   */
  public trackById(_: number, { id }: PathwaySection | PathwaySubsection) {
    return id;
  }
}
