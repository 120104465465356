import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { OrgRatingScaleLevel } from '@app/account/account-api.model';
import { TagsService } from '@app/tags/services/tags.service';

@Component({
  selector: 'dgx-tag-rating-modal-change-rating',
  templateUrl: './tag-rating-modal-change-rating.component.html',
  styleUrls: ['./tag-rating-modal-change-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingModalChangeRatingComponent {
  @Input() public currentLevel: number;
  @Input() public isDisabled: boolean;
  @Input() public defaultText: string;
  @Output() ratingChanged = new EventEmitter<number>();

  public placementOffsetLeftPixels: number;
  public isPopoverOpen: boolean;
  public orgLevels: OrgRatingScaleLevel[];

  constructor(private tagsService: TagsService) {}

  public ngOnInit(): void {
    this.orgLevels = this.tagsService.orgLevels;
  }

  public onRating(rating: number): void {
    this.currentLevel = rating;
    this.ratingChanged.emit(this.currentLevel);
  }

  public popoverToggled(): void {
    this.isPopoverOpen = !this.isPopoverOpen;
  }
}
