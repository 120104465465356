<dgx-modal
  bodyClasses="guts-p-h-2 form-wrap"
  [useDefaultSubmitButton]="false"
  (dismiss)="close()"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    {{ 'dgAuthorVideo_ModalTitle' | translate }}
  </ng-container>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <dgx-author-video
      (uploadResult)="emitUploadResult($event)"
    ></dgx-author-video>
  </ng-container>
</dgx-modal>
