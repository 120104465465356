import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DgError } from '@app/shared/models/dg-error';
import {
  AddModalResults,
  PathwayDetailsModel,
  PathwayStep,
} from '@app/pathways/pathway-api.model';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { TrackerService } from '@app/shared/services/tracker.service';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { PathwayService } from '@app/pathways/services/pathway.service';
import { privacyNumFromPrivacyLevel } from '@app/shared/utils/visibility-helpers';
import { NotifierService } from '@app/shared/services/notifier.service';
@Injectable({
  providedIn: 'root',
})
export class PathwayAddContentService {
  public i18n = this.translate.instant([
    'Pathways_AddItemPermissionError',
    'Pathways_HoldForLaterError',
    'Pathways_PathwayItemError',
    'Pathways_RemoveItemError',
    'Pathways_AddItemError',
  ]);

  constructor(
    private notifier: NotifierService,
    private http: NgxHttpClient,
    private translate: TranslateService,
    private tracker: TrackerService,
    private displayTypePipe: DisplayTypePipe,
    private pathwayService: PathwayService
  ) {}

  public addInputsToBin = (
    pathId: number,
    inputs: LearningResourceViewModel[]
  ) => {
    const items = inputs.map((input) => ({
      InputId: input.resourceId,
      InputType: input.resourceType,
    }));
    return this.http
      .post('/pathways/addpathwaybinitems', {
        pathId,
        inputs: items,
      })
      .pipe(
        tap(() => {
          this.pathwayService.updateVersion(pathId);
        }),
        catchError((e) => {
          return throwError(
            new DgError(this.i18n.Pathways_HoldForLaterError, e)
          );
        })
      );
  };

  /**
   * Used for adding a single Input to the Hold For Later bin in pathways
   * Note: anything updated here should probably also be updated in the Degreed Button (ebb) version of PathwayAddContentSvc
   */
  public addInputToBin = (
    pathId: number,
    inputType: InputIdentifier['inputType'],
    inputId: InputIdentifier['inputId'],
    element?: HTMLElement
  ) => {
    const inputInfo = { InputId: inputId, InputType: inputType };
    return this.http
      .post<PathwayStep>('/pathways/addpathwaybinitembyid', {
        PathId: pathId,
        InputIdentifier: inputInfo,
      })
      .pipe(
        tap((response) => {
          this.tracker.trackEventData({
            action: 'Content Added To Pathway Bin',
            category: '',
            element,
            label: '',
            properties: response,
          });
        }),
        catchError((e) => {
          if (e.status === 403) {
            return throwError(
              new DgError(this.i18n.Pathways_AddItemPermissionError, e)
            );
          } else {
            // the filter inputType should have been localized.
            return throwError(
              new DgError(
                this.translate.instant('Pathways_AddingInputTypeError', {
                  inputType: this.displayTypePipe
                    .transform(inputType, false)
                    .toLowerCase(),
                }),
                e
              )
            );
          }
        })
      );
  };

  public getPathAuthoringBin = (pathId: number) => {
    return this.http
      .get<PathwayStep[]>('/pathways/getpathwayauthoringbin', {
        params: { pathId: pathId },
      })
      .pipe(
        catchError((e) => {
          return throwError(new DgError(e));
        })
      );
  };

  public removeItemFromBin = (pathId: number, item) => {
    return this.http
      .post<void>('/pathways/removeinputfrompathway', {
        PathId: pathId,
        LevelNumber: '',
        LessonNumber: '',
        StepNumber: '',
        Item: item,
      })
      .pipe(
        tap(() => {
          this.pathwayService.updateVersion(pathId);
        }),
        catchError((e) => {
          return throwError(new DgError(e));
        })
      );
  };

  public updateButtonText = (savedItems: LearningResourceViewModel[]) => {
    if (savedItems?.length > 1) {
      return this.translate.instant('Pathways_AddXItems', {
        count: savedItems.length,
      });
    } else if (savedItems?.length === 1) {
      return this.translate.instant('Pathways_AddOneItem');
    } else {
      return this.translate.instant('Pathways_AddXItems', {
        count: '',
      });
    }
  };

  public getBinCount = (pathId: number) => {
    return this.http
      .get<number>('/pathways/getpathwaybincount', {
        params: { pathId: pathId },
      })
      .pipe(
        catchError((e) => {
          return throwError(new DgError(e));
        })
      );
  };

  /**
   * Handles adding content to pathway and updating pathway
   *
   * @param pathway - Current pathway content is to added to
   * @param modalResults - Results returned from Add to Content modal
   * @param afterNode - Node content should be added after
   * @param beforeNode - Node content should be added before
   */
  public handleAddContent(
    pathway: PathwayDetailsModel,
    modalResults: AddModalResults,
    afterNode: string,
    beforeNode: string
  ): Observable<AddModalResults> {
    return this.addContentToLesson(
      modalResults.savedItems,
      afterNode,
      beforeNode,
      pathway.id,
      modalResults.isBin,
      modalResults.selectedNodes
    ).pipe(
      switchMap((data) => {
        Object.assign(pathway, data);
        pathway.privacyLevel = privacyNumFromPrivacyLevel(data.privacyLevel);
        return of(modalResults);
      }),
      tap(() => {
        this.tracker.trackEventData({
          action: this.getTrackingAction(modalResults),
          label: 'Pathway',
          properties: {
            PathwayId: pathway.id,
          },
        });
      })
    );
  }

  /**
   * Used for adding one or more steps to a specific lesson in a given pathway.
   *
   * @param steps - Array of Step objects
   * @param afterNode - Either the Step Node to place the content after, or the Lesson Node itself to append to the end of the Lesson automatically
   * @param beforeNode - Can be used in place of afterNode (less common)
   * @param pathwayId - The pathway Id the Node above belongs to
   * @param isBin - (optional) Only used to indicate whether the specified set of inputs came from the Hold For Later bin.
   * @param selectedNodes - (optional) Only used if Step came from the Hold For Later bin.
   */
  public addContentToLesson(
    contents: LearningResourceViewModel[],
    afterNode: string,
    beforeNode: string | null = null,
    pathwayId: number,
    isBin?: boolean,
    selectedNodes?: string[],
    element?: HTMLElement
  ): Observable<PathwayDetailsModel> {
    const uri = isBin
      ? '/pathways/addpathwayinputsfrombin'
      : '/pathways/addpathwayinputs';

    const inputs = contents.map((content) => ({
      InputId: content.resourceId,
      InputType: content.resourceType,
    }));
    const data = {
      nodes: isBin ? selectedNodes : null,
      inputs: isBin ? null : inputs,
      afterNode: afterNode,
      beforeNode: beforeNode,
      pathId: pathwayId,
    };
    return this.http.post<PathwayDetailsModel>(uri, data).pipe(
      tap(() => {
        this.pathwayService.updateVersion(pathwayId);
        let trackData = [];
        for (const content of contents) {
          // Add-by-search contains full LearningResourceModel in this case we just want the model.
          const trackingContent = content.model ?? content;
          trackData = [
            ...trackData,
            {
              element,
              eventName: 'Content Added To Pathway',
              properties: {
                ...trackingContent,
                PathwayId: pathwayId,
                OrganizationId: content.organizationId,
              },
            },
          ];
        }
        this.tracker.trackBatchEvents(trackData);
      }),
      catchError((error) => {
        if (error.status === 409) {
          this.notifier.showError(
            this.translate.instant('Pathways_SetPathwayNameError')
          );
        }
        return throwError(
          new DgError(this.translate.instant(`Pathways_AddItemError`), error)
        );
      })
    );
  }

  // Get tracking action name
  private getTrackingAction(result) {
    const whichModal = result.whichModal;
    let action = 'Content Added ';
    switch (whichModal) {
      case 'search':
        action += 'by Search';
        break;
      case 'manual':
        action += 'by Type';
        break;
      case 'bin':
        action += 'from Bin';
        break;
    }
    return action;
  }
}
