<form class="form-wrap" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="!formValid && isSubmitting"
    [submitButtonText]="buttonText | translate"
    [useDefaultForm]="false"
    bodyClasses="guts-p-1"
  >
    <ng-container class="modal-header">
      {{ headerText | translate }}
    </ng-container>
    <ng-container class="modal-body">
      <!-- User search typeahead -->
      <div class="guts-p-full-1">
        <div class="rel">
          <dgx-user-search
            [excludeSelectedRecipients]="true"
            [recipients]="selectedUsers"
            [ariaLabel]="ariaLabel"
            (selectRecipient)="onSelect($event)"
            (removeRecipient)="onRemove($event)"
          ></dgx-user-search>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>
