import { LDFlagsService } from '../ld-flags.service';

export class PathwaysLDFLags {
  /***
   *  Team: CCO
   *  Created Date: TBD
   *  Planned Cleanup: TBD
   *  Visibility:
   *    - TBD
   *
   *  TBD
   */
  public get concurrentEditingPathways(): boolean {
    return this.ldFlagsService.getFlag(
      'concurrent-editing-pathways-20210611',
      false
    );
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
