import { NgModule } from '@angular/core';
import { ItemRoleDirective } from './item-role.directive';
import { CommonModule } from '@angular/common';
import { ItemContainerRoleDirective } from './item-role-container.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ItemRoleDirective, ItemContainerRoleDirective],
  exports: [ItemRoleDirective, ItemContainerRoleDirective],
})
export class DgxItemRoleModule {}
