<dgx-modal
  [canCancel]="true"
  [isSubmitDisabled]="!moveToValue"
  [item]="{
    pathwayId: pathway.id,
    node: subsection.node,
    type: 'subsection',
    moveAfterNode: moveAfterNode,
    beforeNode: null
  }"
  [submitButtonText]="i18n?.Core_SaveChanges"
  bodyClasses="guts-p-h-2 guts-p-b-2 guts-p-t-1 guts-m-b-1"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    {{ i18n?.Pathways_MoveLessonTo }}
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <ul class="guts-m-h-1 guts-p-v-2">
      <li
        *ngFor="let section of sectionList; trackBy: trackBySectionId"
        data-dgat="pathway-subsection-move-modal-c0a"
      >
        <label
          [ngClass]="{ 'sort hover-bg-color-ebony-a03': !section.disabled }"
          class="l_flex l_flex-justify-left selection-box__label l_flex-center-children guts-p-v-half guts-p-h-1 rounded"
          data-dgat="moveSubsectionModal-832"
        >
          <div class="l_flex-grow">
            <input
              type="radio"
              class="a11y-hide hide-focus"
              [disabled]="section.disabled"
              (keypress)="
                select(section.id, section.afterNode, section.tempAfterNode)
              "
              (click)="
                select(section.id, section.afterNode, section.tempAfterNode)
              "
              name="moveTo"
              data-dgat="moveSubsectionModal-be6"
            />
            <h3 class="guts-p-v-quart">{{ section.title }}</h3>
          </div>
          <df-icon
            [ngClass]="{
              'color-blue': moveToValue === section.id,
              'color-ebony-a45': section.disabled
            }"
            *ngIf="section.disabled || section.id === moveToValue"
            icon="checkmark"
          ></df-icon>
        </label>
      </li>
    </ul>
  </ng-container>
</dgx-modal>
