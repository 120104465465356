import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface TimeZoneDetails {
  timeZoneId: string;
  aliases: string[];
  countries: TimeZoneCountry[];
  names: TimeZoneNames;
  daylightSavingsOffset: string;
  standardOffset: string;
}
export interface TimeZoneCountry {
  name: string;
  code: string;
}
export interface TimeZoneNames {
  daylight: string;
  generic: string;
  iso6391LanguageCode: string;
  standard: string;
}

@Injectable({
  providedIn: 'root',
})
export class TimeZoneService {
  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient
  ) {}

  /**
   * Returns a list of all of the known IANA Time Zone Ids
   */
  public getTimeZoneIds(): Observable<string[]> {
    return this.http.get('/timezones/gettimezoneids').pipe(
      catchError((e: HttpErrorResponse) => {
        throwError(
          new DgError(this.translate.instant('Core_ListItemsError'), e)
        );
        return [];
      })
    );
  }

  /**
   * Returns information about the Time Zone for a specific latitude/longitude location
   * @param latitude
   * @param longitude
   */
  public getTimeZoneFromCoordinates(
    latitude: number,
    longitude: number
  ): Observable<TimeZoneDetails> {
    return this.http.get('/timezones/gettimezonefromcoordinates', {
      params: { latitude, longitude },
    });
  }

  /**
   * Returns information about the Time Zone for a given Time Zone Id
   * Note: This isn't used in our application yet, but is available to us for future use so it was included here.
   * @param timeZoneId
   */
  public getTimeZoneFromName(timeZoneId: string): Observable<TimeZoneDetails> {
    return this.http.get('/timezones/gettimezonefromname', {
      params: { timeZoneId },
    });
  }

  /**
   * Returns the IANA time zone string for the current user if their browser supports it
   */
  public getBrowserTimeZone(): string {
    return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  }
}
