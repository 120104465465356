import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Custom date formatter for use in a date picker that formats date to mediumDate
 * NOTE: the js `Date` month is 0-based, NgbDateStruct month is 1-based
 */
@Injectable()
export class MediumDateParserFormatterService
  implements NgbDateParserFormatter {
  constructor(private datePipe: DatePipe) {}

  /** Parse the given date as a ngb date struct */
  public parse(value?: string): NgbDateStruct {
    if (!value) return;
    const date = new Date(value);

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  /** Format the struct as a date */
  public format(ngbDate: NgbDateStruct): string {
    if (!ngbDate) return '';

    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return this.datePipe.transform(date, 'mediumDate');
  }
}
