<ng-container *ngIf="visibilities[privacy] === 'Groups'; else visibilityName">
  <span *ngIf="!groups.length">
    {{ 'Core_VisibilityGroup' | translate }} (0)
  </span>
  <button
    *ngIf="groups.length"
    [dgxPopoverTrigger]="menuPopover"
    class="uhover v-bottom color-ebony popover-trigger font-medium"
    data-dgat="data-column-visibility-c45"
  >
    {{
      (groups.length < 2 ? 'Core_VisibilityGroup' : 'Core_VisibilityGroups')
        | translate
    }}
    ({{ groups.length }})
  </button>
  <dgx-popover #menuPopover placement="bottom">
    <ul class="zeta oauto popover-menu-list">
      <li *ngFor="let group of groups" data-dgat="data-column-visibility-7d5">
        <a
          class="guts-p-v-1 guts-p-h-1 block hover-bg-color-ebony-a03"
          href="/groups/{{ group.id }}"
          target="_blank"
          data-dgat="data-column-visibility-a45"
          >{{ group.name }}</a
        >
      </li>
    </ul>
  </dgx-popover>
</ng-container>

<ng-template #visibilityName>
  {{
    privacy > 3 ? '' : ('Core_Visibility' + visibilities[privacy] | translate)
  }}
</ng-template>
