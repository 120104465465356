import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  Organization,
  OrganizationMentionUserMatch,
  OrganizationModel,
  OrgMemberSummary,
} from './orgs.model';

@Injectable({
  providedIn: 'root',
})
export class OrgService extends ApiServiceBase {
  public i18n = this.translate.instant(['OrgSvc_OrgError']);

  constructor(
    public http: NgxHttpClient,
    private notifierService: NotifierService,
    private translate: TranslateService
  ) {
    super(http, translate.instant('OrgSvc_OrgError'));
  }

  public processBulkUpload(fileId): Observable<unknown> {
    return this.post('/organizations/bulkuploadprocess', {
      fileId,
    });
  }

  public cancelBulkUpload(fileId): Observable<unknown> {
    return this.post('/organizations/bulkuploadcancel', {
      fileId,
    }).pipe(
      catchError((error) => {
        this.notifierService.showError(
          this.translate.instant('Uploader_UnknownError')
        );
        return error;
      })
    );
  }

  public getMentionUserMatches(
    orgId: number,
    nameFilter: string,
    count: number,
    skip: number,
    groupId: number
  ): Observable<OrganizationMentionUserMatch> {
    return this.get('/organizations/mentionusermatches', {
      orgId,
      groupId,
      nameFilter,
      skip,
      count,
    });
  }

  public getUserOrganizations(): Observable<OrganizationModel[]> {
    return this.get('/user/organizationswithflags', {
      cache: true,
    });
  }

  /**
   * Replaces the original get method.
   * @param orgId
   * @returns
   */
  public getOrganization(orgId: number): Observable<Organization> {
    return this.get('/organizations/get', { id: orgId });
  }

  public updateOrganization(org): Observable<unknown> {
    return this.put('/organizations/put', org);
  }

  public getMember(
    orgId: number,
    userKey: number
  ): Observable<OrgMemberSummary> {
    return this.get('/organizations/member', { id: orgId, userKey });
  }
}
