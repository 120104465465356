import { Injectable, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';

import { VideoService } from '@app/inputs/services/video.service';
import {
  CUSTOM_SCHEME_URL_PATTERN,
  HTTP_REQUIRED_URL_PATTERN,
} from '@app/shared/utils/form-helpers';

import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

import {
  VideoConstraintsViewModel,
  VideoFormModel,
} from './video-form.model.model';
import { hasBrokenUrlValidator } from '@app/shared/validators/has-broken-url.validator';
import { LDFlagsService } from '@dg/shared-services';

/** Exposes methods for building fields common amonst the Article modal variants */
@Injectable({ providedIn: 'root' })
export class VideoCommonFieldBuilder {
  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService,
    private videoService: VideoService,
    private ldFlagsService: LDFlagsService
  ) {}

  /** Builds a video URL input field with a special positive validation for supported video providers,
   * negative https URL validation, and an optional catalog duplicates check warning.
   * @returns a tuple containing the field configuration and an Observable that the help template can
   * use for determining if the positive validation message should be displayed.
   */
  public buildInitialMediaUrlField(
    helpTemplate: TemplateRef<DfFieldTemplateContext>,
    shouldShowRecordVideoButton: boolean
  ): [DfFormFieldConfig, Observable<boolean>] {
    let shouldValidate = false;
    const validator = {
      urlValidation: {
        expression: (control: AbstractControl, _) => {
          const valid =
            !shouldValidate || // emulate onblur update behavior for negative validation
            HTTP_REQUIRED_URL_PATTERN.test(control.value) ||
            control.value?.trim() === '';
          return valid;
        },
        message: this.translate.instant('MediaFormCtrl_UrlRequired'),
      },
    };

    const isPlayable$ = new Subject<boolean>();
    const field = this.builder
      .optionalTextInput('mediaUrl', 'MediaFormCtrl_VideoUrl')
      .ofType('url')
      .withPlaceholder('https://')
      .withHelp(helpTemplate)
      .updatedOn('change') // update on each input change so we can show the compatible source positive validation in realtime
      // emulate onblur update behavior for negative validation
      .onFocus(() => (shouldValidate = false))
      .onBlur((_, __, control) => {
        shouldValidate = true;
        control.updateValueAndValidity(); // re-trigger validation now that we've re-enabled it
      })
      .onControlValueChanges((url: string) => {
        isPlayable$.next(this.videoService.isPlayableVideo(url));
      })
      .validatedByIndexed(validator)
      .withDgatId('videoForm-2b6')
      .autofocused()
      .build();
    field.templateOptions.required = shouldShowRecordVideoButton ? false : true;
    return [field, isPlayable$.asObservable()];
  }

  public buildLoadedMediaUrlField(
    state: () => VideoFormModel,
    urlBrokenTemplate: TemplateRef<DfFieldTemplateContext>,
    helpTemplate: TemplateRef<DfFieldTemplateContext>,
    hideUrl: boolean = false
  ) {
    const originalUrl = state().mediaUrl;
    const urlPattern = CUSTOM_SCHEME_URL_PATTERN('https', 'http', 'degreed');
    const validator = {
      urlValidation: {
        expression: (control: AbstractControl, _) => {
          // Allow either an https video URL or the uploaded video URL at degreed schema
          const valid = urlPattern.test(control.value);
          return valid;
        },
        message: this.translate.instant('MediaFormCtrl_UrlRequired'),
      },
    };
    if (!this.ldFlagsService.showBrokenLinksManagement) {
      return this.builder
        .requiredTextInput('mediaUrl', 'MediaFormCtrl_VideoUrl')
        .ofType('url')
        .readonlyWhen(() => state().shouldShowReplaceContentButtons)
        .validatedByIndexed(validator)
        .withDgatId('videoForm-2b6')
        .withHelp(helpTemplate)
        .hiddenWhen(() => hideUrl)
        .build();
    } else {
      return this.builder
        .customField('mediaUrl', null, urlBrokenTemplate, {
          label: this.translate.instant('MediaFormCtrl_VideoUrl'),
          invalidUrlMessage: this.translate.instant(
            'MediaFormCtrl_UrlRequired'
          ),
          brokenUrlMessage: this.translate.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
          originalUrl,
          isRequired: true,
        })
        .readonlyWhen(() => state().shouldShowReplaceContentButtons)
        .validatedByIndexed(validator)
        .validatedBy((control: AbstractControl) =>
          hasBrokenUrlValidator(
            control,
            this.translate.instant('dgOrgInternalContent_BrokenLinkError'),
            state().hasBrokenUrl,
            control.value !== originalUrl
          )
        )
        .withDgatId('videoForm-2b6')
        .withHelp(helpTemplate)
        .hiddenWhen(() => hideUrl)
        .build();
    }
  }

  public buildRecordButtonField(
    videoConstraints$: Observable<VideoConstraintsViewModel>,
    organizationId: number,
    isVisible: boolean,
    template: TemplateRef<DfFieldTemplateContext>,
    redirectQueryParam: string = ''
  ) {
    return this.builder
      .customField('', '', template, {
        i18n: this.translate.instant([
          'Core_Or',
          'dgOrgInternalContentForm_RecordVideo',
          'dgOrgInternalContentForm_Record',
          'dgOrgInternalContentForm_RecordAVideo',
        ]),
        videoConstraints$: videoConstraints$,
        isRecordPending: false,
        organizationId,
        redirectQueryParam,
        shouldShowRecordVideoButton: isVisible,
      })
      .unwrapped()
      .hiddenWhen(() => !isVisible)
      .build();
  }
}
