<a
  target="_blank"
  [attr.href]="editPath"
  data-dgat="orgPages-5a8"
  class="uhover color-ebony"
  (click)="trackPlanViewed()"
>
  <span>
    {{ plan.name }}
  </span>
  <span *ngIf="plan.hideSelect && i18n.OrgPlans_OnFeaturedTooltip">
    <df-icon
      [attr.aria-label]="i18n.OrgPlans_OnFeaturedTooltip"
      [ngbTooltip]="i18n.OrgPlans_OnFeaturedTooltip"
      icon="exclamation-circle"
      tabindex="0"
      size="small"
      class="par--light guts-m-b-quart"
      role="text"
    ></df-icon>
  </span>
</a>
