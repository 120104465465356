import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Group } from '@app/groups/group-api';
import { TagsService } from '@app/tags/services/tags.service';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { UserProfile, UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { TagsApi } from '@app/tags/tag-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first, map, switchMap } from 'rxjs/operators';
import { GroupService } from '@app/shared/services/group.service';
import { NotificationType } from '@lib/fresco';
import { Peer } from '../tag-peer-search/tag-peer-search.component';

interface PeerRatingRequestModalModel {
  peerCountWarning: string;
  showPeerCountWarning: boolean;
  disableForm: boolean;
}

@Component({
  selector: 'dgx-tag-peer-rating-request-modal',
  templateUrl: './tag-peer-rating-request-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagPeerRatingRequestModalComponent implements OnInit {
  public readonly NotificationType = NotificationType;

  public comment: string;
  public commentMaxLength = 1500;
  public cancelPendingRequest: boolean = true;
  public i18n: { [key: string]: string } = {};
  public viewModel: Observable<PeerRatingRequestModalModel>;
  public submitting = false;

  @Input() public tag: TagsApi.TagDetails;

  private peerCountTrigger = 20;
  private peers: BehaviorSubject<Peer[]> = new BehaviorSubject([]);

  constructor(
    private translateService: TranslateService,
    private tagsService: TagsService,
    private activeModal: NgbActiveModal,
    private groupService: GroupService
  ) {}

  // Exclude peers with pending rating requests
  public get peerProfileKeysToExclude(): number[] {
    return this.tag.ratings
      .filter(
        (rating) =>
          rating.type === InternalTagRatingTypes.peer && !rating.dateCompleted
      )
      .map((rating) => rating.raterProfileKey);
  }

  public ngOnInit(): void {
    this.i18n = this.translateService.instant(
      [
        'Core_Message',
        'Core_Optional',
        'Core_OptionalMessage_Request',
        'dgTagRating_RequestPeerRating',
        'RequestRating_SendRequest',
        'dgTagRating_CancelPeerRequests',
        'dgTagRating_CancelPeerRequestsDesc',
        'dgTagRating_PeerRequestCountWarning',
      ],
      {
        count: this.peerCountTrigger,
      }
    );
    this.viewModel = this.peers.pipe(
      switchMap((peers) => this.getTotalPeerCount(peers)),
      map((count) => {
        return {
          peerCountWarning: this.i18n.dgTagRating_PeerRequestCountWarning,
          showPeerCountWarning: count > this.peerCountTrigger,
          disableForm: count === 0,
        };
      })
    );
  }

  public selectedChanged(selectedPeers: Peer[]): void {
    this.peers.next(selectedPeers);
  }

  public toggleCancelRequests(cancelRequests: boolean) {
    this.cancelPendingRequest = cancelRequests;
  }

  public requestRatings(event: Event) {
    const peers = this.peers.getValue();

    if (this.submitting) {
      return;
    }

    if (peers.length === 0) {
      return;
    }

    this.submitting = true;

    const raterProfileKeys = (peers as unknown as UserProfileSummary[])
      .filter((peer) => !!peer.userProfileKey)
      .map((user) => user.userProfileKey);
    const raterGroupIds = (peers as unknown as Group[])
      .filter((peer) => !!peer.groupId)
      .map((group) => group.groupId);

    this.tagsService
      .requestTagRatings(
        event,
        this.tag,
        InternalTagRatingTypes.peer,
        raterProfileKeys,
        raterGroupIds,
        this.comment,
        this.cancelPendingRequest
      )
      .subscribe(
        () => {
          // refresh the list view/skill modal to reflect changes
          this.tagsService.notifyUserTagsModified();
          this.activeModal.close();
        },
        () => (this.submitting = false)
      );
  }

  /**
   * Get a sum of individual users and group members by async requesting group member counts
   *
   * @param peers
   */
  private getTotalPeerCount(peers: Peer[]): Observable<number> {
    const individualUsersCount = peers.filter(
      (peer) => !!(peer as UserProfileSummary).userProfileKey
    ).length;
    // We have to get group member counts async, so set up array of observables and include single user count...
    const peerCountObservables = [of(individualUsersCount)];
    // ...include observable for each selected group
    (peers as Group[])
      .filter((peer) => !!peer.groupId)
      .forEach((group) => {
        peerCountObservables.push(this.getGroupMemberCount(group.groupId));
      });
    // ...add the counts for a total
    return forkJoin(peerCountObservables).pipe(
      first(),
      map((counts) => counts.reduce((total, count) => total + count, 0))
    );
  }

  /**
   * Get the total member count for a group, minus anonymous users
   *
   * @param groupId
   */
  private getGroupMemberCount(groupId: number): Observable<number> {
    return this.groupService
      .getMemberCount(groupId)
      .pipe(
        map(
          ({ userCount, anonymousUserCount }) => userCount - anonymousUserCount
        )
      );
  }
}
