import { Injectable } from '@angular/core';
import { OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrgService } from './org.service';
import { OrganizationModel } from './orgs.model';

export interface OrgTargetSettings {
  enableDirectoryLegacySections: boolean;
  disablePlanImage: boolean;
  enableDirectoriesOnBrowse: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrgInfoService {
  constructor(
    private authService: AuthService,
    private orgService: OrgService
  ) {}

  public getDefaultOrg() {
    return this.authService.authUser.orgInfo[0];
  }

  // TODO: (20181126) for now limited to user org match; look into making call to backend instead
  public getUserOrgDetails(orgId: number): OrgInfo {
    return this.authService.authUser.orgInfo.filter(
      (org) => org.organizationId === orgId
    )[0];
  }

  public getTargetSettings(
    orgId?: number
  ): Observable<any | OrgTargetSettings> {
    // todo: check if orgId = 0 is a real org.
    if (orgId === undefined || orgId === null) {
      return of(this.createTargetSettings());
    }

    // note: this calls the orgSvc.getUserOrganization which is set to cache
    // the response in the orgSvc.  keep this cache settings to true while
    // the TargetTile is using this function to get its settings for
    // showing/hiding images.
    return this.orgService.getUserOrganizations().pipe(
      map((data: OrganizationModel[]) => {
        if (!data) {
          return undefined;
        }
        const userOrg = data.filter((o) => o.organizationId === orgId)[0];
        return this.createTargetSettings(userOrg);
      })
    );
  }

  private createTargetSettings(userOrg?): OrgTargetSettings {
    const userOrgToUse = userOrg || ({} as any);

    return {
      enableDirectoryLegacySections:
        userOrgToUse.enableDirectoryLegacySections || false,
      disablePlanImage: userOrgToUse.disablePlanImage || false,
      enableDirectoriesOnBrowse:
        userOrgToUse.enableDirectoriesOnBrowse || false,
    };
  }
}
