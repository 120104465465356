import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import { SearchSuggestionSource } from '@app/search/search-api.model';
import { SearchSuggestionPresenterService } from '@app/search/services/search-suggestion-presenter.service';
import {
  SearchSubmitMethod,
  SearchTrackerService,
} from '@app/search/services/search-tracker.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { isKey, Key } from '@app/shared/key';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import { WindowToken } from '@app/shared/window.token';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

/**
 * A basic text-only implementation of a search suggestion component based on {@link SearchSuggestComponent}
 * and {@link SearchSuggestionPresenterService}
 */
@Component({
  selector: 'dgx-simple-search-suggest',
  templateUrl: './simple-search-suggest.component.html',
  styleUrls: ['./simple-search-suggest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSearchSuggestComponent extends SubscriberBaseDirective {
  public shouldShowSuggestions = false;
  public suggestionsByType: ParentItemViewModel<SimpleItemViewModel<any>>[] =
    [];
  @Input() public overrideClasses: string;
  @Input() public showDefaults = false; // shows default user interests
  @Input() public searchTerm: string;
  @Input() public suggestionSource: SearchSuggestionSource; // when undefined causes all available sources to be searched
  @Input() public shouldRenderInGlobalSearchMode: boolean = false;
  @Output() public inputEnter = new EventEmitter<string>();

  private suggest = new Subject<string>();
  private inputEnterWasPressed: boolean;
  private popoverEscaped: boolean;

  constructor(
    private searchTrackerService: SearchTrackerService,
    private suggestionService: SearchSuggestionPresenterService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
    this.suggest
      .pipe(
        switchMap(
          // switchMap cancels (ie, unsubscribes from) any pending request before issuing a new one. Coupled with keypress debouncing, this creates a robustly efficient auto-complete.
          (searchTerm) => {
            // Update search term from what was typed so suggestions are accurate when focus changes, regardless if Enter was pressed
            this.searchTerm = searchTerm;

            let innerSuggest: Observable<ParentItemViewModel[]>;
            if (this.showDefaults && !searchTerm) {
              innerSuggest = this.suggestionService.getDefaultSuggestions(); // get user interest skills as suggestions
            } else {
              innerSuggest = this.suggestionService.getSearchSuggestions(
                searchTerm,
                this.suggestionSource
              );
            }
            return innerSuggest.pipe(takeUntil(this.inputEnter)); // Stop taking suggestion responses when a term is entered
          }
        ),
        this.takeUntilDestroyed()
      )
      .subscribe(
        (suggestions) => {
          // Pressing enter can be faster than the last typed suggestions loaded. If that happens, abort.
          if (this.inputEnterWasPressed) {
            this.inputEnterWasPressed = false;
            return;
          }

          this.suggestionsByType = suggestions;
          const flattenedCount = this.suggestionsByType.reduce(
            (acc, i) => acc + i.subitems.length,
            0
          );
          this.shouldShowSuggestions = flattenedCount > 0;
          this.suggestionService.announceSuggestionCount(flattenedCount);
          this.cdr.markForCheck();
        },
        () => (this.inputEnterWasPressed = false)
      );
  }

  public getItemTrackingKey(index: number, itemVm: SimpleItemViewModel) {
    return itemVm.trackingKey;
  }

  public loadSuggestions(suggestTerm?: string) {
    this.suggest.next(suggestTerm);
  }

  public onInputFocus() {
    if (!this.shouldShowSuggestions && !this.popoverEscaped) {
      this.loadSuggestions(this.searchTerm);
    }
    this.popoverEscaped = false;
  }

  /**
   * Keep for a11y purposes
   * Prevents popover from reappearing after it was explicitly escaped
   */
  public handleKeyup(event: KeyboardEvent) {
    if (isKey(event, Key.Escape)) {
      this.popoverEscaped = true;
    }
  }

  public onInputEnter(searchTerm: string) {
    this.inputEnterWasPressed = true;
    this.shouldShowSuggestions = false;
    this.inputEnter.emit(searchTerm);

    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.enterKey,
    });
  }

  public trackSuggestionClick(suggestionVm: SimpleItemViewModel) {
    this.searchTrackerService.typeaheadClicked({
      searchTerm: this.searchTerm,
      type: suggestionVm.model.type || suggestionVm.model.resourceType,
      keywordsClicked: suggestionVm.title,
    });

    // if suggestionVm has userInterestId, then assume it is a "skill"
    // and also assuming user clicked "Your Skills" autosuggest item.
    if (suggestionVm.model.userInterestId) {
      this.searchTrackerService.setSearchData({
        searchTerm: this.searchTerm,
        submitMethod: SearchSubmitMethod.skillsClicked,
      });
    } else if (suggestionVm.linkUrl.match(/\/learning\//)) {
      // Else: assume user clicked "Typeahead" autosuggest,
      // that leads to search.
      this.searchTrackerService.setSearchData({
        searchTerm: this.searchTerm,
        submitMethod: SearchSubmitMethod.typeaheadClicked,
      });
    }

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.windowRef.open(suggestionVm.linkUrl, '_self');
      }, 1000);
    });
  }
}
