import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { DataPrivacyModalComponent } from '@app/data-privacy/components/data-privacy-modal.component';

@NgModule({
  declarations: [DataPrivacyModalComponent],
  exports: [DataPrivacyModalComponent],
  imports: [CommonModule, SharedModule],
})
export class DataPrivacyModule {}
