<div
  class="form-wrap duration-form__row"
  [formGroup]="durationForm"
  formGroupName="durationForm"
>
  <div class="duration-form__col-half">
    <dgx-reactive-forms-label for="durationHours">
      {{ i18n.Core_Hours }}
    </dgx-reactive-forms-label>
    <input
      type="number"
      id="durationHours"
      formControlName="durationHours"
      (onBlur)="onBlur('durationHours')"
      data-dgat="duration-a54"
      (keydown)="excludeCharacters($event)"
      [readonly]="isReadOnly"
    />
    <dgx-validate-field
      class="block"
      *ngIf="durationForm.get('durationHours').invalid"
      [message]="durationForm.get('durationHours').getError('minZeroError')"
    ></dgx-validate-field>
  </div>
  <div class="duration-form__col-half">
    <dgx-reactive-forms-label for="durationMinutes">
      {{ i18n.Core_Minutes }}
    </dgx-reactive-forms-label>
    <input
      id="durationMinutes"
      type="number"
      formControlName="durationMinutes"
      (onBlur)="onBlur('durationMinutes')"
      data-dgat="duration-133"
      (keydown)="excludeCharacters($event)"
      [readonly]="isReadOnly"
    />
    <dgx-validate-field
      class="block"
      *ngIf="durationForm.get('durationMinutes').invalid"
      [message]="
        durationForm.get('durationMinutes').getError('minOneError') ??
        durationForm.get('durationMinutes').getError('minZeroError')
      "
    ></dgx-validate-field>
  </div>
</div>
