<dgx-modal
  [bodyClasses]="bodyClasses"
  [bodyStyle]="bodyStyle"
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
  [isHeaderBorderless]="isHeaderBorderless"
  [isHeaderCentered]="isHeaderCentered"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <span>{{ title | translate }}</span>
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <form (ngSubmit)="onSubmit(userData, comment)" class="guts-p-b-2">
      <div class="l_flexbar guts-m-v-1-half">
        <div>
          <dgx-profile-pic
            [profile]="userData"
            size="smaller"
            class="guts-m-r-1 ib ib-v-middle"
          ></dgx-profile-pic>
        </div>
        <div class="par par--small font-semibold">{{ userData.name }}</div>
      </div>
      <div class="guts-m-t-1">
        <div class="l_flex guts-m-b-half">
          <div class="l_flex-grow">
            <label
              for="ratingComment"
              class="par par--small font-semibold"
              translate="dgPostComment_Message"
            >
            </label>
          </div>
          <div
            class="l_flex-justify-right par par--small par--light color-ebony-a61"
          >
            {{ 'Core_Optional' | translate }}
          </div>
        </div>
        <textarea
          name="ratingComment"
          id="ratingComment"
          cols="30"
          rows="10"
          [placeholder]="placeholderText"
          [(ngModel)]="comment"
          data-dgat="request-self-rating-modal-476"
        ></textarea>
      </div>
    </form>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      type="button"
      df-button
      dfButtonType="primary"
      (click)="onSubmit(userData, comment)"
      data-dgat="ratingDialog-fe7"
    >
      {{ 'dgTagRating_RequestRating' | translate }}
    </button>
  </ng-container>
</dgx-modal>
