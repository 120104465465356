import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { OrgViewBaseComponent } from '@app/orgs/components/org-view-base/org-view-base.component';
import { RouterComponents } from '@app/orgs/constants';
import {
  DataColumn,
  DataColumnDate,
  DataColumnList,
  MenuSettings,
} from '@app/shared/components/data-table/data-table';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TargetsService } from '@app/shared/services/targets.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import { OrgTargetVM } from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, switchMap } from 'rxjs';
import { ConfirmMovePlanModalComponent } from '../confirm-move-plan-modal/confirm-move-plan-modal.component';
import { privacyNumFromPrivacyLevel } from '@app/shared/utils/visibility-helpers';
import { OrgPlansModalService } from '@app/orgs/services/org-plans-modal.service';
import { LoadOptions, OrgPlansFacade, OrgPlansViewModel } from './rsm';

// This is not in numerical order, needs to match up to what the API expects
export enum OrgPlansColumnIndex {
  name = '1',
  subtitle = '6',
  targetType = '5',
  visibility = '2',
  followers = '3',
}

@Component({
  selector: 'dgx-org-plans',
  templateUrl: './org-plans.component.html',
})
export class OrgPlansComponent extends OrgViewBaseComponent implements OnInit {
  @ViewChild('titleColumn', { static: true })
  public titleColumnTemplate: TemplateRef<any>;
  @ViewChild('subtitleColumn', { static: true })
  public subtitleColumnTemplate: TemplateRef<any>;
  @ViewChild('visibilityColumn', { static: true })
  public visibilityColumnTemplate: TemplateRef<any>;
  @ViewChild('targetTypeColumn', { static: true })
  public targetTypeColumnTemplate: TemplateRef<any>;
  @ViewChild('authorsColumn', { static: true })
  public authorsColumnTemplate: TemplateRef<any>;
  @ViewChild('followersColumn', { static: true })
  public followersColumnTemplate: TemplateRef<any>;

  @Input() public actionOptionFiltered;

  public actions: MenuSettings;

  public authUser: AuthUser = this.authService.authUser;

  public columns: (DataColumn | DataColumnDate | DataColumnList)[];
  public enableCreatePlan: boolean;
  public hasFeaturedPlan: boolean;
  public orgInfo: OrgInfo;
  public vm$: Observable<OrgPlansViewModel>;

  public readonly CLASS_NAME = RouterComponents.ORG_PLANS;

  public i18n = this.translate.instant([
    'dgManagePathways_SearchPlaceholder',
    'OrgPlans_Types',
    'Core_AddToBundle',
    'Core_Delete',
    'Core_Confirm',
    'Core_UnfeatureResource',
    'Core_FeatureResource',
    'Core_MoveToGlobal',
    'Core_Clone',
    'Core_Edit',
    'Core_Add',
    'Core_Remove',
    'OrgPathwayManagerCtrl_DeletePromptDescription',
    'OrgPathwayManagerCtrl_AddCollaborators',
    'OrgPlans_OnFeaturedTooltip',
  ]);

  private readonly deletePhrase = 'DELETE';
  private readonly deleteInstructions = this.translate.instant(
    'TargetCtrl_DeleteInstructions',
    { delete: this.deletePhrase }
  );

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private targetsService: TargetsService,
    private sharedTargetService: SharedTargetService,
    private orgPlansModalService: OrgPlansModalService,
    private facade: OrgPlansFacade
  ) {
    super();
    this.vm$ = this.facade.vm$;
  }

  public ngOnInit(): void {
    this.orgInfo = this.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    );
    this.enableCreatePlan = !!this.orgInfo.permissions?.authorTargets;

    const dataSource = this.buildDataSource();
    this.facade.loadPlansFromSource(dataSource);

    this.targetsService
      .getBrowseTarget(this.authUser.defaultOrgId)
      .subscribe((data) => {
        this.hasFeaturedPlan = !!data?.targetId;
      });

    this.initActions();

    this.initializeColumns();

    this.tracker.trackEventData({
      action: 'Org Plans Page Clicked',
      properties: {
        ActionLocation: 'Manage Org Plans',
      },
    });
  }

  public showAddModal() {
    return this.orgPlansModalService
      .showAddModal(this.orgId, 'default')
      .subscribe();
  }

  public featurePlan(plan) {
    this.sharedTargetService
      .featureTarget(plan.targetId, true)
      .subscribe(() => {
        this.facade.togglePlanFeatured(plan, true);
      });
  }

  public unfeaturePlan(plan) {
    this.sharedTargetService
      .featureTarget(plan.targetId, false)
      .subscribe(() => {
        this.facade.togglePlanFeatured(plan, false);
      });
  }

  public moveToGlobal(plan) {
    this.modalService
      .show(ConfirmMovePlanModalComponent, {
        inputs: { plan },
      })
      .pipe(
        switchMap(() => {
          return this.sharedTargetService.moveTargetToChannel(plan.targetId);
        }),
        this.takeUntilDestroyed()
      )
      .subscribe(() => {
        // Visually remove plan
        this.facade.deletePlans([plan.id]);
      });
  }

  public clonePlan(plan: OrgTargetVM) {
    return this.orgPlansModalService.showCloneModal(plan);
  }

  public openEditModal(plan: OrgTargetVM) {
    return this.orgPlansModalService
      .showEditModal(plan, 'default')
      .subscribe(() => {
        this.facade.reset();
      });
  }

  /**
   * showDeleteModal
   * show and handle submit of deletion confirmation
   */
  public deletePlan(plan: OrgTargetVM) {
    return this.orgPlansModalService.showDeleteModal(plan).subscribe(() => {
      this.facade.deletePlans([plan.id]);
    });
  }

  /**
   * showBulkDeleteModal
   * show and handle submit of bulk deletion confirmation
   */
  public showBulkDeleteModal() {
    const { selectedPlans } = this.facade.snapshot;
    return this.orgPlansModalService
      .showBulkDeleteModal(selectedPlans.map((x) => x.targetId))
      .subscribe(() => {
        this.facade.deletePlans(selectedPlans.map((x) => x.id));
      });
  }

  /**
   * showBulkCollaboratorsModal
   * show and handle results of bulk collaborator add modal
   */
  public showBulkCollaboratorsModal() {
    return this.orgPlansModalService
      .showBulkCollaboratorsModal(
        this.facade.snapshot.selectedPlans.map((x) => x.targetId)
      )
      .subscribe((collaborators) => {
        this.facade.updateAuthors(
          this.facade.snapshot.selectedPlans,
          collaborators
        );
        this.clearSelections();
      });
  }

  /**
   * showBulkVisibilityModal
   * show and handle results of bulk visibility modal
   */
  public showBulkVisibilityModal() {
    return this.orgPlansModalService
      .showBulkVisibilityModal(
        this.facade.snapshot.selectedPlans.map((x) => x.targetId)
      )
      .subscribe((visibility) => {
        this.facade.updateVisibility(
          this.facade.snapshot.selectedPlans,
          visibility
        );
        this.clearSelections();
      });
  }

  public bulkSelect($event: { items: OrgTargetVM[]; isSelected: boolean }) {
    this.facade.selectPlans(
      $event.items.filter((x) => x.targetType !== 'Browse'),
      $event.isSelected
    );
  }

  public selectPlan($event: { item: OrgTargetVM; isSelected: boolean }) {
    this.facade.selectPlans([$event.item], $event.isSelected);
  }

  public clearSelections() {
    this.facade.selectPlans(this.facade.snapshot.selectedPlans, false);
  }

  private initActions() {
    this.actions = {
      getMenuConfig: this.initActionOptions.bind(this),
      bodyClasses: 'guts-p-r-2',
    };
  }

  /**
   * initActionOptions
   * @param plan  Plan for which we're creating action options
   * @returns observable of menu items
   */
  private initActionOptions(plan): Observable<readonly MenuViewModel[]> {
    return of([
      {
        title: this.i18n.Core_Edit,
        defaultAction: () => {
          this.openEditModal(plan);
        },
        isHidden: () => !this.actionOptionFiltered.includes('editDetails'),
        preventRefocus: true,
      },
      {
        title: this.i18n.Core_Clone,
        defaultAction: () => this.clonePlan(plan),
        isHidden: () =>
          !this.actionOptionFiltered.includes('clone') ||
          plan.targetType === 'Browse', // this is an alias for the Featured page
        preventRefocus: true,
      },
      {
        title: this.i18n.Core_MoveToGlobal,
        defaultAction: () => this.moveToGlobal(plan),
        isHidden: () => {
          // We have to be able to move to global from this location...
          if (!this.actionOptionFiltered.includes('moveToGlobal')) {
            return true;
          }

          // ... and the user has to have permission to do so...
          if (!this.authUser.hasChannelManageTargetPermission) {
            return true;
          }

          return this.orgInfo.type !== 'Tenant';
        },
        preventRefocus: true,
      },
      {
        title: this.i18n.Core_FeatureResource,
        defaultAction: () => this.featurePlan(plan),
        preventRefocus: true,
        isHidden: () =>
          !this.orgInfo.permissions?.featurePathways ||
          plan.isFeatured ||
          !this.actionOptionFiltered.includes('feature') ||
          !(
            plan.targetType === 'Target' ||
            plan.targetType === 'Role' ||
            plan.targetType === 'Directory'
          ) ||
          !(
            privacyNumFromPrivacyLevel(plan.privacyLevel) ===
              Visibility.public ||
            privacyNumFromPrivacyLevel(plan.privacyLevel) === Visibility.groups
          ) ||
          this.hasFeaturedPlan,
      },
      {
        title: this.i18n.Core_UnfeatureResource,
        defaultAction: () => this.unfeaturePlan(plan),
        preventRefocus: true,
        isHidden: () =>
          !this.orgInfo.permissions?.featurePathways ||
          !plan.isFeatured ||
          !this.actionOptionFiltered.includes('unfeature') ||
          !(
            plan.targetType === 'Target' ||
            plan.targetType === 'Role' ||
            plan.targetType === 'Directory'
          ) ||
          !(
            privacyNumFromPrivacyLevel(plan.privacyLevel) ===
              Visibility.public ||
            privacyNumFromPrivacyLevel(plan.privacyLevel) === Visibility.groups
          ) ||
          this.hasFeaturedPlan,
      },
      {
        title: this.i18n.Core_Delete,
        defaultAction: () => this.deletePlan(plan),
        isHidden: () =>
          plan.targetType === 'Browse' ||
          !this.actionOptionFiltered.includes('delete'),
        preventRefocus: true,
      },
    ]);
  }

  private buildDataSource() {
    return ({ ordering, paging, term, filterType }: LoadOptions) => {
      return this.sharedTargetService.getOrgTargets({
        organizationId: this.orgId,
        term,
        count: paging.take,
        skip: paging.skip,
        orderBy: ordering.orderBy,
        sortDescending: ordering.isDescending,
        targetTypes: filterType?.join(','),
      });
    };
  }

  private initializeColumns = () => {
    this.columns = [
      {
        label: 'OrgPlans_Title',
        headClasses: 'l_w30',
        canSort: true,
        sortName: OrgPlansColumnIndex.name,
        template: this.titleColumnTemplate,
      } as DataColumn,
      {
        label: 'OrgPlans_Subtitle',
        headClasses: 'l_w20',
        canSort: true,
        sortName: OrgPlansColumnIndex.subtitle,
        template: this.subtitleColumnTemplate,
      } as DataColumn,
      {
        label: 'OrgPlans_Type',
        headClasses: 'l_w10',
        canSort: true,
        sortName: OrgPlansColumnIndex.targetType,
        template: this.targetTypeColumnTemplate,
      } as DataColumn,
      {
        label: 'OrgPlans_Visibility',
        headClasses: 'l_w10',
        canSort: true,
        sortName: OrgPlansColumnIndex.visibility,
        template: this.visibilityColumnTemplate,
      } as DataColumn,
      {
        label: 'OrgPlans_FollowerCount',
        canSort: true,
        headClasses: 'l_w10 right-text',
        bodyClasses: 'right-text',
        sortName: OrgPlansColumnIndex.followers,
        template: this.followersColumnTemplate,
      } as DataColumn,
      {
        label: 'Core_Authors',
        headClasses: 'l_w20',
        template: this.authorsColumnTemplate,
      } as DataColumn,
    ];
  };
}
