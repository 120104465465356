import { AbstractControl } from '@angular/forms';
import { DfFieldTemplateContext } from '@lib/fresco';

// Shared constants and helpers for use with form validation and logic

/** @deprecated: This doesn't really catch anything except odd characters and allows basically anything through.
 * Use one of the scheme-required alternatives instead.
 * Simple URL regex for validation, which matches most variants of http, https or schemeless urls.
 *  Note: Also allows for sharepoint urls such as http://teams/sites/IOLnD/Pages/Home.aspx
 */
export const HTTP_URL_PATTERN =
  /^(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)?(\/[\w\-~]*)*(#[\w\-]*)?(\?.*)?/;

/**
 * Validates a URL and does not allow schemeless urls; https or http is required.
 */
export const HTTP_REQUIRED_URL_PATTERN =
  /^(https?:\/\/)[\w\-~]+(\.[\w\-~]+)?(\/[\w\-~]*)*(#[\w\-]*)?(\?.*)?/;

/**
 * Validates a URL and does not allow schemeless urls; https is required.
 */
export const HTTPS_REQUIRED_URL_PATTERN =
  /^(https:\/\/)[\w\-~]+(\.[\w\-~]+)?(\/[\w\-~]*)*(#[\w\-]*)?(\?.*)?/;

/**
 * Validates a URL, requiring a match with one or more custom schemes
 */
export const CUSTOM_SCHEME_URL_PATTERN = (...schemes) =>
  new RegExp(
    '^((' +
      schemes.join('|') +
      '):\\/\\/)[\\w\\-~]+(\\.[\\w\\-~]+)?(\\/[\\w\\-~]*)*(#[\\w\\-]*)?(\\?.*)?'
  );

/**
 * Compares new, trimmed value to original value and marks form control
 * as pristine if no changes have actually been made yet.
 *
 * (For enabling/disabling save buttons on formly forms.)
 *
 * @param originalValue - The original value before editing began.
 * @param value - The new value.
 * @param model - The current model.
 * @param control - The form control for the current field.
 */
export function markUnchangedStringAsPristine(
  originalValue: string,
  value: string,
  _: unknown,
  control: AbstractControl
): void {
  // Defaulting these values is insufficient because `null` gets passed in as an
  // active value, overwriting defaults. This way we guarantee that any kind of falsy
  // value becomes an empty string instead.
  if ((!value ? '' : value).trim() !== (!originalValue ? '' : originalValue)) {
    return;
  }
  control.markAsPristine();
}

/**
 * Similar to the character limit helper on charCountedTextarea,
 * but help text only appears when the user's text input length
 * reaches 80% of the limit on the textarea.
 *
 * @param maxLength
 * @param translatedMessage
 * @param context
 */
export function warnCharPercentLimited(
  maxLength: number,
  translatedMessage: string,
  { formControl }: DfFieldTemplateContext
): string {
  // Calculates and displays characters remaining below the textarea.
  // Note that unlike the word counted textarea, this uses the built in
  // maxLength attribute to limit, so we don't need a validator
  const text = (formControl.value ?? '').toString();
  const charLength = text.length;
  const charsLeft = maxLength - charLength;
  // Show help only when characters remaining are between 0 and 80% of the limit.
  if (charsLeft > 0 && charsLeft <= 0.2 * maxLength) {
    return translatedMessage;
  }
  // Otherwise, no help message. We may display errors, however.
  return null;
}
