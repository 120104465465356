import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ImagePosition } from '@app/uploader/uploader-api.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-image-position',
  templateUrl: './image-position.component.html',
  styleUrls: ['./image-position.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePositionComponent implements OnInit {
  public currentPosition: ImagePosition = 'Top';
  public i18n = this.translate.instant([
    'dgImageUpload_ImagePosition',
    'dgImageUpload_ImagePosition_HelperText',
  ]);

  /** Set the current position, or this will default to "Top" */
  @Input() public position: ImagePosition;
  /** Pass position updates to the image select/cropper */
  @Output()
  public positionChanged: EventEmitter<ImagePosition> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.currentPosition = this.position ?? 'Top';
    this.cdr.markForCheck();
  }

  public onPositionChange(position: ImagePosition) {
    this.currentPosition = position;
    this.positionChanged.emit(position);
    this.cdr.markForCheck();
  }
}
