import { Component, ElementRef, Input } from '@angular/core';
import { PathwayAuthorBaseDirective } from '@app/pathways/directives/pathway-author-base.directive';
import {
  PathwayDetailsModel,
  PathwaySubsection,
} from '@app/pathways/rsm/pathway-api.model';
import {
  PathwayActionSuccess,
  PathwayLevel,
} from '@app/pathways/rsm/pathway.model';
import { getSubsectionsSafe } from '@app/pathways/rsm/utils';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { A11yService } from '@app/shared/services/a11y.service';
import { AutoSaveService } from '@app/shared/services/auto-save/auto-save.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { DF_COLLAPSE_EXPAND, DF_SLIDE_FADE_IN_LEFT } from '@lib/fresco';
import { PathwayAuthoring, PathwayFacade } from '@dg/pathways-rsm';
import { EventBus } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-author-subsections',
  templateUrl: './pathway-author-subsections.component.html',
  animations: [DF_COLLAPSE_EXPAND, DF_SLIDE_FADE_IN_LEFT],
})
export class PathwayAuthorSubsectionsComponent extends PathwayAuthorBaseDirective {
  @Input() public hasFauxParent = false;
  @Input() public hasSingleParent = false;
  @Input() public isFauxSubsection = false;
  @Input() public parentIndex = 0;
  @Input() public subsections: PathwaySubsection[];

  public readonly i18n = this.translate.instant([
    ...this.baseI18n,
    'Core_MoreOptions',
    'Pathways_DeleteSubsection',
    'Pathways_DeletedSubsection',
    'Pathways_MoveItem',
    'Pathways_MovedSubsection',
    'Pathways_ReorderItems',
    'Pathways_ReorderSubsections',
    'Pathways_SubsectionDescriptionPlaceholder',
    'Pathways_SubsectionTitlePlaceholder',
    'Pathways_UntitledLesson',
  ]);

  constructor(
    private a11yService: A11yService,
    private focusStackService: FocusStackService,
    autoSaveService: AutoSaveService,
    authoring: PathwayAuthoring,
    eventBus: EventBus,
    facade: PathwayFacade,
    translate: TranslateService
  ) {
    super(autoSaveService, authoring, eventBus, facade, translate);
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public getSubsections = getSubsectionsSafe;

  // ***************************
  // Methods
  // ***************************

  /**
   * Create config for subsection menus
   *
   * @param subsection
   */
  public createMenuConfig(subsection: PathwaySubsection): MenuViewModel[] {
    return [
      {
        title: this.i18n?.Pathways_MoveItem,
        defaultAction: (_, popoverTrigger) =>
          this.moveSubsectionToSection(subsection, popoverTrigger),
        preventRefocus: true,
        isHidden: () => this.hasSingleParent,
      },
      {
        title: this.i18n?.Pathways_ReorderSubsections,
        defaultAction: (_, popoverTrigger) =>
          this.reorderSubsection(subsection, popoverTrigger),
        preventRefocus: true,
        isHidden: () => !(this.subsections.length > 1),
      },
      {
        title: this.i18n?.Pathways_DeleteSubsection,
        defaultAction: (_, popoverTrigger) => {
          this.deleteSubsection(subsection, popoverTrigger);
        },
        isSeparated: true,
        isDestructive: true,
        preventRefocus: true,
      },
    ];
  }

  // ***************************
  // API Calls
  // ***************************

  /**
   * Add step.
   *
   * @param node
   * @param afterNode
   * @param target
   */
  public async addSteps(
    {
      node,
      afterNode,
      target,
    }: {
      node: string;
      afterNode: string;
      target: HTMLElement;
    },
    pathway?: PathwayDetailsModel
  ) {
    // If node is undefined, this call will create a new subsection.
    const success = await this.authoring.showAddContentEditor({
      subsections: this.subsections,
      node,
      parentIndex: this.parentIndex,
      afterNode,
      pathway,
    });
    // NOTE: this should error handle on false (api) a toast message

    if (!success) {
      // was closed without deleting - return focus to meatball menu.
      this.focusStackService.push(target);
      this.focusStackService.pop();
      return;
    }

    // TODO: Figure out where to put focus.
  }

  /**
   * Delete subsection from current section
   * @param subsection
   * @param popoverTrigger
   */
  public async deleteSubsection(
    subsection: PathwaySubsection,
    popoverTrigger: ElementRef
  ) {
    const success = await this.authoring.deleteNode(
      PathwayLevel.SUBSECTION,
      this.facade.snapshot.pathway.id,
      subsection.node
    );

    if (success) {
      this.a11yService.announcePolite(
        this.translate.instant(PathwayActionSuccess.DELETED_SUBSECTION)
      );
      // TODO: Figure out where to put focus on delete.
      // this.focusStackService.push() // some other element
      return;
    }

    // Error thrown or modal closed without deleting.
    this.focusStackService.push(popoverTrigger?.nativeElement);
    this.focusStackService.pop();
  }

  /**
   * Move subsection to another section
   *
   * @param subsection
   * @param popoverTrigger
   */
  public async moveSubsectionToSection(
    subsection: PathwaySubsection,
    popoverTrigger: ElementRef
  ) {
    const success = await this.authoring.showMoveEditor(
      PathwayLevel.SUBSECTION,
      subsection
    );
    // NOTE: this should error handle on false (api) a toast message
    if (!success) {
      // was closed without reordering - return focus to meatball menu.
      this.focusStackService.push(popoverTrigger.nativeElement);
      this.focusStackService.pop();
    }
  }

  /**
   * Reorder subsections within current section
   *
   * @param subsection
   * @param popoverTrigger
   */
  public async reorderSubsection(
    subsection: PathwaySubsection,
    popoverTrigger: ElementRef
  ) {
    const success = await this.authoring.showReorderEditor(
      PathwayLevel.SUBSECTION,
      subsection.node
    );

    if (success) {
      this.a11yService.announcePolite(
        this.translate.instant(PathwayActionSuccess.REORDERED_SUBSECTION)
      );
      // TODO: Figure out where to put focus on success.
      return;
    }

    // was closed without reordering - return focus to meatball menu.
    this.focusStackService.push(popoverTrigger.nativeElement);
    this.focusStackService.pop();
  }
}
