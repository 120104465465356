import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

// Shared Imports
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

// Pathways RSM Imports
import {
  PathwayDetailsModel,
  PathwayLevel,
  PathwayMoveNodeInput,
  PathwayStep,
  PathwaySubsection,
  hasAtLeastOneStep,
} from '@dg/pathways-rsm';

// Pathways Imports
import { PathwayHoldService } from '@app/pathways/services/pathway-hold.service';

export type StepMoveToLocation = 'bin' | PathwaySubsection['id'];
interface StepMoveSubsection {
  title: PathwaySubsection['title'];
  id: PathwaySubsection['id'];
  disabled: boolean;
  afterNode: PathwayStep['node'] | null;
}

@Component({
  selector: 'dgx-pathway-step-move-modal',
  templateUrl: './pathway-step-move-modal.component.html',
})
export class PathwayStepMoveModalComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public step: PathwayStep;
  @Input() public pathway: PathwayDetailsModel;

  public itemsToAdd: PathwayStep[];
  public moveToValue: StepMoveToLocation;
  public moveAfterNode: StepMoveSubsection['afterNode'];
  public binCount: number;
  public sections = [];
  public isLoading: boolean = true;

  public readonly i18n = this.translate.instant([
    'Pathways_MoveItem',
    'Pathways_MoveItemTo',
    'Pathways_Bin',
    'Pathways_MovedItem',
  ]);

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private pathwayHoldService: PathwayHoldService
  ) {
    super();
  }

  public get isSubmitDisabled(): boolean {
    return !this.moveToValue || this.isLoading;
  }

  public ngOnInit(): void {
    this.processViewModel();
    this.isLoading = false;
    this.pathwayHoldService.getBinCount(this.pathway.id).subscribe((result) => {
      this.binCount = result;
    });
  }

  public select(
    moveTo: StepMoveToLocation,
    afterNode: StepMoveSubsection['afterNode'] = null
  ) {
    this.moveToValue = moveTo;
    this.moveAfterNode = afterNode;
  }

  public moveStep() {
    const response: PathwayMoveNodeInput = {
      pathwayId: this.pathway.id,
      node: this.step.node,
      type: PathwayLevel.STEP,
      moveAfterNode: this.moveAfterNode,
      beforeNode: null,
      moveToBin: this.moveToValue === 'bin',
    };
    this.activeModal.close(response);
  }

  public onDismiss(): void {
    this.activeModal.dismiss();
  }

  private processViewModel(): void {
    this.pathway.levels.map((section, sectionIndex) => {
      /* set section titles */
      let heading = this.translate.instant('Pathways_SectionNumberFormat', {
        number: section.number,
      });
      if (section.title) {
        heading = heading + `: ${section.title}`;
      }

      /* set subsection options  */
      const subsectionOptions = [];
      section.lessons.map((subsection, subsectionIndex) => {
        let subsectionTitle = this.translate.instant(
          'Pathways_SubsectionNumberFormat',
          { number: subsection.number }
        );
        if (subsection.title) {
          subsectionTitle = subsectionTitle + `: ${subsection.title}`;
        }
        let afterNode: StepMoveSubsection['afterNode'];
        if (hasAtLeastOneStep(subsection)) {
          afterNode = subsection.steps[subsection.steps.length - 1].node;
        } else {
          afterNode = subsection.node + '0/';
        }

        subsectionOptions.push({
          title: subsectionTitle,
          id: subsection.id,
          disabled:
            subsectionIndex + 1 === this.step.lessonNumber &&
            sectionIndex + 1 === this.step.levelNumber,
          afterNode: afterNode,
        });
      });
      this.sections.push({ title: heading, lessons: subsectionOptions });
    });
  }
}
