import { TranslateService } from '@ngx-translate/core';
import { Trigger } from '../model';
import { SecurityContext } from '@angular/core';

export const getTriggerLabel = (
  trigger: Trigger,
  translate: TranslateService
) => {
  if (trigger?.userActivity) {
    return translate.instant(
      `Automation_Event_${trigger.userActivity.replace(/\s/g, '')}`
    );
  } else {
    return translate.instant(`Automation_Trigger_${trigger.triggerType}`);
  }
};

export const formatTriggerOperatorDisplay = (
  operator: string,
  translate: TranslateService
) => {
  if (['isoneof', 'isnotoneof'].includes(operator)) {
    return operator === 'isoneof'
      ? translate.instant('Automation_IsOneOf')
      : translate.instant('Automation_IsNotOneOf');
  }
  return operator;
};

export const buildTriggerForList = (value, translate, sanitizer) => {
  if (value.length < 2) {
    return value;
  } else {
    const commaList = sanitizer.sanitize(
      SecurityContext.HTML,
      value.slice(0, -1).join(', ')
    );
    const lastItem = sanitizer.sanitize(SecurityContext.HTML, value.slice(-1));

    return translate.instant('Automation_Or_List_Format', {
      commaList,
      lastItem,
    });
  }
};
