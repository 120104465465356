import produce from 'immer';
import { LayoutAspect } from '@degreed/apollo-angular';
import { OrgInfo } from '@app/account/account-api.model';

import { BrandingState, NavigationState } from '../org-branding.model';
import { getHexColorFromCSSVariable } from './html.utils';

export const LAYOUT_SCHEMA = 'NavigationBranding';

/**
 * Schema for Layout API
 */
export type LayoutResponse = {
  schemaName: string;
  components: [
    {
      componentName: string;
      parameters: {
        data: string;
      };
    },
  ];
};

// **********************************************************
// Local Storage Utils
// **********************************************************

/**
 * Convert BrandingState to LocalStorage format
 */
export function stateToLocalStorage(state: Partial<BrandingState>) {
  const { endorsements, navigation, orgInfo } = state;
  return JSON.stringify({ endorsements, navigation, orgInfo });
}

/**
 * Save branding to local storage
 */
export function localToStateBranding(
  data: string
): Partial<BrandingState | null> {
  return data ? JSON.parse(data) : null;
}

// **********************************************************
// Layout API Utils
// **********************************************************

/**
 * Convert Layout API response to BrandingState
 */
export function layoutToBranding(
  response: LayoutResponse
): Partial<BrandingState | null> {
  const branding = response
    ? JSON.parse(response.components[0]?.parameters?.data)
    : (null as Partial<BrandingState | null>);

  // Force reset dirty flags
  if (branding) {
    branding.navigation.isDirty = false;
    branding.endorsements.isDirty = false;
    branding.orgInfo.isDirty = false;
  }

  return branding;
}

/**
 * Convert BrandingState to Layout API schema
 */
export function brandingToLayout({
  endorsements,
  navigation,
  orgInfo,
}: Partial<BrandingState>): LayoutResponse {
  return {
    schemaName: LAYOUT_SCHEMA,
    components: [
      {
        componentName: 'branding-v3',
        parameters: {
          data: JSON.stringify({ endorsements, navigation, orgInfo }),
        },
      },
    ],
  };
}

/**
 * Update target LayoutAspect with Navigation state
 */
export function navigationToAspect(
  state: Partial<NavigationState>,
  aspect: LayoutAspect
): LayoutAspect {
  const { colors, logo, mark } = state;

  return {
    ...aspect,
    brand: {
      ...aspect.brand,
      colors,
      logo: {
        ...aspect.brand.logo,
        url: logo.url || aspect.brand.logo.url,
        altText: logo.altText,
      },
      mark: {
        ...aspect.brand.mark,
        url: mark.url || aspect.brand.mark.url,
        altText: mark.altText,
      },
    },
  };
}

// **********************************************************
// Legacy OrganizationBranding Utils
// **********************************************************

/**
 * Update user OrganizationBranding with Layout state
 */
export function layoutToOrgBranding(
  layout: BrandingState,
  orgInfo: OrgInfo
): OrgInfo {
  const { endorsements, navigation, orgInfo: org } = layout;

  return {
    ...orgInfo,
    organizationBranding: {
      brandColor: navigation.colors.background,
      useLightText: navigation.colors.text === '#ffffff',
      organizationNameInOnboarding: org.useInOnboarding,
    },
    name: org.orgName,
    image: navigation.logo.url,
    endorsedImage: endorsements.endorsement.url,
  };
}

/**
 * Convert Authenticate user's OrganziationBranding to BrandingState
 */
export function orgBrandingToState(orgInfo: OrgInfo): Partial<BrandingState> {
  const branding = orgInfo?.organizationBranding;
  const neutralColor = getHexColorFromCSSVariable('--apollo-color-neutral-800');

  return orgInfo
    ? {
        navigation: {
          colors: {
            background: branding.brandColor,
            text: branding.useLightText ? '#ffffff' : neutralColor,
          },
          mark: {
            backgroundColor: branding.brandColor,
            imageType: 'mark',
            altText: orgInfo.name,
            url: orgInfo.image,
            progress: 0,
          },
          logo: {
            backgroundColor: branding.brandColor,
            imageType: 'logo',
            altText: orgInfo.name,
            url: orgInfo.image,
            progress: 0,
          },
        } as NavigationState,
        orgInfo: {
          isDirty: false,
          orgName: orgInfo.name || '',
          useInOnboarding: branding.organizationNameInOnboarding,
        },
        endorsements: {
          isDirty: false,
          endorsement: {
            altText: 'Degreed',
            url: orgInfo.endorsedImage,
            imageType: 'endorsement',
            progress: 0,
          },
        },
      }
    : null;
}
