import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CropperPosition, ImageCroppedEvent } from '../cropper/cropper.model';
import { CropperComponent } from '../cropper/cropper.component';
import {
  CropperCoordinates,
  ImageCropperEvent,
} from './image-cropper-modal.model';
import { ImageCropperModalService } from './services/image-cropper-modal.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

/**
 * A modal to crop an image. Should be used by calling showCropperModal in {@see ImageCropperService}
 *
 * @params imageUrl - Image to crop. Required
 * @params aspectRatio - Aspect ratio of the cropper. Required
 * @params secondaryAspectRatio - Secondary aspect ratio of the cropper. Optional
 * @params cropperCoordinates - Existing cropper coordinates. Optional
 */
@Component({
  selector: 'dgx-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss'],
})
export class ImageCropperModalComponent implements OnInit {
  public readonly imageDocumentationLink: string =
    this.webEnvironmentService.getZendeskUrl('/articles/4408914307218');
  @Input() public imageUrl: string;
  @Input() public aspectRatio: number;
  @Input() public secondaryAspectRatio: number;
  @Input() public cropperCoordinates?: CropperCoordinates;
  @Input() public maintainAspectRatio?: boolean = true;
  /** Existing image alt text to pass to the input */
  @Input() public altText?: string;
  /** Controls the visibility of the alt text input, default visible */
  @Input() public hideAltTextInput?: boolean = false;
  @Input() public modalTitle?: string;
  @Input() public modalSubmit?: string;
  @Input() public modalInstructions?: string;
  @Output() public errorEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('cropper') public cropperRef: CropperComponent;

  public cropperOptions?: {
    aspectRatio: number;
    secondaryAspectRatio?: number;
    cropper?: CropperPosition;
    maintainAspectRatio?: boolean;
  };
  public cropPreview: string;
  public secondaryCropPreview: string;
  public imageCroppedEvent: ImageCroppedEvent;
  public isLoading: boolean = true;
  public i18n = this.translateService.instant([
    'Core_AltText',
    'Core_AltTextHelperText',
    'Core_Cancel',
    'Core_Submit',
    'Core_Preview',
    'dgImageEdit_CropToFit',
    'dgImageEdit_ContentDetails',
    'dgImageEdit_ContentCard',
    'dgImageEdit_DocumentationDesc',
    'dgImageEdit_DocumentationLabel',
  ]);

  public showPlanSimplification = this.ldFlagsService.showPlanSimplification;

  constructor(
    private activeModal: NgbActiveModal,
    private imageCropperModalService: ImageCropperModalService,
    private ldFlagsService: LDFlagsService,
    private translateService: TranslateService,
    private webEnvironmentService: WebEnvironmentService
  ) {}
  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    // manually close the cropper on esc key
    // this is necessary to handle closing this modal when it is
    // opened from an AJS modal
    event.preventDefault();
    this.activeModal.dismiss();
  }

  public ngOnInit(): void {
    this.modalSubmit = this.modalSubmit
      ? this.modalSubmit
      : this.i18n.Core_Submit;
    this.modalTitle = this.modalTitle
      ? this.modalTitle
      : this.i18n.dgImageEdit_CropToFit;

    if (this.aspectRatio) {
      this.cropperOptions = {
        aspectRatio: this.aspectRatio,
        secondaryAspectRatio: this.secondaryAspectRatio,
        maintainAspectRatio: this.maintainAspectRatio,
      };

      this.i18n.dgImageEdit_DocumentationDesc = this.translateService.instant(
        'dgImageEdit_DocumentationDesc',
        {
          startAnchor: `<a href="${this.imageDocumentationLink}" class="link" target="_blank" rel="noopener noreferrer" data-dgat="image-cropper-modal-561" aria-describedby="a11yNewWindowDescription">`,
          endAnchor: '</a>',
        }
      );
    }
  }

  public onImageCropped(imageCroppedEvent: ImageCroppedEvent) {
    if (imageCroppedEvent) {
      this.cropPreview = imageCroppedEvent.cropper.base64;
      this.secondaryCropPreview = imageCroppedEvent.secondaryCropper?.base64;
      this.imageCroppedEvent = imageCroppedEvent;
    }
  }

  public onCropperReady({ width }) {
    const cropper = this.imageCropperModalService.getCropperPosition(
      this.cropperCoordinates,
      width,
      this.cropperRef.loadedImage.size.width
    );

    if (cropper) {
      // pass existing cropper position to the cropper
      this.cropperOptions.cropper = cropper;
    }
  }

  public onImageLoaded() {
    this.isLoading = false;
  }

  public cancel(event: Event) {
    event.preventDefault();
    this.activeModal.dismiss();
  }

  public submit() {
    const cropperCoordinates =
      this.imageCropperModalService.getCropperCoordinates(
        this.imageCroppedEvent
      );

    this.activeModal.close({
      cropperCoordinates,
      altText: this.altText,
      base64: this.cropPreview,
      secondaryBase64: this.secondaryCropPreview,
      imageUrl: this.imageUrl,
    } as ImageCropperEvent);
  }

  public onLoadImageFailed() {
    this.activeModal.dismiss();
  }
}
