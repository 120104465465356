import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Group } from '@app/groups/group-api';
import { InputPermissionsService } from '@app/inputs/services/input-permissions.service';
import {
  MenuViewModel,
  ModifyOptionsFnType,
} from '@app/shared/components/menu/menu.component';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { GroupService } from '@app/shared/services/group.service';
import { ModalService } from '@app/shared/services/modal.service';
import { isFunction } from '@app/shared/utils/type-guard-helpers';
import { TargetService } from '@app/target/services/target.service';
import { TranslateService } from '@ngx-translate/core';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

@Component({
  selector: 'dgx-group-tile',
  templateUrl: './group-tile.component.html',
  styleUrls: ['./group-tile.component.scss'],
})
export class GroupTileComponent implements OnInit {
  @Input() public group: any;
  @Input() public referId: string;
  /** Add to the default menu config. */
  @Input() public modifyOptionsFn?: ModifyOptionsFnType<any>;
  @Input() public menuConfig: MenuViewModel[];

  @ViewChild('optionsMenuTrigger')
  public optionsMenuTrigger: ElementRef;

  public i18n = this.translate.instant([
    'Core_AddToJobRole',
    'Core_Join',
    'Core_Joined',
    'Core_MoreOptions',
    'Core_YesSure',
    'dgGroupTile_JoinRequestPending',
    'dgGroupTile_LeaveGroupModalHeader',
    'dgGroupTile_LeaveGroupModalBodyText',
    'dgGroupTile_RequestMembership',
  ]);
  public ariaLabel: string;
  public ariaPressed: boolean;

  // Limits
  public descriptionEllipsisLimit = 100;
  public titleEllipsisLimit = 40;

  // Actions
  public defaultMenuConfig: MenuViewModel[] = [
    {
      title: this.i18n.Core_AddToJobRole,
      defaultAction: () =>
        this.targetService
          .showAddToTargetModal(
            'JobRole',
            {
              ...this.group,
              resourceId: this.group.groupId,
              resourceType: 'Group',
            },
            this.optionsMenuTrigger.nativeElement
          )
          .subscribe(),
      isHidden: () => !this.inputPermissionsService.canAddToTarget('Group'),
      preventRefocus: true,
    },
  ];
  public defaultActionsConfig = {
    inlineOptions: [],
    menuOptions: [
      {
        optionId: 'jobRole',
        onClick: (_: Event, target: HTMLInputElement, group: Group) =>
          this.targetService
            .showAddToTargetModal(
              'JobRole',
              {
                ...group,
                resourceId: group.groupId,
                resourceType: 'Group',
              },
              target
            )
            .subscribe(),
        isVisible: () => this.inputPermissionsService.canAddToTarget('Group'),
      },
    ],
  };

  public constructor(
    private groupService: GroupService,
    private ldFlagsService: LDFlagsService,
    private modal: ModalService,
    private targetService: TargetService,
    private translate: TranslateService,
    private router: Router,
    private inputPermissionsService: InputPermissionsService,
    private cdr: ChangeDetectorRef
  ) {}

  public get buttonToShow() {
    if (
      !this.group.isMember &&
      !this.group.isPendingMember &&
      this.group.privacyLevel !== 'Closed' &&
      this.group.privacyLevel !== 1
    ) {
      return 'join';
    } else if (
      !this.group.isMember &&
      !this.group.isPendingMember &&
      (this.group.privacyLevel === 'Closed' || this.group.privacyLevel === 1)
    ) {
      return 'requestMembership';
    } else if (this.group.isPendingMember) {
      return 'joinRequestPending';
    } else if (this.group.isMember) {
      return 'joined';
    }
  }

  public ngOnInit() {
    // new menu
    this.menuConfig = isFunction(this.modifyOptionsFn)
      ? this.modifyOptionsFn(this.group, this.defaultMenuConfig)
      : this.defaultMenuConfig;

    this.setAriaAttributes();
  }

  public memberCountLabel(count: number) {
    if (!count) {
      count = 0;
    }
    return this.translate.instant('dgGroupTile_MemberCount', { count });
  }

  public join(event: Event) {
    event.preventDefault();
    this.group.isMember = true;
    this.setAriaAttributes();
    return this.groupService
      .join(
        {
          groupId: this.group.groupId,
          name: this.group.name,
          privacyLevel: this.group.type,
          userCount: this.group.memberCount,
          interestNames: this.group.topics,
        },
        this.referId
      )
      .subscribe();
  }

  public leave(event: Event): void {
    event.preventDefault();
    const inputs = {
      canCancel: true,
      closeOnSubmit: false,
      headerText: this.i18n.dgGroupTile_LeaveGroupModalHeader,
      bodyText: this.i18n.dgGroupTile_LeaveGroupModalBodyText,
      submitButtonText: this.i18n.Core_YesSure,
    };
    this.modal
      .show<void>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: true,
      })
      .subscribe({
        next: () => {
          this.group.isMember = false;
          this.setAriaAttributes();
          return this.groupService.leave(this.group).subscribe();
        },
        error: () => {
          this.group.isMember = true;
        },
        complete: () => {
          this.cdr.detectChanges();
        },
      });
  }

  public requestMembership($event: Event) {
    $event.preventDefault();
    this.group.isPendingMember = true; // Assume success
    return this.groupService
      .requestMembership({
        groupId: this.group.groupId,
        name: this.group.name,
        privacyLevel: this.group.privacyLevel,
        userCount: this.group.userCount,
      })
      .subscribe((data: any) => {
        if (data.addedToGroup) {
          this.group.isMember = true;
        } else {
          this.group.isPendingMember = true;
        }
      });
  }

  public routeToGroup(): void {
    this.router.navigate([`../groups/${this.group?.groupId}`]);
  }

  private setAriaAttributes(): void {
    if (this.buttonToShow === 'join') {
      this.ariaPressed = false;
      this.ariaLabel = this.translate.instant('A11y_GroupNotJoinedButton', {
        title: this.group.name,
      });
    } else if (this.buttonToShow === 'joined') {
      this.ariaPressed = true;
      this.ariaLabel = this.translate.instant('A11y_GroupJoinedButton', {
        title: this.group.name,
      });
    }
  }
}
