import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TranslateService } from '@ngx-translate/core';
import { SubscriberBaseDirective } from '../subscriber-base/subscriber-base.directive';
import { ColumnConfiguration } from './column-configurator.model';

const COLUMN_CONFIG_BREAKPOINTS = {
  L: '(min-width: 1366px)',
  XL: '(min-width: 1440px)',
};

@Component({
  selector: 'dgx-column-configurator',
  templateUrl: './column-configurator.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnConfiguratorComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public columnConfigurations: ColumnConfiguration[] = []; // current column configurations
  @Input() public preferredColumnConfigurations: ColumnConfiguration[] = []; // user preferred column configurations used for media listener
  @Input() public availableColumns: Partial<ColumnConfiguration>[]; // columns available to the configurator
  @Input() public disabled = false;
  @Input() public maxSelectable: number = 5; // the max selectable amount of columns
  @Input() public typeDefaultHierarchy: string[] = []; // the column type hierarchy used for media listener
  @Input() public useMediaListener: boolean = false; // whether or not to adjust max columns based on resolution
  @Input() public wrapperClasses = 'guts-m-r-1';
  @Input() public buttonClasses = '';
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public i18n = this.translateService.instant([
    'dgProfileOverview_Columns',
    'dgProfileOverview_ColumnsToDisplay',
  ]);
  public isPopoverOpen: boolean = false;
  public adjustedMaxSelectable: number = 5; // max selectable columns adjusts based on useMediaListener

  constructor(
    private translateService: TranslateService,
    private windowLayoutService: WindowLayoutService
  ) {
    super();
  }

  public get popupTitle() {
    return this.columnConfigurations?.length <= this.adjustedMaxSelectable
      ? this.i18n.dgProfileOverview_ColumnsToDisplay
      : this.translateService.instant('dgProfileOverview_MaxColumnsToDisplay', {
          maxSelectable: this.adjustedMaxSelectable,
        });
  }

  public ngOnInit(): void {
    this.setColumns();
  }

  private setColumns(): void {
    if (this.useMediaListener) {
      this.setMediaListener();
    } else {
      this.adjustedMaxSelectable = this.maxSelectable;
      this.accountForAvailableColumns();
    }
  }

  // adjusts the # of columns allowed to be shown based on resolution breakpoints
  public setMediaListener(): void {
    const { L, XL } = COLUMN_CONFIG_BREAKPOINTS;

    this.windowLayoutService
      .registerMediaListener([L, XL], false)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        if (data.breakpoints[XL]) {
          this.adjustedMaxSelectable = 8;
        } else if (data.breakpoints[L]) {
          this.adjustedMaxSelectable = 7;
        } else {
          // max columns given from parent
          this.adjustedMaxSelectable = this.maxSelectable;
        }

        this.accountForAvailableColumns();

        const currentVisibleCount = this.columnConfigurations.filter(
          (column) => column.visible
        ).length;

        if (currentVisibleCount > this.adjustedMaxSelectable) {
          // hide extra columns
          this.adjustColumnsShown(currentVisibleCount, false);
          this.updateColumns(false);
        } else if (currentVisibleCount < this.adjustedMaxSelectable) {
          // show more columns
          this.adjustColumnsShown(currentVisibleCount, true);
          this.updateColumns(false);
        }
      });
  }

  // adjusts (adds or removes) column visibility
  public adjustColumnsShown(
    currentVisibleCount: number,
    addColumns: boolean
  ): void {
    const hierarchy = addColumns
      ? this.typeDefaultHierarchy
      : [...this.typeDefaultHierarchy].reverse();
    let adjustedColumnCount = 0;

    hierarchy.forEach((type) => {
      this.columnConfigurations = this.columnConfigurations.map((column) => {
        if (
          column.type === type &&
          this.shouldAdjustColumn(
            addColumns,
            column,
            currentVisibleCount,
            adjustedColumnCount
          )
        ) {
          adjustedColumnCount++;
          return {
            ...column,
            visible: addColumns,
          };
        }
        return column;
      });
    });
  }

  // determines whether column visibility should be adjusted (added or removed)
  public shouldAdjustColumn(
    addColumns: boolean,
    column: ColumnConfiguration,
    currentVisibleCount: number,
    adjustedColumnCount: number
  ): boolean {
    if (addColumns) {
      const preferredColumn = this.preferredColumnConfigurations?.find(
        (col) => column.type === col.type
      );
      return (
        !column.visible &&
        (!preferredColumn || preferredColumn.visible) &&
        currentVisibleCount + adjustedColumnCount < this.adjustedMaxSelectable
      );
    } else {
      return (
        column.visible &&
        currentVisibleCount - adjustedColumnCount > this.adjustedMaxSelectable
      );
    }
  }

  public popoverToggled(event): void {
    if (!event.currentTarget?.contains(event.relatedTarget)) {
      this.isPopoverOpen = false;
    } else {
      this.isPopoverOpen = true;
    }
  }

  public columnChanged(column): void {
    if (!column.visible && this.isConfiguratorDisabled()) {
      return;
    }

    column.visible = !column.visible;
    this.updateColumns();
  }

  // removes columns that are no longer available and adds newly available columns
  public accountForAvailableColumns(): void {
    if (!this.availableColumns) {
      return;
    }

    // sort out columns that are no longer available due to a flag, etc.
    this.columnConfigurations = this.columnConfigurations.filter((column) =>
      this.availableColumns?.some(
        (availableColumn) => availableColumn.type === column.type
      )
    );

    // add columns that are now available, that weren't before
    let currentSelectedColumnCount = this.columnConfigurations.filter(
      (column) => column.visible
    ).length;
    this.availableColumns?.forEach((availableColumn, index) => {
      if (
        !this.columnConfigurations.some(
          (column) => column.type === availableColumn.type
        )
      ) {
        this.columnConfigurations.splice(index, 0, {
          type: availableColumn.type,
          label: availableColumn.label,
          visible: currentSelectedColumnCount < this.adjustedMaxSelectable, // make new column visible if able to
        });
        if (currentSelectedColumnCount < this.adjustedMaxSelectable) {
          currentSelectedColumnCount++;
        }
      }
    });

    // only update user preferences if they already have some set or aren't using the media listener
    this.updateColumns(
      !this.useMediaListener || this.preferredColumnConfigurations?.length > 0
    );
  }

  public isConfiguratorDisabled(): boolean {
    if (
      !this.columnConfigurations ||
      this.columnConfigurations.length <= this.adjustedMaxSelectable
    ) {
      return false;
    }

    const visibleColumns = this.columnConfigurations.filter(
      (column) => column.visible
    ).length;
    return visibleColumns >= this.adjustedMaxSelectable;
  }

  public updateColumns(updatePreferences?: boolean): void {
    this.onChange.emit({
      columnTypes: this.columnConfigurations
        .filter((column) => column.visible)
        .map((column) => column.type),
      columnConfigurations: this.columnConfigurations,
      updatePreferences,
    });
  }
}
