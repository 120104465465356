import { Inject, Injectable } from '@angular/core';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { InputsFacadeBase } from '@app/user-content/services/inputs-facade-base';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { FormRenderAction, RendererContext } from '../form-renderer.model';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { InputContext } from '../user-input.model';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import {
  ExperienceApiEntity,
  ExperienceFormModel,
} from './model/experience-form.model';
import { UserProfileExperienceRenderer } from './renderers/user-profile-experience.renderer';

/** A stateful facade service that provides Article add/edit modal functionality to the generic Input
 * modal container
 **/
@Injectable({ providedIn: 'any' })
export class ExperienceFacade extends InputsFacadeBase<
  ExperienceFormModel,
  ExperienceApiEntity
> {
  public inputType = 'Experience';
  private readonly i18n = this.translate.instant([
    'PositionFormCtrl_JuniorPosition',
    'PositionFormCtrl_IntermediatePosition',
    'PositionFormCtrl_SeniorPosition',
    'PositionFormCtrl_SaveExperience',
    'PositionFormCtrl_AddExperience',
    'PositionFormCtrl_EditExperience',
    'PositionFormCtrl_EndAfterStart',
    'Core_SelectStartDate',
    'Core_SelectEndDate',
    'Core_JobRoleLiteral',
    'Opportunities_TypeProject',
    'Opportunities_TypeMentorship',
    'Opportunities_TypeMenteeship',
    'Opportunities_TypeShadowing',
    'Opportunities_TypeStretchAssignment',
    'Opportunities_TypeOther',
  ]);
  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: ExperienceApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    fieldBuilder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    dgxCommentsApiSvc: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    private renderer: UserProfileExperienceRenderer,
    tipService: TipService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      fieldBuilder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      dgxCommentsApiSvc,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }
  protected get extendedDefaultViewModel(): Partial<ExperienceFormModel> {
    const formTitleAndButtonTitle = this.inputContext.isEditing
      ? this.i18n.PositionFormCtrl_EditExperience
      : this.i18n.PositionFormCtrl_AddExperience;
    return {
      formTitle: formTitleAndButtonTitle,
      extent: { hoursPerWeek: 40 },
      submitButtonText: formTitleAndButtonTitle,
      level: 'Intermediate',
      inputSubType: this.initialModel?.inputSubType ?? null,
    };
  }

  protected buildUIConfiguration(
    _?: FormRenderAction
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const context: RendererContext = {
      inputContext: {
        ...this.inputContext,
        inputType: 'Position',
        isCompleting: this.isCompleting,
      },
      state: () => this.viewModel,
      templates: this.templates,
      action: { type: 'DEFAULT' },
      translationKeys: this.i18n,
    };
    return this.renderer.render(context);
  }

  protected getEditViewModel(
    source: Partial<ExperienceApiEntity> = {}
  ): Observable<ExperienceFormModel> {
    // isEditing set to true? Source undefined? Return our default
    // parent getEditViewModel.
    if (this.isEditing || !source) {
      return super.getEditViewModel(source);
    }
    // Otherwise, we've got an Opportunity here to coerce into our
    // Add Experience form.
    const viewModel = {
      ...this.viewModel,
      ...this.extendedDefaultViewModel,
      ...source,
      // Don't stomp our default values with undefined
      extent: {
        ...source.extent,
        hoursPerWeek:
          source?.extent?.hoursPerWeek ??
          this.extendedDefaultViewModel?.extent?.hoursPerWeek,
      },
    };
    // Return as observable to match parent getEditViewModel,
    // instead of setting this.viewModel in place.
    return of(viewModel);
  }
}
