import {
  Pathway,
  PathwaySection,
  PathwaySubsection,
} from '../pathway-api.model';
import { isUntitled } from './pathway.utils';

/**
 * Return either the valid array of children or a placeholder array containing
 * one faux child.
 *
 * @see {PathwayAuthorSections.getSections} and @see {PathwayAuthorSubsections.getSubsections}
 *
 * @param pathway
 * @param children
 */
export function getSectionsSafe(
  pathway: Pathway,
  children: PathwaySection[]
): PathwaySection[] {
  if (!!children?.length) {
    return children;
  }
  return [createMockFauxSection(pathway)];
}
/**
 * Return either the valid array of children or a placeholder array containing
 * one faux child.
 *
 * @see {PathwayAuthorSections.getSections} and @see {PathwayAuthorSubsections.getSubsections}
 *
 * @param pathway
 * @param children
 */
export function getSubsectionsSafe(
  pathway: Pathway,
  children: PathwaySubsection[]
): PathwaySubsection[] {
  if (!!children?.length) {
    return children;
  }
  return [createMockFauxSubsection(pathway)];
}

/**
 * Whether a given pathway *explicitly* contains a faux section.
 *
 * @param pathway
 * @param hasFaux
 */
export function hasFauxSection(pathway: Pathway, hasFaux: boolean): boolean {
  return pathway?.levels?.length === 1 && hasFaux;
}

/**
 * Whether a given section *explicitly* contains a faux subsection.
 *
 * @param section
 * @param fauxSubsections
 */
export function hasFauxSubsection(
  section: PathwaySection,
  fauxSubsections: string[]
): boolean {
  return (
    section?.lessons?.length === 1 &&
    isFauxSubsection(section.lessons[0], fauxSubsections)
  );
}

/**
 * Whether a given pathway has an explicitly faux or mock section.
 *
 * @param pathway
 * @param hasFaux
 */
export function hasFauxOrMockSection(
  pathway: Pathway,
  hasFaux: boolean
): boolean {
  return hasFauxSection(pathway, hasFaux) || hasMockSection(pathway.levels);
}

/**
 * Whether a given section has an explicitly faux or mock subsection.
 *
 * @param section
 * @param fauxSubsections
 */
export function hasFauxOrMockSubsection(
  section: PathwaySection,
  fauxSubsections: string[]
): boolean {
  return (
    hasFauxSubsection(section, fauxSubsections) ||
    hasMockSubsection(section.lessons)
  );
}

/**
 * Whether a given pathway has a *mock* faux section, not to be
 * confused with a *real* section merely being displayed as faux.
 *
 * @param sections - Array to check.
 */
export function hasMockSection(sections: PathwaySection[]): boolean {
  return hasMockContainer(sections);
}

/**
 * Whether a given pathway has a *mock* faux section, not to be
 * confused with a *real* section merely being displayed as faux.
 *
 * @param subsections - Array to check.
 */
export function hasMockSubsection(subsections: PathwaySubsection[]): boolean {
  return hasMockContainer(subsections);
}

/**
 * Whether a given container array contains an *extra faux* (mock) faux container.
 *
 * @param containers
 */
export function hasMockContainer(
  containers: PathwaySection[] | PathwaySubsection[]
): boolean {
  return !containers?.length || !containers[0].node;
}

/**
 * Whether a given array of containers is faux-*like*. These must be defined containers,
 * but which are only children and don't have a title or description.
 *
 * This method does *not* look for the hasFauxSection or check the fauxSubsections array.
 */
export function isFauxlike(
  containers: PathwaySection[] | PathwaySubsection[]
): boolean {
  return (
    containers?.length === 1 &&
    isUntitled(containers[0]) &&
    !containers[0].description
  );
}

/**
 * Whether a given subsection is explicitly faux.
 *
 * @param subsection
 * @param fauxSubsections
 */
export function isFauxSubsection(
  subsection: PathwaySubsection,
  fauxSubsections: string[]
): boolean {
  return !!subsection && fauxSubsections.includes(subsection.node);
}

// ***************************
// PRIVATE -------------------
// Internal Utils
// ***************************

/**
 * Create and return a *mock* faux section for a pathway that
 * has no sections currently. *Deliberately* leaves node undefined,
 * so that we know later when we are trying to add content to a faux
 * (sub)section and need to create a (sub)section first.
 *
 * @param pathway - The pathway to check.
 */
function createMockFauxSection(pathway: Pathway): PathwaySection {
  return {
    id: undefined,
    node: undefined,
    number: 1,
    pathId: pathway.id,
    lessons: [],
    totalLessons: 0,
    totalLessonsWithItems: 0,
    totalSteps: 0,
    title: '',
    description: '',
  } as PathwaySection;
}

/**
 * Create and return a *mock* faux section for a pathway that
 * has no sections currently. *Deliberately* leaves node undefined,
 * so that we know later when we are trying to add content to a faux
 * (sub)section and need to create a (sub)section first.
 *
 * @param pathway - The pathway to check.
 * @param parentIndex - The section index, not to be confused with its number (which can't be directly relied upon in authoring mode) or node.
 */
function createMockFauxSubsection(
  pathway: Pathway,
  parentIndex = 0
): PathwaySubsection {
  return {
    id: undefined,
    node: undefined,
    number: 1,
    levelNumber: parentIndex + 1,
    pathId: pathway.id,
    steps: [],
    lessonItemCount: 0,
    totalSteps: 0,
    title: '',
    description: '',
  } as PathwaySubsection;
}
