import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InputType } from '@app/shared/models/core-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ActivityHistogram {
  dayFormat: string;
  dto: Record<string, any /* unknown special type System_Object */>;
  length: number;
  monthFormat: string;
  weekFormat: string;
  totalInputs: number;
  totalPoints: number;
}
export interface PopularTag {
  masteryPoints?: number;
  tag: string;
  title: string;
  completions?: number;
}
export interface PopularProvider {
  itemCount?: number;
  providerId?: number;
  providerImage: string;
  providerName: string;
  rankId?: number;
}

export interface UserInsights {
  activityHistogram: ActivityHistogram;
  popularCategories: PopularTag[];
  popularProviders: PopularProvider[];
}

export interface InputPoints {
  inputType: InputType;
  points: number;
}

export interface InputCount {
  inputType: InputType;
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserInsightsService {
  public isClientProvider: boolean;

  constructor(
    private authService: AuthService,
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {
    this.isClientProvider =
      this.authService.authUser?.defaultOrgInfo?.settings.isClientProvider;
  }

  public getUserInsights(
    userKey: number | string,
    period: string,
    startDate: Date,
    endDate: Date,
    inputTypes: InputType | InputType[],
    tags: string
  ): Observable<UserInsights> {
    return this.http
      .get<UserInsights>('/userinsights/getuserinsights', {
        params: {
          userKey: userKey,
          period,
          startDate,
          endDate,
          inputTypes: inputTypes,
          term: tags,
        },
      })
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          throwError(
            new DgError(
              this.translate.instant('UserInsightsSvc_ErrorPersonalInfo'),
              errorResponse
            )
          )
        )
      );
  }

  public getInputPoints(userKey: number): Observable<InputPoints[]> {
    return this.http
      .get<InputPoints[]>('/userinsights/getinputpoints', {
        params: { userKey: userKey },
      })
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          throwError(
            new DgError(
              this.translate.instant('UserInsightsSvc_ErrorPersonalInfo'),
              errorResponse
            )
          )
        )
      );
  }

  public getInputCounts(userKey: number): Observable<InputCount[]> {
    return this.http
      .get<InputCount[]>('/userinsights/getinputcounts', {
        params: { userKey: userKey },
      })
      .pipe(
        map((data: InputCount[]) => {
          // Limiting input data types in insights summary, realizing these types exist in other contexts.
          if (data.length > 0 && this.isClientProvider) {
            const book = 'Book',
              podcast = 'Episode';
            data = data.filter(
              (item) => item.inputType !== book && item.inputType !== podcast
            );
          }
          return data;
        }),
        catchError((errorResponse: HttpErrorResponse) =>
          throwError(
            new DgError(
              this.translate.instant('UserInsightsSvc_ErrorPersonalInfo'),
              errorResponse
            )
          )
        )
      );
  }
}
