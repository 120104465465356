import { Injectable } from '@angular/core';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

import { DgError } from '@app/shared/models/dg-error';
import { BadgeMappingToAPI, BadgeInfo } from '../badge.model';
import { AuthUser } from '@app/account/account-api.model';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';

@Injectable()
export class BadgeService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_EditContentDuplicateError',
    'UserOutcomeSvc_RetreivingError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public addBadge(params: BadgeMappingToAPI): Promise<void> {
    const request$ = this.post<void>('/useroutcomes/add', {
      ...params,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public updateBadge(params: BadgeMappingToAPI): Promise<void> {
    const request$ = this.put<void>('/useroutcomes/update', {
      ...params,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public getBadge(
    userKey: AuthUser['viewerProfile']['userProfileKey'],
    userOutcomeId: UserOutcomeDetail['userOutcomeId']
  ): Promise<UserOutcomeDetail<BadgeInfo>> {
    const payload = { userKey, userOutcomeId };
    const request$ = this.get<UserOutcomeDetail<BadgeInfo>>(
      '/useroutcomes/getuseroutcome',
      payload
    ).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.UserOutcomeSvc_RetreivingError, e);
      }),
      tap((data) => {
        data.details = this.parseBadgeInfoDetails(data);
      })
    );
    return lastValueFrom(request$);
  }

  private parseBadgeInfoDetails(
    badge: UserOutcomeDetail<BadgeInfo>
  ): BadgeInfo {
    if ('string' !== typeof badge?.details) return;

    try {
      return JSON.parse(badge.details);
    } catch (e) {
      // suppress parse error
    }
  }
}
