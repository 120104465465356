import { Directive, Input } from '@angular/core';
import { EventBus } from '@dg/shared-services';
import { PathwaySection } from '../pathway-api.model';
import { PathwaySubsection } from '../rsm/pathway-api.model';
import {
  hasAtLeastOneStep,
  hasAtLeastOneSubsection,
  shouldShowSection,
} from '../rsm/utils';
import { PathwayContainerBaseDirective } from './pathway-container-base.directive';

/**
 * Base directive used by PathwaySection/Subsection components.
 * *Not* used by PathwaySectionNav, which uses PathwayNavBase instead.
 */
@Directive()
export abstract class PathwayUserBaseDirective extends PathwayContainerBaseDirective {
  @Input() public sections: PathwaySection[];
  @Input() public subsections: PathwaySubsection[];

  public markdownOptions = {
    allowImages: false,
  };

  constructor(eventBus: EventBus) {
    super(eventBus);
  }

  public hasAtLeastOneStep = hasAtLeastOneStep;
  public hasAtLeastOneSubsection = hasAtLeastOneSubsection;
  public shouldShowSection = shouldShowSection;
}
