import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CatalogArticleInputFacade } from '@app/user-content/user-input-v2/inputs/article/services/catalog/catalog-article.facade';

// misc
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { ArticleModel } from '@app/user-content/user-input-v2/inputs/article/article.model';
import {
  maxFifteenSkillsValidator,
  atLeastOneRequiredValidator,
  contentOwnerIdValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import {
  validateEntryUrlError,
  hasBrokenUrlValidator,
} from '@app/user-content/user-input-v2/utils/catalog-form-utils';

import {
  onFormControlUpdate,
  markFormAsTouched,
} from '@app/shared/utils/angular-form-field-helpers';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { handleArticleFocusOnSubmit } from '../../services/article.utils';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { AuthService, LDFlagsService } from '@dg/shared-services';
import { useSkillProficiencyLevels } from '@app/tags/utils/skills-proficiency-level.utils';

@Component({
  selector: 'dgx-article-catalog',
  templateUrl: './article-catalog.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCatalogComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @ViewChild('entryUrl')
  public entryUrlInput: ElementRef<HTMLElement>;

  public articleCatalogForm: FormGroup;
  public i18n = this.translate.instant([
    'Core_Description',
    'Core_GeneralErrorMessage',
    'Core_Hours',
    'Core_Minutes',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Or',
    'Core_Required',
    'Core_Source',
    'Core_Skills',
    'Core_Title',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgContentHosting_DragAndDrop',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContentForm_ArticleFormat',
    'dgOrgInternalContentForm_ArticleFormatSelectPlaceholder',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContentForm_ArticleDescriptionPlaceholder',
    'MediaFormCtrl_AddArticle',
    'MediaFormCtrl_ArticleUrl',
    'MediaFormCtrl_EditArticle',
    'MediaFormCtrl_ValidContentOwnerRequired',
    'MediaFormCtrl_SaveArticle',
    'MediaFormCtrl_TitleRequired',
    'MediaFormCtrl_UrlRequired',
    'MediaFormCtrl_UrlOrUploadRequired',
  ]);
  public heading: string;
  public vm$: Observable<ArticleModel>;
  public showExpandedForm: boolean = false;
  public useSkillProficiencyLevels: boolean = false;

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private facade: CatalogArticleInputFacade,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private ldFlagService: LDFlagsService
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    await this.facade.initializeViewModel(this.context);
    this.useSkillProficiencyLevels = useSkillProficiencyLevels(
      this.authService.authUser,
      this.ldFlagService
    );

    this.heading = this.facade.snapshot.inputContext.isEditing
      ? this.i18n.MediaFormCtrl_EditArticle
      : this.i18n.MediaFormCtrl_AddArticle;
    if (this.facade.snapshot.inputContext.isEditing) {
      await this.facade.initializeEdit();
      this.initializeExpandedEditForm();
      return;
    }
    // Setup the view model
    this.initializeForm();
  }

  /**
   * When the URL has duplicates we display a message with the option to view the duplicates in a modal
   * Open the view duplicates modal
   */
  public openViewDuplicates(): void {
    this.facade.viewDuplicates();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/

  public onFormControlUpdate(field: string, value: any): void {
    onFormControlUpdate(this.articleCatalogForm, field, value);
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  /****************************************************
   * On form page submissions
   ****************************************************/
  public async onNext(form: FormGroupDirective): Promise<void> {
    markFormAsTouched(this.articleCatalogForm);
    if (this.articleCatalogForm.invalid) {
      return;
    }

    if (
      !!this.facade.snapshot.hostedContentDetails &&
      !!this.articleCatalogForm.get('contentUploader').value
    ) {
      this.articleCatalogForm.patchValue({
        entryUrl: '',
      });
      await this.facade.onNext(null);
    } else {
      await this.onEntryUrlNext();
    }

    // reset the submitted state on the form
    form.resetForm(this.articleCatalogForm.value);

    this.loadExpandedForm();
  }

  /**
   * On form Submission check if the form is valid
   */
  public async onSubmit(): Promise<void> {
    markFormAsTouched(this.articleCatalogForm);
    if (this.articleCatalogForm.invalid) {
      handleArticleFocusOnSubmit(this.articleCatalogForm.controls);
      return;
    }
    const entryUrlValue = this.articleCatalogForm.get('entryUrl')?.value;
    if (!!entryUrlValue) {
      await this.facade.fetchUrlDuplicates(entryUrlValue);
      if (this.facade.snapshot.duplicateCount > 0) {
        this.articleCatalogForm.get('entryUrl').markAsTouched();
        return;
      }
    }
    await this.facade.onSubmit(this.articleCatalogForm);
    this.activeModal.close(this.facade.snapshot);
  }
  /***************************************************
   * Helper methods
   ***************************************************/

  /**
   * On the initial catalog form page if the
   * @returns
   */
  public validateEntryUrlError(): boolean {
    return validateEntryUrlError(
      this.translate,
      this.facade.snapshot.isInitialForm,
      this.articleCatalogForm.get('entryUrl'),
      this.articleCatalogForm.hasError('atLeastOneRequired'),
      this.facade.snapshot.hasBrokenUrl,
      this.facade.snapshot.entryUrl
    );
  }

  /**
   * Calculate the correct error message to display
   *
   * @returns string translation for URL error message to be shown
   */
  public get getUrlErrorMessage(): string {
    const shouldShowContentUploader =
      !!this.facade.snapshot.shouldShowContentUploader;
    if (
      this.articleCatalogForm.hasError('atLeastOneRequired') &&
      shouldShowContentUploader
    ) {
      return this.i18n.MediaFormCtrl_UrlOrUploadRequired;
    }

    const hasBrokenUrlValidatorcos = hasBrokenUrlValidator(
      this.translate,
      this.facade.snapshot.hasBrokenUrl,
      this.facade.snapshot.entryUrl,
      this.articleCatalogForm.get('entryUrl').value
    );
    if (!!hasBrokenUrlValidatorcos) {
      return hasBrokenUrlValidatorcos.urlBrokenValidation;
    }

    return this.i18n.MediaFormCtrl_UrlRequired;
  }

  /******************************************************************
   * Private utils
   ******************************************************************/
  /**
   * On selecting next from the initial form
   */
  private async onEntryUrlNext(): Promise<void> {
    await this.facade.onNext(this.articleCatalogForm.get('entryUrl').value);

    // Check if the entry URL has duplicates, if it does the UI will display corresponding error
    if (this.facade.snapshot.duplicateCount > 0) {
      return;
    }
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.articleCatalogForm = this.formBuilder.group(
      {
        entryUrl: ['', [Validators.pattern(HTTP_REQUIRED_URL_PATTERN)]],
        contentUploader: this.facade.snapshot.shouldShowContentUploader
          ? ['', []]
          : null,
      },
      {
        validator: atLeastOneRequiredValidator(['entryUrl', 'contentUploader']),
      }
    );
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm(): void {
    this.showExpandedForm = true;

    const inputEntry = !!this.facade.snapshot.hostedContentDetails
      ? {
          contentUploader: [
            this.articleCatalogForm.get('contentUploader').value,
            Validators.required,
          ],
        }
      : {
          entryUrl: [
            this.articleCatalogForm.get('entryUrl'),
            [
              Validators.required,
              Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
            ],
          ],
        };

    this.articleCatalogForm = this.formBuilder.group({
      ...inputEntry,
      title: ['', [Validators.required, isEmptyValidator]],
      sourceName: [],
      format: [],
      description: [],
      owner: ['', Validators.required],
      image: [],
      skills: ['', maxFifteenSkillsValidator],
    });

    this.articleCatalogForm.patchValue({
      entryUrl: this.facade.snapshot.entryUrl,
      title: this.facade.snapshot.title,
      sourceName: this.facade.snapshot.sourceName,
      format: this.facade.snapshot.format,
      description: this.facade.snapshot.summary,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.highConfidenceInferredSkills ?? [],
    });

    // When loading the full view reset the focus on the url input if we do not have
    // hosted content, otherwise dgxautofocus will focus on the title.
    if (!this.facade.snapshot.hostedContentDetails) {
      this.entryUrlInput?.nativeElement.focus();
    }
    // Subscribe to value changes on the entryURL to reset the duplicates error
    this.articleCatalogForm
      .get('entryUrl')
      ?.valueChanges.subscribe(() => this.facade.resetDuplicates());
    this.cdr.detectChanges();
  }

  /**
   * On edit initialize the full form
   */
  private initializeExpandedEditForm() {
    this.showExpandedForm = true;
    const inputEntry = !!this.facade.snapshot.hostedContentDetails
      ? {
          contentUploader: [
            this.facade.snapshot.hostedContentDetails,
            Validators.required,
          ],
        }
      : {
          entryUrl: [
            this.facade.snapshot.entryUrl,
            [
              Validators.required,
              Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
            ],
          ],
        };

    const createdByI18n = this.translate.instant('Core_CreatedBy', {
      authorName: this.facade.snapshot.createdBy,
    });

    const createdBy = !!this.facade.snapshot.createdBy ? createdByI18n : '';

    this.articleCatalogForm = this.formBuilder.group({
      ...inputEntry,
      createdBy: createdBy,
      title: [
        this.facade.snapshot.title ?? '',
        [Validators.required, isEmptyValidator],
      ],
      sourceName: this.facade.snapshot.sourceName ?? '',
      description: this.facade.snapshot.summary ?? '',
      format: this.facade.snapshot.format ?? '',
      owner: [
        this.facade.snapshot.owner ?? '',
        [Validators.required, contentOwnerIdValidator],
      ],
      image: this.facade.snapshot.imageUrl ?? '',
      skills: [this.facade.snapshot.tags ?? [], maxFifteenSkillsValidator],
    });
  }
}
