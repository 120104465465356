<form class="form-wrap" role="form" [formGroup]="form" (ngSubmit)="submit()">
  <dgx-modal
    [isSubmitDisabled]="!(form.valid && form.dirty)"
    [isSubmitPending]="isSubmitting || isEditingImage"
    [submitButtonText]="i18n.Core_Save"
    submitButtonType="primary"
    [useDefaultForm]="false"
    [canCancel]="true"
    [modalHeaderTitle]="headerText"
    (dismiss)="onDismiss()"
    headerWrapperClasses="guts-p-h-2"
  >
    <ng-container class="modal-body">
      <div class="guts-m-full-1">
        <df-local-notification
          [type]="NotificationType.warning"
          [text]="i18n.OrgPlans_CustomTitlesAndTextNotLocalized"
          size="small"
          class="block guts-m-b-2"
        ></df-local-notification>
        <df-form-root
          [form]="form"
          [model]="model"
          [options]="options"
          [fields]="fields"
        ></df-form-root>
      </div>
    </ng-container>
  </dgx-modal>

  <!-- wrapping this template with parent form so we can use FormControlName with it -->
  <ng-template #markdown let-formControl="formControl" let-params="params">
    <dgx-markdown-editor
      class="height-auto"
      name="markdownDescription"
      [maxLength]="params.maxLength"
      [placeholder]="i18n.dgFlexRow_TextTitlePlaceholder"
      [textareaId]="'markdownDescription'"
      [enableImageUpload]="false"
      [required]="params.required()"
      [showValidationErrors]="false"
      formControlName="freeformTextValue"
      minHeight="150px"
      ngDefaultControl
    ></dgx-markdown-editor>
  </ng-template>
</form>

<ng-template #imageUploader>
  <dgx-upload-section
    [imageUrl]="model.image?.imageUrl"
    [cropperCoordinates]="model.image?.cropperCoordinates"
    [modalInstructions]="modalInstructions"
    [uploadAdapter]="uploadAdapter"
    [aspectRatio]="1"
    [maintainAspectRatio]="false"
    [showImagePosition]="true"
    [imageAltText]="model.image?.altText"
    [imagePosition]="model.image?.position"
    [useCropper]="true"
    (uploadSuccessEvent)="onImageUploadSuccess($event)"
    (deleteEvent)="onDeleteImage()"
    (editEvent)="onEditingImage()"
    (isCropperOpenChange)="onEditingImage()"
  >
  </dgx-upload-section>
</ng-template>

<ng-template #imageUploaderOld>
  <dgx-upload-section
    [imageUrl]="model.image?.imageUrl"
    [cropperCoordinates]="model.image?.cropperCoordinates"
    [modalInstructions]="modalInstructions"
    [uploadAdapter]="uploadAdapter"
    [aspectRatio]="16 / 9"
    [showImagePosition]="true"
    [imagePosition]="model.image?.position"
    [useCropper]="true"
    (uploadSuccessEvent)="onImageUploadSuccess($event)"
    (deleteEvent)="onDeleteImage()"
    (editEvent)="onEditingImage()"
    (isCropperOpenChange)="onEditingImage()"
  >
  </dgx-upload-section>
</ng-template>

<ng-template #removeButton>
  <button
    df-button
    dfButtonType="destructive"
    class="full-width"
    type="button"
    (click)="removeRow()"
    data-dgat="free-text-row-modal-fa5"
  >
    {{ i18n.dgFlexRow_DeleteConfirmSubmit }}
  </button>
</ng-template>
