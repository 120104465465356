import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { NotificationItemService } from './notification-item.service';

@Component({
  selector: 'dgx-org-rating-scale-change',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgRatingScaleChangeComponent extends NotificationItemComponentBase {
  constructor(
    cdr: ChangeDetectorRef,
    itemService: NotificationItemService,
    private authService: AuthService
  ) {
    super(cdr, itemService);
  }
  protected get usePeriodicRefresh() {
    return true;
  }

  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    return this.translateHtml('OrgRatingScaleChange_NotificationContent', {
      orgName: this.authService?.authUser?.defaultOrgInfo?.name,
      viewNewRatingScaleHtml: this.viewOrgRatingScaleChangeTemplate(),
    });
    return '';
  }
}
