import { Injectable } from '@angular/core';

import { TrackerService } from '@app/shared/services/tracker.service';
import {
  AllInputApiEntities,
  RenderMode,
} from '../user-input/user-input.model';

/** Provides input tracking events to the back end based on input event messages on the global message bus
 * IMPORTANT: Currently, tracking for some actions is provided elsewhere. With additional refactoring
 * those could be brought in here too.
 * Ideally, instead of being called directly, this should be refactored to listen to the salient events on
 * a message bus. However, the current structure of the app modules creates multiple instances of the services,
 * due to lazy loading, which causes duplicated notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class InputTrackingService {
  constructor(private tracker: TrackerService) {}

  public trackInputCreated(
    renderMode: RenderMode,
    entity: AllInputApiEntities,
    trackingArea: string
  ) {
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.trackUserProfileCreate(entity, trackingArea);
      case RenderMode.Pathways:
        return this.trackPathwayAdd(entity);
      case RenderMode.ContentCatalog:
        return this.trackContentCatalogAdd(entity);
    }
  }

  public trackInputUpdated(
    renderMode: RenderMode,
    entity: AllInputApiEntities
  ) {
    // User profile input update tracking is currently handled outside of this module
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.trackUserProfileUpdate(entity);
      case RenderMode.Pathways:
        return this.trackPathwayUpdate(entity);
      case RenderMode.ContentCatalog:
        return this.trackContentCatalogUpdate(entity);
    }
  }

  public trackInputDeleted(
    renderMode: RenderMode,
    entity: AllInputApiEntities
  ) {
    // User profile and pathway input removal tracking is currently handled outside of this module
    switch (renderMode) {
      case RenderMode.ContentCatalog:
        return this.trackContentCatalogDelete(entity);
    }
  }

  public getUpdatedDuration(entity: AllInputApiEntities): number | string {
    let durationHours;
    let durationMinutes;

    if (entity.durationHours && entity.durationMinutes) {
      durationHours = parseInt(entity.durationHours.toString());
      durationMinutes = parseInt(entity.durationMinutes.toString());
      return durationHours * 60 + durationMinutes;
    } else if (entity.durationHours) {
      durationHours = parseInt(entity.durationHours.toString());
      return durationHours * 60;
    } else if (entity.durationMinutes) {
      durationMinutes = parseInt(entity.durationMinutes.toString());
      return durationMinutes;
    }
    return 'None';
  }

  private trackUserProfileCreate(
    entity: AllInputApiEntities,
    trackingArea: string
  ) {
    this.tracker.trackEventData({
      action: 'Content Completed',
      category: entity.inputType,
      properties: {
        ...entity,
        ProviderId: entity.institutionId ?? 'None',
        ProviderName: entity.institutionName ?? 'None',
        SkillTagCount: entity.tags?.length ?? 'None',
        ActionLocation: trackingArea,
      },
    });
  }

  private trackUserProfileUpdate(entity: AllInputApiEntities) {
    this.tracker.trackEventData({
      action: 'User Item Updated',
      category: entity.inputType,
      properties: {
        ...entity,
        skillTagCount: entity.tags?.length,
      },
    });
  }

  private trackPathwayAdd(entity: AllInputApiEntities) {
    this.tracker.trackEventData({
      action: 'Content Added To Pathway Bin',
      category: entity.inputType,
      properties: entity,
    });
  }

  private trackPathwayUpdate(entity: AllInputApiEntities) {
    this.trackContentCatalogUpdate(entity); // In pathways context, editing simply means editing the content catalog item, if present
  }

  private trackContentCatalogUpdate(entity: AllInputApiEntities) {
    let eventDataToTrack = {
      action: 'CMS Item Updated',
      category: entity.inputType,
      properties: {
        ...entity,
        SuggestedDuration:
          entity.durationUnits ??
          entity.duration ??
          entity.mediaLength ??
          'None',
      },
    };

    if (entity.inputType === 'Article' || entity.inputType === 'Assessment') {
      eventDataToTrack.properties = {
        ...eventDataToTrack.properties,
        ...{ UpdatedDuration: this.getUpdatedDuration(entity) },
      };
    }

    this.tracker.trackEventData(eventDataToTrack);
  }

  private trackContentCatalogAdd(entity: AllInputApiEntities) {
    const title = entity.title || entity.name;
    let eventDataToTrack = {
      action: 'Content Added to Catalog',
      // This property set is different for historical reasons
      properties: {
        ContentId: entity.inputId,
        ContentType: entity.inputType,
        DateModified: Date(),
        SuggestedDuration:
          entity.durationUnits ?? entity.duration ?? entity.mediaLength,
        DurationUnits: entity.unitType,
        ExternalId: entity.externalId,
        HostedType: entity.hostedContentDetails?.hostType,
        ImageUrl: entity.imageUrl,
        ProviderId: entity.providerId,
        ProviderName: entity.providerName,
        Title: title,
        SessionCount: entity.sessions?.length,
      },
    };

    if (entity.inputType === 'Article' || entity.inputType === 'Assessment') {
      eventDataToTrack.properties = {
        ...eventDataToTrack.properties,
        ...{ UpdatedDuration: this.getUpdatedDuration(entity) },
      };
    }
    this.tracker.trackEventData(eventDataToTrack);
  }

  private trackContentCatalogDelete(entity: AllInputApiEntities) {
    this.tracker.trackEventData({
      action: 'Content Deleted From Catalog',
      properties: {
        ...entity,
        hosted: entity?.hostedContentDetails?.hostType === 'DegreedMedia',
      },
    });
  }
}
