import { Injectable } from '@angular/core';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { EllipsisPipe } from '@app/shared/pipes/ellipsis.pipe';
import { DurationConverterService } from '@app/shared/services/duration-converter.service';
import { Mapper } from '../mapper.model';
import { ImageUrlService } from '../services/image-url.service';
import { MediaEntryApiEntity } from '../user-input.model';
import { MediaFormModel } from './media-form.model';

/** Maps media entities, e.g., articles and video, to form view models and vice versa for
 * user profile media Input completions */
@Injectable({
  providedIn: 'root',
})
export class MediaMapper
  implements Mapper<MediaFormModel, MediaEntryApiEntity>
{
  private static readonly truncationLength = 500; // number of characters

  constructor(
    private ellipsisPipe: EllipsisPipe,
    private durationConverter: DurationConverterService,
    private imageUrlService: ImageUrlService
  ) {}

  /**
   * Maps a media entry entity/params MediaFormModelto a form model
   */
  public toViewModel(source: MediaEntryApiEntity): Partial<MediaFormModel> {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];

    return {
      hasBrokenUrl: source.hasBrokenUrl,
      fileManaged: source.fileManaged,
      inputId: source.inputId,
      inputType: source.inputType,
      organizationId: source.organizationId,
      userMediaId: source.userMediaId, // only valid for user completions
      providerName: source.sourceName, // invalid in catalog
      mediaUrl: source.entryUrl,
      uploadedContentDetails: source.hostedContentDetails,
      title: source.title,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      description: this.ellipsisPipe.transform(
        source.summary,
        MediaMapper.truncationLength
      ),
      imageUrl: source.imageUrl,
      imageSizeId: source.imageSizeId,
      format: source.format,
      mediaLength: this.durationConverter.fromSecondsToMinutes(
        source.mediaLength
      ),
      tags: source.tags ?? [],
      groupIds: groups,
      groups,
      owner: source.owner,
      publishDate: source.publishDate,
      externalId: source.externalId,
      language: source.language,
      addToCatalog: source.orgContentMetadata
        ? !source.orgContentMetadata?.hideFromCatalog
        : false,
      isUserAuthored: !!source.authored,
      canQuickParse: !!source.useQuickCheck,
      isAccessible: !!source.accessible,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      createdByName: source.createdByName ?? (source.createdBy as string), // createdBy is being phased out per https://degreedjira.atlassian.net/browse/PD-72413
    }; // TODO: We should have the form model be only the stuff that is directly mappable to the entity so we don't need to cast around missing props
  }

  /**
   * Maps a media form model to a media entry entity/params
   */
  public toApiParameters(source: MediaFormModel): MediaEntryApiEntity {
    const imageUrlData = source.imageUrl as any;
    const imageUrl = this.imageUrlService.getImageUrl(imageUrlData);

    // TODO: Cleanup
    // Currently groupIds is only getting set if the adv section of the form gets opened
    // Make sure it's set if it's not already set and something is in groups in order to not lose data.
    // group/groupId's needs a refactor so that we don't have to do this juggling of data
    // PD-79140
    if (!source.groupIds && source.groups) {
      source.groups = source.groupIds;
    }
    return {
      inputId: source.inputId,
      inputType: source.inputType,
      organizationId:
        source.addToCatalog === false ? null : source.organizationId,
      userMediaId: source.userMediaId, // only valid for user completions
      sourceName: source.providerName,
      entryUrl: source.mediaUrl,
      hostedContentDetails:
        source.uploadedContentDetails as HostedContentMetadata,
      title: source.title,
      summary: source.description,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      imageUrl,
      imageSizeId: source.imageSizeId,
      format: source.format,
      mediaLength: this.durationConverter.fromMinutesToSeconds(
        source.mediaLength
      ),
      tags: source.tags,
      orgContentMetadata: {
        hideFromCatalog: !source.addToCatalog,
        groupIds: source.groupIds ?? [],
      },
      owner: source.owner,
      publishDate: source.publishDate,
      externalId: source.externalId,
      language: source.language,
      authored: source.isUserAuthored,
      useQuickCheck: !!source.canQuickParse,
      accessible: !!source.isAccessible,
      resourceImageId: imageUrlData?.resourceImageId,
    };
  }
}
