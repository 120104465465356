import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents an Assigned Learning notification */
@Component({
  selector: 'dgx-assigned-learning',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedLearningComponent extends NotificationItemComponentBase {
  protected get usePeriodicRefresh() {
    return true;
  }

  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    const contentResource = resourceIdBuilder
      .withSegment(`assignedLearning_`)
      .withYouOrGroup()
      .withOptionalAlready()
      .withContentActionType()
      .asFormat();

    return this.translateHtml(contentResource, {
      nameHtml: this.userFullNameTemplate,
      groupNameHtml: this.groupNameTemplate,
      title: this.html`<span>${this.itemService.ellipsisPipe.transform(
        this.params.title,
        this.maxTitleLength
      )}</span>`,
      hasDateHtml: !this.params.dueDate
        ? this.html`<span class="hidden">`
        : this.html`<span>`,
      date: this.dueDateTemplate,
      hasDateEndHtml: this.html`</span>`,
      type: this.inputTypeTemplate,
      action: this
        .html`<span class="no-wrap">${this.itemService.contentActionPipe
        .transform(this.params.inputType)
        .toLowerCase()}</span>`,
    });
  }
}
