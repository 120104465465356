import { Injectable } from '@angular/core';
import {
  GetActiveUserInsightsContent,
  GetInsightsContent,
  OpportunityStatusOptions,
  UserInsightsContentDetails,
} from '@app/insights/insights-api.model';
import { formatInsightsDateLabels } from '@app/insights/utils';
import { GroupRecommendation } from '@app/recommendations/recommendations.api';
import { LocalizationService } from '@app/shared/localization.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { DateRangeService } from '@app/shared/services/date-range.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { camelCaseKeys } from '../../utils/property';
import { LearnerInsightType, ProviderCatalogType } from './constants';
import {
  CompletionParams,
  Group,
  InsightKPI,
  PopularProvider,
  TargetPathwayContent,
  TargetSkillsInfo,
  TargetSkillsInsights,
  TopLearnerInsights,
} from './reporting-service';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private groups$: Observable<Group[]>;
  private opportunityLocations$: Observable<any[]>;

  constructor(
    private dateRangeService: DateRangeService,
    private http: NgxHttpClient,
    private localizationService: LocalizationService
  ) {}

  /**
   * Get all groups for a specified org
   * @param orgId number
   */
  public getGroups(orgId): Observable<Group[]> {
    if (!this.groups$) {
      this.groups$ = this.http
        .get<Group[]>('/reporting/groups', {
          params: { orgId },
        })
        .pipe(shareReplay(1));
    }
    return this.groups$;
  }

  public getActiveUserInsights(params) {
    return this.http
      .get<GetActiveUserInsightsContent>('/reporting/GetActiveUserInsights', {
        params: {
          orgId: params.orgId,
          startDate: this.dateRangeService.getDateFormat(
            params.startDate.value
          ),
          endDate: this.dateRangeService.getDateFormat(params.endDate.value),
          groupId: params.groupId === 0 ? null : params.groupId,
          groupIdList: params.groupIdList,
        },
      })
      .pipe(
        map((response) => ({
          ...response,
          xAxisLabels: formatInsightsDateLabels(
            response.xAxisLabels,
            response.xAxisInterval,
            this.localizationService.userLocale
          ),
        }))
      );
  }

  public getInsights(
    orgId,
    startDate,
    endDate,
    inputTypes,
    tags?,
    groupId?,
    groupIdList?
  ): Observable<GetInsightsContent> {
    return this.http
      .get<GetInsightsContent>('/reporting/GetInsights', {
        params: {
          orgId,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          inputTypes: inputTypes,
          groupId: groupId,
          groupIdList: groupIdList,
          term: tags,
        },
      })
      .pipe(
        map((response) => ({
          ...response,
          xAxisLabels: formatInsightsDateLabels(
            response.xAxisLabels,
            response.xAxisInterval,
            this.localizationService.userLocale
          ),
        }))
      );
  }

  public getInsightKPIs(
    orgId,
    startDate,
    endDate,
    groupId?,
    groupIdList?
  ): Observable<InsightKPI[]> {
    return this.http.get<InsightKPI[]>('/reporting/GetInsightKPIs', {
      params: {
        orgId,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        groupId,
        groupIdList,
      },
    });
  }

  public getInsightSkillKpis(
    orgId,
    groupId,
    groupIdList,
    ratingType,
    skillId
  ): Observable<any[]> {
    return this.http.get<any[]>('/reporting/GetInsightSkillKpis', {
      params: {
        orgId,
        groupId: groupId === 0 ? null : groupId,
        groupIdList: groupIdList,
        ratingType,
        tagsList: skillId === 0 ? null : skillId,
      },
    });
  }

  public getNumberOfDays(startDate, endDate): number {
    if (startDate.value) {
      startDate = startDate.value;
      endDate = endDate.value;
    }
    // original date format "Wed Nov 06 2019 00:00:00"
    // we must convert to milliseconds
    // because the time is 00:00:00 or midnight the last day is not counted so we must add it
    const millisecondsInADay = 86400000,
      endDay = 1,
      diffInTime = endDate.getTime() - startDate.getTime(),
      diffInDays =
        Math.round(Math.abs(diffInTime / millisecondsInADay)) + endDay;
    return diffInDays;
  }

  public getOpportunityLocations(orgId): Observable<any[]> {
    if (!this.opportunityLocations$) {
      this.opportunityLocations$ = this.http
        .get<any[]>('/reporting/GetOpportunityLocations', {
          params: { orgId },
        })
        .pipe(shareReplay(1));
    }
    return this.opportunityLocations$;
  }

  /**
   * Get Opportunity Status Options
   * Used for filters in org opportunities to filter open and closed opportunities
   */
  public getOpportunityStatusOptions(): Observable<OpportunityStatusOptions[]> {
    return this.http.get<OpportunityStatusOptions[]>(
      '/reporting/GetOpportunityStatusOptions'
    );
  }

  public getOpportunityTypesByOrganization(orgId): Observable<any[]> {
    return this.http.get<any[]>(
      '/reporting/GetOpportunityTypesByOrganization',
      {
        params: {
          orgId,
        },
      }
    );
  }

  public getOrganizationSkills(orgId): Observable<any[]> {
    return this.http
      .get<any[]>('/reporting/GetOrganizationSkills', {
        params: {
          orgId,
        },
      })
      .pipe(
        map((data) => {
          if (data && data.length) {
            data = data.map((e) => {
              return camelCaseKeys(e);
            });
          }
          return data;
        })
      );
  }

  public getSkillProgressionInsights(
    orgId,
    groupId,
    ratingType,
    tagsList,
    minimumSkillUserCount?: number,
    groupIdList?: string
  ) {
    return this.http.get<any>('/reporting/GetTagRatingProgression', {
      params: {
        orgId,
        groupId: groupId === 0 ? null : groupId,
        groupIdList,
        ratingType,
        tagsList: tagsList === 0 ? null : tagsList,
        minimumSkillUserCount,
      },
    });
  }

  public getTaxonomyRolesReportURL() {
    return '/api/reporting/generatetaxonomyjobrolesreport';
  }

  public getTagRatingsSummary(
    orgId,
    ratingType?,
    groupId?,
    groupIdList?,
    count?,
    tagsList?,
    minimumSkillUserCount?: number
  ) {
    const params = {
      orgId,
      ratingType,
      groupId,
      groupIdList,
      count,
      tagsList: tagsList === 0 ? null : tagsList,
      minimumSkillUserCount,
    };
    return this.http.get<any>('/reporting/GetTagRatingsSummary', {
      params,
    });
  }

  public getTargetPathwayInfo(
    orgId,
    targetId,
    sectionNode,
    skip = 0,
    take = 10,
    groupId?
  ): Observable<TargetPathwayContent[]> {
    return this.http.get<TargetPathwayContent[]>(
      '/reporting/GetTargetPathwayInfo',
      {
        params: {
          orgId,
          targetId,
          sectionNode,
          skip,
          take,
          groupId,
        },
      }
    );
  }

  public getTotalContentViewed(
    orgid,
    startDate,
    endDate,
    inputTypes,
    tag,
    groupId,
    groupIdList
  ): Observable<Record<string, any>> {
    return this.http
      .get<Record<string, any>>('/reporting/gettotalcontentviewed', {
        params: {
          orgId: orgid,
          startDate: this.dateRangeService.getDateFormat(startDate),
          endDate: this.dateRangeService.getDateFormat(endDate),
          inputTypes: inputTypes,
          groupId: groupId,
          groupIdList: groupIdList,
        },
      })
      .pipe(
        map((response) => ({
          ...response,
          xAxisLabels: formatInsightsDateLabels(
            response.xAxisLabels,
            response.xAxisInterval,
            this.localizationService.userLocale
          ),
        }))
      );
  }

  public getPopularTags(
    orgid,
    startDate,
    endDate,
    count,
    groupId,
    groupIdList,
    location
  ) {
    return this.http.get('/reporting/getpopulartags' as any, {
      params: {
        orgId: orgid,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        count: count,
        groupId: groupId,
        groupIdList: groupIdList,
      },
    });
  }

  public getPopularSearches(
    orgid,
    startDate,
    endDate,
    count,
    location,
    groupId?,
    groupIdList?
  ) {
    return this.http.get('/reporting/getpopularsearchterms', {
      params: {
        orgId: orgid,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        count: count,
        groupId: groupId,
        groupIdList: groupIdList,
      },
    });
  }

  public getSkillRatings(
    orgId,
    groupId,
    groupIdList,
    skip,
    count,
    type,
    sortOn,
    location,
    tagsList,
    minimumSkillUserCount?: number
  ) {
    return this.http.get<any>('/reporting/gettagratings', {
      params: {
        orgId,
        groupId,
        groupIdList,
        skip,
        count,
        type,
        sortOn,
        tagsList: tagsList === 0 ? null : tagsList,
        minimumSkillUserCount,
      },
    });
  }

  public getInsightsCompletion(
    params
  ): Observable<UserInsightsContentDetails[]> {
    if (!params.inputTypes) {
      params.inputTypes = '';
    }
    return this.http.get<UserInsightsContentDetails[]>(
      '/reporting/GetInsightsCompletionDetails',
      {
        params: {
          orgId: params.orgId,
          startDate: this.dateRangeService.getDateFormat(params.startDate),
          endDate: this.dateRangeService.getDateFormat(params.endDate),
          inputTypes: params.inputTypes,
          groupId: params.groupId,
          groupIdList: params.groupIdList,
          term: params.tags,
          count: params.count,
        },
      }
    );
  }

  public getInsightsViewed(params): Observable<UserInsightsContentDetails[]> {
    return this.http.get<UserInsightsContentDetails[]>(
      '/reporting/GetContentViewedDetail',
      {
        params: {
          orgId: params.orgId,
          startDate: this.dateRangeService.getDateFormat(params.startDate),
          endDate: this.dateRangeService.getDateFormat(params.endDate),
          inputTypes: params.inputTypes,
          groupId: params.groupId,
          groupIdList: params.groupIdList,
        },
      }
    );
  }

  public getGroupRecommendations(
    orgId,
    groupId,
    count,
    skip
  ): Observable<GroupRecommendation[]> {
    return this.http.get<GroupRecommendation[]>(
      '/reporting/getgrouprecommendations',
      {
        params: {
          organizationId: orgId,
          count: count,
          skip: skip,
          groupId: groupId,
        },
      }
    );
  }

  public getPopularProviders(
    orgId: number,
    startDate: Date | number | string,
    endDate: Date | number | string,
    catalog: ProviderCatalogType,
    count: number,
    groupId: number,
    groupIdList: string
  ): Observable<PopularProvider[]> {
    return this.http.get<PopularProvider[]>('/reporting/getpopularproviders', {
      params: {
        orgId,
        startDate: this.dateRangeService.getDateFormat(startDate),
        endDate: this.dateRangeService.getDateFormat(endDate),
        contentLevel: catalog,
        count,
        groupId,
        groupIdList,
      },
    });
  }

  public getSkillInsights(
    orgId,
    ratingType,
    targetId,
    sectionNode?,
    groupId?,
    groupIdList?,
    minimumSkillUserCount?: number
  ): Observable<TargetSkillsInfo[]> {
    return this.http.get<TargetSkillsInfo[]>(
      '/reporting/GetTargetTagSectionRatings',
      {
        params: {
          orgId,
          ratingType,
          targetId,
          sectionNode,
          groupId,
          groupIdList,
          minimumSkillUserCount,
        },
      }
    );
  }

  public getSkillInsightsForOrgs(
    orgId,
    ratingType,
    groupId,
    groupIdList,
    tagsList,
    minimumSkillUserCount?: number
  ): Observable<TargetSkillsInsights[]> {
    return this.http.get<TargetSkillsInsights[]>(
      '/reporting/GetTopUserTagRatings',
      {
        params: {
          orgId,
          ratingType,
          groupId,
          groupIdList,
          tagsList: tagsList === 0 ? null : tagsList,
          minimumSkillUserCount,
        },
      }
    );
  }

  public getTopLearnerInsights(
    params: CompletionParams
  ): Observable<TopLearnerInsights[]> {
    return this.http.get<TopLearnerInsights[]>(
      '/reporting/gettoplearnerinsights',
      {
        params: {
          orgId: params.orgId,
          startDate: this.dateRangeService.getDateFormat(params.startDate),
          endDate: this.dateRangeService.getDateFormat(params.endDate),
          groupId: params.groupId,
          groupIdList: params.groupIdList,
          insightType: LearnerInsightType.Completions,
          count: params.count,
        },
      }
    );
  }
}
