// modules
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DfIconModule } from '@lib/fresco';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

// components
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { TabNavigationRouterlessComponent } from './components/tab-navigation-routerless/tab-navigation-routerless.component';

/**
 * This module is meant to be a lightweight, shared module. It contains reusable
 * components for navigation, like the TabNavigation component.
 */
@NgModule({
  declarations: [TabNavigationComponent, TabNavigationRouterlessComponent],
  exports: [TabNavigationComponent, TabNavigationRouterlessComponent],
  imports: [
    CommonModule,
    DfIconModule,
    NgbNavModule,
    RouterModule,
    ScrollingModule,
  ],
})
export class NavigationModule {}
