import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 * We shouldn't worry error handling her, e.g. if a user types in a wrong date or random string, the error will be handled by component.
 */
@Injectable({ providedIn: 'root' })
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  /**
   * turns date string into date object
   * @param value - date string (eg. 12-08-2020, 12/08/2020, or 12082020, etc)
   * @returns an object with year, month, and day as properties
   */
  public parse(value: string): NgbDateStruct | null {
    if (value) {
      let date, day, month, year;
      if (value.includes('-')) {
        date = value.split('-');
      } else if (value.includes('/')) {
        date = value.split('/');
      } else {
        // mmddyyyy. e.g. 08-12-2020
        if (value.length === 8) {
          month = value.slice(0, 2);
          day = value.slice(2, 4);
          year = value.slice(4);
        }
        // mddyyyy
        // There are two ways to parse string like "1112020", it can be 1-11-2020, or 11-1-2020
        // With this situation, we will parse it as 1-11-2020.
        if (value.length === 7) {
          month = value.slice(0, 1);
          day = value.slice(1, 3);
          year = value.slice(3);
        }
        // mdyyyy. e.g. 872020
        if (value.length === 6) {
          month = value.slice(0, 1);
          day = value.slice(1, 2);
          year = value.slice(2);
        }

        return {
          day: parseInt(day, 10),
          month: parseInt(month, 10),
          year: parseInt(year, 10),
        };
      }

      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  /**
   * converts object containing year, month, day as properties in to a dash separated date
   * @param date - object containing year, month, day as properties
   * @returns (eg. 12-08-2020)
   */
  public format(date: NgbDateStruct | null): string {
    return date
      ? ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2) +
          '-' +
          date.year
      : null;
  }
}
