<ul *ngIf="authored?.length > 0" class="ib">
  <li
    *ngFor="let person of authored.slice(0, 2); let i = index"
    class="par--small ib"
    data-dgat="orgPlanManager-2f0"
  >
    <a
      target="_blank"
      [attr.href]="'/' + person.vanityUrl"
      data-dgat="orgPlanManager-118"
      class="uhover"
      >{{ person.name }}</a
    ><span *ngIf="authored.length > 0 && i < authored.length - 1">,&nbsp;</span>
    <span *ngIf="authored.length > 2 && i == 1" class="ib rel">
      <button
        [dgxPopoverTrigger]="moreAuthors"
        class="underline v-bottom color-ebony"
        data-dgat="orgPlanManager-67e"
      >
        <ng-container *ngIf="authored.length - 2 === 1">
          {{ 'dgManagePathways_OneMoreAuthor' | translate }}</ng-container
        >
        <ng-container *ngIf="authored.length - 2 !== 1">
          {{
            'dgManagePathways_MoreAuthorsFormat'
              | translate: { count: authored.length - 2 }
          }}
        </ng-container>
      </button>
      <dgx-popover #moreAuthors placement="bottom">
        <ul role="menu">
          <li
            *ngFor="let author of authored"
            data-dgat="personPopover-c99"
            class="border-bottom"
            role="menuitem"
          >
            <a
              target="_blank"
              [attr.href]="'/' + author.vanityUrl"
              data-dgat="orgPlanManager-118"
              class="guts-p-v-1 guts-p-h-1 guts-p-h-quart ib"
              >{{ author.name }}</a
            >
          </li>
        </ul>
      </dgx-popover>
    </span>
  </li>
</ul>
