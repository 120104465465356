import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { WizardStepComponent } from './wizard-step/wizard-step.component';
/**
 * Wizard component
 * This component allows a step by step navigation between panels
 * use <dgx-wizard-step> to define the contents of each step
 * use <dgx-wizard-step-label> (optional) to define the icon/label of each step component
 *
 * @param currentStepIndex {number} Current step  Default is set to 0
 * @param navigateForward {boolean} enable navigating to the next step by clicking on header or through the goToNextStep() method
 * @param navigateBackward {boolean} enable navigating to the previous step by clicking on header or through the goToPrevStep() method
 * @param indicator {'arrow' | 'line} Default is set to line. this shows the transition from 1 step to the other
 *
 * @example
 * ```
 * <dgx-wizard
 *   [navigateForward]="true"
 *   [navigateBackward]="true"
 *   indicator="arrow"
 *  >
 *    <dgx-wizard-step (stepEnter)="preStepChange()">
 *      content of step 1
 *    </dgx-wizard-step>
 *    <dgx-wizard-step (stepEnter)="preStepChange()">
 *      content of step 2
 *    </dgx-wizard-step>
 *
 * </dgx-wizard>
 * ```
 */
@Component({
  selector: 'dgx-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardComponent implements AfterContentInit {
  @ContentChildren(WizardStepComponent)
  wizardSteps!: QueryList<WizardStepComponent>;
  public stepLabelItems: TemplateRef<any>[] = [];
  public stepSymbolItems: TemplateRef<any>[] = [];
  /**
   * Index of the current active step
   * @type {number}
   */
  @Input() currentStepIndex: number = 0;
  /**
   * enable navigating to subsequent steps
   * @type {boolean}
   */
  @Input() navigateForward: boolean = true;
  /**
   * enable navigating to previous steps
   * @type {boolean}
   */
  @Input() navigateBackward: boolean = true;
  /**
   * indicator type between steps
   * @type {'arrow' | 'line'}
   */
  @Input() indicator: 'arrow' | 'line' = 'line';
  /*
   * Event emitter for change in steps
   */
  @Output() onStepToggle: EventEmitter<number> = new EventEmitter<number>();
  public sectionTitles: string[] = [];
  constructor(private cdr: ChangeDetectorRef) {}
  public ngAfterContentInit() {
    this.wizardSteps.forEach((step) => {
      this.stepLabelItems.push(step.wizardStepTitle);
      this.stepSymbolItems.push(step.wizardStepSymbol);
    });
    this.setActiveStep(this.currentStepIndex);
  }

  public setActiveStep(index: number) {
    this.wizardSteps.forEach((step, i) => {
      step.active = i === index;
    });
    this.cdr.markForCheck();
    this.onStepToggle.emit(index);
  }

  public goToNextStep() {
    if (
      this.currentStepIndex < this.wizardSteps.length - 1 &&
      this.navigateForward
    ) {
      this.setActiveStep(++this.currentStepIndex);
    }
  }

  public goToPreviousStep() {
    if (this.currentStepIndex > 0) {
      this.setActiveStep(--this.currentStepIndex);
    }
  }

  public get isLastStep() {
    return this.currentStepIndex === this.wizardSteps.length - 1;
  }

  public get isFirstStep() {
    return this.currentStepIndex === 0;
  }
  public isActive(index: number) {
    return index === this.currentStepIndex;
  }
  public onHeaderClick(index: number) {
    if (
      (index < this.currentStepIndex && this.navigateBackward) ||
      this.navigateForward
    ) {
      this.currentStepIndex = index;
      this.setActiveStep(index);
    }
  }
  public isClickable(index: number) {
    return (
      index !== this.currentStepIndex &&
      ((index < this.currentStepIndex && this.navigateBackward) ||
        this.navigateForward)
    );
  }
  public hasStepSymbol(index: number) {
    return this.stepSymbolItems[index];
  }
}
