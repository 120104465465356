export class TranslationHelper {
  /**
   * This will return the action type that can be used to select the correct translation strings
   * based on the contentType passed in.
   * @param contentType
   */
  public static getContentActionType(contentType: string) {
    switch (contentType.toLowerCase()) {
      case 'article':
      case 'book':
        return 'Read';
      case 'video':
        return 'Watched';
      case 'event':
        return 'Attended';
      case 'target':
      case 'plan':
      case 'pathway':
        return 'Followed';
      default:
        return 'Completed';
    }
  }
}
