import { SignalSummary } from '@app/profile/profile-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { SearchFacet, SearchFacetValue } from '@app/search';

export const toRegistry = (
  list: SignalSummary[]
): Record<number, SignalSummary> =>
  list.reduce((registry, current) => {
    registry[current.tagId] = current;
    return registry;
  }, {});

/**
 * Strict comparison of array contents
 */
export const isEqual = (previous: unknown[], current: unknown[]): boolean => {
  return (
    previous.length === current.length &&
    !!previous.reduce((acc, cur, idx) => acc && cur === current[idx], true)
  );
};

export const hasSkills = (skills: TagsApi.FocusableTagDetails): boolean => {
  return skills?.focus?.length > 0 || skills?.other?.length > 0;
};

/**
 * Get a cumulative count of a set of facet types from within a profiles set of facets
 *
 * @param allFacets - The set of CollectionFacet for a profile.
 * @param facetsToCount - The facet types to include in the count
 */
export const getFacetCount = (
  allFacets: SearchFacetValue[],
  facetsToCount: string[]
): number => allFacets.length
    ? allFacets.reduce((count, facet) => {
        if (facetsToCount.includes(facet.id)) {
          return count + facet.count;
        }
        return count;
      }, 0)
    : 0;

/**
 * Returns an array of id's for the number of empty tiles needing to be rendered for the last row of items
 *
 * @param numItems - the number of rendered items.
 * @param rowLimit - max number of empty states to render
 */
export const getEmptyTileCount = (numItems: number, rowLimit = 3): number[] => {
  // no empty tiles if the last row is full
  const isFullRow = !(numItems % rowLimit);
  if (isFullRow) {
    return [];
  }
  const count = rowLimit - (numItems % rowLimit);
  return [...Array(count).keys()];
};

export const getTotalCountFromFacets = (facets: SearchFacet[]): number => {
  const typeFacet = facets.find((f) => f.id === 'Type');
  return typeFacet.values.length
    ? typeFacet.values.reduce((count, value) => count + value.count, 0)
    : 0;
};
