import { Injectable, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { ResourceType } from '@app/shared/models/core-api.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  filter as _filter,
  identity as _identity,
  map as _map,
  pickBy as _pickBy,
} from 'lodash-es';
import {
  ParentItemViewModel,
  SimpleItemViewModel,
} from '@app/shared/models/core-view.model';
import {
  GroupFindModel,
  LearningResourceModel,
  OpportunitiesResults,
  PeopleModel,
  SearchFacet,
  SearchFacetValue,
  SearchFilterConfig,
  SearchFilterFilter,
  SearchSuggestion,
  SearchSuggestionSource,
  TagDetailResults,
} from '../search-api.model';
import { DatePipe, DecimalPipe } from '@angular/common';
import {
  AnyLearningResource,
  LearningResourceViewModel,
} from '@app/inputs/models/learning-resource.view-model';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { OrganizationModel } from '@app/orgs/services/orgs.model';
import { VideoService } from '@app/inputs/services/video.service';
import { SearchFlagsService } from './search-flags.service';
import { AppliedSearchFacet } from '../components/search-view/search-reactive-store/models/search.model';
import { SearchFilterId } from '../components/search-filters/search-filters.component';
import { AuthService, LDFlagsService } from '@dg/shared-services';
import {
  facetsToFilters as _facetsToFilters,
  patchFacetConfig as _patchFacetConfig,
} from '@app/search/services/utils';
import { ResourceCategory } from '@app/inputs/inputs.enums';

export interface SearchData {
  total?: number;
  results?: LearningResourceViewModel[];
  term?: string;
  hasMoreItems?: boolean;
  filters?: ParentItemViewModel<
    SearchFacet,
    SimpleItemViewModel<SearchFacetValue>
  >[];
}

export interface SearchParams {
  terms: string;
  facets: string;
  count: number;
  skip: number;
  boostRecent: boolean;
  boostPopular: boolean;
  resourceCategory: ResourceCategory;
  useResourceImages: boolean;
  exclusionList: string;
  persistFilter: boolean;

  includesProviders: boolean; // ??
}

export interface SearchExclusion {
  resourceId: number;
  resourceType: ResourceType;
}

export interface RelatedSkill {
  score: number;
  term: string;
}

@Injectable({ providedIn: 'root' })
export class SearchService {
  // Local
  public readonly notSpecified = 'Core_NotSpecified';

  public i18n: Record<string, string> = this.translateService.instant([
    'LearningSearch_RangeCount',
    'LearningSearch_RangeCountPlus',
    'LearningSearch_ResultSetRangeCount',
    'LearningSearch_ResultSetRangeCountPlus',
  ]);

  constructor(
    private http: NgxHttpClient,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private markdownService: MarkdownService,
    private sanitizer: DomSanitizer,
    private displayTypePipe: DisplayTypePipe,
    private datePipe: DatePipe,
    private webEnvironmentService: WebEnvironmentService,
    private videoService: VideoService,
    private searchFlagsService: SearchFlagsService,
    private translateService: TranslateService,
    private decimalPipe: DecimalPipe,
    private ngZone: NgZone,
    private authService: AuthService,
    private ldFlagsService: LDFlagsService
  ) {}

  public get externalCatalog() {
    return {
      name: this.translateService.instant('Core_External'),
    } as OrganizationModel;
  }

  public get marketplaceCatalog() {
    return {
      name: this.translateService.instant('Core_Marketplace'),
      organizationId: -2,
    } as OrganizationModel;
  }

  /**
   * Coerce facets into filters for use with our search filters.
   *
   * The util function facetsToFilters() used to be on this service but needed to be extracted. This is a wrapper around the util function
   * so that calls to facetsToFilters from outside the searchService will still work without requiring changes.
   *
   * @param facets - An array of facets received from the back-end.
   * @param config - An optional array of config objects. Will ultimately be `SearchFilterConfig`
   * type, after buildFilterConfig has been called.
   */
  public facetsToFilters(
    facets: SearchFacet[],
    config: any[] = []
  ): SearchFilterFilter[] {
    return _facetsToFilters(
      facets,
      this.notSpecified,
      this.translateService.instant(this.notSpecified),
      config
    );
  }

  /**
   * Create/update search filter configs
   *
   * The util function facetsToFilters() used to be on this service but needed to be extracted.  This is a wrapper around the util function
   * so that calls to patchFacetConfig from outside the searchService will still work without requiring changes.
   *
   * @param facetId id of facet you want to create/update a config for
   * @param facets all facets
   * @param configPatch patch object
   * @param configs optionally provide existing config array to patch
   * @returns config array
   */
  public patchFacetConfig(
    facetId: SearchFilterId,
    facets: SearchFacet[],
    configPatch: Partial<SearchFilterConfig>,
    configs: Partial<SearchFilterConfig>[] = []
  ): Partial<SearchFilterConfig>[] {
    return _patchFacetConfig(facetId, facets, configPatch, configs);
  }

  /**
   * Coerce filters back into facets for the back-end search.
   *
   * @param filters - An array of filters coerced from facets by `facetsToFilters`.
   */
  public filtersToFacets(filters: SearchFilterFilter[]) {
    return _filter(
      _map(filters, (filter: SearchFilterFilter) => ({
        id: filter.model.id,
        name: filter.model.name,
        values: _map(
          // shorthand for subitem.isSelected == TRUE
          _filter(filter.subitems, 'isSelected'),
          (value: SimpleItemViewModel<SearchFacetValue>) =>
            // Turn our not-specified results back into an empty string
            value.model.id === this.notSpecified ? '' : value.model.id
        ),
      })),
      'values.length'
    );
  }

  /**
   * For the given search filters return the selected facets
   *
   * @param filters
   * @returns
   */
  public getAppliedFilters(
    filters: SearchFilterFilter[]
  ): AppliedSearchFacet[] {
    return filters
      .filter(this.hasSelectedFacets)
      .reduce((allFilters, filter) => {
        if (filter?.subitems[0]?.model?.config?.isRangeFilter) {
          // range filter values come from user input, so we can't use the static 'id' for the dynamic selected values,
          // these are put on model.name
          for (const item of filter.subitems) {
            if (item.model.name) {
              allFilters.push({
                id: item.model.id,
                name: item.model.id,
                values: [item.model.name],
              });
            }
          }
        } else {
          const values = this.getSelectedFacetValues(filter);
          allFilters.push({
            id: filter.id as string,
            name: filter.id as string,
            values,
          });
        }
        return allFilters;
      }, []);
  }

  public search(
    term: string = '',
    facets = [],
    count: number,
    skip: number = 0,
    organizationId?: number,
    boostRecent?: boolean,
    boostPopular?: boolean,
    resourceCategory?: ResourceCategory,
    exclusionList?: SearchExclusion[],
    persistFilter?: boolean
  ) {
    if (organizationId) {
      const internalFacet = {
        id: 'Internal',
        name: 'Internal',
        values: [organizationId],
      };
      facets = [...facets, internalFacet];
    }

    const params: SearchParams = {
      skip,
      terms: term,
      count,
      facets: JSON.stringify(facets),
      includesProviders: true,
      boostRecent,
      boostPopular,
      resourceCategory,
      useResourceImages: true,
      exclusionList: JSON.stringify(exclusionList),
      persistFilter,
    };

    return this.http
      .get<LearningResourceModel>('/search/findlearningresources', {
        params,
        forceUriEncoding: true,
      })
      .pipe(
        map((response) => {
          // Always display all `Type` subitems, otherwise bookmarked searches
          // and page reloads won't display any subitems that aren't pre-selected
          const configs = this.patchFacetConfig(
            SearchFilterId.Type,
            response.facets,
            { showEmptyOptions: true }
          );
          const filters = this.facetsToFilters(response.facets, configs);
          const results = response.results.map(
            (result) =>
              new LearningResourceViewModel(
                result.reference as AnyLearningResource,
                this.displayTypePipe,
                this.htmlToPlaintextPipe,
                this.markdownService,
                this.sanitizer,
                'Search',
                this.translateService,
                this.datePipe,
                this.videoService,
                this.ldFlagsService
              )
          );
          return {
            ...response,
            term,
            results,
            filters,
          };
        })
      );
  }

  public spellcheck(term: string): Observable<string> {
    return this.http
      .get<string>('/search/spellcheck', {
        params: { term },
        forceUriEncoding: true,
      })
      .pipe(catchError(() => of(null)));
  }

  public opportunitiesSearch(
    term: string,
    organizationId?: number
  ): Observable<OpportunitiesResults> {
    const params = {
      term,
      organizationId,
      count: 5,
    };
    return this.http
      .get<any>('/opportunities/orgopportunities', {
        params,
        forceUriEncoding: true,
      })
      .pipe(
        map(
          (response) =>
            ({
              ...response,
              filters: this.facetsToFilters(response.facets),
            }) as OpportunitiesResults
        ),
        catchError(() => of(null))
      );
  }

  public opportunitiesSearchFull(
    term: string = '',
    filters = [],
    numResultsPerPage: number,
    numResultsToSkip: number = 0,
    organizationId?: number
  ): Observable<OpportunitiesResults> {
    // Only retrieve open opportunities
    filters.push({ id: 'Status', name: 'Status', values: ['open'] });

    const params = {
      term,
      organizationId,
      count: numResultsPerPage,
      skip: numResultsToSkip,
      facets: JSON.stringify(filters),
    };
    return this.http
      .get<any>('/opportunities/orgopportunities', {
        params,
        forceUriEncoding: true,
      })
      .pipe(
        map(
          (response) =>
            ({
              ...response,
              // More filters come back from the server than we currently want to display
              // update filters and only show desired filters
              filters: this.facetsToFilters(
                response.facets.filter(
                  (filter) => filter.id.toString().toLowerCase() !== 'status'
                )
              ),
            }) as OpportunitiesResults
        )
      );
  }

  public peopleSearch(
    term: string = '',
    organizationId: number
  ): Observable<PeopleModel> {
    const params = {
      term,
      count: 5,
      skip: 0,
      organizationId,
    };
    return this.http
      .get('/users/findUsers', { params, forceUriEncoding: true })
      .pipe(catchError(() => of(null))) as Observable<PeopleModel>;
  }

  public peopleSearchFull(
    term: string = '',
    organizationId: number,
    numResultsPerPage: number,
    numResultsToSkip: number = 0,
    filters = []
  ): Observable<PeopleModel> {
    const params = {
      term,
      count: numResultsPerPage,
      skip: numResultsToSkip,
      organizationId,
      facets: JSON.stringify(filters),
    };
    return this.http
      .get<PeopleModel>('/users/findUsers', { params, forceUriEncoding: true })
      .pipe(
        map(
          (response) =>
            ({
              ...response,
              filters: this.facetsToFilters(response.facets),
            }) as PeopleModel
        )
      );
  }

  public skillsSearch(term: string = ''): Observable<TagDetailResults> {
    const params = {
      tagType: 'Skill',
      term: term || '',
      includeUserRatings: true,
      count: 5,
      skip: 0,
    };
    return this.http
      .get('/tag/findTags', {
        params,
        forceUriEncoding: true,
      })
      .pipe(catchError(() => of(null))) as Observable<TagDetailResults>;
  }

  public skillsSearchFull(
    term: string = '',
    numResultsPerPage: number,
    numResultsToSkip: number = 0,
    filters = []
  ): Observable<TagDetailResults> {
    const params = {
      tagType: 'Skill',
      term,
      includeUserRatings: true,
      count: numResultsPerPage,
      skip: numResultsToSkip,
      facets: JSON.stringify(filters),
    };

    return this.http
      .get<TagDetailResults>('/tag/findTags', {
        params,
        forceUriEncoding: true,
      })
      .pipe(
        map(
          (response) =>
            ({
              ...response,
              filters: this.facetsToFilters(response.facets),
            }) as TagDetailResults
        )
      );
  }

  public groupsSearch(
    term: string = '',
    organizationId: number
  ): Observable<GroupFindModel> {
    const params = {
      term,
      skip: 0,
      count: 5,
      organizationId,
    };
    return this.http
      .get('/groups/findgroups', {
        params,
        forceUriEncoding: true,
      })
      .pipe(catchError(() => of(null))) as Observable<GroupFindModel>;
  }

  public groupsSearchFull(
    term: string = '',
    numResultsPerPage: number,
    numResultsToSkip: number = 0,
    organizationId: number
  ): Observable<GroupFindModel> {
    const params = {
      term,
      skip: numResultsToSkip,
      count: numResultsPerPage,
      organizationId,
    };
    return this.http.get('/groups/findgroups', {
      params,
      forceUriEncoding: true,
    });
  }

  public getSuggestions(
    term: string,
    count: number,
    source: SearchSuggestionSource
  ): Observable<any | SearchSuggestion[]> {
    return this.http.get('/search/getsuggestions', {
      params: {
        term,
        count,
        source,
      },
      forceUriEncoding: true,
    }); // TODO: Add global default error handler via interceptor, which displays error in a toast
  }

  ///// POC Natural Language Catalog Search
  public getNaturalLanguageSearchResult(
    query: string
  ): Observable<any | SearchSuggestion[]> {
    if (query === 'test') {
      return of({
        queryResponse: 'This is a test result',
        facets: [{ id: 'Internal', name: 'Internal', values: [1] }],
        references: [{ url: 'https://degreed.com', title: 'Degreed Website' }],
      });
    }

    return this.http.get('/search/naturalLanguageQueryResources', {
      params: {
        query,
      },
      forceUriEncoding: true,
    }); // TODO: Add global default error handler via interceptor, which displays error in a toast
  }

  ///// POC Natural Language Catalog Search
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private eventSource: EventSource;
  public streamNaturalLanguageSearchResult(query: string): Observable<string> {
    this.eventSource = new EventSource(
      '/api/search/naturalLanguageQueryResourcesStream?query=' + query
    );

    return new Observable((observer) => {
      this.eventSource.onmessage = (event) => {
        this.ngZone.run(() => {
          observer.next(event.data);
        });
      };
      this.eventSource.onerror = (error) => {
        observer.next('___END___');
        observer.complete();
        this.eventSource.close();
      };
    });
  }

  public getRelatedTerms(term: string): Observable<RelatedSkill[]> {
    const params = {
      term,
    };
    return this.http.get('/terms/related', { params, forceUriEncoding: true });
  }

  public verifyPageNum(pageNumber) {
    let pageNumData: {
      valid: boolean;
      number: number;
    };

    // Read page number from query string, default to 1 if undefined or not a number
    // (ngb can let a string be passed)
    if (pageNumber && !!Number(pageNumber)) {
      pageNumData = { number: Number(pageNumber), valid: true };
    } else if (pageNumber) {
      // Page number exists, but was not a valid number
      pageNumData = { number: 1, valid: false };
    }

    return pageNumData;
  }

  public getEmptyImageUrl(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/emptystate/empty-search-results.svg',
      true
    );
  }

  public getEmptyFilteredImageUrl(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/emptystate/profile-settings.svg',
      true
    );
  }

  public getFiltersFromParam(filterRouteParam: string): any[] {
    if (!filterRouteParam) {
      return [];
    }

    const filterGroupDelimiter = '|';

    // do we have multiple filter groups with selected items?
    if (filterRouteParam.indexOf(filterGroupDelimiter) !== -1) {
      // split string on | delimiter and iterate through to obtain group ID and selected items
      return filterRouteParam
        .split(filterGroupDelimiter)
        .map((group) => this.getFacetFromParamFrag(group));
    }

    // single filter group
    return [this.getFacetFromParamFrag(filterRouteParam)];
  }

  /**
   * Convert facet object to query param filter string
   *
   * Note that values are encoded
   *
   * @example
   * getParamFragFromFacet({
   *   id: 'Location',
   *   name: 'Location',
   *   values: ['foo', 'bar;|:']
   * })
   * // returns
   * `Location:foo|bar%3B%7C%3A`
   */
  public getParamFragFromFacet(facet: any): string {
    if (!facet) {
      return null;
    }

    const values = facet.values.map(encodeURIComponent).join(';');

    return `${facet.id}:${values}`;
  }

  public getCatalogs(
    organizations: OrganizationModel[],
    showExternalCatalog: boolean,
    showMarketplaceCatalog: boolean = false
  ): OrganizationModel[] {
    let catalogs = [];

    if (organizations?.length > 0) {
      catalogs = [...organizations];
      if (showExternalCatalog) {
        catalogs = [...catalogs, this.externalCatalog];
      }
      if (
        (showMarketplaceCatalog &&
          !this.authService.authUser.disableDegreedMarketplace) ||
        this.ldFlagsService.isPaidCMUser
      ) {
        catalogs = [...catalogs, this.marketplaceCatalog];
      }
    }
    return catalogs;
  }

  public getSearchResltsRange(
    rangeStart: number,
    rangeEnd: number,
    totalContentResults: number
  ) {
    let i18n =
      totalContentResults >= 10000
        ? 'LearningSearch_RangeCountPlus'
        : 'LearningSearch_RangeCount';

    // localize number so we have the correct separator
    const totalNumResults = this.decimalPipe.transform(totalContentResults);

    return this.translateService.instant(i18n, {
      rangeStart: rangeStart,
      rangeEnd: rangeEnd,
      totalNumResults,
    });
  }

  private hasSelectedFacets(filter: SearchFilterFilter): boolean {
    return filter.subitems.filter(({ isSelected }) => isSelected).length > 0;
  }

  private getSelectedFacetValues(
    filter: SearchFilterFilter
  ): string[] | number[] {
    return filter.subitems
      .filter(({ isSelected }) => isSelected)
      .map((item) => item.model.id);
  }

  /**
   * Convert query param filter string to a facet object
   *
   * Note that values are decoded
   *
   * @example
   * getFacetFromParamFrag(`Location:foo|bar%3B%7C%3A`)
   * // returns
   * {
   *   id: 'Location',
   *   name: 'Location',
   *   values: ['foo', 'bar;|:']
   * }
   */
  private getFacetFromParamFrag(filterGroup: string): any {
    const [id, values] = filterGroup.split(':');

    return {
      id,
      name: id,
      values: values.split(';').map(decodeURIComponent),
    };
  }
}
