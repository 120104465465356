import { Injectable, TemplateRef } from '@angular/core';

import {
  DfFormFieldBuilder,
  DfFieldTemplateContext,
  DfFormFieldConfig,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

import { TagsFieldComponent } from '@app/form-fields/wrappers/tags-field/tags-field.component';
import { TagsApi } from '@app/tags/tag-api.model';
import { RenderMode } from '../user-input.model';
import {
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { RendererContext } from '../form-renderer.model';

/** Provides methods for building common Input form fields */
@Injectable({ providedIn: 'root' })
export class InputCommonFieldBuilder {
  constructor(
    private translate: TranslateService,
    private builder: DfFormFieldBuilder
  ) {}

  public buildCreatedByField(
    readonlyTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    return this.builder
      .customField('createdByName', '', readonlyTemplate)
      .unwrapped()
      .build();
  }

  /** Builds a skills editor field configuration, optionally showing a set of top tags
   * to select from.
   */
  public buildSkillsField(
    topTags: TagsApi.Tag[],
    hideTopTags: boolean,
    hiddenWhen: boolean = false
  ): DfFormFieldConfig {
    return this.builder
      .foreignField(
        'tags',
        'Core_Skills',
        TagsFieldComponent.REGISTERED_FIELD_TYPE,
        {
          topTags,
          hideTopTags,
        }
      )
      .hiddenWhen(() => hiddenWhen)
      .build();
  }

  /** Builds a user groups field, only for use with consumer (i.e., non org) users and currently only for
   * media input types */
  public buildMediaConsumerGroupsField(
    isChannel: boolean,
    isCompleting: boolean,
    orgId: number,
    groupsTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.builder
      .customField('groupIds', 'MediaFormCtrl_AddGroups', groupsTemplate, {
        tipText: this.translate.instant('MediaFormCtrl_AddingGroupsInfo'),
      })
      .unwrapped() // The groups editor has its own label and doesn't require validation, so present it sans fresco's default field wrapper
      .hiddenWhen(() => isChannel || isCompleting || orgId > 0)
      .build();
  }

  /** Builds an Add to Catalog checkbox field  */
  public buildPathwaysAddToCatalogField(
    renderMode: RenderMode,
    canManageContent: boolean,
    orgName: string,
    labelFormatKey: string,
    helpTemplate: TemplateRef<DfFieldTemplateContext>,
    changeHandler?: (shouldAdd: boolean) => void,
    addToCatalog?: boolean // has content already been added to catalog?
  ): DfFormFieldConfig {
    // only create field when adding in a pathway and user has manage content permission
    let builder = this.builder
      .checkbox(
        'addToCatalog',
        this.translate.instant(labelFormatKey, {
          orgName,
        })
      )
      .withHelp(helpTemplate)
      .withDgatId('eventForm-f02-5d4') // weird but matches original forms
      .hiddenWhen(
        () =>
          !(
            renderMode === RenderMode.Pathways &&
            canManageContent &&
            !addToCatalog
          )
      );

    if (changeHandler) {
      builder = builder.onControlValueChanges((v: boolean) => changeHandler(v));
    }
    return builder.build();
  }

  public buildDurationFields(
    isFlagTurnedOn: boolean,
    formType: string,
    context?: RendererContext,
    isGlobalAdd?: boolean
  ): DfFormFieldConfig {
    const minZeroError = this.translate.instant('Core_InvalidMinValue', {
      min: 0,
    });
    const minOneError = this.translate.instant('Core_InvalidMinValue', {
      min: 1,
    });
    const durationMinutesValidator: ValidatorFn = (
      control: AbstractControl
    ): ValidationErrors => {
      let minValidationError = null;
      if (
        parseInt(control.value) <= 0 &&
        (!parseInt(control.parent.get('durationHours').value) ||
          parseInt(control.parent.get('durationHours').value) < 0)
      ) {
        minValidationError = { minOneError };
      } else if (parseInt(control.value) < 0) {
        minValidationError = { minZeroError };
      }
      return minValidationError;
    };

    if (isGlobalAdd || (!isFlagTurnedOn && formType === 'Course')) {
      return this.builder
        .fieldGroup(
          '',
          [
            this.builder
              .optionalTextInput('units', 'Core_Hours')
              .readonlyWhen(
                () =>
                  context.inputContext.renderMode === RenderMode.UserProfile &&
                  (context.state().input?.externalId ||
                    context.state().input?.institutionId ||
                    context.state().input?.providerId ||
                    context.inputContext.isEditing)
              )
              .hiddenWhen(() => context.state().input?.unitType === 'Minutes')
              .build(),
            this.builder
              .optionalTextInput('units', 'Core_Minutes')
              .readonlyWhen(
                () =>
                  context.inputContext.renderMode === RenderMode.UserProfile &&
                  (context.state().input?.externalId ||
                    context.state().input?.institutionId ||
                    context.state().input?.providerId ||
                    context.inputContext.isEditing)
              )
              // If this logic is ever updated so that the old duration's minute field actually displays,
              // the unitType property inside of the extendedDefaultViewModel getter (inside of course-facade.ts) will need to be updated to be conditional
              .hiddenWhen(
                () =>
                  context.state().input?.unitType === 'Hours' ||
                  !context.state().input?.unitType
              )
              .build(),
          ],
          'df-form__row'
        )
        .build();
    } else {
      return this.builder
        .fieldGroup(
          '',
          [
            this.builder
              .optionalTextInput('durationHours', 'Core_Hours')
              .ofType('number')
              .styledBy('df-form__col-half')
              .withDgatId(`${formType}Form-durHours`)
              .asOptional()
              .updatedOn('change')
              .onBlur((event, model, formControl: FormControl) => {
                formControl.parent
                  .get('durationMinutes')
                  .updateValueAndValidity();
              })
              .onKeyup((event, model, formControl: FormControl) => {
                formControl.parent
                  .get('durationMinutes')
                  .updateValueAndValidity();
              })
              .onKeydown((event, model, formControl) => {
                if (event.key === '-') {
                  event.preventDefault();
                }
              })
              .validatedBy((control: AbstractControl) =>
                parseInt(control.value) < 0 ? { minZeroError } : null
              )
              .withErrorMessages({ minZeroError })
              .hiddenWhen(() => !isFlagTurnedOn)
              .withStep(1)

              .build(),
            this.builder
              .optionalTextInput('durationMinutes', 'Core_Minutes')
              .ofType('number')
              .styledBy('df-form__col-half')
              .withDgatId(`${formType}Form-durMinutes`)
              .asOptional()
              .onKeydown((event, model, formControl) => {
                if (event.key === '-') {
                  event.preventDefault();
                }
              })
              .updatedOn('change')
              .validatedBy(durationMinutesValidator)
              .withErrorMessages({ minOneError, minZeroError })
              .hiddenWhen(() => !isFlagTurnedOn)
              .withStep(1)
              .build(),
          ],
          'df-form__row'
        )
        .build();
    }
  }
}
