import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TranslateService } from '@ngx-translate/core';
import { CertificateMappingToAPI } from '../certificate.model';
import { ActionFeedback } from '@app/inputs/inputs-api.model';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';

@Injectable()
export class CertificateService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public addCertificate(params: CertificateMappingToAPI): Promise<void> {
    const outcome = {
      ...params,
      details: params.details ? JSON.stringify(params.details) : undefined,
    };
    const request$ = this.post<void>('/useroutcomes/add', {
      ...outcome,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public updateCertificate(params: CertificateMappingToAPI): Promise<void> {
    const outcome = {
      ...params,
      details: params.details ? JSON.stringify(params.details) : undefined,
    };
    const request$ = this.put<void>('/useroutcomes/update', {
      ...outcome,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }
}
