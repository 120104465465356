import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents an Assigned Learning completion notification */
@Component({
  selector: 'dgx-completed-assigned-learning',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedAssignedLearningComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('completedAssignedLearning_Format', {
      nameHtml: this.userFullNameTemplate,
    });
  }
}
