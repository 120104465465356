import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { LearnerHomeFocusSkill } from '@app/learner-home/learner-home-api.model';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { Skill } from '@app/opportunities/opportunities-api.model';

/**
 * For use when you want to display a clickable tag (skill) pill
 * with a hover- or focus-activated popover containing ratings, if any.
 *
 * @example
 * ```
 * // on the learner home focus skills
 * <dgx-tag-pill
 *   *ngFor="let skill of focusSkills"
 *   [showCompleteOnly]="true"
 *   [tag]="skill"
 *   (tagClick)="openSkillRatingModal($event, skill)"
 *   (ratingsViewed)="onSkillRatingPopoverViewed(skill)"
 *   dgatInput="learner-home-focus-skills-f49"
 *   data-dgat="learner-home-focus-skills-f5b"
 *   pillClasses="focus-skill"
 *   triggers="hover"
 * ></dgx-tag-pill>
 * // on the opportunity overview tab
 * <dgx-tag-pill
 *   *ngFor="let skill of matchedSkills"
 *   [tag]="skill"
 *   (tagClick)="onSkillPillClick($event, skill)"
 *   (ratingsViewed)="onSkillRatingPopoverViewed(skill)"
 *   dgatInput="opportunity-view-skills-a123"
 *   data-dgat="opportunity-view-skills-a1f"
 *   triggers="focus hover"
 * ></dgx-tag-pill>
 * ```
 */
@Component({
  selector: 'dgx-tag-pill',
  templateUrl: './tag-pill.component.html',
  styleUrls: ['./tag-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagPillComponent implements OnInit {
  // input bindings
  /**
   * How the popover should be attached to the document. Passed to ngbTooltip.
   * See: https://ng-bootstrap.github.io/#/components/tooltip/api
   */
  @Input() public container = 'body';
  /** Dgat value to be applied to the popover trigger button. */
  @Input() public dgatInput = 'tag-pill-b3a';
  /** Whether to completely disable the tooltip. */
  @Input() public disableTooltip = false;
  /** Whether the current user is the owner of this tag. */
  @Input() public isOwner = false;
  /** Additional classes to be applied to the popover trigger pill. */
  @Input() public pillClasses = '';
  /**
   * Where the popover should be positioned relative to the tirgger. Passed
   * to ngbTooltip.
   * See: https://ng-bootstrap.github.io/#/components/tooltip/api
   */
  @Input() public placement = 'bottom';
  /** Whether to show only completed ratings or to show all ratings. */
  @Input() public showCompleteOnly = false;
  /** The tag, which may be a LearnerHomeFocusSkill or a normal Tag. */
  @Input() public tag: TagsApi.Tag | LearnerHomeFocusSkill | Skill;
  /**
   * How the popover should be triggered. Passed to ngbTooltip.
   * See: https://ng-bootstrap.github.io/#/components/tooltip/api
   */
  @Input() public triggers = 'hover focus';

  // output bindings
  /** Emitted when the tooltip finishes animating open. */
  @Output() public ratingsViewed = new EventEmitter<void>();
  /** Emitted when the tooltip finishes animating closed. */
  @Output() public ratingsHidden = new EventEmitter<void>();
  /** Emitted when the tag pill is clicked. */
  @Output() public tagClick = new EventEmitter<Event>();

  // local bindings
  public authUser: AuthUser;
  public ratings: TagsApi.UserTagRating[];

  constructor(private authService: AuthService) {}

  public ngOnInit() {
    this.authUser = this.authService.authUser;
    this.ratings = this.getRatings(this.tag);
  }

  /**
   * When the tag pill is clicked.
   */
  public onTagClick(event: Event): void {
    this.tagClick.emit(event);
  }

  /**
   * When the tooltip closing animation completes.
   */
  public onTooltipHidden(): void {
    this.ratingsHidden.emit();
  }

  /**
   * When the tooltip opening animation completes.
   */
  public onTooltipShown(): void {
    this.ratingsViewed.emit();
  }

  /**
   * Get the ratings from the tag object.
   *
   * @param tag - Either a normal tag (skill) or a LearnerHomeFocusSkill.
   */
  private getRatings(tag: TagsApi.Tag | LearnerHomeFocusSkill | Skill) {
    return (
      (this.showCompleteOnly
        ? (tag as LearnerHomeFocusSkill)?.completedRatings
        : tag?.ratings) || []
    );
  }
}
