import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
// Angular / third-party
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Types
import { AuthUser } from '@app/account/account-api.model';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { OpportunityCardModel } from './opportunities-card-api.model';

// Services and Pipes
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { OpportunityFlagsService } from '@app/opportunities/services/opportunity-flags.service';
import { OpportunityModalsService } from '@app/opportunities/services/opportunity-modals.service';
import { OpportunityPermissionsService } from '@app/opportunities/services/opportunity-permissions.service';
import { OpportunityViewService } from '@app/opportunities/services/opportunity-view.service';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';

// Utils
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { camelCaseKeys } from '@app/shared/utils/property';
import { OpportunityTrackingService } from '@app/opportunities/services/opportunity-tracking.service';
import { OpportunityMenuService } from '@app/opportunities/services/opportunity-menu.service';
import { OpportunityMenuTypeEnum } from '@app/opportunities/opportunity-menu.model';
import { filter } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';

@Component({
  selector: 'dgx-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityCardComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  // Bindings
  @Input('opportunity') public pascalOpportunity: Opportunity;
  @Input() public canSelect = false;
  /** Override the calculated description length with short, medium, or long. */
  @Input() public descriptionLength?: 'short' | 'medium' | 'long';
  @Input() public isBrowsePreviewView = false;
  @Input() public isSelected = false;
  /** Whether the current opportunity is being previewed via the browse preview pane. */
  @Input() public isViewing = false;
  @Input() public openInNewtab = false; // TODO: Delete with useNewOpportunityBrowse flag.
  // Only used by old opportunity card view
  @Input() public showCollaboratorMenu = false; // TODO: Delete with useNewOpportunityBrowse flag.
  @Input() public showCheckboxes = false;

  @Output() public dismiss = new EventEmitter<HTMLElement>();
  @Output() public selectOpportunity = new EventEmitter<boolean>();
  @Output() public viewOpportunity = new EventEmitter<void>();

  // Local
  public authUser: AuthUser;
  // TODO: Straighten this out. We shouldn't need two models for the card.
  public cardModel: OpportunityCardModel;
  public descriptionLimit = 170;
  public descriptionMaxLines = 5;
  public i18n = this.translateService.instant([
    'Core_MoreOptions',
    'Core_Share',
    'Opportunities_ViewOpportunity',
    'Opportunities_Viewing',
    'Opportunities_Success_AddExperience',
  ]);
  public isUserSelected = false;
  public menuConfig: MenuViewModel[];
  public metaLimit = 40;
  public opportunity: Opportunity;
  public showCheckbox = false;
  public useSmarterMatching = this.opportunityFlagsService.useSmarterMatching;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private markdownService: MarkdownService,
    private opportunityFlagsService: OpportunityFlagsService,
    private opportunityMenuService: OpportunityMenuService,
    private opportunityModalsService: OpportunityModalsService,
    private opportunityPermissionsService: OpportunityPermissionsService,
    private opportunityTrackingService: OpportunityTrackingService,
    private opportunityViewService: OpportunityViewService,
    private translateService: TranslateService,
    private webEnvironmentService: WebEnvironmentService,
    private windowLayoutService: WindowLayoutService
  ) {
    super();
  }

  /**
   * Shell for the service method to make it available to the template.
   */
  public get canShare(): boolean {
    return this.opportunityPermissionsService.canShare(this.opportunity);
  }

  /**
   * Whether or not to show the Match badge on the card.
   */
  public get showMatchBadge(): boolean {
    return !(
      // if using smarter matching AND location is Browse Top/Learner Home
      (
        this.useSmarterMatching &&
        this.webEnvironmentService.analyticsAppLocation === 'Learner Home'
      )
    );
  }

  public ngOnInit(): void {
    this.authUser = this.authService.authUser;
    // set opportunity card, etc.
    // TODO: Get rid of this, it shouldn't be necessary.
    this.initOpportunityDetails(this.pascalOpportunity);
    // track that this card was shown.
    this.opportunityTrackingService.trackOpportunity(
      this.opportunity,
      'Opportunity Impressed'
    );

    // if extension, bookmarklet, or msteams
    this.openInNewtab ? !!this.openInNewtab : this.windowLayoutService.isIframe;
    // Listen for updates to the opportunity.
    // TODO: Ideally this should be handled in the card-view component, where we
    // would also ideally have a separate component; OpportunityCard and OpportunityBrowseTile
    // could extend from a shared base, therefore eliminating the need for replicating any shared
    // modal functionality, without also having a bunch of logic bootstrapped to both implementations
    // that is only used by one or the other.
    this.opportunityViewService.updatedOpportunity$
      .pipe(
        filter(
          ({ opportunityId }) =>
            opportunityId === this.opportunity.opportunityId
        ),
        tap((editedOpportunity) => {
          this.initOpportunityDetails(editedOpportunity);
        }),
        this.takeUntilDestroyed()
      )
      .subscribe();
  }

  /**
   * Adapts an Opportunity to a OpportunityCardModel for display purposes
   *
   * @param opportunity The opportunity to be adapted.
   */
  public adaptToCard(opportunity: Opportunity): OpportunityCardModel {
    // TODO: Be consistent on backend models so we don't need to normalize here.
    const skills = opportunity.tags || opportunity.skills || [];
    const type =
      this.opportunityViewService.getDisplayOpportunityTypes(opportunity);

    // construct meta from type and optionally locationName
    let meta = [
      {
        icon: 'opportunity',
        label: type,
      },
    ];
    if (opportunity.locationName) {
      meta = [
        ...meta,
        ...[
          {
            icon: 'pin',
            label: opportunity.locationName,
          },
        ],
      ];
    }

    // If we have matchingSkills available from the back-end, just use that.
    // The only place where `matchingSkills` is not returned from the BE is
    // calls to `orgopportunities`, which was expected to use smarter matching
    // only. TODO: Update the BE and remove this method, as it currently returns
    // a value based on the *cached* authUser, which can lead to inaccurate counts.
    // TODO: Will need another http call to get matchingSkills,
    // it will not be included in the `orgopportunities` endpoint
    opportunity.matchingSkills = this.getMatchingSkillsCount(skills);

    return {
      title: opportunity.title,
      meta,
      // strip both HTML *and* Markdown from the description, only when having description
      description:
        opportunity.description &&
        this.markdownService.markdownToPlaintext(opportunity.description),
      link: opportunity.url,
      matchedSkillsScore: opportunity.matchedSkillsScore,
      skillMatchData: {
        total: skills.length,
        matched: opportunity.matchingSkills,
      },
      opportunityId: opportunity.opportunityId,
    };
  }

  /**
   * Tooltip for published date, if shown.
   *
   * @param date - Date in UTC format.
   */
  public getDateTooltip(date: string): string {
    return this.translateService.instant('Core_PublishedXTimeAgoFormat', {
      elapsedTime: this.datePipe.transform(date, 'shortDate'),
    });
  }

  /**
   * The number of characters of the description to show.
   */
  public getDescriptionLength(opportunity: Opportunity): number {
    switch (this.getDescriptionLengthType(opportunity)) {
      // shortest possible description length
      case 1:
        return 100;
      // medium description length
      case 2:
        return 160;
      case 3:
        return 200;
      // longest description length
      default:
        return 300;
    }
  }

  /**
   * Re-initialize an opportunity.
   */
  public initOpportunityDetails(opportunity: any): void {
    // camel case opportunity for global usage
    this.opportunity = camelCaseKeys(opportunity);
    // TODO: This has been broken for a while, as it was checking `isSelected`,
    // which was not being set. Fixing it creates an ovular button on the card,
    // which probably isn't what we want. ADDITIONALLY: The home feed doesn't
    // return any of the fields that we would check with `isUserSelected`.
    // determine whether user is selected
    // this.isUserSelected = isUserSelected(this.opportunity);

    this.descriptionLimit = this.getDescriptionLength(this.opportunity);
    // BE sometimes gives us providerId, provideName and providerCode instead of provider object
    // TODO: Remove this once the BE return is consistent.
    this.opportunity.provider =
      this.opportunity.provider ?? this.opportunity.providerId
        ? {
            id: this.opportunity.providerId,
            name: this.opportunity.providerName,
            code: this.opportunity.providerCode,
          }
        : undefined;
    this.cardModel = this.adaptToCard(this.opportunity);
    // Update menu options appropriately (such as hiding close option on closed opportunity)
    this.setUpMenuConfig();
    this.cdr.markForCheck();
  }

  /**
   * Open a modal to add the opportunity to the user's experience.
   */
  public showAddExperienceModal(event: MouseEvent): void {
    this.opportunityModalsService
      .showAddExperienceModal({
        opportunity: this.opportunity,
        authUser: this.authUser,
        sourceTarget: event.target as HTMLElement,
        // Tracking is done on the service.
        trackingAction: 'Opportunity Experience Added',
      })
      .subscribe(({ masteryPoints }) => {
        this.opportunity.masteryPoints = masteryPoints;
      });
  }

  /**
   * Open a share modal to let the user share an opportunity.
   */
  public showShareModal(element?: HTMLElement): void {
    // show modal
    this.opportunityModalsService
      .showShareModal(this.opportunity, element, this.useSmarterMatching)
      .subscribe();
  }

  public showSkillsDetailModal() {
    this.opportunityModalsService
      .showSkillsDetailModal({
        opportunityId: this.opportunity.opportunityId,
        userProfileKey: this.authUser.viewerProfile.userProfileKey,
        extraTrackingProperties: {
          viewMode: 'SkillsMatch',
          matchedSkillsCount: this.opportunity.matchingSkills,
          opportunitySkillsCount: this.opportunity.tags?.length,
        },
      })
      .subscribe(() => {
        this.opportunityTrackingService.trackOpportunity(
          this.opportunity,
          'Opportunity skill match modal opened'
        );
      });
  }

  // TODO: See the note on the updates-listener. This, too, is only
  // used in the browse preview view.
  public toggleCardSelection() {
    this.isSelected = !this.isSelected;
    this.selectOpportunity.emit(this.isSelected);
  }

  public onTitleClick(event: MouseEvent) {
    if (!this.isBrowsePreviewView) {
      return this.opportunityTrackingService.trackOpportunity(
        this.opportunity,
        'Opportunity Clicked'
      );
    }
    this.opportunityTrackingService.trackOpportunity(
      this.opportunity,
      'Opportunity Sidebar Clicked'
    );
    if (
      !event.ctrlKey &&
      !event.metaKey &&
      // also when browseView and mobile, navigate directly to the opportunity
      !(this.isBrowsePreviewView && this.windowLayoutService.isMobile)
    ) {
      event.preventDefault();
      this.viewOpportunity.emit();
    }
  }

  /**
   * Return one of 4 possible lengths.
   *
   * @param opportunity
   */
  private getDescriptionLengthType(opportunity: Opportunity): 4 | 3 | 2 | 1 {
    // if a description length is passed in, use that.
    if (this.descriptionLength) {
      switch (this.descriptionLength) {
        case 'short':
          return 1;
        case 'medium':
          return 2;
        case 'long':
          return 3;
      }
    }
    const type =
      this.opportunityViewService.getDisplayOpportunityTypes(opportunity);
    const locationName = opportunity.locationName;
    const title = opportunity.title;
    // if both are set, check lengths
    if (type && locationName) {
      // is everything long enough to be on its own line?
      if (
        type.length >= this.metaLimit &&
        locationName.length >= this.metaLimit
      ) {
        // and is the title on two or more lines?
        if (title.length >= 40) {
          return 1;
        }
        return 2;
      }
      // or are they at least long enough to force the badge
      // onto its own line?
      if (type.length + locationName.length >= this.metaLimit) {
        return 3;
      }
    }
    // otherwise, use standard description length
    return 4;
  }

  /**
   * Gets the number of matching skills by checking the authUser's interests
   * against the skills on an opportunity.
   *
   * @param opportunitySkills - A list (names) of an opportunity's skills
   */
  private getMatchingSkillsCount(
    // same here
    opportunitySkills: any[]
  ): number {
    // TODO: Fix this? The authUser is cached after log in, so if they
    // update their skills after logging in, this will be inaccurate.
    const userSkills = this.authUser?.viewerInterests || [];
    if (!userSkills.length) {
      return 0;
    }

    return (
      opportunitySkills
        // create a new array of only skill names
        .map((skill) => skill.name || skill)
        // create a new array with the skill name and isMatch boolean
        .map((skillName) => ({
          name: skillName,
          isMatch: userSkills.some(
            (userSkill) =>
              userSkill.name.toLowerCase() === skillName.toLowerCase()
          ),
        }))
        // filter those skills down to only matches
        .filter((skill) => skill.isMatch).length
    );
  }

  private setUpMenuConfig(): void {
    let menuType: OpportunityMenuTypeEnum;
    switch (this.webEnvironmentService.analyticsAppLocation) {
      case 'Learner Home':
        menuType = OpportunityMenuTypeEnum.HomeFeedMenu;
        break;
      default:
        menuType = this.showCollaboratorMenu
          ? OpportunityMenuTypeEnum.BrowseListMenu
          : OpportunityMenuTypeEnum.CardMenu;
        break;
    }

    // get menu
    this.menuConfig = this.opportunityMenuService.getMenuConfig(
      menuType,
      this.opportunity,
      this.dismiss
    );
  }
}
