import { Injectable } from '@angular/core';
import { InputSession } from '@app/inputs/inputs-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LiveEventsService {
  public readonly expirationBuffer = 30; // minutes;

  constructor(private translate: TranslateService) {}

  /**
   * Returns a location string derived from the session data taking in to account the amount of space available
   * @param session - Object containing the session data
   * @param size - amount of space available to display the location string. One of three options is required:
   * - 'short'  - for display on small cards (aka tiles)
   * - 'medium' - for display on wide cards (eg Pathways & Search results)
   * - 'long'   - for display in the details modal
   */
  public getLocation(session: InputSession, size: 'short' | 'medium' | 'long') {
    let location = session.city;
    if (!!session.city && !!session.country && size !== 'short') {
      location = `${session.city}, ${session.country}`;
    } else if (!session.city && !!session.country) {
      location = session.country;
    } else if (!session.city && !session.country && size === 'long') {
      location = session.locationAddress; // locationAdress comes from a free-form text field (eg. "training room")
    }
    if (session.locationType === 'Online') {
      location = this.translate.instant('Core_Online');
    } else if (session.locationType === 'Hybrid') {
      // both online and in person
      if (size === 'long') {
        location = `${location} & ${this.translate.instant('Core_Online')}`;
      } else {
        location = this.translate.instant('LiveEvents_Hybrid');
      }
    }
    return location;
  }

  public getTrackingProperties(
    resource: LearningResourceViewModel,
    session?: InputSession
  ) {
    const trackingProperties = {
      orgId: resource.organizationId,
      contentSessionId: session?.inputSessionId,
      contentId: resource.resourceId,
      contentName: resource.title,
      providerName: resource.providerSummary?.name,
      sessionCount: resource.liveSessions?.length,
    };
    return trackingProperties;
  }

  public isSessionExpired(session: InputSession) {
    const endDateTime = new Date(session.endDateTime);
    const endDateTimeBuffer = new Date(
      endDateTime.getTime() + this.expirationBuffer * 60 * 1000
    );
    const userCurrentTime = new Date();
    return endDateTimeBuffer < userCurrentTime;
  }

  public getLiveSession(sessions: InputSession[]) {
    for (const session of sessions) {
      if (session.isLive) {
        return session;
      }
    }
    return;
  }
}
