import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import {
  ExperienceApiEntity,
  ExperienceFormModel,
} from '../model/experience-form.model';

@Injectable({ providedIn: 'root' })
export class UserProfileExperienceMapper
  implements Mapper<ExperienceFormModel, ExperienceApiEntity>
{
  constructor(private datePipe: DatePipe) {}
  toApiParameters(source: ExperienceFormModel): ExperienceApiEntity {
    return {
      userPositionId: source.userPositionId,
      userProfileKey: source.userProfileKey,
      userProfileId: source.userProfileId,
      positionId: source.positionId,
      title: source.title,
      startDate: this.datePipe.transform(source.extent.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(source.extent.endDate, 'yyyy-MM-dd'),
      hoursPerWeek: source.extent.hoursPerWeek,
      isCurrent: source.isCurrent ?? false,
      level: source.level,
      inputSubType: source.inputSubType,
      inputSubTypePeerName: source.inputSubTypePeerName,
      masteryPoints: source.masteryPoints,
      dateCreated: source.dateCreated,
      organizationName: source.organizationName,
      isImported: source.isImported,
      inputId: source.inputId,
      inputType: 'Position',
      tags: source.tags,
      userInputId: source.userInputId,
      description: source.description,
    } as ExperienceApiEntity;
  }

  toViewModel(source: ExperienceApiEntity): Partial<ExperienceFormModel> {
    return {
      ...source,
      title: source.title,
      description: source.description,
      extent: {
        startDate: source.startDate ? new Date(source.startDate) : undefined,
        endDate: source.endDate ? new Date(source.endDate) : undefined,
        hoursPerWeek: source.hoursPerWeek || 40,
      },
      level: source.level || 'Intermediate',
      inputSubType: source.inputSubType,
      inputSubTypePeerName: source.inputSubTypePeerName,
      tags: source.tags,
      organizationName: source.organizationName,
      isCurrent: source.isCurrent ?? false,
    } as Partial<ExperienceFormModel>;
  }
}
