import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes an array of things and turns it into an array of arrays of things
 * where each subarray is at most count items long. This is useful when you need
 * to render something every N elements or for grouping items into sections, etc.
 */
@Pipe({ name: 'chunked' })
export class ChunkedPipe implements PipeTransform {
  public transform<T>(items: T[], count = 4): T[][] {
    const result: T[][] = [];
    let last: T[];

    for (const item of items) {
      if (!last || last.length === count) {
        // Start a new chunk since the last one is full or this is the first item
        last = [];
        result.push(last);
      }

      last.push(item);
    }

    return result;
  }
}
