import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';

// misc

import { FormGroup } from '@angular/forms';

import { BadgeMapperService, BadgeService, BadgeTrackerService } from '../';

import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { BadgeFormDataModel, BadgeModel, Badge } from '../../badge.model';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { OutcomesFacadeBaseV2 } from '@app/user-content/user-outcome-v2/services/outcomes-facade.base';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { BadgeUploadAdapter } from '@app/user-content/user-outcome-v2/outcomes/badge/services/badge-upload.adapter';
import { UploaderService } from '@app/uploader/uploader.service';
import { AuthService, WebEnvironmentService } from '@dg/shared-services';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable()
export class BadgeGlobalAddFacade extends OutcomesFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<BadgeModel>(undefined);

  constructor(
    public translate: TranslateService,
    public inputsService: InputsService,
    public badgeMapperService: BadgeMapperService,
    public badgeService: BadgeService,
    public badgeTrackerService: BadgeTrackerService,
    public outcomeNotificationService: OutcomeNotificationService,
    public userOutcomeService: UserOutcomeService,
    public outcomesService: OutcomesService,
    public uploadService: UploaderService,
    private webEnvironmentService: WebEnvironmentService,
    private authService: AuthService,
    public tipService: TipService,
    public globalAddTrackingService: GlobalAddTrackingService
  ) {
    super(outcomesService, tipService);
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /** Gets the latest view model */
  protected get viewModel(): BadgeModel {
    return this.viewModel$.value as BadgeModel;
  }

  protected set viewModel(viewModel: BadgeModel) {
    this.viewModel$.next(viewModel);
  }

  /**
   * Easy access to current snapshot of [read-only] BadgeModel
   * ...
   */
  public get snapshot(): BadgeModel {
    return readFirst(this.viewModel$);
  }

  public async onNext(url: string): Promise<void> {
    this.viewModel = { ...this.viewModel, badgeUrl: url };

    this.viewModel = {
      ...this.viewModel,
      badgeUrl: url,
      isInitialForm: false,
    };
    return;
  }

  // *******************************************************
  // Overwrites
  // *******************************************************

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      const formData = form.value;
      // Step 1 update the View model with the form data
      this.updateViewWithFormData(formData);
      // step 2 get API Params mapper
      const apiParameters = this.badgeMapperService.toApiParameters(
        this.viewModel as BadgeModel
      );
      this.submissionStatus$.next(SubmissionStatus.Submitting);
      if (this.viewModel.isEditing) {
        await this.badgeService.updateBadge(apiParameters);
      } else {
        await this.badgeService.addBadge(apiParameters);
      }
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
      this.performSuccessSideEffects();
      this.globalAddTrackingService.trackGlobalAddSaved(
        'Badge',
        this.viewModel.skills,
        this.viewModel.mediumConfidenceInferredSkills,
        this.viewModel.highConfidenceInferredSkills
      );
    } catch (error) {
      this.performFailureSideEffects();
      throw new Error('Error in BadgeFacade', error);
    }
    return;
  }

  /**
   * Override initializeViewModel
   */
  public initializeViewModel(): void {
    super.initializeViewModel();

    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
    ]).pipe(map(([s]) => s === SubmissionStatus.Submitting));

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      issuer: '',
      issuerUrl: '',
      dateRangeForm: {
        startDate: null,
        endDate: null,
      },
      shouldSpinSubmitButton$,
      loadInferredSkills: this.loadInferredSkills.bind(this),
      inferredSkills$: this.inferredSkills$,
      badgeUrl: '',
      isInitialForm: true,
      isSubmitButtonDisabled: true, // check
      uploadAdapter: new BadgeUploadAdapter(this.uploadService, this.translate),
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },

      errorMessages: {
        invalidFileType: this.translate.instant(
          'dgContentHosting_InvalidFileType',
          {
            startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
              '/articles/4408914250514'
            )}">`,
            endAnchor: '</a>',
          }
        ),
      },
    };
  }

  public async initializeEdit(userOutcomeId: number): Promise<void> {
    // Update viewModel
    const badge = await this.badgeService.getBadge(
      this.authService.authUser.viewerProfile.userProfileKey,
      userOutcomeId
    );

    this.loadInferredSkills(badge.title);
    // Map response to view model
    const updatedView = this.badgeMapperService.toViewModel(badge);
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isEditing: true,
      isInitialForm: false,
      isSubmitButtonDisabled: false,
    };
    return;
  }

  /** Performs any side effects required following successful creation of a Badge */
  protected performSuccessSideEffects(): void {
    if (!this.viewModel.isEditing) {
      this.outcomeNotificationService.notifyOutcomeCreated('badge');
    }
    const apiParameters = this.badgeMapperService.toApiParameters(
      this.viewModel
    );
    this.badgeTrackerService.trackBadgeAdded(apiParameters);

    this.userOutcomeService.notifyOutcomeModified('badge');
  }

  /** Performs any side effects required following failed creation of a Badge */
  protected performFailureSideEffects() {
    this.outcomeNotificationService.notifyOutcomeCreateFailed();
  }

  public loadInferredSkills(title: string): void {
    if (title) {
      this.inferredSkillsTrigger$.next({ title });
    }
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: BadgeFormDataModel) {
    this.viewModel = {
      ...this.viewModel,
      ...formData,
    };
  }

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: Badge
  ) {
    this.viewModel = {
      ...this.viewModel,
      badgeUrl: response.badge,
      issuerUrl: response.badge,
      details: response.badgeinfo,
      issuer: response.badgeinfo.issuerinfo.name,
      badgeImageUrl: response.image,
      dateRangeForm: {
        startDate: response.issuedOn ? new Date(response.issuedOn) : null,
        endDate: response.expires ? new Date(response.expires) : null,
      },
      isSubmitButtonDisabled: false,
      startDateEnabled: !response.issuedOn,
      endDateEnabled: !response.expires,
    };
    formGroup.patchValue(this.viewModel);
  }

  protected onContentFileChange(file: File): void {
    this.viewModel = {
      ...this.viewModel,
      file,
    };
  }
}
