import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';
import { AuthUser } from '@app/account/account-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Task, TaskParameters } from '@app/inputs/inputs-api.model';
import { InputImageUpload } from '@app/uploader/upload-section/adapters/input-image-upload.adapter';
import { UploaderService } from '@app/uploader/uploader.service';
import { InlineInputGetter } from '@app/inputs/inputs.model';
import { InputValidationService } from '../services/input-validation.service';

@Component({
  selector: 'dgx-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskModalComponent implements OnInit {
  @Input() public pathwayId: number;
  @Input() public organizationId: number;
  @Input() public canManageContent: boolean;
  @Input() public isPathwayBinAdd: boolean;
  @Input() public inputId: number;
  @Input() public load: InlineInputGetter;

  public durationMinutesValidationError: string = null;
  public durationHoursValidationError: string = null;
  public isEditing: boolean;
  public isAdding = false;
  public isLoading = true;
  public showThumbnail = false;
  public allowSetImage: boolean;
  public isPrivateProfile = false;
  public removeImage = false;
  public org = null;
  public orgId = null;
  public imageSizes = [
    {
      imageSizeId: '',
    },
  ];
  public authUser: AuthUser;
  public input: Partial<TaskParameters> = {
    organizationId: undefined,
    tags: [],
    title: '',
    summary: '',
    durationUnits: null,
    durationHours: null,
    durationMinutes: null,
    imageUrl: '',
  };
  public uploadAdapter: InputImageUpload;
  public i18n = this.translate.instant([
    'Core_Hours',
    'Core_Minutes',
    'Core_DurationMinutes',
    'Core_RemoveImage',
    'Core_ChooseFile',
    'Core_DragFileHere',
    'TaskFormCtrl_Edit',
    'TaskFormCtrl_Add',
    'TaskFormCtrl_Save',
    'TaskFormCtrl_Description',
    'TaskFormCtrl_AddImage',
    'TaskFormCtrl_ImagePreviewAltText',
    'TaskFormCtrl_TitleError',
    'dgOrgInternalContentForm_ImageDisplayOption',
    'dgImageUpload_ImageTypesSize',
  ]);

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private uploadService: UploaderService,
    private cdr: ChangeDetectorRef,
    private inputValidationService: InputValidationService
  ) {}

  public ngOnInit(): void {
    this.isEditing = !!this.inputId;
    this.authUser = this.authService.authUser;
    this.org = this.authUser?.orgInfo ? this.authUser.orgInfo[0] : null;
    this.orgId = this.org ? this.org.organizationId : null;
    this.isPrivateProfile = !!this.authUser?.isPrivateProfile;
    this.allowSetImage =
      !this.authUser.isRestrictedProfile &&
      (!this.orgId ||
        this.authUser.defaultOrgInfo?.permissions?.manageContent ||
        this.authUser.defaultOrgInfo?.settings?.allowRestrictedImages);

    if (this.isEditing) {
      this.load(this.inputId).subscribe((input: Task) => {
        this.input = input;
        this.initTaskModal();
        this.cdr.detectChanges();
      });
    } else {
      this.initTaskModal();
    }
  }

  public onSubmit() {
    this.input.organizationId = this.organizationId;
    this.input.orgContentMetadata = {
      hideFromCatalog: true,
    };
    this.activeModal.close(this.input);
  }

  public onImageUploadSuccess({
    pictureUrl,
    legacyPictureUrl,
    resourceImageId,
  }) {
    if (pictureUrl) {
      this.input.imageUrl = pictureUrl;
      this.input.legacyPictureUrl = legacyPictureUrl;
      this.input.resourceImageId = resourceImageId;
    }
  }

  public showCropper(data) {
    if (data.shouldShow) {
      this.showThumbnail = true;
    }
  }

  public onImageParsed(event) {
    if (event.legacyPictureUrl) {
      this.input.legacyPictureUrl = event.legacyPictureUrl;
    }
  }

  public onDeleteImage() {
    this.input.imageUrl = undefined;
    this.input.legacyPictureUrl = undefined;
    this.input.resourceImageId = undefined;
  }

  public handleTagsChange(updatedTags) {
    this.input.tags = updatedTags;
  }

  public onChangeOfDuration(
    durationHours: number,
    durationMinutes: number
  ): void {
    const durationHoursAndMinutes: {
      durationHoursValidationError: string;
      durationMinutesValidationError: string;
    } = this.inputValidationService.getDurationValidationErrors(
      durationHours,
      durationMinutes
    );

    this.durationHoursValidationError =
      durationHoursAndMinutes.durationHoursValidationError;
    this.durationMinutesValidationError =
      durationHoursAndMinutes.durationMinutesValidationError;
  }

  private initTaskModal() {
    this.uploadAdapter = new InputImageUpload(
      this.uploadService,
      'Task',
      this.isEditing ? this.inputId : null,
      'Pathway',
      this.pathwayId
    );

    this.isLoading = false;
  }
}
