import { LDFlagsService } from '../ld-flags.service';

export class SkillCoachLDFlags {
  /***
   *  Team: Manager Experience
   *  Created Date: 9/11/2024 https://degreedjira.atlassian.net/browse/PD-106518
   *  Planned Cleanup: TBD
   *  Visibility:
   *    - Org 1
   *    - Pre-release: Skill Coach Manager Assignments
   *
   *  Enables new Skill Coach Manager Assignements page
   */
  public get managerAssignments(): boolean {
    return this.ldFlagsService.getFlag(
      'mpr-manager-assignments-2025-feb',
      false
    );
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
