import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Opportunity Interested Candidate notification */
@Component({
  selector: 'dgx-opportunity-interested',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityInterestedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml(
      'Notification_TitleFormat_OpportunityInterested',
      {
        nameHtml: this.userFullNameTemplate,
      }
    );
  }
}
