import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ChannelBundleApiService } from '@app/channel/services/channel-bundle-api.service';

// types
import {
  ChannelBundle,
  ChannelBundleResource,
} from '@app/channel/services/channel-bundle.model';

export interface ChannelBundleSelectModalResolve {
  orgId: number;
  resources: ChannelBundleResource[];
}

export interface ChannelBundleSelectModalResponse {
  selectedBundles: ChannelBundle[];
  resources: ChannelBundleResource[];
}

@Component({
  selector: 'dgx-channel-bundle-select-modal',
  templateUrl: './channel-bundle-select-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelBundleSelectModalComponent implements OnInit {
  @Input() public resolve: ChannelBundleSelectModalResolve;

  public i18n = this.translate.instant([
    'Core_AddToBundle',
    'Channel_NameColumn',
    'Channel_AddBundleDescription',
    'Channel_SearchByName',
    'Core_Add',
  ]);

  private orgId: number;
  private resources: ChannelBundleResource[];

  public bundleList: ChannelBundle[];
  public filteredBundleList: ChannelBundle[];
  public searchTerm: string = '';
  public isBulkStateOn: boolean = false;
  public indeterminate: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private channelBundleApiService: ChannelBundleApiService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.orgId = this.resolve.orgId;
    this.resources = this.resolve.resources;
    this.channelBundleApiService
      .getBundlesForOrg(this.orgId)
      .subscribe((bundles) => {
        this.bundleList = bundles.map((bundle) => {
          return {
            ...bundle,
            ...{
              isSelected: false,
            },
          };
        });
        this.filteredBundleList = [...this.bundleList];
        this.cdr.detectChanges();
      });
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

  public toggleBundle(bundle: ChannelBundle) {
    bundle.isSelected = !bundle.isSelected;
  }

  public getSelectedItemCount() {
    return this.filteredBundleList.filter((bundle) => {
      return bundle.isSelected;
    }).length;
  }

  public bulkChangeState() {
    const isChecked = this.isBulkStateOn;
    this.filteredBundleList.map((bundle) => {
      bundle.isSelected = isChecked;
    });

    this.setBulkStateCheckboxStatus();
  }

  public setBulkStateCheckboxStatus() {
    const selectedItemCount = this.getSelectedItemCount();
    const filteredBundleList = this.filteredBundleList.length;
    if (selectedItemCount === 0 || selectedItemCount === filteredBundleList) {
      this.indeterminate = false;
    } else if (selectedItemCount < filteredBundleList) {
      this.indeterminate = true;
    }
  }

  public save() {
    const selectedBundles = this.filteredBundleList.filter((bundle) => {
      return bundle.isSelected;
    });
    this.activeModal.close({
      selectedBundles,
      resources: this.resources,
    } as ChannelBundleSelectModalResponse);
  }

  public searchMatchedBundles(term: string) {
    this.searchTerm = term;
    this.filteredBundleList = !this.searchTerm
      ? this.bundleList.slice()
      : this.bundleList.filter((bundle) => {
          return (
            bundle.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
            -1
          );
        });
  }
}
