export class InputSessionModel {
  constructor(
    public locationAddress: string = null,
    public city: string = null,
    public country: string = null,
    public countryCode: string = null,
    public endDateTime: string = null,
    public isRegistrationUrlInputUrl: boolean = null,
    public isRegistrationAvailable: boolean = false,
    public startDateTime: string = null,
    public timeZoneId: string = null,
    public latitude: number = null,
    public locationUrl: string = null,
    public longitude: number = null,
    public registrationUrl: string = null
  ) {}
}
