import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResourceType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import {
  FileUploadSetting,
  VideoUploadSettings,
} from '@app/uploader/uploader-api.model';
import { RenderMode } from '@app/user-content/user-input/user-input.model';
import { TranslateService } from '@ngx-translate/core';

export interface UploadFileType {
  uploadType: ResourceType;
  renderMode: RenderMode;
}

export type CanUploadFileResults = [boolean, FileUploadSetting] | undefined;

/**
 * Content Hosting Upload Service
 * @description Content Hosting servcie that handles upload to Box
 */
@Injectable()
export class CHUploadService {
  constructor(
    private http: NgxHttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  /**
   * Get  uploading limits
   */
  public getFileUploadLimits(
    uploadType: ResourceType
  ): Observable<FileUploadSetting> {
    const options = { params: { inputType: uploadType }, cache: true };
    const filterDuplicates = (result) => {
      result = camelCaseKeys(result);
      result.allowedFileTypes = [
        ...new Set( // filter out duplicates
          result.allowedFileTypes.map((fileType) => fileType.toLowerCase())
        ),
      ];
      return result;
    };

    return this.http
      .get<FileUploadSetting>('/inputs/GetInputFileUploadLimits', options)
      .pipe(map(filterDuplicates));
  }

  /**
   * Check if user has access to upload a content hosted file of a particular input type and if so return FileUploadSettings.
   *
   * @params uploadType - Type of resource input to upload ie. Article, Episode etc.
   * @params renderMode - The renderMode the input is uploaded to.  Currently content-catalog is the only renderMode supported.
   * @returns Observable<[boolean, FileUploadSetting]>
   */
  public canUploadHostedFile({
    uploadType,
    renderMode,
  }: UploadFileType): Observable<CanUploadFileResults> {
    const authUser = this.authService.authUser;
    const canUpload =
      authUser.canUploadContentFiles &&
      renderMode === RenderMode.ContentCatalog;
    const toResults = (result: FileUploadSetting): CanUploadFileResults =>
      result.allowedFileTypes.length > 0 ? [true, result] : [false, undefined];
    const request$ = this.getFileUploadLimits(uploadType);

    return canUpload ? request$.pipe(map(toResults)) : of([false, undefined]);
  }

  public getStorageLimitErrorMessage(
    fileRestrictions: FileUploadSetting | VideoUploadSettings
  ): string {
    return this.translateService.instant(
      'dgContentHosting_OrgStorageLimitIsReached',
      {
        startBoldTag: '<b>',
        endBoldTag: '</b>',
        orgStorageLimitInMB: fileRestrictions.inputHostedFileMBLimit,
      }
    );
  }

  public getStorageLimitWarningMessage(
    fileRestrictions: FileUploadSetting | VideoUploadSettings
  ): string {
    return this.translateService.instant(
      'dgContentHosting_OrgStorageLimitAlmostReached',
      {
        startBoldTag: '<b>',
        endBoldTag: '</b>',
        orgStorageRemainingInMB:
          fileRestrictions.inputHostedFileMBLimit -
          fileRestrictions.totalFileMB,
        orgStorageLimitInMB: fileRestrictions.inputHostedFileMBLimit,
      }
    );
  }
}
