import { Injectable } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { Observable } from 'rxjs';
import {
  CropperCoordinates,
  ImageCropperEvent,
} from '../image-cropper-modal.model';
import { ImageCropperModalComponent } from '../image-cropper-modal.component';

export interface ImageCropperModalOptions {
  imageUrl: string;
  aspectRatio: number;
  secondaryAspectRatio?: number;
  cropperCoordinates?: CropperCoordinates;
  modalTitle?: string;
  modalSubmit?: string;
  modalInstructions?: string;
  maintainAspectRatio?: boolean;
  altText?: string;
  hideAltTextInput?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ImageCropperService {
  constructor(private modalService: ModalService) {}

  /**
   * Show the image cropper modal.
   *
   * @param inputs the inputs that will be passed to the image cropper modal
   */
  public showCropperModal(
    inputs: ImageCropperModalOptions,
    errorOnDismiss = false
  ): Observable<ImageCropperEvent | null> {
    return this.modalService.show(ImageCropperModalComponent, {
      inputs,
      errorOnDismiss,
      windowClass: 'modal--image-cropper modal--secondary',
    });
  }
}
