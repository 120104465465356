import { Inject, Injectable } from '@angular/core';
import { OrgService } from '@app/orgs/services/org.service';
import { OrganizationModel } from '@app/orgs/services/orgs.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LOCAL_STORAGE } from './storage';

export interface CareerPathCustomNames {
  customDirectoriesName: string;
  customDirectoryName: string;
  customPlansName: string;
  customPlanName: string;
  groupedSearchFacedName: string;
}

@Injectable({
  providedIn: 'root',
})
export class CareerPathNamingService {
  private i18n = this.translate.instant([
    'Core_Directory',
    'Core_Role',
    'TargetResources_TargetAndDirectoryPlural',
  ]);

  private _careerPathNames$ = new BehaviorSubject<CareerPathCustomNames>(
    undefined
  );

  constructor(
    @Inject(LOCAL_STORAGE) private storage: Storage,
    private orgService: OrgService,
    private translate: TranslateService
  ) {
    const cachedNames = JSON.parse(storage.getItem('careerPathNames'));
    this._careerPathNames$.next(cachedNames);
  }
  public get careerPathNames() {
    return this._careerPathNames$.value;
  }

  public get careerPathNames$(): Observable<CareerPathCustomNames> {
    return this._careerPathNames$;
  }

  public getCareerPathNames(defaultOrgId: number): any {
    return this.orgService.getUserOrganizations().pipe(
      tap((orgData) => {
        const org = orgData.find((org) => org.organizationId === defaultOrgId);
        this._careerPathNames$.next(this.setCustomNamesValues(org));
        this.storage.setItem(
          'careerPathNames',
          JSON.stringify(this.careerPathNames)
        );
      })
    );
  }

  private setCustomNamesValues(orgData: OrganizationModel) {
    const customNames = {} as CareerPathCustomNames;

    // The org has Career Path enabled without a custom name set
    if (orgData && orgData.customCareerPathNames) {
      customNames.customDirectoriesName = customNames.customDirectoryName =
        orgData.customCareerPathNames.customDirectoriesName;
      customNames.customPlansName = customNames.customPlanName =
        orgData.customCareerPathNames.customPlansName;

      // if custom name, then disregard translation and concat with ampersand
      customNames.groupedSearchFacedName =
        orgData.customCareerPathNames.customDirectoriesName +
        ' & ' +
        orgData.customCareerPathNames.customPlansName;
    } else {
      // else the customer has no custom names, using general translations
      customNames.customDirectoryName = this.i18n.Core_Directory;
      customNames.customPlanName = this.i18n.Core_Role;
      customNames.groupedSearchFacedName =
        this.i18n.TargetResources_TargetAndDirectoryPlural;
    }

    return customNames;
  }
}
