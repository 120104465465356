<!-- This file is adapted from https://github.com/Mawi137/ngx-image-cropper with our own
 modifications to support a secondary aspect ratio box while cropping. -->

<div class="l_flex m-l_flex l_flex-column">
  <!-- 
    Linter Error
    [style.transform]="safeTransformStyle"
    safeTransformStyle doesn't exist on type 'CropperComponent'
  -->

  <img
    #sourceImage
    class="__source-image"
    *ngIf="imgDataUrl"
    [src]="imgDataUrl"
    [style.visibility]="imageVisible ? 'visible' : 'hidden'"
    (load)="imageLoadedInView()"
  />
  <div
    class="__cropper"
    *ngIf="imageVisible"
    [style.top.px]="cropper.y1"
    [style.left.px]="cropper.x1"
    [style.width.px]="cropper.x2 - cropper.x1"
    [style.height.px]="cropper.y2 - cropper.y1"
    [style.margin-left]="marginLeft"
    [style.visibility]="imageVisible ? 'visible' : 'hidden'"
    (keydown)="keyboardAccess($event)"
    tabindex="0"
  >
    <div
      (mousedown)="startMove($event, 'move')"
      (touchstart)="startMove($event, 'move')"
      class="__move"
    ></div>
    <ng-container>
      <span
        class="__resize topleft"
        (mousedown)="startMove($event, 'resize', 'topleft')"
        (touchstart)="startMove($event, 'resize', 'topleft')"
      >
        <span class="__square"></span>
      </span>
      <span
        class="__resize topright"
        (mousedown)="startMove($event, 'resize', 'topright')"
        (touchstart)="startMove($event, 'resize', 'topright')"
      >
        <span class="__square"></span>
      </span>
      <span
        class="__resize bottomright"
        (mousedown)="startMove($event, 'resize', 'bottomright')"
        (touchstart)="startMove($event, 'resize', 'bottomright')"
      >
        <span class="__square"></span>
      </span>
      <span
        class="__resize bottomleft"
        (mousedown)="startMove($event, 'resize', 'bottomleft')"
        (touchstart)="startMove($event, 'resize', 'bottomleft')"
      >
        <span class="__square"></span>
      </span>
      <span
        class="__resize-bar top"
        (mousedown)="startMove($event, 'resize', 'top')"
        (touchstart)="startMove($event, 'resize', 'top')"
      >
      </span>
      <span
        class="__resize-bar right"
        (mousedown)="startMove($event, 'resize', 'right')"
        (touchstart)="startMove($event, 'resize', 'right')"
      >
      </span>
      <span
        class="__resize-bar bottom"
        (mousedown)="startMove($event, 'resize', 'bottom')"
        (touchstart)="startMove($event, 'resize', 'bottom')"
      >
      </span>
      <span
        class="__resize-bar left"
        (mousedown)="startMove($event, 'resize', 'left')"
        (touchstart)="startMove($event, 'resize', 'left')"
      >
      </span>
    </ng-container>
  </div>
  <!-- crop box displaying the secondary aspect ratio -->
  <div
    class="__cropper __cropper--secondary"
    *ngIf="imageVisible && secondaryAspectRatio"
    [style.top.px]="secondaryCropper.y1"
    [style.left.px]="secondaryCropper.x1"
    [style.width.px]="secondaryCropper.x2 - secondaryCropper.x1"
    [style.height.px]="secondaryCropper.y2 - secondaryCropper.y1"
    [style.margin-left]="marginLeft"
    [style.visibility]="imageVisible ? 'visible' : 'hidden'"
  >
    <div
      (mousedown)="startMove($event, 'move')"
      (touchstart)="startMove($event, 'move')"
      class="__move"
    ></div>
  </div>

  <div
    class="__overlay"
    [style.width.px]="maxSize.width"
    [style.height.px]="maxSize.height"
  ></div>
</div>
