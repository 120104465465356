<div class="menu" *ngIf="hasMenuOptions">
  <!-- content must be valid button element for a11y -->
  <ng-content select="button,[role=button],dgx-action-button"></ng-content>
  <df-popover
    [isAppendToBody]="appendToBody"
    [isOpen]="isPopoverOpen"
    [placement]="placement"
    [placementAdjustLeftRem]="placementAdjustLeftRem"
    [placementAdjustTopRem]="placementAdjustTopRem"
    [isMobileOptedOut]="isMobileOptedOut"
    (isOpenChange)="popoverToggled($event)"
  >
    <div
      role="menu"
      class="menu__popover"
      [class.menu__popover--grow]="allowMenuGrowth"
      [closeOnSelect]="false"
    >
      <ng-container
        *ngFor="
          let itemVm of menuConfig;
          trackBy: getItemTrackingKey;
          let isFirst = first
        "
        data-dgat="menu-a73"
      >
        <ng-container *ngIf="isVisible(itemVm)">
          <button
            role="menuitem"
            class="menu__item"
            [itemViewModel]="itemVm"
            [class.l_flex]="itemVm.icon"
            [class.menu__item--highlight]="itemVm.isSelected"
            [class.menu__item--primary]="itemVm.isPrimary"
            [class.menu__item--destructive]="itemVm.isDestructive"
            [class.menu__item--separated]="
              isSeparated(isFirst, itemVm.isSeparated)
            "
            [class.menu__item--align-missing-icon-large]="
              missingLargeIcon(itemVm)
            "
            [class.menu__item--align-missing-icon-medium]="
              missingMediumIcon(itemVm)
            "
            [class.menu__item--align-missing-icon-small]="
              missingSmallIcon(itemVm)
            "
            [attr.data-dgat]="getDgat(itemVm)"
            [disabled]="itemVm.isDisabled"
            [attr.aria-label]="itemVm.ariaLabel"
            (click)="onMenuItemClick($event, itemVm)"
          >
            <df-icon
              [icon]="itemVm.icon"
              [size]="iconSize"
              *ngIf="itemVm.icon"
              class="guts-p-r-1"
            ></df-icon>
            {{ itemVm.title }}
            <span *ngIf="itemVm.description" class="a11y-hide">{{
              itemVm.description
            }}</span>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </df-popover>
</div>
