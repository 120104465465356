<div class="grid">
  <header class="grid__col-sm-12 guts-p-t-4 guts-p-b-1-half">
    <div class="l_flex m-l_flex-col m-center-text">
      <h1 class="h1 l_flex-grow">
        {{ 'OrganizationsController_FAQ' | translate }}
      </h1>
    </div>
  </header>
  <div class="grid__col-sm-12">
    <dgx-org-faq [viewOnly]="true" [orgId]="orgId"></dgx-org-faq>
  </div>
</div>
