import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Validates if the url is a broken link
 */
export function hasBrokenUrlValidator(
  control: AbstractControl,
  translatedMessage: string,
  hasBrokenUrl: boolean,
  hasUserEditedBrokenUrl: boolean
): ValidationErrors {
  return control.value && hasBrokenUrl && !hasUserEditedBrokenUrl
    ? {
        urlBrokenValidation: {
          message: translatedMessage,
        },
      }
    : null;
}
