<aside
  class="pathway-author-note l_flex"
  [ngClass]="{
    'pathway-author-note--mobile': vm.isMobileView
  }"
  *ngIf="step.note && (vm$ | async) as vm"
>
  <div class="pathway-author-note__icon--shadow no-tablet">
    <df-icon icon="author-note" class="pathway-author-note__icon"></df-icon>
  </div>
  <button
    class="pathway-author-note__icon--shadow tablet-only"
    data-dgat="pathway-author-note-edit-803"
    (click)="toggleButton()"
  >
    <df-icon
      icon="author-note"
      class="pathway-author-note__icon pathway-author-note__icon--mobile"
    ></df-icon>
  </button>
  <div
    [ngClass]="{
      'popover__window--right': !vm.isMobileView,
      'popover__window--left pathway-author-note__window--mobile':
        vm.isMobileView
    }"
    class="popover__window pathway-author-note__window kristen-says-hi-edit"
    *ngIf="!vm.isMobileView || (vm.isMobileView && step.note.shouldDisplayNote)"
  >
    <div
      [ngClass]="{
        'popover-arrow--window-right-top': !vm.isMobileView,
        'popover-arrow--window-left-top': vm.isMobileView
      }"
      class="popover-arrow pathway-author-note__arrow"
    ></div>
    <div class="popover__content pathway-author-note__content guts-p-full-1">
      <div class="l_flexbar l_flex-wrap guts-p-b-half">
        <h2 class="l_flex-grow par par--light par--small">
          {{ i18n.Pathways_NoteFromAuthor }}
        </h2>
        <dgx-menu
          [menuConfig]="createMenuConfig()"
          placement="bottom-right"
          [placementAdjustLeftRem]="1"
          [appendToBody]="true"
        >
          <button type="button" data-dgat="pathway-author-subsection-122">
            <df-icon
              icon="dots"
              a11yText="{{ 'Core_MoreOptions' | translate }}"
              class="color-ebony-a61"
            ></df-icon>
          </button>
        </dgx-menu>
      </div>
      <p class="par par--small markdown markdown--small">
        <textarea
          #noteTextArea
          class="elastic author-note__textarea"
          [ngModel]="step.note.note"
          (blur)="onBlur($event)"
          [attr.aria-label]="i18n.Pathways_AddANote"
          [placeholder]="i18n.Pathways_AddANote"
          aria-describedby="pathauthA11yBlurInstructions"
          maxlength="2000"
          data-dgat="pathwayAuthorNote-348"
        ></textarea>
      </p>
    </div>
  </div>
</aside>
