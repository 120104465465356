/**
 * Returns true if value is neither null nor undefined.
 * False if 0, -1, false, or empty string.
 *
 * @param value - Value to be tested.
 */
export function notNullish<T>(value: T): boolean {
  return (value ?? null) !== null;
}

/**
 * Returns true if value is neither null nor undefined *nor* an
 * empty string. False if 0, -1, false, or non-empty string.
 *
 * @param value - Value to be tested.
 */
export function notNullishOrEmptyString(value: string): boolean {
  return notNullish<string>(value) && value !== '';
}

/**
 * Returns true if value is neither null nor undefined *nor* an
 * empty string *nor* an empty array *nor* an empty object. False
 * if 0, -1, or false.
 *
 * @param value - Value to be tested.
 */
export function notNullishOrEmptyArray<T>(value: T): boolean {
  return Array.isArray(value)
    ? !!value.length
    : notNullishOrEmptyString(value as string);
}
