import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SearchAutocompleteFacade } from './search-autocomplete.facade';
import { SearchAutocompleteDataService } from './search-autocomplete.data-service';
import { MarkdownModule } from '@app/markdown/markdown.module';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MarkdownModule,
    //standalone components
    AutofocusDirective,
  ],
  providers: [SearchAutocompleteFacade, SearchAutocompleteDataService],
})
export class SearchAutocompleteReactiveStoreModule {}
