import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Group } from '@app/shared/services/reporting/reporting-service';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class InsightsFeatureService {
  private readonly userCount: number;

  constructor(
    private authService: AuthService,
    private ldFlagsService: LDFlagsService,
    private translateService: TranslateService
  ) {
    this.userCount =
      this.authService.authUser?.minimumAggregatedLearningInsightsUserCount;
  }

  /**
   * Determine if the user default organization has a minimum aggregated learning insights user count value greater than 0
   */
  public get hasMinimumUserCount(): boolean {
    return (
      this.ldFlagsService.insights.minimumAggregatedLearningInsightsUserCount &&
      this.userCount > 0
    );
  }

  /**
   * Get the warning message text to be displayed within a parent component (i.e. dgxCombobox)
   */
  public get warningMessage(): string {
    let value: string = '';
    if (this.hasMALIUCFeature && this.userCount) {
      value = this.translateService.instant(
        'OrgReportingCtrl_MinimumAggregatedLearningInsightsUserCount',
        {
          number: this.userCount,
        }
      );
    }
    return value;
  }

  /**
   * Minimum Aggregated Learning Insights User Count LD flag turned on
   */
  private get hasMALIUCFeature(): boolean {
    return this.ldFlagsService.insights
      .minimumAggregatedLearningInsightsUserCount;
  }

  /**
   * If applicable, filter a given array of Group elements if the userCount property value
   * is greater than or equal to the Ops Tool Organization Config Setting:
   * (Minimum Aggregated Learning Insights User Count)
   * @param list
   */
  public applyGroupFilter(list: Group[]): Group[] {
    let filteredList: Group[];
    if (this.hasMALIUCFeature && this.userCount) {
      filteredList = list.filter((g) => g.userCount >= this.userCount);
    } else {
      filteredList = [...list];
    }
    return filteredList;
  }
}
