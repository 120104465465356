<span class="custom-slider">
  <ngx-slider
    [value]="value"
    (valueChange)="onRatingChange.emit($event)"
    [options]="options"
    [triggerFocus]="triggerFocus"
    [manualRefresh]="manualRefresh"
    [attr.aria-description]="i18n.A11y_SliderNavigation"
  ></ngx-slider>
</span>
