import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

@Component({
  selector: 'dgx-bulk-recommendation',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkRecommendationComponent extends NotificationItemComponentBase {
  public i18n = this.itemService.translate.instant(['Core_ViewSkills']);

  public buildTemplate() {
    switch (this.params.resourceType) {
      case 'Tag':
        return this.html`
          ${this.translateHtml(
            'Notification_RecommendedSkills_NotificationTitleFormat',
            {
              nameHtml: this.userFullNameTemplate,
            }
          )}
          <br />
          <a role="button" class="color-blue" data-dgat="bulkrecommendation-ee6">
            ${this.i18n.Core_ViewSkills}
          </a>`;
      default:
        return this.translateHtml(
          'Notification_RecommendedBulkGeneric_NotificationTitleFormat',
          {
            nameHtml: this.userFullNameTemplate,
          }
        );
    }
  }
}
