<form (ngSubmit)="close()">
  <dgx-modal
    [canCancel]="true"
    [canCloseHeader]="true"
    [submitButtonText]="'Core_Done' | translate"
    [useDefaultForm]="false"
    bodyClasses="guts-p-t-0"
  >
    <ng-container class="modal-header">
      {{ i18n.Pathways_ConfirmCompletions }}
    </ng-container>
    <ng-container class="grid modal-body">
      <div class="grid__col-12 guts-p-t-0">
        <h2
          class="guts-p-t-1 guts-p-b-2 par par--small"
          [innerHTML]="didYouCompleteTitle"
        ></h2>
        <ul class="border-top">
          <li
            *ngFor="let item of viewedItems"
            class="guts-p-v-1 border-bottom"
            data-dgat="pathwayViewedItems-7fc"
          >
            <dgx-pathway-viewed-item [item]="item"></dgx-pathway-viewed-item>
          </li>
        </ul>
      </div>
    </ng-container>
  </dgx-modal>
</form>
