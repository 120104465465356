import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a New Mentorship notification */
@Component({
  selector: 'dgx-mentorship-feedback-received',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorshipFeedbackReceivedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml(
      'MentorshipFeedbackReceived_NotificationTitleFormat',
      {
        nameHtml: this.userFullNameTemplate,
      }
    );
  }
}
