<dgx-modal
  (dismiss)="close()"
  [isFooterless]="true"
  bodyClasses="dg-modal__body--no-padding"
>
  <ng-container class="modal-header">
    {{ orgNewGroupSettingsInfo.createGroupTitle || '' }}
  </ng-container>

  <ng-container class="modal-body">
    <dgx-group-settings
      [groupId]="orgNewGroupSettingsInfo.groupId"
      [newGroupOrg]="orgNewGroupSettingsInfo.orgId"
      [canCreateOpenGroups]="orgNewGroupSettingsInfo.canCreateOpenGroups"
      [canCreateClosedGroups]="orgNewGroupSettingsInfo.canCreateClosedGroups"
      [canCreatePrivateGroups]="orgNewGroupSettingsInfo.canCreatePrivateGroups"
      [canCreateAdministrativeGroups]="
        orgNewGroupSettingsInfo.canCreateAdministrativeGroups
      "
      [isRegulated]="orgNewGroupSettingsInfo.isRestrictedProfile"
      (saveCallback)="save($event)"
    ></dgx-group-settings>
  </ng-container>
</dgx-modal>
