import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { ResourceIdBuilder } from '../../resource-id-builder';

/** Represents an Mention in Comment notification */
@Component({
  selector: 'dgx-mention-in-comment',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionInCommentComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    const builder = new ResourceIdBuilder(this.notification);
    const contentResource = builder
      .withSegment('mentionInComment_')
      .withOptionalGroup()
      .withSingleOrPlural()
      .asFormat();

    // "{{nameHtml}} [and {{number}} others] mentioned you [in {{groupNameHtml}}]."

    return this.translateHtml(contentResource, {
      nameHtml: this.userFullNameTemplate,
      groupNameHtml: this.groupNameTemplate,
      number: this.params.personCount,
    });
  }
}
