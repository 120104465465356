import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  CertificateDataModel,
  CertificateMappingToAPI,
  CertificateModel,
} from '../certificate.model';

@Injectable()
export class CertificateMapperService {
  constructor(public datePipe: DatePipe) {}

  public toApiParameters(source: CertificateModel): CertificateMappingToAPI {
    const {
      contentTypeId,
      title,
      issuer,
      dateRangeForm,
      skills,
      issuerUrl,
      imageUrl,
      certNumber,
      noExpiration,
      userOutcomeId,
    } = source;

    return {
      contentTypeId: contentTypeId,
      title: title,
      source: issuer,
      details: {
        certNumber: certNumber,
        noExpiration: noExpiration,
      },
      startDate: this.datePipe.transform(dateRangeForm.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(dateRangeForm.endDate, 'yyyy-MM-dd'),
      tags: skills,
      url: issuerUrl,
      imageUrl,
      userOutcomeId,
    };
  }

  public toViewModel(
    sourceObject: CertificateMappingToAPI
  ): CertificateDataModel {
    const {
      contentTypeId,
      title,
      source,
      details,
      startDate,
      endDate,
      tags,
      url,
      imageUrl,
      userOutcomeId,
    } = sourceObject;

    return {
      title,
      issuer: source,
      issuerUrl: url,
      imageUrl,
      certNumber: details.certNumber,
      noExpiration: details.noExpiration,
      dateRangeForm: {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      },
      skills: tags,
      userOutcomeId,
      contentTypeId,
    };
  }
}
