import { Component, OnInit } from '@angular/core';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { PathwayFacade } from '@dg/pathways-rsm';
import { TrackerService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import * as api from '@app/pathways/rsm/pathway-api.model';
import { Pathway } from '@app/pathways/pathway.model';

@Component({
  selector: 'dgx-pathway-content-insights',
  templateUrl: './pathway-content-insights.component.html',
})
export class PathwayContentInsightsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public pathway: Pathway | api.Pathway;
  public breadcrumbs: Breadcrumbs = [];

  constructor(
    private facade: PathwayFacade,
    private trackingService: TrackerService,
    private translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.trackingService.setLocation('Pathway Insights');

    this.pathway = this.facade.snapshot.pathway;

    this.breadcrumbs = this.getBreadcrumbs();
  }

  private getBreadcrumbs(): Breadcrumbs {
    return [
      {
        label: this.pathway?.title,
        routerLink: '../../pathway',
      },
      {
        label: this.translate.instant('Core_Insights'),
        routerLink: '../../insights',
      },
      {
        label: this.translate.instant('Core_Content'),
      },
    ];
  }
}
