import { ElementRef, Inject, Injectable, NgZone } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { InputPermissionsService } from '@app/inputs/services/input-permissions.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { AuthService } from '@app/shared/services/auth.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { GroupService } from '@app/shared/services/group.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { tagHasIncompleteRatingsForType } from '@app/shared/utils/tag-helpers';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TargetService } from '@app/target/services/target.service';
import { TranslateService } from '@ngx-translate/core';
import { EvaluationService } from './evaluation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowToken } from '@app/shared/window.token';

@Injectable({ providedIn: 'root' })
export class TagActionOptionsService {
  constructor(
    private focusStackService: FocusStackService,
    private translateService: TranslateService,
    private tagRatingService: TagRatingService,
    private tagsService: TagsService,
    private authService: AuthService,
    private ngZone: NgZone,
    private notifierService: NotifierService,
    private evaluationService: EvaluationService,
    private modalService: ModalService,
    private groupService: GroupService,
    private targetService: TargetService,
    private inputPermissionsService: InputPermissionsService,
    private router: Router,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  private get i18n() {
    return this.translateService.instant([
      'Core_FindContent',
      'dgTagRating_CancelMgrRatingRequest',
      'dgTagRating_CancelEval',
      'dgTagRating_RestartEval',
      'dgTagRating_MyRatings',
      'Core_Remove',
      'dgActivityDetails_RemoveActivity',
      'dgActivityDetails_RemoveConfirm',
      'dgTagRating_RemoveFromPlan',
      'Ext_AddToPlan',
      'ProfileCtrl_ViewSkillSignals',
    ]);
  }

  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  /**
   * Determine whether the "Find Content" action should be visible
   */
  private get findContentIsVisible(): boolean {
    return (
      !!this.authUser &&
      !this.authUser?.isSkillInventoryClient &&
      !this.authUser?.isSkillAnalyticsClient
    );
  }

  /**
   * Menu option (for `dgxMenu`) to add a skill to a plan
   * @parm item Tag
   * @returns MenuViewModel
   */
  public getAddToPlanMenuOption(item: TagsApi.Tag): MenuViewModel {
    return {
      title: this.i18n.Ext_AddToPlan,
      defaultAction: (_, popoverTrigger) =>
        this.targetService
          .showAddToTargetModal('JobRole', item, popoverTrigger.nativeElement)
          .subscribe(),
      isHidden: () => !this.inputPermissionsService.canAddToTarget('Tag'),
      preventRefocus: true,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to remove item from plan
   * @returns MenuViewModel
   */
  public getRemoveFromPlanMenuOption(
    item: { providerId?: number; resourceId?: number },
    isEditing: boolean,
    removeFn: (id: number) => void
  ): MenuViewModel {
    return {
      title: this.i18n.dgTagRating_RemoveFromPlan,
      defaultAction: () => {
        const { resourceId, providerId } = camelCaseKeys(item);
        removeFn?.(resourceId || providerId);
      },
      isHidden: () => !isEditing,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to find related content for the skill
   * @parm item Tag
   * @returns MenuViewModel
   */
  public getFindContentMenuOption(item: TagsApi.Tag): MenuViewModel {
    return {
      title: this.i18n.Core_FindContent,
      defaultAction: () => {
        this.tagsService.findContentForTag(item);
      },
      isHidden: () => !this.findContentIsVisible,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to cancel Manager Rating request
   * @parm item Tag
   * @returns MenuViewModel
   */
  public getCancelManagerRatingRequestMenuOption(
    item: TagsApi.Tag,
    pendingRating?: Partial<TagsApi.UserTagRatingDetails>
  ): MenuViewModel {
    return {
      title: this.i18n.dgTagRating_CancelMgrRatingRequest,
      defaultAction: (event: Event, target: ElementRef) => {
        this.focusStackService.push(target?.nativeElement);
        this.tagRatingService
          .openCancelManagerRatingRequestModal(event, item)
          .subscribe();
      },
      isHidden: () =>
        !pendingRating &&
        !tagHasIncompleteRatingsForType(
          camelCaseKeys(item),
          InternalTagRatingTypes.manager
        ),
      preventRefocus: true,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to cancel a Skill Review
   * @parm item Tag
   * @returns MenuViewModel
   */
  public getCancelSkillReviewMenuOption(item: TagsApi.Tag): MenuViewModel {
    return {
      title: this.i18n.dgTagRating_CancelEval,
      defaultAction: (event: Event, target: ElementRef) => {
        this.focusStackService.push(target?.nativeElement);
        this.tagRatingService.openCancelSkillReviewModal(event.target, item);
      },
      isHidden: () =>
        !tagHasIncompleteRatingsForType(
          camelCaseKeys(item),
          InternalTagRatingTypes.evaluation
        ),
      preventRefocus: true,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to force start a new Skill Review
   * @parm item Tag
   * @returns MenuViewModel
   */
  public getForceStartSkillReviewMenuOption(
    item: TagsApi.Tag,
    isEvaluable: boolean
  ): MenuViewModel {
    return {
      title: this.i18n.dgTagRating_RestartEval,
      defaultAction: (event: Event, target: ElementRef) => {
        this.focusStackService.push(target?.nativeElement);
        this.tagRatingService.openForceStartSkillReviewModal(
          event.target,
          item
        );
      },
      isHidden: () => {
        const isPending = item.ratings.some(
          (rating) =>
            rating.type === InternalTagRatingTypes.evaluation &&
            !rating.dateCompleted
        );
        const isComplete = item.ratings.some(
          (rating) =>
            rating.type === InternalTagRatingTypes.evaluation &&
            rating.dateCompleted
        );
        return !isEvaluable || !isComplete || isPending;
      },
      preventRefocus: true,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to show Skill Modal
   * @returns MenuViewModel
   */
  public getMyRatingsMenuOption(
    item: TagsApi.Tag,
    activity: any = {},
    skillsRatingsDone: any = () => {}
  ): MenuViewModel {
    return {
      title: this.i18n.dgTagRating_MyRatings,
      defaultAction: (event: Event) => {
        this.tagRatingService
          .openSkillModal(event, item, true)
          .add(skillsRatingsDone);
      },
      isHidden: () => {
        const _resource = item;
        const _activity = activity;
        return (
          item.resourceType !== 'Tag' &&
          activity.details?.resourceType !== 'Tag'
        );
      },
      preventRefocus: true,
    };
  }

  /**
   * Menu option (for `dgxMenu`) to navigate to the skill signal page for the tag
   * @returns MenuViewModel
   */
  public getViewSkillSignalsMenuOption(
    tag: TagsApi.Tag,
    currentRoute: ActivatedRoute
  ): MenuViewModel {
    return {
      title: this.i18n.ProfileCtrl_ViewSkillSignals,
      defaultAction: () => {
        const url = this.router
          .createUrlTree(['../signals'], {
            relativeTo: currentRoute,
            queryParams: { tagId: tag.tagId },
          })
          .toString();
        this.router.navigateByUrl(url);
      },
      // The option to view skill signals only makes sense for tag tiles within a profile, hide for plans, suggestedResources etc
      isHidden: () =>
        tag.signalCount === 0 ||
        !this.windowRef.location.href.includes('/profile/'),
    };
  }

  /**
   * Menu option (for `dgxMenu`) to remove item from group feed
   * @returns MenuViewModel
   */
  public getRemoveFromFeedMenuOption(activity: any): MenuViewModel {
    return {
      title: this.i18n.Core_Remove,
      defaultAction: () => {
        const inputs: SimpleModalInputBindings = {
          bodyText: this.i18n.dgActivityDetails_RemoveActivity,
          submitButtonText: this.i18n.dgActivityDetails_RemoveConfirm,
          canCancel: true,
        };
        this.modalService
          .show(SimpleModalComponent, { inputs })
          .subscribe(() => {
            this.groupService
              .removeFromFeed(activity.activityId)
              .subscribe(() => {
                activity.remove?.();
              });
          });
      },
      isHidden: () => !activity.canRemove,
    };
  }

  /**
   * Performs the action required to start or continue a Skill Review.
   * Can be used in a menu click function or stand alone
   * @param tag
   * @param $target
   */
  public startSkillReviewAction(tag: TagsApi.Tag, $target?: HTMLElement) {
    const evaluationRating = tag.ratings?.find(
      (rating) => rating.type === InternalTagRatingTypes.evaluation
    );
    if (evaluationRating) {
      if (evaluationRating.dateCompleted) {
        this.evaluationService.reviewEvaluationResults(tag.name, tag.tagId);
      } else {
        this.evaluationService.continueEvaluation(tag.name, tag.tagId);
      }
    } else {
      this.tagsService.getAvailableRatingTypes(tag.tagId).subscribe((types) => {
        const canAddEvaluation = types.find(
          (type) => type.name === InternalTagRatingTypes.evaluation
        );
        if (canAddEvaluation) {
          this.evaluationService.forceStartEvaluation(tag, $target);
        } else {
          this.notifierService.showError(
            this.translateService.instant('dgTagRating_InvalidRequest')
          );
        }
      });
    }
  }

  private hasAction(activity: any, type: InternalTagRatingTypes): boolean {
    const _activity = camelCaseKeys(activity);
    return _activity.action === type || _activity.details?.action === type;
  }
}
