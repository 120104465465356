import { Injectable } from '@angular/core';
import { InputDetails } from '@app/inputs/inputs-api.model';
import { Facet } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, repeat, takeWhile, tap } from 'rxjs/operators';
import { ComboboxOption } from '@app/shared/components/combobox/combobox.model';
import { CatalogSearchQueryOpts } from '../components/catalog-search-query-opts';
import { DgError } from '@app/shared/models/dg-error';

export interface BrokenLinksInputDetails extends InputDetails {
  pathwaysCount: number;
  targetCounts: number;
  createdBy: string;
}
export interface BrokenLinksDuration {
  isOrgJobProcessing: boolean;
  estimatedDuration: number; // time in sec
  lastCompletedScanDate?: Date;
}
export type ScannableInputTypes =
  | 'Article'
  | 'Assessment'
  | 'Course'
  | 'Event'
  | 'Episode'
  | 'Video';
export type PublishedDate = '30D' | '6MO' | '1YR';
export interface ScanCatalogParams {
  inputTypes?: ScannableInputTypes[];
  publishedDate?: PublishedDate;
}
export interface ContentSelectType extends ComboboxOption {
  id: ScannableInputTypes;
  title: string;
}
export interface BrokenLinksInputResponse {
  inputs: BrokenLinksInputDetails[];
  statistics?: [];
  facets: Facet[];
  hasMoreItems: boolean;
  total: number;
}
export interface BrokenLinksInputParams {
  terms?: string;
  facets?: Facet[];
  count?: number;
  skip?: number;
  sortBy?: string;
  isAscending?: boolean;
}
export interface BrokenLinksScanResponse {
  jobId: number;
  requestedDate: Date;
  requestedBy: string;
  queueStatus: string;
  isNew: boolean;
}

export interface BrokenLinksScanStatus {
  isScanStarting?: boolean;
  isScanInProgress?: boolean;
  estimatedDuration?: number;
  lastCompletedScanDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class BrokenLinksService {
  private scanStatus$: BehaviorSubject<BrokenLinksScanStatus> =
    new BehaviorSubject<BrokenLinksScanStatus>({
      isScanStarting: false,
      isScanInProgress: false,
    });
  private i18n = this.translateService.instant(['InputsSvc_GeneralError']);

  constructor(
    private http: NgxHttpClient,
    private translateService: TranslateService
  ) {}

  public get scanStatus(): Observable<BrokenLinksScanStatus> {
    return this.scanStatus$.asObservable();
  }

  /**
   * An API call to check
   * 1) if a broken links scan is in progress
   * and
   * 2) an estimate of how long a broken links scan would take with selected parameters
   * @param inputTypes
   * @param publishedDate
   * @preferenceModal true if the call came from preference modal
   * @returns BrokenLinksDuration
   */
  public getBrokenLinksDuration(
    inputTypes?: ScannableInputTypes[],
    publishedDate?: PublishedDate,
    preferenceModal: boolean = false
  ): Observable<BrokenLinksDuration> {
    const params = {
      inputTypes,
      publishedDate,
      prefs: preferenceModal,
    };
    return this.http
      .get<BrokenLinksDuration>('/inputs/GetBrokenUrlsScanDurationAsync', {
        params,
      })
      .pipe(
        tap(
          ({
            isOrgJobProcessing,
            estimatedDuration,
            lastCompletedScanDate,
          }) => {
            this.scanStatus$.next({
              isScanInProgress: isOrgJobProcessing,
              estimatedDuration: estimatedDuration,
              lastCompletedScanDate: lastCompletedScanDate,
            });
          }
        ),
        catchError((error) => {
          throw new DgError(this.i18n.InputsSvc_GeneralError, error);
        })
      );
  }

  /**
   * An API call that starts up a Broken links scan, limited to users with correct permissions
   * If isNew returns false there was already a scan in progress and the other props will relate to that.
   * @param inputTypes
   * @param publishedDate
   * @returns BrokenLinksScanResponse
   */
  public startBrokenLinksScan(
    inputTypes?: ScannableInputTypes[],
    publishedDate?: PublishedDate
  ): Observable<BrokenLinksScanResponse> {
    const params = {
      inputTypes,
      publishedDate,
    };
    return this.http
      .get<BrokenLinksScanResponse>('/inputs/ScanCatalogForBrokenUrlsAsync', {
        params,
      })
      .pipe(
        tap(({ isNew }) => {
          this.scanStatus$.next({ isScanStarting: isNew });
          this.pollTimeRemaining();
        }),
        catchError((error) => {
          throw new DgError(this.i18n.InputsSvc_GeneralError, error);
        })
      );
  }

  /**
   * API call to fetch a list of inputs with broken links as a result from broken links scan
   * @param BrokenLinksInputParams
   * @returns BrokenLinksInputResponse
   */
  public getBrokenLinksInput(
    params: CatalogSearchQueryOpts
  ): Observable<BrokenLinksInputResponse> {
    return this.http
      .get<BrokenLinksInputResponse>(
        '/inputs/findCatalogContentWithBrokenUrls',
        {
          params: {
            terms: params.terms,
            tags: params.tags.join(),
            facets: JSON.stringify(params.facets),
            skip: params.skip,
            count: params.count,
            includeProviders: params.includeProviders,
            defaults: params.defaults,
            includeCompleted: params.includeCompleted,
            sortBy: params.sortBy,
            isAscending: params.isAscending,
            exclusionList: JSON.stringify(params.exclusionList),
            useResourceImages: true,
          },
        }
      )
      .pipe(
        catchError((error) => {
          throw new DgError(this.i18n.InputsSvc_GeneralError, error);
        })
      );
  }

  /**
   * Removes the Broken Url status from a list of inputs
   * @param inputIds list of input Id's to clear
   */
  public clearBrokenLinkStatus(
    inputsToClean: BrokenLinksInputDetails | BrokenLinksInputDetails[]
  ): Observable<void> {
    let inputs = [];
    if ((inputsToClean as BrokenLinksInputDetails[])?.length) {
      inputsToClean = [inputsToClean as BrokenLinksInputDetails];
    }
    const { inputId, inputType, ..._ } =
      inputsToClean as BrokenLinksInputDetails;
    inputs.push({ inputId: inputId, inputType: inputType });

    return this.http
      .post<void>('/inputs/clearContentBrokenUrlStatus', inputs)
      .pipe(
        catchError((error) => {
          throw new DgError(this.i18n.InputsSvc_GeneralError, error);
        })
      );
  }

  /**
   * Method that kick-starts the polling to check the scan status
   */
  public pollTimeRemaining() {
    const poll = this.getBrokenLinksDuration().pipe(
      repeat({ delay: 10000 }),
      takeWhile(({ isOrgJobProcessing }) => isOrgJobProcessing)
    );

    poll.subscribe(
      ({ isOrgJobProcessing, estimatedDuration, lastCompletedScanDate }) => {
        return this.scanStatus$.next({
          isScanInProgress: isOrgJobProcessing,
          estimatedDuration: estimatedDuration,
          lastCompletedScanDate: lastCompletedScanDate,
        });
      }
    );
  }
}
