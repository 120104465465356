<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!articleGlobalAddForm"
    #formRef="ngForm"
    class="reactive-forms"
    [class.input-success-kickstart]="vm.shouldShowResults$ | async"
    [formGroup]="articleGlobalAddForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.MediaFormCtrl_SaveArticle
      "
      [useDefaultSubmitButton]="!showExpandedForm"
      [canCancel]="!(vm.shouldShowResults$ | async)"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      [bodyClasses]="{
        'guts-p-full-2': true,
        'input-success-kickstart': (vm.shouldShowResults$ | async),
        first: true,
        second: true,
        third: false
      }"
      (dismiss)="onDismiss($event)"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <div class="input-success-wrap">
          <!-- URL input-->
          <div
            *ngIf="articleGlobalAddForm.get('entryUrl')"
            class="reactive-forms__control"
          >
            <dgx-reactive-forms-label for="entryUrl" [isRequired]="true">
              {{ i18n.MediaFormCtrl_ArticleUrl }}
            </dgx-reactive-forms-label>
            <input
              type="url"
              id="entryUrl"
              formControlName="entryUrl"
              placeholder="http://"
              [dgxAutofocus]="true"
              autocomplete="off"
              data-dgat="article-global-add-bb9"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                articleGlobalAddForm.get('entryUrl')?.dirty &&
                articleGlobalAddForm.get('entryUrl').invalid
              "
              [message]="i18n.MediaFormCtrl_UrlRequired"
            ></dgx-validate-field>
          </div>

          <!-- Expanded Form -->
          <ng-container *ngIf="showExpandedForm">
            <!-- Title -->
            <div class="reactive-forms__control">
              <dgx-reactive-forms-label for="title" [isRequired]="true">
                {{ i18n.Core_Title }}
              </dgx-reactive-forms-label>
              <input
                type="text"
                id="title"
                formControlName="title"
                [placeholder]="i18n.dgFlexRow_EditSectionTitlePlaceholder"
                (blur)="
                  vm.loadInferredSkills(
                    articleGlobalAddForm.get('title').value,
                    articleGlobalAddForm.get('description').value
                  )
                "
                data-dgat="article-global-add-08c"
              />
              <dgx-validate-field
                class="block"
                *ngIf="
                  articleGlobalAddForm.get('title').touched &&
                  articleGlobalAddForm.get('title').invalid
                "
                [message]="i18n.MediaFormCtrl_TitleRequired"
              ></dgx-validate-field>
            </div>

            <!-- Source -->
            <ng-container *ngIf="vm.sourceName">
              <div class="reactive-forms__control reactive-forms__read-only">
                <dgx-reactive-forms-label for="sourceName">
                  {{ i18n.Core_Source }}
                </dgx-reactive-forms-label>
                <input
                  id="sourceName"
                  formControlName="sourceName"
                  readonly="true"
                  data-dgat="article-global-add-086"
                />
              </div>
            </ng-container>

            <!-- Description -->
            <div
              *ngIf="articleGlobalAddForm.get('description')"
              class="reactive-forms__control"
            >
              <dgx-reactive-forms-label for="description">
                {{ i18n.Core_Description }}
              </dgx-reactive-forms-label>
              <textarea
                id="description"
                formControlName="description"
                [attr.maxlength]="3000"
                [attr.aria-label]="i18n.Core_Description"
                [attr.placeholder]="
                  i18n.dgOrgInternalContentForm_ArticleDescriptionPlaceholder
                "
                (blur)="
                  vm.loadInferredSkills(
                    articleGlobalAddForm.get('title').value,
                    articleGlobalAddForm.get('description').value
                  )
                "
                data-dgat="article-global-add-7ec"
              ></textarea>
            </div>

            <!-- Comment -->
            <div
              *ngIf="articleGlobalAddForm.get('comment')"
              class="reactive-forms__control"
            >
              <dgx-reactive-forms-label
                for="comment"
                icon="info"
                [iconTooltip]="i18n.dgOrgInternalContentForm_CommentTooltip"
                [iconA11yText]="i18n.Core_MoreInfo"
              >
                {{ i18n.Core_Comment }}
              </dgx-reactive-forms-label>
              <textarea
                id="comment"
                formControlName="comment"
                [attr.placeholder]="
                  'Core_InputCommentPlaceholder'
                    | translate: { inputType: 'article' }
                "
                [attr.maxlength]="3000"
                [attr.aria-label]="i18n.Core_Comment"
                data-dgat="article-global-add-c6e"
              ></textarea>
            </div>

            <!-- Duration -->
            <div class="reactive-forms__control">
              <dgx-duration
                [formModel]="{
                  durationHours: vm.durationHours,
                  durationMinutes: vm.durationMinutes
                }"
              ></dgx-duration>
            </div>

            <!-- Content Owner -->
            <div class="form-wrap__group">
              <dgx-reactive-forms-label
                for="owner"
                icon="info"
                [iconTooltip]="
                  i18n.dgOrgInternalContentForm_ContentOwnerTooltip
                "
                [iconA11yText]="i18n.Core_MoreInfo"
              >
                {{ i18n.dgOrgInternalContentForm_ContentOwner }}
              </dgx-reactive-forms-label>
              <dgx-user-search
                id="owner"
                [useSearchField]="false"
                [recipients]="!!vm.owner ? [vm.owner] : []"
                [search]="userGroupListService.loadGroupsUsersRecommendees"
                [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
                [allowRemoval]="true"
                (removeRecipient)="onContentOwnerChange(undefined)"
                (selectRecipient)="onContentOwnerChange($event)"
                [placeholder]="
                  i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder
                "
              ></dgx-user-search>
              <dgx-validate-field
                class="block"
                *ngIf="
                  articleGlobalAddForm.get('owner').touched &&
                  articleGlobalAddForm.get('owner').invalid
                "
                [message]="i18n.dgOrgInternalContentForm_ContentOwnerRequired"
              ></dgx-validate-field>
            </div>

            <!-- Image -->
            <div class="reactive-forms__control">
              <dgx-upload-section
                [imageUrl]="vm.imageUrl"
                [uploadAdapter]="vm.imageUploadAdapter"
                (imageParsedEvent)="vm.imageUpload.onParse($event)"
                (uploadSuccessEvent)="
                  vm.imageUpload.onUpload(articleGlobalAddForm, $event)
                "
                (deleteEvent)="vm.imageUpload.onDelete(articleGlobalAddForm)"
                [useCropper]="true"
                [aspectRatio]="16 / 9"
                [secondaryAspectRatio]="16 / 5"
                [useBrowserReplace]="true"
              >
              </dgx-upload-section>
            </div>

            <!-- Skills -->
            <div class="reactive-forms__control">
              <dgx-tags-edit
                [hideHint]="true"
                [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
                [customTitle]="i18n.Core_Skills"
                [topTags]="vm.mediumConfidenceInferredSkills"
                [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
                [hideTooltip]="false"
                [tooltipIcon]="'info'"
                [placeholder]="
                  vm.highConfidenceInferredSkills
                    ? i18n.dgOrgInternalContent_SkillsPlaceholderText
                    : ''
                "
                [allowExistingOnly]="false"
                [tags]="articleGlobalAddForm.get('skills').value"
                [showFullWidthLabel]="true"
                [useNewTagsDesign]="true"
                (tagsChange)="onFormControlUpdate('skills', $event)"
              ></dgx-tags-edit>
              <dgx-validate-field
                class="block"
                *ngIf="
                  articleGlobalAddForm.get('skills').touched &&
                  articleGlobalAddForm.get('skills').invalid
                "
                [message]="i18n.dgOrgInternalContent_SkillsMaxError"
              ></dgx-validate-field>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Modal footer -->
      <ng-container class="modal-footer">
        <dgx-global-add-results
          *ngIf="!vm.isInitialForm"
          [class.full-width]="vm.shouldShowResults$ | async"
          [buttonText]="
            vm.isInitialForm ? i18n.Core_Next : i18n.MediaFormCtrl_SaveArticle
          "
          [isEditing]="false"
          [isCompleting]="true"
          [isNewbUser]="vm.isNewbUser$ | async"
          [submissionStatus]="vm.submissionStatus$ | async"
          [creationFeedback]="vm.submissionResult"
          [classifier]="'learning'"
          [showCancelButton]="false"
          [addSpacing]="false"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </ng-container>
    </dgx-modal>
  </form>
</ng-container>
