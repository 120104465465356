<div class="group-control">
  <label
    for="group-search-input"
    class="par par--small guts-m-b-half"
    *ngIf="!hideLabel"
    >{{ completing ? groupDefaultLabel : customTitle || groupDefaultTitle
    }}<span *ngIf="required">*</span>
    <span
      *ngIf="!hideTooltip"
      class="par--light"
      aria-label="groupTooltip"
      [ngbTooltip]="groupTooltip"
      tabindex="0"
      tooltip-trigger="'focus mouseenter'"
      tooltip-append-to-body="true"
    >
      <df-icon
        size="medium"
        icon="question-mark-circle"
        class="v-top guts-m-l-quart"
      ></df-icon>
    </span>
  </label>
  <div class="m-guts-p-t-1">
    <dgx-group-search
      (onGroupSelection)="addGroup($event)"
      (onGroupRemove)="deselectGroup($event)"
      [groups]="groups"
      [groupPrivacyLevel]="groupPrivacyLevel"
      [excludedPrivacyLevels]="excludedPrivacyLevels"
    ></dgx-group-search>
  </div>
</div>
