import { ComboboxOption } from '@app/shared/components/combobox/combobox.model';
import { PredicateType } from './automations.api';

export const enum BusinessRuleType {
  BusinessRule = 0,
  Segment = 1,
  Engage = 2,
}

export enum ActiveType {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Draft = 'Draft',
}

export interface BusinessRule {
  id: string;
  organizationId: number;
  ruleName: string;
  description?: string;
  orgRuleSort: number;
  activeType?: ActiveType;
  createDate: Date;
  lastModifiedDate: Date;
  businessRuleType: BusinessRuleType;
  predicates: Predicate[];
  outcomes?: Outcome[];
  predicateOperators: PredicateOperator[];
  hasError?: boolean;
  trigger?: Trigger;
  isSelected?: boolean;
}

export interface Trigger {
  id?: string;
  businessRuleId?: string;
  triggerType: TriggerType;
  userActivity?: string; // name of event
  resourceFieldName?: string; // name of resource identifier.  i.e. PathwayId
  resourceValue?: number | string; // id of the resource
  resourceFieldNameSecondary?: string;
  resourceValueSecondary?: string;
  schedule?: TriggerSchedule;
  displayResourceValue?: string;
}

export const enum TriggerType {
  UserRecordReceived = 'UserRecordReceived',
  UserCreated = 'UserCreated',
  UserActivity = 'UserActivity',
  Scheduled = 'Scheduled',
}

export interface TriggerSchedule {
  id?: string;
  triggerId?: string;
  scheduleType: TriggerScheduleType; // Daily, Weekly, Monthly, Once
  day?: number; // 1 (Sunday) -> 7 (Saturday) if schedule type is weekly.  1, 5, 10, 15, 20, 25, -1 (last day of month) if schedule type is monthly
  executionTime: string; // Time ( HH:MM ) at which the automation will run
  specifiedDate?: Date; // Specific date automation will run if schedule type is Once
}

export enum TriggerScheduleType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Once = 'Once',
}

export interface Predicate {
  id?: string;
  businessRuleId?: string;
  fieldName?: string;
  comparisonOperator?: string;
  comparisonValue?: (string | number | Date)[];
  internalPredicateName?: string;
  academyName?: string;
  userAcademyStatus?: string[];
  academyId?: string;
  skillName?: string[];
  timeFrame?: number;
  predicateType: PredicateType;
  hasError?: boolean;
}

export interface Outcome {
  id: string;
  businessRuleId: string;
  updateTypeResourceId: string;
  updateValue: string;
  emailTemplate?: EmailTemplate;
  displayName?: string;
  hasError?: boolean;
  parameters?: OutcomeParameters;
  fromFullName?: string; // only used for assign/recommend if user is selected
}

// this object is a dynamic object on the BE, and is case sensitive.  Pascal casing is required since serializer settings don’t apply to dynamic objects and the json is case sensitive
export interface OutcomeParameters {
  DueDate?: Date; // Date assignment is due
  DateUnit?: number; // number of days/weeks/months/years if the due date is relative
  DateUnitType?: DateUnitType; // only populated for relative dates
  ResourceType?: string; // IE: Pathway, Target, Article, Video, Book, etc...
  Id?: string; // ID of the resource
  RecommendationType?: 'Assigned' | 'Recommended';
  RecommendingUser?: number; // UPK of the user who is recommending or assigning the resource
  DueDateType?: DueDateType;
  Subject?: string; // subject of a notification
  Details?: string; // deatils (body) of a notification
  DevelopPermissionRole?: string; // used for ACM
  IsDelete?: boolean; // used for ACM
  IsDisableLogin?: boolean; // used for ACM
}

export enum DateUnitType {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export interface PredicateOperator {
  id: string;
  businessRuleId: string;
  firstPredicateId: string;
  secondPredicateId: string;
  sortOrder: number;
  firstPredicate: Predicate;
  secondPredicate: Predicate;
  operator: string;
}

export interface EmailTemplate {
  id?: string;
  emailSubject: string;
  emailContent: string;
  blobUrl?: string;
}

export interface Attribute {
  attributeName: string;
  attributeDataType: AttributeDataType;
  isVisibleUI?: boolean;
}

export enum DueDateType {
  None = 'None',
  Relative = 'Relative',
  Specific = 'Specific',
}

export enum AttributeDataType {
  String = 'String',
  Number = 'Number',
  DateTime = 'DateTime',
  Bool = 'Bool',
}

export enum StaticOutcomes {
  SEND_EMAIL = 'SendEmail',
  DELETE_USER = 'DeleteUser',
  SHARE_RESOURCE = 'ShareResource',
  ASSIGN_RESOURCE = 'AssignResource',
  NOTIFY_USER = 'NotifyUser',
  ACM_ADD_USER = 'AcmAddUser',
}

export interface PredicateOption extends ComboboxOption {
  title: string;
  attributeDataType?: AttributeDataType;
}

export interface EngageEvent {
  eventName: string;
  id: number;
  resourceFieldName: string;
  associatedResourceType: string;
}

export interface Action {
  id: number;
  name: string;
  associatedResourceType: string;
}
