import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dgx-setting-tile-header',
  templateUrl: './setting-tile-header.component.html',
})
export class SettingTileHeaderComponent {
  @Input() public name: string;
  @Input() public description: string;
  @Input() public enabled: boolean;
  @Output() public toggled = new EventEmitter();

  public handleSettingToggled(isEnabled) {
    this.toggled.emit(isEnabled);
  }
}
