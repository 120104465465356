import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  NgbActiveModal,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CoursePathwaysPlansFacade } from '../../services/pathways-and-plans/course-pathways-plans.facade.service';

// misc
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils';
import {
  markFormAsTouched,
  onFormControlReset,
  onFormControlUpdate,
  showError,
} from '@app/shared/utils/angular-form-field-helpers';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import {
  handleFocusOnSubmit,
  inputFormatter,
  onChangeOfAddCourseToCatalogValue,
  pathPlanHideUrlField,
} from '@app/user-content/user-input-v2/utils/form-field-helper';
import { pathPlanEditContentNotification } from '@app/user-content/user-input-v2/utils/modal-helper';
import {
  atLeastOneRequiredValidator,
  contentOwnerIdValidator,
  maxFifteenSkillsValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import { notNullish } from '@app/utils';
import { NotificationType } from '@lib/fresco';
import { CourseModel, CourseTypeId, courseTypes } from '../../course.model';
import { PathwayStep } from '@dg/pathways-rsm';

@Component({
  selector: 'dgx-course-pathways-plans',
  templateUrl: './course-pathways-plans.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for additional styles
  styleUrls: ['../course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePathwaysPlansComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @Input() public pathwayStep?: PathwayStep;
  @ViewChild('form') public formRef: ElementRef<HTMLElement>;
  @ViewChild('courseUrl') public courseUrlRef: ElementRef<HTMLElement>;

  public i18n = this.translate.instant([
    'Core_GeneralErrorMessage',
    'Core_Next',
    'Core_Or',
    'Core_Required',
    'CourseFormCtrl_AddCourse',
    'CourseFormCtrl_Accredited',
    'CourseFormCtrl_Provider',
    'CourseFormCtrl_CourseUrl',
    'CourseFormCtrl_CourseUrlAria',
    'CourseFormCtrl_HowAdded',
    'CourseFormCtrl_ProviderName',
    'CourseFormCtrl_SelectProvider',
    'CourseFormCtrl_Provider_Placeholder',
    'CourseFormCtrl_EditCourse',
    'CourseFormCtrl_SaveCourse',
    'CourseFormCtrl_UrlOrSelectionRequired',
    'MediaFormCtrl_SelectionRequired',
    'MediaFormCtrl_UrlRequired',
    'dgOrgInternalContentForm_CourseDescriptionPlaceholder',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'Core_MoreInfo',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'MediaFormCtrl_ValidContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContent_SkillsTooltipText',
    'Core_Skills',
    'MediaFormCtrl_Skills_Placeholder',
    'dgOrgInternalContent_SkillsMaxError',
    'CourseFormCtrl_SelectCourse',
    'MediaFormCtrl_Title_Placeholder',
    'MediaFormCtrl_TitleRequired',
    'Core_Title',
    'Core_Description',
    'Core_OrgManagedDocLinkLabel',
  ]);
  public vm$: Observable<CourseModel>;
  public coursePathwaysPlansForm: FormGroup;
  public heading: string;
  public source = courseTypes[1];
  public addingByUrl = false;
  public readonly NotificationType = NotificationType;

  // ***************************
  // GETTERS -------------------
  // Form values
  // ***************************

  public get addToCatalog(): boolean {
    return this.coursePathwaysPlansForm.get('addToCatalog')?.value;
  }

  public get courseTypeId(): CourseTypeId {
    return this.coursePathwaysPlansForm.get('courseTypeId')?.value;
  }

  public get owner(): AnyRecommendee {
    return this.coursePathwaysPlansForm.get('owner')?.value;
  }

  // ***************************
  // GETTERS -------------------
  // Translated Strings
  // ***************************

  public get addToCatalogCheckboxLabel(): string {
    return this.translate.instant('CourseFormCtrl_AddToCatalogFormat', {
      orgName: this.facade.orgName,
    });
  }

  public get editContentNotificationText(): string {
    return pathPlanEditContentNotification(
      this.isEditingInternalContent,
      this.translate
    );
  }

  // ***************************
  // GETTERS -------------------
  // Context Properties
  // ***************************

  public get isCatalogContent(): boolean {
    return this.facade.snapshot.inputContext.isEditing
      ? !!this.facade.snapshot.inputContext.isCmsContent
      : this.addToCatalog;
  }

  public get isEditingInternalContent(): boolean {
    return (
      this.facade.snapshot.inputContext.isEditingInternalContent &&
      this.facade.snapshot.inputContext.isCmsContent
    );
  }

  public get isEditingLocalContent(): boolean {
    return (
      !this.facade.snapshot.inputContext.isEditingInternalContent &&
      this.facade.snapshot.inputContext.isEditing
    );
  }

  public get isOwnerRequired(): boolean {
    return (
      this.addToCatalog ||
      this.facade.snapshot.inputContext.isEditingInternalContent
    );
  }

  // ***************************
  // GETTERS -------------------
  // Show/Hide properties
  // ***************************

  /**
   * Whether to show the advanced settings block or not.
   */
  public get showAdvancedSettings(): boolean {
    return this.facade.snapshot.inputContext.isEditingInternalContent;
  }

  /**
   * Currently for local edit there are properties that we can not edit (PD-95879),
   * If the input has not value and we are editing locally in the pathway|plan
   * do not show the input field. (Content Owner, duration)
   */
  public get showOwnerField(): boolean {
    return !this.isEditingLocalContent || !!this.owner;
  }

  /**
   * Currently for local edit there are properties that we can not edit (PD-95879),
   * If the input has not value and we are editing locally in the pathway|plan
   * do not show the input field. (Content Owner, duration)
   */
  public get showDurationField(): boolean {
    return (
      !this.isEditingLocalContent ||
      !!this.facade.snapshot.durationHours ||
      !!this.facade.snapshot.durationMinutes
    );
  }

  /**
   * Whether to show the courseUrl field or not.
   */
  public get hideUrlField(): boolean {
    return pathPlanHideUrlField(
      this.isCatalogContent,
      this.facade.snapshot.inputContext.isEditing,
      this.facade.snapshot.hostedContentDetails
    );
  }

  // ***************************
  // GETTERS -------------------
  // Error messages
  // ***************************

  /**
   * Change which error message to show based on which error is present.
   * The template checks to make sure there IS an error, so we don't need
   * to do that again here.
   */
  public get ownerErrorMessage(): string {
    return this.coursePathwaysPlansForm
      .get('owner')
      .hasError('validContentOwnerNeeded')
      ? this.i18n.MediaFormCtrl_ValidContentOwnerRequired
      : this.i18n.dgOrgInternalContentForm_ContentOwnerRequired;
  }

  /**
   * Calculate the correct error message to display.
   *
   * There is no need for a separate error message or field for our courseTypeId,
   * because if only that field is showing, by definition there are no errors.
   */
  public get urlErrorMessage(): string {
    // WHEN the form is pristine, bail.
    if (
      !this.coursePathwaysPlansForm.dirty ||
      !this.coursePathwaysPlansForm.invalid
    ) {
      return '';
    }
    // OTHERWISE, grab our courseUrl field.
    const field = this.coursePathwaysPlansForm.get('courseUrl');
    // WHEN we're on the first page and the courseUrl field is dirty and the
    // atLeastOneRequired error exists, show that error. (Field will be made
    // dirty by attempting to submit the form.)
    if (
      this.facade.snapshot.isInitialForm &&
      field.dirty &&
      this.coursePathwaysPlansForm.hasError('atLeastOneRequired')
    ) {
      return this.i18n.CourseFormCtrl_UrlOrSelectionRequired;
    }
    // WHEN field is not invalid, bail.
    if (!field.invalid) {
      return;
    }
    // OTHERWISE, something must be wrong with the URL. The link is either broken...
    const urlIsBroken = this.coursePathwaysPlansForm
      .get('courseUrl')
      .getError('urlBrokenValidation');
    if (!!urlIsBroken) {
      return urlIsBroken;
    }
    // ...or simply invalid.
    return this.i18n.MediaFormCtrl_UrlRequired;
  }

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private facade: CoursePathwaysPlansFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    this.facade.initializeViewModel(this.context);

    this.heading = this.facade.snapshot.inputContext.isEditing
      ? this.i18n.CourseFormCtrl_EditCourse
      : this.i18n.CourseFormCtrl_AddCourse;
    // WHEN we aren't editing
    if (!this.facade.snapshot.inputContext.isEditing) {
      // Set up the initial form
      this.initializeForm();
      return;
    }
    // OTHERWISE, set up the edit form.
    await this.facade.initializeEdit(
      this.isEditingLocalContent,
      this.pathwayStep
    );
    this.initializeExpandedForm();
  }

  // ***************************
  // PUBLIC -------------------
  // Form submission actions
  // ***************************

  public async onNext(form: FormGroupDirective) {
    markFormAsTouched(this.coursePathwaysPlansForm);
    if (this.coursePathwaysPlansForm.invalid) {
      handleFocusOnSubmit(this.formRef.nativeElement);
      return;
    }

    await this.facade.onNext(
      this.coursePathwaysPlansForm.get('courseUrl').value,
      this.courseTypeId
    );

    // reset the submitted state on the form
    form.resetForm(this.coursePathwaysPlansForm.value);
    // initialize expanded form
    this.initializeExpandedForm();
  }

  public async onSubmit() {
    markFormAsTouched(this.coursePathwaysPlansForm);
    // WHEN content is being added to or edited within the catalog...
    if (
      (this.isCatalogContent &&
        this.facade.snapshot.inputContext.isEditingInternalContent) ||
      (this.isCatalogContent && !this.facade.snapshot.inputContext.isEditing)
    ) {
      // THEN check for duplicate URLs.
      const courseUrlValue = this.coursePathwaysPlansForm.value.courseUrl;
      const orgId =
        this.facade.snapshot.inputContext.organizationId ?? this.facade.orgId;
      await this.facade.fetchUrlDuplicates(courseUrlValue, orgId);
      // WHEN there are duplicates...
      if (this.facade.snapshot.duplicateCount > 0) {
        // THEN show duplicate warning and focus field.
        this.coursePathwaysPlansForm.get('courseUrl').markAsTouched();
        // NOTE: The duplicate count specific ng-invalid class doesn't get set quickly enough
        // to rely on that, so we *must* focus the courseUrl field manually when it's the first
        // child with an error.
        // It will always be first when addingByUrl. It will also be first if Provider Name and
        // Course Name are valid.
        if (
          this.addingByUrl ||
          (!this.coursePathwaysPlansForm.get('institutionName').invalid &&
            !this.coursePathwaysPlansForm.get('name').invalid)
        ) {
          this.courseUrlRef.nativeElement.focus();
        }
        // Otherwise, we should focus one of those two fields instead.
        else {
          handleFocusOnSubmit(this.formRef.nativeElement);
        }
        return;
      }
    }

    // All other error checking. *After* the catalog check, othewise that check
    // will only be done the first time the form is submitted.
    if (this.coursePathwaysPlansForm.invalid) {
      handleFocusOnSubmit(this.formRef.nativeElement);
      return;
    }

    await this.facade.onSubmit(
      this.coursePathwaysPlansForm,
      this.isEditingLocalContent,
      this.pathwayStep
    );

    // TODO: We shouldn't return the entire snapshot here. Update
    // mapperService.toStep so that it *only* returns a pathway step,
    // then perhaps add a wrapper to the facade to call that method,
    // and make this something like:
    // this.activeModal.close(this.facade.toStep(this.pathwayStep));
    // ...which might be null, and the method will have to account
    // for that, but that way we won't be adding a ton of properties
    // to a payload that's eventually used for updatePathwayNode.
    this.activeModal.close({
      ...this.facade.snapshot,
      title: this.facade.snapshot.name,
    });
  }

  // ***************************
  // PUBLIC -------------------
  // Event actions from the UI
  // ***************************

  public onFormControlReset(fields: string | string[]): void {
    onFormControlReset(this.coursePathwaysPlansForm, fields);
  }

  public onFormControlUpdate(
    fields: string | string[],
    values: any | any[]
  ): void {
    onFormControlUpdate(this.coursePathwaysPlansForm, fields, values);
  }

  public onContentOwnerChange(value?: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  /**
   * Wrapper for vm.onCourseTypeChange, first sanity-checking to be sure
   * we don't already have this courseTypeId set.
   *
   * @param courseTypeId
   */
  public onCourseTypeChange(courseTypeId?: CourseTypeId) {
    // In this case, we want to *unset* courseTypeId on a second button click.
    if (this.courseTypeId === courseTypeId) {
      courseTypeId = undefined;
    }
    // Update form.
    this.onFormControlUpdate('courseTypeId', courseTypeId);
    this.facade.onCourseTypeChange(courseTypeId);

    // If courseTypeId isn't nullish, void courseUrl.
    if (
      notNullish(courseTypeId) &&
      !!this.coursePathwaysPlansForm.get('courseUrl')?.value
    ) {
      this.onFormControlReset('courseUrl');
    }
  }

  /**
   * When a search term is entered into the provider input.
   *
   * @param term
   */
  public onProviderSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> =>
    this.facade.onProviderSearch(term);

  /**
   * When a result is selected from the provider Tyepahead list.
   *
   * @param term
   */
  public async onProviderSelect(
    event: NgbTypeaheadSelectItemEvent<AutocompleteItem>
  ) {
    await this.facade.onProviderSelect(event, this.coursePathwaysPlansForm);
  }

  /**
   * When the provider input loses focus. We want to set providers here,
   * too, to allow for custom text input.
   *
   * @param term
   */
  public onProviderSet(): void {
    this.facade.onProviderSet(
      this.coursePathwaysPlansForm.get('institutionName')?.value,
      this.coursePathwaysPlansForm
    );
  }

  /**
   * When a search term is entered into the course title input.
   *
   * @param term
   */
  public onCourseNameSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> =>
    this.facade.onCourseNameSearch(term);

  /**
   * When a result is selected from the course title Tyepahead list.
   *
   * @param term
   */
  public async onCourseNameSelect(
    event: NgbTypeaheadSelectItemEvent<AutocompleteItem>
  ) {
    await this.facade.onCourseNameSelect(event, this.coursePathwaysPlansForm);
  }

  /**
   * When the course title input loses focus. We want to set titles here,
   * too, to allow for custom text input.
   */
  public onCourseNameSet(): void {
    this.facade.onCourseNameSet(
      this.coursePathwaysPlansForm.get('name')?.value,
      this.coursePathwaysPlansForm
    );
  }

  /**
   * When the *simple* course title input loses focus.
   */
  public onCourseNameSetSimple(): void {
    this.facade.onCourseNameSimpleSet(
      this.coursePathwaysPlansForm.get('name')?.value
    );
  }

  // ***************************
  // PUBLIC -------------------
  // Misc
  // ***************************

  /**
   * Formats ngbTypeahead results when selected, so that the form
   * displays the actual *name* of a provider instead of [Object, object].
   * Shell for our default inputFormatter, which has 'label' as its prop,
   * shared by the results we get back for both of our search inputs.
   *
   * @param result
   */
  public inputFormatter(result: AutocompleteItem): string {
    return inputFormatter(result);
  }

  /**
   * When the URL has duplicates we display a message with the option to view the duplicates in a modal
   * Open the view duplicates modal
   */
  public openViewDuplicates(): void {
    this.facade.viewDuplicates();
  }

  public showError(fieldName: string): boolean {
    return showError(this.coursePathwaysPlansForm, fieldName);
  }

  // ***************************
  // PRIVATE -------------------
  // Form initializations
  // ***************************

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.coursePathwaysPlansForm = this.formBuilder.group(
      {
        courseTypeId: [undefined],
        courseUrl: ['', [Validators.pattern(HTTP_REQUIRED_URL_PATTERN)]],
      },
      {
        validator: atLeastOneRequiredValidator(['courseUrl', 'courseTypeId']),
      }
    );
  }

  /**
   * Load the second page of the form.
   */
  private initializeExpandedForm(): void {
    // Are we adding by URL or provider?
    this.addingByUrl = !notNullish(this.facade.snapshot.courseTypeId);
    // Is content owner required?
    const ownerValidation = this.isEditingInternalContent
      ? [Validators.required, contentOwnerIdValidator]
      : [contentOwnerIdValidator];
    // How do we validate URLs?
    const urlValidation =
      this.hideUrlField || this.isEditingLocalContent
        ? // Not at all, when editing locally. The field is either hidden or readonly.
          []
        : // Do we *require* course URL? Only for catalog content.
          this.addingByUrl || this.isCatalogContent
          ? [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)]
          : // Otherwise, when adding by provider, we just make sure it's a valid entry.
            [Validators.pattern(HTTP_REQUIRED_URL_PATTERN)];
    // WHEN EDITING, show tags that were saved. Inferred skills aren't working yet.
    const tags = !this.facade.snapshot.inputContext.isEditing
      ? this.facade.snapshot.highConfidenceInferredSkills
      : this.facade.snapshot.tags;

    // Set up our extended form.
    this.coursePathwaysPlansForm = this.formBuilder.group({
      addToCatalog: [this.facade.snapshot.addToCatalog ?? false],

      institutionName: [this.facade.snapshot.institutionName ?? ''],

      name: [
        this.facade.snapshot.name ?? '',
        [Validators.required, isEmptyValidator],
      ],

      description: [this.facade.snapshot.description],

      courseUrl: [this.facade.snapshot.courseUrl, urlValidation],

      owner: [this.facade.snapshot.owner, ownerValidation],

      image: [this.facade.snapshot.imageUrl],

      tags: [tags?.length ? tags : [], maxFifteenSkillsValidator],
    });

    // Subscribe to value changes on the courseURL to reset the duplicates error
    this.coursePathwaysPlansForm
      .get('courseUrl')
      ?.valueChanges.subscribe(() => this.facade.resetDuplicates());

    if (
      this.facade.snapshot.canManageContent &&
      !this.facade.snapshot.inputContext.isEditing
    ) {
      // Subscribe to changes on the addToCatalog checkbox to change owner validation.
      this.coursePathwaysPlansForm
        .get('addToCatalog')
        .valueChanges.subscribe((isCatalogContent) =>
          onChangeOfAddCourseToCatalogValue(
            isCatalogContent,
            this.coursePathwaysPlansForm,
            this.facade
          )
        );
    }
  }
}
