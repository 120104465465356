import { CommonModule } from '@angular/common';
import { DfIconModule } from '../../../../icon';
import { NgModule } from '@angular/core';

import { DfButtonActionComponent } from './button-action.component';

@NgModule({
  imports: [CommonModule, DfIconModule],
  exports: [DfButtonActionComponent],
  declarations: [DfButtonActionComponent],
  providers: [],
})
export class DfButtonActionModule {}
