<ng-container *ngIf="!disabled">
  <section>
    <ng-content select="header"></ng-content>

    <div class="guts-p-t-1">
      <div
        *ngIf="canManage && isOrgContent"
        class="guts-p-b-1-half guts-p-r-1-half l_flex--align-right"
      >
        <button
          df-button
          dfButtonType="primary"
          (click)="newLink()"
          data-dgat="trackingLinks-f42"
        >
          {{ i18n.TrackableLinkCtrl_NewLink }}
        </button>
      </div>
      <dgx-data-table
        [columns]="columns"
        [items]="links"
        [orderBy]="currentSortBy"
        [menuSettings]="menuSettings"
        (updateSort)="sort($event)"
      ></dgx-data-table>
    </div>
  </section>
</ng-container>

<!-- Custom column template-->
<ng-template #linkCreator let-item> {{ item.creator?.name }} </ng-template>

<ng-template #linkCreateDate let-item>
  {{ item.created | date: 'shortDate' }}
</ng-template>

<ng-template #copyLinkButton let-item>
  <button
    *ngIf="canManage && canCopyToClipboard"
    class="beta"
    type="button"
    [attr.aria-label]="getToolTip(item.tag)"
    data-dgat="trackingLinks-6b9"
    (click)="copyLink(item.tag)"
    [ngbTooltip]="getToolTip(item.tag)"
  >
    <df-icon icon="chain" class="color-ebony-a61"></df-icon>
  </button>
</ng-template>
