import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '@app/shared/window.token';
import { SearchUrlService } from '@dg/shared-services';
import { EmitEvent, EventBus } from '@app/shared/services/event-bus';
import { Router } from '@angular/router';
import { SearchState } from '@app/search/components/search-view/search-reactive-store';
import { stateToUrlParams } from '@app/search/components/search-view/search-reactive-store/utils';

export enum SearchEvents {
  GLOBAL_SEARCH = 'globalsearch',
}
export class SearchEvent<K> implements EmitEvent<K> {
  constructor(public type: string, public data?: K) {}
}
export const globalSearch = (config: Partial<SearchState>) =>
  new SearchEvent(SearchEvents.GLOBAL_SEARCH, config);

/**
 * This service is primarily intended to be used within the context of the
 * Global Search SPA to provide the smoothest routing possible. If your
 * service/component routes to a search page and is visibile within that context
 * this will help prevent unncessary page relaods.
 */
@Injectable({
  providedIn: 'root',
})
export class SearchNavigationService {
  // Map nav methods to their respective routes
  private readonly routeActionMap: Record<string, string> = {
    [SearchUrlService.GLOBAL_SEARCH_BASE]: 'searchLearnings',
    [SearchUrlService.SKILL_SEARCH_BASE]: 'searchSkills',
    [SearchUrlService.PEOPLE_SEARCH_BASE]: 'searchPeople',
    [SearchUrlService.GROUP_SEARCH_BASE]: 'searchGroups',
    [SearchUrlService.OPPORTUNITY_SEARCH_BASE]: 'searchOpportunities',
  };

  constructor(
    private searchUrlService: SearchUrlService,
    private eventBus: EventBus,
    private router: Router,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  private get baseUrl(): string {
    return this.windowRef.location.pathname;
  }

  // Are we currently viewing a search tab (Learning/Skills/People/Groups/Opportunities)?
  private get onSearchTab(): boolean {
    return Object.keys(this.routeActionMap).some((searchUrl) =>
      this.baseUrl.startsWith(searchUrl)
    );
  }

  /**
   * Initiate a global search using the routing method that is most
   * appropriate for the current context to prevent unnecessary page reloads
   *
   * - Viewing the Search `Learning` tab? Event is emitted so `SearchFacade` can maintain routing
   * - Viewing one of the other Search tabs (e.g. `Skills`)? Angular routing is used
   * - Outside of the Search SPA? `window.location.assign` is used to reload page
   *
   * @example
   * // Viewing `/learning/search...`?
   * this.navigationService.searchLearnings('python');
   * // We're on the `Learning` tab so notify the `SearchFacade` via `EventBus`...
   * this.eventBus.announce(globalSearch({ term: 'python' }));
   *
   * // Otherwise see `navigateToUrl` for more details...
   * this.navigationService.navigateToUrl('/learning/search?term=python');
   */
  public searchLearnings(config?: string | Partial<SearchState>) {
    let params = typeof config === 'string' ? { term: config } : config;
    const onLearningTab = this.baseUrl.includes(
      SearchUrlService.GLOBAL_SEARCH_BASE
    );

    // If we're already on the Search `Learning` tab emit an event and let the
    // SearchFacade manage the routing state to prevent a page flash
    if (onLearningTab) {
      return this.eventBus.announce(globalSearch(params));
    }

    const urlParams = stateToUrlParams(params);
    const url = this.searchUrlService.getGlobalSearchURL(urlParams);

    this.navigateToUrl(url);
  }

  /**
   * This method checks against the current route to determine if you're viewing one of the
   * search tabs. If so, it will initiate a new search within that context using the least
   * jarring routing method available to prevent unnecessary page reloads. If you're not on
   * a search tab we can't assume you're in the same SPA so a full page reload will be
   * triggered with a new global search.
   *
   * Applies to `Learning` / `Skills` / `People` / `Groups` / `Opportunities` tabs;
   *
   * Note: If you're navigating between tabs in the search SPA with a full url use `navigateToUrl`
   *
   * @example
   * // Viewing `/learning/groups...`?
   * this.navigationService.searchWithinContext('python');
   * // We're in the Search SPA so we use...
   * this.router.navigateByUrl(`/learning/groups?term=python`);
   *
   * // Viewing '/[user]/dashboard`?
   * this.navigationService.searchWithinContext('leadership');
   * // We're NOT in the Search SPA so fall back to...
   * window.location.assign('/learning/search?term=leadership')
   */
  public searchWithinContext(term: string) {
    // We can't use bracket notation on `this` so...
    const searchNavigationService = this;

    for (const [url, method] of Object.entries(this.routeActionMap)) {
      if (this.baseUrl.startsWith(url)) {
        return searchNavigationService[method](term);
      }
    }

    // Fall back to global search
    this.searchLearnings(term);
  }

  public searchSkills(term?: string) {
    const url = this.searchUrlService.getSkillSearchURL(term);
    this.navigateToUrl(url);
  }

  public searchPeople(term?: string) {
    const url = this.searchUrlService.getPeopleSearchURL(term);
    this.navigateToUrl(url);
  }

  public searchGroups(term?: string) {
    const url = this.searchUrlService.getGroupSearchURL(term);
    this.navigateToUrl(url);
  }

  public searchOpportunities(term?: string) {
    const url = this.searchUrlService.getOpportunitySearchURL(term);
    this.navigateToUrl(url);
  }

  /**
   * This method verifies whether you're in the Search SPA before navigating to the given
   * URL to provide the least jarring routing experience.
   *
   * @example
   * // Viewing `/learning/search...`?
   * this.navigationService.navigateToUrl('/learning/skills?term=leadership');
   * // We're in the Search SPA so we use...
   * this.router.navigateByUrl('/learning/skills?term=leadership');
   *
   * // Viewing '/[user]/dashboard`?
   * this.navigationService.navigateToUrl('/learning/skills?term=leadership');
   * // We're NOT in the Search SPA so fall back to...
   * window.location.assign('/learning/search?term=leadership')
   *
   * // Viewing '/learning/search...'?
   * this.navigationService.navigateToUrl('/testUser');
   * // We're leaving search SPA, so
   * window.location.assign('/testUser')
   */
  public navigateToUrl(url: string) {
    // If one of the search tabs is selected we're in the same SPA so use Angular routing
    if (this.onSearchTab && this.isSearchUrl(url)) {
      return this.router.navigateByUrl(url);
    }

    this.windowRef.open(url, '_self');
  }

  /**
   * Is the given URL in the Search context?
   */
  private isSearchUrl(url: string) {
    return Object.keys(this.routeActionMap).some((searchUrl) =>
      url.startsWith(searchUrl)
    );
  }
}
