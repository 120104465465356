import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  FormControl,
} from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';

// misc
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { DgError } from '@app/shared/models/dg-error';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { handleBadgeFocusOnSubmit } from '../../services/badge.utils';
import { BadgeGlobalAddFacade } from '@app/user-content/user-outcome-v2/outcomes/badge/services/global-add/badge-global-add.facade';
import { BadgeModel, GlobalAddBadgeField } from '@app/user-content/user-outcome-v2/outcomes/badge/badge.model';

import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import {
  BadgeTrackerService,
  BadgeMapperService,
  BadgeService,
} from '@app/user-content/user-outcome-v2/outcomes/badge/services';
import { NotificationType } from '@lib/fresco';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { WindowToken } from '@app/shared/window.token';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Component({
  selector: 'dgx-badge-global-add',
  templateUrl: './badge-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    BadgeGlobalAddFacade,
    BadgeMapperService,
    OutcomeNotificationService,
    BadgeService,
    BadgeTrackerService,
    OutcomesService,
  ],
})
export class BadgeGlobalAddComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public readonly NotificationType = NotificationType;

  @Input() public isEditing: boolean = false;
  @Input() public userOutcomeId: number;

  @ViewChild('badgeUrl')
  public badgeUrlInput: ElementRef<HTMLElement>;

  public badgeGlobalAddForm: FormGroup;
  public submitButtonText: string;

  public i18n = this.translate.instant([
    'Core_Save',
    'dgUserOutcomeEditForm_EditBadge',
    'dgUserOutcomeEditForm_AddBadge',
    'BadgeFormCtrl_BadgeHelpText',
    'dgUserOutcomeEditForm_UploadBadge',
    'dgUserOutcomeEditForm_BadgeIssuer',
    'dgUserOutcomeEditForm_IssuerUrl',
    'dgUserOutcomeEditForm_SaveBadge',
    'BadgeFormCtrl_BadgeInfoText',
    'Core_GeneralErrorMessage',
    'Core_Next',
    'Core_Skills',
    'dgContentHosting_DragAndDrop',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'MediaFormCtrl_UrlRequired',
    'CertificateFormCtrl_IssueDate',
    'CertificateFormCtrl_ExpiryDate',
  ]);
  public heading: string;
  public vm$: Observable<BadgeModel>;
  public showExpandedForm: boolean = false;
  public markSubFormsAsTouched = false;

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private facade: BadgeGlobalAddFacade,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private globalAddTrackingService: GlobalAddTrackingService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.facade.initializeViewModel();
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }
    this.heading = this.isEditing
      ? this.i18n.dgUserOutcomeEditForm_EditBadge
      : this.i18n.dgUserOutcomeEditForm_AddBadge;

    this.submitButtonText = this.isEditing ? this.i18n.Core_Save : this.i18n.dgUserOutcomeEditForm_AddBadge;
    if (this.isEditing) {
      await this.facade.initializeEdit(this.userOutcomeId);
      this.loadExpandedForm();
      return;
    }

    // Set up the view model
    this.initializeForm();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/

  public onFormControlUpdate(field: GlobalAddBadgeField, value: any): void {
    this.facade.onFormControlUpdate(this.badgeGlobalAddForm, field, value);
  }

  public onDeleteImage() {
    this.onFormControlUpdate('image', '');
    this.facade.onDeleteImage();
  }

  public onImageUploadSuccess({ pictureUrl }) {
    this.onFormControlUpdate('badgeUrl', pictureUrl);
    this.facade.onImageUploadSuccess(pictureUrl);
  }

  /****************************************************
   * On form page submissions
   ****************************************************/
  public async onNext(form: FormGroupDirective): Promise<void> {
    this.facade.markFormAsTouched(this.badgeGlobalAddForm);
    if (this.badgeGlobalAddForm.invalid) {
      return;
    }
    try {
      await this.onBadgeUrlNext();
      // reset the submitted state on the form
      form.resetForm(this.badgeGlobalAddForm.value);
    } catch (error) {
      throw new DgError(this.i18n.Core_GeneralErrorMessage, error);
    }

    this.loadExpandedForm();
  }

  /**
   * On form Submission check if the form is valid
   */
  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.badgeGlobalAddForm);
    this.markSubFormsAsTouched = true;
    if (this.badgeGlobalAddForm.invalid) {
      handleBadgeFocusOnSubmit(this.badgeGlobalAddForm.controls);
      return;
    }
    try {
      await this.facade.onSubmit(this.badgeGlobalAddForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }
  }

  public onNavigateToCollection(collectionUrl: string) {
    this.activeModal.close(this.facade.snapshot);
    if (collectionUrl) {
      this.windowRef.location.href = collectionUrl
    }
  }

  public onDismiss(event: Event) {
    this.activeModal.dismiss();
    this.globalAddTrackingService.trackGlobalAddCancelled('Badge');
  }

  /******************************************************************
   * Private utils
   ******************************************************************/
  /**
   * On selecting next from the initial form
   */
  private async onBadgeUrlNext(): Promise<void> {
    try {
      await this.facade.onNext(this.badgeGlobalAddForm.get('badgeUrl').value);
    } catch (error) {
      throw new DgError(this.i18n.Core_GeneralErrorMessage, error);
    }
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.badgeGlobalAddForm = this.formBuilder.group({
      badgeUrl: [''],
    });
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm(): void {
    this.showExpandedForm = true;
    this.facade.loadInferredSkills(this.facade.snapshot.issuer);

    const inputEntry = {
      badgeUrl: new FormControl({
        value: this.facade.snapshot.badgeUrl,
        disabled: true,
      }),

      issuer: new FormControl({
        value: this.facade.snapshot.issuer,
        disabled: true,
      }),
      issuerUrl: new FormControl({
        value: this.facade.snapshot.issuerUrl,
        disabled: true,
      }),
      skills: [this.facade.snapshot.skills],
    };

    this.badgeGlobalAddForm = this.formBuilder.group({
      ...inputEntry,
    });

    this.badgeGlobalAddForm.patchValue({
      badgeUrl: this.facade.snapshot.badgeUrl,
      issuer: this.facade.snapshot.issuer,
      issuerUrl: this.facade.snapshot.issuerUrl,
      dateRangeForm: {
        startDate: this.facade.snapshot.dateRangeForm.startDate,
        endDate: this.facade.snapshot.dateRangeForm.endDate,
      },
    });

    this.cdr.detectChanges();
  }
}
