<div
  class="pathway-author-content"
  [ngClass]="{
    'pathway-author-content--large': size === 'large',
    'guts-p-full-2': size === 'small'
  }"
>
  <button
    class="color-blue"
    data-dgat="pathway-subsection-155"
    (click)="addContent($event)"
  >
    <span class="l_flexbar">
      <df-icon
        icon="plus-circle"
        size="large"
        class="color-blue guts-p-r-half"
        aria-hidden="true"
      ></df-icon>
      <span class="pathway-author-content__title">
        {{ i18n?.Pathways_AddContent }}
      </span>
    </span>
  </button>
</div>
