/* eslint-disable max-classes-per-file */
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WindowToken } from '@app/shared/window.token';
import { WindowLayoutService } from '@dg/shared-services';
import { MSTeamsAuthService } from '@app/ms-teams/services/ms-teams-auth/ms-teams-auth.service'

export class CachedHttpRequest<T> extends HttpRequest<T> {}

// An HTTP interceptor that adds the dg-host header to api requests and
// reloads the page if a 401 response is received without the 'Dg-Skip-Intercept' header
// MS Teams does not use the main app login and should instead
// refresh the page to re-authenticate via SSO with microsoftTeams SDK

@Injectable()
export class MsTeamsAuthInterceptor implements HttpInterceptor {
  constructor(
    private windowLayoutService: WindowLayoutService,
    private msTeamsAuthService: MSTeamsAuthService,
    @Inject(WindowToken) private windowRef: Window,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const remoteOrgHostDetails = localStorage.getItem('remoteOrgHostDetails'); //should have remoteOrgHostDetails in local storage
    const currentBaseURl = `https://${window.location.hostname}`;
    const isGateway = window.location.href.includes('https://gateway.'); //should be a gateway build
    const isMSTeams = this.windowLayoutService.isMsTeams || req.url.includes( 'degreed-ms-teams');
    const isGatewayAppWithServiceHostDetails =  isGateway && remoteOrgHostDetails && this.msTeamsAuthService.isValidRemoteHostDetailsSchema();
    const reqUrlMatchesWindowLocation = (req.url.startsWith(currentBaseURl) || req.url.startsWith('/'));
    //should be a relative url or match currentBaseURl e.g. not to cdn
    if (isGatewayAppWithServiceHostDetails && reqUrlMatchesWindowLocation) {
      const remoteOrgHostDetailsJson = JSON.parse(remoteOrgHostDetails);
      // add dg-host to api request headers
      req = req.clone({
        headers: req.headers.append(
          'dg-host',
          remoteOrgHostDetailsJson.host
        ).append(
          'dg-datacenter',
          remoteOrgHostDetailsJson.dataCenter
        ),
        method: req.method
      });
    }

    // NOTE: this allows individual http requests to ignore this interceptor
    if (req.headers.get('Dg-Skip-Intercept')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && isMSTeams) {
          this.windowRef.location.reload();
        }
        return throwError(err);
      })
    );
  }
}
