import { Component, OnInit, Input } from '@angular/core';
import { EngagedLearner } from '@app/shared/services/engaged-learner.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';

/**
 * Engaged learning information modal
 * 
 */
@Component({
  selector: 'dgx-engaged-learner-modal',
  templateUrl: './engaged-learner-modal.component.html',
  styleUrls: ['./engaged-learner-modal.component.scss'],
})
export class EngagedLearnerModalComponent implements OnInit {
  @Input() public profile: EngagedLearner;

  public i18n: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    const authUser = this.authService.authUser;
    this.i18n = this.getTranslations(
      this.profile.id !== undefined &&
        (authUser?.viewerProfile?.userProfileId === this.profile.id ||
          authUser?.viewerProfile?.userProfileKey === this.profile.id),
      this.profile.name.split(' ', 1)[0]
    );
  }

  private getTranslations(isCurrentUser: boolean, name: string) {
    const shared = this.translateService.instant([
      'ActiveLearner_HowToGetRecognized',
    ]);
    if (isCurrentUser) {
      return {
        ...this.translateService.instant([
          'ActiveLearner_ModalTitle',
          'ActiveLearner_Condition1',
          'ActiveLearner_Condition2',
          'ActiveLearner_Condition3',
          'ActiveLearner_Condition4',
          'ActiveLearner_FinePrint',
        ]),
        ...shared,
      };
    }

    const args = { name };
    return {
      ActiveLearner_ModalTitle: this.translateService.instant(
        'ActiveLearner_ModalTitleWithName',
        args
      ),
      ActiveLearner_Condition1: this.translateService.instant(
        'ActiveLearner_Condition1WithName',
        args
      ),
      ActiveLearner_Condition2: this.translateService.instant(
        'ActiveLearner_Condition2WithName',
        args
      ),
      ActiveLearner_Condition3: this.translateService.instant(
        'ActiveLearner_Condition3WithName',
        args
      ),
      ActiveLearner_Condition4: this.translateService.instant(
        'ActiveLearner_Condition4WithName',
        args
      ),
      ActiveLearner_FinePrint: this.translateService.instant(
        'ActiveLearner_FinePrintWithName',
        args
      ),
      ...shared,
    };
  }
}
