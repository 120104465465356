import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { getAverageRatingLevel } from '@app/shared/utils/tag-helpers';
import { TagRatingButtonBaseComponent } from '@app/tags/components/tag-rating-button/tag-rating-button-base.component';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagFlagsService } from '@app/tags/services/tag-flags.service';
import { TagModalService } from '@app/tags/services/tag-modal.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-peer-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeerRatingButtonComponent extends TagRatingButtonBaseComponent {
  public readonly type = InternalTagRatingTypes.peer;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagFlagsService: TagFlagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    private tagModalService: TagModalService
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get noRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_RequestRating');
  }

  public get completedRatingCTAlabel(): string {
    const label =
      this.completedRatings.length > 1
        ? 'dgTagRating_ViewRatings'
        : 'dgTagRating_ViewRating';

    return this.translateService.instant(label, {
      RatingCount: this.allRatings.length,
    });
  }

  public get inProgressRatingCTAlabel(): string {
    return this.noRatingCTAlabel;
  }

  public get showCTAPlus(): boolean {
    return !this.hasCompletedRatings;
  }

  public get pendingRatingNotificationLabel(): string {
    const label =
      this.incompleteRatings.length > 1
        ? 'dgTagRating_RatingsRequested'
        : 'dgTagRating_RatingRequested';
    return this.translateService.instant(label);
  }

  public get completedRatingLevelLabel(): string {
    const label =
      this.completedRatings.length > 1
        ? 'dgTagRating_AverageLevel'
        : 'dgTagRating_Level';
    return this.translateService.instant(label, {
      Level: getAverageRatingLevel(this.completedRatings, true),
    });
  }

  public get inProgressRatingLevelLabel(): string {
    const label =
      this.incompleteRatings.length > 1
        ? 'dgTagRating_RequestsPending'
        : 'OrgGroupsCtrl_RequestPending';
    return this.translateService.instant(label);
  }

  public get showCTA(): boolean {
    return (
      this.ownerIsViewing ||
      (this.hasCompletedRatings &&
        !!this.authService.authUser &&
        !this.hasRedactedRatings)
    );
  }

  public get showPrivacyDropdown(): boolean {
    return (
      this.tagFlagsService.showPeerRatings &&
      !this.authService.authUser?.defaultOrgInfo?.settings
        .lockSkillPeerRatingVisibility &&
      this.ownerIsViewing &&
      this.hasCompletedRatings
    );
  }

  public get ratingDescriptionLabel(): string {
    return this.translateService.instant(
      'dgTagRating_RequestPeerRatingInstruction'
    );
  }

  public get hasMultipleEndorsers(): boolean {
    return !!this.endorsers?.length;
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    if (this.ownerIsViewing) {
      if (!this.hasRatings) {
        this.openPeerRatingRequestModal(event);
      } else {
        this.openPeerRatingOverviewModal();
      }
    } else {
      if (!!this.authService.authUser) {
        this.openPeerRatingOverviewModal();
      }
    }
  }

  public openPeerRatingRequestModal(event: Event): void {
    this.tagModalService
      .openPeerRatingRequestModal(event, this.tag)
      .subscribe();
  }

  public openPeerRatingOverviewModal(): void {
    this.tagModalService.openPeerRatingOverviewModal(
      this.tag,
      this.incompleteRatings,
      this.completedRatings,
      this.ownerIsViewing
    );
  }
}
