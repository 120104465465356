import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { isObservable, Subscription } from 'rxjs';
import { DfDynamicContentRef } from './providers';
import type { DfDynamicContentProvider } from './providers';
import { isString, isFunction } from '../../utilities/types/type-guards';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Provides content from a {@link DfDynamicContentProvider} as either innerText or an embedded view
 * on the host component.
 * @description
 */
@Directive({ selector: '[dfProvideContent]' })
export class DfContentProviderDirective implements OnChanges, OnDestroy {
  private subscription: Subscription;

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  @Input('dfProvideContent') public contentProvider: DfDynamicContentProvider;
  @Input('dfProvideContentContext') public context: any;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.contentProvider) {
      this.updateProvider(changes.contentProvider.currentValue);
    } else if (changes.context && isFunction(this.contentProvider)) {
      // render results of provider function
      // using else if here so we avoid rendering twice when both the content provider and context change together
      this.renderContent(this.contentProvider(changes.context.currentValue));
    }
  }

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private updateProvider(contentProvider: DfDynamicContentProvider) {
    this.subscription?.unsubscribe();
    // First figure out what kind of provider its set up as: string, Observable or function
    if (isFunction(contentProvider)) {
      this.renderContent(contentProvider(this.context)); // render results of provider function
    } else if (isObservable(contentProvider)) {
      this.subscription = contentProvider.subscribe(
        (r) => this.renderContent(r) // render results of pushed provider content
      );
    } else {
      this.renderContent(contentProvider); // render boring old static string
    }
  }

  private renderContent(contentRef: DfDynamicContentRef) {
    if (isString(contentRef) || !contentRef) {
      this.renderer.setProperty(
        this.element.nativeElement,
        'innerText',
        contentRef ?? ''
      );
    } else {
      this.viewContainerRef.createEmbeddedView(
        contentRef as TemplateRef<any>,
        this.context
      );
    }
  }
}
