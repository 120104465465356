import { InjectionToken, inject } from '@angular/core';

import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { UserInputsRepository } from '@app/shared/patterns/repository/user-inputs-repository';
import { EpisodeApiEntity } from './episode-forms/repository/episode.entity.model';
import { BookApiEntity } from './book-forms/repository/book.entity.model';
import { AssessmentApiEntity } from './assessment-forms/repository/assessment.entity.model';
import { EventApiEntity } from './event-modal/repository/user-event.entity.model';
import { ExperienceApiEntity } from './experience-modal/model/experience-form.model';
import { MediaEntryApiEntity } from './user-input.model';

// User Input type repository tree-shakeable tokens
export const USER_EVENT_REPOSITORY: InjectionToken<
  UserInputsRepository<EventApiEntity>
> = new InjectionToken<UserInputsRepository<EventApiEntity>>(
  'EVENT_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Event'),
  }
);

export const USER_ARTICLE_REPOSITORY: InjectionToken<
  UserInputsRepository<MediaEntryApiEntity>
> = new InjectionToken<UserInputsRepository<MediaEntryApiEntity>>(
  'USER_ARTICLE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Article'),
  }
);

export const USER_VIDEO_REPOSITORY: InjectionToken<
  UserInputsRepository<MediaEntryApiEntity>
> = new InjectionToken<UserInputsRepository<MediaEntryApiEntity>>(
  'USER_VIDEO_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Video'),
  }
);

export const USER_COURSE_REPOSITORY: InjectionToken<
  UserInputsRepository<EventApiEntity>
> = new InjectionToken<UserInputsRepository<EventApiEntity>>(
  'USER_COURSE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Course'),
  }
);

export const USER_BOOK_REPOSITORY: InjectionToken<
  UserInputsRepository<BookApiEntity>
> = new InjectionToken<UserInputsRepository<BookApiEntity>>(
  'USER_COURSE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Book'),
  }
);

export const USER_EXPERIENCE_REPOSITORY: InjectionToken<
  UserInputsRepository<ExperienceApiEntity>
> = new InjectionToken<UserInputsRepository<ExperienceApiEntity>>(
  'USER_EXPERIENCE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Position'), // Experience is saved as Position
  }
);

export const USER_ASSESSMENT_REPOSITORY: InjectionToken<
  UserInputsRepository<AssessmentApiEntity>
> = new InjectionToken<UserInputsRepository<AssessmentApiEntity>>(
  'USER_ASSESSMENT_REPOSITORY',
  {
    providedIn: 'root',
    factory: () =>
      new UserInputsRepository(inject(NgxHttpClient), 'Assessment'),
  }
);

export const USER_EPISODE_REPOSITORY: InjectionToken<
  UserInputsRepository<EpisodeApiEntity>
> = new InjectionToken<UserInputsRepository<EpisodeApiEntity>>(
  'USER_EPISODE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new UserInputsRepository(inject(NgxHttpClient), 'Episode'),
  }
);

// TODO: Add other user input type repo tokens here
