import { AuthService } from '@app/shared/services/auth.service';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';
import { OrgBaseGuard } from './org-base.guard';
import { MembersGuard } from './members.guard';
import { SegmentsGuard } from './segments.guard';

@Injectable({
  providedIn: 'root',
})
export class PeopleRedirectGuard extends OrgBaseGuard {
  constructor(
    @Inject(WindowToken) windowRef: Window,
    authService: AuthService,
    protected router: Router,
    private membersGuard: MembersGuard,
    private segmentsGuard: SegmentsGuard
  ) {
    super(authService, windowRef, router);

    this.routeDirectory = '/members';
  }

  override get hasPermission() {
    return this.membersGuard.hasPermission || this.segmentsGuard.hasPermission;
  }

  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.membersGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/users'));
    }
    if (this.segmentsGuard.hasPermission) {
      return this.router.parseUrl(this.resolveOrgUrl('/segments'));
    }
    return this.router.parseUrl('/');
  }
}
