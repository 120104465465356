import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';

import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { NotificationItemService } from './notification-item.service';

/** Represents multiple integration skill assessments have been found */
@Component({
  selector: 'dgx-multiple-assessments-found-review-skills',
  template: `
    <div (click)="reviewSkills()" [innerHTML]="safeContent"></div>
    <button
      type="button"
      class="link par par--small guts-p-t-half"
      (click)="reviewSkills()"
    >
      {{ i18n.OrgSkills_ReviewSkills }}
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleAssessmentsFoundReviewSkillsComponent extends NotificationItemComponentBase {
  public i18n = this.itemService.translate.instant([
    'OrgSkills_ReviewSkills',
    'dgTagRating_RateSkills',
  ]);

  constructor(
    cdr: ChangeDetectorRef,
    itemService: NotificationItemService,
    private tagRatingService: TagRatingService
  ) {
    super(cdr, itemService);
  }

  public reviewSkills() {
    this.tagRatingService.openReviewIntegrationSkillsModal(
      this.params.skillRatings,
      this.i18n.dgTagRating_RateSkills,
      this.i18n.OrgSkills_ReviewSkills,
      true,
      this.params.providerName
    );
  }

  protected buildTemplate() {
    return this.itemService.translate.instant(
      'Notification_MultipleAssessmentsFoundReviewSkills',
      {
        assessmentCount: this.params.skillRatings.length,
        providerName: this.params.providerName,
      }
    );
  }
}
