import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SearchTrackerService } from '@app/search/services/search-tracker.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { camelCaseKeys } from '@app/shared/utils/property';

/**
 *  NGUPGRADE: Keep this list in sync with provider.ts
 */
@Component({
  selector: 'dgx-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnChanges, OnInit {
  @Input() public alignment: string | null;
  @Input() public display: string | null;
  @Input() public isFirstChild: boolean;
  @Input() public organizationId: number;
  @Input() public padding: string | null;
  @Input() public provider: any;
  @Input() public favicon: string;

  public isIframe = false;

  constructor(
    public searchUrlService: SearchUrlService,
    private searchTrackerService: SearchTrackerService,
    private windowLayoutService: WindowLayoutService
  ) {}

  public ngOnChanges(): void {
    this.provider = camelCaseKeys(this.provider);
  }

  public ngOnInit(): void {
    // normalize value of undefined ProviderId
    if (typeof this.provider.providerId !== 'number') {
      this.provider.providerId = null;
    }

    // default values
    this.alignment = this.alignment ? this.alignment : 'left';
    this.display = this.display ? this.display : 'inline-block';
    this.padding = this.padding ? this.padding : 'below';

    this.isIframe = this.windowLayoutService.isIframe;
  }

  public navigateToProvider(e, providerName) {
    this.searchTrackerService.setSearchData({
      submitMethod: 'Provider Link Clicked',
    });

    // TODO: Update method used to track when provider landing page has been created
    this.searchTrackerService.searchInitiated({
      searchTerm: providerName,
    });
    e.stopPropagation();
  }

  public getProviderUrl(provider): string {
    const isExternalCat = !this.organizationId || provider.isExternalCatalog;

    return this.searchUrlService.getProviderSearchURL({
      providerId: provider.providerId,
      isExternalCat,
    });
  }
}
