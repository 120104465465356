import { Pipe, PipeTransform } from '@angular/core';

// Reuse div instead of creating/destroying it on every instance
const div: HTMLElement = document.createElement('div');

@Pipe({
  name: 'decodeHtml',
})
export class DecodeHtmlPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return;
    }
    div.innerHTML = encodeURI(value);
    const decodedString = div.textContent.replace(/\\\//g, '');
    return decodeURI(decodedString);
  }
}
