import { Inject, Injectable } from '@angular/core';
import { ShareWithGroupModalComponent } from '@app/recommendations/components/share-with-group-modal/share-with-group-modal.component';
import { TrackingLinkService } from '@app/insights/services/tracking-link.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { AuthService } from '@app/shared/services/auth.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalOptions, ModalService } from '@app/shared/services/modal.service';
import { catchAndSurfaceNonModalError } from '@app/shared/utils/modal-helpers';
import { WindowToken } from '@app/shared/window.token';
import { UserService } from '@app/user/services/user.service';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { RecommendationsDetailModalComponent } from '../components/recommendations-detail-modal/recommendations-detail-modal.component';
import {
  RecommendedUser,
  ShareTabsModalComponent,
  ShareTabsResolve,
} from '../components/share-tabs-modal/share-tabs-modal.component';
import {
  RecommendationForGroup,
  RecommendationGroup,
  RecommendationsParams,
} from '../recommendations.api';
import { RecommendingItem } from '../recommendations.model';
import { RecommendationUpdateModalComponent } from './../components/recommendation-update-modal/recommendation-update-modal.component';
import {
  RecommendationsService,
  RecommendationType,
} from './recommendations.service';

/** Provides various modals for working with content recommendations and assignments */
@Injectable({
  providedIn: 'root',
})
export class RecommendationsModalService extends ApiServiceBase {
  public static readonly maxTopUsers = 10;

  private i18n = this.translateService.instant([
    'Core_Delete',
    'RecommendationsSvc_ProblemAccessingRecommendations',
    'RecommendationsSvc_ProblemAddingRecommendations',
    'RecommendationsSvc_SuccessDelete',
    'RecommendationsSvc_UsersTitle',
    'RecommendationsSvc_SharedWith',
  ]);

  constructor(
    private translateService: TranslateService,
    http: NgxHttpClient,
    private recommendationsService: RecommendationsService,
    private focusStackService: FocusStackService,
    private modalService: ModalService,
    private authService: AuthService,
    private userService: UserService,
    private trackingLinkService: TrackingLinkService,
    private displayTypePipe: DisplayTypePipe,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super(
      http,
      translateService.instant(
        'RecommendationsSvc_ProblemAccessingRecommendations'
      )
    );
  }

  /** Displays the standard content sharing modal, and adds a recommendation or assignment upon submission
   * @param item A sharable content item or target
   * @param initiator The element representing the initator of the share
   */
  public showShareModal(
    item: RecommendingItem,
    initiator?: HTMLElement,
    selected: RecommendedUser[] = []
  ): Observable<unknown> {
    if (initiator) {
      this.focusStackService.push(initiator as HTMLElement);
    }
    // create enhanced item
    const enhancedItem = this.createEnhancedRecommendingItem(item);
    // grab properties from item
    const { privacyId, resourceType, inviteUrl } = item;
    const authUser = this.authService.authUser;
    const { canManageTargets } = authUser;
    const canManagePathways =
      authUser.defaultOrgInfo?.permissions?.managePathways;
    const resourceCtrl = this.trackingLinkService.resourceCtrlFor(resourceType);

    const trackableResourceTypes = ['Target', 'Pathway'];
    const isPathways = resourceType === 'Pathway';
    const url =
      inviteUrl ??
      this.recommendationsService.getShareUrl(item as RecommendingItem);

    const insightsLink = isPathways
      ? `${item.publicUrl}/insights/links`
      : `/plan/${item.resourceId}/insights`;

    const description = this.translateService.instant(
      'TrackableLinkCtrl_LinkDescription',
      {
        aTagStart: `<a href="${insightsLink}" class="link">`,
        aTagEnd: '</a>',
      }
    );

    // rethink this...
    // show the trackable link tab only when a resource type is
    // a 'Pathway' or a Target (skill/role)
    const canManage =
      trackableResourceTypes.some((tr) => enhancedItem.resourceType === tr) &&
      ((isPathways && canManagePathways) ||
        (!isPathways && canManageTargets && privacyId !== 0));

    const inputs: ShareTabsResolve = {
      item: enhancedItem,
      selected,
      url,
      canManage,
      isEditing: false,
      initiator,
      description,
      resourceId: enhancedItem.resourceId,
      resourceType,
      resourceCtrl,
      // TODO: this is messy, need to find another way to get this state for pathway specific cases
      goToUrlAfterCreate: isPathways ? insightsLink : '',
      link: null,
    };
    return this.modalService
      .show<RecommendationsParams>(ShareTabsModalComponent, { inputs })
      .pipe(
        switchMap((recommendationParams: RecommendationsParams) => {
          if (recommendationParams.resource) {
            // Only call the addRecommendation api for shared links by user and not for trackable links tab used for pathway and targets resources

            const displayType = this.displayTypePipe.transform(
              enhancedItem.resourceType
            );
            return this.recommendationsService.addRecommendation(
              recommendationParams,
              displayType,
              initiator
            );
          } else {
            return of();
          }
        }),
        catchAndSurfaceNonModalError(
          this.i18n.RecommendationsSvc_ProblemAddingRecommendations
        ),
        finalize(() => {
          if (initiator && !this.focusStackService.top) {
            this.focusStackService.pop();
          }
        })
      );
  }

  /** Displays a modal for shared content with groups */
  public showGroupShareModal(
    recommendationId: number,
    event: Event
  ): Observable<unknown> {
    return this.get<RecommendationGroup[]>(
      '/recommendations/getgroupsbyrecommendation',
      {
        recommendationId,
      }
    ).pipe(
      take(1),
      switchMap((groups) => {
        this.focusStackService.push(event.target as HTMLElement);
        return this.modalService
          .show(ShareWithGroupModalComponent, {
            inputs: {
              groups,
              title: this.i18n.RecommendationsSvc_SharedWith,
            },
          })
          .pipe(
            tap(() => {
              this.focusStackService.pop();
            })
          );
      })
    );
  }

  /** Displays recommendation details in a modal */
  public showDetailModal(
    organizationId: number,
    recommendation: any,
    event: Event
  ): Observable<unknown> {
    const modalOptions: ModalOptions = {
      inputs: {
        organizationId,
        recommendation,
        sourceTarget: event.target as HTMLElement,
      },
    };
    return this.modalService.show(
      RecommendationsDetailModalComponent,
      modalOptions
    );
  }

  /** Displays the shared content update modal. */
  public showUpdateModal(
    recommendation: RecommendationForGroup,
    event: Event
    // update to return void once ld flag is lifted
  ): Observable<any> {
    this.focusStackService.push(event?.target as HTMLElement);

    return this.modalService.show(RecommendationUpdateModalComponent, {
      inputs: {
        recommendation,
        userHasRestrictedProfile: this.authService.authUser.isRestrictedProfile,
        promptDelete: (item: RecommendationForGroup, event: Event) =>
          this.showConfirmDeleteModal(item, event),
      },
    });
  }

  /** Displays a list of top recommenders in a modal. Note that, atypically, the Observable completes after
   * the users request completes rather than after the modal is closed. After migration, it probably
   * makes more sense to return no Observable at all, since it's a display-only modal.
   */
  public showTopUsersModal(
    referenceType: string,
    referenceId: number,
    event: Event
  ): Observable<unknown> {
    return this.get<UserProfileSummary[]>(
      '/recommendations/gettopusers',
      {
        referenceId,
        referenceType,
        count: RecommendationsModalService.maxTopUsers,
      },
      this.i18n.RecommendationsSvc_ProblemAccessingRecommendations
    ).pipe(
      tap((users: UserProfileSummary[]) => {
        const count = users.length;
        const i18n =
          count === 1 ? 'InputsSvc_PersonShared' : 'InputsSvc_PeopleShared';
        const title = this.translateService.instant(i18n, { count });

        this.userService.showUsersList(title, users, event);
      })
    );
  }

  private createEnhancedRecommendingItem(item: RecommendingItem) {
    let itemResourceId: number;
    const authUser = this.authService.authUser;
    // Data massaging to get unrelated items munged into a common property set
    // TODO: Code smells abound here. It should be reworked into something aromatic when we get better content unification on the client
    const {
      actions,
      actionsLabel,
      id,
      resourceId,
      resourceType,
      referenceType,
      targetId,
    } = item;

    // check is defined because ResourceIds of Q&As === 0. sigh.
    if (resourceId !== null && resourceId !== undefined) {
      itemResourceId = resourceId;
    } else if (id) {
      itemResourceId = id;
    } else if (targetId) {
      itemResourceId = targetId;
    }

    const itemResourceType = resourceType ? resourceType : referenceType;

    return {
      ...item,
      resourceType: itemResourceType,
      isOrgContent:
        !!item.organizationId &&
        Number(item.organizationId) === authUser.defaultOrgId,
      resourceId: itemResourceId,
      // We shouldn't need to default to null anywhere in ngx code, so this can be assumed is for ajs compatibility
      actions: actions ?? null,
      actionsLabel: actionsLabel ?? null,
    };
  }

  private showConfirmDeleteModal(
    recommendation: RecommendationForGroup,
    event: Event
  ): Observable<void> {
    return this.modalService
      .show<any>(SimpleModalComponent, {
        inputs: {
          canCancel: true,
          canCloseHeader: false,
          bodyClasses: 'delta font-bold center-text',
          bodyText: this.translateService.instant(
            'RecommendationsSvc_DeleteConfirmationFormat',
            { title: recommendation.reference.title }
          ),
          submitButtonText: this.i18n.Core_Delete,
          isHeaderBorderless: true,
        },
        errorOnDismiss: true,
      })
      .pipe(
        tap(() => {
          // moved from action-option-helpers.service, apparently this could be required for CMS items.
          const recommendationId =
            typeof recommendation.recommendationId === 'string'
              ? parseInt(recommendation.recommendationId)
              : recommendation.recommendationId;

          this.recommendationsService
            .deleteRecommendation(
              recommendationId,
              recommendation.reference.title,
              recommendation.recommendationType as RecommendationType
            )
            .subscribe(() => {
              // TODO: Remove this reload once we know recommendations update reactively
              this.windowRef.location.reload();
            });
        })
      );
  }
}
