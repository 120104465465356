<ng-container *ngIf="showBadge || showCompletedBadge">
  <div
    class="
      badge badge-pill badge-pill--compact
      flex
      no-wrap
      ellipsis
      assignment-badge-container
    "
    data-dgprop-item-clicked="Assignment Badge"
    [ngClass]="badgeClass"
  >
    <!-- Skill review assignments don't have a contentTypeLabel, so don't show a delimiter in this case-->
    <span [class.--delimiter]="showAssignedDelimeter" *ngIf="isAssigned">
      {{ 'Core_Assigned' | translate }}
    </span>

    <ng-container *ngIf="isExternalAndRequired">
      <span [class.--delimiter]="showAssignedDelimeter">
        {{ assignmentInfo?.configuredAssignmentType | translate }}
      </span>
    </ng-container>

    <span
      *ngIf="hasDueDate"
      [ngClass]="{ '--delimiter': isAssigned && isPathway }"
    >
      {{ statusLabel }}
    </span>
    <span *ngIf="isPathway" class="no-wrap ellipsis">
      {{
        'DashboardNav_PathwayPercentComplete'
          | translate
            : {
                percent:
                  assignmentInfo.reference.percentComplete | number: '1.0-0'
              }
      }}
    </span>
    <span *ngIf="showVerified">
      {{ 'dgContentTile_Verified' | translate }}
    </span>
  </div>
</ng-container>
