/** Handles values that might be either a true number or a string. */
export function numberFromString(value: number | string): number {
  // if number, return as-is
  if (typeof value === 'number') {
    return value;
  }
  // otherwise, attempt to parse int
  const parsed = parseInt(value, 10);
  // return 0 if NaN
  return parsed ? parsed : 0;
}
