import { Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathwayAuthorNoteService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Used to add author note
   *
   * @param pathId - Id of pathway adding note to
   * @param node - Node of the step author note is in
   * @param note - The author note
   */
  async addUpdateAuthorNote(
    pathId: number,
    node: string,
    note: string
  ): Promise<any> {
    try {
      const request$ = this.http.post<any>(
        '/pathways/addupdatepathwaynodenote',
        {
          pathId,
          node,
          note,
        }
      );
      await lastValueFrom(request$);
    } catch (error) {
      throw new DgError(this.translate.instant('Pathways_SaveError'), error);
    }
  }

  /**
   * Used to delete author note
   *
   * @param pathId - Id of pathway deleting note from
   * @param node - Node of the step author note is in
   */
  async deleteAuthorNote(pathId: number, node: string): Promise<void> {
    try {
      const params = { pathId, node };
      const request$ = this.http.delete<void>(
        '/pathways/deletepathwaynodenote',
        {
          params,
        }
      );
      await lastValueFrom(request$);
    } catch (error) {
      throw new DgError(this.translate.instant('Pathways_SaveError'), error);
    }
  }
}
