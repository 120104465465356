import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { readFirst } from '@dg/shared-rxjs';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { AuthService } from '@app/shared/services';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';
import { TranslateService } from '@ngx-translate/core';
import { ArticleFacadeBase } from '../article-facade-base.service';
import { ArticleMapperService } from '../article-mapper.service';
import { ArticleNotificationService } from '../article-notification.service';
import { ArticleTrackerService } from '../article-tracker.service';
import { ArticleService } from '../article.service';

// misc
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { MediaParseType } from '@app/shared/models/core.enums';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { ArticleModel } from '@app/user-content/user-input-v2/inputs/article/article.model';
import { MediaMetadataStatus } from '@app/user-content/user-input/media-modal/media-modal-facade-base';
import { PathwayStep } from '@dg/pathways-rsm';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable({ providedIn: 'root' })
export class ArticlePathwaysPlansInputFacade extends ArticleFacadeBase {
  constructor(
    public translate: TranslateService,
    public inputsService: InputsService,
    public authService: AuthService,
    public mapperService: ArticleMapperService,
    public orgInternalContentService: OrgInternalContentService,
    public inputImageUploadAdapterService: InputImageUploadAdapterService,
    public articleService: ArticleService,
    public globalAddTrackingService: GlobalAddTrackingService,
    private articleNotificationService: ArticleNotificationService,
    private articleTrackerService: ArticleTrackerService
  ) {
    super(
      inputsService,
      authService,
      translate,
      mapperService,
      orgInternalContentService,
      inputImageUploadAdapterService,
      articleService,
      globalAddTrackingService
    );
  }
  // *******************************************************
  // Getters
  // *******************************************************
  /**
   * Easy access to current snapshot of [read-only] ArticleModel
   * ...
   */
  public get snapshot(): ArticleModel {
    return readFirst(this.viewModel$);
  }

  public get orgName(): string {
    return this.authService.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    ).name;
  }

  // *******************************************************
  // Overwrites
  // *******************************************************

  /**
   * Overwrite the on Next form the base facade for catalog
   * When the next button has been selected
   * @param url
   * @returns
   */
  public async onNext(url: string): Promise<void> {
    if (!!url) {
      this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

      // Update the view model with the url and set loading
      this.viewModel = { ...this.viewModel, entryUrl: url };

      try {
        // Parse url (diffbot)
        const result = await this.inputsService.getMediaMetadataAsPromise(
          url,
          this.viewModel.inputContext.inputType,
          MediaParseType.None
        );

        // Update the viewModel with the results
        const updatedView = this.mapperService.toViewModel(
          result,
          this.viewModel
        );

        this.viewModel = {
          ...this.viewModel,
          ...updatedView,
          isInitialForm: false,
          organizationId: this.orgId,
          owner: undefined,
        };

        this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
        return;
      } catch (e) {
        this.mediaMetadataStatus$.next(MediaMetadataStatus.None);
        throw e;
      }
    }

    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      owner: undefined,
    };
  }

  /**
   * Override onSubmit if submitting article edits locally/for the pathway/plan only
   * @param form
   * @param isEditingContentLocally
   * @param pathwayStep
   */
  public async onSubmit(
    form: FormGroup,
    isEditingContentLocally = false,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    try {
      // TODO: Add in handling here later (bug doesn't even exist yet)
      // // WHEN adding an image for the first time, we need to UPDATE the image on the
      // // BE so that it doesn't have the parentResourceType=pathway/parentResourceId=
      // // pathId settings anymore, otherwise we won't be able to edit it later. This
      // // method doesn't exist yet, a bug will be made for it.
      // if (!!form.get('addToCatalog')?.value) {
      //   // Reupload image!
      // }

      // Newly-added pathway content and content that's been saved to the catalog or
      // which is being saved to a plan... all of that continues on to our default
      // submit.
      if (!isEditingContentLocally || !pathwayStep) {
        await super.onSubmit(form);
        this.performSuccessSideEffects();
        return;
      }
      // OTHERWISE...
      // update the VM with the form data
      const formData = form.value;
      this.updateViewWithFormData(formData);
      // and with the step data
      this.viewModel = this.mapperService.toStep(this.viewModel, pathwayStep);
      // then we're done! the modal close will take it from here.
    } catch {
      this.performFailureSideEffects(isEditingContentLocally, pathwayStep);
    }
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    await super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: !!this.viewModel.inputContext.isCmsContent,
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: !!this.viewModel.inputContext.isCmsContent,
      },
    };
  }

  /**
   * Override initializeEdit if editing article locally/for the pathway/plan only
   * @param isEditingInternalContent
   * @param pathwayStep
   */
  public async initializeEdit(
    isEditingContentLocally = false,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    // All other edit scenarios are handled by default facade method.
    if (!isEditingContentLocally || !pathwayStep) {
      return super.initializeEdit();
    }
    // OTHERWISE, grab owner
    const owner = await this.inputsService.getInputOwner(
      pathwayStep.reference.primaryContactResourceId,
      pathwayStep.reference.primaryContactResourceType,
      this.orgId
    );
    // Pass it and the PathwayStep to our mapper to get a VM from the
    // combined properties
    const updatedView = this.mapperService.fromStepToViewModel(pathwayStep, {
      ...this.viewModel,
      owner,
    });
    // Finally, add a couple more computed properties.
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isInitialForm: false,
      organizationId: this.orgId,
    };
  }

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.articleNotificationService.notifyArticleInputCreated(
        this.viewModel.title
      );

      return this.articleTrackerService.trackContentSkillInferred(
        this.viewModel
      );
    }

    const apiParameters = this.mapperService.toApiParameters(
      this.viewModel as ArticleModel
    );

    this.articleNotificationService.notifyArticleInputUpdated();
    this.articleTrackerService.trackContentCatalogUpdate(apiParameters);
  }

  /** Performs any side effects required following failed creation/update of an Article */
  protected performFailureSideEffects(
    isEditingContentLocally?: boolean,
    pathwayStep?: PathwayStep
  ) {
    if (isEditingContentLocally && pathwayStep) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new Error('Error in ArticlePathwaysPlansFacade');
    }
    this.articleNotificationService.notifyArticleInputCreateFailed();
  }
}
