<dgx-list-item [item]="item">
  <ng-container icon>
    <dgx-image-layout
      format="list"
      [imageSrc]="item.imageUrl"
      [resourceId]="item.resourceId"
      [resourceType]="item.resourceType"
      [videoUrl]="item.resourceType === 'Video' ? item.url : ''"
      [providerImage]="item.providerSummary?.image"
    ></dgx-image-layout>
  </ng-container>
  <ng-container button>
    <dgx-input-completion
      [input]="item | inputToLearningResource: 'Pathway'"
    ></dgx-input-completion>
  </ng-container>
</dgx-list-item>
