import { Injectable } from '@angular/core';
import { DegreeMappingToAPI } from '../degree.model';
import { TrackerService } from '@dg/shared-services';

@Injectable()
export class DegreeTrackerService {
  constructor(private tracker: TrackerService) {}

  public trackDegreeAdded(source: DegreeMappingToAPI) {
    const eventDataToTrack = {
      action: 'Degree Added',
      properties: {
        Location: 'GlobalAdd',
        AchievementType: 'Degree',
        Category: 'User Outcomes',
        Label: 'Degree',
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackDegreeUpdated(source: DegreeMappingToAPI) {
    const { endDate, source: college, title, tags, userOutcomeId } = source;

    const eventDataToTrack = {
      action: 'Degree Updated',
      properties: {
        endDate,
        college,
        skillTagCount: tags?.length,
        tags,
        title,
        userOutcomeId,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
