import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Predicate, PredicateType } from '@app/automations/model';

@Directive()
export abstract class PredicateTemplateBase {
  @Input() public predicates: Predicate[];
  @Output() public cancel = new EventEmitter<void>();
  @Output() public save = new EventEmitter<{
    predicates: Predicate[];
    predicateType: PredicateType;
  }>();
  @Output() public back = new EventEmitter<void>();
  public saveAttempted: boolean;

  protected abstract validatePredicates(): boolean;
  protected abstract onSave(): void;

  public onCancel(): void {
    this.cancel.emit();
  }

  public onBack(): void {
    this.back.emit();
  }
}
