<div
  class="tile tile--content break rel l_flex updated-content-card"
  data-dgprop-item-clicked="Tag Recommendation Tile"
  data-dgprop-item-type="Tag"
>
  <article class="l_flex l_flex-column full-width m-l_flex">
    <!-- Header -->
    <section
      class="
        l_flex l_flex-column l_flex-grow
        center-text
        full-width
        guts-p-full-1
        l_flex-justify-center
      "
    >
      <!-- Rating -->
      <dgx-tag-rating-donut
        size="lg"
        [level]="getLevel"
        [showDefaultGlasses]="true"
        class="l_flex l_flex-center l_flex-justify-center guts-p-b-1-half"
      ></dgx-tag-rating-donut>
      <!-- Title -->
      <header
        class="tile__content-head center-text"
        data-dgprop-item-clicked="Title"
      >
        <h3 class="h3 guts-m-b-1-half">{{ tag.title }}</h3>
      </header>
      <!-- Description -->
      <button
        type="button"
        class="l_flex l_flex-middle l_flex-center"
        (click)="doAction($event)"
        data-dgat="tag-recommendation-tile-e3c"
      >
        <img *ngIf="getLevel" [src]="degreedImageSrc" class="guts-p-r-half" />

        <df-icon
          *ngIf="!getLevel"
          [icon]="'plus-circle'"
          class="guts-p-r-half color-blue"
          size="small"
        ></df-icon>

        <span class="par par--small font-semibold color-blue">{{
          buttonLabel
        }}</span>
      </button>
    </section>

    <!-- Footer -->
    <footer class="tile__footer guts-p-t-1 guts-p-b-1">
      <!-- tile__actions class has an explicit height, maybe we can remove this in future? -->
      <div class="guts-p-full-0">
        <div class="l_flexbar l_flex-grow guts-p-h-1">
          <dgx-menu
            [menuConfig]="menuConfig"
            [appendToBody]="true"
            class="guts-m-l-auto"
            placement="bottom-right"
            [placementAdjustLeftRem]="1"
          >
            <button
              df-button
              df-button-square
              dfButtonType="clear"
              [attr.aria-label]="'Core_MoreOptions' | translate"
              data-dgat="tag-recommendation-tile-d6b"
            >
              <df-icon icon="dots" class="color-ebony-a61"></df-icon>
            </button>
          </dgx-menu>
        </div>
      </div>
    </footer>
  </article>
</div>
