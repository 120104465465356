import { Injectable, Injector } from '@angular/core';

import {
  UserPosition,
  UserPositionParameters,
  UserExistingPositionParameters,
  UpdateUserPositionParameters,
} from '../inputs-api.model';
import { UserInputTypeServiceBase } from './user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Experiences (a.k.a Positions) */
@Injectable({
  providedIn: 'root',
})
export class UserExperienceService extends UserInputTypeServiceBase<
  'Position',
  UserPosition,
  UserPositionParameters,
  UserExistingPositionParameters,
  UpdateUserPositionParameters
> {
  constructor(injector: Injector) {
    super(injector, '', 'Position');
  }
}
