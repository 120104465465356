import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Represents the root container for built form fields. Place this inside your <form> element and wire up
 * to your reactive form and fields generated by {@link FormFieldBuilder}.
 * @description Currently this is simply a wrapper around Formly's FormlyFormComponent in order to maintain
 * an abstraction.
 */
@Component({
  selector: 'df-form-root',
  template: `<formly-form
    class="df-form"
    [form]="form"
    [fields]="fields"
    [options]="options"
    [model]="model"
  ></formly-form>`,
  styleUrls: ['./form-root.component.scss'],
  providers: [],
})
export class DfFormRootComponent {
  @Input() form: FormGroup;
  @Input() model: any;
  @Input() fields: FormlyFieldConfig[];
  @Input() options: FormlyFormOptions;
}
