<div
  *ngIf="vm$ | async as vm"
  class="pathway-subsection__container"
  [class.guts-m-t-2]="!isFauxSubsection"
>
  <div
    class="l_flexbar pathway-subsection__heading"
    id="section-{{ subsection.levelNumber }}-{{ subsection.number }}"
  >
    <div class="l_flex-grow l_flexbar l_flex-wrap">
      <span class="pathway-subsection__meta-text">
        {{ itemTotalText(subsection) }}
      </span>
    </div>
    <dgx-menu
      *ngIf="!isFauxSubsection || hasAtLeastOneStep(subsection)"
      [menuConfig]="menuConfig"
      placement="bottom-right"
      [placementAdjustLeftRem]="1"
    >
      <button type="button" data-dgat="pathway-author-subsection-122">
        <df-icon
          icon="dots"
          a11yText="{{ i18n.Core_MoreOptions }}"
          class="color-ebony-a61"
        ></df-icon>
      </button>
    </dgx-menu>
  </div>
  <!-- Subsection Title & Description-->
  <div
    *ngIf="!isFauxSubsection"
    class="pathway-subsection__description guts-p-full-2"
  >
    <input
      #subsectionTitle="ngModel"
      type="text"
      class="h3"
      required
      [ngModel]="subsection.title"
      [placeholder]="i18n?.Pathways_SubsectionTitlePlaceholder"
      [attr.aria-label]="
        'Pathways_SubsectionTitleLabel'
          | translate
            : {
                sectionNumber: subsection.levelNumber,
                subsectionNumber: subsection.number
              }
      "
      [maxlength]="titleMaxLength"
      aria-describedby="pathauthA11yBlurInstructions"
      (blur)="onBlur($event, 'title', subsection)"
      (keyup)="onFieldChange($event, 'title', subsection)"
      data-dgat="pathway-author-subsection-3f7"
    />
    <dgx-validate-field
      *ngIf="isTitleFieldInvalid(subsectionTitle)"
      [message]="i18n.Core_TitleRequired"
    ></dgx-validate-field>

    <textarea
      #subsectionDescription="ngModel"
      class="pathway-author-subsection__textarea guts-m-t-half"
      [ngModel]="subsection.description"
      [placeholder]="i18n?.Pathways_SubsectionDescriptionPlaceholder"
      [attr.aria-label]="
        'Pathways_SubsectionDescriptionLabel'
          | translate
            : {
                sectionNumber: subsection.levelNumber,
                subsectionNumber: subsection.number
              }
      "
      autosize
      [minRows]="descriptionRowsLength.min"
      [maxRows]="descriptionRowsLength.max"
      [maxlength]="descriptionMaxLength"
      [dgxSoftLimitValidation]="softCharacterLimit"
      (blur)="onBlur($event, 'description', subsection)"
      aria-describedby="pathauthA11yBlurInstructions"
      data-dgat="pathway-author-subsection-eee"
    ></textarea>

    <div
      *ngIf="
        subsectionDescription?.invalid &&
        (subsectionDescription.dirty || subsectionDescription.touched)
      "
    >
      <div aria-live="assertive">
        <div
          class="form__invalid-msg ib rel"
          *ngIf="subsectionDescription.errors.softLimit"
          [class.color-ebony-a61-hue]="
            !isDescriptionFieldInvalid(subsectionDescription)
          "
          [@dfSlideFadeInLeft]
        >
          <df-icon size="small" icon="arrow-down-right"></df-icon>
          <span
            class="guts-p-l-half"
            [innerHtml]="i18n?.Pathways_SectionDescLimit"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <!-- subsection Input Cards-->
  <ul>
    <li
      *ngFor="
        let step of subsection.steps;
        trackBy: trackByStepId;
        let first = first;
        let last = last
      "
      data-dgat="pathway-author-subsection-b6a"
      [@dfCollapseExpand]="!firstRender"
    >
      <dgx-pathway-add-content-between
        *ngIf="first && vm.canAuthor"
        (addContentClicked)="
          onSubsectionAddStepClick(subsection.node, 'first', $event)
        "
      ></dgx-pathway-add-content-between>
      <dgx-pathway-author-step
        [isOnlyChild]="first && last"
        [menuConfig]="
          createStepMenuConfig({
            step,
            subsection,
            pathway: vm.pathway
          })
        "
        [pathwayId]="vm.pathway.id"
        [pathwayVersion]="vm.pathway.version"
        [step]="step"
        (reorderSteps)="reorderSteps(subsection)"
      ></dgx-pathway-author-step>
      <dgx-pathway-add-content-between
        *ngIf="!last && vm.canAuthor"
        (addContentClicked)="
          onSubsectionAddStepClick(subsection.node, step.node, $event)
        "
      ></dgx-pathway-add-content-between>
    </li>
  </ul>
  <dgx-pathway-add-content-button
    *ngIf="vm.canAuthor"
    [size]="!hasAtLeastOneStep(subsection) ? 'large' : 'small'"
    (addContentClicked)="
      onSubsectionAddStepClick(subsection.node, subsection.node, $event)
    "
  ></dgx-pathway-add-content-button>
</div>
