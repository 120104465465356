import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// types
import { TagComparisonByUser } from '@app/insights/insights-api.model';
import {
  DataColumn,
  DataColumnHighlight,
  DataColumnPerson,
} from '@app/shared/components/data-table/data-table';
import { AuthService } from '@app/shared/services/auth.service';
import { InternalTagRatingTypes } from '@app/tags/tags';

@Component({
  selector: 'dgx-personal-skills-table',
  templateUrl: './personal-skills-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalSkillsTableComponent implements OnChanges {
  // View Children
  @ViewChild('averageRating', { static: true })
  public averageRatingTemplate: TemplateRef<any>;

  @ViewChild('selfRating', { static: true })
  public selfRatingTemplate: TemplateRef<any>;

  // bindings
  @Input() public isLoading: boolean = true;
  @Input() public hasJobRole: boolean = false;
  @Input() public skills: TagComparisonByUser[] = [];

  @Output() public rateSkill: EventEmitter<{
    tag: TagComparisonByUser;
    level: string;
  }> = new EventEmitter<{ tag: TagComparisonByUser; level: string }>();

  // public
  // define columns
  public columns: (DataColumn | DataColumnHighlight | DataColumnPerson)[] = [
    {
      label: 'Core_Skill',
      headClasses: 'l_w48',
      bodyClasses: 'break',
      canSort: false,
      prop: 'tagName',
    } as DataColumn,
    {
      label: 'PersonalSkillView_AverageRating',
      bodyClasses: 'break center-item',
      canSort: false,
    } as DataColumn,
    {
      label: 'PersonalSkillView_YourRating',
      headClasses: 'center-text',
      canSort: false,
    } as DataColumn,
  ];

  public firstRun: boolean = true;
  public hasMoreItems: boolean = true;
  public isDescending: boolean = false;
  public hasNoResults: boolean = true;
  public subtitle: string;
  public title: string;

  public i18n = this.translate.instant([
    'Core_Empty_Image_Alt',
    'PersonalSkillView_Top10SkillsTitle1',
    'PersonalSkillView_Top10SkillsTitle2',
    'PersonalSkillView_Top10SkillsSubtitle1',
    'PersonalSkillView_Top10SkillsSubtitle2',
    'PersonalSkillView_Top10SkillsSubtitle3',
    'PersonalSkillView_Top10SkillsSubtitle4',
    'PersonalSkillView_Top10SkillsAvgRating',
    'PersonalSkillView_Top10SkillsAddSkill',
    'PersonalSkillView_AddSkill',
  ]);
  public noItemsImage = this.getEmptyImageUrl();

  private readonly TOP_COUNT: number = 10;

  constructor(
    private authService: AuthService,
    private tagRatingService: TagRatingService,
    private translate: TranslateService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public ngOnChanges(): void {
    if (!this.isLoading && !!this.skills) {
      this.hasNoResults = this.getHasNoResults();
      this.title = this.getTitle();
      this.subtitle = this.getSubtitle();
      this.columns[1].template = this.averageRatingTemplate;
      this.columns[2].template = this.selfRatingTemplate;
    }
  }

  /**
   * get empty search results image
   */
  public getEmptyImageUrl(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/emptystate/empty-skills.svg',
      true
    );
  }

  /* Open the Add Target Level modal for a new skill
   *
   * @param event
   * @param skill - The skill to adjust the target level of.
   */
  public handleAddSkill(
    event: Event,
    tag: TagComparisonByUser
  ): Observable<number> {
    return this.handleAddTargetLevel(event, tag, true);
  }

  /* Open the Add Target Level modal.
   *
   * @param event
   * @param skill - The skill to adjust the target level of.
   * @param addSkill - will present a dialog allowing user to add the skill with or without a rating
   */
  public handleAddTargetLevel(
    event: Event,
    tag: TagComparisonByUser,
    addSkill: boolean = false
  ): Observable<number> {
    event.stopPropagation();
    const modalTitle = addSkill
      ? this.translate.instant('dgTagRating_AddToMySkills', {
          tagName: tag.tagName,
        })
      : this.translate.instant('dgTagRating_RatingTitleFormat', {
          tagName: tag.tagName,
        });

    // pass in 1 if the skill is not yet rated. if they hit cancel in the modal it will return to 0
    const tagCopy = {
      ...tag,
      rating: { level: tag.selfRating === 0 ? 1 : tag.selfRating },
    };

    const tagRatingModal = this.tagRatingService
      .openRatingModal({
        event,
        title: modalTitle,
        tag: tagCopy,
        ratingType: InternalTagRatingTypes.target,
        emptyRating: tag.selfRating === 0,
        initialValue: tagCopy.rating.level,
        instructionsText: tag.isFollowing
          ? undefined
          : 'PersonalSkillView_AddSkillInstructions',
        addNewSkill: addSkill,
      })
      .pipe(
        map(({ level }) => {
          this.rateSkill.emit({ tag, level });
          return level;
        }),
        // for now, swallow and return no results
        catchError(() => [])
      );
    tagRatingModal.subscribe();

    // return results from modal
    return tagRatingModal;
  }

  /**
   *  Determine whether or not to display the empty state image
   */
  private getHasNoResults(): boolean {
    let value: boolean;
    value =
      !this.hasJobRole ||
      this.skills.length === 0 ||
      this.skills.findIndex((s) => s.userCount <= 2) !== -1;
    return value;
  }

  /**
   * Get the subtitle html text to be displayed
   */
  private getSubtitle(): string {
    let value: string;
    if (this.hasJobRole && this.skills.length === 0) {
      value = this.i18n.PersonalSkillView_Top10SkillsSubtitle2;
    } else if (
      this.hasJobRole &&
      this.skills.length > 0 &&
      this.skills.findIndex((s) => s.userCount <= 2) !== -1
    ) {
      value = this.i18n.PersonalSkillView_Top10SkillsSubtitle3;
    } else if (
      (!this.hasJobRole && this.skills.length > 0) ||
      !this.hasJobRole ||
      this.skills.length === 0
    ) {
      value = this.translate.instant('PersonalSkillView_Top10SkillsSubtitle4', {
        profileSettings: `/${this.authService.authUser.viewerProfile.profileUrl}/settings`,
      });
    } else {
      value = this.i18n.PersonalSkillView_Top10SkillsSubtitle1;
    }
    return value;
  }

  /**
   *  Get the title html text to be displayed
   */
  private getTitle(): string {
    let value: string;
    const skills = this.skills.filter((s) => s.tagId !== 0);
    if (skills.length >= this.TOP_COUNT && skills[0].userCount > 2) {
      value = this.translate.instant('PersonalSkillView_Top10SkillsTitle1', {
        count: this.TOP_COUNT,
      });
    } else {
      value = this.i18n.PersonalSkillView_Top10SkillsTitle2;
    }
    return value;
  }
}
