import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '../window.token';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private context: string;

  constructor(@Inject(WindowToken) private windowRef: Window) {}

  public getContext() {
    return this.context;
  }

  public setContext(context: string) {
    this.context = context;
  }

  public addContextToUrl(url: string, context: string) {
    return this.appendContextParam(url, context);
  }

  public addContextToUrls(items: any[], propertyName: string, context: string) {
    items.forEach((item) => {
      item[propertyName] = this.appendContextParam(item[propertyName], context);
    });
    return items;
  }

  public urlHasContext(url: string, context: string) {
    context = context.toLowerCase();
    return url.toLowerCase().indexOf('context=' + context) > -1;
  }

  public isChannel() {
    const hasChannelContext = this.urlHasContext(
      this.windowRef.location.href,
      'channel'
    );
    const urlInChannel = this.urlInChannel();
    return hasChannelContext || urlInChannel;
  }

  public urlInChannel() {
    const inChannelKey = '/channel';
    return (
      this.windowRef.location.pathname.startsWith(inChannelKey + '/') ||
      this.windowRef.location.pathname === inChannelKey
    );
  }

  public workingInTenant() {
    const inTenantKey = 'tenant';
    return (
      this.windowRef.location.pathname.indexOf(inTenantKey) > -1 ||
      this.windowRef.location.search.indexOf(inTenantKey) > -1 ||
      this.windowRef.location.hash.indexOf(inTenantKey) > -1
    );
  }

  private appendContextParam(url: any, context: string) {
    const contextKeyVal = 'context=' + context;
    const qsIndex = url.indexOf('?');
    const hasQs = qsIndex > -1;
    const hashIndex = url.indexOf('#');
    const hasHash = hashIndex > -1;
    const hashBeforeQs = hasHash && hasQs && qsIndex > hashIndex;
    // page#/hash?qs
    if (hashBeforeQs) {
      url = [
        url.slice(0, hashIndex),
        '?' + contextKeyVal + '#',
        url.slice(hashIndex + 1),
      ].join('');
    } else if (!hasQs) {
      // page/ or page#/1
      url += '?' + contextKeyVal;
    } else {
      // page?qs=1, page?q=s#/1, etc.
      url = [
        url.slice(0, qsIndex + 1),
        contextKeyVal,
        '&' + url.slice(qsIndex + 1),
      ].join('');
    }
    return url;
  }
}
