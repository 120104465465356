import { OrganizationUserCounts } from '../../../services/orgs.model';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-member-counts',
  templateUrl: './member-counts.component.html',
})
export class MemberCountsComponent implements OnInit {
  @Input() public memberCounts: OrganizationUserCounts;
  @Input() public isStandaloneOrg: boolean;

  public isOverSubscribed: boolean;
  public memberCountI18n: string;
  public anonymousCountI18n: string;
  public anonymousCountSingleI18n: string;
  public remainingSeats: string;
  public showRemainingSeats: boolean;

  public remainingSeatsTooltip: string = this.translateService.instant(
    'OrgMembersCtrl_SeatsRemainingTooltip'
  );

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.memberCountI18n = this.translateService.instant(
      'OrgMembersCtrl_UsersCountFormat',
      {
        count:
          this.memberCounts.userCount - this.memberCounts.anonymousUserCount,
      }
    );

    this.anonymousCountI18n = this.translateService.instant(
      'OrgMembersCtrl_AnonUsersCountFormat',
      {
        count: this.memberCounts.anonymousUserCount,
      }
    );

    this.anonymousCountSingleI18n = this.translateService.instant(
      'OrgMembersCtrl_AnonUsersCountSingle'
    );

    this.showRemainingSeats =
      Number.isInteger(this.memberCounts?.remainingSeats) &&
      this.isStandaloneOrg;

    if (this.showRemainingSeats) {
      this.isOverSubscribed = this.memberCounts?.remainingSeats < 1;
      const remainingSeatsKey =
        this.memberCounts?.remainingSeats !== -1 &&
        this.memberCounts?.remainingSeats !== 1
          ? 'OrgMembersCtrl_SeatsRemainingPluralFormat'
          : 'OrgMembersCtrl_SeatsRemainingFormat';

      this.remainingSeats = this.translateService.instant(remainingSeatsKey, {
        count: this.memberCounts.remainingSeats,
      });
    }
  }
}
