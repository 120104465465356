<form class="form-wrap" (ngSubmit)="handleSubmit()">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="'Core_Send' | translate"
    [isSubmitDisabled]="!canSubmit || isSubmitting"
    [useDefaultForm]="false"
    [isSubmitPending]="isSubmitting"
  >
    <ng-container class="modal-header">
      {{ 'problemModalContent_Title' | translate }}
    </ng-container>
    <ng-container class="modal-body">
      <div class="grid">
        <div class="grid__col-12 guts-p-full-1">
          <fieldset class="guts-p-b-1">
            <legend class="guts-p-b-1">
              {{ 'problemModalContent_Reason' | translate }}
            </legend>
            <ng-container *ngIf="!showIssueSkeleton; else loading">
              <div
                class="guts-m-t-1"
                *ngFor="
                  let type of feedbackTypes;
                  let first = first;
                  let index = index
                "
                data-dgat="report-problem-modal-6ca"
              >
                <input
                  *ngIf="first"
                  dgxAutofocus
                  type="radio"
                  name="feedbackType"
                  [(ngModel)]="selectedFeedbackType"
                  id="reportProblemTypeRadioButton_{{ type.name }}"
                  [checked]="selectedFeedbackType === type.name"
                  [value]="type.name"
                  class="guts-m-r-half"
                  (change)="changeSelectedType()"
                  [attr.data-dgat]="'report-problem-modal-af0-' + index"
                />
                <input
                  *ngIf="!first"
                  type="radio"
                  name="feedbackType"
                  [(ngModel)]="selectedFeedbackType"
                  id="reportProblemTypeRadioButton_{{ type.name }}"
                  [checked]="selectedFeedbackType === type.name"
                  [value]="type.name"
                  class="guts-m-r-half"
                  (change)="changeSelectedType()"
                  [attr.data-dgat]="'report-problem-modal-af0-' + index"
                />
                <label
                  for="reportProblemTypeRadioButton_{{ type.name }}"
                  class="font-medium ib-v-middle"
                  >{{ type.name | translate }}</label
                >
              </div>
            </ng-container>
          </fieldset>
          <div *ngIf="selectedFeedbackType">
            <fieldset class="guts-p-v-1" *ngIf="currentSubtypes?.length > 0">
              <legend class="guts-p-b-1 form-label">
                {{ subissueLabel }}<df-label-marker></df-label-marker>
              </legend>

              <div class="guts-m-t-half">
                <dgx-select
                  [isRequired]="true"
                  id="selectedFeedbackSubtype"
                  class="full-width"
                  trackBy="name"
                  [labelKey]="'i18n'"
                  [ariaLabel]="subissueLabel"
                  [selectedItem]="selectedFeedbackSubtype"
                  [placeholder]="
                    'problemModalContent_SubissuePlaceholder' | translate
                  "
                  [options]="currentSubtypes"
                  (selection)="changeSelectedSubtype($event)"
                  dgatInput="report-problem-modal-304"
                >
                </dgx-select>
              </div>
            </fieldset>
            <df-local-notification
              *ngIf="warningMessage"
              [type]="NotificationType.info"
              [text]="warningMessage"
              size="small"
            ></df-local-notification>
            <div *ngIf="showAdditionalDetails()">
              <label
                for="reportProblemTextbox"
                class="guts-p-t-1 guts-p-b-half form-label"
              >
                {{ 'problemModalContent_AdditionalDetails' | translate
                }}<df-label-marker
                  *ngIf="isExplanationRequired"
                ></df-label-marker>
              </label>
              <textarea
                [required]="isExplanationRequired"
                id="reportProblemTextbox"
                name="comments"
                [(ngModel)]="message"
                (ngModelChange)="changeTextbox()"
                (blur)="changeTextbox()"
                data-dgat="report-problem-modal-069"
                [placeholder]="
                  'problemModalContent_AdditionalDetailsPlaceholder' | translate
                "
              ></textarea>
              <div role="alert" aria-live="assertive" class="ng-scope">
                <div
                  class="form__invalid-msg ib rel ng-binding ng-scope"
                  [@dfSlideFadeInLeft]
                  *ngIf="hasExplanationError"
                >
                  <df-icon icon="arrow-down-right" size="small"></df-icon>
                  {{
                    'inappropriateModalContent_ReasonValidationError'
                      | translate
                  }}
                </div>
              </div>
              <div class="guts-p-v-1">
                <label class="guts-p-b-half">{{
                  'problemModalContent_AttachScreenshot' | translate
                }}</label>

                <div>
                  <dgx-file-uploader
                    [showCancel]="false"
                    [targetLabel]="'OrgFtp_DragAndDropImage' | translate"
                    (successEvent)="onUploadSuccess($event)"
                    [uploadApiEndpoint]="'/files/uploadscreenshot'"
                    [templateOption]="'compact'"
                    [fileTypes]="'.jpg,.jpeg,.gif,.png'"
                    [sizeLimit]="5"
                    [limitNotificationLabel]="
                      'problemModalContent_FileRestrictionDescription'
                        | translate
                    "
                  ></dgx-file-uploader>
                </div>

                <p class="guts-p-t-1 par par--small par--light">
                  {{ 'problemModalContent_UploadScreenshotDetail' | translate }}
                </p>
              </div>
              <div class="l_flex guts-p-t-1">
                <input
                  id="problemContentPermissionCheckbox"
                  type="checkbox"
                  class="guts-m-t-quart"
                  name="okayToNotify"
                  [(ngModel)]="okayToNotify"
                  data-dgat="report-problem-modal-640"
                />
                <label
                  for="problemContentPermissionCheckbox"
                  class="guts-m-l-half"
                  >{{
                    'problemModalContent_ContactPermission' | translate
                  }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>

<ng-template #loading>
  <div class="guts-p-b-1">
    <div class="skeleton-container">
      <div class="skeleton">
        <div
          class="skeleton__line skeleton__line--medium bg-animate guts-m-t-half"
        ></div>
        <div
          class="skeleton__line skeleton__line--medium bg-animate guts-m-t-half"
        ></div>
        <div
          class="skeleton__line skeleton__line--medium bg-animate guts-m-t-half"
        ></div>
        <div
          class="skeleton__line skeleton__line--medium bg-animate guts-m-t-half"
        ></div>
      </div>
    </div>
  </div>
</ng-template>
