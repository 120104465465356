import { Skill } from '@app/opportunities/opportunities-api.model';
import { TagComparisonByUser } from '@app/insights/insights-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { TitleSuggestion } from '@app/target/target-api.model';

/**
 * Front-end only interfaces
 *
 * NOTE: Tag API interfaces belong in `@app/tags/tag-api.d.ts`
 */

/**
 * Extends `TitleSuggestion` w/endorsment image properties
 */
export interface TagsSearchItem extends TitleSuggestion {
  endorsedSrc?: string;
  endorsedAlt?: string;
  exists?: boolean;
}

/**
 * Extends base `Tag` with tracking properties
 */
export interface OwnerTag extends Partial<TagsApi.TagDetails> {
  isFocusSkillForOwner?: boolean;
  isOnProfileForOwner?: boolean;
}

export interface RatingModalProperties {
  event?: Event;
  title?: string;
  tag: Partial<TagsApi.TagDetails> | TagComparisonByUser | Skill;
  ratingType?: InternalTagRatingTypes;
  emptyRating?: boolean;
  showComments?: boolean;
  placeholderText?: string;
  initialValue?: number;
  instructionsText?: string;
  addNewSkill?: boolean;
  windowClass?: string;
  updateHistoryData?: any;
  errorOnDismiss?: boolean;
  headerOptions?: {
    isBorderless: boolean;
    isCentered: boolean;
  };
  bodyClasses?: string;
  bodyStyle?: any;
  userData?: any;
  maxMessageLength?: number;
  allowClearRating?: boolean;
  requester?: any;
}

export interface RatingLevelNames {
  [key: number]: string;
}

export interface TagRatingMeta {
  date?: string;
  duration?: number;
  cost?: number;
  visibility?: string;
}

export interface TagRatingResponse {
  userTagRatingId: number;
}

export interface ManagerUserTagRatingInfoResponse {
  dateCompleted: string;
  level: string;
  raterName: string;
}

/**
 * Enums for rating types
 * rating hierarchy: Credential, Evaluation, Manager, Peer, Self
 */
export enum InternalTagRatingTypes {
  credential = 'Credential',
  evaluation = 'Evaluation',
  manager = 'Manager',
  peer = 'Peer',
  self = 'Self',
  target = 'Goal',
  signals = 'Signals',
  checkpoint = 'Checkpoint',
  total = 'Total',
}

export enum TotalTagRatings {
  total = 'Total',
}

export enum TagSource {
  source = 'Source',
}

export enum TagRatingPrivacy {
  private = 0,
  public = 1,
}

export const enum PluralsightSkillRating {
  Novice = 'novice',
  ProficientEmerging = 'proficient-emerging',
  ProficientAverage = 'proficient-average',
  ProficientAboveAverage = 'proficient-above-average',
  Expert = 'expert',
}

export const enum PluralsightSkillRatingAsNumber {
  Novice = 1,
  ProficientEmerging = 2,
  ProficientAverage = 3,
  ProficientAboveAverage = 4,
  Expert = 5,
}
