import { Inject, Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { NotifierService } from '@app/shared/services/notifier.service';
import { isFunction } from '@app/shared/utils/type-guard-helpers';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MediaDevicesToken } from './media-devices.token';

@Injectable()
export class AuthorVideoService {
  public static MimeTypes = [
    'video/webm;codecs=opus,vp9',
    'video/webm;codecs=opus,vp8',
    'video/webm',
  ];

  constructor(
    private notifier: NotifierService,
    private translate: TranslateService,
    @Inject(MediaDevicesToken)
    private mediaDevicesRef: any
  ) {}

  /**
   * Initialize a MediaStream from the user's MediaDevices
   */
  public initializeMediaStream(
    constraint: MediaStreamConstraints
  ): Observable<MediaStream> {
    // feature detect support
    if (
      !this.mediaDevicesRef ||
      !isFunction(this.mediaDevicesRef.getUserMedia)
    ) {
      return throwError(
        new DgError(this.translate.instant('dgAuthorVideo_recorderError'))
      );
    }

    const mediaDevices = this.mediaDevicesRef as MediaDevices;
    return from(mediaDevices.getUserMedia(constraint)).pipe(
      catchError((error) => {
        const { name: errorName } = error;

        // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
        let errorMessageKey;
        if (errorName.includes('NotFoundError')) {
          errorMessageKey = 'dgAuthorVideo_CameraNotFoundError';
        } else if (errorName.includes('NotAllowedError')) {
          errorMessageKey = 'dgAuthorVideo_CameraNotAuthorizedError';
        } else if (errorName.includes('NotReadableError')) {
          errorMessageKey = 'dgAuthorVideo_CameraNotReadableError';
        } else {
          errorMessageKey = 'dgAuthorVideo_recorderError';
        }

        if (errorMessageKey) {
          error = new DgError(this.translate.instant(errorMessageKey), error);
        }

        return throwError(error);
      })
    );
  }

  /**
   * Get a configured MediaRecorder instance
   */
  public getMediaRecorder(stream: MediaStream): MediaRecorder {
    const options: MediaRecorderOptions = {
      mimeType: '',
    };

    // find a supported mimeType
    for (const mimeType of AuthorVideoService.MimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        options.mimeType = mimeType;
        break;
      }
    }

    try {
      const mediaRecorder = new MediaRecorder(stream, options);
      return mediaRecorder;
    } catch (e) {
      this.notifier.showError(
        this.translate.instant('dgAuthorVideo_recorderError')
      );
    }
  }
}
