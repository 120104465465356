import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { DfButtonBasicComponent } from '../../forms/buttons/components/button-basic/button-basic.component';

/**
 *
 * @example
 * ```
 * <button dg-button dgButtonType='primary'>
 *     <df-spinner-button [isSpinning]="isSubmitting">Button Text</df-spinner-button>
 * </button>
 * ```
 */
@Component({
  selector: 'df-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
})
export class DfSpinnerButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public isSpinning: boolean;
  @Output() public isSpinningChange = new EventEmitter<boolean>();

  public i18n = {
    loading: 'Loading', // TODO: translate 'Core_Loading' when possible in Fresco
  };
  public buttonParentElement: HTMLButtonElement;

  private unlisteners = [];

  constructor(
    private renderer: Renderer2,
    @Inject(DfButtonBasicComponent) private parentButton: DfButtonBasicComponent
  ) {}

  public ngOnInit() {
    this.buttonParentElement = this.parentButton.elementRef.nativeElement;
    if (this.isSpinning === undefined) {
      // the optional binding is not being used, spin when button is clicked
      this.unlisteners.push(
        this.renderer.listen(this.buttonParentElement, 'click', () => {
          this.spin();
        })
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.buttonParentElement) {
      this.spin(changes.isSpinning.currentValue);
    }
  }

  public ngOnDestroy() {
    this.removeListeners();
  }

  private spin(doSpin: boolean = true) {
    doSpin ? this.disableButton() : this.enableButton();
    this.isSpinning = doSpin;
  }

  private disableButton() {
    // TODO: update with WindowToken when available in Fresco
    window.setTimeout(() => {
      // wait for any relevant form submission (ngSubmit) before disabling the button in case the button is `type="submit"`
      this.renderer.setAttribute(this.buttonParentElement, 'disabled', '');
    });
  }

  private enableButton() {
    this.renderer.removeAttribute(this.buttonParentElement, 'disabled');
  }

  private removeListeners() {
    for (const unlisten of this.unlisteners) {
      unlisten();
    }
    this.unlisteners = [];
  }
}
