import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth.service';
import { FlexEdDetails } from '@app/shared/services/flexed.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'dgx-flex-ed-modal',
  templateUrl: './flex-ed-modal.component.html',
})
export class FlexEdModalComponent implements OnInit {
  public isNewFlexEdLinked: boolean = false;
  public saving: boolean = false;
  public errorText: string;
  public submitGeneralError: boolean;
  public submitMultipleCardsError: boolean;
  public flexEdForm = this.formBuilder.group({
    name: ['', Validators.required],
    cardNumber: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp('^\\d{4}$')),
      ]),
    ],
  });

  @Input() public cardDetails?: FlexEdDetails = null;
  @Input() public updateCardDetails: ({ cardNumber, name }) => Observable<void>;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private translate: TranslateService
  ) {}

  get name() {
    return this.flexEdForm.get('name');
  }

  get cardNumber() {
    return this.flexEdForm.get('cardNumber');
  }

  get saveButtonText() {
    return !this.isPexUser
      ? this.translate.instant('flexEdModal_ConnectCardButton')
      : this.translate.instant('flexEdModal_SaveInfoButton');
  }

  get isPexUser() {
    return !!this.cardDetails;
  }

  get userProfileFlexUrl() {
    let url: string;
    const profileUrl = this.authService.authUser.viewerProfile.profileUrl;
    url = `/profile/${profileUrl}/flex-ed`;
    return url;
  }

  public ngOnInit(): void {
    if (this.cardDetails) {
      this.flexEdForm.patchValue({
        name: `${this.cardDetails.firstName} ${this.cardDetails.lastName}`,
        cardNumber: this.cardDetails.card.cardNumber.substr(
          this.cardDetails.card.cardNumber.length - 4
        ),
      });
    }
  }

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public submit() {
    this.errorText = null;
    this.saving = true;
    this.updateCardDetails({
      cardNumber: this.cardNumber.value,
      name: this.name.value,
    }).subscribe(
      () => {
        this.saving = false;
        if (this.isPexUser) {
          this.activeModal.close(true);
          this.notifierService.showSuccess(
            this.translate.instant('flexEdModal_SavedNewInformation')
          );
        } else {
          // tell utility bar to update with FlexEd $$
          this.isNewFlexEdLinked = true;
        }
      },
      (error) => {
        this.saving = false;
        if (error.status === 409) {
          this.errorText = this.translate.instant(
            'flexEdModal_MultipleCardsError'
          );
        } else {
          this.errorText = this.translate.instant(
            'flexEdModal_NameDoesNotMatchCardError'
          );
        }
      }
    );
  }
}
