import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/** Provides element keyboard focus management methods for popovers, modals and other components that programmatically manipulate focus. */
@Injectable({ providedIn: 'root' })
export class FocusStackService {
  private nodes: HTMLElement[] = [];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /** Gets the most recently pushed node on the stack, if any, or `undefined` otherwise. */
  public get top() {
    return this.nodes[this.nodes.length - 1];
  }

  /** Pushes `element` onto the focus history stack, or the currently focused element in the document if `element` is undefined. */
  public push(element?: HTMLElement) {
    if (!element) {
      element = this.document.activeElement as HTMLElement;
    }
    if (!element) {
      console.error('No element has keyboard focus');
    }
    this.nodes.push(element);
  }

  /** Pops the most recently pushed element off the stack and focuses it. */
  public pop() {
    if (this.nodes.length === 0) {
      return console.error(
        'No focusable elements are available on the focus stack'
      );
    } else {
      let focusTargetEl: HTMLElement;
      let hasError: boolean = false;
      do {
        if (this.nodes.length === 0) {
          hasError = true;
          console.error('Focus stack element(s) are no longer in the DOM.');
          break;
        }
        focusTargetEl = this.nodes.pop();
      } while (!this.document.body.contains(focusTargetEl));
      if (!hasError) {
        focusTargetEl.focus();
        return focusTargetEl;
      }
    }
  }

  /**
   * Removes ALL nodes from the list
   * Added for PD-43933 to allow for replacing an active popover in the stack without re-focusing
   */
  public clear() {
    this.nodes = [];
  }
}
