import { Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

// 'upgraded'
import { WindowToken } from '@app/shared/window.token';

// misc
import { DgError } from '@app/shared/models/dg-error';
import { getTagLevel } from '@app/shared/utils/tag-helpers';
import { booleanFromString } from '@app/shared/utils/common-utils';
import { hasExternalProvider, isUserInterested } from '../utils';

// types
import {
  Opportunity,
  OpportunityDetails,
  OpportunitySkillModel,
  Skill,
} from '../opportunities-api.model';
import { UserSearchItem } from '@app/user/user-api.model';

// services
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';
import { QueueService } from '@app/shared/services/queue.service';
import { ResourceOwner } from '@app/shared/models/core-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';

/**
 * All shared Opportunities service methods should be here.
 */
export abstract class OpportunityServiceBase {
  public i18n = this.translateService.instant([
    'Opportunities_Apply',
    'Opportunities_Interested',
    'Opportunities_RemoveInterest',
    'Opportunities_ShowInterest',
    'Opportunities_Success_AddExperience',
    'OrgManage_Opportunities_Create',
    'OrgManage_Opportunities_DeletePrompt_Title',
    'OrgManage_Opportunities_DeletePrompt_Description',
    'OrgManage_Opportunities_Edit',
    'OrgManage_Opportunities_Error_Edit',
    'OrgManage_Opportunities_VisibleCollaborators',
    'OrgManage_Opportunities_VisibleGroups',
    'OrgManage_Opportunities_VisibleOrganization',
    'TargetCtrl_ConfirmDeleteButton',
  ]);
  protected readonly baseUrl: string = '/opportunities';
  protected readonly deletePhrase = 'DELETE';
  protected deleteConfirm = this.i18n.TargetCtrl_ConfirmDeleteButton;
  protected deleteDescription =
    this.i18n.OrgManage_Opportunities_DeletePrompt_Description;
  protected deleteInstructions = this.translateService.instant(
    'TargetCtrl_DeleteInstructions',
    { delete: this.deletePhrase }
  );
  protected deleteTitle = this.i18n.OrgManage_Opportunities_DeletePrompt_Title;

  constructor(
    protected authService: AuthService,
    protected http: NgxHttpClient,
    protected notifierService: NotifierService,
    protected pageTitle: Title,
    protected translateService: TranslateService,
    @Inject(WindowToken) protected windowRef: Window
  ) {}

  // public methods

  public addBulkCollaborators(collaboratorsData: {
    opportunityIds: number[];
    userProfileKeys: number[];
  }) {
    return this.http
      .put(`${this.baseUrl}/bulkaddcollaborators`, collaboratorsData)
      .pipe(mapTo(collaboratorsData));
  }

  public addOpportunity(
    opportunity: Opportunity,
    isCloning = false
  ): Observable<Opportunity> {
    const opp = { ...opportunity };

    // NOTE: when cloning, we DON'T want to send the `externalId` since
    // these are unique for uploads
    if (isCloning) {
      // deleting the prop instead of setting it to null or undefined
      delete opp.externalId;
    }

    return (
      this.http
        // this endpoint returns the id of the opportunity that was created
        .post<number>(`${this.baseUrl}/addopportunity`, opp, {
          params: { isCloning: `${isCloning}` },
        })
        // send back the opportunity that was sent in after the id has been updated
        .pipe(map((opportunityId) => ({ ...opp, opportunityId })))
    );
  }

  /**
   * Checks if a user's skills match an opportunity's skills by name
   * @param authUser the authenticated user's object
   * @param opportunitySkills a list (names) of an opportunity's skills
   */
  // TODO: Clean this up for Ngx. Determine how much of this casing logic
  // is still needed.
  public checkMatchingSkills(
    authUser, // not typed because we need types from both the js app and the ngx app (line 163) and our build isn't set up for that yet
    opportunitySkills: any[]
  ): OpportunitySkillModel[] {
    const skillMatches = [];
    const userSkills = authUser.userInterests || authUser.viewerInterests;

    if (userSkills.length) {
      opportunitySkills.forEach((opportunitySkill) => {
        let skillName;
        if (opportunitySkill.Name) {
          // upper case N (o.Name)
          skillName = opportunitySkill.Name;
        } else if (opportunitySkill.name) {
          // lower case n (o.name)
          skillName = opportunitySkill.name;
        } else {
          skillName = opportunitySkill;
        }

        // check u.Name (upper case N) and u.name (lower case n)
        skillMatches.push({
          name: skillName,
          isMatch: userSkills.some(
            (userSkill) =>
              userSkill.Name?.toUpperCase() === skillName.toUpperCase() ||
              userSkill.name?.toUpperCase() === skillName.toUpperCase()
          ),
        });
      });
    }

    return skillMatches;
  }

  public editCollaborators(
    opportunityId: number,
    authorProfileKeys: number[]
  ): Observable<UserSearchItem> {
    return this.http
      .put<UserSearchItem>('/opportunities/updateauthors', {
        opportunityId,
        authorProfileKeys,
      })
      .pipe(
        catchError((error: Error) =>
          throwError(
            new DgError(
              this.translateService.instant(
                'OrgManage_Opportunities_Error_Edit'
              ),
              error
            )
          )
        )
      );
  }

  public editOpportunity(opportunity: Opportunity): Observable<Opportunity> {
    return (
      this.http
        // this endpoint returns no content
        .put(`${this.baseUrl}/updateopportunity`, opportunity)
        // send back the opportunity that was sent in
        .pipe(mapTo(opportunity))
    );
  }

  /**
   * Returns a singular localized Opportunity type(s), translating if internal.
   * If there is any possibility you are working with an array, use `Types` version
   * instead.
   *
   * @param opportunity - Current opportunity.
   */
  public getDisplayOpportunityType(opportunity: Opportunity): string {
    if (opportunity.typeDescription) {
      return opportunity.typeDescription;
    }

    return this.needsLocalization(opportunity)
      ? this.translateService.instant('Opportunities_Type' + opportunity.type)
      : opportunity.type;
  }

  /**
   * Returns opportunity types for display, translating if necessary.
   * Returns a string, comma-separated if passed an array.
   *
   * @param opportunity - Current opportunity.
   */
  public getDisplayOpportunityTypes(opportunity: Opportunity): string {
    // TODO: Determine whether we are ever going to actually have more than one type on an Opp.
    // It currently isn't possible but we have a lot of logic handling the hypothetical case.
    if (Array.isArray(opportunity.type)) {
      return opportunity.type
        .map((type: string) =>
          this.getDisplayOpportunityType({
            ...opportunity,
            type,
          })
        )
        .join(', ');
    } else if (opportunity.type?.name) {
      // TODO: sometimes the type is an object and when we address the issue in the BE
      // around types sometimes being an array we should fix this at the same time.
      return this.getDisplayOpportunityType({
        ...opportunity,
        type: opportunity.type.name,
      });
    }

    // must be a string! Handle individually
    return this.getDisplayOpportunityType(opportunity);
  }

  /**
   * Get the text for the interested button of a given opportunity.
   *
   * @param opportunity - the opportunity in question
   */
  public getInterestedButtonText(opportunity: Opportunity): string {
    return hasExternalProvider(opportunity)
      ? this.i18n.Opportunities_Apply
      : isUserInterested(opportunity)
      ? this.i18n.Opportunities_Interested
      : this.i18n.Opportunities_ShowInterest;
  }

  /**
   * Get the tooltip for the interested button of a given opportunity.
   *
   * @param opportunity - the opportunity in question
   */
  public getInterestedButtonTooltip(opportunity: Opportunity): string {
    return isUserInterested(opportunity)
      ? this.i18n.Opportunities_RemoveInterest
      : '';
  }

  /**
   * Get the *only* opportunity from the BE `getopportunityoverview`
   * call. If you only need the opportunity with its resources, use
   * GetOpportunityOverview instead.
   *
   * @param opportunityId - The opportunity ID, as a number.
   */
  public getOpportunity(opportunityId: number): Observable<Opportunity> {
    return this.http
      .get<{
        opportunity: Opportunity;
        userCanEditOpportunity: boolean;
      }>('/opportunities/getopportunityoverview', {
        params: {
          opportunityId,
        },
      })
      .pipe(
        map(({ opportunity }) => opportunity),
        catchError((error) =>
          throwError(
            new DgError(
              this.translateService.instant('Opportunities_View_Error_Get'),
              error
            )
          )
        )
      );
  }

  /**
   * Get the full payload of the BE `getopportunityoverview` call.
   * If you only need the opportunity with its resources, use
   * GetOpportunityOverview instead.
   *
   * @param opportunityId - The opportunity ID, as a number.
   */
  public getOpportunityDetailsBasis(
    opportunityId: number
  ): Observable<OpportunityDetails> {
    // get our complete data
    return forkJoin([
      // get the opportunity
      this.http.get<{
        opportunity: Opportunity;
        userCanEditOpportunity: boolean;
      }>(`${this.baseUrl}/getopportunityoverview`, {
        params: {
          opportunityId,
        },
      }),
      this.getOpportunityResourcesAPICall(opportunityId),
    ]).pipe(
      map(([{ opportunity, userCanEditOpportunity }, { resources }]) => ({
        hasExternalProvider: hasExternalProvider(opportunity),
        opportunity: {
          opportunityResources: resources,
          ...this.formatUserOpportunityData(opportunity),
        },
        totalSkills: opportunity.tags.length,
        userCanEditOpportunity: booleanFromString(userCanEditOpportunity),
      }))
    );
  }

  /**
   * Get an opportunity from the backend by ID, complete with resources.
   *
   * @param opportunityId - Required. Opportunity ID.
   */
  public getOpportunityOverview(
    opportunityId: number
  ): Observable<Opportunity> {
    // get our complete data
    return this.getOpportunityDetailsBasis(opportunityId).pipe(
      // but this call *only* cares about the full opportunity with added resources
      map(({ opportunity }) => opportunity),
      catchError((error) =>
        // careful this will return an error in the the form of an
        // observable that emits only an error, not throw an actual error
        throwError(
          new DgError(
            this.translateService.instant('Opportunities_View_Error_Get'),
            error
          )
        )
      )
    );
  }

  /**
   * Get opportunity resources from the backend for provided opportunity ID
   * @param opportunityId
   */
  public getOpportunityResources(opportunityId: number) {
    return this.getOpportunityResourcesAPICall(opportunityId).pipe(
      catchError((error) =>
        // careful this will return an error in the the form of an
        // observable emits only an error, not throw an actual error
        throwError(
          new DgError(
            this.translateService.instant('Opportunities_View_Error_Get'),
            error
          )
        )
      )
    );
  }

  /**
   * Return the total number of skills with targets, for an opp that has targets.
   *
   * @param skills - The tags to check.
   * @param hasTargetSkillLevels - Whether the opportunity has any target levels.
   */
  public getTotalTargetSkillLevels(
    skills: Skill[],
    hasTargetSkillLevels: boolean
  ): number {
    return hasTargetSkillLevels
      ? skills?.filter((skill) => getTagLevel(skill) > 0).length
      : 0;
  }

  /**
   * Navigate to the opportunity landing page.
   *
   * @param id - The opportunity id.
   */
  public navigate(id: number): void {
    // yes...this is outside of our angular routing :-(
    // open the opportunity in the same tab/window
    this.windowRef.location.href = `/opportunities/${id}/`;
  }

  /**
   * Update the page header dynamically.
   *
   * @param sectionTitle - The (translated) text to prepend the page title with.
   */
  public updatePageTitle(sectionTitle?: string): void {
    this.pageTitle.setTitle(`${sectionTitle || 'Degreed'} | Degreed`);
  }

  /**
   * Update (add/remove) the resources associated with the opportunity
   *
   * @param opportunityId - The opportunity ID.
   * @param resources - The updated list of -all- the associated resources
   */
  public updateResources(
    opportunityId: number,
    resources: any // ResourceReference this type is questionable
  ): Observable<any> {
    return this.http.put(`${this.baseUrl}/upsertopportunityresources`, {
      opportunityId,
      resources,
    });
  }

  private formatUserOpportunityData(opportunity: Opportunity) {
    return {
      ...opportunity,
      matchedSkills: opportunity.matchedSkills?.map((skill) => ({
        title: skill.name,
        ...skill,
      })),
      unMatchedSkills: opportunity.unMatchedSkills?.map((skill) => ({
        title: skill.name,
        ...skill,
      })),
      // Add total target skill levels -- this is only really
      // *used* by the Interested tab, but it being periodically
      // undefined causes all kinds of bugs. Plus, why do this
      // every time the interested tab loads, if it's not necessary?
      // One extra field won't hurt the other components.
      totalTargetSkillLevels: this.getTotalTargetSkillLevels(
        opportunity.tags,
        opportunity.hasTargetSkillLevels
      ),
    };
  }

  private getOpportunityResourcesAPICall(opportunityId: number): Observable<{
    opportunityId: number;
    resources: ResourceOwner<LearningResourceViewModel>[];
  }> {
    return this.http.get(`${this.baseUrl}/getopportunityresources`, {
      params: {
        opportunityId,
        useResourceImages: true,
      },
    });
  }

  // Extracted out to a private method in case we change how we determine this.
  private needsLocalization(opportunity: Opportunity): boolean {
    return !!opportunity.internalType;
  }
}
