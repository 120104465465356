import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[dgxUploader]',
})
/*
 * UploaderDirective
 *   Directive for applying file drag and drop behavior to any appropriate upload markup
 *   Avoid using this directive directly; instead use or improve one of the prepared upload
 *     components that consume this directive
 */
export class UploaderDirective {
  @HostBinding('class') public dropzoneclass = 'uploader__dropzone';
  @Output() public fileDropped = new EventEmitter<FileList>();
  @Output() public errorHandler = new EventEmitter<any>();
  @Output() public dragState = new EventEmitter<any>();
  @Input() public uploadConfigs: object;

  constructor() {}

  @HostListener('dragover', ['$event']) public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneclass = 'uploader__dropzone uploader__dropzone--over';
    this.dragState.emit('dragover');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneclass = 'uploader__dropzone uploader__dropzone--out';
    this.dragState.emit('dragleave');
  }

  @HostListener('drop', ['$event']) public ondrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropzoneclass = 'uploader__dropzone uploader__dropzone--dropped';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
