import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CachedHttpRequest, RequestCache } from '../caching';

/** An HTTP interceptor that conditionally provides caching, similar to angularjs's $http methods with
 *  <pre><code>options: {cache: true } </code></pre>
 */
@Injectable({ providedIn: 'root' })
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse =
      req instanceof CachedHttpRequest && this.cache.get(req);
    return cachedResponse
      ? of(cachedResponse)
      : this.sendRequest(req, next, this.cache);
  }

  public sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
