<dgx-optional-navigation
  [descriptionText]="descriptionText"
  [headerImageClasses]="headerImageClasses"
  [headerTextClasses]="'color-ebony-a45'"
  [headerImageUrl]="headerImageUrl"
  [headerText]="headerText"
  [primaryNavigationText]="navigationText"
  [primaryNavigationUrl]="navigationUrl"
  [primaryNavigationDgat]="'not-found-fb9'"
  primaryNavigationType="primary"
></dgx-optional-navigation>
