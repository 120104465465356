import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';

// misc
import { WindowToken } from '@app/shared/window.token';
import {
  AccomplishmentModel,
  GlobalAddAccomplishmentField,
} from '@app/user-content/user-outcome-v2/outcomes/accomplishment/accomplishment.model';
import { AccomplishmentGlobalAddFacade } from '@app/user-content/user-outcome-v2/outcomes/accomplishment/services/global-add/accomplishment-global-add.facade';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AccomplishmentMapperService,
  AccomplishmentService,
  AccomplishmentTrackerService,
} from '../../services';
import { handleAccomplishmentFocusOnSubmit } from '../../services/accomplishment.utils';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Component({
  selector: 'dgx-accomplishment-global-add',
  templateUrl: './accomplishment-global-add.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    AccomplishmentGlobalAddFacade,
    AccomplishmentMapperService,
    OutcomeNotificationService,
    AccomplishmentService,
    AccomplishmentTrackerService,
    OutcomesService,
  ],
})
export class AccomplishmentGlobalAddComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public isEditing: boolean = false;
  @Input() public userOutcomeId: number;

  public accomplishmentGlobalAddForm: FormGroup;

  public i18n = this.translate.instant([
    'Core_AddAccomplishment',
    'Core_Save',
    'Core_Skills',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgUserOutcomeEditForm_AccomplishmentDate',
    'dgUserOutcomeEditForm_DescribeAccomplishment',
    'dgUserOutcomeEditForm_ExpandedClientBase',
    'MediaFormCtrl_TitleRequired',
    'dgUserOutcomeEditForm_EditAccomplishment',
  ]);
  public heading: string;
  public vm$: Observable<AccomplishmentModel>;
  public submitButtonText: string;
  public markSubFormsAsTouched = false;

  constructor(
    private facade: AccomplishmentGlobalAddFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private globalAddTrackingService: GlobalAddTrackingService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.facade.initializeViewModel();
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }

    this.heading = this.isEditing
      ? this.i18n.dgUserOutcomeEditForm_EditAccomplishment
      : this.i18n.Core_AddAccomplishment;

    this.submitButtonText = this.isEditing
      ? this.i18n.Core_Save
      : this.i18n.Core_AddAccomplishment;

    if (this.isEditing) {
      await this.facade.initializeEdit(this.userOutcomeId);
      this.initializeExpandedEditForm();
      return;
    }

    // Set up the form
    this.initializeForm();
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/
  public onFormControlUpdate(field: GlobalAddAccomplishmentField, value: any) {
    this.facade.onFormControlUpdate(
      this.accomplishmentGlobalAddForm,
      field,
      value
    );
  }

  /**
   * On form Submission check if the form is invalid
   */
  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.accomplishmentGlobalAddForm);
    this.markSubFormsAsTouched = true;
    this.cdr.detectChanges();

    if (this.accomplishmentGlobalAddForm.invalid) {
      handleAccomplishmentFocusOnSubmit(
        this.accomplishmentGlobalAddForm.controls
      );
      return;
    }

    try {
      await this.facade.onSubmit(this.accomplishmentGlobalAddForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }
  }

  public loadInferredSkills() {
    this.facade.loadInferredSkills(
      this.accomplishmentGlobalAddForm.get('title').value || ''
    );
  }

  public onNavigateToCollection(collectionUrl: string) {
    this.activeModal.close(this.facade.snapshot);
    if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }

  public onDismiss(event: Event) {
    this.activeModal.dismiss();
    this.globalAddTrackingService.trackGlobalAddCancelled('Accomplishment');
  }

  private initializeExpandedEditForm(): void {
    this.accomplishmentGlobalAddForm = this.formBuilder.group({
      title: this.facade.snapshot.title ?? '',
      noExpiration: this.facade.snapshot.noExpiration ?? false,
      dateRange: this.facade.snapshot.dateRangeForm ?? null,
      skills: [this.facade.snapshot.skills],
    });
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm() {
    this.accomplishmentGlobalAddForm = this.formBuilder.group({
      title: ['', Validators.required],
      noExpiration: [],
      dateRange: [],
      skills: [],
    });
  }
}
