import {
  Pipe,
  PipeTransform,
  ɵBypassType,
  ɵgetSanitizationBypassType,
  ɵunwrapSafeValue,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EncodeToEntitiesPipe } from './encode-to-entities.pipe';

/**
 * A tagged template literal helper to safely compose HTML fragments and escape user provided values.
 */
@Pipe({
  name: 'html',
})
export class HtmlPipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
    protected encodeToEntitiesPipe: EncodeToEntitiesPipe
  ) {}

  /**
   * Use in combination with @see HtmlTranslatedPipe to safely pass HTML-surrounded
   * variables into interpolated strings. Mind the lack of () around the template strings!
   *
   * @example
   * ```
   * bodyText: this.htmlTranslatedPipe.transform(
   *  'dgFlexRow_DeleteSectionPrompt',
   *    {
   *      type: this.htmlPipe.transform`<span class="font-semibold">${
   *        section.name || sectionDefaultTitle
   *        }</span>`,
   *    }
   *  ),
   * ```
   */
  public transform(
    templates: TemplateStringsArray,
    ...variables: any[]
  ): SafeHtml {
    let result = '';
    for (let i = 0; i < templates.length; i++) {
      result += templates[i]; // Templates are provided by the developer and implicitly trusted
      const va = variables[i];
      if (va !== undefined && va !== null) {
        if (this.isSafeHtml(va)) {
          // If this is already a safe fragment, just add it to the result
          result += ɵunwrapSafeValue(va);
        } else {
          // Otherwise, turn it into a string and escape it
          result += this.encodeToEntitiesPipe.transform(va.toString());
        }
      }
    }

    // Because the template fragments are trusted and all of the variables were either already trusted
    // or were escaped, the result of the concatenation can be trusted too.
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  private isSafeHtml(value: any): value is SafeHtml {
    return ɵgetSanitizationBypassType(value) == ɵBypassType.Html;
  }
}
