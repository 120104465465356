import { Injectable, TemplateRef } from '@angular/core';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { FieldType } from '@app/user-content/services/field-builder-factory';
import { AbstractControl } from '@angular/forms';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { TranslateService } from '@ngx-translate/core';
import { CourseFormModel } from '../course-form.model';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { ContentHostingUploadAdapterService } from '@app/uploader/upload-section/adapters/content-hosting-upload-adapter.service';
import { CourseUploadAdapter } from '../services/course-upload.adapter';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { hasBrokenUrlValidator } from '@app/shared/validators/has-broken-url.validator';

@Injectable({ providedIn: 'root' })
export class ContentCatalogRenderer implements FormRenderer {
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_REQUIRED_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('Core_UrlRequired'),
    },
  };

  private contentUploadStatus: SubmissionStatus;

  constructor(
    private builder: DfFormFieldBuilder,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private contentHostingUploadAdapterService: ContentHostingUploadAdapterService,
    private webEnvironmentService: WebEnvironmentService,
    private translate: TranslateService,
    private uploadAdapter: CourseUploadAdapter,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}

  /**
   * Implementing the strategy pattern.
   */
  public render(
    context: RendererContext<CourseFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();
    const formType = 'Course';
    // Course URL should be hidden on creation/edit for expanded view SCORM input
    // Course URL should be visible on URL type input for creation/edit for expanded view
    // Course URL should be hidden on Uploaded (BOX or CH) type input for creation/edit for expanded view

    const shouldHideUrlField = !!vm.hostedContentDetails || vm.isScorm;
    const courseOriginField =
      vm.hostedContentDetails?.hostType !== 'Box'
        ? this.buildUrlField(shouldHideUrlField, context)
        : this.buildContentHostingField(
            context.state,
            vm.shouldShowContentUploader,
            context.inputContext.isEditing,
            vm.hostedContentDetails,
            vm.inputId,
            context.templates.contentUploader
          );

    vm.contentUploadStatus$.subscribe(
      (uploadStatus) => (this.contentUploadStatus = uploadStatus)
    );

    return [
      // Created By
      this.commonFieldBuilder.buildCreatedByField(context.templates.creator),
      // Main form
      courseOriginField,
      this.builder
        .checkbox(
          'updateScorm',
          this.translate.instant(
            'dgOrgInternalContentForm_ScormPackageUpdateCheckbox'
          )
        )
        .onChange(() => {
          vm.onEditScorm();
        })
        .hiddenWhen(() => !vm.isScorm || !context.inputContext.isEditing)
        .build(),
      this.builder
        .fieldGroup('scormInfo', [
          this.builder
            .optionalTextInput('scormFileName', 'SCORM Zip')
            .readonlyWhen(() => true)
            .hiddenWhen(
              () =>
                context.state().updateScorm &&
                !context.state().scormInfo.scormIsEdited
            )
            .build(),
          this.builder
            .customField('spinner', '', context.templates.spinner, null)
            .hiddenWhen(() => {
              return (
                !context.state().updateScorm ||
                (!!context.state().scormInfo?.scormCourseInputUrl &&
                  this.contentUploadStatus === SubmissionStatus.None) ||
                context.state().scormInfo?.scormIsEdited ||
                (context.state().updateScorm &&
                  this.contentUploadStatus === SubmissionStatus.Succeeded)
              );
            })
            .build(),
          this.buildScormUploaderField(
            context.state,
            context.templates.contentUploader
          ),
          this.builder
            .customField(
              'scormErrorMessage',
              '',
              context.templates.errorMessage,
              {
                errorMessage: vm.scormUploadErrorMessage,
              }
            )
            .hiddenWhen(() => !context.state().scormInfo.scormHasError)
            .unwrapped()
            .build(),
        ])
        .unwrapped()
        .hiddenWhen(() => !vm.isScorm)
        .build(),

      this.builder
        .requiredTextInput('courseName', 'CourseFormCtrl_CourseTitle')
        .build(),
      this.builder.optionalTextarea('description', 'Core_Description').build(),
      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.USER_IMAGE_FIELD)
        .build({
          useCropper: true,
          inputType: context.inputContext.inputType,
          resourceId: vm.inputId,
        }),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.INPUT_SESSION_FIELD)
        .build({
          inputType: context.inputContext.inputType,
          isHidden: vm.hostedContentDetails?.hostType === 'Box',
        }),
      // Duration
      this.commonFieldBuilder.buildDurationFields(
        this.ldFlagsService.durationForCoursesEventsOther,
        formType,
        context
      ),
      this.builder
        .fieldGroup('', [
          this.contentCatalogFormBuilderService
            .getBuilder(FieldType.ADVANCED_FIELDSET)
            .build({
              canRestrictContent: vm.canRestrictContent,
              organizationId: vm.organizationId,
              isInitiallyVisibleToOrg: vm.isVisibleToOrg,
              groups: vm.groups,
              expanderTemplate: context.templates.advancedExpander,
              expand$: vm.expandAdvanced$,
              fieldOptions: {
                externalId: {
                  isDisabled: context.state().fileManaged,
                },
              },
            }),
        ])
        .build(),
    ];
  }

  private buildUrlField(
    shouldHide: boolean,
    context: RendererContext<CourseFormModel>
  ): DfFormFieldConfig {
    const originalUrl = context.state().courseUrl;

    if (!this.ldFlagsService.showBrokenLinksManagement) {
      return this.builder
        .optionalTextInput('courseUrl', 'CourseFormCtrl_CourseUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidator)
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalo
        .hiddenWhen(() => shouldHide)
        .autofocused()
        .build();
    } else {
      return this.builder
        .customField('courseUrl', null, context.templates.urlBrokenValidation, {
          label: this.translate.instant('CourseFormCtrl_CourseUrl'),
          invalidUrlMessage: this.translate.instant('Core_UrlRequired'),
          brokenUrlMessage: this.translate.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
          originalUrl,
          isRequired: false,
        })
        .validatedByIndexed(this.urlValidator)
        .validatedBy((control: AbstractControl) =>
          hasBrokenUrlValidator(
            control,
            this.translate.instant('dgOrgInternalContent_BrokenLinkError'),
            context.state().hasBrokenUrl,
            control.value !== originalUrl
          )
        )
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalo
        .hiddenWhen(() => shouldHide)
        .build();
    }
  }

  private buildContentHostingField(
    state: () => CourseFormModel,
    supportsContentHosting: boolean,
    isEditing: boolean,
    hostedContentDetails: HostedContentMetadata,
    inputId: number,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.builder
      .customField('hostedContentDetails', '', contentUploaderTemplate, {
        useExistingFileRestrictions: true,
        file: hostedContentDetails
          ? {
              name: hostedContentDetails.fileName,
              size: hostedContentDetails.fileSize,
            }
          : undefined,
        uploadAdapter: this.contentHostingUploadAdapterService.getAdapter(
          'Course',
          inputId
        ),
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
          allowedDescription: '',
        },
        i18n: this.translate.instant(['Core_Or']), // hide divider on full form; we now know we're uploading and not allowing a URL
        hasDivider: false,
      })
      .unwrapped()
      .hiddenWhen(() => {
        const vm = state();
        return (
          !vm.shouldShowContentUploader ||
          (hostedContentDetails && hostedContentDetails.hostType !== 'Box')
        );
      })
      .build();
  }

  private buildScormUploaderField(
    state: () => CourseFormModel,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.builder
      .customField('scormUrl', '', contentUploaderTemplate, {
        uploadAdapter: this.uploadAdapter,
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
        },
        i18n: this.translate.instant(['Core_Or']),
        hasDivider: false,
      })
      .unwrapped()
      .hiddenWhen(() => {
        return (
          !state().updateScorm ||
          !state().scormInfo?.scormCourseInputUrl ||
          (state().updateScorm &&
            !state().scormInfo?.scormIsEdited &&
            this.contentUploadStatus === SubmissionStatus.Submitting) ||
          (state().updateScorm &&
            state().scormInfo?.scormIsEdited &&
            this.contentUploadStatus === SubmissionStatus.Succeeded)
        );
      })
      .build();
  }
}
