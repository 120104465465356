import { Component } from '@angular/core';

import { PathwayNavBaseDirective } from '@app/pathways/directives/pathway-nav-base.directive';
import {
  hasAtLeastOneStep,
  shouldShowNavSection,
  shouldShowNavSubsections,
} from '@app/pathways/rsm/utils';
import { StripMarkupPipe } from '@app/shared/pipes/strip-markup.pipe';
import { ColorService } from '@app/shared/services/color.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-section-nav',
  templateUrl: './pathway-section-nav.component.html',
  styleUrls: ['./pathway-section-nav.component.scss'],
})
export class PathwaySectionNavComponent extends PathwayNavBaseDirective {
  // ***************************
  // Properties
  // ***************************

  public readonly i18n = this.translate.instant([
    ...this.baseI18n,
    'A11y_NonOptionalStepsComplete',
    'ProfilePathways_Completed',
  ]);
  public completedImg = '/content/img/circle-check-green.svg';
  public progressCircleInProgress = 'var(--apollo-color-yellow-700)';
  public topOffset = [0, 80];

  // ***************************
  // Util Wrappers
  // ***************************

  public hasAtLeastOneStep = hasAtLeastOneStep;
  public shouldShowNavSection = shouldShowNavSection;
  public shouldShowNavSubsections = shouldShowNavSubsections;

  constructor(stripMarkupPipe: StripMarkupPipe, translate: TranslateService) {
    super(stripMarkupPipe, translate);
  }

  // ***************************
  // PUBLIC -------------------
  // Methods
  // ***************************

  public inProgressAriaLabel(
    completedSteps: number,
    totalSteps: number
  ): string {
    return this.translate.instant('A11y_CompletionProgressDetails', {
      numberCompleted: completedSteps,
      total: totalSteps,
    });
  }

  public completionAriaLabel(
    completedSteps: number,
    totalSteps: number,
    optionalSteps: number
  ) {
    return optionalSteps > 0
      ? `${this.inProgressAriaLabel(completedSteps, totalSteps)} ${this.i18n.A11y_NonOptionalStepsComplete}`
      : this.inProgressAriaLabel(completedSteps, totalSteps);
  }

  public hasFauxSubsection(): boolean {
    return false;
  }
}
