import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { filter } from 'rxjs';
import { twJoin, twMerge } from '../../utils';
import { toastAnimations } from './toast.animations';
import { ProgressToastEvent, ProgressToastOptions } from './toast.model';
import { PROGRESS_TOAST_DATA } from './toast.tokens';

/**
 * Progress Toast component to display progress to the user
 * and autoCloses after a certain time, unless including the close button.
 *
 * Note: Since all the inputs/outputs are handled by the ToastService PROGRESS_TOAST_DATA
 * this component does NOT have formal @Input/@Output decorators
 *
 * Usage to show a toast message to the user with message, title, etc.
 * @code
 *   this.toastService.showProgressToast('message', {
 *     type: 'upload',
 *    ...<ProgressToastOptions>,
 *   });
 *
 * Extras:
 *  TODO: AutoClose will stop on mouse enter and start on mouse leave
 *  i18n translations are performed OUTSIDE this component
 */
@Component({
  selector: 'da-progress-toast',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end',
  },
  template: `
    <div
      class="tw-pointer-events-auto tw-w-72 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-xl tw-ring-1 tw-ring-neutral-200"
      @fadeInOut
    >
      <div class="tw-flex tw-items-start tw-gap-x-3">
        <da-icon [icon]="icon" className="tw-text-neutral-600 tw-h-6 tw-w-6" />

        <div
          class="tw-flex tw-min-h-6 tw-flex-1 tw-flex-col tw-justify-center tw-gap-1 tw-overflow-hidden"
        >
          <p
            class="tw-truncate tw-text-xs tw-font-semibold"
            [title]="options.message"
          >
            {{ options.message }}
          </p>
        </div>

        @if (!!options.close) {
          <button
            type="button"
            class="tw-btn-icon tw-btn-secondary-filled"
            (click)="options.close()"
          >
            <da-icon icon="x-mark" className="tw-h-4 tw-w-4" />
            <span class="tw-sr-only">{{ options.closeLabel }}</span>
          </button>
        }
      </div>

      @if (options.progress$ | async; as progress) {
        <div>
          <div
            class="tw-my-2 tw-overflow-hidden tw-rounded-full tw-bg-neutral-200"
          >
            <div
              class="tw-h-2 tw-rounded-full tw-bg-gradient-to-r tw-from-neutral-200 tw-to-blue-800"
              [ngStyle]="{ width: progress.percentage + '%' }"
            ></div>
          </div>
          <div
            class="tw-text-xs tw-font-semibold"
            [class]="
              twMerge(
                progress.percentage === 100 ? 'tw-text-green-600' : '',
                progress.error ? 'tw-text-red-600' : ''
              )
            "
          >
            {{ progress.error ?? progress.message }}
          </div>
        </div>
      }
    </div>
  `,
  animations: [toastAnimations],
})
export class ProgressToastComponent {
  twJoin = twJoin;
  twMerge = twMerge;
  icon!: string;

  constructor(
    @Inject(PROGRESS_TOAST_DATA) public options: ProgressToastOptions
  ) {
    this.setIcon();
    this.watchProgressBar();
  }

  private setIcon() {
    switch (this.options.type) {
      case 'download':
        this.icon = 'document-arrow-down';
        break;
      case 'upload':
      default:
        this.icon = 'document-arrow-up';
        break;
    }
  }

  /**
   * progress features are optional.
   *
   * If provided, watch the progress$ event stream to
   * reset the autoClose timer on each event
   */
  private watchProgressBar() {
    let source$ = this.options.progress$;
    if (source$) {
      const validateProgress = ({ percentage }: ProgressToastEvent) => {
        return percentage >= 0 && percentage <= 100;
      };
      source$ = source$.pipe(filter(validateProgress));
    }

    this.options.progress$ = source$;
  }
}
