import { Injectable } from '@angular/core';
import { LDFlagsService } from '@dg/shared-services';

/**
 * This service exposes tag related LD flags via a cache to avoid
 * excessive requests to the LD service
 */
@Injectable({
  providedIn: 'root',
})
export class TagFlagsService {
  constructor(private ldFlagsService: LDFlagsService, 
  ) {}

  /** LD flag for displaying peer ratings */
  /** This is all going away in PD-94792 */
  public get showPeerRatings() {
    return this.getFlag('peerRatingsPhaseOne');
  }

  private getFlag(ldFlag: string): boolean {
    // Get the LD flag via the client API if not already cached
    if (this[ldFlag] === undefined) {
      return (this[ldFlag] = this.ldFlagsService.skills[ldFlag]);
    }
    // Else return the cached value
    return this[ldFlag];
  }
}
