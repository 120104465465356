import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

/**
 * This component is used to within the `dgx-split-button` component as the items in the
 * drop down list for other actions.
 *
 * @param displayText {string} Item button's title.
 * @param icon {string} (Optional) Icon the be added in to the item button to the left of the Title.
 * @param buttonAction Event emitter to pass the action to be taken back to the parent component.
 *
 * EXAMPLE USAGE:
 * @example
 * ```
 * <dgx-split-button
 *   ...
 * >
 *
 * <dgx-split-button-menu-item
 *  [displayText]="Delete"
 *  [icon]="delete"
 *  (buttonAction)="showModalDelete($event)"
 * ></dgx-split-button-menu-item>
 *
 * <dgx-split-button-menu-item
 *  [displayText]="Add"
 *  [icon]="plus"
 *  (buttonAction)="showModalAdd($event)"
 * ></dgx-split-button-menu-item>
 *
 * </dgx-split-button>
 * ```
 */
@Component({
  selector: 'dgx-split-button-menu-item',
  templateUrl: './split-button-menu-item.component.html',
  styleUrls: ['./split-button-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitButtonMenuItemComponent {
  @Input() public displayText: string;
  @Input() public icon: string;
  @Output() public buttonAction: EventEmitter<any> = new EventEmitter();

  public actionClicked(event: Event) {
    this.buttonAction.emit(event);
  }
}
