import { Injectable } from '@angular/core';
import { Badge } from '@app/user-content/user-outcome-v2/outcomes/badge/badge.model';
import { UploadAdapter } from '@app/uploader/upload-section/upload-adapter';
import { UploadEvent } from '@app/uploader/uploader';
import { UploaderService } from '@app/uploader/uploader.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// check this
@Injectable({
  providedIn: 'any', // allow the owning module to inject this locally in preference of any higher-level adapters
})
export class BadgeUploadAdapter extends UploadAdapter {
  constructor(
    uploaderService: UploaderService,
    private translate: TranslateService
  ) {
    super(uploaderService);
  }

  // TODO: This should not be needed if the BE is checking things correctly
  public isValidExtension(file: File, _): boolean {
    const ext = file.name.split('.').slice(-1)[0]?.toLocaleLowerCase();
    return ext === 'png' || ext === 'svg';
  }

  public getUploadSettings() {
    return this.uploaderService.getUploadLimit('badge');
  }

  public upload(imageFile: File, _): Observable<UploadEvent> {
    const formData = this.uploaderService.prepRawImageFile(imageFile);
    const endpoint = '/user/claimbadgev2';
    return this.uploaderService
      .uploadFile<Badge>(formData, endpoint, {}, undefined, true)
      .pipe(
        catchError((e) => {
          // Extract error message from server response
          let errorMessage: string = e.error.message
            ? e.error.message
            : e.error.toString();

          if (errorMessage.includes('Maximum request length exceeded')) {
            errorMessage = this.translate.instant(
              'ImageEditSvc_ImageUploadTooBig'
            );
          } else if (
            errorMessage.includes('Could not parse the open badge file')
          ) {
            errorMessage = this.translate.instant(
              'ImageEditSvc_OpenBadgeParseError'
            );
          } else {
            errorMessage = this.translate.instant(
              'ImageEditSvc_ImageUploadError'
            );
          }
          return throwError({
            message: errorMessage,
            overrideDefaultErrorMessage: true,
          });
        })
      );
  }
}
