<ul>
  <li
    class="ib guts-m-b-1 guts-m-r-1"
    *ngFor="let action of actions"
    data-dgat="globalAdd-cd7"
  >
    <button
      (click)="action.handlerFn($event, action)"
      [attr.id]="action.id"
      [attr.data-dgat]="action.dgat"
      [attr.aria-label]="action.displayType"
      dfButtonType="passive"
      df-button
      df-button-small
    >
      <df-icon
        *ngIf="action.icon"
        [icon]="action.icon"
        size="small"
        aria-hidden="true"
        class="guts-m-r-quart"
      ></df-icon>
      {{ action.displayType }}
    </button>
  </li>
</ul>
