<div class="guts-m-b-2 guts-m-t-2 guts-m-l-1 m-guts-m-h-1 guts-m-r-1">
  <dgx-user-recommendation-header
    *ngIf="recommendation"
    [item]="recommendationForHeader"
    [showComment]="true"
  ></dgx-user-recommendation-header>

  <dgx-tag-card
    [tag]="tag"
    [initialAction]="initialAction"
    [actionsContext]="normalizedRecommendation"
  ></dgx-tag-card>
</div>
