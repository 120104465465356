import { Injectable } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import {
  FileChunkUploaderService,
  FileUploadChunkResult,
} from '@app/uploader/file-chunk-uploader/file-chunk-uploader.service';
import {
  UploadEvent,
  Uploader,
  UploadEventType,
  UploadProgressEvent,
  UploadCompleteEvent,
} from '@app/uploader/uploader';
import {
  FileUploadSetting,
  VideoUploadSettings,
} from '@app/uploader/uploader-api.model';
import { UploaderService } from '@app/uploader/uploader.service';

@Injectable({ providedIn: 'root' })
export class VideoUploadAdapter
  implements Uploader<never, FileUploadChunkResult>
{
  static readonly uploadPurpose = 'Video';

  constructor(
    private chunkUploader: FileChunkUploaderService,
    private fileUploader: UploaderService
  ) {}

  getUploadSettings(): Observable<FileUploadSetting> {
    return this.fileUploader.getUploadSettings().pipe(
      map((s: VideoUploadSettings) => ({
        allowedFileTypes: s.supportedVideoFileTypes.split(','),
        maxSizeMB: s.maxVideoSizeMB,
      }))
    );
  }

  upload(
    file: File,
    _?: never
  ): Observable<UploadEvent<FileUploadChunkResult>> {
    const progress$ = new Subject<UploadProgressEvent>();

    // Transform the chunk uploader interface, which has a progress callback and async result into a single
    // async event stream
    return merge(
      progress$,
      this.chunkUploader
        .uploadFile(file, VideoUploadAdapter.uploadPurpose, (progress) => {
          progress$.next({
            type: UploadEventType.Progress,
            progress: progress / 100, // normalize the chunk uploader's percentage progress to a fractional value
          });
        })
        .pipe(
          map(
            (r) =>
              ({
                type: UploadEventType.Complete,
                response: r,
              } as UploadCompleteEvent<FileUploadChunkResult>)
          ),
          finalize(() => progress$.complete()) // we have to explicitly complete or the outer stream will stay open indefinitely
        )
    );
  }
}
