import { DatePipe } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';

import {
  NgbActiveModal,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { DegreeLevel } from '@app/outcomes/outcomes.model';
import {
  UserOutcomeDetail,
  UserOutcomeJsonDetails,
} from '@app/outcomes/outcomes-api.model';
import { UserOutcomeDegreeService } from '@app/outcomes/services/user-outcome-degree.service';
import { AuthService } from '@app/shared/services/auth.service';
import {
  TypeaheadSearchFunction,
  TypeaheadSearchTerm,
} from '@app/shared/shared-api.model';
import { lazySearch } from '@dg/shared-rxjs';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import { UploadFileResponse } from '@app/uploader/uploader-api.model';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { CourseService } from '@app/user-content/course.service';
import { UserContentModalBaseDirective } from '@app/user-content/user-input/user-content-modal-base/user-content-modal-base.directive';
import { UserOutcomeImageUploadAdapter } from '@app/user-content/services/adapters/user-outcome-image-upload.adapter';
import { UserImageFieldComponent } from '@app/form-fields/wrappers/user-image-field/user-image-field.component';
import { MonthDateParserFormatterService } from '@app/shared/services/date-picker/month-date-parser-formatter.service';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';

export interface DegreeFormModel {
  title: string;
  isInternational: boolean;
  country: string;
  institution: Partial<AutocompleteItem>;
  imageUrl: string;
  levelId: number;
  gpa?: string;
  dateCompleted?: Date;
  tags: TagsApi.Tag[];
}

/** A version of UserOutcomeDetail that strongly types the 'details' field vs. treating as arbitrary JSON record */
type UserOutcomeDetailStrong = UserOutcomeDetail<UserOutcomeJsonDetails>;

@Component({
  selector: 'dgx-degree-modal',
  templateUrl: './degree-modal.component.html',
  styleUrls: ['./degree-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: MonthDateParserFormatterService,
    },
  ],
})
export class DegreeModalComponent extends UserContentModalBaseDirective<
  UserOutcomeDetailStrong,
  DegreeFormModel
> {
  public levels: DegreeLevel[];

  public readonly i18n = this.translate.instant([
    'dgUserOutcomeEditForm_DegreeLevel',
    'dgUserOutcomeEditForm_SaveToProfile',
    'dgUserOutcomeEditForm_UniversityCountry',
    'dgUserOutcomeEditForm_UniversityName',
    'DegreeFormCtrl_GradePointAvg',
    'Core_SelectDate',
  ]);

  @ViewChild('country')
  public countryRef: TemplateRef<any>;
  @ViewChild('institution')
  public institutionRef: TemplateRef<any>;
  @ViewChild('level')
  public levelRef: TemplateRef<any>;
  @ViewChild('skillsView')
  public skillsViewRef: TemplateRef<any>;

  public isLoadingInstitutions = false;
  public isLoadingCountries = false;

  constructor(
    @Inject(WindowToken) windowRef: Window,
    cdr: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    authService: AuthService,
    tipService: TipService,
    inputsService: InputsService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private builder: DfFormFieldBuilder,
    private uploadAdapter: UserOutcomeImageUploadAdapter,
    private userOutcomeDegreeService: UserOutcomeDegreeService,
    private courseService: CourseService
  ) {
    super(
      windowRef,
      cdr,
      activeModal,
      authService,
      tipService,
      inputsService,
      translate.instant('dgUserOutcomeEditForm_AddADegree'),
      translate.instant('dgUserOutcomeEditForm_EditDegree')
    );
  }

  public ngOnInit() {
    super.ngOnInit();
    this.userOutcomeDegreeService
      .getDegreeLevels()
      .pipe(take(1))
      .subscribe((levels) => {
        this.levels = levels;
        this.cdr.detectChanges();
      });
  }

  public onTypeaheadSelectItem(formControl: FormControl, value: any) {
    DegreeModalComponent.setCustomFieldValue(formControl, value);
  }

  public onTagsChange(items: any[], formControl: FormControl) {
    UserContentModalBaseDirective.setCustomFieldValue(formControl, items);
  }

  public countrySearch: TypeaheadSearchFunction<string, string> = (
    termProvider: TypeaheadSearchTerm<string>
  ) => {
    return termProvider.pipe(
      lazySearch((term) => {
        this.isLoadingCountries = true;
        this.cdr.detectChanges();
        return this.courseService.getCountries(term).pipe(
          finalize(() => {
            this.isLoadingCountries = false;
            this.cdr.detectChanges();
          })
        );
      })
    );
  };

  public institutionSearch: TypeaheadSearchFunction<string, AutocompleteItem> =
    (termProvider: TypeaheadSearchTerm<string>) => {
      return termProvider.pipe(
        lazySearch((term) => {
          this.isLoadingInstitutions = true;
          this.cdr.detectChanges();
          const country = this.model.isInternational
            ? this.model.country
            : undefined;
          return this.courseService.getInstitutions(term, true, country).pipe(
            finalize(() => {
              this.isLoadingInstitutions = false;
              this.cdr.detectChanges();
            })
          );
        })
      );
    };

  public formatInstitutionResult(result: AutocompleteItem): string {
    return result.label;
  }

  public initControlDependencies() {
    const institutionControl = this.form.get('institution');
    // Note that because 'country' form control presence depends on its visibility, this relies on 'isInternational' being true initially
    // Using elvis operator here for unit testing only, where the form controls are unfortunately not instantiated yet, likely due to 'shallow' setting
    this.form.get('country')?.valueChanges.subscribe(() => {
      // reset the institution input if the country changes
      institutionControl.reset();
    });

    institutionControl?.statusChanges.subscribe(() => {
      // on validation, reset the institution input if the user entered an institution that isn't in the list
      if (institutionControl.invalid) {
        institutionControl.reset(undefined, { emitEvent: false }); // don't re-emit causing a loop
      }
    });
    // Now we can hide 'country', by setting 'isInternational' to the initial value being edited, if any
    const isInternationalControl = this.form.get('isInternational');
    isInternationalControl?.setValue(
      !!this.initialViewModel?.details?.international
    );
  }

  protected buildFormFields(): DfFormFieldConfig<DfTemplateOptions>[] {
    const shouldHideImageField =
      this.authUser.isRestrictedProfile ||
      !this.authUser.defaultOrgInfo?.settings?.allowRestrictedImages;
    const builder = this.builder;

    return [
      builder
        .fieldGroup('' /* presentational group only */, [
          builder
            .title()
            .labeled('dgUserOutcomeEditForm_TitleOfDegree')
            .withPlaceholder('dgUserOutcomeEditForm_DegreeTitleExample')
            .withDgatId('degreeForm-036')
            .autofocused()
            .build(),
          builder
            .checkbox('isInternational', 'dgUserOutcomeEditForm_OutsideUS')
            .withDgatId('degreeForm-ffd')
            .build(),
          builder
            .customField(
              'country',
              'dgUserOutcomeEditForm_Country',
              this.countryRef
            )
            .updatedOn('blur')
            .hiddenWhen(() => !this.model.isInternational)
            .build(),
          builder
            .customField(
              'institution',
              'dgUserOutcomeEditForm_CollegeUniversity',
              this.institutionRef
            )
            .asRequired()
            .updatedOn('blur')
            .build(),
        ])
        .asFieldset()
        .build(),

      builder
        .customField(
          'levelId',
          this.i18n.dgUserOutcomeEditForm_DegreeLevel,
          this.levelRef
        )
        .build(),

      builder
        .fieldGroup(
          '' /* logical group only */,
          [
            builder
              .optionalTextInput('gpa', 'dgUserOutcomeEditForm_GPA')
              .ofType('number')
              .validatedBy(Validators.max(10.0), Validators.min(0)) // 0-10.0 accommodates both unweighted and weighted GPA ranges (Brazil goes up to 10)
              .withErrorMessages({
                min: this.i18n.DegreeFormCtrl_GradePointAvg,
                max: this.i18n.DegreeFormCtrl_GradePointAvg,
              })
              .styledBy('df-form__col-half')
              .build(),

            builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                'dgUserOutcomeEditForm_DateCompleted',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectDate,
                }
              )
              .asOptional()
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .build(), // end field group
      builder
        .fieldGroup('' /* presentational group only */, [
          this.builder
            .foreignField(
              'imageUrl',
              'dgUserOutcomeEditForm_UploadDegreeImage',
              UserImageFieldComponent.REGISTERED_FIELD_TYPE,
              {
                isHostImageUrl: true, // Exceptionally, outcome images are currently served from the degreed domain instead of blob storage
                uploadAdapter: this.uploadAdapter,
                provideControlValue: (response: UploadFileResponse) =>
                  response.url,
              }
            )
            .hiddenWhen(() => shouldHideImageField)
            .build(),
          builder
            .customField('tags', 'Core_Skills', this.skillsViewRef, {
              topTags: this.userInterests,
            })
            .unwrapped() // The tag editor has its own label and doesn't require validation, so present it sans fresco's default field wrapper
            .build(),
        ])
        .asFieldset()
        .build(),
    ];
  }

  protected initFormModel(
    source: Partial<UserOutcomeDetailStrong> /* Additional strong typing for details field */
  ) {
    this.model = {
      title: source.title,
      isInternational: true, // Initially set this to true so the form control for related 'country' gets created too
      country: source.details?.country,
      institution: source.source
        ? { label: source.source, value: source.source }
        : undefined,
      levelId: source.level,
      gpa: source.details?.GPA?.toPrecision(3),
      dateCompleted: source.endDate ? new Date(source.endDate) : undefined, // ensure we get a Date, not string
      imageUrl: source.imageUrl,
      tags: source.tags ?? [],
    };
  }

  protected createResult(
    model: Partial<DegreeFormModel>,
    defaults: Partial<UserOutcomeDetailStrong>
  ): UserOutcomeDetailStrong {
    const result: Partial<UserOutcomeDetailStrong> = {
      ...defaults,
      contentTypeId: 'Degree',
      title: model.title,
      source: model.institution.value,
      level: model.levelId,
      endDate: this.datePipe.transform(model.dateCompleted, 'yyyy-MM-dd'),
      imageUrl: model.imageUrl,
      tags: model.tags,
      details: {
        ...defaults.details,
        international: model.isInternational,
        country: model.isInternational ? model.country : undefined,
        GPA: Number.parseFloat(model.gpa),
      },
    };
    return result as UserOutcomeDetailStrong;
  }
}
