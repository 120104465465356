import { Injectable } from '@angular/core';
import { InputCreationFeedback } from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { Observable } from 'rxjs';
import { Repository } from '@app/shared/patterns/repository/repository';
import { ObsoleteOrgInputsParameters } from '@app/orgs/services/orgs.model';

/**
 * A repository providing CRUD operations for a single user Input type from the user inputs API.
 */
export class InputsRepository<TEntity>
  extends ApiServiceBase
  implements
    Repository<
      TEntity,
      InputCreationFeedback,
      ObsoleteOrgInputsParameters<TEntity>
    >
{
  private static readonly baseUrl = '/inputs';
  private static readonly organizationsBaseUrl = '/organizations';
  private isMedia = this.inputType === 'Article' || this.inputType === 'Video';
  private defaultApiType = this.isMedia
    ? 'media'
    : this.inputType.toLowerCase();

  constructor(httpClient: NgxHttpClient, private inputType: InputType) {
    super(httpClient, '');
  }

  public fetchOne(
    id: number,
    useNewImageUploader?: boolean
  ): Observable<TEntity> {
    const params = { inputId: id, useResourceImages: useNewImageUploader };
    // HACK: The media inputs endpoints are inconsistently named 😭 so we have to work around per-endpoint
    const apiType = this.isMedia ? 'mediaentry' : this.defaultApiType;
    return this.get(`${InputsRepository.baseUrl}/get${apiType}`, params);
  }

  public add(item: TEntity): Observable<InputCreationFeedback> {
    return this.post(
      `${InputsRepository.baseUrl}/add${this.defaultApiType}`,
      item
    );
  }

  public addExisting(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  public remove(item: ObsoleteOrgInputsParameters<TEntity>): Observable<void> {
    return this.post(
      `${InputsRepository.organizationsBaseUrl}/setInputsObsolete`,
      item
    );
  }

  public update(item: TEntity): Observable<unknown> {
    return this.put<void>(
      `${InputsRepository.baseUrl}/update${this.defaultApiType}`,
      item
    );
  }
}
