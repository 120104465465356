import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'slugify' })
export class SlugifyPipe implements PipeTransform {
  public transform(input: string, preserveCharacters?: boolean): string {
    if (!input) {
      return;
    }

    let transformedInput = input
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[!*'();:@&=+$,\/?\[\]]/g, '') // Remove URL reserved characters
      .replace(/\s+/g, '-'); // Replace spaces with -

    if (preserveCharacters) {
      transformedInput = encodeURIComponent(transformedInput);
    }

    return transformedInput;
  }
}
