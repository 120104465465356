import { Observable } from 'rxjs';

import { CropperCoordinates } from '../image-cropper-modal/image-cropper-modal.model';
import { FileUploadSetting } from '../uploader-api.model';
import { UploaderService } from '../uploader.service';
import { UploadEvent, Uploader } from '../uploader';

/**
 * Base class to setup an adapter that will handle API request for uploading.
 * The created adapters should be share across components when appropriate.
 */
export abstract class UploadAdapter<TResponse = unknown>
  implements Uploader<CropperCoordinates, TResponse>
{
  constructor(protected uploaderService: UploaderService) {}
  /**
   * A function that returns an Observable with image upload limits see  {@link FileUploadSetting}
   * for the options that should be returned.
   */
  public abstract getUploadSettings(): Observable<FileUploadSetting>;
  /**
   * A function to save an image and crop settings that returns an Observable
   * @param file the selected file
   * @param options optional settings that should be passed in when saving a cropper image
   * @returns a tuple of Observables providing the API response and, optionally, fractional progress updates (0-1.0)
   */
  public abstract upload(
    file: File,
    options?: CropperCoordinates
  ): Observable<UploadEvent<TResponse>>;
}
