import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
} from '@angular/core';
import { Group, GroupPrivacyLevel } from '@app/groups/group-api';
import { OrgMembersService } from '@app/orgs/services/org-members.service';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { AuthService } from '@app/shared/services/auth.service';
import { GroupService } from '@app/shared/services/group.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { UserService } from '@app/user/services/user.service';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash-es';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'dgx-tag-peer-search',
  templateUrl: './tag-peer-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagPeerSearchComponent implements OnInit {
  @Output() public selectedChanged = new EventEmitter<Peer[]>();

  public selectedPeers: Peer[] = [];
  public noResults: boolean = false;
  public i18n = this.translateService.instant([
    'Core_To',
    'recommendationForm_NoResults',
    'recommendationForm_SearchByName',
  ]);
  private _peerProfileKeysToExclude: number[] = [];
  private _managerUserProfilekey: number;

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private authService: AuthService,
    private orgMembersService: OrgMembersService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  @Input() public set peerProfileKeysToExclude(userProfileKeys: number[]) {
    this._peerProfileKeysToExclude = userProfileKeys;
  }
  public get peerProfileKeysToExclude(): number[] {
    return [...this._peerProfileKeysToExclude, this._managerUserProfilekey];
  }

  private get orgId(): number {
    return this.authService.authUser?.defaultOrgId;
  }

  public ngOnInit(): void {
    // Get the manager user profile key to exclude manager from search results
    this.orgMembersService
      .getManager(this.authService.authUser.defaultOrgId)
      .subscribe((manager) => {
        if (manager) {
          this._managerUserProfilekey = manager.userProfileKey;
          this.cdr.markForCheck();
        }
      });
  }

  public addPeer(peerToAdd: AnyRecommendee): void {
    this.selectedPeers = [...this.selectedPeers, peerToAdd] as Peer[];
    this.selectedChanged.emit(this.selectedPeers);
  }

  public removePeer(peerToRemove: Peer): void {
    this.selectedPeers = this.selectedPeers.filter(
      (peer) => !isEqual(peer, peerToRemove)
    );
    this.selectedChanged.emit(this.selectedPeers);
  }

  public searchPeers: TypeaheadSearchFunction<string, Peer> = (
    searchTerm: Observable<string>
  ): Observable<readonly Peer[]> => {
    return searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        // reset when user starts new search
        this.noResults = false;
        this.cdr.markForCheck();
      }),
      filter((searchTerm) => searchTerm.length >= 2),
      switchMap((searchTerm: string) => this.searchUsersAndGroups(searchTerm)),
      catchError(() => of([]))
    );
  };

  public trackByFn(_: number, peer: Peer): number {
    return (
      (peer as UserProfileSummary).userProfileKey || (peer as Group).groupId
    );
  }

  public hasPicture(peer: Peer): boolean {
    return !!(peer as UserProfileSummary).picture;
  }

  private searchUsersAndGroups(
    searchTerm: string
  ): Observable<readonly Peer[]> {
    const users = this.getUsers(searchTerm);
    const groups = this.getGroups(searchTerm);

    return forkJoin([users, groups]).pipe(
      map(([users, groups]) =>
        this.excludeSelectedPeers([...users, ...groups])
      ),
      tap((usersAndGroups) => {
        if (!usersAndGroups.length) {
          this.noResults = true;
        }
      }),
      finalize(() => {
        this.cdr.markForCheck();
      })
    );
  }

  /** Get all users */
  private getUsers(searchTerm: string): Observable<UserProfileSummary[]> {
    return this.userService.findNetworkMembers(searchTerm);
  }

  /** Get all groups visible to the organization */
  private getGroups(searchTerm: string): Observable<Group[]> {
    return this.groupService.searchGroups(
      this.orgId,
      GroupPrivacyLevel.Closed,
      searchTerm,
      true // exclude private users on peer group searches
    );
  }

  /** Exclude selected users/groups from search results */
  private excludeSelectedPeers(searchResults: Peer[]): Peer[] {
    return (
      searchResults
        // remove already selected users/groups from search results
        .filter(
          (item) => !this.selectedPeers.some((peer) => isEqual(peer, item))
        )
        // remove users with profile keys in peerProfileKeysToExclude
        .filter(
          (item) =>
            !this.peerProfileKeysToExclude.includes(
              (item as UserProfileSummary).userProfileKey
            )
        )
    );
  }
}

export type Peer = UserProfileSummary | Group;
