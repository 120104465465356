<button
  (click)="onClick()"
  class="action-button"
  [class.action-button--active]="isActive"
  [class.action-button--square]="design === 'square'"
  [class.action-button--none]="design === 'none'"
  [class.action-button--compact]="compact"
  [class.action-button--secondary]="secondary"
  [container]="tooltipContainer"
  [attr.data-dgat]="dgatInput"
  [disabled]="isDisabled"
  [ngbTooltip]="tooltip"
  [ngClass]="style.buttonClass"
  [openDelay]="1000"
  triggers="hover"
  [attr.aria-label]="ariaLabel"
  [attr.aria-pressed]="ariaPressed"
  [type]="btnType"
>
  <!-- icon, with tooltip if only showing icon -->
  <df-icon *ngIf="icon" [icon]="icon" [size]="style.iconSize"></df-icon>
  <!-- text to accompany icon -->
  <div
    *ngIf="!isIconOnly"
    class="action-button__content font-semibold"
    [class.action-button__content--no-icon]="!icon"
  >
    <ng-content></ng-content>
  </div>
</button>
