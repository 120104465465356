import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { normalizeDateTime } from '@app/reporting/in-app/utils/date-time';

import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { PrettyTimeElapsedPipe } from '@app/shared/pipes/pretty-time-elapsed.pipe';
import { GroupService } from '@app/shared/services/group.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { Loadable, loadWith } from '@dg/shared-rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Notification } from '../../notification-api.model';
import { NotificationService } from '../../notification.service';
import { NOTIFICATIONS_PER_PAGE } from '../../notification.tokens';
interface NotificationGroup {
  dateHeading?: string;
  notifications?: Notification[];
}

@Component({
  selector: 'dgx-notification-list',
  templateUrl: './notification-list.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent
  extends SubscriberBaseDirective
  implements OnInit, Loadable
{
  @Input() public groupByDate = true;
  @Input() public maxCommentLines: number;
  @Input() public maxTitleLines: number;
  @Input() public itemsPerPage = this.notificationsPerPage;
  @Input() public shouldLoadNotifications = true;

  @Output() public isLoadingChange = new EventEmitter<boolean>();

  public notificationFeed: NotificationGroup[] = []; // notification groups, sorted by date
  public requiredLearningPhrase = undefined;
  public hasRequiredLearning: boolean;
  public requiredLearningItemCount: number;
  public newNotificationCount: number;
  public offset = 0;
  public moreItemsAvailable = true;
  public isLoaded = false;

  private _isLoading = false;
  private notificationGroupMap: Record<string, NotificationGroup> = {}; // notification groups, keyed by date for convienience

  private i18n = this.translateService.instant([
    'Core_Today',
    'Core_Yesterday',
    'NotificationsCtrl_YouHaveRequiredLearningSingular',
  ]);

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private groupService: GroupService,
    private tracker: TrackerService,
    private prettyTimeElapsed: PrettyTimeElapsedPipe,
    @Inject(NOTIFICATIONS_PER_PAGE) private readonly notificationsPerPage
  ) {
    super();
  }

  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(value) {
    if (value !== this._isLoading) {
      this._isLoading = value;
      this.isLoadingChange.emit(value);
    }
  }

  public ngOnInit() {
    this.notificationService.loadShallowCounts().subscribe();

    if (this.shouldLoadNotifications) {
      this.loadNotifications(false);
    }

    this.notificationService.notificationsState.subscribe((state) => {
      this.newNotificationCount = state.newNotificationCount;
      this.hasRequiredLearning = state.requiredLearningItemCount > 0;
      this.requiredLearningItemCount = state.requiredLearningItemCount;

      if (this.requiredLearningItemCount > 1) {
        this.requiredLearningPhrase = this.translateService.instant(
          'NotificationsCtrl_YouHaveRequiredLearningPluralFormat',
          { count: this.requiredLearningItemCount }
        );
      } else {
        this.requiredLearningPhrase =
          this.i18n.NotificationsCtrl_YouHaveRequiredLearningSingular;
      }
      // Process only the notifications actually loaded
      if (state.loadInfo && state.loadInfo.start >= this.offset) {
        // State updated as the result of a load. Process only the last loaded items.
        const requestedItems = state.notifications.slice(
          state.loadInfo.start,
          state.loadInfo.start + state.loadInfo.length
        );
        this.processRequestedNotifications(requestedItems);
        this.offset = state.notifications.length;
      }
    });
  }

  public getGroupTrackingKey(_: number, group: NotificationGroup) {
    return group.dateHeading;
  }

  public getNotificationTrackingKey(_: number, notification: Notification) {
    return notification.notificationId;
  }

  public loadNotifications(doInfiniteScroll) {
    this.tracker.trackEventData({ action: 'Notifications List Viewed' });
    if (!doInfiniteScroll) {
      this.notificationFeed = [];
    }
    return loadWith(
      this.notificationService.loadNotifications(
        this.offset,
        this.notificationsPerPage
      ),
      this
    ).subscribe();
  }

  public respondToGroupInvite(groupId, profileId, isApproved, item, event) {
    event.stopPropagation();
    event.preventDefault();
    if (isApproved) {
      item.parameters.requestStatus = 1;
    } else {
      item.parameters.requestStatus = 0;
    }
    return this.groupService
      .respondToMembershipRequest(groupId, profileId, isApproved)
      .pipe(
        catchError((err) => {
          item.parameters.requestStatus = -1;
          throw err;
        })
      )
      .subscribe();
  }

  public dismissNotification(notificationId) {
    return this.notificationService.dismissNotification(notificationId).pipe(
      tap(() => {
        for (const group of this.notificationFeed) {
          // remove the notification from its group
          group.notifications = group.notifications.filter(
            (n) => n.notificationId !== notificationId
          );
        }
      })
    );
  }

  public onNotificationClick(notification: Notification) {
    this.notificationService.goToNotification(notification);
  }

  public onRespondToGroupInviteClick(
    notification: Notification,
    $event: { event: Event; isApproved: boolean }
  ) {
    const { event, isApproved } = $event;
    this.respondToGroupInvite(
      notification.parameters.groupId,
      notification.parameters.person.userProfileId,
      isApproved,
      notification,
      event
    );
  }

  private processRequestedNotifications(notifications: Notification[]) {
    this.newNotificationCount = 0;

    let dateHeading: string;
    for (const notification of notifications) {
      if (!notification.readFlag) {
        this.newNotificationCount += 1;
      }
      if (this.groupByDate) {
        // TODO: update this so the pipe is in the template and force a change detection cycle periodically to update the elapsed time
        dateHeading = this.prettyTimeElapsed.transform(
          normalizeDateTime(notification.createdDate).toISOString(), // this will handle the created date regardless of if UTC indicator and ensures we treat it correctly
          'days'
        );
      }
      if (
        notification.notificationType !== 'Deprecated' &&
        notification.notificationType !== 'SocialConnectSuccess'
      ) {
        // Group notifications by dateHeading
        let group = this.notificationGroupMap[dateHeading];
        if (!group) {
          this.notificationGroupMap[dateHeading] = group = {
            dateHeading,
            notifications: [],
          };
          this.notificationFeed.push(group);
        }
        group.notifications.push(notification);
      }

      // ideally we don't need to set the input type, but the endpoint on the input page requires it right now.
      // longer term just get the input by id, and then let the api figure out the type
      if (
        notification.parameters.sourceUrl &&
        notification.parameters.inputType
      ) {
        if (notification.parameters.sourceUrl.match(/tasks\/task/) !== null) {
          notification.parameters.sourceUrl;
        } else {
          notification.parameters.sourceUrl += `&inputType=${notification.parameters.inputType}`;
        }
      }
      if (
        notification.parameters.inputUrl &&
        notification.parameters.inputType
      ) {
        notification.parameters.inputUrl += `&inputType=${notification.parameters.inputType}`;
      }
    }

    this.notificationService.markNotificationsRead().subscribe();
  }
}
