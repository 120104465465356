// Angular
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// services
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { ButtonType } from '@app/shared/temporary-types';
import {
  DfButtonBasicModule,
  DfIconModule,
  DfSpinnerButtonModule,
} from '@lib/fresco';
import { TranslateModule } from '@ngx-translate/core';
/**
 * Useful as a generic navigation page. NotFoundComponent makes use of this.
 * Has options for a loading state on the navigation button.
 *
 * @example
 * <dgx-optional-navigation
 *   [descriptionText]="descriptionText"
 *   [headerImageUrl]="headerImageUrl"
 *   [headerText]="headerText"
 *   [isNavigationPending]="isNavigationPending"
 *   [primaryNavigationText]="navigationText"
 *   [primaryNavigationUrl]="navigationUrl"
 *   (primaryNavigationClick)="onNavigationClick($event)"
 *   headerImageClasses="image-layout--empty--large"
 * ></dgx-optional-navigation>
 */
@Component({
  selector: 'dgx-optional-navigation',
  templateUrl: './optional-navigation.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DfSpinnerButtonModule,
    DfIconModule,
    DfButtonBasicModule,
    TranslateModule,
  ],
})
export class OptionalNavigationComponent implements OnInit {
  /** (Optional) Description text. *Must already be translated.* */
  @Input() public descriptionText?: string;
  /** (Optional) Classes for our description text. */
  @Input() public descriptionTextClasses?: string;
  /** (Optional) Whether the optional action button should be loading. */
  @Input() public isNavigationPending = false;
  /** (Optional) Header text. *Must already be translated.* Generic option: `Core_NotFound_DescriptionText`. */
  @Input() public headerText?: string;
  /** (Optional) Classes for our header text. Any valid NgClass value will work. */
  @Input() public headerTextClasses? = '';
  /** Header image for our 404 page. *Defaults to our empty-search-results image.* Generic option: `Core_NotFound_HeaderText`. */
  @Input() public headerImageUrl?: string;
  /** Classes for our header image. *Default class sets height.* */
  @Input() public headerImageClasses = 'image-layout--empty';
  /** (Optional) Dgat for the primary navigation button. Defaults to 'optional-navigation-46f'. */
  @Input() public primaryNavigationDgat = 'optional-navigation-46f';
  /** Whether the primary navigation button goes to an external site. */
  @Input() public primaryNavigationIsExternal = false;
  /** (Optional) Primary navigation button text. If absent, button will be hidden. *Must already be translated.*  Generic option: `Pathways_BackToBrowse`. */
  @Input() public primaryNavigationText: string;
  /** (Optional) Primary navigation button classes. Defaults to 'btn-primary'. */
  @Input() public primaryNavigationType: ButtonType = 'primary';
  /** The URL to navigate to on primary button click. *Defaults to # to allow for click action.* */
  @Input() public primaryNavigationUrl = '#';
  /** (Optional) Dgat for the secondary navigation button. Defaults to 'optional-navigation-46f'. */
  @Input() public secondaryNavigationDgat = 'optional-navigation-57f';
  /** Whether the primary navigation button goes to an external site. */
  @Input() public secondaryNavigationIsExternal = false;
  /** (Optional) Secondary navigation button text. If absent, button will be hidden. *Must already be translated.*  Generic option: `Pathways_BackToBrowse`. */
  @Input() public secondaryNavigationText: string;
  /** (Optional) Secondary navigation button classes. Defaults to 'btn-primary'. */
  @Input() public secondaryNavigationType: ButtonType = 'secondary';
  /** The URL to navigate to on secondary button click. *Defaults to # to allow for click action.* */
  @Input() public secondaryNavigationUrl = '#';
  /** (Optional) Emitted on both button *and* link clicks (for tracking purposes). */
  @Output() public primaryNavigationClick = new EventEmitter<MouseEvent>();
  /** (Optional) Emitted on both button *and* link clicks (for tracking purposes). */
  @Output() public secondaryNavigationClick = new EventEmitter<MouseEvent>();

  public externalIcon = 'arrow-up-right-out';

  constructor(private webEnvironmentService: WebEnvironmentService) {}

  public ngOnInit(): void {
    // preserve our defaults in the face of them being left off
    this.headerImageClasses ||= 'image-layout--empty';
    this.primaryNavigationType ||= 'primary';
    this.primaryNavigationDgat ||= 'optional-navigation-46f';
    this.primaryNavigationUrl ||= '#';
    this.secondaryNavigationType ||= 'secondary';
    this.secondaryNavigationDgat ||= 'optional-navigation-57f';
    this.secondaryNavigationUrl ||= '#';
    // if the header image url has not been provided, we use our preferred default
    this.headerImageUrl ||= this.webEnvironmentService.getBlobUrl(
      '/content/img/emptystate/empty-search-results.svg',
      true
    );
  }

  public onPrimaryClick(event: MouseEvent): void {
    this.primaryNavigationClick.emit(event);
  }

  public onSecondaryClick(event: MouseEvent): void {
    this.secondaryNavigationClick.emit(event);
  }
}
