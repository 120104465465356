import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';

import { TranslateService } from '@ngx-translate/core';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { AssessmentFormModel } from '../assessment-form.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';

@Injectable({ providedIn: 'root' })
export class ContentCatalogInitialAssessmentRendererService
  implements FormRenderer
{
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private translateService: TranslateService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<AssessmentFormModel>
  ): DfFormFieldConfig[] {
    const vm: AssessmentFormModel = context.state();

    return [
      this.formBuilder
        .customField(
          null,
          null,
          context.templates.assessmentContentCatalogInitialView,
          {
            createInCredSpark: vm.createInCredSpark,
            addContentCatalogAssessmentByUrl:
              vm.addContentCatalogAssessmentByUrl,
            i18n: context.translationKeys,
          }
        )
        .build(),
    ];
  }
}
