import showdown from 'showdown';
import showdownHighlight from 'showdown-highlight';

const alwaysDisabledOptions = ['horizontalRule', 'strikethrough', 'underline'];
const imageOption = 'images'; // conditionally disabled
const headerOption = 'headers'; // conditionally disabled
const outdentOption = 'outdent'; // conditionally disabled
const codeBlocksOption = 'codeBlocks'; // conditionally disabled
const codeSpansOption = 'codeSpans'; // conditionally disabled

type SubParserFunc = (
  text: string,
  options: any,
  globals: any,
  name?: string // provided optionally since original parser doesn't require
) => string;
const parserMap = new Map<any, SubParserFunc>();

// Save the original image parser
const defaultImageParser: SubParserFunc = showdown.subParser(imageOption);
const defaultHeaderParser: SubParserFunc = showdown.subParser(headerOption);
const defaultOutdentParser: SubParserFunc = showdown.subParser(outdentOption);
const defaultCodeSpanParser: SubParserFunc =
  showdown.subParser(codeSpansOption);
const defaultCodeOptionParser: SubParserFunc =
  showdown.subParser(codeBlocksOption);

// Replace with the proxy one
// subParser(name)       - Get a registered subParser
// subParser(name, func) - Register a subParser
[
  headerOption,
  imageOption,
  outdentOption,
  codeSpansOption,
  codeBlocksOption,
].forEach((name) => {
  showdown.subParser(name, (text, options, globals) =>
    dynamicProxySubParser(text, options, globals, name)
  );
});

// Disable subparsers for elements we don't support
for (const parserName of alwaysDisabledOptions) {
  disableDefaultSubParser(parserName);
}

/** Creates a Showdown.js markdown converter, limited to our supported options, optionally with image support */
export function createShowdownConverter({
  allowImages = false,
  openLinksInNewWindow = false,
  encodeEmails = false,
  allowCode = false,
  allowHeaders = false,
}: {
  allowImages?: boolean;
  openLinksInNewWindow?: boolean;
  encodeEmails?: boolean;
  allowCode?: boolean;
  allowHeaders?: boolean;
}) {
  const converter = new showdown.Converter({
    simplifiedAutoLink: true,
    simpleLineBreaks: true,
    ghCodeBlocks: allowCode,
    openLinksInNewWindow,
    encodeEmails,
    extensions: allowCode
      ? [
          showdownHighlight({
            pre: true,
            auto_detection: true,
          }),
        ]
      : [],
  });
  const parserSettings = new Map<string, SubParserFunc>();
  parserSettings
    .set(headerOption, allowHeaders ? defaultHeaderParser : bypassedSubParser)
    .set(imageOption, allowImages ? defaultImageParser : bypassedSubParser)
    .set(outdentOption, allowCode ? defaultOutdentParser : bypassedSubParser)
    .set(
      codeBlocksOption,
      allowCode ? defaultCodeOptionParser : bypassedSubParser
    )
    .set(
      codeSpansOption,
      allowCode ? defaultCodeSpanParser : bypassedSubParser
    );
  parserMap.set(
    converter,
    (text: string, options: any, globals: any, name: string) => {
      return parserSettings.get(name)(text, options, globals, name);
    }
  );

  return converter;
}

function disableDefaultSubParser(parserName: string) {
  showdown.subParser(parserName, (text, options, globals) =>
    bypassedSubParser(text, options, globals, parserName)
  );
}

function dynamicProxySubParser(
  text: string,
  options: any,
  globals: any,
  name: string
) {
  const parser = parserMap.get(globals.converter);
  return parser(text, options, globals, name);
}

function bypassedSubParser(
  text: string,
  options: any,
  globals: any,
  name: string
) {
  text = globals.converter._dispatch(name + '.before', text, options, globals);
  text = globals.converter._dispatch(name + '.after', text, options, globals);
  return text;
}
