import { Injectable } from '@angular/core';
import { ResourceImage } from '@app/shared/services/resource-image/resource-image.model';
import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';

@Injectable({
  providedIn: 'root',
})
export class ImageUrlService {
  constructor(private resourceImageService: ResourceImageService) {}

  /**
   * Takes an imageUrl prop that might be a simple URL or might be a stringified representation
   * of ResourceImageJson, and return a ResourceImage-like object. This object can then be
   * checked for other properties (legacyPictureUrl for example) to determine whether it's a
   * true ResourceImage object or not.
   *
   * @param imageUrlData
   */
  public getImageData(imageUrlData: any): ResourceImage {
    const isResourceImage =
      this.resourceImageService.isResourceImage(imageUrlData);

    // Whoops, this is actually an UploadPictureAndCropResponse object! How did this happen?
    // But that object does contain *most* of ResourceImage, so we can use it in most cases...
    if (
      (!isResourceImage && imageUrlData?.pictureUrl) ||
      imageUrlData?.legacyPictureUrl
    ) {
      return {
        legacyPictureUrl: imageUrlData.legacyPictureUrl,
        imageUrl: imageUrlData.pictureUrl,
        resourceImageId: imageUrlData.resourceImageId,
      };
    }

    return this.resourceImageService.parseImageUrl(imageUrlData);
  }

  /**
   * Returns *only* the image URL, preferring legacyPictureUrl and
   * falling back on imageUrl where that isn't available. WARNING:
   * these two props are not interchangeable. Use getImageData if
   * you need to safely get any other props, like resourceImageId.
   *
   * @param imageUrlData
   */
  public getImageUrl(imageUrlData: any): string {
    const { legacyPictureUrl, imageUrl } = this.getImageData(imageUrlData);

    return legacyPictureUrl ? legacyPictureUrl : imageUrl;
  }
}
