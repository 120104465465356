<svg
  *ngIf="isEnabled"
  class="profile-pic__engaged"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="svgSize"
  [attr.height]="svgSize"
  style.transform="translate({{ offsetX }}px, {{ offsetY }}px)"
  viewBox="0 0 100 100"
  role="img"
>
  <title>{{ i18n.Core_ActiveLearner }}</title>
  <g fill="none" [attr.stroke-width]="stroke" stroke-miterlimit="3.4">
    <path
      d="M83.091526 13.862057A49 49 0 0199 50h0a49 49 0 01-14.332701 34.629156"
      stroke="#85128a"
    />
    <path
      d="M17.055245 13.728205A49 49 0 0150 1a49 49 0 0133.091526 12.862057"
      stroke="#de0546"
    />
    <path
      d="M1.00001 50.031302A49 49 0 011 50a49 49 0 0116.055245-36.271795"
      stroke="#3da807"
    />
    <path
      d="M84.667299 84.629156A49 49 0 0150 99 49 49 0 011.00001 50.031302"
      stroke="#00a6a6"
    />
  </g>
</svg>
