import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { SelectOption } from '@app/shared/components/select/select.component';
import { SubscriptionManagerService } from '@app/shared/services/subscription-manager.service';
import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';
import { Observable } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

/** Parameters passed to drive the {@link SelectFieldComponent} properties and its child {@link SelectComponent} inputs */
export interface SelectFieldParams {
  /** optional id for the dgx-select */
  id?: string;
  /** optional placeholder for the dgx-select */
  placeholder?: string;
  /** label key for the dgx-select options */
  optionLabelKey?: string;
  /** optional item tracking key for the dgx-select options. If not provided and no @{link provideOption} value provided,
   * the selected item is used as the control value and vice versa.
   */
  optionTrackByKey?: string;
  /** Observable that emits the list of dgx-select options */
  options$: Observable<SelectOption[]>;
  /** Callback that shoud map the form control value to its corresponding option */
  provideOption?: (controlValue: any) => SelectOption;
  /** Callback that shoud map the selected option to its corresponding form control value */
  provideControlValue?: (selectedItem: SelectOption) => any;
  selectedItem?: SelectOption;
}

/** A Formly-compatible wrapper for the dgx-select component. To create
 * one of these, pass {@link REGISTERED_FIELD_TYPE} to {@link DfFormFieldBuilder.foreignField}.
 */
@Component({
  selector: 'dgx-select-field',
  templateUrl: 'select-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionManagerService],
})
export class SelectFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public static REGISTERED_FIELD_TYPE = 'dgx-select';

  public selectedItem: any;
  public selectOptions: SelectOption[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private subscriptionManager: SubscriptionManagerService
  ) {
    super();
  }

  public get params(): SelectFieldParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions?.params ?? {};
  }

  public ngOnInit() {
    this.params.options$
      .pipe(this.subscriptionManager.takeUntilDestroyed())
      .subscribe((o) => {
        this.selectOptions = o;
      });
    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        distinctUntilChanged(),
        this.subscriptionManager.takeUntilDestroyed()
      )
      .subscribe((v) => {
        this.selectedItem = this.params.provideOption?.(v) ?? v;
        this.cdr.markForCheck();
      });
  }

  public handleSelection(selectedOption: SelectOption) {
    const value =
      this.params.provideControlValue?.(selectedOption) ??
      (this.params.optionTrackByKey
        ? selectedOption[this.params.optionTrackByKey]
        : selectedOption);
    if (value === undefined) {
      console.error('selection control value is invalid'); // notify devs that mapping probably isn't set up right
    }
    this.formControl.setValue(value);
    this.formControl.markAsDirty();
  }

  public handleBlur() {
    this.formControl.markAsTouched();
  }
}
