<div class="guts-p-t-1">
  <div class="tile tag-tile--horizontal" data-dgat="tagRatingDisplay-3ec">
    <div class="l_flex m-l_flex">
      <div class="tag-tile--horizontal__rating">
        <dgx-tag-rating-donut
          size="sm"
          [level]="level"
          [evaluated]="isEvaluation"
          [certified]="isCertified"
          class="ib"
        ></dgx-tag-rating-donut>
      </div>
      <div class="l_flex-grow left-text">
        <h4 class="par par--small font-semibold" [innerText]="ratingType"></h4>
        <p
          class="par par--small par--light guts-p-t-half guts-p-b-half"
          *ngIf="endorserUserName"
        >
          <span [innerText]="i18n.dgTagRating_EndorsedByFormat"></span>
          <span>, {{ ratingInfo.dateCompleted | date: 'longDate' }}</span>
        </p>
        <div
          class="par par--small par--light guts-p-t-half"
          *ngIf="!endorserUserName"
        >
          {{ ratingInfo.dateCompleted | date: 'longDate' }}
        </div>
      </div>
    </div>
  </div>
</div>
