<dgx-simple-field-wrapper
  [label]="label"
  [isRequired]="isRequired"
  [tipText]="tipText"
>
  <dgx-group-search
    (onGroupSelection)="handleGroupSelection($event)"
    (onGroupRemove)="handleGroupRemove($event)"
    [groups]="groups"
  >
  </dgx-group-search>
</dgx-simple-field-wrapper>
