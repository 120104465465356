<button
  class="pathway-add-content-between"
  data-dgat="pathway-subsection-dd3"
  [attr.aria-label]="i18n.Core_AddContent"
  (click)="addContent($event)"
>
  <span class="pathway-add-content-between__contents l_flexbar">
    <span class="pathway-add-content-between__icon">
      <df-icon
        icon="plus-circle"
        size="large"
        class="pathway-add-content-between__icon--blue"
        aria-hidden="true"
      ></df-icon>
    </span>
  </span>
</button>
