import { Injectable } from '@angular/core';
import colors from '@styles/colors/colors.generated.json'; // generated via export from color palette SCSS

/** Provides colors based on the Degreed SASS color pallete */
@Injectable({ providedIn: 'root' })
export class ColorService {
  /** Flattens an object and names the properties kebab-case according to their hierarhcial path */
  private static flatten(obj: any, levelName: string = '') {
    let result = {};
    Reflect.ownKeys(obj).forEach((k) => {
      const val = obj[k];
      let propAffix = k.toString();
      // for the flattened colors, omit the special 'hue' default color key name so the property has the base color name
      if (propAffix === 'hue') {
        propAffix = '';
      }
      let propName = '';
      if (levelName) {
        propName = levelName;
      }

      if (propAffix) {
        propName += (levelName ? '-' : '') + propAffix;
      }
      if (val === undefined) {
        return;
      }
      if (val === Object(val)) {
        // flatten downward if val is an object
        result = {
          ...result,
          ...this.flatten(val, propName),
        }; // spread flattened lower levels into result
      } else {
        result[propName] = obj[k]; // copy primitives
      }
    });
    return result;
  }

  /** The colors are exported from SCSS with empty keys for the default hue variants. This helper patches those so we can avoid having to
   * use a weird colors.blue[''] accessor for convenience.
   */
  private static fixupHues(obj: any) {
    Reflect.ownKeys(obj).forEach((k) => {
      if (obj[k] === Object(obj[k])) {
        this.fixupHues(obj[k]); // fixup recursively
      }
      if (!k) {
        obj.hue = obj[k];
        delete obj[''];
      }
    });
    return obj;
  }

  public readonly colors = colors;
  private colorsFlat = ColorService.flatten(colors);

  /**
   * Returns rgb color from Degreed's flat color palette similar to getColor in SASS
   * @colorName string name of color from palette
   * @asHex set to true if a hex value is needed instead of rgb
   */
  public getColor(colorName: string, asHex = false): string {
    if (this.colorsFlat.hasOwnProperty(colorName)) {
      let colorValue: string = this.colorsFlat[colorName];
      if (asHex) {
        colorValue = this.rgbStringToHex(colorValue);
      }
      return colorValue;
    }

    throw new Error('colorName: ' + colorName);
  }

  /**
   * Takes an rgb string and returns a hex string
   * @param rgb rgb string, e.g. "rgb(0, 70, 176)"
   */
  public rgbStringToHex(rgb: string) {
    const rgbValues = rgb.slice(4, -1);
    const rgbParts = rgbValues.split(',');
    const r = parseInt(rgbParts[0]);
    const g = parseInt(rgbParts[1]);
    const b = parseInt(rgbParts[2]);
    return this.rgbToHex(r, g, b);
  }

  /**
   * Takes rgb values and returns a hex string
   * @param r number representing red value
   * @param g number representing green value
   * @param b number representing blue value
   */
  public rgbToHex(r: number, g: number, b: number) {
    return (
      this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
    );
  }

  /**
   * Takes a HEX color value and converts it into a object representing RGB values
   */
  public hexToRGB(hex: string) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  /**
   * Takes an rgba FG string and an rgb BG string to blend with and return the new color's hex string
   * @param rgba rgba string, e.g. "rgb(0, 70, 176, 0.8)"
   * @param blendColor rgb object such as returned from hexToRGB -- cannot blend two rgbas
   */
  public blendRgbaWithBackground(
    rgba: string,
    blendColor: { r: number; b: number; g: number }
  ) {
    const rgbaValues = rgba.slice(5, -1);
    const rgbaParts = rgbaValues.split(',');

    const a = Number(rgbaParts[3]); // Needs to be a whole number for calcs

    // Blend the components...
    const r = this.blendComponents(a, parseInt(rgbaParts[0]), blendColor.r);
    const g = this.blendComponents(a, parseInt(rgbaParts[1]), blendColor.g);
    const b = this.blendComponents(a, parseInt(rgbaParts[2]), blendColor.b);

    return this.rgbToHex(r, g, b);
  }

  /**
   *  Calculates contrast ratio between two colors
   */
  public contrastRatio(hexColor1: string, hexColor2: string) {
    const color1Luminance = this.luminance(this.hexToRGB(hexColor1));
    const color2Luminance = this.luminance(this.hexToRGB(hexColor2));
    return color1Luminance > color2Luminance
      ? (color2Luminance + 0.05) / (color1Luminance + 0.05)
      : (color1Luminance + 0.05) / (color2Luminance + 0.05);
  }

  /**
   * Calculates relative luminance of a color
   * https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
   *
   * @param rgb
   * @returns
   */
  private luminance(rgb: { r: number; g: number; b: number }) {
    const a = [rgb.r, rgb.g, rgb.b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  private componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  // Layer a transparent component over an opaque one and return the new component value
  private blendComponents(o: number, fg: number, bg: number) {
    // Rounding may still give us minor miscalculations when a contrast is very borderline...
    return Math.round(o * fg + (1 - o) * bg);
  }
}
