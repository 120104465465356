import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchTypeaheadInitLocations } from '@app/search/services';
import {
  SearchNavigationService,
  WindowLayoutService,
} from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'dgx-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent {
  public term?: string;
  public initiationLocation = SearchTypeaheadInitLocations.navSearchBar;
  public i18n = this.translateService.instant([
    'Core_Search',
    'A11y_ToggleSearch',
  ]);

  constructor(
    private windowLayoutService: WindowLayoutService,
    private translateService: TranslateService,
    private searchNavigationService: SearchNavigationService
  ) {}

  public get isTablet(): Observable<boolean> {
    // [PD-92872] shrinks the global-search bar down to just an icon when it hits
    // the breakpoint-- may overlap with product-header links if value
    // is updated independently
    return this.windowLayoutService
      .registerMediaListener(
        [this.windowLayoutService.dgBreakpoints.BrotherBear],
        false
      )
      .pipe(map(({ matches }) => matches));
  }

  public inputEnterHandler(term?: string): void {
    this.searchNavigationService.searchLearnings(term);
  }
}
