import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WebEnvironmentService } from '../services/web-environment.service';

/** Transforms a url into an Azure blob content url via the @see WebEnvironmentService
 */
@Pipe({ name: 'blobifyUrl' })
export class BlobifyUrlPipe implements PipeTransform {
  public constructor(
    private enviroService: WebEnvironmentService,
    private sanitizer: DomSanitizer
  ) {}

  public transform(url: string, isStatic = false): string | SafeUrl {
    if (!url) {
      return url;
    }
    // set staticUrl to true if you want to exclude blob during debug.
    // staticUrl is used for images we add to the /img directory rather than something that got uploaded by the user.
    let imgUrl: string | SafeUrl = this.enviroService.getBlobUrl(url, isStatic);
    if (isStatic) {
      imgUrl = this.sanitizer.bypassSecurityTrustUrl(imgUrl as string);
    }
    return imgUrl;
  }
}
