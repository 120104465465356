<div class="guts-p-v-1">
  <legend class="guts-m-b-quart form__label font-semibold">
    {{ i18n.dgImageUpload_ImagePosition }}
  </legend>
  <div class="l_flex l_flex-middle l_flex-justify">
    <p>{{ i18n.dgImageUpload_ImagePosition_HelperText }}</p>
    <div class="position l_flex">
      <label class="position__option">
        <input
          type="radio"
          name="rating"
          value="Top"
          (change)="onPositionChange('Top')"
          [checked]="currentPosition === 'Top'"
          data-dgat="image-position-f11"
        />
        <df-icon icon="arrow-up" class="position__icon"></df-icon>
      </label>
      <label class="position__option">
        <input
          type="radio"
          name="rating"
          value="Bottom"
          (change)="onPositionChange('Bottom')"
          [checked]="currentPosition === 'Bottom'"
          data-dgat="image-position-15d"
        />
        <df-icon icon="arrow-down" class="position__icon"></df-icon>
      </label>
    </div>
  </div>
</div>
