import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import {
  UserMediaParameters,
  UpdateUserMediaParameters,
  UserExistingMediaParameters,
  UserMediaEntry,
} from '@app/inputs/inputs-api.model'; // TODO: update to relative path when this file is relocated
import { UserInputTypeServiceBase } from '@app/inputs/services/user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Assessments */
@Injectable({
  providedIn: 'root',
})
export class UserMediaService extends UserInputTypeServiceBase<
  'MediaEntry',
  UserMediaEntry,
  UserMediaParameters,
  UserExistingMediaParameters,
  UpdateUserMediaParameters
> {
  constructor(injector: Injector) {
    // Don't provide a default tracking category, normally assigned to the InputType, since this
    // service handles multiple input types.
    super(injector, '', 'MediaEntry', undefined);
  }

  /**
   * @param id the id of the user media entry to request
   */
  public getInput(id: number): Observable<UserMediaEntry> {
    // media GET endpoint doesn't follow naming convention, so this method is overriden, see PD-60692 / PD-61072
    const params = {
      userMediaId: id,
    };
    return this.get(`/userinputs/getusermediaentry`, params);
  }
}
