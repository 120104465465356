/** Returns true if the provided url is valid (http/https protocol) */
export function isUrlValid(string = '') {
  let url: URL;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
