import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
import { TranslationHelper } from '@app/shared/services/translation-helper.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { DfIconExclamationCircleOutline16, DfIconRegistry } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import {
  AssignmentStatusEnum,
  ProfileAssignmentService,
} from '@app/profile/components/profile-assignments/profile-assignments.service';
import { getDaysDifference } from '@app/profile/components/profile-assignments/profile-assignments-reactive-store/utils';

export interface UserRecommendationHeaderItem {
  creator?: {
    vanityUrl: string;
    name: string;
    isEngaged: boolean;
    [key: string]: any;
    isMentoring?: boolean;
  };
  alreadyCompleted?: boolean;
  group?: { groupId: number; groupName: string };
  recommendationType: UserRecommendationType;
  contentType: string;
  contentAction?: string; // for required learning only
  dateCompleted?: string;
  dateDue?: string;
  displayDueDate?: string;
  isAssignment?: boolean;
  userProfileKey?: number;
  comment?: string;
}

export enum SourceUserType {
  Recommender = 'Recommender',
  FollowedUser = 'FollowedUser',
}

@Component({
  selector: 'dgx-user-recommendation-header',
  templateUrl: './user-recommendation-header.component.html',
  styleUrls: ['./user-recommendation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRecommendationHeaderComponent implements OnInit {
  @Input() public item: UserRecommendationHeaderItem;
  @Input() public maxLines?: number;
  @Input() public showComment?: boolean = false;

  public disableTooltip: boolean = true;

  public assignmentStatus: number;

  constructor(
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private iconRegistry: DfIconRegistry,
    private translationService: TranslateService,
    private windowLayoutService: WindowLayoutService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private profileAssignmentService: ProfileAssignmentService
  ) {
    this.iconRegistry.registerIcons([DfIconExclamationCircleOutline16]);
  }

  public get isRequired(): boolean {
    return (
      this.item.recommendationType ===
        UserRecommendationType.RequiredLearning &&
      // Assigned skill ratings come in as 'RequiredLearning' instead of' Assignment'
      this.item.contentType !== 'Tag'
    );
  }

  public get isExternal(): boolean {
    return !!(this.item as any)?.reference?.externalId;
  }

  public get isComplete(): boolean {
    return this.item.alreadyCompleted;
  }

  public get isOverdue(): boolean {
    return (
      !this.isComplete &&
      !!this.item.dateDue &&
      this.assignmentStatus === AssignmentStatusEnum.OverDue
    );
  }

  public get isDueLater(): boolean {
    return (
      !this.isComplete &&
      !!this.item.dateDue &&
      this.assignmentStatus === AssignmentStatusEnum.DueLater
    );
  }

  public get isDueSoon(): boolean {
    return (
      !this.isComplete &&
      !!this.item.dateDue &&
      !this.isDueLater &&
      !this.isOverdue
    );
  }

  public get translatedString(): string {
    return this.getTranslationString(
      this.translationKey,
      this.translationParameters
    );
  }

  public get tooltipContent(): string {
    return this.htmlToPlaintextPipe.transform(this.translatedString);
  }

  private get translationKey(): string {
    switch (true) {
      case this.isGroupRecommendation:
        return this.groupTranslationKey;

      case this.isThirdPartyRecommendation:
        return this.thirdPartyTranslationKey;

      default:
        return this.userTranslationKey;
    }
  }

  private get groupTranslationKey(): string {
    switch (true) {
      case this.isRecommendation:
        return this.isFormerLearner
          ? 'dgRecommendationsOverview_RecommendedYouFormerLearnerFormat'
          : 'dgRecommendationsOverview_RecommendedYouFormat';
      case this.isComplete:
        return this.item.dateCompleted
          ? `assignedLearning_Completed`
          : `assignedLearning_CompletedNoDate`;
      case this.isOverdue:
        return `assignedLearning_Overdue`;
      case this.isDueSoon:
        return `assignedLearning_DueSoon`;
      case this.isDueLater:
        return `assignedLearning_DueLaterGroup`;
      default:
        return `assignedLearning_NoDueDate`;
    }
  }

  private get thirdPartyTranslationKey(): string {
    switch (true) {
      case this.isComplete:
        return 'dgAssignmentsOverview_ThirdPartyCompletedDate';

      case this.isRequired:
      case this.isAssignment:
        const overdueKey = this.isOverdue ? 'Overdue' : '';
        return `dgAssignmentsOverview_RequiredSingle${this.contentAction}${overdueKey}Format`;

      case this.isRecommendation:
        return 'dgRecommendationsOverview_RecommendedYouNoCreatorFormat';
    }
  }

  private get userTranslationKey(): string {
    switch (true) {
      case this.isRecommendation:
        return this.isFormerLearner
          ? 'dgRecommendationsOverview_RecommendedYouFormerLearnerFormat'
          : 'dgRecommendationsOverview_RecommendedYouFormat';
      case this.isComplete:
        return this.item.dateCompleted
          ? `assignedLearning_Completed`
          : `assignedLearning_CompletedNoDate`;
      case this.isOverdue:
        return `assignedLearning_Overdue`;
      case this.isDueSoon:
        return `assignedLearning_DueSoon`;
      case this.isDueLater:
        return this.isExternal
          ? `assignedLearning_DueLaterExternal`
          : `assignedLearning_DueLater`;
      default:
        return this.isExternal
          ? `assignedLearning_NoDueDateExternal`
          : `assignedLearning_NoDueDate`;
    }
  }

  private get translationParameters(): { [key: string]: string } {
    // Dates aren't always formatted nicely at this point, especially when rendered from Inputs.cshtml, make sure that
    // they're formatted properly before rendering
    let dueDate = this.item.displayDueDate;
    if (!dueDate) {
      dueDate = this.item.dateDue
        ? this.datePipe.transform(new Date(this.item.dateDue), 'MMM d, y')
        : '';
    }
    const days = getDaysDifference(new Date(this.item.dateDue), new Date());

    const dateCompleted = this.item.dateCompleted
      ? this.datePipe.transform(this.item.dateCompleted, 'MMM d, y')
      : undefined;

    const translationParams = {
      contentAction: this.item.contentAction,
      contentType: this.item.contentType,
      hasDateHtml: `<span ${dueDate ? '' : 'class="hide"'}> `,
      date: `<span class="due-date"> ${dueDate} </span>`,
      hasCompletedDateHtml: `<span ${dateCompleted ? '' : 'class="hide"'}> `,
      completedDate: `<span class="due-date"> ${dateCompleted} </span>`,
      hasDateEndHtml: ' </span>',
      nameHtml: this.userNameHtml,
      groupNameHtml: this.groupNameHtml,
    };

    return {
      ...translationParams,
      completedDate: dateCompleted,
      date: dueDate,
      days: days.toString(),
      groupNameHtml: this.newGroupNameHtml,
      nameHtml: this.newUserNameHtml,
    };
  }

  private get userNameHtml(): string {
    if (
      (this.isRequired && !this.item?.alreadyCompleted) ||
      !this.item.creator
    ) {
      return undefined;
    }

    if (!this.item.creator.name) {
      return this.translationService.instant('AnonymousUserHelper_Name');
    }

    const url = `/profile/${this.item.creator.vanityUrl}`;
    return this.getLink(url, this.item.creator.name);
  }

  private getShortName(name: string) {
    const nameArray = name.split(' ');

    if (nameArray.length > 1) {
      return `${nameArray[0]} ${nameArray[1].charAt(0)}.`;
    } else {
      return name;
    }
  }

  private get newUserNameHtml(): string {
    if (
      (this.isRequired && !this.item?.alreadyCompleted) ||
      !this.item.creator
    ) {
      return undefined;
    }

    if (!this.item.creator.name) {
      return this.translationService.instant('AnonymousUserHelper_Name');
    }

    const url = `/profile/${this.item.creator.vanityUrl}`;
    return this.getLink(url, this.getShortName(this.item.creator.name));
  }

  private get groupNameHtml(): string {
    if (!this.item?.group) {
      return undefined;
    }

    const url = `/groups/${this.item.group.groupId}`;
    return this.getLink(url, this.item.group.groupName);
  }

  private get newGroupNameHtml(): string {
    if (!this.item?.group) {
      return undefined;
    }

    const url = `/groups/${this.item.group.groupId}`;
    return this.getLink(url, 'group');
  }

  private get isAssignment(): boolean {
    return (
      this.item.recommendationType ===
        UserRecommendationType.AssignedLearning ||
      // Assigned skill ratings come in as 'RequiredLearning' instead of' Assignment'
      (this.item.contentType === 'Tag' && !!this.item.dateDue)
    );
  }

  private get isRecommendation(): boolean {
    return (
      this.item.recommendationType === UserRecommendationType.Recommendation
    );
  }

  private get isGroupRecommendation(): boolean {
    return !!this.item.group?.groupId;
  }

  private get isThirdPartyRecommendation(): boolean {
    return !this.item.creator && !this.item.userProfileKey;
  }

  private get isFormerLearner(): boolean {
    return this.item.userProfileKey === -2 || !this.item.creator;
  }

  private get contentAction(): string {
    return TranslationHelper.getContentActionType(this.item.contentType);
  }

  public ngOnInit() {
    this.assignmentStatus = this.profileAssignmentService.getAssignmentStatus(
      this.item.dateDue
    );
  }

  public getTranslationString(translation: string, params: any) {
    if (!translation) {
      return;
    }

    // NOTE: dgxLineClampHtml sanitizes the html translation string
    return this.translationService.instant(translation, params);
  }

  public onTrim(isTrimmed: boolean) {
    this.disableTooltip = !isTrimmed;
    this.cdr.detectChanges();
  }

  private getLink(url: string, name: string): string {
    if (!url || !name) {
      return undefined;
    }

    const target = this.windowLayoutService.isIframe ? '_blank' : '_self';
    const encodedName = this.encodeToEntitiesPipe.transform(name);

    return `<a class="link" href="${url}" target="${target}">${encodedName}</a>`;
  }
}
