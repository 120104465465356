<div
  class="box guts-p-full-2 guts-m-b-2 grid grid--justify-space-between"
  [class.org-faq-entry--edit-mode]="inEditMode"
>
  <ng-container *ngIf="!inEditMode">
    <div class="par par--small grid__col-lg-8 grid__col-sm-6">
      <h2 class="h3 guts-m-b-1">
        {{ faq.question }}
      </h2>
      <p class="hyphenate">
        {{ faq.answer }}
      </p>
    </div>
    <div class="par par--small grid__col-lg-2 grid__col-sm-2" *ngIf="!viewOnly">
      <div class="grid grid--justify-end grid--align-start">
        <button
          #editTrigger
          class="link-passive"
          data-dgat="org-faq-entry-bb9"
          type="button"
          (click)="toggleEditMode($event)"
          [class.is_disabled]="disableEdit"
          [disabled]="disableEdit"
        >
          <df-icon
            icon="pencil"
            class="guts-m-l-1"
            [a11yText]="editLabel"
          ></df-icon>
        </button>
        <button
          class="link-passive"
          data-dgat="org-faq-entry-29a"
          type="button"
          (click)="delete($event)"
          [class.is_disabled]="disableEdit"
          [disabled]="disableEdit"
        >
          <df-icon
            icon="trash"
            class="guts-m-l-1"
            [a11yText]="deleteLabel"
          ></df-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="inEditMode">
    <form
      #faqform
      name="faqform"
      [formGroup]="faqForm"
      novalidate
      method="post"
      (ngSubmit)="submit($event)"
      class="form-wrap full-width"
    >
      <div class="par par--small grid__col-lg-8 grid__col-sm-6">
        <div class="guts-m-b-1">
          <label for="question">{{ i18n.OrgManage_FAQ_Question }}</label>
          <input
            id="question"
            name="question"
            type="text"
            required
            formControlName="question"
            [placeholder]="i18n.OrgManage_FAQ_QuestionPlaceholder"
            data-dgat="org-faq-entry-5dd"
            class="guts-m-t-half"
            [(ngModel)]="editFaqModel.question"
            dgxAutofocus
          />
        </div>
        <div class="guts-m-b-2">
          <label for="answer">{{ i18n.OrgManage_FAQ_Answer }}</label>
          <textarea
            #answer
            [placeholder]="i18n.OrgManage_FAQ_AnswerPlaceholder"
            formControlName="answer"
            required
            data-dgat="org-faq-entry-aa5"
            class="guts-m-t-half"
            [(ngModel)]="editFaqModel.answer"
          ></textarea>
        </div>
        <div>
          <button
            type="submit"
            class="guts-m-r-1"
            df-button
            dfButtonType="primary"
            data-dgat="org-faq-entry-e92"
          >
            {{ i18n.Core_SaveChanges }}
          </button>
          <button
            df-button
            dfButtonType="passive"
            data-dgat="org-faq-entry-3b2"
            (click)="toggleEditMode($event)"
          >
            {{ i18n.Core_Cancel }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
