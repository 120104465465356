import { Injectable } from '@angular/core';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import {
  ActionFeedback,
  InputCreationFeedback,
  InputCreationResult,
  UserInputCreationFeedback,
} from '@app/inputs/inputs-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { ArticleApiInput, ArticleMappingToAPI } from '../article.model';
import { Merge } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'Core_GeneralErrorMessage',
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_EditContentDuplicateError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public addMedia(
    params,
    useQuickCheck: boolean
  ): Promise<ActionFeedback<InputCreationResult, void>> {
    const request$ = this.post<ActionFeedback<InputCreationResult, void>>(
      '/inputs/addmedia',
      {
        ...params,
        useQuickCheck,
      }
    ).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );

    return lastValueFrom(request$);
  }

  public addUserMedia(
    params: ArticleApiInput
  ): Promise<Merge<UserInputCreationFeedback, InputCreationFeedback>> {
    const request$ = this.post<
      Merge<UserInputCreationFeedback, InputCreationFeedback>
    >('/userinputs/addusermediaentry', {
      ...params,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public updateMedia(params: ArticleMappingToAPI) {
    const request$ = this.put('/inputs/updatemedia', {
      ...params,
    }).pipe(
      catchError((e) => {
        throw new DgError(
          e.data?.isDuplicate
            ? this.i18n.InputsSvc_EditContentDuplicateError
            : this.i18n.Core_GeneralErrorMessage,
          e
        );
      })
    );

    return lastValueFrom(request$);
  }
}
