import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';

export type DfPresentationalInputAddOnType = 'default' | 'split' | 'well';
export type DfInputAddOnType = DfPresentationalInputAddOnType | 'button';

/** A presentational component that decorates an input element or component with custom prepended and appended content.
 */
@Component({
  selector: 'df-input-decorator',
  templateUrl: './input-decorator.component.html',
  styleUrls: ['./input-decorator.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfInputDecoratorComponent {
  @Input() public readonly: boolean = false;
  @Input() public prependageType: DfInputAddOnType = 'default';
  @Input() public appendageType: DfInputAddOnType = 'default';
  // TODO: We look into the requirement to dynamically show/hide or otherwise style add-ons
  // for certain cases such as an email validity checkmark when checking for existing accounts.

  constructor(public elementRef: ElementRef) {}
}
