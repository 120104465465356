// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { UserRecommendationHeaderComponent } from './user-recommendation-header.component';
import { ProfilePicModule } from './../profile-pic/profile-pic.module';

import { DfIconModule } from '@lib/fresco';

import { TranslateModule } from '@ngx-translate/core';
import { DgxLineClampModule } from '@app/shared/directives/line-clamp/line-clamp.module';

@NgModule({
  declarations: [UserRecommendationHeaderComponent],
  imports: [
    CommonModule,
    DfIconModule,
    TranslateModule,
    ProfilePicModule,
    NgbTooltipModule,
    DgxLineClampModule,
  ],
  exports: [UserRecommendationHeaderComponent],
})
export class UserRecommendationHeaderModule {}
