<form (ngSubmit)="onSubmit()" name="postForm" [formGroup]="postForm">
  <dgx-modal
    [canCancel]="true"
    [isLoading]="isLoading"
    [useDefaultForm]="false"
    [useDefaultSubmitButton]="false"
    [canCancel]="true"
    (dismiss)="confirmationModal()"
    bodyClasses="guts-p-h-2 form-wrap"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ header }}
    </ng-container>

    <!-- Modal Body -->
    <ng-container class="modal-body">
      <ng-container *ngIf="editing && (editMessage || clonedEditMessage)">
        <df-local-notification
          class="block guts-m-b-1-half"
          size="small"
          [text]="clonedEditMessage ? clonedEditMessage : editMessage"
          [type]="NotificationType.info"
        ></df-local-notification>
      </ng-container>

      <div class="grid grid--bleed">
        <div class="grid__col-12 guts-m-t-1 guts-p-b-2">
          <label for="title">{{ i18n.PostFormCtrl_Title }}</label>
          <input
            type="text"
            id="title"
            name="title"
            dgxAutofocus
            [maxlength]="titleMaxLength"
            required
            data-dgat="postForm-b0c"
            autocomplete="off"
            formControlName="title"
          />
          <dgx-validate-field
            *ngIf="showTitleErrors"
            [message]="titleErrorMsg"
          ></dgx-validate-field>
        </div>
        <div class="grid__col-12">
          <label for="content">{{ i18n.PostFormCtrl_Content }}</label>
          <dgx-markdown-editor
            *ngIf="!isLoading"
            [textareaId]="'content'"
            (onImageUploadStart)="disable()"
            (onImageUploadEnd)="enable()"
            [textareaLabel]="'content'"
            [maxLength]="contentMaxLength"
            formControlName="content"
          ></dgx-markdown-editor>
        </div>
      </div>

      <div
        *dgxFeatureToggle="'content_duration_course_event_other'"
        class="guts-m-b-1 guts-p-t-2 postDuration"
      >
        <div class="postDuration--flexItem guts-p-r-1">
          <label for="durationHours">{{ i18n.Core_Hours }}</label>
          <input
            id="durationHours"
            name="durationHours"
            type="number"
            formControlName="durationHours"
            data-dgat="task-modal-fb8"
          />
          <dgx-validate-field
            *ngIf="durationHoursError"
            [message]="durationHoursValidationError"
          ></dgx-validate-field>
        </div>
        <div class="postDuration--flexItem guts-p-l-1">
          <label for="durationMinutes">{{ i18n.Core_Minutes }}</label>
          <input
            id="durationMinutes"
            name="durationMinutes"
            type="number"
            formControlName="durationMinutes"
            data-dgat="task-modal-7d6"
          />
          <dgx-validate-field
            *ngIf="durationMinutesError"
            [message]="durationMinutesValidationError"
          ></dgx-validate-field>
        </div>
      </div>
    </ng-container>
    <!-- Modal Footer -->
    <ng-container class="modal-footer">
      <button
        df-button
        dfButtonType="passive"
        class="guts-m-r-1"
        (click)="preview()"
        [disabled]="isSubmitDisabled"
        data-dgat="postForm-483"
      >
        {{ i18n.Core_Preview }}
      </button>
      <button
        df-button
        dfButtonType="primary"
        type="submit"
        [disabled]="isSubmitDisabled || isSubmitPending"
        [class.is_disabled]="isSubmitDisabled || isSubmitPending"
        [attr.aria-disabled]="isSubmitDisabled || isSubmitPending"
        [attr.aria-label]="
          isSubmitPending
            ? i18n.Core_Loading
            : editing
              ? i18n.PostFormCtrl_EditPostSave
              : i18n.PostFormCtrl_CreatePostSave
        "
        data-dgat="postForm-4a3"
      >
        <df-spinner-button [isSpinning]="isSubmitPending">
          {{
            editing
              ? i18n.PostFormCtrl_EditPostSave
              : i18n.PostFormCtrl_CreatePostSave
          }}
        </df-spinner-button>
      </button>
    </ng-container>
  </dgx-modal>
</form>
