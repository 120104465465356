import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';

import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';

import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { TagsFieldComponent } from '@app/form-fields/wrappers/tags-field/tags-field.component';
import { AssessmentFormModel } from '../assessment-form.model';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';

@Injectable({ providedIn: 'root' })
export class UserProfileAssessmentRendererService implements FormRenderer {
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private translateService: TranslateService,
    private userInputsService: UserInputsService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<AssessmentFormModel>
  ): DfFormFieldConfig[] {
    const vm: AssessmentFormModel = context.state();

    return [
      this.formBuilder
        .title()
        .labeled(context.translationKeys.AssessmentFormCtrl_AssessmentName)
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentName
        )
        .withDgatId('assessmentForm-487')
        .autofocused()
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translateService.instant('Core_FieldRequiredFormat', {
              fieldName: this.translateService.instant(
                context.translationKeys.AssessmentFormCtrl_AssessmentName
              ),
            }),
          },
        })
        .build(),
      this.formBuilder
        .optionalTextInput(
          'providerName',
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withDgatId('assessmentForm-e70')
        .hiddenWhen(() => {
          return !context.state().isAuthoring;
        })
        .build(),
      this.formBuilder
        .requiredTextInput(
          'url',
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .ofType('url')
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .withDgatId('assessmentForm-501')
        .validatedByIndexed({
          urlValidation: {
            expression: (url: AbstractControl) => {
              const isCredSpark = vm.getIsCredSpark({
                userProviderName: context.state().providerName,
              });
              return vm.validateAssessmentUrl(url.value, isCredSpark);
            },
            message: context.translationKeys.AssessmentFormCtrl_UrlRequired,
          },
        })
        .build(),
      this.formBuilder
        .optionalTextarea(
          'description',
          context.translationKeys.AssessmentFormCtrl_Description
        )
        .withDgatId('assessmentForm-91f')
        .build(),
      this.formBuilder
        .optionalTextInput(
          'questions',
          context.translationKeys.AssessmentFormCtrl_TotalQuestions
        )
        .ofType('number')
        .withDgatId('assessmentForm-1df')
        .onKeydown((event, model, formControl) => {
          const validateNumericValue =
            this.userInputsService.validateNumericValueOnKeyDown(event);
          if (!validateNumericValue) {
            event.preventDefault();
          }
        })
        .updatedOn('change')
        .validatedByIndexed({
          totalQuestions: {
            expression: (questions: AbstractControl) => {
              // using string values because it's checking whether there is input not
              // what the values are
              return vm.validateTotalQuestionsValue(
                context.state().questionsCorrect?.toString(),
                questions.value
              );
            },
            message:
              context.translationKeys.AssessmentFormCtrl_NumberOfQuestions,
          },
        })
        .build(),
      this.formBuilder
        .customField(
          'questionsCorrect',
          null,
          context.templates.assessmentQuestionsCorrect,
          {
            onQuestionsCorrectFieldChange: vm.onQuestionsCorrectFieldChange,
          }
        )
        .asOptional()
        .unwrapped()
        .withDgatId('assessmentForm-bb7')
        .validatedByIndexed({
          correctQuestionsValueExists: {
            expression: (correctQuestions: AbstractControl) => {
              // using string values because it's checking whether there is input not
              // what the values are
              return vm.validateCorrectQuestionsValueExists(
                correctQuestions?.value,
                context.state().questions?.toString()
              );
            },
            message:
              context.translationKeys.AssessmentFormCtrl_NumberOfQuestions,
          },
          correctQuestionsLessThanTotal: {
            expression: (correctQuestions: AbstractControl) => {
              return vm.validateCorrectQuestionsLessThanTotal(
                parseInt(correctQuestions?.value),
                parseInt(context.state().questions?.toString())
              );
            },
            message:
              context.translationKeys.AssessmentFormCtrl_CorrectQuestions,
          },
          correctQuestionsValidNumber: {
            expression: (correctQuestions: AbstractControl) => {
              return vm.validateCorrectQuestionsValidNumber(
                parseInt(correctQuestions?.value),
                parseInt(context.state().questions?.toString()),
                context.inputContext.isCompleting
              );
            },
            message:
              context.translationKeys.AssessmentFormCtrl_NumberOfQuestionsError,
          },
        })
        .build(),
      this.formBuilder
        .optionalTextInput(
          'percentile',
          context.translationKeys.AssessmentFormCtrl_Percentile
        )
        .ofType('number')
        .withDgatId('assessmentForm-c2a')
        .onKeydown((event, model, formControl) => {
          const validateNumericValue =
            this.userInputsService.validateNumericValueOnKeyDown(event);
          if (!validateNumericValue) {
            event.preventDefault();
          }
        })
        .hiddenWhen(() => {
          const isCredSpark = vm.getIsCredSpark({
            userProviderName: context.state().providerName,
          });

          return isCredSpark;
        })
        .build(),
      this.formBuilder
        .foreignField<MonthPickerFieldParams>(
          'dateCompleted',
          context.translationKeys.AssessmentFormCtrl_DateCompleted,
          MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
          {
            isMaxDateToday: true,
            ariaLabel: context.translationKeys.Core_SelectDate,
          }
        )
        .asOptional()
        .build(),
      this.formBuilder
        .optionalTextarea(
          'comment',
          context.translationKeys.AssessmentFormCtrl_WhatDidYouLearnLong
        )
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_WhatDidYouLearnShort
        )
        .withDgatId('assessmentForm-c01')
        .build(),
      this.formBuilder
        .foreignField(
          'tags',
          'Core_Skills',
          TagsFieldComponent.REGISTERED_FIELD_TYPE,
          { topTags: vm.authUser.viewerInterests }
        )
        .build(),
    ];
  }
}
