import { lowerFirst, upperFirst } from 'lodash-es';
import { isPlainObject } from 'lodash-es';

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key]; // Inferred type is T[K]
}

export function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  obj[key] = value;
}

/** Reinterpret each declared key of type T1 as type T2; useful for inline data transformations. */
export type ReinterpretKeys<T1, T2> = {
  [P in keyof T1]: T2;
};

/**
 * In 2020, there is still not a good, clean way of creating a
 * deep copy of an object; indeed, this method has its own flaws,
 * and should only be used to copy simple objects, *not* Classes.
 * https://medium.com/technofunnel/deep-and-shallow-copy-in-javascript-110f395330c5
 *
 * @param obj - **`getDeepCopy<T>(obj)` or `getDeepCopy<T[]>(obj)` to explicitly type the return.**
 */
export function getDeepCopy<T = any>(obj: any): T {
  if (!obj) {
    console.error('getDeepCopy passed undefined instead of object or array.');
    return undefined;
  }
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Simplified version of lodash.set(). Allows for nested setting of properites
 *
 * @param obj - The object to update
 * @param path - What to update (can be nested)
 * @param value  - Value to update with
 */
export function set(obj, path, value) {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
}

/**
 * Takes keys, either as an array or in dot notation, and a value,
 * to create nested properties of an object. If an object is passed,
 * the properties will be added to a copy of that object and returned
 * as new. **Does not mutate the original object.**
 *
 * @example
 * let user = { profile: { name: 'John Doe' } };
 * user = getNestedObject(['profile', 'email'], 'john@domain.com', user);
 * // { profile: { name: 'John Doe', email: 'john@domain.com' } }
 * user = getNestedObject(['preferences', 'theme' ], 'balloons', user);
 * // { profile: { name: 'John Doe', email: 'john@domain.com' }, preferences: { theme: 'balloons' } }
 * let freshUser = getNestedObject('profile.email', 'mary@domain.com')
 * // { profile: { email: 'mary@domain.com' } }
 */
export function getNestedObject<T = any>(
  keys: string[] | string,
  value: any,
  obj = {}
): T {
  // only check our keys *once*.
  keys = Array.isArray(keys)
    ? keys
    : keys.includes('.')
    ? keys.split('.')
    : [keys];
  // everything else done recursively in helper method
  return recursivelyNestObject<T>(keys, value, getDeepCopy<T>(obj));
}

/** Deliberately not exported -- this is internal-only! */
function recursivelyNestObject<T>(keys: string[], value: any, obj: T): T {
  if (keys.length === 1) {
    obj[keys[0]] = value;
  } else {
    const key = keys.shift();
    obj[key] = recursivelyNestObject(
      keys,
      value,
      typeof obj[key] === 'undefined' ? {} : obj[key]
    );
  }
  return obj;
}

/**
 * Recursively converts camelCase object keys to PascalCase.
 *
 * Note: This is a naive implementation that simply capitalizes
 *       the first letter of each property.
 *       It isn't smart enough to deal with acronyms.
 *
 *       This only supports objects `{}` not arrays.
 */
export const pascalCaseKeys = changePropCasing(upperFirst);

/**
 * Recursively covert PascalCase object keys to camelCase.
 *
 * Note: This is a naive implementation that simply capitalizes
 *       the first letter of each property.
 *       It isn't smart enough to deal with acronyms.
 *
 *       This only supports objects `{}` not arrays.
 * @deprecated Avoid using this on the ngx side because it will need to be cleaned up later
 * Instead, use it from the ajs side before passing data to ngx components and services. That code
 * will be thrown out, leaving us with pristine ngx code.
 */
export const camelCaseKeys = changePropCasing(lowerFirst);

/**
 * Append an object of query params to a given URL.
 *
 * THIS SHOULD ONLY BE USED WITH TRUSTED, INTERNAL CONTENT. No sanitization
 * is performed.
 *
 * @param url - The URL to append.
 * @param params - The object of query parameters to append.
 */
export const appendQueryParams = (url: string, params: any): string => {
  if (!url.includes('?')) {
    return url + '?' + paramsToQueryString(params);
  }
  return url + '&' + paramsToQueryString(params);
};

/**
 * A function that takes a casing function and returns a function that will
 * use the casing function to convert an object's properties to a certian
 * casing.
 *
 * @param casingFn The function to call to change the casing of the props.
 * @returns A function used to change object property casing.
 */
function changePropCasing(casingFn: (str: string) => string) {
  return function change(obj: { [key: string]: any }) {
    if (Array.isArray(obj)) {
      const arr: any = [];
      obj.map((o) => {
        arr.push(processObject(o, casingFn, change));
      });

      return arr;
    }

    // ignore for non-objects (null is an object)
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    return processObject(obj, casingFn, change);
  };
}

/**
 * A function that converts the casing of an object property via the casingFn
 *
 * @param obj The object that will be updated
 * @param casingFn The function to call to change the casing of the props.
 * @returns An object with the newly applied cased properties
 */
function processObject(obj, casingFn, change) {
  const r: any = {};

  Object.keys(obj).forEach((k) => {
    const v = obj[k];
    const pk = casingFn(k);

    if (Array.isArray(v)) {
      r[pk] = v.map((item) => change(item));
    } else if (isPlainObject(v)) {
      r[pk] = change(v);
    } else {
      r[pk] = v;
    }
  });

  return r;
}

/**
 * Internal function for turning an object of params into URL params.
 *
 * @param params
 */
function paramsToQueryString(params: any): string {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
}
