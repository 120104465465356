import { Injectable } from '@angular/core';
import { EbbCommentsApiService } from '@app/browser-extension/services/ebb-comments-api.service';
import { CommentModel, ReplyCommentModel } from '@app/inputs/inputs-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { UserService } from '@app/user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  CommentFeedItemModel,
  CommentFeedModel,
  MentionItem,
  Resource,
} from './comments.model';
import { DeleteCommentConfirmationModalComponent } from './components/delete-comment-confirmation-modal/delete-comment-confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CommentsApiService extends ApiServiceBase {
  public isIframe: boolean;
  private readonly i18n = this.translateService.instant([
    'CommentsSvc_AccessError',
    'CommentsSvc_AccessRepliesError',
    'CommentsSvc_LikeError',
    'CommentsSvc_AddCommentError',
    'CommentsSvc_AddReplyError',
    'CommentsSvc_FlagCommentError',
    'CommentsSvc_UpdateCommentError',
    'CommentsSvc_RemoveCommentError',
    'CommentsSvc_UsersTitle',
    'CommentsSvc_GetUsersError',
  ]);

  constructor(
    private authService: AuthService,
    private ebbCommentsApiService: EbbCommentsApiService,
    httpClient: NgxHttpClient,
    private modalService: ModalService,
    private trackerService: TrackerService,
    private translateService: TranslateService,
    private userService: UserService,
    private windowLayoutService: WindowLayoutService,
    private webEnvironmentService: WebEnvironmentService
  ) {
    super(httpClient, translateService.instant('CommentsSvc_Error'));
    this.isIframe =
      this.windowLayoutService.isIframe &&
      !this.windowLayoutService.isCypress &&
      !this.windowLayoutService.isMsTeams;
  }

  public addComment(
    resource: Resource,
    comment: string,
    parentComment?: null,
    parentReferenceId?: number,
    referenceUserKey?: number,
    mentions?: MentionItem[]
  ): Observable<CommentFeedItemModel>;
  public addComment(
    resource: Resource,
    comment: string,
    parentComment?: CommentFeedItemModel,
    parentReferenceId?: number,
    referenceUserKey?: number,
    mentions?: MentionItem[]
  ): Observable<ReplyCommentModel>;
  public addComment(
    resource: Resource,
    comment: string,
    parentComment?: CommentFeedItemModel,
    parentReferenceId?: number,
    referenceUserKey?: number,
    mentions?: MentionItem[]
  ): Observable<CommentFeedItemModel | ReplyCommentModel> {
    const addComment$: Observable<any> = this.post<number>(
      '/comments/PostCommentOrReply',
      {
        referenceId: resource.resourceId,
        itemType: resource.resourceType,
        comment,
        title: resource.title,
        parentCommentId: parentComment?.id,
        parentCommentUserKey: parentComment?.userProfileKey,
        referenceUserKey: referenceUserKey,
        parentReferenceId,
        // DELIBERATELY an empty array. These will be populated with full UserProfileSummaries on the BE.
        mentions: [],
      },
      this.translateService.instant('CommentsSvc_AddCommentError')
    );
    return addComment$.pipe(
      map((id) => {
        const baseNewComment = {
          comment,
          dateAdded: this.translateService.instant('Core_1MinuteAgo'),
          edited: false,
          favoritedByUser: false,
          favoritedCount: 0,
          id,
          isInappropriate: false,
          isOwner: true,
          mentions,
          name: this.authService.authUser.viewerProfile.name,
          profileImage: this.authService.authUser.viewerProfile.picture,

          showReadMore: false,
          userProfileKey:
            this.authService.authUser.viewerProfile.userProfileKey,
          userUrl: this.authService.authUser.viewerProfile.profileUrl,
        };
        if (parentComment) {
          const replyComment = {
            ...baseNewComment,
            parentId: parentComment.id,
          };
          return replyComment as ReplyCommentModel;
        } else {
          const commentFeedItem = {
            ...baseNewComment,
            replyLimit: 0,
            remainingReplies: 0,
            replies: [],
            hasShownMoreReplies: false,
          };
          return commentFeedItem as CommentFeedItemModel;
        }
      }),
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Takeaway Added',
          properties: {
            location: this.webEnvironmentService.analyticsAppLocation ?? 'None',
          },
        });
      })
    );
  }

  /** Delete the provided comment after presenting a confirmation modal to the user */
  public deleteComment(
    comment: CommentModel,
    event: Event
  ): Observable<unknown> {
    // TODO: after dependent uses are migrated, consider moving this to the top service level
    // if it's still needed, but this is currently mainly necessary for handling delete in ajs comment.ts
    const dismissModalError = 'dismissModal';
    const deleteComment$ = this.isIframe
      ? this.ebbCommentsApiService.deleteComment(comment, event)
      : this.modalService
          .show(DeleteCommentConfirmationModalComponent, {
            backdropClickClose: true,
            inputs: {
              comment,
            },
            windowClass: 'lg-modal',
            errorOnDismiss: true,
          })
          .pipe(
            catchError((errorMessage) => {
              // handle canceling the modal explicitly so we can choose
              // what to do and what to return for further handling
              return of(dismissModalError);
            }),
            switchMap((response) => {
              if (response !== dismissModalError) {
                return this.post<void>('/comments/delete', {
                  commentId: comment.id,
                });
              } else {
                return of(response);
              }
            }),
            tap((response) => {
              if (response !== dismissModalError) {
                this.trackerService.trackEventData({
                  action: 'Takeaway Deleted',
                  properties: {
                    commentId: comment.id,
                  },
                  element: event?.target as HTMLElement,
                });
              }
            })
          );
    return deleteComment$;
  }

  /** Flags the comment as inappropriate */
  public flagComment(comment: CommentModel): Observable<void> {
    return this.post<void>(
      '/comments/flagInappropriate',
      {
        commentId: comment.id,
        comment: comment.comment,
      },
      this.translateService.instant('CommentsSvc_FlagCommentError')
    ).pipe(
      tap(() => {
        comment.isInappropriate = true;
      })
    );
  }

  /** Load the comment feed for a given resource */
  public getComments(
    resource: Resource,
    take = 10,
    skip = 0
  ): Observable<CommentFeedModel> {
    const getComments$: Observable<CommentFeedModel> = this.isIframe
      ? this.ebbCommentsApiService.getComments(resource, 3, skip)
      : this.get<CommentFeedModel>(
          '/comments/get',
          {
            commentType: resource.resourceType,
            referenceId: resource.resourceId,
            take,
            skip,
          },
          this.translateService.instant('CommentsSvc_AccessError')
        );
    return getComments$;
  }

  public getAllComments(itemId: number): Observable<CommentFeedModel> {
    return this.get(
      '/comments/getall',
      {
        params: { referenceId: itemId },
      },
      this.translateService.instant('CommentsSvc_AccessError')
    );
  }

  /** Toggles the viewer's like/unlike status. The comment is optimistically modified in place */
  public likeComment(
    resource: Resource,
    comment: CommentModel,
    parentReferenceId?: number
  ): Observable<void> {
    // Optimistically update the client state
    const isLike = !comment.favoritedByUser;
    comment.favoritedByUser = isLike;
    comment.favoritedCount += isLike ? 1 : -1;

    const likeComment$: Observable<any> = this.isIframe
      ? this.ebbCommentsApiService.likeComment(
          resource,
          comment,
          parentReferenceId
        )
      : this.post<void>(
          '/comments/setLikeStatus',
          {
            commentItem: comment,
            referenceId: resource.resourceId,
            itemType: resource.resourceType,
            isLike,
            parentReferenceId,
          },
          this.translateService.instant('CommentsSvc_LikeError')
        );

    return likeComment$.pipe(
      catchError((error) => {
        // Undo the optimistic update
        comment.favoritedByUser = !isLike;
        comment.favoritedCount += isLike ? -1 : 1;

        throw error;
      })
    );
  }

  /**
   * Load more comments for a given comment feed. The model is updated in place before the returned
   * observable completes
   */
  public loadMore(comments: CommentFeedModel, take = 10): Observable<void> {
    return this.getComments(
      {
        resourceId: comments.objectId,
        resourceType: comments.objectType,
      },
      take,
      comments.feed.length
    ).pipe(
      map((result) => {
        // Merge the feeds and copy over all properties from the server response
        const feed = comments.feed.concat(result.feed);
        Object.assign(comments, result);
        comments.feed = feed;
        return; // Don't return anything because we want Observable<void>
      })
    );
  }

  public getReplies(
    referenceType,
    referenceId: number,
    commentId: number,
    take: number,
    skip: number
  ): Observable<any> {
    return this.get(
      '/comments/getreplies',
      {
        commentType: referenceType,
        referenceId: referenceId,
        commentId: commentId,
        take,
        skip,
      },
      this.translateService.instant('CommentsSvc_AccessRepliesError')
    );
  }

  public setCommentLikeStatus(
    item,
    referenceId,
    referenceType,
    isLike,
    parentReferenceId
  ): Observable<any> {
    // don't want item.Comment to change up the pipe
    const itemCopy = { ...item };
    return this.post(
      '/comments/setLikeStatus',
      {
        commentItem: itemCopy,
        ReferenceId: referenceId,
        ItemType: referenceType,
        isLike: isLike,
        ParentReferenceId: parentReferenceId,
      },
      this.translateService.instant('CommentsSvc_LikeError')
    );
  }

  /**
   * @deprecated Please be aware that the backend had marked comments/postreply as deprecated.
   * @param referenceId
   * @param referenceType
   * @param parentCommentId
   * @param parentCommentUser
   * @param comment
   * @param itemTitle
   * @param parentReferenceId
   * @param mentions
   * @param parentCommentText
   * @param trackingArea
   */
  public addReply(
    referenceId,
    referenceType,
    parentCommentId,
    parentCommentUser,
    comment,
    itemTitle,
    parentReferenceId,
    mentions,
    parentCommentText,
    trackingArea
  ): Observable<any> {
    return this.post('/comments/postreply', {
      ReferenceId: referenceId,
      ItemType: referenceType,
      ParentCommentId: parentCommentId,
      ParentCommentUserKey: parentCommentUser,
      Comment: comment,
      Title: itemTitle,
      ParentReferenceId: parentReferenceId,
      Mentions: [],
      ParentCommentText: parentCommentText,
      Location: trackingArea,
    }).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Reply Added',
          properties: {
            ReferenceId: referenceId,
            ItemType: referenceType,
            ParentCommentId: parentCommentId,
            ParentCommentUserKey: parentCommentUser,
            Comment: comment,
            Title: itemTitle,
            ParentReferenceId: parentReferenceId,
            Mentions: mentions,
            Location: trackingArea,
          },
        });
      })
    );
  }

  public editComment(
    commentId,
    comment,
    itemType,
    itemId,
    ownerId,
    mentions
  ): Observable<any> {
    return this.put(
      '/comments/put',
      {
        commentId: commentId,
        comment: comment,
        itemType: itemType,
        referenceId: itemId,
        parentReferenceId: ownerId,
        mentions: [],
      },
      this.translateService.instant('CommentsSvc_UpdateCommentError')
    ).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Takeaway Edited',
          properties: {
            commentId: commentId,
            comment: comment,
            itemType: itemType,
            referenceId: itemId,
            parentReferenceId: ownerId,
            mentions: mentions,
          },
        });
      })
    );
  }

  public showTopLikers(commentId, event): Observable<any> {
    return this.get('/comments/gettoplikers', {
      params: { commentId: commentId, count: 10 },
    }).pipe(
      tap((response: any) =>
        this.userService.showUsersList(
          this.i18n.CommentsSvc_UsersTitle,
          response.data,
          event
        )
      ),
      catchError((error) => this.i18n.CommentsSvc_GetUsersError)
    );
  }
}
