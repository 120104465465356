<dgx-breadcrumbs [breadcrumbs]="breadcrumbs"></dgx-breadcrumbs>

<div *ngIf="group" class="rel">
  <ng-container *ngIf="!showAccessPage; else groupJoinOrRequest">
    <ng-template *ngTemplateOutlet="groupDetails"></ng-template>
  </ng-container>

  <dgx-footer-glasses></dgx-footer-glasses>

  <!-- Group details template-->
  <ng-template #groupDetails>
    <div class="guts-m-b-1 guts-m-t-2">
      <dgx-group-overview [groupInfo]="groupInfo"></dgx-group-overview>
    </div>

    <!-- Tabs -->
    <dgx-tab-navigation
      [tabList]="tabsList"
      queryParamsHandling="preserve"
    ></dgx-tab-navigation>

    <!-- Current component for selected tab -->
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>

  <!-- Join/Request membership template -->
  <ng-template #groupJoinOrRequest>
    <div class="grid guts-m-b-1 guts-m-t-1">
      <div class="center-text grid__col-8 center-item">
        <!-- Group name -->
        <h2 class="h2 font-bold ib-v-middle">{{ group.name }}</h2>

        <!-- Group description -->
        <p class="par guts-p-v-1">{{ group.description }}</p>

        <!-- Group meta counts -->
        <p class="par par--light guts-p-b-1">
          {{ groupCountText }} • {{ groupTypeText }}
        </p>

        <!-- Active users -->
        <dgx-social-pics
          [users]="topActiveUsers"
          [totalUsers]="group.userCount"
          size="small"
          [attr.aria-label]="groupCountText"
          tabindex="0"
        ></dgx-social-pics>

        <!-- Join group button -->
        <div class="grid__cell guts-m-t-2">
          <button
            df-button
            [dfButtonType]="groupInfo.isPendingMember ? 'ghost' : 'primary'"
            [attr.disabled]="disableJoinGroupButton"
            class="guts-m-t-2"
            data-dgat="group-4ed"
            (click)="submitForMembership()"
          >
            {{ accessPageButtonText }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
