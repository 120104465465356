/**
 * Generate opaque (base64 encoded) id, for use as a block id
 *
 * @link https://docs.microsoft.com/en-us/rest/api/storageservices/put-block#uri-parameters
 */
export function blockIdGenerator() {
  let _count = 0;
  const nextBlockId = () => {
    const nextId = _count.toString().padStart(10, '0');
    _count++;
    return btoa('block-' + nextId);
  };

  return nextBlockId;
}

/**
 * Format a blocklist commit request
 *
 * @link https://docs.microsoft.com/en-us/rest/api/storageservices/put-block-list#sample-request
 */
export function formatCommitRequestBody(blockIds: string[]): string {
  let requestBody = "<?xml version='1.0' encoding='utf-8'?><BlockList>";
  for (const blockId of blockIds) {
    requestBody += '<Latest>' + blockId + '</Latest>';
  }
  requestBody += '</BlockList>';
  return requestBody;
}
