<dgx-modal
  [canCancel]="false"
  [canCloseHeader]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
>
  <!-- Header -->
  <ng-container class="modal-header">
    {{ i18n.recommendationForm_UpdateItem }}
  </ng-container>

  <!-- Content -->
  <ng-container class="modal-body">
    <div *ngIf="!authUser?.isRestrictedProfile">
      <!--
        Linter Error
        [options]="options"
        Property 'options' does not exist on type 'RecommendationUpdateModalComponent'.
      -->
      <df-form-root
        [form]="form"
        [model]="model"
        [fields]="fields"
        class="block guts-p-t-half"
      >
      </df-form-root>
    </div>
  </ng-container>

  <!-- Footer -->
  <ng-container class="modal-footer">
    <div class="grid grid--align-center grid--justify-space-between">
      <button
        df-button
        dfButtonType="passive"
        [disabled]="isSaving"
        (click)="deleteRecommendation($event)"
        data-dgat="recommendationUpdateForm-83e"
        [class.is_disabled]="isSaving || isDeleting"
        [attr.aria-disabled]="isSaving || isDeleting"
        [attr.aria-label]="isSaving ? i18n.Core_Loading : i18n.Core_Delete"
      >
        <df-spinner-button [isSpinning]="isDeleting">
          <span [textContent]="i18n.Core_Delete"></span>
        </df-spinner-button>
      </button>
      <button
        df-button
        dfButtonType="primary"
        (click)="updateRecommendation()"
        [disabled]="form.invalid || isDeleting || isSaving"
        [class.is_disabled]="form.invalid || isDeleting || isSaving"
        [attr.aria-disabled]="form.invalid || isDeleting || isSaving"
        [attr.aria-label]="isSaving ? i18n.Core_Loading : i18n.Core_Save"
        data-dgat="recommendationUpdateForm-291"
      >
        <df-spinner-button [isSpinning]="isSaving">
          <span [textContent]="i18n.Core_Save"></span>
        </df-spinner-button>
      </button>
    </div>
  </ng-container>
</dgx-modal>

<!--Templates-->
<ng-template #dateDuePicker let-formControl="formControl">
  <dgx-date-picker
    [isMinDateToday]="true"
    [date]="model.dateDue"
    [placeholder]="i18n.recommendationForm_PickADate"
    (dateSelect)="updateDateDue(formControl, $event)"
  ></dgx-date-picker>
</ng-template>
