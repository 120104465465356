import { AbstractControl } from '@angular/forms';
import { focusOnFirstInvalidField } from '@app/user-content/user-input-v2/utils/form-field-helper';

export const handleAccomplishmentFocusOnSubmit = (formControls: {
  [key: string]: AbstractControl;
}): void => {
  const objectEntries = Object.entries(formControls);
  const dateRangeFormEntry = getDateRangeFormEntry(objectEntries);
  const controlObjectEntriesInDomOrder =
    getAccomplishmentControlObjectEntriesInDomOrder(
      objectEntries,
      dateRangeFormEntry
    );
  focusOnFirstInvalidField(controlObjectEntriesInDomOrder, dateRangeFormEntry);
};

/**
 * The dateRangeForm field is technically a child component and FormGroup that is appended to the list of form controls.
 * We have to put the form controls in the order that they appear in the dom in order to shift focus
 * to the first field with a validation error upon submit of the form.
 */
const getAccomplishmentControlObjectEntriesInDomOrder = (
  objectEntries: [string, AbstractControl][],
  dateRangeFormEntry: [string, AbstractControl]
): [string, AbstractControl][] => {
  const indexOfDescriptionEntry = objectEntries.indexOf(
    objectEntries.find((entry) => entry[0] === 'title')
  );
  const indexOfDateRangeFormEntry = objectEntries.indexOf(dateRangeFormEntry);

  objectEntries.splice(indexOfDateRangeFormEntry, 1);
  objectEntries.splice(indexOfDescriptionEntry + 1, 0, dateRangeFormEntry);

  return objectEntries;
};

const getDateRangeFormEntry = (
  objectEntries: [string, AbstractControl][]
): [string, AbstractControl] =>
  objectEntries.find((entry) => entry[0] === 'dateRangeForm');
