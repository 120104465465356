// Typeguards

import { GroupInfoCore } from '@app/groups/group-api';
import { UserSearchItem } from '@app/user/user-api.model';

export function isUser(
  recommendee: UserSearchItem | GroupInfoCore
): recommendee is UserSearchItem {
  return !!(recommendee as UserSearchItem).userProfileKey;
}

export function isGroup(
  recommendee: UserSearchItem | GroupInfoCore
): recommendee is GroupInfoCore {
  return !!(recommendee as GroupInfoCore).groupId;
}
