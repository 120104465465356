import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserSearchItem } from '@app/user/user-api.model';

@Component({
  selector: 'dgx-user-search-item',
  templateUrl: './user-search-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSearchItemComponent {
  @Input() public item: UserSearchItem;
}
