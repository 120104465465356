import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  LearningResourceType,
  RecommendationInfo,
} from '@app/shared/models/core-api.model';

import { UserRecommendationType } from '@app/shared/models/core.enums';

import {
  AssignmentStatusEnum,
  ProfileAssignmentService,
} from '@app/profile/components/profile-assignments/profile-assignments.service';

@Component({
  selector: 'dgx-assignment-badge',
  templateUrl: './assignment-badge.component.html',
  styleUrls: ['./assignment-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentBadgeComponent implements OnChanges {
  @Input() public assignmentInfo: RecommendationInfo;
  @Input() public resourceType?: LearningResourceType;
  public statusLabel: string;
  public assignmentStatus: AssignmentStatusEnum;
  public isRequired: boolean;
  public isExternal: boolean;
  public isExternalAndRequired: boolean;
  public isAssigned: boolean;
  public isPathway: boolean;
  public hasDueDate: boolean;
  public contentTypeLabel: string;

  public get showBadge() {
    return (
      this.assignmentInfo &&
      !this.assignmentInfo.alreadyCompleted &&
      (this.isRequired || this.isAssigned || this.isPathway)
    );
  }

  public get showCompletedBadge() {
    return this.assignmentInfo && this.assignmentInfo.alreadyCompleted;
  }

  public get showAssignedDelimeter() {
    return !!this.statusLabel || this.isPathway;
  }

  public get showVerified() {
    return (
      this.assignmentInfo &&
      this.assignmentInfo.alreadyCompleted &&
      this.assignmentInfo.reference.isVerified
    );
  }

  constructor(
    private translateService: TranslateService,
    private profileAssignmentService: ProfileAssignmentService
  ) {}

  public get badgeClass() {
    let className = '';

    if (this.assignmentInfo && this.assignmentInfo.alreadyCompleted) {
      className = 'badge-success';
      return className;
    }

    switch (this.assignmentStatus) {
      case AssignmentStatusEnum.OverDue:
        className = 'badge-danger';
        break;
      case AssignmentStatusEnum.DueSoon:
        className = 'badge-attention';
        break;
      case AssignmentStatusEnum.DueLater:
        className = 'badge-neutral';
        break;
      default:
        className = 'badge-neutral';
        break;
    }

    return className;
  }

  public ngOnChanges({ assignmentInfo, resourceType }: SimpleChanges): void {
    if (
      assignmentInfo &&
      this.assignmentInfo &&
      !this.assignmentInfo.alreadyCompleted
    ) {
      this.isRequired =
        this.assignmentInfo.recommendationType ===
        UserRecommendationType.RequiredLearning;

      // TODO: figure out how to make this work consistently between the home page and the assignments page
      // so we can use recommendations-service.isRequired
      this.isExternalAndRequired =
        !this.assignmentInfo.creator?.name &&
        this.assignmentInfo.recommendationType ===
          UserRecommendationType.RequiredLearning;

      this.isAssigned =
        !this.isRequired &&
        !!this.assignmentInfo.recommendationType &&
        this.assignmentInfo.recommendationType !==
          UserRecommendationType.Recommendation;

      this.hasDueDate = !!this.assignmentInfo.dateDue;

      this.assignmentStatus = this.profileAssignmentService.getAssignmentStatus(
        this.assignmentInfo.dateDue
      );
      this.statusLabel = this.getLabelText(this.assignmentStatus);
      this.contentTypeLabel = this.getContentTypeLabelText();
    }

    if (assignmentInfo && this.assignmentInfo) {
      this.isExternal = !!this.assignmentInfo?.reference?.externalId;
    }

    if (resourceType && resourceType.currentValue) {
      this.isPathway = this.resourceType === 'Pathway';
    }
  }

  public getContentTypeLabelText() {
    if (!this.resourceType) {
      return '';
    }
    if (this.assignmentInfo.contentType) {
      return this.assignmentInfo.contentType;
    }

    // usually we only need assignmentInfo.contentType, but contentType is not available on the inputs razor page
    // InputsSvc.Info so we need to manually translate
    const contentTypeTranslations = {
      course: 'Core_Course',
      article: 'Core_Article',
      podcast: 'Core_Podcast',
      episode: 'Core_Podcast',
      video: 'Core_Video',
      book: 'Core_Book',
      event: 'Core_Event',
      experience: 'Core_Position',
      assessment: 'Core_Assessment',
      pathway: 'Core_Pathway',
      plan: 'Core_Target',
      target: 'Core_Target',
      task: 'Core_Task',
      post: 'Core_Post',
    };
    const lcResourceType = this.resourceType.toLowerCase();
    if (!Object.keys(contentTypeTranslations).includes(lcResourceType)) {
      // there should always be a matching resourceType, but have a safeguard just in case something was missed or added
      return this.translateService.instant('Core_Inputs');
    }
    return this.translateService.instant(
      contentTypeTranslations[lcResourceType]
    );
  }

  public getLabelText(assignmentStatus: AssignmentStatusEnum): string {
    switch (assignmentStatus) {
      case AssignmentStatusEnum.OverDue:
        return this.translateService.instant('dgAssignmentsOverview_Overdue');
      case AssignmentStatusEnum.DueSoon:
        return this.translateService.instant('dgAssignmentsOverview_DueSoon');
      case AssignmentStatusEnum.DueLater:
        return this.translateService.instant(
          'dgAssignmentsOverview_Incomplete'
        );
    }
  }
}
