import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * Validates if the user has only entered spaces.
 */
export const isEmptyValidator = (
  control: AbstractControl,
  field?: FormlyFieldConfig
): ValidationErrors | null => {
  // default to true if field isn't being passed in; otherwise, check
  // to see whether the field is currently required.
  const fieldIsRequired = field ? field?.templateOptions.required : true;
  return !fieldIsRequired || (control.value || '').toString().trim().length
    ? null
    : { required: true };
};
