import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// services
import { TranslateService } from '@ngx-translate/core';

// types and models
import { TagsApi } from '@app/tags/tag-api.model';
import { Group } from '@app/groups/group-api';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { ProfileSkillSignalService } from '@app/profile/components/profile-skill-signal-page/profile-skill-signal-page.service';

export const LEARNING_TYPES = [
  'userepisode',
  'userarticle',
  'userbook',
  'uservideo',
  'usercourse',
  'userpost',
  'userassessment',
  'usertask',
  'userevent',
];

export const ANONYMOUS_LEARNING_TYPES = [
  'userepisode',
  'userarticle',
  'userbook',
  'uservideo',
  'usercourse',
  'userpost',
  'usertask',
];

export const ACHIEVEMENT_TYPES = [
  'userdegree',
  'usercertificate',
  'useraccomplishment',
  'useraward',
  'userbadge',
];

export const EXPERIENCE_TYPES = ['userposition'];

@Injectable({
  providedIn: 'root',
})
export class ProfileOverviewService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private skillSignalsService: ProfileSkillSignalService
  ) {}

  public getHasParsedResume(): Observable<{ hasBeenParsed: boolean }> {
    return this.http.get(`/user/resumeUploadStatus`);
  }

  public getParsedResumeLastModifiedDate(): Observable<string> {
    return this.http.get('/user/resume-upload-date-modified');
  }

  public getUserGroups(userKey: number): Observable<Group[]> {
    return this.http
      .get('/user/Groups', {
        params: {
          userKey,
        },
        cache: false,
      })
      .pipe(
        catchAndSurfaceError(
          this.translate.instant('UserGroupsSvc_ProblemAccessingGroup')
        )
      );
  }

  public getUserGroupsCount(
    userKey: number
  ): Observable<{ groupsCount: number }> {
    return this.http
      .get('/userprofile/GetGroupsCount', {
        params: {
          userKey,
        },
        cache: false,
      })
      .pipe(
        catchAndSurfaceError(
          this.translate.instant('UserGroupsSvc_ProblemAccessingGroup')
        )
      );
  }

  public getAllSkills(userKey: number): Observable<{
    allTagIds: string;
    skills: TagsApi.FocusableTagDetails | {};
    ratingsTotalCount: number;
  }> {
    return this.skillSignalsService.getUserSkills(userKey).pipe(
      take(1),
      map(
        ({
          skills,
          ratingsTotalCount,
        }): {
          allTagIds: string;
          skills: TagsApi.FocusableTagDetails;
          ratingsTotalCount: number;
        } => {
          const focusTagIds = skills.focus
            ? skills.focus.map((s) => s.tagId)
            : [];
          const otherTagIds = skills.other
            ? skills.other.slice(0, 6).reduce((allSkills, skill) => {
                if (!focusTagIds.includes(skill.tagId)) {
                  allSkills.push(skill.tagId);
                }
                return allSkills;
              }, [])
            : [];

          const allTagIds = [...focusTagIds, ...otherTagIds].join(',');
          return {
            allTagIds,
            skills,
            ratingsTotalCount,
          };
        }
      )
    );
  }
}
