import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SkillAssessments } from '@app/orgs/taxonomy-api.model';
import { DataColumn } from '@app/shared/components/data-table/data-table';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { WindowToken } from '@app/shared/window.token';
import { TagRatingAddIntegrationRatingsSuccessModalComponent } from '@app/tags/components/tag-rating-add-integration-ratings-success-modal/tag-rating-add-integration-ratings-success-modal.component';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-review-integration-ratings-modal',
  templateUrl: './tag-rating-review-integration-ratings-modal.component.html',
  styleUrls: ['./tag-rating-review-integration-ratings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingReviewIntegrationRatingsModalComponent implements OnInit {
  @Input() public skillAssessments: SkillAssessments[];
  @Input() public submitButtonText: string;
  @Input() public headerText: string;
  @Input() public isReviewOnly: boolean = false;
  @Input() public providerName: string;

  @ViewChild('skillName', { static: true })
  public skillName: TemplateRef<any>;

  public i18n: { [key: string]: string } = this.translateService.instant([
    'Core_Done',
    'Core_Skills',
    'Core_Cancel',
    'dgTagRating_AssessmentScore',
  ]);
  public propTrackingKey: string = 'tagId';
  public columns: DataColumn[];
  private authUserUrl = this.authService.authUser.viewerProfile.profileUrl;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private translateService: TranslateService,
    private tagsService: TagsService,
    private modalService: ModalService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  /**
   * Get list of selected items
   */
  public get selectedItems(): any[] {
    const items = this.skillAssessments as any[];
    return items.filter((item) => !!item.isSelected);
  }

  /**
   * Submit sould be enabled if only reviewing skills, or if att least 1 skill is selected
   */
  public get submitEnabled(): boolean {
    return this.isReviewOnly ? true : this.selectedItems.length > 0;
  }

  public ngOnInit(): void {
    if (!this.isReviewOnly) {
      // should start with everything selected
      this.selectAll();
      this.i18n.dgTagRating_FoundIntegrationSkillsProfileDetails =
        this.translateService.instant(
          'dgTagRating_FoundIntegrationSkillsProfileDetails',
          {
            providerName: this.providerName,
          }
        );
    } else {
      this.i18n.dgTagRating_AddedIntegrationSkillsProfileDetails =
        this.translateService.instant(
          'dgTagRating_AddedIntegrationSkillsProfileDetails',
          {
            skillsLength: this.skillAssessments.length,
            providerName: this.providerName,
          }
        );
    }

    this.columns = [
      {
        label: this.i18n.Core_Skills + ` (${this.skillAssessments.length})`,
        prop: 'tagName',
        headClasses: 'l_w60',
        bodyClasses: 'break',
        template: this.skillName,
      },
      {
        label: 'dgTagRating_AssessmentScore',
        prop: 'ratingLevel',
        headClasses: 'l_w30',
        bodyClasses: 'break right-text',
      },
    ];
  }

  /**
   * On submit if the user can select skills to add add those skills and take the user to the success modal.
   * If not take the user to the skills profile tab to allow them to rate those skills.
   */
  public onSubmit(): void {
    if (!this.isReviewOnly) {
      const tagsToAdd = this.selectedItems.map((item) => {
        return {
          tagId: item.tagId,
          name: item.tagName,
        };
      });
      this.tagsService.addUserTags(tagsToAdd as TagsApi.Tag[]).subscribe(() => {
        const inputs = {
          skills: tagsToAdd.length,
        };
        this.activeModal.close();
        this.modalService.show(
          TagRatingAddIntegrationRatingsSuccessModalComponent,
          {
            inputs,
          }
        );
      });
    } else {
      let url: string;
      url = `/profile/${this.authUserUrl}/skills`;
      this.windowRef.location.href = url;
      this.activeModal.close();
    }
  }

  /**
   * Select all skill assessments in the table
   */
  private selectAll(): void {
    this.skillAssessments = this.skillAssessments.map((item) => ({
      ...item,
      isSelected: true,
    }));
  }
}
