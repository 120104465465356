<div class="grid">
  <div class="grid__col-12">
    <form [formGroup]="deleteForm" (ngSubmit)="deleteGroup()">
      <p class="guts-m-b-1">{{ i18n.dgGroupDelete_InstructionsFormat }}</p>
      <div class="rel guts-m-b-quart tag-wrap">
        <label for="verifyText" class="a11y-hide">{{
          i18n.dgGroupDelete_DeleteLabelFormat
        }}</label>
        <div class="field-group">
          <span class="field-group__input"
            ><input
              type="text"
              id="verifyText"
              [placeholder]="i18n.dgGroupDelete_DeleteLabelInfoFormat"
              formControlName="verifyText"
              data-dgat="groupDelete-905"
          /></span>
          <span class="field-group__btn">
            <button
              df-button
              dfButtonType="destructive"
              [attr.inactive]="working || !deleteForm.valid ? true : undefined"
              type="submit"
              data-dgat="groupDelete-86f"
            >
              {{ i18n.dgGroupDelete_DeleteGroupTitle }}
            </button>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
