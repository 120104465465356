import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dgx-setting-tile-save-footer',
  templateUrl: './setting-tile-save-footer.component.html',
})
export class SettingTileSaveFooterComponent {
  @Input() public isFormDirty: boolean;
  @Input() public isFormValid: boolean;
  @Output() public saved = new EventEmitter();
  @Output() public cancelled = new EventEmitter();

  public handleSave() {
    this.saved.emit();
  }

  public handleCancel() {
    this.cancelled.emit();
  }
}
