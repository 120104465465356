import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

// types and services
import {
  NgbDateParserFormatter,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  lastValueFrom,
  map,
} from 'rxjs';

// types and services
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { CourseNotificationService } from '@app/user-content/user-input-v2/inputs/course/services/course-notification.service';
import { CourseService } from '@app/user-content/user-input-v2/inputs/course/services/course.service';
import {
  InputsFacadeBaseV2,
  MediaMetadataStatus,
} from '@app/user-content/user-input-v2/services/inputs-facade-base';

// misc
import { CourseEntry, LocationType } from '@app/inputs/inputs-api.model';
import {
  onFormControlReset,
  onFormControlUpdate,
} from '@app/shared/utils/angular-form-field-helpers';
import { shouldUpdateModel } from '@app/user-content/user-input-v2/utils/form-field-helper';
import { institutionIsValid } from '@app/user-content/user-input-v2/utils/validators';
import { notNullishOrEmptyString } from '@app/utils';
import { lazySearch } from '@dg/shared-rxjs';
import { AuthService } from '@dg/shared-services';
import {
  CourseFormDataModel,
  CourseApiInputEdit,
  CourseModel,
  CourseTypeId,
} from '../course.model';
import {
  getUnitType,
  getUnits,
  transformDateAndTimeToUTC,
} from './course.utils';
import { CourseMapper } from './course-mapper.service';

@Injectable()
export abstract class CourseBaseFacade extends InputsFacadeBaseV2<CourseModel> {
  public viewModel$ = new BehaviorSubject<CourseModel>(undefined); // TODO: type

  public get orgId(): number {
    return this.authService.authUser.defaultOrgId;
  }

  public get orgName(): string {
    return this.authService.authUser.orgInfo.find(
      (org) => org.organizationId === this.orgId
    ).name;
  }

  constructor(
    protected inputsService: InputsService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService,
    protected courseService: CourseService,
    protected mapperService: CourseMapper,
    protected courseNotificationService: CourseNotificationService,
    protected authService: AuthService,
    private datehandler: NgbDateParserFormatter
  ) {
    super(inputsService, inputImageUploadAdapterService);
  }

  public resetSubmissionStatus() {
    this.submissionStatus$.next(SubmissionStatus.None);
    // this.shouldS;
  }

  public async onSubmit(
    form: FormGroup,
    isUserMediaEntry: boolean = false
  ): Promise<void> {
    try {
      this.submissionStatus$.next(SubmissionStatus.Submitting);

      // Step 1 update the View model with the form data
      const formData = form.value;
      this.updateViewWithFormData(formData);
      // step 2 get API Params from mapper
      const apiParameters = this.mapperService.toApiParameters(
        this.viewModel as CourseModel
      );

      if (this.viewModel.inputContext.isEditing) {
        // !!! TODO: Remove this later, we're only adding it to make the Cypress tests pass.
        // (And Cypress only checks for it on updateCourse.) The BE doesn't need an owner string
        // anymore and won't use it when other props are being sent. !!!
        await this.courseService.updateCourse({
          ...apiParameters,
          owner: this.viewModel.owner?.name,
        });
      } else {
        const addCourse = isUserMediaEntry
          ? this.courseService.addGlobalCourse.bind(this.courseService)
          : this.courseService.addCourse.bind(this.courseService);
        const submissionResult = await addCourse(apiParameters);
        this.viewModel = {
          ...this.viewModel,
          inputId: submissionResult.result?.inputId ?? this.viewModel.inputId,
          submissionResult, // required for the completion modal
        };
      }
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
    } catch (e) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw e;
    }
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: CourseFormDataModel) {
    // Don't stomp SessionDetails when not present.
    const sessionDetailsFromForm = formData.sessionDetails;
    if (!!sessionDetailsFromForm) {
      const newSessionDetailsForViewModel = {
        ...this.viewModel.sessionDetails,
        endDateTime: transformDateAndTimeToUTC(
          sessionDetailsFromForm.endDate,
          sessionDetailsFromForm.endTime,
          this.datehandler
        ),
        startDateTime: transformDateAndTimeToUTC(
          sessionDetailsFromForm.startDate,
          sessionDetailsFromForm.startTime,
          this.datehandler
        ),
        isRegistrationAvailable: sessionDetailsFromForm.isRegistrationAvailable,
        isRegistrationUrlInputUrl:
          sessionDetailsFromForm.isRegistrationUrlInputUrl,
        locationType:
          sessionDetailsFromForm.locationType.isOnline &&
          sessionDetailsFromForm.locationType.isInPerson
            ? LocationType.Hybrid
            : sessionDetailsFromForm.locationType.isOnline
              ? LocationType.Online
              : sessionDetailsFromForm.locationType.isInPerson
                ? LocationType.InPerson
                : null,
        locationAddress: sessionDetailsFromForm.locationAddress,
        locationUrl: sessionDetailsFromForm.locationUrl,
        registrationUrl: sessionDetailsFromForm.registrationUrl,
        timeZoneId: sessionDetailsFromForm.timeZoneId,
      };
      this.viewModel = {
        ...this.viewModel,
        sessions: this.viewModel.isSessionDetailsToggledOn
          ? [newSessionDetailsForViewModel]
          : null,
        sessionDetails: this.viewModel.isSessionDetailsToggledOn
          ? newSessionDetailsForViewModel
          : null,
      };
    }

    // Advanced Settings form will only be present when toggled open.
    // That doesn't mean we should stomp its settings when it's closed.
    const advancedSettings = formData.advancedSettings;
    if (!!advancedSettings) {
      this.viewModel = {
        ...this.viewModel,
        language: advancedSettings?.language?.id,
        externalId: advancedSettings.internalItemId,
        publishDate: advancedSettings.publishedDate,
      };
    }

    this.viewModel = {
      ...this.viewModel,
      courseUrl: formData.courseUrl,
      // NOTE: Most of the forms don't set name to the viewModel. Those forms are using 'title' instead of name,
      // so we can easily distinguish between the two here. (Pathway/Plans sets the name to the VM, because the
      // form winds up with an object due to NgbTypeahead).
      name: formData.title || this.viewModel.name,
      description: formData.description,
      imageUrl: formData.image,
      durationMinutes: formData.durationForm?.durationMinutes,
      durationHours: formData.durationForm?.durationHours,
      tags: formData.tags?.length
        ? formData.tags
        : formData.skills?.length
          ? formData.skills
          : [],
      // These are for advanced Settings only
      orgContentMetadata: {
        ...this.viewModel.orgContentMetadata,
        hideFromCatalog: formData.addToCatalog
          ? !formData.addToCatalog
          : this.viewModel.orgContentMetadata?.hideFromCatalog,
        groupIds: formData.advancedSettings?.visibility.groups,
      },
    };
  }

  public onNext(...args: [string | CourseTypeId]): Promise<void> {
    return;
  }

  /**
   * This
   * @param inputContext
   */
  protected initializeViewModel(inputContext: InputContext): void {
    // Listen for changes on media parsing status TODO submission Status
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
      this.mediaMetadataStatus$,
    ]).pipe(
      map(
        ([s, m]) =>
          s === SubmissionStatus.Submitting || m === MediaMetadataStatus.Parsing
      )
    );

    // initialize context
    super.initializeContextViewModel(inputContext);

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: false,
      canManageContent:
        !!this.authService.authUser?.defaultOrgInfo?.permissions?.manageContent,
      isFormal: false,
      courseUrl: '',
      duplicateCount: 0,
      limits: {
        description: 3000,
      },
      readonly: {
        courseUrl: false,
        description: false,
      },
      shouldSpinSubmitButton$,
      submissionStatus$: this.submissionStatus$,
      isCourseTypeSelected: this.isCourseTypeSelected,
    };
  }

  public async initializeEdit(): Promise<void> {
    // Get course from CourseService
    const editEntry: CourseApiInputEdit = (await this.courseService.getCourse(
      this.viewModel.inputContext.inputId
    )) as CourseApiInputEdit;
    // Pass it to our mapper to get a VM from the combined properties
    const updatedView = this.mapperService.toViewModel(
      editEntry,
      this.viewModel
    );
    // Finally, add a couple more computed properties.
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isInitialForm: false,
      organizationId: this.orgId,
      isSessionDetailsToggledOn: !!updatedView.sessionDetails,
    };
  }

  /**
   * Whether the courseTypeId of the current item is selected.
   * Used by GlobalAdd and PathsandPlans.
   *
   * @param courseTypeId - Current courseTypeId.
   * @param newCourseTypeId - Comparative courseTypeId.
   */
  public isCourseTypeSelected(
    courseTypeId: CourseTypeId,
    newCourseTypeId: CourseTypeId
  ): boolean {
    return courseTypeId === newCourseTypeId;
  }

  /**
   * When course type changes. Used by GlobalAdd and PathsAndPlans.
   *
   * @param courseTypeId - courseTypeId to update.
   */
  public onCourseTypeChange(courseTypeId: CourseTypeId): void {
    this.viewModel = {
      ...this.viewModel,
      courseTypeId,
      isSubmitButtonDisabled: false,
    };
  }

  /**
   * Handler for course name Typeahead event. Performs a search for a given
   * term, passing a possibly valid and possibly not institution along with.
   * (We do a default search where providers aren't set, and return a list of
   * courses attached to the institution otherwise.)
   *
   * @param term
   */
  public onCourseNameSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> => {
    return term.pipe(
      lazySearch<AutocompleteItem>((searchTerm: string) =>
        this.courseService.getCourses(
          searchTerm,
          this.viewModel.isFormal,
          this.viewModel.institutionId
        )
      )
    );
  };

  /**
   * Handler for item selection on course name Typeahead. Updates the vm,
   * then returns the course where there's a match.
   *
   * @param courseId
   * @param isEbbLoading
   */
  public async onCourseNameSelect(
    {
      item: { id: courseId, value: name },
    }: NgbTypeaheadSelectItemEvent<AutocompleteItem>,
    form: FormGroup,
    isEbbLoading = false // TODO: Handle the extension scenario.
  ): Promise<void | CourseEntry> {
    // SANITY CHECK: courseId should always be truthy here, but just in case.
    if (!courseId) {
      // Full course reset.
      this.courseReset(form, true);
      // ...and return. This will set the form to error.
      return;
    }

    // THEN update loading state.
    this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

    // THEN attempt to get a course matching our courseId.
    const course = await lastValueFrom(
      this.courseService.getRawCourse(courseId)
    );

    // WHEN there is no course
    if (!course) {
      // THEN update the name
      this.viewModel.name = name;
      // AND do a partial reset
      this.courseReset(form);
      // AND bail.
      this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
      return;
    }

    // OTHERWISE, update course
    this.courseUpdate(form, course);
    // THEN update loading state.
    this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);
  }

  /**
   * Handler for removing focus from course name input. Updates the vm
   * where necessary.
   *
   * @param course - Either a string or an AutocompleteItem. Metadata contains our institutionId.
   * @param form - Parent form.
   */
  public onCourseNameSet(course: string | AutocompleteItem, form: FormGroup) {
    // WHEN the new value is not meaningfully different, bail.
    if (
      (!course && !this.viewModel.institutionId) ||
      (!!this.viewModel.institutionId &&
        (course as AutocompleteItem)?.metadata?.institution ===
          this.viewModel.institutionId)
    ) {
      return;
    }

    // OTHERWISE, update the vm.
    // AND WHEN the old name was NOT nullish, reset the course.
    if (notNullishOrEmptyString(this.viewModel.name)) {
      this.courseReset(form);
    }
    // EITHER WAY, set our new name. By this point, we can assume that it's a text
    // input, because if it were a real course, institutionId would've been set and
    // would have matched.
    this.viewModel.name = course as string;

    // THEN, if this is a truthy, free-text entry, and we have a provider set,
    // we should also clear it to avoid associating free-text courses with legit
    // providers.
    if (course && this.viewModel.institutionName) {
      this.providerReset(form, true);
    }
  }

  /**
   * Handler for removing focus from SIMPLE course name input. Updates the vm
   * when necessary.
   *
   * @param courseName - Current string value of the input.
   */
  public onCourseNameSimpleSet(courseName: string) {
    // WHEN the new value is not meaningfully different, bail.
    if (!shouldUpdateModel(this.viewModel.name, courseName)) {
      return;
    }
    // OTHERWISE, set our new name.
    this.viewModel.name = courseName;
  }

  /**
   * Handler for provider Typeahead event.
   *
   * @param term
   */
  public onProviderSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> => {
    return term.pipe(
      lazySearch<AutocompleteItem>((searchTerm: string) =>
        this.courseService.getInstitutions(
          searchTerm,
          this.viewModel.isFormal,
          this.viewModel.country
        )
      )
    );
  };

  /**
   * Handler for item selection on provider Typeahead. Updates the vm,
   * then returns the institution name for the input.
   *
   * @param institutionId
   * @param institutionName
   */
  public async onProviderSelect(
    {
      item: { id: institutionId, value: institutionName },
    }: NgbTypeaheadSelectItemEvent<AutocompleteItem>,
    form: FormGroup
  ): Promise<void> {
    // SANITY CHECK: institutionId should always be truthy here, but just in case.
    if (!institutionId) {
      // reset institution property and institutionId, reset isAccredited...
      this.viewModel.institutionName = institutionName;
      this.providerReset(form);
      // ...and return. This will set the form to error.
      return;
    }
    // OTHERWISE, update the loading status
    this.mediaMetadataStatus$.next(MediaMetadataStatus.Parsing);

    // THEN attempt to get an institution matching our institutionId
    const institution = await this.courseService.getInstitution(institutionId);
    // WHEN provider is truthy
    if (!!institution) {
      // THEN save the updated institution details
      institutionName = institution.name;
      institutionId = institution.institutionId;
    }

    // THEN, for UNACCREDITED PROVIDERS ONLY, check course. Accredited courses are not
    // directly associated with institutionIds.
    if (
      !this.viewModel.isFormal &&
      this.viewModel.name &&
      this.viewModel.input?.institutionId !== institutionId
    ) {
      this.courseReset(form, true);
    }

    // EITHER WAY, update the loading status again
    this.mediaMetadataStatus$.next(MediaMetadataStatus.FullyParsed);

    // THEN update the view model.
    this.viewModel = {
      ...this.viewModel,
      institution,
      institutionId,
      institutionName,
    };
  }

  /**
   * Handler for removing focus from provider input. Updates the vm
   * where necessary.
   *
   * @param course - Either a string or an AutocompleteItem. Id matches our institutionId.
   * @param form - Parent form.
   */
  public onProviderSet(
    institution: string | AutocompleteItem,
    form: FormGroup
  ): void {
    // WHEN the new value is not meaningfully different, bail.
    if (
      (!institution && !this.viewModel.institutionId) ||
      (!!this.viewModel.institutionId &&
        (institution as AutocompleteItem)?.id === this.viewModel.institutionId)
    ) {
      // SANITY CHECK. Re-validate if there's an error!
      if (form.get('institutionName')?.hasError) {
        this.providerValidation(form);
      }
      // EITHER WAY, return. We're done.
      return;
    }
    // OTHERWISE, update the vm.
    // AND WHEN the institutionName name was NOT nullish, reset the provider.
    if (notNullishOrEmptyString(this.viewModel.institutionName)) {
      this.providerReset(form);
    }
    // EITHER WAY, set our new name. By this point, we can assume that it's a text
    // input, because if it were a real course, institutionId would've been set and
    // would have matched.
    this.viewModel.institutionName = institution as string;

    // THEN, for UNACCREDITED PROVIDERS ONLY, check course. Accredited courses are not
    // directly associated with institutionIds.
    if (
      !this.viewModel.isFormal &&
      this.viewModel.name &&
      !!this.viewModel.input?.institutionId
    ) {
      this.courseReset(form, true);
    }

    // THEN validate provider, which will show an error.
    this.providerValidation(form);
  }

  // ***************************
  // PROTECTED -------------------
  // Course methods
  // ***************************

  /**
   * Remove / null-out properties associated with the course.
   *
   * @param form
   * @param clearName
   */
  protected courseReset(form: FormGroup, clearName = false) {
    // Clear course, potentially including name.
    this.viewModel = {
      ...this.viewModel,
      name: clearName ? '' : this.viewModel.name,
      creditHours: null,
      inputId: undefined,
      input: undefined,
    };

    // Potential form updates.
    const propsToUpdate = [];

    if (clearName) {
      propsToUpdate.push('name');
    }

    // Input has already been emptyed, but creditHours needs to be removed from
    // the top-level too.
    for (let prop of [
      'courseNumber',
      'creditHours',
      'courseUrl',
      'description',
    ]) {
      if (this.viewModel.readonly?.[prop]) {
        this.viewModel[prop] = undefined;
        this.viewModel.readonly[prop] = false;
        propsToUpdate.push(prop);
      }
    }

    // WHEN there are any updates to make...
    if (propsToUpdate.length) {
      // THEN, update form.
      onFormControlReset(form, propsToUpdate);
    }
  }

  // ***************************
  // PRIVATE -------------------
  // Course methods
  // ***************************

  /**
   * Update the vm course information.
   *
   * @param form
   * @param course
   */
  private courseUpdate(form: FormGroup, course: CourseEntry): void {
    // FIRST, set up the extent.
    const extent = {
      ...this.viewModel.extent,
      courseLevel: this.viewModel.isFormal
        ? this.viewModel.allCourseLevels.find(
            (item) => item.id === course.levelRank
          )?.id
        : undefined,
      dateCompleted: course.dateCompleted,
      verificationUrl: course.verificationUrl,
      courseGrade: course.gradeId,
    };

    // THEN, create our input prop.
    const input = {
      ...this.viewModel.input,
      courseId: course.courseId,
      creditHours: course.creditHours,
      externalId: course.externalId,
      // NOTE: Formal institution courses don't actually have institutionIds here.
      // These courses are very broad and are coming from a generalized database.
      institutionId: course.institutionId,
      providerId: course.providerId,
      durationUnits: course.durationUnits,
      durationUnitType: course.durationUnitType,
      unitType: getUnitType(
        course,
        this.viewModel.input?.unitType,
        this.viewModel.institution
      ),
      units: getUnits(
        course,
        this.viewModel.input?.units,
        this.viewModel.institution
      ),
    };

    const readonly = {
      courseUrl: !!course.courseUrl,
      description: !!course.description,
      courseNumber: !!course.courseId, // We never actually make this field readonly, but we do want to check if it was auto-filled.
      creditHours: !!course.creditHours,
    };

    // FINALLY, complete VM
    this.viewModel = {
      ...this.viewModel,
      input,
      extent,
      inputId: course.inputId,
      isAccredited: course.isAccredited,
      name: course.name,
      description: course.description,
      courseNumber: course.courseId,
      creditHours: course.creditHours || course.durationUnits,
      courseUrl: course.courseUrl,
      tags: course.tags,
      unitType: input.unitType,
      units: input.units,
    };

    const coursePropsToUpdate = [
      'description',
      'courseUrl',
      'tags',
      'courseId',
      'creditHours',
    ];
    let formPropsToUpdate = [];

    coursePropsToUpdate.forEach((courseProp) => {
      if (course[courseProp]) {
        let formPropName = courseProp;
        if (courseProp === 'courseId') {
          formPropName = 'courseNumber';
        }
        formPropsToUpdate.push(formPropName);
      }
    });

    // AND WHEN our course came with a description/courseUrl/tags/courseId/creditHours
    if (formPropsToUpdate.length > 0) {
      // THEN, we also want to update those fields in the form.
      onFormControlUpdate(
        form,
        formPropsToUpdate,
        formPropsToUpdate.map((prop) => this.viewModel[prop])
      );
    }

    // FINALLY update readonly, after the form values have all been set.
    this.viewModel.readonly = readonly;
  }

  // ***************************
  // Provider methods
  // ***************************

  /**
   * Remove / null-out properties associated with the course provider.
   *
   * @param form
   * @param clearName
   */
  private providerReset(form: FormGroup, clearName = false): void {
    this.viewModel = {
      ...this.viewModel,
      institutionName: clearName ? '' : this.viewModel.institutionName,
      institution: null,
      institutionId: undefined,
      isAccredited: this.viewModel.isFormal,
    };
    // WHEN we are also clearing the institutionName prop
    if (clearName) {
      // THEN, update form.
      onFormControlReset(form, 'institutionName');
    }
  }

  /**
   * Manual validation of the provider field. Necessary because
   * ReactiveForms validators can't seem to keep up with our vm
   * changes.
   *
   * @param form
   */
  private providerValidation(form: FormGroup): void {
    const field = form.get('institutionName');
    // WHEN institution is NOT valid
    if (!institutionIsValid(this.viewModel)) {
      // THEN set institutionInvalid error
      field.setErrors({ institutionInvalid: true });
    }
    // WHEN institution IS valid
    else {
      // THEN clear error
      field.setErrors(null);
    }
  }
}
