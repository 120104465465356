import { Injectable } from '@angular/core';
import { emitOnce } from '@ngneat/elf';

import { GroupItem } from '@app/groups/group-api';
import {
  PathwayBinItem,
  PathwayDetailsModel,
  PathwayPermissionsModel,
  PathwaySection,
  PathwayStep,
  PathwaySubsection,
} from '@app/pathways/rsm/pathway-api.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { updateRequestStatus } from '@app/shared/rsm/utils';
import { produce } from 'immer';
import { Observable } from 'rxjs';
import { ReactiveStore } from '@app/shared/rsm';
import {
  PathwayActivation,
  PathwayEntity,
  PathwayLevel,
  PathwayState,
  initPathwayState,
} from './pathway.model';
import { toIndexes, toSectionIndex } from './utils';
const PATHWAY_STORE = 'store.Pathways';

/**
 * Reactive store for "Pathway"
 * Currently only manages 1 pathway at a time...
 *
 * NOTE: Can be easily extended to manage multiple pathways with _store.addItems()
 */
@Injectable({ providedIn: 'root' })
export class PathwayStore extends ReactiveStore<PathwayState, PathwayEntity> {
  public state$: Observable<PathwayState>;

  constructor() {
    super(PATHWAY_STORE, initPathwayState);
  }

  /**********************************************
   * Paythway Update Methods
   **********************************************/

  public updatePathwayAndPermissions(
    pathway: PathwayDetailsModel,
    permissions: PathwayPermissionsModel
  ) {
    emitOnce(() => {
      this.updateStore((draft: PathwayState) => {
        draft.pathway = pathway;
        draft.permissions = permissions;
      });
      this._store.update(updateRequestStatus(PATHWAY_STORE, 'success'));
    });
  }

  public updatePathway(pathway: PathwayDetailsModel) {
    this.updateStore((draft: PathwayState) => {
      draft.pathway = pathway;
    });
  }

  public updateShouldDisplayNote(
    step: PathwayStep,
    shouldDisplayNote: boolean
  ) {
    this.updateStore((draft: PathwayState) => {
      const [sectionIndex, subsectionIndex, stepIndex] = toIndexes(
        draft.pathway,
        step.node
      );

      draft.pathway.levels[sectionIndex].lessons[subsectionIndex].steps[
        stepIndex
      ]['note'] = { ...step.note, shouldDisplayNote };
    });
  }

  public updateEditMode({ editmode, editMode, tltag }: Record<string, string>) {
    this.updateStore((draft: PathwayState) => {
      // query params are case sensitive, fallback for both
      const editModeParam = editmode ?? editMode;
      // only modify if incoming params are defined
      draft.isEditMode = !!editModeParam ? !(editModeParam !== 'true') : false;
      draft.tltag = !!tltag ? tltag : draft.tltag;
    });
  }

  public updateVersion(version: string) {
    this.updateStore((draft: PathwayState) => {
      draft.version = version;
    });
  }

  /**
   * Call on pathway initialization to get our faux container settings.
   *
   * @param hasFauxSection - The true/false state of our hasFauxSection prop.
   * @param fauxSubsections - The array of faux subsections.
   */
  public initializeFauxSettings({ hasFauxSection, fauxSubsections }): void {
    this.updateStore((draft: PathwayState) => {
      draft.hasFauxSection = hasFauxSection;
      draft.fauxSubsections = fauxSubsections;
    });
  }

  /**
   * Update the faux container settings.
   *
   * @param hasFaux - The true/false state of a given property. For faux subsections, whether to add or remove the node.
   * @param node - The node to add or remove from the faux subsections array. Optional.
   * @param type - The type of container.
   */
  public updateHasFaux({
    hasFaux,
    node,
    type,
  }: {
    hasFaux: boolean;
    node?: string;
    type: PathwayLevel;
  }) {
    this.updateStore((draft: PathwayState) => {
      // Section
      if (type === PathwayLevel.SECTION) {
        draft.hasFauxSection = hasFaux;
        return;
      }
      // Subsections
      const currSubsections = draft.fauxSubsections;
      // Add node to our current array of faux subsections.
      if (hasFaux) {
        draft.fauxSubsections.push(node);
        return;
      }
      // Remove node from our current array (will also remove duplicates, in the unlikely event of one)
      draft.fauxSubsections = currSubsections.filter(
        (fauxNode) => fauxNode !== node
      );
    });
  }

  public updateEnrollment(isEnrolled: boolean) {
    this.updateStore((draft: PathwayState) => {
      draft.pathway.isEnrolled = isEnrolled;
    });
  }
  public updateItemInBin(value: boolean, prop?: string, index?: number) {
    this.updateStore((draft: PathwayState) => {
      draft.itemBin[index][prop] = value;
    });
  }

  public updateItemBin(
    items: PathwayBinItem[],
    forceRemove = false,
    index?: number
  ) {
    this.updateStore((draft: PathwayState) => {
      if (!forceRemove) {
        draft.itemBin = items;
      } else {
        draft.itemBin.splice(index, 1);
      }
    });
  }
  public updatePathwayPrivacy(
    privacyLevel: Visibility,
    groups: GroupItem[],
    canViewPathwayEnrollees?: boolean,
    canViewInsightsTab?: boolean,
    orgId?: number
  ) {
    this.updateStore((draft: PathwayState) => {
      draft.pathway.privacyLevel = privacyLevel;
      draft.pathway.groupIds = groups;
      if (canViewPathwayEnrollees) {
        draft.permissions.canViewPathwayEnrollees = canViewPathwayEnrollees;
      }
      if (canViewInsightsTab) {
        draft.permissions.canViewInsightsTab = canViewInsightsTab;
      }
      if (orgId) {
        draft.pathway.organizationId = orgId;
      }
    });
  }

  public updatePathwayPermissions() {}

  /**
   * Update a specific pathway section
   * NOTE: this adds, updates, OR removes a section
   *
   */
  public updateSection(section: PathwaySection, forceRemove = false) {
    this.updateStore((draft: PathwayState) => {
      const index = toSectionIndex(draft.pathway.levels, section.node);
      if (!forceRemove) {
        draft.pathway.levels[index] = { ...section };
      } else {
        delete draft.pathway.levels[index];
      }
    });
  }

  /**
   * Update a specific pathway section's title or description
   */
  public updateSectionField(
    sectionIndex: number,
    field: string,
    value: unknown
  ) {
    this.updateStore((draft: PathwayState) => {
      const current = draft.pathway.levels[sectionIndex][field];
      if (current !== value) {
        // Only mutate if the new value is DIFFERENT
        draft.pathway.levels[sectionIndex][field] = value;
      }
    });
  }

  /**
   *  Update a specific pathway subsection's title or description
   */
  public updateSubsectionField(
    subsection: PathwaySubsection,
    field: string,
    value: unknown
  ) {
    this.updateStore((draft: PathwayState) => {
      const [sectionIndex, subsectionIndex] = toIndexes(
        draft.pathway,
        subsection.node
      );

      const current =
        draft.pathway.levels[sectionIndex].lessons[subsectionIndex][field];

      if (current !== value) {
        // Only mutate if the new value is DIFFERENT
        draft.pathway.levels[sectionIndex].lessons[subsectionIndex][field] =
          value;
      }
    });
  }

  /**
   * Update a specific pathway step's author note
   */
  public updateStepField(
    step: PathwayStep,
    field: string,
    newValue: string
  ): void {
    this.updateStore((draft: PathwayState) => {
      const [sectionIndex, subsectionIndex, stepIndex] = toIndexes(
        draft.pathway,
        step.node
      );
      draft.pathway.levels[sectionIndex].lessons[subsectionIndex].steps[
        stepIndex
      ][field] = { note: newValue };
    });
  }

  /**
   * Update specific SUBSECTION in a pathway section
   * NOTE: this adds, updates, OR removes a subsection
   */
  public updateSubsection(subsection: PathwaySubsection, forceRemove = false) {
    this.updateStore((draft: PathwayState) => {
      const [sectionIndex, subsectionIndex] = toIndexes(
        draft.pathway,
        subsection.node
      );
      if (!forceRemove) {
        draft.pathway.levels[sectionIndex].lessons[subsectionIndex] = {
          ...subsection,
        };
      } else {
        delete draft.pathway.levels[sectionIndex].lessons[subsectionIndex];
      }
    });
  }

  /**
   * Update specific subsection(lesson) STEP in a pathway section
   * NOTE: this adds, updates, OR removes a step
   */
  public updateStep(step: PathwayStep, forceRemove = false) {
    this.updateStore((draft: PathwayState) => {
      const [sectionIndex, subsectionIndex, stepIndex] = toIndexes(
        draft.pathway,
        step.node
      );

      if (!forceRemove) {
        draft.pathway.levels[sectionIndex].lessons[subsectionIndex].steps[
          stepIndex
        ] = {
          ...step,
        };
      } else {
        delete draft.pathway.levels[sectionIndex].lessons[subsectionIndex]
          .steps[stepIndex];
      }
    });
  }

  /**
   * Update the pathway's duration
   * @param durationHours
   * @param durationMinutes
   */
  public updatePathwayDuration(durationHours: number, durationMinutes: number) {
    this.updateStore((draft: PathwayState) => {
      draft.pathway.durationHours = durationHours;
      draft.pathway.durationMinutes = durationMinutes;
    });
  }
  /**
   * Updates a specific section's durations
   * @param node
   * @param durationHours
   * @param durationMinutes
   */
  public updateSectionDuration(
    node: string,
    durationHours: number,
    durationMinutes: number
  ) {
    this.updateStore((draft: PathwayState) => {
      const index = toSectionIndex(draft.pathway.levels, node);
      draft.pathway.levels[index].durationHours = durationHours;
      draft.pathway.levels[index].durationMinutes = durationMinutes;
    });
  }

  /**
   * Update the pathway optional step count.
   * @param modifiedStepCount - Steps to "add" to the count. (Might be negative.)
   */
  public updatePathwayOptionalStepCount(modifiedStepCount = 0) {
    this.updateStore((draft: PathwayState) => {
      draft.pathway.optionalSteps += modifiedStepCount;
    });
  }

  /**
   * Update the section optional step count.
   * @param node
   * @param modifiedStepCount - Steps to "add" to the count. (Might be negative.)
   */
  public updateSectionOptionalStepCount(node: string, modifiedStepCount = 0) {
    this.updateStore((draft: PathwayState) => {
      draft.pathway.levels[
        toSectionIndex(draft.pathway.levels, node)
      ].optionalSteps += modifiedStepCount;
    });
  }

  /**
   * Update the current activation state
   * (e.g. section, lesson, step that is 'active')
   */
  public updateActivations(activations: Partial<PathwayActivation>) {
    this.updateStore((draft: PathwayState) => {
      draft.activations = { ...draft.activations, ...activations };
    });
  }

  // ************************************************************
  // ************************************************************

  /**
   * Special wrapper to ensure all updates are wrapped in an immer produce() call
   * to ensure immutability.
   */
  private updateStore(updateFn: (draft: PathwayState) => void) {
    this._store.update(produce(updateFn));
  }
}
