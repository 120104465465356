<dgx-select
  [id]="params.id"
  class="full-width"
  [labelKey]="params.optionLabelKey"
  [trackBy]="params.optionTrackByKey"
  [options]="selectOptions"
  [selectedItem]="selectedItem"
  [placeholder]="params.placeholder || ''"
  [ariaLabel]="to.label"
  [isDisabled]="to.disabled"
  [dgatInput]="to.dgat"
  (selection)="handleSelection($event)"
  (blur)="handleBlur()"
></dgx-select>
