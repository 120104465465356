import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from 'rxjs/operators';

// misc
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { SearchFieldWrapperType } from '../../search/search-field-wrapper/search-field-wrapper.component';

// services
import { TranslateService } from '@ngx-translate/core';

/**
 * Use this component to search data-tables.
 *
 * @example
 * ```
 * // used alone
 * <dgx-data-search
 *   [placeholder]="searchPlaceholder"
 *   [searchTerm]="searchTerm"
 *   (doSearch)="search($event)"
 *   class="m-full-width"
 * ></dgx-data-search>
 *
 * // used in conjunction with data-filters
 * <dgx-data-search
 *   [placeholder]="searchPlaceholder"
 *   [searchTerm]="searchTerm"
 *   (doSearch)="search($event, filters)"
 *   class="m-full-width"
 * ></dgx-data-search>
 * ```
 *
 * @param delay - Time to wait before executing search, in milliseconds. *Defaults to 300.*
 * @param minLength - Minimum length needed before executing search. *Defaults to 2.*
 * @param placeholder - Already-translated placeholder text. *Defaults to `Search`.*
 * @param searchTerm - Term to search for, passed back to parent component on `doSearch()`.
 * @param doSearch - Event emitter to pass `searchTerm` back to parent component.
 * @param isDisabled - Boolean to indicate if the input should be disabled
 */

@Component({
  selector: 'dgx-data-search',
  templateUrl: './data-search.component.html',
  styleUrls: ['./data-search.component.css'],
})
export class DataSearchComponent
  extends SubscriberBaseDirective
  implements OnChanges, OnInit
{
  // Bindings - Input
  @Input() public delay?: number = 300;
  @Input() public minLength?: number = 2;
  @Input() public placeholder?: string = this.translate.instant('Core_Search');
  @Input() public searchTerm? = '';
  @Input() public autoComplete?: 'on' | 'off';
  @Input() public type: SearchFieldWrapperType;
  @Input() public isDisabled: boolean;

  // Bindings - Output
  @Output() public doSearch: EventEmitter<string> = new EventEmitter();

  // Local
  public searchField = new FormControl(
    // initial value for field
    '',
    // as long as this isn't required, there's no need to
    // send separate events for an emptied-out search form
    // because an empty form counts as a valid submission
    // and `distinctUntilChanged()` will prevent double empty
    // submissions.
    Validators.minLength(this.minLength)
  );
  private prevSearchTerm: string = '';

  constructor(private translate: TranslateService) {
    super();
  }

  // Angular methods
  public ngOnInit(): void {
    this.searchField.valueChanges
      .pipe(
        debounceTime(this.delay),
        distinctUntilChanged(),
        filter(() => this.searchField.valid),
        // user searches for "test", then "t", then "test"
        // this fools `distinctUntilChanged()`, but
        // we don't want to search for "test" again
        // We need to add `|| term === ''` here, as we want to handle the situation
        // that when the data-search component inits, there's already have search term in the input
        // otherwise when user clears input, the doSearch won't fire.
        filter((term: string) => this.prevSearchTerm !== term || term === ''),
        // `term.trim()` filter all the inputs that just have space as input else do not check
        filter((term: string) => (term ? !!term.trim() : true)),
        tap((term: string) => {
          this.prevSearchTerm = term;
          this.doSearch.emit(term);
        }),
        this.takeUntilDestroyed()
      )
      .subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchTerm) {
      // searchTerm can be emptied out by parent component, such was when sorting data.
      // Catch such changes and update value in view for a better user experience.
      this.searchField.patchValue(changes.searchTerm.currentValue);
    }
    if (changes.minLength) {
      this.searchField.setValidators(Validators.minLength(this.minLength));
    }
  }
}
