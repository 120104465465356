<div>
  <h3
    *ngIf="heading"
    class="h2 guts-p-b-2-half center-text"
    [innerText]="heading"
    tabindex="-1"
  ></h3>
  <div class="guts-p-h-1">
    <dgx-rating-slider
      [value]="sliderValue"
      (onRatingChange)="onRatingChange($event)"
      [options]="sliderConfig"
      [autoFocus]="autoFocus"
    ></dgx-rating-slider>
    <div
      class="l_flexbar l_flex-justify"
      *ngIf="lowestLevelName || highestLevelName"
    >
      <div
        class="par par--light par--small up-me guts-p-h-half"
        [innerText]="lowestLevelName"
      ></div>
      <div
        class="par par--light par--small up-me guts-p-h-half"
        [innerText]="highestLevelName"
      ></div>
    </div>
  </div>
  <div
    class="rel border rounded--large guts-p-full-1-half guts-m-t-1 center-text"
  >
    <dgx-tag-rating-donut
      size="sm"
      [level]="sliderValue.toString()"
      class="ib"
    ></dgx-tag-rating-donut>
    <div aria-live="polite">
      <div *ngIf="currentLevelName" class="guts-m-t-1">
        <h4
          class="epsilon no-wrap badge badge-pill badge-neutral"
          [innerText]="currentLevelName"
        ></h4>
      </div>
      <h3
        *ngIf="disableRatingsDescriptions"
        class="h3 guts-p-t-1-half center-text"
        [innerText]="description"
      ></h3>
      <p
        *ngIf="!disableRatingsDescriptions"
        class="par par--small par--light center-text guts-p-t-1-half"
        [innerText]="description"
      ></p>
    </div>
  </div>
</div>
