<span
  *ngIf="matchType"
  class="badge badge-pill"
  data-dgprop-item-clicked="Match"
  [ngClass]="{
    'badge-neutral': matchType.partial,
    'badge-attention': matchType.good,
    'badge-success': matchType.excellent,
    'badge-pill--compact': badgeSize === 'compact'
  }"
>
  {{ matchLabel }}
</span>
