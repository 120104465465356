import { ResourceType } from '@app/shared/models/core-api.model';
import { CropperCoordinates } from '@app/uploader/image-cropper-modal/image-cropper-modal.model';
import { UploadEvent } from '@app/uploader/uploader';
import {
  ImagePosition,
  PictureCropParams,
  UploadPictureAndCropResponse,
} from '@app/uploader/uploader-api.model';
import { UploaderService } from '@app/uploader/uploader.service';
import { Observable } from 'rxjs';
import { UploadAdapter } from '../upload-adapter';

export class InputImageUpload extends UploadAdapter {
  public resourceType: ResourceType;
  public resourceId: number;
  /** Used to save a resource image that has a parent associated with it
   * i.e. saving an image for a pathway node
   */
  public parentResourceTypeName: string;
  public parentResourceId: number;

  constructor(
    uploadService: UploaderService,
    resourceType: ResourceType,
    resourceId?: number,
    parentResourceTypeName?: string,
    parentResourceId?: number
  ) {
    super(uploadService);
    this.resourceType = resourceType;
    this.resourceId = resourceId;
    this.parentResourceTypeName = parentResourceTypeName;
    this.parentResourceId = parentResourceId;
  }

  public getUploadSettings() {
    return this.uploaderService.getUploadLimit('image');
  }

  public upload(
    imageFile: File,
    imageOptions: CropperCoordinates & {
      position: ImagePosition;
      altText?: string;
    }
  ): Observable<UploadEvent<UploadPictureAndCropResponse>> {
    if (!imageOptions) {
      throw new Error('Input image uploads require cropper options');
    }
    const options: PictureCropParams = {
      width: imageOptions.width,
      height: imageOptions.height,
      pointX: imageOptions.pointX,
      pointY: imageOptions.pointY,
      secondaryPointY: imageOptions.secondaryPointY ?? 0,
      secondaryHeight: imageOptions.secondaryHeight ?? 0,
      resourceType: this.resourceType,
      resourceId: imageFile ? '' : this.resourceId,
      position: imageOptions.position,
      description: imageOptions.altText,
    };

    if (this.parentResourceTypeName && this.parentResourceId) {
      options.parentResourceTypeName = this.parentResourceTypeName;
      options.parentResourceId = this.parentResourceId;
    }
    const formData = this.uploaderService.prepRawImageFile(imageFile);
    // when there is no imageFile, edit the existing crops.
    const endpoint = imageFile
      ? '/resourceimages/uploadpictureandcrop'
      : '/resourceimages/updatepicturecrop';
    return this.uploaderService.uploadFile<UploadPictureAndCropResponse>(
      formData,
      endpoint,
      {},
      options,
      true
    );
  }
}
