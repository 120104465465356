import { Component, Input, OnInit } from '@angular/core';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';

@Component({
  selector: 'dgx-org-faq-empty',
  templateUrl: './org-faq-empty.component.html',
  styleUrls: ['./org-faq-empty.component.scss'],
})
export class OrgFaqEmptyComponent implements OnInit {
  @Input() public canEditFaq: boolean;

  public readonly imageSource: string = this.webEnvironmentServive.getBlobUrl(
    '/content/img/faq/empty_faq.png'
  );
  public readonly retinaSource: string = this.webEnvironmentServive.getBlobUrl(
    '/content/img/faq/empty_faq_2x.png 2x'
  );
  public readonly srcset: string = `${this.imageSource}, ${this.retinaSource}`;
  constructor(private webEnvironmentServive: WebEnvironmentService) {}

  public ngOnInit(): void {}
}
