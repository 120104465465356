import { DfIconModule } from '../icon/icon.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DfLocalNotificationComponent } from './local-notification.component';

@NgModule({
  imports: [CommonModule, DfIconModule],
  exports: [DfLocalNotificationComponent],
  declarations: [DfLocalNotificationComponent],
  providers: [],
})
export class DfLocalNotificationModule {}
