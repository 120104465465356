<ng-container *ngIf="!showMore">
  <div
    dgxSticky
    [stickyFullWidth]="true"
    stickyCustomClass="box-shadow bg-color-ebony-a03-hue"
    notStickyCustomClass="grid__col-12"
  >
    <div
      class="l_flexbar bg-color-ebony-a03 rounded--large m-l_flexbar-block guts-p-full-1"
    >
      <div class="l_flexbar m-l_flexbar-block">
        <div class="guts-p-r-1 m-no-guts">
          <ng-container
            *ngComponentOutlet="
              getComponent('dgxReportingRangePicker');
              inputs: getChartInputs('dgxReportingRangePicker')
            "
          ></ng-container>
        </div>
      </div>
      <div
        *ngIf="canViewDownloadableReports"
        class="l_flex--align-right l_flex--align-right--no-mobile guts-p-l-2 m-block m-guts-p-l-0 m-guts-p-t-half"
      >
        <a
          [href]="reportingUrl"
          class="btn btn-passive bg-color-white m-full-width"
          dataIcon="L"
          [innerHtml]="'OrgReportingCtrl_DownloadableReports' | translate"
          data-dgat="groupReporting-af9"
        ></a>
      </div>
    </div>
  </div>
  <section>
    <dgx-kpi-list
      [kpis]="kpis"
      [kpiClass]="'grid__col-sm-3'"
      [kpisLoading]="kpisLoading"
      [kpiPlaceholder]="[0, 0, 0, 0]"
    ></dgx-kpi-list>
  </section>
  <section class="grid__col-12">
    <div class="grid__cell box-shadow rounded--large guts-p-full-2">
      <ng-container
        *ngComponentOutlet="
          getComponent('dgxLearningChart');
          inputs: getChartInputs('dgxLearningChart')
        "
      ></ng-container>
    </div>
  </section>
</ng-container>
<div class="grid" *ngIf="!disableRatingsInsights">
  <section class="grid__col-12">
    <div class="grid__cell box-shadow rounded--large guts-p-full-2">
      <ng-container
        *ngComponentOutlet="
          getComponent('dgxSkillRatingsChart');
          inputs: getChartInputs('dgxSkillRatingsChart')
        "
      ></ng-container>
    </div>
  </section>
</div>
<ng-container *ngIf="!showMore">
  <div class="grid">
    <section class="grid__col-sm-12 grid__col-md-6">
      <div class="box-shadow rounded--large full-height guts-p-full-2">
        <dgx-popular-providers
          [orgId]="orgId"
          [startDate]="startDate.value"
          [endDate]="endDate.value"
          [groupId]="groupId"
        >
        </dgx-popular-providers>
      </div>
    </section>
    <section class="grid__col-sm-12 grid__col-md-6">
      <div class="box-shadow rounded--large full-height guts-p-full-2">
        <ng-container
          *ngComponentOutlet="
            getComponent('dgxPopularDataChart');
            inputs: getChartInputs('dgxPopularDataChart')
          "
        ></ng-container>
      </div>
    </section>
  </div>
  <div class="grid">
    <section class="grid__col-sm-12 guts-m-b-2">
      <div class="grid__cell box-shadow rounded--large guts-p-full-2">
        <dgx-recommendations-status
          [orgId]="orgId"
          [groupId]="groupId"
          [location]="'Group Insights'"
        >
        </dgx-recommendations-status>
      </div>
    </section>
  </div>
</ng-container>
