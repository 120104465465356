import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
/**
 * Subscription Queue Service
 *
 * Use this service each time you initiate a new rxjs Subscription to create a
 * queue that cancels any existing subscriptions that may also be running
 *
 * This was meant to prevent a bug where users would toggle options too quickly,
 * initiating multiple simultaneous API calls. The end result
 * was a race condition where the final API call was not always the one
 * reflected on the page, creating a mismatch between selected options and the
 * data on the page
 *
 * USAGE:
 *
 * @example <caption>Using a Single Queue</caption>
 * ```
 * import { SubscriptionQueueService } from '@app/shared/services/subscription-queue.service';
 * @Component({
 * providers: [SubscriptionQueueService] // create a separate instance of this service for the component
 * })
 * ...
 * const subscription = this.reportingService.getSkillInsights().subscribe((data) => {});
 * this.reportingQueueService.persist(subscription);
 * ```
 *
 * @example <caption>Using Multiple Queues</caption>
 * ```
 *  * import { SubscriptionQueueService } from '@app/shared/services/subscription-queue.service';
 * @Component({
 * providers: [SubscriptionQueueService] // create a separate instance of this service for the component
 * })
 * ...
 * const getSkillInsightsSubscription = this.reportingService.getSkillInsights().subscribe((data) => {
 *    const getUserSubscription = this.reportingService.getUser().subscribe((data) => {});
 *    this.reportingQueueService.persist(getUserSubscription, 'getUser');
 * });
 * this.reportingQueueService.persist(getSkillInsightsSubscription, 'getSkillInsights');
 * ```
 *
 */
@Injectable()
export class SubscriptionQueueService {
  private queues: { [name: string]: Subscription[] } = {};
  private readonly DEFAULT_QUEUE_NAME: string = 'DEFAULT';
  constructor() {}
  /**
   * Adds the newest subscription to a queue and unsubscribes from all other active subscriptions
   * @param subscription and rxJS Subscription
   * @param name the name of the queue. only required if maintaining more than one queue
   */
  public persist(subscription: Subscription, name: string) {
    if (!this.queues[name]) {
      this.queues[name] = [];
    } else if (this.queues[name].length > 0) {
      this.clear(name);
    }
    this.queues[name].push(subscription);
  }

  /**
   * Clears all active subscriptions in a particular queue
   * @param name the name of the queue to clear
   */
  public clear(name: string) {
    this.queues[name].forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.queues[name].length = 0;
  }

  /**
   * Clears all queues of all subscriptions
   */
  public destroy() {
    Object.keys(this.queues).forEach((name) => this.clear(name));
  }
}
