import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DfDynamicContentProvider } from '../content-provider/providers';

import {
  DfFormFieldConfig,
  DfFieldLayout,
  DfFieldTemplateContext,
  ValidationMessageOption,
} from '../field-types';

// TODO: Replace with angular 13 definition once upgraded
type FormControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Extends {@link DfFormFieldBuilder} to allow building of custom form field configurations
 * @description This is not an injectable service. To create an instance, inject {@link DfFormFieldBuilder} and
 * call one of its field type initializer methods.
 */
export class DfConfiguredFormFieldBuilder<TConfig extends DfFormFieldConfig> {
  constructor(
    protected translate: TranslateService,
    protected config?: TConfig
  ) {
    config.modelOptions = { updateOn: 'change' };
  }

  /** Sets the {@link DfFormFieldConfig.key} property used to identify the form control in the
   * reactive form.
   * * @param key The key string, which must correspond to the name of a valid form control
   */
  public withKey(key: string) {
    this.config.key = key;
    return this;
  }

  /** Sets the {@link DfFormFieldConfig.name} property used to identify the form. Typically this
   * can be ignored in modern forms.
   * * @param name The name of the field to apply to the input element
   */
  public named(name: string) {
    this.config.name = name;
    return this;
  }

  /** Sets the field's label provider.
   * @param label A literal string resource ID or TemplateRef; or an Observable or callback or that
   * emits/retuns one
   * of those to be displayed as the field's helper label.
   * @description
   * Only literal strings will be translated as well as used to automatically create validation
   * error messages. When using other label provider types on validated fields, the error messages
   * will have to be explicitly provided.
   * Regardless of the provider type, the resulting label will always be displayed within a <label>
   * and it's child <strong> element. To omit or fully customize the label, use the {@link unwrapped}
   * method instead.
   */
  public labeled(label: DfDynamicContentProvider<DfFieldTemplateContext>) {
    this.config.templateOptions.dfLabel = label;
    return this;
  }

  /** Sets the field's description provider. The description sits just below the label.
   * @param description A literal string resource ID or TemplateRef; or an Observable or callback
   * or that emits/retuns one of those to be displayed as the field's description.
   * @description
   * Only literal strings will be translated automatically. Other provider types need to be
   * pre-translated.
   */
  public describedAs(
    description: DfDynamicContentProvider<DfFieldTemplateContext>
  ) {
    this.config.templateOptions.dfDescription = description;
    return this;
  }

  /** Sets the field's tip text, which adds a hoverable help icon to the label and displays
   * a tooltip on hover.
   * @param name A string resource ID for the localized string to use for the tip text
   */
  public withTip(tipText: string) {
    this.config.templateOptions.tip = tipText;
    return this;
  }

  /** Sets a help string provider, which will provide either static or dynamic help text
   *  below the field input element
   * @param help A literal string resource ID or TemplateRef; or an Observable or callback
   * or that emits/returns one of those to be displayed as the field's helper text.
   * @description
   * Only literal strings will be translated automatically. Other provider type results will
   * need to be pre-translated.
   */
  public withHelp(help: DfDynamicContentProvider<DfFieldTemplateContext>) {
    this.config.templateOptions.help = help;
    return this;
  }

  /** Sets the field's aria label to aid with accessibility
   * @param name A string resource ID for the localized string to use for the aria label
   */
  public ariaLabeled(label: string) {
    this.config.templateOptions.ariaLabel = label;
    return this;
  }

  /** Sets a target element ID for this field's input to control, specified by the `aria-controls`
   * attribute. This is only required and valid when a boolean input controls the state of another element,
   * such as where a checkbox or toggle switch field controls an expander.
   * * @param controlleeId The id of an external element controlled by this field's input
   */
  public ariaControlling(controlleeId: string) {
    this.config.templateOptions.ariaControlleeId = controlleeId;
    return this;
  }

  /** Sets the field's tip text, which adds a hoverable help icon to the label and displays
   * a tooltip on hover.
   * @param name A string resource ID for the localized string to use for the tip text
   */
  public withDgatId(id: string) {
    this.config.templateOptions.dgatId = id;
    return this;
  }

  /** Sets a max length, in characters, on the configuration's template options. */
  public withMaxLength(max: number) {
    this.config.templateOptions.maxLength = max;
    return this;
  }

  /** Sets the field's update mode. By default, we set the mode to 'blur' for a less obnoxious
   * validation experience, but in some cases it may be preferable to override it.
   */
  public updatedOn(triggerEvent: 'change' | 'blur' | 'submit') {
    this.config.modelOptions.updateOn = triggerEvent;
    return this;
  }

  /** Adds validators for the form to use on the field. This can also be called multiple
   * times to add mutiple validators for a more explicit approach.
   * @param validators One or more {@link AbstractFormControl} validators to add to the
   * field configuration.
   */
  public validatedBy(
    ...validators: (
      | string
      | ValidatorFn
      | {
          name: string;
          options: {
            errorPath: string;
          };
        }
    )[]
  ) {
    const config = this.config;
    if (!config.validators) {
      config.validators = {};
    }
    if (!config.validators.validation) {
      config.validators.validation = [];
    }
    config.validators.validation =
      config.validators.validation instanceof Array
        ? [...config.validators.validation, ...validators]
        : [config.validators.validation, ...validators];
    return this;
  }

  public validatedByIndexed(validators: IndexedValidators) {
    const config = this.config;
    config.validators = {
      ...(config.validators ?? {}),
      ...validators,
    };
    return this;
  }

  /** Adds asynchronous validators for the form to use on the field. This can be called
   * multiple times to add mutiple validators for a more explicit approach.
   * @param validators One or more {@link AbstractFormControl} asynchronous validators to
   * add to the field configuration.
   */
  public asyncValidatedBy(...validators: (string | AsyncValidatorFn)[]) {
    const config = this.config;
    if (!config.asyncValidators) {
      config.asyncValidators = {};
    }
    if (!config.asyncValidators.validation) {
      config.asyncValidators.validation = [];
    }
    config.asyncValidators.validation =
      config.asyncValidators.validation instanceof Array
        ? [...config.asyncValidators.validation, ...validators]
        : [config.asyncValidators.validation, ...validators];
    return this;
  }

  public asyncValidatedByIndexed(validators: IndexedAsyncValidators) {
    const config = this.config;
    config.asyncValidators = {
      ...(config.asyncValidators ?? {}),
      ...validators,
    };
    return this;
  }

  /** Specifies a set of validation error messages or message string resource IDs to use with the field,
   * by their error key. These must correspond to the validators applied, and will override the
   * globally configured error messages.
   */
  public withErrorMessages(messages: {
    [messageProperties: string]: ValidationMessageOption['message'];
  }) {
    const config = this.config;
    if (!config.validation) {
      config.validation = {};
    }
    config.validation.messages = messages;
    return this;
  }

  /** Sets the field's css class name to be applied to the field container element. */
  public styledBy(cssClass: string) {
    this.config.className = cssClass;
    return this;
  }

  /** Overrides the field's default wrapper type.
   * @param wrapperTypes One or more wrapper names. These can be any of fresco's built-in
   * wrappers or those externally registered via {@link DfFormlyModule.forRoot}. Calling
   * this method with no arguments is equivalent to calling {@link unwrapped}.
   */
  public wrappedBy(...wrapperTypes: string[]) {
    this.config.wrappers = wrapperTypes;
    return this;
  }

  /** Sets the field to use no wrapper component */
  public unwrapped() {
    this.config.wrappers = [];
    return this;
  }

  /** Sets the field wrapper's layout, overriding the default layout.
   * @description Most fields use a vertically stacked layout by default. Some fields, such as
   * toggle switches, default to a horizontal layout, with all textual content preceding the control
   * component. Note that the 'Optional' designator cannot be displayed on horizontal fields due to the
   * positioning, so unless using a custom field wrapper, this should only be used with required fields.
   */
  public withLayout(layout: DfFieldLayout) {
    this.config.templateOptions.layout = layout;
    return this;
  }

  /** Sets the field input element to receive focus initially. Only one visible field
   * on a form should use this.
   */
  public autofocused() {
    this.config.templateOptions.shouldAutofocus = true;
    return this;
  }

  /** Causes a field to be hidden based the return value of a predicate callback. */
  public hiddenWhen<T>(
    callback: (formControl: AbstractControl, model?: T) => boolean
  ) {
    this.config.hideExpression = (
      model: T,
      __: any,
      field: FormlyFieldConfig
    ) => callback(field.formControl, model);
    return this;
  }

  /** Causes a field to be disabled based the return value of a predicate callback.
   * How this state is displayed is up to each individual field.
   */
  public disabledWhen<T = unknown>(
    callback: (formControl: AbstractControl, model?: T) => boolean
  ) {
    this.config.expressionProperties = {
      'templateOptions.disabled': (
        model: T,
        __: any,
        field: FormlyFieldConfig
      ) => callback(field.formControl, model),
    };
    return this;
  }

  /**
   * Causes a field to be marked readonly based the return value of a predicate callback.
   * How this state is displayed is up to each individual field.
   */
  public readonlyWhen<T = unknown>(
    callback: (formControl: AbstractControl, model?: T) => boolean
  ) {
    this.config.expressionProperties = {
      'templateOptions.readonly': (
        model: T,
        __: any,
        field: FormlyFieldConfig
      ) => callback(field.formControl, model),
    };
    return this;
  }

  /** @summary Adds a subscription to the provided handler for the associated form control's
   * {@link FormControl#statusChanges} event.
   */
  public onControlStatusChanges<T = unknown>(
    handler: (
      status: FormControlStatus,
      model: T,
      formControl: AbstractControl
    ) => void
  ) {
    let subscription: Subscription;
    const hooks: any = {
      onInit: (field: DfFormFieldConfig) => {
        subscription = field.formControl.statusChanges.subscribe((status) => {
          handler(status, field.model, field.formControl);
        }) as unknown as Subscription;
      },
      onDestroy: () => {
        subscription.unsubscribe();
      },
    };
    DfConfiguredFormFieldBuilder.chainHooks(this.config, hooks);
    return this;
  }

  /** @summary Adds a subscription to the provided handler for the associated form control's
   * {@link FormControl#valueChanges} event.
   * @description The semantics used by Angular make understanding
   * this in comparison to {@link onChange} confusing. By default, this will emit when
   * {@link HTMLElement#change} does if the field uses a standard input element because that
   * event fires when the input is blurred, which is also default update trigger for form
   * controls. When the field is set to update on 'change', it will update on every keystroke.
   * @see onChange*/
  public onControlValueChanges<TValue, TModel = unknown>(
    handler: (
      value: TValue,
      model: TModel,
      formControl: AbstractControl
    ) => void
  ) {
    let subscription: Subscription;
    const hooks: any = {
      onInit: (field: DfFormFieldConfig) => {
        subscription = field.formControl.valueChanges.subscribe((v) => {
          handler(v, field.model, field.formControl);
        }) as unknown as Subscription;
      },
      onDestroy: () => {
        subscription.unsubscribe();
      },
    };
    DfConfiguredFormFieldBuilder.chainHooks(this.config, hooks);
    return this;
  }

  /** Adds a {@link HTMLElement.change} handler to the field's input element,
   * which fires when the value has been committed for an input, select or textarea -based
   * field.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event|change event}
   */
  public onChange<T = unknown>(
    handler: (event: Event, model: T, formControl: AbstractControl) => void
  ) {
    this.config.templateOptions.change = (field, event) =>
      handler(event, field.model, field.formControl);
    return this;
  }

  /** Adds a {@link HTMLElement.focus} event handler to the field's input element */
  public onFocus<T = unknown>(
    handler: (event: FocusEvent, model: T, formControl: AbstractControl) => void
  ) {
    this.config.templateOptions.focus = (field, event) =>
      handler(event, field.model, field.formControl);
    return this;
  }

  /** Adds a {@link HTMLElement.blur} event handler to the field's input element */
  public onBlur<T = unknown>(
    handler: (event: FocusEvent, model: T, formControl: AbstractControl) => void
  ) {
    this.config.templateOptions.blur = (field, event) =>
      handler(event, field.model, field.formControl);
    return this;
  }

  /** Adds a {@link HTMLElement.keydown} event handler to the field's input element */
  public onKeydown<T = unknown>(
    handler: (
      event: KeyboardEvent,
      model: T,
      formControl: AbstractControl
    ) => void
  ) {
    this.config.templateOptions.keydown = (field, ev) =>
      handler(ev, field.model, field.formControl);
    return this;
  }

  /** Adds a {@link HTMLElement.keyup} event handler to the field's input element */
  public onKeyup<T = unknown>(
    handler: (
      event: KeyboardEvent,
      model: T,
      formControl: AbstractControl
    ) => void
  ) {
    this.config.templateOptions.keyup = (field, ev) =>
      handler(ev, field.model, field.formControl);
    return this;
  }

  /** Adds a {@link HTMLElement.keydown} event handler to the field's input element */
  public onClick<T = unknown>(
    handler: (event: MouseEvent, model: T, formControl: AbstractControl) => void
  ) {
    this.config.templateOptions.click = (field, ev) =>
      handler(ev, field.model, field.formControl);
    return this;
  }

  /** Returns the fully built configuration and clears the internal state so a new field can be built.
   * This must always be called as the last step in the configuration method chain.
   */
  public build(): TConfig {
    const config = this.config;
    this.config = undefined; // clear for safety
    return config as TConfig;
  }

  /** Chains a set of existing lifecyle hooks for a field, if any, with a set of new ones so they
   * each get called. Note that currently, only onInit and onDestroy hooks are supported.
   */
  protected static chainHooks(config: DfFormFieldConfig, addHooks: any) {
    const existingHooks = config.hooks;
    config.hooks = existingHooks
      ? {
          onInit: (field) => {
            existingHooks.onInit?.(field);
            addHooks.onInit?.(field);
          },
          onDestroy: () => {
            existingHooks.onDestroy?.(config);
            addHooks.onDestroy?.();
          },
        }
      : { ...addHooks };
  }
}

interface IndexedAsyncValidators {
  [key: string]:
    | ((
        control: AbstractControl,
        field: DfFormFieldConfig
      ) => Promise<boolean> | Observable<boolean>)
    | {
        expression: (
          control: AbstractControl,
          field: FormlyFieldConfig
        ) => Promise<boolean>;
        message: string;
      };
}

interface IndexedValidators {
  [key: string]:
    | ((control: AbstractControl, field: FormlyFieldConfig) => boolean)
    | {
        expression: (
          control: AbstractControl,
          field: FormlyFieldConfig
        ) => boolean;
        message: ValidationMessageOption['message'];
      };
}
