import { LDFlagsService } from '../ld-flags.service';

export class InputsLDFlags {
  /***
   *  Team: Platform Services
   *  Created Date: 3/1/2023
   *  Planned Cleanup: Org Flag? TBD
   *  Visibility:
   *    - Orgs: 1529, 1709
   *
   *  Determines if youtube cookie tracking will be enabled or not.
   */
  public get videoInputsEnabledYoutubeCookieTracking(): boolean {
    return this.ldFlagsService.getFlag(
      'video-inputs-enable-youtube-cookie-tracking',
      false
    );
  }

  /***
   *  Team: CCO
   *  Created Date: 1/7/2020
   *  Planned Cleanup: Org Flag? TBD
   *  Visibility:
   *    - Orgs: 29
   *
   *  Allows for creating posts in pathways.
   */
  public get createPathwayPosts(): boolean {
    return this.ldFlagsService.getFlag('create-pathway-posts', false);
  }

  /***
   *  Team: CCO
   *  Created Date: 08/23/2023
   *  Planned Cleanup: TBD
   *  Visibility:
   *    - TBD
   *
   *  Description TBD
   */
  public get addEditContentDuration(): boolean {
    return this.ldFlagsService.getFlag('add-edit-content-duration', false);
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
