import { Component, Input, OnInit } from '@angular/core';
import { PopoverConfig } from '../../data-table';

@Component({
  selector: 'dgx-data-column-list',
  templateUrl: './data-column-list.component.html',
  styleUrls: ['./data-column-list.component.css'],
})
export class DataColumnListComponent implements OnInit {
  // Bindings
  @Input() public hideValue: boolean = false;
  @Input() public limit: number;
  @Input() public list: any[];
  @Input() public listItemClasses: string;
  @Input() public listItemLinkClasses: string;
  @Input() public numberOnly: boolean;
  @Input() public popoverButtonClasses: string;
  @Input() public popoverConfig: PopoverConfig;
  @Input() public popoverListClasses: string;
  @Input() public popoverListItemClasses: string;
  @Input() public popoverListItemLinkClasses: string;
  @Input() public popoverListItemTextClasses: string;
  @Input() public prop: string;
  @Input() public propHref: string;
  @Input() public usePopover: boolean;

  // Local
  public isPopoverOpen = false;
  public popoverListStyles: any;
  public useVanityUrl = false;

  public ngOnInit(): void {
    // defaults -- the dataTable will pass in undefined if they're left blank
    // so we must manually override them rather than relying on normal optional
    // @input() indicators.
    this.list =
      this.list?.sort((a, b) => a[this.prop]?.localeCompare(b[this.prop])) ||
      [];
    this.numberOnly ??= false;
    this.usePopover ??= false;
    // if undefined, set limit to the list length
    this.limit ??= this.list.length;
    this.listItemClasses ??= 'zeta inline rel';
    this.listItemLinkClasses ??= 'uhover';
    this.popoverListItemClasses ??= '';
    this.popoverListItemLinkClasses ??=
      'guts-p-v-1 guts-p-h-1 block hover-bg-color-ebony-a03';
    this.popoverListItemTextClasses ??= '';
    this.popoverButtonClasses ??=
      'uhover v-bottom color-ebony popover-trigger font-medium';
    this.popoverListClasses ??= 'zeta';

    // Handle cases where our propHref value is *not* prefixed with `/`;
    if (this.propHref) {
      // get the first propHref value in the list with a non-null propHref property
      const firstPropHref = this.list.find(
        (item) => item.hasOwnProperty(this.propHref) && !!item[this.propHref]
      )?.[this.propHref];
      // Check to see if it includes a forward slash; if not...
      if (firstPropHref && !firstPropHref.includes('/')) {
        // Replace our list with a version that prefixes all propHref values
        // with a forward slash.
        this.list = this.list.map((item) => {
          if (!item?.[this.propHref]) {
            return item;
          }
          return {
            ...item,
            [this.propHref]: '/' + item[this.propHref],
          };
        });
      }
    }

    // False by default. if set it to true, limit will be 0 and usePopover will be true
    if (this.numberOnly && this.usePopover) {
      this.limit = 0;
    }

    // if using popover with max height, set styles
    if (
      this.usePopover &&
      (this.popoverConfig.maxHeight || this.popoverConfig.maxWidth)
    ) {
      this.popoverListStyles = {
        'max-height': this.popoverConfig?.maxHeight || 'none',
        'max-width': this.popoverConfig?.maxWidth || 'none',
        overflow: this.popoverConfig?.overflow || 'auto',
      };
    }
  }
}
