<div class="l_flexbar border-bottom">
  <div class="l_flex-grow" tabindex="0">
    <div class="l_flexbar guts-p-v-1 guts-p-h-0">
      <dgx-profile-pic [profile]="user" size="small"></dgx-profile-pic>
      <div class="l_flex-grow guts-p-l-1">
        <h3 class="par par--small font-bold" [textContent]="user.name"></h3>
      </div>
    </div>
  </div>
  <div *ngIf="isRemovable">
    <div>
      <button
        df-button
        dfButtonType="clear"
        class="guts-p-full-1 dg-modal__close-icon"
        (click)="onRemove()"
        [ngbTooltip]="i18n.Core_Remove"
        tooltip-trigger="'focus mouseenter'"
        [attr.aria-label]="cancelButtonAriaLabel"
        data-dgat="removableUserItem-405"
      >
        <df-icon icon="cross"></df-icon>
      </button>
    </div>
  </div>
</div>
