import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataColumn } from '@app/shared/components/data-table/data-table';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dgx-skill-scale-level-change-modal',
  templateUrl: './skill-scale-level-change-modal.component.html',
  styleUrls: ['./skill-scale-level-change-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillScaleLevelChangeComponent implements OnInit {
  @Input() public ratingScaleChange: any;

  // Column templates
  @ViewChild('previousSkillScaleTemplate', { static: true })
  public previousSkillScaleTemplate: TemplateRef<any>;
  @ViewChild('newSkillScaleTemplate', { static: true })
  public newSkillScaleTemplate: TemplateRef<any>;

  public previousAnchorHigh: number;
  public columns: DataColumn[] = [];
  public items: any[] = [];
  public isLoading: boolean = true;
  public i18n: { [key: string]: string };
  public isViewingProfileSkillsPage: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.i18n = this.translate.instant([
      'dgTagRating_ReviewSkillsNewRatings',
      'dgTagRating_LevelDescription',
      'OrgSkills_ReviewSkills',
    ]);
    this.i18n.Core_SkillScaleChanged = this.translate.instant(
      'Core_SkillScaleChanged',
      {
        orgName: this.authService.authUser?.defaultOrgInfo?.name,
      }
    );
  }

  public get profileSkillsRoute(): string {
    return `/profile/${this.authService?.authUser.viewerProfile.vanityUrl.toLowerCase()}/skills`;
  }

  public ngOnInit(): void {
    this.isViewingProfileSkillsPage =
      this.router.url.toLowerCase() === this.profileSkillsRoute;
    this.previousAnchorHigh = this.ratingScaleChange[1].previousAnchorHigh;
    this.setUpTable();
  }

  public setUpTable(): void {
    this.isLoading = true;
    this.items = this.groupItemsForDescription();
    this.getTableColumns();
  }

  public reviewProfileSkills(): void {
    this.activeModal.close();
    this.router.navigateByUrl(this.profileSkillsRoute);
  }

  private groupItemsForDescription() {
    const groupedLevelsWithDescription = this.ratingScaleChange.flatMap(
      (currentObject) => [
        currentObject.description
          ? Object.assign({}, currentObject, { parentId: currentObject.value })
          : currentObject,
        currentObject.description
          ? {
              parent: currentObject.value,
              description: currentObject.description,
              type: 'groupedDescription',
              label: currentObject.name,
            }
          : null,
      ]
    );
    return groupedLevelsWithDescription.filter((item) => item !== null);
  }

  private getTableColumns(): void {
    this.columns = [
      {
        label: 'Core_PreviousRating',
        headClasses: 'l_w40 font-medium par guts-p-l-2',
        bodyClasses: 'align-left guts-p-l-2',
        template: this.previousSkillScaleTemplate,
      },
      {
        label: 'Core_NewRating',
        headClasses: 'l_w50 guts-p-l-1 font-medium par',
        bodyClasses: 'guts-p-l-1',
        template: this.newSkillScaleTemplate,
      },
      {
        label: '',
        headClasses: 'l_w10',
        bodyClasses: 'rating-description-toggle guts-p-r-2',
        prop: 'description',
      },
    ];

    this.isLoading = false;
    this.cdr.detectChanges();
  }
}
