import { Injectable } from '@angular/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import {
  TrackingEventArgs,
  TrackingProperties,
} from '@app/shared/services/tracking.model';
import { Opportunity } from '../opportunities-api.model';
import { isUserInterested } from '@app/opportunities/utils';
import { OpportunityFlagsService } from './opportunity-flags.service';

@Injectable({
  providedIn: 'root',
})
export class OpportunityTrackingService {
  private useSmarterMatching = this.opportunityFlagsService.useSmarterMatching;

  constructor(
    private opportunityFlagsService: OpportunityFlagsService,
    private trackerService: TrackerService
  ) {}

  /**
   * General tracking for *one* Opportunity (with different actions).
   */
  public trackOpportunity(
    opportunity: Opportunity,
    action: string,
    properties?: TrackingProperties
  ): void {
    const data: TrackingEventArgs = {
      action,
      properties: {
        opportunityId: opportunity.opportunityId,
        title: opportunity.title,
        isInterested: isUserInterested(opportunity),
        opportunityType: opportunity.type,
        opportunityLocation: opportunity.locationName,
        // TODO: Add this after smarter skill matching is implemented
        // relatedSkills: opportunity.relatedSkills
        matchScore: opportunity.matchedSkillsScore,
        matchScoreSource: this.useSmarterMatching
          ? 'Smarter matching'
          : 'Simple matching',
        ...properties,
      },
    };

    // track event data
    this.trackerService.trackEventData(data);
  }

  /**
   * General tracking for Manage Org Opportunity (with different actions).
   */
  public trackOrgOpportunities(
    action: string,
    properties?: TrackingProperties
  ): void {
    const data: TrackingEventArgs = {
      action,
      // events originating from this method
      // *used to be* from the Manage org section of the app
      properties: {
        ...properties,
      },
    };
    // track event data
    this.trackerService.trackEventData(data);
  }
}
