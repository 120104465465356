import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TagsApi } from '@app/tags/tag-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsService } from '@app/tags/services/tags.service';
import { TagRatingButtonService } from '@app/tags/components/tag-rating-button/tag-rating-button.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This modal allows a user to view another users ratings for a specific tag (skill)
 *
 * - The modal is only accessible to authenticated/non-owner users.
 * - Only completed/public ratings are displayed.
 */
@Component({
  selector: 'dgx-tag-public-rating-modal',
  templateUrl: './tag-public-rating-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagPublicRatingModalComponent implements OnInit {
  @Input() public tag: TagsApi.Tag;
  @Input() public headerText: string;
  @Input() public isManager?: boolean;

  public vm$: Observable<any>;

  constructor(
    private activeModal: NgbActiveModal,
    private tagsService: TagsService,
    private tagRatingButtonService: TagRatingButtonService
  ) {}

  public ngOnInit(): void {
    // `Tag.Ratings` don't include endorser/rater information so capture that here
    this.vm$ = this.tagsService
      .getTagRatingDetails(
        this.tag.rating.userProfileKey,
        this.tag.tagId,
        this.isManager
      )
      .pipe(
        map((tagRatingDetails) => {
          // Get array of rating types to dynamically build buttons
          const availableRatingTypes =
            this.tagRatingButtonService.getEnabledRatingTypes(
              tagRatingDetails,
              false // sort by rank - fall back to displaying in descending order of completion
            );
          return {
            tagRatingDetails,
            availableRatingTypes,
          };
        })
      );
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
