<form name="orgMemberForm" (ngSubmit)="save()">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="'Core_Save' | translate"
    [useDefaultForm]="false"
    [isSubmitDisabled]="!canEditPermissions"
    [isSubmitPending]="isSubmitting"
  >
    <ng-container class="modal-header">
      {{ headerTitle }}
    </ng-container>
    <ng-container class="modal-body">
      <div class="form-wrap guts-m-h-1 guts-m-v-2">
        <ng-container *ngIf="!isLoading; else loading">
          <div class="l_flex guts-p-b-1-half">
            <div>
              <div class="guts-m-r-2">
                <dgx-profile-pic
                  [profile]="member"
                  size="medium"
                ></dgx-profile-pic>
              </div>
            </div>
            <div class="l_flex-center min-width-0 break">
              <h3 class="h3">{{ member.name }}</h3>
              <ul class="par par--small par--light font-medium">
                <li *ngIf="member.organizationEmail; else nonOrgEmail">
                  <a
                    href="mailto:{{ member.organizationEmail }}"
                    data-dgat="orgMemberForm-79f"
                    >{{ member.organizationEmail }}</a
                  >
                </li>
              </ul>
              <p class="zeta color-ebony-a61 guts-m-t-half">{{ member.bio }}</p>
            </div>
          </div>
          <div class="border-top guts-p-v-2">
            <h2 class="par par--small font-bold">
              {{ 'OrgMemberFormCtrl_Role' | translate }}
            </h2>
            <div class="guts-p-t-half">
              <div
                class="ib guts-m-r-1"
                *ngFor="let role of availableRoles; trackBy: trackByRoleId"
                data-dgat="orgMemberForm-d34"
              >
                <label>
                  <input
                    type="radio"
                    name="role-option"
                    [checked]="role.roleId === assignedRole?.roleId"
                    [value]="role.roleId"
                    (change)="onRoleChange(role)"
                    [disabled]="!canEditPermissions"
                    data-dgat="orgEditMemberModal-561"
                  />
                  {{ role.name }}
                </label>
              </div>
              <ng-container
                *dgxFeatureToggle="
                  ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
                  hidden: true
                "
              >
                <div class="par par--small" data-dgat="orgEditMemberModal-f44">
                  <p class="font-bold guts-p-t-1 guts-p-b-half">
                    {{ 'Core_' + assignedRole?.labelKey + 'sCan' | translate }}
                  </p>
                  <p class="font-medium" data-dgat="orgMemberForm-9f6">
                    {{ assignedRole?.permissionNames }}
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container
            *dgxFeatureToggle="
              ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
              hidden: true
            "
          >
            <div class="guts-p-v-2 border-top">
              <h2 class="par par--small font-bold">
                {{ 'OrgMemberFormCtrl_Groups' | translate }}
              </h2>
              <div class="guts-p-t-half par par--small font-medium">
                <ul class="inline-list">
                  <li *ngIf="!memberDetail?.groups?.length">
                    <p>{{ 'OrgMemberFormCtrl_None' | translate }}</p>
                  </li>

                  <li
                    *ngFor="let group of memberDetail.groups; last as isLast"
                    data-dgat="orgMemberForm-b39"
                  >
                    <a
                      href="/groups/{{ group.groupId }}"
                      class="uhover"
                      data-dgat="orgMemberForm-b47"
                      >{{ group.name }}{{ isLast ? '' : ',&nbsp;' }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="
              canEditPermissions &&
              !isSsoOnly &&
              (canEmailResetPassword || canSetDefaultPassword)
            "
            class="guts-p-t-2 guts-p-b-1 border-top"
          >
            <h2 class="par par--small font-bold">
              {{ 'OrgMemberFormCtrl_PasswordManagement' | translate }}
            </h2>

            <div class="guts-p-v-1" *ngIf="canEmailResetPassword">
              <p class="guts-p-b-1 par par--small font-medium">
                {{ 'OrgMemberFormCtrl_ResetPasswordDesc' | translate }}
              </p>
              <button
                df-button
                dfButtonType="secondary"
                data-dgat="orgMemberForm-37d"
                (click)="emailResetPassword()"
              >
                <span>{{
                  'OrgMemberFormCtrl_EmailPasswordReset' | translate
                }}</span>
              </button>
              <br />
            </div>

            <div class="guts-p-v-1" *ngIf="canSetDefaultPassword">
              <p class="guts-p-b-1 par par--small font-medium">
                {{ 'OrgMemberFormCtrl_ResetPasswordToDefaultDesc' | translate }}
              </p>
              <button
                df-button
                dfButtonType="secondary"
                (click)="resetPasswordToDefault()"
                data-dgat="edit-member-modal-008"
              >
                {{ 'OrgMemberFormCtrl_ResetPasswordToDefault' | translate }}
              </button>
            </div>
          </div>
          <div *ngIf="canRemoveMembers" class="border-top guts-p-t-1-half">
            <h2 class="par par--small font-bold">
              {{ 'dgOrgEditMemberModal_RemoveUser' | translate }}
            </h2>
            <div class="guts-p-t-half" aria-live="polite">
              <button
                *ngIf="!confirmRemoval"
                [disabled]="removing"
                df-button
                dfButtonType="danger"
                #softRemovalButton
                (click)="softRemove()"
                data-dgat="orgMemberForm-74f"
              >
                <span *ngIf="!removing">{{
                  'OrgMemberFormCtrl_RemoveFromOrg' | translate
                }}</span>
              </button>
              <div *ngIf="confirmRemoval">
                <p class="color-destructive guts-p-b-1">
                  {{ 'OrgMemberFormCtrl_ConfirmRemovalMessage' | translate }}
                </p>
                <div class="l_flexbar l_flex-justify">
                  <button
                    #cancelRemovalButton
                    [disabled]="removing"
                    df-button
                    dfButtonType="secondary"
                    (click)="cancelRemove()"
                    data-dgat="orgMemberForm-80c"
                  >
                    {{ 'Core_Cancel' | translate }}
                  </button>
                  <button
                    [disabled]="removing"
                    df-button
                    dfButtonType="destructive"
                    (click)="remove()"
                    data-dgat="orgMemberForm-eee"
                  >
                    <span *ngIf="!removing">{{
                      'OrgMemberFormCtrl_ConfirmRemoval' | translate
                    }}</span>
                    <span *ngIf="removing">{{
                      'OrgMemberFormCtrl_Removing' | translate
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </dgx-modal>
</form>

<ng-template #loading>
  <df-spinner></df-spinner>
</ng-template>

<ng-template #nonOrgEmail>
  <li *ngIf="!member.organizationEmail">
    <a href="mailto:{{ member.email }}" data-dgat="orgMemberForm-2e5">{{
      member.email
    }}</a>
  </li>
</ng-template>
