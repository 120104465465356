<div
  class="__container df-field-list__item"
  [class.__container--reverse]="isReversed"
>
  <div
    class="rel __content"
    [ngClass]="{
      '__content--horiz': !isVertical,
      'df-form__field--invalid': showErrors,
      '__content--heading': isHeading
    }"
  >
    <label class="df-form__label" for="{{ id }}">
      <h2
        *ngIf="isHeading; else defaultLabel"
        [dfProvideContent]="to.dfLabel"
        [dfProvideContentContext]="contentContext"
      ></h2>
      <ng-template #defaultLabel>
        <span class="df-form__label-container">
          <span
            [dfProvideContent]="to.dfLabel"
            [dfProvideContentContext]="contentContext"
          ></span>
          <df-icon
            class="tip par par--light"
            icon="question-mark-circle"
            *ngIf="to.tip"
            [ngbTooltip]="to.tip"
            [a11yText]="to.tip"
            tabindex="0"
          ></df-icon>
        </span>
        <ng-container *ngIf="shouldShowRequiredDesignator">
          <df-label-marker></df-label-marker
        ></ng-container>
      </ng-template>
    </label>

    <!--Description-->
    <p
      id="{{ id + '-desc' }}"
      class="par par--small par--light"
      [ngClass]="isVertical ? 'guts-p-b-half' : 'guts-p-b-0'"
      [class.font-italic]="!isHeading"
      [attr.role]="isHeading ? 'heading' : ''"
      [attr.aria-level]="isHeading ? '4' : ''"
      [dfProvideContent]="to.dfDescription"
      [dfProvideContentContext]="contentContext"
      *ngIf="to.dfDescription"
    ></p>
    <!--Stacked layout: input container vertically sandwiched between descriptive content -->
    <ng-container *ngIf="isVertical">
      <ng-container *ngTemplateOutlet="inputContainer"></ng-container>
    </ng-container>
    <!--Helper text-->
    <p
      id="{{ id + '-help' }}"
      class="par par--small par--light guts-p-b-0"
      [ngClass]="{ 'guts-m-t-quart guts-p-h-1': isVertical }"
      [dfProvideContent]="to.help"
      [dfProvideContentContext]="contentContext"
      *ngIf="to.help"
    ></p>
    <!--Field errors-->
    <div
      id="{{ id + '-error' }}"
      class="df-form__invalid-msg ib rel"
      data-icon=""
      aria-live="polite"
      [@dfSlideFadeInLeft]
      *ngIf="showErrors"
    >
      <df-icon icon="arrow-down-right" size="small"></df-icon>
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </div>
  <!--Inline layout: input container horizontally following descriptive content -->
  <ng-container *ngIf="!isVertical">
    <ng-container *ngTemplateOutlet="inputContainer"></ng-container>
  </ng-container>
</div>

<ng-template #inputContainer>
  <div>
    <ng-container #fieldComponent></ng-container>
  </div>
</ng-template>
