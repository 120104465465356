import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';

@Component({
  selector: 'dgx-tag-rating-level-percentage-ring',
  templateUrl: './tag-rating-level-percentage-ring.component.html',
  styleUrls: ['./tag-rating-level-percentage-ring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingLevelPercentageRingComponent
  implements AfterViewInit, OnInit
{
  @Input() public size: 'lg' | 'md' | 'sm';
  @Input() public maxLevel: number;
  @Input() public currentLevel: number;
  @Input() public targetLevel: number = 0;

  public readonly diameter = 100;

  @ViewChild('svg') private svg: ElementRef;

  private readonly centerX = this.diameter / 2;
  private readonly centerY = this.diameter / 2;
  private readonly radius = 50;
  /** The stroke width of the level segments */
  private strokeWidth: number;
  /** The `radius` + half the stroke width prevents `overflow:hidden` of a parent from cutting off part of the ring */
  private outerRadius: number;
  private readonly sizes = {
    lg: {
      strokeWidth: 7, // thickness of level segment stroke
    },
    md: {
      strokeWidth: 8,
    },
    sm: {
      strokeWidth: 9,
    },
  };

  constructor(private colorService: ColorService) {}

  public get currentLevelColor(): string {
    return this.colorService.getColor('blue');
  }

  private get inactiveColor(): string {
    return this.colorService.getColor('ebony-a18');
  }

  private get targetLevelColor(): string {
    return this.colorService.getColor('sky-light');
  }

  public ngOnChanges({ currentLevel, targetLevel }: SimpleChanges): void {
    if (!this.svg || !currentLevel) {
      return;
    }

    if (
      currentLevel.currentValue !== currentLevel.previousValue ||
      targetLevel.currentValue !== targetLevel.previousValue
    ) {
      this.svg.nativeElement.replaceChildren();
      this.ngAfterViewInit();
    }
  }

  public ngOnInit(): void {
    const { strokeWidth } = this.sizes[this.size];
    this.strokeWidth = strokeWidth;
    this.outerRadius = this.radius - strokeWidth / 2;
  }

  public ngAfterViewInit(): void {
    const { maxLevel } = this;
    let { currentLevel, targetLevel } = this;
    if (!targetLevel) {
      targetLevel = 0;
    }

    if (currentLevel > maxLevel) {
      currentLevel = maxLevel;
    }
    if (targetLevel > maxLevel) {
      targetLevel = maxLevel;
    }

    // background ring
    if (currentLevel !== maxLevel && targetLevel !== maxLevel) {
      this.drawRing(
        this.inactiveColor,
        Math.max(currentLevel, targetLevel),
        maxLevel
      );
    }

    // target level ring
    if (targetLevel !== 0 && currentLevel < targetLevel) {
      if (currentLevel === 0) {
        // the target level ring needs to be round on the first end and square on the other
        this.drawRing(this.targetLevelColor, 0, 0.1, 'round');
      }
      this.drawRing(this.targetLevelColor, currentLevel, targetLevel);
    }

    // current level ring
    if (currentLevel !== 0) {
      if (currentLevel === targetLevel) {
        // if the current level === target, it needs to be round on the first end and square on the other
        this.drawRing(this.currentLevelColor, 0, 0.1, 'round');
        this.drawRing(this.currentLevelColor, 0, currentLevel, null);
      } else {
        this.drawRing(this.currentLevelColor, 0, currentLevel, 'round');
      }
    }
  }

  public drawRing(strokeColor, startLevel, endLevel, strokeLinecap?) {
    const levelAngle = 360 / this.maxLevel;
    const startAngle = levelAngle * startLevel;
    const endAngle = levelAngle * endLevel;
    // if max level draw a full circle
    if (startLevel === 0 && endLevel === this.maxLevel) {
      this.svg.nativeElement.appendChild(
        this.getCircleElement(
          this.centerX,
          this.centerY,
          this.outerRadius,
          strokeColor
        )
      );
    } else {
      // if less than max level draw an arc
      const arcPathDescription = this.getArcDescription(
        this.centerX,
        this.centerY,
        this.outerRadius,
        startAngle,
        endAngle
      );

      this.svg.nativeElement.appendChild(
        this.getArcElement(arcPathDescription, strokeColor, strokeLinecap)
      );
    }
  }

  /**
   * Build the path description of one segment
   *
   * @param centerX
   * @param centerY
   * @param radius
   * @param startAngle
   * @param endAngle
   */
  private getArcDescription(
    centerX: number,
    centerY: number,
    radius: number,
    startAngle: number,
    endAngle: number
  ): string {
    const start = this.polarToCartesian(centerX, centerY, radius, endAngle);
    const end = this.polarToCartesian(centerX, centerY, radius, startAngle);
    const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;
    return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`;
  }

  /**
   * Get the x/y coordinates for a point of an segment
   *
   * @param centerX
   * @param centerY
   * @param radius
   * @param angleInDegrees
   */
  private polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ): { x: number; y: number } {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  /**
   * Build arc element
   *
   * @param arcPathDescription
   * @param strokeColor
   */
  private getArcElement(
    arcPathDescription: string,
    strokeColor: string,
    strokeLinecap: string
  ): SVGPathElement {
    const arc = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    arc.setAttributeNS(null, 'stroke', strokeColor);
    arc.setAttributeNS(null, 'style', 'fill:none');
    arc.setAttributeNS(null, 'stroke-width', `${this.strokeWidth}`);
    arc.setAttributeNS(null, 'd', arcPathDescription);
    arc.setAttributeNS(null, 'stroke-linecap', strokeLinecap);
    return arc;
  }

  /**
   * Build circle element
   *
   * @param arcPathDescription
   * @param strokeColor
   */
  private getCircleElement(
    centerX,
    centerY,
    radius,
    strokeColor: string
  ): SVGPathElement {
    const circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle'
    );
    circle.setAttributeNS(null, 'cx', centerX);
    circle.setAttributeNS(null, 'cy', centerY);
    circle.setAttributeNS(null, 'r', radius);
    circle.setAttributeNS(null, 'stroke', strokeColor);
    circle.setAttributeNS(null, 'style', 'fill:none');
    circle.setAttributeNS(null, 'stroke-width', `${this.strokeWidth}`);
    return circle;
  }
}
