<div
  class="tile tag-tile--horizontal h-inherit"
  [ngClass]="tileClasses"
  (click)="captureTileClick($event)"
  attr.data-dgat="{{ dgatType }}"
  tabindex="0"
>
  <div class="l_flex m-l_flex">
    <div class="tag-tile--horizontal__rating">
      <!--Provider logo-->
      <dgx-tag-provider-logo
        [src]="imgSrc"
        [providerName]="providerName"
        size="md"
      ></dgx-tag-provider-logo>
    </div>

    <div class="l_flex-grow left-text">
      <!--Rating type-->
      <h4
        class="par par--small font-semibold"
        [textContent]="ratingTypeLabel"
      ></h4>

      <!--Rating level-->
      <h4
        *ngIf="hasRatings"
        class="par par--small font-medium guts-p-v-half"
        [textContent]="ratingLevelLabel"
      ></h4>

      <!--Rating description-->
      <p
        *ngIf="!hasRatings"
        class="par par--small par--light font-medium guts-p-t-half guts-p-b-1"
        [textContent]="ratingDescriptionLabel"
      ></p>

      <!--Single Endorser (Evaluation)/Rater (Manager)-->
      <div *ngIf="hasSingleEndorser" class="l_flex guts-p-t-half">
        <div class="l_flex-center">
          <dgx-profile-pic
            [profile]="endorser"
            size="tiny"
            class="ib guts-m-r-half"
          ></dgx-profile-pic>
        </div>
        <p
          class="par par--small par--light font-medium guts-p-v-half l_flex-grow"
          [textContent]="endorsedByLabel"
        ></p>
      </div>

      <!--Multiple Endorser/Rater (Peer)-->
      <div
        *ngIf="hasMultipleEndorsers"
        class="social-pics__align guts-p-t-half"
      >
        <dgx-social-pics
          [users]="endorsers"
          [maxUsers]="6"
          size="tinyish"
        ></dgx-social-pics>
      </div>

      <!--Rating meta-->
      <dgx-rating-button-meta
        *ngIf="showRatingMeta"
        [options]="ratingMeta"
      ></dgx-rating-button-meta>

      <!--Call to action-->
      <div class="guts-p-t-1-half l_flexbar" *ngIf="showCTA">
        <!-- PD-69780 - keep -->
        <ng-container>
          <div class="l_flex-grow">
            <df-icon
              *ngIf="showCTAPlus"
              icon="plus-circle"
              class="guts-p-r-half color-blue"
            ></df-icon>
            <button
              #target
              class="par par--small font-semibold color-blue left-text"
              [textContent]="CTALabel"
              (click)="handleClick($event)"
              attr.data-dgat="{{ dgatType }}-cta"
              [attr.aria-label]="ratingTypeLabel + ': ' + CTALabel"
            ></button>
          </div>
          <div class="l_flex">
            <!--Privacy PD-69780 - keep -->
            <dgx-tag-privacy-dropdown
              *ngIf="showPrivacyDropdown"
              (setTagPrivacy)="setPrivacy($event.privacyId)"
              [privacyId]="privacyId"
              [ratingType]="ratingTypeLabel"
            ></dgx-tag-privacy-dropdown>
          </div>
        </ng-container>
      </div>
    </div>

    <!--Menu-->
    <div
      *ngIf="showActionMenu"
      class="tag-tile--horizontal__menu"
      (click)="$event.stopPropagation()"
    >
      <dgx-menu
        [menuConfig]="menuConfig"
        placement="bottom-right"
        [placementAdjustLeftRem]="1"
      >
        <button type="button" attr.data-dgat="{{ dgatType }}-action-menu">
          <df-icon icon="dots" size="medium" class="color-ebony-a61"></df-icon>
        </button>
      </dgx-menu>
    </div>
  </div>

  <!--In progress notification-->
  <df-local-notification
    *ngIf="showPendingRatingNotification"
    class="block guts-m-t-1-half guts-m-h-1"
    size="small"
    [text]="pendingRatingNotificationLabel"
    icon="clock-filled"
    [type]="NotificationType.info"
  ></df-local-notification>
</div>
