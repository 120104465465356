<div class="empty-profile-content guts-m-v-1">
  <h1 class="h1 color-blue center-text">
    {{ 'Core_NoResultsFound' | translate }}
  </h1>
  <hr class="guts-m-v-2" />
  <ul class="center-text crap">
    <li>{{ 'dgNoSearchResults_CorrectSpelling' | translate }}</li>
    <li class="guts-p-v-1-half">
      {{ 'dgNoSearchResults_DifferentKeywords' | translate }}
    </li>
    <li>{{ 'dgNoSearchResults_GeneralKeywords' | translate }}</li>
  </ul>
</div>
