import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { Component, Input, OnInit } from '@angular/core';
import { generateGuid } from '@app/shared/utils/uuid';

@Component({
  selector: 'dgx-toggle-element',
  templateUrl: './toggle-element.component.html',
  animations: [DF_COLLAPSE_EXPAND],
})
export class ToggleElementComponent implements OnInit {
  @Input() public buttonText: string;
  public isOpen: boolean;
  public uid: string;

  public ngOnInit(): void {
    this.uid = generateGuid();
  }

  public toggle() {
    this.isOpen = !this.isOpen;
    return;
  }
}
