import { DatePipe } from '@angular/common';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentActionPipe } from '@app/shared/pipes/content-action.pipe';
import { EllipsisPipe } from '@app/shared/pipes/ellipsis.pipe';
import { PrettyTimeElapsedPipe } from '@app/shared/pipes/pretty-time-elapsed.pipe';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TranslateService } from '@ngx-translate/core';

/** Aggregates a few other services and pipes to make life easier when building custom notifications */
@Injectable({ providedIn: 'root' })
export class NotificationItemService {
  constructor(
    public readonly resolver: ComponentFactoryResolver,
    public readonly sanitizer: DomSanitizer,
    public readonly translate: TranslateService,
    public readonly tagRatingService: TagRatingService,
    public readonly datePipe: DatePipe,
    public readonly ellipsisPipe: EllipsisPipe,
    public readonly contentActionPipe: ContentActionPipe,
    public readonly prettyTimeElapsedPipe: PrettyTimeElapsedPipe
  ) {}
}
