import { Injectable, Pipe, PipeTransform } from '@angular/core';
import {
  MarkdownService,
  MarkdownToHtmlOptions,
} from '@app/markdown/services/markdown.service';

/** Converts markdown text to html */
@Pipe({
  name: 'markdownToHtml',
  pure: true,
})
// allows this to be imported into standalone pipes.
@Injectable({
  providedIn: 'root',
})
export class MarkdownToHtmlPipe implements PipeTransform {
  public constructor(private markdownService: MarkdownService) {}

  public transform(markdown: string, options?: MarkdownToHtmlOptions) {
    // set standard presentation options as defaults that can be individually overridden
    options = {
      sanitize: true,
      stripSourceHtml: true,
      proxyImages: true,
      openLinksInNewWindow: true,
      ...options,
    };
    if (!markdown) {
      return '';
    }
    const html = this.markdownService.markdownToHtml(markdown, options);
    return html;
  }
}
