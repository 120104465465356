<ng-container *ngIf="!showUpdateLink">
  <!--Custom label-->
  <h4
    *ngIf="showCustomLabel"
    class="epsilon no-wrap badge badge-pill badge-neutral guts-m-t-half"
    [innerHtml]="levelName"
    data-dgprop-item-clicked="Skill Tile Description"
  ></h4>

  <!--Add rating button-->
  <h4
    *ngIf="!targetAuthoring && isOwner"
    data-dgprop-item-clicked="Skill Tile Add Rating Link"
  >
    <button
      type="button"
      class="h4 par par--small font-semibold color-blue guts-m-t-1"
      data-dgat="tag-tile-rating-description-d04"
    >
      <df-icon
        [icon]="showAddRating ? 'plus-circle' : 'pencil'"
        class="guts-p-r-half color-blue"
      ></df-icon>
      <span class="ib-v-middle no-wrap">
        {{
          showAddRating
            ? i18n.dgTagRating_AddRating
            : i18n.dgTagRating_UpdateRating
        }}
      </span>
    </button>
  </h4>
</ng-container>

<ng-container *ngIf="showUpdateLink && isOwner">
  <h4 data-dgprop-item-clicked="Skill Tile Update Rating Link">
    <button
      type="button"
      class="par par--small font-semibold color-blue"
      data-dgat="tag-tile-rating-description-7f3"
    >
      <span>{{ i18n.dgTagRating_UpdateRating }}</span>
    </button>
  </h4>
</ng-container>
