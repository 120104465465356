import {
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';

// TODO: Fix Linter Error
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[dgLoadChild]' })
export class LoaderDirective implements OnInit {
  @Input('dgLoadChild')
  public componentContext: ComponentContext;

  constructor(
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  public ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.componentContext.componentType
    );

    const componentRef = this.viewContainerRef.createComponent(factory);

    this.renderer.appendChild(
      this.viewContainerRef.element.nativeElement,
      componentRef.location.nativeElement
    );
    // eslint-disable-next-line guard-for-in
    for (const binding in this.componentContext.bindings) {
      componentRef.instance[binding] = this.componentContext.bindings[binding];
    }
  }
}

export interface ComponentContext {
  componentType: Type<any>;
  bindings: any;
}
