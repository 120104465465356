import { orderBy } from './order-by';

/**
 * Return sorted items, case-insensitive. *This is not a full replacement for
 * lodash's sortBy method*, as it does not allow for dot-notation nested keys.
 *
 * @param a - The current item.
 * @param b - The next item.
 * @param keys - The sort key, or array of keys.
 *
 * @example
 * ```
 * users.sort((a, b) => sortBy(a, b, ['user', 'age']));
 * // [{ user: 'barney', age: 30 }, { user: 'friend', age: 40 }, { user: 'friend', age: 48 }]
 * ```
 */
export function sortBy(a: any, b: any, keys: string | string[]): number {
  // pass to orderBy, which will default to using ascending order on both
  return orderBy(a, b, keys);
}
