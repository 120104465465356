import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Workday Skill Added notification */
@Component({
  selector: 'dgx-workday-skills-integration-toggled',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkdaySkillsIntegrationToggledComponent extends NotificationItemComponentBase {
  protected get usePeriodicRefresh() {
    return true;
  }

  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    if (this.notification.parameters.enabled) {
      return this.translateHtml('workdaySkillAdded_WorkdayIntegrationEnabled', {
        viewSkillsHtml: this.viewSkillsTemplate(true),
      });
    } else {
      return this.translateHtml(
        'workdaySkillAdded_WorkdayIntegrationDisabled',
        {
          viewSkillsHtml: this.viewSkillsTemplate(true),
        }
      );
    }
    return '';
  }
}
