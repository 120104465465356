import { DgError } from '@app/shared/models/dg-error';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ContextService } from '@app/shared/services/context.service';
import { TranslateService } from '@ngx-translate/core';
import { OrgMemberDetail } from './orgs.model';
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { UserProfileSummary } from '@app/user/user-api.model';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrgMembersService {
  constructor(
    private contextService: ContextService,
    private http: NgxHttpClient,
    private notifierService: NotifierService,
    private trackerService: TrackerService,
    private translate: TranslateService
  ) {}

  public getManager(orgId: number): Observable<UserProfileSummary> {
    return this.http
      .get('/organizations/managers', {
        params: { orgId },
      })
      .pipe(
        map((managers: UserProfileSummary[]) => {
          // since there may be more than one manager, default to first
          if (managers.length) {
            return managers[0];
          }
        })
      );
  }

  /**
   * Returns member detail for an organization user.
   * Method is context aware for tenant, or regular organization.
   *
   * @param orgId - current org id being managed
   * @param userId - the profile id of the user being requested ( not UPK )
   */
  public getMemberDetail(
    orgId: number,
    userId: string
  ): Observable<OrgMemberDetail> {
    const api = this.contextService.workingInTenant()
      ? '/channel/tenantMemberDetail'
      : '/organizations/memberDetail';
    return this.http.get<OrgMemberDetail>(api, {
      params: { id: orgId, userId },
    });
  }

  /**
   * Returns whether or not an organization allows password reset emails to be sent from the application.
   *
   * @param orgId - current org id being managed
   */
  public allowEmailPasswordReset(orgId: number): Observable<boolean> {
    return this.http.get<boolean>('/organizations/allowemailpasswordreset', {
      params: { orgId },
    });
  }

  /**
   * Returns whether or not an organization allows admins to reset a user's password to the org default password
   */
  public allowSetPasswordToDefault(orgId: number): Observable<boolean> {
    return this.http.get<boolean>('/organizations/allowsetpasswordtodefault', {
      params: { orgId },
    });
  }

  /**
   * Returns whether or not an organization only allows sso logins
   *
   * @param orgId - current org id being managed
   */
  public getIsSsoOnly(orgId: number): Observable<boolean> {
    return this.http.get<boolean>('/organizations/isssoonly', {
      params: { orgId },
    });
  }

  /**
   * Sends a reset password email to the provided user profile id
   *
   * @param orgId - current org id being managed
   * @param userId - the profile id of the user to send email to ( not UPK )
   * @param resetOnLogin - force password change upon next successful login
   */
  public emailResetPassword(
    orgId: number,
    userId: string,
    resetOnLogin?: boolean
  ): Observable<any> {
    const params = {
      organizationId: orgId,
      userId,
      resetOnLogin,
    };
    const api = this.contextService.workingInTenant()
      ? '/channel/EmailResetPassword'
      : '/organizations/EmailResetPassword';
    return this.http.post(api, params).pipe(
      tap(() => {
        this.trackerService.trackEventData({
          action: 'Org Member Reset Password Email Sent',
          category: orgId.toString(),
          label: userId.toString(),
        });
      }),
      catchError((error) =>
        throwError(
          new DgError(this.translate.instant('OrgSvc_OrgError'), error)
        )
      )
    );
  }

  /**
   * Removes requested user from an organization
   *
   * @param orgId - current org id being managed
   * @param userId - the profile id of the user to remove from the organization ( not UPK )
   */
  public removeMember(orgId: number, userId: string): Observable<any> {
    const api = this.contextService.workingInTenant()
      ? '/channel/removeTenantMember'
      : '/organizations/removemember';

    return this.http
      .put(api, {
        organizationId: orgId,
        userId: userId,
      })
      .pipe(
        tap(() => {
          this.trackerService.trackEventData({
            action: 'Org Member Removed',
            category: orgId.toString(),
            label: 'Member',
          });
        }),
        catchError((error) =>
          throwError(
            new DgError(this.translate.instant('OrgSvc_OrgError'), error)
          )
        )
      );
  }

  /**
   * Sets an organization member's role
   *
   * @param orgId - current org id being managed
   * @param userId - the profile id of the user being modified ( not UPK )
   * @param roleId  - the new role to grant to the user
   */
  public setMemberRole(orgId: number, userId: string, roleId: number) {
    const api = this.contextService.workingInTenant()
      ? '/channel/settenantmemberrole'
      : '/organizations/setmemberrole';
    return this.http
      .put(api, {
        organizationId: orgId,
        userId,
        roleId,
      })
      .pipe(
        tap(() => {
          this.trackerService.trackEventData({
            action: 'Org Member Role Changed',
            properties: {
              roleId,
              orgId,
            },
          });
        }),
        catchError((error) =>
          throwError(
            new DgError(this.translate.instant('OrgSvc_OrgError'), error)
          )
        )
      );
  }

  /**
   * Sends an organization invite email to a list of email addresses
   *
   * @param {Object} invite
   * @param {number} invite.orgId - current org id being managed
   * @param {string[]} invite.emailAddresses - list of email addresses to send the org invitation to
   * @param {string} invite.customMessage - User entered message to accompany the invite email
   */
  public sendInvite(invite: {
    orgId: number;
    emailAddresses: string[];
    customMessage: string;
  }): Observable<any> {
    return this.http.post('/organizations/invite', invite).pipe(
      tap(() => {
        this.notifierService.showSuccess(
          this.translate.instant('OrgSvc_SendInviteSuccess')
        );
        this.trackerService.trackEventData({
          action: 'User Invite Sent',
          category: invite.orgId.toString(),
          label: 'Email Sent',
          properties: {
            OrganizationId: invite.orgId,
            InviteType: 'Email',
          },
        });
      }),
      catchError((error) =>
        throwError(
          new DgError(this.translate.instant('OrgSvc_SendInviteError'), error)
        )
      )
    );
  }

  /**
   * Gets a fully qualified invite URL for the org if the authenticated user can invite users in that org.
   * Contains a URL friendly base64 encrypted value consisting of the org id and authenticated user's profile key.
   *
   * ex: "https://degreed.com/orgs/invite/sxAM9ureg7o__BnStb4Fqw~~?orgsso=abc" or forbidden
   *
   * @param orgId - current org id being managed
   */
  public getOrgInviteUrl(orgId: number): Observable<string> {
    return this.http
      .get<string>('/organizations/GetOrganizationInviteUrl', {
        params: { orgId },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(this.translate.instant('OrgSvc_OrgError'), error)
          )
        )
      );
  }

  /**
   * Gets organization's required email suffixes.
   *
   * ex: "degreed.com,gmail.com"
   *
   * @param orgId
   */
  public getRequiredEmailSuffix(orgId: number): Observable<string> {
    return this.http.get<string>(
      '/organizations/GetOrganizationRequiredEmailSuffix',
      {
        params: { orgId },
      }
    );
  }

  public resetPasswordToDefault(
    orgId: number,
    userId: string
  ): Observable<any> {
    return this.http.post<any>('/organizations/setUserDefaultPassword', {
      organizationId: orgId,
      userId,
    });
  }
}
