import { Component, Injectable, OnInit } from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { PathwayInsightsService } from '@app/insights/services/pathway-insights.service';
import { Pathway } from '@app/pathways/pathway.model';
import * as api from '@app/pathways/rsm/pathway-api.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import { ReportingService } from '@app/shared/services/reporting/reporting.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { orderBy } from '@app/shared/utils/common-utils';
import { PathwayFacade } from '@dg/pathways-rsm';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { InsightsFeatureService } from '../services/insights-feature.service';

@Component({
  selector: 'dgx-pathway-insights',
  templateUrl: './pathway-insights.component.html',
  styleUrls: ['./pathway-insights.component.scss'],
})
export class PathwayInsightsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public pathway: Pathway | api.Pathway;
  public authUser: AuthUser;
  public defaultOrgInfo: OrgInfo;
  public orgId: number;
  public selectedOrg: any;
  public isChannel: boolean;

  public showFilterBar = false;
  public groupOptions: any[];
  public selectedGroupId?: number;
  public tenantOptions: any[];
  public viewAllContentUrl: string; // TODO: remove after LaunchDarkly.PATHWAY_ROUTER is gone

  public canViewInsightsTab: boolean = true;

  public i18n = this.translate.instant([
    'Core_ViewAll',
    'OrgReportingCtrl_AllGroups',
    'OrgReportingCtrl_FilterByGroup',
    'OrgReportingCtrl_FilterByOrg',
    'OrgReportingCtrl_NoMatchingGroups',
    'OrgReportingCtrl_NoMatchingOrgs',
  ]);

  constructor(
    private authService: AuthService,
    private contextService: ContextService,
    private facade: PathwayFacade,
    private insightsFeatureService: InsightsFeatureService,
    private pathwayInsightsService: PathwayInsightsService,
    private reportingService: ReportingService,
    private trackingService: TrackerService,
    private translate: TranslateService
  ) {
    super();
  }

  public get displayHeader(): boolean {
    let value: boolean = false;
    if (
      this.tenantOptions ||
      (this.insightsFeatureService.hasMinimumUserCount && this.groupOptions)
    ) {
      value = true;
    }
    return value;
  }

  public ngOnInit() {
    this.trackingService.setLocation('Pathway Insights');

    this.pathway = this.facade.snapshot.pathway;

    this.getUserData();
    this.trackPageView();
  }

  public updateGroupId(event: any) {
    this.selectedGroupId = event.groupId;
    this.trackEvent(event.groupId);
  }

  public updateOrg(org: any) {
    this.selectedOrg = org;
  }

  private getUserData() {
    this.authService.authUser$
      .pipe(this.takeUntilDestroyed())
      .subscribe((authUser) => {
        this.authUser = authUser;
        this.orgId = this.authUser.defaultOrgInfo.organizationId;
        this.isChannel = this.contextService.urlHasContext(
          window.location.href,
          'channel'
        );
        // TODO: this check will go away when groups are implemented on channel
        if (!this.isChannel) {
          this.initGroups();
        } else if (this.isChannel) {
          this.initTenants();
        }
      });
  }

  private trackPageView() {
    this.trackingService.trackEventData({
      action: 'Pathway Insights Viewed',
      properties: {
        internalUrl: this.pathway.internalUrl,
        id: this.pathway.id,
        title: this.pathway.title,
      },
    });
  }

  private initTenants() {
    const organizationId = this.authUser.orgInfo[0].organizationId;
    const resourceId = this.pathway.id;
    return this.pathwayInsightsService
      .GetTenants({
        organizationId,
        resourceId,
      })
      .pipe(take(1))
      .subscribe((tenants: any[]) => {
        this.tenantOptions = [{ name: this.i18n.Core_ViewAll }, ...tenants];
      });
  }

  private initGroups() {
    this.reportingService
      .getGroups(this.orgId)
      .pipe(take(1))
      .subscribe((response) => {
        this.groupOptions = [
          {
            groupId: undefined,
            name: this.i18n.OrgReportingCtrl_AllGroups,
          },
          ...response.sort((a, b) => orderBy(a, b, 'name')),
        ];
      });
  }

  private trackEvent(groupId) {
    this.trackingService.trackEventData({
      action: 'Pathway Insights Filtered',
      properties: {
        filterType: 'Group',
        filterSelection: groupId,
      },
    });
  }
}
