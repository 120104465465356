import { Directive, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { DfTemplateOptions } from '../field-types';

interface DfFieldType {
  templateOptions?: DfTemplateOptions;
}

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Base class for form inputs that require dgat identifiers
 * */
@Directive({ selector: 'df-dgat-field' })
// eslint-disable-next-line directive-class-suffix
export class DfDgatFieldTypeDirective
  extends FieldType
  implements OnInit, DfFieldType
{
  public templateOptions?: DfTemplateOptions;
  public dgat: string;

  ngOnInit() {
    this.dgat = `${this.formState.dgatPrefix ?? ''}${
      this.to.dgatId ?? this.id
    }`;
  }
}
