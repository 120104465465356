<ng-container *ngIf="!isOnboarding">
  <div *ngIf="!isNgxPasswordReset">
    <!-- 
    Linter Errors
      
      [orgId]="orgId"
      Property 'orgId' does not exist on type 'AppHeader'.
      
      [shouldHideGlobalSearch]="shouldHideGlobalSearch"
      Property 'shouldHideGlobalSearch' does not exist on type 'AppHeader'.
      
    -->
    <dgx-product-header
      [navLinks]="navLinks"
      [context]="context"
    ></dgx-product-header>
  </div>

  <!-- 
    Linter Error
      && !hideBannerHeader
      Property 'hideBannerHeader' does not exist on type 'AppHeader'.
  -->

  <dgx-banner
    *ngIf="!isMobile && !authUser && !isNgxPasswordReset"
    position="top"
    cookie-name="loggedout"
    [cookieExpirationDays]="0.02"
  >
    <div>
      {{ 'Banner_LoggedOutCTA' | translate }}
      <a href="/for-me" class="guts-m-l-1" data-dgat="app-header-719">
        {{ 'Banner_LoggedOutCTAButton' | translate }}
        <df-icon icon="chevron-right"></df-icon>
      </a>
    </div>
  </dgx-banner>
</ng-container>
