import { InputType } from '@app/shared/models/core-api.model';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { mergeMap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { InputContext, RenderMode } from '../user-input.model';
import {
  Event,
  EventInvolvementLevel,
  UserEvent,
  InputSession,
} from '@app/inputs/inputs-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import {
  InputsFacadeBase,
  InputSubmissionResult,
} from '@app/user-content/services/inputs-facade-base';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { TranslateService } from '@ngx-translate/core';
import { EventApiEntity } from './repository/user-event.entity.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { RepositoryFactoryService } from '@app/user-content/user-input/services/repository-factory.service';
import { MapperFactoryService } from '@app/user-content/user-input/services/mapper-factory.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { RendererContext } from '../form-renderer.model';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputFormModel } from '../input-form.model';
import { EventFormRenderManager } from './render-manager/event-form-render.manager';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { TipService } from '@app/onboarding/services/tip.service';

export interface EventFormModel
  extends InputFormModel,
    Partial<Event>,
    Partial<UserEvent> {
  comment?: string;
  extent?: {
    length: number;
    involvementLevel: EventInvolvementLevel;
    dateAttended: Date;
  };
  addToCatalog?: boolean;
  title: string;
  sessionDetails?: InputSession;
  onAddToCatalogChange?: (shouldAdd: boolean) => void;
}

@Injectable({ providedIn: 'any' })
export class EventModalFacade extends InputsFacadeBase<
  EventFormModel,
  EventApiEntity
> {
  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: EventApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    fieldBuilder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    dgxCommentsApiSvc: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    private mediator: EventFormRenderManager,
    tipService: TipService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      fieldBuilder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      dgxCommentsApiSvc,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }

  /** Override */
  public onSubmit(): Observable<InputSubmissionResult> {
    // editing we need to check for duplicates before submitting
    if (
      this.inputContext.renderMode === RenderMode.ContentCatalog &&
      !!this.viewModel.eventUrl
    ) {
      return this.checkAndUpdateUrlDuplicates().pipe(
        mergeMap(() => {
          return super.onSubmit();
        })
      );
    }

    return super.onSubmit(); // Close stream and don't actually submit if parsing (i.e. "Next" button pressed)
  }
  protected get extendedDefaultViewModel(): Partial<EventFormModel> {
    return {
      ...this.viewModel,
      length: 8,
      organizationName: this.authService.authUser.defaultOrgInfo?.name,
      formTitle: this.translate.instant(
        this.inputContext.isEditing
          ? this.inputContext.renderMode === RenderMode.Pathways
            ? 'Core_EditItem' // pathways internal content edit shows a special title
            : `EventFormCtrl_Edit${this.inputContext.inputType}`
          : `EventFormCtrl_Add${this.inputContext.inputType}`
      ),
      submitButtonText: this.translate.instant('EventFormCtrl_SaveEvent'),
      onAddToCatalogChange: this.onAddToCatalogChange.bind(this),
    };
  }

  protected buildUIConfiguration(): DfFormFieldConfig<DfTemplateOptions>[] {
    const context: RendererContext = {
      inputContext: {
        ...this.inputContext,
        inputType: 'Event',
        isCompleting: this.isCompleting,
      },
      state: () => this.viewModel,
      templates: this.templates,
      expandAdvanced$: this.viewModel
        .expandAdvanced$ as BehaviorSubject<boolean>,
    };
    return this.mediator.render(context);
  }

  private onAddToCatalogChange(shouldAdd: boolean) {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.fetchDuplicates(
      shouldAdd && !!this.viewModel.eventUrl,
      this.viewModel.organizationId,
      this.viewModel.eventUrl,
      inputIdentifier
    ).subscribe();
  }

  private checkAndUpdateUrlDuplicates() {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.viewModel.eventUrl = this.inputsService.cleanUrl(
      this.viewModel.eventUrl
    );

    return this.fetchDuplicates(
      true,
      this.viewModel.organizationId,
      this.viewModel.eventUrl,
      inputIdentifier
    );
  }
}
