import { Component } from '@angular/core';

import { DfDgatFieldTypeDirective } from '../dgat-field-type/dgat-field-type.directive';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Presents a custom template as a form field within a form field wrapper.
 * @description This is useful for creating form fields that contain 3rd party or
 * other non-fresco components. Consider applying {@link DfControlValueDirective},
 * implementing the {@link ControlValueAccessor}
 * interface for those components or inhertiting from {@link DfControlValueDirective}
 */
@Component({
  selector: 'df-form-custom-field',
  template: `
    <ng-container
      *ngTemplateOutlet="
        to.fieldTemplate;
        context: {
          ariaDescribedby: to.ariaDescribedbyIds,
          id: id,
          formControl: formControl,
          key: key,
          label: to.label,
          params: to.params
        }
      "
    >
    </ng-container>
  `,
})
export class DfFormCustomFieldComponent extends DfDgatFieldTypeDirective {}
