import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Inject,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
@Component({
  selector: 'dgx-first-pathway',
  templateUrl: './first-pathway.component.html',
  styleUrls: ['./first-pathway.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstPathwayComponent implements OnInit {
  @Input() public pathwaysUrl: string;
  constructor(
    private activeModal: NgbActiveModal,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  ngOnInit(): void {}

  public closeModal(): void {
    this.activeModal.dismiss();
  }

  public openPathWayPage(): void {
    this.activeModal.dismiss();
    window.location.href = this.pathwaysUrl;
  }

  public get degreedFirstPathwayIntroLogo(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/onboarding/firstadd.png',
      true
    );
  }
}
