<div
  class="l_flex l_flex-middle l_flex__ellipsis-wrapper rating-provider-label"
>
  <div class="l_flex l_flex-shrink">
    <dgx-tag-provider-logo
      [src]="imgSrc"
      [providerName]="rating.providerName"
      class="guts-m-r-half"
      size="xs"
    ></dgx-tag-provider-logo>
  </div>

  <div
    *ngIf="rating.type === 'Checkpoint' && checkpointCount === 1; else default"
    class="par par--small l_flex-grow no-wrap ellipsis font-medium"
  >
    {{ rating.checkpointName }}
  </div>
  <ng-template #default>
    <div class="par par--small l_flex-grow no-wrap ellipsis font-medium">
      <span [textContent]="ratingType"></span>
      <ng-container *dgxFeatureToggle="'sc-update-privacy'; hidden: false">
        <span
          *ngIf="showPrivacyIcon(rating)"
          [ngbTooltip]="getPrivacyTooltip()"
          class="guts-m-l-half"
        >
          <df-icon
            class="color-ebony-a61"
            icon="eye-closed"
            size="small"
          ></df-icon
        ></span>
      </ng-container>
    </div>
  </ng-template>
</div>
