<ng-template #dPicker>
  <ngb-datepicker></ngb-datepicker>
</ng-template>

<ng-template
  #skillsViewTemplate
  let-formControl="formControl"
  let-params="params"
  dfSkill
>
  <dgx-tags-edit
    #tagsEditor
    class="form-wrap guts-m-t-1-quart"
    [tags]="formControl.value"
    (tagsChange)="onTagsChanged($event, formControl)"
  ></dgx-tags-edit>
</ng-template>

<ng-template
  #courseLevelTemplate
  let-label="label"
  let-formControl="formControl"
>
  <dgx-select
    [options]="model.allCourseLevels"
    [selectedItem]="model.extent.courseLevel"
    class="full-width"
    [ariaLabel]="label"
    dgatInput="event-modal-ec0"
    (selection)="onCourseLevelSelected($event.id, formControl)"
  ></dgx-select>
</ng-template>

<ng-template
  #courseGradeTemplate
  let-label="label"
  let-formControl="formControl"
>
  <dgx-select
    [options]="model.allGrades"
    [selectedItem]="model.extent.courseGrade"
    class="full-width"
    [ariaLabel]="label"
    dgatInput="event-modal-ec0"
    (selection)="formControl.setValue($event.id, formControl)"
  ></dgx-select>
</ng-template>

<!-- template for institution search -->
<ng-template #institutionSearchTemplate let-formControl="formControl">
  <input
    dgxAutofocus
    type="text"
    class="df-text-input df-text-input-border"
    autocomplete="off"
    [ngbTypeahead]="onInstitutionSearch"
    [inputFormatter]="labelFormatter"
    [resultTemplate]="institutionResultTemplate"
    (selectItem)="onInstitutionSelection($event, formControl)"
    data-dgat="course-formal-form-a54"
  />
</ng-template>

<ng-template #institutionResultTemplate let-item="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="item.label"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
  <dgx-validate-field
    *ngIf="model.institutionMessage"
    [message]="model.institutionMessage"
  ></dgx-validate-field>
</ng-template>

<!-- template for country -->
<ng-template #countrySearchTemplate let-formControl="formControl">
  <input
    dgxAutofocus
    id="courseCountry"
    type="text"
    class="course-country"
    name="country"
    autocomplete="off"
    [value]="model.country"
    [ngbTypeahead]="onCountrySearch"
    (selectItem)="onCountrySelection($event, formControl)"
    [resultTemplate]="countrySearchResultTemplate"
    data-dgat="course-formal-form-26d"
  />
</ng-template>

<ng-template #countrySearchResultTemplate let-item="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="item"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
</ng-template>

<!-- template for course title -->
<ng-template #courseTitleSearchTemplate let-formControl="formControl">
  <input
    id="courseTitle"
    type="text"
    class="course-title"
    name="courseName"
    autocomplete="off"
    [ngbTypeahead]="onCourseTitleSearch"
    [inputFormatter]="labelFormatter"
    [resultTemplate]="courseTitleResultTemplate"
    (selectItem)="onSelectCourseFromList($event, formControl)"
    data-dgat="course-formal-form-294"
  />
</ng-template>
<ng-template #courseTitleResultTemplate let-item="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="item.value"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
</ng-template>

<dgx-add-to-pathway
  *ngIf="model.formUIState.shouldIncludeAddToPathWay"
  (submit)="onEbbSubmit($event)"
  [activeModal]="modalInstance"
  [addToPathwayOptions]="addToPathwayOptions"
  [ebbAddToPathwayConfig]="ebbConfig"
></dgx-add-to-pathway>
