import { ElementRef, Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { Observable } from 'rxjs';
import { ColorService } from '@app/shared/services/color.service';
import {
  CropperPosition,
  ImageCroppedData,
  LoadedImage,
} from '../cropper.model';
/**
 * This file is adapted from https://github.com/Mawi137/ngx-image-cropper with our own
 * modifications to support a secondary aspect ratio box while cropping
 */
@Injectable({ providedIn: 'root' })
export class CropperService {
  constructor(private colorService: ColorService) {}
  public crop(
    sourceImage: ElementRef,
    loadedImage: LoadedImage,
    cropper: CropperPosition
  ): ImageCroppedData | null {
    const imagePosition = this.getImagePosition(
      sourceImage,
      loadedImage,
      cropper
    );
    const width = imagePosition.x2 - imagePosition.x1;
    const height = imagePosition.y2 - imagePosition.y1;
    const cropCanvas = document.createElement('canvas') as HTMLCanvasElement;
    cropCanvas.width = width;
    cropCanvas.height = height;

    const ctx = cropCanvas.getContext('2d');
    if (!ctx) {
      return;
    }

    // fill in the background for transparent pictures
    ctx.fillStyle = this.colorService.getColor('white');
    ctx.fillRect(0, 0, width, height);

    const transformedImage = loadedImage;
    ctx.setTransform(
      1,
      0,
      0,
      1,
      transformedImage.size.width / 2,
      transformedImage.size.height / 2
    );
    ctx.translate(-imagePosition.x1 / 1, -imagePosition.y1 / 1);
    ctx.drawImage(
      transformedImage.image,
      -transformedImage.size.width / 2,
      -transformedImage.size.height / 2
    );

    const output: ImageCroppedData = {
      width,
      height,
      imagePosition,
      cropperPosition: { ...cropper },
    };
    output.base64 = cropCanvas.toDataURL('image/jpeg');
    return output;
  }
  public loadBase64Image(imageBase64: string): Observable<LoadedImage> {
    return new Observable((observer) => {
      const img = new Image();
      img.onload = () => {
        if (!img.complete) {
          observer.error(new DgError('No image loaded'));
        }
        observer.next({
          base64: imageBase64,
          image: img,
          size: {
            width: img.naturalWidth,
            height: img.naturalHeight,
          },
        });
        observer.complete();
      };
      img.src = imageBase64;
    });
  }

  public loadImageFromURL(url: string): Observable<LoadedImage> {
    return new Observable((observer) => {
      const img = new Image();
      img.onerror = (error) => observer.error(error);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        this.loadBase64Image(canvas.toDataURL()).subscribe((loadedImage) => {
          observer.next(loadedImage);
        });
      };
      img.crossOrigin = 'anonymous';
      img.src = url;
    });
  }

  private getImagePosition(
    sourceImage: ElementRef,
    loadedImage: LoadedImage,
    cropper: CropperPosition
  ): CropperPosition {
    const sourceImageElement = sourceImage.nativeElement;
    const ratio = loadedImage.size.width / sourceImageElement.offsetWidth;

    const out: CropperPosition = {
      x1: Math.round(cropper.x1 * ratio),
      y1: Math.round(cropper.y1 * ratio),
      x2: Math.round(cropper.x2 * ratio),
      y2: Math.round(cropper.y2 * ratio),
    };

    out.x1 = Math.max(out.x1, 0);
    out.y1 = Math.max(out.y1, 0);
    out.x2 = Math.min(out.x2, loadedImage.size.width);
    out.y2 = Math.min(out.y2, loadedImage.size.height);

    return out;
  }
}
