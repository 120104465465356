import { TagsSearchItem } from '@app/tags/tags';
import { TagsApi } from '@app/tags/tag-api.model';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-search-list',
  templateUrl: './tag-search-list.component.html',
})
export class TagSearchListComponent {
  @Output() public onTagsModified: EventEmitter<any> = new EventEmitter();

  public skillSuggestionSource = null;
  public i18n = this.translate.instant([
    'Core_Recommend',
    'Core_Remove',
    'OrgSkills_SearchPlaceholder',
    'OrgSkills_Selected',
  ]);

  // Not using user's topTags since this is currently for managers recommending
  // to their direct reports, which are unlikely to share common skill needs
  public topTags: (Partial<TagsApi.Tag> | TagsSearchItem)[] = [];
  public tags: (Partial<TagsApi.Tag> | TagsSearchItem)[] = [];

  constructor(private translate: TranslateService) {}

  public get formValid() {
    return this.tags.length > 0;
  }

  /**
   * Add a tag to the list, if valid
   */
  public addTag(tag: Partial<TagsApi.Tag> | TagsSearchItem): void {
    if (this.validTag(tag)) {
      this.tags.push(tag);
      this.modifiedTags();
    }
  }

  /**
   * Remove a tag from the list
   */
  public removeTag(tag: Partial<TagsApi.Tag> | TagsSearchItem): void {
    const index = this.tags.findIndex((t) => t.title === tag.title);
    this.tags.splice(index, 1);
    this.modifiedTags();
  }

  /**
   * Emit when the list has changed
   */
  public modifiedTags(): void {
    this.onTagsModified.emit({
      tags: this.tags,
      formValid: this.formValid,
    });
  }

  /**
   * Determine if a tag is valid (i.e. can be added)
   */
  private validTag(tag: Partial<TagsApi.Tag> | TagsSearchItem): boolean {
    const alreadyAdded = this.tags.some(
      (t) => t.title.toLowerCase() === tag.title.toLowerCase()
    );
    const hasText = tag.title?.length;
    return hasText && !alreadyAdded;
  }
}
