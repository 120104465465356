<dgx-breadcrumbs [breadcrumbs]="breadcrumbs"></dgx-breadcrumbs>
<div>
  <header class="grid center-item">
    <div class="grid__col-sm-12 center-item guts-p-t-4 guts-p-b-2">
      <div class="l_flexbar m-l_flexbar-col">
        <div
          class="m-center-text l_flex-grow full-width guts-p-r-4 m-guts-p-r-0"
        >
          <h1
            class="h1 inline"
            [innerHtml]="i18n.OrgReportingCtrl_LearningSummary"
          ></h1>
          <h2 *ngIf="hasResults" class="h2 inline color-ebony-a45 m-block">
            (<span [innerHtml]="resultsText"></span>)
          </h2>
        </div>
      </div>
    </div>
  </header>
  <div
    class="l_flexbar m-l_flexbar-block guts-m-b-1 guts-p-full-1 bg-color-ebony-a03"
  >
    <div class="l_flex-grow m-guts-p-b-1 guts-m-r-1 m-guts-m-r-0">
      <dgx-reporting-range-picker
        [appearance]="'dropdown'"
        [selection]="setSelectedPeriod"
        [value]="dateRange"
        [location]="'Org Insights'"
      ></dgx-reporting-range-picker>
    </div>
    <div class="guts-m-r-1 m-guts-m-r-0 m-guts-p-b-1">
      <dgx-select
        class="m-full-width"
        (selection)="updateSelectedData($event)"
        [selectedItem]="selectedDataType.type"
        [options]="dataTypeOptions"
        labelKey="name"
        trackBy="type"
        [ariaLabel]="i18n.dgVBarChart_DisplayTypePlaceholder"
        [placeholder]="i18n.dgVBarChart_DisplayTypePlaceholder"
        dgatInput="org-insights-learning-summary-details-720"
      >
      </dgx-select>
    </div>
    <div class="guts-m-r-1 m-guts-m-r-0 m-guts-p-b-1">
      <dgx-select
        class="m-full-width"
        (selection)="updateSelectedInput($event)"
        [selectedItem]="selectedInputType.name"
        [options]="allInputDisplayTypes"
        labelKey="name"
        trackBy="name"
        [ariaLabel]="i18n.dgVBarChart_InputTypePlaceholder"
        [placeholder]="i18n.dgVBarChart_InputTypePlaceholder"
        dgatInput="org-insights-learning-summary-details-91a"
      >
      </dgx-select>
    </div>
    <div>
      <dgx-combobox
        class="m-full-width"
        [selectedOptions]="selectedGroups"
        [attr.disabled]="!isLoadingGroups"
        (selections)="updateGroupIds($event)"
        [isExactMatchesEnabled]="true"
        [isMultiSelect]="true"
        [isFixedWidth]="true"
        [options]="groupOptions"
        labelKey="name"
        trackBy="groupId"
        [ariaLabel]="i18n.OrgReportingCtrl_FilterByGroup"
        [placeholder]="i18n.OrgReportingCtrl_FilterByGroup"
        [hasNoResultsText]="i18n.OrgReportingCtrl_NoMatchingGroups"
        dgatInput="learningSummary-db7"
      >
        <dgx-user-count-warning-message
          [warningMessage]="warningMessage"
          message
        ></dgx-user-count-warning-message>
      </dgx-combobox>
    </div>
  </div>
  <div class="grid guts-p-t-2 m-guts-p-t-0">
    <div class="grid__col-12">
      <div *ngIf="hasResults" class="rel data-table--sortable-wrapper">
        <table class="data-table data-table--sortable">
          <thead>
            <tr>
              <th class="l_w75" scope="col">{{ 'Core_Title' | translate }}</th>
              <th class="l_w15 right-text" scope="col">
                {{
                  (summaryType === 'views' ? 'Core_Views' : 'Core_Completed')
                    | translate
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let result of learningSummaryResults"
              data-dgat="learningSummary-b42"
            >
              <td>
                <a
                  href="{{ result.inputDetails.internalUrl }}"
                  target="_blank"
                  ng-class="{
                    'cursor-default': !result.inputDetails.internalUrl
                  }"
                  data-dgat="learningSummary-a97"
                >
                  {{ result.inputDetails.title }}
                  <df-icon
                    icon="office"
                    size="small"
                    *ngIf="result.inputDetails.organizationId > 0"
                    [ngbTooltip]="result.inputDetails.organizationName"
                    [attr.aria-label]="
                      i18n.reportingContentService_InternalContent
                    "
                    class="guts-m-l-half"
                  ></df-icon>
                </a>
                <span
                  *ngIf="!inputType"
                  class="color-ebony-a45 guts-m-l-half"
                  >{{ result.inputDetails.inputType | displayType }}</span
                >
              </td>
              <td class="right-text">
                {{ result.count }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        *ngIf="hasResults === false"
        translate="OrgReportingCtrl_NoResults"
      ></p>
    </div>
  </div>
</div>
