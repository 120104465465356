import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { Subject } from 'rxjs';
import { ModalService } from './modal.service';
import { User } from '@app/user/user-api.model';
import { MODAL_FOCUS_ERROR } from '../utils/modal-helpers';
import { ProfileService } from '@app/profile/services/profile.service';
import { ToastService } from '@degreed/apollo-angular';

interface FollowUserCheck {
  showWarning: boolean;
}

@Injectable({ providedIn: 'root' })
export class FollowingService {
  public followerCount = 0;
  public followeeCount = 0;
  public followUserCheck: FollowUserCheck;
  public viewersFolloweeIds: string[] = [];
  public ownerUser = this.authService.authUser?.viewerProfile;
  public followers: User[] = [];
  public followees: User[] = [];
  public i18n = this.translateService.instant([
    'FollowingSvc_FollowUserNotification',
    'FollowingSvc_Continue',
    'FollowingSvc_FollowError',
    'FollowingSvc_UnfollowError',
    'FollowingSvc_InteralFollowError',
    'FollowingSvc_InternalRetrievalError',
    'FollowingSvc_FollowerInfoError',
    'FollowingSvc_FollowerCountsError',
  ]);

  private _followingChange = new Subject<void>();

  constructor(
    private http: NgxHttpClient,
    private authService: AuthService,
    private translateService: TranslateService,
    private notifierService: NotifierService,
    private trackerService: TrackerService,
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private modalService: ModalService,
    private toastService: ToastService
  ) {}

  public get followingChange() {
    return this._followingChange.asObservable();
  }
  public get ownerIsViewing() {
    return (
      this.ownerUser.userProfileKey ===
      this.authService?.authUser?.viewerProfile?.userProfileKey
    );
  }

  public followUser(
    userGuid: string,
    userName: string,
    isEngaged: boolean,
    showSuccess = true
  ): Observable<void> {
    const openModal = () => {
      return this.modalService.showAlert(
        {
          title: '',
          description: this.i18n.FollowingSvc_FollowUserNotification,
          confirmButtonText: this.i18n.FollowingSvc_Continue,
        },
        { errorOnDismiss: true }
      );
    };

    const onSuccess = () => {
      this.viewersFolloweeIds.push(userGuid);
      if (this.ownerIsViewing) {
        this.loadFollowCounts(this.ownerUser.userProfileKey);
      }
      if (showSuccess) {
        this.toastService.showToast(
          this.translateService.instant(
            'FollowingSvc_FollowNotificationFormat',
            {
              person: this.encodeToEntitiesPipe.transform(userName),
            }
          ),
          {
            type: 'success',
          }
        );
      }

      this.trackerService.trackEventData({
        action: 'User Followed',
        category: this.ownerUser?.userProfileKey.toString(),
        properties: {
          Followee: userGuid,
          IsActiveLearner: isEngaged,
        },
      });
    };

    const onError = (error: HttpErrorResponse & string) => {
      if (error !== MODAL_FOCUS_ERROR) {
        this.notifierService.showError(this.i18n.FollowingSvc_FollowError);
      }
    };

    const followUser = this.http
      .post<void>(`/user/followuser/${userGuid}`, {})
      .pipe(tap(onSuccess, onError));

    return this.getFollowUserCheck(userGuid).pipe(
      switchMap((response: FollowUserCheck) => {
        this.followUserCheck = response;
        return response.showWarning
          ? openModal().pipe(switchMap(() => followUser))
          : followUser;
      })
    );
  }

  public getFollowUserCheck(userGuid: string): Observable<FollowUserCheck> {
    if (!this.followUserCheck) {
      return this.http.get('/user/followusercheck', {
        params: { userId: userGuid },
      });
    }
    return of(this.followUserCheck);
  }

  public unfollowUser(
    userGuid: string,
    userName: string,
    isEngaged: boolean,
    showSuccess = true
  ): Observable<void> {
    const onSuccess = () => {
      this.viewersFolloweeIds = this.viewersFolloweeIds.filter(
        (id) => id !== userGuid
      );
      if (this.ownerIsViewing) {
        this.loadFollowCounts(this.ownerUser.userProfileKey);
      }
      if (showSuccess) {
        this.toastService.showToast(
          this.translateService.instant(
            'FollowingSvc_UnfollowNotificationFormat',
            {
              person: this.encodeToEntitiesPipe.transform(userName),
            }
          ),
          {
            type: 'success',
          }
        );
      }

      this.trackerService.trackEventData({
        action: 'User Unfollowed',
        category: userGuid,
        properties: {
          Followee: userGuid,
          IsActiveLearner: isEngaged,
        },
      });
    };

    const onError = (error: HttpErrorResponse) => {
      this.notifierService.showError(this.i18n.FollowingSvc_UnfollowError);
    };

    return this.http
      .post<void>(`/user/unfollowuser/${userGuid}`, {})
      .pipe(tap(onSuccess, onError));
  }

  public getFollowers(userKey: number): Observable<any> {
    return this.http
      .get('/user/getfollowers', {
        params: { userKey: userKey },
      })
      .pipe(
        tap((data: any) => {
          this.followers = data.items;
        }),
        catchError((error) => {
          if (error.status !== 0) {
            this.notifierService.showError(
              this.i18n.FollowingSvc_InteralFollowError
            );
          }
          return of(null);
        })
      );
  }

  public getFollowees(userKey: number): Observable<any> {
    return this.http
      .get('/user/getfollowees', {
        params: { userKey: userKey },
      })
      .pipe(
        tap((data: any) => {
          this.followees = data.items;
        }),
        catchError((error) => {
          if (error.status !== 0) {
            this.notifierService.showError(
              this.i18n.FollowingSvc_InternalRetrievalError
            );
          }
          return of(null);
        })
      );
  }

  public getViewersFollowees(doCache: boolean = false): Observable<string[]> {
    return this.http
      .get('/user/getfolloweeids', {
        cache: doCache,
        headers: {
          // Ensure that this call is ignored by the auth.intercept service
          'Dg-Skip-Intercept': 'true',
        },
      })
      .pipe(
        tap((data: any) => {
          this.viewersFolloweeIds = data;
          this._followingChange.next();
        }),
        catchError((error) => {
          const status = error.status;
          if (status !== 0) {
            return throwError(this.i18n.FollowingSvc_FollowerInfoError);
          }
        })
      );
  }

  public isViewersFollowee(userId: string): boolean {
    return this.viewersFolloweeIds.indexOf(userId) !== -1;
  }

  public loadFollowCounts(userKey: number) {
    return this.loadFollowCountsAsync(userKey).subscribe(
      (data: any) => {
        this.followeeCount = data.followeesCount;
        this.followerCount = data.followersCount;
        this._followingChange.next();
      },
      (response) => {
        const status = response.status;
        if (status !== 0) {
          this.notifierService.showError(
            this.i18n.FollowingSvc_FollowerCountsError
          );
        }
      }
    );
  }

  public loadFollowCountsAsync(userKey: number): Observable<any> {
    return this.http.get('/userprofile/getfollowcounts', {
      params: { userKey: userKey },
    });
  }

  public getFollowerCount = (): number => {
    return this.followerCount;
  };

  public getFolloweeCount = (): number => {
    return this.followeeCount;
  };

  public getViewersFolloweeIds(): string[] {
    return this.viewersFolloweeIds;
  }
}
