import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { of } from 'rxjs';

import {
  SelectFieldParams,
  SelectFieldComponent,
} from '@app/form-fields/wrappers/select-field/select-field.component';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { RendererContext, FormRenderer } from '../../form-renderer.model';
import { RenderMode } from '../../user-input.model';
import { isUrlValid } from '@app/shared/utils/common-utils';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';

@Injectable({ providedIn: 'root' })
export class UserProfileEventRenderer implements FormRenderer {
  private readonly i18n = this.translate.instant([
    'EventFormCtrl_OneHour',
    'EventFormCtrl_TwoHours',
    'EventFormCtrl_ThreeToEightHours',
    'EventFormCtrl_OneDay',
    'EventFormCtrl_TwoDays',
    'EventFormCtrl_ThreeToFiveDays',
    'EventFormCtrl_WeekOrMore',
    'EventFormCtrl_Attendee',
    'EventFormCtrl_Panelist',
    'EventFormCtrl_Presenter',
    'EventFormCtrl_Keynote',
    'Core_SelectDate',
  ]);
  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService
  ) {}
  render(
    context: RendererContext<any>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const urlValidation = {
      urlValidation: {
        expression: (control: AbstractControl) => {
          return control.value?.length > 0 ? isUrlValid(control.value) : true;
        },
        message: this.translate.instant('EventFormCtrl_ValidUrl'),
      },
    };
    return [
      this.builder
        .title()
        .asRequired()
        .withPlaceholder('EventFormCtrl_EventTitle')
        .autofocused()
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translate.instant('Core_FieldRequiredFormat', {
              fieldName: this.translate.instant('Core_Title'),
            }),
          },
        })
        .build(),
      this.builder
        .optionalTextInput('eventUrl', 'EventFormCtrl_EventSite')
        .ofType('url')
        .withPlaceholder('EventFormCtrl_EventUrl')
        .validatedByIndexed(urlValidation)
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateAttended',
                'EventFormCtrl_DateAttended',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectDate,
                }
              )
              .styledBy('df-form__col-thirds')
              .build(),
            this.builder
              .foreignField<SelectFieldParams>(
                'length',
                'EventFormCtrl_EventLength',
                SelectFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  optionLabelKey: 'title',
                  optionTrackByKey: 'id',
                  options$: of([
                    { id: 1, title: this.i18n.EventFormCtrl_OneHour },
                    { id: 2, title: this.i18n.EventFormCtrl_TwoHours },
                    {
                      id: 3,
                      title: this.i18n.EventFormCtrl_ThreeToEightHours,
                    },
                    { id: 8, title: this.i18n.EventFormCtrl_OneDay },
                    { id: 16, title: this.i18n.EventFormCtrl_TwoDays },
                    {
                      id: 24,
                      title: this.i18n.EventFormCtrl_ThreeToFiveDays,
                    },
                    { id: 40, title: this.i18n.EventFormCtrl_WeekOrMore },
                  ]),
                }
              )
              .styledBy('df-form__col-thirds')
              .build(),
            this.builder
              .foreignField<SelectFieldParams>(
                'involvementLevel',
                'EventFormCtrl_HowInvolved',
                SelectFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  optionLabelKey: 'title',
                  optionTrackByKey: 'id',
                  options$: of([
                    { id: 'Attendee', title: this.i18n.EventFormCtrl_Attendee },
                    { id: 'Panelist', title: this.i18n.EventFormCtrl_Panelist },
                    {
                      id: 'Presenter',
                      title: this.i18n.EventFormCtrl_Presenter,
                    },
                    { id: 'Keynote', title: this.i18n.EventFormCtrl_Keynote },
                  ]),
                }
              )
              .styledBy('df-form__col-thirds')
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(
          () => context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),
      this.builder
        .optionalTextarea('comment', 'EventFormCtrl_WhatDidYouLearnLong')
        .withPlaceholder('EventFormCtrl_WhatDidYouLearnShort')
        .hiddenWhen(
          () => context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),

      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
    ];
  }
}
