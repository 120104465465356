<dgx-modal
  bodyClasses="guts-p-t-0 "
  [isHeaderBorderless]="false"
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
  [isFooterless]="true"
  trapFocus
>
  <ng-container class="modal-header">
    {{ modalTitle }}
  </ng-container>

  <ng-container class="modal-body">
    <form
      role="form"
      [formGroup]="form"
      class="form-wrap guts-p-full-2"
      (ngSubmit)="onSubmit()"
    >
      <div [class.input-success-kickstart]="shouldShowResults">
        <div class="input-success-wrap">
          <df-form-root
            [form]="form"
            [model]="model"
            [fields]="fields"
            [options]="options"
          >
          </df-form-root>
        </div>
        <!--Submit button and submission results-->
        <dgx-global-add-results
          [buttonText]="i18n.PositionFormCtrl_SaveExperience"
          [isEditing]="isEditing"
          [isCompleting]="isCompleting"
          [isNewbUser]="isNewbUser"
          [submissionStatus]="submissionStatus"
          [creationFeedback]="creationFeedback"
          [classifier]="'experiences'"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </div>
    </form>
  </ng-container>
</dgx-modal>

<!--Basic read only text field for title during editing existing input-->
<ng-template #readonlyField let-label="label" let-formControl="formControl">
  <p>{{ formControl.value }}</p>
</ng-template>

<ng-template
  #skillsView
  let-formControl="formControl"
  let-params="params"
  dfSkill
>
  <dgx-tags-edit
    #tagsEditor
    class="form-wrap guts-m-t-1-quart"
    [tags]="formControl.value | pascalCase"
    (tagsChange)="onTagsEditorChange($event, formControl)"
  ></dgx-tags-edit>
</ng-template>

<ng-template #seniority let-label="label" let-formControl="formControl">
  <dgx-select
    [options]="seniorityOptions"
    [selectedItem]="model.level"
    (selection)="formControl.setValue($event.id)"
    dgatInput="experience-modal-14e"
    class="full-width"
    [ariaLabel]="label"
  ></dgx-select>
</ng-template>
