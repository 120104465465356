import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { LDFlagsService } from '../services/ld-flags.service';

/**
 * Feature Toggle Directive
 * Shows/hides contained HTML based on settings/permissions.
 * Specified flag(s) can either be a property on authUser or a Launch Darkly flag:
 *
 * result = authUser?.[flag] ?? LD.variation(flag)
 *
 * You can pass in a single flag as a string, or multiple flags in an array.
 * Result will be true if ANY of the the flags are found.
 *
 * Uses ng-container to not affect HTML styling or formatting
 * @example
 * // will show <some HTML /> only if flag exists
 * <ng-container *dgxFeatureToggle="'flagName'"><some HTML /></ng-container>
 *
 * // will show <some HTML /> if ANY flag('a', 'b', or 'c') exists.
 * <ng-container *dgxFeatureToggle="['a','b','c']"><some HTML /></ng-container>
 *
 * // will always show <some HTML /> unless the flag exists
 * <ng-container *dgxFeatureToggle="'flagName'; hidden: true"><some HTML /></ng-container>
 *
 * @param dgxFeatureFlagToggle: name of the flags. either a string or array of strings.
 * @param hidden: inverts the flag so the content is hidden when the flag is true and vice versa.
 */
@Directive({
  selector: '[dgxFeatureToggle]',
})
export class FeatureToggleDirective implements OnInit {
  public authUser: AuthUser;
  public invert: boolean = false;
  public flags: string[];
  public flagValue: boolean;
  public initialized: boolean = false;
  public warn = true;
  public property: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private ldFlagsService: LDFlagsService
  ) {}

  @Input()
  set dgxFeatureToggleHidden(val) {
    this.invert = val === true;
    if (this.initialized) {
      this.updateView();
    }
  }

  @Input()
  set dgxFeatureToggle(val) {
    this.flags = Array.isArray(val) ? val : [val];
    if (this.initialized) {
      this.updateView();
    }
  }

  @Input()
  set dgxFeatureToggleWarn(val) {
    this.warn = val === true;
  }

  /** Name of an optional sub-property to read for the actual flag value, instead of reading the flag value directly. */
  @Input()
  set dgxFeatureToggleProperty(val) {
    this.property = val;
  }

  public ngOnInit() {
    this.initialized = true;
    this.authUser = this.authService.authUser;

    for (const flag of this.flags) {
      const flagValue =
        this.authUser?.[flag] ??
        this.authUser?.defaultOrgInfo?.settings?.[flag] ??
        this.ldFlagsService.getFlag(flag, false);

      // If there is a sub-property specified for the flag, drill down further to it
      this.flagValue = this.property ? flagValue?.[this.property] : flagValue;

      if (this.flagValue) {
        break;
      }

      if (this.flagValue === undefined && this.warn && this.authUser) {
        console.warn(
          `Feature Toggle: No flag '${flag}' exists in authUser or in LDFlagsService`
        );
      }
    }

    this.updateView();
  }

  public updateView() {
    if ((this.flagValue && !this.invert) || (!this.flagValue && this.invert)) {
      this.replace();
    } else {
      this.remove();
    }
  }

  public replace() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  public remove() {
    this.viewContainer.clear();
  }
}
