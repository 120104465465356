import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MentionItem } from '@app/comments/comments.model';
import { EncodeToEntitiesPipe } from '@app/shared/pipes/encode-to-entities.pipe';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { SanitizationService } from '@dg/shared-services';
import { MentionUserMatch } from '../../comments.model';

export const COMMENT_MAX_LENGTH = 30000; // The longest comment we currently have is ~25k characters.

@Injectable({
  providedIn: 'root',
})
export class MentionService {
  constructor(
    private encodeToEntitiesPipe: EncodeToEntitiesPipe,
    private sanitizationService: SanitizationService,
    private formBuilder: FormBuilder
  ) {}

  public initializeCommentForm(
    comment = '',
    mentions: MentionItem[] = []
  ): FormGroup {
    return this.formBuilder.group({
      comment: [
        comment ? comment : '',
        [
          Validators.required,
          Validators.maxLength(COMMENT_MAX_LENGTH),
          isEmptyValidator,
        ],
      ],
      mentions: [Array.isArray(mentions) ? mentions : []],
    });
  }

  public formatCommentAndMentionsForBE(
    comment = '',
    mentions: MentionItem[] = []
  ): { comment: string; mentions: MentionItem[] } {
    // Silly that we have to 'bypass' sanitization here, but if we don't, new lines get stripped out.
    const updatedMentions: MentionItem[] = [];
    let safeComment = this.sanitizationService.removeHtml(comment || '', true);

    // SANITY CHECK.
    if (safeComment === '') {
      return { comment: '', mentions: [] };
    }

    if (mentions?.length) {
      // Replace @[vanityUrl](User's Name) with @[1234] -- aka userProfileKey,
      // as that's what the BE and our other code expects.
      for (const mention of mentions) {
        let stillMentioned = false;
        const key = new RegExp(
          '@\\[' + mention.vanityUrl + '\\]\\([^)]+\\)',
          'g'
        );
        const formattedMention = '@[' + mention.userProfileKey + ']';
        safeComment = safeComment.replaceAll(key, () => {
          if (!stillMentioned) {
            stillMentioned = true;
          }
          return formattedMention;
        });

        if (stillMentioned) {
          updatedMentions.push(mention);
        }
      }
    }

    return { comment: safeComment, mentions: updatedMentions };
  }

  public formatCommentAndMentionsForDisplay(
    comment = '',
    mentions: MentionItem[] = []
  ): string {
    if (!mentions) {
      return comment;
    }

    if (mentions?.length) {
      // Replace @[1234] with blue links to profiles for display.
      for (const mention of mentions) {
        const key = new RegExp('@\\[' + mention.userProfileKey + '\\]', 'g');
        const formattedMention = this.formatMentionLink(mention);
        comment = comment.replaceAll(key, formattedMention);
      }
    }

    return comment;
  }

  public formatCommentAndMentionsForEdit(
    comment = '',
    mentions: MentionItem[] = []
  ): string {
    // Silly that we have to 'bypass' sanitization here, but if we don't, new lines get stripped out.
    let safeComment = this.sanitizationService.removeHtml(comment || '', true);

    // SANITY CHECK.
    if (safeComment === '') {
      return '';
    }

    if (mentions?.length) {
      // Replace @[1234] with @[vanityUrl](User's Name) for user-readable editing.
      for (const mention of mentions) {
        const key = new RegExp('@\\[' + mention.userProfileKey + '\\]', 'g');
        const formattedMention =
          '@[' + mention.vanityUrl + '](' + mention.name.trim() + ')';
        safeComment = safeComment.replaceAll(key, formattedMention);
      }
    }

    return safeComment;
  }

  // This takes each mention object and defines a simple new anchor element
  // e.g.: { name: 'Victor Osuyak', vanityUr: 'vosuyak' } -> <a href="/vosuyak">Victor Osuyak</a>
  private formatMentionLink(mention: MentionUserMatch): string {
    if (!mention) {
      return '';
    }
    let newComment = `<a href="/${mention.vanityUrl}" class="color-blue js-mention">`;
    // Special character encode (this is going to be displayed with innerHTML)
    newComment += this.encodeToEntitiesPipe.transform(mention?.name);
    newComment += '</a>';
    return newComment;
  }
}
