import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, of } from 'rxjs';

import { FieldWrapper } from '@ngx-formly/core';

import { DF_COLLAPSE_EXPAND } from '../../utilities/animations/animations';
import {
  DfFieldsetTemplateOptions,
  DfFieldTemplateContext,
} from '../field-types';
import { isString } from '../../utilities/types/type-guards';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Provides a fieldset as a formly custom wrapper, typically applied to
 * a field group to associate inputs together for accessibility.
 */
@Component({
  selector: 'df-fieldset',
  template: `
    <fieldset [disabled]="to.disabled">
      <legend
        *ngIf="to.dfLabel"
        class="df-form__label guts-p-b-1"
        [dfProvideContent]="to.dfLabel"
        [dfProvideContentContext]="contentContext"
      ></legend>
      <div
        class="clear"
        [@dfCollapseExpand]
        *ngIf="expand | async"
        [id]="to.expandingContainerId"
      >
        <ng-container #fieldComponent></ng-container>
      </div>
    </fieldset>
  `,
  styleUrls: ['./fieldset.component.scss'],
  animations: [DF_COLLAPSE_EXPAND],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfFieldsetComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  public fieldComponent: ViewContainerRef;

  public expand: Observable<boolean>;

  public ngOnInit(): void {
    const to = this.to as DfFieldsetTemplateOptions;
    // expansion observable may either be directly provided, referenced by a key or path to a boolean form control
    // or, if not provided, defaults to a one-shot observable that expands immediately upon creation
    this.expand = ((isString(to.expansionController)
      ? this.form.get(to.expansionController)?.valueChanges
      : to.expansionController) ?? of(true)) as unknown as Observable<boolean>;
  }

  public get contentContext(): DfFieldTemplateContext {
    return {
      formControl: this.formControl,
      key: this.key,
      label: this.to.dfLabel,
      // there are no params to provide in the case of the auxiliary content such as labels
    };
  }
}
