import {
  Pipe,
  PipeTransform,
  ɵBypassType,
  ɵgetSanitizationBypassType,
  ɵunwrapSafeValue,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { EncodeToEntitiesPipe } from './encode-to-entities.pipe';

/**
 * Wrapper around {@see #TranslateService.instant()} that escapes any arguments if necessary
 *
 * You only need to use this if either the translation string itself or any of the arguments
 * contain HTML.
 */
@Pipe({
  name: 'htmlTranslated',
})
export class HtmlTranslatedPipe implements PipeTransform {
  constructor(
    protected sanitizer: DomSanitizer,
    protected translateService: TranslateService,
    protected encodeToEntitiesPipe: EncodeToEntitiesPipe
  ) {}

  public transform(key: string, args: Record<string, any>): SafeHtml {
    const escaped: Record<string, any> = {};
    for (const name in args) {
      const value = args[name];
      escaped[name] = this.isSafeHtml(value)
        ? ɵunwrapSafeValue(value)
        : this.encodeToEntitiesPipe.transform((value ?? '').toString());
    }
    const result = this.translateService.instant(key, escaped);

    // All of the arguments were escaped so we can trust the combined translation
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  private isSafeHtml(value: any): value is SafeHtml {
    return ɵgetSanitizationBypassType(value) == ɵBypassType.Html;
  }
}
