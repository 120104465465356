import { LDFlagsService } from '../ld-flags.service';

export class SearchLDFlags {
  /***
   *  Team: Platform Services
   *  Created Date: 5/06/2023 https://github.com/degreed/Degreed/pull/35258
   *  Planned Cleanup: 1/6/2024 [PD-91488]
   *  Visibility:
   *    - Org 1
   *    - Pre-Release: NL Catalog Search
   *
   *  Enables POC for natural language results when searching for content
   */
  public get naturalLanguageCatalogSearch(): boolean {
    return this.ldFlagsService.getFlag(
      'natural-language-catalog-search',
      false
    );
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
