import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OpportunityFlagsService } from '@app/opportunities/services/opportunity-flags.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { NotificationType } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-select-candidate-modal',
  templateUrl: './select-candidate-modal.component.html',
})
export class SelectCandidateModalComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public usePlural = false;

  public i18n = this.translateService.instant([
    // Title translation wont be used anymore, remove in i18n?
    // 'Opportunities_Candidates_Modal_UpdateApplicantPrompt_Title',
    // 'Opportunities_Candidates_Modal_UpdateApplicantPrompt_Title_Plural',
    'Core_Optional',
    'Opportunities_Candidates_Email_RejectionMessage',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_Congratulations',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_Congratulations_Plural',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_Description',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_Description_Plural',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_CloseOpp',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_NotifyUnselected',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_SubmitButton',
    'Opportunities_Candidates_Modal_UpdateApplicantPrompt_SubmitButton_Plural',
  ]);

  public readonly NotificationType = NotificationType;
  public showRejectMessageEmail =
    this.opportunityFlagsService.useRejectMessageEmail;
  public descriptionText =
    this.i18n.Opportunities_Candidates_Modal_UpdateApplicantPrompt_Description;
  public congratulationsText =
    this.i18n
      .Opportunities_Candidates_Modal_UpdateApplicantPrompt_Congratulations;
  public submitButtonText =
    this.i18n.Opportunities_Candidates_Modal_UpdateApplicantPrompt_SubmitButton;

  public modalForm = this.showRejectMessageEmail
    ? (this.formBuilder.group({
        closeOpportunity: [false],
        notifyOthers: new FormControl({
          disabled: true,
          // also *true* by default, but this value will be discarded by Angular
          // if it's still disabled when the form is submitted.
          value: true,
        }),
        rejectionMessage: new FormControl({
          disabled: true,
          value: '',
        }),
        // TODO NGX 14 UPGRADE: Review type "FormGroup" (CCO)
      }) as FormGroup)
    : (this.formBuilder.group({
        closeOpportunity: [false],
        // disabled by default, otherwise disabled if closeOpportunity is false
        notifyOthers: new FormControl({
          disabled: true,
          // also *true* by default, but this value will be discarded by Angular
          // if it's still disabled when the form is submitted.
          value: true,
        }),
        // TODO NGX 14 UPGRADE: Review type "FormGroup" (CCO)
      }) as FormGroup);

  public inputs = [
    {
      id: 'close-opportunity',
      name: 'closeOpportunity',
      dgat: 'award-opportunity-modal-025',
      label: 'Opportunities_Candidates_Modal_UpdateApplicantPrompt_CloseOpp',
    },
    {
      id: 'notify-others',
      name: 'notifyOthers',
      dgat: 'award-opportunity-modal-0a7',
      label:
        'Opportunities_Candidates_Modal_UpdateApplicantPrompt_NotifyUnselected',
    },
  ];

  public get showRejectionField(): boolean {
    return (
      this.showRejectMessageEmail &&
      !this.modalForm.get('notifyOthers').disabled &&
      !!this.modalForm.get('notifyOthers').value
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private opportunityFlagsService: OpportunityFlagsService,
    private translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.usePlural) {
      this.submitButtonText =
        this.i18n.Opportunities_Candidates_Modal_UpdateApplicantPrompt_SubmitButton_Plural;
      this.descriptionText =
        this.i18n.Opportunities_Candidates_Modal_UpdateApplicantPrompt_Description_Plural;
      this.congratulationsText =
        this.i18n.Opportunities_Candidates_Modal_UpdateApplicantPrompt_Congratulations_Plural;
    }
    this.modalForm
      .get('closeOpportunity')
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((value) => {
        if (value) {
          return this.modalForm.get('notifyOthers').enable();
        }
        this.modalForm.get('notifyOthers').disable();
        this.modalForm.get('rejectionMessage').disable();
      });
    this.modalForm
      .get('notifyOthers')
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((value) =>
        value
          ? this.modalForm.get('rejectionMessage').enable()
          : this.modalForm.get('rejectionMessage').disable()
      );
  }
}
