<ng-container *ngIf="!showLearnerHubModal">
  <dgx-modal-header (dismiss)="onDismiss()" [canCancel]="!isEbb">
    {{ i18n.addToPathwayModal_AddToPathway }}
  </dgx-modal-header>
  <div aria-live="polite">
    <!-- ^^^ Lots of dynamic changes in this modal. Let's read them all out -->
    <form
      class="form-wrap"
      *ngIf="displayArea === 'selectionForm'"
      (ngSubmit)="onFormSubmit()"
    >
      <div [ngClass]="isEbb ? 'guts-p-v-2 guts-p-h-1-half' : 'guts-m-full-2'">
        <dgx-org-managed-notification
          class="guts-m-t-2 guts-m-b-2 block"
          *ngIf="showOrgManagedNotification"
          resourceType="Pathway"
        ></dgx-org-managed-notification>
        <div class="guts-p-b-1">
          <label for="pathway-input">{{
            i18n.addToPathwayModal_Pathway
          }}</label>
          <dgx-combobox
            *ngIf="!loadingPathways"
            class="full-width"
            (selection)="onPathwaySelected($event)"
            [options]="pathwayOptions"
            labelKey="name"
            placeholder="{{ i18n.addToPathwayModal_SelectPathway }}"
            hasNoResultsText="{{ i18n.Core_NoResultsFound }}"
            dgatInput="addToPathway-4d1"
          ></dgx-combobox>
          <dgx-skeleton *ngIf="loadingPathways" type="formfield"></dgx-skeleton>
        </div>
        <div
          *ngIf="sectionOptions.length > 0 || loadingSections"
          class="guts-p-b-1"
        >
          <label for="section-input">{{
            i18n.addToPathwayModal_Section
          }}</label>
          <dgx-combobox
            *ngIf="!loadingSections"
            class="full-width"
            (selection)="onSectionSelected($event)"
            [options]="sectionOptions"
            labelKey="name"
            [selectedOption]="autoSelectedSection?.name"
            placeholder="{{ i18n.addToPathwayModal_SelectSection }}"
            hasNoResultsText="{{ i18n.Core_NoResultsFound }}"
            dgatInput="addToPathway-681"
          ></dgx-combobox>
          <dgx-skeleton *ngIf="loadingSections" type="formfield"></dgx-skeleton>
        </div>
        <div *ngIf="subsectionOptions.length > 0" class="guts-p-b-1">
          <label for="section-input">{{ i18n.addToPathwayModal_Lesson }}</label>
          <dgx-combobox
            class="full-width"
            (selection)="onSubsectionSelected($event)"
            [options]="subsectionOptions"
            labelKey="name"
            placeholder="{{ i18n.addToPathwayModal_SelectLesson }}"
            hasNoResultsText="{{ i18n.Core_NoResultsFound }}"
            dgatInput="addToPathway-9e9"
          ></dgx-combobox>
        </div>
        <div
          *ngIf="isEbb && displayAddToCatalogOption && isValid"
          class="guts-p-t-1 v-middle-children m-lh-1-half"
        >
          <input
            type="checkbox"
            id="addToCatalog"
            name="addToCatalog"
            [checked]="addToCatalog"
            (change)="toggleAddToCatalog()"
            data-dgat="addToPathway-d36"
          />
          <label for="addToCatalog" class="guts-m-l-half">{{
            i18n.addToPathwayModal_AddToCatalog
          }}</label>
        </div>
      </div>
      <dgx-modal-footer
        [canCancel]="true"
        [useDefaultSubmitButton]="false"
        (dismiss)="onDismiss()"
      >
        <button
          df-button
          dfButtonType="primary"
          type="submit"
          data-dgat="addToPathway-c23"
          [disabled]="!isValid || (isSubmitting | async)"
          [class.is_disabled]="!isValid || (isSubmitting | async)"
          [attr.aria-disabled]="!isValid || (isSubmitting | async)"
          [attr.aria-label]="
            (isSubmitting | async)
              ? i18n.Core_Loading
              : i18n.addToPathwayModal_AddToPathway
          "
        >
          <df-spinner-button [isSpinning]="isSubmitting | async">
            {{ i18n.addToPathwayModal_AddToPathway }}
          </df-spinner-button>
        </button>
      </dgx-modal-footer>
    </form>
    <!-- no pathways -->
    <div *ngIf="displayArea === 'noPathways'">
      <div class="guts-p-full-2 center-text">
        <h3 class="h3">{{ i18n.addToPathwayModal_CreateYourPathway }}</h3>
        <p class="par par--small">
          {{ i18n.addToPathwayModal_CreatePathwayExplanation }}
        </p>
      </div>
      <dgx-modal-footer
        [canCancel]="true"
        [useDefaultSubmitButton]="false"
        (dismiss)="onDismiss()"
      >
        <button
          df-button
          dfButtonType="primary"
          (click)="openCreatePathway()"
          data-dgat="addToPathway-cdf"
        >
          {{ i18n.addToPathwayModal_AddToPathway }}
        </button>
      </dgx-modal-footer>
    </div>
    <!-- success message -->
    <div *ngIf="displayArea === 'successMessage'">
      <div class="guts-p-full-2 center-text">
        <h3 class="h3">{{ i18n.addToPathwayModal_AddedSuccessfully }}</h3>
        <p class="par par--small">
          {{ i18n.addToPathwayModal_VisitPathwayEditPage }}
        </p>
      </div>

      <dgx-modal-footer [useDefaultSubmitButton]="false">
        <a
          *ngIf="pathwayAuthoringUrl"
          df-button
          dfButtonType="passive"
          class="guts-m-r-1 ib"
          href="{{ pathwayAuthoringUrl }}"
          (click)="isEbb || isIframe ? closeModal() : undefined"
          attr.target="{{ isEbb || isIframe ? '_blank' : undefined }}"
          data-dgat="addToPathway-af2"
          >{{ i18n.addToPathwayModal_EditPathway }}</a
        >
        <button
          df-button
          dfButtonType="primary"
          (click)="closeModal()"
          data-dgat="addToPathway-7fe"
        >
          {{ i18n.Core_Done }}
        </button>
      </dgx-modal-footer>
    </div>

    <!-- Open Create New Pathway for Ebb -->
    <div *ngIf="displayArea === 'ebbCreatePathway'">
      <div class="guts-p-full-2 center-text">
        <p class="h4">{{ i18n.dgManagePathways_CreatePathway }}</p>
      </div>

      <dgx-modal-footer [useDefaultSubmitButton]="false">
        <button
          df-button
          dfButtonType="primary"
          (click)="openCreatePathway()"
          data-dgat="addToPathway-7fr"
        >
          {{ i18n.OnboardingFooter_NextStep }}
        </button>
      </dgx-modal-footer>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showLearnerHubModal">
  <div
    class="tw-w-full tw-max-w-80 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg sm:tw-max-w-sm md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
    role="dialog"
    aria-labelledby="add-to-pathway-title"
    aria-describedby="add-to-pathway-description"
    *ngIf="displayArea === 'selectionForm'"
  >
    <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
      <h1
        class="tw-text-sm tw-font-extrabold tw-text-neutral-900"
        id="add-to-pathway-title"
        tabindex="0"
        autofocus
      >
        {{ i18n.addToPathwayModal_AddToPathway }}
      </h1>

      <div
        *ngIf="showOrgManagedNotification"
        class="tw-relative tw-flex tw-min-h-10 tw-min-w-full tw-items-center tw-gap-2 tw-overflow-hidden tw-rounded-lg tw-bg-neutral-100 tw-px-3 tw-py-2 tw-pl-4 before:tw-absolute before:tw-inset-0 before:tw-block before:tw-w-1 before:tw-rounded-l-lg before:tw-bg-neutral-600"
      >
        <da-icon
          icon="information-circle"
          type="solid"
          [solidSize]="16"
          className="tw-size-4 tw-shrink-0 tw-text-neutral-600"
        />
        <p
          class="tw-text-xs tw-text-neutral-800"
          [innerHTML]="orgManagedLabel"
        ></p>
      </div>

      <da-dropdown
        *ngIf="!loadingPathways"
        [items]="pathwayRowItems"
        [className]="
          'tw-btn-medium tw-btn-secondary-outline tw-w-full tw-justify-between'
        "
        (itemSelect)="onPathwaySelected($event)"
        [placeholder]="i18n.addToPathwayModal_SelectPathway"
      ></da-dropdown>

      <da-dropdown
        *ngIf="sectionOptions.length > 0 && !loadingSections"
        [items]="sectionRowItems"
        [className]="
          'tw-btn-medium tw-btn-secondary-outline tw-w-full tw-justify-between'
        "
        (itemSelect)="onSectionSelected($event)"
        [placeholder]="i18n.addToPathwayModal_SelectPathwaySection"
      ></da-dropdown>

      <da-dropdown
        *ngIf="subsectionOptions.length > 0"
        [items]="subSectionRowItems"
        [className]="
          'tw-btn-medium tw-btn-secondary-outline tw-w-full tw-justify-between'
        "
        (itemSelect)="onSubsectionSelected($event)"
        [placeholder]="i18n.addToPathwayModal_SelectPathwaySubSection"
      ></da-dropdown>

      <label
        class="tw-flex tw-items-center tw-gap-2 tw-text-sm tw-text-neutral-800"
        for="addToCatalog"
        *ngIf="isEbb && displayAddToCatalogOption && isValid"
      >
        <input
          class="tw-rounded tw-border-neutral-300"
          type="checkbox"
          name="addToCatalog"
          id="addToCatalog"
          [checked]="addToCatalog"
          (change)="toggleAddToCatalog()"
        />
        {{ i18n.addToPathwayModal_AddToCatalog }}
      </label>
    </div>
    <div
      class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
    >
      <button
        class="tw-btn-medium tw-btn-secondary-filled"
        (click)="onDismiss()"
      >
        {{ i18n.Core_Cancel }}
      </button>
      <button
        class="tw-btn-primary tw-btn-medium"
        [class.is_disabled]="!isValid || (isSubmitting | async)"
        [attr.aria-disabled]="!isValid || (isSubmitting | async)"
        [attr.aria-label]="
          (isSubmitting | async)
            ? i18n.Core_Loading
            : i18n.addToPathwayModal_AddToPathway
        "
        (click)="onFormSubmit()"
      >
        {{ i18n.addToPathwayModal_AddToPathway }}
      </button>
    </div>
  </div>

  <!-- no pathways -->
  <div
    class="tw-w-full tw-max-w-80 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg sm:tw-max-w-sm md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
    role="dialog"
    aria-labelledby="add-to-pathway-title"
    aria-describedby="add-to-pathway-description"
    *ngIf="displayArea === 'noPathways'"
  >
    <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
      <h1
        class="tw-text-sm tw-font-extrabold tw-text-neutral-900"
        id="add-to-pathway-title"
        tabindex="0"
        autofocus
      >
        {{ i18n.addToPathwayModal_CreatePathway }}
      </h1>
      <p class="tw-text-neutral-800">
        {{ i18n.addToPathwayModal_NoPathwayDescription }}
      </p>
    </div>
    <div
      class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
    >
      <button
        class="tw-btn-medium tw-btn-secondary-filled"
        (click)="onDismiss()"
      >
        {{ i18n.Core_Cancel }}
      </button>
      <button
        class="tw-btn-primary tw-btn-medium"
        (click)="openCreatePathway()"
      >
        {{ i18n.addToPathwayModal_CreatePathway }}
      </button>
    </div>
  </div>

  <!-- success message -->
  <div
    class="tw-w-full tw-max-w-80 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg sm:tw-max-w-sm md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
    role="dialog"
    aria-labelledby="add-to-pathway-title"
    aria-describedby="add-to-pathway-description"
    *ngIf="displayArea === 'successMessage'"
  >
    <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
      <h1
        class="tw-text-sm tw-font-extrabold tw-text-neutral-900"
        id="add-to-pathway-title"
        tabindex="0"
        autofocus
      >
        {{ i18n.addToPathwayModal_AddToPathway }}
      </h1>
      <p class="tw-text-neutral-800" [innerHTML]="successLabel"></p>
    </div>
    <div
      class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
    >
      <button
        class="tw-btn-medium tw-btn-secondary-filled"
        (click)="closeModal()"
      >
        {{ i18n.Core_Done }}
      </button>

      <a
        *ngIf="pathwayAuthoringUrl"
        href="{{ pathwayAuthoringUrl }}"
        (click)="isEbb || isIframe ? closeModal() : undefined"
        attr.target="{{ isEbb || isIframe ? '_blank' : undefined }}"
        class="tw-btn-primary tw-btn-medium"
        >{{ i18n.addToPathwayModal_GoToPathway }}</a
      >
    </div>
  </div>

  <!-- Open Create New Pathway for Ebb -->
  <div
    class="tw-w-full tw-max-w-80 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg sm:tw-max-w-sm md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
    role="dialog"
    aria-labelledby="add-to-pathway-title"
    aria-describedby="add-to-pathway-description"
    *ngIf="displayArea === 'ebbCreatePathway'"
  >
    <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
      <h1
        class="tw-text-sm tw-font-extrabold tw-text-neutral-900"
        id="add-to-pathway-title"
        tabindex="0"
        autofocus
      >
        {{ i18n.dgManagePathways_CreatePathway }}
      </h1>
    </div>
    <div
      class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
    >
      <button
        class="tw-btn-primary tw-btn-medium"
        (click)="openCreatePathway()"
      >
        {{ i18n.OnboardingFooter_NextStep }}
      </button>
    </div>
  </div>
</ng-container>
