<div
  class="image-layout rel"
  data-dgprop-item-clicked="Image"
  [ngClass]="getImageLayoutClass"
>
  <div class="rel image-layout__wrapper">
    <div
      class="image-layout__container m-l_flex l_flex l_flex-justify-center"
      [ngClass]="{
        'round bg-color-ebony-a08 image-layout__container--icon l_flex-middle':
          icon,
        'image-layout__container--pattern': fallbackPatternSrc,
      }"
    >
      <!-- Main img or icon -->
      <ng-container *ngIf="!imageHasError; else imageErrors" class="rel">
        <df-icon
          *ngIf="icon"
          [icon]="icon"
          size="medium"
          class="color-ebony-a45"
        ></df-icon>
        <img
          *ngIf="resourceSrc"
          class="image-layout__resource"
          [alt]="altText || ''"
          [title]="altText || ''"
          role="img"
          [src]="resourceSrc.retina"
          [attr.srcset]="
            resourceSrc.orig + ' 1x, ' + resourceSrc.retina + ' 2x'
          "
          [ngStyle]="{ 'max-height': imageSize.h / 12 + 'rem' }"
          [class]="
            shouldPadCropping ? 'image-layout__resource--padded' : undefined
          "
          (error)="errorLoadingImage()"
        />
        <div *ngIf="providerLogo" class="logo-container">
          <img [src]="providerLogo" class="provider-logo" />
        </div>
      </ng-container>

      <!-- fallbacks -->
      <ng-template #imageErrors>
        <div *ngIf="!fallbackLogoSrc; else fallbackpattern">
          <div
            *ngIf="isLearnerHub"
            class="bg-color-purple-light image-layout__pattern l_flex m-l_flex l_flex-middle l_flex-justify-center"
          >
            <df-icon
              *ngIf="resourceType === 'Tag'"
              [icon]="resourceTypeIcon"
              class="color-purple flip"
              [size]="fallbackIconSize"
            ></df-icon>
            <!-- SVG used here as photo icon is not yet available -->
            <svg
              *ngIf="resourceType !== 'Tag'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="color-purple"
              [class]="fallbackIconSize === 'large' ? 'size-24' : 'size-16'"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <div
            *ngIf="!isLearnerHub"
            class="bg-color-forest-light image-layout__pattern l_flex m-l_flex l_flex-middle l_flex-justify-center"
          >
            <df-icon
              [icon]="resourceTypeIcon"
              class="color-forest-dark"
              [size]="fallbackIconSize"
            ></df-icon>
          </div>
        </div>

        <img
          *ngIf="fallbackLogoSrc"
          class="image-layout__logo"
          alt=""
          [src]="fallbackLogoSrc"
        />
        <div *ngIf="providerLogo" class="logo-container">
          <img [src]="providerLogo" class="provider-logo" />
        </div>
      </ng-template>
    </div>

    <!-- Include ?brandlogo here to assist the Degreed Live folks in targetting this image for logo replacement (AB#20558) -->
    <img
      *ngIf="endorsedSrc"
      class="image-layout__endorsed"
      [src]="endorsedSrc + '?brandlogo'"
      [alt]="'Core_Endorsed' | translate"
      [ngbTooltip]="'Core_EndorsedTooltip' | translate"
    />
  </div>
</div>

<ng-template #fallbackpattern>
  <div
    *ngIf="fallbackPatternSrc"
    class="image-layout__pattern"
    [ngStyle]="{ 'background-image': 'url(' + fallbackPatternSrc + ')' }"
  ></div>
</ng-template>
