<div class="utility-bar l_flexbar">
  <ng-container
    *dgxFeatureToggle="
      ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
      hidden: true
    "
  >
    <div class="utility-bar-item">
      <dgx-global-search *ngIf="!shouldHideGlobalSearch"></dgx-global-search>
    </div>
  </ng-container>
  <div class="l_flexbar">
    <ng-container
      *dgxFeatureToggle="
        [
          'isClientProvider',
          'isSkillInventoryClient',
          'isSkillAnalyticsClient'
        ];
        hidden: true
      "
    >
      <div
        class="utility-bar-item"
        *ngIf="isUserAuthenticated && !shouldHideGlobalAdd"
      >
        <dgx-global-add-menu></dgx-global-add-menu>
      </div>
    </ng-container>
    <div
      class="utility-bar-item"
      *ngIf="isUserAuthenticated && !shouldHideNotifications"
    >
      <dgx-notification-menu></dgx-notification-menu>
    </div>
    <div *ngIf="isUserAuthenticated" class="utility-bar-item">
      <dgx-profile-menu
        [orgId]="orgId"
        [hasLightText]="hasLightText"
      ></dgx-profile-menu>
    </div>
    <!-- Unauthenticated user -->
    <ng-container *ngIf="!isUserAuthenticated">
      <div class="utility-bar-item">
        <a
          [attr.href]="loginUrl"
          class="btn btn-passive"
          data-dgat="utilityBar-8b1"
          >{{ 'AppHeader_Login' | translate }}</a
        >
      </div>
      <div class="utility-bar-item">
        <a
          [attr.href]="signUpUrl"
          class="btn btn-primary"
          data-dgat="utilityBar-d79"
          >{{ 'AppHeader_Signup' | translate }}</a
        >
      </div>
    </ng-container>
  </div>
</div>
