import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'dgx-confirm-move-plan-modal',
  templateUrl: './confirm-move-plan-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmMovePlanModalComponent {
  constructor() {}
}
