import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';

// services
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

// types
import { TagsApi } from '@app/tags/tag-api.model';
import { AnyUserInput, InlineInputSubmitter } from '@app/inputs/inputs.model';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { InputModalConfig } from '@app/inputs/services/user-input-modal.service';

// utils
import { camelCaseKeys } from '@app/shared/utils/property';

@Component({
  selector: 'dgx-add-inputs-tags-modal',
  templateUrl: './add-inputs-tags-modal.component.html',
})
export class AddInputsTagsModalComponent implements OnInit {
  @Input() public inputIdentifier: InputIdentifier;
  @Input() public config: InputModalConfig;
  @Input() public saveInput: InlineInputSubmitter;

  public userInput: AnyUserInput;
  public isSaving = false;
  public i18n = this.translateService.instant([
    'Core_Save',
    'Core_Loading',
    'Ext_AddTopicsOrSkills',
  ]);

  public userTags: TagsApi.Tag[] = [];

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    @Inject(DOCUMENT) private document
  ) {}

  public get tags() {
    return this.userInput?.tags || [];
  }

  public ngOnInit() {
    this.config
      .load(this.inputIdentifier)
      .subscribe((userInput: AnyUserInput) => {
        this.userInput = userInput;
        this.updateUserTags();
      });
  }

  public updateUserTags(): void {
    const tagIds = this.userInput.tags.map((tag: TagsApi.Tag) => tag.tagId);
    this.userTags = this.authService.authUser.viewerInterests.reduce(
      (allTags, tag) => {
        if (!tagIds.includes(tag.tagId)) {
          allTags.push(tag);
        }
        return allTags;
      },
      []
    );
  }

  public handleTagsChange(updatedTags: TagsApi.Tag[]): void {
    this.userInput.tags = camelCaseKeys(updatedTags);
    this.updateUserTags();
  }

  public saveTagsChange(): void {
    this.isSaving = true;
    this.saveInput(this.userInput).subscribe((result) => {
      this.isSaving = false;
      this.activeModal.close(this.userInput);
    });
  }

  public dismiss = (): void => {
    this.activeModal.dismiss();
  };
}
