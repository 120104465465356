<form #form="ngForm" (ngSubmit)="submit(form)">
  <dgx-search-field-wrapper
    class="full-width"
    [type]="!shouldRenderInGlobalSearchMode ? 'large' : undefined"
    [shouldRenderInGlobalSearchMode]="shouldRenderInGlobalSearchMode"
  >
    <input
      #searchInput
      id="search-input-{{ instanceId }}"
      type="search"
      role="combobox"
      name="term"
      [ngClass]="styles"
      #term="ngModel"
      [(ngModel)]="searchTerm"
      [placeholder]="i18n.Core_Search"
      (focus)="onInputFocus($event)"
      (input)="onInputChange($event)"
      autocomplete="off"
      [attr.aria-label]="i18n.Core_Search"
      attr.aria-describedby="search-input-desc-{{ instanceId }}"
      aria-autocomplete="list"
      attr.aria-owns="search-popover-{{ instanceId }}"
      aria-haspopup="listbox"
      [attr.aria-expanded]="isPopoverOpen"
      attr.aria-controls="search-popover-{{ instanceId }}"
      spellcheck="true"
      data-dgat="search-suggest-8d1"
    />
  </dgx-search-field-wrapper>
</form>
<df-popover
  #popoverComponent
  id="search-popover-{{ instanceId }}"
  [(isOpen)]="isPopoverOpen"
  (isOpenChange)="onIsPopoverOpenChange($event)"
  (result)="onSuggestionSelection($event)"
  [popoverTrigger]="searchInputRef"
  placement="bottom-left"
  [placementAdjustLeftRem]="-2.8"
  [isFocusFirstItem]="false"
>
  <!-- Your autocomplete search results get projected into the ng-content -->
  <ng-content></ng-content>
</df-popover>

<p class="hidden" id="search-input-desc-{{ instanceId }}">
  {{ ariaDescription }}
</p>
