import { Injectable } from '@angular/core';
import { OrgInfo, OrgType } from '@app/account/account-api.model';
import { OrganizationType } from '@app/shared/models/core.enums';
import { AuthService } from '@dg/shared-services';

/**
 * Service for handling reporting authorization.
 * @class
 */
@Injectable({
  providedIn: 'root',
})
export class ReportingAuthorizationService {
  /**
   * Checks if reporting in app is enabled.
   * @returns {boolean} - True if reporting in app is enabled, false otherwise.
   */
  public get isReportingInAppEnabled(): boolean {
    return this.defaultOrgInfo.settings.enableReportingInApp ?? false;
  }

  /**
   * Checks if the user has permission to view reporting.
   * @returns {boolean} - True if the user can view reporting, false otherwise.
   */
  public get canViewReporting(): boolean {
    return this.authService.userCanViewReporting ?? false;
  }

  /**
   * Checks if the user has permission to view in app reports.
   */
  public get canViewInAppReports(): boolean {
    return (this.isReportingInAppEnabled && this.canViewReporting) ?? false;
  }

  /**
   * Checks if the user has permission to schedule FTP reports.
   * @returns {boolean} - True if the user can schedule FTP reports, false otherwise.
   */
  public get canScheduleFTPReports(): boolean {
    return this.defaultOrgInfo.permissions.manageReportingFTPScheduler ?? false;
  }

  /**
   * Checks if the user has permission to manage in app reports (include scheduling).
   * @returns {boolean} - True if the user can manage in app reports, false otherwise.
   */
  public get canManageInAppReports(): boolean {
    return this.defaultOrgInfo.permissions.manageReportingInApp ?? false;
  }

  /**
   * Checks if the user has permission to manage reporting transmitters.
   * @returns {boolean} - True if the user can manage reporting transmitters, false otherwise.
   */
  public get canManageReportingTransmitters(): boolean {
    return (
      (this.canManageInAppReports &&
        this.defaultOrgInfo.permissions
          .manageReportingTransmitterConfigurations) ??
      false
    );
  }

  /**
   * Checks if the user has permission to manage reporting segments.
   * @returns {boolean} - True if the user can manage reporting segments, false otherwise.
   */
  public get canManageReportingSegments(): boolean {
    return (
      (this.canManageInAppReports &&
        this.defaultOrgInfo.permissions.manageSegments) ??
      false
    );
  }

  private defaultOrgId: number;
  private defaultOrgInfo: OrgInfo;
  private orgType: OrgType;

  /**
   * Constructs the ReportingAuthorizationService.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(private authService: AuthService) {
    this.defaultOrgId = this.authService.authUser.defaultOrgId;
    this.defaultOrgInfo = this.authService.authUser.defaultOrgInfo;
    this.orgType = this.authService.authUser.defaultOrgInfo.type;
  }

  /**
   * Resolves the organization URL.
   * @param {string} routeDirectory - The route directory.
   * @param {string} routePath - The route path.
   * @returns {string} - The resolved URL.
   */
  public reportingRedirectUrl(
    routeDirectory: string,
    routePath: string
  ): string {
    //@TODO: there's a 3rd (OrganizationType.Tenant) that is not being handled here
    const basePath =
      this.orgType === OrganizationType.Channel
        ? `/channel${routeDirectory}`
        : `/orgs/${this.defaultOrgId}${routeDirectory}`;

    const reportingRedirectUrl = `${basePath}${routePath}`;

    return reportingRedirectUrl;
  }
}
