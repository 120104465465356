import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SimpleItemViewModel } from '../models/core-view.model';
import { FocusStackService } from './focus-stack.service';

export interface Popover {
  isOpen: boolean;
  isOpenChange: EventEmitter<boolean>;
  bodyContainer: HTMLElement | null;
}

export interface ClosePopoverResult {
  itemViewModel?: SimpleItemViewModel;
  preventRefocus?: boolean;
}

/** A simple service for tracking and managing popovers to enforce singleton visibility behavior and allow subscribers to get menu selections or other results from popovers
 *
 * Order of opperations for popovers that close other popovers
 * 1) open popover1 initialized
 * 2) open popover1 finalized (focus stack push happens here)
 * 3) open popover2 initialized
 * 4) close popover1 initialized (focus stack clear happens here)
 * 5) close popover1 finalized
 * 6) open popover2 finalized (focus stack push happens here)
 * 7) close popover2 initialized (normal close, not 3rd popover)
 * 8) close popover2 finalized (focus stack pop happens here)
 */

@Injectable({ providedIn: 'root' })
export class PopoverService {
  public _activePopover: Popover;
  public _close$ = new Subject<any>();

  constructor(private focusStackService: FocusStackService) {}

  /** Fires when the active popover is closed, providing an optional popover selection result */
  public get close$() {
    return this._close$ as Observable<any>;
  }

  public get activePopover() {
    return this._activePopover;
  }

  public set openPopover(popover: Popover) {
    if (this._activePopover && popover !== this._activePopover) {
      // immediately clear focus stack to prevent race conditions
      this.focusStackService.clear();
      this.close();
    }
    this._activePopover = popover;
  }

  public close(result?: ClosePopoverResult) {
    if (this._activePopover) {
      // if popover was appended to bodyContainer, don't allow prevent focus because focus will for sure get lost in this scenario (eg. when the tab key is pressed) unless prevent focus was set by the item model. This is a bit hokey, but I couldn't see a better place to handle this. --Jason
      if (
        (!this._activePopover.bodyContainer && result?.preventRefocus) ||
        result?.itemViewModel?.preventRefocus
      ) {
        this.focusStackService.clear();
      }
      this._close$.next(result);
      this._activePopover = undefined;
    }
  }
}
