import { Injectable } from '@angular/core';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { parseImageDataForBE } from '@app/user-content/user-input-v2/utils/image-data-utils';
import { getMappedInferredSkills } from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { ImageUrlService } from '@app/user-content/user-input/services/image-url.service';
import { PathwayStep } from '@dg/pathways-rsm';
import { DurationConverterService } from '@dg/shared-services';
import {
  ArticleApiInputEdit,
  ArticleEntryApi,
  ArticleMappingToAPI,
  ArticleModel,
} from '../article.model';

@Injectable({
  providedIn: 'root',
})
export class ArticleMapperService {
  constructor(
    private imageUrlService: ImageUrlService,
    private durationConverterService: DurationConverterService
  ) {}

  public toViewModel(
    source: ArticleEntryApi,
    articleModel: ArticleModel
  ): ArticleModel {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    const addToCatalog = !(
      source.orgContentMetadata?.hideFromCatalog ?? !articleModel.addToCatalog
    );

    return {
      accessible: !!source.accessible ?? !!articleModel.orgContentMetadata,
      createdBy: source.createdBy ?? articleModel.createdBy,
      durationHours: source.durationHours ?? articleModel.durationHours,
      durationMinutes: source.durationMinutes ?? articleModel.durationMinutes,
      entryUrl: source.entryUrl ?? articleModel.entryUrl,
      externalId: source.externalId ?? articleModel.externalId,
      file: source.hostedContentDetails
        ? {
            name: source.hostedContentDetails.fileName,
            size: source.hostedContentDetails.fileSize,
          }
        : undefined,
      fileManaged: source.fileManaged ?? articleModel.fileManaged,
      format: source.format ?? articleModel.format,
      hasBrokenUrl: source.hasBrokenUrl,
      highConfidenceInferredSkills: source.highConfidenceInferredSkills?.length
        ? getMappedInferredSkills(source.highConfidenceInferredSkills)
        : articleModel?.highConfidenceInferredSkills?.length
          ? articleModel?.highConfidenceInferredSkills
          : [],
      hostedContentDetails: source.hostedContentDetails,
      imageUrl: source.imageUrl,
      inputId: source.inputId,
      inputType: source.inputType,
      language: source.language,
      mediaLength: this.durationConverterService.fromSecondsToMinutes(
        source.mediaLength
      ),
      mediaType: source.mediaType,
      // Note: BE returns string[] that doesn't map to TagsApi.Tag[].
      mediumConfidenceInferredSkills: getMappedInferredSkills(
        source.mediumConfidenceInferredSkills
      ),
      organizationId: source.organizationId ?? articleModel.organizationId,
      orgContentMetadata: {
        groupIds: groups,
        hideFromCatalog: !addToCatalog,
      }, // for On edit visibility
      owner: source.owner ? source.owner : articleModel.owner,
      publishDate: source.publishDate,
      sourceName: source.sourceName,
      summary: source.summary,
      comment: source.comment,
      tags: source.tags ?? [],
      title: source.title,
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
    } as ArticleModel;
  }

  public toApiParameters(source: ArticleModel): ArticleMappingToAPI {
    return {
      inputId: source.inputId,
      inputType: source.inputType,
      organizationId: source.orgContentMetadata?.hideFromCatalog
        ? null
        : source.organizationId,
      // userMediaId: source.userMediaId, // only valid for user completions
      sourceName: source.sourceName,
      entryUrl: source.entryUrl,
      hostedContentDetails:
        source.hostedContentDetails as HostedContentMetadata,
      title: source.title,
      summary: source.summary,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      format: source.format,
      tags: source.tags,
      orgContentMetadata: {
        hideFromCatalog: source.orgContentMetadata?.hideFromCatalog,
        groupIds: source.orgContentMetadata?.groupIds ?? [],
      },
      publishDate: source.publishDate,
      externalId: source.externalId,
      language: source.language,
      useQuickCheck: false, // TODO: currently articles this is always false check if global needs this or just delete!!source.useQuickCheck,
      accessible: !!source.accessible,
      primaryContactResourceId: source.owner?.resourceId,
      primaryContactResourceType: source.owner?.resourceType,
      comment: source.comment,
      ...parseImageDataForBE(source, this.imageUrlService),
    };
  }

  /**
   * Called both when editing a fresh, never-before-edited local step
   * and when editing an already-edited step. Prefer properties from
   * the top-level where they exist (as top-level props will be the
   * non-internal step, if there's both a local and internal version,
   * and internal edits will never call this method), falling back to
   * the reference as necessary.
   *
   * @param reference - Reference parameter of our step.
   * @param step - All other step properties.
   * @param model - ViewModel.
   */
  public fromStepToViewModel(
    { reference, ...step }: PathwayStep,
    model: ArticleModel
  ): ArticleModel {
    const editEntry: ArticleApiInputEdit = {
      entryUrl: reference.url,
      title: step.title ?? reference.title,
      sourceName: reference.source,
      summary: step.description ?? reference.summary,
      durationHours: step.durationHours ?? reference.durationHours,
      durationMinutes: step.durationMinutes ?? reference.durationMinutes,
      primaryContactResourceId: reference.primaryContactResourceId,
      primaryContactResourceType: reference.primaryContactResourceType,
      imageUrl: step.imageUrl ?? reference.imageUrl,
    } as unknown as ArticleApiInputEdit;

    // Map response to view model
    return this.toViewModel(editEntry, model);
  }

  /**
   * Only called on detail edit. Update top-level step properties.
   *
   * @param source
   * @param step
   */
  public toStep(source: ArticleModel, step: PathwayStep): ArticleModel {
    return {
      ...source,
      ...step,
      description: source.summary,
      title: source.title,
      ...parseImageDataForBE(source, this.imageUrlService),
    } as unknown as ArticleModel;
  }
}
