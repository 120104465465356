import { WindowToken } from '@app/shared/window.token';
import { Inject, OnInit, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '@app/shared/services/modal.service';
import { OrgSkillsBulkUploadSuccessComponent } from '../org-skills-bulk-upload-success/org-skills-bulk-upload-success.component';
import { OrgService } from '@app/orgs/services/org.service';
import { FileUploadSuccessResult } from '@app/uploader/file-uploader/file-uploader.component';

export interface SkillsBulkUploadResolveParams {
  heading: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  description?: string;
  onBackClick?: () => void;
  templateType: string;
  successHeading?: string;
  successNotice: string;
}
/**
 * This component is a reusable, customizable 'bulk upload' component.
 * Currently used by orgSkills and orgRoles. Should not be subbed for
 * orgBulkUpload yet, as requires resolve.
 *
 * @param resolve {SkillsBulkUploadResolveParams}
 */
@Component({
  selector: 'dgx-org-skills-bulk-upload',
  templateUrl: './org-skills-bulk-upload.component.html',
  styleUrls: ['./org-skills-bulk-upload.component.scss'],
})
export class OrgSkillsBulkUploadComponent implements OnInit {
  // Bindings
  @Input()
  public resolve: SkillsBulkUploadResolveParams;

  // Local
  public i18n: Record<any, string> = this.translate.instant([
    'Core_Back',
    'Core_Cancel',
    'Core_Upload',
    'bulkUpdateModal_UploadAFile',
    'bulkUpdateModal_SelectAFile',
    'bulkUpdateModal_CancelProcessing',
    'bulkUpdateModal_ExcelImported',
    'bulkUpdateModal_RowsFound',
    'bulkUpdateModal_FirstRow',
    'bulkUpdateModal_SuccessfulUpload',
    'bulkUpdateModal_FileName',
    'bulkUpdateModal_DownloadTemplate',
  ]);
  public fileId: number;
  public sheetName: string;
  public fileName: string;
  public rowsFound: number;
  public firstItemValue: string;
  public isFileUploaded: boolean = false;
  public uploadTo: string = '/organizations/bulkupload';
  public postObject = {
    uploadTemplateType: 'BulkTaxonomySkills',
  };
  // resolve properties
  public heading: string;
  public cancelButtonText?: string = this.i18n.Core_Cancel;
  public confirmButtonText?: string = this.i18n.Core_Upload;
  public description?: string;
  public onBackClick?: () => void;
  public templateType: string;
  public successNotice: string;

  constructor(
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    @Inject(WindowToken) private windowRef: Window,
    private orgService: OrgService,
    private modalService: ModalService
  ) {}
  public ngOnInit() {
    // set various props dynamically
    const {
      heading,
      cancelButtonText,
      confirmButtonText,
      description,
      onBackClick,
      templateType,
      successNotice,
    } = this.resolve;
    this.heading = heading;
    this.description = description;
    this.onBackClick = onBackClick;
    this.templateType = templateType;
    this.successNotice = successNotice;
    // fall back to default if undefined
    this.cancelButtonText = cancelButtonText
      ? cancelButtonText
      : this.cancelButtonText;
    this.confirmButtonText = confirmButtonText
      ? confirmButtonText
      : this.confirmButtonText;
  }

  // public methods
  public cancel() {
    return this.closeModal();
  }
  public cancelProcessing() {
    this.orgService.cancelBulkUpload(this.fileId).subscribe(() => {
      this.closeModal();
    });
  }
  public downloadTemplate() {
    this.windowRef.open(
      `/api/organizations/template?type=${this.templateType}`
    );
  }

  public preprocessingComplete({
    fileId,
    firstItemValue,
    rowsFound,
    sheetName,
    uploadFileName,
  }: FileUploadSuccessResult) {
    this.fileId = fileId;
    this.sheetName = sheetName;
    this.fileName = uploadFileName;
    this.rowsFound = rowsFound;
    this.firstItemValue = firstItemValue;
    this.isFileUploaded = true;
  }
  public upload() {
    if (this.isFileUploaded) {
      this.orgService.processBulkUpload(this.fileId).subscribe(() => {
        this.showSuccess();
      });
    }
  }
  // private methods
  public closeModal() {
    return this.activeModal.dismiss();
  }
  public showSuccess() {
    this.activeModal.close();
    const inputs = {
      heading: this.heading,
      notice: this.successNotice,
    };
    this.modalService.show(OrgSkillsBulkUploadSuccessComponent, {
      inputs,
    });
  }
}
