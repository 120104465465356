<div class="scrollable search-autocomplete guts-p-t-1">
  <div
    role="listbox"
    tabindex="0"
    [closeOnTab]="false"
    [attr.aria-label]="i18n.Core_Skills"
    class="search-autocomplete-suggestions"
  >
    <ng-container
      *ngFor="let typeVm of suggestions; trackBy: getItemTrackingKey"
      data-dgat="search-autocomplete-800"
    >
      <ul
        *ngIf="shouldShowType(typeVm)"
        role="group"
        attr.aria-labelledby="suggestions-label-{{ typeVm.trackingKey }}"
      >
        <!-- Type header -->
        <li
          *ngIf="typeVm.title !== 'Autosuggest'"
          [tabindex]="!!typeVm.allowNavigation ? 0 : -1"
          (click)="onTypeClick(typeVm)"
          id="suggestions-label-{{ typeVm.trackingKey }}"
          role="presentation"
          class="h4 border-bottom search-autocomplete-title"
          [ngClass]="{ 'cursor-pointer': !!typeVm.allowNavigation }"
        >
          {{ typeVm.title | translate }}
          <df-icon
            *ngIf="!!typeVm.allowNavigation"
            size="medium"
            icon="chevron-right"
            class="color-ebony-a61"
            role="presentation"
          ></df-icon>
        </li>
        <!-- Suggestions -->
        <li
          *ngFor="
            let suggestionVm of typeVm.subitems
              | slice: 0:limit(typeVm.title === 'Autosuggest');
            trackBy: getItemTrackingKey
          "
          role="none"
          class="search-autocomplete-suggestions"
          data-dgat="search-autocomplete-849"
        >
          <a
            role="option"
            (click)="onSuggestionClick(suggestionVm, typeVm.title)"
            (keydown)="
              keydownHandler(
                $event,
                onSuggestionClick.bind(this, suggestionVm, typeVm.title)
              )
            "
            class="listbox--option search-autocomplete-suggestions__suggestion"
            data-dgat="search-autocomplete-337"
          >
            <df-icon
              *ngIf="typeVm.title === 'Autosuggest'"
              size="medium"
              icon="magnifying-glass"
              class="search-autocomplete-suggestions__suggestion--icon"
              role="presentation"
            ></df-icon>
            <span>{{ suggestionVm.title }}</span>
            <span
              class="search-autocomplete-suggestions__suggestion--describer"
              >{{ describer(suggestionVm) }}</span
            >
          </a>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
