import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PathwayAuthorBaseDirective } from '@app/pathways/directives/pathway-author-base.directive';
import {
  PathwayActionSuccess,
  PathwayLevel,
} from '@app/pathways/rsm/pathway.model';
import {
  PathwayAuthoringStepMenuProperties,
  PathwayAuthoringStepMenuService as PathwayAuthoringStepMenuService,
} from '@app/pathways/services/reactive-pathway-services/pathway-authoring-step-menu.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { A11yService } from '@app/shared/services/a11y.service';
import { AutoSaveService } from '@app/shared/services/auto-save/auto-save.service';
import { DF_COLLAPSE_EXPAND, DF_SLIDE_FADE_IN_LEFT } from '@lib/fresco';
import {
  PathwayAuthoring,
  PathwayFacade,
  PathwaySubsection,
  hasAtLeastOneAuthoringStep,
  hasAuthoringStep,
} from '@dg/pathways-rsm';
import { EventBus } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

/**
 * A real or faux subsection.
 *
 * @see {PathwayAuthorBaseDirective}
 */
@Component({
  selector: 'dgx-pathway-author-subsection',
  templateUrl: './pathway-author-subsection.component.html',
  styleUrls: [
    '../pathway-subsections/pathway-subsections.component.scss',
    './pathway-author-subsection.component.scss',
  ],
  animations: [DF_COLLAPSE_EXPAND, DF_SLIDE_FADE_IN_LEFT],
})
export class PathwayAuthorSubsectionComponent
  extends PathwayAuthorBaseDirective
  implements AfterViewChecked
{
  @Input() public isFauxSubsection = false;
  @Input() public menuConfig: MenuViewModel[];
  @Input() public subsection: PathwaySubsection;
  @Output() public subsectionsAddStepClick = new EventEmitter<{
    node: string;
    afterNode: string;
    target: HTMLElement;
  }>();

  public readonly i18n = this.translate.instant([
    ...this.baseI18n,
    'Core_MoreOptions',
    'Pathways_DeleteSubsection',
    'Pathways_DeletedSubsection',
    'Pathways_MoveItem',
    'Pathways_MovedSubsection',
    'Pathways_ReorderItems',
    'Pathways_SubsectionDescriptionPlaceholder',
    'Pathways_SubsectionTitlePlaceholder',
    'Pathways_UntitledLesson',
  ]);

  public firstRender = true;

  constructor(
    private a11yService: A11yService,
    private changeDetectorRef: ChangeDetectorRef,
    private pathwayAuthoringStepMenuService: PathwayAuthoringStepMenuService,
    autoSaveService: AutoSaveService,
    authoring: PathwayAuthoring,
    eventBus: EventBus,
    facade: PathwayFacade,
    translate: TranslateService
  ) {
    super(autoSaveService, authoring, eventBus, facade, translate);
  }

  public ngAfterViewChecked(): void {
    this.firstRender = false; // used to prevent animations from happening during first render
    this.changeDetectorRef.detectChanges(); // this is needed to prevent the "ExpressionChangedAfterItHasBeenCheckedError" that was happening without it (see https://stackoverflow.com/a/45467987/441894)
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public hasStep = hasAuthoringStep;
  public hasAtLeastOneStep = hasAtLeastOneAuthoringStep;

  // ***************************
  // Emitters
  // ***************************

  /**
   * Pass the Add Content button click up the line.
   *
   * @param subsectionNode
   * @param afterNode
   * @returns
   */
  public onSubsectionAddStepClick(
    subsectionNode: string,
    afterNode: string | 'first',
    target: any // Angular annoyingly is not smart enough to realize that PathwayAddContentBetween/Button is sending only the target prop. So this needs to be 'any' for parsing.
  ) {
    this.subsectionsAddStepClick.emit({
      node: subsectionNode,
      afterNode,
      target: target as HTMLElement,
    });
  }

  // ***************************
  // Methods
  // ***************************

  /**
   * Create config for step menus
   *
   * @param properties
   */
  public createStepMenuConfig = (
    properties: PathwayAuthoringStepMenuProperties
  ): MenuViewModel[] => {
    return this.pathwayAuthoringStepMenuService.getPathwayAuthoringMenu(
      properties
    );
  };

  /**
   * Get the right interpolated translation for the item total.
   *
   * @param subsection
   */
  public itemTotalText(subsection: PathwaySubsection): string {
    return this.hasStep(subsection)
      ? this.translate.instant('Pathways_ItemCountSingular')
      : this.translate.instant('Pathways_ItemCountFormat', {
          count: subsection.steps?.length || 0,
        });
  }

  // ***************************
  // API Calls
  // ***************************

  /**
   * Reorder steps within current subsection.
   *
   * @param subsection
   */
  public async reorderSteps(subsection: PathwaySubsection) {
    const success = await this.authoring.showReorderEditor(
      PathwayLevel.STEP,
      subsection.node,
      subsection
    );

    if (success) {
      this.a11yService.announcePolite(
        this.translate.instant(PathwayActionSuccess.REORDERED_STEP)
      );
    }
  }
}
