<label [for]="for" [attr.aria-label]="ariaLabel" class="reactive-forms__label">
  <span class="reactive-forms__label-text">
    <ng-content></ng-content>
    <df-icon
      *ngIf="icon"
      size="small"
      class="par--light guts-m-b-quart"
      tabindex="0"
      role="text"
      [icon]="icon"
      [a11yText]="iconA11yText"
      [attr.aria-label]="iconA11yText"
      [ngbTooltip]="iconTooltip"
    ></df-icon>
  </span>
  <ng-container *ngIf="isRequired">
    <span class="reactive-forms__label--required">
      {{ 'Core_Required' | translate }}
    </span>
  </ng-container>
</label>
