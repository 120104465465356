import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Mapper } from '../../mapper.model';
import { CourseFormModel } from '../course-form.model';
import { CourseApiEntity } from '../repository/course.entity.model';

@Injectable({ providedIn: 'root' })
export class GlobalAddCourseMapper
  implements Mapper<Partial<CourseFormModel>, CourseApiEntity>
{
  constructor(private datePipe: DatePipe) {}
  public toApiParameters(source: CourseFormModel): CourseApiEntity {
    const courseEntity: CourseApiEntity = {
      ...source.input,
      inputId: source.inputId,
      inputType: 'Course',
      courseUrl: source.courseUrl,
      authored: source.authored || false,
      creditHours: source.input?.creditHours,
      units: source.units,
      unitType: source.unitType || 'Hours',
      dateCompleted: this.datePipe.transform(
        source.extent.dateCompleted,
        'yyyy-MM-dd'
      ),
      institutionId: source.institution
        ? source.institution.institutionId
        : null,
      institutionName: source.institutionName,
      name: source.course?.id ? source.course.label : source.courseName,
      description: source.description,
      tags: source.tags,
      verificationUrl: source.extent.verificationUrl,
      gradeId: source.extent.courseGrade,
      levelId: source.extent.courseLevel,
      organizationId:
        source.org !== null &&
        source.isPathwayBinAdd &&
        source.selectedOrg.id > 0
          ? source.org.organizationId
          : undefined,
      orgContentMetadata: undefined,
      owner: '',
      language: '',
      country: source.country,
      isAccredited: source.isAccredited,
      comment: source.comment,
    };

    return courseEntity;
  }
  public toViewModel(source: CourseApiEntity): Partial<CourseFormModel> {
    const target: Partial<CourseFormModel> = {
      inputId: source.inputId,
      courseUrl: source.courseUrl,
      courseName: source.name,
      courseTypeId: source.isAccredited ? 0 : 1, // Informal by default ( radio button )
      creditHours: source.creditHours,
      durationUnits: source.durationUnits,
      durationUnitType: source.durationUnitType,
      units: source.units,
      unitType: source.unitType,
      institutionName: source.institutionName,
      authored: source.authored || false,
      institution: {
        defaultUnitType: undefined,
        institutionId: source.institutionId,
        name: source.institutionName,
      },
      isAccredited: source.isAccredited ? source.isAccredited : false, // Informal by default ( radio button )
      country: source.country,
      extent: {
        dateCompleted: source.dateCompleted,
        verificationUrl: source.verificationUrl,
        courseLevel: source.levelId,
        courseGrade: source.gradeId,
      },
      input: {
        isAccredited: source.isAccredited ? source.isAccredited : false, // Informal by default ( radio button )
        accessible: source.accessible,
        orgContentMetadata: { ...source.orgContentMetadata },
        institutionId: source.institutionId,
        name: source.name,
        userInputId: source.userInputId,
        inputType: source.inputType,
        inputId: source.inputId,
        title: source.title,
        courseUrl: source.courseUrl,
        institutionName: source.institutionName,
        externalId: source.externalId,
        description: source.description,
        groupIds: source.groupIds,
        dateCompleted: source.dateCompleted,
        gradeId: source.gradeId,
        tags: source.tags,
        verificationUrl: source.verificationUrl,
        authored: source.authored || false,
        levelId: source.levelId,
        organizationId: source.organizationId,
        courseNumber: source.courseNumber,
        creditHours: source.creditHours,
        units: source.units,
        unitType: source.unitType,
        imageUrl: source.imageUrl,
      },
      tags: source.tags,
    };
    return target;
  }
}
