import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  Attribute,
  AttributeDataType,
  Predicate,
  PredicateOption,
  PredicateType,
} from '@app/automations/model';
import {
  getFollowingLabelKey,
  getOperatorFromPredicate,
  getPredicateValueMin,
  predicateIdToAdjustedType,
  validatePredicates,
} from '@app/automations/utils';
import { getDeepCopy } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType } from '@lib/fresco';
import { ComboboxComponent } from '@app/shared/components/combobox/combobox.component';
import { ModalService } from '@dg/shared-services';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { firstValueFrom } from 'rxjs';
import { AutomationsApiService } from '@app/automations/services/automations-api.service';
import { PredicateTemplateBase } from '../../predicate-templates/PredicateTemplateBase';
export const NUMERIC_DATE_OPERATORS = ['<ago', '>ago'];

@Component({
  selector: 'dgx-condition-form',
  templateUrl: './condition-form.component.html',
})
export class ConditionFormComponent
  extends PredicateTemplateBase
  implements OnInit
{
  @Input() public conditions: Predicate[];
  @Input() public isSaving: boolean;
  @Input() public orgId: number;

  @ViewChildren('attributesField')
  public attributesFields: QueryList<ComboboxComponent>;

  public conditionOptions = [];
  public attributes: Attribute[];

  @ViewChild('stepHeader')
  public stepHeader: ElementRef;
  public i18n = this.translate.instant([
    'Automation_AttributeValue_HelperText',
    'Automation_ConditionHelp',
    'Automation_ConditionsLimit',
    'Automation_DeleteConditions',
    'Automation_DeleteConditions_Confirmation',
    'Automation_IsOneOf',
    'Automation_IsNotOneOf',
    'Automation_RemoveEmptyCondition',
    'Automation_UserAttribute',
    'Automation_Warning_RequiredBeforeSaving',
    'Automation_UserConditions_Header',
    'BusinessRules_AddCondition',
    'BusinessRules_And',
    'BusinessRules_AttributeName',
    'BusinessRules_AttributeValue',
    'BusinessRules_BeyondDaysAgo',
    'BusinessRules_Condition',
    'BusinessRules_Conditions_Column',
    'BusinessRules_Contains',
    'BusinessRules_DoesNotEqual',
    'BusinessRules_Equals',
    'BusinessRules_GreaterOrEqual',
    'BusinessRules_GreaterThan',
    'BusinessRules_LesserOrEqual',
    'BusinessRules_LessThan',
    'BusinessRules_Operator',
    'BusinessRules_RemoveCondition',
    'BusinessRules_Value',
    'BusinessRules_WithinDaysAgo',
    'Core_Back',
    'Core_Cancel',
    'Core_Delete',
    'Core_FieldRequired',
    'Core_NoResults',
    'Core_Remove',
    'Core_Save',
    'Core_Loading',
    'RecommendationCtrl_SelectADate',
  ]);
  public localConditions: Predicate[];
  public warnings: string[] = [];
  public readonly PredicateOptionType = PredicateType;
  public readonly AttributeDataType = AttributeDataType;
  public readonly NotificationType = NotificationType;
  public readonly booleanOptions = [
    { name: 'True', value: 'True' },
    { name: 'False', value: 'False' },
  ];
  public readonly allOperatorOptions = {
    '=': {
      name: this.i18n.BusinessRules_Equals,
      value: '=',
    },
    '!=': {
      name: this.i18n.BusinessRules_DoesNotEqual,
      value: '!=',
    },
    contains: {
      name: this.i18n.BusinessRules_Contains,
      value: 'contains',
    },
    isOneOf: {
      name: this.i18n.Automation_IsOneOf,
      value: 'isoneof',
    },
    isNotOneOf: {
      name: this.i18n.Automation_IsNotOneOf,
      value: 'isnotoneof',
    },
    '>': {
      name: this.i18n.BusinessRules_GreaterThan,
      value: '>',
    },
    '>=': {
      name: this.i18n.BusinessRules_GreaterOrEqual,
      value: '>=',
    },
    '<': {
      name: this.i18n.BusinessRules_LessThan,
      value: '<',
    },
    '<=': {
      name: this.i18n.BusinessRules_LesserOrEqual,
      value: '<=',
    },
    '<ago': {
      name: this.i18n.BusinessRules_WithinDaysAgo,
      value: '<ago',
    },
    '>ago': {
      name: this.i18n.BusinessRules_BeyondDaysAgo,
      value: '>ago',
    },
  };
  public readonly numericDateOperators = NUMERIC_DATE_OPERATORS;

  public readonly operatorOptionsByType = {
    // These properties should match the values allowed in OrganizationAttribute.attributeDataType
    String: [
      this.allOperatorOptions['='],
      this.allOperatorOptions['!='],
      this.allOperatorOptions.contains,
      this.allOperatorOptions.isOneOf,
      this.allOperatorOptions.isNotOneOf,
    ],
    Number: [
      this.allOperatorOptions['='],
      this.allOperatorOptions['!='],
      this.allOperatorOptions.isOneOf,
      this.allOperatorOptions.isNotOneOf,
      this.allOperatorOptions['>'],
      this.allOperatorOptions['>='],
      this.allOperatorOptions['<'],
      this.allOperatorOptions['<='],
    ],
    DateTime: [
      this.allOperatorOptions['='],
      this.allOperatorOptions['!='],
      this.allOperatorOptions['>'],
      this.allOperatorOptions['>='],
      this.allOperatorOptions['<'],
      this.allOperatorOptions['<='],
      this.allOperatorOptions['<ago'],
      this.allOperatorOptions['>ago'],
    ],
    Bool: [this.allOperatorOptions['=']],
  };
  public readonly operatorsInfo = {
    allOperatorOptions: this.allOperatorOptions,
    operatorOptionsByType: this.operatorOptionsByType,
  };
  public readonly emptyCondition: Predicate = {
    fieldName: '',
    comparisonValue: [],
    comparisonOperator: '',
    predicateType: PredicateType.Attribute,
  };
  private readonly editableAttributes = [
    'ContentLanguage',
    'DisplayLanguage',
    'Email',
    'FirstName',
    'FullName',
    'LastName',
    'ManagerUniqueID',
    'PermissionRole',
    'JobRole',
    'Privacy',
  ];
  public constructor(
    private translate: TranslateService,
    private modalService: ModalService,
    private automationsService: AutomationsApiService
  ) {
    super();
  }
  public async ngOnInit() {
    this.attributes = await (
      await firstValueFrom(this.automationsService.getAttributes(this.orgId))
    ).payload;

    this.localConditions =
      this.conditions?.length > 0
        ? getDeepCopy(this.conditions)
        : [getDeepCopy(this.emptyCondition)];

    this.conditionOptions = this.attributes.map((c) => ({
      id: c.attributeName,
      title: c.attributeName,
    }));

    setTimeout(() => {
      this.stepHeader.nativeElement.focus();
    });
  }
  public setFieldName(event, index) {
    this.localConditions[index] = {
      ...this.localConditions[index],
      fieldName: event.id,
      comparisonValue: [],
      comparisonOperator: '=',
    };
  }
  public setOperatorValue(event, index) {
    this.localConditions[index].comparisonOperator = event.value;
  }
  public setDateValue(event, index) {
    const day = event.getDate();
    const month =
      event.getMonth() > 8
        ? event.getMonth() + 1
        : '0' + (event.getMonth() + 1);
    const year = event.getFullYear();

    this.localConditions[index].comparisonValue = [`${month}/${day}/${year}`];
  }
  public setComparisonValue(event, index) {
    const value = event.target ? event.target.value : event.value;

    this.localConditions[index].comparisonValue = [value];
  }

  public addPredicate() {
    this.localConditions.push(getDeepCopy(this.emptyCondition));
    setTimeout(() => {
      this.attributesFields
        .get(this.localConditions.length - 1)
        .inputElement.nativeElement.focus();
    });
  }
  public removePredicate(index) {
    this.localConditions.splice(index, 1);
    setTimeout(() => {
      this.attributesFields.get(index - 1).inputElement.nativeElement.focus();
    });
  }
  public isInvalidOperator(condition: Predicate): boolean {
    return this.saveAttempted && !condition.comparisonOperator;
  }
  public isInvalidValue(condition: Predicate): boolean {
    return (
      this.saveAttempted &&
      (condition.comparisonValue?.length === 0 || !condition.comparisonValue)
    );
  }

  public onSave(): void {
    this.saveAttempted = true;
    this.validatePredicates()
      ? this.save.emit({
          predicates: this.localConditions,
          predicateType: PredicateType.Attribute,
        })
      : (this.isSaving = false);
  }
  protected validatePredicates() {
    // Clear warnings to prep for validity messaging
    this.warnings = [];
    const isValid = validatePredicates(this.localConditions);
    if (isValid) {
      this.localConditions.forEach((c) => {
        if (
          c.comparisonOperator !== 'isoneof' &&
          c.comparisonOperator !== 'isnotoneof' &&
          c.comparisonValue?.length > 1
        ) {
          c.comparisonValue = [c.comparisonValue[0]];
        }
      });
    } else {
      this.addWarning(this.i18n.Automation_Warning_RequiredBeforeSaving);
    }
    return isValid;
  }

  public getConditionType(condition: Predicate): AttributeDataType {
    const attributeOptions = this.attributes.map((a) => ({
      id: a.attributeName,
      attributeDataType: a.attributeDataType,
    })) as PredicateOption[];

    const attributeType = predicateIdToAdjustedType(
      attributeOptions,
      condition.fieldName,
      this.numericDateOperators,
      condition.comparisonOperator
    );

    return attributeType;
  }
  public getOperatorOptions(condition: Predicate) {
    const attributeOptions = this.attributes.map((a) => ({
      id: a.attributeName,
      attributeDataType: a.attributeDataType,
    })) as PredicateOption[];

    return getOperatorFromPredicate(
      this.operatorsInfo,
      attributeOptions as PredicateOption[],
      condition.fieldName,
      condition.comparisonOperator
    );
  }

  /**
   * getFollowingLabel
   * @returns additional contextual labeling for days ago or days over
   */
  public getFollowingLabel = (condition): string | boolean => {
    const attributeOptions = this.attributes.map((a) => ({
      id: a.attributeName,
      attributeDataType: a.attributeDataType,
    })) as PredicateOption[];

    const labelKey = getFollowingLabelKey(
      attributeOptions as PredicateOption[],
      condition.fieldName,
      this.numericDateOperators,
      condition.comparisonOperator
    );

    return labelKey ? this.translate.instant(labelKey) : labelKey;
  };

  /**
   * getMin
   * @returns minimum where appropriate for number fields
   */
  public getMin = (condition): number | null => {
    const attributeOptions = this.attributes.map((a) => ({
      id: a.attributeName,
      attributeDataType: a.attributeDataType,
    })) as PredicateOption[];

    return getPredicateValueMin(
      attributeOptions as PredicateOption[],
      condition.fieldName,
      this.numericDateOperators,
      condition.comparisonOperator
    );
  };
  public getFormattedRemoveConditionString(condition): string {
    return this.translate.instant('Automation_RemoveCondition_Format', {
      value: `${condition.fieldName} ${condition.comparisonOperator} ${condition.comparisonValue}`,
    });
  }
  public castToDate(value): Date {
    return value as Date;
  }
  public castToNumber(value): number {
    return value as number;
  }
  public addWarning(warning) {
    if (!this.warnings.includes(warning)) {
      this.warnings.push(warning);
    }
  }
  public showAttributeWarning(condition: Predicate) {
    return this.editableAttributes.includes(condition.fieldName);
  }
  public getAttributeWarning(condition: Predicate) {
    return this.translate.instant(
      'Automation_EditableAttributeWarning_Format',
      { attribute: condition.fieldName }
    );
  }

  public deleteConditions() {
    const inputs: SimpleModalInputBindings = {
      canCancel: true,
      headerText: this.i18n.Automation_DeleteConditions,
      bodyText: this.i18n.Automation_DeleteConditions_Confirmation,
      submitButtonText: this.i18n.Core_Delete,
    };
    this.modalService.show(SimpleModalComponent, { inputs }).subscribe(() => {
      this.localConditions = [];
      this.save.emit({
        predicates: this.localConditions,
        predicateType: PredicateType.Attribute,
      });
    });
  }

  public updateAttributes(
    attributes: (string | number | Date)[],
    condition: Predicate
  ) {
    condition.comparisonValue = attributes;
  }
}
