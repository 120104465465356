import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { TitleCasePipe } from '@angular/common';
import { NotificationItemService } from './notification-item.service';

/** Represents a Completed Recommendation notification */
@Component({
  selector: 'dgx-completed-recommendation',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedRecommendationComponent extends NotificationItemComponentBase {
  constructor(
    cdr: ChangeDetectorRef,
    itemService: NotificationItemService,
    private titleCasePipe: TitleCasePipe
  ) {
    super(cdr, itemService);
  }

  protected buildTemplate() {
    const inputTypes = [
      'article',
      'video',
      'episode',
      'book',
      'assessment',
      'course',
      'event',
      'target',
      'pathway',
      'skill',
      'task',
      'post',
    ];
    const translationKey = inputTypes.includes(
      this.params.resourceType.toLowerCase()
    )
      ? `completedRecommendation_${this.titleCasePipe.transform(this.params.resourceType)}Format`
      : 'completedRecommendation_Format';
    const translationArgs = {
      nameHtml: this.userFullNameTemplate,
      resourceType: this.resourceTypeArgument,
    };

    return this.translateHtml(translationKey, translationArgs);
  }
}
