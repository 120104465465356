import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfLayoutModule } from '../utilities/layout/layout.module';
import { DfPopoverComponent } from './components/popover.component';
import { DfPopoverService } from './services/popover.service';
import { DfPopoverTriggerDirective } from './directives/popover-trigger.directive';

@NgModule({
  imports: [CommonModule, DfLayoutModule],
  declarations: [DfPopoverComponent, DfPopoverTriggerDirective],
  providers: [DfPopoverService],
  exports: [DfPopoverComponent, DfPopoverTriggerDirective],
})
export class DfPopoverModule {}
