import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
} from 'rxjs/operators';

/** Classes that want to manage loading state via @see loadWith can implement this to provide loading state feedback,
 * such as by binding a spinner to @see isLoading.
 */
export interface Loadable {
  isLoading: boolean;
}

/** A simple Observable wrapper used with @see Loadable implementations to robustly track loading state  */
export function loadWith<T>(
  observable: Observable<T>,
  loadable: Loadable
): Observable<T> {
  loadable.isLoading = true;
  return observable.pipe(
    finalize(() => {
      loadable.isLoading = false;
    })
  );
}

/** A specialized pipeable operator for constructing our standard minimalist API search behavior, which
 * includes debouncing and de-duplicating inputs and requiring at least two characters.
 * @param searchFunc The search function to chain with the input observable
 * @param filterFunc Pass in a function to override the default check (ie term.length >= 2)
 * @param debounceDuration Debounce time in ms to wait between input changes to reduce API chatter,
 * which defaults to 300.
 */
export function lazySearch<T>(
  searchFunc: (term: string) => Observable<T[]>,
  filterFunc: (term: string) => boolean = (term: string) => term.length >= 2,
  debounceDuration = 300
) {
  return (source: Observable<string>) => {
    return source.pipe(
      debounceTime(debounceDuration),
      distinctUntilChanged(),
      filter((term: string) => filterFunc(term)),
      switchMap((term: string) => searchFunc(term)),
      catchError((e) => {
        if (e) {
          console.error(e);
        }
        return of([] as T[]); // eat errors to keep stream alive for additional searches
      })
    );
  };
}
