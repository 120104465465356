import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ButtonType } from '@app/shared/temporary-types';
import { TranslateService } from '@ngx-translate/core';

/**
 * Used to add a footer to any modal.
 *
 * ```
 * // custom submit button *with* cancel button
 * // (**dismiss** must be defined)
 * <dgx-modal-footer
 *   [canCancel]="true"
 *   [useDefaultSubmitButton]="false"
 *   (dismiss)="onClose($event)"
 * >
 *   <button
 *     type="submit"
 *     df-button
 *     dfButtonType="primary"
 *   >
 *     {{ 'Core_Save' | translate }}
 *   </button>
 * </dgx-modal-footer>
 *
 * // custom submit button but no cancel button
 * <dgx-modal-footer [useDefaultSubmitButton]="false">
 *   <button
 *     type="submit"
 *     df-button
 *     dfButtonType="primary"
 *   >
 *     {{ 'Core_Save' | translate }}
 *   </button>
 * </dgx-modal-footer>
 * ```
 */
@Component({
  selector: 'dgx-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFooterComponent implements OnInit {
  // Bindings - Input
  /** Set to true to add a 'Cancel' button. To work, `dismiss` *must be defined*. */
  @Input() public canCancel = false;
  /** Additional classes for the cancel button. *Default `btn-passive--no-border font-regular`.* */
  @Input() public cancelButtonClasses? = 'btn-passive--no-border font-regular';
  /** Dgat for the default cancel button. */
  @Input() public cancelButtonDgat? = 'modalFooter-b7c';
  /** Translated text to display on the cancel button. *Default `Cancel`.* */
  @Input() public cancelButtonText? =
    this.translateService.instant('Core_Cancel');
  /** Can be passed in to prevent submissions until true. */
  @Input() public isSubmitDisabled = false;
  /** Shows a loading state on the submit button while true. */
  @Input() public isSubmitPending = false;
  /** What needs to be passed back when the modal closes, if any. */
  @Input() public item?: any;
  /** Dgat for the default submit button. */
  @Input() public submitButtonDgat? = 'simple-modal-c67';
  /** Type of dfButton to use for the submit button. *Default `primary`.* */
  @Input() public submitButtonType?: ButtonType = 'primary';
  /** Translated text to display on the submit button. *Default `Submit`.* */
  @Input() public submitButtonText? =
    this.translateService.instant('Core_Submit');
  /** Translated text to be read aloud by the submit button, giving further context to the button's action. *Default undefined.* */
  @Input() public submitButtonAriaLabel: string = undefined;
  /** Whether or not to use the default submit button. *Default `true`.* If false, be sure to supply a new submit button with your custom content. */
  @Input() public useDefaultSubmitButton = true;

  // - Output
  /** If using 'Cancel' button, this *must* be passed in and modal dismissal must be handled by the parent. */
  @Output() public dismiss = new EventEmitter<Event>();

  public submitButtonLoadingAriaLabel =
    this.translateService.instant('Core_Loading');

  constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    this.cancelButtonDgat ??= 'modalFooter-b7c';
    this.cancelButtonText ??= this.translateService.instant('Core_Cancel');
    this.submitButtonDgat ??= 'simple-modal-c67';
    this.submitButtonText ??= this.translateService.instant('Core_Submit');
    this.submitButtonType ??= 'primary';
  }

  /**
   * Pass Event to parent for logging.
   *
   * @param event - Mouse or keyboard event that triggered the method.
   */
  public onDismiss(event: Event): void {
    this.dismiss.emit(event);
  }
}
