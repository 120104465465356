import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { A11yService } from '@app/shared/services/a11y.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { Loadable, loadWith } from '@dg/shared-rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  NotificationService,
  NotificationsState,
} from '../../notification.service';
import { LDFlagsService } from '@dg/shared-services';
import { 
  DfIconSize,
  DfPlacement,
  DfPlacementArray
} from '@lib/fresco';

const MAX_MENU_NOTIFICATIONS = 10;
@Component({
  selector: 'dgx-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
})
export class NotificationMenuComponent
  extends SubscriberBaseDirective
  implements OnInit, Loadable
{
  @Input() iconSize: DfIconSize = 'medium';
  @Input() disablePopover: boolean = false;
  @Input() refreshIcon: boolean = false;
  @Input() placement: DfPlacement | DfPlacementArray = 'auto';
  @Input() hasNotificationsFormat: 'count' | 'dot' = 'count';

  public readonly maxTitleLines = 1;
  public readonly maxCommentLines = 2;

  public showNotifications = true; // assume we initially have notifications to show so they get fetched
  public state: NotificationsState;
  public hasRequiredLearning: boolean;
  public _isLoading = false;
  public assignedLearningLink =
    this.notificationService.getAssignedLearningLink();
  public newNotificationCountLabel: string;

  constructor(
    public notificationService: NotificationService,
    private translate: TranslateService,
    private a11yService: A11yService,
    private cdr: ChangeDetectorRef,
    private tracker: TrackerService,
    private ldFlagsService: LDFlagsService
  ) {
    super();
  }

  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(val) {
    this._isLoading = val;
    this.cdr.detectChanges();
  }

  public get newNotificationCount(): string {
    const count = this.state.newNotificationCount;
    return this.ldFlagsService.limitNotificationBadgeCount && count > 9
      ? '9+'
      : `${count}`;
  }

  public ngOnInit() {
    this.notificationService.notificationsState
      .pipe(this.takeUntilDestroyed())
      .subscribe((state) => {
        this.state = state;
        this.hasRequiredLearning = state.requiredLearningItemCount > 0;
        this.newNotificationCountLabel = this.translate.instant(
          'NotificationFeedCtrl_NotificationCountFormat',
          { notificationItemCount: this.state.newNotificationCount }
        );
        this.a11yService.announcePolite(this.newNotificationCountLabel);

        this.showNotifications = !!this.state.notifications?.length;
      });

    // Initially refresh notification counts so we can present the badge count
    this.notificationService.loadShallowCounts().subscribe();
  }

  public onIsOpenChange(isOpen: boolean) {
    if (isOpen) {
      // Load the initial notifications ourselves rather than delegating to the list,
      // so we can discover if there are any to show
      loadWith(
        this.notificationService.loadNotifications(0, MAX_MENU_NOTIFICATIONS),
        this
      ).subscribe(); // this will update the notificationsState observable above if there are changes
      this.tracker.trackEventData({ action: 'Notifications List Viewed' });
    } else {
      this.markAllAsRead();
    }
  }

  private markAllAsRead() {
    this.notificationService.markNotificationsRead().subscribe();
  }
}
