import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { LanguageLocale } from '@app/inputs/inputs.model';
import { StaticSettings } from '@app/profile-settings/profile-settings-api.model';
import { UserStaticSettingsService } from '@app/profile-settings/user-static-settings.service';
import { Option } from '@app/shared/components/select/select.component';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { A11yService } from '@app/shared/services/a11y.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ColorService } from '@app/shared/services/color.service';
import { ThumbnailService } from '@app/thumbnails/thumbnail.service';
import { LanguageService } from '@app/translation/services/language.service';
import {
  ProductHeaderService,
  ProductName,
} from '@app/layout/v1/product-header.service';
import { LDFlagsService } from '@dg/shared-services';
import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, take, tap } from 'rxjs';
import { WindowToken } from '../../../shared/window.token';
import { UtilityBarComponent } from '../utility-bar/utility-bar.component';

export interface NavLink {
  href?: string; // use href if using angular JS routing
  title: string;
  dgat: string;
  isVisible: () => boolean;
  isActive?: () => boolean;
  routerLink?: string[]; // Use router link if using NGX routing
}

@Component({
  selector: 'dgx-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
  animations: [DF_COLLAPSE_EXPAND],
})
export class ProductHeaderComponent
  extends SubscriberBaseDirective
  implements OnInit, AfterViewInit
{
  @Input() public context: ProductName;
  @Input() public navLinks: NavLink[] = [];
  @Input() public orgId?: number;
  @Input() public productImagePath?: string;
  @Input() public shouldHideCenterOrgLogo?: boolean = true;
  @Input() public shouldHideGlobalAdd?: boolean = false;
  @Input() public shouldHideGlobalSearch?: boolean = false;
  @Input() public shouldHideHomeLogo?: boolean = false;
  @Input() public shouldHideNotifications?: boolean = false;
  @Input() public shouldHideUtilityBar?: boolean = false;
  @Input() public shouldHideProductSwitcher?: boolean = false; // if true, we won't show the switcher, even if the product switcher has mutliple products.  Onboarding is the only current use case for this.
  @Input() public useNgxRouting: boolean = false; // This will trigger the links to be rendered as routerLinks.  The navLinks objects will need to have the routerLink property populated

  @ViewChildren('navigationLinks')
  public navigationLinks: QueryList<ElementRef>;
  @ViewChild('logos')
  public logosElement: ElementRef<HTMLElement>;
  @ViewChild('utilityBar')
  public utilityBar: UtilityBarComponent;

  public authUser: AuthUser;
  public brandColor: string;
  public brandLogoSrcset: {
    retina: string;
    orig: string;
  };
  public brandName: string;
  public degreedLogo: string;
  public hasLightText: boolean;
  public hideProductSwitcher: boolean;
  public isDegreedLearnerHome: boolean;
  public isInitializing: boolean = true;
  public isMobile: boolean = false;
  public navOpen: boolean = false;
  public orgInfo?: OrgInfo;
  public productImageSrc: {
    retina: string;
    orig: string;
  };
  public productImageSrcValue: string = '';
  public showBrandLogo: boolean;

  /** Show or hide the header based on the mobile query param */
  public showHeader = true;

  public DEFAULT_LANGUAGE = { name: 'English', lcid: 'en' };

  public data$: Observable<{
    personalSettings: StaticSettings;
    allLanguages: LanguageLocale[];
  }>;
  private personalSettings: StaticSettings;

  private navLinksWidth: number = 0;
  private maxLogoWidth: number = 0; // this is used to track the width of the logo images displayed.  this value can change as images are loaded
  private windowWidth: number;
  private utilitiesWidth: number = 0;

  constructor(
    private a11yService: A11yService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef,
    private colorService: ColorService,
    private thumbnailService: ThumbnailService,
    private translate: TranslateService,
    private productHeaderService: ProductHeaderService,
    @Inject(WindowToken) private windowRef: Window,
    private userStaticSettingsService: UserStaticSettingsService,
    private languageService: LanguageService,
    public ldFlagsService: LDFlagsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  public get userHasMultipleProducts() {
    return this.productHeaderService.multipleProductsAvailable;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (this.navOpen) {
      if (!target.classList.contains('js-appLogo-clicktarget')) {
        this.navOpen = false;
      }
    }
  }

  @HostListener('window:resize', ['$event']) public onResize(event) {
    this.windowWidth = event.currentTarget.innerWidth;
    this.setDropdownBehavior();
  }

  public ngAfterViewInit(): void {
    // Remove the product header placeholder that is added to the page via Views\Shared\_Layout.cshtml
    // TODO: Once we're no longer relying on the razor view to provide the page layout, this should be removed
    document.querySelector('#header-placeholder')?.remove();

    this.maxLogoWidth = this.logosElement?.nativeElement.clientWidth;
    this.windowWidth = this.windowRef.innerWidth;
    this.utilitiesWidth = this.utilityBar?.clientWidth;

    const navLinksArray = this.navigationLinks.toArray();

    // For some reason org nav is immediately populated and won't trigger the changes subscription, but home
    // is not populated on load and requires the changes subscription to populate.
    // TODO: figure out why the behavior is different and see if we can make them aligned that instead of this conditional
    // though not high priority since the UX refresh may not need this kind of solution
    if (navLinksArray.length > 0) {
      this.setNavLinksWidth();
    } else {
      // [PD-92872] Wait for navigationLinks to populate since we need to calculate the width of the nav section before setting the dropdown behavior.  Otherwise, the links could be hidden, and the width would be 0.
      // take(1) so it doesn't loop in keep checking and re-setting the dropdown config
      this.navigationLinks.changes.pipe(take(1)).subscribe(() => {
        // prevent NG0100 error 'Expression has changed after it was checked' by using setTimeout
        // otherwise errors in the case where the dropdown is shown on load because isMobile changes from false -> true
        // TODO: look into using afterNextRender instead after Angular upgrading to v16 [PD-93302]
        setTimeout(() => {
          this.setNavLinksWidth();
        });
      });
    }
  }

  public ngOnInit(): void {
    this.hideProductSwitcher =
      this.shouldHideProductSwitcher || !this.userHasMultipleProducts;
    this.authUser = this.authService.authUser;
    if (!!this.authUser) {
      this.orgInfo = this.orgId
        ? this.authUser.orgInfo.find((x) => x.organizationId === this.orgId) // for multi org users
        : this.authUser.defaultOrgInfo; // this could be null for consumer users
    }
    this.initCoBranding();
    this.productImageSrc = this.getProductImageThumbnail();

    this.authService.authUser$
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        if (!this.authUser && data?.defaultOrgInfo?.organizationBranding) {
          this.authUser = data;
          if (!!this.authUser) {
            this.orgInfo = this.orgId
              ? this.authUser.orgInfo.find(
                  (x) => x.organizationId === this.orgId
                ) // for multi org users
              : this.authUser.defaultOrgInfo; // this could be null for consumer users
          }
          this.initCoBranding();
          this.changeDetector.detectChanges();
        }
      });

    this.route.queryParams
    .pipe(this.takeUntilDestroyed())
    .subscribe((params) => {
      const mobile = params['mobile'];
      // Hide the controls if mobile=1
      this.showHeader = mobile !== '1';
    });

    this.ldFlagsService.skills.july2024MajorRelease &&
      this.getLanguageAndSettings();
    this.isInitializing = false;
  }

  private getLanguageAndSettings() {
    this.data$ = forkJoin({
      personalSettings: this.userStaticSettingsService.getPersonalSettings(),
      allLanguages: this.languageService.getSystemLanguages(),
    }).pipe(
      tap((data) => {
        this.personalSettings = data.personalSettings;
      })
    );
  }

  public onLanguageSelection($event: Option) {
    const localeId = $event;
    this.userStaticSettingsService
      .updatePersonalSettings({
        ...this.personalSettings,
        localeId,
      })
      .subscribe();
  }

  public isActiveLink(link: NavLink) {
    return link.isActive();
  }

  public handleImageLoad() {
    if (this.logosElement.nativeElement.clientWidth > this.maxLogoWidth) {
      this.maxLogoWidth = this.logosElement.nativeElement.clientWidth;
      this.setDropdownBehavior();
    }
  }

  public onLogoClick(event) {
    event.preventDefault();
    if (this.isMobile) {
      this.navOpen = !this.navOpen;
      this.changeDetector.detectChanges(); // we need to update the template so the navigationLinks reference will be updated
      if (this.navOpen) {
        this.navigationLinks.first.nativeElement.focus();
      }
    } else {
      // Do the navigation action of the first visible link in the array
      this.navigationLinks.first.nativeElement.click();
    }
  }

  /*
   * Public to aid in unit testing, as $rootscope.$watch/$on doesn't
   *  appear to be possible to properly test in hybrid apps
   */
  public setBrandingValues(event, brandObject) {
    this.hasLightText = brandObject.hasLightText;
    this.brandColor = brandObject.hexBrand;
    if (brandObject.orgImage) {
      this.brandLogoSrcset = this.thumbnailService.fetchProxyImageSrcset({
        imageSrc: brandObject.orgImage,
        imageHeight: 36,
        crop: 'fit',
      });
    }
    this.productImageSrc = this.getProductImageThumbnail();
    this.productImageSrcValue = `${this.productImageSrc.orig} 1x,
    ${this.productImageSrc.retina} 2x`;
    // TODO: Once the component to update the org branding has been upgraded to ngx, either refresh the authuser object there, or invalidate the client cached version of the auth user.
    // This simply allows us to get the newly updated org branding updated on the auth user on the next refresh.  Otherwise the old data persists.
    this.authService.clearAuth();
  }

  public onClickNavItem(event, url, tabName) {
    // PD-69645 Issue on right click because the href provided did not contain a link
    // if you're going to handle a link programmatically you still need a href, even if it's blank
    // for keyboard accessibility, which means you have to prevent the default behavior of the link
    // preventing default on click works for the original issue(see PD-68734 and PD-69249) with the href
    // routing from '/opportunities/browse' to '/opportunities'
    event.preventDefault();
    this.a11yService.announcePolite(
      this.translate.instant('A11y_TabChangeAnnouncement', {
        newPageTitle: tabName,
      })
    );
    window.open(url, '_self');
  }

  /**
   * Sets the value of the width of the navigation width for the header to be used to
   * calculate if the links need to collapse into the menu button or not based on the width
   * of the window.
   */
  private setNavLinksWidth(): void {
    const sum: number = this.navigationLinks
      .toArray()
      .reduce(
        (sum, curValue) =>
          sum + (curValue?.nativeElement?.parentElement?.clientWidth || 140),
        0
      );
    this.navLinksWidth = sum;
    this.setDropdownBehavior();
  }

  /**
   * Sets display behavior for the header based on the current width of the window.
   */
  private setDropdownBehavior() {
    this.isMobile = this.productHeaderService.displayAsMobile({
      windowWidth: this.windowWidth,
      navLinksWidth: this.navLinksWidth,
      logosWidth: this.maxLogoWidth,
      utilitiesWidth: this.utilitiesWidth,
    });
  }

  private initCoBranding() {
    const defaultBrandColor = this.colorService.getColor('white');
    const defaultHasLightText = false;

    this.brandColor = this.orgInfo?.organizationBranding?.brandColor
      ? this.orgInfo?.organizationBranding?.brandColor
      : defaultBrandColor;
    this.hasLightText = this.orgInfo?.organizationBranding?.useLightText
      ? this.orgInfo?.organizationBranding?.useLightText
      : defaultHasLightText;
    this.brandLogoSrcset = this.orgInfo?.image
      ? this.thumbnailService.fetchProxyImageSrcset({
          imageSrc: this.orgInfo.image,
          imageHeight: 36,
          crop: 'fit',
        })
      : null;
    this.brandName = this.orgInfo?.name;
    this.showBrandLogo = !!this.brandLogoSrcset;
    this.isDegreedLearnerHome =
      this.orgInfo?.organizationId == 1 && this.context === 'learner-home';
  }

  private getProductImageThumbnail() {
    return this.productImagePath
      ? this.thumbnailService.fetchProxyImageSrcset({
          imageSrc: this.productImagePath,
          imageHeight: 36,
          crop: 'fit',
        })
      : this.productHeaderService.getProductImageSrcset(
          this.context,
          this.hasLightText,
          this.orgInfo?.settings?.isClientProvider
        );
  }
}
