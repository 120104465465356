<button
  df-button
  dfButtonType="tag"
  df-button-small
  [attr.data-dgat]="dgatInput"
  [container]="container"
  [disableTooltip]="disableTooltip ? disableTooltip : !ratings?.length"
  [ngbTooltip]="tooltipContent"
  [ngClass]="pillClasses"
  [placement]="placement"
  [triggers]="triggers"
  (click)="onTagClick($event)"
  (shown)="onTooltipShown()"
  (hidden)="onTooltipHidden()"
  autoClose="outside"
  class="guts-m-v-quart guts-m-r-half break"
  tooltipClass="faux-popover"
  type="button"
>
  {{ tag.title }}
</button>

<ng-template #tooltipContent>
  <dgx-tag-rating-signal-popover-content
    [ratings]="ratings"
  ></dgx-tag-rating-signal-popover-content>
</ng-template>
