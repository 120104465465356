<dgx-modal
  [canCancel]="hideCancel ? false : true"
  [cancelButtonText]="cancelButtonText"
  [isHeaderBorderless]="!title || !description"
  [submitButtonText]="confirmButtonText"
  [useDefaultSubmitButton]="confirmButtonText ? true : false"
  (dismiss)="onDismiss()"
  submitButtonType="destructive"
  *ngIf="!useApolloStyles"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <ng-container *ngIf="description">{{ title }}</ng-container>
  </ng-container>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div
      *ngIf="description; else titleAsDescription"
      [innerHTML]="description"
    ></div>
    <ng-template #titleAsDescription>
      <h3 id="dialogTitle" class="h3 center-text">{{ title }}</h3>
    </ng-template>
  </ng-container>
</dgx-modal>

<div
  class="tw-max-w-72 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
  role="dialog"
  aria-labelledby="title"
  aria-describedby="description"
  *ngIf="useApolloStyles"
>
  <div
    class="tw-mb-8 tw-flex tw-flex-col tw-items-start tw-gap-6 md:tw-flex-row"
  >
    <div
      class="tw-flex tw-h-10 tw-min-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 tw-text-red-800"
    >
      <da-icon icon="exclamation-triangle" className="tw-size-6"></da-icon>
    </div>
    <div class="tw-flex tw-flex-col">
      <h4
        class="tw-mb-2 tw-font-extrabold tw-text-neutral-900"
        id="title"
        *ngIf="title"
      >
        {{ title }}
      </h4>
      <p id="description" *ngIf="description">
        {{ description }}
      </p>
    </div>
  </div>
  <div
    class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
  >
    <button
      class="tw-btn-medium tw-btn-secondary-filled"
      (click)="onDismiss()"
      *ngIf="cancelButtonText"
    >
      {{ cancelButtonText }}
    </button>
    <button
      class="tw-btn-medium tw-btn-destructive"
      (click)="onConfirm()"
      *ngIf="confirmButtonText"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</div>
