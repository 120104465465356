import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Custom date formatter for use in dgxReportingRangePicker component
 * NOTE: the js `Date` month is 0-based, NgbDateStruct month is 1-based
 */
@Injectable({ providedIn: 'root' })
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  constructor(private datePipe: DatePipe) {
    super();
  }

  /** Format the struct as a date */
  public format(ngbDate: NgbDateStruct | null): string {
    if (!ngbDate) return null;
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return this.datePipe.transform(date, 'MMM d y');
  }

  /**
   * Return a datepicker date object for a given JS Date
   * @param value
   */
  public getNgbDate(value: Date): NgbDate {
    return value
      ? new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate())
      : null;
  }

  /**
   * Return the Utc date number value for a given datepicker date
   * @param value
   */
  public getUtcDate(value: NgbDate): number {
    return value
      ? new Date(value.year, value.month - 1, value.day).getTime()
      : null;
  }

  /** Parse the given date as a ngb date struct with day forced to 1 */
  public parse(value: string | null): NgbDateStruct {
    if (!value) return null;
    const date = new Date(value);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
