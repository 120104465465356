import { AbstractControl, FormGroup } from '@angular/forms';
import { CourseEntry } from '@app/inputs/inputs-api.model';
import { focusOnFirstInvalidField } from '@app/user-content/user-input-v2/utils/form-field-helper';
import { Institution } from '@app/user-content/user-input/course-form/course-form.model';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * @deprecated - use {form-field-helper.handleFocusOnSubmit}
 */
export const handleCourseFocusOnSubmit = (
  formControls: {
    [key: string]: AbstractControl;
  },
  isSessionDetailsToggledOn = false
): void => {
  const objectEntries = Object.entries(formControls);
  const durationFormEntry = getDurationFormEntry(objectEntries);
  const sessionDetailsFormEntry = getSessionDetailsFormEntry(objectEntries);
  const controlObjectEntriesInDomOrder =
    getCourseControlObjectEntriesInDomOrder(
      objectEntries,
      durationFormEntry,
      sessionDetailsFormEntry,
      isSessionDetailsToggledOn
    );

  focusOnFirstInvalidField(
    controlObjectEntriesInDomOrder,
    durationFormEntry,
    sessionDetailsFormEntry
  );
};

/**
 * The durationForm field is technically a child component and FormGroup that is appended to the list of form controls.
 * We have to put the form controls in the order that they appear in the dom in order to shift focus
 * to the first field with a validation error upon submit of the form.
 */
const getCourseControlObjectEntriesInDomOrder = (
  objectEntries: [string, AbstractControl][],
  durationFormEntry: [string, AbstractControl],
  sessionDetailsFormEntry: [string, AbstractControl],
  isSessionDetailsToggledOn: boolean
): [string, AbstractControl][] => {
  const indexOfDescriptionEntry = objectEntries.indexOf(
    objectEntries.find((entry) => entry[0] === 'description')
  );
  const indexOfDurationFormEntry = objectEntries.indexOf(durationFormEntry);

  objectEntries.splice(indexOfDurationFormEntry, 1);
  if (isSessionDetailsToggledOn) {
    objectEntries.splice(
      indexOfDescriptionEntry + 1,
      0,
      durationFormEntry,
      sessionDetailsFormEntry
    );
  } else {
    objectEntries.splice(indexOfDescriptionEntry + 1, 0, durationFormEntry);
  }

  return objectEntries;
};

/**
 * Helper method to set the correct units
 * @param course
 * @param inputUnits
 * @param selectedInstitution
 */
export const getUnits = (
  course: CourseEntry,
  inputUnits: number,
  selectedInstitution: Institution
) => {
  return course.units || inputUnits || selectedInstitution?.defaultUnits;
};

/**
 * Helper method to set the correct unit type.
 * @param course
 * @param inputUnitType
 * @param selectedInstitution
 */
export const getUnitType = (
  course: CourseEntry,
  inputUnitType: string,
  selectedInstitution: Institution
) => {
  return (
    course.unitType || inputUnitType || selectedInstitution?.defaultUnitType
  );
};

/**
 * Transforms the currently selected date and time into a UTC string
 * @param date NgbDateStruct
 * @param time string
 * @returns string
 */
export const transformDateAndTimeToUTC = (
  date: NgbDateStruct,
  time: string,
  dateHandler: NgbDateParserFormatter
): string => {
  if (!date || typeof date === 'string' || !time) {
    return null;
  }
  const UTCDate = dateHandler.format(date);
  return `${UTCDate}T${time}`;
};

const getDurationFormEntry = (
  objectEntries: [string, AbstractControl][]
): [string, AbstractControl] =>
  objectEntries.find((entry) => entry[0] === 'durationForm');

const getSessionDetailsFormEntry = (
  objectEntries: [string, AbstractControl][]
): [string, AbstractControl] =>
  objectEntries.find((entry) => entry[0] === 'sessionDetails');
