<span
  class="field-group"
  [class.field-group--focus]="hasFocus"
  [attr.aria-label]="'A11y_CharacterLimit' | translate: { limit }"
>
  <span #content class="field-group__input">
    <ng-content select="input, textarea"></ng-content>
  </span>
  <span
    class="field-group--character-counter"
    [ngClass]="{ 'field-group--error': errorState }"
  >
    <div
      class="par par--small par--light"
      [class.l_flex-bottom]="isTextarea"
      [ngClass]="{ 'field-group--character-counter-div': isOnSettingsPageV2 }"
    >
      {{ text?.length || 0 }} / <b>{{ limit }}</b>
    </div>
  </span>
</span>
