import { TranslateService } from '@ngx-translate/core';

// ****************************************************************
// i18n Translation for Facades
// ****************************************************************

export type TranslateFn = (
  defaultValue: string,
  key: string,
  params?: Record<string, unknown>
) => string;

/**
 * Accessor to ngx-translate service lookups
 */
export function translateWithDefaults(
  translate: TranslateService,
  enableLog = false
): TranslateFn {
  return (
    defaultValue: string,
    key: string,
    params?: Record<string, unknown>
  ): string => {
    const found = params
      ? translate.instant(key, params)
      : translate.instant(key);

    if (enableLog && found === key)
      console.error(`i18n: Missing translation for key: ${key}`);

    return found && found !== key ? found : defaultValue;
  };
}
