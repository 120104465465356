<dgx-breadcrumbs [breadcrumbs]="breadcrumbs"></dgx-breadcrumbs>
<dgx-tracking-links-table
  *ngIf="!!links && !permissions.isTrackableLinksDisabled"
  [inviteUrl]="pathway.inviteUrl"
  [links]="links"
  [canManage]="permissions.canManagePathway"
  [isOrgContent]="isOrgContent"
  [currentSortBy]="currentSortBy"
  [ascend]="ascend"
  (onDelete)="handleDeleteLink($event)"
  (onEdit)="handleEditLink($event)"
  (onNew)="handleNewLink()"
  (onSort)="onSort($event)"
>
  <header class="guts-p-t-5 guts-p-b-1 guts-p-l-1">
    <h1 class="h1 break">{{ i18n.TrackableLinkCtrl_Title }}</h1>
  </header>
</dgx-tracking-links-table>
