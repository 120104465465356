/**
 * Shorter a large number into a human-readable string.
 *
 * @param input - The input. Should be a number.
 * @param decimals - The number of decimal places to round to. *Defaults to 0.*
 * @example
 * ```
 * shortenBigNumber(10000);
 * // 10k
 * ```
 */
export function shortenBigNumber(input: number, decimals = 0) {
  let exp: number;
  const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];

  if (window.isNaN(input)) {
    return null;
  }

  if (input < 1000) {
    return input;
  }

  exp = Math.floor(Math.log(input) / Math.log(1000));

  return (input / Math.pow(1000, exp)).toFixed(decimals) + suffixes[exp - 1];
}
