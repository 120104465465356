import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { AssessmentForm } from '../assessment-form.model';
import { AssessmentApiEntity } from '../repository/assessment.entity.model';
import { ImageUrlService } from '../../services/image-url.service';

@Injectable({ providedIn: 'root' })
export class ContentCatalogAssessmentMapper
  implements Mapper<any, AssessmentApiEntity>
{
  constructor(private imageUrlService: ImageUrlService) {}

  public toApiParameters(source: AssessmentForm): AssessmentApiEntity {
    const imageUrlData = source.imageUrl as any;
    const imageUrl = this.imageUrlService.getImageUrl(imageUrlData);

    //TODO: Cleanup
    // Currently groupIds is only getting set if the adv section of the form gets opened
    // Make sure it's set if it's not already set and something is in groups in order to not lose data.
    // group/groupId's needs a refactor so that we don't have to do this juggling of data
    // PD-79285 [Bug]
    if (!source.groupIds && source.groups) {
      source.groups = source.groupIds;
    }

    const assessmentEntity: AssessmentApiEntity = {
      inputType: 'Assessment',
      name: source.title,
      userProviderName: source.providerName,
      url: source.url,
      description: source.description,
      imageUrl,
      legacyPictureUrl: imageUrlData?.legacyPictureUrl,
      resourceImageId: imageUrlData?.resourceImageId,
      tags: source.tags,
      organizationId: source.organizationId,
      orgContentMetadata: {
        groupIds: source.groupIds ? source.groupIds : [],
        hideFromCatalog: false,
      },
      scenario: 'CMS',
      owner: source.owner,
      publishDate: source.publishDate,
      externalId: source.externalId,
      language: source.language,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,

      // edit only
      questions: source.questions,
      inputId: source.inputId,
      dateModified: source.dateModified,
      dateCreated: source.dateCreated,
      organizationName: source.organizationName,
      organizationName_Secure: source.organizationName_Secure,
      obsolete: source.obsolete,
      isCredSpark: source.isCredSpark,
      userCount: source.userCount,
    };

    return assessmentEntity;
  }
  public toViewModel(source: AssessmentApiEntity): Partial<AssessmentForm> {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    const target: Partial<AssessmentForm> = {
      ...source,
      fileManaged: source.fileManaged,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      groups,
      groupIds: groups,
      title: source.name,
      providerName: source.userProviderName,
      createdByName: source.createdByName ?? (source.createdBy as string),
    } as any;
    return target;
  }
}
