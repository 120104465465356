import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

// services
import { TranslateService } from '@ngx-translate/core';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { Resource } from '@app/comments/comments.model';
// types
import { CredSparkService } from '@app/cred-spark/services/cred-spark.service';
import { AuthService } from '@app/shared/services/auth.service';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { UserRecommendationType } from '@app/shared/models/core.enums';
import { RecommendationInfo } from '@app/shared/models/core-api.model';
import { RecommendationForUser } from '@app/recommendations/recommendations.api';
import { InputsService } from '@app/inputs/services/inputs.service';
import { ActivatedRoute } from '@angular/router';
import { InputTypeDetails, MediaEntry } from '@app/inputs/inputs-api.model';
import { Title } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// temporary reference to input types to allow parsing of input type in the URL
// should be refactored to use data from route config once we're off cshtml pages.
const pathSegments = [
  'article',
  'book',
  'video',
  'event',
  'course',
  'episode',
  'assessment',
  'post',
  'task',
];

@Component({
  selector: 'dgx-input-page',
  templateUrl: './input-page.component.html',
  styleUrls: ['./input-page.component.scss'],
})
export class InputPage implements OnInit {
  @Input() public isNativeApp: boolean;

  public showTakeaways: boolean;

  public canComment = this.authService?.authUser?.canComment;
  public inputResource: LearningResourceViewModel;
  public isCredSparkAssessment: boolean;
  public commentResource: Resource;
  public i18n = this.translateService.instant([
    'InputsController_AssessmentUnavailable',
    'CredSpark_MissingEmployeeId',
  ]);

  public credSparkMsg: string;
  public credSparkFormat: SafeHtml;
  public credSparkExternalId: string;
  public mediaEntry: MediaEntry;

  ////// POC - Related Content Recommendations
  public relatedContent: any[];

  constructor(
    private translateService: TranslateService,
    private recommendationsModalService: RecommendationsModalService,
    private recommendationsService: RecommendationsService,
    private authService: AuthService,
    private credSparkService: CredSparkService,
    private inputToLearningResourcePipe: InputToLearningResourcePipe,
    private inputsService: InputsService,
    private activeRoute: ActivatedRoute,
    private titleService: Title,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit() {
    const d = this.activeRoute.snapshot.queryParams.d;
    const inputType = this.activeRoute.snapshot.data.type;
    const r = this.activeRoute.snapshot.queryParams.r;

    this.inputsService
      .getInputTypeSpecificDetails(d, inputType, r)
      .subscribe((input: InputTypeDetails) => {
        this.titleService.setTitle(input.details.title);

        this.inputResource = this.inputToLearningResourcePipe.transform(
          input.details
        );

        this.inputResource.statistics = input.statistics;
        const recInfo = input.recommendation;

        if (recInfo) {
          const isRequired = this.recommendationsService.isRequired(
            this.recommendationsService.normalizeRecommendation(
              recInfo as unknown as RecommendationForUser
            )
          );

          let { recommendationType } = recInfo;
          if (recommendationType !== UserRecommendationType.Recommendation) {
            recommendationType = isRequired
              ? UserRecommendationType.RequiredLearning
              : UserRecommendationType.AssignedLearning;
          }

          this.inputResource.recommendationInfo = {
            ...recInfo,
            recommendationType,
          } as unknown as RecommendationInfo;
        }
        this.commentResource = {
          resourceId: input.details.inputId,
          resourceType: input.details.inputType,
          title: input.details.title,
        };

        this.showTakeaways =
          this.canComment &&
          !this.isNativeApp &&
          this.inputResource.model.isCompleted;

        // Commenting this out for now, it's currently being handled on the cshtml page

        if (input.details.inputType === 'Assessment' && input.isCredSpark) {
          this.handleCredSparkAssessment(input);
        }

        ////// POC - Related Content Recommendations
        if (this.inputResource.hasRelatedContent) {
          this.inputsService
            .getRelatedContentRecommendations({
              inputId: d,
              inputType: inputType,
              take: 3,
              userProfileKey:
                this.authService.authUser.viewerProfile.userProfileKey,
            })
            .subscribe((results: any) => {
              this.relatedContent = results.map((r) => r.reference);
              console.log(this.relatedContent);
            });
        }
      });
  }

  // TODO: Refactor opportunity -  All of this business with regexes and div's and having
  // to bypass angular security is probably not how we would do any of this if we were
  // building this from scratch.
  private handleCredSparkAssessment(input: InputTypeDetails) {
    // only do this step if credSparkFormat is available
    if (!input.credSparkFormat || input.credSparkFormat === '') {
      return;
    }

    // regex to get the script src so we can add it to the page manually (only way to get past browser security)
    const scriptSrcRegex = /<script.*?src="(.*?)"/gim;
    // regex to remove the script tag from the html that we want to insert into the page
    const stripScriptRegex =
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

    // pulls the script url from the string
    const url = scriptSrcRegex.exec(input.credSparkFormat)[1];

    this.isCredSparkAssessment = true;

    // allow the div to be used by angular
    this.credSparkFormat = this.sanitizer.bypassSecurityTrustHtml(
      input.credSparkFormat.replace(stripScriptRegex, '')
    );

    // get everything in place before we run the script
    setTimeout(() => {
      // add the script tag manually
      var scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', url);
      document.body.appendChild(scriptTag);
    });
  }

  @HostListener('window:message', ['$event'])
  @HostListener('window:onmessage', ['$event'])
  public onMessage(event) {
    const hasCredSparkData = event?.originalEvent?.data?.embeddedQuizFinished;
    if (hasCredSparkData) {
      // Call API, then display edit modal.
      this.credSparkService
        .getCredSparkAssessment(this.credSparkExternalId)
        .subscribe((assessment) => {
          // TODO: Call the new edit assessment modal - this service no longer exists
          // PD-80034 (to be done this sprint)

          // this.ajsUserInputFormService.edit(
          //   assessment.inputType,
          //   assessment.inputId,
          //   assessment.userInputId
          // );
          this.showTakeaways = true;
        });
    }
  }
  /**
   *
   * Until we're out of cshtml pages the inputs lose the context of the url routes because they're
   * not being loaded via the learner-experience layout <router-outlet></router-outlet>. We have to
   * manually do some things until that refactor is completed, in this case figure out the input
   * type based on the url, which we normally can get from the ngx route definitions.
   *
   * Editied to make sure we're not accidentally matching anything in the title
   */
  private urlToInputType(): string {
    return pathSegments.find((segment) => {
      const urlSegmentToMatch = window.location.pathname.split('/')[1]; // will match 'episodes' etc...
      return urlSegmentToMatch.includes(segment);
    });
  }
}
