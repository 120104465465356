import { Component, Input } from '@angular/core';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { OpportunityFlagsService } from '@app/opportunities/services/opportunity-flags.service';
import { ButtonType } from '@app/shared/temporary-types';
import { NotificationType } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-opportunity-close-modal',
  templateUrl: './opportunity-close-modal.component.html',
})
export class OpportunityCloseModalComponent {
  @Input() public bodyText? = '';
  @Input() public bodyNoSelectionText? = '';
  @Input() public bodySendNotificationText? = '';
  @Input() public canCancel = false;
  @Input() public headerText? = '';
  @Input() public opportunity?: Opportunity;
  @Input() public submitButtonType?: ButtonType = 'primary';
  @Input() public submitButtonText? = this.translate.instant('Core_Submit');

  public readonly NotificationType = NotificationType;

  public i18n = this.translate.instant([
    'Core_Optional',
    'Opportunities_Candidates_Email_RejectionMessage',
  ]);
  public notifyUnSelectedCandidates: boolean = false;
  public rejectionMessage: string;
  public showRejectMessageEmail =
    this.opportunityFlagsService.useRejectMessageEmail;

  constructor(
    private opportunityFlagsService: OpportunityFlagsService,
    private translate: TranslateService
  ) {}

  public toggleUnSelectedCandidatesNotification(): void {
    this.notifyUnSelectedCandidates = !this.notifyUnSelectedCandidates;
  }
}
