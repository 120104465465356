import { Component, Input, OnInit } from '@angular/core';
import {
  PathwayDetailsModel,
  PathwaySection,
  PathwaySubsection,
} from '@app/pathways/rsm/pathway-api.model';
import { toNumbers } from '@dg/pathways-rsm';
import { TranslateService } from '@ngx-translate/core';

interface Section {
  title: PathwaySection['title'];
  id: PathwaySection['id'];
  disabled: boolean;
  afterNode: PathwaySubsection['node'] | null;
  tempAfterNode: PathwaySubsection['node'] | null;
}

@Component({
  selector: 'dgx-pathway-subsection-move-modal',
  templateUrl: './pathway-subsection-move-modal.component.html',
})
export class PathwaySubsectionMoveModalComponent implements OnInit {
  @Input() public pathway: PathwayDetailsModel;
  @Input() public subsection: PathwaySubsection;

  public sectionList: Section[] = [];
  public readonly i18n = this.translate.instant([
    'Pathways_MoveLessonTo',
    'Core_SaveChanges',
  ]);

  public itemsToAdd: PathwaySubsection[];
  public moveToValue: Section['id'];
  public moveAfterNode: Section['afterNode'];
  public tempMoveAfterNode: Section['tempAfterNode'];
  public binCount: number;

  constructor(private translate: TranslateService) {}

  public ngOnInit() {
    this.pathway.levels.map((section) => {
      let heading = this.translate.instant('Pathways_SectionNumberFormat', {
        number: section.number,
      });
      if (section.title) {
        heading = heading + `: ${section.title}`;
      }
      const disabled = section.number === this.subsection.levelNumber;
      const afterNode: Section['afterNode'] =
        section.totalLessons < 1
          ? `${section.node}0/`
          : section.lessons[section.totalLessons - 1].node;

      let newNode = '';
      if (section.totalLessons < 1) {
        newNode = `${section.node}0/`; // = correctly turn `/sectionNode/` into `/sectionNode/0/`
      } else {
        // gotta grab the node of the last subsection to increment properly
        // (Node values do not update when sections and subsections are deleted.
        // If you added 10 subsections to a section, then deleted all but the last one,
        // that last subsection will have a node "index" of 10 (e.g., `/1/10/` for the
        // first subsection of the first section), not 1 (`/1/1/`).)
        const [sectionNumber, subsectionNumber] = toNumbers(
          section.lessons[section.lessons.length - 1].node
        );
        newNode = `/${sectionNumber}/${subsectionNumber + 1}/`;
      }
      const tempAfterNode: Section['tempAfterNode'] = newNode;

      this.sectionList.push({
        id: section.id,
        title: heading,
        disabled: disabled,
        afterNode: afterNode,
        tempAfterNode: tempAfterNode,
      });
    });
  }

  public select = (
    sectionId: Section['id'],
    afterNode: Section['afterNode'],
    tempAfterNode: Section['tempAfterNode']
  ) => {
    this.moveToValue = sectionId;
    this.moveAfterNode = afterNode;
    this.tempMoveAfterNode = tempAfterNode;
  };

  public trackBySectionId(_: number, { id }: Section) {
    return id;
  }
}
