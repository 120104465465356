import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Workday Skill Removed notification */
@Component({
  selector: 'dgx-workday-skill-removed',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkdaySkillRemovedComponent extends NotificationItemComponentBase {
  protected get usePeriodicRefresh() {
    return true;
  }

  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    if (!this.notification.parameters.skillName) {
      return this.translateHtml('workdaySkillRemoved_Plural', {
        skillsRemovedHtml: this.tagsRemovedMessageTemplate(),
        viewSkillHtml: this.viewSkillsTemplate(true),
      });
    } else {
      return this.translateHtml('workdaySkillRemoved_Single', {
        skillName: this.skillNameTemplate,
        viewSkillHtml: this.viewSkillsTemplate(),
      });
    }
  }
}
