import { NgModule } from '@angular/core';
import { DfInputDebounceDirective } from './input-debounce.directive';

@NgModule({
  imports: [],
  exports: [DfInputDebounceDirective],
  declarations: [DfInputDebounceDirective],
  providers: [],
})
export class DfInputDebounceModule {}
