<div
  class="rel"
  aria-live="polite"
  [ngClass]="{
    'form__field-valid': message && message.length == 0 && !isMutedMessage,
    'form__field-invalid': (message && message.length > 0) || isMutedMessage,
    'guts-p-h-2': pad
  }"
>
  <div *ngIf="message" class="form__invalid-msg ib rel" [@dfSlideFadeInLeft]>
    <df-icon size="small" icon="arrow-down-right"></df-icon>
    <span class="guts-p-l-half" [innerHtml]="message"></span>
  </div>
</div>
