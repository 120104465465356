import { Injectable } from '@angular/core';

// types
import { InputStatistics } from '@app/inputs/inputs-api.model';
import {
  ChannelBundleInput,
  ChannelBundleResource,
  ChannelBundleResourceDetails,
  ChannelBundleResourceUnformatted,
} from './channel-bundle.model';

@Injectable({
  providedIn: 'root',
})
export class ChannelBundleHelperService {
  constructor() {}

  /**
   * Strips unused properties and standardizes the format for a channel
   * bundle input to make it easier to work with elsewhere.
   *
   * @param {ChannelBundleResourceDetails} resource A resource with an input reference
   */
  public formatInput(
    resource: ChannelBundleResourceDetails
  ): ChannelBundleInput {
    if (!resource?.reference) {
      return;
    }

    const { inputId, inputType } = resource?.reference;

    return {
      inputId,
      inputType,
    };
  }

  /**
   * Formats an entire list of input resources to be associated with a channel bundle.
   *
   * @param {ChannelBundleResourceDetails[]} resources Takes a list of resources with input details
   */
  public formatInputs(
    resources: ChannelBundleResourceDetails[]
  ): ChannelBundleInput[] {
    if (!resources) {
      return [];
    }

    return resources.map((resource) => this.formatInput(resource));
  }

  /**
   * Strips unused properties and standardizes the format for a channel
   * bundle resource so that various types can be acted upon without
   * additional individual checks.
   *
   * @param {ChannelBundleResourceUnformatted} resource  A resource to be formatted
   * for adding to a bundle.
   */
  public formatResource(
    resource: ChannelBundleResourceUnformatted
  ): ChannelBundleResource {
    let { resourceId, resourceType } = resource;

    if (resource.resourceType === 'Target') {
      resourceId = resource.targetId || resource.resourceId;
      resourceType = 'Target';
    } else if (resource.referenceType) {
      // TODO: not sure if this is needed anymore, having some trouble
      // figuring out what this type actually is for-- content usually
      // has resourceId/resourceType, not referenceId/referenceType
      // might be for content that's already associated with a bundle
      // or something similar
      resourceId = resource.referenceId || resource.resourceId;
      resourceType = resource.referenceType;
    } else if (!resourceType) {
      // pathways are an exception that are just an id
      // so there are no properties on resource and we have to do this hack
      resourceId = resource as unknown as number;
      resourceType = 'Pathway';
    }

    return {
      resourceId,
      resourceType,
    };
  }

  /**
   * Formats an entire list of resources to be associated with a channel bundle.
   *
   * @param resources {ChannelBundleResourceUnformatted[]} List of resources of any valid type
   */
  public formatResources(
    resources: ChannelBundleResourceUnformatted[]
  ): ChannelBundleResource[] {
    if (!resources) {
      return [];
    }

    return resources.map((resource) => this.formatResource(resource));
  }

  /**
   * Matches inputs and their statistic values.
   *
   * TODO: this is very similar to the Inputs Service version-- should
   * look into seeing if we can combine them.  The difference
   * is mostly the depth of the object property, input.statistics vs
   * input.reference.statistics
   *
   * @param resources
   * @param statistics
   * @param comparer
   */
  public mapInputStatistics({
    resources,
    statistics,
    comparer,
  }: {
    resources: ChannelBundleResourceDetails[];
    statistics: InputStatistics[];
    comparer?: (resource, stat) => boolean;
  }): ChannelBundleResource[] {
    if (comparer === undefined) {
      comparer = (resource, stat) => {
        const type = resource.referenceType;
        const id = resource.referenceId;
        return type === stat.inputType && id === stat.inputId;
      };
    }

    return resources.map((resource: ChannelBundleResource) => {
      const statisticMatch = statistics.filter((stat) => {
        return comparer(resource, stat);
      })[0];
      const resourceCopy = { ...{}, ...resource };
      if (statisticMatch) {
        resourceCopy.reference = {
          ...resource.reference,
          ...{
            statistics: statisticMatch,
          },
        };
      }
      return resourceCopy;
    });
  }
}
