import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/** Monitors CSS transitions that are added by a transition provider, typically a component.
 * The provider adds transitions whose completion may be observed by a subscriber via the {@link end} Observable.
 */
@Injectable({
  providedIn: 'root',
})
export class TransitionMonitorService {
  public end: Observable<TransitionMonitorEvent> = new Subject<TransitionMonitorEvent>();
  private transitions = new Map<HTMLElement, TransitionType>();

  public addTransition(el: HTMLElement, transitionType: TransitionType) {
    this.transitions.set(el, transitionType);
    el.addEventListener(
      'transitionend',
      (sourceEvent: Event) => {
        this.transitions.delete(el);
        (this.end as Subject<TransitionMonitorEvent>).next({
          sourceEvent,
          transitionType,
        });
      },
      { once: true }
    );
  }

  public isTransitioning(transitionType: TransitionType) {
    if (this.transitions.size) {
      return (
        Array.from(this.transitions.values()).filter(
          (t) => t === transitionType
        ).length > 0
      );
    }
    return false;
  }
}

export interface TransitionMonitorEvent {
  sourceEvent: Event;
  transitionType: TransitionType;
}

export enum TransitionType {
  MenuOpen,
  MenuClose,
  // Add other transition types we need to monitor as needed
}
