import { Injectable } from '@angular/core';
import { ActionFeedback } from '@app/inputs/inputs-api.model';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';
import { JsonObject } from '@app/shared/models/core-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccomplishmentMappingToAPI } from '../accomplishment.model';

@Injectable()
export class AccomplishmentService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
    'InputsSvc_EditContentDuplicateError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public addAccomplishment(params: AccomplishmentMappingToAPI): Promise<void> {
    const request$ = this.post<void>('/useroutcomes/add', {
      ...params,
      details: params.details ? JSON.stringify(params.details) : undefined,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public updateAccomplishment(
    params: AccomplishmentMappingToAPI
  ): Promise<void> {
    const request$ = this.put<void>('/useroutcomes/update', {
      ...params,
      details: params.details ? JSON.stringify(params.details) : undefined,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }
}
