<dgx-modal
  [useDefaultSubmitButton]="false"
  [isHeaderBorderless]="false"
  [canCancel]="true"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <span [textContent]="i18n.TagsSvc_UseWorkdaySkillNames"></span>
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <df-local-notification
      [type]="NotificationType.warning"
      [text]="'TagsSvc_WorkdayCanonicalSkillConversionInstructions' | translate"
      size="small"
    ></df-local-notification>
    <form
      role="form"
      [formGroup]="form"
      class="form-wrap guts-p-l-1 guts-p-r-1 guts-p-t-1"
    >
      <df-form-root
        [form]="form"
        [model]="model"
        [fields]="fields"
        [options]="options"
      >
      </df-form-root>
    </form>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      type="button"
      df-button
      dfButtonType="primary"
      [ngClass]="{ is_disabled: saving }"
      [disabled]="saving"
      [textContent]="saving ? i18n.Core_Saving : i18n.OrgSkills_AddSkills"
      (click)="save()"
      data-dgat="add-tags-convert-canonical-modal-8e3"
    ></button>
  </ng-container>
</dgx-modal>

<ng-template #descriptionTemplate>
  <p class="par par--small par--light border-bottom guts-p-b-half">
    {{ i18n.TagsSvc_ConvertWorkdaySkillsDescription }}
  </p></ng-template
>

<ng-template #selectAllTemplate let-formControl="formControl" let-tag="params">
  <button
    type="button"
    class="h4 par par--small font-semibold color-blue guts-m-t-1-half"
    (click)="selectAllBoxes(showSelectAll)"
    data-dgat="add-tags-convert-canonical-modal-f5e"
  >
    <span class="ib-v-middle no-wrap">
      {{
        showSelectAll ? i18n.UserController_SelectAll : i18n.Core_UnselectAll
      }}
    </span>
  </button>
</ng-template>

<ng-template
  #canonicalTagCheckboxRow
  let-formControl="formControl"
  let-tag="params"
>
  <table class="guts-m-b-quart par par--small">
    <tr>
      <td class="v-top-children">
        <input
          type="checkbox"
          class="guts-m-r-1"
          [checked]="formControl.value"
          (change)="onControlValueChange(formControl, !formControl.value)"
          data-dgat="add-tags-convert-canonical-modal-8e3"
        />
      </td>

      <td>
        <span class="guts-m-r-1">
          {{ tag.tag }}
        </span>
      </td>
      <td>
        <df-icon
          icon="arrow-right"
          size="small"
          class="color-ebony-a25 guts-m-r-1"
        ></df-icon>
      </td>
      <td>{{ tag.canonicalTag }}</td>
    </tr>
  </table>
</ng-template>

<ng-template
  #canonicalTagCheckboxRow
  let-formControl="formControl"
  let-tag="params"
>
  <table class="guts-m-b-quart par par--small">
    <tr>
      <td class="v-top-children">
        <input
          type="checkbox"
          class="guts-m-r-1"
          [checked]="formControl.value"
          (change)="onControlValueChange(formControl, !formControl.value)"
          data-dgat="add-tags-convert-canonical-modal-8e3"
        />
      </td>

      <td>
        <span class="guts-m-r-1">
          {{ tag.tag }}
        </span>
      </td>
      <td>
        <df-icon
          icon="arrow-right"
          size="small"
          class="color-ebony-a25 guts-m-r-1"
        ></df-icon>
      </td>
      <td>{{ tag.canonicalTag }}</td>
    </tr>
  </table>
</ng-template>
