<dgx-modal
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  (dismiss)="dismiss()"
>
  <ng-container class="modal-header">{{ i18n.Core_AddToBundle }}</ng-container>
  <ng-container class="modal-body">
    <div class="guts-p-full-1">
      <div class="grid">
        <div class="grid__col-12 guts-p-b-0">
          <dgx-data-search
            [placeholder]="i18n.Channel_SearchByName"
            [searchTerm]="searchTerm"
            type="short"
            (doSearch)="searchMatchedBundles($event)"
          ></dgx-data-search>
        </div>
        <div class="grid__col-12">
          <div class="rel data-table--sortable-wrapper">
            <table *ngIf="bundleList" class="data-table data-table--sortable">
              <thead>
                <tr>
                  <th class="l_w-min" scope="col">
                    <input
                      df-checkbox
                      type="checkbox"
                      class="bulk-state-bundle-add"
                      [(ngModel)]="isBulkStateOn"
                      (change)="bulkChangeState()"
                      id="bulk-state"
                      [indeterminate]="indeterminate"
                      data-dgat="selectBundleModal-3b4"
                    />
                  </th>
                  <th scope="col" [innerHtml]="i18n.Channel_NameColumn"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let bundle of filteredBundleList"
                  data-dgat="selectBundleModal-4f2"
                >
                  <td>
                    <input
                      df-checkbox
                      type="checkbox"
                      [(ngModel)]="bundle.isSelected"
                      (change)="setBulkStateCheckboxStatus()"
                      data-dgat="selectBundleModal-914"
                    />
                  </td>
                  <td [innerHtml]="bundle.title"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      (click)="save()"
      [innerHtml]="i18n.Core_Add"
      data-dgat="selectBundleModal-8e9"
    ></button>
  </ng-container>
</dgx-modal>
