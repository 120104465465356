import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

/**
 * Link Button Component
 * This component is used to display a link action button with an icon
 *
 * @param buttonClass {string} (Optional) Default value is `btn-passive`.
 * @param displayText {string} Primary button's display text.
 * @param icon {string} Icon the be added in to the primary button to the left of the Title.
 * @param primaryAction Event emitter to pass the action to be taken back to the parent component.
 *
 * EXAMPLE USAGE:
 * @example
 * ```
 * <dgx-action-button-link
 *   [displayText]="displayText"
 *   (primaryAction)="showModal($event)"
 *   icon="plus"
 * >
 * </dgx-action-button-link>
 * ```
 */

@Component({
  selector: 'dgx-action-button-link',
  templateUrl: './action-button-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonLinkComponent {
  @Input() public buttonClass: string = 'link';
  @Input() public displayText: string;
  @Input() public icon: string;
  @Output() public primaryAction: EventEmitter<Event> = new EventEmitter();

  /**
   * Emit click event to parent
   */
  public buttonAction($event: Event) {
    this.primaryAction.emit($event);
  }
}
