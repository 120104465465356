import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TimeZoneService } from '@app/shared/services/time-zone.service';
import { SelectOption } from '../select/select.component';

/**
 * A time zone picker custom component which loads all current available timezones from Azure's Geolocation service. This component will accept the same properties as a normal input (eg. `placeholder`, `(change)`, etc) and will be updated with ng classes like a normal input (eg. `ng-invalid`, `ng-touched`, etc.)
 * @param dgatInput - string used as the `data-dgat` attribute of the `input` field
 * @param select - Output event which passes the selected IANA Time Zone string
 *
 * (Warning: `ngDefaultControl` directive is REQUIRED when implementing for `ngModel` to work as expected)
 *
 * @example
 * <dgx-time-zone-picker
 *   id="timeZone"
 *   name="timeZone"
 *   ngDefaultControl
 *   [(ngModel)]="session.timeZoneId"
 *   (select)="onChange()"
 *   [placeholder]="'Core_Select' | translate"
 *   [required]="true"
 *   dgatInput="abc-123"
 * ></dgx-time-zone-picker>
 */
@Component({
  selector: 'dgx-time-zone-picker',
  templateUrl: './time-zone-picker.component.html',
  styleUrls: ['./time-zone-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeZonePickerComponent implements OnInit, OnChanges {
  @Input() public dgatInput: string;
  @Input() public timeZoneId?: string;
  @Output() public select: EventEmitter<string> = new EventEmitter();

  // mimic <input ngmodel> controls
  @Input('id') public hostId;
  @Input() public placeholder: string;
  @Input() public required: boolean;
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  public disabled: boolean = false;
  public onChange: (value: string) => void;
  public onTouch: () => void;

  public isSearching: boolean = false;
  public timeZoneOptions: SelectOption[] = [];
  public selectedTimeZone: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private timeZoneService: TimeZoneService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  // set up ngControl.valueAccessor
  public writeValue(val: string) {
    if (!!val) {
      this.selectedTimeZone = val;
    }
  }
  public registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }
  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public ngOnInit(): void {
    this.ngControl?.control.updateValueAndValidity({ onlySelf: true });
    this.timeZoneService.getTimeZoneIds().subscribe((response) => {
      this.timeZoneOptions = response.map((tz) => {
        const tzOption = {
          id: tz,
          title: tz,
        };
        return tzOption;
      });
      if (!this.selectedTimeZone) {
        const userTz = this.timeZoneService.getBrowserTimeZone();
        this.ngControl?.control.setValue(userTz);
      }
      this.cdr.detectChanges();
    });
    // since we are passing the id attribute in to the input and you can only have 1 id per html page.
    this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.timeZoneId?.currentValue) {
      this.selectedTimeZone = changes.timeZoneId.currentValue;
    }
  }

  public onSelectItem(selectionTitle: string) {
    // gotta inform the form control model that it has changed
    this.ngControl?.control.setValue(selectionTitle);
    this.ngControl?.viewToModelUpdate(selectionTitle);

    this.select.emit(selectionTitle);
  }
}
