import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MarkdownService } from './services/markdown.service';
import { MarkdownToHtmlPipe } from './pipes/markdown-to-html.pipe';
import { MarkdownToPlaintextPipe } from './pipes/strip-markdown.pipe';
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { UploaderModule } from '@app/uploader/uploader.module';
import { TextareaService } from './services/textarea.service';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';

@NgModule({
  declarations: [
    MarkdownToHtmlPipe,
    MarkdownToPlaintextPipe,
    MarkdownEditorComponent,
  ],
  imports: [
    SharedModule,
    UploaderModule,

    //standalone components
    AutofocusDirective,
  ],
  exports: [
    MarkdownToHtmlPipe,
    MarkdownToPlaintextPipe,
    MarkdownEditorComponent,
  ],
  providers: [MarkdownService, TextareaService],
})
export class MarkdownModule {}
