<dgx-modal
  [canCancel]="true"
  [submitButtonText]="'Core_YesProceed' | translate"
  [isSubmitDisabled]="false"
  [useDefaultForm]="true"
>
  <ng-container class="modal-header"> </ng-container>
  <ng-container class="modal-body">
    <p>
      {{ 'Targets_MoveConfirmationMessage' | translate }}
    </p>
    <p class="color-destructive">
      {{ 'Core_CannotBeUndone' | translate }}
    </p>
    <p>
      {{ 'Core_WouldYouLikeToProceed' | translate }}
    </p>
  </ng-container>
</dgx-modal>
