import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { ImageUrlService } from '../../services/image-url.service';
import { CourseFormModel } from '../course-form.model';
import { CourseApiEntity } from '../repository/course.entity.model';
import { ScormInfo } from '@app/content-catalog/services/scorm.service';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { HostType } from '@app/content-hosting';

@Injectable({ providedIn: 'root' })
export class ContentCatalogCourseMapper
  implements Mapper<any, CourseApiEntity>
{
  constructor(
    private imageUrlService: ImageUrlService,
    private ldFlagsService: LDFlagsService
  ) {}

  public toApiParameters(source: CourseFormModel): CourseApiEntity {
    const imageUrlData = source.imageUrl as any;
    const imageUrl = this.imageUrlService.getImageUrl(imageUrlData);

    //TODO: Cleanup
    // Currently groupIds is only getting set if the adv section of the form gets opened
    // Make sure it's set if it's not already set and something is in groups in order to not lose data.
    // group/groupId's needs a refactor so that we don't have to do this juggling of data
    // PD-79285 [Bug]
    if (!source.groupIds && source.groups) {
      source.groups = source.groupIds;
    }

    const courseEntity: CourseApiEntity = {
      ...source.input,
      courseId: source.courseId,
      courseUrl: source.courseUrl,
      durationHours:
        !this.ldFlagsService.durationForCoursesEventsOther &&
        source.unitType === 'Hours'
          ? source.units
          : source.durationHours,
      durationMinutes: source.durationMinutes,
      hostedContentDetails: source.isScorm
        ? this.getScormHostedDetails(source.scormInfo)
        : source.hostedContentDetails,
      name: source.course?.id ? source.course.label : source.courseName,
      institutionId: source.institution?.institutionId,
      institutionName: source.institutionName,
      description: source.description,
      organizationId: source.org ? source.org.organizationId : undefined,
      obsolete: source.obsolete,
      owner: source.owner,
      externalId: source.externalId,
      inputId: source.inputId,
      inputType: 'Course',
      scenario: 'CMS',
      publishDate: source.publishDate,
      tags: source.tags,
      orgContentMetadata: {
        groupIds: source.groupIds,
        hideFromCatalog: false,
      },
      language: source.language,
      creditHours: source.creditHours,
      sessions: [{ ...source.sessionDetails }],
      imageUrl,
      resourceImageId: imageUrlData?.resourceImageId,
      units: source.units,
      unitType: source.unitType,
    };

    return courseEntity;
  }
  public toViewModel(source: CourseApiEntity): Partial<CourseFormModel> {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    const isScorm = source.hostedType === 'SCORM';

    const target: Partial<CourseFormModel> = {
      units: source.units,
      unitType: source.unitType,
      courseId: source.courseId,
      inputId: source.inputId,
      courseUrl: source.courseUrl,
      hostedContentDetails: !isScorm ? source.hostedContentDetails : null,
      fileManaged: source.fileManaged,
      scormInfo: isScorm
        ? {
            scormFileName: source.hostedContentDetails.fileName,
            scormCourseId: source.hostedContentDetails.fileId,
          }
        : null, // Update SCORM with hostedContentDetails
      isScorm,
      courseName: source.name,
      courseTypeId: source.isAccredited ? 0 : 1, // Informal by default ( radio button )
      creditHours: source.creditHours,
      description: source.description,
      sessionDetails:
        source.sessions?.length > 0 ? { ...source?.sessions[0] } : undefined,
      institution: {
        defaultUnitType: undefined,
        institutionId: source.institutionId,
        name: source.institutionName,
      },
      isAccredited: source.isAccredited ? source.isAccredited : false, // Informal by default ( radio button )
      country: source.country ? source.country : '',
      authoring: source.authored ? source.authored : false,
      extent: {
        dateCompleted: source.dateCompleted,
        verificationUrl: source.verificationUrl,
        courseLevel: source.levelId,
        courseGrade: source.gradeId,
      },
      imageUrl: source.imageUrl,
      input: {
        isAccredited: source.isAccredited ? source.isAccredited : false, // Informal by default ( radio button )
        accessible: source.accessible,
        orgContentMetadata: { ...source.orgContentMetadata },
        institutionId: source.institutionId,
        name: source.name,
        userInputId: source.userInputId,
        inputType: source.inputType,
        inputId: source.inputId,
        title: source.title,
        courseUrl: source.courseUrl,
        institutionName: source.institutionName,
        externalId: source.externalId,
        description: source.description,
        groupIds: source.groupIds,
        dateCompleted: source.dateCompleted,
        gradeId: source.gradeId,
        tags: source.tags,
        verificationUrl: source.verificationUrl,
        authored: source.authored,
        levelId: source.levelId,
        organizationId: source.organizationId,
        courseNumber: source.courseNumber,
        creditHours: source.creditHours,
        imageUrl: source.imageUrl,
      },
      owner: source.owner,
      publishDate: source.publishDate,
      externalId: source.externalId,
      language: source.language,
      scenario: source.scenario,
      durationHours:
        !this.ldFlagsService.durationForCoursesEventsOther &&
        source.unitType === 'Hours'
          ? source.units
          : source.durationHours,
      durationMinutes: source.durationMinutes,
      durationUnits: source.durationUnits,
      durationUnitType: source.durationUnitType,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      groups,
      groupIds: groups,
      tags: source.tags,
      createdByName: source.createdByName ?? (source.createdBy as string),
      hasBrokenUrl: source.hasBrokenUrl,
    };
    return target;
  }

  // TODO: Update the uploadResponse from UploadSection to return an UploadFile similar to Content Hosting files and use getContentHostedDetails instead
  private getScormHostedDetails(scormInfo: ScormInfo) {
    const details: HostedContentMetadata = {
      fileId: scormInfo.scormCourseId,
      fileName: scormInfo.scormFileName,
      fileSize: 0,
      fileType: '.zip',
      hostType: HostType.Scorm,
    };
    return details;
  }
}
