import { InjectionToken } from '@angular/core';

export interface NavigatorMockable extends Navigator {
  // NOTE: override the 'readonly' type as 'string' for testing
  userAgent: string;
}

export const NAVIGATOR = new InjectionToken<NavigatorMockable>('navigator');

export function navigatorProvider(): Navigator {
  return window.navigator;
}
