import { Injectable } from '@angular/core';
import { UserProfileSummary } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import {
  Mentorship,
  MentorshipStatus,
  MentorshipType,
} from '../mentoring-api.model';
import {
  MentorshipContactType,
  MentorshipStatusEnum,
  MentorshipTypeEnum,
} from '../mentoring.enums';

@Injectable({
  providedIn: 'root',
})
export class MentoringHelperService {
  public contactMethods = ['', 'Email', 'Phone', 'Chat', 'Meeting', 'Other'];
  private i18n = this.translate.instant([
    'Core_Accept',
    'Core_Cancel',
    'Core_Complete',
    'Core_Decline',
    'Core_InProgress',
    'Core_New',
    'Core_Pending',
    'Core_Remove',
  ]);

  constructor(private translate: TranslateService) {}

  /**
   * Returns the mentorship contact type as a string.
   *
   * @param id - The ID of the mentorship contact type.
   */
  public getMentorContactMethod(id = MentorshipContactType.None): string {
    return this.contactMethods[id] || '';
  }

  /**
   * Returns the mentorship contact type as a number.
   *
   * @param name - The (title-cased) name of the contact type.
   */
  public getMentorContactMethodId(name = ''): MentorshipContactType {
    return this.contactMethods.indexOf(name);
  }

  /**
   * Returns the strings for a specific mentorship status.
   *
   * @param status - The MentorshipStatus enum to update to.
   * @param mentorshipType Optional: MentorshipTypeEnum. The type of mentorship, only needed to determine requested.
   */
  public getMentorshipStatus(
    newStatus: MentorshipStatus,
    mentorshipType?: MentorshipType
  ): { menuLabel: string; pillLabel: string; pillClass: string } {
    switch (newStatus) {
      case MentorshipStatusEnum.None:
        return { menuLabel: '', pillLabel: '', pillClass: '' };
      case MentorshipStatusEnum.Requested:
        return {
          menuLabel: '', // Default set to: this.i18n.Core_Accept | this.i18n.Core_Decline,
          pillLabel:
            mentorshipType === MentorshipTypeEnum.Mentee
              ? this.i18n.Core_New
              : this.i18n.Core_Pending,
          pillClass: 'emphasize',
        };
      case MentorshipStatusEnum.Accepted:
        return {
          menuLabel: this.i18n.Core_Complete,
          pillLabel: this.i18n.Core_InProgress,
          pillClass: 'attention',
        };
      case MentorshipStatusEnum.Declined:
        return {
          menuLabel: this.i18n.Core_Remove,
          pillLabel: this.i18n.Core_Decline,
          pillClass: 'danger',
        };
      case MentorshipStatusEnum.Completed:
        return {
          menuLabel: this.i18n.Core_Remove,
          pillLabel: this.i18n.Core_Complete,
          pillClass: 'success',
        };
      case MentorshipStatusEnum.Archived:
        return {
          menuLabel: this.i18n.Core_Remove,
          pillLabel: 'Archived',
          pillClass: '',
        }; // TODO: Update i18n once 'Archived' status has been added
      default:
        console.error(
          'Something went wrong when requesting mentorship status.'
        );
        break;
    }
  }

  public getCurrentMentorship(
    mentorship: Mentorship,
    mentorshipType: MentorshipType
  ): UserProfileSummary {
    switch (mentorshipType) {
      case MentorshipTypeEnum.Mentee:
        return mentorship.mentee;

      case MentorshipTypeEnum.Mentor:
        return mentorship.mentor;
    }
  }
}
