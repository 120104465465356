import { Component, OnInit } from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { TargetsService } from '@app/shared/services/targets.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TeamFlagsService } from '@app/team/services/team-flags.service';
import { ProductName } from '@app/layout/v1/product-header.service';
import { NavLink } from '@app/layout/v1/product-header/product-header.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ignoreElements, of, tap } from 'rxjs';

@Component({
  selector: 'dgx-app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeader implements OnInit {
  public authUser?: AuthUser;
  public currentOrg?: OrgInfo;
  public homeUrl: string;
  public isMobile: boolean = false;
  public teamAvailable: boolean;
  public pageURL: string;
  public userOrgs?: OrgInfo[];
  public vanityUrl?: string;
  public brandLogoSrcset?: { retina: string; orig: string };
  public isSkillInventoryClient = false;
  public isSkillAnalyticsClient = false;
  public navLinks: NavLink[];
  public featuredPlanId: number;

  public i18n = this.translate.instant([
    'AppHeader_HomeTitle',
    'AppHeader_BrowseTitle',
    'AppHeader_OpportunitiesTitle',
    'AppHeader_ProfileTitle',
    'AppHeader_SkillCoachTitle',
    'AppHeader_FeaturedTitle',
    'AppHeader_AcademiesTitle',
  ]);

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private targetsService: TargetsService,
    private translate: TranslateService,
    private windowLayoutService: WindowLayoutService,
    private teamFlagsService: TeamFlagsService,
    private ldflagService: LDFlagsService
  ) {}

  public get context(): ProductName {
    return ProductName.LearnerHome;
  }

  public get isOnboarding(): boolean {
    if (window.location.href.indexOf('getstarted') < 0) {
      return false;
    }
    return true;
  }

  public get isNgxPasswordReset(): boolean {
    return window.location.href.indexOf('resetpassword') >= 0;
  }

  public ngOnInit(): void {
    // Do nothing if Onboarding
    // TODO: revisit when Layout.cshtml is removed, we shouldn't need this then.
    if (!this.isOnboarding) {
      this.isMobile = this.windowLayoutService.isMobile;

      this.initAuthUser();
      this.initNav().subscribe();
    }
  }

  public initAuthUser() {
    const authUser = this.authService.authUser;
    if (authUser) {
      this.vanityUrl = this.navigationService.paths.vanityUrl;
      this.userOrgs = authUser.orgInfo;
      this.currentOrg =
        this.userOrgs && this.userOrgs.length ? this.userOrgs[0] : undefined;
      this.authUser = authUser;
      this.isSkillInventoryClient = authUser.isSkillInventoryClient;
      this.isSkillAnalyticsClient = authUser.isSkillAnalyticsClient;
      if (this.isSkillInventoryClient || this.isSkillAnalyticsClient) {
        this.teamAvailable = authUser.isManager;
      } else {
        this.teamAvailable =
          authUser.isManager &&
          authUser.defaultOrgInfo.settings.enableTeamSpace &&
          (authUser.defaultOrgInfo.settings.skillCoachFullOrgAccess ||
            this.teamFlagsService.teamSpaceEnabled);
      }
    }
  }

  public initNav(): Observable<void> {
    this.pageURL = window.location.pathname;

    this.homeUrl = this.navigationService.paths.home;

    if (this.currentOrg) {
      return this.targetsService
        .getBrowseTarget(this.currentOrg.organizationId)
        .pipe(
          tap((target: { targetId: number }) => {
            this.featuredPlanId = target?.targetId;
            this.initNavLinks();
          }),
          ignoreElements()
        );
    }

    this.initNavLinks();
    return of();
  }

  private initNavLinks() {
    this.navLinks = [
      {
        title: this.i18n.AppHeader_HomeTitle,
        isVisible: () =>
          !this.isSkillAnalyticsClient && !this.isSkillInventoryClient,
        isActive: () => this.pageURL.indexOf('/dashboard') > -1,
        href: this.homeUrl,
        dgat: '_AppHeader-c13',
      },
      {
        title: this.i18n.AppHeader_FeaturedTitle,
        isVisible: () =>
          this.featuredPlanId &&
          !this.isSkillAnalyticsClient &&
          !this.isSkillInventoryClient,
        isActive: () =>
          this.featuredPlanId &&
          this.pageURL.indexOf(`/explore/${this.featuredPlanId}`) > -1,
        href: `/explore/${this.featuredPlanId}/`,
        dgat: '_AppHeader-f1c',
      },
      {
        title: this.i18n.AppHeader_OpportunitiesTitle,
        isVisible: () => this.authUser?.hasCareerMobility,
        isActive: () => this.pageURL.indexOf('/career') > -1,
        href: '/career/opportunities',
        dgat: '_AppHeader-opportunity',
      },
      {
        title: this.i18n.AppHeader_ProfileTitle,
        isVisible: () => !!this.authUser,
        isActive: () => {
          const result = !!this.authUser
            ? this.pageURL.indexOf(
                `/profile/${this.authUser.viewerProfile.profileUrl}`
              ) > -1
            : this.pageURL.indexOf('/index/1') > -1;
          return result;
        },
        href: !!this.authUser
          ? `/profile/${this.authUser.viewerProfile.profileUrl}`
          : `/${this.vanityUrl}/index/1#/overview`,
        dgat: '_AppHeader-33a',
      },
      {
        title: this.i18n.AppHeader_SkillCoachTitle,
        isVisible: () => this.teamAvailable,
        isActive: () =>
          this.pageURL.indexOf('/team') > -1 ||
          window.location.href.indexOf('&team') > -1,
        href: `/team`,
        dgat: '_AppHeader-83k',
      },
      {
        title: this.i18n.AppHeader_AcademiesTitle,
        isVisible: () => !!this.authUser && this.ldflagService.showAcademies,
        isActive: () => this.pageURL.indexOf('/academies') > -1,
        href: '/academies',
        dgat: '_AppHeader-92a',
      },
    ];
  }
}
