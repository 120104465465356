import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';

@Injectable({
  providedIn: 'root',
})
export class OrgEndorsedService {
  public static readonly defaultEndorsedImage =
    '~/content/img/org-endorsed-preview.png';
  public static readonly transformedImageUrlRoot =
    'https://img.degreed.com/image/fetch/h_48/';
  private _previewEndorsedImage: string;

  constructor(
    private webEnvironmentService: WebEnvironmentService,
    private authService: AuthService
  ) {}

  // Change this to change the endorsed image in the preview
  public get previewEndorsedImage(): string {
    return this._previewEndorsedImage || this._defaultEndorsedImage;
  }
  public set previewEndorsedImage(src: string) {
    this._previewEndorsedImage = src;
  }

  private get _defaultEndorsedImage(): string {
    return this.webEnvironmentService.getBlobUrl(
      OrgEndorsedService.defaultEndorsedImage,
      true
    );
  }

  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public getEndorsedSrc(organizationId: number, preview?: boolean): string {
    if (preview) {
      return this.previewEndorsedImage;
    } else if (this.authUser) {
      for (const org of this.authUser.orgInfo) {
        if (org.organizationId === organizationId) {
          return (
            org.endorsedImage && this.getTransformedImageUrl(org.endorsedImage)
          );
        }
      }
    }
  }

  private getTransformedImageUrl(imageSrc: string) {
    // TODO: This will always return a 48px high image which should be good for
    // retina displays, but is not necessary for regular screen, try using src-set.
    const src = this.webEnvironmentService.getBlobUrl(imageSrc);
    return `${OrgEndorsedService.transformedImageUrlRoot}${encodeURIComponent(
      src
    )}`;
  }
}
