<div class="guts-p-b-1" *ngIf="shouldShowNavTitle(pathway, hasFauxSection)">
  <h3 class="pathway-section-nav__title" tabindex="-1">
    {{ pathway.title }}
  </h3>
</div>

<div
  class="pathway-section-nav pathway-author-nav--no-border-bottom"
  *ngIf="hasAtLeastOneNonFauxSection(pathway)"
>
  <ol class="pathway-section-nav__sections">
    <li
      *ngFor="
        let section of pathway.levels;
        trackBy: trackById;
        let first = first;
        let last = last;
        index as sectionIndex
      "
      class="pathway-section-nav__item guts-p-full-1-half"
      [ngClass]="{
        'pathway-section-nav__item--border-bottom': !last
      }"
      data-dgat="pathway-author-nav-2b7"
    >
      <div class="l_flexbar">
        <div class="l_flex-grow">
          <a
            href="#section-{{ section.number }}"
            class="pathway-section-nav__section-menu--title l_flex"
            [title]="getSectionTitle(section.title)"
            target="_self"
            (click)="sectionClick($event, section)"
            [ngClass]="{
              'pathway-section-nav__item--active': isActiveSection(
                section.number
              )
            }"
            data-dgat="pathway-author-nav-3ef"
            [attr.aria-label]="
              getSectionTitleForAriaLabel(
                sectionIndex + 1,
                getSectionTitle(section.title),
                asString(section.progress)
              )
            "
          >
            <dgx-text-expander
              [content]="getSectionTitle(section.title)"
              [maxCollapsedLines]="2"
              [useReadMore]="false"
              class="pathway-author-nav--text-expander guts-m-r-1"
            ></dgx-text-expander>
          </a>
        </div>
        <dgx-menu
          [menuConfig]="createMenuConfig(section)"
          placement="bottom-right"
          [placementAdjustLeftRem]="1"
          [appendToBody]="true"
        >
          <button type="button" data-dgat="pathway-author-nav-e77">
            <df-icon
              icon="dots"
              a11yText="{{ i18n.Core_MoreOptions }}"
              class="color-ebony-a61"
            ></df-icon>
          </button>
        </dgx-menu>
      </div>

      <div
        class="l_flexbar"
        *ngIf="shouldShowSubsections(section, fauxSubsections)"
      >
        <ul class="guts-m-l-1-half">
          <li
            *ngFor="let subsection of section.lessons; trackBy: trackById"
            class="pathway-section-nav__sub-menu"
            data-dgat="pathway-author-nav-467"
          >
            <div class="guts-m-t-1">
              <a
                href="#section-{{ section.number }}-{{ subsection.number }}"
                [title]="getSubsectionTitle(subsection.title)"
                target="_self"
                (click)="subsectionClick($event, section, subsection)"
                [ngClass]="{
                  'pathway-section-nav__item--active': isActiveSubsection(
                    subsection.number
                  )
                }"
                data-dgat="pathway-section-nav-2b8"
              >
                <dgx-text-expander
                  [content]="getSubsectionTitle(subsection.title)"
                  [maxCollapsedLines]="2"
                  [useReadMore]="false"
                  class="pathway-author-nav--text-expander"
                ></dgx-text-expander>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ol>
</div>
<div
  class="
    pathway-section-nav
    guts-p-full-1-half
    center-text
    pathway-section-nav__item
  "
  [ngClass]="{
    'pathway-author-nav__add-section': hasAtLeastOneNonFauxSection(pathway)
  }"
>
  <button
    class="pathway-author-nav__add-section--text"
    data-dgat="pathway-author-nav-e5c"
    (click)="createSection()"
  >
    <div class="l_flexbar gap-full-half">
      <df-icon icon="plus"></df-icon>
      <div>
        {{
          hasAtLeastOneNonFauxSection(pathway)
            ? i18n.Pathways_AddSection
            : i18n.Pathways_CreateSection
        }}
      </div>
    </div>
  </button>
</div>
