/*
 *   Container for Groups search input field that auto populates group names
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import {
  GroupIdentifier,
  GroupPrivacy,
  GroupPrivacyLevel,
} from '@app/groups/group-api';
import { GroupService } from '@app/shared/services/group.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupSearchInputComponent } from '@app/shared/components/group-search/group-search-input/group-search-input.component';

@Component({
  selector: 'dgx-group-search',
  templateUrl: './group-search.component.html',
  styleUrls: ['./group-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSearchComponent {
  @Input() public groupPrivacyLevel?: GroupPrivacyLevel =
    GroupPrivacyLevel.Closed;
  @Input() public groups?: GroupIdentifier[] = [];
  @Input() public excludedPrivacyLevels?: GroupPrivacy[] = [];
  @Input() public isRequired = false;

  @Output()
  public onGroupSelection: EventEmitter<GroupIdentifier> = new EventEmitter();
  @Output()
  public onGroupRemove: EventEmitter<GroupIdentifier> = new EventEmitter();
  @Output() public onRemove: EventEmitter<GroupIdentifier> = new EventEmitter();
  @Output()
  public onGroupBlur: EventEmitter<GroupIdentifier> = new EventEmitter();

  @ViewChild(GroupSearchInputComponent)
  public groupSearchInput: GroupSearchInputComponent;

  public i18n = this.translate.instant([
    'dgGroupsEdit_AddGroups',
    'dgGroupsEdit_AddGroupsTooltip',
    'dgGroupsEdit_AddGroupsLabel',
    'dgGroupSearch_PlaceHolder',
    'OrgReportingCtrl_NoMatchingGroups',
  ]);

  public isNotFound$: Subject<boolean> = this.groupService.isNotFound$;

  constructor(
    private groupService: GroupService,
    private translate: TranslateService
  ) {}

  public search = (
    term: Observable<string>
  ): Observable<readonly GroupIdentifier[]> => {
    return term.pipe(
      switchMap((searchTerm: string) => {
        return this.groupService.search(of(searchTerm), this.groupPrivacyLevel);
      }),
      map(this.groupPrivacyFilter.bind(this))
    );
  };

  public handleGroupSelection($event: GroupIdentifier): void {
    this.onGroupSelection.emit($event);
  }

  public handleBlur(): void {
    this.onGroupBlur.emit();
  }

  public remove($event: GroupIdentifier): void {
    this.onGroupRemove.emit($event);
    this.groupSearchInput.groupSearchInput.nativeElement.focus();
  }

  public trackBy(index: number, item: GroupIdentifier): number {
    return item.groupId;
  }

  private groupPrivacyFilter(groups: GroupIdentifier[]): GroupIdentifier[] {
    return groups.filter(
      (group) =>
        !this.excludedPrivacyLevels?.includes((group as any).privacyLevel)
    );
  }
}
