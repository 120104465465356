<div class="family-empty">
  <h2 class="h3 family-empty__heading">
    {{ 'User_FamilyEmptyStateHeader' | translate }}
  </h2>
  <div class="collapse-white-space">
    <img
      class="family-empty__image"
      [alt]="'Core_Empty_Image_Alt' | translate"
      [src]="imgSrc1x | blobifyUrl: true"
      [srcset]="imgSrc2x | blobifyUrl: true"
    />
  </div>
</div>
