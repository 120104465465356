import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { filter, fromEvent, Subscription } from 'rxjs';

import { MentionItem, MentionUserMatch } from '@app/comments/comments.model';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import {
  MENTION_BASE_CLASS,
  MentionListComponent,
} from '../components/mention-list/mention-list.component';
import { stopEvent } from '../utils';

const SEARCH_TERM_MIN = 2;
const SEARCH_TERM_MAX = 30;

@Directive({
  selector: '[dgxMention]',
  standalone: true,
  // https://github.com/angular-eslint/angular-eslint/blob/main/packages/eslint-plugin/docs/rules/no-host-metadata-property.md <- deprecated rule
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    autocomplete: 'off',
  },
})
export class MentionDirective
  extends SubscriberBaseDirective
  implements OnChanges, OnInit
{
  @Input('dgxMention') public searchResults: MentionUserMatch[] = [];
  @Input() public alreadyMentioned: MentionItem[] = [];
  @Input() public orgId: number;
  /** Only used by groups. */
  @Input() public groupId?: number;
  /** Only used by groups. */
  @Input() public groupPrivacy?: GroupPrivacyLevel;

  @Output() public addMention: EventEmitter<MentionUserMatch> =
    new EventEmitter();
  @Output() public search: EventEmitter<string> = new EventEmitter();

  private element!: HTMLTextAreaElement;
  private isSearching = false;
  private mentionList: MentionListComponent | null = null;
  private mentionListItemClick$: Subscription;
  private startIndex!: number;

  constructor(
    private elementRef: ElementRef<HTMLTextAreaElement>,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.element = this.elementRef.nativeElement;
    this.groupId =
      this.groupPrivacy === GroupPrivacyLevel.Open ? undefined : this.groupId;

    fromEvent(this.element, 'input')
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => this.onInput());

    fromEvent(this.element, 'keydown')
      .pipe(
        filter(({ key }: KeyboardEvent) => key !== 'Shift'),
        this.takeUntilDestroyed()
      )
      .subscribe((event) => this.onKeydown(event));

    fromEvent(this.element, 'blur')
      .pipe(
        filter(
          (event: FocusEvent) =>
            event instanceof FocusEvent &&
            // relatedTarget = the target focus is moving to. Will sometimes be
            // null. If we're focusing one of our list items (by clicking on them),
            // we don't want to stopSearch.
            !(event.relatedTarget as HTMLElement)?.id.startsWith(
              MENTION_BASE_CLASS
            )
        ),
        this.takeUntilDestroyed()
      )
      .subscribe((event: FocusEvent) => this.onBlur(event));
  }

  public ngOnChanges({ searchResults }: SimpleChanges): void {
    if (!searchResults || searchResults.firstChange) {
      return;
    }

    this.updateMentionListItems(this.searchResults);

    // Stop search when the list is still showing but there are now
    // no results.
    if (!this.mentionList?.isHidden && this.searchResults.length === 0) {
      this.stopSearch();
    }
  }

  public onBlur(event: FocusEvent): void {
    stopEvent(event);
    this.stopSearch();
  }

  /**
   * We want to stop the search when the textarea is actively emptied while searching.
   * We want to only perform searches with at least SEARCH_TERM_MIN characters.
   * We want to limit search strings to SEARCH_TERM_MAX, and not include as part of
   * the search term anything after the next @ symbol, or any search terms that start
   * with `@[`, which would be an old mention that's already correctly formatted.
   */
  public onInput(): void {
    // Stop search when there are results but the textarea is empty.
    if (
      this.element.value === '' &&
      this.isSearching &&
      !!this.searchResults?.length
    ) {
      return this.stopSearch();
    }

    if (!this.isSearching) {
      return;
    }

    let endIndex = this.element.value.length;

    if (endIndex < this.startIndex) {
      return this.stopSearch();
    }

    if (endIndex - this.startIndex > SEARCH_TERM_MAX) {
      endIndex = this.startIndex + SEARCH_TERM_MAX;
    }

    let searchTerm = this.element.value.substring(this.startIndex, endIndex);

    if (searchTerm.startsWith('@[')) {
      return this.stopSearch();
    }

    if (searchTerm.startsWith('@')) {
      searchTerm = searchTerm.substring(1);
    }

    // If there's *another* @ in the search term, we want to trim it to that point.
    const newEndIndex = searchTerm.indexOf('@');
    // Won't be 0 at this point so we just have to check for -1.
    if (newEndIndex !== -1) {
      searchTerm = searchTerm.substring(0, newEndIndex);
    }

    // Don't search for terms under our limit.
    if (searchTerm.length < SEARCH_TERM_MIN) {
      return;
    }

    this.startSearch(searchTerm);
  }

  public onKeydown(event: KeyboardEvent): void {
    // Catch @ characters in the middle of the textarea as well as at the end.
    // Doesn't work for mentions that were pasted directly in, but that's a pretty
    // common limitation for mentions, and the performance tradeoff of constantly
    // being in 'search' mode is NOT worth it.
    if (event.key === '@') {
      this.startIndex = this.element.selectionStart;
      this.isSearching = true;
      return;
    }

    if (!this.isSearching || !this.mentionList || this.mentionList.isHidden) {
      return;
    }
    switch (event.key) {
      case 'Tab':
      case 'Enter':
        stopEvent(event);
        this.onMentionSelect();
        break;
      case 'Escape':
        stopEvent(event);
        this.stopSearch();
        break;
      case 'ArrowDown':
      case 'Down':
        stopEvent(event);
        this.mentionList.activateNextItem();
        break;
      case 'ArrowUp':
      case 'Up':
        stopEvent(event);
        this.mentionList.activatePreviousItem();
        break;
    }
  }

  private onMentionSelect(): void {
    if (!this.mentionList || this.mentionList.isHidden) {
      return;
    }

    const startIndex = this.startIndex;
    const cursorPosIndex = this.element.selectionStart;

    const newMention = this.searchResults[this.mentionList.activeIndex];

    // `@searchterm` --> `@[vanityUrl](User's Name)`.
    const formattedMention = `@[${newMention.vanityUrl}](${newMention.name.trim()})`;
    let formattedMentionLength = formattedMention.length;

    let newValue =
      this.element.value.substring(0, startIndex) + formattedMention;
    // If we're in the middle of the textarea instead of at the end, then add
    // the rest of the textarea content, from current cursor position to end.
    // Otherwise, add a space to the end of our input.
    if (cursorPosIndex < this.element.value.length) {
      newValue += this.element.value.substring(cursorPosIndex);
    } else {
      newValue += ' ';
      formattedMentionLength += 1;
    }
    this.element.value = newValue;

    this.element.focus();

    const newCursorPosIndex = startIndex + formattedMentionLength;
    this.element.setSelectionRange(newCursorPosIndex, newCursorPosIndex);
    // Alert our textarea that is has been changed. Important when at the end of content!
    this.element.dispatchEvent(new Event('input'));

    if (
      !this.alreadyMentioned.find(
        (mention) => mention.userProfileKey === newMention.userProfileKey
      )
    ) {
      this.addMention.emit(newMention);
    }

    this.stopSearch();
  }

  private showMentionList(): void {
    if (this.mentionList === null) {
      const componentRef =
        this.viewContainerRef.createComponent<MentionListComponent>(
          MentionListComponent
        );

      this.mentionList = componentRef.instance;
    }

    // We call this every time we showMentionList because it repositions
    // the list.
    this.mentionList.initializeList([], this.element);

    if (!this.mentionListItemClick$) {
      this.mentionListItemClick$ = this.mentionList.mentionClicked
        .pipe(this.takeUntilDestroyed())
        .subscribe(() => {
          this.onMentionSelect();
        });
    }
  }

  private startSearch(searchTerm = ''): void {
    this.search.emit(searchTerm);

    this.showMentionList();
  }

  private stopSearch(): void {
    if (!this.isSearching) {
      return;
    }

    this.isSearching = false;

    if (this.mentionList) {
      this.mentionList.clearList();
    }
  }

  private updateMentionListItems(mentions: MentionUserMatch[] = []): void {
    if (!this.mentionList) {
      return;
    }

    this.mentionList.updateList(mentions, mentions.length === 0);
  }
}
