import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dgx-org-faq-view',
  templateUrl: './org-faq-view.component.html',
})
export class OrgFaqViewComponent implements OnInit {
  public orgId: number;

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private translate: TranslateService
  ) {
    this.orgId = this.authService.authUser.defaultOrgInfo.organizationId;
    this.titleService.setTitle(
      `${this.translate.instant('OrganizationsController_FAQ')} | Degreed`
    );
  }

  public ngOnInit(): void {}
}
