import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { DgError } from '@app/shared/models/dg-error';

@Injectable({
  providedIn: 'root',
})
export class UserEmailAddressesService {
  constructor(
    private http: NgxHttpClient,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public validate(emailAddress, organizationId) {
    return this.http
      .put('/user/validateemailaddress', {
        emailAddress: emailAddress,
        organizationId: organizationId,
      })
      .pipe(
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.translate.instant(
                'UserEmailAddressesSvc_ErrorValidatingEmail'
              ),
              error
            )
          );
        })
      );
  }

  public submitForVerification(
    emailAddress,
    organizationId,
    referringUserKey,
    isNewInviteUser
  ) {
    return this.http
      .put('/user/confirmemailaddress', {
        emailAddress: emailAddress,
        organizationId: organizationId,
        referKey: referringUserKey,
        isNewInviteUser: isNewInviteUser,
      })
      .pipe(
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.translate.instant(
                'UserEmailAddressesSvc_ErrorValidatingEmail'
              ),
              error
            )
          );
        })
      );
  }
}
