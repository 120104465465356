import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';
import { DateRangeService } from '@app/shared/services/date-range.service';
import { HighchartsService } from '@app/shared/services/highcharts.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ReportingService } from './../services/reporting.service';
import { Option } from '@app/shared/components/select/select.component';

export type ChartType =
  | 'views'
  | 'enroll'
  | 'completes'
  | 'totalviews'
  | 'enrollment'
  | 'users';

export type DropdownType = 'all' | ChartType;
interface TypeFormData {
  options: Option[];
  selected: Option;
}

interface ChartData {
  colors: any;
  series: any;
  labels: any;
  extraLabels: any;
  hasData: any;
}
@Component({
  selector: 'dgx-activity-insights',
  templateUrl: './activity-insights.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityInsightsComponent implements OnInit, OnChanges {
  @Input() public orgId: number;
  @Input() public filterOrgId?: number | undefined;
  @Input() public opportunityId?: number | undefined;
  @Input() public pathwayId?: number | undefined;
  @Input() public planId?: number | undefined;
  @Input() public groupId?: number | undefined;
  @Input() public hasExternalProvider?: boolean | undefined;

  public chartName: string;
  public context: string;
  public colors: Record<ChartType, string> = {
    views: this.colorService.getColor('ocean'),
    enroll: this.colorService.getColor('pink'),
    completes: this.colorService.getColor('orange-medium'),
    totalviews: this.colorService.getColor('ocean'),
    users: this.colorService.getColor('fuchsia'),
    enrollment: this.colorService.getColor('orange'),
  };
  public isLoading = true;
  public hasData = false;
  public noDataImage = '/content/img/emptystate/profile-settings.svg';
  public formData = {
    dataType: undefined as TypeFormData,
    dateRange: this.dateRangeService.getPastDaysExcludeTodayUTC(30),
  };
  public activeLegend: { Color: string; Label: string }[];
  public i18n = this.translate.instant([
    'Core_Completions',
    'Core_Enrolls',
    'Core_Enrollment',
    'Core_Follows',
    'Core_Interested',
    'Core_ViewAll',
    'Core_Views',
    'Core_NotAvailableForGroup',
    'KPI_OpportunityApplyName',
    'PathwayActivity_PathwayTitle',
    'PathwayActivity_PlanTitle',
    'ReportingContentSvc_NotEnoughActivity',
    'ReportingContentSvc_ReportsNotAvailable',
    'PathwayActivity_Users',
    'Core_Date',
    'PathwayActivity_SelectionAriaLabel',
    'Core_NotAvailable',
    'Core_ChartNotAvailableForAppliedFilters',
    'PathwayActivity_OpportunityTitle',
    'Opportunities_Interested',
    'PathwayActivity_UsersViewed',
    'PathwayActivity_TotalViews',
    'ReportingContentSvc_ImageAltText',
    'dgVBarChart_DisplayTypePlaceholder',
  ]);
  public rawChartData;
  public chartData: ChartData;

  private IS_RTL: boolean;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private element: ElementRef,
    private Highcharts: HighchartsService,
    private trackerService: TrackerService,
    private translate: TranslateService,
    private dateRangeService: DateRangeService,
    private colorService: ColorService,
    private reportingService: ReportingService
  ) {}

  public ngOnInit(): void {
    if (this.pathwayId !== undefined) {
      this.context = 'Pathway';
      this.chartName = this.i18n.PathwayActivity_PathwayTitle;
    } else if (this.planId !== undefined) {
      this.context = 'Plan';
      this.chartName = this.i18n.PathwayActivity_PlanTitle;
    } else if (this.opportunityId !== undefined) {
      this.context = 'Opportunity';
      this.chartName = this.i18n.PathwayActivity_OpportunityTitle;
    }
    this.loadChart();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.groupId &&
        !changes.groupId.isFirstChange() &&
        changes.groupId.previousValue !== '') ||
      (changes.filterOrgId && !changes.filterOrgId.isFirstChange())
    ) {
      this.loadChart();
    }
  }

  public loadChart() {
    this.formData.dataType = this.getTypeOptions();
    this.activeLegend = this.getActiveLegend();

    // TODO: Temp solution only
    // We need to coordinate with BE to return "Apply" info instead of manually alter it in FE
    // as soon as the apply feature finalize design.
    if (this.hasExternalProvider) {
      this.formData.dataType.options = this.formData.dataType.options.map(
        (option) =>
          option.Name === 'Interested'
            ? { ...option, Name: 'Clicked Apply' }
            : option
      );
    }

    this.drawChart('all', this.formData.dateRange);
  }

  public getTypeOptions() {
    // Ensure we always provide all of the options defined in DropdownType
    const types: Record<DropdownType, string> = {
      all: this.i18n.Core_ViewAll,
      views: this.i18n.Core_Views,
      completes: this.i18n.Core_Completions,
      enroll: this.i18n.Core_Follows,
      totalviews: this.i18n.PathwayActivity_TotalViews,
      enrollment: this.hasExternalProvider
        ? this.i18n.KPI_OpportunityApplyName
        : this.i18n.Core_Interested,
      users: this.i18n.PathwayActivity_UsersViewed,
    };

    // Make sure we only show options that are appropriate for the current insights type.
    const options = ['all', ...this.getActiveTypes('all')].map(
      (type: DropdownType) => ({
        Name: types[type],
        Value: type,
      })
    );

    return {
      options,
      selected: options.find((option) => option.Value === 'all'),
    };
  }

  public getActiveLegend() {
    const chartTypes = this.getActiveTypes().filter(
      (type) =>
        !(this.context === 'Pathway' && this.groupId && type === 'enroll')
    );
    return chartTypes.map((type) => ({
      Color: this.colors[type],
      Label: this.getLabelForType(type),
    }));
  }

  public updateDateRange = (range) => {
    this.formData.dateRange = range;
    this.drawChart(
      this.formData.dataType.selected.Value,
      this.formData.dateRange
    );
  };

  public updateDataType($event: Option) {
    this.formData.dataType.selected = $event;
    this.activeLegend = this.getActiveLegend();
    this.drawChart(
      this.formData.dataType.selected.Value,
      this.formData.dateRange
    );
    if (this.context) {
      this.trackerService.trackEventData({
        action: `${this.context} Activity Graph Filtered`,
        properties: {
          FilterType: 'Data Shown',
          FilterSelection: this.formData.dataType.selected.Name,
          Location: `${this.context} Insights`,
        },
      });
    }
  }

  public formatTooltip(type: ChartType, count: number, label: string) {
    const tooltipKey = this.getTooltipI18nKey(type);
    if (!tooltipKey) {
      return;
    }

    const description = this.translate.instant(tooltipKey, {
      count,
    });

    return `
            <div class="highcharts__tooltip guts-p-v-half guts-p-h-2">
                <div class="highcharts__tooltip__label">${label}</div>
                <div>${description}</div>
            </div>
        `;
  }

  public trackHover(Type: string, Date: string, UserCount: number) {
    if (this.context) {
      this.trackerService.trackEventData({
        action: `${this.context} Activity Graph Hovered`,
        properties: {
          Type,
          Date,
          UserCount,
          Location: `${this.context} Insights`,
        },
      });
    }
  }

  private getActiveTypes(
    selectedType = this.formData.dataType.selected.Value
  ): ChartType[] {
    if (selectedType === 'all') {
      if (this.planId) {
        return ['views', 'enroll'];
      } else if (this.pathwayId && !this.groupId) {
        return ['views', 'enroll', 'completes'];
      } else if (this.pathwayId && this.groupId) {
        return ['views', 'completes'];
      } else {
        return ['totalviews', 'users', 'enrollment'];
      }
    }

    return [selectedType];
  }

  private getLabelForType(type: ChartType): string {
    const option = this.formData.dataType.options.find(
      (option) => option.Value === type
    );
    return (option && option.Name) || type;
  }

  private generateChart(
    series: { name; data }[],
    labels: string[],
    extraLabels: string[],
    colors: string[]
  ): Observable<any> {
    const pathwayActivity = this;
    const container = this.element.nativeElement.querySelector('.highcharts');
    return this.Highcharts.createChart(container, {
      chart: {
        type: 'line',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'inherit',
        },
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: labels,
        reversed: this.IS_RTL,
        tickWidth: 0,
        title: {
          text: this.i18n.Core_Date,
        },
      },
      yAxis: {
        allowDecimals: false,
        floor: 0,
        min: 0,
        opposite: this.IS_RTL,
        lineWidth: 1,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: this.i18n.PathwayActivity_Users,
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: this.colorService.getColor('white'),
        borderColor: this.colorService.getColor('ebony-a18'),
        borderWidth: 1,
        shadow: false,
        style: {
          padding: 0,
        },
        formatter: function (this: {
          x: string;
          y: number;
          point: {
            index: number;
          };
          series: {
            name: string;
            userOptions: {
              __type: ChartType;
            };
          };
        }) {
          pathwayActivity.trackHover(this.series.name, this.x, this.y);
          return pathwayActivity.formatTooltip(
            this.series.userOptions.__type,
            this.y,
            extraLabels[this.point.index]
          );
        },
      },
      credits: {
        enabled: false,
      },
      colors: colors,
      legend: {
        enabled: false,
      },
      plotOptions: {
        line: {
          marker: {
            fillColor: '#FFFFFF', // should match the chart background
            lineColor: null,
            lineWidth: 2,
            radius: 3,
            symbol: 'circle',
            states: {
              hover: {
                // Dirty hack to make the points filled using the current line-color
                radius: 2,
                lineWidth: 4,
              },
            },
          },
          states: {
            hover: {
              halo: false,
              lineWidthPlus: 0, // don't make the line thicker on hover
            },
          },
        },
      },
      series: series,
    });
  }

  private getPoints(range: { startDate: number; endDate: number }): any {
    if (this.opportunityId !== undefined) {
      return this.reportingService.GetOpportunityInsightsActivity(
        range.startDate,
        range.endDate,
        this.opportunityId,
        this.orgId
      );
    } else if (this.planId !== undefined) {
      return this.reportingService.getTargetInsightsActivity(
        this.orgId,
        range.startDate,
        range.endDate,
        this.planId,
        this.groupId
      );
    } else if (this.pathwayId) {
      return this.reportingService.GetPathwayInsightsActivity(
        range.startDate,
        range.endDate,
        this.pathwayId,
        this.groupId,
        this.filterOrgId
      );
    } else {
      throw new Error('Missing bindings!');
    }
  }

  private drawChart(
    type: DropdownType,
    range: { startDate: number; endDate: number }
  ) {
    this.getPoints(range)
      .pipe(
        take(1),
        map((results) => {
          const types = this.getActiveTypes(type);
          // Set this to true if we have ANY non-zero data points, even in series that aren't currently visible
          const hasData = {
            completes: false,
            enroll: false,
            views: false,
            totalviews: false,
            users: false,
            enrollment: false,
          };

          this.processChartData({ types, hasData, results });
        }),
        switchMap(() => {
          return this.generateChart(
            this.chartData.series,
            this.chartData.labels,
            this.chartData.extraLabels,
            this.chartData.colors
          );
        }),
        tap(() => {
          setTimeout(() => {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          }, 0);
        })
      )
      .subscribe();
  }

  private processChartData({ types, hasData, results }): void {
    const labels: Record<string, string> = {};
    const labelDescriptions: Record<string, string> = {};
    const data: Record<ChartType, number[]> = {
      completes: [],
      enroll: [],
      views: [],
      totalviews: [],
      users: [],
      enrollment: [],
    };

    // sets hasData flag based on the itemCount value greater than zero
    this.hasData = results.filter((point) => point.itemCount > 0).length > 0;

    results.map((point) => {
      // Series can have multiple points with the same XAxisLabel, but the DateItem is guaranteed to be unique so use that
      // to collect the labels and the descriptions.
      labels[point.dateItem] = point.xAxisLabel;
      labelDescriptions[point.dateItem] = point.xAxisDescription;

      const pointType = point.itemType.toLowerCase() as ChartType;

      if (pointType in data) {
        data[pointType].push(point.itemCount);
        hasData[pointType] = hasData[pointType] || point.itemCount > 0;
      }
    });
    this.chartData = {
      colors: types.map((type) => this.colors[type]),
      series: types.map((type) => ({
        __type: type,
        name: this.getLabelForType(type),
        data: data[type],
      })),
      labels: this.values(labels),
      extraLabels: this.values(labelDescriptions),
      hasData,
    };
  }

  private values<T>(obj: Record<any, T>): T[] {
    const result = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result.push(obj[key]);
      }
    }
    return result;
  }

  private getTooltipI18nKey(type: ChartType) {
    switch (type) {
      case 'completes':
        return 'PathwayActivity_TooltipCompletions';
      case 'enroll':
        return 'PathwayActivity_TooltipFollow';
      case 'views':
        return 'PathwayActivity_TooltipViews';
      case 'totalviews':
        return 'PathwayActivity_TooltipTotalViews';
      case 'enrollment':
        return this.hasExternalProvider
          ? 'PathwayActivity_TooltipClickedApply'
          : 'PathwayActivity_TooltipInterested';
      case 'users':
        return 'PathwayActivity_TooltipViews';
    }
  }
}
