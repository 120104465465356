import { Injectable } from '@angular/core';
import {
  WizardLinkLevel,
  WizardPosition,
  WizardState,
  WizardStep,
  WizardSteps,
} from '@app/shared/components/wizard/wizard.model';

/**
 * WIP to manage state of dgxWizardNavigation in ngx context
 * Eventual replacement for AngularJS WizardSvc
 */
@Injectable({
  providedIn: 'root',
})
export class WizardService {
  private _state: WizardState = wizardInitialState;

  public get steps(): WizardSteps {
    return this._state.steps;
  }

  // `progress` is the first incomplete step in the wizard
  public get progress(): WizardPosition {
    return this._state.progress;
  }

  // `position` is the current location in the wizard and may be
  // any point up to the current `progress`
  public get position(): WizardPosition {
    return this._state.position;
  }

  public initState(partialState: Partial<WizardState>): void {
    const state = {
      ...wizardInitialState,
      ...partialState,
    };
    this.updateState(state);
  }

  public setSteps(steps: WizardSteps): void {
    this.updateState({ steps });
  }

  public setPosition(position: WizardPosition): void {
    this.updateState({ position });
  }

  public setProgress(progress: WizardPosition): void {
    this.updateState({ progress });
  }

  public setExpandAll(expandAll: boolean): void {
    this.updateState({ expandAll });
  }

  public setCollapseAll(collapseAll: boolean): void {
    this.updateState({ collapseAll });
  }

  public updateState(partialState: Partial<WizardState>): void {
    const newState = {
      ...this._state,
      ...partialState,
    };
    const steps = this.updateSteps(newState);
    this._state = {
      ...newState,
      steps,
    };
  }

  public setLinkLevel(linkLevel: WizardLinkLevel): void {
    this.updateState({ linkLevel });
  }

  public updateSteps(state: WizardState) {
    return state.steps.map((step: WizardStep) => {
      return {
        ...step,
        isExpanded: this.stepIsExpanded(
          step,
          state.expandAll,
          state.collapseAll,
          state.progress
        ),
        isCurrent: this.stepIsCurrent(step.position, state.progress),
        isCompleted: this.stepIsCompleted(step.position, state.progress),
        isActive: this.stepIsActive(step.position, state.position),
        isClickable: this.stepIsClickable(
          step,
          state.linkLevel,
          state.progress
        ),
        type: 'step',
        tasks:
          step.tasks &&
          step.tasks.map((task: WizardStep) => {
            return {
              ...task,
              isCurrent: this.taskIsCurrent(task.position, state.progress),
              isCompleted: this.taskIsCompleted(task.position, state.progress),
              isActive: this.taskIsActive(task.position, state.position),
              isClickable: this.taskIsClickable(task.position, state.progress),
              type: 'task',
            };
          }),
      };
    });
  }

  public stepIsExpanded(
    step: WizardStep,
    expandAll: boolean,
    collapseAll: boolean,
    progress: WizardPosition
  ): boolean {
    const isCurrent = this.stepIsCurrent(step.position, progress);
    return (
      (isCurrent || expandAll) &&
      !collapseAll &&
      step.tasks &&
      step.tasks.length > 0 &&
      +progress.task !== -1
    );
  }

  public stepIsCurrent(
    stepPosition: WizardPosition,
    currentProgress: WizardPosition
  ): boolean {
    return +stepPosition.step === +currentProgress.step;
  }

  public stepIsActive(
    stepPosition: WizardPosition,
    currentPosition: WizardPosition
  ): boolean {
    return +stepPosition.step === +currentPosition.step;
  }

  public stepIsCompleted(
    stepPosition: WizardPosition,
    currentProgress: WizardPosition
  ): boolean {
    return (
      +stepPosition.step < +currentProgress.step ||
      (+stepPosition.step === +currentProgress.step &&
        +currentProgress.task === -1)
    );
  }

  public stepIsClickable(
    step: WizardStep,
    linkLevel: WizardLinkLevel,
    currentProgress: WizardPosition
  ): boolean {
    const isCurrent = this.stepIsCurrent(step.position, currentProgress);
    const isCompleted = this.stepIsCompleted(step.position, currentProgress);
    return (
      linkLevel === 'steps' &&
      (isCurrent || isCompleted) &&
      (!step.tasks || !step.tasks.length)
    );
  }

  public taskIsCurrent(
    taskPosition: WizardPosition,
    currentProgress: WizardPosition
  ): boolean {
    return (
      +taskPosition.step === +currentProgress.step &&
      +taskPosition.task === +currentProgress.task
    );
  }

  public taskIsActive(
    taskPosition: WizardPosition,
    currentPosition: WizardPosition
  ): boolean {
    return (
      +taskPosition.step === +currentPosition.step &&
      +taskPosition.task === +currentPosition.task
    );
  }

  public taskIsCompleted(
    taskPosition: WizardPosition,
    currentPosition: WizardPosition
  ): boolean {
    return (
      +taskPosition.step === +currentPosition.step &&
      +taskPosition.task < +currentPosition.task
    );
  }

  public taskIsClickable(
    taskPosition: WizardPosition,
    currentProgress: WizardPosition
  ): boolean {
    const isCurrent = this.taskIsCurrent(taskPosition, currentProgress);
    const isCompleted = this.taskIsCompleted(taskPosition, currentProgress);
    return isCurrent || isCompleted;
  }
}

export const wizardInitialState: WizardState = {
  steps: [],
  linkLevel: 'steps',
  progress: { step: 1, task: null },
  position: { step: 1, task: null },
  redirect: true,
  collapseAll: false,
  expandAll: false,
};
