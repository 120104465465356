import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import produce from 'immer';
import { BehaviorSubject, firstValueFrom, Observable, pipe } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { AuthUser } from '@app/account/account-api.model';

import {
  DialogService,
  LayoutAspect,
  ToastService,
} from '@degreed/apollo-angular';

import { OrgBrandingLayoutService } from '@app/orgs/services';
import { EventBus } from '@app/shared/services/event-bus';
import { ReactiveStore } from '@dg/shared-rsm';
import { AuthService } from '@dg/shared-services';

import {
  brandingChanged,
  BrandingState,
  BrandingViewModel,
  ColorType,
  EndorsementsState,
  EndorsementsViewModel,
  initAspect,
  initBrandingState,
  LogoData,
  NavigationState,
  NavigationViewModel,
  OrgInfoState,
  OrgInfoViewModel,
  TabType,
} from './org-branding.model';

import {
  ConfirmOnLeaveData,
  ConfirmOnLeaveModalComponent,
  PublishChangesData,
  PublishChangesModalComponent,
} from '../components';
import {
  loadFile,
  navigationToAspect,
  TranslateFn,
  translateWithDefaults,
  UploadResponse,
} from './utils';

// ****************************************************************
// i18n Keys and translation function
// ****************************************************************

const i18n = {
  PUBLISHED: 'org.branding.status.changes.published', // 'Branding changes published!'
  DISCARDED: 'org.branding.status.changes.discarded', // 'Branding changes discarded!'
  UNABLE_TO_SAVE: 'org.branding.status.changes.unable-to-save', // 'Unable to save your branding changes!'
  UNABLE_TO_LOAD: 'org.branding.status.branding.unable-to-load', // 'Failed to load branding'
  FAILED_TO_SAVE: 'org.branding.status.branding.failed-to-save', // 'Failed to save branding'
  RELOADED: 'org.branding.status.branding.reloaded', // 'Reloaded current branding...'
};

let translate: TranslateFn;

// ****************************************************************
// Branding Facade
// ****************************************************************

/**
 * BrandingFacade
 *
 * Manage Org features include custom organization branding, navigation, and endorsements
 * This facade is used to manage all the data, state, and persistence for the Org Branding features.
 */
@Injectable({ providedIn: 'root' })
export class OrgBrandingFacade {
  private previewEmitter: BehaviorSubject<LayoutAspect>;
  private store: ReactiveStore<BrandingState>;

  // Streams for the three (3) Branding features: Navigation, Endorsements, and Org Info
  public vm$: Observable<BrandingViewModel>;
  public orgInfo$: Observable<OrgInfoViewModel>;
  public endorsements$: Observable<EndorsementsViewModel>;
  public navigation$: Observable<NavigationViewModel>;

  // Aspect used for Navigation Preview functionality
  public preview$: Observable<LayoutAspect>;

  constructor(
    private api: OrgBrandingLayoutService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private authService: AuthService,
    private eventBus: EventBus
  ) {
    const {
      updateIsDirty,
      addBrandingAPI,
      selectNavigation,
      addComputedColors,
      addNavigationAPI,
      selectEndorsements,
      addEndoresementsAPI,
      selectOrgInfo,
      addOrgInfoAPI,
    } = this.buildRxJsOperators();
    translate = translateWithDefaults(this.translateService);
    const name = 'manageOrg.branding.store';
    this.store = new ReactiveStore<BrandingState>(name, initBrandingState);

    this.previewEmitter = new BehaviorSubject<LayoutAspect>(initAspect());
    this.preview$ = this.previewEmitter.asObservable();
    this.vm$ = this.store.state$.pipe(updateIsDirty, addBrandingAPI);
    this.navigation$ = this.vm$.pipe(
      selectNavigation,
      distinctUntilChanged(),
      addComputedColors,
      addNavigationAPI,
      tap(this.refreshLayout.bind(this))
    );
    this.endorsements$ = this.vm$.pipe(
      selectEndorsements,
      distinctUntilChanged(),
      addEndoresementsAPI
    );
    this.orgInfo$ = this.vm$.pipe(
      selectOrgInfo,
      distinctUntilChanged(),
      addOrgInfoAPI
    );

    this.autoLoadBranding();
  }

  /**
   * Load the current branding state from the server
   */
  async loadBranding(options?: {
    tab?: TabType;
    orgId?: number;
    reset?: boolean;
  }) {
    try {
      const query = async () =>
        this.api.loadBranding(options.reset, options?.orgId);
      const saved = await this.store.track(query, 'loadBranding');

      this.store.update(
        (state) => {
          state.selectedTab = options?.tab ?? state.selectedTab;
          state.navigation.colors = {
            ...state.navigation.colors,
            ...saved.navigation.colors,
          };
          state.navigation.mark = saved.navigation.mark;
          state.navigation.logo = saved.navigation.logo;
          state.endorsements = saved.endorsements;
          state.orgInfo = saved.orgInfo;
        },
        [],
        options?.reset
      );
    } catch (error) {
      const msg = translate('Failed to load branding', i18n.UNABLE_TO_LOAD);
      console.error(msg);
    }
  }

  /**
   * Save the current branding state to the server
   * Note: this is the 'publish' method
   */
  async publishChanges() {
    const branding = this.store.snapshot;
    const confirmed = await this.confirmPublish(branding);
    if (confirmed) {
      try {
        const query = async () => this.api.saveBranding(branding);
        const response = await this.store.track(query, 'saveBranding');

        if (response) {
          const msg = translate('Branding changes published!', i18n.PUBLISHED);
          this.toastService.showToast(msg);
          this.store.update((state) => {
            state.navigation.isDirty = false;
            state.endorsements.isDirty = false;
            state.orgInfo.isDirty = false;
          });

          // Announce globally recent changes to publish Org Branding
          this.eventBus.announce(brandingChanged(this.store.snapshot));
          return true;
        } else {
          const message = translate(
            'Unable to save your branding changes!',
            i18n.UNABLE_TO_SAVE
          );
          this.toastService.showToast(message, { type: 'error' });
        }
      } catch (error) {
        const msg = translate('Failed to save branding', i18n.FAILED_TO_SAVE);
        console.error(msg, error);
      }
    }
    return false;
  }

  /**
   * Discard any changes made to the branding
   */
  async discardChanges(restoreCurrent = true) {
    const vm = await firstValueFrom(this.vm$);

    if (vm.isDirty) {
      const initWith = () => ({ selectedTab: vm.selectedTab }) as BrandingState;

      if (restoreCurrent) {
        const message = translate(
          'Reloaded current branding...',
          i18n.RELOADED
        );

        this.toastService.showToast(message, { type: 'success' });
        await this.loadBranding({ reset: true, tab: vm.selectedTab });
      } else {
        const message = translate(
          'Branding changes discarded!',
          i18n.DISCARDED
        );

        this.store.reset(initWith);
        this.toastService.showToast(message, { type: 'warning' });
      }
    }
  }

  // ********************************************************************
  // In-memory only methods... 'publish' is required to save state to the server
  // ****************************************************************

  selectTab(tab: TabType) {
    this.store.update((state: BrandingState) => {
      state.selectedTab = tab;
    });
  }

  updateColor(color: string, type: ColorType) {
    this.store.update((state: BrandingState) => {
      state.navigation.isDirty = true;
      state.navigation.colors[type] = color;
    });
  }

  /**
   * Larger version of the Logo
   * Upload specified file and save in state
   * @returns  UploadResponse tuple
   */
  async updateImage(settings: Partial<LogoData>): Promise<UploadResponse> {
    const [fileName, url] = await this.loadImage(settings);
    const hasAltText = typeof settings.altText !== 'undefined';

    if (url || hasAltText) {
      this.store.update((state: BrandingState) => {
        const imageType = settings.imageType ?? 'logo';
        const fallBackAltText = fileName || state.navigation[imageType].altText;

        state.navigation.isDirty = true;
        state.navigation[imageType] = {
          url: url || state.navigation[imageType].url,
          fileName: fileName || state.navigation[imageType].fileName,
          altText: settings.altText ?? fallBackAltText,
          isLoading: false,
          progress: 0,
        };
      });
    }

    return [fileName, url];
  }

  /**
   * Update the Endorsement image
   */
  async updateEndorsement(
    settings: Partial<LogoData>
  ): Promise<UploadResponse> {
    const [fileName, url] = await this.loadImage({
      ...settings,
      imageType: 'endorsement',
    });
    const hasAltText = typeof settings.altText !== 'undefined';

    if (url || hasAltText) {
      this.store.update((state: BrandingState) => {
        const endorsement = state.endorsements.endorsement;
        const fallBackAltText = fileName || endorsement.altText;

        state.endorsements.isDirty = true;
        state.endorsements.endorsement = {
          url: url || endorsement.url,
          fileName: fileName || endorsement.fileName,
          altText: settings.altText ?? fallBackAltText,
          isLoading: false,
          progress: 0,
        };
      });
    }

    return [fileName, url];
  }

  /**
   * Update the Organization info
   */
  updateOrgInfo(settings: Partial<OrgInfoState>) {
    this.store.update((state: BrandingState) => {
      const orgInfo = state.orgInfo;

      orgInfo.isDirty = true;
      orgInfo.orgName = settings.orgName ?? orgInfo.orgName;
      orgInfo.useInOnboarding =
        settings.useInOnboarding ?? orgInfo.useInOnboarding;
    });
  }

  // ****************************************************************
  // Private Modal Confirmation Dialogs
  // ****************************************************************

  private async confirmPublish(state: BrandingState) {
    return new Promise<boolean>((resolve) => {
      const dialogRef = this.dialogService.show<PublishChangesData>(
        PublishChangesModalComponent,
        {
          state,
          onClose: (confirmed) => {
            dialogRef.close();
            resolve(confirmed);
          },
        }
      );
    });
  }

  private async confirmOnLeave() {
    return new Promise<boolean>((resolve) => {
      const dialogRef = this.dialogService.show<ConfirmOnLeaveData>(
        ConfirmOnLeaveModalComponent,
        {
          // Confirmed true == publish, false == discard
          onClose: (confirmed) => {
            dialogRef.close();
            resolve(confirmed);
          },
        }
      );
    });
  }

  // ****************************************************************
  // Private API injection methods
  // ****************************************************************

  /**
   * Special compute function to determine if ANY of the state is dirty
   */
  private computeIsDirty(state: BrandingState): BrandingState {
    return produce(state, (draft) => {
      draft.isDirty =
        draft.navigation.isDirty ||
        draft.endorsements.isDirty ||
        draft.orgInfo.isDirty;

      let count = 0;
      draft.navigation.isDirty && count++;
      draft.endorsements.isDirty && count++;
      draft.orgInfo.isDirty && count++;

      draft.dirtyCount = count;
    });
  }

  private async loadImage(settings: Partial<LogoData>) {
    const onProgress = (progress: number, message: string) => {
      settings.imageType &&
        this.store.update((state: BrandingState) => {
          const isEndorsement = settings.imageType === 'endorsement';
          const target = isEndorsement
            ? state.endorsements.endorsement
            : state.navigation[settings.imageType];

          target.isLoading = true;
          target.progress = progress;
          target.fileName = message;
        });
    };

    try {
      return !settings.file
        ? [null, null]
        : await loadFile(settings.file, onProgress);
    } catch (error) {
      this.toastService.showToast(error, { type: 'error' });
      return [null, null];
    }
  }

  // ****************************************************************
  // RxJS Stream Features
  // ****************************************************************

  /**
   * Dynamically update the Layout Aspect; each time the state changes
   * Whenever the state changes, conditionally check if the layout needs to be refreshed
   * NOTE: the preview$ is a ready-only stream that is synchronized from the BrandingState
   */
  private refreshLayout(state: NavigationState): void {
    const layout = navigationToAspect(state, this.previewEmitter.value);
    this.previewEmitter.next(layout);
  }

  /**
   * Build custom RxJs selectors and operators for the Org Branding features
   */
  private buildRxJsOperators() {
    return {
      /**
       * RxJS Selectors
       */
      selectNavigation: pipe(map((state: any) => state.navigation)),
      selectEndorsements: pipe(map((state: any) => state.endorsements)),
      selectOrgInfo: pipe(map((state: any) => state.orgInfo)),

      /**
       * Computed State Operators
       */
      updateIsDirty: pipe(
        map<BrandingState, BrandingState>(this.computeIsDirty.bind(this))
      ),
      addComputedColors: pipe(
        map((state: NavigationState) => {
          return produce(state, (draft) => {
            draft.logo.backgroundColor = draft.colors.background;
            draft.mark.backgroundColor = draft.colors.background;
          });
        })
      ),

      /**
       * API Injection Operators
       */
      addOrgInfoAPI: pipe(
        map<OrgInfoState, OrgInfoViewModel>((state: OrgInfoState) => ({
          ...state,
          updateInfo: this.updateOrgInfo.bind(this),
        }))
      ),
      addNavigationAPI: pipe(
        map<NavigationState, NavigationViewModel>((state: NavigationState) => ({
          ...state,
          updateColor: this.updateColor.bind(this),
          updateMark: (settings) =>
            this.updateImage({ ...settings, imageType: 'mark' }),
          updateLogo: (settings) =>
            this.updateImage({ ...settings, imageType: 'logo' }),
        }))
      ),
      addBrandingAPI: pipe(
        map<BrandingState, BrandingViewModel>((state: BrandingState) => ({
          ...state,
          discardChanges: this.discardChanges.bind(this),
          publishChanges: this.publishChanges.bind(this),
          selectTab: this.selectTab.bind(this),
        }))
      ),
      addEndoresementsAPI: pipe(
        map<EndorsementsState, EndorsementsViewModel>(
          (state: EndorsementsState) => ({
            ...state,
            updateImage: this.updateEndorsement.bind(this),
          })
        )
      ),
    } as const;
  }

  /**
   * Whenever authenicated user changes, reload the branding
   */
  private autoLoadBranding() {
    this.authService.authUser$.subscribe((user: AuthUser | null) => {
      if (!!user) this.loadBranding({ reset: true });
    });
  }
}
