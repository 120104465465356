import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import { ChartsService } from '@app/charts/charts.service';
import { LearningChartService } from '@app/charts/components/learning-chart/learning-chart.service';
import { RouterComponents } from '@app/orgs/constants';
import { AuthService } from '@app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { OrgReportingBaseComponent } from './org-reporting-base.component';
import { Params } from '@angular/router';
@Component({
  selector: 'dgx-org-reporting',
  templateUrl: './org-reporting.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgReportingComponent
  extends OrgReportingBaseComponent
  implements AfterViewInit, OnInit
{
  // dgxLearningChart component Output binding
  @Output() public getChartData: EventEmitter<{
    params: any;
  }> = new EventEmitter<{ params: any }>();
  public readonly CLASS_NAME = RouterComponents.ORG_REPORTING_COMPONENT;
  public resolver: any;

  public get typedReportingParams() {
    return this.reportingParams as Params;
  }
  constructor(
    protected injector: Injector,
    private learningChartService: LearningChartService,
    public cdr: ChangeDetectorRef,
    authService: AuthService
  ) {
    super(authService, injector, cdr);
  }

  /**
   * Property to determine whether or not to display the dgRecommendationsStatus component
   */
  public get hasRecommendationsStatus(): boolean {
    let valid = false;
    const showMore = !this.showMore || this.showMore === 'recommendations';
    const hasPermission = this.permissions.canViewReports;
    const singleGroupIdSelected =
      this.groupIdList.split(',').filter((id) => !isNaN(parseInt(id, 10)))
        .length === 1;
    if (showMore && hasPermission && singleGroupIdSelected) {
      valid = true;
    }
    return valid;
  }

  /**
   * Property to determine whether or not to display the dgxTopGroups component
   */
  public get hasTopGroups(): boolean {
    let valid = false;
    const showMore = !this.showMore || this.showMore === 'topGroups';
    const hasPermission =
      this.permissions.canViewReports || this.permissions.canManageGroups;
    if (showMore && hasPermission) {
      valid = true;
    }
    return valid;
  }

  public ngAfterViewInit(): void {
    this.loadChartsModule();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.getChartData.subscribe((event) => {
      this.getLearningSummary(event);
    });
  }
  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  // channel context
  // called in the template when tenant org is changed
  public onOrgSelection(selectedItem): void {
    this.setCurrentOrgById(selectedItem.organizationId);
    const event = {
      params: {
        dataType: this.learningChartService.selectedDataType.type,
        inputType: this.learningChartService.selectedInputType.type,
        period: this.learningChartService.selectedPeriod.Value,
        tag: this.learningChartService.selectedTag,
      },
    };
    this.getLearningSummary(event);
  }
  public getLearningSummary($event) {
    const params = $event.params;
    const orgId = this.getOrgId();
    const startDate = this.startDate.value;
    const endDate = this.endDate.value;
    const groupIdAdjust = this.adjustGroup(this.groupId);
    if (this.reportingContentService.isInputType(params.inputType)) {
      params.inputType = [params.inputType];
    }
    params.tag = this.reportingContentService.setTagWhenDefault(params.tag);
    let observer: Observable<any>, queue: string;
    if (params.dataType === this.dataTypes.views) {
      observer = this.reportingService.getTotalContentViewed(
        orgId,
        startDate,
        endDate,
        params.inputType,
        params.tag,
        groupIdAdjust,
        this.groupIdList
      );
      queue = 'getTotalContentViewed';
    } else if (params.dataType === this.dataTypes.Views) {
      observer = this.reportingService.getTotalContentViewed(
        orgId,
        startDate,
        endDate,
        params.inputType,
        params.tag,
        groupIdAdjust,
        this.groupIdList
      );
      queue = 'getTotalContentViewed';
    } else {
      observer = this.reportingService.getInsights(
        orgId,
        startDate,
        endDate,
        params.inputType,
        params.tag,
        groupIdAdjust,
        this.groupIdList
      );
      queue = 'getInsights';
    }
    const subscription = observer.pipe(take(1)).subscribe(
      (data) => {
        data = this.learningChartService.transformDataToPascal(data);
        const chartData = this.learningChartService.extractChartData(
          data,
          params.dataType
        );
        this.totalDisplayData = chartData;
        this.cdr.detectChanges();
      },
      (err) => {
        this.totalDisplayData = null;
      }
    );
    this.subscriptionQueueService.persist(subscription, queue);
    this.getKpis();
  }
  public trackInsightFilter(type, event): void {
    this.trackFilter(
      'Org Skill Insights Filtered',
      'Org Skill Insights',
      type,
      event.target.innerText
    );
  }

  private adjustGroup(groupId): any {
    return groupId === 0 ? null : groupId;
  }
  /**
   * Lazy load Charts module
   */
  private loadChartsModule(): void {
    ChartsService.loadModule().then((m) => {
      this.resolver = m.ComponentResolver;
      this.cdr.detectChanges();
    });
  }
  public showChart(name: string) {
    return true;
  }
  public getComponent(name: string): any {
    if (this.resolver) {
      return this.resolver.getComponent(name);
    }
    return null;
  }
  public getChartInputs(name: string) {
    let config = {};
    switch (name) {
      case 'dgxActiveUserChart':
        config = {
          startDate: this.startDate,
          endDate: this.endDate,
          selectedPeriod: this.selectedPeriod,
          orgId: this.orgId,
          hasDrillDown: false,
          groupId: this.groupId,
          groupIdList: this.groupIdList,
          location: 'Org Insights',
        };
        break;
      case 'dgxReportingRangePicker':
        config = {
          appearance: 'dropdown',
          selection: this.updateDateRange,
          value: this.dateRange,
          location: 'Org Insights',
          maxDays: 366,
        };
        break;
      case 'dgxLearningChart':
        config = {
          startDate: this.startDate,
          endDate: this.endDate,
          chart: this.totalDisplayData,
          selectedPeriod: this.selectedPeriod,
          setSelectedPeriod: false,
          orgId: this.orgId,
          hasDrillDown: this.context !== 'channel',
          groupId: this.groupId,
          groupIdList: this.groupIdList,
          context: this.context,
          location: 'Org Insights',
          getChartData: this.getChartData,
        };
        break;
      case 'dgxSkillRatingsChart':
        config = {
          orgId: this.orgId,
          startDate: this.startDate.value,
          endDate: this.endDate.value,
          groupId: this.groupId,
          groupIdList: this.groupIdList,
          moreId: 'skillRatings',
          context: this.context,
          location: 'Org Insights',
        };
        break;
      case 'dgxTopCompletionsChart':
        config = {
          orgId: this.orgId,
          startDate: this.startDate.value,
          endDate: this.endDate.value,
          groupId: this.groupId,
          groupIdList: this.groupIdList,
          permissions: this.permissions,
          location: 'Org Insights',
        };
        break;
      case 'dgxPopularDataChart':
        config = {
          orgId: this.orgId,
          startDate: this.startDate.value,
          endDate: this.endDate.value,
          groupId: this.groupId,
          groupIdList: this.groupIdList,
          location: 'Org Insights',
        };
        break;
    }
    return config;
  }
}
