import { Component, OnInit } from '@angular/core';

import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';

/** {@link MonthPickerFieldComponent} input parameters. See {@link MonthPickerComponent} for details.   */
export interface MonthPickerFieldParams {
  isMinDateToday?: boolean;
  isMaxDateToday?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  customDgat?: string;
  disabled?: boolean;
  ariaLabel?: string;
}

/** A formly-compatibly wrapper around the {@link MonthPickerComponent} */
@Component({
  selector: 'dgx-month-picker-field',
  templateUrl: 'month-picker-field.component.html',
})
export class MonthPickerFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public static readonly REGISTERED_FIELD_TYPE = 'dgx-month-picker'; // Field type for registration with Formly

  public get params(): MonthPickerFieldParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions?.params ?? {};
  }

  public handleDateSelected(date: Date) {
    this.formControl.setValue(date);
    this.formControl.markAsDirty();
  }

  public handleBlur() {
    this.formControl.markAsTouched();
  }
}
