<ng-container *ngIf="!showLearnerHubModal">
  <div aria-live="polite">
    <form class="form-wrap" (submit)="submit($event)">
      <dgx-modal
        [modalHeaderTitle]="i18n.addToTargetModal_AddToTarget"
        [canCancel]="true"
        [cancelButtonText]="cancelButtonText"
        [isLoading]="isModalLoading"
        [submitButtonText]="submitButtonText"
        [useDefaultForm]="false"
        [useDefaultSubmitButton]="!!submitButtonText"
        [isSubmitDisabled]="!canSubmit"
      >
        <!-- Modal Body -->
        <ng-container class="modal-body">
          <dgx-org-managed-notification
            class="guts-m-t-1 guts-m-b-2 block"
            *ngIf="showOrgManagedNotification"
            resourceType="Target"
          ></dgx-org-managed-notification>
          <div class="guts-m-full-half">
            <div class="guts-p-b-1">
              <ng-container *ngIf="targets.length; else createATarget">
                <ng-container *ngIf="!isAdded; else addedToPlan">
                  <label for="plan-input">{{ i18n.Core_SkillPlan }}</label>
                  <dgx-combobox
                    *ngIf="!loadingTargets; else comboboxSkeleton"
                    class="full-width"
                    (selection)="onSelectTargetOption($event)"
                    [options]="targetOptions"
                    labelKey="name"
                    [placeholder]="
                      i18n.addToTargetModal_SelectTargetPlaceHolder
                    "
                    [hasNoResultsText]="i18n.Core_NoResultsFound"
                    dgatInput="addToPlan-4d1"
                  ></dgx-combobox>
                  <div class="guts-p-t-1" *ngIf="!loadingSections">
                    <div
                      *ngIf="sectionOptions?.length > 0; else noSectionsFound"
                    >
                      <label for="plan-input-section">{{
                        i18n.addToTargetModal_Section
                      }}</label>
                      <dgx-combobox
                        class="full-width"
                        (selection)="onSelectSectionOption($event)"
                        [options]="sectionOptions"
                        labelKey="name"
                        [placeholder]="
                          i18n.addToTargetModal_SelectSectionPlaceHolder
                        "
                        [hasNoResultsText]="i18n.Core_NoResultsFound"
                        dgatInput="addToPlan-4d1"
                      ></dgx-combobox>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </dgx-modal>
    </form>
  </div>

  <ng-template #comboboxSkeleton>
    <dgx-skeleton type="formfield"></dgx-skeleton>
  </ng-template>

  <ng-template #noSectionsFound>
    <df-local-notification
      [text]="i18n.addToTargetModal_NoSectionsFound"
      size="small"
      [type]="NotificationType.info"
    ></df-local-notification>
  </ng-template>

  <ng-template #addedToPlan>
    <div class="center-text break">
      <h3
        [innerHtml]="
          isAdded ? successfullAddText : i18n.addToTargetModal_AddingError
        "
      ></h3>
    </div>
  </ng-template>

  <!-- when there are no targets associated with the orgId -->
  <ng-template #createATarget>
    <h3 class="h3">
      {{ i18n.addToTargetModal_CreateYourTarget }}
    </h3>
    <p class="zeta explanation">
      {{ i18n.addToTargetModal_CreateJobRoleExplanation }}
    </p>
  </ng-template>
</ng-container>

<ng-container *ngIf="showLearnerHubModal">
  <div
    class="tw-w-full tw-max-w-80 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg sm:tw-max-w-sm md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
    role="dialog"
    aria-labelledby="title"
    aria-describedby="description"
  >
    <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
      <h4 class="tw-font-extrabold tw-text-neutral-900" id="title">
        {{
          targets.length || loadingTargets
            ? i18n.addToTargetModal_AddToTarget
            : i18n.addToTargetModal_CreateTarget
        }}
      </h4>
      <!-- Notice -->
      <div
        *ngIf="showOrgManagedNotification"
        class="tw-relative tw-flex tw-min-h-10 tw-min-w-full tw-items-center tw-gap-2 tw-overflow-hidden tw-rounded-lg tw-bg-neutral-100 tw-px-3 tw-py-2 tw-pl-4 before:tw-absolute before:tw-inset-0 before:tw-block before:tw-w-1 before:tw-rounded-l-lg before:tw-bg-neutral-600"
      >
        <da-icon
          icon="information-circle"
          type="solid"
          [solidSize]="16"
          className="tw-size-4 tw-shrink-0 tw-text-neutral-600"
        />
        <p
          class="tw-text-xs tw-text-neutral-800"
          [innerHTML]="orgManagedLabel"
        ></p>
      </div>

      <ng-container *ngIf="targets.length && !isAdded">
        <da-dropdown
          *ngIf="!loadingTargets"
          [items]="targetRowItems"
          [className]="
            'tw-btn-medium tw-btn-secondary-outline tw-w-full tw-justify-between'
          "
          (itemSelect)="onSelectTargetOption($event)"
          [placeholder]="i18n.addToTargetModal_SelectTargetPlaceHolder"
        ></da-dropdown>

        <da-dropdown
          *ngIf="!loadingSections && sectionOptions?.length > 0"
          [items]="targetSectionRowItems"
          [className]="
            'tw-btn-medium tw-btn-secondary-outline tw-w-full tw-justify-between'
          "
          (itemSelect)="onSelectSectionOption($event)"
          [placeholder]="i18n.addToTargetModal_SelectPlanSection"
        ></da-dropdown>
      </ng-container>

      <!-- no pathways -->
      <ng-container *ngIf="!targets.length && !loadingTargets">
        <p class="tw-text-neutral-800">
          {{ i18n.addToTargetModal_CreateJobRoleExplanation }}
        </p>
      </ng-container>

      <!-- success message -->
      <ng-container *ngIf="isAdded">
        <p
          class="tw-text-neutral-800"
          [innerHtml]="
            isAdded ? successfullAddText : i18n.addToTargetModal_AddingError
          "
        ></p>
      </ng-container>
    </div>
    <div
      class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
    >
      <button class="tw-btn-medium tw-btn-secondary-filled" (click)="cancel()">
        {{ !isAdded ? i18n.Core_Cancel : i18n.Core_Done }}
      </button>
      <button
        class="tw-btn-primary tw-btn-medium"
        [attr.aria-disabled]="!canSubmit"
        (click)="submit($event)"
      >
        {{ submitButtonText }}
      </button>
    </div>
  </div>
</ng-container>
