<div class="data-table-container">
  <h1 class="h3 guts-m-b-1">{{ chartName }}</h1>
  <div
    class="l_flexbar m-l_flexbar-block guts-m-t-half m-guts-m-t-0 guts-p-full-1 bg-color-ebony-a03"
  >
    <div class="guts-p-r-1">
      <dgx-select
        labelKey="Name"
        trackBy="Type"
        (selection)="setSelectedCatalogType($event)"
        [selectedItem]="catalogType"
        [options]="catalogTypes"
        [ariaLabel]="i18n.dgPopularProviders_ProviderPlaceholder"
        [placeholder]="i18n.dgPopularProviders_ProviderPlaceholder"
        dgatInput="popular-providers-50e"
      >
      </dgx-select>
    </div>
  </div>
  <div class="guts-p-t-1">
    <table class="data-table">
      <thead>
        <tr>
          <th
            *ngFor="let header of popularProviders.Headers; let i = index"
            data-dgat="popularProviders-5f5"
            [ngClass]="{
              'center-text': i > 0,
              'left-text': i === 0,
              l_w25: i === 1
            }"
            scope="col"
          >
            <span class="font-medium">{{ header }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let provider of popularProviders.Providers"
          data-dgat="popularProviders-bbc"
        >
          <td>
            <span>{{ provider.providerName }}</span>
          </td>
          <td class="center-text">
            {{ provider.itemCount }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    *ngIf="popularProviders.Providers.length === 0"
    translate="dgPopularProviders_Empty"
  ></div>
</div>
