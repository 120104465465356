import { TranslateService } from '@ngx-translate/core';
import { DfTextFieldConfig } from '../field-types';
import { DfRequirableFormFieldBuilder } from './requirable-form-field.builder';
import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * A configuration builder base class common to input and textarea text fields
 */
export abstract class DfTextFieldBuilder<
  TConfig extends DfTextFieldConfig = DfTextFieldConfig
> extends DfRequirableFormFieldBuilder<TConfig> {
  constructor(translate: TranslateService, config: DfTextFieldConfig) {
    super(translate, config as any);

    // set default update mode for text fields to 'blur' so validation happens when users move away from the field
    // which is less obtrusive than 'change'
    config.modelOptions = {
      updateOn: 'blur',
    };
  }

  /**
   * Sets placeholder text on the configuration's template options, to be displayed in an empty input or textarea element.
   * @deprecated  Use .describedAs
   */
  public withPlaceholder(placeholder: string) {
    this.config.templateOptions.placeholder = placeholder;
    return this;
  }

  public asReadonly() {
    this.config.templateOptions.readonly = true;
    return this;
  }
}
