<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!courseGlobalAddForm"
    #form
    #formRef="ngForm"
    class="reactive-forms"
    [class.input-success-kickstart]="vm.shouldShowResults$ | async"
    [formGroup]="courseGlobalAddForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.CourseFormCtrl_SaveCourse
      "
      [useDefaultSubmitButton]="vm.isInitialForm"
      [canCancel]="!(vm.shouldShowResults$ | async)"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      [bodyClasses]="{
        'guts-p-h-2': true,
        'guts-p-v-2': true,
        'input-success-kickstart': (vm.shouldShowResults$ | async)
      }"
    >
      <!-- Modal body -->
      <ng-container class="modal-body" *ngIf="vm.isInitialForm; else full">
        <div
          role="radiogroup"
          aria-required
          aria-errormessage="course-type-required-error"
          [attr.aria-label]="i18n.CourseFormCtrl_HowAdded"
          [attr.aria-invalid]="courseTypeIdHasError"
        >
          <ng-container
            *ngFor="let source of sources; first as firstSourceItem"
            data-dgat="course-global-add-7fb"
          >
            <div *ngIf="!firstSourceItem" class="divider guts-m-v-1">
              <span
                class="par par--small par--light up-me bg-color-white divider__content"
                >{{ i18n.Core_Or }}</span
              >
            </div>
            <div class="reactive-forms__control">
              <button
                type="button"
                role="radio"
                class="reactive-forms__toggle btn full-width full-height guts-p-v-1-half l_flex-middle"
                [class.active]="
                  vm.isCourseTypeSelected(courseTypeId, source.id)
                "
                [attr.aria-checked]="
                  vm.isCourseTypeSelected(courseTypeId, source.id)
                "
                (click)="onCourseTypeChange(source.id)"
                [dgxAutofocus]="firstSourceItem"
                data-dgat="course-global-add-8d1"
              >
                <df-icon
                  icon="{{ source.icon }}"
                  class="block guts-m-b-half"
                ></df-icon>
                <span class="block par--small">{{
                  source.label | translate
                }}</span>
              </button>
            </div>
          </ng-container>
        </div>
        <dgx-validate-field
          class="block"
          *ngIf="courseTypeIdHasError"
          id="course-type-required-error"
          [message]="i18n.MediaFormCtrl_SelectionRequired"
        ></dgx-validate-field>
      </ng-container>

      <!-- Modal footer -->
      <ng-container class="modal-footer">
        <dgx-global-add-results
          *ngIf="!vm.isInitialForm"
          [class.full-width]="vm.shouldShowResults$ | async"
          [buttonText]="
            vm.isInitialForm ? i18n.Core_Next : i18n.CourseFormCtrl_SaveCourse
          "
          [isEditing]="false"
          [isCompleting]="true"
          [isNewbUser]="vm.isNewbUser$ | async"
          [submissionStatus]="vm.submissionStatus$ | async"
          [creationFeedback]="vm.submissionResult"
          [classifier]="'learning'"
          [showCancelButton]="false"
          [addSpacing]="false"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </ng-container>
    </dgx-modal>

    <!-- still inside form, so that we can use `formControlName` -->
    <ng-template #full>
      <div class="input-success-wrap">
        <!-- Country -->
        <div *ngIf="isAddingAccreditedCourse" class="reactive-forms__control">
          <dgx-reactive-forms-label for="courseCountry">
            {{ i18n.CourseFormCtrl_Country }}
          </dgx-reactive-forms-label>
          <dgx-search-field-wrapper type="rounded-full-width">
            <input
              dgxAutofocus
              id="courseCountry"
              type="text"
              class="course-country"
              formControlName="country"
              autocomplete="off"
              [placeholder]="i18n.CourseFormCtrl_CountryPlaceholder"
              [ngbTypeahead]="onCountrySearch"
              (selectItem)="onCountrySelection($event)"
              [resultTemplate]="resultTemplate"
              data-dgat="course-formal-form-26d"
          /></dgx-search-field-wrapper>
        </div>

        <!-- School/Provider Name -->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="institutionName">
            {{
              isAddingAccreditedCourse
                ? i18n.CourseFormCtrl_SchoolName
                : i18n.CourseFormCtrl_ProviderName
            }}
          </dgx-reactive-forms-label>
          <dgx-search-field-wrapper type="rounded-full-width">
            <input
              type="text"
              id="institutionName"
              formControlName="institutionName"
              autocomplete="off"
              aria-errormessage="institutionName-error"
              [placeholder]="
                isAddingAccreditedCourse
                  ? i18n.CourseFormCtrl_SchoolPlaceholder
                  : i18n.CourseFormCtrl_Provider_Placeholder
              "
              [ngbTypeahead]="onProviderSearch"
              [inputFormatter]="inputFormatter"
              [resultTemplate]="resultTemplate"
              (selectItem)="onProviderSelect($event)"
              (blur)="onProviderSet()"
              data-dgat="course-global-add-79h2"
            />
          </dgx-search-field-wrapper>
          <dgx-validate-field
            id="institutionName-error"
            class="block"
            [message]="
              courseGlobalAddForm.get('institutionName').touched &&
              courseGlobalAddForm.get('institutionName').invalid
                ? i18n.CourseFormCtrl_SelectProvider
                : ''
            "
          ></dgx-validate-field>
        </div>

        <!-- Course Title/Name -->
        <div class="reactive-forms__control">
          <dgx-reactive-forms-label for="name" [isRequired]="true">
            {{ i18n.Core_Title }}
          </dgx-reactive-forms-label>
          <dgx-search-field-wrapper type="rounded-full-width">
            <input
              [dgxAutofocus]="false"
              type="text"
              formControlName="name"
              id="name"
              autocomplete="off"
              aria-errormessage="name-error"
              [placeholder]="i18n.CourseFormCtrl_TitlePlaceholder"
              [ngbTypeahead]="onCourseNameSearch"
              [inputFormatter]="inputFormatter"
              [resultTemplate]="resultTemplate"
              (selectItem)="onCourseNameSelect($event)"
              (blur)="onCourseNameSet()"
              data-dgat="course-global-add-923j"
            />
          </dgx-search-field-wrapper>
          <dgx-validate-field
            *ngIf="
              courseGlobalAddForm.get('name').touched &&
              courseGlobalAddForm.get('name').invalid
            "
            id="name-error"
            class="block"
            [message]="i18n.CourseFormCtrl_SelectCourse"
          ></dgx-validate-field>
        </div>

        <span
          *ngIf="isAddingAccreditedCourse"
          class="reactive-forms__row reactive-forms__control"
        >
          <!-- Course Number -->
          <div class="reactive-forms__col-half">
            <dgx-reactive-forms-label for="courseNumber">
              {{ i18n.CourseFormCtrl_CourseNumber }}
            </dgx-reactive-forms-label>
            <input
              id="courseNumber"
              formControlName="courseNumber"
              [placeholder]="i18n.CourseFormCtrl_CourseNumberPlaceholder"
            />
          </div>

          <!-- Credit Hours -->
          <div
            class="reactive-forms__col-half"
            [class.reactive-forms__read-only]="vm.readonly.creditHours"
          >
            <dgx-reactive-forms-label for="creditHours">
              {{ i18n.CourseFormCtrl_CreditHours }}
            </dgx-reactive-forms-label>
            <input
              id="creditHours"
              formControlName="creditHours"
              [placeholder]="i18n.CourseFormCtrl_CreditHoursPlaceholder"
              [readonly]="vm.readonly.creditHours"
            />
          </div>
        </span>

        <span
          *ngIf="isAddingAccreditedCourse"
          class="reactive-forms__row reactive-forms__control"
        >
          <!-- Course Level -->
          <div class="reactive-forms__col-half">
            <dgx-reactive-forms-label for="courseLevel">
              {{ i18n.CourseFormCtrl_CourseLevel }}
            </dgx-reactive-forms-label>
            <dgx-select
              id="courseLevel"
              trackBy="title"
              labelKey="title"
              [options]="vm.allCourseLevels"
              [selectedItem]="{ title: vm.extent?.courseLevel }"
              class="full-width"
              [placeholder]="i18n.CourseFormCtrl_CourseLevelPlaceholder"
              [ariaLabel]="i18n.CourseFormCtrl_CourseLevel"
              dgatInput="course-global-add-9dj3"
              (selection)="onFormControlUpdate('courseLevel', $event.value)"
            ></dgx-select>
          </div>

          <!-- Course Grade -->
          <div class="reactive-forms__col-half">
            <dgx-reactive-forms-label for="courseGrade">
              {{ i18n.CourseFormCtrl_CourseGrade }}
            </dgx-reactive-forms-label>
            <dgx-select
              id="courseGrade"
              class="full-width"
              trackBy="title"
              labelKey="title"
              dgatInput="course-global-add-9234j"
              [options]="vm.allGrades"
              [selectedItem]="{ title: vm.extent?.courseGrade }"
              [placeholder]="i18n.CourseFormCtrl_CourseGradePlaceholder"
              [ariaLabel]="i18n.CourseFormCtrl_CourseGrade"
              (selection)="onFormControlUpdate('courseGrade', $event.value)"
            ></dgx-select>
          </div>
        </span>

        <!-- Date Completed and Verification URL -->
        <ng-container *ngIf="isAddingAccreditedCourse">
          <ng-container *ngTemplateOutlet="dateCompletedField"></ng-container>
        </ng-container>

        <!-- Course URL -->
        <ng-container *ngIf="!isAddingAccreditedCourse">
          <ng-container *ngTemplateOutlet="courseUrlField"></ng-container>
        </ng-container>

        <!-- Description -->
        <div
          *ngIf="!isAddingAccreditedCourse"
          class="reactive-forms__control"
          [class.reactive-forms__read-only]="vm.readonly.courseUrl"
        >
          <dgx-reactive-forms-label for="description">
            {{ i18n.Core_Description }}
          </dgx-reactive-forms-label>
          <textarea
            id="description"
            formControlName="description"
            [attr.maxlength]="vm.limits.description"
            [placeholder]="
              i18n.dgOrgInternalContentForm_CourseDescriptionPlaceholder
            "
            [readonly]="vm.readonly.description"
            aria-describedby="description-character-limit"
            data-dgat="course-global-add-93j"
          ></textarea>
          <div id="description-character-limit" class="a11y-hide">
            {{
              'Core_CharacterLimit'
                | translate: { limit: vm.limits.description }
            }}
          </div>
        </div>

        <!-- Date Completed and Verification URL -->
        <ng-container *ngIf="!isAddingAccreditedCourse">
          <ng-container *ngTemplateOutlet="dateCompletedField"></ng-container>
        </ng-container>

        <!-- Duration -->
        <dgx-duration
          *ngIf="!isAddingAccreditedCourse"
          class="block reactive-forms__control"
          [formModel]="{
            durationHours: vm.durationHours,
            durationMinutes: vm.durationMinutes
          }"
        ></dgx-duration>

        <!-- Content Owner -->
        <ng-container *ngIf="!isAddingAccreditedCourse">
          <ng-container *ngTemplateOutlet="courseOwnerField"></ng-container>
        </ng-container>

        <!-- Comment -->
        <div *ngIf="isAddingAccreditedCourse" class="reactive-forms__control">
          <dgx-reactive-forms-label for="comment">
            {{ i18n.Core_Comment }}
          </dgx-reactive-forms-label>
          <textarea
            formControlName="comment"
            id="comment"
            [attr.placeholder]="i18n.CourseFormCtrl_CommentPlaceholder"
            [attr.maxlength]="3000"
            [attr.aria-label]="i18n.Core_Comment"
            data-dgat="course-global-add-924j"
          ></textarea>
        </div>

        <!-- Content Owner -->
        <ng-container *ngIf="isAddingAccreditedCourse">
          <ng-container *ngTemplateOutlet="courseOwnerField"></ng-container>
        </ng-container>

        <!-- Image -->
        <div *ngIf="!isAddingAccreditedCourse" class="reactive-forms__control">
          <dgx-upload-section
            [imageUrl]="vm.imageUrl"
            [uploadAdapter]="vm.imageUploadAdapter"
            (imageParsedEvent)="vm.imageUpload.onParse($event)"
            (uploadSuccessEvent)="
              vm.imageUpload.onUpload(courseGlobalAddForm, $event)
            "
            (deleteEvent)="vm.imageUpload.onDelete(courseGlobalAddForm)"
            [useCropper]="true"
            [aspectRatio]="16 / 9"
            [secondaryAspectRatio]="16 / 5"
            [useBrowserReplace]="true"
          >
          </dgx-upload-section>
        </div>

        <!-- Skills-->
        <div class="reactive-forms__control">
          <dgx-tags-edit
            [hideHint]="true"
            [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
            [customTitle]="i18n.Core_Skills"
            [topTags]="vm.mediumConfidenceInferredSkills"
            [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
            [hideTooltip]="false"
            [tooltipIcon]="'info'"
            [placeholder]="i18n.MediaFormCtrl_Skills_Placeholder"
            [allowExistingOnly]="false"
            [tags]="courseGlobalAddForm.get('tags').value"
            [showFullWidthLabel]="true"
            [useNewTagsDesign]="true"
            (tagsChange)="onFormControlUpdate('tags', $event)"
            aria-errormessage="tags-error"
          ></dgx-tags-edit>
          <dgx-validate-field
            *ngIf="
              courseGlobalAddForm.get('tags').touched &&
              courseGlobalAddForm.get('tags').invalid
            "
            id="tags-error"
            class="block"
            [message]="i18n.dgOrgInternalContent_SkillsMaxError"
          ></dgx-validate-field>
        </div>
      </div>
    </ng-template>

    <!-- Result Template For Search Fields -->
    <ng-template #resultTemplate let-item="result" let-term="term">
      <span class="listbox--option">
        <ngb-highlight
          [result]="item.value ?? item"
          [term]="term"
          [highlightClass]="'font-semibold'"
        ></ngb-highlight>
      </span>
    </ng-template>

    <!-- Course URL Template -->
    <ng-template #courseUrlField>
      <div
        class="reactive-forms__control"
        [class.reactive-forms__read-only]="vm.readonly.courseUrl"
      >
        <dgx-reactive-forms-label
          for="courseUrl"
          [ariaLabel]="
            vm.isInitialForm ? i18n.CourseFormCtrl_CourseUrlAria : ''
          "
        >
          {{ i18n.CourseFormCtrl_CourseUrl }}
        </dgx-reactive-forms-label>
        <input
          #courseUrl
          autocomplete="off"
          type="url"
          id="courseUrl"
          formControlName="courseUrl"
          placeholder="http://"
          aria-errormessage="courseUrl-error"
          [readonly]="vm.readonly.courseUrl"
          (input)="(undefined)"
          data-dgat="course-pathways-plans-fb9"
        />
        <dgx-validate-field
          *ngIf="
            courseGlobalAddForm.get('courseUrl').touched &&
            courseGlobalAddForm.get('courseUrl').invalid
          "
          id="courseUrl-error"
          class="block"
          [message]="i18n.MediaFormCtrl_UrlRequired"
        ></dgx-validate-field>
      </div>
    </ng-template>

    <!-- Course Owner Template -->
    <ng-template #courseOwnerField>
      <div class="reactive-forms__control">
        <dgx-reactive-forms-label
          for="owner"
          icon="info"
          [isRequired]="false"
          [iconTooltip]="i18n.dgOrgInternalContentForm_ContentOwnerTooltip"
          [iconA11yText]="i18n.Core_MoreInfo"
        >
          {{ i18n.dgOrgInternalContentForm_ContentOwner }}
        </dgx-reactive-forms-label>
        <dgx-user-search
          id="owner"
          aria-errormessage="owner-error"
          [useSearchField]="false"
          [recipients]="!!vm.owner ? [vm.owner] : []"
          [search]="userGroupListService.loadGroupsUsersRecommendees"
          [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
          [allowRemoval]="true"
          [isRequired]="false"
          [placeholder]="i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder"
          (removeRecipient)="onContentOwnerChange()"
          (selectRecipient)="onContentOwnerChange($event)"
        ></dgx-user-search></div
    ></ng-template>

    <!-- Course Completion Date & Verification Template -->
    <ng-template #dateCompletedField>
      <span class="reactive-forms__row reactive-forms__control">
        <!-- Date Completed -->
        <div class="reactive-forms__col-half">
          <dgx-reactive-forms-label for="dateCompleted">
            {{ i18n.CourseFormCtrl_DateCompleted }}
          </dgx-reactive-forms-label>
          <div
            class="reactive-forms__control field-group session-details__date-wrapper"
          >
            <span class="field-group__input field-group__input--tag">
              <df-icon
                icon="calendar"
                class="guts-m-r-1 color-ebony-a61"
              ></df-icon>
              <input
                id="dateCompleted"
                formControlName="dateCompleted"
                type="text"
                class="field-group--autowidth"
                #datePicker="ngbDatepicker"
                autocomplete="off"
                ngbDatepicker
                [firstDayOfWeek]="7"
                [readonly]="true"
                (click)="datePicker.toggle()"
                [placeholder]="i18n.CourseFormCtrl_DateCompletedPlaceholder"
                data-dgat="course-global-add-933j"
              />
            </span>
          </div>
        </div>
        <!-- Verification URL -->
        <div class="reactive-forms__col-half">
          <dgx-reactive-forms-label for="verificationUrl">
            {{ i18n.CourseFormCtrl_VerificationUrl }}
          </dgx-reactive-forms-label>
          <input
            type="url"
            id="verificationUrl"
            formControlName="verificationUrl"
            placeholder="http://"
            [attr.aria-errormessage]="'verificationUrl-error'"
            data-dgat="course-global-add-953j"
          />
          <dgx-validate-field
            *ngIf="
              courseGlobalAddForm.get('verificationUrl').touched &&
              courseGlobalAddForm.get('verificationUrl').invalid
            "
            id="verificationUrl-error"
            class="block"
            [message]="i18n.MediaFormCtrl_UrlRequired"
          ></dgx-validate-field>
        </div>
      </span>
    </ng-template>
  </form>
</ng-container>
