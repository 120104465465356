<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!articlePlanPathForm"
    #formRef="ngForm"
    class="reactive-forms"
    [formGroup]="articlePlanPathForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.MediaFormCtrl_SaveArticle
      "
      [canCancel]="true"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      bodyClasses="guts-p-h-2 guts-p-v-2"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <!-- Notification-->
        <df-local-notification
          *ngIf="vm.inputContext.isEditing; else orgManagedCheck"
          class="block guts-m-b-1-half"
          size="small"
          [text]="editContentNotificationText"
          [type]="NotificationType.info"
        ></df-local-notification>

        <ng-template #orgManagedCheck>
          <dgx-org-managed-notification
            class="guts-m-b-1 block"
            *ngIf="
              articlePlanPathForm.get('addToCatalog')?.value &&
              !vm.inputContext.organizationId
            "
            [resourceType]="vm.inputContext.pathwayId ? 'Pathway' : 'SkillPlan'"
            link="/articles/5900536194588-About-Org-managed-Content"
            [linkTextKey]="i18n.Core_OrgManagedDocLinkLabel"
          ></dgx-org-managed-notification>
        </ng-template>

        <!-- Add to Catalog -->
        <ng-container
          *ngIf="
            vm.canManageContent &&
            showExpandedForm &&
            !vm.inputContext.isEditing
          "
        >
          <div class="reactive-forms__control">
            <dgx-add-to-catalog
              [checkboxLabel]="addToCatalogCheckboxLabel"
            ></dgx-add-to-catalog>
          </div>
        </ng-container>

        <!-- URL input-->
        <div
          *ngIf="
            isEditingContentLocally
              ? !!vm.entryUrl
              : articlePlanPathForm.get('entryUrl') && !hideUrlField
          "
          class="reactive-forms__control"
          [ngClass]="{ 'reactive-forms__read-only': isEditingContentLocally }"
        >
          <dgx-reactive-forms-label
            for="entryUrl"
            [isRequired]="!isEditingContentLocally"
          >
            {{ i18n.MediaFormCtrl_ArticleUrl }}
          </dgx-reactive-forms-label>
          <input
            #entryUrl
            type="url"
            id="entryUrl"
            formControlName="entryUrl"
            data-dgat="articlePathPlanForm-343j"
            placeholder="http://"
            [dgxAutofocus]="!isEditingContentLocally"
            [readonly]="isEditingContentLocally"
            autocomplete="off"
          />
          <!-- Note http is the same for all countries -->
          <dgx-check-duplicate-inputs
            *ngIf="showEntryUrlDuplicateCheck"
            [duplicateCount]="vm.duplicateCount"
            (viewDuplicates)="openViewDuplicates()"
          >
          </dgx-check-duplicate-inputs>
          <dgx-validate-field
            class="block"
            *ngIf="
              articlePlanPathForm.get('entryUrl')?.dirty &&
              articlePlanPathForm.get('entryUrl').invalid
            "
            [message]="i18n.MediaFormCtrl_UrlRequired"
          ></dgx-validate-field>
        </div>

        <!-- Expanded Form -->
        <ng-container *ngIf="showExpandedForm">
          <!-- Title -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [dgxAutofocus]="isEditingContentLocally"
              [placeholder]="i18n.dgFlexRow_EditSectionTitlePlaceholder"
              data-dgat="article-pathways-plans-54a"
              (blur)="
                vm.loadInferredSkills(
                  articlePlanPathForm.get('title').value,
                  articlePlanPathForm.get('description').value
                )
              "
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                articlePlanPathForm.get('title').touched &&
                articlePlanPathForm.get('title').invalid
              "
              [message]="i18n.MediaFormCtrl_TitleRequired"
            ></dgx-validate-field>
          </div>

          <!-- Source -->
          <ng-container *ngIf="vm.sourceName">
            <div class="reactive-forms__control reactive-forms__read-only">
              <dgx-reactive-forms-label for="sourceName">
                {{ i18n.Core_Source }}
              </dgx-reactive-forms-label>
              <input
                id="sourceName"
                formControlName="sourceName"
                readonly="true"
                data-dgat="article-pathways-plans-1d1"
              />
            </div>
          </ng-container>

          <!-- Article Format -->
          <ng-container *ngIf="showExtraFields">
            <div class="reactive-forms__control">
              <dgx-reactive-forms-label for="format">
                {{ i18n.dgOrgInternalContentForm_ArticleFormat }}
              </dgx-reactive-forms-label>
              <dgx-select
                id="format"
                class="full-width"
                trackBy="title"
                labelKey="title"
                [ariaLabel]="i18n.dgOrgInternalContentForm_ArticleFormat"
                [selectedItem]="{ title: vm.format }"
                [options]="vm.inputTypeFormats"
                [placeholder]="
                  i18n.dgOrgInternalContentForm_ArticleFormatSelectPlaceholder
                "
                dgatInput="article-pathways-plans-a3d"
                (selection)="onFormControlUpdate('format', $event.title)"
              >
              </dgx-select>
            </div>
          </ng-container>

          <!-- Description -->
          <div
            *ngIf="articlePlanPathForm.get('description')"
            class="reactive-forms__control"
          >
            <dgx-reactive-forms-label for="description">
              {{ i18n.Core_Description }}
            </dgx-reactive-forms-label>
            <textarea
              id="description"
              formControlName="description"
              [attr.maxlength]="3000"
              [attr.aria-label]="i18n.Core_Description"
              [attr.placeholder]="
                i18n.dgOrgInternalContentForm_ArticleDescriptionPlaceholder
              "
              data-dgat="article-pathways-plans-138"
              (blur)="
                vm.loadInferredSkills(
                  articlePlanPathForm.get('title').value,
                  articlePlanPathForm.get('description').value
                )
              "
            ></textarea>
          </div>

          <!-- Duration -->
          <div
            *ngIf="shouldShowDurationField"
            class="reactive-forms__control"
            [ngClass]="{ 'reactive-forms__read-only': isEditingContentLocally }"
          >
            <dgx-duration
              [formModel]="{
                durationHours: vm.durationHours,
                durationMinutes: vm.durationMinutes
              }"
              [isReadOnly]="isEditingContentLocally"
            ></dgx-duration>
          </div>

          <!-- Content Owner -->
          <div
            *ngIf="shouldShowContentOwnerField"
            class="form-wrap__group"
            [ngClass]="{ 'reactive-forms__read-only': isEditingContentLocally }"
          >
            <dgx-reactive-forms-label
              for="owner"
              [icon]="!isEditingContentLocally ? 'info' : null"
              [isRequired]="
                this.articlePlanPathForm.get('addToCatalog')?.value ||
                vm.inputContext.isEditingInternalContent
              "
              [iconTooltip]="i18n.dgOrgInternalContentForm_ContentOwnerTooltip"
              [iconA11yText]="i18n.Core_MoreInfo"
            >
              {{ i18n.dgOrgInternalContentForm_ContentOwner }}
            </dgx-reactive-forms-label>
            <dgx-user-search
              id="owner"
              [useSearchField]="false"
              [recipients]="!!vm.owner ? [vm.owner] : []"
              [search]="userGroupListService.loadGroupsUsersRecommendees"
              [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
              [allowRemoval]="!isEditingContentLocally"
              (removeRecipient)="onContentOwnerChange(undefined)"
              (selectRecipient)="onContentOwnerChange($event)"
              [placeholder]="
                i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder
              "
              [isReadOnly]="isEditingContentLocally"
            ></dgx-user-search>
            <dgx-validate-field
              class="block"
              *ngIf="
                articlePlanPathForm.get('owner').touched &&
                articlePlanPathForm.get('owner').invalid
              "
              [message]="
                articlePlanPathForm
                  .get('owner')
                  .hasError('validContentOwnerNeeded')
                  ? i18n.MediaFormCtrl_ValidContentOwnerRequired
                  : i18n.dgOrgInternalContentForm_ContentOwnerRequired
              "
            ></dgx-validate-field>
          </div>

          <!-- Image -->
          <div class="reactive-forms__control">
            <dgx-upload-section
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.imageUploadAdapter"
              (imageParsedEvent)="vm.imageUpload.onParse($event)"
              (uploadSuccessEvent)="
                vm.imageUpload.onUpload(articlePlanPathForm, $event)
              "
              (deleteEvent)="vm.imageUpload.onDelete(articlePlanPathForm)"
              [useCropper]="true"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [useBrowserReplace]="true"
            >
            </dgx-upload-section>
          </div>

          <!-- Skills -->
          <div *ngIf="!isEditingContentLocally" class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.mediumConfidenceInferredSkills"
              [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="
                vm.highConfidenceInferredSkills
                  ? i18n.dgOrgInternalContent_SkillsPlaceholderText
                  : ''
              "
              [allowExistingOnly]="false"
              [tags]="articlePlanPathForm.get('skills').value"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                articlePlanPathForm.get('skills').touched &&
                articlePlanPathForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>

          <!-- Advanced Settings -->
          <ng-container *ngIf="showExtraFields">
            <dgx-advanced-settings
              [formModel]="{
                visibility: vm.orgContentMetadata,
                publishedDate: vm.publishDate,
                internalItemId: vm.externalId,
                language: vm.language
              }"
            ></dgx-advanced-settings>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Modal footer -->
      <ng-container class="modal-footer"></ng-container>
    </dgx-modal>
  </form>
</ng-container>
