import { Injectable } from '@angular/core';
import { LDFlagsService } from '@dg/shared-services';

/**
 * A centralized location for LD flags used by Mentoring, to help us
 * avoid too many API calls.
 *
 * TODO: Move to a global service used by the whole app? One with the
 * functionality to allow flag values to be re-checked when changes get
 * made (say, to the groups a user belongs to).
 *
 *  @deprecated - Use LDFlagsService.mentoring for new flags
 */
@Injectable({
  providedIn: 'root',
})
export class MentoringFlagsService {
  /** Whether the new mentorship options are ready. */

  public get showMentorshipOptions(): boolean {
    return this.ldFlagsService.mentoring.dcmMentorshipOpportunities;
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
