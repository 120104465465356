<div class="l_flexbar m-guts-p-t-1">
  <button
    [class]="buttonClass"
    (click)="buttonAction($event)"
    data-dgat="link-button-8f9"
    type="button"
  >
    <df-icon icon="{{ icon }}" class="guts-m-r-half" size="small"></df-icon>
    <span class="ib-v-middle guts-m-l-quart">{{ displayText }}</span>
  </button>
</div>
