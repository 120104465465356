import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-skill-standards-publish-status-badge',
  templateUrl: './skill-standards-publish-status-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkillStandardsPublishStatusBadgeComponent implements OnInit, OnChanges {
  @Input() public status: number;
  @Input() public size: string;

  public i18n = this.translateService.instant([
    'Core_withPublished',
    'SkillStd_Unpublished',
  ]);
  public isPublished = false;
  public showBadge = true;
  public badgeClasses: string[] = [];

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.init();
  }

  public ngOnChanges() {
    this.init();
  }

  private init(): void {
    if (this.status === 1) {
      this.isPublished = true;
    } else if (this.status === 0) {
      this.isPublished = false;
    } else {
      this.showBadge = false;
      return;
    }
    this.badgeClasses = [];
    this.badgeClasses.push(
      this.isPublished ? 'badge-neutral' : 'badge-attention'
    );
    if (this.size) {
      this.badgeClasses.push(`badge-pill--${this.size}`);
    }
  }
}
