// NOTE: must be imported first to configure the environment
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';
import './environments/overrides';
import { AppMsTeamsBootstrapModule } from '@app/ms-teams/app-ms-teams-bootstrap.module';
import { AppBrowserExtensionBootstrapModule } from '@app/browser-extension/app-browser-extension-bootstrap.module';

if (environment.isProduction) {
  enableProdMode();
}

/*
  Conditionally Bootstrapping two apps:
   - LXP/Organization Management - AppModule
   - MSTeams - AppMSTeamsBootstrapModule
   - Chrome Extension - AppBrowserExtensionBootstrapModule

   They both use the <dgx-root> from the _layout.cshtml file, which means we can't load both of them at the same time or 
   they end up conflicting with each other. To get around that we have to check the route, which is unique to ms teams 
   and only launch that app. 
*/
if (location.pathname.includes('/degreed-ms-teams/')) {
  platformBrowserDynamic([]).bootstrapModule(AppMsTeamsBootstrapModule);
} else if (location.pathname.includes('/degreed-button/')) {
  platformBrowserDynamic([]).bootstrapModule(
    AppBrowserExtensionBootstrapModule
  );
} else {
  platformBrowserDynamic([]).bootstrapModule(AppModule);
}
