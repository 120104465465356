<!-- <div class="tile break rel l_flex updated-content-card" > -->
<article
  class="tile l_flex l_flex-column full-width m-l_flex break rel guts-p-h-1 guts-p-v-1"
  *ngIf="skillStandard"
>
  <section class="l_flex l_flex-column l_flex-grow full-width">
    <!-- Title -->
    <header class="tile__content-head guts-m-b-half">
      <a
        [routerLink]="
          skillStandard.isAssigned
            ? getViewDetailsLink(skillStandard.skillStandardId)
            : getEditDetailsLink(skillStandard.skillStandardId)
        "
        data-dgat="skill-standards-card-95c"
      >
        <h3 class="h3 standard-name">{{ skillStandard.skillStandardName }}</h3>
      </a>
    </header>
    <!-- Skill Count -->
    <div class="par--light_a meta-skill-count v-middle-children guts-p-b-1">
      <span *ngIf="showSkillCount" class="guts-p-r-1">
        {{ skillStandard.skillCount }} {{ 'Core_Skills' | translate }}
      </span>
      <span *ngIf="showCollaboratorIcon">
        <img
          [src]="'/content/img/home/collaborator-icon.png' | blobifyUrl: true"
          class="collaborator-icon"
          [attr.alt]="i18n.Core_Collaborator"
          [attr.aria-label]="i18n.Core_Collaborator"
        />
        <span>
          {{ i18n.Core_Collaborator }}
        </span>
      </span>
    </div>
    <!-- Description -->
    <p class="gut-m-b-1 meta-list standard-description">
      {{ skillStandard.description }}
    </p>
  </section>
  <!-- Footer -->
  <footer class="tile__footer guts-m-t-2">
    <div class="l_flex l_flex-grow l_flex-wrap l_flex-center-children">
      <ng-container [ngSwitch]="skillStandard.isAssigned">
        <a
          *ngSwitchCase="true"
          [routerLink]="getViewDetailsLink(skillStandard.skillStandardId)"
          class="action-link bg-color-ebony-a08 color-ebony font-semibold guts-m-r-half btn btn-sm rounded"
          data-dgat="skill-standards-card-28b"
        >
          {{ 'Core_ViewDetails' | translate }}
        </a>
        <a
          *ngSwitchDefault
          [routerLink]="getEditDetailsLink(skillStandard.skillStandardId)"
          class="action-link bg-color-ebony-a08 color-ebony font-semibold guts-m-r-half btn btn-sm rounded"
          data-dgat="skill-standards-card-a1a"
        >
          {{ 'Core_EditDetails' | translate }}
        </a>
      </ng-container>
    </div>
  </footer>
</article>
<!-- </div> -->
