import { Pipe, PipeTransform } from '@angular/core';

/** Transforms a string potentially containing urls, by replacing them with anchor tags.
 * This should be used in conjunction with DomeSanitizer or SafePipe for binding
 */
@Pipe({ name: 'anchorify' })
export class AnchorifyPipe implements PipeTransform {
  public transform(text: any, addLinkClass?: boolean) {
    // TODO: consider using something more robust like linkify.js instead of regex
    const urlExp = /(\b(https?|ftp):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gi;
    const linkClass = addLinkClass ? ' link' : '';
    const replacement = `<a class="underline hyphenate${linkClass}" href="$1" target="_blank" rel="noopener noreferrer nofollow">$1</a>`;
    return String(text).replace(urlExp, replacement);
  }
}
