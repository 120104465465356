import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { A11yService } from '@app/shared/services/a11y.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-th-sort',
  templateUrl: './th-sort.component.html',
  styleUrls: ['./th-sort.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThSortComponent implements OnInit {
  // Bindings
  // - input
  @Input() public buttonLabel: string;
  @Input() public isDescending?: boolean | '' = '';
  @Input() public isDisabled?: boolean = false;
  // - output
  @Output() public onSort: EventEmitter<string> = new EventEmitter();

  // Local
  // - public
  public ariaLabel: string;

  constructor(
    private a11yService: A11yService,
    private translate: TranslateService
  ) {}

  // Getters
  public get label() {
    return this.isDisabled
      ? this.translate.instant('Core_Disabled')
      : this.ariaLabel;
  }

  public get role() {
    return this.isDisabled ? '' : 'button';
  }

  public get tabindex() {
    return this.isDisabled ? '' : 0;
  }

  // Methods
  // - angular

  public ngOnInit(): void {
    if (this.isDescending === '') {
      this.isDescending = true;
    }
    if (!this.isDisabled) {
      this.updateAria();
    }
  }

  // - public

  /**
   * Update aria label and emit to parent.
   */
  public sort(): void {
    if (this.isDisabled) {
      return;
    }
    this.isDescending = !this.isDescending;
    this.onSort.emit();
    this.updateAria();
  }

  // - private
  private getState() {
    let currentState: string;
    let nextState: string;
    switch (this.isDescending) {
      case true:
        currentState = 'Descending';
        nextState = 'Ascending';
        break;
      case false:
        currentState = 'Ascending';
        nextState = 'Descending';
        break;
      default:
        currentState = 'none';
        nextState = 'Ascending';
    }
    return [currentState, nextState];
  }

  private updateAria() {
    if (this.isDisabled) {
      return;
    }
    const [currentState, nextState] = this.getState();
    if (currentState !== 'none') {
      this.ariaLabel = this.translate.instant(
        `dgThSort_Sort${nextState}Format`,
        {
          columnName: this.buttonLabel,
        }
      );
      this.a11yService.announcePolite(
        this.translate.instant(`dgThSort_Sorted${currentState}Format`, {
          columnName: this.buttonLabel,
        })
      );
    }
  }
}
