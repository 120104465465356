import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a new user follower notification */
@Component({
  selector: 'dgx-new-user-follower',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUserFollowerComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('newUserFollower_SingleFormat', {
      nameHtml: this.userFullNameTemplate,
    }); // "{{nameHtml}} is now following you"
  }
}
