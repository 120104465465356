import { Inject, Injectable } from '@angular/core';
import {
  EvaluationQuestionResult,
  UserEvaluation,
} from '@app/evaluation/evaluation';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { SlugifyPipe } from '@app/shared/pipes/slugify.pipe';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { WindowToken } from '@app/shared/window.token';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { InternalTagRatingTypes } from '../tags';

@Injectable({ providedIn: 'root' })
export class EvaluationService extends ApiServiceBase {
  constructor(
    private authService: AuthService,
    public http: NgxHttpClient,
    private modalService: ModalService,
    private translateService: TranslateService,
    private slugifyPipe: SlugifyPipe,
    private tagRatingTrackerService: TagRatingTrackerService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super(http, translateService.instant('InputsSvc_GeneralError'));
  }

  /**
   * API methods
   */

  public getUserEvaluation(
    tagId: number,
    userKey: number
  ): Observable<UserEvaluation> {
    return this.get(
      '/evaluations/GetUserEvaluation',
      { tagId, userKey },
      this.translateService.instant('EvaluationSvc_RetrieveError')
    );
  }

  /**
   * For getting the sub-task result ratings
   * Returns an array of sub-task ratings
   */
  public getUserEvaluationStepResults(
    uid: string
  ): Observable<EvaluationQuestionResult[]> {
    return this.get(
      '/evaluations/GetUserEvaluationStepResults',
      { uid },
      this.translateService.instant('EvaluationSvc_RetrieveError')
    );
  }

  /**
   * Determine whether evaluations are allowed for a `tag`
   *
   * @param tagId
   */
  public getIsEvaluableByTagId(tagId: number): Observable<boolean> {
    return this.get(
      '/tag/IsEvaluatable',
      { tagId },
      this.translateService.instant('CredentialSvc_GetCredsError')
    );
  }

  /**
   * Delete a partially complete evaluation
   *
   * @param uid
   */
  public deleteUserEvaluation(uid: string): Observable<void> {
    return this.delete(
      '/evaluations/DeleteUserEvaluation',
      { uid },
      this.translateService.instant('EvaluationSvc_DeleteError')
    );
  }

  /**
   * Helper methods
   */

  // our slugify pipe is better for skills containing characters such as C#, C++,
  // etc. than the server is. So we need to intercede here and generate the URL ourselves.
  // https://github.com/a8m/angular-filter#slugify
  /**
   * @param tagName
   * @param tagId
   * @param forceNew `true` to force start of new evaluation if completed evaluation already exists
   */
  public getEvaluationUrl(
    tagName: string,
    tagId?: number,
    forceNew: boolean = false
  ): string {
    if (tagName) {
      const params = [`tagId=${tagId}`];
      if (forceNew) {
        params.push(`forceNew=true`);
      }
      tagName = this.slugifyPipe.transform(tagName, true);

      return `/skillreview/application/${tagName}/questions?${params.join(
        '&'
      )}`;
    }
  }

  public goToEvaluation(route: string): void {
    this.modalService.dismissAll();
    this.windowRef.open(route, '_self');
  }

  /**
   * Start a new evaluation for a `tag` the user has not previously started or completed an evaluation for
   *
   * @param tagName
   * @param tagId
   */
  public startEvaluation(tag: TagsApi.TagDetails, element?: HTMLElement): void {
    this.tagRatingTrackerService.trackRatingUpdateInitiated(
      element,
      tag,
      InternalTagRatingTypes.evaluation,
      this.authService.authUser?.viewerProfile?.userProfileKey // logged in user is the owner here
    );
    this.goToEvaluation(this.getEvaluationUrl(tag.name, tag.tagId));
  }

  /**
   * Force the start of a new evaluation when the user already has a completed evaluation for the given `tag`
   * NOTE: Should NOT be used if the user has a partial evlaution in process for the given `tag`
   *
   * @param tagName
   * @param tagId
   */
  public forceStartEvaluation(tag: TagsApi.Tag, element?: HTMLElement): void {
    this.tagRatingTrackerService.trackRatingUpdateInitiated(
      element,
      tag,
      InternalTagRatingTypes.evaluation,
      this.authService.authUser?.viewerProfile?.userProfileKey // logged in user is the owner here
    );

    this.goToEvaluation(this.getEvaluationUrl(tag.name, tag.tagId, true));
  }

  /**
   * Review the results of a completed `tag` evaluation
   *
   * @param tagName
   * @param tagId
   */
  public reviewEvaluationResults(tagName: string, tagId: number): void {
    const slugifiedTagName = this.slugifyPipe.transform(tagName, true);
    this.goToEvaluation(
      `/skillreview/application/${slugifiedTagName}/results?tagId=${tagId}`
    );
  }

  /**
   * Continue a `tag` evaluation that's already in process
   *
   * @param tagName
   * @param tagId
   */
  public continueEvaluation(tagName: string, tagId: number): void {
    this.goToEvaluation(this.getEvaluationUrl(tagName, tagId));
  }
}
