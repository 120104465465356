import { Inject, Injectable } from '@angular/core';
import { A11yService } from '@app/shared/services/a11y.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgPageHeaderService {
  public $pageHeader = new ReplaySubject<string>();
  public $windowTitle = new ReplaySubject<string>();
  public $showPageHeader = new ReplaySubject<boolean>();

  private pageHeader: string;
  private windowTitle: string;
  private showPageHeader: boolean;

  constructor(
    private a11yService: A11yService,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public setPageHeader(key: string) {
    this.pageHeader = this.translateService.instant(key);
    this.$pageHeader.next(this.pageHeader);
  }

  public setWindowTitle(key: string) {
    this.windowTitle = this.translateService.instant(key);
    this.$windowTitle.next(this.windowTitle);

    this.windowRef.document.title = `${this.windowTitle} | Degreed`;
    this.a11yService.announcePolite(
      this.translateService.instant('A11y_TabChangeAnnouncement', {
        newPageTitle: this.windowTitle,
      })
    );
  }

  public setShowPageHeader(visible: boolean) {
    this.showPageHeader = visible;
    this.$showPageHeader.next(this.showPageHeader);
  }
}
