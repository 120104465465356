import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { WindowToken } from '../window.token';

declare global {
  interface Window {
    __dg_preload: Record<string, XMLHttpRequest>;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PreloadService {
  private requests = this.window.__dg_preload ?? {};

  constructor(@Inject(WindowToken) private window: Window) {
    window.__dg_preload = undefined;
  }

  public get<T>(search: string): Observable<T> {
    if (this.requests[search]) {
      return this.xhrToObservable<T>(this.requests[search]);
    }
  }

  public xhrToObservable<T>(xhr: XMLHttpRequest): Observable<T> {
    if (xhr.readyState === 4) {
      // The request already completed
      if (xhr.status === 200) {
        try {
          return of(
            // IE11 will ignore responseType and response will always be a string
            typeof xhr.response === 'string'
              ? JSON.parse(xhr.response)
              : xhr.response
          );
        } catch {
          return throwError(xhr);
        }
      } else {
        return throwError(xhr);
      }
    } else {
      return new Observable((subscriber) => {
        const callback = () => {
          // readyState guaranteed to be 4 here
          this.xhrToObservable(xhr).subscribe(subscriber);
        };
        xhr.addEventListener('loadend', callback);
        return () => {
          xhr.removeEventListener('loadend', callback);
        };
      });
    }
  }
}
