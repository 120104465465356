import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfIconComponent } from './icon.component';

@NgModule({
  declarations: [DfIconComponent],
  imports: [CommonModule],
  exports: [DfIconComponent],
})
export class DfIconModule {}
