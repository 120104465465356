import { Component, Inject, Input, OnInit } from '@angular/core';
import { EngagedLearnerService } from '@app/shared/services/engaged-learner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-profile-engagement',
  templateUrl: './profile-engagement.component.html',
  styleUrls: ['./profile-engagement.component.scss'],
})
export class ProfileEngagementComponent implements OnInit {
  /**
   * This is a private component used by dgx-profile-pic to add a decoration to the
   * learner's profile picture if they are an engaged user.
   */

  // Bindings
  /** Size of the profile pic this component is decorating */
  @Input() public size: number;

  // Local
  public i18n = this.translate.instant(['Core_ActiveLearner']);

  // Is the feature enabled for this user
  public isEnabled = undefined as boolean;

  // Size of the SVG element calculated from the profile pic size binding
  public svgSize: number;

  // Amount of negative offset to center the avatar and the SVG
  public offsetX: number;
  public offsetY: number;

  // Stroke width of the decoration
  public stroke: number;

  private ProfilePicSmall = 24;
  private ProfilePicMedium = 48;

  constructor(
    private translate: TranslateService,
    private engagedLearner: EngagedLearnerService
  ) {}

  public ngOnInit() {
    this.isEnabled = this.engagedLearner.isEnabled;

    let stroke: number, whitespace: number;
    if (this.size <= this.ProfilePicSmall) {
      stroke = 2;
      whitespace = 1;
    } else if (this.size <= this.ProfilePicMedium) {
      stroke = 2;
      whitespace = 2;
    } else {
      stroke = 3;
      whitespace = 3;
    }

    this.svgSize = this.size + 2 * (stroke + whitespace);
    this.offsetY = (this.size - this.svgSize) / 2;
    this.offsetX = this.engagedLearner.invertHorizOffset
      ? -this.offsetY
      : this.offsetY;
    this.stroke = stroke * (100 / this.svgSize);
  }
}
