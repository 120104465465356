import { ElementRef, Inject, Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import {
  FeedbackOptions,
  FeedbackService,
} from '@app/feedback/services/feedback.service';
import {
  AnyLearningResource,
  LearningResourceViewModel,
} from '@app/inputs/models/learning-resource.view-model';
import { InputPermissionsService } from '@app/inputs/services/input-permissions.service';
import { OrgPathwaysService } from '@app/orgs/services/org-pathways.service';
import { AddToPathwayModalService } from '@app/pathways/services/add-to-pathway-modal.service';
import { PathwayAddEditFormModalService } from '@app/pathways/services/pathway-add-edit-form-modal-service';
import { PathwaySummaryModel } from '@app/pathways/pathway-api.model';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { QueueService } from '@app/shared/services/queue.service';
import { TargetsService } from '@app/shared/services/targets.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowToken } from '@app/shared/window.token';
import { TargetService } from '@app/target/services/target.service';
import { Target, TargetSuggestionType } from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EndorseContentService } from '../services/endorse-content.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceCardMenuService {
  public i18n = this.translate.instant([
    'Core_SaveForLater',
    'Core_Unsave',
    'Core_Share',
    'Core_AddToPathway',
    'Core_AddToJobRole',
    'Core_ReportAProblem',
    'Core_MoreDetails',
    'Core_Clone',
    'Core_Insights',
    'Core_FeatureResource',
    'Core_UnfeatureResource',
    'Pathways_AddAuthorNote',
  ]);

  public hasFeaturedPlan: boolean = false;
  public onUpdate$ = new Subject<LearningResourceViewModel>();

  constructor(
    @Inject(WindowToken) private windowRef: Window,
    private addToPathwayModalService: AddToPathwayModalService,
    private authService: AuthService,
    private contextService: ContextService,
    private feedbackService: FeedbackService,
    private notifier: NotifierService,
    private orgPathwaysService: OrgPathwaysService,
    private pathwayAddEditFormModalService: PathwayAddEditFormModalService,
    private targetsService: TargetsService,
    private targetService: TargetService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private queueService: QueueService,
    private recommendationsModalService: RecommendationsModalService,
    private inputPermissionsService: InputPermissionsService,
    private endorseContentService: EndorseContentService
  ) {
    const authUser = this.authService.authUser;
    if (authUser) {
      this.targetsService
        .getBrowseTarget(authUser.defaultOrgId)
        .subscribe((data) => {
          this.hasFeaturedPlan = !!data?.targetId;
        });
    }
  }

  public getResourceMenu(
    resource: LearningResourceViewModel,
    isModal: boolean = false,
    location: string = '',
    isSearchResult: boolean = false
  ) {
    const authUser = this.authService.authUser;
    if (!resource || !authUser) {
      return [];
    }

    const menuConfig: MenuViewModel[] = [
      {
        title: this.i18n.Core_Clone,
        isHidden: () =>
          !authUser ||
          (!resource.isTarget && !resource.isPathway) ||
          (resource.isTarget && !authUser.canManageTargets) ||
          !!resource.model.marketplaceInputId,
        defaultAction: (_, popoverTrigger) =>
          resource.isTarget
            ? this.cloneTarget(resource, popoverTrigger)
            : this.clonePathway(resource, popoverTrigger),
      },
      {
        title: this.i18n.Core_SaveForLater,
        isHidden: () => {
          return (
            isSearchResult ||
            isModal ||
            resource.isTarget ||
            resource.isQueued ||
            resource.completionInfo?.isCompleted ||
            !!resource.model.marketplaceInputId
          );
        },
        defaultAction: (_) =>
          this.saveForLater({
            resourceType: resource.resourceType,
            resourceId: resource.resourceId,
            title: resource.title,
            isEndorsed: resource.isEndorsed,
          }).subscribe((result) => {
            resource.queueItemId = result;
            resource.isQueued = true;
            this.onUpdate$.next(resource);
          }),
      },
      {
        title: this.i18n.Core_Unsave,
        isHidden: () => {
          return (
            isSearchResult ||
            isModal ||
            resource.isTarget ||
            !resource.isQueued ||
            resource.completionInfo?.isCompleted ||
            !!resource.model.marketplaceInputId
          );
        },
        defaultAction: (_) =>
          this.unsave({
            resourceId: resource.resourceId,
            resourceType: resource.resourceType,
            queueItemId: resource.queueItemId,
          }).subscribe(() => {
            resource.queueItemId = null;
            resource.isQueued = false;
            this.onUpdate$.next(resource);
          }),
      },
      {
        title: this.i18n.Core_Share,
        preventRefocus: true,
        isHidden: () =>
          isModal ||
          !authUser ||
          !authUser.canRecommendItems ||
          !!resource.model.marketplaceInputId,
        defaultAction: (_, popoverTrigger) => {
          const model = resource.model || resource;
          this.showShareModal(
            popoverTrigger?.nativeElement,
            model as AnyLearningResource
          );
        },
      },
      {
        title: this.i18n.Core_Insights,
        isHidden: () =>
          !authUser ||
          !resource.isPathway ||
          !!resource.model.marketplaceInputId ||
          !this.hasInsightsAccess(
            authUser,
            resource.model as PathwaySummaryModel
          ),
        defaultAction: (_) => this.goToInsights(resource),
      },
      {
        title: this.i18n.Core_AddToPathway,
        preventRefocus: true,
        isHidden: () =>
          !this.mayAddToPathway(resource) ||
          !!resource.model.marketplaceInputId ||
          isModal,
        defaultAction: (event, popoverTrigger) =>
          this.addToPathway(event, resource, popoverTrigger),
      },
      {
        title: this.i18n.Core_AddToJobRole,
        preventRefocus: true,
        isHidden: () =>
          !this.mayAddToTarget(resource) ||
          !!resource.model.marketplaceInputId ||
          isModal,
        defaultAction: (_, { nativeElement }) =>
          this.addToTarget('JobRole', resource, nativeElement),
      },
      {
        title: this.i18n.Core_FeatureResource,
        isHidden: () =>
          !authUser ||
          !resource.isPathway ||
          !authUser?.canFeaturePathways ||
          !resource.organizationId ||
          resource.isFeatured ||
          (resource.privacyLevel !== 1 &&
            (resource.privacyLevel as any) !== 'Public' &&
            resource.privacyLevel !== 2 &&
            (resource.privacyLevel as any) !== 'Group') ||
          this.hasFeaturedPlan ||
          !!resource.model.marketplaceInputId,
        defaultAction: (_) =>
          this.orgPathwaysService
            .featurePathway(resource.resourceId)
            .subscribe(() => {
              resource.isFeatured = true;
            }),
      },
      {
        title: this.i18n.Core_UnfeatureResource,
        isHidden: () =>
          !authUser ||
          !resource.isPathway ||
          !authUser?.canFeaturePathways ||
          !resource.organizationId ||
          !resource.isFeatured ||
          (resource.privacyLevel !== 1 &&
            (resource.privacyLevel as any) !== 'Public' &&
            resource.privacyLevel !== 2 &&
            (resource.privacyLevel as any) !== 'Group') ||
          this.hasFeaturedPlan ||
          !!resource.model.marketplaceInputId,
        defaultAction: (_) =>
          this.orgPathwaysService
            .unfeaturePathway(resource.resourceId)
            .subscribe(() => {
              resource.isFeatured = false;
            }),
      },
      this.endorseContentService.endorseContentMenuOption(resource),
      this.endorseContentService.removeEndorsementContentMenuOption(resource),
      {
        title: this.i18n.Core_ReportAProblem,
        preventRefocus: true,
        isHidden: () => !authUser,
        defaultAction: (_, popoverTrigger) =>
          this.reportAProblem(resource, popoverTrigger, location),
      },
    ];
    return menuConfig;
  }

  public queue(resource: {
    resourceType: string;
    resourceId: number;
    title: string;
    isEndorsed: boolean;
  }) {
    return this.queueService.post(
      resource.resourceType,
      resource.resourceId,
      null,
      resource
    );
  }

  public saveForLater(resourceDetails: {
    resourceType: string;
    resourceId: number;
    title: string;
    isEndorsed: boolean;
  }) {
    return this.queue(resourceDetails).pipe(
      tap((result) => {
        this.notifier.showSuccess(
          this.translate.instant('Pathways_ItemSavedFormat', {
            title: resourceDetails.title,
          })
        );
        return result;
      })
    );
  }

  public unsave(resourceDetails: {
    queueItemId: number;
    resourceType: string;
    resourceId: number;
  }) {
    return this.queueService.deleteItem(
      resourceDetails.queueItemId,
      resourceDetails.resourceType,
      resourceDetails.resourceId
    );
  }

  public mayAddToTarget(resource: LearningResourceViewModel) {
    return (
      this.authService.authUser &&
      this.inputPermissionsService.canAddToTarget(resource.resourceType)
    );
  }

  public addToTarget(
    targetType: TargetSuggestionType,
    resource: LearningResourceViewModel,
    element: HTMLElement
  ) {
    return this.targetService
      .showAddToTargetModal(targetType, resource.model, element)
      .subscribe();
  }

  public mayAddToPathway(resource: LearningResourceViewModel) {
    return (
      this.authService.authUser &&
      this.inputPermissionsService.canAddToPathway(resource.resourceType)
    );
  }

  public addToPathway(
    event: Event,
    resource: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ) {
    const returnFocusEl = popoverTrigger.nativeElement;
    this.addToPathwayModalService
      .showAddToPathwayForm(
        resource.resourceId,
        resource.resourceType,
        resource.organizationId,
        resource.isEndorsed,
        returnFocusEl
      )
      .subscribe();
  }

  private showShareModal(
    returnFocusEl: HTMLElement,
    resource: AnyLearningResource
  ) {
    return this.recommendationsModalService
      .showShareModal(resource, returnFocusEl)
      .subscribe();
  }

  private reportAProblem(
    resource: LearningResourceViewModel,
    poppoverTrigger: ElementRef,
    location: string
  ) {
    const returnFocusEl = poppoverTrigger.nativeElement;
    let feedbackOptions: FeedbackOptions = {
      itemType: this.feedbackService.getFeedbackItemTypeFromResource(
        resource.resourceType
      ),
      itemId: resource.resourceId,
      sectionType: location,
    };
    if (!!resource.pathwayStepDetails) {
      feedbackOptions = {
        ...feedbackOptions,
        contextType: this.feedbackService.feedbackContextType.Pathway,
        contextId: resource.pathwayStepDetails.pathwayId,
      };
    }
    return this.feedbackService.showReportProblemModal(
      feedbackOptions,
      returnFocusEl
    );
  }

  private cloneTarget(
    resource: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ) {
    this.targetService
      .showTargetCloneModal({
        target: resource.model as unknown as Target, // this is a target at this point
        context: 'default',
      })
      .subscribe({
        next: (clonedTarget: Target) => {
          this.windowRef.location.href = `/explore/${clonedTarget.referenceId}`;
        },
        error: (error) => {
          // TODO: Fix 'undefined' error thrown when cancelling the modal
        },
      });
  }

  private clonePathway(
    resource: LearningResourceViewModel,
    popoverTrigger: ElementRef
  ) {
    const returnFocusEl = popoverTrigger.nativeElement;
    const modalOptions = {
      pathId: resource.resourceId,
      forCloning: true,
      itemToAdd: null,
      preventRedirect: undefined,
      isChannel: false,
      disableEndorsedOption: false,
      sourceTarget: returnFocusEl,
    };
    this.pathwayAddEditFormModalService.openModal(modalOptions).subscribe();
  }

  private hasInsightsAccess(authUser: AuthUser, pathway: PathwaySummaryModel) {
    let hasAccess = false;
    let canManageThisPathway: boolean = false;
    let canAuthorPathways: boolean = false;

    const isAuthor =
        pathway.authorProfileKeys &&
        pathway.authorProfileKeys.indexOf(
          authUser.viewerProfile.userProfileKey
        ) !== -1,
      pathwayIsPrivateOrProfileVisible =
        pathway.privacyLevel === 0 || pathway.privacyLevel === 3;

    if (authUser.defaultOrgInfo) {
      (canManageThisPathway =
        authUser.defaultOrgInfo.permissions.managePathways &&
        authUser.defaultOrgInfo.organizationId === pathway.organizationId),
        (canAuthorPathways =
          authUser.defaultOrgInfo.permissions.authorPathways);
    }

    if (pathway.organizationId) {
      if (
        (isAuthor &&
          (canAuthorPathways ||
            pathway.shareAuthorPermission ||
            pathwayIsPrivateOrProfileVisible)) ||
        canManageThisPathway
      ) {
        hasAccess = true;
      }
    } else if (
      authUser.defaultOrgInfo &&
      authUser.defaultOrgInfo.organizationId &&
      isAuthor
    ) {
      hasAccess = true;
    }

    return hasAccess;
  }

  private goToInsights(resource: LearningResourceViewModel) {
    const hasChannelContext = this.contextService.urlHasContext(
      window.location.href,
      'channel'
    );
    const context = hasChannelContext ? 'channel' : null;
    let insightsUrl = '';
    const contextPath = !!context ? `/${context}` : '';
    insightsUrl = `${contextPath}${resource?.publicUrl}/insights`;

    this.windowRef.open(insightsUrl, '_self');
    this.tracker.trackEventData({
      action: 'Pathway View Insights',
      category: 'Pathways',
      properties: {
        Id: resource.resourceId,
        Source: 'Card',
      },
    });
  }
}
