import { Directive, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable } from 'rxjs';

import { AutoSaveService } from '@app/shared/services/auto-save/auto-save.service';
import { EventBus } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { PathwayDetailsModel, PathwaySection } from '../pathway-api.model';
import { PathwaySubsection } from '../rsm/pathway-api.model';
import { PathwayAuthoring } from '../rsm/pathway.authoring';
import { PathwayFacade } from '../rsm/pathway.facade';
import { PathwayViewModel } from '../rsm/pathway.model';
import { hasAtLeastOneAuthoringSection, hasAuthoringStep } from '../rsm/utils';
import { PathwayContainerBaseDirective } from './pathway-container-base.directive';

/**
 * Base directive used by PathwaySection/SubsectionAuthor components.
 * *Not* used by PathwayAuthorNav, which uses PathwayNavBase instead.
 */
@Directive()
export abstract class PathwayAuthorBaseDirective
  extends PathwayContainerBaseDirective
  implements OnInit
{
  public abstract readonly i18n: Record<string, string>;
  public readonly baseI18n = [
    'Core_TitleRequired',
    'Pathways_SectionDescLimit',
  ];
  public softCharacterLimit = 180;
  public descriptionMaxLength = 2000;
  public descriptionRowsLength = { min: 2, max: 5 };
  public titleMaxLength = 255;
  public vm$: Observable<PathwayViewModel>;

  constructor(
    private autoSaveService: AutoSaveService,
    protected authoring: PathwayAuthoring,
    eventBus: EventBus,
    protected facade: PathwayFacade,
    protected translate: TranslateService
  ) {
    super(eventBus);
  }

  public ngOnInit(): void {
    this.vm$ = this.facade.vm$;
  }

  // ***************************
  // PUBLIC -------------------
  // Util Wrappers
  // ***************************

  public hasAtLeastOneSection = hasAtLeastOneAuthoringSection;
  public hasStep = hasAuthoringStep;

  // ***************************
  // Methods
  // ***************************

  /**
   * Check for invalidness from our description field to change the helper
   * text to red.
   *
   * @param field
   */
  public isDescriptionFieldInvalid(field: NgModel) {
    return field.control.value?.length >= this.descriptionMaxLength - 200;
  }

  /**
   * Whether the given field is invalid.
   *
   * @param field
   */
  public isTitleFieldInvalid(field: NgModel): boolean {
    if (!(field.dirty && field.touched)) {
      return false;
    }

    if (field.invalid) {
      return true;
    }

    // Make sure the value isn't *sneakily* invalid.
    if (this.getValue(field.control.value) === '') {
      return true;
    }

    return false;
  }

  /**
   * Update pathway section title or description field.
   *
   * @param event - Focus event.
   * @param field - Current field.
   * @param section - Section to update.
   */
  public async onBlur(
    event: FocusEvent,
    field: string,
    section: PathwaySection | PathwaySubsection
  ) {
    const value = this.getValueFromEvent(event).trim();
    // if this is the required title, cancel save
    if (field === 'title' && !value) return;

    this.autoSaveService.saving();
    const success = await this.authoring.updateField(
      field,
      value,
      section,
      false
    );

    if (success) {
      this.autoSaveService.saved();
    } else {
      this.autoSaveService.close();
    }
  }

  /**
   * Update pathway section node title on key up.
   * NOTE: This is a purely visual update, no API calls are made.
   *
   * @param event - Keyboard event.
   * @param field - Current field.
   * @param section - Section to update.
   */
  public async onFieldChange(
    event: KeyboardEvent,
    field: string,
    section: PathwaySection | PathwaySubsection
  ): Promise<boolean> {
    const value = this.getValueFromEvent(event);
    // if this is the required title, cancel save
    if (field === 'title' && !value) return;

    return await this.authoring.updateField(field, value, section, true);
  }

  // ***************************
  // PRIVATE -------------------
  // Getters
  // ***************************

  /**
   * Gets the value from an event with proper typing to make TypeScript calm down.
   *
   * @param event Focus or keyboard event.
   */
  private getValueFromEvent(event: FocusEvent | KeyboardEvent): string {
    return this.getValue((<HTMLInputElement>event.target).value);
  }

  /**
   * Returns an empty string properly for values that are *just* whitespace. Otherwise,
   * returns value unchanged.
   *
   * @param event Focus or keyboard event.
   */
  private getValue(value = ''): string {
    if (!value || value.replace(/\s/g, '') === '') {
      return '';
    }
    return value;
  }
}
