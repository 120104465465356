import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { TagRatingModalComponent } from '@app/tags/components/tag-rating-modal/tag-rating-modal.component';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-modal-multi-mode',
  templateUrl: './tag-rating-modal-multi-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingModalMultiModeComponent implements OnInit {
  @Input() public trackingLocation: string;

  public ratingLevel: number;
  public currentTagIndex: number = 0;
  public loadingRating: boolean;
  public i18n: { [key: string]: string } = {};
  private _tags: TagsApi.Tag[];

  constructor(
    private activeModal: NgbActiveModal,
    private tagsService: TagsService,
    private authService: AuthService,
    private translate: TranslateService,
    private modalService: ModalService,
    private tagRatingService: TagRatingService
  ) {}
  @Input() public set tags(tags: TagsApi.Tag[]) {
    this._tags = tags.map((tag) => camelCaseKeys(tag));
  }
  public get tags(): TagsApi.Tag[] {
    return this._tags;
  }

  public get currentTag(): TagsApi.Tag {
    return this.tags[this.currentTagIndex];
  }

  public get isLastTag(): boolean {
    return this.currentTagIndex === this.tags.length - 1;
  }

  public get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public ngOnInit(): void {
    this.getCurrentTagRating();
  }

  public onRating(rating: number): void {
    this.ratingLevel = rating;
  }

  public getCurrentTagRating() {
    this.loadingRating = true;
    this.tagsService
      .getRequestedTagRating(
        this.authUser.viewerProfile.userProfileKey,
        this.currentTag.tagId,
        InternalTagRatingTypes.self
      )
      .subscribe((currentRating: TagsApi.UserTagRating) => {
        this.loadingRating = false;
        const title = this.translate.instant('dgTagRating_RatingTitleFormat', {
          tagName: this.currentTag.name,
        });
        this.modalService
          .show(TagRatingModalComponent, {
            inputs: {
              ratingType: InternalTagRatingTypes.self,
              title,
              currentRating: currentRating?.level,
              targetRating: this.tagRatingService.getSkillTarget(
                this.currentTag
              ),
              showComments: false,
              multiMode: true,
            },
          })
          .subscribe((response: { rating; done }) => {
            if (!response) {
              this.close();
            } else {
              const { rating, done } = response;
              if (!done) {
                this.submitNext(this.currentTag.tagId, rating.level);
              } else {
                this.doneRating(this.currentTag.tagId, rating.level);
              }
            }
          });
      });
  }

  public submitNext(tagId: number, newRatingLevel: number) {
    this.loadingRating = true;
    if (newRatingLevel) {
      this.saveRating(tagId, newRatingLevel).subscribe(() => {
        this.currentTagIndex++;
        this.getCurrentTagRating();
      });
    } else {
      this.currentTagIndex++;
      this.getCurrentTagRating();
    }
  }

  public doneRating(tagId: number, newRatingLevel: number) {
    if (newRatingLevel) {
      this.saveRating(tagId, newRatingLevel).subscribe(() => {
        this.close('done');
      });
    } else {
      this.close('done');
    }
  }

  public saveRating(tagId: number, userRating: number) {
    return this.tagsService.rateTag(
      userRating,
      InternalTagRatingTypes.self,
      this.currentTag as TagsApi.TagDetails,
      this.trackingLocation
    );
  }

  public close(reason?: string) {
    this.activeModal.close(reason);
  }
}
