<div class="org-selector l_flexbar">
  <div class="org-selector__container">
    <ul class="org-selector__button-list" role="radiogroup">
      <li
        #org
        *ngFor="let org of activeOrgs; let i = index"
        class="org-selector__button"
        [class.org-selector__button--active]="selectedIndex === i"
        role="radio"
        [tabindex]="i === 0 ? 0 : -1"
        [attr.aria-checked]="selectedIndex === i"
        (keyup)="handleKeyUp($event)"
        (click)="handleClick(org.organizationId)"
        data-dgat="org-selector-2b2"
        [class.disabled]="isLoading"
        [attr.aria-disabled]="isLoading"
      >
        {{ org.name }}
      </li>
    </ul>

    <div
      class="org-selector__slider"
      [@slideAndStretchHoriz]="animationData"
    ></div>
  </div>

  <dgx-menu
    *ngIf="menuConfig.length > 0"
    [menuConfig]="menuConfig"
    placement="bottom-right"
    [appendToBody]="true"
  >
    <button
      type="button"
      [attr.aria-label]="'Core_MoreOptions' | translate"
      data-dgat="provider-bubble-599"
      class="guts-p-h-1"
    >
      <df-icon icon="chevron-down" class="org-selector__chevron"></df-icon>
    </button>
  </dgx-menu>
</div>
