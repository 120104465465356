<form (ngSubmit)="onSubmit()" name="coachForm" [formGroup]="coachForm">
  <dgx-modal
    [canCancel]="true"
    [useDefaultForm]="false"
    [useDefaultSubmitButton]="false"
    [canCancel]="true"
    (dismiss)="confirmationModal()"
    bodyClasses="tw-px-8 tw-relative"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ i18n.Coach_AddModalTitle }}
    </ng-container>

    <!-- Modal Body -->
    <ng-container class="modal-body">
      <div class="tw-relative tw-flex tw-w-full tw-flex-wrap">
        <div class="tw-flex tw-w-full tw-flex-col tw-gap-1 tw-pb-8 tw-pt-4">
          <label class="tw-font-semibold" for="coachName">{{
            i18n.Coach_AddInputLabelText
          }}</label>

          <input
            type="text"
            class="df-text-input df-text-input-border"
            [placeholder]="i18n.Coach_AddInputPlaceholder"
            autocomplete="off"
            data-dgat="coach-add-form-input"
            id="coachName"
            name="coachName"
            formControlName="coachName"
            [resultTemplate]="coachItem"
            [resultFormatter]="formatCoachResult"
            [inputFormatter]="formatCoachResult"
            [ngbTypeahead]="search"
            (selectItem)="onSelectCoach($event.item)"
          />
          <dgx-validate-field
            *ngIf="showTitleErrors"
            [message]="titleErrorMsg"
          ></dgx-validate-field>
        </div>
      </div>
      @if (selectedCoach) {
        <div class="tw-flex tw-items-center" data-dgat="coach-selected">
          <div>
            <img
              alt="{{
                'Coach_AddCoverAltFormat'
                  | translate: { title: selectedCoach.coachName }
              }}"
              [src]="selectedCoach.avatar"
              class="tw-h-20 tw-w-20 tw-object-cover"
            />
          </div>
          <div class="tw-items-start">
            <div class="tw-pl-4">
              <p class="tw-pb-0 tw-text-sm tw-font-semibold">
                {{ selectedCoach.coachName }}
              </p>
              <p *ngIf="selectedCoach.shortDescription" class="tw-text-xs">
                {{ selectedCoach.shortDescription }}
              </p>
            </div>
          </div>
        </div>
      }
    </ng-container>
    <!-- Modal Footer -->
    <ng-container class="modal-footer">
      <button
        class="tw-btn-primary tw-btn-small"
        type="submit"
        [disabled]="isSubmitDisabled || isSubmitPending"
        [class.is_disabled]="isSubmitDisabled || isSubmitPending"
        [attr.aria-disabled]="isSubmitDisabled || isSubmitPending"
        [attr.aria-label]="isSubmitPending ? i18n.Core_Loading : i18n.Core_Save"
        data-dgat="coach-add-form-submit"
      >
        {{ i18n.Core_Save }}
      </button>
    </ng-container>
  </dgx-modal>
</form>

<ng-template #coachItem let-item="result">
  <a class="tw-flex tw-items-center tw-px-2 tw-py-1" data-dgat="coachItem">
    <img
      alt="{{
        'Coach_AddCoverAltFormat' | translate: { title: item.coachName }
      }}"
      [src]="item.avatar"
      class="tw-h-20 tw-w-20 tw-object-cover"
    />
    <div class="tw-items-start tw-text-left">
      <div class="tw-pl-4">
        <p class="tw-pb-0 tw-text-sm tw-font-semibold">
          {{ item.coachName }}
        </p>
        <p *ngIf="item.shortDescription" class="tw-text-xs">
          {{ item.shortDescription }}
        </p>
      </div>
    </div>
  </a>
</ng-template>
