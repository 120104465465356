import { mergeMap } from 'rxjs/operators';
import { InputType } from '@app/shared/models/core-api.model';
import { InputIdentifier } from '../../../inputs/inputs-api.model';
import { Injectable, Inject } from '@angular/core';
import {
  InputsFacadeBase,
  InputSubmissionResult,
} from '@app/user-content/services/inputs-facade-base';
import { AssessmentFormModel } from './assessment-form.model';
import { AssessmentApiEntity } from './repository/assessment.entity.model';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { AuthService } from '@app/shared/services/auth.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { RendererContext, FormRenderAction } from '../form-renderer.model';
import { FormControl, FormGroup } from '@angular/forms';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputContext, RenderMode } from '../user-input.model';
import { UserProfileAssessmentRendererService } from './renderers/user-profile-assessment-renderer.service';
import { ContentCatalogAssessmentRendererService } from './renderers/content-catalog-assessment-renderer.service';
import { CredSparkService } from '@app/cred-spark/services/cred-spark.service';
import { PathwayAssessmentRendererService } from './renderers/pathway-assessment-renderer.service';
import { ContextService } from '@app/shared/services/context.service';
import { isUrlValid } from '@app/shared/utils/common-utils';
import { ContentCatalogInitialAssessmentRendererService } from './renderers/content-catalog-initial-assessment-renderer.service';
import { BehaviorSubject, of, Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserProfileEditAssessmentRendererService } from './renderers/user-profile-edit-assessment-renderer';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { TipService } from '@app/onboarding/services/tip.service';

@Injectable({ providedIn: 'any' })
export class AssessmentFacade extends InputsFacadeBase<
  AssessmentFormModel,
  AssessmentApiEntity
> {
  private i18n = this.translate.instant([
    'AssessmentFormCtrl_SaveAssessment',
    'AssessmentFormCtrl_NameRequired',
    'AssessmentFormCtrl_UrlRequired',
    'AssessmentFormCtrl_NumberOfQuestions',
    'AssessmentFormCtrl_NumberOfQuestionsError',
    'AssessmentFormCtrl_CorrectQuestions',
    'AssessmentFormCtrl_Category',
    'AssessmentFormCtrl_AssessmentName',
    'AssessmentFormCtrl_AssessmentProvider',
    'AssessmentFormCtrl_AssessmentUrl',
    'AssessmentFormCtrl_Description',
    'AssessmentFormCtrl_TotalQuestions',
    'AssessmentFormCtrl_Percentile',
    'AssessmentFormCtrl_DateCompleted',
    'AssessmentFormCtrl_WhatDidYouLearnLong',
    'AssessmentFormCtrl_WhatDidYouLearnShort',
    'Core_CommentPrivacyWarning',
    'Core_OpenDatePicker',
    'Core_Skills',
    'TagsCtrl_SkillsPlaceholder',
    'dgOrgInternalContentForm_Questions',
    'dgOrgInternalContentForm_CreateNewCredSparkAssessment',
    'dgOrgInternalContentForm_AddFromUrl',
    'Core_SelectDate',
  ]);

  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: AssessmentApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    builder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    commentsApiService: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    tipService: TipService,
    private userProfileAddRenderer: UserProfileAssessmentRendererService,
    private userProfileEditRenderer: UserProfileEditAssessmentRendererService,
    private contentCatalogRenderer: ContentCatalogAssessmentRendererService,
    private contentCatalogInitialRenderer: ContentCatalogInitialAssessmentRendererService,
    private pathwayAssessmentRenderer: PathwayAssessmentRendererService,
    private credSparkService: CredSparkService,
    private contextService: ContextService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      builder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      commentsApiService,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }

  /** Override */
  public onSubmit(): Observable<InputSubmissionResult> {
    // editing we need to check for duplicates before submitting
    if (this.inputContext.renderMode === RenderMode.ContentCatalog) {
      return this.checkAndUpdateUrlDuplicates().pipe(
        mergeMap(() => {
          return super.onSubmit();
        })
      );
    }

    return super.onSubmit(); // Close stream and don't actually submit if parsing (i.e. "Next" button pressed)
  }

  protected get extendedDefaultViewModel(): Partial<AssessmentFormModel> {
    const formTitle = this.inputContext.isEditing
      ? 'AssessmentFormCtrl_EditAssessment'
      : 'AssessmentFormCtrl_AddAssessment';

    return {
      authUser: this.authService.authUser,
      formTitle: this.translate.instant(formTitle),
      submitButtonText: this.translate.instant(
        'AssessmentFormCtrl_SaveAssessment'
      ),
      inputType: 'Assessment',
      isChannelContext: this.contextService.urlHasContext(
        window.location.href,
        'channel'
      ),
      useAnimatedSubmit:
        this.inputContext.renderMode === RenderMode.UserProfile &&
        !this.isEditing,
      userProviderName: undefined,
      title: undefined,
      providerName: undefined,
      url: undefined,
      description: undefined,
      questions: undefined,
      questionsCorrect: undefined,
      percentile: undefined,
      dateCompleted: undefined,
      comment: undefined,
      tags: [],
      organizationId: this.orgId,
      addToCatalog: false,
      isAuthoring: this.isAuthoring,
      onAddToCatalogChange: this.onAddToCatalogChange.bind(this),
      onQuestionsCorrectFieldChange: (form, formControl, value) =>
        this.onQuestionsCorrectFieldChange(form, formControl, value),
      getIsCredSpark: (input) =>
        this.authService.authUser.orgInfo?.find(
          (o) => o.organizationId === this.defaultOrgId
        ).hasCredSpark && this.credSparkService.getIsCredSpark(input),
      forwardToCredSpark: ($event) => this.forwardToCredSpark($event),
      createInCredSpark: () => this.createInCredSpark(),
      addContentCatalogAssessmentByUrl: () =>
        this.addContentCatalogAssessmentByUrl(),
      validateAssessmentUrl: (url, isCredSpark) =>
        this.validateAssessmentUrl(url, isCredSpark),
      validateTotalQuestionsValue: (correctQuestions, totalQuestions) =>
        this.validateTotalQuestionsValue(correctQuestions, totalQuestions),
      validateCorrectQuestionsValueExists: (correctQuestions, totalQuestions) =>
        this.validateCorrectQuestionsValueExists(
          correctQuestions,
          totalQuestions
        ),
      validateCorrectQuestionsLessThanTotal: (
        correctQuestions,
        totalQuestions
      ) =>
        this.validateCorrectQuestionsLessThanTotal(
          correctQuestions,
          totalQuestions
        ),
      validateCorrectQuestionsValidNumber: (
        correctQuestions,
        totalQuestions,
        isCompleting
      ) =>
        this.validateCorrectQuestionsValidNumber(
          correctQuestions,
          totalQuestions,
          isCompleting
        ),
    };
  }

  protected buildUIConfiguration(
    action?: FormRenderAction
  ): DfFormFieldConfig[] {
    const context: RendererContext = {
      inputContext: {
        ...this.inputContext,
        inputType: 'Assessment',
        isCompleting: this.isCompleting,
      },
      state: () => this.viewModel,
      templates: this.templates,
      action: action ? action : { type: 'DEFAULT' },
      translationKeys: this.i18n,
    };

    switch (this.inputContext.renderMode) {
      case RenderMode.UserProfile:
        return this.inputContext.isEditing
          ? this.userProfileEditRenderer.render(context)
          : this.userProfileAddRenderer.render(context);
      case RenderMode.ContentCatalog:
        if (this.inputContext.isEditing || this.viewModel.isContentCatalogUrl) {
          this.viewModel.shouldShowSubmitButton$ = new BehaviorSubject(true);
          return this.contentCatalogRenderer.render(context);
        } else {
          this.viewModel.shouldShowSubmitButton$ = new BehaviorSubject(false);
          return this.contentCatalogInitialRenderer.render(context);
        }
      case RenderMode.Pathways:
        return this.pathwayAssessmentRenderer.render(context);
      default:
        throw `Assessment Facade - No render mode defined for ${this.inputContext.renderMode}`;
    }
  }

  /**
   * Questions correct label is dynamic based on whether or not the questions field has been filled out.
   */
  public get assessmentQuestionsCorrectLabel() {
    const label = this.viewModel.questions
      ? this.translate.instant('AssessmentFormCtrl_CorrectQuestionsFormat', {
          startTag: ``,
          number: this.viewModel.questions,
          endTag: ``,
        })
      : this.translate.instant('AssessmentFormCtrl_CorrectQuestionsFormat', {
          startTag: `<span class="hidden">`,
          endTag: `</span>`,
        });
    return label;
  }

  /**
   * Forces validation of questions field when questions correct field has changed because the questions field
   * has a validator that determines if the number of questions correct makes sense (greater than) vs the number of
   * total questions.
   */
  private onQuestionsCorrectFieldChange(
    form: FormGroup,
    formControl: FormControl,
    value: any
  ) {
    this.setCustomFieldValue(formControl, value, true);
  }

  /**
   * Helper method to forward to credspark when editing a credspark assessment
   */
  private forwardToCredSpark($event) {
    $event.stopPropagation();
    const csQuizId = this.viewModel.externalId.substring(
      this.viewModel.externalId.lastIndexOf('-') + 1
    );

    this.credSparkService.launchAssessmentEdit(parseInt(csQuizId, 10), false);
  }

  private addContentCatalogAssessmentByUrl() {
    this.viewModel.isContentCatalogUrl = true;
    this.updateUIConfiguration();
  }

  /**
   * Helper method to forward to credspark when creating a new assessment
   */
  private createInCredSpark() {
    this.credSparkService.launchCredSpark(
      false,
      this.viewModel.isChannelContext
    );
  }

  private validateAssessmentUrl(url: string, isCredSpark: boolean): boolean {
    const isValid = url && (isCredSpark || isUrlValid(url));
    return !!isValid;
  }

  private validateTotalQuestionsValue(
    correctQuestions: string,
    totalQuestions: string
  ): boolean {
    if (totalQuestions) {
      return parseInt(totalQuestions) > 0;
    }
    // if there are questions correct listed, total questions needs to be defined
    return !correctQuestions;
  }

  private validateCorrectQuestionsValueExists(
    correctQuestions: string,
    totalQuestions: string
  ): boolean {
    // if there are total questions there should be correct questions as well
    if (!!totalQuestions) {
      return !!correctQuestions;
    } else {
      // if no total questions, this field is not required
      return true;
    }
  }

  private validateCorrectQuestionsLessThanTotal(
    correctQuestions: number,
    totalQuestions: number
  ): boolean {
    // this check only matters if total has been filled out so bail otherwise
    if (!totalQuestions) {
      return true;
    }
    // if there are more questions marked correct than total questions
    // that is an invalid state, you can only have as many questions correct
    // as matches the total
    return correctQuestions <= totalQuestions;
  }

  private validateCorrectQuestionsValidNumber(
    correctQuestions: number,
    totalQuestions: number,
    isCompleting: boolean
  ): boolean {
    // this check only matters for completions and other checks will catch if total is empty so bail
    if (!isCompleting || !totalQuestions) {
      return true;
    }

    // if this is a completion and total questions marked
    // the questions correct must not be empty, it should be marked 0 if
    // there were no questions correct
    return !!correctQuestions && correctQuestions > 0;
  }

  private onAddToCatalogChange(shouldAdd: boolean) {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.fetchDuplicates(
      shouldAdd && this.viewModel.url !== null,
      this.viewModel.organizationId,
      this.viewModel.url,
      inputIdentifier
    ).subscribe();
  }

  private checkAndUpdateUrlDuplicates() {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.viewModel.url = this.inputsService.cleanUrl(this.viewModel.url);

    return this.fetchDuplicates(
      true,
      this.viewModel.organizationId,
      this.viewModel.url,
      inputIdentifier
    );
  }
}
