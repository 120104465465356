import { Injectable } from '@angular/core';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { catchAndSurfaceError } from '@app/shared/utils';
import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
} from 'rxjs';

/**
 * A simplified user-or-group result, representing only the fields needed for
 * displaying a minimal profile pic (or group fallback) for a user or group.
 * Does NOT include engagement, mentoring info, etc.
 */
export interface UserOrGroupSearchItem {
  /** UserProfileKey or GroupId, depending. */
  resourceId: number;
  resourceType: string;
  organizationId: number;
  name: string;
  /** User-only. Redundant to resourceId, but kept for safety. */
  userProfileKey?: number;
  /** User-only. */
  picture?: string;
  /** User-only. */
  vanityUrl?: string;
  /** User-only. */
  email?: string;
  /** User-only. */
  organizationEmail?: string;
}

@Injectable()
export class UserGroupListService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Load recommendations of groups and users for pathway primary contact or catalog content owner
   *
   * @param searchTerm
   * @returns
   */
  public loadGroupsUsersRecommendees: TypeaheadSearchFunction<
    string,
    AnyRecommendee
  > = (searchTerm: Observable<string>): Observable<readonly AnyRecommendee[]> =>
    searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((searchTerm) => searchTerm.length >= 2),
      switchMap((searchTerm: string) =>
        this.getUserAndGroupSuggestions(searchTerm)
      ),
      catchError(() => of([] as AnyRecommendee[]))
    );

  /**
   * Get a user or group based on the resource ID, type, and orgId.
   */
  public getUserOrGroupDetailsByKey(
    resourceKey: number,
    resourceType: string,
    orgId: number
  ): Observable<UserOrGroupSearchItem> {
    return this.http
      .get<UserOrGroupSearchItem>('/resources/GetUserOrGroupIgnorePrivacy', {
        params: {
          resourceKey,
          resourceType,
          orgId,
        },
      })
      .pipe(
        map((item: UserOrGroupSearchItem) => {
          if (item.resourceType === 'User') {
            return {
              ...item,
              userProfileKey: item.resourceId,
            };
          }
          return item;
        }),
        catchError((error) => {
          // WHEN error code is 404, there was no content owner match found. Send back undefined.
          if (error.status === 404) {
            return of(undefined);
          }
          // Otherwise, pass error on
          throw error;
        }),
        catchAndSurfaceError(this.translate.instant('TargetsSvc_RetrieveError'))
      );
  }

  /**
   * Get a list of possible pathway primary contacts OR possible content owners from a search term
   * @param searchTerm
   * @returns
   */
  private getUserAndGroupSuggestions(
    searchTerm: string
  ): Observable<AnyRecommendee[]> {
    return this.http
      .get<AnyRecommendee[]>('/search/findUserGroupResources', {
        params: {
          term: searchTerm,
        },
      })
      .pipe(
        map((item: any) => {
          if (item.resourceType === 'User') {
            return {
              ...item,
              userProfileKey: item.resourceId,
            };
          }
          return item;
        })
      );
  }
}
