<div
  class="
    org-manage-notification
    org-manage-notification--info
    org-manage-notification--small
  "
>
  <df-icon size="small" icon="info"></df-icon>
  <div class="org-manage-notification--small__text">
    <p class="guts-p-full-0">
      {{ message }}
    </p>
    <p>
      <a
        class="link"
        [href]="orgManagedLink"
        target="_blank"
        aria-describedby="a11yNewWindowDescription"
        data-dgat="org-manage-notification-054"
      >
        {{ linkTextKey
        }}<df-icon
          size="extra-small"
          icon="arrow-up-right-out"
          class="color-blue guts-m-l-quart"
        ></df-icon>
      </a>
    </p>
  </div>
</div>
