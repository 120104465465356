<div class="rel external-logo--{{ size }}">
  <img
    *ngIf="!fallbackChar"
    [alt]="providerName"
    aria-hidden="true"
    [src]="blobSrc"
    (error)="fallbackChar ? undefined : useFallback()"
    [ngClass]="imageClass"
  />
  <span *ngIf="!!fallbackChar">
    <div class="bg-color-ebony-a61 round external-logo__fallback"></div>
    <span
      class="ib absolute abs-center center-text color-white external-logo__text"
      [textContent]="fallbackChar"
    >
    </span>
  </span>
</div>
