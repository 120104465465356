import { InternalTagRatingTypes } from '@app/tags/tags';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { DfIconPlusCircle24, DfIconRegistry } from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { getAverageRatingLevel } from '@app/shared/utils/tag-helpers';

@Component({
  selector: 'dgx-tag-tile-rating-description',
  templateUrl: './tag-tile-rating-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagTileRatingDescriptionComponent {
  @Input() public tag: TagsApi.Tag;
  @Input() public targetAuthoring: boolean;
  @Input() public isOwner: boolean;
  @Input() public showUpdateLink: boolean;

  public i18n: { [key: string]: string } = this.translate.instant([
    'dgTagRating_InProgress',
    'dgTagRating_Evaluation',
    'dgTagRating_ManagerAssessment',
    'dgTagRating_SelfAssessment',
    'dgTagRating_AddRating',
    'dgTagRating_UpdateRating',
    'dgTagRating_EvaluationInProgress',
    'dgTagRating_DegreedCertified',
    'dgTagRating_PrivateToYou',
  ]);

  constructor(
    private iconRegistry: DfIconRegistry,
    private translate: TranslateService,
    public tagsService: TagsService
  ) {
    this.iconRegistry.registerIcons([DfIconPlusCircle24]);
  }

  public get level(): number {
    switch (this.tag?.rating?.type) {
      case InternalTagRatingTypes.peer:
        return +getAverageRatingLevel(
          this.tag?.ratings?.filter(
            (rating) =>
              rating.type === InternalTagRatingTypes.peer &&
              !!rating.dateCompleted
          )
        );
      default:
        return +this.tag.rating.level;
    }
  }

  public get levelName(): string {
    return this.tagsService.getNameForRatingLevel(this.level);
  }

  public get showCustomLabel(): boolean {
    return this.levelName && !this.targetAuthoring;
  }

  public get showAddRating(): boolean {
    return !this.level && !this.targetAuthoring && this.isOwner;
  }
}
