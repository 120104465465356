<dgx-modal
  (dismiss)="onClose()"
  [canCancel]="isDetailModal"
  *ngIf="statisticsLoaded"
  bodyClasses="dg-modal__body--no-padding"
  [isHeaderBorderless]="true"
  [isFooterless]="true"
  headerLabelId="input-details-header-label"
>
  <ng-container class="modal-header">
    <ng-container *ngIf="!isPreview">
      <!-- Recommendation Header -->
      <dgx-user-recommendation-header
        *ngIf="userRecHeaderItem"
        [item]="this.userRecHeaderItem"
        [maxLines]="2"
        [showComment]="true"
      >
      </dgx-user-recommendation-header>
      <dgx-input-statistics
        *ngIf="!userRecHeaderItem && resource.statistics?.topUser"
        [input]="resource"
      ></dgx-input-statistics>
    </ng-container>
    <h3 *ngIf="isPreview" class="h3">
      {{ previewHeader }}
    </h3>
  </ng-container>

  <ng-container class="modal-body">
    <!-- Learning Content -->
    <dgx-input-card
      [resource]="resource"
      [isDetailModal]="isDetailModal"
      [isPreview]="isPreview"
      [expandComments]="expandComments"
      [isMarketplaceProgram]="config?.isMarketplaceProgram"
      [searchResultTrackingConfig]="config?.searchResultTrackingConfig"
    >
    </dgx-input-card>
  </ng-container>
</dgx-modal>
