import { Inject, Injectable, Injector } from '@angular/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { DgError } from '../models/dg-error';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor(
    private injector: Injector,
  ) {}

  public get notifierService(): NotifierService {
    return this.injector.get(NotifierService);
  }

  public handle(error: DgError, notFoundMessage?: string) {
    const { status } = error.innerError as any;
    const options = {
      onActivateTick: true,
      closeButton: true,
      disableTimeOut: true,
    };
    if (status === 404 && notFoundMessage) {
      this.notifierService.showError(notFoundMessage, options);
    } else if (status === 401) {
      // Do nothing
    } else if (status > 0) {
      this.notifierService.showError(
        error.message || error.innerError?.message,
        options
      );
    }
  }
}
