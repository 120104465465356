import { Injectable } from '@angular/core';
import { ResourceType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Observable } from 'rxjs';
import { TrackingLink } from '../tracking-links-table/tracking-link.model';

@Injectable({
  providedIn: 'root',
})
export class TrackingLinkService {
  private readonly resourceCtrls = {
    Article: 'targets',
    Pathway: 'pathways',
    Target: 'targets',
    Role: 'targets',
  };

  constructor(private http: NgxHttpClient) {}

  public getTrackingLinks(resourceId: number, ctrl = 'pathways') {
    return this.http.get<TrackingLink[]>(`/${ctrl}/gettrackablelinks`, {
      params: { resourceId },
    });
  }

  public deleteTrackingLink(
    linkId: number,
    resourceId: number,
    resourceType: 'targets' | 'pathways' = 'pathways'
  ): Observable<void> {
    return this.http.delete<void>(
      `/${resourceType}/DeleteTrackableLink/${linkId}?resourceId=${resourceId}`
    );
  }

  public createTrackingLink(
    resourceId: number,
    name: string,
    guid: string,
    type = 'Pathway',
    ctrl = 'pathways'
  ): Observable<TrackingLink> {
    return this.http.post<TrackingLink>(`/${ctrl}/CreateTrackableLink`, {
      name,
      trackedResource: {
        resourceId,
        resourceType: type,
      },
      tag: guid,
    });
  }

  public updateTrackingLink(
    linkId: number,
    resourceId: number,
    name: string,
    type = 'Pathway',
    ctrl = 'pathways'
  ): Observable<TrackingLink> {
    return this.http.put<TrackingLink>(
      `/${ctrl}/UpdateTrackableLink/${linkId}`,
      {
        name,
        trackedResource: {
          resourceId,
          resourceType: type,
        },
      }
    );
  }

  public resourceCtrlFor(resourceType: ResourceType): 'pathways' | 'targets' {
    if (!resourceType) {
      return null;
    }

    return this.resourceCtrls[resourceType] as 'pathways' | 'targets';
  }
}
