import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import { CommentModel } from '@app/inputs/inputs-api.model';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TranslateService } from '@ngx-translate/core';
import {
  CommentFeedItemModel,
  CommentSubmission,
  CommentThreadContext,
  MentionItem,
  Resource,
} from '../../comments.model';

export interface CommentsResp {
  comment: string;
  mentions: MentionItem[];
}
@Component({
  selector: 'dgx-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnChanges {
  @Input() public comment: CommentModel;
  @Input() public mentions: MentionItem[] = [];
  @Input() public resource: Resource;
  @Input() public menuConfig: MenuViewModel[];
  @Input() public isPreview?: boolean;
  @Input() public referenceUserKey?: number;
  @Input() public parentReferenceId?: number;
  @Input() public groupId?: number;
  @Input() public groupPrivacy?: GroupPrivacyLevel;
  @Input() public context?: CommentThreadContext;
  @Output() public commentChange = new EventEmitter<CommentModel>();

  public i18n = this.translateService.instant([
    'Core_SaveChanges',
    'Core_Cancel',
    'CommentsCtrl_Unlike',
    'CommentsCtrl_Like',
    'Core_Edit',
    'Core_Reply',
    'Pathways_Edited',
  ]);
  public isViewerComment: boolean;
  public isEditing = false;
  public isReplying = false;
  public isSubmitting = false;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private commentApiService: CommentsApiService,
    private translateService: TranslateService,
    private windowLayoutService: WindowLayoutService
  ) {}

  public get isBrowserExtension() {
    return this.windowLayoutService.isIframe;
  }

  public ngOnChanges({ comment }: SimpleChanges) {
    if (comment?.currentValue) {
      this.isViewerComment =
        this.comment.userProfileKey ===
        this.authService.authUser?.viewerProfile.userProfileKey;
    }
  }

  public onCancelEdit() {
    this.isEditing = false;
    this.cdr.markForCheck();
  }

  public onEdit({ value, mentions }: CommentSubmission) {
    this.isEditing = false;
    this.isSubmitting = true;
    this.cdr.markForCheck();

    this.commentApiService
      .editComment(
        this.comment.id,
        value,
        this.resource.resourceType,
        this.resource.resourceId,
        this.parentReferenceId,
        mentions
      )
      .subscribe({
        next: () => {
          this.comment.edited = true;
          this.comment.dateAdded =
            this.translateService.instant('dgComment_JustNow');
          this.commentChange.emit(this.comment);
        },
        complete: () => {
          this.isSubmitting = false;
          this.cdr.markForCheck();
        },
      });
  }

  public onReply({ value, mentions }: CommentSubmission) {
    this.isSubmitting = true;
    this.isReplying = false;
    this.isEditing = false;
    this.cdr.markForCheck();

    this.commentApiService
      .addComment(
        this.resource,
        value,
        this.comment as CommentFeedItemModel,
        this.parentReferenceId,
        this.referenceUserKey,
        mentions
      )
      .subscribe({
        next: (reply) => {
          (this.comment as CommentFeedItemModel).replies.push({
            parentId: this.comment.id,
            ...reply,
          });
        },
        complete: () => {
          this.isSubmitting = false;
          this.cdr.markForCheck();
        },
      });
  }

  public toggleEdit() {
    this.isEditing = true;
    this.isReplying = false;
  }

  public toggleLike() {
    this.commentApiService
      .likeComment(this.resource, this.comment, this.parentReferenceId)
      .subscribe(() => {
        this.commentChange.emit(this.comment);
        this.cdr.markForCheck();
      });
  }

  public toggleReply() {
    this.isReplying = !this.isReplying;
    this.isEditing = false;
  }
}
