import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { SortByColumns, TrackingLink } from './tracking-link.model';
import {
  DataColumn,
  DataColumnDate,
  MenuSettings,
} from '@app/shared/components/data-table/data-table';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'dgx-tracking-links-table',
  templateUrl: './tracking-links-table.component.html',
})
export class TrackingLinksTableComponent {
  @Input() public disabled: boolean;
  @Input() public links: TrackingLink[];
  @Input() public canManage: boolean;
  @Input() public isOrgContent: boolean;
  @Input() public currentSortBy: string;
  @Input() public ascend: SortByColumns;
  @Input() public inviteUrl: string;

  @Output() public onDelete: EventEmitter<{
    item: TrackingLink;
    popoverTrigger: ElementRef;
  }> = new EventEmitter();
  @Output() public onEdit: EventEmitter<TrackingLink> = new EventEmitter();
  @Output() public onNew: EventEmitter<string> = new EventEmitter();
  @Output() public onSort: EventEmitter<string> = new EventEmitter();

  @ViewChild('linkCreator', { static: true })
  public linkCreatorTemplate: TemplateRef<any>;

  @ViewChild('copyLinkButton', { static: true })
  public copyLinkTemplate: TemplateRef<any>;

  @ViewChild('linkCreateDate', { static: true })
  public linkCreateDateTemplate: TemplateRef<any>;

  public copiedToolTips = {};
  public menuSettings: MenuSettings;
  public columns: DataColumn[] = [];
  public i18n = this.translate.instant([
    'TrackableLinkCtrl_NewLink',
    'TrackableLinkCtrl_CopyLink',
    'TrackableLinkCtrl_TableHeaderLink',
    'TrackableLinkCtrl_TableHeaderCreator',
    'TrackableLinkCtrl_TableHeaderDate',
    'TrackableLinkCtrl_TableHeaderClicks',
    'TrackableLinkCtrl_TableHeaderEnrolls',
    'PathwayInsights_CopyLink',
    'Core_Actions',
    'Core_Edit',
    'Core_Delete',
  ]);

  constructor(
    private translate: TranslateService,
    private clipboardService: ClipboardService
  ) {}

  public get canCopyToClipboard(): boolean {
    return this.clipboardService.canCopyToClipboard;
  }

  public ngOnInit(): void {
    this.setColumns();
    this.menuSettings = {
      getMenuConfig: this.getMenuConfig.bind(this),
    };
  }

  public getToolTip(linkTag): string {
    return this.copiedToolTips[linkTag]
      ? this.copiedToolTips[linkTag]
      : this.i18n.TrackableLinkCtrl_CopyLink;
  }

  public getMenuConfig(
    item: TrackingLink
  ): Observable<readonly MenuViewModel[]> {
    return of([
      {
        title: this.i18n.Core_Edit,
        defaultAction: () => {
          this.onEdit.emit(item);
        },
        isHidden: () => !(this.canManage && this.isOrgContent),
        preventRefocus: true,
      },
      {
        title: this.i18n.Core_Delete,
        defaultAction: (_, popoverTrigger) => {
          this.onDelete.emit({ item, popoverTrigger });
        },
        isHidden: () => !(this.canManage && this.isOrgContent),
        preventRefocus: true,
      },
    ]);
  }

  public newLink(): void {
    this.onNew.emit();
  }

  public sort(sortBy): void {
    this.onSort.emit(sortBy);
  }

  public copyLink(linkTag): void {
    const paramSeparator = this.inviteUrl.indexOf('?') > -1 ? '&' : '?';
    const trackableLink = `${this.inviteUrl}${paramSeparator}tltag=${linkTag}`;
    this.clipboardService.copyToClipboard(trackableLink).subscribe({
      next: (result) => {
        this.copiedToolTips[linkTag] = result;
      },
    });
  }

  private setColumns() {
    this.columns = [
      {
        label: 'TrackableLinkCtrl_TableHeaderLink',
        headClasses: 'l_w25',
        prop: 'name',
      } as DataColumn,
      {
        label: 'TrackableLinkCtrl_TableHeaderCreator',
        headClasses: 'l_w25',
        template: this.linkCreatorTemplate,
      } as DataColumn,
      {
        label: 'TrackableLinkCtrl_TableHeaderDate',
        headClasses: 'l_w10',
        template: this.linkCreateDateTemplate,
      } as DataColumnDate,
      {
        label: 'TrackableLinkCtrl_TableHeaderClicks',
        headClasses: 'l_w10',
        prop: 'clicks',
        canSort: true,
        sortName: 'clicks',
      } as DataColumn,
      {
        label: 'TrackableLinkCtrl_TableHeaderEnrolls',
        headClasses: 'l_w10',
        prop: 'enrolls',
        canSort: true,
        sortName: 'enrolls',
      } as DataColumn,
      {
        label: '',
        headClasses: 'l_w10',
        template: this.copyLinkTemplate,
      } as DataColumn,
    ];
  }
}
