import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TagsModule } from '@app/tags/tags.module';
import { UploaderModule } from '@app/uploader/uploader.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TagsModule,
    UploaderModule,
  ],
  providers: [],
})
export class UserInputModule {}
