import { TranslateService } from '@ngx-translate/core';
import { DfTextareaFieldConfig } from '../field-types';
import { DfTextFieldBuilder } from './text-field.builder';

/** Provides specialized methods for building customized multi-line text (textarea-based) form field configurations
 * @description This is not an injectable service. To create an instance, inject {link @ DfFormFieldBuilder} and
 * call one of its field type initializer methods.
 */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export class DfTextareaFieldBuilder extends DfTextFieldBuilder<DfTextareaFieldConfig> {
  constructor(translate: TranslateService, config: DfTextareaFieldConfig) {
    super(translate, config);
  }

  /** Sets an initial number of rows on the configuration's template options. This will size the textarea to fit
   * the specified number of rows. However, it may still be manually resized or overridden using CSS.
   */
  public withRows(rows: number) {
    this.config.templateOptions.rows = rows;
    return this;
  }
}
