import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents multiple integration skill assessments have been found */
@Component({
  selector: 'dgx-multiple-assessments-found-add-skills',
  template: `
    <div (click)="reviewSkills()" [innerHTML]="safeContent"></div>
    <button
      type="button"
      class="link par par--small guts-p-t-half"
      (click)="reviewSkills()"
    >
      {{ i18n.OrgSkills_ReviewSkills }}
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleAssessmentsFoundAddSkillsComponent extends NotificationItemComponentBase {
  public i18n = this.itemService.translate.instant([
    'OrgSkills_ReviewSkills',
    'OrgSkills_AddSkills',
  ]);

  public reviewSkills() {
    this.itemService.tagRatingService.openReviewIntegrationSkillsModal(
      this.params.skillRatings,
      this.i18n.OrgSkills_AddSkills,
      this.i18n.OrgSkills_AddSkills,
      false,
      this.params.providerName
    );
  }

  protected buildTemplate() {
    return this.itemService.translate.instant(
      'Notification_MultipleAssessmentsFoundAddSkills',
      {
        skillCount: this.params.skillRatings.length,
        providerName: this.params.providerName,
      }
    );
  }
}
