import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { ProfileInputCountsByTypeComponent } from './components/profile-input-counts-by-type/profile-input-counts-by-type.component';
import { ProfileShareActivityComponent } from './components/profile-share-activity/profile-share-activity.component';
import { ProfileSkillSignalService } from './components/profile-skill-signal-page/profile-skill-signal-page.service';

@NgModule({
  declarations: [
    ProfileInputCountsByTypeComponent,
    ProfileShareActivityComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [ProfileInputCountsByTypeComponent, ProfileShareActivityComponent],
  providers: [ProfileSkillSignalService],
})
export class ProfileInsightsSharedModule {}
