import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DgError } from '../models/dg-error';
import { NgxHttpClient } from '../ngx-http-client';
import { catchAndSurfaceError } from '../utils';

/**
 * Optional base class for services that need to hit a REST API.
 * @description Provides a slightly simplified interface and error handling, as compared with
 * directly using the {@link NgxHttpClient}, which is also exposed as a protected member in
 * case you need to get fancy.
 * If you catch errors thrown from an Observable, consider rethrowing it so it gets propagated
 * to the global error handler as a toast message to the user:
 * @example
 * post.pipe(catchError(e) => {
 *    handleError(e);
 *    return throwError(e));
 * });
 */
export abstract class ApiServiceBase {
  constructor(
    protected http: NgxHttpClient,
    protected defaultErrorMessage: string
  ) {}

  protected get<T>(
    url: string,
    params?: any,
    errorMessage = this.defaultErrorMessage
  ) {
    return this.http
      .get<T>(url, { params, cache: false })
      .pipe(catchAndSurfaceError<T>(errorMessage));
  }

  protected post<T>(
    url: string,
    data: any,
    errorMessage = this.defaultErrorMessage
  ) {
    return this.http
      .post<T>(url, data)
      .pipe(catchAndSurfaceError<T>(errorMessage));
  }

  protected put<T>(
    url: string,
    data: any,
    errorMessage = this.defaultErrorMessage
  ) {
    return this.http
      .put<T>(url, data)
      .pipe(catchAndSurfaceError<T>(errorMessage));
  }

  protected delete<T>(
    url: string,
    params: any,
    errorMessage = this.defaultErrorMessage
  ) {
    return this.http
      .delete<T>(url, { params })
      .pipe(catchAndSurfaceError<T>(errorMessage));
  }
}
