import { Pipe, PipeTransform } from '@angular/core';
import { MarkdownService } from '@app/markdown/services/markdown.service';

/**
 * Strips markdown *and* html, converting to plain text. Also strips <br> tags.
 */
@Pipe({
  name: 'stripMarkup',
  pure: true,
})
export class StripMarkupPipe implements PipeTransform {
  constructor(private markdownService: MarkdownService) {}

  public transform(text: string): string {
    return text ? this.markdownService.markdownToPlaintext(text) : '';
  }
}
