export class PageCoordinator {
  private readonly _items = new Array();
  private _itemsCount: number = 0;

  public get count(): number {
    return this._items.length;
  }

  public get currentPage(): number {
    return this._itemsCount;
  }

  public get isInLastPage(): boolean {
    return this._itemsCount === this._items.length;
  }

  public get isInFirstPage(): boolean {
    return this.currentPage === 1;
  }

  public get isEmpty(): boolean {
    return this._items.length === 0;
  }

  public push(item: any): void {
    this._items.push(item);
    this._itemsCount++;
  }

  public clear(): void {
    this._items.length = 0;
    this._itemsCount = 0;
  }

  public next(): any {
    if (this._itemsCount > 0) {
      return this.pop();
    } else {
      return this.peek();
    }
  }

  private peek(): any {
    return this._items[this._itemsCount - 1];
  }

  private pop(): any {
    const element = this._items.pop();
    if (element === undefined) {
      this._itemsCount = 0;
    } else {
      this._itemsCount--;
    }
    return element;
  }
}
