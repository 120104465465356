<div class="guts-p-h-2 guts-p-t-2 guts-p-b-1">
  <div
    *ngFor="let rating of ratings | slice: 0:7"
    class="l_flex guts-m-b-1"
    data-dgat="tag-rating-signal-popover-450"
  >
    <dgx-tag-rating-provider-label
      class="l_flex-grow guts-m-r-2 left-text ib"
      [rating]="rating"
      [checkpointCount]="checkpointCount"
    ></dgx-tag-rating-provider-label>
    <dgx-tag-rating-provider-level
      class="l_flex-grow right-text ib"
      [rating]="rating"
      [checkpointCount]="checkpointCount"
    ></dgx-tag-rating-provider-level>
  </div>
  <dgx-tag-rating-more-count
    class="left-text guts-m-b-1"
    *ngIf="ratings.length > 7"
    [count]="ratings.length - 7"
  ></dgx-tag-rating-more-count>
</div>
