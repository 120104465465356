import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { InsightsFeatureService } from './../services/insights-feature.service';

@Directive({
  selector: '[dgxGroupFilterToggle]',
})
export class GroupFilterToggleDirective implements OnInit {
  constructor(
    private container: ViewContainerRef,
    private insightsFeatureService: InsightsFeatureService,
    private template: TemplateRef<any>
  ) {
    
  }

  public ngOnInit() {
    if (this.insightsFeatureService.hasMinimumUserCount) {
      this.container.createEmbeddedView(this.template);
    } else {
      this.container.clear();
    }
  }
}
