import { TranslateService } from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UserSearchItem } from '@app/user/user-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';

/**
 * User Search Modal Component
 * Generic modal for selecting 1 or many users,
 * typically used for selecting collaborators.
 *
 * ```
 * // One step up from the simple modal component
 * // Contains a search field for users and a list of (if any) selected users.
 *
 * return this.modalService
 *   .show<UserSearchItem[]>(UserSearchModalComponent, {
 *     inputs: {
 *       users,
 *     },
 *   }).subscribe()
 * ```
 */

@Component({
  selector: 'dgx-user-search-modal',
  templateUrl: './user-search-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSearchModalComponent implements OnInit {
  public headerText: string = '';
  public buttonText: string = '';
  public selectedUsers: UserSearchItem[] = [];
  public isSubmitting = false;

  /** Our pre-selected list of user(s) */
  @Input() public users?: UserSearchItem | UserSearchItem[];
  /** If the modal should only allow to select one user */
  @Input() public singular?: boolean = false;
  /** Overrides the default header text 'Manage Collaborators' */
  @Input() public modalHeaderText = 'TargetAuthorsForm_Title';
  /** Overrides the default submit button text 'Save Changes' */
  @Input() public submitButtonText = 'Core_SaveChanges';
  @Input() public ariaLabel?: string;

  constructor(
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef
  ) {}

  public get formValid() {
    return this.selectedUsers?.length > 0;
  }

  public ngOnInit(): void {
    // Check if we're getting an array of users,
    // or if we're getting a singular user,
    // then setup our selectedUsers array.
    if (Array.isArray(this.users)) {
      this.selectedUsers = [...(this.users as UserSearchItem[])];
    } else if (!!this.users) {
      this.selectedUsers = [this.users as UserSearchItem];
    }

    this.headerText = this.modalHeaderText;
    this.buttonText = this.submitButtonText;
  }

  // Linter fix with the any because
  public onSelect(result: AnyRecommendee): void {
    const user = result as UserSearchItem;
    const exists = this.selectedUsers.some(
      ({ userProfileKey }) => userProfileKey === user.userProfileKey
    );

    // Check to make sure a profile isn't already selected and
    // double check if we allow more than one selected user
    if (exists) {
      // if existing
      return;
    } else if (this.singular) {
      // only allowing singular users to be selected so replace the current user
      this.selectedUsers = [user];
    } else {
      // otherwise push users to selected list!
      this.selectedUsers.push(user);
    }

    this.cdr.markForCheck();
  }

  public onRemove(result: AnyRecommendee): void {
    const user = result as UserSearchItem;
    this.selectedUsers = this.selectedUsers.filter(
      ({ userProfileKey }) => user.userProfileKey !== userProfileKey
    );
    this.cdr.markForCheck();
  }

  public onSubmit(): void {
    this.isSubmitting = true;

    this.activeModal.close(this.selectedUsers);
    this.cdr.markForCheck();
  }
}
