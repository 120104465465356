import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a user skill evaluation endorsement request notification */
@Component({
  selector: 'dgx-user-evaluation-endorsement-requested',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEvaluationEndorsementRequestedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml(
      this.params.alreadyCompleted
        ? 'dgUserEvaluationEndorsement_complete_endorsementRequested'
        : 'dgUserEvaluationEndorsement_endorsementRequested',
      {
        personNameHtml: this.userFullNameTemplate,
        skillNameHtml: this.tagNameTemplate,
      }
    );
  }
}
