import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Repository } from '@app/shared/patterns/repository/repository';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DegreedRepository
  extends ApiServiceBase
  implements Repository<any, any, any>
{
  constructor(httpClient: NgxHttpClient) {
    super(httpClient, '');
  }

  public fetchOne(id: number, useResourceImages?: boolean): Observable<any> {
    throw new Error('Method not implemented.');
  }
  public add(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  public addExisting(item: any): Observable<any> {
    throw new Error('Method not implemented.');
  }
  public update(item: any): Observable<unknown> {
    throw new Error('Method not implemented.');
  }
  public remove(id: any): Observable<void> {
    throw new Error('Method not implemented.');
  }

  public getInstitutions(
    term: string,
    isFormal: boolean,
    country: string
  ): Observable<AutocompleteItem[]> {
    const payload = {
      term: term,
      isAccredited: isFormal,
      country: isFormal ? country : '',
    };
    return this.get(`${this.baseUrl()}/institutions`, payload);
  }

  public getCountries(term: string): Observable<string[]> {
    return this.get(`${this.baseUrl()}/getcountries`, { term });
  }

  public getCourses(
    term: string,
    isFormal: boolean,
    institutionId?: number
  ): Observable<AutocompleteItem[]> {
    const url = isFormal
      ? `${this.baseUrl()}/courses`
      : `${this.baseUrl()}/informalcourses`;
    const payload = {
      term: term,
      instId: !isFormal ? institutionId : undefined,
      count: 500,
    };
    return this.get(url, payload);
  }

  private baseUrl(): string {
    return '/degreed';
  }
}
