import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-slider',
  templateUrl: './tag-rating-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingSliderComponent implements OnInit {
  @Input() public initialRating: number;
  @Input() public heading: string;
  @Input() public autoFocus: boolean;
  @Output() public onRating: EventEmitter<number> = new EventEmitter();

  public currentLevelName: string;
  public description: string;
  public sliderConfig: any = {};
  public disableRatingsDescriptions = this.authService.authUser?.defaultOrgInfo
    ?.settings.disableRatingsDescriptions;
  public sliderValue: number = 0;
  public lowestLevelName: string;
  public highestLevelName: string;
  private expertiseLevels = [
    'dgTagRating_Beginner',
    'dgTagRating_Capable',
    'dgTagRating_Intermediate',
    'dgTagRating_Effective',
    'dgTagRating_Experienced',
    'dgTagRating_Advanced',
    'dgTagRating_Distinguished',
    'dgTagRating_Master',
  ];
  public i18n = this.translateService.instant([
    ...this.expertiseLevels,
    'dgTagRating_Expert',
  ]);

  constructor(
    private translateService: TranslateService,
    private tagRatingService: TagRatingService,
    private authService: AuthService,
    private tagsService: TagsService
  ) {}

  public ngOnInit(): void {
    this.sliderConfig = this.tagRatingService.tagRatingSliderConfig;
    this.sliderValue = this.initialRating || 1;
    this.description = this.translateSliderValue();
    this.setRatingLevelNames();
  }

  public ngOnChanges({ initialRating }: SimpleChanges): void {
    if (initialRating?.currentValue !== initialRating?.previousValue) {
      this.onRatingChange(initialRating.currentValue);
    }
  }

  public translateSliderValue = () => {
    if (this.disableRatingsDescriptions) {
      return this.i18n[this.expertiseLevels[this.sliderValue - 1]];
    } else {
      return this.translateService.instant(
        `dgTagRating_SummaryScale${this.scaleMax}_Level${this.sliderValue}`
      );
    }
  };

  public onRatingChange(rating: number) {
    this.sliderValue = rating;
    this.currentLevelName = this.tagsService.getNameForRatingLevel(
      this.sliderValue
    );
    this.description = this.translateSliderValue();
    this.onRating.emit(this.sliderValue);
  }

  private setRatingLevelNames(): void {
    this.currentLevelName = this.tagsService.getNameForRatingLevel(
      this.sliderValue
    );
    const { min, max } = this.tagRatingService.tagRatingRange;
    this.lowestLevelName = this.getLowLevelName(min);
    this.highestLevelName = this.getHighLevelName(max);
  }

  private getLowLevelName(level: number): string {
    return (
      this.tagsService.getNameForRatingLevel(level) ||
      this.i18n.dgTagRating_Beginner
    );
  }

  private getHighLevelName(level: number): string {
    if (this.tagsService.getNameForRatingLevel(level)) {
      return this.tagsService.getNameForRatingLevel(level);
    } else {
      return !this.disableRatingsDescriptions
        ? this.i18n.dgTagRating_Expert
        : this.i18n.dgTagRating_Master;
    }
  }

  private get scaleMax(): number {
    return this.authService.authUser?.orgRatingScale?.anchorHigh;
  }
}
