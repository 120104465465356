import { HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { WindowToken } from '@app/shared/window.token';
import { UploadEvent, UploadEventType } from './uploader';
import {
  FileUploadSetting,
  FileUploadSettingType,
  VideoUploadSettings,
} from './uploader-api.model';

export type SupportedVideoFileType = '.mp4' | '.mv4' | '.webm' | '.mov';
export type UploadResponse = {
  success: boolean;
  message: string;
  pictureUrl: string;
};

@Injectable({ providedIn: 'root' })
export class UploaderService {
  constructor(
    private http: NgxHttpClient,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public uploadFile<T = any>(
    formData: FormData,
    api: string,
    postObject?: Object,
    queryParams?: any,
    reportProgress?: false
  ): Observable<T>;

  public uploadFile<T = any>(
    formData: FormData,
    api: string,
    postObject?: Object,
    queryParams?: any,
    reportProgress?: true
  ): Observable<UploadEvent<T>>;

  public uploadFile<T = any>(
    formData: FormData,
    api: string,
    postObject: Object = {},
    queryParams?: any,
    reportProgress?: false | true
  ): Observable<any> {
    const getFormDataSize = () =>
      (formData as any) // cast required to get around our old IE-compatible FormData type def
        .values()
        .reduce(
          (size, [_, value]) =>
            size + (typeof value === 'string' ? value.length : value.size),
          0
        );
    // TODO: Form data passed in should be immutable and we should consider providing FormData from a service for mocking
    formData = this.setFormData(formData, postObject);
    if (reportProgress) {
      // Post and report progress and completion events
      return this.http
        .post<T>(api, formData, {
          observe: 'events',
          reportProgress: !!reportProgress,
          params: { ...queryParams },
        })
        .pipe(
          filter(
            (ev) =>
              ev.type === HttpEventType.UploadProgress ||
              ev.type === HttpEventType.Response
          ),
          map((result) => {
            if (result.type === HttpEventType.UploadProgress) {
              return {
                type: UploadEventType.Progress,
                progress: result.loaded / result.total ?? getFormDataSize(),
              };
            } else if (result.type === HttpEventType.Response) {
              return {
                type: UploadEventType.Complete,
                response: result.body,
              };
            }
          })
        );
    }
    // Else, post and return only the response body
    return this.http.post<T>(api, formData, {
      observe: 'body',
      params: { ...queryParams },
    }) as any;
  }

  public scormUpload(
    formData: FormData,
    url: string,
    queryParams: any,
    reportProgress: boolean
  ): Observable<any> {
    const getFormDataSize = () =>
      (formData as any) // cast required to get around our old IE-compatible FormData type def
        .values()
        .reduce(
          (size, [_, value]) =>
            size + (typeof value === 'string' ? value.length : value.size),
          0
        );
    return this.http
      .postForScorm(url, formData, {
        observe: 'events',
        reportProgress: !!reportProgress,
        params: { ...queryParams },
      })
      .pipe(
        filter((ev) => {
          return (
            ev.type === HttpEventType.UploadProgress ||
            ev.type === HttpEventType.Response
          );
        }),
        map((result) => {
          if (result.type === HttpEventType.UploadProgress) {
            return {
              type: UploadEventType.Progress,
              progress: result.loaded / result.total ?? getFormDataSize(),
            };
          } else if (result.type === HttpEventType.Response) {
            return {
              type: UploadEventType.Complete,
              response: result.body,
            };
          }
        })
      );
  }

  public prepRawFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return formData;
  }

  public prepRawFiles(filesToUpload: FileList) {
    const formData: FormData = new FormData();
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload.item(i);
      formData.append('fileKey', file, file.name);
    }
    return formData;
  }

  public prepRawImageFile(imageFile: File, overrides: object = {}): FormData {
    const formData = new FormData();
    const params = {
      picture: imageFile,
      ...overrides,
    };
    return this.setFormData(formData, params);
  }

  public getUploadLimit(
    uploadType: FileUploadSettingType,
    doCache: boolean = true
  ) {
    return this.http
      .get<FileUploadSetting>('/files/uploadLimit', {
        params: { type: uploadType },
        cache: doCache,
      })
      .pipe(
        map((result) => {
          // filter out duplicates
          result.allowedFileTypes = [
            ...new Set(
              result.allowedFileTypes.map((fileType) => fileType.toLowerCase())
            ),
          ];
          return result;
        })
      );
  }

  public getImageDimension(
    image: File
  ): Observable<{ width: number; height: number }> {
    const URL =
      (this.windowRef as any).URL || (this.windowRef as any).webkitURL;
    return new Observable((observer) => {
      const img = new Image();
      img.onload = (event) => {
        const loadedImage: any = event.currentTarget;
        observer.next({ width: loadedImage.width, height: loadedImage.height });
        observer.complete();
      };
      img.src = URL.createObjectURL(image);
    });
  }

  public getUploadSettings(): Observable<VideoUploadSettings> {
    return this.http.get('/files/getuploadsettings');
  }

  public getUploadStatuses(standByLabel?: string) {
    if (!standByLabel) {
      standByLabel = 'dgFileUploadButton_UploadFile';
    }

    return {
      standby: {
        uploading: false,
        label: this.translateService.instant(standByLabel),
        dataIcon: '9',
        colorClass: 'color-blue',
        disableInput: false,
      },
      processing: {
        uploading: true,
        label: this.translateService.instant(
          'dgFileUploadButton_ProcessingFile'
        ),
        dataIcon: '',
        colorClass: '', // hidden anyways
        disableInput: true,
      },
      uploading: {
        uploading: true,
        label: this.translateService.instant('dgFileUploadButton_Uploading'),
        dataIcon: '', // hidden anyways
        colorClass: '', // hidden anyways
        disableInput: true,
      },
      success: {
        uploading: false,
        label: this.translateService.instant(
          'dgFileUploadButton_UploadComplete'
        ),
        dataIcon: 'X',
        colorClass: 'color-success',
        disableInput: true,
      },
      error: {
        uploading: false,
        label: this.translateService.instant('dgFileUploadButton_UploadError'),
        dataIcon: 'K',
        colorClass: 'color-error',
        disableInput: false,
      },
    };
  }

  private setFormData(formData: FormData, options: object): FormData {
    if (Object.keys(options).length > 0) {
      Object.keys(options).forEach((key) => {
        formData.append(key, options[key]);
      });
    }

    return formData;
  }
}
