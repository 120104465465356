<dgx-setting-tile-toggle-text
  *ngIf="
    configurationValue === ConfigurationValue.OrgCommunications &&
      setting.displayType === 'ToggleField';
    else settingTile
  "
  [setting]="setting"
  (settingToggled)="toggle($event)"
  (settingSaved)="toggle($event)"
></dgx-setting-tile-toggle-text>

<ng-template #settingTile>
  <div class="l_flexbar l_flex-justify">
    <label class="par--small font-medium" for="{{ setting.id }}CheckBox">{{
      getSettingName()
    }}</label>
    <dgx-toggle-switch
      *ngIf="showToggleSwitch"
      [isChecked]="setting.enabled"
      (toggled)="toggle(setting, $event)"
      id="{{ setting.id }}CheckBox"
      data-dgat="settingTile-a5a"
    ></dgx-toggle-switch>
  </div>
  <p class="grid__col-sm-6 par par--small par--light setting-tile__description">
    {{ getSettingDescription() }}
  </p>
  <!--Toggle, Field, and Selection determine different contexts-->
  <div
    *ngIf="setting.displayType === 'ToggleField'"
    [ngClass]="{ 'guts-p-t-1': !isMessageSetting }"
  >
    <div
      class="l_flexbar"
      *ngIf="configurationValue !== ConfigurationValue.OrgCommunications"
    >
      <label
        *ngIf="!isMessageSetting"
        [for]="setting.id"
        class="par--small font-semibold"
        >{{ i18n.Channel_SettingMessageLabel }}</label
      >
      <div
        *ngIf="showResetAcceptanceLink"
        class="l_flex-grow l_flex--align-right"
      >
        <button
          class="link par par--small font-medium"
          (click)="showResetModal()"
          data-dgat="settingTile-02d"
        >
          {{ i18n.OrgSettings_ResetAcceptance }}
        </button>
      </div>
    </div>
    <div *ngIf="!isMessageSetting">
      <textarea
        [id]="setting.id"
        [name]="setting.id"
        [(ngModel)]="setting.value"
        [placeholder]="i18n.Channel_SettingMessagePlaceholder"
        type="text"
        data-dgat="settingTile-7bd"
        (keyup)="textChanged()"
        *ngIf="configurationValue !== ConfigurationValue.OrgCommunications"
      ></textarea>
    </div>
    <div
      class="l_flex--align-right guts-p-t-1"
      *ngIf="isMessageSetting && setting.enabled"
    >
      <div class="grid__col-sm-6 setting-tile__textcontainer">
        <button
          class="setting-tile__button"
          [title]="i18n.dgFlexRow_EditTextSection"
          (click)="showMessageEditModal(setting)"
          data-dgat="settingTile-f3a"
        >
          <df-icon icon="pencil"></df-icon>
        </button>
        <div class="guts-p-full-half color-ebony-a45 bg-color-ebony-a03">
          <div class="guts-p-b-0 guts-m-r-2 os setting-tile__preview">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="setting.displayType === 'Field'" class="guts-p-t-1">
    <input
      *ngIf="!setting.size"
      [id]="setting.id"
      type="text"
      [name]="setting.id"
      [(ngModel)]="setting.value"
      [placeholder]="i18n.Channel_SettingMessagePlaceholder"
      data-dgat="settingTile-321"
      (keyup)="textChanged()"
    />
    <textarea
      *ngIf="setting.size"
      [id]="setting.id"
      type="text"
      [name]="setting.id"
      [(ngModel)]="setting.value"
      [attr.placeholder]="i18n.Channel_SettingMessagePlaceholder"
      [ngStyle]="{ 'min-height': setting.size * 1.5 + 'rem' }"
      data-dgat="settingTile-b89"
      (keyup)="textChanged()"
    ></textarea>
  </div>
  <div *ngIf="setting.displayType === 'Password'" class="guts-p-t-1">
    <label [for]="setting.id" class="par--small font-semibold">{{
      i18n.Channel_SettingPasswordLabel
    }}</label>
    <input
      *ngIf="!setting.revealText"
      [name]="setting.id"
      type="password"
      [(ngModel)]="setting.value"
      [placeholder]="i18n.Channel_SettingPasswordPlaceholder"
      data-dgat="settingTile-cb2"
      (keyup)="textChanged()"
    />
    <input
      *ngIf="setting.revealText"
      [name]="setting.id"
      type="text"
      [(ngModel)]="setting.value"
      [placeholder]="i18n.Channel_SettingPasswordPlaceholder"
      data-dgat="settingTile-1f4"
      (keyup)="textChanged()"
    />
    <button
      *ngIf="setting.value"
      class="link micro"
      type="button"
      (click)="setting.revealText = !setting.revealText"
      data-dgat="settingTile-7b4"
    >
      {{ i18n.Channel_SettingReveal }}
    </button>
  </div>
</ng-template>
