import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DataBulkActionButtonComponent } from './data-bulk-action-button/data-bulk-action-button.component';

export interface BulkAction {
  label: string;
  handler: () => void;
  disabled?: boolean;
  icon?: string;
}

/**
 * Use this component to add bulk edit actions for data-tables.
 *
 * Each action is defined using `dgx-data-bulk-action-button`.
 *
 * The parent component must supply
 *  - a listener for `bulkActionsCancelled` which should set each item's isSelected to false
 *  - numItemsForAction which has the number of items currently selected
 *
 * @example
 * ```
 * <dgx-data-bulk-actions
 *    [numItemsForAction]="selectedItems.length"
 *    [view]="card"
 *    (bulkActionsCancelled)="selectNone()"
 * >
 *    <dgx-data-bulk-action-button
 *      actionIcon="eye-open"
 *      [displayText]="Do Something"
 *      (buttonAction)="showMyModal()"
 *    ></dgx-data-bulk-action-button>
 *  </dgx-data-bulk-actions>
 * ```
 */
@Component({
  selector: 'dgx-data-bulk-actions',
  templateUrl: './data-bulk-actions.component.html',
})
export class DataBulkActionsComponent {
  // Bindings
  // - input
  /** Number of items for bulk action */
  @Input() public numItemsForAction = 0;
  /** View - table or card */
  @Input() public view = 'table';

  // - output
  /** Emits an event when user cancels bulk actions */
  @Output() public bulkActionsCancelled = new EventEmitter<void>();

  @ContentChildren(DataBulkActionButtonComponent)
  public buttons: DataBulkActionButtonComponent[];

  public cancelBulkActions(): void {
    this.bulkActionsCancelled.emit();
  }
}
