import { SkillsStandardsApi } from '@app/skill-standards/skill-standards-api.model';
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DgError } from '@app/shared/models/dg-error';
import { DeleteModalParams } from '@app/orgs/services/org-skills.service';
import { DeleteModalInputs } from '@app/shared/components/modal/delete-confirmation-modal/delete-modal.component';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { BusinessRule, PagedResponse } from '@app/automations/model';

@Injectable({
  providedIn: 'root',
})
export class SkillStandardsService {
  public i18n = this.translate.instant([
    'OrgSkills_UpdateSkills',
    'OrgSkills_UploadFile',
    'OrgSkills_UploadSkillsDescription',
    'SkillsSvc_AddSkillSuccess',
    'SkillsSvc_DeleteRoleError',
    'SkillsSvc_DeleteSkillError',
    'SkillsSvc_EditSkillSuccess',
    'SkillsSvc_OneMoreItem',
    'SkillsSvc_RetrieveError',
    'SkillsSvc_RolesBulkUploadNotice',
    'TargetCtrl_ConfirmDeleteButton',
  ]);

  private _skillStandardsResponse$ =
    new BehaviorSubject<SkillsStandardsApi.SkillStandardViewModel>(undefined);

  private readonly deletePhrase = 'DELETE';
  private readonly deleteInstructions = this.translate.instant(
    'TargetCtrl_DeleteInstructions',
    { delete: this.deletePhrase }
  );

  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private modalService: ModalService,
    private notifierService: NotifierService
  ) {}

  public get skillStandardsResponse() {
    return this._skillStandardsResponse$.value;
  }

  public get skillStandardsResponse$(): Observable<SkillsStandardsApi.SkillStandardViewModel> {
    return this._skillStandardsResponse$;
  }

  public setSkillStandardsResponse(
    data: SkillsStandardsApi.SkillStandardViewModel
  ) {
    this._skillStandardsResponse$.next(data);
  }

  /**
   * Reloads the Skill Standard stored in skillStandardsResponse and refreshes all subscriptions.
   * use setSkillStandardsResponse() for initial load
   */
  public refresh() {
    if (!this.skillStandardsResponse) {
      console.error('No Skill Standard is currently loaded to refresh ');
      return;
    }
    return this.getSkillStandard({
      organizationId: this.skillStandardsResponse.organizationId,
      skillStandardId: this.skillStandardsResponse.skillStandardsId,
    }).pipe(
      tap((results) => {
        this.setSkillStandardsResponse(results);
        return results;
      })
    );
  }

  public searchSkillStandard(
    params: SkillsStandardsApi.SkillStandardSearchParameters
  ): Observable<any> {
    return this.http
      .get(`/skillStandards/searchOrgSkillStandards`, {
        params: {
          ...params,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(this.translate.instant('Core_ListItemsError'), error)
          )
        )
      );
  }

  public addSkillStandard(
    params: SkillsStandardsApi.SkillStandardCreateParameters
  ): Observable<any> {
    return this.http.put('/skillStandards/addSkillStandard', params).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  public editSkillStandardName(
    params: SkillsStandardsApi.SkillStandardUpdateNameParameters
  ): Observable<any> {
    return this.http
      .put(
        '/skillStandards/updateSkillStandardsName',
        {},
        { params: { ...params } }
      )
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  public deleteSkillStandards(
    params: SkillsStandardsApi.SkillStandardGetParameters
  ): Observable<any> {
    return this.http
      .delete('/skillStandards/deleteSkillStandards', { params: { ...params } })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('Core_GeneralErrorMessage'),
              error
            )
          )
        )
      );
  }

  public removeSkillStandardsSkill(
    params: SkillsStandardsApi.SkillStandardRemoveSkillParameters
  ): Observable<any> {
    return this.http
      .delete('/skillStandards/removeSkillStandardsSkill', {
        params: { ...params },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('Core_GeneralErrorMessage'),
              error
            )
          )
        )
      );
  }

  public getSkillStandard(
    params: SkillsStandardsApi.SkillStandardGetParameters
  ): Observable<any> {
    return this.http
      .get(`/skillStandards/getSkillStandard`, {
        params: {
          ...params,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_RetrieveError'),
              error
            )
          )
        )
      );
  }

  public getSkillStandardsSegmentSkillStandardResources(
    params: SkillsStandardsApi.SkillStandardGetParameters
  ): Observable<any> {
    return this.http
      .get(
        `/skillStandards/getSkillStandardsSegmentSkillStandardResourcesByUser`,
        {
          params: {
            ...params,
          },
        }
      )
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_RetrieveError'),
              error
            )
          )
        )
      );
  }

  public getUserSkillStandards(
    params: SkillsStandardsApi.UserSkillStandardGetParameters
  ): Observable<any> {
    return this.http
      .get(`/skillStandards/getUserSkillStandards`, {
        params: {
          ...params,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_RetrieveError'),
              error
            )
          )
        )
      );
  }

  public addSkillStandardDescription(
    params: SkillsStandardsApi.SkillStandardsDescriptionParameters
  ): Observable<any> {
    return this.http.put(
      `/skillStandards/addSkillStandardsDescription`,
      {},
      {
        params: {
          ...params,
        },
      }
    );
  }

  public setAuthors(
    params: SkillsStandardsApi.SkillStandardSetAuthorsParameters
  ): Observable<any> {
    return this.http.put(`/skillStandards/setAuthors`, params);
  }

  public removeSkillStandardsSegment(
    params: SkillsStandardsApi.SkillStandardsRemoveSegmentParameters
  ): Observable<any> {
    return this.http
      .delete('/skillStandards/removeSkillStandardsSegment', {
        params: {
          ...params,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('Core_GeneralErrorMessage'),
              error
            )
          )
        )
      );
  }

  public getSegment(
    orgId: number,
    segmentId: number
  ): Observable<PagedResponse<BusinessRule>> {
    return this.http
      .get<PagedResponse<BusinessRule>>(
        `/organizations/${orgId}/automationsv2/rules/${segmentId}`,
        {
          headers: {
            'Dg-Skip-Intercept': 'true',
          },
        }
      )
      .pipe(
        map((response) => {
          response.payload.predicates?.forEach((p) => {
            if (p.comparisonValue) {
              p.comparisonValue = JSON.parse(
                p.comparisonValue as unknown as string
              );
            }
          });
          return response;
        }),
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_RetrieveError'),
              error
            )
          )
        )
      );
  }

  public addSkillStandardsSkills(
    params: SkillsStandardsApi.SkillStandardAddSkillsParameters
  ): Observable<any> {
    return this.http
      .put(`/skillStandards/addSkillStandardsSkills`, params)
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('Core_GeneralErrorMessage'),
              error
            )
          )
        )
      );
  }
  public addSkillStandardsSegments(
    params: SkillsStandardsApi.SkillStandardAddSegmentsParameters
  ): Observable<any> {
    return this.http
      .put(`/skillStandards/addSkillStandardsSegments`, params)
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('Core_GeneralErrorMessage'),
              error
            )
          )
        )
      );
  }
  public getUserSkillTargets(params) {
    return this.http.get(`/skillStandards/getUserSkillTargets`, {
      params: { ...params },
    });
  }

  public getSkillCoachUserSkillTargetList(params) {
    return this.http.get(`/managers/getUserSkillTargetsForManagerAsync`, {
      params: { ...params },
    });
  }

  /**
   * Display the skill delete modal prompt.
   * @param sourceEventTarget - Where to return focus when the modal closes. Used by AJS modal Svc.
   * @param item - Skill to be deleted.
   * @param title - Title of the modal with the skill name.
   */
  public showDeleteSkillStandardsModal({
    title,
    item,
    sourceEventTarget,
  }: DeleteModalParams): Observable<any> {
    const inputs: DeleteModalInputs = {
      title: this.translate.instant('OrgSkills_DeleteItem', { name: title }),
      description: null,
      deleteInstructions: this.deleteInstructions,
      confirmButtonText: this.i18n.TargetCtrl_ConfirmDeleteButton,
      deletePhrase: this.deletePhrase,
      descriptionClasses: 'break',
    };
    return this.modalService.showDeleteConfirmation(inputs).pipe(
      switchMap(() => {
        return this.deleteItem(item);
      }),
      catchError((error: Error) => {
        // canceling or closing the modal will result in an undefined
        // item
        if (!error) {
          return of(undefined);
        }
        const typeCased = 'SkillStandard';
        return (
          error &&
          throwError(
            new DgError(
              this.translate.instant(`SkillsSvc_Delete${typeCased}Error`),
              error
            )
          )
        );
      })
    );
  }

  public getSkillStandardSegmentRelationships(
    params: SkillsStandardsApi.SkillStandardsGetSegmentRelationshipsParameters
  ): Observable<any> {
    return this.http
      .get(`/skillStandards/getSkillStandardSegmentRelationships`, {
        params: {
          ...params,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_RetrieveError'),
              error
            )
          )
        )
      );
  }

  public updateSkillStandardSegmentRelationship(
    params: SkillsStandardsApi.SkillStandardsUpdateSegmentRelationshipsParameters
  ): Observable<any> {
    const {
      organizationId,
      skillStandardId,
      segmentId,
      skillId,
      isRequired,
      isVisible,
      proficiencyLevel,
    } = params;
    return this.http
      .put(`/skillStandards/updateSkillStandardSegmentRelationship`, {
        organizationId,
        skillStandardId,
        segmentId,
        skillId,
        isRequired,
        isVisible,
        proficiencyLevel,
      })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_RetrieveError'),
              error
            )
          )
        )
      );
  }

  public updateSegmentSkillStandardStatus(
    params: SkillsStandardsApi.SkillStandardUpdateSegmentStatusParameters
  ): Observable<any> {
    return this.http.put(
      `/skillStandards/updateSegmentSkillStandardStatus`,
      {},
      {
        params: {
          ...params,
        },
      }
    );
  }

  private deleteItem(
    item: SkillsStandardsApi.SkillStandardViewModel
  ): Observable<any> {
    const params: SkillsStandardsApi.SkillStandardGetParameters = {
      organizationId: item.organizationId,
      skillStandardId: item.skillStandardsId,
    };

    return this.deleteSkillStandards(params).pipe(
      tap(() => {
        // show success message to end user
        this.notifierService.showSuccess(
          this.translate.instant(`SkillStd_DeleteSkillStandardsSuccess`, {
            name: item.name,
          })
        );
      }),
      catchError(
        (error: Error) =>
          error &&
          throwError(
            new DgError(
              this.translate.instant(`SkillStd_DeleteSkillStandardsError`),
              error
            )
          )
      )
    );
  }
}
