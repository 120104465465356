import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrgRatingScaleLevel } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-modal',
  templateUrl: './tag-rating-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingModalComponent {
  @Input() public addNewSkill = false;
  @Input() public comment: string;
  @Input() public showComments: boolean;
  @Input() public currentRating: number;
  @Input() public ratingType: InternalTagRatingTypes;
  @Input() public emptyRating: boolean;
  @Input() public targetRating: number;
  @Input() public sourceTarget: EventTarget;
  @Input() public title: string;
  @Input() public allowClearRating: boolean = true;
  @Input() public maxMessageLength?: number;
  @Input() public requester?;
  @Input() public updateHistoryData: {
    title: string;
    date: string;
  }[];
  // used by skills checkup, has different buttons and cycles through skills
  @Input() public multiMode?: boolean;

  public updatedRating: number = null;

  public currentLevelValue: number;
  public currentLevelName: string;
  public currentLevelDescription: string;

  public newLevelValue: number;
  public newLevelName: string;
  public newLevelDescription: string;

  public targetLevelValue: number;
  public targetLevelName: string;

  public descriptionPlaceholderText: string;

  public ratingChanged: boolean = false;
  public lastRatedDate: string = null;
  public currentRatingHeader: string;
  public newRatingHeader: string;
  public updatedRatingHeader: string;
  public dropDownDefaultText: string;
  public confirmButtonText: string;
  public clearButtonText: string;

  public unratedRatingHeader: string;
  public unratedRatingDescription: string;

  public readonly i18n = this.translate.instant([
    'dgTagRating_NewSkillTarget',
    'dgTagRating_CurrentSkillTarget',
    'dgTagRating_UpdatedSkillTarget',
    'dgTagRating_ChangeSkillTarget',
    'dgTagRating_NewManagerRating',
    'dgTagRating_CurrentManagerRating',
    'dgTagRating_UpdatedManagerRating',
    'dgTagRating_ChangeManagerRating',
    'Core_NewRating',
    'dgTagRating_YourCurrentRating',
    'dgTagRating_UpdatedNewRating',
    'TagRating_ChangeRating',
    'dgTagRating_NewPeerRating',
    'dgTagRating_CurrentPeerRating',
    'dgTagRating_UpdatedPeerRating',
    'dgTagRating_ChangePeerRating',
    'dgTagRating_ClearCurrentRating',
    'dgTagRating_ClearCurrentTarget',
    'dgTagRating_saveNewRating',
    'dgTagRating_saveNewTarget',
    'dgTagRating_descriptionPlaceholderSkillTarget',
    'dgTagRating_descriptionPlaceholderPeerRating',
    'dgTagRating_descriptionPlaceholderManagerRating',
    'dgTagRating_NoSkillTarget',
    'dgTagRating_UnratedSkillTargetDescription',
    'dgTagRating_Unrated',
    'dgTagRating_UnratedDescription',
  ]);

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private tagsService: TagsService,
    private tagRatingService: TagRatingService,
    private translate: TranslateService
  ) {}

  public get maxLevel(): number {
    return this.authService.authUser?.orgRatingScale?.anchorHigh;
  }

  public get buttonConfig(): string {
    if (this.multiMode) {
      return 'multi';
    } else if (this.addNewSkill) {
      return 'add';
    } else {
      return 'confirm';
    }
  }

  /** This is super hacky but the previous modal received a variety fo values to indicate no rating, including 1 */
  public isEmptyRating(): boolean {
    return (
      this.emptyRating ||
      this.currentRating === 0 ||
      this.currentRating === undefined ||
      this.currentRating === null ||
      isNaN(this.currentRating)
    );
  }

  public getSuggestedLevelValue() {
    if (this.isEmptyRating()) {
      return this.tagRatingService.tagRatingRange.min;
    } else if (this.currentRating === this.maxLevel) {
      return this.maxLevel;
    } else {
      return this.currentRating + 1;
    }
  }

  public ngOnInit(): void {
    if (this.emptyRating) {
      this.currentRating = null;
    } else {
      this.currentRating = +this.currentRating;
    }
    this.allowClearRating ??= true;

    const currentLevel = this.getOrgRatingScaleLevel(this.currentRating);
    if (currentLevel) {
      this.currentLevelValue = currentLevel.value;
      this.currentLevelName = currentLevel.name;
      this.currentLevelDescription = currentLevel.description;
    }

    const newLevel = this.getOrgRatingScaleLevel(this.getSuggestedLevelValue());
    if (newLevel) {
      this.newLevelValue = newLevel.value;
      this.newLevelName = newLevel.name;
      this.newLevelDescription = newLevel.description;
    }

    if (this.targetRating) {
      const targetLevel = this.getOrgRatingScaleLevel(this.targetRating);
      if (targetLevel) {
        this.targetLevelValue = targetLevel.value;
        this.targetLevelName = targetLevel.name;
      } else {
        this.targetLevelValue = this.targetRating;
      }
    }

    this.setTextStrings();
  }

  public onSubmit(rating: Partial<TagsApi.UserTagRating>): void {
    this.activeModal.close(rating);
  }

  public dismiss(): void {
    this.activeModal.close(null);
  }

  public onMultiSubmit(
    rating: Partial<TagsApi.UserTagRating>,
    done: boolean
  ): void {
    this.activeModal.close({ rating, done });
  }

  public updateRating(newValue: number) {
    this.updatedRating = newValue;
    this.ratingChanged = this.updatedRating !== this.currentRating;
    const newLevel = this.getOrgRatingScaleLevel(newValue);
    this.newLevelValue = newLevel.value;
    this.newLevelName = newLevel.name;
    this.newLevelDescription = newLevel.description;
  }

  public getOrgRatingScaleLevel(value: number): OrgRatingScaleLevel {
    if (!value) {
      return null;
    }
    return this.tagsService.orgLevels.find((level) => level.value === +value);
  }

  public setTextStrings() {
    this.lastRatedDate = this.updateHistoryData?.[0]?.date;

    switch (this.ratingType) {
      case InternalTagRatingTypes.self:
        this.currentRatingHeader = this.i18n.dgTagRating_YourCurrentRating;
        this.newRatingHeader = this.i18n.Core_NewRating;
        this.updatedRatingHeader = this.i18n.dgTagRating_UpdatedNewRating;
        this.dropDownDefaultText = this.i18n.TagRating_ChangeRating;
        this.confirmButtonText = this.i18n.dgTagRating_saveNewRating;
        this.clearButtonText = this.i18n.dgTagRating_ClearCurrentRating;
        this.unratedRatingHeader = this.i18n.dgTagRating_Unrated;
        this.unratedRatingDescription = this.i18n.dgTagRating_UnratedDescription;
        break;

      case InternalTagRatingTypes.target:
        this.currentRatingHeader = this.i18n.dgTagRating_CurrentSkillTarget;
        this.newRatingHeader = this.i18n.dgTagRating_NewSkillTarget;
        this.updatedRatingHeader = this.i18n.dgTagRating_UpdatedSkillTarget;
        this.dropDownDefaultText = this.i18n.dgTagRating_ChangeSkillTarget;
        this.confirmButtonText = this.i18n.dgTagRating_saveNewTarget;
        this.clearButtonText = this.i18n.dgTagRating_ClearCurrentTarget;
        this.descriptionPlaceholderText = this.i18n.dgTagRating_descriptionPlaceholderSkillTarget;
        this.unratedRatingHeader = this.i18n.dgTagRating_NoSkillTarget;
        this.unratedRatingDescription = this.i18n.dgTagRating_UnratedSkillTargetDescription;
        break;

      case InternalTagRatingTypes.manager:
        this.currentRatingHeader = this.i18n.dgTagRating_CurrentManagerRating;
        this.newRatingHeader = this.i18n.dgTagRating_NewManagerRating;
        this.updatedRatingHeader = this.i18n.dgTagRating_UpdatedManagerRating;
        this.dropDownDefaultText = this.i18n.dgTagRating_ChangeManagerRating;
        this.confirmButtonText = this.i18n.dgTagRating_saveNewRating;
        this.clearButtonText = this.i18n.dgTagRating_ClearCurrentRating;
        this.descriptionPlaceholderText = this.i18n.dgTagRating_descriptionPlaceholderManagerRating;
        this.unratedRatingHeader = this.i18n.dgTagRating_Unrated;
        this.unratedRatingDescription = this.translate.instant(
          'dgTagRating_UnratedPeerRatingDescription',
          { name: this.requester.name }
        );
        break;

      case InternalTagRatingTypes.peer:
        this.currentRatingHeader = this.i18n.dgTagRating_CurrentPeerRating;
        this.newRatingHeader = this.i18n.dgTagRating_NewPeerRating;
        this.updatedRatingHeader = this.i18n.dgTagRating_UpdatedPeerRating;
        this.dropDownDefaultText = this.i18n.dgTagRating_ChangePeerRating;
        this.confirmButtonText = this.i18n.dgTagRating_saveNewRating;
        this.clearButtonText = this.i18n.dgTagRating_ClearCurrentRating;
        this.descriptionPlaceholderText = this.i18n.dgTagRating_descriptionPlaceholderPeerRating;
        this.unratedRatingHeader = this.i18n.dgTagRating_Unrated;
        this.unratedRatingDescription = this.translate.instant(
          'dgTagRating_UnratedPeerRatingDescription',
          { name: this.requester.name }
        );
        break;
    }
  }
}
