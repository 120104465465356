<dgx-modal
  (dismiss)="dismiss()"
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-header">
    {{ headerTitle }}
  </ng-container>

  <ng-container class="modal-body">
    <div class="guts-p-full-2">
      <p class="par">
        {{ instructions }}
      </p>
    </div>

    <form
      class="guts-p-h-2 guts-p-b-2"
      (ngSubmit)="submit()"
      *ngIf="!isLoading; else loading"
    >
      <div *ngIf="!sectionsToFollow.length" class="border-top guts-p-v-1">
        <p class="par par--small par--light_a">
          {{
            'followTargetForm_NoResources'
              | translate: { targetType: targetType }
          }}
        </p>
      </div>
      <cdk-accordion
        data-dgat="follow-target-form-modal-e0a"
        *ngIf="sectionsToFollow.length"
        class="accordion ng-star-inserted"
      >
        <ng-container
          *ngFor="
            let section of sectionsToFollow;
            trackBy: trackById;
            let index = index
          "
          data-dgat="follow-target-form-modal-064"
        >
          <div class="accordion-item">
            <cdk-accordion-item
              *ngIf="section.resources.length > 0"
              data-dgat="followTargetForm-0e7"
              #accordionItem="cdkAccordionItem"
              [attr.id]="'follow-target-row-' + index"
              [attr.aria-expanded]="
                getSectionHeaderName(section, true) + accordionItem.expanded
              "
              [attr.aria-controls]="'follow-target-row-body-' + index"
            >
              <div class="accordion-header">
                <div class="l_flexbar guts-p-full-1">
                  <label class="l_flexbar l_flex-grow">
                    <input
                      type="checkbox"
                      name="{{ section.resourceType }}"
                      [attr.aria-label]="getSectionHeaderName(section, true)"
                      [(ngModel)]="section.selected"
                      (change)="sectionSelected(section)"
                      data-dgat="followTargetForm-d78"
                    />

                    <span class="par par--small guts-p-l-1-half l_flex-one">
                      <span class="l_flexbar font-medium">{{
                        getSectionName(section.resourceType, section.name)
                      }}</span>

                      <span>{{ getSectionHeaderName(section) }}</span>
                    </span>
                  </label>

                  <button
                    (click)="accordionItem.toggle()"
                    type="button"
                    class="expander guts-p-full-1"
                    [attr.aria-expanded]="accordionItem.expanded"
                    [attr.aria-label]="
                      getAriaText(
                        accordionItem.expanded,
                        getSectionName(section.resourceType, section.name)
                      )
                    "
                    data-dgat="followTargetForm-0b6"
                  >
                    <df-icon
                      [icon]="
                        accordionItem.expanded ? 'chevron-up' : 'chevron-down'
                      "
                      size="medium"
                      [a11yText]="
                        getAriaText(
                          accordionItem.expanded,
                          getSectionName(section.resourceType, section.name)
                        )
                      "
                    ></df-icon>
                  </button>
                </div>
              </div>

              <div
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'follow-target-row-body-' + index"
                [attr.aria-labelledby]="'follow-target-row-' + index"
                [ngClass]="{
                  'accordion-body': true,
                  'no-display': !accordionItem.expanded
                }"
              >
                <ul class="border-left guts-m-l-1-quart guts-p-l-2">
                  <li
                    *ngFor="let resource of section.resources"
                    class="guts-p-v-quart"
                    data-dgat="followTargetForm-163"
                  >
                    <label class="l_flexbar">
                      <input
                        type="checkbox"
                        name="{{ resource.reference.title }}"
                        class="guts-p-r-half"
                        [(ngModel)]="resource.selected"
                        [attr.aria-label]="
                          'followTargetForm_selectTitleFormat'
                            | translate: { title: resource.reference.title }
                        "
                        (change)="resourceSelected(section, resource)"
                        data-dgat="followTargetForm-b98"
                      />
                      <span class="par par--small guts-p-l-half">{{
                        resource.reference.title
                      }}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </cdk-accordion-item>
          </div>
        </ng-container>
      </cdk-accordion>
    </form>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      type="submit"
      df-button
      dfButtonType="primary"
      class="guts-m-r-quart guts-p-h-2"
      [disabled]="isLoading || isSaving"
      [class.is_disabled]="isLoading || isSaving"
      [attr.aria-disabled]="isLoading || isSaving"
      [attr.aria-label]="
        isLoading || isSaving
          ? i18n.Core_Loading
          : ('followTargetForm_Submit' | translate: { targetType: targetType })
      "
      (click)="submit()"
      data-dgat="followTargetForm-17c"
    >
      <df-spinner-button [isSpinning]="isSaving">
        {{ 'followTargetForm_Submit' | translate: { targetType: targetType } }}
      </df-spinner-button>
    </button>
  </ng-container>
</dgx-modal>

<ng-template #loading>
  <div class="guts-p-t-4 guts-p-b-6 rel">
    <df-spinner [isSpinning]="true"></df-spinner>
  </div>
</ng-template>
