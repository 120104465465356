import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DfIconModule } from '../../icon/icon.module';
import { NgModule } from '@angular/core';
import { DfSearchBoxComponent } from './search-box.component';
import { DfInputsModule } from '../../forms/inputs/inputs.module';

@NgModule({
  imports: [
    DfIconModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DfInputsModule,
  ],
  declarations: [DfSearchBoxComponent],
  exports: [DfSearchBoxComponent],
})
export class DfSearchBoxModule {}
