import { EditMemberModalResult } from './../components/edit-member-modal/edit-member-modal.component';
import { InviteMemberModalComponent } from './../components/invite-member-modal/invite-member-modal.component';
import { OrgInfo } from '../../account/account-api.model';
import { OrgMemberSummary } from './orgs.model';
import { EditMemberModalComponent } from '../components/edit-member-modal/edit-member-modal.component';

import { ModalService } from '@app/shared/services/modal.service';
import { camelCaseKeys } from '@app/shared/utils/property';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgMemberModalService {
  constructor(private modalService: ModalService) {}

  /**
   * Displays modal to edit member role, email reset password, or remove member from the organization.
   * Returns an event that indicates whether the member was modified, or removed from the org.
   *
   * @param orgId - the current orgId being modified
   * @param member - the member to edit
   */
  public showEditMemberModal(
    orgId: number,
    member: OrgMemberSummary
  ): Observable<EditMemberModalResult> {
    return this.modalService.show(EditMemberModalComponent, {
      inputs: {
        orgId,
        member,
      },
      windowClass: 'lg-modal',
    });
  }

  /**
   * Returns modal observable.  When the modal closes, it does not provide any data.
   *
   * @param org - the current organization the member belongs to
   */
  public showInviteMemberModal(org: OrgInfo): Observable<null> {
    return this.modalService.show(InviteMemberModalComponent, {
      inputs: {
        org,
      },
    });
  }
}
