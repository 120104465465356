import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-add-integration-ratings-success-modal',
  templateUrl:
    './tag-rating-add-integration-ratings-success-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Adding Skills Success Modal component
 * This modal is display success for adding provider skill assessments and those skills to your profile.
 * On submitting this modal, it takes the user to their profile skills tab to view the users skills.
 *
 * @param skills {number} Number of skills that successfully added to your profile
 *
 * @example
 * ```
 * <dgx-tag-rating-add-integration-ratings-success-modal
 *    [skills]="skillsNumber">
 * </dgx-tag-rating-add-integration-ratings-success-modal>
 * ```
 */
export class TagRatingAddIntegrationRatingsSuccessModalComponent
  implements OnInit
{
  @Input() public skills: number; // number of skills successfully added

  public i18n: { [key: string]: string } = this.translateService.instant([
    'dgTagRating_AddSkills',
    'dgTagRating_AddingRatingsToPersonalize',
    'Core_Done',
    'dgTagRating_RateNewSkills',
  ]);
  private authUserUrl = this.authService.authUser.viewerProfile.profileUrl;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public ngOnInit(): void {
    this.i18n.dgTagRating_SuccessfullyAddedSkills =
      this.translateService.instant('dgTagRating_SuccessfullyAddedSkills', {
        skillNumber: this.skills,
      });
  }

  /**
   * Takes the user to their skills profile.
   */
  public onSubmit(): void {
    let url: string;
    url = `/profile/${this.authUserUrl}/skills`;
    // this is outside of our angular routing :(
    this.windowRef.location.href = url;
    this.activeModal.close();
  }
}
