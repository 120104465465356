import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrgMemberModalService } from '@app/orgs/services/org-member-modal.service';
import { FtpFileUploadType } from '@app/ftp/ftp-api.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-org-members-header',
  templateUrl: './org-members-header.component.html',
})
export class OrgMembersHeaderComponent implements OnInit {
  @Input() public orgId: number;
  @Input() public canInviteMembers: boolean;
  @Output() public updateQueries = new EventEmitter();

  public inviteButtonText: string;
  public members: any;
  public searchTerm: string;
  public searchPlaceholderText: string;
  public canUpdateUsers: boolean;
  public canUploadUsers: boolean;

  public readonly userUpdateType = FtpFileUploadType.UserUpdate;
  public readonly userUploadType = FtpFileUploadType.ClientUser;
  public readonly i18n = this.translate.instant([
    'OrgMembersCtrl_InviteMembers',
    'OrgMembersCtrl_InviteUsers',
    'OrgMembersCtrl_SearchForUser',
    'OrgMembersCtrl_SearchForMember',
  ]);

  private authUser: AuthUser;
  private currentOrg: OrgInfo;

  constructor(
    private authService: AuthService,
    private orgMemberModalService: OrgMemberModalService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.inviteButtonText = this.i18n.OrgMembersCtrl_InviteUsers;

    this.searchPlaceholderText = this.i18n.OrgMembersCtrl_SearchForUser;

    this.authUser = this.authService.authUser;
    this.currentOrg = this.orgId
      ? this.authUser.orgInfo.find((o) => o.organizationId === this.orgId)
      : this.authUser.defaultOrgInfo;

    // These should eventually check against the same service the FTP guard
    //   uses HOWEVER currently issues with doubling this component in org
    //   and channel prevents us from doing this.
    this.canUpdateUsers =
      this.authUser.canBulkUpload &&
      this.currentOrg?.permissions?.uploadUserUpdates;
    this.canUploadUsers =
      this.authUser.canBulkUpload &&
      this.currentOrg?.permissions?.uploadClientUser;
  }

  /**
   * Handles text changes in field, and emits the new term to listening components
   *
   * @param term
   */
  public updateQuery(term: string): void {
    this.searchTerm = term;
    this.updateQueries.emit(this.searchTerm);
  }

  /**
   * Handles button click event to display the org invite modal
   *
   * @param event
   */
  public inviteMembers(event): void {
    const orgInfo = this.authUser.orgInfo.find(
      (x) => x.organizationId === this.orgId
    );
    this.orgMemberModalService.showInviteMemberModal(orgInfo);
  }
}
