import { Inject, Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { WindowToken } from '../window.token';
import { AuthService } from './auth.service';
import { SurveyAndTipsService } from './survey-and-tips.service';
import { TrackerService } from './tracker.service';

@Injectable({
  providedIn: 'root',
})
export class UserTourService {
  public static readonly API_KEY =
    'SP7M3r64GxnYrje0hzHlBTJ60xG5VbdQL5RdFRLCu7QmV-1I2EKE-BAGH6WQgtwsCR6fb';

  constructor(
    private authService: AuthService,
    private surveyAndTipsService: SurveyAndTipsService,
    private trackerService: TrackerService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  private get authUser(): AuthUser {
    return this.authService.authUser;
  }

  private get preReleaseGroups(): string[] {
    // Bail early if this is a consumer user since orgInfo will be null and they don't have any groups anyway.
    if (this.authService.isConsumerUser) {
      return [];
    }

    // Flat map to get all of the groups that the user is a member in across all orgs
    const groupNames: string[] = []
      .concat(
        ...this.authUser.orgInfo.map((org) =>
          org.groups.map((group) => group.name)
        )
      )
      // Only include `Pre-release...` groups
      .filter((name: string) => /^Pre-release/i.test(name));

    // Remove duplicates
    return [...new Set([...groupNames])];
  }

  private get showInAppTips(): boolean {
    return (
      this.authUser.showInAppTips &&
      this.surveyAndTipsService.enableShowInAppTips
    );
  }

  private get participateInUserSurveys(): boolean {
    return (
      this.authUser.participateInUserSurveys &&
      this.surveyAndTipsService.enableParticipateInUserSurveys
    );
  }

  /** Load the Chameleon library and start the tour */
  public loadTourIfEnabled(): void {
    if (!this.authUser) {
      return;
    }

    const chameleon = require('@chamaeleonidae/chmln');
    const {
      onboardDate,
      defaultOrgInfo,
      defaultOrgId,
      viewerProfile,
      jobRole,
      isEngaged,
    } = this.authUser;
    const created = onboardDate && new Date(onboardDate).getTime() / 1000;
    const company = defaultOrgInfo && {
      uid: defaultOrgId,
      name: defaultOrgInfo.name,
    };

    chameleon.init(UserTourService.API_KEY);

    chameleon.identify(viewerProfile.userProfileId, {
      company,
      created,
      role: jobRole,
      showInAppTips: this.showInAppTips,
      participateInUserSurveys: this.participateInUserSurveys,
      preReleaseGroups: this.preReleaseGroups,
      isEngaged,
    });

    // Chameleon creates a global `chmln` object but does not export the `on` method for some reason
    (this.windowRef as any).chmln.on(
      'chmln:event',
      (action: string, properties: { [key: string]: any }) => {
        this.trackerService.trackEventData({
          action,
          properties,
        });
      }
    );
  }
}
