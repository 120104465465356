import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DfFormFieldConfig, DfTemplateOptions } from '@lib/fresco';

import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { VideoFormModel } from '../video-form.model.model';
import { VideoCommonFieldBuilder } from '../video-common-field.builder';

/** Creates an array of form field configurations for the initial UI (single URL field) when adding/editing Videos
 *  for pathways and user profile content */
@Injectable({ providedIn: 'root' })
export class DefaultInitialVideoFormRenderer implements FormRenderer {
  public expandAdvanced = new BehaviorSubject(false);
  constructor(private videoFieldBuilder: VideoCommonFieldBuilder) {}

  public render(
    context: RendererContext<VideoFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();

    if (vm.isMediaParsed || context.inputContext.isEditing) {
      throw new Error('Wrong state for renderer');
    }

    const urlFieldTuple = this.videoFieldBuilder.buildInitialMediaUrlField(
      context.templates.videoCompatibleAndDupsHelp,
      vm.shouldShowRecordVideoButton
    );
    vm.isPlayableVideo$ = urlFieldTuple[1];
    // Initial video loader URL input and recording button
    return [
      // Media URL
      urlFieldTuple[0],
      this.videoFieldBuilder.buildRecordButtonField(
        vm.videoConstraints$,
        vm.organizationId,
        vm.shouldShowRecordVideoButton,
        context.templates.videoRecordButton,
        'feed'
      ),
    ];
  }
}
