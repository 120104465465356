<ng-container *ngIf="attributes$ | async">
  <div
    class="grid grid--bleed"
    *ngFor="
      let predicateControl of predicates.controls;
      let i = index;
      first as first;
      last as last
    "
    [formGroup]="predicateControl"
  >
    <!-- Logical Operator -->
    <div class="grid__col-12">
      <div
        *ngIf="!first"
        class="center-text up-me guts-p-v-1 font-semibold rel condition-form_and-divider"
      >
        <span class="guts-p-full-half ib rel">
          {{ i18n.BusinessRules_And }}</span
        >
      </div>
    </div>
    <!-- Attribute Name -->
    <div class="grid__col-sm-8 guts-m-b-1 guts-p-r-1">
      <label [for]="'attributeName-' + i" class="form-label">
        {{ i18n.BusinessRules_AttributeName }}
        <df-label-marker></df-label-marker>
      </label>
      <dgx-combobox
        #attributesField
        [id]="'attributeName-' + i"
        class="full-width"
        [options]="attributeOptions"
        [labelKey]="'title'"
        [selectedOption]="predicateControl.get('fieldName')?.value"
        (selection)="setFieldName($event, predicateControl)"
        [placeholder]="i18n.BusinessRules_AttributeName"
        [hasNoResultsText]="i18n.Core_NoResults"
        (onBlur)="predicateControl.get('fieldName').markAsTouched()"
      ></dgx-combobox>
      <dgx-validate-field
        [message]="
          predicateControl.get('fieldName').touched &&
          predicateControl.get('fieldName').errors &&
          i18n.Core_FieldRequired
        "
      ></dgx-validate-field>
    </div>
    <!-- Condition Operator -->
    <div class="grid__col-sm-4 guts-m-b-1">
      <label [for]="'operator-' + i" class="form-label"
        >{{ i18n.BusinessRules_Operator }} <df-label-marker></df-label-marker>
      </label>
      <dgx-select
        [id]="'operator-' + i"
        class="full-width"
        [options]="getOperatorOptions(predicateControl)"
        labelKey="name"
        trackBy="value"
        (selection)="setComparisonOperator($event.value, predicateControl)"
        [selectedItem]="predicateControl.get('comparisonOperator')?.value"
        (blur)="predicateControl.get('comparisonOperator').markAsTouched()"
      ></dgx-select>
      <dgx-validate-field
        [message]="
          predicateControl.get('comparisonOperator').touched &&
          predicateControl.get('comparisonOperator').errors &&
          i18n.Core_FieldRequired
        "
      ></dgx-validate-field>
    </div>
    <!-- Attribute Value -->
    <div class="grid__col-12">
      <label [for]="'value-' + i" class="form-label"
        >{{ i18n.BusinessRules_AttributeValue }}
        <df-label-marker></df-label-marker>
      </label>
      <ng-container [ngSwitch]="getAttributeDataType(predicateControl)">
        <!-- String Value -->
        <ng-container *ngSwitchCase="AttributeDataType.String">
          <ng-container
            *ngTemplateOutlet="
              predicateControl.get('comparisonOperator')?.value !== 'isoneof' &&
              predicateControl.get('comparisonOperator')?.value !== 'isnotoneof'
                ? textAttributeValue
                : multiAttributeValueSelection;
              context: { $implicit: predicateControl, index: i, type: 'text' }
            "
          ></ng-container>
        </ng-container>
        <!-- Number or relative date Value-->
        <ng-container *ngSwitchCase="AttributeDataType.Number">
          <div class="grid grid--bleed grid--align-end">
            <div
              [ngClass]="{
                'grid__col-8': getFollowingLabel(predicateControl),
                'grid__col-12': !getFollowingLabel(predicateControl),
              }"
            >
              <ng-container
                *ngTemplateOutlet="
                  predicateControl.get('comparisonOperator')?.value !==
                    'isoneof' &&
                  predicateControl.get('comparisonOperator')?.value !==
                    'isnotoneof'
                    ? numberAttributeValue
                    : multiAttributeValueSelection;
                  context: {
                    $implicit: predicateControl,
                    index: i,
                    type: 'number',
                  }
                "
              ></ng-container>
            </div>
            <div
              class="grid__col-4 guts-p-l-2 guts-p-b-half guts-m-b-quart"
              *ngIf="getFollowingLabel(predicateControl) as trailingLabel"
            >
              {{ trailingLabel }}
            </div>
          </div>
        </ng-container>
        <!-- Static date value -->
        <div *ngSwitchCase="AttributeDataType.DateTime">
          <dgx-date-picker
            [date]="predicateControl.get('comparisonValue')?.value[0]"
            [isMinDateToday]="false"
            [placeholder]="i18n.RecommendationCtrl_SelectADate"
            (dateSelect)="setDateValue($event, predicateControl)"
            (onBlur)="predicateControl.get('comparisonValue').markAsTouched()"
          >
          </dgx-date-picker>
        </div>
        <!-- True or False -->
        <div *ngSwitchCase="AttributeDataType.Bool">
          <dgx-select
            [id]="'attributeValue-' + i"
            class="full-width"
            [options]="booleanOptions"
            labelKey="name"
            trackBy="value"
            (selection)="setComparisonValue($event, predicateControl)"
            [selectedItem]="predicateControl.get('comparisonValue')?.value[0]"
            [placeholder]="i18n.BusinessRules_Value"
            [isRequired]="true"
            (blur)="predicateControl.get('comparisonValue').markAsTouched()"
          ></dgx-select>
        </div>
        <dgx-validate-field
          [message]="
            predicateControl.get('comparisonValue').touched &&
            predicateControl.get('comparisonValue').errors &&
            i18n.Core_FieldRequired
          "
        ></dgx-validate-field>
      </ng-container>
    </div>
    <!-- Add Predicate -->
    <div class="grid__col-6">
      <div *ngIf="last && predicates.controls?.length < 20">
        <button
          class="btn btn-secondary guts-p-full-quart no-border"
          (click)="addPredicate()"
        >
          <df-icon icon="plus" size="small" [attr.aria-hidden]="'true'">
          </df-icon>
          {{ i18n.BusinessRules_AddCondition }}
        </button>
      </div>
    </div>
    <!-- Remove predicate -->
    <div class="grid__col-6">
      <div
        class="guts-p-l-2 right-text"
        *ngIf="predicates.controls?.length > 1"
      >
        <button
          class="btn btn-danger btn--ghost--no-border guts-p-full-quart"
          type="button"
          [attr.aria-label]="
            predicateControl.get('fieldName')?.value
              ? getFormattedRemoveConditionString(predicateControl)
              : i18n.Automation_RemoveEmptyCondition
          "
          (click)="removePredicate(i)"
        >
          {{ i18n.Core_Remove }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template
  #multiAttributeValueSelection
  let-predicateControl
  let-index="index"
  let-type="type"
>
  <dgx-attribute-multi-select
    [attributes]="predicateControl.get('comparisonValue')?.value"
    [index]="index"
    (attributesUpdated)="updateAttributes($event, predicateControl)"
    [helpText]="i18n.Automation_AttributeValue_HelperText"
    [placeholderText]="i18n.BusinessRules_AttributeValue"
    [a11yLabelledBy]="'attributeValue-' + index"
    [inputType]="type"
  ></dgx-attribute-multi-select>
</ng-template>

<ng-template #textAttributeValue let-predicateControl let-index="index">
  <ng-container>
    <input
      [attr.aria-labelledby]="'attributeValue-' + index"
      type="text"
      [placeholder]="i18n.BusinessRules_AttributeValue"
      title="{{ i18n.BusinessRules_AttributeValue }}"
      required="true"
      [ngModel]="predicateControl.get('comparisonValue')?.value[0]"
      (input)="setComparisonValue($event, predicateControl)"
      (blur)="predicateControl.get('comparisonValue').markAsTouched()"
    />
  </ng-container>
</ng-template>

<ng-template #numberAttributeValue let-predicateControl let-index="index">
  <input
    [attr.aria-labelledby]="'attributeValue-' + index"
    type="number"
    title="{{ i18n.BusinessRules_AttributeValue }}"
    [placeholder]="i18n.BusinessRules_AttributeValue"
    [attr.min]="getMin(predicateControl)"
    [ngModel]="predicateControl.get('comparisonValue')?.value[0]"
    (input)="setComparisonValue($event, predicateControl)"
    required="true"
    (blur)="predicateControl.get('comparisonValue').markAsTouched()"
  />
</ng-template>
