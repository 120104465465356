<nav
  class="breadcrumb"
  *ngIf="showBreadcrumbs"
  [attr.aria-label]="i18n.A11y_BreadcrumbNavType"
>
  <div class="grid center-item">
    <div class="grid__col-12 guts-p-v-0 center-item">
      <ul class="breadcrumb__list">
        <li
          *ngFor="let crumb of breadcrumbs"
          class="breadcrumb__item"
          data-dgat="breadcrumb-19b"
        >
          <ng-container *ngIf="crumb.link || crumb.routerLink">
            <a
              *ngIf="crumb.link"
              [href]="crumb.link"
              target="_self"
              class="breadcrumb__link"
              data-dgat="breadcrumb-e15"
              (click)="handleClick($event, crumb)"
              >{{ crumb.label
              }}<df-icon
                icon="chevron-right"
                size="small"
                class="guts-h-r-half"
              ></df-icon
            ></a>
            <a
              *ngIf="crumb.routerLink"
              [routerLink]="
                crumb.routerLinkParams
                  ? [crumb.routerLink, crumb.routerLinkParams]
                  : crumb.routerLink
              "
              [queryParams]="crumb.queryParams"
              class="breadcrumb__link"
              data-dgat="breadcrumb-e15"
              (click)="handleClick($event, crumb)"
              >{{ crumb.label
              }}<df-icon
                icon="chevron-right"
                size="small"
                class="guts-h-r-half"
              ></df-icon
            ></a>
          </ng-container>
          <span *ngIf="!(crumb.link || crumb.routerLink)">{{
            crumb.label
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</nav>
