import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingButtonBaseComponent } from '@app/tags/components/tag-rating-button/tag-rating-button-base.component';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-self-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfRatingButtonComponent extends TagRatingButtonBaseComponent {
  public readonly type = InternalTagRatingTypes.self;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    private tagRatingService: TagRatingService
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get pendingRatingNotificationLabel(): string {
    return this.translateService.instant('dgTagRating_SelfRatingRequested');
  }

  public get completedRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_EditExpertise');
  }

  public get ratingDescriptionLabel(): string {
    return this.translateService.instant('dgTagRating_AddSelfRatingDetail');
  }

  public get showPrivacyDropdown(): boolean {
    return (
      !this.authService.authUser?.defaultOrgInfo?.settings
        .lockSkillSelfRatingVisibility &&
      this.ownerIsViewing &&
      this.hasCompletedRatings
    );
  }

  public get menuConfig(): MenuViewModel[] {
    return [
      {
        title: this.translateService.instant('Core_ClearRating'),
        defaultAction: (event: Event) => this.clearSelfRating(event),
        isHidden: () => !this.completedRatings?.length,
      },
    ];
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    if (this.ownerIsViewing) {
      this.tagRatingService.openSelfRatingModal(event, {
        ...this.tag,
        rating: { level: this.level } as unknown as TagsApi.UserTagRating,
        ratings: this.completedRatings?.length
          ? [this.completedRatings[0] as TagsApi.UserTagRating]
          : undefined, // used for analytics
      });
    }
  }

  private clearSelfRating(event: Event): void {
    this.tagsService
      .rateTag(
        null,
        InternalTagRatingTypes.self,
        this.tag,
        this.trackingLocation,
        event.target as HTMLElement
      )
      .subscribe();
  }
}
