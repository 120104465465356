import { Component, Input } from '@angular/core';

// This file is shared with the bookmarklet/extensions
@Component({
  selector: 'dgx-notification-comment',
  template: `
    <q
      class="par par--light par--small"
      [dgxLineClampHtml]="comment | htmlToPlaintext: true | anchorify: true"
      [clampLinesMax]="maxLines"
    ></q>
  `,
})
export class NotificationCommentComponent {
  @Input() public comment: string;
  @Input() public maxLines: number;
}
