import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { ReportingContentService } from '@app/shared/services/reporting-content/reporting-content.service';
import { ReportingService } from '@app/shared/services/reporting/reporting.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'dgx-recommendations-status',
  templateUrl: './recommendations-status.component.html',
  styleUrls: ['./recommendations-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsStatusComponent implements OnInit, OnChanges {
  @Input() public orgId;
  @Input() public groupId;
  @Input() public location;

  public loading: boolean = true;
  public showMore: boolean = false;
  public chartName: string;
  public recommendations: any[];
  public detailTooltipEnabled: boolean = true;
  private numResultsPerPage: number = 20;
  private numCurrentPage: number = 0;
  private authKey;

  constructor(
    private reportingService: ReportingService,
    private authService: AuthService,
    private tracker: TrackerService,
    private reportingContentService: ReportingContentService,
    private displayTypePipe: DisplayTypePipe,
    private cdr: ChangeDetectorRef,
    private recommendationsModalService: RecommendationsModalService
  ) {}

  public ngOnInit(): void {
    this.authService.authUser$.subscribe((user) => {
      this.authKey = user.viewerProfile.userProfileKey;
    });
    this.chartName = this.reportingContentService.getChartName(
      'recommendationsStatus'
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupId) {
      this.resetRecommendations();
    }
  }

  public loadMore(): void {
    this.numCurrentPage = this.numCurrentPage += 1;
    this.getRecommendations();
  }

  public getDetailsModal($event, recommendation) {
    this.recommendationsModalService.showDetailModal(
      this.orgId,
      recommendation,
      $event
    );
  }

  public editRec($event, recommendation): void {
    this.recommendationsModalService
      .showUpdateModal(recommendation, $event)
      .subscribe((response) => {
        // TODO: this code is never reached as the recommendations-modal-service is not hooked up yet.
        // the modal currently calls a page reload to refresh data on the page
        // once that is hooked up, make sure this works.
        if (response === 'deleted') {
          this.resetRecommendations();
        }
      });
  }

  public trackEdit(name) {
    this.tracker.trackEventData({
      action: 'Shared or Assigned Module Item Edited',
      properties: { Name: name, Location: this.location },
    });
  }

  public trackRecommendationById(index, recommendation) {
    return recommendation.recommendationId;
  }

  private resetRecommendations(): void {
    this.numCurrentPage = 0;
    this.recommendations = [];
    this.getRecommendations();
  }

  private getRecommendations(): void {
    const numRecordsToSkip = this.numResultsPerPage * this.numCurrentPage;
    const groupId = this.groupId === 0 ? null : this.groupId;

    this.loading = true;

    this.reportingService
      .getGroupRecommendations(
        this.orgId,
        groupId,
        this.numResultsPerPage,
        numRecordsToSkip
      )
      .pipe(take(1))
      .subscribe((data) => {
        this.recommendations = [
          ...this.recommendations,
          ...data
            // filter out required learning
            .filter((rec) => rec.creator)
            .map((recommendation) => {
              return {
                ...recommendation,
                canEdit:
                  recommendation.creator?.userProfileKey === this.authKey,
                displayType: this.getDisplayType(recommendation),
                percentComplete: this.getPercentComplete(recommendation),
              };
            }),
        ];
        this.showMore = data.length === this.numResultsPerPage;
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  private getDisplayType(recommendation): string {
    const displayType =
      recommendation.referenceType + (recommendation.action || '');
    return this.displayTypePipe.transform(displayType);
  }

  private getPercentComplete(recommendation): number {
    return (
      (recommendation.usersCompleted /
        (recommendation.usersPending + recommendation.usersCompleted)) *
        100 || 0
    );
  }
}
