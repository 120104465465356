import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { twMerge } from '@degreed/apollo-angular';
import { TranslateService } from '@ngx-translate/core';

import {
  fileFromInput,
  LogoData,
  LogoState,
  TranslateFn,
  translateWithDefaults,
  valueFromInput,
} from '../data-service';

@Component({
  selector: 'dgx-org-branding-uploader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <div class="tw-mb-4">
        <label class="tw-text-sm tw-font-semibold">
          {{ name || 'File Upload' }}
          @if (required) {
            <span class="tw-text-red-800">*</span>
          }
        </label>

        @if (hint) {
          <p class="tw-text-sm tw-text-neutral-600">
            {{ hint }}
          </p>
        }
      </div>

      <!-- Image Loader -->
      <input
        #imageFile
        type="file"
        [id]="'loader' + instanceId"
        [name]="'loader' + instanceId"
        accept=".jpg,.jpeg,.png,.gif"
        (change)="handleFileSelected($event)"
        hidden
      />

      @if (state.isLoading) {
        <!-- Progress Loading Indicator -->
        <div
          class="tw-mb-2 tw-flex tw-aspect-video tw-w-full tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-neutral-300 tw-bg-neutral-100 tw-px-10 tw-text-neutral-600 md:tw-w-80"
        >
          <div class="tw-w-full">
            <div class="tw-flex tw-items-center tw-gap-1">
              <span class="tw-grow tw-text-xs tw-font-semibold">{{
                state.fileName
              }}</span>
            </div>
            <div
              class="tw-my-2 tw-overflow-hidden tw-rounded-full tw-bg-neutral-200"
            >
              <div
                class="tw-h-2 tw-rounded-full tw-bg-gradient-to-r tw-from-neutral-200 tw-to-blue-800"
                [ngStyle]="{ width: state.progress + '%' }"
              ></div>
            </div>
          </div>
        </div>
      } @else {
        <!-- Drag-n-Drop Area -->
        <button
          type="button"
          [ngStyle]="{ '--background-color': state.backgroundColor }"
          [class]="
            twMerge(
              'tw-group tw-relative tw-mb-2 tw-grid tw-aspect-video tw-w-full tw-place-content-center tw-text-center tw-caret-transparent md:tw-h-48',
              'tw-text-neutral-600 hover:tw-bg-blue-100',
              'tw-rounded-lg tw-border-2 tw-border-dashed',
              isDragging
                ? 'tw-border-blue-600 tw-bg-blue-100'
                : 'tw-border-neutral-600 hover:tw-border-blue-600',
              state.url && !isDragging
                ? 'tw-border-transparent tw-bg-[var(--background-color)] tw-ring-1 tw-ring-neutral-600 hover:tw-ring-0'
                : ''
            )
          "
          (dragover)="handleImageDragOver($event)"
          (dragenter)="handleImageDragEnter($event)"
          (dragleave)="handleImageDragLeave($event)"
          (drop)="handleFileSelected($event)"
          (click)="imageFile.click()"
        >
          @if (state.url) {
            <!-- Image Preview -->
            <img
              [src]="state.url"
              [alt]="state.altText"
              class="tw-pointer-events-none tw-max-h-48 tw-p-0.5"
            />
          }

          <!-- Drag-n-Drop Area Helper Text -->
          <div
            [ngClass]="[
              'tw-pointer-events-none tw-absolute tw-place-content-center tw-text-sm',
              state.url && !isDragging
                ? 'tw-hidden group-hover:tw-grid'
                : 'tw-grid',
              state.url
                ? 'tw-inset-x-0 tw-bottom-0 tw-rounded-b-lg tw-bg-blue-200 tw-p-2'
                : 'tw-inset-0 tw-rounded-lg',
            ]"
          >
            <span class="tw-font-semibold"
              >Drag and Drop or
              <span class="tw-text-blue-800">Click to Upload</span> a File</span
            >
            <p class="tw-mt-1 tw-text-neutral-600">1MB max file size</p>
          </div>
        </button>
      }

      <!-- Recommendations -->
      <div class="tw-pb-4 tw-text-neutral-600">
        <p class="tw-mb-1 tw-text-sm">{{ sizeHint }}</p>
        <em class="tw-flex tw-items-center tw-gap-2 tw-text-xs">
          <span class="tw-grow">JPEG | GIF | JPG | PNG</span>
          <span>up to 1MB</span>
        </em>
      </div>

      <!-- Alt Text area -->
      <div class="tw-px-6">
        <div class="tw-group tw-relative tw-z-0 tw-w-full">
          <input
            [id]="'image-alt' + instanceId"
            [name]="'image-alt' + instanceId"
            class="group-[.has-error]:[&amp;:not(:focus)]:tw-pr-6 focus:[&amp;:not(:placeholder-shown)]:tw-pr-6 group-hover:[&amp;:not(:placeholder-shown)]:tw-pr-6 tw-peer tw-mt-4 tw-block tw-w-full tw-cursor-text tw-appearance-none tw-border-0 tw-border-b-2 tw-border-neutral-200 tw-bg-transparent tw-px-0 tw-py-1 tw-text-base tw-font-normal tw-text-neutral-800 tw-placeholder-neutral-600 placeholder:tw-opacity-0 read-only:tw-cursor-not-allowed read-only:tw-border-dashed read-only:tw-bg-neutral-100 focus:tw-border-blue-800 focus:tw-outline-none focus:tw-ring-0 focus:placeholder:tw-opacity-100 group-[.has-error]:tw-border-red-200 group-[.has-error]:tw-text-red-800 group-[.has-error]:tw-placeholder-red-800 focus:group-[.has-error]:tw-border-red-800 dark:tw-border-neutral-700 dark:tw-text-neutral-100 dark:tw-placeholder-neutral-400 dark:read-only:tw-bg-neutral-800 dark:focus:tw-border-blue-600 dark:group-[.has-error]:tw-border-red-700 dark:group-[.has-error]:tw-text-red-100 dark:group-[.has-error]:tw-placeholder-red-400 dark:focus:group-[.has-error]:tw-border-red-700"
            placeholder="Provide a brief description of the image"
            [value]="state.altText"
            (change)="handleAltTextChange($event)"
          />

          <button
            class="tw-dark:text-neutral-300 tw-absolute tw-bottom-5 tw-right-0 tw-top-0 tw-z-10 tw-hidden tw-items-center focus:tw-flex peer-focus:peer-[&:not(:placeholder-shown)]:peer-[&:not(:read-only)]:tw-flex group-hover:peer-enabled:peer-[&:not(:placeholder-shown)]:peer-[&:not(:read-only)]:tw-flex"
            type="button"
            (click)="handleAltTextChange()"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.71967 1.71967C2.01256 1.42678 2.48744 1.42678 2.78033 1.71967L6 4.93934L9.21967 1.71967C9.51256 1.42678 9.98744 1.42678 10.2803 1.71967C10.5732 2.01256 10.5732 2.48744 10.2803 2.78033L7.06066 6L10.2803 9.21967C10.5732 9.51256 10.5732 9.98744 10.2803 10.2803C9.98744 10.5732 9.51256 10.5732 9.21967 10.2803L6 7.06066L2.78033 10.2803C2.48744 10.5732 2.01256 10.5732 1.71967 10.2803C1.42678 9.98744 1.42678 9.51256 1.71967 9.21967L4.93934 6L1.71967 2.78033C1.42678 2.48744 1.42678 2.01256 1.71967 1.71967Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <span class="tw-sr-only">Reset Alt Text</span>
          </button>

          <i
            class="tw-pointer-events-none tw-absolute tw-bottom-5 tw-right-0 tw-top-0 tw-hidden tw-items-center tw-text-neutral-600 peer-read-only:tw-flex"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              alt=""
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00002 0.799988C6.0118 0.799988 4.40002 2.41176 4.40002 4.39999V7.19999H4.00002C3.11637 7.19999 2.40002 7.91633 2.40002 8.79999V13.6C2.40002 14.4836 3.11637 15.2 4.00002 15.2H12C12.8837 15.2 13.6 14.4836 13.6 13.6V8.79999C13.6 7.91633 12.8837 7.19999 12 7.19999H11.6V4.39999C11.6 2.41176 9.98825 0.799988 8.00002 0.799988ZM10.4 7.19999V4.39999C10.4 3.0745 9.32551 1.99999 8.00002 1.99999C6.67454 1.99999 5.60002 3.0745 5.60002 4.39999V7.19999H10.4Z"
                fill="currentColor"
              />
            </svg>
          </i>

          <label
            for="mark-alt"
            class="tw-absolute tw-top-1 tw-origin-[0] -tw-translate-y-5 tw-transform tw-cursor-text tw-text-xs tw-text-neutral-600 tw-duration-300 group-hover:tw-text-neutral-800 group-[.has-error]:tw-text-red-800 group-hover:group-[.has-error]:tw-text-red-800 peer-placeholder-shown:tw-translate-y-0 peer-placeholder-shown:tw-text-base peer-placeholder-shown:tw-font-normal peer-read-only:tw-cursor-not-allowed peer-focus:tw-left-0 peer-focus:-tw-translate-y-5 peer-focus:tw-text-xs peer-focus:tw-font-semibold peer-focus:tw-text-blue-800 group-[.has-error]:peer-focus:tw-text-red-800 dark:tw-text-neutral-300 dark:group-hover:tw-text-neutral-200 dark:group-[.has-error]:tw-text-red-400 dark:group-hover:group-[.has-error]:tw-text-red-300 dark:peer-focus:tw-text-blue-600 dark:group-[.has-error]:peer-focus:tw-text-red-400"
          >
            Alt Text
          </label>
        </div>
      </div>
    </div>
  `,
})
export class OrgBrandingUploaderComponent {
  fileFromInput = fileFromInput;
  valueFromInput = valueFromInput;
  twMerge = twMerge;

  i18n: TranslateFn;
  instanceId: number;
  isDragging = false;

  @Input() name: string;
  @Input() required?: boolean;
  @Input() hint: string;
  @Input() sizeHint = 'Recommended dimensions: 320x320 pixels';

  @Input() state: LogoState;
  @Output() change = new EventEmitter<Partial<LogoData>>();

  private static counter = 0;

  constructor(private translator: TranslateService) {
    this.instanceId = OrgBrandingUploaderComponent.counter++;

    this.i18n = translateWithDefaults(translator);
  }

  // ****************************************************
  // Announce file selected or altText changes
  // ****************************************************

  handleAltTextChange(event?: Event) {
    this.change.emit({ altText: event ? valueFromInput(event) : '' });
  }

  handleFileSelected(event: Event | DragEvent) {
    event.preventDefault();

    const isDragEvent = event instanceof DragEvent;
    const file = isDragEvent
      ? event.dataTransfer.files[0]
      : fileFromInput(event);

    this.change.emit({ file });

    this.isDragging = false;
  }

  handleImageDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleImageDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = true;
  }

  handleImageDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = false;
  }
}
