import { Component, Input } from '@angular/core';
import { PathwayAuthorBaseDirective } from '@app/pathways/directives/pathway-author-base.directive';
import { AutoSaveService } from '@app/shared/services/auto-save/auto-save.service';
import { DF_SLIDE_FADE_IN_LEFT } from '@lib/fresco';
import {
  PathwayAuthoring,
  PathwayFacade,
  PathwaySection,
} from '@dg/pathways-rsm';
import { EventBus } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

/**
 * A real or faux section.
 *
 * @see {PathwayAuthorBaseDirective}
 */
@Component({
  selector: 'dgx-pathway-author-section',
  templateUrl: './pathway-author-section.component.html',
  styleUrls: [
    '../pathway-sections/pathway-sections.component.scss',
    './pathway-author-section.component.scss',
  ],
  animations: [DF_SLIDE_FADE_IN_LEFT],
})
export class PathwayAuthorSectionComponent extends PathwayAuthorBaseDirective {
  @Input() hasFauxSubsection = false;
  @Input() hideDuration = false;
  @Input() index = 0;
  @Input() isFauxSection = false;
  @Input() isOnlyChild = false;
  @Input() section: PathwaySection;

  public readonly i18n = this.translate.instant([
    ...this.baseI18n,
    'Pathways_SectionDescriptionPlaceholder',
    'Pathways_SectionTitlePlaceholder',
  ]);

  constructor(
    autoSaveService: AutoSaveService,
    authoring: PathwayAuthoring,
    eventBus: EventBus,
    facade: PathwayFacade,
    translate: TranslateService
  ) {
    super(autoSaveService, authoring, eventBus, facade, translate);
  }
}
