import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';
import { AuthUser } from '@app/account/account-api.model';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { FormGroup } from '@angular/forms';
import { RecommendationForGroup } from '@app/recommendations/recommendations.api';
import { FlexibleDate } from '@app/shared/models/core-api.model';

@Component({
  selector: 'dgx-recommendation-update-modal',
  templateUrl: './recommendation-update-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationUpdateModalComponent
  implements OnInit, AfterViewInit
{
  @Input() public recommendation: RecommendationForGroup;
  @Input() public promptDelete: Function;

  @ViewChild('dateDuePicker')
  public dateDuePicker: TemplateRef<any>;

  public i18n: { [key: string]: string };
  public model: { [key: string]: string | FlexibleDate | any } = {
    comment: '',
    dateDue: null,
  };
  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public isSaving: boolean = false;
  public isDeleting: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private formFieldBuilder: DfFormFieldBuilder,
    private translateService: TranslateService,
    private recommendationsService: RecommendationsService
  ) {}

  public get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public get isAssignment(): boolean {
    return this.recommendation.recommendationType === 'RequiredLearning';
  }

  public ngOnInit(): void {
    this.i18n = this.translateService.instant(
      [
        'Core_Save',
        'Core_Delete',
        'Core_FieldRequired',
        'recommendationForm_dateLabel',
        'recommendationForm_UpdateItem',
        'recommendationForm_WhyAssignedUpdate',
        'recommendationForm_WhyRecommendedUpdate',
        'recommendationForm_PickADate',
        'Core_Loading',
      ],
      {
        itemType: this.recommendation.referenceType,
      }
    );

    this.model = {
      dateDue: this.recommendation.dateDue,
      comment: this.recommendation.comment,
    };
  }

  public ngAfterViewInit(): void {
    this.fields = this.buildFormFields();
    this.cdr.detectChanges(); // force form control creation
  }

  public updateDateDue(formControl, date?: Date) {
    formControl.setValue(date);
  }

  public updateRecommendation(): void {
    this.isSaving = true;
    this.recommendationsService
      .updateRecommendation({
        recommendationId: this.recommendation.recommendationId,
        dateDue: this.model.dateDue,
        comment: this.model.comment,
        recommendationType: this.recommendation.recommendationType,
      })
      .subscribe(() => {
        this.isSaving = false;
        this.activeModal.close();
      });
  }

  public deleteRecommendation(event: Event): void {
    this.isDeleting = true;
    this.promptDelete(this.recommendation, event).subscribe({
      error: () => {
        // was closed without deleting
        this.isDeleting = false;
        this.cdr.markForCheck();
      },
    });
  }

  private buildFormFields(): DfFormFieldConfig[] {
    return [
      this.formFieldBuilder
        .charCountedTextarea(
          'comment',
          this.i18n[
            this.isAssignment
              ? 'recommendationForm_WhyAssignedUpdate'
              : 'recommendationForm_WhyRecommendedUpdate'
          ],
          1500
        )
        .withDgatId('recommendationUpdateForm-290')
        .updatedOn('change')
        .autofocused()
        .build(),
      this.formFieldBuilder
        .customField(
          'dateDue',
          this.i18n.recommendationForm_dateLabel,
          this.dateDuePicker
        )
        .hiddenWhen(
          () => !this.authUser?.canAssignLearning || !this.isAssignment
        )
        .build(),
    ];
  }
}
