import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeCurlys',
})
export class EscapeCurlysPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return;
    }
    return value.replace(/{{/gi, ' { { ').replace(/}}/gi, ' } } ');
  }
}
