import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

@Component({
  selector: 'dgx-new-content-in-followed-pathway',
  template: `<div [innerHTML]="safeContent"></div>
    <div class="guts-p-t-half color-blue">{{ linkText }}</div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewContentInFollowedPathwayComponent extends NotificationItemComponentBase {
  public linkText = this.itemService.translate.instant(
    'Notification_ReviewItems'
  );
  protected buildTemplate() {
    const count =
      this.params.newInputCount > 1
        ? this.translateHtml('Core_NewItems', {
            count: this.params.newInputCount,
          })
        : this.itemService.translate.instant('Core_NewItem');

    return this.translateHtml('Pathways_NewPathwayItemsNotification', {
      newItems: this.html`<b>${count}</b>`,
      pathwayTitle: this.html`<b>${this.params.pathwayTitle}</b>`,
    });
  }
}
