<dgx-modal
  [canCancel]="true"
  [item]="modalForm.value"
  [submitButtonText]="submitButtonText"
  [modalHeaderTitle]="submitButtonText"
  bodyClasses="guts-p-full-2"
  headerWrapperClasses="guts-p-h-2 guts-p-v-1"
>
  <!-- Modal body -->
  <ng-container class="modal-body">
    <df-local-notification
      [type]="NotificationType.info"
      [text]="descriptionText"
      size="small"
    ></df-local-notification>
    <p class="guts-m-v-2">
      {{ congratulationsText }}
    </p>

    <form [formGroup]="modalForm" class="form-wrap">
      <ng-container
        *ngFor="let input of inputs"
        data-dgat="award-opportunity-modal-342"
      >
        <!--
          We only want to hide the input here if it's the notifyOthers input.
          That input should be hidden if showRejectMessageEmail is false OR if
          it's disabled, which it will be until close-opportunity is true...
          There's probably a less convoluted way of doing this, but trying to
          preserve the original logic as much as possible:
        -->
        <div
          *ngIf="
            input.name !== 'notifyOthers' ||
            (showRejectMessageEmail && !modalForm.get(input.name).disabled)
          "
          class="l_flexbar guts-m-t-1"
          data-dgat="award-opportunity-modal-1a8"
        >
          <input
            [attr.data-dgat]="input.dgat"
            [id]="input.id"
            [formControlName]="input.name"
            type="checkbox"
            class="block"
          />
          <label [for]="input.id" class="block guts-m-l-1 font-regular">{{
            i18n[input.label]
          }}</label>
        </div>
      </ng-container>

      <ng-container *ngIf="showRejectionField">
        <div class="l_flexbar l_flex-justify guts-m-t-2">
          <label for="rejectionMessage">
            {{ i18n.Opportunities_Candidates_Email_RejectionMessage }}</label
          >
          <span class="par par--small color-ebony-a61">{{
            i18n.Core_Optional
          }}</span>
        </div>
        <textarea
          id="rejectionMessage"
          class="guts-p-t-quart"
          formControlName="rejectionMessage"
          maxlength="250"
          data-dgat="award-opportunity-modal-2d9"
        ></textarea>
      </ng-container>
    </form>
  </ng-container>
</dgx-modal>
