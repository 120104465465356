import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-attribute-multi-select',
  templateUrl: './attribute-multi-select.component.html',
  styleUrls: ['./attribute-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeMultiSelectComponent {
  @ViewChild('attributesInput') public attributesInput: ElementRef;
  @Input() public attributes: (string | number | Date)[] = [];
  @Input() public index: number;
  @Input() public placeholderText: string;
  @Input() public helpText: string;
  @Input() public a11yLabelledBy: string;
  @Input() public inputType = 'text';

  @Output() public attributesUpdated = new EventEmitter<
    (string | number | Date)[]
  >();
  @Output() public blur = new EventEmitter<void>();

  public searchTerm: string;

  public i18n = this.translate.instant([
    'dgGlobalSearch_A11ySearchDescription',
  ]);

  constructor(private translate: TranslateService) {}

  public remove(attribute: string | number | Date): void {
    this.attributes = this.attributes.filter((attr) => attr !== attribute);
    this.attributesUpdated.emit(this.attributes);
    this.focusAttributeField();
  }

  // Using $event.key instead of $event.which or $event.keyCode due to
  // deprecation
  public inputKeyPress($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      this.selected(this.searchTerm);
      this.searchTerm = null;
    }
  }

  public selected(attribute: string | number) {
    if (!this.attributes) {
      this.attributes = [];
    }

    if (attribute && !this.attributes?.includes(attribute)) {
      this.attributes.push(attribute);
      this.attributesUpdated.emit(this.attributes);
      this.focusAttributeField();
    }
  }

  public focusAttributeField(): void {
    this.attributesInput?.nativeElement.focus();
  }

  public handleBlur(): void {
    this.blur.emit();
  }
}
