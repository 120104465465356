<label class="df-text-input-label" [for]="name">{{ label }}</label>
<div
  *ngIf="placeholder"
  [id]="placeholderName"
  class="df-input-placeholder par par--small par--light"
>
  {{ placeholder }}
</div>
<textarea
  [id]="name"
  [disabled]="disabled || undefined"
  [readonly]="readonly || undefined"
  [attr.contenteditable]="!readonly"
  [attr.aria-label]="ariaLabel ? ariaLabel : undefined"
  [attr.aria-describedby]="placeholder ? placeholderName : undefined"
  [value]="value"
  class="df-textarea df-text-input-border"
></textarea>
