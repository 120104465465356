/**
 * Strip object properties that are undefined or null
 * Useful for removing empty properties from objects before sending to API
 */
export function deleteUndefinedOrNull<T>(source: T): T {
  Object.keys(source).forEach((key) => {
    // eslint-disable-next-line eqeqeq
    if (source[key] == undefined) {
      delete source[key];
    }
  });

  return source;
}
