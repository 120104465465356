/**
 * Change a YouTube 'shorts' URL (which we do not support)
 * to a standard URL.
 *
 * @param url - The URL to (potentially) update.
 */
export function shortsUrlToFull(url: string): string {
  // Ensure valid string before continuing.
  if (!url) {
    return '';
  }
  // If URL is either not a youtube URL or not a 'shorts' URL, return unchanged.
  if (isYouTubeShortsUrl(url)) {
    return url;
  }
  // Otherwise, parse the URL for the video ID
  const matches = url.match(
    /(?:youtu\.be\/|youtube\.com(?:\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/|embed\/|v\/|m\/|watch\?(?:[^=]+=[^&]+&)*?v=))([^"&?\/\s]{11})/
  );
  // Attempt to update the URL to something that will work in our system.
  return matches?.[1] ? `https://www.youtube.com/watch?v=${matches[1]}` : url;
}

/**
 * Internal function for identifying 'shorts' URLs.
 *
 * @param url - The URL to test.
 */
function isYouTubeShortsUrl(url: string): boolean {
  url = url.toLowerCase();
  return !url.includes('youtu') || !url.includes('shorts');
}
