import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackerService } from '@app/shared/services/tracker.service';

/**
 * This component is the matching 'success' for the reuseable orgSkillsBulkUpload
 * component. Currently used by orgSkills and orgRoles. Should not be subbed for
 * orgBulkUploadSuccess yet.
 *
 * @param heading {string} (Optional) modal heading
 * @param notice {string} description
 */
@Component({
  selector: 'dgx-org-skills-bulk-upload-success',
  templateUrl: './org-skills-bulk-upload-success.component.html',
})
export class OrgSkillsBulkUploadSuccessComponent {
  // Bindings
  @Input() public heading: string;
  @Input() public notice: string;

  // Local
  public i18n = this.translate.instant(['Core_Done']);
  constructor(
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private trackerService: TrackerService
  ) {}
  public done() {
    this.trackerService.trackEventData({ action: 'Bulk upload complete' });
    this.activeModal.dismiss();
  }
}
