import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  LoadedImage,
  MoveStart,
  MoveTypes,
} from './cropper.model';
import { CropperService } from './services/cropper.service';
import { DgxCropperSettings } from './cropper.settings';
import { CropperPositionService } from './services/cropper-position.service';
import {
  getEventForKey,
  getInvertedPositionForKey,
  getPositionForKey,
} from './services/cropper-keyboard.utils';

/**
 * This file is adapted from https://github.com/Mawi137/ngx-image-cropper with our own
 * modifications to support a secondary aspect ratio box while cropping.
 */

@Component({
  selector: 'dgx-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropperComponent implements OnChanges {
  public settings: DgxCropperSettings = new DgxCropperSettings();

  @Input() public image: string | ArrayBuffer;
  @Input() public altText: string = '';
  @Input() public maintainAspectRatio: boolean =
    this.settings.maintainAspectRatio;
  @Input() public aspectRatio: number = this.settings.aspectRatio;
  /**
   * When a value is passed in, a secondary crop box (with no handles) will be displayed with limitations:
   * - the secondary box can not be individually moved
   * - the secondary box must be shorter than the primary
   * - the secondary box width ratio must match the primary width ratio
   * i.e: primary aspect ratio = 16 / 9 secondary aspect ratio = 16 / 6
   */
  @Input() public secondaryAspectRatio: number =
    this.settings.secondaryAspectRatio;
  /** Number between 0 and 1. Percentage of the image to initially crop. Default: 0.9 (90%) */
  @Input() public cropArea: number = this.settings.cropArea;
  @Input() public cropperMinWidth: number = this.settings.cropperMinWidth;
  @Input() public cropperMinHeight: number = this.settings.cropperMinHeight;
  @Input() public cropperMaxHeight: number = this.settings.cropperMaxHeight;
  @Input() public cropperMaxWidth: number = this.settings.cropperMaxWidth;
  @Input() public cropperStaticWidth: number = this.settings.cropperStaticWidth;
  @Input() public cropperStaticHeight: number =
    this.settings.cropperStaticHeight;
  /**
   * Pass in a CropperPosition to initialize the cropper in a certain space.
   * This should be done after the cropperReady event is emitted
   */
  @Input() public cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000,
  };
  @Input() public secondaryCropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000,
  };

  @Output()
  public imageCropped: EventEmitter<ImageCroppedEvent> = new EventEmitter<ImageCroppedEvent>();
  @Output()
  public startCropImage: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public imageLoaded: EventEmitter<LoadedImage> = new EventEmitter<LoadedImage>();
  @Output()
  public cropperReady: EventEmitter<Dimensions> = new EventEmitter<Dimensions>();
  @Output()
  public loadImageFailed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('sourceImage', { static: false })
  public sourceImage: ElementRef<HTMLDivElement>;
  public imgDataUrl: string;
  public loadedImage: LoadedImage;
  public moveStart: MoveStart;
  public maxSize: Dimensions;
  public imageVisible: boolean = false;
  public marginLeft: string = '0px';
  public moveTypes: MoveTypes;
  public readonly dataUrlRegEx = new RegExp(/^data:/);
  public readonly urlValidatorRegEx = new RegExp(
    '^(https?://){1}([a-zA-Z0-9.-]{1,256})\\.([a-z.]{2,6})[/\\w .-]*/?'
  );
  private setImageMaxSizeRetries = 0;
  // prevent the cropper from going smaller than 20x20
  private readonly defaultMinHeight = 20;
  private readonly defaultMinWidth = 20;

  constructor(
    private cdr: ChangeDetectorRef,
    private cropperService: CropperService,
    private cropperPositionService: CropperPositionService
  ) {
    this.reset();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.onChangesUpdateSettings(changes);
    this.onChangesInputImage(changes);

    if (
      (changes.aspectRatio || changes.secondaryAspectRatio) &&
      this.imageVisible
    ) {
      this.resetCropperPosition();
    }

    if (changes.cropper && changes.cropper.currentValue) {
      this.setMaxSize();
      this.setCropperScaledMinSize();
      this.setCropperScaledMaxSize();
      this.checkCropperPosition(false);
      this.cropperPositionService.setSecondaryCropperPosition(
        this.cropper,
        this.settings,
        this.secondaryCropper
      );
      this.crop();
      this.cdr.markForCheck();
    }
  }

  /** Called when the src is loaded in the <img/> element */
  public imageLoadedInView(): void {
    if (this.loadedImage !== null) {
      this.imageLoaded.emit(this.loadedImage);
      this.setImageMaxSizeRetries = 0;
      setTimeout(() => this.checkImageMaxSizeRecursively());
    }
  }

  public resetCropperPosition(): void {
    this.cropperPositionService.resetCropperPosition(
      this.sourceImage,
      this.cropper,
      this.settings
    );
    this.cropperPositionService.setSecondaryCropperPosition(
      this.cropper,
      this.settings,
      this.secondaryCropper
    );
    this.crop();
    this.imageVisible = true;
  }

  public crop(): ImageCroppedEvent | null {
    if (this.sourceImage?.nativeElement && this.loadedImage.image !== null) {
      this.startCropImage.emit();
      const output: ImageCroppedEvent = {
        cropper: this.cropperService.crop(
          this.sourceImage,
          this.loadedImage,
          this.cropper
        ),
      };
      if (this.secondaryAspectRatio) {
        output.secondaryCropper = this.cropperService.crop(
          this.sourceImage,
          this.loadedImage,
          this.secondaryCropper
        );
      }

      if (output.cropper !== null) {
        this.imageCropped.emit(output);
      }
      return output;
    }
    return null;
  }

  public startMove(
    event: any,
    // couldn't get the linter to work with this as an enum, casting it later.
    moveType: string,
    position: string | null = null
  ): void {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.moveStart = {
      active: true,
      type: moveType as MoveTypes,
      position,
      clientX: this.cropperPositionService.getClientX(event),
      clientY: this.cropperPositionService.getClientY(event),
      ...this.cropper,
    };
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  public moveImg(event: any): void {
    if (this.moveStart.active) {
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (event.preventDefault) {
        event.preventDefault();
      }
      if (this.moveStart.type === MoveTypes.Move) {
        this.cropperPositionService.move(event, this.moveStart, this.cropper);
        this.checkCropperPosition(true);
        this.cropperPositionService.setSecondaryCropperPosition(
          this.cropper,
          this.settings,
          this.secondaryCropper
        );
      } else if (this.moveStart.type === MoveTypes.Resize) {
        if (!this.cropperStaticWidth && !this.cropperStaticHeight) {
          this.cropperPositionService.resize(
            event,
            this.moveStart,
            this.cropper,
            this.maxSize,
            this.settings
          );
        }
        this.checkCropperPosition(false);
        this.cropperPositionService.setSecondaryCropperPosition(
          this.cropper,
          this.settings,
          this.secondaryCropper
        );
      }
      this.cdr.detectChanges();
    }
  }

  @HostListener('document:mouseup')
  @HostListener('document:touchend')
  public moveStop(): void {
    if (this.moveStart.active) {
      this.moveStart.active = false;
      this.crop();
    }
  }

  public keyboardAccess(event: any) {
    const keyboardWhiteList: string[] = [
      'ArrowUp',
      'ArrowDown',
      'ArrowRight',
      'ArrowLeft',
    ];
    if (!keyboardWhiteList.includes(event.key)) {
      return;
    }
    const moveType = event.shiftKey ? MoveTypes.Resize : MoveTypes.Move;
    const position = event.altKey
      ? getInvertedPositionForKey(event.key)
      : getPositionForKey(event.key);
    const moveEvent = getEventForKey(event.key, 3);
    event.preventDefault();
    event.stopPropagation();
    this.startMove({ clientX: 0, clientY: 0 }, moveType, position);
    this.moveImg(moveEvent);
    this.moveStop();
  }

  /** wait for the image to load completely before cropping */
  private checkImageMaxSizeRecursively(): void {
    if (this.setImageMaxSizeRetries > 40) {
      this.loadImageFailed.emit();
    } else if (this.sourceImageLoaded()) {
      this.setMaxSize();
      this.setCropperScaledMinSize();
      this.setCropperScaledMaxSize();
      this.resetCropperPosition();
      this.cropperReady.emit({ ...this.maxSize });
      this.cdr.markForCheck();
    } else {
      this.setImageMaxSizeRetries++;
      setTimeout(() => this.checkImageMaxSizeRecursively(), 50);
    }
  }

  /**
   * Check the cropper position, taking into account the scaled maxSize. This prevents the cropper from overflowing the image
   * @param maintainSize when true, the cropper position will be updated while keeping the croppers current size intact
   */
  private checkCropperPosition(maintainSize = false): void {
    if (this.cropper.x1 < 0) {
      this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
      this.cropper.x1 = 0;
    }
    if (this.cropper.y1 < 0) {
      this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
      this.cropper.y1 = 0;
    }
    if (this.cropper.x2 > this.maxSize.width) {
      this.cropper.x1 -= maintainSize
        ? this.cropper.x2 - this.maxSize.width
        : 0;
      this.cropper.x2 = this.maxSize.width;
    }
    if (this.cropper.y2 > this.maxSize.height) {
      this.cropper.y1 -= maintainSize
        ? this.cropper.y2 - this.maxSize.height
        : 0;
      this.cropper.y2 = this.maxSize.height;
    }
  }

  private sourceImageLoaded(): boolean {
    return this.sourceImage?.nativeElement?.offsetWidth > 0;
  }

  private reset(): void {
    this.imageVisible = false;
    this.loadedImage = null;
    this.moveStart = {
      active: false,
      type: null,
      position: null,
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      clientX: 0,
      clientY: 0,
    };
    this.maxSize = {
      width: 0,
      height: 0,
    };
    this.cropper = {
      x1: -100,
      y1: -100,
      x2: 10000,
      y2: 10000,
    };
  }

  private setMaxSize(): void {
    if (this.sourceImage) {
      const sourceImageElement = this.sourceImage.nativeElement;
      this.maxSize.width = sourceImageElement.offsetWidth;
      this.maxSize.height = sourceImageElement.offsetHeight;
      // center the cropper
      this.marginLeft = 'calc(50% - ' + this.maxSize.width / 2 + 'px)';
    }
  }

  private setCropperScaledMinSize(): void {
    if (this.loadedImage?.image) {
      this.setCropperScaledMinWidth();
      this.setCropperScaledMinHeight();
    } else {
      this.settings.cropperScaledMinWidth = this.defaultMinWidth;
      this.settings.cropperScaledMinHeight = this.defaultMinHeight;
    }
  }
  private setCropperScaledMinWidth(): void {
    this.settings.cropperScaledMinWidth =
      this.cropperMinWidth > 0
        ? Math.max(
            this.defaultMinWidth,
            (this.cropperMinWidth / this.loadedImage.image.width) *
              this.maxSize.width
          )
        : this.defaultMinWidth;
  }

  private setCropperScaledMinHeight(): void {
    if (this.maintainAspectRatio) {
      this.settings.cropperScaledMinHeight = Math.max(
        this.defaultMinHeight,
        this.settings.cropperScaledMinWidth / this.aspectRatio
      );
    } else if (this.cropperMinHeight > 0) {
      this.settings.cropperScaledMinHeight = Math.max(
        this.defaultMinHeight,
        (this.cropperMinHeight / this.loadedImage.image.height) *
          this.maxSize.height
      );
    } else {
      this.settings.cropperScaledMinHeight = this.defaultMinHeight;
    }
  }

  private setCropperScaledMaxSize(): void {
    if (this.loadedImage?.image) {
      const ratio = this.loadedImage.size.width / this.maxSize.width;
      this.settings.cropperScaledMaxWidth =
        this.cropperMaxWidth > this.defaultMinWidth
          ? this.cropperMaxWidth / ratio
          : this.maxSize.width;
      this.settings.cropperScaledMaxHeight =
        this.cropperMaxHeight > this.defaultMinHeight
          ? this.cropperMaxHeight / ratio
          : this.maxSize.height;
      if (this.maintainAspectRatio) {
        if (
          this.settings.cropperScaledMaxWidth >
          this.settings.cropperScaledMaxHeight * this.aspectRatio
        ) {
          this.settings.cropperScaledMaxWidth =
            this.settings.cropperScaledMaxHeight * this.aspectRatio;
        } else if (
          this.settings.cropperScaledMaxWidth <
          this.settings.cropperScaledMaxHeight * this.aspectRatio
        ) {
          this.settings.cropperScaledMaxHeight =
            this.settings.cropperScaledMaxWidth / this.aspectRatio;
        }
      }
    } else {
      this.settings.cropperScaledMaxWidth = this.maxSize.width;
      this.settings.cropperScaledMaxHeight = this.maxSize.height;
    }
  }

  private onChangesUpdateSettings(changes: SimpleChanges) {
    this.settings.setOptionsFromChanges(changes);

    if (this.settings.cropperStaticHeight && this.settings.cropperStaticWidth) {
      this.settings.setStaticCropper();
    }
  }

  private onChangesInputImage(changes: SimpleChanges): void {
    if (changes.image) {
      this.reset();
      this.loadImage(this.image);
    }
  }

  private loadImage(image) {
    if (this.dataUrlRegEx.test(image)) {
      this.loadBase64Image(image);
    } else if (this.urlValidatorRegEx.test(image)) {
      this.loadImageFromURL(image);
    } else {
      throw new Error('The cropper does not support this image type.');
    }
  }
  private loadBase64Image(imageBase64: string) {
    this.cropperService.loadBase64Image(imageBase64).subscribe(
      (results) => this.setImageLoaded(results),
      (error) => this.loadImageError(error)
    );
  }
  private loadImageFromURL(imageUrl: string) {
    this.cropperService.loadImageFromURL(imageUrl).subscribe(
      (results) => this.setImageLoaded(results),
      (error) => this.loadImageError(error)
    );
  }

  private setImageLoaded(loadedImage: LoadedImage): void {
    this.loadedImage = loadedImage;
    this.imgDataUrl = this.loadedImage.base64;
    this.cdr.markForCheck();
  }

  private loadImageError(error: any): void {
    console.error(error);
    this.loadImageFailed.emit();
  }
}
