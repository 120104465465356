import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  cloneDeep as _cloneDeep,
  isEqual as _isEqual,
  pick as _pick,
} from 'lodash-es';
import { LOCAL_STORAGE } from '@app/shared/services/storage';
import { TrackerService } from '@app/shared/services/tracker.service';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { SearchFilterFilter } from '../search-api.model';
import { SearchProviderCustomProperties } from '../components/search-provider-view/search-provider-view.component';
import { TrackingEventsParams } from '@dg/shared-services';
import { CatalogSource } from '@app/shared/utils/tracker-helper';
import { AnalyticsAppLocation } from '@app/shared/web-environment-info';

@Injectable({ providedIn: 'root' })
export class SearchTrackerService {
  private LOCAL_STORAGE_SEARCH_TRACKING_DATA = 'searchTrackingData';
  private _searchData$ = new BehaviorSubject<SearchData>(undefined);

  constructor(
    private trackerService: TrackerService,
    @Inject(LOCAL_STORAGE) private localStorage: Storage
  ) {}

  /*
   * Data shared between components on search that
   * aren't directly related but uses the data in tracking.
   */
  public get searchData(): SearchData {
    return this._searchData$.value || {};
  }

  public setTrackerLocationSearch(): void {
    this.trackerService.setLocation(
      SearchOrigin.globalSearch as AnalyticsAppLocation
    );
  }

  public setTrackerLocationMarketplace(): void {
    this.trackerService.setLocation(
      SearchOrigin.marketplace as AnalyticsAppLocation
    );
  }

  public searchInitiated(p: SearchInitiated = {}): void {
    this.trackerService.trackEventData({
      action: 'Search Initiated',
      properties: {
        searchLocation: p.origin,
        keywords: p.searchTerm,
        typeaheadInitiationLocation: p.typeaheadInitiationLocation,
      },
    });
  }

  public searchExecuted(p: SearchExecuted = {}): void {
    this.setSearchData({
      searchTerm: p.searchTerm,
      pageNumber: p.pageNumber,
      contentTotal: p.contentTotal,
      previousSearchFilters: p.filters,
    });

    this.trackerService.trackEventData({
      action: 'Search Executed',
      properties: {
        submitMethod: this.searchData.submitMethod,
        searchLocation: p.origin,
        keywords: p.searchTerm,
        typoSuggestedKeywords: p.suggestedTerm,
        isExternal: p.isExternal,
        relatedSkills: p.relatedSkills || undefined,

        filter: this.getFilterTypes(this.searchData.previousSearchFilters),
        ...this.getFiltersByType(this.searchData.previousSearchFilters),
        ...this.getResultCounts(),
        ...p.custom,
      },
    });

    this.clearOneTimeData();
  }

  public searchResultViewChangeInitiated(initiatedFilter: string): void {
    this.trackerService.trackEventData({
      action: 'Search Results View Change Initiated',
      properties: {
        keywords: this.searchData.searchTerm,
        filter: initiatedFilter,
      },
    });
  }

  public searchResultViewChanged(p: SearchResultView = {}): void {
    this.setSearchData({
      contentTotal: p.contentTotal,
      pageNumber: p.pageNumber,
      // don't update previousSearchFilters yet, since we will need them to determine changes
    });

    this.trackerService.trackEventData({
      action: 'Search Result View Changed',
      properties: {
        searchLocation: p.origin,
        isExternal: p.isExternal,
        keywords: p.searchTerm || this.searchData.searchTerm,

        paginationChangedFrom:
          p.previousPageNumber !== p.pageNumber
            ? p.previousPageNumber
            : undefined,
        paginationChangedTo:
          !!p.previousPageNumber && p.previousPageNumber !== p.pageNumber
            ? p.pageNumber
            : undefined,
        linkClicked: p.linkClicked,
        sortType: p.sortType,

        filter: this.getFilterTypes(p.filters),
        ...this.getResultCounts(),
        ...this.getFilterChanges(p.filters),
        ...p.custom,
      },
    });

    this.clearOneTimeData();
  }

  public loadMoreClicked(p: SearchResultView = {}) {
    this.trackerService.trackEventData({
      action: 'Load More Endorsed Results Clicked',
      properties: {
        isExternal: p.isExternal,
        keywords: p.searchTerm || this.searchData.searchTerm,
        sortType: p.sortType,
        filter: this.getFilterTypes(p.filters),
        linkClicked: SearchSubmitMethod.loadMoreEndorsed,
      },
    });

    this.clearOneTimeData();
  }

  public contentClicked(
    resource: LearningResourceViewModel,
    p: ContentClicked
  ): void {
    this.setSearchData({
      contentTotal: p?.totalContentResults,
    });

    this.trackerService.trackEventData({
      action: 'Content Clicked',
      properties: this.getContentClickedEventProperties(resource, p),
    });
  }

  public contentOpened(
    resource: LearningResourceViewModel,
    p: ContentClicked
  ): Observable<TrackingEventsParams> {
    this.setSearchData({
      contentTotal: p?.totalContentResults,
    });

    return this.trackerService.trackEventData({
      action: 'Content Opened',
      properties: this.getContentClickedEventProperties(resource, p),
    });
  }

  public typeaheadClicked({
    searchTerm,
    type,
    keywordsClicked,
    filter,
    isCleanSearch,
    typeaheadInitiationLocation,
  }: TypeaheadClicked): Observable<TrackingEventsParams> {
    return this.trackerService.trackEventData({
      action: 'Search Typeahead Clicked',
      properties: {
        keywords: searchTerm,
        type,
        keywordsClicked,
        filter,
        isCleanSearch,
        typeaheadInitiationLocation,
      },
    });
  }

  public searchTypoSuggestionClicked(
    searchTerm: string,
    suggestedTerm: string
  ): void {
    this.trackerService.trackEventData({
      action: 'Search Typo Suggestion Clicked',
      properties: {
        keywords: searchTerm,
        suggestedKeywords: suggestedTerm,
      },
    });
  }

  public searchTypoSuggestionAutoSearched(
    searchTerm: string,
    suggestedTerm: string
  ): void {
    this.trackerService.trackEventData({
      action: 'Search Typo Suggestion Auto Searched',
      properties: {
        keywords: searchTerm,
        suggestedKeywords: suggestedTerm,
      },
    });
  }

  public suggestedTermOpened(
    term: string,
    index: number,
    allTerms: string[]
  ): void {
    this.trackerService.trackEventData({
      action: 'Search Skills Suggestion Opened',
      properties: {
        keywordClicked: term,
        previousKeyword:
          this.searchData.submitMethod ===
          SearchSubmitMethod.suggestedTermClicked
            ? this.searchData.searchTerm
            : undefined,
        keywordPosition: index,
        suggestedKeywordsCount: allTerms.length,
        suggestedKeywords: allTerms,
        currentPage: this.searchData.pageNumber,

        filter: this.getFilterTypes(this.searchData.previousSearchFilters),
        ...this.getResultCounts(),
        ...this.getFiltersByType(this.searchData.previousSearchFilters),
      },
    });
  }

  public searchFeaturedCarouselItemClicked(
    resourceId: number,
    resourceType: string
  ) {
    this.trackerService.trackEventData({
      action: 'Search Featured Carousel Slide Changed',
      properties: {
        contentId: resourceId,
        contentType: resourceType,
      },
    });
  }

  /*
   * Used to save data unavailable to other components that need it
   * etc: submitMethod, count, filters
   */
  public setSearchData(p: SearchData): void {
    const savedData = JSON.parse(
      this.localStorage.getItem(this.LOCAL_STORAGE_SEARCH_TRACKING_DATA)
    );

    // If we have existing savedData or searchData then:
    // merge objects and override the older data if updated
    const newState =
      savedData || this.searchData
        ? _cloneDeep({
            ...savedData,
            ...this.searchData,
            ...p,
          })
        : _cloneDeep({ ...p });

    this._searchData$.next(newState);

    // After merging or setting data, update local storage data
    this.localStorage.setItem(
      this.LOCAL_STORAGE_SEARCH_TRACKING_DATA,
      JSON.stringify(this.searchData)
    );
  }

  private getContentClickedEventProperties(
    resource: LearningResourceViewModel,
    p: ContentClicked
  ) {
    return {
      resultType: 'Content',
      descriptionCharCount: resource.displaySummary?.length,
      hasImage: !!resource.imageUrl,
      providerId: resource.providerSummary?.id,
      providerName: resource.providerSummary?.name,
      isEndorsed: resource.isEndorsed,
      contentId: resource.resourceId,
      contentName: resource.title,
      hostedType: resource.model.hostedType,
      contentType: resource.resourceType,
      catalogSource: resource.internalUrl
        ? CatalogSource.Internal
        : CatalogSource.External,
      organizationId: resource.organizationId,

      keywords: p?.searchTerm,
      currentPage: p?.currentPage,
      resultPosition: p?.resultPosition,
      sessionCount: p?.sessionCount,

      ...this.getResultCounts('totalResults'),
      ...this.getFiltersByType(this.searchData.previousSearchFilters),
    };
  }

  /*
   * returns applied filter types
   * ex: ['Provider', 'Duration']
   */
  private getFilterTypes(filters: SearchFilterFilter[]): string[] {
    if (!filters) {
      return [];
    }

    return filters.reduce((arr, { title, subitems }) => {
      if (subitems.some((item) => item.isSelected)) {
        arr.push(title);
      }
      return arr;
    }, []);
  }

  /*
   * returns filters grouped by type
   * ex: filterProvider: ['Degreed']
   * allowEmpty: allows the return of the filter with an empty array
   * specifically useful when determining filter changes
   */
  private getFiltersByType(
    filters: SearchFilterFilter[],
    allowEmpty?: boolean
  ): { [key: string]: string[] } {
    if (!filters) {
      return {};
    }

    return filters.reduce((obj, { title, subitems }) => {
      const filterItems = subitems
        .filter((item) => item.isSelected)
        .map((item) => item.title);

      if (allowEmpty || filterItems.length > 0) {
        return {
          ...obj,
          [`filter${title}`]: filterItems,
        };
      }
      return obj;
    }, {});
  }

  /*
   * returns applied filters or changed filters grouped by type
   * ex: filterProviderChangedFrom/To if changed or filterProvider if unchanged
   */
  private getFilterChanges(filters: SearchFilterFilter[]): {
    [key: string]: string[];
  } {
    const previousFilters = this.getFiltersByType(
      this.searchData.previousSearchFilters,
      true
    );
    const currentFilters = this.getFiltersByType(filters, true);

    this.setSearchData({
      previousSearchFilters: filters,
    });

    return Object.keys(currentFilters).reduce((obj, key) => {
      if (
        !!previousFilters[key] &&
        !_isEqual(previousFilters[key].sort(), currentFilters[key].sort())
      ) {
        return {
          ...obj,
          [`${key}ChangedFrom`]: previousFilters[key],
          [`${key}ChangedTo`]: currentFilters[key],
        };
      } else if (currentFilters[key]?.length > 0) {
        return {
          ...obj,
          [key]: currentFilters[key],
        };
      }
      return obj;
    }, {});
  }

  /*
   * returns the search results grouped by types
   * totalResultsKey: optional param since some events use a different property name
   */

  private getResultCounts(totalResultsKey?: string): ResultCounts {
    const content = this.searchData?.contentTotal || 0;

    const total = content;

    return {
      contentResultsCount: content,
      [totalResultsKey ?? 'resultCount']: total ?? undefined,
    };
  }

  /*
   * clears one time use search data
   */
  private clearOneTimeData(): void {
    this._searchData$.next(
      _pick(this.searchData, [
        'searchTerm',
        'pageNumber',
        'contentTotal',
        'skillsTotal',
        'peopleTotal',
        'groupsTotal',
        'opportunitiesTotal',
        'submitMethod',
        'previousSearchFilters',
      ])
    );

    this.localStorage.setItem(
      this.LOCAL_STORAGE_SEARCH_TRACKING_DATA,
      JSON.stringify(this.searchData)
    );
  }
}

export enum SearchOrigin {
  globalSearch = 'Search',
  search = 'Catalog Search',
  opportunities = 'Opportunities Landing Page',
  skills = 'Skills Landing Page',
  groups = 'Groups Landing Page',
  people = 'People Landing Page',
  provider = 'Provider Landing Page',
  mentors = 'Mentors Landing Page',
  tagRatingOverviewModal = 'Tag Rating Overview Modal',
  actionOptionMenu = 'Action Option Menu',
  opportunityPage = 'Opportunity Page',
  socialCounts = 'Social Counts',
  providerBubble = 'Provider Bubble',
  searchLearnings = 'Learning Landing Page',
  marketplace = 'Search Marketplace',
  featuredItems = 'Search Marketplace Featured Items',
}

export enum SearchTypeaheadInitLocations {
  searchBar = 'search-bar',
  navSearchBar = 'nav-search-bar',
}

export enum SearchSubmitMethod {
  clearFilters = 'Clear Filters',
  enterKey = 'Enter Key',
  allResults = 'All Results',
  skillsClicked = 'Search Skills Clicked',
  filtersClicked = 'Search Typeahead Filters Clicked',
  learningTabClicked = 'Search Learning Tab Clicked',
  skillsTabClicked = 'Search Skills Tab Clicked',
  peopleTabClicked = 'Search People Tab Clicked',
  groupsTabClicked = 'Search Groups Tab Clicked',
  opportunitiesTabClicked = 'Search Opportunities Tab Clicked',
  typeaheadClicked = 'Search Typeahead Clicked',
  moreOpportunities = 'More Opportunities Clicked',
  moreGroups = 'More Groups Clicked',
  morePeople = 'More People Clicked',
  moreSkills = 'More Skills Clicked',
  providerLink = 'Provider Link Clicked',
  popular = 'Popular',
  recent = 'Recent',
  relevant = 'Relevant',
  findContentClicked = 'Find Content Clicked',
  skillTagClicked = 'Skill tag Clicked',
  suggestedTermClicked = 'Suggested Keyword Clicked',
  socialCounts = 'Social Counts Tag Clicked',
  browseProvider = 'Browse Provider Link Clicked',
  sortBy = 'sort-by',
  loadMoreEndorsed = 'Load More Endorsed',
}

// add more custom property interfaces from the view components as needed
export type CustomSearchTrackerProperties =
  Partial<SearchProviderCustomProperties>;

interface SearchData extends SearchTotals {
  searchTerm?: string;
  submitMethod?: string;
  pageNumber?: number;
  latestFilterChanged?: string;
  previousSearchFilters?: SearchFilterFilter[];
  relatedSkills?: string[];
}

export interface SearchInitiated {
  searchTerm?: string;
  origin?: SearchOrigin;
  typeaheadInitiationLocation?: SearchTypeaheadInitLocations;
}

export interface TypeaheadClicked {
  searchTerm: string;
  type: string;
  keywordsClicked: string;
  filter?: string[];
  isCleanSearch?: boolean;
  typeaheadInitiationLocation?: SearchTypeaheadInitLocations;
}

export interface SearchQuery extends SearchInitiated {
  pageNumber?: number;
  filters?: SearchFilterFilter[];
  isExternal?: boolean;
  isMarketplace?: boolean;
  custom?: CustomSearchTrackerProperties;
  suggestedTerm?: string;
}

export type SearchExecuted = SearchQuery & SearchData;

export interface SearchResultView extends SearchTotals, SearchQuery {
  previousPageNumber?: number;
  linkClicked?: string;
  sortType?: string;
  relatedSkills?: string[];
}

export interface ContentClicked {
  location?: string;
  searchTerm?: string;
  currentPage?: number;
  resultPosition?: number;
  totalContentResults?: number;
  sessionCount?: number;
}

interface SearchTotals {
  contentTotal?: number;
  skillsTotal?: number;
  peopleTotal?: number;
  groupsTotal?: number;
  opportunitiesTotal?: number;
}

interface ResultCounts {
  contentResultsCount?: number;
  groupResultsCount?: number;
  opportunityResultsCount?: number;
  peopleResultsCount?: number;
  skillResultsCount?: number;
  // these represent the same thing - use resultCount if adding new properties
  resultCount?: number;
  totalResults?: number;
}
