import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SubscriptionManagerService } from '@app/shared/services/subscription-manager.service';
import { CropperCoordinates } from '@app/uploader/image-cropper-modal/image-cropper-modal.model';
import { Uploader } from '@app/uploader/uploader';

import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';

export interface FormImageUploaderParams {
  /** When true, the cropper will show after an image is selected. */
  useCropper?: boolean;
  /** Callback called by {@link UserImageFieldComponent} to provide the url of the image to display. This is
   * called initially with no argument to allow a form to populate any default or editing image. After a successful
   * upload, it's called providing the response body returned by the upload adapter, which the published image's url
   * may be extracted from. There's not a standard image upload interface so it's the responsibility of the client
   * code to know the shape of the response object for each use case.
   */
  provideImageUrl?: (formControl?: AbstractControl) => string;
  /** Callback that shoud map a file upload response to the form control value */
  provideControlValue?: (uploadResponse?: unknown) => unknown;
  /** When true, the URL will be interpreted as an image on the current domain. When false, will be transformed to a Blob URL. */
  isHostImageUrl?: boolean;
  uploadAdapter: Uploader<CropperCoordinates>;
  setFocusManually?: boolean;
  /** Override for showing the replace button, in some forms, eg the badge form, we don't want to be able to replace the image  */
  showReplaceButton?: boolean;
}

/** A Formly-compatible wrapper for the standard image uploader component. To create
 * one of these, pass {@link REGISTERED_FIELD_TYPE} to {@link DfFormFieldBuilder.foreignField}.
 */
@Component({
  selector: 'dgx-user-image-field',
  templateUrl: 'user-image-field.component.html',
  styleUrls: ['user-image-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionManagerService],
})
export class UserImageFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public static readonly REGISTERED_FIELD_TYPE = 'dgx-user-image'; // Field type for registration with Formly

  public imageUrl: Observable<string>;

  public get params(): FormImageUploaderParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions?.params ?? {};
  }

  constructor(private subscriptionManager: SubscriptionManagerService) {
    super();
  }

  public onImageUploadSuccess(uploadResponse: unknown) {
    this.updateControl(uploadResponse);
  }

  public onDelete() {
    this.updateControl(); // clear the current value from the control
  }

  public ngOnInit() {
    // Create a single stream of the initial value and value changes that emits a url
    this.imageUrl = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      this.subscriptionManager.takeUntilDestroyed(),
      map((value) => this.getImageUrl(value))
    );
  }

  private updateControl(uploadResponse?: unknown) {
    // Save the entire upload response as the control value. For images with metadata, such as badges,
    // all of it may be required for proper representation.
    this.formControl.setValue(
      this.params.provideControlValue?.(uploadResponse) ?? uploadResponse
    );
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  }

  private getImageUrl(controlValue: unknown): string {
    // Display the published image url after upload
    return (
      this.params.provideImageUrl?.(this.formControl) ??
      (controlValue as string) ?? // if provideImageUrl is not populated we must assume the control value is the url itself
      ''
    );
  }
}
