<div [ngClass]="{ 'grid__col-12': !viewOnly }">
  <dgx-org-faq-empty
    *ngIf="isEmpty()"
    [canEditFaq]="canEditFaq"
  ></dgx-org-faq-empty>
  <div class="org-faq__desc guts-m-b-3" *ngIf="!viewOnly && !isEmpty()">
    {{ 'OrgManage_FAQ_CustomizeFAQ' | translate }}
  </div>

  <div
    class="guts-m-b-2 l_flexbar"
    [ngClass]="{
      'l_flex-justify': !isEmpty(),
      'l_flex-justify-center': isEmpty()
    }"
  >
    <dgx-data-search
      [searchTerm]="searchTerm"
      type="short"
      (doSearch)="handleSearch($event)"
      *ngIf="!isEmpty()"
    ></dgx-data-search>
    <div *ngIf="canEditFaq">
      <a
        class="btn btn-passive m-guts-m-t-1"
        [href]="manageFAQLink"
        data-dgat="org-faq-957"
        *ngIf="viewOnly"
      >
        <df-icon icon="pencil"></df-icon>
        <span class="ib-v-middle guts-p-l-quart">{{
          'Core_Edit' | translate
        }}</span>
      </a>
      <button
        df-button
        dfButtonType="primary"
        (click)="createFaq($event)"
        data-dgat="org-faq-95e"
        [class.is_disabled]="disableAddUpdate"
        [disabled]="disableAddUpdate"
        *ngIf="!viewOnly"
        #createTrigger
      >
        <div class="l_flexbar gap-full-half">
          <df-icon icon="plus"></df-icon>
          <div>{{ 'OrgManage_FAQ_AddFAQ' | translate }}</div>
        </div>
      </button>
    </div>
  </div>
  <dgx-no-search-results
    class="guts-m-t-2"
    *ngIf="searchTerm && faqs?.length === 0"
  ></dgx-no-search-results>

  <div *ngFor="let faq of faqs; trackBy: trackBy" data-dgat="org-faq-2f2">
    <dgx-org-faq-entry
      [faq]="faq"
      [viewOnly]="viewOnly"
      (faqDeleted)="handleFaqDelete($event)"
      (faqEditing)="handleEditState($event)"
      (faqCreated)="handleCreate($event)"
      [disableEdit]="disableAddUpdate"
    ></dgx-org-faq-entry>
  </div>
  <ul *ngIf="loading">
    <li
      *ngFor="let i of [1, 2, 3, 4]"
      class="guts-m-b-2"
      data-dgat="org-faq-4c6"
    >
      <dgx-skeleton type="faq"></dgx-skeleton>
    </li>
  </ul>
  <div *ngIf="faqs?.length > 50" class="center-text guts-m-t-3">
    <div class="h2 guts-m-b-1">
      {{ 'OrgManage_FAQ_MaxFAQsMsg' | translate }}
    </div>
    <div class="par">{{ 'OrgManage_FAQ_TryNarrowingSearch' | translate }}</div>
  </div>
  <dgx-footer-glasses></dgx-footer-glasses>
</div>
