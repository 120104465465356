import { Injectable } from '@angular/core';
import { InputSessionFieldComponent } from '@app/form-fields/wrappers/input-session-field/input-session-field.component';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FieldWrapperBuilder } from './field-builder-factory';

@Injectable({ providedIn: 'root' })
export class InputSessionFieldBuilderService implements FieldWrapperBuilder {
  constructor(private builder: DfFormFieldBuilder) {}

  public build({ inputType, isHidden }): DfFormFieldConfig<DfTemplateOptions> {
    return this.builder
      .foreignField(
        'sessionDetails',
        '',
        InputSessionFieldComponent.REGISTERED_FIELD_TYPE,
        { inputType }
      )
      .hiddenWhen(() => isHidden)
      .build();
  }
}
