<div class="par par--small par--light tag-rating-provider-label">
  <div
    *ngIf="rating.type !== 'Checkpoint'"
    class="ellipsis no-wrap"
    [textContent]="level"
  ></div>
  <div
    *ngIf="rating.type === 'Checkpoint' && checkpointCount > 1"
    class="ellipsis no-wrap"
    [textContent]="checkpointRatings"
  ></div>

  <div
    *ngIf="rating.type === 'Checkpoint' && checkpointCount === 1"
    class="ellipsis no-wrap"
  >
    {{ rating.level }}
  </div>
</div>
