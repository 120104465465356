import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  TrackingLink,
  TrackingLinkResponse,
} from '../tracking-links-table/tracking-link.model';
import { orderBy } from 'lodash-es';
import { ModalService } from '@app/shared/services/modal.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { switchMap, tap } from 'rxjs/operators';
import { TrackingLinkService } from '../services/tracking-link.service';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import {
  PathwayPermissionsModel,
  PathwayDetailsModel,
} from '@app/pathways/pathway-api.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { TrackingLinkFormComponent } from '@app/recommendations/components/tracking-link-form/tracking-link-form.component';
import { PathwayFacade } from '@app/pathways/rsm/pathway.facade';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from '@dg/shared-services';

@Component({
  selector: 'dgx-pathway-tracking-links',
  templateUrl: './pathway-tracking-links.component.html',
})
export class PathwayTrackingLinksComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  public pathway: PathwayDetailsModel;
  public permissions: PathwayPermissionsModel;
  public links: TrackingLink[];
  public ascend = {};
  public currentSortBy = '';
  public linkTag: string;
  public sorting;
  public breadcrumbs: Breadcrumbs;
  public isOrgContent: boolean;

  public i18n = this.translate.instant([
    'TrackableLinkCtrl_Title',
    'TrackableLinkCtrl_CopyLinkAgain',
    'TrackableLinkCtrl_DeleteLinkModalHeader',
    'Core_Delete',
    'deleteModal_Delete',
  ]);

  private authUser: AuthUser;

  constructor(
    private facade: PathwayFacade,
    private modalService: ModalService,
    private trackerService: TrackerService,
    private trackingLinkService: TrackingLinkService,
    private translate: TranslateService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit() {
    this.trackerService.setLocation('Pathway Insights');

    this.pathway = this.facade.snapshot.pathway;
    this.permissions = this.facade.snapshot.permissions;

    this.breadcrumbs = this.getBreadCrumbs();
    this.authUser = this.authService.authUser;
    this.isOrgContent =
      this.authUser?.defaultOrgId === this.pathway.organizationId;

    this.trackingLinkService
      .getTrackingLinks(this.pathway.id)
      .subscribe((links) => {
        this.links = links;
        this.cdr.detectChanges();
      });

    this.trackerService.trackEventData({
      action: 'Pathway Insights Follower Source Link Clicked',
    });
  }

  public onSort = (sortBy: string) => {
    if (this.currentSortBy === sortBy) {
      this.ascend[sortBy] = !this.ascend[sortBy];
    } else {
      this.ascend[sortBy] = true;
    }
    const direction = this.ascend[sortBy] ? 'asc' : 'desc';
    this.currentSortBy = sortBy;
    this.links = orderBy(this.links, sortBy, direction);
  };

  public handleNewLink() {
    return this.addOrEditLink(null).subscribe((data: TrackingLinkResponse) => {
      const direction = this.ascend[this.currentSortBy] ? 'asc' : 'desc';
      this.links.push(data.link as TrackingLink);
      this.links = orderBy(this.links, this.currentSortBy, direction);
    });
  }

  public handleDeleteLink(trackingLink: {
    item: TrackingLink;
    popoverTrigger: ElementRef;
  }) {
    const inputs = {
      canCancel: true,
      bodyClasses: 'h3 center-text',
      bodyText: this.i18n.TrackableLinkCtrl_DeleteLinkModalHeader,
      submitButtonText: this.i18n.deleteModal_Delete,
      item: { pathId: this.pathway.id, linkId: trackingLink.item.id },
    };

    return this.modalService
      .show<any>(SimpleModalComponent, {
        inputs,
        errorOnDismiss: true,
      })
      .pipe(
        switchMap(() => {
          return this.trackingLinkService.deleteTrackingLink(
            trackingLink.item.id,
            this.pathway.id
          );
        }),
        tap(() => {
          this.links = this.links.filter((l) => l.id !== trackingLink.item.id);
        })
      )
      .subscribe({
        error: () => {
          // was closed without deleting
          trackingLink.popoverTrigger.nativeElement.focus();
        },
      });
  }

  public handleEditLink(link: TrackingLink) {
    return this.addOrEditLink(link).subscribe((data: TrackingLinkResponse) => {
      const { id, name } = data.link;

      // update the name in the existing collection
      this.links = this.links.map((l) => {
        if (l.id === id) {
          return { ...l, name };
        }

        return l;
      });
    });
  }

  private addOrEditLink(link?: TrackingLink) {
    const resourceType = 'Pathway';
    const resourceId = this.pathway.id;
    const isEditing = !!link;

    return this.modalService.show(TrackingLinkFormComponent, {
      inputs: {
        resolve: {
          link,
          url: this.pathway.inviteUrl,
          isEditing,
          resourceId,
          resourceType,
          resourceCtrl: `${resourceType.toLowerCase()}s`,
        },
      },
    });
  }

  private getBreadCrumbs = () => {
    return [
      {
        label: this.pathway?.title,
        routerLink: '../../pathway',
      },
      {
        label: this.translate.instant('Core_Insights'),
        routerLink: '../../insights',
      },
      {
        label: this.translate.instant('PathwayEnrollmentSource_TrackableLinks'),
      },
    ];
  };
}
