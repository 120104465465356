<df-input-decorator
  [prependageType]="to.prependage?.addOnType"
  [appendageType]="to.appendage?.addOnType"
  [readonly]="to.readonly"
>
  <div
    class="decoration"
    [ngClass]="to.prependage.cssClass | dfProvideString: formControl"
    df-prepend
    *ngIf="to.prependage"
  >
    <ng-container
      *ngTemplateOutlet="addOn; context: { $implicit: to.prependage }"
    ></ng-container>
  </div>

  <input
    class="df-text-input"
    [id]="id"
    [name]="to.name"
    [type]="to.type"
    [required]="to.required"
    [formControl]="control"
    [formlyAttributes]="field"
    [autocomplete]="to.autocomplete || 'off'"
    [attr.aria-label]="to.ariaLabel || to.label"
    [attr.aria-labelledby]="labelIds"
    [attr.data-dgat]="to.dgatId"
    [attr.maxlength]="to.maxLength ? to.maxLength : undefined"
    [readonly]="to.readonly"
    [attr.contenteditable]="!to.readonly"
    [min]="to.min"
    [max]="to.max"
    [step]="to.step"
    [dfAutofocus]="to.shouldAutofocus"
    [placeholder]="to.placeholder"
    [attr.aria-describedby]="
      (to.ariaDescribedbyIds ? to.ariaDescribedbyIds : '') +
      (showErrors ? ' ' + id + '-error' : '')
    "
  />
  <div
    class="decoration"
    [ngClass]="to.appendage.cssClass | dfProvideString: formControl"
    df-append
    *ngIf="to.appendage"
  >
    <!-- {{ to.content }} -->
    <ng-container
      *ngTemplateOutlet="addOn; context: { $implicit: to.appendage }"
    ></ng-container>
  </div>
</df-input-decorator>

<ng-template #addOn let-context>
  <ng-container *ngIf="context.addOnType === 'button'; else rawContent">
    <!--For the button case, we render the text or icon content inside the button -->
    <button
      df-append
      df-button
      dfButtonType="primary"
      [type]="context.options?.buttonType"
      (click)="
        context.options?.clickHandler && context.options.clickHandler($event)
      "
    >
      <df-spinner-button [isSpinning]="context.options?.working | async">
        <ng-container
          *ngTemplateOutlet="addOnContent; context: context"
        ></ng-container>
      </df-spinner-button>
    </button>
  </ng-container>
  <!--Else, render text or icon content directly-->
  <ng-template #rawContent
    ><ng-content
      *ngTemplateOutlet="addOnContent; context: context"
    ></ng-content>
  </ng-template>
</ng-template>

<!--text/icon content template-->
<ng-template
  #addOnContent
  let-content="content"
  let-contentType="contentType"
  let-cssClass="cssClass"
>
  <span
    [id]="id + '-prependage'"
    *ngIf="contentType === 'text'; else addIcon"
    dfSafeContent="content"
    dfSafeContent
  >
    {{ content | dfProvideString: formControl }}
  </span>
  <ng-template #addIcon>
    <df-icon [icon]="content | dfProvideString: formControl"></df-icon>
  </ng-template>
</ng-template>
