<button
  [dgxPopoverTrigger]="ratingChangePopover"
  data-dgat="tag-rating-modal-change-rating-popover-575"
  type="button"
  class="btn btn-sm guts-m-r-1 guts-p-h-1 btn-passive"
  [disabled]="isDisabled"
  [class.is_disabled]="isDisabled"
>
  {{ defaultText }}
  <df-icon
    icon="chevron-down"
    size="small"
    class="guts-p-l-quart guts-m-l-half"
  ></df-icon>
</button>
<dgx-popover
  #ratingChangePopover
  [isOpen]="isPopoverOpen"
  (isOpenChange)="popoverToggled()"
  [popoverArrowHidden]="true"
  placement="bottom-left"
  (blur)="popoverToggled()"
>
  <fieldset class="guts-p-full-1 tag-rating-change-popover-content">
    <div
      *ngFor="let level of orgLevels"
      class="guts-p-v-half tag-rating-selection-box"
    >
      <label class="l_flex m-l_flex">
        <div class="custom-skill-level-selector-container">
          <input
            class="l_w10 guts-m-h-half"
            type="radio"
            name="ratingLevel"
            [value]="level.value"
            [(ngModel)]="currentLevel"
            (change)="onRating(level.value)"
          />
          <dgx-tag-rating-donut
            class="ib guts-m-full-1"
            size="sm"
            [level]="level.value.toString()"
            [showDefaultPlus]="false"
            [isOwner]="true"
          ></dgx-tag-rating-donut>
          <div class="par par--small l_w60 guts-p-full-half">
            <span class="par--small font-bold" [innerHTML]="level.name"> </span>
            <p
              class="par--light clamp"
              [title]="level.description"
              [dgxLineClampText]="level.description"
              [clampLinesMax]="3"
            ></p>
          </div>
        </div>
      </label>
    </div>
  </fieldset>
</dgx-popover>
