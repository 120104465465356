import { Component, ElementRef, Input } from '@angular/core';

import { PathwayNavBaseDirective } from '@app/pathways/directives/pathway-nav-base.directive';
import { Pathway, PathwaySection } from '@app/pathways/rsm/pathway-api.model';
import { PathwayAuthoring } from '@app/pathways/rsm/pathway.authoring';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { StripMarkupPipe } from '@app/shared/pipes/strip-markup.pipe';
import { A11yService } from '@app/shared/services/a11y.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import {
  PathwayLevel,
  hasAtLeastOneAuthoringSection,
  hasAuthoringSections,
  hasFauxOrMockSection,
  hasFauxSubsection,
  shouldShowAuthoringNavSubsections,
  shouldShowAuthoringNavTitle,
} from '@dg/pathways-rsm';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-author-nav',
  templateUrl: './pathway-author-nav.component.html',
  styleUrls: [
    '../pathway-section-nav/pathway-section-nav.component.scss',
    './pathway-author-nav.component.scss',
  ],
})
export class PathwayAuthorNavComponent extends PathwayNavBaseDirective {
  // ***************************
  // Properties
  // ***************************

  @Input() public hasFauxSection = false;
  @Input() public fauxSubsections = [];

  public readonly i18n = this.translate.instant([
    ...this.baseI18n,
    'Core_MoreOptions',
    'Pathways_A11ySectionsReordered',
    'Pathways_AddSection',
    'Pathways_AddSubsection',
    'Pathways_ConfirmRemoveSection',
    'Pathways_CreateSection',
    'Pathways_DeleteSection',
    'Pathways_DeletedSection',
    'Pathways_LessonCountSingular',
    'Pathways_ReorderSections',
  ]);
  public menuConfig: MenuViewModel[];

  // ***************************
  // Util Wrappers
  // ***************************

  public shouldShowNavTitle = shouldShowAuthoringNavTitle;
  public hasFauxSubsection = hasFauxSubsection;
  public shouldShowNavSubsections = shouldShowAuthoringNavSubsections;

  constructor(
    private a11yService: A11yService,
    private focusStackService: FocusStackService,
    private authoring: PathwayAuthoring,
    stripMarkupPipe: StripMarkupPipe,
    translate: TranslateService
  ) {
    super(stripMarkupPipe, translate);
  }

  // ***************************
  // PUBLIC -------------------
  // Methods
  // ***************************

  /**
   * Get the meatball menu.
   *
   * @param section - Current section.
   */
  public createMenuConfig(section: PathwaySection): MenuViewModel[] {
    return [
      {
        title: this.i18n?.Pathways_AddSubsection,
        defaultAction: () => this.createSubsection(section),
      },
      {
        title: this.i18n?.Pathways_ReorderSections,
        defaultAction: (_, popoverTrigger) =>
          this.reorderSection(popoverTrigger),
        isHidden: () => !hasAuthoringSections(this.pathway), // Hide if there's fewer than 2 sections.
        preventRefocus: true,
      },
      {
        title: this.i18n?.Pathways_DeleteSection,
        defaultAction: (_, popoverTrigger) =>
          this.deleteSection(section, popoverTrigger),
        isSeparated: true,
        isDestructive: true,
        preventRefocus: true,
      },
    ];
  }

  /**
   * Whether to use the Add or Create styling on our add/create section button.
   *
   * @param pathway
   */
  public hasAtLeastOneNonFauxSection(pathway: Pathway): boolean {
    return (
      !hasFauxOrMockSection(pathway, this.hasFauxSection) &&
      hasAtLeastOneAuthoringSection(pathway)
    );
  }

  // ***************************
  // API Calls
  // ***************************

  /**
   * Create a section.
   */
  public async createSection(): Promise<boolean> {
    if (this.hasFauxSection) {
      return await this.authoring.toggleHasFauxSection(
        this.pathway.id,
        this.pathway.levels[0].node,
        false
      );
    }

    return await this.authoring.createNode(
      PathwayLevel.SECTION,
      this.pathway.id
    );
  }

  /**
   * Create a subsection.
   *
   * @param section - The section to add the subsection to.
   */
  public async createSubsection(section: PathwaySection): Promise<boolean> {
    if (this.hasFauxSubsection(section, this.fauxSubsections)) {
      return await this.authoring.toggleHasFauxSubsection(
        this.pathway.id,
        section.lessons[0].node,
        false
      );
    }

    return await this.authoring.createNode(
      PathwayLevel.SUBSECTION,
      this.pathway.id,
      section.node
    );
  }

  /**
   * Delete a section.
   *
   * @param section - The section to delete.
   * @param popoverTrigger - Reference to the meatball menu.
   */
  public async deleteSection(
    section: PathwaySection,
    popoverTrigger: ElementRef
  ) {
    this.focusStackService.push(popoverTrigger.nativeElement);
    const success = await this.authoring.deleteNode(
      PathwayLevel.SECTION,
      this.pathway.id,
      section.node
    );
    if (!success) {
      // was closed without deleting - return focus to meatball menu.
      this.focusStackService.pop();
    }
  }

  /**
   * Reorder sections.
   *
   * @param popoverTrigger - Reference to the meatball menu.
   */
  public async reorderSection(popoverTrigger: ElementRef) {
    this.focusStackService.push(popoverTrigger.nativeElement);
    const success = await this.authoring.showReorderEditor(
      PathwayLevel.SECTION
    );

    if (!success) {
      // was closed without reordering - return focus to meatball menu.
      this.focusStackService.pop();
      return;
    }
    this.a11yService.announcePolite(this.i18n.Pathways_A11ySectionsReordered);
  }
}
