<ol
  class="guts-p-full-0 grid__col-lg-12 pathway-section__list"
  *ngIf="!showSkeleton; else skeleton"
>
  <ng-container
    *ngFor="
      let section of sections;
      trackBy: trackById;
      let last = last;
      let sectionIndex = index
    "
    data-dgat="pathway-section-85d"
  >
    <li *ngIf="shouldShowSection(section)" data-dgat="pathway-334">
      <div
        class="grid__col-lg-8 guts-p-t-0"
        id="section-{{ section.number }}"
        tabindex="-1"
        [attr.aria-label]="
          getTitleForAriaLabel(sectionIndex + 1, section.title)
        "
      >
        <div class="pathway-section__container">
          <h2 class="pathway-section__title" *ngIf="section.title">
            <!-- hidden number is for screen readers. Actual number added by CSS to help support RTL languages -->
            <span class="a11y-hide">{{ sectionIndex + 1 }}</span>
            <span [innerHTML]="section.title | stripMarkup"></span>
          </h2>
          <div
            class="pathway-section guts-p-t-half"
            *ngIf="section.description"
          >
            <dgx-text-expander
              [content]="section.description | markdownToHtml: markdownOptions"
              [maxCollapsedLines]="2"
              [useReadMore]="true"
              [isHtml]="true"
              class="pathway-section__meta-text markdown"
            ></dgx-text-expander>
          </div>
        </div>

        <dgx-pathway-section-meta-data
          [hasFauxSubsection]="hasFauxSubsection(section.lessons)"
          [hideDuration]="hideDuration"
          [section]="section"
          *ngIf="hasAtLeastOneStep(section)"
        ></dgx-pathway-section-meta-data>
        <dgx-pathway-subsections
          [subsections]="section.lessons"
        ></dgx-pathway-subsections>
      </div>
      <hr
        *ngIf="!last"
        class="pathway-section__line guts-m-t-3 guts-m-b-2 guts-m-l-1-half"
      />
    </li>
  </ng-container>
</ol>
<ng-template #skeleton>
  <div class="guts-p-full-0 grid__col-lg-8">
    <div
      *ngFor="let skeleton of skeletons"
      aria-hidden="true"
      data-dgat="pathway-section-ca7"
    >
      <dgx-skeleton type="input-card"></dgx-skeleton>
    </div>
  </div>
</ng-template>
