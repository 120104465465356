<footer class="tile__footer l_flexbar l_flex-justify full-width">
  <div class="l_flex l_flex-grow l_flex-middle">
    <ng-container *ngIf="userLoggedIn">
      <!--Add/Added button-->

      <!--
        Linter Error
        [isActive]="tag.isFollowing"
        Can't bind to 'isActive' since it isn't a known property of 'button'.
      -->
      <button
        *ngIf="!hideFollowing"
        df-button
        df-button-small
        [dfButtonType]="tag.isFollowing ? 'primary' : 'passive'"
        (click)="toggleFollow($event)"
        [ngbTooltip]="addActionTooltip"
        [attr.aria-label]="addActionA11y"
        class="tag-tile-footer__action-button guts-m-r-half"
        data-dgat="tagTile-d6e"
      >
        <df-icon
          [icon]="tag.isFollowing ? 'checkmark' : 'plus'"
          size="medium"
        ></df-icon>
        {{ addActionText }}
      </button>

      <!--Focus star-->
      <ng-container
        *dgxFeatureToggle="
          ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
          hidden: true
        "
      >
        <ng-container *ngIf="!isSkillStandard">
          <button
            *ngIf="isOwner || targetAuthoring; else nonOwner"
            df-button
            df-button-small
            dfButtonType="passive"
            (click)="toggleFocused($event)"
            [ngbTooltip]="focusActionTooltip"
            [attr.aria-label]="focusActionA11y"
            class="tag-tile-footer__action-button guts-m-r-half focused-button"
            data-dgat="tagTile-077"
          >
            <df-icon
              icon="star"
              size="small"
              [class.color-warning]="
                tag.requestingUserIsFocused || tag.isFocused
              "
            ></df-icon>
          </button>
          <ng-template #nonOwner>
            <df-icon
              class="guts-m-r-half guts-p-h-quart focused-button color-warning"
              *ngIf="tag.isFocusSkillForOwner"
              icon="star"
              size="small"
              [ngbTooltip]="i18n.Core_FocusSkill"
              [attr.aria-label]="i18n.Core_FocusSkill"
            ></df-icon>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container
        *dgxFeatureToggle="
          ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
          hidden: true
        "
      >
        <button
          *ngIf="
            showRecommendAction && !isSkillInventoryClient && !isSkillStandard
          "
          df-button
          df-button-small
          dfButtonType="passive"
          (click)="recommend($event)"
          [ngbTooltip]="recommendActionTooltip"
          [attr.aria-label]="recommendActionA11y"
          class="tag-tile-footer__action-button"
          data-dgat="pathwayTile-9ba"
        >
          <df-icon
            icon="arrow-forward"
            size="small"
            class="color-ebony-a61"
          ></df-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>

  <!--Action menu-->

  <dgx-menu
    *dgxFeatureToggle="
      ['isSkillInventoryClient', 'isSkillAnalyticsClient'];
      hidden: true
    "
    [appendToBody]="true"
    [menuConfig]="menuConfig"
    placement="bottom-right"
    [placementAdjustLeftRem]="1"
    (click)="$event.stopPropagation()"
  >
    <button
      type="button"
      df-button
      df-button-square
      dfButtonType="clear"
      class="tag-tile-footer__menu"
      data-dgat="tag-card-6ca"
      [attr.aria-label]="i18n.Core_MoreOptions"
    >
      <df-icon icon="dots" class="color-ebony-a61"></df-icon>
    </button>
  </dgx-menu>
</footer>
