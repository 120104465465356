import { Injectable, Injector } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

export type TranslationTable = Record<string, string>;

@Injectable()
export class DgTranslateDynamicLoader implements TranslateLoader {
  /**
   * Alternate languages/locales to check for a given language key. This can be used to set the
   * default specific locale for a language when the user may have the neutral, or non-country
   * specific language set.
   */
  public static alternateLocales = {
    en: ['en-US'],
  };

  constructor(private http: NgxHttpClient, private auth: AuthService) {}

  /**
   * Gets translation bundles for each unique language key used in `translate.use(...)`
   * and `translate.setDefaultLang(...)`
   */
  public getTranslation(lang: string): Observable<TranslationTable> {
    const translations$ = [this.getTranslationFromApi(lang)];

    // change to channel check
    if (this.auth.authUser?.defaultOrgInfo?.type === 'Channel') {
      translations$.push(this.getChannelTranslationFromApi(lang));
    }

    return forkJoin(translations$).pipe(
      map((results) => {
        let translations: TranslationTable = {};
        results.forEach((result) => {
          translations = {
            ...translations,
            ...result,
          };
        });
        return translations;
      })
    );
  }

  /**
   * Gets the translations from the server
   */
  private getTranslationFromApi(lang: string): Observable<any> {
    return this.http.get('/translations/translate', {
      params: {
        culture: lang,
        format: 'Json',
      },
    });
  }

  private getChannelTranslationFromApi(lang: string): Observable<any> {
    return this.http.get('/translations/translate', {
      params: {
        culture: lang,
        format: 'Json',
        bundle: 'channel',
      },
    });
  }
}

export function createTranslateLoader(http: any, authService: AuthService) {
  return new DgTranslateDynamicLoader(http, authService);
}
