import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Opportunity New Author notification */
@Component({
  selector: 'dgx-opportunity-applicant-selected',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityApplicantSelectedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml(
      'OpportunityApplicantSelected_NotificationTitleFormat',
      {
        opportunityAuthor: this.userFullNameTemplate,
      }
    );
  }
}
