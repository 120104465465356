import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { NotificationItemService } from './notification-item.service';
import { WindowToken } from '@app/shared/window.token';

/** Represents an Automations notification */
@Component({
  selector: 'dgx-automations-push',
  template:
    '<span (click)="handleClick($event)" [innerHTML]="safeContent"></span>',
  styleUrls: ['./automations-push.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationsPushComponent extends NotificationItemComponentBase {
  constructor(
    cdr: ChangeDetectorRef,
    itemService: NotificationItemService,
    private readonly markdownService: MarkdownService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super(cdr, itemService);
  }
  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    const notificationLine = this.itemService.translate.instant(
      'Notification_AutomationNotification'
    );
    const subject = this.notification.parameters.subject;
    const details = this.markdownService.markdownToHtml(
      this.notification.parameters.details,
      {
        openLinksInNewWindow: true,
      }
    );
    return this.itemService.sanitizer.bypassSecurityTrustHtml(
      `<p class="par par--small guts-p-b-half">${notificationLine}</p><h1 class="h3 clamp">${subject}</h1><div class="automations-push">${details}</div>`
    );
  }

  public handleClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (e.target instanceof HTMLAnchorElement) {
      const anchor = e.target as HTMLAnchorElement;
      this.windowRef.open(anchor.href, '_blank');
    }
  }
}
