import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DfAccessibilityService {
  constructor() {}

  /**
   * Gives keyboard focus to the next valid element that is part of the tab
   * ring within a given container
   * @param el DOM element container for searching within
   * @param includeFocusable (optional) include items that are not tabbable but are focusable (tabindex = -1)
   */
  public focusNextTabbable(el: HTMLElement, includeFocusable = false) {
    const tabbableEl = this.getNextTabbable(el, includeFocusable);
    console.debug('fresco accessibility.service focusNextTabbable', tabbableEl);
    tabbableEl?.focus();
  }

  /**
   * Gives keyboard focus to the next valid element that can recieve focus
   * within a given container
   * @param el DOM element container for searching within
   */
  public focusNextFocusable(el: HTMLElement) {
    return this.focusNextTabbable(el, true);
  }

  /**
   * Returns the next valid element that is part of the tab ring
   * within a given container
   * @param el DOM element container for searching within
   * @param includeFocusable (optional) include items that are not tabbable but are focusable (tabindex = -1)
   */
  public getNextTabbable(el: HTMLElement, includeFocusable = false) {
    const innards = el.getElementsByTagName('*');
    for (let i = 0; i < innards.length; i++) {
      const child: any = innards.item(i);
      if (includeFocusable) {
        if (this.isFocusable(child)) {
          return child;
        }
      } else {
        if (this.isTabbable(child)) {
          return child;
        }
      }
    }
  }

  /**
   * Returns the next valid element that can recieve focus within a given container
   * @param el DOM element container for searching within
   */
  public getNextFocusable(el: HTMLElement) {
    return this.getNextTabbable(el, true);
  }

  private isFocusable(element: any) {
    // check to see if element can recieve focus by keyboard or programatically
    const nodeName = element.nodeName.toLowerCase();
    const tabIndex = element.getAttribute('tabindex');
    const isTabIndexNaN = tabIndex === null || isNaN(tabIndex);
    const isFocusableType = /^(input|select|textarea|button|object)$/.test(
      nodeName
    );
    const isDisabled = element.disabled;
    if (isFocusableType) {
      return !isDisabled;
    } else if (nodeName === 'a') {
      return !!element.href || !isTabIndexNaN;
    } else {
      return !isTabIndexNaN;
    }
  }

  private isTabbable(element: any) {
    // extends isFocusable a bit and is more strict
    // check to see if element can recieve focus by keyboard only
    const tabIndex = element.getAttribute('tabindex');
    const isTabIndexNaN = tabIndex === null || isNaN(tabIndex);
    const hasValidTabIndex = !isTabIndexNaN && tabIndex >= 0;
    const isFocusable = this.isFocusable(element);
    return hasValidTabIndex && isFocusable;
  }
}
