import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { EpisodeFormModel } from '../episode-forms.model';
import { EpisodeApiEntity } from '../repository/episode.entity.model';
import { DurationConverterService } from '@app/shared/services/duration-converter.service';
import { ImageUrlService } from '../../services/image-url.service';

@Injectable({ providedIn: 'root' })
export class ContentCatalogEpisodeMapper
  implements Mapper<any, EpisodeApiEntity>
{
  constructor(
    private datePipe: DatePipe,
    private durationConverterService: DurationConverterService,
    private imageUrlService: ImageUrlService
  ) {}

  public toApiParameters(source: EpisodeFormModel): EpisodeApiEntity {
    const imageUrlData = source.imageUrl as any;
    const imageUrl = this.imageUrlService.getImageUrl(imageUrlData);

    //TODO: Cleanup
    // Currently groupIds is only getting set if the adv section of the form gets opened
    // Make sure it's set if it's not already set and something is in groups in order to not lose data.
    // group/groupId's needs a refactor so that we don't have to do this juggling of data
    // PD-79285 [Bug]
    if (!source.groupIds && source.groups) {
      source.groups = source.groupIds;
    }

    let episodeEntity: EpisodeApiEntity = {
      // authored: source.initializedForm.audioAuthored,
      duration: this.durationConverterService.fromMinutesToSeconds(
        source.initializedForm.duration
      ),
      durationHours:
        source.initializedForm.durationHours != 0
          ? source.initializedForm.durationHours
          : null,
      durationMinutes:
        source.initializedForm.durationMinutes != 0
          ? source.initializedForm.durationMinutes
          : null,
      externalId: source.externalId,
      imageUrl,
      hostedContentDetails: source.hostedContentDetails,
      inputType: 'Episode',
      inputId: source.inputId,
      language: source.language,
      orgContentMetadata: {
        groupIds: source.groupIds ?? [],
        hideFromCatalog:
          !source.initializedForm.addToCatalogSection.addToCatalog,
      },
      organizationId: source.organizationId,
      resourceImageId: imageUrlData?.resourceImageId,
      summary: source.initializedForm.description,
      tags: source.tags?.length > 0 ? source.tags : source.initializedForm.tags,
      owner: source.owner,
      publishDate: source.publishDate,
    };

    if (source.hostedContentDetails) {
      episodeEntity.title = source.initializedForm.title;
    }

    if (source.podcastType === 'url') {
      episodeEntity.title = source.initializedForm.title;
      episodeEntity.url = source.mediaUrl || source.nameOrUrl;
    }

    if (source.podcastType === 'selected') {
      episodeEntity.dateCompleted = this.datePipe.transform(
        source.initializedForm.dateCompleted,
        'yyyy-MM-dd'
      );
      episodeEntity.title = source.selectedEpisode.title;
      episodeEntity.url = source.selectedEpisode.url;
      episodeEntity.summary = source.selectedEpisode.summary;
      episodeEntity.author = source.selectedEpisode.author;
      episodeEntity.imageUrl = source.selectedEpisode.imageUrl;
      episodeEntity.feedUrl = source.selectedEpisode.feedUrl;
      episodeEntity.feedName = source.selectedEpisode.feedName;
      episodeEntity.publishDate = source.selectedEpisode.publishDate;
      episodeEntity.externalId = source.selectedEpisode.id;
      episodeEntity.language = source.selectedEpisode.language;
      episodeEntity.contentUrl = source.selectedEpisode.contentUrl;
    }

    return episodeEntity;
  }
  public toViewModel(source: EpisodeApiEntity): Partial<EpisodeFormModel> {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    const target: Partial<EpisodeFormModel> = {
      nameOrUrl: source.url,
      mediaUrl: source.url,
      fileManaged: source.fileManaged,
      hostedContentDetails: source.hostedContentDetails,
      isFormInitialized: true,
      podcastType: 'url',
      inputId: source.inputId,
      owner: source.owner,
      language: source.language,
      publishDate: source.publishDate,
      externalId: source.externalId,
      imageUrl: source.imageUrl,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      groups,
      groupIds: groups,
      tags: source.tags,
      initializedForm: {
        addToCatalogSection: {
          addToCatalog: source.orgContentMetadata
            ? !source.orgContentMetadata.hideFromCatalog
            : true,
        },
        title: source.title,
        audioAuthored: false,
        comment: '',
        description: source.summary,
        dateCompleted: null,
        duration: this.durationConverterService.fromSecondsToMinutes(
          source.duration
        ),
        durationHours: source.durationHours,
        durationMinutes: source.durationMinutes,
      },
      createdByName: source.createdByName ?? (source.createdBy as string),
      hasBrokenUrl: source.hasBrokenUrl,
    };
    return target;
  }
}
