import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AuthService,
  LOGIN_ROUTE_PATH,
  UNAUTHORIZED_ROUTE_PATH,
} from '../services/auth.service';

// Route guard helper functions

export function ensureLoggedIn(
  authService: AuthService,
  router: Router,
  route: ActivatedRouteSnapshot
) {
  return authService.isLoggedIn || getLoginUrl(router, route);
}

/** Verifies the user is logged in and that the specified condition is true; or if not logged in,
 * returns the login page URL to be redirected to.
 */
export function ensureLoggedInAnd(
  check: (authService: AuthService) => boolean
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  return (
    authService: AuthService,
    router: Router,
    route: ActivatedRouteSnapshot
  ) => {
    if (authService.isLoggedIn) {
      if (check(authService)) {
        return true;
      }
      return router.parseUrl(UNAUTHORIZED_ROUTE_PATH);
    }
    return getLoginUrl(router, route);
  };
}

function getLoginUrl(router: Router, returnToRoute?: ActivatedRouteSnapshot) {
  const returnToUrl = router.createUrlTree(
    returnToRoute.url.map((s) => s.toString())
  );
  return router.createUrlTree([LOGIN_ROUTE_PATH], {
    queryParams: {
      returnTo: returnToUrl.toString(),
    },
  });
}
