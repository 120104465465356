<dgx-modal
  [bodyStyle]="{ 'max-height': '300px' }"
  [canCancel]="false"
  [canCloseHeader]="false"
  [item]="skills"
  [submitButtonText]="'Core_Close' | translate"
  bodyClasses="guts-p-h-0 guts-p-t-0 body-max-height"
>
  <!-- Modal header -->
  <ng-container class="modal-header">
    {{ 'Opportunities_UserRateSkillsModal_Header' | translate }}
  </ng-container>
  <!-- Modal body -->
  <ng-container class="modal-body">
    <!-- Skills view -->
    <div class="guts-p-l-1 guts-p-r-1 user-rate-skills-modal__body">
      <dgx-skills-view
        [addTargetModalHeight]="300"
        [addRatingButtonText]="
          'Opportunities_Skills_AddSkillRating' | translate
        "
        [isOwnSkills]="true"
        [skills]="skills"
        (ratingViewed)="onRatingViewed($event)"
        (updateSkills)="onUpdateSkills($event)"
      ></dgx-skills-view>
    </div>
  </ng-container>
</dgx-modal>
