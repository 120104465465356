import { Inject, Injectable } from '@angular/core';
import { Assessment } from '@app/inputs/inputs-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowToken } from '@app/shared/window.token';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface SyncAssessmentResult {
  syncAssessmentExists: boolean;
  assessment: Assessment;
}

export const enum CredSparkOptions {
  Manage = 'manage',
  Sync = 'sync',
}

/**
 * Provides web API and helper methods for interacting with CredSpark
 */
@Injectable({ providedIn: 'root' })
export class CredSparkService extends ApiServiceBase {
  private static readonly CREDSPARK_INPUT_TYPE = 'CredSpark';
  private readonly i18n = this.translate.instant([
    'CredSparkSvc_SyncError',
    'CredSparkSvc_AssessmentError',
    'OrgInternalContentCtrl_AssessmentUnavailableError',
    'dgOrgInternalContentForm_CredSparkDashboard',
    'dgOrgInternalContentForm_ReSyncCredSpark',
  ]);

  constructor(
    private translate: TranslateService,
    private tracker: TrackerService,
    @Inject(WindowToken) private windowRef: Window,
    protected http: NgxHttpClient
  ) {
    super(http, translate.instant('CredSparkSvc_SyncError'));
  }

  public getIsCredSpark(input: Partial<Assessment>): boolean {
    return (
      input.userProviderName?.toLowerCase() ===
      CredSparkService.CREDSPARK_INPUT_TYPE.toLowerCase()
    );
  }

  public launchCredSpark(isCreating: boolean, isChannelOrg: boolean): void {
    const launchUrl = isChannelOrg
      ? '/orgs/launchchannelcredspark'
      : '/orgs/launchcredspark';

    this.windowRef.open(launchUrl, '_blank');
    if (isCreating) {
      this.track('CSAssessment');
    } else {
      this.track('Catalog CredSpark Dashboard Viewed');
    }
  }

  public launchAssessmentEdit(
    csAssessmentId: number,
    isChannelOrg: boolean
  ): void {
    const launchUrl = isChannelOrg
      ? '/orgs/credsparkchannelassessment/' + csAssessmentId
      : '/orgs/credsparkassessment/' + csAssessmentId;

    this.windowRef.open(launchUrl, '_blank');
    this.track('CSAssessment');
  }

  public resync(orgId: number): Observable<void> {
    return this.post(
      '/credspark/resync',
      { orgId },
      this.i18n.CredSparkSvc_SyncError
    );
  }

  /**
   * NOTE: unpacks the HttpErrorResponse to set the appropriate error message for the DgError
   *
   * The endpoint `/api/credspark/resyncassessment` has a polymorphic response, returning an `Assessment` on success,
   * or a wrapped SyncAssessmentResult on error
   */
  public resyncAssessment(
    orgId: number,
    inputId: number
  ): Observable<Assessment> {
    return this.get<Assessment>('/credspark/resyncassessment', {
      orgId,
      inputId,
    }).pipe(
      catchError((error: any) => {
        let data: SyncAssessmentResult;

        // Unpack a nested HttpErrorResponse within the DgError
        if (error.name === 'DgError') {
          const innerError = error.innerError;
          if (innerError.name === 'HttpErrorResponse') {
            data = innerError.error as SyncAssessmentResult;
          }
        }

        // NOTE: could also check `errorResponse.status === 404`
        const syncAssessmentExists = data?.syncAssessmentExists === false;

        error.message = syncAssessmentExists
          ? this.i18n.OrgInternalContentCtrl_AssessmentUnavailableError
          : this.i18n.CredSparkSvc_SyncError;

        return throwError(error);
      })
    );
  }

  public getCredSparkAssessment(externalId: string): Observable<Assessment> {
    return this.get<Assessment>(
      '/credspark/getcredsparkassessment',
      {
        externalId,
      },
      this.i18n.CredSparkSvc_AssessmentError
    );
  }

  public getCredSparkOptions() {
    return [
      {
        id: CredSparkOptions.Manage,
        name: this.i18n.dgOrgInternalContentForm_CredSparkDashboard,
      },
      {
        id: CredSparkOptions.Sync,
        name: this.i18n.dgOrgInternalContentForm_ReSyncCredSpark,
      },
    ];
  }

  private track(action: string): void {
    this.tracker.trackEventData({
      action,
    });
  }
}
