<div
  [ngClass]="{
    'input-success': true,
    'guts-p-t-1': addSpacing
  }"
>
  <div
    *ngIf="!isNewbUser; else newb"
    [ngClass]="{
      'points-received': creationFeedback?.result.masteryPoints || errorMessage,
      'input-success-kickstart-complete': isSuccessAnimationComplete,
      'input-success-kickstart-complete--show-results': shouldShowResults,
      'input-success-kickstart-complete--show-reward': shouldShowReward
    }"
  >
    <div
      [ngClass]="{
        'input-success-button-wrap': true,
        'guts-m-t-1-half': addSpacing
      }"
    >
      <div class="input-success-dot"></div>
      <div [ngClass]="{ 'input-success-buttons-container': showCancelButton }">
        <button
          *ngIf="showCancelButton"
          df-button
          dfButtonType="clear"
          df-button-small
          (click)="dismiss()"
          data-dgat="global-add-results-302"
        >
          {{ cancelButtonText }}
        </button>
        <button
          df-button
          dfButtonType="primary"
          type="submit"
          class="input-success-button zeta full-width"
          [ngClass]="{ 'full-width': !showCancelButton }"
          data-dgat="globalAddSuccess-f73"
          [attr.aria-label]="
            isSubmitting ? ('Core_Loading' | translate) : buttonText
          "
        >
          <df-spinner-button [isSpinning]="isSubmitting">
            {{ buttonText }}
          </df-spinner-button>
        </button>
      </div>
    </div>
    <div class="input-success-flipper">
      <div class="input-success-check">
        <img [src]="checkImage" class="input-success-icon" />
      </div>
      <div
        *ngIf="creationFeedback?.result.masteryPoints | dgxPoints; let points"
        class="input-success-points"
        [ngClass]="{ 'server-error': errorMessage }"
      >
        <div class="vertical-center">
          <div
            [ngClass]="{
              'giga font-medium': points.length == 1,
              'mega font-medium': points.length > 1 && points.length < 4,
              'beta font-regular guts-p-t-quart': points.length >= 4
            }"
          >
            {{ points }}
          </div>
          <div class="color-success up-me epsilon font-medium">
            {{ 'dgGlobalAddSuccess_Points' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="input-success-message center-text">
      <div class="guts-m-t-2 input-success-message__error" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="guts-m-t-2" *ngIf="creationFeedback?.statistics; let stats">
        <h3 class="delta color-ebony-a61">
          {{ 'dgGlobalAddSuccess_CompletedToday' | translate }}
        </h3>
        <div class="guts-m-t-half color-ebony-a61">
          <div
            *ngIf="stats.todaysCounts?.length > 3"
            class="input-success-stat mega"
          >
            <a
              [href]="collectionUrl"
              (click)="onCollectionLinkClick($event)"
              class="link"
              id="dgat-globalAddSuccess-linkCollection"
              data-dgat="globalAddSuccess-6f3"
              ><div class="ib">
                <!-- div wrapper prevents weird underlining due to variable font sizes -->
                <span class="ib-v-middle">{{ stats.todaysCount }}</span>
                <span class="delta ib-v-middle up-me"
                  >{{ 'dgGlobalAddSuccess_LearningItems' | translate }}
                </span>
              </div></a
            >
          </div>
          <ul *ngIf="stats.todaysCounts?.length <= 3">
            <li
              *ngFor="let inputCount of todaysCountsOrdered"
              class="input-success-stat mega"
              data-dgat="globalAddSuccess-edd"
            >
              <a
                class="link"
                href="{{ collectionUrl }}?type={{ inputCount.inputType }}"
                (click)="onCollectionLinkClick($event)"
                id="dgat-globalAddSuccess-linkCollection2"
                data-dgat="globalAddSuccess-f13"
                ><div class="ib">
                  <!-- div wrapper prevents weird underlining due to variable font sizes -->
                  <span class="ib-v-middle">{{ inputCount.count }}</span>
                  <span
                    class="delta ib-v-middle up-me"
                    *ngIf="inputCount.Count === 1"
                    >{{ inputCount.inputType | displayType }}</span
                  >
                  <span
                    class="delta ib-v-middle up-me"
                    *ngIf="inputCount.Count > 1"
                    >{{ inputCount.inputType | displayType: true }}</span
                  >
                </div></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="guts-m-t-2">
        <a
          class="delta link underline"
          [href]="collectionUrl"
          (click)="onCollectionLinkClick($event)"
          id="dgat-globalAddSuccess-viewCollection"
          data-dgat="globalAddSuccess-09e"
        >
          {{ 'dgGlobalAddSuccess_ViewYourCollection' | translate }}</a
        >
      </div>
    </div>
    <div
      class="input-success-reward center-text"
      *ngIf="creationFeedback?.reward"
    >
      <img
        [src]="'/content/img/reward.svg' | blobifyUrl"
        [alt]="'dgGlobalAddSuccess_RewardTrophy' | translate"
      />
      <div class="input-success-reward-message crap guts-m-t-2">
        <p class="alpha">{{ creationFeedback.reward }}</p>
      </div>
      <div class="guts-m-t-2">
        <a
          class="delta link underline"
          [href]="collectionUrl"
          (click)="onCollectionLinkClick($event)"
          id="dgat-globalAddSuccess-viewCollection2"
          data-dgat="globalAddSuccess-545"
          >{{ 'dgGlobalAddSuccess_ViewYourCollection' | translate }}
        </a>
      </div>
    </div>
  </div>

  <ng-template #newb>
    <div>
      <div class="input-success-newb">
        <img
          [src]="'/content/img/onboarding/firstadd.png?v=1' | blobifyUrl"
          class="img-responsive"
          [alt]="'dgGlobalAddSuccess_StartedYourCollection' | translate"
        />
        <h1 class="h2 guts-m-b-half guts-m-t-1">
          {{ 'dgGlobalAddSuccess_StartedYourCollection' | translate }}
        </h1>
        <p class="par guts-m-b-1 guts-p-h-1 crap">
          {{ 'dgGlobalAddSuccess_Description' | translate }}
        </p>
        <a
          class="par link underline"
          [href]="collectionUrl"
          (click)="onCollectionLinkClick($event)"
          data-dgat="globalAddSuccess-c32"
        >
          {{ 'dgGlobalAddSuccess_ViewYourCollection' | translate }}
        </a>
      </div>
      <button
        type="submit"
        class="input-success-button full-width guts-p-v-half guts-p-h-2"
        df-button
        dfButtonType="primary"
        id="dgat-globalAddSuccess-button"
        data-dgat="globalAddSuccess-57f"
      >
        {{ buttonText }}
      </button>
    </div>
  </ng-template>
</div>
