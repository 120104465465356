import { Observable } from 'rxjs';

import { DfFormFieldConfig, DfFieldsetTemplateOptions } from '../field-types';
import { DfConfiguredFormFieldBuilder } from './configured-form-field.builder';

/** Provides specialized methods for building field groups, which are structurally and often
 * visually grouped fields.
 * @description This is not an injectable service. To create an instance, inject
 * {link @ DfFormFieldBuilder} and call one of its field type initializer methods.
 */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export class DfFieldGroupBuilder<
  TConfig extends DfFormFieldConfig = DfFormFieldConfig
> extends DfConfiguredFormFieldBuilder<TConfig> {
  /** Styles the element containing the group's child fields, just as the {@link DfFormFieldBuilder.fieldGroup}
   * creation method does. To style the outer group field itself, which is useful when it's a child of another
   * group, use {@link DfConfiguredFormFieldBuilder.styledBy} instead.
   * */
  public withContainerStyledBy(cssClass: string) {
    this.config.fieldGroupClassName = DfFieldGroupBuilder.combineCssClassNames(
      this.config.fieldGroupClassName,
      cssClass
    );
    return this;
  }

  /** Styles the grouped items as a list. This is typically used with fields having the
   *  'horizontal-normal' style applied.
   */
  public asList() {
    // TODO: add optional list controller argument so the group fields states
    // (selected and disabled) can be driven by a parent field
    this.config.fieldGroupClassName = DfFieldGroupBuilder.combineCssClassNames(
      this.config.fieldGroupClassName,
      'df-field-list'
    );
    return this;
  }

  /** Wraps the group in a <fieldset> element, including an optional <legend> as a caption.
   * @param legend optional caption to apply as a child <legend> element
   */
  public asFieldset(legend?: string) {
    this.config.wrappers = ['fieldset'];
    this.config.templateOptions.dfLabel = legend;

    // TODO: Create a new Fieldset builder that lets us have the expander control. It makes sense for an expanded visual group to always be a fieldset for labeling purposes.
    return new DfFieldsetBuilder(
      this.translate,
      this.config as DfFormFieldConfig<DfFieldsetTemplateOptions>
    );
  }

  private static combineCssClassNames(
    currentClasses: string,
    additionalClasses: string
  ) {
    return ((currentClasses ?? '') + ` ${additionalClasses}`).trim();
  }
}

// This DfFieldGroupBuilder extension is declared here to avoid circular dependencies

/** Provides specialized methods for building field groups, which are structurally and often
 * visually grouped fields.
 * @description This is not an injectable service. To create an instance, inject
 * {link @ DfFormFieldBuilder} and call one of its field type initializer methods.
 */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export class DfFieldsetBuilder extends DfFieldGroupBuilder<
  DfFormFieldConfig<DfFieldsetTemplateOptions>
> {
  /** Allows the group to be collapsed by default and expanded by an Observable
   * @param controller an observable or key/path to a boolean form control that controls
   * the expansion state of the group, where an emitted false value collapses and true expands.
   * @param expandingContainerId the optional ID of the expanding panel. This is applied to the
   * root expanding container element an is useful for referencing from an external template
   * when applying custom labeling
   */
  public expandedBy(
    controller: Observable<boolean> | string,
    expandingContainerId?: string
  ) {
    this.config.templateOptions.expansionController = controller;
    this.config.templateOptions.expandingContainerId = expandingContainerId;
    return this;
  }
}
