<!-- 
    Note: this will be shared between the web app and bookmarklet/extension
    Please consider both scenarios when making changes here
-->

<!--notification groupings by date-->
<ul
  *ngFor="let group of notificationFeed; trackBy: getGroupTrackingKey"
  ngClass="{{ group.dateHeading ? 'guts-p-b-4 guts-p-b-1' : '' }}"
  data-dgat="notificationFeed-e2b"
>
  <div
    class="guts-m-h-1 guts-p-b-half guts-m-b-1 border-bottom"
    *ngIf="group.dateHeading"
  >
    <h2 class="h3">{{ group.dateHeading }}</h2>
  </div>
  <dgx-notification-list-item
    *ngFor="
      let notification of group.notifications;
      trackBy: getNotificationTrackingKey
    "
    [notification]="notification"
    [maxTitleLines]="maxTitleLines"
    [maxCommentLines]="maxCommentLines"
    (notificationClick)="onNotificationClick(notification)"
    (respondToGroupInviteClick)="
      onRespondToGroupInviteClick(notification, $event)
    "
    data-dgat="notificationFeed-ff0"
  >
  </dgx-notification-list-item>
</ul>
