import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DecodeHtmlPipe } from '@app/shared/pipes/decode-html.pipe';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import { EscapeCurlysPipe } from '@app/shared/pipes/escape-curlys.pipe';

@Injectable({
  providedIn: 'root',
})
export class SanitizationService {
  constructor(
    private sanitizer: DomSanitizer,
    private decodeHtmlPipe: DecodeHtmlPipe,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private escapeCurlysPipe: EscapeCurlysPipe
  ) {}

  /**
   * Given a string
   *  - Runs the built in angular DOM sanitizer
   */
  public sanitize(text: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, text);
  }

  /**
   * Given a string
   *  - Runs the decodeHtmlPipe (decodeURI())
   */
  public decode(text: string): string {
    return this.decodeHtmlPipe.transform(text);
  }

  /**
   * Given a string
   *  - Runs the htmlToPlaintextPipe
   *    - converts plaintext line breaks to html
   *    - strip all html that isn't a line break
   *    - converts &amp; to &
   *    - removes extra white spaces
   */
  public htmlToPlaintext(
    text: string,
    preserveLineBreaksAsHtml = true
  ): string {
    return this.htmlToPlaintextPipe.transform(text, preserveLineBreaksAsHtml);
  }

  /**
   * Given a string
   *  - cleans curlies ex. {{ }} => { { } }
   */
  public cleanCurlies(text: string): string {
    return this.escapeCurlysPipe.transform(text);
  }

  /**
   * Given a string
   *  - attempts to remove HTML tags with regex
   *  - sanitize (optionally bypass i.e. for markdown)
   * TODO: Redundant with htmlToPlaintext, which also calls sanitizer but is a bit more conditional/smart.
   */
  public removeHtml(text: string, bypassSanitize: boolean = false): string {
    const cleaned = String(text).replace(/<[^>]+>/gm, '');

    if (bypassSanitize) {
      return cleaned;
    }
    return this.sanitize(cleaned);
  }

  /**
   * Given a string, clean using a common sequence
   *  - sanitize
   *  - decode
   *  - htmlToPlainText (remove all html & markdown)
   *  - clean curlies
   */
  public cleanHtml(text: string): string {
    let cleaned = this.sanitize(text);
    cleaned = this.decode(cleaned);
    cleaned = this.htmlToPlaintext(cleaned);
    return this.cleanCurlies(cleaned);
  }
}
