import { Component } from '@angular/core';
import { fadeIn } from '@app/shared/animations/animations';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { A11yService } from '@app/shared/services/a11y.service';
import { TranslateService } from '@ngx-translate/core';
import { sortBy as _sortBy } from 'lodash-es';
import {
  FamilyTenantOrg,
  GetUserFamilyAccountsApi,
  SettingsFamilyService,
  UserFamilyAccount,
} from '../settings-family.service';

@Component({
  selector: 'dgx-settings-family-view',
  templateUrl: './settings-family-view.component.html',
  styleUrls: ['./settings-family-view.component.scss'],
  animations: [fadeIn],
})
export class SettingsFamilyViewComponent {
  public readonly CLASS_NAME = 'SettingsFamily';

  public i18n = this.translate.instant([
    'User_FamilyAccountAdd',
    'User_FamilyAccountInstructions',
  ]);

  public isLoading = true;
  public isAdding = false;
  public tenantOrgs: FamilyTenantOrg[];
  public userFamilyAccounts: GetUserFamilyAccountsApi['userFamilyAccounts'];

  constructor(
    private translate: TranslateService,
    private settingsFamilyService: SettingsFamilyService,
    private a11yService: A11yService
  ) {}

  public get hasUserFamilyAccounts(): boolean {
    const hasAccounts = this.userFamilyAccounts?.length > 0;
    return this.isLoading ? false : hasAccounts;
  }

  public get menuConfig(): MenuViewModel[] {
    let addButtonMenuConfig = [];
    for (const org of this.tenantOrgs) {
      const menuItem = {
        title: this.translate.instant('User_FamilyCreateAccountFormat', {
          programName: org.tenantOrgName,
        }),
        defaultAction: () => {
          this.createNewAccount(org);
        },
      };
      addButtonMenuConfig.push(menuItem);
    }
    // sorting title alphabetically here just *happens* to sort them by age.
    // lets do that as an acceptible hack knowing it's not perfect but better than nothing.
    addButtonMenuConfig = _sortBy(addButtonMenuConfig, ['title']);
    return addButtonMenuConfig;
  }

  public ngOnInit(): void {
    this.settingsFamilyService.getFamilyAccount().subscribe((result) => {
      this.userFamilyAccounts = result.userFamilyAccounts;
      this.tenantOrgs = result.tenantOrgs;
      this.isLoading = false;
    });
  }

  private createNewAccount(tenantOrg: FamilyTenantOrg) {
    this.isAdding = true;
    this.a11yService.announcePolite(this.translate.instant('Core_Loading'));
    this.settingsFamilyService
      .createFamilyAccount(tenantOrg.tenantOrganizationId)
      .subscribe((newAccount) => {
        this.isAdding = false;
        const newAccountViewModel: UserFamilyAccount = {
          code: tenantOrg.tenantOrgCode,
          defaultFamilyAccountPassword: newAccount.newUserPassword,
          hasPendingRole: true,
          userProfileKey: newAccount.newUserProfileKey,
          userName: newAccount.newUserName,
          organizationName: tenantOrg.tenantOrgName,
          organizationId: tenantOrg.tenantOrganizationId,
        };
        this.userFamilyAccounts.push(newAccountViewModel);
      });
  }
}
