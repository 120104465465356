<ng-container *ngIf="vm$ | async as vm">
  <ul
    class="guts-p-full-0 grid__col-lg-12"
    *ngIf="!vm.showSkeleton; else skeleton"
  >
    <li
      *ngFor="
        let section of getSections(vm.pathway, vm.pathway.levels);
        trackBy: trackById;
        let index = index;
        let first = first;
        let last = last
      "
      data-dgat="pathway-author-section-b16"
    >
      <dgx-pathway-author-section
        [hasFauxSubsection]="hasFauxSubsection(section, vm.fauxSubsections)"
        [hideDuration]="vm.pathway.durationDisplayDisabled"
        [index]="index"
        [isFauxSection]="hasFauxSection(vm.pathway, vm.hasFauxSection)"
        [isOnlyChild]="first && last"
        [section]="section"
      ></dgx-pathway-author-section>
      <hr *ngIf="!last" class="pathway-section__line guts-m-v-4" />
    </li>
  </ul>
  <ng-template #skeleton>
    <div class="guts-p-full-0 grid__col-lg-8">
      <div
        *ngFor="let skeleton of skeletons"
        aria-hidden="true"
        data-dgat="pathway-section-ca7"
      >
        <dgx-skeleton type="input-card"></dgx-skeleton>
      </div>
    </div>
  </ng-template>
</ng-container>
