import { Component, Input, OnInit } from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { TagTypes } from '@app/analytics/models/constants';
import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import { NavigationService, LDFlagsService } from '@dg/shared-services';
import { Observable } from 'rxjs';
@Component({
  selector: 'dgx-product-switcher',
  templateUrl: './product-switcher.component.html',
  styleUrls: ['./product-switcher.component.scss'],
})
export class ProductSwitcherComponent implements OnInit {
  @Input() public hasLightText: boolean;
  @Input() public refreshIcon: boolean = false;

  public colorVarient: string;
  public hasAnalytics: boolean;
  public analyticsUrl: string;
  public hasExtendedEnterprise: boolean;
  public homeImg: string;
  public isSkillInventoryClient: boolean;
  public isSkillAnalyticsClient: boolean;
  public orgInfo: OrgInfo[];
  public defaultOrgId: number;
  public hasSkillsPlatform: boolean;
  public lxpPath: string;
  public learnInSSOUrl$: Observable<string>;
  public ariaExpanded: boolean;
  private authUser: AuthUser;

  constructor(
    private analyticsGuard: AnalyticsGuard,
    private authService: AuthService,
    private contextService: ContextService,
    private navigationService: NavigationService,
    private featureFlag: LDFlagsService
  ) {}

  public get canViewManageOrgLink() {
    return (
      (this.authService.userCanManageLearnerOrg ||
        this.authService.userCanViewReporting ||
        this.authService.userCanManageSkillInventory ||
        this.authService.userCanManageSkillAnalytics) &&
      !this.contextService.isChannel()
    );
  }

  public get canViewLearnIn(): boolean {
    return this.featureFlag.showLearnInProductSwitcher;
  }

  public ngOnInit(): void {
    this.lxpPath = this.navigationService.paths.home;
    this.authUser = this.authService.authUser;
    this.hasExtendedEnterprise = this.authUser?.hasChannel;

    this.orgInfo = !!this.authUser ? this.authUser.orgInfo : [];
    this.defaultOrgId = !!this.authUser
      ? this.authUser.defaultOrgInfo?.organizationId
      : null;
    this.homeImg =
      !!this.authUser &&
      this.authUser.defaultOrgInfo?.settings?.isClientProvider
        ? '/content/img/product-switcher/home-alt@2x.png'
        : '/content/img/product-switcher/home@2x.png';

    // Analytics module (Visier)
    this.hasAnalytics = this.analyticsGuard.isAuthorized;
    if (this.hasAnalytics) {
      this.analyticsUrl = this.analyticsGuard.getParentUrl(
        TagTypes.PRODUCT_SWITCHER
      );
    }

    // Degreed Skills
    this.hasSkillsPlatform =
      this.authUser?.defaultOrgInfo?.settings.enableSkillsPlatform &&
      this.authUser?.defaultOrgInfo?.permissions.manageSkillsPlatform;

    if (this.canViewLearnIn) {
      this.learnInSSOUrl$ = this.navigationService.getLearnInSSOUrl(
        this.defaultOrgId
      );
    }
  }
}
