import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import { Observable } from 'rxjs';
// Services
import { TranslateService } from '@ngx-translate/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

// misc
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { VideoModel } from '@app/user-content/user-input-v2/inputs/video/video.model';
import {
  hasBrokenUrlValidator,
  maxFifteenSkillsValidator,
  contentOwnerIdValidator,
} from '@app/user-content/user-input-v2/utils/validators';
import { DgError } from '@app/shared/models/dg-error';
import { VideoPathwaysPlansInputFacade } from '../../services/pathways-and-plans/video-pathways-plans.facade';
import { handleVideoFocusOnSubmit } from '../../services/video.utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { NotificationType } from '@lib/fresco';
import {
  pathPlanHideUrlField,
  onChangeOfAddToCatalogValue,
} from '@app/user-content/user-input-v2/utils/form-field-helper';
import { pathPlanEditContentNotification } from '@app/user-content/user-input-v2/utils/modal-helper';
import { PathwayStep } from '@dg/pathways-rsm';
import { AuthService } from '@dg/shared-services';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import {
  onFormControlUpdate,
  markFormAsTouched,
} from '@app/shared/utils/angular-form-field-helpers';
@Component({
  selector: 'dgx-video-pathways-plans',
  templateUrl: './video-pathways-plans.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPathwaysPlansComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @Input() public pathwayStep?: PathwayStep;

  @ViewChild('entryUrl')
  public entryUrlInput: ElementRef<HTMLElement>;
  @ViewChild('form') public form: NgForm;

  public videoPlanPathForm: FormGroup;
  public vm$: Observable<VideoModel>;
  public showExpandedForm: boolean = false;
  public readonly NotificationType = NotificationType;
  public hideUrlField = pathPlanHideUrlField;
  public i18n = this.translate.instant([
    'Core_ChangesToPlanOrPathway',
    'Core_Description',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Skills',
    'Core_Source',
    'Core_Title',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgOrgInternalContentForm_VideoTimeLimit',
    'MediaFormCtrl_AddVideo',
    'MediaFormCtrl_EditVideo',
    'MediaFormCtrl_ValidContentOwnerRequired',
    'MediaFormCtrl_VideoUrl',
    'MediaFormCtrl_SaveVideo',
    'MediaFormCtrl_TitleRequired',
    'MediaFormCtrl_UrlRequired',
    'Core_GeneralErrorMessage',
    'dgOrgInternalContentForm_VideoDescriptionPlaceholder',
    'dgOrgInternalContentForm_VideoFormat',
    'dgOrgInternalContentForm_VideoFormatSelectPlaceholder',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsMaxError',
    'Pathways_EditCatalogItemHelper',
    'Pathways_EditLocalContentHelper',
    'Core_OrgManagedDocLinkLabel',
  ]);

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private facade: VideoPathwaysPlansInputFacade,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private authService: AuthService
  ) {
    super();
    this.vm$ = this.facade.viewModel$ as any as Observable<VideoModel>;
  }

  /**
   * Get the url required error message to display
   *
   * @returns string translation for URL error message to be shown
   */
  public get getUrlErrorMessage(): string {
    return this.i18n.MediaFormCtrl_UrlRequired;
  }

  public get editContentNotificationText(): string {
    return pathPlanEditContentNotification(
      this.isEditingInternalContent,
      this.translate
    );
  }

  public get showEntryUrlDuplicateCheck(): boolean {
    return (
      !this.facade.snapshot.isInitialForm &&
      this.facade.snapshot.duplicateCount > 0 &&
      (this.videoPlanPathForm.get('addToCatalog')?.value ||
        (this.facade.snapshot.inputContext.isEditing && this.isCatalogContent))
    );
  }

  public get showExtraFields(): boolean {
    return (
      this.showExpandedForm &&
      (this.facade.snapshot.inputContext.isEditingInternalContent ||
        this.videoPlanPathForm.get('addToCatalog')?.value)
    );
  }

  public get addToCatalogCheckboxLabel(): string {
    return this.translate.instant('MediaFormCtrl_AddVideoToCatalogFormat', {
      orgName: this.facade.orgName,
    });
  }

  public get modalHeading(): string {
    return this.facade.snapshot.inputContext.isEditing
      ? this.i18n.MediaFormCtrl_EditVideo
      : this.i18n.MediaFormCtrl_AddVideo;
  }

  public get isCatalogContent(): boolean {
    return this.facade.snapshot.inputContext.isEditing
      ? !!this.facade.snapshot.inputContext.isCmsContent
      : this.videoPlanPathForm.get('addToCatalog')?.value;
  }

  public get isEditingInternalContent(): boolean {
    return (
      this.facade.snapshot.inputContext.isEditingInternalContent &&
      this.facade.snapshot.inputContext.isCmsContent
    );
  }

  /**
   * Determines if the user is editing the pathway's input only
   * Edits to the input should not be applied to the catalog/internal input
   * @returns boolean
   */
  public get isEditingContentLocally(): boolean {
    return (
      !this.facade.snapshot.inputContext.isEditingInternalContent &&
      this.facade.snapshot.inputContext.isEditing
    );
  }

  /**
   * Currently for local edit there are properties that we can not edit(PD-95879),
   * If the input has not value and we are editing locally in the pathway|plan
   * do not show the input field. (Content Owner, duration)
   * @returns true if the input field should be shown
   */
  public get shouldShowContentOwnerField(): boolean {
    return (
      !this.isEditingContentLocally ||
      !!this.videoPlanPathForm.get('owner').value
    );
  }

  /**
   * Currently for local edit there are properties that we can not edit(PD-95879),
   * If the input has not value and we are editing locally in the pathway|plan
   * do not show the input field. (Content Owner, duration)
   * @returns true if the input field should be shown
   */
  public get shouldShowDurationField(): boolean {
    return (
      !this.isEditingContentLocally ||
      !!this.facade.snapshot.durationHours ||
      !!this.facade.snapshot.durationMinutes
    );
  }

  public async ngOnInit(): Promise<void> {
    await this.facade.initializeViewModel(this.context);

    if (this.facade.snapshot.inputContext.isEditing) {
      await this.facade.initializeEdit(
        this.isEditingContentLocally,
        this.pathwayStep
      );
      this.initializeExpandedEditForm();
      return;
    }
    // Setup the view model

    this.initializeForm();
  }
  /****************************************************
   * On form page submissions
   ****************************************************/

  public async onNext(form: FormGroupDirective): Promise<void> {
    markFormAsTouched(this.videoPlanPathForm);
    if (this.videoPlanPathForm.invalid) {
      return;
    }
    await this.facade.onNext(this.videoPlanPathForm.get('entryUrl').value); // reset the submitted state on the form
    form.resetForm(this.videoPlanPathForm.value);

    this.loadExpandedForm();
  }

  public async onSubmit(): Promise<void> {
    markFormAsTouched(this.videoPlanPathForm);

    if (this.videoPlanPathForm.invalid) {
      handleVideoFocusOnSubmit(this.videoPlanPathForm.controls);
      return;
    }

    const entryUrlValue = this.videoPlanPathForm.get('entryUrl')?.value;
    // Only check for duplicates if fetchUrl
    if (
      (this.isCatalogContent &&
        this.facade.snapshot.inputContext.isEditingInternalContent) ||
      (this.isCatalogContent && !this.facade.snapshot.inputContext.isEditing)
    ) {
      const defaultOrgId =
        this.facade.snapshot.inputContext.organizationId ??
        this.authService.authUser?.defaultOrgId;

      await this.facade.fetchUrlDuplicates(entryUrlValue, defaultOrgId);
    } // if we are adding to catalog, re-check the url for duplicates on submit
    if (this.isCatalogContent && this.facade.snapshot.duplicateCount > 0) {
      this.videoPlanPathForm.get('entryUrl').markAsTouched();
      this.entryUrlInput.nativeElement.focus();
      return;
    }
    await this.facade.onSubmit(
      this.videoPlanPathForm,
      this.isEditingContentLocally,
      this.pathwayStep
    );

    // TODO: We shouldn't return the entire snapshot here. Update
    // mapperService.toStep so that it *only* returns a pathway step,
    // then perhaps add a wrapper to the facade to call that method,
    // and make this something like:
    // this.activeModal.close(this.facade.toStep(this.pathwayStep));
    // ...which might be null, and the method will have to account
    // for that, but that way we won't be adding a ton of properties
    // to a payload that's eventually used for updatePathwayNode.
    this.activeModal.close(this.facade.snapshot);
  }

  /**
   * When the URL has duplicates we display a message with the option to view the duplicates in a modal
   * Open the view duplicates modal
   */
  public openViewDuplicates(): void {
    this.facade.viewDuplicates();
  }

  public onFormControlUpdate(field: string, value: any): void {
    onFormControlUpdate(this.videoPlanPathForm, field, value);
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.videoPlanPathForm = this.formBuilder.group({
      entryUrl: [
        '',
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
    });
  }

  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm(): void {
    const ownerValidator = this.facade.snapshot.inputContext.isEditing
      ? Validators.required
      : '';

    this.showExpandedForm = true;

    this.videoPlanPathForm = this.formBuilder.group({
      addToCatalog: [],
      entryUrl: [
        this.videoPlanPathForm.get('entryUrl'),
        [Validators.required, Validators.pattern(HTTP_REQUIRED_URL_PATTERN)],
      ],
      title: ['', [Validators.required, isEmptyValidator]],
      sourceName: [],
      format: [],
      description: [],
      owner: ['', ownerValidator],
      image: [],
      skills: ['', maxFifteenSkillsValidator],
      // Advanced Setting added by child component
    });

    this.videoPlanPathForm.patchValue({
      entryUrl: this.facade.snapshot.entryUrl,
      title: this.facade.snapshot.title,
      sourceName: this.facade.snapshot.sourceName,
      format: this.facade.snapshot.format,
      description: this.facade.snapshot.summary,
      owner: this.facade.snapshot.owner,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.highConfidenceInferredSkills ?? [],
    });

    // When loading the full view reset the focus on the url input
    this.entryUrlInput?.nativeElement.focus();

    // Subscribe to value changes on the entryURL to reset the duplicates error
    this.videoPlanPathForm
      .get('entryUrl')
      ?.valueChanges.subscribe(() => this.facade.resetDuplicates());

    this.videoPlanPathForm
      .get('addToCatalog')
      .valueChanges.subscribe((isAddingToCatalog) => {
        const defaultOrgId =
          this.facade.snapshot.inputContext.organizationId ??
          this.authService.authUser?.defaultOrgId;
        onChangeOfAddToCatalogValue(
          isAddingToCatalog,
          this.videoPlanPathForm,
          this.facade,
          defaultOrgId
        );
      });

    this.cdr.detectChanges();
  }

  /**
   * On edit internal item, initialize the full form
   */
  private initializeExpandedEditForm() {
    this.showExpandedForm = true;
    const isRecordedVideo = this.isAuthoredContent(
      this.facade.snapshot.entryUrl
    );
    const contentOwnerValidator =
      this.isCatalogContent && this.isEditingInternalContent
        ? [Validators.required, contentOwnerIdValidator]
        : [];

    const urlValidators =
      this.hideUrlField(
        this.isCatalogContent,
        this.facade.snapshot.inputContext.isEditing,
        this.facade.snapshot.hostedContentDetails
      ) ||
      isRecordedVideo ||
      this.isEditingContentLocally
        ? []
        : [
            Validators.required,
            Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
            hasBrokenUrlValidator(
              this.translate,
              this.facade.snapshot.hasBrokenUrl,
              this.facade.snapshot.entryUrl
            ),
          ];

    let inputEntry: any = {
      entryUrl: [this.facade.snapshot.entryUrl, urlValidators],
    };

    if (!!this.facade.snapshot.hostedContentDetails) {
      inputEntry = {
        ...inputEntry,
        contentUploader: [
          this.facade.snapshot.hostedContentDetails,
          Validators.required,
        ],
      };
    }
    const createdByI18n = this.translate.instant('Core_CreatedBy', {
      authorName: this.facade.snapshot.createdBy,
    });

    const createdBy = this.facade.snapshot.createdBy ? createdByI18n : '';

    this.videoPlanPathForm = this.formBuilder.group({
      ...inputEntry,
      createdBy: createdBy ?? '',
      title: [this.facade.snapshot.title ?? '', Validators.required],
      sourceName: this.facade.snapshot.sourceName ?? '',
      description: this.facade.snapshot.summary ?? '',
      format: this.facade.snapshot.format ?? '',
      owner: [this.facade.snapshot.owner ?? '', contentOwnerValidator],
      image: this.facade.snapshot.imageUrl ?? '',
      skills: [this.facade.snapshot.tags, maxFifteenSkillsValidator] ?? [
        [],
        maxFifteenSkillsValidator,
      ],
    });
  }

  private isAuthoredContent(entryUrl: string) {
    return entryUrl?.indexOf('userauthoredvideo') > -1;
  }
}
