import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Mentorship Feedback Request notification */
@Component({
  selector: 'dgx-mentorship-feedback-request',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorshipFeedbackRequestComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('MentorshipFeedbackRequest_NotificationTitleFormat', {
      mentorshipUser: this.userFullNameTemplate,
    });
  }
}
