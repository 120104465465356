import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  checkedFromInput,
  OrgInfoViewModel,
  valueFromInput,
} from '../data-service';

@Component({
  selector: 'dgx-org-branding-org-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'tw-col-span-full tw-flex tw-flex-col tw-gap-y-8 lg:tw-col-start-2 lg:-tw-col-end-2',
  },
  template: `
    <header>
      <h3 class="tw-text-2xl tw-font-bold">Organization Name</h3>
      <p class="tw-text-sm tw-text-neutral-600">
        Organization name is in various places throughout Degreed products.
        <a href="#" class="tw-text-blue-800">Learn More</a>
      </p>
    </header>

    <div>
      <div class="tw-group tw-relative tw-z-0 tw-w-full">
        <input
          id="org-name"
          name="org-name"
          class="tw-peer tw-mt-4 tw-block tw-w-full tw-cursor-text tw-appearance-none tw-border-0 tw-border-b-2 tw-border-neutral-200 tw-bg-transparent tw-px-0 tw-py-1 tw-text-base tw-font-normal tw-text-neutral-800 tw-placeholder-neutral-600 placeholder:tw-opacity-0 read-only:tw-cursor-not-allowed read-only:tw-border-dashed read-only:tw-bg-neutral-100 focus:tw-border-blue-800 focus:tw-outline-none focus:tw-ring-0 focus:placeholder:tw-opacity-100 group-[.has-error]:tw-border-red-200 group-[.has-error]:tw-text-red-800 group-[.has-error]:tw-placeholder-red-800 focus:group-[.has-error]:tw-border-red-800 dark:tw-border-neutral-700 dark:tw-text-neutral-100 dark:tw-placeholder-neutral-400 dark:read-only:tw-bg-neutral-800 dark:focus:tw-border-blue-600 dark:group-[.has-error]:tw-border-red-700 dark:group-[.has-error]:tw-text-red-100 dark:group-[.has-error]:tw-placeholder-red-400 dark:focus:group-[.has-error]:tw-border-red-700 group-[.has-error]:[&:not(:focus)]:tw-pr-6 focus:[&:not(:placeholder-shown)]:tw-pr-6 group-hover:[&:not(:placeholder-shown)]:tw-pr-6"
          placeholder=""
          required
          [value]="orgInfo.orgName"
          (change)="
            orgInfo.updateInfo({
              orgName: valueFromInput($event),
            })
          "
        />

        <button
          class="tw-dark:text-neutral-300 tw-absolute tw-bottom-5 tw-right-0 tw-top-0 tw-z-10 tw-hidden tw-items-center focus:tw-flex peer-focus:peer-[&:not(:placeholder-shown)]:peer-[&:not(:read-only)]:tw-flex group-hover:peer-enabled:peer-[&:not(:placeholder-shown)]:peer-[&:not(:read-only)]:tw-flex"
          type="button"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Icon">
              <path
                id="Vector"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.71967 1.71967C2.01256 1.42678 2.48744 1.42678 2.78033 1.71967L6 4.93934L9.21967 1.71967C9.51256 1.42678 9.98744 1.42678 10.2803 1.71967C10.5732 2.01256 10.5732 2.48744 10.2803 2.78033L7.06066 6L10.2803 9.21967C10.5732 9.51256 10.5732 9.98744 10.2803 10.2803C9.98744 10.5732 9.51256 10.5732 9.21967 10.2803L6 7.06066L2.78033 10.2803C2.48744 10.5732 2.01256 10.5732 1.71967 10.2803C1.42678 9.98744 1.42678 9.51256 1.71967 9.21967L4.93934 6L1.71967 2.78033C1.42678 2.48744 1.42678 2.01256 1.71967 1.71967Z"
                fill="currentColor"
              />
            </g>
          </svg>
          <span class="tw-sr-only">Reset Organization Name</span>
        </button>

        <i
          class="tw-pointer-events-none tw-absolute tw-bottom-5 tw-right-0 tw-top-0 tw-hidden tw-items-center tw-text-neutral-600 peer-read-only:tw-flex"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            alt=""
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.00002 0.799988C6.0118 0.799988 4.40002 2.41176 4.40002 4.39999V7.19999H4.00002C3.11637 7.19999 2.40002 7.91633 2.40002 8.79999V13.6C2.40002 14.4836 3.11637 15.2 4.00002 15.2H12C12.8837 15.2 13.6 14.4836 13.6 13.6V8.79999C13.6 7.91633 12.8837 7.19999 12 7.19999H11.6V4.39999C11.6 2.41176 9.98825 0.799988 8.00002 0.799988ZM10.4 7.19999V4.39999C10.4 3.0745 9.32551 1.99999 8.00002 1.99999C6.67454 1.99999 5.60002 3.0745 5.60002 4.39999V7.19999H10.4Z"
              fill="currentColor"
            />
          </svg>
        </i>

        <label
          for="org-name"
          class="tw-absolute tw-top-1 tw-origin-[0] -tw-translate-y-5 tw-transform tw-cursor-text tw-text-xs tw-text-neutral-600 tw-duration-300 group-hover:tw-text-neutral-800 group-[.has-error]:tw-text-red-800 group-hover:group-[.has-error]:tw-text-red-800 peer-placeholder-shown:tw-translate-y-0 peer-placeholder-shown:tw-text-base peer-placeholder-shown:tw-font-normal peer-read-only:tw-cursor-not-allowed peer-focus:tw-left-0 peer-focus:-tw-translate-y-5 peer-focus:tw-text-xs peer-focus:tw-font-semibold peer-focus:tw-text-blue-800 group-[.has-error]:peer-focus:tw-text-red-800 dark:tw-text-neutral-300 dark:group-hover:tw-text-neutral-200 dark:group-[.has-error]:tw-text-red-400 dark:group-hover:group-[.has-error]:tw-text-red-300 dark:peer-focus:tw-text-blue-600 dark:group-[.has-error]:peer-focus:tw-text-red-400"
        >
          Organization Name <span class="tw-text-red-800">*</span>
        </label>
      </div>
      <div class="tw-mt-4 tw-flex tw-items-center">
        <input
          id="use-in-onboarding"
          name="use-in-onboarding"
          type="checkbox"
          class="tw-size-4 tw-rounded tw-border-neutral-400 tw-bg-neutral-50 tw-text-blue-800 hover:tw-border-blue-800 focus:tw-ring-blue-800 focus:tw-ring-offset-0"
          [checked]="orgInfo.useInOnboarding"
          (change)="
            orgInfo.updateInfo({ useInOnboarding: checkedFromInput($event) })
          "
        />
        <!-- Checked: tw-font-semibold -->
        <label
          for="use-in-onboarding"
          [ngClass]="[
            'tw-ml-2',
            orgInfo.useInOnboarding ? 'tw-font-semibold' : '',
          ]"
          >Use organization name in onboarding</label
        >
      </div>
    </div>
  `,
})
export class OrgBrandingOrgNameComponent {
  valueFromInput = valueFromInput;
  checkedFromInput = checkedFromInput;

  @Input() orgInfo: OrgInfoViewModel;
}
