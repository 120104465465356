import { TranslateService } from '@ngx-translate/core';

import { DfFormFieldConfig } from '../field-types';
import { DfConfiguredFormFieldBuilder } from './configured-form-field.builder';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Extends {@link DfConfiguredFormFieldBuilder} to allow explicit specification for a field to be required
 * or optional, which doesn't make sense for some field types such as checkboxes and radio buttons.
 * @description This is not an injectable service. To create an instance, inject {link @ DfFormFieldBuilder} and
 * call one of its field type initializer methods.
 */
export class DfRequirableFormFieldBuilder<
  TConfig extends DfFormFieldConfig
> extends DfConfiguredFormFieldBuilder<TConfig> {
  constructor(
    protected translate: TranslateService,
    protected config?: TConfig
  ) {
    super(translate, config);
  }

  /** Makes the field optional */
  public asOptional() {
    this.config.templateOptions.required = false;
    return this;
  }

  /** Makes the field required, which sets the attribute on the input element and
   * automatically adds a {@linkcode Validators.required} validator.
   */
  public asRequired() {
    this.config.templateOptions.required = true;
    return this;
  }
}
