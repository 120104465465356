import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  useApolloLayout,
  useApolloLayout_Learner_V3,
} from '@degreed/apollo-angular';

// components
import { ManageCredentialsComponent } from '@app/manage-credentials/components/manage-credentials/manage-credentials.component';
import { IntegrationProviderDetailsComponent } from '@app/manage-providers/integration-provider-details/integration-provider-details.component';
import { IntegrationProvidersComponent } from '@app/manage-providers/integration-providers/integration-providers.component';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import { RouterComponents } from '@app/orgs/constants';
// guards
import { BrandingGuard } from '@app/orgs/services/guards/branding.guard';
import { CommunicationsGuard } from '@app/orgs/services/guards/communications.guard';
import { CredentialsGuard } from '@app/orgs/services/guards/credentials.guard';
import { FaqGuard } from '@app/orgs/services/guards/faq.guard';
import { IntegrationProvidersGuard } from '@app/orgs/services/guards/integration-providers.guard';
import { MessagingGuard } from '@app/orgs/services/guards/messaging.guard';
import { PermissionsGuard } from '@app/orgs/services/guards/permissions.guard';
// services
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { LDFlagsService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { EditSettingsGuard } from '../../services/guards/edit-settings.guard';
import { OrgBrandingComponent } from '../org-branding/org-branding.component';
import { TabNavigationService } from './../../../navigation/components/tab-navigation/tab-navigation.service';
import { OrgPageHeaderService } from './../../services/org-page-header.service';
import { OrgFaqComponent } from './../org-faq/org-faq.component';
import { OrgHelpMenuViewComponent } from './../org-help-menu-view/org-help-menu-view.component';
import { OrgLinksTabComponent } from './../org-links/org-links-tab/org-links-tab.component';
import { OrgPermissionsTabComponent } from './../org-permissions-tab/org-permissions-tab.component';
import { OrgSettingsComponent } from './../org-settings/org-settings.component';
import { OrgViewBaseComponent } from './../org-view-base/org-view-base.component';

export enum SettingsTabComponents {
  OrgBrandingViewComponent,
  OrgHelpMenuViewComponent,
  OrgSettingsComponent,
  OrgFaqComponent,
  OrgLinksTabComponent,
  OrgPermissionsTabComponent,
  IntegrationProvidersComponent,
  IntegrationProviderDetailsComponent,
  ManageCredentialsComponent,
  ManageAttributesViewComponent,
}

@Component({
  selector: 'dgx-org-settings-nav',
  templateUrl: './org-settings-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgSettingsNavComponent
  extends OrgViewBaseComponent
  implements OnInit
{
  public readonly CLASS_NAME = RouterComponents.ORG_SETTINGS_NAV;

  public orgId: number;
  public showNavigation: boolean;
  public tabs: TabNavigationItem[] = [];

  public useApollo = useApolloLayout_Learner_V3(); // v3 navigation

  public readonly i18n = this.translate.instant([
    'OrgSettingsNav_Branding',
    'OrgSettingsNav_HelpMenu',
    'OrgSettingsNav_Communication',
    'OrganizationsController_FAQ',
    'Core_Home',
    'OrgSettingsNav_Messaging',
    'OrgSettingsNav_Permissions',
    'OrgSettingsNav_Providers',
    'User_Integrations',
    'OrgManage_OauthCredentials_apiKeysTab',
    'OrgSettingsNav_BusinessRules',
    'OrgSettingsNav_ManageAttributes',
  ]);

  constructor(
    private authService: AuthService,
    private brandingGuard: BrandingGuard,
    private communicationsGuard: CommunicationsGuard,
    private credentialsGuard: CredentialsGuard,
    private editSettingsGuard: EditSettingsGuard,
    private faqGuard: FaqGuard,
    private integrationProvidersGuard: IntegrationProvidersGuard,
    private messagingGuard: MessagingGuard,
    private orgPageHeaderService: OrgPageHeaderService,
    private permissionsGuard: PermissionsGuard,
    private route: ActivatedRoute,
    private tabNavigationService: TabNavigationService,
    private translate: TranslateService,
    private trackerService: TrackerService,
    private ldFlagsService: LDFlagsService
  ) {
    super();
  }

  public get currentRoute(): string {
    return this.route.snapshot.firstChild.routeConfig.path;
  }

  public ngOnInit(): void {
    this.initializeTabs();
  }

  //TODO:
  public onActivate(
    component:
      | IntegrationProviderDetailsComponent
      | IntegrationProvidersComponent
      | ManageCredentialsComponent
      | OrgBrandingComponent
      | OrgFaqComponent
      | OrgHelpMenuViewComponent
      | OrgLinksTabComponent
      | OrgPermissionsTabComponent
      | OrgSettingsComponent
  ) {
    const orgInfo = this.authService.authUser?.orgInfo?.find(
      (x) => x.organizationId === this.orgId
    );
    this.orgPageHeaderService.setShowPageHeader(true);
    this.showNavigation = true;
    switch (component.CLASS_NAME) {
      case SettingsTabComponents.OrgBrandingViewComponent:
        component.orgId = this.orgId;
        component.hideMasterOrgSettings =
          orgInfo.settings.hideMasterOrgSettings;
        component.canEditSettings = orgInfo.permissions.editSettings;
        component.canEditPermissions = orgInfo.permissions.editPermissions;
        this.trackEvent('Org Settings Branding Viewed');
        break;
      case SettingsTabComponents.OrgHelpMenuViewComponent:
        component.orgId = this.orgId;
        this.trackEvent('Org Settings Help Menu Viewed');
        break;
      case SettingsTabComponents.OrgSettingsComponent:
        const routeData = this.route.snapshot.firstChild.data;
        component.orgId = this.orgId;
        component.configurationValue = routeData.configurationValue;
        component.headingName = routeData.headingNameKey
          ? this.translate.instant(routeData.headingNameKey)
          : null;
        component.headingDescription = routeData.headingDescriptionKey
          ? this.translate.instant(routeData.headingDescriptionKey)
          : null;
        component.isMessageSettings = routeData.isMessageSettings ?? false;
        if (routeData.isMessageSettings) {
          this.trackEvent('Org Settings Messaging Viewed');
        } else {
          this.trackEvent('Org Settings Communications Viewed');
        }
        break;
      case SettingsTabComponents.OrgFaqComponent:
        component.orgId = this.orgId;
        component.viewOnly = false;
        this.trackEvent('Org Settings FAQ Viewed');
        break;
      case SettingsTabComponents.OrgLinksTabComponent:
        component.orgId = this.orgId;
        component.orgName = orgInfo.name;
        if (!this.ldFlagsService.personalizedLearning) {
          this.trackerService.trackEventData({
            action: 'Org Settings Home Viewed',
          });
        }
        break;
      case SettingsTabComponents.OrgPermissionsTabComponent:
        component.orgId = this.orgId;
        this.trackEvent('Org Settings Permissions Viewed');
        break;
      case SettingsTabComponents.IntegrationProvidersComponent:
        component.orgId = this.orgId;
        component.providerCode =
          this.route.parent.snapshot.queryParams.provider;
        break;
      case SettingsTabComponents.ManageCredentialsComponent:
        component.orgId = this.orgId;
        this.trackEvent('Org Settings API Viewed');
        break;
      case SettingsTabComponents.IntegrationProviderDetailsComponent:
        this.orgPageHeaderService.setShowPageHeader(false);
        this.showNavigation = false;
        component.orgId = this.orgId;
        component.providerCode =
          this.route.firstChild.snapshot.params.providerCode;
        break;
      default:
        break;
    }
  }

  private trackEvent(action: string): void {
    this.trackerService.trackEventData({
      action,
      properties: {},
    });
  }

  private initializeTabs(): void {
    const disableHomeAdminSettings =
      this.authService.authUser?.defaultOrgInfo?.settings
        .disableHomeAdminSettings;
    const personalizedLearning = this.ldFlagsService.personalizedLearning;

    this.tabs = this.tabNavigationService.formatTabs([
      {
        dgat: 'orgSettingsNav-810',
        sortOrder: 1,
        label: this.i18n.OrgSettingsNav_Branding,
        routerLink: './branding',
        isAuthorized: this.brandingGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-48d',
        sortOrder: 2,
        label: this.i18n.Core_Home,
        routerLink: './home',
        isAuthorized:
          this.editSettingsGuard.hasPermission &&
          personalizedLearning &&
          useApolloLayout() &&
          !disableHomeAdminSettings,
      },
      {
        dgat: 'orgSettingsNav-27a',
        sortOrder: 3,
        label: this.i18n.OrgSettingsNav_HelpMenu,
        routerLink: './helpMenu',
        isAuthorized: this.editSettingsGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-267',
        sortOrder: 4,
        label: this.i18n.OrgSettingsNav_Communication,
        routerLink: './communications',
        isAuthorized: this.communicationsGuard.hasPermission,
      },
      {
        dgat: '_OrgNav-3b0',
        sortOrder: 5,
        label: this.i18n.OrganizationsController_FAQ,
        routerLink: './faq',
        isAuthorized: this.faqGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-48d',
        sortOrder: 6,
        label: this.i18n.Core_Home,
        routerLink: './links',
        isAuthorized:
          this.editSettingsGuard.hasPermission &&
          (!personalizedLearning ||
            (personalizedLearning && !useApolloLayout())) &&
          !disableHomeAdminSettings,
      },
      {
        dgat: 'orgSettingsNav-517',
        sortOrder: 7,
        label: this.i18n.OrgSettingsNav_Messaging,
        routerLink: './messaging',
        isAuthorized: this.messagingGuard.hasPermission,
      },
      {
        dgat: 'orgSettingsNav-e59',
        sortOrder: 8,
        label: this.i18n.OrgSettingsNav_Permissions,
        routerLink: './permissions',
        isAuthorized: this.permissionsGuard.hasPermission,
      },
      {
        dgat: 'orgAdvancedNav-f84',
        sortOrder: 9,
        label: this.i18n.User_Integrations,
        routerLink: './integrationProviders',
        isAuthorized: this.integrationProvidersGuard.hasPermission,
      },
      {
        dgat: 'orgAdvancedNav-8fb',
        sortOrder: 10,
        label: this.i18n.OrgManage_OauthCredentials_apiKeysTab,
        routerLink: './manageCredentials',
        isAuthorized: this.credentialsGuard.hasPermission,
      },
    ]);
  }
}
