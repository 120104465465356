import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { generateCustomId } from '../../utils/custom-id';
import { ButtonType } from '../../temporary-types';
import { HeadingMetaOption } from './modal-header';

/**
 * Modal Component
 * Used as a base for other `Component` components.
 *
 * ```
 * // most basic usage, adds a "Cancel" button to the footer
 * // and passes in an optional item to be returned on submit
 * <dgx-modal
 *   [canCancel]="true"
 *   [item]="item"
 *   [submitButtonText]="'Core_Submit' | translate"
 * >
 *   // For a modal with no header at all, pass in
 *   // `[canCloseHeader]="false"` and leave off this element.
 *   <ng-container class="modal-header">
 *     {{ 'Core_HeaderText' | translate }}
 *   </ng-container>
 *
 *   <ng-container class="modal-body">
 *     <p>
 *       Any kind of component can go in here!
 *     </p>
 *   </ng-container>
 *
 *
 *    // use [isFooterless]="true" to hide the footer altogether
 *    // use [useDefaultSubmitButton]="false" to supply your own footer button
 *    <ng-container class="modal-footer">
 *       <button
 *         df-button
 *         dfButtonType="primary"
 *         type="submit">
 *         {{ 'Core_Submit' | translate }}
 *       </button>
 *    </ng-container>
 *
 * </dgx-modal>
 *
 * // more complicated usage, wraps in a custom form
 * // and handles its own form submission
 * <form [formGroup]="deleteForm" (ngSubmit)="onSubmit()" class="form-wrap">
 *   <dgx-modal [canCancel]="true" [useDefaultForm]="false">
 *     // same kind of content as the first example -->
 *   </dgx-modal>
 * </form>
 * ```
 */

@Component({
  selector: 'dgx-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  /** Additional classes to apply to the wrapping body DIV. Can be a string, or any other valid value for ngClass. */
  @Input() public bodyClasses: any = '';
  /** Additional styles to apply to the wrapping body DIV. *Avoid if possible.* */
  @Input() public bodyStyle;
  /** Whether or not the modal should have a cancel button. *Default `false`.* */
  @Input() public canCancel = false;
  /** Whether or not the modal header should have a (x) cancel button. *Default `true`.* */
  @Input() public canCloseHeader = true;
  /** Dgat for the default cancel button. */
  @Input() public cancelButtonDgat? = 'modalFooter-b7c';
  /** Translated text to display on the cancel button. *Default `Cancel`.* */
  @Input() public cancelButtonText? =
    this.translateService.instant('Core_Cancel');
  /** Additional classes for the cancel button. *Default `btn-passive--no-border font-regular`.* */
  @Input() public cancelButtonClasses? = 'btn-passive--no-border font-regular';
  /** Whether `submit` is actually just closing the modal. *Default `false`.* */
  @Input() public closeOnSubmit = false;
  /** Value of ngClass on the header's <h1> element. *Can be string or expression.* */
  @Input() public headerClasses: any = 'h3';
  /** Value of ngClass on the wrapping <header> element. *Can be string or expression.* */
  @Input() public headerWrapperClasses: any;
  /** Whether to include the footer at all. */
  @Input() public isFooterless = false;
  /** Whether or not the modal header has bottom. *Default `false`.* */
  @Input() public isHeaderBorderless = false;
  /** Whether or not the modal header is centered. *Default `false`.* */
  @Input() public isHeaderCentered = false;
  /** Can be passed in to control header/body/footer display; shows a spinner instead. */
  @Input() public isLoading = false;
  /** Shows overlay when submit pending. *Default `false`, only works when isSubmitPending set to true.
   * TODO: Remove this one-time-use property, *or*
   * rename `isSubmitPendingWithOverlay` as it's not used according to above *or*
   * refactor to let this work stand alone from `isSubmitPending`
   */
  @Input() public isPendingWithOverlay = false;
  /** Can be passed in to prevent submissions until true. */
  @Input() public isSubmitDisabled = false;
  /** Shows a loading state on the submit button while true. */
  @Input() public isSubmitPending = false;
  /** What needs to be passed back when the modal closes, if any. */
  @Input() public item?: any;
  /** Dgat for the default submit button. */
  @Input() public submitButtonDgat? = 'simple-modal-c67';
  /** Type of dfButton to use for the submit button. *Default `primary`.* */
  @Input() public submitButtonType?: ButtonType = 'primary';
  /** Translated text to display on the submit button. *Default `Submit`.* */
  @Input() public submitButtonText? =
    this.translateService.instant('Core_Submit');
  /** Translated text to be read aloud by the submit button, giving further context to the button's action. *Default undefined.* */
  @Input() public submitButtonAriaLabel: string = undefined;
  /** Whether to wrap with a <form> element and let this modal handle onSubmit, or to handle it in the parent. */
  @Input() public useDefaultForm = true;
  /** Whether or not to use the default submit button. *Default `true`.* If false, be sure to supply a new submit button with your custom content. */
  @Input() public useDefaultSubmitButton = true;

  /** Optional title in the modal header. */
  @Input() public modalHeaderTitle? = '';
  /** If set, display meta in the header below the <h1> element. */
  @Input() public headingMetaConfig?: HeadingMetaOption[];

  /** Optional unique id for aria-labelledby on modal.  Primarily needed for modals missing default header title.  Defaults to a label with generated unique id matching in modal-header.component. */
  @Input() public headerLabelId?;

  // - Output
  /** Pass in to handle dismissal yourself. */
  @Output() public dismiss = new EventEmitter<any>();

  // - Local
  /** Passed to the dgx-modal-header component to set a unique id on the header to use with aria-labelledby.  Using the unique id allows stacking modals to generate independent ids. */
  public headerLabelUID = `modal-header-label-${generateCustomId()}`;

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    if (!this.headerLabelId) {
      this.headerLabelId = this.headerLabelUID;
    }
  }

  /**
   * Close modal and send data back.
   *
   * event - For tracking clicks.
   */
  public onSubmit(event: Event) {
    // prevent submits on a given condition
    if (this.isSubmitDisabled || !this.useDefaultSubmitButton) {
      return;
    }
    // pass event directly to handleClose
    if (this.closeOnSubmit) {
      return this.onDismiss(event);
    }
    // prevent default action
    // (ngSubmit does these two things *for us*
    // when no $event is passed in, but we want
    // to make the events available for tracking)
    event.preventDefault();
    event.stopPropagation();
    // `close` emits `next`, passing
    // any content back to parent.
    this.activeModal.close(this.item);
  }

  /**
   * Close modal.
   *
   * event - For tracking clicks.
   */
  public onDismiss(event: Event): void {
    // prevent default action
    event.preventDefault();
    event.stopPropagation();
    // pass dismiss event to parent, if set
    if (this.dismiss?.observers.length) {
      this.dismiss.emit(event);
      return;
    }
    // `dismiss` emits `complete`
    this.activeModal.dismiss();
  }
}
