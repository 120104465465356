import { Injectable } from '@angular/core';
import { InputsService } from '@app/inputs/services/inputs.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CatalogSearchQueryOpts } from '../components/catalog-search-query-opts';
import { InputDetails, LearningInputModel } from '@app/inputs/inputs-api.model';
import { from, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TagsApi } from '@app/tags/tag-api.model';
import { UserInputModalService } from '@app/inputs/services/user-input-modal.service';
import { LocalizationService } from '@app/shared/localization.service';

// Misc
import { DgError } from '@app/shared/models/dg-error';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InputType } from '@app/shared/models/core-api.model';
import { FacetValue } from '@app/shared/models/core-api.model';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { sortFacet } from '../utils/catalog.utils';

export const enum SortByValues {
  None = 'none',
  DateUpdated = 'dateUpdated',
  DateCreated = 'dateCreated',
  Completions = 'completions',
  Recommendations = 'recommendations',
  Takeaways = 'takeaways',
  NetRating = 'netRating',
  Views = 'views',
}

export enum AssociationType {
  Pathway = 'Pathway',
  SkillPlan = 'SkillPlan',
  Recommendation = 'Recommendation', // AKA Share
}

export type Association = {
  id: number;
  type: InputType;
  name?: string; // This is only present for non-share associations
  url?: string; // This is only present for non-share associations
};

export type AssociationData = {
  associations?: Association[];
  count?: number;
  type?: AssociationType;
};

@Injectable({
  providedIn: 'root',
})
export class ContentCatalogService extends ApiServiceBase {
  private _catalogId: number;
  private authUser = this.authService.authUser;
  private readonly i18n = this.translate.instant([
    'Core_GeneralErrorMessage',
    'ContentCatalogSvc.ProviderError',
    'ContentCatalogSvc.CoursesError',
    'OrgInternalContentCtrl_ContentTitle',
    'OrgInternalContentCtrl_ContentOwner',
    'OrgInternalContentCtrl_Ratings',
    'OrgInternalContentCtrl_DialogConfirmClearCategories',
    'OrgInternalContentCtrl_ClearCategories',
    'OrgInternalContentCtrl_SearchErrorMessage',
    'OrgInternalContentCtrl_Recommend',
    'OrgInternalContentCtrl_Edit',
    'OrgInternalContentCtrl_AddToPathway',
    'OrgInternalContentCtrl_AddTopics',
    'OrgInternalContentCtrl_AddGroups',
    'OrgInternalContentCtrl_ViewInGoals',
    'OrgInternalContentCtrl_SetGoal',
    'OrgInternalContentCtrl_CourseProperties',
    'OrgInternalContentCtrl_Delete',
    'OrgInternalContentCtrl_FlagAsInappropriate',
    'OrgInternalContentCtrl_ReportAProblem',
    'OrgInternalContentCtrl_MoreDetails',
    'OrgInternalContentCtrl_GenericError',
    'OrgInternalContentCtrl_ReSyncDetails',
    'OrgInternalContentCtrl_AssessmentUnavailableError',
    'OrgInternalContentCtrl_SearchCatalog',
    'OrgInternalContentCtrl_InputMetricCompletions',
    'OrgInternalContentCtrl_InputMetricViews',
    'OrgInternalContentCtrl_InputMetricRecommendations',
    'OrgInternalContentCtrl_InputMetricTakeaways',
    'OrgInternalContentCtrl_InputMetricDateAdded',
    'OrgInternalContentCtrl_InputMetricDateUpdated',
    'OrgInternalContentCtrl_InputMetricRating',
    'dgOrgInternalContent_CategoriesTitle',
    'dgOrgInternalContent_CategoriesToolTip',
    'dgOrgInternalContent_GroupsTitle',
    'dgOrgInternalContent_GroupsToolTip',
    'dgOrgInternalContent_GenerateCmsReport',
    'dgOrgInternalContentForm_ManageCredSparkAccount',
    'OrgPathwayManagerCtrl_ItemsSelected',
    'Core_CheckboxAll',
    'Core_Select',
    'dgOrgInternalContent_BulkEdit',
    'Core_Cancel',
    'Core_AddToBundle',
  ]);

  constructor(
    http: NgxHttpClient,
    private authService: AuthService,
    private inputService: InputsService,
    private translate: TranslateService,
    private userInputModalService: UserInputModalService,
    private displayType: DisplayTypePipe,
    private localizationService: LocalizationService
  ) {
    super(http, translate.instant('ContentCatalogSvc.CoursesError'));
  }

  public get catalogId(): number {
    return this._catalogId;
  }
  public set catalogId(catalogId: number) {
    this._catalogId = catalogId;
  }

  public searchCatalog(
    opts: CatalogSearchQueryOpts
  ): Observable<LearningInputModel> {
    return this.inputService.find(opts);
  }

  public bulkEditTags(inputs: InputDetails[], tags: any) {
    return from(inputs).pipe(
      mergeMap((item: InputDetails) => {
        if (!item.tags || item.tags.length === 0) {
          item.tags = tags;
          return of(item);
        }
        // InputDetails tags are in string format so lets get tag objects and merge with new tags
        return this.userInputModalService
          .getUserInputTags({
            inputType: item.inputType,
            userInputId: item.userInputId,
            inputId: item.inputId,
          })
          .pipe(
            map((userInputTags: TagsApi.Tag[]) => {
              // Merge existing tags with new tags
              tags.forEach((newTag) => {
                userInputTags.push(newTag);
              });
              item.tags = userInputTags;
              return item;
            })
          );
      })
    );
  }

  public getResourceAssociations(
    inputs: InputDetails[]
  ): Observable<AssociationData[]> {
    const orgId = this.authUser?.orgInfo[0].organizationId;
    const inputIdentifiers = [];
    let i;
    for (i = 0; i < inputs.length; i++) {
      inputIdentifiers.push({
        InputType: inputs[i].inputType,
        InputId: inputs[i].inputId,
      });
    }
    const stringifyJsonInputs = JSON.stringify(inputIdentifiers);
    const url = '/organizations/getresourceassociations';
    return this.http
      .get<AssociationData[]>(url, {
        params: { orgId: orgId, inputsJson: stringifyJsonInputs },
      })
      .pipe(
        catchError((error) => {
          return throwError(
            new DgError(this.i18n.Core_GeneralErrorMessage, error)
          );
        })
      );
  }

  // This translates and sort the facet values
  public translateAndSortFacetValues(facetValues: FacetValue[]): FacetValue[] {
    const translatedFacetValues = facetValues.map((value) => {
      return {
        ...value,
        name: this.displayType.transform(value.name),
      };
    });
    return sortFacet(
      translatedFacetValues,
      this.localizationService.userLocale
    );
  }
}
