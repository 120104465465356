<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!articleCatalogForm"
    #formRef="ngForm"
    class="reactive-forms"
    [formGroup]="articleCatalogForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.MediaFormCtrl_SaveArticle
      "
      [canCancel]="true"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      bodyClasses="guts-p-h-2 guts-p-v-2"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <!-- Created By -->
        <div
          *ngIf="articleCatalogForm.get('createdBy')?.value"
          class="reactive-forms__control reactive-forms__read-only"
        >
          <input
            class="par par--small"
            formControlName="createdBy"
            readonly="true"
            id="createdBy"
            data-dgat="article-catalog-6c6"
          />
        </div>

        <!-- URL input-->
        <div
          *ngIf="
            articleCatalogForm.get('entryUrl') &&
            !articleCatalogForm.get('contentUploader')?.value
          "
          class="reactive-forms__control"
        >
          <dgx-reactive-forms-label
            for="entryUrl"
            [isRequired]="!vm.isInitialForm"
          >
            <!--Only display the required text on full form page -->
            {{ i18n.MediaFormCtrl_ArticleUrl }}
          </dgx-reactive-forms-label>
          <input
            #entryUrl
            autocomplete="off"
            type="url"
            id="entryUrl"
            formControlName="entryUrl"
            data-dgat="articleForm-97a"
            placeholder="http://"
            [dgxAutofocus]="true"
          />
          <!-- Note http is the same for all countries -->
          <dgx-check-duplicate-inputs
            *ngIf="vm.duplicateCount"
            [duplicateCount]="vm.duplicateCount"
            (viewDuplicates)="openViewDuplicates()"
          >
          </dgx-check-duplicate-inputs>
          <dgx-validate-field
            class="block"
            *ngIf="validateEntryUrlError()"
            [message]="getUrlErrorMessage"
          >
          </dgx-validate-field>
        </div>
        <!-- Hosted Content uploader -->
        <div
          *ngIf="
            articleCatalogForm.get('contentUploader') &&
            articleCatalogForm.get('contentUploader')?.value !== null
          "
        >
          <!-- Only display the or if we are on the initial page  -->
          <div
            class="divider guts-m-t-1 guts-m-b-2"
            *ngIf="
              vm.isInitialForm &&
              !articleCatalogForm.get('contentUploader').value
            "
          >
            <span
              class="par par--small par--light up-me bg-color-white divider__content"
              >{{ i18n.Core_Or }}</span
            >
          </div>
          <dgx-upload-section
            [displayfileDocumentation]="vm.isInitialForm ? true : false"
            [shouldDisableContentUploader]="vm.shouldDisableContentUploader"
            [inputType]="vm.inputType"
            [showReplaceButton]="vm.isInitialForm ? false : true"
            [fileInfo]="vm.file"
            [useCropper]="false"
            [uploadAdapter]="vm.uploadAdapter"
            [fileRestrictions]="vm.fileRestrictions"
            [showDeleteButton]="vm.isInitialForm ? true : false"
            [errorMessages]="vm.errorMessages"
            [labels]="{ header: i18n.dgContentHosting_DragAndDrop }"
            icon="arrow-up-tray"
            (fileChangedEvent)="vm.contentUpload.onContentFileChange($event)"
            (uploadSuccessEvent)="
              vm.contentUpload.onContentUploadSuccess(
                articleCatalogForm,
                'contentUploader',
                $event
              )
            "
            (deleteEvent)="vm.contentUpload.onContentDelete(articleCatalogForm)"
            (errorEvent)="vm.contentUpload.onContentUploadFailure()"
          >
          </dgx-upload-section>
        </div>
        <!-- EXPANDED FORM-->
        <ng-container *ngIf="showExpandedForm">
          <!-- Title -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [placeholder]="i18n.dgFlexRow_EditSectionTitlePlaceholder"
              [dgxAutofocus]="!!vm.hostedContentDetails"
              [attr.aria-describedby]="
                i18n.dgFlexRow_EditSectionTitlePlaceholder
              "
              (blur)="
                vm.loadInferredSkills(
                  articleCatalogForm.get('title').value,
                  articleCatalogForm.get('description').value
                )
              "
              data-dgat="article-catalog-200"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                articleCatalogForm.get('title').touched &&
                articleCatalogForm.get('title').invalid
              "
              [message]="i18n.MediaFormCtrl_TitleRequired"
            ></dgx-validate-field>
          </div>

          <!-- Source -->
          <ng-container *ngIf="vm.sourceName">
            <div class="reactive-forms__control reactive-forms__read-only">
              <dgx-reactive-forms-label for="sourceName">
                {{ i18n.Core_Source }}
              </dgx-reactive-forms-label>
              <input
                formControlName="sourceName"
                readonly="true"
                id="sourceName"
                data-dgat="article-catalog-31c"
              />
            </div>
          </ng-container>

          <!-- format -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="format">
              {{ i18n.dgOrgInternalContentForm_ArticleFormat }}
            </dgx-reactive-forms-label>
            <dgx-select
              class="full-width"
              trackBy="title"
              labelKey="title"
              dgatInput="article-catalog-form-6fb"
              [selectedItem]="{ title: vm.format }"
              [options]="vm.inputTypeFormats"
              [ariaLabel]="i18n.dgOrgInternalContentForm_ArticleFormat"
              [placeholder]="
                i18n.dgOrgInternalContentForm_ArticleFormatSelectPlaceholder
              "
              (selection)="onFormControlUpdate('format', $event.title)"
            >
            </dgx-select>
          </div>
          <!-- Description  -->
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="description">
              {{ i18n.Core_Description }}
            </dgx-reactive-forms-label>
            <textarea
              formControlName="description"
              [attr.maxlength]="3000"
              [attr.aria-label]="
                i18n.dgOrgInternalContentForm_ArticleDescriptionPlaceholder
              "
              id="description"
              data-dgat="article-catalog-1a4"
              [placeholder]="
                i18n.dgOrgInternalContentForm_ArticleDescriptionPlaceholder
              "
              (blur)="
                vm.loadInferredSkills(
                  articleCatalogForm.get('title').value,
                  articleCatalogForm.get('description').value
                )
              "
            ></textarea>
          </div>
          <!-- Duration -->
          <div class="reactive-forms__control">
            <dgx-duration
              [formModel]="{
                durationHours: vm.durationHours,
                durationMinutes: vm.durationMinutes,
              }"
            ></dgx-duration>
          </div>

          <!-- Content Owner -->
          <div class="form-wrap__group">
            <dgx-reactive-forms-label
              for="owner"
              icon="info"
              [isRequired]="true"
              [iconTooltip]="i18n.dgOrgInternalContentForm_ContentOwnerTooltip"
              [iconA11yText]="i18n.Core_MoreInfo"
            >
              {{ i18n.dgOrgInternalContentForm_ContentOwner }}
            </dgx-reactive-forms-label>
            <dgx-user-search
              id="owner"
              [useSearchField]="false"
              [recipients]="!!vm.owner ? [vm.owner] : []"
              [search]="userGroupListService.loadGroupsUsersRecommendees"
              [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
              [allowRemoval]="true"
              (removeRecipient)="onContentOwnerChange(undefined)"
              (selectRecipient)="onContentOwnerChange($event)"
              [placeholder]="
                i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder
              "
            ></dgx-user-search>
            <dgx-validate-field
              class="block"
              *ngIf="
                articleCatalogForm.get('owner').touched &&
                articleCatalogForm.get('owner').invalid
              "
              [message]="
                articleCatalogForm
                  .get('owner')
                  .hasError('validContentOwnerNeeded')
                  ? i18n.MediaFormCtrl_ValidContentOwnerRequired
                  : i18n.dgOrgInternalContentForm_ContentOwnerRequired
              "
            ></dgx-validate-field>
          </div>

          <!-- Image-->
          <div class="reactive-forms__control">
            <dgx-upload-section
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.imageUploadAdapter"
              (imageParsedEvent)="vm.imageUpload.onParse($event)"
              (uploadSuccessEvent)="
                vm.imageUpload.onUpload(articleCatalogForm, $event)
              "
              (deleteEvent)="vm.imageUpload.onDelete(articleCatalogForm)"
              [useCropper]="true"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [useBrowserReplace]="true"
            >
            </dgx-upload-section>
          </div>
          <!-- Skills-->
          <div class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.mediumConfidenceInferredSkills"
              [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="
                vm.highConfidenceInferredSkills
                  ? i18n.dgOrgInternalContent_SkillsPlaceholderText
                  : ''
              "
              [allowExistingOnly]="false"
              [tags]="articleCatalogForm.get('skills').value"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              [useSkillProficiencyLevels]="useSkillProficiencyLevels"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                articleCatalogForm.get('skills').touched &&
                articleCatalogForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>
          <!-- Advanced -->
          <div>
            <dgx-advanced-settings
              [formModel]="{
                visibility: vm.orgContentMetadata,
                publishedDate: vm.publishDate,
                internalItemId: vm.externalId,
                language: vm.language,
                isFileManaged: vm.fileManaged,
              }"
            ></dgx-advanced-settings>
          </div>
        </ng-container>
      </ng-container>
      <!-- Modal footer -->
      <ng-container class="modal-footer"> </ng-container>
    </dgx-modal>
  </form>
</ng-container>
