import { Injectable } from '@angular/core';
import { LanguageLocale } from '@app/inputs/inputs.model';
import { LanguageInfo } from '@app/inputs/inputs-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { forkJoin, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

/**
 * @name Language Service
 *
 * Provides lists of supported languages for the Degreed application and content
 *
 * NOTE: the Observable results of fetching the languages are cached locally for later use, and to
 * prevent duplicate in-flight requests
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languages$: Observable<LanguageLocale[]>;
  private systemLanguages$: Observable<LanguageLocale[]>;

  constructor(private http: NgxHttpClient) {}

  /* Gets the list of the languages available for content within Degreed */
  public getAllLanguages(): Observable<LanguageLocale[]> {
    if (!this.languages$) {
      this.languages$ = this.getLanguage(
        '/inputs/GetSupportedContentLanguages'
      );
    }
    return this.languages$;
  }

  /* Gets a specific language from the list of available languages for content within Degreed */
  public getLanguageDetail(localeId?: string): Observable<LanguageLocale> {
    if (!localeId) return of(undefined);

    return this.getAllLanguages().pipe(
      map((languages) => this.findByLocaleId(languages, localeId))
    );
  }

  /* Gets the list of the languages available for the Degreed Application itself */
  public getSystemLanguages(): Observable<LanguageLocale[]> {
    if (!this.systemLanguages$) {
      this.systemLanguages$ = this.getLanguage(
        '/inputs/GetSupportedApplicationLanguages'
      );
    }
    return this.systemLanguages$;
  }

  /* Gets a specific language from the list of available languages for the Degreed Application */
  public getSystemLanguageDetail(
    localeId?: string
  ): Observable<LanguageLocale> {
    if (!localeId) return of(undefined);

    return this.getSystemLanguages().pipe(
      map((languages) => this.findByLocaleId(languages, localeId))
    );
  }

  public getContentAndSystemLanguageDetail(
    contentLocaleId: string,
    systemLocaleId: string
  ): Observable<{
    contentLanguage: LanguageLocale;
    systemLanguage: LanguageLocale;
  }> {
    const getLanguageDetail$ = this.getLanguageDetail(contentLocaleId);
    const getSystemLanguageDetail$ =
      this.getSystemLanguageDetail(systemLocaleId);

    return forkJoin([getLanguageDetail$, getSystemLanguageDetail$]).pipe(
      map((results) => {
        const [contentLanguage, systemLanguage] = results;
        return { contentLanguage, systemLanguage };
      })
    );
  }

  public getContentLanguagesDetails(
    contentLanguages: string[]
  ): Observable<LanguageLocale[]> {
    if (contentLanguages.length === 0) {
      return of([]);
    }

    const getLanguageDetailsObservables = contentLanguages.map((id) =>
      this.getLanguageDetail(id)
    );

    return forkJoin(getLanguageDetailsObservables);
  }

  /**
   * Fetches a list of supported languages, mapping them to language details, and capturing the
   * result as a replay subject for later calls
   */
  private getLanguage(endpoint: string): Observable<LanguageLocale[]> {
    return this.http.get<LanguageInfo[]>(endpoint).pipe(
      map((supportedLanguages) => {
        return supportedLanguages.map((lang) => ({
          name: lang.nativeName,
          lcid: lang.language,
        }));
      }),
      // NOTE: the shareReplay is needed here to prevent subsequent http calls
      shareReplay(1)
    );
  }

  private findByLocaleId(
    languages: LanguageLocale[],
    localeId: string
  ): LanguageLocale {
    return languages.find(
      (language) => language.lcid.localeCompare(localeId) === 0
    );
  }
}
