<div class="l_flex-full guts-p-full-1-half">
  <ul class="grid">
    <li
      class="grid__col-xs-4 guts-p-full-half"
      *ngFor="let input of inputTypes"
    >
      <button
        class="bg-color-ebony-a03 rounded l_flex l_flex--inline l_flex-column l_flex-middle full-width guts-p-v-1-half color-blue"
        (click)="showAddInputForm($event, input.type)"
        data-dgat="addManually-5af"
      >
        <df-icon [icon]="input.icon" size="large"></df-icon>
        <div class="par par--small color-blue guts-p-t-half">
          {{ input.type | displayType }}
        </div>
      </button>
    </li>
  </ul>
</div>
<div
  *ngIf="savedItems.length > 0 || loadingContentItems"
  class="guts-p-b-2 guts-p-h-2"
>
  <h2
    class="h4 par--light guts-p-t-2 guts-p-b-half"
    *ngIf="savedItems.length !== 0"
  >
    {{ 'TargetResourcesForm_Selected' | translate }}
  </h2>
  <df-spinner *ngIf="loadingContentItems" class="guts-m-r-1"></df-spinner>
  <div
    *ngFor="let saved of savedItems"
    data-dgat="addManually-f90"
    class="border-bottom not-last"
  >
    <dgx-resource-result
      [item]="saved"
      (remove)="removeFromSavedList($event)"
    ></dgx-resource-result>
  </div>
</div>

<dgx-modal-footer
  *ngIf="!isLoading"
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  (dismiss)="dismiss.emit($event)"
>
  <button
    df-button
    dfButtonType="passive"
    (click)="onHoldForLater()"
    [disabled]="savedItems.length === 0"
    class="guts-m-r-1"
    data-dgat="addManually-1a3"
  >
    {{ 'Pathways_HoldForLater' | translate }}
  </button>
  <button
    df-button
    dfButtonType="primary"
    (click)="onAddItems()"
    [disabled]="savedItems.length === 0 || isSubmitting"
    [class.is_disabled]="savedItems.length === 0 || isSubmitting"
    [attr.aria-disabled]="savedItems.length === 0 || isSubmitting"
    [attr.aria-label]="isSubmitting ? i18n.Core_Loading : addItemsButton"
    data-dgat="addManually-4b8"
  >
    <df-spinner-button [isSpinning]="isSubmitting">
      {{ addItemsButton }}
    </df-spinner-button>
  </button>
</dgx-modal-footer>
