<div
  class="action-options__toggle-wrapper l_flex m-l_flex"
  data-dgprop-item-clicked="Thumbs"
>
  <button
    type="button"
    class="action-options__toggle-button"
    (click)="toggleItem($event, 'like')"
    [ngClass]="{
      'color-blue': liked,
      'action-options__toggle-button--lg': size === 'large'
    }"
    data-dgat="thumbs-1e1"
    attr.aria-label="{{ i18n.dgThumbs_Like }} {{ inputTitle }}"
    [ngbTooltip]="likeTooltipText"
    [openDelay]="1000"
  >
    <span class="a11y-hide">{{
      liked ? i18n.dgThumbs_RemoveLike : i18n.dgThumbs_Like
    }}</span>
    <df-icon icon="thumbs-up"></df-icon>
  </button>
  <button
    type="button"
    class="action-options__toggle-button"
    (click)="toggleItem($event, 'dislike')"
    [ngClass]="{
      'color-blue': disliked,
      'action-options__toggle-button--lg': size === 'large'
    }"
    data-dgat="thumbs-f05"
    attr.aria-label="{{ i18n.dgThumbs_Dislike }} {{ inputTitle }}"
    [ngbTooltip]="dislikeTooltipText"
    [openDelay]="1000"
  >
    <span class="a11y-hide">{{
      disliked ? i18n.dgThumbs_RemoveDislike : i18n.dgThumbs_Dislike
    }}</span>
    <df-icon icon="thumbs-down"></df-icon>
  </button>
</div>
