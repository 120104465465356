<form class="form-wrap">
  <dgx-modal
    [canCancel]="true"
    [item]="notifyCandidates"
    [submitButtonText]="submitButtonText"
    [bodyStyle]="{ 'max-height': '420px' }"
    bodyClasses="max-height-420"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">{{ headerText }}</ng-container>

    <!-- Modal Body -->
    <ng-container class="modal-body">
      <p class="guts-p-h-2 guts-p-v-1 par">{{ bodyText }}</p>

      <div class="l_flex guts-p-h-2 guts-p-b-1">
        <input
          [checked]="notifyCandidates"
          (change)="notifyCandidates = !notifyCandidates"
          type="checkbox"
          class="guts-m-r-1 guts-m-t-quart"
          data-dgat="close-opportunities-modal-ff8"
        />
        <p class="par par--light par--small">{{ notifyText }}</p>
      </div>

      <p *ngIf="secondaryBodyText" class="guts-p-h-2 par par--light par--small">
        {{ secondaryBodyText }}
      </p>
    </ng-container>
  </dgx-modal>
</form>
