export interface WebEnvironmentInfo {
  cacheBuster: string;
  blobBaseUrl: string;
  honorStaticBlobUrls: boolean;
  cookieSuffix: string;
  analytics: AnalyticsConfiguration;
  authCookieName: string;
  xsrfTokenName: string;
  xsrfTokenNameVNext: string;
  clientSideFeatureFlagSdkKey: string;
  environment: string;
  featureFlagOverrides: {
    proxyUrl: string;
    eventProxyUrl: string;
  };
}

export interface XSRFCache {
  lastCookieString: string;
  lastToken: string | null;
}

export interface AnalyticsConfiguration {
  cookieName: string;
  url: string;
  anonymousUserId: string;
  appLocation: AnalyticsAppLocation;
}

export type AnalyticsAppLocation =
  | 'Manage Broken Links Page' // /orgs/:id/content/manage-broken-links
  | 'Career Mobility Marketplace' // /career/opportunities
  | 'Career Mobility Landing Page' // /career/opportunities/browse/ (table view)
  | 'Catalog' // /channel/catalog
  | 'Content Hosting Viewer' // Page for viewing Azure hosted content - Example Url: /articles/workforce?d=77019347&view=true&contentSource=CMS&inputType=Article
  | 'Feed'
  | 'Plan' // /plan/:id = (plan), /explore/:id = (directory),
  | 'Plan Insights' // /plan/insights/:node
  | 'Featured Plan' // /explore/:id (plan or directory that is featured)
  | 'Group' // /groups/:id
  | 'Learner Home' // /:user/dashboard
  | 'Learner Hub' // /:id/learnerhub/home
  | 'Manage Org Catalog' // /orgs/:id/content
  | 'Manage Org Plans' // /orgs/:id/plans
  | 'Insight Cards' // /orgs/:id/settings/home/insights
  | 'Links Section' // /orgs/:id/settings/home/links
  | 'Org Announcement' // /orgs/:id/settings/home/announcement
  | 'Notifications' // /notifications
  | 'Organization'
  | 'Onboarding'
  | 'Opportunities Card View' // /career/opportunities/browse/:id
  | 'Opportunity Details Discover' // /opportunities/:id/interested
  | 'Opportunity Details Interested' // /opportunities/:id/interested
  | 'Opportunity Details Overview' // /opportunities/:id
  | 'Opportunity Mentoring' // /career/opportunities/mentoring
  | 'Pathway' // /pathway/:id/pathway
  | 'Pathway Followers' // /pathway/:id/enrollees
  | 'Pathway Insights' // /pathway/:id/insights
  | 'Profile' // /profile/:user/overview
  | 'Profile Assignments'
  | 'Profile Collection' // /profile/:user/collection
  | 'Profile Shared' // /profile/:user/shared
  | 'Profile Insights' // /profile/:user/insights
  | 'Search'
  | 'Search Marketplace'
  | 'Search Marketplace Featured Items'
  | 'Skill'
  | 'Skill Coach'
  | 'Skill Review'
  | 'Skill Review Endorsement'
  | 'Skill Standards';

export enum ApplicationId {
  LXP = 'dgx_web_app_id',
  TeamsApp = 'ms_teams_app_id',
  Extension = 'ebb_app_id',
}
