<button
  type="button"
  class="left-text"
  attr.aria-expanded="{{ isOpen }}"
  id="js-animate-toggle-permission-trigger-{{ uid }}"
  attr.aria-controls="js-animate-toggle-permission-panel-{{ uid }}"
  data-dgat="toggleElement-d30"
  tabindex="0"
  (click)="toggle()"
>
  <span class="font-regular h3">{{ buttonText }}</span>
  <df-icon
    [icon]="isOpen ? 'chevron-up' : 'chevron-down'"
    size="small"
    class="color-ebony-a61 guts-m-l-quart"
  ></df-icon>
</button>
<div
  id="js-animate-toggle-permission-panel-{{ uid }}"
  attr.aria-labelledby="js-animate-toggle-permission-trigger-{{ uid }}"
  aria-live="polite"
  role="region"
  class="rel oh"
>
  <div *ngIf="isOpen" [@dfCollapseExpand]>
    <ng-content></ng-content>
  </div>
</div>
