import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ConfigurationValue } from '@app/orgs/configuration-value';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { OrgSetting } from '@app/orgs/services/orgs.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { AuthService } from '@app/shared/services/auth.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { groupBy } from '@app/shared/utils/common-utils';
import { TranslateService } from '@ngx-translate/core';
import { SettingsTabComponents } from './../org-settings-nav/org-settings-nav.component';

@Component({
  selector: 'dgx-org-settings',
  templateUrl: './org-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgSettingsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public orgId: number;
  @Input() public configurationValue: ConfigurationValue;
  @Input() public headingDescription: string;
  @Input() public headingName: string;
  @Input() public isMessageSettings: boolean = false;
  @Output() public settingToggled = new EventEmitter();
  public i18n = this.translate.instant(['Core_LearnMore']);
  public isLoading: boolean = false;
  public settings: { [key: string]: OrgSetting[] };
  public canViewKnowledgeCenter: boolean = false;

  public readonly CLASS_NAME = SettingsTabComponents.OrgSettingsComponent;

  constructor(
    private translate: TranslateService,
    private AuthService: AuthService,
    private OrgSettingsService: OrgSettingsService,
    private webEnvironmentService: WebEnvironmentService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public get helpLinkUrl() {
    return this.webEnvironmentService.getZendeskUrl('/sections/360009918274');
  }

  public ngOnInit(): void {
    this.AuthService.authUser$
      .pipe(this.takeUntilDestroyed())
      .subscribe((u) => {
        // hack for now, but we shouldn't show the KC link for the messaging tab.  When this component was originally written, it was only used for the communications tab,
        // and the KC link is hardcoded to a communication related article.  We need a better way of handling different KC links for different settings.
        this.canViewKnowledgeCenter =
          u.defaultOrgInfo.permissions.viewKnowledgeCenter &&
          !this.isMessageSettings;
      });

    this.OrgSettingsService.getOrgSettings(
      this.orgId,
      this.configurationValue
    ).subscribe((settings: OrgSetting[]) => {
      this.settings = groupBy(settings, 'configurationCategory');
      this.cdr.markForCheck();
    });
  }

  public getGroupDescription(key): string {
    return this.OrgSettingsService.getGroupDescription(key);
  }

  public getGroupName(key): string {
    return this.OrgSettingsService.getGroupName(key);
  }

  public isGroupedSetting(
    configurationCategory: string,
    settings: OrgSetting[]
  ): boolean {
    return this.OrgSettingsService.isGroupedSetting(
      configurationCategory,
      settings
    );
  }

  public onToggleSetting(setting: OrgSetting): void {
    this.OrgSettingsService.toggleSetting(
      this.orgId,
      setting,
      this.configurationValue
    ).subscribe(() => {
      this.settingToggled.emit(setting);
      this.cdr.markForCheck();
    });
  }
}
