import { PathwayBinItem } from '@app/pathways/rsm/pathway-api.model';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { AddModalResults } from '@app/pathways/rsm/pathway-api.model';
import {
  PathwayFacade,
  PathwayAuthoring,
  PathwayViewModel,
} from '@dg/pathways-rsm';
import { updateBinButtonText } from '@app/pathways/rsm/utils';

@Component({
  selector: 'dgx-hold-for-later',
  templateUrl: './hold-for-later.component.html',
})
export class HoldForLaterComponent implements OnInit {
  public static readonly modalName = 'bin';
  @Input() public savedSearch?: LearningResourceViewModel[];
  @Input() public savedManual?: LearningResourceViewModel[];
  @Input() public isSubmitting: boolean;

  @Output() public addItems = new EventEmitter<AddModalResults>();
  @Output() public dismiss = new EventEmitter<Event>();

  public itemBin: PathwayBinItem[] = [];
  public isRemoving: boolean = false;
  public selectedItems: PathwayBinItem[] = [];
  public selectedNodes: string[] = [];

  public addItemsButton = this.translate.instant('Pathways_AddXItems', {
    count: '',
  });

  public i18n = this.translate.instant([
    'Pathways_EmptyBinMessage',
    'Core_Loading',
  ]);
  public vm$: Observable<PathwayViewModel>;

  constructor(
    private translate: TranslateService,
    private facade: PathwayFacade,
    private authoring: PathwayAuthoring
  ) {}

  public get isSubmitDisabled(): boolean {
    return this.selectedItems.length === 0 || this.isRemoving;
  }

  public ngOnInit(): void {
    this.vm$ = this.facade.vm$;
  }

  public async removeItemFromBin(item: PathwayBinItem) {
    this.isRemoving = true;

    const success = await this.authoring.removeFromBin(item);

    if (success) {
      this.updateBinText();
    }
    this.isRemoving = false;
  }

  public async toggleSelectedItem(item, isSelected) {
    const success = await this.authoring.updateBinSelection(item, isSelected);
    if (success) {
      this.updateBinText();
    }
  }

  public addContent = () => {
    this.selectedNodes = this.selectedItems?.map((item) => item.node);
    this.addItems.emit({
      selectedSearch: this.savedSearch,
      selectedManual: this.savedManual,
      // This conversion is icky the savedItems are type LearningResourceViewModel
      // for this it is save since the only thing that matters here is the length
      savedItems: this.selectedItems as any[],
      selectedNodes: this.selectedNodes,
      isBin: true,
      whichModal: HoldForLaterComponent.modalName,
    });
  };

  /**
   * Update the text count of the selected bin items for the add content submit button
   */
  private updateBinText() {
    this.selectedItems = this.facade.snapshot.itemBin.filter(
      (item) => item.isSelected
    );
    this.addItemsButton = updateBinButtonText(
      this.selectedItems,
      this.translate
    );
  }
}
