import { PathwayDetailsModel } from '@app/pathways/rsm/pathway-api.model';
import { TrackingEventArgs } from '@app/shared/services';
import { ActionType, PathwayActivation, PathwayEvent } from '../pathway.model';

export interface ActivationTrackData {
  elementId: string;
  trackData: TrackingEventArgs;
}

// ******************************************
// Event Actions
// ******************************************

export const enum ActionsList {
  ACTIVATE = 'activatePathwaySection',
  REFRESH = 'refreshPathway',
}

export const ACTIONS = {
  activateNav: (type: ActionType, section: number, subsection?: number) =>
    new PathwayEvent(ActionsList.ACTIVATE, {
      type,
      section,
      subsection,
    }),
  refreshPathway: () => new PathwayEvent(ActionsList.REFRESH, {}),
};

// ******************************************
// Activation Utils
// ******************************************

export function activationForSection(
  pathway: PathwayDetailsModel,
  activations: Partial<PathwayActivation>
): ActivationTrackData {
  const elementId = `section-${activations.section}`;
  const trackData = {
    action: 'Pathway Outline Section Navigated',
    properties: {
      pathwayId: pathway.id,
      pathwayName: pathway.title,
      sectionId: activations.section,
    },
  };

  return { elementId, trackData };
}

export function activationForSubsection(
  pathway: PathwayDetailsModel,
  activations: Partial<PathwayActivation>
): ActivationTrackData {
  const elementId = `section-${activations.section}-${activations.subsection}`;
  const trackData = {
    action: 'Pathway Outline Subsection Navigated',
    properties: {
      pathwayId: pathway.id,
      pathwayName: pathway.title,
      sectionId: `${activations.section}.${activations.subsection}`,
    },
  };

  return { elementId, trackData };
}
