import { Injectable } from '@angular/core';
import { UserEvent } from '@app/inputs/inputs-api.model';
import { EventFormModel } from '../event-modal.facade';
import { EventApiEntity } from '../repository/user-event.entity.model';
import { Mapper } from '../../mapper.model';

@Injectable({ providedIn: 'root' })
export class PathwaysEventMapper
  implements Mapper<EventFormModel, EventApiEntity>
{
  /**
   * from api to initFromModel
   */
  public toViewModel(source: Partial<EventApiEntity>): Partial<EventFormModel> {
    return {
      inputId: source.inputId,
      title: source.name ?? '',
      fileManaged: source.fileManaged,
      eventUrl: source.eventUrl ?? '',
      summary: source.summary,
      tags: source.tags,
      addToCatalog: !source.orgContentMetadata?.hideFromCatalog,
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
    };
  }

  /**
   * From createResult to Api
   */
  public toApiParameters(source: EventFormModel): EventApiEntity {
    return {
      inputId: source.inputId ? source.inputId : source.eventId,
      eventId: source.inputId ? source.inputId : source.eventId,
      userEventId: source.userEventId,
      imageUrl: source.imageUrl,
      organizationId: source.organizationId,
      title: source.title,
      name: source.title,
      eventUrl: source.eventUrl,
      tags: source.tags,
      summary: source.summary,
      orgContentMetadata: {
        hideFromCatalog: !source.addToCatalog,
        groupIds: [],
      },
      durationHours: source.durationHours != 0 ? source.durationHours : null,
      durationMinutes:
        source.durationMinutes != 0 ? source.durationMinutes : null,
    };
  }
}
