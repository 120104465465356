import { Injectable, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
} from '@lib/fresco';

import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import {
  OrgInternalContentVisibilityFieldComponent,
  OrgInternalContentVisibilityFieldParams,
} from '@app/form-fields/wrappers/org-internal-content-visibility-field/org-internal-content-visibility-field.component';
import {
  ComboboxFieldComponent,
  ComboboxFieldOption,
  ComboboxFieldParams,
} from '@app/form-fields/wrappers/combobox-field/combobox-field.component';
import { LanguageService } from '@app/translation/services/language.service';
import { LanguageLocale } from '@app/inputs/inputs.model';
import { FieldWrapperBuilder } from './field-builder-factory';
import { GroupIdentifier } from '@app/groups/group-api';

export interface AdvancedFieldsetBuilderServiceOptions {
  groupIds?: AdvancedFieldsetBuilderServiceOption;
  owner?: AdvancedFieldsetBuilderServiceOption;
  publishDate?: AdvancedFieldsetBuilderServiceOption;
  externalId?: AdvancedFieldsetBuilderServiceOption;
  language?: AdvancedFieldsetBuilderServiceOption;
}

export interface AdvancedFieldsetBuilderServiceOption {
  isDisabled?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AdvancedFieldsetBuilderService implements FieldWrapperBuilder {
  private languages: ComboboxFieldOption[];
  constructor(
    private builder: DfFormFieldBuilder,
    private languageService: LanguageService
  ) {
    this.languageService
      .getAllLanguages()
      .subscribe((languages: LanguageLocale[]) => {
        this.languages = languages.map(({ name, lcid }) => {
          return {
            key: name,
            id: lcid,
          };
        });
      });
  }

  public build(context: {
    canRestrictContent?: boolean;
    isInitiallyVisibleToOrg: boolean;
    groups: GroupIdentifier[];
    organizationId: number;
    expand$: Observable<boolean>;
    expanderTemplate?: TemplateRef<DfFieldTemplateContext>;
    fieldOptions?: AdvancedFieldsetBuilderServiceOptions;
  }): DfFormFieldConfig {
    return this.builder
      .fieldGroup('', [
        this.builder
          .foreignField<OrgInternalContentVisibilityFieldParams>(
            'groupIds',
            'orgInternalContentVisibilitySelector_Title',
            OrgInternalContentVisibilityFieldComponent.REGISTERED_FIELD_TYPE,
            {
              isVisibleToOrg: context.isInitiallyVisibleToOrg,
              orgId: context.organizationId,
              groups: context.groups,
            }
          )
          .hiddenWhen(() => !context.canRestrictContent)
          .readonlyWhen(() => context.fieldOptions?.groupIds?.isDisabled)
          .build(),
        this.builder
          .fieldGroup(
            // Use field group to create half-width field
            '',
            [
              this.builder
                .optionalTextInput(
                  'owner',
                  'dgOrgInternalContentForm_ContentOwner'
                )
                .styledBy('df-form__col-half')
                .readonlyWhen(() => context.fieldOptions?.owner?.isDisabled)
                .build(),
            ],
            'df-form__row'
          )
          .build(),
        this.builder
          .fieldGroup(
            // Use field group to create half-width field
            '',
            [
              this.builder
                .foreignField<MonthPickerFieldParams>(
                  'publishDate',
                  'dgOrgInternalContentForm_CreationDate',
                  MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                  {
                    isMaxDateToday: true,
                  }
                )
                .styledBy('df-form__col-half')
                .readonlyWhen(
                  () => context.fieldOptions?.publishDate?.isDisabled
                )
                .build(),
            ],
            'df-form__row'
          )
          .build(),
        this.builder
          .fieldGroup(
            // Use field group to create half-width field
            '',
            [
              this.builder
                .optionalTextInput(
                  'externalId',
                  'dgOrgInternalContentForm_InternalItemId'
                )
                .styledBy('df-form__col-half')
                .readonlyWhen(
                  () => context.fieldOptions?.externalId?.isDisabled
                )
                .build(),
            ],
            'df-form__row'
          )
          .build(),
        ,
        this.builder
          .fieldGroup(
            // Use field group to create half-width field
            '',
            [
              this.builder
                .foreignField<ComboboxFieldParams>(
                  'language',
                  'dgOrgInternalContentForm_Languages',
                  ComboboxFieldComponent.REGISTERED_FIELD_TYPE,
                  {
                    options$: of(this.languages),
                    optionLabelKey: 'key',
                    // provideOption for language takes the string id (e.g. 'en') and
                    // returns the display name (e.g. 'English') in an array
                    provideOption: (id: string) =>
                      [
                        this.languages.find((lang) => {
                          return lang.id === id;
                        }),
                      ].map((selectedOption) => selectedOption.key as string),
                    // provideControlValue takes the option object wrapped in an array (e.g. [ { key: 'English', id: 'en'} ])
                    // and returns just the id value (e.g. 'en') which will be sent to the server as the value for the field
                    // (e.g. `language: 'en'`)
                    provideControlValue: (option: ComboboxFieldOption[]) =>
                      option[0].id,
                  }
                )
                .styledBy('df-form__col-half')
                // TODO: there isn't a way to disable a combobox
                // dgx-combobox will need to be updated for this to do anything [PD-81493]
                .readonlyWhen(() => context.fieldOptions?.language?.isDisabled)
                .build(),
            ],
            'df-form__row'
          )
          .build(),
      ])
      .asFieldset()
      .labeled(context.expanderTemplate)
      .expandedBy(context.expand$)
      .build();
  }
}
