import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { RecommendingItem } from '@app/recommendations/recommendations.model';
import { ResourceType } from '@app/shared/models/core-api.model';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import { camelCaseKeys } from '@app/shared/utils/property';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TabRouterLessNavigationItem } from '@app/navigation/navigation.model';
import { TrackingLink } from '@app/insights/tracking-links-table/tracking-link.model';

export interface ShareTabsResolve {
  item: RecommendingItem;
  url: string;
  canManage: boolean;
  isEditing: boolean;
  initiator: HTMLElement;
  description: string;
  resourceId: any;
  resourceType: ResourceType;
  resourceCtrl: string;
  selected?: RecommendedUser[];
  goToUrlAfterCreate?: string;
  link: TrackingLink;
}

export interface RecommendedUser {
  userProfileKey: string | number;
  name: string;
  email?: string;
  picture?: string;
}

@Component({
  selector: 'dgx-share-tabs-modal',
  templateUrl: './share-tabs-modal.component.html',
})
export class ShareTabsModalComponent implements OnInit {
  @Input() public canManage: boolean;
  @Input() public goToUrlAfterCreate: string;
  @Input() public hideHeader?: boolean;
  @Input() public initiator: HTMLElement;
  @Input() public isEditing: boolean;
  @Input() public item: any;
  @Input() public description: string;
  @Input() public resourceId: any;
  @Input() public resourceType: ResourceType;
  @Input() public resourceCtrl: string;
  @Input() public selected: RecommendedUser[] = [];
  @Input() public url: string;

  @ViewChild('trackable', { static: true })
  public trackableTemplate: TemplateRef<any>;
  @ViewChild('share', { static: true })
  public shareTemplate: TemplateRef<any>;

  public canCreateTrackableLink = false;
  public displayType: string;
  public inviteUrl: string;
  public heading: string;
  public i18n = this.translate.instant([
    'Core_Share',
    'TrackableLinkCtrl_AutoEnroll',
    'A11y_SecondaryPagesNavType',
    'recommendationForm_RecommendItem_Tag',
    'recommendationForm_RecommendItem_Article',
    'recommendationForm_RecommendItem_Assessment',
    'recommendationForm_RecommendItem_Book',
    'recommendationForm_RecommendItem_Course',
    'recommendationForm_RecommendItem_Event',
    'recommendationForm_RecommendItem_Opportunity',
    'recommendationForm_RecommendItem_Pathway',
    'recommendationForm_RecommendItem_Target',
    'recommendationForm_RecommendItem_Episode',
    'recommendationForm_RecommendItem_Post',
    'recommendationForm_RecommendItem_Task',
    'recommendationForm_RecommendItem_Video',
    'recommendationForm_RecommendItem_Academy',
  ]);
  public resolve: ShareTabsResolve;
  public selectedTab = 'share';
  public tabList: TabRouterLessNavigationItem[];

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    // TODO: Remove this when we are no longer passing pascal-cased items in anywhere
    this.item = camelCaseKeys(this.item);
    this.heading = this.getTranslatedHeading();

    // this being set from the recommendationSvc.
    this.canCreateTrackableLink = this.item.isOrgContent;
    // add everything to a mock resolve object for our AJS children
    this.resolve = {
      item: this.item,
      url: this.url,
      canManage: this.canManage,
      description: this.description,
      goToUrlAfterCreate: this.goToUrlAfterCreate,
      initiator: this.initiator,
      isEditing: this.isEditing,
      resourceId: this.resourceId,
      resourceType: this.resourceType,
      resourceCtrl: this.resourceCtrl,
      // TODO: Remove this when we are no longer passing pascal-cased items in anywhere
      selected: this.selected.map((user) => camelCaseKeys(user)),
      link: null,
    };

    this.tabList = [
      {
        name: this.i18n.Core_Share,
        nameId: 'share',
        template: this.shareTemplate,
      },
    ];
    if (this.canManage && this.canCreateTrackableLink) {
      this.tabList = [
        ...this.tabList,
        {
          name: this.i18n.TrackableLinkCtrl_AutoEnroll,

          nameId: 'trackable',
          template: this.trackableTemplate,
        },
      ];
    }
  }

  public getTranslatedHeading() {
    const translationKey = `recommendationForm_RecommendItem_${this.item.resourceType}`;
    return this.i18n[translationKey];
  }

  public switchTab(newTab: string) {
    this.selectedTab = newTab;
  }

  public close(response?) {
    this.activeModal.close(response);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
