<dgx-modal
  (dismiss)="dismiss()"
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-header">
    {{ i18n.addEditMetaDataModal_EditProperties }}
  </ng-container>
  <ng-container class="modal-body">
    <form #metadataForm="ngForm" class="form-wrap guts-m-h-2" novalidate>
      <div class="l_flexbar guts-m-v-1" *ngIf="showPurpose">
        <div class="guts-p-b-1 guts-p-r-2 l_flex-one l_flex-top">
          <label for="purpose">{{ i18n.OrgPlans_Purpose }}</label>
          <dgx-select
            class="full-width"
            id="purpose"
            [options]="purposes"
            trackBy="targetType"
            labelKey="name"
            [selectedItem]="selectedPurpose?.name"
            [placeholder]="selectedPurpose?.name"
            required
            (selection)="updateSelectedPurpose($event)"
            dgatInput="meta-data-modal-e53"
          ></dgx-select>
        </div>
      </div>

      <div
        *ngFor="
          let property of metadata;
          index as $index;
          trackBy: trackByIndex
        "
        class="l_flexbar guts-m-v-1"
        data-dgat="addEditMetaDataModal-44d"
      >
        <div class="guts-p-b-1 guts-p-r-1 l_flex-one l_flex-top">
          <label for="metadataTitle{{ $index }}" class="form-label">
            {{ i18n.addEditMetaDataModal_Property
            }}<df-label-marker></df-label-marker>
          </label>
          <input
            id="metadataTitle{{ $index }}"
            type="text"
            name="metadataTitle{{ $index }}"
            #metadataTitle="ngModel"
            [(ngModel)]="property.property"
            required
            data-dgat="addEditMetaDataModal-7f6"
          />
          <dgx-validate-field
            *ngIf="
              !metadataTitle.value &&
              (metadataTitle.dirty || metadataTitle.touched)
            "
            [message]="i18n.dgFlexRow_FieldRequired"
          ></dgx-validate-field>
        </div>

        <div class="guts-p-b-1 l_flex-one l_flex-top">
          <label for="metadataValue{{ $index }}" class="form-label">
            {{ i18n.addEditMetaDataModal_Value
            }}<df-label-marker></df-label-marker>
          </label>
          <input
            id="metadataValue{{ $index }}"
            type="text"
            name="metadataValue{{ $index }}"
            #metadataValue="ngModel"
            [(ngModel)]="property.value"
            required
            data-dgat="addEditMetaDataModal-708"
          />
          <dgx-validate-field
            *ngIf="
              !metadataValue.value &&
              (metadataValue.dirty || metadataValue.touched)
            "
            [message]="i18n.dgFlexRow_FieldRequired"
          ></dgx-validate-field>
        </div>

        <div class="l_flex-shrink guts-p-l-1">
          <button
            df-button
            dfButtonType="clear"
            class="color-ebony-a61 hover-color-ebony"
            (click)="removeMetaData(property)"
            data-dgat="addEditMetaDataModal-4af"
          >
            <df-icon icon="cross-circle"></df-icon>
            <span class="a11y-hide">
              {{ i18n.addEditMetaDataModal_RemoveProperty }}
            </span>
          </button>
        </div>
      </div>

      <div class="guts-m-v-2" *ngIf="metadata?.length < 10">
        <button
          df-button
          dfButtonType="clear"
          class="color-blue"
          (click)="addMetaData()"
          data-dgat="addEditMetaDataModal-3e6"
        >
          <df-icon
            icon="plus-circle"
            a11yText="Follow"
            class="guts-m-r-half"
          ></df-icon>
          {{ i18n.addEditMetaDataModal_AddProperty }}
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      type="submit"
      [class.is_disabled]="
      metadataForm.invalid || !metadataForm.dirty || submitting
    "
    [disabled]="metadataForm.invalid || !metadataForm.dirty || submitting"
      [attr.aria-disabled]="
      metadataForm.invalid || !metadataForm.dirty || submitting
    "
    [attr.aria-label]="submitting ? i18n.Core_Loading : i18n.Core_Save"
      (click)="save()"
      data-dgat="meta-data-modal-d4e"
    >
      <df-spinner-button [isSpinning]="submitting">
        {{ i18n.Core_Save }}
      </df-spinner-button>
    </button>
  </ng-container>
</dgx-modal>
