import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { OrgBaseGuard } from './org-base.guard';

@Injectable({
  providedIn: 'root',
})
export class CatalogGuard extends OrgBaseGuard {
  constructor(
    authService: AuthService,
    @Inject(WindowToken) windowRef: Window,
    router: Router
  ) {
    super(authService, windowRef, router);
  }

  override get hasPermission() {
    return this.orgInfo?.permissions.manageContent && super.hasPermission;
  }
}
