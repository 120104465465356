import { Injectable } from '@angular/core';
import {
  ProviderInfo,
  ProviderSharableBundle,
  ProviderData,
} from '@app/orgs/services/orgs.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { LicenseInfo } from '../taxonomy-api.model';

@Injectable({
  providedIn: 'root',
})
export class OrgProvidersService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public isNotFound$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public getContentProviders(orgId: number): Observable<ProviderInfo[]> {
    return this.http.get<ProviderInfo[]>('/providers/getContentProviders', {
      params: { orgId },
    });
  }

  public getOrgProviders(
    orgId: number,
    term: string
  ): Observable<{ hasMoreItems: boolean; providers: ProviderData[] }> {
    return this.http.get('/providers/getProviders', {
      params: { orgId, term },
    });
  }

  public getProviderSharableBundlesWithOrg(
    orgId: number,
    providerId: number
  ): Observable<ProviderSharableBundle[]> {
    return this.http.get<ProviderSharableBundle[]>(
      '/providers/getProviderSharableBundlesWithOrg',
      {
        params: { orgId, providerId },
      }
    );
  }

  public getProvider(id: number, orgId?: number): Observable<ProviderInfo> {
    return this.http.get<ProviderInfo>('/providers/getProviderInfo', {
      params: { id, orgId },
    });
  }

  public getLicenseInfo(
    orgId: number,
    providerId: number
  ): Observable<LicenseInfo> {
    return this.http.get('/license/getlicenseinfo', {
      params: {
        orgId,
        providerId,
      },
    });
  }

  public acquireLicense(
    orgId: number,
    providerId: number,
    providerType: string,
    id: number
  ): Observable<string> {
    return this.http.post('/providers/acquirelicense', {
      oId: orgId,
      pId: providerId,
      t: providerType,
      v: id,
    });
  }
}
