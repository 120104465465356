import { Injectable } from '@angular/core';
import { BadgeMappingToAPI } from '../badge.model';
import { TrackerService } from '@dg/shared-services';

@Injectable()
export class BadgeTrackerService {
  constructor(private tracker: TrackerService) {}

  public trackBadgeAdded(source: BadgeMappingToAPI) {
    const eventDataToTrack = {
      action: 'Achievement Added',
      properties: {
        Location: 'GlobalAdd',
        AchievementType: 'Badge',
        Category: 'User Outcomes',
        Label: 'Badge',
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
