<dgx-modal
  [useDefaultSubmitButton]="false"
  [isHeaderBorderless]="false"
  [canCancel]="true"
>
  <!-- Modal Header -->
  <ng-container class="modal-header">
    <span [textContent]="i18n.OrgSkills_AddSkillTitle"></span>
  </ng-container>

  <!-- Modal Body -->
  <ng-container class="modal-body">
    <dgx-tags-search
      class="grid__col-12"
      [focusOnInit]="true"
      [hideTopTags]="true"
      [placeholderText]="i18n.OrgSkills_SearchForSkills"
      [showHintAlone]="false"
      [useSearchStyle]="true"
      (addTag)="add($event)"
    ></dgx-tags-search>
    <div class="grid__col-12 guts-p-t-0" *ngIf="tags.length">
      <h2
        class="h4 guts-p-b-half border-bottom"
        [textContent]="i18n.TargetResourcesForm_Selected"
      ></h2>
      <ul>
        <li
          *ngFor="let tag of tags; trackBy: trackByTitle"
          data-dgat="tagsForm-50b"
        >
          <div class="l_flexbar border-bottom">
            <div class="l_flex-grow">
              <div class="l_flexbar guts-p-v-1 guts-p-h-0">
                <div
                  class="
                    icon-spot
                    flexed-icon
                    bg-color-ebony-a08
                    center-item
                    guts-m-h-1
                  "
                >
                  <df-icon icon="tag" class="color-ebony-a61"></df-icon>
                </div>
                <div class="l_flex-grow">
                  <h3 class="break par par--small font-bold">
                    {{ tag.title }}
                    <img
                      class="tag-endorsed-img v-bottom guts-m-l-half"
                      *ngIf="tag.isEndorsed && !!endorsedSrc"
                      src="{{ endorsedSrc }}?brandlogo"
                      [attr.alt]="i18n.Core_Endorsed"
                    />
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                class="guts-p-full-1 dg-modal__close-icon"
                (click)="remove(tag)"
                [ngbTooltip]="i18n.Core_Remove"
                tooltip-trigger="'focus mouseenter'"
                [attr.aria-label]="i18n.Core_Remove"
                data-dgat="tagsForm-304"
              >
                <df-icon icon="cross"></df-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>

  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      type="button"
      df-button
      dfButtonType="primary"
      [ngClass]="{ is_disabled: saving || tags.length === 0 }"
      [disabled]="saving || tags.length === 0"
      [textContent]="saving ? i18n.Core_Saving : i18n.OrgSkills_AddSkillTitle"
      (click)="save()"
      data-dgat="tagsForm-cc6"
    ></button>
  </ng-container>
</dgx-modal>
