<div
  class="dgx-pagination {{ className }}"
  [ngClass]="{
    hasBoundaryLinks: boundaryLinks,
    'guts-p-h-1-half guts-p-v-1': !useApolloStyles,
    'tw-reset': useApolloStyles,
  }"
>
  <div class="grid grid--bleed">
    <div *ngIf="showPerPage" class="grid__col-3 no-tablet"></div>

    <div
      class="grid__col-auto dgx-pagination__paging-col"
      [ngClass]="{
        'dgx-pagination__paging-col-apollo': useApolloStyles,
      }"
    >
      <ngb-pagination
        [(page)]="page"
        [boundaryLinks]="boundaryLinks"
        [collectionSize]="collectionSize"
        [directionLinks]="directionLinks"
        [disabled]="disabled"
        [ellipses]="ellipses"
        [maxSize]="maxSize"
        [pageSize]="pageSize"
        [rotate]="rotate"
        (pageChange)="onPageChange($event)"
        [id]="navId"
        [attr.aria-label]="ariaLabel"
      >
        <ng-template ngbPaginationFirst>
          <df-icon
            *ngIf="!useApolloStyles"
            icon="chevron-double-left"
            aria-hidden="true"
            class="v-super"
          ></df-icon>
          <da-icon
            *ngIf="useApolloStyles"
            icon="chevron-double-left"
            aria-hidden="true"
            class="tw-block tw-size-3"
          ></da-icon>
        </ng-template>
        <ng-template ngbPaginationPrevious>
          <df-icon
            *ngIf="!useApolloStyles"
            icon="chevron-left"
            aria-hidden="true"
            class="v-super"
          ></df-icon>
          <da-icon
            *ngIf="useApolloStyles"
            icon="chevron-left"
            aria-hidden="true"
            class="tw-block tw-size-3"
          ></da-icon>
        </ng-template>
        <ng-template ngbPaginationNext>
          <df-icon
            *ngIf="!useApolloStyles"
            icon="chevron-right"
            aria-hidden="true"
            class="v-super"
          ></df-icon>
          <da-icon
            *ngIf="useApolloStyles"
            icon="chevron-right"
            aria-hidden="true"
            class="tw-block tw-size-3"
          ></da-icon>
        </ng-template>
        <!--
          ngbPagination does not have any input options so to
          get text that is more informative to screen readers
          we are hiding the visible value and adding
          screen-reader-only text.
        -->
        <ng-template ngbPaginationNumber let-i>
          <span aria-hidden="true">{{ i }}</span>
          <span class="a11y-hide-text">
            {{ 'Pagination_GoToPageFormat' | translate: { number: i } }}
          </span>
        </ng-template>
      </ngb-pagination>
    </div>

    <div *ngIf="showPerPage" class="grid__col-4 grid__col-lg-3">
      <div class="grid__cell right-text par par--small color-ebony-a61">
        <span class="ib-v-middle no-tablet">
          {{ i18n.Core_Show }}
        </span>
        <dgx-menu
          [appendToBody]="true"
          [menuConfig]="menuConfig"
          [autoCloseOnItemSelect]="true"
          placement="bottom-left"
          class="ib guts-m-h-half l_flex--align-right l_flex--align-right--no-mobile"
        >
          <button
            df-button
            dfButtonType="passive"
            aria-haspopup="true"
            class="btn-dropdown btn-dropdown--sm"
            data-dgat="pagination-component-794"
          >
            <span class="guts-m-r-half">{{ pageSize }}</span>
            <df-icon
              size="small"
              class="btn-dropdown__icon btn-dropdown__icon--sm"
              icon="chevron-down"
            ></df-icon>
          </button>
        </dgx-menu>
        <span class="ib-v-middle">
          <span class="no-tablet">
            {{ i18n.Pagination_OfYResultsFormat }}
          </span>
          <span class="tablet-only">
            {{ i18n.Pagination_OfYFormat }}
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
