import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-data-column-date',
  templateUrl: './data-column-date.component.html',
  styleUrls: ['./data-column-date.component.css'],
})
export class DataColumnDateComponent {
  @Input() public date: Date | string;
  @Input() public format: string;

  // Angular methods
  public ngOnInit(): void {
    // defaults -- the dataTable will pass in undefined if they're left blank
    // so we must manually override them rather than relying on normal optional
    // @input() indicators.
    this.format = this.format ?? 'yyyy/MM/dd';
  }
}
