import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Completed Goal notification */
@Component({
  selector: 'dgx-completed-goal',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedGoalComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('completedGoal_Single', {
      goalHtml: this.titleArgument,
    });
  }
}
