import { Injectable } from '@angular/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OutcomeNotificationService {
  constructor(
    private translate: TranslateService,
    private notifier: NotifierService,
  ) {
  }

  public notifyOutcomeCreated(title: string) {
    this.notifier.showSuccess(
      this.translate.instant('OrgInternalContentCtrl_SuccessfulAddContent', {
        title,
      }),
    );
  }

  public notifyOutcomeUpdated(title: string) {
    this.notifier.showSuccess(
      this.translate.instant('ProfileCtrl_EditCollectionItemNotifierFormat', {
        itemTitle: title,
      }),
    );
  }

  public notifyOutcomeCreateFailed() {
    this.notifier.showError(
      this.translate.instant('OrgInternalContentCtrl_GenericError'),
    );
  }
}
