<form name="form" novalidate class="form-wrap">
  <dgx-modal
    [isSubmitDisabled]="!isChanged"
    [modalHeaderTitle]="headerText"
    [canCancel]="true"
    [useDefaultSubmitButton]="false"
    [useDefaultForm]="false"
    headerWrapperClasses="guts-p-h-2 guts-p-v-1-half"
    bodyClasses="guts-p-h-2 guts-p-v-1-half"
    cancelButtonClasses="guts-p-h-1-half"
    (dismiss)="dismiss()"
  >
    <ng-container class="modal-body">
      <ul dgxSortableList [tileHeight]="72" [sort]="onSort">
        <li
          *ngFor="
            let item of reorderData;
            index as idx;
            first as isFirst;
            last as isLast;
            trackBy: getItemTrackingKey
          "
          dgxSortable
          [sortableItem]="item"
          [sortableIndex]="idx"
          class="full-width reorder__item-wrapper"
          data-dgat="reorderModal-b43"
        >
          <div
            class="reorder__item l_flexbar l_flex-justify guts-p-h-1 full-width"
          >
            <div class="l_flexbar l_flex-grow reorder__ellipsis-wrapper">
              <df-icon
                icon="dots-double-vertical"
                class="color-ebony-a61 guts-m-r-1"
              ></df-icon>
              <h3 *ngIf="item.title" class="par font-semibold reorder__title">
                {{ item.title }}
              </h3>
              <h3
                *ngIf="!item.title"
                class="par font-semibold color-ebony-a61 reorder__title"
              >
                {{ i18n.dgReorder_NoTitleText }}
              </h3>
            </div>
            <div class="guts-p-l-2 m-guts-p-l-1 no-phone no-wrap">
              <p class="par par--light par--small" *ngIf="item.subText">
                {{ item.subText }}
              </p>
            </div>
            <div class="guts-p-l-2 m-guts-p-l-1 no-wrap">
              <button
                type="button"
                class="js-sort-prev"
                #sortPreviousRef
                [attr.aria-label]="item.title + ':' + i18n.Core_MoveUp"
                data-dgat="reorderModal-503"
                [ngClass]="{ disabled: isFirst }"
                [disabled]="isFirst"
              >
                <df-icon
                  icon="arrow-up"
                  class="color-ebony-a61"
                  [ngClass]="{ 'half-opacity': isFirst }"
                  aria-hidden="true"
                ></df-icon>
              </button>
              <button
                type="button"
                class="js-sort-next"
                #sortNextRef
                [attr.aria-label]="item.title + ':' + i18n.Core_MoveDown"
                data-dgat="reorderModal-328"
                [ngClass]="{ disabled: isLast }"
                [disabled]="isLast"
              >
                <df-icon
                  icon="arrow-down"
                  class="color-ebony-a61"
                  [ngClass]="{ 'half-opacity': isLast }"
                  aria-hidden="true"
                ></df-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </ng-container>

    <ng-container class="modal-footer">
      <button
        df-button
        dfButtonType="primary"
        (click)="save()"
        [disabled]="!isChanged || isSubmitting"
        [class.is_disabled]="!isChanged || isSubmitting"
        [attr.aria-disabled]="!isChanged || isSubmitting"
        [attr.aria-label]="
          isSubmitting ? i18n.Core_Loading : i18n.dgReorder_SaveChanges
        "
        data-dgat="reorderModal-c83"
      >
        <df-spinner-button [isSpinning]="isSubmitting">{{
          i18n.dgReorder_SaveChanges
        }}</df-spinner-button>
      </button>
    </ng-container>
  </dgx-modal>
</form>
