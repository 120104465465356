import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { camelCaseKeys, getDeepCopy } from '@app/shared/utils/property';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TargetsService } from '@app/shared/services/targets.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes, OwnerTag } from '@app/tags/tags';
import { UserService } from '@app/user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthUser } from '@app/account/account-api.model';
import { switchMap } from 'rxjs/operators';
import {
  getAverageRatingLevel,
  tagHasIncompleteRatingsForType,
} from '@app/shared/utils/tag-helpers';
import { TagPublicRatingModalService } from '../tag-public-rating-modal/tag-public-rating-modal.service';
import { ModifyOptionsFnType } from '@app/shared/components/menu/menu.component';

@Component({
  selector: 'dgx-tag-tile',
  templateUrl: './tag-tile.component.html',
  styleUrls: ['./tag-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagTileComponent implements OnChanges {
  @Input() public tag: TagsApi.Tag;
  @Input() public actionsContext?: any;
  @Input() public isOwner?: boolean;
  @Input() public hideFollowing?: boolean;
  @Input() public searchCollection: boolean;
  @Input() public targetAuthoring?: boolean;
  @Input() public showAddTarget?: boolean;
  @Input() public targetId?: number;
  @Input() public trackingLocation?: string;
  @Input() public isSkillStandard?: boolean = false;
  @Input() public modifyOptionsFn?: ModifyOptionsFnType<any>;
  @Input() public shouldWaitForRemoval = true;
  // Prevents opening the modal when clicking on the ratings donut
  @Input() public disableRatingsModalOpen?: boolean = false;

  @Output()
  public targetLevelChange?: EventEmitter<number> = new EventEmitter<number>();
  // TODO: Why is the tag tile not utilizing the modifyOptionsFn for removing items from plan?
  @Output()
  public removeFromPlan?: EventEmitter<number> = new EventEmitter<number>();

  public i18n = this.translateService.instant([
    'Core_Endorsed',
    'OrgSkills_EndorsedSkillTooltip',
    'Cred_Certificate_SignaturesAlt',
    'TargetCtrl_SetTargetLevel',
  ]);
  public isRemoved = false;

  private _showUpdateLink: boolean;
  private _targetLevel: number = 0;

  @Input() public set showUpdateLink(value) {
    this._showUpdateLink = value;
  }
  public get showUpdateLink(): boolean {
    return (
      this._showUpdateLink && !!this.tag.rating?.level && !this.isCredentialTag
    );
  }

  @Input() public get targetLevel(): number {
    return this._targetLevel;
  }
  public set targetLevel(val) {
    this._targetLevel = val || 0;
    this.targetLevelChange.emit(this._targetLevel);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private ref: ElementRef,
    private authService: AuthService,
    private translateService: TranslateService,
    private targetsService: TargetsService,
    private tagRatingService: TagRatingService,
    private tagPublicRatingModalService: TagPublicRatingModalService,
    private userService: UserService,
    private trackerService: TrackerService,
    private orgEndorsedService: OrgEndorsedService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public get authUser(): AuthUser {
    return this.authService.authUser;
  }

  public get level(): string {
    return this.targetAuthoring ? `${this.targetLevel}` : this.tagLevel;
  }

  public get ariaLabel(): string {
    return this.tagRatingService.getAriaLabelForRatingDonut(
      this.level,
      this.inProgress,
      this.tag
    );
  }

  public get endorsedSrc(): string | undefined {
    if (!this.tag.isEndorsed) {
      return undefined;
    }
    return this.orgEndorsedService.getEndorsedSrc(
      this.authUser.defaultOrgInfo.organizationId
    );
  }

  public get signaturesUrl(): string {
    return this.webEnvironmentService.getBlobUrl(
      '/content/img/cert-seal-signed2x.png'
    );
  }

  public get showPendingRatingNotification(): boolean {
    return (
      this.tag.rating &&
      (this.hasIncompleteEvaluation ||
        this.hasIncompleteManagerRating ||
        this.hasIncompletePeerRating)
    );
  }

  public get tagLevel(): string {
    let level = this.tag.rating?.level;
    // use average if rating is of peer type
    if (this.tag.rating?.type === InternalTagRatingTypes.peer) {
      level = getAverageRatingLevel(
        this.tag.ratings.filter(
          (rating) =>
            rating.type === InternalTagRatingTypes.peer &&
            !!rating.dateCompleted
        )
      );
    }
    return this.isExternalRating ? null : level;
  }

  public get isCertified(): boolean {
    if (this.targetAuthoring) {
      return false;
    }
    return this.isCredentialTag && !!this.tag.rating?.level;
  }

  public get isEvaluating(): boolean {
    if (this.targetAuthoring) {
      return false;
    }
    return this.isEvalTag && !this.tag.rating?.level;
  }

  public get isEvaluated(): boolean {
    if (this.targetAuthoring) {
      return false;
    }
    return this.isEvalTag && !!this.tag.rating?.level;
  }

  public get inProgress(): boolean {
    return this.isEvaluating;
  }

  /**
   * Determine if the tag tile should be clickable
   * If the tag has public ratings it should be clickable
   */
  public get isClickable(): boolean {
    let canClickTag: boolean = this.isOwner || this.targetAuthoring;
    if (this.isCertified) {
      if (!this.isOwner && this.tag.rating.privacyId !== 0) {
        canClickTag = true;
      }
    }
    return (canClickTag || this.hasPublicRatings) && !!this.authUser;
  }

  private get isCredentialTag(): boolean {
    return this.tag.rating?.type === InternalTagRatingTypes.credential;
  }

  private get isEvalTag(): boolean {
    return this.tag.rating?.type === InternalTagRatingTypes.evaluation;
  }

  private get isExternalRating(): boolean {
    return !this.tag.rating?.isInternal || false;
  }

  private get hasPublicRatings(): boolean {
    return !this.isOwner && this.tag.ratings?.length > 0;
  }

  private get hasIncompleteEvaluation(): boolean {
    return tagHasIncompleteRatingsForType(
      this.tag,
      InternalTagRatingTypes.evaluation
    );
  }

  private get hasIncompleteManagerRating(): boolean {
    return tagHasIncompleteRatingsForType(
      this.tag,
      InternalTagRatingTypes.manager
    );
  }

  private get hasIncompletePeerRating(): boolean {
    return tagHasIncompleteRatingsForType(
      this.tag,
      InternalTagRatingTypes.peer
    );
  }

  public ngOnChanges({ tag }: SimpleChanges): void {
    if (tag?.currentValue) {
      this.tag = camelCaseKeys(tag.currentValue);
      if (!this.tag.rating) {
        this.tag.rating = {
          type: null,
          level: null,
          tagId: tag.currentValue.tagId,
        } as TagsApi.UserTagRating;
      }
    }
  }

  public popoverViewed(tag: OwnerTag): void {
    let properties: any = {
      SkillId: tag.tagId,
      SkillName: tag.name,
      SkillOwnerUserId: this.authService.authUser?.viewerProfile.userProfileKey,
    };
    if (this.isOwner) {
      properties = {
        ...properties,
        IsFocusSkill: tag.isFocused,
        IsOnProfile: tag.isFollowing,
      };
    } else {
      properties = {
        ...properties,
        IsFocusSkillForOwner: tag.isFocusSkillForOwner,
        IsOnProfileForOwner: tag.isOnProfileForOwner,
      };
    }
    this.trackerService.trackEventData({
      action: 'Skill Other Signals Previewed',
      element: this.ref.nativeElement as HTMLElement,
      properties,
    });
  }

  /**
   * Update the tag value from children components
   */
  public updateTagValue(tag: OwnerTag) {
    if (!tag.isFollowing && this.shouldWaitForRemoval) {
      // this disables the tag tile until the tag is removed from the skills page to prevent interaction with the tag tile
      // before the skills page is updated
      this.isRemoved = true;
    }
    this.tag = getDeepCopy({
      ...this.tag,
      ...tag,
    });
  }

  public getElementRef(): ElementRef {
    return this.ref;
  }

  /**
   * Deterine which type of modal should be shown for the skill
   * Modal Types
   * - Set a Skill's target rating
   * - Rating overview for Public rating types
   * - Auth User's Rating overview modal
   * @param event
   */
  public determineModalType(event: Event): void {
    // Do not allow clicks on tag tile if the user is logged out or it is disabled
    if (!this.authUser || this.disableRatingsModalOpen) {
      return;
    }
    if (this.targetAuthoring) {
      this.setTagTargetRating(event);
    } else if (this.hasPublicRatings) {
      this.userService
        .getUserByKey(this.tag.rating.userProfileKey)
        .subscribe((user) => {
          const headerText = `${this.tag.title} - ${user?.name}`;
          this.tagPublicRatingModalService.openUserPublicRatingModal(
            this.tag,
            headerText,
            event,
            user.userProfileKey
          );
        });
    } else {
      if (this.isOwner) {
        this.openSkillModal(event);
      }
    }
  }

  public removeFromPlanFn(id: number): void {
    this.removeFromPlan.emit(id);
  }

  /**
   * Open the tag target rating modal to setup target rating
   * Target ratings are mostly used in plans
   * @param event user event
   */
  private setTagTargetRating(event: Event): void {
    event.preventDefault();
    this.tagRatingService
      .openRatingModal({
        event,
        title: this.i18n.TargetCtrl_SetTargetLevel,
        tag: camelCaseKeys(this.tag),
        ratingType: InternalTagRatingTypes.target,
        initialValue: this.targetLevel,
      })
      .pipe(
        switchMap((rating: TagsApi.UserTagRating) => {
          this.targetLevel = Number(rating.level);
          return this.targetsService.setTagTargetRating(
            this.targetId,
            this.tag.resourceId,
            rating.level as unknown as number
          );
        })
      )
      .subscribe();
  }

  private openSkillModal(event: Event): void {
    this.tagRatingService.openSkillModal(
      event,
      this.tag,
      this.searchCollection,
      this.trackingLocation,
      this.tileFocus
    );
  }

  private tileFocus(tag: TagsApi.TagDetails): HTMLElement {
    return Array.from(
      document.querySelectorAll('[data-dgat="tag-tile-1d0"]')
    ).find((el) => {
      return el.textContent === tag.name;
    }) as HTMLElement;
  }
}
