import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DurationConverterService {
  constructor() {}

  public fromMinutesToSeconds(text) {
    if (!text) {
      return null;
    }
    const val = parseFloat(text);
    if (!isNaN(val) && val >= 0) {
      return val * 60;
    }
    return undefined;
  }

  public fromSecondsToMinutes(text) {
    const val = parseInt(text);
    if (val && !isNaN(val)) {
      return (val / 60).toFixed(1);
    }
    return text;
  }
}
