<form class="form-wrap" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="!visibilityForm.dirty"
    [submitButtonType]="submitButtonType"
    [submitButtonText]="submitButtonText"
    [useDefaultForm]="false"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">{{ headerText }}</ng-container>
    <!-- Modal Body -->
    <ng-container class="modal-body">
      <div class="guts-p-h-2 guts-p-v-2">
        <dgx-visibility
          [groups]="groups"
          [selected]="visibilityForm.get('privacyId').value"
          [options]="visibility"
          (onSelection)="handleVisibilitySelection($event)"
          (onGroupSelection)="handleGroupSelection($event)"
          (onGroupRemove)="handleGroupRemove($event)"
        ></dgx-visibility>
      </div>
    </ng-container>
  </dgx-modal>
</form>
