import {
  LearningInputModel,
  InputIdentifier,
} from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { Injectable, Inject } from '@angular/core';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { AuthService } from '@app/shared/services/auth.service';
import { InputsFacadeBase } from '@app/user-content/services/inputs-facade-base';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { Observable, BehaviorSubject, EMPTY, of } from 'rxjs';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { DegreedRepository } from '@app/user-content/services/repositories/degreed.repository';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import {
  CourseFormModel,
  CourseImportProgress,
  Institution,
} from './course-form.model';
import { CourseApiEntity } from './repository/course.entity.model';
import { delay, mergeMap, take, tap } from 'rxjs/operators';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { lazySearch } from '@dg/shared-rxjs';
import { ContentCatalogRenderer } from './renderers/content-catalog-course.renderer';
import { CourseRenderer } from './renderers/course-renderer.service';
import { UserProfileCourseRenderer } from './renderers/user-profile-course.renderer';
import { InputContext, RenderMode } from '../user-input.model';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { FormRenderAction, RendererContext } from '../form-renderer.model';
import { ProvidersRepository } from '@app/user-content/services/repositories/providers.repository';
import { AuthUser } from '@app/account/account-api.model';
import { PageCoordinator } from '@app/shared/patterns/coordinator/page-coordinator';
import { InitialCourseFormRenderer } from './renderers/initial-course-form.renderer';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { FormControl } from '@angular/forms';
import {
  ScormService,
  ScormXMLResultStatusType,
} from '@app/content-catalog/services/scorm.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { CHUploadService } from '@degreed/content-hosting-data-access';
@Injectable({ providedIn: 'any' })
export class CourseFacade extends InputsFacadeBase<
  CourseFormModel,
  CourseApiEntity
> {
  private readonly _coordinator = new PageCoordinator();
  private readonly COURSE_TYPE_INFORMAL = 1;
  private readonly _courseTypeState$ = new BehaviorSubject<number>(-1);
  private readonly validators = new Map<
    string,
    (modal: CourseFormModel) => boolean
  >();

  public get scormCompleted(): boolean {
    return this.viewModel.isScorm && !!this.viewModel.courseName;
  }

  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: CourseApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    fieldBuilder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    dgxCommentsApiSvc: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    tipService: TipService,
    private degreedRepository: DegreedRepository,
    private providersRepository: ProvidersRepository,
    private contentCatalogRender: ContentCatalogRenderer,
    private globalAddRenderer: UserProfileCourseRenderer,
    private defaultRenderer: CourseRenderer,
    private initialCourseFormRenderer: InitialCourseFormRenderer,
    private scormService: ScormService,
    private contentHostingUploadService: CHUploadService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      fieldBuilder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      dgxCommentsApiSvc,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }
  public expandAdvanced = new BehaviorSubject(false);

  /**
   * Typeahead search function for institution/provider field
   * @param value
   */
  public loadProviders(value: string): Observable<AutocompleteItem[]> {
    return this.degreedRepository.getInstitutions(
      value,
      this.viewModel.courseTypeId === 0, // 0 is formal
      this.viewModel.country
    );
  }

  /**
   * Get some more info about the selected institution/provider
   */
  public onProviderSelected(id: number): void {
    this.providersRepository
      .fetchOne(id)
      .subscribe((institution: Institution) => {
        if (!!institution) {
          this.viewModel.institution = institution;
        }
      });
  }
  /**
   * Typeahead search function for country field
   * @param value
   */
  public loadCountries(value: string): Observable<string[]> {
    return this.degreedRepository.getCountries(value);
  }

  public onCourseTitleSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<unknown[]> => {
    return term.pipe(
      lazySearch((t: string) =>
        this.loadCourses(
          this.viewModel.institution,
          t,
          this.viewModel.isAccredited
        )
      )
    );
  };

  /**
   * Once a course is selected from the Typeahead options list
   * we make an api call to get more details about the selected course.
   * if we are in EbbLoading mode we run logic accordingly.
   * @param courseId
   * @param isEbbLoading
   */
  public onSelectCourse(courseId: number, isEbbLoading: boolean = false): void {
    // get some more info about the selected course
    this.getDetailsForSelectedCourse(courseId)
      .pipe(take(1))
      .subscribe((course: CourseFormModel) => {
        if (!course) {
          return;
        }

        const extentData = {
          ...this.viewModel.extent,
          courseLevel: course.isAccredited
            ? this.viewModel.allCourseLevels.find(
                (item) => item.id === course.levelRank
              ).id
            : undefined, // this will run only in accredited institutions
          dateCompleted: course.dateCompleted,
          verificationUrl: course.verificationUrl,
          courseGrade: course.gradeId,
        };

        const inputData = {
          ...this.viewModel.input,
          courseNumber: course.courseId,
          creditHours: course.creditHours,
          externalId: course.externalId,
          institutionId: course.institutionId,
          providerId: course.providerId,
          durationUnits: course.durationUnits,
          durationUnitType: course.durationUnitType,

          unitType: this.getUnitType(
            course,
            this.viewModel.input?.unitType,
            this.viewModel.institution
          ),
          units: this.getUnits(
            course,
            this.viewModel.input?.units,
            this.viewModel.institution
          ),
        };
        const data: CourseFormModel = {
          ...this.viewModel,
          inputId: course.inputId,
          isAccredited: course.isAccredited,
          courseName: course.name,
          description: course.description,
          creditHours: course.creditHours || course.durationUnits,
          extent: { ...extentData },
          input: { ...inputData },
          courseUrl: course.courseUrl,
          tags: course.tags,
          durationUnits: inputData.durationUnits,
          durationUnitType: inputData.durationUnitType,
          unitType: inputData.unitType,
          units: inputData.units,
        };

        //Update stream data
        this.viewModel = { ...data };
      });
  }

  public onSubmit(): Observable<any> {
    if (this._coordinator.isEmpty == false) {
      if (
        this.inputContext.renderMode === RenderMode.ContentCatalog &&
        !this.viewModel.hostedContentDetails
      ) {
        return this.checkAndUpdateUrlDuplicates().pipe(
          mergeMap(() => {
            return EMPTY;
          })
        );
      }
      this.updateUIConfiguration();
      return EMPTY;
    } else {
      // Currently the URL field is hidden when vm.hostedContentDetails?.hostType !== 'Box' || vm.isScorm;,
      // but if it is not hidden make sure to recheck the url
      if (
        this.inputContext.renderMode === RenderMode.ContentCatalog &&
        !this.viewModel.hostedContentDetails &&
        !this.viewModel.isScorm &&
        !this.isEditing
      ) {
        return this.checkAndUpdateUrlDuplicates().pipe(
          mergeMap(() => {
            return super.onSubmit();
          })
        );
      }
      return super.onSubmit();
    }
  }

  protected get extendedDefaultViewModel(): Partial<CourseFormModel> {
    const user: AuthUser = this.authService.authUser;
    return {
      externalCompletionOnly: this.initialModel?.externalCompletionOnly,
      org: user?.orgInfo ? user.orgInfo[0] : undefined,
      allCourseLevels: [
        {
          id: 1,
          title: this.translate.instant('CourseFormCtrl_FirstYearLevel'),
        },
        {
          id: 2,
          title: this.translate.instant('CourseFormCtrl_SecondYearLevel'),
        },
        {
          id: 3,
          title: this.translate.instant('CourseFormCtrl_ThirdYearLevel'),
        },
        {
          id: 4,
          title: this.translate.instant('CourseFormCtrl_FourthYearLevel'),
        },
        {
          id: 5,
          title: this.translate.instant('CourseFormCtrl_GraduateLevel'),
        },
      ],
      extent: {
        courseLevel: undefined,
        dateCompleted: undefined,
        courseGrade: undefined,
        verificationUrl: undefined,
      },
      isAccredited: this.viewModel?.courseTypeId === 1 ? true : false, // switching radio buttons value.
      country: this.translate.instant('CourseFormCtrl_US'),
      courseTypeId: this.COURSE_TYPE_INFORMAL,
      formTitle: this.translate.instant(
        this.inputContext.isEditing
          ? this.inputContext.renderMode === RenderMode.Pathways
            ? 'Core_EditItem' // pathways internal content edit shows a special title
            : `CourseFormCtrl_Edit${this.inputContext.inputType}`
          : `CourseFormCtrl_Add${this.inputContext.inputType}`
      ),
      // // This submit button displayed as a Next button to create a wizard-like form is only shown in the content catalog context
      // submitButtonText: this.submitButtonText,
      isSubmitButtonDisabled: false, // disable until we've parsed the URL or uploaded some hosted content
      formUIState: { courseTypeState$: this._courseTypeState$ },
      isScorm: false,
      updateScorm: false,
      scormUploadErrorMessage: `${this.translate.instant(
        'Core_Upload'
      )} ${this.translate.instant('Core_Error')}`,
      onSelectScorm: () => {
        const updateCourse = false;
        if (this.viewModel.isScorm) {
          this.getScormCourseImportUrl(updateCourse).subscribe();
          this.viewModel = {
            ...this.viewModel,
            shouldShowContentUploader:
              this.viewModel.isScorm &&
              this.authService.authUser?.defaultOrgInfo?.hasScorm,
          };
        }
        this.viewModel.isSubmitButtonDisabled = this.viewModel.isScorm;
        this.scormService.cachedScormInfo = this.viewModel.scormInfo;
      },
      onEditScorm: () => {
        if (this.viewModel.updateScorm) {
          // in case we revert from edit we need to be able to go back to original files
          if (!this.scormService.cachedScormInfoForEdit) {
            this.scormService.cachedScormInfoForEdit = this.viewModel.scormInfo;
          }
          const updateCourse = true;
          this.viewModel.scormInfo = null;
          this.getScormCourseImportUrl(updateCourse).subscribe();
          this.viewModel = {
            ...this.viewModel,
            shouldShowContentUploader:
              this.viewModel.isScorm &&
              this.authService.authUser?.defaultOrgInfo?.hasScorm,
          };
          this.scormService.cachedScormInfo = this.viewModel.scormInfo;
        } else {
          this.viewModel.scormInfo = this.scormService.cachedScormInfoForEdit;
        }

        this.viewModel.isSubmitButtonDisabled = this.viewModel.updateScorm;
      },
      onAddToCatalogChange: this.onAddToCatalogChange.bind(this),
      // If the hiddenWhen functionality for the old duration fields is updated (inside of input-common-field.builder.ts) so that the minutes field is displayed,
      // the following value for unitType will need to be conditional
      unitType: 'Hours',
    };
  }

  private onAddToCatalogChange(shouldAdd: boolean) {
    this.fetchDuplicates(
      shouldAdd && !!this.viewModel.courseUrl,
      this.viewModel.organizationId,
      this.viewModel.courseUrl
    ).subscribe();
  }

  protected get submitButtonText(): string {
    return this._coordinator.count <= 1
      ? this.translate.instant('CourseFormCtrl_SaveCourse')
      : this.translate.instant('Core_Next');
  }

  /**
   * Because we need to use the mediator we override the default implementation.
   */
  protected buildUIConfiguration(
    action?: FormRenderAction
  ): DfFormFieldConfig[] {
    if (this.isFormLoaded === false) {
      this.initializePages(action);
    }

    this.viewModel = {
      ...this.viewModel,
      submitButtonText: this.submitButtonText,
    }; // update submit button text

    // without this we end up with nothing in the coordinators for edit
    // the problem is w/timing of edit data and the render being created and the coordinator (which needs to die) having the pages it should have
    if (this.isFormLoaded && this.inputContext.isEditing) {
      this.initializePages();
    }

    return this._coordinator.next();
  }

  protected initializePages(
    action?: FormRenderAction,
    resetCoordinator: boolean = false
  ): void {
    if (resetCoordinator) {
      this._coordinator.clear();
    }

    this.setFormValidators(); // Set validators which will be use by the Renderers
    const context: RendererContext = {
      inputContext: {
        ...this.inputContext,
        inputType: 'Course',
        isCompleting: this.isCompleting,
      },
      state: () => this.viewModel,
      templates: this.templates,
      expandAdvanced$: this.expandAdvanced,
      action: { type: 'DEFAULT', payload: this.viewModel.courseTypeId },
      validators: this.validators,
    };
    const defaultFormFields = this.defaultRenderer.render(context);
    let specificFormFields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    switch (context.inputContext.renderMode) {
      case RenderMode.Pathways: {
        if (this.inputContext.isEditing) {
          specificFormFields = this.contentCatalogRender.render(context);
          this._coordinator.push(defaultFormFields.concat(specificFormFields));
        } else {
          const isGlobalAdd = false;
          specificFormFields = this.globalAddRenderer.render(
            context,
            isGlobalAdd
          );
          this._coordinator.push(defaultFormFields.concat(specificFormFields));
        }
        this.initializeRadioButtonSubscription();
        break;
      }
      case RenderMode.UserProfile: {
        const isGlobalAdd = true;
        specificFormFields = this.globalAddRenderer.render(
          context,
          isGlobalAdd
        );
        this._coordinator.push(defaultFormFields.concat(specificFormFields));
        this.initializeRadioButtonSubscription();
        break;
      }
      case RenderMode.ContentCatalog: {
        this.checkForContentHostingSupport();
        specificFormFields = this.contentCatalogRender.render(context);

        // Initial Course Form Renderer screen if we're not editing, and we haven't uploaded a file (scorm or box)
        if (
          !this.inputContext.isEditing &&
          !this.viewModel.hostedContentDetails &&
          !this.scormCompleted
        ) {
          this._coordinator.push(defaultFormFields.concat(specificFormFields));
          this._coordinator.push(
            this.initialCourseFormRenderer.render(context)
          );
          // hosted content upload/scorn or editing
        } else {
          this._coordinator.push(specificFormFields);
        }
        break;
      }
    }
  }

  /**
   * Override, called from dgx-upload-section in modal-container.component.html
   * @param file
   */
  protected onContentFileChange() {
    this.viewModel.isSubmitButtonDisabled = true;
  }

  /**
   * Override, called from dgx-upload-section in modal-container.component.html
   * @param formControl
   * @param details
   */
  protected onContentUploadSuccess(formControl: FormControl, response: string) {
    let responseData = response;
    try {
      JSON.parse(response);
    } catch (error) {
      responseData = response;
      this.viewModel.scormInfo.scormHasError = false;
      this.handleScormXmlResult(response);
      return;
    }
    responseData = JSON.parse(response);
    this.viewModel.scormInfo.scormHasError = false;
    this.handleScormJsonResult(formControl, responseData);
    return;
  }

  private initializeRadioButtonSubscription(): void {
    this._courseTypeState$.subscribe((courseTypeId: number) => {
      if (courseTypeId === -1) {
        return;
      }

      // This is updating what fields are showing, but it doesn't reset the ViewModel
      // Manually resetting the view model re-initizalizes the form ...
      // Manually setting the data for now to resolve a bug...
      // TODO: Need to be able to reset view model when swapping course forms
      this.viewModel.courseName = '';

      let specificFormFields: DfFormFieldConfig<DfTemplateOptions>[] = [];
      const action =
        courseTypeId === 1
          ? { type: 'COURSE_TYPE_CHANGED', payload: courseTypeId }
          : { type: 'DEFAULT', payload: courseTypeId };
      const context: RendererContext = {
        inputContext: {
          ...this.inputContext,
          inputType: 'Course',
          isCompleting: this.isCompleting,
        },
        state: () => this.viewModel,
        templates: this.templates,
        expandAdvanced$: this.expandAdvanced,
        action: action,
        validators: this.validators,
      };
      const defaultFormFields = this.defaultRenderer.render(context);
      switch (this.inputContext.renderMode) {
        case RenderMode.Pathways: {
          const isGlobalAdd = false;
          specificFormFields = this.globalAddRenderer.render(
            context,
            isGlobalAdd
          );
          break;
        }
        case RenderMode.UserProfile: {
          const isGlobalAdd = true;
          specificFormFields = this.globalAddRenderer.render(
            context,
            isGlobalAdd
          );
          break;
        }
        case RenderMode.ContentCatalog: {
          specificFormFields = this.contentCatalogRender.render(context);
          break;
        }
      }
      this.fields$.next(defaultFormFields.concat(specificFormFields));
    });
  }
  /**
   * Helper method to retrieve details on selected course, used by Typeahead search.
   * IMPORTANT!:
   *  NOTE :
   *      The BE exposes the endpoint to get course information using the /inputs key
   *      the factory would pick /userinputs key for GlobalAdd mode.
   *      Therefore we need to change the RenderMode to pick up the correct repository.
   *      This mixmatch will be removed once the BE finish their content unification project.
   * @param courseId
   */
  private getDetailsForSelectedCourse(courseId: number): Observable<unknown> {
    return this.repositoryFactory
      .getRepository('Course', RenderMode.ContentCatalog) // READ NOTE IN SUMMARY DOCUMENTATION
      .fetchOne(courseId);
  }

  /**
   * Helper method to set the correct unit type.
   * @param course
   * @param inputUnitType
   * @param selectedInstitution
   */
  private getUnitType(
    course: CourseFormModel,
    inputUnitType: string,
    selectedInstitution: Institution
  ) {
    return (
      course.unitType || inputUnitType || selectedInstitution?.defaultUnitType
    );
  }

  /**
   * Helper method to set the correct units
   * @param course
   * @param inputUnits
   * @param selectedInstitution
   */
  private getUnits(
    course: CourseFormModel,
    inputUnits: number,
    selectedInstitution: Institution
  ) {
    return course.units || inputUnits || selectedInstitution?.defaultUnits;
  }

  /**
   * Typeahead search function for course form field.
   * @param institution
   * @param value
   * @param isAccredited
   */
  private loadCourses(
    institution: Institution,
    value: string,
    isAccredited: boolean
  ): Observable<any> {
    return this.degreedRepository.getCourses(
      value,
      isAccredited,
      institution?.institutionId
    );
  }

  /**
   * This is an example of how the facade can pass validation to the renderer
   * if validation is simple and not reusable it could be defined in the renderer.
   * When the validation is complex it should be defined in the facade and passed to the renderer.
   */
  private setFormValidators(): void {
    this.validators.set('INSTITUTION_VALIDATION', (vm: CourseFormModel) => {
      if (!vm.institution && !vm.institutionName) {
        // empty value is ok
        return true;
      }
      // if there is a name, it must be from the dropdown, so check the id
      const institutionSpecified = vm.institution?.institutionId > 0;
      const orgSpecified = vm.orgId > 0;
      return institutionSpecified || orgSpecified;
    });
  }

  private getScormCourseImportUrl(updateCourse: boolean): Observable<string> {
    // initialize or re-initialize if stale, otherwise return the url
    if (
      !this.viewModel.scormInfo ||
      this.scormService.isStale(this.viewModel.scormInfo)
    ) {
      this.viewModel.scormInfo = this.scormService.initialize(
        updateCourse,
        this.viewModel.courseUrl
      );
    } else {
      return of(this.viewModel.scormInfo?.scormCourseInputUrl);
    }

    if (updateCourse) {
      return of('/scorm/updatecourse').pipe(
        tap((url: string) => {
          this.viewModel.scormInfo.scormCourseInputUrl = url;
        })
      );
    } else {
      return this.scormService
        .getScormCourseImportUrl()
        .pipe(
          tap(
            (url: string) =>
              (this.viewModel.scormInfo.scormCourseInputUrl = url)
          )
        );
    }
  }

  private handleScormXmlResult(xmlResult: string) {
    const processingType = this.scormService.getXMLResponseType(xmlResult);

    switch (processingType) {
      case ScormXMLResultStatusType.UploadResult:
        this.handleScormUploadResult(xmlResult);
        break;
      case ScormXMLResultStatusType.Processing:
        this.handleScormProcessingResult(xmlResult);
        break;
      default:
        console.warn('unhandled scorm result', xmlResult);
        this.viewModel.scormInfo.scormHasError = true;
        break;
    }
  }

  private handleScormJsonResult(formControl, contentUploadResponse) {
    this.getScormImportStatus(contentUploadResponse.courseImportId)
      .pipe(take(1))
      .subscribe((courseImportProgress: string) => {
        const parsedCourseImportProgress: CourseImportProgress =
          JSON.parse(courseImportProgress);
        if (parsedCourseImportProgress.status === 'ERROR') {
          console.warn(
            'unhandled scorm result',
            parsedCourseImportProgress.message
          );
          this.viewModel.scormInfo.scormHasError = true;
        } else if (parsedCourseImportProgress.status !== 'COMPLETE') {
          setTimeout(() => {
            this.handleScormJsonResult(formControl, contentUploadResponse);
          }, 1000);
        } else {
          this.viewModel.courseName =
            parsedCourseImportProgress.importResult.course.title;
          super.onContentUploadSuccess(formControl, contentUploadResponse);
          this.viewModel.isSubmitButtonDisabled = false;
          if (this.viewModel.updateScorm) {
            this.viewModel.scormInfo.scormIsEdited = true;
          } else {
            this.initializePages(null, true);
          }
          this.updateUIConfiguration(); // force a transition to the full form instead of waiting for Next button
        }
        return;
      });
  }

  private handleScormUploadResult(xmlResult: string) {
    this.viewModel.scormInfo.scormToken =
      this.scormService.getTokenFromXmlResponse(xmlResult);
    this.getScormProcessingUrl()
      .pipe(
        mergeMap((url: string) => {
          this.viewModel.scormInfo.scormCourseProcessingUrl = url;
          return this.getScormImportStatus();
        })
      )
      .subscribe((xmlResult: string) => {
        this.handleScormXmlResult(xmlResult);
      });
  }

  private handleScormProcessingResult(xmlResult: string) {
    const status: string =
      this.scormService.getStatusFromXmlResponse(xmlResult);

    // failed - need to go back to the file uploader with a validation
    if (status === ScormXMLResultStatusType.Fail.toString()) {
      this.tracker.trackEventData({
        action: 'SCORM Upload Fail',
        category: xmlResult,
      });

      this.viewModel.scormInfo.scormCourseProcessingUrl = null;
      this.viewModel.scormInfo.scormHasError = true;
      return;
    }

    const progress: number =
      this.scormService.getProgressFromXmlResponse(xmlResult);

    // processing is still completing - poll again
    if (progress < 100) {
      this.getScormImportStatus()
        .pipe(delay(1000))
        .subscribe((xmlResult: string) => {
          this.handleScormXmlResult(xmlResult);
        });
      return;
    }

    // processing completed - set title and transition to next step
    this.viewModel.courseName =
      this.scormService.getTitleFromXmlResponse(xmlResult);

    this.viewModel.isSubmitButtonDisabled = false;

    if (this.viewModel.updateScorm) {
      this.viewModel.scormInfo.scormIsEdited = true;
    } else {
      this.initializePages(null, true);
    }
    this.updateUIConfiguration(); // force a transition to the full form instead of waiting for Next button
  }

  private getScormProcessingUrl(): Observable<string> {
    return this.scormService.getScormCourseImportResultUrl(
      this.viewModel.scormInfo.scormToken,
      this.orgId
    );
  }

  private getScormImportStatus(courseInputId?: string): Observable<string> {
    const url = courseInputId
      ? null
      : this.viewModel.scormInfo.scormCourseProcessingUrl;
    return this.scormService.getScormImportStatus(url, courseInputId);
  }

  /**
   * Check for content hosting support and update view model with FileUploadSettings if true.
   *
   * @returns void
   */
  protected checkForContentHostingSupport() {
    this.contentHostingUploadService
      .canUploadHostedFile({
        uploadType: 'Course',
        renderMode: this.inputContext.renderMode,
      })
      .subscribe((response) => {
        const [supportsContentHosting, fileRestrictions] = response;
        this.viewModel = {
          ...this.viewModel,
          shouldShowContentUploader: supportsContentHosting, // determines to load upload component in modal container
          fileRestrictions: fileRestrictions,
        };
      });
  }

  private checkAndUpdateUrlDuplicates() {
    if (this.inputContext.renderMode !== RenderMode.ContentCatalog) {
      return of(undefined);
    }

    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.viewModel.courseUrl = this.inputsService.cleanUrl(
      this.viewModel.courseUrl
    );

    return this.inputsService
      .getCmsInputsByUrl(
        this.viewModel.organizationId,
        this.viewModel.courseUrl,
        inputIdentifier
      )
      .pipe(
        tap((response: LearningInputModel) => {
          if (response.inputs) {
            this.duplicates = response.inputs;
            this.viewModel = {
              ...this.viewModel,
              duplicateCount: response.inputs.length,
            };
            if (response.inputs.length == 0) {
              this.updateUIConfiguration();
            }
          } else {
            this.updateUIConfiguration();
          }
        })
      );
  }
}
