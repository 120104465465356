import { Inject, Injectable, Type } from '@angular/core';
import {
  DeleteModalComponent,
  DeleteModalInputs,
} from '@app/shared/components/modal/delete-confirmation-modal/delete-modal.component';
import { WindowToken } from '@app/shared/window.token';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  AlertModalComponent,
  AlertModalInputs,
} from '../components/modal/alert-modal/alert-modal.component';
import { isModalDismissError, MODAL_FOCUS_ERROR } from '../utils/modal-helpers';
import { FocusStackService } from './focus-stack.service';
import WindowReferenceEvent = chrome.windows.WindowReferenceEvent;

/**
 * An object that represents the `@Input` bindings to map to
 * a modal component.
 */
export interface ModalInputBindings {
  [name: string]: any;
}

export interface ModalOptions {
  /**
   * True, to make clicking outside of the modal close the modal. False, otherwise.
   * Default: `false`.
   */
  backdropClickClose?: boolean;
  /**
   * False, to prevent pressing escape key close the modal. True, otherwise.
   * Default: `true`.
   */
  keyboardEscClose?: boolean;
  /** Center a modal vertically. */
  centered?: boolean;
  /** Modal content can be scrolled. */
  scrollable?: boolean;

  /**
   * The `@Input` bindings to map to the component.
   *
   * Notes: Observables are a type of object, and if passed directly will
   *       be iterated over incorrectly. Wrap in a lambda function to be safe.
   *      `@Output` bindings shouldn't be bound to a modal component.
   *       Use the `activeModal` service and the `close`/`dismiss`
   *       functions to output data from the modal.
   */
  inputs?: ModalInputBindings;
  windowClass?: string;
  /**
   * passes along NGB error codes when closed/dismissed
   * for custom handling.
   * See catchError handling below for more details
   */
  errorOnDismiss?: boolean;
}

const NOOP_CALLBACK = () => {};
export type CancelNotify = () => {};
export type NotifyCallback = (response: any) => void;
export interface NotifyOptions {
  onClose?: NotifyCallback;
  autoClose?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(
    private ngbModalService: NgbModal,
    private focusStackService: FocusStackService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  /**
   * Shows a modal with the given component and options.
   *
   * @param component The modal component to show.
   * @param options The modal options.
   */
  public show<T>(
    component: Type<any>,
    options: ModalOptions = {}
  ): Observable<T> {
    const {
      backdropClickClose: backdrop = 'static',
      centered,
      scrollable,
      inputs,
      keyboardEscClose: keyboard = true,
      windowClass = '',
      errorOnDismiss = false,
    } = options;
    const modalRef = this.ngbModalService.open(component, {
      backdrop,
      centered,
      scrollable,
      windowClass,
      keyboard,
    });
    if (!this.focusStackService.top) {
      // assume current focused element should get focus again when modal is closed.
      this.focusStackService.push();
    }

    if (inputs) {
      const { componentInstance } = modalRef;
      // add the modal data into the modal instance's @Input bindings
      // if the input needs to be copied instead, do that prior to passing to the inputs object
      Object.keys(inputs).forEach((key) => {
        const value = inputs[key];
        componentInstance[key] = value;
      });
    }

    return from(modalRef.result).pipe(
      tap((result) => {
        if (!errorOnDismiss) {
          this.focusOpener();
        }
        return result;
      }),
      // ngbModalService rejects the promise when the modal
      // is dismissed, which is interpreted as an error
      // (if the modal is dismissed via clicking the backdrop, or
      // hitting ESC, it is further returned as a number; 0 for
      // the click, 1 for ESC)
      catchError((error: Error | unknown) => {
        if (!errorOnDismiss) {
          this.focusOpener();
        } else if (!error) {
          // pass _something_ to error handler.
          // this will only end up in the console if
          // the developer hasn't done their own error
          // handling, meaning they aren't managing focus.
          error = MODAL_FOCUS_ERROR;
        }
        // so here we want to catch that, then return EMPTY
        // (which is an Observable that completes but never emits)
        // unless the developer specifically requests the error to be returned
        if (!errorOnDismiss && (!error || isModalDismissError(error))) {
          return EMPTY;
        }
        // while throwing *real* errors to be handled and displayed
        // by the services/components using the modal
        return throwError(error);
      })
    );
  }

  /**
   * Open a modal and return a cancel function that will auto-close the dialog
   */
  public open<T>(
    component: Type<any>,
    options: ModalOptions & NotifyOptions = { autoClose: true }
  ): CancelNotify {
    const dialog$: Observable<T> = this.show(component, options);
    const subscription = dialog$.subscribe(options.onClose || NOOP_CALLBACK);

    return (() => {
      // if cancelling, dismiss the dialog also
      subscription.unsubscribe();
      const hasAutoClose = typeof options.autoClose !== 'undefined';
      const autoClose = hasAutoClose ? options.autoClose : true;
      if (autoClose) this.dismissAll();
    }) as CancelNotify;
  }

  /* Show the alert modal.
   *
   * Note: `options.inputs` will be overwritten with the `inputs` args.
   *
   * @param inputs The bindings for the AlertModalComponent.
   * @param options The modal options.
   */
  public showAlert(
    inputs: AlertModalInputs,
    options: ModalOptions = {}
  ): Observable<boolean> {
    return this.show(AlertModalComponent, { ...options, inputs });
  }

  /**
   * Show the delete confirmation modal with confirmation input to type 'DELETE'.
   *
   * Note: `options.inputs` will be overwritten with the `inputs` args.
   *
   * @param inputs The bindings for the DeleteModalComponent.
   * @param options The modal options.
   */
  public showDeleteConfirmation(
    inputs: DeleteModalInputs,
    options: ModalOptions = {}
  ): Observable<boolean> {
    return this.show(DeleteModalComponent, { ...options, inputs });
  }

  /**
   * Closes all open modals present in the GUI
   */
  public dismissAll() {
    this.ngbModalService.dismissAll();
  }

  /**
   * Returns focus to opener (or other element)
   *
   * A11Y NOTE: If you get an error here it's likely because you need to
   * push an element to the FocusStackService prior to opening the modal
   * so it can be focused on close.
   *
   * Alternatively you can set `options.errorOnDismiss` to `true` and
   * handle focus on close/dismiss errors manually. This can be helpful for
   * Modals that need different focus management depending on options chosen
   * in the modal (eg. Delete confirmations may need to return to existing
   * element if canceled but return to somewhere else if that element is deleted.)
   *
   * (see NOTE #2 in this section https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7)
   *
   * Use setTimeout to account for conflicts that can sometimes be caused by a closing or dismissed modal stealing focus back to it's initiator
   */
  private focusOpener() {
    this.windowRef.setTimeout(() => this.focusStackService.pop());
  }
}
