<div class="global-add guts-p-full-1 tw-rounded-lg tw-bg-white">
  <section>
    <h2 class="up-me zeta guts-m-b-half js-a11y-focus" tabindex="-1">
      {{ i18n.dgGlobalAdd_Content }}
    </h2>
    <p class="guts-m-b-half">{{ i18n.dgGlobalAdd_Description }}</p>
    <dgx-global-add-buttons
      [actions]="inputActions"
      type="inputs"
    ></dgx-global-add-buttons>
  </section>
  <section *ngIf="outcomeActions" class="guts-m-t-1">
    <h2 class="up-me zeta guts-m-b-half">
      {{ i18n.dgGlobalAdd_Achievement }}
    </h2>
    <p class="guts-m-b-half">
      {{ i18n.dgGlobalAdd_AchievementDescription }}
    </p>
    <dgx-global-add-buttons
      [actions]="outcomeActions"
      type="outcomes"
    ></dgx-global-add-buttons>
  </section>
  <section class="guts-m-v-1">
    <p
      *ngIf="extensionsText"
      class="epsilon guts-p-full-1 border-bottom not-last"
      [innerHTML]="extensionsText"
    ></p>
    <p
      *ngIf="showIntegrationsSection"
      class="epsilon guts-p-full-1"
      [innerHTML]="integrationsText"
    ></p>
  </section>
</div>
