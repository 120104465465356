import { Params } from '@angular/router';

import {
  CollectionQueryParams,
  ProfileCollectionStoreState,
} from '@app/profile/components/profile-collection/profile-collection-reactive-store';

/**
 * These params are tracked by state management. An error will be thrown to notify
 * developers of new params that aren't added here as a reminder
 */
export const KNOWN_URL_PARAMS = [
  'type',
  'provider',
  'tags',
  'startDate',
  'endDate',
  'classifier',
  'pathwayStatus',
  'targetStatus',
  'term',
  'experienceType',
  'collaboratorOnly',
  'academyStatus',
];

/**
 * Map store state to assignments URL params
 */
export function stateToUrlParams(
  state: Partial<ProfileCollectionStoreState>
): Partial<CollectionQueryParams> {
  const {
    type,
    providers,
    tags,
    startDate,
    endDate,
    currentItemClassifier,
    pathwayStatus,
    targetStatus,
    term,
    experienceType,
    collaboratorOnly,
    academyStatus,
  } = state.filter;

  return {
    type,
    provider: providers,
    tags,
    term,
    startDate,
    endDate,
    classifier: currentItemClassifier,
    pathwayStatus,
    targetStatus,
    experienceType,
    collaboratorOnly,
    academyStatus,
  };
}

/**
 * Map assignments URL parameters to store state
 */
export function urlParamsToState(
  params: Partial<CollectionQueryParams>
): Partial<ProfileCollectionStoreState> {
  const {
    type,
    provider,
    tags,
    startDate,
    endDate,
    classifier,
    targetStatus,
    pathwayStatus,
    term,
    experienceType,
    collaboratorOnly,
    academyStatus,
  } = params;

  const parseQueryParamForStringArray = (
    param: string | string[]
  ): string[] => {
    if (!param) {
      return [];
    }
    return Array.isArray(param) ? param : [param];
  };
  return {
    filter: {
      type: parseQueryParamForStringArray(type),
      tags: parseQueryParamForStringArray(tags),
      providers: parseQueryParamForStringArray(provider),
      term: term || null,
      startDate,
      endDate,
      currentItemClassifier: classifier || 'learning',
      pathwayStatus: parseQueryParamForStringArray(pathwayStatus),
      targetStatus: parseQueryParamForStringArray(targetStatus),
      experienceType: parseQueryParamForStringArray(experienceType),
      collaboratorOnly: parseQueryParamForStringArray(collaboratorOnly),
      academyStatus: parseQueryParamForStringArray(academyStatus),
    },
  };
}

/**
 * Notify developer of new url parameters that are not being
 * properly tracked by state management
 */
export function verifyUrlParams(params: Params) {
  Object.keys(params).forEach((param) => {
    if (!KNOWN_URL_PARAMS.includes(param)) {
      console.error(
        `WARNING: CollectionBookmarkService is likely not tracking the "${param}" parameter`
      );
    }
  });
}
