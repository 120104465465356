import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AwardDataModel, AwardMappingToAPI, AwardModel } from '../award.model';

@Injectable()
export class AwardMapperService {
  constructor(public datePipe: DatePipe) {}

  public toApiParameters(source: AwardModel): AwardMappingToAPI {
    const {
      contentTypeId,
      title,
      issuer,
      skills,
      issuerUrl,
      startDate,
      imageUrl,
      userOutcomeId,
    } = source;

    return {
      contentTypeId,
      title,
      source: issuer,
      startDate: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      tags: skills,
      url: issuerUrl,
      imageUrl,
      userOutcomeId,
    };
  }

  public toViewModel(sourceObject: AwardMappingToAPI): AwardDataModel {
    const {
      contentTypeId,
      title,
      source,
      startDate,
      tags,
      url,
      imageUrl,
      userOutcomeId,
    } = sourceObject;

    return {
      title,
      issuer: source,
      issuerUrl: url,
      imageUrl,
      startDate: new Date(startDate),
      skills: tags,
      userOutcomeId,
      contentTypeId,
    };
  }
}
