import { NgModule } from '@angular/core';
import { CompletedTagRatingsPipe } from './pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from './pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from './pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from './pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from './pipes/tag-ratings-for-type.pipe';

/*
  These pipes were refactored to a shared module because they're used in services that are 
  declared in root. Without them also being made available (app.module.ts) the services will throw a 
  runtime error that they can't find the pipes as providers. 

  The fix is to figure out how to make the services not provided inRoot, this is 
  easy to replicate by commenting this file out of the app.module.ts file. 

  Also, since these pipes are only provided as providers and not declared/exported, do they 
  even need to be pipes? They might be able to be refactored to the service which would also 
  solve the problem. 
*/

@NgModule({
  imports: [],
  providers: [
    /* Pipes */
    TagRatingTypePipe,
    TagRatingsForTypePipe,
    CompletedTagRatingsPipe,
    IncompleteTagRatingsPipe,
    TagRatingEndorsersPipe,
  ],
})
export class TagsSharedModule {}
