import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';

import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { FieldType } from '@app/user-content/services/field-builder-factory';
import { AssessmentFormModel } from '../assessment-form.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';
import { hasBrokenUrlValidator } from '@app/shared/validators/has-broken-url.validator';
import { LDFlagsService } from '@dg/shared-services';
@Injectable({ providedIn: 'root' })
export class ContentCatalogAssessmentRendererService implements FormRenderer {
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private translateService: TranslateService,
    private userInputsService: UserInputsService,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<AssessmentFormModel>
  ): DfFormFieldConfig[] {
    const vm: AssessmentFormModel = context.state();
    const minZeroError = this.translateService.instant('Core_InvalidMinValue', {
      min: 0,
    });

    return [
      // Created By
      this.commonFieldBuilder.buildCreatedByField(context.templates.creator),
      // Main form
      this.formBuilder
        .title()
        .labeled(context.translationKeys.AssessmentFormCtrl_AssessmentName)
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentName
        )
        .withDgatId('assessmentForm-8c8')
        .readonlyWhen(() => {
          return context.inputContext.isEditing && vm.isCredSpark;
        })
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translateService.instant('Core_FieldRequiredFormat', {
              fieldName: this.translateService.instant(
                context.translationKeys.AssessmentFormCtrl_AssessmentName
              ),
            }),
          },
        })
        .build(),
      this.formBuilder
        .optionalTextInput(
          'providerName',
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentProvider
        )
        .withDgatId('assessmentForm-a28')
        .readonlyWhen(() => {
          return context.inputContext.isEditing && vm.isCredSpark;
        })
        .build(),
      this.createUrlField(context),
      this.formBuilder
        .optionalTextarea(
          'description',
          context.translationKeys.AssessmentFormCtrl_Description
        )
        .withDgatId('assessmentForm-a12')
        .readonlyWhen(() => {
          return context.inputContext.isEditing && vm.isCredSpark;
        })
        .build(),
      this.formBuilder
        .optionalTextInput(
          'questions',
          context.translationKeys.dgOrgInternalContentForm_Questions
        )
        .ofType('number')
        .withDgatId('assessmentForm-1df')
        .onKeydown((event, model, formControl) => {
          const validateNumericValue =
            this.userInputsService.validateNumericValueOnKeyDown(event);
          if (!validateNumericValue) {
            event.preventDefault();
          }
        })
        .updatedOn('change')
        .validatedBy((control: AbstractControl) =>
          parseInt(control.value) < 0 ? { minZeroError } : null
        )
        .withErrorMessages({ minZeroError })
        .hiddenWhen(() => vm.isCredSpark)
        .build(),
      this.formBuilder
        .fieldGroup(
          '',
          [
            this.formBuilder
              .optionalTextInput(
                'questions',
                context.translationKeys.dgOrgInternalContentForm_Questions
              )
              .ofType('number')
              .styledBy('df-form__col-quart')
              .withDgatId('assessmentForm-1df')
              .onKeydown((event, model, formControl) => {
                const validateNumericValue =
                  this.userInputsService.validateNumericValueOnKeyDown(event);
                if (!validateNumericValue) {
                  event.preventDefault();
                }
              })
              .updatedOn('change')
              .validatedBy((control: AbstractControl) =>
                parseInt(control.value) < 0 ? { minZeroError } : null
              )
              .withErrorMessages({ minZeroError })
              .hiddenWhen(() => {
                return !context.inputContext.isEditing;
              })
              .readonlyWhen(() => {
                return context.inputContext.isEditing && vm.isCredSpark;
              })
              .build(),
            this.formBuilder
              .customField(
                null,
                null,
                context.templates.assessmentManageCredSpark,
                {
                  forwardToCredSpark: vm.forwardToCredSpark,
                }
              )
              .styledBy('df-form__col-three-quart')
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(() => {
          return !context.inputContext.isEditing || !vm.isCredSpark;
        })
        .build(),
      // Duration
      this.commonFieldBuilder.buildDurationFields(
        this.ldFlagsService.inputs.addEditContentDuration,
        vm.inputType
      ),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.USER_IMAGE_FIELD)
        .build({
          useCropper: true,
          inputType: context.inputContext.inputType,
          resourceId: vm.inputId,
        }),
      this.commonFieldBuilder.buildSkillsField(null, true),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.ADVANCED_FIELDSET)
        .build({
          canRestrictContent: vm.canRestrictContent,
          organizationId: vm.organizationId,
          isInitiallyVisibleToOrg: vm.isVisibleToOrg,
          groups: vm.groups,
          expanderTemplate: context.templates.advancedExpander,
          expand$: vm.expandAdvanced$,
          fieldOptions: {
            externalId: {
              isDisabled:
                context.inputContext.isEditing &&
                vm.isCredSpark &&
                context.state().fileManaged,
            },
          },
        }),
    ];
  }

  private createUrlField(
    context: RendererContext<AssessmentFormModel>
  ): DfFormFieldConfig {
    const vm = context.state();
    const originalUrl = vm.url;
    if (!this.ldFlagsService.showBrokenLinksManagement) {
      return this.formBuilder
        .requiredTextInput(
          'url',
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .ofType('url')
        .withPlaceholder(
          context.translationKeys.AssessmentFormCtrl_AssessmentUrl
        )
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .withDgatId('assessmentForm-787')
        .hiddenWhen(() => {
          const providerNameIsCredSpark = vm.getIsCredSpark({
            userProviderName: vm.providerName,
          });
          return (
            (vm.isCredSpark || providerNameIsCredSpark) &&
            context.inputContext.isEditing
          );
        })
        .validatedByIndexed({
          urlValidation: {
            expression: (url: AbstractControl) => {
              return vm.validateAssessmentUrl(url.value, vm.isCredSpark);
            },
            message: context.translationKeys.AssessmentFormCtrl_UrlRequired,
          },
        })
        .build();
    } else {
      return this.formBuilder
        .customField('url', null, context.templates.urlBrokenValidation, {
          label: this.translateService.instant(
            context.translationKeys.AssessmentFormCtrl_AssessmentUrl
          ),
          invalidUrlMessage: this.translateService.instant(
            context.translationKeys.AssessmentFormCtrl_UrlRequired
          ),
          brokenUrlMessage: this.translateService.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
          originalUrl,
          isRequired: true,
          placeholderText: this.translateService.instant(
            context.translationKeys.AssessmentFormCtrl_AssessmentUrl
          ),
        })
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .withDgatId('assessmentForm-787')
        .hiddenWhen(() => {
          const providerNameIsCredSpark = vm.getIsCredSpark({
            userProviderName: vm.providerName,
          });

          return (
            (vm.isCredSpark || providerNameIsCredSpark) &&
            context.inputContext.isEditing
          );
        })
        .validatedByIndexed({
          urlValidation: {
            expression: (url: AbstractControl) => {
              return vm.validateAssessmentUrl(url.value, vm.isCredSpark);
            },
            message: context.translationKeys.AssessmentFormCtrl_UrlRequired,
          },
        })
        .validatedBy((control: AbstractControl) =>
          hasBrokenUrlValidator(
            control,
            this.translateService.instant(
              'dgOrgInternalContent_BrokenLinkError'
            ),
            vm.hasBrokenUrl,
            control.value !== originalUrl
          )
        )
        .autofocused()
        .onFocus((event, model, formControl) => {
          formControl.markAsTouched();
          formControl.updateValueAndValidity();
        })
        .build();
    }
  }
}
