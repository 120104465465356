import { Injectable } from '@angular/core';
import { TrackerService } from '@dg/shared-services';
import { AwardMappingToAPI } from '../award.model';
@Injectable()
export class AwardTrackerService {
  constructor(private tracker: TrackerService) {}

  public trackAwardAdded(sourceObject: AwardMappingToAPI) {
    const { contentTypeId, title, source, url, startDate, tags } = sourceObject;

    const eventDataToTrack = {
      action: 'Achievement Added',
      properties: {
        achievementType: contentTypeId,
        title,
        issuer: source,
        issuerUrl: url,
        startDate,
        tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackAwardUpdated(sourceObject: AwardMappingToAPI) {
    const { contentTypeId, title, source, url, startDate, tags } = sourceObject;

    const eventDataToTrack = {
      action: 'Achievement Updated',
      properties: {
        achievementType: contentTypeId,
        title,
        issuer: source,
        issuerUrl: url,
        startDate,
        tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
