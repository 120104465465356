<form class="form-wrap" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [isPendingWithOverlay]="true"
    [isSubmitDisabled]="!collaborators.length"
    [isSubmitPending]="isSubmitPending"
    [submitButtonText]="submitButtonText"
    [submitButtonType]="isSubmitPending ? 'passive' : 'primary'"
    [useDefaultForm]="false"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">{{ headerText }}</ng-container>

    <!-- Modal Body -->
    <ng-container class="modal-body">
      <div class="guts-p-h-2 guts-p-v-2">
        <div class="rel">
          <dgx-user-search
            [recipients]="collaborators"
            [ariaLabel]="'Core_Collaborator' | translate"
            (selectRecipient)="onCollaboratorSelect($event)"
            (removeRecipient)="onCollaboratorRemove($event)"
          ></dgx-user-search>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>
