<form class="form-wrap" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="!submitEnabled"
    [submitButtonText]="submitButtonText"
    [useDefaultForm]="false"
    [cancelButtonText]="isReviewOnly ? i18n.Core_Done : i18n.Core_Cancel"
    bodyClasses="guts-p-1"
  >
    <ng-container class="modal-header">
      {{ headerText }}
    </ng-container>
    <ng-container class="modal-body">
      <div class="integration-ratings-review-body">
        <div class="guts-p-full-1-quart">
          <p *ngIf="isReviewOnly">
            {{ i18n.dgTagRating_AddedIntegrationSkillsProfileDetails }}
          </p>
          <p *ngIf="!isReviewOnly">
            {{ i18n.dgTagRating_FoundIntegrationSkillsProfileDetails }}
          </p>
        </div>
        <dgx-data-table
          [columns]="columns"
          [items]="skillAssessments"
          [canSelect]="!isReviewOnly"
          [canSort]="false"
          [propTrackingKey]="propTrackingKey"
        ></dgx-data-table>
      </div>
    </ng-container>
  </dgx-modal>
</form>

<!-- Skill Name-->
<ng-template #skillName let-item>
  <div [dgxLineClampHtml]="item"></div>
</ng-template>
