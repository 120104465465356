import { Injectable, Type } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delayWhen, finalize } from 'rxjs/operators';

import { InputsService } from '@app/inputs/services/inputs.service';
import {
  InlineOutcomeSubmitter,
  OutcomeShowFormParams,
} from '@app/outcomes/outcomes.model';
import { AuthService } from '@app/shared/services/auth.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { UserOutcomeService } from './user-outcome.service';

import { AccomplishmentGlobalAddComponent } from '@app/user-content/user-outcome-v2/outcomes/accomplishment/components/global-add/accomplishment-global-add.component';
import { BadgeGlobalAddComponent } from '@app/user-content/user-outcome-v2/outcomes/badge/components/global-add/badge-global-add.component';
import { CertificateComponent } from '@app/user-content/user-outcome-v2/outcomes/certificate/components/global-add/certificate-modal.component';
import { DegreeGlobalAddComponent } from '@app/user-content/user-outcome-v2/outcomes/degree/components/global-add/degree-global-add.component';
import { AccomplishmentModalComponent } from '@app/user-content/user-outcome/accomplishment-modal/accomplishment-modal.component';
import { AwardModalComponent } from '@app/user-content/user-outcome/award-modal/award-modal.component';
import { BadgeModalComponent } from '@app/user-content/user-outcome/badge-modal/badge-modal.component';
import { CertificateModalComponent } from '@app/user-content/user-outcome/certificate-modal/certificate-modal.component';
import { DegreeModalComponent } from '@app/user-content/user-outcome/degree-modal/degree-modal.component';
import { LDFlagsService } from '@dg/shared-services';
import { OutcomeType, UserOutcomeDetail } from '../outcomes-api.model';
import { AwardComponent } from '@app/user-content/user-outcome-v2/outcomes/award/components/global-add/award-modal.component';

interface OutcomeModalConfig {
  component: Type<any>;
  inferredSkillsComponent?: Type<any>; // for the new global add forms with inferred skills
  /** Allows optional additional async side effects to be chained with add user outcome operation observable */
  addSideEffect?: (
    outcomeDetail: UserOutcomeDetail
  ) => Observable<UserOutcomeDetail>;
  /** Allows optional load */
  load?: (identifier: {
    userOutcomeId: number;
  }) => Observable<UserOutcomeDetail>;
}

@Injectable({
  providedIn: 'root',
})
export class UserOutcomeModalService {
  private static readonly trackingArea = 'GlobalAdd';

  private readonly modalComponentConfig: Partial<
    Record<OutcomeType, OutcomeModalConfig>
  > = {
    Award: {
      component: AwardModalComponent,
      inferredSkillsComponent: AwardComponent,
    },
    Badge: {
      component: BadgeModalComponent,
      inferredSkillsComponent: BadgeGlobalAddComponent,
    },
    Certificate: {
      component: CertificateModalComponent,
      inferredSkillsComponent: CertificateComponent,
    },
    Accomplishment: {
      component: AccomplishmentModalComponent,
      inferredSkillsComponent: AccomplishmentGlobalAddComponent,
    },
    Degree: {
      component: DegreeModalComponent,
      inferredSkillsComponent: DegreeGlobalAddComponent,
    },
    // TODO: Add more outcome modal configs here as migrated
  };

  constructor(
    private modalService: ModalService,
    private focusStackService: FocusStackService,
    private userOutcomeService: UserOutcomeService,
    private authService: AuthService,
    private inputsService: InputsService,
    private ldFlagsService: LDFlagsService
  ) {}

  /**
   * Used in profile-overview and global add
   *
   * @param options
   * @returns Observable<void>
   */
  public add(options: OutcomeShowFormParams) {
    return this.showForm({
      ...options,
      isEditing: false,
    });
  }

  /**
   * Used in profile collection (ajs)
   * and in profile-overview (ngx)
   *
   * @param options
   * @returns Observable<void>
   */
  public edit(options: OutcomeShowFormParams) {
    return this.showForm({
      ...options,
      isEditing: true,
    });
  }

  public showForm(options: OutcomeShowFormParams) {
    const { outcomeType } = options;
    const modalConfig = this.getModalComponentConfig(outcomeType);

    if (modalConfig) {
      const { sourceTarget } = options;
      this.focusStackService.push(sourceTarget);
      return this.showFormComponent(modalConfig, options);
    }
  }

  /**
   * Gets the assigned component type for a particular type of output
   * @param {OutcomeType} type
   * @returns Type<any>
   */
  // TODO: Make this private once we have actual component types to test against
  public getModalComponentConfig(type: OutcomeType): OutcomeModalConfig {
    return this.modalComponentConfig[type];
  }

  /**
   * Uses the ngx version of ModalService to open a modal using a component
   * components should be defined by the `ModalComponent` mapping at the top of this file.
   *
   * @param {Type<any>} modalComponent
   * @param {ModalOptions} modalOptions
   * @returns Observable<void>
   */
  private showFormComponent(
    modalConfig: OutcomeModalConfig,
    options: OutcomeShowFormParams
  ) {
    const {
      modalOptions,
      sourceTarget,
      trackingAction,
      trackingArea,
      ...inputs
    } = options;

    const submitFn: InlineOutcomeSubmitter = (
      options.userOutcomeId
        ? this.userOutcomeService.updateUserOutcome
        : this.userOutcomeService.addUserOutcome
    ).bind(this.userOutcomeService);

    inputs.submit = (contentItemViewModel) =>
      submitFn(
        contentItemViewModel,
        trackingArea || UserOutcomeModalService.trackingArea,
        trackingAction
      ).pipe(
        // delayWhen allows us to fire any side effect, wait for it to return, but still provide the original result
        delayWhen(
          () => modalConfig.addSideEffect?.(contentItemViewModel) || of(void 0)
        ),
        finalize(() => {
          this.inputsService.notifyInputModified(
            contentItemViewModel.contentTypeId
          );
        })
      );

    inputs.load = ({ userOutcomeId }) =>
      this.userOutcomeService.getUserOutcome(
        this.authService.authUser.viewerProfile.userProfileKey,
        userOutcomeId
      );

    this.focusStackService.push(sourceTarget);

    const addModalsInferredSkills = [
      'Accomplishment',
      'Certificate',
      'Badge',
      'Award',
      'Degree',
    ];
    const editModalsInferredSkills = [
      'Accomplishment',
      'Certificate',
      'Badge',
      'Award',
      'Degree',
    ];
    if (
      this.ldFlagsService.showUpdatedAchievementModals &&
      addModalsInferredSkills.includes(options.outcomeType) &&
      (!options.isEditing ||
        (options.isEditing &&
          editModalsInferredSkills.includes(options.outcomeType)))
    ) {
      return this.modalService.show<void>(modalConfig.inferredSkillsComponent, {
        ...modalOptions, // restructure options for modal service
        inputs,
      });
    } else {
      return this.modalService.show<void>(modalConfig.component, {
        ...modalOptions, // restructure options for modal service
        inputs,
      });
    }
  }
}
