import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class InputValidationService {
  constructor(private translate: TranslateService) {}

  public getDurationValidationErrors(
    durationHours: number,
    durationMinutes: number
  ): {
    durationHoursValidationError: string;
    durationMinutesValidationError: string;
  } {
    const minZeroError = this.translate.instant('Core_InvalidMinValue', {
      min: 0,
    });
    const minOneError = this.translate.instant('Core_InvalidMinValue', {
      min: 1,
    });
    let durationHoursValidationError;
    let durationMinutesValidationError;

    if (
      durationMinutes !== null &&
      durationMinutes <= 0 &&
      (!durationHours || durationHours < 0)
    ) {
      durationMinutesValidationError = minOneError;
    } else if (durationMinutes < 0) {
      durationMinutesValidationError = minZeroError;
    } else {
      durationMinutesValidationError = null;
    }

    durationHoursValidationError = durationHours < 0 ? minZeroError : null;

    return { durationHoursValidationError, durationMinutesValidationError };
  }
}
