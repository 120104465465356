import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { InputType } from '@app/shared/models/core-api.model';
import { Injectable, Inject, NgZone } from '@angular/core';
import {
  InputsFacadeBase,
  InputSubmissionResult,
} from '@app/user-content/services/inputs-facade-base';
import { INPUT_CONTEXT, INPUT_ENTITY_MODEL } from '../user-input.tokens';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { DfFormFieldBuilder, DfFormFieldConfig } from '@lib/fresco';
import { AuthService } from '@app/shared/services/auth.service';
import { RepositoryFactoryService } from '../services/repository-factory.service';
import { MapperFactoryService } from '../services/mapper-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { CommentsApiService } from '@app/comments/comments-api.service';
import { RendererContext } from '../form-renderer.model';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import { InputContext, RenderMode } from '../user-input.model';
import { EpisodeFormModel } from './episode-forms.model';
import { EpisodeApiEntity } from './repository/episode.entity.model';
import { EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { ExternalProvidersService } from '@app/inputs/services/external-providers.service';
import { ExternalProvidersAPI } from '@app/inputs/services/externalProviders.model';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { lazySearch } from '@dg/shared-rxjs';
import { EpisodeInfoRenderer } from './renderers/episode-info.renderer';
import { DurationConverterService } from '@app/shared/services/duration-converter.service';
import { EpisodeContentCatalogRendererService } from './renderers/episode-content-catalog.renderer';
import { EpisodeContentCatalogInitialRendererService } from './renderers/episode-content-catalog-initial.renderer';
import { DgError } from '@app/shared/models/dg-error';
import { EpisodeProfileEditRenderer } from './renderers/episode-profile-edit.renderer';
import { InputNotificationService } from '@app/user-content/services/input-notification.service';
import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { CHUploadService } from '@degreed/content-hosting-data-access';

@Injectable({ providedIn: 'any' })
export class EpisodeFacade extends InputsFacadeBase<
  EpisodeFormModel,
  EpisodeApiEntity
> {
  private i18n = this.translate.instant([
    'EpisodeFormCtrl_Episode',
    'EpisodeFormCtrl_SelectEpisode',
    'EpisodeFormCtrl_SelectTitle',
    'EpisodeFormCtrl_ValidNumber',
    'EpisodeFormCtrl_Category',
    'EpisodeFormCtrl_EpisodeError',
    'EpisodeFormCtrl_Loading',
    'EpisodeFormCtrl_ChooseEpisode',
    'EpisodeFormCtrl_NoEpisodes',
    'EpisodeFormCtrl_NoMatch',
    'EpisodeFormCtrl_DateCompleted',
    'Core_AddingGroupsInfo',
    'Core_AddGroups',
    'Core_PodcastUrl',
    'Core_PodcastNameUrl',
    'Core_AudioNamePlaceholder',
    'Core_ICreatedAudio',
    'Core_PodcastHelp',
    'Core_AudioTitlePlaceholder',
    'Core_AudioLength',
    'EpisodeFormCtrl_WhatDidYouLearnLong',
    'EpisodeFormCtrl_WhatDidYouLearnShort',
    'Core_SaveAudio',
    'Core_Minutes',
    'Core_Description',
  ]);

  private hostedContentUploadComplete$ = new ReplaySubject<void>();

  constructor(
    @Inject(INPUT_CONTEXT) inputContext: InputContext,
    @Inject(INPUT_ENTITY_MODEL) initialModel: EpisodeApiEntity,
    contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    builder: DfFormFieldBuilder,
    authService: AuthService,
    repositoryFactory: RepositoryFactoryService,
    mapperFactory: MapperFactoryService,
    translate: TranslateService,
    tracker: TrackerService,
    inputsService: InputsService,
    commentsApiService: CommentsApiService,
    orgInternalContentService: OrgInternalContentService,
    inputNotificationService: InputNotificationService,
    inputTrackingService: InputTrackingService,
    tipService: TipService,
    private episodeRenderer: EpisodeInfoRenderer,
    private episodeProfileEditRenderer: EpisodeProfileEditRenderer,
    private contentCatalogEpisodeInitialRenderer: EpisodeContentCatalogInitialRendererService,
    private contentCatalogEpisodeRenderer: EpisodeContentCatalogRendererService,
    private externalProvidersService: ExternalProvidersService,
    private ngZone: NgZone,
    private durationConverterService: DurationConverterService,
    private contentHostingUploadService: CHUploadService
  ) {
    super(
      inputContext,
      initialModel,
      contentCatalogFormBuilderService,
      builder,
      authService,
      repositoryFactory,
      mapperFactory,
      translate,
      tracker,
      inputsService,
      commentsApiService,
      orgInternalContentService,
      inputNotificationService,
      inputTrackingService,
      tipService
    );
  }

  public get isEpisodeListEmpty() {
    return (
      this.viewModel.episodeList === undefined ||
      this.viewModel.episodeList?.length === 0
    );
  }

  public get submitButtonText() {
    // content catalog requires a url or uploading a file before inputting podcast information
    return this.inputContext.renderMode === RenderMode.ContentCatalog &&
      !this.isEditing &&
      !this.viewModel?.isFormInitialized
      ? this.translate.instant(`Core_Next`)
      : this.translate.instant('Core_SaveAudio');
  }

  protected get extendedDefaultViewModel(): Partial<EpisodeFormModel> {
    const formTitle = this.translate.instant(
      this.inputContext.isEditing ? 'Core_EditAudio' : 'Core_AddAudio'
    );

    return {
      episode: null,
      episodeList: [],
      hasNoPodcastMatch: false,
      inputExists: false,
      isDurationDisabled: false,
      isFormInitialized: false,
      isLoadingEpisode: false,
      isLoadingEpisodes: false,
      podcastType: null,
      selectedEpisode: null,
      selectedPodcast: null,
      formTitle: formTitle,
      submitButtonText: this.submitButtonText,
      inputType: 'Episode',
      nameOrUrl: '',
      addToCatalog: false,
      shouldShowSubmitButton$: of(false),
      supportsContentHosting:
        !!this.authService.authUser?.canUploadContentFiles,
      completionInfo: {
        length: null,
        dateRead: null,
      },
      authUser: this.authService.authUser,
      organizationId: this.orgId,
      updateUIConfiguration: this.updateUIConfiguration.bind(this),
      onAddToCatalogChange: this.onAddToCatalogChange.bind(this),
      initializedForm: {
        addToCatalogSection: {
          // default true for content catalog, false for all others (pathway, global add)
          addToCatalog:
            this.inputContext.renderMode === RenderMode.ContentCatalog,
        },
        title: '',
        audioAuthored: false,
        comment: '',
        description: '',
        dateCompleted: null,
        duration: null,
      },
      // template configuration
      formFields: {
        episodeInitialization: {
          placeholder: this.i18n.Core_AudioNamePlaceholder,
          noMatchLabel: this.i18n.EpisodeFormCtrl_NoMatch,
          helpLabel: this.i18n.Core_PodcastHelp,
          selectPodcast: (podcast) => this.selectPodcast(podcast),
          onFormInitialInput: (formControl, value) =>
            this.onFormInitialInput(formControl, value),
          formatPodcastSelection: (result) =>
            this.formatPodcastSelection(result),
          getPodcasts: (term) => this.getPodcasts(term),
        },
        episodeSelected: {
          episodeTitleLabel: this.i18n.EpisodeFormCtrl_Episode,
          isEpisodeListEmpty: false,
          loadEpisode: (formControl, $event) =>
            this.loadEpisode(formControl, $event),
          getPlaceholder: () => this.updatePlaceholder(),
          minutesLabel: this.i18n.Core_Minutes,
        },
      },
    };
  }

  /** Override */
  public onSubmit(): Observable<InputSubmissionResult> {
    // must have either a media URL entered or hosted content uploaded before submitting (i.e. advancing to full form) is valid
    if (
      this.inputContext.renderMode === RenderMode.ContentCatalog &&
      !this.isEditing &&
      (!this.viewModel.mediaUrl || this.viewModel.mediaUrl?.length === 0) &&
      !this.viewModel.hostedContentDetails
    ) {
      if (
        this.inputContext.renderMode === RenderMode.ContentCatalog &&
        !this.viewModel.hostedContentDetails
      ) {
        return this.checkAndUpdateUrlDuplicates().pipe(
          mergeMap(() => {
            return EMPTY;
          })
        );
      }
      return EMPTY;
    }
    // initialize the form and show the expanded form view
    // for hosted content the form will be expanded as if Next clicked once the upload is complete
    else if (!this.isEditing && !this.viewModel.isFormInitialized) {
      if (
        this.inputContext.renderMode === RenderMode.ContentCatalog &&
        !this.viewModel.hostedContentDetails
      ) {
        return this.checkAndUpdateUrlDuplicates().pipe(
          mergeMap(() => {
            this.initFormForUrl();
            this.viewModel.nameOrUrl = this.viewModel.mediaUrl;
            this.viewModel.submitButtonText = this.submitButtonText;
            this.updateUIConfiguration();
            return EMPTY;
          })
        );
      }
      this.initFormForUrl();
      this.viewModel.nameOrUrl = this.viewModel.mediaUrl;
      this.viewModel.submitButtonText = this.submitButtonText;
      this.updateUIConfiguration();
      return EMPTY; // Close stream and don't actually submit if parsing (i.e. "Next" button pressed)
    } else {
      // update on submit incase the url has changed.
      if (
        this.inputContext.renderMode === RenderMode.ContentCatalog &&
        !this.viewModel.hostedContentDetails
      ) {
        return this.checkAndUpdateUrlDuplicates().pipe(
          mergeMap(() => {
            return super.onSubmit();
          })
        );
      }
      return super.onSubmit();
    }
  }

  protected buildUIConfiguration(): DfFormFieldConfig[] {
    const context: RendererContext = {
      inputContext: {
        renderMode: this.inputContext.renderMode,
        inputType: 'Episode',
        isCompleting: this.isCompleting,
      },
      state: () => this.viewModel,
      templates: this.templates,
      translationKeys: this.i18n,
    };

    switch (this.inputContext.renderMode) {
      case RenderMode.UserProfile:
        return this.isEditing
          ? this.episodeProfileEditRenderer.render(context)
          : this.episodeRenderer.render(context);
      case RenderMode.Pathways:
        if (this.inputContext.isEditing) {
          return this.contentCatalogEpisodeRenderer.render(context);
        } else {
          return this.episodeRenderer.render(context);
        }
      case RenderMode.ContentCatalog:
        this.checkForContentHostingSupport();

        if (
          this.viewModel.isFormInitialized ||
          this.viewModel.hostedContentDetails ||
          this.inputContext.isEditing
        ) {
          return this.contentCatalogEpisodeRenderer.render(context);
        } else {
          return this.contentCatalogEpisodeInitialRenderer.render(context);
        }

      default:
        throw `Podcast Facade - No render mode defined for ${this.inputContext.renderMode}`;
    }
  }

  /**
   * When a podcast is selected, this function runs to set the status of the form to
   * initialized and loads the matching episode list.
   *
   * @param {ExternalProvidersAPI.FindExternalResponse} podcast Object representing a podcast feed
   */
  private selectPodcast(podcast: ExternalProvidersAPI.FindExternalResponse) {
    this.viewModel.isLoadingEpisodes = true;
    this.viewModel.selectedPodcast = podcast;
    this.viewModel.podcastType = 'selected';
    this.viewModel.nameOrUrl = podcast.title;

    this.externalProvidersService
      .getEpisodesList(podcast.id)
      .pipe(first())
      .subscribe((res: ExternalProvidersAPI.GetEpisodesResponse[]) => {
        // the Fresco formly .hiddenWhen does not handle async, so run it in
        // ngZone and explicitly markForCheck to force the update PD-59857
        this.ngZone.run(() => {
          this.viewModel.episodeList = res || [];
          this.viewModel.isLoadingEpisodes = false;
          this.viewModel.isFormInitialized = true;
          this.updatePlaceholder();
          this.updateUIConfiguration();
        });
      });
  }

  /**
   * Once an episode is selected from the dropdown list, this function will set
   * the duration on the form field if available and set the status of the episode
   * as preexisting or not and set the `selectedEpisode` value on the model to display
   * the episode's details in the form as a non-editable display.
   *
   * @param {FormControl} formControl
   * @param episode
   */
  private loadEpisode(
    formControl: FormControl,
    episode: ExternalProvidersAPI.GetEpisodesResponse
  ) {
    this.viewModel.isLoadingEpisode = true;
    return this.externalProvidersService
      .getEpisodeDetails(episode.id)
      .pipe(first())
      .subscribe((response: ExternalProvidersAPI.GetEpisodeDetailsResponse) => {
        this.viewModel.selectedEpisode = response;

        // sets duration from the response, if available
        if (response?.duration) {
          this.viewModel.initializedForm.duration =
            this.durationConverterService.fromSecondsToMinutes(
              response.duration
            );
          this.viewModel.isDurationDisabled = true;
        }

        this.viewModel.initializedForm = {
          ...this.viewModel.initializedForm,
          durationMinutes: response.durationMinutes,
          durationHours: response.durationHours,
        };
        this.viewModel.shouldShowSubmitButton$ = of(true);
        this.checkIfExistsInCatalog(episode.feedUrl);
        this.viewModel.isLoadingEpisode = false;
        this.updateUIConfiguration();
      });
  }

  /**
   * Calls the backend with a url to check against the org catalog to verify if the episode
   * already exists in the catalog.  The string must be an exact match, so this may not catch
   * duplicates if the episode was added to the catalog manually with a different url.
   *
   * @param {string} url URL of a podcast episode
   */
  private checkIfExistsInCatalog(url: string) {
    this.inputsService
      .getInputDurationByUrl('Episode', url)
      .pipe(
        first(),
        catchError((e) => {
          // suppress error for 404
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if (response) {
          this.viewModel.inputExists = true;
        }
      });
  }

  /**
   * This handles what text should be shown to the user on the episode list dropdown
   * and in the dropdown itself as placeholder text if the list has not yet loaded.
   */
  private updatePlaceholder() {
    if (this.viewModel.isLoadingEpisodes) {
      return this.i18n.EpisodeFormCtrl_Loading;
    } else if (!this.viewModel.isLoadingEpisodes && this.isEpisodeListEmpty) {
      return this.i18n.EpisodeFormCtrl_NoEpisodes;
    } else {
      return this.i18n.EpisodeFormCtrl_ChooseEpisode;
    }
  }

  /**
   * This runs when the user types into the initial field and checks if the input
   * matches a url using isUrl, and if so, it will initialize the form
   * as a URL form type and halt the name search, otherwise the input will be passed
   * onto the ngbTypeahead handler and run `getPodcasts` to retrieve a list of matching
   * podcasts to select.
   *
   * @param {FormControl} formControl The FormControl for the input field
   * @param {string} value String the user inputs in the field
   */
  private onFormInitialInput(formControl: FormControl, value: string) {
    // reset form when
    // the value is cleared
    // the value was a url and is no longer a url
    if (
      value.length === 0 ||
      (this.viewModel.podcastType === 'url' && !this.isUrl(value))
    ) {
      this.viewModel.isFormInitialized = false;
      this.viewModel.shouldShowSubmitButton$ = of(false);
      this.viewModel.podcastType = undefined;
      this.viewModel.nameOrUrl = '';
      return;
    }

    // if it's a url set the value, type and initialize the form
    if (this.isUrl(value)) {
      this.initFormForUrl();
    }

    this.viewModel.nameOrUrl = value;
  }

  /**
   * When a podcast is selected from ngbTypeahead's list, it will use this to
   * format what the text in the field prints as the title of the podcast selected.
   *
   * @param {ExternalProvidersAPI.GetEpisodeDetailsResponse} episode Episode object returned from the backend
   */
  private formatPodcastSelection(
    episode: ExternalProvidersAPI.GetEpisodeDetailsResponse
  ): string {
    return episode?.title || '';
  }

  /**
   * Handler for ngbTypeahead that takes the term input and runs a search to match
   * against podcast titles.
   *
   * @param {Observable<string>} term ngbTypeahead passes an observable of the user input value back to the handler
   */
  private getPodcasts: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly any[]> => {
    return term.pipe(
      lazySearch(
        (t) => this.getPodcastsSearch(t),
        () => true // bypassing the default filter so we can handle clearing the popover if the text is deleted
      )
    );
  };

  /**
   * Does a backend check to get a list of podcasts that match a given term
   *
   * @param {string} term User input
   */
  private getPodcastsSearch(term: string) {
    if (this.getPodcastsFilter(term)) {
      return this.externalProvidersService.findPodcasts(term).pipe(
        this.takeUntilDestroyed(),
        map((t) => {
          return this.handleGetPodcastResponse(t);
        })
      );
    }

    return of([]);
  }

  /**
   * Check for the user input to match before the term is sent to the server for search
   *
   * @param {string} term User input
   */
  private getPodcastsFilter(term: string) {
    return term.length >= 2 && !this.isUrl(term);
  }

  /**
   * Handles some logic around if there are or are not podcasts in the list
   * after an API call
   *
   * @param {ExternalProvidersAPI.FindExternalResponse[]} podcasts List of podcasts returned by the server
   */
  private handleGetPodcastResponse(
    podcasts: ExternalProvidersAPI.FindExternalResponse[]
  ): ExternalProvidersAPI.FindExternalResponse[] {
    this.viewModel.hasNoPodcastMatch = false;

    if (!podcasts || podcasts.length === 0) {
      this.viewModel.hasNoPodcastMatch = true;
      this.updateUIConfiguration();
      return [];
    }

    return podcasts;
  }

  /**
   * Simplistic url check to see if a string starts with http: / https: / ftp:
   *
   * @param {string} text Line of text to check
   */
  private isUrl(text: string) {
    const urlExp = /(\b(https?|ftp):)/gi; // http(s): & ftp: match
    return new RegExp(urlExp).test(text);
  }

  private onAddToCatalogChange(shouldAdd: boolean) {
    const isAdd: boolean =
      shouldAdd &&
      this.viewModel.podcastType === 'url' &&
      this.viewModel.nameOrUrl !== null;

    this.fetchDuplicates(
      isAdd,
      this.viewModel.organizationId,
      this.viewModel.nameOrUrl
    ).subscribe();

    this.viewModel.addToCatalog = shouldAdd;
  }

  private initFormForUrl() {
    this.viewModel.selectedPodcast = null;
    this.viewModel.isFormInitialized = true;
    this.viewModel.shouldShowSubmitButton$ = of(true);
    this.viewModel.podcastType = 'url';
    this.viewModel.hasNoPodcastMatch = false;

    // reset if podcast had already been picked
    this.viewModel.initializedForm.duration = null;
    this.viewModel.isDurationDisabled = false;
  }

  /**
   * Check for content hosting support and update view model with FileUploadSettings if true.
   *
   * @returns void
   */
  protected checkForContentHostingSupport() {
    this.contentHostingUploadService
      .canUploadHostedFile({
        uploadType: 'Episode',
        renderMode: this.inputContext.renderMode,
      })
      .subscribe((response) => {
        const [supportsContentHosting, fileRestrictions] = response;
        this.viewModel = {
          ...this.viewModel,
          shouldShowContentUploader: supportsContentHosting, // determines to load upload component in modal container
          fileRestrictions: fileRestrictions,
        };
      });
  }

  private checkAndUpdateUrlDuplicates() {
    const inputIdentifier: InputIdentifier = {
      inputId: this.viewModel.inputId,
      inputType: this.viewModel.inputType as InputType,
    };

    this.viewModel.mediaUrl = this.inputsService.cleanUrl(
      this.viewModel.mediaUrl
    );

    return this.fetchDuplicates(
      true,
      this.viewModel.organizationId,
      this.viewModel.mediaUrl,
      inputIdentifier
    );
  }
}
