import { GroupPrivacyLevel } from '@app/groups/group-api';

/**
 * Convert privacy level text to number
 */
export function getPrivacyLevelText(level: string | number): string {
  if (typeof level === 'number') {
    switch (level) {
      case GroupPrivacyLevel.Open:
        return 'Open';
      case GroupPrivacyLevel.Closed:
        return 'Closed';
      case GroupPrivacyLevel.Private:
        return 'Private';
      case GroupPrivacyLevel.Administrative:
        return 'Administrative';
    }
  }

  return level as string;
}

/**
 * Convert privacy level number to text
 */
export function getPrivacyLevelNumber(level: string | number): number {
  if (typeof level === 'string') {
    switch (level) {
      case 'Open':
        return GroupPrivacyLevel.Open;
      case 'Closed':
        return GroupPrivacyLevel.Closed;
      case 'Private':
        return GroupPrivacyLevel.Private;
      case 'Administrative':
        return GroupPrivacyLevel.Administrative;
    }
  }

  return level as number;
}

/**
 * Convert API permissions array to object for view model
 *
 * @example
 * const permissions = ['ManageContent']; // typically from API
 *
 * transformPermissions(permissions);
 *
 * // returns...
 *
 * {
 *   canManageContent: true,
 * }
 */
export function transformPermissions(
  permissions: string[] = []
): Record<string, true> {
  return permissions.reduce((acc, permission) => {
    const key = `can${permission}`;

    acc[key] = true;

    return acc;
  }, {});
}
