<dgx-modal
  [canCloseHeader]="true"
  [headerClasses]="headerClasses"
  [canCancel]="true"
  [cancelButtonText]="cancelButtonText"
  [useDefaultSubmitButton]="false"
  (dismiss)="onDismiss()"
>
  <!-- Modal Header -->
  <ng-container class="guts-p-h-1 modal-header">{{ headerText }}</ng-container>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <p [innerHTML]="bodyText" class="guts-p-h-1 guts-p-t-1 break par"></p>
  </ng-container>
  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      (click)="onSubmit($event)"
      data-dgat="cancel-tag-rating-request-modal-631"
    >
      {{ submitButtonText | translate }}
    </button>
  </ng-container>
</dgx-modal>
