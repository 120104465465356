import {
  SearchFilterId,
  SearchFiltersService,
  SearchFlagsService,
  SearchOrigin,
  SearchService,
  SearchTypeaheadInitLocations,
} from '@app/search/services';

import { AuthUser } from '@app/account/account-api.model';
import { OrganizationModel } from '@app/orgs/services/orgs.model';

import {
  SearchComputedState,
  SearchFilter,
  SearchState,
} from '../models/search.model';
import { TranslateService } from '@ngx-translate/core';
import { OpportunityPermissionsService } from '@app/opportunities/services/opportunity-permissions.service';
import { CareerPathNamingService } from '@app/shared/services/career-path-naming.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { BreakpointState } from '@angular/cdk/layout';
import { LDFlagsService } from '@dg/shared-services';

export interface ComputeServices {
  searchService: SearchService;
  searchFlagsService: SearchFlagsService;
  translateService: TranslateService;
  opportunityPermissionsService: OpportunityPermissionsService;
  careerPathNamingService: CareerPathNamingService;
  searchFiltersService: SearchFiltersService;
  windowLayoutService: WindowLayoutService;
  ldFlagsService: LDFlagsService;
}

export interface ComputeLookups extends ComputeServices {
  authUser: AuthUser;
  organizations: OrganizationModel[];
  breakpointState: BreakpointState;
}

/**
 * BuildComputed()
 *
 * SearchViewModel == SearchState + SearchComputedState, were ComputedState is derived
 * from current SearchState and other services.
 */
export function buildComputed(
  state: SearchState,
  lookups: ComputeLookups
): SearchComputedState {
  const { authUser, organizations, breakpointState, ...services } = lookups;
  ComputedUtils._s = services;

  const { defaultOrgInfo, hasCareerPathing } = authUser;
  const isClientProvider = defaultOrgInfo?.settings.isClientProvider;
  const i18n = ComputedUtils.mergeI18n(state);
  const showOrgSelector = ComputedUtils.shouldShowOrgSelector(organizations);
  const showTypoSuggestion = ComputedUtils.getTypoSuggestionVisibility(state);
  const endorsedFilterSelected = ComputedUtils.endorsedFilterSelected(state);
  const canViewOpportunities = ComputedUtils.canViewOpportunities();
  const selectedOrganization = ComputedUtils.getSelectedOrg(
    state,
    organizations
  );
  const transformedSearchFilters = ComputedUtils.getTransformedSearchFilters(
    state,
    hasCareerPathing
  );
  const defaultOrgId = defaultOrgInfo?.organizationId;
  const searchInitiationLocation = SearchTypeaheadInitLocations.searchBar;
  const showFilterReset = ComputedUtils.shouldShowFilterReset(state);
  const showNoResults = ComputedUtils.shouldShowNoResults(state);
  const showPrioritizedEndorsedContent =
    ComputedUtils.shouldShowPrioritizedEndorsedContent(authUser);

  const ldFlags = services.searchFlagsService;
  const isMobile =
    !!breakpointState.breakpoints[
      services.windowLayoutService.dgBreakpoints.BabyBear
    ];
  const isPhone =
    !!breakpointState.breakpoints[
      services.windowLayoutService.dgBreakpoints.TeenieBear
    ];

  const isMarketplaceEnabled =
    (!authUser.disableDegreedMarketplace &&
      services.ldFlagsService.showMarketplace) ||
    services.ldFlagsService.isPaidCMUser;

  const showFeaturedCarousel = ComputedUtils.getShowFeaturedCarousel(state);
  const showSearchFiltersSidebar = ComputedUtils.shouldShowSearchFiltersSidebar(
    isPhone,
    state
  );
  const trackingLocation = state.isMarketplaceCat
    ? SearchOrigin.marketplace
    : SearchOrigin.search;

  const computed: SearchComputedState = {
    i18n,
    isClientProvider,
    organizations,
    showOrgSelector,
    selectedOrganization,
    defaultOrgId,
    showTypoSuggestion,
    hasCareerPathing,
    endorsedFilterSelected,
    canViewOpportunities,
    searchInitiationLocation,
    transformedSearchFilters,
    showFilterReset,
    showNoResults,
    ldFlags,
    showPrioritizedEndorsedContent,
    isPhone,
    isMobile,
    showFeaturedCarousel,
    showSearchFiltersSidebar,
    isMarketplaceEnabled,
    trackingLocation,
  };

  return computed;
}

/**
 * Add external catalog if enabled
 */
export function addExternalCatalog(
  organizations: OrganizationModel[],
  authUser: AuthUser,
  showMarketplaceCatalog: boolean,
  getCatalogs: (
    organizations: OrganizationModel[],
    showExternalCatalog: boolean,
    showMarketplaceCatalog: boolean
  ) => OrganizationModel[]
): OrganizationModel[] {
  const hideCatalog = !authUser.defaultOrgInfo?.settings.hideExternalCatalog;

  return getCatalogs(organizations, hideCatalog, showMarketplaceCatalog);
}

/**
 * Fall back to the `defaultOrgId` if `orgId` isnt set
 * and not viewing the external catalog
 */
export function getOrgId(
  { isExternalCat, isMarketplaceCat, orgId }: SearchState,
  { defaultOrgId }: AuthUser
): number {
  return isExternalCat || isMarketplaceCat || orgId ? orgId : defaultOrgId;
}

/**
 * ************************************************************************************************
 * Utils to calculate computed properties for the SearchFacade view model
 * Note: exported only for testing purposes
 * ************************************************************************************************
 */
export namespace ComputedUtils {
  // easy way to register 2..n services for internal
  export let _s: ComputeServices;

  // ************************************************************************************************
  // i18n Utils
  // ************************************************************************************************

  export function mergeI18n({ term }: SearchState): Record<string, string> {
    return {
      ...ComputedUtils.getStaticI18n(),
      ...ComputedUtils.getDynamicI18n(term),
    };
  }

  export const staticI18nStrings = [
    'Core_Search',
    'Core_Follow',
    'Core_UnFollow',
    'Core_Results',
    'Core_Endorsed',
    'dgTagRating_AddToProfileTooltip',
    'dgTagRating_RemoveFromProfileTooltip',
    'dgSkipNav_SkipToSearchResults',
  ];

  export function getStaticI18n(): Record<string, string> {
    return _s.translateService.instant(staticI18nStrings);
  }

  export const dynamicI18nStrings = ['LearningSearch_ResultsFor'];

  export function getDynamicI18n(searchTerm: string): Record<string, string> {
    return _s.translateService.instant(dynamicI18nStrings, {
      searchTerm,
    });
  }

  /**
   * Get the currently selected organization model
   */
  export function getSelectedOrg(
    { isExternalCat, isMarketplaceCat, orgId }: SearchState,
    organizations: OrganizationModel[]
  ): OrganizationModel {
    if (isMarketplaceCat) {
      return _s.searchService.marketplaceCatalog;
    }
    return isExternalCat
      ? _s.searchService.externalCatalog
      : organizations.find((org) => org.organizationId === orgId);
  }

  export function getTransformedSearchFilters(
    { filters }: SearchState,
    hasCareerPathing
  ): SearchFilter[] {
    const careerPathingNames = hasCareerPathing
      ? _s.careerPathNamingService.careerPathNames
      : null;

    return _s.searchFiltersService.transformSearchFilters(
      filters,
      careerPathingNames
    );
  }

  /**
   * Determine typo suggestion visibility
   */
  export function getTypoSuggestionVisibility({
    isLoading,
    suggestedTerm,
    typoAutoSearched,
    previousTerm,
  }: SearchState): boolean {
    return (
      _s.searchFlagsService.showTypoSuggestions &&
      !isLoading &&
      (!!suggestedTerm || (typoAutoSearched && !!previousTerm))
    );
  }

  export function shouldInvalidateFilterCache({
    filters,
  }: SearchState): boolean {
    return filters?.length > 0 ? false : true;
  }

  export function shouldShowOrgSelector(orgs: OrganizationModel[]): boolean {
    return orgs?.length > 1;
  }

  export function canViewOpportunities(): boolean {
    return _s.opportunityPermissionsService.canViewOpportunities();
  }

  export function endorsedFilterSelected({
    appliedFacets,
  }: SearchState): boolean {
    return appliedFacets?.some(({ id }) => id === 'Endorsed');
  }

  /**
   * Should we show the 'Reset Filters (#)' button?
   */
  export function shouldShowFilterReset({
    appliedFacets,
  }: SearchState): boolean {
    if (!appliedFacets) {
      return false;
    }

    return appliedFacets.length > 0;
  }

  export function shouldShowNoResults({
    isLoading,
    results,
  }: SearchState): boolean {
    return !isLoading && !results?.length;
  }

  export function shouldShowPrioritizedEndorsedContent(
    authUser: AuthUser
  ): boolean {
    return (
      _s.searchFlagsService.showPrioritizedEndorsedContent &&
      !authUser?.defaultOrgInfo?.settings?.disableEndorsedSearchResultsSection
    );
  }

  export function getShowFeaturedCarousel(state: SearchState): boolean {
    return state.isMarketplaceCat && !state.term;
  }

  export function shouldShowSearchFiltersSidebar(
    isPhone: boolean,
    state: SearchState
  ): boolean {
    return !!(
      !isPhone &&
      (state.term || state.appliedFacets.length || state.results.length)
    );
  }
}
