import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { NgxHttpClient } from '@app/shared/ngx-http-client';

@Injectable({ providedIn: 'root' })
export class UserGroupService {
  // local
  public orgs: [];
  // private
  private baseGroupAPIUrl: string = '/groups';

  constructor(private authService: AuthService, private http: NgxHttpClient) {}

  /** public methods */
  /**
   * Get currently stored organizations
   */
  public currentOrgs() {
    return this.orgs;
  }

  /**
   * Get user groups
   *
   * @param includeInterests
   */
  public get(includeInterests: boolean) {
    return this.http.get(`${this.baseGroupAPIUrl}/usergroups`, {
      params: {
        includeInterests,
      },
    });
  }

  /**
   * Get user groups from users org groups
   */
  public getGroups() {
    const userOrgs = this.getGroupsByOrg(),
      userGroups = [];
    userOrgs.map((org: any) => {
      org.Groups.map((group: any) => {
        userGroups.push(group);
      });
    });
    return userGroups;
  }

  /**
   * Get top groups based on member activity
   *
   * @param requestedUserKey
   * @param skip
   * @param take
   */
  public getGroupsWithTopActiveMembers(
    requestedUserKey: any,
    skip: number,
    take: number
  ) {
    return this.http.get(
      `${this.baseGroupAPIUrl}/usergroupswithtopactivemembers`,
      {
        params: {
          requestedUserKey,
          skip,
          take,
        },
      }
    );
  }

  /** private methods */
  /**
   * Get org groups for the user
   */
  private getGroupsByOrg() {
    const userInfo = this.authService.authUser,
      userOrgs = userInfo.orgInfo;
    return userOrgs;
  }
}
