<dgx-modal
  [canCancel]="true"
  [canCloseHeader]="false"
  [isHeaderBorderless]="true"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
>
  <ng-container class="modal-body">
    <div class="center-text">
      <div class="l_flexbar eval-endorsement-complete__profile-status">
        <div class="absolute eval-endorsement-complete__profile-status-icon">
          <dgx-profile-pic
            *ngIf="notification.parameters?.person"
            [profile]="notification.parameters.person"
            size="medium"
          ></dgx-profile-pic>
          <df-icon
            [icon]="statusIcon"
            [ngClass]="statusIconClass"
            class="inherit absolute--bottom-right-offset round circle-icon-border bg-color-white"
          ></df-icon>
        </div>
      </div>

      <h3 class="guts-p-t-1 guts-p-b-1-quart font-semibold">
        {{ notification.parameters?.person.userFullName }}
      </h3>
      <p
        class="par par--light_a par--small guts-p-b-3 font-medium"
        [textContent]="statusMessage"
      ></p>
      <p
        *ngIf="notification.parameters?.comment"
        class="h3 font-light guts-p-b-4 guts-p-r-3 guts-p-l-3"
      >
        "{{ notification.parameters.comment }}"
      </p>
    </div>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      type="submit"
      df-button
      dfButtonType="primary"
      [textContent]="i18n.dgUserEvaluationEndorsement_goToApplication"
      (click)="goToApplication()"
      data-dgat="tag-rating-endorsement-completed-modal-3fb"
    ></button>
  </ng-container>
</dgx-modal>
