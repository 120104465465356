import { NgModule } from '@angular/core';
import { DfControlValueDirective } from './control-value.directive';

@NgModule({
  imports: [],
  exports: [DfControlValueDirective],
  declarations: [DfControlValueDirective],
  providers: [],
})
export class DfControlValueModule {}
