import { LOCALES_LAZY, LocalesLazy } from './services/locales-lazy.token';
import { registerLocaleData } from '@angular/common';
import { AuthService } from '@app/shared/services/auth.service';
import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '@app/shared/window.token';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  public localeIds: string[];

  public get defaultLocale() {
    // there are lots of locales we don't (yet) support, if somehow a user has been added to the system, without a localeId set
    // then we want to default them to their browser language if possible. However, if we don't support that locale, then
    // a lot of the app breaks as the translations don't react well to unknown locales.
    const defaultLocale = this.windowRef.navigator.language
      .split('-')[0]
      .toLowerCase();
    if (!this.localeIds.includes(defaultLocale)) {
      return 'en';
    }
    return defaultLocale;
  }

  public get userLocale(): string {
    const user = this.authService.authUser;
    const userLocale = user?.viewerProfile?.localeId;
    const supportedIds = [
      ...this.localeIds.map((l) => l.toLowerCase()),
      'en-JM',
      'fr-CA',
    ];
    const isSupportedLocale =
      supportedIds.includes(userLocale) ||
      supportedIds.includes(userLocale?.split('-')[0]);
    if (userLocale && !isSupportedLocale) {
      return this.defaultLocale;
    }
    return userLocale || this.defaultLocale;
  }

  constructor(
    private authService: AuthService,
    @Inject(WindowToken) public windowRef: any,
    @Inject(LOCALES_LAZY) private localesLazy: LocalesLazy
  ) {
    this.localesLazy.then((locales) => {
      this.localeIds = Object.keys(locales);
    });
  }

  /**
   * Imports the Angular data for the given locale;
   * This could also be done using webpack magic comments if
   * we enable those in the future:
   * see https://stackoverflow.com/a/66372794
   *
   * @param {string} locale The locale to load data
   */
  public loadLocale(localeId: string): Promise<any> {
    // From `en-GB` to `en_gb`. (See: https://degreedjira.atlassian.net/browse/PD-73939)
    localeId = localeId.replace('-', '_').toLowerCase();
    // From `en_gb` to `en` for simple locale matching in the event of missing a more
    // complete language pack.
    const simpleLocale = localeId.split('_')[0].toLowerCase();

    return this.localesLazy
      .then((locales) => {
        // Get full language if possible.
        if (locales[localeId]) {
          return registerLocaleData(locales[localeId]);
        }
        // Fall back to 'parent' language.
        return registerLocaleData(locales[simpleLocale]);
      })
      .catch((err) => {
        console.error('Error loading translations:', err);
      });
  }
}
