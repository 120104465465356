import { Injectable } from '@angular/core';
import { CertificateMappingToAPI } from '../certificate.model';
import { TrackerService } from '@dg/shared-services';
@Injectable()
export class CertificateTrackerService {
  constructor(private tracker: TrackerService) {}

  public trackCertificateAdded(sourceObject: CertificateMappingToAPI) {
    const {
      contentTypeId,
      title,
      source,
      url,
      details,
      startDate,
      endDate,
      tags,
    } = sourceObject;

    const eventDataToTrack = {
      action: 'Achievement Added',
      properties: {
        achievementType: contentTypeId,
        title,
        issuer: source,
        issuerUrl: url,
        details,
        startDate,
        endDate,
        tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackCertificateUpdated(sourceObject: CertificateMappingToAPI) {
    const {
      contentTypeId,
      title,
      source,
      url,
      details,
      startDate,
      endDate,
      tags,
    } = sourceObject;

    const eventDataToTrack = {
      action: 'Achievement Updated',
      properties: {
        achievementType: contentTypeId,
        title,
        issuer: source,
        issuerUrl: url,
        details,
        startDate,
        endDate,
        tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
