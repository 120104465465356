import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[dgxMaxTime]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxTimeValidationDirective,
      multi: true,
    },
  ],
})
export class MaxTimeValidationDirective implements Validator {
  @Input('dgxMaxTime') public maxTime: string;
  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validateMaxTime(control.value, this.maxTime);
  }
  public validateMaxTime(
    currentTime: string,
    maxTime: string
  ): ValidationErrors | null {
    const invalid = maxTime < currentTime;
    return invalid ? { maxTime: false } : null;
  }
}
