import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { AssessmentFormModel } from '../assessment-form.model';
import { AssessmentApiEntity } from '../repository/assessment.entity.model';
import { AssessmentForm } from '../assessment-form.model';

@Injectable({ providedIn: 'root' })
export class PathwayAssessmentMapper
  implements Mapper<any, AssessmentApiEntity>
{
  public toApiParameters(source: AssessmentFormModel): AssessmentApiEntity {
    const assessmentEntity: AssessmentApiEntity = {
      inputType: 'Assessment',
      inputId: source.inputId,
      name: source.title,
      description: source.description,
      percentile: parseInt(source.percentile, 10),
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
      questions: source.questions,
      questionsCorrect: source.questionsCorrect,
      tags: source.tags,
      url: source.url,
      userProviderName: source.providerName,
      orgContentMetadata: {
        groupIds: source.groupIds ? source.groupIds : [],
        hideFromCatalog: !(source.isChannelContext || source.addToCatalog),
      },
      organizationId: source.organizationId,
    };

    return assessmentEntity;
  }
  public toViewModel(source: AssessmentApiEntity): Partial<AssessmentForm> {
    const target: Partial<AssessmentForm> = {
      ...source,
      fileManaged: source.fileManaged,
      title: source.name,
      providerName: source.userProviderName,
      // this isn't on the assessment item, but if we're in pathway edit it must be true
      addToCatalog: true,
    } as any;
    return target;
  }
}
