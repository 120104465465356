<div
  class="form-wrap date-range-form__row"
  [formGroup]="dateRangeForm"
  formGroupName="dateRangeForm"
>
  <div
    class="form-wrap"
    [ngClass]="{
      'date-range-form__col-half': showEndDate,
      'full-width': !showEndDate
    }"
  >
    <dgx-reactive-forms-label for="startDate" [isRequired]="startDateRequired">
      {{ startDateLabel }}
    </dgx-reactive-forms-label>
    <dgx-date-picker
      id="startDate"
      formControlName="startDate"
      (onBlur)="onBlur('startDate')"
      [isMaxDateToday]="isMaxStartDateToday"
      [attr.aria-label]="i18n.Core_SelectStartDate"
      [placeholder]="i18n.Core_SelectDate"
      (dateSelect)="updateEndDateValidation()"
      [disabled]="!startDateEnabled"
    ></dgx-date-picker>
    <dgx-validate-field
      class="block"
      *ngIf="showValidationMessage('startDate')"
      [message]="
        'Core_FieldRequiredFormat' | translate: { fieldName: startDateLabel }
      "
    ></dgx-validate-field>
  </div>
  <div *ngIf="showEndDate" class="date-range-form__col-half">
    <dgx-reactive-forms-label for="endDate" [isRequired]="endDateRequired">
      {{ endDateLabel }}
    </dgx-reactive-forms-label>
    <dgx-date-picker
      id="endDate"
      formControlName="endDate"
      (onBlur)="onBlur('endDate')"
      [isMaxDateToday]="isMaxEndDateToday"
      [attr.aria-label]="i18n.Core_SelectEndDate"
      [placeholder]="i18n.Core_SelectDate"
      [disabled]="!endDateEnabled"
      [minDate]="formModel.startDate"
    ></dgx-date-picker>
    <dgx-validate-field
      class="block"
      *ngIf="showValidationMessage('endDate')"
      [message]="getEndDateValidationMessage()"
    ></dgx-validate-field>
  </div>
</div>
