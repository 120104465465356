import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { isKey, Key } from '@app/shared/key';
import { A11yService } from '@app/shared/services/a11y.service';
import { PopoverComponent } from './popover.component';

@Directive({
  selector:
    // Must be an accessible, clickable element
    'button[dgxPopoverTrigger], [role="button"][dgxPopoverTrigger]',
})
export class PopoverTriggerDirective implements AfterViewInit, OnDestroy {
  @Input() public dgxPopoverTrigger: PopoverComponent;
  public popoverComponent: PopoverComponent;
  private unlisteners: (() => void)[] = [];
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private a11yService: A11yService
  ) {}

  public ngAfterViewInit() {
    this.popoverComponent = this.dgxPopoverTrigger;
    this.popoverComponent.popoverTrigger = this.elementRef;
    this.initializeListeners();
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'aria-haspopup',
      'true'
    );

    // updating the aria-expanded attribute when the popover is opened or closed is handled by the animation events in popover.component.ts
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'aria-expanded',
      'false'
    );
  }

  public ngOnDestroy(): void {
    for (const unlisten of this.unlisteners) {
      unlisten();
    }
    this.unlisteners = [];
  }

  private initializeListeners() {
    // The @HostListener mechanism is more convenient, but isn't used in this directive so that it can be dynamically instantiated. (See MenuComponent)
    this.unlisteners.push(
      this.renderer.listen(this.elementRef.nativeElement, 'click', () => {
        this.popoverComponent.toggle();
      })
    );
    this.unlisteners.push(
      this.renderer.listen(
        this.elementRef.nativeElement,
        'keyup',
        (e: KeyboardEvent) => {
          if (this.popoverComponent.popover && isKey(e, Key.Down)) {
            e.preventDefault();
            this.a11yService.focusNextFocusable(
              this.popoverComponent.popover.nativeElement
            );
          }
        }
      )
    );
  }
}
