import { Injectable } from '@angular/core';
import { CHUploadService } from '@degreed/content-hosting-data-access';
import { ResourceType } from '@app/shared/models/core-api.model';
import { ContentHostingUpload } from './content-hosting-upload.adapter';
import { FileChunkUploaderService } from '@app/uploader/file-chunk-uploader/file-chunk-uploader.service';

/**
 * Helper service to create an {@see ContentHostingUpload } for AngularJs app components
 * because we can not share classes between the ngx and js app.
 * Once all of the forms using this service have been migrated, this can be deleted
 */
@Injectable({
  providedIn: 'root',
})
export class ContentHostingUploadAdapterService {
  constructor(
    private contentHostingUploadService: CHUploadService,
    private chunkUploader: FileChunkUploaderService
  ) {}

  public getAdapter(resourceType: ResourceType, resourceId?: number) {
    return new ContentHostingUpload(
      this.contentHostingUploadService,
      this.chunkUploader,
      resourceType,
      resourceId
    );
  }
}
