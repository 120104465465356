import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a resource association removed notification */
@Component({
  selector: 'dgx-resource-association-removed',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceAssociationRemovedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    const shared = {
      type: this.itemService.translate
        .instant('Core_' + this.params.resourceType)
        .toLowerCase(),
      resourceTitle: this.itemService.ellipsisPipe.transform(
        this.params.associatedResourceTitle,
        this.maxTitleLength
      ),
      resourceType: this.itemService.translate.instant(
        this.params.associatedResourceType === 'Pathway'
          ? 'Core_Pathway'
          : 'Core_SkillPlan'
      ),
    };

    if (this.params.hasRelatedUser) {
      return this.translateHtml('resourceAssociationRemoved_ActionFormat', {
        ...shared,
        nameHtml: this.userFullNameTemplate,
      }); // "{{nameHtml}} deleted this {{type}} from the {{resourceTitle}} {{resourceType}}"
    } else {
      return this.translateHtml(
        'resourceAssociationRemoved_ActionNoUserFormat',
        shared
      );
      // "Your organization's latest catalog import deleted this {{type}} from the {{resourceTitle}} {{resourceType}}[...]"
    }
  }
}
