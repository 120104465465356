import { InjectionToken } from '@angular/core';
import { app } from '@microsoft/teams-js';

export const MicrosoftTeamsToken = new InjectionToken('microsoftTeams');

export type MSTeamsToken = typeof app;

export function microsoftTeamsProvider(): typeof app {
  return app;
}
