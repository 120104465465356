<form class="form-wrap" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="false"
    [submitButtonText]="i18n.dgTagRating_RateNewSkills"
    [useDefaultForm]="false"
    [cancelButtonText]="i18n.Core_Done"
    bodyClasses="guts-p-1"
  >
    <ng-container class="modal-header">
      {{ i18n.dgTagRating_AddSkills }}
    </ng-container>
    <ng-container class="modal-body">
      <div class="guts-p-full-1 center-text color-ebony-a61">
        <df-icon
          size="large"
          class="guts-m-b-1 hyper"
          icon="checkmark-badge"
        ></df-icon>
        <p>{{ i18n.dgTagRating_SuccessfullyAddedSkills }}</p>
        <p>{{ i18n.dgTagRating_AddingRatingsToPersonalize }}</p>
      </div>
    </ng-container>
  </dgx-modal>
</form>
