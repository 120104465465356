import {
  animate,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata,
} from '@angular/animations';

/**
 * Animations used by the Apollo Toast components
 * @docs-private
 */
export const toastAnimations: AnimationTriggerMetadata = trigger('fadeInOut', [
  transition(':enter', [
    style({ transform: 'scale(0.8)', opacity: 0 }),
    animate(
      '1100ms cubic-bezier(0, 0, 0.2, 1)',
      style({ transform: 'scale(1)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1 }),
    animate(
      '700ms cubic-bezier(0.4, 0.0, 1, 1)',
      style({ transform: 'scale(0.8)', opacity: 0 })
    ),
  ]),
]);
