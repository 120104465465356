import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { ResourceIdBuilder } from '@app/notifications/resource-id-builder';

/** Represents a Group Invite Accepted notification */
@Component({
  selector: 'dgx-group-invite-accepted',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInviteAcceptedComponent extends NotificationItemComponentBase {
  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    const contentResource = resourceIdBuilder
      .withSegment('groupInviteAccepted_')
      .withSingleOrPlural()
      .asFormat();

    return this.translateHtml(contentResource, {
      nameHtml: this.userFullNameTemplate,
      groupNameHtml: this.groupNameTemplate,
      number: this.personCountArgument,
    });
    // Singular: "{{nameHtml}} accepted your invite to the {{groupNameHtml}} group."
    // Plural: "{{nameHtml}} and {{number}} others accepted your invite to the {{groupNameHtml}} group."
  }
}
