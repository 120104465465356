<div
  class="tile segment-tile break rel l_flex updated-content-card"
  *ngIf="segment && !loading"
>
  <article class="tile tile--tag l_flex l_flex-column full-width m-l_flex">
    <!-- Header -->
    <dgx-skill-standards-publish-status-badge
      [status]="segment.skillStandardSegmentStatusId"
      size="compact"
      class="block guts-m-l-2 guts-m-t-2"
    ></dgx-skill-standards-publish-status-badge>
    <section
      class="l_flex l_flex-column l_flex-grow full-width guts-p-v-1 guts-p-l-2 guts-p-r-2"
    >
      <!-- Title -->
      <header class="tile__content-head">
        <h3 class="h3 guts-m-b-1">{{ segment.name }}</h3>
      </header>
      <!-- Description -->
      <div class="gut-m-b-1 meta-list" *ngIf="segmentBusinessRule?.predicates">
        <p
          [title]="businessRuleAttributes"
          *ngFor="
            let attribute of formatBusinessRuleList(businessRuleAttributes);
            let last = last
          "
          data-dgat="profile-skill-standards-e678"
        >
          <span [title]="attribute.title">{{ attribute.value }}</span>
        </p>
      </div>
    </section>

    <!-- Footer -->
    <footer
      *ngIf="segment.skillStandardsHasSkills"
      class="tile__footer guts-p-t-half guts-p-b-1 guts-p-l-1-half"
    >
      <div
        class="l_flex l_flex-grow l_flex-wrap l_flex-center-children guts-m-r-1"
      >
        <a
          (click)="manageSegment()"
          data-dgat="skillstandardSegment-df3"
          class="action-link bg-color-ebony-a08 color-ebony font-semibold guts-m-r-half btn btn-sm"
        >
          <df-icon icon="pencil"></df-icon>
          {{ i18n.OrgManage_Manage }}
        </a>
        <dgx-menu
          [appendToBody]="true"
          [menuConfig]="menuConfig"
          placement="bottom-right"
          class="skillstd-segment__footer__menu"
        >
          <button
            [attr.aria-label]="i18n.Core_MoreOptions"
            type="button"
            data-dgat="actionOptions-3cf"
          >
            <df-icon icon="dots" class="color-ebony-a61"></df-icon>
          </button>
        </dgx-menu>
      </div>
    </footer>
  </article>
</div>
