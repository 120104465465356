import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { StickyDirective } from '@app/shared/directives/sticky.directive';
import { CookieService } from 'ngx-cookie-service';

/**
 * @position @default 'bottom' Using 'top' will show up just under the app header. Using 'bottom' will be at the bottom of the screen and cover help or any other elements down here.
 * @cookieName @optional A simple, unique string that will be appended to "degreed_banner_". Leave this off if you don't want a cookie set at all.
 * @cookieExpirationDays @optional Number of days until the cookies expires. Without this the cookie will just be a session cookie.
 */
@Component({
  selector: 'dgx-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, AfterViewInit {
  @Input() public position: 'top' | 'bottom' = 'bottom';
  @Input() public cookieName?: string;
  @Input() public cookieExpirationDays?: number;
  @Output() public dismissed?: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('banner') public bannerElement: ElementRef;
  public buttonType: 'primary' | 'ghost';
  private readonly cookieValue = 'dismissed';
  private readonly cookiePath = '/';

  constructor(
    private elementRef: ElementRef,
    private cookieService: CookieService
  ) {
    // hide by default until we check for cookies
    this.hide();
  }

  private get dgCookieName() {
    return `degreed_banner_${this.cookieName}`;
  }

  private get isCookieSet() {
    const cookieValue = this.cookieService.get(this.dgCookieName);
    return !!cookieValue && cookieValue === this.cookieValue;
  }

  public ngOnInit(): void {
    if (this.cookieName) {
      if (this.isCookieSet) {
        return;
      }
      this.unhide();
      if (
        !!this.cookieExpirationDays &&
        typeof this.cookieExpirationDays !== 'number'
      ) {
        this.cookieExpirationDays = parseFloat(this.cookieExpirationDays);
      }
    }
    this.buttonType = this.position === 'bottom' ? 'primary' : 'ghost';
  }

  public ngAfterViewInit(): void {
    if (this.position === 'top' && !this.isCookieSet) {
      const stickyDirective = new StickyDirective(
        undefined,
        this.bannerElement
      );
      stickyDirective.ngAfterViewInit();
    }
  }

  public dismiss(): void {
    if (this.cookieName) {
      this.cookieService.set(
        this.dgCookieName,
        this.cookieValue,
        this.cookieExpirationDays,
        this.cookiePath
      );
    }
    this.hide();
    this.dismissed.emit(true);
  }

  private unhide(): void {
    this.elementRef.nativeElement.hidden = false;
  }

  private hide(): void {
    this.elementRef.nativeElement.hidden = true;
  }
}
