import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { normalizeDateTime } from '@app/reporting/in-app/utils/date-time';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { Notification, NotificationPerson } from '../../notification-api.model';
import { NotificationService } from '../../notification.service';
import * as ItemComponents from '../items';
import { WindowToken } from '@app/shared/window.token';

export enum NotificationItemType {
  NewPathwayAuthor,
  NewTargetAuthor,
  Opportunity,
  Recommendation,
  ResourceAssociationRemoved,
  NewSkillProviderAdded,
  OrgRatingScaleChange,
  AutomationsPush,
  SkillChangePublished
}

export interface NotificationGroupInviteResponse {
  notification: Notification;
  isApproved: boolean;
}

@Component({
  selector: 'dgx-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListItemComponent implements OnInit {
  public static readonly defaultMaxTitleLines = 2;
  public static readonly defaultMaxCommentLines = 2;
  public static readonly notificationMaxPeople = 5;

  public notificationItemCount: any;
  public moreItemsAvailable = true;
  public people: NotificationPerson[];

  public itemType: NotificationItemType;
  public notificationItemType = NotificationItemType; // expose enum type for comparison in template
  // determines if the list item should be used for the degreed extension or the webapp
  public navigationTarget = '_self';
  public notificationCreatedDate: string;

  @Input() public notification: Notification;
  @Input() public maxTitleLines =
    NotificationListItemComponent.defaultMaxTitleLines;
  @Input() public maxCommentLines =
    NotificationListItemComponent.defaultMaxCommentLines;
  @Output() public notificationClick = new EventEmitter();
  /** emits 'true' if the group invite is accepted; 'false' otherwise */
  @Output() public respondToGroupInviteClick = new EventEmitter<{
    event: Event;
    isApproved: boolean;
  }>();

  constructor(
    private notificationService: NotificationService,
    private windowLayoutService: WindowLayoutService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public get params() {
    return this.notification.parameters;
  }

  public get isSingular() {
    return (
      (!this.params.personCount || this.params.personCount === 1) &&
      this.params.person.userProfileKey !== -2
    );
  }

  public get isPlural() {
    return this.params.personCount > 1 && this.params.person.length;
  }

  public ngOnInit() {
    const type = this.notification.notificationType.toLowerCase();

    // this will handle the created date regardless of if UTC indicator and ensures we treat it correctly
    this.notificationCreatedDate = normalizeDateTime(
      this.notification.createdDate
    ).toISOString();

    this.navigationTarget = this.windowLayoutService.isIframe
      ? '_blank'
      : '_self';
    switch (type) {
      case 'skillchangepublished':
        this.itemType = NotificationItemType.SkillChangePublished;
        break;
      case 'orgratingscalechange':
        this.itemType = NotificationItemType.OrgRatingScaleChange;
        break;
      case 'newtargetauthor':
        this.itemType = NotificationItemType.NewTargetAuthor;
        break;
      case 'newpathwayauthor':
        this.itemType = NotificationItemType.NewPathwayAuthor;
        break;
      case 'opportunitynewauthor':
      case 'opportunityinterested':
      case 'opportunityclosed':
        this.itemType = NotificationItemType.Opportunity;
        break;
      case 'singlerecommendation':
      case 'assignedlearning':
      case 'completedrecommendation':
      case 'completedassignedlearning':
        if (this.params.title || this.params.resourceTitle) {
          this.itemType = NotificationItemType.Recommendation;
        }
        break;
      case 'resourceassociationremoved':
        if (this.params.resourceTitle) {
          this.itemType = NotificationItemType.ResourceAssociationRemoved;
        }
        break;
      case 'newskillsprovideradded':
        this.itemType = NotificationItemType.NewSkillProviderAdded;
        break;
      case 'automationspush':
        this.itemType = NotificationItemType.AutomationsPush;
        break;
      default:
        break;
    }
    if (this.isPlural) {
      this.people = this.params.person.slice(
        0,
        NotificationListItemComponent.notificationMaxPeople
      );
    }
  }

  public onNotificationClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.itemType === NotificationItemType.NewSkillProviderAdded) {
      return;
    }

    this.notificationClick.emit();
  }

  public onRespondToGroupInviteClick(event: Event, isApproved: boolean) {
    event.stopPropagation();
    event.preventDefault();
    this.respondToGroupInviteClick.emit({ event, isApproved });
  }

  public lookupIcon(notification): string {
    // TODO: consider more efficiently implementing this as a pipe
    return this.notificationService.getIcon(notification);
  }

  public getItemComponentType(notificationTypeName: string) {
    const type = (ItemComponents as any)[notificationTypeName + 'Component'];
    return type;
  }

  public stopProfileEventPropagation(event: Event, url: string) {
    // this is required to stop the event bubbling up to the section click handler when the profile pic is clicked
    event.stopPropagation();
    event.preventDefault();
    this.windowRef.open(url, '_self');
  }
}
