import { AbstractControl, ValidatorFn } from '@angular/forms';
import { countWords } from './helpers';

/** Creates a word count validator that is valid until  {@link maxCount} is reached. */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export function dfWordCountValidator(maxCount: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const count = countWords(control.value?.toString());
    return count > maxCount ? { wordCount: { count } } : null;
  };
}

/** Creates a validator that checks if two fields' values are identical.
 * @param prototypeFieldKey the key for the standard field to be matched
 * @param duplicateFieldKey the key secondary field, which must match the prototype
 */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export function dfFieldMatchValidator(
  prototypeFieldKey,
  duplicateFieldKey
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const prototype = control.value[prototypeFieldKey];
    const duplicate = control.value[duplicateFieldKey];

    // Consider values valid if either empty or matching
    if (!duplicate || !prototype) {
      return null;
    }
    if (duplicate === prototype) {
      return null;
    }
    return { fieldMatch: { prototype, duplicate } };
  };
}
