import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ReportingRangePickerComponent } from './components/reporting-range-picker/reporting-range-picker.component';

@NgModule({
  declarations: [ReportingRangePickerComponent],
  imports: [SharedModule],
  exports: [ReportingRangePickerComponent],
})
export class ChartsSharedModule {}
