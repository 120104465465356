import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartsService } from '@app/charts/charts.service';
import { SkillSectionsChartService } from '@app/charts/components/skill-sections-chart/skill-sections-chart.service';
import { InsightsFeatureService } from '@app/insights/services/insights-feature.service';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { AuthService } from '@app/shared/services/auth.service';
import { BreadcrumbsService } from '@app/shared/services/breadcrumbs.service';
import {
  DateRange,
  DateRangeService,
} from '@app/shared/services/date-range.service';
import { ReportingContentService } from '@app/shared/services/reporting-content/reporting-content.service';
import { ReportingService } from '@app/shared/services/reporting/reporting.service';
import { SubscriptionQueueService } from '@app/shared/services/subscription-queue.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, take, tap } from 'rxjs/operators';
import { DownloadableReportsParams } from './org-reporting.model';
import { throwError } from 'rxjs';
import { LocalizationService } from '@app/shared/localization.service';

@Component({
  template: '',
  providers: [SubscriptionQueueService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgReportingBaseComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context?: string;
  @Input() public orgId?: number;
  @Input() public orgName?: string;
  @Input() public permissions?: any;
  @Input() public tenants?: any[];

  public i18n;
  public dateRange: DateRange;
  public enableSkillInsightsDashboard = false;
  public endDate: any = {};
  public groupId: number = 0;
  public groupIdList: string = '';
  public groupOptions: any[] = [];
  public kpis: any[];
  public kpisLoading: boolean = false;
  public kpisError: boolean = false;
  public hasNoFilter: boolean = false;
  public ratingType: string;
  public ratingTypeOptions: any;
  public reportingParams: string | DownloadableReportsParams;
  public resolver: any;
  public selectedGroup: any;
  public selectedPeriod: any = {};
  public selectedOrg: any;
  public selectedRatingType: any;
  public selectedSkill: any;
  public showMore: string;
  public skillId: number = 0;
  public skillKpisLoading: boolean = false;
  public skillKpis: any[];
  public skillOptions: any[];
  public startDate: any = {};
  public totalDisplayData = {};
  public warningMessage: string;
  public breadcrumbs: Breadcrumbs;

  protected breadcrumbsService: BreadcrumbsService;
  protected chartsService: ChartsService;
  protected dateRangeService: DateRangeService;
  protected dataTypes: any;
  protected insightsFeatureService: InsightsFeatureService;
  protected reportingService: ReportingService;
  protected subscriptionQueueService: SubscriptionQueueService;
  protected reportingContentService: ReportingContentService;
  protected route: ActivatedRoute;
  protected skillSectionsChartService: SkillSectionsChartService;
  protected trackerService: TrackerService;
  protected translate: TranslateService;
  protected localizationService: LocalizationService;

  private datePipe: DatePipe;
  public initComplete: boolean = false;

  private readonly DATE_FORMAT = 'd MMM yyyy';

  constructor(
    protected authService: AuthService,
    protected injector: Injector,
    protected cdr: ChangeDetectorRef
  ) {
    super();
    this.breadcrumbsService = injector.get(BreadcrumbsService);
    this.chartsService = injector.get(ChartsService);
    this.datePipe = injector.get(DatePipe);
    this.dateRangeService = injector.get(DateRangeService);
    this.insightsFeatureService = injector.get(InsightsFeatureService);
    this.reportingService = injector.get(ReportingService);
    this.subscriptionQueueService = injector.get(SubscriptionQueueService);
    this.reportingContentService = injector.get(ReportingContentService);
    this.route = injector.get(ActivatedRoute);
    this.skillSectionsChartService = injector.get(SkillSectionsChartService);
    this.trackerService = injector.get(TrackerService);
    this.translate = injector.get(TranslateService);
    this.localizationService = injector.get(LocalizationService);

    this.i18n = this.translate.instant([
      'Core_CalendarView',
      'Core_Organizations',
      'OrgReportingCtrl_AllGroups',
      'OrgReportingCtrl_AllSkills',
      'OrgReportingCtrl_And',
      'OrgReportingCtrl_DateMaxValidation',
      'OrgReportingCtrl_DownloadableReports',
      'OrgReportingCtrl_FilterByGroup',
      'OrgReportingCtrl_FilterByOrg',
      'OrgReportingCtrl_FilterBySkill',
      'OrgReportingCtrl_Insights',
      'OrgReportingCtrl_NoMatchingGroups',
      'OrgReportingCtrl_NoMatchingOrgs',
      'OrgReportingCtrl_NoMatchingSkills',
      'SkillRatingsChart_Evaluations',
      'SkillRatingsChart_DegreedCertified',
      'SkillRatingsChart_ManagerRatings',
      'SkillRatingsChart_RatingType',
      'SkillRatingsChart_SelfRatings',
    ]);
    this.skillOptions = [
      {
        title: this.i18n.OrgReportingCtrl_AllSkills,
        id: 0,
      },
    ];
    this.hasNoFilter = this.queryParams?.nofilter;
    this.showMore = this.queryParams?.more;
  }

  public get queryParams() {
    return this.route?.snapshot?.queryParams;
  }

  public ngOnInit(): void {
    this.ratingTypeOptions =
      this.skillSectionsChartService.getRatingTypeOptions();
    this.onRatingTypeSelection(this.ratingTypeOptions[0][0]);

    this.dateRange = this.dateRangeService.getPastDaysExcludeTodayUTC(90);
    this.dataTypes = this.reportingContentService.getDataTypes();
    this.enableSkillInsightsDashboard =
      this.authService.authUser.defaultOrgInfo?.settings.enableSkillInsightsDashboard;
    this.updateDateRange(this.dateRange);
    if (this.context === 'channel') {
      this.orgName = this.tenants[0].name;
      this.setCurrentOrgById(this.tenants[0].organizationId);
    }
    this.updateReportingUrl();
    this.initGroups();
    if (this.queryParams?.more) {
      this.setBreadcrumb(this.queryParams.more);
    }

    if (this.context === 'skills-tab') {
      this.getSkillKpis();
      this.getSkillOptions();
    }
    this.trackPageLoadEvent();
  }

  public getSkillKpis(): void {
    this.skillKpisLoading = true;
    const subscription = this.reportingService
      .getInsightSkillKpis(
        this.orgId,
        this.groupId,
        this.groupIdList,
        this.ratingType,
        this.skillId
      )
      .pipe(take(1))
      .subscribe((data) => {
        const kpis = [];
        for (let i = 0; i < data.length; i++) {
          const kpi = data[i],
            secondKpi =
              kpi.metricSecondary === 0
                ? 0
                : Math.round((kpi.metricPrimary / kpi.metricSecondary) * 100),
            kpiItem = {
              name: kpi.name,
              location: 'Org Skill Insights',
              metric:
                kpi.name === 'SkillRatedUsers'
                  ? secondKpi + '%'
                  : kpi.metricPrimary.toLocaleString(
                      this.localizationService.userLocale
                    ),
              description: this.translate.instant(
                'OrgReportingCtrl_Kpi' + kpi.name + 'Name',
                { metricSecondary: kpi.metricSecondary }
              ),
              tooltip: this.translate.instant(
                'OrgReportingCtrl_Kpi' + kpi.name + 'Tooltip'
              ),
              order: i,
              uid: i, // for track by
            };
          switch (kpi.name) {
            case 'OrgExperts':
              kpiItem.order = 0;
              break;
            case 'SkillRatedUsers':
              kpiItem.order = 1;
              break;
          }
          kpis.push(kpiItem);
        }
        this.skillKpis = kpis;
        this.skillKpisLoading = false;
        this.cdr.detectChanges();
      });
    this.subscriptionQueueService.persist(subscription, 'getInsightSkillKpis');
  }

  public onGroupSelection(selectedItem): void {
    if (this.initComplete) {
      this.selectedGroup = selectedItem;
      this.groupId = this.selectedGroup.groupId;
      this.updateReportingUrl();
    }
  }

  public onGroupSelections(options: any[]): void {
    this.groupIdList =
      options?.length > 0
        ? options
            .map((o) => {
              return o.groupId;
            })
            .join(',')
        : '';
    this.updateReportingUrl();
  }

  public onRatingTypeSelection(selectedItem) {
    this.selectedRatingType = selectedItem;
    this.ratingType = this.selectedRatingType.Type;
  }

  public onSkillSelection(selectedItem): void {
    this.selectedSkill = selectedItem;
    this.skillId = this.selectedSkill.id;
  }

  public trackFilter(action, location, name, selection): void {
    if (selection) {
      this.trackerService.trackEventData({
        action,
        properties: {
          FilterType: name,
          FilterSelection: selection,
          Location: location,
        },
      });
    }
  }

  public updateDateRange = (range) => {
    this.dateRange = range;
    // This remaining bit is still necessary since all of the chid components depend on these bindings
    const startDate = new Date(this.dateRange.startDate),
      endDate = new Date(this.dateRange.endDate);
    this.selectedPeriod = {
      Value: {
        start: startDate,
        end: endDate,
      },
    };
    this.startDate = {
      name: this.datePipe.transform(startDate, this.DATE_FORMAT),
      value: startDate,
    };
    this.endDate = {
      name: this.datePipe.transform(endDate, this.DATE_FORMAT),
      value: endDate,
    };
  };

  protected getOrgId(): any {
    return this.context === 'channel'
      ? this.selectedOrg.organizationId
      : this.orgId;
  }

  protected getKpis(): void {
    const orgId = this.getOrgId();
    const groupId = this.groupId > 0 ? this.groupId : '';

    const subscription = this.reportingService
      .getInsightKPIs(
        orgId,
        this.startDate.value,
        this.endDate.value,
        groupId,
        this.groupIdList
      )
      .pipe(
        tap((_) => (this.kpisLoading = true)),
        take(1)
      )
      .subscribe({
        next: (data) => {
          const kpis = [];
          for (let i = 0; i < data.length; i++) {
            const kpi = data[i];

            const kpiItem = {
              name: kpi.name,
              Location: 'Org Insights',
              metric: kpi.metric.toLocaleString(
                this.localizationService.userLocale
              ),
              trend: kpi.trend || 0,
              description: this.translate.instant(
                'OrgReportingCtrl_Kpi' + kpi.name + 'Name'
              ),
              tooltip: this.translate.instant(
                'OrgReportingCtrl_Kpi' + kpi.name + 'Tooltip'
              ),
              order: i,
              uid: i, // for track by
              numOfDays: this.reportingService.getNumberOfDays(
                this.startDate.value,
                this.endDate.value
              ),
            };
            switch (kpi.name) {
              case 'ItemsAdded':
                kpiItem.order = 2;
                break;
              case 'Recommendations':
                kpiItem.order = 3;
                break;
            }
            kpis.push(kpiItem);
          }
          this.kpis = kpis;
          this.kpisLoading = false;
          this.cdr.detectChanges();
        },
        error: () => {
          this.kpisLoading = false;
          this.kpisError = true;
          this.cdr.detectChanges();
        },
      });

    this.subscriptionQueueService.persist(subscription, 'getInsightKPIs');
  }

  protected setBreadcrumb(moreReportId): void {
    if (moreReportId != '') {
      this.breadcrumbs = this.breadcrumbsService.decodeBreadcrumbs([
        {
          label: this.orgName,
          link: '/me/dashboard',
        },
        {
          label: this.i18n.OrgReportingCtrl_Insights,
          routerLink: `/orgs/${this.orgId}/insights/skills`,
        },
        {
          label: this.reportingContentService.getChartName(moreReportId),
        },
      ]);
    }
  }

  protected setCurrentOrgById(id): void {
    this.selectedOrg = this.tenants.filter((tenant) => {
      return tenant.organizationId === id;
    })[0];
    this.orgId = this.selectedOrg.organizationId;
  }

  private getSkillOptions(): void {
    const subscription = this.reportingService
      .getOrganizationSkills(this.orgId)
      .pipe(take(1))
      .subscribe((data) => {
        this.skillOptions = [
          {
            title: this.i18n.OrgReportingCtrl_AllSkills,
            id: 0,
          },
        ];
        this.skillOptions = this.skillOptions.concat(data);
      });
    this.subscriptionQueueService.persist(
      subscription,
      'getOrganizationSkills'
    );
  }

  private initGroups(): void {
    const subscription = this.reportingService
      .getGroups(this.getOrgId())
      .pipe(take(1))
      .subscribe((data) => {
        const groups = this.insightsFeatureService.applyGroupFilter(data);
        this.warningMessage = this.insightsFeatureService.warningMessage;
        groups.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.groupOptions = this.groupOptions.concat(groups);
        setTimeout(() => {
          this.initComplete = true;
          this.cdr.detectChanges();
        });
      });
    this.subscriptionQueueService.persist(subscription, 'getGroups');
  }

  private updateReportingUrl(): void {
    const params: DownloadableReportsParams = {};
    if (this.groupId !== 0) {
      params.gId = this.groupId;
    }
    if (this.groupIdList?.length > 0) {
      params.groupIdList = this.groupIdList;
    }
    if (this.context === 'skills-tab') {
      params.defaultReport = encodeURIComponent('Top 1000 Skills Rated');
      params.location = encodeURIComponent('Org Skill Insights');
    } else {
      params.location = encodeURIComponent('Org Learning Insights');
    }
    if (Object.keys(params).length > 0) {
      this.reportingParams = params;
    } else {
      this.reportingParams = undefined;
    }
    this.cdr.detectChanges();
  }

  private trackPageLoadEvent() {
    let action = 'Org Insights Viewed';
    let location = 'Org Insights';
    if (this.context === 'skills-tab') {
      action = 'Org Skill Insights Viewed';
      location = 'Org Skill Insights';
    }
    if (this.context === 'skills-show-more') {
      action = 'Skill Rating Summary Link Clicked';
      location = 'Skill Rating Summary More Insights';
    }
    this.trackerService.trackEventData({
      action,
      properties: {
        Location: location,
      },
    });
  }
}
