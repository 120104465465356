import { Injectable, Injector } from '@angular/core';

import {
  UserBook,
  UserBookParameters,
  UserExistingBookParameters,
  UpdateUserBookParameters,
} from '../inputs-api.model';
import { UserInputTypeServiceBase } from './user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Books */
@Injectable({
  providedIn: 'root',
})
export class UserBookService extends UserInputTypeServiceBase<
  'Book',
  UserBook,
  UserBookParameters,
  UserExistingBookParameters,
  UpdateUserBookParameters
> {
  constructor(injector: Injector) {
    super(injector, '', 'Book');
  }
}
