import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a New Target Author notification */
@Component({
  selector: 'dgx-new-target-author',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTargetAuthorComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('newTargetAuthor_DescriptionFormat', {
      targetAuthor: this.userFullNameTemplate,
      targetTitle: this.params.targetTitle,
    });
  }
}
