import { UrlHostWhiteList } from './constants';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '@app/shared/window.token';
import { map } from 'rxjs/operators';
import { WebEnvironmentService } from '../web-environment.service';

interface Configuration {
  env: string;
  cdnDomains: [string];
  ebb: {
    id: string;
    name: string;
    extensionId: string;
    clientId: string;
    cspExtras: string;
    connectables: [string];
    baseUrl: string;
  };
}

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private configuration: Configuration;

  constructor(
    private http: HttpClient,
    private environmentService: WebEnvironmentService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const url = '/scripts/deploy/dgapp/assets/config.json';
      const cdnUrl = this.getCdnUrl(url);
      this.http
        .get(cdnUrl)
        .pipe(map((result) => result))
        .subscribe(
          (value: Configuration) => {
            this.configuration = value;
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  get config(): Configuration {
    return this.configuration;
  }

  get env(): string {
    return this.configuration.env;
  }

  get cdnDomains(): [string] {
    return this.configuration.cdnDomains;
  }

  get ebbId(): string {
    return this.configuration.ebb.id;
  }

  get ebbName(): string {
    return this.configuration.ebb.name;
  }

  get ebbExtensionId(): string {
    return this.configuration.ebb.extensionId;
  }

  get ebbClientId(): string {
    return this.configuration.ebb.clientId;
  }

  get ebbCspExtras(): string {
    return this.configuration.ebb.cspExtras;
  }

  get ebbConnectables(): [string] {
    return this.configuration.ebb.connectables;
  }

  get ebbBaseUrl(): string {
    return this.configuration.ebb.baseUrl;
  }

  get isProduction(): boolean {
    return this.configuration.env === 'prod';
  }

  /**
   * Return the CDN url for a given environment
   * @param url
   */
  public getCdnUrl(url: string): string {
    let isWhiteListed = false;
    const host = this.windowRef.location.host.toLowerCase();

    // urls in the degreed.dev domain must match regex to be feature branches
    let regexp = new RegExp(
      UrlHostWhiteList.DegreedDevFeatureBranchRegEx,
      'gmi'
    );
    isWhiteListed = regexp.test(host);

    if (!isWhiteListed) {
      const featureBranchDomains =
        UrlHostWhiteList.FeatureBranchDomains.split(',');
      for (const value of [
        UrlHostWhiteList.LocalHost,
        ...featureBranchDomains,
      ]) {
        if (host.indexOf(value.toLowerCase()) !== -1) {
          isWhiteListed = true;
          break;
        }
      }
    }

    return isWhiteListed ? url : this.environmentService.getBlobUrl(url);
  }
}
