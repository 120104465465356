<form class="form-wrap" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [item]="item"
    [isSubmitDisabled]="!formValid"
    [submitButtonText]="buttonText"
    (dismiss)="onClose()"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ i18n.OrgSkills_RecommendModalTitle }}
    </ng-container>
    <!-- Modal Body -->
    <ng-container class="modal-body">
      <div class="dg-modal__body dropdown-menu__min-height">
        <dgx-tag-search-list
          class="rel block guts-p-full-1"
          (onTagsModified)="handleTagsModified($event)"
        ></dgx-tag-search-list>
      </div>
    </ng-container>
  </dgx-modal>
</form>
