import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { AuthService } from '@app/shared/services/auth.service';

/**
 * Mostly a container component for other components, this is used in the product header to display the global search, global add, notifications, profile dropdown, product switcher components.
 * Also is used to display a sign in and sign up button when the user is not authenticated.
 *
 * @param hasLightText {string} (required) for branding purposes.  determines whether text/images in the utility bar should be dark, or light.
 * @param shouldHideGlobalAdd {boolean=false} (optional) allows the global add component to be hidden
 * @param shouldHideGlobalSearch {boolean=false} (optional) allows the global search component to be hidden
 * @param shouldHideNotifications {boolean=false} (optional) allows the notifications component to be hidden
 */
@Component({
  selector: 'dgx-utility-bar',
  templateUrl: './utility-bar.component.html',
})
export class UtilityBarComponent implements OnInit, AfterViewInit {
  @Input() public hasLightText: boolean;
  @Input() public shouldHideGlobalAdd: boolean;
  @Input() public shouldHideGlobalSearch: boolean;
  @Input() public shouldHideNotifications: boolean;
  @Input() public orgId?: number;

  // used by product-header for mobile view calculations
  public clientWidth: number;

  public authUser: AuthUser;
  public isUserAuthenticated: boolean;
  public loginUrl: string;
  public orgInfo: OrgInfo;
  public signUpUrl: string;

  private readonly LOGIN_URL_PREFIX = '/account/login';

  constructor(
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private element: ElementRef
  ) {}

  public ngOnInit(): void {
    // PD-80853 there was an issue with 400% magnification that the content switcher was not visible
    // but we can not just use a scroll because
    // it should not require two dimensional scrolling when the viewport is 320px at 100%
    // 1280 at 400% where the user doesn't loose context or functionality
    // in our case we are removing the global add, at a later date TBD
    // the application header will be redesigned
    this.breakpointObserver
      .observe(['(max-width: 400px)'])
      .subscribe((result: BreakpointState) => {
        const shouldHideGlobalAddForMobile = result.matches ? true : false;
        this.shouldHideGlobalAdd =
          this.shouldHideGlobalAdd || shouldHideGlobalAddForMobile;
      });

    this.authUser = this.authService.authUser;
    this.isUserAuthenticated = !!this.authUser;
    this.orgInfo = this.authUser ? this.authUser.defaultOrgInfo : null;

    if (!this.isUserAuthenticated) {
      // Hyperlink default values
      this.loginUrl = `${this.LOGIN_URL_PREFIX}`;
      this.signUpUrl = `${this.LOGIN_URL_PREFIX}?reg=true`;

      /* 
        Prevent user from navigating to previous user profile.
        Otherwise, append the queryString returnUrl parameter to the given hyperlinks. Profile settings pages are
        excluded as these pages have deep linking requirements
      */
      if (
        !location.pathname.toLowerCase().startsWith('/profile/') ||
        location.pathname.toLowerCase().includes('/settings/')
      ) {
        const param = `returnUrl=${encodeURIComponent(
          location.pathname + location.search + location.hash
        )}`;
        this.loginUrl += `?${param}`;
        this.signUpUrl += `&${param}`;
      }
    }
  }

  public ngAfterViewInit(): void {
    this.clientWidth = this.element.nativeElement.clientWidth;
  }
}
