import { HttpEvent } from '@angular/common/http';
import { CompleteAzureUploadResult } from '@degreed/content-hosting-data-access';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { AbstractControl } from '@angular/forms';
import { focusOnFirstInvalidField } from '@app/user-content/user-input-v2/utils/form-field-helper';

export function getHostedContentDetails(
  uploadResponse: HttpEvent<CompleteAzureUploadResult>
): HostedContentMetadata {
  const response = uploadResponse as any;

  if (!response.azureUploadFileResult) {
    return undefined;
  }

  const { name, id, size } = response.azureUploadFileResult;
  const fileType = response.azureUploadFileResult.type;
  const fileName = name;
  const hostType = response.azureUploadFileResult.hostType;

  const details: HostedContentMetadata = {
    fileId: id,
    fileName,
    fileSize: size,
    fileType,
    hostType,
  };

  return details;
}

/**
 * @deprecated - use {form-field-helper.handleFocusOnSubmit}
 */
export const handleArticleFocusOnSubmit = (formControls: {
  [key: string]: AbstractControl;
}): void => {
  const objectEntries = Object.entries(formControls);
  const durationFormEntry = getDurationFormEntry(objectEntries);
  const controlObjectEntriesInDomOrder =
    getArticleControlObjectEntriesInDomOrder(objectEntries, durationFormEntry);
  focusOnFirstInvalidField(controlObjectEntriesInDomOrder, durationFormEntry);
};

/**
 * The durationForm field is technically a child component and FormGroup that is appended to the list of form controls.
 * We have to put the form controls in the order that they appear in the dom in order to shift focus
 * to the first field with a validation error upon submit of the form.
 */
const getArticleControlObjectEntriesInDomOrder = (
  objectEntries: [string, AbstractControl][],
  durationFormEntry: [string, AbstractControl]
): [string, AbstractControl][] => {
  const indexOfDescriptionEntry = objectEntries.indexOf(
    objectEntries.find((entry) => entry[0] === 'description')
  );
  const indexOfDurationFormEntry = objectEntries.indexOf(durationFormEntry);

  objectEntries.splice(indexOfDurationFormEntry, 1);
  objectEntries.splice(indexOfDescriptionEntry + 1, 0, durationFormEntry);

  return objectEntries;
};

const getDurationFormEntry = (
  objectEntries: [string, AbstractControl][]
): [string, AbstractControl] =>
  objectEntries.find((entry) => entry[0] === 'durationForm');
