<ul *ngIf="!isLoading" class="guts-m-t-half">
  <li
    *ngFor="let tag of  (isSuggestedTagsByOrg? suggestedTags.slice(0, 5): suggestedTags.slice(0, 10)); trackBy: trackByTitle"
    class="ib not-too-wide"
    data-dgat="add-suggested-tags-modal-4f5"
  >
    <button
      df-button
      dfButtonType="passive"
      df-button-small
      (click)="addSuggestedTag(tag)"
      class="guts-m-r-half guts-m-b-half suggested-tag-button not-too-wide"
      [ngClass]="{ 'color-white bg-color-blue': isTagSelected(tag) }"
      [attr.aria-label]="
        'dgTagsEdit_AddTagFormat' | translate: { tag: tag.title }
      "
      data-dgat="add-suggested-tags-modal-061"
    >
      <div class="l_flexbar gap-full-half">
        <df-icon [icon]="isTagSelected(tag) ? 'checkmark' : 'plus'"></df-icon>
        <div class="ellipsis" title="{{ tag.title }}">{{ tag.title }}</div>
      </div>
    </button>
  </li>
  <li *ngIf = "isSuggestedTagsByOrg && suggestedTags.length > 5" 
    class="ib not-too-wide tw-rounded-lg"
    [routerLink]="getSkillStandardUrl()" tabindex="0" [attr.aria-label] = "'dgTagsEdit_View_More_Button' | translate">
    <button class="tw-flex tw-items-center tw-justify-center tw-px-1 tw-gap-1 tw-text-blue-800 tw-text-xs tw-font-semibold"> 
      <span class="tw-relative -tw-top-0.5" > {{ 'dgTagsEdit_View_More_Button' | translate }}</span>
      <da-icon
        icon="chevron-right"
        type="solid"
        [solidSize]="16"
        class="tw-w-4"
      />
    </button>
  </li>
</ul>
