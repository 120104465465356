import { Observable, firstValueFrom, forkJoin } from 'rxjs';
import { Attribute, EngageEvent, TriggerType } from '../model';
import { Filter } from '@app/shared/components/filter/filter.component';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from '@dg/shared-services';

export const makeSearchFilters = async (
  translate: TranslateService,
  filterService: FilterService,
  sources: {
    events: Observable<{ payload: EngageEvent[] }>;
    attributes: Observable<{ payload: Attribute[] }>;
    actionOptions: Observable<{
      payload: { id: number; name: string; associatedResourceType: string }[];
    }>;
  }
) => {
  const [events, attributeResponse, actions] = await firstValueFrom(
    forkJoin([sources.events, sources.attributes, sources.actionOptions])
  );

  const conditionsFilter = makeConditionsFilter(
    events.payload,
    attributeResponse.payload,
    translate
  );

  const actionsFilter = makeActionsFilter(
    actions.payload,
    filterService,
    translate
  );

  const statusFilter = makeStatusFilter(filterService, translate);

  const enableFilter = makeEnableFilter(filterService, translate);

  return [conditionsFilter, actionsFilter, statusFilter, enableFilter];
};

const makeConditionsFilter = (
  events: EngageEvent[],
  attributes: Attribute[],
  translate: TranslateService
) => {
  const userManagementFilter = {
    id: 'userManagement',
    title: translate.instant('Automation_UserManagement'),
    model: {
      id: 'userManagement',
      name: 'userManagement',
    },
    subitems: [TriggerType.UserCreated, TriggerType.UserRecordReceived].map(
      (attribute) => ({
        title: translate.instant(`Automation_Trigger_${attribute}`),
        model: {
          id: attribute,
          name: attribute,
          filter: false,
        },
        isSelected: false,
      })
    ),
  };

  const attributesFilter = {
    id: 'attributes',
    title: translate.instant('BusinessRules_Attributes_Column'),
    model: {
      id: 'attributes',
      name: 'attributes',
    },
    subitems: attributes.map((attribute) => ({
      title: attribute.attributeName,
      model: {
        id: attribute.attributeName,
        name: attribute.attributeName,
        filter: false,
      },
      isSelected: false,
    })),
  };

  const eventsFilter = {
    id: 'activities',
    title: translate.instant('Core_Events'),
    model: {
      id: 'activities',
      name: 'activities',
    },
    subitems: events.map((event) => {
      const translation = `Automation_Event_${event.eventName.replace(
        / /g,
        ''
      )}`;
      return {
        title: translation,
        model: { id: event.eventName, name: translation, filter: false },
        isSelected: false,
      };
    }),
  };

  const conditionsFilter: Filter = {
    id: 'conditions',
    title: translate.instant('BusinessRules_Conditions_Column'),
    isFilterCollection: true,
    filters: [userManagementFilter, eventsFilter, attributesFilter],
  };

  return conditionsFilter;
};

const makeActionsFilter = (
  actions: { id: number; name: string; associatedResourceType: string }[],
  filterService: FilterService,
  translate: TranslateService
) =>
  filterService.facetToFilter(
    {
      id: 'actions',
      name: 'actions',
      values: actions.map((action) => ({
        id: action.name,
        name: translate.instant(`BusinessRules_${action.name}`),
        filter: false,
      })),
    },
    { showEmptyOptions: true, title: translate.instant('Automation_Actions') }
  );

const makeStatusFilter = (
  filterService: FilterService,
  translate: TranslateService
) =>
  filterService.facetToFilter(
    {
      id: 'status',
      name: 'status',
      values: ['Warning', 'No Warning'].map((status) => ({
        id: status,
        name: translate.instant(`Automation_${status.replace(' ', '')}`),
        filter: false,
      })),
    },
    { showEmptyOptions: true, title: translate.instant('Core_Warning') }
  );

const makeEnableFilter = (
  filterService: FilterService,
  translate: TranslateService
) =>
  filterService.facetToFilter(
    {
      id: 'activeStates',
      name: 'activeStates',
      values: ['Enabled', 'Disabled', 'Draft'].map((status) => ({
        id: status,
        name: translate.instant(`Core_${status}`),
        filter: false,
      })),
    },
    {
      showEmptyOptions: true,
      title: translate.instant('Core_Status'),
    }
  );
