<form
  role="form"
  [formGroup]="form"
  class="form-wrap guts-p-full-2"
  (ngSubmit)="onSubmit()"
>
  <df-form-root [form]="form" [model]="model" [fields]="fields"></df-form-root>
  <div class="par par--small color-error" *ngIf="!canAssignToSelectedUsers">
    {{ i18n.recommendationForm_OnlyOrgMembers }}
  </div>
</form>

<dgx-modal-footer
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  (dismiss)="onDismiss()"
>
  <button
    df-button
    dfButtonType="primary"
    (click)="onSubmit()"
    [disabled]="!form.valid"
    data-dgat="recommendationForm-bae"
  >
    {{ submitButtonText }}
  </button>
</dgx-modal-footer>

<!--User/group search input field-->
<ng-template #recommendeeSearch let-formControl="formControl" let-id="id"
  ><dgx-invite-user-search
    [labelKey]="id"
    [parentResource]="asAnyLearningResource(item)"
    [selected]="model.recommendees"
    (selectedChanged)="onRecommendeesChange(formControl, $event)"
    [usersOnly]="usersOnly"
    [showSuggestions]="true"
    [autoFocus]="false"
  ></dgx-invite-user-search>
</ng-template>

<ng-template #preview>
  <!--Preview with copyable link field (non-skill recommendations only)-->
  <div class="break guts-m-v-1 guts-p-full-1-half box-shadow rounded--large">
    <ng-container *ngIf="item?.resourceType !== 'Tag'; else skill">
      <article class="l_flexbar l_flex-justify">
        <div class="l_flex-top">
          <h3 class="h3 guts-p-b-half break">{{ item.title }}</h3>
          <ul
            class="l_flexbar l_flex-wrap l_flex-shrink font-medium color-ebony-a61 par par--small"
          >
            <li *ngIf="itemDisplayType" class="l_flexbar guts-m-r-half">
              <df-icon
                icon="{{ item.resourceType | resourceTypeIcon }}"
                size="medium"
                class="color-ebony-a61"
                [a11yText]="itemDisplayType"
                [ngbTooltip]="itemDisplayType"
                triggers="focus mouseenter"
                container="body"
                [openDelay]="300"
                placement="auto top"
              ></df-icon>
              <span class="a11y-hide">{{ item.resourceType }}</span>
            </li>
            <ng-container
              *ngIf="
                !(
                  item.liveSessions?.length > 0 && item.resourceType === 'Event'
                )
              "
            >
              <li
                *ngIf="showLegacyDurationDisplay && item.durationDisplay"
                class="my-item"
              >
                {{ item.durationDisplay }}
              </li>
              <li *ngIf="hoursToDisplay">
                {{ hoursToDisplay }}
              </li>
              <li
                *ngIf="minutesToDisplay"
                class="minutes"
                [ngClass]="{ 'minutes-separator': !!hoursToDisplay }"
              >
                {{ minutesToDisplay }}
              </li>
            </ng-container>
          </ul>
        </div>
        <div
          class="l_flex-shrink l_flex-top guts-p-l-1-half"
          *ngIf="item.resourceType != 'Opportunity'"
        >
          <dgx-image-layout
            format="preview"
            [imageSrc]="item.imageUrl"
            [resourceId]="item.resourceId"
            [resourceType]="item.resourceType"
            [videoUrl]="item.resourceType === 'Video' ? item.url : ''"
            [providerImage]="item.providerImage"
          ></dgx-image-layout>
        </div>
      </article>
      <div class="guts-m-t-1-half" *ngIf="item.resourceType == 'Opportunity'">
        <ul class="meta-list meta-list--bullets m-guts-m-b-half">
          <li>{{ item.type }}</li>
          <li *ngIf="item.location">{{ item.location }}</li>
        </ul>
      </div>
      <div class="guts-m-t-1-half" *ngIf="model.shareableUrl">
        <dgx-copy-link
          [url]="model.shareableUrl"
          trackingLabel="Recommendation Link Copied"
        ></dgx-copy-link>
      </div>
    </ng-container>
  </div>

  <!--Skill rating donut (for skill recommendations only)-->
  <ng-template #skill>
    <div class="center-text">
      <dgx-tag-rating-donut size="lg" class="ib"></dgx-tag-rating-donut>
      <h3 class="h3 guts-m-t-1">{{ item.name }}</h3>
    </div>
  </ng-template>
</ng-template>

<!-- Selected action field-->
<ng-template #actionSelector let-formControl="formControl">
  <dgx-select
    *ngIf="item.actions?.length"
    required
    class="full-width"
    [ariaLabel]="item.actionsLabel?.label"
    [placeholder]="item.actionsLabel?.placeholder"
    [options]="item.actions"
    labelKey="name"
    trackBy="targetType"
    [selectedItem]="model.actionOption"
    (selection)="onActionChange(formControl, $event)"
    dgatInput="recommendation-form-b19"
  >
  </dgx-select>
</ng-template>

<!--Due date field-->
<ng-template #dateDue let-formControl="formControl" let-id="id">
  <!--although hidden, exclude from DOM when !shouldShowDueDate because formControl isn't initially created-->
  <dgx-date-picker
    *ngIf="shouldShowDueDate"
    [labelKey]="id"
    [isMinDateToday]="true"
    [date]="model.dateDue"
    (dateSelect)="onDateChange(formControl, $event)"
    [placeholder]="i18n.recommendationForm_PickADate"
  ></dgx-date-picker>
</ng-template>
