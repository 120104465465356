<div class="field-group">
  <span class="group-search field-group__input field-group__input--tag">
    <div class="group-search__tag-wrapper" *ngIf="groups.length">
      <span
        *ngFor="let group of groups; trackBy: trackBy"
        class="group-search__tag"
        data-dgat="group-search-5ef"
      >
        <button
          df-button
          df-button-small
          dfButtonType="passive"
          class="group-search__tag-button"
          [attr.aria-label]="group.name"
          (click)="remove(group)"
          data-dgat="group-search-621"
        >
          <span>{{ group.name }}</span>
          <df-icon
            icon="cross"
            size="small"
            class="guts-m-l-quart color-ebony-a61"
          ></df-icon>
        </button>
      </span>
    </div>

    <dgx-group-search-input
      [search]="search"
      [isNotFound]="isNotFound$ | async"
      [placeholderText]="i18n.dgGroupSearch_PlaceHolder"
      [groupNotFoundText]="i18n.OrgReportingCtrl_NoMatchingGroups"
      [isRequired]="isRequired"
      (onGroupSelection)="handleGroupSelection($event)"
      (onGroupInputBlur)="handleBlur()"
    >
    </dgx-group-search-input>
  </span>
</div>
