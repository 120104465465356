/**
 * Return sorted items, case-insensitive, ascending or descending. *This is
 * not a full replacement for lodash's sortBy method*, as it does not allow
 * for dot-notation nested keys.
 *
 * @param a - The current item.
 * @param b - The next item.
 * @param keys - The sort key, or array of keys.
 * @param orders - Ascending or descending. Defaults to ascending.
 *
 * @example
 * ```
 * users.sort((a, b) => orderBy(a, b, ['user', 'age']));
 * // [{ user: 'barney', age: 30 }, { user: 'fred', age: 40 }, { user: 'fred', age: 48 }]
 * users.sort((a, b) => orderBy(a, b, ['user', 'age'], 'desc'));
 * // [{ user: 'fred', age: 48 }, { user: 'fred', age: 40 }, { user: 'barney', age: 30 }]
 * users.sort((a, b) => orderBy(a, b, ['user', 'age'], ['asc', 'desc']));
 * // [{ user: 'barney', age: 30 }, { user: 'fred', age: 48 }, { user: 'fred', age: 40 }]
 * ```
 */
export function orderBy(
  a: any,
  b: any,
  keys: string | string[],
  orders?: string | string[]
): number {
  // ensure our keys and orders are an array
  if (!Array.isArray(keys)) {
    keys = keys === null ? [] : [keys];
  }
  if (!Array.isArray(orders)) {
    orders = orders === null ? [] : [orders];
  }
  // fewer sort orders than keys to sort by?
  if (keys.length > orders.length) {
    // If there's only one sort order given, sort all fields by it.
    // Otherwise, default to 'asc' for empty sort orders, as our
    // documentation would indicate.
    const sortOrderToAdd = orders.length === 1 ? orders[0] : 'asc';
    for (let i = 0; i <= keys.length - orders.length; ++i) {
      orders.push(sortOrderToAdd);
    }
  }
  // the result we want to return should be a number
  let result: number;
  // loop through our keys
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i];
    const order = orders[i];
    // get current a / b key values
    const aValue = a[key];
    const bValue = b[key];
    // defaults to ascending
    const firstSort = order === 'desc' ? -1 : 1;
    const secondSort = -firstSort;
    // we only want to lowercase string values, not numbers or booleans
    const aLowerCased =
      typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
    const bLowerCased =
      typeof bValue === 'string' ? bValue.toLowerCase() : bValue;
    // should A come before B? should B come before A? or are they equal?
    result =
      aLowerCased > bLowerCased
        ? firstSort
        : bLowerCased > aLowerCased
        ? secondSort
        : 0;
    // if we have arrived at a sort order for these two items, we're done
    if (result !== 0) {
      break;
    }
    // if they're equal, but we're also out of keys to sort by, we're done
    if (i === keys.length - 1) {
      break;
    }
    // otherwise, continue on to the next key
  }
  return result;
}
