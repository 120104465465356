import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { ModalService } from '@app/shared/services/modal.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgSelectorExternalWarningService {
  constructor(
    private modalService: ModalService,
    private translate: TranslateService
  ) {}

  public show(bodyText: string, catalogName: string): Observable<void> {
    const inputs = {
      canCancel: true,
      closeOnSubmit: false,
      headerText: this.translate.instant('externalContentModal_HeaderFormat', {
        catalogName,
      }),
      bodyText,
      submitButtonText: this.translate.instant('Core_Okay'),
    };
    return this.modalService.show<void>(SimpleModalComponent, {
      inputs,
      errorOnDismiss: true,
    });
  }

  public showMarketplaceWarning(
    warningText?: string,
    orgName?: string
  ): Observable<void> {
    const bodyText =
      warningText ||
      this.translate.instant('OrgSettings_MarketplaceWarning_Default');

    return this.show(bodyText, orgName);
  }
}
