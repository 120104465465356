<dgx-modal
  *ngIf="!isLoading"
  bodyClasses="guts-p-full-0"
  [canCancel]="true"
  [isHeaderBorderless]="true"
  [isPendingWithOverlay]="true"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-body">
    <div class="grid__col-12 guts-p-t-1-half center-text">
      <h1 class="guts-m-b-half">
        {{ i18n.Core_SkillScaleChanged }}
      </h1>
      <p class="par par--small par--light">
        {{ i18n.dgTagRating_ReviewSkillsNewRatings }}
      </p>
    </div>
    <dgx-data-table
      [tableWrapperClass]="'guts-p-full-0'"
      [componentWrapperClass]="'grid__col-12 guts-p-full-0'"
      [columns]="columns"
      [items]="items"
      [isLoading]="isLoading"
      [collapseGroups]="true"
    ></dgx-data-table>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      *ngIf="!isViewingProfileSkillsPage"
      df-button
      dfButtonType="primary"
      (click)="reviewProfileSkills()"
      [textContent]="i18n.OrgSkills_ReviewSkills"
    ></button>
  </ng-container>
</dgx-modal>

<!-- New Skill Scale Template -->
<ng-template #newSkillScaleTemplate let-item>
  <dgx-tag-rating-donut
    *ngIf="item.type !== 'groupedDescription'"
    class="ib float-left"
    size="sm"
    [level]="item.value"
    [showDefaultPlus]="false"
    [isOwner]="true"
    [showLock]="false"
  ></dgx-tag-rating-donut>
  <p
    *ngIf="item.type !== 'groupedDescription'"
    class="par par--small font-semibold ib float-left new-scale-label"
    [dgxLineClampText]="item.name"
    [clampLinesMax]="1"
    [title]="item.name"
  ></p>
</ng-template>

<!-- Previous Skill Scale Template -->
<ng-template #previousSkillScaleTemplate let-item>
  <div
    *ngIf="item.type !== 'groupedDescription'"
    class="l_flex rel rating-wrapper"
  >
    <ng-container
      *ngFor="
        let level of item.mappedValues;
        let i = index;
        let first = first;
        let last = last
      "
    >
      <dgx-tag-rating-donut
        *ngIf="first || last || (item.mappedValues.length === 3 && i === 1)"
        [anchorHigh]="previousAnchorHigh"
        class="ib greyscale"
        size="sm"
        [level]="level"
        [background]="true"
        [showDefaultPlus]="false"
        [isOwner]="true"
        [showLock]="false"
        [style.z-index]="i"
        [ngClass]="{
          'opacity-half': !!item.duplicatePreviousRatingMappings,
          'opacity-full': !item.duplicatePreviousRatingMappings,
          'multi-skill-scale-levels': i > 0
        }"
      ></dgx-tag-rating-donut>
      <div
        *ngIf="i === 1 && item.mappedValues.length > 3"
        class="multi-skill-scale-levels multi-skill-scale-levels-middle"
      >
        ...
      </div>
    </ng-container>
    <df-icon
      *ngIf="item.type !== 'groupedDescription'"
      icon="arrow-right"
      size="medium"
      class="color-ebony-a25 guts-m-r-1 l rating-scale-change-arrow"
    ></df-icon>
  </div>
  <div *ngIf="item.type === 'groupedDescription'">
    <p class="font-semibold guts-p-b-quart">
      {{ i18n.dgTagRating_LevelDescription }}
    </p>
    <p class="par par--light par--small">{{ item.description }}</p>
  </div>
</ng-template>
