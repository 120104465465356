import { RecommendationsService } from './../../../recommendations/services/recommendations.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthUser } from '@app/account/account-api.model';

import { RecommendationForUser } from '@app/recommendations/recommendations.api';
import { TagsApi } from '@app/tags/tag-api.model';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { TagsService } from '@app/tags/services/tags.service';
import { forkJoin, of } from 'rxjs';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'dgx-tag-resource-view',
  templateUrl: './tag-resource-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagResourceViewComponent implements OnInit {
  public get isMobile(): boolean {
    return this.windowLayoutService.isMobile;
  }

  public authUser: AuthUser = this.authService.authUser;

  public tag: TagsApi.Tag;
  public recommendation?: RecommendationForUser;
  public loading: boolean = true;

  constructor(
    private activeRoute: ActivatedRoute,
    private windowLayoutService: WindowLayoutService,
    private tagsService: TagsService,
    private recommendationsService: RecommendationsService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    const tagId = this.activeRoute.snapshot?.queryParams.id;
    const resourceId = this.activeRoute.snapshot?.queryParams.r;
    const $tagItem = this.tagsService.getTag(tagId);
    // the tag item does not include the ratings, need to get the ratings from rating details call
    const $tagDetails = this.authUser
      ? this.tagsService.getTagRatingDetails(
          this.authUser.viewerProfile.userProfileKey,
          tagId
        )
      : of([]);
    const $recommendations =
      this.recommendationsService.getRecommendationsByIds([resourceId], true);
    this.loading = true;

    forkJoin([$tagItem, $tagDetails, $recommendations]).subscribe(
      ([tagItem, tagDetails, recommendations]) => {
        this.tag = tagItem;
        // tag rating details are sorted on the backend should display first rating in list
        this.tag.rating = tagDetails?.length ? tagDetails?.[0] : null;
        this.tag.ratings = tagDetails?.length ? tagDetails : [];
        this.recommendation = recommendations.recommendations?.[0] as any;
        this.loading = false;
        this.cdr.detectChanges();
      }
    );
  }
}
