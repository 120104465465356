<div>
  <button
    (click)="actionClicked()"
    [disabled]="disabled"
    [class.is_disabled]="disabled"
    df-button
    [dfButtonType]="buttonType"
    [attr.data-dgat]="dgatInput"
    [attr.aria-label]="ariaLabel"
  >
    <div class="l_flexbar gap-full-half">
      <df-icon [icon]="actionIcon"></df-icon>
      <div>{{ displayText }}</div>
    </div>
  </button>
</div>
