import { SubmissionStatus } from '@app/inputs/inputs.model';
import { AuthService } from '../../../../shared/services/auth.service';
import { Injectable, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { TranslateService } from '@ngx-translate/core';
import { CourseFormModel } from '../course-form.model';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { ContentHostingUploadAdapterService } from '@app/uploader/upload-section/adapters/content-hosting-upload-adapter.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { CourseUploadAdapter } from '../services/course-upload.adapter';

@Injectable({ providedIn: 'root' })
export class InitialCourseFormRenderer implements FormRenderer {
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('CourseFormCtrl_UrlRequired'),
    },
  };
  private contentUploadStatus: SubmissionStatus;

  constructor(
    private builder: DfFormFieldBuilder,
    private contentHostingUploadAdapterService: ContentHostingUploadAdapterService,
    private webEnvironmentService: WebEnvironmentService,
    private uploadAdapter: CourseUploadAdapter,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();
    const contentHosting = this.buildContentHostingField(
      context.state,
      vm.shouldShowContentUploader,
      vm.isEditing,
      vm.hostedContentDetails,
      vm.inputId,
      context.templates.contentUploader
    );
    const authUser = this.authService.authUser;

    vm.contentUploadStatus$.subscribe(
      (uploadStatus) => (this.contentUploadStatus = uploadStatus)
    );

    return [
      this.builder
        .checkbox(
          'isScorm',
          this.translate.instant(
            'dgOrgInternalContentForm_ScormPackageCheckbox'
          )
        )
        // can't go back at this point w/out starting over
        .readonlyWhen(() => context.state().scormInfo?.scormToken)
        .hiddenWhen(() => !authUser.defaultOrgInfo.hasScorm)
        .onChange(() => {
          vm.onSelectScorm();
        })
        .build(),
      this.builder
        .fieldGroup('', [
          this.builder
            .customField('spinner', '', context.templates.spinner, null)
            .hiddenWhen(() => {
              return (
                (context.state().isScorm &&
                  !context.state().courseName &&
                  this.contentUploadStatus === SubmissionStatus.None) ||
                (context.state().isScorm &&
                  !!context.state().courseName &&
                  this.contentUploadStatus === SubmissionStatus.Succeeded)
              );
            })
            .build(),
          this.buildScormUploaderField(
            context.state,
            context.templates.contentUploader
          ),
          this.builder
            .customField(
              'scormErrorMessage',
              '',
              context.templates.errorMessage,
              {
                errorMessage: vm.scormUploadErrorMessage,
              }
            )
            .hiddenWhen(() => !context.state().scormInfo?.scormHasError)
            .unwrapped()
            .build(),
        ])
        .unwrapped()
        .hiddenWhen(() => !context.state().isScorm)
        .build(),
      this.builder
        .fieldGroup('', [
          this.builder
            // this has to be optional (https://degreedjira.atlassian.net/browse/PD-80488)
            // Formly doesn't update the form group when the VM changes so despite going to a
            // different page this input makes it impossible to move forward when the form doesn't
            // have a url input.
            .optionalTextInput('courseUrl', 'CourseFormCtrl_CourseUrl')
            .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalo
            .ofType('url')
            .validatedByIndexed(this.urlValidator)
            .build(),
          contentHosting,
        ])
        .unwrapped()
        .hiddenWhen(() => context.state().isScorm)
        .build(),
    ];
  }

  private buildContentHostingField(
    state: () => CourseFormModel,
    supportsContentHosting: boolean,
    isEditing: boolean,
    hostedContentDetails: HostedContentMetadata,
    inputId: number,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.builder
      .customField('hostedContentDetails', '', contentUploaderTemplate, {
        useExistingFileRestrictions: true,
        file:
          isEditing && hostedContentDetails
            ? {
                name: hostedContentDetails.fileName,
                size: hostedContentDetails.fileSize,
              }
            : undefined,
        uploadAdapter: this.contentHostingUploadAdapterService.getAdapter(
          'Course',
          inputId
        ),
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
          allowedDescription: '',
        },
        i18n: this.translate.instant(['Core_Or']),
        hasDivider: true,
      })
      .unwrapped()
      .hiddenWhen(() => {
        const vm = state();
        return (
          !vm.shouldShowContentUploader ||
          isEditing ||
          (hostedContentDetails && hostedContentDetails.hostType !== 'Box')
        );
      })
      .build();
  }

  private buildScormUploaderField(
    state: () => CourseFormModel,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.builder
      .customField('scormUrl', '', contentUploaderTemplate, {
        uploadAdapter: this.uploadAdapter,
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
        },
        i18n: this.translate.instant(['Core_Or']),
        hasDivider: false,
      })
      .unwrapped()
      .hiddenWhen(() => {
        return (
          state().isScorm &&
          !state().courseName &&
          this.contentUploadStatus === SubmissionStatus.Submitting
        );
      })
      .build();
  }
}
