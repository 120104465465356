<dgx-modal
  [bodyClasses]="bodyClasses"
  [bodyStyle]="bodyStyle"
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  [useDefaultForm]="false"
  [isHeaderBorderless]="true"
  [isHeaderCentered]="isHeaderCentered"
>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div class="l_flexbar l_flex-column">
      <div
        class="guts-p-b-1 v-middle-children l_flex-middle center-text modal-overlapping-images"
      >
        <dgx-profile-pic
          [profile]="userData"
          size="medium"
          class="ib rel left-image"
        ></dgx-profile-pic>
        <dgx-tag-rating-donut
          size="lg"
          [level]="tag.level"
          [background]="true"
          class="ib rel right-image"
        ></dgx-tag-rating-donut>
      </div>
    </div>
    <div class="l_flexbar l_flex-column">
      <div class="l_flex-middle center-text">
        <h3
          class="guts-p-b-half color-ebony font-semibold"
          [textContent]="userData.name"
        ></h3>
        <p class="par--small par--light_a guts-p-b-3">
          {{ tag.tagName }} &bull; {{ i18n.Core_SelfRating }}
        </p>
      </div>
    </div>
    <!-- Last updated section -->
    <div *ngIf="filteredHistoryData?.length">
      <dgx-expanding-title-section
        title="{{ 'Core_LastUpdated' | translate }}"
        rightText="{{ filteredHistoryData[0].date | date: 'mediumDate' }}"
      >
        <div>
          <div
            *ngFor="let item of filteredHistoryData"
            class="l_flexbar par par--small par--light guts-m-b-quart"
            data-dgat="update-self-rating-eac"
          >
            <div class="l_flex-grow guts-p-r-1">
              {{ item.title }}
            </div>
            <div class="right-text">
              {{ item.date | date: 'mediumDate' }}
            </div>
          </div>
        </div>
      </dgx-expanding-title-section>
    </div>
  </ng-container>
  <!-- Modal Footer -->
  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      (click)="onSubmit(userData)"
      data-dgat="update-self-rating-5c7"
    >
      {{ 'dgTagRating_RequestNewRating' | translate }}
    </button>
  </ng-container>
</dgx-modal>
