import { Injectable } from '@angular/core';
import { PathwaySummaryModel } from '@app/pathways/pathway-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddToPathwayService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  public getAuthoredPathways = (searchTerm?: string) => {
    return this.http
      .get<PathwaySummaryModel[]>('/pathways/getauthoredpathways', {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant('Pathways_AuthoredPathwayError'),
              error
            )
          );
        })
      );
  };
}
