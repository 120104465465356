import { Injectable, Injector } from '@angular/core';

import {
  UserAssessment,
  UserAssessmentParameters,
  UserExistingAssessmentParameters,
  UpdateUserAssessmentParameters,
} from '../inputs-api.model';
import { UserInputTypeServiceBase } from './user-input-type-service-base';

/** Provides web API methods for getting and manipulating User Assessments */
@Injectable({
  providedIn: 'root',
})
export class UserAssessmentService extends UserInputTypeServiceBase<
  'Assessment',
  UserAssessment,
  UserAssessmentParameters,
  UserExistingAssessmentParameters,
  UpdateUserAssessmentParameters
> {
  constructor(injector: Injector) {
    super(injector, '', 'Assessment');
  }
}
