import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dgx-request-self-rating-modal',
  templateUrl: './request-self-rating-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestSelfRatingModalComponent {
  @Input() public bodyClasses: string;
  @Input() public bodyStyle: any;
  @Input() public comment: string;
  @Input() public isHeaderBorderless = true;
  @Input() public isHeaderCentered = true;
  @Input() public placeholderText: string;
  @Input() public sourceTarget: EventTarget;
  @Input() public title: string;
  @Input() public userData: any;

  constructor(private activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.bodyClasses ??= 'guts-p-b-2 guts-p-h-2';
  }

  public onSubmit(userData, comment): void {
    userData.comment = comment;
    this.activeModal.close(userData);
  }
}
