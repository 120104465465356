import { Injectable } from '@angular/core';
import {
  InputStatistics,
  InputSocialCounts,
} from '@app/inputs/inputs-api.model';

@Injectable({
  providedIn: 'root',
})
export class SocialCountsService {
  // use this list to make sure we're looking at the appropriate items from
  // the InputStatistics object
  private validSocialCountStatisticTypes = [
    'commentCount',
    'likeCount',
    'queuedCount',
    'recommendationCount',
    'tags',
    'userCount',
    'viewCount',
  ];

  /**
   * Returns simplified object for displaying social counts
   * @param statistics Statistics object usually returned from the server with Input.
   * @param exclusions (optional) array of InputSocialCounts properties to ignore per instance.
   */
  public getInputSocialCounts = (
    statistics: InputStatistics,
    exclusions: string[] = []
  ): InputSocialCounts | null => {
    if (!statistics) {
      return null;
    }
    const inputSocialCounts: InputSocialCounts = {};
    for (const type of this.validSocialCountStatisticTypes) {
      if (statistics[type] && exclusions.indexOf(type) === -1) {
        inputSocialCounts[type] = statistics[type];
      }
    }
    return inputSocialCounts;
  };
}
