<div [ngSwitch]="playerType" class="video-player video-player--rounded">
  <div *ngSwitchCase="'iframe'" class="video-player__embed-iframe">
    <iframe
      [src]="safeVideoUrl"
      [attr.title]="resourceTitle"
      width="100%"
      height="auto"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
  </div>
  <div *ngSwitchCase="'native'">
    <div
      *ngIf="!videoReady && !stillBeingProcessed"
      class="video-player__loading-spinner"
    >
      <df-spinner [spinnerContext]="'video'"></df-spinner>
    </div>
    <div aria-live="polite">
      <div *ngIf="stillBeingProcessed" class="video-player__message par">
        <p
          class="vertical-center"
          [innerHTML]="'dgVideoPlayer_EncodingMessage' | translate"
        ></p>
      </div>
    </div>
    <video
      #videoPlayer
      class="video-player__component"
      controls
      muted="false"
      preload="metadata"
    >
      <!-- NOTE: `muted` property required for some browsers to autoplay https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attr-autoplay -->
      <source
        #sourceElement
        *ngFor="let videoSource of videoSources"
        [src]="parseEmbeddedVideoUrl(videoSource.url)"
        [attr.type]="videoSource.type"
        data-dgat="video-player-6e8"
      />
    </video>
  </div>
</div>
