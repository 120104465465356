import { TranslateService } from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortableResult } from '@app/shared/directives/sortable-list.directive';
import { Observable } from 'rxjs';
import { ReorderType } from './reorder-modal.constants';
import { ReorderItem } from './reorder-modal.model';

@Component({
  selector: 'dgx-reorder-modal',
  templateUrl: './reorder-modal.component.html',
  styleUrls: ['./reorder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReorderModalComponent {
  @Input() public headerText: string = '';
  @Input() public reorderData: ReorderItem[];
  @Input() public reorderType: ReorderType;
  @Input() public onSave: (
    reorderData: ReorderItem[],
    close: () => void
  ) => void;

  public isChanged: boolean = false;
  public isSubmitting: boolean = false;
  public i18n = this.translate.instant([
    'dgReorder_SaveChanges',
    'dgReorder_NoTitleText',
    'Core_MoveUp',
    'Core_MoveDown',
    'Core_Loading',
  ]);

  constructor(
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public dismiss() {
    this.activeModal.dismiss();
  }

  // set onSort with an arrow function so it retains the right this when called in the child directive
  public onSort = (sortable: SortableResult<ReorderItem>) => {
    return new Observable((observer) => {
      // update DOM order for correct a11y tab order
      this.reorderData = sortable.list;
      // enable button
      this.isChanged = true;
      this.changeDetectorRef.detectChanges();
      observer.next();
    });
  };

  // TODO: these onSave methods are unnecessary and should be removed
  // when plans and pathways (the places using this modal) are converted
  // to reactive stores.
  public save() {
    if (this.isChanged) {
      this.isSubmitting = true;
      if (!this.onSave) {
        this.activeModal.close(this.reorderData);
        return;
      }
      this.onSave(this.reorderData, this.activeModal.close);
    }
  }

  public getItemTrackingKey(idx, item: ReorderItem) {
    return item.node;
  }
}
