import { Injectable, Inject } from '@angular/core';
import { WindowToken } from '../window.token';
import { of, Observable, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private readonly modernBrowserSupport = !!this.windowRef?.navigator
    ?.clipboard;
  private readonly oldBrowserSupport = !!this.windowRef?.clipboardData;

  constructor(
    @Inject(WindowToken) public windowRef: any,
    private translate: TranslateService
  ) {}

  public get canCopyToClipboard() {
    return this.modernBrowserSupport || this.oldBrowserSupport;
  }

  public copyToClipboard(textToCopy: string): Observable<string> {
    if (this.modernBrowserSupport) {
      return from(
        this.windowRef.navigator.clipboard.writeText(textToCopy) as string
      ).pipe(
        map(() => {
          return this.getClipboardMessage(true);
        }),
        catchError((error: Error) => {
          console.error(error);
          return this.getClipboardMessage(false);
        })
      );
    } else if (this.oldBrowserSupport) {
      // remove after IE11 support is dropped
      const success = this.windowRef.clipboardData.setData('Text', textToCopy);
      return of(this.getClipboardMessage(success));
    } else {
      return of(this.getClipboardMessage(false));
    }
  }

  private getClipboardMessage(success: boolean): string {
    let actionMsg = this.translate.instant('CopyLinkSvc_ActionMessage');

    if (!success) {
      if (/Mac/i.test(this.windowRef?.navigator?.userAgent)) {
        actionMsg = this.translate.instant('CopyLinkSvc_MacCopy');
      } else {
        actionMsg = this.translate.instant('CopyLinkSvc_PcCopy');
      }
    }

    return actionMsg;
  }
}
