import { FtpService } from '@app/ftp/services/ftp.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { FtpFileUploadType } from '@app/ftp/ftp-api.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-ftp-upload-link',
  templateUrl: './ftp-upload-link.component.html',
})
export class FtpUploadLinkComponent {
  @Input() public buttonText: string;
  @Input() public fileUploadType: FtpFileUploadType;
  @Input() public icon: string;
  @Input() public orgId: number;
  constructor(
    private ftpService: FtpService,
    private trackerService: TrackerService
  ) {}

  public get bulkUploadUrl() {
    return this.ftpService.getFileUploadUrl(this.orgId, this.fileUploadType);
  }

  public track() {
    this.trackerService.trackEventData({
      action: 'File Upload Button Clicked',
      properties: {
        fileType: this.fileUploadType,
      },
    });
  }
}
