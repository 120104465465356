<a
  (click)="track()"
  [routerLink]="bulkUploadUrl"
  class="btn btn-passive"
  data-dgat="ftp-upload-link-023"
>
  <span class="l_flexbar">
    <df-icon [icon]="icon" class="guts-m-r-half"></df-icon>
    <span>{{ buttonText }}</span>
  </span>
</a>
