import { AbstractControl, ValidationErrors } from '@angular/forms';
import { HTTP_REQUIRED_URL_PATTERN } from '../utils';

/**
 * Validates if the user has entered a valid url.
 */
export function isUrlValidator(
  control: AbstractControl,
  translatedMessage: string
): ValidationErrors {
  return control.value && HTTP_REQUIRED_URL_PATTERN.test(control.value)
    ? null
    : { urlValidation: { message: translatedMessage } };
}
