import { AttributeDataType, PredicateOption } from '../model';

export const getOperatorFromPredicate = (
  operatorSettings,
  predicateOptions: PredicateOption[],
  predicateId: string,
  selectedOperator: string
) => {
  // Default to string if we don't have enough information
  if (!predicateId || !predicateOptions) {
    return operatorSettings.operatorOptionsByType[AttributeDataType.String];
  }

  const selectedAttribute: PredicateOption = predicateOptions.find(
    (a) => a.id === predicateId
  );

  // Default to string if for some reason there's no type
  const attributesList = !!selectedAttribute?.attributeDataType
    ? operatorSettings.operatorOptionsByType[
        selectedAttribute.attributeDataType
      ]
    : operatorSettings.operatorOptionsByType[AttributeDataType.String];

  /** If this operator has a pre-existing 'illegal' value,
   * go ahead and allow it.
   * Unless it also doesn't exist in the all operators list,
   *  in which case something's very wrong.
   */
  if (
    !attributesList.find((a) => a.value === selectedOperator) &&
    operatorSettings.allOperatorOptions[selectedOperator]
  ) {
    return [operatorSettings.allOperatorOptions[selectedOperator]].concat(
      attributesList
    );
  }

  // Otherwise, return curated list
  return attributesList;
};
export const getFollowingLabelKey = (
  predicateOptions: PredicateOption[],
  selectedPredicateId: string,
  numericDateOperators: string[],
  comparisonOperator: string
) => {
  const baseAttributeType = predicateIdToType(
    predicateOptions,
    selectedPredicateId
  );
  if (
    numericDateOperators.indexOf(comparisonOperator) > -1 &&
    baseAttributeType === AttributeDataType.DateTime
  ) {
    if (comparisonOperator === '<ago') {
      return 'Core_Days';
    }
    return 'BusinessRules_DaysAgo';
  }
  return false;
};

export const predicateIdToType = (
  predicateOptions: PredicateOption[],
  selectedPredicateId: string
): AttributeDataType =>
  predicateOptions.find((x) => x.id === selectedPredicateId)
    ?.attributeDataType ?? AttributeDataType.String;

export const predicateIdToAdjustedType = (
  predicateOptions: PredicateOption[],
  selectedPredicateId: string,
  numericDateOperators,
  comparisonOperator
): AttributeDataType => {
  const baseAttributeType = predicateIdToType(
    predicateOptions,
    selectedPredicateId
  );

  // If types is datetime but we're expecting a relative value...
  if (
    numericDateOperators.indexOf(comparisonOperator) > -1 &&
    baseAttributeType === AttributeDataType.DateTime
  ) {
    return AttributeDataType.Number;
  }
  return baseAttributeType;
};

export const getPredicateValueMin = (
  predicateOptions: PredicateOption[],
  selectedPredicateId: string,
  numericDateOperators,
  comparisonOperator
) => {
  // If this is actually a date in disguise and we're using a positive integer restricted type...
  if (
    numericDateOperators.indexOf(comparisonOperator) > -1 &&
    predicateIdToType(predicateOptions, selectedPredicateId) ===
      AttributeDataType.DateTime
  ) {
    return 0;
  }
  // Otherwise, don't restrict
  return null;
};
