import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { AutocompleteItem } from './course-api.model';

@Injectable({ providedIn: 'root' })
export class CourseService extends ApiServiceBase {
  private readonly i18n = this.translate.instant([
    'CourseSvc.ProviderError',
    'CourseSvc.CoursesError',
  ]);

  constructor(http: NgxHttpClient, private translate: TranslateService) {
    super(http, translate.instant('CourseSvc.CoursesError'));
  }

  public getInstitutions(
    term: string,
    isFormal: boolean,
    country: string
  ): Observable<AutocompleteItem[]> {
    const payload = {
      term: term,
      isAccredited: isFormal,
      country: isFormal ? country : '',
    };
    return this.get(
      '/degreed/institutions',
      payload,
      this.i18n['CourseSvc.ProviderError']
    );
  }

  public getCountries(term: string): Observable<string[]> {
    return this.get('/degreed/getcountries', { term });
  }

  public getCourses(
    term: string,
    isFormal: boolean,
    institutionId?: number
  ): Observable<AutocompleteItem[]> {
    const url = isFormal ? '/degreed/courses' : '/degreed/informalcourses';
    const payload = {
      term: term,
      instId: isFormal ? null : institutionId,
      count: 500,
    };
    return this.get(url, payload, this.i18n['CourseSvc.CoursesError']);
  }
}
