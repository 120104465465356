import { DatePipe } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import { UploadFileResponse } from '@app/uploader/uploader-api.model';
import { UserContentModalBaseDirective } from '@app/user-content/user-input/user-content-modal-base/user-content-modal-base.directive';
import { UserImageFieldComponent } from '@app/form-fields/wrappers/user-image-field/user-image-field.component';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { CertificateUploadAdapter } from './certificate-upload.adapter';

export interface CertificateFormModel {
  title: string;
  issuer: string;
  issuerUrl: string;
  imageUrl: string;
  certNumber?: string;
  noExpiration: boolean;
  issueDate?: Date;
  expiryDate?: Date;
  tags: TagsApi.Tag[];
}

@Component({
  selector: 'dgx-certificate-modal',
  templateUrl: './certificate-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateModalComponent extends UserContentModalBaseDirective<
  UserOutcomeDetail,
  CertificateFormModel
> {
  public readonly i18n = this.translate.instant([
    'Core_ValidUrl',
    'dgUserOutcomeEditForm_SaveToProfile',
    'CertificateFormCtrl_StartDate',
    'CertificateFormCtrl_ExpirationDate',
    'CertificateFormCtrl_EndAfterStart',
    'Core_SelectStartDate',
    'Core_SelectEndDate',
    'Core_SelectDate',
  ]);

  @ViewChild('readonlyField') public readonlyFieldRef: TemplateRef<any>;
  @ViewChild('skillsView')
  public skillsViewRef: TemplateRef<any>;

  constructor(
    @Inject(WindowToken) windowRef: Window,
    cdr: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    authService: AuthService,
    tipService: TipService,
    inputsService: InputsService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private builder: DfFormFieldBuilder,
    private uploadAdapter: CertificateUploadAdapter
  ) {
    super(
      windowRef,
      cdr,
      activeModal,
      authService,
      tipService,
      inputsService,
      translate.instant('dgUserOutcomeEditForm_AddCertificate'),
      translate.instant('dgUserOutcomeEditForm_EditCertificate')
    );
  }

  public onTagsChange(items: any[], formControl: FormControl) {
    UserContentModalBaseDirective.setCustomFieldValue(formControl, items);
  }

  public initControlDependencies() {
    super.initControlDependencies();
    // When issue date changes, revalidate the expiry date, where the range validation error is performed
    // Using elvis operator here only for unit testing only, where the form controls are unfortunately not instatiated yet, likely due to 'shallow' setting
    this.form.get('issueDate')?.statusChanges.subscribe(() => {
      this.form.get('expiryDate').updateValueAndValidity();
    });
  }

  protected buildFormFields(): DfFormFieldConfig<DfTemplateOptions>[] {
    const urlValidation = {
      urlValidation: {
        expression: (control: AbstractControl) => {
          const valid = !control.value || HTTP_URL_PATTERN.test(control.value);
          return valid;
        },
        message: this.i18n.Core_ValidUrl,
      },
    };

    const shouldHideImageField =
      this.authUser.isRestrictedProfile ||
      !this.authUser.defaultOrgInfo?.settings?.allowRestrictedImages;

    return [
      this.builder
        .fieldGroup('' /* presentational group only */, [
          this.builder
            .title()
            .labeled('dgUserOutcomeEditForm_TitleOfCertificate')
            .withDgatId('certificateForm-d64')
            .autofocused()
            .build(),
          this.builder
            .requiredTextInput('issuer', 'dgUserOutcomeEditForm_IssuedBy')
            .withDgatId('certificateForm-5b4')
            .build(),
          this.builder
            .optionalTextInput('issuerUrl', 'dgUserOutcomeEditForm_IssuerUrl')
            .ofType('url')
            .validatedByIndexed(urlValidation)
            .withDgatId('certificateForm-e34')
            .build(),
          this.builder
            .optionalTextInput('certNumber', 'dgUserOutcomeEditForm_CertNumber')
            .withDgatId('certificateForm-a63')
            .build(),
        ])
        .asFieldset()
        .build(),

      this.builder
        .fieldGroup('', [
          this.builder
            .checkbox('noExpiration', 'dgUserOutcomeEditForm_DoesCertExpire')
            .withDgatId('certificateForm-621')
            .build(),

          // Create separate sub-groups for the different date configurations because hiding the fields inside the groups can cause grid padding to build up
          this.builder
            .fieldGroup(
              '' /* logical group only */,
              [
                // Awarded date (for the case when the no expiration checkbox is checked)
                this.builder
                  .foreignField<MonthPickerFieldParams>(
                    'issueDate',
                    'dgUserOutcomeEditForm_AwardedDate',
                    MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                    {
                      isMaxDateToday: true,
                      ariaLabel: this.i18n.Core_SelectStartDate,
                    }
                  )
                  .styledBy('df-form__col-half guts-p-r-1') // maintain same padding as when we have both date fields for continuity when toggling
                  .asRequired()
                  .build(),
              ],
              'df-form__row'
            )
            .hiddenWhen(() => !this.model.noExpiration)
            .build(),

          this.builder
            .fieldGroup(
              '' /* logical group only */,
              [
                // Issue date (for the case when the no expiration checkbox is checked)
                this.builder
                  .foreignField<MonthPickerFieldParams>(
                    'issueDate',
                    'dgUserOutcomeEditForm_StartDate',
                    MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                    {
                      isMaxDateToday: true,
                      ariaLabel: this.i18n.Core_SelectStartDate,
                    }
                  )
                  .styledBy('df-form__col-half')
                  .asRequired()
                  .build(),

                // Expiration date
                this.builder
                  .foreignField<MonthPickerFieldParams>(
                    'expiryDate',
                    'dgUserOutcomeEditForm_EndDate',
                    MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                    {
                      ariaLabel: this.i18n.Core_SelectEndDate,
                    }
                  )
                  .asRequired()
                  .styledBy('df-form__col-half')
                  .validatedByIndexed({
                    endDateError: {
                      expression: (control) => {
                        return control.value > this.model.issueDate; // no end date comparison needed for current positions,
                      },
                      message: this.i18n.CertificateFormCtrl_ExpirationDate, // TODO: this isn't a great error message here but was used on the ajs form
                    },
                  })
                  .build(),
              ],
              'df-form__row'
            )
            .hiddenWhen(() => this.model.noExpiration)
            .build(),
        ])
        .asFieldset('dgUserOutcomeEditForm_ActiveCert')
        .build(),

      this.builder
        .fieldGroup('' /* presentational group only */, [
          this.builder
            .foreignField(
              'imageUrl',
              'dgUserOutcomeEditForm_UploadImageOfCertificate',
              UserImageFieldComponent.REGISTERED_FIELD_TYPE,
              {
                isHostImageUrl: true, // Exceptionally, outcome images are currently served from the degreed domain instead of blob storage
                uploadAdapter: this.uploadAdapter,
                provideControlValue: (response: UploadFileResponse) =>
                  response.url,
              }
            )
            .hiddenWhen(() => shouldHideImageField)
            .build(),
          this.builder
            .customField('tags', 'Core_Skills', this.skillsViewRef, {
              topTags: this.userInterests,
            })
            .unwrapped() // The tag editor has its own label and doesn't require validation, so present it sans fresco's default field wrapper
            .build(),
        ])
        .asFieldset()
        .build(),
    ];
  }

  protected initFormModel(source: Partial<UserOutcomeDetail>) {
    this.model = {
      title: source.title,
      issuer: source.source,
      issuerUrl: source.url,
      imageUrl: source.imageUrl,
      certNumber: source.details?.certNumber,
      noExpiration: !!source.details?.noExpiration,
      issueDate: source.startDate ? new Date(source.startDate) : undefined, // ensure we get a Date, not string
      expiryDate: source.endDate ? new Date(source.endDate) : undefined,
      tags: source.tags ?? [],
    };
  }
  protected createResult(
    model: Partial<CertificateFormModel>,
    defaults: Partial<UserOutcomeDetail>
  ): UserOutcomeDetail {
    const result: Partial<UserOutcomeDetail> = {
      ...defaults,
      contentTypeId: 'Certificate',
      title: model.title,
      imageUrl: model.imageUrl,
      source: model.issuer,
      url: model.issuerUrl,
      details: {
        ...defaults.details,
        certNumber: model.certNumber,
        noExpiration: model.noExpiration,
      },
      startDate: this.datePipe.transform(model.issueDate, 'yyyy-MM-dd'),
      endDate: model.noExpiration
        ? undefined
        : this.datePipe.transform(model.expiryDate, 'yyyy-MM-dd'),
      tags: model.tags,
    };
    return result as UserOutcomeDetail;
  }
}
