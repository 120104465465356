<dgx-modal
  [canCancel]="true"
  [isHeaderBorderless]="true"
  (dismiss)="dismiss()"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-header">
    {{ i18n.Ext_AddTopicsOrSkills }}
  </ng-container>

  <ng-container class="modal-body">
    <div class="guts-p-full-1">
      <dgx-tags-edit
        [tags]="tags"
        [topTags]="userTags"
        (tagsChange)="handleTagsChange($event)"
        [required]="true"
      ></dgx-tags-edit>
    </div>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      [class.is_disabled]="isSaving"
      [attr.aria-disabled]="isSaving"
      [attr.aria-label]="isSaving ? i18n.Core_Loading : i18n.Core_Save"
      class="btn btn-primary"
      (click)="saveTagsChange()"
      data-dgat="bulkUpdateModal-80a"
    >
      <df-spinner-button [isSpinning]="isSaving">
        {{ i18n.Core_Save }}
      </df-spinner-button>
    </button>
  </ng-container>
</dgx-modal>
