import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { ProfileInput, UserProfileSkillPermissions } from '../profile.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public profileInput: ProfileInput;

  public getProfileDetails(vanityUrl: string): any {
    return this.http
      .get<ProfileInput>('/user/GetProfileDetails', {
        params: {
          vanityUrl,
        },
      })
      .pipe(
        tap((profileInput: ProfileInput) => {
          this.profileInput = profileInput;
        })
      );
  }

  /**
   * Returns true when the profile page you're currently on is owned by
   * the logged in user. Uses ownerIsViewing from profileInput.options when it is available (ie inside the profile routes)
   * validateOwner is used when outside of the profile.
   */
  public get ownerIsViewing() {
    return this.profileInput?.options.ownerIsViewing;
  }

  /**
   * Temporary function until we can get ownerIsViewing refactored
   */
  public isOwner(profileId: string) {
    if (!this.profileInput) {
      const authuserProfileId =
        this.auth.authUser?.viewerProfile?.profileUrl.toLowerCase();
      return authuserProfileId === profileId;
    }
  }

  public get isProfilePage() {
    return this.router.url.includes('/profile/');
  }

  public get profileLocation() {
    // get the last part of the url path without queryParams
    return `Profile ${this.router.url.split('/').slice(-1)[0].split('?')[0]}`;
  }

  public getUserProfileSkillsPermissions(): Observable<UserProfileSkillPermissions> {
    return this.http.get('/userprofile/getUserProfileSkillsPermissions');
  }

  constructor(
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: NgxHttpClient
  ) {}

  /**
   * Pass in an activated route snapshot to determine if owner is authorized
   * based on url parameter
   *
   * @param snapshot ActivatedRouteSnapshot
   * @returns boolean
   */
  public ownerAuthorizedForGuard(snapshot: ActivatedRouteSnapshot): boolean {
    return this.validateOwner(snapshot);
  }

  public getProfileType(): string {
    return this.ownerIsViewing ? 'Self' : 'Others';
  }

  private validateOwner(routeSnapshot: ActivatedRouteSnapshot): boolean {
    return (
      this.auth.authUser?.viewerProfile?.profileUrl.toLowerCase() ===
      routeSnapshot.params['id']?.toLowerCase()
    );
  }
}
