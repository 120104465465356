import { Injectable } from '@angular/core';

export interface SelectionRange {
  text: string;
  selectionStart?: number;
  selectionEnd?: number;
}

@Injectable({ providedIn: 'root' })
export class TextareaService {
  public canInsertText: boolean;

  public selectText(textarea: HTMLTextAreaElement, text: string) {
    const fullText = textarea.value;
    const selectionStart = fullText.indexOf(text);
    const selectionEnd = selectionStart + text.length;
    textarea.focus();
    textarea.setSelectionRange(selectionStart, selectionEnd);
  }

  public getSelectedText(textarea: HTMLTextAreaElement): string {
    return textarea.value.slice(textarea.selectionStart, textarea.selectionEnd);
  }

  public insertText(
    textarea: HTMLTextAreaElement,
    { text, selectionStart, selectionEnd }: SelectionRange
  ) {
    const originalSelectionStart = textarea.selectionStart;
    const before = textarea.value.slice(0, originalSelectionStart);
    const after = textarea.value.slice(textarea.selectionEnd);

    if (this.canInsertText === undefined || this.canInsertText === true) {
      textarea.contentEditable = 'true';
      try {
        this.canInsertText = document.execCommand('insertText', false, text);
      } catch (error) {
        this.canInsertText = false;
      }
      textarea.contentEditable = 'false';
    }

    if (
      this.canInsertText &&
      textarea.value.slice(0, textarea.selectionStart).endsWith(text)
    ) {
      this.canInsertText = false;
    }

    if (!this.canInsertText) {
      try {
        document.execCommand('ms-beginUndoUnit');
      } catch (e) {
        // Do nothing.
      }
      textarea.value = before + text + after;
      try {
        document.execCommand('ms-endUndoUnit');
      } catch (e) {
        // Do nothing.
      }
      textarea.dispatchEvent(
        new CustomEvent('input', { bubbles: true, cancelable: true })
      );
    }

    if (selectionStart != null && selectionEnd != null) {
      textarea.setSelectionRange(selectionStart, selectionEnd);
    } else {
      textarea.setSelectionRange(originalSelectionStart, textarea.selectionEnd);
    }
  }
}
