import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TabNavigationItem } from '@app/navigation/navigation.model';
import { BehaviorSubject, filter, Observable } from 'rxjs';

/**
 * Configuration for the top navigation.
 * @property navigationItems - Re-using the TabNavigationItem object to define the links at the top of the page.
 * @property title - The title associated with the page.
 */
export interface NavigationInfo {
  navigationItems?: TabNavigationItem[];
  title?: string;
  endIconSvgString?: SafeHtml;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Service for managing global navigation state. This should be used only one at the very top
 * level of a route to set top level page titles and navigation links when applicable.
 */
export class GlobalNavigationService {
  public activeTab: TabNavigationItem = null;

  constructor(private router: Router) {
    this.listenToRouteChanges();
  }

  /**
   * Subject to hold the current active tab.
   * @type {BehaviorSubject<TabNavigationItem | null>}
   */
  private _activeTab: BehaviorSubject<TabNavigationItem | null> =
    new BehaviorSubject<TabNavigationItem | null>(null);

  /** Observable that emits the current active tab. */
  public activeTab$: Observable<TabNavigationItem | null> =
    this._activeTab.asObservable();

  /** Subject holding the current state of navigation information. */
  private _navigationInfo: BehaviorSubject<NavigationInfo> =
    new BehaviorSubject<NavigationInfo>({
      navigationItems: [],
      title: '',
    });

  /** Public observable to allow subscribers to listen to navigation changes. */
  public navigationInfo$: Observable<NavigationInfo> =
    this._navigationInfo.asObservable();

  /**
   * Registers new navigation items with a title.
   * @param navigationInfo - An object containing the navigation items and title.
   */
  public register(navigationInfo: NavigationInfo): void {
    this._navigationInfo.next(navigationInfo);
    this.setInitialActiveTab();
  }

  /** Resets the navigation information to an empty state. */
  public reset(): void {
    this._navigationInfo.next({});
    this._activeTab.next(null);
  }

  /**
   * Listen to Angular router changes and updates the active tab accordingly.
   */
  private listenToRouteChanges(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const activeRoute = event.urlAfterRedirects;

        const activeTab = this._navigationInfo.value.navigationItems?.find(
          (tab) => `${tab.routerLink}` === activeRoute
        );
        this.activeTab = activeTab;
        this._activeTab.next(activeTab);
      });
  }

  /**
   * Sets the initial active tab based on the current route when the service initializes.
   * It checks the current URL against the navigation items and determines which tab is active.
   * If a match is found, the active tab is updated in the BehaviorSubject.
   * @private
   */
  private setInitialActiveTab(): void {
    const activeRoute = this.router.url;
    const activeTab = this._navigationInfo.value.navigationItems?.find(
      (tab) => `${tab.routerLink}` === activeRoute
    );
    this._activeTab.next(activeTab);
  }
}
