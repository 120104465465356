import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RelatedLearningComponent } from './components/related-learning/related-learning.component';
import { EmbeddedLearningComponent } from './components/embedded-learning/embedded-learning.component';
import { SharedModule } from '@app/shared/shared.module';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';
import { CommentsModule } from '@app/comments/comments.module';

@NgModule({
  imports: [CommonModule, SharedModule, CommentsModule],
  declarations: [
    RelatedLearningComponent,
    EmbeddedLearningComponent,
    IframeTrackerDirective,
  ],
  providers: [TitleCasePipe],
  exports: [EmbeddedLearningComponent],
})
export class SharedEmbeddedLearningModule {}
