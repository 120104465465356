import { Visibility } from '../components/visibility/visibility.enum';

/**
 * privacyNumFromPrivacyLevel
 * The api sends up strings, other apis expect Visibility and the
 *    i18n strings want Visibility for sure, so allow for
 *    conversion in the template from either
 * TODO: get api, i18n, and services all on the same page here
 * @param level string identifier of privacy level
 * @returns numeric identifier of privacy level
 */
export function privacyNumFromPrivacyLevel(
  level: string | Visibility
): Visibility {
  switch (level) {
    case 'Author':
      return Visibility.private;
    case 'Public':
      return Visibility.public;
    case 'Group':
      return Visibility.groups;
    case 'ProfileVisible':
      return Visibility.profile;
    default:
      return level as Visibility;
  }
}
