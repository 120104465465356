import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { twJoin } from '../../../../utils';
import { LayoutFeature, LayoutNavigationItem } from '../../layout.model';
import { getActiveChild } from '../../layout.utils';

@Component({
  selector: 'da-nav-disclosure',
  template: `
    <div>
      <button
        type="button"
        [class]="
          twJoin(
            'tw-flex tw-w-full tw-items-center tw-px-4 tw-py-2 tw-text-left rtl:tw-text-right',
            'hover:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] hover:tw-text-[var(--apollo-layout-highlight)]',
            'focus:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_15%)] focus:tw-text-[var(--apollo-layout-highlight)] focus:tw-outline-none',
            'active:tw-bg-[rgb(from_var(--apollo-layout-highlight)_r_g_b_/_30%)] active:tw-text-[var(--apollo-layout-highlight)]'
          )
        "
        [attr.data-dgat]="item.dgat"
        (click)="open = !open"
      >
        <da-nav-icon
          [item]="item"
          [active]="!!activeChild"
          className="tw-size-6 tw-shrink-0 tw-mr-2 rtl:tw-mr-0 rtl:tw-ml-2"
        />

        <span [class]="twJoin(beta?.enabled && item.isBeta ? '' : 'tw-grow')">{{
          item.text
        }}</span>

        <div
          *ngIf="beta?.enabled && item.isBeta"
          [class]="twJoin('tw--mt-2 tw-flex tw-grow tw-self-start')"
        >
          <div
            [class]="
              twJoin(
                'tw-rounded-2xl tw-border tw-border-purple-300 tw-bg-purple-100 tw-px-1 tw-py-0.5 tw-text-[8px] tw-font-extrabold tw-uppercase tw-leading-3 tw-text-purple-800'
              )
            "
          >
            {{ beta.text }}
          </div>
        </div>

        <da-icon
          icon="chevron-right"
          type="solid"
          [solidSize]="16"
          [className]="
            twJoin(
              'tw-size-4 tw-ml-2 rtl:tw-ml-0 rtl:tw-mr-2',
              open ? 'tw-rotate-90' : 'rtl:tw-rotate-180'
            )
          "
        />
      </button>

      <div *ngIf="open">
        <ul class="tw-flex tw-flex-col">
          <li *ngFor="let child of item.children">
            <ng-container *ngIf="child.href || child.routerLink">
              <da-nav-item
                [item]="child"
                [active]="child === activeChild"
                (itemSelect)="handleItemSelect(child)"
                className="tw-px-12 tw-text-base"
              ></da-nav-item>
            </ng-container>
          </li>
        </ul>
      </div>

      <ul
        *ngIf="
          !open && activeChild && (activeChild.href || activeChild.routerLink)
        "
      >
        <li>
          <da-nav-item
            [item]="activeChild"
            [active]="true"
            (itemSelect)="handleItemSelect(activeChild)"
            className="tw-px-12 tw-text-base"
          ></da-nav-item>
        </li>
      </ul>
    </div>
  `,
})
export class NavDisclosureComponent implements OnInit {
  @Input() item!: LayoutNavigationItem;
  @Input() beta?: LayoutFeature;
  @Output() itemSelect = new EventEmitter<LayoutNavigationItem>();

  open = false;
  activeChild: LayoutNavigationItem | null = null;
  twJoin = twJoin;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.updateActiveChild(this.router.url);
  }

  handleItemSelect(item: LayoutNavigationItem) {
    this.open = false;
    item.trackEvent?.();
    this.itemSelect.emit(item);
  }

  private updateActiveChild(url: string) {
    this.activeChild = getActiveChild(url, this.item.children);
  }
}
