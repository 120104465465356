<ng-container *ngIf="vm$ | async as vm">
  <div>
    <div class="guts-p-h-1-half guts-p-v-half">
      <ng-container *ngIf="!vm.showSkeleton">
        <div
          *ngFor="let item of vm.itemBin; let first = first"
          class="full-width l_flexbar"
          [ngClass]="{ 'border-top': !first }"
          data-dgat="binModal-fa9"
        >
          <ng-container *ngIf="item">
            <div class="l_flex-grow">
              <label
                class="input-summary l_flex-grow l_flex-justify left-text l_flex l_flex-center-children guts-p-v-1 cursor-pointer"
              >
                <input
                  type="checkbox"
                  name="isSelected"
                  [attr.aria-label]="item.reference.title"
                  [checked]="item.isSelected"
                  (change)="toggleSelectedItem(item, !item.isSelected)"
                  data-dgat="binModal-e91"
                />
                <!-- NOTE: `dgx-list-item` was not used here intentionally, since we didn't want the items in this list to be seen as links (clicking the items should only check/uncheck the checkbox) -->
                <div class="full-width l_flexbar guts-p-l-1">
                  <div class="guts-m-r-1">
                    <dgx-image-layout
                      format="list"
                      [videoUrl]="
                        item.reference.inputType === 'Video'
                          ? item.reference.url
                          : ''
                      "
                      [imageSrc]="item.reference.imageUrl"
                      [resourceType]="item.reference.resourceType"
                      [resourceId]="item.reference.resourceId"
                      [providerImage]="item.reference.providerImageInfo?.svg"
                    ></dgx-image-layout>
                  </div>
                  <div class="l_flex-grow">
                    <h2
                      class="par par--small font-semibold clamp"
                      *ngIf="item.reference.title"
                    >
                      {{ item.reference.title | htmlToPlaintext }}
                    </h2>
                    <p class="par par--small par--light clamp">
                      {{ item.reference.inputType | displayType }}
                    </p>
                  </div>
                </div>
              </label>
            </div>
            <button
              type="button"
              (click)="removeItemFromBin(item)"
              [ngbTooltip]="'Core_Remove' | translate"
              [attr.aria-label]="'Core_Remove' | translate"
              data-dgat="binModal-d6a"
            >
              <df-icon icon="trash"></df-icon>
            </button>
          </ng-container>
        </div>
      </ng-container>
      <div *ngIf="vm.showSkeleton">
        <dgx-skeleton type="list" [rows]="5"></dgx-skeleton>
      </div>
      <div *ngIf="!vm.showSkeleton && vm.itemBin.length === 0">
        <h3 class="h3 par--small par--light center-text guts-p-h-12 guts-p-v-5">
          {{ i18n.Pathways_EmptyBinMessage }}
        </h3>
      </div>
    </div>
    <dgx-modal-footer
      [canCancel]="true"
      [useDefaultSubmitButton]="false"
      (dismiss)="dismiss.emit($event)"
    >
      <button
        df-button
        dfButtonType="primary"
        [disabled]="isSubmitDisabled || isSubmitting"
        [class.is_disabled]="isSubmitDisabled || isSubmitting"
        [attr.aria-disabled]="isSubmitDisabled || isSubmitting"
        [attr.aria-label]="isSubmitting ? i18n.Core_Loading : addItemsButton"
        (click)="addContent()"
        data-dgat="binModal-4c5"
      >
        <df-spinner-button [isSpinning]="isSubmitting">
          {{ addItemsButton }}
        </df-spinner-button>
      </button>
    </dgx-modal-footer>
  </div>
</ng-container>
