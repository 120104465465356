import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SlugifyPipe } from '@app/shared/pipes/slugify.pipe';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'dgx-tag-rating-provider-label',
  templateUrl: './tag-rating-provider-label.component.html',
  styleUrls: ['./tag-rating-provider-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingProviderLabelComponent implements OnInit {
  public i18n: { [key: string]: string };
  public imgSrc: string;

  @Input() public rating: TagsApi.UserTagRating;
  @Input() public checkpointCount?: number = 0;

  constructor(
    private translate: TranslateService,
    private slugify: SlugifyPipe,
    private tagRatingType: TagRatingTypePipe,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.i18n = this.translate.instant(['dgTagRating_ProviderLogo'], {
      ProviderName: this.rating.providerName || 'Degreed',
    });
    this.setImageSrc();
  }

  public showPrivacyIcon(rating) {
    // check for pending status and privacy id
    return rating.privacyId === 0 && rating.dateCompleted;
  }

  public get ratingType(): string {
    return this.tagRatingType.transform(this.rating as TagsApi.UserTagRating);
  }

  private setImageSrc(): void {
    const provider = this.rating.providerName
      ? this.slugify.transform(this.rating.providerName)
      : 'degreed';
    this.imgSrc = `/content/img/app/tag-ratings/small/${provider}.svg`;
  }

  public getPrivacyTooltip() {
    return !this.authService.authUser.participateInTeamProgram
      ? this.translate.instant('Core_Private')
      : this.translate.instant('Core_LimitedVisibility');
  }
}
