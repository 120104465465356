import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PredicateListComponent } from './components/predicate-list/predicate-list.component';

@NgModule({
  declarations: [PredicateListComponent],
  exports: [PredicateListComponent],
  imports: [CommonModule],
})
export class BusinessRulesSharedModule {}
