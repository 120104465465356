import { ReactiveStore } from '@app/shared/rsm';
import { emitOnce } from '@ngneat/elf';
import {
  getActiveIds,
  removeActiveIds,
  resetActiveIds,
  setActiveIds,
  updateAllEntities,
  updateEntities,
} from '@ngneat/elf-entities';

import { OrgPlansState, OrgPlanEntity } from './org-plans.model';

export class PlansStore extends ReactiveStore<OrgPlansState, OrgPlanEntity> {
  constructor(key: string, init: () => OrgPlansState) {
    super(key, init);
  }
  /**********************************************
   * Selection Methods
   **********************************************/

  public clearAllSelections(): void {
    emitOnce(() => {
      this._store.update(updateAllEntities({ isSelected: false }));
      this._store.update(resetActiveIds());
    });
  }

  /**
   * Select an item as 'active'
   * Remove any other selections if clearAll === true
   */
  public selectItem(id: string, clearAll = true) {
    if (!id) return;

    const clearActives = clearAll
      ? resetActiveIds
      : () => (s: OrgPlansState) => s;
    const actives = clearAll ? [] : this._store.query(getActiveIds);
    const isActive = actives.indexOf(id) > -1;

    if (!isActive || clearAll) {
      emitOnce(() => {
        this._store.update(clearActives(), setActiveIds([...actives, id]));
        this._store.update(
          updateEntities(id, (entity) => ({ ...entity, isSelected: true }))
        );
      });
    }
  }

  public deselectItem(id: string) {
    if (!id) return;

    emitOnce(() => {
      this._store.update(removeActiveIds(id));
      this._store.update(
        updateEntities(id, (entity) => ({ ...entity, isSelected: false }))
      );
    });
  }
}
