import { CommonModule } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
/**
 * Wizard step component
 * This component allows you show relevant content based on the current step in the wizard component
 * use <dgx-wizard-step-label> (optional) to define the icon/label of each step component
 * use <dgx-wizard-step-symbol> for custom icon in the header
 * @param active {boolean} is this step active
 * @param stepEnter {method} method called while navigating from one step to another
 * @example
 * ```
 * <dgx-wizard
 *   [navigateForward]="true"
 *   [navigateBackward]="true"
 *   indicator="arrow"
 *  >
 *    <dgx-wizard-step (stepEnter)="preStepChange()">
 *      content of step 1
 *    </dgx-wizard-step>
 *    <dgx-wizard-step (stepEnter)="preStepChange()">
 *      content of step 2
 *    </dgx-wizard-step>
 *
 * </dgx-wizard>
 * ```
 */
@Component({
  selector: 'dgx-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss'],
})
export class WizardStepComponent {
  /**
   *  is this step active
   * @type {boolean}
   */
  @Input() active: boolean = false;
  /**
   *  function to be called before navigating to the next step
   * @type {method}
   */
  @Input() stepEnter: (data: any) => void;
  @ContentChild('wizardStepTitle', { static: true })
  wizardStepTitle: TemplateRef<any> = null;
  @ContentChild('wizardStepSymbol', { static: true })
  wizardStepSymbol: TemplateRef<any> = null;
  public isValid: boolean = false;
}
