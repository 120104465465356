import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WindowToken } from '../../window.token';

@Injectable()
export class MiniProfilerInterceptor implements HttpInterceptor {
  private profiler = this.windowRef['MiniProfiler'];

  constructor(@Inject(WindowToken) private windowRef: Window) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (typeof this.profiler !== 'undefined' && evt && evt.headers) {
            this.getProfilerResults(evt.headers);
          }
        }
      })
    );
  }

  private getProfilerResults(headers: HttpHeaders) {
    const miniProfilerHeaders = headers.getAll('x-miniprofiler-ids');

    if (!miniProfilerHeaders) {
      return;
    }

    const idsToFetch = [];
    miniProfilerHeaders.forEach((miniProfilerIdHeaderValue) => {
      const ids = JSON.parse(miniProfilerIdHeaderValue) as string[];
      idsToFetch.push(...ids);
    });

    this.profiler.fetchResults(idsToFetch);
  }
}
