<div *ngIf="metaData !== {}">
  <dl
    *ngIf="metaData"
    class="l_flex m-l_flex font-medium par par--light_a par--small guts-m-b-quart"
    [class.resource-meta--compact]="config?.compact"
  >
    <dt class="a11y-hide">{{ i18n.A11y_OptionalStatus }}</dt>
    <dd
      *ngIf="isOptional"
      class="resource-meta__item"
      [title]="i18n.Core_Optional"
    >
      <span class="no-wrap badge badge-pill badge-pill--compact badge-neutral">
        {{ i18n.Core_Optional }}
      </span>
    </dd>
    <dt class="a11y-hide">{{ i18n.A11y_VerificationStatus }}</dt>
    <dd
      *ngIf="metaData.isVerified"
      data-dgprop-item-clicked="Verified"
      class="guts-m-r-half resource-meta__item"
      [title]="i18n.dgContentTile_Verified"
    >
      <df-icon
        icon="checkmark-circle"
        size="small"
        class="color-success-dark guts-m-r-quart verified-icon"
      ></df-icon>
      <span class="color-success-dark">{{ i18n.dgContentTile_Verified }}</span>
    </dd>
    <dt class="a11y-hide">{{ i18n.Core_Completion_Progress }}</dt>
    <dd
      *ngIf="showPathwayProgress"
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      data-dgat="learning-resource-metadata-pathwayprogress-8de"
    >
      <span class="badge badge-pill badge-pill--compact badge-emphasize">{{
        pathwayPercentComplete
      }}</span>
    </dd>
    <dt class="a11y-hide">{{ i18n.Core_Completion_Progress }}</dt>
    <dd
      *ngIf="isCompletedPathway || isCompletedAcademy"
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      data-dgat="learning-resource-metadata-pathwayprogress-8de"
    >
      <span
        class="badge badge-pill badge-success"
        [ngClass]="{
          'badge-pill--compact': config.compact,
        }"
        >{{ i18n.Core_Completed }}</span
      >
    </dd>
    <dt class="a11y-hide">{{ i18n.Core_Completion_Progress }}</dt>
    <dd
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      *ngIf="isInProgressAcademy"
    >
      <span
        class="badge badge-pill badge-neutral"
        [ngClass]="{
          'badge-pill--compact': config.compact,
        }"
        >{{ i18n.Core_Started }}</span
      >
    </dd>
    <dt class="a11y-hide">{{ i18n.A11y_AssignmentStatus }}</dt>
    <dd
      *ngIf="showAssignmentBadge"
      class="resource-meta__item l_flexbar l_flex-shrink guts-m-r-half"
      data-dgat="learning-resource-metadata-pathwayprogress-8de"
    >
      <dgx-assignment-badge
        [assignmentInfo]="assignmentInfo"
        [resourceType]="resourceType"
      ></dgx-assignment-badge>
    </dd>
  </dl>

  <dl
    *ngIf="metaData"
    class="l_flex m-l_flex font-medium par par--light_a par--small"
    [class.resource-meta--compact]="config?.compact"
  >
    <dt class="a11y-hide">
      {{ i18n.dgOrgInternalContent_ContentType }}
    </dt>
    <dd
      *ngIf="showResourceLabel"
      class="resource-meta__item l_flex-shrink skip-delimiter guts-p-l-0"
      data-dgat="learning-resource-metadata-resourceLabel-8dde"
      [title]="translatedResourceLabel"
    >
      <span>{{ translatedResourceLabel }}</span>
    </dd>
    <ng-container
      *ngIf="
        !(
          liveSessions?.length > 0 &&
          translatedResourceLabel === i18n.Core_Event
        )
      "
    >
      <dt class="a11y-hide">{{ i18n.Core_Duration }}</dt>
      <dd
        *ngIf="showLegacyDurationDisplay && metaData.durationDisplay"
        class="resource-meta__item"
        data-dgat="learning-resource-metadata-duration-8de8"
        [title]="metaData.durationDisplay"
      >
        {{ metaData.durationDisplay }}
      </dd>
      <dt class="a11y-hide">{{ i18n.Core_Hours }}</dt>
      <dd
        *ngIf="hoursToDisplay"
        class="resource-meta__item"
        data-dgat="learning-resource-metadata-hours-123e"
        [title]="hoursToDisplay"
      >
        {{ hoursToDisplay }}
      </dd>
      <dt class="a11y-hide">{{ i18n.Core_Minutes }}</dt>
      <dd
        *ngIf="minutesToDisplay"
        class="resource-meta__item"
        data-dgat="learning-resource-metadata-minutes-123e"
        [title]="minutesToDisplay"
      >
        {{ minutesToDisplay }}
      </dd>
    </ng-container>
    <dt class="a11y-hide">{{ i18n.Core_Provider }}</dt>
    <dd
      *ngIf="showProvider"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-provider-8de"
      [title]="metaData.providerSummaryName"
    >
      <a
        *ngIf="providerLinkEnabled"
        [href]="getProviderUrl(metaData.providerId)"
        class="resource-meta__link"
        (click)="trackLinkClick()"
        data-dgat="learning-resource-metadata-84d"
        >{{ metaData.providerSummaryName }}</a
      >
      <span *ngIf="!providerLinkEnabled">
        {{ metaData.providerSummaryName }}
      </span>
    </dd>

    <dt class="a11y-hide">
      {{ i18n.ReportingInApp_ReportColumn_ProgramLength }}
    </dt>
    <dd
      *ngIf="metaData.programLength && config?.isAcademy"
      class="resource-meta__item"
      [title]="metaData.programLength + ' ' + metaData.programLengthUnit"
    >
      <span
        >{{ metaData.programLength + ' ' + metaData.programLengthUnit }}
      </span>
    </dd>

    <dt class="a11y-hide">{{ i18n.Core_Cost }}</dt>
    <dd
      *ngIf="metaData.cost && !config?.isAcademy"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-cost-8de"
      [title]="metaData.cost"
    >
      <span>{{ metaData.cost }}</span>
    </dd>

    <dt class="a11y-hide">{{ i18n.A11y_NumberOfSkills }}</dt>
    <dd
      *ngIf="metaData.tagsCountDisplay"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-tagscount-8de"
      [title]="metaData.tagsCountDisplay"
    >
      <span>{{ metaData.tagsCountDisplay }}</span>
    </dd>

    <dt class="a11y-hide">{{ i18n.Core_Subtitle }}</dt>
    <dd
      *ngIf="metaData.subtitle"
      class="resource-meta__item"
      data-dgat="learning-resource-metadata-subtitle-8de"
      [title]="metaData.subtitle"
    >
      <span>{{ metaData.subtitle }}</span>
    </dd>

    <!-- Format -->
    <dt class="a11y-hide">{{ i18n.Core_Format }}</dt>
    <dd
      *ngIf="metaData.format"
      class="resource-meta__item"
      [title]="metaData.format"
    >
      {{ metaData.format }}
    </dd>

    <!-- Difficulty -->
    <dt class="a11y-hide">{{ i18n.Core_Difficulty }}</dt>
    <dd
      *ngIf="metaData.difficulty"
      class="resource-meta__item"
      [title]="metaData.difficulty"
    >
      {{ metaData.difficulty }}
    </dd>

    <!-- Continuing Education Units -->
    <dt class="a11y-hide">
      {{ i18n.A11y_ContinuingEducationUnits }}
    </dt>
    <dd
      *ngIf="metaData.continuingEducationUnits"
      class="resource-meta__item"
      [title]="metaData.continuingEducationUnits"
    >
      {{ metaData.continuingEducationUnits }}
    </dd>
  </dl>
</div>
