import { Observable } from 'rxjs';
import { Entity, StoreState } from '@app/shared/rsm/store.model';

import { OrgPlansColumnIndex } from '@app/orgs/components/org-plans/org-plans.component';
import {
  GetOrgTargetResponse,
  OrgTargetVM,
} from '@app/target/target-api.model';
import { Filter } from '@app/shared/components/filter/filter.component';

export type OrgPaging = {
  take: number;
  skip: number;
  hasMoreItems: boolean;
};
export type OrgOrdering = {
  orderBy: OrgPlansColumnIndex;
  isDescending: boolean;
};

export type LoadOptions = {
  ordering: Partial<OrgOrdering>;
  paging: Partial<OrgPaging>;
  term: string;
  filterType?: number[];
  clearPlans?: boolean;
};

export type OrgPlansSource = (
  params: LoadOptions
) => Observable<GetOrgTargetResponse>;

export type OrgPlanStateWithEntities = OrgPlansState & {
  ids: string[];
  entities: Record<string, OrgPlanEntity>;
};

export type OrgPlansComputedState = {
  allPlans: OrgPlanEntity[];
  selectedPlans: OrgPlanEntity[];
};

export type OrgPlansViewModel = OrgPlansState &
  OrgPlansAPI &
  OrgPlansComputedState;

export interface OrgPlansAPI {
  filters: Filter[];
  loadMore: () => void;
  updateSort: (column: string) => void;
  updateFilters: (filters: Filter[]) => void;
  search: (term: string) => void;
}

export function initState(): OrgPlansState {
  return {
    term: '',
    paging: {
      take: 25,
      skip: 0,
      hasMoreItems: undefined,
    },
    ordering: {
      orderBy: OrgPlansColumnIndex.name,
      isDescending: false,
    },
    filterType: [],
  };
}

export type OrgPlanEntity = OrgTargetVM & Entity;

export interface OrgPlansState extends StoreState {
  term: string;
  paging: OrgPaging;
  ordering: OrgOrdering;
  filterType: number[];
}
