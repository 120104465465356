import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { InputContext, RenderMode } from '../../user-input.model';
import { TranslateService } from '@ngx-translate/core';
import {
  SelectFieldComponent,
  SelectFieldParams,
} from '@app/form-fields/wrappers/select-field/select-field.component';
import { FieldType } from '@app/user-content/services/field-builder-factory';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { VideoCommonFieldBuilder } from '../video-common-field.builder';
import { VideoFormModel } from '../video-form.model.model';
import { VideoUploadAdapter } from '../video-upload.adapter';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { GroupIdentifier } from '@app/groups/group-api';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

/** Creates an array of form field configurations for the second (full-form) step of adding/editing
 * Videos within the content catalog */
@Injectable({ providedIn: 'any' })
export class ContentCatalogFullVideoFormRenderer implements FormRenderer {
  public expandAdvanced = new BehaviorSubject(false);
  constructor(
    private translate: TranslateService,
    private builder: DfFormFieldBuilder,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private uploadAdapter: VideoUploadAdapter,
    private videoFieldBuilder: VideoCommonFieldBuilder,
    private webEnvironmentService: WebEnvironmentService,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext<VideoFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();

    if (
      !(
        (context.inputContext.renderMode === RenderMode.ContentCatalog &&
          (vm.isMediaParsed ||
            vm.uploadedContentDetails ||
            context.inputContext.isEditing)) ||
        (context.inputContext.renderMode === RenderMode.Pathways &&
          context.inputContext.isEditing)
      )
    ) {
      throw new Error('Wrong state for renderer');
    }

    const isPlayable$ = new Subject<boolean>();
    vm.isPlayableVideo$ = isPlayable$.asObservable();
    return [
      this.commonFieldBuilder.buildCreatedByField(context.templates.creator),
      // Media URL
      this.videoFieldBuilder.buildLoadedMediaUrlField(
        context.state,
        context.templates.videoCompatibleAndDupsHelp,
        context.templates.urlBrokenValidation,
        vm.shouldShowContentUploader
      ),
      this.buildVideoUploaderField(
        context.state,
        context.templates.contentUploader
      ),
      this.buildTitleField(),
      this.buildFormatField(vm.inputTypeFormats$),
      this.buildLengthField(),
      // Duration
      this.inputFieldBuilder.buildDurationFields(
        this.ldFlagsService.durationForCoursesEventsOther,
        vm.inputType
      ),
      this.buildDescriptionField(),
      this.buildImageUploaderField(context.inputContext, vm.inputId),
      // Skills
      this.commonFieldBuilder.buildSkillsField(vm.topTags, true), // we have a lot of fields to show in catalog mode so hide top tags for compactness
      // Advanced fields
      this.buildAdvancedFieldset(
        vm.canRestrictContent,
        vm.isVisibleToOrg,
        vm.organizationId,
        vm.expandAdvanced$,
        vm.groups,
        context.templates.advancedExpander,
        context
      ),
    ];
  }

  private buildTitleField() {
    return this.builder.title().withDgatId('videoForm-3bf').build();
  }

  private buildFormatField(inputTypeFormats$: Observable<string[]>) {
    return this.builder
      .fieldGroup(
        '',
        [
          this.builder
            .foreignField<SelectFieldParams>(
              'format',
              'dgOrgInternalContentForm_VideoFormat',
              SelectFieldComponent.REGISTERED_FIELD_TYPE,
              {
                options$: inputTypeFormats$.pipe(
                  // map formats to SelectOption
                  map((formats) => formats.map((f) => ({ title: f })))
                ),
                optionLabelKey: 'title',
                optionTrackByKey: 'title',
                placeholder: this.translate.instant(
                  'dgOrgInternalContentForm_VideoFormPlaceholder'
                ),
              }
            )
            .styledBy('df-form__col-half')
            .build(),
        ],
        'df-form__row'
      )
      .build();
  }

  private buildLengthField() {
    return this.builder
      .fieldGroup(
        '',
        [
          this.builder
            .requiredTextInput(
              'mediaLength',
              'dgOrgInternalContentForm_VideoLength'
            )
            .ofType('number')
            .withMin(0)
            .withDgatId('videoForm-684')
            .styledBy('df-form__col-half')
            .hiddenWhen(() => this.ldFlagsService.durationForCoursesEventsOther)
            .build(),
        ],
        'df-form__row'
      )
      .build();
  }

  private buildDescriptionField() {
    return this.builder.descriptionLong().withDgatId('articleForm-59d').build();
  }

  private buildImageUploaderField(inputContext: InputContext, inputId: number) {
    return this.contentCatalogFormBuilderService
      .getBuilder(FieldType.USER_IMAGE_FIELD)
      .build({
        useCropper: true,
        inputType: inputContext.inputType,
        resourceId: inputId,
      });
  }

  private buildVideoUploaderField(
    state: () => VideoFormModel,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    const hostedContentDetails = state().uploadedContentDetails;
    return this.builder
      .customField('mediaUrl', '', contentUploaderTemplate, {
        file: hostedContentDetails
          ? {
              name: hostedContentDetails.fileName,
              size: hostedContentDetails.fileSize,
            }
          : undefined,
        uploadAdapter: this.uploadAdapter,
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
        },
        hasDivider: false,
        i18n: this.translate.instant([
          'Core_Or',
          'dgOrgInternalContentForm_ReRecordVideo',
          'dgOrgInternalContentForm_ReUploadRecording',
        ]),
      })
      .unwrapped()
      .hiddenWhen(
        () =>
          !(
            state().shouldShowContentUploader ||
            state().shouldShowReplaceContentButtons
          )
      )
      .build();
  }

  private buildAdvancedFieldset(
    canRestrictContent: boolean,
    isInitiallyVisibleToOrg: boolean,
    orgId: number,
    expand$: Observable<boolean>,
    groups: GroupIdentifier[],
    expanderTemplate: TemplateRef<DfFieldTemplateContext>,
    context: RendererContext<VideoFormModel>
  ) {
    return this.contentCatalogFormBuilderService
      .getBuilder(FieldType.ADVANCED_FIELDSET)
      .build({
        canRestrictContent,
        orgId,
        isInitiallyVisibleToOrg,
        groups,
        expand$,
        expanderTemplate,
        fieldOptions: {
          externalId: {
            isDisabled: context.state().fileManaged,
          },
        },
      });
  }
}
