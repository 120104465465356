import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GroupIdentifier } from '@app/groups/group-api';
import { AuthService } from '@app/shared/services/auth.service';
import { SubscriptionManagerService } from '@app/shared/services/subscription-manager.service';
import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';
import { startWith } from 'rxjs/operators';

/** Parameters passed to drive the {@link SelectFieldComponent} properties and its child {@link SelectComponent} inputs */
export interface OrgInternalContentVisibilityFieldParams {
  /** Optional organization ID. If omitted, the user's default org ID will be used. */
  orgId?: number;
  isVisibleToOrg?: boolean;
  groups?: GroupIdentifier[];
}

/** A Formly-compatible wrapper for the dgx-orgs-internal-content-visibility component. To create
 * one of these, pass {@link REGISTERED_FIELD_TYPE} to {@link DfFormFieldBuilder.foreignField}.
 */
@Component({
  selector: 'dgx-org-internal-content-visibility-field',
  templateUrl: 'org-internal-content-visibility-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionManagerService],
})
export class OrgInternalContentVisibilityFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public static REGISTERED_FIELD_TYPE = 'dgx-org-internal-content-visibility';

  public groups: GroupIdentifier[];
  public selectedGroupIds = [];
  public selectedGroupNames = [];

  constructor(
    private authService: AuthService,
    private subscriptionManager: SubscriptionManagerService
  ) {
    super();
  }

  public get params(): OrgInternalContentVisibilityFieldParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions?.params ?? {};
  }

  public get orgId(): number {
    return this.params.orgId ?? this.authService.authUser.defaultOrgId;
  }

  public ngOnInit() {
    if (this.params?.groups) {
      this.formControl.setValue(this.params.groups);
    }

    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        this.subscriptionManager.takeUntilDestroyed()
      )
      .subscribe(() => {
        this.updateSelectedGroupInfoFromControl();
      });
  }

  public handleSetVisibilityValues({ groupIds, groupNames }) {
    // HACK: re-zip the group data from org visibility component
    const groups: GroupIdentifier[] = groupIds.map((groupId, i) => ({
      groupId,
      name: groupNames[i],
    }));
    this.updateSelectedGroupInfo(groups);
    this.formControl.setValue(groups);
    this.formControl.markAsDirty();
  }

  public handleBlur() {
    this.formControl.markAsTouched();
  }

  private updateSelectedGroupInfoFromControl() {
    const selectedGroups = (this.formControl.value as GroupIdentifier[]) ?? [];
    this.updateSelectedGroupInfo(selectedGroups);
  }

  private updateSelectedGroupInfo(selectedGroups: GroupIdentifier[]) {
    this.selectedGroupNames = selectedGroups.map((g) => g.name);
    this.selectedGroupIds = selectedGroups.map((g) => g.groupId);
  }
}
