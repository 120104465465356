<form (ngSubmit)="submit()">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="'Core_SaveChanges' | translate"
    [useDefaultForm]="false"
    [isSubmitDisabled]="!canSubmit"
  >
    <ng-container class="modal-header"> {{ headerText }} </ng-container>
    <ng-container class="modal-body">
      <div class="form-wrap guts-m-full-2 m-guts-m-full-1">
        <h2
          *ngIf="isDefaultMessage"
          class="par--small par--light font-semibold guts-p-b-1"
        >
          {{ 'OrgSettings_DefaultMessage' | translate }}
        </h2>
        <div class="l_flex" *ngIf="!isDefaultMessage">
          <df-icon
            class="color-ebony-a45 guts-m-r-half"
            icon="exclamation-circle"
          ></df-icon>
          <p class="par--small par--light font-semibold guts-p-b-1">
            {{ 'OrgSettings_CustomMessageDesc' | translate }}
          </p>
        </div>
        <div class="l_flex guts-p-b-half">
          <textarea
            class="setting-tile__textbox"
            [id]="setting.id"
            dgxAutofocus
            type="text"
            [name]="setting.id"
            [placeholder]="'Channel_SettingMessagePlaceholder' | translate"
            [style.min-height]="setting.size * 1.5 + 'rem'"
            [(ngModel)]="message"
            data-dgat="orgMessageEditModal-784"
          ></textarea>
        </div>
        <span
          class="l_flex--align-right"
          *ngIf="!isDefaultMessage && defaultMessage"
        >
          <button
            class="link par par--small par--light"
            (click)="resetToDefault()"
            data-dgat="orgMessageEditModal-b67"
          >
            {{ 'OrgSettings_ResetDefaultMessage' | translate }}
          </button>
        </span>
      </div>
    </ng-container>
  </dgx-modal>
</form>
