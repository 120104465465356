/* 🤖 this file was generated by svg-to-ts */
import { DfIconMentor24 } from './DfIcon-mentor-24.icon';
import { DfIconPlusCircle24 } from './DfIcon-plus-circle-24.icon';
import { DfIconTwitterSquare24 } from './DfIcon-twitter-square-24.icon';
import { DfIconAccomplishment16 } from './DfIcon-accomplishment-16.icon';
import { DfIconAccomplishmentCircle16 } from './DfIcon-accomplishment-circle-16.icon';
import { DfIconArchive16 } from './DfIcon-archive-16.icon';
import { DfIconArrowBackward16 } from './DfIcon-arrow-backward-16.icon';
import { DfIconArrowBackwardCircle16 } from './DfIcon-arrow-backward-circle-16.icon';
import { DfIconArrowCircular16 } from './DfIcon-arrow-circular-16.icon';
import { DfIconArrowCircularLeft16 } from './DfIcon-arrow-circular-left-16.icon';
import { DfIconArrowDown16 } from './DfIcon-arrow-down-16.icon';
import { DfIconArrowDownRight16 } from './DfIcon-arrow-down-right-16.icon';
import { DfIconArrowDownTray16 } from './DfIcon-arrow-down-tray-16.icon';
import { DfIconArrowForward16 } from './DfIcon-arrow-forward-16.icon';
import { DfIconArrowForwardCircle16 } from './DfIcon-arrow-forward-circle-16.icon';
import { DfIconArrowForwardSquare16 } from './DfIcon-arrow-forward-square-16.icon';
import { DfIconArrowLeft16 } from './DfIcon-arrow-left-16.icon';
import { DfIconArrowNe16 } from './DfIcon-arrow-ne-16.icon';
import { DfIconArrowOpenRight16 } from './DfIcon-arrow-open-right-16.icon';
import { DfIconArrowRight16 } from './DfIcon-arrow-right-16.icon';
import { DfIconArrowUp16 } from './DfIcon-arrow-up-16.icon';
import { DfIconArrowUpDown16 } from './DfIcon-arrow-up-down-16.icon';
import { DfIconArrowUpRightOut16 } from './DfIcon-arrow-up-right-out-16.icon';
import { DfIconArrowUpTray16 } from './DfIcon-arrow-up-tray-16.icon';
import { DfIconArticle16 } from './DfIcon-article-16.icon';
import { DfIconAssessment16 } from './DfIcon-assessment-16.icon';
import { DfIconAuthorNote16 } from './DfIcon-author-note-16.icon';
import { DfIconAward16 } from './DfIcon-award-16.icon';
import { DfIconBadge16 } from './DfIcon-badge-16.icon';
import { DfIconBargraph16 } from './DfIcon-bargraph-16.icon';
import { DfIconBargraphSquare16 } from './DfIcon-bargraph-square-16.icon';
import { DfIconBell16 } from './DfIcon-bell-16.icon';
import { DfIconBold16 } from './DfIcon-bold-16.icon';
import { DfIconBook16 } from './DfIcon-book-16.icon';
import { DfIconBookmark16 } from './DfIcon-bookmark-16.icon';
import { DfIconBroadcast16 } from './DfIcon-broadcast-16.icon';
import { DfIconBulletedList16 } from './DfIcon-bulleted-list-16.icon';
import { DfIconCalendar16 } from './DfIcon-calendar-16.icon';
import { DfIconCameraPlus16 } from './DfIcon-camera-plus-16.icon';
import { DfIconCaretDown16 } from './DfIcon-caret-down-16.icon';
import { DfIconCaretUp16 } from './DfIcon-caret-up-16.icon';
import { DfIconCertificate16 } from './DfIcon-certificate-16.icon';
import { DfIconChain16 } from './DfIcon-chain-16.icon';
import { DfIconCheckmark16 } from './DfIcon-checkmark-16.icon';
import { DfIconCheckmarkBadge16 } from './DfIcon-checkmark-badge-16.icon';
import { DfIconCheckmarkCircle16 } from './DfIcon-checkmark-circle-16.icon';
import { DfIconCheckmarkOutline16 } from './DfIcon-checkmark-outline-16.icon';
import { DfIconCheckmarkSquare16 } from './DfIcon-checkmark-square-16.icon';
import { DfIconChevronDoubleLeft16 } from './DfIcon-chevron-double-left-16.icon';
import { DfIconChevronDoubleRight16 } from './DfIcon-chevron-double-right-16.icon';
import { DfIconChevronDown16 } from './DfIcon-chevron-down-16.icon';
import { DfIconChevronLeft16 } from './DfIcon-chevron-left-16.icon';
import { DfIconChevronRight16 } from './DfIcon-chevron-right-16.icon';
import { DfIconChevronUp16 } from './DfIcon-chevron-up-16.icon';
import { DfIconClock16 } from './DfIcon-clock-16.icon';
import { DfIconClockArrow16 } from './DfIcon-clock-arrow-16.icon';
import { DfIconClockFilled16 } from './DfIcon-clock-filled-16.icon';
import { DfIconClockSquare16 } from './DfIcon-clock-square-16.icon';
import { DfIconCoins16 } from './DfIcon-coins-16.icon';
import { DfIconColumns16 } from './DfIcon-columns-16.icon';
import { DfIconCourse16 } from './DfIcon-course-16.icon';
import { DfIconCross16 } from './DfIcon-cross-16.icon';
import { DfIconCrossCircle16 } from './DfIcon-cross-circle-16.icon';
import { DfIconCrossCircleOutline16 } from './DfIcon-cross-circle-outline-16.icon';
import { DfIconDart16 } from './DfIcon-dart-16.icon';
import { DfIconDartSquare16 } from './DfIcon-dart-square-16.icon';
import { DfIconDegree16 } from './DfIcon-degree-16.icon';
import { DfIconDots16 } from './DfIcon-dots-16.icon';
import { DfIconDotsDoubleVertical16 } from './DfIcon-dots-double-vertical-16.icon';
import { DfIconEnvelope16 } from './DfIcon-envelope-16.icon';
import { DfIconEnvelopeCircle16 } from './DfIcon-envelope-circle-16.icon';
import { DfIconEpisode16 } from './DfIcon-episode-16.icon';
import { DfIconEvent16 } from './DfIcon-event-16.icon';
import { DfIconExclamationCircle16 } from './DfIcon-exclamation-circle-16.icon';
import { DfIconExclamationCircleOutline16 } from './DfIcon-exclamation-circle-outline-16.icon';
import { DfIconExclamationSquare16 } from './DfIcon-exclamation-square-16.icon';
import { DfIconExclamationTriangleOutline16 } from './DfIcon-exclamation-triangle-outline-16.icon';
import { DfIconExperience16 } from './DfIcon-experience-16.icon';
import { DfIconEyeClosed16 } from './DfIcon-eye-closed-16.icon';
import { DfIconEyeOpen16 } from './DfIcon-eye-open-16.icon';
import { DfIconEyeOpenSquare16 } from './DfIcon-eye-open-square-16.icon';
import { DfIconFacebookSquare16 } from './DfIcon-facebook-square-16.icon';
import { DfIconFlag16 } from './DfIcon-flag-16.icon';
import { DfIconGear16 } from './DfIcon-gear-16.icon';
import { DfIconGearSquare16 } from './DfIcon-gear-square-16.icon';
import { DfIconGoogleSquare16 } from './DfIcon-google-square-16.icon';
import { DfIconGrid16 } from './DfIcon-grid-16.icon';
import { DfIconHeart16 } from './DfIcon-heart-16.icon';
import { DfIconHeartSquare16 } from './DfIcon-heart-square-16.icon';
import { DfIconHome16 } from './DfIcon-home-16.icon';
import { DfIconImageBroken16 } from './DfIcon-image-broken-16.icon';
import { DfIconImagePlus16 } from './DfIcon-image-plus-16.icon';
import { DfIconInfo16 } from './DfIcon-info-16.icon';
import { DfIconInfoSquare16 } from './DfIcon-info-square-16.icon';
import { DfIconItalic16 } from './DfIcon-italic-16.icon';
import { DfIconLinkedinSquare16 } from './DfIcon-linkedin-square-16.icon';
import { DfIconLockClosed16 } from './DfIcon-lock-closed-16.icon';
import { DfIconLockOpen16 } from './DfIcon-lock-open-16.icon';
import { DfIconMagnifyingGlass16 } from './DfIcon-magnifying-glass-16.icon';
import { DfIconMentor16 } from './DfIcon-mentor-16.icon';
import { DfIconMinus16 } from './DfIcon-minus-16.icon';
import { DfIconMinusCircle16 } from './DfIcon-minus-circle-16.icon';
import { DfIconMinusSquare16 } from './DfIcon-minus-square-16.icon';
import { DfIconNumberedList16 } from './DfIcon-numbered-list-16.icon';
import { DfIconOffice16 } from './DfIcon-office-16.icon';
import { DfIconOpportunity16 } from './DfIcon-opportunity-16.icon';
import { DfIconPage16 } from './DfIcon-page-16.icon';
import { DfIconPaperclip16 } from './DfIcon-paperclip-16.icon';
import { DfIconPencil16 } from './DfIcon-pencil-16.icon';
import { DfIconPencilSquare16 } from './DfIcon-pencil-square-16.icon';
import { DfIconPerson16 } from './DfIcon-person-16.icon';
import { DfIconPersonDouble16 } from './DfIcon-person-double-16.icon';
import { DfIconPersonDoubleSquare16 } from './DfIcon-person-double-square-16.icon';
import { DfIconPersonPlus16 } from './DfIcon-person-plus-16.icon';
import { DfIconPersonSquare16 } from './DfIcon-person-square-16.icon';
import { DfIconPersonTriple16 } from './DfIcon-person-triple-16.icon';
import { DfIconPersonTripleSquare16 } from './DfIcon-person-triple-square-16.icon';
import { DfIconPhone16 } from './DfIcon-phone-16.icon';
import { DfIconPin16 } from './DfIcon-pin-16.icon';
import { DfIconPlay16 } from './DfIcon-play-16.icon';
import { DfIconPlus16 } from './DfIcon-plus-16.icon';
import { DfIconPlusCircle16 } from './DfIcon-plus-circle-16.icon';
import { DfIconPlusOutline16 } from './DfIcon-plus-outline-16.icon';
import { DfIconPlusSquare16 } from './DfIcon-plus-square-16.icon';
import { DfIconQanda16 } from './DfIcon-qanda-16.icon';
import { DfIconQuestionMark16 } from './DfIcon-question-mark-16.icon';
import { DfIconQuestionMarkCircle16 } from './DfIcon-question-mark-circle-16.icon';
import { DfIconQuote16 } from './DfIcon-quote-16.icon';
import { DfIconRecord16 } from './DfIcon-record-16.icon';
import { DfIconSpeechBubble16 } from './DfIcon-speech-bubble-16.icon';
import { DfIconSpeechBubbleCircle16 } from './DfIcon-speech-bubble-circle-16.icon';
import { DfIconSpeechBubbleSquare16 } from './DfIcon-speech-bubble-square-16.icon';
import { DfIconSquareDouble16 } from './DfIcon-square-double-16.icon';
import { DfIconStar16 } from './DfIcon-star-16.icon';
import { DfIconStop16 } from './DfIcon-stop-16.icon';
import { DfIconStripesDown16 } from './DfIcon-stripes-down-16.icon';
import { DfIconTag16 } from './DfIcon-tag-16.icon';
import { DfIconTagCircle16 } from './DfIcon-tag-circle-16.icon';
import { DfIconTagSquare16 } from './DfIcon-tag-square-16.icon';
import { DfIconTask16 } from './DfIcon-task-16.icon';
import { DfIconThumbsDown16 } from './DfIcon-thumbs-down-16.icon';
import { DfIconThumbsDownCircle16 } from './DfIcon-thumbs-down-circle-16.icon';
import { DfIconThumbsUp16 } from './DfIcon-thumbs-up-16.icon';
import { DfIconThumbsUpCircle16 } from './DfIcon-thumbs-up-circle-16.icon';
import { DfIconThumbsUpSquare16 } from './DfIcon-thumbs-up-square-16.icon';
import { DfIconToolsSquare16 } from './DfIcon-tools-square-16.icon';
import { DfIconTrash16 } from './DfIcon-trash-16.icon';
import { DfIconTriangleDown16 } from './DfIcon-triangle-down-16.icon';
import { DfIconTriangleUp16 } from './DfIcon-triangle-up-16.icon';
import { DfIconVideo16 } from './DfIcon-video-16.icon';
import { DfIconVideoCamera16 } from './DfIcon-video-camera-16.icon';
import { DfIconAccomplishment12 } from './DfIcon-accomplishment-12.icon';
import { DfIconAccomplishmentCircle12 } from './DfIcon-accomplishment-circle-12.icon';
import { DfIconArrowCircularLeft12 } from './DfIcon-arrow-circular-left-12.icon';
import { DfIconArrowDownRight12 } from './DfIcon-arrow-down-right-12.icon';
import { DfIconArrowForward12 } from './DfIcon-arrow-forward-12.icon';
import { DfIconArrowUpRightOut12 } from './DfIcon-arrow-up-right-out-12.icon';
import { DfIconBookmark12 } from './DfIcon-bookmark-12.icon';
import { DfIconCaretDown12 } from './DfIcon-caret-down-12.icon';
import { DfIconCaretUp12 } from './DfIcon-caret-up-12.icon';
import { DfIconCheckmark12 } from './DfIcon-checkmark-12.icon';
import { DfIconCheckmarkCircle12 } from './DfIcon-checkmark-circle-12.icon';
import { DfIconChevronDoubleLeft12 } from './DfIcon-chevron-double-left-12.icon';
import { DfIconChevronDoubleRight12 } from './DfIcon-chevron-double-right-12.icon';
import { DfIconChevronDown12 } from './DfIcon-chevron-down-12.icon';
import { DfIconChevronLeft12 } from './DfIcon-chevron-left-12.icon';
import { DfIconChevronRight12 } from './DfIcon-chevron-right-12.icon';
import { DfIconChevronUp12 } from './DfIcon-chevron-up-12.icon';
import { DfIconClockFilled12 } from './DfIcon-clock-filled-12.icon';
import { DfIconCross12 } from './DfIcon-cross-12.icon';
import { DfIconCrossCircle12 } from './DfIcon-cross-circle-12.icon';
import { DfIconExclamationCircle12 } from './DfIcon-exclamation-circle-12.icon';
import { DfIconEyeClosed12 } from './DfIcon-eye-closed-12.icon';
import { DfIconEyeOpen12 } from './DfIcon-eye-open-12.icon';
import { DfIconEyeOpenSquare12 } from './DfIcon-eye-open-square-12.icon';
import { DfIconInfo12 } from './DfIcon-info-12.icon';
import { DfIconLockClosed12 } from './DfIcon-lock-closed-12.icon';
import { DfIconLockOpen12 } from './DfIcon-lock-open-12.icon';
import { DfIconMentor12 } from './DfIcon-mentor-12.icon';
import { DfIconMinus12 } from './DfIcon-minus-12.icon';
import { DfIconOpportunity12 } from './DfIcon-opportunity-12.icon';
import { DfIconPage12 } from './DfIcon-page-12.icon';
import { DfIconPin12 } from './DfIcon-pin-12.icon';
import { DfIconPlus12 } from './DfIcon-plus-12.icon';
import { DfIconTag12 } from './DfIcon-tag-12.icon';
import { DfIconThumbsDown12 } from './DfIcon-thumbs-down-12.icon';
import { DfIconThumbsUp12 } from './DfIcon-thumbs-up-12.icon';
export const completeIconSet = [
  DfIconMentor24,
  DfIconPlusCircle24,
  DfIconTwitterSquare24,
  DfIconAccomplishment16,
  DfIconAccomplishmentCircle16,
  DfIconArchive16,
  DfIconArrowBackward16,
  DfIconArrowBackwardCircle16,
  DfIconArrowCircular16,
  DfIconArrowCircularLeft16,
  DfIconArrowDown16,
  DfIconArrowDownRight16,
  DfIconArrowDownTray16,
  DfIconArrowForward16,
  DfIconArrowForwardCircle16,
  DfIconArrowForwardSquare16,
  DfIconArrowLeft16,
  DfIconArrowNe16,
  DfIconArrowOpenRight16,
  DfIconArrowRight16,
  DfIconArrowUp16,
  DfIconArrowUpDown16,
  DfIconArrowUpRightOut16,
  DfIconArrowUpTray16,
  DfIconArticle16,
  DfIconAssessment16,
  DfIconAuthorNote16,
  DfIconAward16,
  DfIconBadge16,
  DfIconBargraph16,
  DfIconBargraphSquare16,
  DfIconBell16,
  DfIconBold16,
  DfIconBook16,
  DfIconBookmark16,
  DfIconBroadcast16,
  DfIconBulletedList16,
  DfIconCalendar16,
  DfIconCameraPlus16,
  DfIconCaretDown16,
  DfIconCaretUp16,
  DfIconCertificate16,
  DfIconChain16,
  DfIconCheckmark16,
  DfIconCheckmarkBadge16,
  DfIconCheckmarkCircle16,
  DfIconCheckmarkOutline16,
  DfIconCheckmarkSquare16,
  DfIconChevronDoubleLeft16,
  DfIconChevronDoubleRight16,
  DfIconChevronDown16,
  DfIconChevronLeft16,
  DfIconChevronRight16,
  DfIconChevronUp16,
  DfIconClock16,
  DfIconClockArrow16,
  DfIconClockFilled16,
  DfIconClockSquare16,
  DfIconCoins16,
  DfIconColumns16,
  DfIconCourse16,
  DfIconCross16,
  DfIconCrossCircle16,
  DfIconCrossCircleOutline16,
  DfIconDart16,
  DfIconDartSquare16,
  DfIconDegree16,
  DfIconDots16,
  DfIconDotsDoubleVertical16,
  DfIconEnvelope16,
  DfIconEnvelopeCircle16,
  DfIconEpisode16,
  DfIconEvent16,
  DfIconExclamationCircle16,
  DfIconExclamationCircleOutline16,
  DfIconExclamationSquare16,
  DfIconExclamationTriangleOutline16,
  DfIconExperience16,
  DfIconEyeClosed16,
  DfIconEyeOpen16,
  DfIconEyeOpenSquare16,
  DfIconFacebookSquare16,
  DfIconFlag16,
  DfIconGear16,
  DfIconGearSquare16,
  DfIconGoogleSquare16,
  DfIconGrid16,
  DfIconHeart16,
  DfIconHeartSquare16,
  DfIconHome16,
  DfIconImageBroken16,
  DfIconImagePlus16,
  DfIconInfo16,
  DfIconInfoSquare16,
  DfIconItalic16,
  DfIconLinkedinSquare16,
  DfIconLockClosed16,
  DfIconLockOpen16,
  DfIconMagnifyingGlass16,
  DfIconMentor16,
  DfIconMinus16,
  DfIconMinusCircle16,
  DfIconMinusSquare16,
  DfIconNumberedList16,
  DfIconOffice16,
  DfIconOpportunity16,
  DfIconPage16,
  DfIconPaperclip16,
  DfIconPencil16,
  DfIconPencilSquare16,
  DfIconPerson16,
  DfIconPersonDouble16,
  DfIconPersonDoubleSquare16,
  DfIconPersonPlus16,
  DfIconPersonSquare16,
  DfIconPersonTriple16,
  DfIconPersonTripleSquare16,
  DfIconPhone16,
  DfIconPin16,
  DfIconPlay16,
  DfIconPlus16,
  DfIconPlusCircle16,
  DfIconPlusOutline16,
  DfIconPlusSquare16,
  DfIconQanda16,
  DfIconQuestionMark16,
  DfIconQuestionMarkCircle16,
  DfIconQuote16,
  DfIconRecord16,
  DfIconSpeechBubble16,
  DfIconSpeechBubbleCircle16,
  DfIconSpeechBubbleSquare16,
  DfIconSquareDouble16,
  DfIconStar16,
  DfIconStop16,
  DfIconStripesDown16,
  DfIconTag16,
  DfIconTagCircle16,
  DfIconTagSquare16,
  DfIconTask16,
  DfIconThumbsDown16,
  DfIconThumbsDownCircle16,
  DfIconThumbsUp16,
  DfIconThumbsUpCircle16,
  DfIconThumbsUpSquare16,
  DfIconToolsSquare16,
  DfIconTrash16,
  DfIconTriangleDown16,
  DfIconTriangleUp16,
  DfIconVideo16,
  DfIconVideoCamera16,
  DfIconAccomplishment12,
  DfIconAccomplishmentCircle12,
  DfIconArrowCircularLeft12,
  DfIconArrowDownRight12,
  DfIconArrowForward12,
  DfIconArrowUpRightOut12,
  DfIconBookmark12,
  DfIconCaretDown12,
  DfIconCaretUp12,
  DfIconCheckmark12,
  DfIconCheckmarkCircle12,
  DfIconChevronDoubleLeft12,
  DfIconChevronDoubleRight12,
  DfIconChevronDown12,
  DfIconChevronLeft12,
  DfIconChevronRight12,
  DfIconChevronUp12,
  DfIconClockFilled12,
  DfIconCross12,
  DfIconCrossCircle12,
  DfIconExclamationCircle12,
  DfIconEyeClosed12,
  DfIconEyeOpen12,
  DfIconEyeOpenSquare12,
  DfIconInfo12,
  DfIconLockClosed12,
  DfIconLockOpen12,
  DfIconMentor12,
  DfIconMinus12,
  DfIconOpportunity12,
  DfIconPage12,
  DfIconPin12,
  DfIconPlus12,
  DfIconTag12,
  DfIconThumbsDown12,
  DfIconThumbsUp12
];
