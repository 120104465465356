import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
/**
 * This servcie is using the Ngx-toastr library to create toast messages
 * https://github.com/scttcper/ngx-toastr
 *
 */
interface NotifierOptions {
  closeButton?: boolean;
  disableTimeOut?: boolean;
  timeOutLength?: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  private defaultOptions: NotifierOptions = {
    closeButton: true,
    disableTimeOut: true,
  };

  private defaultSuccessOptions: NotifierOptions = {
    ...this.defaultOptions,
    disableTimeOut: false,
  };
  constructor(private toastr: ToastrService) {}

  /**
   * Display an error toast message to the user.
   * @param message message to dispplay to the user
   * @param options specific toast options(https://github.com/scttcper/ngx-toastr) otherwise default
   * @param title title for toast message
   */
  public showError(
    message: string,
    options: NotifierOptions = this.defaultOptions,
    title?: string
  ) {
    this.toastr.error(message, title, options);
  }
  /**
   * Display an success toast message to the user.
   * @param message message to dispplay to the user
   * @param options specific toast options(https://github.com/scttcper/ngx-toastr) otherwise default
   * @param title title for toast message
   */
  public showSuccess(
    message: string,
    options: NotifierOptions = this.defaultSuccessOptions,
    title?: string
  ): void {
    // only generate a timeOut based on length if the timeOut length isn't already set
    if (!options.disableTimeOut && !options.timeOutLength) {
      options.timeOutLength = this.getTimeOutLength(message.length);
    }

    this.toastr.success(message, title, options);
  }
  /**
   * Display an warning toast message to the user.
   * @param message message to dispplay to the user
   * @param options specific toast options(https://github.com/scttcper/ngx-toastr) otherwise default
   * @param title title for toast message
   */
  public showWarning(
    message: string,
    options: NotifierOptions = this.defaultOptions,
    title?: string
  ): void {
    this.toastr.warning(message, title, options);
  }

  /**
   * Display an informational toast message to the user.
   * @param message message to display to the user
   * @param options specific toast options(https://github.com/scttcper/ngx-toastr) otherwise default
   * @param title title for toast message
   */
  public showInformational(
    message: string,
    options: {} = this.defaultOptions,
    title?: string
  ): void {
    this.toastr.info(message, title, options);
  }

  /**
   * Get the message timeout length in milliseconds based on the length of the message
   * @param nChar the length of the message being displayed
   */
  private getTimeOutLength(nChar: number) {
    // all values in miliseconds
    const minTime = 3000,
      maxTime = 7000,
      timePerCharacter = 50;
    let timeout = minTime + nChar * timePerCharacter;
    // timeout should not exceed our maximum timeout
    if (timeout > maxTime) {
      timeout = maxTime;
    }
    // return timeout
    return timeout;
  }
}
