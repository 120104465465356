<div #banner class="banner banner--{{ position }} l_flexbar">
  <div class="l_flex-grow guts-p-r-1" role="status" aria-live="polite">
    <ng-content></ng-content>
  </div>
  <div>
    <button
      df-button
      df-button-small
      [dfButtonType]="buttonType"
      (click)="dismiss()"
      data-dgat="banner-73f"
    >
      {{ 'Core_Dismiss' | translate }}
    </button>
  </div>
</div>
