import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppComponent } from '@app/app.component';

@Component({
  selector: 'dgx-root',
  template: `
    <dgx-bulk-icon-registry>
      <ng-content></ng-content>
    </dgx-bulk-icon-registry>
    <router-outlet></router-outlet>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EbbBootstrapComponent extends AppComponent {}