<dgx-tags-edit
  class="form-wrap guts-m-t-1-quart"
  [completing]="params.isCompleting"
  [customTagToolTip]="params.customTooltip"
  [customTitle]="label"
  [topTags]="params.topTags"
  [hideTopTags]="params.hideTopTags"
  [hideTooltip]="params.hideTooltip"
  [targetLevel]="params.targetLevel"
  [hint]="params.hint"
  [placeholder]="params.placeholder"
  [allowExistingOnly]="params.allowExistingOnly"
  [tags]="formControl.value | pascalCase"
  (tagsChange)="handleTagsChange($event)"
  (focusout)="handleFocusOut()"
></dgx-tags-edit>
