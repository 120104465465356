<div
  #popoverTrigger
  placement="bottom"
  container="body"
  class="{{ displayHorizontal ? 'l_flex' : 'guts-m-t-1-quart block' }}"
  tooltipClass="faux-popover"
  [openDelay]="popoverViewedDelay"
  [disableTooltip]="!hasMoreRatings"
  [ngbTooltip]="tooltipContent"
  (mouseenter)="togglePopover(true)"
  (mouseleave)="togglePopover(false)"
  (focus)="togglePopover(true)"
  (blur)="togglePopover(false)"
  tabindex="{{hasInternalRating || hasMoreRatings ? 0 : -1}}"
>
  <!-- Provider icon & rating type -->
  <dgx-tag-rating-provider-label
    class="ib-v-middle"
    *ngIf="hasInternalRating"
    [rating]="tag.rating"
  ></dgx-tag-rating-provider-label>

  <!-- +[n] more rating(s) -->
  <dgx-tag-rating-more-count
    [ngClass]="{ 'guts-m-l-half': displayHorizontal }"
    *ngIf="hasMoreRatings"
    [count]="moreRatingsCount"
  ></dgx-tag-rating-more-count>

  <!-- Popover list -->
  <ng-template #tooltipContent>
    <dgx-tag-rating-signal-popover-content
      [ratings]="completedRatings"
    ></dgx-tag-rating-signal-popover-content>
  </ng-template>
</div>
