import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';
import { DfPopoverModule } from '@lib/fresco';
import { OutcomesModule } from '@app/outcomes/outcomes.module';
import { InputsModule } from '@app/inputs/inputs.module';
import { GlobalAddButtonsComponent } from './components/global-add-buttons/global-add-buttons.component';
import { GlobalAddMenuComponent } from './components/global-add-menu/global-add-menu.component';
import { UserContentModule } from '@app/user-content/user-content.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DfPopoverModule,
    InputsModule,
    OutcomesModule,
    UserContentModule,
  ],
  exports: [GlobalAddMenuComponent, GlobalAddButtonsComponent],
  declarations: [GlobalAddMenuComponent, GlobalAddButtonsComponent],
  providers: [],
})
export class GlobalAddModule {}
