<article
  class="resource-card--{{ cardStyle }}"
  [ngClass]="{
    'resource-card--new-border': displayNewPathwayInputNotification,
  }"
  *ngIf="!isLoading"
>
  <!-- ^^^ article because https://stackoverflow.com/a/3281934/441894  -->
  <div class="guts-p-h-2 guts-p-t-2 guts-p-b-1">
    <div class="l_flex l_flex-middle guts-p-b-half">
      <span
        *ngIf="endorsedImageUrl"
        [ngbTooltip]="i18n.Core_EndorsedTooltip"
        class="resource-card__endorsed l_flex guts-m-r-1-quart"
      >
        <img [src]="endorsedImageUrl" [alt]="'Core_Endorsed' | translate" />
      </span>
      <dgx-learning-resource-metadata
        *ngIf="!resource?.liveSessions"
        [metaData]="resource.metaData"
        [searchTerm]="searchTerm"
        [assignmentInfo]="recommendationInfo"
        [isOptional]="resource.pathwayStepDetails?.isOptional"
        [config]="{
          percentComplete: resource.percentComplete,
          compact: false,
          isAcademy: resource.isAcademy,
        }"
        [isVerified]="resource.isVerified"
        [disableProviderLink]="disableProviderLink"
        [isCompleted]="resource.completionInfo?.isCompleted"
      ></dgx-learning-resource-metadata>
      <dgx-live-event-metadata
        *ngIf="resource?.liveSessions"
        [liveSessions]="resource?.liveSessions"
        [assignmentInfo]="recommendationInfo"
        [isRegistered]="resource?.isRegistered"
        [isOptional]="resource.pathwayStepDetails?.isOptional"
        class="guts-m-b-quart"
      ></dgx-live-event-metadata>
      <span
        *ngIf="displayNewPathwayInputNotification"
        class="resource-card__new-tag"
        >{{ i18n.Core_New }}</span
      >
    </div>
    <div class="l_flex m-l_flex-justify-center m-l_flex m-l_flex-wrap-reverse">
      <div class="l_flex-grow guts-m-r-2 m-no-guts">
        <h3 *ngIf="resource.displayTitle" class="h4 guts-p-b-half l_flex">
          <!-- Link below needs to have noopener and noreferrer when opening a new tab
               for security reasons.
               See https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c

               Per PD-61286: reverting back to to `noopener nofollow`
          -->
          <a
            #title
            *ngIf="url"
            class="resource-card__title"
            [ngClass]="{
              'resource-card__title--expanded': (expanded$ | async)?.title,
            }"
            [href]="
              shouldOpenContentInModal
                ? url
                : addNewQueryParam(url, 'newWindow=true')
            "
            (click)="onLinkClick($event)"
            [attr.aria-describedby]="
              shouldOpenNewWindow ? 'a11yNewWindowDescription' : null
            "
            [attr.role]="shouldOpenContentInModal ? 'button' : null"
            [attr.title]="resource.displayTitle"
            [attr.target]="shouldOpenNewWindow ? '_blank' : null"
            [attr.rel]="shouldOpenNewWindow ? 'noopener nofollow' : null"
            data-dgat="input-card-608"
            [innerHTML]="resource.displayTitle"
          >
          </a>
          <span
            #title
            *ngIf="!url"
            class="resource-card__title"
            [ngClass]="{
              'resource-card__title--expanded': (expanded$ | async)?.title,
            }"
            [innerHTML]="resource.displayTitle"
          ></span>
          <button
            *ngIf="
              (showEllipsesButton$ | async)?.title &&
              !(expanded$ | async)?.title
            "
            [attr.title]="i18n.A11y_ExpandForTitle"
            [attr.aria-label]="i18n.A11y_ExpandForTitle"
            (click)="expandCard(titleExpanded)"
            data-dgat="learning-resource-card-5dd"
          >
            ...
          </button>
        </h3>
        <div class="l_flex">
          <p
            #summary
            class="font-medium par par--light_a par--small resource-card__summary"
            [ngClass]="{
              'resource-card__summary--expanded': (expanded$ | async)?.summary,
            }"
            [attr.title]="resource.displaySummary"
            [innerHTML]="resource.displaySummary"
          ></p>
          <button
            *ngIf="
              (showEllipsesButton$ | async)?.summary &&
              !(expanded$ | async)?.summary
            "
            [attr.title]="i18n.A11y_ExpandForSummary"
            [attr.aria-label]="i18n.A11y_ExpandForSummary"
            class="l_flex-bottom"
            (click)="expandCard(summaryExpanded)"
            data-dgat="learning-resource-card-5dc"
          >
            ...
          </button>
        </div>
      </div>
      <!--TODO: fix "safe value must use [property]=" warning on videoUrl -->
      <div class="resource-card__img m-guts-m-b-2" [hidden]="!shouldShowImage">
        <dgx-image-layout
          (click)="onImageClick($event)"
          class="cursor-pointer"
          format="resource-card"
          [imageSrc]="resource.displayImageUrl"
          [resourceType]="resource.resourceType"
          [videoUrl]="resource.videoInfo?.contentUrl"
          [resourceId]="resource.resourceId"
          [providerImage]="resource.providerSummary?.image"
          [hideFallback]="true"
          (brokenImage)="onBrokenImage($event)"
        ></dgx-image-layout>
      </div>
    </div>
  </div>

  <footer class="guts-p-h-2 guts-p-b-2 guts-p-b-2">
    <fieldset>
      <legend class="a11y-hide" [innerHTML]="resource.displayTitle"></legend>
      <!--
        A11y Note: Using fieldset and legend here is a way to group these buttons
        (fieldset is semantic for role="group"). Screen readers read the <legend>
        text with every field (button in this case) inside the <fieldset>.
        Most screen readers read the <legend> first. VoiceOver reads the label
        of the form field first, then the <legend>, which sound backwards,
        but that's out of the developer's control, and regular VoiceOver
        users will be used to hearing things that way.
       -->
      <div class="l_flexbar">
        <div class="l_flex-grow l_flexbar l_flex-wrap guts-m-r-1">
          <!--View Academy button-->
          <dgx-view-academy-button
            *ngIf="resource.isAcademy"
            [url]="resource.url"
          ></dgx-view-academy-button>
          <!--Completion action button-->
          <dgx-input-completion
            *ngIf="!hideCompleteButton"
            class="action-options__item"
            [isCompleted]="isCompleted"
            [input]="resource"
            [pathway]="pathway"
            [class.guts-m-r-0]="showMoreDetails"
          ></dgx-input-completion>
          <dgx-action-button
            *ngIf="showMoreDetails"
            (click)="openDetails()"
            data-dgprop-item-clicked="ViewDetails"
            [autoToggleState]="false"
            [class.guts-m-l-1]="!isCompleted"
            dgatInput="learning-resource-card-656"
          >
            <div>{{ i18n.Core_ViewDetails }}</div>
          </dgx-action-button>

          <!-- Live Event is Live -->
          <dgx-action-button
            *ngIf="resource.isLive"
            (click)="openLiveSession()"
            [autoToggleState]="false"
            [isActive]="true"
            icon="video-camera"
            dgatInput="learning-resource-card-509"
          >
            <div>{{ 'Core_Join' | translate }}</div>
          </dgx-action-button>

          <!-- Pathway follow button-->
          <dgx-action-button
            class="action-options__item"
            *ngIf="resource.canFollow && resource.isPathway"
            [icon]="isEnrolled ? 'checkmark' : 'plus'"
            size="xs"
            (click)="onEnrollClick($event)"
            [isActive]="isEnrolled"
            [autoToggleState]="false"
            dgatInput="actionButton-follow"
          >
            {{ isEnrolled ? i18n.Core_Following : i18n.Core_Follow }}
          </dgx-action-button>

          <!-- Target (skill, role, directory, browse) follow button-->
          <dgx-action-button
            class="action-options__item"
            *ngIf="resource.canFollow && resource.isTarget"
            [icon]="isFollowing ? 'checkmark' : 'plus'"
            size="xs"
            (click)="onFollowClick()"
            [isActive]="isFollowing"
            [autoToggleState]="false"
            dgatInput="actionButton-follow"
          >
            {{ isFollowing ? i18n.Core_Following : i18n.Core_Follow }}
          </dgx-action-button>
          <div *ngIf="showViewedTag" class="action-options__item">
            <span class="font-medium">
              <df-icon
                class="color-ebony-a61 guts-m-r-quart"
                size="small"
                icon="eye-open"
              ></df-icon>
              <span class="icon-text par--light par--small visibility">{{
                i18n.Core_Viewed
              }}</span>
            </span>
          </div>
        </div>
        <div>
          <ng-content select=".additionalActions"></ng-content>
          <dgx-menu
            [menuConfig]="getMenuConfig()"
            placement="bottom-right"
            [placementAdjustLeftRem]="1"
          >
            <button
              type="button"
              [attr.aria-label]="'Core_MoreOptions' | translate"
              data-dgat="learning-resource-card-8ea"
            >
              <df-icon icon="dots" class="color-ebony-a61"></df-icon>
            </button>
          </dgx-menu>
        </div>
      </div>
    </fieldset>
  </footer>
</article>

<div *ngIf="isLoading">
  <dgx-skeleton type="input-card"></dgx-skeleton>
</div>
