import { InternalTagRatingTypes } from '@app/tags/tags';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { TagsApi } from '@app/tags/tag-api.model';
import { INTERNAL_RATINGS_ORDER } from '@app/shared/utils/tag-helpers';

@Component({
  selector: 'dgx-tag-rating-signal-popover-content',
  templateUrl: './tag-rating-signal-popover-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingSignalPopoverContentComponent {
  private _ratings: TagsApi.UserTagRating[] = [];
  private peerRatings: TagsApi.UserTagRating[] = [];
  public checkpointCount: number = 0;
  @Input() public popoverTrigger: ElementRef;
  @Input() public isOpen: boolean;
  @Input() public placement: PlacementArray;
  @Input() public appendToBody = false;
  @Input() public set ratings(ratings: TagsApi.UserTagRating[]) {
    this._ratings = ratings;

    this.peerRatings = ratings.filter(
      (rating) => rating.type === InternalTagRatingTypes.peer
    );
    const checkpointRatings = ratings.filter(
      (rating) => rating.type === InternalTagRatingTypes.checkpoint
    );
    this._ratings = [
      ...this.getInternalRatings(),
      ...this.externalRatings,
      ...this.getCheckpointRatings(checkpointRatings),
    ];
  }

  public get ratings(): TagsApi.UserTagRating[] {
    return this._ratings;
  }

  private get externalRatings(): TagsApi.UserTagRating[] {
    return this._ratings.filter(
      (rating) =>
        !rating.isInternal && rating.type !== InternalTagRatingTypes.checkpoint
    );
  }

  private getCheckpointRatings(checkpointRatings): TagsApi.UserTagRating[] {
    if (checkpointRatings && checkpointRatings.length > 0) {
      this.checkpointCount = checkpointRatings.length;
      return checkpointRatings.splice(0, 1);
    }
    return [];
  }

  private getInternalRatings(): TagsApi.UserTagRating[] {
    const internalRatingsSummary = this._ratings.filter(
      (rating) =>
        rating.type !== InternalTagRatingTypes.peer &&
        rating.type !== InternalTagRatingTypes.checkpoint &&
        rating.isInternal
    );

    if (this.peerRatings.length) {
      internalRatingsSummary.push(
        this._ratings.find(
          (rating) => rating.type === InternalTagRatingTypes.peer
        )
      );
    }

    // order ratings correctly
    const orderedInternalRatingsSummary = internalRatingsSummary.sort(
      (a, b) =>
        INTERNAL_RATINGS_ORDER.indexOf(a.type as InternalTagRatingTypes) -
        INTERNAL_RATINGS_ORDER.indexOf(b.type as InternalTagRatingTypes)
    );

    return orderedInternalRatingsSummary.reverse();
  }
}
