import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CourseBaseFacade } from '../course-base.facade.service';

// misc
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { PathwayStep } from '@dg/pathways-rsm';
import { readFirst } from '@dg/shared-rxjs';
import { CourseModel, CourseTypeId } from '../../course.model';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { AuthService } from '@dg/shared-services';
import { CourseMapper } from '../course-mapper.service';
import { CourseNotificationService } from '../course-notification.service';
import { CourseTrackerService } from '../course-tracker.service';
import { CourseService } from '../course.service';

@Injectable({
  providedIn: 'root',
})
export class CoursePathwaysPlansFacade extends CourseBaseFacade {
  constructor(
    protected inputsService: InputsService,
    protected inputImageUploadAdapterService: InputImageUploadAdapterService,
    protected courseService: CourseService,
    protected courseNotificationService: CourseNotificationService,
    protected mapperService: CourseMapper,
    private courseTrackerService: CourseTrackerService,
    authService: AuthService,
    datehandler: NgbDateParserFormatter
  ) {
    super(
      inputsService,
      inputImageUploadAdapterService,
      courseService,
      mapperService,
      courseNotificationService,
      authService,
      datehandler
    );
  }

  // *******************************************************
  // Getters
  // *******************************************************

  /**
   * Easy access to current snapshot of [read-only] CourseModel
   */
  public get snapshot(): CourseModel {
    return readFirst(this.viewModel$);
  }

  // *******************************************************
  // Public
  // *******************************************************

  /**
   * Overwrite the on Next form the base facade for pathways and plans
   * When the next button has been selected
   *
   * @param courseUrl
   * @param courseTypeId
   */
  public async onNext(
    courseUrl: string,
    courseTypeId?: CourseTypeId
  ): Promise<void> {
    this.viewModel = {
      ...this.viewModel,
      isInitialForm: false,
      courseUrl,
      courseTypeId,
    };
  }

  public async onSubmit(
    form: FormGroup,
    isEditingLocalContent = false,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    try {
      // TODO: Add in handling here later (bug doesn't even exist yet)
      // // WHEN adding an image for the first time, we need to UPDATE the image on the
      // // BE so that it doesn't have the parentResourceType=pathway/parentResourceId=
      // // pathId settings anymore, otherwise we won't be able to edit it later. This
      // // method doesn't exist yet, a bug will be made for it.
      // if (!!form.get('addToCatalog')?.value) {
      //   // Reupload image!
      // }

      // Newly-added pathway content and content that's been saved to the catalog or
      // which is being saved to a plan... all of that continues on to our default
      // submit.
      if (!isEditingLocalContent || !pathwayStep) {
        await super.onSubmit(form);
        this.performSuccessSideEffects();
        return;
      }
      // OTHERWISE...
      // update the VM with the form data
      const formData = form.value;
      this.updateViewWithFormData(formData);
      // and with the step data
      this.viewModel = this.mapperService.toStep(this.viewModel, pathwayStep);
      // then we're done! the modal close will take it from here.
    } catch {
      this.performFailureSideEffects(isEditingLocalContent, pathwayStep);
    }
  }

  /**
   * Override initializeViewModel
   * @param inputContext
   */
  public async initializeViewModel(inputContext: InputContext): Promise<void> {
    super.initializeViewModel(inputContext);

    // initialize new/computed Properties
    const addToCatalog = !!this.viewModel.inputContext.isCmsContent;
    this.viewModel = {
      ...this.viewModel,
      addToCatalog: addToCatalog,
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: !addToCatalog,
      },
    };
  }

  /**
   * Override initializeEdit if editing article locally/for the pathway/plan only
   * @param isEditingInternalContent
   * @param pathwayStep
   */
  public async initializeEdit(
    isEditingLocalContent = false,
    pathwayStep?: PathwayStep
  ): Promise<void> {
    // All other edit scenarios are handled by default facade method.
    if (!isEditingLocalContent || !pathwayStep) {
      return super.initializeEdit();
    }
    // OTHERWISE, grab owner
    const owner = await this.inputsService.getInputOwner(
      pathwayStep.reference.primaryContactResourceId,
      pathwayStep.reference.primaryContactResourceType,
      this.orgId
    );
    // Pass it and the PathwayStep to our mapper to get a VM from the
    // combined properties
    const updatedView = this.mapperService.fromStepToViewModel(pathwayStep, {
      ...this.viewModel,
      owner,
    });
    // THEN, update viewModel.
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isInitialForm: false,
      organizationId: this.orgId,
      isSessionDetailsToggledOn: !!updatedView.sessionDetails,
    };
  }

  // *******************************************************
  // Protected
  // *******************************************************

  /** Performs any side effects required following successful creation of an Input */
  protected performSuccessSideEffects() {
    if (!this.viewModel.inputContext.isEditing) {
      this.courseNotificationService.notifyCourseInputCreated(
        this.viewModel.name
      );

      return this.courseTrackerService.trackContentSkillInferred(
        this.viewModel
      );
    }

    const apiParameters = this.mapperService.toApiParameters(
      this.viewModel as CourseModel
    ) as CourseModel;

    this.courseNotificationService.notifyCourseInputUpdated();
    this.courseTrackerService.trackContentCatalogAdd(apiParameters);
  }

  /** Performs any side effects required following failed creation/update of a Course */
  protected performFailureSideEffects(
    isEditingLocalContent = false,
    pathwayStep?: PathwayStep
  ) {
    if (isEditingLocalContent && pathwayStep) {
      this.submissionStatus$.next(SubmissionStatus.Failed);
      throw new Error('Error in CoursePathwaysPlansFacade');
    }
    this.courseNotificationService.notifyCourseInputCreateFailed();
  }
}
