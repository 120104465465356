import { InjectionToken } from '@angular/core';

import type { Highcharts } from '../vendor/highcharts';

export type HighchartsLazy = Promise<typeof Highcharts>;
export const HIGHCHARTS_LAZY = new InjectionToken<HighchartsLazy>(
  'HIGHCHARTS_LAZY'
);

let resolved: HighchartsLazy = null;
export function highchartsLazySingleton(): HighchartsLazy {
  if (!resolved) {
    resolved = import('../vendor/highcharts').then((m) => m.Highcharts);
  }

  return resolved;
}
