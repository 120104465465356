export const constants = {
  CURRENT_ROOM_KEY: 'currentRoom',
  ENDPOINTS_KEY: 'endpoints',
  PARENT_URL: '/analytics',
  ROOMS_KEY: 'rooms',
  SSO_ENDPOINT_URL: '/visiersaml/InitiateIdpSso',
  VANITY_URL: 'https://degreed.visier.com/hr/auth/embedded?tenant',
};

export enum TagTypes {
  PRODUCT_SWITCHER = 1,
}

export enum TrackEventActionTypes {
  AUTHENTICATION_ERROR = 'Visier Authentication Error',
  PAGE_VISITED = 'Analytics Page Visited',
  SESSION_CONNECTED = 'Visier Session Connected',
}

export enum ViserMessageTypes {
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  SESSION_CONNECTED = 'SESSION_CONNECTED',
  VISIER_APP_LOADED = 'VISIER_APP_LOADED',
  VISIER_SESSION_ALIVE = 'VISIER_SESSION_ALIVE',
  VISIER_SESSION_EXPIRED = 'VISIER_SESSION_EXPIRED',
}
