<dgx-combobox
  class="full-width"
  [id]="params.id"
  [hasNoResultsText]="params.hasNoResultsText"
  [isMultiSelect]="params.isMultiSelect"
  [isFixedWidth]="params.isMultiSelect"
  [labelKey]="params.optionLabelKey"
  [trackBy]="params.optionTrackByKey"
  [options]="comboboxOptions"
  [placeholder]="params.placeholder || ''"
  [selectedOption]="selectedOption"
  [selectedOptions]="selectedOptions"
  (selection)="handleSelection([$event])"
  (selections)="handleSelection($event)"
  [ariaLabel]="to.label"
  [dgatInput]="to.dgat"
  (blur)="handleBlur()"
>
</dgx-combobox>
