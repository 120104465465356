import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { KPI } from '@app/insights/insights-api.model';
import { LocalizationService } from '@app/shared/localization.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ReportingService } from './../../services/reporting.service';

@Component({
  selector: 'dgx-kpi-insights',
  templateUrl: './kpi-insights.component.html',
})
export class KpiInsightsComponent implements OnInit, OnChanges {
  @Input() public orgId: number;
  @Input() public filterOrgId: number;
  @Input() public pathwayId: number;
  @Input() public targetId: number;
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public groupId: number;

  public type: 'Pathway' | 'Target';
  public placeholders: number[];
  public itemClass: string;
  public loadingKpis = true;
  public kpis: KPI[];
  public userLocale: string;

  constructor(
    private datePipe: DatePipe,
    private localizationService: LocalizationService,
    private translate: TranslateService,
    private ReportingService: ReportingService
  ) {}

  public ngOnInit(): void {
    this.userLocale = this.localizationService.userLocale;
    if (this.pathwayId !== undefined) {
      this.type = 'Pathway';
      this.placeholders = [0, 0, 0];
      this.itemClass = 'grid__col-sm-4';
    } else if (this.targetId !== undefined) {
      this.type = 'Target';
      this.placeholders = [0, 0];
      this.itemClass = 'grid__col-sm-6';
    } else {
      throw new Error('Missing bindings!');
    }
    this.loadKpis();
  }
  public ngOnChanges(onChangesObj: SimpleChanges): void {
    const { groupId, filterOrgId } = onChangesObj;
    const groupFirstChange = groupId && groupId.isFirstChange();
    const orgFirstChange = filterOrgId && filterOrgId.isFirstChange();
    const isFirstChange = groupFirstChange || orgFirstChange;

    if ((groupId || filterOrgId) && !isFirstChange) {
      this.loadKpis();
    }
  }

  public loadKpis() {
    this.loadingKpis = true;
    this.fetchKpis()
      .pipe(take(1))
      .subscribe((result) => {
        this.kpis = result.map((kpi, i) => ({
          name: kpi.name,
          location:
            this.type == 'Pathway' ? 'Pathway Insights' : 'Plan Insights',
          metric: isNaN(kpi.metric)
            ? null
            : kpi.metric.toLocaleString(this.userLocale),
          order: i,
          uid: i, // for track by,
          numOfDays: this.ReportingService.getNumberOfDays(
            new Date(kpi.startDate),
            new Date(kpi.endDate)
          ),
          ...this.getKPILabels({
            name: kpi.name,
            count: kpi.metric,
            startDate: this.datePipe.transform(kpi.startDate, 'mediumDate'),
            endDate: this.datePipe.transform(kpi.endDate, 'mediumDate'),
          }),
        }));
        this.loadingKpis = false;
      });
  }

  private fetchKpis() {
    switch (this.type) {
      case 'Pathway':
        return this.ReportingService.GetPathwayInsightKPIs(
          this.pathwayId,
          this.groupId,
          this.filterOrgId
        );
      case 'Target':
        return this.ReportingService.GetTargetsInsightKPIs(
          this.orgId,
          this.startDate,
          this.endDate,
          this.targetId,
          this.groupId
        );
    }
  }

  private getKPILabels({
    name,
    ...args
  }: {
    name: string;
    count: string | number;
    startDate: string;
    endDate: string;
  }) {
    switch (name) {
      default:
        return {
          description: this.translate.instant(
            'KPI_' + this.type + name + 'Name'
          ),
          tooltip: this.translate.instant(
            'KPI_' + this.type + name + 'Tooltip',
            args
          ),
        };
    }
  }
}
