import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingButtonBaseComponent } from '@app/tags/components/tag-rating-button/tag-rating-button-base.component';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { EvaluationService } from '@app/tags/services/evaluation.service';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-evaluation-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaluationRatingButtonComponent extends TagRatingButtonBaseComponent {
  /** May a user start/complete a Skill Review for this tag? Applies to owner users only. */
  @Input() public isEvaluable?: boolean;

  // private isLoading = true;
  public readonly type = InternalTagRatingTypes.evaluation;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    private evaluationService: EvaluationService
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get ratingDescriptionLabel(): string {
    return this.translateService.instant('dgTagRating_MeasureYourSkill');
  }

  public get endorsedByLabel(): string {
    return this.hasSingleEndorser
      ? this.translateService.instant('dgTagRating_EndorsedByFormat', {
          name: this.endorser?.name,
        })
      : undefined;
  }

  public get inProgressRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ContinueProcess');
  }

  public get completedRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ViewLevelBreakdown');
  }

  public get pendingRatingNotificationLabel(): string {
    return this.translateService.instant('dgTagRating_RatingInProgress');
  }

  public get menuConfig(): MenuViewModel[] {
    // we need to overwrite the isHidden functions on the menu items as the originals use tag.ratings, which doesn't seem
    // to include incomplete tag evaluations, so the start option was always shown, even when there were incomplete
    // evaluation ratings, see PD-60199
    const cancel = this.tagActionOptionsService.getCancelSkillReviewMenuOption(
      this.tag
    );
    cancel.isHidden = () => !this.hasIncompleteRatings;
    const start =
      this.tagActionOptionsService.getForceStartSkillReviewMenuOption(
        this.tag,
        this.isEvaluable
      );
    start.isHidden = () => this.hasIncompleteRatings;
    return [cancel, start];
  }

  public get hasSingleEndorser(): boolean {
    return !!this.endorser;
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    if (this.ownerIsViewing) {
      if (!this.hasRatings) {
        this.startNewEvaluation(event);
      } else if (this.hasIncompleteRatings) {
        this.continueEvaluation(event);
      } else {
        this.reviewEvaluationResults();
      }
    }
  }

  private startNewEvaluation(event: Event): void {
    this.evaluationService.startEvaluation(
      this.tag,
      event.target as HTMLElement
    );
  }

  private reviewEvaluationResults(): void {
    this.evaluationService.reviewEvaluationResults(
      this.tag.name,
      this.tag.tagId
    );
  }

  private continueEvaluation(event: Event): void {
    this.tagRatingTrackerService.trackRatingUpdateStepCompleted(
      event.target as HTMLElement,
      this.tag,
      InternalTagRatingTypes.evaluation,
      'skill-modal-eval-button',
      'Continue'
    );
    this.evaluationService.continueEvaluation(this.tag.name, this.tag.tagId);
  }
}
