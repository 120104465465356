import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError, Subject } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';

import {
  OrgMatchedTag,
  Role,
  Skill,
  SkillsWithAssociation,
} from '@app/orgs/taxonomy-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { FilterService } from '@app/shared/services/filter.service';
import { TranslateService } from '@ngx-translate/core';
import { TagIdentifier } from '@app/orgs/taxonomy-api.model';

// 'upgraded' services
import { ModalSourceTarget } from '@app/shared/ajs/core.model';
import { NotifierService } from '@app/shared/services/notifier.service';
import { camelCaseKeys, getDeepCopy } from '@app/shared/utils/property';

// misc
import { Facet } from '@app/shared/models/core-api.model';
import { findIndex as _findIndex } from 'lodash-es';
import { ModalService } from '@app/shared/services/modal.service';
import { OrgSkillsBulkUploadComponent } from '../components/org-skills-bulk-upload/org-skills-bulk-upload.component';
import { DeleteModalInputs } from '@app/shared/components/modal/delete-confirmation-modal/delete-modal.component';

export interface OrgSkillsParams {
  term?: string;
  count?: number;
  filters?: any[];
  orderBy?: string;
  organizationId?: number;
  organizationName?: string;
  skip?: number;
  sortDescending?: boolean;
}
export interface ListWithCountParams {
  displayCountParam: string;
  objParam: string;
  objNameParam: string;
  totalParam?: string;
}

export interface AddEditModalParams {
  isNew: boolean;
  orgId: number;
  skill?: Skill;
  sourceTarget?: ModalSourceTarget;
}
export interface DeleteModalParams {
  title: string;
  item: any;
  sourceEventTarget: ModalSourceTarget;
  type?: 'role' | 'skill';
}
export interface DeleteItemParams {
  id: number;
  name: string;
  type: string;
  url: string;
}
export interface DefaultSkillsParams {
  term?: string;
  skip?: number;
  count?: number;
  orderBy?: number;
  sortDescending?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrgSkillsService {
  // public properties
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public isNotFound$: Subject<boolean> = new Subject<boolean>();
  public i18n = this.translate.instant([
    'OrgSkills_UpdateSkills',
    'OrgSkills_UploadFile',
    'OrgSkills_UploadSkillsDescription',
    'SkillsSvc_AddSkillSuccess',
    'SkillsSvc_DeleteRoleError',
    'SkillsSvc_DeleteSkillError',
    'SkillsSvc_EditSkillSuccess',
    'SkillsSvc_OneMoreItem',
    'SkillsSvc_RetrieveError',
    'SkillsSvc_RolesBulkUploadNotice',
    'TargetCtrl_ConfirmDeleteButton',
  ]);
  // private properties
  private readonly baseUrl = '/taxonomy';
  private readonly deletePhrase = 'DELETE';
  private readonly maxDescLength = 2000;
  private readonly deleteInstructions = this.translate.instant(
    'TargetCtrl_DeleteInstructions',
    { delete: this.deletePhrase }
  );
  // Number of plans/skills to show until overflow (+ # others)
  private readonly planDisplayCount: number = 5;
  private readonly skillDisplayCount: number = 5;

  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private notifierService: NotifierService,
    private filterService: FilterService,
    private modalService: ModalService
  ) {}

  /**
   * Get the url for the skills engine report . (Download button)
   */
  public getTaxonomySkillsReportURL(): string {
    return '/api/reporting/generatetaxonomyskillsreport';
  }

  /**
   * Get organization skills for taxonomy table.
   *
   * @param term - The search term, if any.
   * @param count - The number of results to return.
   * @param filters - Filters to filter search results on
   * @param orderby - The column to sort the results by, if any
   * @param organizationId - The org to search within, if set
   * @param organizationName - The org name to display when formatted, if set
   * @param skip - The number of results to skip
   * @param sortDescending - Whether to sort results in ascending (default) or descending order.
   */
  public getOrgSkills(
    {
      term = '',
      count = 10,
      filters = [],
      orderBy = 'name',
      organizationId,
      organizationName = '',
      skip = 0,
      sortDescending = false,
    }: OrgSkillsParams = {},
    defaultFacets?: any[]
  ): Observable<any> {
    const facets =
      // If neither defaultFacets nor filters have any content,
      // set to an empty array.
      !defaultFacets?.length && !filters.length
        ? []
        : // If defaultFacets has a length, set to defaultFacts
          defaultFacets?.length
          ? defaultFacets
          : // Otherwise, run filtersToFacets on filters
            this.filterService.filtersToFacets(filters);
    return this.http
      .get(`${this.baseUrl}/orgskills`, {
        params: {
          term,
          count,
          orderBy,
          organizationId,
          skip,
          sortDescending,
          facets: JSON.stringify(facets),
        },
      })
      .pipe(
        map((response: any) => {
          return this.formatSkills(
            response,
            {
              displayCountParam: 'planDisplayCount',
              objParam: 'plans',
              objNameParam: 'name',
              totalParam: 'totalPlanCount',
            },
            organizationName,
            organizationId
          );
        }),
        catchError((error) =>
          throwError(new DgError(this.i18n.SkillsSvc_RetrieveError, error))
        )
      );
  }

  /**
   * Display the skill delete modal prompt.
   * @param sourceEventTarget - Where to return focus when the modal closes. Used by AJS modal Svc.
   * @param item - Skill to be deleted.
   * @param title - Title of the modal with the skill name.
   */
  public showDeleteSkillModal({
    title,
    item,
    sourceEventTarget,
  }: DeleteModalParams): Observable<any> {
    return this.showDeleteModal({
      title,
      item,
      sourceEventTarget,
      type: 'skill',
    });
  }

  /**
   * Remove this methis when the org-skills component is removed
   * and remove OrgSkillsBulkUploadComponent
   * Display upload skills modal
   *
   * @param organizationId - The org to upload skills to.
   */
  public showUploadSkillsModal() {
    const inputs = {
      resolve: {
        heading: this.i18n.OrgSkills_UpdateSkills,
        description: this.i18n.OrgSkills_UploadSkillsDescription,
        confirmButtonText: this.i18n.OrgSkills_UploadFile,
        successNotice: this.i18n.SkillsSvc_RolesBulkUploadNotice,
        templateType: 'BulkTaxonomySkills',
      },
    };

    return this.modalService.show(OrgSkillsBulkUploadComponent, {
      inputs,
    });
  }

  /**
   * Check if a tag name exists for validation of new skill
   * @param term {string} tag name to search
   * @param tagType {string} type of tag to search, defaults to 'Skill'
   */
  public hasTag(term: string, tagType: string = 'Skill'): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.baseUrl}/hastag`, {
        params: {
          term,
          tagType,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(new DgError(this.i18n.SkillsSvc_RetrieveError, error))
        )
      );
  }

  public getAssociatedSkills(tagMetaId: number): Observable<TagIdentifier[]> {
    return this.http
      .get<TagIdentifier[]>(`${this.baseUrl}/getassociatedskills`, {
        params: {
          tagMetaId,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(new DgError(this.i18n.SkillsSvc_RetrieveError, error))
        )
      );
  }

  public getDefaultSkills(
    options: DefaultSkillsParams
  ): Observable<TagIdentifier[]> {
    const { term } = options;

    return (
      this.http
        .get<TagIdentifier[]>(`${this.baseUrl}/defaultskills`, {
          params: {
            term,
          },
        })

        // TODO: This endpoint provides ElasticSearch results which does not include
        //       a Title, only Name.
        //
        //       Map Name to Title for now since that's the soon to be translated
        //       name/title of the tag/skill.
        //
        //       Use Name for Title only if Title is undefined.
        .pipe(map((results) => results.map((r) => ({ title: r.name, ...r }))))
    );
  }

  /**
   * Get suggestions for matching skills to a degreed skill
   * @param term {string} search term
   * @param organizationId {number} id of the user's organization
   */
  public suggestTag(
    term: string,
    organizationId: number
  ): Observable<OrgMatchedTag[]> {
    return this.http
      .get<OrgMatchedTag[]>(`${this.baseUrl}/OrgMatchableSkills`, {
        params: {
          term,
          organizationId,
        },
      })
      .pipe(
        catchError((error) =>
          throwError(new DgError(this.i18n.SkillsSvc_RetrieveError, error))
        )
      );
  }
  /**
   * Get suggestions for new skills from the skill registry I/o
   * @param term {string} search term
   */
  public suggestFromRegistryIo(term: string): Observable<TagIdentifier[]> {
    return this.http
      .get<TagIdentifier[]>(`${this.baseUrl}/findorgsearchableskills`, {
        params: {
          term,
        },
      })
      .pipe(
        map((results) => results.map((r) => ({ title: r.name, ...r }))),
        catchError((error) =>
          throwError(new DgError(this.i18n.SkillsSvc_RetrieveError, error))
        )
      );
  }
  /**
   * Get suggestions for matching skills to a degreed skill
   * @param term {string} search term
   */
  public suggestDefaultTag(term: string): Observable<TagIdentifier[]> {
    return this.http
      .get<TagIdentifier[]>(`${this.baseUrl}/defaultskills`, {
        params: {
          term,
        },
      })
      .pipe(
        map((results) => results.map((r) => ({ title: r.name, ...r }))),
        catchError((error) =>
          throwError(new DgError(this.i18n.SkillsSvc_RetrieveError, error))
        )
      );
  }

  /**
   * Add the skill on save in add/edit skill modal.
   *
   * @param skill:SkillsWithAssociation skill to be added
   */
  public addSkill(
    skill: Partial<SkillsWithAssociation>
  ): Observable<SkillsWithAssociation> {
    if (!skill) {
      return of();
    }
    return this.http
      .post<SkillsWithAssociation>(`${this.baseUrl}/addskill`, {
        ...skill,
      })
      .pipe(
        tap<SkillsWithAssociation>((data) => {
          // show success message to end user
          this.notifierService.showSuccess(this.i18n.SkillsSvc_AddSkillSuccess);
          // return skill as SkillsWithAssociation;
        }),
        // send back the skill that was sent in
        mapTo(skill as SkillsWithAssociation)
      );
  }

  /**
   * Update the skill on save in add/edit skill modal.
   *
   * @param skill:SkillsWithAssociation skill to be updated
   */
  public editSkill(
    skill: Partial<SkillsWithAssociation>
  ): Observable<SkillsWithAssociation> {
    if (!skill) {
      return of();
    }

    return this.http
      .put<SkillsWithAssociation>(`${this.baseUrl}/updateskill`, {
        ...skill,
      })
      .pipe(
        tap<SkillsWithAssociation>((data) => {
          // show success message to end user
          this.notifierService.showSuccess(
            this.i18n.SkillsSvc_EditSkillSuccess
          );
          // return skill as SkillsWithAssociation;
        }),
        // send back the skill that was sent in
        // need to update casing ajs modal is still using pascal casing
        mapTo(camelCaseKeys(skill) as SkillsWithAssociation)
      );
  }

  /**
   *
   * @param title -  Title of the modal with the item name.
   * @param item - Item to be deleted
   * @param sourceEventTarget - Where to return focus when the modal closes. Used by AJS modal Svc.
   * @param type - Type of item: role | skill
   */
  private showDeleteModal({
    title,
    item,
    sourceEventTarget,
    type,
  }: DeleteModalParams): Observable<any> {
    const inputs: DeleteModalInputs = {
      title,
      description: null,
      deleteInstructions: this.deleteInstructions,
      confirmButtonText: this.i18n.TargetCtrl_ConfirmDeleteButton,
      deletePhrase: this.deletePhrase,
    };

    return this.modalService.showDeleteConfirmation(inputs).pipe(
      switchMap(() =>
        iif(
          () => type === 'role',
          this.deleteRole(item),
          this.deleteSkill(item)
        )
      ),
      catchError((error: Error) => {
        // canceling or closing the modal will result in an undefined
        // item
        if (!error) {
          return of(undefined);
        }
        const typeCased = type.charAt(0).toUpperCase() + type.slice(1);
        return (
          error &&
          throwError(
            new DgError(
              this.translate.instant(`SkillsSvc_Delete${typeCased}Error`),
              error
            )
          )
        );
      })
    );
  }

  /**
   *
   * @param url - Url to delete from.
   * @param id - Id of the item to delete.
   * @param name - Name of the item to delete.
   * @param type - Type of item Skill || Role to delete.
   */
  private deleteItem({
    url,
    id,
    name,
    type,
  }: DeleteItemParams): Observable<any> {
    const errorMessage =
      type === 'Skill'
        ? this.i18n.SkillsSvc_DeleteSkillError
        : this.i18n.SkillsSvc_DeleteRoleError;

    return this.http
      .delete(url, {
        params: { id: id },
      })
      .pipe(
        tap(() => {
          // show success message to end user
          this.notifierService.showSuccess(
            this.translate.instant(`SkillsSvc_Delete${type}Success`, {
              name,
            })
          );
        }),
        catchError(
          (error: Error) =>
            error && throwError(new DgError(errorMessage, error))
        )
      );
  }

  private deleteRole(role: Role): Observable<any> {
    return this.deleteItem({
      url: `${this.baseUrl}/orgroles`,
      id: role.id,
      name: role.name,
      type: 'Role',
    });
  }

  private deleteSkill(skill: Skill): Observable<any> {
    return this.deleteItem({
      url: `${this.baseUrl}/orgskills`,
      id: skill.tagMetaId,
      name: skill.name,
      type: 'Skill',
    });
  }

  private formatSkills(
    response: any,
    responseParams: ListWithCountParams,
    organizationName: string,
    organizationId: number
  ): any {
    // manipulate Items (if orgId = 1, we're in Degreed itself)
    // and we want the Source field to read "Internal" when it is.
    if (organizationId === 1) {
      response.items = response.items.map((item: Skill) => {
        // push the altered item into our new array
        return item as Skill;
      });
      response.filters = this.filterService.facetsToFilters(
        this.manipulateOrgSkillsFacetsName(
          response.facets,
          organizationId,
          organizationName
        ),
        [{ showEmptyOptions: true, ignoreCount: true }]
      );

      // return full $http response for the use of our components

      return response;
    }
    // otherwise, we need to replace "Internal" with the org name

    response.items = response.items.map((item: Skill) => {
      // for skills, manipulate source field
      if (organizationName && item.source === 'Internal') {
        item.source = organizationName;
      }
      // push the altered item into our new array
      return item as Skill;
    });
    // return full $http response for the use of our components
    response.filters = this.filterService.facetsToFilters(
      this.manipulateOrgSkillsFacetsName(
        response.facets,
        organizationId,
        organizationName
      ),
      [{ showEmptyOptions: true, ignoreCount: true }]
    );

    return response;
  }

  private manipulateOrgSkillsFacetsName(
    facets: Facet[],
    organizationId: number,
    organizationName: string
  ): Facet[] {
    // Avoid mutating the array that was passed in
    const updatedFacets = getDeepCopy(facets);
    const sourceFacetIndex = _findIndex(updatedFacets, ['id', 'Source']);

    // manipulate Items (if orgId = 1, we're in Degreed itself)
    // and we want the Source field to read "Internal" when it is otherwise the org name.
    if (organizationId !== 1 && organizationName) {
      updatedFacets[sourceFacetIndex].values = updatedFacets[
        sourceFacetIndex
      ].values.map((value) => {
        if (value.id === 'Internal') {
          return {
            ...value,
            name: organizationName,
          };
        }
        return value;
      });
    }
    return updatedFacets;
  }
}
