import { InputTrackingService } from '@app/user-content/services/input-tracking.service';
import { GlobalAddInputAction } from '@app/global-add/global-add.model';
import { ContextService } from '@app/shared/services/context.service';
import { GlobalAddService } from '@app/global-add/services/global-add.service';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnInit,
  Inject,
} from '@angular/core';
import { InputsService } from '@app/inputs/services/inputs.service';
import { PathwayAddContentService } from '@app/pathways/services/pathway-add-content.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { AuthService } from '@app/shared/services/auth.service';
import { InputType } from '@app/shared/models/core-api.model';
import {
  InputCreationFeedback,
  InputCreationResult,
  InputIdentifier,
} from '@app/inputs/inputs-api.model';
import { AddModalResults } from '@app/pathways/pathway-api.model';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputModalDispatcherService } from '@app/user-content/user-input/services/input-modal-dispatcher.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PathwayInputModalService } from '@app/pathways/services/inputs/pathway-input-modal.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { InputAddType, InputShowFormParams } from '@app/inputs/inputs.model';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { AddToPathwayLearningResourceViewModel } from '@app/pathways/rsm/pathway-api.model';

@Component({
  selector: 'dgx-pathway-add-by-type',
  templateUrl: './pathway-add-by-type.component.html',
})
export class PathwayAddByTypeComponent implements OnChanges, OnInit {
  public static readonly modalName = 'manual';
  public inputTypes: Partial<GlobalAddInputAction>[];
  public addItemsButton: string;
  public savedItems: LearningResourceViewModel[] = [];
  public isLoading: boolean;
  public loadingContentItems = false;
  public canManageContent: boolean;
  public readonly i18n = this.translate.instant([
    'Pathways_ExistingContentTypeError',
    'Core_Loading',
  ]);

  public showOrgManagedFlag =
    this.ldFlagsService.showPathwayVisibilityEnhancements;

  @Input() public pathId: number;
  @Input() public pathwayOrgId: number;
  @Input() public savedSearch: LearningResourceViewModel[];
  @Input() public savedManual: LearningResourceViewModel[];
  @Input() public isSubmitting: boolean;

  @Output() public updateItemList = new EventEmitter<AddModalResults>();
  @Output() public addToBin = new EventEmitter<AddModalResults>();
  @Output() public addItems = new EventEmitter<AddModalResults>();
  @Output() public dismiss = new EventEmitter<Event>();

  constructor(
    private inputsService: InputsService,
    private pathwayAddContentService: PathwayAddContentService,
    private authService: AuthService,
    private tracker: TrackerService,
    private inputTrackingService: InputTrackingService,
    private ldFlagsService: LDFlagsService,
    private GlobalAddService: GlobalAddService,
    private ContextService: ContextService,
    private genericInputModalService: InputModalDispatcherService,
    private notifier: NotifierService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private pathwayInputModalService: PathwayInputModalService
  ) {}

  public get orgId() {
    return this.showOrgManagedFlag
      ? this.pathwayOrgId
      : this.authService.authUser.defaultOrgId;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedManual && this.savedManual) {
      this.savedItems = this.savedManual;
    }
  }

  public ngOnInit(): void {
    const authUser = this.authService.authUser;
    const hasMainAppOrg = authUser?.defaultOrgInfo !== null;
    const isChannelContext = this.ContextService.urlHasContext(
      location.href,
      'channel'
    );
    const hasOrg = isChannelContext || hasMainAppOrg;
    this.addItemsButton = this.pathwayAddContentService.updateButtonText(
      this.savedManual
    );
    this.inputTypes = this.GlobalAddService.getPathwayAddInputs(hasOrg);
    if (!this.inputsService.showCustomPostType()) {
      // Only those with access via feature flag can create posts.
      this.inputTypes = this.inputTypes.filter((input) => {
        return input.type !== 'Post';
      });
    }
    if (!this.inputsService.showCoach()) {
      this.inputTypes = this.inputTypes.filter(
        (input) => input.type !== 'Coach'
      );
    }
  }

  // Shows add manually form to add content item
  public showAddInputForm(e: MouseEvent, inputType: InputAddType): void {
    let create: Observable<Partial<InputCreationResult>>;
    // TODO: Remove once Post and Task have been converted to genericInputModalService
    if (inputType === 'Post' || inputType === 'Task' || inputType == 'Coach') {
      create = this.pathwayInputModalService
        .create({
          inputType,
          organizationId: this.orgId,
          canManageContent: this.canManageContent,
          pathwayId: this.pathId,
          sourceTarget: e.target as HTMLElement,
          isPathwayBinAdd: true,
        })
        .pipe(
          switchMap((result: InputShowFormParams) => {
            this.loadingContentItems = true;
            return this.pathwayInputModalService.submitFn(result);
          }),
          map((data: InputCreationFeedback) => {
            return data.result;
          })
        );
    } else {
      create = this.genericInputModalService.create({
        // linter fix, InputAddType doesn't work because modal doesn't like "createPathway"
        inputType: inputType as InputType,
        organizationId: this.orgId,
        pathwayId: this.pathId,
        sourceTarget: e.target as HTMLElement,
      });
    }

    create.subscribe({
      next: (item: any) => {
        // TODO: Once deprecated pathwayInputModalService has been removed, this safety check can also be removed; genericInputModalService
        // no longer fires activeModal.close() when dismissed, so it will never hit `next` with an undefined payload.
        if (item) {
          // for some reason this sometimes returns a number instead of string. Add the type we originally passed in to the modal to fix.
          item.inputType = inputType;
          this.handleAddContentItem(item);
        }
      },
      error: (e) => {
        if (e) {
          console.error(e);
        }
      },
      complete: () => {
        (e.target as HTMLButtonElement).focus();
      },
    });
  }

  public addToSavedList(item: AddToPathwayLearningResourceViewModel): void {
    let savedItemList: AddToPathwayLearningResourceViewModel[];
    if (this.savedItems) {
      savedItemList = this.savedItems;
      savedItemList.push(item);
    } else {
      savedItemList = [item];
    }
    this.addItemsButton =
      this.pathwayAddContentService.updateButtonText(savedItemList);
    this.updateItemList.emit({
      savedItems: savedItemList,
      whichModal: PathwayAddByTypeComponent.modalName,
    });
  }

  public removeFromSavedList = (item: LearningResourceViewModel): void => {
    let savedItemList: LearningResourceViewModel[];
    savedItemList = this.savedItems;
    if (!item) {
      // there's a problem. clear everything.
      savedItemList = [];
    } else {
      savedItemList = savedItemList.filter((i) => {
        return i.resourceId !== item.resourceId;
      });
    }
    this.addItemsButton =
      this.pathwayAddContentService.updateButtonText(savedItemList);
    this.updateItemList.emit({
      savedItems: savedItemList,
      whichModal: PathwayAddByTypeComponent.modalName,
    });
  };

  public onHoldForLater(): void {
    this.addToBin.emit({
      savedItems: this.savedItems,
      whichModal: PathwayAddByTypeComponent.modalName,
    });
  }

  public onAddItems(): void {
    this.addItems.emit({
      savedItems: this.savedItems,
      selectedSearch: this.savedSearch,
      whichModal: PathwayAddByTypeComponent.modalName,
    });
  }

  public handleAddContentItem = (item): void => {
    const inputIdentifier: InputIdentifier = {
      inputType: item.inputType,
      inputId: item.inputId,
      parentResourceTypeName: 'Pathway',
      parentResourceId: this.pathId,
    };
    this.inputsService.getInputAndStatistics(inputIdentifier, true).subscribe({
      next: ({ input }: any) => {
        if (item.alreadyExists && !input) {
          // Prevents user from adding the same content under a different type: https://degreedjira.atlassian.net/browse/PD-57960
          this.notifier.showError(this.i18n.Pathways_ExistingContentTypeError);
          this.activeModal.close();
        } else {
          // even if the user didn't change anything, the scraped data might differ from the initial saved content item, confirm that here.
          // Also override the fields to show the modified dat correctly in the modal until the real changes are fetched from the API
          // lastly, save values of the fields that were modifies to override in the API call
          if (
            item.title !== input.title ||
            item.name !== input.name ||
            item.description !== input.description ||
            item.summary !== input.summary
          ) {
            input = {
              ...input,
              overrideScrapedData: true,
              title: item.title || item.name,
              description: item.description || item.summary,
              summary: item.description || item.summary,
            };
          }
          this.addToSavedList(input);
          this.loadingContentItems = false;

          // tracking
          let eventDataToTrack;
          if (input.organizationId && !item.hideFromCatalog) {
            eventDataToTrack = {
              action: 'Content Added to Catalog',
              properties: {
                ContentId: input.inputId,
                ContentType: input.inputType,
                DateModified: input.dateModified,
                SuggestedDuration: input.durationUnitType,
                DurationUnits: input.durationUnits,
                ExternalId: input.externalId,
                HostedType: input.hostedType,
                ImageUrl: input.imageUrl,
                ProviderName: item.providerName,
                Title: input.title,
              },
            };
            if (
              input.inputType === 'Article' ||
              input.inputType === 'Assessment'
            ) {
              eventDataToTrack.properties = {
                ...eventDataToTrack.properties,
                ...{
                  UpdatedDuration:
                    this.inputTrackingService.getUpdatedDuration(input),
                },
              };
            }
            this.tracker.trackEventData(eventDataToTrack);
          }
        }
      },
    });
  };
}
