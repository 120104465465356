import { Component, Input, OnInit } from '@angular/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-recommendation-modal',
  templateUrl: './tag-recommendation-modal.component.html',
})
export class TagRecommendationModalComponent implements OnInit {
  @Input() public recipientProfileKey: number;
  @Input() public trackingLocation?: string;

  public i18n = this.translate.instant([
    'OrgSkills_RecommendModalTitle',
    'Team_RecommendationSent',
  ]);
  public formValid: boolean;
  public totalTags: number;
  public tags: TagsApi.Tag[];
  public item: { tags: TagsApi.Tag[]; recipientProfileKey: number };

  constructor(
    private activeModal: NgbActiveModal,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {}

  public get buttonText() {
    let resource = 'OrgSkills_Recommend';
    let interpolation: { number: number }; // if applicable.

    // Conditionally show a singular or plural resource, else the generic one.
    if (this.totalTags) {
      if (this.totalTags === 1) {
        resource = 'OrgSkills_RecommendSkillsSingleFormat';
      } else {
        resource = 'OrgSkills_RecommendSkillsPluralFormat';
        interpolation = {
          number: this.totalTags,
        };
      }
    }

    return this.translate.instant(resource, interpolation);
  }

  public ngOnInit(): void {
    this.item = {
      tags: this.tags,
      recipientProfileKey: this.recipientProfileKey,
    };

    this.tracker.trackEventData({
      action: 'Manager Opened Recommend Skills Modal',
      properties: {
        Location: this.trackingLocation,
        UserProfileKey: this.recipientProfileKey,
      },
    });
  }

  /**
   * Handle when tags are changed. Emitted from child.
   */
  public handleTagsModified(event): void {
    this.formValid = event.formValid;
    this.tags = event.tags;
    this.item.tags = event.tags;
    this.totalTags = event.tags.length;
  }

  /**
   * Close the modal, not saving.
   */
  public onClose(): void {
    this.activeModal.dismiss();
  }

  /**
   * Primary action for the modal.
   */
  public onSubmit(): void {
    this.onClose();

    this.tracker.trackEventData({
      action: 'Manager Recommended Skills',
      properties: {
        Location: this.trackingLocation,
        UserProfileKey: this.recipientProfileKey,
        Skills: this.tags,
      },
    });
  }
}
