import { TranslateService } from '@ngx-translate/core';
import { DfFormFieldConfig } from './field-types';
import { DfFieldWrapperComponent } from './field-wrapper/field-wrapper.component';
import { DfFormTextareaComponent } from './form-textarea/form-textarea.component';
import { DfFormTextInputComponent } from './form-text-input/form-text-input.component';
import { DfFormToggleSwitchComponent } from './form-toggle-switch/form-toggle-switch.component';
import { FormlyTranslateExtension } from './formly-translate.extension';
import { dfFieldMatchValidator } from './validation/validators';
import { DfFormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { DfFormCustomFieldComponent } from './form-custom-field/form-custom-field.component';
import { DfFieldsetComponent } from './fieldset/fieldset.component';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import { isString } from '../utilities/types/type-guards';

/** Provides the base configuration for to support fresco's common formly field types.
 * @description Currently 'input' and 'textarea' are the only types supported. More to follow.
 */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export const DF_COMMON_FORMS_CONFIG = {
  types: [
    {
      name: 'input',
      component: DfFormTextInputComponent,
      wrappers: ['default'],
    },
    {
      name: 'textarea',
      component: DfFormTextareaComponent,
      wrappers: ['default'],
    },
    {
      name: 'toggle-switch',
      component: DfFormToggleSwitchComponent,
      wrappers: ['default'],
    },
    {
      name: 'checkbox',
      component: DfFormCheckboxComponent,
      wrappers: ['default'],
    },
    {
      name: 'custom',
      component: DfFormCustomFieldComponent,
      wrappers: ['default'],
    },
  ],
  validators: [
    // This validator has to be registered globally because it works at the form level
    {
      name: 'fieldMatch',
      validation: dfFieldMatchValidator('newPassword', 'confirmPassword'),
    },
  ],
  wrappers: [
    { name: 'default', component: DfFieldWrapperComponent },
    {
      name: 'fieldset',
      component: DfFieldsetComponent,
    },
  ],
};

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function formlyTranslationConfigFactory(translate: TranslateService) {
  return {
    extensions: [
      {
        name: 'translate',
        extension: new FormlyTranslateExtension(translate),
      },
    ],
    // Default validation messages. These can be overridden per-field if desired.
    validationMessages: [
      {
        name: 'required',
        message: (_, field: DfFormFieldConfig) => {
          if (isString(field.templateOptions.dfLabel)) {
            return translate.instant(`Core_FieldRequiredFormat`, {
              fieldName: field.templateOptions.dfLabel,
            });
          } else {
            // eslint-disable-next-line no-console
            console.info(
              'You must provide your own validation error string when using dynamic or template-based labels'
            );
            return '';
          }
        },
      },
      {
        name: 'email',
        message: () => translate.instant('Core_InvalidEmailPattern'),
      },
      {
        name: 'emailExists',
        message: () => translate.instant('Core_EmailExistsError'),
      },
      {
        name: 'pattern',
        message: () => translate.instant('Core_PasswordRequirementsNotMet'),
      },
      {
        name: 'fieldMatch',
        message: () => translate.instant('Core_PasswordMatchError'),
      },
      {
        name: 'wordCount',
        message: () => translate.instant('Core_WordLimitExceeded'),
      },
    ],
  };
}

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export const FORMLY_TRANSLATIONS_CONFIG_PROVIDER = {
  provide: FORMLY_CONFIG,
  useFactory: formlyTranslationConfigFactory,
  deps: [TranslateService],
  multi: true,
};
