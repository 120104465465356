<form class="form-wrap" [formGroup]="flexEdForm" (ngSubmit)="submit()">
  <dgx-modal
    [isSubmitDisabled]="(!flexEdForm.valid && !saving) || isNewFlexEdLinked"
    [isSubmitPending]="saving"
    [submitButtonText]="saveButtonText"
    [useDefaultForm]="false"
    (dismiss)="onDismiss()"
    bodyClasses="guts-p-h-2"
  >
    <!-- Modal header -->
    <ng-container class="modal-header">
      {{ 'flexEdModal_LinkYourFlexEdCard' | translate }}
    </ng-container>
    <!-- Modal body -->
    <ng-container class="modal-body">
      <ng-container *ngIf="!isNewFlexEdLinked">
        <div class="guts-m-b-1">
          <div *ngIf="!isPexUser">
            <label for="flexEdContentForm" class="ib color-hit_grey">{{
              'flexEdModal_ContactYourAdminMessage' | translate
            }}</label>
          </div>
          <div role="alert" aria-live="assertive">
            <span *ngIf="errorText" class="error-text">
              <df-icon icon="exclamation-triangle-outline"></df-icon>
              {{ errorText }}
            </span>
          </div>
        </div>
        <div class="l_flex">
          <div
            class="
              l_flex l_flex-grow l_flex-column
              guts-p-r-2
              m-guts-p-r-0 m-guts-m-b-1
            "
          >
            <label for="fullName">{{
              'flexEdModal_NameOnCard' | translate
            }}</label>
            <input
              ngbAutoFocus
              autocomplete="cc-name"
              id="fullName"
              name="fullName"
              type="text"
              formControlName="name"
              data-dgat="flexEdModal-1ca"
            />
            <div role="alert" aria-live="assertive">
              <div
                class="form__invalid-msg ib rel"
                *ngIf="!name.valid && !name.pristine"
              >
                <df-icon
                  size="small"
                  icon="arrow-down-right"
                  class="guts-p-r-quart"
                ></df-icon>
                <span>{{ 'Core_FieldRequired' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="l_flex l_flex-column">
            <label for="lastFour">{{
              'flexEdModal_LastFour' | translate
            }}</label>
            <input
              id="lastFour"
              name="cardNumber"
              type="text"
              formControlName="cardNumber"
              data-dgat="flexEdModal-412"
            />
            <div role="alert" aria-live="assertive">
              <div
                class="form__invalid-msg ib rel"
                *ngIf="!cardNumber.valid && !cardNumber.pristine"
              >
                <df-icon
                  size="small"
                  icon="arrow-down-right"
                  class="guts-p-r-quart"
                ></df-icon>
                <span>{{ 'flexEdModal_InvalidNumberError' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isNewFlexEdLinked">
        <p class="font-semibold">
          {{ 'flexEdModal_SuccessfullyConnected' | translate }}
        </p>
        <p>
          {{ 'flexEdModal_ViewYourExpenditures' | translate }}
        </p>
        <a
          class="btn btn-primary font-semibold"
          [href]="userProfileFlexUrl"
          data-dgat="flexEdModal-9a8"
          >{{ 'flexEdModal_ViewMyFlexEd' | translate }}</a
        >
      </ng-container>
    </ng-container>
  </dgx-modal>
</form>
