import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupService } from '@app/shared/services/group.service';
import { WindowToken } from '@app/shared/window.token';
import { NotifierService } from '@app/shared/services/notifier.service';
import { ContextService } from '@app/shared/services/context.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '@app/shared/services/modal.service';

@Component({
  selector: 'dgx-group-delete',
  templateUrl: './group-delete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupDeleteComponent {
  @Input() public groupId: number;
  @Input() public orgId: number;

  public deleteForm = this.fb.group({
    verifyText: ['', [Validators.required, Validators.pattern(/^delete$/i)]],
  });

  public i18n: any = this.translate.instant(
    [
      'Core_Continue',
      'GroupDeleteCtrl_DeleteGroupInfoDescription',
      'GroupDeleteCtrl_DeleteGroupConfirm',
      'GroupDeleteCtrl_DeleteGroupSuccess',
      'GroupDeleteCtrl_DeleteGroupError',
      'dgGroupDelete_DeleteGroupTitle',
      // Note: these should always say the english "Delete" so we can validate against it
      'dgGroupDelete_InstructionsFormat',
      'dgGroupDelete_DeleteLabelFormat',
      'dgGroupDelete_DeleteLabelInfoFormat',
    ],
    {
      delete: 'Delete',
    }
  );

  public working: boolean = false;

  constructor(
    private groupService: GroupService,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private translate: TranslateService,
    private contextService: ContextService,
    private modalService: ModalService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public deleteGroup() {
    if (this.deleteForm.valid) {
      this.working = true;
      this.modalService
        .showAlert(
          {
            title: this.i18n.GroupDeleteCtrl_DeleteGroupConfirm,
            description: this.i18n.GroupDeleteCtrl_DeleteGroupInfoDescription,
            confirmButtonText: this.i18n.Core_Continue,
          },
          { errorOnDismiss: true }
        )
        .subscribe({
          next: () => this.confirm(),
          error: (error) => this.cancel(),
        });
    }
  }

  public confirm() {
    this.groupService.deleteGroup(this.orgId, this.groupId).subscribe({
      next: () => {
        this.notifier.showSuccess(this.i18n.GroupDeleteCtrl_DeleteGroupSuccess);
        setTimeout(() => this.redirectToOrg(), 500);
      },
      error: () => {
        this.notifier.showError(this.i18n.GroupDeleteCtrl_DeleteGroupError);
        this.working = false;
      },
    });
  }

  public cancel() {
    this.working = false;
    this.deleteForm.patchValue({
      verifyText: '',
    });
  }

  public redirectToOrg() {
    this.working = false;

    const isChannel = this.contextService.urlHasContext(
      this.windowRef.location.href,
      'channel'
    );
    const returnUrl = isChannel
      ? '/channel/groups'
      : `/orgs/${this.orgId}/groups`;

    this.windowRef.location.assign(returnUrl);
  }
}
