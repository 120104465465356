<dgx-org-groups-header
  [groups]="groups"
  [showBulkActions]="showBulkActions"
  (selectNone)="selectNone()"
  [isLoading]="isLoading"
  [orgId]="orgId"
  (updateQueries)="doSearch($event)"
  (filtersChange)="doFilter($event)"
  [filters]="filters"
></dgx-org-groups-header>
<dgx-data-table
  [canSelect]="canManageGroups"
  [columns]="columns"
  [items]="groups"
  [hasMoreItems]="hasMoreItems"
  (loadMoreItems)="getGroups()"
  [useInfiniteScroll]="!dynamicGroupsEnabled"
  [isLoading]="isLoading"
  [propTrackingKey]="propTrackingKey"
  (updateSort)="changeSort($event)"
  [orderBy]="orderBy"
  [isDescending]="isDescending"
  [pageNum]="pageNum"
  (pageChange)="pageChange($event)"
  (perPageChange)="perPageChange($event)"
  [numResultsPerPage]="numResultsPerPage"
  [totalNumResults]="totalNumResults"
  [perPageOptions]="[
    { value: 10, display: '10' },
    { value: 30, display: '30' },
    { value: 50, display: '50' },
  ]"
  [showPerPage]="true"
></dgx-data-table>
<dgx-footer-glasses></dgx-footer-glasses>

<!-- Custom column templates -->
<ng-template #groupName let-item>
  <a
    *ngIf="item.showGroupLink"
    class="uhover break color-ebony"
    [href]="item.url"
    data-dgat="orgGroups-bf4"
    >{{ item.name }}</a
  >
  <span *ngIf="!item.showGroupLink">{{ item.name }}</span>
</ng-template>

<ng-template #actionLink let-item>
  <button
    *ngIf="item.showLeaveButton"
    class="link font-medium"
    [title]="getAriaLabel('OrgGroupsCtrl_LeaveGroup', item.name)"
    [attr.aria-label]="getAriaLabel('OrgGroupsCtrl_LeaveGroup', item.name)"
    (click)="leaveGroup(item)"
    data-dgat="orgGroups-21f"
  >
    {{ i18n.OrgGroupsCtrl_Leave }}
  </button>
  <button
    *ngIf="item.showJoinButton"
    class="link font-medium"
    [title]="getAriaLabel('OrgGroupsCtrl_JoinGroup', item.name)"
    [attr.aria-label]="getAriaLabel('OrgGroupsCtrl_JoinGroup', item.name)"
    (click)="joinGroup(item)"
    data-dgat="orgGroups-82a"
  >
    {{ i18n.Core_Join }}
  </button>
  <button
    *ngIf="item.showRequestButton"
    class="link font-medium"
    [title]="getAriaLabel('OrgGroupsCtrl_RequestGroupMembership', item.name)"
    [attr.aria-label]="
      getAriaLabel('OrgGroupsCtrl_RequestGroupMembership', item.name)
    "
    (click)="requestMembership(item)"
    data-dgat="orgGroups-7dd"
  >
    {{ i18n.Core_Join }}
  </button>
  <span *ngIf="item.showPendingRequest">{{
    i18n.OrgGroupsCtrl_RequestPending
  }}</span>
</ng-template>
<ng-template #visibilityTemplate let-item>
  <span>{{ item.privacyDisplayText }}</span>
  <span *ngIf="item.privacyLevel === 3" class="par par--small par--light block">
    {{
      item.membershipType === 'Dynamic'
        ? i18n.OrgGroupsCtrl_Dynamic
        : i18n.OrgGroupsCtrl_Static
    }}
  </span>
</ng-template>
<ng-template #memberCountTemplate let-item>
  <span>{{ getMemberCountDisplay(item) }}</span>
</ng-template>
