import { DatePipe } from '@angular/common';
import {
  CourseEntry,
  GroupId,
  Input,
  UserCourse,
} from '../../../inputs/inputs-api.model';
import { Injectable } from '@angular/core';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import {
  AddToPathwayOptions,
  CourseFormModel,
  DefaultInputParameters,
  EbbAddToPathwayConfig,
  EbbPathwayConfigurationResult,
  Institution,
} from './course-form.model';
import { CourseService } from '../../course.service';
import { UserCourseService } from './user-course.service';
import { AbstractControl } from '@angular/forms';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { InputType } from '@app/shared/models/core-api.model';
import { take } from 'rxjs/operators';
import {
  AllUserCourseParametersViewModel,
  AnyUserCourseParametersViewModel,
} from '../user-input.model';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { DfFormFieldConfig, DfTemplateOptions } from '@lib/fresco';
import { FieldType } from '@app/user-content/services/field-builder-factory';

@Injectable({ providedIn: 'root' })
export class CourseFormDomainService {
  public i18n = this.translateService.instant([
    'CourseFormCtrl_Accredited',
    'CourseFormCtrl_AddCourse',
    'CourseFormCtrl_EditCourse',
    'CourseFormCtrl_Informal',
    'CourseFormCtrl_SelectProvider',
    'CourseFormCtrl_SelectCourse',
    'CourseFormCtrl_UrlError',
    'CourseFormCtrl_ValidNumber',
    'CourseFormCtrl_UnitType',
    'CourseFormCtrl_Category',
    'Core_Minutes',
    'Core_Hours',
    'Core_Days',
    'Core_Weeks',
    'Core_AddGroups',
    'Core_AddGroupsInfo',
    'Core_ValidUrl',
    'Core_Skills',
    'CourseFormCtrl_US',
    'CourseFormCtrl_Country',
    'CourseFormCtrl_FirstYearLevel',
    'CourseFormCtrl_SecondYearLevel',
    'CourseFormCtrl_ThirdYearLevel',
    'CourseFormCtrl_FourthYearLevel',
    'CourseFormCtrl_GraduateLevel',
    'CourseFormCtrl_ProviderName',
    'CourseFormCtrl_SchoolName',
    'CourseFormCtrl_ITaughtCourse',
    'CourseFormCtrl_WhatDidYouLearnShort',
    'CourseFormCtrl_WhatDidYouLearnLong',
    'CourseFormCtrl_SaveCourse',
    'CourseFormCtrl_CourseTitle',
    'CourseFormCtrl_CourseUrl',
    'CourseFormCtrl_Summary',
    'CourseFormCtrl_WhereTaken',
    'CourseFormCtrl_VerificationUrl',
    'CourseFormCtrl_DateCompleted',
    'CourseFormCtrl_DateTaught',
    'CourseFormCtrl_CourseNumber',
    'CourseFormCtrl_CourseGrade',
    'CourseFormCtrl_AddToCatalogFormat',
    'Core_CommentPrivacyWarning',
    'CourseFormCtrl_CreditHours',
    'CourseFormCtrl_CourseLevel',
    'TagsCtrl_SkillsPlaceholder',
    'Core_SelectDate',
  ]);

  /**
   *  Validation function use by the course form.
   */
  public urlValidation = {
    urlValidation: {
      expression: (control: AbstractControl) => {
        const valid = !control.value || HTTP_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.i18n.Core_ValidUrl,
    },
  };

  // institutionId should be set to null if an institution is not provided - see https://degreedjira.atlassian.net/browse/PD-59181
  private specialInstitutionForInternalOrganization = null;
  constructor(
    private datePipe: DatePipe,
    private courseService: CourseService,
    private inputsService: InputsService,
    private userCourseService: UserCourseService,
    private translateService: TranslateService,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService
  ) {}

  /**
   * Typeahead search function for country field
   * @param value
   */
  public loadCountries(value: string): Observable<string[]> {
    return this.courseService.getCountries(value);
  }

  /**
   * Typeahead search function for institution/provider field
   * @param value
   * @param isAccredited
   * @param country
   */
  public loadInstitutions(
    value: string,
    isAccredited: boolean,
    country: string
  ): Observable<AutocompleteItem[]> {
    return this.courseService.getInstitutions(value, isAccredited, country);
  }

  /**
   * Once a provider/instution is selected from the Typeahead options list
   * we make an api call to get more details about the selected provider.
   * @param institutionId
   */
  public getDetailsForSelectedInstitution(
    institutionId: number
  ): Observable<Institution> {
    // get some more info about the selected institution
    return this.userCourseService.getInstitution(institutionId);
  }

  /**
   * Typeahead search function for course form field.
   * @param institution
   * @param value
   * @param isAccredited
   */
  public loadCourses(
    institution: Institution,
    value: string,
    isAccredited: boolean
  ): Observable<any> {
    if (institution) {
      return this.courseService.getCourses(
        value,
        isAccredited,
        institution.institutionId
      );
    } else {
      return from([]);
    }
  }

  /**
   * Once a course is selected from the Typeahead options list
   * we make an api call to get more details about the selected course.
   * if we are in EbbLoading mode we run logic accordingly.
   * @param courseId
   * @param isEbbLoading
   */
  public onSelectCourse(
    courseId: number,
    isEbbLoading: boolean = false
  ): Observable<unknown> {
    // get some more info about the selected course
    return this.getDetailsForSelectedCourse(courseId);
  }

  /**
   * Helper method to set the correct unit type.
   * @param course
   * @param inputUnitType
   * @param selectedInstitution
   */
  public getUnitType(
    course: CourseEntry,
    inputUnitType: string,
    selectedInstitution: Institution
  ) {
    return (
      course.unitType || inputUnitType || selectedInstitution?.defaultUnitType
    );
  }

  /**
   * Helper method to set the correct units
   * @param course
   * @param inputUnits
   * @param selectedInstitution
   */
  public getUnits(
    course: CourseEntry,
    inputUnits: number,
    selectedInstitution: Institution
  ) {
    return course.units || inputUnits || selectedInstitution?.defaultUnits;
  }

  /**
   * Helper method to extract the domain from an uri.
   * @param url
   */
  public getDomain(url: string) {
    // account for 'www.' not always in url
    let domain = '';
    if (url.indexOf('www.') > -1) {
      domain = url.split('.')[1];
    } else if (url.indexOf('://') > -1) {
      const domainParts = url.split('//')[1].split('.');
      domain = `${domainParts[0]} ${domainParts[1]}`;
    }
    return domain;
  }

  /**
   * Helper method to normalize provider/institution name.
   * @param url
   */
  public normalizeName(name: string) {
    return name ? name.toLowerCase().replace('-', ' ') : '';
  }

  // find a course by title and if no match is found
  // pick the top item in the returned list, we will validate it against current URL next
  public getCourseMatchForEbb(
    courseTitle: string,
    courses: any[]
  ): CourseEntry {
    if (!courses) {
      return undefined;
    }
    const courseMatch = courses.find((course) => {
      return this.isMatchingCourse(courseTitle, course);
    });
    return courseMatch ? courseMatch : courses[0];
  }

  /**
   * Helper method to detect Ebb mode.
   * @param modalOptions
   * @param resolve
   */
  public shouldConfigureForEbb(modalOptions: any, resolve: any): boolean {
    const operationResult =
      modalOptions?.savePathwayFromExtension ||
      modalOptions?.addToCatalogForEbb ||
      resolve?.getCourseInputForEbb;
    return operationResult;
  }

  /**
   * Helper method to detect Pathway mode.
   * @param modalOptions
   * @param resolve
   */
  public shouldWeUsePathwayData(modalOptions: any, resolve: any): boolean {
    return (
      this.shouldConfigureForEbb(modalOptions, resolve) &&
      this.isPathwayData(modalOptions)
    );
  }

  /**
   * If we are in Pathway mode, we should override some data.
   * @param resolve
   * @param resp
   */
  public updatePathwayData(
    resolve: any,
    resp = null
  ): EbbPathwayConfigurationResult {
    const data = resp?.data || resp?.result;
    const inputId = data?.inputId || resolve.inputId;

    const addToPathwayOptions: AddToPathwayOptions = {
      inputId: inputId,
      inputType: 'Course' as InputType,
    };

    const ebbConfig: EbbAddToPathwayConfig = {
      baseUrl: resolve.baseUrl,
      displayAddToCatalogOption: resolve.canManageContent,
      input: resolve.input,
    };

    return { addToPathwayOptions, ebbConfig };
  }

  /**
   * If we are not in Global Search, we need to load the Providers/Institutions
   * using the url as the search term.
   * After that we load the Courses and update the selectedItem, using the
   * values from the passed modalOption object.
   */
  public configureForEbb(
    model: Partial<CourseFormModel>,
    resolve: any,
    isChannel: boolean
  ): void {
    const resolveInput = resolve?.input;
    if (!resolveInput) {
      return;
    }
    const url = resolveInput.Url;
    const courseTitle = resolveInput.Title || '';
    const domain = this.getDomain(url);
    // 1 - Load Institutions using resolve data
    this.loadInstitutions(domain, model.input.isAccredited, model.country)
      .pipe(take(1))
      .subscribe((institutions: AutocompleteItem[]) => {
        const normalizedDomain = this.normalizeName(domain);
        const institutionAsItem =
          institutions.find(
            (institution) =>
              this.normalizeName(institution.label) === normalizedDomain ||
              normalizedDomain.indexOf(this.normalizeName(institution.label)) >
                -1
          ) || institutions[0];
        model.institution = {
          institutionId: institutionAsItem.id,
          name: institutionAsItem.label,
          defaultUnitType: undefined,
        };

        // 2 - Get details for the institution we just found.
        this.getDetailsForSelectedInstitution(
          model.institution.institutionId
        ).subscribe((institutionInfo) => {
          model.institution = institutionInfo; // Update model
          model.institution = institutionInfo;
          // 3 - Get Course information using resolve data
          model.courseName = courseTitle;
          this.loadCourses(
            model.institution,
            model.courseName,
            model.input.isAccredited
          )
            .pipe(take(1))
            .subscribe((courses: CourseEntry[]) => {
              const ebbMatchedCourse = this.getCourseMatchForEbb(
                courseTitle,
                courses
              );
              if (ebbMatchedCourse) {
                // We are in EbbMode with a correct course
                // remove course if isEbbLoading and not correct url
                // populate form from scraped page data
                model.course = null;
                model.input.courseUrl = resolve?.input?.url;
                model.input.description = resolve?.input?.summary;
                model.input.accessible = this.isUrlIsAccessible(
                  model.input.courseUrl
                );
              } else {
                // If course is not found continue populating with page data
                model.input.description = resolveInput.Summary;
                model.input.courseUrl = resolveInput.Url;
                if (resolve?.getCourseInputForEbb) {
                  resolve.getCourseInputForEbb(
                    this.buildInputForEbb(model, isChannel)
                  );
                }
              }
            });
        });
      });
  }

  /**
   * Helper method to setup data while in Channel context.
   * @param input
   * @param forceOrganizationId
   * @param isChannel
   */
  public forceAddToCatalogForChannelContext(
    input: DefaultInputParameters,
    forceOrganizationId: number,
    isChannel: boolean
  ) {
    if (isChannel) {
      input.orgContentMetadata = {
        hideFromCatalog: false,
        groupIds: [],
      };
      input.organizationId = forceOrganizationId;
    }
    return input;
  }

  /**
   * Helper method to set groupids/groupname relationship.
   * @param groupNames
   * @param groupIds
   */
  public getGroupIdentifiers(groupNames: [], groupIds: GroupId[]): GroupId[] {
    return groupNames.map((gn, i) => {
      return {
        groupId: groupIds[i].groupId,
        name: gn,
      };
    });
  }

  /**
   * This function will override input parameters
   * from parameters coming from the resolve object.
   */
  public determineSubmitActionForEbb(
    hideFromCatalog: boolean,
    model: CourseFormModel,
    resolve: any
  ): Observable<unknown> {
    model.description = model.description || resolve.pageData?.summary;
    model.input.orgContentMetadata.hideFromCatalog = hideFromCatalog;
    model.input.organizationId = resolve?.input?.OrganizationId;
    model.input.imageUrl = resolve?.input?.ImageUrl;
    // need to clear InputId when adding to catalog
    model.inputId = !hideFromCatalog ? null : model.input.inputId;
    return this.determineSubmitAction(
      model.completing,
      model.input,
      model.institutionId,
      model.institutionName
    );
  }

  /**
   * Helper method to define submit action depending on running mode.
   * @param isCompleting
   * @param input
   */
  public determineSubmitAction(
    isCompleting: boolean,
    input: DefaultInputParameters,
    institutionId: number,
    institutionName: string
  ): Observable<unknown> {
    let action: Observable<unknown>;
    if (isCompleting) {
      if (input.userInputId) {
        action = this.userCourseService.Update(input);
      } else if (input.inputId) {
        action = this.userCourseService.AddExisting(
          input,
          'GlobalAdd',
          institutionId,
          institutionName
        );
      } else {
        action = this.userCourseService.Add(
          input,
          'GlobalAdd',
          institutionId,
          institutionName
        );
      }
    } else {
      if (input.inputId) {
        action = this.inputsService.updateCourse(input);
      } else {
        const payload: Input = {
          ...input,
          uid: undefined,
          obsolete: false,
          fileManaged: false,
          durationISO: undefined,
          inputType: 'Course',
          language: undefined,
          durationDisplay: undefined,
          durationUnitType: undefined,
          url: input.courseUrl,
          source: undefined,
          owner: undefined,
          organization: undefined,
          createdByName: undefined,
        };

        action = this.inputsService.addCourse(payload);
      }
    }
    return action;
  }

  /**
   * Helper method to validate provider/institution.
   * @param model
   */
  public institutionValidation(model: CourseFormModel) {
    if (!model.institutionName) {
      // empty value is ok
      return true;
    }
    // if there is a name, it must be from the dropdown, so check the id
    const institutionSpecified = model.institution?.institutionId > 0;
    const orgSpecified = model.orgId > 0;
    return institutionSpecified || orgSpecified;
  }

  /**
   * Helper method to validate courses.
   * @param model
   */
  public courseValidation(model: CourseFormModel) {
    return !!model.courseName;
  }

  /**
   * Helper method to convert data from Form Model to Api expected payload interface.
   * @param model
   */
  public convertViewModelToApiModel(
    model: Partial<CourseFormModel>
  ): AnyUserCourseParametersViewModel {
    let input: DefaultInputParameters = {
      ...model.input,
      inputId: model.inputId,
      inputType: 'Course',
      courseUrl: model.courseUrl,
      creditHours: model.creditHours,
      authored: model.authoring,
      dateCompleted: this.datePipe.transform(
        model.extent?.dateCompleted,
        'yyyy-MM-dd'
      ),
      institutionId: model.institution
        ? model.institution.institutionId
        : this.specialInstitutionForInternalOrganization,
      institutionName: model.institutionName,
      name: model.course?.id ? model.course.label : model.courseName,
      tags: model.tags,
      verificationUrl: model.extent.verificationUrl,
      gradeId: model.extent.courseGrade,
      levelId: model.extent.courseLevel,
    };

    if (
      model.org !== null &&
      model.isPathwayBinAdd &&
      model.selectedOrg.id > 0
    ) {
      input.organizationId = model.org.organizationId;
    }

    if (!input.orgContentMetadata) {
      // Default state if checkbox for internal content was left unchecked
      input.orgContentMetadata = {
        hideFromCatalog: true,
        // groupIds: model.input.orgContentMetadata.groupIds,
        groupIds: [],
      };
    }
    input = this.forceAddToCatalogForChannelContext(
      input,
      model.forceOrganizationId,
      model.hideGroups
    );
    input.orgContentMetadata.groupIds = this.getGroupIdentifiers(
      model.groupNames,
      model.groupIds
    );

    const result: AllUserCourseParametersViewModel = {
      ...input,
      name: input.name,
      institutionId: input.institutionId,
      organizationId: input.organizationId,
      orgContentMetadata: { ...input.orgContentMetadata },
      owner: '',
      language: '',
      country: model.country,
      tags: model.tags,
      isAccredited: model.isAccredited,
      comment: model.comment,
    };

    return result as AnyUserCourseParametersViewModel;
  }

  /**
   * Helper method to convert data from Api payload interface to Form Model interface.
   * @param source
   * @param target
   */
  public convertApiModelToViewModel(
    source: UserCourse,
    target: Partial<CourseFormModel>
  ): Partial<CourseFormModel> {
    if (JSON.stringify(source) === '{}') {
      return target;
    }
    target.inputId = source.inputId ?? target.inputId;
    target.course.id = source.courseId ?? target.course.id;
    target.courseUrl = source.courseUrl ?? target.courseUrl;
    target.courseName = source.name ?? target.courseName;
    target.creditHours = source.creditHours ?? target.creditHours;
    target.institution = {
      ...target.institution,
      institutionId: source.institutionId,
      name: source.institutionName,
    };
    target.isAccredited = source.isAccredited ?? target.isAccredited;
    target.country = source.country ?? target.country;
    target.input.courseNumber =
      source.courseNumber ?? target.input.courseNumber;
    target.input.creditHours = source.creditHours ?? target.input.creditHours;
    target.input.units = source.units ?? target.input.units;
    target.input.unitType = source.unitType ?? target.input.unitType;

    target.authoring = source.authored ?? target.authoring;
    target.input.imageUrl = source.imageUrl ?? target.input.imageUrl;
    target.extent.dateCompleted =
      source.dateCompleted ?? target.extent.dateCompleted;
    target.extent.verificationUrl =
      source.verificationUrl ?? target.extent.verificationUrl;
    target.extent.courseLevel = source.levelId ?? target.extent.courseLevel;
    target.extent.courseGrade = source.gradeId ?? target.extent.courseGrade;
    target.createdByName = source.createdByName ?? (source.createdBy as string);
    return target;
  }

  /**
   * Helper method to check Pathway mode.
   * @param modalOptions
   */
  public isPathwayData(modalOptions: any): boolean {
    return modalOptions?.inputId || modalOptions?.userInputId;
  }

  public buildUIConfiguration(
    model: CourseFormModel
  ): DfFormFieldConfig<DfTemplateOptions> {
    return this.contentCatalogFormBuilderService
      .getBuilder(FieldType.ADVANCED_FIELDSET)
      .build({
        organizationId: model.organizationId,
        isInitiallyVisibleToOrg: model.isVisibleToOrg,
      });
  }

  /**
   * Helper method to check if course is accessible.
   * @param courseUrl
   */
  private isUrlIsAccessible(courseUrl: string) {
    if (courseUrl) {
      this.inputsService
        .getCourseMetadata(courseUrl)
        .subscribe((result: any) => {
          return result.accessible;
        });
    } else {
      return false;
    }
  }

  /**
   * Helper method related to Ebb mode.
   * @param courseTitle
   * @param course
   */
  private isMatchingCourse(courseTitle, course): boolean {
    return (
      courseTitle.indexOf(course.label) > -1 ||
      courseTitle.replace(/-/, ' ').indexOf(course.label) > -1
    );
  }

  /**
   * Helper method to retrieve details on selected course, used by Typeahead search.
   * @param courseId
   */
  private getDetailsForSelectedCourse(courseId: number): Observable<unknown> {
    return this.inputsService.getCourse(courseId);
  }

  /**
   * Helper method used while in Ebb mode to create api payload.
   * @param model
   * @param isChannel
   */
  private buildInputForEbb(
    model: Partial<CourseFormModel>,
    isChannel: boolean
  ): DefaultInputParameters {
    let input = model.input;

    input.institutionId = model.institution
      ? model.institution.institutionId
      : -1; // -1 is a special institution for org (internal) courses
    if (model.course?.id) {
      // selected an existing course
      input.name = model.course.label;
    } else if (model.courseName) {
      // typed a new course name
      input.name = model.courseName;
    }
    if (
      model.org !== null &&
      model.isPathwayBinAdd &&
      model.selectedOrg.id > 0
    ) {
      input.organizationId = model.org.organizationId;
    }
    if (!input.orgContentMetadata) {
      // Default state if checkbox for internal content was left unchecked
      input.orgContentMetadata = {
        hideFromCatalog: true,
        groupIds: model.input.orgContentMetadata.groupIds,
      };
    }

    input = this.forceAddToCatalogForChannelContext(
      input,
      model.forceOrganizationId,
      isChannel
    );

    input.orgContentMetadata.groupIds = this.getGroupIdentifiers(
      model.groupNames,
      model.groupIds
    );
    return input;
  }
}
