<ng-container *ngIf="showPlanSimplification; else oldImageCropperModal">
  <form class="form-wrap" (ngSubmit)="submit()">
    <dgx-modal
      [canCancel]="true"
      [useDefaultForm]="false"
      [isLoading]="isLoading"
      [isSubmitDisabled]="isLoading"
      [submitButtonText]="modalSubmit"
      [submitButtonDgat]="'image-cropper-modal-389'"
      [modalHeaderTitle]="modalTitle"
      [bodyClasses]="'guts-p-h-1'"
      (dismiss)="cancel($event)"
    >
      <ng-container class="modal-body">
        <div class="__cropper-container guts-m-b-1-half">
          <dgx-cropper
            #cropper
            *ngIf="imageUrl"
            [aspectRatio]="cropperOptions?.aspectRatio"
            [cropper]="cropperOptions?.cropper"
            [image]="imageUrl"
            [secondaryAspectRatio]="cropperOptions?.secondaryAspectRatio"
            [maintainAspectRatio]="cropperOptions?.maintainAspectRatio"
            (cropperReady)="onCropperReady($event)"
            (imageCropped)="onImageCropped($event)"
            (imageLoaded)="onImageLoaded()"
            (loadImageFailed)="onLoadImageFailed()"
          ></dgx-cropper>
        </div>

        <div *ngIf="modalInstructions" [innerHTML]="modalInstructions"></div>

        <ng-container
          *ngIf="!modalInstructions && aspectRatio && secondaryAspectRatio"
        >
          <!-- Preview of the two aspect ratios when cropping an input -->
          <h4 class="h4 guts-m-b-1">{{ i18n.Core_Preview }}</h4>
          <div class="__preview-container">
            <div class="__primary-preview guts-m-r-1 ib">
              <img
                *ngIf="cropPreview"
                [src]="cropPreview"
                [alt]="i18n.dgImageEdit_ContentCard"
                class="__preview-image __preview-image--primary"
              />
              <p
                [dgxLineClampText]="i18n.dgImageEdit_ContentCard"
                [clampLinesMax]="1"
                class="par par--small font-bold __preview-desc"
              ></p>
            </div>
            <div class="__secondary-preview l_flex-bottom ib">
              <img
                *ngIf="secondaryCropPreview"
                [src]="secondaryCropPreview"
                [alt]="i18n.dgImageEdit_ContentDetails"
                class="__preview-image __preview-image--secondary"
              />
              <p
                [dgxLineClampText]="i18n.dgImageEdit_ContentDetails"
                [clampLinesMax]="1"
                class="par par--small font-bold __preview-desc"
              ></p>
            </div>
          </div>
          <div class="guts-m-t-1">
            <p
              class="__helper-text par par--small par--light font-medium inline"
              [innerHTML]="i18n.dgImageEdit_DocumentationDesc"
            ></p>
            <a
              [href]="imageDocumentationLink"
              class="link"
              target="_blank"
              aria-describedby="a11yNewWindowDescription"
              data-dgat="image-cropper-modal-565"
            >
              <df-icon
                [a11yText]="i18n.dgImageEdit_DocumentationLabel"
                size="extra-small"
                icon="arrow-up-right-out"
                class="color-blue guts-m-l-quart"
              ></df-icon>
            </a>
          </div>
        </ng-container>

        <ng-container
          *ngIf="!modalInstructions && aspectRatio && !secondaryAspectRatio"
        >
          <p
            class="__helper-text par par--small par--light font-medium inline"
            [innerHTML]="i18n.dgImageEdit_DocumentationDesc"
          ></p>
          <a
            [href]="imageDocumentationLink"
            class="link"
            target="_blank"
            aria-describedby="a11yNewWindowDescription"
            data-dgat="image-cropper-modal-565"
          >
            <df-icon
              [a11yText]="i18n.dgImageEdit_DocumentationLabel"
              size="extra-small"
              icon="arrow-up-right-out"
              class="color-blue guts-m-l-quart"
            ></df-icon>
          </a>
        </ng-container>

        <ng-container *ngIf="!hideAltTextInput && showPlanSimplification">
          <div class="guts-p-v-1">
            <label class="guts-m-b-quart form__label" for="altText">
              {{ i18n.Core_AltText }}
            </label>
            <input
              [(ngModel)]="altText"
              name="altText"
              aria-label="default"
              data-dgat="image-cropper-modal-a60"
            />
            <p
              class="
                par par--small par--light
                guts-p-b-0 guts-m-t-quart guts-p-h-1
              "
            >
              {{ i18n.Core_AltTextHelperText }}
            </p>
          </div>
        </ng-container>
      </ng-container>
    </dgx-modal>
  </form>
</ng-container>

<ng-template #oldImageCropperModal>
  <div class="guts-p-h-2 guts-p-t-1-half guts-p-b-2">
    <div [hidden]="isLoading">
      <h4 class="h4 guts-p-b-1-half">{{ modalTitle }}</h4>
      <div class="__cropper-container guts-m-b-1-half">
        <dgx-cropper
          #cropper
          *ngIf="imageUrl"
          [aspectRatio]="cropperOptions?.aspectRatio"
          [cropper]="cropperOptions?.cropper"
          [image]="imageUrl"
          [secondaryAspectRatio]="cropperOptions?.secondaryAspectRatio"
          [maintainAspectRatio]="cropperOptions?.maintainAspectRatio"
          (cropperReady)="onCropperReady($event)"
          (imageCropped)="onImageCropped($event)"
          (imageLoaded)="onImageLoaded()"
          (loadImageFailed)="onLoadImageFailed()"
        ></dgx-cropper>
      </div>
      <div *ngIf="modalInstructions" [innerHTML]="modalInstructions"></div>
      <ng-container
        *ngIf="!modalInstructions && aspectRatio && secondaryAspectRatio"
      >
        <!-- Preview of the two aspect ratios when cropping an input -->
        <h4 class="h4 guts-m-b-1">{{ i18n.Core_Preview }}</h4>
        <div class="__preview-container">
          <div class="__primary-preview guts-m-r-1 ib">
            <img
              *ngIf="cropPreview"
              [src]="cropPreview"
              [alt]="i18n.dgImageEdit_ContentCard"
              class="__preview-image __preview-image--primary"
            />
            <p
              [dgxLineClampText]="i18n.dgImageEdit_ContentCard"
              [clampLinesMax]="1"
              class="par par--small font-bold __preview-desc"
            ></p>
          </div>
          <div class="__secondary-preview l_flex-bottom ib">
            <img
              *ngIf="secondaryCropPreview"
              [src]="secondaryCropPreview"
              [alt]="i18n.dgImageEdit_ContentDetails"
              class="__preview-image __preview-image--secondary"
            />
            <p
              [dgxLineClampText]="i18n.dgImageEdit_ContentDetails"
              [clampLinesMax]="1"
              class="par par--small font-bold __preview-desc"
            ></p>
          </div>
        </div>
        <div class="guts-m-t-1">
          <p
            class="__helper-text par par--small par--light font-medium inline"
            [innerHTML]="i18n.dgImageEdit_DocumentationDesc"
          ></p>
          <a
            [href]="imageDocumentationLink"
            class="link"
            target="_blank"
            aria-describedby="a11yNewWindowDescription"
            data-dgat="image-cropper-modal-565"
          >
            <df-icon
              [a11yText]="i18n.dgImageEdit_DocumentationLabel"
              size="extra-small"
              icon="arrow-up-right-out"
              class="color-blue guts-m-l-quart"
            ></df-icon>
          </a>
        </div>
      </ng-container>
      <ng-container
        *ngIf="!modalInstructions && aspectRatio && !secondaryAspectRatio"
      >
        <p
          class="__helper-text par par--small par--light font-medium inline"
          [innerHTML]="i18n.dgImageEdit_DocumentationDesc"
        ></p>
        <a
          [href]="imageDocumentationLink"
          class="link"
          target="_blank"
          aria-describedby="a11yNewWindowDescription"
          data-dgat="image-cropper-modal-565"
        >
          <df-icon
            [a11yText]="i18n.dgImageEdit_DocumentationLabel"
            size="extra-small"
            icon="arrow-up-right-out"
            class="color-blue guts-m-l-quart"
          ></df-icon>
        </a>
      </ng-container>
    </div>
    <ng-container *ngIf="isLoading">
      <df-spinner [isSpinning]="isLoading"></df-spinner>
    </ng-container>
  </div>
  <dgx-modal-footer
    *ngIf="!isLoading"
    [canCancel]="true"
    [useDefaultSubmitButton]="false"
    (dismiss)="cancel($event)"
  >
    <button
      df-button
      dfButtonType="primary"
      data-dgat="image-cropper-modal-389"
      class="guts-p-h-1"
      (click)="submit()"
    >
      {{ modalSubmit }}
    </button>
  </dgx-modal-footer>
</ng-template>
