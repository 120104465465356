import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { OrgEndorsedService } from '@app/orgs/services/org-endorsed.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { TagsSearchItem } from '@app/tags/tags';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-add-tags-modal',
  templateUrl: './add-tags-modal.component.html',
  styleUrls: ['./add-tags-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTagsModalComponent implements OnInit {
  // Part of Workday Skills Integration. Before saving will check if any alternative canonical skills exist
  @Input() public checkForCanonicalTags: boolean = false;
  public tags: TagsSearchItem[] = [];
  public saving: boolean = false;
  public i18n: { [key: string]: string } = {};
  public endorsedSrc: string;

  constructor(
    private trackerService: TrackerService,
    private tagsService: TagsService,
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private authService: AuthService,
    private orgEndorsedService: OrgEndorsedService
  ) {}

  public ngOnInit(): void {
    this.i18n = this.translateService.instant([
      'Core_Saving',
      'Core_Endorsed',
      'Core_Remove',
      'OrgSkills_AddSkillTitle',
      'OrgSkills_SearchForSkills',
      'TargetResourcesForm_Selected',
    ]);
    const orgId = this.authService.authUser?.defaultOrgInfo?.organizationId;
    this.endorsedSrc = this.orgEndorsedService.getEndorsedSrc(orgId);
  }

  public add(tag: TagsApi.Tag): void {
    // Prevent duplicate tag items from being added
    const isFound = (item: TagsSearchItem) =>
      item.title.trim().toLowerCase() === tag.title.trim().toLowerCase() ||
      item.name.trim().toLowerCase() === tag.name.trim().toLocaleLowerCase();
    if (this.tags.some(isFound)) {
      return;
    }

    // Add to tags array
    this.tags = [...this.tags, tag] as TagsSearchItem[];

    // Track user action
    this.trackerService.trackEventData({
      action: 'Skill Adding Step Completed',
      properties: {
        SkillId:
          (tag as TagsSearchItem).id || (tag as Partial<TagsApi.Tag>).tagId,
        SkillName: tag.name,
      },
    });
  }

  public remove(tag: TagsSearchItem) {
    this.tags = this.tags.filter(({ name }) => name !== tag.name);
  }

  public save(): void {
    if (this.tags.length === 0) {
      return;
    }

    this.saving = true;

    if (this.checkForCanonicalTags) {
      this.saveCanonicalTags();
    } else {
      this.saveTags();
    }
  }

  public saveTags(): void {
    // Analytics and the back end require `TagId`
    const tags = this.tags.map(({ name, id }) => ({
      name,
      tagId: id,
    })) as Partial<TagsApi.Tag[]>;

    this.tagsService.addUserTags(tags).subscribe(() => {
      this.saving = false;
      this.activeModal.close();
    });
  }

  public saveCanonicalTags(): void {
    // Analytics and the back end require `TagId`
    const tags = this.tags.map(({ name, id }) => ({
      name,
      tagId: id,
    })) as Partial<TagsApi.Tag[]>;

    this.tagsService.getAllCanonicalTags(tags).subscribe((results) => {
      const sorted = tags.reduce(
        (acc, curr) => {
          const canonical = results.find((result) => result.tag === curr.name);
          if (
            canonical?.hasCanonical &&
            canonical?.tag !== canonical?.canonicalTag
          ) {
            acc.canonical.push(canonical);
          } else {
            acc.nonCanonical.push(curr);
          }
          return acc;
        },
        { canonical: [], nonCanonical: [] }
      );

      if (sorted.nonCanonical.length) {
        this.tagsService.addUserTags(sorted.nonCanonical).subscribe(() => {
          if (sorted.canonical.length) {
            this.activeModal.close(sorted.canonical);
          } else {
            this.activeModal.close();
          }
          this.saving = false;
        });
      } else {
        this.activeModal.close(sorted.canonical);
      }
    });
  }

  public trackByTitle(index: number, item: TagsSearchItem): string {
    return item.title;
  }
}
