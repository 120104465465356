import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PDFZoomControls } from '@app/content-hosting/services';

/**
 * Content Hosting Viewer Header Component
 */
@Component({
  selector: 'dgx-viewer-header',
  templateUrl: './viewer-header.component.html',
  styleUrls: ['./viewer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerHeaderComponnet {
  @Input() public controls: PDFZoomControls;
}
