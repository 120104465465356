import { NgModule } from '@angular/core';

// misc
import { NOTIFICATIONS_PER_PAGE } from './notification.tokens';

// modules
import { SharedModule } from '@app/shared/shared.module';

// components
import { NotificationCommentComponent } from './components/notification-comment/notification-comment.component';
import { NotificationListItemComponent } from './components/notification-list-item/notification-list-item.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { NotificationViewComponent } from './components/notification-view/notification-view.component';

const NOTIFICATIONS_PER_PAGE_VALUE = 20;

@NgModule({
  declarations: [
    // components
    NotificationCommentComponent,
    NotificationListComponent,
    NotificationListItemComponent,
    NotificationMenuComponent,
    NotificationViewComponent,
  ],
  exports: [
    NotificationCommentComponent,
    NotificationListComponent,
    NotificationListItemComponent,
    NotificationMenuComponent,
    NotificationViewComponent,
  ],
  imports: [SharedModule],
  providers: [
    { provide: NOTIFICATIONS_PER_PAGE, useValue: NOTIFICATIONS_PER_PAGE_VALUE },
  ],
})
export class NotificationSharedModule {}
