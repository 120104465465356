import { UserSearchItem } from '../../../user/user-api.model';
import { Component, Input } from '@angular/core';

/**
 * This component is used to display a list of authors, typically within a DataTable column
 */

@Component({
  selector: 'dgx-authors-list',
  templateUrl: './authors-list.component.html',
})
export class AuthorsListComponent {
  @Input() public authored: UserSearchItem[];

  constructor() {}
}
