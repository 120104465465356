import { TranslateService } from '@ngx-translate/core/public_api';

/**
 * When editing a content item from a pathway or plan,
 * a notification within the modal will tell the user
 * whether the change will effect the catalog item or the local path/plan item
 * @param isEditingInternalContent
 * @param translateService
 * @returns string
 */
export const pathPlanEditContentNotification = (
  isEditingInternalContent: boolean,
  translateService: TranslateService
): string =>
  isEditingInternalContent
    ? translateService.instant('Pathways_EditCatalogItemHelper')
    : translateService.instant('Pathways_EditLocalContentHelper');
