import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators,
} from '@angular/forms';

// services
import { TranslateService } from '@ngx-translate/core';

export function endDateValidator(translate: TranslateService) {
  return (control: FormControl): ValidationErrors | null => {
    const startAfterEndError = translate.instant(
      'PositionFormCtrl_EndAfterStart'
    );
    const startDate = control.parent?.get('startDate')?.value;
    return startDate >= control.value ? { startAfterEndError } : null;
  };
}

@Component({
  selector: 'dgx-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class DateRangeComponent implements OnInit, OnChanges {
  @Input() public formModel: {
    startDate?: Date;
    endDate?: Date;
  };

  @Input() public dateRangeFormControlName = 'dateRangeForm';
  @Input() public showEndDate = true;
  @Input() public startDateRequired = true;
  @Input() public endDateRequired = true;
  // this is currently required so that we can inform the dateRangeForm that the parent form has programmatically touched all
  // fields, currently this touch is not being passed through from the parent to the child form
  @Input() public isTouched = false;
  @Input() public startDateEnabled = true;
  @Input() public endDateEnabled = true;
  @Input() public isMaxStartDateToday = true;
  @Input() public isMaxEndDateToday = true;

  public dateRangeForm: FormGroup;
  public i18n = this.translate.instant([
    'Core_StartDate',
    'Core_EndDate',
    'Core_SelectStartDate',
    'Core_SelectEndDate',
    'Core_SelectDate',
  ]);
  @Input() public startDateLabel: string = this.i18n.Core_StartDate;
  @Input() public endDateLabel: string = this.i18n.Core_EndDate;

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public parentForm: FormGroupDirective,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.initializeForm();

    if (this.formModel) {
      this.dateRangeForm.patchValue({
        startDate: this.formModel.startDate,
        endDate: this.formModel.endDate,
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.dateRangeForm) {
      return;
    }

    if (changes.showEndDate && !changes.showEndDate.currentValue) {
      this.dateRangeForm.get('endDate').disable();
    } else if (changes.showEndDate && changes.showEndDate.currentValue) {
      this.dateRangeForm.get('endDate').enable();
    }
  }

  public onBlur(field: string) {
    const formField = this.dateRangeForm.get(field);
    formField.markAsDirty();
    formField.markAsTouched();
  }

  public getEndDateValidationMessage() {
    const startAfterEndError =
      this.dateRangeForm.get('endDate').errors?.startAfterEndError;
    const requiredError = this.dateRangeForm.get('endDate').errors?.required
      ? this.translate.instant('Core_FieldRequiredFormat', {
          fieldName: this.endDateLabel,
        })
      : null;
    return requiredError ?? startAfterEndError;
  }

  public showValidationMessage(fieldName: string): boolean {
    return (
      (this.isTouched || this.dateRangeForm.get(fieldName).touched) &&
      this.dateRangeForm.get(fieldName).invalid
    );
  }

  public updateEndDateValidation(): void {
    // fire endDate validation when startDate is selected
    this.dateRangeForm.get('endDate').updateValueAndValidity();
  }

  private initializeForm(): void {
    const endDateValidatorFn = endDateValidator(this.translate);

    const startDateValidation = this.startDateRequired
      ? [Validators.required]
      : [undefined];
    const endDateValidation = this.endDateRequired
      ? [Validators.required, endDateValidatorFn]
      : [endDateValidatorFn];

    const startDate = ['', startDateValidation];
    const endDate = ['', endDateValidation];

    const dateRangeFormGroup = this.formBuilder.group({
      startDate,
      endDate,
    });
    if (!this.showEndDate) {
      dateRangeFormGroup.get('endDate').disable();
    }

    this.parentForm.form.addControl('dateRangeForm', dateRangeFormGroup);
    this.dateRangeForm = dateRangeFormGroup;
    this.cdr.markForCheck();
  }
}
