<dgx-action-button
  *ngIf="!isSearchResultCard; else searchResultButton"
  (click)="openAcademy()"
  [size]="size"
  [a11yContext]="'Core_AcademyView' | translate"
  dgatInput="view-academy-button"
>
  {{ 'Core_AcademyView' | translate }}
</dgx-action-button>

<ng-template #searchResultButton>
  <a
    df-button
    df-button-small
    dfButtonType="passive"
    [href]="buildAcademyUrl(url)"
    class="search-result-card__button"
  >
    {{ 'Core_AcademyView' | translate }}
  </a>
</ng-template>
