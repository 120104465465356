import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AccomplishmentFormDataModel,
  AccomplishmentMappingToAPI,
  AccomplishmentModel,
} from '@app/user-content/user-outcome-v2/outcomes/accomplishment/accomplishment.model';

@Injectable()
export class AccomplishmentMapperService {
  constructor(public datePipe: DatePipe) {}

  public toApiParameters(
    source: AccomplishmentModel
  ): AccomplishmentMappingToAPI {
    const { title, noExpiration, dateRangeForm, skills, userOutcomeId } =
      source;

    return {
      contentTypeId: 'Accomplishment',
      title,
      details: { noExpiration },
      startDate: this.datePipe.transform(dateRangeForm.startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(dateRangeForm.endDate, 'yyyy-MM-dd'),
      tags: skills,
      userOutcomeId,
    };
  }

  public toViewModel(
    source: AccomplishmentMappingToAPI
  ): AccomplishmentFormDataModel {
    const { title, startDate, endDate, details, tags, userOutcomeId } = source;

    return {
      title,
      noExpiration: details ? details?.noExpiration : false,
      dateRangeForm: {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      },
      skills: tags,
      userOutcomeId,
    };
  }
}
