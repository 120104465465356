import { TrackerService } from '@app/shared/services/tracker.service';
import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AuthUser, OrgInfo } from '@app/account/account-api.model';
import { RouterComponents } from '@app/orgs/constants';
import { OrgMemberModalService } from '@app/orgs/services/org-member-modal.service';
import {
  DataColumn,
  DataColumnDate,
  DataColumnList,
} from '@app/shared/components/data-table/data-table.d';
import { AuthService } from '@app/shared/services/auth.service';
import { ContextService } from '@app/shared/services/context.service';
import { MembersService } from '@app/shared/services/members.service';
import { TranslateService } from '@ngx-translate/core';
import { EditMemberModalEvent } from '../edit-member-modal/edit-member-modal.component';
import {
  OrganizationUserCounts,
  OrgMemberSummary,
  UserSort,
} from '../../services/orgs.model';
import { OrgViewBaseComponent } from './../org-view-base/org-view-base.component';

@Component({
  selector: 'dgx-org-members',
  templateUrl: './org-members.component.html',
})
export class OrgMembersComponent
  extends OrgViewBaseComponent
  implements OnInit
{
  @ViewChild('profile', { static: true })
  public profileTemplate: TemplateRef<any>;
  @ViewChild('editMember', { static: true })
  public editTemplate: TemplateRef<any>;
  @ViewChild('roleDisplay', { static: true })
  public roleDisplayTemplate: TemplateRef<any>;
  @ViewChild('privacyDisplay', { static: true })
  public privacyDisplayTemplate: TemplateRef<any>;
  @ViewChild('pointsDisplay', { static: true })
  public pointsDisplayTemplate: TemplateRef<any>;
  @ViewChild('statusDisplay', { static: true })
  public statusDisplayTemplate: TemplateRef<any>;

  @Input() public canEditPermissions: boolean;
  @Input() public canInviteMembers: boolean;

  public columns: (DataColumn | DataColumnDate | DataColumnList)[] = [];
  public hasMoreItems: boolean;
  public hasNoResults: boolean;
  public isDescending: boolean = false;
  public isLoading: boolean;
  public isSorting: boolean;
  public isStandaloneOrg: boolean;
  public memberCounts: OrganizationUserCounts;
  public visibleMembersTotal: number = 0;
  public members: OrgMemberSummary[];
  public orderBy: UserSort = 'name';
  public pageNum: number = 1;
  public searchTerm: string;
  public take: number = 10;

  public readonly CLASS_NAME = RouterComponents.ORG_MEMBERS;

  public readonly i18n = this.translateService.instant([
    'OrgMembersCtrl_MemberSettingsText',
    'OrgMembersCtrl_Private',
    'OrgMembersCtrl_PrivateToOrg',
    'OrgMembersCtrl_PrivateTooltip',
    'OrgMembersCtrl_Public',
    'OrgMembersCtrl_PublicToOrgTooltip',
    'OrgMembersCtrl_PublicTooltip',
  ]);

  private readonly authUser: AuthUser = this.authService.authUser;
  private orgInfo: OrgInfo;
  private skip: number = 0;

  private readonly roleStringsTranslated = this.translateService.instant([
    'Core_RoleAdmin',
    'Core_RoleCareerProfessional',
    'Core_RoleLearningProfessional',
    'Core_RoleManager',
    'Core_RoleMember',
    'Core_RoleTechnicalAdmin',
  ]);

  private readonly statusStringsTranslated = this.translateService.instant([
    'Core_Disabled',
    'Core_Active',
    'Core_PasswordLocked',
    'Core_PasswordReset',
  ]);

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private contextService: ContextService,
    private membersService: MembersService,
    private orgMemberModalService: OrgMemberModalService,
    private translateService: TranslateService,
    private trackerService: TrackerService,
    private ngZone: NgZone
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.contextService.urlInChannel()) {
      this.orgInfo = this.authUser.defaultOrgInfo;
    } else {
      this.orgInfo = this.authUser.orgInfo.find(
        (x) => x.organizationId === this.orgId
      );
      this.isStandaloneOrg = this.orgInfo.type !== 'Tenant';
    }

    this.columns = this.getColumns();
    this.getMemberCount();
    this.getMembers();

    this.trackerService.trackEventData({
      action: 'Org People Viewed',
      properties: {
        ActionLocation: 'Manage Org People',
        context: this.contextService.urlInChannel() ? 'channel' : 'org',
      },
    });
  }

  /**
   * Handles updates made to the search input field
   *
   * @param term - the term to search by
   */
  public doSearch(term?: string): void {
    /**
     * PD-86295
     * Regular markForCheck and detectChanges don't work here, so this is
     *   needed to get the modal to completely load in some cases
     */
    this.ngZone.run(() => {
      this.searchTerm = term;
      this.orderBy = null; // Searching with an initial order by reduces relevancy on the search term.  On new search, remove ordering
      this.reset();
      this.getMembers();

      if (term) {
        this.trackerService.trackEventData({
          action: 'Org People Searched',
          properties: {
            context: this.contextService.urlInChannel() ? 'channel' : 'org',
          },
        });
      }
    });
  }

  /**
   * Opens modal to begin editing an organization user.
   * Currently, the UI only updates when a user is removed from an org.  Updates are simply ignored at the moment.
   *
   * @param member - the member to edit
   */
  public openMember(member: OrgMemberSummary): void {
    this.orgMemberModalService
      .showEditMemberModal(this.orgId, member)
      .subscribe((result) => {
        if (result.event === EditMemberModalEvent.DELETE) {
          this.handleMemberDelete(member);
        }

        if (result.event === EditMemberModalEvent.UPDATE) {
          member.role = result.memberRole?.name || member.role;
        }
      });
  }

  /**
   * Returns string translation for a given role
   *
   * @param role
   */
  public getRoleString(role: string): string {
    return role
      ? this.roleStringsTranslated[`Core_Role${role.replace(/\s/g, '')}`]
      : '';
  }

  /**
   * Returns string translation for a given status
   *
   * @param status
   */
  public getStatusString(status: string): string {
    if (status === 'Pending') {
      return this.statusStringsTranslated.Core_PasswordReset;
    } else {
      return this.statusStringsTranslated[`Core_${status}`];
    }
  }

  /**
   * Loads members from the server.  Handles infinite scroll loading.
   */
  public getMembers(): void {
    this.isLoading = true;
    this.members = [];
    this.membersService
      .getMembers({
        orgId: this.orgId,
        searchTerm: this.searchTerm,
        skip: this.skip,
        take: this.take,
        orderBy: this.orderBy,
        sortDescending: this.isDescending,
      })
      .subscribe((response) => {
        this.members = response.items;
        this.hasNoResults = this.skip === 0 && response.items?.length === 0;
        this.hasMoreItems = response.hasMoreItems;
        this.skip += this.take;
        this.visibleMembersTotal = response.totalCount;
        this.isSorting = false;
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  /**
   * Handles updates made to column sorting
   *
   * @param sortColumn - current column being sorted
   */
  public updateSort(sortColumn: string): void {
    // disallow sort-updates while loading
    if (this.isLoading || this.isSorting) {
      return;
    }
    this.pageNum = 1;
    this.skip = 0;

    // check to see if we're only swapping from ascending to descending
    if (this.orderBy === (sortColumn as UserSort)) {
      this.isDescending = !this.isDescending;
    } else {
      this.orderBy = sortColumn as UserSort;
      this.isDescending = false;
    }

    this.isSorting = true;
    this.getMembers();
  }

  public updatePagination(event) {
    const { currentPage, pageSize } = event;

    if (currentPage) {
      this.skip = (currentPage - 1) * this.take;
      this.pageNum = currentPage;
    }

    if (pageSize) {
      this.take = pageSize;
      this.skip = 0;
    }

    this.getMembers();
  }

  /**
   * Handles updating the UI after a member is removed from an organization
   *
   * @param member
   */
  private handleMemberDelete(member: OrgMemberSummary): void {
    const index = this.members.indexOf(member);
    if (index !== -1) {
      this.members.splice(index, 1);
      this.skip -= 1; // decrement skip
    }
  }

  /**
   * Gets organization member count from API
   */
  private getMemberCount(): void {
    this.membersService.getMemberCount(this.orgId).subscribe((counts) => {
      this.memberCounts = counts;
    });
  }

  /**
   * Reset to intiial state.  Invoked when user enters a new search term
   */
  private reset(): void {
    this.skip = 0;
  }

  /**
   * Returns columns displayed in DataTable
   */
  private getColumns(): DataColumn[] {
    return [
      {
        label: 'OrgMembersCtrl_Name',
        template: this.profileTemplate,
        headClasses: 'l_w25',
        canSort: true,
        sortName: 'name',
      },
      {
        label: 'OrgMembersCtrl_Role',
        template: this.roleDisplayTemplate,
        headClasses: 'l_w15',
        canSort: true,
        sortName: 'role',
      },
      {
        hide: !this.canEditPermissions,
        label: 'OrgMembersCtrl_Visibility',
        headClasses: 'l_w15',
        canSort: true,
        sortName: 'privacy',
        template: this.privacyDisplayTemplate,
      },
      {
        label: 'OrgMembersCtrl_LastActive',
        headClasses: 'l_w15',
        prop: 'lastActiveFormatted',
        canSort: true,
        sortName: 'lastLogin',
      },
      {
        label: 'OrgMembersCtrl_Points',
        headClasses: 'l_w5 right-text guts-p-l-half guts-p-r-3',
        bodyClasses: 'right-text guts-p-r-3-half',
        hide:
          this.orgInfo.settings.skillInventoryClient ||
          this.orgInfo.settings.skillAnalyticsClient,
        template: this.pointsDisplayTemplate,
        canSort: true,
        sortName: 'points',
      },
      {
        label: 'Core_AccountStatus',
        headClasses: 'l_w15',
        template: this.statusDisplayTemplate,
      },
      {
        label: 'Core_Actions',
        headClasses: 'l_w5 a11y-hide-text',
        template: this.editTemplate,
      },
    ];
  }
}
