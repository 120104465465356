import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RatingBreakdown } from './tag-rating-summary-breakdown.model';

@Component({
  selector: 'dgx-tag-rating-summary-breakdown',
  templateUrl: './tag-rating-summary-breakdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingSummaryBreakdownComponent {
  @Input() public breakdown: RatingBreakdown[];
}
