<div
  class="rel guts-p-b-1 guts-p-t-half"
  [ngClass]="{ l_flexbar: !alignTop, l_flex: alignTop === true }"
>
  <div>
    <a
      *ngIf="item.linkUrl || item.defaultAction; else iconContainer"
      [href]="item.linkUrl || ''"
      tabindex="-1"
      (click)="onClick($event)"
      data-dgat="list-item-7cc"
      aria-hidden="true"
    >
      <ng-container *ngTemplateOutlet="iconContainer"></ng-container>
    </a>

    <!--icon placeholder. Note the [icon] selector which is how the parent differentiates which content it provides goes where-->
    <ng-template #iconContainer>
      <div class="icon-container">
        <ng-content select="[icon]"></ng-content>
      </div>
    </ng-template>
  </div>
  <div class="guts-p-r-half min-width-0 par par--small">
    <div class="l_flexbar font-semibold">
      <a
        *ngIf="item.linkUrl || item.defaultAction"
        [href]="item.linkUrl || ''"
        class="block clamp uhover"
        (click)="onClick($event)"
        data-dgat="list-item-956"
        [attr.role]="!item.linkUrl ? 'button' : undefined"
        >{{ item.title }}</a
      >
      <span *ngIf="!item.linkUrl && !item.defaultAction" class="block clamp">{{
        item.title
      }}</span>
      <img
        class="block guts-m-l-half tag-endorsed-img v-bottom"
        *ngIf="endorsedSrc"
        src="{{ endorsedSrc }}?brandlogo"
        attr.alt="{{ 'Core_Endorsed' | translate }}"
      />
    </div>
    <p class="par--light clamp">{{ item.description }}</p>
  </div>
  <div class="l_flex--align-right" [ngbTooltip]="item.explainText">
    <!--button (or other end component) placeholder-->
    <ng-content select="[button]"></ng-content>
  </div>
</div>
