import { Injectable } from '@angular/core';
import { EventFormModel } from '../event-modal.facade';
import { EventApiEntity } from '../repository/user-event.entity.model';
import { Mapper } from '../../mapper.model';
import { ImageUrlService } from '../../services/image-url.service';

@Injectable({ providedIn: 'root' })
export class ContentCatalogEventMapper
  implements Mapper<EventFormModel, EventApiEntity>
{
  constructor(private imageUrlService: ImageUrlService) {}
  /**
   * from api to initFromModel
   */
  public toViewModel(source: EventApiEntity): Partial<EventFormModel> {
    const groups = source.orgContentMetadata?.groupIds
      ? source.orgContentMetadata.groupIds.map((g) => {
          return { groupId: g.id, name: g.name, isRestricted: g.isRestricted };
        })
      : [];
    const data = {
      ...source,
      isVisibleToOrg: !groups.some((g) => g.isRestricted),
      groups,
      fileManaged: source.fileManaged,
      groupIds: groups,
      title: source?.name,
      eventUrl: source?.url ?? source?.eventUrl,
      orgContentMetadata: {
        groupIds: source?.groupIds ?? [],
        hideFromCatalog: false,
      },
      sessionDetails:
        source.sessions?.length > 0 ? { ...source?.sessions[0] } : undefined,
      createdByName: source.createdByName ?? (source.createdBy as string),
      durationHours: source.durationHours,
      durationMinutes: source.durationMinutes,
    };
    return data;
  }

  /**
   * From createResult to Api
   */
  public toApiParameters(source: EventFormModel): EventApiEntity {
    const imageUrlData = source.imageUrl as any;
    const imageUrl = this.imageUrlService.getImageUrl(imageUrlData);

    //TODO: Cleanup
    // Currently groupIds is only getting set if the adv section of the form gets opened
    // Make sure it's set if it's not already set and something is in groups in order to not lose data.
    // group/groupId's needs a refactor so that we don't have to do this juggling of data
    // PD-79285 [Bug]
    if (!source.groupIds && source.groups) {
      source.groups = source.groupIds;
    }

    delete source.expandAdvanced$;
    delete source.shouldShowSubmitButton$;
    delete source.toggleAdvanced;
    delete source.shouldSpinSubmitButton$;
    delete source.shouldShowResults$;
    delete source.submissionStatus$;
    const data = {
      ...source,
      inputId: source.inputId ?? source.eventId,
      eventId: source.inputId ?? source.eventId,
      inputType: source.inputType ?? 'Event',
      scenario: 'CMS',
      sessions: [{ ...source.sessionDetails }],
      name: source.title,
      eventUrl: source.url ?? source.eventUrl,
      url: source.url ?? source.eventUrl,
      orgContentMetadata: {
        groupIds: source.groupIds,
        hideFromCatalog: false,
      },
      imageUrl,
      resourceImageId: imageUrlData?.resourceImageId,
      durationHours: source.durationHours != 0 ? source.durationHours : null,
      durationMinutes:
        source.durationMinutes != 0 ? source.durationMinutes : null,
    } as EventApiEntity;
    return data;
  }
}
