import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dgxIframeTracker]',
})
export class IframeTrackerDirective {
  constructor(private element: ElementRef) {
    element.nativeElement.onload = () => {
      (window as any).iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
        },
        this.element.nativeElement
      );
    };
  }
}
