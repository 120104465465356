import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs/operators';
import { ModalService } from './../../../shared/services/modal.service';
import { OrgFaqService } from './../../services/org-faq.service';
import { OrgFaq } from '../../services/orgs.model';

@Component({
  selector: 'dgx-org-faq-entry',
  templateUrl: './org-faq-entry.component.html',
  styleUrls: ['./org-faq-entry.component.scss'],
})
export class OrgFaqEntryComponent implements OnInit {
  // bindings
  @Input() public faq: OrgFaq;
  @Input() public viewOnly: boolean = true;
  @Input() public disableEdit: boolean = false;

  @Output() public faqDeleted: EventEmitter<OrgFaq> = new EventEmitter();
  @Output() public faqEditing: EventEmitter<boolean> = new EventEmitter();
  @Output() public faqCreated: EventEmitter<OrgFaq> = new EventEmitter();

  public inEditMode: boolean = false;
  public faqForm: FormGroup;
  public editFaqModel: OrgFaq;
  public i18n = this.translate.instant([
    'Core_Cancel',
    'Core_NotSpecified',
    'Core_SaveChanges',
    'Core_YesSure',
    'OrgManage_FAQ_Answer',
    'OrgManage_FAQ_AnswerPlaceholder',
    'OrgManage_FAQ_ConfirmDelete',
    'OrgManage_FAQ_Delete',
    'OrgManage_FAQ_Edit',
    'OrgManage_FAQ_Question',
    'OrgManage_FAQ_QuestionPlaceholder',
  ]);

  public editLabel: string;
  public deleteLabel: string;

  @ViewChild('editTrigger') public editTrigger: ElementRef<HTMLElement>;

  constructor(
    private modal: ModalService,
    private orgFaq: OrgFaqService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.faqForm = new FormGroup({
      answer: new FormControl(),
      question: new FormControl(),
    });

    this.editFaqModel = { ...this.faq };

    if (!this.faq.faqId) {
      this.inEditMode = true;
    }

    this.editLabel = this.translate.instant('A11y_Edit_Format', {
      item: this.faq?.question
        ? this.faq?.question
        : this.i18n.Core_NotSpecified,
    });

    this.deleteLabel = this.translate.instant('A11y_Delete_Format', {
      item: this.faq?.question
        ? this.faq?.question
        : this.i18n.Core_NotSpecified,
    });
  }

  public toggleEditMode($event: Event): void {
    this.inEditMode = !this.inEditMode;
    this.faqEditing.emit(this.inEditMode);

    if (!this.inEditMode && !this.faq.faqId) {
      this.faqDeleted.emit(this.faq); // cancelled adding a new FAQ, need to remove it from the parent list.
    } else if (!this.inEditMode) {
      this.editFaqModel = { ...this.faq }; // cancelled editing an FAQ, reset the values so they don't persist
      setTimeout(() => this.editTrigger?.nativeElement.focus(), 0);
    }
  }

  public submit($event: any): void {
    const apiCall = this.editFaqModel.faqId
      ? this.orgFaq.updateFaq(this.editFaqModel)
      : this.orgFaq.addFaq(this.editFaqModel).pipe(
          tap((newFaq) => {
            this.faqCreated.emit(newFaq);
          })
        );

    apiCall
      .pipe(
        tap((updatedFaq) => {
          this.inEditMode = !this.inEditMode;
          this.faq = updatedFaq;
          this.faqEditing.emit(false);
          setTimeout(() => this.editTrigger?.nativeElement.focus(), 0); // if we're creating a new faq, there won't be an edit trigger
        })
      )
      .subscribe();
  }

  public delete($event: any): any {
    const inputs = {
      bodyText: `<span class="font-semibold">${this.i18n.OrgManage_FAQ_Question}</span><br>${this.faq.question}<br><br><span class="font-semibold">${this.i18n.OrgManage_FAQ_Answer}</span><br>${this.faq.answer}<br><br>${this.i18n.OrgManage_FAQ_ConfirmDelete}`,
      canCancel: true,
      headerText: this.i18n.OrgManage_FAQ_Delete,
      submitButtonType: 'destructive',
      submitButtonText: this.i18n.Core_YesSure,
    };
    return this.modal
      .show<void>(SimpleModalComponent, {
        inputs,
      })
      .pipe(
        switchMap(() => {
          return this.orgFaq.deleteFaq(this.faq);
        }),
        tap(() => {
          this.faqDeleted.emit(this.faq);
        })
      )
      .subscribe();
  }
}
