import { Inject, Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ResourceVersionTypeService } from './resource-version-type.model';
import { WindowToken } from '@app/shared/window.token';
import { catchError, throwError } from 'rxjs';
import { EventBus } from '@dg/shared-services';
import { ACTIONS } from '@app/pathways/rsm/utils';

@Injectable({
  providedIn: 'root',
})
export class PathwayVersionService implements ResourceVersionTypeService {
  constructor(
    private http: NgxHttpClient,
    private eventBus: EventBus,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public getVersion(id: number) {
    return this.http
      .get<string>('/pathways/getPathwayVersion', {
        params: { pathId: id },
      })
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public reloadInformation() {
    return this.eventBus.announce(ACTIONS.refreshPathway());
  }
}
