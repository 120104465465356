<dgx-search-field-wrapper [type]="type">
  <label class="a11y-hide" for="orgSearch">{{ placeholder }}</label>
  <input
    [autocomplete]="autoComplete"
    [formControl]="searchField"
    [placeholder]="placeholder"
    id="orgSearch"
    minlength="{{ minLength }}"
    type="search"
    [disabled]="isDisabled"
    data-dgat="data-search-025"
  />
</dgx-search-field-wrapper>
