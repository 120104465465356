import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AutoSaveService {
  public i18n = this.translate.instant(['Core_Saving', 'Core_ChangesSaved']);

  constructor(
    private translate: TranslateService,
    private toastrService: ToastrService
  ) {}

  public saving() {
    this.toastrService.show(`${this.i18n.Core_Saving} &hellip;`, null, {
      closeButton: false,
      disableTimeOut: true,
      enableHtml: true,
      toastClass: 'ngx-toastr ngx-toastr__auto-save',
      messageClass: 'ngx-toastr__auto-save--message',
      positionClass: 'toast-bottom-right',
    });
  }

  public saved() {
    this.close();
    this.toastrService.show(this.i18n.Core_ChangesSaved, null, {
      closeButton: false,
      toastClass: 'ngx-toastr ngx-toastr__auto-save',
      messageClass: 'ngx-toastr__auto-save--message',
      positionClass: 'toast-bottom-right',
    });
  }

  public close() {
    this.toastrService.clear();
  }
}
