<div class="org-faq-empty center-text">
  <img class="guts-m-t-2 guts-m-b-2" [src]="imageSource" [srcset]="srcset" />
  <h2 class="h1 center-text guts-m-b-2">
    {{
      (canEditFaq ? 'OrgManage_FAQ_CreateFAQPage' : 'OrgManage_FAQ_NoFAQs')
        | translate
    }}
  </h2>
  <p
    *ngIf="canEditFaq"
    class="org-faq-empty__description guts-m-b-2 par center-item"
  >
    {{ 'OrgManage_FAQ_CreateFAQPageInstructions' | translate }}
  </p>
</div>
