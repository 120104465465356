import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dgxCurrency',
})
export class DgxCurrencyPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}
  // this filter keeps everything as USD because that's all Degreed is interested in currently
  public transform(
    amount: number,
    fractionSize: number,
    flatten: boolean = false
  ): string {
    let currencyString: string;
    const isNegative = amount < 0;
    const defaultFraction = 2;

    if (!isNaN(+fractionSize)) {
      fractionSize = defaultFraction;
    }

    if (flatten === true) {
      fractionSize = amount.toString().indexOf('.') > -1 ? fractionSize : 0;
    }

    currencyString =
      '<span title="' +
      this.translate.instant('Core_Usd') +
      '">' +
      this.currencyPipe.transform(amount, 'USD', 'symbol', `.${fractionSize}`) +
      '</span>';
    return currencyString;
  }
}
