import { InternalTagRatingTypes } from './../../tags';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { getTagLevelLabel } from '@app/shared/utils/tag-helpers';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-provider-level',
  templateUrl: './tag-rating-provider-level.component.html',
  styleUrls: ['./tag-rating-provider-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingProviderLevelComponent implements OnChanges {
  @Input() public rating: TagsApi.UserTagRating;
  @Input() public checkpointCount?: number = 0;

  public i18n: { [key: string]: string } = {};
  public level: string | number;

  public get checkpointRatings() {
    return this.translate.instant('dgTagRating_NumberOfRatings', {
      numberOfRatings: this.checkpointCount,
    });
  }

  constructor(private translate: TranslateService) {}

  public ngOnChanges({ rating }: SimpleChanges): void {
    if (rating.currentValue.isInternal) {
      const ratingLevel =
        rating.currentValue.type === InternalTagRatingTypes.peer
          ? rating.currentValue.averageLevel
          : rating.currentValue.level;

      this.i18n.dgTagRating_Level = this.translate.instant(
        'dgTagRating_Level',
        {
          Level: ratingLevel,
        }
      );
    }

    this.level = rating.currentValue.isInternal
      ? this.i18n.dgTagRating_Level
      : getTagLevelLabel(this.rating);
  }
}
