import { Inject, Injectable } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import {
  PathwayAddEditFormOptions,
  PathwaySummaryConfig,
} from '@app/pathways/pathway-api.model';
import { Observable } from 'rxjs';
import { WindowToken } from '@app/shared/window.token';
import { PathwayAddEditFormModalComponent } from '../components/settings/pathway-add-edit-form-modal/pathway-add-edit-form-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PathwayAddEditFormModalService {
  constructor(
    private modalService: ModalService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public openModal(
    options: PathwayAddEditFormOptions,
    fromExternal = false
  ): Observable<PathwaySummaryConfig> {
    if (fromExternal) {
      this.openModalOnProfilePathways(options);
      return;
    }
    const modalOptions = {
      backdropClickClose: true,
      inputs: { options },
    };
    return this.modalService.show(
      PathwayAddEditFormModalComponent,
      modalOptions
    );
  }

  private openModalOnProfilePathways(options: PathwayAddEditFormOptions) {
    const url = `${options.baseUrl}/me/dashboard/pathways?newpath=true&inputId=${options.itemToAdd.inputId}&inputType=${options.itemToAdd.inputType}`;
    const win = this.windowRef.open(url, '_blank');
    win.focus();
  }
}
