import { TranslateService } from '@ngx-translate/core';
import { ContextService } from '@app/shared/services/context.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { Injectable } from '@angular/core';
import {
  OrganizationUserCounts,
  OrgMemberSummary,
  UserSort,
} from '@app/orgs/services/orgs.model';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DgError } from '../models/dg-error';

/**
 * Intended to house shared methods for handling 'members',
 * using same params but different endpoints.
 *
 * See: {@link OrgMembersService}, {@link ChannelService}.
 */
@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(
    private contextService: ContextService,
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  /**
   * Gets list of organization members that match given search parameters
   *
   * @param searchParams
   */
  public getMembers(
    searchParams: {
      orgId: number;
      searchTerm: string;
      skip: number;
      take: number;
      orderBy: UserSort;
      sortDescending: boolean;
    },
    localContext?: 'org' | 'channel' | 'tenant'
  ): Observable<{
    items: OrgMemberSummary[];
    hasMoreItems: boolean;
    totalCount: number;
  }> {
    let api: string;
    switch (localContext || this.contextService.workingInTenant()) {
      case 'org':
      case 'channel':
      case false:
        api = '/organizations/members';
        break;
      case 'tenant':
      case true:
      default:
        api = '/channel/tenantMembers';
        break;
    }

    return this.http
      .get<{ items: OrgMemberSummary[]; hasMoreItems: boolean }>(api, {
        params: {
          id: searchParams.orgId,
          nameFilter: searchParams.searchTerm,
          skip: searchParams.skip,
          count: searchParams.take,
          orderBy: searchParams.orderBy,
          sortDescending: searchParams.sortDescending,
        },
      })
      .pipe(catchAndSurfaceError(this.translate.instant('OrgSvc_OrgError')));
  }

  /**
   * Gets organization member counts
   *
   * @param orgId
   */
  public getMemberCount(orgId: number): Observable<OrganizationUserCounts> {
    return this.http
      .get<OrganizationUserCounts>('/organizations/memberCount', {
        params: {
          id: orgId,
        },
      })
      .pipe(catchAndSurfaceError(this.translate.instant('OrgSvc_OrgError')));
  }
}
