import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DfDgatFieldTypeDirective } from '../dgat-field-type/dgat-field-type.directive';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * A {@link DfTextareaComponent}-based component for Formly form multi-line text fields
 */
@Component({
  selector: 'df-form-textarea',
  template: `
    <textarea
      df-textarea
      [id]="id"
      [name]="to.name"
      [required]="to.required"
      [formControl]="control"
      [formlyAttributes]="field"
      [rows]="to.rows"
      [attr.aria-label]="to.ariaLabel || to.label"
      [attr.data-dgat]="to.dgatId"
      [attr.maxlength]="to.maxLength ? to.maxLength : undefined"
      [dfAutofocus]="to.shouldAutofocus"
      [readonly]="to.readonly"
      [attr.aria-describedby]="
        (to.ariaDescribedbyIds ? to.ariaDescribedbyIds : '') +
        (showErrors ? ' ' + id + '-error' : '')
      "
    ></textarea>
  `,
  styleUrls: ['./form-textarea.component.scss'],
})
export class DfFormTextareaComponent extends DfDgatFieldTypeDirective {
  public get showErrors() {
    return (
      this.field?.formControl?.invalid &&
      (this.field.formControl?.touched || this.field.formControl?.dirty)
    );
  }

  public get control() {
    return this.formControl as FormControl;
  }
}
