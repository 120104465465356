<div
  class="reactive-form__group session-details"
  [ngClass]="{ 'session-details--expanded': isExpanded }"
  [formGroup]="sessionDetailsForm"
  formGroupName="sessionDetails"
>
  <!-- Toggle -->
  <fieldset class="l_flexbar">
    <label for="toggleSessionDetails">
      <span class="session-details__form-title">{{
        i18n.InputSessionForm_AddSessionDetails
      }}</span>
    </label>
    <div class="l_flex--align-right">
      <dgx-toggle-switch
        [isChecked]="isExpanded"
        [disabled]="false"
        (toggled)="onToggleSessionDetails($event)"
        id="toggleSessionDetails"
      ></dgx-toggle-switch>
    </div>
  </fieldset>

  <hr *ngIf="isExpanded" class="session-details__divider" />

  <!-- Deletion Alert -->
  <div *ngIf="isEditing" aria-live="polite">
    <div *ngIf="!isExpanded" [@fadeInAndOut]>
      <df-local-notification
        class="block guts-m-t-1"
        [type]="NotificationType.warning"
        text="{{ i18n.InputSessionForm_DeletionAlert }}"
        size="small"
      ></df-local-notification>
    </div>
  </div>

  <!-- Expanded Session Details -->
  <div *ngIf="isExpanded" class="__expand-area" [@dfCollapseExpand]>
    <!-- Registration Section -->
    <fieldset
      class="session-details__fieldset"
      [ngClass]="{
        'session-details__fieldset--default-registration-url':
          sessionDetailsForm.get('isRegistrationAvailable').value &&
          sessionDetailsForm.get('isRegistrationUrlInputUrl').value,
        'session-details__fieldset--different-registration-url':
          sessionDetailsForm.get('isRegistrationAvailable').value &&
          !sessionDetailsForm.get('isRegistrationUrlInputUrl').value
      }"
    >
      <legend class="session-details__section-title">
        {{ i18n.InputSessionForm_Registration }}
      </legend>

      <!-- 'Registration Available' Checkbox -->
      <div class="session-details__checkbox-container">
        <input
          type="checkbox"
          id="isRegisteringAvailable"
          class="session-details__checkbox-input"
          formControlName="isRegistrationAvailable"
          data-dgat="session-details-87a"
        />
        <label
          class="form__label font-medium guts-m-b-0"
          for="isRegisteringAvailable"
        >
          {{ i18n.InputSessionForm_RegistrationAvailableNew }}
        </label>
      </div>

      <!-- Registration URL Radio Options -->
      <div
        *ngIf="sessionDetailsForm.get('isRegistrationAvailable').value"
        class="session-details__registration-options"
        [ngClass]="{
          'session-details__registration-options--expanded':
            !sessionDetailsForm.get('isRegistrationUrlInputUrl').value
        }"
        [@dfCollapseExpand]
      >
        <div>
          <div class="v-middle-children">
            <input
              type="radio"
              id="useInputUrl"
              [value]="true"
              formControlName="isRegistrationUrlInputUrl"
              class="guts-m-r-half"
              data-dgat="session-details-234h"
            />
            <label class="form__label font-medium guts-m-b-0" for="useInputUrl">
              {{ defaultUrlRegistrationRadioOption }}
            </label>
          </div>
          <div class="v-middle-children">
            <input
              type="radio"
              id="useDifferentUrl"
              [value]="false"
              formControlName="isRegistrationUrlInputUrl"
              class="guts-m-r-half"
              data-dgat="session-details-973b"
            />
            <label
              class="form__label font-medium guts-m-b-0"
              for="useDifferentUrl"
            >
              {{ i18n.InputSessionForm_DifferentUrl }}
            </label>
          </div>

          <!-- Registration URL Input -->
          <div
            *ngIf="!sessionDetailsForm.get('isRegistrationUrlInputUrl').value"
            [@dfCollapseExpand]
          >
            <div class="reactive-forms_control guts-m-t-1">
              <dgx-reactive-forms-label
                for="registrationUrl"
                [isRequired]="true"
              >
                {{ i18n.InputSessionForm_RegistrationUrl }}
              </dgx-reactive-forms-label>
              <input
                [ngClass]="{
                  'session-details__error':
                    sessionDetailsForm.get('registrationUrl').touched &&
                    sessionDetailsForm.get('registrationUrl').invalid
                }"
                type="url"
                id="registrationUrl"
                formControlName="registrationUrl"
                (blur)="onBlur('registrationUrl')"
                [placeholder]="'http://'"
                data-dgat="session-details-237f"
              />
              <dgx-validate-field
                class="block"
                *ngIf="
                  sessionDetailsForm.get('registrationUrl').touched &&
                  sessionDetailsForm.get('registrationUrl').invalid
                "
                [message]="i18n.Core_ValidUrl"
              ></dgx-validate-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <hr class="session-details__divider" />

    <!-- Location -->
    <fieldset class="__fieldset session-details__fieldset">
      <fieldset formGroupName="locationType">
        <legend class="session-details__location-legend">
          <span class="session-details__section-title">{{
            i18n.InputSessionForm_LocationType | titlecase
          }}</span>
          <span class="reactive-forms__label--required">{{
            'Core_Required' | translate
          }}</span>
        </legend>
        <div class="guts-p-t-quart v-middle-children">
          <input
            type="checkbox"
            id="isInPerson"
            formControlName="isInPerson"
            (change)="onFormFieldChange('isInPerson')"
            class="guts-m-r-quart"
            data-dgat="session-details-9234h"
          />
          <label for="isInPerson" class="font-medium guts-p-r-1">
            {{ i18n.InputSessionForm_InPerson }}
          </label>
          <input
            type="checkbox"
            id="isOnline"
            formControlName="isOnline"
            (change)="onFormFieldChange('isOnline')"
            class="guts-m-r-quart"
            data-dgat="session-details-239g"
          />
          <label for="isOnline" class="font-medium">
            {{ i18n.Core_Online }}
          </label>
        </div>
        <dgx-validate-field
          class="block"
          *ngIf="
            sessionDetailsForm.get('locationType').touched &&
            sessionDetailsForm.get('locationType').invalid
          "
          [message]="i18n.Core_FieldRequired"
        ></dgx-validate-field>
      </fieldset>
      <div
        *ngIf="sessionDetailsForm.get('locationType.isInPerson').value"
        [@dfCollapseExpand]
      >
        <div class="reactive-forms__control guts-p-t-1">
          <dgx-reactive-forms-label for="locationAddress" [isRequired]="true">{{
            i18n.InputSessionForm_Address
          }}</dgx-reactive-forms-label>
          <dgx-address-suggest
            [ngClass]="{
              'session-details__error':
                sessionDetailsForm.get('locationAddress').touched &&
                sessionDetailsForm.get('locationAddress').invalid
            }"
            id="locationAddress"
            formControlName="locationAddress"
            (change)="onFormFieldChange('locationAddress')"
            (select)="onAddressSelection($event)"
            (clear)="clearAddress()"
            [placeholder]="i18n.InputSessionForm_SearchAddress"
            [required]="true"
            [initialValue]="session?.locationAddress"
            dgatInput="session-details-973j"
            ariaDescribedby="locationAddressHelp"
          ></dgx-address-suggest>
          <dgx-validate-field
            class="block"
            *ngIf="
              sessionDetailsForm.get('locationAddress').touched &&
              sessionDetailsForm.get('locationAddress').invalid
            "
            [message]="i18n.Core_FieldRequired"
          ></dgx-validate-field>
        </div>
      </div>
      <div
        *ngIf="sessionDetailsForm.get('locationType.isOnline').value"
        [@dfCollapseExpand]
      >
        <div class="reactive-forms__control guts-p-t-1">
          <dgx-reactive-forms-label for="locationUrl" [isRequired]="true">{{
            i18n.InputSessionForm_LocationUrl
          }}</dgx-reactive-forms-label>
          <input
            [ngClass]="{
              'session-details__error':
                sessionDetailsForm.get('locationUrl').touched &&
                sessionDetailsForm.get('locationUrl').invalid
            }"
            type="url"
            id="locationUrl"
            formControlName="locationUrl"
            [placeholder]="'http://'"
            (change)="onFormFieldChange('locationUrl')"
            (blur)="onBlur('locationUrl')"
            data-dgat="session-details-7364h"
            aria-describedby="locationUrlLinkHelp"
          />
          <div class="form__help" id="locationUrlLinkHelp">
            {{ locationUrlDescription }}
          </div>
          <dgx-validate-field
            class="block"
            *ngIf="
              sessionDetailsForm.get('locationUrl').touched &&
              sessionDetailsForm.get('locationUrl').invalid
            "
            [message]="i18n.Core_ValidUrl"
          ></dgx-validate-field>
        </div>
      </div>
    </fieldset>

    <hr class="session-details__divider" />

    <!-- Date & Time -->
    <fieldset class="__fieldset session-details__fieldset">
      <legend class="session-details__section-title">
        {{ i18n.InputSessionForm_DateTimeNew }}
      </legend>
      <div class="l_flex guts-p-t-1 guts-p-b-1">
        <div class="m-guts-p-b-1 session-details__date-time-input-container">
          <dgx-reactive-forms-label for="startDate" [isRequired]="true">
            {{ i18n.Core_StartDate }}
          </dgx-reactive-forms-label>
          <div
            [ngClass]="{
              'session-details__error':
                sessionDetailsForm.get('startDate').touched &&
                sessionDetailsForm.get('startDate').invalid
            }"
            class="reactive-forms__control field-group session-details__date-wrapper"
          >
            <span class="field-group__input field-group__input--tag">
              <df-icon
                icon="calendar"
                class="guts-m-r-1 color-ebony-a61"
              ></df-icon>
              <input
                id="startDate"
                formControlName="startDate"
                type="text"
                class="field-group--autowidth"
                (blur)="onFormFieldChange('startDate')"
                #startDatePicker="ngbDatepicker"
                autocomplete="off"
                ngbDatepicker
                [firstDayOfWeek]="7"
                (click)="startDatePicker.toggle()"
                placeholder="yyyy-mm-dd"
                data-dgat="session-details-347j"
              />
            </span>
          </div>
          <dgx-validate-field
            class="block"
            *ngIf="
              sessionDetailsForm.get('startDate').touched &&
              sessionDetailsForm.get('startDate').invalid
            "
            [message]="i18n.InputSessionForm_InvalidDate"
          ></dgx-validate-field>
        </div>
        <div
          class="reactive-forms__control guts-p-l-1 m-guts-p-l-0 session-details__date-time-input-container"
        >
          <dgx-reactive-forms-label for="startTime" [isRequired]="true">
            {{ i18n.InputSessionForm_StartTime }}
          </dgx-reactive-forms-label>
          <input
            type="time"
            formControlName="startTime"
            id="startTime"
            [dgxMaxTime]="bounds.maxStartTime"
            (change)="onFormFieldChange('startTime')"
            data-dgat="session-details-239f"
          />
          <dgx-validate-field
            class="block"
            *ngIf="
              sessionDetailsForm.get('startTime').touched &&
              sessionDetailsForm.get('startTime').invalid
            "
            [message]="i18n.InputSessionForm_InvalidTimeNew"
          ></dgx-validate-field>
        </div>
      </div>
      <div class="l_flex guts-p-b-1">
        <div class="m-guts-p-b-1 session-details__date-time-input-container">
          <dgx-reactive-forms-label for="endDate" [isRequired]="true">
            {{ i18n.Core_EndDate }}
          </dgx-reactive-forms-label>
          <div
            [ngClass]="{
              'session-details__error':
                sessionDetailsForm.get('endDate').touched &&
                sessionDetailsForm.get('endDate').invalid
            }"
            class="reactive-forms__control field-group session-details__date-wrapper"
          >
            <span class="field-group__input field-group__input--tag">
              <df-icon
                icon="calendar"
                class="guts-m-r-1 color-ebony-a61"
              ></df-icon>
              <input
                id="endDate"
                formControlName="endDate"
                type="text"
                class="field-group--autowidth"
                (blur)="onFormFieldChange('endDate')"
                #endDatePicker="ngbDatepicker"
                autocomplete="off"
                ngbDatepicker
                [firstDayOfWeek]="7"
                [minDate]="minEndDate"
                (click)="endDatePicker.toggle()"
                placeholder="yyyy-mm-dd"
                data-dgat="session-details-932k"
              />
            </span>
          </div>
          <dgx-validate-field
            class="block"
            *ngIf="
              sessionDetailsForm.get('endDate').touched &&
              sessionDetailsForm.get('endDate').invalid
            "
            [message]="i18n.InputSessionForm_InvalidDate"
          ></dgx-validate-field>
        </div>
        <div
          class="reactive-forms__control guts-p-l-1 m-guts-p-l-0 session-details__date-time-input-container"
        >
          <dgx-reactive-forms-label for="endTime" [isRequired]="true">
            {{ i18n.InputSessionForm_EndTime }}
          </dgx-reactive-forms-label>
          <input
            type="time"
            id="endTime"
            formControlName="endTime"
            [dgxMinTime]="bounds.minEndTime"
            (change)="onFormFieldChange('endTime')"
            data-dgat="session-details-973d"
          />
          <dgx-validate-field
            class="block"
            *ngIf="
              sessionDetailsForm.get('endTime').touched &&
              sessionDetailsForm.get('endTime').invalid
            "
            [message]="i18n.InputSessionForm_InvalidTimeNew"
          ></dgx-validate-field>
        </div>
      </div>
      <div>
        <dgx-reactive-forms-label for="timeZoneId" [isRequired]="true">
          {{ i18n.InputSessionForm_Timezone }}
        </dgx-reactive-forms-label>
        <dgx-time-zone-picker
          id="timeZoneId"
          formControlName="timeZoneId"
          (select)="onFormFieldChange('timeZoneId')"
          [placeholder]="i18n.Core_Select"
          [timeZoneId]="sessionDetailsForm.get('timeZoneId').value"
          [required]="true"
          dgatInput="session-details-3708d"
        ></dgx-time-zone-picker>
        <dgx-validate-field
          class="block"
          *ngIf="
            sessionDetailsForm.get('timeZoneId').touched &&
            sessionDetailsForm.get('timeZoneId').invalid
          "
          [message]="i18n.Core_FieldRequired"
        ></dgx-validate-field>
      </div>
    </fieldset>
  </div>
</div>
