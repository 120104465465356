import { Injectable } from '@angular/core';

import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { catchError, map, tap } from 'rxjs/operators';

import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, Subject } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';
import { PathwayManageModel } from '@app/pathways/pathway-api.model';
import { SearchService } from '@app/search/services/search.service';

@Injectable({
  providedIn: 'root',
})
export class OrgPathwaysService {
  public i18n = this.translate.instant([
    'Core_GeneralErrorMessage',
    'Pathways_MakeFeatureNotification',
    'Pathways_MoveSuccessMessage',
    'Pathways_RemoveFeaturedNotification',
    'Pathways_SaveError',
  ]);
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public isNotFound$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private translate: TranslateService,
    private searchService: SearchService
  ) {}

  public getContainerPathways(
    term: string,
    skip: number,
    count: number,
    orderBy: number,
    sortDescending: boolean,
    organizationId: number
  ): Observable<{ hasMoreItems: boolean; pathways: PathwayManageModel[] }> {
    return this.getPathwaysApi(
      organizationId,
      term,
      skip,
      count,
      orderBy,
      sortDescending
    ).pipe(
      map((p) => {
        const pathways = p.pathways.map((p: PathwayManageModel) => {
          if (!p.publicUrl.endsWith('/pathway'))
            return {
              ...p,
              publicUrl: `${p.publicUrl}/pathway`,
            };
        });
        return {
          ...p,
          pathways,
        };
      })
    );
  }

  public featurePathway(pathId: number) {
    return this.http.post<void>('/pathways/featurepathway', { pathId }).pipe(
      tap((response) => {
        this.notifier.showSuccess(this.i18n.Pathways_MakeFeatureNotification);
        return response;
      }),
      catchError((error) => {
        return throwError(new DgError(this.i18n.Pathways_SaveError, error));
      })
    );
  }

  public unfeaturePathway(pathId: number) {
    return this.http.post<void>('/pathways/unfeaturepathway', { pathId }).pipe(
      tap((response) => {
        this.notifier.showSuccess(
          this.i18n.Pathways_RemoveFeaturedNotification
        );
        return response;
      }),
      catchError((error) => {
        return throwError(new DgError(this.i18n.Pathways_SaveError, error));
      })
    );
  }

  public movePathwayToChannel(pathwayId: number) {
    return this.http
      .get('/pathways/MovePathwayToChannel', {
        params: {
          pathwayId,
        },
      })
      .pipe(
        tap((response) => {
          this.notifier.showSuccess(this.i18n.Pathways_MoveSuccessMessage);
          return response;
        }),
        catchError((error) => {
          return throwError(
            new DgError(this.i18n.Core_GeneralErrorMessage, error)
          );
        })
      );
  }

  public bulkDeletePathways(pathwayIds) {
    return this.http.post<void>('/pathways/bulkdelete', {
      ItemIds: pathwayIds,
    });
  }

  public bulkEndorsePathways(pathwayIds, shouldEndorse) {
    return this.http.put<void>('/pathways/bulkUpdateEndorsed', {
      ItemIds: pathwayIds,
      IsEndorsed: shouldEndorse,
    });
  }

  public bulkVisibilityChange(pathwayIds, visibility, groupIdentifiers) {
    return this.http
      .put<void>('/pathways/bulkVisibilityChange', {
        ItemIds: pathwayIds,
        Visibility: visibility,
        GroupIdentifiers: groupIdentifiers,
      })
      .pipe(
        catchError((error) => {
          return throwError(new DgError(this.i18n.Pathways_SaveError, error));
        })
      );
  }

  public bulkAddCollaborators({ pathwayIds, userKeysToAdd }) {
    return this.http
      .put<void>('/pathways/bulkCollaboratorAdd', {
        ItemIds: pathwayIds,
        ToAddUserKeys: userKeysToAdd,
      })
      .pipe(
        catchError((error) => {
          return throwError(new DgError(this.i18n.Pathways_SaveError, error));
        })
      );
  }

  public deletePathway(pathId: number) {
    return this.http
      .delete('/pathways/deletepathway', { params: { pathId } })
      .pipe(
        catchError((error) =>
          throwError(
            new DgError(this.translate.instant('Pathways_DeleteError'), error)
          )
        )
      );
  }

  private getPathwaysApi(
    organizationId: number,
    term: string,
    skip: number,
    count: number,
    orderBy: number,
    sortDescending: boolean
  ): Observable<{ hasMoreItems: boolean; pathways: PathwayManageModel[] }> {
    return this.http.get<any>('/pathways/getorganizationpathways', {
      params: {
        organizationId,
        term,
        skip,
        count,
        orderBy,
        sortDescending,
      },
    });
  }
}
