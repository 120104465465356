import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { SubscriptionManagerService } from '@app/shared/services/subscription-manager.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DfDgatFieldTypeDirective, DfForeignFieldConfig } from '@lib/fresco';
import { ComboboxOption } from '@app/shared/components/combobox/combobox.model';
import { Observable } from 'rxjs';

export interface ComboboxFieldParams {
  /** optional id for the dgx-combobox */
  id?: string;
  /** optional placeholder for the dgx-combobox */
  placeholder?: string;
  /** label key for the dgx-combobox options */
  optionLabelKey?: string;
  /** optional item tracking key for the dgx-combobox options. If not provided and no @{link provideOption} value provided,
   * the selected item is used as the control value and vice versa.
   */
  optionTrackByKey?: string;
  /** Observable that emits the list of dgx-combobox options */
  options$: Observable<ComboboxOption[]>;
  /** Callback that should map the form control value to its corresponding option.  Use this when the data value is not the display value. */
  provideOption?: (controlValue: any) => string[] | ComboboxOption[];
  /** Callback that should map the selected option to its corresponding form control value. Use this when the selected display value is not what should be sent to the api call. */
  provideControlValue?: (selectedItems: ComboboxOption[]) => any;
  /** Overwrites the default text for no matching results in the list */
  hasNoResultsText?: string;
  /** Combobox option passthrough to use a multiselect version of the combobox instead of single-select. */
  isMultiSelect?: boolean;
}

export interface ComboboxFieldOption extends ComboboxOption {
  [key: string]: any;
}

@Component({
  selector: 'dgx-combobox-field',
  templateUrl: 'combobox-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionManagerService],
})
export class ComboboxFieldComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  /**
   * User's selections for display.  string only for single select, array for multi-select
   */
  public comboboxSelectedValue: string | ComboboxFieldOption[];
  /**
   * For displaying the list in the template
   */
  public comboboxOptions: ComboboxFieldOption[] = [];
  /**
   * Field type for registration with Formly
   */
  public static readonly REGISTERED_FIELD_TYPE = 'dgx-combobox';

  public get params(): ComboboxFieldParams {
    return (this.field as DfForeignFieldConfig)?.templateOptions.params ?? {};
  }

  public get selectedOptions(): ComboboxFieldOption[] {
    return this.comboboxSelectedValue as ComboboxFieldOption[];
  }
  public get selectedOption(): string {
    return this.comboboxSelectedValue as string;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private subscriptionManager: SubscriptionManagerService
  ) {
    super();
  }

  public ngOnInit() {
    // sets up the list
    this.params.options$
      .pipe(this.subscriptionManager.takeUntilDestroyed())
      .subscribe((o) => {
        this.comboboxOptions = o;
      });

    // set up initial selected value if it exists, and watch for changes for display updates
    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        distinctUntilChanged(),
        this.subscriptionManager.takeUntilDestroyed()
      )
      .subscribe((value) => {
        if (value) {
          const parsedVal = this.parseDisplayValue(value);

          // set the display of the combobox
          this.comboboxSelectedValue = parsedVal;
          this.cdr?.markForCheck();
        }
      });
  }

  /**
   * Takes in the value of the selection from the combobox and turns it into something
   * that can be displayed in the input field.  Handles both the multi-select mode and
   * single-select mode and will set the value based on what mode is being used.
   *
   * Defaults to the given value, otherwise if `provideOption` was provided in the
   * field params during the field setup, it will use that function to format the value
   * into either a string for single select or an array for multi-select to allow display.
   *
   * @param value
   */
  public parseDisplayValue(value) {
    let parsedVal = value;
    if (this.params?.provideOption) {
      const provideOptionVal = this.params.provideOption(value);
      // combobox-field will expect `provideOptions` to return an array value regardless
      // it uses the array if multi-select, or just return the first string value if not
      parsedVal = this.params.isMultiSelect
        ? provideOptionVal
        : provideOptionVal[0];
    }
    return parsedVal;
  }

  /**
   * Handles either a single or multi-select as an array value,
   * the individual option converts the value to an array before it
   * becomes an input here.
   *
   * @param selections
   */
  public handleSelection(selections: ComboboxFieldOption[]) {
    // if an option is needed to translate the array into a different value, use that
    // otherwise assign the value as the selection
    const val = this.params.provideControlValue?.(selections) ?? selections;

    if (val === undefined) {
      console.error(
        'dgx-combobox-field error: selection control value is invalid, check the setup of the field provideControlValue function or selection values'
      );
    }

    this.formControl.setValue(val);
    this.formControl.markAsDirty();
  }

  /**
   * Marks touch status for validation
   */
  public handleBlur() {
    this.formControl.markAsTouched();
  }
}
