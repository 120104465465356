import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Institution } from './course-form.model';

@Injectable({ providedIn: 'root' })
export class UserCourseService {
  public i18n = this.translate.instant(['UserCourseSvc_CourseError']);

  private inputType = 'Course';

  constructor(
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private translate: TranslateService,
    private tracker: TrackerService
  ) {}

  public Add(
    course: any,
    trackingArea: string,
    institutionId?: number,
    institutionName?: string
  ): Observable<any> {
    return this.http.post('/userinputs/addusercourse', course).pipe(
      map(
        (result: any) => {
          const data = result?.result || result;
          if (!course.InputId && data) {
            course.InputId = data.inputId;
            course.InputType = this.inputType;
          }
          course.InternalUrl = course.InternalUrl || data.internalUrl;
          this.tracker.trackEventData({
            action: 'Content Completed',
            category: this.inputType,
            label: null,
            properties: {
              ...this.cleanCourse(course),
              Location: trackingArea,
              SkillTagCount: course.Tags?.length,
              ProviderId: institutionId ?? 'None',
              ProviderName: institutionName ?? 'None',
            },
          });
          return result;
        },
        catchError((error) => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      )
    );
  }

  public AddExisting(
    input,
    trackingArea: string,
    institutionId?: number,
    institutionName?: string
  ): Observable<any> {
    return this.http.post('/userinputs/adduserexistingcourse', input).pipe(
      map(
        (result: any) => {
          this.tracker.trackEventData({
            action: 'Content Completed',
            category: this.inputType,
            label: null,
            properties: {
              ...this.cleanCourse(input),
              Location: trackingArea,
              SkillTagCount: input.Tags?.length,
              ProviderId: institutionId ?? 'None',
              ProviderName: institutionName ?? 'None',
            },
          });
          return result;
        },
        catchError((error) => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      )
    );
  }

  public Update(course): Observable<any> {
    return this.http.put('/userinputs/updateusercourse', course).pipe(
      map(
        (result: any) => {
          this.tracker.trackEventData({
            action: 'User Item Updated',
            category: this.inputType,
            label: null,
            properties: {
              ...this.cleanCourse(course),
              SkillTagCount: course.Tags?.length,
            },
          });
          return result;
        },
        catchError(() => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      )
    );
  }

  public Get(userCourseId): Observable<any> {
    return this.http
      .get('/userinputs/getusercourse', {
        params: { userCourseId: userCourseId },
      })
      .pipe(
        catchError(() => {
          this.notifier.showError(this.i18n.UserCourseSvc_CourseError);
          return of(this.i18n.UserCourseSvc_CourseError);
        })
      );
  }

  public getInstitution(institutionId: number) {
    return this.http
      .get<Institution>('/providers/getinstitutioninfo', {
        params: { institutionId: institutionId },
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          const errorMessage = this.translate.instant(
            'UserInputProvidersSvc_ProfileProvidersError'
          );
          return throwError(new DgError(errorMessage, e));
        })
      );
  }

  private cleanCourse = (inObj) => {
    inObj.Description = '';
    return inObj;
  };
}
