import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GroupIdentifier } from '@app/groups/group-api';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import {
  VisibilityItem,
  VisibilityOption,
} from '@app/shared/components/visibility/visibility.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { ButtonType } from '@app/shared/temporary-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-edit-visibility-modal',
  templateUrl: './edit-visibility-modal.component.html',
})
export class EditVisibilityModalComponent extends SubscriberBaseDirective {
  @Input() public visibility: VisibilityOption[] = [];
  @Input() public headerText? = '';
  @Input() public canCancel = true;
  @Input() public opportunity?: Opportunity;
  @Input() public submitButtonType?: ButtonType = 'primary';
  @Input() public submitButtonText? = this.translate.instant('Core_Submit');

  public groups: GroupIdentifier[] = [];
  public visibilityForm = this.formBuilder.group({
    groups: [],
    privacyId: [undefined, Validators.required],
  });

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  public onSubmit(): void {
    const privacyId = this.visibilityForm.get('privacyId').value;
    let groups = this.visibilityForm.get('groups').value;

    if (privacyId !== Visibility.groups) {
      groups = [];
    }

    this.activeModal.close({ groups, privacyId });
  }

  public handleVisibilitySelection($event: VisibilityItem): void {
    // TODO NGX 14 UPGRADE: Fix any type (Search)
    (this.visibilityForm as any).patchValue({ privacyId: $event.visibility });
    this.visibilityForm.markAsDirty();
  }

  public handleGroupSelection($event: GroupIdentifier): void {
    if (this.groups.some((g) => g.groupId === $event.groupId)) {
      return;
    }

    this.groups = [...this.groups, { ...$event }];
    this.visibilityForm.patchValue({ groups: [...this.groups] });
    this.visibilityForm.markAsDirty();
  }

  public handleGroupRemove($event: GroupIdentifier): void {
    this.groups = this.groups.filter(
      ({ groupId }) => $event.groupId !== groupId
    );

    this.visibilityForm.patchValue({ groups: [...this.groups] });
    this.visibilityForm.markAsDirty();
  }
}
