import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'dgx-check-duplicate-inputs',
  templateUrl: './check-duplicate-inputs.component.html',
})
export class CheckDuplicateInputsComponent implements OnInit {
  /** number of duplicates */
  @Input() public duplicateCount: number;
  /** translation string key that will be translated and appended to the error message */
  @Input() public decisionText?: string;
  /** event emitted when the view duplicated buttons is selected */
  @Output() public viewDuplicates: EventEmitter<void> = new EventEmitter();
  public i18n: any = {};
  constructor(private translate: TranslateService) {}
  public ngOnInit() {
    this.i18n.viewDuplicates = this.translate.instant(
      'checkDuplicateInputs_ViewDuplicates'
    );
    if (this.decisionText && this.decisionText.length > 0) {
      this.i18n.decision = ` ${this.translate.instant(this.decisionText)}`;
    }
    this.i18n.duplicatesFound = this.translate.instant(
      this.duplicateCount > 1
        ? 'checkDuplicateInputs_DuplicatesFoundPlural'
        : 'checkDuplicateInputs_DuplicatesFoundSingle',
      { count: this.duplicateCount }
    );
  }
}
