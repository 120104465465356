import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';

import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { VideoFormModel } from '../video-form.model.model';
import { RenderMode } from '../../user-input.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';

/** Creates an array of form field configurations for the initial UI (single URL field) when adding/editing Videos
 *  for pathways and user profile content */
@Injectable({ providedIn: 'root' })
export class ProfileEditVideoFormRenderer implements FormRenderer {
  public expandAdvanced = new BehaviorSubject(false);
  constructor(
    private builder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder
  ) {}

  public render(
    context: RendererContext<VideoFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();
    if (
      !(
        context.inputContext.isEditing &&
        context.inputContext.renderMode === RenderMode.UserProfile
      )
    ) {
      throw new Error('Wrong state for renderer');
    }

    return [
      // Read-only title field
      this.buildTitleField(context.templates.readonlyField),
      // User authored ("I made this video")
      this.buildUserAuthoredField(),
      // Skills
      this.inputFieldBuilder.buildSkillsField(vm.topTags, false), // we have a lot of fields to show in catalog mode so hide top tags for compactness
    ];
  }

  // Field builder helpers _______________________________

  private buildTitleField(
    readonlyTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    return this.builder
      .customField('title', 'Core_Title', readonlyTemplate)
      .build();
  }

  private buildUserAuthoredField() {
    return this.builder
      .checkbox('isUserAuthored', 'MediaFormCtrl_ICreatedVideo')
      .withDgatId('videoForm-0b8')
      .build();
  }
}
