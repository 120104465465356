import { Injectable } from '@angular/core';

import { LDFlagsService } from '@dg/shared-services';

/**
 * A centralized location for LD flags used by Opportunities, to help us
 * avoid too many API calls.
 *
 *  @deprecated - Use LDFLagsService
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunityFlagsService {
  /** Whether the new opportunity marketplace tab is enabled. */
  public readonly useOpportunityMarketplace: boolean =
    this.LDFLagsService.getFlag('opportunity-marketplace', false);

  /** Whether smarter matching is active. */
  public readonly useSmarterMatching: boolean = this.LDFLagsService.getFlag(
    'opportunities-smarter-matching-07072020',
    false
  );

  /** Whether the new opportunity browse page is active. */
  public readonly useNewOpportunityBrowse: boolean =
    this.LDFLagsService.getFlag('new-opportunity-browse-search', false);

  /** Whether to display the Advanced Group setting for enabling opportunities. */
  public readonly useEnableOpportunitySettingForGroup: boolean =
    this.LDFLagsService.getFlag(
      'opp-marketplace-ops-tool-group-visibility',
      false
    );

  /** Whether to update the close opportunity modal with new reject message */
  public readonly useRejectMessageEmail: boolean = this.LDFLagsService.getFlag(
    'opportunity-rejection-email',
    false
  );

  /** Whether to update the close opportunity modal with new reject message */
  public readonly useCompleteMentorshipEnhancements: boolean =
    this.LDFLagsService.getFlag('complete-mentorship-enhancements', false);

  constructor(private LDFLagsService: LDFlagsService) {}
}
