<div class="guts-p-b-1">
  <h3 class="pathway-section-nav__title" tabindex="-1">
    {{ pathway.title }}
  </h3>
</div>
<div class="pathway-section-nav">
  <ol class="pathway-section-nav__sections">
    <ng-container
      *ngFor="
        let section of pathway.levels;
        trackBy: trackById;
        let first = first;
        let last = last;
        index as sectionIndex
      "
      data-dgat="pathway-section-nav-060"
    >
      <li
        *ngIf="shouldShowNavSection(section)"
        class="pathway-section-nav__item guts-p-h-1-half"
        [ngClass]="{
          'pathway-section-nav__item--border-bottom': !last,
          'guts-p-t-1-half': first
        }"
        data-dgat="pathway-section-nav-060"
      >
        <!-- Vertical line spacer added to top of all sections but the first one -->
        <div
          class="pathway-section-nav__vertical-line pathway-section-nav__vertical-line--spacer-top"
          *ngIf="sectionIndex > 0"
        ></div>
        <div
          class="pathway-section-nav__vertical-line pathway-section-nav__section-menu"
          [ngClass]="{
            'pathway-section-nav__section-menu--last':
              !isActiveSection(section.number) && last
          }"
        >
          <div class="pathway-section-nav__section-menu--progress-items">
            <dgx-progress-circle
              *ngIf="section.progress < 100"
              [percent]="section.progress"
              [config]="{
                color: progressCircleInProgress,
                radius: 8
              }"
              [customAriaLabel]="
                inProgressAriaLabel(section.completedSteps, section.totalSteps)
              "
            >
            </dgx-progress-circle>
            <img
              *ngIf="section.progress === 100"
              [alt]="i18n?.ProfilePathways_Completed"
              [src]="completedImg | blobifyUrl: true"
              [attr.aria-label]="
                completionAriaLabel(
                  section.completedSteps,
                  section.totalSteps,
                  section.optionalSteps
                )
              "
              [attr.aria-valuenow]="section.progress"
              aria-valuemax="100"
              aria-valuemin="0"
              aria-role="progressbar"
            />
          </div>

          <a
            href="#section-{{ section.number }}"
            [title]="getSectionTitle(section.title)"
            target="_self"
            (click)="sectionClick($event, section)"
            [ngClass]="{
              'pathway-section-nav__item--active': isActiveSection(
                section.number
              )
            }"
            class="pathway-section-nav__section-menu--title"
            data-dgat="pathway-section-nav-ae3"
            [attr.aria-label]="
              getSectionTitleForAriaLabel(
                sectionIndex + 1,
                getSectionTitle(section.title),
                asString(section.progress)
              )
            "
          >
            <dgx-text-expander
              [content]="getSectionTitle(section.title)"
              [maxCollapsedLines]="2"
              [useReadMore]="false"
              class="pathway-section-nav--text-expander"
            ></dgx-text-expander
          ></a>
        </div>
        <!-- Subsections: vertical line not shown after last item if not active-->
        <div
          class="pathway-section-nav__vertical-line l_flexbar"
          [ngClass]="{
            'pathway-section-nav__vertical-line--spacer-bottom':
              !isActiveSection(section.number) && !last,
            'guts-p-b-1 no-border': !isActiveSection(section.number) && last
          }"
        >
          <ul
            class="guts-m-l-1-half guts-p-b-1-half"
            *ngIf="shouldShowSubsections(section)"
          >
            <ng-container
              *ngFor="let subsection of section.lessons; trackBy: trackById"
              data-dgat="pathway-section-nav-319"
            >
              <li
                *ngIf="hasAtLeastOneStep(subsection)"
                [ngClass]="
                  'pathway-section-nav__sub-menu' +
                  (subsection.complete ? '--completed' : '')
                "
              >
                <div
                  class="guts-m-t-1"
                  [ngClass]="{
                    l_flexbar: subsection.complete
                  }"
                >
                  <df-icon
                    icon="checkmark"
                    *ngIf="subsection.complete"
                    class="pathway-section-nav__sub-menu--completed__checkmark"
                  ></df-icon>
                  <a
                    href="#section-{{ section.number }}-{{ subsection.number }}"
                    [title]="getSubsectionTitle(subsection.title)"
                    target="_self"
                    (click)="subsectionClick($event, section, subsection)"
                    [ngClass]="{
                      'pathway-section-nav__item--active': isActiveSubsection(
                        subsection.number
                      )
                    }"
                    data-dgat="pathway-section-nav-2b8"
                  >
                    <dgx-text-expander
                      [content]="getSubsectionTitle(subsection.title)"
                      [maxCollapsedLines]="2"
                      [useReadMore]="false"
                      class="pathway-section-nav--text-expander"
                    ></dgx-text-expander>
                  </a>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </li>
    </ng-container>
  </ol>
</div>
