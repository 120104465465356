<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!badgeGlobalAddForm"
    #formRef="ngForm"
    class="reactive-forms"
    [class.input-success-kickstart]="vm.shouldShowResults$ | async"
    [formGroup]="badgeGlobalAddForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="i18n.Core_Next"
      [useDefaultSubmitButton]="!showExpandedForm"
      [canCancel]="!(vm.shouldShowResults$ | async)"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      [bodyClasses]="{
        'guts-p-full-2': true,
        'input-success-kickstart': (vm.shouldShowResults$ | async),
        first: true,
        second: true,
        third: false
      }"
      (dismiss)="onDismiss($event)"
    >
      <!-- Modal body -->
      <ng-container class="modal-body">
        <div class="input-success-wrap">
          <ng-container *ngIf="!showExpandedForm">
            <div class="guts-m-b-1-half">
              <df-local-notification
                [text]="i18n.BadgeFormCtrl_BadgeHelpText"
                [type]="NotificationType.info"
                size="small"
                icon="info"
              ></df-local-notification>
            </div>

            <div>
              <dgx-reactive-forms-label for="badgeUpload">
                {{ i18n.dgUserOutcomeEditForm_UploadBadge }}
              </dgx-reactive-forms-label>
              <dgx-upload-section
                id="badgeUpload"
                [showReplaceButton]="false"
                [fileInfo]="vm.file"
                [useCropper]="false"
                [uploadAdapter]="vm.uploadAdapter"
                [fileRestrictions]="vm.fileRestrictions"
                [showDeleteButton]="false"
                [errorMessages]="vm.errorMessages"
                [aspectRatio]="16 / 9"
                [secondaryAspectRatio]="16 / 5"
                [labels]="{ header: i18n.dgContentHosting_DragAndDrop }"
                icon="arrow-up-tray"
                (fileChangedEvent)="
                  vm.contentUpload.onContentFileChange($event)
                "
                (uploadSuccessEvent)="
                  vm.contentUpload.onContentUploadSuccess(
                    badgeGlobalAddForm,
                    'badgeUrl',
                    $event
                  )
                "
                (errorEvent)="vm.contentUpload.onContentUploadFailure()"
              >
              </dgx-upload-section>
            </div>
          </ng-container>
          <!-- EXPANDED FORM-->
          <ng-container *ngIf="showExpandedForm">
            <div class="guts-p-b-1-half">
              <df-local-notification
                [text]="i18n.BadgeFormCtrl_BadgeInfoText"
                size="small"
                icon="info"
                [type]="NotificationType.info"
              ></df-local-notification>
            </div>

            <div class="reactive-forms__control">
              <dgx-upload-section
                [imageUrl]="vm.badgeImageUrl"
                [uploadAdapter]="vm.uploadAdapter"
                [fileInfo]="vm.file"
                [useCropper]="false"
                [aspectRatio]="16 / 9"
                [secondaryAspectRatio]="16 / 5"
                [showDeleteButton]="false"
                [showReplaceButton]="true"
                (fileChangedEvent)="
                  vm.contentUpload.onContentFileChange($event)
                "
                (uploadSuccessEvent)="
                  vm.contentUpload.onContentUploadSuccess(
                    badgeGlobalAddForm,
                    'badgeUrl',
                    $event
                  )
                "
                [useBrowserReplace]="true"
              >
              </dgx-upload-section>
            </div>
            <div class="reactive-forms__control">
              <dgx-reactive-forms-label for="issuer">
                {{ i18n.dgUserOutcomeEditForm_BadgeIssuer }}
              </dgx-reactive-forms-label>
              <input
                type="text"
                id="issuer"
                formControlName="issuer"
                data-dgat="badge-global-add-e0b"
              />
            </div>

            <div class="reactive-forms__control">
              <dgx-reactive-forms-label for="issuerUrl">
                {{ i18n.dgUserOutcomeEditForm_IssuerUrl }}
              </dgx-reactive-forms-label>
              <input
                type="text"
                id="issuerUrl"
                formControlName="issuerUrl"
                data-dgat="badge-global-add-818"
              />
            </div>

            <div class="reactive-forms__control">
              <dgx-date-range
                [formModel]="{
                  startDate: vm.dateRangeForm.startDate,
                  endDate: vm.dateRangeForm.endDate
                }"
                [isTouched]="markSubFormsAsTouched"
                [showEndDate]="true"
                [startDateRequired]="true"
                [endDateRequired]="true"
                [startDateLabel]="i18n.CertificateFormCtrl_IssueDate"
                [endDateLabel]="i18n.CertificateFormCtrl_ExpiryDate"
                [startDateEnabled]="vm.startDateEnabled"
                [endDateEnabled]="vm.endDateEnabled"
                [isMaxStartDateToday]="true"
                [isMaxEndDateToday]="false"
              ></dgx-date-range>
            </div>

            <!-- Skills -->
            <div class="reactive-forms__control">
              <dgx-tags-edit
                [hideHint]="true"
                [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
                [customTitle]="i18n.Core_Skills"
                [topTags]="vm.inferredSkills$ | async"
                [hideTopTags]="!(vm.inferredSkills$ | async).length"
                [hideTooltip]="false"
                [tooltipIcon]="'info'"
                [placeholder]="i18n.dgOrgInternalContent_SkillsPlaceholderText"
                [allowExistingOnly]="false"
                [tags]="badgeGlobalAddForm.get('skills').value || []"
                [showFullWidthLabel]="true"
                [useNewTagsDesign]="true"
                (tagsChange)="onFormControlUpdate('skills', $event)"
              ></dgx-tags-edit>
              <dgx-validate-field
                class="block"
                *ngIf="
                  badgeGlobalAddForm.get('skills').touched &&
                  badgeGlobalAddForm.get('skills').invalid
                "
                [message]="i18n.dgOrgInternalContent_SkillsMaxError"
              ></dgx-validate-field>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Modal footer -->
      <ng-container class="modal-footer">
        <dgx-global-add-results
          *ngIf="!vm.isInitialForm"
          [class.full-width]="vm.shouldShowResults$ | async"
          [buttonText]="i18n.dgUserOutcomeEditForm_SaveBadge"
          [isEditing]="false"
          [isCompleting]="true"
          [isNewbUser]="vm.isNewbUser$ | async"
          [submissionStatus]="vm.submissionStatus$ | async"
          [classifier]="'learning'"
          [showCancelButton]="false"
          [addSpacing]="false"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </ng-container>
    </dgx-modal>
  </form>
</ng-container>
