import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupSearchComponent } from './group-search.component';
import { GroupSearchInputComponent } from './group-search-input/group-search-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { DfIconModule } from '@lib/fresco';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    DfIconModule,
    NgbTypeaheadModule,
    FormsModule,
  ],
  providers: [],
  declarations: [GroupSearchComponent, GroupSearchInputComponent],
  exports: [GroupSearchComponent, GroupSearchInputComponent],
})
export class DgxGroupSearchModule {}
