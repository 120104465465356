import { getDeepCopy } from '@app/shared/utils';
import { ActiveType, BusinessRule, Predicate, StaticOutcomes } from '../model';
import { AutomationsApiService } from '../services/automations-api.service';
import { firstValueFrom, forkJoin, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export function prepareOperators(predicates: Predicate[]) {
  const operators = [];
  if (predicates.length > 0) {
    for (let i = 0; i < predicates.length; i++) {
      const firstPredicate = predicates[i],
        secondPredicate = predicates[i + 1];

      if (secondPredicate) {
        operators.push({
          firstPredicate,
          secondPredicate,
          operator: 'and',
        });
      }
    }
  }

  return operators;
}

// This function is used to prepare a clone of the automation by removing all object identifiers
// It also fetches the email templates for the email outcomes if they exist
export async function prepareClone(
  automation: BusinessRule,
  orgId: number,
  automationsApi: AutomationsApiService,
  translate: TranslateService
) {
  let clone: BusinessRule = getDeepCopy(automation); // original object is immutable

  const emailOutcomes = clone.outcomes?.filter(
    (x) => x.updateTypeResourceId === StaticOutcomes.SEND_EMAIL
  );
  if (emailOutcomes.length > 0) {
    const requests = emailOutcomes.map((x) =>
      automationsApi.getEmailTemplate(orgId, x.emailTemplate.id).pipe(
        tap((response) => {
          x.emailTemplate = { ...response.payload, id: null };
        })
      )
    );
    await firstValueFrom(forkJoin(requests));
  }

  clone = {
    ...clone,
    id: null,
    ruleName: translate.instant('Core_CloneOfFormat', {
      title: clone.ruleName,
    }),
    activeType: ActiveType.Draft,
    outcomes: clone.outcomes?.map((o) => ({
      ...o,
      id: null,
      businessRuleId: null,
    })),
    trigger: !clone.trigger
      ? null
      : {
          ...clone.trigger,
          id: null,
          businessRuleId: null,
          schedule: !clone.trigger.schedule
            ? null
            : {
                ...clone.trigger.schedule,
                id: null,
                specifiedDate: clone.trigger?.schedule?.specifiedDate
                  ? new Date(clone.trigger.schedule.specifiedDate)
                  : null,
              },
        },
    predicates: clone.predicates?.map((p) => ({
      ...p,
      id: null,
      businessRuleId: null,
    })),
  };
  clone.predicateOperators = prepareOperators(clone.predicates);

  return clone;
}
