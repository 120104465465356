import { FlexibleDate } from '@app/shared/models/core-api.model';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NgbDatePipe } from '@app/shared/pipes/ngb-date.pipe';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { Badge, UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { TagsApi } from '@app/tags/tag-api.model';
import { UserContentModalBaseDirective } from '@app/user-content/user-input/user-content-modal-base/user-content-modal-base.directive';
import { UserImageFieldComponent } from '@app/form-fields/wrappers/user-image-field/user-image-field.component';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, startWith } from 'rxjs/operators';
import { BadgeUploadAdapter } from './badge-upload.adapter';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { DatePipe } from '@angular/common';

export interface BadgeFormModel {
  badge: Partial<Badge>;
  issuer: string;
  issuerUrl: string;
  imageUrl: string;
  dateGroup: {
    issueDate?: FlexibleDate;
    expiryDate?: FlexibleDate;
  };
  tags: TagsApi.Tag[];
}

@Component({
  selector: 'dgx-badge-modal',
  templateUrl: './badge-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeModalComponent extends UserContentModalBaseDirective<
  UserOutcomeDetail,
  BadgeFormModel
> {
  public readonly i18n = this.translate.instant([
    'dgUserOutcomeEditForm_SaveToProfile',
    'AwardFormCtrl_AwardDate',
    'AccomplishmentFormCtrl_StateDate',
    'AccomplishmentFormCtrl_ExpirationDate',
    'BadgeFormCtrl_BadgeHelpText',
  ]);

  @ViewChild('readonlyField') public readonlyFieldRef: TemplateRef<any>;
  @ViewChild('disabledDateField') public disabledDateFieldRef: TemplateRef<any>;
  @ViewChild('skillsView')
  public skillsViewRef: TemplateRef<any>;

  public isLoaded = false; // indicates if badge data has been loaded

  constructor(
    @Inject(WindowToken) windowRef: Window,
    cdr: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    authService: AuthService,
    tipService: TipService,
    inputsService: InputsService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private builder: DfFormFieldBuilder,
    private uploadAdapter: BadgeUploadAdapter
  ) {
    super(
      windowRef,
      cdr,
      activeModal,
      authService,
      tipService,
      inputsService,
      translate.instant('dgUserOutcomeEditForm_AddBadge'),
      translate.instant('dgUserOutcomeEditForm_EditBadge')
    );
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();

    const initialModel = { ...this.model };

    // Listen for badge form value changes. The 'badge' form control isn't created until after ngAfterViewInit,
    // so this pairwise hack lets us listen to the entire form and compare to the previous to see if 'badge'
    // was the field that changed
    this.form.valueChanges
      .pipe(startWith(initialModel), pairwise())
      .subscribe(([previous, current]) => {
        if (previous.badge != current.badge) {
          // init (or clear if badge image deleted) badge-related form data
          this.initFormBadgeMetadata(current.badge);
          this.fields = this.buildFormFields();
        }
      });
  }

  public initFormBadgeMetadata(badge: Badge) {
    this.model = {
      ...this.model,
      badge,
      issuer: badge?.badgeinfo.issuerinfo.name,
      imageUrl: badge?.image,
      issuerUrl: badge?.badge,
      dateGroup: {
        issueDate: badge?.issuedOn ? new Date(badge.issuedOn) : undefined,
        expiryDate: badge?.expires ? new Date(badge.expires) : undefined,
      },
    };

    this.isLoaded = !!badge;
  }

  public onTagsChange(items: any[], formControl: FormControl) {
    UserContentModalBaseDirective.setCustomFieldValue(formControl, items);
  }

  protected buildFormFields(): DfFormFieldConfig<DfTemplateOptions>[] {
    const disabledExpiryDateBuilder = this.builder
      .customField(
        'expiryDate',
        'dgUserOutcomeEditForm_Expiration',
        this.disabledDateFieldRef
      )
      .styledBy('first-col df-form__col-half')
      .readonlyWhen(() => true);

    const enabledExpiryDateBuilder = this.builder
      .foreignField<MonthPickerFieldParams>(
        'expiryDate',
        'dgUserOutcomeEditForm_Expiration',
        MonthPickerFieldComponent.REGISTERED_FIELD_TYPE
      )
      .styledBy('first-col df-form__col-half')
      .validatedByIndexed({
        endDateError: {
          expression: (control) => {
            return control.value > this.model.dateGroup.issueDate; // no end date comparison needed for current positions,
          },
          message: this.i18n.AccomplishmentFormCtrl_ExpirationDate, // TODO: this isn't a great error message here but was used on the ajs form
        },
      });

    const disabledIssuedDateBuilder = this.builder
      .customField(
        'issueDate',
        'Cred_Certificate_DateIssued',
        this.disabledDateFieldRef
      )
      .styledBy('first-col df-form__col-half')
      .readonlyWhen(() => true);

    const enabledIssuedDateBuilder = this.builder
      .foreignField<MonthPickerFieldParams>(
        'issueDate',
        'Cred_Certificate_DateIssued',
        MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
        {
          isMaxDateToday: true,
        }
      )
      .styledBy('first-col df-form__col-half')
      .asRequired()
      .readonlyWhen(() => !!this.model.dateGroup.issueDate);

    // disable the issue date field if the issue date is set on the badge when adding, or always when editing the badge
    // see https://degreedjira.atlassian.net/browse/PD-74332
    const hasIssuedDate = !!(this.model?.badge?.issuedOn || this.isEditing);
    const issuedDateBuilder = hasIssuedDate
      ? disabledIssuedDateBuilder
      : enabledIssuedDateBuilder;

    // disable the expiry date field if the expiry date is set on the badge when adding, or always when editing the badge
    // see https://degreedjira.atlassian.net/browse/PD-74332
    const hasExpiryDate = !!(this.model?.badge?.expires || this.isEditing);
    const expiryDateBuilder = hasExpiryDate
      ? disabledExpiryDateBuilder
      : enabledExpiryDateBuilder;

    return [
      this.builder
        .foreignField(
          'badge',
          'dgUserOutcomeEditForm_UploadBadge',
          UserImageFieldComponent.REGISTERED_FIELD_TYPE,
          {
            setFocusManually: true,
            // This is how the upload section gets the thumbnail image to display
            provideImageUrl: (control: AbstractControl) =>
              (control.value as Badge)?.image ?? this.model?.imageUrl,
            uploadAdapter: this.uploadAdapter,
            showReplaceButton: false,
          }
        )
        .build(),
      this.builder
        .customField(
          'issuer',
          'dgUserOutcomeEditForm_BadgeIssuer',
          this.readonlyFieldRef
        )
        .hiddenWhen(() => !this.isLoaded)
        .build(),
      this.builder
        .customField(
          'issuerUrl',
          'dgUserOutcomeEditForm_IssuerUrl',
          this.readonlyFieldRef
        )
        .hiddenWhen(() => !this.isLoaded)
        .build(),
      this.builder
        .fieldGroup(
          'dateGroup',
          [
            // Issue date
            issuedDateBuilder.build(),

            // Expiration date
            (this.initialViewModel?.details?.noExpiration
              ? expiryDateBuilder
              : expiryDateBuilder.asRequired()
            ).build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(() => !this.isLoaded)
        .build(),
      this.builder
        .customField('tags', 'Core_Skills', this.skillsViewRef, {
          topTags: this.userInterests,
        })
        .unwrapped() // The tag editor has its own label and doesn't require validation, so present it sans fresco's default field wrapper
        .hiddenWhen(() => !this.isLoaded)
        .build(),
    ];
  }

  protected initFormModel(source: Partial<UserOutcomeDetail>) {
    this.model = {
      issuer: source.source,
      issuerUrl: source.url,
      imageUrl: source.imageUrl,
      dateGroup: {
        issueDate: source.startDate ? new Date(source.startDate) : undefined, // ensure we get a Date, not string
        expiryDate: source.endDate ? new Date(source.endDate) : undefined,
      },
      tags: source.tags ?? [],
    };

    this.isLoaded = !!source.imageUrl;
  }

  protected createResult(
    model: Partial<BadgeFormModel>,
    defaults: Partial<UserOutcomeDetail>
  ): UserOutcomeDetail {
    const result: Partial<UserOutcomeDetail> = {
      ...defaults,
      title: '',
      contentTypeId: 'Badge',
      imageUrl: model.imageUrl,
      source: model.issuer,
      url: model.issuerUrl,
      startDate: this.datePipe.transform(
        model.dateGroup.issueDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        model.dateGroup.expiryDate,
        'yyyy-MM-dd'
      ),
      tags: model.tags,
    };
    return result as UserOutcomeDetail;
  }
}
