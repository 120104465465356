import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { ngbDateToJsDate } from '@app/shared/utils/time-utils';

import { MonthDateParserFormatterService } from '@app/shared/services/date-picker/month-date-parser-formatter.service';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'dgx-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: MonthDateParserFormatterService,
    },
  ],
})
export class MonthPickerComponent implements OnInit {
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public placeholder: string;
  @Input() public customDgat: string;
  @Input() public date: Date;
  @Input() public isMinDateToday: boolean = false;
  @Input() public isMaxDateToday: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public ariaLabel: string;

  @Output() public dateSelect = new EventEmitter<Date>();

  @ViewChild('ngbDatepicker')
  public ngbDatepicker: NgbInputDatepicker;

  public i18n = this.translateService.instant([
    'Core_Clear',
    'Core_SelectDate',
  ]);

  public get dateButtonLabel() {
    if (this.date) {
      return this.datePipe.transform(this.date, 'MMM yyyy');
    } else {
      return this.i18n.Core_SelectDate;
    }
  }

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  public ngOnInit(): void {
    if (this.isMinDateToday && !this.minDate) {
      this.minDate = new Date();
    }

    if (this.isMaxDateToday && !this.maxDate) {
      this.maxDate = new Date();
    }
    this.ariaLabel = this.ariaLabel
      ? this.ariaLabel
      : this.i18n.Core_SelectDate;
  }

  public clearDate(): void {
    this.date = undefined;
    this.ngbDatepicker.close();
    this.dateSelect.emit();
  }

  public selectDate(selectedDate: NgbDate): void {
    this.dateSelect.emit(ngbDateToJsDate(selectedDate));
  }
}
