import { InjectionToken } from '@angular/core';
import * as microsoftTeams from '@microsoft/teams-js';
import * as msTeamsMsal2Token from '@microsoft/mgt-teams-msal2-provider';

// Microsoft Auth Library Provider for Teams v2 - supports SSO and needed to fix issues with 2FA
//TODO:
// Microsoft has depreciated this now and we should move to the new TeamsFx library and test SSO with 2FA again
// https://learn.microsoft.com/en-us/microsoftteams/platform/toolkit/teamsfx-sdk
export const MSTeamsMsal2Token = new InjectionToken('msTeamsMsal2Token');

export function teamsMsal2TokenProvider() {
  msTeamsMsal2Token.TeamsMsal2Provider.microsoftTeamsLib = microsoftTeams;
  return msTeamsMsal2Token;
}
