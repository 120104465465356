<aside
  *ngIf="vm$ | async as vm"
  class="pathway-author-note l_flex"
  [ngClass]="{
    'pathway-author-note--mobile': vm.isMobileView
  }"
>
  <div class="pathway-author-note__icon--shadow no-tablet">
    <df-icon icon="author-note" class="pathway-author-note__icon"></df-icon>
  </div>
  <button
    class="pathway-author-note__icon--shadow tablet-only"
    (click)="toggleButton()"
    data-dgat="pathway-author-note-8ae"
  >
    <df-icon
      icon="author-note"
      class="pathway-author-note__icon guts-m-t-0"
    ></df-icon>
  </button>
  <div
    [ngClass]="
      vm.isMobileView
        ? 'popover__window--left pathway-author-note__window--mobile'
        : 'popover__window--right'
    "
    class="popover__window pathway-author-note__window kristen-says-hi-not-edit"
    *ngIf="!vm.isMobileView || (vm.isMobileView && step.note.shouldDisplayNote)"
  >
    <div
      [ngClass]="
        vm.isMobileView
          ? 'popover-arrow--window-left-top'
          : 'popover-arrow--window-right-top'
      "
      class="popover-arrow pathway-author-note__arrow"
    ></div>
    <div class="popover__content pathway-author-note__content guts-p-full-1">
      <h2 class="par par--light par--small guts-p-b-half">
        {{ i18n.Pathways_NoteFromAuthor }}
      </h2>
      <p
        class="par par--small markdown markdown--small break"
        [innerHTML]="step.note.note | markdownToHtml: markdownOptions"
      ></p>
    </div>
  </div>
</aside>
