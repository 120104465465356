<ng-container *ngIf="useApollo && currentRoute === 'branding'; else v1">
  <dgx-org-branding-v3></dgx-org-branding-v3>
</ng-container>

<ng-template #v1>
  <dgx-tab-navigation
    [tabList]="tabs"
    *ngIf="showNavigation"
  ></dgx-tab-navigation>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</ng-template>
