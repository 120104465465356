import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

// modules
import { DfButtonsModule, DfIconModule } from '@lib/fresco';
import { UploaderModule } from '@app/uploader/uploader.module';

// components
import { AuthorVideoComponent } from './components/author-video/author-video.component';

// providers
import { AuthorContentService } from './services/author-content.service';
import { AuthorVideoService } from './services/author-video.service';
import {
  MediaDevicesToken,
  mediaDevicesProvider,
} from './services/media-devices.token';
import { AuthorVideoModalComponent } from './components/author-video-modal/author-video-modal.component';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';
import { ProgressCircleComponent } from '@app/shared/components/progress-circle/progress-circle.component';
import { ModalModule } from '@app/shared/components/modal/modal.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AuthorVideoComponent, AuthorVideoModalComponent],
  imports: [
    CommonModule,
    DfIconModule,
    DfButtonsModule,
    UploaderModule,
    ModalModule,
    TranslateModule,

    //standalone components
    AutofocusDirective,
    ProgressCircleComponent,
  ],
  exports: [AuthorVideoComponent],
  providers: [
    AuthorContentService,
    AuthorVideoService,
    { provide: MediaDevicesToken, useFactory: mediaDevicesProvider },
  ],
})
export class AuthorSharedModule {}
