import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';

// guards
import { SkillsPlatformGuard } from '@app/skills-platform-lxp-ui';

import { ReportingAuthorizationService } from '@app/reporting/services/reporting-authorization.service';
import { AutomationsGuard } from './automations.guard';
import { BulkUploadGuard } from './bulk-upload.guard';
import { CatalogGuard } from './catalog.guard';
import { GroupsGuard } from './groups.guard';
import { InsightsGuard } from './insights.guard';
import { OrgBaseGuard } from './org-base.guard';
import { OrgSettingsRedirectGuard } from './org-settings-redirect.guard';
import { PathwaysGuard } from './pathways.guard';
import { PeopleRedirectGuard } from './people-redirect.guard';
import { PlansGuard } from './plans.guard';
import { SkillDevGuard } from './skill-dev.guard';
import { SkillsRedirectGuard } from './skills-redirect.guard';

export type GuardWithSegment = {
  guard: { hasPermission: boolean };
  segment: string;
};

@Injectable({
  providedIn: 'root',
})
export class OrgRedirectGuard extends OrgBaseGuard {
  constructor(
    authService: AuthService,
    @Inject(WindowToken) windowRef: Window,
    private bulkUploadGuard: BulkUploadGuard,
    private catalogGuard: CatalogGuard,
    private groupsGuard: GroupsGuard,
    private insightsGuard: InsightsGuard,
    private peopleRedirectGuard: PeopleRedirectGuard,
    private pathwaysGuard: PathwaysGuard,
    private plansGuard: PlansGuard,
    private reportingAuthorizationService: ReportingAuthorizationService,
    private skillDevGuard: SkillDevGuard,
    private skillsRedirectGuard: SkillsRedirectGuard,
    private sKillsPlatformGuard: SkillsPlatformGuard,
    private automationsGuard: AutomationsGuard,
    private orgSettingsRedirectGuard: OrgSettingsRedirectGuard,
    protected router: Router
  ) {
    super(authService, windowRef, router);
  }

  /**
   * Check all Org page permissions. If n   ot availalbe
   * @param route
   * @param state
   * @returns
   */
  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const segment = this.findFirstAllowedSegment() || '/';
    const accessDenied = segment === '/';

    if (accessDenied) {
      const url = this.resolveOrgUrl(route.url?.join('') || '/');
      console.error(`Access Denied to ${url}`);
    }

    // Go to primary child segment OR back to the Learner View
    return this.router.parseUrl(
      this.resolveOrgUrl(accessDenied ? '/forbidden' : segment)
    );
  }

  public findFirstAllowedSegment(): string | undefined {
    const canViewReporting =
      this.reportingAuthorizationService.canViewReporting ||
      this.reportingAuthorizationService.canScheduleFTPReports;

    // Needs to be in priority order
    const orderedGuards = [
      { guard: this.insightsGuard, segment: '/insights' },
      { guard: this.groupsGuard, segment: '/groups' },
      { guard: this.peopleRedirectGuard, segment: '/members' },
      { guard: this.plansGuard, segment: '/plans' },
      { guard: this.skillsRedirectGuard, segment: '/skills' },
      { guard: this.skillDevGuard, segment: '/skill-dev' },
      { guard: this.sKillsPlatformGuard, segment: '/skills-platform' },
      { guard: this.pathwaysGuard, segment: '/pathways' },
      { guard: this.catalogGuard, segment: '/content' },
      { guard: this.bulkUploadGuard, segment: '/fileupload' },
      // @TODO: - confirm that automations guard should be added.
      // { guard: this.automationsGuard, segment: '/automations' },
      { guard: this.orgSettingsRedirectGuard, segment: '/settings' },
      { guard: { hasPermission: canViewReporting }, segment: '/reporting' },
    ];

    const guardWithPermission = orderedGuards.find(
      (g) => g.guard.hasPermission
    );

    return guardWithPermission?.segment;
  }
}
