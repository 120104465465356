import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

import { DF_SLIDE_FADE_IN_LEFT } from '../../utilities/animations/animations';
import {
  DfIconRegistry,
  DfIconArrowDownRight16,
  DfIconQuestionMarkCircle16,
} from '../../icon';
import { DfFieldLayout, DfFieldTemplateContext } from '../field-types';
import { Subscription } from 'rxjs';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * A field input component wrapper that provides a label, optional/required field indication, description,
 * tip text, help text and validation UI. Multiple layouts are supported via the {@link layout} property.
 */
@Component({
  selector: 'df-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  styleUrls: ['./field-wrapper.component.scss'],
  animations: [DF_SLIDE_FADE_IN_LEFT],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfFieldWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  public layout: DfFieldLayout;
  public isVertical: boolean;
  public isHeading: boolean;
  public isReversed: boolean;
  public shouldShowRequiredDesignator: boolean;

  private changeSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private iconRegistry: DfIconRegistry
  ) {
    super();
    this.iconRegistry.registerIcons([
      DfIconArrowDownRight16,
      DfIconQuestionMarkCircle16,
    ]);
  }

  public get contentContext(): DfFieldTemplateContext {
    return {
      formControl: this.formControl,
      key: this.key,
      label: this.to.label,
      params: this.to.params,
    };
  }

  public ngOnInit() {
    this.layout = this.to?.layout || this.to?.defaultLayout || 'vertical';
    this.isVertical = this.layout === 'vertical';
    this.isHeading = this.layout === 'horizontal-heading';
    this.isReversed = this.layout === 'horizontal-reverse';
    this.shouldShowRequiredDesignator =
      !this.to.hideRequiredDesignator && this.to.required;
    const prefix = this.id || this.key;
    this.to.ariaDescribedbyIds =
      [
        this.to.dfDescription ? prefix + '-desc' : undefined,
        this.to.help ? prefix + '-help' : undefined,
      ]
        .filter((id) => !!id)
        .join(' ') || undefined;
    this.changeSubscription = this.field.formControl.valueChanges.subscribe(
      () => {
        this.cdr.markForCheck(); // change can be pushed from control side too
      }
    ) as unknown as Subscription;
  }

  public ngOnDestroy() {
    this.changeSubscription.unsubscribe();
  }

  public get showErrors() {
    return (
      this.field.formControl.invalid &&
      (this.field.formControl.touched || this.field.formControl.dirty)
    );
  }
}
