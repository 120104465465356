import { FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { ScormInfo } from '@app/content-catalog/services/scorm.service';
import { GroupIdentifier } from '@app/groups/group-api';
import {
  GroupId,
  InputCreationFeedback,
  InputDetails,
  InputIdentifier,
  InputSession,
  OrganizationContentMetadata,
  UserInputCreationFeedback,
} from '@app/inputs/inputs-api.model';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import {
  FlexibleDate,
  HostedContentMetadata,
  InputType,
} from '@app/shared/models/core-api.model';
import { Merge } from '@app/shared/utils';
import { TagsApi } from '@app/tags/tag-api.model';
import { FileUploadSetting } from '@app/uploader/uploader-api.model';
import {
  ApiInputImageProps,
  ContentOwnerProps,
  ImageUploadProps,
  InputContext,
  MappingToApiImageProps,
} from '@app/user-content/user-input-v2/input.model';
import {
  DefaultInputParameters,
  DefaultSelectOptionsType,
  Grades,
  Institution,
} from '@app/user-content/user-input/course-form/course-form.model';

/**
 * CourseTypeId options as an enum.
 */
export const enum CourseTypeId {
  Informal = 0,
  Accredited = 1,
}

/**
 * CourseType.
 */
export interface CourseType {
  label: string;
  id: CourseTypeId;
  icon: string;
}

/**
 * CourseTypes as a constant that can be used anywhere.
 * Plans/Pathways and Catalog should just pull in courseTypes[1].
 */
export const courseTypes = [
  {
    label: 'CourseFormCtrl_Accredited',
    id: CourseTypeId.Accredited,
    icon: 'degree',
  },
  {
    label: 'CourseFormCtrl_Provider',
    id: CourseTypeId.Informal,
    icon: 'certificate',
  },
];

/**
 * Complete VM
 */
export type CourseModel = CourseInput &
  CourseComputed & { inputContext: InputContext };

/**
 * When mapping data from the api to the UI, this combines both edit and non edit properties
 */
export type CourseEntryApi = Merge<CourseApiInputEdit, CourseApiInput>;

export interface CourseApiInput
  extends InputIdentifier,
    ContentOwnerProps,
    ApiInputImageProps {
  accessible: boolean; // It looks like it is a true/false value that gets set based on if the media scraper is able to access the URL for the content
  authored?: boolean; // this is global only
  comment?: string; // this is global only
  courseId?: number; // They send courseId, we send courseNumber.
  courseNumber?: number; // See above.
  courseUrl: string;
  creditHours?: number; // only on global Add and Pathway|Plans
  description: string;
  dateCompleted?: FlexibleDate; // only on global Add
  durationHours?: number;
  durationMinutes?: number;
  groupIds?: number; // This is with orgContentMetadata
  gradeId?: number; // only on global Add
  highConfidenceInferredSkills?: string[]; // this will be used for the inferred skills that are pre-selected, partial object
  input?: DefaultInputParameters;
  institutionId?: number;
  institutionName?: string;
  institution?: Institution;
  isAccredited: boolean; // TODO: Deliberately flipping courseTypeId, which isn't used on the BE anyway, so that 1 = formal and 0 = informal, thus making our truthy check THEORETICALLY the same as isAccredited.
  levelId?: number;
  mediumConfidenceInferredSkills: string[]; // this will be used for the inferred skills that are on the wall,  partial object
  name: string; // Course name and title in the old context seem to be used interchangeably watch out for this as we implement
  sessions?: [InputSession]; // Catalog only
  tags?: TagsApi.Tag[];
  userInputId?: number;
  verificationUrl?: string; // Global add only
}

type CourseEditBase = CourseApiInput & CourseMappingToApi;

// Properties that are only on the edit form
export interface CourseApiInputEdit extends CourseEditBase {
  createDate?: string;
  /** @deprecated - See: https://degreedjira.atlassian.net/browse/PD-72413 */
  createdBy?: string;
  createdByName?: string;
  createdByUserProfileKey?: number;
  fileManaged: boolean; // Catalog only
  hasBrokenUrl: boolean; // Catalog only
  hostedContentDetails?: HostedContentMetadata;
  hostedType?: string;
  orgContentMetadata?: OrganizationContentMetadata; // Catalog and Pathways With catalog
  organizationId?: number;
  organizationName?: string;
  owner?: AnyRecommendee;
  publishDate?: string;
}

export interface CourseComputed extends ImageUploadProps {
  addToCatalog?: boolean; // always true from catalog, can also be added from Plan/Pathways
  canManageContent?: boolean;
  duplicateCount?: number; // Duplicate count for URL for content going to catalog
  duplicates?: InputDetails[]; // duplicate inputs to display for content going to catalog
  inputContext: InputContext;
  isSubmitButtonDisabled?: boolean; // this is used when the uploader is loading content
  isInitialForm: boolean;
  isFormal?: boolean; // Global add only. Used to distinguish between which *form* we're showing and which API call we're making. Individual courses for a university might not be accredited but it's still formal.
  scormInfo?: ScormInfo; //{ scormFileName: string; scormCourseId: string }; // is this needed since it is also hostedContentDetails>
  shouldShowContentUploader?: boolean;
  shouldSpinSubmitButton$: Observable<boolean>;
  updateScorm?: boolean;
  // Image uploading
  imageUploadAdapter?: any;
  isSessionDetailsToggledOn?: boolean;
  contentUpload?: {
    onContentFileChange: (file: File) => void;
    onContentUploadSuccess: (
      formGroup: FormGroup,
      fieldName: string,
      response: any
    ) => void;
    onContentUploadFailure: () => void;
    onContentDelete: (formGroup: FormGroup) => void;
    onContentMissingMetadata: () => void;
  };
  file?: any;
  hostedContentDetails?: HostedContentMetadata;
  uploadAdapter?: any;
  fileRestrictions?: FileUploadSetting;
  errorMessages?: any;
  shouldDisableContentUploader: boolean; // Readonly statuses for fields in the form.
  readonly?: { [key: string]: boolean };
  // Character limits for fields in the form.
  limits?: { [key: string]: number };
  isCourseTypeSelected?: (
    courseTypeId: CourseTypeId,
    newCourseTypeId: CourseTypeId
  ) => boolean; // TODO: may need modification for Catalog and Pathways/Plans? CourseUrl and SCORM are both *ultimately* "informal".
  onCourseTypeChange?: (
    form: FormGroup,
    courseTypeId: CourseTypeId
  ) => FormGroup;
  // For Global Add
  isNewbUser$?: Observable<boolean>; // is it the user's first input? Show a different completion modal content if true
  shouldShowResults$?: Observable<boolean>; // show the completion modal
  submissionStatus$?: Observable<SubmissionStatus>;
  submissionResult?: Merge<UserInputCreationFeedback, InputCreationFeedback>; // the response after adding a video containing the stats, points, rewards etc, required for the completion modal
}

export interface CourseInput extends ContentOwnerProps, ApiInputImageProps {
  accessible?: boolean;
  allCourseLevels?: DefaultSelectOptionsType[];
  allGrades?: Grades[];
  authored?: boolean; // this is global only
  createdByName?: string;
  creditHours?: number; // only on global Add and Pathway|Plans
  comment?: string; // only on global add
  courseNumber?: number; // only on global Add and Pathway|Plans
  courseUrl: string;
  country?: string;
  name: string; // Course name and title in the old context seem to be used interchangeably watch out for this as we implement
  courseTypeId: number; // Course type informal or formal. Only formal courses can be accredited?
  description: string;
  dateCompleted?: FlexibleDate; // only on global Add
  durationHours: number;
  durationMinutes: number;
  unitType?: string;
  units?: number;
  externalId?: string; // only on catalog
  gradeId?: number; // only on global Add
  groupIds?: GroupId[];
  highConfidenceInferredSkills?: TagsApi.Tag[]; // this will be used for the inferred skills that are pre-selected, partial object
  inputId?: number; // ONLY on EDIT
  inputType?: InputType;
  institutionId?: number;
  institutionName?: string;
  isAccredited: boolean; // TODO: Deliberately flipping courseTypeId, which isn't used on the BE anyway, so that 1 = formal and 0 = informal, thus making our truthy check THEORETICALLY the same as isAccredited.
  isScorm?: boolean;
  institution?: Institution; // Institution;
  language?: string; // Catalog only
  levelId?: number;
  mediumConfidenceInferredSkills?: TagsApi.Tag[];
  organizationId?: number;
  orgContentMetadata?: OrganizationContentMetadata; // Catalog and Pathways With catalog
  owner?: AnyRecommendee;
  publishDate?: string;
  sessions?: [InputSession]; // Catalog only
  sessionDetails?: InputSession; // Catalog only
  scormInfo?: ScormInfo;
  tags?: TagsApi.Tag[];
  // selectedOrg: { id: number }; // TODO: This seems to be used in globalAdd is this still needed?
  userInputId?: number;
  verificationUrl?: string; // Global add only
  // EDIT
  courseId?: number; // On edit catalog only
  createDate?: string;
  createdBy?: string;
  createdByUserProfileKey?: number;
  fileManaged?: boolean; // Catalog only
  hasBrokenUrl?: boolean; // Catalog only
  hostedContentDetails?: HostedContentMetadata;
  hostedType?: string;
  obsolete?: boolean;
  extent?: {
    courseLevel: number;
    dateCompleted: FlexibleDate;
    courseGrade: number;
    verificationUrl: string;
  };
  input?: DefaultInputParameters & { courseId?: number };
}

export interface CourseMappingToApi
  extends ContentOwnerProps,
    MappingToApiImageProps {
  authored?: boolean; // only on global add/edit
  continuingEducationUnits?: number;
  courseUrl?: string;
  courseNumber?: number; // sent instead of courseId
  creditHours?: number;
  dateCompleted?: FlexibleDate; // only on global add
  description?: string;
  durationHours: number;
  durationMinutes: number;
  externalId?: string;
  fileManaged?: boolean;
  gradeId?: number; // only on global add
  hostedContentDetails?: HostedContentMetadata;
  inputId?: number; // mirrors courseNumber
  institutionId?: number;
  language?: string;
  levelId?: number;
  name?: string;
  obsolete?: boolean; // adding for compatibility with other models, but FTR the BE doesn't expect this prop for courses.
  platform?: string; // only on global add
  publishDate?: string;
  organizationId?: number;
  orgContentMetadata?: {
    hideFromCatalog?: boolean;
    groupIds?: GroupIdentifier[];
  };
  scormManifestId?: string;
  sessions?: [InputSession]; // Catalog only
  tags?: TagsApi.Tag[]; // These are the selected tags
  unitType?: string;
  units?: number;
  userInputId?: number; // Global edit only
  verificationUrl?: string; // Global add only

  // !!! TODO: Remove everything below this line, we're only adding it to make the Cypress tests pass.
  // The BE doesn't expect or use these properties. !!!
  institutionName?: string;
  inputType?: InputType;
  isAccredited?: boolean;
}

export interface CourseFormDataModel {
  addToCatalog?: boolean;
  comment?: string;
  country?: string;
  courseUrl: string;
  courseLevel?: number;
  courseNumber?: number;
  dateCompleted?: FlexibleDate;
  courseGrade?: number;
  verificationUrl?: string;
  institutionName?: string;
  description?: string;
  durationForm?: {
    durationMinutes: number;
    durationHours: number;
  };
  image?: string;
  owner: AnyRecommendee;
  skills?: TagsApi.Tag[];
  tags?: TagsApi.Tag[];
  title?: string; // NOTE: the api uses name
  name?: string;
  contentUploader?: {}; // TODO: Scorm Type Only on Catalog
  advancedSettings?: {
    internalItemId: string;
    language: {
      key: string;
      id: string;
    };
    publishedDate: string;
    visibility: {
      groups: [GroupIdentifier];
    };
  };
  sessionDetails?: {
    // sessionDetails is a toggleable formGroup
    isRegistrationAvailable: boolean;
    isRegistrationUrlInputUrl: boolean;
    registrationUrl?: string; // Only required if !isRegistrationUrlInputUrl
    locationType: {
      isInPerson: boolean;
      isOnline: boolean;
    };
    locationAddress?: string; // Only required if locationType.isInPerson is true
    locationUrl?: string; // Only required if locationType.isOnline is true
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
    startTime: string;
    endTime: string;
    timeZoneId: string;
  };
}

export interface CourseImportProgress {
  jobId: string;
  status: string;
  message: string;
  importResult: {
    course: {
      id: string;
      title: string;
      version: number;
    };
  };
}
