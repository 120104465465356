<div
  *ngIf="showCommentThread"
  class="comment-thread__container"
  [ngClass]="{
    'comment-thread__container--embedded bg-color-white':
      context === 'Embedded',
  }"
>
  <button
    class="comment-thread__action"
    (click)="toggleExpanded()"
    data-dgat="comment-thread-541"
    type="button"
  >
    <p *ngIf="isLoading">{{ i18n.Core_Loading }}</p>
    <p *ngIf="!isLoading && !isExpanded">
      {{ getShowCommentsLabel }}
    </p>
    <p *ngIf="!isLoading && isExpanded">
      {{ 'dgComment_HideComments' | translate }}
    </p>
  </button>
  <div *ngIf="isExpanded">
    <div
      *ngIf="comments?.feed.length > 0"
      class="comment-thread__separator"
    ></div>
    <div
      *ngIf="comments?.feed.length > 0"
      class="comment-thread__comments-container"
    >
      <ng-container
        *ngFor="
          let comment of comments?.feed;
          trackBy: commentId;
          let idx = index
        "
        data-dgat="comment-thread-9b1"
      >
        <div
          *ngIf="comment.isInappropriate"
          class="comment-thread__inappropriate-comment"
        >
          <df-icon icon="flag" size="small" class="guts-m-r-half"></df-icon>
          <span>{{ i18n.dgComment_CommentHidden }}</span>
          <button
            (click)="comment.isExpanded = !comment.isExpanded"
            type="button"
            class="link zeta block guts-m-t-half"
            data-dgat="comment-698"
          >
            {{ getInappropriateRepliesButtonText(comment) }}
          </button>
        </div>
        <dgx-comment
          *ngIf="!comment.isInappropriate"
          [comment]="asCommentModel(comments?.feed[idx])"
          [menuConfig]="getMenuConfig(comment)"
          [resource]="resource"
          [referenceUserKey]="referenceUserKey"
          [parentReferenceId]="parentReferenceId"
          [groupId]="groupId"
          [groupPrivacy]="groupPrivacy"
          class="comment-thread__comment"
        ></dgx-comment>
        <!-- Replies -->
        <div
          *ngIf="
            comment.replies.length > 0 &&
            (!comment.isInappropriate || comment.isExpanded)
          "
          class="comment-thread__replies"
        >
          <div
            *ngIf="comment.remainingReplies > 0 && !comment.isLoadingReplies"
            class="guts-m-b-1"
          >
            <button
              type="button"
              (click)="loadMoreReplies(comment)"
              class="link zeta"
              data-dgat="commentThread-3dc"
            >
              <span *ngIf="!comment.hasShownMoreReplies">{{
                getPreviousRepliesLabel(comment)
              }}</span>
              <span *ngIf="comment.hasShownMoreReplies">{{
                i18n.dgComment_ShowPreviousReplies
              }}</span>
            </button>
          </div>
          <df-spinner *ngIf="comment.isLoadingReplies"></df-spinner>
          <ul>
            <li
              *ngFor="
                let reply of comment.replies;
                let idx = index;
                let last = last
              "
              [class.guts-m-b-1]="!last"
              data-dgat="comment-thread-fb1"
            >
              <div
                *ngIf="reply.isInappropriate"
                class="guts-m-v-1 font-medium comment-thread__inappropriate-comment"
              >
                <df-icon
                  icon="flag"
                  size="small"
                  class="guts-m-r-half"
                ></df-icon>
                <span>{{ i18n.dgComment_ReplyHidden }}</span>
              </div>
              <dgx-comment
                *ngIf="!reply.isInappropriate"
                [comment]="asCommentModel(comment.replies[idx])"
                [menuConfig]="getMenuConfig(reply)"
                [resource]="resource"
                [referenceUserKey]="referenceUserKey"
                [parentReferenceId]="parentReferenceId"
                [groupId]="groupId"
                [groupPrivacy]="groupPrivacy"
                class="comment-thread__comment"
              ></dgx-comment>
            </li>
          </ul>
        </div>
      </ng-container>
      <button
        data-dgat="comment-thread-d36"
        *ngIf="comments?.remainingComments > 0"
        (click)="loadMore()"
        data-dgat="comment-thread-d36"
        [disabled]="isLoadingMore"
        class="comment-thread__load-more"
        type="button"
      >
        {{ isLoadingMore ? i18n.Core_Loading : i18n.Core_LoadMore }}
      </button>
    </div>
    <div class="comment-thread__separator"></div>
    <div *ngIf="showCommentField" class="guts-p-v-1 l_flexbar">
      <dgx-profile-pic
        *ngIf="showUserProfilePic"
        [profile]="authUser.viewerProfile"
        class="guts-m-r-1"
        size="small"
      ></dgx-profile-pic>
      <dgx-comment-field
        class="l_flex-one full-width"
        [autoFocus]="true"
        [groupId]="groupId"
        [groupPrivacy]="groupPrivacy"
        [isSubmitting]="isSubmitting"
        (submitComment)="createComment($event)"
      ></dgx-comment-field>
    </div>
  </div>
</div>
