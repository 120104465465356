import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-delete-opportunities-modal',
  templateUrl: './delete-opportunities-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteOpportunitiesModalComponent implements OnInit {
  @Input() public opportunities: Opportunity[] = [];

  public i18n = this.translateService.instant([
    'OrgManage_Opportunities_DeleteOpportunity', // Delete Opportunity
    'OrgManage_Opportunities_DeleteOpportunities', // Delete Opportunities
    'OrgManage_Opportunities_Delete_Confirmation', // Deleting opportunities will remove them from all search results. Are you sure you want to delete them?
    'OrgManage_Opportunities_Delete_Confirmation_Singular', // Deleting this opportunity will remove it from all search results. Are you sure you want to delete it?
    'OrgManage_Opportunities_Delete_Notice_Specific', // You're about to delete the following opportunities.
    'OrgManage_Opportunities_Delete_Notice_Specific_Singular', // You're about to delete the following opportunity.
    'OrgManage_Opportunities_Delete_Notice_Generic', // This action will be permanent and cannot be undone:
    'OrgManage_Opportunities_Delete_Undeletable', // These external opportunities cannot be deleted:
    'OrgManage_Opportunities_Delete_Undeletable_Singular', // This external opportunity cannot be deleted:
  ]);
  // Undeletable item titles, deletable item titles, and deletable item IDs.
  public itemIds: any[] = [];
  public items: string[] = [];
  public undeletableItems: string[] = [];
  // Strings that will change for singular deletes.
  public deleteConfirmation =
    this.i18n.OrgManage_Opportunities_Delete_Confirmation;
  public deleteNoticeSpecific =
    this.i18n.OrgManage_Opportunities_Delete_Notice_Specific;
  public deleteNoticeUndeletable =
    this.i18n.OrgManage_Opportunities_Delete_Undeletable;
  public modalHeaderTitle =
    this.i18n.OrgManage_Opportunities_DeleteOpportunities;

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    // When we only have one opportunity, we can assume it's not external.
    if (this.opportunities.length === 1) {
      this.items = [this.opportunities[0].title];
      this.itemIds.push(this.opportunities[0].opportunityId);
      this.setStringsToSingular();
      return;
    }

    // Otherwise...
    this.opportunities.forEach(({ opportunityId, title, url }) => {
      if (!!url) {
        return this.undeletableItems.push(title);
      }
      this.items.push(title);
      this.itemIds.push(opportunityId);
    });

    // If we are only deleting *one* opportunity, we want the singular strings
    if (this.itemIds.length === 1) {
      this.setStringsToSingular();
    }

    // If we can only *not* delete one opportunity, we want that string to be singular
    if (this.undeletableItems.length === 1) {
      this.deleteNoticeUndeletable =
        this.i18n.OrgManage_Opportunities_Delete_Undeletable_Singular;
    }
  }

  private setStringsToSingular(): void {
    this.deleteNoticeSpecific =
      this.i18n.OrgManage_Opportunities_Delete_Notice_Specific_Singular;
    this.modalHeaderTitle = this.i18n.OrgManage_Opportunities_DeleteOpportunity;
    this.deleteConfirmation =
      this.i18n.OrgManage_Opportunities_Delete_Confirmation_Singular;
  }
}
