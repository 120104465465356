import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';
import { TagFlagsService } from '@app/tags/services/tag-flags.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-completed-associate-rating-modal',
  templateUrl: './tag-completed-associate-rating-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCompletedAssociateRatingModalComponent implements OnInit {
  @Input() public ratingInfo: TagsApi.UserTagRatingDetails;
  @Input() public allowRatingRequest: boolean;

  public i18n: { [key: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private tagsService: TagsService,
    private activeModal: NgbActiveModal,
    private tagFlagsService: TagFlagsService
  ) {}

  public get ratingLevelMessage(): string {
    switch (this.ratingInfo.type) {
      case 'Manager':
        return this.i18n.TagRating_YouWereRatedFormat;
      case 'Goal':
        return this.i18n.TagRating_SkillTargetSetFormat;
      default:
        return '';
    }
  }

  public get showPeerRatings(): boolean {
    // this determines whether to show the new design for this component
    // should be removed (along with the old design) when Peer Ratings becomes available
    return this.tagFlagsService.showPeerRatings;
  }

  public get levelName(): string {
    return this.tagsService.getNameForRatingLevel(+this.ratingInfo.level);
  }

  public ngOnInit(): void {
    this.ratingInfo = camelCaseKeys(this.ratingInfo);
    this.i18n = this.translateService.instant(
      [
        'dgTagRating_ViewSkill',
        'dgTagRating_ViewLevelDescription',
        'dgTagRating_ManagerAssessment',
        'TagRating_AskForNewRating',
        'TagRating_YouWereRatedFormat',
        'TagRating_SkillTargetSetFormat',
      ],
      {
        nameHtml: `<h3 class="center-text h3 color-ebony-a61">${this.ratingInfo.rater?.name}</h3>`,
        level: this.ratingInfo.level,
      }
    );
  }

  public onViewLevelDescriptions(): void {
    this.activeModal.close({
      action: AssociateRatingModalActions.ViewLevelDescriptions,
    });
  }

  public onViewTagDetails(): void {
    this.activeModal.close({
      action: AssociateRatingModalActions.ViewTagDetails,
    });
  }

  public onRequestNewRating(event: Event): void {
    this.activeModal.close({
      action: AssociateRatingModalActions.RequestNewRating,
      event,
    });
  }
}

export enum AssociateRatingModalActions {
  ViewLevelDescriptions,
  ViewTagDetails,
  RequestNewRating,
}
