import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSkillType',
  pure: true,
})
export class GetSkillTypePipe implements PipeTransform {
  public transform(rating: object): any {
    return this.getSkillType(rating);
  }
  public getSkillType(rating): string {
    if (!rating?.type) {
      return null;
    }
    return rating.type;
  }
}
