import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';

// Services
import { AuthService } from '@app/shared/services/auth.service';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { TranslateService } from '@ngx-translate/core';

// Types
import { AuthUser } from '@app/account/account-api.model';
import { OrgSetting } from '@app/orgs/services/orgs.model';
import { ConfigurationValue } from '@app/orgs/configuration-value';

@Component({
  selector: 'dgx-setting-tile',
  templateUrl: './setting-tile.component.html',
  styleUrls: ['./setting-tile.component.scss'],
})
export class SettingTileComponent implements OnInit {
  @Input() public setting: OrgSetting;
  @Input() public orgId: number;
  @Input() public isMessageSetting: boolean;
  @Input() public isGroupedSetting: boolean;
  @Input() public configurationValue: ConfigurationValue;

  @Output() public settingToggled = new EventEmitter();
  @Output() public textUpdated = new EventEmitter();

  public defaultMessage: string;
  public isSaved: boolean;
  public message: string;
  public originalMessage: string;
  public showResetAcceptanceLink: boolean;
  public showToggleSwitch: boolean;

  public readonly ConfigurationValue = ConfigurationValue;

  public i18n = this.translateService.instant([
    'Channel_SettingMessageLabel',
    'Channel_SettingPasswordLabel',
    'Channel_SettingPasswordPlaceholder',
    'Channel_SettingMessagePlaceholder',
    'Channel_SettingHeaderDescription',
    'Channel_SettingDescription',
    'Channel_SettingReveal',
    'Channel_SettingsNotSaved',
    'Core_SettingsSaved',
    'OrgSettings_ResetAcceptance',
    'dgFlexRow_EditTextSection',
  ]);

  private authUser: AuthUser;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private authService: AuthService,
    private orgSettingsService: OrgSettingsService
  ) {}

  public ngOnInit(): void {
    this.authUser = this.authService?.authUser;
    this.showResetAcceptanceLink =
      this.setting.id === 'EnforceDataPrivacyAcceptance';

    this.defaultMessage = this.getDefaultMessage();
    this.message = this.setting.value
      ? this.setting.value
      : this.defaultMessage
        ? this.defaultMessage
        : undefined;

    this.showToggleSwitch =
      this.isMessageSetting ||
      (this.setting.displayType !== 'Field' &&
        this.setting.displayType !== 'Password') ||
      (this.setting.displayType === 'Field' &&
        this.setting.enabled !== undefined);
  }

  public getSettingName(): string {
    const settingKey = `OrgSettings_${this.setting.id}_Name`;
    const result = this.translateService.instant(settingKey);
    const settingName = result === settingKey ? this.setting.name : result;
    return settingName;
  }

  public getSettingDescription(): string {
    const settingKey = `OrgSettings_${this.setting.id}_Desc`;
    const result = this.translateService.instant(settingKey);
    const settingDescription =
      result === settingKey ? this.setting.description : result;
    return settingDescription;
  }

  public getDefaultMessage(): string {
    const settingKey = `OrgSettings_${this.setting.id}_Default`;
    const result = this.translateService.instant(settingKey, {
      OrgName: this.authUser.defaultOrgInfo.name,
      OrgNameSingular: this.authUser.defaultOrgInfo.name,
    });
    const defaultMessage = result === settingKey ? undefined : result;
    return defaultMessage;
  }

  public isDefault(): boolean {
    let isDefault = false;
    if (this.setting.enabled && !this.setting.value && this.defaultMessage) {
      isDefault = this.message === this.defaultMessage ? true : false;
    }
    return isDefault;
  }

  public toggle(setting: OrgSetting, toggleValue?: boolean): void {
    setting.enabled = toggleValue ?? setting.enabled;
    this.settingToggled.emit(setting);
    if (this.isMessageSetting && setting.enabled && !setting.value) {
      this.showMessageEditModal(setting);
    }
  }

  public textChanged() {
    this.textUpdated.emit();
  }

  public showMessageEditModal(setting: OrgSetting): void {
    this.orgSettingsService
      .showMessageEditModal(
        this.orgId,
        setting,
        this.isDefault(),
        this.defaultMessage,
        this.message,
        this.configurationValue
      )
      .subscribe({
        next: (updatedSetting: OrgSetting) => {
          this.setting = updatedSetting;
          this.handleToggle();
        },
      });
  }

  public handleToggle(): void {
    if (this.setting.value) {
      this.message = this.setting.value;
    } else {
      this.message = this.defaultMessage;
    }
    this.cdr.detectChanges();
  }

  public showResetModal(): void {
    this.orgSettingsService
      .showResetPrivacyAcceptanceModal(this.orgId)
      .subscribe();
  }
}
