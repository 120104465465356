<div class="l_flexbar m-guts-p-v-half">
  <!-- profile pic -->
  <dgx-profile-pic
    *ngIf="propPic"
    [fallbackImage]="picFallback"
    [profile]="profile"
    [size]="picSize"
    [useAlt]="picUseAlt"
    [hideMentorIcon]="picHideMentorIcon"
    [mentorIconSize]="picMentorIconSize"
    class="block l_flex-shrink"
  ></dgx-profile-pic>
  <!-- name, optionally email -->
  <div class="no-wrap oh" [class]="propPic ? 'guts-p-l-1' : ''">
    <ng-container [ngSwitch]="person.privacyId">
      <span *ngSwitchCase="'2'" class="ellipsis" [title]="profile.name">{{
        profile.name
      }}</span>
      <a
        *ngSwitchDefault
        [href]="person[propHref]"
        [title]="profile.name"
        target="_blank"
        class="block ellipsis uhover color-ebony"
        data-dgat="data-column-person-952"
        >{{ profile.name }}
      </a>
      <span
        *ngIf="propIsAdmin && person[propIsAdmin]"
        class="par par--small par--light ellipsis"
      >
        - {{ 'Core_RoleAdmin' | translate }}</span
      >
    </ng-container>
    <div
      *ngIf="propEmail"
      [title]="profile.email"
      class="par par--small par--light ellipsis"
    >
      {{ profile.email }}
    </div>
  </div>
</div>
