import { ResourceVersionService } from '@app/resource-version/services/resource-version.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { Pathway } from '../pathway.model';
import {
  PathwayPermissionsModel,
  PathwayLesson,
  PathwaySection,
  PathwayStep,
  PathwayDetailsModel,
} from '@app/pathways/pathway-api.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { DgError } from '@app/shared/models/dg-error';
import { TranslateService } from '@ngx-translate/core';
import { InputIdentifier } from '@app/inputs/inputs-api.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PathwayVersionService } from '@app/resource-version/services/pathway-version.service';
import { AuthService } from '@app/shared/services/auth.service';
import { PathwayWithUserPermissions } from '../rsm/pathway-api.model';

interface GetPathwayWithPermissionsParams {
  obfuscatedId?: string;
  pathId?: number;
  code?: string;
}

interface LogPathwayViewParams {
  pathId: number;
  tlTag?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PathwayService {
  public exclusionList: InputIdentifier[] = [];

  /* eslint-disable @typescript-eslint/member-ordering */
  private permissions$ = new BehaviorSubject<PathwayPermissionsModel>(
    undefined
  );
  public readonly permissions = this.permissions$.asObservable();
  private pathway$ = new BehaviorSubject<PathwayDetailsModel>(undefined);
  public readonly pathway = this.pathway$.asObservable();
  private isEditMode$ = new BehaviorSubject(false);
  public readonly isEditMode = this.isEditMode$.asObservable();
  private isEnrolled$ = new BehaviorSubject(false);
  public readonly isEnrolled = this.isEnrolled$.asObservable();
  private totalSections$ = new BehaviorSubject(0);
  public readonly totalSections = this.totalSections$.asObservable();
  private totalSteps$ = new BehaviorSubject(0);
  public readonly totalSteps = this.totalSteps$.asObservable();
  private completedSteps$ = new BehaviorSubject(0);
  public readonly completedSteps = this.completedSteps$.asObservable();
  private optionalSteps$ = new BehaviorSubject(0);
  public readonly optionalSteps = this.optionalSteps$.asObservable();
  public tltag: string;
  /* eslint-enable */

  private _pathway: Pathway;

  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient,
    private trackerService: TrackerService,
    private route: ActivatedRoute,
    private pathwayVersionService: PathwayVersionService,
    private resourceVersionService: ResourceVersionService,
    private authService: AuthService,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      const disabled = params?.editMode !== 'true';
      this.isEditMode$.next(!disabled);
      this.tltag = params.tltag;
    });
  }

  private setPathway(pathway: Pathway) {
    this.pathway$.next(pathway);
    this._pathway = pathway;
    this.setTotalSections(pathway.levels.length);
    this.updateCounts(pathway);
    this.setEnrollment(pathway.isEnrolled);
    this.setIsEditMode(pathway);
    this.isTrackableSSO(pathway); // should always be called last
  }

  public setIsEditMode(pathway: Pathway) {
    const _pathway = {
      ...pathway,
      isEditMode: this.isEditMode$.value,
      canAuthor: this.canAuthorPathway(),
    };
    this.pathway$.next(_pathway);
  }

  public setTotalSections(n: number) {
    this.totalSections$.next(n);
  }

  public setTotalSteps(n: number) {
    this.totalSteps$.next(n);
  }

  public setCompletedSteps(n: number) {
    this.completedSteps$.next(n);
  }

  public setOptionalSteps(n: number) {
    this.optionalSteps$.next(n);
  }

  public setEnrollment(enrolled: boolean) {
    this.isEnrolled$.next(enrolled);
  }

  public updateCounts(pathway: Pathway) {
    this.setTotalSteps(pathway.totalSteps);
    this.setCompletedSteps(pathway.completedSteps);
    this.setOptionalSteps(pathway.optionalSteps);
  }

  public updateSection(section: PathwaySection) {
    const sectionIndex = this.getIndex(section.number);
    this._pathway.levels[sectionIndex] = section;
    this.pathway$.next(this._pathway);
  }

  public updateLesson(lesson: PathwayLesson) {
    const sectionIndex = this.getIndex(lesson.levelNumber);
    const lessonIndex = this.getIndex(lesson.number);
    this._pathway.levels[sectionIndex].lessons[lessonIndex] = lesson;
    this.pathway$.next(this._pathway);
  }

  public updateStep(step: PathwayStep) {
    const sectionIndex = this.getIndex(step.levelNumber);
    const lessonIndex = this.getIndex(step.lessonNumber);
    const stepIndex = this.getIndex(step.number);
    this._pathway.levels[sectionIndex].lessons[lessonIndex].steps[
      stepIndex
    ] = step;
    this.pathway$.next(this._pathway);
  }

  /**
   * Updates the pathway version when changes are made to pathway apis.  Used to prevent concurrent editing of pathways.
   * Note: This should only be used on NGX Pathway Routed components and services
   * @param pathwayId
   * @return void
   */
  public updateVersion(pathwayId: number, version?: string): void {
    if (this.resourceVersionService.lDConcurrentEditing()) {
      if (version) {
        const pathway = {
          ...this.pathway$.value,
          version: version,
        };
        this.pathway$.next(pathway);
      } else {
        this.pathwayVersionService
          .getVersion(pathwayId)
          .subscribe((newVersion) => {
            const pathway = {
              ...this.pathway$.value,
              version: newVersion,
            };
            this.pathway$.next(pathway);
          });
      }
    }
  }

  /**
   * Used to retrieve the a full pathway object including sections, lessons, etc. as well as the user permissions needed for pathways.
   * @param params object containing either a pathId, obfuscatedId, or code (bofa military)
   */
  public initPathway(
    params: GetPathwayWithPermissionsParams,
    returnPermissions: boolean = false
  ): Observable<{ pathway: Pathway; permissions?: PathwayPermissionsModel }> {
    const pathwayParams = {
      ...params,
      useResourceImages: true,
    };
    return this.http
      .get<PathwayWithUserPermissions>(
        '/pathways/getpathwaywithuserpermissions',
        {
          params: pathwayParams,
          cache: true,
        }
      )
      .pipe(
        tap((response) => {
          this.permissions$.next(response.userPermissions);
        }),
        map((response) => {
          let pathway: Pathway = response.pathway;
          pathway = this.preProcessPathway(pathway);
          pathway = this.setPathIds(pathway);
          if (returnPermissions) {
            return { pathway, permissions: response.userPermissions };
          }
          return { pathway };
        }),
        tap(({ pathway }) => {
          this.setPathway(pathway);
          // TODO: is there a better way to handle this?
          this.pathwayTrackView(pathway.id);
        }),
        catchError((e) => {
          const message = this.translate.instant(
            'Pathways_PathwayDetailAccessError'
          );

          this.router.navigateByUrl('/error-handler/403');
          throw new DgError(message, e);
        })
      );
  }

  /**
   * Defaults to true unless it is a tenant org pathway then checks if the user author its content.
   * Added to fix the issue (https://degreedjira.atlassian.net/browse/PD-80654) where non-admins of tenant
   * org pathways where getting BE errors when adding content.
   * Currently BE logic is set so that non-admins can not add content to Tenant pathways.
   *
   * TODO: Work on a better solution for handling tenant pathway users who are collaborators
   * on the BE (maybe through the canAuthorPathway property).
   * @returns boolean
   *
   */
  private canAuthorPathway() {
    const pathwayOrgId = this.pathway$.value.organizationId;
    // Default to true - Tenant org pathways will always have orgIds
    if (!pathwayOrgId) {
      return true;
    }

    const authUser = this.authService.authUser;
    const canAuthorPathway = this.permissions$.value.canAuthorPathway;
    const isTenantOrgPathway =
      authUser.orgInfo[0].organizationId !== pathwayOrgId;

    return isTenantOrgPathway ? canAuthorPathway : true;
  }

  // checks if url includes orgsso and tltag, if true enroll into pathway
  // and use tltag to "click" and track users event with enrollByTlTag()
  public isTrackableSSO(pathway: Pathway) {
    return this.route.queryParams
      .pipe(
        switchMap((params: { orgsso: string; tltag: string }) =>
          iif(
            () => !!(params.orgsso && params.tltag),
            this.enrollByTlTag(pathway.id, params.tltag),
            of(false)
          )
        ),
        map((success) => {
          if (success) {
            this.setEnrollment(true);
            this._pathway = {
              ...this._pathway,
              isEnrolled: true,
            };
          }
        })
      )
      .subscribe();
  }

  /**
   * Additional pathway logging needed to handle BE tracking
   *
   * @param LogPathwayViewParams params
   * @returns void
   */
  public logPathwayView(params: LogPathwayViewParams) {
    return this.http
      .get<void>('/Pathways/LogPathwayView', {
        params,
      })
      .subscribe();
  }

  private preProcessPathway(pathway: Pathway) {
    for (const section of pathway.levels as PathwaySection[]) {
      section.totalLessons = section.lessons.length;
      for (const lesson of section.lessons as PathwayLesson[]) {
        for (let i = lesson.steps.length - 1; i >= 0; i--) {
          const step: PathwayStep = lesson.steps[i];
          if (!step.complete && !pathway.nextStepToComplete) {
            pathway.nextStepToComplete = step.id;
            section.autoExpand = true;
            lesson.autoExpand = true;
          }
          if (!!step.reference) {
            step.edited = 0; // has been edited 0 times (helps trackby)
            step.reference = this.normalizeStepReference(step, pathway.id);
            // TODO: pass exclusion list to the Add to Pathway modal>search
            this.exclusionList.push({
              inputId: step.reference.inputId,
              inputType: step.reference.inputType,
            });
          } else {
            // clean up broken steps
            console.error('Missing Reference object on Step.', step);
            this.trackerService.trackEventData({
              action: 'Missing Reference',
              category: 'Pathway Step',
              label: '',
              properties: step,
            });
            lesson.steps.splice(i, 1);
          }
        }
        lesson.visibleSteps = [];
      }
    }
    pathway.privacyLevel = this.convertServerPrivacy(
      pathway.privacyLevel as any
    );
    return pathway;
  }

  private convertServerPrivacy(serverPrivacyString: string) {
    let clientPrivacyNumber;
    const pathwayPrivacyMap = {
      Author: Visibility.private,
      ProfileVisible: Visibility.profile,
      Group: Visibility.groups,
      Public: Visibility.public,
    };
    clientPrivacyNumber = pathwayPrivacyMap[serverPrivacyString];
    return clientPrivacyNumber;
  }

  private normalizeStepReference(step: PathwayStep, pathwayId: number) {
    step.reference.pathwayStepDetails = {
      pathwayId,
      isOptional: !step.isRequired,
      node: step.node,
    };
    step.reference.hasUserDescription =
      !!step.description && step.description !== step.reference.summary;
    step.reference.title =
      typeof step.title !== 'undefined' && step.title !== null
        ? step.title
        : step.reference.title;
    step.reference.summary =
      typeof step.description !== 'undefined' && step.description !== null
        ? step.description
        : step.reference.summary;
    step.reference.imageUrl =
      typeof step.imageUrl !== 'undefined' && step.imageUrl !== null
        ? step.imageUrl
        : step.reference.imageUrl;
    return step.reference;
  }

  // Note: set isEnrolled Subject to true so the pathway-header component get's updated
  private enrollByTlTag(pathId: number, tlTag: string) {
    return this.http.post<boolean>('/Pathways/EnrollByTlTag', {
      pathId,
      tlTag,
    });
  }

  // Note: anything updated here should probably also be updated in the Degreed Button (ebb) version of PathwaySvc
  private setPathIds(pathway: Pathway) {
    for (const section of pathway.levels as PathwaySection[]) {
      section.pathId = pathway.id;
      for (const lesson of section.lessons as PathwayLesson[]) {
        lesson.pathId = pathway.id;
      }
    }
    return pathway;
  }

  private pathwayTrackView(pathId: number) {
    return this.http.post<void>('/pathways/viewed', { pathId: pathId });
  }

  private getIndex(n: number) {
    return n === 0 ? 0 : n - 1;
  }
}
