import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';

// misc
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { OpportunityServiceBase } from '@app/opportunities/services/opportunity-service-base';

// types
import { JobRolesItem } from '@app/opportunities/components/job-roles/job-role.model';
import { OpportunityType } from '@app/orgs/org-opportunity-types';

// services
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { WindowToken } from '@app/shared/window.token';
import { ModalService } from '@app/shared/services/modal.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';

@Injectable({
  providedIn: 'root',
})
export class OpportunityModalService extends OpportunityServiceBase {
  // Behavior subject for opportunity types
  private opportunityTypesSubject: BehaviorSubject<OpportunityType[]> =
    new BehaviorSubject<OpportunityType[]>([]);

  constructor(
    authService: AuthService,
    http: NgxHttpClient,
    notifierService: NotifierService,
    pageTitle: Title,
    translateService: TranslateService,
    private modalService: ModalService,
    @Inject(WindowToken) windowRef: Window
  ) {
    super(
      authService,
      http,
      notifierService,
      pageTitle,
      translateService,
      windowRef
    );

    // Load initial opportunity types
    this.loadOpportunityTypes().subscribe();
  }

  // Return Behavior subject as an observable
  public get opportunityTypes$(): Observable<OpportunityType[]> {
    return this.opportunityTypesSubject.asObservable();
  }

  /**
   * Search for job roles
   *
   * - This is used primarily by the job-roles.component to provide search results.
   * - Searches are uniquely debounced and length limited to two characters or more.
   * - Errors are swallowed and an empty result set is returned instead.
   *
   * This function is intentionally defined as a function property to support ngb-typeahead
   * searching where `this` is undefined.
   */
  public search: TypeaheadSearchFunction<string, JobRolesItem> = (
    term: Observable<string>
  ): Observable<readonly JobRolesItem[]> => {
    return term.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      switchMap((term: string) => {
        return this.getJobRoles(term);
      }),
      catchError(() => {
        // for now, swallow and return no results
        return of([] as JobRolesItem[]);
      })
    );
  };

  /**
   * Get job roles from the back-end.
   *
   * @param term - The term to match job roles against.
   */
  public getJobRoles(term: string): Observable<JobRolesItem[]> {
    return this.http.get('/targets/getsuggestedjobroles', {
      params: {
        term,
        count: 20,
      },
    });
  }

  /**
   * Pre-load the opportunity types and pass them to Behavior Subject.
   */
  public loadOpportunityTypes(): Observable<any> {
    // TODO: This is all a little convoluted, is there no other way to pre-load
    // the types result?
    return this.getOpportunityTypes().pipe(
      map((opportunityTypes) => {
        this.opportunityTypesSubject.next(
          // since types coming from the back-end here are always singular,
          // and should always be internal, there's no need for the work of
          // opportunitiesService.getLocalizedTypes
          opportunityTypes.map((opportunityName) => ({
            name: opportunityName,
            i18n: this.translateService.instant(
              `Opportunities_Type${opportunityName}`
            ),
          }))
        );
      }),
      catchError(() => of([] as OpportunityType[]))
    );
  }

  /**
   * Get the opportunity types from the back-end.
   */
  public getOpportunityTypes(): Observable<string[]> {
    return this.http.get('/opportunities/getorganizationopportunitytypes');
  }

  public showCloseConfirmModal() {
    const inputs = {
      headerText: this.translateService.instant('Opportunities_UnsavedChanges'),
      bodyText: this.translateService.instant('Opportunities_DiscardChanges'),
      submitButtonText: this.translateService.instant('Opportunities_Discard'),
      canCancel: true,
    };
    return this.modalService.show(SimpleModalComponent, { inputs });
  }
}
