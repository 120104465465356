import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { switchMap } from 'rxjs/operators';

import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { NotificationItemService } from './notification-item.service';

/** Represents a single integration skill assessments have been found */
@Component({
  selector: 'dgx-single-assessment-found-add-skill',
  template: `
    <span (click)="addSkill()" [innerHTML]="safeContent"></span>
    <div class="color-ebony-a61 par par--small guts-p-t-half">
      {{ i18n.OrgSkills_SkillLevel }}
    </div>
    <button
      type="button"
      class="link par par--small guts-p-t-half"
      (click)="addSkill()"
    >
      {{ 'OrgSkills_AddSkillTitle' | translate }}
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleAssessmentFoundAddSkillComponent
  extends NotificationItemComponentBase
  implements OnInit
{
  public i18n = this.itemService.translate.instant([
    'OrgSkills_AddSkillTitle',
    'OrgSkills_AlreadyAddedSkill',
    'OrgSkills_SkillLevel',
    'OrgSkills_SuccessAddingSingleSkill',
  ]);

  constructor(
    cdr: ChangeDetectorRef,
    itemService: NotificationItemService,
    private tagsService: TagsService,
    private notifierService: NotifierService
  ) {
    super(cdr, itemService);
  }

  public ngOnInit(): void {
    this.i18n.OrgSkills_SkillLevel = this.itemService.translate.instant(
      'OrgSkills_SkillLevel',
      {
        skillLevel: this.params.skillRating.ratingLevel,
      }
    );
    super.ngOnInit();
  }

  public addSkill() {
    const tagToAdd: Partial<TagsApi.TagDetails> = {
      tagId: this.params.skillRating.tagId,
      name: this.params.skillRating.tagName,
      isFollowing: null,
      internalUrl: null,
      title: null,
      resourceId: null,
      resourceType: null,
      rating: null,
      ratings: null,
      isFocused: null,
    };
    this.tagsService
      .getUserTopTags(false, false, false)
      .pipe(
        switchMap((data) => {
          const hasItem =
            data.filter((skill) => {
              return skill.tagId === tagToAdd.tagId;
            }).length > 0;
          if (hasItem) {
            this.notifierService.showSuccess(
              this.i18n.OrgSkills_AlreadyAddedSkill
            );
          } else {
            return this.tagsService.addUserTag(tagToAdd as TagsApi.Tag);
          }
        })
      )
      .subscribe(() => {
        this.notifierService.showSuccess(
          this.i18n.OrgSkills_SuccessAddingSingleSkill
        );
      });
  }

  protected buildTemplate() {
    return this.itemService.translate.instant(
      'Notification_SingleAssessmentFoundAddSkill',
      {
        skillName: this.params.skillRating.tagName,
        providerName: this.params.providerName,
      }
    );
  }
}
