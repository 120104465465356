<dgx-modal
  bodyClasses="guts-p-t-0 "
  [canCancel]="false"
  [isHeaderBorderless]="false"
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
  [isFooterless]="true"
  [isLoading]="isLoading"
  trapFocus
>
  <ng-container class="modal-header">
    {{ modalTitle }}
  </ng-container>

  <ng-container class="modal-body">
    <form
      role="form"
      [formGroup]="form"
      class="form-wrap guts-p-full-2"
      (ngSubmit)="onSubmit()"
    >
      <div [class.input-success-kickstart]="shouldShowResults">
        <div class="input-success-wrap">
          <df-form-root [form]="form" [model]="model" [fields]="fields">
          </df-form-root>
        </div>
        <!--Submit button and submission results-->
        <dgx-global-add-results
          [buttonText]="i18n.dgUserOutcomeEditForm_SaveToProfile"
          [isCompleting]="true"
          [submissionStatus]="submissionStatus"
          [classifier]="'achievements'"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </div>
    </form>
  </ng-container>
</dgx-modal>

<ng-template #country let-label="label" let-formControl="formControl">
  <div class="l_flex">
    <input
      required="false"
      [placeholder]="i18n.dgUserOutcomeEditForm_UniversityCountry"
      [formControl]="formControl"
      [attr.aria-label]="label"
      [ngbTypeahead]="countrySearch"
      [resultTemplate]="searchResultItem"
      (selectItem)="onTypeaheadSelectItem(formControl, $event.item.id)"
      data-dgat="degreeForm-eda"
    />
    <df-spinner
      *ngIf="isLoadingCountries"
      spinnerContext="textInput"
    ></df-spinner>
  </div>
</ng-template>

<ng-template #institution let-label="label" let-formControl="formControl">
  <div class="l_flex">
    <!--explicitly handling the FormControl manipulation here since the value would otherwise be the full institution data instead of its name-->
    <input
      class="input df-text-input df-text-input-border"
      type="text"
      required="true"
      [class.df-form__field--invalid]="
        formControl.invalid && (formControl.touched || formControl.dirty)
      "
      [placeholder]="i18n.dgUserOutcomeEditForm_UniversityName"
      [formControl]="formControl"
      [editable]="false"
      [attr.aria-label]="label"
      [ngbTypeahead]="institutionSearch"
      [resultTemplate]="institutionItem"
      [inputFormatter]="formatInstitutionResult"
      (selectItem)="onTypeaheadSelectItem(formControl, $event.item)"
      data-dgat="degreeForm-c9b"
    />
    <df-spinner
      *ngIf="isLoadingInstitutions"
      spinnerContext="textInput"
    ></df-spinner>
  </div>
</ng-template>

<!-- template for search results -->
<ng-template #searchResultItem let-name="result" let-term="term">
  <span class="listbox--option">
    <ngb-highlight
      [result]="name"
      [term]="term"
      [highlightClass]="'font-semibold'"
    ></ngb-highlight>
  </span>
</ng-template>

<ng-template #institutionItem let-result="result" let-term="term">
  <!--reuse the search result template presenting result.label -->
  <ng-container
    *ngTemplateOutlet="
      searchResultItem;
      context: { result: result.label, term: term }
    "
  ></ng-container>
</ng-template>

<ng-template #level let-label="label" let-formControl="formControl">
  <dgx-select
    [options]="levels"
    [selectedItem]="formControl.value"
    (selection)="formControl.setValue($event.id)"
    trackBy="id"
    labelKey="name"
    dgatInput="select-4f4"
    class="full-width"
    [ariaLabel]="label"
  ></dgx-select>
</ng-template>

<ng-template
  #skillsView
  let-formControl="formControl"
  let-params="params"
  dfSkill
>
  <dgx-tags-edit
    #tagsEditor
    class="form-wrap guts-m-t-1-quart"
    [tags]="formControl.value"
    (tagsChange)="onTagsChange($event, formControl)"
  ></dgx-tags-edit>
</ng-template>
