import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { AwardGlobalAddFacade } from '../../services/global-add/award-global-add.facade';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  Input,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AwardField, AwardModel } from '../../award.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AwardMapperService } from '../../services/award-mapper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AwardService } from '../../services/award.service';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { AwardTrackerService } from '../../services/award-tracker.service';
import { WindowToken } from '@app/shared/window.token';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';
import { resetIfWhitespaceOnly } from '@app/user-content/user-input-v2/utils/form-field-helper';

@Component({
  selector: 'dgx-award',
  templateUrl: './award-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    OutcomeNotificationService,
    OutcomesService,
    AwardGlobalAddFacade,
    AwardMapperService,
    AwardService,
    AwardTrackerService,
  ],
})
export class AwardComponent implements OnInit {
  public vm$: Observable<AwardModel>;
  public awardForm: FormGroup;
  public heading: string;
  public markSubFormsAsTouched = false;
  public submitButtonText: string;

  @Input() public isEditing: boolean;
  @Input() public userOutcomeId: number;

  public i18n = this.translate.instant([
    'Core_Title',
    'MediaFormCtrl_TitleRequired',
    'dgProfileCollectionItem_CertIssuedBy',
    'CertificateFormCtrl_IssuerName',
    'CertificateFormCtrl_IssuerURL',
    'Core_SelectDate',
    'AwardFormCtrl_AwardIssuer',
    'AwardFormCtrl_AwardTitle',
    'dgOrgInternalContent_SkillsTooltipText',
    'Core_Skills',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContent_SkillsMaxError',
    'dgContentHosting_DragAndDrop',
    'dgUserOutcomeEditForm_EditAward',
    'AwardFormCtrl_AddAward',
    'AwardFormCtrl_SaveAward',
    'AwardFormCtrl_AwardTitleLabel',
    'AwardFormCtrl_AwardDateLabel',
    'AwardFormCtrl_AwardDate',
    'Core_Save',
  ]);

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private facade: AwardGlobalAddFacade,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private globalAddTrackingService: GlobalAddTrackingService,
    @Inject(WindowToken) private windowRef: Window
  ) {
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.facade.initializeViewModel();
    } catch (error) {
      console.error('Error during initializeViewModel:', error);
    }
    this.heading = this.isEditing
      ? this.i18n.dgUserOutcomeEditForm_EditAward
      : this.i18n.AwardFormCtrl_AddAward;

    this.submitButtonText = this.isEditing
      ? this.i18n.Core_Save
      : this.i18n.AwardFormCtrl_SaveAward;

    if (this.isEditing) {
      await this.facade.initializeEdit(this.userOutcomeId);
      this.initializeExpandedEditForm();
      return;
    }

    this.initializeForm();
  }

  public async onSubmit(): Promise<void> {
    this.facade.markFormAsTouched(this.awardForm);
    this.markSubFormsAsTouched = true;
    this.cdr.detectChanges();

    if (this.awardForm.invalid) {
      return;
    }

    try {
      await this.facade.onSubmit(this.awardForm);
    } catch (error) {
      console.error('Error during submit:', error);
    }
  }

  public loadInferredSkills() {
    this.facade.loadInferredSkills(
      this.awardForm.get('title').value || '',
      this.awardForm.get('issuer').value || ''
    );
  }

  private initializeForm() {
    this.awardForm = this.formBuilder.group({
      title: ['', Validators.required],
      issuer: ['', Validators.required],
      issuerUrl: [],
      startDate: [],
      imageUrl: [],
      skills: [],
    });
  }

  private initializeExpandedEditForm(): void {
    this.awardForm = this.formBuilder.group({
      title: this.facade.snapshot.title ?? '',
      issuer: this.facade.snapshot.issuer ?? '',
      issuerUrl: this.facade.snapshot.issuerUrl ?? '',
      startDate: this.facade.snapshot.startDate ?? null,
      imageUrl: this.facade.snapshot.imageUrl ?? '',
      skills: [this.facade.snapshot.skills],
    });
  }

  public onDismiss(event: Event) {
    this.activeModal.dismiss();
    this.globalAddTrackingService.trackGlobalAddCancelled('Award');
  }

  public onFormControlUpdate(field: AwardField, value: any) {
    this.facade.onFormControlUpdate(this.awardForm, field, value);
  }

  public onNavigateToCollection(collectionUrl: string) {
    this.activeModal.close(this.facade.snapshot);
    if (collectionUrl) {
      this.windowRef.location.href = collectionUrl;
    }
  }

  transformInput(formControlName: string) {
    resetIfWhitespaceOnly(this.awardForm, formControlName);
  }
}
