<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!coursePathwaysPlansForm"
    #form
    #formRef="ngForm"
    class="reactive-forms"
    [formGroup]="coursePathwaysPlansForm"
    (ngSubmit)="vm.isInitialForm ? onNext(formRef) : onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="
        vm.isInitialForm ? i18n.Core_Next : i18n.CourseFormCtrl_SaveCourse
      "
      [canCancel]="true"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      [modalHeaderTitle]="heading"
      bodyClasses="guts-p-h-2 guts-p-v-2"
    >
      <!-- Modal body -->
      <ng-container class="modal-body" *ngIf="vm.isInitialForm; else full">
        <div
          role="group"
          [attr.aria-label]="i18n.CourseFormCtrl_HowAdded"
          [attr.aria-invalid]="!!urlErrorMessage"
          [ngClass]="
            coursePathwaysPlansForm.hasError('atLeastOneRequired')
              ? 'ng-invalid ng-touched ng-dirty'
              : 'ng-valid ng-pristine ng-untouched'
          "
          aria-errormessage="course-url-errors"
        >
          <div class="reactive-forms__control">
            <button
              type="button"
              id="courseTypeId"
              class="reactive-forms__toggle btn full-width full-height guts-p-v-1-half l_flex-middle"
              [class.active]="vm.isCourseTypeSelected(courseTypeId, source.id)"
              [attr.aria-pressed]="
                vm.isCourseTypeSelected(courseTypeId, source.id)
              "
              dgxAutofocus
              (click)="onCourseTypeChange(source.id)"
              data-dgat="course-pathways-plans-44b"
            >
              <df-icon
                icon="{{ source.icon }}"
                class="block guts-m-b-half"
              ></df-icon>
              <span class="block par--small">{{
                source.label | translate
              }}</span>
            </button>
          </div>
          <ng-container
            *ngIf="!vm.isCourseTypeSelected(courseTypeId, source.id)"
          >
            <div class="divider guts-m-v-1">
              <span
                class="par par--small par--light up-me bg-color-white divider__content"
                >{{ i18n.Core_Or }}</span
              >
            </div>
            <ng-container *ngTemplateOutlet="courseUrlField"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </dgx-modal>

    <!-- still inside form, so that we can use `formControlName` -->
    <ng-template #full>
      <!-- Notification-->
      <df-local-notification
        *ngIf="vm.inputContext.isEditing; else orgManagedCheck"
        class="block guts-m-b-1-half"
        size="small"
        [text]="editContentNotificationText"
        [type]="NotificationType.info"
      ></df-local-notification>

      <ng-template #orgManagedCheck>
        <dgx-org-managed-notification
          class="guts-m-b-1 block"
          *ngIf="addToCatalog && !vm.inputContext.organizationId"
          [resourceType]="vm.inputContext.pathwayId ? 'Pathway' : 'SkillPlan'"
          link="/articles/5900536194588-About-Org-managed-Content"
          [linkTextKey]="i18n.Core_OrgManagedDocLinkLabel"
        ></dgx-org-managed-notification>
      </ng-template>

      <!-- Add to Catalog -->
      <ng-container *ngIf="vm.canManageContent && !vm.inputContext.isEditing">
        <div class="reactive-forms__control">
          <dgx-add-to-catalog
            [checkboxLabel]="addToCatalogCheckboxLabel"
          ></dgx-add-to-catalog>
        </div>
      </ng-container>

      <!-- Provider Name -->
      <div
        class="reactive-forms__control"
        *ngIf="!addingByUrl && !vm.inputContext.isEditing; else courseUrlField"
      >
        <dgx-reactive-forms-label for="institutionName">
          {{ i18n.CourseFormCtrl_ProviderName }}
        </dgx-reactive-forms-label>
        <dgx-search-field-wrapper type="rounded-full-width">
          <input
            dgxAutofocus
            type="text"
            formControlName="institutionName"
            id="institutionName"
            autocomplete="off"
            aria-errormessage="institutionName-error"
            [placeholder]="i18n.CourseFormCtrl_Provider_Placeholder"
            [ngbTypeahead]="onProviderSearch"
            [inputFormatter]="inputFormatter"
            [resultTemplate]="resultTemplate"
            (selectItem)="onProviderSelect($event)"
            (blur)="onProviderSet()"
            data-dgat="course-pathways-plans-a54"
          />
        </dgx-search-field-wrapper>
        <dgx-validate-field
          id="institutionName-error"
          class="block"
          [message]="
            showError('institutionName')
              ? i18n.CourseFormCtrl_SelectProvider
              : ''
          "
        ></dgx-validate-field>
      </div>

      <!-- Course Name -->
      <div class="reactive-forms__control">
        <dgx-reactive-forms-label for="name" [isRequired]="true">
          {{ i18n.Core_Title }}
        </dgx-reactive-forms-label>
        <input
          *ngIf="
            vm.inputContext.isEditing || addingByUrl;
            else courseNameSearch
          "
          type="text"
          id="name"
          formControlName="name"
          [dgxAutofocus]="isEditingLocalContent"
          [placeholder]="i18n.MediaFormCtrl_Title_Placeholder"
          (blur)="onCourseNameSetSimple()"
          data-dgat="course-pathways-plans-9b1"
        />
        <ng-template #courseNameSearch>
          <dgx-search-field-wrapper type="rounded-full-width">
            <input
              [dgxAutofocus]="false"
              type="text"
              formControlName="name"
              id="name"
              autocomplete="off"
              aria-errormessage="name-error"
              [placeholder]="i18n.MediaFormCtrl_Title_Placeholder"
              [ngbTypeahead]="onCourseNameSearch"
              [inputFormatter]="inputFormatter"
              [resultTemplate]="resultTemplate"
              (selectItem)="onCourseNameSelect($event)"
              (blur)="onCourseNameSet()"
              data-dgat="course-pathways-plans-9b1"
            />
          </dgx-search-field-wrapper>
        </ng-template>
        <dgx-validate-field
          class="block"
          [message]="
            showError('name')
              ? vm.inputContext.isEditing
                ? i18n.MediaFormCtrl_TitleRequired
                : i18n.CourseFormCtrl_SelectCourse
              : ''
          "
        ></dgx-validate-field>
      </div>

      <!-- Course URL -->
      <ng-container *ngIf="!addingByUrl && !vm.inputContext.isEditing">
        <ng-container *ngTemplateOutlet="courseUrlField"></ng-container>
      </ng-container>

      <!-- Description  -->
      <div
        class="reactive-forms__control"
        [class.reactive-forms__read-only]="vm.readonly.description"
      >
        <dgx-reactive-forms-label for="description">
          {{ i18n.Core_Description }}
        </dgx-reactive-forms-label>
        <textarea
          id="description"
          formControlName="description"
          [attr.maxlength]="vm.limits.description"
          [placeholder]="
            i18n.dgOrgInternalContentForm_CourseDescriptionPlaceholder
          "
          [readonly]="vm.readonly.description"
          aria-describedby="description-character-limit"
          data-dgat="course-pathways-plans-1f4"
        ></textarea>
        <div id="description-character-limit" class="a11y-hide">
          {{
            'Core_CharacterLimit' | translate: { limit: vm.limits.description }
          }}
        </div>
      </div>

      <!-- Duration -->
      <dgx-duration
        *ngIf="showDurationField"
        [class.reactive-forms__read-only]="isEditingLocalContent"
        class="block reactive-forms__control"
        [formModel]="{
          durationHours: vm.durationHours,
          durationMinutes: vm.durationMinutes,
        }"
        [isReadOnly]="isEditingLocalContent"
      ></dgx-duration>

      <!-- Content Owner -->
      <div
        *ngIf="showOwnerField"
        [class.reactive-forms__read-only]="isEditingLocalContent"
        class="reactive-forms__control"
      >
        <dgx-reactive-forms-label
          for="owner"
          [icon]="!isEditingLocalContent ? 'info' : null"
          [isRequired]="isOwnerRequired"
          [iconTooltip]="i18n.dgOrgInternalContentForm_ContentOwnerTooltip"
          [iconA11yText]="i18n.Core_MoreInfo"
        >
          {{ i18n.dgOrgInternalContentForm_ContentOwner }}
        </dgx-reactive-forms-label>
        <dgx-user-search
          id="owner"
          aria-errormessage="owner-error"
          [useSearchField]="false"
          [recipients]="!!vm.owner ? [vm.owner] : []"
          [search]="userGroupListService.loadGroupsUsersRecommendees"
          [ariaLabel]="i18n.dgOrgInternalContentForm_ContentOwner"
          [allowRemoval]="!isEditingLocalContent"
          [isRequired]="isOwnerRequired"
          [isReadOnly]="isEditingLocalContent"
          [placeholder]="i18n.dgOrgInternalContentForm_ContentOwnerPlaceholder"
          (removeRecipient)="onContentOwnerChange()"
          (selectRecipient)="onContentOwnerChange($event)"
        ></dgx-user-search>
        <dgx-validate-field
          id="owner-error"
          class="block"
          [message]="showError('owner') ? ownerErrorMessage : ''"
        ></dgx-validate-field>
      </div>

      <!-- Image -->
      <div class="reactive-forms__control">
        <dgx-upload-section
          [imageUrl]="vm.imageUrl"
          [uploadAdapter]="vm.imageUploadAdapter"
          (imageParsedEvent)="vm.imageUpload.onParse($event)"
          (uploadSuccessEvent)="
            vm.imageUpload.onUpload(coursePathwaysPlansForm, $event)
          "
          (deleteEvent)="vm.imageUpload.onDelete(coursePathwaysPlansForm)"
          [useCropper]="true"
          [aspectRatio]="16 / 9"
          [secondaryAspectRatio]="16 / 5"
          [useBrowserReplace]="true"
        >
        </dgx-upload-section>
      </div>

      <!-- Skills-->
      <div *ngIf="!isEditingLocalContent" class="reactive-forms__control">
        <dgx-tags-edit
          [hideHint]="true"
          [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
          [customTitle]="i18n.Core_Skills"
          [topTags]="vm.mediumConfidenceInferredSkills"
          [hideTopTags]="!vm.mediumConfidenceInferredSkills?.length"
          [hideTooltip]="false"
          [tooltipIcon]="'info'"
          [placeholder]="i18n.MediaFormCtrl_Skills_Placeholder"
          [allowExistingOnly]="false"
          [tags]="coursePathwaysPlansForm.get('tags').value"
          [showFullWidthLabel]="true"
          [useNewTagsDesign]="true"
          (tagsChange)="onFormControlUpdate('tags', $event)"
          aria-errormessage="tags-error"
        ></dgx-tags-edit>
        <dgx-validate-field
          id="tags-error"
          class="block"
          [message]="
            showError('tags') ? i18n.dgOrgInternalContent_SkillsMaxError : ''
          "
        ></dgx-validate-field>
      </div>

      <!-- Advanced Settings -->
      <dgx-advanced-settings
        *ngIf="showAdvancedSettings"
        [formModel]="{
          visibility: vm.orgContentMetadata,
          publishedDate: vm.publishDate,
          internalItemId: vm.externalId,
          language: vm.language,
        }"
      ></dgx-advanced-settings>
    </ng-template>

    <!-- result template for our search fields -->
    <ng-template #resultTemplate let-item="result" let-term="term">
      <span class="listbox--option">
        <ngb-highlight
          [result]="item.value"
          [term]="term"
          [highlightClass]="'font-semibold'"
        ></ngb-highlight>
      </span>
    </ng-template>

    <!-- course URL template, shared by the two forms, will be first child if adding by URL -->
    <ng-template #courseUrlField>
      <div
        *ngIf="
          isEditingLocalContent
            ? !!vm.courseUrl
            : coursePathwaysPlansForm.get('courseUrl') && !hideUrlField
        "
        class="reactive-forms__control"
        [class.reactive-forms__read-only]="
          vm.readonly.courseUrl || isEditingLocalContent
        "
      >
        <dgx-reactive-forms-label
          for="courseUrl"
          [isRequired]="isCatalogContent"
          [ariaLabel]="
            vm.isInitialForm ? i18n.CourseFormCtrl_CourseUrlAria : ''
          "
        >
          {{ i18n.CourseFormCtrl_CourseUrl }}
        </dgx-reactive-forms-label>
        <!-- only required if adding by URL is truthy -->
        <input
          #courseUrl
          [dgxAutofocus]="isCatalogContent"
          autocomplete="off"
          type="url"
          id="courseUrl"
          formControlName="courseUrl"
          placeholder="http://"
          [attr.aria-errormessage]="
            (!vm.isInitialForm ? 'courseUrl-error ' : '') +
            'courseUrl-duplicates'
          "
          [readonly]="vm.readonly.courseUrl || isEditingLocalContent"
          (input)="vm.isInitialForm ? onCourseTypeChange() : undefined"
          data-dgat="course-pathways-plans-fb9"
        />
        <dgx-check-duplicate-inputs
          id="courseUrl-duplicates"
          *ngIf="vm.duplicateCount && !urlErrorMessage"
          [duplicateCount]="vm.duplicateCount"
          (viewDuplicates)="openViewDuplicates()"
        ></dgx-check-duplicate-inputs>
        <dgx-validate-field
          id="courseUrl-error"
          class="block"
          [message]="urlErrorMessage"
        ></dgx-validate-field>
      </div>
    </ng-template>
  </form>
</ng-container>
