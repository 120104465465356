import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-close-opportunities-modal',
  templateUrl: './close-opportunities-modal.component.html',
})
export class CloseOpportunitiesModalComponent {
  public i18n = this.translateService.instant([
    'OrgManage_Opportunities_BulkCloseNotify',
    'OrgManage_Opportunities_BulkCloseWarning',
    'OrgManage_Opportunities_Close',
  ]);
  public notifyCandidates = false;

  @Input() public bodyText = this.i18n.OrgManage_Opportunities_BulkCloseWarning;
  @Input() public headerText = '';
  @Input() public notifyText = this.i18n
    .OrgManage_Opportunities_BulkCloseNotify;
  @Input() public opportunityIds: number[];
  @Input() public secondaryBodyText = '';
  @Input() public submitButtonText = this.i18n.OrgManage_Opportunities_Close;

  constructor(private translateService: TranslateService) {}
}
