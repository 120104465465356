/**
 * Orgs module components within an Angular router-outlet
 */
export enum RouterComponents {
  CONTENT_CATALOG_PAGE = 'ContentPageComponent',
  DOWNLOADABLE_REPORTS = 'DownloadableReportsComponent',
  FTP_NAV = 'FtpNavComponent',
  ORG_GROUPS = 'OrgGroupsComponent',
  ORG_INSIGHTS_LEARNING_SUMMARY_DETAILS = 'OrgInsightsLearningSummaryDetailsComponent',
  ORG_INSIGHTS_NAV = 'OrgInsightsNavComponent',
  ORG_MEMBERS = 'OrgMembersComponent',
  ORG_REPORTING_COMPONENT = 'OrgReportingComponent',
  ORG_PLANS = 'OrgPlansComponent',
  ORG_REPORTING_SKILL_COMPONENT = 'OrgReportingSkillComponent',
  ORG_SETTINGS_NAV = 'OrgSettingsNavComponent',
  ORG_SKILLS_NAV = 'OrgSkillsNavComponent',
  ORG_TARGETS = 'OrgTargetsComponent',
  ORGS_MANAGE_PATHWAYS = 'OrgsManagePathwaysComponent',
  REPORTING_NAV_NGX_ROUTING = 'ReportingNavNgxRoutingComponent',
  BUSINESS_RULES_VIEW = 'BusinessRulesViewComponent',
  MANAGE_CREDENTIALS_COMPONENT = 'ManageCredentialsComponent',
  PEOPLE_NAV = 'OrgPeopleNavComponent',
  SEGMENTS_VIEW = 'SegmentsViewComponent',
  ATTRIBUTES_VIEW = 'AttributesView',
  ORG_REPORTING_SKILL_TRENDS_COMPONENT = 'OrgReportingSkillTrendsComponent',
}

// Card type options when you are in this area: Org Settings => Home Tab => Insight Cards Tab
export enum CardType {
  AssignedLearning = 'AssignedLearning',
  MonthlyActivity = 'MonthlyActivity',
  Link = 'Link',
}
