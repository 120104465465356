import { Injectable, TemplateRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';

import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { VideoCommonFieldBuilder } from '../video-common-field.builder';
import { VideoFormModel } from '../video-form.model.model';
import { VideoUploadAdapter } from '../video-upload.adapter';
import { RenderMode } from '../../user-input.model';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

/** Creates an array of form field configurations for the initial media-selection step of adding/editing
 * Videos within the content catalog */
@Injectable({ providedIn: 'any' })
export class ContentCatalogInitialVideoFormRenderer
  implements FormRenderer<VideoFormModel>
{
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl) => {
        const valid = HTTP_REQUIRED_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('MediaFormCtrl_UrlRequired'),
    },
  };

  private urlValidatorAllowEmpty = {
    urlValidation: {
      ...this.urlValidator.urlValidation,
      expression: (control: AbstractControl) => {
        const valid =
          HTTP_REQUIRED_URL_PATTERN.test(control.value) ||
          control.value?.trim() === '';
        return valid;
      },
    },
  };

  public expandAdvanced = new BehaviorSubject(false);
  constructor(
    private translate: TranslateService,
    private builder: DfFormFieldBuilder,
    private uploadAdapter: VideoUploadAdapter,
    private videoFieldBuilder: VideoCommonFieldBuilder,
    private webEnvironmentService: WebEnvironmentService,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext<VideoFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();

    if (
      vm.isMediaParsed ||
      vm.uploadedContentDetails ||
      context.inputContext.isEditing ||
      context.inputContext.renderMode !== RenderMode.ContentCatalog
    ) {
      throw new Error('Wrong state for renderer');
    }
    const fields: DfFormFieldConfig[] = [];

    fields.push(
      this.builder
        .optionalTextInput('mediaUrl', 'MediaFormCtrl_VideoUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidatorAllowEmpty) // allow empty to be valid initially to not throw errors if user wants to upload or record instead
        .withPlaceholder('https://')
        .withDgatId('articleForm-97a')
        .autofocused()
        .build()
    );

    // Video uploader
    fields.push(
      this.buildVideoUploaderField(
        context.state,
        context.templates.contentUploader
      )
    );
    // Record button
    fields.push(
      this.videoFieldBuilder.buildRecordButtonField(
        vm.videoConstraints$,
        context.inputContext.isChannel ? null : vm.organizationId,
        vm.shouldShowRecordVideoButton,
        context.templates.videoRecordButton,
        context.inputContext.isChannel ? 'gat' : 'orgs'
      )
    );

    return fields;
  }

  private buildVideoUploaderField(
    state: () => VideoFormModel,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.builder
      .customField('mediaUrl', '', contentUploaderTemplate, {
        useExistingFileRestrictions: true,
        uploadAdapter: this.uploadAdapter,
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
        },
        i18n: this.translate.instant(['Core_Or']),
        hasDivider: true,
      })
      .unwrapped()
      .hiddenWhen(() => !state().shouldShowContentUploader)
      .build();
  }
}
