import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Required Learning notification */
@Component({
  selector: 'dgx-required-learning',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequiredLearningComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('requiredLearning_notificationDateFormat', {
      startHtml: this
        .html`<a class="link" href="${this.params.sourceUrl}" data-dgat="requiredlearning-91f">`,
      endHtml: this.html`</a>`,
      date: this.dueDateTemplate,
    });
  }
}
