import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { ModalService } from '@app/shared/services/modal.service';
import { Injectable } from '@angular/core';
import { PathwayDetailsModel, PathwayDataAPI } from '@dg/pathways-rsm';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
/**
 * This PathwayEnrollmentService should only be used inside the pathway view for RSM purposes
 */
export class PathwayEnrollmentRSMService {
  constructor(
    private translate: TranslateService,
    private modalService: ModalService,
    private api: PathwayDataAPI
  ) {}

  public showUnenrollConfirmationModal = () => {
    const modalI18n = this.translate.instant([
      'Pathways_WithdrawFromPathway',
      'Pathways_WithdrawFromPathwayBody',
      'Core_YesSure',
    ]);
    const inputs = {
      canCancel: true,
      closeOnSubmit: false,
      headerText: modalI18n.Pathways_WithdrawFromPathway,
      bodyText: modalI18n.Pathways_WithdrawFromPathwayBody,
      submitButtonText: modalI18n.Core_YesSure,
    };
    return this.modalService.show<void>(SimpleModalComponent, {
      inputs,
      errorOnDismiss: false,
    });
  };
  /**
   * Auto enrolls the user to a Pathway behind the scenes via code after an action such as completing first content item
   * @param pathway - existing pathway
   */
  async autoEnroll(pathway: PathwayDetailsModel) {
    const isAutoEnroll = true;
    return this.api.enroll(pathway, isAutoEnroll);
  }
}
