<form class="form-wrap" [formGroup]="settingForm" tabindex="-1" #formElement>
  <dgx-setting-tile-header
    [description]="settingDescription"
    [name]="settingName"
    [enabled]="settingEnabled"
    (toggled)="handleSettingToggled($event)"
  ></dgx-setting-tile-header>
  <div *ngIf="settingEnabled" class="guts-p-t-1-half">
    <div class="grid grid--bleed">
      <div class="grid__col-md-5">
        <label for="setting_{{ setting.id }}">{{ settingLabel }}</label>
        <input
          type="text"
          id="setting_{{ setting.id }}"
          [formControlName]="setting.id"
          data-dgat="setting-tile-toggle-text-6a8"
          [placeholder]="settingPlaceholder"
          [class.border--error]="
            this.settingForm.controls[setting.id].errors &&
            this.settingForm.controls[setting.id].dirty
          "
        />
        <dgx-setting-tile-validation-message
          [errors]="this.settingForm.controls[setting.id].errors"
          [controlIsDirty]="this.settingForm.controls[setting.id].dirty"
          [customErrorId]="this.setting.id"
        ></dgx-setting-tile-validation-message>
      </div>
    </div>
    <dgx-setting-tile-save-footer
      [isFormValid]="settingForm.valid"
      (saved)="handleSave()"
      (cancelled)="handleCancel()"
      *ngIf="settingForm.dirty"
    ></dgx-setting-tile-save-footer>
  </div>
</form>
