<dgx-org-add-page-selection-modal
  *ngIf="stepName === 'select'"
  [stepName]="stepName"
  [orgId]="resolve.orgId"
  (pageSelectionChange)="pageSelectionChange($event)"
></dgx-org-add-page-selection-modal>

<dgx-edit-target
  *ngIf="stepName === 'plan' || stepName == 'directory'"
  [autoPopulate]="resolve.autoPopulate"
  [context]="resolve.context"
  [isAddToTarget]="resolve.isAddToTarget"
  [isManagePlans]="resolve.isManagePlans"
  [loadTarget]="resolve.loadTarget"
  [mode]="resolve.mode"
  [navigateTo]="resolve.navigateTo"
  [orgId]="orgId"
  [showAutoPopulateOption]="resolve.showAutoPopulateOption"
  [targetSource]="resolve.targetSource"
>
</dgx-edit-target>

<dgx-org-bulk-upload-modal
  *ngIf="stepName === 'bulkUpload'"
  [orgId]="resolve.orgId"
  (pageSelection)="pageSelectionChange($event)"
>
</dgx-org-bulk-upload-modal>

<dgx-org-bulk-upload-success-modal *ngIf="stepName === 'bulkUploadSuccess'">
</dgx-org-bulk-upload-success-modal>
