import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { EngagedLearner } from '@app/shared/services/engaged-learner.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

// Once standalone
// - Make sure to include `imports: AutofocusDirective` as dgx-autofocus is a  dependency
// - Remove from the SharedModule

@Component({
  selector: 'dgx-engaged-congratulations-modal',
  templateUrl: './engaged-congratulations-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagedCongratulationsModalComponent
  implements OnInit, AfterViewInit
{
  @Input() public profile: EngagedLearner;

  @ViewChild('description') public description: ElementRef;

  public i18n: { [key: string]: string };

  // there's no definition for this return type - check profile-pic component "@input() profile" for more info
  public get profileForProfilePic() {
    return {
      id: this.profile.id.toString(),
      isEngaged: this.profile.isEngaged,
      name: this.profile.name,
      picture: this.profile.picture,
    };
  }

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.i18n = this.translateService.instant([
      'ActiveLearner_Congratulations',
      'ActiveLearner_CongratulationsText',
      'Core_Close',
    ]);
  }

  public ngAfterViewInit(): void {
    const link = this.description.nativeElement.querySelector('a');
    this.renderer.addClass(link, 'link');
    this.renderer.setAttribute(link, 'role', 'button');
  }

  public close(): void {
    this.activeModal.close();
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
