import { emitOnce } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { Observable, pipe, Subscription, UnaryFunction } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { UserSearchItem } from '@app/user/user-api.model';
import { readFirst } from '@app/shared/utils';
import { SharedTargetService } from '@app/target/services/shared-target.service';

import { OrgPlansService } from '@app/orgs/services/org-plans.service';

import { OrgPlansColumnIndex } from '../org-plans.component';

import { Filter } from '@app/shared/components/filter/filter.component';
import {
  LoadOptions,
  OrgPlansComputedState,
  OrgPlansSource,
  OrgPlanStateWithEntities,
  OrgPlansViewModel,
  initState,
  OrgPlanEntity,
  OrgPlansState,
} from './org-plans.model';
import { PlansStore } from './plans.store';

import {
  makeAuthorUpdates,
  toSelectedPlanTypes,
  makeFilter,
  preparePlan,
  handleStateUpdates,
} from './utils';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';

@Injectable()
export class OrgPlansFacade {
  public vm$: Observable<OrgPlansViewModel>;
  private _store = new PlansStore('orgPlans', initState);
  private _dataSource: OrgPlansSource;
  private _filters: Filter[];
  /**
   * Fixes - PD-94152 | This is used to cancel the previous API call that is redundant.
   */
  private dataSourceSubscription: Subscription;

  constructor(
    private sharedTargetService: SharedTargetService,
    private orgPlansService: OrgPlansService,
    private tracker: TrackerService,
    private translate: TranslateService
  ) {
    // Stream the OrgPlansViewModel
    this.vm$ = this._store.state$.pipe(
      this.addComputedState(),
      this.addViewModelAPI(),
      shareReplay<OrgPlansViewModel>({ refCount: true, bufferSize: 1 })
    );

    this._filters = makeFilter(
      this.translate,
      this.orgPlansService.getLabeledPlanTypes()
    );
  }

  // Fast access to CURRENT read-only values of the view model
  public get snapshot(): OrgPlansViewModel {
    return readFirst(this.vm$);
  }

  // ******************************************************
  // Public API functions
  // ******************************************************

  /**
   * This function is designed to accept a function that returns an observable as its data source,
   * in order to allow different components to use the facade,
   * but each with their own unique endpoint that have identical response structures
   */
  public async loadPlansFromSource(plansSource: OrgPlansSource): Promise<void> {
    this._dataSource = plansSource;
    this.reset();
  }

  /**
   * Sets selection state for plans that are displayed
   */
  public selectPlans(plans: OrgPlanEntity[], selected = true) {
    emitOnce(() => {
      plans.forEach((x) => {
        if (selected) this._store.selectItem(x.id, false);
        else this._store.deselectItem(x.id);
      });
    });
  }

  /**
   * Merges newly selected authors with existing authors and updates plans in the store
   */
  public updateAuthors(
    plans: OrgPlanEntity[],
    newAuthors: { userKeys: number[]; collaborators: UserSearchItem[] }
  ) {
    const updates = makeAuthorUpdates(plans, newAuthors);
    this._store.upsertItems(updates);
  }

  /**
   * Sets the isFeatured property for the provided plan and updates the store
   */
  public togglePlanFeatured(plan: OrgPlanEntity, isFeatured) {
    this._store.upsertItems([{ ...plan, isFeatured }]);
  }

  /**
   * Sets the privacyId property for the provided plans and updates the store
   */
  public updateVisibility(plans: OrgPlanEntity[], privacyId: Visibility) {
    this._store.upsertItems(plans.map((x) => ({ ...x, privacyId })));
  }

  /**
   * Deletes provided entity records from the store
   */
  public deletePlans(ids: string[]) {
    this._store.deleteEntities({ entityIds: ids });
  }

  /**
   * Resets loading options back to initial state and triggers a query to the data source
   */
  public reset() {
    const { term, paging, ordering, filterType } = initState();
    this.loadPlans({
      term,
      paging: { ...paging },
      ordering: { ...ordering },
      filterType,
      clearPlans: true,
    });
  }

  // ****************************************************************************
  // Internal View Model API methods
  // ****************************************************************************

  /**
   * Loads more plans into the store.  Note: implementation currently only supports infinite scroll
   */
  private loadMore() {
    const { paging, isLoading } = this._store.snapshot;
    if (!isLoading) {
      this.loadPlans({
        paging: { skip: paging.skip + paging.take },
        clearPlans: false,
      });
    }
  }

  /**
   * Updates the orderBy column and sort direction and triggers a query to the data source
   * Defaults to ascending sort direction on new column sorts.
   * Toggles sort direction if sorting on the current order by value.
   */
  private updateSort(column: OrgPlansColumnIndex) {
    const { ordering } = this._store.snapshot;

    const currentlySortingOnColumn = ordering.orderBy === column;
    const isDescending = currentlySortingOnColumn
      ? !ordering.isDescending
      : false;

    this.loadPlans({
      paging: { skip: 0 },
      ordering: { orderBy: column, isDescending },
      clearPlans: true,
    });
  }

  /**
   * Updates search term in the store and triggers a query to the data source
   * Tracks event if a term is provided
   */
  private search(term: string) {
    if (term) {
      // only track when a user includes a search term
      this.tracker.trackEventData({ action: 'Org Plan Searched' });
    }

    this.loadPlans({ term: term, paging: { skip: 0 }, clearPlans: true });
  }

  /**
   * Updates filters in the store and triggers a query to the data store
   */
  private updateFilters(filters: Filter[]) {
    this._filters = filters;
    this.loadPlans({
      paging: { skip: 0 },
      filterType: toSelectedPlanTypes(filters),
      clearPlans: true,
    });
  }

  // *******************************************************************
  // Internal View Model fabrication
  // *******************************************************************

  /**
   * Build an RXJS operator that will build the ViewModel when the store changes
   */
  private addViewModelAPI(): UnaryFunction<
    Observable<OrgPlanStateWithEntities & OrgPlansComputedState>,
    Observable<OrgPlansViewModel>
  > {
    return pipe(
      map((store: OrgPlanStateWithEntities) => {
        const { entities, ids, ...state } = store;

        return {
          ...state, //exclude entities, ids
          loadMore: this.loadMore.bind(this),
          updateSort: this.updateSort.bind(this),
          search: this.search.bind(this),
          filters: this._filters,
          updateFilters: this.updateFilters.bind(this),
        } as OrgPlansViewModel;
      })
    );
  }

  /**
   * Build an RXJS operator that will build the Computed Properties when the store changes
   */
  private addComputedState(): UnaryFunction<
    Observable<OrgPlansState>,
    Observable<OrgPlansState & OrgPlansComputedState>
  > {
    const filterSelected = (p: OrgPlanEntity) => p.isSelected;

    return pipe(
      map((store: OrgPlansState) => {
        const { entities, ids, paging, ...state } = store;
        const allEntities = ids?.map((id) => entities[id]) ?? [];
        return {
          ...state,
          paging,
          allPlans: allEntities,
          selectedPlans: allEntities.filter(filterSelected),
        } as OrgPlansState & OrgPlansComputedState;
      })
    );
  }

  // *****************************************************************
  // Unpublished helper methods
  // *****************************************************************

  /**
   * Queries the data source and handles state properties
   */
  private async loadPlans(options?: Partial<LoadOptions>) {
    /**
     * Unsubscribe from the previous subscription to avoid multiple active subscriptions.
     * Each subscription responds asynchronously, which can lead to conflicting updates
     * in the UI and cause incorrect behavior.
     */
    this.dataSourceSubscription?.unsubscribe();
    this.dataSourceSubscription = this._dataSource(
      this.validateOptions(options)
    )
      .pipe(this._store.trackLoadStatus())
      .subscribe(({ hasMoreItems, results }) => {
        results.forEach((plan) => {
          preparePlan(plan, this.sharedTargetService);
        });

        emitOnce(() => {
          if (options?.clearPlans)
            this._store.deleteEntities({ deleteAll: true });
          this._store.update(
            handleStateUpdates(hasMoreItems, {
              ...options,
              // Retain the `term` as the latest version while the user is still typing.
              // If the API has already been called, it preserves the previous term value and updates the store with that value,
              // which overwrites the user's input and can appear as a bug to the user.
              term: this._store.snapshot.term,
            }),
            results
          );
        });
      });
  }

  // merges overrides with existing state properties to create loading params
  private validateOptions(options?: Partial<LoadOptions>): LoadOptions {
    const { term, paging, ordering, filterType } = this._store.snapshot;

    return {
      term: options?.term ?? term,
      filterType: options?.filterType ?? filterType,
      paging: {
        ...paging,
        ...options?.paging,
      },
      ordering: {
        ...ordering,
        ...options?.ordering,
      },
    };
  }
}
