<div
  class="combobox"
  [ngClass]="{ 'm-full-width': !isFixedWidth, 'fixed-width': isFixedWidth }"
  (clickOutside)="isMultiSelect && closeDropdown()"
  dgxClickOutside
>
  <div
    class="field-group"
    [ngClass]="{
      'field-group--focus': isFieldGroupFocus,
      'fixed-width': isFixedWidth
    }"
  >
    <df-input-decorator
      appendageType="button"
      class="full-width"
      [ngClass]="{
        'multi-select--active': showMultiSelectStyle
      }"
    >
      <input
        #inputElement
        (blur)="!isMultiSelect && handleBlur()"
        (focus)="!readonly && !disabled && toggleDropdown(true)"
        (keyup)="
          isMultiSelect ? handleMultiSelectKeyup($event) : handleKeyup($event)
        "
        (ngModelChange)="modelChanged.next($event)"
        [(ngModel)]="inputText"
        autocomplete="off"
        aria-autocomplete="list"
        attr.aria-expanded="{{ isFieldGroupFocus }}"
        attr.aria-owns="combobox-dropdown-{{ hostId }}"
        attr.aria-label="{{ ariaLabel }}"
        attr.aria-controls="combobox-dropdown-{{ hostId }}"
        class="df-text-input combobox-input ellipsis"
        attr.data-dgat="{{ dgatInput }}-input"
        placeholder="{{ placeholder }}"
        role="combobox"
        [disabled]="disabled"
        [readonly]="readonly"
        attr.aria-disabled="{{ readonly }}"
        type="text"
      />
      <button
        df-button
        dfButtonType="clear"
        df-append
        (click)="setGroupFieldFocus(!isFieldGroupFocus)"
        (mousedown)="isDropdownDisplayed = !isDropdownDisplayed"
        [attr.aria-label]="ariaLabel"
        attr.data-dgat="{{ dgatInput }}-dropdownToggle"
        attr.aria-controls="combobox-dropdown-{{ hostId }}"
        [disabled]="readonly || disabled"
        tabindex="-1"
      >
        <df-icon icon="chevron-down" size="small"></df-icon>
      </button>
    </df-input-decorator>
  </div>

  <!-- Dropdown results -->
  <ng-container *ngIf="!readonly && !disabled && isDropdownDisplayed">
    <ul
      #scrollContainer
      (mousedown)="handleMousedown($event)"
      class="l_flex l_flex-column combobox__menu dropdown-menu dropdown-menu--open dropdown-menu--empty dropdown-menu-left guts-p-v-half rounded--large white-space scrollbar"
      id="combobox-dropdown-{{ hostId }}"
      role="listbox"
      tabindex="{{ selectedIndex }}"
      [ngSwitch]="optionsLoaded"
      [@fadeInAndOut]
    >
      <cdk-virtual-scroll-viewport
        itemSize="32"
        minBufferPx="224"
        maxBufferPx="224"
        class="l_flex l_flex-row guts-m-b-1 scrollbar"
      >
        <!-- Exact matches toggle -->
        <ng-container *ngSwitchDefault>
          <ng-content select="[message]"></ng-content>
          <li
            *ngIf="isExactMatchesEnabled"
            class="listbox--option combobox__option cursor-default guts-p-v-0 guts-p-h-0"
            attr.data-dgat="{{ dgatInput }}-exactMatchesOption"
            role="option"
          >
            <div
              class="border-bottom l_flexbar l_flex-justify guts-p-v-1 guts-p-h-1"
              attr.data-dgat="{{ dgatInput }}-exactMatchesDiv"
              id="exactMatches"
            >
              {{ 'Core_ExactMatches' | translate }}
              <dgx-toggle-switch
                [isChecked]="isExactMatches"
                [ariaLabel]="'Core_ToggleExactMatches' | translate"
                (toggled)="toggleExactMatches($event)"
                id="exactMatchesToggleSwitch"
                attr.data-dgat="{{ dgatInput }}-exactMatchesCheckbox"
              ></dgx-toggle-switch>
            </div>
          </li>
        </ng-container>
        <!-- loading results -->

        <ng-container *ngSwitchCase="false">
          <li
            #listOptions
            class="l_flex l_flex-middle listbox--option combobox__option"
            role="option"
          >
            {{ loadingText ? loadingText : i18n.Core_LoadingResults }}
          </li>
        </ng-container>
        <!-- filtered results -->
        <ng-container *ngSwitchDefault>
          <ng-container
            *ngIf="(filteredOptions$ | async).length > 0; else noResults"
          >
            <ng-container
              *cdkVirtualFor="
                let option of filteredOptions$ | async as options;
                let i = index;
                let first = first;
                trackBy: trackById
              "
            >
              <!-- group divider/label -->
              <ng-container *ngIf="isGrouping(option, i) | async">
                <div *ngIf="!first" class="combobox__divider"></div>
                <div
                  *ngIf="!!option.groupingLabel"
                  [innerHtml]="option.groupingLabel"
                  [ngClass]="{ 'guts-m-t-1': first }"
                  class="l_flex l_flex-middle combobox__group-label"
                ></div>
              </ng-container>
              <!-- multi select / non multi select templates -->
              <ng-container
                *ngIf="isMultiSelect; then multiSelect; else nonMultiSelect"
              ></ng-container>
              <ng-template #multiSelect>
                <li
                  #listOptions
                  [ngClass]="{ 'guts-m-t-1': first }"
                  class="l_flex l_flex-middle listbox--option combobox__option {{
                    option.classname
                  }}"
                  attr.data-dgat="{{ dgatInput }}-listOption"
                  role="option"
                >
                  <label class="l_flex full-width cursor-pointer">
                    <input
                      type="checkbox"
                      [checked]="option.isChecked"
                      (change)="toggleSelection($event, option, i)"
                      data-dgat="combobox-379"
                    />
                    <!-- Apply the lcid as the lang attribute if it exists in the options. This is mainly for the
                    profile settings language drop downs -->
                    <div
                      class="inline guts-m-l-1 cursor-pointer"
                      [attr.lang]="option.lcid ? option.lcid : ''"
                    >
                      {{ option[labelKey] }}
                    </div>
                  </label>
                </li>
              </ng-template>
              <ng-template #nonMultiSelect>
                <li
                  #listOptions
                  id="listbox-item-{{ i }}"
                  (click)="selectOption(i)"
                  (mousedown)="handleMousedown($event)"
                  [ngClass]="{
                    'listbox--option-selected': i === selectedIndex,
                    'guts-m-t-1': first
                  }"
                  class="l_flex l_flex-middle listbox--option combobox__option {{
                    option.classname
                  }}"
                  role="option"
                  [attr.aria-setsize]="filteredOptionsLength"
                  attr.data-dgat="{{ dgatInput }}-listOption"
                >
                  <!-- Apply the lcid as the lang attribute if it exists in the options. This is mainly for the
                  profile settings language drop downs -->
                  <div
                    class="inline"
                    [attr.lang]="option.lcid ? option.lcid : ''"
                  >
                    {{ option[labelKey] }}
                  </div>
                </li>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #noResults>
            <div
              class="guts-p-h-1 guts-p-v-2 par--small par--light font-semibold"
            >
              {{ this.hasNoResultsText }}
            </div>
          </ng-template>
        </ng-container>
      </cdk-virtual-scroll-viewport>
      <!-- Multi select clear/apply -->
      <ng-container *ngIf="isMultiSelect && hasSelections">
        <div
          class="l_flex l_flex-row border-top guts-p-t-1 guts-p-b-half guts-p-h-1"
          [@fadeInAndOut]
        >
          <ng-container *ngIf="!hideClearButton; else showOnlyApplyButton">
            <div class="l_flex l_flex-justify-right full-width">
              <button
                df-button
                type="button"
                class="clear"
                (click)="clearFilters()"
                data-dgat="combobox-708"
              >
                <span
                  class="ib-v-middle"
                  [innerHTML]="'Core_Clear' | translate"
                ></span>
              </button>
              <button
                df-button
                dfButtonType="primary"
                class="guts-m-l-half"
                (click)="applyFilters()"
                data-dgat="combobox-da2"
              >
                <span
                  class="ib-v-middle"
                  [innerHTML]="'Core_Apply' | translate"
                ></span>
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ul>
  </ng-container>
</div>

<ng-template #showOnlyApplyButton>
  <button
    df-button
    dfButtonType="primary"
    class="full-width"
    (click)="applyFilters()"
    data-dgat="combobox-da2"
  >
    <span class="ib-v-middle" [innerHTML]="'Core_Apply' | translate"></span>
  </button>
</ng-template>
