import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';

import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdGeneratorService } from '../../../../lib/modules/utilities/layout/id-generator.service';
import { DfControlValueDirective } from '../inputs/directives/control-value/control-value.directive';

/** A simple toggle switch component.
 * @description The toggle switch controls a boolean value, typically a configuration setting on a form, via
 * the {@link isOn} property. As the component extends {@link DfControlValueDirective}, user input changes can be
 * monitored via the {@link valueChange} event.
 */
@Component({
  selector: 'df-toggle-switch',
  template: `
    <div class="__container">
      <input
        #input
        type="checkbox"
        [id]="inputId"
        [tabIndex]="tabIndex"
        [checked]="isOn ? 'checked' : null"
        [disabled]="disabled"
        [required]="required"
        [attr.name]="name"
        [attr.aria-label]="ariaLabel || null"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-checked]="isAriaChecked"
        [attr.aria-describedby]="ariaDescribedby"
        [attr.aria-controls]="ariaControls"
        (change)="handleOnChangesOfCheckedEvent($event)"
        (blur)="onTouched()"
      />
      <div class="__overlay __icons"></div>
      <div class="__overlay __actuator"></div>
    </div>
  `,
  styleUrls: ['./toggle-switch.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // we transfer the tab index provided on the host to the input, nulling the former to remove it from the tab order
    '[attr.tabindex]': 'null',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DfToggleSwitchComponent,
      multi: true,
    },
  ],
})
export class DfToggleSwitchComponent extends DfControlValueDirective<boolean> {
  @Input() public ariaControls: string;
  @ViewChild('input') public _inputElement: ElementRef;
  /** Standard id property */
  @HostBinding('attr.id')
  @Input()
  public id: string;
  @Input() public fieldId: string;
  /** Standard property indicating if the field is required on a form */
  @Input() public required = false;
  /** Standard name property for old-school forms */
  @Input() public name: string;

  private _uniqueId: string;
  private _tabIndex = 0;

  constructor(
    @Attribute('tabindex') tabIndex: string,
    private cdr: ChangeDetectorRef,
    idGenerator: IdGeneratorService
  ) {
    super();
    this._uniqueId = idGenerator.generateStringId('df-toggle-sw');
    this.fieldId = this._uniqueId;
    this.tabIndex = parseInt(tabIndex);
  }
  /** Gets the apparent tab index of the component, which is actually the tab index of the internal input element */
  public get tabIndex(): number {
    return this.disabled ? -1 : this._tabIndex;
  }

  /** Sets the apparent tab index of the component, which is actually the tab index of the internal input element */
  @Input()
  public set tabIndex(value: number) {
    // If the specified tabIndex value is null or undefined, fall back to the default value.
    this._tabIndex = value != null ? coerceNumberProperty(value) : 0;
  }

  /** Gets the unique id for the internal input.
   * @description This is useful for label binding when the component isn't contained by the label.
   * @example <label [for]="toggle.inputId"></label><df-toggle-switch #toggle></df-toggle-switch>
   */
  public get inputId(): string {
    return `${this.fieldId ?? this._uniqueId}`;
  }

  /** Sets the toggle state */
  @Input() public set isOn(value: any) {
    const isOn = coerceBooleanProperty(value); // allow a valueless attribute presence to mean 'true'
    if (!!this.value !== isOn) {
      this.handleOnChangeEvent(isOn);
    }
  }

  /** Gets the toggle state */
  public get isOn() {
    return this.value;
  }

  public get isAriaChecked() {
    return this.value ? 'true' : 'false';
  }

  public focus(options?: FocusOptions): void {
    this._inputElement.nativeElement.focus(options); // pass focus to the child input
  }

  public writeValue(obj): void {
    this.isOn = obj;
    super.writeValue(obj);
    this.cdr.markForCheck();
  }

  public setDisabledState(disabled: true) {
    super.setDisabledState(disabled);
    this.cdr.markForCheck();
  }
}
