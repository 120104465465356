<dgx-list-item [item]="listItemModel" [endorsedSrc]="endorsedSrc">
  <ng-container icon>
    <dgx-image-layout
      format="list"
      *ngIf="item"
      [imageSrc]="item.imageUrl"
      [resourceId]="item.resourceId"
      [resourceType]="item.resourceType"
      [videoUrl]="item.resourceType === 'Video' ? item.url : ''"
      [icon]="icon"
      [providerImage]="item.providerSummary?.image"
    ></dgx-image-layout>
  </ng-container>
  <ng-container button *ngIf="removable">
    <button
      type="button"
      [ngbTooltip]="'Core_Remove' | translate"
      (click)="remove.emit(item)"
      [attr.aria-label]="ariaLabelForRemoveContent"
      data-dgat="resource-result-6c4"
    >
      <df-icon icon="cross" size="medium"></df-icon>
    </button>
  </ng-container>
</dgx-list-item>
