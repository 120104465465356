import { Injectable } from '@angular/core';
import { DfFormFieldConfig, DfTemplateOptions } from '@lib/fresco';
import { FormalCourseRenderer } from './formal-course-renderer';
import { InformalCourseRenderer } from './informal-course.renderer';

import { FormRenderer, RendererContext } from '../../form-renderer.model';

@Injectable({ providedIn: 'root' })
export class UserProfileCourseRenderer implements FormRenderer {
  constructor(
    private formalCourseRenderer: FormalCourseRenderer,
    private informalCourseRenderer: InformalCourseRenderer
  ) {}

  /**
   * Implementing the strategy pattern.
   */
  public render(
    context: RendererContext,
    isGlobalAdd: boolean
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];

    fields =
      context.action.payload === 0
        ? this.formalCourseRenderer.render(context)
        : this.informalCourseRenderer.render(context, isGlobalAdd);
    return fields;
  }
}
