import { Pipe, PipeTransform } from '@angular/core';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';

@Pipe({ name: 'isIframePipe' })
export class IsIframePipe implements PipeTransform {
  constructor(private windowLayoutService: WindowLayoutService) {}

  public transform() {
    return this.windowLayoutService.isIframe;
  }
}
