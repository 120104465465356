import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MentoringHelperService } from './services/mentoring-helper.service';

@NgModule({
  declarations: [],
  exports: [],
  providers: [MentoringHelperService],
  imports: [CommonModule],
})
export class MentoringSharedModule {}
