import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { AbstractControl } from '@angular/forms';
import { focusOnFirstInvalidField } from '@app/user-content/user-input-v2/utils/form-field-helper';
import { AzureUserAuthoredResult } from '@app/content-hosting';

export function getHostedContentDetails(
  fileDetails: AzureUserAuthoredResult
): HostedContentMetadata {
  const { fileId, fileUrl } = fileDetails?.result;
  const { name, size } = fileDetails?.file;

  const fileExtension = name.substring(name.lastIndexOf('.') + 1, name.length);

  const details: HostedContentMetadata = {
    fileId: fileId,
    fileName: name,
    fileSize: size,
    fileType: fileExtension,
    hostType: 'DegreedMedia',
    url: fileUrl,
  };
  return details;
}

/**
 * @deprecated - use {form-field-helper.handleFocusOnSubmit}
 */
export const handleVideoFocusOnSubmit = (formControls: {
  [key: string]: AbstractControl;
}): void => {
  const objectEntries = Object.entries(formControls);
  const durationFormEntry = getDurationFormEntry(objectEntries);
  const controlObjectEntriesInDomOrder = getVideoControlObjectEntriesInDomOrder(
    objectEntries,
    durationFormEntry
  );
  focusOnFirstInvalidField(controlObjectEntriesInDomOrder, durationFormEntry);
};

/**
 * The durationForm field is technically a child component and FormGroup that is appended to the list of form controls.
 * We have to put the form controls in the order that they appear in the dom in order to shift focus
 * to the first field with a validation error upon submit of the form.
 */
const getVideoControlObjectEntriesInDomOrder = (
  objectEntries: [string, AbstractControl][],
  durationFormEntry: [string, AbstractControl]
): [string, AbstractControl][] => {
  const indexOfDescriptionEntry = objectEntries.indexOf(
    objectEntries.find((entry) => entry[0] === 'description')
  );
  const indexOfDurationFormEntry = objectEntries.indexOf(durationFormEntry);

  objectEntries.splice(indexOfDurationFormEntry, 1);
  objectEntries.splice(indexOfDescriptionEntry + 1, 0, durationFormEntry);

  return objectEntries;
};

const getDurationFormEntry = (
  objectEntries: [string, AbstractControl][]
): [string, AbstractControl] =>
  objectEntries.find((entry) => entry[0] === 'durationForm');
