import { Component, Input, OnInit } from '@angular/core';
import { CommentFeedItemModel } from '@app/inputs/inputs-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-delete-comment-confirmation-modal',
  templateUrl: './delete-comment-confirmation-modal.component.html',
  styleUrls: ['./delete-comment-confirmation-modal.component.scss'],
})
export class DeleteCommentConfirmationModalComponent {
  @Input() public comment: CommentFeedItemModel;

  public i18n = this.translateService.instant([
    'CommentsCtrl_DeleteComment',
    'CommentsCtrl_YesDelete',
  ]);

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) {}

  public confirm($event: Event) {
    $event.preventDefault();
    this.activeModal.close();
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
