export enum ConfigurationValue {
  None,
  OrgAdmin,
  Channel,
  OpsTool,
  AllConfigurations,
  OrgCommunications,
  OrgSupportInfo,
  OrgHome,
  OrgAttributes,
  ManageSkills,
}
