import { Observable } from 'rxjs';

/**
 * Supported @Inputs for Toast components
 *
 * These are delivered via DialogService using
 * `@Inject(DIALOG_DATA) public data: ToastProps`
 */
export interface ToastOptions {
  /**
   * Type of message shown in the toast. this is used to change the icon we display
   * in the toast. Defaults to info:
   *
   *   default - neutral information circle
   *   info - purple information circle
   *   warning - yellow exclamation triangle
   *   success - green check circle
   *   error - red exclamation circle
   */
  type?: IconType;

  message?: string;
  title?: string;

  autoClose?: boolean;
  autoCloseDelay?: number; // Duration for which the toast is visible if autoClose is true.

  closeLabel?: string; // pass i18n translated text
  actionLabel?: string; // pass i18n translated text

  close?: () => void; // Show/hide close button for the user to close the toast
  action?: () => void; // @Output, Callback function to be executed on click of the action button
}

/**
 * These toasts do not have title nor close action buttons
 */
export interface ProgressToastOptions {
  type: ProgressIconType;
  message: string;

  autoClose: boolean;
  autoCloseDelay: number; // Duration for which the toast is visible if autoClose is true.

  close?: () => void;
  closeLabel: string; // pass i18n translated text

  progress$?: Observable<ProgressToastEvent>;
}

export interface ProgressToastEvent {
  percentage: number;
  message?: string;
  error?: string;
}

export interface IconStyle {
  color: string;
  icon: string;
}
export type IconType = 'default' | 'info' | 'success' | 'warning' | 'error';

export type ProgressIconType = 'upload' | 'download';

export const initToastOptions = (): ToastOptions =>
  ({
    type: 'default',

    message: '<missing message>',
    title: '',

    autoClose: true,
    autoCloseDelay: 2000,

    actionLabel: '',
    closeLabel: '',
  }) as ToastOptions;

export const initProgressToastOptions = (): ProgressToastOptions =>
  ({
    type: 'upload',
    message: '<missing message>',

    autoClose: true,
    autoCloseDelay: 3000,

    closeLabel: '',
  }) as ProgressToastOptions;
