import { Injectable } from '@angular/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { EpisodeFormModel } from '../episode-forms.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
@Injectable({ providedIn: 'root' })
export class EpisodeProfileEditRenderer implements FormRenderer {
  constructor(
    private formBuilder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private translate: TranslateService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return this.buildFormFields(context);
  }

  private buildFormFields(
    context: RendererContext<EpisodeFormModel>
  ): DfFormFieldConfig[] {
    const state = context.state();
    return [
      this.formBuilder
        .fieldGroup('initializedForm', [
          this.formBuilder
            .title()
            .withPlaceholder(context.translationKeys.Core_AudioTitlePlaceholder)
            .withDgatId('episodeForm-cca')
            .asOptional()
            .readonlyWhen(() => true)
            .build(),
          this.formBuilder
            .foreignField<MonthPickerFieldParams>(
              'dateCompleted',
              context.translationKeys.EpisodeFormCtrl_DateCompleted,
              MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
              {
                isMaxDateToday: true,
              }
            )
            .readonlyWhen(() => true)
            .hiddenWhen(() => !state.initializedForm.dateCompleted)
            .asOptional()
            .build(),
          this.formBuilder
            .checkbox(
              'audioAuthored',
              context.translationKeys.Core_ICreatedAudio
            )
            .withDgatId('episodeForm-e7f')
            .build(),

          this.formBuilder
            .optionalTextInput(
              'duration',
              context.translationKeys.Core_AudioLength
            )
            .ofType('number')
            .withDgatId('episodeForm-512')
            .withMin(0)
            .asRequired()
            .withStep(0.1)
            .readonlyWhen(() => true)
            .build(),
          this.inputFieldBuilder.buildSkillsField(
            state.authUser.viewerInterests,
            false
          ),
        ])
        .build(),
    ];
  }
}
