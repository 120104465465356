<ng-container *ngIf="vm$ | async as vm">
  <form
    *ngIf="!!awardForm"
    class="reactive-forms"
    [formGroup]="awardForm"
    [class.input-success-kickstart]="vm.shouldShowResults$ | async"
    (ngSubmit)="onSubmit()"
  >
    <dgx-modal
      [useDefaultForm]="false"
      [submitButtonText]="submitButtonText"
      [canCancel]="!(vm.shouldShowResults$ | async)"
      [modalHeaderTitle]="heading"
      [useDefaultSubmitButton]="false"
      [bodyClasses]="{
        'guts-p-full-2': true,
        'input-success-kickstart': (vm.shouldShowResults$ | async),
        first: true,
        second: true,
        third: false
      }"
      [isSubmitDisabled]="vm.isSubmitButtonDisabled"
      [isSubmitPending]="vm.shouldSpinSubmitButton$ | async"
      (dismiss)="onDismiss($event)"
    >
      <ng-container class="modal-body">
        <div class="input-success-wrap">
          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="title" [isRequired]="true">
              {{ i18n.Core_Title }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="title"
              formControlName="title"
              [placeholder]="i18n.AwardFormCtrl_AwardTitleLabel"
              (blur)="loadInferredSkills(); transformInput('title')"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                awardForm.get('title').touched && awardForm.get('title').invalid
              "
              [message]="i18n.AwardFormCtrl_AwardTitle"
            ></dgx-validate-field>
          </div>

          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="issuer" [isRequired]="true">
              {{ i18n.dgProfileCollectionItem_CertIssuedBy }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="issuer"
              formControlName="issuer"
              [placeholder]="i18n.CertificateFormCtrl_IssuerName"
              (blur)="loadInferredSkills(); transformInput('issuer')"
            />
            <dgx-validate-field
              class="block"
              *ngIf="
                awardForm.get('issuer').touched &&
                awardForm.get('issuer').invalid
              "
              [message]="i18n.AwardFormCtrl_AwardIssuer"
            ></dgx-validate-field>
          </div>

          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="issuerUrl">
              {{ i18n.CertificateFormCtrl_IssuerURL }}
            </dgx-reactive-forms-label>
            <input
              type="text"
              id="issuerUrl"
              formControlName="issuerUrl"
              placeholder="https://"
            />
          </div>

          <div class="reactive-forms__control">
            <dgx-reactive-forms-label for="startDate" [isRequired]="true">
              {{ i18n.AwardFormCtrl_AwardDateLabel }}
            </dgx-reactive-forms-label>
            <dgx-date-picker
              [placeholder]="i18n.Core_SelectDate"
              [required]="true"
              [isMaxDateToday]="true"
              id="startDate"
              formControlName="startDate"
            ></dgx-date-picker>
            <dgx-validate-field
              class="block"
              *ngIf="
                awardForm.get('startDate').touched &&
                awardForm.get('startDate').invalid
              "
              [message]="i18n.AwardFormCtrl_AwardDate"
            ></dgx-validate-field>
          </div>

          <div class="reactive-forms__control">
            <dgx-upload-section
              [useCropper]="false"
              [aspectRatio]="16 / 9"
              [secondaryAspectRatio]="16 / 5"
              [fileInfo]="vm.file"
              [imageUrl]="vm.imageUrl"
              [uploadAdapter]="vm.uploadAdapter"
              [isHostImageUrl]="true"
              [labels]="{ header: i18n.dgContentHosting_DragAndDrop }"
              icon="arrow-up-tray"
              (fileChangedEvent)="vm.contentUpload.onContentFileChange($event)"
              (uploadSuccessEvent)="
                vm.contentUpload.onContentUploadSuccess(
                  awardForm,
                  'imageUrl',
                  $event.url
                )
              "
              (errorEvent)="vm.contentUpload.onContentUploadFailure()"
              [useBrowserReplace]="true"
            >
            </dgx-upload-section>
          </div>

          <div class="reactive-forms__control">
            <dgx-tags-edit
              [hideHint]="true"
              [customTagToolTip]="i18n.dgOrgInternalContent_SkillsTooltipText"
              [customTitle]="i18n.Core_Skills"
              [topTags]="vm.inferredSkills$ | async"
              [hideTopTags]="!(vm.inferredSkills$ | async).length"
              [hideTooltip]="false"
              [tooltipIcon]="'info'"
              [placeholder]="i18n.dgOrgInternalContent_SkillsPlaceholderText"
              [allowExistingOnly]="false"
              [tags]="awardForm.get('skills').value || []"
              [showFullWidthLabel]="true"
              [useNewTagsDesign]="true"
              (tagsChange)="onFormControlUpdate('skills', $event)"
            ></dgx-tags-edit>
            <dgx-validate-field
              class="block"
              *ngIf="
                awardForm.get('skills').touched &&
                awardForm.get('skills').invalid
              "
              [message]="i18n.dgOrgInternalContent_SkillsMaxError"
            ></dgx-validate-field>
          </div>
        </div>
      </ng-container>
      <ng-container class="modal-footer">
        <dgx-global-add-results
          [class.full-width]="vm.shouldShowResults$ | async"
          [buttonText]="submitButtonText"
          [isEditing]="false"
          [isCompleting]="true"
          [isNewbUser]="vm.isNewbUser$ | async"
          [submissionStatus]="vm.submissionStatus$ | async"
          [classifier]="'achievements'"
          [showCancelButton]="false"
          [addSpacing]="false"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </ng-container>
    </dgx-modal>
  </form>
</ng-container>
