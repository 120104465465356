import { TranslateService } from '@ngx-translate/core';
import {
  DfFieldAddOn,
  DfFormNonNumericInputType,
  DfFormFieldConfig,
  DfFormInputAutocomplete,
  DfFormTextInputType,
  DfNumericInputFieldConfig,
  DfTextFieldConfig,
  DfFormNumericInputType,
} from '../field-types';
import { DfTextFieldBuilder } from './text-field.builder';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Provides specialized methods for building customized single-line (Input element -based) form field configurations
 * @description This is not an injectable service. To create an instance, inject {link @ DfFormFieldBuilder} and
 * call one of its field type initializer methods.
 */
export class DfTextInputFieldBuilder<
  T extends DfFormFieldConfig = DfTextFieldConfig
> extends DfTextFieldBuilder<T> {
  constructor(translate: TranslateService, config: DfTextFieldConfig) {
    super(translate, config);
  }

  /** Specifies the 'number' {@link HTMLInputElement} type on the configuration's template options. By default,
   * the min and max are undefined and the step is 1.
   * @returns a new DfNumericInputFieldBuilder */
  public ofType(inputType: DfFormNumericInputType): DfNumericInputFieldBuilder;

  /** Specifies one of the textual (i.e., non-numeric) {@link HTMLInputElement} types on the configuration's template options  */
  public ofType(inputType: DfFormNonNumericInputType): DfTextInputFieldBuilder;

  /** Specifies one of the supported {@link HTMLInputElement} types on the configuration's template options  */
  public ofType(inputType: DfFormTextInputType): DfTextInputFieldBuilder {
    this.config.templateOptions.type = inputType;
    if (inputType === 'number') {
      this.config.templateOptions.step = 1; // default numeric inputs to step of 1 and no min or max
      return new DfNumericInputFieldBuilder(
        this.translate,
        this.config as DfNumericInputFieldConfig
      );
    }
    return this;
  }

  /** Sets an autocomplete field type on the configuration's template options  */
  public autocompletingAs(autocomplete: DfFormInputAutocomplete) {
    this.config.templateOptions.autocomplete = autocomplete;
    return this;
  }

  /** Sets a pattern match expression to use on the configuration's template options  */
  public matching(pattern: string | RegExp) {
    this.config.templateOptions.pattern = pattern;
    return this;
  }

  // TODO: Move these add-on methods to the DfTextFieldBuilder base class once supported by DfTextarea
  // (see https://degreedjira.atlassian.net/browse/PD-52860)

  /** Sets add-on on the configuration's template options, to be prepended to the start of the field.  */
  public prepend(addOn: DfFieldAddOn) {
    this.config.templateOptions.prependage = addOn;
    return this;
  }

  /** Sets add-on on the configuration's template options, to be appended to the end of the field.  */
  public append(addOn: DfFieldAddOn) {
    this.config.templateOptions.appendage = addOn;
    return this;
  }
}

// Related DfNumericInputFieldBuilder declared below to avoid circular references

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * Provides specialized methods for building customized numeric form field configurations
 * @description This is not an injectable service. To create an instance, inject {link @ DfFormFieldBuilder} and
 * call one of its field type initializer methods.
 */
export class DfNumericInputFieldBuilder extends DfTextInputFieldBuilder<DfNumericInputFieldConfig> {
  constructor(translate: TranslateService, config: DfNumericInputFieldConfig) {
    super(translate, config);
  }

  /** Sets the minimum numeric value accepted by the input. */
  public withMin(min: number) {
    this.config.templateOptions.min = min;
    this.validatedByIndexed({
      min: {
        expression: (v) => v.value >= min,
        message: this.translate.instant('Core_InvalidMinValue', { min }),
      },
    });
    return this;
  }

  /** Sets the maxium numeric value accepted by the input. */
  public withMax(max: number) {
    this.config.templateOptions.max = max;
    this.validatedByIndexed({
      max: {
        expression: (v) => v.value <= max,
        message: this.translate.instant('Core_InvalidMaxValue', { max }),
      },
    });
    return this;
  }

  /** Sets the step size used by the browser's increment/decrement UI for the input. */
  public withStep(step: number) {
    this.config.templateOptions.step = step;
    return this;
  }
}
