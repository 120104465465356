import { SectionDisplayInfo } from '@app/flex-framework/flex-section-api.model';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FlexRowSummary } from '@app/flex-framework/flex-api.model';
import {
  GetPathwayTopContent,
  GetTargetTopContent,
  TopContent,
} from '@app/insights/insights-api.model';
import { DataColumn } from '@app/shared/components/data-table/data-table';
import { EllipsisPipe } from '@app/shared/pipes/ellipsis.pipe';
import { TrackerService } from '@app/shared/services/tracker.service';
import { Target } from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ReportingService } from './../services/reporting.service';

@Component({
  selector: 'dgx-content-insights',
  templateUrl: './content-insights.component.html',
})
export class ContentInsightsComponent implements OnInit, OnChanges {
  @Input() public isViewAll?: boolean;
  @Input() public loadCount: number = 10;
  @Input() public orgId: number;
  @Input() public filterOrgId: number;
  @Input() public pathwayId?: number;
  @Input() public target?: Target;
  @Input() public section?: FlexRowSummary;
  @Input() public sectionDetails?: SectionDisplayInfo;
  @Input() public groupId?: number;
  @Input() public viewAllUrl?: string; // TODO: remove after LaunchDarkly.PATHWAY_ROUTER is gone
  @Input() public viewAllRouterLink?: string | any[];

  public chartName = '';
  public loading = true;
  public sorting = false;
  public subsequentLoadCount = 20; // Allow parents to configure the count of rows to be loaded when initializing.
  public initialLoadCount: number;
  public hasData = false;
  public hasMoreItems: boolean;
  public topContent: TopContent[] = [];
  public infiniteScrollDiv;

  public i18n = this.translate.instant([
    'Core_Content',
    'Core_ViewAll',
    'PathwayTopContent_Empty',
    'PathwayTopContent_Title',
    'PathwayTopContent_ViewAllContext',
  ]);

  public columns: DataColumn[] = [
    {
      headClasses: 'font-medium l_w50 guts-p-l-0',
      bodyClasses: 'guts-p-l-0',
      canSort: true,
      label: 'Core_Title',
      prop: 'title',
    } as DataColumn,
    {
      headClasses: 'font-medium',
      canSort: true,
      label: 'Core_Type',
      prop: 'inputType',
    } as DataColumn,
    {
      headClasses: 'font-medium right-text guts-p-r-0',
      bodyClasses: 'right-text guts-p-r-0',
      canSort: true,
      label: 'Core_Completions',
      prop: 'completedCount',
      sortName: 'CompletedCount',
    } as DataColumn,
  ];
  // By default sort by Completions descending
  private isDescending = true;
  private sortName = this.columns[2].sortName;
  constructor(
    private translate: TranslateService,
    private reportingService: ReportingService,
    private tracker: TrackerService,
    private changeDetectorRef: ChangeDetectorRef,
    public readonly ellipsisPipe: EllipsisPipe
  ) {}

  public ngOnInit(): void {
    this.infiniteScrollDiv = document.getElementById(
      'dgx-content-insights-parent'
    );
    this.chartName = this.pathwayId
      ? this.isViewAll
        ? this.i18n.Core_Content
        : this.i18n.PathwayTopContent_Title
      : this.i18n.Core_Content;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.loadCount?.currentValue) {
      this.initialLoadCount = changes.loadCount.currentValue;
    }
    this.resetContent();
    this.loadMoreItems();
  }

  public loadMoreItems() {
    this.loading = true;
    this.getTopContent(this.loadCount)
      .pipe(take(1))
      .subscribe((result) => {
        this.hasMoreItems = result.hasMoreItems;
        this.topContent = [
          ...this.topContent,
          ...result.items.map((item) => ({
            ...item,
            title: this.ellipsisPipe.transform(item.title, 35),
          })),
        ];
        this.hasData = this.topContent.length > 0;
        this.loading = false;
        this.sorting = false;
        this.loadCount = this.subsequentLoadCount;
        // this is super hacky but infinite-scroll does not fill the page on initial load: https://github.com/orizens/ngx-infinite-scroll/issues/207
        if (this.isViewAll && this.hasMoreItems) {
          setTimeout(() => {
            const bounding = this.infiniteScrollDiv.getBoundingClientRect();
            if (bounding.bottom < window.innerHeight) {
              this.loadMoreItems();
            }
          }, 50);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  public trackViewAllClicked() {
    if (!this.pathwayId) {
      this.tracker.trackEventData({
        action: 'Plan Insights Content Module View All Clicked',
        properties: {
          planId: this.target.targetId,
          planTitle: this.target.title,
        },
      });
    }
  }

  public onSort = (key: string) => {
    if (this.loading || this.sorting) {
      return;
    }
    this.sorting = true;
    if (this.sortName === key) {
      this.isDescending = !this.isDescending;
    }
    this.sortName = key;
    this.resetContent();
    this.loadMoreItems();
  };

  private getTopContent(
    count: number
  ): Observable<GetPathwayTopContent | GetTargetTopContent> {
    if (this.pathwayId) {
      return this.reportingService.GetPathwayTopContent(
        this.orgId,
        this.pathwayId,
        this.topContent.length,
        count,
        this.sortName,
        !this.isDescending,
        this.groupId,
        this.filterOrgId
      );
    } else if (this.target) {
      return this.reportingService.GetTargetTopContent(
        this.orgId,
        this.target.targetId,
        this.topContent.length,
        count,
        this.sortName,
        !this.isDescending,
        this.section?.parentNode,
        this.groupId
      );
    } else {
      throw new Error('Missing bindings!');
    }
  }

  private resetContent() {
    // Clear any existing results
    this.topContent.splice(0);

    // Reset the load count to the original value
    this.loadCount = this.initialLoadCount;
  }
}
