import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * DatepickerDataDgat directive is designed to add predefined 
 * data-dgat attributes to the NgbDatepicker component dynamically at runtime. 
 * These data-dgat attributes are used for Cypress automation testing.
 */
@Directive({
  selector: '[dgxDatepickerDataDgat]',
})
export class DatepickerDataDgatDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    const button = this.elementRef?.nativeElement.querySelector(
      "button[title='Previous month']"
    );
    if (button) {
      this.renderer.setAttribute(button, 'data-dgat', 'datepicker-prevMonth');
    }
  }
}
