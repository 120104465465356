import { getDeepCopy } from '@app/shared/utils/property';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  InternalTagRatingTypes,
  TagRatingPrivacy,
  PluralsightSkillRating,
  PluralsightSkillRatingAsNumber,
} from '@app/tags/tags';

// public methods

export const INTERNAL_RATINGS_ORDER = [
  InternalTagRatingTypes.self,
  InternalTagRatingTypes.peer,
  InternalTagRatingTypes.manager,
  InternalTagRatingTypes.evaluation,
  InternalTagRatingTypes.credential,
];

/**
 * Attempts to get a tag's level as a number.
 *
 * @param rawTag - The original tag.
 * @returns - The level as a number, or 0 if conversion is not possible.
 */
export function getTagLevel(rawTag: any): number {
  // return 0 for no tag or no tag rating at all
  if (!rawTag || (!rawTag.rating && !rawTag.currentValue)) {
    return 0;
  }
  // copy tag to avoid mutating it
  const tag = getDeepCopy(rawTag);
  // grab rating or currentValue
  const rating = tag.rating ?? tag.currentValue;
  const level: unknown = rating.level;
  // otherwise, check provider
  switch (rating.providerName) {
    // special handling for...
    case 'Pluralsight':
      switch (level) {
        case PluralsightSkillRating.Novice:
          return PluralsightSkillRatingAsNumber.Novice;
        case PluralsightSkillRating.ProficientEmerging:
          return PluralsightSkillRatingAsNumber.ProficientEmerging;
        case PluralsightSkillRating.ProficientAverage:
          return PluralsightSkillRatingAsNumber.ProficientAverage;
        case PluralsightSkillRating.ProficientAboveAverage:
          return PluralsightSkillRatingAsNumber.ProficientAboveAverage;
        case PluralsightSkillRating.Expert:
          return PluralsightSkillRatingAsNumber.Expert;
      }
      break;
    // default: Degreed, most others.
    case 'Degreed':
    default:
      // If this level does not convert to a number, treat it as a 0.
      const attemptedConversion = Number(level);
      return isNaN(attemptedConversion) ? 0 : attemptedConversion;
  }
}

/**
 * Attempts to get a tag's level as a string. *No translation is done here.*
 *
 * @param rawRating - The original rating.
 * @returns - The raw level, if providerName is unaccounted for.
 */
export function getTagLevelLabel(rawRating: any): any {
  // return an empty string for no rating at all
  if (!rawRating || !rawRating.level) {
    return '';
  }
  // copy rating to avoid mutating it
  const rating = getDeepCopy(rawRating);
  // otherwise, check provider
  switch (rating.providerName) {
    case 'Pluralsight':
      return formatPluralsightRating(rating.level);
    default:
      return rating.level;
  }
}

/**
 * Attempts to determine whether one tag "is" the second tag. Compares
 * on tagId, and, failing that, tag name. Expects a camel-cased Tag.
 *
 * @param a - The first tag (or skill) to compare.
 * @param b - The second tag (or skill) to compare.
 */
export function isMatchingTag(
  a: TagsApi.Tag | Partial<TagsApi.Tag>,
  b: TagsApi.Tag | Partial<TagsApi.Tag>
): boolean {
  // if tagId is defined on both tags and it matches,
  // our tags are a match.
  if (a.tagId && b.tagId && a.tagId === b.tagId) {
    return true;
  }
  // otherwise, check name
  if (a.name.toLowerCase() === b.name.toLowerCase()) {
    return true;
  }
  // no match
  return false;
}

/**
 * Determine if a given tag's primary rating is exernal.
 *
 * @param tag - The tag to check.
 */
export function hasExternalRating(
  tag: TagsApi.Tag | Partial<TagsApi.Tag>
): boolean {
  // get rating
  const rating = tag?.rating;
  // if no rating, obviously no external rating
  if (!rating) {
    return false;
  }
  // otherwise...
  // check `isInternal` value
  if (rating.isInternal) {
    return false;
  }
  // check for `providerName`
  if (!rating.providerName || rating.providerName === 'Degreed') {
    return false;
  }
  // okay, assume external tag rating
  return true;
}

/**
 * Attempts to find the in progress manager rating in a set of tag ratings
 *
 * @param ratings - set of tag ratings
 * @returns - in progress manager rating or undefined
 */
export function getPendingManagerRating(
  ratings: Partial<TagsApi.UserTagRatingDetails>[]
): Partial<TagsApi.UserTagRatingDetails> {
  return ratings?.find(
    (rating) =>
      rating.type === InternalTagRatingTypes.manager && !rating.dateCompleted
  );
}

/**
 * Attempts to find the in progress skill review in a set of tag ratings
 *
 * @param ratings - set of tag ratings
 * @returns - in progress manager rating or undefined
 */
export function getPendingEvaluation(
  ratings: Partial<TagsApi.UserTagRatingDetails>[]
): Partial<TagsApi.UserTagRatingDetails> {
  return ratings?.find(
    (rating) =>
      rating.type === InternalTagRatingTypes.evaluation && !rating.dateCompleted
  );
}

/**
 * Attempts to find the in progress self rating in a set of tag ratings
 *
 * @param ratings - set of tag ratings
 * @returns - in progress self rating or undefined
 */
export function getPendingSelfRating(
  ratings: Partial<TagsApi.UserTagRatingDetails>[]
): Partial<TagsApi.UserTagRatingDetails> {
  return ratings?.find(
    (rating) =>
      rating.type === InternalTagRatingTypes.self && !rating.dateCompleted
  );
}

/**
 * Get only the completed ratings for the given `Tag`
 *
 * @deprecated use `getCompletedRatings` for better type safety
 */
export function getTagCompletedRatings(
  tag: TagsApi.Tag,
  combinePeerRatings: boolean = false
): TagsApi.UserTagRating[] {
  if (!tag?.ratings) {
    return [];
  }

  const ratings = tag.ratings.filter(
    (r) => !!r.dateCompleted && r.type !== InternalTagRatingTypes.target
  );

  if (combinePeerRatings) {
    // only return one completed peer rating
    return ratings
      .filter((rating) => rating.type !== InternalTagRatingTypes.peer)
      .concat(
        ratings.find((rating) => rating.type === InternalTagRatingTypes.peer) ||
          []
      );
  }

  return ratings;
}

/**
 * Get only the completed ratings for the given ratings array
 *
 * This is a replacement for `getTagCompletedRatings` to accept/return the type
 * given to it instead of forcing coercion to `TagsApi.UserTagRating[]`
 */
export function getCompletedRatings<
  T extends Record<string, any> & {
    type: any;
    dateCompleted?: any;
  }
>(ratings: T[], combinePeerRatings: boolean = false): T[] {
  if (!ratings) {
    return [];
  }

  const completed = ratings.filter(
    (r) => !!r.dateCompleted && r.type !== InternalTagRatingTypes.target
  );

  return combinePeerRatings
    ? completed
        .filter(({ type }) => type !== InternalTagRatingTypes.peer)
        .concat(
          ratings.find(({ type }) => type === InternalTagRatingTypes.peer) || []
        )
    : completed;
}

/**
 * Finds if any ratings have certain info redacted because of viewing permissions
 *
 * @param ratings - set of tag ratings
 */
export function tagHasRedactedRatings(
  ratings: Partial<TagsApi.UserTagRatingDetails>[]
): boolean {
  return ratings.some((r) => r.raterProfileKey === 0);
}

/**
 * Determine whether any of the ratings for a given `Tag` are public
 *
 * @param tag
 * @returns boolean
 */
export function tagHasPublicRatings(tag: TagsApi.Tag): boolean {
  return tag.ratings?.some(
    (rating) => rating.privacyId === TagRatingPrivacy.public
  );
}

/**
 * Check for incomplete ratings of a given type for the given `Tag`
 *
 * @param tag
 * @param type
 * @returns boolean
 */
export function tagHasIncompleteRatingsForType(
  tag: TagsApi.Tag,
  type: InternalTagRatingTypes
): boolean {
  if (!tag?.ratings) {
    return false;
  }
  return tag.ratings.some(
    (rating) => rating.type === type && !rating.dateCompleted
  );
}

/**
 * Determine whether ANY of the given tags includes the given rating type
 *
 * @param tags
 * @param type
 * @returns boolean
 */
export function tagsHaveRatingsForType(
  tags: TagsApi.Tag[],
  type: InternalTagRatingTypes
): boolean {
  if (!tags) {
    return false;
  }
  return tags.some((tag) =>
    tag.ratings?.some((rating) => rating.type === type)
  );
}

export function getAverageRatingLevel(
  ratings: (TagsApi.UserTagRating | Partial<TagsApi.UserTagRatingDetails>)[],
  fixedDecimal?: boolean
): string {
  const count = ratings?.length;

  if (!count) {
    return;
  }

  let average;
  if (ratings[0].averageLevel) {
    average = ratings[0].averageLevel;
  } else {
    const sum = ratings?.reduce((a: number, b: TagsApi.UserTagRating) => {
      return a + Number(b.level);
    }, 0);
    average = sum / count;
  }

  return fixedDecimal
    ? `${average % 1 != 0 ? average.toFixed(1) : average}`
    : `${Math.floor(average)}`;
}

/**
 * For the given tag, is the given rating type available?
 *
 * @param tag Tag
 * @param type ratingTypeName
 * @returns boolean
 */
export function isRatingTypeAvailable(tag: TagsApi.Tag, type: string): boolean {
  // users may no longer start Skill Certifications
  if (type === InternalTagRatingTypes.credential) {
    return false;
  }
  return tag.availableRatingTypes?.includes(type);
}

// private methods

/** Format's a tag's Pluralsight-style level (e.g., 'expert', 'emerging-proficient'). */
function formatPluralsightRating(level: string): string {
  // if spaces exist already, this is a correctly-formatted level
  // (pure sanity checking for other places on the site where we might have a human-readable level)
  if (level.indexOf(' ') > -1) {
    return level;
  }
  // otherwise, format accordingly
  return !level.indexOf('-') ? toTitleCase(level) : toTitleCase(level, '-');
}

/** Splits a string either by a given character or by individual letters, and title-cases it. */
function toTitleCase(string: string, splitCar = undefined): string {
  return string
    .split(splitCar)
    .map((w) => w[0].toUpperCase() + w.substr(1))
    .join(' ');
}
