import { Injectable } from '@angular/core';
import { Pathway } from '@app/pathways/pathway.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { orderBy } from '@app/shared/utils/common-utils';
import { PathwayInsightsEnrolledModel } from '@app/pathways/rsm/pathway-api.model';

@Injectable({
  providedIn: 'root',
})
export class PathwayInsightsService {
  public i18n = this.translate.instant([
    'Pathways_PathwayDetailAccessError',
    'Channel_ErrorLoadingOrgs',
  ]);

  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService
  ) {}

  public GetEnrolledInsights(
    pathId: Pathway['id'],
    skip: number,
    take: number,
    sortColumn: string,
    sortAscending: boolean
  ): Observable<any> {
    return this.http
      .get<PathwayInsightsEnrolledModel>('/pathways/getEnrolledInsights', {
        params: {
          pathwayId: pathId,
          skip: skip,
          take: take,
          sortColumn: sortColumn,
          sortAscending: sortAscending,
        },
      })
      .pipe(
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant('Pathways_PathwayDetailAccessError'),
              error
            )
          );
        })
      );
  }

  public GetTenants({
    resourceId,
    organizationId,
    sortBy,
  }: {
    resourceId: number;
    organizationId: number;
    sortBy?: string;
  }): Observable<any[]> {
    return this.http
      .get('/containers/GetSharedOrgsByResource', {
        params: {
          organizationId,
          resourceId,
          resourceType: 'Pathway',
        },
      })
      .pipe(
        map((tenants: any[]) => {
          if (sortBy) {
            tenants.sort((a, b) => orderBy(a, b, `+${sortBy}`));
          }
          return tenants;
        }),
        catchError((error) => {
          return throwError(
            new DgError(
              this.translate.instant('Channel_ErrorLoadingOrgs'),
              error
            )
          );
        })
      );
  }
}
