const DATE_NO_TZ = /^\d{4}-\d{2}-\d{2}$/;
const TIME_NO_TZ = /T\d{2}:\d{2}:\d{2}(.\d+)?$/;

/**
 * Force UTC timezone for DateTime values from the backend that do not have a explicit timezone set.
 *
 * @example
 * // returns 2021-01-01T01:23:45.678Z
 * normalizeDateTime('2021-01-01T01:23:45.678Z')
 *
 * @example
 * // returns 2021-01-01T01:23:45.678Z
 * normalizeDateTime('2021-01-01T01:23:45.678')
 *
 * @example
 * // returns 2021-01-01T00:00:00Z
 * normalizeDateTime('2021-01-01')
 */
export function normalizeDateTime(dateTime?: string, ignoreUTC = false): Date {
  if (!dateTime) return;

  // if there is a time component, or date without a time component, add UTC timezone
  if (TIME_NO_TZ.test(dateTime)) {
    dateTime += 'Z';
  } else if (DATE_NO_TZ.test(dateTime)) {
    dateTime += ignoreUTC ? 'T00:00:00' : 'T00:00:00Z';
  }

  return new Date(dateTime);
}
