<dgx-modal
  [canCancel]="true"
  [useDefaultSubmitButton]="false"
  (dismiss)="dismiss()"
>
  <ng-container class="modal-header">{{
    i18n.GroupFormSvc_EditUserSettings
  }}</ng-container>
  <ng-container class="modal-body">
    <form class="form-wrap guts-m-full-2 m-guts-m-full-1" *ngIf="!loading">
      <div class="l_flex guts-p-b-1-half">
        <div>
          <div class="guts-m-r-2">
            <dgx-profile-pic [profile]="member" size="medium"></dgx-profile-pic>
          </div>
        </div>
        <div class="l_flex-center min-width-0 break">
          <div *ngIf="isPending" class="badge badge-notification">
            {{ i18n.GroupFormSvc_PendingMember | translate }}
          </div>
          <h3 class="h3">
            {{ member.name }}
          </h3>

          <ul class="par par--small par--light font-medium">
            <li *ngIf="member.organizationEmail">
              <a
                href="mailto:{{ member.organizationEmail }}"
                data-dgat="groupMemberForm-255"
                >{{ member.organizationEmail }}</a
              >
            </li>
            <li *ngIf="!member.organizationEmail">
              <a
                href="mailto:{{ member.email }}"
                data-dgat="groupMemberForm-f9d"
                >{{ member.email }}</a
              >
            </li>
          </ul>
          <p class="zeta color-ebony-a61 guts-m-t-half">
            {{ member.bio }}
          </p>
        </div>
      </div>
      <div class="border-top guts-p-b-1-half">
        <h2 class="guts-p-t-1-half par par--small font-bold">
          {{ i18n.GroupFormSvc_Role | translate }}
        </h2>
        <div class="guts-p-t-half">
          <div
            class="ib guts-m-r-1"
            *ngFor="let role of availableRoles | orderBy: 'name'"
            data-dgat="groupMemberForm-980"
          >
            <label>
              <input
                id="{{ role.labelKey }}Option"
                type="radio"
                name="role-option"
                [(ngModel)]="assignedRoleId"
                [value]="role.roleId"
                [disabled]="!allowEditPermissions"
                data-dgat="groupEditMemberModal-c70"
              />
              {{ role.name }}
            </label>
          </div>
          <div
            class="par par--small"
            *ngFor="let role of getOrderedAvailableRoles(availableRoles)"
            data-dgat="groupEditMemberModal-295"
          >
            <p class="font-bold guts-p-t-1 guts-p-b-half">
              {{ 'Core_' + role.labelKey + 'sCan' | translate }}
            </p>
            <p class="font-medium">
              {{ role.permissionNames }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="member.categories.length" class="border-top">
        <h2 class="guts-p-t-1-half par par--small font-bold">
          {{ i18n.GroupFormSvc_TopCategories | translate }}
        </h2>
        <div class="guts-p-t-half guts-p-b-1-half">
          <dgx-tags-list
            [list]="member.categories"
            [hideIcon]="true"
          ></dgx-tags-list>
        </div>
      </div>
      <div class="border-top guts-p-t-1-half" *ngIf="allowRemove">
        <h2 class="par par--small font-bold">
          {{ i18n.dgOrgEditMemberModal_RemoveUser | translate }}
        </h2>
        <div class="guts-p-t-half" aria-live="polite">
          <button
            *ngIf="!confirmRemoval"
            #softRemoveBtn
            [disabled]="removing"
            df-button
            dfButtonType="danger"
            class="js-btn-soft-remove"
            (click)="softRemove()"
            data-dgat="groupEditMemberModal-068"
          >
            <span *ngIf="!removing">{{
              i18n.GroupFormSvc_RemoveFromGroup | translate
            }}</span>
          </button>
          <div *ngIf="confirmRemoval">
            <p class="color-destructive guts-p-b-1">
              {{ i18n.GroupFormSvc_ConfirmRemovalMessage | translate }}
            </p>
            <div class="l_flexbar l_flex-justify">
              <button
                #cancelRemovalBtn
                [disabled]="removing"
                df-button
                dfButtonType="secondary"
                class="js-btn-cancel-removal"
                (click)="cancelRemove()"
                data-dgat="groupEditMemberModal-050"
              >
                {{ i18n.Core_Cancel | translate }}
              </button>
              <button
                [disabled]="removing"
                df-button
                dfButtonType="destructive"
                (click)="remove()"
                data-dgat="groupEditMemberModal-991"
              >
                <span *ngIf="!removing">{{
                  i18n.GroupFormSvc_ConfirmRemoval | translate
                }}</span>
                <span *ngIf="removing">{{
                  i18n.GroupFormSvc_Removing | translate
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="spinner" *ngIf="loading"></div>
  </ng-container>
  <ng-container class="modal-footer" *ngIf="allowEditPermissions">
    <button
      df-button
      dfButtonType="primary"
      [disabled]="loading"
      (click)="submit()"
      data-dgat="groupEditMemberModal-b46"
      [class.is_disabled]="loading"
      [attr.aria-disabled]="loading"
      [attr.aria-label]="loading ? i18n.Core_Loading : i18n.Core_Save"
    >
      <df-spinner-button [isSpinning]="loading">{{
        i18n.Core_Save
      }}</df-spinner-button>
    </button>
  </ng-container>
</dgx-modal>
