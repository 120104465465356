export const DGX_CHATBOT_DEFAULT_PROMPTS = [
  {
    label: 'Find skill-related content',
    icon: 'search',
    description:
      'Explore skills, enhance knowledge, and grow through diverse content.',
    quickReply: [
      'Welcome back to Degreed! What are you interested in learning today?',
      'Great to see you again at Degreed! What new skills or knowledge are you eager to explore today?',
      'Hello and welcome back to Degreed! Which areas are you looking to dive into today?',
      'Welcome back! What topics or courses are you interested in pursuing today at Degreed?',
    ],
  },
  {
    label: 'Curate pathway',
    icon: 'sparkles',
    description:
      'Select and organize content for a specific learning or creative journey.',
    quickReply: [
      "Hi there! I'm thrilled to help you create a new learning pathway.<br />Could you please tell me the topic you're interested in for this pathway?",
      "Hello! I'm excited to assist you in building a fresh learning journey. 🚀 What's the topic you'd like to focus on for this pathway?",
      'Greetings! Ready to embark on a new learning adventure? 🚀 Tell me, what subject are you interested in exploring for this pathway?',
      "Hi! Let's get started on crafting your unique learning pathway. 🚀 Can you share the topic you're keen on delving into?",
      "Welcome! I can't wait to help you develop a personalized learning pathway. 🚀 Which area or topic are you looking to explore?",
      "Hey there! I'm here to guide you through setting up a learning path. 🚀 What particular topic are you interested in for this journey?",
    ],
  },
  {
    label: 'Update your skills',
    icon: 'tag',
    description:
      'Enhance abilities, stay current, and adapt to evolving professional demands.',
    quickReply: [
      'Greetings from Degreed Learning! What can I do for you today? Are you looking to acquire a new skill, refresh your current skill set, or perhaps concentrate on mastering a specific skill?',
    ],
    suggestions: [
      'Update/change my focus skills',
      'Update my skill ratings',
      'Add skills',
    ],
  },
  {
    label: 'Recommend content to my team',
    icon: 'arrow_up_tray',
    description:
      'Guide team with valuable content for enhanced collaboration and productivity.',
    quickReply: [
      'Suggest learning materials for my team.',
      'Propose educational content for my team.',
      "Advise on resources for my team's development.",
      'Provide learning suggestions for my team.',
      "Offer content recommendations for my team's growth.",
    ],
    suggestions: ['View my team', 'Recommend content'],
  },
];

export const ROUTER_CONSTANTS = {
  HOME: '/maestro',
  SKILL_PAGE: '/profile/<user>/skills',
};

export const SKILL_COACH_SUBTYPE = 'Skills';

export const COACH_API = {
  GET_COACH: '/coach/get',
  GET_CONVERSATION: '/coach/conversations',
  CREATE_CONVERSATION: '/coach/conversations/create',
  UPDATE_CONVERSATION: '/coach/conversations/update',
  UPDATE_PARTIAL: '/coach/conversations/updatepartial',
  DELETE_CONVERSATION: '/coach/conversations/delete',
  COACH_LIST: '/coach/list',
  FEEDBACK: '/coach/feedbacks/create',
  RECOMMENDATION: '/coach/recommendations/conversation',
  SUMMARIES: '/coach/summaries/conversation',
  SKILL_REPORT: '/coach/inferences/skillreview',
  REGISTER_CALL: '/coach/registercall',
  GET_FEEDBACK_OPTIONS: '/coach/feedbacks/options',
  GET_VOICE_METADATA: '/coach/audiometadata',
} as const;
