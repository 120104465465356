import { Injectable } from '@angular/core';
import { AnyInputUpdateResult } from '@app/inputs/inputs.model';
import {
  Input,
  InputCreationFeedback,
  InputParameters,
  UpdateInputParameters,
} from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { JsonObject, InputType } from '@app/shared/models/core-api.model';
import { Observable } from 'rxjs';
import { PathwayInputService } from './pathway-input.model';

/**
 * Provides a helper service for pathway media modal form inputs.
 * TODO: Add additional functions here to load and update modal + add base class for getters and setters when ready if applicable
 * */
@Injectable({
  providedIn: 'root',
})
export class PathwayPostService
  implements PathwayInputService<InputParameters>
{
  constructor(private inputsService: InputsService) {}

  /**
   * @param userInputParams parameters for the specific new input type to add and associate with this user
   */
  public addNewInput(
    userInputParams: InputParameters
  ): Observable<InputCreationFeedback> {
    return this.inputsService.addNewInput(userInputParams);
  }

  public updateInput(
    userInputParams: UpdateInputParameters
  ): Observable<AnyInputUpdateResult> {
    return this.inputsService.updateInput(userInputParams);
  }

  public getInput(
    inputId: number,
    inputType: InputType
  ): Observable<Input<JsonObject>> {
    return this.inputsService.getInput(inputId, inputType);
  }
}
