import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { JsonObject } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OutcomeType, UserOutcomeDetail } from '../outcomes-api.model';

@Injectable({
  providedIn: 'root',
})
export class UserOutcomeService extends ApiServiceBase {
  private _userOutcomeModify = new Subject<string>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly userOutcomeModify = this._userOutcomeModify.asObservable();

  private i18n = this.translate.instant([
    'UserOutcomeSvc_AddingError',
    'UserOutcomeSvc_UpdatingError',
    'UserOutcomeSvc_RemovingError',
    'UserOutcomeSvc_RetreivingError',
  ]);

  constructor(
    http: NgxHttpClient,
    private translate: TranslateService,
    private tracker: TrackerService
  ) {
    super(http, translate.instant('UserOutcomeSvc_Error'));
  }

  public notifyOutcomeModified(contentType: string) {
    this._userOutcomeModify.next(contentType);
  }

  public addUserOutcome(
    passedOutcome: UserOutcomeDetail,
    trackingArea: string,
    trackingAction?: string
  ): Observable<UserOutcomeDetail> {
    const outcome: UserOutcomeDetail<string> = {
      ...passedOutcome,
      details: passedOutcome.details
        ? JSON.stringify(passedOutcome.details)
        : undefined,
    };
    return this.post<UserOutcomeDetail>(
      '/useroutcomes/add',
      outcome,
      this.i18n.UserOutcomeSvc_AddingError
    ).pipe(
      tap((_) => {
        this.tracker.trackEventData({
          action: trackingAction || 'Added',
          category: 'User Outcomes',
          label: passedOutcome.contentTypeId,
          properties: {
            Location: trackingArea,
            AchievementType: passedOutcome.contentTypeId,
          },
        });
      })
    );
  }

  public updateUserOutcome(passedOutcome: UserOutcomeDetail): Observable<void> {
    const outcome: UserOutcomeDetail<string> = {
      ...passedOutcome,
      details: passedOutcome.details
        ? JSON.stringify(passedOutcome.details)
        : undefined,
    };
    return this.put<void>(
      '/useroutcomes/update',
      outcome,
      this.i18n.UserOutcomeSvc_UpdatingError
    ).pipe(
      tap((_) => {
        this.tracker.trackEventData({
          action: 'Updated',
          category: 'User Outcomes',
          label: passedOutcome.contentTypeId,
        });
      })
    );
  }

  public deleteUserOutcome(
    userOutcomeId: number,
    outcomeType: OutcomeType
  ): Observable<void> {
    return this.delete<void>(
      '/useroutcomes/delete',
      {
        userOutcomeId: userOutcomeId,
      },
      this.i18n.UserOutcomeSvc_RemovingError
    ).pipe(
      tap((_) => {
        this.tracker.trackEventData({
          action: 'Deleted',
          category: 'User Outcomes',
          label: outcomeType,
        });
      })
    );
  }

  public getUserOutcome(
    userKey: AuthUser['viewerProfile']['userProfileKey'],
    userOutcomeId: UserOutcomeDetail['userOutcomeId']
  ): Observable<UserOutcomeDetail> {
    const payload = { userKey, userOutcomeId };
    return this.get<UserOutcomeDetail>(
      '/useroutcomes/getuseroutcome',
      payload,
      this.i18n.UserOutcomeSvc_RetreivingError
    ).pipe(
      tap((data) => {
        data.details = this.parseUserOutcomeDetails(data);
        if (
          data.imageUrl &&
          !data.imageUrl.match(/\.(jpg|jpeg|png|gif|svg|pdf|tif|tiff)$/)
        ) {
          data.imageUrl = undefined;
        }
      })
    );
  }

  private parseUserOutcomeDetails(userOutcome: UserOutcomeDetail): JsonObject {
    if ('string' !== typeof userOutcome?.details) return;

    try {
      return JSON.parse(userOutcome.details);
    } catch (e) {
      // suppress parse error
    }
  }
}
