<dgx-month-picker
  [minDate]="params.minDate"
  [maxDate]="params.maxDate"
  [isMinDateToday]="params.isMinDateToday"
  [isMaxDateToday]="params.isMaxDateToday"
  [customDgat]="params.customDgat"
  [placeholder]="params.placeholder"
  [date]="formControl.value"
  [disabled]="params.disabled"
  [ariaLabel]="params.ariaLabel"
  (dateSelect)="handleDateSelected($event)"
></dgx-month-picker>
