<div
  *ngIf="!uploadInProgress"
  dgxUploader
  (fileDropped)="onFileSelected($event)"
  (dragState)="onChangeDragState($event)"
  class="file-uploader file-uploader--ready"
  [ngClass]="{
    'file-uploader--focused': uploaderFocused,
    'color-blue-dark': uploaderFocused,
    'color-ebony-a61': !uploaderFocused,
    grid: templateOption == 'full',
    'full-width': templateOption == 'full'
  }"
  [uploadConfigs]="{
    multiple: false,
    showCancel: false
  }"
>
  <div
    class="grid__col-12 guts-p-full-0 guts-m-v-auto color-ebony-a61"
    [ngClass]="{
      'center-text': templateOption == 'full'
    }"
  >
    <input
      class="hidden-without-display"
      type="file"
      #fileDropRef
      id="fileDropRef"
      data-dgat="file-uploader-0f1"
      (blur)="onBlur()"
      (focus)="onFocus()"
      (change)="onFileSelected($event)"
      [size]="sizeLimit * 1000000"
      [accept]="fileTypes"
      [multiple]="multiple"
      [attr.aria-describedby]="fileUploaderDescribedBy"
    />

    <label
      for="fileDropRef"
      class="rounded"
      [ngClass]="{
        'guts-p-full-half': templateOption == 'compact',
        'guts-p-h-3': templateOption == 'full',
        'guts-p-v-8': templateOption == 'full'
      }"
      data-dgat="file-uploader-0f2"
    >
      <span class="ib">
        <df-icon
          icon="plus"
          class="bg-color-ebony-a61 round"
          [size]="iconSize"
          [ngClass]="{
            'color-blue-light': uploaderFocused,
            'color-ebony-a18-hue': !uploaderFocused,
            'guts-p-full-half': templateOption == 'full',
            'guts-p-full-quart': templateOption == 'compact'
          }"
        ></df-icon>
      </span>
      <br *ngIf="templateOption == 'full'" />
      <span
        class="ib"
        [ngClass]="{
          'guts-p-t-1': templateOption == 'full',
          'guts-p-l-half': templateOption == 'compact'
        }"
        >{{ targetLabel }}</span
      >
    </label>
  </div>
</div>
<div *ngIf="uploadInProgress" class="file-uploader full-width grid">
  <div
    class="grid__col-12 guts-p-full-0 guts-m-v-auto color-ebony-a61"
    [ngClass]="{
      'center-text': templateOption == 'full'
    }"
  >
    <div
      [ngClass]="{
        'guts-p-h-3': templateOption == 'full',
        'guts-p-v-8': templateOption == 'full'
      }"
    >
      <df-spinner
        *ngIf="templateOption == 'full'"
        [isSpinning]="!inErrorState && !isFileReady"
        class="ib guts-m-l-1"
      ></df-spinner>
      <span role="alert" aria-live="assertive" class="a11y-hide">{{
        'dgFileUploadButton_Uploading' | translate
      }}</span>
      <span
        *ngIf="isFileReady && templateOption == 'full'"
        class="guts-m-b-0"
        role="alert"
        aria-live="assertive"
        >{{ 'Uploader_Completed' | translate }}</span
      >
      <br *ngIf="templateOption == 'full'" />
      <span
        class="ib"
        *ngIf="inProgressLabel"
        [ngClass]="{
          'guts-p-t-2': templateOption == 'full',
          'guts-p-l-half': templateOption == 'compact'
        }"
      >
        {{ inProgressLabel }}
      </span>
      <span
        class="bg-color-ebony-a08 color-ebony guts-p-full-1 left-text rounded"
        [ngClass]="{
          'guts-m-t-3': templateOption == 'full',
          'guts-m-h-auto': templateOption == 'full',
          'file-uploader__active-file': templateOption == 'full',
          'guts-p-full-1': templateOption == 'full',
          'guts-p-full-half': templateOption == 'compact',
          block: templateOption == 'compact'
        }"
      >
        <df-spinner
          *ngIf="templateOption == 'compact'"
          class="ib guts-m-r-1 guts-m-l-1"
          [spinnerContext]="'button'"
          [isSpinning]="!inErrorState && !isFileReady"
        ></df-spinner>
        <span class="ib guts-m-r-1" *ngIf="isFileReady">
          <df-icon
            class="color-success"
            icon="checkmark"
            size="small"
          ></df-icon>
        </span>
        <span class="guts-m-r-auto">{{ activeFileName | ellipsis: 40 }}</span>
        <span
          ><button
            (click)="cancelFileUpload($event)"
            data-dgat="file-uploader-6e1"
            *ngIf="showCancel && !inErrorState && !isFileReady"
          >
            <df-icon
              class="bg-color-ebony-a61 round color-ebony-a08-hue guts-p-full-quart"
              icon="cross"
              size="small"
            ></df-icon>
          </button>
        </span>
      </span>
    </div>
  </div>
</div>
<div
  *ngIf="!uploadInProgress && limitNotificationLabel"
  id="limit-notification-desc"
  class="guts-m-t-half par--small par--light"
>
  {{ limitNotificationLabel }}
</div>

<div
  *ngIf="!uploadInProgress && sizingNotice"
  id="sizing-notice-desc"
  class="guts-m-t-half par--small par--light"
>
  {{
    sizingNotice
      | translate
        : {
            types: fileTypes,
            size: sizeLimit
          }
  }}
</div>
