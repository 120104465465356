import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBannerComponent } from './cookie-banner.component';
import { CookieLawService } from './services/cookie-law.service';
import { DfIconModule } from '@lib/fresco';

@NgModule({
  imports: [CommonModule, DfIconModule],
  declarations: [CookieBannerComponent],
  exports: [CookieBannerComponent],
  providers: [CookieLawService],
})
export class CookieBannerModule {}
