import { Injectable } from '@angular/core';
import { PathwayInputService } from '@app/pathways/services/inputs/pathway-input.model';
import {
  Task,
  TaskParameters,
  UpdateTaskParameters,
} from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathwayTaskService implements PathwayInputService<TaskParameters> {
  constructor(private inputsService: InputsService) {}

  /**
   * @param taskParameters parameters for the specific new input type to add and associate with this user
   */
  public addNewInput(taskParameters: TaskParameters): any {
    if (taskParameters.legacyPictureUrl) {
      // see ResourceImageJson.LegacyPictureUrl
      taskParameters.imageUrl = taskParameters.legacyPictureUrl;
    }
    return this.inputsService.addTask(taskParameters);
  }

  public updateInput(userInputParams: UpdateTaskParameters): Observable<void> {
    if (userInputParams.legacyPictureUrl) {
      // see ResourceImageJson.LegacyPictureUrl
      userInputParams.imageUrl = userInputParams.legacyPictureUrl;
    }
    return this.inputsService.updateTask(userInputParams);
  }

  public getInput(inputId: number): Observable<Task> {
    return this.inputsService.getTask(inputId);
  }
}
