import { Injectable } from '@angular/core';
import { ResourceType } from '@app/shared/models/core-api.model';
import { UploaderService } from '@app/uploader/uploader.service';
import { InputImageUpload } from './input-image-upload.adapter';

/**
 * Helper service to create an {@see InputImageUpload } for AngularJs app components
 * because we can not share classes between the ngx and js app.
 * Once all of the forms using this service have been migrated, this can be deleted
 */
@Injectable({
  providedIn: 'root',
})
export class InputImageUploadAdapterService {
  constructor(private uploadService: UploaderService) {}

  public getAdapter(...args: (ResourceType | number | string)[]) {
    const [resourceType, resourceId, parentResourceTypeName, parentResourceId] =
      args;
    return new InputImageUpload(
      this.uploadService,
      resourceType as ResourceType,
      resourceId as number,
      parentResourceTypeName as string,
      parentResourceId as number
    );
  }
}
