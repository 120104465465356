import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FileUploadChunkResult } from '@app/uploader/file-chunk-uploader/file-chunk-uploader.service';
import { Uploader } from '@app/uploader/uploader';
import { FileUploadSetting } from '@app/uploader/uploader-api.model';

import { ScormService } from '@app/content-catalog/services/scorm.service';

@Injectable({ providedIn: 'root' })
export class CourseUploadAdapter
  implements Uploader<never, FileUploadChunkResult>
{
  constructor(private scormService: ScormService) {}

  public getUploadSettings(): Observable<FileUploadSetting> {
    return of({
      allowedFileTypes: ['.zip'],
      maxSizeMB: ScormService.MAX_UPLOAD_FILE_SIZE_MB,
    });
  }

  public upload(file: File, _?: never): any {
    this.scormService.cachedScormInfo.scormFileName = file.name;

    return this.scormService.uploadScormFile(
      file,
      this.scormService.cachedScormInfo.scormCourseInputUrl
    );
  }
}
