import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
  DfIconCross16,
  DfIconQuestionMarkCircle16,
  DfIconRegistry,
  DfIconTag16,
} from '@lib/fresco';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserContentModalBaseDirective } from '../../user-content-modal-base/user-content-modal-base.directive';
import { CourseEntry } from '@app/inputs/inputs-api.model';
import { AutocompleteItem } from '@app/user-content/course-api.model';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { lazySearch } from '@dg/shared-rxjs';
import { from, Observable } from 'rxjs';

import { AbstractControl, FormControl } from '@angular/forms';
import { TagsApi } from '@app/tags/tag-api.model';
import { CourseFormDomainService } from '../course-form-domain.service';
import { AnyUserCourseParametersViewModel } from '../../user-input.model';
import {
  AddToPathwayOptions,
  CourseFormModel,
  EbbAddToPathwayConfig,
  EbbPathwayConfigurationResult,
  Institution,
} from '../course-form.model';
import { take } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';

@Component({
  selector: 'dgx-course-formal-form',
  templateUrl: './course-formal-form.component.html',
  styleUrls: ['./course-formal-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseFormalFormComponent implements OnInit {
  public formOptions = {};
  @ViewChild('courseLevelTemplate')
  public courseLevelTemplateRef: TemplateRef<any>;
  @ViewChild('courseGradeTemplate')
  public courseGradeTemplateRef: TemplateRef<any>;
  @ViewChild('skillsViewTemplate')
  public skillsTemplateRef: TemplateRef<any>;
  @ViewChild('institutionSearchTemplate')
  public institutionSearchRef: TemplateRef<any>;
  @ViewChild('countrySearchTemplate')
  public countrySearchRef: TemplateRef<any>;
  @ViewChild('courseTitleSearchTemplate')
  public courseTitleSearchRef: TemplateRef<any>;
  @Input() public model: CourseFormModel;
  @Input() public resolve: any;
  @Input() public modalOptions: any;
  @Input() public modalInstance;
  @Output()
  public onModelHasChanged = new EventEmitter<CourseFormModel>();
  @Output()
  public onSelectedCourseHasChange = new EventEmitter<CourseFormModel>();
  public ebbConfig: EbbAddToPathwayConfig;
  public addToPathwayOptions: AddToPathwayOptions;

  constructor(
    private cdr: ChangeDetectorRef,
    private builder: DfFormFieldBuilder,
    private iconRegistry: DfIconRegistry,
    private domainService: CourseFormDomainService
  ) {
    this.iconRegistry.registerIcons([
      DfIconQuestionMarkCircle16,
      DfIconCross16,
      DfIconTag16,
    ]);
  }

  public ngOnInit(): void {
    this.setupEbbConfiguration();
  }

  public setupEbbConfiguration(): void {
    if (
      this.domainService.shouldWeUsePathwayData(this.modalOptions, this.resolve)
    ) {
      const configuration: EbbPathwayConfigurationResult =
        this.domainService.updatePathwayData(this.resolve);
      if (configuration) {
        this.ebbConfig = configuration.ebbConfig;
        this.addToPathwayOptions = configuration.addToPathwayOptions;
        this.cdr.markForCheck();
      }
    } else if (
      this.domainService.shouldConfigureForEbb(this.modalOptions, this.resolve)
    ) {
      this.model.isEbbLoading = true;
      this.domainService.configureForEbb(
        this.model,
        this.resolve,
        this.model.hideGroups
      );
      this.cdr.markForCheck();
    }
  }
  /*
   * Handler for selected item from Institution Typeahead
   */
  public onInstitutionSelection(
    event: NgbTypeaheadSelectItemEvent,
    formControl: FormControl
  ): void {
    const item: AutocompleteItem = event.item;
    this.model.institution = {
      ...this.model.institution,
      institutionId: item.id,
      name: item.value,
    };
    this.model.institutionName = this.model.institution.name;
    const institutionSpecified = this.model.institution.institutionId;
    UserContentModalBaseDirective.setCustomFieldValue(formControl, item.value); // Updates UI control
    if (!this.model.isAccredited && institutionSpecified) {
      this.domainService
        .getDetailsForSelectedInstitution(institutionSpecified)
        .subscribe((institution: Institution) => {
          if (!!institution) {
            this.model.institution = institution;
          }
          this.onModelHasChanged.emit(this.model);
        });
    }
    this.onModelHasChanged.emit(this.model);
  }

  public onInstitutionSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly AutocompleteItem[]> => {
    return term.pipe(
      lazySearch((t: string) =>
        this.domainService.loadInstitutions(
          t,
          this.model.isAccredited,
          this.model.country
        )
      )
    );
  };

  public onCountrySearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<readonly any[]> => {
    return term.pipe(
      lazySearch((t: string) => this.domainService.loadCountries(t))
    );
  };

  public onCourseTitleSearch: TypeaheadSearchFunction<string, any> = (
    term: Observable<string>
  ): Observable<unknown[]> => {
    return term.pipe(
      lazySearch((t: string) =>
        this.model.institution
          ? this.domainService.loadCourses(
              this.model.institution,
              t,
              this.model.isAccredited
            )
          : from([])
      )
    );
  };

  public onSelectCourseFromList(
    event: NgbTypeaheadSelectItemEvent,
    formControl: FormControl
  ): void {
    const { item } = event;
    UserContentModalBaseDirective.setCustomFieldValue(formControl, item);
    this.model.course = { ...item };
    this.domainService
      .onSelectCourse(item.id)
      .pipe(take(1))
      .subscribe((course: CourseEntry) => {
        // Update model with course information
        this.model.inputId = course.inputId;
        this.model.isAccredited = course.isAccredited;
        this.model.courseName = course.name;
        this.model.description = course.description;
        this.model.creditHours = course.creditHours || course.durationUnits;
        this.model.extent.courseLevel = this.model.allCourseLevels.find(
          (item) => item.id === course.levelRank
        ).id;
        this.model.input.courseNumber = course.courseId;
        this.model.courseUrl = course.courseUrl;
        this.model.input.externalId = course.externalId;
        this.model.tags = course.tags;
        this.model.input.courseNumber = course.courseId;
        this.model.input.unitType = this.domainService.getUnitType(
          course,
          this.model.input.unitType,
          this.model.institution
        );
        this.model.input.units = this.domainService.getUnits(
          course,
          this.model.input.units,
          this.model.institution
        );
        this.onSelectedCourseHasChange.emit(this.model);
      });
  }

  public labelFormatter(result) {
    return result.label;
  }

  public onCountrySelection(
    event: NgbTypeaheadSelectItemEvent,
    formControl: FormControl
  ): void {
    const { item } = event;
    this.model.country = item;
    UserContentModalBaseDirective.setCustomFieldValue(
      formControl,
      this.model.country
    );
    this.onModelHasChanged.emit(this.model);
  }

  public onTagsChanged(updatedTags: TagsApi.Tag[], formControl: FormControl) {
    // this.domainService.onTagsChanged(updatedTags);
    UserContentModalBaseDirective.setCustomFieldValue(formControl, updatedTags);
    this.model.tags = updatedTags;
    this.onModelHasChanged.emit(this.model);
  }

  public initFormModel(data: Partial<CourseFormModel>) {
    this.model = { ...this.model, ...data };
  }

  /**
   * Before calling the service function, which updates the model using
   * the common logic among informal and formal forms,
   * make sure to update model with any logic related to this form.
   */
  public createResult(): AnyUserCourseParametersViewModel {
    return this.domainService.convertViewModelToApiModel(this.model);
  }

  public generateUI(): DfFormFieldConfig<DfTemplateOptions>[] {
    return [
      this.builder
        .customField(
          'country',
          this.domainService.i18n.CourseFormCtrl_Country,
          this.countrySearchRef
        )
        .build(),
      this.builder
        .customField(
          'institutionName',
          this.domainService.i18n.CourseFormCtrl_SchoolName,
          this.institutionSearchRef
        )
        .validatedByIndexed({
          institutionValidation: {
            expression: (control: AbstractControl) => {
              return this.domainService.institutionValidation(this.model);
            },
            message: this.domainService.i18n.CourseFormCtrl_SelectProvider,
          },
        })
        .asRequired()
        .build(),
      this.builder
        .customField(
          'courseName',
          this.domainService.i18n.CourseFormCtrl_CourseTitle,
          this.courseTitleSearchRef
        )
        .asRequired()
        .build(),
      this.builder
        .fieldGroup(
          'input',
          [
            this.builder
              .optionalTextInput(
                'courseNumber',
                this.domainService.i18n.CourseFormCtrl_CourseNumber
              )
              .styledBy('df-form__col-half')
              .build(),
            this.builder
              .optionalTextInput(
                'creditHours',
                this.domainService.i18n.CourseFormCtrl_CreditHours
              )
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .build(),
      this.builder
        .checkbox(
          'shouldAddToCatalog',
          this.domainService.i18n.CourseFormCtrl_AddToCatalogFormat
        )
        .hiddenWhen(
          () =>
            (!!this.model.isPathwayBinAdd &&
              !!this.model.canManageContent &&
              this.model.selectedOrg.id > 0) === false
        )
        .build(),
      this.builder
        .checkbox(
          'authoring',
          this.domainService.i18n.CourseFormCtrl_ITaughtCourse
        )
        .hiddenWhen(() => this.model.isPathwayBinAdd)
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .customField(
                'courseLevel',
                this.domainService.i18n.CourseFormCtrl_CourseLevel,
                this.courseLevelTemplateRef
              )
              .styledBy('df-form__col-half')
              .build(),
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                this.domainService.i18n.CourseFormCtrl_DateCompleted,
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.domainService.i18n.Core_SelectDate,
                }
              )
              .styledBy('df-form__col-half')
              .hiddenWhen(() => this.model.authoring)
              .build(),
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                this.domainService.i18n.CourseFormCtrl_DateTaught,
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.domainService.i18n.Core_SelectDate,
                }
              )
              .styledBy('df-form__col-half')
              .hiddenWhen(() => !this.model.authoring)
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(() => this.model.isPathwayBinAdd)
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .customField(
                'courseGrade',
                this.domainService.i18n.CourseFormCtrl_CourseGrade,
                this.courseGradeTemplateRef
              )
              .styledBy('df-form__col-half')
              .build(),
            this.builder
              .optionalTextInput(
                'verificationUrl',
                this.domainService.i18n.CourseFormCtrl_VerificationUrl
              )
              .ofType('url')
              .validatedByIndexed(this.domainService.urlValidation)
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .hiddenWhen(() => this.model.authoring || this.model.isPathwayBinAdd)
        .build(),
      this.builder
        .optionalTextarea(
          'comment',
          this.domainService.i18n.CourseFormCtrl_WhatDidYouLearnLong,
          100
        )
        .withPlaceholder(
          this.domainService.i18n.CourseFormCtrl_WhatDidYouLearnShort
        )
        .hiddenWhen(
          () =>
            (!this.model.canComment &&
              !this.model.completing &&
              this.model.formUIState.isEditing) ||
            this.model.isPathwayBinAdd
        )
        .build(),
      this.builder
        .customField(
          'tags',
          this.domainService.i18n.Core_Skills,
          this.skillsTemplateRef
        )
        .unwrapped()
        .build(),
    ];
  }

  public onCourseLevelSelected(id, formControl: FormControl): void {
    UserContentModalBaseDirective.setCustomFieldValue(formControl, id);
    this.model.extent.courseLevel = id;
    this.onModelHasChanged.emit(this.model);
  }

  public onEbbSubmit(hideFromCatalog: boolean): void {
    this.domainService
      .determineSubmitActionForEbb(hideFromCatalog, this.model, this.resolve)
      .pipe(take(1))
      .subscribe((resp) => {
        const ebbPathwayConfig = this.domainService.updatePathwayData(resp);
        this.addToPathwayOptions = ebbPathwayConfig.addToPathwayOptions;
        this.ebbConfig = ebbPathwayConfig.ebbConfig;
      });
  }
}
