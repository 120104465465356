<div class="ib">
  <button
    [dfPopoverTrigger]="navProfileMenu"
    df-button
    dfButtonType="secondary"
    data-dgat="profile-menu-78d"
    (click)="toggleOpen()"
    class="guts-p-full-0 no-border profile-menu__trigger round"
    [ngClass]="{
      'profile-menu__trigger--light': hasLightText
    }"
    attr.aria-label="{{ 'A11y_ToggleProfileMenu' | translate }}"
  >
    <dgx-profile-pic
      [profile]="authUser?.viewerProfile"
      size="smallnav"
      [hideTooltip]="true"
      [hideMentorIcon]="true"
      class="app-header__profile-pic profile-menu__profile-pic"
    ></dgx-profile-pic>
  </button>
  <df-popover
    #navProfileMenu
    [placement]="placement"
    [isAppendToBody]="isAppendToBody"
    [isOpen]="isMenuOpen"
    (isOpenChange)="avatarMenuViewed($event)"
  >
    <div class="guts-p-v-1" role="menu">
      <div
        *ngFor="let item of menuConfig; trackBy: trackById"
        data-dgat="profile-menu-d84"
      >
        <div *ngIf="isVisible(item)">
          <div *ngIf="item.isSeparated" class="border-bottom guts-m-v-1"></div>
          <div class="guts-p-h-1">
            <a
              #menuTrigger
              class="
                block
                guts-p-h-1 guts-p-v-half
                color-ebony
                profile-menu__button
                left-text
                rounded--large
              "
              [attr.data-dgat]="'menuitem-' + item.id"
              (click)="handleItemClick($event, item, menuTrigger)"
              role="menuitem"
              [attr.href]="item.href ? item.href : null"
              [attr.target]="item.isNewWindow ? '_blank' : null"
              *ngIf="item.href"
            >
              <df-icon
                *ngIf="item.icon"
                class="guts-p-r-1"
                [icon]="item.icon"
              ></df-icon>
              {{ item.title }}
            </a>

            <button
              #menuTrigger
              class="
                block
                guts-p-h-1 guts-p-v-half
                color-ebony
                profile-menu__button
                left-text
                rounded--large
              "
              data-dgat="profile-menu-2a1"
              (click)="handleItemClick($event, item, menuTrigger)"
              [attr.data-dgat]="'menuitem-' + item.id"
              role="menuitem"
              *ngIf="!item.href"
            >
              <df-icon
                *ngIf="item.icon"
                class="guts-p-r-1"
                [icon]="item.icon"
              ></df-icon>
              {{ item.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </df-popover>
</div>
