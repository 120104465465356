import { pascalCaseKeys } from './../utils/property';
import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsAppLocation } from '../../shared/web-environment-info';
import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';
import {
  TrackingContext,
  TrackingDetails,
  TrackingEventArgs,
  TrackingEventData,
  TrackingEventsParams,
  TrackingProperties,
} from './tracking.model';
import { WebEnvironmentService } from './web-environment.service';
import { generateGuid } from '@app/shared/utils/uuid';
import { Observable } from 'rxjs';

/** Tracks app usage events */
@Injectable({ providedIn: 'root' })
export class TrackerService {
  private _currentLocation: URL;

  public constructor(
    private location: Location,
    private router: Router,
    private title: Title,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private webEnvironmentService: WebEnvironmentService
  ) {
    // Listen for location changes. This way we don't have to keep re-parsing the url for multiple tracking calls on the same URL.
    location.subscribe((l) => {
      this._currentLocation = undefined; // clear to force update of currentLocation prop on next read
    });
  }

  private get currentLocation() {
    // lazy-init
    if (!this._currentLocation) {
      this._currentLocation = new URL(
        this.location.prepareExternalUrl(this.location.path())
      );
    }
    return this._currentLocation;
  }

  private get webPlatform(): string {
    const pathname = this.currentLocation.pathname;
    const isMSTeams = pathname.indexOf('degreed-ms-teams') > -1;
    const isExtension = pathname.indexOf('degreed-button') > -1;

    if (isExtension) {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.has('is_ext') ? 'extension' : 'bookmarklet';
    } else if (isMSTeams) {
      return 'MS Teams';
    }
    return 'Web';
  }

  public trackEventData({
    action,
    category,
    label,
    properties = {} as { [key: string]: any },
    element = null,
  }: TrackingEventArgs): Observable<TrackingEventsParams> {
    const localProperties: TrackingProperties = {
      ...properties,
      category,
      label,
    };

    if (element) {
      this.collectDOMProperties(element, localProperties);
    }

    // Note: this should not need a pascal check
    if (!localProperties.platform && !localProperties.Platform) {
      localProperties.platform = properties?.webPlatform || this.webPlatform;
    }
    // Note: this should not need a pascal check either
    // Also please do not pass location through with properties,
    // this should be set with this.setLocation(<Location>)
    if (!localProperties.location && !localProperties.Location) {
      localProperties.location =
        this.webEnvironmentService.analyticsAppLocation;
    }

    localProperties.sessionId =
      this.webEnvironmentService.analyticsAnonymousUserId;

    const details = this.getCommonDetails(localProperties);
    const userId = details.userId
      ? details.userId
      : this.webEnvironmentService.analyticsAnonymousUserId;

    const trackData = {
      eventName: action,
      properties: localProperties,
      context: details.context,
      userId: userId,
    };

    return this.analyticsService.track(trackData);
  }

  public trackBatchEvents(eventsData: TrackingEventData[]) {
    const details = this.getCommonDetails();
    const userId = details.userId
      ? details.userId
      : this.webEnvironmentService.analyticsAnonymousUserId;
    for (const eventData of eventsData) {
      eventData.properties.location =
        this.webEnvironmentService.analyticsAppLocation ||
        eventData.properties.location;
      eventData.properties.platform =
        eventData.properties.platform || this.webPlatform;
      eventData.properties.sessionId =
        this.webEnvironmentService.analyticsAnonymousUserId;

      if (eventData.element) {
        this.collectDOMProperties(eventData.element, eventData.properties);
        delete eventData.element;
      }

      eventData.properties = {
        // Pascal case the properties for the DB. Our BE is case-insenetive but
        // our tracking properties have to match the instrumented properties in related DB table (at the moment).
        ...pascalCaseKeys(eventData.properties),
      };
    }

    const eventsParameters: TrackingEventsParams = {
      userId: userId,
      eventsData: eventsData,
      context: details.context,
    };

    this.analyticsService.trackBatch(eventsParameters);
  }

  // This was migrated to simulate the tracking calls on the CSHTML pages
  // Once we're off MVC routing, this should be managed differently by
  // ngx routing/pages.
  public trackPage(path?: string) {
    const localProperties: TrackingProperties = {
      path: path || this.router.url,
    };

    const details = this.getCommonDetails(localProperties);
    const uId = details.userId ? details.userId : null;
    return this.analyticsService.trackPage(localProperties, details, uId);
  }

  /** Calls the analytics identify endpoint */
  public trackIdentify() {
    const details: any = this.getCommonDetails();
    const userId = details.userId;

    return this.analyticsService.trackIdentify(userId, generateGuid(), details);
  }

  /**
   * A method for setting the name of the Location property
   * for tracking events on a specific landing page within the app.
   *
   * @location landing page within the app
   */
  public setLocation(location: AnalyticsAppLocation) {
    this.webEnvironmentService.analyticsAppLocation = location;
  }

  private getLocale() {
    return navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  }

  /**
   * Walk all of the element's parents and collect any properties that are added
   * via data-dgprop-[name]=[value]. When conflicting, deeper nested properties
   * have precedence.
   */
  private collectDOMProperties(
    element: HTMLElement,
    properties: TrackingProperties
  ) {
    const parents: HTMLElement[] = [];

    while (element) {
      parents.push(element);
      element = element.parentElement;
    }
    // Iterate the parents in reverse order and find the properties
    while (parents.length > 0) {
      const dataset = parents.pop().dataset;
      for (const name in dataset) {
        if (name.startsWith('dgprop')) {
          // Try to convert the value to a number or a boolean first and if it works, use that.
          const floatVal = parseFloat(dataset[name]);
          const value = !isNaN(floatVal)
            ? floatVal
            : dataset[name] === 'true'
              ? true
              : dataset[name] === 'false'
                ? false
                : dataset[name];
          // The browser automatically converts data-dgprop-foo-bar attrs to
          // camel case in the dataset object, e.g., dgpropFooBar
          properties[name.replace('dgprop', '')] = value;
        }
      }
    }
  }

  private getParamsAsObj(locationSearch): any {
    const keyValues = locationSearch.replace('?', ''),
      paramsList = keyValues.split('&'),
      params = {};
    for (const paramAndValue of paramsList) {
      const param = paramAndValue.split('=');
      if (param) {
        params[param[0]] = param[1];
      }
    }
    return params;
  }

  private getCommonDetails(props?: TrackingProperties) {
    const user = this.authService.authUser;
    const details: TrackingDetails = {};
    const location = this.currentLocation;
    const referrer = this.router
      .getCurrentNavigation()
      ?.previousNavigation?.finalUrl.toString();

    details.userId = user?.viewerProfile.userProfileKey;
    details.activeLearner = user?.isEngaged;
    details.hasCareerMobility = user?.hasCareerMobility;
    details.context = {
      library: { name: 'dg.js' },
      timezone: new Date().getTimezoneOffset() * -1,
      locale: this.getLocale(),
      userAgent: navigator.userAgent,
      page: {
        path: props?.path || location.pathname,
        params: this.getParamsAsObj(location.search),
        referrer: referrer,
        search: location.search,
        title: this.title.getTitle(),
        url: location.href,
      },
      // see https://degreedjira.atlassian.net/browse/PD-81100
      profileTabsMovedToHome: true,
    };
    details.context.page = this.cleanSensitiveQueryString(details.context.page);

    details.receivedAt = null;
    details.sentAt = new Date();

    return details;
  }

  private cleanSensitiveQueryString(
    detailsContextPage: TrackingContext['page']
  ) {
    const queryStringParams = this.getParamsAsObj(this.currentLocation.search);

    const isSensitiveQueryString =
      queryStringParams.response_type === 'token' ||
      !!queryStringParams.client_id;

    if (isSensitiveQueryString) {
      detailsContextPage.params = {};
      detailsContextPage.referrer = this.currentLocation.pathname;
      detailsContextPage.search = '';
      detailsContextPage.url = this.currentLocation.pathname;
    }
    return detailsContextPage;
  }
}
