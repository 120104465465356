import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupService } from '@app/shared/services/group.service';
import { ToastrService } from 'ngx-toastr';
import { AvailableGroupPermissions, GroupRole } from '@app/groups/group-api';
import { WebEnvironmentService } from '@dg/shared-services';

@Component({
  selector: 'dgx-group-security',
  templateUrl: './group-security.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSecurityComponent implements OnInit {
  @Input() public availablePermissions: AvailableGroupPermissions[];
  @Input() public groupId: number;
  @Input() public isAdministrativeGroup: boolean;

  public roles: GroupRole[] = [];
  public saving: boolean = false;
  public loadingRoles: boolean = false;
  public i18n: Record<string, string> = this.translateService.instant([
    'Core_Save',
    'Core_Saving',
    'Core_Permissions',
    'GroupSecurityCtrl_UpdateRolesSuccess',
  ]);

  public constructor(
    private translateService: TranslateService,
    private groupService: GroupService,
    private toastrService: ToastrService,
    private webEnvironmentService: WebEnvironmentService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.loadingRoles = true;

    this.groupService.getRoles(this.groupId).subscribe({
      next: (roles: GroupRole[]) => {
        this.roles = this.transformRoles(roles);
      },
      complete: () => {
        this.loadingRoles = false;

        this.cdr.markForCheck();
      },
    });
  }

  public save() {
    this.saving = true;

    const updatedRoles = [...this.roles].map((role) => ({
      ...role,
      permissions: role.permissions.filter(({ assigned }) => assigned),
    }));

    this.groupService.updateRoles(this.groupId, updatedRoles).subscribe({
      next: () => {
        this.toastrService.success(
          this.i18n.GroupSecurityCtrl_UpdateRolesSuccess
        );
        this.saving = false;
        setTimeout(this.reloadPermissions.bind(this), 500);
      },
      error: () => {
        this.saving = false;
      },
    });
  }

  public getRoleI18nKey(name: string) {
    const nameWithoutSpaces = name.replace(/\s/g, '');

    return `Core_Role${nameWithoutSpaces}`;
  }

  public getRoleLabel(name: string) {
    const id = this.getRoleI18nKey(name);

    return this.translateService.instant(id);
  }

  public getPermissionLabel(name: string) {
    const nameWithoutSpaces = name.replace(/\s/g, '');

    return this.translateService.instant(
      `dgGroupSecurity_${nameWithoutSpaces}`
    );
  }

  public getPermissionDescription(name: string) {
    const nameWithoutSpaces = name.replace(/\s/g, '');

    return this.translateService.instant(
      `dgGroupSecurity_${nameWithoutSpaces}Description`
    );
  }

  public getDefaultRoleDescription(name: string) {
    return this.translateService.instant(
      'dgGroupSecurity_DefaultRoleDescription',
      { roleName: name }
    );
  }

  public getPermissionDgat(roleId: number, permissionId: number) {
    return `groupSecurity-p${permissionId}-${roleId}`;
  }

  public isPermissionDisabled(
    roleId: number,
    permission: AvailableGroupPermissions
  ) {
    return (
      this.isAdministrativeGroup ||
      (permission.isRequiredForAdmin && roleId === 1) ||
      (!permission.isMemberPermission && roleId === 2)
    );
  }

  private transformRoles(roles: GroupRole[]) {
    return roles.map((role) => {
      const assignedPermissionIds = role.permissions.map(
        ({ permissionId }) => permissionId
      );

      const permissions = this.availablePermissions.map((permission) => {
        const assigned = assignedPermissionIds.includes(
          permission.permissionId
        );

        return {
          ...permission,
          assigned,
        };
      });

      return {
        ...role,
        permissions,
      };
    });
  }

  private reloadPermissions() {
    this.webEnvironmentService.reloadRoute();
  }
}
