import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { TranslateService } from '@ngx-translate/core';
import { PathwayAuthorNoteService } from '@app/pathways/services/reactive-pathway-services/pathway-author-note.service';
import {
  PathwayAuthoring,
  PathwayFacade,
  PathwayStep,
  PathwayViewModel,
} from '@dg/pathways-rsm';
import { AutoSaveService } from '@app/shared/services/auto-save/auto-save.service';
import { FocusStackService } from '@dg/shared-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'dgx-pathway-author-note-edit',
  templateUrl: './pathway-author-note-edit.component.html',
  styleUrls: ['../pathway-author-note/pathway-author-note.component.scss'],
})
export class PathwayAuthorNoteEditComponent
  extends SubscriberBaseDirective
  implements AfterViewInit
{
  @Input() public step: PathwayStep;
  @Input() public pathwayId: number;
  @Input() public pathwayVersion: string;

  public readonly i18n = this.translate.instant([
    'Pathways_NoteFromAuthor',
    'Pathways_AddANote',
    'Core_MoreOptions',
    'Pathways_DeleteNote',
  ]);
  public vm$: Observable<PathwayViewModel>;

  @ViewChild('noteTextArea') public noteTextAreaInput: ElementRef;

  constructor(
    private translate: TranslateService,
    private pathwayAuthorNoteService: PathwayAuthorNoteService,
    private authoring: PathwayAuthoring,
    private autoSaveService: AutoSaveService,
    private focusStackService: FocusStackService,
    private facade: PathwayFacade
  ) {
    super();
    this.vm$ = this.facade.vm$;
  }

  public ngAfterViewInit(): void {
    if (this.step.note?.note.trim() === '' && this.noteTextAreaInput) {
      this.focusStackService.push(this.noteTextAreaInput.nativeElement);
      this.focusStackService.pop();
    }
  }

  // Create config for author note menu
  public createMenuConfig() {
    return [
      {
        title: this.i18n.Pathways_DeleteNote,
        defaultAction: () => this.deleteNote(),
        isDestructive: true,
      },
    ];
  }

  public async deleteNote() {
    const updatedStep = {
      ...this.step,
      note: undefined,
    };
    this.authoring.deleteAuthorNote(updatedStep);
  }

  public toggleButton() {
    const shouldDisplayNote = !this.step.note.shouldDisplayNote;
    this.authoring.toggleDisplayOfAuthorNote(this.step, shouldDisplayNote);
  }

  public async onBlur($event) {
    if ($event.target.value) {
      this.autoSaveService.saving();

      const stepWithNewNote = {
        ...this.step,
        note: {
          ...this.step.note,
          note: $event.target.value.trim(),
        },
      };
      const success = await this.authoring.updateField(
        'note',
        $event.target.value,
        stepWithNewNote,
        false
      );

      // If updating the author note is successful,
      // Show a toaster in the bottom right corner indicating that changes have been saved.
      // Otherwise, close any existing toasts.
      success ? this.autoSaveService.saved() : this.autoSaveService.close();
    }
  }
}
