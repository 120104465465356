import { Injectable, TemplateRef } from '@angular/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  DfFieldTemplateContext,
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { EpisodeFormModel } from '../episode-forms.model';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { HostedContentMetadata } from '@app/shared/models/core-api.model';
import { ContentHostingUploadAdapterService } from '@app/uploader/upload-section/adapters/content-hosting-upload-adapter.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { FieldType } from '@app/user-content/services/field-builder-factory';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { RenderMode } from '../../user-input.model';
import { hasBrokenUrlValidator } from '@app/shared/validators/has-broken-url.validator';

@Injectable({ providedIn: 'root' })
export class EpisodeContentCatalogRendererService implements FormRenderer {
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('MediaFormCtrl_UrlRequired'),
    },
  };

  private urlValidatorAllowEmpty = {
    urlValidation: {
      ...this.urlValidator.urlValidation,
      expression: (control: AbstractControl, _) => {
        const valid =
          HTTP_URL_PATTERN.test(control.value) || control.value?.trim() === '';
        return valid;
      },
    },
  };

  constructor(
    private formBuilder: DfFormFieldBuilder,
    private contentHostingUploadAdapterService: ContentHostingUploadAdapterService,
    private translate: TranslateService,
    private webEnvironmentService: WebEnvironmentService,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return this.renderExpandedForm(context);
  }

  private renderExpandedForm(
    context: RendererContext<EpisodeFormModel>
  ): DfFormFieldConfig[] {
    const vm = context.state();

    const episodeOriginField = !vm.hostedContentDetails
      ? this.buildUrlField(context)
      : this.buildContentHostingField(
          context.state,
          vm.shouldShowContentUploader,
          context.inputContext.isEditing,
          vm.hostedContentDetails,
          vm.inputId,
          context.templates.contentUploader
        );

    return [
      // Created By
      this.commonFieldBuilder.buildCreatedByField(context.templates.creator),
      // Main form
      episodeOriginField,
      this.formBuilder
        .fieldGroup('initializedForm', [
          this.formBuilder
            .title()
            .withPlaceholder(context.translationKeys.Core_AudioTitlePlaceholder)
            .withDgatId('episodeForm-cca')
            .build(),

          this.formBuilder
            .optionalTextarea(
              'description',
              context.translationKeys.Core_Description
            )
            .withDgatId('episodeForm-328')
            .build(),
          // Duration
          this.formBuilder
            .optionalTextInput(
              'duration',
              context.translationKeys.Core_AudioLength
            )
            .ofType('number')
            .withDgatId('episodeForm-512')
            .withMin(0)
            .withStep(0.1)
            .asRequired()
            .readonlyWhen(() => context.state().isDurationDisabled)
            .hiddenWhen(
              () =>
                this.ldFlagsService.durationForCoursesEventsOther &&
                context.inputContext.renderMode !== RenderMode.UserProfile
            )
            .build(),
          this.inputFieldBuilder.buildDurationFields(
            this.ldFlagsService.durationForCoursesEventsOther &&
              context.inputContext.renderMode !== RenderMode.UserProfile,
            vm.inputType
          ),
        ])
        .hiddenWhen(() => !context.state().isFormInitialized)
        .build(),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.USER_IMAGE_FIELD)
        .build({
          useCropper: true,
          inputType: context.inputContext.inputType,
          resourceId: vm.inputId,
        }),
      this.inputFieldBuilder.buildSkillsField(null, true),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.ADVANCED_FIELDSET)
        .build({
          canRestrictContent: vm.canRestrictContent,
          organizationId: vm.organizationId,
          isInitiallyVisibleToOrg: vm.isVisibleToOrg,
          groups: vm.groups,
          expanderTemplate: context.templates.advancedExpander,
          expand$: vm.expandAdvanced$,
          fieldOptions: {
            externalId: {
              isDisabled: context.state().fileManaged,
            },
          },
        }),
    ];
  }

  private buildUrlField(
    context: RendererContext<EpisodeFormModel>
  ): DfFormFieldConfig {
    const originalUrl = context.state().mediaUrl;

    if (!this.ldFlagsService.showBrokenLinksManagement) {
      return this.formBuilder
        .optionalTextInput('mediaUrl', 'dgOrgInternalContentForm_AudioURL')
        .ofType('url')
        .validatedByIndexed(this.urlValidatorAllowEmpty) // allow empty to be valid initially to not throw errors
        .withDgatId('episodeForm-a78')
        .autofocused()
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .build();
    } else {
      return this.formBuilder
        .customField('mediaUrl', null, context.templates.urlBrokenValidation, {
          label: this.translate.instant('dgOrgInternalContentForm_AudioURL'),
          invalidUrlMessage: this.translate.instant(
            'MediaFormCtrl_UrlRequired'
          ),
          brokenUrlMessage: this.translate.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
          originalUrl,
          isRequired: false,
        })
        .validatedByIndexed(this.urlValidatorAllowEmpty) // allow empty to be valid initially to not throw errors
        .validatedBy((control: AbstractControl) =>
          hasBrokenUrlValidator(
            control,
            this.translate.instant('dgOrgInternalContent_BrokenLinkError'),
            context.state().hasBrokenUrl,
            control.value !== originalUrl
          )
        )
        .withDgatId('episodeForm-a78')
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .build();
    }
  }

  private buildContentHostingField(
    state: () => EpisodeFormModel,
    supportsContentHosting: boolean,
    isEditing: boolean,
    hostedContentDetails: HostedContentMetadata,
    inputId: number,
    contentUploaderTemplate: TemplateRef<DfFieldTemplateContext>
  ): DfFormFieldConfig {
    return this.formBuilder
      .customField('hostedContentDetails', '', contentUploaderTemplate, {
        useExistingFileRestrictions: true,
        file: hostedContentDetails
          ? {
              name: hostedContentDetails.fileName,
              size: hostedContentDetails.fileSize,
            }
          : undefined,
        uploadAdapter: this.contentHostingUploadAdapterService.getAdapter(
          'Episode',
          inputId
        ),
        errorMessages: {
          invalidFileType: this.translate.instant(
            'dgContentHosting_InvalidFileType',
            {
              startAnchor: `<a class="color-blue" target="_blank" href="${this.webEnvironmentService.getZendeskUrl(
                '/articles/4408914250514'
              )}">`,
              endAnchor: '</a>',
            }
          ),
        },
        labels: {
          header: this.translate.instant('dgContentHosting_DragAndDrop'),
        },
        i18n: this.translate.instant(['Core_Or']), // hide divider on full form; we now know we're uploading and not allowing a URL
        hasDivider: false,
      })
      .unwrapped()
      .hiddenWhen(() => {
        const vm = state();
        return (
          !vm.shouldShowContentUploader ||
          (hostedContentDetails && hostedContentDetails.hostType !== 'Box')
        );
      })

      .build();
  }
}
