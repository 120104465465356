<dgx-modal
  [canCancel]="canCancel"
  [canCloseHeader]="canCloseHeader"
  [cancelButtonText]="cancelButtonText"
  [closeOnSubmit]="closeOnSubmit"
  [isHeaderBorderless]="isHeaderBorderless"
  [isSubmitDisabled]="isSubmitDisabled"
  [modalHeaderTitle]="headerText"
  [submitButtonText]="submitButtonText"
  [submitButtonAriaLabel]="submitButtonAriaLabel"
  [submitButtonType]="submitButtonType"
  [item]="item"
>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <!-- Modal Body Image-->
    <div *ngIf="bodyImg" class="center-text guts-p-v-2">
      <img [alt]="bodyImgAlt" [src]="bodyImg" class="image-layout--tile" />
    </div>

    <!-- Modal Body Text -->
    <p
      *ngIf="bodyText"
      [innerHTML]="bodyText"
      [ngClass]="bodyClass"
      class="break"
    ></p>

    <p
      *ngIf="secondaryBodyText"
      [innerHTML]="secondaryBodyText"
      [ngClass]="secondaryBodyClass"
      class="break"
    ></p>
  </ng-container>
</dgx-modal>
