<header
  [ngClass]="headerWrapperClasses"
  [class.modal-header--borderless]="hideBorder"
  class="modal-header"
>
  <div
    *ngIf="isSubmitPending && isPendingWithOverlay"
    class="modal-header--overlay"
  ></div>
  <div class="l_flexbar">
    <!-- header content, if any -->
    <div [class.modal-header--centered]="isCentered" class="l_flex-grow">
      <h1
        #headerContent
        [hidden]="!showHeaderContent"
        class="modal-header__title break"
        [ngClass]="headerClasses"
        data-dgat="dialogTitle-d4e"
        [id]="headerLabelId"
      >
        <ng-content *ngIf="!isLoading"></ng-content>
      </h1>
    </div>
    <!-- close button, if any -->
    <button
      *ngIf="canCancel && dismiss.observers.length"
      [attr.aria-label]="'Core_Close' | translate"
      (click)="onDismiss($event)"
      class="modal-header__close-icon js-modal-close-btn l_flex-none"
      type="button"
      data-dgat="modalHeader-e5f"
    >
      <df-icon icon="cross"></df-icon>
    </button>
  </div>
  <!-- header meta, if any -->
  <ul *ngIf="!!headingMetaConfig" class="l_flexbar m-l_flexbar-block meta-list">
    <li
      *ngFor="let config of headingMetaConfig"
      [ngClass]="config.class"
      class="guts-p-r-half"
      data-dgat="modalHeader-1d5"
    >
      {{ config.label }}
    </li>
  </ul>
</header>
