import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfAccessibilityModule } from './accessibility/accessibility.module';
import { DfLayoutModule } from './layout/layout.module';
import { DfPipesModule } from './pipes';

@NgModule({
  imports: [CommonModule, DfAccessibilityModule, DfLayoutModule, DfPipesModule],
  declarations: [],
  providers: [],
  exports: [DfAccessibilityModule, DfLayoutModule, DfPipesModule],
})
export class DfUtilitiesModule {}
