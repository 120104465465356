import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { isKey, Key } from '@app/shared/key';
import { OrgSelectorExternalWarningService } from '@app/orgs/services/org-selector-external-warning.service';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { OrganizationModelIdentifier } from '@app/orgs/services/orgs.model';
import { MenuViewModel } from '../menu/menu.component';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';

/** Allows switching between two organization catalogs. One of the provided organizations may be a "dummy"
 * external catalog, i.e. an org with no organizationId. In this case, when the user attempts to select
 * that org, the ShowExternalContentWarning org setting will be inspected to determine if an external
 * content warning should be displayed. If the user then cancels via the warning, the org selection will
 * be aborted.
 *
 * Although similar to an on/off toggle switch, accessibility-wise, the component manifests as a radiogroup
 * because its options are better presented as mutually-exclusive labeled selections.
 */
@Component({
  selector: 'dgx-org-selector',
  templateUrl: './org-selector.component.html',
  styleUrls: ['./org-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    /** Slides an element horizontally between left and right positions while stretching or shrinking its width. */
    trigger('slideAndStretchHoriz', [
      state('0', style({ left: '{{left}}', width: '{{width}}' }), {
        params: { left: '0', width: '0' },
      }),
      state('1', style({ left: '{{left}}', width: '{{width}}' }), {
        params: { left: '0', width: '0' },
      }),
      transition('0 <=> 1', animate('300ms ease-out')),
    ]),
  ],
})
export class OrgSelectorComponent implements AfterViewInit, OnChanges {
  public readonly i18n = this.translate.instant([
    'Core_Okay',
    'OrgSettings_ShowExternalContentWarning_Default',
    'OrgSettings_MarketplaceWarning_Default',
    'Core_Marketplace',
  ]);

  @Input() public defaultOrgId: number;
  @Input() public orgs: OrganizationModelIdentifier[];
  @Input() public selectedOrg: OrganizationModelIdentifier;
  @Input() public showMarketplace: boolean = false;
  @Input() public isLoading: boolean;
  @Output()
  public selectedOrgChange = new EventEmitter<OrganizationModelIdentifier>();

  @ViewChildren('org') public orgElements: QueryList<ElementRef>;

  public animationData = {
    value: -1, // init to undefined animation state to prevent initial animation
    params: {
      left: '0',
      width: '0',
    },
  };

  public menuConfig: MenuViewModel[] = [];
  /** The selected org and the external org */
  public activeOrgs: OrganizationModelIdentifier[];

  constructor(
    private translate: TranslateService,
    private orgSettingsService: OrgSettingsService,
    private orgSelectorExternalWarningService: OrgSelectorExternalWarningService,
    private cdr: ChangeDetectorRef,
    private ldFlagsService: LDFlagsService
  ) {}

  get selectedIndex(): number {
    return this.activeOrgs[0].organizationId === this.selectedOrg.organizationId
      ? 0
      : 1;
  }

  @HostListener('window:resize', ['$event']) public onResize(event) {
    this.updateAnimationState();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.selectedOrg?.currentValue?.name !==
      changes?.selectedOrg?.previousValue?.name
    ) {
      if (this.defaultOrgId && !this.activeOrgs) {
        this.initActiveOrgs();
      } else {
        this.changeOrg(false);
      }
    }
  }

  public ngAfterViewInit() {
    this.updateAnimationState();
  }

  public handleClick(organizationId: number) {
    if (this.isLoading) return;
    this.selectOrgWithWarning(organizationId);
  }

  public handleKeyUp(event: KeyboardEvent) {
    const selectedIndex =
      this.activeOrgs[0].organizationId === this.selectedOrg.organizationId
        ? 0
        : 1;
    // Behaves like a set of radio buttons, where arrow keys move selection
    // and also like a dual-state button, where Enter key toggles
    if (
      (selectedIndex === 0 && isKey(event, Key.Down, Key.Right)) ||
      (selectedIndex === 1 && isKey(event, Key.Up, Key.Left)) ||
      isKey(event, Key.Enter)
    ) {
      this.selectOrgWithWarning(
        this.activeOrgs.find((o) => o !== this.selectedOrg).organizationId
      );
    }
  }

  private initActiveOrgs() {
    // first option should be the selected org unless it's external, then it should be the default org
    const optionOne =
      !this.selectedOrg ||
      this.isExternalOrg(this.selectedOrg) ||
      this.isMarketplace(this.selectedOrg)
        ? this.orgs.find((o) => o.organizationId === this.defaultOrgId)
        : this.selectedOrg;

    const externalOrg = !this.ldFlagsService.hideExternalCatalog
      ? this.orgs.find((org) => this.isExternalOrg(org))
      : undefined;

    const marketplaceOrg = this.showMarketplace
      ? this.orgs.find((org) => this.isMarketplace(org))
      : undefined;

    if (this.showMarketplace) {
      this.activeOrgs = marketplaceOrg
        ? [optionOne, marketplaceOrg]
        : [optionOne];
    } else {
      this.activeOrgs = externalOrg ? [optionOne, externalOrg] : [optionOne];
    }

    // initial call to get everything setup, don't emit
    this.changeOrg(false);
  }

  private updateMenuConfig() {
    this.menuConfig = [];
    // filter out active orgs
    const orgsForMenu = this.orgs.filter((org) =>
      this.isExternalOrg(org) && this.ldFlagsService.hideExternalCatalog
        ? false
        : !this.activeOrgs.find((o) => o.organizationId === org.organizationId)
    );

    // setup menu config
    orgsForMenu.forEach((org) => {
      this.menuConfig.push({
        title: org.name,
        defaultAction: () => {
          this.selectOrgWithWarning(org.organizationId);
        },
      });
    });
  }

  private selectOrgWithWarning(organizationId: number) {
    if (this.selectedOrg.organizationId === organizationId) {
      return;
    }

    const selectedOrg = this.orgs.find(
      (o) => o.organizationId === organizationId
    );

    const commitSelection = () => {
      this.selectedOrg = selectedOrg;
      this.changeOrg();
      this.focusSelectedOrg();
    };

    if (this.isMarketplace(selectedOrg)) {
      return this.orgSettingsService
        .getSetting(this.defaultOrgId, 'MarketplaceWarning')
        .pipe(
          take(1),
          switchMap(
            (setting) =>
              setting.enabled
                ? this.orgSelectorExternalWarningService.showMarketplaceWarning(
                    setting.value,
                    this.selectedOrg.name
                  )
                : of({}) // warning is disabled so just continue downstream
          )
        )
        .subscribe(() => commitSelection());
    }

    if (this.isExternalOrg(selectedOrg)) {
      // if switching to external content, we need async check the warning setting
      // and show the warning if enabled, all rolled into a single observable
      // stream returned
      return this.orgSettingsService
        .getSetting(this.defaultOrgId, 'ShowExternalContentWarning')
        .pipe(
          take(1),
          switchMap(
            (setting) =>
              setting.enabled
                ? this.showExternalContentWarning(setting.value)
                : of({}) // warning is disabled so just continue downstream
          )
        )
        .subscribe(() => commitSelection());
    }

    commitSelection();
    return;
  }

  // Performs the actual org change when necessary
  private changeOrg(shouldEmit: boolean = true) {
    if (this.showMarketplace) {
      if (!this.isMarketplace(this.selectedOrg)) {
        this.activeOrgs[0] = this.selectedOrg;
      }
    } else {
      if (!this.isExternalOrg(this.selectedOrg)) {
        this.activeOrgs[0] = this.selectedOrg;
      }
    }

    this.updateMenuConfig();
    // have to run this after the ui has a change to update with the new name to get the width correct
    setTimeout(() => {
      this.updateAnimationState();
      if (shouldEmit) {
        this.selectedOrgChange.emit(this.selectedOrg);
      }
    });
  }

  // Updates animation state for transition
  private updateAnimationState() {
    const targetEl =
      this.orgElements.toArray()[this.selectedIndex]?.nativeElement;
    this.animationData = {
      value: this.selectedIndex,
      params: {
        left: targetEl?.offsetLeft + 'px',
        width: targetEl?.offsetWidth + 'px',
      },
    };
    this.cdr.markForCheck();
  }

  private focusSelectedOrg() {
    this.orgElements.toArray()[this.selectedIndex].nativeElement.focus();
  }

  private showExternalContentWarning(warningText?: string): Observable<void> {
    const bodyText =
      warningText || this.i18n.OrgSettings_ShowExternalContentWarning_Default;

    return this.orgSelectorExternalWarningService.show(
      bodyText,
      this.selectedOrg.name
    );
  }

  private isExternalOrg(org: OrganizationModelIdentifier): boolean {
    return org.organizationId === undefined;
  }

  private isMarketplace(org: OrganizationModelIdentifier): boolean {
    return org.organizationId === -2;
  }
}
