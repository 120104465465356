import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dgxPrettyDuration',
})
export class PrettyDurationPipe implements PipeTransform {
  private hour = 60;
  constructor(private translate: TranslateService) {}

  // Doesn't account for negative minutes? TODO check if it should?
  public transform(minutes: number): string {
    if (minutes > 0) {
      if (minutes === 1) {
        return this.translate.instant('Core_1Minute');
      } else if (minutes < this.hour) {
        return this.translate.instant('Core_MinutesFormat', {
          minutes,
        });
      } else if (minutes === this.hour) {
        return this.translate.instant('Core_1Hour');
      } else if (this.getMinutes(minutes) === 0) {
        return this.translate.instant('Core_HoursFormat', {
          hours: minutes / this.hour,
        });
      } else if (
        (minutes - this.getMinutes(minutes)) / this.hour === 1 &&
        this.getMinutes(minutes) === 1
      ) {
        return this.translate.instant('Core_1Hour1Minute');
      } else if ((minutes - this.getMinutes(minutes)) / this.hour === 1) {
        return this.translate.instant('Core_1HourMinutesFormat', {
          minutes: this.getMinutes(minutes),
        });
      } else {
        return this.translate.instant('Core_HoursMinutesFormat', {
          hours: (minutes - this.getMinutes(minutes)) / this.hour,
          minutes: this.getMinutes(minutes),
        });
      }
    }
    return ''; // JS doesn't account if there may be a negative number?
  }

  private getMinutes(minutes: number): number {
    return minutes % this.hour;
  }
}
