import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { useApolloLayout_Learner_V3 } from '@degreed/apollo-angular';

const APP_ROUTES = [
  {
    path: 'account',
    loadChildren: () =>
      import('../account/account-layout.module').then(
        (m) => m.AccountLayoutModule
      ),
  },
  {
    path: 'orgs',
    loadChildren: () => import('../orgs/orgs.module').then((m) => m.OrgsModule),
  },
  {
    path: 'channel',
    loadChildren: () =>
      import('../channel/channel.module').then((m) => m.ChannelModule),
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('../analytics/analytics.module').then((m) => m.AnalyticsModule),
  },
  {
    path: '',
    loadChildren: () => {
      const loader = useApolloLayout_Learner_V3()
        ? import('../learner-experience/v3/learner-experience.module')
        : import('../learner-experience/v2/learner-experience.module');

      return loader.then((m) => m.LearnerExperienceModule);
    },
  },
  {
    path: 'validate-email-link-expired',
    loadComponent: () =>
      import(
        '../error-handling/components/verify-email-error/verify-email-error.component'
      ).then((m) => {
        return m.VerifyEmailErrorComponent;
      }),
  },
  {
    path: '**',
    loadComponent: () =>
      import(
        '../error-handling/components/handle-error/handle-error.component'
      ).then((m) => {
        return m.HandleErrorComponent;
      }),
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
