import { NgModule } from '@angular/core';

import { CHFileLoaderService, CHUploadService } from './services';

/**
 * Register services for ContentHosting
 *
 * Note: We do not need to use forRoot() to register these singleton services
 * because these services do not have internal state.
 */
@NgModule({
  providers: [CHUploadService, CHFileLoaderService],
})
export class ContentHostingDataAccessModule {}
