<div>
  <dgx-modal-header
    [canCancel]="true"
    (dismiss)="onClose()"
    tabindex="0"
    dgxAutofocus
    >{{ title }}</dgx-modal-header
  >
  <div
    *ngIf="showAnonLearners"
    class="par par--small par--light guts-p-t-1 guts-p-h-2"
  >
    {{ i18n.Core_AnonymousLearnersNotShown }}
  </div>
  <div class="guts-p-h-2 guts-p-b-2 guts-p-t-1">
    <div *ngIf="!loading && (!users || users.length === 0)">
      {{ i18n.Core_NoMembers }}
    </div>
    <ul *ngIf="!loading && users">
      <li
        *ngFor="let user of users"
        class="guts-p-v-1 border-bottom not-last"
        data-dgat="usersListForm-7b3"
      >
        <div class="l_flexbar">
          <div class="guts-p-r-1">
            <dgx-profile-pic
              [profile]="user"
              pic-size="small"
            ></dgx-profile-pic>
          </div>
          <div class="l_flex-grow">
            <a
              *ngIf="!disableLink"
              [href]="user.vanityUrl"
              data-dgat="usersListForm-045"
              target="{{ isIframe ? '_blank' : '_self' }}"
              >{{ user.name }}</a
            >
            <p *ngIf="disableLink">{{ user.name }}</p>
          </div>
          <div
            *ngIf="authUser && !isViewer(user.userProfileId) && showFollowBtns"
            class="guts-m-l-1 action-items no-wrap"
          >
            <button
              df-button
              dfButtonType="clear"
              *ngIf="!isViewersFollowee(user.userProfileId)"
              class="action-link"
              (click)="
                followUser(user.userProfileId, user.name, user.isEngaged)
              "
              data-dgat="usersListForm-0a8"
              [attr.aria-label]="i18n.Core_Follow"
            >
              <div class="l_flexbar gap-full-half">
                <df-icon icon="plus"></df-icon>
                <div>{{ i18n.Core_Follow }}</div>
              </div>
            </button>
            <button
              df-button
              dfButtonType="clear"
              *ngIf="isViewersFollowee(user.userProfileId)"
              class="action-link hover-btn is_active"
              (click)="
                unfollowUser(user.userProfileId, user.name, user.isEngaged)
              "
              data-dgat="usersListForm-9ce"
              [attr.aria-label]="i18n.Core_FollowingButtonLabel"
            >
              <span class="l_flexbar color-white">
                <df-icon
                  icon="checkmark"
                  size="small"
                  class="guts-m-r-half"
                ></df-icon>
                <span>{{ i18n.Core_Following }}</span>
              </span>
            </button>
          </div>
          <div *ngIf="userAttribute" class="guts-m-l-1 action-items no-wrap">
            <ng-container *ngIf="dateAttributes.indexOf(userAttribute) === -1">
              {{ user[userAttribute] }}
            </ng-container>
            <!-- if the userAttribute is one we recognize needs date piping... -->
            <ng-container *ngIf="dateAttributes.indexOf(userAttribute) !== -1">
              {{ user[userAttribute] | date }}
            </ng-container>
          </div>
        </div>
      </li>
      <li class="guts-p-t-1" *ngIf="!showFollowBtns && users.length == 250">
        {{ i18n.Core_ScrollLimited }}
      </li>
    </ul>
    <div *ngIf="loading" class="guts-m-v-2">
      <df-spinner></df-spinner>
    </div>
  </div>
</div>
