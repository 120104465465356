<div class="scrollable guts-p-t-1">
  <!-- Type Filters -->
  <div role="group" class="search-initiation-quick-filters">
    <h1 class="search-initiation-title">
      <df-icon
        size="medium"
        icon="magnifying-glass"
        class="search-initiation-quick-filters__icon"
        role="presentation"
      ></df-icon>
      <span>{{ i18n.LearningSearch_LookingFor }}</span>
    </h1>
    <ul class="search-initiation-quick-filters__links">
      <ng-container
        *ngFor="let filterType of filterTypes"
        data-dgat="search-initiation-autocomplete-326"
      >
        <li>
          <a
            tabindex="0"
            (click)="onFilterClick(filterType.value)"
            (keydown)="
              keydownHandler($event, onFilterClick.bind(this, filterType.value))
            "
            class="ib search-initiation-title search-initiation-quick-filters__links-link"
            data-dgat="search-initiation-autocomplete-b00"
          >
            {{ filterType.title }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <!-- Skill Suggestions -->
  <div
    *ngIf="showDefaults && shouldShowSuggestions"
    class="search-initiation-quick-skills"
  >
    <h1 class="search-initiation-title">
      <df-icon
        size="medium"
        icon="tag"
        class="search-initiation-quick-skills__icon"
        role="presentation"
      ></df-icon>
      <span>{{ i18n.Core_Skills }}</span>
    </h1>
    <div
      role="listbox"
      [closeOnTab]="false"
      tabindex="0"
      [attr.aria-label]="i18n.Core_Skills"
      class="search-initiation-quick-skills__suggestions"
    >
      <ng-container
        *ngFor="let typeVm of suggestions; trackBy: getItemTrackingKey"
        data-dgat="search-initiation-autocomplete-202"
      >
        <ul
          *ngIf="typeVm.subitems.length > 0"
          role="group"
          attr.aria-labelledby="suggestions-label-{{ typeVm.trackingKey }}"
        >
          <li
            *ngFor="
              let suggestionVm of typeVm.subitems | slice: 0 : 6;
              trackBy: getItemTrackingKey
            "
            role="none"
            data-dgat="search-initiation-autocomplete-800"
          >
            <a
              role="option"
              (click)="onSuggestionClick(suggestionVm)"
              (keydown)="
                keydownHandler(
                  $event,
                  onSuggestionClick.bind(this, suggestionVm)
                )
              "
              class="listbox--option search-initiation-title search-initiation-quick-skills__suggestions-suggestion"
              data-dgat="search-initiation-autocomplete-aaf"
            >
              {{ suggestionVm.title }}
            </a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
