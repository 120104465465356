import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dgx-first-queue',
  templateUrl: './first-queue.component.html',
})
export class FirstQueueComponent implements OnInit {
  @Input() public vanityUrl: string;
  @Input() public isIframe = false;

  public savedItemsLink: string;

  constructor(private ngbModalService: NgbModal) {}

  public ngOnInit() {
    this.savedItemsLink = `/${this.vanityUrl}/dashboard/saved`;
  }

  public close() {
    this.ngbModalService.dismissAll();
  }
}
