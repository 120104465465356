import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export type ConfirmProps = {
  disableClose: boolean; // do not allow auto-close on backdrop clicks or closing on escape
  title: string;
  content: string;
  infoText?: string;
  primaryBtnLabel: string;
  secondaryBtnLabel?: string;
  learnMoreLabel?: string;
  learnMoreUrl?: string;
  acknowledgeLabel?: string;
  onClose?: (confirmed: boolean) => void;
  mode?: ConfirmationMode;
  theme?: ConfirmationTheme;
  inputMatch?: string;
  inputPlaceHolder?: string;
};

export type ConfirmationMode = 'default' | 'checkbox' | 'input';
export type ConfirmationTheme = 'default' | 'destructive';

@Component({
  selector: 'da-confirm',
  template: `
    <div
      class="tw-border-1 tw-flex tw-max-w-72 tw-flex-col tw-items-start tw-justify-center tw-gap-4 tw-rounded-2xl tw-border-neutral-200 tw-bg-white tw-p-6 tw-shadow-xl md:tw-max-w-md md:tw-flex-row lg:tw-max-w-xl lg:tw-p-6"
      role="dialog"
      aria-labelledby="da-confirm-title"
    >
      <da-icon
        icon="exclamation-triangle"
        class="tw-mb-4 tw-h-10 tw-min-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 tw-p-2 tw-text-red-800 lg:tw-mb-0"
        type="outline"
        *ngIf="theme === 'destructive'"
      ></da-icon>
      <div class="tw-flex-grow tw-self-stretch">
        <h1
          class="tw-mb-2 tw-text-base tw-font-extrabold tw-text-neutral-800"
          id="da-confirm-title"
          aria-describedby="da-confirm-description"
          tabindex="0"
          autofocus
        >
          {{ title }}
        </h1>
        <!-- start info block -->
        <div
          *ngIf="infoText"
          class="tw-col-span-full tw-my-2 tw-flex tw-items-center tw-gap-3 tw-self-stretch tw-rounded-lg tw-border-0 tw-border-l-4 tw-border-solid tw-bg-neutral-100 tw-pr-4 tw-text-xs tw-text-neutral-800 sm:tw-col-span-8 md:tw-col-start-2 lg:tw-col-span-10 lg:tw-col-start-2 xl:tw-col-span-8 xl:tw-col-start-3"
        >
          <div class="tw-flex tw-items-start tw-rounded-l-lg tw-px-3 tw-py-2">
            <da-icon
              icon="information-circle"
              type="solid"
              [solidSize]="16"
              class="tw-h-4 tw-w-4 tw-flex-none"
            ></da-icon>
            <span class="tw-ml-2">{{ infoText }}</span>
          </div>
        </div>
        <!-- end info block -->
        <p id="da-confirm-description" class="tw-text-neutral-800 tw-whitespace-pre-line">
          {{ content }}
          <a
            *ngIf="learnMoreUrl"
            href="{{ learnMoreUrl }}"
            target="_blank"
            class="tw-text-sm tw-font-semibold tw-text-blue-800"
          >
            {{ learnMoreLabel }}
          </a>
        </p>
        <label
          *ngIf="mode === 'checkbox' && checkboxControl"
          class="tw-mt-4 tw-inline-flex tw-items-center tw-gap-2 tw-text-neutral-700"
        >
          <input
            class="tw-cursor-pointer"
            type="checkbox"
            data-dgat="confirm-modal-checkbox-field"
            [formControl]="checkboxControl"
          />
          {{ acknowledgeLabel }}
        </label>
        <div
          *ngIf="mode === 'input' && inputControl"
          class="w-col-span-full sm:tw-col-span-8 md:tw-col-span-10 md:tw-col-start-2 lg:tw-col-start-2 xl:tw-col-span-10 xl:tw-col-start-3"
        >
          <input
            id="input-component"
            type="text"
            data-dgat="confirm-modal-text-field"
            [formControl]="inputControl"
            [placeholder]="inputPlaceHolder"
            class="tw-align-middle tw-flex tw-h-10 tw-mt-4 tw-w-full tw-flex-col tw-justify-center tw-gap-2 tw-rounded-lg !tw-text-base !tw-font-normal tw-text-neutral-800 tw-border tw-border-neutral-200 tw-bg-white !tw-placeholder-neutral-600 placeholder:tw-not-italic !placeholder:tw-text-neutral-600  focus:tw-outline-none"
          />
        </div>
        <div
          class="tw-flex tw-w-full tw-flex-col tw-justify-end tw-gap-2 tw-pt-4 md:tw-flex-row lg:tw-gap-4"
        >
          <button
            class="tw-btn-medium tw-btn-secondary-filled"
            (click)="handleClose(false)"
          >
            {{ secondaryBtnLabel }}
          </button>
          <button
            class="tw-btn-medium"
            [ngClass]="{
              'tw-btn-primary': theme === 'default',
              'tw-btn-destructive': theme === 'destructive',
            }"
            [attr.aria-disabled]="isConfirmDisabled"
            (click)="handleClose(true)"
          >
            {{ primaryBtnLabel }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class ConfirmComponent {
  @Input() public acknowledgeLabel?: string = '';
  @Input() public content: string = '';
  @Input() public infoText?: string = '';
  @Input() public inputPlaceHolder?: string = '';
  @Input() public inputMatch?: string = '';
  @Input() public learnMoreLabel?: string = '';
  @Input() public learnMoreUrl?: string = '';
  @Input() public primaryBtnLabel: string = '';
  @Input() public secondaryBtnLabel?: string = '';
  @Input() public title: string = '';
  @Input() public mode?: ConfirmationMode = 'default';
  @Input() public theme?: ConfirmationTheme = 'default';
  @Output() public close: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkboxControl = new FormControl(false);
  inputControl = new FormControl();
  get isConfirmDisabled() {
    if (this.mode === 'default') {
      return false;
    }
    return this.checkboxErrorState || this.inputErrorState;
  }
  get checkboxErrorState() {
    return this.mode === "checkbox" && !this.checkboxControl.value;
  }
  get inputErrorState() {
    return this.mode === "input" && (!!this.inputMatch && this.inputControl.value !== this.inputMatch);
  }
  constructor(
    @Inject(DIALOG_DATA)
    public data: ConfirmProps,
    public translate: TranslateService
  ) {
    this.infoText = data.infoText;
    this.title = data.title;
    this.content = data.content;
    this.primaryBtnLabel = data.primaryBtnLabel;
    this.secondaryBtnLabel =
      data.secondaryBtnLabel ?? this.translate.instant('Core_Cancel');
    this.learnMoreUrl = data.learnMoreUrl;
    this.learnMoreLabel =
      data.learnMoreLabel ?? this.translate.instant('Core_Learn_More');
    this.acknowledgeLabel =
      data.acknowledgeLabel ??
      this.translate.instant('Core_Acknowledge_Changes');
    this.mode = data.mode ?? 'default';
    this.theme = data.theme ?? 'default';
    this.inputMatch = data.inputMatch ?? this.translate.instant('Core_ConfirmModalDefaultInputString');
    this.inputPlaceHolder = data.inputPlaceHolder ?? this.translate.instant('Core_ConfirmModalInputStringPlaceHolder', {
      input: this.inputMatch,
    });
  }

  handleClose(confirmed: boolean): void {
    // only skip if user tried to click the primary button and it's still disabled
    if (confirmed && this.isConfirmDisabled) {
      return;
    }

    if (!!this.data.onClose) {
      this.data.onClose(confirmed);
    }
    this.close.emit(confirmed);
  }
}
