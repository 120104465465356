import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { DfIconRegistry } from './icon-registry.service';
import { DfIconMap, DfIconSize } from './icon-sizes.model';

@Injectable({
  providedIn: 'root',
})
export class DfIconService {
  private DfIconMap = DfIconMap;

  constructor(
    private IconRegistry: DfIconRegistry,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  public createIcon(icon: string, size?: DfIconSize): SVGElement {
    const sizeName = this.getSize(size);
    const svgData = this.IconRegistry.getIcon(icon, sizeName);
    const svgIcon = this.createSvgElementFromString(svgData);
    svgIcon.setAttribute('role', 'img'); // for Firefox a11y
    svgIcon.setAttribute('focusable', 'false'); // for IE11 a11y
    svgIcon.setAttribute('data-icon', icon);
    return svgIcon;
  }

  public createSvgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('https://www.w3.org/2000/svg', 'svg')
    );
  }

  public getSize(sizeName: DfIconSize = 'medium'): string {
    // this shouldn't be REM sizes because REM isn't supported by svg
    return this.DfIconMap[sizeName]
      ? this.DfIconMap[sizeName].toString()
      : this.DfIconMap.medium.toString();
  }

  // Transforms to set styles on a given SVG
  public setA11yText(a11yText: string, svg: SVGElement): void {
    if (!a11yText || a11yText.length === 0) {
      svg.setAttribute('aria-hidden', 'true');
    } else {
      const title = this.document.createElement('title');
      const text = this.document.createTextNode(`${a11yText}`);
      title.appendChild(text);
      svg.appendChild(title);
      svg.setAttribute('aria-label', a11yText); // not all browsers will read the <title>
    }
  }

  public setSize(size: DfIconSize, svg: SVGElement): void {
    const sizeVal = this.getSize(size);

    svg.setAttribute('width', sizeVal);
    svg.setAttribute('height', sizeVal);
  }

  public setSvgStyles(opts): void {
    const { svg, size, a11yText } = opts;
    this.setA11yText(a11yText, svg);
    this.setSize(size, svg);
  }
}
