import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@angular/cdk/dialog';

import { GlobalAddModule } from '@app/global-add/global-add.module';
import { SearchSharedModule } from '@app/search/search-shared.module';
import { SharedModule } from '@app/shared/shared.module';

import { LayoutModule_v1 } from '../../v1';
import { AddContentComponent } from './add-content/add-content.component';
import { SearchComponent } from './search/search.component';

import {
  SearchAutocompleteDataService,
  SearchAutocompleteFacade,
} from '@app/search/components/search-initiation-autocomplete';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    SharedModule,
    GlobalAddModule,
    SearchSharedModule,
    RouterModule,
    LayoutModule_v1,
  ],
  declarations: [AddContentComponent, SearchComponent],
  providers: [SearchAutocompleteFacade, SearchAutocompleteDataService],
})
export class LayoutModule_v3 {}
