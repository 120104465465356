<ul *ngIf="kpisLoading" class="grid">
  <li
    *ngFor="let i of kpiPlaceholder"
    [class]="kpiClass"
    data-dgat="kpi-list-d55"
  >
    <dgx-skeleton type="kpi"></dgx-skeleton>
  </li>
</ul>
<ng-container *ngIf="kpisError">
  <ul class="grid">
    <li
      *ngFor="let i of kpiPlaceholder"
      [class]="kpiClass"
      data-dgat="kpi-list-31a"
    >
      <div class="skeleton--kpi border rounded--large center-text">
        <div
          class="grid grid--align-content-center grid--justify-center guts-p-full-1 full-height"
        >
          <df-icon
            icon="info"
            class="guts-m-r-half color-yellow-dark"
          ></df-icon>
        </div>
      </div>
    </li>
  </ul>
  <div class="grid__col-12">
    <df-local-notification
      class="full-width"
      [type]="NotificationType.warning"
      [text]="'OrgReportingCtrl_KPIS_Error' | translate"
      icon="info"
      size="small"
    ></df-local-notification>
  </div>
</ng-container>
<ul *ngIf="!kpisLoading && !kpisError" class="grid">
  <li
    *ngFor="let kpi of kpis; index as kpiIndex"
    [class]="kpiClass"
    data-dgat="kpi-list-84d"
  >
    <dgx-kpi-item [kpi]="kpi" [kpiIndex]="kpiIndex" class="grid"></dgx-kpi-item>
  </li>
</ul>
