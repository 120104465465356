import { Injectable } from '@angular/core';
import {
  InputCreationFeedback,
  UserEpisodeParameters,
} from '@app/inputs/inputs-api.model';
import { InputsService } from '@app/inputs/services/inputs.service';
import { Observable } from 'rxjs';
import { PathwayInputService } from './pathway-input.model';

/**
 * Provides a helper service for pathway episode modal form inputs.
 * TODO: Add additional functions here to load and update modal + add base class for getters and setters when ready if applicable
 * */
@Injectable({
  providedIn: 'root',
})
export class PathwayEpisodeService
  implements PathwayInputService<UserEpisodeParameters>
{
  constructor(private inputsService: InputsService) {}

  /**
   * @param userInputParams parameters for the specific new input type to add and associate with this user
   */
  public addNewInput(
    userInputParams: Partial<UserEpisodeParameters>
  ): Observable<InputCreationFeedback> {
    return this.inputsService.addEpisode(userInputParams);
  }
}
