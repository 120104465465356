import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class CustomAdapterService extends NgbDateAdapter<string> {
  public fromModel(value: string | NgbDateStruct | null): NgbDateStruct | null {
    if (value && typeof value === 'object') {
      return value;
    }

    if (value && typeof value === 'string') {
      const date = value.split('-');
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }

    return null;
  }

  public toModel(date: NgbDateStruct | null): string | null {
    return date
      ? ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2) +
          '-' +
          date.year
      : null;
  }
}
