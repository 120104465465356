import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { LanguageService } from '@app/translation/services/language.service';
import { LanguageLocale } from '@app/inputs/inputs.model';
import { ComboboxFieldOption } from '@app/form-fields/wrappers/combobox-field/combobox-field.component';
import { sortBy } from '@app/shared/utils';
import { lastValueFrom } from 'rxjs';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import {
  VisibilityItem,
  VisibilityOption,
} from '@app/shared/components/visibility/visibility.model';
import {
  GroupIdentifier,
  GroupPrivacy,
  GroupPrivacyLevel,
} from '@app/groups/group-api';

export interface VisibilityField {
  groups: GroupIdentifier[];
  options: VisibilityOption[];
  selectedOption: Visibility;
  groupPrivacyLevel: GroupPrivacyLevel;
}
export interface PublishDateField {
  date?: Date;
  disabled?: boolean; // TODO: Set if field is disabled
}
export interface LanguageField {
  options: ComboboxFieldOption[];
  labelKey: string;
  selectedOption: string;
  disabled?: boolean; // TODO: Set if field is disabled
}

export interface AdvancedSettingsFormModel {
  visibility: { hideFromCatalog?: boolean; groupIds?: GroupIdentifier[] };
  publishedDate: string;
  internalItemId: string;
  language: string;
  isFileManaged?: boolean;
}

@Component({
  selector: 'dgx-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [DF_COLLAPSE_EXPAND],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class AdvancedSettingsComponent implements OnInit {
  @Input() public formModel: AdvancedSettingsFormModel;

  public advancedSettingsForm: FormGroup;
  /** keeps track of the field's current expanded state */
  public expanded: boolean;

  // local models
  public visibilityField: VisibilityField = {
    options: [],
    groups: [],
    selectedOption: undefined,
    groupPrivacyLevel: undefined,
  };
  public publishDateField: PublishDateField;
  public languageField: LanguageField = {
    options: [],
    labelKey: 'key',
    selectedOption: undefined,
  };

  public i18n = this.translate.instant([
    'Core_AdvancedSettings',
    'Core_Collapse',
    'Core_Expand',
    'Core_FieldRequired',
    'Core_NoResultsFound',
    'Core_SelectDate',
    'MediaFormCtrl_TitleRequired',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_CreationDate',
    'dgOrgInternalContentForm_InternalItemId',
    'dgOrgInternalContentForm_AddInternalItemId',
    'dgOrgInternalContentForm_Languages',
    'dgOrgInternalContentForm_SelectLanguage',
    'dgPathwayPrivacy_VisibilityBoostToGroupTooltip',
    'orgInternalContentVisibilitySelector_Title',
    'orgInternalContentVisibilitySelector_VisibleToOrganization',
    'orgInternalContentVisibilitySelector_VisibleToGroups',
    'orgInternalContentVisibilitySelector_TargetGroupsLabel',
    'dgGroupsEdit_AddGroupsToBoostTooltip',
  ]);

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    public parentForm: FormGroupDirective,
    private translate: TranslateService
  ) {}

  public get excludedGroupPrivacyLevels(): GroupPrivacy[] {
    return this.visibilityField.groupPrivacyLevel === GroupPrivacyLevel.Closed
      ? ['Administrative']
      : [];
  }

  public get internalItemIdPlaceholder(): string {
    return !this.formModel.isFileManaged
      ? this.i18n.dgOrgInternalContentForm_AddInternalItemId
      : '';
  }

  public async ngOnInit(): Promise<void> {
    this.initializeForm();
    try {
      await this.initiateLanguageField();
    } catch (e) {}

    if (this.formModel) {
      this.patchInitialValues();
    }

    // if the parent form has add-to-catalog toggle, we want to watch this value change
    // in case we want to not block submit due to required field option on visibility
    const addToCatalog = this.parentForm.form.get('addToCatalog');
    if (addToCatalog) {
      addToCatalog.valueChanges.subscribe((value) => {
        const groupVisibility =
          this.advancedSettingsForm.get('visibility.groups');

        if (value) {
          groupVisibility.addValidators(Validators.required);
        } else {
          groupVisibility.clearValidators();
          groupVisibility.updateValueAndValidity();
        }
      });
    }

    this.cdr.detectChanges();
  }

  public toggleExpanded() {
    this.expanded = !this.expanded;
  }

  /**
   * Check if required field on local formGroup is valid even after parent form is submit
   * @param field
   * @returns boolean
   */
  public isFieldInvalid(field: string): boolean {
    const control = this.advancedSettingsForm.get(field);
    if (!control.hasValidator(Validators.required)) {
      return false;
    }

    const controlInvalid = control.invalid && this.advancedSettingsForm.touched;
    return controlInvalid;
  }

  /**
   * Marks touch status for validation
   */
  public onBlur(field: string) {
    // also mark the field a touched
    const formField = this.advancedSettingsForm.get(field);
    formField.markAsDirty();
    formField.markAsTouched();

    // if field change resulted in a added validator, validate.
    const isRequired = formField.hasValidator(Validators.required);
    if (isRequired) {
      formField.updateValueAndValidity();
    }
  }

  /**
   * Handle when visibility dropdown is changed
   * Also toggles required on the groups input depending on selection
   * @param item VisibilityItem
   * @returns
   */
  public onVisibilitySelection(item: VisibilityItem): void {
    // mark the form field as touched
    this.advancedSettingsForm.markAsTouched();

    if (this.visibilityField.selectedOption === item.visibility) {
      // value didn't change
      return;
    }
    this.visibilityField.selectedOption = item.visibility;

    // clear out the group selection
    this.visibilityField.groups = [];
    this.advancedSettingsForm
      .get('visibility.groups')
      .patchValue(this.visibilityField.groups);
    const visibleToSpecificGroups = item.visibility !== Visibility.public;
    if (visibleToSpecificGroups) {
      // when visibility set to "..for specific groups" the groups field is required
      this.advancedSettingsForm
        .get('visibility.groups')
        .setValidators([Validators.required, Validators.min(1)]);

      // update the group visibility to be limited to
      this.visibilityField.groupPrivacyLevel = GroupPrivacyLevel.Administrative;
    } else {
      // (boosting) groups are optional when Org visibility is selected
      this.advancedSettingsForm.get('visibility.groups').clearValidators();
      // update the group visibility
      this.visibilityField.groupPrivacyLevel = GroupPrivacyLevel.Closed;
    }
    this.advancedSettingsForm.get('visibility.groups').updateValueAndValidity();
  }

  /**
   * Handle when a group is added in the visibility group input
   * @param group GroupIdentifier
   */
  public onGroupSelection(group: GroupIdentifier): void {
    const exists = this.visibilityField.groups?.some(
      (g) => g.groupId === group.groupId
    );
    if (exists) {
      return;
    }

    this.visibilityField.groups.push(group);
    this.advancedSettingsForm
      .get('visibility.groups')
      .patchValue(this.visibilityField.groups);
  }

  /**
   * Handle when a group is removed from the visibility group input
   * @param $event GroupIdentifier
   */
  public onGroupRemove($event: GroupIdentifier): void {
    this.visibilityField.groups = this.visibilityField.groups?.filter(
      ({ groupId }) => $event.groupId !== groupId
    );
    this.advancedSettingsForm
      .get('visibility.groups')
      .patchValue(this.visibilityField.groups);
  }

  /**
   * Handle when a user selects a date
   * @param date
   */
  public onDateSelected(date: Date) {
    this.publishDateField = {
      ...this.publishDateField,
      date: date,
    };
    this.advancedSettingsForm.get('publishedDate').patchValue(date);
    this.advancedSettingsForm.markAsDirty();
  }

  /**
   * Handle when a user selects a language
   * @param LanguageSelection
   */
  public onLanguageSelection(selection: ComboboxFieldOption) {
    // provideOption for language takes the string id (e.g. 'en') and
    // returns the display name (e.g. 'English') in an array
    const option = this.languageField.options.find(
      (lang) => lang.id === selection?.id
    );
    this.languageField.selectedOption = option?.key;
    this.advancedSettingsForm.get('language').patchValue(option);
    this.advancedSettingsForm.markAsDirty();
  }

  private initializeForm() {
    this.initiateVisibilityField();

    this.advancedSettingsForm = this.formBuilder.group({
      visibility: this.formBuilder.group({
        hideFromCatalog: [Validators.required],
        groups: [],
      }),
      publishedDate: [],
      internalItemId: [],
      language: [],
    });

    this.parentForm.form.addControl(
      'advancedSettings',
      this.advancedSettingsForm
    );
  }

  private patchInitialValues() {
    const languageSelection = this.languageField.options.find(
      (lang) => lang.id === this.formModel?.language
    );
    this.languageField.selectedOption = languageSelection?.key;

    const date = new Date(this.formModel.publishedDate);
    this.publishDateField = {
      ...this.publishDateField,
      date: date,
    };
    if (this.formModel.visibility?.groupIds.length > 0) {
      this.visibilityField.selectedOption = this.formModel.visibility
        .groupIds[0].isRestricted
        ? Visibility.groups
        : Visibility.public;
      this.visibilityField.groupPrivacyLevel =
        this.visibilityField.selectedOption !== Visibility.public
          ? GroupPrivacyLevel.Administrative
          : GroupPrivacyLevel.Closed;
      this.visibilityField.groups = this.formModel.visibility.groupIds;
    }

    this.advancedSettingsForm.patchValue({
      visibility: {
        hideFromCatalog: this.formModel.visibility?.hideFromCatalog,
        groups: this.formModel.visibility?.groupIds,
      },
      publishedDate: this.formModel?.publishedDate,
      internalItemId: this.formModel?.internalItemId,
      language: languageSelection,
    });
  }

  private initiateVisibilityField() {
    const visibilityOptions: VisibilityOption[] = [
      {
        disabled: false,
        canExpand: true, // allows boosting to groups
        item: {
          name: this.i18n
            .orgInternalContentVisibilitySelector_VisibleToOrganization,
          visibility: Visibility.public,
          level: Visibility.public,
        },
        expandedGroupText:
          'orgInternalContentVisibilitySelector_TargetGroupsLabel',
        customGroupTooltip: this.i18n.dgGroupsEdit_AddGroupsToBoostTooltip,
      },
      {
        disabled: false,
        canExpand: true, // group visibility selection
        item: {
          name: this.i18n.orgInternalContentVisibilitySelector_VisibleToGroups,
          visibility: Visibility.groups,
          level: Visibility.groups,
        },
        expandedGroupRequired: true,
      },
    ];
    this.visibilityField.options = visibilityOptions;

    // set the default value, visible to Org
    this.visibilityField.selectedOption = visibilityOptions[0].item.visibility;
    this.visibilityField.groupPrivacyLevel = GroupPrivacyLevel.Closed;
  }

  private async initiateLanguageField(): Promise<FormControl> {
    try {
      const request$ = this.languageService.getAllLanguages();
      const languages: LanguageLocale[] = await lastValueFrom(request$);
      languages.forEach(({ name, lcid }) => {
        this.languageField.options.push({
          key: name,
          id: lcid,
        });
        // sort the languages alphabetically by the display values
        this.languageField.options.sort((a, b) => sortBy(a, b, 'key'));
      });
    } catch (error) {
      console.error('Failed to get languages: ', error);
    }

    return this.formBuilder.control('language');
  }
}
