import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrgSetting } from '@app/orgs/services/orgs.model';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-setting-message-edit-modal',
  templateUrl: './setting-message-edit-modal.component.html',
})
export class SettingMessageEditModalComponent implements OnInit {
  @Input() public orgId: number;
  @Input() public setting: OrgSetting;
  @Input() public defaultMessage: string;
  @Input() public message: string;
  @Input() public settingNameTranslation: string;

  private originalMessage: string;

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal
  ) {}

  // this isn't valid i18n formatting, but existed in ajs - should fix at some point
  public get headerText() {
    return `${this.translateService.instant('Core_Edit')} ${
      this.settingNameTranslation
    }`;
  }

  /**
   * Returns whether the current message value is equal to the setting's default message value
   */
  public get isDefaultMessage(): boolean {
    return this.defaultMessage && this.message === this.defaultMessage;
  }

  /**
   * Returns whether or not the modal can be submitted
   */
  public get canSubmit(): boolean {
    return (
      (this.message && this.message !== this.originalMessage) ||
      (this.message && this.isDefaultMessage)
    );
  }

  public ngOnInit(): void {
    this.originalMessage = this.message;
  }

  /**
   * Resets the current message value to the default message value
   */
  public resetToDefault(): void {
    this.message = this.defaultMessage;
  }

  /**
   * Sets the setting's value and closes modal
   */
  public submit(): void {
    if (this.isDefaultMessage) {
      // We have to use empty string here in order to save correctly in the DB
      this.setting.value = '';
    } else {
      this.setting.value = this.message;
    }
    this.activeModal.close(this.setting);
  }
}
