import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { notNullishOrEmptyString } from '@app/utils';

/**
 * Whether to show error for a particular field.
 *
 * @param formGroup
 * @param fieldName
 */
export const showError = (
  formGroup: FormGroup,
  fieldName: string,
  propToCheck: 'dirty' | 'touched' = 'dirty'
): boolean => {
  const field = formGroup.get(fieldName);
  return field.invalid && field[propToCheck];
};

/**
 * When a form control is updated, patch the form group value and mark field as dirty and touched.
 * @param formGroup
 * @param field
 * @param value
 */
export const onFormControlUpdate = (
  formGroup: FormGroup,
  fieldNames: string | string[],
  values: any | any[]
): void => {
  if (!Array.isArray(fieldNames)) {
    fieldNames = [fieldNames];
    values = [values];
  }
  fieldNames.forEach((fieldName, index) => {
    formGroup.patchValue({
      [fieldName]: values[index],
    });
    const field = formGroup.get(fieldName);
    if (!!field) {
      field.markAsDirty();
      field.markAsTouched();
    }
  });
};

export const onFormControlReset = (
  formGroup: FormGroup,
  fieldNames: string | string[]
): void => {
  if (!Array.isArray(fieldNames)) {
    fieldNames = [fieldNames];
  }
  fieldNames.forEach((fieldName) => {
    const field = formGroup.get(fieldName);
    if (!!field) {
      field.setErrors(null);
      field.reset();
    }
  });
};

/**
 * Recursively mark all controls as touched
 * @param formGroup
 */
export const markFormAsTouched = (formGroup: FormGroup): void => {
  Object.values(formGroup.controls).forEach((control) => {
    if (control instanceof FormGroup) {
      markFormAsTouched(control);
    } else {
      control.markAsTouched();
      control.markAsDirty();
    }
  });
};
