<div class="grid__col-12">
  <h3 class="h3 guts-m-b-2" *ngIf="headingName">{{ headingName }}</h3>
  <p class="par guts-m-b-2" *ngIf="headingDescription">
    {{ headingDescription }}
  </p>
  <div class="grid grid--bleed">
    <div
      *ngFor="let keyValue of settings | keyvalue"
      class="grid__col-sm-12 guts-p-b-1"
      data-dgat="org-settings-831"
    >
      <div class="box guts-p-full-2">
        <div *ngIf="isGroupedSetting(keyValue.key, keyValue.value)">
          <h4 class="h4 guts-p-b-half">
            {{ getGroupName(keyValue.key) }}
          </h4>
          <p class="grid__col-sm-6 par--small par--light guts-p-b-2 ib">
            {{ getGroupDescription(keyValue.key) }}
            <a
              *ngIf="canViewKnowledgeCenter"
              target="_blank"
              rel="noopener noreferrer"
              class="link"
              [href]="helpLinkUrl"
              data-dgat="org-settings-a98"
              >{{ i18n.Core_LearnMore }}</a
            >
          </p>
        </div>
        <div
          *ngFor="let setting of keyValue.value; last as isLast"
          data-dgat="org-settings-6fc"
        >
          <dgx-setting-tile
            [configurationValue]="configurationValue"
            [setting]="setting"
            [orgId]="orgId"
            [isMessageSetting]="isMessageSettings"
            [isGroupedSetting]="isGroupedSetting(keyValue.key, keyValue.value)"
            (settingToggled)="onToggleSetting($event)"
          ></dgx-setting-tile>
          <div *ngIf="!isLast" class="divider guts-p-h-1 guts-p-v-1-half"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-glasses"></div>
