<ul class="inline-list m-full-width" *ngIf="memberCounts">
  <li *ngIf="memberCounts.userCount" class="m-guts-p-v-half par par--light">
    {{ memberCountI18n }}
  </li>
  <li
    *ngIf="memberCounts.anonymousUserCount === 1"
    class="m-guts-p-v-half par par--light"
  >
    <strong>&middot;</strong> {{ anonymousCountSingleI18n }}
  </li>
  <li
    *ngIf="memberCounts.anonymousUserCount > 1"
    class="m-guts-p-v-half par par--light"
  >
    <strong>&middot;</strong> {{ anonymousCountI18n }}
  </li>

  <li
    *ngIf="remainingSeats"
    class="m-guts-p-v-half par par--light"
    [ngClass]="{
      'error-text': isOverSubscribed,
      'font-bold': isOverSubscribed
    }"
  >
    <strong>&middot;</strong> {{ remainingSeats }}
    <df-icon
      icon="info"
      size="small"
      class="color-ebony-a61 guts-m-l-half"
      [attr.aria-label]="remainingSeatsTooltip"
      [ngbTooltip]="remainingSeatsTooltip"
      container="body"
    ></df-icon>
  </li>
</ul>
