import { Injectable } from '@angular/core';
import {
  CommentFeedItemModel,
  CommentFeedModel,
  Resource,
} from '@app/comments/comments.model';
import { DeleteCommentConfirmationModalComponent } from '@app/comments/components/delete-comment-confirmation-modal/delete-comment-confirmation-modal.component';
import { CommentModel, ReplyCommentModel } from '@app/inputs/inputs-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { ModalService } from '@app/shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * @note When comparing the /extension/comment/* API to the /comment API used for the webapp
 * the response from the server does not match. Will need to bring this up to the backend team to point
 * this endpoints to the equivalent web app handler
 */
export class EbbCommentsApiService extends ApiServiceBase {
  constructor(
    private httpClient: NgxHttpClient,
    private modalService: ModalService,
    private translateService: TranslateService
  ) {
    super(httpClient, translateService.instant('CommentsSvc_Error'));
  }

  public addComment(
    resource: Resource,
    comment: string,
    parentComment?: CommentFeedItemModel,
    parentReferenceId?: number,
    referenceUserKey?: number
  ): Observable<CommentFeedItemModel | ReplyCommentModel> {
    return this.post<CommentFeedItemModel | ReplyCommentModel>(
      '/extension/comments/PostCommentOrReply',
      {
        referenceId: resource.resourceId,
        itemType: resource.resourceType,
        comment,
        title: resource.title,
        parentCommentId: parentComment?.id,
        parentCommentUserKey: parentComment?.userProfileKey,
        referenceUserKey: referenceUserKey,
        parentReferenceId,
      },
      this.translateService.instant('CommentsSvc_AddCommentError')
    );
  }

  public deleteComment(comment: CommentModel, event: Event): Observable<void> {
    return this.modalService.show(DeleteCommentConfirmationModalComponent, {
      backdropClickClose: true,
      inputs: {
        comment,
      },
      windowClass: 'lg-modal',
    });
  }

  public flagComment(comment: CommentModel): Observable<void> {
    return this.post<void>(
      '/extension/comment/flaginappropriate',
      {
        commentId: comment.id,
        comment: comment.comment,
      },
      this.translateService.instant('CommentsSvc_FlagCommentError')
    );
  }

  public getComments(
    resource: Resource,
    count = 3,
    skip = 0
  ): Observable<CommentFeedModel> {
    return this.get<CommentFeedModel>(
      '/extension/comments',
      {
        referenceId: resource.resourceId,
        referenceType: resource.resourceType,
        count,
        skip,
      },
      this.translateService.instant('CommentsSvc_AccessError')
    );
  }

  public likeComment(
    resource: Resource,
    comment: CommentModel,
    parentReferenceId?: number
  ): Observable<void> {
    // Optimistically update the client state
    const isLike = !comment.favoritedByUser;
    comment.favoritedByUser = isLike;
    comment.favoritedCount += isLike ? 1 : -1;

    return this.post<void>(
      '/extension/comment/like',
      {
        commentItem: comment,
        referenceId: resource.resourceId,
        itemType: resource.resourceType,
        isLike,
        parentReferenceId,
      },
      this.translateService.instant('CommentsSvc_LikeError')
    );
  }

  public loadMore(
    comments: CommentFeedModel,
    take = 10
  ): Observable<CommentFeedModel> {
    return this.getComments(
      {
        resourceId: comments.objectId,
        resourceType: comments.objectType,
      },
      take,
      comments.feed.length
    );
  }

  public getReplies(
    referenceType,
    referenceId: number,
    commentId: number,
    take: number,
    skip: number
  ): Observable<any> {
    return this.get(
      '/extension/comment/replies',
      {
        commentType: referenceType,
        referenceId: referenceId,
        commentId: commentId,
        take,
        skip,
      },
      this.translateService.instant('CommentsSvc_AccessRepliesError')
    );
  }

  public editComment(
    commentId,
    comment,
    itemType,
    itemId,
    ownerId,
    mentions
  ): Observable<any> {
    return this.put(
      '/extension/comment/edit',
      {
        commentId: commentId,
        comment: comment,
        itemType: itemType,
        referenceId: itemId,
        parentReferenceId: ownerId,
        mentions: mentions,
      },
      this.translateService.instant('CommentsSvc_UpdateCommentError')
    );
  }
}
