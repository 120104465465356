import {
  SimpleChanges,
  OnChanges,
  Component,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { DataColumn } from '@app/shared/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import {
  PathwayEnrollment,
  PathwayEnrollmentSourceService,
} from './pathway-enrollment-source.service';

interface Enrollment extends PathwayEnrollment {
  label: string;
  tooltip?: string;
  url?: any;
  click?: () => void;
}

@Component({
  selector: 'dgx-pathway-enrollment-source',
  templateUrl: './pathway-enrollment-source.component.html',
})
export class PathwayEnrollmentSourceComponent implements OnChanges {
  @Input() public orgId: number;
  @Input() public groupId: number;
  @Input() public filterOrgId: number;
  @Input() public pathwayId: number;
  @Input() public pathwayPrivacyLevel: number;

  @ViewChild('label', { static: true }) public labelTemplate: TemplateRef<any>;
  @ViewChild('count', { static: true }) public countTemplate: TemplateRef<any>;

  public isLoading = true;
  public sources: Enrollment[];
  public columns: DataColumn[] = [];

  private readonly TRACKABLE_LINKS_URL: string = '#/insights/links'; // TODO: remove after flag is gone
  private readonly TRACKABLE_LINKS_ROUTE: string = '../insights/links';

  private i18n = this.translate.instant([
    'Core_Other',
    'Core_Plans',
    'Core_RecommendationsDisplayName',
    'PathwayEnrollmentSource_Assignments',
    'PathwayEnrollmentSource_BusinessRules',
    'PathwayEnrollmentSource_OtherTooltip',
    'PathwayEnrollmentSource_TrackableLinks',
  ]);
  constructor(
    private translate: TranslateService,
    private pathwayEnrollmentService: PathwayEnrollmentSourceService
  ) {}

  public ngOnInit(): void {
    this.setColumns();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filterOrgId || changes.groupId) {
      this.getEnrollmentSources();
    }
  }

  private getEnrollmentSources() {
    this.pathwayEnrollmentService
      .getPathwayEnrollments(this.pathwayId, this.groupId, this.filterOrgId)
      .subscribe((result) => {
        this.sources = result.map(({ enrollType, ...rest }) => {
          let routerLink: string, url: string, tooltip: string, label: string;
          // handle special cases
          if (enrollType === 'Assignments') {
            label = this.i18n.PathwayEnrollmentSource_Assignments;
          } else if (enrollType === 'BusinessRules') {
            label = this.i18n.PathwayEnrollmentSource_BusinessRules;
          } else if (enrollType === 'Other') {
            tooltip = this.i18n.PathwayEnrollmentSource_OtherTooltip;
            label = this.i18n.Core_Other;
          } else if (enrollType === 'Recommendations') {
            label = this.i18n.Core_RecommendationsDisplayName;
          } else if (
            enrollType === 'TrackableLinks' ||
            enrollType === 'Trackable Links'
          ) {
            routerLink = this.TRACKABLE_LINKS_ROUTE;
            label = this.i18n.PathwayEnrollmentSource_TrackableLinks;
          } else if (enrollType === 'Targets') {
            label = this.i18n.Core_Plans;
          }
          return {
            ...rest,
            enrollType,
            routerLink,
            url,
            tooltip,
            label: label || enrollType,
          };
        });
        this.isLoading = false;
      });
  }

  private setColumns(): void {
    this.columns = [
      {
        label: 'Core_Type',
        headClasses: 'guts-p-l-0',
        bodyClasses: 'guts-p-l-0',
        template: this.labelTemplate,
      },
      {
        label: 'PathwayEnrollmentSource_AmountSent',
        headClasses: 'right-text guts-p-r-0',
        bodyClasses: 'right-text guts-p-r-0',
        prop: 'sentCount',
        template: this.countTemplate,
      },
      {
        label: 'Core_Follows',
        headClasses: 'right-text guts-p-r-0',
        bodyClasses: 'right-text guts-p-r-0',
        prop: 'enrollCount',
        template: this.countTemplate,
      },
    ];
  }
}
