<ng-container *ngIf="!loading; else showSkeleton">
  <div class="guts-m-t-1">
    <h1 class="h3 guts-p-h-2 guts-p-b-1">
      {{ i18n.dgProfileShareActivity_ShareActivity }}
    </h1>
    <dgx-data-table
      [columns]="columns"
      [isLoading]="loading"
      [items]="allActivity"
    ></dgx-data-table>
  </div>
</ng-container>

<!-- Loading template  -->
<ng-template #showSkeleton>
  <dgx-skeleton type="table" [columns]="6" [rows]="4"></dgx-skeleton>
</ng-template>

<!-- Custom column template-->
<ng-template #title let-item>
  <div>
    <button
      class="left-text color-ebony uhover"
      type="button"
      title="{{ i18n.Core_Title }}"
      attr.aria-label="{{ item.title || '-' }}"
      *ngIf="item.referenceId && item.reference.internalUrl != ''"
      (click)="openRecommendation($event, item)"
      data-dgat="profileShareActivity-753"
    >
      {{ item.title || '-' }}
    </button>
    <span *ngIf="!item.referenceId || item.reference.internalUrl == ''">{{
        item.title || '-'
      }}</span>
  </div>
</ng-template>

<ng-template #userCount let-item>
  <div>
    <button
      type="button"
      [title]="getPeopleAriaLabel(item)"
      [attr.aria-label]="getPeopleAriaLabel(item)"
      (click)="handleOpenUsersModal($event, item)"
      data-dgat="profileShareActivity-6f3"
    >
      {{ item.userCount }}
    </button>
  </div>
</ng-template>

<ng-template #groupCount let-item>
  <div>
    <button
      *ngIf="!!item.groupCount"
      type="button"
      [title]="getGroupsAriaLabel(item)"
      [attr.aria-label]="getGroupsAriaLabel(item)"
      (click)="handleOpenGroupModal($event, item?.recommendationId)"
      data-dgat="profileShareActivity-449"
    >
      {{ item.groupCount }}
    </button>

    <span
      *ngIf="item.groupCount === 0"
      [title]="getGroupsAriaLabel(item)"
      [attr.aria-label]="getGroupsAriaLabel(item)"
      tabindex="0"
    >
      {{ item.groupCount }}
    </span>
  </div>
</ng-template>

<ng-template #commentCount let-item>
  <div>
    <button
      type="button"
      [title]="getCommentsAriaLabel(item)"
      [attr.aria-label]="getCommentsAriaLabel(item)"
      *ngIf="isInputRecommendation(item)"
      (click)="openRecommendation($event, item)"
      data-dgat="profileShareActivity-26f"
    >
      {{ item.commentCount }}
    </button>
    <span *ngIf="!isInputRecommendation(item)" tabindex="0"> - </span>
  </div>
</ng-template>

<ng-template #dateCreated let-item>
  <div>
    <span attr.aria-label="{{ i18n.dgProfileShareActivity_DateShared }}">{{
        item.dateCreated | date: 'mediumDate'
      }}</span>
  </div>
</ng-template>
