import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ConfigOption, FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

import { DfFormFieldBuilder } from './builders/form-field.builder';
import { DfPipesModule } from '../utilities/pipes/pipes.module';
import { DfIconModule } from '../icon/icon.module';
import { DfInputsModule } from '../forms/inputs/inputs.module';
import { DfToggleSwitchModule } from '../forms/toggle-switch/toggle-switch.module';
import { DfFormToggleSwitchComponent } from './form-toggle-switch/form-toggle-switch.component';
import { DfFormTextInputComponent } from './form-text-input/form-text-input.component';
import { DfFormTextareaComponent } from './form-textarea/form-textarea.component';
import { DfFieldWrapperComponent } from './field-wrapper/field-wrapper.component';
import {
  DF_COMMON_FORMS_CONFIG,
  FORMLY_TRANSLATIONS_CONFIG_PROVIDER,
} from './common-forms-config';
import { DfCheckboxModule } from '../forms/inputs/components/checkbox/checkbox.module';
import { DfFormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { DfButtonsModule } from '../forms/buttons/buttons.module';

import { DfFormCustomFieldComponent } from './form-custom-field/form-custom-field.component';
import { DfFormRootComponent } from './form-root/form-root.component';
import { DfContentProviderDirective } from './content-provider/content-provider.directive';
import { DfDgatFieldTypeDirective } from './dgat-field-type/dgat-field-type.directive';
import { DfFieldsetComponent } from './fieldset/fieldset.component';
import { DfLayoutModule } from '../utilities';
import { DfSpinnerButtonModule } from '../placeholders/spinner-button/spinner-button.module';
import { DfLabelMarkerModule } from '../forms/label-marker';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(DF_COMMON_FORMS_CONFIG),
    TranslateModule,
    DfIconModule,
    DfPipesModule,
    DfIconModule,
    DfInputsModule,
    DfCheckboxModule,
    DfToggleSwitchModule,
    DfButtonsModule,
    DfSpinnerButtonModule,
    DfLayoutModule,
    DfLabelMarkerModule,
    NgbTooltipModule,
  ],
  exports: [
    FormlyModule,
    DfDgatFieldTypeDirective,
    DfFormCustomFieldComponent, // This has to be exported so the custom field can have an external template provided to it
    DfFormRootComponent,
  ],
  declarations: [
    DfContentProviderDirective,
    DfDgatFieldTypeDirective,
    DfFieldWrapperComponent,
    DfFieldsetComponent,
    DfFormToggleSwitchComponent,
    DfFormTextInputComponent,
    DfFormTextareaComponent,
    DfFormCheckboxComponent,
    DfFormToggleSwitchComponent,
    DfFormCustomFieldComponent,
    DfFormRootComponent,
  ],
  providers: [DfFormFieldBuilder, FORMLY_TRANSLATIONS_CONFIG_PROVIDER],
})
export class DfFormlyModule {
  /** Initializes the module and exposes its providers.
   * @description By default, the module is configured with degreed's common form components.
   * Because the customConfig is currently tied to the underlying Formly library, it should be
   * avoided unless stricly required.
   */
  public static forRoot(
    customConfig: ConfigOption = {}
  ): ModuleWithProviders<DfFormlyModule> {
    return {
      ngModule: DfFormlyModule,
      providers: [
        DfFormFieldBuilder,
        // pass any additional configuration to formly which will get merged into DF_COMMON_FORMS_CONFIG and formly's default config
        { provide: FORMLY_CONFIG, useValue: customConfig, multi: true },
      ],
    };
  }
}
