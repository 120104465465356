import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
// services
import { TrackerService } from '@app/shared/services/tracker.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** A modal for creating or editing existing Plan and Directory Targets.
 * Supports the following optional resolved input properties:
 * orgId?: number,
 * targetSource?: Partial<Target> -- The target to be edited if any or defaults for a new target. A partial target may be passed
 *    in which case the 'loadTarget' param should be set to true to cause the full target to be fetched.
 * mode?: 'new', 'edit' | 'load-edit',
 * context?: string,
 * isCloning?: boolean..
 * autoPopulate?: boolean
 * loadTarget?: boolean
 * showAutoPopulateOption?: boolean
 */
@Component({
  selector: 'dgx-add-edit-target-modal',
  templateUrl: './add-edit-target-modal.component.html',
})
export class AddEditTargetModal implements OnInit {
  @Input() public resolve: any = {};

  // TODO: This should be refactored so the child controls fire events instead of having intimate step (or rather "state") name knowledge.
  public stepName:
    | 'select'
    | 'plan'
    | 'directory'
    | 'bulkUpload'
    | 'bulkUploadSuccess' = 'select';
  public orgId: number;

  constructor(
    private authService: AuthService,
    private tracker: TrackerService,
    private activeModal: NgbActiveModal
  ) {}

  public ngOnInit() {
    const authUser = this.authService.authUser;
    // TODO: remove after profile plans & target.service.ts & targetSettingsForm migration
    // is complete and njs component is no longer accounted for
    if (this.resolve.targetSource.targetType == undefined) {
      this.resolve.targetSource.targetType =
        this.resolve?.targetSource.TargetType;
    }
    this.orgId =
      this.resolve.targetSource?.organizationId || authUser.defaultOrgId;
    if (this.resolve.mode === 'edit' || this.resolve.targetSource?.targetType) {
      this.setStepName('plan'); // skip the target type selection state when editing an existing target or when type has been pre-speficied
    }
    if (
      !authUser.canManageTargets || // top level directories only allowed with ManageTargets permission
      (this.resolve.isChild && !authUser?.hasCareerPathing)
    ) {
      // skip selection state if creating a plan from within a target (a 'child')
      this.setStepName('plan');
    }
    // track new plan creation
    if (this.resolve.mode !== 'edit') {
      this.trackEventData('Open Create Plan Modal');
    }
  }

  public pageSelectionChange(stepName: string) {
    this.updateStepName(stepName);
  }

  /**
   * For ajs modal. when adding a plan to a plan it was closing all modals,
   * and not submitting the new target information.
   * Note this will be removed with the targetSettingsForm (ajs)
   *
   * @param result
   */
  public onCloseModal(result) {
    this.activeModal.close(result.$event.result);
  }

  public updateStepName(stepName) {
    this.setStepName(stepName);
    if (stepName === 'plan') {
      this.resolve.targetSource.targetType = 'Target'; // default new plan type is Skill Plan
    } else if (stepName === 'directory') {
      this.resolve.targetSource.targetType = 'Directory';
    }
  }
  public backToSelect() {
    this.setStepName('select');
  }

  public setStepName(stepName) {
    this.stepName = stepName;
  }

  private trackEventData(action: string) {
    const authUser = this.authService.authUser;
    this.tracker.trackEventData({
      action,
      category: 'Catalog',
      label: null,
      properties: {
        UserId: authUser.viewerProfile.userProfileKey,
        OrganizationId: authUser.defaultOrgId || undefined,
        Type: this.resolve.targetSource.targetType,
      },
    });
  }
}
