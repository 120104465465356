import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TagRatingMeta } from '@app/tags/tags';
import {
  DfIconCalendar16,
  DfIconClock16,
  DfIconCoins16,
  DfIconRegistry,
  DfIconEyeOpen16,
} from '@lib/fresco';

@Component({
  selector: 'dgx-rating-button-meta',
  templateUrl: './rating-button-meta.component.html',
  styleUrls: ['./rating-button-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingButtonMetaComponent {
  @Input() public options: TagRatingMeta;

  constructor(private iconRegistry: DfIconRegistry) {
    this.iconRegistry.registerIcons([
      DfIconClock16,
      DfIconCoins16,
      DfIconCalendar16,
      DfIconEyeOpen16,
    ]);
  }
}
