import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from '@app/shared/services/notifier.service';
import { RenderMode } from '@app/user-content/user-input/user-input.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CourseNotificationService {
  constructor(
    private translate: TranslateService,
    private notifier: NotifierService
  ) {}

  public notifyCourseInputCreated(title: string) {
    this.notifier.showSuccess(
      this.translate.instant('OrgInternalContentCtrl_SuccessfulAddContent', {
        title,
      })
    );
  }

  public notifyCourseInputUpdated() {
    this.notifier.showSuccess(
      this.translate.instant(
        'OrgInternalContentSvc_SingleItemUpdatedSuccessMessage',
        {}
      )
    );
  }

  public notifyCourseInputCreateFailed() {
    this.notifier.showError(
      this.translate.instant('OrgInternalContentCtrl_GenericError')
    );
  }

  public notifyContentCatalogInputUpdateFailed(error: HttpErrorResponse) {
    if (error?.status === 400 && error?.error?.isDuplicate) {
      this.notifier.showError(
        this.translate.instant(
          'OrgInternalContentSvc_ItemUpdateDuplicateError',
          {
            url: error.error.message.split('URL: ').pop(),
          }
        )
      );
    } else {
      this.notifier.showError(
        this.translate.instant('OrgInternalContentSvc_ItemsUpdateError', {
          inputsCount: 1,
        })
      );
    }
  }
}
