import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { AuthService } from '@dg/shared-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { sortBy } from '@app/shared/utils';

import { TagsApi } from '@app/tags/tag-api.model';

@Component({
  selector: 'dgx-add-suggested-tags-modal',
  templateUrl: './add-suggested-tags-modal.component.html',
  styleUrls: ['./add-suggested-tags-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSuggestedTagsModalComponent implements OnInit {
  // Part of Workday Skills Integration. Before saving will check if any alternative canonical skills exist
  @Input() public checkForCanonicalTags: boolean = false;
  // don't show suggested skills when this is false
  @Input() public canViewSkillSuggestions: boolean = true;
  public tags: TagsApi.Tag[] = [];
  public suggestedTags: TagsApi.Tag[] = [];

  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public maxSuggestions = 25;
  public numDefaultInputTagsForSuggestions = 20;

  // the last tag selected via the dropdown, used to generate a new set suggested tags based on this tag
  public currentTag: TagsApi.Tag;

  public i18n = this.translateService.instant([
    'Core_AddTags',
    'Core_Cancel',
    'TagsCtrl_SuggestedSkillsLoading',
    'TagsCtrl_SuggestedSkillsTitle',
    'TagsCtrl_SuggestedSkillsForSkill',
  ]);

  public currentSuggestedTagIds: number[] = [];

  constructor(
    private trackerService: TrackerService,
    private tagsService: TagsService,
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.getTagSuggestions();
  }

  /**
   * Get the set of suggested tags. If this.currentTag has been set then this tag is used as the input for the suggestions.
   * this.currentTag is set when a user has a selected a tag from the dgx-tags-edit component and not from the suggested tags list.
   * If this.currentTag has not been set - eg, when the modal is first displayed, or the corresponding tag has been
   * removed - then the top 20 tags from the user's tag list are used, based on the latest dateModified values.
   */
  public getTagSuggestions(): void {
    if (!this.canViewSkillSuggestions) {
      return;
    }
    this.isLoading = true;
    this.suggestedTags = [];

    const userTags = this.authService.authUser.viewerInterests;

    let topUserTags;

    if (this.currentTag) {
      topUserTags = this.currentTag.name;
    } else {
      // Get the top 20 tags based on dateModified, sorted in descending order if currentTag is not supplied
      topUserTags = userTags
        .sort((a, b) => sortBy(b, a, 'dateModified'))
        .slice(0, this.numDefaultInputTagsForSuggestions)
        .map((t) => t.name)
        .join(',');
    }

    const allTagIds = userTags.map((t) => t.tagId);
    const allTagNames = userTags.map((t) => t.name.toLowerCase());
    this.tagsService
      .getTagSuggestions(topUserTags, this.maxSuggestions)
      .pipe(take(1))
      .subscribe(({ tags, isFromDs3 }) => {
        this.suggestedTags = tags.reduce((allTags, tag) => {
          if (
            !allTagIds.includes(tag.tagId) &&
            !allTagNames.includes(tag.name.toLowerCase())
          ) {
            allTags.push(tag);
          }
          return allTags;
        }, []);
        this.currentSuggestedTagIds = this.suggestedTags.map((t) => t.tagId);
        this.isLoading = false;
        this.trackSkillSuggestionsLoaded(
          topUserTags.split(','),
          this.suggestedTags.map((t) => t.name)
        );
        this.cdr.detectChanges();
      });
  }

  /**
   * Add a tag from the suggested tags list
   * @param tag - the TagsApi.Tag object that was added from the suggested tags list
   */
  public addSuggestedTag(tag: TagsApi.Tag): void {
    // Prevent duplicate tag items from being added, this can only really happen if someone types in the name of a
    // tag that is also in the suggested tags list, selects it and then tries to add the tag from the suggestions as well
    const isFound = (item: TagsApi.Tag) =>
      item.title.trim().toLowerCase() === tag.title.trim().toLowerCase() ||
      item.name.trim().toLowerCase() === tag.name.trim().toLowerCase();
    if (this.tags.some(isFound)) {
      return;
    }

    this.tags = [...this.tags, tag];
    // remove the selected tag from the suggested tags list
    this.suggestedTags.splice(
      this.suggestedTags.findIndex((t) => t.tagId === tag.tagId),
      1
    );

    // Track user action
    this.trackerService.trackEventData({
      action: 'Suggested Skill Adding Step Completed',
      properties: {
        SkillId: tag.tagId,
        SkillName: tag.name,
      },
    });
  }

  public onAddSuggestedTag(tag: TagsApi.Tag): void {
    this.tags = [...this.tags, tag];
  }

  /**
   * Called when a tag has been added via the combo box input in the dgx-tags-edit component, add the tag and use it
   * as an input to get more suggestions
   * @param tag - the TagsApi.Tag object that was added from dgx-tags-edit
   */
  public onTagAdded(tag: TagsApi.Tag): void {
    this.tags = [...this.tags, tag];
    this.currentTag = tag;
    this.getTagSuggestions();
  }

  /**
   * Called when a tag has been removed from the combo box input in the dgx-tags-edit component, if the tag was a
   * suggested tag from the current set of suggestions, re-add it to the suggestions. If this results in no tags
   * being selected, reset the tag suggestions using the users top 20 tags.
   * @param tag - the TagsApi.Tag object that was removed from dgx-tags-edit
   */
  public onTagRemoved(tag: Partial<TagsApi.Tag>): void {
    this.tags = this.tags.filter(({ name }) => name !== tag.name);
    if (tag.name === this.currentTag?.name) {
      this.currentTag = null;
      this.getTagSuggestions();
    }
    if (!this.tags.length) {
      this.getTagSuggestions();
      return;
    }
    if (this.currentSuggestedTagIds.includes(tag.tagId)) {
      this.suggestedTags = [...this.suggestedTags, tag as TagsApi.Tag];
    }
  }

  /**
   * Check the selected tags to see if they have canonical workday skills before saving.
   * The save button is always enabled, so don't do anything if no tags have been selected
   */

  public save() {
    if (this.tags.length === 0) {
      return;
    }

    this.isSaving = true;

    if (this.checkForCanonicalTags) {
      this.saveCanonicalTags();
    } else {
      this.saveTags();
    }
  }

  /**
   * Save the selected tags via the tagsService
   */
  public saveTags(): void {
    this.tagsService.addUserTags(this.tags).subscribe(() => {
      this.isSaving = false;
      this.authService.fetchAuthenticatedUser().subscribe();
      this.activeModal.close();
    });
  }

  /**
   *  Look for tags with canonical alternatives and save any that do not
   */
  public saveCanonicalTags(): void {
    this.tagsService.getAllCanonicalTags(this.tags).subscribe((results) => {
      const sorted = this.tags.reduce(
        (acc, curr) => {
          const canonical = results.find((result) => result.tag === curr.name);
          if (
            canonical?.hasCanonical &&
            canonical?.tag !== canonical?.canonicalTag
          ) {
            acc.canonical.push(canonical);
          } else {
            acc.nonCanonical.push(curr);
          }
          return acc;
        },
        { canonical: [], nonCanonical: [] }
      );

      if (sorted.nonCanonical.length) {
        this.tagsService.addUserTags(sorted.nonCanonical).subscribe(() => {
          this.isSaving = false;
          this.authService.fetchAuthenticatedUser().subscribe();
          this.activeModal.close(
            sorted.canonical.length ? sorted.canonical : null
          );
        });
      } else {
        this.isSaving = false;
        this.authService.fetchAuthenticatedUser().subscribe();
        this.activeModal.close(sorted.canonical);
      }
    });
  }

  public trackByTitle(index: number, item: TagsApi.Tag): string {
    return item.title;
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  private trackSkillSuggestionsLoaded(
    inputSkillNames: string[],
    suggestedSkillNames: string[]
  ) {
    this.trackerService.trackEventData({
      action: 'Suggested Skills Loaded',
      properties: {
        inputSkillNames,
        suggestedSkillNames,
      },
    });
  }
}
