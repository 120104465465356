import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

function prefixZero(n) {
  return ('0' + n).slice(-2);
}

/** Formats a duration as HH:MM:SS */
export function msToTimeString(s: number): string {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;
  let time = prefixZero(mins) + ':' + prefixZero(secs);

  if (hrs > 0) {
    time = prefixZero(hrs) + ':' + time;
  }

  return time;
}

/** Converts a {@link NgbDateStruct} to a js Date with the day value set to
 * the first of the month
 */
export function ngbDateToJsMonth(ngbDate: NgbDateStruct) {
  // NOTE: js Date month is 0-based, NgbDateStruct month is 1-based
  return new Date(ngbDate.year, ngbDate.month - 1, 1);
}

/** Converts a {@link NgbDateStruct} to a js Date */
export function ngbDateToJsDate(ngbDate: NgbDateStruct) {
  // NOTE: js Date month is 0-based, NgbDateStruct month is 1-based
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
}

/** Converts a js Date to a {@link NgbDate} */
export function jsDateToNgbDate(date: Date) {
  return new NgbDate(
    date.getFullYear(),
    date.getMonth() + 1, // 0 indexed normally
    date.getDate()
  );
}
