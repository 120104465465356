import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { SimpleItemViewModel } from '../../models/core-view.model';
import { ItemContainerRoleDirective } from './item-role-container.directive';

/** Add to list or menu items within a container element that has the @see ItemContainerRoleDirective applied */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[role=menuitem],[role=option]',
})
export class ItemRoleDirective implements AfterViewInit {
  @Input() public itemViewModel: SimpleItemViewModel;
  @Input() public disableTabIndex = true;

  constructor(
    public element: ElementRef<HTMLElement>,
    public container: ItemContainerRoleDirective,
    private renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  public onclick() {
    // this inherently includes pressing the Enter and Space keys, no need to handle those seperately
    if (this.itemViewModel) {
      this.container.onItemClick(this.itemViewModel);
    } else if (this.container.closeOnSelect && !this.container.isListBox) {
      this.container.closeMenuPopover();
    }
  }

  public ngAfterViewInit() {
    // disable tab-ring for each item in favor of arrow keys
    this.disableTabIndex &&
      this.renderer.setAttribute(this.element.nativeElement, 'tabindex', '-1');
  }
}
