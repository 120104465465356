import { inject, InjectionToken, Type } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { InputsRepository } from '@app/shared/patterns/repository/inputs-repository';
import { EpisodeApiEntity } from './episode-forms/repository/episode.entity.model';
import { BookApiEntity } from './book-forms/repository/book.entity.model';
import { AssessmentApiEntity } from './assessment-forms/repository/assessment.entity.model';
import { EventApiEntity } from './event-modal/repository/user-event.entity.model';
import { MediaEntryApiEntity } from './user-input.model';

// Input type repository tree-shakeable tokens
export const EVENT_REPOSITORY: InjectionToken<
  InputsRepository<EventApiEntity>
> = new InjectionToken<InputsRepository<EventApiEntity>>('EVENT_REPOSITORY', {
  providedIn: 'root',
  factory: () => new InputsRepository(inject(NgxHttpClient), 'Event'),
});

export const ARTICLE_REPOSITORY: InjectionToken<
  InputsRepository<MediaEntryApiEntity>
> = new InjectionToken<InputsRepository<MediaEntryApiEntity>>(
  'ARTICLE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new InputsRepository(inject(NgxHttpClient), 'Article'),
  }
);

export const VIDEO_REPOSITORY: InjectionToken<
  InputsRepository<MediaEntryApiEntity>
> = new InjectionToken<InputsRepository<MediaEntryApiEntity>>(
  'VIDEO_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new InputsRepository(inject(NgxHttpClient), 'Video'),
  }
);

export const COURSE_REPOSITORY: InjectionToken<
  InputsRepository<EventApiEntity>
> = new InjectionToken<InputsRepository<EventApiEntity>>('COURSE_REPOSITORY', {
  providedIn: 'root',
  factory: () => new InputsRepository(inject(NgxHttpClient), 'Course'),
});

export const ASSESSMENT_REPOSITORY: InjectionToken<
  InputsRepository<AssessmentApiEntity>
> = new InjectionToken<InputsRepository<AssessmentApiEntity>>(
  'ASSESSMENT_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new InputsRepository(inject(NgxHttpClient), 'Assessment'),
  }
);

export const BOOK_REPOSITORY: InjectionToken<InputsRepository<BookApiEntity>> =
  new InjectionToken<InputsRepository<BookApiEntity>>('BOOK_REPOSITORY', {
    providedIn: 'root',
    factory: () => new InputsRepository(inject(NgxHttpClient), 'Book'),
  });

export const EPISODE_REPOSITORY: InjectionToken<
  InputsRepository<EpisodeApiEntity>
> = new InjectionToken<InputsRepository<EpisodeApiEntity>>(
  'EPISODE_REPOSITORY',
  {
    providedIn: 'root',
    factory: () => new InputsRepository(inject(NgxHttpClient), 'Episode'),
  }
);
// TODO: Add other input type repo tokens here
