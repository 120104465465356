import { Injectable } from '@angular/core';

import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';

import { SkillScaleLevelChangeComponent } from '../components/skill-scale-level-change-modal.component';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { take } from 'rxjs';

export enum SkillScaleLevelChangeModalTypes {
  ORG_RATING_SCALE_CHANGE = 'orgRatingScaleChange',
}
@Injectable({
  providedIn: 'root',
})
export class SkillScaleLevelChangeService {
  constructor(
    private authService: AuthService,
    private http: NgxHttpClient,
    private modalService: ModalService
  ) {}

  public get enableSkillsPlatform(): boolean {
    return (
      this.authService.authUser?.defaultOrgInfo?.settings
        ?.enableSkillsPlatform || false
    );
  }

  public get showPrimaryRatingScaleChanged(): boolean {
    return this.authService.authUser?.showScaleChanged;
  }

  public getUserOrganizationScaleChange() {
    return this.http.get(`/user/getUserOrganizationLatestScaleChangeData`);
  }

  public getSkillScaleLevelChangeModal(adhocRequest: boolean = false) {
    if (
      this.enableSkillsPlatform &&
      (this.showPrimaryRatingScaleChanged || adhocRequest)
    ) {
      this.getUserOrganizationScaleChange()
        .pipe(take(1))
        .subscribe((ratingScaleChanges: any) => {
          ratingScaleChanges = this.markDuplicatePreviousRatingMappings(
            ratingScaleChanges
          );
          this.showModal(ratingScaleChanges);
        });
    }
  }

  private showModal(ratingScaleChange): void {
    this.modalService.show(SkillScaleLevelChangeComponent, {
      backdropClickClose: false,
      keyboardEscClose: false,
      inputs: {
        ratingScaleChange,
      },
    });
  }

  private markDuplicatePreviousRatingMappings(ratingScaleChanges) {
    // mark these items so that we can display appropriately in the ui
    return ratingScaleChanges.map((obj, index, array) => {
      if (index === 0) {
        return obj;
      }
      const prevItemMappings = array[index - 1].mappedValues;
      const hasDuplicatePreviousRatingMappings =
        index !== 0 &&
        obj.mappedValues &&
        !!prevItemMappings &&
        obj.mappedValues.length === prevItemMappings.length &&
        obj.mappedValues.every(
          (value, index) => value === prevItemMappings[index]
        );

      return {
        ...obj,
        duplicatePreviousRatingMappings: hasDuplicatePreviousRatingMappings,
      };
    });
  }
}
