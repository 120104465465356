import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** A utility service for directives and components that maintain hot Observable subscriptions over their lifetime.
 * @description
 * Use this as an alternative to inheriting from {@link SubscriberBaseDirective} when inheritance isn't an option.
 * To use:
 * 1. Provide the service directly on your directive/component to ensure you're getting a 1:1 instance. A typical
 * singleton instance will not work because the service needs to share the same lifetime as the component in order
 * to clean up properly for garbage collection.
 * 2. Call the pipeable operator {@link takeUntilDestroyed} prior to subscribing which will automatically `complete`
 * the observable when the component is destroyed.
 * ```myHotObservable.pipe(this.takeUntilDestroyed()).subscribe(...)```
 */
@Injectable()
export class SubscriptionManagerService implements OnDestroy {
  private destroying = new Subject<void>();

  public ngOnDestroy(): void {
    this.destroying.next();
    this.destroying.complete();
  }

  public takeUntilDestroyed<T>() {
    return <T>(source: Observable<T>) =>
      source.pipe(takeUntil<T>(this.destroying));
  }
}
