import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DfDgatFieldTypeDirective } from '../dgat-field-type/dgat-field-type.directive';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 *
 * A {@link DfInputComponent}-based component for all types of textual, single-line Formly form
 * fields.
 */
@Component({
  selector: 'df-form-text-input',
  templateUrl: './form-text-input.component.html',
  styleUrls: ['./form-text-input.component.scss'],
})
export class DfFormTextInputComponent
  extends DfDgatFieldTypeDirective
  implements OnInit
{
  public labelIds: string;

  public get control() {
    return this.formControl as FormControl;
  }

  public get showErrors() {
    return (
      this.field.formControl?.invalid &&
      (this.field.formControl?.touched || this.field.formControl?.dirty)
    );
  }

  public ngOnInit() {
    super.ngOnInit();

    // Use non-button add-on text for additional input label IDs. Note that the wrapper should
    // also contain the field's formal label which directly references the input.
    this.labelIds = (
      (this.to.prependage?.addOnType !== 'button'
        ? this.id + '-prepend '
        : '') +
      (this.to.appendage?.addOnType !== 'button' ? this.id + '-append' : '')
    ).replace(/\s+$/, '');
  }
}
