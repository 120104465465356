<article
  *ngIf="content"
  class="content-card box break rel l_flex content--{{
    content.resourceType | lowercase
  }}"
  [ngClass]="{ 'content-card--lg': isLargeFormat }"
  data-dgprop-item-clicked="Content Card"
  [attr.data-dgprop-item-type]="content.resourceType"
  [class.tile--selected]="isSelected"
>
  <!-- <article> is most appropriate here: http://stackoverflow.com/questions/3255109/marking-up-a-search-result-list-with-html5-semantics -->

  <div class="l_flex m-l_flex l_flex-column full-width">
    <div class="l_flex-grow-vertical">
      <div class="rel" [ngClass]="{ 'guts-m-h-1 guts-m-t-1': !isLargeFormat }">
        <h2
          class="a11y-hide"
          [innerHTML]="content.title | htmlToPlaintext"
        ></h2>
        <dgx-image-layout
          *ngIf="!hideImage"
          [format]="!isLargeFormat ? 'content-card' : 'content-card-lg'"
          [imageSrc]="content.imageUrl"
          [resourceType]="content.resourceType"
          [videoUrl]="content.videoInfo?.contentUrl"
          [endorsedSrc]="content.isEndorsed ? endorsedSrc : null"
          [resourceId]="content.resourceId"
          [providerImage]="content.providerSummary?.image"
          [class.tile--clickable]="clickable"
          (click)="onImageClick($event)"
          [class.disabled]="isSelected"
        ></dgx-image-layout>
      </div>

      <!-- Body -->
      <div
        class="guts-m-t-2 guts-m-h-2 l_flex l_flex-column l_flex-grow-vertical"
      >
        <dgx-learning-resource-metadata
          *ngIf="!content?.liveSessions"
          [isTileCardView]="true"
          [metaData]="content?.metaData"
          [resourceType]="content?.resourceType"
          [assignmentInfo]="recommendationInfo"
          [config]="{
            compact: !isLargeFormat,
            percentComplete: content?.percentComplete,
            isEnrolledInPathway: content?.isEnrolled,
            isAcademy: content?.isAcademy,
          }"
          [isCompleted]="content.completionInfo?.isCompleted"
          [isInprogress]="content?.isInProgress"
          [disableProviderLink]="isSelected || !isClickable"
          class="guts-m-b-quart"
        ></dgx-learning-resource-metadata>
        <dgx-live-event-metadata
          *ngIf="content?.liveSessions"
          [liveSessions]="content?.liveSessions"
          [assignmentInfo]="recommendationInfo"
          [isRegistered]="content?.isRegistered"
          [isVerified]="content?.isVerified"
          [config]="{
            compact: true,
          }"
          class="guts-m-b-quart"
        ></dgx-live-event-metadata>

        <h4
          [ngClass]="{
            'content-card__title-container--height': expandCard,
            'content-card__title-container--lg': isLargeFormat,
          }"
          class="guts-p-b-1 content-card__title-container"
          data-dgprop-item-clicked="Title"
        >
          <div
            #title
            class="content-card__title"
            [ngClass]="{
              'content-card__title--max-height': expandCard,
              'content-card__title--hide-overflow': !expandCard,
            }"
          >
            <button
              #link
              *ngIf="clickable && opensModal"
              (click)="viewItem($event)"
              class="h4 content-card__title--clickable left-text"
              data-dgat="content-card-2ef"
              [attr.title]="content.title"
              [attr.aria-label]="getContentCardAriaLabel(content)"
            >
              <div [innerHTML]="content.title | htmlToPlaintext"></div>
            </button>
            <!-- Per PD-61286: reverting back to `rel="noopener nofollow"` -->
            <!-- TODO: add routerLink as replacement to href once learner home page route has been migrated -->
            <!-- <ng-container *ngIf="lDFPathwayEnabled; then ngx; else njs"></ng-container> -->
            <a
              #link
              *ngIf="clickable && !opensModal"
              (click)="trackContentClicked($event)"
              [href]="addNewQueryParam(url, 'newWindow=true')"
              [attr.aria-describedby]="
                !content.isPathway && !content.isTarget
                  ? 'a11yNewWindowDescription'
                  : undefined
              "
              [attr.target]="
                !content.isPathway && !content.isTarget && !content.isAcademy
                  ? '_blank'
                  : undefined
              "
              [attr.rel]="
                !content.isPathway && !content.isTarget
                  ? 'noopener nofollow'
                  : undefined
              "
              class="h4 content-card__title--clickable"
              data-dgat="content-card-dfb"
              [attr.title]="content.title | decodeHtml"
              [attr.aria-label]="getContentCardAriaLabel(content)"
              [ngClass]="{
                'content-card__title--lg': isLargeFormat,
              }"
              [innerHTML]="content.title | htmlToPlaintext"
            ></a>
            <span
              *ngIf="!clickable"
              class="h4 content-card__unclickable-title"
              [attr.title]="content.title"
              [innerHTML]="content.title | htmlToPlaintext"
              [attr.aria-label]="getContentCardAriaLabel(content)"
              tabindex="0"
            ></span>
            <button
              *ngIf="showEllipsesButton && !expandCard"
              class="content-card__ellipsis"
              data-dgat="content-card-480"
              [attr.title]="i18n.A11y_ExpandForTitle"
              [attr.aria-label]="i18n.A11y_ExpandForTitle"
              (click)="onClickTitleEllipses(title)"
              [ngClass]="{
                'content-card__ellipsis--lg': isLargeFormat,
              }"
            >
              ...
            </button>
            <!-- POC - Related Content Recommendations -->
            <ng-container *ngIf="hasRelatedContent">
              <df-icon
                class="related-content-icon"
                icon="person-triple"
              ></df-icon>
            </ng-container>
          </div>
        </h4>

        <div
          *ngIf="content.continuingEducationUnits"
          class="par par--small par--light_a"
          data-dgprop-item-clicked="Continuing Education"
        >
          {{ i18n.Core_CEUEligible }}
        </div>

        <div
          *ngIf="isLargeFormat && content?.summary"
          class="content-card__summary par--light_a"
          [innerHTML]="content.summary | stripMarkup"
        ></div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="content-card__footer cursor-default guts-p-full-1">
      <div class="content-card__actions">
        <div *ngIf="!hideFooter" class="l_flexbar">
          <span class="l_flexbar l_flex-grow">
            <dgx-view-academy-button
              *ngIf="content.isAcademy"
              [url]="content.url"
              [size]="isLargeFormat ? 'md' : 'xs'"
              class="guts-m-r-half"
            ></dgx-view-academy-button>

            <!-- Academy v2 Joined Button -->
            <button
              *ngIf="showJoinedButton"
              df-button
              df-button-small
              dfButtonType="primary"
              disabled
              [attr.aria-disabled]="true"
              class="joined-btn guts-m-r-half"
              data-dgat="content-card-5e1"
            >
              <div class="l_flexbar">
                <df-icon
                  icon="checkmark"
                  class="guts-m-r-half"
                  size="small"
                ></df-icon>
                <div>{{ 'Core_Joined' | translate }}</div>
              </div>
            </button>

            <dgx-input-completion
              *ngIf="showCompletionButton"
              class="guts-m-r-half"
              [isCompleted]="content.completionInfo?.isCompleted"
              [recommendationInfo]="recommendationInfo"
              [input]="content"
              [tooltip]="inputCompletionTooltip"
              [iconOnly]="true"
            ></dgx-input-completion>
            <dgx-action-button
              *ngIf="showMoreDetails"
              (click)="openDetails($event)"
              dgatInput="content-card-1e3"
              data-dgprop-item-clicked="ViewDetails"
              [autoToggleState]="false"
              [tooltip]="i18n.Core_ViewDetails"
              [a11yContext]="content.title"
              icon="info"
              [isIconOnly]="true"
              [size]="isLargeFormat ? 'md' : 'xs'"
            >
            </dgx-action-button>
            <!-- Live Event is Live -->
            <dgx-action-button
              *ngIf="content.isLive"
              (click)="openLiveSession()"
              [autoToggleState]="false"
              [isActive]="true"
              icon="video-camera"
              dgatInput="content-card-a4a"
            >
              <div>{{ 'Core_Join' | translate }}</div>
            </dgx-action-button>
            <!-- Follow Pathway -->
            <dgx-action-button
              *ngIf="showFollowPathwayButton"
              class="guts-m-r-half"
              [icon]="content.isEnrolled ? 'checkmark' : 'plus'"
              (click)="onEnrollClick($event)"
              [isActive]="content.isEnrolled"
              [autoToggleState]="false"
              [isDisabled]="isPreview"
              [tooltip]="
                content.isEnrolled ? i18n.Core_Following : i18n.Core_Follow
              "
              [metaData]="content.metaData"
              [isIconOnly]="true"
              dgatInput="content-card-24f"
              [a11yContext]="content.title"
            >
            </dgx-action-button>
            <!-- Follow Target -->
            <dgx-action-button
              *ngIf="content.canFollow && content.isTarget"
              class="guts-m-r-half"
              [icon]="content.isFollowing ? 'checkmark' : 'plus'"
              (click)="onFollowTargetClick()"
              [isActive]="content.isFollowing"
              [autoToggleState]="false"
              [isDisabled]="isPreview"
              [tooltip]="
                content.isFollowing ? i18n.Core_Following : i18n.Core_Follow
              "
              [metaData]="content.metaData"
              [isIconOnly]="true"
              dgatInput="content-card-42d"
              [a11yContext]="content.title"
            >
            </dgx-action-button>
            <!-- Share -->
            <dgx-action-button
              *ngIf="canRecommend"
              [icon]="'arrow-forward'"
              (click)="shareContent($event)"
              [autoToggleState]="false"
              [isActive]="false"
              [isDisabled]="isPreview"
              [tooltip]="i18n.Core_Share"
              [isIconOnly]="true"
              dgatInput="content-card-3fs"
              [a11yContext]="content.title"
            >
            </dgx-action-button>
          </span>
          <dgx-menu
            *ngIf="showActionMenu"
            [menuConfig]="menuConfig"
            placement="bottom-right"
            [appendToBody]="appendMenuToBody"
            [placementAdjustLeftRem]="0.5"
          >
            <button
              df-button
              df-button-square
              dfButtonType="clear"
              type="button"
              [attr.aria-label]="'Core_MoreOptions' | translate"
              data-dgat="content-card-58d"
              class="content-card__menu"
            >
              <df-icon icon="dots" class="color-ebony-a61"></df-icon>
            </button>
          </dgx-menu>
        </div>
      </div>
    </footer>
  </div>
</article>
