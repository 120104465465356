import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export type VideoType =
  | 'youtube'
  | 'vimeo'
  | 'degreed'
  | 'mp4'
  | 'hls'
  | 'vztube'
  | 'other';

/**
 * Video related service.
 *
 * This was split from the old ajs `ContentSvc`.
 */
@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(private sanitizer: DomSanitizer) {}

  public getVideoType(url: string): VideoType {
    const sanitizedUrl = this.sanitize(url) || '';
    const youtubeUrls: string[] = ['youtu.be', 'youtube.com'];
    const vimeoUrls: string[] = ['vimeo.com', 'vimeocdn.com'];
    const hostedUrls: string[] = ['degreed://'];
    const vzTubeUrls: string[] = ['vztube.verizon.com'];

    if (
      sanitizedUrl.indexOf(youtubeUrls[0]) !== -1 ||
      sanitizedUrl.indexOf(youtubeUrls[1]) !== -1
    ) {
      return 'youtube';
    }

    if (
      sanitizedUrl.indexOf(vimeoUrls[0]) !== -1 ||
      sanitizedUrl.indexOf(vimeoUrls[1]) !== -1
    ) {
      return 'vimeo';
    }

    if (sanitizedUrl.indexOf(hostedUrls[0]) !== -1) {
      // we are hosting this from uploader/recorder in CMS
      return 'degreed';
    }

    if (this.isMp4(sanitizedUrl)) {
      return 'mp4';
    }

    if (this.isHls(sanitizedUrl)) {
      return 'hls';
    }

    if (sanitizedUrl.indexOf(vzTubeUrls[0]) !== -1) {
      return 'vztube';
    }

    // if we get here, it's either falsy or not one of the
    // above types
    return 'other';
  }

  public getVimeoEmbedUrl(vimeoKey: string, autoplay: boolean = false): string {
    const urlString = `//player.vimeo.com/video/${vimeoKey}${
      autoplay ? '?autoplay=1' : ''
    }`;
    return this.sanitize(urlString);
  }

  public getVimeoIdByUrl(url: string): string {
    // These should mirror the patterns in the PatternMatchVimeoIdFromUrl method in VimeoHelper.cs
    // Account for vimeo.com/vimeocdn.com url beginning and query string/fragment end
    const idOnlyRegex = /^.+\.com\/([0-9]+)\/?(?:[\?#].*)?$/; // http://vimeo.com/1234 - id is 1234
    const idAtStartRegex = /^.+\.com\/([0-9]+)\/\w+\/?(?:[\?#].*)?$/; // https://vimeo.com/1234/abcd789 - id is 1234
    const channelRegex = /^.+\.com\/channels\/\w+\/([0-9]+)\/?(?:[\?#].*)?$/; // http://vimeo.com/channels/abcd789/1234 - id is 1234
    const albumRegex = /^.+\.com\/album\/\w+\/video\/([0-9]+)\/?(?:[\?#].*)?$/; // http://vimeo.com/album/abcd789/video/1234 - id is 1234

    const match =
      url.match(idOnlyRegex) ||
      url.match(idAtStartRegex) ||
      url.match(channelRegex) ||
      url.match(albumRegex);

    return match ? match[1] : '';
  }

  public getVzTubeEmbedUrl(
    vzTubeKey: string,
    autoplay: boolean = false
  ): string {
    const urlString = `https://vztube.verizon.com/getVideoID.php?type=embed&id=${vzTubeKey}&width=auto&flowplayer=${autoplay}`;

    return this.sanitize(urlString);
  }

  public getVzTubeIdByUrl(url: string): string {
    // vzTube sample url = https://vztube.verizon.com/getVideoID.php?type=embed&id=12345&width=auto&flowplayer=true - id is 12345
    const match = url.match(/id=([^&]*)/);

    return match ? match[1] : '';
  }

  public getYoutubeEmbedUrl(
    youTubeKey: string,
    autoplay: boolean = false
  ): string {
    const url = `https://www.youtube.com/embed/${youTubeKey}?rel=0&showinfo=0&autohide=1&wmode=transparent${
      autoplay ? '&autoplay=1' : ''
    }`;

    return this.sanitize(url);
  }

  public getYoutubeThumbnail(youTubeKey: string): string {
    return this.sanitize(
      `https://img.youtube.com/vi/${youTubeKey}/hqdefault.jpg`
    );
  }

  public isPlayableVideo(url: string): boolean {
    return this.getVideoType(url) !== 'other';
  }

  public youTubeIdParser(url: string) {
    const regExp =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const match = url.match(regExp);

    return match && match[1].length === 11 ? match[1] : undefined;
  }

  public youtubeParseVideoId(url: string): string | boolean {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);

    return match && match[7].length === 11 ? match[7] : false;
  }

  public isMp4(url: string) {
    const mp4Extensions: string[] = ['mp4', 'm4v', 'mpeg4'];
    const parts = url.split('.');
    const extension = parts[parts.length - 1];

    return (
      extension.indexOf(mp4Extensions[0]) === 0 ||
      extension.indexOf(mp4Extensions[1]) === 0 ||
      extension.indexOf(mp4Extensions[2]) === 0
    );
  }

  public isHls(url: string) {
    const hlsExtensions: string[] = ['m3u8'];
    // urls could end with a querystring
    const parts = url.split('.');
    const extension = parts[parts.length - 1];

    return extension.indexOf(hlsExtensions[0]) === 0;
  }

  private sanitize(url: string): string {
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }
}
