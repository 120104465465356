import { ActivatedRouteSnapshot, UrlTree, UrlSegment } from '@angular/router';
import { getDeepCopy } from '@app/shared/utils/property';
import { Opportunity, Skill } from '@app/opportunities/opportunities-api.model';
import { camelCaseKeys } from '@app/shared/utils/property';
import { getTagLevel } from '@app/shared/utils/tag-helpers';
import { AuthUser } from '@app/account/account-api.model';
import { normalizeDateTime } from '@app/reporting/in-app/utils/date-time';
import { TagsApi } from '@app/tags/tag-api.model';
import { numberFromString } from '@app/shared/utils/common-utils';

/**
 * Get an OpportunityEndDate from an Opportunity Duration.
 *
 * @param startDateStr - The start date as a string.
 * @param duration - The duration, e.g. 15.
 * @param durationType - The duration type, e.g. days.
 */
export const getEndDate = (
  startDateStr: string,
  duration: number,
  durationType: string
): string => {
  // if no start date, we have nothing to calculate against
  if (!startDateStr) {
    return '';
  }
  // likewise if duration isn't filled out
  if (!duration || typeof duration !== 'number' || !durationType) {
    return '';
  }

  // ensure our comparisons are *also* done in UTC:
  const startDateObj = normalizeDateTime(startDateStr);
  const startDateObjUTC = new Date(
    startDateObj.getTime() - startDateObj.getTimezoneOffset() * 60000
  );
  let endDateMonths = startDateObjUTC.getUTCMonth();
  let endDateDays = startDateObjUTC.getUTCDate();
  let endDateHours = startDateObjUTC.getUTCHours();

  // only use the duration if it is less than or equal to 100 years
  switch (durationType) {
    case 'Months':
      if (duration <= 1200) {
        endDateMonths += duration;
      }
      break;
    case 'Weeks':
      if (duration <= 5218) {
        endDateDays += duration * 7;
      }
      break;
    case 'Days':
      if (duration <= 36525) {
        endDateDays += duration;
      }
      break;
    case 'Hours':
      if (duration <= 876600) {
        endDateHours += duration;
      }
      break;
  }
  // remove .000Z from the end of the string to match
  // the startDate value.
  return new Date(
    startDateObjUTC.getUTCFullYear(),
    endDateMonths,
    endDateDays,
    endDateHours
  )
    .toISOString()
    .slice(0, -5);
};

/**
 * We need to transform date format to "yyyy-mm-dd", then use toISOString.
 * Otherwise may or may not be ONE DAY OFF depending on YOUR timezone and current time.
 */
export const getISODateString = (date: any) => {
  if (!date) {
    return;
  }

  let dateString: string;

  try {
    // The date format could be mm-dd-yyyy, yyyy-dd-mmT00:00:00
    // or could be any random value that user inputs
    if (typeof date === 'string') {
      if (date.slice(-8) === '00:00:00') {
        dateString = date.slice(0, 10);
      } else {
        dateString = `${date.slice(6)}-${date.slice(0, 2)}-${date.slice(3, 5)}`;
      }
    }

    // For a date in the edit opportunity modal, the date format is a NgbDateStruct.
    // for example:  { year: 1789, month: 7, day: 14 }; // July, 14 1789
    if (typeof date === 'object') {
      dateString = `${date.year}-${('0' + date.month).slice(-2)}-${(
        '0' + date.day
      ).slice(-2)}`;
    }

    // yyyy-mm-ddT00:00:00.000Z
    return new Date(dateString).toISOString();
  } catch (error) {
    return;
  }
};

/**
 * A *temporary* solution to getting the matching skill count for the currently-logged in
 * user when it is not provided.
 *
 * @deprecated As soon as the BE consistently returns matchingSkills count, discard this.
 *
 * @param authUser - An AuthUser, or an AJS AuthUser.
 * @param opportunity - Opportunity that contains everything needed to figure out the matching skills count.
 */
export function getMatchingSkillsCount(
  // not typed because we need types from both the js app and the ngx app (line 163) and our build isn't set up for that yet
  authUser: AuthUser | any,
  // same here
  opportunity: Opportunity
): number {
  if (
    opportunity.hasOwnProperty('matchedSkills') ||
    opportunity.hasOwnProperty('matchingSkills')
  ) {
    return opportunity.matchedSkills?.length ?? opportunity.matchingSkills;
  }

  const oppSkills = opportunity.tags || opportunity.skills || [];

  // TODO: Fix this? The authUser is cached after log in, so if they
  // update their skills after logging in, this will be inaccurate.
  const userSkills = authUser.userInterests || authUser.viewerInterests || [];
  if (!oppSkills.length || !userSkills.length) {
    return 0;
  }

  return (
    oppSkills
      // create a new array with the skill name and isMatch boolean
      .map((skill) => {
        const skillName = skill.name || (skill as string);
        return {
          name: skillName,
          isMatch: userSkills.some(
            ({ name }) => name.toLowerCase() === skillName.toLowerCase()
          ),
        };
      })
      // filter those skills down to only matches
      .filter((skill) => skill.isMatch).length
  );
}

/**
 * Get the id property from an opportunities URL.
 *
 * @param activeSnapshot - The current route's snapshot.
 * @param urlTree - The URL tree, if needed.
 */
export const getOpportunityIdFromRoute = (
  activeSnapshot: ActivatedRouteSnapshot,
  urlTree: UrlTree
): string => {
  // If we can get the ID out of a param map, grab it
  const paramId = activeSnapshot.paramMap.get('id');
  if (!!paramId) {
    return paramId;
  }
  // Otherwise, dig the ID out of the urlTree.
  const segments = urlTree.root.children.primary.segments;
  for (let i = 0, l = segments.length; i < l; ++i) {
    // If our segment path becomes a truthy (e.g. non-zero) number,
    // it's our opportunity ID.
    if (numberFromString(segments[i].path) && idIsOpportunityId(segments, i)) {
      return segments[i].path;
    }
  }
  // Final sanity check
  return '0';
};

/**
 * We only care about name, title and rating
 */
export const mapSkills = (skills: Skill[]) => {
  return skills.map((skill) => {
    const { name, title, rating } = camelCaseKeys(skill);
    return {
      name,
      title,
      rating,
    };
  });
};

/**
 * Sort skills for display in our skill modals. They're sorted
 * first by rating, if any, and then signal, then alphabetically.
 *
 * @param skills
 */
export const sortSkillsForDisplay = (originalSkills: Skill[]) => {
  // don't attempt to format if skills are missing
  if (
    !originalSkills ||
    !Array.isArray(originalSkills) ||
    !originalSkills.length
  ) {
    return [];
  }

  const sortedSkills = getDeepCopy(originalSkills);

  // should only show 1 rating per rating type and use the average score provided with that rating type
  sortedSkills.forEach((s: Skill) => {
    const listOfRatingTypes = new Set(
      s.ratings?.map((r: TagsApi.UserTagRating) => r.type)
    );
    const ratings = [];

    // we want to produce a list with no duplicate rating types
    listOfRatingTypes.forEach((t) => {
      if (t) {
        ratings.push(
          s.ratings?.find((r: TagsApi.UserTagRating) => r.type === t)
        );
      }
    });

    s.ratings = ratings;
  });

  // otherwise, make rating a number and sort
  // (manual sort here for our somewhat complicated work --
  // we don't want to *modify* the skill rating at this point.)
  return sortedSkills.sort((a: Skill, b: Skill) => {
    let result: number;
    // compare ratings
    result = aOrB(getTagLevel(a), getTagLevel(b), true);

    // if the result is uneven, we're done
    if (result !== 0) {
      return result;
    }

    // otherwise, compare userInterestId
    if (a.userInterestId && !b.userInterestId) {
      return -1;
    }

    if (!a.userInterestId && b.userInterestId) {
      return 1;
    }

    // finally, compare names
    return a.name.localeCompare(b.name);
  });
};

// This is just for use internally here.
const aOrB = (
  a: number | string,
  b: number | string,
  isDescending = false
): number => {
  const firstSort = isDescending ? -1 : 1;
  const secondSort = -firstSort;
  return a > b ? firstSort : b > a ? secondSort : 0;
};

const idIsOpportunityId = (segments: UrlSegment[], index: number): boolean => {
  const prevPath = segments[index - 1].path;
  return prevPath === 'opportunities' || prevPath === 'browse';
};
