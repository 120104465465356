import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  /**
   * Lazy load the ChartsModule
   */
  public static loadModule() {
    return import('@app/charts/charts.module').then((m) => m);
  }

  constructor() {}
}
