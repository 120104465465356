<ul class="grid grid--justify-space-between">
  <li
    *ngIf="!pointsDecommission"
    class="grid__col-4 grid__col-sm-2"
    data-dgat="inputCountsByType-026"
  >
    <div class="border-right">
      <div
        class="h2 block"
        [innerHTML]="totalPoints | number: '1.0-0' : userLocale"
        tabindex="0"
        [attr.aria-label]="
          (totalPoints | number: '1.0-0' : userLocale) +
          ' ' +
          ('dgInputCountsByType_Points' | translate | titlecase)
        "
        role="heading"
      ></div>
      <p
        class="par par--light"
        [innerHTML]="'dgInputCountsByType_Points' | translate | titlecase"
      ></p>
    </div>
  </li>
  <li
    *ngFor="let count of counts; let $index = index; let $last = last"
    class="grid__col-4 grid__col-sm-2"
    data-dgat="inputCountsByType-026"
  >
    <div
      class="m-guts-p-b-1"
      [ngClass]="{
        'border-right':
          (!$last && !isMobile) || (!$last && $index !== 1 && isMobile),
      }"
    >
      <a
        class="h2 block"
        [routerLink]="['../collection']"
        [queryParams]="{ type: count.inputType }"
        [innerHTML]="count.count | number: '1.0-0' : userLocale"
        data-dgat="inputCountsByType-2a4"
        [attr.aria-label]="
          (count.count | number: '1.0-0' : userLocale) +
          ' ' +
          (count.inputType | displayType: count.count !== 1)
        "
      ></a>
      <p
        class="par par--light"
        [innerHTML]="count.inputType | displayType: count.count !== 1"
      ></p>
    </div>
  </li>
</ul>
