import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { tap } from 'rxjs/operators';
import { OrgFaqService } from './../../services/org-faq.service';
import { OrgFaq } from '../../services/orgs.model';
import { SettingsTabComponents } from './../org-settings-nav/org-settings-nav.component';

@Component({
  selector: 'dgx-org-faq',
  templateUrl: './org-faq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgFaqComponent implements OnInit {
  @Input() public orgId: number;
  @Input() public viewOnly: boolean = true;
  public faqs: OrgFaq[];
  public searchTerm: string = '';
  public canEditFaq: boolean = false;
  public manageFAQLink: string;
  public disableAddUpdate: boolean = false;
  public loading: boolean = true;

  public readonly CLASS_NAME = SettingsTabComponents.OrgFaqComponent;

  @ViewChild('createTrigger') public createTrigger: ElementRef<HTMLElement>;

  private readonly count: number = 50;
  private readonly skip: number = 0;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private orgFaq: OrgFaqService,
    private tracker: TrackerService
  ) {}

  public ngOnInit(): void {
    this.canEditFaq =
      this.authService.authUser?.defaultOrgInfo?.permissions?.editSettings;
    this.manageFAQLink = `/orgs/${this.orgId}/settings/faq`;
    this.loadFaqs()
      .pipe(
        tap(() => {
          this.tracker.trackEventData({
            action: 'FAQ Viewed',
            category: this.orgId.toString(),
          });
        })
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  public handleFaqDelete(faq: OrgFaq) {
    this.faqs = this.faqs.filter((x) => x.faqId !== faq.faqId);
    this.cdr.detectChanges();
  }

  public createFaq(event: any) {
    this.disableAddUpdate = true;
    this.faqs.unshift({
      answer: '',
      question: '',
      organizationId: this.orgId,
    } as OrgFaq);
  }

  public handleEditState(isEditing) {
    this.disableAddUpdate = isEditing;
    this.cdr.detectChanges();
  }

  public handleSearch(term?: string) {
    this.searchTerm = term || '';
    this.loadFaqs()
      .pipe(
        tap(() => {
          if (term) {
            this.tracker.trackEventData({
              action: 'FAQ Searched',
              category: this.orgId.toString(),
              properties: {
                keywords: term,
              },
            });
          }
        })
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  public handleCreate(newFaq: OrgFaq) {
    this.faqs[0] = newFaq;
    setTimeout(() => {
      this.createTrigger?.nativeElement.focus();
      this.cdr.markForCheck();
    }, 0);
  }

  public isEmpty() {
    return !this.loading && !this.searchTerm && this.faqs?.length === 0;
  }

  public trackBy(index: number, item: OrgFaq): number {
    return item.faqId;
  }

  private loadFaqs() {
    this.loading = true;

    return this.orgFaq
      .getFaqs(this.orgId, this.count, this.skip, this.searchTerm)
      .pipe(
        tap((faqs) => {
          this.faqs = faqs;
          this.disableAddUpdate = false; // In the event that someone was editing, and then entered a search term, we need to reset this flag
          this.loading = false;
        })
      );
  }
}
