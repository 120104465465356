import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  // TODO: Fix Linter Error
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trapFocus]',
})
export class TrapFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.trapFocus(this.el.nativeElement);
  }

  trapFocus(trapElement) {
    function filterEnabledFocusableElements(focusableEls) {
      return Array.from(focusableEls).filter((el: any) => {
        const styleSelf = window.getComputedStyle(el);
        const styleParentNode = window.getComputedStyle(el.parentNode);
        const isElementInView = !!(
          el.offsetWidth ||
          el.offsetHeight ||
          el.getClientRects().length
        );
        return (
          styleSelf.display !== 'none' &&
          !el.disabled &&
          styleParentNode.display !== 'none' &&
          styleSelf.visibility !== 'hidden' &&
          isElementInView
        );
      });
    }

    function handleKeydown(e) {
      const isTabPressed = e.keyCode === 9;
      if (!isTabPressed) return;
      const focusableSelectors = [
        'a[href]',
        'button',
        'textarea',
        'input',
        'select',
        '[tabindex]:not([tabindex="-1"]',
      ];
      const focusableEls = trapElement.querySelectorAll(
        focusableSelectors.join(', ')
      );
      const enabledFocusableEls = filterEnabledFocusableElements(focusableEls);
      const firstFocusableEl: any = enabledFocusableEls[0];
      const lastFocusableEl: any =
        enabledFocusableEls[enabledFocusableEls.length - 1];
      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    }
    trapElement.addEventListener('keydown', handleKeydown);
  }
}
