import { Component, Input } from '@angular/core';
import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { OpportunityModalService } from '@app/opportunities/components/modals/opportunity-modal.service';
import { UserSearchItem } from '@app/user/user-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';

@Component({
  selector: 'dgx-add-collaborators-modal',
  templateUrl: './add-collaborators-modal.component.html',
})
export class AddCollaboratorsModalComponent {
  @Input() public headerText? = '';
  @Input() public opportunity?: Opportunity;
  @Input() public opportunityIds?: number[] = [];
  @Input() public submitButtonText? = this.translate.instant('Core_Add');

  public collaborators: UserSearchItem[] = [];
  public isSubmitPending = false;

  constructor(
    private activeModal: NgbActiveModal,
    private opportunityModalService: OpportunityModalService,
    private translate: TranslateService
  ) {}

  public onSubmit(): void {
    this.isSubmitPending = true;
    const userProfileKeys = this.collaborators.map((c) => c.userProfileKey);

    this.opportunityModalService
      .addBulkCollaborators({
        opportunityIds: this.opportunityIds,
        userProfileKeys,
      })
      .subscribe({
        next: (collaborators) => {
          this.isSubmitPending = false;
          this.activeModal.close(collaborators);
        },
        // TODO: This will swallow errors instead of surfacing them.
        error: () => {
          this.isSubmitPending = false;
          this.activeModal.close();
        },
      });
  }

  public onCollaboratorSelect($event: AnyRecommendee): void {
    const collaborator = $event as UserSearchItem;
    const exists = this.collaborators.some(
      (c) => c.userProfileKey === collaborator.userProfileKey
    );

    if (!exists) {
      this.collaborators.push(collaborator);
    }
  }

  public onCollaboratorRemove($event: AnyRecommendee): void {
    this.collaborators = this.collaborators.filter(
      ({ userProfileKey }) =>
        ($event as UserSearchItem).userProfileKey !== userProfileKey
    );
  }
}
