<div
  *ngIf="input?.statistics?.topUser"
  class="par par--small par--light"
  data-dgprop-item-clicked="Statistics"
  [ngClass]="{
    'l_flex m-l_flex l_flex-middle': layout == 'learner-home',
    'guts-m-l-half': input.statistics.topUser.isEngaged
  }"
>
  <div class="ib guts-p-r-1">
    <dgx-profile-pic
      [profile]="input.statistics.topUser"
      size="tiny"
    ></dgx-profile-pic>
  </div>
  <span [innerHtml]="inputStatisticsString | safe: 'html'"></span>
</div>
