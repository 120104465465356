import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { DfIconModule } from '@lib/fresco';

export type SearchFieldWrapperType =
  | 'short'
  | 'long'
  | 'large'
  | 'squared'
  | 'ebb'
  | 'rounded-full-width'
  | 'search-popover';

@Component({
  selector: 'dgx-search-field-wrapper',
  standalone: true,
  imports: [DfIconModule, NgIf, NgClass],
  templateUrl: './search-field-wrapper.component.html',
  styleUrls: ['./search-field-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldWrapperComponent {
  private static readonly globalSearchModeClasses =
    'dropdown global-search global-search-field-wrapper rounded--large';
  private static readonly searchFieldClass = 'search-field-wrapper';

  /* eslint-disable @typescript-eslint/member-ordering */
  @Input() public icon = 'magnifying-glass-16';
  @Input() public hasError = false;
  @Input() public type: SearchFieldWrapperType;
  @Input() public iconSize: 'small' | 'medium' | 'large' = 'medium';
  @Input() public shouldRenderInGlobalSearchMode: boolean = false;
  /* eslint-enable @typescript-eslint/member-ordering */
  private _styles: string = '';
  @Input() public set styles(value) {
    this._styles = value || '';
  }

  public get styles(): string {
    let defaultClasses = this.shouldRenderInGlobalSearchMode
      ? SearchFieldWrapperComponent.globalSearchModeClasses
      : SearchFieldWrapperComponent.searchFieldClass;

    if (!!this.type) {
      defaultClasses = defaultClasses.concat(
        ' ',
        `${SearchFieldWrapperComponent.searchFieldClass}--${this.type}`
      );
    }

    if (!!this._styles) {
      defaultClasses = defaultClasses.concat(' ', this._styles);
    }

    return defaultClasses;
  }
}
