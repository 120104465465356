import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// services
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';

// types and models
import { DgError } from '@app/shared/models/dg-error';
import {
  ChartSignals,
  ChartTagSignals,
  CollectionCounts,
  ProfileCollectionModel,
  SignalSummary,
} from '@app/profile/profile-api.model';
import { ProfileCollectionService } from '@app/profile/components/profile-collection/services/profile-collection.service';
import {
  ACHIEVEMENT_TYPES,
  ANONYMOUS_LEARNING_TYPES,
  EXPERIENCE_TYPES,
  LEARNING_TYPES,
} from '@app/profile/components/profile-overview/profile-overview.service';
import { SearchFacetValue } from '@app/search';
import { getFacetCount } from '@app/profile/components/profile-overview/profile-overview.utils';
import { catchAndSurfaceError } from '@app/shared/utils';
import { TagsApi } from '@app/tags/tag-api.model';
import { UserSkills } from '@app/profile/components/profile-skill-signal-page/profile-skill-signals.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileSkillSignalService {
  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private profileCollectionService: ProfileCollectionService
  ) {}

  /**
   * Gets signals for chart
   *
   * @param userKey - The key for the user
   * @param tag - tag id
   *
   */
  public getSignalSkillPage(
    userKey: number,
    tag: string
  ): Observable<ChartSignals> {
    const now = new Date();
    const start = new Date();
    start.setMonth(now.getMonth() - 5);
    const currentMonth = now.getMonth() + 1;
    const startMonth = start.getMonth() + 1;
    const startDate = `${start.getFullYear()}-${startMonth}-${now.getDate()}`;
    const endDate = `${now.getFullYear()}-${currentMonth}-${now.getDate()}`;

    return this.http
      .get<ChartSignals>('/user/GetSignalSkillPage', {
        params: {
          userKey: userKey,
          tagId: tag,
          startDate: startDate,
          endDate: endDate,
        },
        cache: false,
      })
      .pipe(
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.translate.instant(
                'UserProfileSvc_ProblemAccessingCollection'
              ),
              error
            )
          );
        })
      );
  }

  /**
   * Get the counts for the profile collection items - experiences, achievements and learnings
   *
   * @param userKey - The key for the user
   */

  public getCollectionCounts(
    userKey: number,
    isLoggedIn: boolean
  ): Observable<CollectionCounts> {
    const facets = [
      {
        id: 'Type',
        values: [...ACHIEVEMENT_TYPES, ...LEARNING_TYPES, ...EXPERIENCE_TYPES],
      },
    ];
    return this.profileCollectionService
      .getCollectionModel(userKey, facets, 0, 1, '', '', false)
      .pipe(
        switchMap((items: ProfileCollectionModel<any>) => {
          const facets = items.facets.find((f) => f.id === 'Type')
            ?.values as unknown as SearchFacetValue[];
          return of({
            learningsCount: getFacetCount(
              facets,
              isLoggedIn ? LEARNING_TYPES : ANONYMOUS_LEARNING_TYPES
            ),
            experiencesCount: getFacetCount(facets, EXPERIENCE_TYPES),
            achievementsCount: getFacetCount(facets, ACHIEVEMENT_TYPES),
          });
        })
      );
  }

  public getSignalCounts(userKey: number): Observable<ChartTagSignals> {
    const now = new Date();
    const start = new Date();
    start.setMonth(now.getMonth() - 5);
    const currentMonth = now.getMonth() + 1;
    const startMonth = start.getMonth() + 1;
    const startDate = `${start.getFullYear()}-${startMonth}-${now.getDate()}`;
    const endDate = `${now.getFullYear()}-${currentMonth}-${now.getDate()}`;

    return this.http
      .get('/user/GetProfileSkillSignalSummary', {
        params: {
          userKey,
          startDate,
          endDate,
        },
      })
      .pipe(
        catchAndSurfaceError(
          this.translate.instant('UserProfileSvc_ProblemAccessingSignalSummary')
        )
      );
  }

  /**
   * Get the focus and other skills for a user.
   * sorted by most recently added.
   *
   * @param userKey - The key for the user
   */
  public getUserSkills(userKey: number): Observable<UserSkills> {
    return this.http
      .get<{ skills: TagsApi.FocusableTagDetails; ratingsTotalCount: number }>(
        '/user/GetSortedProfileSkills',
        {
          params: {
            ownerUserProfileKey: userKey,
          },
        }
      )
      .pipe(
        catchAndSurfaceError(
          this.translate.instant('UserProfileSvc_ProblemAccessingCollection')
        )
      );
  }

  /**
   * Gets the signal's breakdown
   *
   * @param userKey - The key for the user
   * @param tags - comma separated tags id
   *
   */
  public getSignalBreakdown(
    userKey: number,
    tags: string
  ): Observable<SignalSummary[]> {
    const now = new Date();
    const start = new Date();
    start.setMonth(now.getMonth() - 5);
    const currentMonth = now.getMonth() + 1;
    const startMonth = start.getMonth() + 1;
    const startDate = `${start.getFullYear()}-${startMonth}-${now.getDate()}`;
    const endDate = `${now.getFullYear()}-${currentMonth}-${now.getDate()}`;

    return this.http
      .get<SignalSummary[]>('/user/GetSignalBreakdown', {
        params: {
          userKey: userKey,
          tagIds: tags,
          startDate: startDate,
          endDate: endDate,
        },
      })
      .pipe(
        catchAndSurfaceError(
          this.translate.instant('UserProfileSvc_ProblemAccessingCollection')
        )
      );
  }
}
