// originally based on ng-bootstrap's position service
export type DfPlacement =
  | 'auto'
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom'
  | 'top-left'
  | 'top-right'
  | 'top'
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom'
  | 'left'
  | 'right';

export type DfPopoverDirection = 'left' | 'right' | 'up' | 'down';

export type DfPlacementArray = DfPlacement | DfPlacement[] | string;

export type DfElementPosition = {
  width: number;
  height: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export class DfPositioningService {
  private _originalPlacements: DfPlacement[];
  /**
   * This getter/setter help keep track of the original placement value
   * so that it can be used for repositioning calculations such as resizing
   * the window while the popover is open.
   */
  public get originalPlacements() {
    return this._originalPlacements;
  }
  public set originalPlacements(val) {
    this._originalPlacements = val;
  }

  /*
   * Accept the placement array and applies the appropriate placement dependent on the viewport.
   * Returns the applied placement.
   * In case of auto placement, placements are selected in order
   *   'bottom-left', 'bottom-right', 'bottom',
   *   'top-left', 'top-right', 'top'
   *   'left-top', 'left-bottom',
   *   'right-top', 'right-bottom'.
   *   'left', 'right',
   * */
  public positionElement({
    hostElement,
    targetElement,
    placement = 'auto',
    offsetLeftPixels = 0,
    appendToBody,
    baseClass,
    isMobileOptedOut = false,
  }: {
    hostElement: HTMLElement;
    targetElement: HTMLElement;
    placement: string | DfPlacement | DfPlacementArray;
    offsetLeftPixels: number;
    appendToBody?: boolean;
    baseClass?: string;
    isMobileOptedOut?: boolean;
  }): DfPlacement | null {
    const placementVals: DfPlacement[] = Array.isArray(placement)
      ? placement
      : (placement.split(placementSeparator) as DfPlacement[]);

    if (!this.originalPlacements) {
      this.originalPlacements = placementVals;
    }

    const allowedPlacements = [
      'bottom-left',
      'bottom-right',
      'bottom',
      'top-left',
      'top-right',
      'top',
      'left-top',
      'left-bottom',
      'right-top',
      'right-bottom',
      'left',
      'right',
    ];

    const classList = targetElement.classList;
    const addClassesToTarget = (targetPlacement: DfPlacement): string[] => {
      const [primary, secondary] = targetPlacement.split('-');
      const classes: string[] = [];
      if (baseClass) {
        classes.push(`${baseClass}-${primary}`);
        if (secondary) {
          classes.push(`${baseClass}-${primary}-${secondary}`);
        }

        classes.forEach((classname) => {
          classList.add(classname);
        });
      }
      return classes;
    };

    // Remove old placement classes to avoid issues
    if (baseClass) {
      allowedPlacements.forEach((placementToRemove) => {
        classList.remove(`${baseClass}-${placementToRemove}`);
      });
    }

    const placementList = [
      // start with the existing placements, excluding 'auto'
      ...this.originalPlacements.filter((val) => val !== 'auto'),
      // append the rest of the values as fallbacks
      ...allowedPlacements
        .map((allowedPlacement) => {
          const isPlacementInSelection =
            // check if the (allowed) selectedPlacement in the placementList already
            this.originalPlacements.find(
              // is the selectedPlacement an allowed placement (returns value of selectedPlacement if it is)
              (selectedPlacement) =>
                selectedPlacement.search('^' + allowedPlacement) === 0
            ) !== undefined;

          // return the placement option into the new array
          if (!isPlacementInSelection) {
            return allowedPlacement as DfPlacement;
          }
        })
        .filter((val) => val !== undefined),
    ];

    // Required for transform:
    const style = targetElement.style;
    style.position = 'absolute';
    style.top = '0';
    style.left = `${offsetLeftPixels}px`;
    style['will-change'] = 'transform';

    let testPlacement: DfPlacement | null = null;
    let isInViewport = false;
    for (testPlacement of placementList) {
      const addedClasses = addClassesToTarget(testPlacement);

      if (
        positionService.setElementStylesAndCheck({
          hostElement,
          targetElement,
          placement: testPlacement,
          appendToBody,
          isMobileOptedOut,
        })
      ) {
        isInViewport = true;
        break;
      }

      // Remove the baseClasses for further calculation
      if (baseClass) {
        addedClasses.forEach((classname) => {
          classList.remove(classname);
        });
      }
    }

    if (!isInViewport) {
      // If nothing match, the first placement is the default one
      testPlacement = placementList[0];
      addClassesToTarget(testPlacement);
      positionService.setElementStylesAndCheck({
        hostElement,
        targetElement,
        placement: testPlacement,
        appendToBody,
        isMobileOptedOut,
      });
    }

    return testPlacement;
  }

  /**
   * Returns the direction a popover will open given its placement. Primarily used for animations.
   * @param placement Known position string, usually derived from positionElement method of the Positioning service
   */
  public getDirectionFromPlacement(placement: DfPlacement): DfPopoverDirection {
    let [prefix] = placement.split('-');
    if (prefix === 'bottom') {
      prefix = 'down';
    } else if (prefix === 'top') {
      prefix = 'up';
    }
    // left and right open in the direction they are named
    return prefix as DfPopoverDirection;
  }

  /**
   * Sets the overlay position to full width (minus gutters to account for scrollbars / visuals) for mobile use.
   *
   */
  public setMobileStyles({
    targetElement,
    popoverContainerEl,
    popoverContentEl,
    positions,
  }): void {
    const rem = 12;
    const gutter = rem / 2;
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    const { innerHeight } = window;

    // force a mobile style that spans the width of the viewport when the available area is small
    // this also affects zoom if a user is at high zoom like 400%
    targetElement.style.width = `calc(100vw - ${rem}px - ${scrollbarWidth}px)`;

    popoverContainerEl.classList.add('popover__container--mobile');

    // height goes on the content container so the scroll happens inside the outline
    popoverContentEl.style.maxHeight = `${innerHeight -
      positions.top -
      gutter}px`; // available window height - distance to top - bottom margin
  }

  /**
   * Removes the styling set by {@link setMobileStyles} so that the element will be correctly
   * positioned if not in the mobile view.
   */
  public removeMobileStyles({
    targetElement,
    popoverContainerEl,
    popoverContentEl,
  }): void {
    targetElement.style.width = 'initial';
    popoverContainerEl.classList.remove('popover__container--mobile');
    popoverContentEl.style.maxHeight = 'initial';
  }

  private getElementPosition(
    element: HTMLElement,
    round = true
  ): DfElementPosition {
    let elPosition: DfElementPosition;
    let parentOffset: DfElementPosition = {
      width: 0,
      height: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };

    if (this.getStyle(element, 'position') === 'fixed') {
      elPosition = element.getBoundingClientRect();
      elPosition = {
        top: elPosition.top,
        bottom: elPosition.bottom,
        left: elPosition.left,
        right: elPosition.right,
        height: elPosition.height,
        width: elPosition.width,
      };
    } else {
      const offsetParentEl = this.offsetParent(element);

      elPosition = this.getElementOffset(element, false);

      if (offsetParentEl !== document.documentElement) {
        parentOffset = this.getElementOffset(offsetParentEl, false);
      }

      parentOffset.top += offsetParentEl.clientTop;
      parentOffset.left += offsetParentEl.clientLeft;
    }

    elPosition.top -= parentOffset.top;
    elPosition.bottom -= parentOffset.top;
    elPosition.left -= parentOffset.left;
    elPosition.right -= parentOffset.left;

    if (round) {
      elPosition.top = Math.round(elPosition.top);
      elPosition.bottom = Math.round(elPosition.bottom);
      elPosition.left = Math.round(elPosition.left);
      elPosition.right = Math.round(elPosition.right);
    }

    return elPosition;
  }

  private getElementOffset(
    element: HTMLElement,
    round = true
  ): DfElementPosition {
    const elBcr = element.getBoundingClientRect();
    const viewportOffset = {
      top: window.pageYOffset - document.documentElement.clientTop,
      left: window.pageXOffset - document.documentElement.clientLeft,
    };

    const elOffset = {
      height: elBcr.height || element.offsetHeight,
      width: elBcr.width || element.offsetWidth,
      top: elBcr.top + viewportOffset.top,
      bottom: elBcr.bottom + viewportOffset.top,
      left: elBcr.left + viewportOffset.left,
      right: elBcr.right + viewportOffset.left,
    };

    if (round) {
      elOffset.height = Math.round(elOffset.height);
      elOffset.width = Math.round(elOffset.width);
      elOffset.top = Math.round(elOffset.top);
      elOffset.bottom = Math.round(elOffset.bottom);
      elOffset.left = Math.round(elOffset.left);
      elOffset.right = Math.round(elOffset.right);
    }

    return elOffset;
  }

  /**
   * Returns the x/y in pixels of where the overlay should be placed on the page determined
   * by the given placement values and existing positions of the trigger element and the
   * overlay element itself.
   *
   * @param placements Strings representing the given placement (e.g. 'top' and 'left' or 'mobile')
   * @param hostElPosition Where the trigger element is on the page
   * @param targetOffsets If there are already offsets styled onto the target
   * @param margins If there are margins already styled onto the target
   * @returns { top, left } Coordinates in pixels where the overlay will be positioned
   */
  private getCoordinatesByPlacements(
    placements,
    hostElPosition,
    targetOffsets
  ) {
    let top = 0;
    let left = 0;
    const rem = 12; // this the base REM value, so if that changes this needs to change
    const gutter = rem / 2;
    const { offsetHeight, offsetWidth } = targetOffsets;

    switch (placements.primary) {
      case 'top':
        top = hostElPosition.top - offsetHeight - gutter;
        break;
      case 'bottom':
        top = hostElPosition.top + hostElPosition.height + gutter;
        break;
      case 'left':
        left = hostElPosition.left - offsetWidth - gutter;
        break;
      case 'right':
        left = hostElPosition.left + hostElPosition.width + gutter;
        break;
      case 'mobile':
        top = hostElPosition.top + hostElPosition.height + gutter;
        left = gutter;
    }

    switch (placements.secondary) {
      case 'top':
        top = hostElPosition.top;
        break;
      case 'bottom':
        top = hostElPosition.top + hostElPosition.height - offsetHeight;
        break;
      case 'left':
        left = hostElPosition.left;
        break;
      case 'right':
        left = hostElPosition.left + hostElPosition.width - offsetWidth;
        break;
      case 'center':
        if (placements.primary === 'top' || placements.primary === 'bottom') {
          left =
            hostElPosition.left + hostElPosition.width / 2 - offsetWidth / 2;
        } else {
          top =
            hostElPosition.top + hostElPosition.height / 2 - offsetHeight / 2;
        }
        break;
    }

    return {
      top,
      left,
    };
  }

  /**
   * Sets the overlay's position using styling and does a check to see if the new position is
   * in the bounds of the viewport and returns the result of the check, so that {@link positionElement}
   * can determine whether or not to try to reposition.
   */
  private setElementStylesAndCheck({
    hostElement,
    targetElement,
    placement = 'auto',
    appendToBody,
    isMobileOptedOut = false,
  }: {
    hostElement: HTMLElement;
    targetElement: HTMLElement;
    placement: string;
    appendToBody?: boolean;
    isMobileOptedOut?: boolean;
  }): boolean {
    const [
      placementPrimary = 'top',
      placementSecondary = 'center',
    ] = placement.split('-');

    const hostElPosition = appendToBody
      ? this.getElementOffset(hostElement, false)
      : this.getElementPosition(hostElement, false);

    const breakpointSize = 768; // baby-bear
    const isMobileView =
      window.innerWidth < breakpointSize && !isMobileOptedOut;
    const { offsetHeight, offsetWidth } = targetElement;
    const positions = isMobileView
      ? this.getCoordinatesByPlacements({ primary: 'mobile' }, hostElPosition, {
          offsetHeight,
          offsetWidth,
        })
      : this.getCoordinatesByPlacements(
          { primary: placementPrimary, secondary: placementSecondary },
          hostElPosition,
          { offsetHeight, offsetWidth }
        );
    const popoverContainerEl = targetElement.getElementsByClassName(
      'popover__container'
    )[0];
    const popoverContentEl = targetElement.getElementsByClassName(
      'popover__content'
    )[0];

    // handle extra mobile styling
    if (isMobileView) {
      this.setMobileStyles({
        popoverContainerEl,
        popoverContentEl,
        targetElement,
        positions,
      });
    } else {
      this.removeMobileStyles({
        targetElement,
        popoverContainerEl,
        popoverContentEl,
      });
    }

    // The translate3d/gpu acceleration render a blurry text on chrome, the next line is commented until a browser fix
    // targetElement.style.transform = `translate3d(${Math.round(positions.left)}px, ${Math.floor(positions.top)}px, 0px)`;
    targetElement.style.transform = `translate(${Math.round(
      positions.left
    )}px, ${Math.round(positions.top)}px)`;

    return this.checkTargetInBounds(targetElement);
  }

  /**
   * Check if the targetElement is inside the viewport
   * @param targetElement Popover overlay after positioning
   */
  private checkTargetInBounds(targetElement): boolean {
    const targetElBCR = targetElement.getBoundingClientRect();
    const html = document.documentElement;
    const windowHeight = window.innerHeight || html.clientHeight;
    const windowWidth = window.innerWidth || html.clientWidth;

    return (
      targetElBCR.left >= 0 &&
      targetElBCR.top >= 0 &&
      targetElBCR.right <= windowWidth &&
      targetElBCR.bottom <= windowHeight
    );
  }

  /**
   * Shorthand for {@link window.getComputedStyle}
   */
  private getAllStyles(element: HTMLElement) {
    return window.getComputedStyle(element);
  }

  /**
   *
   * @param element
   * @param prop
   * @returns Only the style value for the given property key
   */
  private getStyle(element: HTMLElement, prop: string): string {
    return this.getAllStyles(element)[prop];
  }

  private isStaticPositioned(element: HTMLElement): boolean {
    return (this.getStyle(element, 'position') || 'static') === 'static';
  }

  private offsetParent(element: HTMLElement): HTMLElement {
    let offsetParentEl =
      (element.offsetParent as HTMLElement) || document.documentElement;

    while (
      offsetParentEl &&
      offsetParentEl !== document.documentElement &&
      this.isStaticPositioned(offsetParentEl)
    ) {
      offsetParentEl = offsetParentEl.offsetParent as HTMLElement;
    }

    return offsetParentEl || document.documentElement;
  }
}

const placementSeparator = /\s+/;
export const positionService = new DfPositioningService();
