<form
  (ngSubmit)="updateDetails()"
  class="form-wrap"
  [formGroup]="pathwayStepDetailsModalForm"
>
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="isSubmitDisabled"
    [isSubmitPending]="isSaving"
    [submitButtonText]="'Core_SaveChanges' | translate"
    [useDefaultForm]="false"
    (dismiss)="onDismiss()"
    bodyClasses="guts-p-h-2 guts-p-v-2"
  >
    <!-- Modal Header -->
    <ng-container class="modal-header">
      {{ i18n.Core_EditDetails }}
    </ng-container>
    <ng-container class="modal-body">
      <!-- Local edit item info  -->
      <df-local-notification
        [type]="NotificationType.info"
        [text]="i18n.Pathways_EditingDescription"
        size="small"
        class="form-wrap__group block"
      ></df-local-notification>

      <!-- Title -->
      <div class="form-wrap__group">
        <label for="title">
          <span class="form__label">{{ i18n.Core_Title }}</span>
          <span
            *ngIf="titleIsEdited"
            class="pathway-step-details-modal__edited"
            >{{ i18n.Pathways_Edited }}</span
          >
        </label>
        <button
          *ngIf="titleIsEdited"
          type="button"
          class="color-ebony-a61 inline"
          (click)="revertTitle()"
          [ngbTooltip]="i18n.Pathways_RestoreToOriginal"
          triggers="focus:blur mouseenter:blur"
          [attr.aria-label]="i18n.Pathways_RestoreToOriginal"
          data-dgat="pathwayEditDescriptionModal-9fc"
        >
          <df-icon
            class="v-super"
            icon="arrow-circular-left"
            size="medium"
          ></df-icon>
        </button>
        <input
          id="title"
          name="title"
          type="text"
          [maxlength]="titleMaxLength"
          required
          data-dgat="pathwayEditDescriptionModal-44b"
          formControlName="titleInput"
        />
        <dgx-validate-field
          *ngIf="showTitleErrors"
          [message]="titleErrorMsg"
        ></dgx-validate-field>
      </div>

      <!-- Description -->
      <div class="form-wrap__group">
        <label for="description">
          <span class="form__label">{{ i18n.Core_Description }}</span>
          <span
            *ngIf="descriptionIsEdited"
            class="pathway-step-details-modal__edited"
            >{{ i18n.Pathways_Edited }}</span
          >
        </label>
        <button
          *ngIf="descriptionIsEdited"
          type="button"
          class="color-ebony-a61 inline"
          (click)="revertDescription()"
          [ngbTooltip]="i18n.Pathways_RestoreToOriginal"
          triggers="focus:blur mouseenter:blur"
          [attr.aria-label]="i18n.Pathways_RestoreToOriginal"
          data-dgat="pathwayEditDescriptionModal-d65"
        >
          <df-icon
            class="v-super"
            icon="arrow-circular-left"
            size="medium"
          ></df-icon>
        </button>
        <textarea
          id="description"
          name="description"
          data-dgat="pathwayEditDescriptionModal-ed3"
          formControlName="descriptionInput"
        ></textarea>
      </div>

      <!-- Image -->
      <div *ngIf="allowSetImage" class="form-wrap__group">
        <dgx-upload-section
          [imageUrl]="image"
          [uploadAdapter]="uploadAdapter"
          (imageParsedEvent)="onImageParsed($event)"
          (uploadSuccessEvent)="onImageUploadSuccess($event)"
          (deleteEvent)="onDeleteImage()"
          [useCropper]="true"
          [aspectRatio]="16 / 9"
          [secondaryAspectRatio]="16 / 5"
        >
        </dgx-upload-section>
      </div>
    </ng-container>
  </dgx-modal>
</form>
