<button
  (click)="onClick()"
  class="button-action"
  [class.button-action--active]="isActive"
  [ngClass]="style.buttonClass"
  [attr.data-dgat]="dataDgat"
  [disabled]="isDisabled"
  [attr.aria-label]="ariaLabel"
  data-dgat="actionButton"
>
  <df-icon [icon]="icon" [size]="style.iconSize"></df-icon>
  <div class="button-action__content font-semibold" aria-live="polite" #label>
    <ng-content></ng-content>
  </div>
</button>
