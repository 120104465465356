import { HostType } from '@app/content-hosting';
import { OrgInfo } from '@app/account/account-api.model';
import { GroupIdentifier } from '@app/groups/group-api';
import { UserPathwaySummary } from '@app/pathways/pathway-api.model';
import { SearchFacet } from '@app/search/search-api.model';
import {
  FlexibleDate,
  HostedContentMetadata,
  ImageOwner,
  InputType,
  JsonObject,
  JsonObjectOrString,
  LearningResource,
  MediaInputType,
  Reference,
  ReferenceOwner,
  ResourceType,
} from '@app/shared/models/core-api.model';
import { SimpleItemViewModel } from '@app/shared/models/core-view.model';
import { ResourceImageJson } from '@app/shared/services/resource-image/resource-image.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { UserProfileSummary } from '@app/user/user-api.model';
import { UserResource } from '../browser-extension/models/result-api.model';
import { InputActions } from './inputs.enums';
import { PathwayStepDetails } from '@app/pathways/rsm/pathway-api.model';
import { ResourceSuggestionDetails } from '@app/learner-home/learner-home-api.model';
import { AnyInputApiEntity } from '@app/user-content/user-input/user-input.model';
import { MentionItem } from '@app/comments/comments.model';

export interface InputStatisticsTuple<
  TJsonData extends JsonObjectOrString = JsonObject,
> {
  input: Input<TJsonData>;
  statistics: InputStatistics[];
}

export interface InputTypeDetails {
  // could be any input type, not sure if we have a way to handle this cleanly
  details: any;
  statistics: InputStatistics;
  // setting the type for this (RecommendationForUser) causes things to break?
  recommendation: any;
  credSparkFormat: string;
  credSparkExternalId: string;
  credSparkMsg: string;
  isCredSpark: boolean;
}

export interface OrganizationContentMetadata {
  groupIds?: GroupId[];
  hideFromCatalog?: boolean;
}

export interface GroupId {
  id?: number;
  groupId?: number;
  name: string;
  isRestricted?: boolean;
}

export interface OrganizationContent {
  orgContentMetadata?: OrganizationContentMetadata;
}

export interface Language {
  language: string;
}

export type DurationUnitType =
  | 'Unknown'
  | 'Years'
  | 'Months'
  | 'Weeks'
  | 'Days'
  | 'Hours'
  | 'Minutes'
  | 'Seconds'
  | 'Words'
  | 'Pages'
  | 'Questions';

export interface Duration {
  durationUnits?: number;
  durationUnitType: DurationUnitType;
  durationDisplay: string;
  durationHours?: number;
  durationMinutes?: number;
}

export interface SearchLearningModel {
  facets: SearchFacet[];
  hasMoreItems: boolean;
  total: number;
}

export interface LearningInputModel extends SearchLearningModel {
  inputs: InputDetails[];
  statistics: InputStatistics[];
}

export interface InputInfo extends ImageOwner, Duration {
  inputType: InputType;
  inputTypeName: string;
  inputId: number;
  title: string;
  subTitle: string;
  summary: string;
  commentText: string;
  url: string;
  imageDisplayUrl: string;
  organizationName: string;
  internalUrl: string;
  userProviderName: string;
  institutionId: number;
  test: SimpleItemViewModel<Input>[];
  readonly dateCreated?: string;
  dateModified?: string;
  organizationId?: number;
}

export type InputId = number;

export interface InputIdentifier<T extends InputType = InputType> {
  inputId: InputId;
  inputType: T;
  parentResourceId?: number;
  parentResourceTypeName?: string;
}

export interface InputNotification extends InputIdentifier {
  action: InputActions.completed | InputActions.uncompleted;
  userInputId?: number;
  isPathwayOptionalStep?: boolean;
}

export interface Authored {
  author: string;
}

export interface Published {
  publishDate?: string;
}

export interface InputDetails
  extends InputIdentifier,
    LearningResource,
    ImageOwner,
    Duration {
  obsolete: boolean;
  author: string;
  authorship?: string;
  authored?: boolean;
  tags: string | TagsApi.Tag[];
  provider?: string;
  providerId?: number;
  providerName: string;
  providerUrl: string;
  providerInternalUrl: string;
  providerImageInfo: { favicon?: string; svg?: string; png?: string };
  providerImages?: string;
  providerObj?: any;
  organizationId?: number;
  organizationName_Secure: string;
  summary: string;
  cost: string;
  queueItemId?: number;
  isCompleted: boolean;
  externalId: string;
  userHasCommented: boolean;
  providerCode?: string;
  requestingUserRating?: number;
  requiredDueDate?: string;
  imageSizeId?: number;
  narrator: string;
  externalCompletionOnly: boolean;
  dateCreated?: string;
  language: string;
  learningMinutes?: number;
  durationISO: string;
  publishDate?: string;
  dateModified?: Date; // this is coming back as a Date from the BE.
  userInputId?: number;
  format?: string;
  owner?: string;
  continuingEducationUnits?: number;
  groupIds: GroupIdentifier[];
  organizationName: string;
  isQueued: boolean;
  isCmsContent: boolean;
  resourceType: InputType; // Note narrowing of inherited type for inputs
  hasUserDescription?: boolean;
  hasVideoUrl?: boolean;
  videoType?: string;
  youTubeUrl?: string;
  dateRange?: string;
  isVerified?: boolean;
  liveSessions?: InputSession[];
  isLive?: boolean;
  isRegistered?: boolean;
  ////// POC - Related Content Recommendations
  hasRelatedContent?: boolean;
  url: string;
  userResource?: UserResource;
  statistics?: InputStatistics;
  model?: any;
  isSelected?: boolean;
  openInNewTab?: boolean;
  providerSourceId?: number;
  isViewed?: boolean;
  metaData?: any;
  hostedContentDetails?: HostedContentMetadata;
  hostedType?: HostType;
  difficulty?: string;
  hasBrokenUrl?: boolean;
  inputSubType?: string;
  primaryContactResourceName?: string;
  suggestionDetails?: ResourceSuggestionDetails;
}

export interface Taggable {
  tags: TagsApi.Tag[];
}

export interface Input<TJsonData extends JsonObjectOrString = JsonObject>
  extends InputIdentifier,
    Taggable,
    OrganizationContent,
    ImageOwner,
    Language,
    Duration {
  uid: string;
  title: string;
  description: string;
  dateCreated?: string;
  dateModified?: string;
  createdBy?: number | string; // Conflated user ID and user name between some inputs. Migrating to numeric ID only.
  createdByName: string; // Use this instead of createdBy once fully migrated
  organizationId?: number;
  obsolete: boolean;
  fileManaged: boolean;
  durationISO: string;
  url: string;
  externalId: string;
  source: string;
  owner: string;
  details?: TJsonData; // Contains input-specific data. Type parameterization helps ensure that we transform to/from JSON as necesssary.
  accessible: boolean;
  organization: OrgInfo; //CMS functions only
  pathwayStepDetails?: PathwayStepDetails;
  contentType?: InputType;
  resourceType?: ResourceType;
  isCompleted?: boolean;
  hasBrokenUrl?: boolean;
}

export interface UserInputCreationResult extends InputCreationResult {
  userInputId: number;
  masteryPoints: number;
}

export interface ActionFeedback<ResultType, StatisticsType> {
  readonly result: ResultType;
  readonly statistics: StatisticsType;
}

export type InputCreationFeedback = ActionFeedback<
  InputCreationResult,
  InputStatistics
>;

export interface UserInputCreationFeedback
  extends ActionFeedback<UserInputCreationResult, UserInputStatistics> {
  internalUrl: string;
  readonly reward: string;
}

/** Used to post user input for an existing input, i.e. despite its name, a completion for
 *  an existing input
 */
export interface NewInputParameters {
  inputType: InputType;
  inputId: number;
  tags: TagsApi.Tag[];
}

export interface InputPropertiesParameters extends ImageOwner {
  organizationId?: number;
  tags: TagsApi.Tag[];
  language?: string;
  imageSizeId?: number;
  externalId?: string;
  owner?: string;
  orgContentMetadata?: OrganizationContentMetadata;
  resourceImageId?: number;
  hostedContentDetails?: HostedContentMetadata;
}

export interface InputParameters<
  TJsonData extends JsonObjectOrString = JsonObject,
> extends InputPropertiesParameters {
  inputType: string; // TODO: After ngx migration update these to InputType
  title: string;
  description: string;
  url: string;
  details: TJsonData; // Contains input-specific data. Type parameterization helps ensure that we transform to/from JSON as necesssary.
  durationUnits?: number;
  durationUnitType: string;
  durationHours?: number;
  durationMinutes?: number;
}

export interface UpdateInputParameters<
  TJsonData extends JsonObjectOrString = JsonObject,
> extends InputParameters<TJsonData> {
  inputId: number;
}

export interface UserInputIdentifier<T extends InputType = InputType>
  extends InputIdentifier<T> {
  userInputId: number;
  readonly userProfileKey?: number;
}

//#region Assessment
export interface Assessment
  extends Input,
    InputIdentifier,
    Taggable,
    OrganizationContent,
    Published,
    Language {
  providerId?: number;
  providerName: string;
  providerUrl: string;
  url: string;
  name: string;
  description: string;
  questions?: number;
  userCount?: number;
  organizationId?: number;
  userProviderName: string;
  externalId: string;
  fileManaged: boolean;
  obsolete: boolean;
  dateCreated?: string;
  dateModified?: string;
  language: string;
  publishDate?: string;
  owner: string;
  tags: TagsApi.Tag[];
  orgContentMetadata: OrganizationContentMetadata;
  readonly inputType: 'Assessment';
  organizationName: string;
  organizationName_Secure: string;
  isCredSpark: boolean;
  userInputId?: number;
  legacyPictureUrl: string;
  scenario: string;
  durationMinutes: number;
  durationHours: number;
}

export interface UserAssessment
  extends InputIdentifier,
    UserInputIdentifier,
    Taggable,
    ImageOwner {
  userProfileId: string;
  providerId?: number;
  providerName: string;
  userProviderName: string;
  url: string;
  name: string;
  description: string;
  questions?: number;
  percentile?: number;
  questionsAttempted?: number;
  questionsCorrect?: number;
  masteryPoints?: number;
  dateCreated?: string;
  dateCompleted?: string;
  yearCompleted?: number;
  monthCompleted?: number;
  dayCompleted?: number;
  rating?: number;
  isVerified: boolean;
  isImported: boolean;
  readonly inputType: 'Assessment';
}

export interface AssessmentParameters extends InputPropertiesParameters {
  name: string;
  description: string;
  url: string;
  questions?: number;
  providerCode: string;
  userProviderName: string;
  fileManaged?: boolean;
  publishDate?: string;
}

export interface UpdateAssessmentParameters extends AssessmentParameters {
  inputId: number;
}

export interface UserAssessmentParameters extends AssessmentParameters {
  dateCompleted: FlexibleDate;
  questionsAttempted?: number;
  questionsCorrect?: number;
  percentile?: number;
}

export interface UserExistingAssessmentParameters {
  inputId: number;
  dateCompleted: FlexibleDate;
  questionsAttempted?: number;
  questionsCorrect?: number;
  percentile?: number;
  tags: TagsApi.Tag[];
}

export interface UpdateUserAssessmentParameters {
  userInputId: number;
  inputId: number;
  dateCompleted: FlexibleDate;
  questions?: number;
  questionsCorrect?: number;
  percentile?: number;
  tags: TagsApi.Tag[];
}
//#endregion

//#region Book
export interface Book
  extends Input,
    Taggable,
    Duration,
    Authored,
    ImageOwner,
    OrganizationContent,
    Language {
  bookId: number;
  title: string;
  subtitle: string;
  narrator: string;
  url: string;
  summary: string;
  fileManaged: boolean;
  userCount?: number;
  obsolete: boolean;
  organizationId?: number;
  dateCreated?: string;
  dateModified?: string;
  publishDate?: string;
  owner: string;
  format: string;
  readonly inputType: 'Book';
  organizationName: string;
  durationDisplay: string;
  providerCode: string;
  externalId: string;
  externalData: string;
  isbn: string;
}

export interface BookParameters extends InputPropertiesParameters {
  title: string;
  subtitle: string;
  summary: string;
  author: string;
  durationUnits?: number;
  durationUnitType: DurationUnitType;
  provider: string;
  isbn13: string;
  externalData: string;
  format: string;
}
export interface Task extends Taggable {
  dateCreated: string;
  dateModified: string;
  durationISO: string;
  durationUnitType: DurationUnitType;
  durationUnits: number;
  fileManaged: boolean;
  inputId: number;
  readonly inputType: 'Task';
  imageUrl: string;
  obsolete: boolean;
  organizationId: number;
  organizationName: string;
  organizationName_Secure?: string;
  summary: string;
  taskId: number;
  title: string;
  userCount: number;
}

export interface TaskParameters extends InputPropertiesParameters {
  legacyPictureUrl?: string;
  title: string;
  durationUnits?: number;
  durationHours?: number;
  durationMinutes?: number;
  summary?: any;
  imageDisplayUrl?: string;
  inputId?: number;
}

export interface UpdateTaskParameters extends TaskParameters {
  inputId: number;
}

export interface UserBook
  extends InputIdentifier,
    UserInputIdentifier,
    Taggable,
    Duration,
    ImageOwner {
  userBookId: number;
  userProfileKey: number;
  userProfileId: string;
  bookId: number;
  title: string;
  subtitle: string;
  author: string;
  narrator: string;
  externalId: string;
  bookUrl: string;
  summary: string;
  dateRead: string;
  masteryPoints: number;
  dateCreated: string;
  isImported: boolean;
  authored: boolean;
}

export interface UserBookParameters extends BookParameters {
  dateRead: FlexibleDate;
  authored: boolean;
  platform: string;
}

export interface UserExistingBookParameters {
  bookId: number;
  dateRead: FlexibleDate;
  authored: boolean;
  tags: TagsApi.Tag[];
}

export class UpdateUserBookParameters {
  userBookId: number;
  dateRead: FlexibleDate;
  authored: boolean;
  tags: TagsApi.Tag[];
}

//#endregion

//#region Episode
export interface Episode
  extends InputIdentifier,
    Taggable,
    OrganizationContent,
    Authored,
    Published,
    ImageOwner,
    Language {
  episodeId: number;
  feedName: string;
  feedUrl: string;
  title: string;
  contentUrl: string;
  contentType: string;
  duration?: number;
  summary: string;
  externalId: string;
  userCount?: number;
  organizationId?: number;
  obsolete: boolean;
  dateCreated: string;
  dateModified: string;
  language: string;
  owner: string;
  readonly inputType: 'Episode';
  orgContentMetadata: OrganizationContentMetadata;
  organizationName: string;
  // scenario?: string;
  // legacyPictureUrl?: string;
}

export interface EpisodeParameters extends InputPropertiesParameters {
  title: string;
  summary: string;
  author: string;
  url: string;
  duration?: number;
  publishDate?: string;
  feedName: string;
  feedUrl: string;
  contentUrl: string;
  contentType: string;
}

export interface UpdateEpisodeParameters extends EpisodeParameters {
  inputId: number;
}

export interface UserEpisode
  extends Input,
    InputIdentifier,
    UserInputIdentifier,
    Taggable,
    ImageOwner {
  userEpisodeId: number;
  userProfileKey: number;
  userProfileId: string;
  episodeId: number;
  title: string;
  feedName: string;
  feedUrl: string;
  author: string;
  duration?: number;
  summary: string;
  url: string;
  externalId: string;
  dateCompleted?: string;
  yearCompleted?: number;
  monthCompleted?: number;
  dayCompleted?: number;
  masteryPoints?: number;
  rating?: number;
  authored?: boolean;
  isVerified: boolean;
  dateCreated?: string;
  organizationId?: number;
  organizationName: string;
  hideFromCatalog?: boolean;
}

export interface UserEpisodeParameters extends EpisodeParameters {
  dateCompleted: string;
  authored: boolean;
}

export interface UserExistingEpisodeParameters {
  episodeId: number;
  dateCompleted: string;
  authored: boolean;
  tags: TagsApi.Tag[];
}

export interface UpdateUserEpisodeParameters {
  userEpisodeId: number;
  dateCompleted: string;
  authored: boolean;
  tags: TagsApi.Tag[];
}

//#endregion Episodes

//#region Events

export type EventInvolvementLevel =
  | 'Attendee'
  | 'Panelist'
  | 'Presenter'
  | 'Keynote';

export interface Event
  extends Input,
    InputIdentifier,
    Taggable,
    OrganizationContent,
    ImageOwner,
    Language {
  sessions: InputSession[];
  resourceImageId?: number;
  organizationName: string;
  eventId: number;
  eventUrl: string;
  url: string;
  name: string;
  title: string;
  summary: string;
  externalId: string;
  fileManaged: boolean;
  userCount?: number;
  organizationId?: number;
  obsolete: boolean;
  dateCreated?: string;
  dateModified?: string;
  publishDate?: string;
  owner: string;
  format: string;
  learningMinutes: number;
  groupIds?: GroupIdentifier[]; // In edit mode the organizationContent does not exists
  hideFromCatalog?: boolean; // In edit mode the organizationContent does not exists
  resourceImageData?: ResourceImageJson;
}

export interface EventParameters extends InputPropertiesParameters {
  title: string;
  name: string;
  summary: string;
  eventUrl: string;
  format: string;
  fileManaged?: boolean;
  publishDate: string;
  sessions: InputSession[];
}

export interface UpdateEventParameters extends EventParameters {
  inputId: number;
}
export interface UserEventParameters extends EventParameters {
  length?: number;
  dateAttended?: FlexibleDate;
  involvementLevel?: EventInvolvementLevel;
}

export interface UserEvent
  extends Input,
    InputIdentifier,
    UserInputIdentifier,
    Taggable,
    ImageOwner {
  organizationName: string;
  userEventId: number; // alias for userInputId
  userProfileKey: number;
  userProfileId: string;
  eventId: number;
  name: string;
  eventUrl: string;
  imageUrl: string;
  dateAttended?: FlexibleDate;
  length?: number;
  masteryPoints?: number;
  involvementLevel?: EventInvolvementLevel;
  dateCreated?: string;
  organizationId?: number;
  isImported: boolean;
}

export interface PostInput extends Input {
  details: {
    body: string;
    originalPathwayId: number;
  };
  orgContentMetadata: OrganizationContentMetadata;
  internalUrl: string;
  organizationId: number;
}

export interface CoachInput extends Input {
  coachName?: string;
}

export interface UserExistingEventParameters {
  eventId: number;
  length?: number;
  dateAttended?: FlexibleDate;
  involvementLevel?: EventInvolvementLevel;
  tags: TagsApi.Tag[];
}

export interface UpdateUserEventParameters {
  userEventId: number;
  length?: number;
  dateAttended?: FlexibleDate;
  involvementLevel?: EventInvolvementLevel;
  tags: TagsApi.Tag[];
}
//#endregion Events

//#region Media

//#region Media
export interface MediaInput extends ImageOwner {
  accessible: boolean;
  durationHours?: number;
  durationMinutes?: number;
  entryUrl: string;
  externalId: string;
  fileManaged: boolean;
  format?: string;
  highConfidenceInferredSkills?: string[];
  inputType: MediaInputType; // This is redundant since mediaType gets serialized as a string anyway
  length: number;
  language: string;
  mediaLength?: number; // redundant to length when present
  mediaType: MediaInputType;
  mediumConfidenceInferredSkills?: string[];
  providerCode: string;
  publishDate?: string;
  sourceName: string;
  summary: string;
  tags: TagsApi.Tag[];
  title: string;
  isPreview?: boolean;
}

export interface MediaEntry
  extends Input,
    MediaInput,
    Taggable,
    OrganizationContent,
    ImageOwner,
    Language {
  inputId: number;
  /** @deprecated Use inputId instead */
  mediaId: number;
  inputTypeId: number;
  inputType: MediaInputType;
  entryUrl: string;
  imageUrl: string;
  title: string;
  providerId?: number;
  providerUrl: string;
  providerName: string;
  summary: string;
  externalId: string;
  fileManaged: boolean;
  mediaLength?: number;
  userCount?: number;
  organizationId?: number;
  obsolete: boolean;
  imageSizeId?: number;
  createDateTime: string;
  modifyDateTime: string;
  language: string;
  eTag: string;
  publishDate?: string;
  format: string;
  durationHours: number;
  durationMinutes: number;
  hasBrokenUrl?: boolean;
  owner: string;
  primaryContactResourceId?: number;
  primaryContactResourceType?: string;
  primaryContactName?: string;
}

export interface MediaParameters extends InputPropertiesParameters {
  inputType: MediaInputType;
  title: string;
  providerName?: string;
  summary: string;
  entryUrl: string;
  mediaLength?: number;
  providerCode?: string;
  format?: string;
  fileManaged?: boolean;
  publishDate?: FlexibleDate;
  useQuickCheck: boolean;
  resourceImageId?: number;
}

export interface UserMediaEntry
  extends MediaInput,
    UserInputIdentifier<MediaInputType>,
    Taggable,
    ImageOwner {
  inputId: number;
  organizationName: string;
  /** @deprecated Use userInputId instead */
  userMediaId: number;
  userProfileId: string;
  /** @deprecated Use inputId instead */
  mediaId: number;
  inputTypeId: number;
  title: string;
  integrationId?: number;
  masteryPoints: string;
  dateCreated: string;
  rating?: number;
  organizationId?: number;
  isImported: boolean;
  authored: boolean;
}
export interface UserMediaParameters extends MediaParameters {
  commentary?: string;
  authored: boolean;
}

export interface UserExistingMediaParameters extends UserMediaParameters {
  /** @deprecated Use inputId instead */
  mediaId: number;
  tags: TagsApi.Tag[];
}

export interface UpdateUserMediaParameters extends UserMediaParameters {
  userMediaId: number;
  tags: TagsApi.Tag[];
  imageUrl: string;
}
//#endregion

//#region Position (aka. Experience)

export type PositionLevel = 'Junior' | 'Intermediate' | 'Senior';

export interface PositionParameters {
  title?: string;
  description: string;
  tags: TagsApi.Tag[];
}

export interface UserPosition
  extends InputIdentifier,
    UserInputIdentifier,
    Taggable,
    ImageOwner {
  userPositionId: number;
  userProfileKey: number;
  userProfileId: string;
  positionId: number;
  title: string;
  description: string;
  startDate?: string;
  endDate?: string;
  hoursPerWeek: number;
  isCurrent: boolean;
  level: PositionLevel;
  masteryPoints: number;
  dateCreated: string;
  organizationName: string;
  isImported: boolean;
  readonly inputType: 'Position';
}

/** Synthetic interface to unify common add/update user position props */
interface UserPositionParametersCore {
  title?: string;
  organizationName: string;
  description: string;
  hoursPerWeek: number;
  startDate: FlexibleDate;
  endDate: FlexibleDate;
  isCurrent: boolean;
  level: PositionLevel;
  tags: TagsApi.Tag[];
}

export interface UserPositionParameters
  extends UserPositionParametersCore,
    PositionParameters {}

export interface UpdateUserPositionParameters
  extends UserPositionParametersCore {
  userPositionId: number;
}

export interface InputUpdateResult<
  TJsonData extends JsonObjectOrString = JsonObject,
> {
  accessible: boolean;
  createdBy: number;
  dateCreated: string;
  dateModified: string;
  description: string;
  summary: string;
  details: TJsonData;
  durationDisplay: string;
  durationISO: string;
  durationUnitType: string;
  durationUnits: number;
  fileManaged: boolean;
  imageUrl: string;
  inputId: 7475946;
  inputType: string;
  internalUrl: string;
  obsolete: boolean;
  organizationId: number;
  tags: TagsApi.Tag[];
  title: string;
}

export interface UserExistingPositionParameters
  extends UpdateUserPositionParameters {
  positionId: number;
}

export interface OpportunityUserPositionParameters
  extends UserPositionParameters {
  opportunityId: number;
}
//#endregion

export interface InputSocialCounts {
  commentCount?: number;
  likeCount?: number;
  queuedCount?: number;
  recommendationCount?: number;
  tags?: Array<{ label: string; url: string }>;
  userCount?: number;
  viewCount?: number;
}

export interface InputCreationResult {
  alreadyExists: boolean;
  inputId: number;
  inputType: InputType;
  isDuplicate: boolean;
}

export interface InputStatistics extends InputSocialCounts {
  inputId: number;
  inputType: string;
  topRecommenderProfileKey?: number;
  topRecommender: UserProfileSummary;
  topUser?: InputStatisticsUser;
  topUserProfileKey?: number;
  userCount: number;
  recommendationCount: number;
  commentCount: number;
  likeCount: number;
  dislikeCount: number;
  viewCount: number;
}

export interface InputStatisticsUser {
  isEngaged: boolean;
  name: string;
  picture: string;
  profileUrl: string;
  userProfileKey: number;
}

interface InputCountViewModel {
  inputType: string;
  count: number;
}

interface UserInputStatistics {
  readonly todaysCounts: InputCountViewModel[];
  readonly counts: InputCountViewModel[];
  readonly todaysCount: number;
}

export interface UserSuggestion extends ReferenceOwner {
  userSuggestionId: number;
  source: string;
  suggestionDetails: string;
  sourceDurationText: string;
  sourceSummary: string;
  sourceImageUrl: string;
  suggestionDetailsCollection: Record<string, string>;
  hostname: string;
  contentSource: string;
  sortOrder: number;
  totalSuggestions: number;
  referenceType: string;
  referenceId: number;
  reference: Reference;
  relatedInput: ReferenceOwner;
}
export interface CommentModel {
  id: number;
  comment: string;
  showReadMore: boolean;
  userProfileKey: number;
  profileImage: string;
  name: string;
  userUrl: string;
  dateAdded: string;
  isInappropriate: boolean;
  isOwner: boolean;
  favoritedByUser: boolean;
  favoritedCount: number;
  edited: boolean;
  mentions: MentionItem[];

  // ui only
  isLoadingReplies?: boolean;
  isExpanded?: boolean;
}

export interface ReplyCommentModel extends CommentModel {
  parentId: number;

  // Linter Error
  // These fields are required based on how comment.component.ts had defined it's comment dto
  // Unless replies can have replies, this dto probably has no use for them
  hasShownMoreReplies?: boolean;
  replies?: ReplyCommentModel[];
  remainingReplies?: number;
  replyLimit?: number;
}

export interface CommentFeedItemModel extends CommentModel {
  hasShownMoreReplies: boolean;
  replies: ReplyCommentModel[];
  remainingReplies: number;
  replyLimit: number;

  // Linter Error
  // This DTO doesn't need parentId but the way we're using the typings in the comment.component.ts requires it
  // TODO: Refactor CommentFeedItemModel and ReplyCommentModel to have a comment inheritence that
  // can be used by the comment component?
  parentId?: number;
}

export interface CommentFeedModel {
  objectType: string;
  objectId: number;
  remainingComments: number;
  feed: CommentFeedItemModel[];
  hasMoreItems: boolean;
}

export interface SuggestedInputsViewModel {
  hasMoreItems: boolean;
  suggestions: UserSuggestion[];
  comments: CommentFeedModel[];
  statistics: InputStatistics[];
  enrolledPathways: UserPathwaySummary[];
  showEnrolledPathways: boolean;
}

export interface ExternalResultItem {
  id: string;
  author: string;
  title: string;
  summary: string;
  url: string;
  previewUrl: string;
  imageUrl: string;
  duration?: number;
  publishDate?: string;
}

export interface ItunesRssResultItem extends ExternalResultItem {
  rssAuthor: string;
  rssFeedAuthor: string;
  collectionName: string;
  feedUrl: string;
  trackName: string;
  artistName: string;
  artistViewUrl: string;
  collectionViewUrl: string;
  artworkUrl100: string;
  collectionExplicitness: string;
  primaryGenreName: string;
  genres: string[];
  rssSubTitle: string;
  rssLinks: string[];
  rssFeedLinks: string[];
  categories: string[];
  rssSummary: string;
  rssDescription: string;
  rssDuration: string;
  rssContnetUrl: string;
  rssContentType: string;
  rssPubDate: string;
  rssGuid: string;
  feedName: string;
  publishDate?: string;
}

export interface InputDuration {
  inputId: number;
  durationUnits: number;
  durationUnitType: DurationUnitType;
  durationHours?: number;
  durationMinutes?: number;
}

export interface CourseMetadata {
  accessible: boolean;
}

export interface VideoSource {
  type: string; // codec type
  url: string;
}

export type VideoType =
  | 'degreed'
  | 'youtube'
  | 'vimeo'
  | 'mp4'
  | 'hls'
  | 'vztube'
  | 'other';

export enum LocationType {
  InPerson = 'InPerson',
  Online = 'Online',
  Hybrid = 'Hybrid',
}

interface LiveEventSession {
  // base/shared session info
  startDateTime: string; // UTC
  city?: string;
  country?: string;
  endDateTime?: string; // UTC
  locationAddress?: string; // free form text
  locationUrl?: string;
  registrationUrl?: string;
  timeZoneId?: string; // IANA
  countryCode?: string; // 3 digit
  latitude?: number;
  longitude?: number;
  inputUrl?: string;
  isRegistrationUrlInputUrl?: boolean;
  areStartEndDatesInUtc?: boolean;
  locationType?: LocationType;
}

export interface InputSession extends LiveEventSession, InputIdentifier {
  // for display on live event tiles/cards/modal
  inputSessionId: number;
  locationType: LocationType;
  isLive?: boolean;
  isRegistered?: boolean; // this property does not appear to exist here, but rather on the parent resource alongside isCompelted, isQueued? at least for courses/events.
  isRegistrationAvailable?: boolean;
  language?: string; // mostly used for integrations
  overrideRegistrationUrl?: boolean;
  sessionType?: string; // mostly used for integrations
  startDateFormatted?: string; // FE only
}

export interface SessionParameters extends LiveEventSession {
  // for the add/edit modal
  isRegistrationUrlInputUrl: boolean;
  isRegistrationAvailable: boolean;
  areStartEndDatesInUtc?: boolean;
  countryCode?: string; // 3 digit
  latitude?: number;
  longitude?: number;
}

export interface LanguageInfo {
  englishName: string;
  language: string;
  nativeName: string;
}

// Course Api parameters
export interface UserCourse extends InputPropertiesParameters {
  inputId: number;
  userCourseId?: number;
  userProfileKey?: number;
  userProfileId?: string;
  institutionId: number;
  institutionName: string;
  isAccredited: boolean;
  logoName?: string;
  country: string;
  courseId?: number;
  name: string;
  providerId?: number;
  courseUrl: string;
  courseNumber?: number;
  creditHours?: number;
  units?: number;
  unitType: string;
  levelId?: number;
  levelRank?: number;
  gradeId?: number;
  isUserSpecified?: boolean;
  isVerified?: boolean;
  verificationUrl: string;
  masteryPoints?: number;
  dateCreated?: FlexibleDate;
  organizationName_Secure?: string;
  isImported?: boolean;
  authored: boolean;
  imageUrl: string;
  dateCompleted?: FlexibleDate;
  yearCompleted?: string;
  monthCompleted?: string;
  dayCompleted?: string;
  scenario?: string;
  // below are for content catalog only
  createdBy?: string | number;
  createdByName?: string;
}

export interface CourseParameters extends UserCourse {
  accessible?: boolean;
  comment?: string;
  inputType: string; // TODO: After ngx migration update these to InputType
  dateCompleted: FlexibleDate;
  verificationUrl: string;
  gradeId: number;
  levelId: number;
  authored: boolean;
}

export interface UserCourseParameters extends CourseParameters {
  unitType: string;
  institutionId: number;
  name: string;
  courseNumber: number;
  creditHours: number;
  courseUrl: string;
  units: number;
  description: string;
  continuingEducationUnits?: number;
}

export type UserExistingCourseParameters = UserCourseParameters;

export interface UpdateUserCourseParameters extends UserCourseParameters {
  userInputId: number;
}

export interface CourseEntry extends UserCourse, InputIdentifier, Taggable {
  courseId: number;
  courseUrl: string;
  videoUrl: string;
  name: string;
  imageUrl: string;
  creditHours?: number;
  levelRank?: number;
  isAccredited: boolean;
  institutionId: number;
  institutionName: string;
  institutionUrl: string;
  providerInternalUrl: string;
  providerId?: number;
  description: string;
  externalId: string;
  fileManaged: boolean;
  units?: number;
  unitType: string;
  durationHours: number;
  durationMinutes: number;
  durationUnits?: number;
  durationUnitType: string;
  userCount?: number;
  organizationId?: number;
  organizationName_Secure: string;
  obsolete: boolean;
  language: string;
  publishDate: string;
  owner: string;
  format: string;
  hostedContentMetadata: string;
  sessions: InputSession[];
  scormCourseId?: string;
  scormFileName?: string;
  hostedType?: string;
  externalCompletionOnly?: boolean;
  url?: string;
  hasBrokenUrl?: boolean;
}

// End Course Api parameters

export interface MarketplaceProgram {
  title: string;
  summary: string;
  imageUrl: string;
  url: string;
  resourceType: string;
  resourceId: string;
  marketplaceInputId: string;
  cost: string;
  providerName: string;
  providerId: string;
  providerLogo: string;
  internalUrl: string;
  tags: string;
  programLength: number;
  programLengthUnit: string;
}

// Used in resume review component to display the experience form
export type ExperienceForm = {
  orgName: string;
  hoursPerWeek: number;
  dateRangeForm: {
    startDate: string;
    endDate: string;
  };
  isCurrent: boolean;
  level: PositionLevel;
} & AnyInputApiEntity;
