/* ScrollService uses Angular's ViewportScroller to scroll to elements. It is meant to eventually replace the existing ScrollSvc
   TODO: Expand to support other functionality of Ajs ScrollSvc as other Ajs tempaltes are migrated
*/
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { WindowToken } from '@app/shared/window.token';

@Injectable()
export class ScrollService {
  public sectionElement: HTMLElement;
  public topOffset: [number, number] = [0, 80];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WindowToken) private windowRef: Window,
    private viewportScroller: ViewportScroller,
    private ngZone: NgZone
  ) {}

  /**
   * Check if the given element is visible within a given viewport
   *
   * @param element The element to check
   * @param threshold Threshold to allow when comparing offsets (in px)
   * @param viewPort Viewport to check within for the element (defaults to the window)
   */
  public inViewport(
    element: HTMLElement,
    threshold: number = 0,
    viewPort?: HTMLElement
  ) {
    // Return immediately if element is hidden.
    if (!this.isVisible(element)) {
      return false;
    }

    // Default the viewport to the window
    const viewPortOffset = viewPort
      ? this.getOffset(viewPort)
      : this.getWindowOffset();
    const elementOffset = this.getOffset(element);

    if (elementOffset.top - threshold < viewPortOffset.top) {
      if (elementOffset.bottom + threshold >= viewPortOffset.top) {
        // top edge below the viewPort's top
      } else {
        return false;
      }
    } else if (elementOffset.bottom - threshold > viewPortOffset.bottom) {
      // not (bottom edge above the viewPort's bottom)
      return false;
    }

    return true;
  }

  public scrollToElementById(sectionId: string) {
    // Focus first and then scroll to anchor to prevent default browser scrolling.
    this.sectionElement = this.document.getElementById(sectionId);
    if (this.sectionElement) {
      this.sectionElement.focus();
    }
    this.viewportScroller.setOffset(this.topOffset);
    this.viewportScroller.scrollToAnchor(sectionId);
  }

  /**
   * Scroll to the provided html element after optional delay (in ms)
   * @param element HTMLElement
   * @param delay optional delay in milliseconds
   * @param options optional config (ScrollIntoViewOptions)
   */
  public scrollToElementByReference(
    element: HTMLElement,
    delay?: number,
    options?: ScrollIntoViewOptions
  ): void {
    this.runOutsideAngular(() => {
      const defaultConfig = {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      };
      const config =
        typeof options === 'boolean'
          ? options
          : ({
              ...defaultConfig,
              ...options,
            } as ScrollIntoViewOptions);
      element.scrollIntoView(config);
    }, delay);
  }

  /**
   * Scroll to the top of the page after optional delay (in ms)
   * @param delay optional delay in milliseconds
   * @param offset optional offset from top of page in pixels
   */
  public scrollToPageTop(delay?: number, offset: number = 0): void {
    this.runOutsideAngular(
      () => this.windowRef.scrollTo({ top: offset, behavior: 'smooth' }),
      delay
    );
  }

  /**
   * Copied from the Positioning Service. Calculates the offset for a given element
   */
  private getOffset(element: HTMLElement, round = true): DOMRect {
    const elBcr = element.getBoundingClientRect();
    const viewportOffset = {
      top: window.pageYOffset - document.documentElement.clientTop,
      left: window.pageXOffset - document.documentElement.clientLeft,
    };

    const elOffset = {
      height: elBcr.height || element.offsetHeight,
      width: elBcr.width || element.offsetWidth,
      top: elBcr.top + viewportOffset.top,
      bottom: elBcr.bottom + viewportOffset.top,
      left: elBcr.left + viewportOffset.left,
      right: elBcr.right + viewportOffset.left,
    };

    if (round) {
      elOffset.height = Math.round(elOffset.height);
      elOffset.width = Math.round(elOffset.width);
      elOffset.top = Math.round(elOffset.top);
      elOffset.bottom = Math.round(elOffset.bottom);
      elOffset.left = Math.round(elOffset.left);
      elOffset.right = Math.round(elOffset.right);
    }

    return elOffset as DOMRect;
  }

  /**
   * Calculate the viewport offset for the window
   */
  private getWindowOffset(): DOMRect {
    const doc = this.document;
    const win = this.windowRef;

    // Factor the height of the fixed app header into the height and offset top
    const appHeader = doc.querySelector('.js-app-header') as HTMLElement;
    const appHeaderHeight = appHeader?.offsetHeight ?? 0;

    const width =
      win.innerWidth || doc.documentElement.clientWidth || doc.body.clientWidth;
    const height =
      win.innerHeight ||
      doc.documentElement.clientHeight ||
      doc.body.clientHeight;
    const top = win.pageYOffset - doc.documentElement.clientTop;
    const left = win.pageXOffset - doc.documentElement.clientLeft;

    return {
      width,
      height,
      top: top + appHeaderHeight,
      bottom: top + height,
      left,
      right: left + width,
    } as DOMRect;
  }

  /**
   * Extracted from the jQuery 1.3.2+ implementation of `is(':visible')`
   *
   * @link http://blog.jquery.com/2009/02/20/jquery-1-3-2-released/#visible-hidden-overhauled
   * @link https://github.com/jquery/jquery/blob/main/src/css/hiddenVisibleSelectors.js#L8-L10
   */
  private isVisible(elem?: HTMLElement) {
    return (
      elem &&
      !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
    );
  }

  /**
   * Run outside of angular to avoid triggering change detection
   * @param fn scrolling function
   * @param delay optional delay in milliseconds
   */
  private runOutsideAngular(fn: any, delay: number = 0): void {
    // setTimeout triggers change detection so...
    this.ngZone.runOutsideAngular(() =>
      // Optionaly delay scrolling function to allow ui to update and prevent janky performance
      this.windowRef.setTimeout(() => fn(), delay)
    );
  }
}
