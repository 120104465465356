import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { GroupTileComponent } from '@app/groups/components/group-tile/group-tile.component';
import { GroupVisibilityComponent } from '@app/groups/components/group-visibility/group-visibility.component';
import { GroupEditMemberModalComponent } from '@app/groups/components/group-edit-member-modal/group-edit-member-modal.component';
import { TagsModule } from '@app/tags/tags.module';
import { RouterModule } from '@angular/router';
import { OrgNewGroupSettingsComponent } from './components/org-new-group-settings/org-new-group-settings.component';
import { GroupSettingsComponent } from './components/group-settings/group-settings.component';
import { GroupDeleteComponent } from './components/group-delete/group-delete.component';
import { GroupSecurityComponent } from './components/group-security/group-security.component';
import { DfLabelMarkerModule } from '@lib/fresco';
import { DgxGroupsEditModule } from '@app/shared/components/groups-edit/groups-edit.module';
import { DgxGroupSearchModule } from '@app/shared/components/group-search/groups-search.module';
import { OrderByPipe } from '@app/shared/pipes/order-by.pipe';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';
import { DynamicGroupDefinitionComponent } from './components/dynamic-group-definition/dynamic-group-definition.component';
import { AutomationsSharedModule } from '@app/automations/automations-shared.module';

/**
 * Shared module for Groups Components and Services
 */
@NgModule({
  declarations: [
    GroupEditMemberModalComponent,
    GroupTileComponent,
    GroupVisibilityComponent,
    OrgNewGroupSettingsComponent,
    GroupSettingsComponent,
    GroupDeleteComponent,
    GroupSecurityComponent,
    DynamicGroupDefinitionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TagsModule,
    DfLabelMarkerModule,
    DgxGroupsEditModule,
    DgxGroupSearchModule,
    // standalone components
    OrderByPipe,
    AutofocusDirective,
    AutomationsSharedModule,
  ],
  providers: [],
  exports: [
    GroupEditMemberModalComponent,
    GroupTileComponent,
    GroupVisibilityComponent,
    OrgNewGroupSettingsComponent,
    GroupSettingsComponent,
    GroupDeleteComponent,
    GroupSecurityComponent,
    DgxGroupsEditModule,
    DgxGroupSearchModule,
    DynamicGroupDefinitionComponent,
  ],
})
export class GroupsSharedModule {}
