<form role="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <dgx-modal
    [canCancel]="true"
    [isSubmitDisabled]="!form.valid"
    [submitButtonText]="
      i18n.Opportunities_ArchiveCandidatesModal_SubmitButtonText
    "
    [useDefaultForm]="false"
    bodyClasses="guts-m-h-1"
    headerWrapperClasses="guts-p-h-2"
    (dismiss)="onDismiss()"
  >
    <!-- Modal header -->
    <ng-container class="modal-header">
      {{ modalHeaderText }}
    </ng-container>
    <!-- Modal body -->
    <ng-container class="modal-body">
      <df-form-root
        [form]="form"
        [model]="model"
        [fields]="fields"
      ></df-form-root>
    </ng-container>
  </dgx-modal>
</form>
