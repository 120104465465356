/**
 * True if values are equal or equally nullish. By default, compares to
 * null, but can be passed empty strings in situations where those should
 * also be considered null.
 *
 * @param value
 * @param value2
 */
export const roughlyMatching = (value: any, value2: any, nullish = null) => {
  return (value ?? nullish) === (value2 ?? nullish);
};
