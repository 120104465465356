<dgx-modal
  class="guts-p-t-0"
  [canCancel]="true"
  [isHeaderBorderless]="false"
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-header">
    {{ addResourceText }}
  </ng-container>

  <ng-container class="modal-body">
    <form role="form" class="form-wrap guts-p-full-1" (ngSubmit)="onSubmit()">
      <div class="">
        <label for="searchResources" class="guts-m-b-half font-strong ib">{{
          addFromCatalogText
        }}</label>
        <div class="l_flex rel">
          <input
            id="searchResources"
            autocomplete="off"
            name="searchResources"
            type="text"
            class="input"
            [placeholder]="searchForText"
            [attr.aria-label]="searchForText"
            [(ngModel)]="searchResourceInput"
            [ngbTypeahead]="getResources"
            (selectItem)="selectResource($event.item)"
            [resultTemplate]="searchItemTemplate"
            [inputFormatter]="searchItemFormatter"
            data-dgat="addBundleResourceModal-307"
          />
          <df-spinner
            *ngIf="isSearching"
            spinnerContext="textInput"
          ></df-spinner>
        </div>
      </div>

      <div
        *ngIf="!isSearching && noMatchingResults"
        class="par--small guts-m-t-1"
      >
        {{ noMatchText }}
      </div>

      <div class="guts-m-t-1" *ngIf="selectedSearchResults.length">
        <h2 class="h4 guts-p-b-half border-bottom">
          {{ i18n.TargetResourcesForm_Selected }}
        </h2>
        <ul>
          <li
            *ngFor="let item of selectedSearchResults"
            data-dgat="addBundleResourceModal-bf1"
          >
            <div class="l_flexbar border-bottom">
              <div class="l_flex-grow">
                <dgx-target-resource-list-item
                  [item]="item"
                ></dgx-target-resource-list-item>
              </div>
              <div>
                <button
                  type="button"
                  [ngbTooltip]="i18n.Core_Remove"
                  (click)="removeResource(item)"
                  data-dgat="addBundleResourceModal-630"
                >
                  <df-icon icon="cross" size="medium"></df-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </form>
  </ng-container>

  <ng-container class="modal-footer">
    <button
      df-button
      dfButtonType="primary"
      (click)="onSubmit()"
      [ngClass]="{ is_disabled: !isValid || isSaving }"
      [disabled]="!isValid || isSaving"
      [attr.aria-disabled]="!isValid || isSaving"
      [attr.aria-label]="isSaving ? i18n.Core_Loading : addResourceText"
      data-dgat="add-bundle-resource-modal-ea8"
    >
      <df-spinner-button [isSpinning]="isSaving"
        >{{ addResourceText }}
      </df-spinner-button>
    </button>
  </ng-container>
</dgx-modal>

<!-- template for search results -->
<ng-template #searchItemTemplate let-item="result" let-term="term">
  <dgx-target-resource-list-item [item]="item"></dgx-target-resource-list-item>
</ng-template>
