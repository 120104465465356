<div class="field-group">
  <span class="field-group__input field-group__input--tag">
    <button
      type="button"
      *ngFor="let attribute of attributes"
      class="btn btn-sm bg-color-ebony-a18 color-ebony guts-m-l-quart guts-m-v-half guts-m-r-half guts-p-r-half"
      [attr.aria-label]="
        'dgTagsEdit_RemoveTagFormat' | translate: { tag: attribute }
      "
      (click)="remove(attribute)"
    >
      <span>{{ attribute }}</span>
      <df-icon
        icon="cross"
        size="small"
        class="guts-m-l-quart color-ebony-a61"
      ></df-icon>
    </button>
    <input
      #tagsInput
      autocomplete="off"
      class="field-group__input--full-width"
      name="attributeSelections"
      role="combobox"
      [type]="inputType"
      [(ngModel)]="searchTerm"
      (keypress)="inputKeyPress($event)"
      maxlength="200"
      [attr.aria-labelledby]="a11yLabelledBy"
      [placeholder]="placeholderText"
      [attr.aria-describedby]="'helpText-' + index"
      (blur)="handleBlur()"
    />
  </span>
</div>
<div
  *ngIf="helpText"
  class="micro explanation guts-m-v-half"
  [attr.id]="'helpText-' + index"
>
  {{ helpText }}
</div>
