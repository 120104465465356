<section *ngIf="!!authUser">
  <div
    dgxSticky
    [stickyFullWidth]="true"
    [stickyCustomClass]="'box-shadow bg-color-ebony-a03-hue'"
    [notStickyCustomClass]="'grid__col-12 guts-p-t-2 guts-p-b-0'"
    *ngIf="displayHeader"
  >
    <div
      class="l_flexbar bg-color-ebony-a03 rounded--large m-l_flexbar-block guts-p-full-1"
    >
      <div class="l_flexbar m-l_flexbar-block" *ngIf="tenantOptions">
        <div class="m-guts-m-t-half">
          <dgx-combobox
            class="m-full-width"
            [selectedOption]="selectedOrg?.name"
            (selection)="updateOrg($event)"
            [options]="tenantOptions"
            labelKey="name"
            trackBy="id"
            ariaLabel="{{ i18n.OrgReportingCtrl_FilterByOrg }}"
            placeholder="{{ i18n.OrgReportingCtrl_FilterByOrg }}"
            hasNoResultsText="{{ i18n.OrgReportingCtrl_NoMatchingOrgs }}"
            dgatInput="pathwayInsights-7ca"
          >
          </dgx-combobox>
        </div>
      </div>
      <ng-container *dgxGroupFilterToggle>
        <div class="l_flexbar m-l_flexbar-block" *ngIf="groupOptions">
          <div class="m-guts-m-t-half">
            <dgx-combobox
              class="m-full-width"
              [selectedOption]="groupOptions[0].name"
              (selection)="updateGroupId($event)"
              [options]="groupOptions"
              labelKey="name"
              trackBy="groupId"
              ariaLabel="{{ i18n.OrgReportingCtrl_FilterByGroup }}"
              placeholder="{{ i18n.OrgReportingCtrl_FilterByGroup }}"
              hasNoResultsText="{{ i18n.OrgReportingCtrl_NoMatchingGroups }}"
              dgatInput="pathwayInsights-736"
            >
            </dgx-combobox>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="grid__col-12 guts-p-h-0 guts-p-b-0">
    <dgx-kpi-insights
      [orgId]="orgId"
      [filterOrgId]="selectedOrg?.id"
      [pathwayId]="pathway.id"
      [groupId]="selectedGroupId"
    >
    </dgx-kpi-insights>
    <dgx-activity-insights
      [orgId]="orgId"
      [filterOrgId]="selectedOrg?.id"
      [pathwayId]="pathway.id"
      [groupId]="selectedGroupId"
      class="grid__col-12"
      data-dgat="activity-insights-container"
    ></dgx-activity-insights>
    <div class="l_flex">
      <dgx-pathway-enrollment-source
        [orgId]="orgId"
        [groupId]="selectedGroupId"
        [filterOrgId]="selectedOrg?.id"
        [pathwayId]="pathway.id"
        [pathwayPrivacyLevel]="pathway.privacyLevel"
        class="grid__col-sm-6"
      ></dgx-pathway-enrollment-source>
      <dgx-content-insights
        [orgId]="orgId"
        [filterOrgId]="selectedOrg?.id"
        [pathwayId]="pathway.id"
        [groupId]="selectedGroupId"
        [viewAllUrl]="viewAllContentUrl"
        [loadCount]="6"
        class="grid__col-sm-6"
      ></dgx-content-insights>
    </div>
  </div>
</section>
