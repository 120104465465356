import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'prettyTimeElapsed' })
@Injectable() // TODO: temporarily needed with Ivy. Should be able to remove when released.
// See https://github.com/angular/angular/issues/31990
export class PrettyTimeElapsedPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(
    dateString: string,
    min: 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years',
    minimalFormat = false
  ) {
    const date: any = new Date(dateString);

    const delta = Math.round((+new Date() - date) / 1000);
    const dayDelta = (+new Date() - date) / 1000;

    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    const year = day * 365;

    let limit = 0;
    if (min === 'minutes') {
      limit = 1;
    } else if (min === 'hours') {
      limit = 2;
    } else if (min === 'days') {
      limit = 3;
    } else if (min === 'weeks') {
      limit = 4;
    } else if (min === 'months') {
      limit = 5;
    } else if (min === 'years') {
      limit = 6;
    }

    let fuzzy: string;

    if (delta < minute && limit < 1) {
      fuzzy = this.translate.instant(
        minimalFormat
          ? 'Core_SecondsAgoFormat_Minimal'
          : 'Core_SecondsAgoFormat',
        {
          time: delta,
        }
      );
    } else if (delta < 2 * minute && limit < 2) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_1MinuteAgo_Minimal' : 'Core_1MinuteAgo'
      );
    } else if (delta < hour && limit < 2) {
      fuzzy = this.translate.instant(
        minimalFormat
          ? 'Core_MinutesAgoFormat_Minimal'
          : 'Core_MinutesAgoFormat',
        {
          time: Math.floor(delta / minute),
        }
      );
    } else if (Math.floor(delta / hour) === 1 && limit < 3) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_1HourAgo_Minimal' : 'Core_1HourAgo'
      );
    } else if (delta < day && limit < 3) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_HoursAgoFormat_Minimal' : 'Core_HoursAgoFormat',
        {
          time: Math.floor(delta / hour),
        }
      );
    } else if (delta < day && limit === 3) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_Today_Minimal' : 'Core_Today'
      );
    } else if (dayDelta < day * 2 && limit < 5) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_Yesterday_Minimal' : 'Core_Yesterday'
      );
    } else if (dayDelta < week && limit < 5) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_DaysAgoFormat_Minimal' : 'Core_DaysAgoFormat',
        {
          time: Math.floor(delta / day),
        }
      );
    } else if (dayDelta < week * 2 && limit < 5) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_1WeekAgo_Minimal' : 'Core_1WeekAgo'
      );
    } else if (dayDelta < month && limit < 6) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_ThisMonth_Minimal' : 'Core_ThisMonth'
      );
    } else if (dayDelta < year && limit < 6) {
      const time = Math.floor(delta / month);
      const monthsAgoFormat =
        time > 1 ? 'Core_MonthsAgoFormat' : 'Core_MonthAgoFormat';
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_MonthsAgoFormat_Minimal' : monthsAgoFormat,
        { time }
      );
    } else if (dayDelta < year) {
      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_ThisYear_Minimal' : 'Core_ThisYear'
      );
    } else {
      const time = Math.floor(delta / year);
      const yearsAgoFormat =
        time > 1 ? 'Core_YearsAgoFormat' : 'Core_YearAgoFormat';

      fuzzy = this.translate.instant(
        minimalFormat ? 'Core_YearsAgoFormat_Minimal' : yearsAgoFormat,
        { time }
      );
    }
    return fuzzy;
  }
}
