<dgx-modal
  [canCloseHeader]="true"
  [isHeaderBorderless]="true"
  [isFooterless]="true"
>
  <!-- Modal Header -->
  <ng-container class="modal-header"></ng-container>
  <!-- Modal Body -->
  <ng-container class="modal-body">
    <div class="center-text guts-p-h-5 guts-p-b-5">
      <img
        [src]="'/content/img/onboarding/firstqueue.png' | blobifyUrl"
        static="true"
        class="img-responsive"
      />
      <p
        class="par color-ebony-a61 guts-m-t-4 guts-p-h-2 guts-m-b-2"
        id="dialogDescription"
      >
        {{ 'TipSvc_SavedItemsModalHome' | translate }}
      </p>
      <a
        *ngIf="vanityUrl"
        class="btn btn-primary"
        [href]="savedItemsLink"
        (click)="close()"
        target="{{ isIframe ? '_blank' : '_self' }}"
        data-dgat="firstQueue-9f6"
        >{{ 'TipSvc_ViewSavedItems' | translate }}</a
      >
    </div>
  </ng-container>
</dgx-modal>
