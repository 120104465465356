<div
  class="popover__window popover__window--{{ placement }}"
  [ngClass]="{ 'popover__window--no-arrow': popoverArrowHidden }"
  #popover
  *ngIf="popoverPresent"
  [@openClose]="animationState"
  (@openClose.done)="onAnimationEvent($event)"
  [ngStyle]="{
    left: placementAdjustLeftRem + 'rem',
    top: placementAdjustTopRem + 'rem'
  }"
>
  <div
    #arrow
    class="popover-arrow popover-arrow--window-{{ placement }}"
    *ngIf="!popoverArrowHidden"
  ></div>
  <div class="popover__content">
    <ng-content></ng-content>
  </div>
</div>
