<div
  ngForm
  #sessionForm="ngForm"
  class="border-top border-bottom guts-p-full-2 m-guts-p-full-1"
>
  <fieldset class="l_flexbar">
    <label for="enableSession">
      <span class="h4">{{
        'InputSessionForm_AddSessionDetails' | translate
      }}</span>
    </label>
    <div class="l_flex--align-right">
      <dgx-toggle-switch
        [isChecked]="isEnabled && !isFromIntegrations"
        [disabled]="isFromIntegrations"
        (toggled)="onToggleForm($event)"
        id="enableSession"
      ></dgx-toggle-switch>
    </div>
  </fieldset>
  <div *ngIf="isEditing" aria-live="polite">
    <div *ngIf="!isEnabled" [@fadeInAndOut]>
      <df-local-notification
        class="block guts-m-t-1"
        [type]="NotificationType.warning"
        text="{{ 'InputSessionForm_DeletionAlert' | translate }}"
        size="small"
      ></df-local-notification>
    </div>
  </div>
  <div *ngIf="isFromIntegrations">
    <!-- If there's an external ID, it came from an integration. Session data will still be re-saved here, just not editable -->
    <df-local-notification
      class="block guts-m-t-1"
      [type]="NotificationType.info"
      text="{{ 'InputSessionForm_IntegratedDetailsNotEditable' | translate }}"
      size="small"
    ></df-local-notification>
  </div>
  <div
    *ngIf="isEnabled && !isFromIntegrations"
    class="__expand-area"
    [@dfCollapseExpand]
  >
    <fieldset class="__fieldset guts-m-t-2">
      <!-- Registration -->
      <legend class="h4 font-regular guts-p-b-2">
        {{ 'InputSessionForm_Registration' | translate }}
      </legend>
      <div class="v-middle-children">
        <input
          type="checkbox"
          name="isRegistrationAvailable"
          id="isRegistrationAvailable"
          class="guts-m-r-half"
          [(ngModel)]="session.isRegistrationAvailable"
          (change)="onFormFieldChange($event)"
          data-dgat="input-session-form-partial-b3b"
        />
        <label class="form__label guts-m-b-0" for="isRegistrationAvailable">
          {{ 'InputSessionForm_RegistrationAvailable' | translate }}
        </label>
      </div>
      <div *ngIf="session.isRegistrationAvailable" [@dfCollapseExpand]>
        <div class="guts-p-t-1 guts-p-l-1-half m-guts-p-l-0">
          <div class="v-middle-children">
            <input
              type="radio"
              name="isRegistrationUrlInputUrl"
              id="sameUrl"
              [value]="true"
              [(ngModel)]="session.isRegistrationUrlInputUrl"
              (change)="onFormFieldChange($event)"
              class="guts-m-r-half"
              data-dgat="input-session-form-partial-067"
            />
            <label class="form__label guts-m-b-0" for="sameUrl">
              {{
                'InputSessionForm_SameUrl' | translate: { inputType: inputType }
              }}
            </label>
          </div>
          <div class="v-middle-children">
            <input
              type="radio"
              name="isRegistrationUrlInputUrl"
              id="differentUrl"
              [value]="false"
              [(ngModel)]="session.isRegistrationUrlInputUrl"
              (change)="onFormFieldChange($event)"
              class="guts-m-r-half"
              data-dgat="input-session-form-partial-067"
            />
            <label class="form__label guts-m-b-0" for="differentUrl">
              {{ 'InputSessionForm_DifferentUrl' | translate }}
            </label>
          </div>
          <div *ngIf="!session.isRegistrationUrlInputUrl" [@dfCollapseExpand]>
            <div class="guts-p-t-half guts-p-l-1-half m-guts-p-l-0">
              <label class="form_label" for="registrationUrl">
                {{ 'InputSessionForm_RegistrationUrl' | translate
                }}<df-label-marker></df-label-marker>
              </label>
              <input
                type="url"
                id="registrationUrl"
                name="registrationUrl"
                [(ngModel)]="session.registrationUrl"
                (change)="onFormFieldChange()"
                #registrationUrl="ngModel"
                required
                [pattern]="urlValidatorRegEx"
                data-dgat="input-session-form-partial-c33"
              />
              <dgx-validate-field
                [message]="
                  registrationUrl.errors &&
                  (registrationUrl.dirty || registrationUrl.touched) &&
                  ('Core_ValidUrl' | translate)
                "
              ></dgx-validate-field>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="__fieldset">
      <!-- Location -->
      <legend class="h4 font-regular guts-p-b-2">
        {{ 'Core_Location' | translate }}
      </legend>
      <fieldset>
        <legend class="form_label">
          {{ 'InputSessionForm_LocationType' | translate
          }}<df-label-marker></df-label-marker>
        </legend>
        <div class="guts-p-t-quart v-middle-children">
          <input
            type="checkbox"
            name="isInPerson"
            id="isInPerson"
            [(ngModel)]="isInPerson"
            (change)="onFormFieldChange($event)"
            class="guts-m-r-quart"
            data-dgat="input-session-form-partial-44a"
          />
          <label for="isInPerson" class="par par--small guts-p-r-1-half">
            {{ 'InputSessionForm_InPerson' | translate }}
          </label>
          <input
            type="checkbox"
            name="isOnline"
            id="isOnline"
            [(ngModel)]="isOnline"
            (change)="onFormFieldChange($event)"
            class="guts-m-r-quart"
            data-dgat="input-session-form-partial-002"
          />
          <label for="isOnline" class="par par--small">
            {{ 'InputSessionForm_Online' | translate }}
          </label>
        </div>
        <dgx-validate-field
          [message]="
            sessionForm.errors?.locationType &&
            ('Core_FieldRequired' | translate)
          "
        ></dgx-validate-field>
      </fieldset>
      <div *ngIf="isInPerson" [@dfCollapseExpand]>
        <div class="guts-p-t-1-half">
          <label class="form_label block" for="locationAddress">
            {{ 'InputSessionForm_Address' | translate
            }}<df-label-marker></df-label-marker>
          </label>
          <dgx-address-suggest
            id="locationAddress"
            name="locationAddress"
            ngDefaultControl
            [(ngModel)]="session.locationAddress"
            (change)="onFormFieldChange($event)"
            (select)="onAddressSelect($event)"
            (clear)="clearAddress()"
            [placeholder]="'InputSessionForm_SearchAddress' | translate"
            [required]="true"
            #locationAddress="ngModel"
            dgatInput="input-session-form-partial-e52"
            ariaDescribedby="locationAddressHelp"
          ></dgx-address-suggest>
          <dgx-validate-field
            [message]="
              locationAddress.errors &&
              (locationAddress.dirty || locationAddress.touched) &&
              ('Core_FieldRequired' | translate)
            "
          ></dgx-validate-field>
          <div class="form__help" id="locationAddressHelp">
            {{ 'InputSessionForm_PhysicalLocation' | translate }}
          </div>
        </div>
      </div>
      <div *ngIf="isOnline" [@dfCollapseExpand]>
        <div class="guts-p-t-1-half">
          <label class="form_label" for="locationUrl">
            {{ 'InputSessionForm_LocationUrl' | translate
            }}<df-label-marker></df-label-marker>
          </label>
          <input
            type="url"
            id="locationUrl"
            name="locationUrl"
            [placeholder]="
              'InputSessionForm_EventStreamLinkPlaceholder' | translate
            "
            [(ngModel)]="session.locationUrl"
            (change)="onFormFieldChange($event)"
            #locationUrl="ngModel"
            required
            [pattern]="urlValidatorRegEx"
            data-dgat="input-session-form-partial-c33"
            aria-describedby="streamLinkHelp"
          />
          <div class="form__help" id="streamLinkHelp">
            {{ 'InputSessionForm_EventStreamLinkLabel' | translate }}
          </div>
          <dgx-validate-field
            [message]="
              locationUrl.errors &&
              (locationUrl.dirty || locationUrl.touched) &&
              ('Core_ValidUrl' | translate)
            "
          ></dgx-validate-field>
        </div>
      </div>
    </fieldset>
    <fieldset class="__fieldset guts-p-b-1">
      <!-- Date & Time -->
      <legend class="h4 font-regular guts-p-b-2">
        {{ 'InputSessionForm_DateTime' | translate }}
      </legend>
      <div class="l_flex guts-p-b-1-half">
        <div class="m-guts-p-b-1">
          <label class="form_label block" for="startDate">
            {{ 'InputSessionForm_EventStarts' | translate
            }}<df-label-marker></df-label-marker>
          </label>
          <div class="field-group">
            <span class="field-group__input field-group__input--tag">
              <df-icon
                icon="calendar"
                class="guts-m-r-1 color-ebony-a61"
              ></df-icon>
              <input
                id="startDate"
                name="startDate"
                type="text"
                class="field-group--autowidth"
                [(ngModel)]="sessionStartDate"
                (blur)="onFormFieldChange($event)"
                #startDate="ngModel"
                #startDatePicker="ngbDatepicker"
                autocomplete="off"
                ngbDatepicker
                [firstDayOfWeek]="7"
                (click)="startDatePicker.toggle()"
                placeholder="yyyy-mm-dd"
                required
                data-dgat="input-session-form-partial-3e8"
              />
            </span>
          </div>
          <dgx-validate-field
            [message]="
              startDate.errors &&
              (startDate.dirty || startDate.touched) &&
              ('InputSessionForm_InvalidDate' | translate)
            "
          ></dgx-validate-field>
        </div>
        <div class="guts-p-l-1 m-guts-p-l-0">
          <label class="form_label" for="startTime">
            {{ 'InputSessionForm_StartTime' | translate
            }}<df-label-marker></df-label-marker>
          </label>
          <input
            type="time"
            name="startTime"
            id="startTime"
            [dgxMaxTime]="bounds.maxStartTime"
            [(ngModel)]="sessionStartTime"
            (change)="onFormFieldChange($event)"
            #startTime="ngModel"
            data-dgat="input-session-form-partial-9d1"
          />
          <dgx-validate-field
            [message]="
              startTime.errors &&
              (startTime.dirty || startTime.touched) &&
              ('InputSessionForm_InvalidTime' | translate)
            "
          ></dgx-validate-field>
        </div>
      </div>
      <div class="l_flex guts-p-b-1-half">
        <div class="m-guts-p-b-1">
          <label class="form_label block" for="endDate">
            {{ 'InputSessionForm_EventEnds' | translate
            }}<df-label-marker></df-label-marker>
          </label>
          <div class="field-group">
            <span class="field-group__input field-group__input--tag">
              <df-icon
                icon="calendar"
                class="guts-m-r-1 color-ebony-a61"
              ></df-icon>
              <input
                id="endDate"
                name="endDate"
                type="text"
                class="field-group--autowidth"
                [(ngModel)]="sessionEndDate"
                (blur)="onFormFieldChange($event)"
                #endDate="ngModel"
                #endDatePicker="ngbDatepicker"
                autocomplete="off"
                ngbDatepicker
                [firstDayOfWeek]="7"
                [minDate]="minEndDate"
                (click)="endDatePicker.toggle()"
                placeholder="yyyy-mm-dd"
                required
                data-dgat="input-session-form-partial-724"
              />
            </span>
          </div>
          <dgx-validate-field
            [message]="
              endDate.errors &&
              (endDate.dirty || endDate.touched) &&
              ('InputSessionForm_InvalidDate' | translate)
            "
          ></dgx-validate-field>
        </div>
        <div class="guts-p-l-1 m-guts-p-l-0">
          <label class="form_label block" for="endTime">
            {{ 'InputSessionForm_EndTime' | translate
            }}<df-label-marker></df-label-marker>
          </label>
          <input
            type="time"
            name="endTime"
            id="endTime"
            [dgxMinTime]="bounds.minEndTime"
            [(ngModel)]="sessionEndTime"
            (change)="onFormFieldChange($event)"
            #endTime="ngModel"
            data-dgat="input-session-form-partial-82d"
          />
          <dgx-validate-field
            [message]="
              endTime.errors &&
              (endTime.dirty || endTime.touched) &&
              ('InputSessionForm_InvalidTime' | translate)
            "
          ></dgx-validate-field>
        </div>
      </div>
      <div>
        <label class="form_label block" for="timeZone">
          {{ 'InputSessionForm_EventTimeZone' | translate
          }}<df-label-marker></df-label-marker>
        </label>
        <dgx-time-zone-picker
          id="timeZone"
          name="timeZone"
          ngDefaultControl
          [(ngModel)]="session.timeZoneId"
          (select)="onFormFieldChange()"
          [placeholder]="'Core_Select' | translate"
          #timeZone="ngModel"
          [required]="true"
          dgatInput="input-session-form-partial-ac3"
        ></dgx-time-zone-picker>
        <dgx-validate-field
          [message]="
            timeZone.errors &&
            (timeZone.dirty || timeZone.touched) &&
            ('Core_FieldRequired' | translate)
          "
        ></dgx-validate-field>
      </div>
    </fieldset>
  </div>
</div>
