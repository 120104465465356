import { SettingMessageEditModalComponent } from './../components/setting-message-edit-modal/setting-message-edit-modal.component';
import { ModalService, ModalOptions } from '@app/shared/services/modal.service';
import { SimpleModalInputBindings } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { SettingsPermission } from './orgs.model';
import { Injectable, NgZone } from '@angular/core';
import { OrgSetting, OrgSettings } from '@app/orgs/services/orgs.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { OrganizationLinksConfig, SettingsRole } from './orgs.model';
import { ConfigurationValue } from '../configuration-value';
import { OrganizationSetting } from './org-settings.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrgSettingsService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public isNotFound$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private tracker: TrackerService,
    private translate: TranslateService,
    private modalService: ModalService,
    private notifier: NotifierService,
    private NgZone: NgZone,
    private http: NgxHttpClient
  ) {}

  public getGroupDescription(key: string) {
    return this.translate.instant(`OrgSettings_${key}_GroupDesc`);
  }

  public getGroupName(key: string) {
    return this.translate.instant(`OrgSettings_${key}_GroupDisplayName`);
  }

  public isGroupedSetting(
    configurationCategory: string,
    settings: OrgSetting[]
  ) {
    return configurationCategory !== settings[0].name;
  }

  public getOrgSettings(
    organizationId: number,
    visibility: ConfigurationValue
  ): Observable<OrgSetting[]> {
    return this.http
      .get('/organizations/getsettings', {
        params: { organizationId, visibility },
      })
      .pipe(
        map((settingsResponse: OrgSettings) =>
          settingsResponse.settings.map((setting) => ({
            ...setting,
            configurationCategory:
              setting.configurationCategory || setting.name,
          }))
        )
      );
  }

  /**
   * Get organization setting
   *
   * @param organizationId
   * @param settingId
   */
  public getSetting(
    organizationId: number,
    settingId: OrganizationSetting
  ): Observable<OrgSetting> {
    return this.http.get('/organizations/getsetting', {
      params: { organizationId, settingId },
    });
  }

  /**
   * Set organization setting
   *
   * @param orgId
   * @param settingName
   */
  public setSetting(
    orgId: number,
    settingName: OrganizationSetting
  ): Observable<void> {
    return this.http.post('/organizations/setting', {
      orgId,
      settingName,
    });
  }

  /**
   * Get organization privacy disclaimer
   *
   * @param orgId
   * @param settingName
   */
  public getDataPrivacyDisclaimer(
    orgId: number,
    settingName: OrganizationSetting
  ): Observable<string> {
    return this.http.get('/organizations/disclaimer', {
      params: {
        orgId,
        settingName,
      },
    });
  }

  public getOrganizationLinks(
    organizationId: number
  ): Observable<OrganizationLinksConfig | any> {
    return this.http
      .get('/organizations/getorganizationlinks', {
        params: {
          organizationId,
          v: 'ngx',
        },
      })
      .pipe(
        catchError(() =>
          of({
            links: [],
            linkSectionTitle: '',
          })
        )
      );
  }

  public toggleSetting(
    orgId: number,
    setting: OrgSetting,
    configurationValue: ConfigurationValue
  ): Observable<any> {
    return this.updateSettings(orgId, [setting], configurationValue).pipe(
      tap((response) => {
        // we had to use NgZone here because there was a bug in IE where we weren't showing the toast until after a click had happened. This is a hacky, albeit temporary, solution.
        // the dgSettingTile component ultimately should be responsible for calling the api to update the setting, however due to the channel implementation of having a save footer,
        // the parent component handles this.
        this.NgZone.run(() => {
          this.tracker.trackEventData({
            action: 'Org Setting Changed',
            properties: {
              SettingName: setting.name,
            },
          });
          const i18nString = setting.enabled
            ? 'OrgSettings_ToggleOn'
            : 'OrgSettings_ToggleOff';
          this.notifier.showSuccess(
            this.translate.instant(i18nString, {
              settingName: setting.name,
            })
          );
        });
      })
    );
  }

  public getSettingNameTranslated(setting: OrgSetting) {
    const i18nKey = `OrgSettings_${setting.id}_Name`;
    const result = this.translate.instant(i18nKey);
    const settingName = result === i18nKey ? setting.name : result;
    return settingName;
  }

  public getSettingDescriptionTranslated(setting: OrgSetting) {
    const i18nKey = `OrgSettings_${setting.id}_Desc`;
    const result = this.translate.instant(i18nKey);
    const settingDescription =
      result === i18nKey ? setting.description : result;
    return settingDescription;
  }

  public getFieldLabelTranslated(key: string) {
    const i18nKey = `OrgSettings_${key}_Label`;
    return this.translate.instant(i18nKey);
  }

  public getFieldPlaceholderTranslated(setting: OrgSetting) {
    const i18nKey = `OrgSettings_${setting.id}_Placeholder`;
    const result = this.translate.instant(i18nKey);
    return result !== i18nKey ? result : '';
  }

  public getRoles(
    orgId: number,
    isChannel: boolean = false,
    term?: string
  ): Observable<SettingsRole[]> {
    const api = isChannel ? '/channel/tenantRoles' : '/organizations/roles';
    return this.http
      .get<SettingsRole[]>(api, {
        params: { id: orgId },
      })
      .pipe(
        map((roles) => {
          // NOTE: roles sorted on the API response here, rather than in view
          if (Array.isArray(roles) && roles.length) {
            roles.sort((a, b) => a.sortOrder - b.sortOrder);
            if (term) {
              roles = roles.filter((role) =>
                role.name.toLowerCase().includes(term.toLowerCase())
              );
            }
          }

          return roles;
        })
      );
  }

  public updateRoles(orgId: number, roles: SettingsRole[]): Observable<void> {
    // Only send the assigned permissions for each role
    const assignedRoles = roles.map((role) => {
      const permissions = role.permissions.filter((p) => {
        return p.assigned;
      });

      return { ...role, permissions };
    });

    return this.http
      .put<void>('/organizations/updateroles', {
        organizationId: orgId,
        roles: assignedRoles,
      })
      .pipe(
        tap(() => {
          this.tracker.trackEventData({
            action: 'Org Permissions Updated',
            properties: {
              OrganizationId: orgId,
            },
          });
        }),
        catchError((error: Error) =>
          throwError(
            new DgError(this.translate.instant('OrgSvc_OrgError'), error)
          )
        )
      );
  }

  public getAvailablePermissions(
    orgId: number
  ): Observable<SettingsPermission[]> {
    return this.http.get<SettingsPermission[]>(
      '/organizations/availablePermissions',
      {
        params: {
          id: orgId,
        },
      }
    );
  }

  /**
   * Opens modal to confirm with user they would like to reset the privacy acceptance message with all users belonging to their organization.
   *
   * @param orgId
   */
  public showResetPrivacyAcceptanceModal(orgId: number): Observable<void> {
    const inputs: SimpleModalInputBindings = {
      bodyText: this.translate.instant(
        'OrgSettings_ResetPrivacyAcceptanceWarning'
      ),
      headerText: this.translate.instant(
        'OrgSettings_ResetPrivacyAcceptanceHeader'
      ),
      submitButtonText: this.translate.instant('OrgSettings_Confirmation'),
      canCancel: true,
    };
    return this.modalService.show(SimpleModalComponent, { inputs }).pipe(
      switchMap(() => this.resetPrivacyAcceptance(orgId)),
      catchError((error: Error) =>
        throwError(
          new DgError(
            this.translate.instant('OrgSvc_ResetPrivacyAcceptanceError'),
            error
          )
        )
      )
    );
  }

  public updateSettings(
    orgId: number,
    settings: OrgSetting[],
    configurationValue: ConfigurationValue
  ): Observable<unknown> {
    return this.http
      .post('/organizations/UpdateSettings', {
        OrganizationId: orgId,
        Settings: settings,
        ConfigurationVisibility: configurationValue,
      })
      .pipe(
        tap(() => {
          this.notifier.showSuccess(
            this.translate.instant('Core_SettingsSaved')
          );
          let trackData = [];
          for (const setting of settings) {
            trackData = [
              ...trackData,
              {
                action: 'Org Setting Changed',
                properties: {
                  SettingName: setting.name,
                },
              },
            ];
          }
          this.tracker.trackBatchEvents(trackData);
        })
      );
  }

  public showMessageEditModal(
    orgId: number,
    setting: OrgSetting,
    isDefault: boolean,
    defaultMessage: string,
    message: string,
    configurationValue: ConfigurationValue
  ): Observable<unknown> {
    const settingNameTranslation = this.getSettingNameTranslated(setting);
    const modalOptions: ModalOptions = {
      errorOnDismiss: true,
      windowClass: 'large',
      inputs: {
        orgId,
        setting,
        isDefault,
        defaultMessage,
        message,
        settingNameTranslation,
      },
    };
    return this.modalService
      .show(SettingMessageEditModalComponent, modalOptions)
      .pipe(
        switchMap((updatedSetting: OrgSetting) => {
          if (updatedSetting) {
            return this.updateSettings(
              orgId,
              [updatedSetting],
              configurationValue
            ).pipe(
              switchMap(() => {
                return of(updatedSetting);
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }

  /**
   * Makes api call to reset privacy acceptance.  Made private because this should only be done after the user has confirmed they would like to do this.
   */
  private resetPrivacyAcceptance(orgId: number): Observable<void> {
    return this.http
      .post<void>('/organizations/ResetPrivacyAcceptance', {
        OrgId: orgId,
      })
      .pipe(
        tap(() => {
          this.notifier.showSuccess(
            this.translate.instant('OrgSettings_ResetPrivacyAcceptanceSuccess')
          );
          this.tracker.trackEventData({
            action: 'Org Data Privacy Acceptance Reset',
          });
        })
      );
  }
}
