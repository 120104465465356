<ul class="par par--light l_flexbar m-l_flexbar-block rating-button-meta">
  <li class="guts-p-r-1 v-middle-children" *ngIf="options && options.duration">
    <df-icon icon="clock"></df-icon>
    <span class="icon-text par--small font-medium duration">{{
      options.duration | dgxPrettyDuration
    }}</span>
  </li>
  <li
    class="guts-p-r-1 v-middle-children"
    *ngIf="options && options.cost && options.cost > 0"
  >
    <df-icon icon="coins"></df-icon>
    <span
      class="icon-text par--small font-medium cost"
      [innerHTML]="options.cost | dgxCurrency: 2:true"
    ></span>
  </li>
  <li class="guts-p-r-1 v-middle-children" *ngIf="options && options.date">
    <df-icon icon="calendar"></df-icon>
    <span class="icon-text par--small font-medium date">{{
      options.date | date: 'shortDate'
    }}</span>
  </li>
  <li
    class="guts-p-r-1 v-middle-children"
    *ngIf="options && options.visibility"
  >
    <df-icon icon="eye-open"></df-icon>
    <span class="icon-text par--small font-medium visibility">{{
      options.visibility
    }}</span>
  </li>
</ul>
