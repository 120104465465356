import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafePipe } from '@app/shared/pipes/safe.pipe';
import { ButtonType } from '@app/shared/temporary-types';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  NotificationType,
} from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-delete-multi-confirmation-modal',
  templateUrl: './delete-multi-confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteMultiConfirmationModalComponent implements OnInit {
  /**
   * e.g., `Delete <Types>`
   *
   * (Do *not* genericize, different types have different genders in gendered languages and the whole
   * sentence must be translated at once.)
   */
  @Input() public modalHeaderTitle = '';
  /** Can be passed in to prevent submissions until true. */
  @Input() public isSubmitDisabled = false;
  /** Dgat for the default submit button. */
  @Input() public submitButtonDgat? = 'delete-multi-confirmation-modal-c68';
  /** Translated text to be read aloud by the submit button, giving further context to the button's action. *Default undefined.* */
  @Input() public submitButtonAriaLabel: string = undefined;
  /**
   * e.g., `Deleting opportunities will remove them from all search results. Are you sure you want to
   * delete them?`
   */
  @Input() public deleteConfirmation = '';
  /**
   * e.g., `You're about to delete the following <types>.`
   *
   * (Do *not* genericize, different types have different genders in gendered languages and the whole
   * sentence must be translated at once.)
   */
  @Input() public deleteNoticeSpecific = '';
  /**
   * e.g., `This action will be permanent and cannot be undone:`
   */
  @Input() public deleteNoticeGeneric = '';
  /**
   * e.g., `These external opportunities cannot be deleted:`
   */
  @Input() public deleteNoticeUndeletable = '';
  /** Array of item *IDs* that can be deleted. */
  @Input() public itemIds: any[] = [];
  /** Array of item titles that can be deleted. */
  @Input() public items: string[] = [];
  /** (Optional) Array of item titles that can NOT be deleted. */
  @Input() public undeletableItems: string[] = [];

  public i18n = this.translateService.instant([
    'Core_Next', // Next
    'Core_Delete', // Delete
  ]);
  public deletePhrase = 'DELETE';
  public fields: DfFormFieldConfig[] = [];
  public form: FormGroup = new FormGroup({});
  public model = {
    deleteConfirm: '',
  };
  public showDeleteForm = false;
  public submitButtonText = this.i18n.Core_Next;
  public submitButtonType?: ButtonType = 'primary';
  public notificationType = NotificationType.info;
  public undeletableHtml: string = '';

  public get disableSubmit(): boolean {
    return (
      this.isSubmitDisabled ||
      (this.showDeleteForm && this.model.deleteConfirm !== this.deletePhrase)
    );
  }

  constructor(
    private activeModal: NgbActiveModal,
    private builder: DfFormFieldBuilder,
    private safePipe: SafePipe,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    // Bail if no undeletable items.
    if (!this.undeletableItems.length) {
      return;
    }
    // Begin undeletable HTML block for our DfLocalNotification component.
    // (This is necessary because the string is passed to innerHTML, but as
    // a string, not as NgContent.)
    let undeletableHtml = `
      <p class="font-semibold guts-p-h-0">
        ${this.deleteNoticeUndeletable}
      </p>
      <ul class="bullets font-medium guts-p-l-1-half par--xsmall">
    `;
    // Loop through our undeletable items
    let isLast = false;
    for (let i = 0; i < this.undeletableItems.length; ++i) {
      isLast = i === this.undeletableItems.length - 1;
      undeletableHtml += `
        <li
          ${isLast ? 'class="guts-m-b-half"' : ''}
          data-dgat="delete-multi-confirmation-modal-71a"
        >
          ${this.undeletableItems[i]}
         </li>
      `;
    }
    // Complete and mark as safe.
    this.undeletableHtml = this.safePipe.transform(
      undeletableHtml + '</ul>',
      'html'
    ) as string;
  }

  public onNext(): void {
    // Configure delete form
    this.fields = [
      this.builder
        // Manually *labeling* this as required, but without the other functionality
        .optionalTextInput(
          'deleteConfirm',
          this.translateService.instant('TargetCtrl_DeleteInstructions', {
            delete: this.deletePhrase,
          }) + '*'
        )
        .updatedOn('change')
        .build(),
    ];
    // Show delete form
    this.showDeleteForm = true;
    // Update submit button text/style
    this.submitButtonText = this.i18n.Core_Delete;
    this.submitButtonType = 'destructive';
  }

  public onSubmit(): void {
    // Prevent submissions entirely on a given condition
    if (this.isSubmitDisabled) {
      return;
    }
    // Close modal, continuing the delete flow.
    this.activeModal.close(this.itemIds);
  }
}
