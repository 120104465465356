import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'dgx-user-count-warning-message',
  templateUrl: './user-count-warning-message.component.html',
  styleUrls: ['./user-count-warning-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCountWarningMessageComponent implements OnInit {
  @Input() public warningMessage: string;

  @HostBinding('attr.data-dgat') public dataDgat = 'user-count-warning-message';

  constructor() {}

  public ngOnInit(): void {}
}
