<div class="guts-m-h-1-half guts-m-b-2 guts-p-t-2 m-guts-m-b-1 m-guts-m-h-1">
  <dgx-data-bulk-actions
    *ngIf="showBulkActions"
    class="full-width m-guts-m-h-1"
    [numItemsForAction]="selectedGroups?.length || 0"
    (bulkActionsCancelled)="cancelBulkActions()"
  >
    <dgx-data-bulk-action-button
      actionIcon="trash"
      [displayText]="'Core_Delete' | translate"
      (buttonAction)="showBulkDeleteModal()"
      dgatInput="org-groups-aed"
    ></dgx-data-bulk-action-button>
  </dgx-data-bulk-actions>
  <div
    *ngIf="!showBulkActions"
    class="l_flexbar l_flex-justify m-l_flexbar-col m-l_flexbar-block full-width"
  >
    <div class="guts-p-r-1 l_flex m-guts-p-r-0">
      <dgx-data-search
        [placeholder]="'OrgGroupsCtrl_SearchforGroup' | translate"
        [searchTerm]="searchTerm"
        type="short"
        (doSearch)="updateQuery($event)"
      ></dgx-data-search>
      <dgx-data-filters
        *ngIf="filters?.length > 0 && dynamicGroupsEnabled"
        [filters]="filters"
        class="guts-p-l-1"
        (filtersChange)="updateFilters($event)"
      ></dgx-data-filters>
    </div>
    <div class="l_flexbar gap-full-half m-l_flexbar-col m-gap-full-1">
      <button
        (click)="addGroup($event)"
        df-button
        dfButtonType="primary"
        data-dgat="orgGroups-938"
      >
        <div class="l_flexbar gap-full-half">
          <df-icon icon="plus"></df-icon>
          <div>{{ 'OrgGroupsCtrl_CreateAGroup' | translate }}</div>
        </div>
      </button>
      <dgx-ftp-upload-link
        class="guts-m-l-0"
        *ngIf="canUploadGroups"
        buttonText="{{ 'OrgGroupsCtrl_UploadGroups' | translate }}"
        [fileUploadType]="fileType"
        icon="arrow-up-tray"
        [orgId]="orgId"
      ></dgx-ftp-upload-link>
    </div>
  </div>
</div>
