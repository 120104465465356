import { Injectable } from '@angular/core';

import { DfFormFieldConfig } from '@lib/fresco';

import { RenderMode } from '../../user-input.model';
import { MediaFormModel } from '../../media-modal/media-form.model';
import { FormRenderManager } from '@app/shared/patterns/render-manager/form-render-manager';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { DefaultInitialVideoFormRenderer } from './default-initial-video-form.renderer';
import { DefaultFullVideoFormRenderer } from './default-full-video-form.renderer';
import { ContentCatalogInitialVideoFormRenderer } from './content-catalog-initial-video-form.renderer';
import { ContentCatalogFullVideoFormRenderer } from './content-catalog-full-video-form.renderer';
import { ProfileEditVideoFormRenderer } from './profile-edit-video-form.renderer';

/** Top-level form field configuration manager for video form.
 */
@Injectable({ providedIn: 'any' })
export class VideoFormRenderManager implements FormRenderManager {
  constructor(
    private defaultInitialRenderer: DefaultInitialVideoFormRenderer,
    private defaultFullRenderer: DefaultFullVideoFormRenderer,
    private profileEditRenderer: ProfileEditVideoFormRenderer,
    private contentCatalogInitialRenderer: ContentCatalogInitialVideoFormRenderer,
    private contentCatalogFullRenderer: ContentCatalogFullVideoFormRenderer
  ) {}

  public render(context: RendererContext<MediaFormModel>): DfFormFieldConfig[] {
    const state = context.state();
    const isEditing = context.inputContext.isEditing;
    let renderer: FormRenderer = this.defaultInitialRenderer;

    const hasMedia =
      isEditing || state.isMediaParsed || state.uploadedContentDetails;

    if (
      context.inputContext.renderMode === RenderMode.ContentCatalog ||
      (context.inputContext.renderMode === RenderMode.Pathways && isEditing)
    ) {
      // When editing the item from pathways, we're actually editing the content catalog internal item
      renderer = hasMedia
        ? this.contentCatalogFullRenderer
        : this.contentCatalogInitialRenderer;
    } else if (
      context.inputContext.isEditing &&
      context.inputContext.renderMode === RenderMode.UserProfile
    ) {
      renderer = this.profileEditRenderer;
    } else {
      renderer = hasMedia
        ? this.defaultFullRenderer
        : this.defaultInitialRenderer;
    }

    return renderer.render(context);
  }
}
