import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
} from '@angular/forms';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CourseCatalogFacade } from '@app/user-content/user-input-v2/inputs/course/services/catalog/course-catalog.facade.service';
import { Observable } from 'rxjs';
import { UserGroupListService } from '@app/shared/services/content/user-group-list.service';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';

// misc
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { CourseModel } from '@app/user-content/user-input-v2/inputs/course/course.model';
import { InputContext } from '@app/user-content/user-input-v2/input.model';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import {
  maxFifteenSkillsValidator,
  atLeastOneRequiredValidator,
  contentOwnerIdValidator,
} from '@app/user-content/user-input-v2/utils/validators';

import {
  validateEntryUrlError,
  hasBrokenUrlValidator,
} from '@app/user-content/user-input-v2/utils/catalog-form-utils';

import {
  onFormControlUpdate,
  markFormAsTouched,
  showError,
} from '@app/shared/utils/angular-form-field-helpers';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnyRecommendee } from '@app/recommendations/recommendations.model';
import { handleCourseFocusOnSubmit } from '@app/user-content/user-input-v2/inputs/course/services/course.utils';
import { LocalityViewModel } from '@app/shared/components/address-suggest/address-suggest.service';

@Component({
  selector: 'dgx-course-catalog',
  templateUrl: './course-catalog.component.html',
  // see ngx-app\src\styles\components\_form-wrapper.scss for style
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseCatalogComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public context: InputContext;
  @ViewChild('courseUrl')
  public courseUrlInput: ElementRef<HTMLElement>;

  public i18n = this.translate.instant([
    'Core_Description',
    'Core_GeneralErrorMessage',
    'Core_MoreInfo',
    'Core_Next',
    'Core_Or',
    'Core_Required',
    'Core_Skills',
    'Core_Title',
    'CourseFormCtrl_AddCourse',
    'CourseFormCtrl_CourseUrl',
    'CourseFormCtrl_CourseUrlAria',
    'CourseFormCtrl_EditCourse',
    'CourseFormCtrl_SaveCourse',
    'CourseFormCtrl_CourseFromScormFile',
    'CourseFormCtrl_CourseFromScormFile_MetadataMissingMessage',
    'CourseFormCtrl_UrlOrScormRequired',
    'dgContentHosting_DragAndDrop',
    'dgFlexRow_EditSectionTitlePlaceholder',
    'dgOrgInternalContent_SkillsMaxError',
    'dgOrgInternalContentForm_ContentOwnerTooltip',
    'dgOrgInternalContentForm_ContentOwner',
    'dgOrgInternalContentForm_ContentOwnerPlaceholder',
    'dgOrgInternalContentForm_ContentOwnerRequired',
    'dgOrgInternalContent_SkillsTooltipText',
    'dgOrgInternalContent_SkillsPlaceholderText',
    'dgOrgInternalContentForm_CourseDescriptionPlaceholder',
    'MediaFormCtrl_UrlRequired',
    'MediaFormCtrl_TitleRequired',
    'MediaFormCtrl_ValidContentOwnerRequired',
  ]);
  public vm$: Observable<CourseModel>;
  public courseCatalogForm: FormGroup;
  public heading: string;
  public showExpandedForm: boolean = false;

  public get showURLInput() {
    return (
      this.courseCatalogForm.get('courseUrl') &&
      !this.courseCatalogForm.get('contentUploader')?.value
    );
  }

  constructor(
    public userGroupListService: UserGroupListService,
    private translate: TranslateService,
    private facade: CourseCatalogFacade,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private userInputsService: UserInputsService
  ) {
    super();
    this.vm$ = this.facade.viewModel$;
  }

  public async ngOnInit(): Promise<void> {
    this.facade.initializeViewModel(this.context);

    this.heading = this.facade.snapshot.inputContext.isEditing
      ? this.i18n.CourseFormCtrl_EditCourse
      : this.i18n.CourseFormCtrl_AddCourse;
    if (this.facade.snapshot.inputContext.isEditing) {
      await this.facade.initializeEdit();
      this.initializeExpandedEditForm();
      return;
    }
    this.initializeForm();
  }

  /**
   * When the URL has duplicates we display a message with the option to view the duplicates in a modal
   * Open the view duplicates modal
   */
  public openViewDuplicates(): void {
    this.facade.viewDuplicates();
  }

  /**
   * On the initial catalog form page if the
   * @returns
   */
  public validateEntryUrlError(): boolean {
    return validateEntryUrlError(
      this.translate,
      this.facade.snapshot.isInitialForm,
      this.courseCatalogForm.get('courseUrl'),
      this.courseCatalogForm.hasError('atLeastOneRequired'),
      this.facade.snapshot.hasBrokenUrl,
      this.facade.snapshot.courseUrl
    );
  }

  /**
   * Calculate the correct error message to display
   *
   * @returns string translation for URL error message to be shown
   */
  public get getUrlErrorMessage(): string {
    const shouldShowContentUploader =
      !!this.facade.snapshot.shouldShowContentUploader;
    if (
      this.courseCatalogForm.hasError('atLeastOneRequired') &&
      shouldShowContentUploader
    ) {
      return this.i18n.CourseFormCtrl_UrlOrScormRequired;
    }
    const hasBrokenUrl = hasBrokenUrlValidator(
      this.translate,
      this.facade.snapshot.hasBrokenUrl,
      this.facade.snapshot.courseUrl,
      this.courseCatalogForm.get('courseUrl').value
    );
    if (!!hasBrokenUrl) {
      return hasBrokenUrl.urlBrokenValidation;
    }
    return this.i18n.MediaFormCtrl_UrlRequired;
  }

  public get scormErrorMessage(): string {
    if (this.facade.snapshot.scormInfo?.scormHasError) {
      // There's an error, but it's handled by the upload-section component.
      if (
        !this.courseCatalogForm.get('contentUploader').value.fileName &&
        !this.facade.snapshot.scormInfo?.showOurScormErrorMessage
      ) {
        return '';
      }
      // Otherwise, display our custom error message.
      return this.facade.snapshot.errorMessages.scormUploadErrorMessage;
    }

    // File required error (name misleading, but set by upload-section).
    if (showError(this.courseCatalogForm, 'contentUploader', 'touched')) {
      return this.facade.snapshot.errorMessages.invalidFileType;
    }

    return '';
  }

  public async onNext(form: FormGroupDirective) {
    markFormAsTouched(this.courseCatalogForm);
    if (this.courseCatalogForm.invalid) {
      return;
    }

    if (
      !!this.facade.snapshot.scormInfo &&
      !!this.courseCatalogForm.get('contentUploader').value
    ) {
      this.courseCatalogForm.patchValue({
        courseUrl: '',
      });
      await this.facade.onNext(null);
    } else {
      await this.onCourseUrlNext();
    }
    await this.onCourseUrlNext();

    // reset the submitted state on the form
    form.resetForm(this.courseCatalogForm.value);

    this.loadExpandedForm();
  }

  public async onSubmit() {
    // If scromError, empty out form value so that *it* will have an error.
    if (this.facade.snapshot.scormInfo?.scormHasError) {
      this.courseCatalogForm.patchValue({ contentUploader: '' });
      this.courseCatalogForm.updateValueAndValidity();
    }

    markFormAsTouched(this.courseCatalogForm);
    if (this.courseCatalogForm.invalid) {
      handleCourseFocusOnSubmit(
        this.courseCatalogForm.controls,
        this.facade.snapshot.isSessionDetailsToggledOn
      );
      this.userInputsService.markChildFormsAsTouched();

      return;
    }
    const courseUrlValue = this.courseCatalogForm.get('courseUrl')?.value;
    if (!!courseUrlValue) {
      await this.facade.fetchUrlDuplicates(courseUrlValue);
      if (this.facade.snapshot.duplicateCount > 0) {
        this.courseCatalogForm.get('courseUrl').markAsTouched();
        return;
      }
    }
    await this.facade.onSubmit(this.courseCatalogForm);
    this.activeModal.close(this.facade.snapshot);
  }

  /****************************************************
   * Event actions from UI
   ****************************************************/

  public onFormControlUpdate(field: string, value: any): void {
    onFormControlUpdate(this.courseCatalogForm, field, value);
  }

  public onContentOwnerChange(value: AnyRecommendee): void {
    this.onFormControlUpdate('owner', value);
    this.facade.onContentOwnerChange(value);
  }

  public onAddressChange(addressDetails: LocalityViewModel): void {
    this.facade.onAddressChange(addressDetails);
  }

  public onToggleOfSessionDetails(isSessionDetailsToggledOn: boolean): void {
    this.facade.onToggleOfSessionDetails(isSessionDetailsToggledOn);
  }

  public updateDuration(event$: {
    durationHours: number;
    durationMinutes: number;
  }): void {
    this.courseCatalogForm
      .get('durationForm.durationHours')
      .setValue(event$.durationHours);
    this.courseCatalogForm
      .get('durationForm.durationMinutes')
      .setValue(event$.durationMinutes);

    this.facade.updateDuration(event$.durationHours, event$.durationMinutes);
    this.courseCatalogForm.get('durationForm').markAsTouched();
    this.courseCatalogForm.get('durationForm').markAsDirty();
    this.courseCatalogForm.get('durationForm').updateValueAndValidity();
    this.cdr.detectChanges();
  }

  /**
   * On selecting next from the initial form
   */
  private async onCourseUrlNext(): Promise<void> {
    await this.facade.onNext(this.courseCatalogForm.get('courseUrl').value);

    // Check if the Course URL has duplicates, if it does the UI will display corresponding error
    if (this.facade.snapshot.duplicateCount > 0) {
      return;
    }
  }

  /**
   * Initialize the first page of the form
   */
  private initializeForm(): void {
    this.courseCatalogForm = this.formBuilder.group(
      {
        courseUrl: ['', [Validators.pattern(HTTP_REQUIRED_URL_PATTERN)]],
        contentUploader: this.facade.snapshot.shouldShowContentUploader
          ? ['', []]
          : null,
      },
      {
        validator: atLeastOneRequiredValidator([
          'courseUrl',
          'contentUploader',
        ]),
      }
    );
  }
  /**
   * Load the expanded Form from creating new content
   */
  private loadExpandedForm(): void {
    this.showExpandedForm = true;

    const inputEntry =
      this.facade.snapshot.shouldShowContentUploader &&
      !!this.facade.snapshot.scormInfo &&
      !!this.courseCatalogForm.get('contentUploader').value
        ? {
            contentUploader: [
              this.courseCatalogForm.get('contentUploader').value,
              Validators.required,
            ],
          }
        : {
            courseUrl: [
              this.courseCatalogForm.get('courseUrl'),
              [
                Validators.required,
                Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
              ],
            ],
          };

    this.courseCatalogForm = this.formBuilder.group({
      ...inputEntry,
      title: ['', [Validators.required, isEmptyValidator]],
      description: [],
      // duration
      owner: ['', Validators.required],
      image: [],
      skills: ['', maxFifteenSkillsValidator],
      // advanced settings
    });

    this.courseCatalogForm.patchValue({
      courseUrl: this.facade.snapshot.courseUrl,
      title: this.facade.snapshot.name,
      description: this.facade.snapshot.description,
      image: this.facade.snapshot.imageUrl,
      skills: this.facade.snapshot.highConfidenceInferredSkills ?? [], // Currently tags are not being inferred here
    });

    // When loading the full view reset the focus on the url input if we do not have
    // hosted content, otherwise dgxautofocus will focus on the title.
    if (!this.facade.snapshot.hostedContentDetails) {
      this.courseUrlInput?.nativeElement.focus();
    }
    // Subscribe to value changes on the courseURL to reset the duplicates error
    this.courseCatalogForm
      .get('courseUrl')
      ?.valueChanges.subscribe(() => this.facade.resetDuplicates());
  }

  /**
   * On edit initialize the full form
   */
  private initializeExpandedEditForm() {
    this.showExpandedForm = true;
    const inputEntry =
      this.facade.snapshot.shouldShowContentUploader &&
      !!this.facade.snapshot.hostedContentDetails &&
      !!this.facade.snapshot.scormInfo
        ? {
            contentUploader: [
              this.facade.snapshot.hostedContentDetails,
              Validators.required,
            ],
          }
        : {
            courseUrl: [
              this.facade.snapshot.courseUrl,
              [
                Validators.required,
                Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
              ],
            ],
          };

    const createdByI18n = this.translate.instant('Core_CreatedBy', {
      authorName: this.facade.snapshot.createdBy,
    });

    const createdBy = !!this.facade.snapshot.createdBy ? createdByI18n : '';
    // NOTE: (duration, session, advancedSettings) are applied to this parent form within the child components
    this.courseCatalogForm = this.formBuilder.group({
      createdBy: createdBy,
      ...inputEntry,
      title: [
        this.facade.snapshot.name ?? '',
        [Validators.required, isEmptyValidator],
      ],
      description: this.facade.snapshot.description ?? '',
      // duration
      // session
      owner: [
        this.facade.snapshot.owner ?? '',
        [Validators.required, contentOwnerIdValidator],
      ],
      image: this.facade.snapshot.imageUrl ?? '',
      skills: [this.facade.snapshot.tags ?? [], maxFifteenSkillsValidator],
      // advanced settings
    });
  }
}
