import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { CredentialService } from '@app/credential/services/credential.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingButtonBaseComponent } from '@app/tags/components/tag-rating-button/tag-rating-button-base.component';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';

@Component({
  selector: 'dgx-credential-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialRatingButtonComponent extends TagRatingButtonBaseComponent {
  public readonly type = InternalTagRatingTypes.credential;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    private credentialService: CredentialService
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get completedRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ViewCertificate');
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    if (this.ownerIsViewing && this.hasCompletedRatings) {
      this.viewCertification();
    }
  }

  /** View completed Skill Certification */
  private viewCertification(): void {
    this.credentialService
      .getCredentialByTagId(this.tag.tagId)
      .pipe(
        map((data) => {
          const userCredentialUid = data?.userCredential?.userCredentialUid;
          this.credentialService.viewCredentialCertificate(userCredentialUid);
        })
      )
      .subscribe();
  }
}
