import { sortBy } from '@app/shared/utils';
import { UserSearchItem } from '@app/user/user-api.model';
import { OrgPlanEntity } from '../org-plans.model';

// Create Partial OrgPlanEntity updates to merge existing authors with newly added authors
export function makeAuthorUpdates(
  plans: OrgPlanEntity[],
  newAuthors: { userKeys: number[]; collaborators: UserSearchItem[] }
) {
  let updates = [];
  plans.forEach((p) => {
    const authors = p.authored
      .filter((a) => !newAuthors.userKeys.includes(a.userProfileKey))
      .concat(newAuthors.collaborators)
      .sort((a, b) => sortBy(a, b, 'name'));

    updates.push({ id: p.id, authored: authors } as Partial<OrgPlanEntity>);
  });
  return updates;
}
