import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnInit,
  OnChanges,
} from '@angular/core';

// services
import { TranslateService } from '@ngx-translate/core';
import { SignalCount } from '@app/profile/profile-api.model';
import { isKey, Key } from '@app/shared/key';
import { TagsApi } from '@app/tags/tag-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverComponent } from '@app/shared/components/popover/popover.component';

@Component({
  selector: 'dgx-signal-popover',
  templateUrl: './signal-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalPopoverComponent implements OnInit, OnChanges {
  @Input() public signalSummary: SignalCount[];
  @Input() public signalCount: number;
  @Input() public skillName: string;
  @Input() public tag: TagsApi.Tag;
  // Use when SignalPopoverComponent has a click event so that screen reader can click on button.
  @Input() public isButton: boolean = false;
  @ViewChild('popoverTrigger', { static: true })
  public popoverTriggerRef: ElementRef;
  public showPopover: boolean = false;
  public ratingsCount: number = 0;
  public experiencesCount: number = 0;
  public achievementsCount: number = 0;
  public learningsCount: number = 0;

  public authUser = this.authService.authUser;

  public i18n = this.translateService.instant([
    'Core_Ratings',
    'Core_ExperiencesDisplayName',
    'Core_Achievements',
    'Core_Learnings',
    'dgProfileOverview_SkillSignalsTitle',
    'dgProfileOverview_TooltipSignalsPlural',
    'dgProfileOverview_TooltipSignalsSingle',
  ]);

  public get skillSignalsAriaLabel() {
    return this.translateService.instant(
      'dgProfileOverview_SkillSignalsLabel',
      {
        skillName: this.skillName,
        numSignals: this.signalCount,
        ratingsCount: this.ratingsCount,
        experiencesCount: this.experiencesCount,
        achievementsCount: this.achievementsCount,
        learningsCount: this.learningsCount,
      }
    );
  }

  public get signalLabel(): string {
    if (this.signalCount === 0) {
      return this.translateService.instant('Core_ZeroSignalsCount');
    }
    const label =
      this.signalCount === 1
        ? this.translateService.instant('Core_SignalCount')
        : this.translateService.instant('Core_SignalsCount', {
            count: this.signalCount,
          });
    return label;
  }

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.setState();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.signalSummary?.currentValue) {
      this.setState();
    }
  }

  public setState(): void {
    this.ratingsCount = this.getCount('Ratings');
    this.experiencesCount = this.getCount('Experiences');
    this.achievementsCount = this.getCount('Achievements');
    this.learningsCount = this.getCount('Learnings');
  }

  public togglePopover(showPopover: boolean = true): void {
    this.showPopover = showPopover;
  }

  public handleKeydown(event: KeyboardEvent) {
    if (isKey(event, Key.Enter, Key.Space)) {
      event.preventDefault();
      event.stopPropagation();

      if (this.tag) {
        this.goToSignalPage(event, this.tag);
      }
    }
  }

  public goToSignalPage(event, tag: TagsApi.Tag): void {
    if (this.authUser) {
      // prevent event from triggering skill modal
      event.stopPropagation();
      const url = this.router
        .createUrlTree(['../signals'], {
          relativeTo: this.route,
          queryParams: { tagId: tag.tagId },
        })
        .toString();
      this.router.navigateByUrl(url);
    }
  }

  public onIsPopoverOpenChange(popoverElement: PopoverComponent) {
    popoverElement.preventRefocus = true;
  }

  private getCount(countType: string): number {
    return this.signalSummary?.find((el) => el.name === countType)?.count || 0;
  }
}
