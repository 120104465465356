import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MentionItem } from '@app/comments/comments.model';
import { ProfilePicModule } from '@app/shared/components/profile-pic/profile-pic.module';

@Component({
  selector: 'dgx-mention-list-item',
  standalone: true,
  imports: [ProfilePicModule],
  templateUrl: './mention-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionListItemComponent {
  @Input() public mention: MentionItem;
}
