import { NgModule } from '@angular/core';

import { DfButtonBasicComponent } from './button-basic.component';

@NgModule({
  imports: [],
  exports: [DfButtonBasicComponent],
  declarations: [DfButtonBasicComponent],
  providers: [],
})
export class DfButtonBasicModule {}
