<div class="guts-m-b-2" *ngIf="showPrivateOrAnonymous">
  <df-local-notification
    [type]="NotificationType.info"
    [text]="i18n.Team_Group_Shared_And_Private_Message"
    size="small"
    icon="info"
  ></df-local-notification>
</div>
<div class="shared-with-user-list" (scroll)="scrollHandler($event)">
  <div *ngIf="loadingUsers">
    <div class="spinner"></div>
  </div>
  <div *ngIf="!loadingUsers">
    <ul *ngIf="users">
      <li
        *ngFor="let recommendationUser of users; last as isLast"
        class="guts-p-b-half"
        [ngClass]="{ 'guts-m-b-half border-bottom': !isLast }"
        data-dgat="shared-with-user-list-e07"
      >
        <div class="l_flexbar guts-p-t-half">
          <div class="guts-p-r-1">
            <dgx-profile-pic
              [profile]="recommendationUser.user"
              size="small"
            ></dgx-profile-pic>
          </div>

          <div
            class="l_flex-grow"
            [attr.aria-label]="recommendationUser.user.name"
            tabindex="0"
          >
            <a
              *ngIf="!isSkillCoach"
              [href]="recommendationUser.user.internalUrl"
              class="par par--small font-medium"
              data-dgat="shared-with-user-list-ef7"
            >
              {{ recommendationUser.user.name }}
            </a>
            <p *ngIf="isSkillCoach" class="par par--small font-medium">
              {{ recommendationUser.user.name }}
            </p>
          </div>

          <div class="l_flex-none" tabindex="0">
            <div class="par--small par--light_a font-semibold">
              <div
                *ngIf="
                  statusTypes.Complete === recommendationUser.statusId ||
                  statusTypeNums.Complete === recommendationUser.statusId
                "
              >
                <span
                  class="guts-p-r-1"
                  *ngIf="recommendationUser.statusDetails"
                  >{{
                    'recommendationDetail_LevelFormat'
                      | translate
                        : {
                            statusDetails: recommendationUser.statusDetails
                          }
                  }}</span
                >
                <div
                  class="badge badge-pill badge-success"
                  [attr.aria-label]="i18n.recommendationDetail_StatusComplete"
                >
                  {{ i18n.recommendationDetail_StatusComplete }}
                </div>
              </div>
              <div
                *ngIf="
                  statusTypes.Queued === recommendationUser.statusId ||
                  statusTypeNums.Queued === recommendationUser.statusId
                "
              >
                <div
                  class="epsilon no-wrap badge badge-pill badge-neutral"
                  [attr.aria-label]="i18n.recommendationDetail_StatusQueued"
                >
                  {{ i18n.recommendationDetail_StatusQueued }}
                </div>
              </div>
              <div
                *ngIf="
                  statusTypes.Pending === recommendationUser.statusId ||
                  statusTypeNums.Pending === recommendationUser.statusId
                "
              >
                <div
                  class="epsilon no-wrap badge badge-pill badge-attention"
                  [attr.aria-label]="i18n.recommendationDetail_StatusPending"
                >
                  {{ i18n.recommendationDetail_StatusPending }}
                </div>
              </div>
              <div
                *ngIf="
                  statusTypes.Dismissed === recommendationUser.statusId ||
                  statusTypeNums.Dismissed === recommendationUser.statusId
                "
              >
                <div
                  class="epsilon no-wrap badge badge-pill badge-danger"
                  [attr.aria-label]="i18n.recommendationDetail_StatusDismissed"
                >
                  {{ i18n.recommendationDetail_StatusDismissed }}
                </div>
              </div>
              <div
                *ngIf="
                  statusTypes.Private === recommendationUser.statusId ||
                  statusTypeNums.Private === recommendationUser.statusId
                "
              >
                <div
                  class="epsilon no-wrap badge badge-pill badge-neutral"
                  [attr.aria-label]="i18n.Core_Private"
                >
                  {{ i18n.Core_Private }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
