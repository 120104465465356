import { SlicePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitToClosestWord',
})
export class ClosestWordLimitPipe implements PipeTransform {
  constructor(private slicePipe: SlicePipe) {}

  public transform(value: any, limit: number = 100): any {
    if (value.length > limit) {
      const truncatedValue = (this.slicePipe.transform(
        value,
        0,
        limit + 1
      ) as unknown) as string;
      const truncatedWords = truncatedValue.split(' ');

      if (truncatedWords.length > 1) {
        truncatedWords.pop();
      }

      value = truncatedWords.join(' ');
    }

    return value;
  }
}
