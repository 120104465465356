import {
  UserGroupListService,
  UserOrGroupSearchItem,
} from '@app/shared/services/content/user-group-list.service';
import { map, Observable, of } from 'rxjs';

/**
 * Where we have a 'real' owner, get any necessary supporting details (userpic, name)
 * and add that to the input.
 *
 * @param input - Article/Video/Course, anywhere a content owner could be found.
 * @param service - UserGroupListService
 */
export function addOwnerDetailsToInput<T>(
  input: any,
  service: UserGroupListService
): Observable<T> {
  // Where the type is group and the name is available, an extra API call is unnecessary.
  if (
    input.primaryContactResourceType === 'Group' &&
    input.primaryContactName
  ) {
    return of({
      ...input,
      owner: {
        resourceType: input.primaryContactResourceType,
        resourceId: input.primaryContactResourceId,
        name: input.primaryContactName,
      },
    });
  }
  // Otherwise, get the details and return them.
  return service
    .getUserOrGroupDetailsByKey(
      input.primaryContactResourceId,
      input.primaryContactResourceType,
      input.organizationId
    )
    .pipe(map((owner) => ({ ...input, owner }) as any));
}

/**
 * Where we have a simple string owner, set the name but leave other fields null.
 *
 * @param input
 */
export function addTempOwnerToInput<T>(input: any): Observable<T> {
  const owner: UserOrGroupSearchItem = {
    name: input.owner as any as string,
    resourceId: null,
    resourceType: null,
    organizationId: null,
    picture: null,
    vanityUrl: null,
  };
  return of({ ...input, owner });
}
