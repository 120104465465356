<ng-container *ngIf="facade.formUiState$ | async as uiState">
  <ng-container *ngIf="uiState.viewModel || asAny({}) as vm">
    <form
      role="form"
      [formGroup]="form"
      class="form-wrap"
      (ngSubmit)="onSubmit()"
      (keydown)="onKeyDown($event, vm.shouldShowResults$)"
      trapFocus
    >
      <dgx-modal
        [bodyClasses]="{
          'guts-p-h-2 guts-p-t-1': true,
          'input-success-kickstart': vm.shouldShowResults$ | async,
          first: true,
          second: true,
          third: false
        }"
        [canCancel]="true"
        [isFooterless]="vm.useAnimatedSubmit"
        [isSubmitDisabled]="isSaving || vm.isSubmitButtonDisabled"
        [isSubmitPending]="isSaving"
        [modalHeaderTitle]="modalTitle || vm.formTitle"
        [submitButtonText]="vm.submitButtonText"
        [useDefaultForm]="false"
        (dismiss)="onDismiss(vm.shouldShowResults$)"
        submitButtonDgat="contentCatalogForm-99d"
      >
        <ng-container class="modal-body">
          <div class="input-success-wrap">
            <div
              *ngIf="vm.shouldShowCatalogEditWarning"
              class="border rounded guts-p-full-1 bg-color-ebony-a03 guts-m-b-2 guts-m-t-1-half"
            >
              <p>{{ i18n.Core_EditItemHelper }}</p>
            </div>
            <df-form-root
              [form]="form"
              [options]="options"
              [fields]="uiState.fields"
              [model]="vm"
            >
            </df-form-root>
          </div>
          <!--Submit button and animated submission results for user completion context only-->
          <dgx-global-add-results
            *ngIf="(vm.shouldShowSubmitButton$ | async) && vm.useAnimatedSubmit"
            [buttonText]="vm.submitButtonText"
            [isEditing]="vm.isEditing"
            [isCompleting]="vm.isCompleting"
            [isNewbUser]="isNewbUser"
            [submissionStatus]="vm.submissionStatus$ | async"
            [creationFeedback]="result?.creationFeedback"
            [classifier]="
              facade.inputType === 'Experience' ? 'experiences' : 'learning'
            "
            [showCancelButton]="facade.inputType === 'Experience'"
            (dismissWithNavigation)="onNavigateToCollection($event)"
          >
          </dgx-global-add-results>
        </ng-container>

        <ng-container class="modal-footer">
          <!--separator dot and delete button-->
          <div
            class="grid__col-4 grid__col--bleed lh1 l_flex-grow l_flex-center left-text"
            *ngIf="vm.shouldShowDelete"
          >
            <span>
              <df-icon
                icon="record"
                size="extra-small"
                class="color-ebony-a61 guts-m-h-1"
              ></df-icon
              ><button
                df-button
                dfButtonType="passive"
                class="btn btn--xs btn-passive--no-border font-regular"
                (click)="onDelete()"
                data-dgat="contentCatalogForm-c6a"
              >
                <span>{{ i18n.Core_Delete }}</span>
              </button>
            </span>
          </div>
        </ng-container>
      </dgx-modal>
    </form>

    <ng-template>
      <ngb-datepicker #d></ngb-datepicker>
    </ng-template>

    <!--Common fields. Consider refactoring most of these as foreign fields-->

    <!--Content uploader for media files-->
    <ng-template
      #contentUploader
      let-formControl="formControl"
      let-params="params"
    >
      <div>
        <div *ngIf="params.hasDivider" class="divider guts-m-t-1 guts-m-b-2">
          <span
            class="par par--small par--light up-me bg-color-white divider__content"
            >{{ params.i18n.Core_Or }}</span
          >
        </div>
        <div
          *ngIf="vm.shouldShowReplaceContentButtons"
          class="grid grid--bleed rel oh"
        >
          <button
            *ngIf="vm.shouldShowRecordVideoButton"
            df-button
            [dfButtonType]="'clear'"
            data-dgat="videoForm-1a7"
            class="guts-m-l-1 guts-m-b-1"
            (click)="loadRecordedVideoModal()"
          >
            <df-icon
              icon="video-camera"
              aria-hidden="true"
              class="color-blue guts-m-r-half"
            ></df-icon>
            <span class="color-blue">{{
              params.i18n.dgOrgInternalContentForm_ReRecordVideo
            }}</span>
          </button>
        </div>
        <div
          class="guts-m-t-1"
          *ngIf="vm.shouldShowContentUploader"
          [@dfCollapseExpand]
        >
          <!--This could be replaced by the UserImageFieldComponent in the builder with some tweaks-->
          <dgx-upload-section
            [shouldDisableContentUploader]="vm.shouldDisableContentUploader"
            [inputType]="vm.inputType"
            [fileInfo]="params.file"
            [uploadAdapter]="params.uploadAdapter"
            [fileRestrictions]="
              params.useExistingFileRestrictions
                ? vm.fileRestrictions
                : undefined
            "
            [errorMessages]="params.errorMessages"
            [labels]="params.labels"
            [useCropper]="false"
            icon="arrow-up-tray"
            (fileChangedEvent)="vm.contentUpload.onContentFileChange($event)"
            (uploadSuccessEvent)="
              vm.contentUpload.onContentUploadSuccess(formControl, $event)
            "
            (errorEvent)="vm.contentUpload.onContentUploadFailure()"
          >
          </dgx-upload-section>
        </div>
      </div>
    </ng-template>

    <!--"Created By" read only text field, Hidden when no creator value, i.e., when not editing-->
    <ng-template #creator let-label="label" let-formControl="formControl">
      <p
        class="par par--small par--light guts-m-t-1 guts-m-b-half"
        *ngIf="formControl.value"
      >
        {{ 'Core_CreatedBy' | translate: { authorName: formControl.value } }}
      </p>
    </ng-template>

    <!--Basic read only text field for un-editable states-->
    <ng-template #readonlyField let-label="label" let-formControl="formControl">
      <p>{{ formControl.value }}</p>
    </ng-template>

    <!--Skills editor-->
    <ng-template
      #tagsEditor
      let-formControl="formControl"
      let-params="params"
      dfSkill
    >
      <dgx-tags-edit
        class="form-wrap guts-m-t-1-quart"
        [tags]="formControl.value"
        (tagsChange)="onTagsChanged(formControl, $event)"
      ></dgx-tags-edit>
    </ng-template>

    <!--Groups editor field for consumer users-->
    <ng-template
      #groupsEditor
      let-formControl="formControl"
      let-label="label"
      let-params="params"
    >
      <div class="form-wrap guts-m-t-1-half">
        <!--
          Linter error
          (groupsChange)="onItemsFieldChange($event, formControl)"
           Property 'onItemsFieldChange' does not exist on type 'ModalContainerComponent'.
        -->
        <dgx-groups-edit
          [label]="label"
          [isRequired]="formControl.required"
          [tipText]="params?.tipText"
          [groups]="formControl.value"
        >
        </dgx-groups-edit>
      </div>
    </ng-template>

    <!-- Duplicate check help warning -->
    <ng-template #addToCatalogDupsHelp>
      <dgx-check-duplicate-inputs
        *ngIf="vm.addToCatalog && vm.duplicateCount"
        decisionText="checkDuplicateInputs_Decision"
        [duplicateCount]="vm.duplicateCount"
        (viewDuplicates)="onViewDuplicates()"
      >
      </dgx-check-duplicate-inputs>
      <!-- Show org-managed notification for Pathway add-by-type content that is add-to-catalog-->
      <dgx-org-managed-notification
        class="guts-m-t-2 block"
        *ngIf="
          vm.addToCatalog && !vm.isEditing && vm.isPathway && !vm.organizationId
        "
        resourceType="Pathway"
      ></dgx-org-managed-notification>
    </ng-template>

    <!--Duplicate check help warning for content catalog modals-->
    <ng-template #addToCatalogDupsHelpCatalog>
      <dgx-check-duplicate-inputs
        *ngIf="vm.duplicateCount"
        [duplicateCount]="vm.duplicateCount"
        (viewDuplicates)="onViewDuplicates()"
      >
      </dgx-check-duplicate-inputs>
    </ng-template>

    <ng-template
      #urlBrokenValidation
      *ngIf="isBrokenLinksFlagOn"
      let-formControl="formControl"
      let-params="params"
    >
      <label for="urlBrokenValidation" class="form-label"
        >{{ params.label
        }}<df-label-marker *ngIf="params.isRequired"></df-label-marker>
      </label>
      <input
        dgxAutofocus
        id="urlBrokenValidation"
        type="url"
        class="df-text-input df-text-input-border"
        [ngClass]="{
          'border--error':
            formControl.pristine &&
            params.originalUrl === formControl.value &&
            vm.hasBrokenUrl
        }"
        data-dgat="input-url-1jd8"
        placeholder="{{ params.placeholderText }}"
        [value]="formControl.value"
        (change)="updateAndValidateComponent($event, formControl, true)"
      />
      <!-- Show error message on initiation of form, but hide this & allow Formly to handle error messaging upon changes -->
      <div
        *ngIf="
          formControl.value &&
          formControl.status === 'INVALID' &&
          !formControl.touched
        "
        class="df-form__invalid-msg"
      >
        <df-icon
          icon="arrow-down-right"
          class="guts-m-r-quart"
          size="small"
        ></df-icon>
        <span>{{
          getInputUrlErrorMessage(formControl.value, params, vm.hasBrokenUrl)
        }}</span>
      </div>
    </ng-template>

    <!--Video player compatibility confirmation help, plus the duplicates check-->
    <ng-template #videoCompatibleAndDupsHelp>
      <div aria-live="polite">
        <p
          class="color-success par--small guts-p-t-half l_flexbar"
          *ngIf="vm.isPlayableVideo$ | async"
        >
          <df-icon
            icon="checkmark"
            size="small"
            class="ib guts-p-r-half"
          ></df-icon>
          {{ i18n.dgOrgInternalContentForm_VideoSourceSupported }}
        </p>
        <ng-container *ngTemplateOutlet="addToCatalogDupsHelpCatalog">
        </ng-container>
      </div>
    </ng-template>

    <!--URL field custom help template which includes thumbnail from parsed content-->
    <ng-template #urlCustomHelp let-formControl="formControl">
      <ng-container *ngIf="!vm.isAccessible; else accessible">
        <label *ngIf="vm.inputType === 'Article'">
          {{ i18n.MediaFormCtrl_ErrorUnaccessibleArticle }}</label
        >
        <label *ngIf="vm.inputType === 'Video'">
          {{ i18n.MediaFormCtrl_ErrorUnaccessibleVideo }}
        </label>
      </ng-container>

      <ng-template #accessible>
        <div class="grid guts-p-t-2">
          <div
            class="grid__col-3 grid__col--bleed guts-m-b-1 item"
            *ngIf="vm.imageUrl && facade.isAuthoring"
          >
            <img [alt]="vm.title" [src]="vm.imageUrl" class="grid__cell-img" />
          </div>
        </div>
      </ng-template>
    </ng-template>

    <!--Event length select. TODO: replace with SelectFieldComponent -->
    <ng-template #eventLength let-label="label" let-formControl="formControl">
      <dgx-select
        [options]="eventLengthOptions"
        [selectedItem]="vm.extent?.length"
        class="full-width"
        [ariaLabel]="label"
        dgatInput="event-modal-ec0"
        (selection)="formControl.setValue($event.id)"
      ></dgx-select>
    </ng-template>

    <ng-template
      #eventInvolvement
      let-label="label"
      let-formControl="formControl"
    >
      <!--
      lint cleanup - dgx-select below...
      involvementOptions doesn't exist, does it need to?
      [options]="involvementOptions"
    -->
      <dgx-select
        [selectedItem]="vm.extent?.involvementLevel"
        (selection)="formControl.setValue($event.id)"
        class="full-width"
        [ariaLabel]="label"
        dgatInput="event-modal-7bd"
      ></dgx-select>
    </ng-template>

    <ng-template>
      <ngb-datepicker #d></ngb-datepicker>
    </ng-template>

    <!-- Assessment questions correct. TODO: replace with formly numeric text field -->
    <ng-template #assessmentContentCatalogInitialView let-params="params">
      <div class="guts-m-b-2">
        <button
          dgxAutofocus
          df-button
          dfButtonType="passive"
          (click)="params.createInCredSpark(); onDismiss()"
          data-dgat="assessmentForm-0d0"
        >
          {{
            params.i18n.dgOrgInternalContentForm_CreateNewCredSparkAssessment
          }}
        </button>
        <button
          type="button"
          class="text color-blue guts-m-t-half guts-m-l-1"
          (click)="params.addContentCatalogAssessmentByUrl()"
          data-dgat="assessmentForm-10c"
        >
          {{ params.i18n.dgOrgInternalContentForm_AddFromUrl }}
        </button>
      </div>
    </ng-template>
    <ng-template
      #assessmentManageCredSpark
      let-onClick="params.forwardToCredSpark"
    >
      <div
        class="btn btn-primary full-width guts-m-t-half"
        data-dgat="modal-container-93f"
        (click)="onClick($event)"
      >
        <span class="guts-m-r-half">{{
          'dgOrgInternalContentForm_ManageOnCredSpark' | translate
        }}</span>
      </div>
    </ng-template>

    <ng-template
      #assessmentQuestionsCorrect
      let-formControl="formControl"
      let-updateField="params.onQuestionsCorrectFieldChange"
    >
      <label
        for="assessmentQuestionsCorrect"
        [innerHtml]="assessmentQuestionsCorrectLabel"
      ></label>
      <input
        (keydown)="validatePositiveNumericValue($event)"
        inputmode="numeric"
        id="assessmentQuestionsCorrect"
        class="df-text-input df-text-input-border"
        type="number"
        data-dgat="assessment-form-a71"
        (change)="
          updateField(
            form,
            formControl,
            HTMLInputElement($event.currentTarget).value
          )
        "
        (blur)="
          updateField(
            form,
            formControl,
            HTMLInputElement($event.currentTarget).value
          )
        "
        [class.df-form__field--invalid]="
          formControl.invalid && (formControl.touched || formControl.dirty)
        "
      />
      <div
        id="correctQuestions-error"
        class="df-form__invalid-msg ib rel"
        aria-live="polite"
        *ngIf="
          formControl.invalid && (formControl.touched || formControl.dirty)
        "
      >
        <df-icon
          class="guts-m-r-quart"
          icon="arrow-down-right"
          size="small"
        ></df-icon>
        <span>{{
          (
            formControl.errors.correctQuestionsValidNumber ||
            formControl.errors.correctQuestionsLessThanTotal
          ).message
        }}</span>
      </div>
    </ng-template>

    <!-- Books -->
    <!--  [formControl]="formControl" -->
    <ng-template
      #bookTitleSuggest
      let-formControl="formControl"
      let-placeholder="params.placeholder"
      let-ariaDescribedby="params.ariaDescribedby"
      let-vm="params.vm"
    >
      <input
        type="text"
        class="df-text-input df-text-input-border"
        [placeholder]="placeholder"
        dgxAutofocus
        autocomplete="off"
        data-dgat="bookForm-ef3"
        [attr.aria-describedby]="ariaDescribedby"
        [ngbTypeahead]="vm.searchTitle"
        [resultTemplate]="bookListItem"
        [resultFormatter]="vm.formatBookResult"
        [inputFormatter]="vm.formatBookResult"
        (selectItem)="vm.onSelectBook($event.item, formControl)"
      />
    </ng-template>

    <!--Book search auto-suggest list item-->
    <ng-template #bookListItem let-item="result">
      <div class="listbox--option">
        <a class="l_flexbar" data-dgat="bookListItem-f24">
          <div>
            <img
              alt="{{
                'BookFormCtrl_BookCoverAltFormat'
                  | translate: { title: item.title }
              }}"
              [src]="item.imageUrl"
              class="book-cover-image-width"
            />
          </div>
          <div class="l_flex-top">
            <div class="guts-p-l-1">
              <p class="guts-p-b-0 color-ebony">{{ item.title }}</p>
              <p *ngIf="item.subtitle" class="guts-p-b-0 color-ebony">
                {{ item.subtitle }}
              </p>
              <p class="color-ebony-a61 micro" *ngIf="item.author">
                ({{ item.author }})
              </p>
            </div>
          </div>
        </a>
      </div>
    </ng-template>

    <!--Book info field template which includes thumbnail, title and summary from parsed content-->
    <ng-template
      #bookInfo
      let-formControl="formControl"
      let-book="params.book"
      let-isEditing="params.isEditing"
    >
      <div class="center-text">
        <img
          *ngIf="!isEditing"
          alt="{{ book.title }} book cover"
          [src]="book.imageUrl"
          width="100"
        />
        <ul
          *ngIf="!isEditing"
          class="meta-list meta-list--light meta-list--all-caps guts-m-v-1"
        >
          <li>{{ book.author }}</li>
          <li>
            {{ book.durationUnits }}
            {{
              book.durationUnitType
                ? (book.durationUnitType | displayType)
                : ('BookFormCtrl_Pages' | translate)
            }}
          </li>
        </ul>
        <h1 class="beta guts-m-b-1">{{ book.title }}</h1>
        <h2 class="delta guts-m-b-1">{{ book.subtitle }}</h2>
      </div>
      <div *ngIf="!isEditing" class="guts-p-b-2 guts-p-h-4 m-guts-p-h-0">
        <p
          class="color-ebony-a61"
          [dgxLineClampText]="book.description"
          [clampLinesMax]="8"
        ></p>
      </div>
    </ng-template>

    <!--TODO: once we merge the capability to provide specific contextual params to labels, help and description
    we can use template params to control expansion instead-->
    <ng-template #advancedExpander>
      <div class="guts-m-t-2 df-form__label color-blue">
        <strong>
          {{ i18n.Core_AdvancedSettings }}
          <button
            df-button
            dfButtonType="clear"
            class="expander color-blue guts-m-t-quart guts-p-l-half l_flex-shrink"
            (click)="vm.toggleAdvanced()"
            attr.aria-expanded="{{ vm.expandAdvanced$ | async }}"
            aria-controls="eventForm-advanced"
            data-dgat="event-modal-ee8"
            attr.aria-label="{{
              (vm.expandAdvanced$ | async)
                ? getAriaText(true, 'Core_AdvancedSettings')
                : getAriaText(false, 'Core_AdvancedSettings')
            }}"
          >
            <df-icon
              [icon]="
                (vm.expandAdvanced$ | async) ? 'chevron-up' : 'chevron-down'
              "
            ></df-icon>
          </button>
        </strong>
      </div>
    </ng-template>

    <!-- Course -->

    <!-- Informal/Formal selection Begin -->
    <ng-template
      #institutionSelection
      let-formControl="formControl"
      let-params="params"
    >
      <ng-container>
        <div
          *ngFor="let source of params.sourceList"
          data-dgat="courseForm-f3d"
        >
          <label for="courseType">
            <input
              dgxAutofocus
              type="radio"
              id="courseType"
              name="courseType"
              [value]="source"
              [checked]="vm.courseTypeId === source.id"
              (change)="
                onCourseTypeChange(formControl, source);
                vm.formUIState.courseTypeState$.next(source.id)
              "
              data-dgat="courseForm-63b"
            />
            {{ source.title }}
          </label>
        </div>
      </ng-container>
    </ng-template>
    <!-- Informal/Formal selection End -->

    <ng-template
      #courseLevel
      let-label="label"
      let-formControl="formControl"
      let-params="params"
    >
      <dgx-select
        [options]="params.allCourseLevels"
        [selectedItem]="vm.extent.courseLevel"
        class="full-width"
        [ariaLabel]="label"
        dgatInput="event-modal-ec0"
        [labelKey]="'level'"
        [trackBy]="'id'"
        (selection)="formControl.setValue($event.id, formControl)"
      ></dgx-select>
    </ng-template>

    <ng-template
      #courseGrade
      let-label="label"
      let-formControl="formControl"
      let-params="params"
    >
      <dgx-select
        [options]="params.allGrades"
        [selectedItem]="vm.extent.courseGrade"
        class="full-width"
        [ariaLabel]="label"
        dgatInput="event-modal-ec0"
        [labelKey]="'Name'"
        [trackBy]="'id'"
        (selection)="formControl.setValue($event.id, formControl)"
      ></dgx-select>
    </ng-template>

    <!-- template for institution search -->
    <ng-template #institutionSearch let-formControl="formControl">
      <input
        dgxAutofocus
        type="text"
        class="df-text-input df-text-input-border"
        autocomplete="off"
        [ngbTypeahead]="onProviderSearch"
        [inputFormatter]="labelFormatter"
        [resultTemplate]="institutionResult"
        (selectItem)="onProviderSelection($event, formControl)"
        (blur)="updateAndValidateComponent($event, formControl)"
        data-dgat="course-formal-form-a54"
      />
    </ng-template>

    <ng-template #institutionResult let-item="result" let-term="term">
      <span class="listbox--option">
        <ngb-highlight
          [result]="item.label"
          [term]="term"
          [highlightClass]="'font-semibold'"
        ></ngb-highlight>
      </span>
      <dgx-validate-field
        *ngIf="model.institutionMessage"
        [message]="model.institutionMessage"
      ></dgx-validate-field>
    </ng-template>

    <!-- template for country -->
    <ng-template #countrySearch let-formControl="formControl">
      <input
        dgxAutofocus
        id="courseCountry"
        type="text"
        class="course-country"
        name="country"
        autocomplete="off"
        [value]="vm.country"
        [ngbTypeahead]="onCountrySearch"
        (selectItem)="onCountrySelection($event, formControl)"
        [resultTemplate]="countrySearchResult"
        data-dgat="course-formal-form-26d"
      />
    </ng-template>

    <ng-template #countrySearchResult let-item="result" let-term="term">
      <span class="listbox--option">
        <ngb-highlight
          [result]="item"
          [term]="term"
          [highlightClass]="'font-semibold'"
        ></ngb-highlight>
      </span>
    </ng-template>

    <!-- template for course title -->
    <ng-template #courseTitleSearch let-formControl="formControl">
      <input
        id="courseTitle"
        type="text"
        class="course-title"
        name="courseName"
        autocomplete="off"
        [ngbTypeahead]="onCourseTitleSearch"
        [inputFormatter]="labelFormatter"
        [resultTemplate]="courseTitleResult"
        (selectItem)="onSelectCourseFromList($event, formControl)"
        (blur)="
          formControl.setValue(
            HTMLInputElement($event.target).value,
            formControl
          )
        "
        data-dgat="course-formal-form-294"
      />
    </ng-template>
    <ng-template #courseTitleResult let-item="result" let-term="term">
      <span class="listbox--option">
        <ngb-highlight
          [result]="item.label"
          [term]="term"
          [highlightClass]="'font-semibold'"
        ></ngb-highlight>
      </span>
    </ng-template>

    <!-- Episode -->
    <ng-template
      #episodeInitialization
      let-formControl="formControl"
      let-vm="params.vm"
      let-isLoadingEpisodes="params.isLoadingEpisodes"
      let-hasNoPodcastMatch="params.hasNoPodcastMatch"
      let-nameOrUrl="params.nameOrUrl"
      let-i18n="params.i18n"
    >
      <input
        id="episodeInitialization"
        type="text"
        data-dgat="episodeForm-668"
        class="df-text-input df-text-input-border"
        [class.df-form__field--invalid]="
          formControl.invalid && (formControl.touched || formControl.dirty)
        "
        [placeholder]="vm.placeholder"
        autocomplete="off"
        dgxAutofocus
        (input)="
          vm.onFormInitialInput(
            formControl,
            HTMLInputElement($event.target).value
          )
        "
        [value]="nameOrUrl"
        [ngbTypeahead]="vm.getPodcasts"
        (selectItem)="vm.selectPodcast($event.item)"
        [resultTemplate]="podcastListItemTemplate"
        [inputFormatter]="vm.formatPodcastSelection"
      />
      <p class="par par--small par--light guts-p-b-0 guts-m-t-quart guts-p-h-1">
        {{ vm.helpLabel }}
      </p>

      <label
        *ngIf="hasNoPodcastMatch && !isLoadingEpisodes"
        class="guts-p-h-1 guts-m-t-half"
        >{{ vm.noMatchLabel }}</label
      >
    </ng-template>

    <ng-template #podcastListItemTemplate let-item="result" let-term="term">
      <a class="l_flexbar" data-dgat="podcastListItem-d58">
        <div class="guts-p-r-half">
          <img alt="{{ item.title }} image" [src]="item.imageUrl" />
        </div>
        <div class="l_flex-grow">
          <p [innerHtml]="item.title"></p>
          <p *ngIf="item.artistName" class="micro par--light">
            ({{ item.artistName }})
          </p>
        </div>
      </a>
    </ng-template>

    <ng-template
      #episodeSelection
      let-formControl="formControl"
      let-isLoadingEpisode="params.isLoadingEpisode"
      let-selectedEpisode="params.selectedEpisode"
      let-episodeList="params.episodeList"
      let-fieldData="params.fieldData"
      let-vm="params.vm"
    >
      <df-spinner *ngIf="isLoadingEpisode"></df-spinner>

      <span *ngIf="!selectedEpisode">
        <label for="episodeTitle" class="form-label"
          >{{ fieldData.episodeTitleLabel }}
          <df-label-marker></df-label-marker>
        </label>
        <dgx-select
          id="episodeTitle"
          class="full-width"
          labelKey="title"
          trackBy="title"
          [options]="episodeList"
          [selectedItem]="formControl.value"
          (selection)="fieldData.loadEpisode(formControl, $event)"
          [placeholder]="fieldData.getPlaceholder()"
          [ariaLabel]="fieldData.getPlaceholder()"
          [isDisabled]="fieldData.isEpisodeListEmpty"
          dgatInput="episode-form-8bf"
        ></dgx-select>
      </span>

      <span *ngIf="selectedEpisode">
        <div class="center-text">
          <img
            *ngIf="selectedEpisode.imageUrl"
            alt="{{ selectedEpisode.title }} image"
            [src]="selectedEpisode.imageUrl"
          />
          <ul class="meta-list color-ebony-a61 guts-m-v-1">
            <li *ngIf="selectedEpisode.author">
              {{ selectedEpisode.author }}
            </li>
            <li>{{ selectedEpisode.feedName }}</li>
            <li *ngIf="selectedEpisode.duration">
              {{ vm.initializedForm.duration }}
              {{ fieldData.minutesLabel }}
            </li>
          </ul>
          <h1 class="beta guts-m-b-1">{{ selectedEpisode.title }}</h1>
        </div>
        <div class="guts-p-h-4 guts-p-b-2 crap">
          <p
            class="color-ebony-a61"
            [innerHTML]="selectedEpisode.summary | ellipsis: 500"
          ></p>
        </div>
      </span>
    </ng-template>

    <!-- /Episode -->

    <ng-template
      #imageSize
      let-formControl="formControl"
      let-imageSizeOptions$="params.imageSizeOptions$"
    >
      <label
        class="block guts-m-t-half"
        *ngFor="let imageSizeOption of imageSizeOptions$ | async"
        data-dgat="articleForm-b85"
      >
        <span class="ib font-medium cursor-pointer">
          <input
            type="radio"
            class="guts-m-r-half"
            [value]="imageSizeOption.id"
            [checked]="formControl.value === imageSizeOption.id"
            (change)="onImageSizeChange(imageSizeOption.id, formControl)"
            data-dgat="articleForm-4af"
          />
          <span>{{ imageSizeOption.label }}</span>
        </span>
      </label>
    </ng-template>

    <ng-template #videoRecordButton let-params="params">
      <!--Record Video section-->
      <ng-container *ngIf="params.videoConstraints$ | async as constraints">
        <ng-container
          *ngIf="videoConstraints(constraints).settings.usedDataPercent < 100"
        >
          <!--Section divider (present only initially)-->
          <div class="divider guts-m-t-1 guts-m-b-2">
            <span
              class="par par--small par--light up-me bg-color-white divider__content"
              >{{ params.i18n.Core_Or }}</span
            >
          </div>
          <div class="l_flexbar">
            <div class="guts-p-t-quart l_flex-grow">
              <div class="form__label">
                {{ params.i18n.dgOrgInternalContentForm_RecordAVideo }}
              </div>
              <div class="par par--small par--light">
                {{
                  videoConstraints(constraints)
                    .dgOrgInternalContentForm_VideoTimeLimit
                }}
              </div>
            </div>
            <button
              df-button
              [dfButtonType]="'clear'"
              data-dgat="videoForm-1a7"
              class="guts-m-full-1"
              [class.is_disabled]="!constraints"
              (click)="vm.isRecordPending = true; loadRecordedVideoModal()"
            >
              <df-icon
                icon="video-camera"
                aria-hidden="true"
                class="color-blue guts-m-r-half"
              ></df-icon>
              <span class="color-blue">{{
                params.i18n.dgOrgInternalContentForm_Record
              }}</span>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #spinner>
      <df-spinner></df-spinner>
    </ng-template>

    <ng-template #errorMessage let-params="params">
      <div class="__error-message color-error l_flexbar guts-m-r-2">
        <span class="l_flexbar guts-m-r-1">
          <df-icon
            icon="exclamation-circle"
            size="medium"
            class="color-red"
          ></df-icon>
        </span>
        <span
          class="par par--small color-error"
          [innerHTML]="params.errorMessage"
        ></span>
      </div>
    </ng-template>

    <ng-template #horizontalDivider>
      <div class="full-width">
        <hr
          class="full-width guts-m-h-0 guts-m-t-1--negative guts-p-full-0 bg-color-ebony-a18"
        />
      </div>
    </ng-template>
  </ng-container>
</ng-container>
