import { Component, Input, OnChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { fadeInAndOut } from '@app/shared/animations/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-setting-tile-validation-message',
  templateUrl: './setting-tile-validation-message.component.html',
  animations: [fadeInAndOut],
})
export class SettingTileValidationMessageComponent implements OnChanges {
  @Input() public errors: ValidationErrors;
  @Input() public controlIsDirty: boolean;
  @Input() public customErrorId: string;
  public errorTranslations: string[];

  public i18n = this.translate.instant([
    'Core_FieldRequired',
    'Core_InvalidEmail',
    'Core_UrlRequired',
  ]);
  public fallbackStrings = {
    required: this.i18n.Core_FieldRequired,
    email: this.i18n.Core_InvalidEmail,
    pattern: this.i18n.Core_UrlRequired,
  };

  constructor(private translate: TranslateService) {}
  public ngOnChanges(): void {
    if (this.errors) {
      this.errorTranslations = Object.entries(this.errors).map((error) => {
        const translateString = `OrgManage_SettingsError_${this.customErrorId}_${error[0]}`;
        const translation = this.translate.instant(translateString);
        return translateString !== translation
          ? translation
          : this.fallbackStrings[error[0]];
      });
    }
  }
}
