import { GlobalAddAction } from '@app/global-add/global-add.model';
import { TranslateService } from '@ngx-translate/core';
import { DisplayTypePipe } from '@app/shared/pipes/display-type.pipe';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Entity } from '@dg/shared-rsm';

@Component({
  selector: 'dgx-global-add-buttons',
  templateUrl: 'global-add-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAddButtonsComponent implements OnInit {
  // actions comes in as partial, transformed to a full GlobalAddAction
  @Input() public actions: Partial<GlobalAddAction & Entity>[];
  @Input() public type: string;

  constructor(
    private displayTypePipe: DisplayTypePipe,
    private translateService: TranslateService
  ) {}

  public ngOnInit() {
    if (!this.actions) {
      throw new Error(
        `'actions' is a required input to display buttons in dgx-global-add-buttons.`
      );
    }
    this.actions = this.actions.map((action, index) => {
      let modifiedAction = {};
      if (action.type === 'CreatePathway') {
        modifiedAction = {
          displayType: this.translateService.instant(
            'dgGlobalAdd_CreateAPathway'
          ),
        };
      } else {
        const displayType = this.displayTypePipe.transform(action.type);
        modifiedAction = {
          displayType,
        };
      }
      return {
        ...action,
        ...modifiedAction,
        ...{
          id: `dgat-globalAdd-${action.type}`,
        },
      };
    });
  }
}
