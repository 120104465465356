import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HTTP_URL_PATTERN } from '@app/shared/utils/form-helpers';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { RendererContext, FormRenderer } from '../../form-renderer.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { RenderMode } from '../../user-input.model';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

@Injectable({ providedIn: 'root' })
export class InformalCourseRenderer implements FormRenderer {
  private i18n = this.translate.instant([
    'CourseFormCtrl_SelectProvider',
    'Core_SelectDate',
  ]);
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('Core_UrlRequired'),
    },
  };

  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}

  /**
   * Implementing the strategy pattern.
   */
  public render(
    context: RendererContext,
    isGlobalAdd?: boolean
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const formType = 'Course';
    return [
      this.getInstitutionNameField(context),
      this.getCourseNameField(context),
      this.builder
        .optionalTextInput('courseUrl', 'CourseFormCtrl_CourseUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidator)
        .readonlyWhen(() => context.state().input?.externalId)
        .hiddenWhen(() => context.inputContext.isEditing)
        .build(),
      this.builder
        .optionalTextarea('description', 'Core_Description')
        .readonlyWhen(() => context.state().input?.externalId)
        .hiddenWhen(() => context.inputContext.isEditing)
        .build(),
      // Duration
      this.inputFieldBuilder.buildDurationFields(
        this.ldFlagsService.durationForCoursesEventsOther,
        formType,
        context,
        isGlobalAdd
      ),
      this.builder
        .checkbox('authored', 'CourseFormCtrl_ITaughtCourse')
        .hiddenWhen(
          () => context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),
      this.builder
        .fieldGroup(
          'extent',
          [
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                'CourseFormCtrl_DateCompleted',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectDate,
                  disabled: context.state().externalCompletionOnly,
                }
              )
              .styledBy('df-form__col-half')
              .hiddenWhen(
                () =>
                  context.inputContext.renderMode === RenderMode.Pathways ||
                  (context.inputContext.renderMode === RenderMode.UserProfile &&
                    context.state().authored === true)
              )
              .build(),
            this.builder
              .foreignField<MonthPickerFieldParams>(
                'dateCompleted',
                'CourseFormCtrl_DateTaught',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                  ariaLabel: this.i18n.Core_SelectDate,
                }
              )
              .styledBy('df-form__col-half')
              .hiddenWhen(
                () =>
                  context.inputContext.renderMode === RenderMode.Pathways ||
                  (context.inputContext.renderMode === RenderMode.UserProfile &&
                    context.state().authored !== true)
              )
              .build(),
            this.builder
              .optionalTextInput(
                'verificationUrl',
                'CourseFormCtrl_VerificationUrl'
              )
              .ofType('url')
              .validatedByIndexed(this.urlValidator)
              .hiddenWhen(
                () =>
                  context.inputContext.renderMode === RenderMode.Pathways ||
                  (context.inputContext.renderMode === RenderMode.UserProfile &&
                    context.state().authored === true)
              )
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .build(),
      this.builder
        .optionalTextarea('comment', 'CourseFormCtrl_WhatDidYouLearnLong', 100)
        .withPlaceholder('CourseFormCtrl_WhatDidYouLearnShort')
        .hiddenWhen(
          () =>
            context.inputContext.renderMode === RenderMode.Pathways ||
            context.inputContext.isEditing
        )
        .build(),
      this.inputFieldBuilder.buildPathwaysAddToCatalogField(
        context.inputContext.renderMode,
        context.state().canManageContent && !context.inputContext.isEditing,
        // Org name is optional so that consumer users can global add
        context.state().org?.name,
        'CourseFormCtrl_AddToCatalogFormat',
        context.templates.addToCatalogDupsHelp,
        context.state().onAddToCatalogChange
      ),
      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
    ];
  }
  private getInstitutionNameField(context: RendererContext): DfFormFieldConfig {
    if (context.inputContext.isEditing) {
      return this.builder
        .requiredTextInput('institutionName', 'CourseFormCtrl_ProviderName')
        .readonlyWhen(() => true)
        .asOptional()
        .build();
    } else {
      return this.builder
        .customField(
          'institutionName',
          'CourseFormCtrl_ProviderName',
          context.templates.institutionSearch
        )
        .validatedByIndexed({
          institutionValidation: {
            expression: (control: AbstractControl) => {
              return context.validators.get('INSTITUTION_VALIDATION')(
                context.state()
              );
            },
            message: this.i18n.CourseFormCtrl_SelectProvider,
          },
        })
        .asOptional()
        .autofocused()
        .build();
    }
  }

  private getCourseNameField(context: RendererContext): DfFormFieldConfig {
    if (context.inputContext.isEditing) {
      return this.builder
        .requiredTextInput('courseName', 'CourseFormCtrl_CourseTitle')
        .readonlyWhen(() => true)
        .build();
    } else {
      return this.builder
        .customField(
          'courseName',
          'CourseFormCtrl_CourseTitle',
          context.templates.courseTitleSearch
        )
        .asRequired()
        .validatedBy(isEmptyValidator)
        .build();
    }
  }
}
