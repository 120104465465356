import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper.model';
import { BookFormModel } from '../book-forms.model';
import { BookApiEntity } from '../repository/book.entity.model';

@Injectable({ providedIn: 'root' })
export class UserProfileBookMapper implements Mapper<any, BookApiEntity> {
  constructor(private datePipe: DatePipe) {}

  public toApiParameters(source: BookFormModel): BookApiEntity {
    if (!source.book) {
      return;
    }

    const bookEntity: BookApiEntity = {
      userBookId: source.userInputId,
      inputType: source.inputType,
      bookId: source.book.bookId,
      title: source.book.title,
      subtitle: source.book.subtitle,
      externalId: source.book.externalId,
      summary: source.book.description,
      author: source.book.author,
      provider: source.book.providerCode,
      isbn13: source.book.isbn,
      imageUrl: source.book.imageUrl,
      externalData: source.book.externalData,
      format: source.book.format,
      language: source.book.language,
      owner: source.book.owner,
      durationUnitType: source.book.durationUnitType,
      durationUnits: source.completionInfo.length,
      dateRead: this.datePipe.transform(
        source.completionInfo.dateRead,
        'yyyy-MM-dd'
      ),
      authored: source.isUserAuthored,
      comment: source.comment,
      tags: source.tags,
      orgContentMetadata: {
        hideFromCatalog: true, // books are always hidden from the catalog
      },
      organizationId: source.organizationId,
    };

    return bookEntity;
  }
  public toViewModel(source: BookApiEntity): Partial<BookFormModel> {
    const target: Partial<BookFormModel> = {
      inputType: source.inputType,
      userInputId: source.userBookId,
      organizationId: source.organizationId,
      tags: source.tags,
      comment: source.comment,
      completionInfo: {
        length: source.durationUnits,
        dateRead: source.dateRead as string,
      },
      book: {
        bookId: source.bookId,
        accessible: source.accessible,
        author: source.author,
        createdByName: source.createdByName,
        description: source.description,
        durationDisplay: source.durationDisplay,
        durationISO: source.durationISO,
        durationUnitType: source.durationUnitType,
        externalData: source.externalData,
        externalId: source.externalId,
        fileManaged: source.fileManaged,
        format: source.format,
        imageUrl: source.imageUrl,
        inputId: source.inputId,
        inputType: source.inputType,
        isbn: source.isbn,
        language: source.language,
        narrator: source.narrator,
        obsolete: source.obsolete,
        organization: source.organization,
        organizationName: source.organizationName,
        providerCode: source.providerCode,
        owner: source.owner,
        title: source.title,
        subtitle: source.subtitle,
        url: source.url,
        summary: source.summary,
        source: source.source,
        tags: source.tags,
        uid: source.uid,
        durationUnits: source.durationUnits,
      },
    };
    return target;
  }
}
