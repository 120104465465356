<ng-container *ngIf="!inputResource">
  <df-spinner [isSpinning]="true"></df-spinner>
</ng-container>

<ng-container *ngIf="inputResource">
  <div
    class="border-bottom guts-p-v-half m-guts-p-v-half"
    *ngIf="isCredSparkAssessment"
  >
    <div id="credSparkSrc" tabindex="0">
      <div [innerHTML]="credSparkFormat"></div>
    </div>
  </div>

  <div
    [ngClass]="{ 'guts-p-v-1': !isNativeApp }"
    *ngIf="!isCredSparkAssessment"
  >
    <div>
      <dgx-input-details
        [resource]="inputResource"
        [isDetailModal]="false"
      ></dgx-input-details>
    </div>
  </div>

  <!-- POC - Related Content Recommendations   --->
  <!-- should have a skeleton loader -->
  <!-- No LD flag needed on FE, this will only ever return true from the BE based on LD flag -->
  <!-- this styling is real janky because changing the outer wrapper side is in the cshtml file, so not 
       something I'm going to mess around with for the POC -->
  <div
    class="row content-recommendations"
    *ngIf="inputResource.hasRelatedContent && this.relatedContent?.length > 0"
  >
    <div class="content-recommendations-wrapper">
      <div>
        <h3 class="h3 guts-m-t-2 guts-m-b-1">Related Content</h3>
      </div>
      <div class="content-recommendations-cards">
        <dgx-content-card
          class="content-recommendations-card"
          *ngFor="let item of this.relatedContent; index as index"
          [appendMenuToBody]="false"
          [content]="item | camelCase | inputToLearningResource"
          [recommendationInfo]="null"
          [hideFooter]="false"
          [isSelected]="false"
          [isClickable]="false"
          [expandCard]="false"
          [cardIndex]="index"
          data-dgat="input-page-ac4"
        >
        </dgx-content-card>
      </div>
    </div>
  </div>

  <div class="footer-glasses" *ngIf="!isNativeApp"></div>
</ng-container>
