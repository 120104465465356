import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  deleteUndefinedOrNull,
  safeEncodeURIComponent,
} from '@app/shared/utils';

/**
 * Service to provide a simple interface for building search
 * queries w/properly encoded search parameter
 */
@Injectable({
  providedIn: 'root',
})
export class SearchUrlService {
  public static readonly GLOBAL_SEARCH_BASE = '/learning/search';
  public static readonly PEOPLE_SEARCH_BASE = '/learning/people';
  public static readonly PROVIDER_SEARCH_BASE = '/learning/provider';
  public static readonly SKILL_SEARCH_BASE = '/learning/skills';
  public static readonly MENTOR_SEARCH_BASE = '/learning/mentors';
  public static readonly GROUP_SEARCH_BASE = '/learning/groups';
  public static readonly OPPORTUNITY_SEARCH_BASE = '/learning/opportunities';

  /**
   * Get a global search URL.
   */
  public getGlobalSearchURL(config?: string | GlobalSearchUrlParams): string {
    const params = typeof config === 'string' ? { term: config } : config;

    return this.buildURL(SearchUrlService.GLOBAL_SEARCH_BASE, params);
  }

  /**
   * Get a People search URL.
   */
  public getPeopleSearchURL(config?: string | PeopleSearchUrlParams): string {
    const params = typeof config === 'string' ? { term: config } : config;

    return this.buildURL(SearchUrlService.PEOPLE_SEARCH_BASE, params);
  }

  /**
   * Get a Provider search URL.
   */
  public getProviderSearchURL(
    config?: number | ProviderSearchUrlParams
  ): string {
    let params = this.buildProviderParams(config);

    return this.buildURL(SearchUrlService.GLOBAL_SEARCH_BASE, params);
  }

  /**
   * Get a Skill search URL.
   */
  public getSkillSearchURL(term?: string): string {
    const params = { term };

    return this.buildURL(SearchUrlService.SKILL_SEARCH_BASE, params);
  }

  /**
   * Get a Mentor search URL.
   */
  public getMentorSearchURL(term?: string): string {
    const params = { term, filters: 'Mentors:true' };

    return this.buildURL(SearchUrlService.PEOPLE_SEARCH_BASE, params);
  }

  /**
   * Get a Group search URL.
   */
  public getGroupSearchURL(term?: string): string {
    const params = { term };

    return this.buildURL(SearchUrlService.GROUP_SEARCH_BASE, params);
  }

  /**
   * Get a Opportunity search URL.
   */
  public getOpportunitySearchURL(term?: string): string {
    const params = { term };

    return this.buildURL(SearchUrlService.OPPORTUNITY_SEARCH_BASE, params);
  }

  private buildURL(base: string, params: Params = {}): string {
    params = deleteUndefinedOrNull(params);

    // No params? Return base URL
    if (!Object.entries(params).length) {
      return base;
    }

    // Make sure search term is encoded so we don't lose special characters (e.g., `C++`)
    if (params.term) {
      params = {
        ...params,
        term: safeEncodeURIComponent(params.term),
      };
    }

    const httpParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return `${base}?${httpParams}`;
  }

  private buildProviderParams(
    config?: number | ProviderSearchUrlParams
  ): GlobalSearchUrlParams {
    if (typeof config === 'number') {
      return {
        filters: `Provider:${config}`,
      };
    }

    const providerFilter = `Provider:${config.providerId}`;

    const filters = config.filters
      ? `${providerFilter}|${config.filters}`
      : providerFilter;

    // providerId isn't a valid param for global search and we can
    // remove now that it's added to the filters
    delete config.providerId;

    return {
      ...config,
      filters,
    };
  }
}

interface BaseSearchUrlParams {
  term?: string;
}

export interface GlobalSearchUrlParams extends BaseSearchUrlParams {
  orgId?: number;
  filters?: string;
  sort?: string;
  isExternalCat?: boolean;
  viewLearningId?: number;
  is_msteams?: boolean;
}

export interface ProviderSearchUrlParams extends BaseSearchUrlParams {
  providerId?: number;
  orgId?: number;
  filters?: string;
  sort?: string;
  isExternalCat?: boolean;
  is_msteams?: boolean;
}

export interface PeopleSearchUrlParams extends BaseSearchUrlParams {
  orgId?: number;
  isExternalCat?: boolean;
  filters?: string;
}
