import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

// 'upgraded'
import { WindowToken } from '@app/shared/window.token';

// misc
import { OpportunityServiceBase } from './opportunity-service-base';

// services
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';

/**
 * This service is intended to be used for as the most generic form of the
 * service. It *only* has methods from OpportunityServiceBase.
 *
 * @type {OpportunityServiceBase}
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunitiesService extends OpportunityServiceBase {
  constructor(
    authService: AuthService,
    http: NgxHttpClient,
    notifierService: NotifierService,
    pageTitle: Title,
    translateService: TranslateService,
    @Inject(WindowToken) windowRef: Window
  ) {
    super(
      authService,
      http,
      notifierService,
      pageTitle,
      translateService,
      windowRef
    );
  }
}
