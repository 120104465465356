import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

@Component({
  selector: 'dgx-broken-links-scan-completed',
  template: `
    <div [innerHTML]="safeContent"></div>
    <div class="guts-p-t-half color-blue">{{ linkText }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrokenLinksScanCompletedComponent extends NotificationItemComponentBase {
  public linkText = this.itemService.translate.instant(
    'dgOrgInternalContent_ViewBrokenLinks'
  );

  protected buildTemplate() {
    return this.translateHtml('dgOrgInternalContent_ScanCompleteNotification', {
      boldStart: this.html`<b>`,
      boldEnd: this.html`</b>`,
    });
  }
}
