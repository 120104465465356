import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DfButtonsModule } from './buttons/buttons.module';
import { DfInputsModule } from './inputs/inputs.module';
import { DfToggleSwitchModule } from './toggle-switch/toggle-switch.module';
import { DfLabelMarkerModule } from './label-marker';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DfButtonsModule,
    DfInputsModule,
    DfToggleSwitchModule,
    DfLabelMarkerModule,
  ],
  exports: [
    DfButtonsModule,
    DfInputsModule,
    DfToggleSwitchModule,
    DfLabelMarkerModule,
  ],
  declarations: [],
  providers: [],
})
export class DfFormsModule {}
