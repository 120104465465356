import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { DegreeLevel } from '../outcomes.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { BehaviorSubject, Observable } from 'rxjs';

interface DegreeLevelResponse {
  id: number;
  name: string;
  translationKey: string;
  displayOrder: number;
}

@Injectable({
  providedIn: 'root',
})
export class UserOutcomeDegreeService {
  public i18n = this.translateService.instant([
    'UserOutcomeDegreeSvc_AddingCoursesError',
    'UserOutcomeDegreeSvc_PartialAssociates',
    'UserOutcomeDegreeSvc_Associates',
    'UserOutcomeDegreeSvc_PartialBachelors',
    'UserOutcomeDegreeSvc_Bachelors',
    'UserOutcomeDegreeSvc_PartialMasters',
    'UserOutcomeDegreeSvc_Masters',
    'UserOutcomeDegreeSvc_PartialDoctorate',
    'UserOutcomeDegreeSvc_Doctorate',
    'UserOutcomeDegreeSvc_Magister',
    'UserOutcomeDegreeSvc_PreDiploma',
    'UserOutcomeDegreeSvc_Diploma',
  ]);

  constructor(
    private http: NgxHttpClient,
    private translateService: TranslateService
  ) {}

  public getDegreeLevels(): Observable<DegreeLevel[]> {
    return this.http
      .get<DegreeLevelResponse[]>('/degreed/GetDegreeLevels')
      .pipe(
        take(1),
        map((levels) => {
          return levels.reduce((allLevels, level) => {
            const { id, translationKey } = level;
            allLevels.push({ id, name: this.i18n[translationKey] });
            return allLevels;
          }, []);
        })
      );
  }
}
