import { LDFlagsService } from '../ld-flags.service';

export class MentoringLDFlags {
  // Only one org enabled in production - Should be an org setting
  // Description - Whether the new mentorship options are ready.
  public get dcmMentorshipOpportunities(): boolean {
    return this.ldFlagsService.getFlag('dcm-mentorship-opportunities', false);
  }

  constructor(private ldFlagsService: LDFlagsService) {}
}
