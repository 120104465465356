export const valueFromInput = (event: Event) =>
  (event.target as HTMLInputElement).value;

export const checkedFromInput = (event: Event) =>
  (event.target as HTMLInputElement).checked;

export const fileFromInput = (event: Event) =>
  (event.target as HTMLInputElement).files[0];

export function getHexColorFromCSSVariable(variableName) {
  if (
    !variableName ||
    (!variableName.startsWith('var(') && !variableName.startsWith('--'))
  )
    return variableName;

  variableName = variableName.replace(/var\((--[\w-]+)\)/, '$1');

  const computedStyle = getComputedStyle(document.documentElement);
  const colorValue = computedStyle.getPropertyValue(variableName);

  // Check if the color is already in hex format
  if (colorValue.startsWith('#')) {
    return colorValue;
  }

  // Convert RGB/RGBA to hex
  const rgbMatch = colorValue.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/
  );
  if (rgbMatch) {
    const r = parseInt(rgbMatch[1]).toString(16).padStart(2, '0');
    const g = parseInt(rgbMatch[2]).toString(16).padStart(2, '0');
    const b = parseInt(rgbMatch[3]).toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
  }

  // Return the color value as it is not in a recognized format
  return colorValue;
}
