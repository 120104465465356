import { Pipe, PipeTransform } from '@angular/core';
import { pascalCaseKeys } from '@app/shared/utils/property';

/**
 * Transform all of object's keys to pascal case. Useful for interop with AngularJS
 */
@Pipe({ name: 'pascalCase' })
export class PascalCasePipe implements PipeTransform {
  public transform(obj: any) {
    return pascalCaseKeys(obj);
  }
}
