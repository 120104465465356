import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DfIconRegistry,
  DfIconCheckmarkCircle12,
  DfIconCheckmarkCircle16,
  DfIconExclamationCircle12,
  DfIconExclamationCircle16,
  DfIconCrossCircle16,
  DfIconCrossCircle12,
  DfIconInfo16,
  DfIconInfo12,
} from '../icon';

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export const NotificationTypeIconMap = {
  success: 'checkmark-circle',
  warning: 'exclamation-circle',
  error: 'cross-circle',
  info: 'info',
};

export const NotificationSizeIconMap = {
  small: 'small',
  large: 'medium',
};

@Component({
  selector: 'df-local-notification',
  templateUrl: './local-notification.component.html',
  styleUrls: ['./local-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfLocalNotificationComponent {
  @Input() public set icon(icon: string) {
    this._icon = icon;
  }
  @Input() public set size(size: 'small' | 'large') {
    this._size = size;
  }

  public get icon() {
    return this._icon || NotificationTypeIconMap[this.type];
  }
  public get size() {
    return NotificationSizeIconMap[this._size];
  }
  @Input() public type: NotificationType;
  @Input() public text: string;
  public configuredIcon: { icon?: string; size?: string } = {};

  private _icon: string;
  private _size: string;

  constructor(private IconRegistry: DfIconRegistry) {
    this.IconRegistry.registerIcons([
      DfIconCheckmarkCircle12,
      DfIconCheckmarkCircle16,
      DfIconExclamationCircle12,
      DfIconExclamationCircle16,
      DfIconCrossCircle16,
      DfIconCrossCircle12,
      DfIconInfo16,
      DfIconInfo12,
    ]);
  }
}
