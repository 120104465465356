<ng-container *ngIf="!loadingRoles">
  <div
    dgxSticky
    [notStickyMobile]="true"
    [stickyFullWidth]="true"
    [stickyCustomClass]="'box-shadow bg-color-white'"
    class="dg-sticky border-bottom bg-color-white"
  >
    <div class="grid center-item">
      <div
        class="
          grid__col-sm-8
          font-medium
          l_flex-center
          grid--align-start
          guts-p-l-0
          m-guts-p-l-1
        "
      >
        <h3 class="h3" id="permissions">
          {{ i18n.Core_Permissions }}
        </h3>
      </div>
      <div
        [id]="getRoleI18nKey(role.name)"
        *ngFor="let role of roles | orderBy: 'name'"
        class="grid__col-sm-2 font-medium no-mobile l_flex-center center-text"
        data-dgat="groupSecurity-df4"
      >
        {{ getRoleLabel(role.name) }}
      </div>
    </div>
  </div>

  <ng-container
    *ngFor="let availablePermission of availablePermissions; let $index = index"
    data-dgat="group-security-f41"
  >
    <div
      *ngIf="availablePermission.name && roles"
      class="grid m-guts-p-b-2"
      [ngClass]="{
        'bg-color-ebony-a03': availablePermission?.nested,
        'border-bottom': !availablePermission?.nested,
        'border-top':
          !availablePermission?.nested &&
          availablePermissions[$index - 1]?.nested
      }"
      data-dgat="groupSecurity-bde"
    >
      <div
        class="
          grid__col-12 grid__col-sm-8
          guts-p-l-0
          m-guts-p-l-1 m-guts-p-t-2 m-guts-p-b-0
        "
        [ngClass]="{ 'guts-p-l-3 m-guts-p-l-1': availablePermission?.nested }"
      >
        <dgx-toggle-element
          [buttonText]="getPermissionLabel(availablePermission.name)"
          class="m-guts-p-b-1"
          aria-labelledby="permissions"
        >
          <span class="color-ebony-a61 zeta">{{
            getPermissionDescription(availablePermission.name)
          }}</span>
        </dgx-toggle-element>
      </div>
      <div
        *ngFor="let role of roles | orderBy: 'name'"
        class="
          grid__col-12 grid__col-sm-2
          l_flex-center
          center-text
          m-left-text m-guts-p-v-0
          font-medium
        "
        data-dgat="groupSecurity-2df"
      >
        <ng-container
          *ngFor="let permission of role.permissions"
          data-dgat="group-security-8fe"
        >
          <div
            *ngIf="
              permission.name === availablePermission.name &&
              availablePermission.id !== 'CreateGroups'
            "
            data-dgat="groupSecurity-1aa"
          >
            <label [title]="getPermissionDescription(permission.name)">
              <input
                [disabled]="isPermissionDisabled(role.roleId, permission)"
                type="checkbox"
                [(ngModel)]="permission.assigned"
                [attr.data-dgat]="
                  getPermissionDgat(role.roleId, permission.permissionId)
                "
                [attr.aria-labelledby]="getRoleI18nKey(role.name)"
              />
              <span class="a11y-hide mobile-only ib">{{
                getRoleLabel(role.name)
              }}</span>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div class="l_flex l_flex-justify guts-p-t-1-half">
    <div>
      <ng-container
        *ngFor="let role of roles | orderBy: 'name'"
        data-dgat="group-security-dd2"
      >
        <div
          *ngIf="role.isDefault || role.description"
          data-dgat="groupSecurity-973"
        >
          <p class="par par--light par--small">
            {{ getDefaultRoleDescription(role.name) }}
          </p>
        </div>
      </ng-container>
    </div>

    <div>
      <button
        *ngIf="!isAdministrativeGroup && !saving"
        df-button
        dfButtonType="primary"
        (click)="save()"
        data-dgat="groupSecurity-bef"
      >
        {{ i18n.Core_Save }}
      </button>

      <button
        *ngIf="saving"
        type="button"
        class="btn is_disabled"
        data-dgat="groupSecurity-789"
      >
        {{ i18n.Core_Saving }}
      </button>
    </div>
  </div>
</ng-container>
