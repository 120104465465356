<div class="profile-pic__container">
  <div
    class="profile-pic"
    #profilePic
    [attr.role]="
      shouldRouteToProfile && shouldPreventEngagedLearnerModal
        ? 'link'
        : shouldPreventEngagedLearnerModal
          ? 'presentation'
          : 'button'
    "
    [attr.aria-owns]="
      !shouldRouteToProfile && !shouldPreventEngagedLearnerModal
        ? 'engagedLearnerModal'
        : null
    "
    [class.profile-pic--fallback]="useFallbackClass && fallbackLoaded"
    [ngStyle]="{
      width: sizeScalar / 12 + 'rem',
      height: sizeScalar / 12 + 'rem',
    }"
    [attr.aria-label]="ariaLabelForProfilePic | translate: { profileName }"
    [ngbTooltip]="engagedLearnerTooltip"
    [disableTooltip]="shouldPreventEngagedLearnerModal"
    [autoClose]="false"
    triggers="manual"
    #tooltip="ngbTooltip"
    (mouseenter)="tooltip.open()"
    (mouseleave)="checkHover($event)"
    (focus)="tooltip.open()"
    (blur)="tooltip.close()"
    (click)="onClickOfProfilePic($event)"
    (keydown)="keydownHandler($event)"
    [tabindex]="tabIndexOfProfilePic"
  >
    <!-- Removed for early adopter testing -->
    <dgx-profile-engagement
      *ngIf="isEngaged"
      [size]="sizeScalar"
    ></dgx-profile-engagement>

    <ng-template [ngTemplateOutlet]="profileImage"></ng-template>
  </div>

  <div
    *ngIf="canViewMentorship"
    class="profile-pic__mentor-image"
    [ngClass]="baseMentorImageSizeClass + mentorImageSize"
  >
    <df-icon
      icon="mentor"
      class="profile-pic__mentor-icon color-blue"
      [size]="mentorImageSize"
    ></df-icon>
  </div>
</div>

<ng-template #engagedLearnerTooltip>
  <span
    *ngIf="!shouldPreventEngagedLearnerModal"
    class="cursor-pointer no-wrap"
    (click)="showEngagedLearnerDetails($event)"
    (mouseleave)="closeTooltip()"
    (mouseenter)="openTooltip()"
    >{{ 'Core_ActiveLearner' | translate }}
    <df-icon icon="question-mark-circle"></df-icon
  ></span>
</ng-template>

<ng-template #profileImage>
  <img
    *ngIf="!isLoading && !useLearnerHubFallback"
    [alt]="altText"
    aria-labelledby="profileImageA11yText"
    [class]="'profile-pic__image ' + size"
    [src]="(fallbackLoaded ? fallbackImage : image) | safe: 'resourceUrl'"
    (error)="onFallback()"
    loading="auto"
  />
  <div
    *ngIf="tabIndexOfProfilePic === 0"
    id="profileImageA11yText"
    class="a11y-hide"
  >
    {{
      profile?.name ||
        profile?.title ||
        profile?.firstName + ' ' + profile?.lastName
    }}
  </div>
  <div
    *ngIf="!isLoading && useLearnerHubFallback"
    class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-purple-300 tw-bg-purple-100 tw-text-purple-800"
  >
    <!-- Icon: User -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="tw-h-3/5 tw-w-3/5"
      [class]="'profile-pic__image ' + size"
    >
      <path
        fill-rule="evenodd"
        d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
</ng-template>
