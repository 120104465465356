/**
 * Takes an array and a key to group on, and will return an object where the properties are the
 * values of the grouping matches, and the values are arrays of objects that have the same value.
 *
 * @example
 * ```
 * groupBy([{firstName: 'Fred', lastName: 'Flintstone'}, {firstName: 'Fred', lastName: 'Savage'}, {firstName: 'Wilma', lastName: 'Flintstone'}], 'lastName')
 * // { Flintstone: [{firstName: 'Fred', lastName: 'Flintstone'}, {firstName: 'Wilma', lastName: 'FlintStone'}], Savage: [{firstName: 'Fred', lastName: 'Savage'}]}
 * ```
 *
 * @param arr the array to group on
 * @param key the property of the object to group on
 * @returns object containing groupings
 */
export function groupBy<T>(arr: T[], key: string): { [key: string]: T[] } {
  return arr.reduce((all, current) => {
    (all[current[key]] = all[current[key]] || []).push(current);
    return all;
  }, {});
}
