import { PaginationProps } from '@dg/shared-rsm';
import {
  AssignmentsState,
  SearchAssignmentOptions,
} from '../profile-assignments.model';
import { AppliedSearchFacet } from '@app/search/components/search-view/search-reactive-store/models/search.model';

export const PAGINATION_LIMIT = 10;

export function initAssignmentsState(): AssignmentsState {
  return {
    term: undefined,
    sort: { sortBy: 'dueDate', sortDir: 'asc' },
    results: [],
    assignmentType: 'incomplete',
    facets: [],
    pagination: {
      currentPage: 0,
      perPage: 0,
      total: 0,
      lastPage: 0,
      start: 0,
      end: 0,
    } as PaginationProps,
  };
}

export function stateToAssignmentOptions(
  state: AssignmentsState,
  appliedFacets?: AppliedSearchFacet[]
): SearchAssignmentOptions {
  const { currentPage, perPage } = state.pagination;
  return {
    page: currentPage || 1,
    count: perPage,
    sortBy: state.sort.sortBy,
    sortDir: state.sort.sortDir,
    completed: state.assignmentType === 'complete',
    term: state.term,
    type: state.assignmentType,
    facets: appliedFacets,
  };
}

/**
 * Special partial application to easily modify AssignmentsState with partial overrides.
 *
 * Note: this is called with `this._store.getState(mergeOverrides(modified))`
 */
export function mergeOverrides({
  pagination,
  ...others
}: Partial<AssignmentsState>) {
  return (state: AssignmentsState): AssignmentsState => {
    const updated = { ...state, ...others };
    updated.pagination = { ...updated.pagination, ...pagination };

    return updated;
  };
}

/**
 * Returns the difference between two dates
 * For example, if startDate is 1/1/2020 and endDate is 1/3/2020, the difference is 2 days
 * The method within the date range service will return 3 for the same parameters
 */
export function getDaysDifference(startDate: Date, endDate: Date): number {
  // Convert both dates to UTC to avoid issues with daylight saving time
  const utcStartDate = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const utcEndDate = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );

  // Calculate the difference in milliseconds
  const diffInMilliseconds = Math.abs(utcEndDate - utcStartDate);

  // Convert milliseconds to days
  return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
}
