import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import { coerceBooleanProperty } from '@angular/cdk/coercion';

/** A simple checkbox component.
 * @description This component works like a directive as an attribute on an input element. Note
 * that while the 'type="input"' attribute is automatically added if omitted, in order to use
 * with a [formControl] directive the type must be explicitly specified.
 */
@Component({
  /* eslint-disable-next-line @angular-eslint/component-selector */
  selector: 'input[df-checkbox]',
  template: '',
  styleUrls: ['./checkbox.component.scss'],
  /* eslint-disable-next-line @angular-eslint/no-host-metadata-property */
  host: {
    type: 'checkbox', // Enforce input type=checkbox
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfCheckboxComponent implements OnChanges {
  @HostBinding('type')
  public readonly type = 'checkbox'; // set as checkbox type if not already

  @HostBinding('indeterminate')
  @Input()
  public set indeterminate(value) {
    if (value !== this._indeterminate) {
      this._indeterminate = coerceBooleanProperty(value); // allow valueless attribute presence to mean 'true'
    }
  }

  public get indeterminate() {
    return this._indeterminate;
  }

  @HostBinding('attr.checked') // pick up initial declarative checked state
  @Input()
  public set checked(value: any) {
    if (value !== this._checked) {
      this._checked = coerceBooleanProperty(value); // allow valueless attribute presence to mean 'true'
    }
  }

  public get checked() {
    return this._checked;
  }

  @Output() public indeterminateChange = new EventEmitter<boolean>();
  private _checked = false;
  private _indeterminate = false;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  public ngOnChanges({ indeterminate }: SimpleChanges) {
    if (indeterminate) {
      // test the coerced current value, not the bound value
      if (this._indeterminate) {
        this.addIndeterminate();
      } else {
        this.removeIndeterminate();
      }
    }
  }

  @HostListener('change')
  public onValueChange(event: Event) {
    this.indeterminate = false;
    this.removeIndeterminate();
  }

  private addIndeterminate() {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'indeterminate',
      undefined
    );
    this.indeterminateChange.emit(true);
  }

  private removeIndeterminate() {
    this.renderer.removeAttribute(
      this.elementRef.nativeElement,
      'indeterminate'
    );
    this.indeterminateChange.emit(false);
  }
}
