import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'dgx-view-collaborators-modal',
  templateUrl: './view-collaborators-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewCollaboratorsModalComponent {
  @Input() users;
}
