import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a new skills provider added  notification */
@Component({
  selector: 'dgx-new-skills-provider-added',
  template: `
    <div class="guts-p-b-1" [innerHTML]="safeContent"></div>
    <dgx-tag-provider-logo
      [src]="integationIcon"
      [providerName]="notification.parameters.providerName"
      class="block guts-m-r-half"
      size="xs"
    ></dgx-tag-provider-logo>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewSkillsProviderAddedComponent extends NotificationItemComponentBase {
  public get integationIcon() {
    return `/content/img/app/tag-ratings/${this.params.providerName.toLowerCase()}.svg`;
  }

  protected buildTemplate() {
    return this.translateHtml('Notification_NewSkillsProviderAdded', {
      nameHtml: this.userFullNameTemplate,
      organizationName: this.params.organizationName,
      providerName: this.params.providerName,
    });
  }
}
