import { TagsApi } from '@app/tags/tag-api.model';

/**
 * Convert the inferred string[] into a partial TagsApi.Tag object.
 * BE is only passing name, this is needed for the tag-rendered. This is later mapped back to name as the BE needs
 * at least name to construct a full Tag object once received.
 * @param skills
 */
export const getMappedInferredSkills = (skills: string[]): TagsApi.Tag[] => {
  if (!skills?.length) return [];
  return skills.map(
    (skillName) => ({ title: skillName, name: skillName }) as TagsApi.Tag
  );
};

/**
 * Returns a list of skills that have been added from the DS Medium confidence skills.
 *
 * @param tags List of tags selected
 * @param mediumConfidence medium Confidence DS Skills
 */
export const getDSSkillsAdded = (
  tags: TagsApi.Tag[],
  mediumConfidence: TagsApi.Tag[]
): string[] => {
  const itemsAdded = !tags?.length
    ? []
    : mediumConfidence?.filter((item1) =>
        tags.some((item2) => item1.title === item2.title)
      );
  return itemsAdded?.map((item) => item.name);
};

/**
 * Returns list of pre-selected highConfidence skills that have been removed from skills.
 *
 * @param tags List of tags selected
 * @param highConfidence high Confidence DS Skills
 */
export const getDSSkillsRemoved = (
  tags: TagsApi.Tag[],
  highConfidence: TagsApi.Tag[]
): string[] => {
  highConfidence = highConfidence?.length ? highConfidence : [];
  const itemsRemoved = tags?.length
    ? highConfidence.filter(
        (item1) => !tags.some((item2) => item1.title === item2.title)
      )
    : highConfidence.length
      ? highConfidence
      : [];
  return itemsRemoved.map((item) => item.name);
};

/**
 * Returns a list of all inferred skills suggestions suggested to the user
 *
 * @param mediumConfidence medium Confidence DS Skills
 */
export const getDSSkillsSuggested = (
  mediumConfidence: TagsApi.Tag[]
): string[] => {
  mediumConfidence = mediumConfidence?.length ? mediumConfidence : [];

  return mediumConfidence.map((tag) => tag.name);
};
