<div [ngClass]="{ 'rel field-group': canCopyToClipboard }">
  <span [ngClass]="{ 'field-group__input': canCopyToClipboard }"
    ><input
      type="text"
      value="{{ url }}"
      class="monospace par--small readonly-ebony bg-color-ebony-a03"
      readonly
      [attr.aria-label]="inputLabel"
      data-dgat="copyLink-3ba"
  /></span>
  <span class="field-group__btn" *ngIf="canCopyToClipboard">
    <button
      df-button
      dfButtonType="passive"
      (click)="copy(copyTextTooltip, url)"
      [ngbTooltip]="tooltipText"
      #copyTextTooltip="ngbTooltip"
      triggers="manual"
      data-dgat="copyLink-9d0"
      (blur)="copyTextTooltip.close()"
    >
      {{ buttonLabel }}
    </button>
  </span>
</div>
