import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { camelCaseKeys } from '@app/shared/utils/property';
import { UserProfile } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component provides a simple user avatar w/name and a remove button.
 */
@Component({
  selector: 'dgx-removable-user-item',
  templateUrl: './removable-user-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemovableUserItemComponent implements OnInit {
  private _user: UserProfile;
  @Input() public set user(user: UserProfile) {
    this._user = camelCaseKeys(user);
  }
  public get user(): UserProfile {
    return this._user;
  }
  @Output() public remove?: EventEmitter<any> = new EventEmitter();

  public isRemovable: boolean = false;
  public i18n = this.translateService.instant(['Core_Remove']);

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.isRemovable = this.remove?.observers.length > 0;
  }

  get cancelButtonAriaLabel(): string {
    return `${this.i18n.Core_Remove} ${this.user.name}`;
  }

  public onRemove(): void {
    this.remove.emit();
  }
}
