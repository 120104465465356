import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  DegreeFormDataModel,
  DegreeMappingToAPI,
  DegreeModel,
} from '@app/user-content/user-outcome-v2/outcomes/degree/degree.model';

@Injectable()
export class DegreeMapperService {
  constructor(public datePipe: DatePipe) {}

  public toApiParameters(source: DegreeModel): DegreeMappingToAPI {
    const {
      country,
      endDate,
      degree,
      gpa,
      imageUrl,
      isInternational,
      college,
      skills,
      title,
      userOutcomeId,
    } = source;

    return {
      contentTypeId: 'Degree',
      details: {
        country,
        GPA: gpa,
        international: isInternational,
      },
      endDate: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      imageUrl,
      level: degree,
      source: college,
      tags: skills,
      title,
      userOutcomeId,
    };
  }

  public toViewModel(source: DegreeMappingToAPI): DegreeFormDataModel {
    const {
      details,
      endDate,
      imageUrl,
      level,
      source: college,
      tags,
      title,
      userOutcomeId,
    } = source;

    return {
      country: details ? details.country : null,
      degree: level,
      endDate: endDate ? new Date(endDate) : null,
      gpa: details ? details?.GPA : null,
      imageUrl,
      isInternational: details ? details?.international : false,
      college,
      skills: tags,
      title,
      userOutcomeId,
    };
  }
}
