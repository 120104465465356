import { Pipe, PipeTransform } from '@angular/core';
import { TagsApi } from '../tag-api.model';

/**
 * Given an array of user tag ratings, return array of incomplete ratings
 *
 * @param ratings TagsApi.UserTagRatingDetails[]
 * @returns TagsApi.UserTagRatingDetails[]
 */

@Pipe({
  name: 'incompleteTagRatings',
})
export class IncompleteTagRatingsPipe implements PipeTransform {
  public transform(
    ratings: TagsApi.UserTagRatingDetails[]
  ): TagsApi.UserTagRatingDetails[] {
    return !!ratings?.length ? ratings.filter((rating) => !rating.level) : [];
  }
}
