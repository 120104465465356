import { Injectable } from '@angular/core';
import { ResourceType, TargetType } from '@app/shared/models/core-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { getDeepCopy } from '@app/shared/utils/property';
import { AddEditTargetModal } from '@app/target/components/modals/add-edit-target-modal/add-edit-target-modal.component';
import { SharedTargetService } from '@app/target/services/shared-target.service';
import {
  GetTargetResponse,
  ResourceReference,
  Target,
  TargetCompatibility,
  TargetSettingsFormMode,
  TargetSettingsFormResult,
  TargetSuggestionType,
} from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { AddToTargetModalComponent } from '../components/modals/add-to-target-modal/add-to-target-modal.component';

/**
 * Intended to house methods for a *single* target.
 * See: {@link SharedTargetService}, {@link TargetsService}.
 *
 * TODO: Clean this up and straighten out the differences
 * between the services; there are currently methods here
 * that are duplicated in TargetsService.
 */
@Injectable({
  providedIn: 'root',
})
export class TargetService {
  private _targetResponse: GetTargetResponse;

  constructor(
    private http: NgxHttpClient,
    private focusStackService: FocusStackService,
    private sharedTargetService: SharedTargetService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private modalService: ModalService
  ) {}

  public get targetResponse(): GetTargetResponse {
    return this._targetResponse;
  }
  public set targetResponse(targetResponse: GetTargetResponse) {
    this._targetResponse = targetResponse;
  }

  public get isTargetOrgManaged(): boolean {
    return !!this._targetResponse?.target.organizationId;
  }

  public getCurrentTargetId(): number {
    return this.targetResponse.target.targetId;
  }

  public getTargetCompatibility(targetType: TargetType) {
    return targetType === 'Directory'
      ? TargetCompatibility.Directory
      : TargetCompatibility.Plan;
  }

  /*
   * Compare to:
   * {@link TargetsService.showTargetModal}
   */
  public showTargetModal({
    target,
    loadTarget,
    mode,
    context,
    isChild,
    isAddToTarget,
  }: {
    target: Partial<Target>;
    loadTarget?: boolean;
    mode?: TargetSettingsFormMode;
    context?: string;
    isChild?: boolean;
    isAddToTarget?: boolean;
  }): Observable<TargetSettingsFormResult> {
    return this.modalService.show(AddEditTargetModal, {
      inputs: {
        resolve: {
          mode: mode || 'new',
          targetSource: target,
          loadTarget: loadTarget,
          context: context || 'default',
          orgId: target.organizationId,
          isChild,
          isAddToTarget,
        },
      },
    });
  }

  /**
   * Show a modal to add an input to a target.
   *
   * @param type - The type of content being added to the target.
   * @param resource - The original resource.
   * @param element - The element to return focus to.
   *
   * TODO: Set the correct type of the resource PD-69881
   */
  public showAddToTargetModal(
    type: ResourceType | TargetSuggestionType,
    resource: any,
    element?: HTMLElement
  ) {
    return this.modalService
      .show(AddToTargetModalComponent, {
        inputs: {
          type,
          resource,
        },
      })
      .pipe(
        // handle focus
        finalize(() => {
          if (element) {
            this.focusStackService.push(element);
          }
        })
      );
  }

  /**
   * Compare to:
   * {@link TargetsService.showTargetCloneModal}
   */
  public showTargetCloneModal({
    target,
    loadTarget,
    context,
    navigateTo,
  }: {
    target: Target;
    loadTarget?: boolean;
    context?: string;
    navigateTo?: boolean;
  }) {
    const resolve = {
      mode: 'clone',
      targetSource: target,
      loadTarget: loadTarget,
      context: context || 'default',
    };
    const modalOptions = {
      inputs: {
        resolve,
      },
    };
    return this.modalService.show(AddEditTargetModal, modalOptions);
  }

  /**
   * Compare to:
   * {@link TargetsService.showTargetDeleteModal}
   */
  public showTargetDeleteModal({
    targetTitle,
    target,
    sourceEventTarget,
    errorOnDismiss = false,
  }: {
    targetTitle: string;
    target: Target;
    sourceEventTarget: any; // ModalSourceTarget;
    errorOnDismiss?: boolean;
  }): Observable<any> {
    const deletePhrase = 'DELETE';

    const title = this.translate.instant('TargetCtrl_DeletePrompt', {
      targetTitle: targetTitle,
    });

    const deleteInstructions = this.translate.instant(
      'TargetCtrl_DeleteInstructions',
      { delete: deletePhrase }
    );

    const description = this.translate.instant(
      'TargetCtrl_DeletePromptDescription'
    );
    const confirmDeleteButton = this.translate.instant(
      'TargetCtrl_ConfirmDeleteButton'
    );

    const deleteModalInputs = {
      title,
      description,
      deleteInstructions,
      confirmButtonText: confirmDeleteButton,
      deletePhrase,
    };

    this.focusStackService.push(sourceEventTarget);
    return this.modalService
      .showDeleteConfirmation(deleteModalInputs, { errorOnDismiss })
      .pipe(switchMap(() => this.sharedTargetService.deleteTarget(target)));
  }

  public addTargetResources(
    targetId: number,
    targetName: string,
    targetType: TargetType | TargetSuggestionType,
    sectionNode: string,
    resources: ResourceReference[],
    element?: HTMLElement
  ) {
    const eventTargetType =
      this.sharedTargetService.getTargetTypeForEvents(targetType);
    return this.http
      .post<any[]>('/targets/addtargetresources', {
        TargetId: targetId,
        SectionNode: sectionNode,
        Resources: resources,
      })
      .pipe(
        tap((response) => {
          const trackEvents = [];
          for (const resource of resources) {
            const properties = getDeepCopy(resource) as any;
            properties.category = 'Plans';
            properties.TargetId = targetId;
            properties.Name = targetName;
            const eventName =
              resource.referenceType + ' Added To ' + eventTargetType;
            const trackEvent = {
              element,
              eventName: eventName,
              properties: properties,
            };
            trackEvents.push(trackEvent);
          }
          this.tracker.trackBatchEvents(trackEvents);
        }),
        catchError((error) =>
          throwError(
            new DgError(
              this.translate.instant('TargetsSvc_AddResourceError', {
                targetType: this.getTypeDisplayName('JobRole').toLowerCase(),
              }),
              error
            )
          )
        )
      );
  }

  private getTypeDisplayName(
    type: TargetType | TargetSuggestionType,
    plural?: boolean,
    specific?: boolean
  ) {
    let displayType = type.replace(/\s/g, '');

    if (type === 'Browse') {
      displayType = 'Featured';
      specific = true;
    }
    return this.translate.instant(
      'TargetsSvc_Type' +
        displayType +
        (plural ? 's' : '') +
        (specific ? '_Specific' : '')
    );
  }
}
