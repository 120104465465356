import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlightSearchText' })
export class HighlightSearchTextPipe implements PipeTransform {
  public transform(inputVal: string, searchText: string) {
    if (!searchText) {
      return inputVal;
    }
    // Escape so things like "C++" don't throw for the RegExp
    const escaped = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return inputVal.replace(new RegExp(escaped, 'gi'), '<strong>$&</strong>');
  }
}
