import { InternalTagRatingTypes } from '@app/tags/tags';
import { Pipe, PipeTransform } from '@angular/core';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'tagRatingType',
})
export class TagRatingTypePipe implements PipeTransform {
  private i18n: { [key: string]: string } = {};

  constructor(private translateService: TranslateService) {
    this.i18n = this.translateService.instant([
      'dgTagRating_SkillCertification',
      'dgTagRating_Evaluation',
      'dgTagRating_ManagerAssessment',
      'dgTagRating_PeerRatings',
      'dgTagRating_SelfAssessment',
      'PersonalSkillView_SkillTarget',
      'dgTagRating_Checkpoints',
    ]);
  }

  public transform(rating: Partial<TagsApi.UserTagRatingDetails>): string {
    if (!rating?.type) {
      return null;
    }
    switch (rating.type) {
      case InternalTagRatingTypes.credential:
        return this.i18n.dgTagRating_SkillCertification;
      case InternalTagRatingTypes.evaluation:
        return this.i18n.dgTagRating_Evaluation;
      case InternalTagRatingTypes.manager:
        return this.i18n.dgTagRating_ManagerAssessment;
      case InternalTagRatingTypes.peer:
        return this.i18n.dgTagRating_PeerRatings;
      case InternalTagRatingTypes.self:
        return this.i18n.dgTagRating_SelfAssessment;
      case InternalTagRatingTypes.target:
        return this.i18n.PersonalSkillView_SkillTarget;
      case InternalTagRatingTypes.checkpoint:
        return this.i18n.dgTagRating_Checkpoints;
      default:
        return rating.isInternal ? rating.type : rating.providerName;
    }
  }
}
