import { NgModule } from '@angular/core';

// Modules
import { CommonModule } from '@angular/common';

/**
 * Translation related services
 */
@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class TranslationModule {}
