<div class="guts-m-b-5" *ngIf="vm$ | async as vm">
  <div class="grid__col-12" *ngIf="vm.selectedPlans?.length === 0">
    <div class="l_flexbar m-l_flexbar-col">
      <!-- Search -->
      <dgx-data-search
        [placeholder]="i18n.dgManagePathways_SearchPlaceholder"
        [searchTerm]="vm.term"
        type="short"
        class="m-full-width m-guts-m-b-1"
        (doSearch)="vm.search($event)"
      ></dgx-data-search>
      <dgx-data-filters
        [applyOnClose]="false"
        [filters]="vm.filters"
        (filtersChange)="vm.updateFilters($event.filters)"
        class="guts-m-l-1 m-full-width m-guts-m-l-0"
      >
      </dgx-data-filters>
      <!-- actions, from right to left -->
      <div class="l_flex--align-right m-guts-p-full-0 m-guts-m-full-0">
        <button
          df-button
          dfButtonType="primary"
          (click)="showAddModal()"
          data-dgat="orgPages-cc0"
          *ngIf="enableCreatePlan"
        >
          <div class="l_flexbar gap-full-half">
            <df-icon icon="plus"></df-icon>
            <div>{{ 'OrgPlans_CreateTargetOrDirectory' | translate }}</div>
          </div>
        </button>
      </div>
    </div>
  </div>

  <div class="grid__col-12 guts-p-t-2" *ngIf="vm.selectedPlans?.length > 0">
    <dgx-data-bulk-actions
      class="full-width m-guts-m-h-1"
      [numItemsForAction]="vm.selectedPlans?.length"
      (bulkActionsCancelled)="clearSelections()"
    >
      <!-- delete -->
      <dgx-data-bulk-action-button
        actionIcon="trash"
        [displayText]="'OrgPathwayManagerCtrl_Delete' | translate"
        (buttonAction)="showBulkDeleteModal()"
        dgatInput="bulkActions-adf"
      ></dgx-data-bulk-action-button>
      <!-- add collaborators -->
      <dgx-data-bulk-action-button
        actionIcon="person-triple"
        dgatInput="bulkActions-adf"
        [displayText]="'OrgPathwayManagerCtrl_AddCollaborators' | translate"
        (buttonAction)="showBulkCollaboratorsModal()"
      ></dgx-data-bulk-action-button>
      <!-- visibility -->
      <dgx-data-bulk-action-button
        actionIcon="eye-open"
        dgatInput="bulkActions-adf"
        [displayText]="'OrgPathwayManagerCtrl_UpdateVisibility' | translate"
        (buttonAction)="showBulkVisibilityModal()"
      ></dgx-data-bulk-action-button>
    </dgx-data-bulk-actions>
  </div>

  <dgx-data-table
    [canSelect]="true"
    [canSort]="true"
    [columns]="columns"
    [isLoading]="vm.isLoading"
    [isSorting]="vm.isLoading"
    [hasMoreItems]="vm.paging.hasMoreItems"
    [hasNoResults]="!vm.paging.hasMoreItems && vm.allPlans?.length === 0"
    [isDescending]="vm.ordering.isDescending"
    [items]="vm.allPlans"
    [readOnlyItems]="true"
    [menuSettings]="actions"
    [orderBy]="vm.ordering.orderBy"
    propTrackingKey="targetId"
    [useInfiniteScroll]="true"
    (updateSort)="vm.updateSort($event)"
    (loadMoreItems)="vm.loadMore()"
    (select)="selectPlan($event)"
    (bulkSelect)="bulkSelect($event)"
  ></dgx-data-table>

  <dgx-footer-glasses
    *ngIf="!vm.isLoading && !!vm.moreItemsAvailable"
  ></dgx-footer-glasses>
</div>

<ng-template #titleColumn let-item>
  <dgx-plan-link [plan]="item" [autoPopulate]="false"></dgx-plan-link>
</ng-template>
<ng-template #subtitleColumn let-item>
  {{ item.subtitle }}
</ng-template>
<ng-template #targetTypeColumn let-item>
  {{ item.displayTargetType }}</ng-template
>
<ng-template #visibilityColumn let-item>
  {{ 'OrgPathwayManagerCtrl_Visibility' + item.privacyId | translate }}
</ng-template>
<ng-template #followersColumn let-item>
  {{ item.followerCount }}
</ng-template>
<ng-template #authorsColumn let-item>
  <dgx-authors-list [authored]="item.authored"></dgx-authors-list>
</ng-template>
