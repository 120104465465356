import { Injectable } from '@angular/core';
import { SelectionRange } from '@app/markdown/services/textarea.service';
import { Newlines, StyleArgs, ToolbarConfig } from './markdown-editor.model';

@Injectable({
  providedIn: 'root',
})
export class MarkdownEditorService {
  private toolbarConfig: ToolbarConfig = [
    [
      {
        type: 'bold',
        icon: 'bold',
        style: { prefix: '**', suffix: '**', trimFirst: true },
      },
      {
        type: 'italic',
        icon: 'italic',
        style: { prefix: '_', suffix: '_', trimFirst: true },
      },
    ],
    [
      {
        type: 'addImage',
        icon: 'image-plus',
        style: {
          prefix: '![',
          suffix: '](url)',
          surroundWithNewlines: true,
        },
      },
      {
        type: 'link',
        icon: 'chain',
        style: {
          prefix: '[',
          suffix: '](url)',
          replaceNext: 'url',
          scanFor: 'https?://',
        },
      },
    ],
    [
      {
        type: 'ul',
        icon: 'bulleted-list',
        style: { prefix: '- ', multiline: true },
      },
      {
        type: 'ol',
        icon: 'numbered-list',
        style: {
          prefix: '1. ',
          multiline: true,
          orderedList: true,
        },
      },
      {
        type: 'quote',
        icon: 'quote',
        style: { prefix: '> ', multiline: true },
      },
    ],
  ];

  private styleDefaults: StyleArgs = {
    prefix: '',
    suffix: '',
    multiline: false,
    replaceNext: '',
    scanFor: '',
    surroundWithNewlines: false,
    orderedList: false,
    trimFirst: false,
  };

  constructor() {}

  public getStyleDefaults = () => {
    return this.styleDefaults;
  };

  public getToolbarConfig = () => {
    return this.toolbarConfig;
  };

  public orderedList = (
    textarea: HTMLTextAreaElement,
    arg: StyleArgs
  ): SelectionRange => {
    const { surroundWithNewlines } = arg;
    const orderedListRegex = /^\d+\.\s+/;
    let text = textarea.value.slice(
      textarea.selectionStart,
      textarea.selectionEnd
    );
    let selectionStart = textarea.selectionStart;
    let selectionEnd = textarea.selectionEnd;
    let lines = text.split('\n');

    const undoStyling = lines.every((line) => orderedListRegex.test(line));

    if (undoStyling) {
      lines = lines.map((line) => line.replace(orderedListRegex, ''));
      text = lines.join('\n');
    } else {
      lines = (() => {
        let i: number;
        let len: number;
        let index: number;
        const results = [];
        for (index = i = 0, len = lines.length; i < len; index = ++i) {
          const line = lines[index];
          results.push(`${index + 1}. ${line}`);
        }
        return results;
      })();

      text = lines.join('\n');
      if (surroundWithNewlines) {
        const { newlinesToAppend, newlinesToPrepend } =
          this.newlinesToSurroundSelectedText(textarea);
        selectionStart = textarea.selectionStart + newlinesToAppend.length;
        selectionEnd = selectionStart + text.length;
        text = newlinesToAppend + text + newlinesToPrepend;
      }
    }

    return { text, selectionStart, selectionEnd };
  };

  public multilineStyle = (textarea: HTMLTextAreaElement, arg: StyleArgs) => {
    const { prefix, suffix, surroundWithNewlines } = arg;
    let text = textarea.value.slice(
      textarea.selectionStart,
      textarea.selectionEnd
    );
    let selectionStart = textarea.selectionStart;
    let selectionEnd = textarea.selectionEnd;
    const lines = text.split('\n');
    const undoStyle = lines.every(
      (line) => line.startsWith(prefix) && line.startsWith(suffix)
    );

    if (undoStyle) {
      text = lines
        .map((line) => line.slice(prefix.length, line.length - suffix.length))
        .join('\n');
      selectionEnd = selectionStart + text.length;
    } else {
      text = lines.map((line) => prefix + line + suffix).join('\n');
      if (surroundWithNewlines) {
        const { newlinesToAppend, newlinesToPrepend } =
          this.newlinesToSurroundSelectedText(textarea);
        selectionStart += newlinesToAppend.length;
        selectionEnd = selectionStart + text.length;
        text = newlinesToAppend + text + newlinesToPrepend;
      }
    }

    return { text, selectionStart, selectionEnd };
  };

  public blockStyle = (
    textarea: HTMLTextAreaElement,
    arg: StyleArgs
  ): SelectionRange => {
    let newlinesToAppend: string;
    let newlinesToPrepend: string;

    const { prefix, suffix, replaceNext, scanFor, surroundWithNewlines } = arg;
    const originalSelectionStart = textarea.selectionStart;
    const originalSelectionEnd = textarea.selectionEnd;

    let selectedText = textarea.value.slice(
      textarea.selectionStart,
      textarea.selectionEnd
    );
    let prefixToUse = prefix;
    let suffixToUse = suffix;

    if (!arg.skipExpand) {
      selectedText = this.expandSelectedText(textarea, prefix, suffix);
    }
    let selectionStart = textarea.selectionStart;
    let selectionEnd = textarea.selectionEnd;
    const hasReplaceNext =
      replaceNext.length > 0 &&
      suffixToUse.indexOf(replaceNext) > -1 &&
      selectedText.length > 0;
    if (surroundWithNewlines) {
      const ref = this.newlinesToSurroundSelectedText(textarea);
      newlinesToAppend = ref.newlinesToAppend;
      newlinesToPrepend = ref.newlinesToPrepend;
      prefixToUse = newlinesToAppend + prefix;
      suffixToUse += newlinesToPrepend;
    }

    let replacementText: string;
    if (
      selectedText.startsWith(prefixToUse) &&
      selectedText.endsWith(suffixToUse)
    ) {
      replacementText = selectedText.slice(
        prefixToUse.length,
        selectedText.length - suffixToUse.length
      );
      if (originalSelectionStart === originalSelectionEnd) {
        let position = originalSelectionStart - prefixToUse.length;
        position = Math.max(position, selectionStart);
        position = Math.min(position, selectionStart + replacementText.length);
        selectionStart = selectionEnd = position;
      } else {
        selectionEnd = selectionStart + replacementText.length;
      }
    } else if (!hasReplaceNext) {
      replacementText = prefixToUse + selectedText + suffixToUse;
      selectionStart = originalSelectionStart + prefixToUse.length;
      selectionEnd = originalSelectionEnd + prefixToUse.length;
      const whitespaceEdges = selectedText.match(/^\s*|\s*$/g);
      if (arg.trimFirst && whitespaceEdges) {
        const leadingWhitespace = whitespaceEdges[0] || '';
        const trailingWhitespace = whitespaceEdges[1] || '';
        replacementText =
          leadingWhitespace +
          prefixToUse +
          selectedText.trim() +
          suffixToUse +
          trailingWhitespace;
        selectionStart += leadingWhitespace.length;
        selectionEnd -= trailingWhitespace.length;
      }
    } else if (scanFor.length > 0 && selectedText.match(scanFor)) {
      suffixToUse = suffixToUse.replace(replaceNext, selectedText);
      replacementText = prefixToUse + suffixToUse;
      selectionStart = selectionEnd = selectionStart + prefixToUse.length;
    } else {
      replacementText = prefixToUse + selectedText + suffixToUse;
      selectionStart =
        selectionStart +
        prefixToUse.length +
        selectedText.length +
        suffixToUse.indexOf(replaceNext);
      selectionEnd = selectionStart + replaceNext.length;
    }
    return { text: replacementText, selectionStart, selectionEnd };
  };

  public isMultipleLines = (s: string): boolean => {
    if (!s) {
      return false;
    }
    return s.trim().split('\n').length > 1;
  };

  private repeatString(s: string, n: number): string {
    return Array(n + 1).join(s);
  }

  private wordSelectionStart = (text: string, i: number): number => {
    let index = i;
    while (
      text[index] &&
      text[index - 1] != null &&
      !text[index - 1].match(/\s/)
    ) {
      index--;
    }
    return index;
  };

  private wordSelectionEnd = (text: string, i: number): number => {
    let index = i;
    while (text[index] && !text[index].match(/\s/)) {
      index++;
    }
    return index;
  };

  private expandSelectedText = (
    textarea: HTMLTextAreaElement,
    prefixToUse: string,
    suffixToUse: string
  ): string => {
    if (textarea.selectionStart === textarea.selectionEnd) {
      textarea.selectionStart = this.wordSelectionStart(
        textarea.value,
        textarea.selectionStart
      );
      textarea.selectionEnd = this.wordSelectionEnd(
        textarea.value,
        textarea.selectionEnd
      );
    } else {
      const expandedSelectionStart =
        textarea.selectionStart - prefixToUse.length;
      const expandedSelectionEnd = textarea.selectionEnd + suffixToUse.length;
      const beginsWithPrefix =
        textarea.value.slice(
          expandedSelectionStart,
          textarea.selectionStart
        ) === prefixToUse;
      const endsWithSuffix =
        textarea.value.slice(textarea.selectionEnd, expandedSelectionEnd) ===
        suffixToUse;
      if (beginsWithPrefix && endsWithSuffix) {
        textarea.selectionStart = expandedSelectionStart;
        textarea.selectionEnd = expandedSelectionEnd;
      }
    }
    return textarea.value.slice(textarea.selectionStart, textarea.selectionEnd);
  };

  private newlinesToSurroundSelectedText = (textarea): Newlines => {
    const beforeSelection = textarea.value.slice(0, textarea.selectionStart);
    const afterSelection = textarea.value.slice(textarea.selectionEnd);

    const breaksBefore = beforeSelection.match(/\n*$/);
    const breaksAfter = afterSelection.match(/^\n*/);
    const newlinesBeforeSelection = breaksBefore ? breaksBefore[0].length : 0;
    const newlinesAfterSelection = breaksAfter ? breaksAfter[0].length : 0;

    let newlinesToAppend: string;
    let newlinesToPrepend: string;

    if (beforeSelection.match(/\S/) && newlinesBeforeSelection < 2) {
      newlinesToAppend = this.repeatString('\n', 2 - newlinesBeforeSelection);
    }

    if (afterSelection.match(/\S/) && newlinesAfterSelection < 2) {
      newlinesToPrepend = this.repeatString('\n', 2 - newlinesAfterSelection);
    }

    if (newlinesToAppend == null) {
      newlinesToAppend = '';
    }

    if (newlinesToPrepend == null) {
      newlinesToPrepend = '';
    }

    return { newlinesToAppend, newlinesToPrepend };
  };
}
