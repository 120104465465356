import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfPipesModule } from '../../../../utilities/pipes/pipes.module';

import { DfInputDecoratorComponent } from './input-decorator.component';

@NgModule({
  imports: [CommonModule, DfPipesModule],
  exports: [DfInputDecoratorComponent],
  declarations: [DfInputDecoratorComponent],
  providers: [],
})
export class DfInputDecoratorModule {}
