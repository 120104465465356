import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';

@Component({
  selector: 'dgx-author-video-modal',
  templateUrl: './author-video-modal.component.html',
})
export class AuthorVideoModalComponent {
  constructor(private activeModal: NgbActiveModal) {}
  public close() {
    this.activeModal.close();
  }

  public emitUploadResult(uploadResult) {
    this.activeModal.close(uploadResult);
  }
}
