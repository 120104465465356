import { Injectable } from '@angular/core';
import {
  CourseMappingToApi,
  CourseModel,
} from '@app/user-content/user-input-v2/inputs/course/course.model';
import { getUpdatedDuration } from '@app/user-content/user-input-v2/utils/duration-helper';
import {
  getDSSkillsAdded,
  getDSSkillsRemoved,
} from '@app/user-content/user-input-v2/utils/inferred-skills-helper';
import { TrackerService, WebEnvironmentService } from '@dg/shared-services';

@Injectable({
  providedIn: 'root',
})
export class CourseTrackerService {
  public inputType = 'Course';
  constructor(
    private tracker: TrackerService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  /**
   * Track that content has been added to the pathway bin.
   *
   * @param source
   */
  public trackPathwayAdd(source: CourseMappingToApi) {
    this.tracker.trackEventData({
      action: 'Content Added To Pathway Bin',
      category: this.inputType,
      properties: source,
    });
  }

  /**
   * If an actual edit is happening, it's happening to the catalog
   * version of the course.
   *
   * @param source
   */
  public trackPathwayUpdate(source: CourseMappingToApi) {
    this.trackContentCatalogUpdate({
      ...source,
      externalId: source.externalId ?? undefined,
    });
  }

  public trackContentCatalogAdd(source: CourseModel) {
    const {
      durationHours,
      durationMinutes,
      inputId,
      inputType,
      externalId,
      hostedContentDetails,
      imageUrl,
      name,
      tags,
      mediumConfidenceInferredSkills,
      highConfidenceInferredSkills,
    } = source;

    const eventDataToTrack = {
      action: 'Content Added to Catalog',
      properties: {
        contentId: inputId,
        contentType: inputType,
        dateModified: Date(),
        suggestedDuration: 'None',
        externalId: externalId,
        hostedType: hostedContentDetails?.hostType,
        imageUrl: imageUrl,
        title: name,
        skillsAdded: getDSSkillsAdded(tags, mediumConfidenceInferredSkills),
        skillsRemoved: getDSSkillsRemoved(tags, highConfidenceInferredSkills),
        tagsCount: tags.length,
        ...{
          updatedDuration: getUpdatedDuration(durationHours, durationMinutes),
        },
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentCatalogUpdate(source: CourseMappingToApi) {
    let eventDataToTrack = {
      action: 'CMS Item Updated',
      location: 'Manage Org Catalog',
      category: 'Course',
      properties: {
        ...source,
        suggestedDuration: 'None',
      },
    };

    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackContentSkillInferred(source: CourseModel): void {
    const appLocation = this.webEnvironmentService.analyticsAppLocation;

    const eventDataToTrack = {
      action: `${appLocation} Content Skill Inferred`,
      properties: {
        skillsAdded: getDSSkillsAdded(
          source.tags,
          source.mediumConfidenceInferredSkills
        ),
        skillsRemoved: getDSSkillsRemoved(
          source.tags,
          source.highConfidenceInferredSkills
        ),
        tagsCount: source.tags.length,
        ...{
          updatedDuration: getUpdatedDuration(
            source.durationHours,
            source.durationMinutes
          ),
        },
      },
    };
  }

  public trackContentCompleted(
    source: CourseMappingToApi & { institutionName: string }
  ) {
    const {
      externalId,
      imageUrl,
      name,
      tags,
      institutionName,
      courseUrl,
      inputId,
    } = source;

    const eventDataToTrack = {
      action: 'Content Completed',
      category: this.inputType,
      properties: {
        title: name,
        url: courseUrl,
        imageUrl: imageUrl,
        inputId: inputId,
        inputType: this.inputType,
        externalId: externalId,
        length: 'None',
        inputSource: institutionName,
        tags: tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackScormFail(xmlResult: string) {
    const eventDataToTrack = {
      action: 'Scorm Upload Failed',
      properties: {
        xmlResult: xmlResult,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackScormUploadCompleted(xmlResult: string) {
    const eventDataToTrack = {
      action: 'SCORM Upload Completed',
      properties: {
        xmlResult: xmlResult,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
