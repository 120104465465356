import { ApiServiceBase } from '@app/shared/services/api-service-base';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AwardMappingToAPI } from '../award.model';
import { catchError } from 'rxjs/operators';
import { DgError } from '@app/shared/models/dg-error';
import { lastValueFrom } from 'rxjs';
import { ActionFeedback } from '@app/inputs/inputs-api.model';
import { UserOutcomeDetail } from '@app/outcomes/outcomes-api.model';

@Injectable()
export class AwardService extends ApiServiceBase {
  private i18n = this.translate.instant([
    'InputsSvc_GeneralError',
    'InputsSvc_AddItemError',
  ]);

  constructor(
    private translate: TranslateService,
    http: NgxHttpClient
  ) {
    super(http, translate.instant('InputsSvc_GeneralError'));
  }

  public addAward(params: AwardMappingToAPI): Promise<void> {
    const request$ = this.post<void>('/useroutcomes/add', {
      ...params,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }

  public updateAward(params: AwardMappingToAPI): Promise<void> {
    const request$ = this.put<void>('/useroutcomes/update', {
      ...params,
    }).pipe(
      catchError((e) => {
        throw new DgError(this.i18n.InputsSvc_AddItemError, e);
      })
    );
    return lastValueFrom(request$);
  }
}
