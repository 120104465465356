<div
  class="local-notification local-notification--{{
    type
  }} local-notification--{{ size }}"
>
  <df-icon [icon]="icon" [size]="size" class="aria-hidden"></df-icon>
  <p
    class="local-notification__text local-notification--{{ size }}__text"
    [innerHTML]="text"
  ></p>
</div>
