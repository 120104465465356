import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputNotification } from './../../inputs/inputs-api.model';
import { PathwayDetailsModel } from '@dg/pathways-rsm';

export const shouldPerformAutoEnrollment = (
  pathway: PathwayDetailsModel,
  input: LearningResourceViewModel | InputNotification,
  isCompleting: boolean,
  isInputNotification: boolean
): boolean => {
  const numOfNewlyCompletedRequiredSteps = 1;
  const alreadyCompletedRequiredSteps = pathway.completedSteps;
  const isOptionalStep = isInputNotification
    ? (input as InputNotification).isPathwayOptionalStep
    : (input as LearningResourceViewModel).pathwayStepDetails?.isOptional;
  const isLastRequiredInputCompletion =
    !isOptionalStep &&
    alreadyCompletedRequiredSteps +
      pathway.optionalSteps +
      numOfNewlyCompletedRequiredSteps ===
      pathway.totalSteps;
  const isLastStepAndIsOptional =
    isOptionalStep &&
    pathway.complete &&
    alreadyCompletedRequiredSteps + pathway.optionalSteps ===
      pathway.totalSteps;

  if (isInputNotification) {
    return (
      !pathway?.isEnrolled &&
      isCompleting &&
      !isLastRequiredInputCompletion &&
      !isLastStepAndIsOptional
    );
  }
  return (
    !pathway?.isEnrolled &&
    isCompleting &&
    (isLastRequiredInputCompletion || isLastStepAndIsOptional)
  );
};
