import { Component, Input, OnInit } from '@angular/core';

// services
import { TranslateService } from '@ngx-translate/core';

// types
import { TagsApi } from '@app/tags/tag-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';

@Component({
  selector: 'dgx-replace-focus-interest-modal',
  templateUrl: './replace-focus-interest-modal.component.html',
})
export class ReplaceFocusInterestModal implements OnInit {
  // pass in TagsService as an input to avoid a circular dependency as the TagsService uses the ngx ModalService to
  // launch this modal, but the TagsService is also required by the modal. We'll need to look into splitting up the TagsService
  // to avoid this in the future
  @Input() public tagsService;

  public isLoading: boolean = true;
  public tags: TagsApi.Tag[];
  public selectedTag?: TagsApi.Tag; // selected skill set by ng-model

  public i18n = this.translateService.instant([
    'dgTagRating_InProgress',
    'TagsSvc_Replace',
    'TagsSvc_ReplaceFocusInterestModalDescription',
    'TagsSvc_ReplaceFocusInterestModalTitle',
  ]);

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private tagRatingType: TagRatingTypePipe
  ) {}

  public ngOnInit() {
    this.tagsService.getUserTopTags(true, false, true).subscribe((result) => {
      this.tags = result.filter((tag) => tag.isFocused);
      this.isLoading = false;
    });
  }

  public getRatingLabel({ rating }: TagsApi.Tag): string {
    if (!rating) {
      return;
    }

    if (!rating.level && rating.type === InternalTagRatingTypes.evaluation) {
      return this.i18n.dgTagRating_InProgress;
    } else {
      return this.tagRatingType.transform(rating);
    }
  }

  // On submit, resolve the modal promise with the selected tag
  public submit() {
    if (this.selectedTag) {
      this.activeModal.close(this.selectedTag);
    }
  }

  public closeModal() {
    this.activeModal.dismiss();
  }
}
