<div class="grid__col-12">
  <dgx-member-counts
    *ngIf="memberCounts"
    [memberCounts]="memberCounts"
    [isStandaloneOrg]="isStandaloneOrg"
  ></dgx-member-counts>
</div>
<div class="grid__col-12">
  <dgx-org-members-header
    [orgId]="orgId"
    [canInviteMembers]="canInviteMembers"
    (updateQueries)="doSearch($event)"
  ></dgx-org-members-header>
</div>
<dgx-data-table
  [columns]="columns"
  [useSkeleton]="true"
  [items]="members"
  (loadMoreItems)="getMembers()"
  [isLoading]="isLoading"
  propTrackingKey="userProfileKey"
  [isSorting]="isSorting"
  [isDescending]="isDescending"
  [orderBy]="orderBy"
  (updateSort)="updateSort($event)"
  [hasNoResults]="hasNoResults"
  [hasNoItems]="false"
  [searchTerm]="searchTerm"
  [numResultsPerPage]="take"
  [pageNum]="pageNum"
  [showPerPage]="true"
  [perPageOptions]="[
    { value: 10, display: '10' },
    { value: 30, display: '30' },
    { value: 50, display: '50' }
  ]"
  [totalNumResults]="visibleMembersTotal"
  (pageChange)="updatePagination({ currentPage: $event })"
  (perPageChange)="updatePagination({ pageSize: $event })"
>
</dgx-data-table>

<dgx-footer-glasses></dgx-footer-glasses>

<ng-template #profile let-item>
  <div class="l_flexbar">
    <div class="ib ib-v-middle">
      <dgx-profile-pic [profile]="item" size="tiny"></dgx-profile-pic>
    </div>
    <div [ngSwitch]="item.privacyId" class="guts-p-l-1">
      <span *ngSwitchCase="'Private'" class="guts-m-r-half">{{
        item.name
      }}</span>
      <a
        *ngSwitchDefault
        target="_blank"
        [attr.href]="'/profile/' + item.vanityUrl"
        class="guts-m-r-half uhover color-ebony"
        data-dgat="orgMembers-4bc"
        >{{ item.name }}
      </a>
      <div class="guts-m-r-half par par--small par--light">
        {{ item.organizationEmail }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #roleDisplay let-item>
  {{ getRoleString(item.role) }}
</ng-template>

<ng-template #privacyDisplay let-item>
  <div [ngSwitch]="item.privacyId">
    <span
      *ngSwitchCase="'Public'"
      [ngbTooltip]="i18n.OrgMembersCtrl_PublicTooltip"
      [attr.aria-label]="i18n.OrgMembersCtrl_PublicTooltip"
      tabindex="0"
    >
      <span>{{ i18n.OrgMembersCtrl_Public }}</span>
    </span>
    <span
      *ngSwitchCase="'OrgVisible'"
      [ngbTooltip]="i18n.OrgMembersCtrl_PublicToOrgTooltip"
      [attr.aria-label]="i18n.OrgMembersCtrl_PublicToOrgTooltip"
      tabindex="0"
    >
      <span>{{ i18n.OrgMembersCtrl_PrivateToOrg }}</span>
    </span>
    <span
      *ngSwitchCase="'Private'"
      [ngbTooltip]="i18n.OrgMembersCtrl_PrivateTooltip"
      [attr.aria-label]="i18n.OrgMembersCtrl_PrivateTooltip"
      tabindex="0"
    >
      <span>{{ i18n.OrgMembersCtrl_Private }}</span>
    </span>
  </div>
</ng-template>

<ng-template #pointsDisplay let-item>
  {{ item.masteryPoints | number: '1.0-0' || '0' }}
</ng-template>

<ng-template #statusDisplay let-item>
  {{ getStatusString(item.status) }}
</ng-template>

<ng-template #editMember let-item>
  <button
    class="beta color-ebony-a61"
    type="button"
    [title]="i18n.OrgMembersCtrl_MemberSettingsText"
    [attr.aria-label]="i18n.OrgMembersCtrl_MemberSettingsText"
    (click)="openMember(item)"
    data-dgat="orgMembers-64d"
  >
    <df-icon icon="gear"></df-icon>
  </button>
</ng-template>
