import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { DialogData } from '@degreed/apollo-angular';
import { BrandingState } from '../data-service';

export interface PublishChangesData extends DialogData {
  state: Readonly<BrandingState>;
  onClose?: (confirmed: boolean) => void;
}

@Component({
  selector: 'dgx-org-branding-publish-changes-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="tw-max-w-72 tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow-lg md:tw-max-w-md md:tw-p-6 lg:tw-max-w-xl"
      role="dialog"
      aria-labelledby="title"
      aria-describedby="description"
    >
      <div
        class="tw-mb-8 tw-flex tw-flex-col tw-items-start tw-gap-6 md:tw-flex-row"
      >
        <div class="tw-flex tw-flex-col">
          <h4 class="tw-mb-2 tw-font-extrabold tw-text-neutral-900" id="title">
            Publish Changes
          </h4>
          <p id="description" class="tw-text-neutral-900">
            You are about to publish the following changes to everyone in your
            organization's Degreed instance.
          </p>
          <ul class="tw-mt-4 tw-list-inside tw-list-disc tw-pl-2 tw-text-sm">
            @if (data.state.navigation.isDirty) {
              <li>Navigation Styling</li>
            }
            @if (data.state.endorsements.isDirty) {
              <li>Endorsement Image</li>
            }
            @if (data.state.orgInfo.isDirty) {
              <li>Organization Name</li>
            }
          </ul>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-justify-end tw-gap-2 md:tw-flex-row md:tw-gap-4"
      >
        <button
          class="tw-btn-medium tw-btn-secondary-filled"
          (click)="handleClose(false)"
        >
          Cancel
        </button>
        <button
          class="tw-btn-primary tw-btn-medium"
          (click)="handleClose(true)"
        >
          Publish
        </button>
      </div>
    </div>
  `,
})
export class PublishChangesModalComponent {
  @Output() public close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(DIALOG_DATA)
    public data: PublishChangesData
  ) {}

  handleClose(confirmed: boolean): void {
    if (!!this.data.onClose) {
      this.data.onClose(confirmed);
    }
    this.close.emit(confirmed);
  }
}
