import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';

// services
import { InputsService } from '@app/inputs/services/inputs.service';
import { TranslateService } from '@ngx-translate/core';

// misc
import { FormGroup } from '@angular/forms';

import {
  AccomplishmentMapperService,
  AccomplishmentService,
  AccomplishmentTrackerService,
} from '../';

import { SubmissionStatus } from '@app/inputs/inputs.model';
import { TipService } from '@app/onboarding/services/tip.service';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { AuthService } from '@app/shared/services/auth.service';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { OutcomesFacadeBaseV2 } from '@app/user-content/user-outcome-v2/services/outcomes-facade.base';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { BehaviorSubject, combineLatest, lastValueFrom, map } from 'rxjs';
import {
  AccomplishmentFormDataModel,
  AccomplishmentMappingToAPI,
  AccomplishmentModel,
} from '../../accomplishment.model';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable()
export class AccomplishmentGlobalAddFacade extends OutcomesFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<AccomplishmentModel>(undefined);

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    public inputsService: InputsService,
    public accomplishmentMapperService: AccomplishmentMapperService,
    public accomplishmentService: AccomplishmentService,
    public accomplishmentTrackerService: AccomplishmentTrackerService,
    public outcomeNotificationService: OutcomeNotificationService,
    public userOutcomeService: UserOutcomeService,
    public outcomesService: OutcomesService,
    public tipService: TipService,
    public globalAddTrackingService: GlobalAddTrackingService
  ) {
    super(outcomesService, tipService);
  }

  // *******************************************************
  // Getters
  // *******************************************************
  /** Gets the latest view model */
  protected get viewModel(): AccomplishmentModel {
    return this.viewModel$.value as AccomplishmentModel;
  }

  protected set viewModel(viewModel: AccomplishmentModel) {
    this.viewModel$.next(viewModel);
  }

  /**
   * Easy access to current snapshot of [read-only] AccomplishmentModel
   * ...
   */
  public get snapshot(): AccomplishmentModel {
    return readFirst(this.viewModel$);
  }

  // not used, but required to implement the abstract class
  public async onNext(url: string): Promise<void> {}

  // *******************************************************
  // Overwrites
  // *******************************************************

  public async onSubmit(form: FormGroup): Promise<void> {
    const formData = form.value;
    // Step 1 update the View model with the form data
    this.updateViewWithFormData(formData);
    // step 2 get API Params mapper
    const apiParameters = this.accomplishmentMapperService.toApiParameters(
      this.viewModel as AccomplishmentModel
    );
    this.submissionStatus$.next(SubmissionStatus.Submitting);
    try {
      if (this.viewModel.isEditing) {
        await this.accomplishmentService.updateAccomplishment(apiParameters);
      } else {
        await this.accomplishmentService.addAccomplishment(apiParameters);
      }
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
      this.performSuccessSideEffects();
      this.globalAddTrackingService.trackGlobalAddSaved(
        'Accomplishment',
        this.viewModel.skills,
        this.viewModel.mediumConfidenceInferredSkills,
        this.viewModel.highConfidenceInferredSkills
      );
    } catch (error) {
      this.performFailureSideEffects();
      throw new Error('Error in AccomplishmentFacade', error);
    }
    return;
  }

  public async initializeEdit(userOutcomeId: number): Promise<void> {
    // Update viewModel
    const request$ = this.userOutcomeService.getUserOutcome(
      this.authService.authUser.viewerProfile.userProfileKey,
      userOutcomeId
    );

    const accomplishment = await lastValueFrom(request$);

    this.loadInferredSkills(accomplishment.title);
    // Map response to view model
    const updatedView = this.accomplishmentMapperService.toViewModel(
      accomplishment as AccomplishmentMappingToAPI
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
      isEditing: true,
    };
    return;
  }

  /**
   * Override initializeViewModel
   */
  public initializeViewModel(): void {
    super.initializeViewModel();

    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
    ]).pipe(map(([s]) => s === SubmissionStatus.Submitting));

    // initialize new/computed Properties
    this.viewModel = {
      ...this.viewModel,
      title: '',
      noExpiration: false,
      dateRangeForm: {
        startDate: null,
        endDate: null,
      },
      inferredSkills$: this.inferredSkills$,
      skills: [],
      userOutcomeId: null,
      shouldSpinSubmitButton$,
      loadInferredSkills: this.loadInferredSkills.bind(this),
      isEditing: false,
    };
  }

  public loadInferredSkills(title: string): void {
    this.inferredSkillsTrigger$.next({ title });
  }

  /** Performs any side effects required following successful creation of an Accomplishment */
  protected performSuccessSideEffects(): void {
    this.outcomeNotificationService.notifyOutcomeCreated(this.viewModel.title);
    const apiParameters = this.accomplishmentMapperService.toApiParameters(
      this.viewModel
    );

    if (!this.viewModel.isEditing) {
      this.outcomeNotificationService.notifyOutcomeCreated(
        this.viewModel.title
      );
      this.accomplishmentTrackerService.trackAccomplishmentAdded(apiParameters);
    } else {
      this.outcomeNotificationService.notifyOutcomeUpdated(
        this.viewModel.title
      );
      this.accomplishmentTrackerService.trackAccomplishmentUpdated(
        apiParameters
      );
    }

    this.userOutcomeService.notifyOutcomeModified('accomplishment');
  }

  /** Performs any side effects required following failed creation of an Accomplishment */
  protected performFailureSideEffects() {
    this.outcomeNotificationService.notifyOutcomeCreateFailed();
  }

  /**
   * Update the view with the current form data values
   * @param formData
   */
  protected updateViewWithFormData(formData: AccomplishmentFormDataModel) {
    this.viewModel = {
      ...this.viewModel,
      ...formData,
    };
  }
}
