import { Opportunity } from '@app/opportunities/opportunities-api.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import {
  OpportunityApplicationStatus,
  OpportunityStatus,
  OpportunityApplicationStageEnum,
} from '../opportunities.enums';

/**
 * Checks for an external provider.
 *
 * @param opportunity - The opportunity in question.
 */
export const hasExternalProvider = (opportunity: Opportunity): boolean => {
  return !!opportunity?.url;
};

/**
 * Is the current opportunity closed.
 *
 * @param opportunity - the opportunity of concern
 */
export const isOpportunityClosed = (opportunity: Opportunity): boolean => {
  return opportunity.status === OpportunityStatus.Closed;
};

/**
 * Is the current opportunity in the user's queue.
 *
 * @param opportunity - the opportunity of concern
 */
export const isOpportunityQueued = (opportunity: Opportunity): boolean => {
  return opportunity.isQueued || !!opportunity.userQueueId;
};

/**
 * Is the current opportunity set to private.
 *
 * @param opportunity - the opportunity of concern
 */
export const isOpportunityPrivate = (opportunity: Opportunity): boolean => {
  return opportunity.privacyId === Visibility.private;
};

/**
 * Is the current opportunity set to groups only visibility.
 *
 * @param opportunity - the opportunity of concern
 */
export const isOpportunityGroupsVisible = (
  opportunity: Opportunity
): boolean => {
  return opportunity.privacyId === Visibility.groups;
};

/**
 * Is the current user interested in the current opportunity.
 *
 * @param opportunity - the opportunity of concern
 */
export const isUserInterested = (opportunity: Opportunity): boolean => {
  return (
    opportunity.applicationStatus === OpportunityApplicationStatus.Interested
  );
};

/**
 * Is the current user interested in the current opportunity.
 *
 * @param opportunity - the opportunity of concern
 */
export const isUserSelected = (opportunity: Opportunity): boolean => {
  return (
    opportunity.applicationStage === OpportunityApplicationStageEnum.Selected
  );
};
