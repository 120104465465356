import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';

export interface PathwayEnrollment {
  enrollCount: number;
  enrollType: string;
  sentCount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PathwayEnrollmentSourceService {
  constructor(private http: NgxHttpClient) {}

  public getPathwayEnrollments(
    pathwayId: number,
    groupId?: number,
    filterOrgId?: number
  ) {
    return this.http.get<PathwayEnrollment[]>(
      '/reporting/getpathwayenrollments',
      {
        cache: true,
        params: {
          pathwayId,
          groupId,
          filterOrgId,
        },
      }
    );
  }
}
