import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DfLabelMarkerComponent } from './label-marker.component';

@NgModule({
  imports: [CommonModule],
  exports: [DfLabelMarkerComponent],
  declarations: [DfLabelMarkerComponent],
  providers: [],
})
export class DfLabelMarkerModule {}
