export class UserCompletionInfo {
  constructor(
    public readonly userInputId: number,
    public readonly externalCompletionOnly?: boolean
  ) {}

  public get isCompleted() {
    return !!this.userInputId;
  }
}
