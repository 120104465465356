import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WindowToken } from '@app/shared/window.token';

@Component({
  selector: 'dgx-tags-list',
  templateUrl: './tags-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsListComponent implements OnInit {
  @Input() public centered = false;
  @Input() public fitOneLine = false;
  @Input() public hideIcon = false;
  @Input() public list: string[] | Partial<TagsApi.Tag[]>;
  @Input() public moreAction: Function = undefined;
  @Input() public tagClick: Function = undefined;

  @ViewChild('tagsListContainer') tagsListContainer: ElementRef;
  @ViewChild('tagsListElement') tagsListElement: ElementRef;

  public hiddenTags: Partial<{ label: string; url: string }>[] = [];
  public isHiding = false;
  public showMoreLink = false;
  public tagLinks: Partial<{ label: string; url: string }>[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private searchUrlService: SearchUrlService,
    private tagsService: TagsService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public ngOnInit(): void {
    this.tagLinks = this.tagsService.objectifyTags(
      this.list as Partial<TagsApi.Tag[]>
    );

    if (this.fitOneLine) {
      this.isHiding = true;
      this.showMoreLink = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.list && changes.list.currentValue) {
      this.tagLinks = this.tagsService.objectifyTags(changes.list.currentValue);

      if (this.fitOneLine) {
        this.isHiding = true;
        this.hiddenTags = [];
        // tick for DOM to update
        setTimeout(() => {
          const tagsEl = this.tagsListElement.nativeElement;
          const containerEl = this.tagsListContainer.nativeElement;
          this.fitTagsOnOneLine(tagsEl, containerEl);
          this.cdr.detectChanges();
        });
      } else {
        this.cdr.detectChanges();
      }
    }
  }

  public fitTagsOnOneLine(tagsEl: HTMLElement, containerEl: HTMLElement): void {
    if (
      this.tagLinks?.length > 1 &&
      tagsEl.offsetWidth > containerEl.offsetWidth
    ) {
      const i = this.tagLinks.length - 1;
      // move last tag from tagsList array to hiddenTags array
      this.hiddenTags.push(this.tagLinks[i]);
      this.tagLinks.splice(i, 1);
      // go again: recursively applied till tagLink's empty
      this.fitTagsOnOneLine(tagsEl, containerEl);
    } else {
      this.showMoreLink = this.hiddenTags.length > 0;
      this.isHiding = false;
    }
  }

  public openTag(event: Event, tag: { label?: string; Label?: string }): void {
    event.preventDefault();
    this.tagClick ? this.tagClick({ tag }) : this.defaultOpenTag(event, tag);
  }

  public showMore(): void {
    this.moreAction ? this.moreAction() : this.defaultShowMore();
  }

  public getTagUrl(term: string): string {
    return this.searchUrlService.getGlobalSearchURL(term);
  }

  public defaultShowMore(): void {
    this.tagLinks = this.tagLinks.concat(this.hiddenTags);
    this.hiddenTags = [];
    this.fitOneLine = false;
  }

  public defaultOpenTag(
    event: Event,
    tag: { label?: string; Label?: string }
  ): void {
    event.stopPropagation();
    const searchTerm = tag.label || tag.Label;
    const url = this.searchUrlService.getGlobalSearchURL(searchTerm);
    this.windowRef.location.assign(url);

    if (this.activeModal) {
      this.activeModal.dismiss();
    }
  }
}
