import { Injectable } from '@angular/core';

import {
  FormImageUploaderParams,
  UserImageFieldComponent,
} from '@app/form-fields/wrappers/user-image-field/user-image-field.component';
import { InputImageUploadAdapterService } from '@app/uploader/upload-section/adapters/input-image-upload-adapter.service';
import { UploadFileResponse } from '@app/uploader/uploader-api.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FieldWrapperBuilder } from './field-builder-factory';

@Injectable({ providedIn: 'root' })
export class UserImageFieldBuilderService implements FieldWrapperBuilder {
  constructor(
    private builder: DfFormFieldBuilder,
    private inputImageUploadAdapterService: InputImageUploadAdapterService
  ) {}

  public build(context?: any): DfFormFieldConfig<DfTemplateOptions> {
    return this.builder
      .foreignField<FormImageUploaderParams>(
        'imageUrl',
        '',
        UserImageFieldComponent.REGISTERED_FIELD_TYPE,
        {
          uploadAdapter: this.inputImageUploadAdapterService.getAdapter(
            context?.inputType,
            context?.resourceId
          ),
          provideControlValue: (response: UploadFileResponse) => response,
          useCropper: context?.useCropper,
        }
      )
      .build();
  }
}
