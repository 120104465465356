<div class="l_flexbar guts-p-t-2">
  <button
    df-button
    dfButtonType="primary"
    class="guts-m-r-1"
    data-dgat="setting-tile-toggle-text-e9b"
    (click)="handleSave()"
    [disabled]="!isFormValid"
    [class.is_disabled]="!isFormValid"
  >
    {{ 'Core_SaveChanges' | translate }}
  </button>
  <button
    df-button
    dfButtonType="secondary"
    data-dgat="setting-tile-toggle-text-33f"
    (click)="handleCancel()"
  >
    {{ 'Core_Cancel' | translate }}
  </button>
</div>
