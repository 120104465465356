<label for="addToCatalog" class="reactive-forms__label-checkbox">
  <input
    id="addToCatalog"
    df-checkbox
    [checked]="addToCatalog"
    type="checkbox"
    name="addToCatalog"
    formControlName="addToCatalog"
    data-dgat="add-to-catalog-8fa"
  />
  <span class="guts-m-l-half">{{ checkboxLabel }}</span></label
>
