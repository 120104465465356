<div class="l_flexbar">
  <label class="l_flex-grow" for="settingToggle_{{ name }}"
    ><h4 class="h4">{{ name }}</h4></label
  >
  <dgx-toggle-switch
    id="settingToggle_{{ name }}"
    [isChecked]="enabled"
    (toggled)="handleSettingToggled($event)"
  ></dgx-toggle-switch>
</div>
<p class="par par--small par--light grid__col-9" *ngIf="description">
  {{ description }}
</p>
