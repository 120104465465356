import {
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Directive,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrgSettingsService } from '@app/orgs/services/org-settings.service';
import { OrgSetting } from '@app/orgs/services/orgs.model';

export interface Validation {
  ValidationType: 'URL' | 'Email' | 'ExcludeEmailDomain' | 'Required';
  ValidationField?: string;
}

@Directive()
export abstract class OrgSettingBase implements OnInit {
  @Input() public setting: OrgSetting;
  @Output()
  public settingToggled: EventEmitter<OrgSetting> = new EventEmitter();
  @Output() public settingSaved: EventEmitter<OrgSetting> = new EventEmitter();
  @ViewChild('formElement') public formElement: ElementRef<HTMLElement>;

  public settingDescription: string;
  public settingEnabled: boolean;
  /// the setting form is the source of truth.  The setting does not get updated until the setting is toggled off, or the save button is clicked.
  public settingForm: FormGroup;
  public settingName: string;
  public validationSettings: Validation[];

  constructor(
    protected formBuilder: FormBuilder,
    protected orgSettings: OrgSettingsService
  ) {}

  public ngOnInit(): void {
    this.validationSettings = this.setting.validationSettings
      ? (JSON.parse(this.setting.validationSettings) as Validation[])
      : null;
    this.settingName = this.orgSettings.getSettingNameTranslated(this.setting);
    this.settingDescription = this.orgSettings.getSettingDescriptionTranslated(
      this.setting
    );
    this.settingEnabled = this.setting.enabled; // keep this state separate of the settings enabled state.
    this.settingForm = this.formBuilder.group(
      this.createFormGroupFromSetting()
    );
  }

  public handleSettingToggled(isEnabled: boolean) {
    this.settingEnabled = isEnabled;
    if (isEnabled) {
      this.settingForm.markAsDirty();
    } else {
      this.resetFormToDefault(); // clear out any unsaved changes
      if (this.setting.enabled) {
        // we only need to update the setting if the setting is currently enabled on the backend.
        this.setting.enabled = false;
        this.settingToggled.emit(this.setting);
      }
    }
  }

  public handleCancel() {
    this.settingEnabled = this.setting.enabled;
    this.resetFormToDefault();
    this.setFocusToForm();
  }
  public abstract handleSave(): void;

  protected getValidator(validation: Validation) {
    switch (validation.ValidationType) {
      case 'Email':
        return Validators.email;
      case 'URL':
        const reg = new RegExp(
          '^(https?://){1}([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        );
        return Validators.pattern(reg);
      case 'ExcludeEmailDomain':
        const domainReg = new RegExp('.*(?<!@degreed.com)$');
        return Validators.pattern(domainReg);
      case 'Required':
        return Validators.required;
    }
  }

  protected setFocusToForm() {
    this.formElement.nativeElement.focus();
  }

  protected abstract resetFormToDefault(): void;
  protected abstract createFormGroupFromSetting(): any;
}
