import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { gsap } from 'gsap/dist/gsap';
import { Observable } from 'rxjs';
import { A11yService } from '../services/a11y.service';
import { Sortable } from './sortable.directive';

export interface SortableResult<T> {
  list: T[];
  itemIndex: number; // index position of last item changed in sort (based on $index OnChanges)
  movedToIndex: number; // index position item moved to
}

export const SORTABLE_LIST_TOKEN = new InjectionToken<SortableListDirective>(
  'SortableListDirective'
);

/**
 * Container that wraps a set of sortable items.
 */
@Directive({
  selector: '[dgxSortableList]',
  providers: [
    { provide: SORTABLE_LIST_TOKEN, useExisting: SortableListDirective },
  ],
})
export class SortableListDirective
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() public sortingDisabled: boolean = false;
  @Input() public tileHeight?: number = 0;
  @Input() public sort: (sortable: SortableResult<any>) => Observable<unknown>;
  /** Make sure the container (this list) is position relative */
  @HostBinding('class.rel') public relativeContainer = true;
  public zIndex = 100;
  public container: HTMLElement = this.elementRef.nativeElement;
  public rowCount = 0;
  public sortableItems: Sortable[] = [];
  public rowSize = 0;
  public colSize = 0;
  public colCount = 0;
  private threshold = '25%';
  private hasBeenDragged = false;
  private placeholder: HTMLElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private a11yService: A11yService
  ) {}

  @HostListener('window:resize', ['direction'])
  public onWindowResize(direction) {
    if (direction !== 'vertical' && !this.sortingDisabled) {
      this.resetSize();
      this.calculateSize();
      this.layoutInvalidated();
    }
  }

  public ngAfterViewInit(): void {
    this.placeholder = this.createPlaceHolder();
  }

  public ngOnChanges({ sortingDisabled }: SimpleChanges): void {
    if (sortingDisabled?.currentValue) {
      this.disableTiles();
    } else {
      this.enableTiles();
    }
  }
  public ngOnDestroy() {
    /*
        Helps performance to disable here because all the sortables are
        being destroyed as well. Sortables make a call to
        layoutInvalidated() if this isn't true in case they are
        destroyed individually.
    */
    this.sortingDisabled = true;
  }

  public registerItem(newSortable: Sortable) {
    // verify item exists and check for duplicates
    const hasDuplicate = this.sortableItems.some((sortable) => {
      const existingNode = sortable.item?.node;
      const newSortableNode = newSortable.item?.node;
      return existingNode === newSortableNode;
    });

    // if the item is a duplicate dont push it to the list
    if (newSortable && !hasDuplicate) {
      this.sortableItems.push(newSortable);
    }
  }

  public destroyItem(item: Sortable) {
    if (item) {
      this.sortableItems.splice(item.index, 1);
    }
  }

  public layoutInvalidated(
    rowToUpdate?: number | null,
    sortButtonClicked?: HTMLButtonElement
  ) {
    let col = 0;
    let row = 0;
    const timeline = gsap.timeline();
    const partialLayout =
      rowToUpdate !== null && rowToUpdate !== undefined
        ? rowToUpdate > -1
        : false;
    const time = 0.35;

    this.sortableItems.forEach((sortable, i) => {
      const oldRow = sortable.row;
      const oldCol = sortable.col;

      if (partialLayout) {
        /*
          PARTIAL LAYOUT: This condition can only occur while an
          item is being dragged. The purpose of this is to only
          swap positions within a row, which will prevent a item
          from jumping to another row if a space is available.

          Without this, a large item in column 0 may appear to be
          stuck if hit by a smaller item, and if there is space in
          the row above for the smaller item. When the user stops
          dragging the item, a full layout update will happen,
          allowing items to move to available spaces in rows above
          them.
        */
        row = sortable.row;
        if (sortable.row !== rowToUpdate) {
          return;
        }
      }

      // Update trackers when colCount is exceeded
      if (col + 1 > this.colCount) {
        col = 0;
        row++;
      }

      Object.assign(sortable, {
        col,
        row,
        index: i,
        x: col * this.colSize,
        y: row * this.rowSize,
      });

      col++;

      // If the sortable being dragged is in bounds, set a new
      // last index in case it goes out of bounds
      if (sortable.isDragging && sortable.inBounds) {
        sortable.lastIndex = i;
      }

      // Don't animate the item that is being dragged and
      // only animate the items that have changes
      if (
        !sortable.isDragging &&
        sortable.positioned &&
        (oldRow !== sortable.row || oldCol !== sortable.col)
      ) {
        const duration = time;
        // Boost the z-index for sortables that will travel over
        // another sortable due to a row change.
        // Also make sure sortables are set to position absolute prior to adjusting x and y coordinates
        if (oldRow !== sortable.row) {
          timeline.set(
            sortable.element,
            {
              zIndex: ++this.zIndex,
              position: 'absolute',
            },
            'reflow'
          );
        }
        timeline.to(
          sortable.element,
          {
            duration: duration,
            x: sortable.x,
            y: sortable.y,
            onComplete: () => {
              sortable.positioned = true;
              if (sortable.draggableObj && sortable.draggableObj.length > 0) {
                sortable.draggableObj[0].enable();
              }
              if (sortButtonClicked) {
                sortable.manageSortButtonFocus(sortButtonClicked);
              }
            },
            onStart: () => {
              if (sortable.draggableObj && sortable.draggableObj.length > 0) {
                sortable.draggableObj[0].disable();
              }
              sortable.positioned = false;
            },
          },
          'reflow'
        );
      } else if (!sortable.isDragging && !this.sortingDisabled) {
        // set up new items
        timeline.set(
          sortable.element,
          {
            x: sortable.x,
            y: sortable.y,
            position: 'absolute',
            zIndex: ++this.zIndex,
          },
          'reflow'
        );
        sortable.positioned = true;
      }
    });

    if (!row) {
      this.rowCount = 1;
    }

    // If the row count has changed, change the height of the container
    if (row !== this.rowCount) {
      this.rowCount = row;
    }

    if (!this.sortingDisabled) {
      this.elementRef.nativeElement.style.height = `${++row * this.rowSize}px`;
    }
  }

  public calculateSize(newItem?: HTMLElement) {
    if (!newItem) {
      newItem = this.sortableItems[0].element;
    }
    this.rowSize = newItem.offsetHeight;
    this.tileHeight = newItem.clientHeight; // this feeds the css hack
    this.colSize = newItem.offsetWidth;
    this.colCount = this.elementRef.nativeElement.clientWidth / this.colSize;
  }

  public onPress(dragUtil: any, sortable: Sortable) {
    const sortableEl = sortable.element;
    if (!dragUtil.isPressed) {
      return;
    }
    sortable.lastX = dragUtil.x;
    sortable.isDragging = true;
    sortable.lastIndex = sortable.index;

    this.layoutInvalidated();

    gsap.set(this.placeholder, {
      padding: getComputedStyle(sortableEl).padding,
      margin: getComputedStyle(sortableEl).margin,
      width: sortableEl.offsetWidth,
      height: sortableEl.offsetHeight,
      x: sortable.x,
      y: sortable.y,
      autoAlpha: 1,
    });

    sortableEl.classList.add('is_dragging');
    gsap.to(sortableEl, {
      duration: 0.2,
      opacity: 0.85,
    });
  }

  public onDrag(dragUtil: any, sortable: Sortable) {
    const validPosition = dragUtil.hitTest(
      this.elementRef.nativeElement,
      this.threshold
    );

    // Move to end of list if not in bounds
    if (!validPosition) {
      sortable.inBounds = false;
      this.changePosition(sortable.index, this.sortableItems.length - 1);
      return;
    }

    sortable.inBounds = true;

    for (let i = 0; i < this.sortableItems.length; i++) {
      // Row to update is used for a partial layout update
      // Shift left/right checks if the item is being dragged
      // towards the the item it is testing
      const testItemVM = this.sortableItems[i];
      const onSameRow = sortable.row === testItemVM.row;
      const rowToUpdate = onSameRow ? sortable.row : -1;
      const shiftLeft = onSameRow
        ? dragUtil.x < sortable.lastX && sortable.index > i
        : true;
      const shiftRight = onSameRow
        ? dragUtil.x > sortable.lastX && sortable.index < i
        : true;
      const validMove = testItemVM.positioned && (shiftLeft || shiftRight);
      const isDisabled = testItemVM.disabled;

      if (
        dragUtil.hitTest(this.sortableItems[i].element, this.threshold) &&
        validMove &&
        !isDisabled
      ) {
        gsap.set(this.placeholder, {
          x: testItemVM.x,
          y: testItemVM.y,
        });
        this.changePosition(sortable.index, i, rowToUpdate);
        this.hasBeenDragged = true;
        break;
      }
    }

    sortable.lastX = dragUtil.x;
  }

  public onRelease(dragUtil: any, sortable: Sortable) {
    const sortableEl = sortable.element;
    const validPosition = dragUtil.hitTest(
      this.elementRef.nativeElement,
      this.threshold
    );
    const newPosition = sortable.itemIndex !== sortable.index;
    if (validPosition && this.hasBeenDragged) {
      this.layoutInvalidated();
    } else if (!validPosition) {
      // Move tile back to last position if released out of bounds
      this.changePosition(sortable.index, sortable.lastIndex);
    }

    // `onDrag()` may be triggered again if users "throw" a sortable item.
    // Immediately setting `isDragging` to false prevents `layoutInvalidated()`
    // from incorrectly positioning siblings of the dragged element
    sortable.isDragging = false;

    gsap.to(this.placeholder, { autoAlpha: 0, duration: 0.2 });
    gsap.to(sortableEl, {
      duration: 0.2,
      opacity: 1,
      x: sortable.x,
      y: sortable.y,
      zIndex: ++this.zIndex,
      onComplete: () => {
        if (validPosition && this.hasBeenDragged && newPosition) {
          // prevent more dragging until sort Node gets updated from the server
          this.disableTiles(true);
          this.applySort(sortable).subscribe(() => {
            this.doneSorting(sortable);
            this.enableTiles(true);
          });
        } else {
          this.doneSorting(sortable);
        }
      },
    });
  }

  public onSortButton(
    direction: 'prev' | 'next',
    sortable: Sortable,
    buttonClicked: HTMLButtonElement
  ) {
    const newPosition =
      direction === 'prev' ? sortable.index - 1 : sortable.index + 1;
    const screenReaderAnnouncement = this.translateService.instant(
      'dgReorder_A11yItemMovedFormat',
      {
        a: sortable.index + 1, // convert 0 base to 1 base
        b: newPosition + 1, // convert 0 base to 1 base
      }
    );
    this.changePosition(sortable.index, newPosition, null, buttonClicked);
    this.applySort(sortable).subscribe();
    this.a11yService.announcePolite(screenReaderAnnouncement);
  }

  private resetSize() {
    this.colSize = 0;
    this.rowSize = 0;
    this.tileHeight = 0;
    this.colCount = 0;
  }

  private applySort(sortable: Sortable) {
    const i = sortable.index;
    const updatedItemArray = [];
    this.sortableItems.map((sortable) => {
      if (sortable.item) {
        updatedItemArray.push(sortable.item);
      }
    });

    return this.sort({
      list: updatedItemArray,
      itemIndex: i,
      movedToIndex: sortable.index - 1,
    });
  }

  private doneSorting(sortable: Sortable) {
    sortable.element.classList.remove('is_dragging');
    this.hasBeenDragged = false;
  }

  private createPlaceHolder() {
    const div = this.renderer.createElement('div');
    this.renderer.setAttribute(div, 'id', 'sortable-placeholder');
    this.renderer.setAttribute(div, 'class', 'sortable-placeholder');
    const childDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(childDiv, 'class', 'tile tile__placeholder');
    div.appendChild(childDiv);
    this.renderer.appendChild(this.elementRef.nativeElement, div);
    return div;
  }

  private changePosition(
    from: number,
    to: number,
    rowToUpdate?: number,
    sortButtonClicked?: HTMLButtonElement
  ) {
    const oldFromIndex = this.sortableItems[from].index;
    const oldToIndex = this.sortableItems[to].index;
    this.sortableItems[from].index = oldToIndex;
    this.sortableItems[to].index = oldFromIndex;
    // change array positions
    this.sortableItems.splice(to, 0, this.sortableItems.splice(from, 1)[0]);
    this.layoutInvalidated(rowToUpdate, sortButtonClicked);
  }

  private disableTiles(skipInvalidation = false) {
    this.sortableItems.forEach((sortable) => {
      if (sortable.draggableObj && sortable.draggableObj.length > 0) {
        sortable.draggableObj[0].disable();
      } else if (!sortable.disabled) {
        console.error('no draggable defined');
      }
      if (!skipInvalidation) {
        this.rowCount = 0;
        this.elementRef.nativeElement.style.height = '';
        sortable.element.style.transform = '';
        sortable.element.style.position = '';
        sortable.positioned = false;
      }
    });
    if (this.placeholder) {
      this.placeholder.remove();
    }
  }

  private enableTiles(skipInvalidation = false) {
    this.sortableItems.forEach((sortable) => {
      if (sortable.draggableObj && sortable.draggableObj.length > 0) {
        sortable.draggableObj[0].enable();
      } else if (!sortable.disabled) {
        console.error('no draggable defined');
      }
    });

    setTimeout(() => {
      if (!this.rowSize) {
        this.calculateSize();
      }
      if (!skipInvalidation) {
        this.layoutInvalidated();
      }
    });
    if (this.placeholder) {
      this.elementRef.nativeElement.append(this.placeholder);
    }
  }
}
