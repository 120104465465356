<div class="field-group" [class.disabled]="disabled">
  <input
    class="hide"
    type="text"
    ngbDatepicker
    disabled
    #ngbDatepicker="ngbDatepicker"
    (click)="ngbDatepicker.toggle()"
    [disabled]="disabled"
    [minDate]="minDate | ngbDate"
    [maxDate]="maxDate | ngbDate"
    [footerTemplate]="datepickerFooter"
    [firstDayOfWeek]="7"
    [placeholder]="placeholder || ''"
    [ngModel]="date | ngbDate"
    (dateSelect)="selectDate($event)"
    [attr.data-dgat]="customDgat || 'monthPicker-52a'"
    [attr.aria-label]="ariaLabel"
  />
  <button
    df-button
    dfButtonType="ghost"
    (click)="ngbDatepicker.toggle()"
    [attr.aria-label]="ariaLabel"
    data-dgat="monthPicker-b7d"
  >
    <df-icon icon="calendar" size="medium"></df-icon>
    {{ dateButtonLabel }}
  </button>
</div>

<ng-template #datepickerFooter>
  <div class="grid grid--align-center grid--justify-space-between">
    <button
      df-button
      dfButtonType="passive"
      (click)="clearDate()"
      [textContent]="i18n.Core_Clear"
      data-dgat="month-picker-0f8"
    ></button>
  </div>
</ng-template>
