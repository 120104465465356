<form role="form" [formGroup]="form" (ngSubmit)="submit()">
  <dgx-modal
    [canCancel]="true"
    [modalHeaderTitle]="headerText"
    [useDefaultForm]="false"
    [isSubmitPending]="isSubmitting"
    [isSubmitDisabled]="!(form.valid && form.dirty)"
    [submitButtonText]="i18n.Core_Save"
    (dismiss)="dismiss()"
    headerWrapperClasses="guts-p-h-2"
    submitButtonDgat="link-row-modal-65c"
  >
    <ng-container class="modal-body">
      <div class="form-wrap">
        <div class="guts-p-h-1 guts-p-t-1">
          <df-local-notification
            [type]="NotificationType.warning"
            [text]="i18n.OrgPlans_CustomTitlesAndDescriptionsNotLocalized"
            size="small"
            class="block guts-p-b-2"
          ></df-local-notification>

          <df-form-root
            [fields]="fields"
            [form]="form"
            [model]="model"
          ></df-form-root>
        </div>
      </div>
    </ng-container>
  </dgx-modal>
</form>

<ng-template #removeButton>
  <button
    df-button
    dfButtonType="destructive"
    class="full-width"
    (click)="removeRow()"
    data-dgat="link-row-modal-f2b"
  >
    {{ i18n.dgFlexRow_DeleteConfirmSubmit }}
  </button>
</ng-template>
