import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

@Component({
  selector: 'dgx-unsubscribe',
  template: `
    <span
      >{{ params.title }}
      <a
        class="link"
        href="{{ params.sourceUrl }}"
        data-dgat="unsubscribe-070"
        >{{ 'unsubscribeTemplate_ChangeSettings' | translate }}</a
      ></span
    >
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // "Change email settings"
})
export class UnsubscribeComponent extends NotificationItemComponentBase {
  protected buildTemplate(): string {
    return '';
  }
}
