import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePathsService } from '@app/routing/services/profile-paths.service';
import {
  SearchOrigin,
  SearchSubmitMethod,
  SearchTrackerService,
} from '@app/search/services/search-tracker.service';
import { AuthService } from '@app/shared/services/auth.service';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { switchMap, shareReplay } from 'rxjs/operators';
import { TagRatingButtonService } from '@app/tags/components/tag-rating-button/tag-rating-button.service';
import { CheckpointService } from '@app/tags/services/checkpoint.service';
import { EvaluationService } from '@app/tags/services/evaluation.service';
import { InternalTagRatingTypes } from '@app/tags/tags';

/**
 * This modal allows a user to review a summary of all their ratings for a given tag (skill)
 *
 * - All available rating types are visible to start/continue/view
 * - Completed rating types are visible to view even if start/continue are no longer available
 */
@Component({
  selector: 'dgx-tag-rating-overview',
  templateUrl: './tag-rating-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingOverviewComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  @Input() public searchCollection: boolean;
  @Input() public trackingLocation?: string;
  @Input() public tag: TagsApi.Tag;

  public vm$: Observable<any>;
  public i18n: { [key: string]: string };

  /** Allow for refresh of observable stream on tag rating updates */
  private refreshToken = new BehaviorSubject<undefined>(undefined);

  constructor(
    private translate: TranslateService,
    private profilePaths: ProfilePathsService,
    private tagsService: TagsService,
    private searchUrlService: SearchUrlService,
    private authService: AuthService,
    private searchTracker: SearchTrackerService,
    private activeModal: NgbActiveModal,
    private tagRatingButtonService: TagRatingButtonService,
    private checkpointService: CheckpointService,
    private evaluationService: EvaluationService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.i18n = this.translate.instant([
      'Core_FindContent',
      'Core_Done',
      'dgTagRating_SearchCollection',
    ]);
  }

  public get authUser() {
    return this.authService.authUser;
  }

  public get types(): { [key: string]: string } {
    return InternalTagRatingTypes;
  }

  public get isSkillInventoryClient(): boolean {
    return this.authUser.isSkillInventoryClient;
  }

  public get isSkillAnalyticsClient(): boolean {
    return this.authUser.isSkillAnalyticsClient;
  }

  public get searchLink(): { label: string; url: string } {
    // title is translated vs name is not translated
    const tagTitle = encodeURIComponent(this.tag.title);
    const vanityUrl = this.profilePaths.paths.vanityUrl;
    const profileBase = this.profilePaths.paths.profileBase;
    return this.searchCollection
      ? {
          label: this.i18n.dgTagRating_SearchCollection,
          url: `/${vanityUrl}${profileBase}#/collection?term=${tagTitle}`,
        }
      : {
          label: this.i18n.Core_FindContent,
          url: this.searchUrlService.getGlobalSearchURL(tagTitle),
        };
  }

  private get availableRatings(): Observable<TagsApi.RatingType[]> {
    return this.tagsService
      .getAvailableRatingTypes(this.tag.tagId)
      .pipe(shareReplay(1));
  }

  private get tagMetadata(): Observable<TagsApi.TagMetadata> {
    return this.tagsService.getTagMetadata(this.tag.tagId).pipe(shareReplay(1));
  }

  private get linkedTags(): Observable<TagsApi.Tag> {
    const isWorkdayEnabled =
      this.authService.authUser?.defaultOrgInfo?.settings
        .enableWorkdaySkillsIntegration;
    return isWorkdayEnabled
      ? this.tagsService.getLinkedTags(this.tag.tagId).pipe(shareReplay(1))
      : (of([]) as Observable<any>);
  }

  private get allCheckpoints(): Observable<TagsApi.Checkpoint[]> {
    return this.checkpointService
      .getAllCheckpoints(
        this.tag.tagId,
        this.authUser.viewerProfile.userProfileKey
      )
      .pipe(shareReplay(1));
  }

  private get isEvaluable(): Observable<boolean> {
    return this.evaluationService
      .getIsEvaluableByTagId(this.tag.tagId)
      .pipe(shareReplay(1));
  }

  public ngOnInit(): void {
    this.vm$ = combineLatest([
      this.refreshToken,
      this.tagMetadata,
      this.linkedTags,
      this.availableRatings,
      this.allCheckpoints,
      this.isEvaluable,
    ]).pipe(
      switchMap(
        ([
          refreshToken, // triggers refresh - do not remove
          tagMetadata,
          linkedTags,
          availableRatings,
          allCheckpoints,
          isEvaluable,
        ]) => {
          return this.tagsService
            .getTagRatingDetails(
              this.authUser.viewerProfile.userProfileKey,
              this.tag.tagId
            )
            .pipe(
              switchMap((ratingDetails) => {
                const tagDescription = tagMetadata?.description;
                const enabledRatingTypes =
                  this.tagRatingButtonService.getEnabledRatingTypes(
                    ratingDetails,
                    true, // sort by rank
                    true, // owner is viewing - always true for this modal
                    availableRatings,
                    allCheckpoints,
                    isEvaluable
                  );
                // Include new ratings details on the tag so child components update correctly
                const tag = {
                  ...this.tag,
                  ratings: ratingDetails,
                };
                this.cdr.markForCheck();
                return of({
                  tag,
                  tagDescription,
                  linkedTags,
                  ratingDetails,
                  availableRatings,
                  enabledRatingTypes,
                  allCheckpoints,
                  isEvaluable,
                });
              })
            );
        }
      )
    );

    // Trigger initialization of view model
    this.refreshToken.next(undefined);

    // Refresh view model when rating is updated
    this.tagsService.userTagsModified
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.refreshToken.next(undefined);
      });
  }

  public close(): void {
    this.activeModal.close();
  }

  public search(): void {
    this.close();

    this.searchTracker.setSearchData({
      submitMethod: SearchSubmitMethod.findContentClicked,
    });

    this.searchTracker.searchInitiated({
      origin: SearchOrigin.tagRatingOverviewModal,
      searchTerm: this.tag.title,
    });
  }

  public trackByFn(index: number, type: string): string {
    return type;
  }
}
