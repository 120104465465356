import { TrackerService } from '@app/shared/services/tracker.service';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/breadcrumbs.component.model';
import { PathwayFacade } from '@dg/pathways-rsm';
import { TranslateService } from '@ngx-translate/core';
import * as api from '@app/pathways/rsm/pathway-api.model';
import { Pathway } from '@app/pathways/pathway.model';

@Component({
  selector: 'dgx-pathway-downloadable-reports',
  templateUrl: './pathway-downloadable-reports.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathwayDownloadableReportsComponent implements OnInit {
  public pathway: Pathway | api.Pathway;
  public breadcrumbs: Breadcrumbs = [];
  public i18n = this.translate.instant([
    'Core_Followers',
    'dgDownloadableReports_DownloadableReports',
  ]);

  constructor(
    private facade: PathwayFacade,
    private trackingService: TrackerService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.trackingService.setLocation('Pathway Insights');

    this.pathway = this.facade.snapshot.pathway;

    this.breadcrumbs = this.getBreadcrumbs();
  }

  private getBreadcrumbs() {
    return [
      {
        id: 'pathway',
        label: this.pathway?.title,
        routerLink: '../../pathway',
      },
      {
        label: this.i18n.Core_Followers,
        routerLink: '../../enrollees',
      },
      {
        label: this.i18n.dgDownloadableReports_DownloadableReports,
      },
    ];
  }
}
