<div class="mention-input__wrapper">
  <div
    *ngIf="!isEditing; else editing"
    class="mention-input__readonly__wrapper"
  >
    <div
      [innerHTML]="value | mentionToHtml: { mentions: mentions }"
      class="mention-input__readonly"
    ></div>
  </div>
</div>
<ng-template #editing>
  <form [formGroup]="form">
    <div class="mention-input__input__wrapper">
      <textarea
        #inputRef
        class="mention-input__input no-border"
        [dgxAutofocus]="autoFocus"
        [dgxMention]="searchResults"
        [alreadyMentioned]="mentions"
        [orgId]="orgId"
        [groupPrivacy]="groupPrivacy"
        [groupId]="groupId"
        autosize
        [onlyGrow]="false"
        [minRows]="1"
        [maxRows]="10"
        placeholder="{{ placeholder }}"
        [attr.data-dgat]="dgatInput"
        [attr.maxlength]="maxLength + 100"
        [attr.aria-label]="ariaLabel"
        (addMention)="onAddMention($event)"
        (search)="onSearch($event)"
        (focus)="autoFocus ? undefined : focused.emit()"
        formControlName="comment"
      ></textarea>
    </div>
    <dgx-validate-field [message]="errorMessage"></dgx-validate-field>
  </form>
</ng-template>
