import { Injectable } from '@angular/core';
import { AccomplishmentMappingToAPI } from '../accomplishment.model';
import { TrackerService } from '@dg/shared-services';

@Injectable()
export class AccomplishmentTrackerService {
  constructor(private tracker: TrackerService) {}

  public trackAccomplishmentAdded(source: AccomplishmentMappingToAPI) {
    const {
      title,
      details,
      startDate,
      endDate,
      tags,
    } = source;

    const eventDataToTrack = {
      action: 'Accomplishment Added',
      properties: {
        title: title,
        noExpiration: details ? details.noExpiration : false,
        startDate,
        endDate,
        tags,
        skillTagCount: tags?.length,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }

  public trackAccomplishmentUpdated(source: AccomplishmentMappingToAPI) {
    const { title, details, startDate, endDate, tags, userOutcomeId } = source;

    const eventDataToTrack = {
      action: 'Accomplishment Updated',
      properties: {
        title: title,
        noExpiration: details ? details.noExpiration : false,
        startDate,
        endDate,
        tags,
        skillTagCount: tags?.length,
        userOutcomeId,
      },
    };
    this.tracker.trackEventData(eventDataToTrack);
  }
}
