import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

// types
import { Opportunity, Skill } from '@app/opportunities/opportunities-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';

// utils
import { sortSkillsForDisplay } from '@app/opportunities/utils';
import { pascalCaseKeys } from '@app/shared/utils/property';
import { getTagLevel } from '@app/shared/utils/tag-helpers';

// services
import { TagsService } from '@app/tags/services/tags.service';
import { AuthUser } from '@app/account/account-api.model';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TrackingEventArgs } from '@app/shared/services/tracking.model';
import { TagsApi } from '@app/tags/tag-api.model';

/**
 * Used to display the user's unrated skills! Implements the SkillsViewComponent.
 */
@Component({
  selector: 'dgx-user-rate-skills-modal',
  templateUrl: './user-rate-skills-modal.component.html',
  styleUrls: ['./user-rate-skills-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRateSkillsModalComponent implements OnInit {
  // Bindings - input
  /** The current logged-in user. */
  @Input() public user: AuthUser;
  /** The relevant opportunity. */
  @Input() public opportunity: Opportunity;
  /** The original skills. */
  @Input() public skills: Skill[];
  /** (Optional) A tracking location, to be passed to the TagsService. */
  @Input() public trackingLocation?: string;
  // Bindings - view
  @ViewChild('userSkillsModalAccordion', { static: false })
  // Bindings - local
  public i18n = this.translateService.instant([
    'Core_Close',
    'Opportunities_Skills_AddSkillRating',
    'Opportunities_UserRateSkillsModal_Header',
  ]);

  // Constructor
  constructor(
    private cdr: ChangeDetectorRef,
    private tagsService: TagsService,
    private trackerService: TrackerService,
    private translateService: TranslateService
  ) {}

  public ngOnInit() {
    this.skills = sortSkillsForDisplay(this.skills);
  }

  /**
   * Shell to use `getTagLevel` in the template.
   *
   * @param skill - The given skill.
   */
  public getCurrentSkillLevel(skill: Skill) {
    return getTagLevel(skill);
  }

  /**
   * Event listener for rating modal viewing.
   */
  public onRatingViewed(skill: Skill): void {
    // The rating modal has been viewed
    this.trackEvent('Skill Rating Viewed', {
      isFocusSkill: skill.isFocused,
      isOnProfile: skill.isFollowing,
      skillId: skill.tagId,
      skillName: skill.name,
    });
  }

  /**
   * When a tag rating is added (or removed).
   *
   * @param index - Index of the updated skill.
   * @param skills - Array of the skills, including the updated one.
   */
  public onUpdateSkills({
    skills,
    skill: updatedSkill,
  }: {
    skills: Skill[];
    skill?: Skill;
  }): void {
    // Grab the updated skill level as a number
    const updatedSkillLevel = getTagLevel(updatedSkill);
    // Call the BE to update our skill
    this.tagsService
      .rateTag(
        // The TagsService actually expects *null* here for removing
        // ratings.
        updatedSkillLevel ? updatedSkillLevel : null,
        InternalTagRatingTypes.self,
        updatedSkill as TagsApi.TagDetails,
        this.trackingLocation
      )
      // This *can* return `userTagRatingId`, which can be used to look up a
      // full tag and return that. We won't do that unless absolutely
      // necessary.
      .subscribe(() => {
        // Update our local skills, so that we can pass them back when
        // the modal closes. (See `ModalComponent`.)
        this.skills = sortSkillsForDisplay(skills);
        // Unless we explicitly mark for check here, OpportunityModalsService
        // won't have the updated value for the nested `rating` property.
        this.cdr.markForCheck();
      });
  }

  // Ideally we would be doing this tracking elsewhere, by subscribing
  // to @Outputs on this component in our service, but we don't have access
  // to the NgbModalRef with the way we've wrapped it in an Observable, and
  // trying to create a new method that returned the reference didn't work
  // as desired. TODO: Update this when a better solution is found.
  private trackEvent(
    action: string,
    properties?: { [name: string]: any }
  ): void {
    let data: TrackingEventArgs = {
      action,
      properties: {
        location: this.trackingLocation,
        opportunityName: this.opportunity.title,
        opportunityType: this.opportunity.type,
      },
    };
    // add properties, if defined
    if (properties) {
      data = {
        ...data,
        properties: pascalCaseKeys({
          ...data.properties,
          ...properties,
        }),
      };
    }
    // track event data
    this.trackerService.trackEventData(data);
  }
}
