/** Ideally this would be in the inputs module, but then we end up with a circular dependency between the input and
 * shared modules due to dgx-social-counts being used in the input-tile.component.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { InputPermissionsService } from '@app/inputs/services/input-permissions.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import { UserInputModalService } from '@app/inputs/services/user-input-modal.service';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import {
  SearchOrigin,
  SearchSubmitMethod,
  SearchTrackerService,
} from '@app/search/services/search-tracker.service';
import { SocialCountsService } from '@app/shared/components/social-counts/social-counts.service';
import { InputToLearningResourcePipe } from '@app/shared/pipes/input-to-learning-resource.pipe';
import { AuthService } from '@app/shared/services/auth.service';
import { DetailsModalService } from '@app/shared/services/content/details-modal.service';
import { SearchUrlService } from '@app/shared/services/search-url.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { camelCaseKeys } from '@app/shared/utils/property';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'dgx-social-counts',
  templateUrl: './social-counts.component.html',
  styleUrls: ['./social-counts.component.scss'],
})
export class SocialCounts implements OnInit, AfterViewInit {
  @Input() public config: any;
  @Input() public context: any;
  @Input() public exclusions: any;
  @Input() public useBadgeLayout = false;

  @Output() public toggleComments = new EventEmitter<void>();

  @ViewChild('tooltip') public socialCountTooltip: any;
  @ViewChildren('socialCountButton')
  private socialCountButtons: QueryList<ElementRef>;

  public isCollapsed: boolean = this.windowLayoutService.isMobile;
  public statistics: any = null;
  public tooltipOpenDelay = this.isCollapsed ? 0 : 1000;
  public i18n = this.translateService.instant([
    'InputsSvc_UsersTitle',
    'RecommendationsSvc_UsersTitle',
    'CommentsSvc_UsersTitle',
    'dgInputStatistics_Commented',
    'dgInputStatistics_PeopleWhoLiked',
    'dgInputStatistics_PeopleWhoDisliked',
    'QueueSvc_UsersTitle',
    'dgInputStatistics_PeopleWhoViewed',
    'Core_Topics',
    'dgComment_ViewConversation',
  ]);
  public tags$: Observable<TagsApi.Tag[]>;

  private _item: any;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private inputsService: InputsService,
    private recommendationsModalService: RecommendationsModalService,
    private tracker: TrackerService,
    private searchUrlService: SearchUrlService,
    private searchTrackerService: SearchTrackerService,
    private socialCountsService: SocialCountsService,
    private windowLayoutService: WindowLayoutService,
    private detailsModalService: DetailsModalService,
    private inputPermissionsService: InputPermissionsService,
    private inputToLearningPipe: InputToLearningResourcePipe,
    private userInputModalService: UserInputModalService
  ) {}

  public get item() {
    return this._item;
  }

  public get canComment() {
    return this.authService.authUser?.canComment;
  }

  public get isIframe() {
    return this.windowLayoutService.isIframe;
  }

  public get hasCommentsAction() {
    return (
      !this.item.isModal &&
      !this.item.isInputDetailsPage &&
      this.authService.authUser &&
      !this.config?.disableSocialClick
    );
  }

  public get canAssignStatistics() {
    const { inputType, contentType, resourceType } = this.item;
    const type = inputType || contentType || resourceType;

    return this.inputPermissionsService.canRate(type);
  }

  public get socialCounts() {
    const { inputType, contentType, resourceType, statistics } = this.item;
    const type = inputType || contentType || resourceType;

    // Opportunities have no social aspect.
    if (!type || type === 'Opportunity') {
      return;
    }

    if (!statistics || !this.canAssignStatistics) {
      return null;
    }

    return camelCaseKeys(
      this.socialCountsService.getInputSocialCounts(statistics, this.exclusions)
    );
  }

  public get shouldGetTags() {
    const { inputType, inputId } = this.item;

    return (
      !!inputType &&
      !!inputId &&
      !!this.authService.authUser &&
      !this.config.hideSkills
    );
  }

  public get buttonClasses() {
    return !this.useBadgeLayout
      ? 'social-counts__count'
      : 'badge badge-pill badge-neutral';
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  @Input() public set item(value: any) {
    this._item = camelCaseKeys(value);
  }

  public ngOnInit() {
    const { inputType, userInputId, inputId } = this.item;

    this.tags$ = this.shouldGetTags
      ? this.userInputModalService.getUserInputTags({
          inputType,
          userInputId,
          inputId,
        })
      : of([]);
  }

  public ngAfterViewInit() {
    /* if focus came from anything other than a sibling element,
          it probably had programmatic focus (eg. from a modal)
          and shouldn't get a tooltip popping up. */
    this.socialCountButtons.forEach((button) => {
      button.nativeElement.onfocus = (event) => {
        if (event?.relatedTarget?.closest('.js-content-container') === null) {
          event.stopPropagation();
        }
      };
    });
  }

  public socialCountsUrl(term: string) {
    return this.searchUrlService.getGlobalSearchURL(term);
  }

  public trackLinkClick(event: Event, term: string) {
    this.searchTrackerService.setSearchData({
      submitMethod: SearchSubmitMethod.socialCounts,
    });

    this.searchTrackerService.searchInitiated({
      searchTerm: term,
      origin: SearchOrigin.socialCounts,
    });

    event.stopPropagation();
  }

  public trackTagsView(event: Event) {
    event.stopPropagation();

    this.tracker.trackEventData({
      action: 'Content Topics Viewed',
      properties: this.item,
    });
  }

  public getAriaLabelForSocialCount(typeTitle: string, count: number) {
    return `${typeTitle}: ${count}`;
  }

  public showTopUsers(event: Event, type: string) {
    event.preventDefault();
    event.stopPropagation();

    this.closeTooltips();

    const { inputType, inputId, contentId } = this.item;

    switch (type) {
      case 'completions':
        this.inputsService.showTopUsers(
          {
            inputType,
            inputId: inputId || contentId,
          },
          event
        );
        this.tracker.trackEventData({
          action: 'Content Completions Viewed',
          properties: this.item,
        });
        break;
      case 'recommendations':
        this.recommendationsModalService
          .showTopUsersModal(inputType, inputId || contentId, event)
          .subscribe();
        this.tracker.trackEventData({
          action: 'Content Shares Viewed',
          properties: this.item,
        });
        break;
      case 'likes':
        this.inputsService.showUsersWhoRated(
          {
            inputType,
            inputId: inputId || contentId,
          },
          1,
          event
        );
        this.tracker.trackEventData({
          action: 'Content Likes Viewed',
          properties: this.item,
        });
        break;
    }
  }

  public closeTooltips() {
    if (this.socialCountTooltip) {
      this.socialCountTooltip.close();
    }
  }

  public openComments() {
    this.toggleComments.emit();
  }

  public openDetailsModal(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.hasCommentsAction) {
      return;
    }

    const resource = this.inputToLearningPipe.transform(this.item);
    return this.detailsModalService.openDetails(resource);
  }
}
