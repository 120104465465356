/** Limited keyboard key list. Note, keycode values are specified here only for legacy purposes.
 * New code should use the @see isKey function to test for these keys portably, by name.
 */
export enum Key {
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Escape = 27,
  Space = 32,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
  Backspace = 8,
  End = 35,
  Home = 36,
  ',' = 188,
  e = 69,
}

export function isKey(event: KeyboardEvent, ...keys: Key[]) {
  for (const key of keys) {
    const matchKeyName = Key[key]; // use the key enum value's name for matching

    if (event.key === matchKeyName) {
      return true;
    }
    if (key === Key.Escape && event.key?.startsWith('Esc')) {
      /* Matches 'Escape' or, for ie11, 'Esc' */
      return true;
    }
    if (key === Key.Space && (event.key === ' ' || event.key === 'Spacebar')) {
      // 'Spacebar' = ie11
      return true;
    }
    if (event.key?.startsWith('Arrow')) {
      // Newer browsers use 'Arrow' prefix for nav arrow keys
      if (event.key.substring('Arrow'.length) === matchKeyName) {
        return true;
      }
    }
  }
  return false;
}
