import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';

import {
  FlexRow,
  GetTargetFollowersSelectResult,
} from '@app/flex-framework/flex-api.model';
import { OrgTargetColumnIndex } from '@app/orgs/components/org-targets/org-targets.component';
import { TargetType } from '@app/shared/models/core-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { WindowLayoutService } from '@app/shared/services/window-layout/window-layout.service';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import {
  GetContainerTargetParams,
  GetOrgTargetParams,
  GetOrgTargetResponse,
  OrgTargetVM,
  Target,
  TargetSuggestionType,
} from '@app/target/target-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Intended to house shared methods.
 * See: {@link TargetService}, {@link TargetsService}.
 */
@Injectable({
  providedIn: 'root',
})
export class SharedTargetService {
  public isLoading$: Subject<boolean> = new Subject<boolean>();
  public isNotFound$: Subject<boolean> = new Subject<boolean>();

  private followTargetStatusChange = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private windowLayoutService: WindowLayoutService
  ) {}

  public getOrgTargets(
    orgTargetsParams: GetOrgTargetParams
  ): Observable<GetOrgTargetResponse> {
    return this.http
      .get('/targets/getOrgTargets', {
        params: {
          ...orgTargetsParams,
        },
      })
      .pipe(
        catchAndSurfaceError(this.translate.instant('TargetsSvc_RetrieveError'))
      );
  }

  public getBundleTargets(
    params: GetContainerTargetParams
  ): Observable<GetOrgTargetResponse> {
    return this.http
      .get<GetOrgTargetResponse>('/targets/GetContainerTargets', {
        params: {
          ...params,
        },
      })
      .pipe(
        catchAndSurfaceError(this.translate.instant('TargetsSvc_RetrieveError'))
      );
  }

  public getTargetTypeForEvents(
    targetType: TargetType | TargetSuggestionType
  ): string {
    if (!targetType) {
      return '';
    }

    const targetTypeLower = targetType.toLowerCase();
    const dirType: TargetType = 'Directory';
    const browseType: TargetType = 'Browse';
    if (
      targetTypeLower === dirType.toLowerCase() ||
      targetTypeLower === browseType.toLowerCase()
    ) {
      return 'Page';
    } else {
      return 'Plan';
    }
  }

  public getTagFromUrl(search: string): string {
    if (
      !search ||
      (search.indexOf('?tltag=') < 0 && search.indexOf('&tltag=') < 0)
    ) {
      return null;
    }
    let tltag = '';
    if (search.indexOf('&') < 0) {
      // the tltag is the only param
      tltag = search.split('=')[1];
    } else {
      tltag = search
        .split('&')
        .filter((q) => q.indexOf('tltag') >= 0)
        .map((q) => q.split('=')[1])[0];
    }
    return tltag;
  }

  // TODO: Old method, review usage need after modals using have been migrated
  public navigateToTarget({
    target,
    context,
    autoPopulate,
  }: {
    target: Target;
    context?: string;
    autoPopulate?: boolean;
  }) {
    if (target) {
      let contextParam = '';
      if (context) {
        contextParam = `?context=${context}`;
      }
      const targetHref = `/plan/${
        target.referenceId
      }${contextParam}/?editMode=true&autoSuggest=${
        autoPopulate ? true : false // be explicit about query param vals
      }`;
      if (this.windowLayoutService.isIframe) {
        window.open(targetHref, '_blank');
      } else {
        window.location.href = targetHref;
      }
    } else {
      window.location.reload();
    }
  }

  public canEditTarget(target: Target, userCanEditTarget: boolean): boolean {
    const authUser = this.authService.authUser;
    const isFeaturedPlan = target.targetType === 'Browse';

    if (isFeaturedPlan) {
      if (authUser?.canManageTargets) {
        return authUser?.canConfigureBrowse;
      }

      // if the plan is featured, the user must have permission for canConfigureBrowse AND be a collaborator if
      // the user does NOT have canManageTargets
      return (
        authUser?.canConfigureBrowse &&
        (userCanEditTarget || target.userCanEditTarget)
      );
    }

    // target.userCanEditTarget returns true for users who are the authors/collaborators or for users who have authUser.canManageTargets and plans have that have an orgId
    return userCanEditTarget || target.userCanEditTarget;
  }

  public getTypeDisplayName(
    type: TargetType | TargetSuggestionType,
    plural?: boolean,
    specific?: boolean
  ) {
    if (!type) {
      return '';
    }

    if (type === 'Browse') {
      type = 'Featured';
      specific = true;
    }

    return this.translate.instant(
      'TargetsSvc_Type' +
        type.replace(/\s/g, '') +
        (plural ? 's' : '') +
        (specific ? '_Specific' : '')
    );
  }

  /**
   * Returns a Subject that you can subscribe to so that you can know if the
   * user triggers a follow or unfollow of a plan and take action
   *
   * @returns boolean of whether the user is following or unfollowing the plan
   */
  public getFollowTargetStatusChange(): Subject<boolean> {
    return this.followTargetStatusChange;
  }

  /**
   * Follows the target any any additional resources the user selects.
   *
   * @param target the target we are following
   * @param resources  the additional resources for follow
   * @param tltag
   * @returns
   */
  public followTargetAndResources(
    target: Target,
    resources: any[],
    tltag: string
  ): Observable<void> {
    const targetId = target.targetId || target.resourceId;
    return this.http
      .post('/targets/followtargetresources', {
        targetId,
        resources: resources,
        trackableLinkTag: tltag,
      })
      .pipe(
        tap(() => {
          this.followTargetStatusChange.next(true);
          const action =
            this.getTargetTypeForEvents(target.targetType) + ' Followed';
          this.tracker.trackEventData({
            action: action,
            properties: {
              followerCount: target.followerCount,
              name: target.title,
              privacyId: target.privacyId,
              targetId,
              targetType: target.targetType,
            },
          });
        }),
        catchAndSurfaceError(this.translate.instant('TargetsSvc_FollowError'))
      );
  }

  public unfollowTarget(target: Target): Observable<void> {
    const targetId = target.targetId || target.resourceId;
    return this.http
      .delete('/targets/unfollowtarget', {
        params: { targetId },
      })
      .pipe(
        tap(() => {
          this.followTargetStatusChange.next(false);
          this.tracker.trackEventData({
            action:
              this.getTargetTypeForEvents(target.targetType) + ' Unfollowed',
            properties: {
              followerCount: target.followerCount,
              name: target.title,
              privacyId: target.privacyId,
              targetId,
              targetType: target.targetType,
            },
          });
        }),
        catchAndSurfaceError(this.translate.instant('TargetsSvc_UnfollowError'))
      );
  }

  public deleteTarget(target: Target | OrgTargetVM) {
    return this.http
      .delete('/targets/deletetarget', {
        params: { targetId: target.targetId },
      })
      .pipe(
        tap(() => {
          this.tracker.trackEventData({
            action: 'Target Deleted',
            properties: {
              TargetId: target.targetId,
            },
          });
          this.notifier.showSuccess(
            this.translate.instant('TargetsSvc_DeleteSuccess', {
              targetType: this.getTypeDisplayName(
                target.targetType
              ).toLowerCase(),
              targetTitle: target.name,
            })
          );
        }),
        catchAndSurfaceError(
          this.translate.instant('TargetsSvc_DeleteError', {
            targetType: this.getTypeDisplayName(
              target.targetType
            ).toLowerCase(),
          })
        )
      );
  }

  public moveTargetToChannel(targetId: number) {
    return this.http
      .get<void>('/targets/MoveTargetToChannel', {
        params: {
          targetId,
        },
      })
      .pipe(
        tap(() => {
          this.notifier.showSuccess(
            this.translate.instant('Targets_MoveSuccessMessage')
          );
        }),
        catchAndSurfaceError('Core_GeneralErrorMessage')
      );
  }

  /**
   * @deprecated 'Browse', where featuredTargets are displayed, is being phased out
   */
  public featureTarget(
    targetId: number,
    newFeaturedValue: boolean
  ): Observable<any> {
    return this.http
      .post<void>('/targets/featuretarget', {
        TargetId: targetId,
        NewFeaturedValue: newFeaturedValue,
      })
      .pipe(
        tap(() => {
          this.notifier.showSuccess(
            newFeaturedValue
              ? this.translate.instant('TargetsSvc_MakeFeaturedNotification')
              : this.translate.instant('TargetsSvc_RemoveFeaturedNotification')
          );
        }),
        catchAndSurfaceError(this.translate.instant('TargetsSvc_FeaturedError'))
      );
  }

  public getFollowingUsersFull(params: {
    id: number;
    orgId: number;
    sortColumn: number;
    sortAscending: boolean;
    skip: number;
    take: number;
  }): Observable<GetTargetFollowersSelectResult> {
    const { id, ...apiParams } = { ...params, targetId: params.id };
    return this.http
      .get<GetTargetFollowersSelectResult>(
        '/targets/gettargetfollowersselect',
        {
          params: apiParams,
        }
      )
      .pipe(
        catchAndSurfaceError(this.translate.instant('TargetsSvc_GetUserError'))
      );
  }

  /**
   * Saves the user re-ordered resource.
   * @param request
   * @return the items new designated node
   */
  public reorderTargetResource(request: {
    targetId: number;
    node: string;
    leftNode: string;
    rightNode: string;
  }): Observable<string> {
    return this.http
      .put<string>('/targets/reordertargetresource', request)
      .pipe(
        catchAndSurfaceError(
          this.translate.instant('TargetsSvc_ReorderResourceError')
        )
      );
  }

  public trackCareerPathingClick(event, id, row: FlexRow) {
    const item = row.targetResources.find(
      (resource) => resource.referenceId === id
    ).reference as any;
    const textString =
      row.flexRowType === 'LinkText'
        ? 'Call to Action Button Clicked'
        : (item.targetType || item.flexRowType) + ' Clicked';
    this.tracker.trackEventData({
      action: textString,
      category: 'Career Pathing',
      label: null,
      properties: item,
    });
  }
}
