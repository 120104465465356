import { Injectable } from '@angular/core';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import {
  MonthPickerFieldComponent,
  MonthPickerFieldParams,
} from '@app/form-fields/wrappers/month-picker-field/month-picker-field.component';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { RenderMode } from '../../user-input.model';

@Injectable({ providedIn: 'root' })
export class BookInfoRendererService implements FormRenderer {
  public readonly i18n = this.translate.instant([
    'BookFormCtrl_BookLength',
    'BookFormCtrl_BookTitle',
    'BookFormCtrl_AddCategory',
    'BookFormCtrl_SelectBook',
    'BookFormCtrl_SaveBook',
    'Core_AddGroups',
    'Core_AddGroupsInfo',
    'Core_OpenDatePicker',
  ]);

  constructor(
    private formBuilder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private translate: TranslateService // private domainService: BookFormDomainService, // private authService: AuthService
  ) {}

  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    let fields: DfFormFieldConfig<DfTemplateOptions>[] = [];
    return this.buildFormFields(context);
  }

  private buildFormFields(context: RendererContext): DfFormFieldConfig[] {
    const state = context.state();

    return [
      this.formBuilder
        .customField('title', '', context.templates.bookInfo, {
          book: state.book,
          isEditing: context.inputContext.isEditing,
        })
        .build(),
      this.formBuilder
        .fieldGroup(
          'completionInfo',
          [
            this.formBuilder
              .foreignField<MonthPickerFieldParams>(
                'dateRead',
                'BookFormCtrl_DateRead',
                MonthPickerFieldComponent.REGISTERED_FIELD_TYPE,
                {
                  isMaxDateToday: true,
                }
              )
              .styledBy('first-col df-form__col-half')
              .hiddenWhen(
                () => context.inputContext.renderMode === RenderMode.Pathways
              )
              .build(),
            this.formBuilder
              .optionalTextInput(
                'length',
                // Append parenthesized length units to label, falling back to 'Pages' if unspecified or 'Unknown'
                `${this.translate.instant(
                  'BookFormCtrl_Length'
                )} (${this.translate.instant(
                  'Core_' + context.state().book.durationUnitType &&
                    context.state().book.durationUnitType !== 'Unknown'
                    ? context.state().book.durationUnitType
                    : 'Pages'
                )})`
              )
              .ofType('number')
              .withMin(1)
              .withErrorMessages({
                min: this.translate.instant('Core_InvalidMinValue', { min: 1 }),
              })
              .readonlyWhen(() => context.inputContext.isEditing)
              .autofocused()
              .styledBy('df-form__col-half')
              .build(),
          ],
          'df-form__row'
        )
        .build(),
      this.formBuilder
        .optionalTextarea('comment', 'BookFormCtrl_WhatDidYouLearnLong')
        .withPlaceholder('BookFormCtrl_WhatDidYouLearnShort')
        .withDgatId('bookForm-797')
        .hiddenWhen(
          () =>
            // comment is independently associated with the user completion, and not available when editing
            !!state.isEditing ||
            context.inputContext.renderMode === RenderMode.Pathways
        )
        .build(),
      this.inputFieldBuilder.buildSkillsField(
        context.state().authUser.viewerInterests,
        false
      ),
    ];
  }
}
