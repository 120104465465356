import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { ResourceIdBuilder } from '../../resource-id-builder';

/** Represents a Reply to Comment notification */
@Component({
  selector: 'dgx-reply-to-comment',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplyToCommentComponent extends NotificationItemComponentBase {
  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    // compose a valid resource ID permutation based on the notification parameters
    const contentResource = resourceIdBuilder
      .withSegment(
        `replyToComment_${
          this.params.title === 'Post' ? 'Comment' : 'Takeaway'
        }`
      )
      .withOptionalGroup()
      .withSingleOrPlural()
      .asFormat();

    return this.translateHtml(
      contentResource,
      {
        nameHtml: this.userFullNameTemplate,
        groupNameHtml: this.groupNameTemplate,
        takeawayTitleHtml: this
          .html`<span class="font-semibold">${this.params.title}</span>`,
        number: this.params.personCount,
      }
      // comments:
      // "{{nameHtml}} [and {{number}} others] replied to your comment [in {{groupNameHtml}}]"

      // takeaway
      // {{nameHtml}} deleted this {{type}} from the {{resourceTitle}} {{resourceType}}
    );
  }
}
