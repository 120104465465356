import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceIdBuilder } from '../../resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents an Assigned Learning notification */
@Component({
  selector: 'dgx-single-recommendation',
  template: ` <span [innerHTML]="safeContent"></span> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleRecommendationComponent extends NotificationItemComponentBase {
  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    // compose a valid resource ID permutation based on the notification parameters
    let contentResource: string;
    if (this.params.inputType === 'Target') {
      contentResource = resourceIdBuilder
        .withSegment('singleRecommendation_')
        .withYouOrGroup()
        .withFollow()
        .asFormat()
        // HACK: fixup 'GroupAlready' to be 'GroupYouAlready' for non-conformant key
        // TODO: fix the string in the database instead
        .replace('GroupA', 'GroupYouA');
    } else {
      contentResource = resourceIdBuilder
        .withSegment('singleRecommendation_')
        .withYouOrGroup()
        .withOptionalCompletion()
        .asFormat()
        // HACK: fixup 'YouAlreadyCompletedPathway' to be camelCase for non-conformant keys
        // TODO: fix the strings in the database instead
        .replace('YouAlreadyCompletedP', 'youAlreadyCompletedPathway');
    }

    return this.translateHtml(contentResource, {
      nameHtml: this.userFullNameTemplate,
      groupNameHtml: this.groupNameTemplate,
      title: this.trimmedTitleTemplate,
      date: this.dueDateTemplate,
      type: this.inputTypeTemplate,
    });
    // "{{nameHtml}} shared (this {{type}}|the pathway {{title}}) with (you|{{groupNameHtml}})
    // [, but you’ve already completed it! Great job, overachiever]."
  }
}

/* Used translation keys
singleRecommendation_GroupFormat;
singleRecommendation_GroupDateFormat;
singleRecommendation_GroupAlreadyCompletedFormat;
singleRecommendation_GroupAlreadyCompletedDateFormat;
singleRecommendation_YouFormat;
singleRecommendation_YouDateFormat;
singleRecommendation_YouAlreadyCompletedFormat;
singleRecommendation_YouAlreadyCompletedDateFormat;
singleRecommendation_youAlreadyCompletedPathwayFormat;      // should be '...You...'
singleRecommendation_youAlreadyCompletedPathwayDateFormat;  // should be '...You...'

singleRecommendation_YouFollowFormat;
singleRecommendation_GroupFollowFormat;
singleRecommendation_YouAlreadyFollowFormat;
singleRecommendation_GroupYouAlreadyFollowFormat;           // should be '...GroupAlready...'
*/
