<form (submit)="confirm($event)">
  <dgx-modal
    [canCancel]="true"
    [submitButtonText]="i18n.CommentsCtrl_YesDelete"
    [useDefaultForm]="false"
    [isHeaderBorderless]="true"
    submitButtonType="destructive"
  >
    <!-- Modal Body -->
    <ng-container class="modal-body">
      <div class="__body">
        <h1 class="__title">
          {{ i18n.CommentsCtrl_DeleteComment }}
        </h1>
        <dgx-comment
          [comment]="comment"
          [isPreview]="true"
          class="__comment"
        ></dgx-comment>
      </div>
    </ng-container>
  </dgx-modal>
</form>
