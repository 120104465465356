import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TranslateService } from '@ngx-translate/core';
import { TagRatingButtonBaseComponent } from '../tag-rating-button-base.component';

@Component({
  selector: 'dgx-external-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalRatingButtonComponent extends TagRatingButtonBaseComponent {
  @Input() public type: string;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    @Inject(WindowToken) private windowRef: Window
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get url(): string {
    return this.ratingType?.url;
  }

  public get ratingLevelLabel(): string {
    return this.allRatings[0].level;
  }

  public get showPendingRatingNotification(): boolean {
    return false;
  }

  public get completedRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ViewExternalRating', {
      ProviderName: this.allRatings?.[0]?.providerName,
    });
  }

  public get showCTA(): boolean {
    return !!this.url && this.ownerIsViewing;
  }

  public get ratingDescriptionLabel(): string {
    return this.translateService.instant(
      'dgTagRating_AddExternalRatingDetail',
      {
        ProviderName: this.ratingType?.providerName,
      }
    );
  }

  public get dgatType(): string {
    return `external-rating-button`;
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    if (this.ownerIsViewing && this.url) {
      this.windowRef.open(this.url, '_blank');
    }
  }
}
