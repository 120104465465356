import { EmitEvent } from '@app/shared/services/event-bus';
import { LayoutAspect } from '@degreed/apollo-angular';
import { StoreState } from '@dg/shared-rsm';

import { UploadResponse } from './utils';
import { getHexColorFromCSSVariable } from './utils/html.utils';

// ************************************************************
// Branding Events
// ************************************************************

export enum BrandingEvents {
  LAYOUT_CHANGED = 'brandingLayoutChanged',
}

export class BrandingEvent<K = BrandingState> implements EmitEvent<K> {
  constructor(
    public type: string,
    public data?: K
  ) {}
}

export function brandingChanged(branding: BrandingState): BrandingEvent {
  return new BrandingEvent(BrandingEvents.LAYOUT_CHANGED, branding);
}

// ************************************************************
// Navigation Types and State
// ************************************************************

export type UpdateImageFn = (
  settings: Partial<LogoData>
) => Promise<UploadResponse>;

export type ColorType = 'background' | 'highlight' | 'text' | 'separator';
export type ImageType = 'logo' | 'mark' | 'endorsement';
export type TabType = 'navigation' | 'endorsements' | 'orgInfo';

// Upload data
export interface LogoData {
  file: File;
  altText: string;
  imageType?: ImageType;
}

export interface LoadStatus {
  isLoading?: boolean;
  progress?: number;
  showSkeleton?: boolean;
}
export interface LogoState extends LoadStatus {
  backgroundColor?: string;
  imageType?: ImageType;
  fileName?: string;
  url: string;
  altText: string;
}

export interface NavigationState {
  isDirty: boolean;
  logo: LogoState;
  mark: LogoState;
  colors: {
    background: string;
    highlight: string;
    text: string;
    separator: string;
  };
}

export interface NavigationAPI {
  updateColor(color: string, type: ColorType): void;
  updateMark: UpdateImageFn;
  updateLogo: UpdateImageFn;
}

export type NavigationViewModel = NavigationState & NavigationAPI;

// ************************************************************
// Endorsements Types and State
// ************************************************************

export interface EndorsementsState {
  isDirty: boolean;
  endorsement: LogoState;
}

export interface EndorsementsAPI {
  updateImage(settings: Partial<LogoData>);
}

export type EndorsementsViewModel = EndorsementsState & EndorsementsAPI;

// ************************************************************
// Org Inforemation Types and State
// ************************************************************

export interface OrgInfoState {
  isDirty: boolean;
  orgName: string;
  useInOnboarding: boolean;
}

export interface OrgInfoAPI {
  updateInfo(settings: Partial<OrgInfoState>);
}

export type OrgInfoViewModel = OrgInfoState & OrgInfoAPI;

// ************************************************************
// Branding Types and State
// ************************************************************

export interface BrandingState extends StoreState {
  selectedTab: TabType | null;

  // Computed from children states
  isDirty?: boolean;
  dirtyCount?: number;

  navigation: NavigationState;
  endorsements: EndorsementsState;
  orgInfo: OrgInfoState;
}

export interface BrandingAPI {
  discardChanges: () => Promise<void>;
  publishChanges: () => Promise<void>;
}

export type BrandingViewModel = BrandingState & BrandingAPI;

// ************************************************************
// Initializers
// ************************************************************

/**
 * Initializer for Org custom Branding state
 */
export const initBrandingState = (): BrandingState => ({
  selectedTab: null,

  navigation: initNavigationState(),
  endorsements: initEndorsementsState(),
  orgInfo: initOrgInfoState(),
});

export const initNavigationState = (): NavigationState => ({
  isDirty: false,
  logo: { url: '', altText: '', isLoading: false, progress: 0, fileName: '' },
  mark: { url: '', altText: '', isLoading: false, progress: 0, fileName: '' },
  colors: {
    background: '#ffffff',
    text: getHexColorFromCSSVariable('--apollo-color-neutral-800'),
    highlight: getHexColorFromCSSVariable('--apollo-color-blue-800'),
    separator: getHexColorFromCSSVariable('--apollo-color-neutral-200'),
  },
});

export const initEndorsementsState = (): EndorsementsState => ({
  isDirty: false,
  endorsement: { url: '', altText: '', isLoading: false, progress: 0 },
});

export const initOrgInfoState = (): OrgInfoState => ({
  isDirty: false,
  useInOnboarding: true,
  orgName: '',
});

export const initAspect = (): LayoutAspect =>
  ({
    brand: {
      routerLink: '/#',
      mark: {
        url: "data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8_7)'%3E%3Cpath d='M31.0303 0.444443H0.969701C0.501095 0.444443 0.121216 1.83733 0.121216 3.55555V28.4444C0.121216 30.1627 0.501095 31.5556 0.969701 31.5556H31.0303C31.4989 31.5556 31.8788 30.1627 31.8788 28.4444V3.55555C31.8788 1.83733 31.4989 0.444443 31.0303 0.444443Z' fill='%23F4F6F7'/%3E%3Cpath d='M31.0303 0.444443H0.969701C0.501095 0.444443 0.121216 1.83733 0.121216 3.55555V28.4444C0.121216 30.1627 0.501095 31.5556 0.969701 31.5556H31.0303C31.4989 31.5556 31.8788 30.1627 31.8788 28.4444V3.55555C31.8788 1.83733 31.4989 0.444443 31.0303 0.444443Z' stroke='%23C9D3D8' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='8 8'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8_7'%3E%3Crect width='32' height='32' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A",
        altText: 'Logo Mark',
      },
      logo: {
        url: "data:image/svg+xml,%3Csvg width='118' height='32' viewBox='0 0 118 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8_2)'%3E%3Cpath d='M113.778 0.444443H3.55557C1.83735 0.444443 0.444458 1.83733 0.444458 3.55555V28.4444C0.444458 30.1627 1.83735 31.5556 3.55557 31.5556H113.778C115.496 31.5556 116.889 30.1627 116.889 28.4444V3.55555C116.889 1.83733 115.496 0.444443 113.778 0.444443Z' fill='%23F4F6F7'/%3E%3Cpath d='M113.778 0.444443H3.55557C1.83735 0.444443 0.444458 1.83733 0.444458 3.55555V28.4444C0.444458 30.1627 1.83735 31.5556 3.55557 31.5556H113.778C115.496 31.5556 116.889 30.1627 116.889 28.4444V3.55555C116.889 1.83733 115.496 0.444443 113.778 0.444443Z' stroke='%23C9D3D8' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='8 8'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8_2'%3E%3Crect width='117.333' height='32' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A",
        altText: 'Logo',
      },
      colors: {
        background: '#ffffff',
        text: 'var(--apollo-color-neutral-800)',
        highlight: 'var(--apollo-color-blue-800)',
        separator: 'var(--apollo-color-neutral-200)',
      },
    },
    features: {
      search: {
        enabled: true,
        visible: true,
        text: 'Search',
        i18n: 'global.navigation.learner.features.search, Core_Search',
        dgat: 'global.navigation.learner.features.search',
        analytics: 'global.navigation.learner.features.search',
      },
      addContent: {
        enabled: true,
        visible: true,
        text: 'Add',
        i18n: 'global.navigation.learner.features.add-content',
        titleText: 'Add to Profile',
        titleI18n: 'global.navigation.learner.features.add-to-profile',
        dgat: 'global.navigation.learner.features.add-content',
        analytics: 'global.navigation.learner.features.add-content',
      },
      beta: {
        enabled: true,
        visible: true,
        text: 'Beta',
        i18n: 'global.navigation.learner.features.beta',
        dgat: 'global.navigation.learner.features.beta',
      },
      footerBranding: {
        enabled: true,
        visible: false,
        text: 'Powered by',
        i18n: 'global.navigation.learner.features.footer-branding',
        dgat: 'global.navigation.learner.features.footer-branding',
      },
      sidebarBranding: {
        enabled: true,
        visible: true,
        text: 'Powered by',
        i18n: 'global.navigation.learner.features.sidebar-branding',
        dgat: 'global.navigation.learner.features.sidebar-branding',
      },
      switcher: {
        enabled: true,
        visible: true,
        text: 'Show Admin View',
        i18n: 'global.navigation.learner.features.show-admin-view',
        dgat: 'global.navigation.learner.features.show-admin-view',
        analytics: 'global.navigation.learner.features.show-admin-view',
        href: '#',
      },
    },
    navigation: {
      top: [
        {
          headerTitle: 'Home',
          headerTitleI18n: 'AppHeader_HomeTitle',
          text: 'Home',
          i18n: 'global.navigation.learner.sidebar.home, Core_Home',
          dgat: 'global.navigation.learner.sidebar.home',
          analytics: 'global.navigation.learner.sidebar.home',
          icon: 'home',
          children: [
            {
              text: 'My Learning',
              i18n: 'global.navigation.learner.sidebar.home.my-learning',
              dgat: 'global.navigation.learner.sidebar.home.my-learning',
              analytics: 'global.navigation.learner.sidebar.home.my-learning',
              routerLink: '/#',
            },
            {
              text: 'Assignments',
              i18n: 'global.navigation.learner.sidebar.home.assignments, DashboardNav_Assignments',
              dgat: 'global.navigation.learner.sidebar.home.assignments',
              analytics: 'global.navigation.learner.sidebar.home.assignments',
              routerLink: '/#',
            },
            {
              text: 'Saved',
              i18n: 'global.navigation.learner.sidebar.home.saved, Core_Saved',
              dgat: 'global.navigation.learner.sidebar.home.saved',
              analytics: 'global.navigation.learner.sidebar.home.saved',
              routerLink: '/#',
            },
            {
              text: 'Shared',
              i18n: 'global.navigation.learner.sidebar.home.shared, Core_Shared',
              dgat: 'global.navigation.learner.sidebar.home.shared',
              analytics: 'global.navigation.learner.sidebar.home.shared',
              routerLink: '/#',
            },
            {
              text: 'Pathways',
              i18n: 'global.navigation.learner.sidebar.home.pathways, Core_Pathways',
              dgat: 'global.navigation.learner.sidebar.home.pathways',
              analytics: 'global.navigation.learner.sidebar.home.pathways',
              routerLink: '/#',
            },
            {
              text: 'Plans',
              i18n: 'global.navigation.learner.sidebar.home.plans, Core_Plans',
              dgat: 'global.navigation.learner.sidebar.home.plans',
              analytics: 'global.navigation.learner.sidebar.home.plans',
              routerLink: '/#',
            },
            {
              text: 'Groups',
              i18n: 'global.navigation.learner.sidebar.home.groups, Core_Groups',
              dgat: 'global.navigation.learner.sidebar.home.groups',
              analytics: 'global.navigation.learner.sidebar.home.groups',
              routerLink: '/#',
            },
          ],
        },
        {
          headerTitle: 'Featured',
          headerTitleI18n:
            'global.navigation.learner.sidebar.featured, AppHeader_FeaturedTitle',
          text: 'Featured',
          i18n: 'global.navigation.learner.sidebar.featured, AppHeader_FeaturedTitle',
          dgat: 'global.navigation.learner.sidebar.featured',
          icon: 'star',
          analytics: 'global.navigation.learner.sidebar.featured',
          href: '#',
        },
        {
          headerTitle: 'Discover',
          headerTitleI18n:
            'global.navigation.learner.sidebar.discover, Core_Discover',
          text: 'Discover',
          i18n: 'global.navigation.learner.sidebar.discover, Core_Search',
          dgat: 'global.navigation.learner.sidebar.discover',
          icon: 'magnifying-glass',
          rtlMirrorIcon: true,
          analytics: 'global.navigation.learner.sidebar.discover',
          routerLink: '/#',
        },
        {
          headerTitle: 'Skill Coach',
          headerTitleI18n:
            'global.navigation.learner.sidebar.skill-coach, AppHeader_SkillCoachTitle',
          text: 'Skill Coach',
          i18n: 'global.navigation.learner.sidebar.skill-coach, Core_SkillCoach',
          dgat: 'global.navigation.learner.sidebar.skill-coach',
          icon: 'user-group',
          analytics: 'global.navigation.learner.sidebar.skill-coach',
          children: [
            {
              text: 'Members',
              i18n: 'global.navigation.learner.sidebar.skill-coach.members, Core_Members',
              dgat: 'global.navigation.learner.sidebar.skill-coach.members',
              analytics:
                'global.navigation.learner.sidebar.skill-coach.members',
              routerLink: '/#',
            },
            {
              text: 'Team Skills',
              i18n: 'global.navigation.learner.sidebar.skill-coach.team-skills, Team_Skills',
              dgat: 'global.navigation.learner.sidebar.skill-coach.team-skills',
              analytics:
                'global.navigation.learner.sidebar.skill-coach.team-skills',
              routerLink: '/#',
            },
            {
              text: 'Learning Insights',
              i18n: 'global.navigation.learner.sidebar.skill-coach.learning-insights, Team_LearningInsights',
              dgat: 'global.navigation.learner.sidebar.skill-coach.learning-insights',
              analytics:
                'global.navigation.learner.sidebar.skill-coach.learning-insights',
              routerLink: '/#',
            },
            {
              text: 'Skill Insights',
              i18n: 'global.navigation.learner.sidebar.skill-coach.skill-insights, Team_SkillInsights',
              dgat: 'global.navigation.learner.sidebar.skill-coach.skill-insights',
              analytics:
                'global.navigation.learner.sidebar.skill-coach.skill-insights',
              routerLink: '/#',
            },
          ],
        },
      ],
    },
  }) satisfies LayoutAspect;
