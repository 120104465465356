import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { FormRenderer, RendererContext } from '../../form-renderer.model';
import { AbstractControl } from '@angular/forms';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils/form-helpers';
import { MediaMetadataStatus } from '../../media-modal/media-modal-facade-base';
import { TranslateService } from '@ngx-translate/core';
import { MediaFormModel } from '../../media-modal/media-form.model';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { ArticleCommonFieldBuilder } from '../article-common-field.builder';

/** Creates an array of form field configurations for adding/editing Articles for pathways and user profile content */
@Injectable({ providedIn: 'root' })
export class DefaultArticleFormRenderer implements FormRenderer {
  private urlValidator = {
    urlValidation: {
      expression: (control: AbstractControl, _) => {
        const valid = HTTP_REQUIRED_URL_PATTERN.test(control.value);
        return valid;
      },
      message: this.translate.instant('MediaFormCtrl_UrlRequired'),
    },
  };

  public expandAdvanced = new BehaviorSubject(false);
  constructor(
    private translate: TranslateService,
    private builder: DfFormFieldBuilder,
    private inputFieldBuilder: InputCommonFieldBuilder,
    private articleFieldBuilder: ArticleCommonFieldBuilder
  ) {}

  public render(
    context: RendererContext<MediaFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    return context.state().isMediaParsed || context.inputContext.isEditing
      ? this.renderExpandedForm(context)
      : this.renderInitialForm(context);
  }

  protected renderInitialForm(context: RendererContext<MediaFormModel>) {
    const vm = context.state();
    // Initial article loader input
    return [
      // Media URL
      this.builder
        .requiredTextInput('mediaUrl', 'MediaFormCtrl_ArticleUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidator)
        .withDgatId('articleForm-97a')
        .autofocused()
        .append({
          addOnType: 'button', // TODO: This button needs to be a spinner button! PD-58472
          contentType: 'text',
          content: vm.mediaMetadataStatus$.pipe(
            map(
              (s) =>
                (s === MediaMetadataStatus.Parsing
                  ? this.translate.instant('Core_Submitting')
                  : this.translate.instant('Core_Submit')) as string
            )
          ),
          options: {
            // We want this button to act like a submit button in the sense that it's the default button,
            // but since the field uses update on blur, the value won't be in the form control yet and will not validate
            // The`onKeyDown` listener emulates the default button behavior
            buttonType: 'button',
            clickHandler: () => {
              vm.parseMediaUrl();
            },
            working: vm.mediaMetadataStatus$.pipe(
              map((s) => (s === MediaMetadataStatus.Parsing ? true : false))
            ),
          },
        })
        .build(),
    ];
  }

  /** Expanded field set, shown when article has been parsed */
  protected renderExpandedForm(context: RendererContext<MediaFormModel>) {
    const vm = context.state();
    return [
      // Media URL
      this.builder
        .requiredTextInput('mediaUrl', 'MediaFormCtrl_ArticleUrl')
        .ofType('url')
        .validatedByIndexed(this.urlValidator)
        .withDgatId('articleForm-391')
        .autofocused()
        .withHelp(context.templates.urlCustomHelp)
        .build(),
      this.articleFieldBuilder.buildTitleField(
        vm.canEditTitle,
        context.templates.readonlyField
      ),
      // Provider name
      this.articleFieldBuilder.buildProviderField(
        vm.canEditProvider,
        context.templates.readonlyField
      ),
      // Add to catalog checkbox
      this.inputFieldBuilder.buildPathwaysAddToCatalogField(
        context.inputContext.renderMode,
        vm.canManageContent,
        vm.organizationName,
        'MediaFormCtrl_AddToCatalogFormat',
        context.templates.addToCatalogDupsHelp,
        vm.onAddToCatalogChange
      ),
      // User authored ("I wrote this")
      this.buildUserAuthoredField(context.inputContext.isCompleting),
      // Comment
      this.buildCommentField(
        context.inputContext.isCompleting,
        context.inputContext.isEditing,
        vm.canComment
      ),
      // Skills
      this.inputFieldBuilder.buildSkillsField(vm.topTags, false), // we have a lot of fields to show in catalog mode so hide top tags for compactness
      // Consumer Groups
      this.inputFieldBuilder.buildMediaConsumerGroupsField(
        context.inputContext.isChannel,
        context.inputContext.isCompleting,
        vm.organizationId,
        context.templates.groups
      ),
    ];
  }

  protected buildUserAuthoredField(isCompleting: boolean) {
    return this.builder
      .checkbox('isUserAuthored', 'MediaFormCtrl_IWroteArticle')
      .withDgatId('articleForm-951')
      .hiddenWhen(() => !isCompleting)
      .build();
  }

  protected buildCommentField(
    isCompleting: boolean,
    isEditing: boolean,
    userCanComment: boolean
  ) {
    return this.builder
      .optionalTextarea('comment', 'MediaFormCtrl_WhatDidYouLearnLong')
      .withPlaceholder('MediaFormCtrl_WhatDidYouLearnShort')
      .withDgatId('articleForm-aaf')
      .hiddenWhen(
        () =>
          // comment is independently associated with the user completion, and not available when editing
          !(isCompleting && userCanComment) || isEditing
      )
      .build();
  }
}
