<!-- todo kas need to update classes and dgats then update any cypress tests based on them -->
<ul *ngIf="vm$ | async as vm" [class.guts-m-t-2]="!hasFauxParent">
  <li
    *ngFor="
      let subsection of getSubsections(vm.pathway, subsections);
      trackBy: trackById
    "
    dgxIntersectionObserver
    [unObserve]="false"
    [useDefaultViewArea]="true"
    (onIntersection)="
      onItemIsVisible('subsection', subsection.levelNumber, subsection.number)
    "
    data-dgat="pathway-author-subsection-361"
  >
    <dgx-pathway-author-subsection
      [isFauxSubsection]="isFauxSubsection"
      [subsection]="subsection"
      [menuConfig]="createMenuConfig(subsection)"
      (subsectionsAddStepClick)="addSteps($event, vm.pathway)"
    ></dgx-pathway-author-subsection>
  </li>
</ul>
