<ng-container [ngSwitch]="displayType">
  <ng-container *ngSwitchCase="'hosted'">
    <dgx-content-hosting-preview
      class="l_flex center-item content-preview"
    ></dgx-content-hosting-preview>
  </ng-container>
  <ng-container *ngSwitchCase="'hmm'">
    <dgx-embedded-learning
      [inputId]="inputId"
      [inputType]="castedInputType()"
      [embedSrc]="HMMEmbeddedURL"
    ></dgx-embedded-learning>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="grid center-item">
      <div
        class="rel {{ inputContainerWidthClass }} grid__col--bleed center-item"
      >
        <div
          class="grid__cell guts-p-h-1 m-guts-p-h-0"
          [ngClass]="{ 'guts-p-t-5': !isNativeApp }"
        >
          <!-- removed cred spark conditional, bring that back when migrating cred spark-->
          <dgx-input-page [isNativeApp]="isNativeApp"> </dgx-input-page>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
