import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

import {
  FlexRow,
  FlexRowSummary,
  FreeformTextRow,
  OptionalResourceRow,
} from '@app/flex-framework/flex-api.model';
import { FlexRowService } from '@app/flex-framework/services/flex-row.service';
import { ResourceImage } from '@app/shared/services/resource-image/resource-image.model';
import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import {
  markUnchangedStringAsPristine,
  warnCharPercentLimited,
} from '@app/shared/utils';
import { isCharLimitedValidator } from '@app/shared/validators/is-char-limited.validator';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';
import { InputImageUpload } from '@app/uploader/upload-section/adapters/input-image-upload.adapter';
import { UploaderService } from '@app/uploader/uploader.service';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  NotificationType,
} from '@lib/fresco';
import { LDFlagsService } from '@dg/shared-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

interface LocalModel {
  sectionTitle: string;
  freeformTextValue: string;
  image: ResourceImage;
}

@Component({
  selector: 'dgx-free-text-row-modal',
  templateUrl: './free-text-row-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeTextRowModal implements AfterViewInit, OnInit {
  // Translation strings
  public i18n = this.translate.instant([
    'Core_FieldRequired',
    'Core_Delete',
    'Core_Save',
    'dgFlexRow_AddTextAndImage',
    'dgFlexRow_CustomErrors_RequiredMultiple',
    'dgFlexRow_EditTextAndImage',
    'Core_Optional',
    'dgFlexRow_DeleteConfirmSubmit',
    'dgFlexRow_DeleteSuccess',
    'dgFlexRow_EditSectionTitle',
    'dgFlexRow_LinkTitleHelperText',
    'dgFlexRow_TextTitle',
    'dgFlexRow_TextTitlePlaceholder',
    'dgFlexRow_EditSectionDetails',
    'dgImageEdit_DocumentationLabel',
    'Markdown_MarkdownSupported',
    'Markdown_MarkdownLearnMore',
    'OrgPlans_ImageUpload',
    'OrgPlans_CustomTitlesAndTextNotLocalized',
  ]);

  public readonly NotificationType = NotificationType;

  public headerText: string = this.i18n.dgFlexRow_AddTextAndImage;

  // Form properties
  public fields: DfFormFieldConfig[];
  public form: FormGroup = new FormGroup({});
  public model: LocalModel = {
    sectionTitle: undefined,
    freeformTextValue: undefined,
    image: undefined,
  };
  public options: FormlyFormOptions = {};
  public freeformText: FreeformTextRow;
  public image: ResourceImage;
  public uploadAdapter: InputImageUpload;
  public descriptionCharLimit = 35000;
  public modalInstructions: string;

  // Boolean flags
  public isEditingImage: boolean = false;
  public isSubmitting: boolean = false;
  public showPlanSimplification = this.ldFlagsService.showPlanSimplification;

  @Input() public flexRowSummary: FlexRowSummary;
  @Input() public row: FlexRow;
  @Input() public isEditing: boolean;

  @ViewChild('imageUploader') public imageUploaderRef: TemplateRef<any>;
  // This can be removed with the Plan Simplification (2.0) flag
  @ViewChild('imageUploaderOld') public imageUploaderOldRef: TemplateRef<any>;
  @ViewChild('markdown') public markdownRef: TemplateRef<any>;
  @ViewChild('removeButton')
  public removeButtonRef: TemplateRef<any>;

  constructor(
    private activeModal: NgbActiveModal,
    private builder: DfFormFieldBuilder,
    private cdr: ChangeDetectorRef,
    private ldFlagsService: LDFlagsService,
    private flexRowService: FlexRowService,
    private resourceImageService: ResourceImageService,
    private translate: TranslateService,
    private uploadService: UploaderService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  public ngOnInit(): void {
    this.flexRowSummary = this.flexRowSummary || this.row.section;
    this.model = {
      sectionTitle: '',
      freeformTextValue: '',
      image: undefined,
    };

    if (this.isEditing) {
      this.headerText = this.i18n.dgFlexRow_EditSectionDetails;
      this.freeformText = this.row.targetResources[0]
        ?.reference as FreeformTextRow;

      // If there is no image the backend will send a dummy string,
      // we're filtering those out here.
      const image = this.flexRowSummary?.imageUrl;
      if (image && image !== 'ImageUrl') {
        this.image = this.resourceImageService.parseImageUrl(
          this.flexRowSummary?.imageUrl
        );
      }

      this.model = {
        sectionTitle: this.row.section.name,
        freeformTextValue: this.freeformText.freeformTextValue,
        image: this.image,
      };
    }

    this.cdr.markForCheck();
  }

  public ngAfterViewInit() {
    const imageLink = this.webEnvironmentService.getZendeskUrl(
      '/articles/4408914307218'
    );
    this.modalInstructions = `<a href="${imageLink}" class="__helper-text par par--small par--light font-medium inline link" target="_blank" rel="noopener noreferrer" aria-describedby="a11yNewWindowDescription">${this.i18n.dgImageEdit_DocumentationLabel}</a>`;

    this.uploadAdapter = new InputImageUpload(
      this.uploadService,
      'FreeformText',
      this.flexRowSummary?.targetResourceId,
      'Target',
      this.flexRowSummary?.targetId
    );

    this.fields = this.showPlanSimplification
      ? [
          this.builder
            .optionalTextInput('sectionTitle', 'dgFlexRow_EditSectionTitle')
            .withErrorMessages({
              required: this.i18n.dgFlexRow_CustomErrors_RequiredMultiple,
            })
            .withMaxLength(255)
            .withDgatId('addEditFreeTextModal-423')
            .validatedBy((formControl) =>
              isEmptyValidator(formControl, this.fields[0])
            )
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.row.section.name,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .autofocused()
            .build(),
          this.builder
            .customField(
              'freeformTextValue',
              this.i18n.dgFlexRow_TextTitle,
              this.markdownRef,
              {
                required: () => !this.model.sectionTitle && !this.model.image,
                // A max length of 2% *more* than our actual limit prevents character
                // entry beyond this limit, but the form is invalid the second the user
                // is over the limit by 1 character.
                maxLength:
                  this.descriptionCharLimit + this.descriptionCharLimit * 0.02,
              }
            )
            .withErrorMessages({
              required: this.i18n.dgFlexRow_CustomErrors_RequiredMultiple,
            })
            .withHelp((context) =>
              // Print 'Characters remaining: 200' warning once character count
              // approaches 80% of the limit.
              warnCharPercentLimited(
                this.descriptionCharLimit,
                this.translate.instant('Core_CharsRemainingFormat', {
                  count:
                    this.descriptionCharLimit -
                    (context.formControl.value ?? '').toString().length,
                }),
                context
              )
            )
            .validatedBy(
              (formControl) => isEmptyValidator(formControl, this.fields[1]),
              (control) =>
                // Print 'Characters remaining: -1' error once character count is
                // over the limit.
                isCharLimitedValidator(
                  control,
                  this.descriptionCharLimit,
                  this.translate.instant('Core_CharsRemainingFormat', {
                    count:
                      this.descriptionCharLimit -
                      (control.value ?? '').toString().length,
                  })
                )
            )
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.freeformText.freeformTextValue,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .withDgatId('ddEditFreeTextModal-d96')
            .build(),
          this.builder
            .customField(
              'imageUploader',
              this.i18n.OrgPlans_ImageUpload,
              this.imageUploaderRef,
              {
                required: () =>
                  !this.model.sectionTitle && !this.model.freeformTextValue,
              }
            )
            .withErrorMessages({
              required: this.i18n.dgFlexRow_CustomErrors_RequiredMultiple,
            })
            .updatedOn('change')
            .build(),
          this.builder
            .customField('', '', this.removeButtonRef)
            .styledBy('form__col-1')
            .hiddenWhen(() => !this.isEditing)
            .build(),
        ]
      : [
          this.builder
            .requiredTextInput('sectionTitle', 'dgFlexRow_EditSectionTitle')
            .withMaxLength(255)
            .withDgatId('addEditFreeTextModal-423')
            .validatedBy(isEmptyValidator)
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.row.section.name,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .autofocused()
            .build(),
          this.builder
            .customField(
              'freeformTextValue',
              this.i18n.dgFlexRow_TextTitle,
              this.markdownRef,
              {
                required: () => true,
                // A max length of 2% *more* than our actual limit prevents character
                // entry beyond this limit, but the form is invalid the second the user
                // is over the limit by 1 character.
                maxLength:
                  this.descriptionCharLimit + this.descriptionCharLimit * 0.02,
              }
            )
            .asRequired()
            .withHelp((context) =>
              // Print 'Characters remaining: 200' warning once character count
              // approaches 80% of the limit.
              warnCharPercentLimited(
                this.descriptionCharLimit,
                this.translate.instant('Core_CharsRemainingFormat', {
                  count:
                    this.descriptionCharLimit -
                    (context.formControl.value ?? '').toString().length,
                }),
                context
              )
            )
            .validatedBy(isEmptyValidator, (control) =>
              // Print 'Characters remaining: -1' error once character count is
              // over the limit.
              isCharLimitedValidator(
                control,
                this.descriptionCharLimit,
                this.translate.instant('Core_CharsRemainingFormat', {
                  count:
                    this.descriptionCharLimit -
                    (control.value ?? '').toString().length,
                })
              )
            )
            .onControlValueChanges((value: string, model, formControl) =>
              this.isEditing
                ? markUnchangedStringAsPristine(
                    this.freeformText.freeformTextValue,
                    value,
                    model,
                    formControl
                  )
                : undefined
            )
            .updatedOn('change')
            .withDgatId('ddEditFreeTextModal-d96')
            .build(),
          this.builder
            .customField(
              'imageUploader',
              this.i18n.OrgPlans_ImageUpload,
              this.imageUploaderOldRef,
              {
                required: () => false,
              }
            )
            .updatedOn('change')
            .build(),
          this.builder
            .customField('', '', this.removeButtonRef)
            .styledBy('form__col-1')
            .hiddenWhen(() => !this.isEditing)
            .build(),
        ];

    if (this.showPlanSimplification) {
      // add our interdependent requirements -- only ONE of these three
      // fields is actually required.
      this.fields[0] = this.addRequireWhen(
        this.fields[0],
        '!model.freeformTextValue && !model.image?.resourceImageId'
      );
      this.fields[1] = this.addRequireWhen(
        this.fields[1],
        '!model.sectionTitle && !model.image?.resourceImageId'
      );
      // hide the `*` after required fields that would ordinarily show.
      this.fields[0].templateOptions.hideRequiredDesignator = true;
      this.fields[1].templateOptions.hideRequiredDesignator = true;
      this.fields[2].templateOptions.hideRequiredDesignator = true;
    }

    this.cdr.detectChanges();
  }

  public onDismiss(): void {
    this.activeModal.dismiss();
  }

  public onImageUploadSuccess({ pictureUrl, resourceImageId }): void {
    this.form.markAsDirty();
    this.isEditingImage = false;
    if (pictureUrl) {
      this.model.image = {
        imageUrl: pictureUrl,
        resourceImageId,
      };
    }
    this.form.updateValueAndValidity();
    this.cdr.markForCheck();
  }

  public onDeleteImage() {
    this.form.markAsDirty();
    this.model.image = undefined;
    this.isEditingImage = false;
    this.cdr.markForCheck();
  }

  public onEditingImage() {
    this.form.markAllAsTouched();
    this.isEditingImage = true;
    this.cdr.markForCheck();
  }

  public getHelpLink() {
    return this.webEnvironmentService.getZendeskUrl('/articles/4409136620434');
  }

  /**
   * Opens a custom sub-modal to confirm the user intends to delete
   * this row.
   */
  public removeRow(): Subscription {
    return this.flexRowService.removeRow(
      this.row.section,
      this.row.sectionDefaultTitle,
      this.activeModal
    );
  }

  public getBlobUrl(url) {
    return this.webEnvironmentService.getBlobUrl(url);
  }

  public submit() {
    // Prevent duplicate submissions.
    if (this.isSubmitting || this.isEditingImage) {
      return;
    }

    // Sanity-check trimming of fields *before* we check the form for validity.
    this.model.sectionTitle = this.model.sectionTitle.trim();
    this.model.freeformTextValue = this.model.freeformTextValue.trim();
    this.isSubmitting = true;
    this.form.markAsDirty();

    // Prevent invalid submissions.
    if (this.form.invalid) {
      this.isSubmitting = false;
      this.cdr.markForCheck();
      return;
    }

    this.isSubmitting = true;

    const flexRowSummary = this.flexRowSummary || this.row?.section;
    // Trim the values before submitting them.
    // Formly also has parsers, but they don't appear to work any better
    // than simply trimming once here.
    const textImageRow: OptionalResourceRow = {
      section: {
        ...flexRowSummary,
        name: this.model.sectionTitle,
        resourceImage: {
          resourceImageId: this.model.image?.resourceImageId ?? null,
        },
      },
      resource: {
        // Note: The BE passes those values onto the DB and will throw an exception
        // if more/less data is sent, be mindful to only sent expected properties!
        freeformTextValue: this.model.freeformTextValue.trim(),
        // Those three properties are for mapping current
        // link-text rows with an existing
        freeformTextId: this.freeformText?.freeformTextId,
        resourceId: this.freeformText?.resourceId,
        resourceType: this.freeformText?.resourceType,
      },
    };

    this.activeModal.close(textImageRow);
  }

  private addRequireWhen(
    fieldConfig: DfFormFieldConfig,
    requireWhenCheck: string
  ) {
    let subscription: Subscription;

    // parse checks from our requireWhenCheck property
    const checks = getConditionalFieldNames(requireWhenCheck);

    // all fields should start off with a defined required property
    fieldConfig.templateOptions = {
      ...fieldConfig.templateOptions,
      required: false,
    };
    // add our conditional expression property
    fieldConfig.expressionProperties = {
      ...fieldConfig.expressionProperties,
      'templateOptions.required': requireWhenCheck,
    };
    // add our hooks
    fieldConfig.hooks = {
      ...fieldConfig.hooks,
      onInit: (f) => {
        const form = f.form;
        subscription = f.options.fieldChanges
          .pipe(
            filter(({ property }) => property === 'templateOptions.required')
          )
          .subscribe(({ value }) => {
            if (!!value) {
              return;
            }
            this.updateFormValidity(checks, form);
          });
      },
      onDestroy: (f) => {
        subscription.unsubscribe();
      },
    };
    return fieldConfig;
  }

  private updateFormValidity(checks: string[], form: FormGroup | FormArray) {
    // Do nothing if there are no checks of this type (neg or pos)
    if (!checks.length) {
      return;
    }
    // Otherwise, loop through our checks
    let control: AbstractControl;
    for (let i = 0, l = checks.length; i < l; ++i) {
      // Skip images.
      if (checks[i] === 'image?.resourceImageId') {
        continue;
      }
      // Grab the field off our form group by name.
      control = form.get(checks[i]);
      // Trim our value.
      control.setValue((control.value || '').trim());
      // Check our field, and only our field, for validity.
      // (We can't, unfortunately, check all the fields at once
      // by setting `onlySelf` to false, as `updateValueAndValidity`
      // traverses the tree straight up from itself rather than checking
      // siblings (or children).
      control.updateValueAndValidity({ onlySelf: true });
    }
    // Update the view
    this.cdr.detectChanges();
  }
}

function getConditionalFieldNames(requireWhen: string) {
  const regex = /(?:!model[.])([^ ]*)/g;
  // TODO: When we have `matchesAll` available, just use that.
  // Otherwise, exec is necessary for non-capturing groups, e.g. matching `!model.`
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
  const fieldNames = [];
  let matches: RegExpExecArray;
  while ((matches = regex.exec(requireWhen)) !== null) {
    fieldNames.push(matches[1]);
  }
  return fieldNames;
}
