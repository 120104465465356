import { Params } from '@angular/router';
import { stringToInteger } from '@app/search/components/search-view/search-reactive-store/utils';
import {
  AssignmentsQueryUrlParams,
  AssignmentsState,
} from '@app/profile/components/profile-assignments/profile-assignments-reactive-store/profile-assignments.model';

/**
 * These params are tracked by state management. An error will be thrown to notify
 * developers of new params that aren't added here as a reminder
 */
export const KNOWN_URL_PARAMS = [
  'term',
  'pageNum',
  'sortBy',
  'sortDir',
  'type',
];

/**
 * Map store state to assignments URL params
 */
export function stateToUrlParams(
  state: Partial<AssignmentsState>
): Partial<AssignmentsQueryUrlParams> {
  const {
    term,
    sort: { sortBy, sortDir },
    pagination,
    assignmentType,
  } = state;

  return {
    term,
    pageNum: pagination?.currentPage,
    sortBy,
    sortDir,
    type: assignmentType,
  };
}

/**
 * Map assignments URL parameters to store state
 */
export function urlParamsToState(
  params: Partial<AssignmentsQueryUrlParams>
): Partial<AssignmentsState> {
  const {
    term,
    pageNum: currentPage,
    sortBy,
    sortDir,
    type,
  } = params;

  return {
    term,
    sort: { sortBy: sortBy || 'dueDate', sortDir: sortDir || 'asc' },
    pagination: {
      currentPage,
    },
    assignmentType: type || 'incomplete',
  };
}

/**
 * Coerce url param strings to correct types
 */
export function coerceUrlParams({
  term,
  pageNum,
  sortBy,
  sortDir,
  type,
}: Params): AssignmentsQueryUrlParams {
  return {
    term,
    pageNum: stringToInteger(pageNum),
    sortBy,
    sortDir,
    type,
  };
}

/**
 * Notify developer of new url parameters that are not being
 * properly tracked by state management
 */
export function verifyUrlParams(params: Params) {
  Object.keys(params).forEach((param) => {
    if (!KNOWN_URL_PARAMS.includes(param)) {
      console.error(
        `WARNING: AssignmentsBookmarkService is likely not tracking the "${param}" parameter`
      );
    }
  });
}
