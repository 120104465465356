import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * Provides utility methods specific to the banner required by law.
 */

@Injectable({
  providedIn: 'root',
})
export class CookieLawService {
  public static EXPIRATION_SECONDS =
    1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 3000;

  // utility service
  public cookieService = new CookieService();

  private isEu: string = 'isEU';
  private isNonEu: string = 'isNonEU';
  private acceptedCookiesName: string = 'acceptedCookies';
  private acceptedPrivacyPolicyName: string = 'acceptedPrivacyPolicy';

  private authUser: AuthUser;

  constructor(
    authService: AuthService,
    private http: NgxHttpClient,
    private webEnvironmentService: WebEnvironmentService
  ) {
    this.authUser = authService.authUser;
  }

  public getEuCookie() {
    return this.cookieService.get(this.isEu);
  }

  public getAuthUser() {
    return this.authUser;
  }

  public getNonEuCookie() {
    return this.cookieService.get(this.isNonEu);
  }

  public getAcceptedCookie() {
    return this.cookieService.get(this.getAcceptedCookieName());
  }

  public getAcceptedCookieName() {
    return this.acceptedCookiesName;
  }

  public getAcceptedPrivacyPolicyCookie() {
    return this.cookieService.get(this.getAcceptedPrivacyPolicyCookieName());
  }

  public getAcceptedPrivacyPolicyCookieName() {
    return this.acceptedPrivacyPolicyName;
  }

  public setPrivacyPolicyAccepted() {
    return this.http.post('/api/user/AcceptPrivacyNotification', {});
  }

  public shouldGetCookieScript() {
    const euCookie = this.getEuCookie();
    const isNonEuCookie = this.getNonEuCookie() === 'true';
    const hasAcceptedCookie = this.getAcceptedCookie() === 'true';
    return !isNonEuCookie && (euCookie === '' || !hasAcceptedCookie);
  }

  public getCookieScript() {
    const scriptSrc = this.webEnvironmentService.getBlobUrl(
      '~/scripts/shared/messagebanner/hascookielaw.js'
    );

    return this.http.get(scriptSrc).pipe(
      tap(() => {
        this.cookieService.set(this.isNonEu, 'true', null, '/');
        return false;
      }),
      catchError((response: HttpErrorResponse) => {
        // Browser sometimes returns a status of -1 if there are no access headers on requested resource.
        if (response.status === 403 || response.status === -1) {
          this.cookieService.set(this.isEu, 'true', null, '/');

          const scriptEl = document.createElement('script');
          scriptEl.setAttribute('src', scriptSrc);
          scriptEl.append('body');
        }
        return throwError('error getting script');
      })
    );
  }
}

// (SDM 12/15/2021) Copied from https://stackoverflow.com/a/49530256
// We could use a 3rd party lib, but this is simple enough to just keep the code in house
// I don't want to encourage using cookies, keeping it as a "private" non injected service.

class CookieService {
  constructor() {}

  /**
   * delete cookie
   * @param name
   */
  public delete(name) {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    this.set(name, '', date);
  }

  /**
   * get cookie
   * @param {string} name
   * @returns {string}
   */
  public get(name: string) {
    const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * set cookie
   * @param {string} name
   * @param {string} value
   * @param {Date} expireDays
   * @param {string} path
   */
  public set(
    name: string,
    value: string,
    expiresDate?: Date,
    path: string = ''
  ) {
    const expires = expiresDate ? `expires=${expiresDate.toUTCString()}` : '';
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}; SameSite=Lax`;
  }
}
