<fieldset>
  <legend *ngIf="!hideTitle" class="form__label font-semibold">
    {{ i18n.orgInternalContentVisibilitySelector_Title }}
  </legend>
  <div class="selection-box" (blur)="handleInputBlur($event)">
    <cdk-accordion class="accordion">
      <div class="accordion-item">
        <cdk-accordion-item
          [expanded]="isActive(true)"
          #accordionItemOrg="cdkAccordionItem"
          [attr.id]="'cdk-panel-org'"
          [attr.aria-expanded]="accordionItemOrg.expanded"
          [attr.aria-controls]="'cdk-panel-org-body'"
          (opened)="handlePanelChange(true, true)"
        >
          <div class="accordion-header">
            <div class="l_flex m-l_flex">
              <label class="l_flex-grow guts-p-v-1">
                <input
                  type="radio"
                  class="selection-box__radio"
                  name="visibility"
                  [value]="true"
                  [ngModel]="visibleToOrg"
                  (ngModelChange)="handleVisibilityChange($event)"
                  data-dgat="orgInternalContentVisibilitySelector-e15"
                />
                <span class="selection-box__text par--small">
                  {{
                    i18n.orgInternalContentVisibilitySelector_VisibleToOrganization
                  }}
                </span>
              </label>
              <div>
                <button
                  type="button"
                  (click)="accordionItemOrg.toggle()"
                  class="color-ebony-a25 hover-bg-color-white hover-color-blue-dark ftp-detail-row-list__trigger guts-p-l-1-half"
                  data-dgat="ftp-detail-row-list-591"
                  [attr.aria-label]="
                    getAriaText(
                      accordionItemOrg.expanded,
                      i18n.orgInternalContentVisibilitySelector_VisibleToOrganization
                    )
                  "
                >
                  <df-icon
                    class="guts-m-full-1"
                    [icon]="
                      accordionItemOrg.expanded ? 'chevron-up' : 'chevron-down'
                    "
                    size="medium"
                  ></df-icon>
                </button>
              </div>
            </div>
          </div>

          <div
            role="region"
            [attr.id]="'cdk-panel-org-body'"
            [attr.aria-labelledby]="'cdk-panel-org'"
            [ngClass]="{
              'accordion-body': true,
              'no-display': !accordionItemOrg.expanded
            }"
          >
            <div class="bg-color-white guts-p-h-1-half guts-p-b-1-half">
              <dgx-group-visibility
                [includeUsersGroups]="true"
                [groupNames]="groupNames"
                [groupIds]="groupIds"
                [customTitle]="
                  i18n.orgInternalContentVisibilitySelector_TargetGroupsLabel
                "
                [selectCallback]="handleGroupsAdd"
                [deselectCallback]="handleGroupsRemove"
                [groupPlaceholderText]="
                  i18n.orgInternalContentVisibilitySelector_OrgVisibilityPlaceholder
                "
                [excludedPrivacyLevels]="excludedGroupPrivacyLevels"
              ></dgx-group-visibility>
            </div>
          </div>
        </cdk-accordion-item>
      </div>

      <div class="accordion-item">
        <cdk-accordion-item
          [expanded]="isActive(false)"
          #accordionItemGroup="cdkAccordionItem"
          [attr.id]="'cdk-panel-group'"
          [attr.aria-expanded]="accordionItemGroup.expanded"
          [attr.aria-controls]="'cdk-panel-group-body'"
          id="ngb-panel-group"
          (opened)="handlePanelChange(true, false)"
        >
          <div class="accordion-header">
            <div class="l_flex m-l_flex">
              <label class="l_flex-grow guts-p-v-1">
                <input
                  type="radio"
                  class="selection-box__radio"
                  name="visibility"
                  [value]="false"
                  [ngModel]="visibleToOrg"
                  (ngModelChange)="handleVisibilityChange($event)"
                  data-dgat="orgInternalContentVisibilitySelector-e5c"
                />
                <span class="selection-box__text par--small">{{
                  i18n.orgInternalContentVisibilitySelector_VisibleToGroups
                }}</span>
              </label>
              <div>
                <button
                  (click)="accordionItemGroup.toggle()"
                  type="button"
                  class="color-ebony-a25 hover-bg-color-white hover-color-blue-dark ftp-detail-row-list__trigger guts-p-l-1-half"
                  data-dgat="ftp-detail-row-list-592"
                  [attr.aria-label]="
                    getAriaText(
                      accordionItemGroup.expanded,
                      i18n.orgInternalContentVisibilitySelector_VisibleToGroups
                    )
                  "
                >
                  <df-icon
                    class="guts-m-full-1"
                    [icon]="
                      accordionItemGroup.expanded
                        ? 'chevron-up'
                        : 'chevron-down'
                    "
                    size="medium"
                  ></df-icon>
                </button>
              </div>
            </div>
          </div>

          <div
            role="region"
            [style.display]="accordionItemGroup.expanded ? '' : 'none'"
            [attr.id]="'cdk-panel-group-body'"
            [attr.aria-labelledby]="'cdk-panel-group'"
            [ngClass]="{
              'accordion-body': true,
              'no-display': !accordionItemGroup.expanded
            }"
          >
            <div class="bg-color-white guts-p-h-1-half guts-p-b-1-half">
              <dgx-group-visibility
                [adminOnly]="true"
                [groupNames]="groupNames"
                [groupIds]="groupIds"
                [selectCallback]="handleGroupsAdd"
                [deselectCallback]="handleGroupsRemove"
                [hideTooltip]="true"
                [hideLabel]="true"
                [groupPlaceholderText]="
                  i18n.orgInternalContentVisibilitySelector_GroupVisibilityPlaceholder
                "
              ></dgx-group-visibility>
            </div>
          </div>
        </cdk-accordion-item>
      </div>
    </cdk-accordion>
  </div>
</fieldset>
