import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[dgxMinTime]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinTimeValidationDirective,
      multi: true,
    },
  ],
})
export class MinTimeValidationDirective implements Validator {
  @Input('dgxMinTime') public minTime: string;
  public validate(control: AbstractControl): ValidationErrors | null {
    return this.validateMinTime(control.value, this.minTime);
  }
  private validateMinTime(
    currentTime: string,
    minTime: string
  ): ValidationErrors | null {
    const invalid = minTime > currentTime;
    return invalid ? { mintime: false } : null;
  }
}
