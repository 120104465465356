import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService } from '@dg/shared-services';
import { EbbAuthService } from '@app/browser-extension/services/ebb-auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private router: Router, private modalService: ModalService, private ebbAuthService: EbbAuthService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isBearerTokenUrl(request)) {
      return next.handle(request);
    }

    return from(this.ebbAuthService.getAccessTokenAsync())
      .pipe(
        switchMap((token) => {
          request = request.clone({
            url:  this.getUrl(request),
            headers: request.headers.set('Authorization', 'Bearer ' + token),
          });

          // TODO: camelCaseKeys all response event.body
          return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                event;
              }
              return event;
            }),
            catchError((err: HttpErrorResponse) => {
              // handle case where the cookie from web is not present,
              // and we need to force a re-login
              // If LXP session is expired but the extension access token is valid
              // then we fall into this edge case where we get 401 from lxp endpoints
              if (
                err.status === 401 &&
                window.location.href.indexOf('/account/login') < 0
              ) {
                this.modalService.dismissAll();
                this.router.navigate(['/degreed-button/app/auth'], {
                  queryParams: { forceNewLogin: true },
                  replaceUrl: true,
                });
              }
              return throwError(err);
            })
          );
        }),
      );
  }

  // determine if url can have BearerToken attached
  public isBearerTokenUrl(request: HttpRequest<any>): boolean {
    return (
      request.url.indexOf('/api/extension') > -1 ||
      request.url.indexOf('/api/tag/') > -1 ||
      request.url.indexOf('/api/pathways/') > -1 ||
      request.url.indexOf('/api/targets/') > -1 ||
      request.url.indexOf('/api/inputs/') > -1 ||
      request.url.indexOf('/api/degreed/') > -1 ||
      request.url.indexOf('/api/providers/') > -1 ||
      request.url.indexOf('/api/userinputs/') > -1
    );
  }

  private getUrl(request: HttpRequest<any>): string {
    const listOfUrlsToBeRedirected = ['/api/pathways/', '/api/targets/', '/api/degreed/', '/api/inputs/', '/api/providers/', '/api/userinputs/'];

    if (listOfUrlsToBeRedirected.some((path) => request.url.includes(path))) {
        // For other URLs in the list
        return request.url.replace('/api/', '/api/extension/');
    } else {
        // Default behavior for URLs not in the list
        return request.url;
    }
  }
}
