<!-- Set target level -->
<div *ngIf="showAddTarget || !tag.rating.type">
  <h4 *ngIf="targetAuthoring">
    <button
      type="button"
      class="h4 par par--small font-semibold color-blue guts-m-t-1"
      data-dgat="tag-tile-target-rating-3fd"
    >
      <df-icon
        [icon]="targetLevel ? 'pencil' : 'plus-circle'"
        class="guts-p-r-half color-blue"
      ></df-icon>
      <span class="ib-v-middle">{{
        targetLevel
          ? i18n.Opportunities_Skills_EditTargetLevel
          : i18n.dgTagRating_NoTargetLevelAssigned
      }}</span>
    </button>
  </h4>
</div>

<!-- Target level [n] -->
<div *ngIf="targetLevel" class="guts-p-t-half">
  <h4
    *ngIf="levelName && targetAuthoring"
    class="epsilon no-wrap badge badge-pill badge-neutral guts-m-t-half guts-m-b-half"
  >
    {{ levelName }}
  </h4>
  <p
    class="par par--small par--light"
    [innerHtml]="'Target_TargetLevelFormat' | translate: { level: targetLevel }"
  ></p>
</div>
