import { LDFlagsService } from '../ld-flags.service';

export class OrgManagementLDFlags {
  constructor(private ldFlagsService: LDFlagsService) {}

  /***
   *  Team: Admin Experience
   *  Created Date: 09/23/2022 [PD-79275]
   *  Planned Cleanup: TBD (Covert to org setting) [PD-92084]
   *  Visibility:
   *    - Pre-Release: Segments UI
   *    - OrgId (many)
   *
   *  Provides access to view the segments UI via the SegmentsGuard.
   */
  public get segmentsUI(): boolean {
    return this.ldFlagsService.getFlag('segments-ui', false);
  }

  public get dynamicGroups(): boolean {
    return this.ldFlagsService.getFlag('manage-exe-segmentgroups', false);
  }
}
