import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import { InternalTagRatingTypes } from '@app/tags/tags';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

// types
import {
  CandidateAlgorithmData,
  Opportunity,
  Skill,
} from '@app/opportunities/opportunities-api.model';

// utils
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { sortSkillsForDisplay } from '@app/opportunities/utils';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import { getTagLevel } from '@app/shared/utils/tag-helpers';
import { TagsApi } from '@app/tags/tag-api.model';
import { UserProfile } from '@app/user/user-api.model';

// service
import { AuthService } from '@app/shared/services/auth.service';
import { OpportunityViewService } from '@app/opportunities/services/opportunity-view.service';
import { OpportunitySkillsModalService } from '@app/opportunities/services/opportunity-skills-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from '@app/tags/services/tags.service';

interface SkillWithLocalFields extends Skill {
  newlyAdded?: boolean;
  opened?: boolean;
}

/**
 * Used to display a given user's matching skills for an opportunity.
 */
@Component({
  selector: 'dgx-user-skills-modal',
  templateUrl: './user-skills-modal.component.html',
  styleUrls: ['./user-skills-modal.component.scss'],
  animations: [DF_COLLAPSE_EXPAND],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSkillsModalComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  // Bindings - input
  @Input() public opportunity: Opportunity;
  @Input() public opportunityId: number;
  @Input() public showWithTargetLevelSkillsOnly = false;
  /** The user's data. Piped in from the parent component. */
  @Input() public userData: () => Observable<CandidateAlgorithmData>;
  /** Emits the current skill when the Add Target Rating modal opens. */
  @Output() public ratingViewed = new EventEmitter<Skill>();
  @ViewChild('ratingsMenuButton', { static: false })
  public returnFocus: ElementRef<any>;

  // Bindings - local
  public i18n = this.translateService.instant([
    'dgProfileOverview_TooltipSignalsPlural',
    'dgProfileOverview_TooltipSignalsSingle',
    'Core_Close',
    'Core_MoreOptions',
    'Opportunities_Skills_AddSkillRating',
    'Opportunities_Skills_EditSkillRating',
    'Opportunities_Skills_RemoveSkillRating',
  ]);
  public isLoading = true;
  public profile: Partial<UserProfile>;
  public skills: SkillWithLocalFields[] = [];
  public state: string;
  private opportunityTagLevelMap: Record<string, number> = {};

  // Constructor
  constructor(
    public opportunitySkillsModalService: OpportunitySkillsModalService,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private tagsService: TagsService,
    private translateService: TranslateService,
    private opportunityViewService: OpportunityViewService,
    private authService: AuthService
  ) {
    super();
  }

  public ngOnInit() {
    this.userData()
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        ({
          opportunityTags = [],
          profile,
          state,
          tags,
        }: CandidateAlgorithmData) => {
          this.profile = profile;
          this.skills = sortSkillsForDisplay(tags);
          this.state = state;
          opportunityTags.forEach((tag) => {
            this.opportunityTagLevelMap[tag.name] = getTagLevel(tag);
          });

          if (this.showWithTargetLevelSkillsOnly) {
            this.skills = this.skills.filter((skill) =>
              this.hasSkillLevelOnOpportunity(skill)
            );
          }

          this.isLoading = false;
          this.cdr.detectChanges();
        }
      );
  }

  public getMenuConfig(skill: SkillWithLocalFields): MenuViewModel[] {
    return [
      {
        title: this.i18n.Opportunities_Skills_EditSkillRating,
        defaultAction: () => this.showUserRateSkillModal(skill),
        preventRefocus: true,
      },
      {
        title: this.i18n.Opportunities_Skills_RemoveSkillRating,
        defaultAction: () => this.removeSkillRate(skill),
        preventRefocus: true,
      },
    ];
  }

  public hideRatingButton(skill: Skill): boolean {
    return (
      // Is the current user NOT the one being rated?
      this.profile.userProfileKey !==
        this.authService.authUser.viewerProfile.userProfileKey ||
      // OR: Does this skill *already* have a self rating?
      !!skill.ratings?.find((r) => r.type === InternalTagRatingTypes.self)
    );
  }

  /**
   * Get the level defined on the opportunity for this skill.
   *
   * @param skill
   * @returns the level or undefined if skill not on opportunity
   */
  public getSkillLevelOnOpportunity(skill: Skill): number {
    return this.opportunityTagLevelMap[skill.name];
  }

  /**
   * Is the skill level defined on the opportunity for this skill
   *
   * @param skill
   * @returns
   */
  public hasSkillLevelOnOpportunity(skill: Skill): boolean {
    return !!this.getSkillLevelOnOpportunity(skill);
  }

  /**
   * Is the skill the level defined on the opportunity for this skill met by the skill level
   *
   * @param skill
   * @returns
   */
  public isSkillLevelOnOpportunityMet(skill: Skill): boolean {
    const skillTagLevel = getTagLevel(skill);
    const opportunityTagLevel = this.opportunityTagLevelMap[skill.name];

    if (!skillTagLevel || !opportunityTagLevel) {
      return false;
    }

    return skillTagLevel >= opportunityTagLevel;
  }

  public onClose(): void {
    this.activeModal.close();
  }

  public removeSkillRate(skill: Skill) {
    if (skill.rating.type === InternalTagRatingTypes.self) {
      skill.rating.level = '0';
    }

    skill.ratings.forEach((r, i) => {
      if (r.type === InternalTagRatingTypes.self) {
        skill.ratings.splice(i, 1);
      }
    });
    return this.tagsService
      .rateTag(null, InternalTagRatingTypes.self, skill as TagsApi.TagDetails)
      .subscribe();
  }

  public showMoreOption(rating): boolean {
    return rating.type === InternalTagRatingTypes.self;
  }

  public showUserRateSkillModal(skill: SkillWithLocalFields) {
    this.opportunitySkillsModalService
      .showUserRateSkillModal(skill, this.returnFocus)
      .subscribe((updatedRatings: any) => {
        const selfRating = updatedRatings.find(
          (u) => u.type === InternalTagRatingTypes.self
        );
        const typesAboveSelf = [
          InternalTagRatingTypes.credential,
          InternalTagRatingTypes.evaluation,
          InternalTagRatingTypes.manager,
          InternalTagRatingTypes.peer,
        ];

        // We only want to update the donut rating value
        // when there's no rating that is higher than the self rating
        if (
          !typesAboveSelf.includes(skill.rating?.type as InternalTagRatingTypes)
        ) {
          skill.rating = selfRating;
        }

        // If it's a newly added skill, there's no ratings field,
        // we need to create that field then push the newly added skill to the skills field
        if (!skill.ratings) {
          skill.newlyAdded = true;
          skill.ratings = [];
          if (this.opportunity) {
            this.opportunity.matchedSkills.push(skill);
            this.opportunity.unMatchedSkills =
              this.opportunity.unMatchedSkills.filter(
                (s) => s.tagId !== skill.tagId
              );
          }
        }

        const ratingIndex = skill.ratings.findIndex(
          (r) => r.type === InternalTagRatingTypes.self
        );

        if (ratingIndex !== -1) {
          skill.ratings[ratingIndex] = selfRating;
        } else {
          skill.ratings.push(selfRating);
        }

        if (this.opportunity) {
          this.opportunityViewService.rehydrateOpportunity(this.opportunity);
        }

        this.cdr.markForCheck();
      });
  }

  public toggleSkillDetail(skill: SkillWithLocalFields) {
    skill.opened = !skill.opened;
  }

  /**
   * Linter Fix
   * Casts SkillwithLocalFields to Tag, they're not equivalent because skill is defined as a
   * partial of TagDetails, whichs makes tagId optional.
   *
   * @param skill
   * @returns
   */
  public asTag(skill: SkillWithLocalFields): TagsApi.Tag {
    return skill as TagsApi.Tag;
  }
}
