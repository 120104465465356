import { Pipe, PipeTransform } from '@angular/core';

/** @summary A trivial pipe that purely transforms a string into a prefixed version of itself.
 * @description Useful for transforming enum or string literal properties into a CSS class name
 * without having to cache values in a component property for change detection friendliness.
 */
@Pipe({
  name: 'dfPrefix',
})
export class PrefixPipe implements PipeTransform {
  transform(value: any, classPrefix: string): any {
    return `${classPrefix}${value}`;
  }
}
