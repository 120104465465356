import { Pipe, PipeTransform } from '@angular/core';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TagsApi } from '@app/tags/tag-api.model';

/**
 * Given an array of user tag ratings, return only the ratings for the given type
 *
 * @param ratings TagsApi.UserTagRatingDetails[]
 * @returns TagsApi.UserTagRatingDetails[]
 */

@Pipe({
  name: 'tagRatingsForType',
})
export class TagRatingsForTypePipe implements PipeTransform {
  public transform(
    ratings: TagsApi.UserTagRatingDetails[],
    type: InternalTagRatingTypes | string
  ): TagsApi.UserTagRatingDetails[] {
    return !!ratings?.length && !!type
      ? ratings.filter((rating) => rating.type === type)
      : [];
  }
}
