import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  buffer,
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NgxHttpClient } from '../ngx-http-client';
import { isUrlAbsolute } from '../utils/common-utils';
import { pascalCaseKeys } from '../utils/property';
import { TrackingEventData, TrackingEventsParams } from './tracking.model';
import { WebEnvironmentService } from './web-environment.service';

/**
 * Old comment: A service for interacting with Google Analytics
 * Angular is not in use anymore in the main app, move to appropriate service.
 */
@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private trackBatchQueue$ = new Subject<TrackingEventsParams>();
  private completedEvents$ = new Subject<TrackingEventsParams>();

  constructor(
    private location: Location,
    private router: Router,
    private http: NgxHttpClient,
    private webEnvironmentService: WebEnvironmentService
  ) {
    this.trackBatchQueue$
      .pipe(
        buffer(this.trackBatchQueue$.pipe(debounceTime(300))),
        map((events) => {
          // Group batched events by shared context
          const eventsByContext: Record<string, TrackingEventsParams> =
            events.reduce((memo, event: any) => {
              const key = `${event.userId}:${JSON.stringify(event.context)}`;
              if (!memo[key]) {
                memo[key] = event;
              } else {
                memo[key].eventsData.push(...event.eventsData);
              }
              return memo;
            }, {});

          return eventsByContext;
        }),
        mergeMap((eventByContext) => {
          const sendEvents = Object.values(eventByContext).map((event) =>
            this.sendTrackBatch(event)
          );

          return forkJoin(sendEvents);
        })
      )
      .subscribe();
  }

  /** Tracks an event in the application. Tracking calls are batched and debounced
   *
   * @deprecated DO NOT CALL THIS DIRECTLY! Use TrackingService.trackEventData instead
   */
  public track(trackData: TrackingEventData) {
    const eventData = {
      eventName: trackData.eventName,
      properties: {
        // Pascal case the properties for the DB. Our BE is case-insenetive but
        // our tracking properties have to match the instrumented properties in related DB table (at the moment).
        ...pascalCaseKeys(trackData.properties),
        // Add performance props
        ...pascalCaseKeys(this.getPerfTrackingProps()),
      },
    };

    const event: TrackingEventsParams = {
      userId: trackData.userId,
      context: trackData.context,
      eventsData: [eventData],
    };

    this.trackBatch(event);

    // Navigating outside of angular routing (location.assign etc) can interrupt the tracking request
    // returning this so the observer can wait for the tracking request to complete before navigating
    return this.emitSelectedEvent(trackData.eventName);
  }

  /** Tracks a batch of events. */
  public trackBatch(trackData: TrackingEventsParams) {
    this.trackBatchQueue$.next(_cloneDeep(trackData));
  }

  /** Tracks a page event **/
  public trackPage(props, details, userId) {
    const API = '/analytics/page';
    const endpointURL = this.webEnvironmentService.analyticsUrl;
    const normalizedEndpoint = this.normalizeEndpoint(endpointURL);

    const pageData = {
      context: details.context,
      userId: userId,
      properties: props,
    };

    return this.http.post(normalizedEndpoint + API, pageData).pipe(
      catchError((error) => {
        console.error('Failed to send tracking batch', error);
        return of(); // swallow tracking send errors
      })
    );
  }

  /** Tracks a page event **/
  public trackIdentify(userId, anonymousId, details) {
    const API = '/analytics/identify';
    const endpointURL = this.webEnvironmentService.analyticsUrl;
    const normalizedEndpoint = this.normalizeEndpoint(endpointURL);

    const identifyParameters = {
      context: details.context,
      userId: userId,
      anonymousId: anonymousId,
      activeLearner: details.activeLearner,
      hasCareerMobility: details.hasCareerMobility,
    };
    return this.http.post(normalizedEndpoint + API, identifyParameters).pipe(
      catchError((error) => {
        console.error('Failed to send tracking batch', error);
        return of(); // swallow tracking send errors
      })
    );
  }

  /** Reduces a url to its unique, compact path */
  public consolidateUrl() {
    const path = new URL(this.router.url).pathname;
    const tab = this.location.path();

    let consolidatedUrl = '';
    if (path.indexOf('index/1') > -1) {
      // HACK: to get to profile when the user is viewing their own profile
      consolidatedUrl = '/profile';
    }
    if (path.indexOf('/learn/how-to-learn-') > -1) {
      consolidatedUrl = '/topicpage'; // HACK generalize topic pages
    }
    if (path.indexOf('/orgs/') > -1) {
      // HACK: generalize orgs pages
      consolidatedUrl = '/orgs';
    }
    if (path.indexOf('/groups/') > -1) {
      // HACK: generalize groups pages
      consolidatedUrl = '/groups';
    }
    if (path.indexOf('/authoring/') > -1) {
      consolidatedUrl = '/authoring';
    }
    if (path.indexOf('/plan/') > -1 || path.indexOf('/plan/') > -1) {
      consolidatedUrl = '/plan';
    }
    if (path.indexOf('/explore/') > -1) {
      consolidatedUrl = '/explore';
    }
    if (path.indexOf('/dashboard') > -1) {
      consolidatedUrl = '/dashboard';
    }

    if (consolidatedUrl === '') {
      consolidatedUrl = path.substring(path.lastIndexOf('/'), path.length);
    }

    if (tab && tab !== '/') {
      if (tab.indexOf('/group') > -1 && tab.indexOf('/feed') > -1) {
        // HACK:  generalize group activity pages
        consolidatedUrl = '/group/feed';
      } else {
        consolidatedUrl = tab;
      }
    }

    return consolidatedUrl;
  }

  /** Get performance tracking properties that are added to every event.
   *
   * It can return `undefined` if performance tracking is not supported, though
   * it should work in all modern browsers and non-modern browsers like IE11.
   */
  private getPerfTrackingProps() {
    // Using the deprecated `timing` API since IE11 doesn't support performance.timeOrigin
    if (performance?.timing?.navigationStart) {
      return {
        secondsSincePageLoad:
          (Date.now() - performance?.timing?.navigationStart) / 1000,
      };
    }
  }

  private sendTrackBatch(trackData: TrackingEventsParams): Observable<unknown> {
    const API = '/analytics/trackbatch';
    const endpointURL = this.webEnvironmentService.analyticsUrl;
    const normalizedEndpoint = this.normalizeEndpoint(endpointURL);

    return this.http.post(normalizedEndpoint + API, trackData).pipe(
      tap(() => this.completedEvents$.next(trackData)),
      catchError((error) => {
        // Emit completedEvents$ no matter what so that the observer isn't blocked on errors
        this.completedEvents$.next(trackData);
        console.error('Failed to send tracking batch', error);
        return of(); // swallow tracking send errors
      })
    );
  }

  private normalizeEndpoint(endpoint = ''): string {
    if (endpoint.endsWith('/')) {
      endpoint = endpoint.slice(0, endpoint.length - 1);
    }
    // http client won't automatically append apiBaseUrl to absolute URLs so we must do it explicitly
    if (isUrlAbsolute(endpoint)) {
      endpoint += '/api';
    }

    return endpoint;
  }

  private emitSelectedEvent(name: string): Observable<TrackingEventsParams> {
    return this.completedEvents$.pipe(
      filter(({ eventsData }) =>
        eventsData.some(({ eventName }) => eventName === name)
      )
    );
  }
}
