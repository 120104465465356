import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { WindowToken } from '@app/shared/window.token';

@Component({
  selector: 'dgx-tag-checkpoint-ratings-modal',
  templateUrl: './tag-checkpoint-ratings-modal.component.html',
  styleUrls: ['./tag-checkpoint-ratings-modal.component.scss'],
})
export class TagCheckpointRatingsModalComponent implements OnInit {
  @Input() public tag: TagsApi.Tag;
  @Input() public checkpoints: TagsApi.Checkpoint[];

  public i18n = this.translateService.instant([
    'dgTagRating_InProgress',
    'dgTagRating_Checkpoints',
    'dgTagRating_TakeCheckpoint',
  ]);

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
    @Inject(WindowToken) private windowRef: Window
  ) {}

  public ngOnInit(): void {
    this.i18n.dgTagRating_Checkpoints = ' ' + this.i18n.dgTagRating_Checkpoints;
  }

  public done(): void {
    this.activeModal.dismiss();
  }

  public openCheckpointLink($event: MouseEvent, url: string): void {
    $event?.stopImmediatePropagation();
    this.windowRef.open(url, '_blank');
  }

  public mayStartCheckpoint(checkpoint: TagsApi.Checkpoint): boolean {
    return !checkpoint.completedDate && !checkpoint.inProgress;
  }
}
