import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CachingInterceptor } from './caching.interceptor';
import { AuthInterceptor } from './auth.interceptor';
import { MiniProfilerInterceptor } from './mini-profiler/mini-profiler.interceptor';
import { XSRFTokenInterceptor } from './xsrf-token.interceptor';

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: XSRFTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MiniProfilerInterceptor,
      multi: true,
    },
  ],
})
export class InterceptorsModule {}
