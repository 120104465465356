import { ElementRef, Injectable } from '@angular/core';
import { OrgInternalContentService } from '@app/orgs/services/org-internal-content.service';
import {
  PathwayDetailsModel,
  PathwayStep,
  PathwaySubsection,
  StepModalProperties,
} from '@app/pathways/rsm/pathway-api.model';
import { PathwayAuthoring } from '@app/pathways/rsm/pathway.authoring';
import {
  AuthoringStepType,
  PathwayActionSuccess,
  PathwayLevel,
} from '@app/pathways/rsm/pathway.model';
import { ResourceVersionService } from '@app/resource-version/services/resource-version.service';
import { MenuViewModel } from '@app/shared/components/menu/menu.component';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { A11yService } from '@dg/shared-services';
import { TranslateService } from '@ngx-translate/core';

export interface PathwayAuthoringStepMenuProperties {
  step: PathwayStep;
  subsection: PathwaySubsection;
  pathway: PathwayDetailsModel;
}
interface OnMoveAction extends StepAction {
  type: 'moveStep';
}
interface OnRemoveAction extends StepAction {
  type: 'removeStep';
}
interface StepAction {
  type: AuthoringStepType;
  properties: PathwayAuthoringStepMenuProperties;
  popoverTrigger: ElementRef;
}

type MenuAction = OnMoveAction | OnRemoveAction;

@Injectable({ providedIn: 'root' })
export class PathwayAuthoringStepMenuService {
  public i18n = this.translateService.instant([
    'Core_Hour',
    'Core_Hours',
    'Core_Remove',
    'Core_Edit',
    'Core_EditDetails',
    'Core_EditItem',
    'Pathways_ConfirmRemoveItem',
    'Pathways_DeleteItem',
    'Pathways_MarkAsOptional',
    'Pathways_MarkAsRequired',
    'Pathways_MoveItem',
    'Pathways_AddAuthorNote',
    'Pathways_AdditionalContentSuccess',
    'Pathways_RequiredContentSuccess',
  ]);

  constructor(
    private a11yService: A11yService,
    private focusStackService: FocusStackService,
    private translateService: TranslateService,
    private resourceVersionService: ResourceVersionService,
    private orgInternalContentService: OrgInternalContentService,
    private authoring: PathwayAuthoring
  ) {}

  public getPathwayAuthoringMenu(
    properties: PathwayAuthoringStepMenuProperties
  ) {
    const that = this; // only for jest testing scenarios for now because `this` context gets lost when firing from there
    const pathwayAuthorMenuConfig: MenuViewModel[] = [
      {
        title: properties.step.isRequired
          ? this.i18n.Pathways_MarkAsOptional
          : this.i18n.Pathways_MarkAsRequired,
        defaultAction: (_, popoverTrigger) => {
          this.focusStackService.push(popoverTrigger.nativeElement);
          this.toggleOptional(properties);
        },
      },
      {
        title: this.i18n.Core_EditItem,
        isHidden: () =>
          properties.step.reference.format === 'Academy' ||
          properties.step.referenceType === 'Post' ||
          properties.step.referenceType === 'Book' ||
          !this.orgInternalContentService.canEditOrgManagedContent(
            properties.step.reference
          ),
        defaultAction: (event, _) => {
          this.openInternalEditModal(event, properties);
        },
        preventRefocus: true,
      },
      {
        title: this.i18n.Core_EditDetails,
        isHidden: () => {
          return (
            properties.step.referenceType === 'Post' ||
            properties.step.referenceType === 'Task'
          );
        },
        defaultAction: (_, popoverTrigger) => {
          this.editDetails(event, properties);
          this.focusStackService.push(popoverTrigger.nativeElement);
        },
        preventRefocus: true,
      },
      // TODO: Fix focus on post edit cancel
      {
        title: this.i18n.Core_Edit,
        isHidden: () =>
          properties.step.referenceType !== 'Task' &&
          properties.step.referenceType !== 'Post',
        defaultAction: (_, popoverTrigger) =>
          this.openEditCustomTypesModal(
            properties,
            popoverTrigger.nativeElement
          ),
        preventRefocus: true,
      },
      {
        title: this.i18n.Pathways_MoveItem,
        defaultAction: (_, popoverTrigger) => {
          this.loadMenuAction({ type: 'moveStep', properties, popoverTrigger });
        },
        preventRefocus: true,
      },
      {
        title: this.i18n.Pathways_AddAuthorNote,
        isHidden: () => !!properties.step.note,
        defaultAction: async (_, popoverTrigger) => {
          await this.authoring.updateField('note', '', properties.step, true);
        },
        preventRefocus: true,
      },
      {
        title: this.i18n.Pathways_DeleteItem,
        defaultAction: (_, popoverTrigger) => {
          this.loadMenuAction({
            type: 'removeStep',
            properties,
            popoverTrigger,
          });
        },
        isSeparated: true,
        isDestructive: true,
      },
    ];
    return pathwayAuthorMenuConfig;
  }

  private loadMenuAction(action: MenuAction) {
    this.focusStackService.push(action.popoverTrigger.nativeElement);
    switch (action.type) {
      case 'moveStep':
        this.moveStep(action.properties);
        break;
      case 'removeStep':
        this.showRemoveModal(action.properties, action.popoverTrigger);
        break;
    }
  }

  /**
   * Marks content Optional or Required
   * @param properties menu properties containing the current step object to be updated after saving edits
   */
  private async toggleOptional(
    properties: PathwayAuthoringStepMenuProperties
  ): Promise<boolean> {
    const { step } = properties;
    const pathId = properties.pathway.resourceId;

    return await this.authoring.toggleOptionalStep(pathId, step);
  }

  /**
   * Opens the same modal as the edit option in the Org Catalog for Internal (org) Inputs (aka Edit Internal Item modal)
   * @param event the click event required by the ajs service
   * @param properties menu properties containing the current step object to be updated after saving edits
   */
  private async openInternalEditModal(
    event: Event,
    properties: PathwayAuthoringStepMenuProperties
  ): Promise<boolean> {
    const isEditingInternalContent = true;
    const stepProperties: StepModalProperties = this.getStepProperties(
      event,
      properties,
      isEditingInternalContent
    );

    return await this.authoring.showEditInternalDetails(
      stepProperties,
      properties.step
    );
  }

  /**
   * Opens edit modal for Task and Post types.
   * @param properties menu properties containing the current step object to be updated after saving edits
   */
  private async openEditCustomTypesModal(
    properties: PathwayAuthoringStepMenuProperties,
    sourceTarget: any
  ): Promise<boolean> {
    if (
      properties.step.referenceType === 'Post' ||
      properties.step.referenceType === 'Task'
    ) {
      const stepModalProperties: StepModalProperties = {
        inputType: properties.step.referenceType,
        inputId: properties.step.referenceId,
        pathwayId: properties.pathway.resourceId,
        sourceTarget: sourceTarget,
        isEditing: true,
      };

      return await this.authoring.showEditCustomDetails(
        stepModalProperties,
        properties.step
      );
    }
  }

  /**
   * Opens the Edit Details modal which saves custom Title, Description, and ImageUrl to the Step object (as opposed to the reference object) for the purpose of displaying information IN PATHWAY ONLY for the given Input.
   * @param event the click event required
   * @param properties menu properties containing the current step object to be updated after saving edits
   */
  private async editDetails(
    event: Event,
    properties: PathwayAuthoringStepMenuProperties
  ) {
    const isEditingInternalContent = false;
    const stepProperties: StepModalProperties = this.getStepProperties(
      event,
      properties,
      isEditingInternalContent
    );
    return await this.authoring.showEditDetails(
      stepProperties,
      properties.step
    );
  }

  /**
   *
   * @param properties menu properties containing the current step object to be moved
   */
  private async moveStep(properties: PathwayAuthoringStepMenuProperties) {
    return await this.authoring.showMoveEditor(
      PathwayLevel.STEP,
      properties.step
    );
  }

  /**
   * Removes the content from the pathway and adds it to the Bin. Shows a confirmation modal before deleting.
   * @param properties menu properties containing the current step object to be removed
   */
  private async showRemoveModal(
    { pathway, step }: PathwayAuthoringStepMenuProperties,
    popoverTrigger: ElementRef
  ) {
    const success = await this.authoring.deleteNode(
      PathwayLevel.STEP,
      pathway.id,
      step.node
    );

    if (success) {
      this.a11yService.announcePolite(
        this.translateService.instant(PathwayActionSuccess.ADDTOBIN_STEP)
      );
      // TODO: Figure out where to put focus on delete.
      // this.focusStackService.push() // some other element
      return;
    }

    // Error thrown or modal dismissed instead of closed, so the item
    // wasn't deleted, and we can return focus to the original element.
    this.focusStackService.push(popoverTrigger?.nativeElement);
  }

  private getStepProperties(
    event: Event,
    properties: PathwayAuthoringStepMenuProperties,
    isEditingInternalContent: boolean
  ): StepModalProperties {
    const input = properties.step.reference;
    // if the input is a LearningResourceViewModel then isCmsContent will exist on the model
    const isCmsContent = input?.isCmsContent ?? input?.model?.isCmsContent;
    const stepProperties: StepModalProperties = {
      inputId: properties.step.referenceId,
      inputType: properties.step.referenceType,
      pathwayId: properties.pathway.resourceId,
      sourceTarget: event?.target as HTMLElement,
      initialModel: {
        inputId: properties.step.referenceId,
        inputType: properties.step.referenceType,
      },
      organizationId: properties.pathway.organizationId,
      isCmsContent,
      isEditingInternalContent,
    };

    return stepProperties;
  }
}
