<cdk-accordion class="expanding-title-section accordion" type="light">
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    [attr.id]="'expand-title-row'"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'expand-title-row-body'"
    class="accordion-item"
  >
    <div class="accordion-header">
      <div class="grid guts-p-full-0 guts-m-full-0">
        <div class="grid__col-md-6 guts-p-full-0 guts-m-full-0">
          <h5 class="par par--small font-medium guts-p-full-0 guts-m-full-0">
            {{ title }}
          </h5>
        </div>
        <div
          class="grid__col-md-6 guts-p-full-0 guts-m-full-0 right-text text-right"
        >
          <p class="right-text par par--small par--light">
            <span *ngIf="rightText">{{ rightText }}</span>
            <button
              (click)="accordionItem.toggle()"
              class="btn-link right-text guts-p-l-half"
              data-dgat="expanding-title-section-bcd"
              [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-label]="getAriaText(accordionItem.expanded, title)"
            >
              <span>
                <df-icon
                  [icon]="
                    accordionItem.expanded ? 'chevron-up' : 'chevron-down'
                  "
                  class="color-ebony-a61 header-icon"
                  size="small"
                ></df-icon>
              </span>
            </button>
          </p>
        </div>
      </div>
    </div>
    <div
      role="region"
      [attr.id]="'expand-title-row-body'"
      [attr.aria-labelledby]="'expand-title-row'"
      class="accordion-body"
      [ngClass]="{
        'accordion-body': true,
        'no-display': !accordionItem.expanded
      }"
    >
      <ng-content></ng-content>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
