import { Injectable } from '@angular/core';

// services
import { MentoringFlagsService } from '@app/mentoring/services/mentoring-flags.service';
import { AuthService } from '@app/shared/services/auth.service';

// types
import {
  AuthUser,
  OrganizationRole,
  OrgInfo,
} from '@app/account/account-api.model';
import { Opportunity } from '../opportunities-api.model';
import { OpportunityApplicationStageEnum } from '../opportunities.enums';

// utils
import {
  hasExternalProvider,
  isOpportunityClosed,
  isOpportunityQueued,
  isUserInterested,
} from '../utils';

/**
 * A microservice for permission checks.
 *
 * Conventions:
 * `canBe<Action>` = can action *be* performed on opportunity (wrt opportunity state)
 * `can<Action>` = can user perform action on an opportunity or opportunities in general (wrt permissions)
 * `is<State>` = is user in <state>
 * `show<Action>Button/Options` = should <action> options be shown
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunityPermissionsService {
  constructor(
    private authService: AuthService,
    private mentoringFlagsService: MentoringFlagsService
  ) {}

  /**
   * Whether the current opportunity can be applied to (only for external opportunities).
   *
   * @param opportunity - the opportunity of interest.
   */
  public canApply(opportunity: Opportunity): boolean {
    return (
      !isOpportunityClosed(opportunity) && hasExternalProvider(opportunity)
    );
  }

  /**
   * Whether the current opportunity can be cloned at all.
   *
   * @param opportunity - the opportunity of interest.
   */
  public canBeCloned(opportunity: Opportunity): boolean {
    return !hasExternalProvider(opportunity);
  }

  /**
   * Whether the current opportunity can be closed at all.
   *
   * @param opportunity - the opportunity of interest.
   */
  public canBeClosed(opportunity: Opportunity): boolean {
    return (
      !hasExternalProvider(opportunity) && !isOpportunityClosed(opportunity)
    );
  }

  /**
   * Whether the current opportunity can be deleted at all.
   *
   * @param opportunity - the opportunity of interest.
   */
  public canBeDeleted(opportunity: Opportunity): boolean {
    return !hasExternalProvider(opportunity);
  }

  /**
   * Is the opportunity open to interested users, whether they're
   * indicating interest through our system (internal opportunities)
   * or clicking an Apply button (external opportunities).
   *
   * @param opportunity - the opportunity of concern
   */
  public canBeInterested(opportunity: Opportunity): boolean {
    return !isOpportunityClosed(opportunity);
  }

  /**
   * Can the opportunity be queued at all, period.
   *
   * @param opportunity - the opportunity of concern
   */
  public canBeQueued(opportunity: Opportunity): boolean {
    return !isOpportunityClosed(opportunity);
  }

  /**
   * Can the opportunity be shared at all, period.
   *
   * @param opportunity - the opportunity of concern
   */
  public canBeShared(opportunity: Opportunity): boolean {
    return !isOpportunityClosed(opportunity);
  }

  /**
   * Can the current logged in user bulk upload opportunities.
   *
   * @param authUser the authenticated user's object.
   * @param orgInfo org that user is currently working in
   */
  public canBulkUpload(authUser: AuthUser, orgInfo: OrgInfo): boolean {
    return (
      authUser.canManageOpportunities &&
      !!orgInfo?.permissions?.uploadOpportunities
    );
  }

  /**
   * Whether the given user can create any opportunity at all.
   *
   * @param authUser - the authenticated user's object.
   */
  public canCreate(authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return authUser.canManageOpportunities || authUser.canCreateOpportunities;
  }

  /**
   * Can the current logged in user clone the opportunity.
   *
   * @param opportunity - the opportunity of concern
   * @param authUser - the authenticated user's object, passed optionally
   */
  public canClone(opportunity: Opportunity, authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return this.canBeCloned(opportunity) && this.canCreate(authUser);
  }

  /**
   * Can the current logged in user close the opportunity.
   *
   * @param opportunity - the opportunity of concern
   * @param authUser - the authenticated user's object, passed optionally
   */
  public canClose(opportunity: Opportunity, authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return this.canBeClosed(opportunity) && this.canEdit(opportunity, authUser);
  }

  /**
   * Can the current logged in user delete the opportunity.
   *
   * @param opportunity - the opportunity of concern
   * @param authUser - the authenticated user's object, passed optionally
   */
  public canDelete(opportunity: Opportunity, authUser?: AuthUser): boolean {
    return (
      this.canBeDeleted(opportunity) &&
      this.canEdit(opportunity, authUser ?? this.authService.authUser)
    );
  }

  /**
   * Can the current logged in user edit the opportunity.
   *
   * @param opportunity - the opportunity of concern
   * @param authUser - the authenticated user's object, passed optionally
   */
  public canEdit(opportunity: Opportunity, authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return (
      authUser.canManageOpportunities ||
      this.isAuthorOrCollaborator(opportunity, authUser)
    );
  }

  /**
   * Whether the current logged-in user can queue the opportunity.
   * Closed opportunities and opportunities that the user is interested
   * in *cannot* be queued by that user, unless they are third-party
   * opportunities. (ATS opportunities can be in an "interested" state
   * due to how we're tracking analytics.)
   *
   * @param opportunity - the opportunity of concern
   */
  public canQueue(opportunity: Opportunity): boolean {
    return (
      this.canBeQueued(opportunity) &&
      (!isUserInterested(opportunity) || hasExternalProvider(opportunity))
    );
  }

  /**
   * Whether the current logged-in user can share the opportunity.
   *
   * @param opportunity - the opportunity of concern
   * @param authUser - the authenticated user's object, passed optionally
   */
  public canShare(opportunity: Opportunity, authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return authUser.canShareOpportunity && this.canBeShared(opportunity);
  }

  /**
   * Whether the current logged-in user can show interest in or apply to
   * the opportunity.
   *
   * @param opportunity - the opportunity of concern
   */
  public canShowInterest(opportunity: Opportunity): boolean {
    return !isOpportunityClosed(opportunity) && !isUserInterested(opportunity);
  }

  /**
   * Checks if a user is able to view opportunities and opportunity-
   * related pages. Does *not* check the new career mobility landing
   * page feature flag.
   *
   * @param authUser the authenticated user's object, passed optionally
   */
  public canViewOpportunities(authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return (
      !!authUser?.defaultOrgInfo?.organizationId && !!authUser.hasCareerMobility
    );
  }

  /**
   * Checks if a user is able to view mentorship options.
   *
   * @param authUser the authenticated user's object, passed optionally
   */
  public canViewMentorshipOptions(authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return (
      this.canViewOpportunities(authUser) &&
      !!authUser?.enableMentorshipOpportunities &&
      this.mentoringFlagsService.showMentorshipOptions
    );
  }

  /**
   * Checks if a user should be able to view the Manage Org -> Opportunities page.
   *
   * @param authUser the authenticated user's object, passed optionally
   */
  public canViewOpportunitiesManagement(authUser?: AuthUser): boolean {
    authUser ??= this.authService.authUser;
    return (
      this.canViewOpportunities(authUser) &&
      !!(
        authUser.canCreateOpportunities ||
        authUser.canManageOpportunities ||
        authUser.canViewOrganizationOpportunityInsights
      )
    );
  }

  /**
   * Is the current logged in user an author or collaborator on the opportunity.
   *
   * @param opportunity - the opportunity of concern
   * @param authUser - the authenticated user's object, passed optionally
   */
  public isAuthorOrCollaborator(
    opportunity: Opportunity,
    authUser?: AuthUser
  ): boolean {
    authUser ??= this.authService.authUser;
    return !!opportunity.authors?.find(
      (author) =>
        author.userProfileKey === authUser.viewerProfile.userProfileKey
    );
  }

  /**
   * Whether to show the Add Opportunity button as a split button
   * with the Bulk Upload option or not.
   *
   * @param authUser - the authenticated user's object
   * @param orgInfo - the organization info
   */
  public showAddOpportunityButtonWithUpload(
    authUser: AuthUser,
    orgInfo: OrgInfo
  ): boolean {
    return this.canBulkUpload(authUser, orgInfo);
  }

  /**
   * Whether to show the "Save for later" aka. queue option on a given opportunity.
   *
   * @param opportunity - the opportunity in question.
   */
  public showQueueOption(opportunity: Opportunity): boolean {
    return this.canQueue(opportunity) && !isOpportunityQueued(opportunity);
  }

  /**
   * Whether to show the user stage badge for the current opportunity.
   *
   * @param opportunity - the opportunity in question.
   */
  public showUserStage(opportunity: Opportunity): boolean {
    return (
      opportunity.applicationStage === OpportunityApplicationStageEnum.Selected
    );
  }

  /**
   * Whether the current logged-in user can view details of interested candidates of the opportunity
   *
   * @param opportunity - the opportunity of concern
   */
  public canViewInterestedCandidateDetails(
    opportunity: Opportunity,
    authUser?: AuthUser
  ): boolean {
    authUser ??= this.authService.authUser;

    // Only users with manage or is collaborator can see candidate details
    return (
      authUser.canManageOpportunities ||
      this.isAuthorOrCollaborator(opportunity)
    );
  }

  /**
   * Whether to display the advanced group setting
   *
   * @param authUser the authenticated user's object, passed optionally
   */
  public canViewOpportunityGroupSettings(authUser?: AuthUser) {
    authUser ??= this.authService.authUser;

    return (
      authUser.hasCareerMobility &&
      authUser.careerMobilityIsGroupControlled &&
      (authUser.defaultOrgPermissionRole === OrganizationRole.technicalAdmin ||
        authUser.defaultOrgPermissionRole === OrganizationRole.admin)
    );
  }
}
