// This file is shared with the bookmarklet/extensions

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';

/**
 * Presents plain or html text, truncated with an ellipsis, which can be clicked to expand vertically to show the full text.
 * This component replaces the functionality of the ngjs dg-ellipsis directive.  Use the `html` option when the string could
 * contain any html to be rendered and accounted for. For simple fixed character count truncation consider @see EllipsisPipe.
 */
@Component({
  selector: 'dgx-text-expander',
  templateUrl: './text-expander.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextExpanderComponent implements OnInit {
  // Bindings - Input
  @Input() public content: string;
  @Input() public isExpanded? = false;
  @Input() public isHtml? = false;
  @Input() public maxCollapsedLines? = 1;
  @Input() public useReadMore? = false;
  @Input() public collapse?: boolean = true;

  // Local
  public contentHasChanged = false;
  public currentVisibleLines: any;
  public isReadMoreNeeded = false;
  public showReadMoreLink = false;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  public get showReadMore() {
    return this.useReadMore && (this.isReadMoreNeeded || this.showReadMoreLink);
  }

  // Angular methods
  public ngOnInit() {
    this.currentVisibleLines = this.isExpanded
      ? undefined
      : this.maxCollapsedLines;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.contentHasChanged = true;
    }
  }

  public isContentTrimmed(isTrimmed: boolean) {
    // only update showReadMoreLink if isTrimmed is true
    // (it may be false or true when this is called)
    // or if the content has changed since this was last checked
    if (isTrimmed || this.contentHasChanged) {
      this.showReadMoreLink = isTrimmed;
      if (this.isExpanded) {
        this.toggleExpanded();
      }
      if (isTrimmed !== this.isReadMoreNeeded) {
        this.cdr.detectChanges();
      }
    }
    this.isReadMoreNeeded = isTrimmed;
    this.contentHasChanged = false;
  }

  public toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.isExpanded ? this.showAllLines() : this.showMaxLines();
  }

  private showAllLines() {
    this.currentVisibleLines = undefined;
  }

  private showMaxLines() {
    this.currentVisibleLines = this.maxCollapsedLines;
  }
}
