<div
  dgxUploader
  (dragState)="onChangeDragState($event)"
  (fileDropped)="onFileSelected($event)"
  (click)="inputRef.click()"
  [ngClass]="{
    '__container--focused': isFocused,
    '__container--has-data': hasData && !errorMessage && !storageLimitError,
    '__container--error': errorMessage || storageLimitError,
    '__container--disable-uploader':
      shouldDisableContentUploader ||
      (!(showReplaceButton ?? true) && !!imageUrl),
    '__container--remove-click-events':
      storageLimitError || (hasData && showEditButton),
  }"
  class="__container guts-p-full-1-half guts-m-b-1"
>
  <input
    type="file"
    #inputRef
    id="inputRef"
    class="a11y-hide hide-focus"
    (change)="onFileSelected($event)"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [attr.aria-label]="
      !!storageLimitError
        ? storageLimitError
        : !!storageLimitWarning
          ? storageLimitWarning
          : i18n.dgImageEdit_ChangePhoto
    "
    [attr.aria-describedby]="ariaDescribedBy"
    [attr.aria-errormessage]="ariaErrorMessage"
    data-dgat="image-upload-section-6b6"
  />
  <!-- Default empty state -->
  <ng-container *ngIf="!hasData && !errorMessage && !storageLimitError">
    <div>
      <span
        class="__icon-wrapper round ib guts-p-full-half vertical-center guts-m-r-1"
      >
        <df-icon
          [icon]="icon"
          size="medium"
          class="color-white full-height"
        ></df-icon>
      </span>
    </div>
    <div>
      <div
        class="__header par par--small"
        [ngClass]="{
          '__header--center': !isSettingsLoading && !labels.allowedDescription,
        }"
      >
        <p id="file-upload-desc" class="par par--small">
          {{ labels.header }}
        </p>
        <ng-container *ngIf="canCancel"
          >·
          <a
            (click)="cancelReplace($event)"
            class="color-blue"
            data-dgat="upload-section-ad9"
            >{{ i18n.Core_Cancel }}</a
          ></ng-container
        >
      </div>
      <p id="file-upload-types" class="par par--small par--light_a">
        {{ labels.allowedDescription }}
        <!-- spin until the allowed file types and max size are loaded -->
        <df-spinner
          class="rel guts-m-l-3-quart"
          *ngIf="isSettingsLoading && !labels.allowedDescription"
          spinnerContext="button"
        ></df-spinner>
      </p>
    </div>
  </ng-container>

  <!-- Has image state -->
  <div
    *ngIf="hasData && !errorMessage && !storageLimitError"
    class="l_flexbar full-width"
  >
    <div
      class="__image-container full-height guts-m-r-1"
      *ngIf="isUploadingImage && (thumbnailImage || imageUrl)"
    >
      <img
        [src]="thumbnailImage || imageUrl"
        class="cover"
        [alt]="altText || i18n.dgImageUpload_ImagePreview"
        [title]="altText || i18n.dgImageUpload_ImagePreview"
      />
      <div *ngIf="!isUploadingImage">
        <!-- if an image is not being uploaded, an element can be provided to show in place of the image preview -->
        <ng-content></ng-content>
      </div>
    </div>
    <div *ngIf="selectedFile" class="__file-information l_flex l_flex-column">
      <div
        class="__file-name par par--small font-semibold"
        [class.__file-name--fix-width]="useCropper"
        title="{{ fileName }}"
        [dgxLineClampText]="fileName"
        [clampLinesMax]="1"
      ></div>
      <p class="__file-metadata par par--small par--light_a">
        {{ getFileMetadataDisplay(selectedFile) }}
      </p>
    </div>
    <div class="guts-m-l-auto" [class.l_flex]="showEditButton">
      <dgx-progress-circle
        *ngIf="isLoading"
        [percent]="progress"
        [config]="{
          color: progressCircleInProgress,
          bgcolor: progressCircleTrailColor,
          radius: 12,
        }"
      ></dgx-progress-circle>
      <button
        *ngIf="!isLoading && showEditButton && (showReplaceButton ?? true)"
        df-button
        dfButtonType="passive"
        df-button-small
        class="__edit-button color-ebony guts-m-r-1 font-semibold"
        data-dgat="image-upload-section-2f3"
        (click)="edit($event)"
      >
        {{ labels.editButton }}
      </button>
      <button
        *ngIf="
          !isLoading &&
          (useCropper || (!shouldDisableContentUploader && showDeleteButton))
        "
        df-button
        dfButtonType="destructive"
        df-button-small
        class="__delete-button font-semibold"
        data-dgat="image-upload-section-461"
        (click)="delete($event)"
      >
        {{ labels.deleteButton }}
      </button>
      <button
        *ngIf="
          !isLoading &&
          !useCropper &&
          !shouldDisableContentUploader &&
          (showReplaceButton ?? true)
        "
        df-button
        dfButtonType="passive"
        df-button-small
        class="__replace-button color-ebony font-semibold"
        (click)="replace($event)"
        data-dgat="upload-section-627"
      >
        {{ labels.replaceButton }}
      </button>
    </div>
  </div>

  <!-- Error State -->
  <ng-container *ngIf="errorMessage || storageLimitError">
    <div class="__error-message color-error l_flexbar guts-m-r-2">
      <span class="l_flexbar guts-m-r-1">
        <df-icon
          icon="exclamation-circle"
          size="medium"
          class="color-red"
        ></df-icon>
      </span>
      <span
        (click)="$event.stopPropagation()"
        id="upload-section-error-message"
        class="par par--small"
        [ngClass]="{
          'color-error': !storageLimitError,
        }"
        [innerHTML]="errorMessage || storageLimitError"
      ></span>
    </div>
    <div *ngIf="!storageLimitError" class="guts-m-l-auto l_flexbar">
      <button
        df-button
        dfButtonType="passive"
        df-button-small
        class="__retry-button color-ebony font-semibold"
        (click)="retry($event)"
        data-dgat="image-upload-section-9fa"
      >
        {{ labels.retryButton }}
      </button>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="storageLimitWarning">
  <df-local-notification
    [type]="notificationType"
    [text]="storageLimitWarning"
    icon="exclamation-circle"
    size="small"
  ></df-local-notification>
</ng-container>

<p
  class="par par--small par--light font-medium inline"
  *ngIf="isUploadingImage"
>
  <a
    [href]="imageDocumentationLink"
    class="link"
    target="_blank"
    aria-describedby="a11yNewWindowDescription"
    data-dgat="upload-section-824"
  >
    {{ i18n.dgImageEdit_DocumentationLabel }}
    <df-icon
      size="extra-small"
      icon="arrow-up-right-out"
      class="color-blue guts-m-l-quart"
    ></df-icon>
  </a>
</p>

<!-- File documentation link -->
<p
  class="par par--small par--light font-medium inline"
  *ngIf="displayfileDocumentation"
>
  <a
    [href]="fileDocumentationLink"
    class="link"
    target="_blank"
    aria-describedby="a11yNewWindowDescription"
    data-dgat="upload-section-824"
  >
    {{ i18n.dgFileUploadButton_ViewFileDocumentation }}
    <df-icon
      size="extra-small"
      icon="arrow-up-right-out"
      class="color-blue guts-m-l-quart"
    ></df-icon>
  </a>
</p>

<dgx-image-position
  *ngIf="showImagePosition"
  (positionChanged)="onPositionChanged($event)"
  [position]="imagePosition"
  class="guts-m-t-1"
></dgx-image-position>
