import { DOCUMENT } from '@angular/common';
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { take } from 'rxjs/operators';

import {
  animate,
  AnimationEvent,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { isKey, Key } from '../../utilities/keyboard/key-helper';
import { DfAccessibilityService } from '../../utilities/accessibility/accessibility.service';
import { DfFocusStackService } from '../../utilities/layout/focus-stack.service';
import {
  DfClosePopoverResult,
  DfPopover,
  DfPopoverService,
} from '../services/popover.service';
import {
  DfPlacement,
  DfPlacementArray,
  DfPositioningService,
} from '../../utilities/layout/positioning.service';

const animationTiming = '400ms cubic-bezier(0.68, -0.55, 0.265, 1.55)';

/** An popover with custom content. You can put whatever you like in it and manage/monitor closure and any selected results
 * via the @see PopoverService.
 */
@Component({
  selector: 'df-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      /* Opening */
      transition('closed => up', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateY(0rem)' })
        ),
      ]),
      transition('closed => right', [
        style({ opacity: 0, transform: 'translateX(-1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateX(0rem)' })
        ),
      ]),
      transition('closed => down', [
        style({ opacity: 0, transform: 'translateY(-1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateY(0rem)' })
        ),
      ]),
      transition('closed => left', [
        style({ opacity: 0, transform: 'translateX(1rem)' }),
        animate(
          animationTiming,
          style({ opacity: 1, transform: 'translateX(0rem)' })
        ),
      ]),
      /* Closing */
      transition('up => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateY(1rem)' })
        ),
      ]),
      transition('right => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateX(-1rem)' })
        ),
      ]),
      transition('down => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateY(-1rem)' })
        ),
      ]),
      transition('left => closed', [
        animate(
          animationTiming,
          style({ opacity: 0, transform: 'translateX(1rem)' })
        ),
      ]),
    ]),
  ],
})
export class DfPopoverComponent implements DfPopover, OnDestroy, OnChanges {
  /**
   * Set the reference for the trigger element for the popup, typically a button.
   * (i.e. `popoverContent` if trigger is marked as `#popoverContent`)  **Required.**
   * @required
   */
  @Input() public popoverTrigger: ElementRef;
  /**
   * Determines which direction the popover opens.  Defaults to `auto`.
   * In 'auto', the placements will prefer this order based on space availability in the window:
   * `bottom-left`, `bottom-right`, `bottom`, `top-left`, `top-right`, `top`, `left-top`, `left-bottom`, `right-top`, `right-bottom`, `left`, `right`.
   */
  @Input() public placement: DfPlacement | DfPlacementArray = 'auto';
  /**
   * Determines whether the popover is appended inside the parent container or the page body.
   */
  @Input() public isAppendToBody = false;
  /**
   * Should the popover attempt to focus on the first focusable item in the popover.  Defaults to true.
   */
  @Input() public isFocusFirstItem = true;
  /**
   * By default the popover will use a mobile view at the baby-bear breakpoint.  If you want to override this,
   * set this input to true.
   */
  @Input() public isMobileOptedOut = false;
  /**
   * Allows override of the popover state.
   */
  @Input() public isOpen: boolean;
  /**
   * Adds an extra offset to the left pixels, pushing the popover to the right.  Works in all placement modes, defaults to 0.
   * Unlike {@link placementAdjustLeftRem}, this will moves popover's host element in the positioning service and not just adjust
   * the inner `popover_window` element.
   */
  @Input() public placementOffsetLeftPixels = 0;
  /**
   * Adds an extra offset to the left side of the popover, pushing it to the right.  Works on the inner `popover_window` element,
   * so this is preferred, but if you need to override you may also want to look at {@link placementOffsetLeftPixels}.
   */
  @Input() public placementAdjustLeftRem = 0;
  /**
   * Adds an extra offset to the top side of the popover, pushing it down.  Works on the inner `popover_window` element,
   * so this is preferred, but if you need to override you may also want to look at {@link placementOffsetLeftPixels}.
   */
  @Input() public placementAdjustTopRem = 0;

  /**
   * Public property to tell the template whether or not the popover is visible.
   */
  public isPopoverPresent = false;
  /**
   * Public property for template use, direction is determined by the placement of the popover.
   */
  public animationState: 'closed' | 'up' | 'right' | 'down' | 'left' = 'closed';

  /** Wrapper to contain the popover when it's appended to the `body` element */
  public bodyContainer: HTMLElement | null = null;

  @ViewChild('popover') public popover: ElementRef<HTMLElement>;

  /**
   * Fires when the popover open or close animation begins.
   * In most cases,this is the right event to listen to
   * monitor popover state.
   */
  @Output() public isOpenChange = new EventEmitter<boolean>();

  /** Fires when the popover open or close animation ends.
   * This is mainly provided for managing focus transitions or
   * other accessibility state.
   */
  @Output() public transitionComplete = new EventEmitter<boolean>();
  /**
   * Allows consumers to get values out of the view model, if any,
   * and what the state of `preventRefocus` for this popover is.
   * {@link DfClosePopoverResult}
   */
  @Output() public result = new EventEmitter<DfClosePopoverResult>();

  /**
   * Private property to track internal open state.
   */
  private _isOpen = false;
  /**
   * Private property to track internal animation state.
   */
  private isAnimating = false;
  /**
   * Private property to track listeners for click and key handling on the page.
   */
  private listeners: (() => void)[] = [];
  /**
   * Private property to determine refocus status for an individual popover.
   */
  private preventRefocus = false;

  constructor(
    private popoverService: DfPopoverService,
    private focusStackService: DfFocusStackService,
    private renderer: Renderer2,
    private positioningService: DfPositioningService,
    private elementRef: ElementRef,
    private accessibilityService: DfAccessibilityService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnChanges({ isOpen }: SimpleChanges): void {
    if (this.isAnimating) {
      // We only check when we are not in the middle of an animation.
      return;
    }
    if (isOpen?.currentValue) {
      if (!!isOpen.currentValue !== this._isOpen) {
        this.animateOpen();
      }
    } else {
      if (!!isOpen?.currentValue !== this._isOpen) {
        this.triggerClose();
      }
    }
  }

  public toggle() {
    if (this.isAnimating) {
      return;
    }
    if (!this._isOpen) {
      this.isOpen = true; // keep binding in sync
      this.animateOpen();
    } else {
      this.isOpen = false; // keep binding in sync
      this.triggerClose();
    }
  }

  public ngOnDestroy() {
    if (this.isOpen) {
      this.isOpen = false;
      this.popoverService.close(); // clears out activePopover
      this.removeContainerFromBody();
    }
    this.removeListeners();
  }

  public onAnimationEvent(event: AnimationEvent) {
    // Fires after the open/close animation transitions are complete.
    // The animation is in the 'void' state briefly while the popover
    // placement is determined and then moves to the 'closed state where
    // it begins animating using the transitions defined above. The transition used is based on the placement.

    /* after opening */
    if (event.fromState === 'closed' && event.toState !== 'void') {
      // If `isOpen` is immediately changed back to false make sure popover closes
      // This can happen when a cursor moves rapidly over a hover event trigger
      if (!this.isOpen) {
        this.focusStackService.clear();
        this.triggerClose();
      } else {
        // track where focus should go after popover closes.
        // this can be overriden in the SimpleItemViewModel
        this.focusStackService.push(this.popoverTrigger.nativeElement);

        if (this.isFocusFirstItem) {
          this.accessibilityService.focusNextFocusable(
            this.popover.nativeElement
          );
        }

        // Listen for closure triggering events only when popover is open
        // The @HostListener mechanism is more convenient, but not efficient for this case.
        this.listeners.push(
          this.renderer.listen('document', 'click', ($event: MouseEvent) => {
            return this.onDocumentClick($event);
          })
        );
        this.listeners.push(
          this.renderer.listen('document', 'keyup', (ev) => {
            this.onDocumentKeyUp(ev);
          })
        );
        this.listeners.push(
          this.renderer.listen('window', 'resize', (ev) => {
            this.positionElement();
          })
        );

        this.isAnimating = false;
        this.transitionComplete.emit(true);
      }
    }

    /* after closing */
    if (event.fromState !== 'void' && event.toState === 'closed') {
      this.resetContainer();
      this.isPopoverPresent = false; // remove the popover once it's animated closed

      // The popover service currently only supports a single popover open at a time (opening one closes all open)
      // If there _is_ another popover open, there won't be a top item in the current focus stack at this point.
      // see popover.service for more details
      if (this.focusStackService.top && !this.preventRefocus) {
        this.focusStackService.pop();
      }
      this.preventRefocus = false; // reset for next time
      this.cdr.detectChanges();
      this.isAnimating = false;
    }
  }

  private animateOpen() {
    this._isOpen = true;

    // tracks the current popover as being open so that it can be closed by the popoverService if another popover gets opened
    this.popoverService.openPopover = this;

    // popoverService.close$ resolves after popoverService does its thing
    // to prevent more than one popover being open at the same time. All calls to popoverService.close()
    // in this component end up here eventually, but so does the case where a new popover
    // gets opened causing the previous to be closed.

    this.popoverService.close$
      .pipe(take(1))
      .subscribe((result: DfClosePopoverResult) => {
        if (this._isOpen) {
          this.result.emit(result);
          this.preventRefocus =
            result?.itemViewModel?.preventRefocus ||
            result?.preventRefocus ||
            false;
          this.animateClose();
        }
      });

    this.isOpenChange.emit(true);

    // bring popover element in to DOM for positioning
    this.isAnimating = true;
    this.isPopoverPresent = true;
    // Do the rest on the next tick so the popover is present in the DOM
    this.cdr.detectChanges();

    if (!this.popoverTrigger) {
      console.error(
        'No popoverTrigger provided! Use DfPopoverTrigger directive or supply an elementRef.nativeElement as a popoverTrigger Input()'
      );
      return;
    }

    // append to body handling (prior to placement being set but after trigger is found to be present)
    this.applyContainer();
    this.positionElement();

    // begin animation now that placement is determined
    const dir = this.positioningService.getDirectionFromPlacement(
      this.placement as DfPlacement
    );
    this.animationState = dir;
    this.cdr.detectChanges();
  }

  private positionElement() {
    const hostElement = this.popoverTrigger.nativeElement;
    // `bodyContainer` is null by default, but set to a <div> when `isAppendToBody` is true
    const targetElement = this.bodyContainer || this.elementRef.nativeElement;
    this.placement = this.positioningService.positionElement({
      hostElement, // required for positioning relative to the trigger
      targetElement,
      placement: this.placement,
      offsetLeftPixels: this.placementOffsetLeftPixels,
      appendToBody: this.isAppendToBody,
      isMobileOptedOut: this.isMobileOptedOut,
    });
  }

  private triggerClose() {
    // Always use this method to close to notify listeners and update state
    if (this._isOpen) {
      // need to clear this state to allow the popup to be programmatically repositioned
      this.positioningService.originalPlacements = undefined;
      this.popoverService.close();
    }
  }

  private animateClose() {
    this._isOpen = false;
    this.isOpenChange.emit(false);

    this.removeListeners();

    if (this.popover?.nativeElement) {
      // begin animation
      this.isAnimating = true;
      this.animationState = 'closed';
      this.cdr.detectChanges();
    }
  }

  private onDocumentClick(event: MouseEvent) {
    // Click anywhere outside the popover closes after initial open click (or programmatic open)
    if (this._isOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.focusStackService.clear();
      this.triggerClose();
    }
  }

  private onDocumentKeyUp(ev: KeyboardEvent) {
    if (this._isOpen && isKey(ev, Key.Escape)) {
      this.triggerClose();
    }
  }

  private removeListeners() {
    for (const listener of this.listeners) {
      listener();
    }
    this.listeners = [];
  }

  /**
   * Append the popover to the body element
   * from ngBootstrap:
   * https://github.com/ng-bootstrap/ng-bootstrap/blob/ea59f8829f099801df34f15b77a9e06a51f2a110/src/dropdown/dropdown.ts
   */
  private applyContainer() {
    if (this.isAppendToBody) {
      this.resetContainer();
      const dropdownMenuElement = this.popover.nativeElement;
      const bodyContainer = (this.bodyContainer =
        this.bodyContainer || this.renderer.createElement('div'));
      bodyContainer.id = 'popoverBodyContainer';

      this.renderer.appendChild(bodyContainer, dropdownMenuElement);
      this.renderer.appendChild(this.document.body, bodyContainer);
    }
  }

  /**
   * Remove the popover from the body element
   * from ngBootstrap:
   * https://github.com/ng-bootstrap/ng-bootstrap/blob/ea59f8829f099801df34f15b77a9e06a51f2a110/src/dropdown/dropdown.ts
   */
  private resetContainer() {
    if (this.isAppendToBody) {
      if (this.popover) {
        const dropdownElement = this.elementRef.nativeElement;
        const dropdownMenuElement = this.popover.nativeElement;
        this.renderer.appendChild(dropdownElement, dropdownMenuElement);
      }
      this.removeContainerFromBody();
    }
  }

  // Remove content that is appended to the body
  private removeContainerFromBody() {
    if (this.isAppendToBody && this.bodyContainer) {
      this.renderer.removeChild(this.document.body, this.bodyContainer);
      this.bodyContainer = null;
    }
  }
}
