import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';
import {
  PathwayAuthoring,
  PathwayFacade,
  PathwayStep,
  PathwayViewModel,
} from '@dg/pathways-rsm';
import { Observable } from 'rxjs';

@Component({
  selector: 'dgx-pathway-author-note',
  templateUrl: './pathway-author-note.component.html',
  styleUrls: ['./pathway-author-note.component.scss'],
})
export class PathwayAuthorNoteComponent extends SubscriberBaseDirective {
  @Input() public step: PathwayStep;
  public readonly i18n = this.translate.instant(['Pathways_NoteFromAuthor']);
  public markdownOptions = {
    allowImages: false,
  };
  public vm$: Observable<PathwayViewModel>;

  constructor(
    private translate: TranslateService,
    private facade: PathwayFacade,
    private authoring: PathwayAuthoring
  ) {
    super();
    this.vm$ = this.facade.vm$;
  }

  public toggleButton() {
    const shouldDisplayNote = !this.step.note.shouldDisplayNote;
    this.authoring.toggleDisplayOfAuthorNote(this.step, shouldDisplayNote);
  }
}
