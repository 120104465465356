import { DatePipe } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { VideoService } from '@app/inputs/services/video.service';
import { MarkdownService } from '@app/markdown/services/markdown.service';
import { HtmlToPlaintextPipe } from '@app/shared/pipes/htmlToPlaintext.pipe';
import { TranslateService } from '@ngx-translate/core';
import { DisplayTypePipe } from './display-type.pipe';
import { LDFlagsService } from '@dg/shared-services';

@Pipe({
  name: 'inputToLearningResource',
})
export class InputToLearningResourcePipe implements PipeTransform {
  constructor(
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private markDownService: MarkdownService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private displayTypePipe: DisplayTypePipe,
    private datePipe: DatePipe,
    private videoService: VideoService,
    private ldFlagsService: LDFlagsService
  ) {}
  public transform(
    input: any,
    trackingArea?: string
  ): LearningResourceViewModel {
    trackingArea = trackingArea ? trackingArea : '';
    return new LearningResourceViewModel(
      input,
      this.displayTypePipe,
      this.htmlToPlaintextPipe,
      this.markDownService,
      this.sanitizer,
      trackingArea,
      this.translate,
      this.datePipe,
      this.videoService,
      this.ldFlagsService
    );
  }
}
