<!-- Desktop layout -->
<ng-container *ngIf="!(isTablet | async); else mobileLayout">
  <ng-container *ngTemplateOutlet="searchField"></ng-container>
</ng-container>

<!-- Mobile layout -->
<ng-template #mobileLayout>
  <div ngbDropdown display="static" class="mobile-mode">
    <button
      df-button
      dfButtonType="passive"
      id="globalSearch"
      [attr.aria-label]="i18n.A11y_ToggleSearch"
      [title]="i18n.A11y_ToggleSearch"
      aria-haspopup="true"
      class="btn-utility rounded--large global-search__trigger"
      data-dgat="global-search-0ce"
      ngbDropdownToggle
    >
      <df-icon size="medium" icon="magnifying-glass"></df-icon>
    </button>
    <div
      ngbDropdownMenu
      class="global-search guts-m-t-1 full-width"
      aria-labelledby="globalSearch"
    >
      <ng-container *ngTemplateOutlet="searchField"></ng-container>
    </div>
  </div>
</ng-template>

<!-- Search field -->
<ng-template #searchField>
  <dgx-search-initiation-autocomplete
    [isMobile]="isTablet | async"
    [showDefaults]="true"
    [initiationLocation]="initiationLocation"
    (inputEnter)="inputEnterHandler($event)"
    [shouldRenderInGlobalSearchMode]="true"
  ></dgx-search-initiation-autocomplete>
</ng-template>
