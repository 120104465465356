import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { AuthService } from './auth.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { UserStaticSettingsService } from '@app/profile-settings/user-static-settings.service';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { of, throwError } from 'rxjs';
import { DgError } from '@app/shared/models/dg-error';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface SurveyAction {
  endpoint: string;
  trackingSetting: string;
}

@Injectable({
  providedIn: 'root',
})
export class SurveyAndTipsService {
  public enableParticipateInUserSurveys: boolean;
  public enableShowInAppTips: boolean;

  public i18n = this.translate.instant([
    'UserSettingsSvc_CommunicationSettingsSaved',
    'UserSettingsSvc_CommunicationSettingsSavedFailed',
  ]);

  constructor(
    private authUser: AuthService,
    private http: NgxHttpClient,
    private notifier: NotifierService,
    private tracker: TrackerService,
    private translate: TranslateService,
    private userStaticSettingsService: UserStaticSettingsService
  ) {
    const _authUser: AuthUser = this.authUser.authUser;
    const settings = _authUser?.orgInfo[0]?.settings;
    this.enableParticipateInUserSurveys = settings?.degreedSurveys;
    this.enableShowInAppTips = settings?.inAppTips;
  }

  public togglePreference(
    type: 'surveys' | 'tips',
    settingValue: boolean
  ): Observable<any> {
    const { endpoint, trackingSetting } = this.getSurveyAction(type);

    return this.http
      .post(`${endpoint}?settingValue=${settingValue}`, null)
      .pipe(
        map((response) => {
          this.notifier.showSuccess(
            this.i18n.UserSettingsSvc_CommunicationSettingsSaved
          );
          this.tracker.trackEventData({
            action: 'Profile Communication Settings Changed',
            properties: {
              Setting: trackingSetting,
              Location: 'Profile',
              Value: settingValue ? 'Turned On' : 'Turned Off',
            },
          });
          return response;
        }),
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.i18n.UserSettingsSvc_CommunicationSettingsSavedFailed,
              error
            )
          );
        })
      );
  }

  /** Conditionally loads the profile from the user's static settings when the user should have surveys OR tips */
  public getProfile(): Observable<any> {
    if (this.enableParticipateInUserSurveys || this.enableShowInAppTips) {
      return this.userStaticSettingsService.getPersonalSettings();
    }

    return of({});
  }

  private getSurveyAction(type: 'surveys' | 'tips'): SurveyAction {
    switch (type) {
      case 'surveys':
        return {
          endpoint: '/user/UpdateParticipateInUserSurveysSetting',
          trackingSetting: 'Survey Opt-In',
        };
      case 'tips':
        return {
          endpoint: '/user/UpdateShowInAppTipsSetting',
          trackingSetting: 'Tips Opt-In',
        };
      default:
        throw new Error(`Unrecognized action type: "${type}"`);
    }
  }
}
