<div class="field-group cursor-pointer" data-dgat="newSearchUsers-6d0">
  <div
    class="field-group__input field-group__input--tag l_flex l_flex-top-children l_flex-wrap"
  >
    <span
      class="guts-m-r-3-quart guts-m-v-3-quart par par--small font-medium color-ebony"
    >
      <span>{{ i18n.Core_To }}:</span>
    </span>
    <!--selected user tags list-->
    <button
      df-button
      dfButtonType="clear"
      df-button-small
      *ngFor="let item of selected"
      class="bg-color-ebony-a08 color-ebony guts-m-l-quart guts-m-v-half guts-m-r-half user-tag"
      (click)="removeInvitee(item)"
      data-dgat="newSearchUsers-7d9"
    >
      <dgx-profile-pic
        *ngIf="asUserSearchItem(item).picture"
        class="user-tag__picture"
        [profile]="asUserSearchItem(item)"
        size="tiny"
      >
      </dgx-profile-pic>
      <span class="no-wrap"
        >{{ item.name }}
        <df-icon
          icon="cross"
          size="small"
          class="guts-m-l-quart color-ebony-a45"
        ></df-icon>
      </span>
    </button>
    <dgx-user-search
      class="l_flex-one"
      [labelKey]="labelKey"
      [placeholder]="i18n.recommendationForm_SearchByName"
      [autofocus]="autoFocus"
      [useSearchField]="false"
      [search]="searchGroupsOrUsers"
      [isLoading]="isLoading"
      [hideResults]="true"
      [usersOnly]="usersOnly"
      [excludeSelectedRecipients]="true"
      [showSuggestions]="showSuggestions"
      [recipients]="selected"
      (removeRecipient)="removeInvitee($event)"
      (selectRecipient)="addInvitee($event)"
    ></dgx-user-search>
  </div>
</div>
<div class="guts-m-b-1-half" [hidden]="!noResults">
  <span class="guts-p-v-1 ib">{{ i18n.recommendationForm_NoResults }}</span>
</div>
