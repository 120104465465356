import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { SkillsGuard } from '../../orgs/services/guards/skills.guard';
import { LDFlagsService } from '@dg/shared-services';

@Injectable({
  providedIn: 'root',
})
export class SkillsPlatformGuard extends SkillsGuard {
  constructor(
    authService: AuthService,
    @Inject(WindowToken) windowRef: Window,
    router: Router,
    private ldFlagsService: LDFlagsService
  ) {
    super(authService, windowRef, router);
  }

  override get hasPermission() {
    const hasSkillsOctober2024LDFlag =
      this.ldFlagsService.degreedSkillsOctober2024;
    const hasSkillsPlatform =
      !!this.authUser?.defaultOrgInfo?.settings.enableSkillsPlatform &&
      !!this.authUser?.defaultOrgInfo?.permissions.manageSkillsPlatform;

    return (
      super.hasPermission && hasSkillsPlatform && hasSkillsOctober2024LDFlag
    );
  }
}
