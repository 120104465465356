import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MentionService } from '@app/comments/mentions/services/mention.service';
import { GroupPrivacyLevel } from '@app/groups/group-api';
import {
  SimpleModalComponent,
  SimpleModalInputBindings,
} from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { CommentSubmission, MentionItem, Resource } from '../../comments.model';
import { MentionInputComponent } from '@app/comments/mentions/components/mention-input/mention-input.component';
import { stopEvent } from '@app/comments/mentions/utils';
import { AuthUser } from '@app/account/account-api.model';

export type Parent = Resource;

/**
 * Used by GROUP-COMMENT/GROUP-ACTIVITY-POST as well as comment-thread/comment.
 * A future version of this ecosystem should use a facade+view model to better
 * manage comments, comment threads, and mentions.
 *
 * A 'base' comment-field component could be extended, perhaps, but the point
 * would be to have two separate component TEMPLATES, as the two scenarios are
 * different enough to make sharing a component awkward, but they NEED to share
 * a form and most of the same methods in order not to have tons of duplicate,
 * out-of-sync code.
 *
 * So this is our (ugly, but improved) compromise over the previous code, which
 * used an earlier version of the mention directive on a content-editable div and
 * then also comment-field components elsewhere, and was vulnerable to XSS attacks,
 * as well as prone to hanging and chugging.
 *
 * This way we can also take advantage of built-in reactive form validity, etc.
 */
@Component({
  selector: 'dgx-comment-field',
  templateUrl: './comment-field.component.html',
  styleUrls: ['./comment-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentFieldComponent implements OnInit {
  @Input() public ariaLabel: string;
  /** Optional value to initialize the input field with */
  @Input() public initialValue?: string = '';
  @Input() public initialMentions?: MentionItem[] = [];
  /** Optional value for setting the SDET data-dgat field */
  @Input() public dataDgat?: string;
  /** A new comment is being submitted to the server */
  @Input() public isSubmitting = false;
  /** Determines if the submit button is shown */
  @Input() public useDefaultControls? = true;
  /** Determines if the field should autofocus. Default: true */
  @Input() public autoFocus? = true;
  /** Passed to the mention directive */
  @Input() public groupId?: number;
  /** Passed to the mention directive */
  @Input() public groupPrivacy?: GroupPrivacyLevel;
  /** Determines if the field should read-only. Default: false */
  @Input() public isReadOnly? = false;
  /** For a 'seamless' layout. Used by groups. */
  @Input() public isSeamless? = false;
  /** For a 'mock textarea' look. Used by groups. */
  @Input() public isPseudoTextarea? = false;

  /** Fires when the user triggers a submit action */
  @Output() public submitComment = new EventEmitter<CommentSubmission>();
  /** Fires when the user triggers a reset action */
  @Output() public resetComment = new EventEmitter<null>();
  @Output() public focusCommentField = new EventEmitter<void>();

  @ViewChild('mention', { static: true })
  public mentionRef!: MentionInputComponent;
  @ViewChild('form', { static: true })
  public formRef: ElementRef<HTMLFormElement>;

  public i18n = this.translateService.instant([
    'A11y_AddComment',
    'CommentsCtrl_PublicComment',
    'CommentsCtrl_PublicCommentDesc',
    'Core_Comment',
    'Core_YesSure',
    'dgComments_ShareSomethingAbout',
  ]);
  public authUser: AuthUser;
  public commentForm: FormGroup;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private mentionService: MentionService,
    private modalService: ModalService
  ) {}

  public get value(): string {
    return this.commentForm?.value?.comment;
  }

  public get mentions(): MentionItem[] {
    return this.commentForm?.value?.mentions;
  }

  public ngOnInit(): void {
    this.ariaLabel = this.ariaLabel
      ? this.ariaLabel
      : this.i18n.dgComments_ShareSomethingAbout;

    // TODO: View model, view model, view model! ALL of this should be
    // initialized via a facade.
    this.initialValue = this.initialValue ? this.initialValue : '';
    this.initialMentions = this.initialMentions?.length
      ? this.initialMentions
      : [];
    this.commentForm = this.mentionService.initializeCommentForm(
      this.initialValue,
      this.initialMentions
    );

    this.authUser = this.authService.authUser;

    // Vanilla JS event listener becuase *this* lets us truly prevent reset
    // from *emptying* the form, which is probably not what we want it to do!
    // (The key is `capture: true`, which runs our listener before even native
    // HTML handling. Without it, preventDefault() doesn't actually work!)
    this.formRef.nativeElement.addEventListener(
      'reset',
      (event: Event) => this.onReset(event),
      {
        capture: true,
      }
    );
  }

  // Again, view model. For both onSubmit and onReset, there should be a 'default'
  // version on the facade, which groups can then call (super.onSubmit) along with
  // its own specialized handling. See: Roughly what's going on with the updated
  // content modals.
  public onSubmit(): void {
    if (this.commentForm.invalid || this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    if (this.authUser.isPrivateProfile) {
      return this.showPrivacyModal();
    }

    this.emitSubmitEvent();
  }

  /**
   * Can be called manually with no event. When that happens, preventDefault etc
   * are not invoked, and the resetComment event is not emitted.
   *
   * @param event
   */
  public onReset(event?: Event): void {
    if (event) {
      stopEvent(event);
    }

    // Don't attempt to cancel a submission in progress, it won't work anyway.
    if (this.isSubmitting) {
      return;
    }

    this.updateFormAndView(this.initialValue, this.initialMentions);

    if (event) {
      this.resetComment.emit();
    }

    // Call MentionInputComponent reset, which will focus the textarea
    // and resize it back to the smallest size, if needed.
    this.mentionRef.reset();
  }

  private showPrivacyModal(): void {
    const inputs: SimpleModalInputBindings = {
      headerText: this.i18n.CommentsCtrl_PublicComment,
      bodyText: this.i18n.CommentsCtrl_PublicCommentDesc,
      submitButtonText: this.i18n.Core_YesSure,
      canCancel: true,
    };
    this.modalService
      .show<SimpleModalComponent>(SimpleModalComponent, { inputs })
      .subscribe(() => this.emitSubmitEvent());
  }

  private emitSubmitEvent(): void {
    // TODO: View model, view model, view model. We have to format these here, then patch them
    // *back* into the form for snappy UI updates, in order to avoid formatting the mentions over
    // and over.
    // Alternatively: Make the BE expect the vanityUrl-instead-of-upk version of this, so that we only
    // have one version of plain text mentions. (Can't directly expose UPKs to the end user like this,
    // plus @[some random number] is a lot less user-readable than @[vanityUrl](My friend's name), so.)
    const { comment, mentions } =
      this.mentionService.formatCommentAndMentionsForBE(
        this.value,
        this.mentions
      );
    this.updateFormAndView(comment, mentions);

    this.submitComment.emit({
      value: comment,
      mentions,
    });
  }

  private updateFormAndView(comment: string, mentions: MentionItem[]): void {
    // Immediately flip this value here for a snappier UI feeling. These values also
    // need to be updated in the parent component, but for slow connections especially
    // this quick update helps a lot.
    this.isSubmitting = false;

    // Update form values.
    this.commentForm.setValue({
      comment,
      mentions,
    });
  }
}
