import { InternalTagRatingTypes } from '@app/tags/tags';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a skill rating request notification */
@Component({
  selector: 'dgx-user-tag-rating-requested',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTagRatingRequestedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    let titleString = 'UserTagRatingRequested_NotificationTitleFormat';

    if (this.params.tagRatingType === InternalTagRatingTypes.self) {
      titleString = this.params.alreadyCompleted
        ? 'UserTagRatingRequested_Self_CompleteNotificationTitleFormat'
        : 'UserTagRatingRequested_Self_NotificationTitleFormat';
    } else if (this.params.alreadyCompleted) {
      // TODO: Update the name in the DB to use standard 'AlreadyCompleted' segment instead
      titleString = 'UserTagRatingRequested_Complete_NotificationTitleFormat';
    }

    let ratingType = {
      [InternalTagRatingTypes.manager]: this.itemService.translate.instant(
        'dgTagRating_ManagerAssessment'
      ),
      [InternalTagRatingTypes.peer]: this.itemService.translate.instant(
        'dgTagRating_PeerRating'
      ),
    }[this.params.tagRatingType];

    return this.translateHtml(titleString, {
      nameHtml: this.userFullNameTemplate,
      tag: this.params.tagName,
      ratingType,
    });
  }
}
