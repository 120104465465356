<div class="grid center-item" data-dgprop-location="Skill" *ngIf="!loading">
  <div
    class="rel grid__col-sm-7 grid__col--bleed center-item guts-p-h-1 m-guts-p-h-0"
    [ngClass]="{ 'guts-p-t-5': !isMobile }"
  >
    <dgx-tag-card-resource
      *ngIf="recommendation"
      [tag]="tag | camelCase"
      [recommendation]="recommendation | camelCase"
    >
    </dgx-tag-card-resource>
    <dgx-tag-card-resource *ngIf="!recommendation" [tag]="tag | camelCase">
    </dgx-tag-card-resource>
  </div>
</div>
