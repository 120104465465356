import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DfDgatFieldTypeDirective } from '../dgat-field-type/dgat-field-type.directive';

/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
@Component({
  selector: 'df-form-toggle-switch',
  template: `<df-toggle-switch
    [id]="id + '-wrapper'"
    [fieldId]="id"
    [name]="to.name"
    [required]="to.required"
    [formControl]="control"
    [formlyAttributes]="field"
    [ariaControls]="to.ariaControlleeId"
    [attr.data-dgat]="to.dgatId"
    [dfAutofocus]="to.shouldAutofocus"
    aria-describedby="{{ to.ariaDescribedbyIds }}"
  ></df-toggle-switch>`,
  // See: control-value.directive.ts, which hijacks `aria-describedby` as an @Input
  // for the value of the public property, ariaDescribedby.
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class DfFormToggleSwitchComponent extends DfDgatFieldTypeDirective {
  public get control() {
    return this.formControl as FormControl;
  }
}
