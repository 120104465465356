import { OutcomesFacadeBaseV2 } from '@app/user-content/user-outcome-v2/services/outcomes-facade.base';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, lastValueFrom, map } from 'rxjs';
import { AwardMappingToAPI, AwardModel } from '../../award.model';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { AuthService } from '@app/shared/services/auth.service';
import { readFirst } from '@dg/shared-rxjs';
import { UploaderService } from '@app/uploader/uploader.service';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { UserOutcomeImageUploadAdapter } from '@app/user-content/services/adapters/user-outcome-image-upload.adapter';
import { FormGroup } from '@angular/forms';
import { AwardMapperService } from '../award-mapper.service';
import { AwardService } from '../award.service';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { AwardTrackerService } from '../award-tracker.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable()
export class AwardGlobalAddFacade extends OutcomesFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<AwardModel>(undefined);

  constructor(
    public userOutcomeService: UserOutcomeService,
    public outcomesService: OutcomesService,
    public authService: AuthService,
    public uploaderService: UploaderService,
    public awardMapperService: AwardMapperService,
    public awardService: AwardService,
    public outcomeNotificationService: OutcomeNotificationService,
    public awardTrackerService: AwardTrackerService,
    public tipService: TipService,
    public globalAddTrackingService: GlobalAddTrackingService
  ) {
    super(outcomesService, tipService);
  }

  protected get viewModel(): AwardModel {
    return this.viewModel$.value as AwardModel;
  }

  protected set viewModel(viewModel: AwardModel) {
    this.viewModel$.next(viewModel);
  }

  public get snapshot(): AwardModel {
    return readFirst(this.viewModel$);
  }

  public async onNext(url: string): Promise<void> {}

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      const formData = form.value;
      this.updateViewWithFormData(formData);
      const apiParameters = this.awardMapperService.toApiParameters(
        this.viewModel as AwardModel
      );
      this.submissionStatus$.next(SubmissionStatus.Submitting);
      if (this.viewModel.isEditing) {
        await this.awardService.updateAward(apiParameters);
      } else {
        await this.awardService.addAward(apiParameters);
      }
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
      this.performSuccessSideEffects();
      this.globalAddTrackingService.trackGlobalAddSaved(
        'Award',
        this.viewModel.skills,
        this.viewModel.mediumConfidenceInferredSkills,
        this.viewModel.highConfidenceInferredSkills
      );
    } catch (error) {
      this.performFailureSideEffects();
      throw new Error('Error in AwardFacade', error);
    }
    return;
  }

  protected performSuccessSideEffects() {
    const apiParameters = this.awardMapperService.toApiParameters(
      this.viewModel
    );

    if (!this.viewModel.isEditing) {
      this.outcomeNotificationService.notifyOutcomeCreated(
        this.viewModel.title
      );
      this.awardTrackerService.trackAwardAdded(apiParameters);
    } else {
      this.outcomeNotificationService.notifyOutcomeUpdated(
        this.viewModel.title
      );
      this.awardTrackerService.trackAwardUpdated(apiParameters);
    }

    this.userOutcomeService.notifyOutcomeModified('award');
  }

  protected performFailureSideEffects() {
    this.outcomeNotificationService.notifyOutcomeCreateFailed();
  }

  protected updateViewWithFormData(formData: AwardModel) {
    this.viewModel = {
      ...this.viewModel,
      ...formData,
    };
  }

  public initializeViewModel(): void {
    super.initializeViewModel();
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
    ]).pipe(map(([s]) => s === SubmissionStatus.Submitting));

    this.viewModel = {
      ...this.viewModel,
      title: '',
      issuer: '',
      issuerUrl: '',
      imageUrl: '',
      startDate: null,
      skills: [],
      userOutcomeId: null,
      contentTypeId: 'Award',
      contentType: '',
      loadInferredSkills: this.loadInferredSkills.bind(this),
      uploadAdapter: new UserOutcomeImageUploadAdapter(this.uploaderService),
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },
      inferredSkills$: this.inferredSkills$,
      shouldSpinSubmitButton$: shouldSpinSubmitButton$,
    };
  }

  public async initializeEdit(userOutcomeId: number): Promise<void> {
    this.viewModel.isEditing = true;
    const request$ = this.userOutcomeService.getUserOutcome(
      this.authService.authUser.viewerProfile.userProfileKey,
      userOutcomeId
    );

    const award = await lastValueFrom(request$);

    this.loadInferredSkills(award.title, award.source);
    const updatedView = this.awardMapperService.toViewModel(
      award as AwardMappingToAPI
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
    };
    return;
  }

  public loadInferredSkills(title: string, description: string): void {
    this.inferredSkillsTrigger$.next({ title, description });
  }

  protected onContentFileChange(file: File) {
    this.viewModel = {
      ...this.viewModel,
      file,
    };
    this.viewModel.isSubmitButtonDisabled = true;
    this.submissionStatus$.next(SubmissionStatus.Pending);
  }

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {
    this.onFormControlUpdate(formGroup, fieldName, response);

    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      imageUrl: response,
    };
    this.submissionStatus$.next(SubmissionStatus.None);
  }

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
    this.submissionStatus$.next(SubmissionStatus.None);
  }
}
