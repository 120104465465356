import { PathwayLiteModel } from '../pathway.model';
import { Inject, Injectable } from '@angular/core';
import {
  InputIdentifier,
  InputNotification,
} from '@app/inputs/inputs-api.model';
import { InputActions } from '@app/inputs/inputs.enums';
import { Pathway } from '../pathway.model';
import {
  PathwayDetailsModel,
  PathwayLesson,
  PathwaySection,
  PathwayStep,
} from '@app/pathways/pathway-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TrackerService } from '@app/shared/services/tracker.service';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { PathwayWithUserPermissions } from '../rsm/pathway-api.model';

export interface GetPathwayWithPermissionsParams {
  pathId?: number;
  obfuscatedId?: string;
  code?: string;
  useResourceImages?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class PathwayLearnerService {
  public exclusionList: InputIdentifier[] = [];

  constructor(
    private translate: TranslateService,
    private http: NgxHttpClient,
    private trackerService: TrackerService
  ) {}

  /**
   * Used to retrieve both the a full pathway object and user permissions.
   */
  public getPathwayWithPermissions(
    params: GetPathwayWithPermissionsParams
  ): Observable<PathwayWithUserPermissions> {
    const options = { params, cache: true };
    const url = '/pathways/getpathwaywithuserpermissions';
    const request$ = this.http.get<PathwayWithUserPermissions>(url, options);

    return request$;
  }

  /**
   * Used to retrieve the a full pathway object including sections, lessons, etc.
   *
   * @param pathwayId Id of pathway to retrieve
   */
  public getPathway(pathwayId: number): Observable<PathwayDetailsModel> {
    return this.http
      .get<PathwayDetailsModel>('/pathways/getpathway', {
        params: {
          pathId: pathwayId,
        },
      })
      .pipe(
        catchError((error: Error) => {
          return throwError(
            new DgError(
              this.translate.instant('Pathways_PathwayDetailAccessError'),
              error
            )
          );
        })
      );
  }

  public displayCompletedSteps(
    displayCompleted: boolean,
    totalSteps: number,
    completedSteps?: number
  ) {
    if (!displayCompleted && totalSteps === 1) {
      return this.translate.instant('Pathways_ItemCountSingular');
    }
    const stepsText = displayCompleted
      ? completedSteps + '/' + totalSteps
      : totalSteps;
    return this.translate.instant('Pathways_ItemCountFormat', {
      count: stepsText,
    });
  }

  // Provide aria-label to describe/voice section title numbering and progress(when present) due to styled section numbering & progress circle
  public getSectionTitleForAriaLabel(
    sectionNumber: number,
    sectionTitle: string,
    completionProgress: string = ''
  ) {
    return `${sectionNumber}. ${sectionTitle}. ${completionProgress}`;
  }

  public updateStep(input: InputNotification, pathway: PathwayDetailsModel) {
    if (input.action) {
      for (const section of pathway.levels) {
        for (const lesson of section.lessons) {
          for (const step of lesson.steps) {
            if (step.referenceId === input.inputId) {
              // Used to maintain complete/uncomplete state on resource cards
              step.reference.userInputId = input.userInputId;
              if (step.isRequired) {
                switch (input.action) {
                  case InputActions.completed:
                    return this.incrementStepTotal(pathway, section, lesson);
                  case InputActions.uncompleted:
                    return this.decrementStepTotal(pathway, section, lesson);
                }
              }
            }
          }
        }
      }
    }
    return pathway;
  }

  public incrementStepTotal(
    pathway: PathwayDetailsModel,
    section: PathwaySection,
    lesson: PathwayLesson
  ) {
    // Update Pathway Completed & Progress
    pathway.completedSteps++;
    pathway.progress = Math.floor(
      (pathway.completedSteps / (pathway.totalSteps - pathway.optionalSteps)) *
        100
    );

    pathway.complete = pathway.progress === 100 ? true : false;

    // Section completed & progress
    section.completedSteps++;
    section.progress =
      (section.completedSteps / (section.totalSteps - section.optionalSteps)) *
      100;
    section.complete = section.progress === 100 ? true : false;

    // Lesson completed & progress
    lesson.completedSteps++;
    lesson.progress =
      (lesson.completedSteps / (lesson.totalSteps - lesson.optionalSteps)) *
      100;
    lesson.complete = lesson.progress === 100 ? true : false;

    return pathway;
  }

  public decrementStepTotal(
    pathway: PathwayDetailsModel,
    section: PathwaySection,
    lesson: PathwayLesson
  ) {
    // Update Pathway Completed & Progress
    pathway.completedSteps--;
    pathway.progress = Math.floor(
      (pathway.completedSteps / pathway.totalSteps) * 100
    );
    pathway.complete = pathway.progress === 100 ? true : false;

    // Section completed & progress
    section.completedSteps--;
    section.progress = (section.completedSteps / section.totalSteps) * 100;
    section.complete = section.progress === 100 ? true : false;

    // subsection completed & progress
    lesson.completedSteps--;
    lesson.progress = (lesson.completedSteps / lesson.totalSteps) * 100;
    lesson.complete = lesson.progress === 100 ? true : false;

    return pathway;
  }

  /**
   * Determines if the section is valid to show. Includes a check for showing empty sections that have the LD flag
   *
   * @param totalLessonsWithItems Total lessons with items of the section
   * @param totalSteps Total steps of the section
   */
  public isValidSection(
    totalLessonsWithItems: number,
    totalSteps: number
  ): boolean {
    return totalLessonsWithItems > 0;
  }

  /**
   * Determines if the lesson is valid to show. Includes a check for showing empty sections that have the LD flag
   *
   * @param lesson Lesson of the pathway
   */
  public isValidLesson(lesson: PathwayLesson) {
    const totalSteps = lesson.steps?.length;
    const isValid = totalSteps > 0;

    return isValid;
  }

  /**
   * Valid lessons check to prevent empty subsection title & descriptions
   *
   * @param lessons - The section lessons
   */
  public isValidLessons(lessons: PathwayLesson[]): boolean {
    if (lessons.length > 1 || (lessons.length === 1 && lessons[0].title)) {
      return true;
    }
    return false;
  }

  /**
   * Returns the desired Lesson object from within the provided source.
   * @param source - either a pathway or a section to get the lesson from
   * @param lessonId  - id of the lesson to look for
   */
  public getLessonById(source: any, lessonId: PathwayLesson['id']) {
    let foundLesson: PathwayLesson;
    if (source.levels) {
      const pathway: PathwayDetailsModel = source;
      for (const section of pathway.levels) {
        const lessonMatch = section.lessons.filter(
          (lesson) => lesson.id === lessonId
        );
        if (lessonMatch.length > 0) {
          foundLesson = lessonMatch[0];
          break;
        }
      }
    } else if (source.lessons) {
      const section: PathwaySection = source;
      const lessonMatch = section.lessons.filter(
        (lesson) => lesson.id === lessonId
      );
      if (lessonMatch.length > 0) {
        foundLesson = lessonMatch[0];
      }
    } else {
      console.error('source is neither a pathway nor a section');
    }
    return foundLesson;
  }

  /**
   * Used to retrieve the a full pathway object including sections, lessons, etc. as well as the user permissions needed for pathways.
   * @param params object containing either a pathId, obfuscatedId, or code (bofa military)
   */
  public getPathwayWithUserPermissions(
    params: GetPathwayWithPermissionsParams
  ) {
    params = {
      ...params,
      useResourceImages: true,
    };
    return this.http
      .get<PathwayWithUserPermissions>(
        '/pathways/getpathwaywithuserpermissions',
        {
          params,
        }
      )
      .pipe(
        map((response) => {
          let pathway: Pathway = response.pathway;
          pathway = this.preProcessPathway(pathway);
          pathway = this.setPathIds(pathway);
          response.pathway = pathway;
          return response;
        }),
        catchError((e) => {
          return throwError(
            new DgError(
              this.translate.instant('Pathways_PathwayDetailAccessError'),
              e
            )
          );
        })
      );
  }

  public getViewedItems(pathwayId) {
    const params = {
      pathId: pathwayId,
      take: 10,
      skip: 0,
    };

    return this.http.get<PathwayLiteModel>(
      '/inputs/getViewedInputsForPathway',
      {
        params,
      }
    );
  }

  private preProcessPathway(pathway: Pathway) {
    for (const section of pathway.levels as PathwaySection[]) {
      section.totalLessons = section.lessons.length;
      for (const lesson of section.lessons as PathwayLesson[]) {
        for (let i = lesson.steps.length - 1; i >= 0; i--) {
          const step: PathwayStep = lesson.steps[i];
          if (!step.complete && !pathway.nextStepToComplete) {
            pathway.nextStepToComplete = step.id;
            section.autoExpand = true;
            lesson.autoExpand = true;
          }
          if (!!step.reference) {
            step.edited = 0; // has been edited 0 times (helps trackby)
            step.reference = this.normalizeStepReference(step, pathway.id);
            // TODO: pass exclusion list to the Add to Pathway modal>search
            this.exclusionList.push({
              inputId: step.reference.inputId,
              inputType: step.reference.inputType,
            });
          } else {
            // clean up broken steps
            console.error('Missing Reference object on Step.', step);
            this.trackerService.trackEventData({
              action: 'Missing Reference',
              category: 'Pathway Step',
              label: '',
              properties: step,
            });
            lesson.steps.splice(i, 1);
          }
        }
        lesson.visibleSteps = [];
      }
    }
    pathway.privacyLevel = this.convertServerPrivacy(
      pathway.privacyLevel as any
    );

    return pathway;
  }

  private convertServerPrivacy(serverPrivacyString: string) {
    let clientPrivacyNumber;
    const pathwayPrivacyMap = {
      Author: Visibility.private,
      ProfileVisible: Visibility.profile,
      Group: Visibility.groups,
      Public: Visibility.public,
    };
    clientPrivacyNumber = pathwayPrivacyMap[serverPrivacyString];
    return clientPrivacyNumber;
  }

  private normalizeStepReference(step: PathwayStep, pathwayId: number) {
    step.reference.pathwayStepDetails = {
      pathwayId,
      isOptional: !step.isRequired,
      node: step.node,
    };
    step.reference.hasUserDescription =
      !!step.description && step.description !== step.reference.summary;
    step.reference.title =
      typeof step.title !== 'undefined' && step.title !== null
        ? step.title
        : step.reference.title;
    step.reference.summary =
      typeof step.description !== 'undefined' && step.description !== null
        ? step.description
        : step.reference.summary;
    step.reference.imageUrl =
      typeof step.imageUrl !== 'undefined' && step.imageUrl !== null
        ? step.imageUrl
        : step.reference.imageUrl;
    return step.reference;
  }

  // Note: anything updated here should probably also be updated in the Degreed Button (ebb) version of PathwaySvc
  private setPathIds(pathway: Pathway) {
    for (const section of pathway.levels as PathwaySection[]) {
      section.pathId = pathway.id;
      for (const lesson of section.lessons as PathwayLesson[]) {
        lesson.pathId = pathway.id;
      }
    }
    return pathway;
  }
}
