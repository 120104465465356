import { InputType } from '@app/shared/models/core-api.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { InputsService } from '@app/inputs/services/inputs.service';
import { HostType } from '../models';

/**
 * Content Hosting File Loader Service
 */

@Injectable()
export class CHFileLoaderService {
  constructor(private inputsService: InputsService) {}

  loadFile({ inputId, inputType }: { inputId: number; inputType: InputType }) {
    return this.inputsService
      .getHostedInputUrls({
        inputId: inputId,
        inputType: inputType,
      })
      .pipe(
        map((response) => {
          return response[0];
        })
      );
  }

  canLoadFile(hostType: HostType) {
    return hostType === HostType.DegreedMedia;
  }
}
