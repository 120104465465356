import { SendFeedbackParams } from '@app/feedback/services/feedback-modal.service';
import { DF_SLIDE_FADE_IN_LEFT, NotificationType } from '@lib/fresco';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FocusStackService } from '@app/shared/services/focus-stack.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  FeedbackCategory,
  FeedbackModalService,
} from './../services/feedback-modal.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { FileUploadSuccessResult } from '@app/uploader/file-uploader/file-uploader.component';
import { FeedbackService } from '../services/feedback.service';

export interface FeedbackOption extends FeedbackCategory {
  i18n?: string;
  warningTranslation?: string;
}

@Component({
  selector: 'dgx-report-problem-modal',
  templateUrl: './report-problem-modal.component.html',
  animations: [DF_SLIDE_FADE_IN_LEFT],
})
export class ReportProblemModalComponent implements OnInit {
  @Input() public itemType: string;
  @Input() public itemId: number;
  @Input() public contextType: string;
  @Input() public contextId: number;
  @Input() public feedbackType: number;
  @Input() public sectionType: string;

  public readonly NotificationType = NotificationType;

  public canSubmit: boolean;
  public currentSubtypes: FeedbackOption[];
  public element: string;
  public explanationTouched: boolean;
  public feedbackTypes: FeedbackOption[];
  public imageUrl: string;
  public message: string;
  public okayToNotify: boolean = true;
  public selectedFeedbackSubtype: string = '';
  public selectedFeedbackType: string;
  public showIssueSkeleton: boolean = true;
  public subissueLabel: string;
  public isSubmitting: boolean = false;
  private readonly KC_LINK = this.webEnvironmentService.getZendeskUrl('');

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private feedbackModalService: FeedbackModalService,
    private cdr: ChangeDetectorRef,
    private focusStackService: FocusStackService,
    private webEnvironmentService: WebEnvironmentService,
    private feedbackService: FeedbackService
  ) {}

  // returns a warning message if exists.  If both the selected type and selected subtype has a warning, we'll use the warning on the subtype, since it's more specific
  public get warningMessage() {
    const currType = this.feedbackTypes.find(
      (type) => type.name === this.selectedFeedbackType
    );

    const currSubType = this.currentSubtypes?.find(
      (type) => type.name === this.selectedFeedbackSubtype
    );

    if (currSubType?.userWarningMessage) {
      return this.translate.instant(currSubType.userWarningMessage);
    } else if (currType?.userWarningMessage) {
      return this.translate.instant(currType.userWarningMessage);
    } else if (
      this.isOtherSelected() &&
      !this.feedbackService.sendAllRapTicketsToClient()
    ) {
      return this.translate.instant('problemModalContent_OtherInstructions', {
        knowledgeCenterLinkStart:
          '<a class="link" target="_blank" href="' + this.KC_LINK + '">',
        knowledgeCenterLinkEnd: '</a>',
      });
    } else {
      return null;
    }
  }

  public get isExplanationRequired() {
    let currSubtype: null | FeedbackOption = null;

    const currType = this.feedbackTypes.find(
      (type) => type.name === this.selectedFeedbackType
    );

    if (this.currentSubtypes?.length > 0) {
      currSubtype = this.currentSubtypes.find(
        (type) => type.name === this.selectedFeedbackSubtype
      );
    }

    return currType?.descriptionRequired || currSubtype?.descriptionRequired;
  }

  public get hasExplanationError() {
    return (
      this.isExplanationRequired && !this.message && this.explanationTouched
    );
  }

  public ngOnInit(): void {
    this.subissueLabel = this.translate.instant(
      'problemModalContent_Subreason'
    );
    this.feedbackModalService
      .getFeedbackCategories(
        this.contextType,
        this.contextId,
        this.itemType,
        this.itemId
      )
      .subscribe((response: FeedbackCategory[]) => {
        this.feedbackTypes = response.map((item) => {
          return {
            ...item,
            subcategories: item.subcategories
              ? item.subcategories.map((subitem) => {
                  let translated = '';
                  if (subitem.name) {
                    translated = this.translate.instant(subitem.name);
                  }
                  return {
                    ...subitem,
                    i18n: translated,
                  };
                })
              : null,
          };
        });
        this.showIssueSkeleton = false;
      });
  }

  public changeSelectedType(): void {
    /*
     * dgSelect doesn't provide a resetter for the selected label
     *  so we cheat and force a change.
     */
    this.currentSubtypes = null;
    this.explanationTouched = false;
    this.cdr.markForCheck();
    this.cdr.detectChanges();

    const category = this.feedbackTypes.find((type) => {
      return type.name === this.selectedFeedbackType;
    });

    this.currentSubtypes = category.subcategories
      ? category.subcategories.filter((item) => item.name)
      : [];
    this.selectedFeedbackSubtype = '';
    this.canSubmit = this.getCurrentSubmitState();
  }

  public changeSelectedSubtype($event): void {
    this.selectedFeedbackSubtype = $event?.name || $event?.$event.name;
    this.canSubmit = this.getCurrentSubmitState();
  }

  public changeTextbox(): void {
    this.explanationTouched = true;
    this.canSubmit = this.getCurrentSubmitState();
  }

  public handleSubmit(): void {
    const onDone = () => {
      this.isSubmitting = false;

      this.activeModal.close();
      this.focusStackService.pop();
    };
    const feedbackParams: SendFeedbackParams = {
      category: this.selectedFeedbackType,
      contextId: this.contextId,
      contextType: this.contextType,
      element: this.element,
      feedbackType: this.feedbackType,
      imageUrl: this.imageUrl,
      itemType: this.itemType,
      itemId: this.itemId,
      message: this.message,
      okayToNotify: this.okayToNotify,
      routeDestination: this.getCurrentRouteDestination(),
      subcategory: this.selectedFeedbackSubtype,
      sectionType: this.sectionType,
    };
    const indexOfSelectedFeedbackType = this.feedbackTypes.findIndex(
      (feedbackOption: FeedbackOption) =>
        feedbackOption.name === this.selectedFeedbackType
    );
    const indexOfSelectedFeedbackSubtype = this.currentSubtypes.findIndex(
      (feedbackOption: FeedbackOption) =>
        feedbackOption.name === this.selectedFeedbackSubtype
    );
    // These values represent the numerical option chosen (1-4) in the category/subcategory list
    // Numerical values are being used for Amplitude tracking purposes
    const numOfCategorySelected = indexOfSelectedFeedbackType + 1;
    const numOfSubcategorySelected = indexOfSelectedFeedbackSubtype + 1;

    this.isSubmitting = true;
    this.feedbackModalService
      .sendFeedback(
        feedbackParams,
        numOfCategorySelected,
        numOfSubcategorySelected
      )
      .subscribe({
        complete: onDone,
        error: onDone,
      });
  }

  public onUploadSuccess(event: FileUploadSuccessResult): void {
    this.imageUrl = event.url;
  }

  public getCurrentSubmitState() {
    if (!this.selectedFeedbackType) {
      return false;
    }
    if (this.currentSubtypes?.length > 0 && !this.selectedFeedbackSubtype) {
      return false;
    }
    if (this.isExplanationRequired && !this.message) {
      return false;
    }
    if (this.isOtherSelected() && this.shouldSuppressOther()) {
      return false;
    }
    return true;
  }

  public getCurrentRouteDestination() {
    const category = this.feedbackTypes.find((type) => {
      return type.name === this.selectedFeedbackType;
    });

    if (this.selectedFeedbackSubtype) {
      const subcategory = category.subcategories.find((type) => {
        return type.name === this.selectedFeedbackSubtype;
      });

      return subcategory.routeDestination;
    } else {
      return category.routeDestination;
    }
  }

  public isOtherSelected() {
    const otherType = `problemModalContent_Other`;
    return (
      this.selectedFeedbackType === otherType ||
      this.selectedFeedbackSubtype === otherType
    );
  }

  public showAdditionalDetails() {
    if (this.shouldSuppressOther() && this.isOtherSelected()) {
      return false;
    }

    return true;
  }

  private shouldSuppressOther() {
    return !this.feedbackService.sendAllRapTicketsToClient();
  }
}
