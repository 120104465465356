import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CopyLinkComponent } from './copy-link.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DfButtonsModule } from '@lib/fresco';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbTooltipModule, DfButtonsModule],
  providers: [],
  declarations: [CopyLinkComponent],
  exports: [CopyLinkComponent],
})
export class DgxCopyLinkModule {}
