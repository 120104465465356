import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { TagCheckpointRatingsModalComponent } from '@app/tags/components/tag-checkpoint-ratings-modal/tag-checkpoint-ratings-modal.component';
import { TagRatingButtonBaseComponent } from '@app/tags/components/tag-rating-button/tag-rating-button-base.component';
import { CompletedTagRatingsPipe } from '@app/tags/pipes/completed-tag-ratings.pipe';
import { IncompleteTagRatingsPipe } from '@app/tags/pipes/incomplete-tag-ratings.pipe';
import { TagRatingEndorsersPipe } from '@app/tags/pipes/tag-rating-endorsers.pipe';
import { TagRatingTypePipe } from '@app/tags/pipes/tag-rating-type.pipe';
import { TagRatingsForTypePipe } from '@app/tags/pipes/tag-ratings-for-type.pipe';
import { TagActionOptionsService } from '@app/tags/services/tag-action-options.service';
import { TagRatingTrackerService } from '@app/tags/services/tag-rating-tracker.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';

/**
 * Only show one for all checkpoints (cred spark assessments).
 * This will open a modal to view all percentage scores, and other available assessments.
 * If there is only one checkpoint and it does not yet have a rating level, the user will see the empty
 * button. Otherwise the user will always see the completed even if there are no completed checkpoints
 */

@Component({
  selector: 'dgx-checkpoint-rating-button',
  styleUrls: ['../tag-rating-button-base.component.scss'],
  templateUrl: '../tag-rating-button-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckpointRatingButtonComponent extends TagRatingButtonBaseComponent {
  /** All available checkpoints. Only required when owner user is viewing */
  @Input() public allCheckpoints?: TagsApi.Checkpoint[];

  public readonly type = InternalTagRatingTypes.checkpoint;

  constructor(
    public authService: AuthService,
    public translateService: TranslateService,
    public ratingTypePipe: TagRatingTypePipe,
    public ratingsForTypePipe: TagRatingsForTypePipe,
    public completedTagRatingsPipe: CompletedTagRatingsPipe,
    public incompleteTagRatingsPipe: IncompleteTagRatingsPipe,
    public tagRatingEndorsersPipe: TagRatingEndorsersPipe,
    public tagsService: TagsService,
    public tagActionOptionsService: TagActionOptionsService,
    public tagRatingTrackerService: TagRatingTrackerService,
    public cdr: ChangeDetectorRef,
    private modalService: ModalService
  ) {
    super(
      authService,
      translateService,
      ratingTypePipe,
      ratingsForTypePipe,
      completedTagRatingsPipe,
      incompleteTagRatingsPipe,
      tagRatingEndorsersPipe,
      tagsService,
      tagActionOptionsService,
      tagRatingTrackerService,
      cdr
    );
  }

  public get ratingTypeLabel(): string {
    return this.translateService.instant('dgTagRating_Checkpoints');
  }

  public get ratingLevelLabel(): string {
    const completedCt = this.completedRatings.length;
    if (this.hasCompletedRatings) {
      if (this.ownerIsViewing) {
        return `${completedCt}/${
          this.allCheckpoints.length
        } ${this.translateService.instant('dgTagRating_Checkpoints')}`;
      } else {
        return `${completedCt} ${
          completedCt === 1
            ? this.translateService.instant('dgTagRating_Checkpoint')
            : this.translateService.instant('dgTagRating_Checkpoints')
        }`;
      }
    }
  }

  public get noRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_TakeCheckpoint');
  }

  public get completedRatingCTAlabel(): string {
    return this.translateService.instant('dgTagRating_ViewCheckpoints');
  }

  public get ratingDescriptionLabel(): string {
    return this.translateService.instant('dgTagRating_CompleteCheckpoints');
  }

  public get showCTA(): boolean {
    return this.ownerIsViewing || this.hasCompletedRatings;
  }

  public ngOnInit() {
    // non-owner users should only see completed checkpoints
    if (!this.ownerIsViewing) {
      this.allCheckpoints = this
        .completedRatings as unknown as TagsApi.Checkpoint[];
    }
  }

  public handleClick(event: Event): void {
    event.stopImmediatePropagation();
    const inputs = {
      tag: this.tag,
      checkpoints: this.allCheckpoints,
    };
    this.modalService
      .show(TagCheckpointRatingsModalComponent, {
        inputs,
      })
      .subscribe();
  }
}
