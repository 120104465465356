<div class="l_flex l_flex-middle l_flex-wrap" [ngClass]="wrapperClasses">
  <!-- search filters -->
  <dgx-filter
    *ngFor="let filter of filters; let isLast = last; trackBy: trackByFn"
    [applyOnClose]="applyOnClose"
    [class.guts-m-r-1]="!isLast"
    [disabledButtonClass]="disabledButtonClass"
    [hideCheckboxIcon]="hideCheckboxIcon"
    [filter]="filter"
    [ngClass]="filterClasses"
    [isDisabled]="isLoading || isDisabled"
    [ellipsis]="ellipsis"
    (filterChange)="applyFilter($event)"
    (trackableEvent)="trackEvent($event)"
    (trackableFilterUpdateEvent)="trackFilterUpdateEvent($event)"
    class="m-guts-m-r-0"
    data-dgat="data-filters-0c1"
  ></dgx-filter>
  <!-- clear button (shows if there is more than one filter and any filter has selected children) -->
  <div
    *ngIf="showClearFiltersButton && !isLoading"
    class="l_flex--align-right guts-m-l-1 m-guts-m-l-0"
    [ngClass]="filterClasses"
  >
    <button
      (click)="clearFilters()"
      class="link ib color-blue par--small font-medium uhover"
      data-dgat="data-filters-14d"
    >
      <div class="l_flexbar gap-full-half">
        <df-icon icon="cross"></df-icon>
        <div>{{ clearFilterLabel }}</div>
      </div>
    </button>
  </div>
</div>
