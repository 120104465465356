import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DfAccessibilityReaderService {
  private announceTimeout: Subscription;

  constructor(private translate: TranslateService) {}

  /**
   * Announces text to screen readers.
   * The screen reader will speak changes whenever the user is idle
   * @param announcement text to be read
   */
  public announcePolite(announcement: string): void {
    this.announce(announcement, 'Polite');
  }

  /**
   * Announces text to screen readers
   * The screen reader will interrupt the user to speak changes
   * @param announcement text to be read
   */
  public announceAssertive(announcement: string): void {
    this.announce(announcement, 'Assertive');
  }

  /**
   * Announces number of options to screen readers
   */
  public announceOptionCount(count: number) {
    this.announcePolite(
      this.translate.instant('Core_OptionsAvailableFormat', { count })
    );
  }

  /**
   * Announces number of search results to screen readers
   */
  public announceResultsCount(count: number) {
    this.announcePolite(
      this.translate.instant('Core_ResultsFoundFormat', { count })
    );
  }
  private announce(announcement: string, type: 'Polite' | 'Assertive') {
    const a11yLiveRegionEl = document.querySelector(`#AriaLive${type}`);
    if (this.announceTimeout) {
      this.announceTimeout.unsubscribe();
    }
    const announceEl = document.createElement('span');
    announceEl.classList.add('js-announce-text');
    announceEl.classList.add('a11y-hide-text');
    announceEl.textContent = announcement;
    a11yLiveRegionEl.appendChild(announceEl);

    this.announceTimeout = timer(1000).subscribe(
      () => (a11yLiveRegionEl.innerHTML = '')
    );
  }
}
