import { Injectable } from '@angular/core';
import { InputType } from '@app/shared/models/core-api.model';
import { TagsApi } from '@app/tags/tag-api.model';
import { TranslateService } from '@ngx-translate/core';
import { extend, isObject } from 'lodash-es';
@Injectable({
  providedIn: 'root',
})
export class ReportingContentService {
  public periods = [
    { name: this.translate.instant('Core_Month'), value: 'month' },
    { name: this.translate.instant('Core_Quarter'), value: 'quarter' },
    { name: this.translate.instant('Core_Year'), value: 'year' },
  ];

  public allInputTypes = [
    { type: '', name: this.translate.instant('ReportingContentSvc_All') },
  ];

  public reportingInputTypes = [
    {
      type: 'Article',
      name: this.translate.instant('Core_ArticlesDisplayName'),
    },
    {
      type: 'Video',
      name: this.translate.instant('Core_VideosDisplayName'),
    },
    { type: 'Book', name: this.translate.instant('Core_BooksDisplayName') },
    {
      type: 'Course',
      name: this.translate.instant('Core_CoursesDisplayName'),
    },
    {
      type: 'Event',
      name: this.translate.instant('Core_EventsDisplayName'),
    },
    {
      type: 'Episode',
      name: this.translate.instant('Core_PodcastsDisplayName'),
    },
    {
      type: 'Assessment',
      name: this.translate.instant('Core_AssessmentsDisplayName'),
    },
    {
      type: 'Task',
      name: this.translate.instant('Core_TasksDisplayName'),
    },
    {
      type: 'Post',
      name: this.translate.instant('Core_PostsDisplayName'),
    },
    { type: 'NoInput', name: 'NoInput' }, // No i18n
  ];

  private dataTypeOptions = [
    {
      name: this.translate.instant('ReportingContentSvc_TotalItemsCompleted'),
      type: 'Items',
    },
    {
      name: this.translate.instant('ReportingContentSvc_TotalContentViewed'),
      type: 'Views',
    },
    {
      name: this.translate.instant('ReportingContentSvc_TotalPointsEarned'),
      type: 'Points',
    },
  ];

  constructor(private translate: TranslateService) {}

  public getDataTypes(): any {
    const dataTypes = {};
    this.dataTypeOptions.forEach((option) => {
      const typeObj = {};
      typeObj[option.type.toLowerCase()] = option.type;
      extend(dataTypes, typeObj);
    });
    return dataTypes;
  }

  public getDataTypeOptions(
    isPersonal?: boolean,
    isManager?: boolean,
    shouldFilterPoints?: boolean
  ) {
    let types = this.dataTypeOptions;
    if (isPersonal || isManager) {
      types = Array.prototype.filter.call(types, (e) => e.type !== 'Views'); // Remove View item
    }
    if (shouldFilterPoints || isManager) {
      types = Array.prototype.filter.call(types, (e) => e.type !== 'Points'); // Remove Points item
    }
    return types;
  }

  public isInputType(inputType: InputType | InputType[]) {
    return inputType !== null && isObject(inputType);
  }

  public setTagWhenDefault(tag: TagsApi.Tag) {
    /* used */
    if (!tag) {
      return;
    } else {
      if (!tag.tagId && tag.value === '') {
        return null;
      } else {
        return tag.name;
      }
    }
  }

  public getChartName(chartId) {
    return this.translate.instant('ReportingContentSvc_ChartID' + chartId);
  }
}
