import { Component, Input } from '@angular/core';
import { PathwaySection } from '@app/pathways/rsm/pathway-api.model';
import { hasAtLeastOneSubsection } from '@app/pathways/rsm/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-section-meta-data',
  templateUrl: './pathway-section-meta-data.component.html',
  styleUrls: ['./pathway-section-meta-data.component.scss'],
})
export class PathwaySectionMetaDataComponent {
  @Input() public section: PathwaySection;
  @Input() public hideDuration = false;
  @Input() public hasFauxSubsection = false;

  public subsectionTotal: number;
  public itemTotal: number;
  public optionalSteps: number;
  public durationUnits: number;

  constructor(private translate: TranslateService) {}

  // ***************************
  // PUBLIC -------------------
  // Getters
  // ***************************

  public get subsectionTotalText(): string {
    return this.getPluralOrSingular(
      this.section.totalLessonsWithItems,
      'Pathways_SubsectionCountSingular',
      'Pathways_SubsectionCountFormat',
      (_) => this.hasAtLeastOneNonFauxSubsection()
    );
  }

  public get itemTotalText(): string {
    return this.getPluralOrSingular(
      this.section.totalSteps,
      'Pathways_ItemCountSingular',
      'Pathways_ItemCountFormat',
      (num) => !!num
    );
  }

  public get optionalText(): string {
    return this.getPluralOrSingular(
      this.section.optionalSteps,
      'Pathways_OptionalItemCountSingular',
      'Pathways_OptionalItemCountFormat',
      (num) => !!num
    );
  }

  public get durationHours(): string {
    return this.getPluralOrSingular(
      this.section.durationHours,
      'Core_1Hour',
      'Core_HoursFormat',
      (num) => !!num,
      'hours'
    );
  }

  public get durationMinutes(): string {
    return this.getPluralOrSingular(
      this.section.durationMinutes,
      'Core_1Minute',
      'Core_MinutesFormat',
      (num) => !!num,
      'minutes'
    );
  }

  // ***************************
  // PRIVATE -------------------
  // Local Utils
  // ***************************

  private getPluralOrSingular(
    num: number,
    singular: string,
    plural: string,
    test: (num: number) => boolean,
    prop = 'count'
  ): string {
    if (!test(num)) {
      return '';
    }
    return num > 1
      ? this.translate.instant(plural, {
          [prop]: num,
        })
      : this.translate.instant(singular);
  }

  private hasAtLeastOneNonFauxSubsection(): boolean {
    return hasAtLeastOneSubsection(this.section) && !this.hasFauxSubsection;
  }
}
