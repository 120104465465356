<div class="guts-m-v-2">
  <dgx-profile-input-counts-by-type
    [ownerKey]="owner.id"
  ></dgx-profile-input-counts-by-type>
</div>
<div class="grid">
  <section class="grid__col-12">
    <div class="guts-p-full-2 box-shadow rounded--large">
      <ng-container
        *ngComponentOutlet="
          getComponent('dgxLearningChart');
          inputs: getChartInputs('dgxLearningChart')
        "
      ></ng-container>
    </div>
  </section>
</div>
<div class="grid" *ngIf="popularDataList.Categories?.length">
  <section class="grid__col-12">
    <div class="guts-p-full-2 box-shadow rounded--large">
      <ng-container
        *ngComponentOutlet="
          getComponent('dgxPopularDataChart');
          inputs: getChartInputs('dgxPopularDataChart')
        "
      ></ng-container>
    </div>
  </section>
</div>
<div *ngIf="ownerIsViewing">
  <dgx-profile-share-activity></dgx-profile-share-activity>
</div>
<div class="footer-glasses"></div>
