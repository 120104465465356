<dgx-kpi-list
  [kpis]="kpis"
  [kpiClass]="'grid__col-sm-4'"
  [kpisLoading]="kpisLoading"
  [kpiPlaceholder]="[0, 0, 0]"
></dgx-kpi-list>
<dgx-personal-skills-table
  [isLoading]="tableLoading"
  [hasJobRole]="hasJobRole"
  [skills]="skills"
  (rateSkill)="rateSkill($event)"
></dgx-personal-skills-table>
