import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-pathway-add-content-between',
  templateUrl: './pathway-add-content-between.component.html',
  styleUrls: ['./pathway-add-content-between.component.scss'],
})
export class PathwayAddContentBetweenComponent {
  @Output() public addContentClicked = new EventEmitter<any>();

  public i18n = this.translate.instant(['Core_AddContent']);

  constructor(private translate: TranslateService) {}
  // Emit addContent button has been clicked
  public addContent($event: Event | KeyboardEvent) {
    this.addContentClicked.emit($event.target);
  }
}
