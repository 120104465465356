import { AuthService } from '@app/shared/services/auth.service';
import { Injectable } from '@angular/core';
import { LDFlagsService, WindowLayoutService } from '@dg/shared-services';
import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';

export const enum ProductName {
  LearnerHome = 'learner-home',
  LearningExperience = 'learning-experience',
  ExtendedEnterprise = 'extended-enterprise',
  CareerMobility = 'career-mobility', // not in use yet
  SkillAnalytics = 'skill-analytics',
  Onboarding = 'onboarding',
}

interface DisplayAsMobileParams {
  windowWidth: number;
  navLinksWidth: number;
  logosWidth: number;
  utilitiesWidth?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProductHeaderService {
  constructor(
    private authService: AuthService,
    private windowLayoutService: WindowLayoutService,
    private featureFlag: LDFlagsService,
    private analyticsGuard: AnalyticsGuard
  ) {}

  public get multipleProductsAvailable() {
    const authUser = this.authService.authUser;
    const hasAdvanceAnalytics = this.analyticsGuard.isAuthorized;
    return (
      authUser?.hasChannel ||
      this.authService.userCanViewReporting ||
      this.authService.userCanManageLearnerOrg ||
      this.authService.userCanManageSkillAnalytics ||
      this.authService.userCanManageSkillInventory ||
      this.featureFlag.showLearnInProductSwitcher ||
      hasAdvanceAnalytics
    );
  }

  public getProductImageSrcset(
    productName: ProductName,
    useLightText: boolean,
    isClientProvider: boolean
  ) {
    const lightTextPostfix = useLightText ? '-light' : '';
    const productPrefix = isClientProvider ? 'home' : productName;
    return {
      orig: `/content/img/branding/${productPrefix}${lightTextPostfix}@1x.png`,
      retina: `/content/img/branding/${productPrefix}${lightTextPostfix}@2x.png`,
    };
  }

  public displayAsMobile({
    navLinksWidth,
    logosWidth,
    windowWidth,
    utilitiesWidth = 0,
  }: DisplayAsMobileParams) {
    const linksAndLogosPercentageTaken =
      (navLinksWidth + logosWidth + utilitiesWidth) / windowWidth;

    // if the logos, navigation, and utilities sections combined will take more than 95% of the window width,
    // display as a dropdown if this is too wide it may overlap with global-search.component
    // or if the window is what we consider 'tablet'-sized we will force the dropdown
    return (
      linksAndLogosPercentageTaken >= 0.95 ||
      this.windowLayoutService.dgBreakpointSize.BrotherBear >= windowWidth
    );
  }
}
