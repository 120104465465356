import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';
import { ResourceIdBuilder } from '../../resource-id-builder';

/** Represents an Liked Comment notification */
@Component({
  selector: 'dgx-like-comment',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LikeCommentComponent extends NotificationItemComponentBase {
  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    const params = this.params;
    // compose a valid resource ID permutation based on the notification parameters
    const contentResource = (params.action === 'Post'
      ? resourceIdBuilder
          .withSegment('likeGroupActivity_')
          .withOptionalGroup()
          .withSegment('Post')
          .withSingleOrPlural()
      : resourceIdBuilder
          .withSegment('likeComment_')
          .withSingleOrPlural()
          .withOptionalGroup()
    ).asFormat();

    // "{{nameHtml}} [and {{number}} others] liked your post|commment [in {{groupNameHtml}}]."

    return this.translateHtml(contentResource, {
      nameHtml: this.userFullNameTemplate,
      groupNameHtml: this.groupNameTemplate,
      number: this.params.personCount,
    });
  }
}
