import { ChannelBundleHelperService } from '@app/channel/services/channel-bundle-helper.service';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import { Observable, forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { map, mapTo } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

// types
import { ChannelTenantOrg } from './channel.model';
import {
  ChannelBundle,
  ChannelBundleAssociation,
  ChannelBundleDetails,
  ChannelBundleGroup,
  ChannelBundleOrg,
  ChannelBundleResource,
  ChannelBundleResourceDetails,
  ChannelBundleResourceUnformatted,
  ChannelBundleTarget,
} from './channel-bundle.model';
import {
  AddOrgsToBundleParams,
  AddResourcesToBundleParams,
  GetBundleInputsParams,
  GetBundleTargetsParams,
  IsOrganizationNativeProviderResponse,
} from './channel-bundle-api.model';

@Injectable({
  providedIn: 'root',
})
export class ChannelBundleApiService {
  private errorKey = 'Channel_ErrorSavingChanges';

  constructor(
    private http: NgxHttpClient,
    private translate: TranslateService,
    private channelBundleHelperService: ChannelBundleHelperService
  ) {}

  /**
   * Used on Catalog > Bundles > Bundle > Visibility > Associate Groups
   *
   * @param groupIds Ids of groups to be associated
   * @param containerId ID of the container/bundle to associate the groups
   * @returns Observable only to notify of completion of task
   */
  public addBundleToGroups(
    groupIds: number[],
    containerId: number
  ): Observable<unknown> {
    return this.http
      .post<void>('/containers/ShareChannelContainerWithGroups', {
        groupIds,
        containerId,
      })
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Used on Catalog > Bundles > Bundle > Visibility > Associate Orgs
   *
   * @param opts Formatted params containing the bundle id, channel org id,
   *   and ids of the tenants to be associated
   * @returns Observable only to notify of completion of task
   */
  public addOrgsToBundle(opts: AddOrgsToBundleParams): Observable<unknown> {
    return this.http
      .post<void>('/containers/ShareContainerWithOrgs', opts)
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Adds a piece of content, plan, or pathway to a bundle to share
   *
   * Used on Catalog > Bundles > Bundle > Content > Add Content
   *
   * @param opts
   * @returns Observable only to notify of completion of task
   */
  public addResourcesToBundle(
    opts: AddResourcesToBundleParams
  ): Observable<unknown> {
    opts.resources = this.channelBundleHelperService.formatResources(
      opts.resources as ChannelBundleResourceUnformatted[]
    );

    return this.http
      .post<void>('/containers/AddResources', opts)
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Associates a piece of content, plan, or pathway with a bundle or bundles
   *
   * Used in:
   *  Catalog > Bundles > Bundle > Content / Plan / Pathway
   *  Catalog > Piece of Content > Action Options > Add to Bundle
   *
   * TODO: should have a backend update to hit an endpoint with
   * a set of bundles instead of individually adding them all like this
   *
   * @param resources Pre-formatted resource list to associate with a bundle
   * @param bundles List of bundles to associate resources to
   * @returns Observable of a list of the BundleModels with that were changed
   */
  public addResourcesToBundles(
    resources: ChannelBundleResource[],
    bundles: ChannelBundle[]
  ): Observable<ChannelBundle[]> {
    const batch = bundles.map((bundle) => {
      return this.addResourcesToBundle({
        organizationId: bundle.organizationId,
        containerId: bundle.containerId,
        resources: resources,
      });
    });

    return forkJoin(batch).pipe(
      mapTo(bundles),
      catchAndSurfaceError(this.translate.instant(this.errorKey))
    );
  }

  /**
   * Creates a new bundle in the channel organization
   * Passes empty ContainerId with orgId and title to backend to create bundle.
   *
   * @param bundle {BundleModel} without the ContainerId
   * @returns Observable only to notify of completion of task
   */
  public createBundle(bundle: Partial<ChannelBundle>): Observable<unknown> {
    bundle.title = bundle.title?.trim();
    return this.http
      .post<void>('/containers/CreateContainer', bundle)
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Gets the list of all orgs or groups associated with the bundle
   *
   * Found on Content > Bundles > Bundle > Visibility
   *
   * @param ownerOrganizationId Channel Org Id
   * @param containerId Bundle Id
   * @returns {ChannelBundleAssociation[]} List of orgs and groups associated with the bundle id
   */
  public getAllBundleAssociations(
    ownerOrganizationId: number,
    containerId: number
  ): Observable<ChannelBundleAssociation[]> {
    return this.http
      .get<ChannelBundleAssociation[]>('/containers/GetAllContainerShares', {
        params: {
          ownerOrganizationId,
          containerId,
        },
      })
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Gets the list of bundles for a channel org (not bundles associated with a tenant)
   *
   * Used on Catalog > Bundles
   *
   * @param organizationId Channel Org Id
   * @returns {ChannelBundle[]} Observable of a list of bundle models created under the org
   */
  public getBundlesForOrg(
    organizationId: number,
    options = null
  ): Observable<ChannelBundle[]> {
    // Remove the pipe from this chain when sort and search have been moved to BE
    return this.http
      .get<ChannelBundle[]>('/containers/GetOrgContainers', {
        params: { organizationId, ...options },
      })
      .pipe(
        map((results) => {
          return this.frontendSortSearch(options, results);
        }),
        catchAndSurfaceError(this.translate.instant(this.errorKey))
      );
  }

  /**
   * Gets all the information to view a bundle
   *
   * Used on Catalog > Bundles > Individual Bundle View
   *
   * @param containerId Bundle id
   * @returns {ChannelBundle} Return model is essentially the same as BundleModel,
   * but formatted with camelCase props instead of TitleCase
   */
  public getBundleInfo(containerId: number): Observable<ChannelBundle> {
    return this.http
      .get<ChannelBundle>('/containers/GetContainerDetail', {
        params: { containerId },
      })
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Gets the content items associated with a bundle
   *
   * Used on Catalog > Bundles > Bundle > Content
   *
   * @param bundleParams
   * @returns A list of resources associated with the bundle
   */
  public getBundleInputs(
    params: GetBundleInputsParams
  ): Observable<ChannelBundleDetails> {
    return this.http
      .get<ChannelBundleDetails>('/containers/GetContainerInputs', {
        params,
      })
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Fetches a list of groups not yet associated with the bundle
   *
   * Used on Catalog > Bundles > Bundle > Visibility > Add Org or Group Modal
   *
   * @param organizationId The channel org id
   * @param containerId The id of the bundle
   * @returns A list of groups that have not yet been associated with the bundle
   */
  public getBundleGroups(
    organizationId: number,
    containerId: number
  ): Observable<ChannelBundleGroup[]> {
    return this.http
      .get<ChannelBundleGroup[]>('/containers/GetAvailableGroupsForContainer', {
        params: { organizationId, containerId },
      })
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Fetches a list of tenant orgs not yet associated with the bundle
   *
   * Used on Catalog > Bundles > Bundle > Visibility > Add Org or Group Modal
   *
   * @param organizationId The channel org id
   * @param containerId The id of the bundle
   * @returns A list of tenant orgs that have not yet been associated with the bundle
   */
  public getBundleOrgs(
    organizationId: number,
    containerId: number
  ): Observable<ChannelTenantOrg[]> {
    return this.http
      .get<ChannelTenantOrg[]>('/containers/GetAvailableOrgsForContainer', {
        params: { organizationId, containerId },
      })
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Gets the list of plans associated with the bundle
   *
   * Used on Catalog > Bundles > Bundle > Plans
   *
   * TODO: API Should support what targetsSvc.ts / getOrgTargets supports
   *
   * @param opts Should pass along filter options as well, currently just using bundleId
   * @returns A list of associated plans
   */
  public getBundleTargets({
    bundleId,
    containerId,
  }: GetBundleTargetsParams): Observable<ChannelBundleTarget[]> {
    return (
      this.http
        .get<ChannelBundleTarget[]>('/targets/GetContainerTargets', {
          params: { containerId: containerId || bundleId },
        })
        // returns data on response.results
        // format shared with orgPlans
        .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)))
    );
  }

  /**
   * Used for certain clients to show 'public' bundles
   *
   * TODO: needs more information
   *
   * Used on Catalog > Bundles ??
   */
  public getIsOrganizationNativeProvider(
    orgId: number
  ): Observable<IsOrganizationNativeProviderResponse> {
    return this.http
      .get<IsOrganizationNativeProviderResponse>(
        '/providers/IsOrganizationNativeProvider',
        {
          params: {
            orgId,
          },
        }
      )
      .pipe(catchAndSurfaceError(this.translate.instant(this.errorKey)));
  }

  /**
   * Removes bundles from the channel org
   *
   * Used on Catalog > Bundles > Delete bundles
   *
   * @param bundles List of bundles
   * @returns Observable of an array of container ids that were removed
   */
  public removeBundles(bundles: ChannelBundle[]): Observable<number[]> {
    const bundleTransformList = bundles.map(
      ({ containerId, organizationId }) => {
        return { containerId, organizationId };
      }
    );
    return this.http
      .post<unknown>('/containers/DeleteContainers', bundleTransformList)
      .pipe(
        // server doesn't return anything, manual return so we can remove
        // the bundles from the page without a page refresh
        mapTo(bundles.map((bundle) => bundle.containerId)),
        catchAndSurfaceError(this.translate.instant(this.errorKey))
      );
  }

  /**
   * Removes a group association from a bundle
   *
   * Used on Catalog > Bundles > Bundle > Visibility > Remove a group
   *
   * @param containerId Bundle id
   * @param groupIds Groups to remove from the bundle
   * @returns Observable of an array of group ids that were removed
   */
  public removeBundleFromGroups(
    containerId: number,
    groupIds: number[]
  ): Observable<number[]> {
    return this.http
      .post<unknown>('/containers/RemoveChannelContainerFromGroups', {
        containerId,
        groupIds,
      })
      .pipe(
        mapTo(groupIds),
        catchAndSurfaceError(this.translate.instant(this.errorKey))
      );
  }

  /**
   * Removes a group association from a bundle
   *
   * Used on Catalog > Bundles > Bundle > Visibility > Remove a group
   *
   * @param containerId Bundle id
   * @param groupIds Groups to remove from the bundle
   * @returns Observable of an array of group ids that were removed
   */
  public removeBundleFromOrgs(
    organizationId: number,
    containerId: number,
    organizationIds: number[]
  ): Observable<number[]> {
    return this.http
      .post('/containers/RemoveContainerFromOrgs', {
        containerId,
        organizationId,
        organizationIds,
      })
      .pipe(
        mapTo(organizationIds),
        catchAndSurfaceError(this.translate.instant(this.errorKey))
      );
  }

  /**
   * Removes content, plans, or pathways that were associated with the bundle
   *
   * Used on Catalog > Bundles > Bundle > Content / Plans / Pathways > Remove item
   *
   * @param bundle
   * @returns Observable of an array of resource details for resources that were removed
   */
  public removeResourcesFromBundle(
    bundle: ChannelBundle
  ): Observable<ChannelBundleResourceDetails[]> {
    const { organizationId, containerId } = bundle;
    let resources: ChannelBundleResource[];
    resources = this.channelBundleHelperService.formatResources(
      bundle.resources as unknown as ChannelBundleResourceUnformatted[]
    );
    return this.http
      .post<unknown>('/containers/RemoveResources', {
        organizationId,
        containerId,
        resources,
      })
      .pipe(
        mapTo(resources),
        catchAndSurfaceError(this.translate.instant(this.errorKey))
      );
  }

  /**
   * Updates the properties of the bundle (at the moment just the title)
   *
   * Used on Catalog > Bundles > Action Options > Edit Details
   *   or Catalog > Bundles > Bundle > Settings Gear > Edit Details
   *
   * @param bundle
   * @returns Observable of a reference to the updated bundle
   */
  public updateBundle(bundle: ChannelBundle): Observable<ChannelBundle> {
    bundle.title = bundle.title.trim();
    return this.http
      .post('/containers/UpdateContainer', bundle)
      .pipe(
        mapTo(bundle),
        catchAndSurfaceError(this.translate.instant(this.errorKey))
      );
  }
  /*
   * Temporary FE sort and filter
   *    Doing this here to preserve filters/sorts when adding or deleting
   *    Will be replaced with BE sort and filter, when that
   *    happens this tap can completely go
   */
  public frontendSortSearch(options, results) {
    if (options?.sortOptions) {
      // sort results
      results = results.sort((a, b) => {
        const sortDir = options.sortOptions.isDescending ? 1 : -1;
        if (
          a[options.sortOptions.sortColumnName] <
          b[options.sortOptions.sortColumnName]
        ) {
          return sortDir * 1;
        } else if (
          a[options.sortOptions.sortColumnName] >
          b[options.sortOptions.sortColumnName]
        ) {
          return sortDir * -1;
        }
        return 0;
      });
    }
    if (options?.term) {
      // filter results
      results = results.filter((r) =>
        r.title.toLowerCase().includes(options.term.toLowerCase())
      );
    }
    return results;
  }
}
