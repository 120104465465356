import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/** Converts a decimal point value into formatted string */
@Pipe({ name: 'dgxPoints' })
export class PointsPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: number) {
    if (value === undefined) {
      return '';
    } else {
      if (value < 0.05) {
        value = 0.05;
      }
      return this.decimalPipe.transform(value, `1.0-2`);
    }
  }
}
