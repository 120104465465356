import { InternalTagRatingTypes } from '@app/tags/tags';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { TagsApi } from '@app/tags/tag-api.model';
import * as ButtonComponents from '@app/tags/components/tag-rating-button/buttons';
import { InternalRatingTypesByRank } from './tag-rating-button.service';

/**
 * This component returns the appropriate rating button component for a given rating type.
 *
 * If our class has available rating types defined...
 *
 * @example
 *
 * this.vm.availableRatingTypes = ['Evaluation', 'Manager', 'Peer'];
 *
 * ...
 *
 * <dgx-tag-rating-button
 *  *ngFor="let type of vm.availableRatingTypes; trackBy: trackByFn"
 *  [type]="type"
 *  [tag]="tag"
 *  [tagRatingDetails]="vm.tagRatingDetails"
 * ></dgx-tag-rating-button>
 */
@Component({
  selector: 'dgx-tag-rating-button',
  styleUrls: ['./tag-rating-button-base.component.scss'],
  template: '<!-- template set by child component -->',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingButtonComponent {
  @Input() public type: InternalTagRatingTypes | string;
  @Input() public tag: TagsApi.Tag;
  @Input() public ownerIsViewing?: boolean;
  @Input() public tagRatingDetails?: TagsApi.UserTagRatingDetails[];
  @Input() public availableRatingTypes?: TagsApi.RatingType[];
  @Input() public trackingLocation?: string;

  /** Checkpoints only */
  @Input() public allCheckpoints?: TagsApi.Checkpoint[];
  /** Evaluation only */
  @Input() public isEvaluable?: boolean;

  // NOTE: this must be kept in sync with the bindings ^^
  private readonly bindings: string[] = [
    'type',
    'tag',
    'ownerIsViewing',
    'tagRatingDetails',
    'availableRatingTypes',
    'trackingLocation',
    'allCheckpoints',
    'isEvaluable',
  ];

  private componentRef: ComponentRef<Component>;

  constructor(
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  public ngOnChanges(): void {
    this.setBindings();
  }

  public ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.getButtonComponentContext(this.type)
    );

    this.componentRef = this.viewContainerRef.createComponent(factory);

    this.renderer.appendChild(
      this.viewContainerRef.element.nativeElement,
      this.componentRef.location.nativeElement
    );

    this.setBindings();
  }

  /** Set/update bindings and trigger change detection */
  private setBindings(): void {
    if (this.componentRef) {
      this.bindings.forEach((binding) => {
        this.componentRef.instance[binding] = this[binding];
      });
      this.componentRef.injector.get(ChangeDetectorRef).detectChanges();
    }
  }

  /** Get the appropriate rating button component for the given type */
  private getButtonComponentContext(
    type: InternalTagRatingTypes | string
  ): Type<Component> {
    const isInternal = InternalRatingTypesByRank.includes(type);
    const ratingType = isInternal ? type : 'External';
    return ButtonComponents[ratingType + 'RatingButtonComponent'];
  }
}
