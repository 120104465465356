import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceIdBuilder } from '@app/notifications/resource-id-builder';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Custom Report Outcome notification */
@Component({
  selector: 'dgx-custom-report-outcome',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReportOutcomeComponent extends NotificationItemComponentBase {
  protected get usePeriodicRefresh() {
    return true; // use periodic refresh of content since we have an elapsed time field
  }

  protected buildTemplate(resourceIdBuilder: ResourceIdBuilder) {
    // NOTE: shares resource with DownloadableReportOutcome
    const contentResource = resourceIdBuilder
      .withSegment('downloadReportOutcome_')
      .withStatus()
      .asFormat();

    const reportTitle = this.params.reportTitle;

    const elapsedTime = this.itemService.prettyTimeElapsedPipe
      .transform(this.params.requestDate, 'days')
      .toLowerCase();

    return this.translateHtml(contentResource, {
      name: this.html`<span class="font-semibold">${reportTitle}</span>`,
      timeAgo: elapsedTime,
    });
  }
}
