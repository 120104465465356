import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/account-api.model';
import { UserExperienceService } from '@app/inputs/services/user-experience.service';
import { ViewCollaboratorsModalComponent } from '@app/opportunities/components/modals/view-collaborators-modal/view-collaborators-modal.component';
import {
  EditVisibilityModalService,
  VisibilityData,
} from '@app/orgs/components/edit-visibility-modal/edit-visibility-modal.service';
import { RecommendedUser } from '@app/recommendations/components/share-tabs-modal/share-tabs-modal.component';
import { RecommendationsModalService } from '@app/recommendations/services/recommendations-modal.service';
import { SimpleModalComponent } from '@app/shared/components/modal/simple-modal/simple-modal.component';
import { VisibilityOption } from '@app/shared/components/visibility/visibility.model';
import { Visibility } from '@app/shared/components/visibility/visibility.enum';
import { TargetSuggestionType } from '@app/shared/models/core-api.model';
import { DgError } from '@app/shared/models/dg-error';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { NotifierService } from '@app/shared/services/notifier.service';
import { TrackerService } from '@app/shared/services/tracker.service';
import { TrackingProperties } from '@app/shared/services/tracking.model';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { catchAndSurfaceError } from '@app/shared/utils/dg-error-helpers';
import {
  catchAndSurfaceModalFocusError,
  catchAndSurfaceNonModalError,
} from '@app/shared/utils/modal-helpers';
import { getTagLevel, isMatchingTag } from '@app/shared/utils/tag-helpers';
import { TagsApi } from '@app/tags/tag-api.model';
import { InputModalDispatcherService } from '@app/user-content/user-input/services/input-modal-dispatcher.service';
import { UserSearchModalComponent } from '@app/user/components/user-search-modal/user-search-modal.component';
import { UserSearchItem } from '@app/user/user-api.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, defer, forkJoin, iif, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AddCollaboratorsModalComponent } from '../components/modals/add-collaborators-modal/add-collaborators-modal.component';
import { ArchiveCandidatesModalComponent } from '../components/modals/archive-candidates-modal/archive-candidates-modal.component';
import { CloseOpportunitiesModalComponent } from '../components/modals/close-opportunities-modal/close-opportunities-modal.component';
import { DeleteOpportunitiesModalComponent } from '../components/modals/delete-opportunities-modal/delete-opportunities-modal.component';
import { OpportunityCloseModalComponent } from '../components/modals/opportunity-close-modal/opportunity-close-modal.component';
import { OpportunityModalComponent } from '../components/modals/opportunity-modal/opportunity-modal.component';
import { SelectCandidateModalComponent } from '../components/modals/select-candidate-modal/select-candidate-modal.component';
import { UserRateSkillsModalComponent } from '../components/modals/user-rate-skills-modal/user-rate-skills-modal.component';
import { UserSkillsModalComponent } from '../components/modals/user-skills-modal/user-skills-modal.component';
import {
  ArchiveCandidatesParams,
  Candidate,
  CandidateAlgorithmData,
  CloseAndNotify,
  CloseOpportunityRequest,
  Opportunity,
  OpportunityApplicationStage,
  Skill,
  StageUpdate,
} from '../opportunities-api.model';
import {
  OpportunityApplicationStageEnum,
  OpportunityApplicationStatus,
  OpportunityStatus,
} from '../opportunities.enums';
import { getEndDate, isOpportunityQueued } from '../utils';
import { OpportunitiesService } from './opportunities.service';
import { OpportunityApiService } from './opportunity-api.service';
import { OpportunityFlagsService } from './opportunity-flags.service';

// interfaces
export interface OpportunityModalOptions {
  activeTabId?: 1 | 2 | 3;
  fetchExtraData?: boolean;
  isCloning?: boolean;
  isEditing?: boolean;
  opportunity?: Opportunity;
}

export interface OpportunityCloneModalOptions {
  fetchExtraData?: boolean;
  opportunity: Opportunity;
}

@Injectable({
  providedIn: 'root',
})
export class OpportunityModalsService {
  public i18n = this.translateService.instant([
    // OLD text for the update applicant confirmation modal -- leave
    // in place until we're no longer hiding the change behind a feature flag.
    // (The new text is in the custom modal.)
    'Opportunities_Candidates_Modal_UpdateApplicant_Title',
    'Opportunities_Candidates_Modal_UpdateApplicant_Description',
    'Opportunities_Candidates_Modal_Award',
    'Opportunities_Error_AddExperience',
    'Opportunities_Error_GetApplicantData',
    'Opportunities_InterestNotConfirmedError',
    'OrgManage_Opportunities_Create',
    'OrgManage_Opportunities_AddCollaborators',
    'OrgManage_Opportunities_BulkChangeVisibility',
    'OrgManage_Opportunities_Close',
    'OrgManage_Opportunities_ClosePrompt_Description',
    'OrgManage_Opportunities_ClosePrompt_SendNotification',
    'OrgManage_Opportunities_Delete',
    'OrgManage_Opportunities_Edit',
    'OrgManage_Opportunities_EditSkills',
    'OrgManage_Opportunities_Error_Add',
    'OrgManage_Opportunities_Error_Close',
    'OrgManage_Opportunities_Error_Delete',
    'OrgManage_Opportunities_Error_Edit',
    'OrgManage_Opportunities_Error_EditCollaborators',
    'OrgManage_Opportunities_Error_EditVisibility',
    'OrgManage_Opportunities_Error_Share',
    'OrgManage_Opportunities_BulkDeleteError',
    'OrgManage_Opportunities_OptIn',
    'OrgManage_Opportunities_OptIn_Description',
    'OrgManage_Opportunities_OptIn_Header',
    'OrgManage_Opportunities_Success_AddCollaboratorToOpportunity',
    'OrgManage_Opportunities_Success_EditVisibility',
    'OrgManage_Opportunities_Upload',
    'OrgManage_Opportunities_UploadDescription',
    'OrgManage_Opportunities_UploadError',
    'OrgManage_Opportunities_UploadSuccess',
    'OrgManage_Opportunities_VisibleCollaborators',
    'OrgManage_Opportunities_VisibleGroups',
    'OrgManage_Opportunities_VisibleOrganization',
  ]);
  protected readonly baseUrl: string = '/opportunities';
  protected useRejectMessageEmail =
    this.opportunityFlagsService.useRejectMessageEmail;
  private isUserOptIn: boolean;
  private userAddRatingsPromptData: {
    hasBeenPrompted: boolean;
    opportunityId: number;
    userKey: number;
  };

  constructor(
    private authService: AuthService,
    private http: NgxHttpClient,
    private inputModalDispatcherService: InputModalDispatcherService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private opportunityApiService: OpportunityApiService,
    private opportunityFlagsService: OpportunityFlagsService,
    private recommendationsModalService: RecommendationsModalService,
    private translateService: TranslateService,
    private userExperienceService: UserExperienceService,
    private webEnvironmentService: WebEnvironmentService,
    private editVisibilityModalService: EditVisibilityModalService,
    private trackerService: TrackerService,
    private opportunitiesService: OpportunitiesService
  ) {
    // Get user OptIn status
    this.getUserOptInStatus().subscribe();
  }

  /**
   * Apply for an opportunity request
   *
   * @param opportunity The Opportunity
   */
  public apply(
    opportunity: Opportunity
  ): Observable<{ isUserOptIn: boolean; opportunity: Opportunity }> {
    // this endpoint returns no content
    let observable: Observable<any> = this.http.post<void>(
      `${this.baseUrl}/apply`,
      opportunity
    );
    // if the opportunity is currently in the user's save-for-later list,
    // forkJoin our observable with an API call to remove it from the queue
    if (isOpportunityQueued(opportunity)) {
      observable = forkJoin([
        observable,
        this.opportunityApiService.removeFromQueue({ opportunity }),
      ]);
    }
    // either way, return the observable, mapping its response to what
    // our components expect
    return (
      observable
        // send back the original opportunity with the new application status,
        // isQueued, and userQueueId values.
        .pipe(
          mapTo({
            isUserOptIn: true,
            opportunity: {
              ...opportunity,
              applicationStatus: OpportunityApplicationStatus.Interested,
              isInterested: true,
              isQueued: false,
              userQueueId: null,
            },
          })
        )
    );
  }

  /**
   * Apply for an opportunity
   *
   * @param opportunity - The opportunity being applied to.
   */
  public applyForOpportunity(
    opportunity: Opportunity
  ): Observable<{ isUserOptIn: boolean; opportunity: Opportunity }> {
    // Get the latest authUser. This will not always result in an API call,
    // so it's fine to use here even though this recurses.
    const authUser = this.authService.authUser;
    // Define unmatchedUserSkills for later filling in.
    let unmatchedUserSkills: Skill[] = [];
    // Apply to the opportunity
    return iif(
      () => !!this.isUserOptIn,
      // Double check that the users doesn't have matched skills without targets
      // for an opportunity that has at least one target set.
      defer(() =>
        iif(
          () => {
            // If the user has already seen this prompt, and either added or not
            // added skills, no need to do anything else
            if (
              this.hasUserBeenPromptedToAddSkills(
                authUser.viewerProfile.userProfileKey,
                opportunity.opportunityId
              )
            ) {
              return false;
            }
            // Check to see if the current *user* has any of the skills on the
            // opportunity, which the user has not yet rated.
            unmatchedUserSkills = opportunity.matchedSkills.filter(
              (skill) =>
                // Check against all tags
                !!opportunity.tags
                  // Find any that our user matched...
                  .find((tag) => isMatchingTag(tag, skill)) &&
                // ...that our user hasn't rated yet
                !getTagLevel(skill)
            );
            // If we've got at least one, then we should show the rate modal
            return !!unmatchedUserSkills.length;
          },
          defer(() =>
            this.showUserRateSkillsModal({
              opportunity,
              user: authUser,
              skills: unmatchedUserSkills,
            }).pipe(
              // *Regardless* of whether or not the user has updated their skills, we
              // go ahead and apply... but we do want to update the matchedSkills
              // array, maybe. :|a
              tap(() => {
                // Update this value now that the user has seen our modal
                // TODO: Maybe track this on the opportunity itself? So that it will persist?
                this.userAddRatingsPromptData = {
                  hasBeenPrompted: true,
                  opportunityId: opportunity.opportunityId,
                  userKey: authUser.viewerProfile.userProfileKey,
                };
              }),
              mergeMap((updatedMatchedSkills: Skill[]) =>
                // Merge the updated matched skills into our opportunity's skills,
                // replacing the ones with matching names. Then return to the top
                // of the chain.
                this.applyForOpportunity({
                  ...opportunity,
                  matchedSkills: opportunity.matchedSkills.map(
                    (matchedSkill) =>
                      updatedMatchedSkills.find((updatedSkill) =>
                        isMatchingTag(matchedSkill, updatedSkill)
                      ) ?? matchedSkill
                  ),
                })
              )
            )
          ),
          // Finally, apply if neither of the above conditions was met.
          defer(() => this.apply(opportunity))
        )
      ),
      // Prompt users that aren't opted in to opt in
      defer(() =>
        this.showOptInModal({
          isUserOptIn: this.isUserOptIn,
          opportunity,
        }).pipe(
          // Return to the top of the chain!
          mergeMap(() => this.applyForOpportunity(opportunity))
        )
      )
    );
  }

  // Get user opt in status for opportunities and store locally
  public getUserOptInStatus(): Observable<boolean> {
    return this.http
      .get(`${this.baseUrl}/getuseroptinstatus`)
      .pipe(tap((isUserOptIn: boolean) => (this.isUserOptIn = isUserOptIn)));
  }

  public showAddCollaboratorsModal(
    opportunities: Opportunity[]
  ): Observable<any> {
    const opportunityIds = opportunities.map((o) => o.opportunityId);

    const inputs = {
      headerText: this.i18n.OrgManage_Opportunities_AddCollaborators,
      opportunityIds,
    };

    return this.modalService
      .show(AddCollaboratorsModalComponent, {
        inputs,
      })
      .pipe(
        tap(
          (collaboratorsData: {
            opportunityIds: number[];
            userProfileKeys: number[];
          }) => {
            let successMsg = '';
            const { opportunityIds: oIds, userProfileKeys: uIds } =
              collaboratorsData;

            // sadly we have to handle all the plural situations
            if (oIds.length === 1 && uIds.length === 1) {
              successMsg =
                this.i18n
                  .OrgManage_Opportunities_Success_AddCollaboratorToOpportunity;
            } else if (oIds.length === 1 && uIds.length > 1) {
              successMsg = this.translateService.instant(
                'OrgManage_Opportunities_Success_AddCollaboratorPluralToOpportunity',
                {
                  count: uIds.length,
                }
              );
            } else if (oIds.length > 1 && uIds.length === 1) {
              successMsg = this.translateService.instant(
                'OrgManage_Opportunities_Success_AddCollaboratorToOpportunityPlural',
                {
                  count: oIds.length,
                }
              );
            } else {
              successMsg = this.translateService.instant(
                'OrgManage_Opportunities_Success_AddCollaboratorPluralToOpportunityPlural',
                {
                  collaboratorCount: uIds.length,
                  opportunityCount: oIds.length,
                }
              );
            }

            this.notifierService.showSuccess(successMsg);
          }
        ),
        catchError((error: Error) =>
          throwError(
            new DgError(
              this.i18n.OrgManage_Opportunities_Error_EditCollaborators,
              error
            )
          )
        )
      );
  }

  /**
   * Use modal service to show a modal for adding and editing opportunities.
   *
   * @param isEditing - Editing or adding.
   * @param opportunity - (Optional) The opportunity to edit, if editing.
   *
   * @return {Observable<Opportunity>} An observable with the `Opportunity`;
   */
  public showAddEditModal(
    {
      activeTabId = 1,
      fetchExtraData = false,
      isCloning = false,
      isEditing = false,
      opportunity,
    }: OpportunityModalOptions,
    errorOnDismiss = false
  ): Observable<Opportunity> {
    const headerText = isEditing
      ? this.i18n.OrgManage_Opportunities_Edit
      : this.i18n.OrgManage_Opportunities_Create;

    const visibility = isEditing // this allows us to maintain the existing visibility setting
      ? this.visibilityOptions().filter(
          (v) => !v.disabled || opportunity.privacyId === v.item.visibility
        )
      : this.visibilityOptions().filter((v) => !v.disabled);

    const inputs = {
      activeTabId,
      fetchExtraData,
      headerText,
      isCloning,
      isEditing,
      opportunity,
      visibility,
    };

    return this.modalService
      .show<Opportunity>(OpportunityModalComponent, {
        inputs,
        windowClass: 'xlg-modal',
        errorOnDismiss,
        keyboardEscClose: false,
      })
      .pipe(
        tap((updatedOpportunity) => {
          this.notifierService.showSuccess(
            this.translateService.instant(
              isEditing
                ? 'OrgManage_Opportunities_Success_Update'
                : 'OrgManage_Opportunities_Success_Add',
              {
                title: updatedOpportunity.title,
              }
            )
          );
        }),
        catchAndSurfaceModalFocusError(
          isEditing
            ? this.i18n.OrgManage_Opportunities_Error_Edit
            : this.i18n.OrgManage_Opportunities_Error_Add
        )
      );
  }

  /**
   * Open a modal to add the opportunity to the user's experience.
   */
  public showAddExperienceModal({
    authUser,
    opportunity,
    sourceTarget,
    trackingAction,
  }: {
    authUser: AuthUser;
    opportunity: Opportunity;
    sourceTarget?: HTMLElement;
    trackingAction?: string;
  }): Observable<{ masteryPoints: number }> {
    return this.inputModalDispatcherService
      .addOpportunity({
        sourceTarget,
        trackingAction,
        initialModel: {
          ...opportunity,
          // manually type tags (TODO: This is a mess, we need to fix it --
          // we should know for sure whether our tag objects are Tag[] or TagDetails[].
          tags: (opportunity.tags
            ? opportunity.tags
            : opportunity.skills) as TagsApi.Tag[],
          extent: {
            startDate: opportunity.opportunityStartDate,
            endDate:
              opportunity.opportunityEndDate ??
              // Attempt to coerce endDate out of duration values
              getEndDate(
                opportunity.opportunityStartDate,
                opportunity.durationUnits,
                opportunity.durationUnitType
              ),
          },
          organizationName:
            // Attempt to get the opportunity's provider name, in case of third-party opp
            opportunity.provider?.name ??
            opportunity.providerName ??
            // if neither are defined, fall back to default org name
            authUser.defaultOrgInfo?.name ??
            // if that's also undefined somehow, fall back to Degreed
            'Degreed',
        },
      })
      .pipe(
        // We need to associate the Opportunity with the Experience here manually.
        switchMap((response) =>
          this.opportunityApiService.setOpportunityExperience(
            // opportunityId isn't a standard property on the default inputModalDispatcher
            // method, but we don't need it, either: we can just grab it from the original
            // opportunity object.
            opportunity.opportunityId,
            response
          )
        ),
        catchAndSurfaceError<{ masteryPoints: number }>(
          this.i18n.Opportunities_Error_AddExperience
        )
      );
  }

  public showBulkCloseOpportunitiesModal(
    opportunities: Opportunity[]
  ): Observable<any> {
    const externalOppCount = opportunities.filter((o) => o.url).length;
    const allowedCount = opportunities.length - externalOppCount;
    const allowedOpportunityIds = opportunities
      .filter((o) => !o.url)
      .map((o) => o.opportunityId);

    // Different header, depends on whether have external opportunities
    const headerText =
      externalOppCount > 0
        ? this.translateService.instant(
            'OrgManage_Opportunities_BulkCloseHeader',
            {
              allowedCount,
              totalCount: opportunities.length,
            }
          )
        : this.translateService.instant(
            'OrgManage_Opportunities_BulkCloseAllHeader',
            {
              totalCount: opportunities.length,
            }
          );

    // Only need secondary body text when having external opportunities
    const secondaryBodyText =
      externalOppCount > 0 &&
      this.translateService.instant(
        'OrgManage_Opportunities_BulkCloseWarningExternal',
        { notAllowedCount: externalOppCount }
      );

    const inputs = {
      headerText,
      secondaryBodyText,
    };

    return this.modalService
      .show(CloseOpportunitiesModalComponent, {
        inputs,
      })
      .pipe(
        switchMap((notifyUnSelectedCandidates) =>
          this.http.put(`${this.baseUrl}/bulkcloseopportunities`, {
            OpportunityIds: allowedOpportunityIds,
            notifyUnSelectedCandidates,
          })
        ),
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant(
              'OrgManage_Opportunities_BulkClosedSuccess',
              {
                allowedCount,
              }
            )
          );
          if (externalOppCount > 0) {
            this.notifierService.showError(
              this.translateService.instant(
                'OrgManage_Opportunities_BulkCloseExternalError',
                {
                  notAllowedCount: externalOppCount,
                }
              )
            );
          }
        }),
        catchError((error: Error) =>
          throwError(
            new DgError(
              this.translateService.instant(
                'OrgManage_Opportunities_BulkCloseError'
              ),
              error
            )
          )
        )
      );
  }

  public showDeleteOpportunitiesModal(
    opportunities: Opportunity[]
  ): Observable<number[]> {
    const deletableOpportunitiesCount = opportunities.filter(
      ({ url }) => !url
    ).length;
    return this.modalService
      .show<number[]>(DeleteOpportunitiesModalComponent, {
        inputs: {
          opportunities,
        },
      })
      .pipe(
        // TODO: Update with https://degreedjira.atlassian.net/browse/PD-87505
        switchMap((deletedOpportunityIds) =>
          this.http
            .put(`${this.baseUrl}/bulkdeleteopportunities`, {
              opportunityIds: deletedOpportunityIds,
            })
            // force this to return our deleted opportunity ID array
            .pipe(map(() => deletedOpportunityIds))
        ),
        tap((deletedOpportunityIds) => {
          if (deletableOpportunitiesCount === 1) {
            return this.notifierService.showSuccess(
              this.translateService.instant(
                'OrgManage_Opportunities_Success_Delete',
                {
                  title: opportunities.find(
                    ({ opportunityId }) =>
                      deletedOpportunityIds[0] === opportunityId
                  ).title,
                }
              )
            );
          }
          this.notifierService.showSuccess(
            this.translateService.instant(
              'OrgManage_Opportunities_BulkDeleteSuccess',
              {
                allowedCount: deletedOpportunityIds.length,
              }
            )
          );
        }),
        catchAndSurfaceError<number[]>(
          deletableOpportunitiesCount === 1
            ? this.i18n.OrgManage_Opportunities_Error_Delete
            : this.i18n.OrgManage_Opportunities_BulkDeleteError
        )
      );
  }

  /**
   * Use modal service to show a modal for editing visibility of opportunities.
   *
   * @param opportunities - List of Opportunity objects
   */
  public showBulkEditVisibilityModal(
    opportunities: Opportunity[]
  ): Observable<any> {
    const headerText =
      opportunities.length < 2
        ? this.i18n.OrgManage_Opportunities_BulkChangeVisibility
        : this.translateService.instant(
            'OrgManage_Opportunities_BulkChangeVisibilityPlural',
            { count: opportunities.length }
          );

    const visibility = this.visibilityOptions().filter((v) => !v.disabled);
    const inputs = {
      headerText,
      visibility,
    };

    return this.editVisibilityModalService.openModal(inputs).pipe(
      switchMap((visibilityData) => {
        const opportunityIds = opportunities.map((o) => o.opportunityId);
        return this.editBulkVisibility({
          opportunityIds,
          ...visibilityData,
        });
      }),
      tap(() => {
        const successMsg =
          opportunities.length < 2
            ? this.i18n.OrgManage_Opportunities_Success_EditVisibility
            : this.translateService.instant(
                'OrgManage_Opportunities_Success_EditVisibilityPlural',
                { count: opportunities.length }
              );

        this.notifierService.showSuccess(successMsg);
      }),
      catchError((error: Error) =>
        throwError(
          new DgError(
            this.i18n.OrgManage_Opportunities_Error_EditVisibility,
            error
          )
        )
      )
    );
  }

  /**
   * Use modal service to show a modal for cloning opportunities.
   *
   * @param opportunity - Opportunity object
   */
  public showCloneModal(
    { fetchExtraData = false, opportunity }: OpportunityCloneModalOptions,
    errorOnDismiss = false
  ): Observable<Opportunity> {
    return this.showAddEditModal(
      {
        fetchExtraData,
        isCloning: true,
        opportunity,
      },
      errorOnDismiss
    );
  }

  /**
   * Use modal service to show a modal for closing opportunities.
   *
   * @param opportunity - Opportunity object
   */
  public showCloseModal(opportunity: Opportunity): Observable<Opportunity> {
    return this.modalService
      .show<CloseOpportunityRequest>(OpportunityCloseModalComponent, {
        inputs: {
          bodyText: this.i18n.OrgManage_Opportunities_ClosePrompt_Description,
          bodySendNotificationText:
            this.i18n.OrgManage_Opportunities_ClosePrompt_SendNotification,
          canCancel: true,
          headerText: this.translateService.instant(
            'OrgManage_Opportunities_ClosePrompt_Header',
            { opportunityTitle: opportunity.title }
          ),
          opportunity,
          submitButtonText: this.i18n.OrgManage_Opportunities_Close,
        },
      })
      .pipe(
        switchMap((closeRequest) => this.closeOpportunity(closeRequest)),
        map((opportunity) => {
          this.notifierService.showSuccess(
            this.translateService.instant(
              'OrgManage_Opportunities_Success_Close',
              {
                opportunityTitle: opportunity.title,
              }
            )
          );
          return opportunity;
        }),
        catchError((error: Error) =>
          throwError(
            new DgError(this.i18n.OrgManage_Opportunities_Error_Close, error)
          )
        )
      );
  }

  /**
   * Show the edit collaborators modal.
   *
   * @param opportunity The opportunity to update.
   * @param canEdit Show view or edit collaborators modal depending on if the user can edit
   */
  public showEditCollaboratorsModal(
    opportunity: Opportunity,
    canEdit = false
  ): Observable<UserSearchItem[]> {
    const { opportunityId, authors: collaborators } = opportunity;

    if (!canEdit) {
      return this.modalService.show(ViewCollaboratorsModalComponent, {
        inputs: {
          users: collaborators,
        },
      });
    }

    return this.modalService
      .show<UserSearchItem[]>(UserSearchModalComponent, {
        inputs: {
          users: collaborators,
        },
      })
      .pipe(
        switchMap((selectedCollaborators) => {
          const authorProfileKeys = selectedCollaborators.map(
            (u) => u.userProfileKey
          );

          return this.opportunitiesService
            .editCollaborators(opportunityId, authorProfileKeys)
            .pipe(mapTo(selectedCollaborators));
        }),
        tap(() => {
          this.notifierService.showSuccess(
            this.translateService.instant(
              'OrgManage_Opportunities_Success_Update',
              {
                title: opportunity.title,
              }
            )
          );
        })
      );
  }

  /**
   * Use modal service to show a modal for opt in to an opportunity.
   *
   * @param opportunity - Opportunity object
   */
  public showOptInModal({
    isUserOptIn,
    opportunity,
  }): Observable<{ isUserOptIn: boolean; opportunity: Opportunity }> {
    // If user is already opted in, return an opportunity without opening the modal
    if (isUserOptIn) {
      return of({ isUserOptIn: true, opportunity });
    }
    // input options for the modal
    const inputs = {
      bodyImg: this.getBlobUrl('/content/img/check-item.svg'),
      bodyText: this.i18n.OrgManage_Opportunities_OptIn_Description,
      canCancel: true,
      headerText: this.i18n.OrgManage_Opportunities_OptIn_Header,
      item: opportunity,
      opportunity,
      submitButtonText: this.i18n.OrgManage_Opportunities_OptIn,
    };

    return this.modalService
      .show<Opportunity>(SimpleModalComponent, {
        inputs,
      })
      .pipe(
        switchMap((opportunity) => {
          // Set local flag for opt in
          this.isUserOptIn = true;
          // Opt in for opportunities when user clicks opt in
          return this.optIntoOpportunities(opportunity);
        }),
        catchError((error: Error) =>
          throwError(
            new DgError(
              this.i18n.Opportunities_InterestNotConfirmedError,
              error
            )
          )
        )
      );
  }

  /**
   * Use ajs modal service to show a recommendation modal.
   *
   * @param opportunity - The opportunity to share
   * @param selected - Optional: The selected users to pre-populate the form with
   */
  public showShareModal(
    opportunity: Opportunity,
    element?: HTMLElement,
    useSmarterMatching: boolean = false,
    selected: RecommendedUser[] = []
  ): Observable<void> {
    return this.recommendationsModalService
      .showShareModal(
        {
          internalUrl: `/opportunities/${opportunity.opportunityId}`,
          // Opportunities can only be shared within orgs
          isOrgContent: true,
          location: opportunity.locationName,
          matchScore: opportunity.matchedSkillsScore,
          matchScoreSource: useSmarterMatching
            ? 'Smarter matching'
            : 'Simple matching',
          name: opportunity.targetName,
          resourceId: opportunity.opportunityId,
          resourceType: 'Opportunity',
          title: opportunity.title,
          type: this.opportunitiesService.getDisplayOpportunityTypes(
            opportunity
          ) as TargetSuggestionType,
          // Linter error - Argument of type {...} is not assignable to parameter of type 'RecommendingItem'
        } as any,
        element,
        selected
      )
      .pipe(
        catchAndSurfaceNonModalError<void>(
          this.i18n.OrgManage_Opportunities_Error_Share
        )
      );
  }

  public showSkillsDetailModal({
    opportunity,
    opportunityId,
    userProfileKey,
    showWithTargetLevelSkillsOnly = false,
    extraTrackingProperties,
  }: {
    opportunity?: Opportunity;
    opportunityId: number;
    userProfileKey: string | number;
    showWithTargetLevelSkillsOnly?: boolean;
    extraTrackingProperties?: TrackingProperties;
  }) {
    this.trackerService.trackEventData({
      action: 'Opportunity SkillsMatch Viewed',
      properties: {
        opportunityId: opportunityId,
        profileOwnerId: userProfileKey,
        ...extraTrackingProperties,
      },
    });

    return this.modalService
      .show(UserSkillsModalComponent, {
        inputs: {
          // Wrapping in a function because modal.show iterates over its inputs,
          // and only passes on some of the properties for an object. This is what
          // we want in most cases, but not for an Observable.
          userData: () =>
            this.getApplicantAlgorithmData(opportunityId, userProfileKey),
          showWithTargetLevelSkillsOnly,
          opportunityId,
          opportunity,
        },
        centered: true,
        // There is a `scrollable` setting for ngBootstrap's modal,
        // but we aren't using it because this modal has a fixed height,
        // whereas ngBootstrap's scrollable modal takes up as much
        // vertical space as possible.
        // Instead: we're setting overflow-y: auto and a max-height on
        // the modal's body ourselves.
      })
      .pipe(
        catchError((error: Error) =>
          throwError(
            new DgError(this.i18n.Opportunities_Error_GetApplicantData, error)
          )
        )
      );
  }

  /**
   * Show the user a modal to let them rate their matching, unrated
   * skills.
   *
   * @param skills - The skills to rate.
   * @param trackingLocation - For the underlying Add Rating modal.
   */
  public showUserRateSkillsModal({
    opportunity,
    skills,
    trackingLocation,
    user,
  }: {
    opportunity: Opportunity;
    skills: Skill[];
    trackingLocation?: string;
    user: AuthUser;
  }): Observable<Skill[]> {
    return this.modalService
      .show<Skill[]>(UserRateSkillsModalComponent, {
        inputs: {
          opportunity,
          skills,
          trackingLocation,
          user,
        },
        // There is a `scrollable` setting for ngBootstrap's modal,
        // but we aren't using it because this modal has a fixed height,
        // whereas ngBootstrap's scrollable modal takes up as much
        // vertical space as possible.
        // Instead: we're setting overflow-y: auto and a max-height on
        // the modal's body ourselves.
      })
      .pipe(
        catchError((error: Error) =>
          throwError(
            new DgError(this.i18n.Opportunities_Error_GetApplicantData, error)
          )
        )
      );
  }

  /**
   * Unapply for an opportunity
   *
   * @param opportunity The Opportunity
   */
  public unapplyForOpportunity(
    opportunity: Opportunity
  ): Observable<{ isUserOptIn: boolean; opportunity: Opportunity }> {
    return (
      this.http
        // this endpoint returns no content
        .post(`${this.baseUrl}/unapply`, opportunity)
        // send back the original opportunity with the new ApplicationStatus
        .pipe(
          map(() => {
            return {
              isUserOptIn: true,
              opportunity: {
                ...opportunity,
                isInterested: false,
                applicationStatus: OpportunityApplicationStatus.None,
              },
            };
          })
        )
    );
  }

  /**
   * @deprecated Use `updateApplicantStages` instead.
   * TODO: Delete when useRejectMessageEmail flag is deleted.
   */
  public updateApplicantStage({
    opportunity,
    stage,
    item,
  }: {
    opportunity: Opportunity;
    stage: OpportunityApplicationStage;
    item: Candidate;
  }): Observable<void | CloseAndNotify> {
    // For any stage other than Selected, just update in the BE.
    if (stage !== OpportunityApplicationStageEnum.Selected) {
      return this.updateBulkStages({
        opportunityId: opportunity.opportunityId,
        stage,
        userProfileKeys: [item.userProfileKey],
      });
    }
    // For Selected, we have a modal to show first.
    return this.showClosePromptModal({
      userProfileKeys: [item.userProfileKey],
      opportunity,
      stage,
      inputs: undefined,
    });
  }

  /**
   * Update the stage of a given applicant.
   *
   * @param opportunity - Current opportunity.
   * @param stage - Stage to set the applicant to.
   * @param item - The current applicant.
   */
  public updateApplicantStages({
    opportunity,
    stage,
    userProfileKeys,
  }: {
    opportunity: Opportunity;
    stage: OpportunityApplicationStage;
    userProfileKeys: (number | string)[];
  }): Observable<void | CloseAndNotify> {
    if (!this.useRejectMessageEmail) {
      return this.updateBulkApplicantStages({
        opportunity,
        stage,
        userProfileKeys,
      });
    }
    switch (stage) {
      case OpportunityApplicationStageEnum.Selected: {
        // Prompt the user to optionally close the opportunity.
        return this.showClosePromptModal({
          userProfileKeys,
          opportunity,
          stage,
          inputs: { usePlural: userProfileKeys.length > 1 },
        });
      }
      case OpportunityApplicationStageEnum.Archived: {
        return this.showArchiveCandidatesModal({
          userProfileKeys,
          opportunityId: opportunity.opportunityId,
        });
      }
      default: {
        // Otherwise, just do the update
        return this.updateBulkStages({
          opportunityId: opportunity.opportunityId,
          stage,
          userProfileKeys,
        });
      }
    }
  }

  /**
   * @deprecated Use `updateApplicantStages` instead.
   * TODO: Delete when useRejectMessageEmail flag is deleted.
   */
  public updateBulkApplicantStages({
    opportunity,
    stage,
    userProfileKeys,
  }: {
    opportunity: Opportunity;
    stage: OpportunityApplicationStage;
    userProfileKeys: (number | string)[];
  }): Observable<void | CloseAndNotify> {
    if (stage !== 'Selected') {
      return this.updateBulkStages({
        opportunityId: opportunity.opportunityId,
        stage,
        userProfileKeys,
      });
    }

    return this.showClosePromptModal({
      opportunity,
      stage,
      userProfileKeys,
      inputs: { usePlural: true },
    });
  }

  /**
   * Update the current logged-in user's interest in the given opportunity.
   *
   * @param opportunity - The current opportunity.
   * @param wasInterested - Whether the user *was* interested.
   */
  public updateInterest({
    opportunity,
    wasInterested,
  }: {
    opportunity: Opportunity;
    wasInterested: boolean;
  }): Observable<Opportunity> {
    const isNowInterested = !wasInterested; // toggle current interest
    // set our strings
    let successMessage = 'Opportunities_InterestConfirmed';
    let errorMessage = 'Opportunities_InterestNotConfirmedError';
    // flip if wasInterested === true
    if (wasInterested) {
      successMessage = 'Opportunities_InterestRemoved';
      errorMessage = 'Opportunities_InterestNotRemovedError';
    }
    // Lazy evaluation with defer, only the valid branch will be executed
    return defer(() =>
      isNowInterested
        ? this.applyForOpportunity(opportunity)
        : this.unapplyForOpportunity(opportunity)
    ).pipe(
      // don't do anything if the user did not opt in to sharing their information
      filter(({ isUserOptIn }) => !!isUserOptIn),
      // do most of our processing: show success message
      // and return updated opportunity + translated `trackedAction` for tracking.
      map(({ opportunity }) => {
        // show success message
        this.notifierService.showSuccess(
          this.translateService.instant(successMessage)
        );
        // return only `opportunity`, discarding `isUserOptIn` since we don't need it any longer
        return opportunity;
      }),
      catchError((error: Error) =>
        throwError(
          new DgError(this.translateService.instant(errorMessage), error)
        )
      )
    );
  }

  private closeOpportunity(
    closeRequest: CloseOpportunityRequest
  ): Observable<Opportunity> {
    return (
      this.http
        // this endpoint doesn't return content
        .put(`${this.baseUrl}/closeopportunity`, {
          opportunityId: closeRequest.opportunity.opportunityId,
          notifyUnSelectedCandidates: closeRequest.notifyUnSelectedCandidates,
          messageToUnSelectedCandidates:
            closeRequest.messageToUnSelectedCandidates,
        })
        // return the opportunity that was sent in
        .pipe(
          map(() => ({
            ...closeRequest.opportunity,
            status: OpportunityStatus.Closed,
          }))
        )
    );
  }

  private editBulkVisibility(visibilityData: VisibilityData) {
    return (
      this.http
        // this endpoint returns no content
        .put(`${this.baseUrl}/bulkupdatevisibility`, visibilityData)
        // send back the visibility data that was sent in
        .pipe(mapTo(visibilityData))
    );
  }

  private getApplicantAlgorithmData(
    opportunityId: number,
    userProfileKey: string | number
  ): Observable<CandidateAlgorithmData> {
    return this.http.get<CandidateAlgorithmData>(
      `${this.baseUrl}/GetApplicantAlgorithmData`,
      {
        params: { opportunityId, userKey: userProfileKey },
      }
    );
  }

  // Pass it a path to locally stored images in 'content/img' directory and return blob url from it
  private getBlobUrl(assetPath: string): string {
    return this.webEnvironmentService.getBlobUrl(assetPath, true);
  }

  /**
   * Sanity-check: Make sure we're updating the right user.
   * As with the sanity check on the opportunity view, this shouldn't
   * be necessary, as we shouldn't actually have collisions.
   *
   * @param userKey - The current logged-in user's user profile key.
   * @param opportunityId - The current opportunity's ID.
   */
  private hasUserBeenPromptedToAddSkills(
    userKey: number,
    opportunityId: number
  ): boolean {
    return (
      userKey === this.userAddRatingsPromptData?.userKey &&
      opportunityId === this.userAddRatingsPromptData?.opportunityId &&
      !!this.userAddRatingsPromptData?.hasBeenPrompted
    );
  }

  /**
   * Posts a request to opt in to opportunities
   *
   * @param {Opportunity} opportunity
   */
  private optIntoOpportunities(opportunity: Opportunity): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/optintoopportunitiesshareuserdata`, opportunity)
      .pipe(mapTo(opportunity));
  }

  private showArchiveCandidatesModal({
    userProfileKeys,
    opportunityId,
  }): Observable<CloseAndNotify> {
    return this.modalService
      .show<ArchiveCandidatesParams>(ArchiveCandidatesModalComponent, {
        inputs: {
          selectedUserCount: userProfileKeys.length,
          opportunityId: opportunityId,
        },
      })
      .pipe(
        switchMap(({ notifyUnSelectedCandidates, messageFromSender }) =>
          // API call with the modal data added in
          this.updateBulkStages({
            userProfileKeys,
            opportunityId,
            stage: OpportunityApplicationStageEnum.Archived,
            notifyUnSelectedCandidates,
            messageFromSender,
          }).pipe(
            // Consistent return for the UX. The opportunity cannot be
            // closed from this flow, so it will always be false.
            map(() => ({
              closed: false,
              notified: notifyUnSelectedCandidates,
            }))
          )
        )
      );
  }

  private showClosePromptModal({
    userProfileKeys,
    opportunity,
    stage,
    inputs,
  }): Observable<CloseAndNotify> {
    let closed = false;
    let notifyUnSelectedCandidates = false;
    let messageToUnSelectedCandidates = '';
    return this.modalService
      .show<{
        closeOpportunity: boolean;
        notifyOthers?: boolean;
        rejectionMessage?: string;
      }>(SelectCandidateModalComponent, {
        inputs,
      })
      .pipe(
        // Update the applicant stage.
        switchMap(({ closeOpportunity, notifyOthers, rejectionMessage }) => {
          // Grab our values.
          closed = closeOpportunity;
          notifyUnSelectedCandidates = notifyOthers;
          messageToUnSelectedCandidates = rejectionMessage;
          // Update candidate stage
          return this.updateBulkStages({
            userProfileKeys,
            opportunityId: opportunity.opportunityId,
            stage,
          });
        }),
        // Optionally close the opportunity.
        switchMap(() =>
          iif(
            () => closed,
            this.closeOpportunity({
              opportunity,
              notifyUnSelectedCandidates,
              messageToUnSelectedCandidates,
            }).pipe(
              // Notify user of successful Opportunity closing.
              tap(() =>
                this.notifierService.showSuccess(
                  this.translateService.instant(
                    'OrgManage_Opportunities_Success_Close',
                    {
                      opportunityTitle: opportunity.title,
                    }
                  )
                )
              )
            ),
            // An immediately-resolving Opportunity to continue the pipeline...
            of(true)
          )
        ),
        // Regardless, map our response back to the expected payload
        map(() => ({
          closed,
          notified: notifyUnSelectedCandidates,
        }))
      );
  }

  private updateBulkStages(update: StageUpdate): Observable<void> {
    return this.http
      .put<void>(`${this.baseUrl}/bulkupdateapplicantstages`, update)
      .pipe(
        catchAndSurfaceError(
          this.translateService.instant(
            'Opportunities_Error_UpdateApplicantStage'
          )
        )
      );
  }

  private visibilityOptions(): VisibilityOption[] {
    // get the current auth user
    const authUser = this.authService.authUser;
    const canUserPublishOpportunities =
      authUser?.canManageOpportunities || authUser?.canPublishOpportunities;
    // visible-to-collaborators and visible-to-groups are available by default
    const availableVisibilities: VisibilityOption[] = [
      {
        canExpand: false,
        disabled: false,
        item: {
          name: this.i18n.OrgManage_Opportunities_VisibleCollaborators,
          visibility: Visibility.private,
          level: Visibility.private,
        },
      },
      {
        canExpand: true,
        disabled: !canUserPublishOpportunities,
        item: {
          name: this.i18n.OrgManage_Opportunities_VisibleGroups,
          visibility: Visibility.groups,
          level: Visibility.groups,
        },
      },
      {
        canExpand: false,
        disabled: !canUserPublishOpportunities,
        item: {
          name: this.i18n.OrgManage_Opportunities_VisibleOrganization,
          visibility: Visibility.public,
          level: Visibility.public,
        },
      },
    ];

    return availableVisibilities;
  }
}
