import { SelectOption } from '@app/shared/components/select/select.component';
import { TriggerSchedule, TriggerScheduleType } from '../model';
import { TranslateService } from '@ngx-translate/core';

export const makeCadenceOptions = (
  translate: TranslateService
): SelectOption[] => [
  {
    id: TriggerScheduleType.Daily,
    title: translate.instant('Automation_Schedule_Daily'),
  },
  {
    id: TriggerScheduleType.Weekly,
    title: translate.instant('Automation_Schedule_Weekly'),
  },
  {
    id: TriggerScheduleType.Monthly,
    title: translate.instant('Automation_Schedule_Monthly'),
  },
  {
    id: TriggerScheduleType.Once,
    title: translate.instant('Automation_Schedule_Once'),
  },
];

export const makeWeeklyDayOptions = (
  translate: TranslateService
): SelectOption[] => {
  const prefix = `Core_DayOfWeek`;
  return [
    { id: 1, title: translate.instant(`${prefix}${getDayOfWeek(1)}`) },
    { id: 2, title: translate.instant(`${prefix}${getDayOfWeek(2)}`) },
    { id: 3, title: translate.instant(`${prefix}${getDayOfWeek(3)}`) },
    { id: 4, title: translate.instant(`${prefix}${getDayOfWeek(4)}`) },
    { id: 5, title: translate.instant(`${prefix}${getDayOfWeek(5)}`) },
    { id: 6, title: translate.instant(`${prefix}${getDayOfWeek(6)}`) },
    { id: 7, title: translate.instant(`${prefix}${getDayOfWeek(7)}`) },
  ];
};

export const makeMonthlyDayOptions = (
  translate: TranslateService
): SelectOption[] => [
  { id: 1, title: translate.instant(`Automation_Schedule_Ordinal_1`) },
  { id: 5, title: translate.instant(`Automation_Schedule_Ordinal_5`) },
  { id: 10, title: translate.instant(`Automation_Schedule_Ordinal_10`) },
  { id: 15, title: translate.instant(`Automation_Schedule_Ordinal_15`) },
  { id: 20, title: translate.instant(`Automation_Schedule_Ordinal_20`) },
  { id: 25, title: translate.instant(`Automation_Schedule_Ordinal_25`) },
  {
    id: -1,
    title: translate.instant(`Automation_Schedule_LastDayOfMonth`),
  },
];

export const makeTimeOptions = (): SelectOption[] => [
  { id: '00:00', title: '00:00' },
  { id: '01:00', title: '01:00' },
  { id: '02:00', title: '02:00' },
  { id: '03:00', title: '03:00' },
  { id: '04:00', title: '04:00' },
  { id: '05:00', title: '05:00' },
  { id: '06:00', title: '06:00' },
  { id: '07:00', title: '07:00' },
  { id: '08:00', title: '08:00' },
  { id: '09:00', title: '09:00' },
  { id: '10:00', title: '10:00' },
  { id: '11:00', title: '11:00' },
  { id: '12:00', title: '12:00' },
  { id: '13:00', title: '13:00' },
  { id: '14:00', title: '14:00' },
  { id: '15:00', title: '15:00' },
  { id: '16:00', title: '16:00' },
  { id: '17:00', title: '17:00' },
  { id: '18:00', title: '18:00' },
  { id: '19:00', title: '19:00' },
  { id: '20:00', title: '20:00' },
  { id: '21:00', title: '21:00' },
  { id: '22:00', title: '22:00' },
  { id: '23:00', title: '23:00' },
];

export const getDayOfWeek = (number) => {
  switch (number) {
    case 1:
      return 'Sunday';
    case 2:
      return 'Monday';
    case 3:
      return 'Tuesday';
    case 4:
      return 'Wednesday';
    case 5:
      return 'Thursday';
    case 6:
      return 'Friday';
    case 7:
      return 'Saturday';
    default:
      return null;
  }
};
export const isValidSchedule = (schedule?: TriggerSchedule): boolean => {
  if (!schedule?.scheduleType) {
    return false;
  }

  switch (schedule.scheduleType) {
    case TriggerScheduleType.Once:
      return !!(schedule.specifiedDate && schedule.executionTime);
    case TriggerScheduleType.Daily:
      return !!schedule.executionTime;
    case TriggerScheduleType.Weekly:
    case TriggerScheduleType.Monthly:
      return !!(schedule.day && schedule.executionTime);
  }
};
