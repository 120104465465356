<div
  id="dgx-content-insights-parent"
  infiniteScroll
  [infiniteScrollDisabled]="!isViewAll || loading || !hasMoreItems"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [immediateCheck]="true"
  (scrolled)="loadMoreItems()"
>
  <section
    [ngClass]="{ 'box-shadow rounded--large guts-p-full-2': !isViewAll }"
  >
    <div class="data-table-container">
      <div class="m-guts-m-t-0">
        <div class="l_flexbar guts-p-b-half">
          <h3 class="h3 table-title l_flex-grow" [innerHtml]="chartName"></h3>
          <a
            *ngIf="hasMoreItems || !isViewAll"
            class="par par--small link"
            (click)="trackViewAllClicked()"
            [routerLink]="'content'"
            data-dgat="targetTopContent-3f8"
            [attr.aria-label]="i18n.PathwayTopContent_ViewAllContext"
            ><span class="ib-v-middle">{{ i18n.Core_ViewAll }}</span>
            <df-icon icon="chevron-right" size="small"></df-icon
          ></a>
        </div>
        <div
          *ngIf="!loading && !hasData"
          [innerHtml]="i18n.PathwayTopContent_Empty"
          class="guts-p-t-2"
        ></div>
        <div class="guts-p-t-1" *ngIf="hasData">
          <dgx-data-table
            componentWrapperClass="data-table--medium grid__col-12 guts-p-full-0"
            tableWrapperClass="data-table"
            [columns]="columns"
            [items]="topContent"
            [isSorting]="sorting"
            (updateSort)="onSort($event)"
          ></dgx-data-table>
        </div>
        <dgx-skeleton
          *ngIf="loading"
          type="table"
          [rows]="loadCount + 1"
          [columns]="1"
        ></dgx-skeleton>
      </div>
    </div>
  </section>
</div>
