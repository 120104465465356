import { TranslateService } from '@ngx-translate/core';
import { isString } from '../utilities/types/type-guards';
import { DfFormFieldConfig } from './field-types';

/** An formly extension that provides translation for displayed template options properties. */
/**
 * @deprecated Fresco's Formly implementation is deprecated, please use reactive forms or another option.
 */
export class FormlyTranslateExtension {
  constructor(private translate: TranslateService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  prePopulate(field: DfFormFieldConfig) {
    const to = field.templateOptions;

    if (!to || to.noTranslate || to._translated) {
      return;
    }
    to._translated = true;
    field.expressionProperties = {
      ...(field.expressionProperties ?? {}),
      // Update translated properties when language changes
      // label is required; other props aren't
      ...(to.dfLabel &&
        isString(to.dfLabel) && {
          'templateOptions.dfLabel': this.translate.stream(to.dfLabel),
        }),
      ...(to.dfRequiredLabel &&
        isString(to.dfRequiredLabel) && {
          'templateOptions.dfRequiredLabel': this.translate.stream(
            to.dfRequiredLabel
          ),
        }),
      ...(to.dfDescription &&
        isString(to.dfDescription) && {
          'templateOptions.dfDescription': this.translate.stream(
            to.dfDescription
          ),
        }),
      ...(to.help &&
        isString(to.help) && {
          'templateOptions.help': this.translate.stream(to.help),
        }),
      ...(to.placeholder && {
        'templateOptions.placeholder': this.translate.stream(to.placeholder),
      }),
    };
  }
}
