import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CommentFieldComponent } from './components/comment-field/comment-field.component';
import { CommentThreadComponent } from './components/comment-thread/comment-thread.component';
import { CommentComponent } from './components/comment/comment.component';
import { DeleteCommentConfirmationModalComponent } from './components/delete-comment-confirmation-modal/delete-comment-confirmation-modal.component';
import { MentionInputComponent } from './mentions/components/mention-input/mention-input.component';
import { MentionService } from './mentions/services/mention.service';

const commentComponents = [
  CommentThreadComponent,
  CommentComponent,
  DeleteCommentConfirmationModalComponent,
  CommentFieldComponent,
];

@NgModule({
  declarations: [commentComponents],
  exports: [commentComponents],
  imports: [CommonModule, SharedModule, MentionInputComponent],
  providers: [MentionService],
})
export class CommentsModule {}
