import { Directive } from '@angular/core';
import { EventBus } from '@dg/shared-services';
import {
  PathwaySection,
  PathwayStep,
  PathwaySubsection,
} from '../rsm/pathway-api.model';
import { ActionType } from '../rsm/pathway.model';
import { ACTIONS } from '../rsm/utils';

/**
 * Base directive used by PathwayAuthorBase/PathwayUserBase.
 * *Not* used by PathwayNavBase.
 */
@Directive()
export abstract class PathwayContainerBaseDirective {
  public skeletons = new Array(5); // show 5 skeletons while loading

  constructor(private eventBus: EventBus) {}

  /**
   * Update activation of a given section / subsection.
   * Currently only used for subsections.
   *
   * Only works on browsers that support interesection observers. For other browsers,
   * our sections are expanded by default.
   *
   * @param type
   * @param section
   * @param subsection
   */
  public onItemIsVisible(
    type: string,
    section: number,
    subsection?: number
  ): void {
    return this.eventBus.announce(
      ACTIONS.activateNav(type as ActionType, section, subsection)
    );
  }

  /**
   * Track by (sub)section ID.
   *
   * @param index - The ngFor index.
   * @param item - The (sub)section.
   */
  public trackById(
    _: number,
    { id }: PathwaySection | PathwaySubsection
  ): number {
    return id;
  }

  /**
   * Track by step ID.
   *
   * @param index - The ngFor index.
   * @param step - The step.
   */
  public trackByStepId(_: number, { id, node }: PathwayStep): string {
    return `${id}${node}`;
  }
}
