<ul>
  <li
    *ngFor="let rating of breakdown"
    data-dgat="tag-rating-summary-breakdown-14b"
  >
    <div class="l_flex m-l_flex guts-p-t-3 l_flex-middle">
      <div class="guts-p-r-1">
        <dgx-tag-rating-donut
          [size]="'sm'"
          [level]="rating.level.toString()"
          class="ib"
        ></dgx-tag-rating-donut>
      </div>
      <div class="l_flex-grow">
        <h4 class="par par--small font-semibold">
          {{ rating.type }}
        </h4>
      </div>
    </div>
  </li>
</ul>
