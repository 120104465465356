import { Injectable } from '@angular/core';
import { DfIcon } from './svg/index';
import { DfIconMap } from './icon-sizes.model';

/**
 * Icon Registry was created because of the limitations of
 * using an Angular library which prevents the library itself
 * from hosting image files.  Instead, since the main usage for images
 * in a library is icons which are svg code, we've translated the
 * svgs into Typescript modules that can be imported. This also
 * allows for the potential to enable treeshaking by only
 * registering the necessary icons.
 *
 * That does also require us to have some way of translating
 * our simplified icon strings into which icon code we want, and that
 * is what this registry handles.
 * If you need an icon like `<df-icon icon="alert"></df-icon>` it should
 * be registered with the registry `IconRegistry.registerIcons([DfIconAlert])`
 * which will allow the 'alert' name to be mappped to the actual svg code
 * and returned on `getIcon`.
 */
@Injectable({
  providedIn: 'root',
})
export class DfIconRegistry {
  private registry = new Map<string, string>();

  public getRegisteredIcons(): Map<string, string> {
    return this.registry;
  }

  public registerIcons(icons: DfIcon[]): void {
    icons.forEach((icon: DfIcon) => {
      this.registry.set(icon.name, icon.data);
    });
  }

  public getIcon(iconName: string, size?: string): string | undefined {
    // if the name already includes the size, use that
    // this block should go away, but it'll make things easier in transition
    if (this.registry.has(iconName)) {
      return this.registry.get(iconName);
    }

    // if it works, just use it
    const iconNameWithSize = `${iconName}-${size}`;
    if (this.registry.has(iconNameWithSize)) {
      return this.registry.get(iconNameWithSize);
    }

    // Some checks to handle the current transition state where the default can be 24 or 16
    // this should go away when we have verified all icons have an appropriate default value
    const iconSmallName = `${iconName}-${DfIconMap.small}`;
    const iconMediumName = `${iconName}-${DfIconMap.medium}`;
    const iconLargeName = `${iconName}-${DfIconMap.large}`;
    if (this.registry.has(iconMediumName)) {
      // prefer medium if it exists
      return this.registry.get(iconMediumName);
    } else if (this.registry.has(iconSmallName)) {
      // small is the secondmost common size and has a few that medium doesn't have
      return this.registry.get(iconSmallName);
    } else if (this.registry.has(iconLargeName)) {
      // large is the least common size
      return this.registry.get(iconLargeName);
    }

    console.warn(
      `Could not find icon with name ${iconName}, did you add it to the registry and use the correct size reference?`
    );
    return undefined;
  }
}
