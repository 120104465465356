<form
  id="comment-field-form"
  [class.full-width]="isSeamless"
  #form
  [formGroup]="commentForm"
  (ngSubmit)="onSubmit()"
>
  <div
    [ngClass]="
      isSeamless ? 'comments__post guts-p-full-1 l_flex l_flex-justify' : ''
    "
  >
    <!-- insert anything that should go *before* the mention-input field. -->
    <ng-content select=".comment-field-prepend"></ng-content>

    <div
      class="l_flex-grow"
      [ngClass]="
        useDefaultControls ? 'l_flex l_flex-justify l_flex-center-children' : ''
      "
    >
      <dgx-mention-input
        #mention
        [form]="commentForm"
        [ariaLabel]="ariaLabel"
        [autoFocus]="autoFocus"
        [isEditing]="!isReadOnly"
        [groupId]="groupId"
        [groupPrivacy]="groupPrivacy"
        [placeholder]="ariaLabel"
        [dgatInput]="dataDgat ? dataDgat : 'comment-field-74c'"
        class="block l_flex-grow"
        [ngClass]="{
          'mention-input--seamless': isSeamless,
          'mention-input--pseudo-textarea': isPseudoTextarea,
        }"
        (focused)="focusCommentField.emit()"
      ></dgx-mention-input>

      <!-- default comment button -->
      <button
        *ngIf="useDefaultControls"
        df-button
        dfButtonType="primary"
        type="submit"
        class="__submit guts-m-l-1"
        [attr.aria-disabled]="commentForm.invalid || isSubmitting"
        [attr.aria-label]="i18n.A11y_AddComment"
        data-dgat="comment-field-934j"
      >
        <df-spinner-button [isSpinning]="isSubmitting">
          {{ i18n.Core_Comment }}
        </df-spinner-button>
      </button>
    </div>
  </div>

  <!-- insert controls + anything else you want to follow the mention-input -->
  <ng-content></ng-content>
</form>
