import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { DfIconModule } from '@lib/fresco';
import { DgxLineClampModule } from '@app/shared/directives/line-clamp/line-clamp.module';

@NgModule({
  imports: [CommonModule, DfIconModule, DgxLineClampModule],
  providers: [],
  declarations: [SelectComponent],
  exports: [SelectComponent],
})
export class DgxSelectModule {}
