<dgx-modal
  (dismiss)="closeModal()"
  [isFooterless]="true"
  [isHeaderBorderless]="true"
  bodyClasses="dg-modal__body--no-padding"
  headerLabelId="first-pathway-header-label"
>
  <ng-container class="modal-header"></ng-container>
  <ng-container class="modal-body">
    <div class="center-text guts-p-full-2">
      <img
        [src]="degreedFirstPathwayIntroLogo"
        static="true"
        class="img-responsive custom-img"
      />
      <h1
        class="h2 guts-m-b-half guts-m-t-1"
        id="dialogTitle"
        [innerHTML]="'firstPathwayModal_Followed' | translate"
        id="first-pathway-header-label"
      ></h1>
      <p
        class="par guts-m-b-1"
        [innerHTML]="'firstPathwayModal_WhyTheyHelp' | translate"
        id="dialogDescription"
      ></p>
      <a
        (click)="openPathWayPage()"
        [innerHTML]="'firstPathwayModal_ViewPathways' | translate"
        *ngIf="pathwaysUrl"
        class="par link underline"
        data-dgat="firstPathway-b67"
      ></a>
    </div>
  </ng-container>
</dgx-modal>
