import { ElementRef, Injectable } from '@angular/core';
import { Skill } from '@app/opportunities/opportunities-api.model';
import { getTagLevel, hasExternalRating } from '@app/shared/utils/tag-helpers';
import { InternalTagRatingTypes } from '@app/tags/tags';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TagsApi } from '@app/tags/tag-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { TagRatingService } from '@app/tags/services/tag-rating.service';
import { TagsService } from '@app/tags/services/tags.service';
import { FocusStackService } from '@app/shared/services/focus-stack.service';

@Injectable({
  providedIn: 'root',
})
export class OpportunitySkillsModalService {
  public i18n = this.translateService.instant([
    'dgProfileOverview_TooltipSignalsSingle',
    'dgProfileOverview_TooltipSignalsPlural',
  ]);

  constructor(
    private authService: AuthService,
    private focusStackService: FocusStackService,
    private tagRatingService: TagRatingService,
    private tagsService: TagsService,
    private translateService: TranslateService
  ) {}

  public showUserRateSkillModal(skill: Skill, sourceTarget?: ElementRef) {
    let skillLevel: number;
    let emptyRating = true;

    const selfRatingSkill = skill.ratings?.find(
      (r) => r.type === InternalTagRatingTypes.self
    );

    if (selfRatingSkill) {
      skillLevel = +selfRatingSkill.level;
      emptyRating = false;
    } else {
      skillLevel = 1;
    }

    const isEditing = !(skillLevel < 1);
    const title = this.translateService.instant(
      isEditing
        ? 'Opportunities_Skills_Modal_EditTargetHeader'
        : 'Opportunities_Skills_Modal_AddTargetHeader',
      { skillName: skill.name }
    );

    return this.tagRatingService
      .openRatingModal({
        title,
        tag: skill,
        ratingType: InternalTagRatingTypes.self,
        emptyRating,
        initialValue: skillLevel,
        addNewSkill: false,
        errorOnDismiss: true,
      })
      .pipe(
        finalize(() => {
          if (sourceTarget) {
            this.focusStackService.push(sourceTarget.nativeElement);
            this.focusStackService.pop();
          }
        }),
        catchError(() => {
          return of();
        }),
        mergeMap((rating: any) => {
          // When the user saves rating without scrolling the horizontal rating bar,
          // the default level is 0, we should manually alter it to 1.
          if (rating.level === 0) {
            rating.level = 1;
          }
          if (!skill.userInterestId) {
            return this.tagsService
              .addUserTags([{ ...skill, rating } as any])
              .pipe(map(() => rating));
          }
          return of(rating);
        }),
        mergeMap((rating: any) =>
          this.tagsService.rateTag(
            rating.level,
            InternalTagRatingTypes.self,
            skill as TagsApi.TagDetails
          )
        ),
        mergeMap(() =>
          this.tagRatingService.getTagRatings(
            this.authService.authUser.viewerProfile.userProfileKey,
            skill.tagId
          )
        )
      );
  }

  public hasExternalSkillRating(skill: Skill): boolean {
    // TODO: No point adding a tag-helper, just move it to service
    return hasExternalRating(skill);
  }

  public getCurrentSkillLevel(skill: Skill) {
    // TODO: No point adding a tag-helper, just move it to service
    return getTagLevel(skill);
  }

  /**
   * Determine whether the skill rating has been certified.
   * @param skill - The given skill.
   */
  public isCertified(skill: Skill): boolean {
    const rating = skill?.rating;
    if (!rating?.level || !rating?.type) {
      return false;
    }
    return rating.type === InternalTagRatingTypes.credential;
  }

  /**
   * Determine whether the skill rating has been evaluated.
   * @param skill - The given skill.
   */
  public isEvaluated(skill: Skill): boolean {
    const rating = skill.rating;
    if (!rating?.level || !rating?.type) {
      return false;
    }
    return rating.type === InternalTagRatingTypes.evaluation;
  }

  public getSkillSignalsLabel(count: number) {
    return `${count} ${
      count === 1
        ? this.i18n.dgProfileOverview_TooltipSignalsSingle
        : this.i18n.dgProfileOverview_TooltipSignalsPlural
    }`;
  }
}
