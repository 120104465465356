import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Completed Mentorship notification */
@Component({
  selector: 'dgx-mentorship-completed',
  template: '<span [innerHTML]="safeContent"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentorshipCompletedComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml('MentorshipCompleted_NotificationTitleFormat', {
      mentorshipUser: this.userFullNameTemplate,
    });
  }
}
