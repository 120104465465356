import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { InputsService } from '@app/inputs/services/inputs.service';
import { TipService } from '@app/onboarding/services/tip.service';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
  DfIconCross16,
  DfIconQuestionMarkCircle16,
  DfIconRegistry,
  DfIconTag16,
} from '@lib/fresco';
import { AuthUser } from '@app/account/account-api.model';
import { ContextService } from '@app/shared/services/context.service';
import { UserContentModalBaseDirective } from '../user-content-modal-base/user-content-modal-base.directive';
import { AnyUserCourseParametersViewModel } from '../user-input.model';
import { CourseFormModel, DefaultInputParameters } from './course-form.model';
import { CourseFormDomainService } from './course-form-domain.service';
import { GroupId, UserCourse } from '@app/inputs/inputs-api.model';
import { CourseInformalFormComponent } from './course-informal-form/course-informal-form.component';
import { CourseFormalFormComponent } from './course-formal-form/course-formal-form.component';
import { UserCourseService } from './user-course.service';
import { take } from 'rxjs/operators';
import { OrgService } from '@app/orgs/services/org.service';

@Component({
  selector: 'dgx-course-modal',
  templateUrl: './course-modal.component.html',
  styleUrls: ['./course-modal.component.scss'],
})
export class CourseModalComponent
  extends UserContentModalBaseDirective<
    UserCourse,
    CourseFormModel,
    AnyUserCourseParametersViewModel
  >
  implements OnInit, AfterViewInit
{
  @Input() public resolve;
  @Input() public modalInstance;
  @ViewChild(CourseFormalFormComponent)
  public formalInstitutionForm: CourseFormalFormComponent;
  @ViewChild(CourseInformalFormComponent)
  public informalInstitutionForm: CourseInformalFormComponent;

  @ViewChild('institutionSelectionTemplate')
  public institutionSelectionRef: TemplateRef<any>;

  public formOptions = {};
  public modalOptions: any = {};
  public saveCourseLabel = this.domainService.i18n.CourseFormCtrl_SaveCourse;
  public readonly formTitle = this.isEditing
    ? this.domainService.i18n.CourseFormCtrl_EditCourse
    : this.domainService.i18n.CourseFormCtrl_AddCourse;
  private accreditedSource = 0;
  private informalSource = -1;
  private isChannel = false;

  public get courseFormModel(): CourseFormModel {
    // until types get figured out this is required to set the
    // model to the child formal/informal course forms
    return this.model as unknown as CourseFormModel;
  }

  constructor(
    @Inject(WindowToken) windowRef: Window,
    cdr: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    authService: AuthService,
    tipService: TipService,
    inputsService: InputsService,
    translateService: TranslateService,
    private builder: DfFormFieldBuilder,
    private iconRegistry: DfIconRegistry,
    private domainService: CourseFormDomainService,
    private contextService: ContextService,
    private userCourseService: UserCourseService,
    private orgService: OrgService
  ) {
    super(
      windowRef,
      cdr,
      activeModal,
      authService,
      tipService,
      inputsService,
      translateService.instant('CourseFormCtrl_AddCourse'),
      translateService.instant('CourseFormCtrl_EditCourse')
    );
    this.iconRegistry.registerIcons([
      DfIconQuestionMarkCircle16,
      DfIconCross16,
      DfIconTag16,
    ]);
    this.initializeFormState();
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.handleModalOptions();
    this.cdr.detectChanges();
  }

  public onCourseTypeChange($event): void {
    const selectedItem = $event?.$event?.selectedItem;
    this.model.institution = null;
    this.model.selectedOrg = this.selectOrg(selectedItem);
    if (selectedItem) {
      this.model.selectedOrg.id = selectedItem.Id;
    } else {
      this.model.selectedOrg = $event;
    }
    this.model.selectedOrg = this.model.selectedOrg;
    this.model.isAccredited =
      this.model.selectedOrg?.id === this.accreditedSource;
    this.model.extent.dateCompleted = undefined; // Re-setting datepicker ui bound property
    this.fields = this.buildFormFields();
    this.cdr.detectChanges();
  }

  public onModelHasChanged(data: CourseFormModel): void {
    this.model = { ...this.model, ...data };
    this.cdr.detectChanges();
  }

  public onSelectedCourseHasChange(selectedCourse: CourseFormModel): void {
    this.model = { ...this.model, ...selectedCourse };
    if (this.model.isEbbLoading) {
      // remove course if isEbbLoading and not correct url
      // populate form from scraped page data
      this.model.input.courseUrl = this.resolve?.input?.Url;
      this.model.input.description = this.resolve?.input?.Summary;
    }
    this.cdr.detectChanges();
    if (this.resolve?.getCourseInputForEbb) {
      this.resolve.getCourseInputForEbb(this.buildInputForEbb());
    }
  }

  /**
   * Generates the UI.
   * Displays 2 radio buttons which will trigger the display of the actual forms.
   */
  protected buildFormFields(): DfFormFieldConfig<DfTemplateOptions>[] {
    // Decide which form fields should we build.
    const formFields = this.model.isAccredited
      ? this.formalInstitutionForm.generateUI()
      : this.informalInstitutionForm.generateUI();

    const defaultFields: DfFormFieldConfig<DfTemplateOptions>[] = [
      this.builder
        .customField(
          'organizationType',
          this.domainService.i18n.CourseFormCtrl_WhereTaken,
          this.institutionSelectionRef
        )
        .hiddenWhen(() => this.isCompleting === false)
        .build(),
    ];
    return defaultFields.concat(
      formFields,
      this.domainService.buildUIConfiguration(this.model as CourseFormModel)
    );
  }

  protected initFormModel(source: UserCourse) {
    this.model = this.domainService.convertApiModelToViewModel(
      source,
      this.model
    );
  }

  protected createResult(
    model: CourseFormModel,
    defaults: UserCourse
  ): AnyUserCourseParametersViewModel {
    const result = this.model.isAccredited
      ? this.formalInstitutionForm.createResult()
      : this.informalInstitutionForm.createResult();
    return result;
  }

  private selectOrg(selectedItem = null) {
    const sourceList = this.model.sourceList || [];
    return selectedItem
      ? selectedItem
      : sourceList.find((org) => org.id === this.model.orgId);
  }

  /**
   * Initialized form state.
   */
  private initializeFormState() {
    this.isLoading = true;
    this.isChannel = this.contextService.urlHasContext(
      location.href,
      'channel'
    );
    const user: AuthUser = this.authService.authUser;
    this.model.input = this.initializeDefaultInputParameters();
    this.model.isEbbLoading = false;
    this.model.hideGroups = this.isChannel;
    this.model.groupNames = [];
    this.model.org = user?.orgInfo ? user.orgInfo[0] : null;
    if (this.model.input?.organizationId) {
      this.model.orgId = this.model.input.organizationId;
    } else {
      this.model.orgId = this.model.input?.isAccredited
        ? this.accreditedSource
        : this.informalSource;
    }
    this.model.canComment = !!user?.canComment;
    this.model.isPrivateProfile = !!user?.isPrivateProfile;
    this.model.country = this.domainService.i18n.CourseFormCtrl_US;
    this.model.sourceList = [
      {
        title: this.domainService.i18n.CourseFormCtrl_Accredited,
        id: this.accreditedSource,
      },
      {
        title: this.domainService.i18n.CourseFormCtrl_Informal,
        id: this.informalSource,
      },
    ];
    this.model.shouldAddToCatalog =
      this.model.isPathwayBinAdd &&
      this.model.canManageContent &&
      this.model.selectedOrg.id > 0;

    this.model.authoring = false; // I taught this course field.
    this.model.completing = this.isCompleting;
    this.isLoading = false;
    this.model.formUIState = {
      isAdding: this.isAdding,
      isEditing: this.isEditing,
      isLoading: this.isLoading,
      shouldIncludeAddToPathWay: this.modalOptions?.savePathwayFromExtension
        ? this.modalOptions.savePathwayFromExtension
        : false,
    };
    this.saveCourseLabel = this.modalOptions?.addToCatalogForEbb
      ? this.resolve?.addToCatalogButtonText
      : this.saveCourseLabel;
    this.model.selectedOrg = this.selectOrg();
    this.model.isAccredited = this.model.input.isAccredited; // Default mode
    this.model.isAccredited =
      this.model.selectedOrg?.id === this.accreditedSource;

    this.model.allCourseLevels = [
      { id: 1, title: this.domainService.i18n.CourseFormCtrl_FirstYearLevel },
      {
        id: 2,
        title: this.domainService.i18n.CourseFormCtrl_SecondYearLevel,
      },
      { id: 3, title: this.domainService.i18n.CourseFormCtrl_ThirdYearLevel },
      {
        id: 4,
        title: this.domainService.i18n.CourseFormCtrl_FourthYearLevel,
      },
      { id: 5, title: this.domainService.i18n.CourseFormCtrl_GraduateLevel },
    ];
    this.model.unitTypes = [
      this.domainService.i18n.Core_Minutes,
      this.domainService.i18n.Core_Hours,
      this.domainService.i18n.Core_Days,
      this.domainService.i18n.Core_Weeks,
    ];
    this.model.allGrades = [
      { id: 11, title: 'A' },
      { id: 10, title: 'A-' },
      { id: 9, title: 'B+' },
      { id: 8, title: 'B' },
      { id: 7, title: 'B-' },
      { id: 6, title: 'C+' },
      { id: 5, title: 'C' },
      { id: 4, title: 'C-' },
      { id: 3, title: 'D+' },
      { id: 2, title: 'D' },
      { id: 1, title: 'D-' },
    ];
    this.model.course = {
      id: undefined,
      label: undefined,
      value: undefined,
      relevance: undefined,
      image: undefined,
      rank: undefined,
      desc: undefined,
      metadata: undefined,
    };
    this.model.extent = {
      courseLevel: undefined,
      dateCompleted: undefined,
      courseGrade: undefined,
      verificationUrl: undefined,
    };
  }

  private initializeDefaultInputParameters(): DefaultInputParameters {
    const defaultInput: DefaultInputParameters = {
      isAccredited: false,
      unitType: 'Hours', // (this is a value, not a display string)
      inputType: 'Course',
      accessible: true,
      orgContentMetadata: {
        groupIds: [],
        hideFromCatalog: true,
      },
      organizationId: undefined,
      institutionId: undefined,
      name: undefined,
      userInputId: undefined,
      inputId: undefined,
      title: undefined,
      courseUrl: undefined,
      courseNumber: undefined,
      institutionName: undefined,
      creditHours: undefined,
      units: undefined,
      externalId: undefined,
      description: undefined,
      groupIds: undefined,
      dateCompleted: undefined,
      verificationUrl: undefined,
      authored: false,
      imageUrl: undefined,
      levelId: undefined,
      gradeId: undefined,
      tags: [],
    };
    return defaultInput;
  }

  // REVIEW THIS CODE
  private buildInputForEbb() {
    let input = { ...this.model.input };

    input.institutionId = this.model.institution
      ? this.model.institution.institutionId
      : this.informalSource; // -1 is a special institution for org (internal) courses
    if (this.model.course?.id) {
      // selected an existing course
      input.name = this.model.course.label;
    } else if (this.model.courseName) {
      // typed a new course name
      input.name = this.model.courseName;
    }
    input.gradeId = input.gradeId;
    if (
      this.model.org !== null &&
      this.model.isPathwayBinAdd &&
      this.model.selectedOrg.id > 0
    ) {
      input.organizationId = this.model.org.organizationId;
    }
    if (!input.orgContentMetadata) {
      // Default state if checkbox for internal content was left unchecked
      input.orgContentMetadata = {
        hideFromCatalog: true,
        groupIds: this.model.input.orgContentMetadata.groupIds,
      };
    }

    input = this.forceAddToCatalogForChannelContext(input);

    input.orgContentMetadata.groupIds = this.getGroupIdentifiers();
    return input;
  }

  private forceAddToCatalogForChannelContext(input) {
    if (this.isChannel) {
      input.OrgContentMetadata = {
        HideFromCatalog: false,
      };
      input.OrganizationId = this.model.forceOrganizationId;
    }
    return input;
  }

  private getGroupIdentifiers() {
    const groupNames = this.model.groupNames;
    return groupNames.map((gn, i) => {
      return {
        groupId: this.model.groupIds[i].groupId,
        name: gn,
      };
    });
  }

  private handleModalOptions() {
    const mdlOpts = this.modalInstance?.modalOptions || this.modalOptions;
    if (!mdlOpts) {
      return;
    }
    this.modalOptions = mdlOpts;
    const viewModel = this.model;
    const addOrgToSourceList =
      !viewModel.completing &&
      (viewModel.forceOrganizationId ||
        (mdlOpts.isPathwayBinAdd && viewModel.org?.organizationId));
    this.model.forceOrganizationId = mdlOpts.forceOrganizationId;
    this.model.completing = mdlOpts.completing;
    this.isEditing = mdlOpts.editing;
    this.model.completing = !mdlOpts.inputId || !mdlOpts.completing;
    this.model.canManageContent = mdlOpts.canManageContent;
    this.model.isPathwayBinAdd =
      this.modalOptions.isPathwayBinAdd || this.isPathwayBinAdd;

    if (addOrgToSourceList) {
      this.addToSourceList();
    }

    // If the Authored checkBox should be checked by default set Authored to true
    if (
      this.model.authoring &&
      !this.isEditing &&
      this.modalOptions.authoredChecked
    ) {
      this.model.input.authored = this.modalOptions.authoredChecked;
    }

    if (this.modalOptions.inputId || this.modalOptions.userInputId) {
      this.isLoading = true;
      this.model.authoring = false; // Original code was using 'creating'

      const getCourse = this.modalOptions.userInputId
        ? this.userCourseService.Get(this.modalOptions.userInputId)
        : this.inputsService.getCourse(this.modalOptions.inputId);

      getCourse.pipe(take(1)).subscribe((response: any) => {
        const respData = response.data || response || {};
        this.model.input = {
          ...this.model.input,
          ...respData,
        };

        this.model.input.tags = respData.tags;
        this.model.input.description =
          respData.description || this.resolve.pageData?.summary;

        // setup inner objects for binding to the autocompletes
        if (this.model.input.institutionId) {
          this.model.institution = {
            ...this.model.institution,
            institutionId: this.model.input.institutionId,
            name: this.model.input.institutionName,
          };
          this.model.institutionName = this.model.institution.name;
        }
        this.model.course = {
          ...this.model.course,
          id: this.model.input.inputId,
          label: this.model.input.name,
        };
        this.model.courseName = this.model.course.label;

        if (respData.gradeId) {
          this.model.input.gradeId = this.model.allGrades.filter(
            (g) => g.id === respData.gradeId
          )[0].id;
        }

        // hack to help the radio buttons work correctly
        if (this.model.input.organizationId) {
          this.model.orgId = this.model.input.organizationId;
        } else {
          this.model.orgId = this.model.isAccredited ? 0 : -1;
        }
        this.model.selectedOrg = this.selectOrg();
        this.isLoading = false;
      });
    }
    this.cdr.detectChanges();
  }

  private async addToSourceList() {
    const orgIdForSourceList =
      this.model.forceOrganizationId || this.model.org.organizationId;
    const orgData = await this.orgService
      .getOrganization(orgIdForSourceList)
      .toPromise();
    const org = orgData;
    this.model.sourceList.push({
      title: org.name,
      id: org.organizationId,
    });
  }
}
