import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  inject,
  Injectable,
} from '@angular/core';
import { ToastService } from '@degreed/apollo-angular';
import { LDFlagsService } from '@dg/shared-services';
import { CoachBotComponent } from '../components/coach-bot/coach-bot.component';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class CoachDynamicLoaderService {
  private appRef: ApplicationRef = inject(ApplicationRef);
  private componentRef: ComponentRef<CoachBotComponent> = null;
  private readonly environmentInjector: EnvironmentInjector =
    inject(EnvironmentInjector);
  private readonly ldFlagService = inject(LDFlagsService);
  private readonly toasterService = inject(ToastService);
  private readonly translate = inject(TranslateService);

  public readonly i18n = this.translate.instant([
    'Core_Warning',
    'Coach_NotEnabledWarning',
  ]);

  public async loadCoachBuilder(
    coachId: string,
    pathwayId?: number
  ): Promise<void> {
    if (this.componentRef) {
      this.destroyComponent();
    }

    if (!this.ldFlagService.lxpDegreedCoach) {
      this.toasterService.showToast(this.i18n.Coach_NotEnabledWarning, {
        title: this.i18n.Core_Warning,
        type: 'warning',
      });
      return;
    }

    const { CoachBotComponent } = await import(
      '../components/coach-bot/coach-bot.component'
    );

    this.componentRef = createComponent(CoachBotComponent, {
      environmentInjector: this.environmentInjector,
    });

    this.componentRef.instance.coachId = coachId;
    this.componentRef.instance.pathwayId = pathwayId;

    this.appRef.attachView(this.componentRef.hostView);

    document.body.appendChild(this.componentRef.location.nativeElement);

    this.componentRef.instance.closed.subscribe(() => {
      this.destroyComponent();
    });
  }

  public destroyComponent(): void {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
