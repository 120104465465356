import { Component } from '@angular/core';

import { NotificationItemComponentBase } from './core/notification-item-component-base';

/** Represents a Group Invite notification */
@Component({
  selector: 'dgx-group-invite',
  template: '<span [innerHTML]="safeContent"></span>',
})
export class GroupInviteComponent extends NotificationItemComponentBase {
  protected buildTemplate() {
    return this.translateHtml(
      'dgGroupInvite_groupInviteNotificationInviteFormat',
      {
        personNameHtml: this.userFullNameTemplate,
        groupNameHtml: this.groupNameTemplate,
      }
    );
  }
}
