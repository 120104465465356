import { AuthoringParams } from '../pathway.model';
export const enum PathwayTracking {
  CREATE_SECTION = 'Pathway Section Added',
  REORDER_SECTION = 'Pathway Section Reordered', // Tracking not instrumented!
  DELETE_SECTION = 'Pathway Section Deleted', // Tracking not instrumented!

  CREATE_SUBSECTION = 'Pathway Lesson Added',
  REORDER_SUBSECTION = 'Pathway Lesson Reordered', // Tracking not instrumented!
  DELETE_SUBSECTION = 'Pathway Lesson Deleted', // Tracking not instrumented!

  CREATE_STEP = 'Content Added to Pathway',
  REORDER_STEP = 'Pathway Item Reordered', // Tracking not instrumented!
  DELETE_STEP = 'Content Removed from Pathway', // Tracking not instrumented!
  ADDTOBIN_STEP = 'Content Added To Pathway Bin', // Tracking not instrumented!

  UNENROLL = 'Pathway Withdrawn',
  ENROLL = 'Pathway Enrolled',
  UPDATE_VISIBILITY = 'Pathway Visibility Changed',
  DELETE_PATHWAY = 'Pathway Deleted',
}

export function getTrackingParams(
  actionType: PathwayTracking,
  payload: Partial<AuthoringParams>
) {
  return {
    action: actionType,
    properties: {
      pathwayId: payload?.id,
      pathwayName: payload?.title,
      isEndorsed: payload?.isEndorsed,
    },
  };
}
