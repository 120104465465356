import { Injectable } from '@angular/core';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import { AuthService } from '@app/shared/services/auth.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { ThumbnailService } from '@app/thumbnails/thumbnail.service';
import { cloneDeep } from 'lodash-es';
import { UploaderService } from '@app/uploader/uploader.service';

@Injectable({
  providedIn: 'root',
})
export class OrgBrandingService {
  constructor(
    private authService: AuthService,
    private http: NgxHttpClient,
    private thumbnailService: ThumbnailService,
    private uploaderService: UploaderService,
    private webEnvironmentService: WebEnvironmentService
  ) {}

  /**
   * getBranding
   * @param orgId
   * @param originalBrandingData
   * @returns object containing all branding information for editing
   */
  public getBranding(orgId, originalBrandingData = null) {
    if (!originalBrandingData) {
      originalBrandingData = {};
      const authUser = this.authService.authUser;
      const orgInfo = authUser.orgInfo.find(
        (org) => org.organizationId === orgId
      );
      originalBrandingData.org = orgInfo;
      originalBrandingData.hasLightText =
        orgInfo.organizationBranding.useLightText;
      originalBrandingData.hexBrand = orgInfo.organizationBranding.brandColor;
      originalBrandingData.srcSet = this.getOrgImageSrcSet(orgInfo.image);
      originalBrandingData.orgImage = orgInfo.image;
      originalBrandingData.endorsedImage = orgInfo.endorsedImage;
      originalBrandingData.organizationNameInOnboarding =
        orgInfo.organizationBranding.organizationNameInOnboarding;
      originalBrandingData.academiesHeaderImg = authUser.academiesBannerImage;
      return cloneDeep(originalBrandingData);
    } else {
      return cloneDeep(originalBrandingData);
    }
  }

  /**
   * getOrgImageSrcSet
   * @param src
   * @returns all src urls to fill a srcset
   */
  public getOrgImageSrcSet(src: string) {
    return this.thumbnailService.fetchProxyImageSrcset({
      imageSrc: this.webEnvironmentService.getBlobUrl(src),
      imageHeight: 110,
      imageWidth: 110,
      crop: 'fit',
    });
  }

  /**
   * uploadLogoFile
   * @param file
   * @param cropperSettings
   * @param orgId
   * @returns server response after saving
   */
  public uploadLogoFile(file: File, cropperSettings, orgId) {
    const preparedFile = this.uploaderService.prepRawImageFile(file);

    return this.uploaderService.uploadFile(
      preparedFile,
      '/organizations/savepicturecrop',
      {},
      { orgId, ...cropperSettings },
      false
    );
  }

  /**
   * updateBranding
   * @param orgId
   * @param brandColor
   * @param usesLightText
   * @param orgImage
   * @param endorsedImage
   * @param organizationNameInOnboarding
   * @param orgName
   * @returns server response after saving
   */
  public updateBranding(
    orgId,
    brandColor,
    usesLightText,
    orgImage,
    endorsedImage,
    organizationNameInOnboarding,
    orgName,
    academiesImage
  ) {
    return this.http.post('/organizations/saveorganizationbranding', {
      orgId,
      brandColor,
      endorsedImage,
      orgImage,
      usesLightText,
      organizationNameInOnboarding,
      orgName,
      academiesImage,
    });
  }

  /**
   * discardBrandingImage
   * Warns server to delete an image that we won't actually be using
   */
  public discardBrandingImage(orgId, orgImage) {
    return this.http.delete('/organizations/deletebrandinglogofile', {
      params: {
        orgId,
        orgImage,
      },
    });
  }
}
