<dgx-modal
  [canCancel]="false"
  [isHeaderBorderless]="false"
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
>
  <ng-container class="modal-header">
    {{ i18n.bulkUpdateModal_HeadingRolePlans }}
  </ng-container>
  <ng-container class="modal-body">
    <div class="guts-p-full-1 form-wrap">
      <div class="guts-p-b-2">
        <button
          class="font-medium link"
          (click)="downloadTemplate()"
          data-dgat="orgBulkUpload-b08"
        >
          <df-icon icon="arrow-down-tray" class="gamma guts-m-r-half"></df-icon>
          <span class="zeta">{{ i18n.bulkUpdateModal_DownloadTemplate }}</span>
        </button>
      </div>
      <div>
        <dgx-file-uploader
          [showCancel]="false"
          [targetLabel]="i18n.bulkUpdateModal_UploadAFile"
          (successEvent)="preprocessingComplete($event)"
          [uploadApiEndpoint]="uploadTo"
          [fileTypes]="'.xls,.xlsx'"
          [sizeLimit]="2"
          [templateOption]="'compact'"
          [postObject]="postObject"
          [errorLabel]="i18n.dgFileUploadButton_UploadErrorMessage"
        ></dgx-file-uploader>
      </div>
      <div class="guts-p-t-2">
        <table *ngIf="isFileUploaded" class="data-table data-table--short">
          <tbody>
            <tr>
              <th scope="row">{{ i18n.bulkUpdateModal_FileName }}</th>
              <td class="right-text">{{ fileName | ellipsis: 35 }}</td>
            </tr>
            <tr>
              <th scope="row">
                {{ i18n.bulkUpdateModal_ExcelImported }}
              </th>
              <td class="right-text">{{ sheetName | ellipsis: 35 }}</td>
            </tr>
            <tr>
              <th scope="row">{{ i18n.bulkUpdateModal_RowsFound }}</th>
              <td class="right-text">{{ rowsFound }}</td>
            </tr>
            <tr>
              <th scope="row">{{ i18n.bulkUpdateModal_FirstRow }}</th>
              <td class="right-text">{{ firstItemValue | ellipsis: 35 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="isFileUploaded" class="guts-p-t-2 font-medium zeta">
        {{ i18n.bulkUpdateModal_SuccessfulUpload }}
      </div>
    </div>
  </ng-container>
  <ng-container class="modal-footer">
    <div class="l_flex-grow l_flex-center left-text">
      <button
        *ngIf="!isFileUploaded; else cancelButton"
        df-button
        dfButtonType="clear"
        (click)="backToSelection()"
        class="l_flex--align-right"
        data-dgat="orgBulkUpload-413"
      >
        <span class="action-options__button-label">{{ i18n.Core_Back }}</span>
      </button>
      <ng-template #cancelButton>
        <button
          df-button
          dfButtonType="clear"
          (click)="cancelProcessing()"
          class="l_flex--align-right"
          data-dgat="orgBulkUpload-413"
        >
          <span class="action-options__button-label">{{
            i18n.bulkUpdateModal_CancelProcessing
          }}</span>
        </button>
      </ng-template>
    </div>
    <div>
      <button
        type="submit"
        df-button
        dfButtonType="primary"
        (click)="createRoles()"
        data-dgat="orgBulkUpload-d38"
        [disabled]="!isFileUploaded"
        ngClass="{ is_disabled: !isFileUploaded }"
      >
        <span>{{ i18n.bulkUpdateModal_CreateRoles }}</span>
      </button>
    </div>
  </ng-container>
</dgx-modal>
