import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NotifierService } from '@app/shared/services/notifier.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AllInputApiEntities,
  RenderMode,
} from '../user-input/user-input.model';

/** Provides input notifying events to the back end based on input event messages on the global message bus
 * IMPORTANT: Currently, notifications for render modes other than ContentCatalog are either not provided or handled
 * elsewhere. With additional refactoring those could be brought in here too.
 * Ideally, instead of being called directly, this should be refactored to listen to the salient events on
 * a message bus. However, the current structure of the app modules creates multiple instances of the services,
 * due to lazy loading, which causes duplicated notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class InputNotificationService {
  constructor(
    private translate: TranslateService,
    private notifier: NotifierService
  ) {}

  public notifyInputCreated(
    renderMode: RenderMode,
    entity: AllInputApiEntities
  ) {
    // Profile input creation notification is represented by the completion results presented; pathways hasn't historically provided any
    if (renderMode === RenderMode.ContentCatalog) {
      return this.notifyContentCatalogInputCreated(entity);
    }
  }

  public notifyInputCreateFailed(
    renderMode: RenderMode,
    entity: AllInputApiEntities
  ) {
    // Pathways hasn't historically provided any input addition errors
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.notifyUserProfileInputCreateFailed(entity);
      case RenderMode.ContentCatalog:
        return this.notifyContentCatalogInputCreateFailed();
    }
  }

  public notifyInputUpdated(
    renderMode: RenderMode,
    entity: AllInputApiEntities,
    isBrokenLinkUpdated?: boolean
  ) {
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.notifyUserProfileInputUpdated(entity);
      case RenderMode.Pathways: // Pathways input editing, when available, simply edits the content catalog item
      case RenderMode.ContentCatalog:
        return this.notifyContentCatalogInputUpdated(
          renderMode,
          isBrokenLinkUpdated
        );
    }
  }

  public notifyInputUpdateFailed(
    renderMode: RenderMode,
    entity: AllInputApiEntities,
    error?: HttpErrorResponse
  ) {
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.notifyUserProfileInputUpdateFailed(entity);
      case RenderMode.Pathways: // Pathways input editing, when available, simply edits the content catalog item
      case RenderMode.ContentCatalog:
        return this.notifyContentCatalogInputUpdateFailed(error);
    }
  }

  public notifyInputDeleted(
    renderMode: RenderMode,
    entity: AllInputApiEntities
  ) {
    // Pathways hasn't historically provided any input deletion errors
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.notifyUserProfileInputDeleted(entity);
      case RenderMode.ContentCatalog:
        return this.notifyContentCatalogInputDeleted(entity);
    }
  }

  public notifyInputDeleteFailed(
    renderMode: RenderMode,
    entity: AllInputApiEntities
  ) {
    // Pathways hasn't historically provided any input deletion errors
    switch (renderMode) {
      case RenderMode.UserProfile:
        return this.notifyUserProfileInputDeleteFailed(entity);
      case RenderMode.ContentCatalog:
        return this.notifyContentCatalogInputDeleteFailed(entity);
    }
  }

  // User Profile input notifications

  private notifyUserProfileInputCreateFailed(entity: AllInputApiEntities) {
    const messageMap = {
      Assessment: 'UserAssessmentSvc_AssessmentsError',
      Book: 'UserBookSvc_BookAddError', // Note this is an outlier as it's add-specific
      Course: 'UserCourseSvc_CourseError',
      Article: 'UserMediaSvc_MediaError',
      Video: 'UserMediaSvc_MediaError',
      Episode: 'UserEpisodeSvc_EpisodeError',
      Event: 'UserEventSvc_EventError',
      Position: 'UserPositionSvc_ExperienceError',
    };
    this.notifier.showError(
      this.translate.instant(messageMap[entity.inputType], {
        title: this.getTitle(entity),
      })
    );
  }

  private notifyUserProfileInputUpdated(entity: AllInputApiEntities) {
    this.notifier.showSuccess(
      this.translate.instant('ProfileCtrl_EditCollectionItemNotifierFormat', {
        itemTitle: this.getTitle(entity),
      })
    );
  }

  private notifyUserProfileInputUpdateFailed(entity: AllInputApiEntities) {
    this.notifier.showError(
      this.translate.instant('UserProfileSvc_ProblemUpdatingCollectionItem')
    );
  }

  private notifyUserProfileInputDeleted(entity: AllInputApiEntities) {
    this.notifier.showSuccess(
      this.translate.instant('ProfileCtrl_DeleteCollectionItemNotifierFormat', {
        itemTitle: this.getTitle(entity),
      })
    );
  }

  private notifyUserProfileInputDeleteFailed(entity: AllInputApiEntities) {
    this.notifier.showError(
      this.translate.instant('UserProfileSvc_ProblemDeletingCollectionItem', {
        itemTitle: this.getTitle(entity),
      })
    );
  }

  // Content Catalog input notifications

  private notifyContentCatalogInputCreated(entity: AllInputApiEntities) {
    this.notifier.showSuccess(
      this.translate.instant('OrgInternalContentCtrl_SuccessfulAddContent', {
        title: this.getTitle(entity),
      })
    );
  }

  private notifyContentCatalogInputCreateFailed() {
    this.notifier.showError(
      this.translate.instant('OrgInternalContentCtrl_GenericError')
    );
  }

  private notifyContentCatalogInputUpdated(
    renderMode: RenderMode,
    isBrokenLinkUpdated?: boolean
  ): void {
    if (renderMode === RenderMode.ContentCatalog && isBrokenLinkUpdated) {
      this.notifier.showSuccess(
        this.translate.instant('dgOrgInternalContent_LinkUpdated')
      );
      return;
    }
    this.notifier.showSuccess(
      this.translate.instant(
        'OrgInternalContentSvc_SingleItemUpdatedSuccessMessage'
      )
    );
  }

  private notifyContentCatalogInputUpdateFailed(error: HttpErrorResponse) {
    if (error?.status === 400 && error?.error?.isDuplicate) {
      this.notifier.showError(
        this.translate.instant(
          'OrgInternalContentSvc_ItemUpdateDuplicateError',
          {
            url: error.error.message.split('URL: ').pop(),
          }
        )
      );
    } else {
      this.notifier.showError(
        this.translate.instant('OrgInternalContentSvc_ItemsUpdateError', {
          inputsCount: 1,
        })
      );
    }
  }

  private notifyContentCatalogInputDeleted(entity: AllInputApiEntities) {
    const title = this.getTitle(entity);
    if (title) {
      this.notifier.showSuccess(
        title +
          this.translate.instant(
            'OrgInternalContentSvc_ItemWTitleDeletedSuccessMessage'
          )
      );
    } else {
      this.notifier.showSuccess(
        1 +
          this.translate.instant(
            'OrgInternalContentSvc_ItemsDeletedSuccessMessage'
          )
      );
    }
  }

  private notifyContentCatalogInputDeleteFailed(entity: AllInputApiEntities) {
    const title = this.getTitle(entity);
    if (title) {
      this.notifier.showError(
        this.translate.instant(
          'OrgInternalContentSvc_ItemWTitleDeletionError',
          {
            title,
          }
        )
      );
    } else {
      this.notifier.showError(
        this.translate.instant('OrgInternalContentSvc_ItemsDeletionError', {
          inputsCount: 1,
        })
      );
    }
  }

  private getTitle(entity: AllInputApiEntities) {
    return entity.title || entity.name || '';
  }
}
