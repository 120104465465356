import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { RendererContext, FormRenderer } from '../../form-renderer.model';
import { FieldType } from '@app/user-content/services/field-builder-factory';
import { ContentCatalogFormBuilderService } from '@app/user-content/services/content-catalog-form-builder.service';
import { isUrlValid } from '@app/shared/utils/common-utils';
import { EventFormModel } from '../event-modal.facade';
import { InputCommonFieldBuilder } from '../../services/input-common-field.builder';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { RenderMode } from '../../user-input.model';
import { hasBrokenUrlValidator } from '@app/shared/validators/has-broken-url.validator';

@Injectable({ providedIn: 'root' })
export class ContentCatalogEventRenderer implements FormRenderer {
  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService,
    private contentCatalogFormBuilderService: ContentCatalogFormBuilderService,
    private commonFieldBuilder: InputCommonFieldBuilder,
    private ldFlagsService: LDFlagsService
  ) {}
  render(
    context: RendererContext<EventFormModel>
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const vm = context.state();
    return [
      // Created By
      this.commonFieldBuilder.buildCreatedByField(context.templates.creator),
      // Main form
      this.createUrlField(context),
      this.builder
        .title()
        .asRequired()
        .withPlaceholder('EventFormCtrl_EventTitle')
        .validatedByIndexed({
          emptyStringError: {
            expression: (control) => {
              return (control.value || '').trim().length;
            },
            message: this.translate.instant('Core_FieldRequiredFormat', {
              fieldName: this.translate.instant('Core_Title'),
            }),
          },
        })
        .build(),
      this.builder
        .optionalTextarea('summary', 'EventFormCtrl_Description')
        .withDgatId('eventForm-abd')
        .build(),
      this.builder
        .customField('tags', 'Core_Skills', context.templates.skills)
        .unwrapped()
        .build(),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.USER_IMAGE_FIELD)
        .build({
          useCropper: true,
          inputType: context.inputContext.inputType,
          resourceId: vm.inputId,
        }),
      this.contentCatalogFormBuilderService
        .getBuilder(FieldType.INPUT_SESSION_FIELD)
        .build({ inputType: context.inputContext.inputType }),
      this.commonFieldBuilder.buildDurationFields(
        this.ldFlagsService.durationForCoursesEventsOther &&
          context.inputContext.renderMode === RenderMode.ContentCatalog,
        vm.inputType
      ),
      this.builder
        .fieldGroup('', [
          this.contentCatalogFormBuilderService
            .getBuilder(FieldType.ADVANCED_FIELDSET)
            .build({
              canRestrictContent: vm.canRestrictContent,
              organizationId: vm.organizationId,
              isInitiallyVisibleToOrg: vm.isVisibleToOrg,
              groups: vm.groups,
              expanderTemplate: context.templates.advancedExpander,
              expand$: vm.expandAdvanced$,
              fieldOptions: {
                externalId: {
                  isDisabled: context.state().fileManaged,
                },
              },
            }),
        ])
        .build(),
    ];
  }

  private createUrlField(
    context: RendererContext<EventFormModel>
  ): DfFormFieldConfig<DfTemplateOptions> {
    const vm = context.state();
    const urlValidation = {
      urlValidation: {
        expression: (control: AbstractControl) => {
          return control.value?.length > 0 ? isUrlValid(control.value) : true;
        },
        message: this.translate.instant('EventFormCtrl_ValidUrl'),
      },
    };
    const originalUrl = vm.eventUrl;

    if (!this.ldFlagsService.showBrokenLinksManagement) {
      return this.builder
        .optionalTextInput('eventUrl', 'EventFormCtrl_EventSite')
        .ofType('url')
        .withPlaceholder('EventFormCtrl_EventUrl')
        .validatedByIndexed(urlValidation)
        .autofocused()
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .build();
    } else {
      return this.builder
        .customField('eventUrl', null, context.templates.urlBrokenValidation, {
          label: this.translate.instant('EventFormCtrl_EventSite'),
          invalidUrlMessage: this.translate.instant('EventFormCtrl_ValidUrl'),
          brokenUrlMessage: this.translate.instant(
            'dgOrgInternalContent_BrokenLinkError'
          ),
          originalUrl,
          isRequired: false,
          placeholderText: this.translate.instant('EventFormCtrl_EventUrl'),
        })
        .validatedByIndexed(urlValidation)
        .validatedBy((control: AbstractControl) =>
          hasBrokenUrlValidator(
            control,
            this.translate.instant('dgOrgInternalContent_BrokenLinkError'),
            vm.hasBrokenUrl,
            control.value !== originalUrl
          )
        )
        .withHelp(context.templates.addToCatalogDupsHelpCatalog) // Use duplicates warning instead of thumbnail image for help in content catalog
        .build();
    }
  }
}
