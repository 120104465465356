import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DecodeHtmlPipe } from './decode-html.pipe';

@Pipe({
  name: 'htmlToPlaintext',
})
export class HtmlToPlaintextPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private decodeHtml: DecodeHtmlPipe
  ) {}

  /**
   * Strips html tags leaving only text content, optionally preserving linebreaks. Can also be used
   * to convert plaintext with (CR)/LF breaks to plaintext with html <br>s, which is useful
   * for preprocessing text that is pasted into a contenteditable element.
   *
   * @param htmlOrText - The string to strip.
   * @param preserveLineBreaksAsHtml - If TRUE, preserves linebreaks as <br> tags. Must be used with innerHTML.
   * @param skipSanitize - If FALSE, encodes potential HTML characters like & and < and >. Must be used with innerHTML.
   */
  public transform(
    htmlOrText: any,
    preserveLineBreaksAsHtml: boolean = false,
    skipSanitize: boolean = true
  ): any {
    if (!htmlOrText) {
      return '';
    }

    // Decode entities that might have been included, so that we can fully strip HTML.
    let strippedText = this.decodeHtml.transform(htmlOrText);

    if (!preserveLineBreaksAsHtml) {
      // replace consecutive white space, control chars and line breaks with single space
      strippedText = strippedText.replace(/(?:<br\s*\/?>|\s)+/gim, ' ').trim();
      strippedText = strippedText.replace(/<.*?>/gm, ''); // strip remaining html
      strippedText = strippedText.replace(/&#10;/gm, ' '); // strip line break entity created by markdown
    } else {
      strippedText = strippedText.replace(/\r?\n/gm, '<br>'); // convert plaintext line breaks to html
      strippedText = strippedText.replace(/&#10;/gm, '<br>'); // convert serialized line breaks to html
      strippedText = strippedText.replace(/<(?!br)[^>]*\/?>/gim, '').trim(); // strip all html that isn't a line break
    }
    strippedText = strippedText.replace(/\s+/gm, ' '); // consolidate remaining consecutive whitespace

    if (!skipSanitize) {
      // https://angular.io/api/platform-browser/DomSanitizer
      strippedText = this.sanitizer.sanitize(
        SecurityContext.HTML,
        strippedText
      );
      // Only necessary if and after sanitizer has been run.
      strippedText = strippedText.replace(/&amp;/g, '&');
    }

    return strippedText;
  }
}
