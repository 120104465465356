import { Injectable } from '@angular/core';
import { RecommendationsService } from '@app/recommendations/services/recommendations.service';

// types
import { ModalService } from '@app/shared/services/modal.service';
import { TagRecommendationViewModalComponent } from '@app/tags/components/tag-recommendation-view-modal/tag-recommendation-view-modal.component';

@Injectable({
  providedIn: 'root',
})
export class TagsRecommendationService {
  constructor(
    private modal: ModalService,
    private recommendationsService: RecommendationsService
  ) {}

  public processBulkRecommendation(notification) {
    this.recommendationsService
      .getRecommendationsByIds(notification.parameters.recommendationIds)
      .subscribe((response) => {
        const userInfo = notification.parameters.person;
        const inputs = {
          tags: response.recommendations,
          recommender: userInfo,
        };
        return this.modal
          .show<void>(TagRecommendationViewModalComponent, { inputs })
          .subscribe();
      });
  }
}
