import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { fadeInAndOut } from '@app/shared/animations/animations';
import { LocalityViewModel } from '@app/shared/components/address-suggest/address-suggest.service';
import { SessionedInputType } from '@app/shared/models/core-api.model';
import {
  TimeZoneDetails,
  TimeZoneService,
} from '@app/shared/services/time-zone.service';
import { DF_COLLAPSE_EXPAND, NotificationType } from '@lib/fresco';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { catchError, tap, throwError } from 'rxjs';
import {
  InputSession,
  LocationType,
  SessionParameters,
} from '@app/inputs/inputs-api.model';
import { TranslateService } from '@ngx-translate/core';
import { HTTP_REQUIRED_URL_PATTERN } from '@app/shared/utils';
import { InputSessionModel } from '@app/inputs/input-session-form-partial/input-session-model';
import { transformDateAndTimeToUTC } from '../../../course/services/course.utils';
import { calculateDurationBasedOnSessionDetails } from '@app/user-content/user-input-v2/utils/form-field-helper';
import {
  onFormControlUpdate,
  markFormAsTouched,
} from '@app/shared/utils/angular-form-field-helpers';
import { UserInputsService } from '@app/inputs/services/user-inputs.service';
import { SubscriberBaseDirective } from '@app/shared/components/subscriber-base/subscriber-base.directive';

@Component({
  selector: 'dgx-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [DF_COLLAPSE_EXPAND, fadeInAndOut],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SessionDetailsComponent
  extends SubscriberBaseDirective
  implements OnInit
{
  // As of now, we can only add a single session to a course/event
  @Input() public session: InputSession;
  @Input() public isSessionDetailsToggledOn: boolean = false;
  @Input() public inputType: SessionedInputType;

  // Some property changes are emitted so that they can be updated on the view model (properties that don't exist on the form group)
  @Output() public onAddressChange = new EventEmitter<LocalityViewModel>();
  @Output() public onToggleOfSessionDetails = new EventEmitter<boolean>();
  @Output() public updateDurationBasedOnSessionTime = new EventEmitter<{
    durationHours: number;
    durationMinutes: number;
  }>();

  // Local Values
  public localAddressDetails: LocalityViewModel;
  public sessionStartDate: NgbDateStruct;
  public sessionEndDate: NgbDateStruct;
  public sessionStartTime: string = '09:00'; // setting a default to act like a placeholder
  public sessionEndTime: string = '17:00'; // setting a default to act like a placeholder
  public minEndDate: NgbDateStruct;
  public bounds = {
    minEndTime: undefined,
    maxStartTime: undefined,
  };
  public isEditing: boolean = false;
  public isExpanded: boolean = false;
  /**
   * backupSession is used to preserve
   * values typed in by the user.
   * If the session fields are toggled off,
   * then when they are toggled back on,
   * the data that was input by the user is not lost.
   */
  public backupSession: SessionParameters;

  // Form Group
  public sessionDetailsForm: FormGroup;

  public i18n = this.translate.instant([
    'Core_Select',
    'Core_EndDate',
    'Core_FieldRequired',
    'Core_Online',
    'Core_StartDate',
    'Core_ValidUrl',
    'InputSessionForm_AddSessionDetails',
    'InputSessionForm_DeletionAlert',
    'InputSessionForm_Registration',
    'InputSessionForm_RegistrationAvailableNew',
    'InputSessionForm_InPerson',
    'InputSessionForm_Address',
    'InputSessionForm_DifferentUrl',
    'InputSessionForm_SearchAddress',
    'InputSessionForm_RegistrationUrl',
    'InputSessionForm_LocationType',
    'InputSessionForm_LocationUrl',
    'InputSessionForm_LocationUrlDescription',
    'InputSessionForm_InvalidDate',
    'InputSessionForm_StartTime',
    'InputSessionForm_EndTime',
    'InputSessionForm_InvalidTimeNew',
    'InputSessionForm_Timezone',
    'InputSessionForm_SameUrlNew',
    'InputSessionForm_DateTimeNew',
  ]);

  public readonly NotificationType = NotificationType;

  constructor(
    private datehandler: NgbDateParserFormatter,
    private timeZoneService: TimeZoneService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private userInputsService: UserInputsService,
    public parentForm: FormGroupDirective
  ) {
    super();
  }

  public get defaultUrlRegistrationRadioOption(): string {
    return this.translate.instant('InputSessionForm_SameUrlNew', {
      inputType: this.inputType.toLocaleLowerCase(),
    });
  }

  public get locationUrlDescription(): string {
    return this.translate.instant('InputSessionForm_LocationUrlDescription', {
      inputType: this.inputType.toLocaleLowerCase(),
    });
  }

  public ngOnInit(): void {
    this.initializeForm();

    this.isEditing = !!this.session && !this.isEmptyObject(this.session);
    const sessionDefaults: SessionParameters = new InputSessionModel();
    this.isExpanded = this.isSessionDetailsToggledOn;
    if (this.isEditing) {
      this.patchInitialFormValues();
      this.backupSession = { ...sessionDefaults, ...this.session };
      this.localAddressDetails = {
        locationAddress: this.session.locationAddress,
        city: this.session.city,
        country: this.session.country,
        countryCode: this.session.countryCode,
        latitude: this.session.latitude,
        longitude: this.session.longitude,
      };
    } else {
      const sessionDefaults: SessionParameters = new InputSessionModel();
      this.backupSession = { ...sessionDefaults };
    }
    this.userInputsService.childFormsMarkedAsTouched$
      .pipe(
        tap(() => {
          markFormAsTouched(this.sessionDetailsForm);
          this.cdr.markForCheck();
        }),
        this.takeUntilDestroyed()
      )
      .subscribe();
  }

  public onToggleSessionDetails(isExpanding: boolean): void {
    if (isExpanding) {
      this.initDatesAndTimes(true);
      this.sessionDetailsForm.patchValue({
        isRegistrationAvailable: this.backupSession?.isRegistrationAvailable,
        isRegistrationUrlInputUrl:
          this.backupSession?.isRegistrationUrlInputUrl,
        registrationUrl: this.backupSession?.registrationUrl,
        locationType: {
          isInPerson:
            this.backupSession?.locationType === LocationType.InPerson ||
            this.backupSession?.locationType === LocationType.Hybrid,
          isOnline:
            this.backupSession?.locationType === LocationType.Online ||
            this.backupSession?.locationType === LocationType.Hybrid,
        },
        locationAddress: this.backupSession?.locationAddress,
        locationUrl: this.backupSession?.locationUrl,
        startDate: this.sessionStartDate,
        endDate: this.sessionEndDate,
        startTime: this.sessionStartTime,
        endTime: this.sessionEndTime,
        timeZoneId: this.backupSession?.timeZoneId,
      });
      this.setInitialValidators();
    } else {
      this.backupSession = {
        isRegistrationAvailable: this.sessionDetailsForm.get(
          'isRegistrationAvailable'
        ).value,
        isRegistrationUrlInputUrl: this.sessionDetailsForm.get(
          'isRegistrationUrlInputUrl'
        ).value,
        registrationUrl: this.sessionDetailsForm.get('registrationUrl').value,
        locationType:
          this.sessionDetailsForm.get('locationType.isInPerson').value &&
          this.sessionDetailsForm.get('locationType.isOnline').value
            ? LocationType.Hybrid
            : this.sessionDetailsForm.get('locationType.isOnline').value
              ? LocationType.Online
              : LocationType.InPerson,
        locationAddress: this.sessionDetailsForm.get('locationAddress').value,
        locationUrl: this.sessionDetailsForm.get('locationUrl').value,
        startDateTime: transformDateAndTimeToUTC(
          this.sessionDetailsForm.get('startDate').value,
          this.sessionDetailsForm.get('startTime').value,
          this.datehandler
        ),
        endDateTime: transformDateAndTimeToUTC(
          this.sessionDetailsForm.get('endDate').value,
          this.sessionDetailsForm.get('endTime').value,
          this.datehandler
        ),
        timeZoneId: this.sessionDetailsForm.get('timeZoneId').value,
      };
      this.clearAllValidators();
    }
    this.isExpanded = isExpanding;
    this.onToggleOfSessionDetails.emit(this.isExpanded);
  }

  /**
   * Emits an event in order to update the view model & updates relevant form fields with the selected address details
   * @param $event LocalityViewModel
   */
  public onAddressSelection($event: LocalityViewModel): void {
    if (!$event?.locationAddress) {
      return;
    }
    this.timeZoneService
      .getTimeZoneFromCoordinates($event.latitude, $event.longitude)
      .pipe(catchError((e) => throwError(() => new Error(e))))
      .subscribe((response: TimeZoneDetails) => {
        this.localAddressDetails = {
          ...this.localAddressDetails,
          locationAddress: $event.locationAddress,
          city: $event.city,
          country: $event.country,
          countryCode: $event.countryCode,
          latitude: $event.latitude,
          longitude: $event.longitude,
        };
        this.onAddressChange.emit(this.localAddressDetails);
        this.sessionDetailsForm
          .get('locationAddress')
          .setValue($event.locationAddress);
        this.sessionDetailsForm.get('timeZoneId').setValue(response.timeZoneId);
      });
  }

  public onFormFieldChange(field?: string): void {
    if (!!field) {
      switch (field) {
        case 'isInPerson':
          this.onChangesToIsInPerson();
          break;
        case 'isOnline':
          this.onChangesToIsOnline();
          break;
        case 'startDate':
        case 'startTime':
          this.onChangesToStartDateOrTime();
          break;
        case 'endDate':
        case 'endTime':
          this.onChangesToEndDateOrTime();
          break;
        case 'timeZoneId':
          onFormControlUpdate(
            this.sessionDetailsForm,
            'timeZoneId',
            this.sessionDetailsForm.get('timeZoneId').value
          );
          break;
      }
    }
  }

  public clearAddress(): void {
    // These address-related properties don't exist within the form,
    // but we still need to update them in the view model in the parent after emitting the updated values
    const properties = [
      'locationAddress',
      'city',
      'country',
      'countryCode',
      'latitude',
      'longitude',
    ];

    for (const property of properties) {
      if (!!this.localAddressDetails[property]) {
        this.localAddressDetails[property] = null;
      }
    }

    this.onAddressChange.emit(this.localAddressDetails);

    // Reset the address and time zone form fields back to their default
    this.sessionDetailsForm.get('locationAddress').setValue(null);
    this.sessionDetailsForm
      .get('timeZoneId')
      .setValue(this.timeZoneService.getBrowserTimeZone());
  }

  /**
   * Marks touch status for validation
   */
  public onBlur(field: string) {
    // also mark the field a touched
    const formField = this.sessionDetailsForm.get(field);
    formField.markAsDirty();
    formField.markAsTouched();

    if (field === 'registrationUrl' || field === 'locationUrl') {
      const registrationUrlControl = this.sessionDetailsForm.get(field);
      registrationUrlControl.setValidators([
        Validators.required,
        Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
      ]);
    }

    // if field change resulted in a added validator, validate.
    const isRequired = formField.hasValidator(Validators.required);
    if (isRequired) {
      formField.updateValueAndValidity();
    }
  }

  private isEmptyObject(o: any): boolean {
    return JSON.stringify(o) === '{}';
  }

  /**
   * Sets the properties on the backupSession object, emits an event to update non-form properties, and clears the relevant properties within the form
   * @param properties string[]
   */
  private backupAndClearSessionProperties = (properties: string[]): void => {
    for (const property of properties) {
      if (!!this.sessionDetailsForm.get(property)) {
        this.backupSession[property] =
          this.sessionDetailsForm.get(property).value;
        this.sessionDetailsForm.get(property).setValue(null);
      }
    }
  };

  /**
   * Sets the form fields to the values from the backupSession object, emits an event to update non-form properties
   * @param properties string[]
   */
  private restoreSessionProperties = (properties: string[]): void => {
    for (const property of properties) {
      if (!!this.backupSession[property]) {
        this.sessionDetailsForm
          .get(property)
          .setValue(this.backupSession[property]);
      }
    }
  };

  /**
   * Creates separate properties for session start/end date and session start/end time & set minEndDate
   */
  private initDatesAndTimes = (useBackupSessionData: boolean): void => {
    const sessionReference = useBackupSessionData
      ? this.backupSession
      : this.session;
    if (!!sessionReference.startDateTime) {
      const dateTimeArray: string[] = sessionReference.startDateTime.split('T');
      const date: string = dateTimeArray[0];
      const time: string[] = dateTimeArray[1].split(':');
      this.sessionStartDate = this.datehandler.parse(date);
      this.sessionStartTime = `${time[0]}:${time[1]}`;
      this.minEndDate = this.sessionStartDate;
    }
    if (!!sessionReference.endDateTime) {
      const dateTimeArray: string[] = sessionReference.endDateTime.split('T');
      const date: string = dateTimeArray[0];
      const time: string[] = dateTimeArray[1].split(':');
      this.sessionEndDate = this.datehandler.parse(date);
      this.sessionEndTime = `${time[0]}:${time[1]}`;
    }
    if (!!sessionReference.startDateTime && !!sessionReference.endDateTime) {
      this.updateDuration();
    }
  };

  private onChangesToIsRegistrationAvailable(
    isRegistrationAvailable: boolean
  ): void {
    if (!isRegistrationAvailable) {
      this.sessionDetailsForm.get('isRegistrationUrlInputUrl').setValue(null);
      return;
    }
    this.sessionDetailsForm.get('isRegistrationUrlInputUrl').setValue(true);
  }

  private onChangesToIsRegistrationUrlInputUrl(
    isRegistrationUrlInputUrl: boolean
  ): void {
    const registrationUrlControl =
      this.sessionDetailsForm.get('registrationUrl');
    if (isRegistrationUrlInputUrl) {
      registrationUrlControl.clearValidators();
    } else if (this.sessionDetailsForm.get('isRegistrationAvailable').value) {
      registrationUrlControl.setValidators([
        Validators.required,
        Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
      ]);
      registrationUrlControl.markAsDirty();
      registrationUrlControl.markAsTouched();
    }
    registrationUrlControl.updateValueAndValidity();
  }

  private onChangesToIsInPerson(): void {
    const geolocationProperties = [
      'locationAddress',
      'city',
      'country',
      'countryCode',
      'latitude',
      'longitude',
    ];
    this.sessionDetailsForm.get('locationType.isInPerson').value
      ? this.restoreSessionProperties(geolocationProperties)
      : this.backupAndClearSessionProperties(geolocationProperties);
  }

  private onChangesToIsOnline(): void {
    this.sessionDetailsForm.get('locationType.isOnline').value
      ? this.restoreSessionProperties(['locationUrl'])
      : this.backupAndClearSessionProperties(['locationUrl']);
  }

  private onChangesToStartDateOrTime(): void {
    this.sessionStartDate = this.sessionDetailsForm.get('startDate').value;
    this.sessionStartTime = this.sessionDetailsForm.get('startTime').value;
    this.minEndDate = this.sessionStartDate;
    if (
      this.datehandler.format(this.sessionStartDate) ===
      this.datehandler.format(this.sessionEndDate)
    ) {
      this.bounds.minEndTime = this.sessionStartTime;
      this.bounds.maxStartTime = this.sessionEndTime;
      this.cdr.detectChanges();
      this.sessionDetailsForm.get('endTime').updateValueAndValidity();
    }
    if (
      this.sessionStartDate &&
      this.sessionStartTime &&
      this.sessionEndDate &&
      this.sessionEndTime
    ) {
      this.updateDuration();
    }
  }

  private onChangesToEndDateOrTime(): void {
    this.sessionEndDate = this.sessionDetailsForm.get('endDate').value;
    this.sessionEndTime = this.sessionDetailsForm.get('endTime').value;
    if (
      this.datehandler.format(this.sessionEndDate) ===
      this.datehandler.format(this.sessionStartDate)
    ) {
      this.bounds.minEndTime = this.sessionStartTime;
      this.bounds.maxStartTime = this.sessionEndTime;
      this.cdr.detectChanges();
      this.sessionDetailsForm.get('startTime').updateValueAndValidity();
    }
    if (
      this.sessionStartDate &&
      this.sessionStartTime &&
      this.sessionEndDate &&
      this.sessionEndTime
    ) {
      this.updateDuration();
    }
  }

  /**
   * Initializes the session details form group and add it to the parent form
   */
  private initializeForm(): void {
    this.sessionDetailsForm = this.formBuilder.group({
      isRegistrationAvailable: [],
      isRegistrationUrlInputUrl: [],
      registrationUrl: [],
      locationType: this.formBuilder.group({
        isInPerson: [],
        isOnline: [],
      }),
      locationAddress: [],
      locationUrl: [],
      startDate: [],
      endDate: [],
      startTime: [],
      endTime: [],
      timeZoneId: [],
    });

    this.parentForm.form.addControl('sessionDetails', this.sessionDetailsForm);

    this.sessionDetailsForm
      .get('isRegistrationAvailable')
      .valueChanges.subscribe((isRegistrationAvailable) =>
        this.onChangesToIsRegistrationAvailable(isRegistrationAvailable)
      );

    this.sessionDetailsForm
      .get('isRegistrationUrlInputUrl')
      .valueChanges.subscribe((isRegistrationUrlInputUrl) =>
        this.onChangesToIsRegistrationUrlInputUrl(isRegistrationUrlInputUrl)
      );
    this.sessionDetailsForm
      .get('locationType')
      .valueChanges.subscribe((locationType) => {
        if (locationType.isOnline) {
          this.sessionDetailsForm
            .get('locationUrl')
            .setValidators([
              Validators.required,
              Validators.pattern(HTTP_REQUIRED_URL_PATTERN),
            ]);
        } else if (!locationType.isOnline) {
          this.sessionDetailsForm.get('locationUrl').clearValidators();
        }
      });
  }

  /**
   * Patches form group fields with any existing session details when isEditing is true
   */
  private patchInitialFormValues(): void {
    this.initDatesAndTimes(false);
    this.sessionDetailsForm.patchValue({
      isRegistrationAvailable: this.session?.isRegistrationAvailable,
      isRegistrationUrlInputUrl: this.session?.isRegistrationUrlInputUrl,
      registrationUrl: this.session?.registrationUrl,
      locationType: {
        isInPerson:
          this.session?.locationType === LocationType.InPerson ||
          this.session?.locationType === LocationType.Hybrid,
        isOnline:
          this.session?.locationType === LocationType.Online ||
          this.session?.locationType === LocationType.Hybrid,
      },
      locationAddress: this.session?.locationAddress,
      locationUrl: this.session?.locationUrl,
      startDate: this.sessionStartDate,
      endDate: this.sessionEndDate,
      startTime: this.sessionStartTime,
      endTime: this.sessionEndTime,
      timeZoneId: this.session?.timeZoneId,
    });
    this.cdr.markForCheck();
  }

  private setInitialValidators(): void {
    function atLeastOneCheckedValidator(): ValidatorFn {
      return (group: FormGroup): ValidationErrors | null => {
        const inPerson = group.get('isInPerson').value;
        const online = group.get('isOnline').value;
        return inPerson || online ? null : { atLeastOneChecked: true };
      };
    }
    const isRegistrationUrlInputUrlValidation = this.sessionDetailsForm?.get(
      'isRegistrationAvailable'
    )?.value
      ? [Validators.required]
      : null;
    const addressValidation =
      this.sessionDetailsForm?.get('locationType')?.value ===
        LocationType.InPerson ||
      this.sessionDetailsForm?.get('locationType')?.value ===
        LocationType.Hybrid
        ? Validators.required
        : null;
    this.sessionDetailsForm
      .get('isRegistrationUrlInputUrl')
      .setValidators(isRegistrationUrlInputUrlValidation);
    this.sessionDetailsForm
      .get('locationType')
      .setValidators(atLeastOneCheckedValidator());
    this.sessionDetailsForm
      .get('locationAddress')
      .setValidators(addressValidation);
    const alwaysRequiredFields = [
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'timeZoneId',
    ];
    alwaysRequiredFields.forEach((field) => {
      this.sessionDetailsForm.get(field).setValidators(Validators.required);
    });
  }

  private clearAllValidators(): void {
    if (this.sessionDetailsForm?.controls) {
      Object.keys(this.sessionDetailsForm.controls).forEach((field) => {
        const control = this.sessionDetailsForm.get(field);
        if (control instanceof FormControl) {
          control.setErrors(null);
          control.clearValidators();
        } else if (control instanceof FormGroup) {
          this.sessionDetailsForm.get('locationType').clearValidators();
          this.sessionDetailsForm
            .get('locationType.isOnline')
            .clearValidators();
          this.sessionDetailsForm
            .get('locationType.isInPerson')
            .clearValidators();
        }
      });
      this.cdr.detectChanges();
      this.sessionDetailsForm.updateValueAndValidity();
    }
  }

  private updateDuration(): void {
    const duration: { durationHours: number; durationMinutes: number } =
      calculateDurationBasedOnSessionDetails(
        this.sessionStartDate,
        this.sessionEndDate,
        this.sessionStartTime,
        this.sessionEndTime
      );
    this.updateDurationBasedOnSessionTime.emit({
      durationHours: duration.durationHours,
      durationMinutes: duration.durationMinutes,
    });
  }
}
