import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'contentAction' })
export class ContentActionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(contentType: any, ...args: any[]): string {
    let result;
    switch (contentType.toLowerCase()) {
      case 'article':
      case 'book':
        result = this.translate.instant('Core_Read');
        break;
      case 'video':
        result = this.translate.instant('Core_Watch');
        break;
      case 'event':
        result = this.translate.instant('Core_Attend');
        break;
      case 'target':
        result = this.translate.instant('Core_Follow');
        break;
      default:
        result = this.translate.instant('Core_Complete');
    }
    return result;
  }
}
