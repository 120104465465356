import { Injectable, TemplateRef } from '@angular/core';
import { isEmptyValidator } from '@app/shared/validators/is-empty.validator';

import { DfFieldTemplateContext, DfFormFieldBuilder } from '@lib/fresco';

/** Exposes methods for building fields common amonst the Article modal variants */
@Injectable({ providedIn: 'root' })
export class ArticleCommonFieldBuilder {
  constructor(private builder: DfFormFieldBuilder) {}

  public buildTitleField(
    canEdit: boolean,
    readonlyTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    return (
      canEdit
        ? this.builder
            .title() // defaults to required
            .withDgatId('articleForm-dac')
            .validatedBy(isEmptyValidator) // editable
        : this.builder.customField('title', 'Core_Title', readonlyTemplate)
    ) // readonly
      .build();
  }

  public buildProviderField(
    canEdit: boolean,
    readonlyTemplate: TemplateRef<DfFieldTemplateContext>
  ) {
    return (
      canEdit
        ? this.builder
            .requiredTextInput('providerName', 'Core_Provider')
            .validatedBy(isEmptyValidator)
            .withDgatId('articleForm-160')
        : this.builder.customField(
            'providerName',
            'Core_Provider',
            readonlyTemplate
          )
    ).build();
  }
}
