import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { VideoService } from '@app/inputs/services/video.service';
import {
  LearningResourceType,
  ResourceType,
} from '@app/shared/models/core-api.model';
import { AuthService } from '@app/shared/services/auth.service';
import { LDFlagsService } from '@app/shared/services/ld-flags.service';
import { ResourceImageService } from '@app/shared/services/resource-image/resource-image.service';
import { WebEnvironmentService } from '@app/shared/services/web-environment.service';
import { resourceTypeIcon } from '@app/shared/utils/common-utils/resource-type-icon';
import {
  ImageSize,
  ImageSourceSet,
  ProxyImageOptions,
  ResourceFormat,
} from '@app/thumbnails/thumbnail.model';
import { ThumbnailService } from '@app/thumbnails/thumbnail.service';
import { CropperCoordinates } from '@app/uploader/image-cropper-modal/image-cropper-modal.model';
import { DfIconModule, DfIconSize } from '@lib/fresco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'dgx-image-layout',
  standalone: true,
  templateUrl: './image-layout.component.html',
  styleUrls: ['./image-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DfIconModule, NgbTooltip, NgClass, NgIf, NgStyle, TranslateModule],
})
export class ImageLayoutComponent implements OnChanges {
  public fallbackPatternSrc: string;
  public fallbackLogoSrc: string;
  public resourceSrc: ImageSourceSet;
  public imageHasError: boolean = false;
  public altText?: string;

  @Input() public endorsedSrc?: string;
  @Input() public isModal?: boolean;
  @Input() public providerImage?: string;
  @Input() public videoUrl?: string;
  @Input() public hideFallback?: boolean = false;
  @Input() public icon?: string;
  @Input() public imageSrc?: string;
  @Input() public providerLogo?: string;
  @Input() public resourceId: number;
  @Input() public format: ResourceFormat;
  @Input() public resourceType: ResourceType;

  @Output() public brokenImage = new EventEmitter();
  public imageSize: ImageSize;
  private cropperCoordinates: CropperCoordinates | null;
  constructor(
    private thumbnailService: ThumbnailService,
    private cdr: ChangeDetectorRef,
    private webEnvironmentService: WebEnvironmentService,
    private authService: AuthService,
    private resourceImageService: ResourceImageService,
    private videoService: VideoService,
    private ldFLagsService: LDFlagsService
  ) {}

  public get isLearnerHub(): boolean {
    return this.ldFLagsService.useLearnerHub;
  }

  public get getImageLayoutClass(): string {
    let cssClass = `image-layout--${this.format}`;

    if (
      this.thumbnailService.shouldCenterImage(this.resourceType) ||
      this.icon
    ) {
      cssClass += ' image-layout--centered';
    }
    if (this.isModal) {
      cssClass += ' image-layout--squared';
    }

    return cssClass;
  }

  public get resourceIsVideo() {
    return this.resourceType === 'Video';
  }

  public get resourceTypeIcon() {
    return resourceTypeIcon(this.resourceType);
  }

  public get shouldPadCropping() {
    // feature flag uploaded-cloudinary-transforms-11102020
    const featureEnabled: boolean =
      this.authService?.authUser?.uploadedImageType === 'padded';
    return featureEnabled && this.isResourceCard && this.isClientUploadedImg;
  }

  private get isResourceCard() {
    return this.format === 'resource-card';
  }

  private get isClientUploadedImg() {
    const isClientUploadedImg = this.thumbnailService.isClientUploadedImg(
      this.imageSrc
    );
    return isClientUploadedImg;
  }

  private get shouldGravitateToCenter() {
    // feature flag cloudinary-without-gfaces
    const featureDisabled: boolean =
      this.authService?.authUser?.uploadedImageType === 'centered';
    return (
      this.shouldPadCropping || (featureDisabled && this.isClientUploadedImg)
    );
  }

  private get useCropperCoordinates() {
    return (
      this.thumbnailService.useNewImageUploader &&
      this.cropperCoordinates &&
      this.isClientUploadedImg
    );
  }

  public get fallbackIconSize() {
    switch (this.format) {
      case 'card':
      case 'details-modal':
      case 'content-card':
      case 'content-card-lg':
        return 'large' as DfIconSize;
      case 'list':
      case 'preview':
      case 'related':
      case 'resource-card':
      case 'learner-hub-card':
        return 'medium' as DfIconSize;
    }
  }

  public ngOnChanges() {
    this.buildImageUrls();
  }

  public getFallback() {
    if (this.providerImage) {
      this.fallbackLogoSrc = this.webEnvironmentService.getBlobUrl(
        this.providerImage
      );
      this.cdr.detectChanges();
    } else {
      this.getFallbackPattern();
    }
  }

  // Used by template
  public errorLoadingImage() {
    this.imageHasError = true;

    // Try to set fallback pattern,
    if (!this.fallbackLogoSrc) {
      this.getFallback();
    } else {
      this.getFallbackPattern();
    }
  }

  private getFallbackPattern() {
    if (this.hideFallback) {
      return this.reportBrokenImage();
    }
    const pattern = this.thumbnailService.getFallbackPattern(
      this.resourceType,
      this.resourceId
    );

    if (pattern) {
      this.fallbackPatternSrc = pattern;
      this.cdr.detectChanges();
    } else {
      this.reportBrokenImage();
    }
  }

  private buildImageUrls() {
    // Don't bother building anything if we're using icons
    if (this.icon) {
      return;
    }
    // Get image in this order:
    // 1. Main image, if no fallback wanted then emit no image
    // 2. Fallback provider logo
    // 3. Fallback degreed resource type pattern
    if (this.thumbnailService.useNewImageUploader) {
      const { imageUrl, cropperCoordinates, altText } =
        this.resourceImageService.parseImageUrl(this.imageSrc);
      this.imageSrc = imageUrl;
      this.cropperCoordinates = cropperCoordinates;
      this.altText = altText;
    }
    this.imageSize = this.thumbnailService.setImageSize(
      this.format,
      // fixing linter error for types
      // not sure if setImageSize should be updated to just take "ResourceType"
      this.resourceType as LearningResourceType,
      this.useCropperCoordinates ? this.cropperCoordinates : null
    );

    let method: ProxyImageOptions['method'] = 'fetch';

    if (this.videoUrl) {
      const videoType = this.videoService.getVideoType(this.videoUrl);
      if (
        (videoType === 'youtube' || videoType === 'vimeo') &&
        !this.imageSrc
      ) {
        // if we don't already have an associated image for a Vimeo or YouTube video,
        // try to get it via the cloudinary-specific api for video images.
        // (note: this also handles instances where we upload Vimeo / YouTube videos to
        // the CMS with a custom image)
        method = videoType;
        this.imageSrc = this.videoUrl;
      }
    }

    if (this.imageSrc) {
      this.resourceSrc = this.thumbnailService.fetchProxyImageSrcset({
        imageSrc: this.imageSrc,
        imageWidth: this.imageSize.w,
        imageHeight: this.imageSize.h,
        method: method,
        crop: this.useCropperCoordinates
          ? 'crop'
          : this.shouldPadCropping
            ? 'pad'
            : null,
        gravity: this.shouldGravitateToCenter ? 'center' : null,
        cropCoordinates: this.getCropperCoordinates(),
      });
      // Set back to FALSE and detect changes, or else new image will not show.
      if (this.imageHasError) {
        this.imageHasError = false;
        this.cdr.detectChanges();
        this.reportFixedImage();
      }
    } else {
      this.imageHasError = true;
      this.getFallback();
    }
  }

  private reportBrokenImage() {
    this.brokenImage.emit(true);
  }

  private reportFixedImage() {
    this.brokenImage.emit(false);
  }

  private getCropperCoordinates(): {
    pointX: number;
    pointY: number;
    width: number;
    height: number;
  } | null {
    if (this.useCropperCoordinates) {
      switch (this.format) {
        case 'content-card':
        case 'resource-card':
        case 'list':
        case 'preview':
        case 'related':
        case 'learner-hub-card':
          return {
            pointX: this.cropperCoordinates.pointX,
            pointY: this.cropperCoordinates.pointY,
            width: this.cropperCoordinates.width,
            height: this.cropperCoordinates.height,
          };
        case 'card':
        case 'details-modal':
          // cards use the secondary ratio
          return {
            pointX: this.cropperCoordinates.pointX,
            pointY: this.cropperCoordinates.secondaryPointY,
            width: this.cropperCoordinates.width,
            height: this.cropperCoordinates.secondaryHeight,
          };
      }
    } else {
      return null;
    }
  }
}
