import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-tag-rating-more-count',
  templateUrl: './tag-rating-more-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRatingMoreCountComponent implements OnChanges {
  @Input() public count: number;

  public i18n: { [key: string]: string } = {};

  constructor(private translate: TranslateService) {}

  public ngOnChanges({ count }: SimpleChanges): void {
    if (count.currentValue) {
      this.i18n.dgTagRating_SignalCount = this.getSignalCountLabel(
        count.currentValue
      );
    }
  }

  private getSignalCountLabel(count: number): string {
    const i18n =
      count === 1
        ? 'dgTagRating_SignalCountSingle'
        : 'dgTagRating_SignalCountPlural';
    return this.translate.instant(i18n, { count });
  }
}
