/* eslint-disable @typescript-eslint/no-explicit-any */
import { Router } from '@angular/router';

import { Observable, forkJoin, from, of, pipe } from 'rxjs';
import {
  map,
  switchMap,
} from 'rxjs/operators';

import {
  BulkUploadGuard,
  PeopleRedirectGuard,
  SettingsSecurityGuard,
  SkillsRedirectGuard,
} from '@app/orgs/services/guards';
import {
  AuthService,
  ContextService,
  LDFlagsService,
  NavigationService,
  TargetsService,
  TrackerService,
  WebEnvironmentService,
} from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';

import { AnalyticsGuard } from '@app/analytics/guards/analytics.guard';
import { TagTypes } from '@app/analytics/models/constants';
import { OrgHelpMenuService } from '@app/orgs/services/org-help-menu.service';
import { OrganizationSupportInfo } from '@app/orgs/services/orgs.model';
import { TeamFlagsService } from '@app/team/services/team-flags.service';
import { LayoutAspect, LayoutConfiguration } from '@degreed/apollo-angular';

import { AuthUser } from '../../account/account-api.model';

import { BrandingState, navigationToAspect } from '@app/orgs/components/org-branding/v3/data-service';
import { OrgBrandingLayoutService } from '@app/orgs/services';

import * as DegreedLayout from './layout-configuration.json';

/**
 * Private internal cache of the layout configuration
 */
let layout$: Observable<LayoutConfiguration>;

/**
 * Replace all `<orgId>` tokens with url-specific orgId or default orgId
 * This can be called at app initialization or at any time the user navigates to a new org
 * 
 * @param configuration T = LayoutConfiguration | LayoutAspect
 * @param user AuthUser
 */
export function updateOrgId<T = LayoutConfiguration>(
  configuration: T,
  user: AuthUser | undefined,
  forceUpdate = false
): T {
  // Determine which org the user has access
  const [isOrgView, orgId] = resolveOrgId(user);
  if (isOrgView || forceUpdate) {    
    const visitLinks = (node, key) => {
      switch (key) {
        case 'href':
        case 'routerLink':
          {
            const text: string = node[key];
            if (text.includes('<orgId>')) {
              node[key] = text.replace('<orgId>', orgId);
            }
          }
          break;
      }
    };
  
    // Only do this the url starts with `orgs/:id`
    configuration = traverse(configuration, visitLinks);
  }

  return configuration;
}

/**
 * Update the "Admin" view (instead of the Learner view) items requiring admin access based on feature flags and user permissions
 */
export function updateAdminNavigation(
  configuration: LayoutConfiguration,
  auth: AuthService,
  featureFlag: LDFlagsService,
  guards: {
    analyticsGuard: AnalyticsGuard;
    peopleRedirectGuard: PeopleRedirectGuard;
    skillsRedirectGuard: SkillsRedirectGuard;
    bulkUploadGuard: BulkUploadGuard;
    settingsSecurityGuard: SettingsSecurityGuard;
  }
) {
  // Only update Admin configuration IF we have routed to Admin <pages className=""></pages>
  // Why? Because we need to know the specific orgId
  const isAdminRoute = window.location.href.includes('/orgs');
  if (!isAdminRoute) return configuration;

  const user = auth.authUser;
  const orgId = auth.authUser.defaultOrgId; // TODO: Get the orgId from the URL // Number(window.location.href.split('/')[4]);
  const orgInfo = auth.authUser.orgInfo.find(
    (x) => x.organizationId === orgId
  ) || { permissions: {} as any, settings: {} as any };
  const permissions = orgInfo.permissions;
  const settings = orgInfo.settings;

  // Skills
  const showSkills = permissions.manageSkills;
  const showSkillsRoles = permissions.manageSkills;
  const showSkillsSettings = permissions.manageSkills;
  // Skills+
  const showSkillsDashboard = permissions.manageSkills && settings.enableSkillsPlatform && featureFlag.degreedSkillsOctober2024;
  const showSkillsInventory = permissions.manageSkills && settings.enableSkillsPlatform && featureFlag.degreedSkillsOctober2024; // Inventory is analogous Org Skills
  const showSkillsScales =
    permissions.manageSkills && settings.enableSkillsPlatform && featureFlag.degreedSkillsOctober2024 && permissions.manageSkillsPlatform;
  const showSkillsPublish = permissions.manageSkills && settings.enableSkillsPlatform && featureFlag.degreedSkillsOctober2024;
  // Skill Standards
  const showSkillsSkillsSkillList = permissions.manageSkills && !(settings.enableSkillsPlatform && featureFlag.degreedSkillsOctober2024); // Org Skills is analogous to Inventory
  const showSkillsSkillStandards = permissions.manageSkills && settings.enableSkillStandards;

  const skillsClient =
    settings.skillInventoryClient || settings.skillAnalyticsClient;

  const showAcademies = user?.acmCanViewAcademyAdmin;
  const showExtendedEnterprise = user?.hasChannel;
  const showReports =
    ((settings.enableReportingInApp && auth.userCanViewReporting) ||
      permissions.manageReportingFTPScheduler) &&
    !skillsClient;
  const showInsights = permissions.viewReports;
  const showManagePlans =
    permissions.manageTargets && settings.enableCareerPathing;
  const showManageContent = permissions.manageContent;
  const showOrgGroups = permissions.manageGroups && !skillsClient;
  const showPathways = permissions.managePathways && !skillsClient;
  const showAutomations = permissions.manageBusinessRules;
  const showManageMembers = guards.peopleRedirectGuard.hasPermission;
  const showAnalytics = guards.analyticsGuard.isAuthorized;
  const showFileUpload = guards.bulkUploadGuard.canUpload();
  const showSessionManagement = guards.settingsSecurityGuard.hasPermission;
  const showContentMarketplace = settings.enableContentMarketplace;
  const showReportConfiguration = permissions.manageReportingInApp && permissions.manageReportingTransmitterConfigurations;
  const showReportSegments = showReportConfiguration && permissions.manageSegments;

  const visitDGATs = (node, key, parent) => {
    const redact = () => deleteFromParent(node, parent);

    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.admin.sidebar.dashboard.learning':
          !showInsights && redact();
          break;

        case 'global.navigation.admin.sidebar.skills':
          !showSkills && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.dashboard':
          !showSkillsDashboard && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.scales':
          !showSkillsScales && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.inventory':
          !showSkillsInventory && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.roles':
          !showSkillsRoles && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.skill-list':
          !showSkillsSkillsSkillList && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.skill-standards':
          !showSkillsSkillStandards && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.publish':
          !showSkillsPublish && deleteFromParent(node, parent);
          break;
        case 'global.navigation.admin.sidebar.skills.settings':
          !showSkillsSettings && deleteFromParent(node, parent);
          break;

        case 'global.navigation.admin.sidebard.people.groups':
          !showOrgGroups && redact();
          break;
        case 'global.navigation.admin.sidebard.people.users':
          !showManageMembers && redact();
          break;

        case 'global.navigation.admin.sidebar.catalog.plans':
          !showManagePlans && redact();
          break;
        case 'global.navigation.admin.sidebar.catalog.pathways':
          !showPathways && redact();
          break;
        case 'global.navigation.admin.sidebar.catalog.content':
          !showManageContent && redact();
          break;
        case 'global.navigation.admin.sidebar.catalog.academies':
          !showAcademies && redact();
          break;

        case 'global.navigation.admin.sidebar.reporting.reports':
          !showReports && redact();
          break;
        case 'global.navigation.admin.sidebar.reporting.segments':
          !showReportSegments && redact();
          break;
          
        case 'global.navigation.admin.sidebar.reporting.configurations':
          !showReportConfiguration && redact();
          break;

        case 'global.navigation.admin.sidebar.reporting.advanced-analytics':
          !showAnalytics && redact();
          break;

        case 'global.navigation.admin.sidebar.automations':
          !showAutomations && redact();
          break;
        case 'global.navigation.admin.sidebar.integrations.file-log':
          !showFileUpload && redact();
          break;
        case 'global.navigation.admin.sidebar.extended-enterprise':
          !showExtendedEnterprise && redact();
          break;

        case 'global.navigation.admin.sidebar.settings.security':
          !showSessionManagement && redact();
          break;
        case 'global.navigation.admin.sidebar.content-marketplace':
          !showContentMarketplace && redact();
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
}

/**
 * Return a LayoutConfiguration object based on the user's default organization
 * NOTE: Currently we use compiled configuration files: embedded JSON that is runtime transformed.
 *       !! This configuration should be constructed on the server
 *
 * @returns Observable<LayoutConfiguration>
 */
export const buildLayoutConfiguration_v3 = (
  router: Router,
  auth: AuthService,
  translate: TranslateService,
  environment: WebEnvironmentService,
  targets: TargetsService,
  navigation: NavigationService,
  featureFlag: LDFlagsService,
  context: ContextService,
  teamFlags: TeamFlagsService,
  helpMenu: OrgHelpMenuService,
  trackerService: TrackerService,
  analyticsGuard: AnalyticsGuard,
  peopleRedirectGuard: PeopleRedirectGuard,
  skillsRedirectGuard: SkillsRedirectGuard,  
  bulkUploadGuard: BulkUploadGuard,
  settingsSecurityGuard: SettingsSecurityGuard,  
  brandingService: OrgBrandingLayoutService
): Observable<LayoutConfiguration> => {

  // Customize the configuration JSON based on the user's organization, i18n and other information
  const buildLayout = pipe(
    switchMap((user: AuthUser | undefined) => {
      // Gather async data before customizing layout
      const organizationId = user?.defaultOrgId;
      const supportInfo$ = helpMenu.getSupportInfo(organizationId);
      const learnInSSOUrl$ = navigation.getLearnInSSOUrl(organizationId);
      const featuredPlanId$ = targets.getBrowseTarget(organizationId).pipe(map((target) => target.targetId));
      const branding$ = from(brandingService.loadBranding());

      return forkJoin([ of(user), supportInfo$, featuredPlanId$, learnInSSOUrl$, branding$ ]);
    }),
    map(
      ([ user, supportInfo, featuredPlanId, learnInSSOUrl, branding ]): LayoutConfiguration => {

        const analyticsUrl = analyticsGuard.getParentUrl( TagTypes.PRODUCT_SWITCHER );
        const organizationId = user?.defaultOrgId;
        const guards = { analyticsGuard, peopleRedirectGuard, skillsRedirectGuard, bulkUploadGuard, settingsSecurityGuard } as const;

        // Note: Update i18n first to get any placeholder variables used in translations that need to be replaced afterward
        let config = DegreedLayout as LayoutConfiguration;

            config = updateVisibility(config) as LayoutConfiguration;
            config = updatei18n(config, translate);
            config = updateUser(config, user, environment);
            config = updateUrls( config, user, featuredPlanId, learnInSSOUrl, analyticsUrl );

            config = updateNavigationBranding(config, branding);
            config = updateLearnerNavigation(config, user, featureFlag, teamFlags, auth, context);
            config = updateAdminNavigation(config, auth, featureFlag, guards);
            
            config = updateHelpMenu(config, organizationId, supportInfo);
            config = addAnalyticTrackers(config, trackerService);
            config = validateConfiguration(config);

        return config;
      }
    )
  );

  if (!layout$) {
    layout$ = auth.authUser$.pipe(buildLayout);
  }

  return layout$;
};

function isAspect(configuration: LayoutConfiguration | LayoutAspect): configuration is LayoutAspect {
  return !configuration['admin'] && !configuration['learner'];
}

/**
 * For all navigation nodes, set `visible` to true unless explicitly set to false
 */
export function updateVisibility(configuration: LayoutConfiguration | LayoutAspect): LayoutConfiguration | LayoutAspect {
  const visitNavigation = (node) => {
    // Force explicity visibility settings
    if (node.visible !== false) node.visible = true;
  };
  
  if (isAspect(configuration)) {
    traverse(configuration.navigation, visitNavigation);
  } else {
    ['admin', 'learner'].forEach((aspect) => {
      configuration[aspect] && traverse(configuration[aspect].navigation, visitNavigation);
    })
  }

  return configuration;
}

function updateNavigationBranding(
  config: LayoutConfiguration, 
  branding: Partial<BrandingState>
): LayoutConfiguration {

    return {
      ...config,
      admin: navigationToAspect(branding.navigation, config.admin),
      learner: navigationToAspect(branding.navigation, config.learner),
    }
}

/**
 * Replace all `<user>` tokens with current user's vanityUrl
 * Replace profile nav item image with current user's picture
 * @param configuration LayoutConfiguration
 * @param user AuthUser
 */
function updateUser(
  configuration: LayoutConfiguration,
  user: AuthUser | undefined,
  environment: WebEnvironmentService
): LayoutConfiguration {
  const userName = user?.viewerProfile.vanityUrl || '';
  let profileImage = user?.viewerProfile.picture;

  const visitLinks = (node, key) => {
    switch (key) {
      case 'href':
      case 'routerLink': {
        const text: string = node[key];
        if (text.includes('<user>'))
          node[key] = text.replace('<user>', userName);
      }
        break;
    }
  };
  const visitDGATs = (node) => {
    switch (node.dgat) {
      case 'global.navigation.learner.sidebar.profile': // Update Profile image
        if (profileImage) {
          if (profileImage.startsWith('~')) {
            profileImage = environment.getBlobUrl(profileImage);
            node.image = profileImage;
          }
        }
        break;
    }
  };

  configuration = traverse(configuration, visitLinks);
  configuration = traverse(configuration, visitDGATs);

  return configuration;
}



/**
 * Update the navigation items based on feature flags and user permissions
 */
function updateLearnerNavigation(
  configuration: LayoutConfiguration,
  user: AuthUser | undefined,
  featureFlag: LDFlagsService,
  teamFlags: TeamFlagsService,
  auth: AuthService,
  context: ContextService,
) {
  const userName = user?.viewerProfile.vanityUrl || '';
  const useLearnerHub = featureFlag.useLearnerHub;
  const showFlexEd =
    (user?.isPexUser && user?.isPexOrg) || user.defaultOrgInfo?.hasPex;
  const showNotifications = !!user;
  const showProfile = !!user;
  const showAssistant = featureFlag.lxpDegreedAssistant;
  const showOpportunities = user?.hasCareerMobility;
  const showSkillCoach = 
    user?.isSkillInventoryClient || user?.isSkillAnalyticsClient
      ? user?.isManager
      : user?.isManager &&
        user?.defaultOrgInfo.settings.enableTeamSpace &&
        (user?.defaultOrgInfo.settings.skillCoachFullOrgAccess ||
          teamFlags.teamSpaceEnabled);
  const showSkillCoachAssignments = featureFlag.skillCoach.managerAssignments;
  const showManageOrg =
    (auth.userCanManageLearnerOrg ||
      auth.userCanViewReporting ||
      auth.userCanManageSkillInventory ||
      auth.userCanManageSkillAnalytics) &&
    !context.isChannel();
  const showMyPlan = user?.defaultOrgInfo.settings.enableContentMarketplace;

  const visitDGATs = (node, key, parent) => {
    const updateLink = (link) => (node.routerLink = link);

    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.features.show-admin-view':
          parent.visible = showManageOrg;
          break;

        case 'global.navigation.learner.sidebar.profile.flex-ed':
          !showFlexEd && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.opportunities':
          !showOpportunities && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.skill-coach':
          !showSkillCoach && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.skill-coach.assignments':
          !showSkillCoachAssignments && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.assistant':
          !showAssistant && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.notifications':
          !showNotifications && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.profile':
          !showProfile && deleteFromParent(node, parent);
          break;
        case 'global.navigation.learner.sidebar.home.my-learning':
          useLearnerHub && updateLink(`/${userName}/learnerhub/home`);
          break;
        case 'global.navigation.learner.sidebar.home.assignments':
          useLearnerHub && updateLink(`/${userName}/learnerhub/assignments`);
          break;
        case 'global.navigation.learner.sidebar.profile.my-plan':
          !showMyPlan && deleteFromParent(node, parent);
          break;
      }
    }
  };

  return traverse(configuration, visitDGATs);
}


/**
 * Update the navigation items with the correct URLs
 */
function updateUrls(
  configuration: LayoutConfiguration,
  user: AuthUser,
  featuredPlanId: number,
  learnInSSOUrl: string,
  analyticsUrl: string
) {
  const showFeatured =
    featuredPlanId &&
    !user?.isSkillAnalyticsClient &&
    !user?.isSkillInventoryClient;
  
  const visitor = (node, key, parent) => {
    const text = () => node[key];
    if (key === 'dgat') {
      switch (node.dgat) {
        case 'global.navigation.learner.sidebar.featured':
          !showFeatured && deleteFromParent(node, parent);
          break;
      }
    } else {
      switch (key) {
        // This 'text' replacement relies on i18n being updated beforehand
        case 'text':
          node[key] = text().replace(
            '{{orgName}}',
            user?.defaultOrgInfo?.name || 'LXP'
          );
          break;
        case 'routerLink':
        case 'href':
          node[key] = text()
            .replace('<analyticsUrl>', analyticsUrl)
            .replace('<learnInSSOUrl>', learnInSSOUrl)
            .replace( '<featuredPlanId>', featuredPlanId.toString());
          break;
      }
    }
  };

  return traverse(configuration, visitor);
}

/**
 * config = updateHelpMenu(config, organizationId, helpMenu)
 * Update the help menu items based on the organization's support info
 */
export const updateHelpMenu = (
	configuration: LayoutConfiguration | LayoutAspect,
	organizationId: number,
	supportInfo: OrganizationSupportInfo
) => {
	if (!organizationId) supportInfo = { phone: '800.311.7061' };

	const visitDGATs = (node, key) => {
		const updateByKey = (field, value, allowEmpty = false) => {
      const isVisible =  (value || allowEmpty);
      
			node.visible = isVisible;
			if (isVisible) node[field] = value || '';
		};
		if (key === 'dgat') {
			switch (node.dgat) {
				case 'global.navigation.admin.sidebar.help.knowledge-center':
				case 'global.navigation.learner.sidebar.help.knowledge-center':
					updateByKey('href', supportInfo?.helpLink);
					break;
				case 'global.navigation.admin.sidebar.help.faq':
				case 'global.navigation.learner.sidebar.help.faq':
					updateByKey('href', supportInfo?.faq);
					break;
				case 'global.navigation.admin.sidebar.help.cookie-notice':
				case 'global.navigation.learner.sidebar.help.cookie-notice':
					node.visible = supportInfo?.showCookieLink;
					break;
				case 'global.navigation.admin.sidebar.help.custom-link':
				case 'global.navigation.learner.sidebar.help.custom-link':
					updateByKey('text', supportInfo?.customText);
					updateByKey('href', supportInfo?.customLink);
					break;
				case 'global.navigation.admin.sidebar.help.support-phone':
				case 'global.navigation.learner.sidebar.help.support-phone':
					updateByKey('text', supportInfo?.phone);
					updateByKey('href', supportInfo?.phone ? `tel:${supportInfo.phone}` : '');
					break;
				case 'global.navigation.admin.sidebar.help.support-email':
				case 'global.navigation.learner.sidebar.help.support-email':
          {
            const hasEmail = supportInfo?.email && !supportInfo?.email.includes('@degreed.com');
            updateByKey('text', hasEmail ? supportInfo.email : '');
            updateByKey('href', hasEmail ? `mailto:${supportInfo.email}` : '');
          }
					break;
			}
		}
	};

	return traverse(configuration, visitDGATs);
};


/**
 * For any `i18n` field, replace the associated field with translated value
 * @returns LayoutConfiguration
 */
function updatei18n(
  configuration: LayoutConfiguration,
  translate: TranslateService
): LayoutConfiguration {
  const MAPPINGS = {
    i18n: 'text',
    headerTitleI18n: 'headerTitle',
    buttonI18n: 'buttonText',
    titleI18n: 'titleText',
  };
  const i18n = i18nWithLog(translate, false);
  const visitor = (node, key) => {
    // For each node, check if it has an translation field
    switch (key) {
      case 'i18n':
      case 'headerTitleI18n':
      case 'buttonI18n':
      case 'titleI18n':
        {
          const field = MAPPINGS[key];
          node[field] = i18n(node[key], node[field]);
        }
        break;
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Add analytics tracking to report clicks on the navigation items
 * @returns
 */
function addAnalyticTrackers(
  configuration: LayoutConfiguration,
  trackerService: TrackerService
): LayoutConfiguration {
  // Build analytics data for tracking
  const makeTrackData = (key: string) => {
    return {
      category: '',
      label: '',
      action: 'Global Navigation Item Clicked',
      properties: { itemClicked: key },
    };
  };
  const visitor = (node, key) => {
    switch (key) {
      case 'analytics':
        {
          const itemId = node.analytics;
          const defaultVal = node.text || '';

          // Inject callback tracking function
          node['trackEvent'] = () => {
            if (!itemId)
              console.error(
                `Missing analytics key: ${itemId} for ${defaultVal} (${node.i18n || ''})`
              );

            trackerService.trackEventData(makeTrackData(itemId));
          };
        }
        break;
    }
  };

  return traverse(configuration, visitor);
}

/**
 * Translation util that logs missing translations
 * Keys can be comma-separated to try multiple keys until a valid translation is found
 * Note: Left-most key is the most recent... right-most key is the fallback
 */
const i18nWithLog =
  (translate: TranslateService, showLog = true) => (key: string, fallback: string) => {
    const allKeys = key.replace(/\s+/g, '').split(','); // This will remove all whitespace

    // Find "most recent" translation
    let value = '';
    let selectedKey = '';

    for (let i = 0; i < allKeys.length; i++) {
      if (!value && allKeys[i]) {
        selectedKey = allKeys[i];
        value = translate.instant(selectedKey) || '';
        
        // If ngx/translate returns the key, it means the translation is missing
        if (value === selectedKey) value = '';
      }
    }

    if ( !value ) {
      showLog && console.error(`i18n: ${selectedKey} using fallback: ${fallback}`);
      value = fallback;
    }

    return value || fallback;
  };

export const validateConfiguration = (config: LayoutConfiguration | LayoutAspect) => { 
  const filterNulls = (node: LayoutNode, key: string) => {
    switch (key) {
      case 'subItems':
      case 'children':        
      case 'top':
      case 'bottom':
        node[key] = node[key].filter((item) => item !== null);
        break;
    }
  };

  return traverse(clearRedactionQueue(config), filterNulls);
};

// *************************************************
// Tree Utils
// *************************************************

type LayoutNode = Record<string, any>;
type Visitor = (node: any, key: string, parent?: any) => void;

// Depth-first node tree scanning
const traverse = (node: any, visit: Visitor, parent?: LayoutNode) => {
  for (const key in node) {
    if (typeof node[key] === 'object') {
      visit(node, key, parent);
      traverse(node[key], visit, node);
    } else {
      visit(node, key, parent);
    }
  }
  return node;
};

/**
 * Remove node reference from parent...
 *
 * NOTE: this destructive process is allowed due to the nature
 * of the visitor pattern and tree traversal.
 */

let queue: (() => void)[] = [];

/**
 * Defer the removal of a node from its parent until the end of the traversal
 */
const deleteFromParent = (node: LayoutNode, parent: LayoutNode) => {
  const removeNode = () => {
    const key = Object.keys(parent).find((key) => parent[key] === node);
    if (key) {
      if (Array.isArray(parent)) {
        parent.splice(+key, 1);
      } else delete parent[key];
    }
  };
  queue.push(removeNode);
};

/**
 * Execute all deferred removals
 */
const clearRedactionQueue = (config: LayoutConfiguration | LayoutAspect) => {
  queue.forEach((fn) => fn());
  queue = [];

  return config;
};


/**
 * For the specified window.href and authenticated user, determine the orgId allowed for the user
 * @returns [boolean, string] - [isOrgView, orgId]
 */
const resolveOrgId = (user: AuthUser | undefined): [boolean, string] => {
  // match orgs/:d in the url to get the org id
  const url = window.location.href
  const match = url ? url.match(/orgs\/(\d+)/) : null; 
  const urlId = match ? Number(match[1]) : user?.defaultOrgId || 1;

  const results = user?.orgInfo.find((x) => x.organizationId === urlId);
  const orgId = results?.organizationId || user?.defaultOrgId || 1

  return [!!match, orgId.toString()];
}
