<div class="data-table-container">
  <div class="l_flexbar guts-m-b-half">
    <div class="l_flex-grow">
      <h1 class="h3">{{ chartName }}</h1>
    </div>
    <div>
      <a
        class="link font-medium zeta"
        [routerLink]="['/orgs', orgId, 'groups']"
        data-dgat="topGroups-d08"
        *ngIf="context !== 'channel'"
      >
        {{ i18n.dgTopGroups_LinkToGroups }}
        <df-icon icon="chevron-right" size="small"></df-icon>
      </a>
    </div>
  </div>
  <div class="guts-m-t-half">
    <table class="data-table">
      <thead>
        <tr>
          <th
            *ngFor="let header of topGroups?.headers; let i = index"
            [ngClass]="{
              'right-text': i !== 0,
              'left-text l_w50': i === 0
            }"
            data-dgat="topGroups-4c9"
            scope="col"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of topGroups?.groups" data-dgat="topGroups-b9b">
          <td>
            <span *ngIf="group.privacyLevel === 0">
              <a
                class="color-ebony uhover"
                *ngIf="context !== 'channel'"
                href="/groups/{{ group.groupId }}"
                data-dgat="topGroups-188"
                >{{ group.groupName }}</a
              >
              <span *ngIf="context === 'channel'">{{ group.groupName }}</span>
            </span>
            <span *ngIf="group.privacyLevel === 1">
              <df-icon
                icon="lock-closed"
                size="small"
                class="guts-m-r-quart color-ebony-a61"
                [attr.aria-label]="i18n.dgTopGroups_ClosedGroup"
                [ngbTooltip]="i18n.dgTopGroups_ClosedGroup"
                tabindex="0"
              ></df-icon>
              <a
                *ngIf="context !== 'channel'"
                href="/groups/{{ group.groupId }}"
                data-dgat="topGroups-2c1"
                >{{ group.groupName }}</a
              >
              <span *ngIf="context === 'channel'">{{ group.groupName }}</span>
            </span>
            <span *ngIf="group.privacyLevel === 2">
              <df-icon
                icon="lock-closed"
                size="small"
                class="guts-m-r-quart color-ebony-a61"
                [attr.aria-label]="i18n.dgTopGroups_PrivateGroup"
                [ngbTooltip]="i18n.dgTopGroups_PrivateGroup"
                tabindex="0"
              ></df-icon>
              <a
                *ngIf="context !== 'channel'"
                href="/groups/{{ group.groupId }}"
                data-dgat="topGroups-45f"
                >{{ group.groupName }}</a
              >
              <span *ngIf="context === 'channel'">{{ group.groupName }}</span>
            </span>
            <span *ngIf="group.privacyLevel === 3">
              <df-icon
                icon="lock-closed"
                size="small"
                class="guts-m-r-quart color-ebony-a61"
                [attr.aria-label]="i18n.dgTopGroups_AdministrativeGroup"
                [ngbTooltip]="i18n.dgTopGroups_AdministrativeGroup"
                tabindex="0"
              ></df-icon>
              <a
                *ngIf="context !== 'channel'"
                href="/groups/{{ group.groupId }}"
                data-dgat="topGroups-6be"
                >{{ group.groupName }}</a
              >
              <span *ngIf="context === 'channel'">{{ group.groupName }}</span>
            </span>
          </td>
          <td class="right-text">
            {{ group.total }}
          </td>
          <td class="right-text">
            <a
              href="/groups/{{ group.groupId }}#/members"
              attr.aria-label="{{ topGroups?.headers[2] }}: {{
                group.memberTotal
              }}"
              data-dgat="topGroups-8f4"
              >{{ group.memberTotal }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="topGroups?.groups.length === 0" class="guts-p-t-1">
    {{ i18n.dgTopGroups_Empty }}
  </div>
</div>
