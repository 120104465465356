import { Injectable } from '@angular/core';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  DfTemplateOptions,
} from '@lib/fresco';
import { TranslateService } from '@ngx-translate/core';
import { RendererContext, FormRenderer } from '../../form-renderer.model';
import { RenderMode } from '../../user-input.model';

@Injectable({ providedIn: 'root' })
export class CourseRenderer implements FormRenderer {
  private i18n = this.translate.instant([
    'CourseFormCtrl_Accredited',
    'CourseFormCtrl_Informal',
  ]);
  constructor(
    private builder: DfFormFieldBuilder,
    private translate: TranslateService
  ) {}
  public render(
    context: RendererContext
  ): DfFormFieldConfig<DfTemplateOptions>[] {
    const sourceList = [
      {
        title: this.i18n.CourseFormCtrl_Accredited,
        id: 0,
      },
      {
        title: this.i18n.CourseFormCtrl_Informal,
        id: 1,
      },
    ];
    return [
      this.builder
        .customField(
          'courseTypeId',
          'CourseFormCtrl_WhereTaken',
          context.templates.institutionSelection,
          {
            sourceList,
            renderMode: context.inputContext.renderMode,
          }
        )
        .onControlValueChanges((value) => {
          context.state().isAccredited = value === 0;
        })
        .hiddenWhen(
          () =>
            context.inputContext.renderMode === RenderMode.ContentCatalog ||
            context.inputContext.isEditing
        )
        .build(),
    ];
  }
}
