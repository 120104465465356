<dgx-modal
  bodyClasses="guts-p-t-0 "
  [canCancel]="false"
  [isHeaderBorderless]="false"
  [isPendingWithOverlay]="true"
  [useDefaultForm]="false"
  [useDefaultSubmitButton]="false"
  [isFooterless]="true"
  [isLoading]="isLoading"
  trapFocus
>
  <ng-container class="modal-header">
    {{ modalTitle }}
  </ng-container>

  <ng-container class="modal-body">
    <form
      role="form"
      [formGroup]="form"
      class="form-wrap guts-p-full-2"
      (ngSubmit)="onSubmit()"
    >
      <div [class.input-success-kickstart]="shouldShowResults">
        <div class="input-success-wrap">
          <df-form-root [form]="form" [model]="model" [fields]="fields">
          </df-form-root>
        </div>
        <!--Submit button and submission results-->
        <dgx-global-add-results
          [buttonText]="i18n.dgUserOutcomeEditForm_SaveToProfile"
          [isCompleting]="true"
          [submissionStatus]="submissionStatus"
          [classifier]="'achievements'"
          (dismissWithNavigation)="onNavigateToCollection($event)"
        >
        </dgx-global-add-results>
      </div>
    </form>
  </ng-container>
</dgx-modal>

<ng-template #titleHelperText let-formControl="formControl">
  <div class="guts-p-b-1" id="title-helper-text">
    <p class="par par--small par--light">
      {{ i18n.dgUserOutcomeEditForm_QuantitativeDescriptions }}
    </p>
    <p class="par par--small par--light">
      {{ i18n.dgUserOutcomeEditForm_ExpandedClientBase }}
    </p>
    <p class="par par--small par--light">
      {{ i18n.dgUserOutcomeEditForm_OrganizedCharity }}
    </p>
  </div>
</ng-template>

<ng-template
  #skillsView
  let-formControl="formControl"
  let-params="params"
  dfSkill
>
  <dgx-tags-edit
    #tagsEditor
    class="form-wrap guts-m-t-1-quart"
    [tags]="formControl.value"
    (tagsChange)="onTagsChange($event, formControl)"
  ></dgx-tags-edit>
</ng-template>
