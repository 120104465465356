import { BusinessRuleType } from './business-rules';

export interface LoadOptions {
  orgId: number;
  businessRuleType?: BusinessRuleType;
  page?: number;
  pageSize?: number;
  sort?: string;
  isDescending?: boolean;
  term?: string;
  facets: any[];
  getAll?: boolean;
}

export interface Pagination {
  totalResults: number;
  page: number;
  pageSize: number;
  numPages: number;
}

export interface PagedResponse<T> {
  pagination: Pagination;
  payload: T;
}

export interface AutomationFindModel {
  resources: AutomationResource[];
  hasMoreItems: boolean;
  total: number;
}

export interface AutomationResource {
  name: string;
  resourceType?: string | AutomationResourceType;
  resourceId: number | string;
  resourceInputType?: string;
  organizationId?: number;
  isUserCreated?: boolean;
}

export enum AutomationResourceType {
  None = 'None',
  Privacy = 'Privacy',
  PermissionRole = 'PermissionRole',
  Pathway = 'Pathway',
  Target = 'Target',
  Skill = 'Skill',
  Group = 'Group',
  Provider = 'Provider',
  Content = 'Content',
}

export enum PredicateType {
  Attribute = 'Attribute',
  Skill = 'Skill',
  Academy = 'Academy',
}
