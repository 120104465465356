import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/** Automatically focuses an element immediately following its insertion into the DOM */
@Directive({
  selector: '[dgxAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  private _dgxAutofocus: boolean;

  constructor(private el: ElementRef) {
    if (!el.nativeElement.focus) {
      throw new Error(
        `Element '${el.nativeElement.tagName}' does not accept focus.`
      );
    }
  }
  @Input() public set dgxAutofocus(autofocus) {
    this._dgxAutofocus = typeof autofocus == 'boolean' ? autofocus : true;
  }

  public ngAfterViewInit(): void {
    if (this._dgxAutofocus) {
      // need to delay setting focus until popovers are fully closed
      setTimeout(() => {
        const element = this.el.nativeElement;

        // check if the element is an input, this should cover most cases
        if (element instanceof HTMLInputElement) {
          // then we focus on the input
          element.focus();

          // if the content is selectable, we also want to select the content
          if (element.select) {
            element.select();
          }
        } else {
          // Important! This will only work if combined with tabindex="0"
          // pass a warning to the console for the next dev who comes across this.
          if (this.el.nativeElement.tabIndex !== 0) {
            console.warn(
              'Warning! To set the focus on an element that is not natively focusable, you have to combine this with tabindex=0!'
            );
          }
          // we assume this is any other type of focusable element
          element.focus();
        }
      }, 500);
    }
  }
}
