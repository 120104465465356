import { Injectable } from '@angular/core';
import { readFirst } from '@dg/shared-rxjs';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { CertificateService } from '../certificate.service';
import { CertificateMapperService } from '../certificate-mapper.service';
import { OutcomeNotificationService } from '@app/user-content/user-outcome-v2/services/outcome-notification.service';
import { CertificateTrackerService } from '../certificate-tracker.service';
import { InputsService } from '@app/inputs/services/inputs.service';
import {
  CertificateMappingToAPI,
  CertificateModel,
} from '../../certificate.model';
import { FormGroup } from '@angular/forms';
import { UserOutcomeService } from '@app/outcomes/services/user-outcome.service';
import { OutcomesFacadeBaseV2 } from '@app/user-content/user-outcome-v2/services/outcomes-facade.base';
import { OutcomesService } from '@app/user-content/user-outcome-v2/services/outcomes.service';
import { SubmissionStatus } from '@app/inputs/inputs.model';
import { AuthService } from '@app/shared/services/auth.service';
import { UploaderService } from '@app/uploader/uploader.service';
import { CertificateUploadAdapter } from '@app/user-content/user-outcome/certificate-modal/certificate-upload.adapter';
import { lastValueFrom } from 'rxjs';
import { TipService } from '@app/onboarding/services/tip.service';
import { GlobalAddTrackingService } from '@app/global-add/services/global-add-tracking.service';

@Injectable()
export class CertificateGlobalAddFacade extends OutcomesFacadeBaseV2 {
  public viewModel$ = new BehaviorSubject<CertificateModel>(undefined);

  constructor(
    public certificateService: CertificateService,
    public certificateMapperService: CertificateMapperService,
    public outcomeNotificationService: OutcomeNotificationService,
    public certificateTrackerService: CertificateTrackerService,
    public userOutcomeService: UserOutcomeService,
    public inputsService: InputsService,
    public outcomesService: OutcomesService,
    public authService: AuthService,
    public uploaderService: UploaderService,
    public tipsService: TipService,
    public globalAddTrackingService: GlobalAddTrackingService
  ) {
    super(outcomesService, tipsService);
  }

  protected get viewModel(): CertificateModel {
    return this.viewModel$.value as CertificateModel;
  }

  protected set viewModel(viewModel: CertificateModel) {
    this.viewModel$.next(viewModel);
  }

  public get snapshot(): CertificateModel {
    return readFirst(this.viewModel$);
  }

  public async onNext(url: string): Promise<void> {}

  public async onSubmit(form: FormGroup): Promise<void> {
    try {
      const formData = form.value;
      this.updateViewWithFormData(formData);
      const apiParameters = this.certificateMapperService.toApiParameters(
        this.viewModel as CertificateModel
      );
      this.submissionStatus$.next(SubmissionStatus.Submitting);
      if (this.viewModel.isEditing) {
        await this.certificateService.updateCertificate(apiParameters);
      } else {
        await this.certificateService.addCertificate(apiParameters);
      }
      this.viewModel = {
        ...this.viewModel,
      };
      this.submissionStatus$.next(SubmissionStatus.Succeeded);
      this.performSuccessSideEffects();
      this.globalAddTrackingService.trackGlobalAddSaved(
        'Certificate',
        this.viewModel.skills,
        this.viewModel.mediumConfidenceInferredSkills,
        this.viewModel.highConfidenceInferredSkills
      );
    } catch (error) {
      this.performFailureSideEffects();
      throw new Error('Error in CertificateFacade', error);
    }
    return;
  }

  protected updateViewWithFormData(formData: CertificateModel) {
    this.viewModel = {
      ...this.viewModel,
      ...formData,
    };
  }

  protected performSuccessSideEffects() {
    const apiParameters = this.certificateMapperService.toApiParameters(
      this.viewModel
    );

    if (!this.viewModel.isEditing) {
      this.outcomeNotificationService.notifyOutcomeCreated(
        this.viewModel.title
      );
      this.certificateTrackerService.trackCertificateAdded(apiParameters);
    } else {
      this.outcomeNotificationService.notifyOutcomeUpdated(
        this.viewModel.title
      );
      this.certificateTrackerService.trackCertificateUpdated(apiParameters);
    }

    this.userOutcomeService.notifyOutcomeModified('certificate');
  }

  protected performFailureSideEffects() {
    this.outcomeNotificationService.notifyOutcomeCreateFailed();
  }

  public initializeViewModel(): void {
    super.initializeViewModel();
    const shouldSpinSubmitButton$ = combineLatest([
      this.submissionStatus$,
    ]).pipe(map(([s]) => s === SubmissionStatus.Submitting));

    this.viewModel = {
      ...this.viewModel,
      title: '',
      issuer: '',
      issuerUrl: '',
      imageUrl: '',
      certNumber: '',
      noExpiration: false,
      dateRangeForm: {
        startDate: null,
        endDate: null,
      },
      skills: [],
      userOutcomeId: null,
      contentTypeId: 'Certificate',
      contentType: '',
      loadInferredSkills: this.loadInferredSkills.bind(this),
      uploadAdapter: new CertificateUploadAdapter(this.uploaderService),
      contentUpload: {
        // Content uploader events
        onContentFileChange: this.onContentFileChange.bind(this),
        onContentUploadSuccess: this.onContentUploadSuccess.bind(this),
        onContentUploadFailure: this.onContentUploadFailure.bind(this),
      },
      inferredSkills$: this.inferredSkills$,
      shouldSpinSubmitButton$: shouldSpinSubmitButton$,
    };
  }

  public loadInferredSkills(title: string, description: string): void {
    this.inferredSkillsTrigger$.next({ title, description });
  }

  protected onContentUploadSuccess(
    formGroup: FormGroup,
    fieldName: string,
    response: any
  ) {
    this.onFormControlUpdate(formGroup, fieldName, response);

    this.viewModel = {
      ...this.viewModel,
      isSubmitButtonDisabled: false,
      imageUrl: response,
    };
    this.submissionStatus$.next(SubmissionStatus.None);
  }

  protected onContentFileChange(file: File) {
    this.viewModel = {
      ...this.viewModel,
      file,
    };
    this.viewModel.isSubmitButtonDisabled = true;
    this.submissionStatus$.next(SubmissionStatus.Pending);
  }

  protected onContentUploadFailure() {
    this.viewModel.isSubmitButtonDisabled = false;
    this.submissionStatus$.next(SubmissionStatus.None);
  }

  public async initializeEdit(userOutcomeId: number): Promise<void> {
    this.viewModel.isEditing = true;
    const request$ = this.userOutcomeService.getUserOutcome(
      this.authService.authUser.viewerProfile.userProfileKey,
      userOutcomeId
    );

    const certificate = await lastValueFrom(request$);

    this.loadInferredSkills(certificate.title, certificate.source);
    const updatedView = this.certificateMapperService.toViewModel(
      certificate as CertificateMappingToAPI
    );
    this.viewModel = {
      ...this.viewModel,
      ...updatedView,
    };
    return;
  }
}
