<div class="par par--small">
  <span class="df-form__invalid-msg form__invalid-msg ib guts-p-r-quart"
    ><span>{{ i18n.duplicatesFound }}</span>
    <span>{{ i18n.decision }}</span></span
  >
  <button
    (click)="viewDuplicates.emit()"
    type="button"
    class="link"
    data-dgat="checkDuplicateInputs-f6a"
  >
    {{ i18n.viewDuplicates }}
  </button>
</div>
