// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

// misc
import { DfIconModule } from '@lib/fresco';
import { SafePipe } from '@app/shared/pipes/safe.pipe';

import { ProfilePicComponent } from './profile-pic.component';
import { ProfileEngagementComponent } from './profile-engagement.component';
import { ProfilePicFallbackService } from '@app/shared/components/profile-pic/profile-pic-fallback.service';

@NgModule({
  declarations: [ProfileEngagementComponent, ProfilePicComponent, SafePipe],
  exports: [ProfileEngagementComponent, ProfilePicComponent, SafePipe],
  imports: [
    DfIconModule,
    CommonModule,
    TranslateModule,
    NgbModule,
    NgbTooltipModule,
  ],
  providers: [ProfilePicFallbackService, SafePipe],
})
export class ProfilePicModule {}
