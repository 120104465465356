import { DF_COLLAPSE_EXPAND } from '@lib/fresco';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GroupIdentifier } from '@app/groups/group-api';
import { TypeaheadSearchFunction } from '@app/shared/shared-api.model';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dgx-group-search-input',
  templateUrl: './group-search-input.component.html',
  styleUrls: ['./group-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [DF_COLLAPSE_EXPAND],
})
export class GroupSearchInputComponent implements OnInit {
  @Input() public placeholderText: string;
  @Input() public isNotFound: boolean;
  @Input() public groupNotFoundText: string;
  @Input() public groups?: GroupIdentifier[] = [];
  @Input() public isRequired = false;
  @Input() public search: TypeaheadSearchFunction<
    string | number,
    GroupIdentifier
  >;

  @Output()
  public onGroupSelection: EventEmitter<GroupIdentifier> = new EventEmitter();
  @Output()
  public onGroupInputBlur: EventEmitter<any> = new EventEmitter();

  /**
   * @deprecated
   *
   * TODO: This isn't being emitted by this component, but there is also no
   * underlying "remove" event associated with ngbTypeahead. Arguably this
   * should be deleted, but that would mean touching several more components.
   *
   * https://ng-bootstrap.github.io/#/components/typeahead/api
   */
  @Output()
  public onGroupRemove: EventEmitter<GroupIdentifier> = new EventEmitter();

  @ViewChild('groupSearchInput')
  public groupSearchInput: ElementRef<HTMLElement>;

  public model = '';

  public ngOnInit(): void {
    // this is a required binding for typeahead searches
    if (typeof this.search !== 'function') {
      throw new Error('search');
    }
  }

  public onItemClick(event: Event) {
    // if we don't eat item clicks, they propagate outside the typeahead drop-down to the containing component.
    event.preventDefault();
  }

  public onBlur() {
    this.onGroupInputBlur.emit();
  }

  public selection($event: NgbTypeaheadSelectItemEvent): void {
    const { item } = $event;
    // clear the input
    this.model = '';
    // fire the selection event
    this.onGroupSelection.emit(item);
    // prevent the actual typeahead selection
    $event.preventDefault();
    this.groupSearchInput.nativeElement.focus();
  }
}
