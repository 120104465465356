import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ButtonType } from '@app/shared/temporary-types';
import { TranslateService } from '@ngx-translate/core';

export interface SimpleModalInputBindings {
  bodyClasses?: string;
  bodyImg?: string;
  bodyImgAlt?: string;
  bodyText?: string | SafeHtml;
  secondaryBodyClasses?: string;
  secondaryBodyText?: string;
  canCancel?: boolean;
  canCloseHeader?: boolean;
  cancelButtonText?: string;
  closeOnSubmit?: boolean;
  headerText?: string;
  item?: any;
  isSubmitDisabled?: boolean;
  submitButtonType?: string;
  submitButtonText?: string;
}

/**
 * Simple Modal Component
 * Used with ModalService as a param of show() method
 *
 * (NOTE: The ＠ symbol within the example is *in unicode*, to prevent jsdoc
 * from treating it like a special character. Be sure to replace it with a **real**
 * `@` when copy-pasting.)
 *
 * ```
 * // add to dependencies:
 * import { SimpleModalComponent, SimpleModalInputBindings } from '＠app/shared/components/modal/simple-modal/simple-modal.component';
 * // then, wherever the modal is to be used (all optional):
 * const inputs: SimpleModalInputBindings = {
 *   bodyClasses: 'color-ebony-a18 center-text',
 *   bodyText: 'Body text.<br>With line breaks.',
 *   canCancel: true,
 *   headerText: 'Header',
 *   secondaryBodyClasses: 'par--light par--small'
 *   secondaryBodyText: 'something'
 *   submitButtonText: this.translate.instant('Core_Submit'),
 * };
 * this.modalService.show<Opportunity>(SimpleModalComponent, { inputs });
 * ```
 */

@Component({
  selector: 'dgx-simple-modal',
  templateUrl: './simple-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleModalComponent {
  /** Additional styling classes to apply to the body paragraph. */
  @Input() public bodyClasses? = '';
  /** Image path for an image above the content */
  @Input() public bodyImg?: string;
  /** Translated image alt string. */
  @Input() public bodyImgAlt?: string;
  /** Text displayed as a paragraph, if set. *Allows HTML, will be sanitized.* */
  @Input() public bodyText? = '';
  /** Additional styling classes to apply to the secondary body paragraph. */
  @Input() public secondaryBodyClasses? = '';
  /**
   * Text displayed as a secondary paragraph.
   * only use it when need to display two different paragraphs that using different styles.
   * Otherwise, use <br> in BodyText instead.
   * if set. *Allows HTML, will be sanitized.
   */
  @Input() public secondaryBodyText? = '';
  /** Whether or not the modal should have a cancel button. *Default `false`.* */
  @Input() public canCancel = false;
  /** Whether or not the modal header should have a (x) cancel button. *Default `true`.* */
  @Input() public canCloseHeader = true;
  /** Translated text to display on the cancel button. *Default `Cancel`.* */
  @Input() public cancelButtonText? =
    this.translateService.instant('Core_Cancel');
  /** Whether `submit` is actually just closing the modal. *Default `false`.* */
  @Input() public closeOnSubmit = false;
  /** Header that shows as the title for this modal, if set. */
  @Input() public headerText? = '';
  /** Whether or not the modal header has bottom. *Default `false`.* */
  @Input() public isHeaderBorderless? = false;
  /** Results that needs to be passed back. */
  @Input() public item?: any;
  /** Can be passed in to prevent submissions until true. */
  @Input() public isSubmitDisabled = false;
  /** Classes to add to the submit button. *Default `primary`.* */
  @Input() public submitButtonType?: ButtonType = 'primary';
  /** Translated text to display on the submit button. *Default `Submit`.* */
  @Input() public submitButtonText? =
    this.translateService.instant('Core_Submit');
  /** Translated text to be read aloud by the submit button, giving further context to the button's action. *Default undefined.* */
  @Input() public submitButtonAriaLabel: string = undefined;
  /** Whether or not the default bodyText and secondaryBodyText Classes are used **/
  @Input() public useDefaultStyles = true;

  constructor(private translateService: TranslateService) {}

  public get bodyClass() {
    const defaultClass = 'guts-p-h-2 guts-p-t-1 par';
    return this.useDefaultStyles
      ? `${defaultClass} ${this.bodyClasses}`
      : this.bodyClasses;
  }

  public get secondaryBodyClass() {
    const defaultClass = 'guts-p-h-2 par';
    return this.useDefaultStyles
      ? `${defaultClass} ${this.secondaryBodyClasses}`
      : this.secondaryBodyClasses;
  }
}
