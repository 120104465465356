import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth.service';
import { TagsService } from '@app/tags/services/tags.service';
import { TagsApi } from '@app/tags/tag-api.model';
import {
  DfFormFieldBuilder,
  DfFormFieldConfig,
  NotificationType,
} from '@lib/fresco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dgx-add-tags-convert-canonical-modal',
  templateUrl: './add-tags-convert-canonical-modal.component.html',
  styleUrls: ['./add-tags-convert-canonical-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTagsConvertCanonicalModalComponent
  implements OnInit, AfterViewInit
{
  @Input() public canonicalTags = [];
  public saving: boolean = false;
  public i18n: { [key: string]: string } = {};

  public form: FormGroup = new FormGroup({});
  public fields: DfFormFieldConfig[] = [];
  public options: FormlyFormOptions = {};

  public model = {};
  public showSelectAll: boolean = false;
  public selectAllKey = 'selectAll';

  public readonly NotificationType = NotificationType;

  @ViewChild('canonicalTagCheckboxRow')
  public canonicalTagCheckboxRef: TemplateRef<any>;
  @ViewChild('selectAllTemplate')
  public selectAllRef: TemplateRef<any>;
  @ViewChild('descriptionTemplate')
  public descriptionTemplateRef: TemplateRef<any>;

  constructor(
    private translateService: TranslateService,
    private tagsService: TagsService,
    private authService: AuthService,
    private builder: DfFormFieldBuilder,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal
  ) {}

  public ngOnInit(): void {
    this.i18n = this.translateService.instant([
      'Core_Saving',
      'TagsSvc_ConvertSkills',
      'UserController_SelectAll',
      'Core_UnselectAll',
      'TagsSvc_UseWorkdaySkillNames',
      'OrgSkills_AddSkills',
      'TagsSvc_ConvertSkills',
      'TagsSvc_ConvertWorkdaySkillsDescription',
    ]);
  }

  public ngAfterViewInit() {
    this.fields = this.buildFormFields();
    this.cdr.detectChanges();
    setTimeout(() => {
      this.selectAllBoxes(true);
      this.checkSelected();
      this.cdr.detectChanges();
    });
  }

  public onControlValueChange(control: FormControl, value: boolean): void {
    control.setValue(value);
    control.markAsDirty();
    this.checkSelected();
  }

  public checkSelected() {
    this.showSelectAll = !Object.keys(this.form.controls)
      .filter((key) => key !== this.selectAllKey)
      .some((key) => {
        return this.form.get(key).value;
      });
  }

  public selectAllBoxes(value: boolean) {
    Object.keys(this.form.controls)
      .filter((key) => key !== this.selectAllKey)
      .forEach((key) => {
        this.form.get(key).patchValue(value);
      });
    this.checkSelected();
  }

  public save(): void {
    this.saving = true;

    const tags = this.canonicalTags.map((t) => {
      const tag: { [key: string]: any } = {};
      if (this.form.contains(t.tagId) && this.form.get(`${t.tagId}`).value) {
        tag.tagId = t.canonicalTagId;
        tag.name = t.canonicalTag;
        tag.title = t.canonicalTag;
      } else {
        if (t.tagId !== 0) {
          tag.tagId = t.tagId;
        }
        tag.name = t.tag;
        tag.title = t.tag;
      }
      return tag;
    }) as Partial<TagsApi.Tag[]>;

    this.tagsService.addUserTags(tags).subscribe(() => {
      this.saving = false;
      this.authService.fetchAuthenticatedUser().subscribe();
      this.activeModal.close();
    });
  }

  private buildFormFields(): DfFormFieldConfig[] {
    const fields: DfFormFieldConfig[] = [
      this.builder
        .customField(
          this.selectAllKey,
          'TagsSvc_ConvertSkills',
          this.selectAllRef,
          {}
        )
        .describedAs(this.descriptionTemplateRef)
        .build(),
    ];

    for (const tag of this.canonicalTags) {
      fields.push(
        this.builder
          .customField(tag.tagId, null, this.canonicalTagCheckboxRef, tag)
          .unwrapped()
          .build()
      );
    }

    return fields;
  }
}
