import { LearningResourceViewModel } from '@app/inputs/models/learning-resource.view-model';
import { InputType } from '@app/shared/models/core-api.model';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Observable } from 'rxjs';

import {
  ContentHostingSource,
  CHFileLoaderService,
} from '@degreed/content-hosting-data-access';

/**
 * Content Switcher Component
 * @description Determines the Content Hosting content type component to be used.
 */
@Component({
  selector: 'dgx-content-switcher',
  templateUrl: './content-switcher.component.html',
  styleUrls: ['./content-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSwitcherComponent implements OnInit {
  @Input() public input: LearningResourceViewModel;
  @Input() public inputType: InputType;
  @Input() public inputId: number;

  file$: Observable<ContentHostingSource>;

  constructor(private fileLoaderService: CHFileLoaderService) {}

  ngOnInit(): void {
    this.file$ = this.fileLoaderService.loadFile({
      inputId: this.inputId,
      inputType: this.inputType,
    });
  }
}
