import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '@app/shared/temporary-types';

/**
 * Use this component to add bulk edit button for data-tables.
 *
 * This component is used within dgx-data-bulk-actions.
 *
 * @example
 * ```
 * <dgx-data-bulk-actions
 *    [numItemsForAction]="selectedItems.length"
 *    (bulkActionsCancelled)="selectNone()"
 * >
 *    <dgx-data-bulk-action-button
 *      actionIcon="eye-open"
 *      [disabled]="true"
 *      [displayText]="Do Something"
 *      (buttonAction)="showMyModal()"
 *    ></dgx-data-bulk-action-button>
 *  </dgx-data-bulk-actions>
 * ```
 */
@Component({
  selector: 'dgx-data-bulk-action-button',
  templateUrl: './data-bulk-action-button.component.html',
})
export class DataBulkActionButtonComponent {
  @Input() public actionIcon: string;
  @Input() public disabled: boolean = false;
  @Input() public displayText: string;
  @Input() public ariaLabel: string;
  // TODO: Export buttonType types from fresco
  @Input() public buttonType: ButtonType = 'passive';
  @Input() public dgatInput: string = 'data-bulk-action-button-3af';
  @Output() public buttonAction: EventEmitter<void> = new EventEmitter();

  public actionClicked() {
    this.buttonAction.emit();
  }
}
